import React from 'react';
import styled, { css } from 'styled-components';

const ErrorState = css`
  border: 1px solid red;
  &:focus,
  &:active {
    border: 1px solid red;
  }
`;

const InputField = styled('input')`
  height: 46px;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  margin: ${(props) => props.margin || '0px 0 16px 0'};
  padding: 10px;
  width: ${(props) => props.width || '100%'};

  background: ${(props) => props.theme.themeColors.inputBg};
  color: ${(props) => props.theme.themeColors.text};
  border: 1px solid ${(props) => props.theme.themeColors.text};

  &::placeholder {
    color: ${(props) => props.theme.themeColors.secondary};
  }
  &:focus {
    border: 1px solid ${(props) => props.theme.themeColors.primaryCtaButton};
  }
  ${(props) => (props.error ? ErrorState : '')};
`;

export default InputField;
