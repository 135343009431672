import { ADD_FOOTER_PARTIAL, FOOTER_LOAD_ERROR } from '../constants';

const defaultState = {
  isLoading: true,
  wasError: false,

  partial: null,
};

export default function footerReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_FOOTER_PARTIAL:
      return {
        ...state,
        isLoading: false,
        wasError: false,
        partial: action.payload,
      };

    case FOOTER_LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        wasError: true,
      };

    default:
      return state;
  }
}
