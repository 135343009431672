import React from 'react';
import { useSelector } from 'react-redux';

const findUserIdeas = (userIdeaLookup, userId) => {
  if (!userIdeaLookup) {
    return null;
  }
  const ideaKeys = Object.keys(userIdeaLookup);
  const userIdeas = [];

  for (let i = 0; i < ideaKeys.length; i++) {
    let ideaId = ideaKeys[i];
    let idea = userIdeaLookup[ideaId];
    if (idea.user_id === userId) {
      userIdeas.push(idea);
    }
  }
  return userIdeas;
};

export const useIdeas = (userId) => {
  const ideaLookup = useSelector((state) => state.ideas.ideaList);

  const [ideas, setIdeas] = React.useState(findUserIdeas(ideaLookup, userId));

  React.useEffect(() => {
    setIdeas(findUserIdeas(ideaLookup, userId));
  }, [ideaLookup]);

  return ideas;
};
