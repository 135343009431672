import React from 'react';
import NotificationsPage from '../Notifications/NotificationsPage';

// Only used on Dashboard page for inFeed var and css selector
const FeedContainer = (props) => {
  return (
    <div className={`feed-container`}>
      <NotificationsPage
        {...props}
        size={'small'}
        hideLoadingIndicator
        hideNotificationsDisclaimer
        inFeed
        dontGetNotifsOnMount
      />
    </div>
  );
};

export default FeedContainer;
