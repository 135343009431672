import { SHOW_MODAL, HIDE_MODAL, CONFIRM_MODAL } from '../constants';

const defaultState = {};

export default function modalReducer(state = defaultState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...action.payload,
      };

    case HIDE_MODAL:
      return {};

    // OLD METHOD, PHASING OUT, USE CALLBACKS FOR CONFIRMATION SEE RISK PROFILES IN INVESTMENT OBJECTIVES FOR EXAMPLE
    case CONFIRM_MODAL:
      return {
        ...state.modal,
        confirmed: true,
      };

    default:
      return state;
  }
}
