import React from 'react';
import { Gradient } from '../components/backgrounds';
import { colorPalette } from '../lib/nvstr-utils.es';
import { Body1, defineFontSettings } from '../lib/nvstr-common-ui.es';
import { Text } from '../components/text';
import { SkeletonButton } from '../components/buttons';
import { Page } from '../components/layout';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/paths';
import { TrackingEvents } from '../../utils/tracking/events';

const LearnAndEarnProgressPage = () => {
  const textColor = colorPalette.primary.charcoal;
  const hoverTextColor = colorPalette.primary.oak;
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(ROUTES.DASHBOARD.build());
  };

  const fontSettings = defineFontSettings(
    {
      fontSize: '18px',
      lineHeight: '24px',
    },
    {
      fontSize: '36px',
      lineHeight: '42px',
    }
  );

  React.useEffect(() => {
    TrackingEvents.learn.VIEW_ALL_LEARN_LESSONS_COMPLETED_SCREEN.send();
  }, []);

  return (
    <Page maxWidth={'600px'} style={{ position: 'relative', overflow: 'hidden' }}>
      <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }}>
        <Gradient />
      </div>
      <div style={{ position: 'relative', zIndex: 2 }}>
        <div style={{ padding: '24px 0' }}>
          <Text uppercase fontSettings={fontSettings} bold color={textColor}>
            {'Learn and Earn Completed'}
          </Text>
        </div>

        <div style={{ padding: '16px 0 76px 0' }}>
          <Body1 color={textColor}>
            Come check back later, new lessons are always being added with new content and more rewards!
          </Body1>
        </div>

        <div style={{ textAlign: 'center', padding: '0 0 8px 0' }}>
          <SkeletonButton
            color={textColor}
            textColor={hoverTextColor}
            onClick={handleClose}
            buttonProps={{ width: '225px' }}
            fullWidth
          >
            CLOSE
          </SkeletonButton>
        </div>
      </div>
    </Page>
  );
};

export default LearnAndEarnProgressPage;
