import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withWindowSize from '../HOCS/withWindowSize';

import * as Actions from '../../actions/index';

import PageLoading from '../../components/PageLoading';
import ConnectionsListItem from './components/ConnectionsListItem';
import ConnectionRow from '../People/components/ConnectionRow';

import Icon from '../../components/misc/Icon';
import LinkButton from '../../components/buttons/LinkButton';

export class ConnectionsList extends Component {
  constructor() {
    super();
    this.DEFAULT_RENDER_TYPE = 'profile';
  }

  componentDidMount() {
    if (!this._isUserConnectionDataAvailable()) {
      this._getConnectionData();
    }
  }

  render() {
    if (this._wasError()) {
      return <div>An error occurred, try reloading the page.</div>;
    }

    if (this._isLoading()) {
      return <PageLoading flatStyle />;
    }

    if (this._hasNoConnections()) {
      return this._renderHasNoConnections();
    }

    return (
      <div className={'connections-list-container'}>
        {this._returnConnectionList().map((user) => this._renderUser(user))}
      </div>
    );
  }

  _renderConnectionsTableRow = (user) => {
    return (
      <ConnectionRow
        key={`connection-list-user-${user.user_id}`}
        userId={user.user_id}
        dontGetData={this.props.dontGetData}
        showWeeklyInsteadOfDailyPerf={this.props.showWeeklyInsteadOfDailyPerf}
      />
    );
  };

  _renderConnectionsListProfile = (user) => {
    return (
      <ConnectionsListItem
        key={`connection-list-user-${user.user_id}`}
        user={user}
        isCurrentUser={this.props.currentUser.user_id === user.user_id}
      />
    );
  };

  _renderType = () => this.props.renderType || this.DEFAULT_RENDER_TYPE;

  _renderUser = (user) => {
    const renderLookup = {
      profile: this._renderConnectionsListProfile,
      connectionsTable: this._renderConnectionsTableRow,
    };
    const renderer = renderLookup[this._renderType()];
    return renderer(user);
  };

  _renderHasNoConnections = () => {
    return (
      <div className={'no-idea-profile-portfolio-table'}>
        <div className={'icon-container'}>
          <Icon icon="fa-address-book" color="#ccc" size="xlarge" />
        </div>
        <div className={'message-container'}>
          {`${
            this.props.isCurrentUsersProfile ? 'You have' : `${this.props.profiledUsersFirstName} has`
          } not added any connections.`}
        </div>
        {this.props.isCurrentUsersProfile && (
          <div className={'available-action-button'}>
            <LinkButton
              linkTo={'/users/connections'}
              text={'Find Connections'}
              viewingContext={'Profile'}
              scrollToTop={true}
            />
          </div>
        )}
      </div>
    );
  };

  _isLoading = () => {
    const userId = this._returnUserId();
    const connectionStore = this._returnUserConnectionStore();
    return !(userId in connectionStore) || !('connections' in connectionStore[userId]);
  };

  _wasError = () => {
    const userConnectionStoreData = this._returnUserConnectionStoreData();
    return userConnectionStoreData && 'error' in userConnectionStoreData;
  };

  _hasNoConnections = () => this._returnConnectionList().length === 0;

  _isUserConnectionDataAvailable = () => {
    const userId = this._returnUserId();
    const connectionStore = this._returnUserConnectionStore();
    const isLoading = this._isLoading();
    return !isLoading && userId in connectionStore && connectionStore[userId].connections;
  };

  _getConnectionData = () => {
    const userId = this._returnUserId();
    this.props.actions.getUserConnections(userId);
  };

  _returnUserConnectionStoreData = () => {
    const userId = this._returnUserId();
    const connectionStore = this._returnUserConnectionStore();
    return userId in connectionStore ? connectionStore[userId] : null;
  };

  _returnConnectionListById = () => {
    if (this.props.connectionListById) {
      return this.props.connectionListById;
    }

    const userId = this._returnUserId();
    const connectionStore = this._returnUserConnectionStore();
    if (!(userId in connectionStore)) {
      console.error('User data not found in connection store', {
        connectionStore,
      });
    }
    return connectionStore[userId].connections;
  };

  _returnUser = (userId) => {
    const usersLookup = this.props.users.userList;
    return usersLookup[userId];
  };

  _returnConnectionList = () => this._returnConnectionListById().map((id) => this._returnUser(id));

  _returnUserConnectionStore = () => this.props.userConnections.userDict;

  _returnUserId = () => {
    const userId = this.props.userId;
    if (!userId) {
      console.error('User Id was not found in props', { props: this.props });
    }
    return userId;
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    users: state.users,
    userConnections: state.userConnections,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(withWindowSize, connect(mapStateToProps, mapDispatchToProps))(ConnectionsList);

export const withWindowProfileContainer = withWindowSize(ConnectionsList);
export default composedComponent;
