import React from 'react';
import { returnSecurityPanelUrl } from '@src/helpers/securityPanelHelpers';
import { useLocation, useNavigate } from 'react-router';

export const useNavigateToSecurity = (securityId) => {
  const navigate = useNavigate();
  const location = useLocation();

  const openSecurityPanelConfig = {
    securityId,
    navigate,
    location,
  };
  const url = returnSecurityPanelUrl(openSecurityPanelConfig);
  const handler = () => {
    navigate(url);
  };
  return handler;
};
