import React from 'react';
import { logMetricsTrackingEvent, sendAnetCreditCardPaymentTokenForPaymentSource } from '../../actions';
import { wasApiRequestSuccessful, returnApiRequestError } from '../../helpers/ajaxHelpers';

import Button from '../../components/buttons/Button';
import Icon from '../../components/misc/Icon';

import ChargeSummary from '../Transactions/ChargeSummary';
import ANetCreditCard from './forms/ANetCreditCard';
import PaymentSuccess from '../Payment/components/PaymentSuccess';
import NvstrCompanyInfo from '../UI/NvstrCompanyInfo';
import FormLoadingOverlay from '../../components/overlays/FormLoadingOverlay';
import ExpandableTextField from '../UI/ExpandableTextField';
import { FlatButton } from '../../main/components/buttons';
import { SUPPORT_EMAIL } from '@src/appConfig';

class CheckoutForm extends React.Component {
  constructor() {
    super();
    this.state = {
      _isSendingPayment: false,
      _wasPaymentSuccessful: false,

      _paymentError: null,
    };
  }

  render() {
    if (this.wasPaymentSuccessful()) {
      return <div className="checkout-form-container">{this.renderPaymentSuccessModal()}</div>;
    }

    return (
      <div className="checkout-form-container">
        <FormLoadingOverlay
          show={this.isSendingPayment()}
          spinner={{
            iconClass: 'circle-loading-spinner circle-loading-spinner-medium',
            style: { marginBottom: '-7px' },
          }}
          message="Processing Payment"
        />
        {this.props.charges && <ChargeSummary columns={['Plan', 'Price']} charges={this.props.charges} />}
        <div className={'subscription-form-container'}>
          <p className={'payment-options  '}>{'Pay by Credit Card'}</p>
          <p className={'payment-security secondary-text-color'}>
            <span className={``}>
              <Icon icon="fa-lock" size="small" className="" />
            </span>
            <span className={`text`}>Secured with SSL Encryption</span>
          </p>
          <ANetCreditCard
            charges={this.props.charges}
            onPaymentFailure={this.handlePurchaseFailure}
            onPaymentSubmitStart={this.handleStartSendingPayment}
            submitTokenAction={this.submitPaymentTokenForProcessing}
            handleCancel={this.props.handleCancel}
            hideUseAccountAddress={this.props.hideUseAccountAddress}
          />
        </div>
        {this.wasErrorSendingPayment() && this.renderSendingPaymentError()}
        <div className={`return-refund-policy-container`}>
          <ExpandableTextField
            linkClassName={'link-btn link-btn-low-contrast'}
            openLinkText={'View Return/Refund Policy'}
            text={this.returnRefundPolicyText()}
            label={'Subscription Return/Refund Policy'}
          />
        </div>
        <NvstrCompanyInfo className={`secondary-text-color`} />
      </div>
    );
  }

  submitPaymentTokenForProcessing = (token, billingInfo) => {
    sendAnetCreditCardPaymentTokenForPaymentSource(token, billingInfo).then((response) => {
      if (wasApiRequestSuccessful(response)) {
        this.handlePurchaseComplete();
      } else {
        const errorMessage = returnApiRequestError(response);
        this.handlePurchaseFailure(errorMessage, response);
      }
    });
  };

  returnRefundPolicyText = () => {
    return "Full refund available if you cancel within the first 7 days and haven't placed any live trades during that time.";
  };

  renderPaymentSuccessModal = () => {
    return (
      <div className={`payment-modal-overlay was-success component-bg`}>
        <PaymentSuccess />
        <div className="continue-btn-container">
          <FlatButton onClick={this.handleContinueClick}>Continue</FlatButton>
        </div>
      </div>
    );
  };

  renderSendingPaymentError = () => {
    return (
      <div className={`error-message-container`}>
        <p className={'error-message'}>{this.returnErrorSendingPayment()}</p>
        <p className={`contact-support-message`}>
          If issue persists, contact <a href={`mailto:${SUPPORT_EMAIL}`}>support</a> for more information.
        </p>
      </div>
    );
  };

  isSendingPayment = () => this.state._isSendingPayment;

  wasPaymentSuccessful = () => this.state._wasPaymentSuccessful;

  wasErrorSendingPayment = () => this.state._paymentError;

  returnErrorSendingPayment = () => this.state._paymentError;

  handlePurchaseComplete = () => {
    this._setPaymentComplete();
    this.props.onPaymentComplete();
  };

  handlePurchaseFailure = (errorMessage, response) => {
    this._setPaymentError(errorMessage);
    this._logSubmitPaymentError(errorMessage, response);
  };

  handleContinueClick = () => {
    this.props.handleContinue();
  };

  handleDismissErrorClick = () => {
    this._clearErrorMessage();
  };

  handleStartSendingPayment = () => {
    this._setStartSendingPayment();
  };

  _clearErrorMessage = () => {
    this.setState(() => ({
      _paymentError: null,
    }));
  };

  _setStartSendingPayment = () => {
    this.setState(() => ({
      _isSendingPayment: true,
    }));
  };

  _setStopSendingPayment = () => {
    this.setState(() => ({
      _isSendingPayment: false,
    }));
  };

  _setPaymentComplete = () => {
    this.setState(() => ({
      _isSendingPayment: false,
      _wasPaymentSuccessful: true,
    }));
  };

  _setPaymentError = (errorMessage) => {
    this.setState(() => ({
      _isSendingPayment: false,
      _paymentError: errorMessage,
    }));
  };

  _logSubmitPayment = () => {
    const event = 'Submitted Payment';
    const properties = {
      Context: this.props.viewingContext,
    };
    logMetricsTrackingEvent(event, properties)();
  };

  _logSubmitPaymentError = (errorMessage, response) => {
    const event = 'Received Error Processing Payment';
    const properties = {
      Context: this.props.viewingContext,
      'Error Message': response && response.message ? response.message : errorMessage,
    };
    logMetricsTrackingEvent(event, properties)();
  };
}

export default CheckoutForm;
