import React from 'react';
import { connect } from 'react-redux';

import { logErrorEvent, logMetricsTrackingEvent, showModal } from '../../actions';

import SizeReader from '../ExtraFunctionalityComponents/SizeReader';
import ScriptLoader from '../ExtraFunctionalityComponents/ScriptLoader';

import { createTimeInstance } from '../../helpers/timeHelpers';
import { createBasicErrorModal } from '../../constants/modals';

// IMPLEMENTS:
// https://developers.google.com/youtube/iframe_api_reference

const YOUTUBE_API_URL = 'https://www.youtube.com/iframe_api';

class Player extends React.PureComponent {
  render() {
    const { id, height: _props_height, width: _props_width, inPanel } = this.props;

    if (inPanel) {
      return (
        <div
          className={'video-player'}
          style={{
            height: `${_props_height}px`,
            width: `${_props_width}px`,
          }}
        >
          <div id={id} />
        </div>
      );
    } else {
      return (
        <SizeReader>
          {(height, width) => (
            <div
              className={'video-player'}
              style={{
                height: `${height}px`,
                width: `${width}px`,
              }}
            >
              <div id={id} />
            </div>
          )}
        </SizeReader>
      );
    }
  }
}

class VideoPlayer extends React.PureComponent {
  constructor(props) {
    super(props);

    this._player = null;

    this.ASPECT_RATIO = 0.594;
    this.REVERSE_ASPECT_RATIO = 1.6842;
    this.MAX_WIDTH = 800;
    this.WIDTH = this.MAX_WIDTH;
    this.HEIGHT = this.MAX_WIDTH * this.ASPECT_RATIO;

    this._playerId = this._buildPlayerIdFromProps(props);

    this.state = {
      height: this.HEIGHT,
      width: this.WIDTH,
    };
  }

  componentDidMount() {
    this._logViewPanel();
    this._handleWindowResize();

    window.addEventListener('resize', this._handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleWindowResize);
  }

  render() {
    const { inPanel } = this.props;
    const { height, width } = this.state;

    return (
      <div className={'video-player-container'} style={inPanel ? {} : { height: '100%', width: '100%' }}>
        <Player id={this._playerId} height={height} width={width} inPanel={inPanel} />
        <ScriptLoader
          uri={YOUTUBE_API_URL}
          onLoad={this.handleYouTubeApiLoad}
          onLoadFail={this.handleYouTubeApiLoadFailure}
        />
      </div>
    );
  }

  handleYouTubeApiLoad = () => {
    this.handleAPIReady();
  };

  handleYouTubeApiLoadFailure = (error) => {
    const errorMessage = 'Something went wrong. Please try again later.';
    this.showError(errorMessage, error);
  };

  handleDismiss = () => {
    const location = this.props.location.pathname;
    this.props.navigate(location);

    this._logDismiss();
  };

  handleAPIReady = () => {
    // 3. This function creates an <iframe> (and YouTube player)
    //    after the API code downloads.
    const { videoId } = this.props;

    const _this = this;
    this._playbackIsDone = false;

    try {
      _this._player = new window.YT.Player(this._playerId, {
        height: _this.HEIGHT,
        width: _this.WIDTH,
        videoId,
        events: {
          onReady: _this.onPlayerReady,
          onStateChange: _this.onPlayerStateChange,
        },
      });
    } catch (e) {
      // some async here that can cause a failure
      setTimeout(this.handleAPIReady, 100);
    }
  };

  showError = (message, error) => {
    const { navigate, dispatch } = this.props;
    const modal = {
      contentComponent: createBasicErrorModal(message),
      size: 'wide',
      dismissable: true,
    };
    showModal(modal)(dispatch);
    navigate(-1);
  };

  onPlayerReady = (event) => {
    // console.log('onPlayerReady', event);

    const { autoPlay } = this.props;
    if (autoPlay) event.target.playVideo();
  };

  onPlayerStateChange = (event) => {
    // console.log('onPlayerStateChange', event)
    // example
    // if (event.data === window.YT.PlayerState.PLAYING && !this._playbackIsDone) {
    // }
  };

  _buildPlayerIdFromProps = ({ thoughtId, videoId }) =>
    `video-player-${thoughtId}-${videoId}-${createTimeInstance().valueOf()}`;

  _handleWindowResize = () => {
    const { windowHeight, windowWidth } = this.props;
    const paddingOffset = 50;
    const usableWidth = windowWidth - paddingOffset;
    let width = Math.min(usableWidth, this.MAX_WIDTH);
    const aspectHeight = usableWidth * this.ASPECT_RATIO;
    let height = aspectHeight;
    if (aspectHeight > windowHeight) {
      height = windowHeight;
      width = height * this.REVERSE_ASPECT_RATIO;
    }
    this.setVideoPlayerDims({ height, width });
  };

  setVideoPlayerDims = ({ height, width }) => {
    this.HEIGHT = height;
    this.WIDTH = width;
    this.setState(() => ({
      height,
      width,
    }));
  };

  _logDismiss = () => {
    const { inPanel } = this.props;

    const event = 'Dismissed Video';
    const properties = {
      'In Panel': inPanel,
    };
    logMetricsTrackingEvent(event, properties)();
  };

  _logViewPanel = () => {
    const { inPanel } = this.props;

    const event = 'View Video';
    const properties = {
      'In Panel': inPanel,
    };
    logMetricsTrackingEvent(event, properties)();
  };
}

export default connect()(VideoPlayer);
