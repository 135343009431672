import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../../../actions/index';

import LinkTo from '../../../Links/LinkTo';

import { truncateName, snakeToHyphens, isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import { findUserIdeaForSecurity } from '../../../../helpers/ideaHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';

export class IdeaTypeCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;
    return (
      <td
        className={`security-table-${snakeToHyphens(columnKeyName)}`}
        style={this._doesOpenSecurityPanel() ? { cursor: 'default' } : {}}
      >
        {this._isLoading() ? this.props.loadingCellComponent : this._renderCell()}
      </td>
    );
  }

  _doesOpenSecurityPanel = () => !this.props.additionalProps || this.props.additionalProps.openSecurityPanel !== false;

  _renderCell = () => {
    return this._doesOpenSecurityPanel() ? (
      <LinkTo
        to={this._returnLinkForHandlingClick()}
        className={''}
        additionalClickAction={this.props.logSecurityPanelOpenClick}
        child={this._renderCellContent()}
        noTextDecoration
      />
    ) : (
      <div className={`tcell-container`}>{this._renderCellContent()}</div>
    );
  };

  _isLoading = () => {
    const { userId, securityId } = this.props;
    const currentUserId = this.props.currentUser.user_id;
    const security = this.props.securities.lookup[securityId];

    const ideaUserId = userId || currentUserId;
    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    const ideaId = idea && idea.idea_id;

    return !security || !('name' in security) || (!this.props.idea && !ideaId);
  };

  _returnLinkForHandlingClick = () => {
    const { userId, securityId } = this.props;
    const currentUserId = this.props.currentUser.user_id;
    const ideaUserId = userId || currentUserId;

    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    const ideaId = idea && idea.idea_id;

    const additionalProps = {
      activeTab: 'ideas',
      ideaId,
    };
    const openSecurityPanelConfig = {
      securityId,
      additionalProps,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    const { userId, securityId } = this.props;
    const currentUserId = this.props.currentUser.user_id;

    const ideaUserId = userId || currentUserId;
    const ideaList = this.props.ideas.ideaList;
    const idea = this.props.idea || findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    const ideaType = idea.idea_type ? idea.idea_type.name : '';

    return (
      <span
        className={`security-table-idea-type ${
          ideaType.toLowerCase() === 'buy' ? 'buy-text-color' : 'sell-text-color'
        }`}
      >
        {ideaType}
      </span>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    ideas: state.ideas,
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(IdeaTypeCell);

export default composedComponent;
