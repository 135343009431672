import React from 'react';

const TransactionStatus = (props) => {
  const { className, value } = props;
  const convertValueToDisplayDict = {
    pending: 'Pending',
    processing: 'Processing',
    cancelation_pending: 'Cancelation Pending',
    canceled: 'Canceled',
    complete: 'Complete',
    rejected: 'Rejected',
  };
  const displayString = convertValueToDisplayDict[value] || '';
  return <span className={`transaction-status ${className || ''}`}>{displayString}</span>;
};

export default TransactionStatus;
