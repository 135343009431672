import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../../../actions/index';

import LinkTo from '../../../Links/LinkTo';
import ExternalPositionAmountData from '../../../SecurityData/ExternalPositionAmountData';
import NumberChange from '../../../../components/animations/NumberChange';

import { snakeToHyphens, isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import { formatDataValue } from '../../../../helpers/securitiesHelpers';
import { findUserIdeaForSecurity } from '../../../../helpers/ideaHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';
import { getCurrentUserFromState } from '../../../../selectors/currentUser';
import { shouldUseStreamingPrices } from '../../../../helpers/currentUserHelpers';
import { ExternalPositionsBasicCell } from './ExternalPositionsBasicCell';

export class ExternalPositionAmountCell extends ExternalPositionsBasicCell {
  _returnLinkForHandlingClick = () => {
    const { userId, securityId } = this.props;
    const currentUserId = this.props.currentUser.user_id;
    const ideaUserId = userId || currentUserId;

    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    const ideaId = idea && idea.idea_id;

    const additionalProps = {
      activeTab: 'ideas',
      ideaId,
    };
    const openSecurityPanelConfig = {
      securityId,
      additionalProps,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    const { securityId, plaidSecurityId, column, useStreamingPrice, additionalTableCellProps } = this.props;
    const { onDashboard } = column;
    const turnOffChangeAnimation = false;

    const plaid_account_id_filter =
      additionalTableCellProps &&
      additionalTableCellProps['external_positions'] &&
      additionalTableCellProps['external_positions']['plaid_account_id_filter'];

    return (
      <ExternalPositionAmountData
        showStreamingPrices={onDashboard}
        securityId={securityId}
        plaidSecurityId={plaidSecurityId}
        plaid_account_id_filter={plaid_account_id_filter}
      >
        {(value, allocationPercentage, shares) => {
          if (shares === 0 || isUndefinedOrNull(shares)) {
            return null;
          }
          return (
            <span className={`security-table-allocation-container`}>
              <span className={`security-table-allocation-container`}>
                {useStreamingPrice && turnOffChangeAnimation ? (
                  <NumberChange value={formatDataValue(value, 'price')}>
                    {(value, animationClass) => (
                      <span className={`security-table-allocation-total-value   ${animationClass || ''}`}>{value}</span>
                    )}
                  </NumberChange>
                ) : (
                  <span className={`security-table-allocation-total-value  `}>{formatDataValue(value, 'price')}</span>
                )}
                {useStreamingPrice && turnOffChangeAnimation ? (
                  <NumberChange value={formatDataValue(allocationPercentage, 'percentage')}>
                    {(value, animationClass) => (
                      <span className={`multi-text-box-inline ${animationClass}`}>
                        <span className={`security-table-allocation-total-value-shares  `}>
                          {`${formatDataValue(shares, 'shares')} sh`}
                        </span>
                        <span className={`security-table-allocation-total-value-percentage  `}>{/*{ value }*/}</span>
                      </span>
                    )}
                  </NumberChange>
                ) : (
                  <span className={`multi-text-box-inline`}>
                    <span className={`security-table-allocation-total-value-shares  `}>
                      {`${formatDataValue(shares, 'shares')} sh`}
                    </span>
                    <span className={`security-table-allocation-total-value-percentage  `}>
                      {/*{ formatDataValue( allocationPercentage, 'percentage' ) }*/}
                    </span>
                  </span>
                )}
              </span>
            </span>
          );
        }}
      </ExternalPositionAmountData>
    );
  };

  _renderUnmappedCellContent = () => {
    return this._renderCellContent();
  };
}

const mapStateToProps = (state) => {
  const currentUser = getCurrentUserFromState(state);
  return {
    currentUser,
    securities: state.securities,
    ideas: state.ideas,
    useStreamingPrice: shouldUseStreamingPrices(currentUser),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(ExternalPositionAmountCell);

export default composedComponent;
