import React, { Component } from 'react';
import ThumbsUp from '../../../assets/SVGS/ThumbsUp';
import ThumbsDown from '../../../assets/SVGS/ThumbsDown';
import Icon from '../../../components/misc/Icon';
import styled from 'styled-components';

const ThoughtButtonWrapper = styled.div`
  cursor: pointer;
  text-align: center;

  .button-text {
    vertical-align: unset;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    text-transform: uppercase;

    .fa-icon-small {
      font-size: 16px !important;
    }
  }

  &.disagree,
  &.agree {
    border-radius: 5px;

    border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.buttonText};
    background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};

    * {
      color: ${({ theme }) => theme.themeColors.buttonText};
    }

    &.selected {
      background-color: transparent;
      color: ${({ theme }) => theme.themeColors.primaryCtaButton};

      * {
        color: ${({ theme }) => theme.themeColors.primaryCtaButton};
      }
    }

    &.selected-alternative {
      background-color: transparent;
      border: none;
      color: ${({ theme }) => theme.themeColors.primaryCtaButton};

      * {
        color: ${({ theme }) => theme.themeColors.primaryCtaButton};
      }
      &:hover {
        background-color: transparent;
        opacity: 0.8;
        color: ${({ theme }) => theme.themeColors.primaryCtaButton};

        * {
          color: ${({ theme }) => theme.themeColors.primaryCtaButton};
        }
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
      color: ${({ theme }) => theme.themeColors.buttonText};
      opacity: 0.8;

      * {
        color: ${({ theme }) => theme.themeColors.buttonText};
      }

      &.selected {
        background-color: transparent;
        color: ${({ theme }) => theme.themeColors.primaryCtaButton};
        opacity: 1;

        * {
          color: ${({ theme }) => theme.themeColors.primaryCtaButton};
        }
      }
    }
  }
`;

class Opinion extends Component {
  render() {
    const { userOpinion, hasSelectedOpinion } = this.props;
    return (
      <ThoughtButtonWrapper
        onClick={this._handleClick}
        className={`
          ${this._returnClassNames()}
          ${this._shouldShowAgree() ? 'agree' : 'disagree'}
          ${this._isActionAvailable() ? (hasSelectedOpinion ? 'selected-alternative' : '') : ' selected'}
        `}
      >
        {this._renderDisplayText()}
      </ThoughtButtonWrapper>
    );
  }

  _renderDisplayText = () => {
    const { opinion, userOpinion, hasSelectedOpinion } = this.props;
    return (
      <span
        className={`button-text ${
          opinion === userOpinion
            ? 'eye-catching-link-text-color'
            : hasSelectedOpinion
            ? 'eye-catching-link-text-color'
            : ''
        } ${this._isActionAvailable() ? '' : 'selected'}`}
      >
        {!this._isActionAvailable() && (
          <Icon
            size={'small'}
            icon={'fa-check-circle'}
            colorClassName={'eye-catching-link-text-color'}
            style={{ margin: '0 8px' }}
          />
        )}
        {this._returnDisplayText()}
      </span>
    );
  };

  _returnDisplayText = () => {
    if (this._shouldShowDefaultStyle()) {
      const { opinion, userOpinion, hasSelectedOpinion } = this.props;
      if (opinion === userOpinion) {
        return 'You ' + opinion + 'd';
      } else if (userOpinion !== 'neutral') {
        return null;
      } else {
        return opinion;
      }
    } else {
      return this._isActionAvailable()
        ? this._shouldShowAgree()
          ? 'Agree'
          : 'Disagree'
        : this._shouldShowAgree()
        ? 'You Agreed'
        : 'You Disagreed';
    }
  };

  _renderThumbsIcon = () => {
    if (this._shouldShowDefaultStyle()) {
      if (this._isActionAvailable()) {
        return this._shouldShowAgree()
          ? this.displayUserNeutralThumbsUpIcon()
          : this.displayUserNeutralThumbsDownIcon();
      } else {
        return this._shouldShowAgree() ? this.displayUserAgreeIcon() : this.displayUserDisagreeIcon();
      }
    } else {
      if (this._isActionAvailable()) {
        return this._shouldShowAgree() ? this.displayUserAgreeIcon() : this.displayUserDisagreeIcon();
      } else {
        return this._shouldShowAgree() ? this.displayUserAgreeIcon() : this.displayUserDisagreeIcon();
      }
    }
  };

  _handleClick = () => {
    if (this._isActionAvailable()) {
      return this._shouldShowAgree() ? this._handleAgreeClick() : this._handleDisagreeClick();
    }
    return false;
  };

  _handleAgreeClick = () => {
    this.props.handleClick('agree');
  };

  _handleDisagreeClick = () => {
    this.props.handleClick('disagree');
  };

  _returnClassNames = () => {
    return this._shouldShowDefaultStyle()
      ? 'opinion-button primary-CTA-btn-text-color'
      : `btn ${this._shouldShowAgree() ? 'agree' : 'disagree'}-button alternate-style `;
  };

  _returnHeightForSVG = () => '17px';

  _returnWidthForSVG = () => '17px';

  _returnUserCurrentOpinion = () => this.props.userOpinion;

  _returnOpinionType = () => this.props.opinion;

  _doesUserHaveAnOpinion = () =>
    this._returnUserCurrentOpinion() === 'agree' || this._returnUserCurrentOpinion() === 'disagree';

  _doesUserHaveAnAlternateOpinion = () =>
    this._doesUserHaveAnOpinion() && this._returnUserCurrentOpinion() !== this._returnOpinionType();

  _shouldShowAgree = () => this._returnOpinionType() === 'agree';

  _shouldShowDefaultStyle = () => !this.props.styleType || this.props.styleType === 'default';

  _isActionAvailable = () => this._returnUserCurrentOpinion() !== this._returnOpinionType();

  _isThisActionAlsoUserOpinion = () => this._returnUserCurrentOpinion() === this._returnOpinionType();

  _isInActivityPageFeed = () => this.props.inActivityPageFeed;

  _isInFeed = () => this.props.inFeed;

  displayUserAgreeIcon = () => {
    return (
      <span
        style={{
          display: 'inline-block',
          height: this._returnHeightForSVG(),
          width: this._returnWidthForSVG(),
          margin: this._shouldShowDefaultStyle() ? '' : '4px 6px 0 -2px',
        }}
      >
        <ThumbsUp height={this._returnHeightForSVG()} width={this._returnWidthForSVG()} />
      </span>
    );
  };
  displayUserDisagreeIcon = () => {
    return (
      <span
        style={{
          display: 'inline-block',
          height: this._returnHeightForSVG(),
          width: this._returnWidthForSVG(),
          margin: this._shouldShowDefaultStyle() ? '' : '4px 6px 0 -2px',
        }}
      >
        <ThumbsDown height={this._returnHeightForSVG()} width={this._returnWidthForSVG()} />
      </span>
    );
  };
  displayUserNeutralThumbsUpIcon = () => {
    return (
      <span
        className={`no-fill-svg`}
        style={{
          display: 'inline-block',
          height: this._returnHeightForSVG(),
          width: this._returnWidthForSVG(),
        }}
      >
        <ThumbsUp fill={'none'} height={this._returnHeightForSVG()} width={this._returnWidthForSVG()} />
      </span>
    );
  };
  displayUserNeutralThumbsDownIcon = () => {
    return (
      <span
        className={`no-fill-svg`}
        style={{
          display: 'inline-block',
          height: this._returnHeightForSVG(),
          width: this._returnWidthForSVG(),
        }}
      >
        <ThumbsDown fill={'none'} height={this._returnHeightForSVG()} width={this._returnWidthForSVG()} />
      </span>
    );
  };
}

export default Opinion;
