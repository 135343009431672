import React, { useState } from 'react';
import { FlatButton } from '../../../main/components/buttons';
import { Container } from '../../../main/components/ui';

const OrderWarnings = (props) => {
  const {
    ordersCount,
    isOptimizedOrder,
    warnings,

    handleAcceptAllWarnings,
    handleCancel,
  } = props;

  const createAdditionalMessagingForWarning = (warningType) => {
    const orderString = ordersCount === 1 ? 'order' : 'orders';
    const orderPossessiveString = ordersCount === 1 ? 'it' : 'them';
    const messageLookup = {
      market_closed: isOptimizedOrder
        ? `Click "Place Anyway" if you want ${orderPossessiveString} to be sent automatically when the market reopens. If the ${orderString} came from the Optimizer, consider re-running the Optimizer when the market is open instead.`
        : `Place the ${orderString} again if you want ${orderPossessiveString} to be sent automatically when the market reopens.`,
    };
    return messageLookup[warningType] || null;
  };

  const enhanceMessage = (message, warningType) => {
    // const orderString = ordersCount === 1 ? 'order' : 'orders';
    // const orderPossessiveString = ordersCount === 1 ? 'it' : 'them';
    const messageLookup = {
      market_closed: 'The market is open from 9:30am ET to 4pm ET on weekdays except holidays.',
    };
    return messageLookup[warningType] || null;
  };

  const [warningQueuePosition, setWarningQueuePosition] = useState(0);

  const displayWarningQueuePosition = warningQueuePosition + 1;
  const warningsCount = warnings.length;
  const isMultipleWarnings = warningsCount > 1;

  const activeWarning = warnings[warningQueuePosition];
  const { message, type } = activeWarning;
  const additionalMessage = createAdditionalMessagingForWarning(type);
  const enhancedMessage = enhanceMessage(message, type);

  const handlePlaceAnyway = () => {
    const didAcceptAllWarnings = warningQueuePosition + 1 === warningsCount;
    if (didAcceptAllWarnings) {
      handleAcceptAllWarnings(warnings);
    } else {
      setWarningQueuePosition((prevQueuePos) => prevQueuePos + 1);
    }
  };

  return (
    <div className="modal-message" style={{ paddingTop: '0px' }}>
      {isMultipleWarnings && (
        <p className={'order-warning-count'}>{`${displayWarningQueuePosition} of ${warningsCount}`}</p>
      )}
      <p>{message}</p>
      {enhancedMessage && <p>{enhancedMessage}</p>}
      {additionalMessage && <p>{additionalMessage}</p>}
      <Container className={`modal-confirmation-buttons-container`} row centerAll>
        <FlatButton onClick={handlePlaceAnyway}>Place Anyway</FlatButton>
        <FlatButton transparent onClick={handleCancel}>
          Don't Place
        </FlatButton>
      </Container>
    </div>
  );
};

export default OrderWarnings;
