import React from 'react';

export const IdeaPerformanceHeading = (props) => {
  const { notification } = props;
  const { adjective, start_phrase, end_phrase } = notification;
  const colorClass = adjective.toLowerCase() === 'best' ? 'increase-change-text-color' : 'decrease-change-text-color';
  const isWeekly = start_phrase.toLowerCase() === 'past week';
  return (
    <div className={`idea-perf-notif-header`}>
      <span style={{ whiteSpace: 'nowrap' }}>
        <span className="bold" style={{ display: 'inline-block' }}>
          {start_phrase}
        </span>
        &nbsp;
        <span className={colorClass}>{adjective}</span>
        &nbsp;
        <span>{'Performers'}</span>
        &nbsp;
      </span>
      {end_phrase && end_phrase.length > 0 && (
        <span style={{ display: 'inline-block' }}>
          <span className={isWeekly ? 'hide-mobile' : ''}>
            {'-'}
            &nbsp;
          </span>
          {end_phrase}
        </span>
      )}
    </div>
  );
};
