import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import * as Actions from '../../actions/index';

import IconButton from '../../components/buttons/IconButton';
import Button from '../../components/buttons/Button';
import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import { FlatButton } from '../../main/components/buttons';

class ReloadAppBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _shouldShow: false,
      _isDismissed: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this._didVersionChange(prevProps) && !this._stateShowBanner()) {
      this._handleVersionChange();
    }
  }

  render() {
    if (!this._showBanner()) {
      return null;
    }

    return (
      <div className={`reload-banner-container fade-in-drop`}>
        {this._renderMessage()}
        {this._renderReloadButton()}
        {this._renderDismissButton()}
      </div>
    );
  }

  _stateShowBanner = () => this.state._shouldShow;
  _isDismissed = () => this.state._isDismissed;

  _showBanner = () => this._stateShowBanner() && !this._isDismissed();

  _renderDismissButton = () => {
    return (
      <IconButton
        icon="fa fa-times fa-times-thin"
        colorClassName="secondary-text-color"
        size="large"
        handleClick={this._handleDismiss}
      />
    );
  };

  _renderMessage = () => {
    return <div className={`reload-banner-message`}>{this._returnBannerMessage()}</div>;
  };

  _renderReloadButton = () => {
    return <FlatButton onClick={this._handleReloadClick}>Reload</FlatButton>;
  };

  _returnBannerMessage = () =>
    `An update is available to ${PRODUCT_DISPLAY_NAME}. Please reload the page to get the latest version.`;

  _handleReloadClick = () => {
    this._logBannerReloadClick();
    window.location.reload();
  };
  _handleVersionChange = () => {
    this._setShouldShow();
    this._logBannerShow();
  };
  _handleDismiss = () => {
    this._setDismiss();
    this._logBannerDismiss();
  };
  _setDismiss = () => {
    this.setState(() => ({
      _isDismissed: true,
    }));
  };
  _setShouldShow = () => {
    this.setState(() => ({
      _shouldShow: true,
    }));
  };

  _isVersionStored = (props) => !isUndefinedOrNull(this._returnAppVersion(props));
  _didVersionChange = (prevProps) =>
    this._isVersionStored(prevProps) && this._returnAppVersion(prevProps) !== this._returnAppVersion();
  _returnAppVersion = (props) => this._returnCurrentUser(props).app_version;
  _returnCurrentUser = (props) => (props || this.props).currentUser;

  _logBannerReloadClick = () => {
    const event = 'Clicked Reload Nvstr Banner';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logBannerDismiss = () => {
    const event = 'Clicked Dismiss Reload Nvstr Banner';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logBannerShow = () => {
    const event = 'Viewed Reload Nvstr Banner';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ReloadAppBanner);

export default composedComponent;
