import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/index';

import { isUserThoughtLeader, isUserCollective } from '../../helpers/usersHelpers';

export class UserContainer extends React.PureComponent {
  render() {
    return <div className={`user-container ${this.props.containerClass}`}>{this.childrenWithUserProps()}</div>;
  }

  childrenWithUserProps = () => {
    let isUserDataStillLoading = false; // Hackish way to prevent page breaks due to data not arriving on time, no guarantee that this won't stay true indefinitely if data doesn't load.

    const userId = this.props.userId;
    const userIsCurrentUser = this.props.currentUser.user_id === userId;
    const user = this._wasUserSupplied()
      ? this.props.user
      : userIsCurrentUser
      ? this.props.currentUser
      : this.props.collectives.lookup[userId] || this.props.users.userList[userId];

    if (!user) {
      isUserDataStillLoading = true;
    }

    const loadingUser = {
      user_id: 0,
      first_name: '',
      last_name: '',
      is_current_user: false,
      is_live_trading: false,
      is_on_waitlist: false,
      is_private: false,
      analytics_id: null,
      avatar_url: '',
    };
    const userProps = isUserDataStillLoading
      ? {
          user: loadingUser,
          userIsCurrentUser: false,
          isThoughtLeader: false,
          isLiveTrading: false,
          isLoading: true,
        }
      : {
          user,
          userId,
          userIsCurrentUser,
          isCollective: isUserCollective(user),
          isThoughtLeader: isUserThoughtLeader(user),
          isLiveTrading: user.is_live_trading,
        };

    return React.Children.map(this._returnChildren(), (child) => React.cloneElement(child, userProps));
  };

  _returnChildren = () => {
    return Array.isArray(this.props.children) ? this.props.children.filter((child) => child) : this.props.children;
  };

  _wasUserSupplied = () => !!this.props.user;
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    users: state.users,
    collectives: state.collectives,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);
