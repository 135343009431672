import { useIsExtendedPricingEnabled } from '../../hooks/user/useCurrentUser';
import { useExtendedTradingHours } from '../../hooks/securities/useExtendedTradingHours';
import { usePriceData } from '../../hooks/securities/usePriceData';
import { Container, Spacing } from '../../components/ui';
import { formatSecurityPropertyValue } from '../../../helpers/securitiesHelpers';
import React from 'react';
import styled from 'styled-components';
import { createTimeInstance, formatLocalizedDateTime } from '@src/helpers/timeHelpers';

const ExtendedTradingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ align }) => align || 'flex-start'};
  align-items: center;

  * {
    font-size: 12px;
    line-height: 14px;
  }
`;
const ExtendedTradingPrefixWrapper = styled.div`
  color: ${({ theme }) => theme.themeColors.secondaryText};
`;
const ExtendedTradingPriceWrapper = styled.div`
  color: ${({ theme, value }) =>
    value === 0
      ? theme.themeColors.text
      : value > 0
      ? theme.themeColors.positive
      : theme.themeColors.negative} !important;
`;

const ExtendedAsOfWrapper = styled.div`
  padding-top: 2px;
  ${({ align }) => (align === 'flex-end' ? 'text-align: right;' : '')};
`;
const ExtendedPriceValue = styled.div`
  opacity: 0.8;
  color: ${({ theme }) => theme.themeColors.text};
  * {
    color: ${({ theme }) => theme.themeColors.text};
  }
`;

export const ExtendedTradingPrice = ({
  securityId,
  showPriceChange,
  showPrice,
  showTimestamp,
  ContainerProps,
  align,
}) => {
  const isUsingExtendedTrading = useIsExtendedPricingEnabled();
  const { isInExtendedTrading, extendedTradingPrefixShort } = useExtendedTradingHours();
  const { extendedPrice, extendedChangePercent, extendedChange, extendedAsOf } = usePriceData(securityId);

  if (!isUsingExtendedTrading || !isInExtendedTrading || extendedPrice === null || isNaN(extendedPrice)) return null;

  const extendedDisplay = 'As of ' + formatLocalizedDateTime('asOf', createTimeInstance(extendedAsOf));
  const children = (
    <ExtendedTradingWrapper align={align}>
      <ExtendedTradingPrefixWrapper>{extendedTradingPrefixShort}:</ExtendedTradingPrefixWrapper>
      <Spacing right={3} />

      {showPrice && (
        <>
          <ExtendedPriceValue>{formatSecurityPropertyValue(extendedPrice, 'current_price')}</ExtendedPriceValue>
          <Spacing right={3} />
        </>
      )}
      <Spacing right={3} />
      {showPriceChange && (
        <>
          <ExtendedTradingPriceWrapper value={extendedChangePercent}>
            {formatSecurityPropertyValue(extendedChange, 'current_price')}
          </ExtendedTradingPriceWrapper>
          <Spacing right={3} />
        </>
      )}
      <ExtendedTradingPriceWrapper value={extendedChangePercent}>
        {formatSecurityPropertyValue(extendedChangePercent, 'percentage')}
      </ExtendedTradingPriceWrapper>
    </ExtendedTradingWrapper>
  );

  if (ContainerProps) {
    return (
      <>
        <Container {...ContainerProps}>{children}</Container>
        {showTimestamp && <ExtendedAsOfWrapper align={align}>{extendedDisplay}</ExtendedAsOfWrapper>}
      </>
    );
  }
  return (
    <>
      {children}
      {showTimestamp && <ExtendedAsOfWrapper align={align}>{extendedDisplay}</ExtendedAsOfWrapper>}
    </>
  );
};
