import React from 'react';
import UserContainer from '../UserContainer';
import ProfileAvatar from '../../../components/user/ProfileAvatar';

export class UserAvatarListItem extends React.Component {
  constructor() {
    super();
    this.state = {
      wasHoveredOnce: false,
      isHovered: false,
    };
  }

  render() {
    const { profileLinkQuery } = this.props;

    return (
      <div
        className={`
          user-avatar-list-item
          ${this.state.wasHoveredOnce && !this.state.isHovered ? 'user-avatar-list-item-unfocus-animation' : ''}
          ${this.state.isHovered ? 'user-avatar-list-item-focus-animation' : ''}
        `}
        style={{
          zIndex: this.state.isHovered ? this.props.zIndexOnHover : this.props.zIndex,
        }}
      >
        {this.props.count ? (
          <ProfileAvatar
            {...this._createFakeUserDataForProfileAvatar()}
            profileLinkQuery={profileLinkQuery}
            withLink={false}
            handleMouseOver={this.handleMouseOver}
            handleMouseOut={this.handleMouseOut}
          />
        ) : (
          <UserContainer userId={this.props.userId}>
            <ProfileAvatar
              profileLinkQuery={profileLinkQuery}
              linkTo={this.props.linkTo}
              handleMouseOver={this.handleMouseOver}
              handleMouseOut={this.handleMouseOut}
            />
          </UserContainer>
        )}
      </div>
    );
  }

  _createFakeUserDataForProfileAvatar = () => ({
    user: {
      analytics_id: null,
      avatar_url: null,
      bio: '',
      city: '',
      connection_status_to_current_user: 'connected',
      current_user: false,
      is_community_thought_leader: false,
      is_current_user: false,
      is_live_trading: false,
      is_nvstr_employee: false,
      is_on_waitlist: false,
      is_private: false,
      first_name: this.props.count > 9 ? '9' : '+',
      last_name: this.props.count > 9 ? '+' : this.props.count.toString(),
      state: '',
      user_id: null,
    },
  });

  handleMouseOver = () => {
    this.setState(() => ({
      wasHoveredOnce: true,
      isHovered: true,
    }));
  };

  handleMouseOut = () => {
    this.setState(() => ({
      isHovered: false,
    }));
  };
}
