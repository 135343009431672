import React from 'react';
import styled from 'styled-components';

const FlipSwitchWrapper = styled.div`
  .flip-switch-container input[type='checkbox'].flip-switch:checked + div {
    background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
  }
  .switch-track {
    position: relative;
    background-color: ${({ theme }) => theme.themeColors.border};
  }

  .switch-track .switch-knob {
    background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    position: absolute;
    width: 26px !important;
    height: 26px !important;
    top: -2px;
    left: -4px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .switch-track.active .switch-knob {
    width: 26px !important;
    height: 26px !important;
    top: -2px;
    left: auto;
    right: -4px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
`;

export const Switch = (props) => {
  const label = props.label;
  const name = props.name;
  const value = props.getValue(name) || false;
  const isDisabled = props.checkIfIsDisabled && props.checkIfIsDisabled();

  const doNothingFunc = () => {
    // click handler is on a diff element to increase the click area
    return false;
  };

  const handleClick = () => {
    if (!isDisabled) {
      const callback = props.afterChangeFunc || (() => false);
      props.handleSwitchClick(name, callback);

      if (props.saveOnChange) {
        // HACK: HARDCODED TO ONLY WORK FOR ACTIVE SWITCH
        const data = {
          [`${props.objectType}_id`]: props.getObjectId(props.objectType),
          [name]: !value,
        };
        props.saveOnChange(data);
      }
      $('.idea-value').blur();
    }
  };

  return (
    <FlipSwitchWrapper>
      <div className={`flip-switch-container ${isDisabled ? 'disabled' : ''}`}>
        <label className="flip-switch-label-container">
          {label}

          <input type="checkbox" className="flip-switch" checked={value} onChange={doNothingFunc} />
          <div className={`switch-track ${value ? 'active' : ''}`} onClick={handleClick}>
            <div className="switch-knob" />
          </div>
        </label>
      </div>
    </FlipSwitchWrapper>
  );
};
export default Switch;
