import { BASEURL } from './index';

import { sendRequest } from '../helpers/ajaxHelpers';
import { isFloatOrInteger, isInt } from '../helpers/numberHelpers';

const URL = `${BASEURL}/api`;

export const securitiesAdapter = {
  viewSecurity: (securityId) => {
    return sendRequest('post', [`${URL}/securities/${securityId}/view`]);
  },

  quickFetchSecuritiesData: (securityIds) => {
    const key = isNaN(securityIds[0]) ? 'symbol' : 'security_id';
    const securitiesCSV = securityIds.join(',');

    return sendRequest('get', [`${URL}/securities?${key}=${securitiesCSV}`], null, 'quickFetchSecuritiesData').then(
      (response) => (response ? response.data : response)
    );
  },

  fetchSecuritiesFundamentalsData: (securities) => {
    const key = 'security_id';
    const securitiesCSV = securities.join(',');

    return sendRequest('get', [`${URL}/securities?${key}=${securitiesCSV}&include_fundamentals=true`]).then(
      (response) => (response ? response.data : response)
    );
  },

  fetchSecuritiesPriceHistoryData: (securities, timePeriod) => {
    if (!timePeriod) {
      console.error('No time period was provided for the security price data call');
    }

    const key = 'security_id';
    const securitiesCSV = Array.isArray(securities) ? securities.join(',') : securities;
    return sendRequest('get', [
      `${URL}/securities?${key}=${securitiesCSV}&include_price_history=true&time_period=${timePeriod}`,
    ]).then((response) => (response ? response.data : response));
  },

  fetchSecuritiesPriceData: (securities, key) => {
    const securitiesCSV = securities.join(',');
    key = key || 'security_id';
    return sendRequest(
      'get',
      [`${URL}/securities?${key}=${securitiesCSV}&include_price=true`],
      null,
      'fetchSecuritiesPriceData'
    );
  },

  fetchSecuritiesOrderPriceData: (securities) => {
    const securitiesCSV = securities.join(',');
    const key = 'security_id';
    return sendRequest(
      'get',
      [`${URL}/securities?${key}=${securitiesCSV}&include_bid_ask=true`],
      null,
      'fetchSecuritiesOrderPriceData'
    );
  },

  querySecurities: (query) => {
    return sendRequest('get', [`${BASEURL}/api/v1/securities/search?starts_with=true&query=${query}`]);
  },

  fetchSimilarCompanies: (securityId) => {
    return sendRequest('get', [`${URL}/similar_companies?security_id=${securityId}`]);
  },

  findSecuritiesMatchingFilters: (selectionKeys) => {
    let url = `${BASEURL}/api/investing_interests`;
    return sendRequest('post', [url, { interests: selectionKeys }]);
  },

  getSecuritiesPriceStreamConfig: () => {
    const options = { ignoreAuth: true };
    return sendRequest('get', [`${URL}/v1/securities/config`], options);
  },
  getUpcomingEarningsAnnouncements: () => {
    const options = { ignoreAuth: true };
    return sendRequest('get', [`${URL}/v1/securities/upcoming_earnings_announcements`], options);
  },
  getFilteredSecurities: (filters, count) => {
    const options = { ignoreAuth: true };
    return sendRequest('post', [`${URL}/v1/securities/filter`, { filters, count }], options);
  },
};
