import React from 'react';
import { useSelector } from 'react-redux';
import { usePriceLookup } from '@src/main/hooks/securities/usePriceData';
import { doArraysContainSameElementValues } from '@src/helpers/usefulFuncs';
import { approximateValueOfOrder } from '@src/main/utils/orders';

export const useOrdersInCart = () => {
  const orders = useSelector((state) => state.u_orders.not_placed_orders);
  return orders;
};

const filterOrderByMinValue = (value, orders, priceLookup) => {
  return orders.filter((o) => {
    const priceData = priceLookup[o.security_id];
    if (!priceData) return false;
    const { price } = priceData;
    const orderValue = Math.abs(approximateValueOfOrder(o, price));
    return value < orderValue;
  });
};
export const useFilteredOrdersInCart = (minValue) => {
  const orders = useSelector((state) => state.u_orders.not_placed_orders);
  const priceLookup = usePriceLookup();

  const [filteredOrders, setFilteredOrders] = React.useState(filterOrderByMinValue(minValue, orders, priceLookup));

  React.useEffect(
    () => {
      const nextFilteredOrders = filterOrderByMinValue(minValue, orders, priceLookup);
      if (!doArraysContainSameElementValues(nextFilteredOrders.map((o) => o.id), filteredOrders.map((o) => o.id))) {
        setFilteredOrders(nextFilteredOrders);
      }
    },
    [orders, priceLookup, minValue]
  );

  return filteredOrders;
};
