import React from 'react';

import LineChartUp from '../../../images/chart-up-green.png';
import LineChartDown from '../../../images/chart-down-red.png';

import { OpenCreateThoughtPanelButton } from './OpenCreateThoughtPanelButton';

export class ThoughtListHeading extends React.Component {
  render() {
    const thoughtType = this._returnThoughtType();
    const { headingImage, headingText, colorScheme } = this._returnThoughtsListHeadingMarkupConfig()[thoughtType];

    return (
      <div className={`thought-heading pro-cons-heading ${thoughtType.toLowerCase()} ${colorScheme}`}>
        <img className="pro-con-chart-img" src={headingImage} alt="line-chart" />
        <span>{headingText}</span>
        {this._shouldShowOpenCreateThoughtButton() ? (
          <OpenCreateThoughtPanelButton
            thoughtType={thoughtType}
            handleClick={this.handleOpenCreateThoughtPanelClick}
          />
        ) : null}
      </div>
    );
  }

  _returnThoughtType = () => this.props.thoughtType;
  _returnThoughtsListHeadingMarkupConfig = () => ({
    pro: {
      headingText: 'Pros',
      headingImage: LineChartUp,
      colorScheme: 'green',
    },
    con: {
      headingText: 'Cons',
      headingImage: LineChartDown,
      colorScheme: 'red',
    },
  });

  _shouldShowOpenCreateThoughtButton = () => this.props.showAddButton;
  handleOpenCreateThoughtPanelClick = () => {
    if (this._shouldShowOpenCreateThoughtButton()) {
      this.props.handleOpenCreateThoughtPanelClick(this._returnThoughtType());
    }
  };
}
