import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useOrdersInCart } from '@src/main/hooks/orders/useOrdersInCart';
import { fetchCurrentUserQuickInfo, getOrdersV1, placeOrdersV1, runOptimizer } from '@src/actions';
import { Page } from '@src/main/components/layout';
import { LivePriceTracking } from '@src/main/Managers/LivePriceTracking';
import { Container } from '@src/main/components/ui';
import { OrderCart } from '@src/main/containers/Orders/OrderCart';
import { UserTradingAccountInfos } from '@src/main/containers/Orders/UserTradingAccountInfo';
import { LoadingAnimation } from '@src/main/components/ui/LoadingAnimation';
import { Body3, Body5, CheckCircle, H5 } from '@src/main/lib/nvstr-common-ui.es';
import { pluralize, sleep } from '@src/helpers/usefulFuncs';
import { SkeletonButton } from '@src/main/components/buttons';
import { PlaceOrderWarnings } from '@src/main/containers/Orders/PlaceOrderWarnings';
import { Warning } from '@src/main/icons/svg/Warning';
import { FixedBackgroundOverlay } from '@src/main/components/layout/FixedBackgroundOverlay';

const width = '440px';

const LoadingSpinnerWrapper = styled.div``;
const CheckIconWrapper = styled.div`
  svg {
    height: 64px;
    width: 64px;

    * {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  justify-content: space-between;
`;
const WarningIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 28px;
    width: 28px;

    fill: ${({ theme }) => theme.themeColors.error};
  }
`;

const PlaceOrderHeading = styled.div`
  svg {
    fill: ${({ theme }) => theme.themeColors.positive} !important;
  }
`;
const PlaceOrderHeadingText = styled.div`
  h5 {
    line-height: 1.1;
  }
`;

const STATES = {
  unSubmitted: 'unsub',
  isSubmitting: 'issub',
  acceptWarnings: 'accW',
  error: 'err',
  placedWithErrors: 'placedWithErrors',
  placed: 'placed',
};

export const OrderCartController = ({ onComplete }) => {
  const dispatch = useDispatch();
  const unplacedOrders = useOrdersInCart();

  const [state, setState] = React.useState(STATES.unSubmitted);

  const [orders, setOrders] = React.useState(null);
  const [placedOrders, setPlacedOrders] = React.useState(null);

  const [error, setError] = React.useState(null);
  const [orderWarnings, setOrderWarnings] = React.useState(null);
  const [isFromOptimizer, setIsFromOptimizer] = React.useState(null); // not set until place orders is called
  const [ordersWithErrors, setOrdersWithErrors] = React.useState(null);

  React.useEffect(() => {
    getOrdersV1()(dispatch);
  }, []);

  const onCancel = () => {
    setOrders(null);
    setPlacedOrders(null);
    setError(null);
    setOrderWarnings(null);
    setIsFromOptimizer(null);
    setOrdersWithErrors(null);
    setState(STATES.unSubmitted);
  };

  const handlePlaceOrdersResponse = async (response) => {
    const { data, isPlaced, orders, withWarnings, withErrors, error } = response;

    if (isPlaced) {
      setPlacedOrders(orders);
      await sleep(500);
      await getOrdersV1()(dispatch);

      // not blocking
      runOptimizer()(dispatch);
      fetchCurrentUserQuickInfo()(dispatch);

      setState(STATES.placed);
      return;
    }

    if (withErrors) {
      setOrdersWithErrors(error);

      await sleep(500);
      await getOrdersV1()(dispatch);

      // not blocking
      runOptimizer()(dispatch);
      fetchCurrentUserQuickInfo()(dispatch);

      setState(STATES.placedWithErrors);
      return;
    }

    if (withWarnings) {
      setOrderWarnings(data.warnings);
      setState(STATES.acceptWarnings);
      return;
    }

    setError(error);
    setState(STATES.error);
  };

  const handlePlacedOrdersContinue = async () => {
    onComplete();
  };
  const handleCancel = async () => {
    onComplete();
  };
  const onAcceptAllWarnings = async () => {
    setState(STATES.isSubmitting);
    const response = await placeOrdersV1(orders.map((o) => o.id), orderWarnings.map((w) => w.type))(dispatch);
    await handlePlaceOrdersResponse(response);
  };

  const handlePlaceAll = async (orders) => {
    setOrders(orders);
    setIsFromOptimizer(orders.some((o) => o.is_from_optimizer));
    setState(STATES.isSubmitting);

    const response = await placeOrdersV1(orders.map((o) => o.id))(dispatch);
    await handlePlaceOrdersResponse(response);
  };

  if (state === STATES.isSubmitting) {
    return (
      <FixedBackgroundOverlay>
        <Page width={width}>
          <Wrapper>
            <Container flex={1} column centerAll>
              <Container centerAll>
                <LoadingSpinnerWrapper>
                  <LoadingAnimation withLogo />
                </LoadingSpinnerWrapper>
              </Container>
              <Container top={16}>
                <Body5 thin>Placing {pluralize(orders.length, 'Order')}</Body5>
              </Container>
            </Container>
          </Wrapper>
        </Page>
      </FixedBackgroundOverlay>
    );
  }
  if (state === STATES.placed) {
    return (
      <FixedBackgroundOverlay>
        <Page width={width}>
          <Container>
            <Wrapper>
              <Container>
                <PlaceOrderHeading>
                  <Container row centerAll>
                    <Container top={4} right={12}>
                      <CheckIconWrapper>
                        <CheckCircle />
                      </CheckIconWrapper>
                    </Container>

                    <Container centerAll>
                      <PlaceOrderHeadingText>
                        <H5>Orders Placed</H5>
                      </PlaceOrderHeadingText>
                      <Container top={3} />
                    </Container>
                  </Container>
                </PlaceOrderHeading>
              </Container>
            </Wrapper>
          </Container>
          <Container centerAll>
            <SkeletonButton fullWidth onClick={handlePlacedOrdersContinue}>
              Continue
            </SkeletonButton>
          </Container>
        </Page>
      </FixedBackgroundOverlay>
    );
  }
  if (state === STATES.acceptWarnings) {
    return (
      <FixedBackgroundOverlay>
        <Page width={width}>
          <Wrapper>
            <PlaceOrderWarnings
              ordersCount={1}
              isFromOptimizer={isFromOptimizer}
              warnings={orderWarnings}
              handleAcceptAllWarnings={onAcceptAllWarnings}
              handleWarningsCancel={onCancel}
            />
          </Wrapper>
        </Page>
      </FixedBackgroundOverlay>
    );
  }
  if (state === STATES.error) {
    return (
      <Page width={width}>
        <Wrapper>
          <Container fullWidth>
            <Container row centerAll>
              <WarningIconWrapper>
                <Warning />
              </WarningIconWrapper>
              <Container left={9} verticallyCenter>
                <H5>Orders not placed</H5>
              </Container>
            </Container>
            <Container top={24} row centerAll>
              <Body5>{error}</Body5>
            </Container>
          </Container>
          <Container row centerAll>
            <SkeletonButton fullWidth onClick={onCancel}>
              Dismiss
            </SkeletonButton>
          </Container>
        </Wrapper>
      </Page>
    );
  }
  if (state === STATES.placedWithErrors) {
    return (
      <FixedBackgroundOverlay>
        <Page width={width}>
          <Wrapper>
            <Container top={24} horizontal={8}>
              <Container>
                <Container>
                  <H5>Orders partially placed</H5>
                </Container>
                <Container top={4}>
                  <Body5>Some orders are not placed</Body5>
                </Container>
              </Container>
            </Container>
            <Container row centerAll>
              <SkeletonButton fullWidth onClick={onCancel}>
                Dismiss
              </SkeletonButton>
            </Container>
          </Wrapper>
        </Page>
      </FixedBackgroundOverlay>
    );
  }

  if (state === STATES.unSubmitted) {
    return (
      <Page>
        <LivePriceTracking securityIds={unplacedOrders.map((o) => o.security_id)} />
        <Wrapper>
          <UserTradingAccountInfos />
          <Container top={24}>
            <OrderCart onPlaceAll={handlePlaceAll} onCancel={handleCancel} />
          </Container>
        </Wrapper>
      </Page>
    );
  }

  return null;
};
