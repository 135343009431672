import { axios, BASEURL } from '../adapters';

import { consoleError } from './devToolHelpers';
import { logErrorEvent, logMetricsTrackingEvent } from '../actions';
import { PRODUCT_PARTNER_ID } from '@src/appConfig';

const ignore401Routes = ['/live_accounts/identify'];

const _getPathnameFromRequestUrl = (url) => {
  const hosts = ['dev-api.tornado.com/api', 'qa-api.tornado.com/api', 'api.tornado.com/api'];

  if (hosts.some((hUrl) => url.includes(hUrl))) {
    return url.split('api.tornado.com/api')[1];
  } else {
    return url.split('/api')[1];
  }
};

export const sendRequest = async (method, args, options) => {
  let cache = {};
  try {
    if (!method) {
      console.error('No request method was specified');
    }
    if (!Array.isArray(args)) {
      console.error('Arguments should be an array of arguments that will be passed into function.');
    }

    let requestArgs = [...args];
    const data = requestArgs[1] || {};
    const requestProperties =
      method.toLowerCase() === 'get'
        ? { params: { ...data, platform: 'web', client_identifier: PRODUCT_PARTNER_ID } }
        : { data, platform: 'web', client_identifier: PRODUCT_PARTNER_ID };

    const csrfMethods = ['post', 'put', 'patch', 'delete'];
    let csrfToken = null;
    if (csrfMethods.includes(method.toLowerCase())) {
      const getCSRFBeforeLogin = await fetch(`${BASEURL}/api/v1/util/csrf`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      const data = await getCSRFBeforeLogin.json();
      csrfToken = data.csrf_token || null;
    }

    if (!requestProperties) {
      requestArgs.push({
        'X-CSRF-Token': csrfToken,
      });
    } else if (requestProperties.headers) {
      requestProperties.headers = {
        ...requestProperties.headers,
        'X-CSRF-Token': csrfToken,
      };
    } else {
      requestProperties.headers = {
        'X-CSRF-Token': csrfToken,
      };
    }

    cache.url = window?.location?.href;
    cache.endpoint = requestArgs[0];

    const response = await axios({
      method: method.toLowerCase(),
      url: requestArgs[0],
      ...requestProperties,
    });

    if (response?.data?.maintenance) {
      window.location = '/maintenance';
      return { error: 'Down for Maintenance' };
    }
    if (response && response.status === 401 && !(options && options.ignoreAuth)) {
      console.warn('redirecting, unauthorized from .then', requestArgs[0]);
      const rootUrl = window.origin + '/';
      if (window.location.href !== rootUrl && !ignore401Routes.includes(_getPathnameFromRequestUrl(err?.config?.url))) {
        window.location = '/?loginRequired=true';
        return { error: 'Login Required' };
      }

      logMetricsTrackingEvent('401 Response Received', {
        URL: window.location.href,
        Endpoint: requestArgs[0],
      })();
      return response;
    }
    if (response?.data?.error === 'Invalid CSRF Token') {
      consoleError('Invalid CSRF Token');
      const event = 'Invalid CSRF Token';
      logMetricsTrackingEvent(event)();
      window.location.reload();
      return { error: 'Something went wrong. Refreshing page and try again.' };
    }
    if (response?.status === 500) {
      return {
        data: {
          error: 'Something went wrong. Please try again later. We apologize for any inconvenience.',
        },
      };
    }
    return {
      ...response,
      statusCode: response?.status,
    };
  } catch (err) {
    if (
      !err.response ||
      !err.response.status ||
      (err.response && err.response.status && err.response.status.toString()[0] !== '4')
    ) {
      logErrorEvent(err)();
      console.error(err);
    }
    if (err.response && err.response.status === 401 && !(options && options.ignoreAuth)) {
      logMetricsTrackingEvent('401 Response Received', {
        URL: cache.url,
        Endpoint: cache.endpoint,
      })();

      const rootUrl = window.origin + '/';
      if (window.location.href !== rootUrl && !ignore401Routes.includes(_getPathnameFromRequestUrl(err?.config?.url))) {
        console.warn('redirecting, unauthorized from .catch -> ' + err?.config?.url, args);

        window.location = '/?loginRequired=true';
        return { error: 'Login Required' };
      }
    } else {
      return {
        status: 'error',
        error: err,
        message: 'The was a problem processing the request',
        raw_response: err?.response,
        statusCode: err?.response?.status,
      };
    }
  }
};

export const sendRequestV1 = async ({ method, url, body = {}, headers: params_header, options }) => {
  let cache;
  try {
    if (!method) {
      console.error('No request method was specified');
      return { error: true };
    }
    cache = { method, url };
    const headers = params_header ? { ...params_header } : {};

    // SET CSRF Token
    const csrfMethods = ['post', 'put', 'patch', 'delete'];
    let csrfToken = null;
    if (csrfMethods.includes(method.toLowerCase())) {
      const getCSRFBeforeLogin = await fetch(`${BASEURL}/api/v1/util/csrf`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      const data = await getCSRFBeforeLogin.json();
      csrfToken = data.csrf_token || null;
      headers['X-CSRF-Token'] = csrfToken;
    }

    const requestBody =
      method.toLowerCase() === 'get'
        ? { params: { ...body, platform: 'web', client_identifier: PRODUCT_PARTNER_ID } }
        : { data: { ...body, platform: 'web', client_identifier: PRODUCT_PARTNER_ID } };

    const response = await axios({
      method: method.toLowerCase(),
      url,
      headers,
      ...requestBody,
    });

    if (response?.data?.maintenance) {
      window.location = '/maintenance';
      return { error: 'Down for Maintenance' };
    }

    // HANDLE 401
    if (response && response.status === 401 && !(options && options.ignoreAuth)) {
      console.warn('redirecting, unauthorized from .then', requestArgs[0]);
      const rootUrl = window.origin + '/';
      if (window.location.href !== rootUrl && !ignore401Routes.includes(_getPathnameFromRequestUrl(err?.config?.url))) {
        window.location = '/?loginRequired=true';
        return { error: 'Login Required' };
      }

      logMetricsTrackingEvent('401 Response Received')({
        URL: window.location.href,
        Endpoint: requestArgs[0],
      });
      return response;
    }

    // HANDLE INVALID CSRF TOKEN
    if (response?.data?.error === 'Invalid CSRF Token') {
      consoleError('Invalid CSRF Token');
      const event = 'Invalid CSRF Token';
      logMetricsTrackingEvent(event)();
      window.location.reload();
      return { error: 'Something went wrong. Refreshing page and try again.' };
    }

    // HANDLE SERVER ERROR
    if (response?.status === 500) {
      return { data: { error: 'Something went wrong. Please try again later. We apologize for any inconvenience.' } };
    }

    return { ...response, statusCode: response?.status };
  } catch (err) {
    if (
      !err.response ||
      !err.response.status ||
      (err.response && err.response.status && err.response.status.toString()[0] !== '4')
    ) {
      console.error(err);
    }
    if (err.response && err.response.status === 401 && !(options && options.ignoreAuth)) {
      logMetricsTrackingEvent('401 Response Received')({ URL: cache.url, Method: cache.method });

      const rootUrl = window.origin + '/';
      if (window.location.href !== rootUrl && !ignore401Routes.includes(_getPathnameFromRequestUrl(err?.config?.url))) {
        console.log('redirecting, unauthorized from .catch -> ' + err?.config?.url);

        window.location = '/?loginRequired=true';
        return { error: 'Login Required' };
      }
    } else {
      return {
        ...(err?.response || {}),
        statusCode: err?.response?.status,
      };
    }
  }
};

export const isResponseErrorAMessage = (responseError) => {
  return responseError && typeof responseError === 'string';
};

export const wasApiRequestSuccessful = (response) =>
  response && response.data && response.data.status && response.data.status === 'success';

export const returnApiRequestError = (response) => {
  const defaultErrorMessage = 'Something went wrong. Please try again.';
  const isApiReturningReadableError =
    response && response.data && response.data.error && typeof response.data.error === 'string';
  if (isApiReturningReadableError) {
    return response.data.error;
  } else if (response && isResponseErrorAMessage(response.error)) {
    return response.error;
  } else {
    return defaultErrorMessage;
  }
};
