import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../actions/index';
import SubmitButton from '../../../components/buttons/SubmitButton';

import TextField from '../../../components/form/TextField';
import TextNumberField from '../../../components/form/TextNumberField';
import Dropdown from '../../../components/form/Dropdown';
import ToggleWithLabel, { YesNoToggleWithLabel } from '../../../components/form/ToggleWithLabel';
import PageLoading from '../../../components/PageLoading';

import {
  handleFocus,
  handleBlur,
  handleChange,
  handleUpdate,
  handleToggleClick,
  createInputComponent,
  listUsStateNames,
  listCountryNames,
  setExistingFormData,
} from '../../../helpers/formHelpers';
import styled from 'styled-components';

const Wrapper = styled.div`
  input {
    //border: none;
    line-height: 29px;

    //-webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }

  select {
    //border: none;

    //-webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
`;

class UpdateApexAccount extends Component {
  constructor(props) {
    super();

    this.state = {
      inputs: [
        {
          type: TextField,
          typeName: 'TextField',
          name: 'given_name',
          label: 'First Name',
          required: true,
          props: {},
        },
        {
          type: TextField,
          typeName: 'TextField',
          name: 'family_name',
          label: 'Last Name',
          required: true,
          props: {},
        },
        {
          type: TextField,
          typeName: 'TextField',
          name: 'contact_street_address',
          label: 'Address',
          required: true,
          props: {},
        },
        {
          type: TextField,
          typeName: 'TextField',
          name: 'contact_apartment',
          label: 'Apartment',
          props: {},
        },
        {
          type: TextField,
          typeName: 'TextField',
          name: 'contact_city',
          label: 'City',
          required: true,
          props: {},
        },
        {
          type: Dropdown,
          typeName: 'TextField',
          name: 'contact_state',
          label: 'State',
          required: true,
          props: {
            styling: 'dropdown-inline-left',
            size: 'small',
            values: listUsStateNames(),
            showArrowIcons: true,
          },
        },
        {
          type: TextField,
          typeName: 'TextField',
          name: 'contact_postal_code',
          label: 'Zip',
          required: true,
          props: {
            size: 'small',
          },
        },
        {
          type: Dropdown,
          typeName: 'Dropdown',
          name: 'contact_country',
          label: 'Country',
          required: true,
          props: {
            styling: 'dropdown-inline-left',
            values: ['United States of America'],
            hideLabel: true,
            showArrowIcons: true,
          },
        },
        {
          type: TextNumberField,
          typeName: 'TextNumberField',
          name: 'phone_number',
          label: 'Phone Number',
          required: true,
          props: {},
        },
        {
          type: Dropdown,
          typeName: 'Dropdown',
          name: 'total_investible_assets',
          label: 'What are your total investible assets?',
          props: {
            showLabel: true,
            styling: 'dropdown-inline-left',
            values: [
              'Less than $25k',
              '$25k - $50k',
              '$50k - $100k',
              '$100k - $250k',
              '$250k - $500k',
              '$500k - $1M',
              'More than $1M',
            ],
            note: 'Optional',
            center: true,
            showArrowIcons: true,
          },
        },
        {
          type: Dropdown,
          typeName: 'Dropdown',
          name: 'annual_income',
          label: 'What is your total annual income?',
          props: {
            showLabel: true,
            styling: 'dropdown-inline-left',
            values: ['Less than $25k', '$25k - $50k', '$50k - $100k', 'More than $100k'],
            note: 'Optional',
            center: true,
            showArrowIcons: true,
          },
        },
        {
          type: TextNumberField,
          typeName: 'TextNumberField',
          name: 'social_security_number',
          label: 'Social Security Number',
          required: true,
          props: {
            style: {
              paddingTop: '20px',
            },
          },
        },
        {
          type: Dropdown,
          typeName: 'Dropdown',
          name: 'citizenship_country',
          label: 'Citizenship Country',
          required: true,
          props: {
            showLabel: true,
            styling: 'dropdown-inline-left',
            center: true,
            values: listCountryNames(true),
            showArrowIcons: true,
          },
        },
        {
          type: YesNoToggleWithLabel,
          typeName: 'YesNoToggleWithLabel',
          name: 'is_control_person',
          label: 'A director, 10% shareholder, or a senior officer of a <b>publicly traded company.</b>',
          required: true,
          props: {
            values: ['YES', 'NO'],
            toggleAdditionalInfoFieldsIf: 'YES',
            additionalInfoFields: [
              {
                type: TextField,
                typeName: 'TextField',
                name: 'company_symbols',
                label: 'Stock Symbol of Company',
                required: true,
                props: {},
              },
            ],
          },
        },
        {
          type: YesNoToggleWithLabel,
          typeName: 'YesNoToggleWithLabel',
          name: 'is_affiliated_exchange_or_finra',
          label: 'Employed by or associated with a <b>member firm of</b> a <b>stock exchange</b> or <b>FINRA.</b>',
          required: true,
          props: {
            values: ['YES', 'NO'],
            toggleAdditionalInfoFieldsIf: 'YES',
            additionalInfoFields: [
              {
                type: TextField,
                typeName: 'TextField',
                name: 'firm_name',
                label: 'Name of Firm',
                required: true,
                props: {},
              },
            ],
          },
        },
        {
          type: YesNoToggleWithLabel,
          typeName: 'YesNoToggleWithLabel',
          name: 'is_politically_exposed',
          label: 'A senior military, government, or political official in a <b>non-US country.</b>',
          required: true,
          props: {
            values: ['YES', 'NO'],
            toggleAdditionalInfoFieldsIf: 'YES',
            additionalInfoFields: [
              {
                type: TextField,
                typeName: 'TextField',
                name: 'immediate_family',
                label: 'Full Name of Person',
                required: true,
                props: {},
              },
              {
                type: TextField,
                typeName: 'TextField',
                name: 'political_organization',
                label: 'Name of Organization',
                required: true,
                props: {},
              },
            ],
          },
        },
      ],
      formData: {},
      focusedField: null,
      updatingAccount: false,
      accountUpdated: false,
      focusNote: {
        contact_street_address: 'Must be a residential address. P.O. boxes are not accepted.',
        contact_country: 'Address must be in the USA.',
      },
      errors: {},

      loading: true,
      previouslyLoaded: false,

      _showUnsubscribedMessage: false,
      _isUnsubscribing: false,
    };
  }

  componentDidMount() {
    const userIsLiveTrading = this.props.currentUser.is_live_trading;
    if (!userIsLiveTrading) {
      this.props.navigate('users/edit');
      return false;
    }

    const requests = Promise.all([this.props.actions.getLiveTradingAccount()]);
    requests.then(() => {
      this.setState(() => ({
        loading: false,
      }));
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.liveAccount.liveTrading.loaded && !this.state.previouslyLoaded) {
      this.setState(() => ({
        previouslyLoaded: true,
      }));
      setExistingFormData.call(this, nextProps.liveAccount.liveTrading);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.updatingAccount && !this.state.updatingAccount) {
      this.setState({ accountUpdated: true });
      setTimeout(() => {
        this.setState({ accountUpdated: false });
      }, 5000);
    }
  }

  handleUpdate = () => {
    handleUpdate.call(this);
  };

  returnInputDefaultProps() {
    return {
      handleFocus: handleFocus.bind(this),
      handleBlur: handleBlur.bind(this),
      handleToggleClick: handleToggleClick.bind(this),
      handleChange: handleChange.bind(this),
      isFocused: function (name) {
        return this.state.focusedField === name;
      }.bind(this),
      getFocusNote: function (name) {
        if ('focusNote' in this.state) {
          return this.state.focusNote[name];
        }
      }.bind(this),
      getErrorMessage: function (name) {
        return this.state.errors[name];
      }.bind(this),
      getValue: function (name) {
        return this.state.formData[name];
      }.bind(this),
    };
  }

  render() {
    const receivedFormDataFromServer =
      'liveTrading' in this.props.liveAccount && !this.props.liveAccount.liveTrading.loaded;
    if (receivedFormDataFromServer || this.state.loading) {
      return <PageLoading style={{ zIndex: 10000 }} flatStyle={true} />;
    }
    const nameInputs = this.state.inputs.slice(0, 2);
    const contactInputs = this.state.inputs.slice(2, 9);
    const investorProfileInputs = this.state.inputs.slice(9, 11);
    const identityInputs = this.state.inputs.slice(11, 13);
    const disclosuresInputs = this.state.inputs.slice(13, 16);
    return (
      <Wrapper className="live-trading-edit-container">
        <div className="live-trading-edit-account-heading">
          <h3>Update Account Information</h3>
        </div>
        <div className="sign-up-form-message">
          <div className="icon-left-text-container">
            <div>
              <i style={{ lineHeight: '72px', marginRight: '-25px' }} className="fa fa-drivers-license-o fa-2x" />
            </div>
            <div>
              <p className="secondary-heading-text-color">
                Your full legal name as it appears on your license or passport is required.
              </p>
            </div>
          </div>
          <div className="icon-left-text-container">
            <div>
              <i style={{ lineHeight: '72px', marginRight: '-25px' }} className="fa fa-lock fa-2x" />
            </div>
            <div>
              <p className="secondary-heading-text-color">
                This information will be kept private and it will never appear on your public profile.
              </p>
            </div>
          </div>
        </div>
        <div className="live-trading-edit-contact-container">
          <div className="live-trading-edit-account-sub-heading">
            <h4 className="secondary-heading-text-color">Contact</h4>
          </div>
          <div className="live-trading-edit-account-form-container">
            {nameInputs.map((input, i) =>
              createInputComponent(input, this.returnInputDefaultProps.call(this), input.name)
            )}
            {contactInputs.map((input, i) =>
              createInputComponent(input, this.returnInputDefaultProps.call(this), input.name)
            )}
          </div>
        </div>
        <div className="live-trading-edit-investor-profile-container">
          <div className="live-trading-edit-account-sub-heading">
            <h4 className="secondary-heading-text-color">Assets</h4>
          </div>
          <div className="live-trading-edit-account-form-container">
            {investorProfileInputs.map((input, i) =>
              createInputComponent(input, this.returnInputDefaultProps.call(this), input.name)
            )}
          </div>
        </div>
        <div className="live-trading-edit-identity-container">
          <div className="live-trading-edit-account-sub-heading">
            <h4 className="secondary-heading-text-color">Identity</h4>
          </div>
          <div className="live-trading-edit-account-form-container">
            {identityInputs.map((input, i) =>
              createInputComponent(input, this.returnInputDefaultProps.call(this), input.name)
            )}
          </div>
        </div>
        <div className="live-trading-edit-disclosures-container">
          <div className="live-trading-edit-account-sub-heading">
            <h4 className="secondary-heading-text-color">Disclosures</h4>
          </div>
          <div className="live-trading-edit-account-form-container">
            <div className="sign-up-form-message">
              <p className="secondary-text-color">I'm (or a member of my immediate family is)...</p>
            </div>
            <div className="disclosures-form-container">
              {createInputComponent(
                disclosuresInputs[0],
                this.returnInputDefaultProps.call(this),
                disclosuresInputs[0].name
              )}
              {createInputComponent(
                disclosuresInputs[1],
                this.returnInputDefaultProps.call(this),
                disclosuresInputs[1].name
              )}
              {createInputComponent(
                disclosuresInputs[2],
                this.returnInputDefaultProps.call(this),
                disclosuresInputs[2].name
              )}
            </div>
          </div>
        </div>
        <div className="edit-account-update-btn-container">
          {!this.state.updatingAccount ? (
            <SubmitButton customClass="edit-account-update-btn" text="Save" onClick={this.handleUpdate} />
          ) : (
            <SubmitButton customClass="edit-account-update-btn btn-grey-disabled" text="Save" onClick={() => false} />
          )}
          {this.state.updatingAccount && (
            <div className="updating-account-message-container">
              <i
                className={'circle-loading-spinner circle-loading-spinner-small'}
                style={{ verticalAlign: 'middle' }}
              ></i>
              <span style={{ verticalAlign: 'middle', marginLeft: '8px' }}>{'Updating Account...'}</span>
            </div>
          )}
          {this.state.accountUpdated && (
            <div className="updating-account-message-container">
              <i
                className={`
                    fa
                    ${this.state.updateError ? 'fa-times-circle' : 'fa-check-square-o'}
                    ${this.state.updateError ? 'failure-text-color' : 'success-text-color'}
                  `}
                style={{
                  verticalAlign: 'middle',
                }}
              ></i>
              <span style={{ verticalAlign: 'middle', marginLeft: '8px' }}>{this.state.updateMessage}</span>
            </div>
          )}
        </div>
        <div id="bottom"></div>
      </Wrapper>
    );
  }

  _returnCurrentUser = () => this.props.currentUser;
}

const mapStateToProps = (state) => {
  return {
    liveAccount: state.liveAccount,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateApexAccount);
