import React from 'react';
import { Container, WarningNotice } from '@src/main/components/ui';
import { Modal } from '@src/main/components/layout';
import { Body1, Body5 } from '@src/main/lib/nvstr-common-ui.es';
import { FlatButton } from '@src/main/components/buttons';
import styled from 'styled-components';
import { convertHexToRGBA } from '@src/main/lib/nvstr-utils.es';
import { PRODUCT_NAME } from '@src/appConfig';
import { useSecurity } from '@src/main/hooks/securities/useSecurity';
import Space from '@src/components/UI/Space';
import { SupportContact } from '@src/constants/types/support';
import { SupportEmail } from '@src/components/UI/messaging/ContactSupport';

const WarningWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.themeColors.warning};
  border-radius: 5px;
  padding: 3px 8px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => convertHexToRGBA(theme.themeColors.text, 0.1)};
  }

  svg {
    opacity: 1 !important;
    * {
      opacity: 1 !important;
      fill: ${({ theme }) => theme.themeColors.warning} !important;
    }
  }

  * {
    color: ${({ theme }) => theme.themeColors.text} !important;
  }
`;

const ModalContent = styled.div`
  max-width: 500px;
`;
const SecurityRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-top: 16px;

  &:last-child {
    padding-top: 0;
  }
`;

const SecurityRow = ({ securityId }) => {
  const { symbol, name } = useSecurity(securityId);

  return (
    <SecurityRowWrapper row verticallyCenter top={16}>
      <Body1 bold>{symbol}</Body1>
      <Space />
      <Space />
      <Body5>{name}</Body5>
    </SecurityRowWrapper>
  );
};

export const MissingPricingBanner = ({ missingPricesForSecurityIds }) => {
  const [showingModal, setShowingModal] = React.useState(false);
  const bannerMessage = 'Portfolio value excludes positions where pricing is unavailable. Details here.';

  const handleModalDismiss = () => {
    setShowingModal(false);
  };

  const handleClick = () => {
    setShowingModal(true);
  };
  return (
    <Container top={4}>
      {showingModal && (
        <Modal onDismiss={handleModalDismiss}>
          <ModalContent>
            <Container>
              <Body5>
                We are unable to get accurate pricing for the{' '}
                {missingPricesForSecurityIds.length > 1
                  ? 'following securities in your portfolio'
                  : 'security listed below'}{' '}
                because {missingPricesForSecurityIds.length > 1 ? 'they are' : 'it is'} either no longer available to
                trade on {PRODUCT_NAME} or under going a corporate action and is temporarily unavailable.
              </Body5>
            </Container>
            <Container top={24}>
              {missingPricesForSecurityIds.map((sid) => (
                <SecurityRow key={sid} securityId={sid} />
              ))}
            </Container>
            <Container top={24}>
              <Body5>
                Please contact <SupportEmail /> with any questions or if you wish to exit your position.
              </Body5>
            </Container>
            <Container top={24} centerAll>
              <FlatButton transparent onClick={handleModalDismiss}>
                Dismiss
              </FlatButton>
            </Container>
          </ModalContent>
        </Modal>
      )}
      <WarningWrapper onClick={handleClick}>
        <WarningNotice message={bannerMessage} />
      </WarningWrapper>
    </Container>
  );
};
