import { START_AJAX_REQUEST, END_AJAX_REQUEST } from '../constants';

const defaultState = {
  security: {},
  user: {},
};

let requestName, objectName, ids;

const updateState = (updateObj, status, state) => {
  const { objectName, requestName, ids } = updateObj;
  const copyState = {
    ...state,
    [objectName]: { ...(state[objectName] || {}) },
  };

  if (objectName in copyState) {
    ids.forEach((id) => {
      if (id in copyState[objectName]) {
        status === 'start'
          ? copyState[objectName][id].push(requestName)
          : (copyState[objectName][id] = copyState[objectName][id].filter((r) => requestName !== r));
      } else {
        if (status === 'start') {
          copyState[objectName][id] = [requestName];
        }
      }
    });
  } else {
    if (status === 'start') {
      copyState[objectName] = {};
      ids.forEach((id) => {
        copyState[objectName][id] = [requestName];
      });
    }
  }
  return copyState;
};

export default function openAjaxRequestsReducer(state = defaultState, action) {
  switch (action.type) {
    case START_AJAX_REQUEST:
      return updateState(action.payload, 'start', state);

    case END_AJAX_REQUEST:
      return updateState(action.payload, 'end', state);

    default:
      return state;
  }
}
