import React from 'react';
import { getTimeNowEpoch } from '../../helpers/timeHelpers';
import { consoleLog } from '../../helpers/devToolHelpers';

/*
  Purpose: use if you want something to render after a delay period of time, for instance if a request is fast (~300ms) no need to flash an unreadable loading message

  <RenderAfterDelay
    shouldRender -> (bool) => show children
    timeDelayToRender -> (milliseconds) -> time delay length to wait until rendering children
    minTimeLengthToRender -> (milliseconds) -> minimum time length children will be rendered, will prevent shouldRender from stopping the render of the children before this length of time
  >
    {children}
  </RenderAfterDelay>
*/

class RenderAfterDelay extends React.Component {
  constructor() {
    super();
    this.state = {
      _shouldRenderChildren: false,
      _timeStartedRunningEpoch: null,

      _renderChildrenTimeout: null,
      _stopRenderChildrenTimeout: null,
    };

    this._useDebugLogging = false;
  }

  componentDidMount() {
    if (this._isRenderRequested()) {
      this._debugLog('onRenderStart');
      this.onRenderStart();
    }
  }

  componentDidUpdate(prevProps) {
    if (this._isRenderRequested() && !this._isRenderRequested(prevProps)) {
      this._debugLog('onRenderStart');
      this.onRenderStart();
    }
    if (!this._isRenderRequested() && this._isRenderRequested(prevProps)) {
      this._debugLog('onRenderStop');
      this.onRenderStop();
    }
  }

  render() {
    this._debugLog(['render', this.state, this.props]);
    return this._shouldRenderChildren() ? this.props.children : null;
  }

  onRenderStart = () => {
    this._resetToDefaultState();
    this._createAndSetRenderChildrenTimeout();
  };

  onRenderStop = () => {
    if (this._shouldRenderChildren()) {
      this.handleStopRenderChildren();
    } else {
      this._resetToDefaultState();
    }
  };

  // API

  _resetToDefaultState = () => {
    this._clearRenderChildrenTimeout();
    this._clearStopRenderChildrenTimeout();
    this.setState(() => ({
      _shouldRenderChildren: false,
      _renderChildrenTimeout: null,
      _stopRenderChildrenTimeout: null,
    }));
  };

  handleStartRenderChildren = () => {
    this._debugLog('showing children');
    this._showChildren();
  };

  handleStopRenderChildren = () => {
    this._debugLog([
      'hiding children - requested',
      this._didMinRenderTimeLengthPass(),
      this._getTimeLengthChildrenRendered(),
      this._getRenderMinLengthTime(),
    ]);
    if (this._didMinRenderTimeLengthPass()) {
      this._debugLog('hiding children - min time passed');
      this._hideChildren();
    } else {
      this._debugLog('not hiding children - min time not passed');
      this._debugLog(['time left', this._getTimeRemainingForForceRender()]);
      this._createAndSetStopRenderChildrenTimeout(this._getTimeRemainingForForceRender());
    }
  };

  _didMinRenderTimeLengthPass = () =>
    this._shouldRenderMinLengthTime() ? this._getTimeLengthChildrenRendered() > this._getRenderMinLengthTime() : true;

  _getTimeLengthChildrenRendered = () => getTimeNowEpoch() - this._getTimeChildrenRenderStarted();

  _getTimeRemainingForForceRender = () => this._getRenderMinLengthTime() - this._getTimeLengthChildrenRendered();

  _showChildren = () =>
    this.setState(() => ({
      _shouldRenderChildren: true,
      _timeStartedRunningEpoch: getTimeNowEpoch(),
    }));

  _hideChildren = () => {
    this._debugLog('hiding children in state');
    return this.setState(() => ({ _shouldRenderChildren: false }));
  };

  _createAndSetRenderChildrenTimeout = () =>
    this._saveToState({
      _renderChildrenTimeout: this._createRenderChildrenTimeout(),
    });

  _createAndSetStopRenderChildrenTimeout = (timeLength) =>
    this._saveToState({
      _stopRenderChildrenTimeout: this._createStopRenderChildrenTimeout(timeLength),
    });

  _clearRenderChildrenTimeout = () => clearTimeout(this._getRenderChildrenTimeout());

  _clearStopRenderChildrenTimeout = () => clearTimeout(this._getStopRenderChildrenTimeout());

  _getTimeChildrenRenderStarted = () => this.state._timeStartedRunningEpoch;

  _getTimeDelayLengthToRenderChildren = () => this.props.timeDelayToRender;

  _getMinTimeLengthToRenderChildren = () => this.props.minTimeLengthToRender;

  _getRenderChildrenTimeout = () => this.state._renderChildrenTimeout;

  _getStopRenderChildrenTimeout = () => this.state._stopRenderChildrenTimeout;

  _isStopRenderTimeoutRunning = () => this.state._stopRenderChildrenTimeout;

  _shouldRenderChildren = () => this.state._shouldRenderChildren;

  _shouldRenderMinLengthTime = () => this._getRenderMinLengthTime() > 0;

  _getRenderMinLengthTime = () => this.props.minTimeLengthToRender;

  _isRenderRequested = (props = this.props) => props.shouldRender;

  // Internals

  _createRenderChildrenTimeout = () =>
    this._createTimeout(this.handleStartRenderChildren, this._getTimeDelayLengthToRenderChildren());

  _createStopRenderChildrenTimeout = (timeLength) => this._createTimeout(this._hideChildren, timeLength);

  _createTimeout = (cb, timeDelay) => setTimeout(cb, timeDelay);

  _saveToState = (data) => this.setState(() => data);

  _debugLog = (message) => {
    if (this._useDebugLogging) {
      consoleLog(message);
    }
  };
}

export default RenderAfterDelay;
