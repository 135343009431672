import React from 'react';
import { connect } from 'react-redux';
import LinkTo from '../../../Links/LinkTo';
import { truncateName, snakeToHyphens } from '../../../../helpers/generalHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';
import styled from 'styled-components';
import { formatSecurityPropertyValue } from '../../../../helpers/securitiesHelpers';
import Minus from '../../../../main/icons/svg/Minus';
import ChevronUp from '../../../../main/icons/svg/ChevronUp';
import ChevronDown from '../../../../main/icons/svg/ChevronDown';
import { usePriceData } from '../../../../main/hooks/securities/usePriceData';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0 !important;
  padding: 0 0 0 0;
  line-height: 1;

  * {
    font-size: ${({ isSmall }) => (isSmall ? '12px' : '12px') + ' !important'};
  }
`;
const PriceWrapper = styled.div`
  color: ${({ theme }) => theme.themeColors.text};
`;
const ChangeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    height: 24px;
    width: 24px;
  }

  * {
    color: ${({ theme, value }) =>
      value === 0 ? theme.themeColors.text : value > 0 ? theme.themeColors.positive : theme.themeColors.negative};

    fill: ${({ theme, value }) =>
      value === 0 ? theme.themeColors.text : value > 0 ? theme.themeColors.positive : theme.themeColors.negative};
  }
`;

const ChangeIcon = ({ value }) => {
  if (value === 0) {
    return <Minus />;
  }
  if (value > 0) {
    return <ChevronUp />;
  }
  if (value < 0) {
    return <ChevronDown />;
  }

  return null;
};

const PriceWithChange = ({ currentPrice, priceChangePercent, isSmall }) => {
  const formattedPrice = formatSecurityPropertyValue(currentPrice, 'current_price');
  const formattedPriceChange = formatSecurityPropertyValue(priceChangePercent, 'current_price_change_percent');
  return (
    <Wrapper isSmall={isSmall}>
      {isNaN(currentPrice) || currentPrice === null ? (
        <PriceWrapper>{'...'}</PriceWrapper>
      ) : (
        <PriceWrapper>{formattedPrice}</PriceWrapper>
      )}
      {isNaN(currentPrice) || currentPrice === null ? (
        <PriceWrapper>{'...'}</PriceWrapper>
      ) : (
        <ChangeWrapper value={priceChangePercent}>
          <span>
            <ChangeIcon value={priceChangePercent} />
          </span>
          <span>{formattedPriceChange}</span>
        </ChangeWrapper>
      )}
    </Wrapper>
  );
};

const Cell = ({ securityId, name, symbol }) => {
  const { price: current_price, changePercent: current_price_change_percent } = usePriceData(securityId);
  return (
    <span className="security-table-identifier">
      <span className="security-table-symbol  ">{symbol}</span>
      <span className="security-table-company-name truncate-text-overflow secondary-text-color">
        {truncateName(name, $(window).width() > 525 ? 33 : 20)}
      </span>
      <span className={`multi-text-box-inline flex-justify-left`}>
        <PriceWithChange
          currentPrice={current_price}
          priceChangePercent={current_price_change_percent}
          isSmall={true}
        />
      </span>
    </span>
  );
};
export class SecurityIdentifierWithCurrentPriceCell extends React.PureComponent {
  render() {
    const columnKeyName = this.props.column.keyName;
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? (
          this.props.loadingCellComponent
        ) : (
          <LinkTo
            to={this._returnLinkForHandlingClick()}
            className={''}
            additionalClickAction={this.props.logSecurityPanelOpenClick}
            child={this._renderCellContent()}
            noTextDecoration
          />
        )}
      </td>
    );
  }

  _isLoading = () => {
    const { securityId } = this.props;
    const security = this.props.securities.lookup[securityId];
    return !(securityId in this.props.securities.lookup) || !security.symbol || !security.name;
  };

  _returnLinkForHandlingClick = () => {
    const securityId = this.props.securityId;
    const openSecurityPanelConfig = {
      securityId,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    const securityId = this.props.security.security_id;
    const security = this.props.securities.lookup[securityId];
    const { name, symbol } = security;
    return <Cell securityId={securityId} symbol={symbol} name={name} />;
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    securities: state.securities,
  };
};

export default connect(mapStateToProps)(SecurityIdentifierWithCurrentPriceCell);
