import React from 'react';
import Icon from '../misc/Icon';

import { formatDataValue } from '../../helpers/securitiesHelpers';
import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import styled from 'styled-components';

const IconWrapper = styled.div``;
const ValueWrapper = styled.div``;
const Wrapper = styled.div`
  ${ValueWrapper} {
    color: ${({ increasing, neutral, decreasing, theme }) =>
      increasing ? theme.themeColors.positive : decreasing ? theme.themeColors.negative : theme.themeColors.neutral};
  }
  ${IconWrapper} {
    color: ${({ increasing, neutral, decreasing, theme }) =>
      increasing ? theme.themeColors.positive : decreasing ? theme.themeColors.negative : theme.themeColors.neutral};
    fill: ${({ increasing, neutral, decreasing, theme }) =>
      increasing ? theme.themeColors.positive : decreasing ? theme.themeColors.negative : theme.themeColors.neutral};
  }
`;

const NumberWithChangeIndicator = (props) => {
  let {
    value,
    valueFormat,
    customClass,
    allowFractional,

    prefix,
    showPlus,

    neutralColorClassName,
    increaseColorClassName,
    decreaseColorClassName,

    neutralIcon,
    increaseIcon,
    decreaseIcon,

    coloredText,
    customDisplayValue,
    customIconCompareValue,

    forceTextColorClassName,

    additionalClasses,
  } = props;

  const iconNeutral = neutralIcon || 'fa-minus';
  const iconIncrease = increaseIcon || 'fa-sort-asc';
  const iconDecrease = decreaseIcon || 'fa-sort-desc';

  neutralColorClassName = neutralColorClassName || 'no-change-text-color';
  increaseColorClassName = increaseColorClassName || 'increase-change-text-color';
  decreaseColorClassName = decreaseColorClassName || 'decrease-change-text-color';

  const _determineIconType = (value) => {
    return value === 0 ? iconNeutral : value >= 0 ? iconIncrease : iconDecrease;
  };
  const _determineColorClassName = (value) => {
    return value === 0 ? neutralColorClassName : value >= 0 ? increaseColorClassName : decreaseColorClassName;
  };

  let displayValue = customDisplayValue || formatDataValue(value, valueFormat);

  if (allowFractional) {
    displayValue = value.toLocaleString('en-US', { maximumFractionDigits: 4 });
  }

  const neutralStyle = {
    fontSize: '12px',
    top: '2px',
  };

  const increaseStyle = {
    top: '1px',
  };

  const decreaseStyle = {
    top: '-7px',
  };

  const defaultStyleValueDependent = value === 0 ? neutralStyle : value >= 0 ? increaseStyle : decreaseStyle;

  const defaultStyle = {
    fontSize: '20px',
    marginRight: '2px',
    position: 'absolute',
    ...defaultStyleValueDependent,
  };
  const style = {
    ...defaultStyle,
  };

  const iconTypeAndColorComparisonValue = isUndefinedOrNull(customIconCompareValue) ? value : customIconCompareValue;
  const icon = _determineIconType(iconTypeAndColorComparisonValue);
  const coloredTextStyle = coloredText ? _determineColorClassName(iconTypeAndColorComparisonValue) : '';
  const textStyleClassName = forceTextColorClassName ? forceTextColorClassName : coloredTextStyle;

  prefix = prefix || (showPlus && value > 0 ? '+' : '');

  return (
    <Wrapper
      increasing={value > 0}
      neutral={value === 0}
      decreasing={value < 0}
      className={`number-with-change-indicator-container ${customClass || ''} ${additionalClasses || ''}`}
    >
      {!props.hideIcon && (
        <IconWrapper
          className={`number-with-change-indicator-icon ${_determineColorClassName(iconTypeAndColorComparisonValue)}`}
        >
          <Icon icon={icon} style={style} />
        </IconWrapper>
      )}
      <ValueWrapper className={`number-with-change-indicator-value ${textStyleClassName} text-node`}>
        {`${prefix || ''}${displayValue}${props.suffix || ''}`}
      </ValueWrapper>
    </Wrapper>
  );
};

export default NumberWithChangeIndicator;
