import { getStartOfDayTodayEpochValue } from './timeHelpers';
import { logMetricsTrackingEvent } from '../actions';
import { parseQueryString } from './routerHelpers';

export const findIndexOfThoughtInThoughts = (id, thoughts) => {
  let index = null;
  for (let i = 0; i < thoughts.length; i++) {
    let thoughtId = thoughts[i].id;
    if (id === thoughtId) {
      index = i;
    }
  }
  return index;
};

export const findThoughtsForUser = (userId, thoughts) => {
  const userAgreedThoughts = thoughts.filter((thought) => thought.agree_user_ids.includes(userId));
  const userDisagreedThoughts = thoughts.filter((thought) => thought.disagree_user_ids.includes(userId));
  const allThoughts = [...userAgreedThoughts, ...userDisagreedThoughts];
  return allThoughts;
};

export const parseThoughtsIntoSecurities = (thoughts) => {
  const thoughtsBySecurity = {};

  thoughts.forEach((thought) => {
    let securityId = thought.security_id;
    if (!thoughtsBySecurity[securityId]) {
      thoughtsBySecurity[securityId] = [];
    }
    thoughtsBySecurity[securityId].push(thought);
  });

  return thoughtsBySecurity;
};

export const separateThoughtsByProAndCon = (thoughts) => {
  const pros = sortThoughtsByMostOpinions(thoughts.filter((thought) => thought.thought_type.id === 0));
  const cons = sortThoughtsByMostOpinions(thoughts.filter((thought) => thought.thought_type.id === 1));

  return { pros, cons };
};

export const sortThoughtsByCreatedFirst = (thoughts, userCreatedThoughtIds) => {
  const ids = userCreatedThoughtIds.sort((a, b) => b - a);
  const userCreatedThoughts = thoughts.filter((t) => ids.indexOf(t.id) >= 0);
  const nonUserCreatedThoughts = thoughts.filter((t) => ids.indexOf(t.id) < 0);
  return [...userCreatedThoughts, ...nonUserCreatedThoughts];
};

export const separateThoughtByUsersWhoAgreeAndDisagree = (thought) => {
  const agree = thought.agree_user_ids;
  const disagree = thought.disagree_user_ids;

  return { agree, disagree };
};

// needs testing
export const sortThoughtsByMostOpinions = (thoughts) => {
  // needs testing
  const sortedThoughts = [];
  const thoughtsWithCounts = {};
  thoughts.forEach((thought) => {
    thoughtsWithCounts[thought.id] = {
      id: thought.id,
      agreeCount: thought.agree_count,
      disagreeCount: thought.disagree_count,
      differential: thought.agree_count - thought.disagree_count,
      totalCount: thought.agree_count + thought.disagree_count,
    };
  });

  const factor = 'differential';
  Object.keys(thoughtsWithCounts).forEach((id) => {
    let thought = {
      id,
      [factor]: thoughtsWithCounts[id][factor],
      ...thoughts.filter((t) => t.id.toString() === id)[0],
    };
    sortedThoughts.push(thought);
  });

  return sortedThoughts.sort(function (a, b) {
    return b[factor] - a[factor];
  });
};

export const convertAgreeToBoolean = (opinion) => {
  if (opinion === 'agree') {
    return true;
  } else if (opinion === 'disagree') {
    return false;
  } else {
    return null;
  }
};

export const convertBooleanToAgree = (opinion) => {
  if (opinion === true) {
    return 'agree';
  } else if (opinion === false) {
    return 'disagree';
  } else {
    return 'neutral';
  }
};

export const returnOpinionForThought = (thought) => {
  return convertBooleanToAgree(thought.agree);
};

export const doesUserDisagreeWithThought = (thought) => {
  return returnOpinionForThought(thought) === 'disagree';
};

export const updateThought = (thought, opinion, currentUserId) => {
  if (thought.agree === convertAgreeToBoolean(opinion)) {
    return thought;
  }

  if (!currentUserId) {
    console.error('No user_id found to assign to thought');
  }

  const oppositeOpinion = opinion === 'agree' ? 'disagree' : 'agree';
  const newThought = {
    ...thought,
    agree: convertAgreeToBoolean(opinion),
    [`${opinion.toLowerCase()}_user_ids`]: [...thought[`${opinion.toLowerCase()}_user_ids`], currentUserId],
    [`${oppositeOpinion.toLowerCase()}_user_ids`]: thought[`${oppositeOpinion.toLowerCase()}_user_ids`].filter(
      (id) => id !== currentUserId
    ),
  };

  switch (opinion) {
    case 'agree':
      newThought.agree_count += 1;

      if (thought.agree === false) {
        newThought.disagree_count -= 1;
      }
      break;
    case 'disagree':
      newThought.disagree_count += 1;

      if (thought.agree === true) {
        newThought.agree_count -= 1;
      }
      break;

    default:
      console.error('Error in updating thought: Thought was changed to neutral or value was undefined/null ');
      break;
  }

  // newThought.differential = newThought.agree_count - newThought.disagree_count;
  return newThought;
};

export const getLinkDomain = (url) => {
  if (!url || typeof url != 'string') {
    return null;
  } else {
    const httpRemoved = url.split('//')[url.split('//').length - 1];
    const wwwRemoved = httpRemoved.split('www.')[httpRemoved.split('www.').length - 1];
    return wwwRemoved.split('/')[0].split('#')[0].split('?')[0];
  }
};

export const removeUrlFromThought = (thoughtText) => {
  const url_regex =
    /http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/gim;
  const wordArray = thoughtText.replace(/(\r\n|\n|\r)/gm, ' ').split(' ');
  const urlArray = [];

  let regexMatch;
  for (let i = 0; i < wordArray.length; i++) {
    regexMatch = wordArray[i].match(url_regex);
    if (regexMatch) {
      urlArray.push(newThoughtFormatThoughtLink(wordArray[i], regexMatch));
    }
  }
  const url = urlArray[0];
  const replaceString = thoughtText.indexOf(url + ' ') === 0 ? '' : ' ';
  return thoughtText.indexOf(url + ' ') >= 0 ? thoughtText.replace(url + ' ', replaceString) : thoughtText;
};

export const removeAdditionalUrls = (value) => {
  const urls = getUrlsFromString(value);
  if (urls.length < 2) {
    return value;
  }
  let sanitizedValue = value;
  for (let i = 1; i < urls.length; i++) {
    let url = urls[i];
    sanitizedValue = sanitizedValue.replace(' ' + url, '');
  }
  return sanitizedValue;
};

function newThoughtFormatThoughtLink(link, regexMatch) {
  // remove anything in-front of http
  const urlSplitByRegexMatch = link.split(regexMatch);
  if (urlSplitByRegexMatch.length > 1) {
    link = regexMatch + urlSplitByRegexMatch[1];
  }

  // remove spaces and new lines
  return link
    .replace(/(\r\n|\n|\r)/gm, ' ')
    .split(' ')[0]
    .trim();
}

export const getUrlsFromString = (string) => {
  const url_regex =
    /http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/gim;
  const wordArray = string.replace(/(\r\n|\n|\r)/gm, ' ').split(' ');
  const urlArray = [];
  let regexMatch;
  for (let i = 0; i < wordArray.length; i++) {
    regexMatch = wordArray[i].match(url_regex);
    if (regexMatch) {
      urlArray.push(newThoughtFormatThoughtLink(wordArray[i], regexMatch));
    }
  }
  return urlArray;
};

export const createNoPreviewImageEvent = (url) => {
  const currentPreviewImageUrl = url;

  const event = 'No Thought Link Preview Image';
  const properties = {
    link: currentPreviewImageUrl,
    linkDomain: getLinkDomain(currentPreviewImageUrl),
  };
  logMetricsTrackingEvent(event, properties)();
};

export const createThoughtPreviewImageNotHttpsEvent = (imageUrl, currentPreviewImageUrl) => {
  const properties = {
    link: currentPreviewImageUrl,
    linkDomain: getLinkDomain(currentPreviewImageUrl),
    imageUrl: imageUrl,
  };
  const event = 'Thought Link Preview Image Not HTTPS';
  logMetricsTrackingEvent(event, properties)();
};

export const createOpenGraphObject = (data) => {
  const image_url = data.images && data.images[0] ? data.images[0] : null;

  const ogo = {};
  ogo.images_url = image_url || null;
  ogo.url = data.url || null;
  ogo.image_link = image_url || null;
  ogo.original_link_url = data.url || null;
  ogo.description = data.description || null;
  ogo.title = data.title || null;
  ogo.open_graph_object_og_type = data.type || null;

  // stored but no longer implemented, could be used again in future
  ogo.open_graph_object_description = data.description || null;
  return ogo;
};

export const handleThoughtPreviewImageResults = function (data) {
  const ogo = createOpenGraphObject(data);

  this.setState((prevState) => ({
    ogo,
  }));
};

export const isLinkCurrentlyBeingPreviewed = (value, currentPreviewUrl) => {
  const urls = getUrlsFromString(value);
  return urls[0] === currentPreviewUrl;
};

export const isMoreThanOneLink = (value) => {
  const urls = getUrlsFromString(value);
  return isLinkInThought(value) ? urls.length > 1 : false;
};

export const isLinkInThought = (value) => {
  const urls = getUrlsFromString(value);
  return urls && urls.constructor === Array && urls[0];
};

export const updateValueAndCreateErrorForMultipleLinks = (value) => {
  const sanitizedValue = removeAdditionalUrls(value);
  const errorMsg = 'Please create separate thoughts for additional links';
  const error = errorMsg;
  return { sanitizedValue, error };
};

export const groupThoughtNotificationsBySecurityId = (thoughtNotifications) => {
  const groupedThoughtDataLookup = {};
  thoughtNotifications.forEach((thoughtNotif) => {
    const thought = thoughtNotif.data.thought;
    const securityId = thought.security_id;
    if (groupedThoughtDataLookup[securityId]) {
      groupedThoughtDataLookup[securityId].push(thought);
    } else {
      groupedThoughtDataLookup[securityId] = [thought];
    }
  });
  const securityIds = Object.keys(groupedThoughtDataLookup);
  const groupedThoughtData = securityIds.map((id) => ({
    securityId: id,
    thoughts: groupedThoughtDataLookup[id],
  }));
  return groupedThoughtData;
};

export const returnExcludedThoughtIdsFromSuggestions = (thoughtsStore) => thoughtsStore.thoughtsByIdSuggested;

export const returnSecurityThoughtsDataLookup = (thoughtsStore) => thoughtsStore.securityLookup;

export const returnSecurityThoughtsData = (thoughtsStore, securityId) =>
  returnSecurityThoughtsDataLookup(thoughtsStore)[securityId];

export const returnSecurityThoughtsLookup = (thoughtsStore, securityId) => {
  const securityThoughtsData = returnSecurityThoughtsData(thoughtsStore, securityId);
  if (!securityThoughtsData) {
    return {};
  }
  return securityThoughtsData.thoughtLookup;
};

export const returnAllThoughtsForSecurity = (thoughtsStore, securityId) => {
  if (!thoughtsStore || !thoughtsStore.securityLookup) {
    return throwError('Invalid thoughts store provided to returnAllThoughtsForSecurity', true);
  }
  if (!securityId) {
    return throwError('Invalid securityId provided to returnAllThoughtsForSecurity', true);
  }
  const securityThoughtsDataLookup = returnSecurityThoughtsDataLookup(thoughtsStore);
  const securityThoughtsData = returnSecurityThoughtsData(thoughtsStore, securityId);
  if (!securityThoughtsData) {
    return [];
  }
  const securityThoughtsLookup = returnSecurityThoughtsLookup(thoughtsStore, securityId);
  if (!securityThoughtsLookup) {
    return [];
  }
  const thoughts = [];
  const thoughtIds = Object.keys(securityThoughtsLookup);
  thoughtIds.forEach((id) => thoughts.push(securityThoughtsLookup[id]));
  return thoughts;
};

export const returnAllThoughtIdsForSecurity = (thoughtsStore, securityId) => {
  if (!thoughtsStore || !thoughtsStore.securityLookup) {
    return throwError('Invalid thoughts store provided to returnAllThoughtsForSecurity', true);
  }
  if (!securityId) {
    return throwError('Invalid securityId provided to returnAllThoughtsForSecurity', true);
  }
  const securityThoughtsDataLookup = returnSecurityThoughtsDataLookup(thoughtsStore);
  const securityThoughtsData = returnSecurityThoughtsData(thoughtsStore, securityId);
  if (!securityThoughtsData) {
    return [];
  }
  const securityThoughtsLookup = returnSecurityThoughtsLookup(thoughtsStore, securityId);
  if (!securityThoughtsLookup) {
    return [];
  }
  return Object.keys(securityThoughtsDataLookup);
};

export const returnAllAgreeUserIdsForThought = (thought) => thought.agree_user_ids || [];

export const returnAllDisagreeUserIdsForThought = (thought) => thought.disagree_user_ids || [];

export const returnAllUserIdsWhoHaveOpinionOnThought = (thought) => [
  ...returnAllAgreeUserIdsForThought(thought),
  ...returnAllDisagreeUserIdsForThought(thought),
];

export const returnAllUniqUserIdsWhoHaveOpinionsOnThoughtsForSecurity = (thoughtsStore, securityId) => {
  const thoughts = returnAllThoughtsForSecurity(thoughtsStore, securityId);
  const thoughtIdsDict = {};
  thoughts.forEach((thought) => {
    const ids = returnAllUserIdsWhoHaveOpinionOnThought(thought);
    ids.forEach((id) => {
      if (!thoughtIdsDict[id]) {
        thoughtIdsDict[id] = true;
      }
    });
  });
  return Object.keys(thoughtIdsDict).map((idString) => parseInt(idString));
};

export const isThoughtFromCommunityThoughtLeader = (thought) => thought.from_community_thought_leader;

export const getViewpointOrgNameFromThought = (thought) => thought.viewpoint_org_name;

export const getViewpointIdFromThought = (thought) => thought.viewpoint_org_id;

export const isThoughtFromViewpointAuthor = (thought) => getViewpointOrgNameFromThought(thought);

export const returnSecurityIdFromThought = (thought) => thought.security_id;

/******************************
  Thought Impression Helpers
*******************************/

const _storage = {};
const _mockStorage = () => ({
  getItem: (key) => _storage[key],
  setItem: (key, value) => (_storage[key] = value),
  removeItem: (key) => delete _storage[key],
});

const _startingStateOfThoughtImpressionData = '{}';

const _getStorage = () => window.sessionStorage || _mockStorage();

const _createThoughtImpressionKeyFromContext = (thoughtImpressionType) =>
  thoughtImpressionType + 'ThoughtImpressionData';

export const getThoughtImpressionSessionStorageRawDataByType = (thoughtImpressionType) =>
  _getStorage().getItem(_createThoughtImpressionKeyFromContext(thoughtImpressionType)) ||
  _startingStateOfThoughtImpressionData;

export const getThoughtImpressionByDayEpochLookupSessionStorageDataByType = (thoughtImpressionType) =>
  JSON.parse(getThoughtImpressionSessionStorageRawDataByType(thoughtImpressionType));

export const getThoughtImpressionIdListSessionStorageDataByType = (thoughtImpressionType, context) => {
  const byDayLookup = getThoughtImpressionByDayEpochLookupSessionStorageDataByType(thoughtImpressionType);
  const allThoughtIdsList = [];
  const datesEpochList = Object.keys(byDayLookup);
  datesEpochList.forEach((dateEpochKey) => {
    const contextDataLookup = byDayLookup[dateEpochKey];
    const contextThoughtImpressions = contextDataLookup[context];
    if (contextThoughtImpressions) {
      contextThoughtImpressions.forEach((id) => allThoughtIdsList.push(id));
    }
  });
  return allThoughtIdsList;
};

export const getTodaysThoughtImpressionContextLookupSessionStorageData = (thoughtImpressionType) => {
  return (
    getThoughtImpressionByDayEpochLookupSessionStorageDataByType(thoughtImpressionType)[
      getStartOfDayTodayEpochValue()
    ] || {}
  );
};

export const getTodaysThoughtImpressionContextIdListSessionStorageData = (thoughtImpressionType, context) => {
  return getTodaysThoughtImpressionContextLookupSessionStorageData(thoughtImpressionType)[context] || [];
};

export const clearThoughtImpressionSessionStorageDataByType = (thoughtImpressionType) =>
  _getStorage().removeItem(_createThoughtImpressionKeyFromContext(thoughtImpressionType));

export const returnSessionViewThoughtImpressionsListById = (context) =>
  getThoughtImpressionIdListSessionStorageDataByType('view', context);

export const wasThoughtImpressionRecorded = (thoughtImpressionType, thoughtId, context) => {
  return getThoughtImpressionIdListSessionStorageDataByType(thoughtImpressionType, context).includes(thoughtId);
};

export const saveImpressionDataToImpressionSessionStorage = (thoughtImpressionType, impressionData) => {
  _getStorage().setItem(_createThoughtImpressionKeyFromContext(thoughtImpressionType), JSON.stringify(impressionData));
  return true;
};

export const saveThoughtToImpressionSessionStorage = (thoughtImpressionType, thoughtId, context) => {
  const impressionData = getThoughtImpressionByDayEpochLookupSessionStorageDataByType(thoughtImpressionType, context);
  const todaysImpressionData = getTodaysThoughtImpressionContextLookupSessionStorageData(thoughtImpressionType);
  const todaysImpressionDataInContext = getTodaysThoughtImpressionContextIdListSessionStorageData(
    thoughtImpressionType,
    context
  );
  if (wasThoughtImpressionRecorded(thoughtImpressionType, thoughtId, context)) {
    return false;
  }
  todaysImpressionDataInContext.push(thoughtId);
  const updatedImpressionData = {
    ...impressionData,
    [getStartOfDayTodayEpochValue()]: {
      ...todaysImpressionData,
      [context]: todaysImpressionDataInContext,
    },
  };
  return saveImpressionDataToImpressionSessionStorage(thoughtImpressionType, updatedImpressionData);
};

export const saveImpressionToStorageIfUnrecorded = (thoughtImpressionType, thoughtId, context) => {
  if (!wasThoughtImpressionRecorded(thoughtImpressionType, thoughtId, context)) {
    return saveThoughtToImpressionSessionStorage(thoughtImpressionType, thoughtId, context);
  }
  return false;
};

export const getFocusThoughtFromUrl = (location) => {
  const queryString = location.search;
  const query = parseQueryString(queryString);
  return query.focus_thought_id ? parseInt(query.focus_thought_id, 10) : null;
};

export const getKarmaGainStructFromThought = (thought) => thought.community_points;

export const getKarmaValueFromKarmaGainStruct = (k) => k.value;

export const getMessageFromKarmaGainStruct = (k) => k.message;

export const didReachMaxDailyKarma = (k) => getMessageFromKarmaGainStruct(k) === 'Maximum points already earned today';
