import React from 'react';

import { LeftDashboardPanel } from '../Dashboard/components/LeftDashboardPanel';
import FeedContainer from '../Feed/FeedContainer';
import AddThoughtInFeed from '../Thoughts/AddThoughtInFeed';
import InviteFriendsMessaging from '../UI/InviteFriendsMessaging';
import SubscriptionPlanMessaging from '../UI/SubscriptionPlanMessaging';
import AskCTLMessaging from '../UI/AskCTLMessaging';

import { PanelCard } from '../UI/Layout/PanelCard';
import { BodyPanel } from '../UI/Layout/BodyPanel';
import MeasureSize from '../ExtraFunctionalityComponents/MeasureSize';
import { BaseScroll } from './BaseScroll';

import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import BannerController from '../../main/components/banners/BannerController';

export class DashboardAffixScroll extends React.Component {
  constructor() {
    super();
    this.state = {
      leftPanelHeight: 0,
      rightPanelHeight: 0,

      _affixedPanelName: null,
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  _returnDashboardContainerWidth = () => {
    const dashContainerElWidth = $('.dashboard-container').width();
    const offset = 8;
    const estimatedWidthOffset = 54 + offset; // offset for margin and frame padding plus a little extra to avoid a rounding issue
    const windowWidth = $(window).width();
    const vnavWidth = 185;

    // estimated width used if dash width is not available due to timing of mounting
    const estimatedDashDesktopWidth = windowWidth - vnavWidth - estimatedWidthOffset;
    const estimatedDashMobileWidth = windowWidth;

    const isTrueDashboardWidthAvailable = dashContainerElWidth && dashContainerElWidth > 0;
    const dashWidth = isTrueDashboardWidthAvailable
      ? dashContainerElWidth - offset
      : this._shouldShowDesktopDashboard()
      ? estimatedDashDesktopWidth
      : estimatedDashMobileWidth;
    return dashWidth;
  };

  _returnLeftBodyPanelWidth = () => Math.floor(this._returnDashboardContainerWidth() * 0.55);

  _returnRightBodyPanelWidth = () => {
    const leftMargin = 25;
    const bufferToPreventWrap = 2;
    const calculatedWidth = Math.floor(this._returnDashboardContainerWidth() * 0.45) - leftMargin - bufferToPreventWrap;

    return calculatedWidth > 499 ? 499 : calculatedWidth;
  };

  _returnAffixTopOffset = () => 150 - this._returnAffixTriggerOffset();

  _returnAffixTriggerOffset = () => 350; // used so affix triggers when panel offsetBottom is scrolled past x pixels

  _returnManualLeftPanelHeightOffset = () => 0;

  render() {
    const {
      className,
      layoutType,
      shouldShowInviteFriendsMessaging,
      shouldShowSubscriptionPlanMessaging,
      shouldShowAskCTLMessaging,
      getUpdatedNotifications,
      sendUpdatedLastSeenNotifTime,
      tabsRenderer,
    } = this.props;
    return (
      <div
        ref={(el) => (this.DashboardAffixScroll = el)}
        className={`affix-scroll-container ${className || ''} ${layoutType}`}
        style={this._returnAffixContainerStyle()}
      >
        {!this._shouldShowDesktopDashboard() && tabsRenderer()}
        <BaseScroll
          $scrollElement={$(window)}
          onScroll={this.handleScroll}
          style={this._returnBaseScrollContainerStyle()}
        >
          <LeftDashboardPanel
            isMobileActivePanel={this._isMobileTabActive('positions')}
            bodyPanelStyle={this._returnLeftPanelStyle()}
            affixWrapperStyle={{
              ...this._returnAffixStyle('leftPanel'),
              ...this._returnLeftPanelStyleWidth(),
            }}
            handleMutation={this._bindNameToHeightChangeHandler('leftPanel')}
          />
          <BodyPanel
            className={`right-dashboard-body-panel ${this._isMobileTabActive('feed') ? 'active' : ''}`}
            style={this._returnRightPanelStyle()}
          >
            <div
              className={`affix-wrapper`}
              style={{
                ...this._returnAffixStyle('rightPanel'),
                ...this._returnRightPanelStyleWidth(),
              }}
            >
              <MeasureSize onMutation={this._bindNameToHeightChangeHandler('rightPanel')} setInitialSize useThrottle>
                <BannerController />
                <PanelCard>
                  <AddThoughtInFeed
                    getUpdatedNotifications={getUpdatedNotifications}
                    sendUpdatedLastSeenNotifTime={sendUpdatedLastSeenNotifTime}
                    showingInviteFriendsMessaging={shouldShowInviteFriendsMessaging}
                  />
                </PanelCard>
                <PanelCard>
                  <FeedContainer
                    isVisible={this._isFeedTabShowing()}
                    ignoreInfiniteScroll={!this._isFeedTabShowing()}
                    dontGetNotifsOnMount
                  />
                </PanelCard>
              </MeasureSize>
            </div>
          </BodyPanel>
        </BaseScroll>
      </div>
    );
  }

  returnActiveTabPanelName = () => this.props.tabNameToPanelNameLookup[this._returnActiveTab()];

  returnActiveTabPanelHeight = () => {
    const panelName = this.returnActiveTabPanelName();
    const manualOffsetToSetPanelHigher = panelName === 'leftPanel' ? this._returnManualLeftPanelHeightOffset() : 0;
    return this.returnPanelNameHeight(panelName) - this._returnAffixPanelOffsetTop() - manualOffsetToSetPanelHigher;
  };

  _isFeedTabShowing = () =>
    this._shouldShowDesktopDashboard() || (!this._shouldShowDesktopDashboard() && this._returnActiveTab() === 'feed');

  _shouldShowDesktopDashboard = () => this.props.layoutType === 'desktop';

  _returnActiveTab = () => this.props.activeTab;

  _isMobileTabActive = (tabKey) => tabKey === this._returnActiveTab(); // do NOT use if desktop width there are no tabs

  _returnAffixStyle = (panelName) => {
    if (this._shouldShowDesktopDashboard() && this.returnAffixedPanelName() === panelName) {
      return {
        position: 'fixed',
        transform: 'translateZ(0)',
        margin: 'auto',
        top: `${this.props.windowHeight - this.returnPanelNameHeight(panelName) + this._returnAffixTopOffset()}px`,
      };
    } else {
      return {};
    }
  };

  handleScroll = (scrollData) => {
    if (!this.mounted) {
      return;
    }

    if (this.state.leftPanelHeight === 0 || this.state.rightPanelHeight === 0) {
      return;
    }

    if (this._didTriggerAffix(scrollData)) {
      this._setAffixedPanel(this._returnTriggeredAffixPanelName(scrollData));
    }
    if (this._didTriggerUnaffix(scrollData)) {
      this._setAffixedPanel(null);
    }
  };

  _didTriggerAffix = (scrollData) => {
    const panelNameToAffix = this._returnTriggeredAffixPanelName(scrollData);
    return !isUndefinedOrNull(panelNameToAffix) && this.returnAffixedPanelName() !== panelNameToAffix;
  };

  _returnTriggeredAffixPanelName = (scrollData) => {
    if (!scrollData || !('scrollDistance' in scrollData)) {
      console.error('Invalid scroll data provided to _returnTriggeredAffixPanelName');
    }
    let affixPanel = null;
    const panel = this._returnPanelDataForAffix();
    const panelName = panel.name;
    const panelHeight = panel.height + this._returnAffixTriggerOffset();
    if (scrollData.scrollDistance >= panelHeight) {
      affixPanel = panelName;
    }
    return affixPanel;
  };

  _didTriggerUnaffix = (scrollData) => {
    if (!scrollData || !('scrollDistance' in scrollData)) {
      console.error('Invalid scroll data provided to _didTriggerUnaffix');
    }
    let didTrigger = false;
    const panel = this._returnPanelDataForAffix();
    const panelHeight = panel.height + this._returnAffixTriggerOffset();
    if (scrollData.scrollDistance < panelHeight) {
      didTrigger = true;
    }
    return didTrigger && !isUndefinedOrNull(this.returnAffixedPanelName());
  };

  returnPanelNameHeight = (panelName) => this.state[`${panelName}Height`];

  _returnPanelDataForAffix = () =>
    this._returnLeftPanelDataForAffix().height <= this._returnRightPanelDataForAffix().height
      ? this._returnLeftPanelDataForAffix()
      : this._returnRightPanelDataForAffix();

  _bindNameToHeightChangeHandler = (name) => (sizeData) => this._handlePanelHeightChange(name, sizeData);

  _handlePanelHeightChange = (name, sizeData) => {
    this._setPanelHeight(name, sizeData.height);
  };

  _returnAffixPanelOffsetTop = () => {
    if (!this._$returnDashboardAffixScrollNode()[0] || !$(`.affix-scroll-container`)) {
      return 0;
    }
    return (
      this._$returnDashboardAffixScrollNode()[0]
        ? this._$returnDashboardAffixScrollNode()
        : $(`.affix-scroll-container`)
    ).offset().top;
  };

  _setPanelHeight = (name, height) => {
    const offsetForDashboardHeader = this._returnAffixPanelOffsetTop();
    const manualOffsetToSetPanelHigher = name === 'leftPanel' ? this._returnManualLeftPanelHeightOffset() : 0;
    this.setState(() => ({
      [`${name}Height`]: height + offsetForDashboardHeader + manualOffsetToSetPanelHigher,
    }));
  };

  returnAffixedPanelName = () => this.state._affixedPanelName;

  _setAffixedPanel = (panel) => {
    this.setState(() => ({
      _affixedPanelName: panel,
    }));
  };

  returnLeftPanelHeight = () => this.state.leftPanelHeight;

  returnRightPanelHeight = () => this.state.rightPanelHeight;

  _returnLeftPanelDataForAffix = () => ({
    name: 'leftPanel',
    height: this.returnLeftPanelHeight(),
  });

  _returnRightPanelDataForAffix = () => ({
    name: 'rightPanel',
    height: this.returnRightPanelHeight(),
  });

  _returnAffixContainerStyle = () =>
    this._shouldShowDesktopDashboard() ? {} : { height: `${this.returnActiveTabPanelHeight() + 60}px` };

  _returnBaseScrollContainerStyle = () =>
    this._shouldShowDesktopDashboard() ? {} : { height: `${this.returnActiveTabPanelHeight() + 60}px` };

  _returnLeftPanelStyle = () => ({
    ...this._returnPanelContainerStyle(),
    ...this._returnLeftPanelStyleWidth(),
  });

  _returnRightPanelStyle = () => ({
    ...this._returnPanelContainerStyle(),
    ...this._returnRightPanelStyleWidth(),
  });

  _returnLeftPanelStyleWidth = () =>
    this._shouldShowDesktopDashboard()
      ? {
          width: `${this._returnLeftBodyPanelWidth()}px`,
        }
      : {
          width: `100%`,
          float: 'none',
        };

  _returnRightPanelStyleWidth = () =>
    this._shouldShowDesktopDashboard()
      ? {
          width: `${this._returnRightBodyPanelWidth()}px`,
        }
      : {
          width: `100%`,
          float: 'none',
        };

  _returnPanelContainerStyle = () =>
    this._shouldShowDesktopDashboard()
      ? {}
      : {
          height: `${this.returnActiveTabPanelHeight()}px`,
        };

  _returnDashboardAffixScrollNode = () => this.DashboardAffixScroll;

  _$returnDashboardAffixScrollNode = () => {
    if (!this.$DashboardAffixScroll) {
      this.$DashboardAffixScroll = $(this._returnDashboardAffixScrollNode());
    }
    return this.$DashboardAffixScroll;
  };
}
