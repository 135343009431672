import React from 'react';

export const Wrapper = (props) => {
  const { className, style, elements } = props;
  return (
    <span className={className || ''} style={style || {}}>
      {elements.map((child, i) => React.cloneElement(child, { ...props, key: i }))}
    </span>
  );
};
