import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as Actions from '../../../../actions/index';
import { OrderOperations, OrderTypes } from '../../../../constants';
import { createBasicErrorModal } from '../../../../constants/modals';
import NumberWithChangeIndicator from '../../../../components/UI/NumberWithChangeIndicator';
import { OptimizerAnalysisSummary } from '../../../Optimizer/components/OptimizerAnalysisSummary';
import LoadingIcon from '../../../../components/misc/LoadingIcon';
import PlaceTrades from '../../../../dataContainers/orders/PlaceTrades';
import OrderForm from '../../../Orders/OrderForm';
import { snakeToHyphens, isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import { customParseFloat } from '../../../../helpers/numberHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';
import { findUserIdeaForSecurity } from '../../../../helpers/ideaHelpers';
import { returnPathTo } from '../../../../constants/paths';
import { inNoOrderBasketTestGroup } from '../../../../helpers/currentUserHelpers';
import { TrackingEvents } from '../../../../utils/tracking/events';
import { formatDataValue } from '../../../../helpers/securitiesHelpers';
import { FlatButton } from '@src/main/components/buttons';
import styled from 'styled-components';

const TradeButtonWrapper = styled.div`
  button {
    padding: 4px 8px;
    background-color: ${({ theme, isEmphasized }) =>
      isEmphasized ? theme.themeColors.primaryCtaButton : 'transparent'};
    color: ${({ theme, isEmphasized }) => (isEmphasized ? theme.themeColors.buttonText : theme.themeColors.text)};
  }
`;

export class OptimizedAllocationWithTradeCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;
    if (!this._isIdeaActive() && !this._doesUserHavePosition()) {
      return <td className={`security-table-${snakeToHyphens(columnKeyName)}`} />;
    }

    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? this.props.loadingCellComponent : this._renderCellContent()}
      </td>
    );
  }

  _isLoading = () => this._isOptimizerRunning() || this._isOptimizerLoading();

  _returnLinkForHandlingClick = () => {
    const { securityId } = this.props;
    const openSecurityPanelConfig = {
      securityId,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _isOptimizerDataAvailable = () => this.props.optimizer.data && this.props.optimizer.data.portfolio_stats;

  _renderCellContent = () => {
    if (this._isOptimizerDataAvailable()) {
      return (
        <div className={`security-table-optimizer-allocation-and-day-change-container`}>
          <div className={`multi-text-box-inline flex-align-start`}>
            {this._renderOptimizedAllocationData()}
            {this._renderOptimizerAnalysisSummary()}
          </div>
        </div>
      );
    } else {
      return (
        <div className={`security-table-optimizer-allocation-and-day-change-container`}>
          <div className={`multi-text-box-inline flex-align-start`}></div>
        </div>
      );
    }
  };

  _renderOptimizedAllocationData = () => {
    return this._isOptimizationAllowed()
      ? this._renderOptimizerResultFullView()
      : this._renderOptimizerResultPercentageView();
  };

  _renderOptimizerAnalysisSummary = () => {
    const { securityId } = this.props;
    const optimizationData = this._returnOptimizationData();

    const correlation = {
      optimizerAnalysisType: 'avg_correl',
      optimizerAnalysisGroup: optimizationData.avg_correl_group,
      optimizerAnalysisSummary: optimizationData.avg_correl_summary,
    };
    const volatility = {
      optimizerAnalysisType: 'hist_volatility',
      optimizerAnalysisGroup: optimizationData.hist_volatility_group,
      optimizerAnalysisSummary: optimizationData.hist_volatility_summary,
    };
    const projectedReturn = {
      optimizerAnalysisType: 'proj_return',
      optimizerAnalysisGroup: optimizationData.proj_return_group,
      optimizerAnalysisSummary: optimizationData.proj_return_summary,
    };

    const optimizerAnalysisSummaryList = [projectedReturn, volatility, correlation];

    return (
      <OptimizerAnalysisSummary securityId={securityId} optimizerAnalysisSummaryList={optimizerAnalysisSummaryList} />
    );
  };

  _returnOptimizedPositionsBySecurityId = () =>
    this.props.optimizer && this.props.optimizer.data && this.props.optimizer.data.optimized_positions_by_security_id
      ? this.props.optimizer.data.optimized_positions_by_security_id
      : {};

  _returnOptimizationData = () => {
    const { securityId } = this.props;
    return this._returnOptimizedPositionsBySecurityId()[securityId] || {};
  };

  _returnUserPositionsBySecurityId = () => this._returnUserPositions();

  _returnPortfolioCurrentTotalValueFromOptimizerData = () =>
    this.props.optimizer &&
    this.props.optimizer.data &&
    this.props.optimizer.data.portfolio_stats &&
    this.props.optimizer.data.portfolio_stats.current &&
    'total_value' in this.props.optimizer.data.portfolio_stats.current
      ? this.props.optimizer.data.portfolio_stats.current
      : null;

  _isOptimizationAllowed = () => !isUndefinedOrNull(this._returnPortfolioCurrentTotalValueFromOptimizerData());

  _logCreateOptimizedOrderClick = () => {
    this._logCreateOrder();
    const { securityId } = this.props;

    const event = 'Clicked Create Single Optimized Order';
    const properties = {
      'Security Id': securityId,
      'Security Symbol': this.props.security.symbol,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logCreateOrder = () => {
    // new amp event style that merges all order types
    const { securityId } = this.props;
    const properties = {
      'Security Id': securityId,
      'Security Symbol': this.props.security.symbol,
      'Order Creation Type': 'Single Optimization',
    };
    TrackingEvents.orders.PLACED_TRADE.send(properties);
  };

  _showCreatingOrdersModal = () => {
    const modalMessage = 'Loading';
    const contentComponent = (
      <div className={`creating-optimized-orders component-bg`}>
        <LoadingIcon size="medium" />
        <div style={{ marginTop: '4px', marginLeft: '8px', fontSize: '24px' }}>{modalMessage}</div>
      </div>
    );
    const modal = {
      contentComponent,
      size: 'wide',
      dismissable: false,
    };
    this.props.actions.showModal(modal);
  };

  _handleCreateOptimizedOrder = () => {
    this._logCreateOptimizedOrderClick();
    this._showCreatingOrdersModal();
    const { securityId } = this.props;
    const currentOptimization = this.props.optimizer.data.optimized_positions_by_security_id;
    const currentAllocations = this.props.currentUser.positions;

    let totalVsCurrentDifference = 0;

    if (currentOptimization[securityId]) {
      const rawTotalSharesOptimizerDesired = currentOptimization[securityId].shares;
      const totalSharesDesired = customParseFloat(formatDataValue(rawTotalSharesOptimizerDesired, 'shares'));
      const currentPosition = currentAllocations[securityId] ? currentAllocations[securityId].shares : 0;
      totalVsCurrentDifference = totalSharesDesired - currentPosition;
    }

    if (totalVsCurrentDifference === 0) {
      // redirect to create their own order
      this.props.actions.clearOrderBasket().then((response) => {
        if (response && response.ok) {
          setTimeout(() => {
            this.props.actions.hideModal();
            this.props.navigate(`/orders?operation=null&security_id=${securityId}`);
          }, 250);
        } else {
          const modalMessage = 'Could not create order. Please try again later.';
          const modal = {
            contentComponent: createBasicErrorModal(modalMessage),
            dismissable: true,
          };
          this.props.actions.showModal(modal);
        }
      });
    } else {
      if (inNoOrderBasketTestGroup(this._returnCurrentUser())) {
        const operation = totalVsCurrentDifference > 0 ? 'buy' : 'sell';
        const rawShares = Math.abs(totalVsCurrentDifference);
        const cappedShares = customParseFloat(formatDataValue(rawShares, 'shares'));
        const type = 'market';
        const is_from_optimizer = true;
        const defaultFormData = {
          operation: OrderOperations[operation],
          type: OrderTypes[type],
          shares: cappedShares,
          is_from_optimizer,
        };
        const contentComponent = (
          <PlaceTrades handleDismiss={this.props.actions.hideModal}>
            {({
              handlePlaceOrders,
              renderPlacingOrdersState,
              showAddProConPanelForPlacedOrders,
              shouldRestrictDismissal,
              wasOrderPlaced,
              isAddProConPanelShowing,
            }) => (
              <OrderForm
                defaultFormData={defaultFormData}
                securityId={securityId}
                handleCompleteOrder={this.handleCompleteOrder}
                handleCancelOrder={this.props.actions.hideModal}
                isUsingBasket={false}
                handlePlaceOrders={handlePlaceOrders}
                renderPlacingOrdersState={renderPlacingOrdersState}
                showAddProConPanelForPlacedOrders={showAddProConPanelForPlacedOrders}
                shouldRestrictDismissal={shouldRestrictDismissal}
                wasOrderPlaced={wasOrderPlaced}
                isAddProConPanelShowing={isAddProConPanelShowing}
              />
            )}
          </PlaceTrades>
        );
        const modal = {
          contentComponent,
          size: 'wide',
        };
        this.props.actions.showModal(modal);
      } else {
        const contentComponent = (
          <div className={`creating-optimized-orders`} style={{ maxWidth: '320px ' }}>
            <LoadingIcon size="medium" />
            <div style={{ marginTop: '4px', marginLeft: '8px', fontSize: '24px' }}>{'Creating Orders'}</div>
          </div>
        );
        const modal = {
          contentComponent,
          size: 'wide',
          dismissable: false,
        };
        this.props.actions.showModal(modal);
        const operation = totalVsCurrentDifference > 0 ? 'buy' : 'sell';
        const rawShares = Math.abs(totalVsCurrentDifference);
        const cappedShares = customParseFloat(formatDataValue(rawShares, 'shares'));

        const type = 'market';
        const is_from_optimizer = true;
        const tradeObj = {
          security_id: securityId,
          operation,
          shares: cappedShares,
          type,
          is_from_optimizer,
        };
        const options = {
          clearOrdersBefore: true,
        };
        const orders = [tradeObj];
        this.props.actions.createOrders(orders, options).then((response) => {
          if (response && response.data && !response.data.error) {
            setTimeout(() => {
              this.props.actions.hideModal();
              this.props.navigate(returnPathTo('orders'));
            }, 250);
          } else {
            const modalMessage = 'Could not create order. Please try again later.';
            const modal = {
              contentComponent: createBasicErrorModal(modalMessage),
              dismissable: true,
            };
            this.props.actions.showModal(modal);
          }
        });
      }
    }
  };

  _returnValueOfTrade = () => {
    const { securityId } = this.props;
    const userPositions = this._returnUserPositions();
    const { optimizedAllocationValue } = this._returnOptimizedAllocationData();

    return this._doesUserHavePosition()
      ? optimizedAllocationValue - userPositions[securityId].value
      : optimizedAllocationValue;
  };

  _shouldEmphasizeOptimizedTrade = () => {
    // Emphasize trade if trade is 2% > equity, > $250, or no current position
    const valueThreshold = 250;

    const valueOfTrade = this._returnValueOfTrade();
    const equity = this.props.currentUser.equity;
    const equityThreshold = customParseFloat(equity) * 0.02;

    const isGreaterThanEquityThreshold = equityThreshold < Math.abs(valueOfTrade);
    const isGreaterThanValueThreshold = valueThreshold < Math.abs(valueOfTrade);
    return (
      isGreaterThanEquityThreshold || isGreaterThanValueThreshold || (!this._doesUserHavePosition() && valueOfTrade > 0)
    );
  };

  _returnNonEmphasizedTradeButtonClasses = () => 'btn skel-btn skel-btn-primary-color btn-xsmall btn-fixed-width-50';

  _returnEmphasizedTradeButtonClasses = () => 'btn btn-primary-color btn-xsmall btn-fixed-width-50';

  _returnOptimizedAllocationData = () => {
    const optimizationData = this._returnOptimizationData();
    const optimizedAllocationValue = optimizationData.value || 0;
    const optimizedAllocationShares = optimizationData.shares || 0;
    const optimizedAllocationPercent = optimizationData.allocation_percent || 0;

    return {
      optimizedAllocationValue,
      optimizedAllocationShares,
      optimizedAllocationPercent,
    };
  };

  _renderOptimizerResultFullView = () => {
    const shouldEmphasizeOptimizedTrade = this._shouldEmphasizeOptimizedTrade();
    const valueOfTrade = this._returnValueOfTrade();
    const { optimizedAllocationValue, optimizedAllocationShares, optimizedAllocationPercent } =
      this._returnOptimizedAllocationData();

    return (
      <div className={``}>
        <div
          className={`security-table-allocation-container`}
          onClick={this.props.actions.openOptimizerPanel}
          style={{ cursor: 'pointer' }}
        >
          <div className={`security-table-optimizer-allocation-total-value`}>
            <NumberWithChangeIndicator
              customClass={'portfolio-dashboard'}
              valueFormat={'price'}
              value={optimizedAllocationValue}
              customIconCompareValue={shouldEmphasizeOptimizedTrade ? valueOfTrade : 0}
              coloredText={true}
              increaseIcon={'fa-arrow-circle-up'}
              decreaseIcon={'fa-arrow-circle-down'}
              neutralIcon={'fa-check'}
            />
          </div>

          <div className={`multi-text-box-inline`}>
            <div className={`security-table-optimizer-allocation-total-value-change`}>
              <NumberWithChangeIndicator
                customClass={'portfolio-dashboard'}
                valueFormat={'shares'}
                value={optimizedAllocationShares}
                customIconCompareValue={shouldEmphasizeOptimizedTrade ? valueOfTrade : 0}
                coloredText={true}
                allowFractional
                forceTextColorClassName={shouldEmphasizeOptimizedTrade ? '' : 'secondary-text-color'}
                suffix={' sh'}
                hideIcon={true}
              />
            </div>

            <div className={`security-table-optimizer-allocation-total-value-change-percentage`}>
              <NumberWithChangeIndicator
                customClass={'portfolio-dashboard'}
                valueFormat={'percentage'}
                value={optimizedAllocationPercent}
                customIconCompareValue={shouldEmphasizeOptimizedTrade ? valueOfTrade : 0}
                coloredText={true}
                forceTextColorClassName={shouldEmphasizeOptimizedTrade ? '' : 'secondary-text-color'}
                hideIcon={true}
              />
            </div>
          </div>
        </div>
        <div className={`security-table-trade`}>
          <TradeButtonWrapper className={'security-table-trade'} isEmphasized={shouldEmphasizeOptimizedTrade}>
            <FlatButton onClick={this._handleCreateOptimizedOrder}>Trade</FlatButton>
          </TradeButtonWrapper>
        </div>
      </div>
    );
  };

  _renderOptimizerResultPercentageView = () => {
    const optimizationData = this._returnOptimizationData();
    const optimizedAllocationPercent = optimizationData.allocation_percent || 0;
    return (
      <div className={``}>
        <div className={`security-table-allocation-container`}>
          <div className={`security-table-optimizer-allocation-total-value`}>
            <NumberWithChangeIndicator
              customClass={'portfolio-dashboard'}
              valueFormat={'percentage'}
              value={optimizedAllocationPercent}
              hideIcon={true}
              coloredText={false}
              increaseIcon={'fa-arrow-circle-up'}
              decreaseIcon={'fa-arrow-circle-down'}
              neutralIcon={'fa-check'}
            />
          </div>
        </div>
      </div>
    );
  };

  _returnUserPositions = () => this.props.currentUser.positions;

  _doesUserHavePosition = () => {
    const { securityId } = this.props;
    const userPositionsBySecurityIds = this._returnUserPositionsBySecurityId();
    const position = userPositionsBySecurityIds[securityId] || {};
    const shares = 'shares' in position ? Math.abs(position.shares) : 0;
    return shares > 0;
  };

  _returnCurrentUser = () => this.props.currentUser;

  _doesCurrentUserHavePositions = () => this._returnPositionsList().length > 0;
  _returnPositionsList = () =>
    Object.keys(this._returnPositions())
      .map((id) => this._returnPositions()[id])
      .filter((security) => security.allocation_percent !== 0 && !isUndefinedOrNull(security.allocation_percent));
  _returnPositions = () => this._returnPortfolioStore().positions;
  _returnPortfolioStore = () => this.props.portfolio;

  _isOptimizerLoading = () => this._returnOptimizerStore().loading;
  _isOptimizerRunning = () => this._returnOptimizerStore().isOptimizerRunning;
  _returnOptimizerStore = () => this.props.optimizer;

  _returnIdeaList = () => this.props.ideas.ideaList;
  _returnIdea = () =>
    findUserIdeaForSecurity(this.props.currentUser.user_id, this._returnSecurityId(), this._returnIdeaList());
  _isIdeaActive = () => !this._returnIdea() || this._returnIdea().active;
  _returnSecurityId = () => this.props.securityId;
  _returnSecuritySymbol = () => this._returnSecurity().symbol;
  _returnSecurity = () => this._returnSecuritiesDict()[this.props.securityId];
  _returnSecuritiesDict = () => this._returnSecuritiesStore().lookup;
  _returnSecuritiesStore = () => this.props.securities;
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    ideas: state.ideas,
    portfolio: state.portfolio,
    optimizer: state.optimizer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(OptimizedAllocationWithTradeCell);

export default composedComponent;
