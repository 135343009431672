import { trackingEvents } from '@src/main/lib/nvstr-utils.es';

import ItlyTrackWrapper from './ItlyTrackWrapper';

const ACCOUNT_TYPE_SELECTED = new ItlyTrackWrapper(trackingEvents.accountTypeSelected);
const COMPLETE_APEX_ACCOUNT = new ItlyTrackWrapper(trackingEvents.completeApexAccount);

const accountTrackIteratively = {
  ACCOUNT_TYPE_SELECTED,
  COMPLETE_APEX_ACCOUNT,
};

export default accountTrackIteratively;
