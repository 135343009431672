import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../../actions/index';

import LineChartUp from '../../../images/chart-up-green.png';
import LineChartDown from '../../../images/chart-down-red.png';

import CreateThoughtContainer from '../CreateThoughtContainer';
import { ThoughtsListOnly } from './ThoughtsListOnly';

import { toCapitalCase } from '../../../helpers/generalHelpers';
import { OpenCreateThoughtPanelButton } from './OpenCreateThoughtPanelButton';
import { H5 } from '../../../main/lib/nvstr-common-ui.es';
import styled from 'styled-components';
import { FlatButton } from '../../../main/components/buttons';
import { Container } from '../../../main/components/ui';

const ChartArrow = () => {
  return (
    <svg viewBox="0 0 20 20">
      <path d="M17.997 5.6c-.028-.333-.345-.607-.693-.6h-2.782c-1.391 0-2.087 1.334-2.087 1.334h3.19l-4.58 4.39-2.293-2.197a.718.718 0 00-.984 0l-5.565 5.335a.649.649 0 000 .943c.272.26.713.26.985 0l5.074-4.864 2.292 2.197a.705.705 0 00.49.197c.185 0 .362-.07.49-.197l5.075-4.86v3.056S18 9.668 18 8.334V5.667c0-.023 0-.043-.003-.067z" />
    </svg>
  );
};

const AddProConButtonWrapper = styled.div`
  button {
    padding: 4px 8px;
  }

  button {
    border: 1px solid
      ${({ theme, thoughtType }) => (thoughtType === 'pro' ? theme.themeColors.proColor : theme.themeColors.conColor)} !important;
    background-color: ${({ theme, thoughtType }) =>
      thoughtType === 'pro' ? theme.themeColors.proColor : theme.themeColors.conColor} !important;
  }
`;
const IconAndTextWrapper = styled.div`
  display: flex;
`;
const ChartIconWrapper = styled.div`
  position: relative;
  margin-right: 16px;
  width: 40px;
  border-left: 2px solid
    ${({ theme, thoughtType }) => (thoughtType === 'pro' ? theme.themeColors.proColor : theme.themeColors.conColor)};
  border-bottom: 2px solid
    ${({ theme, thoughtType }) => (thoughtType === 'pro' ? theme.themeColors.proColor : theme.themeColors.conColor)};

  svg {
    position: absolute;
    top: 0;
    left: 0;

    fill: ${({ theme, thoughtType }) =>
      thoughtType === 'pro' ? theme.themeColors.proColor : theme.themeColors.conColor};

    transform: ${({ theme, thoughtType }) => (thoughtType === 'pro' ? 'rotate(0deg)' : 'rotate(178deg) scaleX(-1);')};
    height: 36px;
    width: 36px;
  }
`;
const ThoughtHeadingWrapper = styled.div`
  padding: 16px;

  border-radius: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 8px;

  h5 {
    color: ${({ theme, thoughtType }) =>
      thoughtType === 'pro' ? theme.themeColors.proColor : theme.themeColors.conColor};
    text-transform: uppercase;
  }
`;

const OpenCreateThoughtButton = ({ thoughtType, handleClick }) => {
  if (thoughtType === 'pro') {
    return (
      <AddProConButtonWrapper thoughtType={thoughtType}>
        <FlatButton onClick={handleClick}>+ ADD PRO</FlatButton>
      </AddProConButtonWrapper>
    );
  }
  if (thoughtType === 'con') {
    return (
      <AddProConButtonWrapper thoughtType={thoughtType}>
        <FlatButton onClick={handleClick}>+ ADD CON</FlatButton>
      </AddProConButtonWrapper>
    );
  }
};

const ThoughtListHeading_Local = ({ thoughtType, allowAddThought, handleAddThought }) => {
  if (thoughtType === 'pro') {
    return (
      <ThoughtHeadingWrapper thoughtType={thoughtType}>
        <IconAndTextWrapper width={'80px'}>
          <ChartIconWrapper thoughtType={thoughtType}>
            <ChartArrow />
          </ChartIconWrapper>
          <H5>Pros</H5>
        </IconAndTextWrapper>
        {allowAddThought && (
          <OpenCreateThoughtButton thoughtType={thoughtType} handleClick={() => handleAddThought(thoughtType)} />
        )}
      </ThoughtHeadingWrapper>
    );
  }
  if (thoughtType === 'con') {
    return (
      <ThoughtHeadingWrapper thoughtType={thoughtType}>
        <IconAndTextWrapper>
          <ChartIconWrapper thoughtType={thoughtType}>
            <ChartArrow />
          </ChartIconWrapper>
          <H5>Cons</H5>
        </IconAndTextWrapper>
        {allowAddThought && (
          <OpenCreateThoughtButton thoughtType={thoughtType} handleClick={() => handleAddThought(thoughtType)} />
        )}
      </ThoughtHeadingWrapper>
    );
  }

  return null;
};

class ThoughtsListWithHeading extends React.Component {
  render() {
    return (
      <div
        className="thought-list-container"
        key={`thoughts-list-${this._returnThoughtType()}-${this._returnSecurityId()}`}
      >
        {this._shouldShowHeading() && (
          <ThoughtListHeading_Local
            allowAddThought={this._shouldShowOpenCreateThoughtButton()}
            thoughtType={this._returnThoughtType()}
            securityId={this._returnSecurityId()}
            handleAddThought={this.props.handleOpenCreateThoughtPanelClick}
          />
        )}
        {this.props.children}
        {!this._isUserAuthed() && this._areNoThoughts()
          ? this._renderJoinCommunityAndEnterThoughtMessage()
          : this._renderThoughtsList()}
      </div>
    );
  }

  _renderThoughtsList = () => {
    return (
      <div>
        <div className={`thought-list clearfix ${this._areNoThoughts() ? 'no-thoughts' : ''}`}>
          {this._shouldShowCreateThought(this._returnThoughtType()) && (
            <CreateThoughtContainer
              security={this._returnSecurity()}
              thoughtType={this.props.creatingThoughtType || this._returnThoughtType().toLowerCase()}
              handleCreateThought={this.props.handleCreateThought}
              handleCancelCreatingThought={this.props.handleCancelCreatingThought}
              viewingContext={this.props.viewingContext}
            />
          )}
          <ThoughtsListOnly
            {...this.props}
            isUserAuthed={this._isUserAuthed()}
            scrollContainerSelector={this.props.scrollContainerSelector}
            distanceFromBottomToTriggerInfiniteScroll={this.props.distanceFromBottomToTriggerInfiniteScroll}
            useInfiniteScroll={this.props.useInfiniteScroll}
            noContainer
          />
          {this._renderBelowLastThoughtPadding()}
        </div>
      </div>
    );
  };

  _renderBelowLastThoughtPadding = () => <div style={{ height: '88px', width: '100%', opacity: 0 }}>.</div>;

  _renderJoinCommunityAndEnterThoughtMessage = () => {
    return (
      <div className="thought-list-container empty-thought-list-container">
        {`Our community has not entered any ${toCapitalCase(
          this._returnThoughtType()
        )}s. Login or Signup to start the conversation.`}
      </div>
    );
  };

  _shouldShowHeading = () => !this.props.hideHeading;

  _shouldShowOpenCreateThoughtButton = () => {
    return this._isUserAuthed() && !this._shouldShowCreateThought();
  };

  _shouldShowCreateThought = () => {
    return (
      this._isUserAuthed() &&
      (this.props.shouldShowOpenCreateThoughtPanel ||
        (this._areNoThoughts() && !(this.props.isTwoColumns && this._isCreatingThoughtOfOtherType())))
    );
  };

  _isCreatingThoughtOfOtherType = () =>
    this.props.creatingThought && this.props.creatingThoughtType !== this._returnThoughtType();

  _areNoThoughts = () => this._returnThoughts().length === 0;

  _returnThoughts = () => this.props.thoughts;

  _returnSecurity = () => this.props.security;

  _returnSecurityId = () => this._returnSecurity().security_id;

  _returnThoughtType = () => this.props.thoughtType;

  _returnCurrentUser = () => this.props.currentUser;

  _isUserAuthed = () => 'user_id' in this._returnCurrentUser();
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThoughtsListWithHeading);
