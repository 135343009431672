import React from 'react';
import Footer from '../Footer';

export const DashboardFooter = (props) => {
  return (
    <div className={`dashboard-disclaimer-footer`}>
      <Footer />
    </div>
  );
};
