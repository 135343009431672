import React from 'react';
import styled from 'styled-components';
import { FlatButton } from '../buttons';
import { Body5, Body6 } from '../../lib/nvstr-common-ui.es';
import { colorPalette } from '../../lib/nvstr-utils.es';
import { TrackingEvents } from '../../../utils/tracking/events';
import { ROUTES } from '../../../constants/paths';
import { useNavigate } from 'react-router-dom';
import { doesCurrentUserHaveFreeSubSlot } from '../../../helpers/currentUserHelpers';
import { useCurrentUser } from '../../hooks/user';

const Wrapper = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid ${({ theme }) => theme.themeColors.text};

  ${Body6} {
    color: ${({ theme }) => theme.themeColors.text};
  }
`;
const Content = styled.div`
  padding: 16px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  position: relative;
  z-index: 2;
`;
const ButtonWrapper = styled.div`
  padding-left: 16px;
`;

export const SelectVPCBanner = ({ onView, onCTAClick, ContainerComponent }) => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const hasFreeViewpointSelectionAvailable = doesCurrentUserHaveFreeSubSlot(currentUser);
  const textColor = colorPalette.primary.charcoal;

  React.useEffect(() => {
    TrackingEvents.banners.VIEW_SELECT_VPC_BANNER.send();
    onView();
  }, []);

  const handleClick = () => {
    TrackingEvents.banners.CLICK_SELECT_VPC_BANNER.send();
    onCTAClick();

    navigate(ROUTES.VIEWPOINT_SUBSCRIPTIONS_MANAGEMENT.build());
  };

  if (!hasFreeViewpointSelectionAvailable) {
    return null;
  }

  return (
    <ContainerComponent>
      <Wrapper>
        <Content>
          <div>
            <Body6 bold>Select Your Free VPC</Body6>
            <div style={{ paddingTop: 4 }}>
              <Body5 isSmall>
                You have a free premium Viewpoint Contributor (VPC) subscription available with your membership.
              </Body5>
            </div>
          </div>
          <ButtonWrapper>
            <FlatButton color={textColor} textColor={colorPalette.primary.oak} onClick={handleClick}>
              Select Now
            </FlatButton>
          </ButtonWrapper>
        </Content>
      </Wrapper>
    </ContainerComponent>
  );
};
