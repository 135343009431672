import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAnnualMembershipEligibility } from '../../../actions';

export const useMembership = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.membership);

  React.useEffect(() => {
    fetchAnnualMembershipEligibility()(dispatch);
  }, []);

  return data;
};
