import { useSelector } from 'react-redux';
import { returnCurrentUserPendingEquityReasonsJSX } from '@src/helpers/infoIconHelpers';
import { useCurrentUser } from '../user';
import { usePositionsList, usePositionsLookup, useUnfeaturedPositions } from './usePositions';
import { createTimeInstance, formatLocalizedDateTime } from '@src/helpers/timeHelpers';
import { useIsMarketOpen } from '../securities/useIsMarketOpen';
import { useIsDelayedPricesEnabled } from '../user/useCurrentUser';
import { useExtendedTradingHours } from '../securities/useExtendedTradingHours';
import { doArraysContainSameElementValues } from '@src/helpers/generalHelpers';
import { isUndefinedOrNull } from '@src/helpers/usefulFuncs';

const _calculatePortfolioValue = (positions, positionsLookup, priceLookup) => {
  let isInvalid = false;
  const missingPricesForSecurityIds = [];
  let value = 0;
  let extendedHoursValue = 0;
  const priceAsOfTimes = {};
  const extendedHoursAsOfTimes = {};
  let latestExtendedHoursAsOfTime = null;

  positions
    .map((p) => positionsLookup[p])
    .forEach((pos) => {
      const securityId = pos.security_id;
      const priceData = priceLookup[securityId] || {};
      const { price, priceAsOf, extendedPrice, extendedChange, extendedAsOf } = priceData;
      if (isNaN(price) || isUndefinedOrNull(price)) {
        isInvalid = true;
        missingPricesForSecurityIds.push(securityId);
      } else {
        priceAsOfTimes[pos.security_id] = priceAsOf;
        value += price * pos.shares;
        if (!isUndefinedOrNull(price) && !isNaN(extendedPrice)) {
          extendedHoursValue += extendedChange * pos.shares;
          extendedHoursAsOfTimes[pos.security_id] = extendedAsOf;
          if (latestExtendedHoursAsOfTime === null || extendedAsOf > latestExtendedHoursAsOfTime) {
            latestExtendedHoursAsOfTime = extendedAsOf;
          }
        }
      }
    });

  return {
    isInvalid,
    missingPricesForSecurityIds,

    value,
    extendedHoursValue: value + extendedHoursValue,

    priceAsOfTimes,
    extendedHoursAsOfTimes,
    latestExtendedHoursAsOfTime,
  };
};

const _calculatePortfolioDayChangeValue = (positions, positionsLookup, priceLookup) => {
  let isInvalid = false;
  const missingPricesForSecurityIds = [];
  let value = 0;
  let extendedHoursValue = 0;
  const priceAsOfTimes = {};
  const extendedHoursAsOfTimes = {};
  let latestExtendedHoursAsOfTime = null;

  positions
    .map((p) => positionsLookup[p])
    .forEach((pos) => {
      /*
            price
            priceAsOf

            delayedPrice,
            delayedPriceAsOf

            change,
            percentChange

            open,
            close,

            extendedPrice,
            extendedChange,
            extendedChangePercent,
            extendedAsOf
         */
      const { price, priceAsOf, previousClose, extendedPrice, extendedChange, extendedAsOf } =
        priceLookup[pos.security_id] || {};
      const securityId = pos.security_id;
      if (isNaN(price) || price === null) {
        isInvalid = true;
        missingPricesForSecurityIds.push(securityId);
      } else {
        const {
          unrealized_shares_transacted_today,
          unrealized_shares_as_of_yesterday,
          previous_close_price,
          cost_of_unrealized_shares_transacted_today,
          shares,
          security_id,
        } = pos;
        const availablePrevClosePrice = previousClose || previous_close_price;

        priceAsOfTimes[security_id] = priceAsOf;

        const todayChange =
          unrealized_shares_as_of_yesterday * price -
          unrealized_shares_as_of_yesterday * availablePrevClosePrice +
          (unrealized_shares_transacted_today * price - cost_of_unrealized_shares_transacted_today);

        value += todayChange;
        if (extendedPrice !== null && !isNaN(extendedPrice)) {
          extendedHoursValue += extendedChange * shares;
          extendedHoursAsOfTimes[security_id] = extendedAsOf;
          if (latestExtendedHoursAsOfTime === null || extendedAsOf > latestExtendedHoursAsOfTime) {
            latestExtendedHoursAsOfTime = extendedAsOf;
          }
        }
      }
    });
  return {
    isInvalid,
    missingPricesForSecurityIds,

    value: value,
    extendedValue: extendedHoursValue,

    priceAsOfTimes,
    extendedHoursAsOfTimes,
    latestExtendedHoursAsOfTime,
  };
};

const _getOldestTime = (pricingAsOfTimes) => {
  const values = Object.values(pricingAsOfTimes);
  let v;
  values.forEach((time) => {
    if (!v) {
      v = time;
    }
    if (v > time) {
      v = time;
    }
  });

  return v;
};

const _buildPricingAsOfDisplay = (isMarketOpen, isUsingDelayedPrices, pricingAsOfTimes) => {
  if (!isMarketOpen) {
    return 'As of market close';
  }

  if (isUsingDelayedPrices) {
    return 'As of ' + formatLocalizedDateTime('asOf', createTimeInstance(_getOldestTime(pricingAsOfTimes)));
  }

  return null;
};

export const useBuyingPower = () => {
  const currentUser = useCurrentUser();
  return currentUser.buying_power;
};

export const usePortfolioCash = () => useSelector((state) => state.portfolio?.cash?.value);

export const usePortfolioMarketValue = () => {
  const { isOpen } = useIsMarketOpen();
  const isUsingDelayedPrices = useIsDelayedPricesEnabled();

  const positions = usePositionsList();
  const positionsLookup = usePositionsLookup();
  const priceLookup = useSelector((state) => state.livePrice.lookupById);
  const unfeaturedPositionSecurityIds = useUnfeaturedPositions();
  const {
    value,
    isInvalid,
    missingPricesForSecurityIds,
    priceAsOfTimes,
    extendedHoursValue,
    // extendedHoursAsOfTimes,
    latestExtendedHoursAsOfTime,
  } = _calculatePortfolioValue(positions, positionsLookup, priceLookup);

  const isLoading =
    missingPricesForSecurityIds.length === 0
      ? false
      : !missingPricesForSecurityIds.every((sid) => unfeaturedPositionSecurityIds.includes(sid));

  return {
    value,
    isLoading,
    missingPricesForSecurityIds: missingPricesForSecurityIds.filter((sid) =>
      unfeaturedPositionSecurityIds.includes(sid)
    ),
    priceAsOfTimes,
    pricingAsOfTimeDisplay: _buildPricingAsOfDisplay(isOpen, isUsingDelayedPrices, priceAsOfTimes),

    extendedHoursValue: isLoading ? null : extendedHoursValue,
    extendedHoursValueAsOfTimeDisplay:
      latestExtendedHoursAsOfTime === null
        ? ''
        : 'As of ' + formatLocalizedDateTime('asOf', createTimeInstance(latestExtendedHoursAsOfTime)),
  };
};

export const useEquityValue = () => {
  const {
    value: marketValue,
    isLoading,
    missingPricesForSecurityIds,
    pricingAsOfTime,
    pricingAsOfTimeDisplay,
    extendedHoursValue,
    // extendedHoursAsOfTimes,
    extendedHoursValueAsOfTimeDisplay,
  } = usePortfolioMarketValue();

  const cash = usePortfolioCash();
  return {
    isLoading,
    value: marketValue + cash,
    missingPricesForSecurityIds,
    pricingAsOfTime,
    pricingAsOfTimeDisplay,
    extendedHoursValue: isLoading ? null : extendedHoursValue + cash,
    extendedHoursValueAsOfTimeDisplay,
  };
};

export const usePortfolioDayChange = () => {
  const { isPreMarket } = useExtendedTradingHours();
  const { value: equityValue } = useEquityValue();

  const positions = usePositionsList();
  const positionsLookup = usePositionsLookup();
  const priceLookup = useSelector((state) => state.livePrice.lookupById);

  const {
    value,
    isLoading,
    extendedValue,
    // extendedHoursAsOfTimes,
    // latestExtendedHoursAsOfTime,
  } = _calculatePortfolioDayChangeValue(positions, positionsLookup, priceLookup);
  const startOfDayEquity = equityValue - value;
  const atCloseEquity = equityValue - extendedValue;
  return {
    isLoading,

    value: isPreMarket ? 0 : value,
    percentage: isPreMarket ? 0 : equityValue === 0 || isLoading ? 0 : (equityValue / startOfDayEquity - 1) * 100,
    extended: {
      value: extendedValue,
      percentage: equityValue === 0 || isLoading ? 0 : (equityValue / atCloseEquity - 1) * 100,
    },
  };
};

export const usePortfolioCashData = () => {
  const { value: portfolioValue, isLoading } = usePortfolioMarketValue();
  const cash = usePortfolioCash();
  const percentageOfEquity = (cash / portfolioValue) * 100;
  return {
    isLoading,
    value: cash,
    percentageOfEquity,
  };
};

export const usePortfolioPendingCash = () => {
  const currentUser = useCurrentUser();

  const pendingEquityValue = (currentUser.pending_equity || 0) - (currentUser.equity || 0);
  const pendingEquityReasons = returnCurrentUserPendingEquityReasonsJSX(currentUser);
  return {
    pendingEquityValue,
    pendingEquityReasons,
  };
};
