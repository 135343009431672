import React, { useState } from 'react';
import { isUndefinedOrNull } from '../../helpers/generalHelpers';

const Input = (props) => {
  const {
    name,
    label,
    value,
    errorMessage,

    type,
    placeholder,
    autoFocus,

    isFloatingStyle,
    holdSpaceForError,

    fieldSetClassName,
    containerClassName,

    renderAdditionalComponentWhenNoValOrFocus,
  } = props;

  const [isFocused, setFocus] = useState(false);

  if (isUndefinedOrNull(value)) {
    console.error(`Input: ${name} is missing a value, hiding input, received value: ${value}`);
    return null;
  }
  const hasValue = value.length > 0;

  const handleChange = (e) => {
    const newValue = e.target.value;
    props.handleChange(name, newValue);
  };

  const handleFocus = () => {
    if (props.handleFocus) {
      props.handleFocus(name);
    }
    setFocus(true);
  };

  const handleBlur = (p, e) => {
    if (props.saveOnChange) {
      const data = {
        [`${props.objectType}_id`]: props.getObjectId(props.objectType),
        [name]: value,
      };
      props.saveOnChange(data);
    }

    if (props.handleBlur) {
      props.handleBlur(value, name);
    }

    if ('additionalBlurAction' in props) {
      props.additionalBlurAction();
    }

    setFocus(false);
  };

  return (
    <div
      className={`
        ${containerClassName || ''}
        stand-alone-input-container
        ${isFloatingStyle ? 'floating-text-label' : ''}
        ${hasValue ? 'has-value' : ''}
        ${isFocused ? 'has-focus' : ''}
        ${!!errorMessage ? 'has-error' : ''}
      `}
    >
      <fieldset className={`${fieldSetClassName || ''}`}>
        {label && (
          <label htmlFor={name} className={`label-${name}`}>
            {label}
          </label>
        )}
        <input
          type={type || 'text'}
          name={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={isFloatingStyle ? '' : placeholder || ''}
          value={value}
          autoFocus={autoFocus}
        />
        {!hasValue && !isFocused && renderAdditionalComponentWhenNoValOrFocus}
        <div className={`underline`} />
        {(holdSpaceForError || errorMessage) && (
          <p className={`text-field-error-message error-text-color`}>{errorMessage || ''}</p>
        )}
      </fieldset>
    </div>
  );
};

export default Input;
