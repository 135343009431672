import React from 'react';
import { LEARN_SCREENS } from '../../constants/learn/lessonDefinitions';
import { useDispatch, useSelector } from 'react-redux';
import { getLesson } from '@src/actions';
import { LEARN_LESSON_TYPES } from '@src/main/constants/learn/types';

const getScreenDataLocally = (lessonId) => {
  if (lessonId < 100000) return null;
  return LEARN_SCREENS[lessonId] || null;
};

export const useBasicLearnLessonData = (lessonId) => {
  if (!lessonId) return null;
  const lessonStore = useSelector((state) => state.learn.lookup);
  return lessonStore[lessonId];
};

export const useLearnLesson = (props_lessonId, fetchLesson) => {
  const dispatch = useDispatch();

  const [lessonId, setLessonId] = React.useState(props_lessonId);

  const localScreenData = getScreenDataLocally(props_lessonId);
  const lessonStore = useSelector((state) => state.learn.lessonData);
  const previewLesson = useSelector((state) => state.learn.previewLesson);
  const lessonDataFromStore = lessonStore[props_lessonId] || null;

  const [isLoading, setIsLoading] = React.useState(!localScreenData && !lessonDataFromStore);
  const [wasError, setWasError] = React.useState(false);

  const getLessonDataFromApi = async (lessonId) => {
    const { errorMessage } = await getLesson(lessonId)(dispatch);
    if (errorMessage) {
      setWasError(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setWasError(false);
      return null;
    }
  };

  React.useEffect(() => {
    if (fetchLesson) getLessonDataFromApi(props_lessonId);
  }, []);

  React.useEffect(
    () => {
      if (props_lessonId !== lessonId) {
        setLessonId(props_lessonId);
        if (fetchLesson) getLessonDataFromApi(props_lessonId);
      }
    },
    [props_lessonId]
  );

  const buildLessonData = () => {
    if (localScreenData) {
      const {
        name,
        heading,
        phraseDisplay,
        type,
        content,
        sponsorId,
        moreOnTopicUrl,
        componentType,
        componentData,
        metadata,
        disclaimers,
      } = localScreenData;
      return {
        name,
        heading,
        phraseDisplay,
        lessonType: type,
        lessonContents: content,
        sponsorId,
        disclaimers,
        metadata: metadata || {},
        moreOnTopicUrl,
        componentType,
        componentData,
      };
    } else {
      if (!lessonDataFromStore) {
        return null;
      }

      const {
        content_type,
        description: name,
        heading,
        is_core_content,

        min_investing_experience,
        max_investing_experience,

        preview_phrase,
        sponsor_id,

        json_lesson_content,
        json_disclaimers_content,
        published_at,
      } = lessonDataFromStore;
      return {
        name,
        heading: heading || name,
        phraseDisplay: preview_phrase,
        lessonType: content_type || LEARN_LESSON_TYPES.text,
        sponsorId: sponsor_id,
        metadata: {
          isCoreContent: is_core_content,
          minInvestingExperience: min_investing_experience,
          maxInvestingExperience: max_investing_experience,
          publishedAt: published_at,
        },
        lessonContents: json_lesson_content,
        disclaimers: json_disclaimers_content,
      };
    }
  };

  if (lessonId === 'preview') {
    const { blogDisclaimers, disclaimers, contents } = previewLesson;
    const concatDisclaimers = [...Object.values(blogDisclaimers), ...Object.values(disclaimers)];
    const lesson = {
      ...previewLesson,
      lessonContents: Array.isArray(contents) && contents.length === 0 ? null : contents ? JSON.parse(contents) : null,
      disclaimers: concatDisclaimers,
    };

    return {
      isLoading: false,
      lesson: lesson,
    };
  }
  return {
    isLoading,
    wasError,
    lesson: buildLessonData(),
  };
};
