import React from 'react';
import { logErrorEvent } from '../../actions/errorReportingActions';
import { FlatButton } from '../../main/components/buttons';
import { Body5 } from '../../main/lib/nvstr-common-ui.es';
import styled from 'styled-components';
import { Spacing } from '../../main/components/layout';
import { HorizontalNav } from '../Nav/HorizontalNav';
import * as Sentry from '@sentry/react';
import { logMetricsTrackingEvent } from '../../actions';
import { useCurrentUser } from '../../main/hooks/user';

const Wrapper = styled.div`
  padding: 24px;
  text-align: center;
`;
const MessagePosition = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MessageContainer = styled.div`
  padding: 24px;
  width: 90%;
  max-width: 360px;
  margin: 0 auto;

  border-radius: 6px;
  border: ${({ theme }) => `1px solid ${theme.themeColors.border}`};
`;

const PageCrashedDisplay = () => {
  const handleClick = () => {
    if (window?.location?.host === 'localhost:3000') {
      window.location.reload();
    } else {
      window.location = '/';
    }
  };

  return (
    <div>
      <HorizontalNav forceEmptyNav />
      <Wrapper>
        <MessageContainer>
          <div>
            <Body5>Something went wrong. Please reload the page.</Body5>
            <Spacing top={16} center>
              <FlatButton onClick={handleClick}>Reload</FlatButton>
            </Spacing>
          </div>
        </MessageContainer>
      </Wrapper>
    </div>
  );
};

const ErrorBoundary = ({ children }) => {
  const currentUser = useCurrentUser();

  const onBeforeCapture = (scope) => {
    scope.setUser({ id: currentUser?.id });
  };
  const onError = (e) => {
    logMetricsTrackingEvent('Webapp page crash occurred', {
      message: e?.message,
    })();
  };
  return (
    <Sentry.ErrorBoundary fallback={PageCrashedDisplay} beforeCapture={onBeforeCapture} onError={onError}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

class oldErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    logErrorEvent(error, { info })();
  }

  render() {
    if (this.state.hasError) {
      return <PageCrashedDisplay />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
