import React from 'react';
import { connect } from 'react-redux';

import {
  getCurrentUserKarmaLabelFromState,
  getKarmaAnimationTestGroupFromState,
  getKarmaShowMaxPointsReachedTestGroupFromState,
  getKarmaTotalFromState,
  getReachedDailyMaxKarmaStateFromState,
  getShowKarmaFromState,
} from '../../selectors/karma';
import Icon from '../../components/misc/Icon';
import styled from 'styled-components';
import { colorPalette } from '../../main/lib/nvstr-utils.es';

const Wrapper = styled.div`
  & .eye-catching-shadow-sd *,
  & .eye-catching-shadow-sd .text-selector,
  & .eye-catching-shadow-sd {
    background-color: ${({ color, theme }) => color || theme.themeColors.primaryCtaButton} !important;
    color: ${({ color, theme }) => color || theme.themeColors.buttonText} !important;
  }

  & .eye-catching-bg-sd *,
  & .eye-catching-bg-sd .text-selector,
  & .eye-catching-bg-sd {
    background-color: ${({ color, theme }) => color || theme.themeColors.primaryCtaButton} !important;
    color: ${({ color, theme }) => color || theme.themeColors.buttonText} !important;
  }
`;

class AnimatedBadge extends React.PureComponent {
  constructor(props) {
    super(props);

    this.BADGE_EXPAND_ANIMATION_LENGTH = 600;
    this.BADGE_SLIDE_OPEN_ANIMATION_LENGTH = 900;
    this.BADGE_STAY_LENGTH = props.message ? (props.wasPassive ? 2000 : 3000) : 1500;
    this.TOTAL_ANIMATION_TIME_LENGTH =
      this.BADGE_EXPAND_ANIMATION_LENGTH + this.BADGE_SLIDE_OPEN_ANIMATION_LENGTH + this.BADGE_STAY_LENGTH;
  }

  componentDidMount() {
    this.animationCompleteTimeout = this._createAnimationCompletionTimeout();
  }

  componentWillUnmount() {
    if (this.animationCompleteTimeout) clearTimeout(this.animationCompleteTimeout);
  }

  _createAnimationCompletionTimeout = () => {
    const { id } = this.props;

    return setTimeout(() => this._onAnimationComplete(id), this.TOTAL_ANIMATION_TIME_LENGTH);
  };

  _onAnimationComplete = (id) => {
    const { onAnimationComplete } = this.props;
    onAnimationComplete && onAnimationComplete(id);
  };

  render() {
    const { earnedAmount, message, label } = this.props;

    const WIDTH = 160;

    return (
      <Wrapper className={'karma-badge-slide-animation'}>
        <div
          style={{
            position: 'absolute',
            bottom: '30px',
            left: 0,
            right: 0,
            height: 1,
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: '50%',
              right: 0,
              top: 0,
              height: '50px',
              overflow: 'hidden',
            }}
          >
            <div className={'karma-badge-message karma-badge-message-slide-animation'}>
              <div
                className={'eye-catching-shadow-sd flex-center-child'}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: `${-(WIDTH - 10)}px`,
                  height: `${50}px`,
                  width: `${25 + WIDTH}px`,
                  borderTopRightRadius: '25px',
                  borderBottomRightRadius: '25px',
                  paddingLeft: '0px',
                  paddingRight: '15px',
                  lineHeight: 1.2,
                }}
              >
                {message ? (
                  <div style={{ paddingLeft: 35 }}>
                    <span
                      className={'text-selector'}
                      style={{
                        fontSize: '11px',
                        fontWeight: 500,
                      }}
                    >
                      {message}
                    </span>
                  </div>
                ) : (
                  <span
                    className={'text-selector'}
                    style={{
                      fontSize: '14px',
                      fontWeight: 500,
                    }}
                  >
                    {`+ ${earnedAmount} ${label}`}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div
            style={{
              position: 'absolute',
              left: '50%',
              right: 0,
              top: 0,
              height: 50,
            }}
          >
            <div
              className={'eye-catching-bg-sd karma-badge-animation'}
              style={{
                position: 'absolute',
                bottom: 0,
                left: '-25px',
                height: 50,
                width: 50,
                borderRadius: 25,
                paddingTop: 1,
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                }}
              >
                <Icon icon={'fa-trophy'} color={'#fff'} style={{ fontSize: '34px' }} />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

class KarmaGainOverlay extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      animationStack: [],
      animationData: null,
    };

    this.animationId = 0;
  }

  //   componentDidMount() {
  //     setInterval(() => {
  //       this.stackAnimation(1);
  //     }, 5000);
  //   }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { karmaTotal: prevKarmaTotal, reachedDailyMaxKarma: prevReachedDailyMaxKarma } = prevProps;
    const { karmaTotal, reachedDailyMaxKarma } = this.props;

    // const { animationData: prevAnimationData } = prevState;
    const { animationData, animationStack } = this.state;

    if (prevKarmaTotal !== karmaTotal && prevKarmaTotal !== null && !isNaN(prevKarmaTotal) && !isNaN(karmaTotal))
      this.handleKarmaTotalChange(prevKarmaTotal, karmaTotal);

    if (animationData === null && animationStack.length > 0) this.popAnimationFromStack();

    if (!prevReachedDailyMaxKarma && reachedDailyMaxKarma) this.handleDailyLimitReach();
  }

  render() {
    const { karmaLabel, showKarma } = this.props;
    const { animationData } = this.state;

    if (!showKarma) return null;

    return (
      <div
        style={{
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'fixed',
            right: 15,
            bottom: 50,
            left: 15,
            height: 1,
            zIndex: 100005,
            pointerEvents: 'none',
          }}
        >
          {animationData !== null && (
            <AnimatedBadge label={karmaLabel} {...animationData} onAnimationComplete={this.handleAnimationComplete} />
          )}
        </div>
      </div>
    );
  }

  popAnimationFromStack = () => {
    const { animationStack } = this.state;

    const animationData = animationStack.slice(-1)[0];
    this.setState((prevState) => ({
      animationData,
      animationStack: prevState.animationStack.filter((a) => a.id !== animationData.id),
    }));
  };

  handleAnimationComplete = (id) => {
    this.setState(() => ({
      animationData: null,
    }));
  };

  handleKarmaTotalChange = (prev, current) => {
    const { inTestGroup } = this.props;

    if (current < prev) return;

    if (!inTestGroup) return;

    const earnedAmount = current - prev;
    this.stackAnimation(earnedAmount);
  };

  handleDailyLimitReach = () => {
    const { inShowMaxPointsReachedTestGroup, karmaLabel } = this.props;

    if (!inShowMaxPointsReachedTestGroup) return;

    this.stackAnimation(0, `Max Daily Agree/Disagree ${karmaLabel} Achievement`);
  };

  _getNextAnimationId = () => {
    this.animationId = this.animationId + 1;
    return this.animationId;
  };

  stackAnimation = (earnedAmount, message, wasPassive) => {
    const animation = {
      id: this._getNextAnimationId(),
      earnedAmount,
      message,
      wasPassive,
    };
    this.setState((prevState) => ({
      animationStack: [...prevState.animationStack, animation],
    }));
  };
}

const mapStateToProps = (state) => {
  return {
    showKarma: getShowKarmaFromState(state),
    inTestGroup: getKarmaAnimationTestGroupFromState(state) === 2,
    inShowMaxPointsReachedTestGroup: getKarmaShowMaxPointsReachedTestGroupFromState(state) === 1,

    reachedDailyMaxKarma: getReachedDailyMaxKarmaStateFromState(state),
    karmaTotal: getKarmaTotalFromState(state),
    karmaLabel: getCurrentUserKarmaLabelFromState(state),
  };
};

export default connect(mapStateToProps)(KarmaGainOverlay);
