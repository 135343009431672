import React from 'react';

export const TextWrapper = (props) => {
  const { className, style, children } = props;
  return (
    <span className={`text-wrapper-component ${className || ''}`} style={style || {}}>
      {children}
    </span>
  );
};
