import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/paths';
import { pickOne } from '../../../helpers/usefulFuncs';
import Lightbulb from '../../../assets/SVGS/Lightbulb';
import { IconSkeletonBannerActionsUnder } from './templates/IconSkeletonBannerActionsUnder';

const tg1 = {
  id: 1,
  message:
    "See what your friends are trading. For a limited time, invite a friend and you'll both get up to $1,000 to invest when they make a deposit.",
  ctaButtonText: 'Invite Connections',
  route: ROUTES.REWARDS.build(),
};

const tg2 = {
  id: 2,
  message:
    "Successful investors know the value of debating ideas. For a limited time, invite a friend and you'll both get up to $1,000 to invest when they make a deposit.",
  ctaButtonText: 'Invite Friends',
  route: ROUTES.REWARDS.build(),
};

const tgs = [tg1, tg2];

export const ReferralBanner = (props) => {
  const navigate = useNavigate();
  const [testGroup] = React.useState(pickOne(tgs));

  const extendedHandleCTAClick = () => {
    const eventProperties = {
      'Test Group Id': testGroup.id,
    };
    navigate(testGroup.route);

    props.onCTAClick(eventProperties);
  };

  React.useEffect(() => {
    const eventProperties = {
      'Test Group Id': testGroup.id,
    };
    props.onView(eventProperties);
  }, []);

  return (
    <IconSkeletonBannerActionsUnder
      {...props}
      Icon={<Lightbulb />}
      message={testGroup.message}
      onCTAClick={extendedHandleCTAClick}
      ctaButtonText={testGroup.ctaButtonText}
    />
  );
};
