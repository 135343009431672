import React from 'react';

class Icon extends React.PureComponent {
  render() {
    let { icon, color, size: _size, style, iconSkinny, customClass, colorClassName } = this.props;

    const iconSizeDict = {
      'x-small': 'fa-icon-x-small',
      small: 'fa-icon-small',
      nav: 'fa-icon-nav',
      medium: 'fa-icon-medium',
      large: 'fa-icon-large',
      xlarge: 'fa-icon-xlarge',
      supersize: 'fa-icon-supersize',
    };

    color = color ? { color: color } : {};
    let size = '';
    if (_size !== null) {
      size = _size ? iconSizeDict[_size] || 'fa-icon-medium' : 'fa-icon-medium';
    }

    customClass = customClass || '';
    style = style || {};
    const iconClass = `fa ${icon} ${size} ${customClass} ${iconSkinny ? 'icon-skinny' : ''} ${colorClassName || ''}`;

    return <i className={iconClass} style={{ ...style, ...color }} />;
  }
}

export default Icon;
