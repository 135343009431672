import React from 'react';
import { useSelector } from 'react-redux';
import { logMetricsTrackingEvent } from '../../actions';
import { LinkTo } from '../Links/LinkTo';
import RewardsEarned from './components/RewardsEarned';
import RewardLimitReachedMessage from './components/RewardLimitReachedMessage';
import { returnPathTo, ROUTES } from '../../constants/paths';
import { pluralizeIfAppropriate } from '../../helpers/generalHelpers';
import {
  getIsCurrentUserLiveTradingFromState,
  getReferralCashRewardRequiresLiveAccountFromState,
} from '../../selectors/currentUser';
import { getRewardsTotalRedeemedFromState } from '../../selectors/rewards';
import { RewardCard } from '../../main/components/rewards/RewardCard';
import { withRouter } from '../../main/utils';

const OpenLiveAccount = ({ TEST_GROUP_TRACKING_DATA }) => {
  const referralCashRewardRequiresLiveAccount = useSelector(getReferralCashRewardRequiresLiveAccountFromState);
  const isLiveTrading = useSelector(getIsCurrentUserLiveTradingFromState);
  const redeemedRewardsTotal = useSelector(getRewardsTotalRedeemedFromState);

  if (isLiveTrading || !referralCashRewardRequiresLiveAccount) {
    return null;
  }

  const logOpenLiveAccountClick = () => {
    const event = 'Clicked Open Live Account';
    const properties = {
      Context: 'Referrals Page',
      ...(TEST_GROUP_TRACKING_DATA || {}),
    };
    logMetricsTrackingEvent(event, properties)();
  };

  return (
    <div className={'open-live-account-rewards-message'}>
      <span>
        {redeemedRewardsTotal === 0
          ? 'Open your live trading account to access referral rewards.'
          : 'Open your live trading account to access it.'}
      </span>
      <div className={'open-live-account-btn-container'}>
        <LinkTo
          text={'Get Started'}
          to={returnPathTo('registerLiveAccountFormStart')}
          className="btn btn-primary-color btn-small-tall"
          additionalClickAction={logOpenLiveAccountClick}
          scrollToTop
        />
      </div>
    </div>
  );
};

const UnredeemedRewardsRedemption = ({
  TEST_GROUP_TRACKING_DATA,

  isUserEligibleForRewards,

  unredeemedRewardsCount,
  hasReachedRewardLimit,

  navigate,
}) => {
  const logRedeemRewardsClick = () => {
    const event = 'Clicked Redeem Rewards';
    const properties = {
      'Redeemable Count': unredeemedRewardsCount,
      ...(TEST_GROUP_TRACKING_DATA || {}),
    };
    logMetricsTrackingEvent(event, properties)();
  };

  const handleRedeemRewardsClick = () => {
    navigate(ROUTES.REWARD_REDEMPTION.build());
    logRedeemRewardsClick();
  };

  if (!isUserEligibleForRewards)
    return (
      <div className={'ineligible-for-referral-program-container'}>
        {"Sorry, you're not eligible to receive rewards."}
      </div>
    );

  if (hasReachedRewardLimit)
    return (
      <div>
        <RewardLimitReachedMessage />

        <OpenLiveAccount TEST_GROUP_TRACKING_DATA={TEST_GROUP_TRACKING_DATA} />
      </div>
    );

  if (!(unredeemedRewardsCount > 0))
    return (
      <div>
        <RewardsEarned />

        <OpenLiveAccount TEST_GROUP_TRACKING_DATA={TEST_GROUP_TRACKING_DATA} />
      </div>
    );

  return (
    <div>
      <div className={'rewards-available-message'}>
        {`You have ${unredeemedRewardsCount} new cash ${pluralizeIfAppropriate('reward', unredeemedRewardsCount)}!`}
      </div>

      <div className={'rewards-card-container'}>
        <RewardCard onClick={handleRedeemRewardsClick} />
      </div>

      <RewardsEarned />

      <OpenLiveAccount TEST_GROUP_TRACKING_DATA={TEST_GROUP_TRACKING_DATA} />
    </div>
  );
};

export default withRouter(UnredeemedRewardsRedemption);
