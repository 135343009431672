import React from 'react';

import SocialThoughtNotification from '../Notifications/components/types/SocialThoughtNotification';
import ThoughtNotification from '../Notifications/components/types/ThoughtNotification';
import ThoughtContainer from './ThoughtContainer';
import BasicSecurityDataWrapper from '../SecurityData/BasicSecurityDataWrapper';

import { ThoughtType } from './components/ThoughtType';
import SecurityIdentifierDisplay from '../UI/SecurityIdentifierDisplay';

import { returnSecurityIdFromThought } from '../../helpers/thoughtsHelpers';
import { getThoughtImpressionEventName } from '../../constants/tracking';

export const ThoughtFeedItem = (props) => {
  const {
    data,
    viewingContext,
    afterTrade,
    withThoughtLink,
    linkToSecurity,
    ignoreImpressionTracking,
    inFeed,
    inActivityPageFeed,
    notificationIndex,

    handleAdditionalPromptForThoughtAfterDisagreeAction,
    handleAfterThoughtAction,
  } = props;

  const notification = data.notification;
  const isItemANotification = !!notification;
  const thought = isItemANotification ? notification.data.thought : data;

  const _renderThoughtHeadline = () => {
    return (
      <div className={'thought-feed-thought-headline'}>
        <span style={{ marginRight: '5px' }}>
          <ThoughtType thought={thought} />
        </span>
        <span style={{ marginRight: '5px' }}>for</span>
        <span style={{ marginRight: '-1px' }}>
          <BasicSecurityDataWrapper securityId={returnSecurityIdFromThought(thought)}>
            <SecurityIdentifierDisplay
              prefix={'('}
              suffix={')'}
              withLink={linkToSecurity || false}
              inFeed={inFeed}
              showSymbol
              showName
              limitNameWidth
            />
          </BasicSecurityDataWrapper>
        </span>
        <span>from the community</span>
      </div>
    );
  };

  const renderThought = () => {
    return (
      <div className={'react-thought-notification'}>
        {_renderThoughtHeadline()}
        <BasicSecurityDataWrapper securityId={returnSecurityIdFromThought(thought)}>
          <ThoughtContainer
            thoughtId={thought.id}
            viewingContext={
              afterTrade ? getThoughtImpressionEventName('THOUGHT_CAROUSEL_IN_AFTER_TRADE_PANEL') : viewingContext
            }
            afterTrade={afterTrade}
            handleAdditionalPromptForThoughtAfterDisagreeAction={handleAdditionalPromptForThoughtAfterDisagreeAction}
            handleAfterThoughtAction={handleAfterThoughtAction}
            ignoreImpressionTracking={ignoreImpressionTracking}
            scrollContainerSelector={props.scrollContainerSelector}
            inFeed={inFeed}
            inActivityPageFeed={inActivityPageFeed}
            showAlternateOpinionActionStyle
            isUserAuthed
            hideOpinionCounts
            withThoughtLink={withThoughtLink || false}
            isSuggestedThought
            useShareInModal
          />
        </BasicSecurityDataWrapper>
      </div>
    );
  };

  const renderThoughtNotification = () => {
    return (
      <div className={'react-thought-notification'}>
        {props.thoughtCleanStyle ? (
          <SocialThoughtNotification
            notification={notification}
            notificationIndex={notificationIndex}
            ignoreImpressionTracking={ignoreImpressionTracking}
            scrollContainerSelector={props.scrollContainerSelector}
            handleAfterThoughtAction={handleAfterThoughtAction}
            handleAdditionalPromptForThoughtAfterDisagreeAction={handleAdditionalPromptForThoughtAfterDisagreeAction}
            inFeed={inFeed}
            inActivityPageFeed={inActivityPageFeed}
            renderProfileAvatarHeading
          />
        ) : (
          <ThoughtNotification
            notification={notification}
            notificationIndex={notificationIndex}
            ignoreImpressionTracking={ignoreImpressionTracking}
            scrollContainerSelector={props.scrollContainerSelector}
            handleAfterThoughtAction={handleAfterThoughtAction}
            handleAdditionalPromptForThoughtAfterDisagreeAction={handleAdditionalPromptForThoughtAfterDisagreeAction}
            inFeed={inFeed}
            inActivityPageFeed={inActivityPageFeed}
            renderProfileAvatarHeading
          />
        )}
      </div>
    );
  };

  return (
    <div className={'thought-feed-item-container'}>
      {isItemANotification ? renderThoughtNotification() : renderThought()}
    </div>
  );
};
