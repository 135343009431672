import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useCurrentUser } from '../../hooks/user';
import { logMetricsTrackingEvent, submitLiveTradingSignUpForm } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import { H5, Body5, Lock } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { useLiveAccount } from '../../hooks/user';
import { useFormik } from 'formik';
import { IdentitySSNSchema } from '../../constants/formValidationSchemas';
import SocialTextField from '../../../components/form/SocialTextField';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { OP_CO_NAME, PRODUCT_NAMES } from '../../../appConfig';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const InputWrapper = styled.div`
  padding-top: 8px;

  input {
    max-width: 80px;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }

  .floating-label-text-field {
    width: 100%;
  }
  .text-field {
    width: 100%;
  }
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 16px;
`;
const PrivacyMessage = styled.div`
  padding-top: 4px;
`;
const SubMessage = styled.div`
  padding-top: 8px;
`;
const IconMessage = styled.div`
  padding-top: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  text-align: left;

  svg {
    height: 24px;
    width: 24px;

    fill: ${({ theme }) => theme.themeColors.text};

    margin-right: 16px;
  }
`;
const Actions = styled.div`
  padding-top: 24px;
  text-align: center;
`;

const getInitialValuesFrom = (currentUser, liveAccount) => {
  const { social_security_number } = liveAccount;
  const alreadyEntered = social_security_number?.includes('*');
  return {
    social_security_number: '',
    has_entered_valid_ssn: alreadyEntered,
    acknowledged_ecbsv_agreement: true,
  };
};

const massageFormData = (v) => v;

const FORM_PAGE_NAME = 'identity';

const SSNAuthorizationAgreement = () => {
  return (
    <Message>
      <Body5 bold style={{ marginTop: '8px' }} isSmall>
        Authorization for the Social Security Administration to Disclose Your Social Security Number Verification
      </Body5>
      <SubMessage>
        <Body5 isSmall>
          I authorize the Social Security Administration (SSA) to verify and disclose to {OP_CO_NAME} (
          {PRODUCT_NAMES.Tornado}), directly or through Socure as its service provider, for the purpose of this
          transaction, whether the name, Social Security Number (SSN) and date of birth I have submitted match
          information in SSA records. My consent is for a one-time validation within the next 90 days.
        </Body5>
      </SubMessage>
      <SubMessage>
        <Body5 isSmall>
          By pressing CONTINUE, I am signing the consent for SSA to disclose my SSN Verification to{' '}
          {PRODUCT_NAMES.Tornado} and Socure. I agree that my electronic signature has the same legal meaning, validity,
          and effect as my handwritten signature.
        </Body5>
      </SubMessage>
    </Message>
  );
};

export const EnterSSN = ({ onContinue, onboardingState }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const liveAccount = useLiveAccount();

  const [isReady, setIsReady] = React.useState(true);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const form = useFormik({
    initialValues: getInitialValuesFrom(currentUser, liveAccount),
    validationSchema: IdentitySSNSchema,
    enableReinitialize: false,
  });
  const { values, errors, setFieldValue, validateForm } = form;

  const [hasAlreadyEnteredValidSSN] = React.useState(values.has_entered_valid_ssn);
  const [isEditingPreviouslyEnteredSSN, setIsEditingPreviouslyEnteredSSN] = React.useState(false);

  const handleChange = React.useCallback(
    (e) => {
      const name = 'social_security_number';
      const {
        target: { value },
      } = e;
      setFieldValue(name, value, false);
    },
    [setFieldValue]
  );

  const submitForm = React.useCallback((values) => {
    const apiFormData = massageFormData(values);
    setIsSubmitting(true);
    return submitLiveTradingSignUpForm(apiFormData, FORM_PAGE_NAME)(dispatch);
  }, []);

  const handleSubmit = React.useCallback(async () => {
    logMetricsTrackingEvent('Submitted Identity SSN Form')();

    const validation = await validateForm();

    if (hasAlreadyEnteredValidSSN && !isEditingPreviouslyEnteredSSN) {
      return {
        ok: true,
      };
    }

    if (Object.keys(validation).length === 0) {
      const { status } = await submitForm(values);
      if (status === 200) {
        return {
          ok: true,
        };
      } else {
        setFormError('Something went wrong, please try again');
        setIsSubmitting(false);
        return {
          ok: false,
        };
      }
    } else {
      logMetricsTrackingEvent('Failed Live Account Validations', {
        Fields: Object.entries(validation),
        'Form Name': 'ssn',
      })();

      // could log errors but no form validations on this form
      setIsSubmitting(false);
      return {
        errors: true,
      };
    }
  }, [values]);

  const handleReenterClick = React.useCallback(async () => {
    setIsEditingPreviouslyEnteredSSN(true);
  }, [setIsEditingPreviouslyEnteredSSN]);

  const handleContinueClick = React.useCallback(async () => {
    const { ok } = await handleSubmit();
    if (ok) onContinue(null, onboardingState);
  }, [handleSubmit]);

  React.useEffect(() => {
    logMetricsTrackingEvent('View Identity SSN Form')();
  }, []);

  if (!isReady) return <LoadingPage />;

  if (hasAlreadyEnteredValidSSN && !isEditingPreviouslyEnteredSSN) {
    return (
      <LocationContext.Provider value={TRACKING_ROUTE_NAMES.enterSSN}>
        <Page width={'600px'}>
          <ContentWrapper>
            <Heading>
              <H5>What is your Social Security Number?</H5>
            </Heading>

            <Message>
              <Body5 bold style={{ marginTop: '8px' }}>
                You have already completed this step
              </Body5>
            </Message>

            <Actions>
              <FormState error={formError} isSubmitting={isSubmitting} />
              <Container bottom={8} centerAll fullWidth>
                <FlatButton transparent isDisabled={isSubmitting} fullWidth onClick={handleReenterClick}>
                  Re-Enter
                </FlatButton>
              </Container>
              <Container centerAll fullWidth>
                <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleContinueClick}>
                  Continue
                </FlatButton>
              </Container>
            </Actions>
          </ContentWrapper>
        </Page>
      </LocationContext.Provider>
    );
  }

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.enterSSN}>
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>Enter Social Security Number</H5>
          </Heading>

          <InputWrapper>
            <SocialTextField
              name={'social_security_number'}
              value={values.social_security_number}
              label={''}
              error={errors.social_security_number || null}
              handleChange={handleChange}
              handleBlur={() => false}
              handleFocus={() => false}
              autoFocus
            />
          </InputWrapper>

          <SSNAuthorizationAgreement />

          <Actions>
            <FormState error={formError} isSubmitting={isSubmitting} />
            <Container fullWidth centerAll>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleContinueClick}>
                Continue
              </FlatButton>
            </Container>
          </Actions>

          <PrivacyMessage>
            <IconMessage>
              <Lock />
              <Body5 isSmall>
                This information will be kept private and it will never appear on your public profile.
              </Body5>
            </IconMessage>
          </PrivacyMessage>
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
