import React from 'react';
import { cleanInputValue, INPUT_REGEX_LOOKUP } from '../../helpers/inputHelpers';
import { roundFloat, isUndefinedOrNull } from '../../helpers/generalHelpers';

class RebalanceAllocInput extends React.PureComponent {
  constructor() {
    super();
    this.EMPTY_VALUE = ' - ';

    this.state = {
      isFocused: false,
    };
  }

  componentDidMount() {
    this.handleBlur();
  }

  render() {
    const { securityId, inputProps, value } = this.props;
    return (
      <div className="rebalance-allocation-input border-accent input-bg">
        <input
          type={'text'}
          name={`rebalance-allocation-input-${securityId}`}
          className={value === this.EMPTY_VALUE ? 'pad-right' : ''}
          placeholder={''}
          value={this.getValue()}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          {...inputProps}
        />
        {value !== this.EMPTY_VALUE && <div className="trailing-input-percent">%</div>}
      </div>
    );
  }

  getValue = () => {
    const { value } = this.props;
    const { isFocused } = this.state;

    if (isFocused && isNaN(value) && typeof value !== 'string') {
      return '0';
    }

    return value;
  };

  setIsFocused = (v) => {
    this.setState(() => ({
      isFocused: v,
    }));
  };

  handleFocus = () => {
    const { value } = this.props;

    this.setIsFocused(true);

    if (value === this.EMPTY_VALUE) {
      const { securityId, handleChange } = this.props;
      handleChange(securityId, 0);
    }
  };

  handleBlur = () => {
    const { value, securityId, roundToDecimalPlace, handleChange } = this.props;

    this.setIsFocused(false);

    if (value === 0 || value === '0' || value === '') {
      handleChange(securityId, this.EMPTY_VALUE);
    } else {
      if (isUndefinedOrNull(value)) return;

      if (typeof value === 'string') {
        const sanitizedValue = roundFloat(
          parseFloat(cleanInputValue(value, INPUT_REGEX_LOOKUP.FLOAT)),
          roundToDecimalPlace
        );
        handleChange(securityId, sanitizedValue);
      } else {
        const sanitizedValue = roundFloat(value, roundToDecimalPlace);
        handleChange(securityId, sanitizedValue);
      }
    }
  };

  handleChange = (e) => {
    const { securityId, handleChange } = this.props;
    const value = e.target.value;
    const sanitizedValue = cleanInputValue(value, INPUT_REGEX_LOOKUP.FLOAT);
    handleChange(securityId, sanitizedValue);
  };
}

RebalanceAllocInput.defaultProps = {
  inputProps: {},
};

export default RebalanceAllocInput;
