import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/index';
import Overlay from '../layout/Overlay';
import Icon from './Icon';
import RestrictHeight from '../../containers/ExtraFunctionalityComponents/RestrictHeight';

class Modal extends React.Component {
  _returnMaxWidth = () => (this._returnModal().size === 'wide' ? '600px' : '300px');

  render() {
    if (!this._shouldHideModal()) {
      return null;
    }

    if (this._returnModal().customBody) {
      return (
        <Overlay onClick={this._handleOverlayDismiss} customClass={'priority-z-index'}>
          {this._returnModal().customBody}
        </Overlay>
      );
    }

    const ModalBody = (
      <div
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: this._returnMaxWidth(),
        }}
      >
        <div
          key={`modal-${this._returnModal().key || '0'}`}
          className={`react-modal component-bg ${this._returnModal().className || ''}`}
          style={{ maxWidth: this._returnMaxWidth(), width: '100%' }}
          onClick={this._stopClickPropagationToOverlay}
        >
          {this._isDismissable() && (
            <div className={'modal-dismiss'} onClick={this._handleDismiss}>
              <Icon icon="fa-times-thin" colorClassName="secondary-text-color" />
            </div>
          )}
          {this._renderHeading()}
          {this._renderMessage()}
          {this._renderContentComponent()}
          {this._renderConfirmationButtons()}
        </div>
      </div>
    );

    return (
      <Overlay onClick={this._handleOverlayDismiss} customClass={'priority-z-index'}>
        {this._returnModal().restrictHeight ? (
          <RestrictHeight heightOffsetBuffer={20} screenHeight minHeight={750}>
            {ModalBody}
          </RestrictHeight>
        ) : (
          ModalBody
        )}
      </Overlay>
    );
  }

  _renderHeading = () => {
    return this._returnModal().heading ? (
      <div className={`modal-heading ${this._returnModal().customHeadingClass || ''}`}>
        {this._returnModal().icon && (
          <Icon
            icon={this._returnModal().icon.className}
            color={this._returnModal().icon.color}
            style={this._returnModal().icon.style}
          />
        )}
        &nbsp;
        {this._returnModal().heading}
        &nbsp;
        {this._returnModal().requireLoading && <i className={`${'fa fa-spinner spin'}`} />}
      </div>
    ) : (
      <div />
    );
  };

  _renderMessage = () => {
    return this._returnModal().message ? (
      <div className="modal-message">
        <p>{this._returnModal().message}</p>
      </div>
    ) : (
      <div />
    );
  };

  _renderContentComponent = () => {
    return this._returnModal().contentComponent ? (
      <div className="modal-contentComponent">{this._returnModal().contentComponent}</div>
    ) : (
      <div />
    );
  };

  _renderConfirmationButtons = () => {
    return this._returnModal().confirmation ? (
      <div className="modal-confirmation">
        <div className="modal-button modal-confirm-button" onClick={this._handleConfirmation}>
          Continue
        </div>
        <div className="modal-button modal-cancel-button" onClick={this._handleDismiss}>
          Cancel
        </div>
      </div>
    ) : (
      <div />
    );
  };

  _returnModal = () => this.props.modal;

  _isDismissable = () => this._returnModal().dismissable;

  _shouldHideModal = () => {
    return (
      !!this._returnModal().heading ||
      !!this._returnModal().message ||
      !!this._returnModal().contentComponent ||
      !!this._returnModal().customBody
    );
  };

  _handleOverlayDismiss = () => {
    if (this._isDismissable()) {
      if (!this._returnModal().overrideOverlayDismiss) {
        return this._handleDismiss();
      } else {
        this._returnModal().overrideOverlayDismiss();
      }
    }
  };

  _handleDismiss = () => {
    const modal = this._returnModal();
    if (modal.overrideDismissWithoutDismissModalAction) {
      return modal.overrideDismiss();
    }
    if (modal.overrideDismiss) {
      modal.overrideDismiss();
      this._dismissModal();
    } else {
      this._dismissModal();
    }
  };

  _dismissModal = () => {
    this.props.actions.hideModal();
  };

  _stopClickPropagationToOverlay = (e) => {
    e.stopPropagation();
  };

  _handleConfirmation = (e) => {
    e.stopPropagation();
    this.props.actions.confirmModal();
  };
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
