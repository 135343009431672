import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import * as Actions from '../../actions/index';

import Button from '../../components/buttons/Button';
import Checkbox from '../../components/form/Checkbox';
import FullPageCard from '../../components/layout/FullPageCard';

import { handleToggleClick, createInputComponent } from '../../helpers/formHelpers';
import { hasCurrentUserAgreedBDTerms } from '../../helpers/currentUserHelpers';
import { returnPathTo } from '../../constants/paths';
import { FlatButton } from '../../main/components/buttons';

class BDTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: [
        {
          type: Checkbox,
          typeName: 'Checkbox',
          name: 'bd_terms',
          required: true,
          props: {
            values: [true, false],
          },
        },
      ],
      formData: {
        bd_terms: false,
      },
      errors: {},
      canContinue: false,

      errorMessage: null, // for not clicking checkbox
    };
  }

  componentDidMount() {
    if (this.hasCurrentUserAgreedToBDTerms()) {
      this.props.navigate(returnPathTo('root'));
    }
  }

  render() {
    const { errorMessage } = this.state;
    const checkboxInput = this.state.inputs[0];
    return (
      <div className="bd-terms-container">
        <FullPageCard>
          <div className="full-page-card-heading">Welcome to {PRODUCT_DISPLAY_NAME}</div>
          <div className="full-page-card-content">
            <div className="bd-terms-acknowledgement">
              <div className="bd-terms-checkbox">{this.renderCheckbox(checkboxInput)}</div>
              <div className="bd-terms-statement">
                I have read and agree to the{' '}
                <a href="/terms_of_service" target="_blank">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a href="/privacy_policy" target="_blank">
                  Privacy Policy
                </a>
                .
              </div>
            </div>
          </div>
          <div className="full-page-card-action-button">
            <FlatButton onClick={this.handleContinueClick}>Continue</FlatButton>
          </div>
          {errorMessage && (
            <div style={{ paddingTop: '25px' }}>
              <span className="error-text-color">{errorMessage}</span>
            </div>
          )}
        </FullPageCard>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.formData.bd_terms && this.state.formData.bd_terms) {
      this.setState(() => ({
        canContinue: true,
      }));
    }
    if (prevState.formData.bd_terms && !this.state.formData.bd_terms) {
      this.setState(() => ({
        canContinue: false,
      }));
    }
  }

  handleContinueClick = () => {
    return this.state.canContinue
      ? this.handleContinue()
      : () => {
          this.setState(() => ({
            errorMessage:
              'You must click the checkbox to confirm you have read and agree to the Terms of Service and Privacy Policy before continuing.',
          }));
          false;
        };
  };

  handleContinue = () => {
    this.submitAgreeToBDTerms();
    this._logAgreeToBDTermsSubmit();
  };

  _navigateBackToOriginalDestination = () => {
    const previousDesinationURL = this.returnAppStorage().destinationURL;
    if (previousDesinationURL) {
      this.props.navigate(previousDesinationURL);
      this.props.actions.removeKeyAppStorage('destinationURL');
    } else {
      this.props.navigate(returnPathTo('root'));
    }
  };

  submitAgreeToBDTerms = () => {
    this.props.actions.postAgreedToBDTerms().then((responseStatusObj) => {
      if (responseStatusObj.success) {
        this._navigateBackToOriginalDestination();
      } else {
        this._displayErrorMessage(responseStatusObj.error);
      }
    });
  };

  _displayErrorMessage = (message) => {
    const contentComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {message}
      </div>
    );
    this.props.actions.showModal({
      contentComponent,
      size: 'wide',
      dismissable: true,
    });
  };

  returnInputDefaultProps() {
    return {
      handleToggleClick: handleToggleClick.bind(this),
      isChecked: function(name) {
        return this.state.formData[name];
      }.bind(this),
      getErrorMessage: function(name) {
        return this.state.errors[name];
      }.bind(this),
    };
  }

  renderCheckbox = (checkboxInput) => {
    return createInputComponent(checkboxInput, this.returnInputDefaultProps.call(this), checkboxInput.name);
  };

  returnAppStorage = () => this.props.appStorage;

  hasCurrentUserAgreedToBDTerms = () => hasCurrentUserAgreedBDTerms(this.returnCurrentUser());

  returnCurrentUser = () => this.props.currentUser;

  _logAgreeToBDTermsSubmit = () => {
    const event = 'Submitted Accept BD Terms';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => ({
  appStorage: state.appStorage,
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BDTerms);
