import { ideasAdapter } from '../adapters/ideasAdapter';
import { clearOptimizerCooldown, refreshOptimizationData } from '../actions';
import { quickFetchSecuritiesData, fetchSecuritiesPriceData } from '../actions/securitiesActions';
import {
  LOADING_IDEAS_FOR_SECURITY,
  LOADING_IDEAS_FOR_USER,
  LOADING_IDEAS,
  ADD_IDEAS_FOR_SECURITY,
  ADD_USER_IDEAS,
  ADD_CURRENT_USER_IDEAS,
  ADD_IDEAS,
  CURRENT_USER_CREATING_IDEAS,
  UPDATE_IDEA,
  REMOVE_CURRENT_USER_CREATING_IDEAS,
  REMOVE_IDEA,
  SHOW_MODAL,
} from '../constants';

import { getIdeaPhraseFor } from '../helpers/terminologyHelpers';
import { serializeIdea, getSecurityIdFromIdea } from '../helpers/ideaHelpers';
import { requestRequiresAuthentication } from '../helpers/authHelpers';
import { fetchSecurityDataForIdeas } from '../helpers/securitiesHelpers';
import { createBasicErrorModal } from '../constants/modals';
import { handleNoResponseInAction } from '../helpers/errorHelpers';
import { moment } from '../helpers/timeHelpers';
import { activeSecuritiesTracker, LivePricePollingManager } from '../main/classes/LivePricePollingManager';

export const getIdeasForSecurity = (securityIds) => {
  return function (dispatch) {
    dispatch({
      type: LOADING_IDEAS_FOR_SECURITY,
      payload: securityIds,
    });
    return ideasAdapter.getIdeasForSecurity(securityIds).then((response) => {
      if (response && response.data && response.data.ideas) {
        const ideas = response.data.ideas;
        dispatch({
          type: ADD_IDEAS_FOR_SECURITY,
          payload: {
            ideas,
            ids: securityIds,
          },
        });
      }
    });
  };
};

export const getIdeasForUsers = (userIds) => {
  return function (dispatch) {
    dispatch({
      type: LOADING_IDEAS_FOR_USER,
      payload: userIds,
    });
    return ideasAdapter.getIdeasForUsers(userIds).then((response) => {
      if (response && response.data && response.data.ideas) {
        const ideas = response.data.ideas;
        dispatch({
          type: ADD_USER_IDEAS,
          payload: {
            ideas,
            ids: userIds,
          },
        });
      }
    });
  };
};

export const getIdeas = (ideaIds) => {
  // No endpoint for this yet
  return function (dispatch) {
    dispatch({
      type: LOADING_IDEAS,
      payload: ideaIds,
    });
    return ideasAdapter.getIdeas(ideaIds).then((response) => {
      if (response && 'ideas' in response) {
        const ideas = response.ideas;
        dispatch({
          type: ADD_IDEAS,
          payload: ideas,
        });
      }
    });
  };
};

export const getCurrentUserIdeas = () => {
  return function (dispatch) {
    return ideasAdapter.getCurrentUserIdeas().then((response) => {
      if (response && response.data && 'ideas' in response.data) {
        const ideas = response.data.ideas;
        dispatch({
          type: ADD_CURRENT_USER_IDEAS,
          payload: { ideas },
        });

        const securityIds = ideas.map((i) => getSecurityIdFromIdea(i));
        quickFetchSecuritiesData(securityIds)(dispatch).then((response) => {
          const securities = response?.securities;
          const addToPricing = [];
          if (securities?.length > 0) {
            securities.map((security) => {
              const d = {
                id: security.id,
                symbol: security.symbol,
              };
              addToPricing.push(d);
            });
          }
          addToPricing.length > 0 && activeSecuritiesTracker.addSecurities(addToPricing);
        });
        fetchSecuritiesPriceData(securityIds)(dispatch);
        return ideas;
      } else {
        return response;
      }
    });
  };
};

export const fetchIdeas = (userId) => {
  return function (dispatch) {
    return ideasAdapter.fetchIdeas(userId).then((response) => {
      const { status, data, errorMessage } = response;
      if (status === 200) {
        const { ideas } = data;
        dispatch({
          type: ADD_IDEAS,
          payload: ideas,
        });
        return { ok: true, ideas };
      } else {
        return { ok: false, errorMessage };
      }
    });
  };
};

export const viewIdea = (ideaId) => {
  return function () {
    return ideasAdapter.viewIdea(ideaId);
  };
};

export const fetchSecurityIdFromIdeaId = (ideaId) => {
  return function () {
    return ideasAdapter
      .fetchSecurityIdFromIdeaId(ideaId)
      .then((response) => (response && response.data ? response.data.idea_securities : response));
  };
};

export const fetchCommunityIdeas = () => {
  return function () {
    return ideasAdapter.fetchCommunityIdeas();
  };
};

export const addIdea = (idea) => {
  return async function (dispatch) {
    const serializedIdea = serializeIdea(idea, 'create');
    const { status, data } = await ideasAdapter.addIdea([serializedIdea]);

    const ideaInResponse = data?.ideas && data.ideas[0];
    const wasErrorInIdea = ideaInResponse?.status === 'error';
    if (status === 200 && !wasErrorInIdea) {
      refreshOptimizationData()(dispatch);

      dispatch({
        type: ADD_IDEAS,
        payload: data?.ideas || [],
      });

      return {
        ok: true,
        data,
      };
    } else {
      return {
        error: true,
        data,
      };
    }
  };
};
export const addIdeas = (ideas) => {
  // NOTE: USED TO CREATE USER IDEAS
  // TODO: In desperate need of refactoring

  return function (dispatch) {
    dispatch({
      type: CURRENT_USER_CREATING_IDEAS,
      payload: ideas.map((idea) => getSecurityIdFromIdea(idea)),
    });
    const serializedIdeas = ideas.map((idea) => serializeIdea(idea, 'create'));
    return ideasAdapter.addIdeas(serializedIdeas).then((response) => {
      const resetAction = () => {
        dispatch({
          type: REMOVE_CURRENT_USER_CREATING_IDEAS,
          payload: ideas.map((idea) => getSecurityIdFromIdea(idea)),
        });
      };
      if (handleNoResponseInAction(response, dispatch, resetAction)) {
        return response;
      }
      requestRequiresAuthentication(response);

      if (!response || !response.ideas) {
        const errorModal = {
          heading: getIdeaPhraseFor('add_error'),
          message: 'Error message: Something went wrong, please try again.',
          size: 'wide',
          icon: {
            className: 'fa-exclamation-circle',
            color: '#ff7666',
            style: {
              fontSize: '22px',
              marginRight: '4px',
              paddingBottom: '4px',
            },
          },
          redirect: false,
          dismissable: true,
        };

        dispatch({
          type: REMOVE_CURRENT_USER_CREATING_IDEAS,
          payload: ideas.map((idea) => getSecurityIdFromIdea(idea)),
        });

        dispatch({
          type: SHOW_MODAL,
          payload: errorModal,
        });

        return {
          error: true,
          response,
        };
      }

      const ideasWithErrors = response.ideas.filter((idea) => idea.status === 'error');
      const errorMsg =
        ideasWithErrors[0] &&
        ideasWithErrors[0].error === 'User already has an idea for this security. Send the idea_id to update it.'
          ? getIdeaPhraseFor('already_have')
          : 'Please try again later';

      if (ideasWithErrors.length === 0) {
        dispatch({
          type: ADD_IDEAS,
          payload: response.ideas,
        });

        clearOptimizerCooldown()(dispatch);
        refreshOptimizationData()(dispatch);
        return response;
      } else {
        const errorModal = {
          heading: getIdeaPhraseFor('add_error'),
          message: `Error message: ${errorMsg}`,
          size: 'wide',
          icon: {
            className: 'fa-exclamation-circle',
            color: '#ff7666',
            style: {
              fontSize: '22px',
              marginRight: '4px',
              paddingBottom: '4px',
            },
          },
          redirect: false,
          dismissable: true,
        };

        dispatch({
          type: REMOVE_CURRENT_USER_CREATING_IDEAS,
          payload: ideas.map((idea) => getSecurityIdFromIdea(idea)),
        });

        dispatch({
          type: SHOW_MODAL,
          payload: errorModal,
        });
      }

      return response;
    });
  };
};
export const copyIdea = (sourceIdea, userHasIdea) => {
  return function (dispatch) {
    const ideaId = sourceIdea.idea_id;
    const securityId = getSecurityIdFromIdea(sourceIdea);
    if (!userHasIdea) {
      dispatch({
        type: CURRENT_USER_CREATING_IDEAS,
        payload: [securityId],
      });
    }

    return ideasAdapter.copyIdea(ideaId).then((response) => {
      const resetAction = () => {
        dispatch({
          type: REMOVE_CURRENT_USER_CREATING_IDEAS,
          payload: [securityId],
        });
      };
      if (handleNoResponseInAction(response, dispatch, resetAction)) {
        return response;
      }
      requestRequiresAuthentication(response);

      const idea = response;
      const ideaHasError = idea.status === 'error';
      const errorMsg =
        ideaHasError && idea.error === 'User already has an idea for this security. Send the idea_id to update it.'
          ? getIdeaPhraseFor('already_have')
          : 'Please try again later.';

      if (!ideaHasError) {
        dispatch({
          type: UPDATE_IDEA,
          payload: idea,
        });

        if (!userHasIdea) {
          dispatch({
            type: REMOVE_CURRENT_USER_CREATING_IDEAS,
            payload: [securityId],
          });
        }

        clearOptimizerCooldown()(dispatch);
        refreshOptimizationData()(dispatch);
        return response;
      } else {
        const errorModal = {
          heading: getIdeaPhraseFor('add_error'),
          message: `Error message: ${errorMsg}`,
          size: 'wide',
          icon: {
            className: 'fa-exclamation-circle',
            color: '#ff7666',
            style: {
              fontSize: '22px',
              marginRight: '4px',
              paddingBottom: '4px',
            },
          },
          redirect: false,
          dismissable: true,
        };

        if (!userHasIdea) {
          dispatch({
            type: REMOVE_CURRENT_USER_CREATING_IDEAS,
            payload: [securityId],
          });
        }

        dispatch({
          type: SHOW_MODAL,
          payload: errorModal,
        });
      }
      return response;
    });
  };
};

export const updateIdea = (idea, ideaToRollbackTo) => {
  // WARNING: DO NOT UPDATE MORE THAN ONE IDEA AT A TIME WITH THIS LOGIC

  return function (dispatch) {
    const partialIdea = idea;

    dispatch({
      type: UPDATE_IDEA,
      payload: partialIdea,
    });

    const serializedIdea = serializeIdea(idea, 'update');
    return ideasAdapter.updateIdea(serializedIdea).then((response) => {
      requestRequiresAuthentication(response);

      const ideas = (response && response.ideas) || [];
      const ideasWithErrors = ideas.filter((idea) => idea.status === 'error');
      let ajaxWasSuccessful = true;

      if (!response || ideasWithErrors.length > 0 || !('ideas' in response)) {
        dispatch({
          type: UPDATE_IDEA,
          payload: ideaToRollbackTo,
        });

        const errorModal = {
          heading: getIdeaPhraseFor('update_error'),
          message: 'Please try again later.',
          size: 'wide',
          icon: {
            className: 'fa-exclamation-circle',
            color: '#ff7666',
            style: {
              fontSize: '22px',
              marginRight: '4px',
              paddingBottom: '4px',
            },
          },
          redirect: false,
          dismissable: true,
        };

        if (!response) {
          const modalMessage = 'Request could not be completed. Please try again later.';
          const requestFailedModal = {
            contentComponent: createBasicErrorModal(modalMessage),
            dismissable: true,
            size: 'wide',
          };
          dispatch({
            type: SHOW_MODAL,
            payload: requestFailedModal,
          });
        } else {
          dispatch({
            type: SHOW_MODAL,
            payload: errorModal,
          });
        }
        ajaxWasSuccessful = false;
      }

      clearOptimizerCooldown()(dispatch);
      refreshOptimizationData()(dispatch);
      return ajaxWasSuccessful;
    });
  };
};

export const removeIdea = (idea) => {
  return function (dispatch) {
    dispatch({
      type: REMOVE_IDEA,
      payload: idea,
    });

    return ideasAdapter.removeIdea(idea).then((response) => {
      requestRequiresAuthentication(response);

      if (!response || 'error' in response) {
        dispatch({
          type: ADD_IDEAS,
          payload: [idea],
        });

        const errorMsg =
          response &&
          response.error ===
            'User has a long position corresponding to this idea, they must exit position before this can be deleted.'
            ? getIdeaPhraseFor('must_exit')
            : 'An error has occurred, please try again.';
        const errorModal = {
          heading: getIdeaPhraseFor('remove_error'),
          message: errorMsg,
          size: 'wide',
          icon: {
            className: 'fa-exclamation-circle',
            color: '#ff7666',
            style: {
              fontSize: '22px',
              marginRight: '4px',
              paddingBottom: '4px',
            },
          },
          redirect: false,
          dismissable: true,
        };

        dispatch({
          type: SHOW_MODAL,
          payload: errorModal,
        });

        return response;
      }

      clearOptimizerCooldown()(dispatch);
      refreshOptimizationData()(dispatch);
      return response;
    });
  };
};

export const fillCurrentUserWithETFIdeas = () => {
  return function (dispatch) {
    return ideasAdapter.fillCurrentUserWithETFIdeas().then((response) => {
      const didAjaxSucceed = response && response.data;
      if (didAjaxSucceed) {
        const ideas = response.data.created_ideas;
        dispatch({
          type: ADD_IDEAS,
          payload: ideas,
        });
        clearOptimizerCooldown()(dispatch);
        refreshOptimizationData()(dispatch);

        if (ideas.length > 0) {
          fetchSecurityDataForIdeas(dispatch, ideas);
        }
      } else {
        const errorMsg = 'Something went wrong. Please try again later.';
        const errorModal = {
          heading: getIdeaPhraseFor('add_error'),
          message: `Error message: ${errorMsg}`,
          size: 'wide',
          icon: {
            className: 'fa-exclamation-circle',
            color: '#ff7666',
            style: {
              fontSize: '22px',
              marginRight: '4px',
              paddingBottom: '4px',
            },
          },
          redirect: false,
          dismissable: true,
        };
        dispatch({
          type: SHOW_MODAL,
          payload: errorModal,
        });
      }
      return response;
    });
  };
};

export const deactivateIdea = (idea) => {
  const updatedIdea = { idea_id: idea.idea_id, active: false };
  return updateIdea(updatedIdea, idea);
};

export const reaffirmIdea = (idea) => {
  return function (dispatch) {
    const timeNow = Math.round(moment().valueOf() / 1000);
    const updatedIdea = {
      ...idea,
      last_reaffirmed_at_timestamp: timeNow,
    };
    dispatch({
      type: UPDATE_IDEA,
      payload: updatedIdea,
    });

    return ideasAdapter.reaffirmIdea(idea.idea_id).then((response) => {
      requestRequiresAuthentication(response);

      if (!response || response.status === 'error') {
        const modalMessage = 'Idea could not be reaffirmed. Please try again later.';
        const requestFailedModal = {
          contentComponent: createBasicErrorModal(modalMessage),
          dismissable: true,
          size: 'wide',
        };
        dispatch({
          type: SHOW_MODAL,
          payload: requestFailedModal,
        });
        dispatch({
          type: UPDATE_IDEA,
          payload: idea,
        });
        return false;
      } else {
        return true;
      }
    });
  };
};
