import { marketMonitorActionTypes } from '@src/constants';

const defaultState = {
  securities: [],
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case marketMonitorActionTypes.addSecurity: {
      return {
        securities: action.payload,
      };
    }

    default:
      return state;
  }
}
