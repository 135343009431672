import React from 'react';

import MyIdeasContainer from '../containers/Ideas/MyIdeasContainer';
import SecurityCardContainer from '../containers/SecurityCard/SecurityCardContainer';
import IdeaCard from '../containers/Ideas/IdeaCard';
import UpdateApexAccount from '../containers/LiveTradingSignUp/Forms/UpdateApexAccount';
import VideoPlayer from '../containers/UI/VideoPlayer';

import Overlay from '../components/layout/Overlay';
import Panel from '../components/layout/Panel';

import { parseQueryString, createQueryString } from '../helpers/routerHelpers';
import { logMetricsTrackingEvent, openOptimizerPanel } from '../actions';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useCurrentUser } from '../main/hooks/user';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const SecurityPanelWrapper = styled(Panel)`
  background-color: ${({ theme }) => theme.themeColors.appBackground};

  .scroll-panel-container {
    background-color: ${({ theme }) => theme.themeColors.appBackground};
  }
`;

const PanelRenderer = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const closePanel = () => {
    const keysToLeaveInQuery = ['tab'];
    const queryString = location.search;
    const queryObj = parseQueryString(queryString);
    const queryObjKeys = Object.keys(queryObj);
    const queryKeysToKeep = queryObjKeys.filter((key) => keysToLeaveInQuery.includes(key));
    const filteredQuery = {};
    queryKeysToKeep.forEach((key) => {
      filteredQuery[key] = queryObj[key];
    });
    return setSearchParams(filteredQuery);
  };

  const renderVideoPlayerPanel = (query) => {
    const handleDismiss = () => {
      const event = 'Dismissed Video';
      const properties = {
        'In Panel': true,
      };
      logMetricsTrackingEvent(event, properties)();

      navigate(location.pathname);
    };
    return (
      <div className={'video-player-overlay-panel-container'}>
        <Panel handleOverlayClick={handleDismiss} childrenWithWindowSize>
          {(windowHeight, windowWidth) => (
            <VideoPlayer
              videoId={query.videoId}
              thoughtId={query.thoughtId}
              windowHeight={windowHeight}
              windowWidth={windowWidth}
              navigate={navigate}
              location={location}
              inPanel
              autoPlay
            />
          )}
        </Panel>
      </div>
    );
  };

  const renderSecurityPanel = (query) => {
    const securityId = query.securityId;
    const securityIdentifier = securityId;
    const securityIdentifierKey = isNaN(securityIdentifier) ? 'symbol' : 'securityId';
    const securityIdentifierValue =
      securityIdentifierKey === 'securityId' ? parseInt(securityIdentifier, 10) : securityIdentifier.toUpperCase();
    const activeTab = query.activeTab || null;
    const additionalTabData = query.ideaId ? { ideaId: query.ideaId } : null;

    return (
      <SecurityPanelWrapper heightPercentage={0.98} handleOverlayClick={closePanel} className="security-overlay">
        <SecurityCardContainer
          key={securityIdentifierValue}
          securityIdentifier={{
            [securityIdentifierKey]: securityIdentifierValue,
          }}
          activeTab={activeTab}
          additionalTabData={additionalTabData}
          navigate={navigate}
          location={location}
          askingWhyFromNotif={query.askingWhyFromNotif === 'true'}
          handleSecurityCardCollapse={closePanel}
          isPanel
        />
      </SecurityPanelWrapper>
    );
  };

  const renderUpdateApexAccountPanel = () => {
    return (
      <Panel handleOverlayClick={closePanel} style={{ maxWidth: '800px' }}>
        <UpdateApexAccount closePanel={closePanel} navigate={navigate} location={location} isPanel />
      </Panel>
    );
  };

  const renderOptimizerPanel = () => {
    // sends action to store to show optimizer
    openOptimizerPanel()(dispatch);
    return null;
  };

  const displayPanel = (query) => {
    const renderPanel = {
      security: renderSecurityPanel,
      editUserAccount: renderUpdateApexAccountPanel,
      'video-player': renderVideoPlayerPanel,
    };
    const panel = query.panel;
    const panelRender = renderPanel[panel];
    if (!panelRender) {
      return null;
    } else {
      return panelRender(query) || null;
    }
  };

  const queryString = location.search;
  const isQuery = location.search && location.search.length > 0;

  const restrictedPathsForDefaultExpectedReturnModal = [
    'onboard',
    'live_accounts',
    'account_type',
    'rewards_redemption',
    'investing_experience',
    'options-trading-experience',
    'funding',
    'about',
    'legal',
    'referrals',
    'terms_of_service',
    'privacy_policy',
    'margin_disclosure',
    'privacy_settings',
    'faq',
    'invites/gmail/contact_callback',
    'portfolio/history',
    'subscriptions',
    'membership',
    'almost-there',
    'get-started',
    'viewpoint_contributors',
    'upload-required-documents',
    'resubmit-address',
    'live-account-submission-status',
  ];
  const isUserInOnboardingPath = restrictedPathsForDefaultExpectedReturnModal.some(
    (path) => location.pathname.indexOf(path) >= 0
  );
  const isUserInProfileSettings = location.pathname.indexOf('users/edit') >= 0;
  const doesUserNeedToSetDefaultExpectedReturn = currentUser.default_expected_return === null;
  if (doesUserNeedToSetDefaultExpectedReturn && !isUserInOnboardingPath && !isUserInProfileSettings) {
    const query = {
      panel: 'defaultExpectedReturn',
    };
    return displayPanel(query);
  }

  if (!isQuery) {
    return null;
  }

  const query = parseQueryString(queryString);
  const shouldOpenOptimizerPanel = query.optimizer === 1 || query.optimizer === '1';
  if (shouldOpenOptimizerPanel) {
    renderOptimizerPanel();
    return null;
  }

  if (location.pathname.slice(0, 12) === '/securities/' && query.panel === 'security') {
    // HACK: Better implementation is needed
    console.error('Cannot display a security panel on top of the security page, update security id on page instead.');
    const securityId = query.securityId;
    const queryWithoutSecurityId = {};
    Object.keys(query).forEach((key) => {
      if (key !== 'securityId' && key !== 'panel') {
        queryWithoutSecurityId[key] = query[key];
      }
    });
    const queryString = createQueryString(queryWithoutSecurityId);
    navigate(`/securities/${securityId}${queryString}`);
    return null;
  }

  return displayPanel(query);
};

export default PanelRenderer;
