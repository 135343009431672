import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentUser, useLiveAccount } from '../../hooks/user';
import { logMetricsTrackingEvent, showModal, submitLiveTradingSignUpForm } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import { H5, Body5, AgreementCheckbox } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { useFormik } from 'formik';
import { AgreementsSchema } from '../../constants/formValidationSchemas';
import { sendFacebookTrackingEvent } from '../../../constants/facebookTracking';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { TrackIteratively } from '../../../utils/itly';
import { PRODUCT_NAME } from '@src/appConfig';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const InputWrapper = styled.div`
  padding-top: 24px;
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 8px;
`;
const Actions = styled.div`
  padding-top: 48px;
  text-align: center;
`;
const AgreementWrapper = styled.div`
  margin-bottom: 16px;
  padding: 12px 16px;
  border: 1px solid ${({ theme }) => theme.themeColors.border};
  border-radius: 5px;
  max-height: 300px;
  overflow: auto;
`;
const AgreementLine = styled.div`
  padding-bottom: 8px;

  &:first-child * {
    font-size: 16px;
  }
`;
const AgreementText = styled.div`
  padding-bottom: 16px;

  * {
    line-height: 1.2;
  }
`;

const getInitialValuesFrom = (currentUser, liveAccount) => {
  const { acknowledged_account_agreement } = liveAccount;
  return {
    acknowledged_account_agreement: acknowledged_account_agreement || false,
  };
};

const massageFormData = (v) => v;

const FORM_PAGE_NAME = 'agreements';

const Agreement = ({ agreementKey, checkboxValue, AdditionalMessagingComponent }) => {
  const agreement = useSelector((state) => state.liveAccount.liveTrading[agreementKey]);

  if (checkboxValue || !agreement) {
    return null;
  }

  const lines = agreement.replace(/\n\n/g, '**newline**').split('**newline**');
  return (
    <div>
      <AgreementWrapper>
        <AgreementText>
          {lines.map((line, i) => (
            <AgreementLine key={`account-agreement-line-${i}`}>
              <Body5 isSmall>{line}</Body5>
            </AgreementLine>
          ))}
        </AgreementText>
      </AgreementWrapper>
      {AdditionalMessagingComponent}
    </div>
  );
};

export const AcceptAgreements = ({ onContinue, onboardingState }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const liveAccount = useLiveAccount();

  const [isReady, setIsReady] = React.useState(true);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const form = useFormik({
    initialValues: getInitialValuesFrom(currentUser, liveAccount),
    validationSchema: AgreementsSchema,
    enableReinitialize: false,
  });
  const { values, errors, setFieldValue, validateForm } = form;

  const handleChange = React.useCallback(
    (name) => (value) => {
      setFieldValue(name, value, false);
    },
    [setFieldValue]
  );

  // const handleMoreOnMarginClick = React.useCallback(
  //   () => {
  //     logMetricsTrackingEvent('Clicked More About Margin Link')();
  //
  //     const modal = {
  //       contentComponent: (
  //         <div style={{ width: '100%', maxHeight: window.innerHeight - 80, overflow: 'auto' }}>
  //           <div className="modal-heading-small">What is a Margin Account?</div>
  //           <div className="modal-message">
  //             <Body5 isSmall>
  //               With a margin account you can borrow money to purchase stocks and ETFs if you choose to. For example, if
  //               you deposit $10,000 in your account and buy $12,000 of securities, you'll receive a margin loan of
  //               $2,000. You can also use margin to trade into new positions faster - more on that later. Using margin
  //               can increase gains from positions that go up in value, but it can also magnify losses. If you choose to
  //               use margin, you’ll pay interest on the amount borrowed. You can read more about margin accounts{' '}
  //               <a href="/margin_disclosure" target="_blank" rel="noopener noreferrer">
  //                 here
  //               </a>
  //               .
  //             </Body5>
  //           </div>
  //           <div className="modal-heading-small modal-heading-subsequent">
  //             Why does {PRODUCT_NAME} offer Margin Accounts?
  //           </div>
  //           <div className="modal-message">
  //             <Body5 isSmall>
  //               A margin account can be useful even if you don't normally plan to use margin. When you sell out of a
  //               position in a cash (non-margin) account, you need to wait two business days before using those funds to
  //               place new trades (this is called{' '}
  //               <a href="https://www.sec.gov/news/press-release/2017-68-0" target="_blank" rel="noopener noreferrer">
  //                 T+2 settlement
  //               </a>
  //               ). While you wait, your portfolio may be unbalanced and you may miss investing opportunities. With a
  //               margin account, you can enter new positions immediately - no waiting. You can use the portfolio
  //               optimization tool to rebalance your allocations with just a few clicks, and margin makes it run smoothly
  //               - it's one of the benefits of investing with {PRODUCT_NAME}.
  //             </Body5>
  //           </div>
  //         </div>
  //       ),
  //       dismissable: true,
  //       size: 'wide',
  //     };
  //     showModal(modal)(dispatch);
  //   },
  //   [dispatch]
  // );

  const submitForm = React.useCallback((values) => {
    const apiFormData = massageFormData(values);
    setIsSubmitting(true);
    return submitLiveTradingSignUpForm(apiFormData, FORM_PAGE_NAME)(dispatch);
  }, []);

  const handleSubmit = React.useCallback(
    async () => {
      logMetricsTrackingEvent('Submitted Agreements Form')();

      const validation = await validateForm();
      if (Object.keys(validation).length === 0) {
        const { status } = await submitForm(values);
        if (status === 200) {
          return {
            ok: true,
          };
        } else {
          setFormError('Something went wrong, please try again');
          setIsSubmitting(false);
          return {
            ok: false,
          };
        }
      } else {
        // could log errors but no form validations on this form
        logMetricsTrackingEvent('Failed Live Account Validations', {
          Fields: Object.entries(validation),
          'Form Name': 'agreements',
        })();

        setIsSubmitting(false);
        return {
          errors: true,
        };
      }
    },
    [values]
  );

  const handleContinueClick = React.useCallback(
    async () => {
      const { ok } = await handleSubmit();

      if (ok) {
        sendFacebookTrackingEvent('Complete Apex Registration', {});
        window?.ttq?.track('CompleteRegistration');
        TrackIteratively.account.COMPLETE_APEX_ACCOUNT.send({});

        return onContinue(null, onboardingState);
      }
    },
    [handleSubmit]
  );

  React.useEffect(() => {
    logMetricsTrackingEvent('View Agreements Form')();
  }, []);

  if (!isReady) return <LoadingPage />;

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.agreements}>
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>Review and accept agreements</H5>
          </Heading>

          <Message>
            <Body5>
              Please read through the agreements and acknowledge you understand your rights and obligations.
            </Body5>
          </Message>

          <InputWrapper>
            <InputWrapper>
              <Agreement agreementKey="new_account_agreement" checkboxValue={values.acknowledged_account_agreement} />
            </InputWrapper>
            <Agreement
              agreementKey="acknowledged_account_agreement"
              checkboxValue={values.acknowledged_account_agreement}
            />
            <AgreementCheckbox
              name={'acknowledged_account_agreement'}
              value={values.acknowledged_account_agreement}
              label={
                'I have read the Client Relationship Summary and Customer Account Agreement and I understand my rights and obligations under them.'
              }
              onChange={handleChange}
              error={errors.acknowledged_account_agreement || null}
            />
          </InputWrapper>

          <Actions>
            <FormState error={formError} isSubmitting={isSubmitting} />
            <Container fullWidth centerAll>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleContinueClick}>
                Continue
              </FlatButton>
            </Container>
          </Actions>
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
