import React from 'react';
import styled from 'styled-components';
import { TrackingEvent } from '@src/main/lib/nvstr-utils.es';
import { useIdeaForSecurity } from '@src/main/hooks/ideas/useIdea';

const Wrapper = styled.div`
  font-size: 12px;
  font-weight: 400;
  cursor: default;

  padding: 8px 16px;

  color: ${({ theme }) => theme.themeColors.secondaryText};
`;

function findVerticalNavGroupItemContainer(element) {
  // Check if the current element has the desired class name
  if (element.classList.contains('vertical-nav-group-item-container')) {
    return element;
  }

  // If not found and there is a parent element, recursively search in the parent
  if (element.parentElement) {
    return findVerticalNavGroupItemContainer(element.parentElement);
  }

  // If no parent is found and the class is not found, return null
  return null;
}

function formatRouteName(name) {
  if (!name) return '';

  return name.split('\n')[0];
}

const VerticalNavGroup = (props) => {
  const hc = (e) => {
    try {
      const target = event.target;
      const verticalNavGroupItemContainer = findVerticalNavGroupItemContainer(target);

      if (verticalNavGroupItemContainer) {
        const routeName = verticalNavGroupItemContainer.innerText;
        TrackingEvent.create('Clicked Vertical Nav Item', {
          'Route Name': formatRouteName(routeName),
        });
      } else {
        // console.log('Not found.');
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div className={`vertical-nav-group`} onClick={hc}>
      <Wrapper className={`vertical-nav-group-item vertical-nav-group-header-item`}>{props.groupName}</Wrapper>

      <div className={`vertical-nav-group-items`}>{props.children}</div>
    </div>
  );
};

export default VerticalNavGroup;
