import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../actions/index';

import LinkTo from '../Links/LinkTo';
import DynamicHeightCarousel from '../UI/DynamicHeightCarousel';
import PageLoading from '../../components/PageLoading';

import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import { throwError } from '../../helpers/devToolHelpers';
import { returnExcludedThoughtIdsFromSuggestions } from '../../helpers/thoughtsHelpers';
import { returnSecurityPanelUrl } from '../../helpers/securityPanelHelpers';

/*
  Props taken:
    securityId -> if not giving a notification
    startingList -> array - can container thought data obj, or thought notification data obj
    allowSkip -> allow user to skip to a new suggested thought without answering the last rendered thought
    // promptForThoughtAtEndOfList -> currently shows a message dialog that they reached the end TODO: implement, new feature idea
    allowCarouselWrapping -> if no more suggested thoughts, goes to the beginning * if promptForThoughtAtEndOfList is true, it will wrap after a next click on the prompt for a thought
    logFirstItemView -> bool -> forwards into DynamicHeightCarousel, determines if should log the viewing of first item
*/

class ThoughtFeedContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _thoughtsList: this.__validateStartingListFromProps(props.startingList),

      _isNoMoreSuggestedThoughts: false,
    };
  }

  componentDidMount() {
    if (!this._didReceiveStartingList()) {
      this.getAndAddSuggestedThoughtToList();
    }
  }

  render() {
    if (this._isLoading()) {
      return this._renderLoading();
    }
    return (
      <div className={`thought-feed`}>
        <DynamicHeightCarousel
          list={this.returnThoughtsList()}
          returnIdFromItemData={this.returnIdFromItemData}
          areNoMoreItems={this.isNoMoreSuggestedThoughts()}
          handleAdvanceClick={this.handleAdvanceClick}
          handlePreviousClick={this.handlePreviousClick}
          itemProps={{
            viewingContext: this.props.viewingContext,
            afterTrade: this.props.afterTrade,
            withThoughtLink: this.props.withThoughtLink,
            linkToSecurity: this.props.linkToSecurity,
            ignoreImpressionTracking: this.props.ignoreImpressionTracking,
            scrollContainerSelector: this.props.scrollContainerSelector,
            handleAfterThoughtAction: this.handleAfterThoughtAction,
            handleAdditionalPromptForThoughtAfterDisagreeAction:
              this.handleAdditionalPromptForThoughtAfterDisagreeAction,
            thoughtCleanStyle: this.props.thoughtCleanStyle,
            inFeed: this.props.inFeed,
            inActivityPageFeed: this.props.inActivityPageFeed,
            notificationIndex: this.props.notificationIndex,
          }}
          renderCustomCarouselControls={this._renderCarouselControls}
          additionalPreviousClickAction={this._logClickedPrevButton}
          additionalAdvanceClickAction={this._logClickedNextButton}
          additionalViewItemAction={this._logViewSuggestedThoughtIfNotAlreadyLoggedInComponentsLifecycle}
          hideAdvanceOnLastItem={!this._shouldAllowSkip()}
          wrapToBeginning={this._shouldAllowCarouselWrapping()}
          logFirstItemView={this.props.logFirstItemView}
        />
      </div>
    );
  }

  _renderCarouselControls = (
    renderPreviousButton,
    renderAdvanceButton,
    shouldRenderPreviousButton,
    shouldRenderAdvanceButton
  ) => {
    return (
      (shouldRenderPreviousButton || this._shouldShowSeeAllThoughtsLink() || shouldRenderAdvanceButton) && (
        <div className={`suggested-thought-actions`}>
          {shouldRenderPreviousButton && renderPreviousButton()}
          {this._shouldShowSeeAllThoughtsLink() && (
            <div className={`see-all-thoughts-link`}>
              <LinkTo
                to={this._returnLinkToOpenSecurityPanelToThoughts()}
                className={`link-btn btn-small`}
                additionalClickAction={this._logSeeAllThoughtLinkClick}
                text={`See more on ${this._returnSecuritySymbolForDisplay()}`}
              />
            </div>
          )}
          {shouldRenderAdvanceButton && renderAdvanceButton()}
        </div>
      )
    );
  };

  _renderLoading = () => <PageLoading loadingMessage="Getting Community Pro/Cons" flatStyle />;

  _isLoading = () => !this.areThoughtsInThoughtsList() && !this.isNoMoreSuggestedThoughts();

  _shouldAllowSkip = () => this.props.allowSkip;

  _shouldPromptForThoughtAtEndOfList = () => this.props.promptForThoughtAtEndOfList; // TODO: implement

  _shouldAllowCarouselWrapping = () => this.props.allowCarouselWrapping;

  _shouldShowSeeAllThoughtsLink = () => this.props.showSeeAllThoughtsLink;

  isNoMoreSuggestedThoughts = () => this.state._isNoMoreSuggestedThoughts;

  areThoughtsInThoughtsList = () => this.returnThoughtsListCount() > 0;

  returnThoughtsListCount = () => this.returnThoughtsList().length;

  returnThoughtsList = () => this.state._thoughtsList;

  _isItemANotification = (itemData) => itemData && itemData.notification;

  _returnNotificationThoughtId = (itemData) =>
    itemData &&
    itemData.notification &&
    itemData.notification.data &&
    itemData.notification.data.thought &&
    itemData.notification.data.thought.id;

  _returnThoughtIdFromThoughtItem = (itemData) => itemData && itemData.id;

  returnIdFromItemData = (itemData) => {
    if (this._isItemANotification(itemData)) {
      const thoughtId = this._returnNotificationThoughtId(itemData);
      if (!thoughtId) {
        return throwError(`Could not find thought_id on notification item`, true, { itemData });
      }
      return thoughtId;
    } else {
      const thoughtId = this._returnThoughtIdFromThoughtItem(itemData);
      if (!thoughtId) {
        return throwError(`Could not find thought_id on thought item`, true, {
          itemData,
        });
      }
      return thoughtId;
    }
  };

  _returnSecurityId = () => this.props.securityId;

  _returnSecuritySymbol = () => this.props.symbol;

  _returnSecuritySymbolUppercase = () =>
    this._returnSecuritySymbol() ? this._returnSecuritySymbol().toUpperCase() : null;

  _returnSecuritySymbolForDisplay = () =>
    this._returnSecuritySymbol() ? this._returnSecuritySymbolUppercase() : 'this stock';

  _returnLinkToOpenSecurityPanelToThoughts = () => {
    const additionalProps = {
      activeTab: 'thoughts',
    };
    const openSecurityPanelConfig = {
      securityId: this._returnSecurityId(),
      additionalProps,
      location: window.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _returnThoughtsStore = () => this.props.thoughts;

  _addToThoughtList = (thought) => {
    this.setState((prevState) => ({
      _thoughtsList: [...prevState._thoughtsList, thought],
    }));
  };

  handleAfterThoughtAction = (opinion) => {
    if (this.props.handleAfterThoughtAction) {
      this.props.handleAfterThoughtAction(opinion);
    }
    if (opinion !== 'disagree') {
      this.getAndAddSuggestedThoughtToList();
    }
  };

  handleAdditionalPromptForThoughtAfterDisagreeAction = () => {
    this.getAndAddSuggestedThoughtToList();
  };

  handleAdvanceClick = (advanceCarousel, carouselIndex) => {
    const isViewingLastItemInList = carouselIndex + 1 === this.returnThoughtsListCount();
    if (isViewingLastItemInList && !this.isNoMoreSuggestedThoughts()) {
      // this is the skip action
      this.getAndAddSuggestedThoughtToList().then((didGetSuggestedThought) => {
        if (!didGetSuggestedThought) {
          advanceCarousel();
        }
      });
      this._logSkipThoughtInThoughtFeed();
    } else {
      // this is if user is viewing a previous thought in list
      advanceCarousel();
    }
  };

  handlePreviousClick = (previousCarouselHandler, carouselIndex) => {
    previousCarouselHandler();
  };

  _handleGetNextThoughtSuccess = (suggestedThought) => {
    this._addToThoughtList(suggestedThought);
  };

  _handleNoMoreSuggestedThoughts = () => {
    this._setNoMoreSuggestedThoughts();
    this._logNoMoreSuggestedThoughts();
  };

  _setNoMoreSuggestedThoughts = () => {
    this.setState(() => ({
      _isNoMoreSuggestedThoughts: true,
    }));
  };

  getSuggestedThought = () => {
    const data = {
      securityId: this._returnSecurityId(),
      excludeThoughtIdsCSV: [...returnExcludedThoughtIdsFromSuggestions(this._returnThoughtsStore())].join(','),
    };
    return this.props.actions.getThoughtFeedSuggestion(data);
  };

  getAndAddSuggestedThoughtToList = () => {
    return this.getSuggestedThought().then((response) => {
      if (this._didResponseReturnSuggestedThought(response)) {
        this._handleGetNextThoughtSuccess(this._returnSuggestedThoughtFromResponse(response));
        return true;
      } else {
        this._handleNoMoreSuggestedThoughts();
        return false;
      }
    });
  };

  _returnSuggestedThoughtFromResponse = (response) => response?.data?.thoughts[0];

  _didResponseReturnSuggestedThought = (response) =>
    response && response.data && response.data.thoughts && response.data.thoughts.length > 0;

  _didReceiveStartingList = () => this.__validateStartingListFromProps(this.props.startingList).length > 0;

  __validateStartingListFromProps = (list) => {
    if (Array.isArray(list)) {
      return list;
    }
    if (isUndefinedOrNull(list)) {
      return [];
    }
    throwError('Received invalid list to ThoughtFeedContainer', true, {
      props: this.props,
      list,
    });
  };

  _logNoMoreSuggestedThoughts = () => {
    const event = 'No More Suggested Thoughts';
    const properties = {
      'Security ID': this._returnSecurityId(),
      Context: this.props.viewingContext,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logSeeAllThoughtLinkClick = () => {
    const event = 'Clicked See All Thoughts';
    const properties = {
      'In Feed': this.props.inFeed,
      'Security ID': this._returnSecurityId(),
      'Security Symbol': this._returnSecuritySymbolUppercase(),
      Context: this.props.viewingContext,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logClickedPrevButton = () => {
    const event = 'Clicked Previous Button On Suggested Thought';
    const properties = {
      'In Feed': this.props.inFeed,
      'Security ID': this._returnSecurityId(),
      'Security Symbol': this._returnSecuritySymbolUppercase(),
      Context: this.props.viewingContext || 'Thought Feed',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logSkipThoughtInThoughtFeed = () => {
    const event = 'Clicked Skip Button On Suggested Thought';
    const properties = {
      'In Feed': this.props.inFeed,
      'Security ID': this._returnSecurityId(),
      'Security Symbol': this._returnSecuritySymbolUppercase(),
      Context: this.props.viewingContext || 'Thought Feed',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logClickedNextButton = () => {
    const event = 'Clicked Next Button On Suggested Thought';
    const properties = {
      'In Feed': this.props.inFeed,
      'Security ID': this._returnSecurityId(),
      'Security Symbol': this._returnSecuritySymbolUppercase(),
      Context: this.props.viewingContext || 'Thought Feed',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logViewSuggestedThoughtIfNotAlreadyLoggedInComponentsLifecycle = (itemData) => {
    if (this._isItemANotification(itemData)) {
      // currently no suggested thoughts as notifications, so skipping log of it
      return false;
    }
    const thoughtId = this.returnIdFromItemData(itemData);
    this._loggedViewThoughtsHistoryLookup = this._loggedViewThoughtsHistoryLookup || {};
    if (!this._loggedViewThoughtsHistoryLookup[thoughtId]) {
      this._loggedViewThoughtsHistoryLookup[thoughtId] = true;
      this._logViewSuggestedThought(thoughtId);
    }
  };

  _logViewSuggestedThought = (thoughtId) => {
    const event = 'Shown Suggested Thought';
    const properties = {
      'In Feed': this.props.inFeed,
      'Security ID': this._returnSecurityId(),
      'Security Symbol': this._returnSecuritySymbolUppercase(),
      'Thought ID': thoughtId,
      Context: this.props.viewingContext || 'Thought Feed',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    thoughts: state.thoughts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(ThoughtFeedContainer);
export default composedComponent;
