import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useCurrentUser } from '../../hooks/user';
import { logMetricsTrackingEvent } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import {
  H5,
  Body5,
  FloatingLabelTextInput,
  createDropdownItem,
  FloatingLabelDropdown,
} from '../../lib/nvstr-common-ui.es';
import { useFormik } from 'formik';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { BankAccountSchema } from '../../constants/formValidationSchemas';
import DataSecurityMessage from '../../../containers/UI/DataSecurityMessage';
import { submitAddBankAccountForm } from '../../../actions';
import { returnCurrentUserThemeTestGroups } from '../../../helpers/currentUserHelpers';
import { sendFacebookTrackingEvent } from '../../../constants/facebookTracking';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const InputWrappers = styled.div`
  padding-top: 8px;
`;
const InputWrapper = styled.div`
  position: relative;
  padding-top: 24px;

  select {
    border-top: none;
    border-right: none;
    border-left: none;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
  input {
    border: none !important;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 24px;
`;
const Actions = styled.div`
  padding-top: 8px;
  text-align: center;
`;

const accountTypeItems = [
  createDropdownItem({ text: '---', value: '', disabled: true }),
  createDropdownItem({ text: 'Checking', value: 'CHECKING' }),
  createDropdownItem({ text: 'Savings', value: 'SAVINGS' }),
];

const massageFormData = (v) => v;

export const AddBankAccountForm = ({ onContinue }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  // const liveAccount = useLiveAccount();

  const [isReady, setIsReady] = React.useState(true);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  // const fullName = `${liveAccount.given_name} ${liveAccount.family_name}`;

  const form = useFormik({
    initialValues: {
      // owner_name: fullName, // not needed currently
      bank_account_type: '',
      bank_routing_number: '',
      bank_account: '',
    },
    validationSchema: BankAccountSchema,
    enableReinitialize: false,
  });
  const { values, errors, setFieldValue, validateForm } = form;

  const handleChange = React.useCallback(
    (name) => (value) => {
      setFieldValue(name, value, false);
    },
    [setFieldValue]
  );

  const submitForm = React.useCallback((values) => {
    const apiFormData = massageFormData(values);
    setIsSubmitting(true);
    return submitAddBankAccountForm(apiFormData)(dispatch);
  }, []);

  const handleSubmit = React.useCallback(async () => {
    logMetricsTrackingEvent('Submitted Add Bank Form')();
    logMetricsTrackingEvent('Clicked Add Bank Account')();

    const validation = await validateForm();

    if (Object.keys(validation).length === 0) {
      const { ok } = await submitForm(values);
      if (ok) {
        return {
          ok: true,
        };
      } else {
        setFormError('Something went wrong, please try again');
        setIsSubmitting(false);
        return {
          ok: false,
        };
      }
    } else {
      logMetricsTrackingEvent('Failed Add Bank Validations', {
        Fields: Object.entries(validation),
        'Form Name': 'add bank',
      })();
      // could log errors but no form validations on this form
      setIsSubmitting(false);
      return {
        errors: true,
      };
    }
  }, [values]);

  const handleContinueClick = React.useCallback(async () => {
    const { ok } = await handleSubmit();
    const params = {
      themeTestGroups: returnCurrentUserThemeTestGroups(currentUser),
    };
    if (ok) {
      sendFacebookTrackingEvent('Linked Bank Account', params);
      onContinue();
    }
  }, [handleSubmit]);

  React.useEffect(() => {
    logMetricsTrackingEvent('View Add Bank Form')();
  }, []);

  if (!isReady) return <LoadingPage />;

  return (
    <Page width={'600px'}>
      <ContentWrapper>
        <Heading>
          <H5>Add a bank account</H5>
        </Heading>

        <InputWrappers>
          <InputWrapper>
            <FloatingLabelDropdown
              name={'bank_account_type'}
              items={accountTypeItems}
              value={values.bank_account_type}
              label={'Account Type'}
              onChange={handleChange}
              error={errors.bank_account_type || null}
            />
          </InputWrapper>
          <InputWrapper>
            <FloatingLabelTextInput
              name={'bank_routing_number'}
              value={values.bank_routing_number}
              label={'Routing Number'}
              onChange={handleChange}
              error={errors.bank_routing_number || null}
              forceLiftedState
            />
          </InputWrapper>
          <InputWrapper>
            <FloatingLabelTextInput
              name={'bank_account'}
              value={values.bank_account}
              label={'Account Number'}
              onChange={handleChange}
              error={errors.bank_account || null}
              forceLiftedState
            />
          </InputWrapper>
        </InputWrappers>

        <Actions>
          <Message>
            <Body5 isLowContrast isSmall>
              For Security, two micro-deposits will be deposited and withdrawn from your account within 1 - 2 days. You
              will have to verify these amounts to confirm account ownership.
            </Body5>
          </Message>

          <div style={{ paddingTop: '16px' }}>
            <FormState error={formError} isSubmitting={isSubmitting} />

            <Container fullWidth centerAll>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleContinueClick}>
                Continue
              </FlatButton>
            </Container>
          </div>
          <DataSecurityMessage startCollapsed />
        </Actions>
      </ContentWrapper>
    </Page>
  );
};
