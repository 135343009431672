import React from 'react';

import UserContainer from '../users/UserContainer';
import ProfileAvatar from '../../components/user/ProfileAvatar';
import UserName from '../../components/user/UserName';
import { PROFILE_CONTEXTS } from '../../constants/contextTracking';

const SearchUserResultRow = (props) => {
  const { userId, hideModal } = props;

  // TODO: add connect button
  return (
    <div className={'search-user-result-row'} onClick={hideModal}>
      <UserContainer userId={userId}>
        <ProfileAvatar size="nav" showNameTag={false} profileLinkQuery={`?context=${PROFILE_CONTEXTS.SEARCH}`} />
        <UserName displayNameStyle={'fullName'} profileLinkQuery={`?context=${PROFILE_CONTEXTS.SEARCH}`} />
      </UserContainer>
    </div>
  );
};

export default SearchUserResultRow;
