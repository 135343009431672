import React from 'react';
import { useCurrentUserId } from './useCurrentUserId';
import { useSelector } from 'react-redux';
import { isConnectionsDataLoadingForUser } from '../../../helpers/userConnectionsHelpers';
import {
  canRequestConnection,
  isAConnection,
  isActiveConnectionRequestSent,
  isConnectionRequestSentExpired,
  isRequestingConnection,
  userFullName,
} from '../../../helpers/usersHelpers';
import { useUser } from './useUser';

export const CONNECTION_STATUS_TYPES = {
  requestedToCurrentUser: 'requested-to-current-user',
  requestedFromCurrentUser: 'requested-from-current-user',
  connected: 'connected',
  none: 'none',
};

const buildStatus = (user) => {
  if (!user) {
    return null;
  }

  const requestingConnection = isRequestingConnection(user);
  const requestedFromCurrentUser = isActiveConnectionRequestSent(user);
  const connected = isAConnection(user);
  if (connected) {
    return CONNECTION_STATUS_TYPES.connected;
  } else if (requestedFromCurrentUser) {
    return CONNECTION_STATUS_TYPES.requestedFromCurrentUser;
  } else if (requestingConnection) {
    return CONNECTION_STATUS_TYPES.requestedToCurrentUser;
  } else {
    return CONNECTION_STATUS_TYPES.none;
  }
};

export const useConnectionStatus = (userId) => {
  const user = useUser(userId);

  const [status, setStatus] = React.useState(buildStatus(user));

  const _canRequestConnection = canRequestConnection(user);
  const hasExpiredConnectionRequestSent = isConnectionRequestSentExpired(user);

  React.useEffect(() => {
    const nextStatus = buildStatus(user);
    if (status !== nextStatus) {
      setStatus(nextStatus);
    }
  }, [user, status]);

  return {
    status,
    canRequestConnection: _canRequestConnection,
    expiredRequestSent: hasExpiredConnectionRequestSent,
  };
};

export const useConnections = (_props_userId) => {
  const currentUserId = useCurrentUserId();
  const userId = _props_userId || currentUserId;

  const connectionsStore = useSelector((state) => state.userConnections);
  const connectionsUserLookup = useSelector((state) => state.userConnections?.userDict);

  const _starting = connectionsUserLookup[userId]?.connections;
  const [connections, setConnections] = React.useState(_starting || null);
  const isLoading = isConnectionsDataLoadingForUser(userId, connectionsStore);

  React.useEffect(() => {
    const c = connectionsUserLookup[userId]?.connections;
    if (c) {
      setConnections(c);
    }
  }, [connectionsUserLookup]);

  return {
    isLoading,
    connections,
  };
};

const sort = (connections, userLookup) => {
  return connections.sort((a, b) => {
    const userA = userLookup[a];
    const userB = userLookup[b];

    if (userFullName(userA) < userFullName(userB)) return -1;
    if (userFullName(userA) > userFullName(userB)) return 1;
    return 0;
  });
};
export const useSortedConnections = (_props_userId) => {
  const currentUserId = useCurrentUserId();
  const userId = _props_userId || currentUserId;

  const { isLoading, connections } = useConnections(userId);
  const usersStore = useSelector((state) => state.users.userList);

  const [sortedConnections, setSortedConnections] = React.useState(
    connections?.length > 0 ? sort(connections, usersStore) : null
  );

  React.useEffect(() => {
    if (connections?.length > 0) {
      const sorted = sort(connections, usersStore);
      setSortedConnections(sorted);
    }
  }, [connections, usersStore]);

  return {
    isLoading,
    sortedConnections,
  };
};
