import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { defineFontSettings, Body5 } from '../../lib/nvstr-common-ui.es';
import { Page, Spacing } from '../../components/layout';
import { FlatButton } from '../../components/buttons';
import { Text } from '../../components/text';
import { TrackingEvents } from '../../../utils/tracking/events';
import { Modal } from '../../components/layout';
import { ROUTES } from '../../../constants/paths';
import { Error, ActionCompleted, LoadingPage } from '../../components/page';
import { startAnnualMembership } from '../../../actions';
import {
  currentUserDailyPriceAnnualMembershipDisplay,
  currentUserMembershipMonthlyFeeAmount,
  currentUserMembershipMonthlyFreeTradeLimit,
} from '../../../helpers/currentUserHelpers';
import { useCurrentUser } from '../../hooks/user';
import { useMembership } from '../../hooks/user/useMembership';
import { customParseFloat } from '../../../helpers/numberHelpers';
import { CheckBullet } from '../../components/ui';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { PRODUCT_NAMES } from '../../../appConfig';

const Certificate = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 21L15 20L17 21V14H13M17 9V7L15 8L13 7V9L11 10L13 11V13L15 12L17 13V11L19 10M20 3H4A2 2 0 0 0 2 5V15A2 2 0 0 0 4 17H11V15H4V5H20V15H19V17H20A2 2 0 0 0 22 15V5A2 2 0 0 0 20 3M11 8H5V6H11M9 11H5V9H9M11 14H5V12H11Z" />
    </svg>
  );
};

const StrikeThrough = styled.div`
  * {
    text-decoration: line-through;
  }
`;

const HeadingIconWrapper = styled.div`
  padding: 8px 0 8px 0;
  text-align: center;

  svg {
    height: 64px;
    width: 64px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

const ActionsWrapper = styled.div`
  padding: 24px 0 0 0;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const Container = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
`;

const Content = styled.div`
  overflow: auto;

  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Actions = styled.div``;

const headingFontSettings = defineFontSettings(
  {
    fontSize: '18px',
    lineHeight: '24px',
  },
  {
    fontSize: '24px',
    lineHeight: '32px',
  }
);

const oldPriceFontSettings = defineFontSettings(
  {
    fontSize: '18px',
    lineHeight: '24px',
  },
  {
    fontSize: '24px',
    lineHeight: '28px',
  }
);

const priceFontSettings = defineFontSettings(
  {
    fontSize: '24px',
    lineHeight: '248x',
  },
  {
    fontSize: '36px',
    lineHeight: '42px',
  }
);

const AnnualMembershipOptIn = ({ onContinue }) => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { annualFee } = useMembership();

  const [showingModal, setShowingModal] = React.useState(false);
  const [isUpgradingAccount, setIsUpgradingAccount] = React.useState(false);
  const [isAccountUpgraded, setIsAccountUpgraded] = React.useState(false);
  const [errorUpgrading, setErrorUpgrading] = React.useState(null);

  const membershipFreeTradesTotal = currentUserMembershipMonthlyFreeTradeLimit(currentUser);
  const showDailyPrice = currentUserDailyPriceAnnualMembershipDisplay(currentUser);

  const monthlyPrice = currentUserMembershipMonthlyFeeAmount(currentUser);
  const nondiscountedAnnualPrice = (12 * monthlyPrice).toFixed(2);
  const savings = !isNaN(annualFee) ? nondiscountedAnnualPrice - annualFee : null;
  const percentSavings = customParseFloat(!isNaN(savings) ? savings / nondiscountedAnnualPrice : 0).toFixed(2);
  const percentSavingsDisplay = percentSavings * 100;

  const discountedAnnualPrice = (12 * (monthlyPrice * (1 - percentSavings))).toFixed(2);
  const discountedMonthlyPrice = (monthlyPrice * (1 - percentSavings)).toFixed(2);

  const dailyPrice = nondiscountedAnnualPrice / 365;
  const discountedDailyPrice = (discountedAnnualPrice / 365).toFixed(2);

  React.useEffect(() => {
    TrackingEvents.user.VIEW_ANNUAL_MEMBERSHIP_OPT_IN.send();
  }, []);

  const handleContinue = () => {
    if (onContinue) {
      onContinue();
    } else {
      navigate(ROUTES.DASHBOARD.build());
    }
  };

  const upgradeAccount = async () => {
    setErrorUpgrading(null);
    setShowingModal(false);
    setIsUpgradingAccount(true);

    const { ok, errorMessage } = await startAnnualMembership();
    if (ok || errorMessage === 'You already have an annual membership.') {
      TrackingEvents.user.ANNUAL_MEMBERSHIP_OPT_IN_REQUEST_SUCCESS.send();
      setIsAccountUpgraded(true);
      setIsUpgradingAccount(false);
    } else {
      TrackingEvents.user.ANNUAL_MEMBERSHIP_OPT_IN_REQUEST_FAILURE.send({
        'Error Message': errorMessage,
      });
      setIsUpgradingAccount(false);
      setErrorUpgrading(errorMessage);
    }
  };

  const handleRetryUpgrade = () => {
    upgradeAccount();
  };

  const handleUpgradeNowPress = async () => {
    TrackingEvents.user.CLICK_ANNUAL_MEMBERSHIP_OPT_IN_ACTION.send({
      Action: 'Upgrade',
    });

    setShowingModal(true);
  };

  const handleMaybeLaterPress = () => {
    TrackingEvents.user.CLICK_ANNUAL_MEMBERSHIP_OPT_IN_ACTION.send({
      Action: 'Decline',
    });
    handleContinue();
  };

  const handleModalConfirm = () => {
    TrackingEvents.user.CLICK_ANNUAL_MEMBERSHIP_OPT_IN_CONFIRMATION_MODAL_ACTION.send({
      Action: 'Confirm',
    });

    upgradeAccount();
  };

  const handleModalDismiss = () => {
    TrackingEvents.user.CLICK_ANNUAL_MEMBERSHIP_OPT_IN_CONFIRMATION_MODAL_ACTION.send({
      Action: 'Cancel',
    });

    setShowingModal(false);
  };

  if (isAccountUpgraded) {
    return <ActionCompleted message={'Account Upgraded'} width={'560px'} onContinue={handleContinue} />;
  }

  if (errorUpgrading !== null) {
    return <Error message={errorUpgrading} width={'560px'} onRetry={handleRetryUpgrade} />;
  }

  if (isUpgradingAccount) {
    return <LoadingPage message={'Upgrading your account...'} />;
  }

  if (isNaN(annualFee)) {
    return <LoadingPage message={'Loading...'} />;
  }

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.agreements}>
      {showingModal && (
        <Modal onDismiss={handleModalDismiss}>
          <Body5>
            Your bank account will be automatically charged ${annualFee.toFixed('2')} annually. Cancel anytime for a
            pro-rated refund.
          </Body5>
          <ActionsWrapper>
            <div>
              <FlatButton onClick={handleModalConfirm}>Confirm</FlatButton>
            </div>
            <div>
              <FlatButton transparent onClick={handleModalDismiss}>
                Cancel
              </FlatButton>
            </div>
          </ActionsWrapper>
        </Modal>
      )}
      <Page expandheight={'true'} width={'560px'} style={{ position: 'relative', overflow: 'hidden' }}>
        <Container>
          <Content>
            <HeadingIconWrapper>
              <Certificate width={64} />
            </HeadingIconWrapper>

            <Spacing center>
              <div style={{ maxWidth: '360px', margin: '0 auto' }}>
                <Text fontSettings={headingFontSettings} bold>
                  Upgrade your Tornado membership to annual and{' '}
                </Text>
                <Text fontSettings={headingFontSettings} bold>
                  SAVE&nbsp;{percentSavingsDisplay}%
                </Text>
              </div>
            </Spacing>

            {showDailyPrice ? (
              <div>
                <Spacing top={24} center>
                  <StrikeThrough>
                    <Text fontSettings={oldPriceFontSettings} center isLowContrast strikethrough>
                      {'$' + monthlyPrice.toFixed(2) + '/month'}
                    </Text>
                  </StrikeThrough>

                  <Text fontSettings={priceFontSettings} center bold>{`$${discountedDailyPrice}/day`}</Text>
                  <Spacing top={4} center>
                    <Text fontSettings={oldPriceFontSettings} center>{`${percentSavingsDisplay}% OFF`}</Text>
                  </Spacing>
                  <Spacing top={16} center>
                    <Body5 center>Billed annually as a one-time charge of ${discountedAnnualPrice}</Body5>
                  </Spacing>
                </Spacing>
              </div>
            ) : (
              <div>
                <Spacing top={24} center>
                  <StrikeThrough>
                    <Text fontSettings={oldPriceFontSettings} center isLowContrast strikethrough>
                      {'$' + monthlyPrice.toFixed(2)}
                    </Text>
                  </StrikeThrough>

                  <Text fontSettings={priceFontSettings} center bold>{`$${discountedMonthlyPrice}/month`}</Text>
                  <Spacing top={4} center>
                    <Text fontSettings={oldPriceFontSettings} center>{`${percentSavingsDisplay}% OFF`}</Text>
                  </Spacing>
                  <Spacing top={16} center>
                    <Body5 center>Billed annually as a one-time charge of ${discountedAnnualPrice}</Body5>
                  </Spacing>
                </Spacing>
              </div>
            )}

            <div style={{ margin: '0 auto', maxWidth: 220 }}>
              <Spacing top={24} center>
                <Body5 center>Includes all these great {PRODUCT_NAMES.Tornado} membership perks:</Body5>

                <Spacing top={16} bottom={16}>
                  <CheckBullet text={`${membershipFreeTradesTotal} commission-free trades a month`} />
                </Spacing>

                <CheckBullet text={'1 free Viewpoint Contributor premium subscription'} />
              </Spacing>
            </div>
          </Content>

          <Actions>
            <Spacing top={24} center>
              <Container fullWidth centerAll>
                <FlatButton fullWidth onClick={handleUpgradeNowPress}>
                  Upgrade Now
                </FlatButton>
              </Container>

              <Spacing top={8} center>
                <Container fullWidth centerAll>
                  <FlatButton fullWidth transparent onClick={handleMaybeLaterPress}>
                    Maybe later
                  </FlatButton>
                </Container>
              </Spacing>
            </Spacing>
          </Actions>
        </Container>
      </Page>
    </LocationContext.Provider>
  );
};

export default AnnualMembershipOptIn;
