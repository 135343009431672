import React, { useCallback } from 'react';
import { enums } from '@src/main/lib/nvstr-utils.es';
import LinkTo from '../../containers/Links/LinkTo';
import { logMetricsTrackingEvent } from '../../actions/trackingActions';
import { useButtonEvent } from '../../utils/itly/hooks/useButtonEvent';

const LinkButton = (props) => {
  const { viewingContext, ignoreTracking, text, linkTo } = props;
  const onClick = useButtonEvent(props);

  if (!linkTo) {
    console.error('Must supply linkTo prop');
  }
  if (!viewingContext && !ignoreTracking) {
    console.error('Cannot log tracking if no viewingContext is supplied');
  }

  const logLinkClick = useCallback(() => {
    if (!ignoreTracking) {
      const event = `Clicked ${text} Link`;
      const properties = {
        Context: viewingContext,
      };
      logMetricsTrackingEvent(event, properties)();
      onClick();
    }
  }, [text, viewingContext]);

  return (
    <LinkTo
      to={linkTo}
      className={`btn btn-primary-color btn-small-tall`}
      additionalClickAction={logLinkClick}
      text={text}
      scrollToTop={props.scrollToTop}
    ></LinkTo>
  );
};

LinkButton.defaultProps = {
  buttonType: enums.button_types.link,
};

export default LinkButton;
