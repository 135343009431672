import React, { Component } from 'react';

import SecuritiesFilterCardList from './SecuritiesFilterCardList';
import { PageHeading } from '../../components/UI/PageHeading';

class PresetFilterSelection extends Component {
  render() {
    return (
      <div className="portfolio-explorer-preset-filter-container">
        <PageHeading className="heading">Find new investment ideas</PageHeading>

        <SecuritiesFilterCardList
          presetFilterList={this.props.presetFilterList}
          presetFilterCardSize={this.props.presetFilterCardSize}
          handleFilterCardClick={this.props.handleFilterCardClick}
        />

        <div className={`fundamental-data-provider-disclaimer secondary-text-color`}>
          Fundamental data provided by S&P Capital IQ
        </div>
      </div>
    );
  }
}
export default PresetFilterSelection;
