import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import PageLoading from '../../components/PageLoading';
import CreateAllocationConstraintContainer from './CreateAllocationConstraintContainer';
import AllocationConstraint from './AllocationConstraint';
import AddButton from '../../components/buttons/AddButton';
import { FlatButton, SkeletonButton } from '../../main/components/buttons';

class AllocationConstraintsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
    };
  }

  componentDidMount() {
    this.props.actions.getOptimizerAllocationConstraints();
  }

  render() {
    if (this._isLoading()) {
      return <PageLoading />;
    }

    return (
      <div className={`optimize-constraints-list-container`}>
        {this._renderCreateConstraint()}
        {this._renderAllocationConstraints()}
      </div>
    );
  }

  handleAllocationConstraintCreation = (querySecurityData) => {
    const securityId = querySecurityData.security_id;

    if (this._isAlreadyAnAllocationConstraint(securityId)) {
      this._moveAllocationConstraintToTopOfList(securityId);
    } else {
      this._createAllocationConstraint(querySecurityData);
    }
    return true;
  };

  _returnCurrentUserEquity = () => {
    return this.props.currentUser.equity;
  };

  _createAllocationConstraint = (querySecurityData) => {
    const securityId = querySecurityData.security_id;
    const optimizeConstraint = {
      ...querySecurityData,
    };
    this.props.actions.createOptimizerAllocationConstraints(querySecurityData);
  };

  _moveAllocationConstraintToTopOfList = (securityId) => {
    this.props.actions.moveAllocationConstraintToTopOfList(securityId);
  };

  _renderCreateConstraintContainer = () => {
    return (
      <CreateAllocationConstraintContainer
        handleAllocationConstraintCreation={this.handleAllocationConstraintCreation}
      />
    );
  };

  _isAlreadyAnAllocationConstraint = (securityId) => {
    const optimizeConstraints = this._returnListOfAllocationConstraints();
    return optimizeConstraints.some((ac) => ac.security_id === securityId);
  };

  _renderCreateConstraintButton = () => {
    return (
      <div className={`create-optimize-constraint-btn-container text-center`}>
        <SkeletonButton onClick={this._startCreatingConstraint} width={'135px'}>
          + Add Constraint
        </SkeletonButton>
      </div>
    );
  };

  _renderCreateConstraint = () => {
    if (this.state.isCreating) {
      // show search field
      return this._renderCreateConstraintContainer();
    } else {
      // show add button
      return this._renderCreateConstraintButton();
    }
  };

  _renderAllocationConstraints = () => {
    const optimizeConstraints = this._returnListOfAllocationConstraints();
    return optimizeConstraints.map((optimizeConstraint, i) => (
      <AllocationConstraint
        key={`optimize-constraint-${optimizeConstraint.security_id}`}
        {...optimizeConstraint}
        currentUserEquity={this.props.currentUser.equity}
        runOptimizer={this.props.runOptimizer}
      />
    ));
  };

  _startCreatingConstraint = () => {
    this.setState((prevState) => ({
      isCreating: true,
    }));
  };

  _createConstraint = () => {
    this.setState((prevState) => ({
      isCreating: false,
    }));
  };

  _cancelCreatingConstraint = () => {
    this.setState((prevState) => ({
      isCreating: false,
    }));
  };

  _isLoading = () => {
    return this._returnAllocationDataStore().loading;
  };

  _returnAllocationDataStore = () => {
    return this.props.optimizer.optimizeConstraints;
  };

  _returnListOfAllocationConstraints = () => {
    return this._returnAllocationDataStore().list || [];
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    optimizer: state.optimizer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllocationConstraintsList);
