import React from 'react';
import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import styled from 'styled-components';

const FieldSetWrapper = styled.fieldset`
  div.checkbox {
    background-color: transparent;
    border-color: ${({ theme }) => theme.themeColors.text};
  }
  div.checkbox.checked {
    background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
  }
  div.checkbox.checked::before {
    background-color: ${({ theme }) => theme.themeColors.buttonText};
  }
  div.checkbox.checked::after {
    background-color: ${({ theme }) => theme.themeColors.buttonText};
  }
`;

class Checkbox extends React.Component {
  render() {
    if (this.props.dontUseFieldset) {
      return (
        <div
          className={`
          standalone-checkbox-container
          ${this._returnName()}
          ${this._returnClassName()}
          ${this._isDisabled() ? 'disabled' : ''}
        `}
        >
          {this._renderCheckbox()}
          {this._renderLabel()}
        </div>
      );
    }
    return (
      <FieldSetWrapper
        className={`
          standalone-checkbox-container
          ${this._returnName()}
          ${this._returnClassName()}
          ${this._isDisabled() ? 'disabled' : ''}
        `}
      >
        {this._renderCheckbox()}
        {this._renderLabel()}
      </FieldSetWrapper>
    );
  }

  _renderLabel = () => {
    return (
      this._shouldShowLabel() && (
        <label className={'form-label'} onClick={this._handleClick}>
          {this._returnLabel()}
        </label>
      )
    );
  };

  _renderCheckbox = () => {
    return (
      <div className="checkbox-container">
        <div
          name={this._returnName()}
          className={`
            checkbox
            ${this._isChecked() ? 'checked' : ''}
          `}
          onClick={this._handleClick}
        />
      </div>
    );
  };

  _renderErrorMessage = () => {
    const isError = !!this.props.errorMessage;
    const errorMessage = isError ? this.props.errorMessage : '';
    return <p className={`text-field-error-message`}>{errorMessage}</p>;
  };

  _returnName = () => this.props.name || '';

  _returnLabel = () => this.props.label;

  _shouldShowLabel = () => this.props.forceShowLabel || !isUndefinedOrNull(this._returnLabel());

  _isChecked = () => {
    if (this._isDisabled() && this.props.useOverrideValueWhenDisabled) {
      return this.props.overrideValueWhenDisabledTo;
    }
    return this.props.value;
  };

  _returnClassName = () => this.props.className || '';

  _isDisabled = (value) => this.props.isDisabled;

  _handleClick = () => {
    if (this._isDisabled()) {
      return false;
    }

    this.props.handleClick(this._returnName());
  };
}

export default Checkbox;
