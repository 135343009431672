import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../../actions/index';

import IdeasList from './IdeaComponents/IdeasList';
import PageLoading from '../../PageLoading';

import { findIdeasForSecurityId, separateIdeasByLongAndShort } from '../../../helpers/ideaHelpers';

class SecurityStackedRow extends Component {
  render() {
    const securityId = this.props.security.security_id;
    // filtering out thought leaders
    const ideas = findIdeasForSecurityId(securityId, this.props.ideas.ideaList).filter(
      (idea) => 'price_target' in idea && idea.active
    );
    const ideasNoCurrentUserIdeas = ideas.filter((idea) => !idea.current_users_idea);
    const { long, short } = separateIdeasByLongAndShort(ideasNoCurrentUserIdeas);
    const userOwnIdea = ideas.filter((idea) => idea.current_users_idea)[0];
    if (userOwnIdea) {
      (userOwnIdea.idea_type.id === 0 ? long : short).unshift(userOwnIdea);
    }

    const isTwoColumns = short.length > 0 && long.length > 0;
    const columnContainer = isTwoColumns ? 'float-left' : 'container-center';

    if (this.props.isLoading) {
      return (
        <div className="stacked-row" style={this.props.containerStyling || {}}>
          <PageLoading icon="fading-3balls" size="small" flatTransparent hideLoadingText />
        </div>
      );
    }

    return (
      <div className="stacked-row" style={this.props.containerStyling || {}}>
        {long.length > 0 && (
          <div>
            <div className="stacked-ideas-container">
              <div className={`column-container ${columnContainer}`}>
                <div className="horizontal-heading secondary-text-color">Interested in Buying</div>
                <IdeasList ideas={long} position={'long'} style={'responsive'} hideLabels showIdeaTypeShadow />
              </div>
            </div>
          </div>
        )}
        {short.length > 0 && (
          <div>
            <div className="stacked-ideas-container">
              <div className={`column-container ${columnContainer}`}>
                <div className="horizontal-heading secondary-text-color">Interested in Selling</div>
                <IdeasList
                  ideas={short}
                  position={'short'}
                  style={'responsive'}
                  expandDirection={'left'}
                  reverse
                  hideLabels
                  showIdeaTypeShadow
                />
              </div>
            </div>
          </div>
        )}
        <div className="clear-float"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ideas: state.ideas,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityStackedRow);
