import { ADD_SECURITY_FUNDAMENTAL_DATA } from '../constants';

// const defaultDataKeys = {
//   'Price / Earnings': true,
//   'Forward P/E': true,
//   'Forward P/E Next Yr': true,
//   'Forward EPS': true,
//   'Forward EPS Next Yr': true,
//   'Price / Tangible Book': true,
//   'Dividend Yield': true,
//   'Total Enterprise Value': true,
//   'Market Cap': true,
//   'Net Debt': true,
//   'Revenue LTM': true,
//   'Projected Revenue Growth': true,
//   'Price / Sales': true,
//   'Forward P/S': true,
//   'Forward P/S Next Yr': true,
//   'Beta': true,
// }

const defaultState = {
  securities: {},
};

const extractFundamentalData = (dataArray) => {
  const extractedData = {};
  dataArray.forEach((dataObj) => {
    extractedData[dataObj.name] = dataObj.value;
  });
  return extractedData;
};

export default function fundamentalDataReducer(state = defaultState, action) {
  let securities, newData, security;

  switch (action.type) {
    case ADD_SECURITY_FUNDAMENTAL_DATA:
      securities = action.payload;
      newData = {};
      if (Array.isArray(securities)) {
        securities.forEach((security) => {
          newData[security.security_id] = extractFundamentalData(security.fundamentals);
        });
        return {
          ...state,
          securities: { ...state.securities, ...newData },
        };
      } else {
        security = securities;
        return {
          ...state,
          securities: {
            ...state.securities,
            ...{
              [securities.security_id]: extractFundamentalData(security.fundamentals),
            },
          },
        };
      }

    default:
      return state;
  }
}
