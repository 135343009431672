import React from 'react';
import QueuedAnimation from './QueuedAnimation';

const NumberChange = (props) => {
  const { textColorTypeValue, value, children } = props;

  return (
    <QueuedAnimation
      value={value}
      textColorTypeValue={textColorTypeValue}
      animationClass={`number-change-animation`}
      appendTextColorType
    >
      {children}
    </QueuedAnimation>
  );
};

export default NumberChange;
