import React, { useEffect, useState } from 'react';
import {
  ExtraOptions,
  FormWrap,
  LoadingCover,
  LogoMobileWrap,
  SignSwitchLink,
  ValidationWrap,
} from '@src/main/components/login/LoginStyles';
import Loading from '@src/main/components/login/Loading';
import { FullLogo } from '@src/main/components/logo/FullLogo';
import InputField from '@src/main/components/form/InputField';
import { Body5, Body6, H3, H5, Place, Row } from '@src/main/lib/nvstr-common-ui.es';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { FlatButton, SkeletonButton } from '@src/main/components/buttons';
import { isSignedIn, signIn } from '@src/main/containers/SignIn/utils/services';
import { signInValidation } from '@src/main/containers/SignIn/utils/SignInValidation';
import { VALIDATION_MESSAGES } from '@src/main/containers/SignIn/constants';
import styled from 'styled-components';
import { Container } from '@src/main/components/ui';
import { sendSessionStartEvents } from '@src/utils/tracking/amplitude';
import { extendedFetch } from './utils/services';
import {
  clearItemFromStorage,
  getItemFromStorage,
  INTERNAL_STORAGE_KEYS,
  InternalStorage,
  setItemToStorage,
} from '@src/utils/storage';
import { BASEURL } from '@src/adapters';
import { BASE_PRODUCT_DOMAIN, IS_WHITE_LABELED, PRODUCT_DISPLAY_FULL_NAME, PRODUCT_NAME } from '@src/appConfig';
import { TrackingEvent } from '@src/main/lib/nvstr-utils.es';
import { ROUTES } from '@src/constants/paths';

const SignInFormWrapper = styled.div`
  max-width: 360px;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  padding: 24px 0 0 0;

  button {
    width: 140px;
  }
`;

export const SignInForm = () => {
  const navigate = useNavigate();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rememberMe, setRememberMe] = React.useState('1');

  const [validationMessage, setValidationMessage] = React.useState('');
  const [isSigningIn, setIsSigningIn] = React.useState(false);

  const userSignIn = async (e) => {
    e?.preventDefault();

    const validationState = signInValidation({
      email,
      password,
    });

    if (!validationState.isValid) {
      setIsSigningIn(false);
      if (VALIDATION_MESSAGES[validationState.validationField]) {
        setValidationMessage(VALIDATION_MESSAGES[validationState.validationField]);
      }
    }

    setIsSigningIn(true);

    const { isAuthed, route_to, errorMessage } = await signIn({
      email,
      password,
      rememberMe,
    });

    if (isAuthed) {
      if (rememberMe) {
        setItemToStorage(INTERNAL_STORAGE_KEYS.EMAIL, email);
      }

      const prevIntent = getItemFromStorage(INTERNAL_STORAGE_KEYS.NAVIGATION_INTENT);
      const route = prevIntent || route_to || '/';
      navigate(route);
      clearItemFromStorage(INTERNAL_STORAGE_KEYS.NAVIGATION_INTENT);

      const userSessionResponse = await extendedFetch(`${BASEURL}/api/v1/current_user/session`, 'GET', null);

      const userSessionData = await userSessionResponse.json();
      const { status } = userSessionResponse;
      if (status === 200) {
        sendSessionStartEvents(userSessionData);
      }
    } else {
      setIsSigningIn(false);
      setValidationMessage(errorMessage);
    }
  };

  const clickRemember = (e) => {
    const newValue = rememberMe === '1' ? '' : '1';
    if (newValue === '') {
      setItemToStorage(INTERNAL_STORAGE_KEYS.EMAIL, '');
      InternalStorage.setItemInStorage(INTERNAL_STORAGE_KEYS.EMAIL, '');
    }
    setRememberMe(newValue);
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      userSignIn(e);
    }
  };

  return (
    <SignInFormWrapper onKeyDown={(e) => handleKeyUp(e)}>
      {isSigningIn ? (
        <LoadingCover>
          <Loading text={'Signing In...'} />
        </LoadingCover>
      ) : (
        <FormWrap>
          <LogoMobileWrap>
            <FullLogo />
          </LogoMobileWrap>
          <form>
            <InputField
              id="email-field"
              placeholder={'Email'}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            />
            <InputField
              id="password-field"
              placeholder={'Password'}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
            <ExtraOptions>
              <Row position="relative" vAlign="center">
                <input
                  type="checkbox"
                  id="checkbox"
                  checked={rememberMe === '1'}
                  onClick={clickRemember}
                  style={{ cursor: 'pointer', marginTop: 0 }}
                />
                <label htmlFor="checkbox" className="not-selectable" style={{ cursor: 'pointer', margin: 0 }}>
                  <Place padding="0 0 0 8px" position="relative" top="0px" display="inline-block">
                    Stay logged in
                  </Place>
                </label>
              </Row>
              <Place>
                <Link to="/forgot_password">Forgot Password</Link>
              </Place>
            </ExtraOptions>

            <Container bottom={8}>
              {validationMessage ? <ValidationWrap>{validationMessage}</ValidationWrap> : null}
            </Container>

            <FlatButton id="sign-in-submit" fullWidth onClick={userSignIn}>
              Sign In
            </FlatButton>

            <Container top={8}>
              <SignSwitchLink>
                Don't have an account? <Link to="/signup">Sign Up</Link>
              </SignSwitchLink>
            </Container>
          </form>
        </FormWrap>
      )}
    </SignInFormWrapper>
  );
};
