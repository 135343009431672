import React, { Component } from 'react';

import IdeaField from './IdeaField';
import ThoughtsListWithSortOptions from '../Thoughts/ThoughtsListWithSortOptions';

import Button from '../../components/buttons/Button';
import Icon from '../../components/misc/Icon';

import { isIdeaUsingPriceTarget, calculateExpectedReturn } from '../../helpers/ideaHelpers';
import { userFirstName } from '../../helpers/usersHelpers';
import { SkeletonButton } from '../../main/components/buttons';

export class UserIdea extends Component {
  renderIdeaField = (key, field) => {
    return (
      <IdeaField
        key={key}
        field={field}
        input={this.findInput(field, this.props.inputs)}
        inputProps={this.props.inputProps}
        screenWidth={this.props.screenWidth}
        idea={this.props.idea}
        security={this.props.security}
        editable={this.props.ideaIsCurrentUsersIdea && this.props.security.featured}
        userAddingPriceTarget={this.props.userAddingPriceTarget}
        warnings={this.props.warnings}
        dismissWarning={this.props.dismissWarning}
      />
    );
  };

  findInput = (field, inputs) => {
    if (!inputs) {
      return null;
    }

    let inputFound = null;
    inputs.forEach((input) => {
      if (input.name === field.property) {
        inputFound = input;
      }
    });
    return inputFound;
  };

  handleAddPriceTarget = () => {
    this.props.handleAddPriceTarget();
  };

  handleRemovePriceTarget = () => {
    this.props.handleRemovePriceTarget(this.props.idea.idea_id);
  };

  showIdeaIsNotActiveMessage = () => {
    return (
      !this.props.idea.active &&
      !(this.props.currentUsersIdea && this.props.idea.idea_id === this.props.currentUsersIdea.idea_id)
    );
  };

  render() {
    const { formData, idea, security, screenWidth, ideaIsCurrentUsersIdea, userAddingPriceTarget, currentPrice } =
      this.props;

    const ideaUsingPriceTarget = isIdeaUsingPriceTarget(idea) || (ideaIsCurrentUsersIdea && userAddingPriceTarget);
    const expectedReturn = calculateExpectedReturn({
      ideaUsingPriceTarget,
      idea,
      security,
      formData,
      currentPrice,
    });

    const headingFields = [{ property: 'idea_type' }];

    const leftPanelFields = [
      {
        property: ideaUsingPriceTarget ? 'price_target' : 'expected_return',
        subValueProperty: ideaUsingPriceTarget ? 'expected_return' : null,
        subValue:
          ideaUsingPriceTarget || formData.price_target ? (formData.price_target ? expectedReturn : null) : null,
        showSubAction: ideaIsCurrentUsersIdea,
        subAction: ideaUsingPriceTarget ? (
          <div style={{ paddingBottom: '5px' }}>
            <SkeletonButton onClick={this.handleRemovePriceTarget}>Remove price target</SkeletonButton>
          </div>
        ) : (
          <div style={{ paddingTop: '10px', paddingBottom: '5px' }}>
            <SkeletonButton onClick={this.handleAddPriceTarget}>Have a price target?</SkeletonButton>
          </div>
        ),
      },
      { property: 'conviction' },
    ];

    const rightPanelFields = [{ property: 'expected_dividend' }, { property: 'thesis' }, { property: 'horizon' }];

    return (
      <div className="idea-content">
        {this.showIdeaIsNotActiveMessage() && (
          <div className="inactive-idea-alert-container">
            <Icon icon={'fa-exclamation-circle'} color="#eecc06" size="fa-icon-small" style={{ marginRight: '8px' }} />
            <span style={{ textAlign: 'left' }}>
              {`${userFirstName(this.props.user)} is no longer considering this security for their portfolio.`}
            </span>
          </div>
        )}

        <div className="heading-idea-panel">
          <div className="idea-security-identificator secondary-text-color">
            {`${security.symbol} (${security.name})`}
          </div>
          <div className="idea-type-field">
            {headingFields.map((field, i) => this.renderIdeaField(`field-${field.property}`, field))}
          </div>
        </div>

        <div className="left-idea-panel">
          {leftPanelFields.map((field, i) => this.renderIdeaField(`field-${field.property}`, field))}
        </div>
        <div className="right-idea-panel">
          {rightPanelFields.map((field, i) => this.renderIdeaField(`field-${field.property}`, field))}
        </div>

        <div className="user-thoughts-container">
          <ThoughtsListWithSortOptions
            security={this.props.security}
            viewingContext={'Idea Panel Thoughts List'} // viewingContext is used for tracking events related to filter and sorting
            ThoughtsDataContainerProps={{
              userId: this.props.idea.user_id,
              idea: this.props.idea,
              lockSingleColumnLayout: true,
              showAsThoughtsInIdea: true,
            }}
            scrollContainerSelector={'.idea-show-container'}
            distanceFromBottomToTriggerInfiniteScroll={450}
            useInfiniteScroll
            flatLoadingStyle
            isUserAuthed
          />
        </div>
      </div>
    );
  }
}

export default UserIdea;
