import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { logMetricsTrackingEvent, sendThoughtClickEvent } from '@src/actions';
import PageLoading from '../../components/PageLoading';
import Image from '../UI/Image';
import VideoPlayer from '../UI/VideoPlayer';
import { getLinkDomain, saveImpressionToStorageIfUnrecorded } from '@src/helpers/thoughtsHelpers';
import { getYoutubeVideoId, isAudioLink, isYoutubeLink, preventClickThruLink } from '@src/helpers/generalHelpers';
import { FlatButton } from '@src/main/components/buttons';
import { withRouter } from '@src/main/utils';
import { Container } from '@src/main/components/ui';
import { Body5 } from '@src/main/lib/nvstr-common-ui.es';
import { TrackingEvent } from '@src/main/lib/nvstr-utils.es';
import { THE_STREET_NEWS_UTM_CAMPAIGNS } from '@src/constants/types/utm';
import { PRODUCT_NAME, PRODUCT_NAMES } from '@src/appConfig';

const AudioPodcastPlayActionWrapper = styled.div`
  padding: 16px 0;
`;

const PlayIconWrapper = styled.svg`
  height: 24px;
  width: 24px;

  path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

const RemoveImageButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;

  button {
    padding: 4px 16px;
  }
`;

const AudioPodcastPlayAction = ({ url }) => {
  const hc = () => {
    TrackingEvent.create('Clicked Audio Play In Thought', {
      'Audio URL': url,
    });
    window.open(url, '_blank');
  };

  return (
    <AudioPodcastPlayActionWrapper>
      <FlatButton onClick={hc} buttonProps={{ stopPropagation: true }}>
        <Container row verticallyCenter fullWidth>
          <Container right={8}>
            <PlayIconWrapper xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M10,16.5L16,12L10,7.5V16.5Z" />
            </PlayIconWrapper>
          </Container>
          <Body5 bold>Listen Now</Body5>
        </Container>
      </FlatButton>
    </AudioPodcastPlayActionWrapper>
  );
};

class ThoughtLink extends React.PureComponent {
  constructor(props) {
    super(props);
    this._isLoading = true;

    this.state = {
      renderAsVideoPlayer: false,
    };
  }

  componentDidMount() {
    if (
      !this._isLinkEntered() ||
      (this._returnOgoProperties() && !this._returnOgoProperties().url) ||
      (this._returnOgoProperties() && !this._returnOgoProperties().imageLink)
    ) {
      this.handleOnLoad();
    }
  }

  render() {
    const { loadingPreview: isLoadingPreview } = this.props;

    if (!this._isLinkEntered()) return null;

    if (isLoadingPreview) {
      return (
        <PageLoading
          icon="fading-3balls"
          size="small"
          pageName="Link Preview"
          style={{ background: 'rgba( 0, 0, 0, 0 )', marginBottom: '15px' }}
          flatStyle
        />
      );
    }

    const { url } = this._returnOgoProperties();
    if (!url) return null;

    if (isAudioLink(url)) return <AudioPodcastPlayAction url={url} />;

    if (isYoutubeLink(url))
      return (
        <div className="preview-link-anchor-tag">
          <div className="span-block" style={{ textDecoration: 'none' }} onClick={this.handleYoutubeVideoClick}>
            <span className="link-preview-container" style={this.props.style || {}}>
              {this._renderImage()}
              {this._renderLinkDescriptionBox()}
            </span>
            {this._shouldRenderRemoveImageButton() && (
              <RemoveImageButtonWrapper>
                <FlatButton transparent fullWidth onClick={this._handleRemovePreviewImage}>
                  Remove Image
                </FlatButton>
              </RemoveImageButtonWrapper>
            )}
          </div>
        </div>
      );

    return (
      <div className="preview-link-anchor-tag">
        <a
          className="span-block"
          href={this._buildExternalUrl()}
          target="_blank"
          rel="no-follow noopener noreferrer"
          style={{ textDecoration: 'none' }}
          onClick={this.handleLinkClick}
        >
          <span className="link-preview-container" style={this.props.style || {}}>
            {this._renderImage()}

            {this._renderLinkDescriptionBox()}
          </span>
        </a>
        {this._shouldRenderRemoveImageButton() && (
          <RemoveImageButtonWrapper>
            <FlatButton transparent fullWidth onClick={this._handleRemovePreviewImage}>
              Remove Image
            </FlatButton>
          </RemoveImageButtonWrapper>
        )}
      </div>
    );
  }

  _buildExternalUrl = () => {
    const url = this._returnOgoProperties().url;
    let externalUrl = url;
    if (url.includes('thestreet') && PRODUCT_NAMES.TheStreet === PRODUCT_NAME) {
      const campaign = THE_STREET_NEWS_UTM_CAMPAIGNS.proCon;
      const utmParams = `utm_source=tornado&utm_medium=webapp&utm_campaign=${campaign}`;
      externalUrl = `${url}${url.includes('?') ? '&' : '?'}${utmParams}`;
    }
    return externalUrl;
  };

  handleYoutubeVideoClick = (e) => {
    const { openVideoInPanel } = this.props;

    preventClickThruLink(e);

    if (openVideoInPanel) {
      const { navigate, location } = this.props;
      const { url } = this._returnOgoProperties();
      const currentPath = location.pathname;
      const youtubeVideoId = getYoutubeVideoId(url);
      const query = `?panel=video-player&videoId=${youtubeVideoId}&thoughtId=${this._returnThoughtId()}`;

      navigate(`${currentPath}${query}`);
    } else {
      this.setState(() => ({
        renderAsVideoPlayer: true,
      }));
    }

    this._logEventForThoughtLinkClick();
  };

  handleLinkClick = (e) => {
    preventClickThruLink(e);
    this._logEventForThoughtLinkClick();
  };

  _renderLinkDescriptionBox = () => {
    const { url, title, domain } = this._returnOgoProperties();
    return (
      <span className="link-preview-description-box">
        <span className="link-preview-title secondary-heading-text-color">{title || url}</span>
        <span className="link-preview-domain secondary-text-color">{domain}</span>
      </span>
    );
  };

  _renderImage = () => {
    const { renderAsVideoPlayer } = this.state;
    const { url, imageLink } = this._returnOgoProperties();
    const isImageAClickableVideo = !!url ? isYoutubeLink(url) : false;

    if (!imageLink) return null;

    return (
      <span className={'thought-list-item-image'} style={{ maxHeight: 'unset', height: 'unset' }}>
        {renderAsVideoPlayer ? (
          <VideoPlayer videoId={getYoutubeVideoId(url)} thoughtId={this._returnThoughtId()} autoPlay />
        ) : (
          <Image
            src={imageLink}
            onHTTPSRetrySuccess={this.updateImageSrc}
            parentClass={'.thought-list-item-image'}
            maxHeight={300}
            onLoad={this.handleOnLoad}
            renderVideoButton={isImageAClickableVideo}
            renderPlaceholder
          />
        )}
      </span>
    );
  };

  handleOnLoad = () => {
    if (this.props.onLoad && this._isLoading) {
      this.props.onLoad();
    }
  };

  _handleRemovePreviewImage = () => {
    this.props.handleRemovePreviewImage();
    if (this._isLoading) {
      this.handleOnLoad();
    }
  };

  _shouldRenderRemoveImageButton = () =>
    this.props.handleRemovePreviewImage &&
    this._returnOgoProperties().imageLink &&
    this._returnOgoProperties().isImageHTTPS;

  _returnOgo = () => this.props.ogo;

  _isLinkEntered = () => !!this._returnOgo();

  _isImageHTTPS = () =>
    this._returnOgo().image_link &&
    this._returnOgo()
      .image_link.toLowerCase()
      .includes('https');

  _returnUrlFromOgo = () => this._returnOgo().original_link_url || this._returnOgo().url;

  _returnOgoProperties = () => ({
    imageLink: this._returnOgo() ? this._returnOgo().image_link : null,
    isImageHTTPS: this._returnOgo() ? this._isImageHTTPS() : false,
    url: this._returnOgo() ? this._returnUrlFromOgo() : null,
    title: this._returnOgo() ? this._returnOgo().title : null,
    domain: (this._returnOgo() ? this._returnOgo().domain : null) || getLinkDomain(this._returnUrlFromOgo()),
  });

  updateImageSrc = (src) => {
    if (this.props.updateImageSrc) {
      this.props.updateImageSrc(src);
    } else {
      console.warn('updateImageSrc prop not found on ThoughtLink, skipping HTTPS update');
    }
  };

  _logEventForThoughtLinkClick = () => {
    const { dispatch } = this.props;
    const { url } = this._returnOgoProperties();
    const event = 'Clicked Link On Thought';
    const properties = {
      ...this._returnThoughtTrackingData(),
      'Suggested Thought': this.props.isSuggestedThought,
      'Youtube Video': isYoutubeLink(url),
      ...this._returnViewingContext(),
    };
    logMetricsTrackingEvent(event, properties)();

    if (this._returnThoughtId()) {
      saveImpressionToStorageIfUnrecorded('link', this._returnThoughtId(), this.props.viewingContext);
      sendThoughtClickEvent(
        this._returnThoughtId(),
        'link',
        this.props.viewingContext,
        this.props.metadata,
        this.props.notificationIndex
      )(dispatch);
    }
  };

  _returnThoughtTrackingData = () => ({
    'Thought Id': this._returnThoughtId(),
    'Thought Type': this.props.thoughtType,
    'Stock Symbol': this.props.stockSymbol,
    'Security ID': this.props.securityId,
  });

  _returnViewingContext = () => {
    return this.props.viewingContext ? { Context: this.props.viewingContext } : {};
  };

  _returnThoughtId = () => this.props.thoughtId;
}

const connectedComponent = connect()(ThoughtLink);
export default withRouter(connectedComponent);
