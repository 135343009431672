import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CloseButton } from '../main/components/buttons';

export const PAGE_PANEL_TYPE = {
  news: 'news',
  // security: 'security', // TODO: coming soon
};

const CloseButtonPosition = styled.div`
  position: absolute;
  top: 8px;
  right: 16px;

  background: #fff;
  padding: 6px 5px 4px 5px;
  border: 1px solid #222;
  border-radius: 20px;

  :hover {
    background: #ccc;
  }
`;

const PagePanelWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 5000;

  background-color: rgba(20, 20, 20, 0.4);
`;

const NewsPanelWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: column;
`;

const AdWrapper = styled.div`
  background-color: #fff;
  width: 100%;
`;
const NewsArticleWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
`;
const NewsArticle = styled.iframe`
  width: 100%;
`;

const NewsPanel = ({ dismiss }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const url = searchParams.get('url');

  const onDismiss = () => {
    const keys = ['url'];
    dismiss(keys);
  };

  React.useEffect(() => {
    try {
      const adsbygoogle = (window.adsbygoogle || []).push({});
    } catch (e) {
      console.error('Google Ad Sense Error');
      console.error(e);
    }
  }, []);

  return (
    <NewsPanelWrapper>
      <AdWrapper>
        <ins
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-6980989574649341"
          data-ad-slot="9357519222"
          data-ad-format="auto"
          data-full-width-responsive="true"
          // data-adtest="on"
        />
      </AdWrapper>
      <NewsArticleWrapper>
        <CloseButtonPosition>
          <CloseButton onClick={onDismiss} />
        </CloseButtonPosition>
        <NewsArticle src={url} />
      </NewsArticleWrapper>
    </NewsPanelWrapper>
  );
};

const panels = {
  [PAGE_PANEL_TYPE.news]: NewsPanel,
};

const PagePanel = ({ type, dismiss }) => {
  const panel = panels[type];
  if (!panel) {
    console.error('missing panel definition');
    return null;
  }

  return <PagePanelWrapper>{React.createElement(panel, { dismiss })}</PagePanelWrapper>;
};

export const PagePanelRenderer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const showing = searchParams.get('showing');

  const closePanel = (additionalParamKeys = []) => {
    const params = searchParams;
    params.delete('showing');
    additionalParamKeys.forEach((k) => params.delete(k));
    setSearchParams(params);
  };

  if (showing) {
    return <PagePanel type={showing} dismiss={closePanel} />;
  }

  return null;
};
