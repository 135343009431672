import React from 'react';
import Icon from '../../components/misc/Icon';

export const LinkedIn = (props) => {
  const returnTitle = () => props.title || '';
  const returnEncodedTitle = () => encodeURIComponent(returnTitle());
  const returnSummary = () => props.summary || '';
  const returnEncodedSummary = () => encodeURIComponent(returnSummary());
  const returnURL = () => props.url;
  const returnLink = () =>
    `https://www.linkedin.com/shareArticle?mini=true&url=${returnURL()}&title=${returnEncodedTitle()}&summary=${returnEncodedSummary()}&source=`;
  const handleClick = () => {
    if (props.additionalActionOnClick) {
      props.additionalActionOnClick();
    }
  };

  return (
    <div className={`social-button linked-in`}>
      <a
        id="linkedin_share"
        className={`${props.className || ''}`}
        href={returnLink()}
        target={'_blank'}
        onClick={handleClick}
      >
        <Icon icon={'fa fa-linkedin'} />
      </a>
    </div>
  );
};
