import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from '../../main/utils';
import * as Actions from '../../actions/index';

import PageLoading from '../../components/PageLoading';
import ThoughtsListWithHeading from '../Thoughts/components/ThoughtsListWithHeading';
import TabbedContainer from '../UI/TabbedContainer';
import NoThoughtsComponent from '../Thoughts/NoThoughtsComponent';
import { OpenCreateThoughtPanelButton } from '../Thoughts/components/OpenCreateThoughtPanelButton';

import { switchToTab } from '../../helpers/navigationHelpers';
import { parseQueryString } from '../../helpers/routerHelpers';
import { toCapitalCase } from '../../helpers/generalHelpers';
import { LoadingPageNames } from '../../constants';
import { getFocusThoughtFromUrl } from '../../helpers/thoughtsHelpers';
import { getThoughtImpressionEventName } from '../../constants/tracking';
import { TrackingEvents } from '../../utils/tracking/events';
import { TrackIteratively } from '../../utils/itly';
import { enums } from '@src/main/lib/nvstr-utils.es';
import { ElementPositionContext } from '../../context';
import styled from 'styled-components';
import { FlatButton, SkeletonButton } from '../../main/components/buttons';
import { Filter } from '../../main/icons';
import { Container } from '../../main/components/ui';

/*
  NOTES: generally wrapped in <ThoughtsDataContainer></ThoughtsDataContainer> which supplies the thought data

  Required Props for <ThoughtsContainer/>

  -----------------------------------------------

  Optional Props
    lockSingleColumnLayout -> bool

 */

const MobileThoughtFilterActionsWrapper = styled.div`
  padding: 16px 8px;

  .thoughts-sort-options-position-container,
  .thoughts-sort-options-container,
  .thoughts-sort-options {
    padding: 0;
    margin: 0;
    height: auto;
    width: 100%;
    max-width: unset;
  }
  .thoughts-sort-filter-option-container {
    padding: 0;
    margin: 0;
  }
  .thoughts-sort-options-position-container {
    position: relative;
    top: 0;
    left: 0;

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media (max-width: 560px) {
    .thoughts_sort {
      width: 120px;
      * {
        min-width: unset;
      }
    }
  }

  @media (max-width: 480px) {
    .thoughts-sort-options-position-container {
      display: block;
    }

    .thoughts_sort {
      width: 100%;

      * {
        min-width: unset;
      }
    }

    .checkbox-container {
      padding-left: 8px;
      width: 24px;
    }

    fieldset {
      width: 100%;

      select {
        width: 100%;
      }
    }
  }
`;
const ShowAdvancedThoughtFiltersWrapper = styled.div`
  padding: 16px 0 8px 0;

  * {
    color: ${({ theme }) => theme.themeColors.primaryCtaButton} !important;
    fill: ${({ theme }) => theme.themeColors.primaryCtaButton} !important;
  }

  svg {
    height: 18px;
    width: 18px;
    margin-right: 8px;
  }

  button {
    padding: 6px 16px;
  }
`;

const MobileThoughtFilterActions = ({ children }) => {
  const [show, setShow] = React.useState(false);

  const hc = () => {
    setShow(true);
  };

  if (!show) {
    return (
      <ShowAdvancedThoughtFiltersWrapper>
        <FlatButton transparent onClick={hc}>
          <Container row verticallyCenter>
            <Filter />
            Advanced Filters
          </Container>
        </FlatButton>
      </ShowAdvancedThoughtFiltersWrapper>
    );
  }

  return <MobileThoughtFilterActionsWrapper>{children}</MobileThoughtFilterActionsWrapper>;
};

const ShowCreateThoughtPanelButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px 16px 8px;
`;
const ShowCreateThoughtPanelButton = ({ thoughtType, handleClick }) => {
  return (
    <ShowCreateThoughtPanelButtonWrapper>
      <SkeletonButton fullWidth onClick={handleClick}>
        post a {thoughtType}
      </SkeletonButton>
    </ShowCreateThoughtPanelButtonWrapper>
  );
};

class ThoughtsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseThoughtList: window.innerWidth < 800,

      creatingThought: false,
      creatingThoughtType: null,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this._handleResize);

    if (this.props.fetchThoughtData) {
      this._fetchThoughtData();
    }

    const query = parseQueryString(this.props.location.search);
    if (query.creatingThought === 'true') {
      this.setState(() => ({
        creatingThought: true,
        creatingThoughtType: query.creatingThoughtType || 'pro',
      }));
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleResize);
  }

  render() {
    if (this._isLoading()) {
      return (
        <div className="react-thoughts-container clearfix">
          <PageLoading pageName={LoadingPageNames.thoughts} flatStyle={this.props.flatLoadingStyle} />
        </div>
      );
    }

    if (this._areNoThoughtsForSecurity()) {
      return (
        <div>
          <NoThoughtsComponent
            security={this.props.security}
            idea={this.props.idea}
            handleCreateThought={this.handleCreateThought}
            handleCancelCreatingThought={this.handleCancelCreatingThought}
            userIsCurrentUser={this.props.userId === this.props.currentUser.user_id}
            isUserAuthed={this._isUserAuthed()}
            showAsThoughtsInIdea={this._shouldShowAsThoughtsInIdea()}
          />
        </div>
      );
    }

    return (
      <div>
        {this._shouldRenderThoughtListAsSingleColumn() ? this._renderOneColLayout() : this._renderTwoColLayout()}
      </div>
    );
  }

  _returnDefaultSharedThoughtListProps = () => {
    return {
      security: this.props.security,
      user: this.props.user,
      showAsThoughtsInIdea: this._shouldShowAsThoughtsInIdea(),
      idea: this.props.idea,

      isTwoColumns: !this._shouldRenderThoughtListAsSingleColumn(),

      creatingThought: this._isCreatingThought(),
      creatingThoughtType: this._returnCreatingThoughtType(),

      handleCreatingThought: this.handleCreatingThought,
      handleCreateThought: this.handleCreateThought,
      handleCancelCreatingThought: this.handleCancelCreatingThought,
    };
  };

  _renderThoughtList = (thoughtType, config = {}) => {
    // thoughtType => 'pro' / 'con'
    return (
      <ThoughtsListWithHeading
        thoughtType={thoughtType}
        thoughts={this._returnDefaultSortedThoughtsFor(thoughtType)}
        securityId={this._returnSecurityId()}
        shouldShowOpenCreateThoughtPanel={this._shouldShowOpenCreateThoughtPanel(thoughtType)}
        handleOpenCreateThoughtPanelClick={this.bindHandleOpenCreateThoughtPanelClick(thoughtType)}
        viewingContext={this._returnContext()}
        thoughtDisplayContext={this.props.thoughtDisplayContext}
        distanceFromBottomToTriggerInfiniteScroll={this.props.distanceFromBottomToTriggerInfiniteScroll}
        scrollContainerSelector={this.props.scrollContainerSelector}
        useInfiniteScroll={this.props.useInfiniteScroll}
        {...this._returnDefaultSharedThoughtListProps()}
        {...config}
      >
        {this.props.sortOptionsComponent && config.showSortOptions && (
          <MobileThoughtFilterActions>{this.props.sortOptionsComponent}</MobileThoughtFilterActions>
        )}
        {config.isOneColumn && !this._isCreatingThoughtForType(thoughtType) && this._areThoughtsFor(thoughtType) && (
          <ShowCreateThoughtPanelButton
            thoughtType={thoughtType}
            handleClick={this.bindHandleOpenCreateThoughtPanelClick(thoughtType)}
          />
        )}
      </ThoughtsListWithHeading>
    );
  };

  _renderTwoColLayout = () => {
    return (
      <div className="react-thoughts-container clearfix">
        {this.props.sortOptionsComponent || null}
        <div className="left-panel">{this._renderProThoughtList()}</div>
        <div className="right-panel">{this._renderConThoughtList()}</div>
      </div>
    );
  };

  _renderOneColLayout = () => {
    const query = parseQueryString(this.props.location.search);
    const focusThoughtId = getFocusThoughtFromUrl(this.props.location);
    return (
      <div className="react-thoughts-container clearfix">
        <TabbedContainer
          defaultActiveTab={
            focusThoughtId
              ? this._returnDefaultSortedThoughtsFor('pro').filter((thought) => thought.id === focusThoughtId).length >
                0
                ? 'pro'
                : 'con'
              : query.creatingThoughtType === 'con'
              ? 'con'
              : this._areProThoughts()
              ? 'pro'
              : 'con'
          }
          forceTab={
            focusThoughtId
              ? this._returnDefaultSortedThoughtsFor('pro').filter((thought) => thought.id === focusThoughtId).length >
                0
                ? 'pro'
                : 'con'
              : null
          }
          handleTabClickAdditionalAction={this.handleTabClickAdditionalAction}
          tabs={[
            {
              name: 'pro',
            },
            {
              name: 'con',
            },
          ]}
          contentComponents={[
            this._renderProThoughtList({
              hideHeading: true,
              showSortOptions: true,
              isOneColumn: true,
            }),
            this._renderConThoughtList({
              hideHeading: true,
              showSortOptions: true,
              isOneColumn: true,
            }),
          ]}
        />
      </div>
    );
  };

  _renderProThoughtList = (config) => this._renderThoughtList('pro', config);

  _renderConThoughtList = (config) => this._renderThoughtList('con', config);

  _shouldShowOpenCreateThoughtPanel = (thoughtType) => this._returnCreatingThoughtType() === thoughtType;

  _isCreatingThought = () => this.state.creatingThought;

  _returnCreatingThoughtType = () => this.state.creatingThoughtType;

  _isCreatingThoughtForType = (thoughtType) =>
    this._isCreatingThought() && this._returnCreatingThoughtType() === thoughtType;

  _handleCreateProClick = () => {
    this.handleCreatingThought('pro');
    this._logCreateThoughtClick('pro');
  };

  _handleCreateConClick = () => {
    this.handleCreatingThought('con');
    this._logCreateThoughtClick('con');
  };

  bindHandleOpenCreateThoughtPanelClick = (thoughtType) => () => this.handleOpenCreateThoughtPanelClick(thoughtType);

  handleOpenCreateThoughtPanelClick = (thoughtType) =>
    thoughtType === 'pro' ? this._handleCreateProClick() : this._handleCreateConClick();

  handleTabClickAdditionalAction = (tabName) => {
    const thoughtType = tabName;
    if (this._isCreatingThought()) {
      this.setState(() => ({
        creatingThoughtType: thoughtType,
      }));
    }
    this._logThoughtTabClick(tabName);
    this._logItlyTabClick(tabName);
  };

  handleCreatingThought = (thoughtType) => {
    this.setState((prevState) => ({
      creatingThought: true,
      creatingThoughtType: thoughtType,
    }));
  };

  handleCreateThought = (thought) => {
    const security = this.props.security;
    const formattedThought = {
      security_id: security.security_id,
      ...thought,
    };
    return this.props.actions.createThought(formattedThought).then((response) => {
      if (!response || response.error) {
        this._showCreateThoughtErrorModal(response);
      } else {
        this._logThoughtCreate(thought, security);
        if (this.props.idea) {
          const ideaIsCurrentUsersIdea = this.props.idea.user_id === this.props.currentUser.user_id;
          if (this._shouldShowAsThoughtsInIdea() && !ideaIsCurrentUsersIdea) {
            switchToTab('thoughts', this.props.navigate);
          }
        }
      }
      return response;
    });
  };

  handleCancelCreatingThought = () => {
    this.setState((prevState) => ({
      creatingThought: false,
      creatingThoughtType: null,
    }));
  };

  _fetchThoughtData = () => {
    this.props.actions.fetchThoughts([this._returnSecurityId()]);
  };

  _returnSecurityId = () => this.props.security_id;

  _returnIdea = () => this.props.idea;

  _shouldShowAsThoughtsInIdea = () => this.props.showAsThoughtsInIdea;

  _returnCurrentUser = () => this.props.currentUser;

  _isUserAuthed = () => 'user_id' in this._returnCurrentUser();

  _isLoading = (props) => (props || this.props).isLoading;

  _areThoughtsFor = (thoughtType) => (thoughtType === 'pro' ? this._areProThoughts() : this._areConThoughts());

  _areProThoughts = () => this.props.areProThoughts;

  _areConThoughts = () => this.props.areConThoughts;

  _areNoThoughtsForSecurity = () => this.props.areNoThoughtsForSecurity;

  _shouldRenderThoughtListAsSingleColumn = () => this.state.collapseThoughtList || this.props.lockSingleColumnLayout;

  _returnDefaultSortedThoughtsFor = (thoughtType) =>
    thoughtType === 'pro' ? this.props.sortedPros : this.props.sortedCons;

  _handleResize = () => {
    if (window.innerWidth < 800 && !this.state.collapseThoughtList) {
      this.setState({
        collapseThoughtList: true,
      });
    } else if (window.innerWidth >= 800 && this.state.collapseThoughtList) {
      this.setState({
        collapseThoughtList: false,
      });
    }
  };

  _showCreateThoughtErrorModal = (response) => {
    const generalComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {`Could not save thought. Please try again later.`}
      </div>
    );
    const unfeaturedMsgComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {`Cannot create a new thought for this security.  Security is currently unavailable.`}
      </div>
    );
    const modal = {
      contentComponent:
        response.thoughtsWithErrors &&
        response.thoughtsWithErrors[0].error === "Can't create a thought for an un-featured security."
          ? unfeaturedMsgComponent
          : generalComponent,
      dismissable: true,
    };
    this.props.actions.showModal(modal);
  };

  _returnThoughtContextEventName = () =>
    this._shouldShowAsThoughtsInIdea()
      ? getThoughtImpressionEventName('SECURITY_PANEL_IDEA_TAB')
      : getThoughtImpressionEventName('SECURITY_PANEL_PRO_CON_TAB');

  _returnContext = () => this.props.viewingContext || this._returnThoughtContextEventName();

  _logThoughtTabClick = (tab) => {
    const event = 'Clicked Thoughts Tab';
    const properties = {
      Tab: tab,
      Context: 'Thoughts List',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logItlyTabClick = (tabName) => {
    const { elementPosition } = this.context;
    const properties = {
      context: location.pathname,
      position: elementPosition || enums.node_location.body,
      platform: enums.platform.web,
      description: tabName,
      url: window.location.pathname,
      url_query: window.location.search,
    };
    TrackIteratively.generic.TAB_VIEWED.send(properties);
  };

  _logThoughtCreate = (thought, security) => {
    const properties = {
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
      'Thought Type': thought.thought_type.name,
      Context: this._returnContext(),
    };
    TrackingEvents.thoughts.ADD_THOUGHT.send(properties);
  };

  _logCreateThoughtClick = (thoughtType) => {
    const event = 'Add Thought Panel Opened';
    const properties = {
      'Security ID': this._returnSecurityId(),
      Context: this._returnThoughtContextEventName(),
      'Concernable Type': this._shouldShowAsThoughtsInIdea() ? 'Idea' : 'Security',
      'Concernable Id': this._shouldShowAsThoughtsInIdea() ? this.props.idea.idea_id : this._returnSecurityId(),
      'Context Idea User': this.props.user ? this.props.user.analytics_id : null,
      'Is Reply': 'N/A', //TODO: add support for getting this out of URL param
      'Thought Type': toCapitalCase(thoughtType),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

ThoughtsContainer.contextType = ElementPositionContext;

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ThoughtsContainer);
export default composedComponent;
