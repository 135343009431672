import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../../../actions/index';

import LinkTo from '../../../Links/LinkTo';
import NumberWithChangeIndicator from '../../../../components/UI/NumberWithChangeIndicator';

import { truncateName, snakeToHyphens, isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import { findUserIdeaForSecurity } from '../../../../helpers/ideaHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';

export class IdeaDayAndTotalChangeCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? (
          this.props.loadingCellComponent
        ) : (
          <LinkTo
            to={this._returnLinkForHandlingClick()}
            className={''}
            additionalClickAction={this.props.logSecurityPanelOpenClick}
            child={this._renderCellContent()}
            noTextDecoration
          />
        )}
      </td>
    );
  }

  _isLoading = () => {
    const securityId = this.props.security.security_id;
    const security = this.props.securities.lookup[securityId];
    return !security || !('name' in security);
  };

  _returnLinkForHandlingClick = () => {
    const { userId, securityId } = this.props;
    const currentUserId = this.props.currentUser.user_id;
    const ideaUserId = userId || currentUserId;

    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    const ideaId = idea && idea.idea_id;

    const additionalProps = {
      activeTab: 'ideas',
      ideaId,
    };
    const openSecurityPanelConfig = {
      securityId,
      additionalProps,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    // doesn't check for itself in positions store for loading because the rendering of the table is assuming the data is there to start populating rows
    const securityId = this.props.securityId;
    const positionData = this.props.portfolio.positions[securityId] || {};
    const allocationShares = positionData.shares || 0;

    const todaysProfitChange = positionData.today_profit_loss;
    const todaysProfitChangePercent = positionData.today_profit_loss_percent;

    const totalPriceChange = positionData.total_profit_loss;
    const totalPriceChangePercent = positionData.total_profit_loss_percent;

    return (
      <span className={`security-table-idea-day-and-total-change-container`}>
        {allocationShares !== 0 && (
          <span className={`security-table-total-price-change-container`}>
            <span className={`daily-change-container multi-text-box-inline`}>
              <span className={`text-label`}>Daily</span>

              <span className={`security-table-total-price-change-wrapper`}>
                <span className={`security-table-total-price-change`}>
                  <NumberWithChangeIndicator
                    customClass={'portfolio-dashboard change-value daily-change-value'}
                    valueFormat={'price'}
                    value={todaysProfitChange}
                    showPlus={true}
                    coloredText={true}
                    hideIcon={true}
                  />
                </span>
                <span className={`security-table-total-price-change-percent`}>
                  <NumberWithChangeIndicator
                    customClass={'portfolio-dashboard daily-change-percentage-value'}
                    valueFormat={'percentageFloat'}
                    value={todaysProfitChangePercent}
                    showPlus={true}
                    coloredText={true}
                    hideIcon={true}
                  />
                </span>
              </span>
            </span>
          </span>
        )}
        {allocationShares !== 0 && (
          <span className={`security-table-total-price-change-container`}>
            <span className={`multi-text-box-inline`}>
              <span className={`text-label`}>Total</span>

              <span className={`security-table-total-price-change-wrapper`}>
                <span className={`security-table-total-price-change`}>
                  <NumberWithChangeIndicator
                    customClass={'portfolio-dashboard change-value total-change-value'}
                    valueFormat={'price'}
                    value={totalPriceChange}
                    showPlus={true}
                    coloredText={true}
                    hideIcon={true}
                  />
                </span>
                <span className={`security-table-total-price-change-percent`}>
                  <NumberWithChangeIndicator
                    customClass={'portfolio-dashboard'}
                    valueFormat={'percentageFloat'}
                    value={totalPriceChangePercent}
                    showPlus={true}
                    coloredText={true}
                    hideIcon={true}
                  />
                </span>
              </span>
            </span>
          </span>
        )}
      </span>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    ideas: state.ideas,
    portfolio: state.portfolio,
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(IdeaDayAndTotalChangeCell);

export default composedComponent;
