import React from 'react';
import { connect } from 'react-redux';
import { returnPathTo } from '../../../../constants/paths';
import { restartTour, logMetricsTrackingEvent } from '../../../../actions';
import VerticalNavItem from '../../components/VerticalNavItem';
import { isEndOfTour } from '../../../../constants/tour';
import TourIcon from '@src/main/components/Images/TourIcon';
import { withRouter } from '../../../../main/utils';
import { useLocation } from 'react-router';

const Tour = (props) => {
  const { pathname } = useLocation();

  const {
    currentUserId,

    isOnTour,
    tourStep,

    dispatch,
    navigate,
  } = props;

  const logStartTourClick = () => {
    const event = 'Clicked Start Tour';
    const properties = {};
    logMetricsTrackingEvent(event, properties)();
  };

  const handleTourStartClick = () => {
    logStartTourClick();
    restartTour(currentUserId)(dispatch);
    navigate(returnPathTo('dashboard'));
  };

  const showTourRouteButton = (!isOnTour || isEndOfTour(tourStep)) && pathname === '/';

  return (
    showTourRouteButton && (
      <VerticalNavItem
        name="Take A Tour"
        customIcon={<TourIcon />}
        activeRoute={null}
        route={false}
        additionalClickAction={handleTourStartClick}
        highlightOnTour={{
          isEndOfTourHighlight: true,
        }}
        noCollapse
      />
    )
  );
};

const mapStateToProps = (state) => {
  const tourStore = state.tour;
  return {
    currentUserId: state.currentUser.user_id,
    tourStep: tourStore.tour_step,
    isOnTour: tourStore.is_on_tour,
  };
};

export default withRouter(connect(mapStateToProps)(Tour));
