const currentIdeaTerminologyMode = 'F';

export const TerminologyModes = {
  A: {
    noun: 'Idea',
    view: 'View Idea',
    add: 'Add Idea',
    edit: 'Edit Idea',
    tab: 'Ideas',
    adding: 'Adding Idea',
    updating: 'Updating Idea',
    first: 'No ideas exist among your connections or famous investors. Be the first to add an idea!',
    'first-unfeatured': 'No ideas exist among your connections or famous investors.',
    comma_list: 'ideas',
    already_have: 'You already have an idea for this.',
    add_error: 'Unable to add idea.',
    update_error: 'Unable to update idea.',
    remove_error: 'Unable to remove idea.',
    must_exit: 'You must exit your position before you can remove this idea.',
    swipe_cards: 'Swipe stock cards down to add ideas.',
    drag: 'Drag and drop to add ideas.',
    done: 'When finished exploring, click continue to add ideas for these stocks.',
    replace: 'You already have an idea for this stock, would you like to overwrite it?',
    table_title: 'My Ideas',
  },
  B: {
    noun: 'Projection',
    view: 'View Projection',
    add: 'Add to Watchlist',
    edit: 'Edit Projection',
    tab: 'Projections',
    adding: 'Adding to Watchlist',
    updating: 'Updating Projection',
    first:
      'No projections exist among your connections or famous investors. Be the first to add to your watchlist and create a projection!',
    'first-unfeatured': 'No projections exist among your connections or famous investors.',
    comma_list: 'watchlist, projections',
    already_have: 'You already have this saved to your watchlist.',
    add_error: 'Unable to add to watchlist.',
    update_error: 'Unable to update projections.',
    remove_error: 'Unable to remove from watchlist.',
    must_exit: 'You must exit your position before you can remove this from your watchlist.',
    swipe_cards: 'Swipe stock cards down to add to your watchlist.',
    drag: 'Drag and drop securities into your portfolio watchlist.',
    done: 'When finished exploring, click continue to add these stocks to your watchlist.',
    replace: 'You already have a projection for this stock, would you like to overwrite it?',
    table_title: 'My Watchlist',
  },
  C: {
    noun: 'Idea',
    view: 'View My Idea',
    add: 'Add to My Stocks',
    edit: 'Edit My Idea',
    tab: 'Ideas',
    adding: 'Adding to My Stocks',
    updating: 'Updating Idea',
    first: 'No ideas exist among your connections or famous investors. Be the first to add this and create an idea!',
    'first-unfeatured': 'No ideas exist among your connections or famous investors.',
    comma_list: 'stock list, ideas',
    already_have: 'You already have this in your stocks.',
    add_error: 'Unable to add idea.',
    update_error: 'Unable to update idea.',
    remove_error: 'Unable to remove idea.',
    must_exit: 'You must exit your position before you can remove this idea.',
    swipe_cards: 'Swipe cards down to add to your stocks.',
    drag: 'Drag and drop to add to your stocks.',
    done: 'When finished exploring, click continue to add these to your stocks.',
    replace: 'You already have an idea for this stock, would you like to overwrite it?',
    table_title: 'My Stocks',
  },
  D: {
    noun: 'Idea',
    view: 'View My Idea',
    add: 'Follow',
    edit: 'Edit My Idea',
    tab: 'Followed By',
    adding: 'Following',
    updating: 'Updating Idea',
    first: 'No ideas exist among your connections or famous investors. Be the first to follow this!',
    'first-unfeatured': 'No ideas exist among your connections or famous investors.',
    comma_list: 'followed stocks, ideas',
    already_have: 'You are already following this.',
    add_error: 'Unable to follow this.',
    update_error: 'Unable to update idea.',
    remove_error: 'Unable to unfollow.',
    must_exit: 'You must exit your position before you can unfollow this stock.',
    swipe_cards: 'Swipe stock cards down to follow them.',
    drag: 'Drag and drop to follow stocks.',
    done: 'When finished exploring, click continue to follow these stocks.',
    replace: 'You are already following this stock, would you like to overwrite your idea?',
    table_title: 'My Followed Stocks',
  },
  E: {
    noun: 'Idea',
    view: 'View My Idea',
    add: 'Save',
    edit: 'Edit My Idea',
    tab: 'Saved By',
    adding: 'Saving',
    updating: 'Updating Idea',
    first: 'No ideas exist among your connections or famous investors. Be the first to save this!',
    'first-unfeatured': 'No ideas exist among your connections or famous investors.',
    comma_list: 'saved stocks, ideas',
    already_have: 'You already saved this.',
    add_error: 'Unable to save idea.',
    update_error: 'Unable to update idea.',
    remove_error: 'Unable to remove idea.',
    must_exit: 'You must exit your position before you can remove this idea.',
    swipe_cards: 'Swipe stock cards down to save them.',
    drag: 'Drag and drop to save stocks.',
    done: 'When finished exploring, click continue to save these stocks.',
    replace: 'You already saved this stock, would you like to overwrite your idea?',
    table_title: 'My Ideas',
  },
  F: {
    noun: 'Idea',
    view: 'View My Idea',
    add: 'Save to My Ideas',
    edit: 'Edit My Idea',
    tab: "Connections' Ideas",
    adding: 'Saving to My Ideas',
    updating: 'Updating Idea',
    first: 'No ideas exist among your connections or famous investors. Be the first to create an idea!',
    'first-unfeatured': 'No ideas exist among your connections or famous investors.',
    comma_list: 'stocks, ideas',
    already_have: 'You already have this stock in your ideas.',
    add_error: 'Unable to save the idea.',
    update_error: 'Your changes could not be saved.',
    remove_error: 'Unable to remove this idea.',
    must_exit: 'You must exit your position before you can remove this idea.',
    swipe_cards: 'Swipe cards down to save a stock to your ideas.',
    drag: 'Drag and drop to save a stock to your ideas.',
    done: 'When finished exploring, click continue to save these stocks to your ideas.',
    replace: 'You already have an idea for this stock, would you like to overwrite it?',
    table_title: 'My Ideas',
  },
};

export const getIdeaPhraseFor = (phraseType) => {
  return TerminologyModes[currentIdeaTerminologyMode || 'F'][phraseType];
};

// Support no longer needed
// export const bindTerminologyHandler = function () {
//   if (window.origin !== 'https://www.nvstr.com') {
//     const keys = {
//       97: 'A',
//       98: 'B',
//       99: 'C',
//       100: 'D',
//       101: 'E',
//       102: 'F',
//     };
//     let queue = [];

//     $(document).on('keypress', (e) => {
//       let key = e.which;
//       if (key === 61) {
//         queue.push(key);
//       } else if (!keys[key]) {
//         queue = [];
//       }
//       if (queue[0] === 61 && queue[1] === 61 && keys[key]) {
//         console.warn('setting window mode: ' + keys[key]);
//         currentIdeaTerminologyMode = keys[key];
//         this._unsafe__forceUpdateHandler();
//       }
//     });
//   }
// };
