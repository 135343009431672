import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../../../actions/index';

import LinkTo from '../../../Links/LinkTo';

import { snakeToHyphens, isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import {
  isIdeaUsingPriceTarget,
  calculateExpectedReturn,
  findUserIdeaForSecurity,
} from '../../../../helpers/ideaHelpers';
import { formatSecurityDataValue } from '../../../../helpers/securitiesHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';

export class ExpectedReturnCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? (
          this.props.loadingCellComponent
        ) : (
          <LinkTo
            to={this._returnLinkForHandlingClick()}
            className={''}
            additionalClickAction={this.props.logSecurityPanelOpenClick}
            child={this._renderCellContent()}
            noTextDecoration
          />
        )}
      </td>
    );
  }

  _isLoading = () => {
    const { securityId } = this.props;
    const security = this.props.securities.lookup[securityId];

    return !security || !this.props.idea || this._isPriceLoading();
  };

  _returnIdea = () => {
    const { userId, securityId } = this.props;
    const ideaUserId = userId || this.props.currentUser.user_id;
    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    return idea;
  };

  _returnLinkForHandlingClick = () => {
    const { securityId } = this.props;
    const idea = this._returnIdea();
    const ideaId = idea && idea.idea_id;

    const additionalProps = {
      activeTab: 'ideas',
      ideaId,
    };
    const openSecurityPanelConfig = {
      securityId,
      additionalProps,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    const { userId, securityId, currentUser } = this.props;
    const ideaUserId = userId || currentUser.user_id;
    const security = this.props.securities.lookup[securityId];
    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    const currentPrice = this._returnCurrentPriceData();
    const expectedReturn = calculateExpectedReturn({
      idea,
      security,
      currentPrice,
    });
    const ideaUsingPriceTarget = isIdeaUsingPriceTarget(idea);

    return (
      <span className={`security-table-expected-return`}>
        <span className={`security-table-expected-return-percentage`}>
          {formatSecurityDataValue(expectedReturn, 'expected_return')}
        </span>
        {ideaUsingPriceTarget && (
          <span className={`security-table-price-target`}>
            {formatSecurityDataValue(idea.price_target, 'price_target')}
          </span>
        )}
      </span>
    );
  };

  _isAPreviousValueAvailable = () => !isUndefinedOrNull(this._returnCurrentPrice());
  _isPriceLoading = () =>
    (!this._returnCurrentPriceData() || this._returnCurrentPriceData().loading) && !this._isAPreviousValueAvailable();
  _returnCurrentPriceData = () => this.props.securitiesPrice.securities[this.props.securityId];
  _returnCurrentPrice = () => (this._returnCurrentPriceData() ? this._returnCurrentPriceData().current_price : {});
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    ideas: state.ideas,
    securities: state.securities,
    securitiesPrice: state.securitiesPrice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(ExpectedReturnCell);

export default composedComponent;
