import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withWindowSize from '../../HOCS/withWindowSize';

import * as Actions from '../../../actions';

import PageLoading from '../../../components/PageLoading';
import UserListItem from './components/UserListItem';

export class UserList extends Component {
  render() {
    if (this._wasError()) {
      return this._renderErrorMessage();
    }

    if (this._isLoading()) {
      return this._renderLoading();
    }

    if (this._areNoUsersInList()) {
      return this._renderNoUsersInList();
    }

    return this._renderUserList();
  }

  _renderUserList = () => {
    return (
      <div className={`connections-list-container`}>
        {this._returnUserList().map((user) => (
          <UserListItem
            key={`user-list-user-${user.user_id}`}
            user={user}
            isCurrentUser={this.props.currentUser.user_id === user.user_id}
            viewingContext={this.props.viewingContext}
          />
        ))}
      </div>
    );
  };

  _renderLoading = () => <PageLoading />;

  _renderErrorMessage = () => {
    return <div className={``}>An error occurred, try reloading the page.</div>;
  };

  _renderNoUsersInList = () => {
    // Currently only way to see this is by viewing own profile
    return <div className={`no-users-in-list`}>{this.props.renderNoUsers()}</div>;
  };

  _isLoading = () => this.props.isLoading;

  _wasError = () => this.props.isErrorGettingData;

  _areNoUsersInList = () => !this._isLoading() && this._returnUserList().length === 0;

  _returnUserList = () => this.props.userList;
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(withWindowSize, connect(mapStateToProps, mapDispatchToProps))(UserList);

export const withWindowProfileContainer = withWindowSize(UserList);
export default composedComponent;
