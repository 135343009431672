import React from 'react';
import { moment } from '../../../helpers/timeHelpers';

const TransactionDateHeading = (props) => {
  const { date } = props;
  const Date = moment(date, 'YYYY-MM-DD');
  const dateDisplayString = Date.format('MMMM Do');
  return (
    <div className={`transactions-group-date-heading-container secondary-text-color`}>
      <h5>{dateDisplayString}</h5>
    </div>
  );
};

export default TransactionDateHeading;
