import React from 'react';
import Order from './Order';
import OrdersPlacedListHeading from './components/OrdersPlacedListHeading';
import { createTimeInstance } from '@src/helpers/timeHelpers';
import { useOrder } from '@src/main/hooks/orders/useOrder';

const OrderPassThru = (props) => {
  const order = useOrder(props.id);
  return <Order order={order} {...props} />;
};

const OrdersPlacedToday = (props) => {
  const {
    isMobile,
    windowWidth,

    isRefreshing,
    showRefreshButton,
    handleRefresh,

    ordersPlacedTodayList,

    refreshUserData,
  } = props;

  const noOrdersPlacedToday = !ordersPlacedTodayList || !ordersPlacedTodayList.length > 0;
  if (noOrdersPlacedToday) {
    return null;
  }

  const ordersList = ordersPlacedTodayList.sort((a, b) => {
    const aExecutedAt = a?.last_updated_at ? createTimeInstance(a?.last_updated_at) : createTimeInstance();
    const bExecutedAt = b?.last_updated_at ? createTimeInstance(b?.last_updated_at) : createTimeInstance();

    return bExecutedAt.isBefore(aExecutedAt) ? -1 : aExecutedAt.isBefore(bExecutedAt) ? 1 : 0;
  });

  return (
    <div className={`orders-placed-container`}>
      <div className={`header`}>
        <h2 className="heading  ">Orders Placed Today</h2>
      </div>

      <OrdersPlacedListHeading
        isMobile={isMobile}
        windowWidth={windowWidth}
        isRefreshing={isRefreshing}
        showRefreshButton={showRefreshButton}
        handleRefresh={handleRefresh}
      />

      <div className={`orders-placed-list`}>
        {ordersList.map((order) => (
          <OrderPassThru
            key={`order-${order.id}`}
            id={order.id}
            refreshUserData={refreshUserData}
            windowWidth={windowWidth}
            allowCancel
          />
        ))}
      </div>
    </div>
  );
};

export default OrdersPlacedToday;
