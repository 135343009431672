import { userMutualConnectionsAdapter } from '../adapters/userMutualConnectionsAdapter';

import { LOADING_USER_MUTUAL_CONNECTIONS, ADD_USER_MUTUAL_CONNECTIONS, ADD_USERS } from '../constants';

export const fetchMutualConnections = (userIds) => {
  if (!userIds) {
    console.error('userIds are required');
    return null;
  }

  return function (dispatch) {
    dispatch({
      type: LOADING_USER_MUTUAL_CONNECTIONS,
      payload: {
        userIds: Array.isArray(userIds) ? userIds : [userIds],
      },
    });

    const userIdsCsv = Array.isArray(userIds) ? userIds.join(',') : userIds;
    return userMutualConnectionsAdapter.fetchMutualConnections(userIdsCsv).then((response) => {
      if (response && response.data && 'users' in response.data) {
        const users = response.data.users;
        const serializeUsersData = users.map((user) => ({
          user_id: user.user_id,
          mutual_connections: user.mutual_connections ? user.mutual_connections.map((user) => user.user_id) : [],
        }));

        dispatch({
          type: ADD_USERS,
          payload: { users },
        });
        dispatch({
          type: ADD_USER_MUTUAL_CONNECTIONS,
          payload: { users: serializeUsersData },
        });
      } else {
        console.error('Error fetching mutual connections');
      }
      return response;
    });
  };
};
