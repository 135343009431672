import React from 'react';

import SecuritiesFilterPropertiesList from './SecuritiesFilterPropertiesList';
import CreateFilterProperties from './CreateFilterProperties';

import IconButton from '../../buttons/IconButton';

import { createInputComponent } from '../../../helpers/formHelpers';
import { getFilterCreateBodyType } from '../../../helpers/portfolioExplorerHelpers';

const NewComponent = (props) => {
  const filterNameSelected = !!props.filterName;
  const filterName = props.filterName;

  const renderFilterHeader = () => {
    const renderSecuritiesFilterName = () => {
      return (
        <div className={`securities-filter-name-container ${filterName.length > 20 && 'securities-filter-name-small'}`}>
          {filterName}
          <IconButton
            customClass="no-shadow"
            style={{
              position: 'absolute',
              top: '5px',
              right: '3px',
              height: '20px',
              width: '20px',
            }}
            icon="fa-times-thin"
            size="medium"
            colorClassName="secondary-text-color"
            handleClick={props.cancelCreateFilter}
          />
        </div>
      );
    };

    const renderSecuritiesFilterNameInput = () => {
      const input = props.inputProps.inputs[0];
      return (
        <div>
          <div
            className="securities-filter-name-container"
            ref={(inputEl) => props.inputProps.createInputRef(input.name, inputEl)}
          >
            {createInputComponent(input, props.inputProps.returnInputDefaultProps.call(this), input.name)}
          </div>
          <div className="securities-filter-close-button-container">
            <IconButton
              customClass="no-shadow main-bg-color-stroke-match"
              style={{ position: 'absolute', top: '10px', right: '3px' }}
              icon="fa fa-times fa-times-thin"
              size="medium"
              colorClassName="secondary-text-color"
              handleClick={props.cancelCreateFilter}
            />
          </div>
        </div>
      );
    };

    return (
      <div className="securities-filter-container securities-filter-container-create main-bg-color-match border-accent">
        {filterNameSelected ? renderSecuritiesFilterName() : renderSecuritiesFilterNameInput()}
      </div>
    );
  };

  const renderCreateFilterBody = () => {
    return filterNameSelected ? (
      <CreateFilterProperties
        filter={props.filter}
        handleCreateFilter={props.handleCreateFilter}
        userEditing={props.userEditing}
        cancelCreateFilter={props.cancelCreateFilter}
        removeFilter={props.removeFilter}
        handleUpdateFilter={props.handleUpdateFilter}
        inputProps={props.inputProps}
        formType={getFilterCreateBodyType(filterName)}
      />
    ) : (
      <SecuritiesFilterPropertiesList
        filterNameQuery={props.filterNameQuery}
        inputProps={props.inputProps}
        handleFilterNameSelection={props.handleFilterNameSelection}
      />
    );
  };
  return (
    <div className="securities-filter-wrapper card-shadow-styling" key="filter-null">
      {renderFilterHeader()}
      <div className="securities-filter-list-container main-bg-color-match selectable-dropdown-list-color-styling border-accent">
        {renderCreateFilterBody()}
      </div>
    </div>
  );
};

export default NewComponent;
