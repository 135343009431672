import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { logMetricsTrackingEvent, removeIdea } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import { Body3, H5, Body5, Delete } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { useCurrentIdeas } from '../../hooks/user';
import UnstyledLoadingSpinner from '../../components/Images/UnstyledLoadingSpinner';
import AddToPortfolio from '../../../containers/Portfolio/AddToPortfolio';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const WatchListWrapper = styled.div`
  padding-top: 0;
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Actions = styled.div`
  padding-top: 80px;
  text-align: center;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 16px;

  border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
`;
const IdentifierCell = styled.div`
  padding: 0 16px 0 0;
`;
const NameWrapper = styled.div`
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const RemoveCell = styled.div`
  .sc-button {
    transition: all 300ms;

    height: 40px;
    width: 40px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: ${({ theme }) => theme.themeColors.text};

      svg {
        height: 20px;
        width: 20px;

        path {
          fill: ${({ theme }) => theme.themeColors.appBackground};
        }
      }
    }
  }

  svg {
    height: 20px;
    width: 20px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const DeletingState = styled.div`
  height: 40px;
  width: 40px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const AddStocksWrapper = styled.div`
  padding: 16px 0;

  .default-search-container,
  .add-to-portfolio-container {
    width: 100% !important;
    max-width: unset;
  }
  #add-to-portfolio-search_container {
    width: 100% !important;
    max-width: unset;
  }
  input {
    background: transparent !important;
  }
`;

const RemoveIdeaAction = ({ idea }) => {
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = React.useState(false);

  const onDelete = async () => {
    const response = await removeIdea(idea)(dispatch);
    if (response?.status === 'deleted') {
      // do nothing
    } else {
      setIsDeleting(false);
    }
  };
  const handleDelete = async () => {
    setIsDeleting(true);
    await onDelete();
  };
  return (
    <RemoveCell>
      {isDeleting ? (
        <DeletingState>
          <UnstyledLoadingSpinner />
        </DeletingState>
      ) : (
        <div className={'sc-button'} onClick={handleDelete}>
          <Delete />
        </div>
      )}
    </RemoveCell>
  );
};

const WatchlistRow = ({ idea }) => {
  const { security } = idea;
  const { id: securityId, symbol, name } = security;
  return (
    <RowWrapper>
      <IdentifierCell>
        <div>
          <Body3>{symbol}</Body3>
        </div>
        <NameWrapper>
          <Body5>{name}</Body5>
        </NameWrapper>
      </IdentifierCell>
      <RemoveIdeaAction idea={idea} />
    </RowWrapper>
  );
};

const WatchlistRows = ({ ideaLookup }) => {
  const ideaList = Object.keys(ideaLookup).map((id) => ideaLookup[id]);
  return (
    <>
      {ideaList.map((i) => (
        <WatchlistRow key={i.id} idea={i} />
      ))}
    </>
  );
};

export const EditWatchlist = ({ onContinue, onboardingState }) => {
  const currentIdeas = useCurrentIdeas();

  const [isReady, setIsReady] = React.useState(true);

  const [formError, setFormError] = React.useState(null);

  const handleContinueClick = React.useCallback(async () => {
    onContinue(null, onboardingState);
  }, []);

  React.useEffect(() => {
    logMetricsTrackingEvent('View Watchlist Onboarding')();
  }, []);

  if (!isReady) return <LoadingPage />;

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.interestsWatchlist}>
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>Here is your starting watchlist</H5>
          </Heading>
          <div style={{ paddingTop: '8px' }}>
            <Body5 isSmall>(Add more stocks or remove any for the list)</Body5>
            <div style={{ paddingTop: '8px' }}>
              <Body5 isSmall>You can view stock details and community content later in the dashboard</Body5>
            </div>
          </div>

          <AddStocksWrapper>
            <AddToPortfolio
              icon={'fa-plus'}
              placeholder="Add Stocks and ETFs To Your Watchlist"
              shouldOpenSecurityPanelOnSelection={false}
            />
          </AddStocksWrapper>
          <WatchListWrapper>
            <WatchlistRows ideaLookup={currentIdeas} />
          </WatchListWrapper>

          <Actions>
            <FormState error={formError} isSubmitting={false} />
            <Container fullWidth centerAll>
              <FlatButton fullWidth onClick={handleContinueClick}>
                Continue
              </FlatButton>
            </Container>
          </Actions>
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
