import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

class SecurityDataGetter extends React.Component {
  componentDidMount() {
    this._getSecurityData();
  }

  render() {
    return null;
  }

  _getSecurityData = () => {
    if (this.props.getBasicSecurityData) {
      this._getBasicSecurityData();
    }
  };

  _getBasicSecurityData = () => {
    this.props.actions.quickFetchSecuritiesData(this.returnSecurityIdsList());
  };

  returnSecurityIdsList = () => this.props.securityIds;
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(SecurityDataGetter);
