import React from 'react';
import { ELEMENT_SIZES } from '../../constants';

const useWindowSize = (config = {}) => {
  const { minusHeaderNavbar } = config;
  const [height, setHeight] = React.useState(window?.innerHeight);
  const [width, setWidth] = React.useState(window?.innerWidth);

  const setHeightIfChanged = React.useCallback(
    (currentHeight) => {
      const selectedHeight = minusHeaderNavbar ? currentHeight - ELEMENT_SIZES.headerNavbar : currentHeight;
      if (height !== selectedHeight) {
        setHeight(selectedHeight);
      }
    },
    [height]
  );

  const setWidthIfChanged = React.useCallback(
    (currentWidth) => {
      if (width !== currentWidth) {
        setWidth(currentWidth);
      }
    },
    [width]
  );

  React.useEffect(() => {
    const handleResize = () => {
      setHeightIfChanged(window?.innerHeight);
      setWidthIfChanged(window?.innerWidth);
    };

    handleResize();

    window.$(window).on('resize', handleResize);
    return () => window.$(window).off('resize', handleResize);
  }, []);

  return { height, width };
};

export default useWindowSize;
