import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/index';
import StatusModal from '../../components/modals/StatusModal';
import Icon from '../../components/misc/Icon';
import FundingBonusBannerRenderer from '../../components/banners/FundingBonusBannerRenderer';
import {
  canResubscribeToViewpointOrg,
  getDisplayNameFromViewpointOrg,
  getViewpointMonthlyFeeFromViewpointOrg,
  isSubscribedToViewpointOrg,
  isViewpointOrgSubscriptionFreeEligible,
  isViewpointOrgSubscribable,
} from '../../helpers/viewpointHelpers';
import { doesCurrentUserHaveFreeSubSlot, isCurrentUserLiveTrading } from '../../helpers/currentUserHelpers';
import { createAndShowConfirmationModal, createBasicErrorModal } from '../../constants/modals';
import { formatForDisplay } from '../../helpers/displayHelpers';
import { getViewpointOrgDataFromState } from '../../selectors/viewpoints';
import { possessiveize } from '../../helpers/generalHelpers';
import { logMetricsTrackingEvent } from '../../actions';
import { withRouter } from '../../main/utils';
import { FlatButton } from '../../main/components/buttons';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  button {
    padding: 2px;
  }
`;

export const VIEWPOINT_SUBSCRIBE_BUTTON_TYPES = {
  SOLID: 'solid',
  LINK: 'link',
};

class ViewpointSubscribeButton extends React.PureComponent {
  render() {
    const {
      viewpointOrg,

      buttonProps,
      buttonType,
    } = this.props;

    if (!viewpointOrg) return null;

    if (!isViewpointOrgSubscribable(viewpointOrg)) return null;

    if (canResubscribeToViewpointOrg(viewpointOrg))
      return (
        <ButtonWrapper>
          <FlatButton transparent onClick={this.handleClick}>
            + Resubscribe
          </FlatButton>
        </ButtonWrapper>
      );

    if (isSubscribedToViewpointOrg(viewpointOrg)) return null;

    return (
      <ButtonWrapper>
        <FlatButton transparent onClick={this.handleClick}>
          + Subscribe
        </FlatButton>
      </ButtonWrapper>
    );
  }

  handleClick = () => {
    const { viewpointOrg, isLiveTrading } = this.props;

    if (!isLiveTrading) {
      return this.handleShowStartLiveTrading();
    }

    if (canResubscribeToViewpointOrg(viewpointOrg)) {
      this.handleReSubscribe();
    } else {
      this.handleSubscribe();
    }
  };

  handleReSubscribe = () => {
    this.logReSubscribePress();

    this.showConfirmationModal();
  };

  handleSubscribe = () => {
    this.logSubscribePress();

    this.showConfirmationModal();
  };

  showConfirmationModal = () => {
    const { viewpointOrg, isFree } = this.props;

    const price = getViewpointMonthlyFeeFromViewpointOrg(viewpointOrg);
    const displayName = getDisplayNameFromViewpointOrg(viewpointOrg);

    const message = isFree
      ? `Get access to premium content from ${displayName} for free.`
      : `Get access to premium content from ${displayName} for ${formatForDisplay(price, 'price')} / month.`;

    const config = {
      message,
      handleContinue: this.handleConfirm,
      handleCancel: this.handleCancel,
      continueButtonText: 'Subscribe',
      cancelButtonText: 'Cancel',
      continueButtonClassName: 'btn btn-primary-color btn-small-tall btn-fixed-width-80',
    };
    createAndShowConfirmationModal.call(this, config);
  };

  handleConfirm = () => {
    this.props.actions.hideModal();

    const subscribingStatus = (
      <div>
        <StatusModal text={'Subscribing'} />
      </div>
    );
    const modal = {
      contentComponent: subscribingStatus,
      dismissable: false,
    };
    this.props.actions.showModal(modal);

    this._subscribeToViewpoint();

    this.logConfirmSubscribePress();
  };

  handleCancel = () => {
    this.props.actions.hideModal();

    this.logCancelConfirmSubscribePress();
  };

  handleShowStartLiveTrading = () => {
    this.logViewOpenLiveAccountForViewpointAccess();

    const message = 'Open a live trading account to get access to premium viewpoint contributor content.';

    const config = {
      message,
      secondaryComponent: (
        <div
          style={{
            paddingTop: '15px',
            paddingBottom: '10px',
          }}
        >
          <FundingBonusBannerRenderer />
        </div>
      ),
      handleContinue: this.handleConfirmStartLiveTrading,
      handleCancel: this.handleCancelStartLiveTrading,
      continueButtonText: 'Get Started',
      cancelButtonText: 'Cancel',
      continueButtonClassName: 'btn btn-primary-color btn-small-tall btn-fixed-width-80',
    };
    createAndShowConfirmationModal.call(this, config);
  };

  handleConfirmStartLiveTrading = () => {
    const { navigate } = this.props;

    this.props.actions.hideModal();

    navigate('/live_accounts/new');

    this.logConfirmLiveTradingStartPress();
  };

  handleCancelStartLiveTrading = () => {
    this.props.actions.hideModal();

    this.logCancelLiveTradingStartPress();
  };

  _subscribeToViewpoint = () => {
    const { id, isFree } = this.props;
    return this.props.actions.subscribeToViewpoint({ viewpointOrgId: id, isFree }).then((response) => {
      const { ok, errorMessage } = response;
      if (ok) {
        this.props.actions.hideModal();
        this.onCompleteSubscription();
      } else {
        const errorModal = {
          contentComponent: createBasicErrorModal(errorMessage || 'Something went wrong. Please try again.'),
          dismissable: true,
        };
        this.props.actions.showModal(errorModal);

        this.props.actions.fetchAllViewpointData();
      }
    });
  };

  onCompleteSubscription = () => {
    const { viewpointOrg } = this.props;
    const displayName = getDisplayNameFromViewpointOrg(viewpointOrg);

    const content = (
      <div className={'subscribe-modal-container'}>
        <div className={'text-center'}>
          <span className={'text-heading  '}>You are now subscribed</span>
        </div>
        <div
          className={'modal-success-icon-container text-center'}
          style={{
            paddingTop: '25px',
          }}
        >
          <Icon icon="fa-check-circle" colorClassName={'success-text-color'} />
        </div>
        <div
          className={'text-center'}
          style={{
            paddingTop: '25px',
          }}
        >
          <span className={' '}>
            {`You can view all of ${possessiveize(
              displayName
            )} premium content. You will also see updates in your feed as they post.`}
          </span>
        </div>
      </div>
    );
    const modal = {
      contentComponent: content,
      dismissable: true,
      size: 'wide',
    };
    this.props.actions.showModal(modal);
  };

  logReSubscribePress = () => {
    const eventName = 'Press Re-Subscribe To Viewpoint';
    const properties = {
      ID: this.props.id,
    };
    logMetricsTrackingEvent(eventName, properties)();
  };

  logSubscribePress = () => {
    const eventName = 'Press Subscribe To Viewpoint';
    const properties = {
      ID: this.props.id,
    };
    logMetricsTrackingEvent(eventName, properties)();
  };

  logConfirmSubscribePress = () => {
    const eventName = 'Press Confirm Subscribe To Viewpoint';
    const properties = {
      ID: this.props.id,
    };
    logMetricsTrackingEvent(eventName, properties)();
  };

  logCancelConfirmSubscribePress = () => {
    const eventName = 'Press Cancel Subscribe To Viewpoint';
    const properties = {
      ID: this.props.id,
    };
    logMetricsTrackingEvent(eventName, properties)();
  };

  logConfirmLiveTradingStartPress = () => {
    const eventName = 'Press Get Started Live Trading After Viewpoint Subscribe Press';
    const properties = {
      ID: this.props.id,
    };
    logMetricsTrackingEvent(eventName, properties)();
  };

  logCancelLiveTradingStartPress = () => {
    const eventName = 'Press Cancel Get Started Live Trading After Viewpoint Subscribe Press';
    const properties = {
      ID: this.props.id,
    };
    logMetricsTrackingEvent(eventName, properties)();
  };

  logViewOpenLiveAccountForViewpointAccess = () => {
    const eventName = 'View Open Live Trading Account After Viewpoint Subscribe Press';
    const properties = {
      ID: this.props.id,
    };
    logMetricsTrackingEvent(eventName, properties)();
  };
}

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const currentUser = state.currentUser;

  const viewpointOrg = getViewpointOrgDataFromState(state, id);
  if (viewpointOrg !== null) {
    const isViewpointFreeEligible = isViewpointOrgSubscriptionFreeEligible(viewpointOrg);

    return {
      isLiveTrading: isCurrentUserLiveTrading(currentUser),
      viewpointOrg,

      isFree: doesCurrentUserHaveFreeSubSlot(currentUser) && isViewpointFreeEligible,
    };
  } else {
    return {
      isLiveTrading: isCurrentUserLiveTrading(currentUser),
      viewpointOrg,
      isFree: null,
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

ViewpointSubscribeButton.defaultProps = {
  buttonType: VIEWPOINT_SUBSCRIBE_BUTTON_TYPES.LINK,
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(ViewpointSubscribeButton);

export default withRouter(connectedComponent);
