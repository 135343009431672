import React from 'react';
import { useLiveAccount } from '@src/main/hooks/user/useLiveAccount';

function getTrustedContactFromLiveAccount(liveAccount) {
  if (!liveAccount) {
    return null;
  }
  const {
    trusted_contact_apartment,
    trusted_contact_city,
    trusted_contact_country,
    trusted_contact_email,
    trusted_contact_name,
    trusted_contact_phone_number,
    trusted_contact_postal_code,
    trusted_contact_relationship,
    trusted_contact_state,
    trusted_contact_street_address,
  } = liveAccount;
  return {
    trusted_contact_apartment,
    trusted_contact_city,
    trusted_contact_country,
    trusted_contact_email,
    trusted_contact_name,
    trusted_contact_phone_number,
    trusted_contact_postal_code,
    trusted_contact_relationship,
    trusted_contact_state,
    trusted_contact_street_address,
  };
}

export const useTrustedContact = () => {
  const liveAccount = useLiveAccount();
  return getTrustedContactFromLiveAccount(liveAccount);
};
