import React from 'react';
import { CloseButton, FlatButton } from '../../components/buttons';
import { useDispatch } from 'react-redux';
import { useBankAccounts } from '../../hooks/funding/useBankAccounts';
import { createRecurringDeposit, getBankAccounts } from '../../../actions';
import { TrackingEvents } from '../../../utils/tracking/events';
import { useDepositFunds } from '../../hooks/funding/useDepositFunds';
import { Body5, defineFontSettings, H3 } from '../../lib/nvstr-common-ui.es';
import styled from 'styled-components';
import { formatDataValue } from '../../../helpers/securitiesHelpers';
import { Plaid } from '../../pages/funding/interstitial/Plaid';
import { Modal } from '../../components/layout';
import { LoadingMessage, LoadingOverlay } from '../../components/ui';
import { CheckCircleFilled } from '../../icons/svg/CheckCircleFilled';
import { DepositForm } from '../forms/DepositForm';
import { Text } from '../../components/text';
import { createTimeInstance, formatLocalizedDateTime } from '../../../helpers/timeHelpers';
import { Container } from '../../components/ui';
import * as PropTypes from 'prop-types';

const ActionsWrapper = styled.div`
  padding-top: 24px;
  text-align: center;
`;
const TransferCompletedWrapper = styled.div`
  max-width: 400px;
`;
const TransferCompletedHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const InlineIconTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const TransferCompletedIconWrapper = styled.div`
  svg {
    height: 36px;
    width: 36px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

const FormWrapper = styled.div`
  input {
    line-height: 24px;
  }
`;
const DepositFormWrapper = styled.div`
  position: relative;
`;
const CloseButtonPosition = styled.div`
  position: absolute;
  top: 0;
  right: -8px;
  z-index: 10;

  svg {
    g {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const NotEnoughFundsMessageWrapper = styled.div`
  padding: 16px 30px 16px 0;
`;
const LineBreak = styled.div`
  border-radius: 1px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.themeColors.border};
  opacity: 0.6;
`;
const ErrorIconWrapper = styled.div`
  height: 24px;
  width: 22px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    height: 22px;
    width: 22px;

    g {
      fill: ${({ theme }) => theme.themeColors.error};
    }
  }
`;
const RecurringSuccessButDepositErrorIconWrapper = styled.div`
  height: 24px;
  width: 22px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

const ModalStates = {
  none: null,
  isSubmitting: 'is',
  completed: 's',
  error: 'err',
  depositForm: 'depositForm',
  insufficientFunds: 'if',
};

const RecurringDepositsWrapper = styled.div`
  width: 100%;
`;
const RecurringDepositsTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;
const RecurringDepositsButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 8px;
  button {
    width: 326px;
  }
`;

function TransferSummaryMessages({ amount, recurringAmount, recurringDepositSuccessful, serverMessage }) {
  const sectionHeaderFontSettings = defineFontSettings(
    { fontSize: '18px', lineHeight: '24px' },
    { fontSize: '18px', lineHeight: '24px' }
  );
  const amountFontSettings = defineFontSettings(
    { fontSize: '20px', lineHeight: '28px' },
    { fontSize: '20px', lineHeight: '28px' }
  );
  const inTextWithAmountFontSettings = defineFontSettings(
    { fontSize: '14px', lineHeight: '28px' },
    { fontSize: '14px', lineHeight: '28px' }
  );
  const textFontSettings = defineFontSettings(
    { fontSize: '14px', lineHeight: '18px' },
    { fontSize: '14px', lineHeight: '18px' }
  );

  if (recurringDepositSuccessful) {
    return (
      <Container top={36}>
        <Text fontSettings={sectionHeaderFontSettings} bold>
          Transfers Requested Summary:
        </Text>
        <Container top={24} horizontal={8}>
          <Container row>
            <Text fontSettings={inTextWithAmountFontSettings}>One time deposit amount:</Text>
            <Text fontSettings={amountFontSettings}>{` $${amount}`}</Text>
          </Container>
          <Container top={8}>
            <Text fontSettings={inTextWithAmountFontSettings}>{`Status: ${serverMessage}`}</Text>
          </Container>
        </Container>

        <Container top={24}>
          <LineBreak />
        </Container>

        <Container row top={16} horizontal={8}>
          <Text fontSettings={inTextWithAmountFontSettings}>Monthly recurring deposit amount:</Text>
          <Text fontSettings={amountFontSettings} bold>
            {` $${recurringAmount}`}
          </Text>
        </Container>
      </Container>
    );
  }
  return (
    <Container top={16}>
      <Container row>
        <Text fontSettings={inTextWithAmountFontSettings}>One time deposit amount:</Text>
        <Text fontSettings={amountFontSettings} bold>
          {` $${amount}`}
        </Text>
      </Container>
      <Container top={8}>
        <Text fontSettings={textFontSettings}>{`Status: ${serverMessage}`}</Text>
      </Container>
    </Container>
  );
}

const ErrorIcon = () => (
  <svg viewBox="0 0 24 24">
    <g>
      <path d="M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
    </g>
  </svg>
);

function ErrorMessage({ text }) {
  const fontSettings = defineFontSettings(
    { fontSize: '14px', lineHeight: '22px' },
    { fontSize: '14px', lineHeight: '22px' }
  );

  return (
    <InlineIconTextWrapper>
      <ErrorIconWrapper>
        <ErrorIcon />
      </ErrorIconWrapper>
      <Container left={16}>
        <Text fontSettings={fontSettings}>{text}</Text>
      </Container>
    </InlineIconTextWrapper>
  );
}

export const ModalContent = ({
  data,
  trackingMetadata,
  recurringAmount,
  modalState,
  errorMessage,
  onDepositComplete,
  onContinue,
  onDismiss,
}) => {
  if (modalState === ModalStates.isSubmitting) {
    return (
      <div>
        <LoadingMessage message={'Submitting Deposit'} />
      </div>
    );
  }
  if (modalState === ModalStates.completed) {
    const { amount, recurringAmount, recurringDepositSuccessful, serverMessage } = data;

    const longMessageFontSettings = defineFontSettings(
      { fontSize: '14px', lineHeight: '28px' },
      { fontSize: '14px', lineHeight: '28px' }
    );
    const shortMessageFontSettings = defineFontSettings(
      { fontSize: '20px', lineHeight: '28px' },
      { fontSize: '20px', lineHeight: '28px' }
    );

    const fontSettings = recurringDepositSuccessful ? longMessageFontSettings : shortMessageFontSettings;

    const statusMessage = recurringDepositSuccessful
      ? 'Your deposit is being processed, and your recurring deposit has been scheduled successfully.'
      : 'Your deposit is being processed.';
    return (
      <TransferCompletedWrapper>
        <TransferCompletedHeadingWrapper>
          <TransferCompletedIconWrapper>
            <CheckCircleFilled />
          </TransferCompletedIconWrapper>
          <Container verticallyCenter row left={16}>
            <Text fontSettings={fontSettings}>{statusMessage}</Text>
          </Container>
        </TransferCompletedHeadingWrapper>
        <TransferSummaryMessages
          amount={amount}
          recurringAmount={recurringAmount}
          recurringDepositSuccessful={recurringDepositSuccessful}
          serverMessage={serverMessage}
        />
        <ActionsWrapper>
          <Container top={recurringDepositSuccessful ? 24 : 0}>
            <FlatButton onClick={onContinue}>Continue</FlatButton>
          </Container>
        </ActionsWrapper>
      </TransferCompletedWrapper>
    );
  }
  if (modalState === ModalStates.error) {
    const { amount, recurringAmount, recurringDepositSuccessful, serverMessage } = data;
    const inTextWithAmountFontSettings = defineFontSettings(
      { fontSize: '14px', lineHeight: '28px' },
      { fontSize: '14px', lineHeight: '28px' }
    );

    return (
      <div>
        <ErrorMessage text={errorMessage} />
        {recurringDepositSuccessful && (
          <Container row top={24} verticallyCenter>
            <RecurringSuccessButDepositErrorIconWrapper>
              <CheckCircleFilled />
            </RecurringSuccessButDepositErrorIconWrapper>
            <Container left={16}>
              <Text fontSettings={inTextWithAmountFontSettings}>
                {`$${recurringAmount} monthly recurring deposit was scheduled successfully.`}
              </Text>
            </Container>
          </Container>
        )}
        <ActionsWrapper>
          <div>
            <FlatButton onClick={onDismiss}>Dismiss</FlatButton>
          </div>
        </ActionsWrapper>
      </div>
    );
  }
  if (modalState === ModalStates.depositForm) {
    const cachedFormValues = {
      account_id: data.bankAccounts.length > 0 ? data.bankAccounts[0].id : '',
      value: isNaN(data.amount) ? '' : data.amount.toString(),
      recurringAmount: data.recurringAmount,
    };

    const handleDepositComplete = (isComplete, serverMessage, amount) => {
      onDepositComplete(amount);
    };

    return (
      <DepositFormWrapper>
        <CloseButtonPosition>
          <CloseButton onClick={onDismiss} />
        </CloseButtonPosition>
        <FormWrapper>
          <DepositForm
            trackingMetadata={trackingMetadata}
            initialFormValues={cachedFormValues}
            onContinue={handleDepositComplete}
            useReauthHandler
            showDepositPicker
            showLinkBankAccountButton
            allowEmailAuth
          />
        </FormWrapper>
      </DepositFormWrapper>
    );
  }
  if (modalState === ModalStates.insufficientFunds) {
    const cachedFormValues = {
      account_id: data.bankAccounts.length > 0 ? data.bankAccounts[0].id : '',
      value: isNaN(data.amount) ? '' : data.amount.toString(),
      // recurringAmount: data.recurringAmount, // NOTE: recurring call is not needed here because it would have already succeeded
    };

    const handleDepositComplete = (isComplete, serverMessage, amount) => {
      onDepositComplete(amount);
    };

    const fontSettings = defineFontSettings(
      {
        fontSize: '14px',
        lineHeight: '18px',
      },
      {
        fontSize: '16px',
        lineHeight: '20px',
      }
    );

    return (
      <DepositFormWrapper>
        <CloseButtonPosition>
          <CloseButton onClick={onDismiss} />
        </CloseButtonPosition>
        <NotEnoughFundsMessageWrapper>
          <Text fontSettings={fontSettings}>
            The bank account selected has insufficient funds for this transfer. Try adding a different bank account or
            lowering the transfer amount.
          </Text>
        </NotEnoughFundsMessageWrapper>
        <FormWrapper>
          <DepositForm
            trackingMetadata={trackingMetadata}
            initialFormValues={cachedFormValues}
            onContinue={handleDepositComplete}
            allowEmailAuth
            useReauthHandler
            showDepositPicker
            showLinkBankAccountButton
          />
        </FormWrapper>
      </DepositFormWrapper>
    );
  }

  return null;
};

export const DepositButton = (props) => {
  const {
    amount,
    recurringAmount,
    onDepositComplete,
    color,
    textColor,
    buttonProps,
    trackingMetadata,
    allowEmailAuth,
  } = props;

  const dispatch = useDispatch();
  const bankAccounts = useBankAccounts();

  const [reauthToken, setReauthToken] = React.useState(false);
  const [showPlaid, setShowPlaid] = React.useState(false);
  const [modalState, setModalState] = React.useState(ModalStates.none);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [recurringDepositSuccessful, setRecurringDepositSuccessful] = React.useState(false);
  const [serverMessage, setServerMessage] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const [bankAccountId, setBankAccountId] = React.useState(null);
  const { submitDeposit } = useDepositFunds(allowEmailAuth);

  React.useEffect(() => {
    const fetchBankAccounts = async () => {
      const { ok } = await getBankAccounts()(dispatch);
      if (!ok) {
        setTimeout(fetchBankAccounts, 1000);
      }
    };
    if (bankAccounts.length === 0) fetchBankAccounts();
  }, []);

  React.useEffect(
    () => {
      if (bankAccounts.length === 1 && bankAccountId !== bankAccounts[0].id) setBankAccountId(bankAccounts[0].id);
    },
    [bankAccountId, bankAccounts]
  );

  const onModalDismiss = () => {
    setErrorMessage(null);
    setModalState(ModalStates.none);
  };

  const onDepositError = (message) => {
    if (message === 'The bank account has insufficient funds for this transfer.') {
      setModalState(ModalStates.insufficientFunds);
    } else {
      setErrorMessage(message);
      setModalState(ModalStates.error);
    }
  };

  const onContinue = () => {
    onDepositComplete(amount);
  };

  const handleDepositComplete = (amount) => {
    onDepositComplete(amount);
  };

  const handleSubmit = React.useCallback(
    async ({ bankAccountId: _params_bankAccountId, recurringAmount, amount }) => {
      let bankAccountId = _params_bankAccountId;

      if (isSubmitting) {
        return;
      }

      TrackingEvents.funding.SUBMIT_DEPOSIT_FORM.send();

      if (!bankAccountId) {
        if (bankAccounts.length === 0) {
          setShowPlaid(true);
          return;
        } else if (bankAccounts.length > 1) {
          setModalState(ModalStates.depositForm);
          return;
        } else {
          bankAccountId = bankAccounts[0].id;
        }
      }

      const formValues = {
        bankAccountId,
        amount,
      };
      setIsSubmitting(true);
      setModalState(ModalStates.isSubmitting);

      if (recurringAmount > 0) {
        const recurringForm = {
          amount: recurringAmount,
          start_date: formatLocalizedDateTime('api2', createTimeInstance().add(1, 'month')),
          account_id: bankAccountId,
        };
        const { ok, errorMessage } = await createRecurringDeposit(recurringForm, allowEmailAuth)(dispatch);
        if (ok) {
          TrackingEvents.funding.RECURRING_DEPOSIT_ADDED_SUCCESS.send({
            Amount: recurringAmount,
            'Lesson ID': trackingMetadata?.lessonId || null,
          });
          setRecurringDepositSuccessful(true);
        }
      }

      const response = await submitDeposit(formValues);
      const { ok, errorMessage, needsPlaidReauth, plaidAccountToken, noticeMessage } = response;
      if (ok) {
        setServerMessage(noticeMessage);
        setModalState(ModalStates.completed);
        TrackingEvents.funding.SUBMIT_DEPOSIT_FORM_SUCCESS.send();
      } else if (needsPlaidReauth) {
        TrackingEvents.funding.SUBMIT_DEPOSIT_FORM_FAILURE.send({
          Reason: 'plaid reauth required',
        });

        setModalState(ModalStates.none);

        setReauthToken(plaidAccountToken);
        setShowPlaid(true);
      } else {
        const message = errorMessage || 'Something went wrong, please try again';
        TrackingEvents.funding.SUBMIT_DEPOSIT_FORM_FAILURE.send({
          Reason: 'API Error',
          'Error Message': message,
        });
        onDepositError(message);
      }
      setIsSubmitting(false);
    },
    [bankAccountId, amount]
  );

  const handleClick = async () => {
    const form = {
      bankAccountId,
      amount,
    };
    await handleSubmit(form);
  };

  const onMakeAllDepositsPress = async () => {
    const form = {
      bankAccountId,
      amount,
      recurringAmount,
    };
    await handleSubmit(form);
  };

  const handlePlaidCancel = React.useCallback((hadLinkSuccess) => {
    setShowPlaid(false);
  }, []);

  const handlePlaidLinkSuccess = React.useCallback(
    async (bankAccountId) => {
      setBankAccountId(bankAccountId);
      const form = {
        bankAccountId,
        amount,
        recurringAmount,
      };
      await handleSubmit(form);
    },
    [amount, recurringAmount]
  );

  const handleLinkFailure = React.useCallback(() => {
    // error shown in plaid component
  }, []);

  const handleMissingLiveAccount = React.useCallback(() => {
    window.reload();
  }, []);

  const showModal = modalState !== ModalStates.none;
  return (
    <>
      {isSubmitting && <LoadingOverlay message={'Processing...'} />}
      {showPlaid && (
        <Plaid
          reauthToken={reauthToken}
          onPlaidContinue={handlePlaidCancel}
          onPlaidLinkSuccess={handlePlaidLinkSuccess}
          onPlaidLinkFailure={handleLinkFailure}
          onMissingLiveAccount={handleMissingLiveAccount}
          inline
        />
      )}
      {showModal && (
        <Modal restrictDismiss={modalState === ModalStates.isSubmitting} onDismiss={onModalDismiss}>
          <ModalContent
            data={{ amount, bankAccounts, recurringAmount, recurringDepositSuccessful, serverMessage }}
            trackingMetadata={trackingMetadata}
            modalState={modalState}
            errorMessage={errorMessage}
            onContinue={onContinue}
            onDepositComplete={handleDepositComplete}
            onDismiss={onModalDismiss}
          />
        </Modal>
      )}

      {recurringAmount > 0 ? (
        <RecurringDepositsWrapper fullWidth>
          <RecurringDepositsTextWrapper centerAll bottom={4}>
            <Body5 color={color}>{`$${amount} Deposit + Start $${recurringAmount} Recurring Monthly Deposit`}</Body5>
          </RecurringDepositsTextWrapper>
          <RecurringDepositsButtonWrapper row>
            <FlatButton onClick={onMakeAllDepositsPress} color={color} textColor={textColor} buttonProps={buttonProps}>
              {`Start Deposits`}
            </FlatButton>
          </RecurringDepositsButtonWrapper>
        </RecurringDepositsWrapper>
      ) : (
        <FlatButton fullWidth onClick={handleClick} color={color} textColor={textColor} buttonProps={buttonProps}>
          Make {formatDataValue(amount, 'price')} Deposit
        </FlatButton>
      )}
    </>
  );
};
