import React from 'react';
import styled from 'styled-components';
import LoadingSpinner from '@src/main/components/Images/LoadingSpinner';
import SearchIcon from '@src/main/components/Images/SearchIcon';
import { Plus } from '../../../../main/assets/svgs/Plus';

export const SearchIconLoadingWrap = styled.div`
  position: absolute;
  z-index: 2;
  left: -12px;
  top: 3px;
`;

export const SearchIconWrap = styled.div`
  position: absolute;
  z-index: 2;
  left: 12px;
  top: ${(props) => props.iconTopPos || '17px'};
`;

export const SearchInputWrapper = styled.div``;

const Icon = ({ loading, showPlus, iconTopPos }) => {
  return loading ? (
    <SearchIconLoadingWrap>
      <LoadingSpinner isSmall />
    </SearchIconLoadingWrap>
  ) : (
    <SearchIconWrap iconTopPos={iconTopPos}>{showPlus ? <Plus thick /> : <SearchIcon />}</SearchIconWrap>
  );
};

const SearchInput = (props) => {
  const { query, iconTopPos, loading, showPlus } = props;
  const className = props.className || 'search-input';
  const placeholder = props.placeholder || 'Search';
  const name = props.name || 'security-query';

  const id = props.id;
  const conditionalInputProps = {
    ...(id ? { id } : {}),
  };

  const handleChange = (e) => {
    props.handleChange(e);
  };

  return (
    <SearchInputWrapper className={className}>
      <Icon {...props} />
      <input
        {...conditionalInputProps}
        name={name}
        type="text"
        value={query}
        placeholder={placeholder}
        onChange={handleChange}
        autoFocus={props.autoFocus}
        spellCheck="off"
        autoCorrect="off"
        autoComplete="off"
      />
    </SearchInputWrapper>
  );
};

export default SearchInput;
