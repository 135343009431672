import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as Actions from '../../../actions/index';

import { fetchRecentThoughts } from '../../../actions/thoughtsActions';
import { returnSecurityIdFromThought } from '../../../helpers/thoughtsHelpers';
import ThoughtContainer from '../../Thoughts/ThoughtContainer';
import BasicSecurityDataWrapper from '../../SecurityData/BasicSecurityDataWrapper';
import { ThoughtType } from '../../Thoughts/components/ThoughtType';
import SecurityIdentifierDisplay from '../../UI/SecurityIdentifierDisplay';
import { getThoughtImpressionEventName } from '../../../constants/tracking';
import styled from 'styled-components';
import { LoadingSpinner, Body3 } from '../../../main/lib/nvstr-common-ui.es';
import { useUser } from '../../../main/hooks/user/useUser';

const Wrapper = styled.div`
  .thought-feed-thought-headline {
    * {
      font-size: 14px !important;
      line-height: 18px !important;
      color: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

const LoadingWrapper = styled.div`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const ProfilePosts = ({ userId }) => {
  const dispatch = useDispatch();
  const user = useUser(userId);
  const [thoughts, setThoughts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);

  const fetchThoughts = () => {
    setIsLoading(true);
    const params = {
      userId,
      count: 15,
    };
    fetchRecentThoughts(params)(dispatch).then((response) => {
      const { ok, thoughts, errorMessage } = response;
      if (ok) {
        setIsLoading(false);
        setThoughts(thoughts);
      } else {
        setError(errorMessage);
        if (errorMessage === 'Recent posts are private.') {
          setIsPrivate(true);
        }
      }
    });
  };

  useEffect(() => {
    fetchThoughts();
  }, []);

  const _renderThoughtHeadline = (thought) => {
    return (
      <div className={'thought-feed-thought-headline'}>
        <span style={{ marginRight: '5px' }}>
          <ThoughtType thought={thought} />
        </span>
        <span style={{ marginRight: '5px' }}>for</span>
        <span style={{ marginRight: '-1px' }}>
          <BasicSecurityDataWrapper securityId={returnSecurityIdFromThought(thought)}>
            <SecurityIdentifierDisplay prefix={'('} suffix={')'} showSymbol showName limitNameWidth />
          </BasicSecurityDataWrapper>
        </span>
        <span>from the community</span>
      </div>
    );
  };

  if (isPrivate)
    return (
      <LoadingWrapper>
        <div style={{ textAlign: 'center', paddingTop: '32px' }}>
          <Body3 isLowContrast>Recent posts are set to private</Body3>
        </div>
      </LoadingWrapper>
    );

  if (isLoading)
    return (
      <LoadingWrapper>
        <LoadingSpinner />
      </LoadingWrapper>
    );

  if (thoughts?.length === 0)
    return (
      <LoadingWrapper>
        <div style={{ textAlign: 'center', paddingTop: '32px' }}>
          <Body3 isLowContrast>{`${user.first_name} has no recent Pro/Con posts`}</Body3>
        </div>
      </LoadingWrapper>
    );

  return (
    <div>
      {thoughts.map((thought, index) => {
        return (
          <Wrapper className={'react-thought-notification'} key={thought.id}>
            {_renderThoughtHeadline(thought)}
            <BasicSecurityDataWrapper securityId={returnSecurityIdFromThought(thought)}>
              <ThoughtContainer
                thoughtId={thought.id}
                thought={thought}
                viewingContext={getThoughtImpressionEventName('FEED')}
                scrollContainerSelector={window}
                inFeed={true}
                showAlternateOpinionActionStyle
                isUserAuthed
                hideOpinionCounts
                withThoughtLink={true}
                isSuggestedThought
                useShareInModal
              />
            </BasicSecurityDataWrapper>
          </Wrapper>
        );
      })}
    </div>
  );
};

export default ProfilePosts;
