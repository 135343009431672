import React from 'react';

const IdeaDeleteOptions = (props) => {
  return (
    <div className={`idea-delete-options-container component-bg`}>
      <div className={`idea-delete-message  `}>
        {`You have a position in ${props.symbol}. You must exit the position to delete this idea.`}
      </div>
      <div className={`idea-delete-options`}>
        <div className={`idea-delete-option`}>
          <span className={`link-btn link-btn-std-color`} onClick={props.handleExitPosition}>
            {'Exit Position'}
          </span>
        </div>
        <div className={`idea-delete-option last`}>
          <span className={`link-btn link-btn-std-color`} onClick={props.handleKeepPosition}>
            {'Keep Position'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default IdeaDeleteOptions;
