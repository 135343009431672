import React from 'react';
import { enums } from '@src/main/lib/nvstr-utils.es';
import { useButtonEvent } from '../../utils/itly/hooks/useButtonEvent';
import styled from 'styled-components';
import { BaseButtonWrapper } from '../../main/components/buttons';

const SubmitButtonWrapper = styled.input`
  background: ${({ theme }) => theme.themeColors.primaryCtaButton};
  border: ${({ theme }) => '1px solid ' + theme.themeColors.primaryCtaButton};
  color: ${({ theme }) => theme.themeColors.buttonText};

  :hover {
    opacity: 0.8;
  }

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  box-shadow: none;
  transition: background-color 300ms, color 300ms;

  border-radius: 6px !important;
  height: auto;
  width: 100%;
  max-width: 360px;
  padding: 12px 24px;
  white-space: nowrap;

  color: ${({ theme }) => theme.themeColors.buttonText};
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
`;

const Submit = (props) => {
  const onClick = useButtonEvent(props);
  const text = props.text || props.value;
  const className = props.className || '';

  const handleClick = (e) => {
    if (props.disabled) {
      e.preventDefault();
    } else {
      onClick();
    }
  };

  return <SubmitButtonWrapper type="submit" onClick={handleClick} value={text} />;
};

Submit.defaultProps = {
  buttonType: enums.button_types.button,
};

export default Submit;
