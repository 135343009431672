import React from 'react';
import styled from 'styled-components';

import { colorPalette } from '@src/main/lib/nvstr-utils.es';
import { LogoShort } from '@src/main/lib/nvstr-common-ui.es';
import GiftBoxNoShadow from '@src/images/Gift_700px_TB_noshadow.png';
import { Gradient1 } from '@src/assets/gradients/Gradient1';
import Icon from '../../../components/misc/Icon';

const RewardCardWrapper = styled.div`
  position: relative;
  width: 250px;
  height: 175px;
  margin: 0 auto;
  border-radius: 8px;
  -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  * {
    color: ${colorPalette.primary.charcoal};
  }
`;

const RewardCardBgWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  border-radius: 8px;
  overflow: hidden;

  svg {
    width: 220%;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: -32px;
  left: 14px;

  pointer-events: none;

  img {
    width: 225px;
  }
`;

const ClickToClaimMessage = styled.div`
  position: absolute;
  bottom: 6px;
  left: 0px;
  right: 0px;

  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;

  svg {
    width: 26px;

    g {
      fill: ${colorPalette.primary.charcoal};
    }
  }
`;

const RewardTextWrapper = styled.div`
  position: absolute;
  top: 9px;
  right: 14px;

  span {
    line-height: 30px;
  }
`;

const CardFront = () => {
  return (
    <>
      <RewardCardBgWrapper>
        <Gradient1 />
      </RewardCardBgWrapper>
      <LogoWrapper>
        <LogoShort />
      </LogoWrapper>
      <RewardTextWrapper>
        <span>Reward</span>
      </RewardTextWrapper>
      <ImageWrapper hAlign="center" vAlign="center">
        <img src={GiftBoxNoShadow} />
      </ImageWrapper>
      <ClickToClaimMessage>
        <span>{'Click to claim'}</span>
      </ClickToClaimMessage>
    </>
  );
};

const CardBack = ({ prize, bonusPercentage, baseReward }) => {
  return (
    <div className={'rewards-card-face prize card-back selected'}>
      <div className={'card-icon-container'}>
        <Icon icon={'fa-money'} size={'xlarge'} colorClassName={'success-text-color'} />
      </div>
      <div className={'prize-amount success-text-color'}>
        {`$${isNaN(prize) || prize === null ? '' : prize && !Number.isInteger(prize) ? prize.toFixed(2) : prize}`}
      </div>
      {bonusPercentage !== null && (
        <div className={'sub-prize-amount success-text-color text-center'}>
          {`$${!Number.isInteger(baseReward) ? baseReward.toFixed(2) : baseReward} + ${bonusPercentage}% Bonus`}
        </div>
      )}
    </div>
  );
};

export const RewardCard = (props) => {
  const {
    onClick,
    isFlippable,

    shouldShowPrize,
  } = props;

  const flippableCardClassNames = `rewards-card flippable-card ${shouldShowPrize ? 'flipped' : ''} selected`;

  return (
    <RewardCardWrapper className={isFlippable ? flippableCardClassNames : ''} onClick={onClick}>
      {isFlippable ? (
        <div className={'rewards-card-face card-front'}>
          <CardFront />
        </div>
      ) : (
        <CardFront />
      )}

      {isFlippable && <CardBack {...props} />}
    </RewardCardWrapper>
  );
};
