import { useSecurity } from '@src/main/hooks/securities/useSecurity';
import { ORDER_TRANSACTION_TYPES } from '@src/main/constants/orders';
import { Page } from '@src/main/components/layout';
import { Container } from '@src/main/components/ui';
import { Body5, H5 } from '@src/main/lib/nvstr-common-ui.es';
import { FlatButton } from '@src/main/components/buttons';
import React from 'react';
import styled from 'styled-components';

const ActionsWrapper = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > div {
    width: 100%;

    :first-child {
      padding-right: 8px;
    }
    :last-child {
      padding-left: 8px;
    }
  }
  button {
    width: 100%;
  }
`;
const NameWrapper = styled.div`
  padding-left: 5px;

  width: calc(60% - 40px);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 12px;
  color: ${({ theme }) => theme.themeColors.secondaryText};
`;

export const TransactionTypeSelectionForm = ({ securityId, onSelect }) => {
  const { name, symbol } = useSecurity(securityId);

  const handleBuyClick = () => {
    onSelect(ORDER_TRANSACTION_TYPES.buy);
  };
  const handleSellClick = () => {
    onSelect(ORDER_TRANSACTION_TYPES.sell);
  };
  return (
    <Page width={'400px'}>
      <Container row style={{ alignItems: 'baseline' }}>
        <H5>{symbol}</H5>
        <NameWrapper>
          <Body5 thin isLowContrast>
            {name}
          </Body5>
        </NameWrapper>
      </Container>
      <ActionsWrapper>
        <FlatButton onClick={handleBuyClick}>Buy</FlatButton>
        <FlatButton onClick={handleSellClick}>Sell</FlatButton>
      </ActionsWrapper>
    </Page>
  );
};
