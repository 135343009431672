import React from 'react';

import { Link } from 'react-router-dom';
import Icon from '../../../components/misc/Icon';
import { convertSizeToStyling } from '../../../helpers/avatarHelpers';

export const CommunityAvatar = (props) => {
  const { linkTo, ideaType } = props;

  const withLink = 'withLink' in props ? props.withLink : true;
  const size = props.size || 'small';
  const sizeOffset = 0;

  let textContainerStyle = {
    ...convertSizeToStyling(size, sizeOffset * 2).user_initials_container,
    ...convertSizeToStyling(size, sizeOffset * 2).profile_avatar_container,
  };
  textContainerStyle.paddingTop = '1px';

  const iconElement = <Icon icon="fa-users" />;

  const defaultAvatar = (
    <span
      className="default-avatar-text-container"
      style={{
        ...textContainerStyle,
        height: '100%',
        width: '100%',
        color: '#fff',
        fontWeight: 600,
      }}
    >
      {iconElement}
    </span>
  );

  const avatarChildren = defaultAvatar;

  const returnChildrenContainer = (children) => (
    <span className="avatar-wrapper">
      <span
        className={`inner-avatar-border`}
        style={{
          position: 'absolute',
          top: `${0}px`,
          right: `${0}px`,
          bottom: `${0}px`,
          left: `${0}px`,
          borderRadius: '200px',
        }}
      >
        <span
          className={`inner-avatar-container`}
          style={{
            position: 'absolute',
            top: `${0}px`,
            right: `${0}px`,
            bottom: `${0}px`,
            left: `${0}px`,
            borderRadius: '200px',
          }}
        >
          {children}
        </span>
      </span>
      {ideaType && (
        <div className="drop-shadow-container">
          <div className="drop-shadow-container-top">
            <div
              className={`drop-shadow-bottom ${
                ideaType && ideaType.toLowerCase() === 'buy' ? 'drop-shadow-green' : 'drop-shadow-red'
              }`}
            ></div>
          </div>
          <div className="drop-shadow-container-bottom">
            <div
              className={`drop-shadow-top ${
                ideaType && ideaType.toLowerCase() === 'buy' ? 'drop-shadow-green' : 'drop-shadow-red'
              }`}
            ></div>
          </div>
        </div>
      )}
    </span>
  );

  const avatarContainerClass = 'default-avatar-container';
  const componentProps = {
    className: `avatar-container ${avatarContainerClass} avatar-${size}  `,
    style: {
      ...convertSizeToStyling(size, 0).profile_avatar_container,
      ...(withLink ? {} : { cursor: 'default' }),
    },
  };

  if (withLink) {
    const url = linkTo;
    componentProps.to = url;
  }

  const handleMouseOver = () => {
    return props.handleMouseOver && props.handleMouseOver();
  };
  const handleMouseOut = () => {
    return props.handleMouseOut && props.handleMouseOut();
  };

  componentProps.onMouseEnter = handleMouseOver;
  componentProps.onMouseLeave = handleMouseOut;
  return React.createElement(withLink ? Link : 'div', componentProps, returnChildrenContainer(avatarChildren));
};
