import React, { PureComponent } from 'react';
import Icon from '../../components/misc/Icon';

export default class InComponentSuccessBanner extends PureComponent {
  render() {
    const {
      message,

      onDismiss,
    } = this.props;

    return (
      <div className="in-component-success-banner success-bg">
        <div className="dismiss-message-button offset-to-center"></div>
        <div className="message-container text-color-contrast-against-color-bg">{message}</div>
        <div className="dismiss-message-button" onClick={onDismiss}>
          <Icon icon="fa fa-times" size="small" customClass={'text-color-contrast-against-color-bg'} />
        </div>
      </div>
    );
  }
}
