import React from 'react';
import { Page } from '../components/layout';
import DocumentUpload from '../../containers/Account/DocumentUpload/DocumentUpload';

export const ManualDocumentUpload = () => {
  return (
    <Page width="600px">
      <DocumentUpload />
    </Page>
  );
};
