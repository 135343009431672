import React from 'react';
import styled from 'styled-components';
import { hexToRgbA } from '@src/helpers/generalHelpers';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden; // card should not have scroll

  background: ${({ theme }) => hexToRgbA('#222', 0.6)};
  color: ${({ theme }) => theme.themeColors.text};

  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  z-index: 10000;
`;

export const Overlay = (props) => {
  const { children, restrictDismiss, onDismiss } = props;

  const handleOverlayClick = () => {
    if (restrictDismiss) {
      return;
    }

    onDismiss && onDismiss();
  };

  return <Wrapper onClick={handleOverlayClick}>{children}</Wrapper>;
};
