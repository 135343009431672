import React from 'react';
import { Thresholds } from '../../../constants';

import Icon from '../../../components/misc/Icon';

const OrdersPlacedListHeading = (props) => {
  const {
    isMobile,
    windowWidth,

    isRefreshing,
    showRefreshButton,
    handleRefresh,
  } = props;

  const needsCondensing = windowWidth <= Thresholds.widthForOrderCompactView;
  return (
    <div
      className={`
      order-basket-list-heading
      placed-orders
      ${needsCondensing ? 'condensed-order-basket-list-heading' : ''}
    `}
    >
      <div className={`order-type-and-operation`}>
        {showRefreshButton && (
          <div className={`refresh-prices-container refresh-text-color`} onClick={handleRefresh}>
            <span className={`refresh-icon-container ${isRefreshing ? 'is-updating' : ''}`}>
              <Icon icon="fa-refresh" size="small" colorClassName={'refresh-text-color'} />
            </span>
            <span className={`text-container`}>{'Refresh order statuses'}</span>
          </div>
        )}
      </div>
      <div className={`order-security-identifier`}>{''}</div>
      <div className={`order-fill-container`}>{'Filled / Order Size'}</div>
      {!isMobile && <div className={`order-executed-at-container`}>{'Time'}</div>}
      <div className={`cancel-order-container`}>{''}</div>
    </div>
  );
};

export default OrdersPlacedListHeading;
