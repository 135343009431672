import React from 'react';
import styled from 'styled-components';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useLearnLesson } from '../../../hooks/learn/useLearnLesson';
import { TrackingEvents } from '../../../../utils/tracking/events';
import { colorPalette } from '../../../lib/nvstr-utils.es';
import { FlatButton, SkeletonButton } from '../../buttons';
import { useCurrentUser } from '../../../hooks/user';
import { Spacing } from '../../layout';
import { buildPartnerButtonText, buildPartnerExternalURL } from '../../../utils/learn';
import { Container } from '../../ui';
import { logUserAction } from '@src/actions/v1/application';
import { useDispatch } from 'react-redux';
import { USER_ACTION_CONTEXTS, USER_ACTION_EVENT_NAMES } from '@src/main/constants/userActions';

const ReadMoreLink = styled.a`
  color: ${({ color }) => color} !important;
  text-decoration: none;

  &:hover {
    opacity: 0.6;
    text-decoration: none;
  }
`;

const Underline = styled.span`
  text-decoration: underline;
`;

export const PrimaryCTA = ({ lessonId, isLastScreen, onComplete, advanceScreen }) => {
  const dispatch = useDispatch();
  const { analytics_id } = useCurrentUser();
  const { lesson: lessonData } = useLearnLesson(lessonId);
  const { sponsorId, metadata } = lessonData || {};
  const { partnerName } = metadata || {};
  const inShowPartnerCalloutTestGroup = metadata?.inShowPartnerCalloutTestGroup || false;
  const inPartnerNonCalloutGroup = !!partnerName && !inShowPartnerCalloutTestGroup;

  if (isLastScreen && sponsorId) {
    return (
      <Container fullWidth top={2} bottom={8} centerAll style={{ textAlign: 'center' }}>
        <FlatButton
          color={colorPalette.primary.charcoal}
          textColor={colorPalette.primary.oak}
          onClick={onComplete}
          buttonProps={{ overrideContext: 'Lesson Completed' }}
          fullWidth
        >
          Complete
        </FlatButton>
      </Container>
    );
  }

  if (isLastScreen && inPartnerNonCalloutGroup) {
    const handleClaimOfferPress = () => {
      TrackingEvents.learn.PRESS_PRODUCT_PARTNER_CALLOUT_LINK.send({
        'Lesson ID': lessonId,
        Partner: partnerName,
      });
      onComplete();

      logUserAction({
        event_name: USER_ACTION_EVENT_NAMES.pressPartnerLink,
        context: USER_ACTION_CONTEXTS.learnLesson,
        value: lessonId,
      })(dispatch);

      const url = buildPartnerExternalURL(analytics_id, lessonId, partnerName);
      window?.open(url, '_blank').focus();
    };

    return (
      <Container fullWidth top={2} bottom={8} centerAll style={{ textAlign: 'center' }}>
        <FlatButton
          color={colorPalette.primary.charcoal}
          textColor={colorPalette.primary.oak}
          onClick={handleClaimOfferPress}
          buttonProps={{ overrideContext: 'Lesson Completed' }}
          fullWidth
        >
          {buildPartnerButtonText(partnerName)}
        </FlatButton>
      </Container>
    );
  }

  if (isLastScreen) {
    return (
      <Container fullWidth top={2} bottom={8} centerAll style={{ textAlign: 'center' }}>
        <FlatButton
          color={colorPalette.primary.charcoal}
          textColor={colorPalette.primary.oak}
          onClick={onComplete}
          buttonProps={{ overrideContext: 'Lesson Completed' }}
          fullWidth
        >
          COMPLETE
        </FlatButton>
      </Container>
    );
  }

  return (
    <Container fullWidth top={2} bottom={8} centerAll style={{ textAlign: 'center' }}>
      <SkeletonButton
        color={colorPalette.primary.charcoal}
        hoverTextColor={colorPalette.primary.oak}
        onClick={advanceScreen}
        fullWidth
      >
        CONTINUE
      </SkeletonButton>
    </Container>
  );
};

export const SecondaryCTA = ({ lessonId, textColor, isLastScreen, onComplete }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { lesson: lessonData } = useLearnLesson(lessonId);
  const { sponsorId, moreOnTopicUrl, metadata } = lessonData || {};
  const partnerName = metadata?.partnerName || null;
  const moreInfoOverride = metadata?.moreInfoOverride || false;
  const inShowPartnerCalloutTestGroup = metadata?.inShowPartnerCalloutTestGroup || false;
  const inPartnerNonCalloutGroup = !!partnerName && !inShowPartnerCalloutTestGroup;

  const onReadMoreClick = () => {
    TrackingEvents.learn.PRESS_SEE_MORE_ON_LEARN_TOPIC.send({
      'Lesson ID': lessonId,
      'Sponsor ID': sponsorId,
    });
  };

  const onDisclaimersPress = () => {
    const searchAsObject = Object.fromEntries(new URLSearchParams(searchParams));
    setSearchParams({ ...searchAsObject, showMoreInfo: '1' });
  };

  if (isLastScreen && moreOnTopicUrl) {
    return (
      <Container centerAll vertical={8}>
        <ReadMoreLink href={moreOnTopicUrl} target="_blank" onClick={onReadMoreClick} color={textColor}>
          <span>Read More About This Topic At </span>
          <Underline>Nasdaq.com</Underline>
        </ReadMoreLink>
      </Container>
    );
  }

  if (isLastScreen && inPartnerNonCalloutGroup) {
    return (
      <Container centerAll bottom={8}>
        <FlatButton transparent height={20} onClick={onComplete} fullWidth color={textColor}>
          No, thanks
        </FlatButton>
      </Container>
    );
  }

  if (moreInfoOverride) {
    const { url, hidden } = moreInfoOverride;
    const handlePress = () => {
      TrackingEvents.learn.PRESS_CUSTOM_MORE_INFO_LINK.send({
        'Lesson ID': lessonId,
        Partner: partnerName,
      });

      if (url) {
        window?.open(url, '_blank').focus();
      } else {
        console.log('no url', url);
      }
    };

    if (hidden) {
      return <Spacing top={0} bottom={0} />;
    }
    return (
      <Container centerAll bottom={8}>
        <FlatButton transparent height={20} onClick={handlePress} fullWidth color={textColor}>
          MORE INFO
        </FlatButton>
      </Container>
    );
  }

  return (
    <Container centerAll bottom={8}>
      <FlatButton transparent height={20} onClick={onDisclaimersPress} fullWidth color={textColor}>
        MORE INFO
      </FlatButton>
    </Container>
  );
};

export const LearnLessonActions = ({ lessonId, isLastScreen, onComplete, advanceScreen }) => {
  const textColor = colorPalette.primary.charcoal;
  return (
    <div style={{ width: '100%' }}>
      <PrimaryCTA
        lessonId={lessonId}
        textColor={textColor}
        isLastScreen={isLastScreen}
        onComplete={onComplete}
        advanceScreen={advanceScreen}
      />
      <SecondaryCTA
        lessonId={lessonId}
        textColor={textColor}
        isLastScreen={isLastScreen}
        onComplete={onComplete}
        advanceScreen={advanceScreen}
      />
    </div>
  );
};
