import { trackingEvents } from '@src/main/lib/nvstr-utils.es';
import ItlyTrackWrapper from './ItlyTrackWrapper';

const BUTTON_CLICKED = new ItlyTrackWrapper(trackingEvents.buttonClicked);
const MODAL_VIEWED = new ItlyTrackWrapper(trackingEvents.modalViewed);
const TAB_VIEWED = new ItlyTrackWrapper(trackingEvents.tabViewed);
const FEED_LOADED = new ItlyTrackWrapper(trackingEvents.feedLoaded);

const genericTrackIteratively = {
  BUTTON_CLICKED,
  MODAL_VIEWED,
  TAB_VIEWED,
  FEED_LOADED,
};

export default genericTrackIteratively;
