import React from 'react';

export const PageHeading = (props) => {
  const { className } = props;

  return (
    <div className={`page-heading ${className || ''}`}>
      <h2>{props.children}</h2>
    </div>
  );
};

export default PageHeading;
