import React from 'react';

import { ConnectionRequestedNotification } from './ConnectionNotificationComponents/ConnectionRequestedNotification';
import { ConnectionAcceptedNotification } from './ConnectionNotificationComponents/ConnectionAcceptedNotification';
import { ConnectionHasNewConnectionsNotification } from './ConnectionNotificationComponents/ConnectionHasNewConnectionsNotification';

import { throwError } from '../../../../helpers/devToolHelpers';

export const ConnectionNotification = (props) => {
  const { notification } = props;
  const { action } = notification;

  if (action === 'connection_requested') {
    return <ConnectionRequestedNotification notification={notification} />;
  } else if (action === 'connection_accepted') {
    return <ConnectionAcceptedNotification notification={notification} />;
  } else if (action === 'friends_new_connection') {
    return <ConnectionHasNewConnectionsNotification notification={notification} />;
  } else {
    throwError('Missing action render for ConnectionNotification', true, {
      notification,
    });
    return null;
  }
};
