import React from 'react';

import Button from '../../../components/buttons/Button';
import { FlatButton, SkeletonButton } from '../../../main/components/buttons';

export class AddThoughtCTA extends React.Component {
  render() {
    return (
      <div className={`no-thoughts-action-container component-bg-faded`}>
        <span className={`text-wrapper`}>{this._returnMessaging()}</span>
        <span className={`btn-wrapper`}>
          <SkeletonButton small onClick={this._handleClick}>
            {`+ Add ${this._returnThoughtType().toUpperCase()}`}
          </SkeletonButton>
        </span>
      </div>
    );
  }

  _handleClick = () => {
    this.props.handleClick(this._returnThoughtType());
  };

  _returnThoughtType = () => this.props.thoughtType;
  _isProThoughtType = () => this._returnThoughtType().toLowerCase() === 'pro';
  _returnSymbol = () => (this.props.symbol ? this.props.symbol.toUpperCase() : 'this');

  _returnMessaging = () =>
    this._isProThoughtType()
      ? `What makes ${this._returnSymbol()} a good investment?`
      : `What makes ${this._returnSymbol()} a risky investment?`;
}
