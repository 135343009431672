import React from 'react';
import styled from 'styled-components';
import { APP_ICON } from '@src/appConfig';

const Wrapper = styled.div`
  flex-grow: 0;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  overflow: hidden;

  img {
    height: 50px;
    width: 50px;
  }
`;
export const AppIcon = () => {
  return (
    <Wrapper>
      <img src={APP_ICON} alt={'app-icon'} />
    </Wrapper>
  );
};
