import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  & > svg {
    width: 18px;
    height: 18px;
  }

  & g,
  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function DicussionIcon() {
  return (
    <Wrapper>
      <svg viewBox="0 0 18 18" fill="none">
        <path
          d="M15.667.667H2.334c-.917 0-1.667.75-1.667 1.666v15L4 14h11.667c.917 0 1.667-.75 1.667-1.667v-10c0-.916-.75-1.666-1.667-1.666zm0 11.666H4L2.334 14V2.333h13.333v10z"
          fill="#1F1F1F"
        />
      </svg>
    </Wrapper>
  );
}

export default DicussionIcon;
