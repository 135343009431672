import React from 'react';
import { useDispatch } from 'react-redux';
import {
  endOnboarding,
  fetchCurrentUserQuickInfo,
  fetchFurtherDocumentationStatus,
  logMetricsTrackingEvent,
} from '../../../actions';
import { LoadingPage } from '../../components/page';
import { ACCOUNT_TYPES } from '../../constants';
import { SOCURE_DECISION_STATUS } from '../../../constants/types/Socure';
import { returnPathTo, ROUTES } from '../../../constants/paths';
import { useNavigate } from 'react-router-dom';
import {
  isCurrentUserOnboarding,
  returnCurrentUserOnboardingTestGroup,
  returnCurrentUserThemeTestGroups,
} from '../../../helpers/currentUserHelpers';
import { setTrackingUserProperty } from '../../../utils/tracking/amplitude';
import { sendFacebookLTVEvent, sendFacebookTrackingEvent } from '../../../constants/facebookTracking';
import { TrackingEvents } from '../../../utils/tracking/events';
import { TrackIteratively } from '../../../utils/itly';
import { useIsOnboarding } from '../../hooks/user';

export const EndOnboarding = ({ accountTypePath, onboardingState }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isOnboarding = useIsOnboarding();

  const [retryCount, setRetryCount] = React.useState(0);

  const validateCurrentUserState = React.useCallback(
    async () => {
      const currentUser = await fetchCurrentUserQuickInfo()(dispatch);
      const currentUserResponseOk = currentUser?.id > 0;
      const socureResponse = await fetchFurtherDocumentationStatus();

      const { decisionStatus } = socureResponse;

      if (!currentUserResponseOk) {
        setRetryCount(retryCount + 1);
        return;
      }

      const isOnboarding = isCurrentUserOnboarding(currentUser);
      if (isOnboarding) {
        const handleEndOnboarding = async () => {
          const isLiveAccount = accountTypePath === ACCOUNT_TYPES.live;

          setTrackingUserProperty('In Onboarding', false);
          setTrackingUserProperty('Is Onboarding', false);
          sendFacebookTrackingEvent('Complete Onboarding', {
            themeTestGroups: returnCurrentUserThemeTestGroups(currentUser),
            accountType: isLiveAccount ? 'Live' : 'Paper',
          });
          sendFacebookLTVEvent(1);

          const defaultEventProperties = {
            'Has Live Account': isLiveAccount,
          };
          isLiveAccount
            ? TrackingEvents.onboarding.COMPLETED_LIVE_SIGNUP.send(defaultEventProperties)
            : TrackingEvents.onboarding.COMPLETED_PAPER_SIGNUP.send(defaultEventProperties);
          TrackIteratively.onboarding.ONBOARDING_COMPLETED.send({
            url: window.location.pathname,
            url_query: window.location.search,
          });

          const response = await endOnboarding(currentUser.id)(dispatch);
          if (response.ok) {
            const endOnboardingPathTestGroup = returnCurrentUserOnboardingTestGroup(currentUser);
            let path;

            const needsMoreInfo = [
              SOCURE_DECISION_STATUS.ADDRESS_RESUBMIT,
              SOCURE_DECISION_STATUS.FURTHER_DOCUMENTS_REQUIRED,
            ].includes(decisionStatus);
            if (needsMoreInfo) {
              navigate(ROUTES.UPLOAD_REQUIRED_DOCUMENTS.build());
            }

            if (endOnboardingPathTestGroup === 5) {
              path = returnPathTo('onboardingDashboardWithOptimizer');
            } else if (endOnboardingPathTestGroup === 6) {
              path = returnPathTo('portfolioAnalysis');
            } else {
              path = returnPathTo('dashboard');
            }
            navigate(path);
            return { ok: true };
          } else {
            return { ok: false };
          }
        };

        const { ok } = await handleEndOnboarding();
        if (!ok) {
          setRetryCount(retryCount + 1);
        }
      }
    },
    [retryCount]
  );

  React.useEffect(() => {
    logMetricsTrackingEvent('View End Onboarding')();

    if (isOnboarding) {
      validateCurrentUserState();
    } else {
      navigate(ROUTES.DASHBOARD.build());
    }
  }, []);

  React.useEffect(
    () => {
      validateCurrentUserState();
    },
    [retryCount]
  );

  return <LoadingPage />;
};
