import React from 'react';

export const MOBILE_BREAKPOINT = 1133;

const checkIsMobile = () => {
  return window.innerWidth < MOBILE_BREAKPOINT;
};

const useIsMobileSize = () => {
  const [isMobile, setIsMobile] = React.useState(checkIsMobile());

  React.useEffect(() => {
    const handleResize = () => {
      const isNowMobile = checkIsMobile();
      setIsMobile(isNowMobile);
    };
    window.$(window).on('resize', handleResize);
    return () => window.$(window).off('resize', handleResize);
  }, []);

  return isMobile;
};

export default useIsMobileSize;
