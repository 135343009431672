// eslint-disable-next-line no-unused-vars
import React from 'react';

export const MARKET_STATES = {
  none: 'unknown',
  preMarket: 'pre',
  open: 'open',
  afterHours: 'after',
  closed: 'closed',
};

export const OptionsActionTypes = {
  updateOptionsPositions: 'updateOptionsPositions',
  addOptionsPosition: 'addOptionPosition',
};

export const applicationActionTypes = {
  showComponentModal: 'showComponentModal',
  hideComponentModal: 'hideComponentModal',
};
export const membershipActionTypes = {
  update: 'updateMembershipData',
};
export const marketMonitorActionTypes = {
  addSecurity: 'm_mAddSecurity',
  // removeSecurity: 'addSecurity',
};
export const livePriceActionTypes = {
  updatePrices: 'lp-updatePrices',
};

export const colorThemeActions = {
  set: 'setColorTheme',
  setCustomColorThemeColorDefinition: 'setCustomColorThemeColorDefinition',
  resetCustomColorTheme: 'resetCustomColorTheme',
};

/********** GLOBALS ACTIONS *****************/
export const SET_APP_HEIGHT = 'SET_APP_HEIGHT';

/********** ACTION MESSAGE ACTIONS *****************/
export const SHOW_ACTION_MESSAGE = 'SHOW_ACTION_MESSAGE';
export const DISMISS_ACTION_MESSAGE = 'DISMISS_ACTION_MESSAGE';

/********** APP STORAGE ACTIONS *****************/
export const SAVE_APP_STORAGE = 'SAVE_APP_STORAGE';
export const REMOVE_KEY_APP_STORAGE = 'REMOVE_KEY_APP_STORAGE';

/********** APPLICATION ACTIONS *****************/
export const ADD_DATA_TYPES = 'ADD_DATA_TYPES';
export const ADD_SECURITIES_DESCRIPTIONS = 'ADD_SECURITIES_DESCRIPTIONS';

export const SET_MARKET_STATE = 'SET_MARKET_STATE';
export const SET_MARKET_HOURS_DATA = 'SET_MARKET_HOURS_DATA';

export const SET_SESSION_RISK_ID = 'SET_SESSION_RISK_ID';
export const UPDATE_EMAIL_INVITE_COPY = 'UPDATE_EMAIL_INVITE_COPY';
export const UPDATE_STATIC_COPY = 'UPDATE_STATIC_COPY';

/********** AUTH ACTIONS *****************/
export const USER_AUTHENTICATION_SUCCESS = 'USER_AUTHENTICATION_SUCCESS';
export const USER_AUTHENTICATION_FAILURE = 'USER_AUTHENTICATION_FAILURE';

/********** AJAX ACTIONS *****************/
export const START_AJAX_REQUEST = 'START_AJAX_REQUEST';
export const END_AJAX_REQUEST = 'END_AJAX_REQUEST';

/********** ASK ACTIONS *****************/
export const SEND_ASK = 'SEND_ASK';
export const UPDATE_ASK_PERMISSION = 'UPDATE_ASK_PERMISSION';

/********** BANNER ACTIONS *****************/
export const FETCH_BANNER = 'FETCH_BANNER';
export const SHOW_BANNER = 'SHOW_BANNER';
export const DISMISS_BANNER = 'DISMISS_BANNER';

/********** COLLECTIVES ACTIONS *****************/
export const FETCH_COLLECTIVES = 'FETCH_COLLECTIVES';
export const ADD_COLLECTIVES = 'ADD_COLLECTIVES';

/********** CURRENT USER ACTIONS *****************/
export const UPDATE_USER_PROPERTY = 'UPDATE_USER_PROPERTY';
export const ADD_CURRENT_USER_QUICK_INFO = 'ADD_CURRENT_USER_QUICK_INFO';

export const CONVERT_CURRENT_USER_TO_LIVE_TRADING = 'CONVERT_CURRENT_USER_TO_LIVE_TRADING';

export const UPDATE_SECURITY = 'UPDATE_SECURITY';

export const ADD_EDIT_PROFILE_PAGE = 'ADD_EDIT_PROFILE_PAGE';
export const REMOVE_EDIT_PROFILE_PAGE = 'REMOVE_EDIT_PROFILE_PAGE';

export const USE_TRADING_CREDITS = 'USE_TRADING_CREDITS';

export const SET_SUBSCRIBER = 'SET_SUBSCRIBER';

export const UPLOAD_AVATAR_IMAGE = 'UPLOAD_AVATAR_IMAGE';
export const DELETE_AVATAR_IMAGE = 'DELETE_AVATAR_IMAGE';

export const AGREE_TO_BD_TERMS = 'AGREE_TO_BD_TERMS';

export const START_MEMBERSHIP = 'START_MEMBERSHIP';

/********** FOOTER ACTIONS *****************/
export const ADD_FOOTER_PARTIAL = 'ADD_FOOTER_PARTIAL';
export const FOOTER_LOAD_ERROR = 'FOOTER_LOAD_ERROR';

/********** HISTORY ACTIONS *****************/
export const ADD_HISTORY_DOCUMENTS_DATA = 'ADD_HISTORY_DOCUMENTS_DATA';
export const GET_HISTORY_TRANSACTIONS_DATA = 'GET_HISTORY_TRANSACTIONS_DATA';
export const ADD_HISTORY_TRANSACTIONS_DATA = 'ADD_HISTORY_TRANSACTIONS_DATA';
export const GET_HISTORY_EQUITY_DATA = 'GET_HISTORY_EQUITY_DATA';
export const ADD_HISTORY_EQUITY_DATA = 'ADD_HISTORY_EQUITY_DATA';
export const CANCEL_FUNDING_TRANSACTION = 'CANCEL_FUNDING_TRANSACTION';

/********** HORIZONTAL LIST ACTIONS *****************/
export const INCREMENT_HORIZONTAL_LIST = 'INCREMENT_HORIZONTAL_LIST';
export const DECREMENT_HORIZONTAL_LIST = 'DECREMENT_HORIZONTAL_LIST';
export const RESET_HORIZONTAL_LIST = 'RESET_HORIZONTAL_LIST';

/********** IDEAS ACTIONS *****************/
export const CURRENT_USER_CREATING_IDEAS = 'CURRENT_USER_CREATING_IDEAS';
export const REMOVE_CURRENT_USER_CREATING_IDEAS = 'REMOVE_CURRENT_USER_CREATING_IDEAS';

export const ADD_IDEAS_FOR_SECURITY = 'ADD_IDEAS_FOR_SECURITY';
export const ADD_USER_IDEAS = 'ADD_USER_IDEAS';
export const ADD_CURRENT_USER_IDEAS = 'ADD_CURRENT_USER_IDEAS';
export const ADD_IDEAS = 'ADD_IDEAS';

export const ADD_TEMP_IDEA = 'ADD_TEMP_IDEA';
export const ADD_IDEAS_FOR_CURRENT_USER = 'ADD_IDEAS_FOR_CURRENT_USER';
export const REMOVE_IDEA = 'REMOVE_IDEA';
export const EDIT_IDEA = 'EDIT_IDEA';

export const FETCH_IDEAS_FOR_SECURITY = 'FETCH_IDEAS_FOR_SECURITY';
export const FETCH_IDEAS = 'FETCH_IDEAS';

export const REFRESHING_IDEA_DATA = 'REFRESHING_IDEA_DATA';

export const LOADING_IDEAS_FOR_SECURITY = 'LOADING_IDEAS_FOR_SECURITY';
export const LOADING_IDEAS_FOR_USER = 'LOADING_IDEAS_FOR_USER';
export const LOADING_IDEAS = 'LOADING_IDEAS';

/********** IDEA EXPLORER ACTIONS *****************/
export const IMPORT_EXISTING_IDEAS = 'IMPORT_EXISTING_IDEAS';
export const INITIALIZE_SUGGESTIONS = 'INITIALIZE_SUGGESTIONS';
export const ASK_WHY = 'ASK_WHY';
export const ADD_IDEA = 'ADD_IDEA';
export const ADD_IDEA_TO_WELL = 'ADD_IDEA_TO_WELL';
export const MAKE_SUGGESTION = 'MAKE_SUGGESTION';
export const ADD_SUGGESTIONS = 'ADD_SUGGESTIONS';
export const UPDATE_IDEA = 'UPDATE_IDEA';
export const UPDATE_INTEREST_AND_SENTIMENT = 'UPDATE_INTEREST_AND_SENTIMENT';
export const DISMISS_IDEA = 'DISMISS_IDEA';
export const DROP_IDEA = 'DROP_IDEA';
export const SAVE_IDEAS = 'SAVE_IDEAS';
export const RESET_IDEAS = 'RESET_IDEAS';
export const INCREMENT_REQUEST_COUNT = 'INCREMENT_REQUEST_COUNT';
export const START_GENERATING_SUGGESTIONS = 'START_GENERATING_SUGGESTIONS';
export const END_GENERATING_SUGGESTIONS = 'END_GENERATING_SUGGESTIONS';
export const REFRESH_WELL_BASED_ON_INTEREST = 'REFRESH_WELL_BASED_ON_INTEREST';

/********** IMPORT CONNECTIONS ACTIONS *****************/
export const ADD_IMPORT_CONNECTIONS_PAGE = 'ADD_IMPORT_CONNECTIONS_PAGE';
export const REMOVE_IMPORT_CONNECTIONS_PAGE = 'REMOVE_IMPORT_CONNECTIONS_PAGE';

export const LOADING_IMPORT_CONNECTIONS_PAGE = 'LOADING_IMPORT_CONNECTIONS_PAGE';
export const LOADING_IMPORT_NETWORKS = 'LOADING_IMPORT_NETWORKS';

export const LOADING_IMPORT_CONTACTS = 'LOADING_IMPORT_CONTACTS';
export const ADD_IMPORT_CONTACTS = 'ADD_IMPORT_CONTACTS';

export const ADD_IMPORT_NETWORKS = 'ADD_IMPORT_NETWORKS';

/********** INFOICON ACTIONS *****************/
export const SHOW_INFO_ICON_MODAL = 'SHOW_INFO_ICON_MODAL';
export const HIDE_INFO_ICON_MODAL = 'HIDE_INFO_ICON_MODAL';

/********** INVESTMENT OBJECTIVES ACTIONS *****************/
export const ADD_AVAILABLE_RISK_PROFILES = 'ADD_AVAILABLE_RISK_PROFILES';
export const ADD_INVESTMENT_OBJECTIVE_SETTINGS = 'ADD_INVESTMENT_OBJECTIVE_SETTINGS';
export const UPDATE_INVESTMENT_OBJECTIVE_SETTINGS = 'UPDATE_INVESTMENT_OBJECTIVE_SETTINGS';

/********** LIVE ACCOUNTS ACTIONS *****************/
export const GET_LIVE_TRADING_ACCOUNT = 'GET_LIVE_TRADING_ACCOUNT';
export const LIVE_TRADING_SUBMIT_STATUS = 'LIVE_TRADING_SUBMIT_STATUS';
export const LIVE_TRADING_SUBMIT_FORM = 'LIVE_TRADING_SUBMIT_FORM';
export const LIVE_TRADING_ACCOUNT_UPDATE = 'LIVE_TRADING_ACCOUNT_UPDATE';
export const UPDATE_LIVE_TRADING_ACCOUNT_STATUS = 'UPDATE_LIVE_TRADING_ACCOUNT_STATUS';
export const REJECT_LIVE_TRADING_ACCOUNT = 'REJECT_LIVE_TRADING_ACCOUNT';

export const GET_BANK_ACCOUNTS = 'GET_BANK_ACCOUNTS';
export const ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT';
export const REMOVE_BANK_ACCOUNT = 'REMOVE_BANK_ACCOUNT';
export const VERIFY_BANK_ACCOUNT = 'VERIFY_BANK_ACCOUNT';
export const VERIFY_BANK_ACCOUNT_APPROVED = 'VERIFY_BANK_ACCOUNT_APPROVED';
export const VERIFY_BANK_ACCOUNT_MAX_ATTEMPTS_REACHED = 'VERIFY_BANK_ACCOUNT_MAX_ATTEMPTS_REACHED';
export const CREATE_BANK_ACCOUNT_NOTICE = 'CREATE_BANK_ACCOUNT_NOTICE';
export const CLEAR_BANK_ACCOUNT_NOTICE = 'CLEAR_BANK_ACCOUNT_NOTICE';
export const CREATE_FUNDING_FORM_NOTICE = 'CREATE_FUNDING_FORM_NOTICE';
export const CLEAR_FUNDING_FORM_NOTICE = 'CLEAR_FUNDING_FORM_NOTICE';
export const TRANSACTION_ACCEPTED = 'TRANSACTION_ACCEPTED';
export const CREATE_ACCOUNT_NOTICE = 'CREATE_ACCOUNT_NOTICE';
export const DEPOSIT_FUNDS = 'DEPOSIT_FUNDS';
export const WITHDRAW_FUNDS = 'WITHDRAW_FUNDS';
export const SUBMIT_APPEAL_IMAGE = 'SUBMIT_APPEAL_IMAGE';

export const START_LINKING_PLAID_ACCOUNT = 'START_LINKING_PLAID_ACCOUNT';
export const CANCEL_LINKING_PLAID_ACCOUNT = 'CANCEL_LINKING_PLAID_ACCOUNT';

/********** MODAL ACTIONS *****************/
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const CONFIRM_MODAL = 'CONFIRM_MODAL';

/********** NAV ACTIONS *****************/
export const TOGGLE_ALWAYS_OPEN_VERTICAL_NAV = 'TOGGLE_ALWAYS_OPEN_VERTICAL_NAV';
export const FORCE_UNDOCK_VERTICAL_NAV = 'FORCE_UNDOCK_VERTICAL_NAV';
export const RELEASE_FORCE_UNDOCK_VERTICAL_NAV = 'RELEASE_FORCE_UNDOCK_VERTICAL_NAV';
export const TOGGLE_VERTICAL_NAV = 'TOGGLE_VERTICAL_NAV';
export const SHOW_VERTICAL_NAV = 'SHOW_VERTICAL_NAV';
export const HIDE_VERTICAL_NAV = 'HIDE_VERTICAL_NAV';

export const SET_SHOW_SEARCH_HEADER = 'SET_SHOW_SEARCH_HEADER';
export const SET_HIDE_SEARCH_HEADER = 'SET_HIDE_SEARCH_HEADER';

/********** NEWS ACTIONS *****************/
export const ADD_LOADING_STATE_FOR_NEWS = 'ADD_LOADING_STATE_FOR_NEWS';
export const ADD_LOADING_STATE_FOR_NEWS_IMAGE = 'ADD_LOADING_STATE_FOR_NEWS_IMAGE';
export const ADD_ERROR_STATE_FOR_NEWS = 'ADD_ERROR_STATE_FOR_NEWS';
export const ADD_NEWS = 'ADD_NEWS';
export const ADD_NEWS_IMAGE = 'ADD_NEWS_IMAGE';
export const ADD_NEWS_IMAGE_FAILURE_STATE = 'ADD_NEWS_IMAGE_FAILURE_STATE';
export const CLEAR_NEWS_DATA_FOR_SECURITY = 'CLEAR_NEWS_DATA_FOR_SECURITY';

/********** NOTIFICATIONS ACTIONS *****************/
export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const ADD_TO_NEW_NOTIFICATIONS_LIST = 'ADD_TO_NEW_NOTIFICATIONS_LIST';
export const REFRESH_NOTIFICATIONS = 'REFRESH_NOTIFICATIONS';

export const UPDATE_NEW_NOTIFICATIONS_COUNT = 'UPDATE_NEW_NOTIFICATIONS_COUNT';

export const FETCH_NEW_NOTIFICATIONS = 'FETCH_NEW_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';

export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const CLEAR_NEW_NOTIFICATIONS = 'CLEAR_NEW_NOTIFICATIONS';
export const CLEAR_NEW_NOTIFICATIONS_ALERT_EXPAND = 'CLEAR_NEW_NOTIFICATIONS_ALERT_EXPAND';

/********** ONBOARDING ACTIONS *****************/
export const END_ONBOARDING = 'END_ONBOARDING';

/********** ORDER ACTIONS *****************/

export const orderActions = {
  getAllOrdersData: 'getAllOrdersData',
  placedOrders: 'placedOrders',
  createCartOrder: 'createCartOrder',
  updateOrder: 'updateOrder',
  deleteOrder: 'deleteOrder',
  deleteAllOrders: 'deleteAllOrders',
};
export const GETTING_ORDER_DATA = 'GETTING_ORDER_DATA';
export const ADD_ORDER_DATA = 'ADD_ORDER_DATA';
export const ADD_PLACED_ORDER = 'ADD_PLACED_ORDER';
export const ADD_ORDER_TO_BASKET = 'ADD_ORDER_TO_BASKET';
export const PLACE_ORDERS = 'PLACE_ORDERS';
export const CREATE_ORDERS = 'CREATE_ORDERS';
export const EDIT_ORDER = 'EDIT_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const CANCEL_ORDER = 'CANCEL_ORDER';

export const DELETE_ORDER = 'DELETE_ORDER';
export const UNDELETE_ORDER = 'UNDELETE_ORDER';

export const CLEAR_ORDER_BASKET = 'CLEAR_ORDER_BASKET';
export const UNDO_CLEAR_ORDER_BASKET = 'UNDO_CLEAR_ORDER_BASKET';

/********** OPTIMIZER ACTIONS *****************/
export const OPEN_OPTIMIZER_PANEL = 'OPEN_OPTIMIZER_PANEL';
export const CLOSE_OPTIMIZER_PANEL = 'CLOSE_OPTIMIZER_PANEL';
export const RUN_OPTIMIZER = 'RUN_OPTIMIZER';
export const NEW_OPTIMIZATION_RECEIVED = 'NEW_OPTIMIZATION_RECEIVED';
export const UPDATE_LAST_OPTIMIZED_TIMESTAMP = 'UPDATE_LAST_OPTIMIZED_TIMESTAMP';

export const ADD_OPTIMIZER_ALLOCATION_CONSTRAINTS = 'ADD_OPTIMIZER_ALLOCATION_CONSTRAINTS,';
export const CREATE_TEMPORARY_OPTIMIZER_ALLOCATION_CONSTRAINT = 'CREATE_TEMPORARY_OPTIMIZER_ALLOCATION_CONSTRAINT,';
export const CREATE_OPTIMIZER_ALLOCATION_CONSTRAINT = 'CREATE_OPTIMIZER_ALLOCATION_CONSTRAINT,';
export const UPDATE_OPTIMIZER_ALLOCATION_CONSTRAINT = 'UPDATE_OPTIMIZER_ALLOCATION_CONSTRAINT,';
export const DELETE_OPTIMIZER_ALLOCATION_CONSTRAINT = 'DELETE_OPTIMIZER_ALLOCATION_CONSTRAINT,';

export const MOVE_OPTIMIZER_ALLOCATION_CONSTRAINT_TO_TOP = 'MOVE_OPTIMIZER_ALLOCATION_CONSTRAINT_TO_TOP,';

/********** REBALANCER ACTIONS *****************/
export const OPEN_REBALANCER_PANEL = 'OPEN_REBALANCER_PANEL';
export const CLOSE_REBALANCER_PANEL = 'CLOSE_REBALANCER_PANEL';
export const RUN_REBALANCER = 'RUN_REBALANCER';
export const NEW_REBALANCER_DATA_RECEIVED = 'NEW_REBALANCER_DATA_RECEIVED';
export const REBALANCER_RUN_FAILED = 'REBALANCER_RUN_FAILED';

/********** PAGES ACTIONS *****************/
export const ADD_PAGES_PARTIAL = 'ADD_PAGES_PARTIAL';
export const PAGES_LOAD_ERROR = 'PAGES_LOAD_ERROR';

/********** PLAID ACTIONS *****************/
export const SHOW_PLAID_OVERLAY = 'SHOW_PLAID_OVERLAY';
export const HIDE_PLAID_OVERLAY = 'HIDE_PLAID_OVERLAY';

export const SHOW_PLAID_INVESTMENT_OVERLAY = 'SHOW_PLAID_INVESTMENT_OVERLAY';
export const HIDE_PLAID_INVESTMENT_OVERLAY = 'HIDE_PLAID_INVESTMENT_OVERLAY';
export const LINK_PLAID_INVESTMENT_ACCOUNT = 'LINK_PLAID_INVESTMENT_ACCOUNT';
export const CANCEL_LINKING_INVESTMENT_PLAID_ACCOUNT = 'CANCEL_LINKING_INVESTMENT_PLAID_ACCOUNT';

/********** PORTFOLIO ACTIONS *****************/
export const ADD_PORTFOLIO_DATA = 'ADD_PORTFOLIO_DATA';
export const UPDATE_PORTFOLIO_DATA = 'UPDATE_PORTFOLIO_DATA';

export const START_LOADING_PORTFOLIO_DATA = 'START_LOADING_PORTFOLIO_DATA';
export const USER_PORTFOLIO_DATA_ERROR = 'USER_PORTFOLIO_DATA_ERROR';

export const ADD_EXTERNAL_POSITIONS_DATA = 'ADD_EXTERNAL_POSITIONS_DATA';
export const UPDATE_EXTERNAL_POSITIONS_DATA = 'UPDATE_EXTERNAL_POSITIONS_DATA';

export const START_LOADING_EXTERNAL_POSITIONS_DATA = 'START_LOADING_EXTERNAL_POSITIONS_DATA';
export const USER_EXTERNAL_POSITIONS_DATA_ERROR = 'USER_EXTERNAL_POSITIONS_DATA_ERROR';

/********** KARMA ACTIONS *****************/
export const ADD_KARMA_POINTS = 'ADD_KARMA_POINTS';
export const UPDATE_KARMA_POINTS = 'UPDATE_KARMA_POINTS';
export const REACHED_MAX_DAILY_KARMA = 'REACHED_MAX_DAILY_KARMA';

/********** PORTFOLIO EXPLORER ACTIONS *****************/
export const FILTER_SECURITIES = 'FILTER_SECURITIES';
export const START_FILTERING_LOADING = 'START_FILTERING_LOADING';
export const END_FILTERING_LOADING = 'END_FILTERING_LOADING';
export const GET_STARTING_STOCKS = 'GET_STARTING_STOCKS';
export const GET_FILTER_SELECTIONS = 'GET_FILTER_SELECTIONS';
export const ADD_SECURITY_TO_PORTFOLIO_EXPLORER = 'ADD_SECURITY_TO_PORTFOLIO_EXPLORER';
export const REMOVE_SECURITY_FROM_PORTFOLIO_EXPLORER = 'REMOVE_SECURITY_FROM_PORTFOLIO_EXPLORER';

/********** REWARDS ACTIONS *****************/
export const LOADING_REWARDS_DATA = 'LOADING_REWARDS_DATA';
export const GET_REWARDS_DATA = 'GET_REWARDS_DATA';
export const GET_REWARDS_DATA_ERROR = 'GET_REWARDS_DATA_ERROR';

export const RECEIVED_RANDOM_REWARD_REDEMPTION = 'RECEIVED_RANDOM_REWARD_REDEMPTION';
export const RECEIVED_REFERRAL_REWARD_REDEMPTION = 'RECEIVED_REFERRAL_REWARD_REDEMPTION';
export const RECEIVED_FUNDING_REWARD_REDEMPTION = 'RECEIVED_FUNDING_REWARD_REDEMPTION';

export const REWARD_REDEMPTION_ERROR = 'REWARD_REDEMPTION_ERROR';

/********** SCROLL ACTIONS *****************/
export const LOCK_APP_SCROLL = 'LOCK_APP_SCROLL';
export const UNLOCK_APP_SCROLL = 'UNLOCK_APP_SCROLL';

/********** SEARCH ACTIONS *****************/
export const QUERY_SECURITIES = 'QUERY_SECURITIES';
export const QUERY_RESULTS = 'QUERY_RESULTS';
export const START_QUERY = 'START_QUERY';
export const END_QUERY = 'END_QUERY';
export const CLEAR_QUERY = 'CLEAR_QUERY';
export const NEW_REQUEST = 'NEW_REQUEST';

/********** SECURITIES ACTIONS *****************/
export const ADD_SYMBOL_LOOKUP = 'ADD_SYMBOL_LOOKUP';

export const LOADING_SECURITIES_DATA = 'LOADING_SECURITIES_DATA';

export const QUICK_ADD_SECURITIES = 'QUICK_ADD_SECURITIES';
export const ADD_SECURITIES = 'ADD_SECURITIES';
export const UPDATE_SECURITIES = 'UPDATE_SECURITIES';
export const UPDATE_EARNING_ANNOUNCEMENTS = 'UPDATE_EARNING_ANNOUNCEMENTS';

/********** SECURITY TABLE ACTIONS *****************/
export const ADD_SECURITY_TO_SECURITY_TABLE = 'ADD_SECURITY_TO_SECURITY_TABLE';
export const ADD_SECURITY_FUNDAMENTAL_DATA = 'ADD_SECURITY_FUNDAMENTAL_DATA';

/********** SECURITIES PRICE ACTIONS *****************/
export const LOADING_SECURITY_ORDER_PRICE_DATA = 'LOADING_SECURITY_ORDER_PRICE_DATA';
export const ADD_SECURITY_ORDER_PRICE_DATA = 'ADD_SECURITY_ORDER_PRICE_DATA';

export const ADD_SECURITY_PRICE_DATA = 'ADD_SECURITY_PRICE_DATA';
export const LOADING_SECURITY_PRICE_DATA = 'LOADING_SECURITY_PRICE_DATA';

/********** SECURITIES PRICE HISTORY ACTIONS *****************/
export const START_LOADING_PRICE_HISTORY = 'START_LOADING_PRICE_HISTORY';
export const ADD_PRICE_HISTORY = 'ADD_PRICE_HISTORY';

/********** STREAMING PRICE ACTIONS *****************/
export const ADD_ADDITIONAL_STREAMING_SECURITY = 'ADD_ADDITIONAL_STREAMING_SECURITY';

export const STREAMING_PRICES_UPDATE = 'STREAMING_PRICES_UPDATE';

/********** TOUR ACTIONS *****************/
export const ADD_TOUR_DATA = 'ADD_TOUR_DATA';
export const UPDATE_TOUR_DATA = 'UPDATE_TOUR_DATA';

/********** THOUGHT ACTIONS *****************/
export const ADD_THOUGHTS = 'ADD_THOUGHTS';
export const CREATE_THOUGHT = 'CREATE_THOUGHT';
export const UPDATE_THOUGHT = 'UPDATE_THOUGHT';
export const LOADING_THOUGHTS_FOR_SECURITY = 'LOADING_THOUGHTS_FOR_SECURITY';

export const TRACK_SUGGESTED_THOUGHT = 'TRACK_SUGGESTED_THOUGHT';
export const ADD_EXCLUDE_THOUGHTS_SUGGESTIONS = 'ADD_EXCLUDE_THOUGHTS_SUGGESTIONS';

/********** USERS ACTIONS *****************/
export const ADD_USERS = 'ADD_USERS';
export const UPDATE_USER = 'UPDATE_USER';

export const REMOVE_CONNECTION = 'REMOVE_CONNECTION';
export const REMOVE_CONNECTION_FAILURE = 'REMOVE_CONNECTION_FAILURE';

export const ADD_CONNECTIONS = 'ADD_CONNECTIONS';
export const SEND_CONNECTION_REQUEST = 'SEND_CONNECTION_REQUEST';
export const CONNECTION_REQUEST_FAILED = 'CONNECTION_REQUEST_FAILED';
export const ACCEPT_CONNECTION_REQUEST = 'ACCEPT_CONNECTION_REQUEST';
export const ACCEPT_CONNECTION_REQUEST_FAILED = 'ACCEPT_CONNECTION_REQUEST_FAILED';
export const IGNORE_CONNECTION_REQUEST = 'IGNORE_CONNECTION_REQUEST';
export const IGNORE_CONNECTION_REQUEST_FAILED = 'IGNORE_CONNECTION_REQUEST_FAILED';

export const ADD_THOUGHT_LEADERS = 'ADD_THOUGHT_LEADERS';

export const ADD_CONNECTIONS_PAGE = 'ADD_CONNECTIONS_PAGE';
export const REMOVE_CONNECTIONS_PAGE = 'REMOVE_CONNECTIONS_PAGE';

export const ADD_PEOPLE_YOU_MAY_KNOW = 'ADD_PEOPLE_YOU_MAY_KNOW';
export const HIDE_CONNECTION_SUGGESTION = 'HIDE_CONNECTION_SUGGESTION';

export const QUERY_USERS = 'QUERY_USERS';
export const USERS_QUERY_RESULTS = 'USERS_QUERY_RESULTS';

export const ADD_USER_PORTFOLIO_DATA = 'ADD_USER_PORTFOLIO_DATA';
export const ADD_USER_EXTERNAL_POSITIONS_DATA = 'ADD_USER_EXTERNAL_POSITIONS_DATA';
export const ADD_TOP_CONNECTION_PERFORMANCES = 'ADD_TOP_CONNECTION_PERFORMANCES';

export const ADD_PENDING_CONNECTIONS = 'ADD_PENDING_CONNECTIONS';
export const REMOVE_PENDING_CONNECTIONS = 'REMOVE_PENDING_CONNECTIONS';

export const ADD_RECEIVED_CONNECTION_REQUEST = 'ADD_RECEIVED_CONNECTION_REQUEST';
export const REMOVE_RECEIVED_CONNECTION_REQUEST = 'REMOVE_RECEIVED_CONNECTION_REQUEST';

export const LOADING_PENDING_CONNECTIONS = 'LOADING_PENDING_CONNECTIONS';
export const COMPLETE_LOADING_PENDING_CONNECTIONS = 'COMPLETE_LOADING_PENDING_CONNECTIONS';

/********** VIEWPOINT ACTIONS *****************/
export const UPDATE_VIEWPOINT_DATA = 'UPDATE_VIEWPOINT_DATA';
export const VIEWPOINT_SUBSCRIBE_CANCELLED = 'VIEWPOINT_SUBSCRIBE_CANCELLED';
export const VIEWPOINT_SUBSCRIBE_COMPLETE = 'VIEWPOINT_SUBSCRIBE_COMPLETE';

/********** USER IDEA PERFORMANCE ACTIONS *****************/
export const LOADING_USERS_IDEA_PERFORMANCES = 'LOADING_USERS_IDEA_PERFORMANCES';
export const ADD_USERS_PERFORMANCES = 'ADD_USERS_PERFORMANCES';

/********** USER CONNECTIONS ACTIONS *****************/
export const LOADING_CURRENT_USER_CONNECTIONS = 'LOADING_CURRENT_USER_CONNECTIONS';
export const ADD_CURRENT_USER_CONNECTIONS = 'ADD_CURRENT_USER_CONNECTIONS';

export const LOADING_USER_CONNECTIONS = 'LOADING_USER_CONNECTIONS';
export const ADD_USER_CONNECTIONS = 'ADD_USER_CONNECTIONS';
export const UPDATE_USER_CONNECTIONS = 'UPDATE_USER_CONNECTIONS';

/********** USER MUTUAL CONNECTIONS ACTIONS *****************/
export const LOADING_USER_MUTUAL_CONNECTIONS = 'LOADING_USER_MUTUAL_CONNECTIONS';
export const ADD_USER_MUTUAL_CONNECTIONS = 'ADD_USER_MUTUAL_CONNECTIONS';

/*********  DEFAULT VALUES ***********/
export const defaultWellStartingValue = 8;

export const Messaging = {
  inviteFriends:
    "Successful investors know the value of debating ideas. For a limited time, invite a friend and you'll both get up to $1,000 to invest when they make a deposit.",
  fundingRewards: 'For a limited time, get a funding bonus (Up to $1,000) when you make your first deposit.',
};

export const Thresholds = {
  interest: 0.4,
  sentiment: 0.4,
  widthForOrderMobileView: 600,
  widthForOrderCompactView: 900,
};

export const Config = {
  // 976 is 991, 15px difference in the way screen width is calced in media queries vs js
  desktopMaxWidth: 976, // also used as VNav lock breakpoint
  commissionFee: 4.5,
  maxCharacterLimitForThought: 250,
};

export const ProgramNames = {
  subscription: 'Subscription',
};

export const LoadingPageNames = {
  thoughts: 'Pros/Cons',
};

export const DefaultLevels = {
  likeIdea: {
    interest: 0.75,
    sentiment: 1,
  },
  dislikeIdea: {
    interest: 0.75,
    sentiment: -1,
  },
  createIdea: {
    interest: 1,
  },
  dismissIdea: {
    interest: 0,
  },
  removeIdeaFromPile: {
    interest: 0.25,
  },
  extraSuggestionsBuffer: 10,
};

export const securityCardTabs = {
  thoughts: 'Pros/Cons',
  ideas: 'Projections',
  financials: 'Financials',
  headlines: 'News',
  compare: 'Compare',
};

export const tabIcons = {
  thoughts: 'fa-comments-o',
  ideas: 'fa-lightbulb-o',
  financials: 'fa-table',
  headlines: 'fa-rss',
  compare: 'fa-list',
};

export const convertLabelToDisplayName = (tab) => {
  const displayName = securityCardTabs[tab.toLowerCase()];
  if (!displayName) {
    console.error('Could not convert tab name to a display name.');
  }
  return displayName || 'N/A';
};

export const OrderOperations = {
  buy: { id: 0, name: 'buy', display: 'Buy' },
  buy_to_cover: { id: 0, name: 'buy', display: 'Buy' },
  sell: { id: 1, name: 'sell', display: 'Sell' },
  sell_short: { id: 1, name: 'sell', display: 'Sell' },
};

export const PlatformContextTypes = {
  WEB: 'web',
  IOS: 'ios',
  ANDROID: 'android',
};

export const OrderTypes = {
  market: {
    id: 0,
    name: 'market',
    displayName: 'Market',
  },
  limit: {
    id: 1,
    name: 'limit',
    displayName: 'Limit',
  },
  stop: {
    id: 2,
    name: 'stop',
    displayName: 'Stop',
  },
  stop_limit: {
    id: 3,
    name: 'stop_limit',
    displayName: 'Stop Limit',
  },
};
