import React from 'react';
import { connect } from 'react-redux';
import { runRebalancer } from '../../actions';

import IconButton from '../../components/buttons/IconButton';
import Button from '../../components/buttons/Button';

import { formatDataValue } from '../../helpers/securitiesHelpers';
import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import { FlatButton } from '../../main/components/buttons';

class RebalancerActions extends React.PureComponent {
  render() {
    const { currentTotalValue, minEquityToUse } = this.props;
    const userHasFunding = !isUndefinedOrNull(currentTotalValue) && currentTotalValue > 0;

    return (
      <div
        className={'optimizer-action-btn-container'}
        style={{
          paddingTop: '65px',
        }}
      >
        {null && (
          <div className={'refresh-btn-container icon-grey-color'}>
            <IconButton icon="fa-refresh" size="medium" background="rgba(0,0,0,0)" handleClick={this._runRebalancer} />
          </div>
        )}
        <div className={'create-optimized-orders-btn-container'}>
          <div className="wrapper">
            <FlatButton small onClick={this._handleCreateRebalancerOrders}>
              Next
            </FlatButton>
            {null && !userHasFunding && (
              <div className="react-info-icon">
                <span className={'info-icon-tooltip-container right-aligned'}>
                  <span className="info-icon-tooltip-container-arrow"></span>
                  <span className="info-icon-tooltip-content">
                    {`To Rebalance you need at least ${formatDataValue(
                      minEquityToUse,
                      'priceNearestDollar'
                    )} in your account.`}
                  </span>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  _handleCreateRebalancerOrders = () => {
    this.props.onCreateOrders();
  };

  _runRebalancer = () => {
    const { dispatch } = this.props;
    return runRebalancer()(dispatch);
  };
}

const mapStateToProps = (state) => {
  return {
    minEquityToUse: state.currentUser.min_equity_required_to_use_optimizer,
  };
};

export default connect(mapStateToProps)(RebalancerActions);
