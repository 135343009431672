import { throwError } from '../helpers/devToolHelpers';
import { slugifyName } from '../helpers/generalHelpers';
import { createQueryString } from '../helpers/routerHelpers';
import { webappRoutes } from '../main/lib/nvstr-utils.es';
import { DOMAIN } from '@src/appConfig';

const _paths = {
  root: () => '/',
  home: () => '/',
  faq: () => '/faq',
  dashboard: () => '/',
  dashboardPositions: () => '/?tab=positions',

  dashboardWithOptimizer: () => '/?auto_expand=optimizer',
  onboardingDashboardWithOptimizer: () => '/?queue_auto_expand=optimizer',

  dashboardWithRebalancer: () => '/?auto_expand=rebalancer',
  onboardingDashboardWithRebalancer: () => '/?queue_auto_expand=rebalancer',

  accountType: () => '/account_type',
  activity: () => '/notifications',
  connections: () => '/users/connections',
  feed: () => '/?tab=feed',
  funding: () => '/portfolio/funding',
  history: () => '/portfolio/history',
  investingExperience: () => '/investing_experience',
  importConnections: () => '/invites/provider',
  importResults: (network) => `/invites/${network}/contact_callback`,
  importResultsGmail: () => '/invites/gmail/contact_callback',
  importResultsFacebook: () => '/invites/facebook/contact_callback',
  notifications: () => '/notifications',
  onboardingStart: () => '/onboard',
  orders: () => '/orders',
  portfolioExplorer: () => '/ideas/portfolio_explorer',
  portfolioAnalysis: () => '/portfolio',
  positions: () => '/portfolio',
  profile: (userId) => `/users/${userId}/public_profile`,
  viewpoint_profile: (name) => `/viewpoint_contributors/${slugifyName(name)}`,
  referrals: () => '/referrals',
  registerLiveAccountFormStart: () => '/live_accounts/new',
  rewards: () => '/referrals',
  rewardRedemption: () => '/rewards_redemption',
  securitiesPage: (securityId, query) => `/securities/${securityId}${query || ''}`,
  trade: () => '/orders',
  referralRules: () => '/referral_rules',
  viewpointGuidelines: () => '/viewpoint_guidelines',

  subscriptions: () => '/subscriptions',
  membership: () => '/membership',

  get registerLiveAccount() {
    return this.accountType;
  },
};

export const STATIC_ROUTES = webappRoutes;

export const ROUTES = {
  SIGNUP: {
    build: ({ isLinkingPartnerAccount, email }) =>
      isLinkingPartnerAccount ? `/signup?linking=1&email=${email}` : '/signup',
  },
  DASHBOARD: { build: () => '/' },
  FINISH_ACCOUNT_SETUP: { build: () => '/finish-account-setup' },
  UPDATE_VERIFICATION_INFO: { build: () => '/upload-required-documents' },
  PORTFOLIO: { build: () => '/portfolio' },
  LINK_BROKERAGE: {
    build: (plaidAccountId) =>
      plaidAccountId ? `/link-brokerage-account?a${plaidAccountId}` : '/link-brokerage-account',
  },
  ONBOARDING_HOME: { build: () => '/get-started' },
  E_DOCS: { build: () => '/documents' },
  COVERED_CALL_WIZARD: { build: (securityId) => `/covered-call-wizard?securityId=${securityId}` },
  TRADE: { build: () => '/orders' },
  CREATE_OPTIMIZED_ORDER_CART: { build: () => '/orders?oo=1' },
  CREATE_ORDER_SECURITY_ID: {
    build: (type, id) => {
      let query = `?sid=${id}`;
      if (type) {
        query += `&tt=${type}`;
      }
      return '/order' + query;
    },
  },
  CREATE_ORDER_SYMBOL: {
    build: (type, symbol, sid) => {
      if (symbol === encodeURIComponent(symbol) && symbol.indexOf('.') < 0) {
        if (type) {
          return `/${type}/${symbol}`;
        }
        return `/order/${symbol}`;
      } else {
        let query = `?sid=${sid}`;
        if (type) {
          query += `&tt=${type}`;
        }
        return '/order' + query;
      }
    },
  },
  TRADE_OPTIONS: {
    build: (securityId, orderId, params) => {
      if (securityId) {
        return `/orders/options?securityId=${securityId}`;
      }
      if (orderId) {
        return `/orders/options?orderId=${orderId}`;
      }
      if (params) {
        const s = createQueryString(params);
        return `/orders/options${s}`;
      }
    },
  },
  LIVE_TRADING_SIGNUP_START: { build: () => '/live_accounts/new' },
  ONBOARD_OPTIONS_EXPERIENCE: { build: () => '/options-trading-experience' },
  PRIVACY_SELECTION: { build: () => '/privacy_settings' },
  REWARDS: { build: () => '/referrals' },
  REFERRAL_RULES: { build: () => `https://${DOMAIN}/referral_rules` },
  REWARD_REDEMPTION: { build: () => '/rewards_redemption' },
  REFER_FRIEND_ACTIONS: { build: () => '/referrals' },
  RECURRING_DEPOSITS: { build: () => '/funding/recurring-deposits' },
  DEPOSIT_FUNDS: { build: () => '/funding/deposit' },
  WITHDRAW_FUNDS: { build: () => '/funding/withdraw' },
  LINK_BANK: { build: () => '/funding/link-bank' },
  VERIFY_BANK_ACCOUNT: { build: () => '/funding/verify-bank-account' },
  LIVE_ACCOUNT_SUBMISSION_STATUS: { build: () => '/live-account-submission-status' },
  UPLOAD_REQUIRED_DOCUMENTS: { build: () => '/upload-required-documents' },
  RESUBMIT_ADDRESS: { build: () => '/resubmit-address' },
  CURRENT_STEP_ONBOARDING_FLOW: { build: () => '/onboard' }, // assumption, this URL will always determine and drop the user in current step of onboarding flow
  FUNDING: { build: () => '/funding' },
  FEATURED_VIEWPOINTS: { build: () => '/viewpoint_contributors/featured' },
  ALMOST_THERE: { build: () => '/almost-there' },
  EXPLORE_STOCKS: { build: () => '/ideas/explore' },
  VIEWPOINT_SUBSCRIPTIONS_MANAGEMENT: { build: () => '/viewpoint_contributors/manage' },
  VIEWPOINT_SUBSCRIPTIONS_PROFILE: { build: (name) => `/viewpoint_contributors/${slugifyName(name)}` },
  LEARN_AND_EARN: { build: (learnScreenLessonName) => `/learn-and-earn?lesson=${learnScreenLessonName}` },
  LEARN_AND_EARN_NEXT_LESSON: { build: () => `/learn-and-earn` },
  LEARN_AND_EARN_HOME: { build: () => `/learn-and-earn-home` },
  LEARN_AND_EARN_PROGRESS: { build: () => '/learn-and-earn-progress' },
  LEARN_AND_EARN_DISCLAIMERS_PUBLIC: {
    build: (learnScreenLessonName) => `/learn-and-earn-more-info-share?lesson=${learnScreenLessonName}`,
  },
  LEARN_AND_EARN_DISCLAIMERS: {
    build: (learnScreenLessonName) => `/learn-and-earn-more-info?lesson=${learnScreenLessonName}`,
  },
};

export const returnPathTo = (page, pathParam, pathAdditionalParam) => {
  const getPath = _paths[page];
  if (!getPath) {
    throwError(`Path to page not found, received ${page}`);
  }
  return getPath(pathParam, pathAdditionalParam);
};
