import React from 'react';
import { useDispatch } from 'react-redux';
import { listUsStateNames } from '../../helpers/formHelpers';
import { useCurrentUser } from '../../main/hooks/user';
import { showModal, updateUserProperty } from '../../actions/index';
import { Container } from '../../main/components/ui';
import styled from 'styled-components';
import { createDropdownItem, FloatingLabelDropdown, TextInput } from '../../main/lib/nvstr-common-ui.es';
import { FormState } from '../../main/components/form/FormState';
import { FlatButton } from '../../main/components/buttons';

const Label = styled.div`
  padding-bottom: 8px;
`;

const FormWrapper = styled.div`
  max-width: 500px;
  margin: 16px auto 0 auto;

  input {
    border: 0px;
    border-bottom: 1px solid ${({ theme }) => theme.themeColors.text};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    width: 100%;
    border-radius: 0;

    padding: 8px;
    outline: none;
  }

  select {
    border: 0px;
    border-bottom: 1px solid ${({ theme }) => theme.themeColors.text};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 0;
    width: 100%;
    padding: 8px;
    -webkit-appearance: auto !important;
    outline: none;
  }

  textarea {
    border: 0px;
    border-bottom: 1px solid ${({ theme }) => theme.themeColors.text};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 0;
    min-height: 100px;
    width: 100%;
    padding: 16px;
    outline: none;
  }
`;

const stateDropdownItems = [
  createDropdownItem({ text: '--', value: '', disabled: true }),
  ...listUsStateNames().map((s) => createDropdownItem({ text: s, value: s })),
];

const initialState = {
  values: {},
  errors: {},
};

const actions = { update: 'u' };
function reducer(state, action) {
  switch (action.type) {
    case actions.update: {
      const { name, value } = action.payload;
      return {
        ...state,
        values: {
          ...state.values,
          [name]: value,
        },
      };
    }

    default: {
      return state;
    }
  }
}

const initState = (currentUser) => {
  const formData = {
    first_name: currentUser.first_name,
    last_name: currentUser.last_name,
    city: currentUser.city,
    state: currentUser.state,
    bio: currentUser.bio,
  };
  return { values: formData, errors: {} };
};

function extractChangedValues(values, currentUser) {
  const fields = Object.keys(values);
  const changes = {};
  fields.forEach((f) => {
    const formValue = values[f];
    const savedValue = currentUser[f];
    if (formValue !== savedValue) {
      changes[f] = formValue;
    }
  });
  if (Object.keys(changes).length === 0) return null;
  return changes;
}

const EditProfileForm = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const [state, localDispatch] = React.useReducer(reducer, initialState, () => initState(currentUser));
  const [isSaving, setIsSaving] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const { values, errors } = state;
  // focusedField: null,

  const handleChange = (name) => (value) => {
    if (['first_name', 'last_name'].includes(name)) {
      const restrictedChars = ['.', '@'];
      if (value.split('').some((c) => restrictedChars.includes(c))) {
        return;
      }
    }
    localDispatch({
      type: actions.update,
      payload: {
        name,
        value,
      },
    });
  };

  const handleTextAreaChange = (e) => {
    const { name, value } = e.target;
    if (['first_name', 'last_name'].includes(name)) {
      const restrictedChars = ['.', '@'];
      if (value.split('').some((c) => restrictedChars.includes(c))) {
        return;
      }
    }
    localDispatch({
      type: actions.update,
      payload: {
        name,
        value,
      },
    });
  };

  const handleSubmit = async () => {
    const changedValues = extractChangedValues(values, currentUser);
    if (changedValues === null) {
      setFormError('No changes to save');
      return;
    }
    setFormError(null);
    setIsSaving(true);
    const wasSuccess = await updateUserProperty(changedValues)(dispatch);
    setIsSaving(false);
    if (!wasSuccess) {
      const message = 'Unable to save. Please reload page and try again.';
      const contentComponent = (
        <div className="modal-message" style={{ paddingTop: '0px' }}>
          {message}
        </div>
      );
      showModal({
        contentComponent,
        size: 'wide',
        dismissable: true,
      })(dispatch);
    }
  };

  return (
    <Container className={`edit-profile-form-container`}>
      <FormWrapper>
        <Container>
          <TextInput
            label="First Name"
            name="first_name"
            onChange={handleChange}
            value={values.first_name}
            error={errors.first_name}
          />
        </Container>
        <Container top={16}>
          <TextInput
            label="Last Name"
            name="last_name"
            onChange={handleChange}
            value={values.last_name}
            error={errors.last_name}
          />
        </Container>

        <Container top={16}>
          <TextInput label="City" name="city" onChange={handleChange} value={values.city} error={errors.city} />
        </Container>
        <Container top={16}>
          <FloatingLabelDropdown
            name={'state'}
            value={values.state}
            label={'State'}
            items={stateDropdownItems}
            onChange={handleChange}
            error={errors.state || null}
          />
        </Container>

        <Container top={16}>
          <Label>Bio</Label>
          <textarea name="bio" onChange={handleTextAreaChange} value={values.bio} />
          {errors.bio && <div>{errors.bio}</div>}
        </Container>

        <Container top={24}>
          <Container centerAll>
            <FormState error={formError} isSubmitting={isSaving} />
          </Container>
          <Container centerAll>
            <FlatButton fullWidth onClick={handleSubmit}>
              Save
            </FlatButton>
          </Container>
        </Container>
      </FormWrapper>
    </Container>
  );
};

export default EditProfileForm;
