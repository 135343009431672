import React from 'react';
import { Body5 } from '../../main/lib/nvstr-common-ui.es';

const FundingBonusBanner = ({ color }) => {
  return (
    <div>
      <Body5 color={color}>
        For a limited time, get a funding bonus (Up to $1,000) when you make your first deposit
      </Body5>
    </div>
  );
};

export default FundingBonusBanner;
