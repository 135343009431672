import React from 'react';

export const Plus = ({ thick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      {thick ? (
        <path d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" />
      ) : (
        <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
      )}
    </svg>
  );
};
