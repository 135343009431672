import { importConnectionsAdapter } from '../adapters/importConnectionsAdapter';

import {
  ADD_IMPORT_CONNECTIONS_PAGE,
  REMOVE_IMPORT_CONNECTIONS_PAGE,
  LOADING_IMPORT_CONNECTIONS_PAGE,
  LOADING_IMPORT_NETWORKS,
  ADD_IMPORT_NETWORKS,
  LOADING_IMPORT_CONTACTS,
  ADD_IMPORT_CONTACTS,
  ADD_USERS,
  SHOW_MODAL,
} from '../constants';
import { returnBasicSomethingWentWrongErrorModalPayload } from '../constants/modals';

export const getImportConnectionsPage = () => {
  return function (dispatch) {
    dispatch({
      type: LOADING_IMPORT_CONNECTIONS_PAGE,
    });

    return importConnectionsAdapter.getImportConnectionsPage().then((response) => {
      const wasSuccess = response && response.data && response.data.partial;
      if (wasSuccess) {
        const partial = response && response.data && response.data.partial;
        dispatch({
          type: ADD_IMPORT_CONNECTIONS_PAGE,
          payload: { partial },
        });
      } else {
        dispatch({
          type: SHOW_MODAL,
          payload: returnBasicSomethingWentWrongErrorModalPayload(),
        });
      }
      return response;
    });
  };
};

export const removeImportConnectionsPage = () => {
  return function (dispatch) {
    dispatch({
      type: REMOVE_IMPORT_CONNECTIONS_PAGE,
    });
  };
};

export const getImportConnectionsNetworks = () => {
  return function (dispatch) {
    dispatch({
      type: LOADING_IMPORT_NETWORKS,
    });

    return importConnectionsAdapter.getImportConnectionsNetworks().then((response) => {
      const wasSuccess = response && response.data && response.data.networks;
      if (wasSuccess) {
        const networks = response.data.networks;
        dispatch({
          type: ADD_IMPORT_NETWORKS,
          payload: { networks },
        });
      } else {
        dispatch({
          type: SHOW_MODAL,
          payload: returnBasicSomethingWentWrongErrorModalPayload(),
        });
      }
      return response;
    });
  };
};

export const getImportContacts = () => {
  return function (dispatch) {
    dispatch({
      type: LOADING_IMPORT_CONTACTS,
    });

    return importConnectionsAdapter.getImportContacts().then((response) => {
      const wasSuccess = response && response.data;
      if (wasSuccess) {
        const contacts = response && response.data;
        const registeredUsers = contacts.users;
        const nonregisteredUsers = contacts.contacts;

        const users = registeredUsers;
        dispatch({
          type: ADD_USERS,
          payload: { users },
        });

        dispatch({
          type: ADD_IMPORT_CONTACTS,
          payload: {
            registeredUsers,
            nonregisteredUsers,
          },
        });
      } else {
        dispatch({
          type: SHOW_MODAL,
          payload: returnBasicSomethingWentWrongErrorModalPayload(),
        });
      }
      return response;
    });
  };
};
