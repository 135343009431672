import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const portfolioAdapter = {
  // this is currently just returned with the current user object
  // fetchCurrentUserPortfolio: userId => {
  //   return sendRequest( 'get', [ `${URL}/connections?include_portfolio=true` ] )
  //     .then( response => response )
  // },

  fetchUserPortfolio: (userId) => {
    return sendRequest('get', [`${URL}/users?user_ids=${userId}&include_portfolio=true&include_performance=true`]).then(
      (response) => response
    );
  },
};
