import React from 'react';

import styled from 'styled-components';
import useWindowSize from '../../hooks/util/useWindowSize';

const Wrapper = styled.div`
  position: relative;
  text-align: left;
  cursor: default;

  min-height: ${({ minHeight }) => minHeight || 'unset'};
  max-height: ${({ maxHeight }) => maxHeight || 'unset'};
  background: ${({ theme }) => theme.themeColors.appBackground};

  display: flex;

  ${({ centerContent }) =>
    centerContent
      ? `
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  `
      : ''}

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    min-height: ${({ height }) => height || '100vh'};
    max-width: 100%;
    width: 100%;
  }
`;

const ExpandedPageBackground = ({ children, centerContent, maxHeight }) => {
  const { height, width } = useWindowSize({ minusHeaderNavbar: true });
  return (
    <Wrapper centerContent={centerContent} minHeight={`${height || 0}px`} maxHeight={maxHeight}>
      {children}
    </Wrapper>
  );
};

export default ExpandedPageBackground;
