import React, { useState } from 'react';
import TransactionStatus from '../TransactionComponents/TransactionStatus';
import TransactionAmount from '../TransactionComponents/TransactionAmount';
import Button from '../../../../components/buttons/Button';
import LoadingIcon from '../../../../components/misc/LoadingIcon';
import { FlatButton } from '../../../../main/components/buttons';

const FundingTransaction = (props) => {
  const {
    className,

    account_name,
    amount,
    status,
    type,
    can_be_canceled,
    is_void,

    funding_transfer_id,
    time,
  } = props;

  const [isCanceling, setIsCanceling] = useState(false);

  const handleCancelClick = () => {
    setIsCanceling(true);
    props.handleCancelClick(funding_transfer_id).then((response) => {
      setIsCanceling(false);
    });
  };

  const isBeingCanceled = isCanceling && can_be_canceled;
  const isCancelable = can_be_canceled;
  return (
    <li
      className={`
        funding-transaction-item
        ${className || ''}
      `}
    >
      {isBeingCanceled && (
        <div className={`canceling-overlay`}>
          <LoadingIcon />
          <span className={`text`}>Canceling</span>
        </div>
      )}
      <div className={`funding-transaction-details ${isBeingCanceled ? 'is-canceling' : ''}`}>
        <span className={`transaction-identifier`}>
          <div className={`transaction-type`}>{type}</div>
          <div className={`transaction-sub-type transaction-bank-account secondary-text-color`}>{account_name}</div>
        </span>
        <span className={`transaction-status-container`}>
          <TransactionAmount className={`transaction-amount`} isVoid={is_void} value={amount} />
          <TransactionStatus className={``} value={status} />
          {isCancelable && (
            <span className={`cancel-transaction-action`}>
              <FlatButton transparent onClick={handleCancelClick}>
                Cancel
              </FlatButton>
            </span>
          )}
        </span>
      </div>
    </li>
  );
};

export default FundingTransaction;
