import { connect } from 'react-redux';

const ThoughtData = (props) => {
  const {
    thought,

    children,
  } = props;

  return children(thought);
};

const mapStateToProps = (state, ownProps) => {
  const { securityId, thoughtId } = ownProps;

  const thoughtsStore = state.thoughts;
  const securityLookup = thoughtsStore.securityLookup;
  const securityThoughtsLookup = securityLookup[securityId] && securityLookup[securityId].thoughtLookup;
  if (!securityThoughtsLookup) {
    return {
      thought: null,
    };
  }
  const thought = securityThoughtsLookup[thoughtId];
  return {
    thought,
  };
};

export default connect(mapStateToProps)(ThoughtData);
