import { membershipActionTypes } from '../constants';

const defaultState = {
  isLoaded: false,

  isEligible: null,
  isEnrolled: null,

  annualFee: null,
};

export default function membershipReducer(state = defaultState, action) {
  switch (action.type) {
    case membershipActionTypes.update:
      return {
        isLoaded: true,
        ...action.payload,
      };

    default:
      return state;
  }
}
