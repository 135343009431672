import { FETCH_BANNER, SHOW_BANNER, DISMISS_BANNER } from '../constants';

const defaultState = {
  show: false,
  text: null,
  linkTo: null,
  linkText: null,
  message: null,
};

export default function bannerReducer(state = defaultState, action) {
  switch (action.type) {
    case SHOW_BANNER:
      //TODO: this sort of logic should not be in the reducer, clean this up
      const banner = action.payload.banner;
      const show = !!banner.text;
      const bannerProps = {
        message: banner.text,
        linkTo: banner.link,
        linkText: banner.link_text,
        status: banner.status,
      };
      return {
        ...state,
        show,
        ...bannerProps,
      };

    case DISMISS_BANNER:
      return { ...state, show: false };

    default:
      return state;
  }
}
