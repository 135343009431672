import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { logMetricsTrackingEvent, openOptimizerPanel, showModal } from '../../../actions';
import HighlightComponent from '../../UI/Tour/HighlightComponent';
import { doesCurrentUserHaveActiveIdeas } from '../../../helpers/currentUserHelpers';
import { useCurrentUser } from '../../../main/hooks/user';
import { FlatButton } from '../../../main/components/buttons';
import { Container } from '../../../main/components/ui';
import { useEquityValue } from '../../../main/hooks/portfolio/useEquityData';

const OptimizeArrow = () => {
  return (
    <svg viewBox="0 0 20 20">
      <path d="M17.997 5.6c-.028-.333-.345-.607-.693-.6h-2.782c-1.391 0-2.087 1.334-2.087 1.334h3.19l-4.58 4.39-2.293-2.197a.718.718 0 00-.984 0l-5.565 5.335a.649.649 0 000 .943c.272.26.713.26.985 0l5.074-4.864 2.292 2.197a.705.705 0 00.49.197c.185 0 .362-.07.49-.197l5.075-4.86v3.056S18 9.668 18 8.334V5.667c0-.023 0-.043-.003-.067z" />
    </svg>
  );
};

const _logNavOptimizerButtonClick = () => {
  const event = 'Clicked Optimizer Button';
  const properties = {
    Context: 'In Nav',
  };
  logMetricsTrackingEvent(event, properties)();
};

const _logViewOptimizerButtonClick = () => {
  const event = 'Clicked Optimizer Button';
  const properties = {
    Context: 'In Dashboard',
  };
  logMetricsTrackingEvent(event, properties)();
};

const ButtonContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const OptimizerButtonWrapper = styled.div`
  position: relative;
  button {
    height: 36px;
    padding: 2px 8px;

    * {
      color: ${({ theme }) => theme.themeColors.buttonText};
    }
  }
`;
const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 8px;

  svg {
    height: 24px;
    width: 24px;
    fill: ${({ theme }) => theme.themeColors.buttonText};
  }
`;
const TopLineText = styled.div`
  text-align: left;
  font-size: 8px;
  line-height: 12px;
`;
const BotLineText = styled.div`
  text-align: left;
  font-size: 11px;
  line-height: 1;
`;

const NewOptimizerButton = ({ viewOptimizerMessage, inVerticalNav, closeNav }) => {
  const dispatch = useDispatch();

  const currentUser = useCurrentUser();
  const optimizer = useSelector((state) => state.optimizer);
  const ideas = useSelector((state) => state.ideas);

  const totalInvestmentsValue = useEquityValue();

  const hasActiveIdeas = doesCurrentUserHaveActiveIdeas(currentUser.user_id, ideas);

  const generateButtonTopLineText = () => {
    const cachedTopText = 'Click to';
    const message = optimizer.data && optimizer.data.summary_message;
    const topMessage =
      message &&
      message
        .split(' ')
        .slice(0, 2)
        .join(' ');

    if (optimizer.isOptimizerRunning) {
      return 'Optimizer is';
    }

    if (!hasActiveIdeas && !ideas.loadingCurrentUserIdeas) {
      return 'To Optimize';
    } else if (!currentUser || totalInvestmentsValue >= 5) {
      return topMessage || cachedTopText || 'Click to';
    } else if (totalInvestmentsValue < 5) {
      return 'To Optimize';
    } else {
      return 'Click to';
    }
  };

  const generateButtonBottomLineText = () => {
    const cachedBottomText = 'Run Optimizer';
    const message = optimizer.data && optimizer.data.summary_message;
    const bottomMessage =
      message &&
      message
        .split(' ')
        .slice(2, message.length)
        .join(' ');

    if (optimizer.isOptimizerRunning) {
      return 'Analyzing Portfolio';
    }

    if (!hasActiveIdeas && !ideas.loadingCurrentUserIdeas) {
      return 'Add Ideas';
    } else if (!currentUser || totalInvestmentsValue >= 5) {
      return bottomMessage || cachedBottomText || 'Run Optimizer';
    } else if (totalInvestmentsValue < 5) {
      return 'Add Funding';
    } else {
      return 'Run Optimizer';
    }
  };

  const createOptimizerButtonFailureMessage = () => {
    const generalComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {'Could not run the optimizer at this time. Please try again later.'}
      </div>
    );
    showModal({
      contentComponent: generalComponent,
      dismissable: true,
    })(dispatch);
  };

  const _logClickOptimizerButton = () => {
    viewOptimizerMessage ? _logViewOptimizerButtonClick() : _logNavOptimizerButtonClick();
  };

  const handleClick = () => {
    inVerticalNav && closeNav();

    _logClickOptimizerButton();

    if (!hasActiveIdeas) {
      if (!ideas.loadingCurrentUserIdeas) {
        const route = '/ideas/portfolio_explorer';
        window.location = route;
        return true;
      }
      return false;
    }

    // HACK: To stop body from becoming scrollable when panel is open because closing nav makes it scrollable.
    // This can be implemented better when on only React frontend
    setTimeout(() => {
      openOptimizerPanel()(dispatch);
    }, 150);
  };

  return null;

  if (viewOptimizerMessage)
    return (
      <OptimizerButtonWrapper>
        <HighlightComponent tourStep={5} />

        <FlatButton onClick={handleClick}>
          <ButtonContentWrapper>
            <ArrowWrapper>
              <OptimizeArrow />
            </ArrowWrapper>
            <Container>
              <TopLineText>View</TopLineText>
              <BotLineText>Optimizer</BotLineText>
            </Container>
          </ButtonContentWrapper>
        </FlatButton>
      </OptimizerButtonWrapper>
    );

  const optimizerTopLine = generateButtonTopLineText();
  const optimizerBottomLine = generateButtonBottomLineText();

  return (
    <OptimizerButtonWrapper>
      <HighlightComponent tourStep={5} />

      <FlatButton onClick={handleClick} id="optimize_button">
        <ButtonContentWrapper>
          <ArrowWrapper>
            <OptimizeArrow />
          </ArrowWrapper>
          <Container>
            <TopLineText>{optimizerTopLine}</TopLineText>
            <BotLineText>{optimizerBottomLine}</BotLineText>
          </Container>
        </ButtonContentWrapper>
      </FlatButton>
    </OptimizerButtonWrapper>
  );
};

export default NewOptimizerButton;
