import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { parseQueryString } from '@src/main/lib/nvstr-utils.es';

export const useExtendedSearchParams = () => {
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const extendedSetSearchParams = (params, shouldClearCurrentParams) => {
    if (shouldClearCurrentParams) {
      setSearchParams(params);
    }

    const query = parseQueryString(search);
    setSearchParams({
      ...query,
      ...params,
    });
  };
  return [searchParams, extendedSetSearchParams];
};
