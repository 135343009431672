import React from 'react';
import { connect } from 'react-redux';

import { getViewpointOrgDataFromState } from '../../../selectors/viewpoints';

import MonthlyFee from './MonthlyFee';

import {
  canResubscribeToViewpointOrg,
  didCancelViewpointOrgSubscription,
  getDescriptionFromViewpointOrg,
  isSubscribedToViewpointOrg,
} from '../../../helpers/viewpointHelpers';

import { UserAvatarAndName } from '../../UI/User/UserAvatarAndName';
import MoreActionsButton from '../../buttons/MoreActions';
import ClickEventComponent from '../../UI/EventActionWrappers/ClickEventComponent';
import { cancelViewpointSubscription, hideModal, showModal } from '../../../actions';
import { createBasicErrorModal } from '../../../constants/modals';
import StatusModal from '../../../components/modals/StatusModal';
import ViewpointData from '../ViewpointData';
import { PROFILE_CONTEXTS } from '../../../constants/contextTracking';
import { ROUTES } from '../../../constants/paths';
import { withRouter } from '../../../main/utils';
import { Container } from '../../../main/components/ui';

class ViewpointSubscriptionCard extends React.PureComponent {
  render() {
    const {
      id,
      style,

      viewpointOrg,

      hideActions,
      showBio,
    } = this.props;

    if (viewpointOrg === null) return null;

    if (canResubscribeToViewpointOrg(viewpointOrg)) return null;

    const dropdownOptions = [
      {
        elementType: ClickEventComponent,
        elementTypeString: 'ClickEventComponent',
        props: {
          id: `additional-action-connection-row-${id}`,
          onClickFunc: this._handleCancelSubscription,
          shouldTriggerDropdownClose: true,
        },
        children: (
          <div className="user-fontSettings-nav-item is-updating connection-user-row-more-actions selectable-item">
            <Container all={8} centerAll>
              Cancel Subscription
            </Container>
          </div>
        ),
      },
    ];

    return (
      <div
        className={'border-accent'}
        style={{
          borderWidth: '1px',
          borderStyle: 'solid',
          padding: '15px',
          borderRadius: '4px',
        }}
      >
        <a
          className={'flex-center-space-between'}
          style={{ cursor: 'pointer' }}
          onClick={() => this.props.navigate(ROUTES.VIEWPOINT_SUBSCRIPTIONS_PROFILE.build(viewpointOrg.name))}
        >
          <UserAvatarAndName
            profileLinkQuery={`?context=${PROFILE_CONTEXTS.VIEWPOINT_SUBSCRIPTIONS_LIST}`}
            avatarSize={'nav'}
            viewpointOrgId={id}
            isViewpointOrg
          />

          {isSubscribedToViewpointOrg(viewpointOrg) && !hideActions ? (
            <div style={{ paddingBottom: 5 }}>
              <MoreActionsButton
                dropdownId={`connection-more-action-button-dropdown-${id}`}
                dropdownChildrenDataList={dropdownOptions}
                eventContext={'Viewpoint Subscription Row'}
              />
            </div>
          ) : null}
        </a>
        <div style={{ marginTop: '15px' }}>
          {showBio ? (
            <ViewpointData id={id}>
              {(id, avatarImgSource, username, userInitials, isLoading, displayName, secondaryDisplayName, org) => (
                <span className={'secondary-text-color'}>{getDescriptionFromViewpointOrg(org)}</span>
              )}
            </ViewpointData>
          ) : didCancelViewpointOrgSubscription(viewpointOrg) ? (
            <span className={'secondary-text-color'}>{'Canceled'}</span>
          ) : (
            <MonthlyFee org={viewpointOrg} />
          )}
        </div>
      </div>
    );
  }

  _handleCancelSubscription = () => {
    const { dispatch } = this.props;

    this.setState(() => ({
      isCancelling: true,
    }));

    const statusModal = {
      contentComponent: <StatusModal text={'Canceling'} />,
      dismissable: false,
    };
    showModal(statusModal)(dispatch);

    setTimeout(() => this._cancelSubscription(), 500);
  };

  _cancelSubscription = () => {
    const { id, dispatch } = this.props;
    return cancelViewpointSubscription(id)(dispatch).then((response) => {
      const { ok, errorMessage } = response;

      if (ok) {
        hideModal()(dispatch);
      } else {
        const errorModal = {
          contentComponent: createBasicErrorModal(errorMessage || 'Something went wrong. Please try again.'),
          dismissable: true,
        };
        showModal(errorModal)(dispatch);

        this.setState(() => ({
          isCancelling: false,
        }));
      }
    });
  };
}

ViewpointSubscriptionCard.defaultProps = {
  style: {},
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  return {
    viewpointOrg: getViewpointOrgDataFromState(state, id),
  };
};

export default connect(mapStateToProps)(withRouter(ViewpointSubscriptionCard));
