import React from 'react';

export const MoneyField = (props) => {
  const name = props.name;
  const label = props.label;
  const value = props.getValue(name) || '';
  const styling = props.styling || '';
  const overwriteClassNames = props.overwriteClassNames;
  const inverted = props.inverted;

  const isError = !!props.getErrorMessage(name);
  const errorMessage = isError ? props.getErrorMessage(name) : '';

  const isFocused = props.isFocused && props.isFocused(name);
  const focusedOrValue = (!!value && value.length > 0) || isFocused;

  const showFocusNote = !!props.focusNote && isFocused;
  const focusNote = props.getFocusNote && props.getFocusNote(name);

  const handleChange = (e, name) => {
    props.overrideChange
      ? props.overrideChange(e, name)
      : props.handleCurrencyChange
      ? props.handleCurrencyChange(e, name)
      : props.handleChange(e, name);
  };

  const centsOnly = props.currencyModifier === 'cents';

  if (inverted) {
    return (
      <div className={overwriteClassNames ? overwriteClassNames : 'floating-label-text-field'}>
        <fieldset className={`text-field ${props.size === 'small' ? 'field-small' : ''} `}>
          <label htmlFor={label} className={focusedOrValue ? 'text-field-lifted-label' : ''}>
            {label}
          </label>

          <div className="parent-wrapper">
            <input
              type="text"
              className={`currency-input ${centsOnly ? 'cents-only' : ''}`}
              name={name}
              onFocus={() => {
                props.handleFocus && props.handleFocus(name);
              }}
              onBlur={() => {
                props.handleBlur && props.handleBlur(value, name);
              }}
              onChange={(e) => {
                handleChange(e, name);
              }}
              value={value}
            />
            {focusedOrValue && <div className="input-currency-prefix">{centsOnly ? '$0.' : '$'}</div>}
          </div>

          <div className={`after ${isError && !isFocused ? 'error-underline' : ''}`}></div>

          <p className={`text-field-error-message`}>{errorMessage}</p>
        </fieldset>
      </div>
    );
  }

  return (
    <div className={overwriteClassNames ? overwriteClassNames : 'floating-label-text-field'}>
      <fieldset className={`text-field ${props.size === 'small' ? 'field-small' : ''} `}>
        {showFocusNote && <div className="focus-note eye-catching-bg">{focusNote}</div>}

        <div className="parent-wrapper">
          <input
            type="text"
            className={`currency-input ${centsOnly ? 'cents-only' : ''}`}
            name={name}
            onFocus={() => {
              props.handleFocus && props.handleFocus(name);
            }}
            onBlur={() => {
              props.handleBlur && props.handleBlur(value, name);
            }}
            onChange={(e) => {
              handleChange(e, name);
            }}
            value={value}
          />
          {focusedOrValue && <div className="input-currency-prefix">{centsOnly ? '$0.' : '$'}</div>}
        </div>

        <label htmlFor={label} className={focusedOrValue ? 'text-field-lifted-label' : ''}>
          {label}
        </label>

        <div className={`after ${isError && !isFocused ? 'error-underline' : ''}`}></div>

        <p className={`text-field-error-message`}>{errorMessage}</p>
      </fieldset>
    </div>
  );
};

export default MoneyField;
