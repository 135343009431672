import React from 'react';
import Transaction from './Transaction';

const TransactionsList = (props) => {
  const { transactions, actions } = props;
  const transactionActions = actions || {};
  return (
    <ul className={`transactions-list-container`}>
      {transactions.map((transactionData, i) => (
        <Transaction key={i} {...transactionData} {...transactionActions} />
      ))}
    </ul>
  );
};

export default TransactionsList;
