import React from 'react';
import { connect } from 'react-redux';

import { getCurrentUserFromState } from '../selectors/currentUser';
import { getAskInNavTestGroupFromCurrentUser } from '../helpers/currentUserHelpers';
import { logMetricsTrackingEvent } from '../actions';

import Page from '../components/layout/Page';
import { PageHeading } from '../components/UI/PageHeading';
import SearchContainer from '../containers/UI/Search/SearchContainer';
import AskAnyone from '../containers/buttons/AskAnyone';
import Button from '../components/buttons/Button';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import { isUndefinedOrNull } from '../helpers/generalHelpers';
import { FlatButton } from '../main/components/buttons';

class AskPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _securityId: null,
      // _showAskAgain: false,
    };
  }

  componentDidMount() {
    document.title = `${PRODUCT_DISPLAY_NAME} - Ask a Community Thought Leader`;
    this._logPageView();
  }

  render() {
    return (
      <div className="ask-page-container">
        <div className="ask-page-ctl-explanation">
          <p>
            <span className="bold  " style={{ fontSize: '16px' }}>
              Community Thought Leaders (CTL)
            </span>
            <span className=" ">
              {' '}
              are select members of the {PRODUCT_DISPLAY_NAME} community who are compensated for their contributions.
            </span>
          </p>
          <p>
            <span className=" ">
              They include former Wall Street pros - for example, one spent 10+ years at a $2 billion fund. Another was
              formerly an Institutional Investor-ranked analyst at Citigroup and Barclays.
            </span>
          </p>
          <p>
            <a href="/faq?section=community-thought-leaders" target="_blank">
              See here
            </a>
            <span className=" "> for details, including how you can become one.</span>
          </p>
        </div>
        <Page className={`ask-page`}>
          {this.renderPickAStock()}
          {this.renderAskAboutStock()}
        </Page>
      </div>
    );
  }

  getSecurityIdForAsk = () => this.state._securityId;

  renderPickAStock = () => {
    if (!isUndefinedOrNull(this.getSecurityIdForAsk())) {
      return null;
    }
    return (
      <>
        <PageHeading className=" ">Pick a stock to ask about</PageHeading>
        <div style={{ maxWidth: '400px', margin: '0 auto', padding: '25px 0 0 0' }}>
          <SearchContainer
            id="ask_ctl_stock_search"
            icon="fa-search"
            parentComponent="Ask CTL"
            placeholder="Search Stocks..."
            handleSecurityQuerySelection={this.handleStockSelection}
          />
        </div>
      </>
    );
  };

  renderAskAboutStock = () => {
    if (isUndefinedOrNull(this.getSecurityIdForAsk())) {
      return null;
    }
    return (
      <>
        <PageHeading className=" ">Enter your question</PageHeading>
        <div style={{ padding: '10px 0', textAlign: 'center' }}>
          <FlatButton transparent onClick={this.handleChangeSelectedStock}>
            {'Choose a different stock'}
          </FlatButton>
        </div>
        <div className={'ask-in-page-container'}>
          <AskAnyone
            containerClass="ask-ctl-share-button"
            viewingContext={`Ask CTL Page`}
            securityId={this.getSecurityIdForAsk()}
            handleAfterAction={this.handleAskAnyoneCompleted}
            onCancel={this.handleAskAnyoneCancel}
            defaultAskingCTL
            openOnMount
            hideButton
          />
        </div>
      </>
    );
  };

  handleStockSelection = (item) => {
    const { id } = item;
    this.setState(() => ({
      _securityId: id,
    }));
    this._logAskStockSelection(id);
  };

  handleAskAnyoneCompleted = () => {
    // this.setState(() => ({
    //   _showAskAgain: true,
    // }))
    setTimeout(() => {
      this.setState(() => ({
        _securityId: null,
      }));
    }, 1900);
  };

  handleChangeSelectedStock = () => {
    this.handleAskAnyoneCancel();
    this._logAskStockChange();
  };

  handleAskAnyoneCancel = () => {
    this.setState(() => ({
      _securityId: null,
    }));
  };

  _logPageView = () => {
    const event = 'View Ask A CTL Page';
    const properties = {
      'Test Group': this.props.testGroup,
    };
    logMetricsTrackingEvent(event, properties)();
  };

  _logAskStockSelection = (id) => {
    const event = 'Clicked Stock Search Selection';
    const properties = {
      Component: 'Ask Page',
      'Test Group': this.props.testGroup,
      'Security ID': id,
    };
    logMetricsTrackingEvent(event, properties)();
  };

  _logAskStockChange = () => {
    const event = 'Clicked Change Asking Stock';
    const properties = {
      'Test Group': this.props.testGroup,
    };
    logMetricsTrackingEvent(event, properties)();
  };
}

const mapStateToProps = (state) => {
  const currentUser = getCurrentUserFromState(state);
  return {
    testGroup: getAskInNavTestGroupFromCurrentUser(currentUser),
  };
};

export default connect(mapStateToProps)(AskPage);
