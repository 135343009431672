import React from 'react';
import { returnPathTo } from '../../constants/paths';

import Button from '../../components/buttons/Button';
import LinkTo from '../Links/LinkTo';

import { logMetricsTrackingEvent } from '../../actions/trackingActions';
import styled from 'styled-components';
import { FlatButton } from '@src/main/components/buttons';
import { useNavigate } from 'react-router';

const ButtonWrapper = styled.div`
  .btn-large {
    margin-top: 1px;
    padding: 13px 20px !important;
  }
`;

const ImportConnections = (props) => {
  const navigate = useNavigate();

  const _logImportClick = () => {
    const event = 'Clicked Import Connections';
    const properties = {
      Context: 'Connections Page',
    };
    logMetricsTrackingEvent(event, properties)();
  };

  const handleClick = () => {
    _logImportClick();
    navigate(returnPathTo('importConnections'));
  };

  return (
    <div className={`connection-cell`}>
      <h5>Find Friends</h5>

      <ButtonWrapper>
        <FlatButton fullWidth onClick={handleClick}>
          Import Connections
        </FlatButton>
      </ButtonWrapper>
    </div>
  );
};

export default ImportConnections;
