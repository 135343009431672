import React, { Component } from 'react';
import Button from '../buttons/Button';
import IconButton from '../buttons/IconButton';

export class TruncateContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleClick = () => {
    this.props.handleClick();
    this.setState((prevState) => {
      return {
        isOpen: !prevState.isOpen,
      };
    });
  };

  truncateText = () => {
    const { text, limit } = this.props;
    return text.slice(0, limit);
  };

  render() {
    const { text } = this.props;
    const closedStyle = {
      padding: '5px 15px 5px 0px',
    };
    const openStyle = {
      padding: '5px 15px 5px 10px',
    };

    return (
      <div className="truncate-container" style={closedStyle}>
        {this.state.isOpen ? (
          <div className="truncate-open-text-container component-bg" style={openStyle}>
            <span className={' '}>{text}</span>
            <Button text={'Less...'} customClass="truncate-text-more-button link-btn" handleClick={this.handleClick} />
            <IconButton
              customClass="truncate-text-less-x-button"
              icon="fa fa-times fa-times-thin"
              colorClassName="secondary-text-color"
              size="medium"
              background="rgba(0,0,0,0)"
              handleClick={this.handleClick}
            />
          </div>
        ) : (
          <span className={this.props.textClassName || ''}>{this.truncateText() + '...'}</span>
        )}
        {!this.state.isOpen && (
          <Button text={'More...'} customClass="truncate-text-more-button link-btn" handleClick={this.handleClick} />
        )}
      </div>
    );
  }
}

export default TruncateContainer;
