import React from 'react';
import { formatForDisplay } from '../../../helpers/displayHelpers';

const MoneyNumber = (props) => {
  const { className, value } = props;
  const displayString = formatForDisplay(value, 'price');
  return <span className={`money-number-container ${className || ''}`}>{displayString}</span>;
};

export default MoneyNumber;
