import React from 'react';

import SearchResult from './SearchResult';
import styled from 'styled-components';

const Wrapper = styled.div`
  ul {
    background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};
  }
  li {
    &:hover {
      color: ${({ theme }) => theme.themeColors.primaryCtaButton};

      * {
        color: ${({ theme }) => theme.themeColors.primaryCtaButton};
      }
    }
  }
`;

const SearchResultsList = (props) => {
  const config = {
    resultHeight: 37.5,
  };

  const generateResultStyle = (index) => {
    return {
      top: `${index * config.resultHeight}px`,
    };
  };

  if (!props.show || props.loading || props.results === null) return <div className="hidden" />;

  const { activeQueryResult, maxResultsSize } = props;
  const results = props.results.slice(0, maxResultsSize);
  const inputWidth = $(`#${props.inputId}`).width();

  return (
    <Wrapper className="search-results-container">
      <ul className="search-results-list" style={{ height: `${results.length * config.resultHeight}px` }}>
        {results.map((result, i) => (
          <SearchResult
            inputWidth={inputWidth}
            key={`res-${i}`}
            query={props.query}
            result={result}
            resultType={'security'}
            isActive={activeQueryResult === result}
            style={generateResultStyle(i)}
            makeQueryResultActive={props.makeQueryResultActive}
          />
        ))}
        {results.length === 0 ? <SearchResult noResults={true} /> : null}
      </ul>
    </Wrapper>
  );
};

export default SearchResultsList;
