export const tornadoColorPalette = {
  primary: {
    charcoal: '#1f1f1f',
    oak: '#F7F4F2',
  },
  secondary: {
    yellow: '#fcfe7d',
    // orangeYellow: '#fcfe7d',
    navy: '#211e2c',
    black: '#000',
    blackTransparent: 'rgba(30,30,30,0.1)',
    white: '#fff',
    whiteTransparent: 'rgba(255,255,255,0.3)',
    cyan: '#9ffbf5',
    blue: '#0000FF',

    streetBlue: '#1674b1',
    streetGreen: '#080',
    streetRed: '#ff0000',

    lightBlue: '#7AC9EA',
    purple: '#6d56fb',
    promoPurple: '#5937f9',
    darkPurple: '#201F2C',
    lavender: '#dcdbff',

    red: '#f5665d',
    green: '#7ecb00',

    brightGreen: '#1AD36F',
    brightRed: '#FF5000',

    darkRed: '#bc332c',
    darkGreen: '#5a7a00',
    silver: '#c0c0c0',
    lightCharcoal: '#343434',
    orange: 'rgb(255,156,34)',
    cream: '#F6F3EF',
    lighterGrey: 'rgb(235,235,235)',
    osGrey: 'rgb(218,224,227)',

    cloud: '#777777',
    darkCloud: '#333',
    lightCloud: '#fafafa',
  },
  element: {
    borderLight: 'rgba(31,31,31, 0.05)',
    borderDark: 'rgba(247,244,242, 0.05)',
  },
};

/*

    --color_header_background: #fafafa;

    --font_ui_weight: 500;

    --background_color_hub: #fafafa;
    --background_color_tile: #fafafa;
    --background_color_ad: #f0f0f0;

    --color_footer_text: #777777;
    --blockquote_text_link_color: #1674b1;
    --pullquote_text_color: #1674b1;
    --color_header_nav_text: #474747;

    --color_button_text: #ffffff;
    --color_button_border: #d8d8d8;

    --color_text_primary: #474747;
    --color_header_nav_text_hover: #1674b1;
    --color_headline: #474747;
    --color_header_text: #474747;
    --color_anchor_text: #1674b1;
    --color_highlight: #1674b1;
    --color_text_secondary: #777777;

    --font_headline_secondary_family: oswald, tahoma, sans-serif;
    --font_ui_family: oswald, tahoma, sans-serif;
    --font_body_primary_family: roboto, verdana, sans-serif;

    --table_of_contents_background_color: #d8d8d8;

    --palette_tone_light: #d8d8d8;
    --palette_tone_mid: #777777;
    --palette_tone_white: #ffffff;

 */
