import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import PageLoading from '../../components/PageLoading';
import PendingConnectionRequest from './components/PendingConnectionRequest';

class PendingConnectionRequestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _isCollapsed: true,
    };
  }

  render() {
    return (
      <div className={`pending-connection-request-list`}>
        {this._renderNonCollapsedItems()}
        {this._isListLargerThanLimit() && !this._isListCollapsed() ? this._renderCollapsedItems() : null}
        {this._isListLargerThanLimit() ? this._renderShowMoreShowLess() : null}
      </div>
    );
  }

  _isListCollapsed = () => this.state._isCollapsed;
  _isListLargerThanLimit = () => this._returnUserIdsCount() > this._returnMaxResultSize();
  _returnUserIdsCount = () => this._returnUserIds().length;
  _returnMaxResultSize = () => this.props.maxResultSize;
  _returnConnectionRequestType = () => this.props.requestType;

  _returnUserIds = () => this.props.userIds;
  _returnNonCollapsedUserIds = () => this._returnUserIds().slice(0, this._returnMaxResultSize());
  _returnCollapsedUserIds = () => this._returnUserIds().slice(this._returnMaxResultSize(), this._returnUserIdsCount());
  _renderNonCollapsedItems = () => {
    return this._returnNonCollapsedUserIds().map((userId) => this._renderPendingConnectionRequest(userId));
  };
  _renderCollapsedItems = () => {
    return this._returnCollapsedUserIds().map((userId) => this._renderPendingConnectionRequest(userId));
  };
  _renderShowMoreShowLess = () => {
    return this._isListCollapsed() ? (
      <div
        className={`link-btn pending-connections-collapse-button pending-connections-show-more-button`}
        onClick={this._handleShowMoreClick}
      >
        Show More
      </div>
    ) : (
      <div
        className={`link-btn pending-connections-collapse-button pending-connections-show-less-button`}
        onClick={this._handleShowLessClick}
      >
        Show Less
      </div>
    );
  };

  _renderPendingConnectionRequest = (userId) => {
    return (
      <PendingConnectionRequest
        key={`pending-connection-request-${userId}`}
        userId={userId}
        requestType={this._returnConnectionRequestType()}
      />
    );
  };

  _handleShowMoreClick = () => {
    this._toggleCollapseList();
    this._logShowMoreClick();
  };
  _handleShowLessClick = () => {
    this._toggleCollapseList();
  };

  _toggleCollapseList = () => {
    this.setState((prevState) => ({
      _isCollapsed: !prevState._isCollapsed,
    }));
  };

  _logShowMoreClick = () => {
    const event = 'Clicked Show More Connection Requests';
    const properties = {
      'Connection Request Type': this._returnConnectionRequestType(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingConnectionRequestList);
