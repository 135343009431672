import React from 'react';
import styled from 'styled-components';
import { BaseText, Row } from '@src/main/lib/nvstr-common-ui.es';
import LinkTo from '../Links/LinkTo';
import NumberWithChangeIndicator from '../../components/UI/NumberWithChangeIndicator';
import { returnSecurityPanelUrl } from '@src/helpers/securityPanelHelpers';
import { formatSecurityPropertyValue } from '@src/helpers/securitiesHelpers';
import { Container } from '@src/main/components/ui';
import { logMetricsTrackingEvent } from '@src/actions';
import { useSecurity } from '@src/main/hooks/securities/useSecurity';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { usePriceData } from '@src/main/hooks/securities/usePriceData';
import { addMarketMonitorSecurities, fetchAndReturnSecuritiesPriceData, quickFetchSecuritiesData } from '@src/actions';
import { activeSecuritiesTracker, LivePricePollingManager } from '@src/main/classes/LivePricePollingManager';
import { isUndefinedOrNull } from '@src/helpers/usefulFuncs';

const InlineWrapper = styled(Row)`
  justify-content: space-between;
`;
const StackedWrapper = styled.div``;
const TickerRowWrapper = styled.div``;
const RowTickerWrapper = styled(Row)`
  justify-content: space-between;

  @media (max-width: 408px) {
    align-items: center;
  }
`;
const TickerDisplay = styled.span`
  display: inline-block;

  * {
    color: ${({ theme }) => theme.themeColors.text};
  }

  @media (max-width: 408px) {
    * {
      font-size: 10px !important;
      line-height: 14px;
    }
  }
`;
const TickerPriceChange = styled.span`
  display: inline-block;
  min-width: 76px;
  text-align: right;

  i {
    font-size: 18px !important;
    line-height: 22px;
  }

  .number-with-change-indicator-value {
    display: inline-block;
    min-width: 46px;
  }

  @media (max-width: 408px) {
    min-width: 58px;

    .number-with-change-indicator-value {
      display: inline-block;
      min-width: 34px;
    }

    * {
      font-size: 10px !important;
      line-height: 14px;
    }
    i {
      font-size: 16px !important;
      line-height: 22px;
    }
  }
`;

function getNickname({ symbol, name }) {
  const nickNames = {
    SPY: 'S&P 500',
    QQQ: 'NASDAQ 100',
  };
  return nickNames[symbol] || name;
}

const Ticker = ({ securityId }) => {
  const security = useSecurity(securityId);
  const { percentChange } = usePriceData(securityId);

  const navigate = useNavigate();
  const location = useLocation();

  const { symbol } = security;

  const logTickerClick = (securityId) => {
    const event = 'Open Security Panel';
    const properties = {
      Context: 'Market Monitor',
      'Security Id': securityId,
      'Security Symbol': symbol,
    };
    logMetricsTrackingEvent(event, properties)();
  };

  const _returnLinkToOpenSecurityPanel = (securityId) => {
    const openSecurityPanelConfig = {
      securityId,
      navigate,
      location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  const handleAfterLinkClick = () => {
    logTickerClick(securityId);
    return null;
  };

  return (
    <TickerRowWrapper key={securityId}>
      <LinkTo
        key={`general-market-ticker-${securityId}`}
        to={_returnLinkToOpenSecurityPanel(securityId)}
        additionalClickAction={handleAfterLinkClick}
        noTextDecoration
      >
        <RowTickerWrapper>
          <TickerDisplay>
            {!!security?.name ? (
              <BaseText fontSettings={{ small: '10px', normal: '12px' }}>{`${getNickname(security)} (${
                security.symbol
              })`}</BaseText>
            ) : null}
          </TickerDisplay>
          <TickerPriceChange>
            {!isUndefinedOrNull(percentChange) ? (
              <NumberWithChangeIndicator
                customClass={'dashboard'}
                valueFormat={'percentage'}
                displayValue={formatSecurityPropertyValue(percentChange, 'percentage')}
                value={percentChange}
                coloredText
                showPlus
              />
            ) : null}
          </TickerPriceChange>
        </RowTickerWrapper>
      </LinkTo>
    </TickerRowWrapper>
  );
};

const MarketMonitor = ({ inline }) => {
  const dispatch = useDispatch();
  const securities = useSelector((state) => state.marketMonitor.securities);

  React.useEffect(() => {
    const getData = async () => {
      const key = 'symbol';
      const symbols = ['SPY', 'QQQ'];
      const response = await fetchAndReturnSecuritiesPriceData(symbols, key)(dispatch);
      const marketMonitorSecurities = [];

      const { status, data } = response;
      if (status === 200) {
        const { securities } = data;
        const ids = [];

        symbols.forEach((s) => {
          const security = securities.filter((sec) => sec.symbol === s)[0];
          const { symbol, id } = security;
          ids.push(id);
          marketMonitorSecurities.push({ symbol, id });
        });
        activeSecuritiesTracker.addSecurities(marketMonitorSecurities);
        addMarketMonitorSecurities(marketMonitorSecurities)(dispatch);

        await quickFetchSecuritiesData(ids)(dispatch);
      }
    };
    getData();
  }, []);

  if (!securities || securities.length < 1) return null;

  if (inline) {
    return (
      <Container>
        <InlineWrapper>
          {securities.map((s) => (
            <Ticker key={s.id} securityId={s.id} />
          ))}
        </InlineWrapper>
      </Container>
    );
  }
  return (
    <Container>
      <StackedWrapper>
        {securities.map((s) => (
          <Ticker key={s.id} securityId={s.id} />
        ))}
      </StackedWrapper>
    </Container>
  );
};

export default MarketMonitor;
