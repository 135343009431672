import React from 'react';
import { connect } from 'react-redux';

import { showModal, fetchSecuritiesPriceHistoryData, hideModal } from '../../actions';
import { STORAGE_KEYS, getItemFromStorage, setItemToStorage, clearItemFromStorage } from '../../utils/storage';

import SearchContainer from '../../containers/UI/Search/SearchContainer';
import PerformanceComparisonSecurityTag from './PerformanceComparisonSecurityTag';

import PageHeading from '../../components/UI/PageHeading';
import Button from '../../components/buttons/Button';
import withWindowSize from '../HOCS/withWindowSize';
import IconButton from '../../components/buttons/IconButton';

import { generateChartColor, CHART_PERFORMANCE_COMPARISON_COLOR_INDEXES } from '../../helpers/chartHelpers';
import { getSecurityPriceHistoryLookupFromState } from '../../selectors/securities';
import { createTimeInstance, formatLocalizedDateTime } from '../../helpers/timeHelpers';
import {
  filterFundingCompletedTransactions,
  getTransactionDate,
  getTransactionEquityImpact,
} from '../../helpers/transactionHelpers';

const MAX_COMPARISON_SECURITIES_COUNT = 5;

class PerformanceComparisonSecuritiesManager extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isUsingPerformanceComparison: false,
      performanceSecurities: [],
    };
  }

  componentDidMount() {
    const performanceSecurities = this._getComparisonPerformanceSecuritiesFromStorage();
    if (Array.isArray(performanceSecurities)) {
      const ids = performanceSecurities.map((s) => s.id);
      this._fetchChartDataForSecurityIds(ids);
      this.setState((prevState) => ({
        isUsingPerformanceComparison: true,
        performanceSecurities,
      }));
    }
  }

  render() {
    const { children } = this.props;

    const securityIds = this.getPerformanceComparisonSecurities();
    const additionalDatasets = this._generateAdditionalDatasets();

    const PerformanceComparisonSecuritiesRenderer = (
      <div className={'chart-performance-comparison-picker-container'}>
        <div>
          {this.isUsingStockPerformanceComparison() && (
            <div className="chart-performance-comparison-picker-tag-container align-top-flex-end">
              <div className="chart-performance-comparison-picker-tags flex-align-center-justify-end">
                {this.getPerformanceComparisonSecurities().map((s, i) => (
                  <div key={s.id} style={{ marginLeft: '5px' }}>
                    <PerformanceComparisonSecurityTag
                      index={i}
                      securityId={s.id}
                      symbol={s.symbol}
                      onRemove={this.handleRemovePerformanceComparisonSecurity}
                    />
                  </div>
                ))}
              </div>
              <div className="flex-column-center" style={{ marginLeft: '15px', height: '39px' }}>
                {this._hasReachedMax() ? (
                  <div>
                    <span className="error-text-color" style={{ lineHeight: '39px' }}>
                      {`${MAX_COMPARISON_SECURITIES_COUNT}/${MAX_COMPARISON_SECURITIES_COUNT}`}
                    </span>
                  </div>
                ) : (
                  <div>
                    <IconButton
                      icon=" fa fa-plus"
                      customClass="circle-fill-button success-button-color"
                      size="medium"
                      handleClick={this.handleAddAnotherClick}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );

    return children(securityIds, additionalDatasets, PerformanceComparisonSecuritiesRenderer);
  }

  handleAddStockPerformanceComparisonClick = () => {
    this.setState(() => ({
      isUsingPerformanceComparison: true,
    }));

    this._showAddStockSearch();
  };

  handleRemoveStockPerformanceComparisonClick = () => {
    this._clearComparisonSecurityIdsFromStorage();
    this.setState(() => ({
      isUsingPerformanceComparison: false,
      performanceSecurities: [],
    }));
  };

  handleAddAnotherClick = () => {
    this._showAddStockSearch();
  };

  handleAddStockSelection = (selectionSecurity) => {
    const { dispatch } = this.props;
    hideModal()(dispatch);

    if (!selectionSecurity) return;

    const { id, symbol } = selectionSecurity;
    const performanceSecurity = {
      id,
      symbol,
    };
    this.handleAddPerformanceComparisonSecurity(performanceSecurity);
    this._fetchChartDataForSecurityIds([id]);
  };

  handleAddPerformanceComparisonSecurity = (performanceSecurity) => {
    const securityId = performanceSecurity.id;

    this.setState((prevState) => {
      const { performanceSecurities } = prevState;
      const securityIds = performanceSecurities.map((s) => s.id);
      if (securityIds.includes(securityId)) {
        return prevState;
      }
      const newStateIds = [...performanceSecurities, performanceSecurity];
      this._saveComparisonPerformanceSecuritiesStorage(newStateIds);
      return {
        performanceSecurities: newStateIds,
      };
    });
  };

  handleRemovePerformanceComparisonSecurity = (securityId) => {
    this.setState((prevState) => {
      const { performanceSecurities } = prevState;
      const newStateIds = performanceSecurities.filter((s) => s.id !== securityId);
      this._saveComparisonPerformanceSecuritiesStorage(newStateIds);
      return {
        performanceSecurities: newStateIds,
      };
    });
  };

  _generateAdditionalDatasets = () => {
    const { isUsingPerformanceComparison, performanceSecurities } = this.state;

    if (!isUsingPerformanceComparison) return [];

    return performanceSecurities.map((s, i) => this._buildDatasetForSecurity(s, i));
  };

  _getCompletedFundingTransactions = () => {
    const transactions = this.props.transactionsHistory;
    return filterFundingCompletedTransactions(transactions);
  };

  _generateRatioChangeDatesLookup = () => {
    const lookup = {};
    const transactions = this._getCompletedFundingTransactions();
    transactions.forEach((t) => {
      const dateEpoch = getTransactionDate(t) * 1000;
      const dateStr = formatLocalizedDateTime('l', createTimeInstance(dateEpoch));
      if (lookup[dateStr]) {
        if (Array.isArray(lookup[dateStr])) {
          lookup[dateStr] = [...lookup[dateStr], t];
        } else {
          lookup[dateStr] = [lookup[dateStr], t];
        }
      } else {
        lookup[dateStr] = t;
      }
    });
    return lookup;
  };

  _generateDatasetValuesForSecurityId = (id) => {
    const equityHistory = this._getEquityHistory();
    if (equityHistory === null) return [];

    let startingEquityValue = null;
    let equityValueIndex = null;
    equityHistory.forEach((h, i) => {
      if (startingEquityValue === null) {
        if (h.equity > 0) {
          startingEquityValue = h.equity;
          equityValueIndex = i;
        }
      }
    });
    const securityPriceHistory = this._getSecurityPriceHistory(id);
    const securityPriceHistoryLookup = this._generateSecurityPriceHistoryByDateLookup(id);

    if (!securityPriceHistory || securityPriceHistory.length === 0) return [];

    const securityPrice = securityPriceHistory[equityValueIndex].price;
    const equityRatioChangeLookup = this._generateRatioChangeDatesLookup();

    let ratio = securityPrice > 0 ? startingEquityValue / securityPrice : 0;
    return equityHistory.map((h, i) => {
      const date = createTimeInstance(h.time * 1000);
      const dateStr = this._convertEpochToDateString(date);

      const securityPrice = (securityPriceHistoryLookup[dateStr] || {}).price;
      const hadEquityChange = !!equityRatioChangeLookup[dateStr];

      if (hadEquityChange) {
        const fundingTransaction = equityRatioChangeLookup[dateStr];
        const reducer = (accumulator, fundingTransaction) => {
          return getTransactionEquityImpact(fundingTransaction) + accumulator;
        };
        const impact = Array.isArray(fundingTransaction)
          ? fundingTransaction.reduce(reducer, 0)
          : getTransactionEquityImpact(fundingTransaction);
        const currentValue = securityPrice * ratio;
        const updatedRatioEquityValue = currentValue + impact;
        ratio = securityPrice > 0 ? updatedRatioEquityValue / securityPrice : 0;
      }

      return securityPrice > 0 ? securityPrice * ratio : null;
    });
  };

  _buildDatasetForSecurity = (performanceSecurity, colorIndex = 0) => {
    const { id, symbol } = performanceSecurity;

    const color = CHART_PERFORMANCE_COMPARISON_COLOR_INDEXES[colorIndex];
    const label = symbol;
    const values = this._generateDatasetValuesForSecurityId(id);

    return {
      label,
      data: values,

      fill: false,
      borderWidth: 3,
      borderColor: generateChartColor(color, '0.75'),
      borderCapStyle: 'butt',
      pointHoverRadius: 1,
      pointRadius: 0.5,
      spanGaps: true,

      backgroundColor: generateChartColor(color, '0.25'),
      pointHoverBackgroundColor: generateChartColor(color, '0.75'),
    };
  };

  _hasReachedMax = () => MAX_COMPARISON_SECURITIES_COUNT <= this.getPerformanceComparisonSecurities().length;

  _fetchChartDataForSecurityIds = (securityIds) => {
    const { dispatch } = this.props;
    fetchSecuritiesPriceHistoryData(securityIds, 'one_year')(dispatch);
  };

  _showAddStockSearch = () => {
    const { windowHeight, dispatch } = this.props;
    const contentComponent = (
      <div className="equity-history-stock-comparison-container">
        <PageHeading className=" ">Search for a stock for comparison</PageHeading>
        <div
          style={{
            width: '95%',
            margin: '0 auto',
            padding: '25px 0 0 0',
            height: windowHeight - 100,
          }}
        >
          <SearchContainer
            id="performance_comparison_securities_search"
            icon="fa-search"
            parentComponent="Performance Comparison Securities"
            placeholder="Search Stocks..."
            handleSecurityQuerySelection={this.handleAddStockSelection}
            autoFocus
          />
        </div>
      </div>
    );
    const modal = {
      contentComponent,
      dismissable: true,
      size: 'wide',
    };
    showModal(modal)(dispatch);
  };

  isUsingStockPerformanceComparison = () => this.state.isUsingPerformanceComparison;

  getPerformanceComparisonSecurities = () =>
    this.isUsingStockPerformanceComparison ? this.state.performanceSecurities : [];

  _getComparisonPerformanceSecuritiesFromStorage = () => {
    const ids = getItemFromStorage(STORAGE_KEYS.EQUITY_CHART_PERFORMANCE_CHART_PERFORMANCE_SECURITIES);
    return ids;
  };

  _saveComparisonPerformanceSecuritiesStorage = (performanceSecurities) => {
    setItemToStorage(STORAGE_KEYS.EQUITY_CHART_PERFORMANCE_CHART_PERFORMANCE_SECURITIES, performanceSecurities);
  };

  _clearComparisonSecurityIdsFromStorage = () => {
    clearItemFromStorage(STORAGE_KEYS.EQUITY_CHART_PERFORMANCE_CHART_PERFORMANCE_SECURITIES);
  };

  _getEquityHistory = () => this.props.equityHistory;

  _generateSecurityPriceHistoryByDateLookup = (id) => {
    const priceHistory = this._getSecurityPriceHistory(id);
    const lookup = {};

    if (priceHistory === null) return lookup;

    priceHistory.forEach((h) => {
      const date = createTimeInstance(h.time * 1000);
      lookup[this._convertEpochToDateString(date)] = h;
    });
    return lookup;
  };

  _convertEpochToDateString = (epoch) => formatLocalizedDateTime('l', epoch);

  _getSecurityPriceHistory = (id) => (this._getPriceHistoryLookup()[id] || {}).one_year || null;

  _getPriceHistoryLookup = () => (this.props.priceHistoryLookup || {}).securitiesList || {};

  _getTransactionsHistory = () => this.props.transactionsHistory;
}

const mapStateToProps = (state) => {
  return {
    transactionsHistory: state.historyTransactions.transactions,
    priceHistoryLookup: getSecurityPriceHistoryLookupFromState(state),
  };
};

const connectedComponent = connect(mapStateToProps)(PerformanceComparisonSecuritiesManager);
export default withWindowSize(connectedComponent);
