import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../../../actions/index';

import CompTableAggregationRow from './CompTableAggregationRow';

import { isLoadingFundamentalData } from '../../../../helpers/securitiesHelpers';

class CompTableFooter extends React.PureComponent {
  constructor() {
    super();

    //taken from https://gist.github.com/Daniel-Hug/7273430
    var arrayStat = {
      max: function (array) {
        return Math.max.apply(null, array);
      },

      min: function (array) {
        return Math.min.apply(null, array);
      },

      range: function (array) {
        return arrayStat.max(array) - arrayStat.min(array);
      },

      midrange: function (array) {
        return arrayStat.range(array) / 2;
      },

      sum: function (array) {
        var num = 0;
        for (var i = 0, l = array.length; i < l; i++) num += array[i];
        return num;
      },

      mean: function (array) {
        return arrayStat.sum(array) / array.length;
      },

      median: function (array) {
        array.sort(function (a, b) {
          return a - b;
        });
        var mid = array.length / 2;
        return mid % 1 ? array[mid - 0.5] : (array[mid - 1] + array[mid]) / 2;
      },

      modes: function (array) {
        if (!array.length) return [];
        var modeMap = {};
        var maxCount = 0;
        var modes = [];

        array.forEach(function (val) {
          if (!modeMap[val]) modeMap[val] = 1;
          else modeMap[val]++;

          if (modeMap[val] > maxCount) {
            modes = [val];
            maxCount = modeMap[val];
          } else if (modeMap[val] === maxCount) {
            modes.push(val);
            maxCount = modeMap[val];
          }
        });
        return modes;
      },

      variance: function (array) {
        var mean = arrayStat.mean(array);
        return arrayStat.mean(
          array.map(function (num) {
            return Math.pow(num - mean, 2);
          })
        );
      },

      standardDeviation: function (array) {
        return Math.sqrt(arrayStat.variance(array));
      },

      meanAbsoluteDeviation: function (array) {
        var mean = arrayStat.mean(array);
        return arrayStat.mean(
          array.map(function (num) {
            return Math.abs(num - mean);
          })
        );
      },

      zScores: function (array) {
        var mean = arrayStat.mean(array);
        var standardDeviation = arrayStat.standardDeviation(array);
        return array.map(function (num) {
          return (num - mean) / standardDeviation;
        });
      },
    };

    this._defaultAggregations = [
      {
        label: 'Average',
        aggFunc: (values) => arrayStat.mean(values),
      },
      {
        label: 'Median',
        aggFunc: (values) => arrayStat.median(values),
      },
      {
        label: 'Std Dev',
        aggFunc: (values) => arrayStat.standardDeviation(values),
      },
      {
        label: 'High',
        aggFunc: (values) => arrayStat.max(values),
      },
      {
        label: 'Low',
        aggFunc: (values) => arrayStat.min(values),
      },
    ];
  }

  render() {
    if (this.isLoading()) {
      return null;
    }
    return (
      <tfoot>
        <tr id={'padding-row'} className={'border-accent-important'}>
          <td colSpan={this.returnAggColSpan() + this.returnColumns().length}>
            <div style={{ padding: '5px' }}></div>
          </td>
        </tr>
        {this._returnAggregations().map((aggregation, i) => (
          <CompTableAggregationRow
            key={`comp-table-security-row-${aggregation.label.toLowerCase()}`}
            securityIds={this.props.securityIds}
            aggregation={aggregation}
            returnAggColSpan={this.returnAggColSpan()}
            columns={this.returnColumns()}
            naIndicator={'-'}
          />
        ))}
      </tfoot>
    );
  }

  _returnAggregations = () => this.props.aggregations || this._defaultAggregations;

  isLoading = () => {
    // HACK: using since fundamental data is the slowest
    const securityIds = this.props.securityIds;
    return securityIds.some((securityId) => isLoadingFundamentalData(securityId, this.props.fundamentalData));
  };

  returnAggColSpan = () => {
    const groupData = this.props.columnGroups;
    const columnsInOrder = [];
    // add columns from groups
    groupData.forEach((group) => {
      group.columns.forEach((column) => {
        columnsInOrder.push(column);
      });
    });
    return columnsInOrder.filter((column) => column.ignoreForAggregation).length;
  };

  returnColumns = () => {
    const groupData = this.props.columnGroups;
    const columnsInOrder = [];
    // add columns from groups
    groupData.forEach((group) => {
      group.columns.forEach((column) => {
        columnsInOrder.push(column);
      });
    });
    return columnsInOrder.filter((column) => !column.ignoreForAggregation);
  };
}

const mapStateToProps = (state) => {
  return {
    securities: state.securities,
    fundamentalData: state.fundamentalData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompTableFooter);
