import React from 'react';

const LoadingCard = (props) => {
  const { position } = props;
  const width = 110;

  const loadingProfileAvatarLeftCover = {
    height: `${40}px`,
    left: `${0}px`,
    right: `${70}px`,
  };
  const loadingProfileAvatarRightCover = {
    height: `${40}px`,
    left: `${70}px`,
    right: `${0}px`,
  };
  const loadingProfileUserNameTopCover = {
    height: `${10}px`,
    top: `${40}px`,
    left: `${0}px`,
    right: `${0}px`,
  };
  const loadingProfileUserNameBottomCover = {
    height: `${15}px`,
    top: `${60}px`,
    left: `${0}px`,
    right: `${0}px`,
  };
  const loadingMutualConnectionBottomCover = {
    height: `${15}px`,
    top: `${95}px`,
    left: `${0}px`,
    right: `${0}px`,
  };
  const loadingConnectButtonBottomCover = {
    height: `${10}px`,
    top: `${130}px`,
    left: `${0}px`,
    right: `${0}px`,
  };

  return (
    <div
      className={`people-you-may-know-loading-card-container user-you-may-know-container card component-bg loading`}
      style={position}
    >
      <div className={`animated-background`}>
        <div className={`loading-animation-covers`} style={loadingProfileAvatarLeftCover}></div>
        <div className={`loading-animation-covers`} style={loadingProfileAvatarRightCover}></div>
        <div className={`loading-animation-covers`} style={loadingProfileUserNameTopCover}></div>
        <div className={`loading-animation-covers`} style={loadingProfileUserNameBottomCover}></div>
        <div className={`loading-animation-covers`} style={loadingMutualConnectionBottomCover}></div>
        <div className={`loading-animation-covers`} style={loadingConnectButtonBottomCover}></div>
      </div>
    </div>
  );
};

export default LoadingCard;
