import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import LoadingIcon from '../../components/misc/LoadingIcon';

import {
  returnIdeaLookupFromIdeaStore,
  returnAllIdeasInStore,
  returnIdeaFromIdeaStore,
  isLoadingIdeaData,
  wasLoadingIdeaDataUpdateForSecurity,
  wasLoadingIdeaDataUpdateForUser,
  getSecurityIdFromIdea,
  wasSecurityIdeasLoadingUpdate,
  wasUserIdeasLoadingUpdate,
  returnSecurityIdeasLoadingUpdateKeys,
  returnUserIdeasLoadingUpdateKeys,
} from '../../helpers/ideaHelpers';
import { throwError } from '../../helpers/devToolHelpers';
import { didFirstLevelPropsValueChange } from '../../helpers/generalHelpers';

/*
  passes idea data to children
    {
      isLoading,
      ideas,
    }

  **** GOTCHAS
    isInitialLoad -> assumes some sort of loading function is guaranteed to run before or shortly after mount, if doesn't happen, value will stay true even if there is cached data and no load is occurring

  <IdeasListDataWrapper
    showLoading -> bool -> component will render a loading state
    getIdeaData -> bool -> ill get ideas on mount depending on ids supplied
    useAsLoadingWrapper -> bool -> doesn't add idea wrapper logic props to children
    useInitialLoading -> bool -> isLoading value will only be for an initial load
    // future feature idea ->  filters -> array of filter type strings -> valid types ['active'] -> not implemented

    *only supply one below group:
      securityId -> will return ideas for security
      userId -> will return ideas for user

    customLoadingComponent -> jsx -> will render this component instead of default loading component
    hideWhenLoading -> bool, won't display a loading state
  >
    {children}
  </IdeasListDataWrapper>

*/

class IdeasListDataWrapper extends Component {
  constructor() {
    super();
    this.state = {
      _isInitialLoadRunning: true,
    };
  }

  componentDidMount() {
    if (this.props.getIdeaData) {
      this._getIdeaData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.isInitialLoad()) {
      this._determineIfInitialLoadCompleted(prevProps);
    }
  }

  shouldComponentUpdate(nextProps) {
    const excludeKeys = ['actions', 'ideas'];
    if (didFirstLevelPropsValueChange(this.props, nextProps, excludeKeys)) {
      return true;
    }
    // checks if any ideas that are returned in the list have changed
    const nextRenderIdeas = this.returnIdeasForIdeaSource(nextProps);
    const isNextRenderIdeasAllSame = nextRenderIdeas.every(
      (idea) => idea == returnIdeaFromIdeaStore(idea.idea_id, this.returnIdeasStore())
    );
    const prevRenderIdeas = this.returnIdeasForIdeaSource();
    const isPrevRenderIdeasAllSame = prevRenderIdeas.every(
      (idea) => idea == returnIdeaFromIdeaStore(idea.idea_id, this.returnIdeasStore(nextProps))
    );

    // checks if the prop supplied loading tracking list in ideas store is different
    const securityLoadingUpdate = wasLoadingIdeaDataUpdateForSecurity(
      this._returnSecurityId(),
      this.returnIdeasStore(),
      this.returnIdeasStore(nextProps)
    );
    const userIdeasLoadingUpdate = wasLoadingIdeaDataUpdateForUser(
      this._returnUserId(),
      this.returnIdeasStore(),
      this.returnIdeasStore(nextProps)
    );

    // checks if the security or user owner of idea returned in list is loading
    let isLoadingSecurityOrUserIdeasOfIdeaInList = false;
    let _wasSecurityIdeasLoadingUpdate = wasSecurityIdeasLoadingUpdate(
      this.returnIdeasStore(),
      this.returnIdeasStore(nextProps)
    );
    if (_wasSecurityIdeasLoadingUpdate) {
      const keys = returnSecurityIdeasLoadingUpdateKeys(this.returnIdeasStore(), this.returnIdeasStore(nextProps));
      nextRenderIdeas.forEach((idea) => {
        if (keys.includes(getSecurityIdFromIdea(idea))) {
          isLoadingSecurityOrUserIdeasOfIdeaInList = true;
        }
      });
    }
    let _wasUserIdeasLoadingUpdate = wasUserIdeasLoadingUpdate(
      this.returnIdeasStore(),
      this.returnIdeasStore(nextProps)
    );
    if (_wasUserIdeasLoadingUpdate) {
      const keys = returnUserIdeasLoadingUpdateKeys(this.returnIdeasStore(), this.returnIdeasStore(nextProps));
      nextRenderIdeas.forEach((idea) => {
        if (keys.includes(idea.user_id)) {
          isLoadingSecurityOrUserIdeasOfIdeaInList = true;
        }
      });
    }
    return (
      isLoadingSecurityOrUserIdeasOfIdeaInList ||
      securityLoadingUpdate ||
      userIdeasLoadingUpdate ||
      !isNextRenderIdeasAllSame ||
      !isPrevRenderIdeasAllSame
    );
  }

  render() {
    if (this.isLoading() && this.props.showLoading) {
      if (this._shouldHideWhenLoading()) {
        return null;
      }
      return this.props.customLoadingComponent || <LoadingIcon icon="fading-3balls" size="small" />;
    }

    return (
      <span className={`basic-idea-list-data-wrapper`}>
        {this.props.useAsLoadingWrapper ? this.props.children : this._renderChildrenWithIdeaDataProps()}
      </span>
    );
  }

  _renderChildrenWithIdeaDataProps = () => {
    return React.Children.map(this.props.children, (child) => React.cloneElement(child, this.returnIdeaDataProps()));
  };

  returnIdeaDataProps = () => ({
    isLoading: this.props.useInitialLoading ? this.isInitialLoad() : this.isLoading(),
    isInitialLoad: this.isInitialLoad(),
    isLoadingIgnoreInitialLoad: this.isLoading(),

    ideaStore: this.returnIdeasStore(),
    ideas: this.returnIdeasForIdeaSource(),
  });

  isLoading = (props) =>
    isLoadingIdeaData(this.returnIdeaDataSource(), this.returnIdeaDataSourceId(), this.returnIdeasStore(props));

  isInitialLoad = () => this.state._isInitialLoadRunning;

  returnIdeaDataSource = () => {
    if (this.props.securityId) {
      return 'security';
    }
    if (this.props.userId) {
      return 'user';
    }
    return throwError('Invalid data type provided to IdeasListDataWrapper, must supply, securityId or userId');
  };

  returnIdeaDataSourceId = () => this.props.securityId || this.props.userId;

  returnIdeasForIdeaSource = (props) => {
    const _props = props || this.props;
    if (_props.securityId) {
      return this.returnIdeasForSecurity(_props);
    }
    if (_props.userId) {
      return this.returnIdeasForUser(_props);
    }
    return throwError('Invalid data type provided to IdeasListDataWrapper, must supply, securityId or userId');
  };

  returnIdeasForSecurity = (props) =>
    this.returnAllIdeas(props).filter((idea) => getSecurityIdFromIdea(idea) === this._returnSecurityId());

  returnIdeasForUser = (props) => this.returnAllIdeas(props).filter((idea) => idea.user_id === this._returnUserId());

  returnAllIdeas = (props) => returnAllIdeasInStore(this.returnIdeasStore(props));

  returnIdeasLookup = (props) => returnIdeaLookupFromIdeaStore(this.returnIdeasStore(props));

  returnIdeasStore = (props) => (props || this.props).ideas;

  _getIdeaData = () => {
    if (this.props.securityId) {
      return this._getIdeaDataForSecurity();
    }
    if (this.props.userId) {
      return this._getIdeaDataForUser();
    }
    return throwError('Invalid data type provided to IdeasListDataWrapper, must supply, securityId or userId');
  };

  _getIdeaDataForSecurity = () => this.props.actions.getIdeasForSecurity([this._returnSecurityId()]);

  _returnUserId = () => this.props.userId;

  _returnSecurityId = () => this.props.securityId;

  _shouldHideWhenLoading = () => this.props.hideWhenLoading;

  _determineIfInitialLoadCompleted = (prevProps) => {
    if (this.isInitialLoad() && this.isLoading(prevProps) && !this.isLoading()) {
      this.setState(() => ({
        _isInitialLoadRunning: false,
      }));
    }
  };
}

const mapStateToProps = (state) => {
  return {
    ideas: state.ideas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const component = connect(mapStateToProps, mapDispatchToProps)(IdeasListDataWrapper);

export default component;
