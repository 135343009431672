import React from 'react';
import styled from 'styled-components';

export const PageCardWrapper = styled.div`
  position: relative;
  padding: ${({ noPadding }) => (noPadding ? '0px' : '16px 24px')};
  border-radius: 5px;
  text-align: left;
  cursor: default;
  overflow: ${({ hasTable, overflow, overflowHidden }) =>
    overflowHidden ? 'hidden' : overflow ? 'visible' : 'auto' || 'hidden'};

  max-width: ${({ width }) => width || '800px'};

  background: ${({ theme, transparentBackground }) =>
    transparentBackground ? 'transparent' : theme.themeColors.component};
  color: ${({ theme }) => theme.themeColors.text};

  @media (max-width: 500px) {
    max-width: 100%;
    width: 100%;
    margin: 0;
  }
  @media (max-width: 390px) {
    padding: ${({ noPadding }) => (noPadding ? '0px' : '16px')};
  }
  @media (max-width: 340px) {
    padding: ${({ noPadding }) => (noPadding ? '0px' : '8px')};
  }
`;

export const PageCard = (props) => {
  const { children } = props;
  return <PageCardWrapper {...props}>{children}</PageCardWrapper>;
};
