import React from 'react';

import FundingTransaction from './TransactionTypes/FundingTransaction';
import TradeTransaction from './TransactionTypes/TradeTransaction';
import GeneralTransaction from './TransactionTypes/GeneralTransaction';

const Transaction = (props) => {
  const { type } = props;
  const transactionTypeToComponentNameDict = {
    Deposit: FundingTransaction,
    Withdrawal: FundingTransaction,
    Trade: TradeTransaction,
  };
  const transactionComponent = transactionTypeToComponentNameDict[type] || GeneralTransaction;
  return React.createElement(transactionComponent, props);
};

export default Transaction;
