import React from 'react';
import SecurityDataGetter from '../../../SecurityData/SecurityDataGetter';
import BasicSecurityDataWrapper from '../../../SecurityData/BasicSecurityDataWrapper';
import SecurityIdentifierDisplay from '../../../UI/SecurityIdentifierDisplay';
import LinkTo from '../../../Links/LinkTo';
import NumberWithChangeIndicator from '../../../../components/UI/NumberWithChangeIndicator';
import { returnSecurityPanelUrl } from '@src/helpers/securityPanelHelpers';
import { logMetricsTrackingEvent } from '@src/actions';
import { formatLocalizedDateTime, moment } from '@src/helpers/timeHelpers';
import { withRouter } from '@src/main/utils';
import { Container } from '@src/main/components/ui';
import { FlatButton } from '@src/main/components/buttons';
import styled from 'styled-components';

const TRUNCATED_LENGTH = 5;

const ActionRowWrapper = styled.div`
  button {
    padding: 0 16px 0 0 !important;
  }
`;

const ForwardEPSChangeNotification = (props) => {
  const { notification, navigate, location } = props;
  const { forward_eps_change_infos, owner, timestamp } = notification;

  const [isTruncated, setIsTruncated] = React.useState(true);

  const securityIdsInNotification = [];
  forward_eps_change_infos.forEach((fwdEPSChangeInfo) => {
    const securityId = fwdEPSChangeInfo.security.id;
    if (!securityIdsInNotification.includes(securityId)) {
      securityIdsInNotification.push(securityId);
    }
  });

  const notificationTimeObj = moment(timestamp * 1000);
  const notificationCreationDateString = formatLocalizedDateTime('ls', notificationTimeObj);

  const getSecurityId = (fwdEPSChangeInfo) => fwdEPSChangeInfo.security.id;
  const hasIdea = (fwdEPSChangeInfo) => !!fwdEPSChangeInfo.idea;
  const getForwardEPSDelta = (fwdEPSChangeInfo) => fwdEPSChangeInfo.forward_eps_delta;
  const getForwardEPS = (fwdEPSChangeInfo) => fwdEPSChangeInfo.forward_eps;
  const getPeriod = (fwdEPSChangeInfo) => fwdEPSChangeInfo.time_period;

  const URLToOpenSecurityPanel = (fwdEPSChangeInfo) =>
    returnSecurityPanelUrl({
      securityId: getSecurityId(fwdEPSChangeInfo),
      additionalProps: {
        activeTab: 'ideas',
        ideaId: hasIdea(fwdEPSChangeInfo) ? fwdEPSChangeInfo.idea.id : null,
      },
      navigate,
      location,
    });

  const _logViewIdeaClick = () => {
    const event = 'Clicked View Idea';
    const properties = {
      Context: 'Forward EPS Change Notification',
    };
    logMetricsTrackingEvent(event, properties)();
  };

  const rows = isTruncated ? forward_eps_change_infos.slice(0, TRUNCATED_LENGTH) : forward_eps_change_infos;
  return (
    <div className={'forward-eps-change-notification-container'}>
      <SecurityDataGetter securityIds={securityIdsInNotification} getBasicSecurityData />
      <div className={''}>{`Stocks with a recent change in consensus EPS - ${notificationCreationDateString}`}</div>
      <div className={'notification-indent'}>
        <table>
          <thead>
            <tr>
              <th className="secondary-text-color"></th>
              <th className="secondary-text-color">Forward EPS</th>
              <th className="secondary-text-color">Change</th>
              <th className="secondary-text-color">Period</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((fwdEPSChangeInfo, i) => (
              <tr key={i}>
                <td className="forward-eps-change-security-identifier-cell">
                  <BasicSecurityDataWrapper securityId={getSecurityId(fwdEPSChangeInfo)}>
                    <SecurityIdentifierDisplay inFeed showSymbol />
                  </BasicSecurityDataWrapper>
                </td>
                <td className="curr-eps-cell eps-cell">
                  <NumberWithChangeIndicator valueFormat={'price'} value={getForwardEPS(fwdEPSChangeInfo)} hideIcon />
                </td>
                <td className="eps-change-cell eps-cell">
                  <NumberWithChangeIndicator
                    valueFormat={'price'}
                    value={getForwardEPSDelta(fwdEPSChangeInfo)}
                    coloredText
                    showPlus
                    hideIcon
                  />
                </td>
                <td className="eps-cell">{getPeriod(fwdEPSChangeInfo)}</td>
                {hasIdea(fwdEPSChangeInfo) && (
                  <td className="view-idea-cell">
                    <LinkTo
                      to={URLToOpenSecurityPanel(fwdEPSChangeInfo)}
                      className="link-btn"
                      additionalClickAction={_logViewIdeaClick}
                      child={<span className={''}>{'Edit Idea'}</span>}
                      noTextDecoration
                    />
                  </td>
                )}
              </tr>
            ))}
            {TRUNCATED_LENGTH < forward_eps_change_infos.length && (
              <ActionRowWrapper>
                <Container top={8} width={56}>
                  {isTruncated ? (
                    <FlatButton transparent smallNoVert onClick={() => setIsTruncated(false)}>
                      MORE
                    </FlatButton>
                  ) : (
                    <FlatButton transparent smallNoVert onClick={() => setIsTruncated(true)}>
                      LESS
                    </FlatButton>
                  )}
                </Container>
              </ActionRowWrapper>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default withRouter(ForwardEPSChangeNotification);
