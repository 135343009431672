import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useCurrentUser, useCurrentIdeas } from '../../hooks/user';
import { addIdeas, findSecuritiesMatchingFilters, logMetricsTrackingEvent } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import { H5, Body5, createMultiSelectItem, MultiSelect, MultiSelectItem } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { getSecurityIdFromIdea } from '../../../helpers/ideaHelpers';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const InputWrapper = styled.div`
  padding-top: 36px;
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 8px;
`;
const Actions = styled.div`
  padding-top: 36px;
  text-align: center;
`;

const interestsItems = [
  createMultiSelectItem({
    value: 'growth',
    text: 'Growth Stocks',
  }),
  createMultiSelectItem({
    value: 'thought_leaders',
    text: 'Famous Investors',
  }),
  createMultiSelectItem({
    value: 'value',
    text: 'Value Stocks',
  }),
  createMultiSelectItem({
    value: 'etfs',
    text: 'Diversified ETFs',
  }),
  createMultiSelectItem({
    value: 'popular',
    text: 'Popular Stocks',
  }),
  createMultiSelectItem({
    value: 'dividend',
    text: 'Dividend Stocks',
  }),
  createMultiSelectItem({
    value: 'energy',
    text: 'Energy Sector',
  }),
  createMultiSelectItem({
    value: 'financial',
    text: 'Financial Sector',
  }),
  createMultiSelectItem({
    value: 'tech',
    text: 'Tech Sector',
  }),
  createMultiSelectItem({
    value: 'healthcare',
    text: 'Healthcare Sector',
  }),
];

export const SelectInterests = ({ onContinue, onboardingState }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const currentIdeas = useCurrentIdeas();

  const [isReady, setIsReady] = React.useState(true);

  const [values, setValues] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const onMultiChange = React.useCallback(
    (name) => (values) => {
      setValues(values);
    },
    [setValues]
  );

  const submitForm = React.useCallback(
    async (values) => {
      setIsSubmitting(true);
      const data = await findSecuritiesMatchingFilters(values)(dispatch);

      if (data.error) {
        return {
          ok: false,
        };
      }

      const currentIdeasList = Object.keys(currentIdeas).map((id) => currentIdeas[id]);
      const uniqueSecuritiesIdeas = data.filter((security, i) => data.indexOf(security >= i));
      const filterDuplicateIdeas = (security) =>
        !currentIdeasList.some((currentIdea) => getSecurityIdFromIdea(currentIdea) === security.security_id);
      const newSecuritiesForIdeas = uniqueSecuritiesIdeas.filter(filterDuplicateIdeas);
      const securitiesNeedingIdeas = newSecuritiesForIdeas.map((security) => ({
        security,
      }));
      const response = await addIdeas(securitiesNeedingIdeas)(dispatch);
      if (response && response.ideas) {
        return {
          ok: true,
        };
      } else {
        return {
          ok: false,
        };
      }
    },
    [currentIdeas]
  );

  const handleSubmit = React.useCallback(
    async (values) => {
      let forcedValues = null;
      logMetricsTrackingEvent('Submitted Interests Form')();

      if (values.length === 0) {
        forcedValues = ['etfs'];
      }

      const { ok } = await submitForm(forcedValues || values);
      if (!ok) {
        setFormError('Something went wrong, please try again');
        setIsSubmitting(false);
      }
      return {
        ok,
      };
    },
    [submitForm]
  );

  const handleContinueClick = React.useCallback(async () => {
    const { ok } = await handleSubmit(values);
    if (ok) onContinue(null, onboardingState);
  }, [handleSubmit, values]);

  const handleETFSClick = React.useCallback(async () => {
    const { ok } = await handleSubmit(['etfs']);
    if (ok) onContinue(null, onboardingState);
  }, [handleSubmit]);

  React.useEffect(() => {
    logMetricsTrackingEvent('View Select Interests Form')();
  }, []);

  if (!isReady) return <LoadingPage />;

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.interestsSelection}>
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>What would you like to invest in?</H5>
          </Heading>
          <Message>
            <Body5>(Choose as many as you'd like)</Body5>
          </Message>

          <InputWrapper>
            <MultiSelect
              ItemComponent={MultiSelectItem}
              items={interestsItems}
              name={'interests'}
              values={values}
              onChange={onMultiChange}
              error={null}
            />
          </InputWrapper>

          <Actions>
            <FormState error={formError} isSubmitting={isSubmitting} />
            <Container fullWidth centerAll>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleContinueClick}>
                Continue
              </FlatButton>
            </Container>
            <Message style={{ paddingTop: '12px' }}>
              <Body5 thin isLowContrast>
                Not sure?
              </Body5>
            </Message>
            <Container centerAll fullWidth>
              <FlatButton transparent isDisabled={isSubmitting} fullWidth onClick={handleETFSClick}>
                Start with a collection of diversified etf&apos;s
              </FlatButton>
            </Container>
          </Actions>
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
