import React from 'react';
import styled from 'styled-components';
import { SOCURE_DECISION_STATUS, SOCURE_DOCUMENT_TYPE_FLAGS } from '@src/constants/types/Socure';
import { TrackingEvents } from '@src/utils/tracking/events';
import { setIdentifyServiceCooldown } from '@src/utils/liveTrading';
import ContactSupportMessage from '../../../components/UI/ContactSupportMessage';
import { isMobileBrowser } from '@src/helpers/generalHelpers';
import { useIsOnboarding } from '../../hooks/user';
import PageLoading from '../../../components/PageLoading';
import { Page } from '../../components/layout';
import UploadDocumentReferenceTable from '../../../containers/Account/DocumentUpload/components/UploadDocumentReferenceTable';
import { FlatButton } from '../../components/buttons';
import { fetchFurtherDocumentationStatus } from '@src/actions';
import { useCurrentUserId } from '../../hooks/user';
import { H5, Body5, defineFontSettings } from '../../lib/nvstr-common-ui.es';
import DocumentUpload from '../../../containers/Account/DocumentUpload/DocumentUpload';
import { EnterContactAddress } from './EnterContactAddress';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@src/constants/paths';
import { DOC_REQUIREMENT_LOOKUP } from '../../constants';
import { LocationContext } from '@src/context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { getMobileOperatingSystem, isMobileDevice } from '@src/helpers/usefulFuncs';
import { Container, Spacing } from '../../components/ui';
import { AppIcon } from '../../components/ui/AppIcon';
import { Text } from '../../components/text';
import {
  PRODUCT_ANDROID_APP_STORE_URL,
  PRODUCT_DISPLAY_FULL_NAME,
  PRODUCT_IOS_APP_STORE_URL,
  PRODUCT_NAMES,
  PRODUCT_NAME,
} from '@src/appConfig';

const AlternateOptionWrapper = styled.div`
  text-align: left;

  max-width: 500px;
  margin: 0 auto;
`;
const Paragraph = styled.div`
  padding-top: 8px;
`;
const UploadToolWrapper = styled.div`
  margin-top: 24px;
  padding-top: 16px;

  border-top: 1px solid ${({ theme }) => theme.themeColors.border};

  table {
    margin-top: 24px;
    margin-bottom: 24px;

    width: 100%;
    max-width: 440px;
  }

  .document-upload-form {
    fieldset {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    input {
      width: 1px;
      height: 1px;
      background-color: transparent;
      border: none;
    }
  }

  .upload-actions {
    text-align: center;
  }
`;
const Action = styled.div`
  padding-top: 24px;
  text-align: center;
`;
const Header = styled.div`
  h5 {
    margin: 0;
  }
`;
const TextWrapper = styled.div`
  width: calc(100% - 50px);
`;
const DownloadAppMessageWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.themeColors.border};
  border-radius: 5px;
  padding: 16px;
  margin-top: 24px;
  text-align: center;
`;
const SizeContainer = styled.div`
  margin: 0 auto;
  max-width: 360px;
`;

const UPLOAD_OPTIONS_TEST_GROUP_NAMES = {
  // DocV: 'DocV',
  MobileAppManualUpload: 'MobileAppManualUpload',
};

const getAreDocsNeededKnown = (flags) => {
  return (
    flags[SOCURE_DOCUMENT_TYPE_FLAGS.IDENTITY] ||
    flags[SOCURE_DOCUMENT_TYPE_FLAGS.REQUIRES_SSN_CARD] ||
    flags[SOCURE_DOCUMENT_TYPE_FLAGS.REQUIRES_NON_US_CITIZEN_CARD] ||
    flags[SOCURE_DOCUMENT_TYPE_FLAGS.SELFIE]
  );
};

const getDocsRequired = (flags) => {
  const docs = [];

  if (flags[SOCURE_DOCUMENT_TYPE_FLAGS.IDENTITY]) docs.push('identity');
  if (flags[SOCURE_DOCUMENT_TYPE_FLAGS.REQUIRES_SSN_CARD]) docs.push('social security number');
  if (flags[SOCURE_DOCUMENT_TYPE_FLAGS.REQUIRES_NON_US_CITIZEN_CARD]) docs.push('immigration status');

  return docs;
};

const MissingDecisionStatus = ({ isOnboarding, onContinue, decisionStatus }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    // IF NOTHING IS NEEDED NAVIGATE AWAY
    isOnboarding ? onContinue() : navigate(ROUTES.DASHBOARD.build());
  }, []);

  return null;
};

const AlternateOption = ({ flags, onCompletedUpload }) => {
  const isOnboarding = useIsOnboarding();
  const requiredDocs = getDocsRequired(flags);

  return (
    <AlternateOptionWrapper>
      <Paragraph>
        <Body5 isSmall>Use the upload tool below to provide the required additional documentation.</Body5>
      </Paragraph>

      <UploadToolWrapper>
        <DocumentUpload requiredDocs={requiredDocs} />
      </UploadToolWrapper>

      {isOnboarding && (
        <Action>
          <Container fullWidth centerAll>
            <FlatButton fullWidth transparent onClick={onCompletedUpload}>
              Skip/Continue
            </FlatButton>
          </Container>
        </Action>
      )}
    </AlternateOptionWrapper>
  );
};

const DocVUploadOption = ({ qrCode, onMobileBrowser, imgCaptureUrl, logStartUploadClick, onCompletedUpload }) => {
  const isOnMobileDevice = isMobileDevice();

  const fontSettings = defineFontSettings(
    {
      fontSize: '14px',
      lineHeight: '18px',
    },
    {
      fontSize: '16px',
      lineHeight: '20px',
    }
  );

  const handleDownloadClick = () => {
    const mobileOS = getMobileOperatingSystem();
    const url = mobileOS === 'ios' ? PRODUCT_IOS_APP_STORE_URL : PRODUCT_ANDROID_APP_STORE_URL;
    window?.open(url, '_blank').focus();
    TrackingEvents.application.CLICK_DOWNLOAD_APP_TO_UPLOAD_DOCS.send();
  };

  // const handleSendAppEmailClick = () => {
  //   const quickLink = 'https://share.tornado.com/YLzlSsPIYub';
  //   window?.open(quickLink, '_blank').focus();
  //   TrackingEvents.application.CLICK_SEND_EMAIL_DOWNLOAD_APP_TO_UPLOAD_DOCS.send();
  // };

  return (
    <div>
      {isOnMobileDevice ? (
        <DownloadAppMessageWrapper>
          <SizeContainer>
            <Container row verticallyCenter>
              <AppIcon />
              <TextWrapper style={{ textAlign: 'left' }}>
                <Spacing left={16}>
                  <Text fontSettings={fontSettings}>
                    Download {PRODUCT_NAME === PRODUCT_NAMES.TheStreet ? '' : 'the '}
                    {PRODUCT_DISPLAY_FULL_NAME} app to securely upload the required documents
                  </Text>
                </Spacing>
              </TextWrapper>
            </Container>

            <Container top={24} fullWidth centerAll>
              <FlatButton fullWidth onClick={handleDownloadClick}>
                Download App
              </FlatButton>
            </Container>
          </SizeContainer>
        </DownloadAppMessageWrapper>
      ) : (
        <DownloadAppMessageWrapper>
          <SizeContainer>
            <Container row verticallyCenter>
              <AppIcon />
              <TextWrapper style={{ textAlign: 'left' }}>
                <Spacing left={16}>
                  <Text fontSettings={fontSettings}>
                    Download {PRODUCT_NAME === PRODUCT_NAMES.TheStreet ? '' : 'the '}
                    {PRODUCT_DISPLAY_FULL_NAME} app on iOS or Android to securely upload the required documents
                  </Text>
                </Spacing>
              </TextWrapper>
            </Container>
          </SizeContainer>
        </DownloadAppMessageWrapper>
      )}
    </div>
  );
};

export const ManualDocumentUpload = ({ flags, qrCode, imgCaptureUrl, onMobileBrowser, onContinue }) => {
  const currentUserId = useCurrentUserId();
  const isOnboarding = useIsOnboarding();

  const [uploadOptionsTestGroupName] = React.useState(UPLOAD_OPTIONS_TEST_GROUP_NAMES.DocV);

  React.useEffect(() => {
    TrackingEvents.liveTradingSignUp.VIEW_ADDITIONAL_DOCUMENTS_SUBMISSION_PAGE.send({
      'Is Onboarding': isOnboarding,
      'Test Group': uploadOptionsTestGroupName,
    });
  }, []);

  // const logStartUploadClick = React.useCallback(
  //   () => {
  //     TrackingEvents.liveTradingSignUp.VIEW_ADDITIONAL_DOCUMENTS_SUBMISSION_PAGE.send({
  //       'Is Onboarding': isOnboarding,
  //     });
  //   },
  //   [isOnboarding]
  // );

  const onCompletedUpload = React.useCallback(
    () => {
      TrackingEvents.liveTradingSignUp.CLICK_COMPLETE_ADDITIONAL_DOCUMENTS_SUBMISSION_PAGE.send({
        'Is Onboarding': isOnboarding,
      });

      setIdentifyServiceCooldown();
      onContinue();
    },
    [isOnboarding, onContinue]
  );

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.socureDocumentUpload}>
      <Page width="500px">
        <Header>
          <H5>Your brokerage application</H5>
          <div style={{ maxWidth: '320px', paddingTop: '4px' }}>
            <Body5 isSmall isLowContrast>
              Further documentation is required to open your brokerage account.
            </Body5>
          </div>
        </Header>
        {getAreDocsNeededKnown(flags) && (
          <>
            <div style={{ padding: '16px 0 16px 0', textAlign: 'left' }}>
              <Body5 isSmall>{'Please upload the documents below.'}</Body5>
            </div>
            <UploadDocumentReferenceTable
              requiredDocumentCategoryList={getDocsRequired(flags)}
              documentCategoryToAcceptableDocumentListLookup={DOC_REQUIREMENT_LOOKUP}
            />
          </>
        )}

        <AlternateOption flags={flags} onCompletedUpload={onCompletedUpload} />

        <div style={{ textAlign: 'center' }}>
          <ContactSupportMessage />
        </div>
      </Page>
    </LocationContext.Provider>
  );
};

export const AddressResubmit = ({ onContinue }) => {
  const isOnboarding = useIsOnboarding();

  React.useEffect(() => {
    TrackingEvents.liveTradingSignUp.VIEW_CONTACT_RESUBMIT_FORM.send({
      'Is Onboarding': isOnboarding,
    });
  }, []);

  const handleContinue = React.useCallback(() => {
    TrackingEvents.liveTradingSignUp.RESUBMITTED_ADDRESS_SUCCESSFULLY.send({
      'Is Onboarding': isOnboarding,
    });

    onContinue();
  }, []);

  return <EnterContactAddress onContinue={handleContinue} onboardingState={null} isResubmit />;
};

export const UpdateUnverifiedInfo = ({ onboardingState, onContinue }) => {
  const navigate = useNavigate();
  const isOnboarding = useIsOnboarding();

  const [isReady, setIsReady] = React.useState(false);

  const [decisionStatus, setDecisionStatus] = React.useState(null);
  const [flags, setFlags] = React.useState(null);
  const [qrCode, setQrCode] = React.useState(null);
  const [imgCaptureUrl, setImgCaptureUrl] = React.useState(null);
  const [onMobileBrowser] = React.useState(isMobileBrowser());

  React.useEffect(() => {
    const setup = async () => {
      const {
        ok,

        isUserActionRequired,
        isRejected,

        qrCode,
        imgCaptureUrl,
        flags,
        decisionStatus,
      } = await fetchFurtherDocumentationStatus();

      if (ok) {
        if (!isUserActionRequired || isRejected) {
          if (isOnboarding) {
            onContinue(null, onboardingState);
          }
        }

        setDecisionStatus(decisionStatus);
        setFlags(flags);
        // setQrCode(qrCode);
        setImgCaptureUrl(imgCaptureUrl);
        setIsReady(true);
      } else {
        setTimeout(setup, 1500);
      }
    };
    setup();
  }, []);

  const onCompletedUpload = React.useCallback(
    () => {
      setIdentifyServiceCooldown();

      if (isOnboarding) {
        onContinue(null, onboardingState);
      }
      {
        navigate(ROUTES.DASHBOARD.build());
      }
    },
    [isOnboarding, onContinue]
  );

  if (!isReady) return <PageLoading />;

  if (decisionStatus === SOCURE_DECISION_STATUS.FURTHER_DOCUMENTS_REQUIRED) {
    return (
      <ManualDocumentUpload
        decisionStatus={decisionStatus}
        flags={flags}
        qrCode={qrCode}
        imgCaptureUrl={imgCaptureUrl}
        onMobileBrowser={onMobileBrowser}
        onContinue={onCompletedUpload}
      />
    );
  }

  if (decisionStatus === SOCURE_DECISION_STATUS.ADDRESS_RESUBMIT) {
    return (
      <LocationContext.Provider value={TRACKING_ROUTE_NAMES.addressResubmit}>
        <AddressResubmit onContinue={onCompletedUpload} />
      </LocationContext.Provider>
    );
  }

  return (
    <MissingDecisionStatus isOnboarding={isOnboarding} decisionStatus={decisionStatus} onContinue={onCompletedUpload} />
  );
};
