import React from 'react';
import ScriptLoader from '@src/containers/ExtraFunctionalityComponents/ScriptLoader';
import { logMetricsTrackingEvent } from '@src/actions';
import { useColorTheme } from '@src/main/hooks/util';
import { ELEMENT_COLOR_NAMES } from '@src/main/constants/elementColors';
import { createTimeInstance } from '@src/helpers/timeHelpers';
import { SUPPORT_CHAT_BOT_URI } from '../../../appConfig';

let lastOpenAt = null;
const setLastOpenAt = (v) => (lastOpenAt = v);

export const SupportChatBot = () => {
  const colorTheme = useColorTheme();
  const [pollForWidget, setPollForWidget] = React.useState(false);

  React.useEffect(() => {
    const pollFindWidget = () => {
      const node = findWidget();
      styleChatButton(node)
        .then((r) => {
          setPollForWidget(false);
        })
        .catch((e) => {
          setTimeout(pollFindWidget, 1);
        });
    };
    if (pollForWidget) {
      pollFindWidget();
    }
  }, [pollForWidget]);

  React.useEffect(() => {
    try {
      const pollFindWidget = () => {
        const node = findWidget();
        styleChatButton(node);
      };
      pollFindWidget();

      const node = findWidget();
      styleChatButton(node);
    } catch (e) {
      // console.error(e)
    }
  }, [colorTheme]);

  const styleChatButton = (iframeNode) => {
    if (!iframeNode) return Promise.reject();
    const button = $(iframeNode).contents().find('button').first();
    if (button.length) {
      button.css('background-color', colorTheme[ELEMENT_COLOR_NAMES.primaryBtnColor]);
      button.find('path').each(function (i) {
        let _this = this;
        try {
          $(this).css('fill', colorTheme[ELEMENT_COLOR_NAMES.primaryBtnTextColor]);
        } catch (e) {
          console.error(e);
          console.log(_this);
        }
      });
      return Promise.resolve();
    }
    return Promise.reject();
  };

  const findWidget = () => {
    const chatBotIframeTitle = 'Button to launch messaging window'; // fragile
    const $chatBotWidgetRoot = $(`iframe[title='${chatBotIframeTitle}']`);
    const node = $chatBotWidgetRoot[0];
    if (node) {
      const onFrameDOMContentLoaded = () => null;
      node.contentWindow.addEventListener('DOMContentLoaded', onFrameDOMContentLoaded, true);
    }
    return node;
  };

  const handleMessengerOpen = () => {
    const now = createTimeInstance().valueOf();
    setLastOpenAt(now);
    logMetricsTrackingEvent('Opened ChatBot')();
  };

  const handleMessengerClose = () => {
    let p = {};
    if (lastOpenAt) {
      const now = createTimeInstance().valueOf();
      p['Time Open Seconds'] = Math.round((now - lastOpenAt) / 1000);
    }
    logMetricsTrackingEvent('Closed ChatBot', p)();
  };

  const onChatBotLoad = () => {
    if (window?.zE) {
      window.zE('messenger:on', 'open', handleMessengerOpen);
      window.zE('messenger:on', 'close', handleMessengerClose);
    }

    setPollForWidget(true);
  };

  const onChatBotLoadFail = (e) => {
    console.error(e);
    logMetricsTrackingEvent('Chat bot load fail', { Message: e?.message })();
  };

  return (
    <ScriptLoader
      id="ze-snippet"
      uri={SUPPORT_CHAT_BOT_URI}
      type={'text/javascript'}
      onLoad={onChatBotLoad}
      onLoadFail={onChatBotLoadFail}
    />
  );
};
