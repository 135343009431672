import React, { Component } from 'react';

import { formatSecurityDataValue } from '../../helpers/securitiesHelpers';
import { convictionConversion } from '../../helpers/ideaHelpers';
import styled from 'styled-components';

const Wrapper = styled.div`
  .rating-circle {
    border-color: ${({ theme }) => theme.themeColors.primaryCtaButton};

    &.rating-filled-hover {
      //opacity: 0.8;
    }
    &.rating-filled-hover,
    &.rating-filled {
      background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }
`;

class Rating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovering: null,
    };
  }

  shouldBeFilled = (i, value) => {
    return i <= convictionConversion({ name: value.name });
  };

  shouldBeFilledHover = (i, value) => {
    return i <= value;
  };

  mouseOverRating = (number) => {
    return this.handleMouseOver.bind(this, number);
  };

  handleMouseOver = (number) => {
    this.setState(() => ({
      hovering: number,
    }));
  };

  handleMouseOut = () => {
    this.setState(() => ({
      hovering: null,
    }));
  };

  setupRatingClick = (rating) => {
    const name = this.props.name;
    return this.props.handleRatingClick.bind(this, name, rating);
  };

  handleRatingClick = (i) => {
    const name = this.props.name;
    return () => {
      this.props.handleRatingClick(name, i);
      if (this.props.saveOnChange) {
        // HACK: HARDCODED TO ONLY WORK FOR CONVICTION
        const data = {
          [`${this.props.objectType}_id`]: this.props.getObjectId(this.props.objectType),
          [name]: { ...convictionConversion({ id: i }) },
        };
        this.props.saveOnChange(data);
      }
      $('.idea-value').blur();
    };
  };

  renderRatings = (totalCount) => {
    const name = this.props.name;
    const value = this.props.getValue(name);
    const array = [];

    for (let i = 0; i < totalCount; i++) {
      array.push(i);
    }

    return array.map((el, i) => (
      <Wrapper
        key={`rating-${name}-${i}`}
        name={name}
        className={`rating-circle-container`}
        onClick={this.handleRatingClick(i)}
        onMouseOver={this.mouseOverRating(i)}
        onMouseOut={this.handleMouseOut}
      >
        <div
          className={`rating-circle
            ${this.shouldBeFilled(i, value) && this.state.hovering === null ? 'rating-filled' : ''}
            ${this.shouldBeFilledHover(i, this.state.hovering) ? 'rating-filled-hover' : ''}`}
        />
      </Wrapper>
    ));
  };

  renderRatingToDescriptionText = () => {
    const name = this.props.name;
    const value =
      this.state.hovering !== null ? convictionConversion({ id: this.state.hovering }) : this.props.getValue(name);
    return formatSecurityDataValue(value, name);
  };

  render() {
    const name = this.props.name;
    return (
      <div
        className={`rating-container ${this.state.hovering !== null ? 'hovering' : ''}`}
        onMouseOut={this.handleMouseOut}
      >
        {this.renderRatings(this.props.maxRating)}
        <div className="ratings-conversion secondary-text-color">{this.renderRatingToDescriptionText()}</div>
      </div>
    );
  }
}

export default Rating;
