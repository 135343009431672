import {
  ADD_USERS,
  UPDATE_USER,
  ADD_CONNECTIONS,
  ADD_THOUGHT_LEADERS,
  ADD_PEOPLE_YOU_MAY_KNOW,
  HIDE_CONNECTION_SUGGESTION,
  SEND_CONNECTION_REQUEST,
  CONNECTION_REQUEST_FAILED,
  ACCEPT_CONNECTION_REQUEST,
  ACCEPT_CONNECTION_REQUEST_FAILED,
  IGNORE_CONNECTION_REQUEST,
  IGNORE_CONNECTION_REQUEST_FAILED,
  ADD_TOP_CONNECTION_PERFORMANCES,
  QUERY_USERS,
  USERS_QUERY_RESULTS,
  LOADING_PENDING_CONNECTIONS,
  COMPLETE_LOADING_PENDING_CONNECTIONS,
} from '../constants';

import { convertArrayToObject } from '../helpers/generalHelpers';
import { moment } from '../helpers/timeHelpers';

const defaultState = {
  loadedPendingConnectionsAt: null,

  userList: {},

  peopleYouMayKnow: {
    loading: true,
    usersYouMayKnow: [],
  },

  connectionsPerformance: { loading: true }, // top connections performance

  queries: [],
  queryResults: {},
};

export default function usersReducer(state = defaultState, action) {
  let payload, query, results, user, users, connectUserId;

  switch (action.type) {
    case ADD_CONNECTIONS:
      const userConnections = action.payload;

      if (Array.isArray(userConnections)) {
        const newConnections = convertArrayToObject(userConnections, 'user_id');
        return {
          ...state,
          userList: {
            ...state.userList,
            ...newConnections,
          },
        };
      } else {
        return {
          ...state,
          userList: {
            ...state.userList,
            ...userConnections,
          },
        };
      }

    case ADD_USERS:
      users = action.payload.users;
      if (users) {
        const newUsers = convertArrayToObject(action.payload.users, 'user_id');
        return {
          ...state,
          userList: {
            ...state.userList,
            ...newUsers,
          },
        };
      } else {
        return state;
      }

    case UPDATE_USER:
      user = action.payload.user;
      return {
        ...state,
        userList: {
          ...state.userList,
          [user.user_id]: {
            ...state.userList[user.user_id],
            ...user,
          },
        },
      };

    case ADD_THOUGHT_LEADERS:
      payload = action.payload;
      if (Array.isArray(payload)) {
        const thoughtLeadersArray = payload;
        const newThoughtLeaders = convertArrayToObject(thoughtLeadersArray, 'user_id');
        return {
          ...state,
          userList: { ...state.userList, ...newThoughtLeaders },
        };
      } else {
        return { ...state, userList: { ...state.userList, ...payload } };
      }

    case ADD_PEOPLE_YOU_MAY_KNOW:
      const { usersYouMayKnow } = action.payload;

      return {
        ...state,
        peopleYouMayKnow: { loading: false, usersYouMayKnow },
      };

    case HIDE_CONNECTION_SUGGESTION:
      const { dismissUserId } = action.payload;
      const filteredUsersYouMayKnow = state.peopleYouMayKnow.usersYouMayKnow.filter(
        (userYouMayKnow) => userYouMayKnow.user_id.toString() !== dismissUserId.toString()
      );
      return {
        ...state,
        peopleYouMayKnow: {
          ...state.peopleYouMayKnow,
          usersYouMayKnow: filteredUsersYouMayKnow,
        },
      };

    case QUERY_USERS:
      query = action.payload;
      return {
        ...state,
        queries: [...state.queries, query],
      };

    case USERS_QUERY_RESULTS:
      query = action.payload.query;
      results = action.payload.results;

      return {
        ...state,
        queryResults: {
          ...state.queryResults,
          [query]: [...results],
        },
      };

    case ADD_TOP_CONNECTION_PERFORMANCES:
      const connectionsPerformance = action.payload.connectionsPerformance;
      return {
        ...state,
        connectionsPerformance,
      };

    case SEND_CONNECTION_REQUEST:
      connectUserId = action.payload.connectUserId;
      return {
        ...state,
        userList: {
          ...state.userList,
          [connectUserId]: {
            ...state.userList[connectUserId],
            connection_status_to_current_user: 'request_sent',
          },
        },
      };

    case CONNECTION_REQUEST_FAILED:
      connectUserId = action.payload.connectUserId;
      return {
        ...state,
        userList: {
          ...state.userList,
          [connectUserId]: {
            ...state.userList[connectUserId],
            connection_status_to_current_user: null,
          },
        },
      };

    case ACCEPT_CONNECTION_REQUEST:
      connectUserId = action.payload.connectUserId;
      return {
        ...state,
        userList: {
          ...state.userList,
          [connectUserId]: {
            ...state.userList[connectUserId],
            connection_status_to_current_user: 'connected',
          },
        },
      };

    case ACCEPT_CONNECTION_REQUEST_FAILED:
      connectUserId = action.payload.connectUserId;
      return {
        ...state,
        userList: {
          ...state.userList,
          [connectUserId]: {
            ...state.userList[connectUserId],
            connection_status_to_current_user: 'request_received',
          },
        },
      };

    case IGNORE_CONNECTION_REQUEST:
      connectUserId = action.payload.connectUserId;
      return {
        ...state,
        userList: {
          ...state.userList,
          [connectUserId]: {
            ...state.userList[connectUserId],
            connection_status_to_current_user: null,
          },
        },
      };

    case IGNORE_CONNECTION_REQUEST_FAILED:
      connectUserId = action.payload.connectUserId;
      return {
        ...state,
        userList: {
          ...state.userList,
          [connectUserId]: {
            ...state.userList[connectUserId],
            connection_status_to_current_user: 'request_received',
          },
        },
      };

    case LOADING_PENDING_CONNECTIONS:
      // TODO: disabled
      return {
        ...state,
        loadingPendingConnections: false,
      };

    case COMPLETE_LOADING_PENDING_CONNECTIONS:
      return {
        ...state,
        loadingPendingConnections: false,
        loadedPendingConnectionsAt: moment().valueOf(),
      };

    default:
      return state;
  }
}
