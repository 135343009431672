import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useCurrentUser } from '../../hooks/user';
import { fetchDataTypes, logMetricsTrackingEvent, updateUserProperty } from '../../../actions';
import { PRODUCT_NAME } from '@src/appConfig';
import { setTrackingUserProperty } from '../../../utils/tracking/amplitude';
import { sendFacebookLTVEvent } from '../../../constants/facebookTracking';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import { H5, Body5, createDropdownItem, FloatingLabelDropdown } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { TrackingEvents } from '../../../utils/tracking/events';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const InputWrapper = styled.div`
  padding-top: 40px;

  select {
    border-top: none;
    border-left: none;
    border-right: none;

    -webkit-appearance: auto;
    background: transparent !important;
  }
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 24px;
`;

const Actions = styled.div`
  padding-top: 64px;
  text-align: center;
`;

const getInvestingExperienceFromUser = (user) =>
  isNaN(user.investing_experience?.id) ? '' : user.investing_experience?.id;

const hasInvestedBefore = (investing_experience) => {
  return investing_experience !== null && investing_experience !== 'No experience';
};

export const EnterInvestingExperience = ({ onContinue, onboardingState }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const [isReady, setIsReady] = React.useState(false);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [formError, setFormError] = React.useState(null);
  const [investingExperience, setInvestingExperience] = React.useState(getInvestingExperienceFromUser(currentUser));
  const [investingExperienceOptions, setInvestingExperienceOptions] = React.useState(null);

  const getInvestingExperienceSelectionTypes = React.useCallback(async () => {
    const response = await fetchDataTypes('investing_experience')(dispatch);
    if (response && response.investing_experience_types) {
      const types = response.investing_experience_types;
      setInvestingExperienceOptions(types);
      setIsReady(true);
    } else {
      setTimeout(() => {
        getInvestingExperienceSelectionTypes();
      }, 1000);
    }
  }, []);

  React.useEffect(() => {
    getInvestingExperienceSelectionTypes();

    const event = 'View Onboarding';
    const properties = {
      Context: 'Investing Experience Form',
    };
    logMetricsTrackingEvent(event, properties)();
  }, []);

  const handleContinueClick = React.useCallback(
    async () => {
      if (isSubmitting) {
        return;
      }

      if (investingExperience === '') {
        setError('Selection is required');
        logMetricsTrackingEvent('Failed Live Account Validations', {
          Fields: ['investing_experience', 'Required'],
          'Form Name': 'investing experience',
        })();
        return;
      }
      const investing_experience = investingExperienceOptions[investingExperience];

      setIsSubmitting(true);
      const ok = await updateUserProperty({
        investing_experience,
      })(dispatch);
      if (ok) {
        TrackingEvents.user.SET_INVESTING_EXPERIENCE.send({
          Value: investingExperience,
        });

        const hasInvestingExperience = hasInvestedBefore(investingExperience);
        if (hasInvestingExperience) sendFacebookLTVEvent(3);

        onContinue(null, onboardingState);
      } else {
        setFormError('Something went wrong, please try again.');
        setIsSubmitting(false);
      }
    },
    [onContinue, onboardingState, investingExperience, investingExperienceOptions, isSubmitting]
  );

  if (!isReady) return <LoadingPage />;

  const handleDropdownSelection = (name) => (v) => {
    setInvestingExperience(v);
  };

  const dropdownItems = [
    createDropdownItem({ text: 'Select your experience level', value: '' }),
    createDropdownItem({ text: '---', value: '---', disabled: true }),

    ...investingExperienceOptions.map((t) => createDropdownItem({ text: t.description, value: t.id })),
  ];

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.investingExperienceSelect}>
      <Page width={'500px'}>
        <ContentWrapper>
          <Heading>
            <H5>How much experience do you have investing?</H5>
          </Heading>

          <InputWrapper>
            <FloatingLabelDropdown
              name={'investing_experience'}
              value={investingExperience}
              label={''}
              items={dropdownItems}
              onChange={handleDropdownSelection}
              error={error}
            />
          </InputWrapper>

          <Message>
            <Body5>
              {`${PRODUCT_NAME} makes sophisticated investing accessible to everyone from first time investors to seasoned experts.`}
            </Body5>
          </Message>

          <Actions>
            <FormState error={formError} isSubmitting={isSubmitting} />
            <Container fullWidth centerAll>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleContinueClick}>
                Continue
              </FlatButton>
            </Container>
          </Actions>
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
