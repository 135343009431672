import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { PRODUCT_NAME } from '@src/appConfig';
import { createBasicErrorModal } from '../../constants/modals';
import * as Actions from '../../actions/index';

import Button from '../../components/buttons/Button';
import Page from '../../components/layout/Page';
import PageLoading from '../../components/PageLoading';

import Icon from '../../components/misc/Icon';
import UserList from '../UI/UserList/UserList';
import InviteFriends from '../People/InviteFriends';

import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import LinkTo from '../Links/LinkTo';
import { returnPathTo } from '../../constants/paths';
import { isCurrentUserOnboarding } from '../../helpers/currentUserHelpers';
import { REFERRAL_SHARE_LINK_CONTEXTS } from '../../main/hooks/user/useCurrentUserReferral';
import { FlatButton } from '../../main/components/buttons';

class ImportResults extends Component {
  constructor() {
    super();
    this.state = {
      _sendingInvitesEmailList: [],
      _sentInvitesEmailList: [],
    };
  }

  componentDidMount() {
    this.props.actions.getImportContacts();
  }

  render() {
    return this._isLoading() ? this._renderLoading() : this._renderPage();
  }

  _isLoading = () => this._returnImportConnectionsStore().isLoadingContacts;

  _isContactsDataLoaded = () => 'registeredUsers' in this._returnImportContactData();

  _renderLoading = () => <PageLoading />;

  _renderPage = () => {
    if (!this._isContactsDataLoaded()) {
      return null;
    }

    return (
      <Page className="import-results-page page">
        {this._renderHeader()}
        {this._renderContacts()}
        <div
          style={{
            textAlign: 'center',
            paddingBottom: '25px',
          }}
        >
          <LinkTo
            to={this._isOnboarding() ? '/portfolio/funding' : returnPathTo('dashboard')}
            className="skel-btn skel-btn-primary-color btn-small-tall"
            child={'Continue'}
          />
        </div>
      </Page>
    );
  };

  _renderHeader = () => {
    return (
      <div className={`import-header`}>
        <Icon icon={'fa-check'} colorClassName={'success-text-color'} size="small" />
        <span className={``}>Import Successful</span>
      </div>
    );
  };

  _renderContacts = () => {
    return (
      <div className={`contact-import-list`}>
        {this._renderNonregisteredUsers()}
        {this._renderRegisteredUsers()}
      </div>
    );
  };

  _renderNonregisteredUsers = () => {
    return (
      <div className={`non-registered-users-invite-list`}>
        {this._areUsersToInvite() ? (
          <div className={`invite-users-emails`}>
            <div className={`heading`}>
              <h2>Invite friends to join you on {PRODUCT_NAME}</h2>
            </div>
            {this._renderInviteAll()}
            <div className={`connections-list-container`}>
              <div className="import-invite-list">
                {this._returnContactsToInvite().map((userData) => this._renderNonregisteredUser(userData))}
              </div>
            </div>
          </div>
        ) : (
          this._renderNoContactsToInvite()
        )}
      </div>
    );
  };

  _renderNonregisteredUser = (userData) => {
    const { name, email } = userData;
    return (
      <div
        key={`invite-user-import-email-${email}`}
        className={`user-list-item-container component-bg-with-hover-feedback border-accent`}
      >
        <div className={`invite-email-identifier`}>
          {isUndefinedOrNull(name) || name !== '' ? (
            <div className={`email-contact-identifier-container`}>
              <div className={`email-contact-name  `}>{name}</div>
              <div className={`contact-email-address secondary-text-color`}>{email}</div>
            </div>
          ) : (
            <div className={`contact-email-address email-address-only  `}>{email}</div>
          )}
        </div>
        <div className={`connection-request-container invite-to-nvstr-action-container`}>
          {this._renderInviteButton(email)}
        </div>
      </div>
    );
  };

  _renderInviteButton = (email) => {
    if (this._wasInviteSentToEmail(email)) {
      return (
        <FlatButton disabled transparent handleClick={() => false}>
          Sent
        </FlatButton>
      );
    }
    if (this._isInviteSendingToEmail(email)) {
      return (
        <FlatButton disabled onClick={() => false}>
          Sending
        </FlatButton>
      );
    }
    return <FlatButton onClick={this._bindEmailToInviteClickHandler(email)}>Invite</FlatButton>;
  };

  _renderInviteAll = () => {
    return (
      <FlatButton className="invite-all-unregistered-import-contacts-container" onClick={this._handleInviteAll}>
        Invite All
      </FlatButton>
    );
  };

  _logSendingInvite = (email) => {
    this.setState((prevState) => ({
      _sendingInvitesEmailList: [...prevState._sendingInvitesEmailList, email],
    }));
  };

  _clearSendingInviteStatus = (email) => {
    this.setState((prevState) => ({
      _sendingInvitesEmailList: [
        ...prevState._sendingInvitesEmailList.filter((sendingEmail) => sendingEmail !== email),
      ],
    }));
  };

  _logSentInvite = (email) => {
    this.setState((prevState) => ({
      _sentInvitesEmailList: [...prevState._sentInvitesEmailList, email],
    }));
  };

  _returnSendingInvitesEmailList = () => this.state._sendingInvitesEmailList;

  _returnSentInvitesEmailList = () => this.state._sentInvitesEmailList;

  _isInviteSendingToEmail = (email) =>
    this._returnSendingInvitesEmailList().some((sendingEmail) => sendingEmail === email);

  _wasInviteSentToEmail = (email) => this._returnSentInvitesEmailList().some((sentEmail) => sentEmail === email);

  _inviteUserByEmail = (email) => {
    this._logSendingInvite(email);
    return this.props.actions
      .sendUserInvite(email)
      .then((response) => {
        const alreadyInvited = response && response.data && response.data.status === 'Invite Friend - Repeat';
        const inviteWasASuccess =
          response &&
          response.data &&
          response.data.message === 'Thank you. You can let your friend know that an invite is on the way!';
        if (alreadyInvited) {
          this._logSentInvite(email);
          const modalMessage = 'Could not send invite at this time. Please try again later.';
          const errorMessage = (response && response.data && response.data.message) || modalMessage;
          this._showErrorSendingInviteModal(errorMessage);
        }
        if (!inviteWasASuccess) {
          this._clearSendingInviteStatus(email);
          const modalMessage = 'Could not send invite at this time. Please try again later.';
          const errorMessage = (response && response.data && response.data.message) || modalMessage;
          this._showErrorSendingInviteModal(errorMessage);
        } else {
          this._logSentInvite(email);
        }
      })
      .catch((err) => {
        this._showErrorSendingInviteModal('Could not send invite at this time. Please try again later.');
        console.error(err);
      });
  };

  _showErrorSendingInviteModal = (errMessage) => {
    const modal = {
      contentComponent: createBasicErrorModal(errMessage),
      dismissable: true,
      size: 'wide',
    };
    this.props.actions.showModal(modal);
  };

  _handleInviteAll = () => {
    const emailsNotInvited = this._returnEmailsToInvite().filter(
      (email) => !this._isInviteSendingToEmail(email) && !this._wasInviteSentToEmail(email)
    );
    const countInvitesSent = emailsNotInvited.length;
    this._logHandleInviteAllClick(countInvitesSent);
    emailsNotInvited.forEach((email) => this._inviteUserByEmail(email));
  };

  _renderNoContactsToInvite = () => {
    return (
      <div className={`no-contacts-to-invite`}>
        <div className={`no-users`}>We couldn't find any {PRODUCT_NAME} users in your imported connections.</div>
        <div className={`no-users`}>Invite your friends to join {PRODUCT_NAME}!</div>

        <div className={`invite-friends-manual`}>
          <InviteFriends
            shareLinkContext={REFERRAL_SHARE_LINK_CONTEXTS.GMAIL_IMPORT}
            placeholder={'Enter Email...'}
            inviteContext={'Import Connections - No Contacts'}
            hideHeading
          />
        </div>
      </div>
    );
  };

  _renderRegisteredUsers = () => {
    return (
      <div className={`friends-list`}>
        {this._areUsersToConnectWith() && (
          <div className={`heading`}>
            <h2>Connect with friends who are already on {PRODUCT_NAME}</h2>
            <UserList
              userList={this._returnUsersToConnectTo()}
              renderNoUsers={this._renderNoUsers}
              viewingContext={'Import Connections'}
            />
          </div>
        )}
      </div>
    );
  };

  _renderNoUsers = () => {
    // doesn't get call anyhow, since render checks for no users before
    return null;
  };

  _bindEmailToInviteClickHandler = (email) => () => this._handleInviteClick(email);

  _handleInviteClick = (email) => {
    this._inviteUserByEmail(email);
    this._logHandleInviteClick();
  };

  _areUsersToConnectWith = () => this._returnUsersToConnectTo().length > 0;

  _areUsersToInvite = () => this._returnContactsToInvite().length > 0;

  _returnUsersToConnectTo = () => this._returnImportContactData().registeredUsers || [];

  _returnContactsToInvite = () => this._returnImportContactData().nonregisteredUsers || [];

  _returnEmailsToInvite = () => this._returnContactsToInvite().map((contactData) => contactData.email);

  _returnImportContactData = (props) => this._returnImportConnectionsStore(props || this.props).contactsData;

  _returnImportConnectionsStore = (props) => (props || this.props).importConnections || {};

  _isOnboarding = () => isCurrentUserOnboarding(this._returnCurrentUser());

  _returnCurrentUser = () => this.props.currentUser;

  _logHandleInviteClick = () => {
    const event = 'Clicked Invite From Imports';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logHandleInviteAllClick = (countInvitesSent) => {
    const event = 'Clicked Invite All From Imports';
    const properties = {
      'Count Of Invites': countInvitesSent,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    importConnections: state.importConnections,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportResults);
