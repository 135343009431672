import { createTimeInstance, isWithinTimeLength } from '../helpers/timeHelpers';

import { getItemFromStorage, setItemToStorage, STORAGE_KEYS } from './storage';
import { SOCURE_DECISION_STATUS, SOCURE_DOCUMENT_TYPE_FLAGS } from '../constants/types/Socure';

export const updateSocureFlowData = (data) => {
  const {
    decision,
    image_capture_qr_code: qrCode,
    image_capture_url: imgCaptureUrl,

    requires_ssn_document,
    requires_non_us_citizen_document,
  } = data;

  const wasRejected = decision === 'reject';

  // current assumption is only can receive single decision state
  let decisionStatus = null;
  const isFurtherDocRequired = decision === 'refer';
  const isAddressResubmitRequired = decision === 'resubmit';

  if (isFurtherDocRequired) decisionStatus = SOCURE_DECISION_STATUS.FURTHER_DOCUMENTS_REQUIRED;

  if (isAddressResubmitRequired) decisionStatus = SOCURE_DECISION_STATUS.ADDRESS_RESUBMIT;

  if (wasRejected) decisionStatus = SOCURE_DECISION_STATUS.REJECTED;

  const flags = {
    [SOCURE_DOCUMENT_TYPE_FLAGS.SELFIE]: true, // always needed
    [SOCURE_DOCUMENT_TYPE_FLAGS.IDENTITY]: true, // always needed
    [SOCURE_DOCUMENT_TYPE_FLAGS.REQUIRES_SSN_CARD]: requires_ssn_document,
    [SOCURE_DOCUMENT_TYPE_FLAGS.REQUIRES_NON_US_CITIZEN_CARD]: requires_non_us_citizen_document,
  };

  return {
    ok: true,
    isUserActionRequired: isFurtherDocRequired || isAddressResubmitRequired,
    isFurtherDocRequired,
    wasRejected,
    decision,
    flags,
    isAddressResubmitRequired,
    decisionStatus,
    qrCode,
    imgCaptureUrl,
  };
};

export const getIsIdentifyServiceOnCooldown = () => {
  const expiry = 1000 * 60 * 10;
  const time = getItemFromStorage(STORAGE_KEYS.SOCURE_UPLOAD_COOLDOWN);
  if (time === null) return false;

  const is = isWithinTimeLength(expiry, parseInt(time));
  return is;
};

export const setIdentifyServiceCooldown = () => {
  return setItemToStorage(STORAGE_KEYS.SOCURE_UPLOAD_COOLDOWN, createTimeInstance().valueOf().toString());
};
