import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const usersAdapter = {
  getUser: (userId, config) => {
    const additionalParams = config && config.include ? config.include.map((item) => `include_${item}=true`) : null;
    const baseUrl = `${URL}/users?user_ids=${userId}`;
    const url = additionalParams ? `${baseUrl}&${additionalParams.join('&')}` : baseUrl;
    return sendRequest('get', [url]).then((response) => response);
  },

  getUserIdeaPerformances: (userIds) => {
    return sendRequest('get', [`${URL}/users?user_ids=${userIds}&include_performance=true`]).then(
      (response) => response
    );
  },

  getUserConnections: (userId) => {
    return sendRequest('get', [`${URL}/connections/${userId}`]).then((response) => response);
  },

  getCurrentUserConnections: () => {
    return sendRequest('get', [`${URL}/connections?include_pending=true`]).then((response) => response);
  },

  fetchThoughtLeaders: () => {
    return sendRequest('get', [`${URL}/thought_leaders`]).then((response) =>
      response && response.data ? response.data : response
    );
  },

  fetchTopConnections: () => {
    return sendRequest('get', [`${URL}/connections/performance`]).then((response) => response);
  },

  fetchPeopleYouMayKnow: () => {
    return sendRequest('get', [`${URL}/people_you_may_know`]).then((response) => response);
  },

  sendConnectionRequest: (connectUserId, context) => {
    const body = {
      user_id: connectUserId,
      context,
    };
    return sendRequest('post', [`${URL}/connections`, body]).then((response) => response);
  },

  acceptConnectionRequest: (connectUserId, context) => {
    const url = `${URL}/connections/accept`;
    return sendRequest('post', [url, { context, user_id: connectUserId }]).then((response) => response);
  },

  ignoreConnectionRequest: (userId, context) => {
    const body = {
      user_id: userId,
      context,
    };
    return sendRequest('post', [`${URL}/connections/ignore`, body]).then((response) => response);
  },

  dismissConnectionSuggestion: (dismissUserId, dismissalType) => {
    return sendRequest('post', [
      `${URL}/users/${dismissUserId}/suggested_connection/dismiss`,
      { dismissal_type: dismissalType },
    ]).then((response) => response);
  },

  viewProfile: (userId, context) => {
    const options = { ignoreAuth: true };
    return sendRequest('post', [`${URL}/users/${userId}/view`, { context }], options).then((response) => response);
  },

  viewViewpointOrgProfile: (userId, context) => {
    const options = { ignoreAuth: true };
    return sendRequest('post', [`${URL}/viewpoints/${userId}/view`, { context }], options).then((response) => response);
  },

  viewSuggestedConnection: (userId, context) => {
    return sendRequest('post', [`${URL}/users/${userId}/suggested_connection/view`, { context }]).then(
      (response) => response
    );
  },

  queryUsers: (queryString) => {
    return sendRequest('get', [`${URL}/users/search?query=${queryString}`]).then((response) => response);
  },

  sendUserInvite: (email, message, context) => {
    const requestBody = {
      invite_email: email,
      message,
      context,
    };
    return sendRequest('post', [`${BASEURL}/users/invite_friend`, requestBody]).then((response) => response);
  },

  removeConnection: (userId) => {
    const body = { user_id: userId };
    return sendRequest('delete', [`${URL}/v1/connections/remove`, body]);
  },

  getPendingConnectionRequestUsers: () => {
    return sendRequest('get', [`${URL}/connections?include_pending=true`]).then((response) => response);
  },

  fetchAnnualMembershipEligibility: () => {
    return sendRequest('get', [`${URL}/v1/current_user/annual_membership_eligible`]);
  },

  startAnnualMembership: () => {
    return sendRequest('post', [`${URL}/v1/current_user/start_annual_membership`]);
  },

  emailMeAppDownloadLink: () => {
    return sendRequest('post', [`${URL}/v1/current_user/email_me_the_app`]);
  },
};
