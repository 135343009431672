import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../../../actions/index';

import LinkTo from '../../../Links/LinkTo';

import { snakeToHyphens } from '../../../../helpers/generalHelpers';
import { findUserIdeaForSecurity } from '../../../../helpers/ideaHelpers';
import { formatDataValue } from '../../../../helpers/securitiesHelpers';

import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';

export class PositionAllocationPercentageCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? (
          this.props.loadingCellComponent
        ) : (
          <LinkTo
            to={this._returnLinkForHandlingClick()}
            className={''}
            additionalClickAction={this.props.logSecurityPanelOpenClick}
            child={this._renderCellContent()}
            noTextDecoration
          />
        )}
      </td>
    );
  }

  _isLoading = () => {
    const { securityId } = this.props;
    const security = this.props.securities.lookup[securityId] || {};
    const portfolioData = this.props.portfolio.positions;
    const positionData = portfolioData && portfolioData[securityId];

    return !positionData || !('symbol' in security);
  };

  _returnLinkForHandlingClick = () => {
    const { securityId } = this.props;

    const additionalProps = {
      activeTab: 'ideas',
    };
    const openSecurityPanelConfig = {
      securityId,
      additionalProps,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    const { securityId } = this.props;
    const portfolioData = this.props.portfolio.positions;
    const positionData = portfolioData && portfolioData[securityId];
    const allocationPercent = positionData ? positionData.allocation_percent : 0;

    return (
      <span className={`security-table-position-allocation-container`}>
        <span className={`security-table-allocation-container`}>
          <span className={`security-table-allocation-total-value  `}>
            {formatDataValue(allocationPercent, 'percentage')}
          </span>
        </span>
      </span>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    portfolio: state.portfolio,
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(PositionAllocationPercentageCell);

export default composedComponent;
