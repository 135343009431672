import React from 'react';
import { linkAccountWithPlaid, logMetricsTrackingEvent, patchInstantLinkBankAccount } from '@src/actions';
import { isUndefinedOrNull } from '@src/helpers/generalHelpers';
import { lerpBankAccountScaleToBurstValue, sendFacebookLTVEvent } from '@src/constants/facebookTracking';
import { TrackingEvents } from '@src/utils/tracking/events';
import { usePlaidLink } from 'react-plaid-link';
import { Page } from '../../../components/layout';
import { PublicPage } from '../../../components/page/PublicPage';
import { Container } from '../../../components/ui';
import { Body1, Body4, Body5, H5 } from '../../../lib/nvstr-common-ui.es';
import styled from 'styled-components';
import { EMAIL_DOMAIN } from '@src/appConfig';
import { LoadingPage } from '../../../components/page';
import { CheckCircleFilled } from '../../../icons';
import { FlatButton, SkeletonButton } from '../../../components/buttons';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

export const REINIT_PLAID_STATE_KEY = 'plaid_link_reinit_metadata';
const getReInitMetadata = () => {
  const d = localStorage.getItem(REINIT_PLAID_STATE_KEY);
  if (d !== null) {
    return JSON.parse(d);
  }
  return null;
};
const HeaderWrapper = styled.div`
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
`;
const BodyWrapper = styled.div`
  padding: 24px;

  input {
    background: transparent !important;
    width: 100%;
    border: none !important;
  }
`;
const Content = styled.div`
  h5 {
    margin: 0;
  }
`;
const ActionsWrapper = styled.div`
  padding-top: 24px;
  text-align: center;
`;
const IconWrapper = styled.div`
  padding-top: 36px;
  text-align: center;
  svg {
    height: 36px;
    width: 36px;
  }
  path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

export const PlaidReinitialization = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [reInitState] = React.useState(getReInitMetadata());
  // reInitState properties
  // // wasReauth
  // // linkToken
  // // bankAccountId
  // // pathname
  // // search

  const [isLinkingAccount, setIsLinkingAccount] = React.useState(false);
  const [wasLinkSuccessful, setWasLinkSuccessful] = React.useState(false);
  const [error, showError] = React.useState(null);

  const handleComplete = () => {
    const prevPathname = reInitState?.pathname;
    const wasOnboarding = prevPathname === '/app/get-started';

    if (wasOnboarding) {
      navigate('/get-started');
    } else {
      navigate('/funding/deposit');
    }
  };

  const handleCancel = () => {
    const prevPathname = reInitState?.pathname;
    const wasOnboarding = prevPathname === '/app/get-started';

    if (wasOnboarding) {
      navigate('/get-started');
    } else {
      navigate('/');
    }
  };

  const onSuccess = React.useCallback(async (public_token, metadata) => {
    setIsLinkingAccount(true);

    const properties = {
      Type: 'On Success',
      'Link Session Id': metadata?.link_session_id,
      'Request Id': metadata?.request_id,
    };
    logMetricsTrackingEvent('Plaid Event', properties)();

    let response;
    const params = {
      public_token: public_token,
      account_id: metadata?.account_id,
    };

    if (reInitState?.wasReauth) {
      response = await patchInstantLinkBankAccount({ tornadoBankAccountId: reInitState?.bankAccountId, ...params })(
        dispatch
      );
      const { bankAccountId } = response;
      setWasLinkSuccessful(true);
      return;
    }

    response = await linkAccountWithPlaid(params)(dispatch);
    const { ok, error, account } = response;
    if (ok) {
      const available_balance_scale = account.available_balance_scale;
      if (!isUndefinedOrNull(available_balance_scale)) {
        const value = lerpBankAccountScaleToBurstValue(available_balance_scale);
        sendFacebookLTVEvent(value);
      }
      TrackingEvents.liveTradingSignUp.COMPLETED_BANK_ACCOUNT_LINK.send();
      setWasLinkSuccessful(true);
    } else {
      let errorMessage = 'Something went wrong. Unable to link bank account at this time. Please try again later.';
      if (error === 'The selected account is already linked. Please try a different account.') {
        errorMessage = error;
      }
      showError(errorMessage);
      TrackingEvents.liveTradingSignUp.LINK_PLAID_ACCOUNT_REQUEST_FAILURE.send({
        'Error Message': response.error || 'unknown',
      });
      setIsLinkingAccount(false);
    }
  }, []);

  const onExit = (err, metadata) => {
    setIsLinkingAccount(false);

    // The user exited the Link flow.
    // metadata contains information about the institution
    // that the user selected and the most recent API request IDs.
    // Storing this information can be helpful for support.
    if (err != null) {
      logMetricsTrackingEvent('Plaid API Error', { err, metadata });
    }

    const properties = {
      Type: 'On Exit',
      'Link Session Id': metadata.link_session_id,
      'Request Id': metadata.request_id,
    };
    logMetricsTrackingEvent('Plaid Event', properties);

    handleCancel();
  };

  const config = {
    token: reInitState?.linkToken,
    receivedRedirectUri: window.location.href, //the redirect URI with an OAuth state ID parameter
    onSuccess,
    onExit,
  };
  const { open, ready, error: plaidError } = usePlaidLink(config);

  // automatically reinitialize Link
  React.useEffect(
    () => {
      if (reInitState?.linkToken && ready) {
        open();
      }
    },
    [ready, open]
  );

  if (error || plaidError) {
    return (
      <PublicPage>
        <Page noPadding>
          <HeaderWrapper>
            <Body1 thin>
              Something went wrong. We could not link your bank account. If problem persists, please contact support@
              {EMAIL_DOMAIN}.com
            </Body1>
          </HeaderWrapper>
        </Page>
      </PublicPage>
    );
  }

  if (wasLinkSuccessful) {
    return (
      <PublicPage>
        <Page width={'600px'}>
          <Content>
            <IconWrapper>
              <CheckCircleFilled />
              <H5>Bank Link Completed</H5>
            </IconWrapper>
            <Container bottom={24}>
              <ActionsWrapper>
                <FlatButton fullWidth onClick={handleComplete}>
                  Continue
                </FlatButton>
              </ActionsWrapper>
            </Container>
          </Content>
        </Page>
      </PublicPage>
    );
  }

  if (isLinkingAccount) {
    return (
      <PublicPage>
        <LoadingPage message={'Linking Bank Account...'} />
      </PublicPage>
    );
  }

  if (!reInitState || !reInitState?.linkToken) {
    return (
      <PublicPage>
        <Page noPadding>
          <HeaderWrapper>
            <Body1 thin>We could not link your bank account.</Body1>
          </HeaderWrapper>
          <BodyWrapper>
            <Container>
              <H5>Two ways to link your account:</H5>

              <Container top={24}>
                <Body4>Download our mobile app and follow the in-app instructions to link your bank account.</Body4>
              </Container>
              <Container top={16}>
                <Body5>OR</Body5>
              </Container>
              <Container top={16}>
                <Body4>
                  This page might have been opened in a different browser than the bank link attempt was first made,
                  paste this URL below into the original browser to complete the bank link.
                </Body4>
                <Container top={12}>
                  <input value={window.location.href} />
                </Container>
                <Container top={24}>
                  <ActionsWrapper>
                    <SkeletonButton fullWidth onClick={handleCancel}>
                      Go Back
                    </SkeletonButton>
                  </ActionsWrapper>
                </Container>
              </Container>
            </Container>
          </BodyWrapper>
        </Page>
      </PublicPage>
    );
  }
  return <PublicPage />;
};
