import React from 'react';
import { setDateBack } from '../../helpers/timeHelpers';
import { logMetricsTrackingEvent } from '../../actions';
import { TrackIteratively } from '../../utils/itly';
import { enums } from '@src/main/lib/nvstr-utils.es';

import { ElementPositionContext } from '../../context';
import styled from 'styled-components';

const ChartTabWrapper = styled.div`
  &:hover .time-period-label {
    color: ${({ theme }) => theme.themeColors.primaryCtaButton};
  }

  &.active .time-period-label {
    color: ${({ theme }) => theme.themeColors.primaryCtaButton};
  }
  &.active .time-period-underline {
    background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
  }
`;

export const EQUITY_CHART_TABS = [
  {
    displayString: '1M',
    name: 'one_month',
    eventName: 'One Month',
    cutoffDate: setDateBack(1, 'month'),
  },
  {
    displayString: '3M',
    name: 'quarter_year',
    eventName: 'Quarter Year',
    cutoffDate: setDateBack(3, 'month'),
  },
  {
    displayString: '6M',
    name: 'half_year',
    eventName: 'Half Year',
    cutoffDate: setDateBack(6, 'month'),
  },
  {
    displayString: '1Y',
    name: 'one_year',
    eventName: 'One Year',
    cutoffDate: setDateBack(1, 'year'),
  },
  {
    displayString: 'Max',
    name: 'max',
    eventName: 'Max',
    cutoffDate: null,
  },
];

const ChartTab = ({ isActive, tab, handleClick }) => {
  const timePeriodName = tab.name;
  const displayLabel = tab.displayString;

  const onClick = () => handleClick(tab);

  return (
    <ChartTabWrapper
      className={`time-period-button ${
        isActive ? 'active primary-CTA-link-text-color' : 'secondary-to-primary-CTA-link-text-color'
      }`}
      onClick={onClick}
      key={`${timePeriodName}-chart-button`}
    >
      <div className={'time-period-label'}>{displayLabel}</div>
      <div
        className={`time-period-underline ${
          isActive ? 'active primary-CTA-link-text-bg-color' : 'secondary-to-primary-CTA-link-text-bg-color'
        }`}
      ></div>
    </ChartTabWrapper>
  );
};

class ChartTabs extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      activeTab: this._getDefaultTab(),
    };
  }

  render() {
    const { children } = this.props;
    const activeTab = this._getActiveTab();
    const TabsRenderer = (
      <div className={'equity-chart-tabs-container'}>
        {EQUITY_CHART_TABS.map((tabData, i) => (
          <ChartTab key={i} tab={tabData} isActive={this._isTabActive(tabData)} handleClick={this.handleTabClick} />
        ))}
      </div>
    );
    return children(activeTab, TabsRenderer);
  }

  handleTabClick = (tab) => {
    this._setActiveTab(tab);
    this._logChartTabClick(tab);
    this._logItlyTabClick(tab);
  };

  _isTabActive = (tab) => this._getActiveTab().name === tab.name;

  _getActiveTab = () => this.state.activeTab;

  _setActiveTab = (tab) => {
    this.setState(() => ({
      activeTab: tab,
    }));
  };

  _getDefaultTab = () => EQUITY_CHART_TABS.filter((tab) => tab.name === 'one_year')[0];

  _logChartTabClick = (tab) => {
    const { eventName } = this.props;
    const event = eventName || 'Chart Tab Clicked';
    const properties = {
      Tab: tab.eventName,
    };
    logMetricsTrackingEvent(event, properties)();
  };

  _logItlyTabClick = (tab) => {
    const { elementPosition } = this.context;
    const properties = {
      context: location.pathname,
      position: elementPosition || enums.node_location.body,
      platform: enums.platform.web,
      description: tab.name,
      url: window.location.pathname,
      url_query: window.location.search,
    };
    TrackIteratively.generic.TAB_VIEWED.send(properties);
  };
}

ChartTabs.contextType = ElementPositionContext;

export default ChartTabs;
