import React from 'react';

const LoadingUser = (props) => {
  const { position } = props;

  const loadingProfileAvatarLeftCover = {
    height: `${40}px`,
    left: `${0}px`,
    right: `${70}px`,
  };
  const loadingProfileAvatarRightCover = {
    height: `${40}px`,
    left: `${70}px`,
    right: `${0}px`,
  };
  const loadingProfileUserNameTopCover = {
    height: `${7}px`,
    top: `${38}px`,
    left: `${0}px`,
    right: `${0}px`,
  };
  const loadingProfileUserNameBottomCover = {
    height: `${8}px`,
    top: `${54}px`,
    left: `${0}px`,
    right: `${0}px`,
  };

  const loadingProfileLeftCover = {
    height: `${60}px`,
    top: `${0}px`,
    left: `${0}px`,
    right: `${50}px`,
  };
  const loadingProfileRightCover = {
    height: `${60}px`,
    top: `${0}px`,
    left: `${50}px`,
    right: `${0}px`,
  };

  return (
    <div
      className={`loading-user-container component-bg`}
      style={{
        position: 'absolute',
        ...position,
      }}
    >
      <div className={`animated-background`}>
        <div className={`loading-animation-covers`} style={loadingProfileAvatarLeftCover}></div>
        <div className={`loading-animation-covers`} style={loadingProfileAvatarRightCover}></div>
        <div className={`loading-animation-covers`} style={loadingProfileUserNameTopCover}></div>
        <div className={`loading-animation-covers`} style={loadingProfileUserNameBottomCover}></div>
        <div className={`loading-animation-covers`} style={loadingProfileLeftCover}></div>
        <div className={`loading-animation-covers`} style={loadingProfileRightCover}></div>
      </div>
    </div>
  );
};

export default LoadingUser;
