import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { fetchTopConnections, logMetricsTrackingEvent } from '../../actions';

import HorizontalList from '../UI/HorizontalList';
import TopPerformer from './TopPerformer';
import LoadingUser from '../UI/LoadingUser';
import InfoIcon from '../UI/InfoIcon';

import { isConnectionsDataLoadingForUser } from '../../helpers/userConnectionsHelpers';
import { sendFacebookTrackingEvent } from '../../constants/facebookTracking';
import { returnCurrentUserThemeTestGroups } from '../../helpers/currentUserHelpers';
import { returnTopPerformersUserIdFromCollectivesStore } from '../../helpers/collectiveHelpers';

export const ConnectionsWrap = styled.div`
  span {
    color: ${({ theme }) => theme.themeColors.component};
    &:hover {
      color: #222;
    }
  }

  .top-connections-container {
    margin: 5px auto 0 auto;
    padding: 16px 0;
    max-width: 825px;
  }
  .top-connections-container .heading {
    padding-bottom: 14px;
    font-size: 12px;
    opacity: var(--de-emphasized-text-opacity);
    margin: 0;
  }

  .top-connections-container .avatar-container.collective-user .profile-image {
    width: 14px !important;
    margin-left: 1px;
  }

  .top-connections-container .heading .react-info-icon {
    font-size: 14px;
  }
  .top-connections-container .heading .react-info-icon * {
    font-size: 14px;
  }
  .top-connections-container .user-container {
    text-align: center;
  }
  .top-connections-container .top-performer-container {
    transition: all 0.15s ease-out;
  }
  .top-connections-container .top-performer-container .stand-alone-arrow {
    top: -24px !important;
    right: auto !important;
    bottom: auto !important;
    left: -83px !important;
    -webkit-transform: rotate(120deg) !important;
    -moz-transform: rotate(120deg) !important;
    -o-transform: rotate(120deg) !important;
    -ms-transform: rotate(120deg) !important;
    transform: rotate(120deg) !important;
  }
  .top-connections-container .top-performer-container .user-name-container {
    padding: 0;
    padding-top: 4px;
    font-size: 12px;
    font-weight: 300;
  }
  .top-connections-container .top-performer-container .top-connection-daily-idea-performance {
    margin-top: -2px;
    padding: 3px 0 1px 0;
    line-height: 1;
  }
  .top-connections-container
    .top-performer-container
    .top-connection-daily-idea-performance
    .number-with-change-indicator-value {
    font-weight: 300;
  }
  .top-connections-container .top-connections-see-more-arrow-container {
    width: 40px;
  }
  .top-connections-container .top-connections-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* fallback for ie/edge */
    justify-content: space-evenly;
    justify-content: space-between;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    max-width: 583px;
    padding: 0 55px;
    margin: 0 auto;
  }
  .top-connections-container .top-connections-list .horizontal-list-container {
    width: 100%;
    margin-bottom: 10px;
  }
  .top-connections-container .top-connections-list .horizontal-list-container .horizontal-list-arrow {
    padding-bottom: 17px;
  }
  .top-connections-container
    .top-connections-list
    .horizontal-list-container
    .horizontal-list-arrow.horizontal-list-arrow-right {
    right: -50px;
  }
  .top-connections-container
    .top-connections-list
    .horizontal-list-container
    .horizontal-list-arrow.horizontal-list-arrow-left {
    left: -50px;
  }
  .top-connections-container .top-connections-list .horizontal-list-container .transparent-gradient {
    z-index: 2;
  }
  .top-connections-container.small {
    padding-bottom: 8px;
  }
  @media (max-width: 825px) {
    .top-connections-container {
      width: auto;
    }
  }
  @media (max-width: 600px) {
    .top-connections-container .top-connections-see-more-arrow-container {
      width: 10px;
    }
    .top-connection-daily-idea-performance {
      padding-top: 3px;
    }
  }
  @media (max-width: 500px) {
    .dashboard-container .top-connections-container .react-info-icon {
      top: -1px !important;
      right: -23px !important;
    }
    .dashboard-container .top-connections-container .user-name-container .react-info-icon {
      top: 0px !important;
      right: -17px !important;
    }
  }
  .top-connections-list .transparent-gradient.to-left {
    left: 0;
  }

  .dashboard-container & .top-connections-container {
    margin-top: 0;
  }
  .dashboard-container & .top-connections-container .user-name-container {
    opacity: var(--de-emphasized-text-opacity);
  }
  .dashboard-container & .top-connections-container .user-name-container .react-info-icon {
    top: 0px !important;
    right: -17px !important;
  }
  .dashboard-container & .top-connections-container div.horizontal-list-container {
    margin-bottom: 0;
  }
`;

const Separator = styled.div`
  margin-bottom: 16px;

  border-bottom-width: 1.2px;
  border-bottom-style: solid;

  border-color: ${({ theme }) => theme.themeColors.appBackground};
`;

const PageWrapper = styled.div`
  background-color: ${({ theme }) => theme.themeColors.component};
`;

class TopConnections extends React.PureComponent {
  componentDidMount() {
    fetchTopConnections()(this.props.dispatch);
  }

  render() {
    if (this._currentUserHasNoConnections()) return null;

    return (
      <ConnectionsWrap>
        <PageWrapper
          className={`
        react-page-container
        top-connections-container
        ${this.props.size || ''}
      `}
        >
          <div className={'heading'}>
            <div className={'info-icon-text-wrapper'}>
              Top Performers
              <InfoIcon word="performance_numbers" style={{ top: '0.5px', right: '-20px' }} position={'right'} />
            </div>
          </div>
          <Separator />
          {this._renderScrollableTopConnectionsList()}
        </PageWrapper>
      </ConnectionsWrap>
    );
  }

  _renderScrollableTopConnectionsList = () => {
    const topConnectionsPerformerList = this._isLoading()
      ? []
      : this._returnListOfTopPerformers().filter((u) => u.user_id != this.props.collectiveUserId);

    return (
      <div className={'top-connections-list'}>
        <HorizontalList
          id="top_connections"
          height={89}
          itemWidth={75}
          paddingBetweenItems={this._returnPaddingBetweenItems()}
          isLoading={this._isLoading()}
          items={topConnectionsPerformerList}
          loadingCardComponent={LoadingUser}
          componentName={TopPerformer}
          listItemProps={this._returnListItemAdditionalProps()}
          contextForTrackingOnPageChange={'Top Connections'}
          customTrackingEvent={"Clicked See More Connections' Performance"}
          logArrowDirectionClickInEvent
        />
      </div>
    );
  };

  _handleSeeMoreConnectionPerformanceClick = () => {
    const event = "Clicked See More Connections' Performance";
    logMetricsTrackingEvent(event)();

    window.location = '/users/connections?sort=today';
  };

  _isLoading = () => {
    const isLoadingPerformanceData = this.props.users.connectionsPerformance.loading;
    const isLoadingCurrentUser = this.props.currentUser && !('user_id' in this.props.currentUser);
    const isLoadingConnections = this._isLoadingConnections();
    const isLoadingCollective = this._returnCollectivesStore().loading;
    return isLoadingPerformanceData || isLoadingCurrentUser || isLoadingConnections || isLoadingCollective;
  };

  _isLoadingConnections = () =>
    isConnectionsDataLoadingForUser(this._returnCurrentUserId(), this._returnUserConnectionsStore());

  _returnCurrentUser = () => this.props.currentUser;

  _returnCurrentUserId = () => this._returnCurrentUser().user_id;

  _returnUserConnectionsStore = () => this.props.userConnections;

  _currentUserHasNoConnections = () => !this._isLoading() && this._returnListOfTopPerformers().length === 0;

  _returnListOfTopPerformers = () => this.props.users.connectionsPerformance;

  _returnPaddingBetweenItems = () => (this.props.size === 'small' ? 10 : 25);

  _returnListItemAdditionalProps = () => {
    const props = {
      handleClick: (userId) => {
        const leaderId =
          this.props.currentUser.user_id === userId
            ? this.props.currentUser.analytics_id || null
            : this.props.users.userList[userId]
            ? this.props.users.userList[userId].analytics_id
            : null;
        sendFacebookTrackingEvent('Clicked Performance Leader', {
          themeTestGroups: returnCurrentUserThemeTestGroups(this.props.currentUser),
        });
        const event = 'Performance Leader Clicked';
        const properties = {
          Context: 'Webpage',
          'Leader Id': leaderId,
          'Is Collective User': this._isUserCollective(userId),
          'User ID': userId,
        };
        logMetricsTrackingEvent(event, properties)();
      },
    };
    return props;
  };

  _isUserCollective = (userId) => userId in this._returnCollectivesUserLookup();

  _returnCollectivesStore = () => this.props.collectives;

  _returnCollectivesUserLookup = () => this._returnCollectivesStore().lookup;
}

const mapStateToProps = (state) => {
  return {
    collectiveUserId: returnTopPerformersUserIdFromCollectivesStore(state.collectives),
    collectives: state.collectives,
    currentUser: state.currentUser,
    userConnections: state.userConnections,
    users: state.users,
  };
};

export default connect(mapStateToProps)(TopConnections);
