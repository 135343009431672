import React from 'react';
import { getLiveAccountDocumentationRequirements, submitLiveAccountDocument, showModal } from '../../../actions';
import SelectDocumentButton from './components/SelectDocumentButton';
import { toTitleCase } from '../../../helpers/generalHelpers';
import { parseQueryString } from '../../../helpers/routerHelpers';
import { buildFileData } from '../../../helpers/fileHelpers';
import { useLocation } from 'react-router-dom';
import LoadingIcon from '../../../components/misc/LoadingIcon';
import { DOC_REQUIREMENT_LOOKUP } from '../../../main/constants';
import { FlatButton } from '../../../main/components/buttons';
import { FloatingLabelDropdown, createDropdownItem, H5, Body5 } from '../../../main/lib/nvstr-common-ui.es';
import styled from 'styled-components';
import DeleteButton from '../../buttons/DeleteButton';
import Icon from '../../../components/misc/Icon';
import UnstyledLoadingSpinner from '../../../main/components/Images/UnstyledLoadingSpinner';
import { CheckCircleFilled } from '../../../main/icons/svg/CheckCircleFilled';

const ErrorText = styled.div`
  padding-bottom: 8px;

  * {
    color: ${({ theme }) => theme.themeColors.error};
  }
`;
const SubmitActionWrapper = styled.div`
  padding-top: 36px;
  text-align: center;
`;
const SelectDocumentWrapper = styled.div`
  padding-top: 24px;
  text-align: center;
`;
const Line = styled.div`
  padding-top: 24px;
  margin: 0 16px 24px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
`;
const ImageUploadWrapper = styled.div`
  padding-top: 24px;
`;
const FrontBackUploadWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
const RequiredDocumentUploadWrapper = styled.div`
  position: relative;
  padding-top: 24px;

  select {
    border-top: none;
    border-right: none;
    border-left: none;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
  input {
    border: none;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
`;
const FileIconWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const DeleteButtonWrapper = styled.div`
  position: absolute;
  top: -12px;
  right: -24px;
`;
const FileIconColumn = styled.div`
  text-align: center;
  max-width: 100px;
  margin: 0 auto;
`;
const FileName = styled.div`
  * {
    font-size: 10px !important;
    line-height: 12px !important;
  }
`;
const IconPosition = styled.div`
  display: inline-block;
  position: relative;
  padding-bottom: 8px;
`;

const FileIcon = ({ blobUrl, fileName, onDelete }) => {
  const handleClick = () => {
    window.open(blobUrl, '_blank');
  };

  return (
    <FileIconColumn onClick={handleClick}>
      <IconPosition>
        <Icon icon="fa-file" size="xlarge" />
        <DeleteButtonWrapper>
          <DeleteButton onPress={onDelete} />
        </DeleteButtonWrapper>
      </IconPosition>
      <FileName style={{ margin: '0 auto', maxWidth: '100px' }}>
        <Body5 isSmall>{fileName}</Body5>
      </FileName>
    </FileIconColumn>
  );
};

const DocumentItem = ({ blobUrl, fileName, onDelete }) => {
  return (
    <FileIconWrapper>
      <FileIcon blobUrl={blobUrl} fileName={fileName} onDelete={onDelete} />
    </FileIconWrapper>
  );
};

const isFrontAndBackRequired = (documentType) => {
  const DOCUMENT_TYPES_REQUIRING_FRONT_AND_BACK = ["Driver's License", 'State ID Card'];
  return DOCUMENT_TYPES_REQUIRING_FRONT_AND_BACK.includes(documentType);
};

const Uploader = ({ selectedDocument, documentType, isFront, isBack, onImageSelect, onDeleteFile, file }) => {
  const handleDelete = () => {
    onDeleteFile({ file, documentType, isFront, isBack });
  };

  if (file) {
    return <DocumentItem blobUrl={file.documentBlob} fileName={file.document.name} onDelete={handleDelete} />;
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ textAlign: 'center' }}>
        <Body5>{`Document ${isFront ? 'Front' : 'Back'}`}</Body5>
      </div>
      <SelectDocumentWrapper>
        <SelectDocumentButton
          selectedDocument={selectedDocument}
          documentType={documentType}
          isFront={isFront}
          isBack={isBack}
          onChange={onImageSelect}
          disableFileUploadMechanism={false}
        />
      </SelectDocumentWrapper>
    </div>
  );
};

const RequiredDocumentUpload = ({ documentType, values, showLine, onDocumentSelect, onDocumentDelete }) => {
  const [selectedDocument, setSelectedDocument] = React.useState('');

  const files = values[documentType];
  const shouldShowFrontAndBackRequiredMessage = isFrontAndBackRequired(selectedDocument);

  const acceptableDocuments = DOC_REQUIREMENT_LOOKUP[documentType];
  const items = [
    createDropdownItem({ text: 'Please select...', value: '' }),
    createDropdownItem({ text: '--', value: '--' }),
    ...acceptableDocuments.map((d) => createDropdownItem({ text: d, value: d })),
  ];

  const handleDeleteFile = (d) => {
    onDocumentDelete(d);
  };

  const handleSelectDocument = (n) => (v) => {
    setSelectedDocument(v);
  };

  return (
    <RequiredDocumentUploadWrapper>
      {showLine && <Line className={'sc-row-line-separator'} />}
      <FloatingLabelDropdown
        name={documentType}
        value={selectedDocument}
        label={documentType === 'headshot' ? 'Headshot Image' : `${toTitleCase(documentType)} Document`}
        items={items}
        onChange={handleSelectDocument}
        error={null}
      />
      {documentType === 'identity' && (
        <div style={{ paddingTop: '4px' }}>
          <Body5 isSmall isLowContrast>
            Some documents require front and back.
          </Body5>
        </div>
      )}

      {selectedDocument !== '' && selectedDocument !== '--' && (
        <ImageUploadWrapper>
          {shouldShowFrontAndBackRequiredMessage ? (
            <FrontBackUploadWrapper>
              <Uploader
                selectedDocument={selectedDocument}
                documentType={documentType}
                isFront
                onImageSelect={onDocumentSelect}
                onDeleteFile={handleDeleteFile}
                file={files?.front}
              />
              <Uploader
                selectedDocument={selectedDocument}
                documentType={documentType}
                isBack
                onImageSelect={onDocumentSelect}
                onDeleteFile={handleDeleteFile}
                file={files?.back}
              />
            </FrontBackUploadWrapper>
          ) : (
            <Uploader
              selectedDocument={selectedDocument}
              documentType={documentType}
              isFront
              onImageSelect={onDocumentSelect}
              onDeleteFile={handleDeleteFile}
              file={files?.front}
            />
          )}
        </ImageUploadWrapper>
      )}
    </RequiredDocumentUploadWrapper>
  );
};

const initialState = {};

const ACTION_TYPES = {
  add: 'add',
  selectDocument: 'selectDocument',
  delete: 'delete',
};
function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.add: {
      const { file, isFront, isBack, documentType, selectedDocument } = action.payload;
      const docState = state[documentType]
        ? { [documentType]: state[documentType] }
        : { [documentType]: { front: null, back: null, selectedDocument } };
      docState[documentType][isFront ? 'front' : 'back'] = file;
      return {
        ...state,
        ...docState,
      };
    }
    case ACTION_TYPES.delete: {
      const { file, isFront, isBack, documentType } = action.payload;
      const docState = state[documentType]
        ? { [documentType]: state[documentType] }
        : { [documentType]: { front: null, back: null } };
      docState[documentType][isFront ? 'front' : 'back'] = null;
      return {
        ...state,
        ...docState,
      };
    }
    default:
      throw new Error();
  }
}

const DocumentUpload = ({ requiredDocs: _props_requiredDocs }) => {
  const { search } = useLocation();

  const [isReady, setIsReady] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);
  const [isUploadComplete, setIsUploadComplete] = React.useState(false);

  const [requiredDocs, setRequiredDocs] = React.useState(_props_requiredDocs || []);
  const [fileUploadState, dispatch] = React.useReducer(reducer, initialState, () => initialState);

  React.useEffect(() => {
    const parseDocsNeededFromUrlQuery = () => {
      const uri = decodeURIComponent(search);
      const query = parseQueryString(uri);
      const queryKeys = Object.keys(query);
      const docTypeKeys = Object.keys(DOC_REQUIREMENT_LOOKUP);
      const requiredDocs = [];

      queryKeys.forEach((key) => {
        if (docTypeKeys.includes(key)) {
          requiredDocs.push(key);
        }
      });

      if (requiredDocs.length > 0) {
        setRequiredDocs(requiredDocs);
      }
    };

    const setup = async () => {
      const { ok, data } = await getLiveAccountDocumentationRequirements();
      if (ok) {
        // setIsReady(true);
      } else {
        setTimeout(setup, 1000);
      }
    };

    if (!_props_requiredDocs) parseDocsNeededFromUrlQuery();

    // setup();
  }, []);

  const handleSelectedDocumentSubmit = async (documentType) => {
    const { front, back, selectedDocument } = fileUploadState[documentType];
    const selectedDocuments = [front];
    if (back) selectedDocuments.push(back);

    const files = selectedDocuments.map((d) => d.document);
    const formData = new FormData();
    formData.append('documentation_type', selectedDocument.toLowerCase());
    files.forEach((file) => {
      formData.append('images[]', file);
    });

    const { ok } = await submitLiveAccountDocument(formData);
    return ok;
  };

  const validateForm = () => {
    const isValid = requiredDocs.every((documentType) => {
      const { front, back, selectedDocument } = fileUploadState[documentType] || {};
      const needsFrontAndBack = isFrontAndBackRequired(selectedDocument);

      if (needsFrontAndBack) {
        return !!front && !!back;
      } else {
        return !!front;
      }
    });
    return isValid;
  };

  const handleSubmit = async () => {
    const isValid = validateForm();
    if (!isValid) {
      setFormError('Missing required documents');
      return;
    }

    setIsSubmitting(true);
    const responses = await Promise.all(requiredDocs.map((docType) => handleSelectedDocumentSubmit(docType)));
    if (responses.every((ok) => ok)) {
      setIsUploadComplete(true);
    } else {
      setFormError('Something went wrong. Please try again later.');
    }
    setIsSubmitting(false);
  };

  const handleDocumentDelete = ({ file, isFront, isBack, documentType }) => {
    dispatch({
      type: ACTION_TYPES.delete,
      payload: { file, isFront, isBack, documentType },
    });
  };

  const handleSelectDocument = (e, metadata) => {
    const saveFileToState = (file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const { url, documentBlob } = buildFileData(file, reader);
        const uploadedFileData = {
          document: file,
          documentUrl: url,
          documentBlob,
        };
        const { isFront, isBack, documentType, selectedDocument } = metadata;
        dispatch({
          type: ACTION_TYPES.add,
          payload: { file: uploadedFileData, isFront, isBack, documentType, selectedDocument },
        });
      };
      reader.readAsDataURL(file);
    };

    e.preventDefault();

    const files = Array.from(e.target.files);
    files.forEach((file) => {
      saveFileToState(file);
    });
  };

  if (!isReady) {
    return (
      <div>
        <LoadingIcon />
      </div>
    );
  }

  if (isSubmitting) {
    return (
      <div>
        <H5>Document Upload Helper</H5>
        <div style={{ padding: '36px 0', textAlign: 'center' }}>
          <UnstyledLoadingSpinner />
          <div style={{ padding: '16px 0 0 0', textAlign: 'center' }}>
            <Body5>Submitting...</Body5>
          </div>
        </div>
      </div>
    );
  }

  if (isUploadComplete) {
    return (
      <div>
        <H5>Document Upload Helper</H5>
        <div style={{ padding: '36px 0', textAlign: 'center' }}>
          <CheckCircleFilled textColor />
          <div style={{ padding: '16px 0 0 0', textAlign: 'center' }}>
            <Body5>Completed</Body5>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>
        <H5>Document Upload Helper</H5>
        <div style={{ paddingTop: '4px' }}>
          <Body5 isSmall thin>
            The required documents are listed below.
          </Body5>
        </div>
        <div style={{ paddingTop: '4px' }}>
          <Body5 isSmall thin>
            For each, use the dropdown to select the acceptable document you are uploading, and click the button to
            select the file from your device. Once completed, click the "Complete Upload" button to securely send your
            documents for review.
          </Body5>
        </div>
      </div>
      {requiredDocs.map((docType, i) => (
        <div key={docType}>
          <RequiredDocumentUpload
            documentType={docType}
            values={fileUploadState}
            showLine={i !== 0}
            onDocumentSelect={handleSelectDocument}
            onDocumentDelete={handleDocumentDelete}
          />
        </div>
      ))}

      <SubmitActionWrapper>
        {formError && (
          <ErrorText>
            <Body5>{formError}</Body5>
          </ErrorText>
        )}
        <FlatButton onClick={handleSubmit}>Complete Upload</FlatButton>
      </SubmitActionWrapper>
    </div>
  );
};

export default DocumentUpload;
