import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Container, Spacing } from '../ui';
import { Body1 } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../buttons';
import { AppIcon } from '../ui/AppIcon';
import { isMobileDevice, pickOne } from '../../../helpers/usefulFuncs';
import { emailMeAppDownloadLink } from '../../../actions';
import { PRODUCT_DISPLAY_FULL_NAME } from '../../../appConfig';

const Wrapper = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid ${({ theme }) => theme.themeColors.text};
`;
const Content = styled.div`
  padding: 16px;
  position: relative;
  z-index: 2;
`;
const ActionsWrapper = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const TextWrapper = styled.div`
  width: calc(100% - 50px);
`;

const testGroups = [
  {
    id: 1,
    message: `Unlock more features by downloading the ${PRODUCT_DISPLAY_FULL_NAME} app.`,
  },
  {
    id: 2,
    message: 'Download the mobile app to get access to more features and a seamless mobile experience.',
  },
  {
    id: 3,
    message: `Download the mobile app for even faster access to the ${PRODUCT_DISPLAY_FULL_NAME} features you love.`,
  },
];
const _useTestGroupMessage = () => {
  const { id, message } = pickOne(testGroups);
  const [testGroup] = React.useState({
    testGroupId: id,
    message,
  });
  return testGroup;
};

export const DownloadAppBanner = (props) => {
  const theme = React.useContext(ThemeContext);
  const textColor = theme.themeColors.text;
  const ctaBtnTextColor = theme.themeColors.appBackground;

  const { testGroupId, message } = _useTestGroupMessage();

  const { ContainerComponent, onCTAClick, onDismiss } = props;

  React.useEffect(() => {
    const eventProperties = {
      'Test Group Id': testGroupId,
      Message: message,
    };
    props.onView(eventProperties);
  }, []);

  const deeplinkToApp = () => {
    const quickLink = 'https://share.tornado.com/YLzlSsPIYub';
    window?.open(quickLink, '_blank').focus();
  };
  const emailAppDownloadLink = () => {
    emailMeAppDownloadLink();
  };
  const extendedHandleCTAClick = () => {
    const eventProperties = {
      'Test Group Id': testGroupId,
      Message: message,
    };
    isMobileDevice() ? deeplinkToApp() : emailAppDownloadLink();
    onCTAClick(eventProperties);
  };

  const isOnMobileDevice = isMobileDevice();

  const appStoreBannerRatio = 204 / 60;
  const appStoreBannerWidth = 140;
  const toPx = (v) => v + 'px';
  return (
    <ContainerComponent>
      <Wrapper>
        <Content>
          <Container row verticallyCenter>
            <AppIcon />
            <TextWrapper>
              <Spacing left={16}>
                <Body1>{message}</Body1>
              </Spacing>
            </TextWrapper>
          </Container>
          <ActionsWrapper>
            <Container>
              {isOnMobileDevice ? (
                <FlatButton onClick={extendedHandleCTAClick}>Get the mobile app</FlatButton>
              ) : (
                <FlatButton onClick={extendedHandleCTAClick}>Email me a Download Link</FlatButton>
                // <Container row verticallyCenter>
                //   <DownloadOnAppStore
                //     width={toPx(appStoreBannerWidth)}
                //     height={toPx(appStoreBannerWidth / appStoreBannerRatio)}
                //     onAfterClick={onCTAClick}
                //   />
                //   <Spacing left={16}>
                //     <DownloadOnGooglePlay
                //       width={toPx(appStoreBannerWidth)}
                //       height={toPx(appStoreBannerWidth / appStoreBannerRatio)}
                //       onAfterClick={onCTAClick}
                //     />
                //   </Spacing>
                // </Container>
              )}
            </Container>
            <Container>
              <FlatButton color={ctaBtnTextColor} textColor={textColor} onClick={onDismiss}>
                Dismiss
              </FlatButton>
            </Container>
          </ActionsWrapper>
        </Content>
      </Wrapper>
    </ContainerComponent>
  );
};
