import React from 'react';

import Button from '../../components/buttons/Button';
import { TooltipBodyOnly } from '../UI/TooltipBodyOnly';
import { AskForConfirmation } from '../UI/AskForConfirmation';

export class ButtonContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      _showGetConfirmationComponent: false,
    };
  }

  render() {
    return (
      <div className={`button-container-wrapper ${this.props.buttonWrapperClassName || ''}`}>
        <Button customClass={`${this.props.buttonClassName}`} text={this.props.text} handleClick={this.handleClick} />
        {this._shouldShowGetConfirmationComponent() && this._renderConfirmation()}
      </div>
    );
  }

  _renderConfirmation = () => {
    return (
      <TooltipBodyOnly>
        <AskForConfirmation
          message={'Are you sure you wish to cancel?'}
          handleConfirm={this.handleConfirmationClick}
          handleCancel={this.handleCancelClick}
          customMessage={this.props.customConfirmationMessage}
          customButtons={this.props.customConfirmationButtonText}
        />
      </TooltipBodyOnly>
    );
  };

  handleConfirmationClick = () => {
    this._hideGetConfirmationComponent();
    this.props.handleConfirmAction();
  };

  handleCancelClick = () => {
    this._hideGetConfirmationComponent();
    this.props.handleCancelAction();
  };

  handleConfirmAction = () => {
    this.props.handleConfirmAction();
  };

  handleCancelAction = () => {
    this.props.handleCancelAction();
  };

  handleClick = () => {
    if (this.props.needsConfirmation) {
      this._showGetConfirmationComponent();
    }
    this.props.handleClick();
  };

  _shouldShowGetConfirmationComponent = () => this.state._showGetConfirmationComponent;

  _showGetConfirmationComponent = () => {
    this.setState(() => ({
      _showGetConfirmationComponent: true,
    }));
  };

  _hideGetConfirmationComponent = () => {
    this.setState(() => ({
      _showGetConfirmationComponent: false,
    }));
  };
}
