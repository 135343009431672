import React from 'react';
import styled from 'styled-components';
import VerticalNavItem from '../../components/VerticalNavItem';
import HistoryIcon from '@src/main/components/Images/HistoryIcon';
import LightningIcon from '@src/main/components/Images/LightningIcon';
import DocumentsIcon from '@src/main/components/Images/DocumentsIcon';
import { isCurrentUserAllowLiveTrading } from '@src/helpers/currentUserHelpers';
import { useIsLiveTrading } from '@src/main/hooks/user';
import { useCurrentUser } from '@src/main/hooks/user';
import Tour from '@src/containers/Nav/verticalNavComponents/routeItems/Tour';

export const A = styled('a')`
  &:hover {
    text-decoration: none;
  }
`;

const SupportIconWrapper = styled.svg`
  margin-top: 4px;
  height: 22px;
  width: 22px;
`;

const SupportIcon = () => {
  return (
    <SupportIconWrapper xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2M20 16H5.2L4 17.2V4H20V16Z" />
    </SupportIconWrapper>
  );
};

const ColorIcon = () => {
  return (
    <SupportIconWrapper xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12,19.58V19.58C10.4,19.58 8.89,18.96 7.76,17.83C6.62,16.69 6,15.19 6,13.58C6,12 6.62,10.47 7.76,9.34L12,5.1M17.66,7.93L12,2.27V2.27L6.34,7.93C3.22,11.05 3.22,16.12 6.34,19.24C7.9,20.8 9.95,21.58 12,21.58C14.05,21.58 16.1,20.8 17.66,19.24C20.78,16.12 20.78,11.05 17.66,7.93Z" />
    </SupportIconWrapper>
  );
};

export const SupportVNavRoute = () => <VerticalNavItem name="Support" customIcon={<SupportIcon />} route="/support" />;

export const ThemeBuilderVNavRoute = () => (
  <VerticalNavItem name="Theme Builder" customIcon={<ColorIcon />} route="/theme-builder" />
);

export const LiveTradingSignupVNavRoute = () => {
  const currentUser = useCurrentUser();
  const isLiveTrading = useIsLiveTrading();
  const isAccountClosed = currentUser.is_account_closed;
  const canSignupForLiveAccount = isCurrentUserAllowLiveTrading(currentUser) && !isLiveTrading;

  if (canSignupForLiveAccount && !isAccountClosed) {
    return (
      <VerticalNavItem
        name="Live Trading"
        customIcon={<LightningIcon />}
        shouldMatchFirstSubDir={true}
        subDir={'live_accounts'}
        route="/live_accounts/new"
      />
    );
  }
  return null;
};

export const HistoryVNavRoute = () => (
  <VerticalNavItem name="History" customIcon={<HistoryIcon />} route="/portfolio/history" />
);

export const EDocumentsVNavRoute = () => (
  <VerticalNavItem name="E-Documents" customIcon={<DocumentsIcon />} route="/documents" />
);

export const TourVNavRoute = () => {
  const currentUser = useCurrentUser();
  const isAccountClosed = currentUser.is_account_closed;

  if (isAccountClosed) return null;
  return <Tour />;
};
