import React, { Component } from 'react';
import IdeaListItem from '../../components/ideas/IdeaListItem';

export class IdeaList extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.userUpdatingIdea && this.props.userUpdatingIdea) {
      this.scrollToTopOfList();
    }
  }

  determineIfActiveIdea = (ideaId) => {
    return (
      this.props.activeIdea &&
      this.props.activeIdea.idea_id === ideaId &&
      !(this.props.userCreatingIdea || this.props.userUpdatingIdea)
    );
  };

  scrollToTopOfList = (timeout) => {
    if (window.innerWidth > 750) {
      $('.idea-list-container').animate(
        {
          scrollTop: 0,
        },
        timeout || 600
      );
    } else {
      $('.idea-list-container').animate(
        {
          scrollLeft: 0,
        },
        timeout || 600
      );
    }
    // scroll to bottom of list below
    // if ( window.innerWidth > 750 ) {
    //   $('.idea-list-container').animate({
    //     scrollTop: $('.idea-list-container').offset().top + ( 200 * ( this.props.ideas.length - 6 ) )
    //   }, 600);
    // }
    // else {
    //   $('.idea-list-container').animate({
    //     scrollLeft: $('.idea-list-container').offset().top + ( 200 * ( this.props.ideas.length - 2 ) )
    //   }, 600);
    // }
  };

  sortIdeasForList = () => {
    const { currentUserIdeaId, thoughtLeaderIdeasIds, connectionsIdeasIds } = this.props;
    return currentUserIdeaId && !this.props.userUpdatingIdea
      ? [currentUserIdeaId, ...thoughtLeaderIdeasIds, ...connectionsIdeasIds]
      : [...thoughtLeaderIdeasIds, ...connectionsIdeasIds];
  };

  render() {
    const ideas = this.sortIdeasForList();

    if (this.determineIfActiveIdea(this.props.currentUserIdeaId)) {
      this.scrollToTopOfList(100);
    }

    return (
      <div className="idea-list-container border-accent">
        {this.renderCurrentUserCreatingIdeaTab()}
        {ideas.map((ideaId) => (
          <IdeaListItem
            key={`idea_id_${ideaId}`}
            ideaId={ideaId}
            activeIdea={this.determineIfActiveIdea(ideaId)}
            screenWidth={this.props.screenWidth}
            handleClick={this.props.handleClick}
          />
        ))}
      </div>
    );
  }

  renderCurrentUserCreatingIdeaTab = () => {
    if (this.props.userCreatingIdea || this.props.userUpdatingIdea) {
      return (
        <IdeaListItem
          idea={null}
          currentUserId={this.props.currentUserId}
          activeIdea={true}
          screenWidth={this.props.screenWidth}
          handleClick={this.props.handleClick}
          userCreatingIdea={true}
        />
      );
    } else {
      return null;
    }
  };
}

export default IdeaList;
