import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../components/buttons/Button';
import IconButton from '../../components/buttons/IconButton';
import { logMetricsTrackingEvent } from '../../actions';

import UserContainer from '../users/UserContainer';
import ProfileAvatar from '../../components/user/ProfileAvatar';
import AskAnyone from '../../containers/buttons/AskAnyone';
import IdeaDeleteOptions from './components/IdeaDeleteOptions';
import DisplayPercentageComponent from '../UI/ValueDisplayComponent/DisplayPercentage';

import { createInputComponent } from '../../helpers/formHelpers';
import { getIdeaPhraseFor } from '../../helpers/terminologyHelpers';
import {
  findUser,
  userFullName,
  displayThoughtLeaderCompany,
  displayThoughtLeaderName,
} from '../../helpers/usersHelpers';
import { PROFILE_CONTEXTS } from '../../constants/contextTracking';
import { FlatButton } from '../../main/components/buttons';
import styled from 'styled-components';

const NameColor = styled.div`
  * {
    color: ${({ theme }) => theme.themeColors.text};
  }
`;

const SaveWrapper = styled.div`
  button {
    padding: 8px 12px;
  }
`;
const OverwriteButtonWrapper = styled.div`
  button {
    padding: 8px 12px;
  }
`;

const IdeaShowHeader = (props) => {
  const {
    currentUser,
    security,
    users,
    idea,
    ideaUserId,
    userHasIdea,
    ideaIsCurrentUsersIdea,
    thoughtLeaderIdea,

    toggleActiveInput,

    showOverwriteIdeaHeading,
    createPromptForIdeaOverwrite,
    handleIdeaOverwrite,
    handleIdeaOverwriteCancel,

    handleSeeMorePositions,
    saveIdea,
    saveTLIdea,
    removeIdea,
    handleExitPosition,
    handleKeepPosition,

    screenWidth,
  } = props;

  const ideaOwner = findUser(ideaUserId, users, currentUser);
  const ideaOwnerFullName = ideaUserId === currentUser.user_id ? 'Me' : userFullName(ideaOwner);

  const isSourced = props.idea.source_user_id > 0 && findUser(props.idea.source_user_id, users, currentUser);
  const options = { showThoughtLeaderCompany: true };
  const sourceUsersName = isSourced && userFullName(findUser(props.idea.source_user_id, users, currentUser), options);
  const allocation = props.allocation !== null && props.allocation !== undefined ? props.allocation : null;

  const isSecurityFeatured = security.featured;

  const logEventForOptimizerToggle = () => {
    const security = props.security;
    const idea = props.idea;
    const event = idea.active ? 'Toggled Idea Inactive' : 'Toggled Idea Active';
    const properties = {
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
      Context: 'Idea Panel',
    };
    logMetricsTrackingEvent(event, properties)();
  };

  const renderUser = () => {
    return (
      <div className="idea-user-info-container">
        <div
          className={`idea-view-profile-avatar ${
            screenWidth > 500 ? 'inline-user-container' : 'stacked-user-container'
          }`}
        >
          <UserContainer userId={ideaUserId}>
            <ProfileAvatar
              size={screenWidth > 625 ? 'large' : 'medium'}
              profileLinkQuery={`?context=${PROFILE_CONTEXTS.IDEA}`}
            />
          </UserContainer>
          <div className={`idea-user-data-container ${screenWidth > 500 && 'inline'}`}>
            <NameColor className={`idea-owner-name   ignore-link-styling`}>
              <Link to={`/users/${ideaUserId}/public_profile`}>{ideaOwnerFullName}</Link>
            </NameColor>
            {isSourced && props.idea.source_user_id !== ideaUserId && (
              <div className="idea-source-user secondary-text-color">
                {'Sourced from '}
                <Link to={`/users/${props.idea.source_user_id}/public_profile`}>{`${sourceUsersName}`}</Link>
              </div>
            )}
            {allocation !== null ? (
              <div className="user-allocation">
                <DisplayPercentageComponent
                  rawValue={allocation}
                  decimalPlaces={2}
                  groupValuesUnder={0.1}
                  showZeroWithNoDecimal
                />
                <span>{` of Portfolio`}</span>
              </div>
            ) : (
              <div className="user-allocation">{`No position in ${security.symbol}`}</div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderThoughtLeader = () => {
    const thoughtLeader = findUser(ideaUserId, users, currentUser);
    return (
      <div className="idea-user-info-container">
        <div className={`idea-view-profile-avatar idea-view-profile-avatar-tl`}>
          <NameColor className={`idea-view-profile-tl-name  `}>
            <a href={`/users/${thoughtLeader.user_id}`}>{displayThoughtLeaderCompany(thoughtLeader)}</a>
          </NameColor>
          <div className={`idea-view-profile-tl-company  `}>{displayThoughtLeaderName(thoughtLeader)}</div>
        </div>
      </div>
    );
  };

  const renderThoughtLeaderHeading = () => {
    return (
      <div className="idea-show-heading">
        <div className="view-idea-user-container">{renderThoughtLeader()}</div>
        <span className="idea-action-buttons-container thought-leaders">
          {isSecurityFeatured && (
            <div className="idea-save-container">
              <FlatButton width={120} onClick={saveTLIdea}>
                Save
              </FlatButton>
            </div>
          )}
        </span>
      </div>
    );
  };

  const renderUserHeading = () => {
    return (
      <div className="idea-show-heading">
        <div className="view-idea-user-container">{renderUser()}</div>
        {isSecurityFeatured && (
          <span className="idea-action-buttons-container">
            {null && (
              <div className="idea-ask-why-container">
                <AskAnyone
                  viewingContext={'Ideas Panel'}
                  ideaId={props.idea.idea_id}
                  autoOpen={props.autoOpenAskWhy}
                  additionalDismissAskWhyAction={props.additionalDismissAskWhyAction}
                  openFormInModalOnMobile
                />
              </div>
            )}
            <SaveWrapper className="idea-save-container">
              <FlatButton width={115} onClick={userHasIdea ? createPromptForIdeaOverwrite : saveIdea}>
                {getIdeaPhraseFor('add')}
              </FlatButton>
            </SaveWrapper>
          </span>
        )}
      </div>
    );
  };

  const renderDeleteOptions = () => {
    return (
      <IdeaDeleteOptions
        symbol={security.symbol}
        handleExitPosition={handleExitPosition}
        handleKeepPosition={handleKeepPosition}
      />
    );
  };

  const renderCurrentUserHeading = () => {
    return (
      <div className="idea-show-heading">
        <div className="view-idea-user-container">{renderUser()}</div>
        <span className="idea-action-buttons-container current-users-idea">
          {isSecurityFeatured && (
            <div className="idea-active-toggle-button-container" onClick={logEventForOptimizerToggle}>
              {createInputComponent(toggleActiveInput.input, toggleActiveInput.defaultProps, toggleActiveInput.input)}
            </div>
          )}
          <div className={`wrapper`}>
            <IconButton
              size="medium"
              handleClick={removeIdea}
              icon="fa-trash-o"
              colorClassName="secondary-text-color"
              customClass="idea-remove-btn"
            />
            {props.showDeleteOptions && renderDeleteOptions()}
          </div>
        </span>
      </div>
    );
  };

  const renderOverwriteIdeaHeading = () => {
    return (
      <div className="idea-show-heading overwrite">
        <div className="overwrite-idea-heading-message">{getIdeaPhraseFor('replace')}</div>
        <OverwriteButtonWrapper className="overwrite-idea-action-btns">
          <FlatButton width={95} handleClick={handleIdeaOverwrite}>
            Overwrite
          </FlatButton>
          <FlatButton width={95} handleClick={handleIdeaOverwriteCancel}>
            Cancel
          </FlatButton>
        </OverwriteButtonWrapper>
      </div>
    );
  };

  const renderHeading = () => {
    if (showOverwriteIdeaHeading) {
      return renderOverwriteIdeaHeading();
    }

    if (ideaIsCurrentUsersIdea) {
      return renderCurrentUserHeading();
    }

    if (thoughtLeaderIdea) {
      return renderThoughtLeaderHeading();
    } else {
      return renderUserHeading();
    }
  };

  return (
    <div className="idea-show-heading-container">
      {renderHeading()}
      <div className="idea-heading-hr border-accent"></div>
    </div>
  );
};

export default IdeaShowHeader;
