import React from 'react';

import { UserAvatarAndName } from '../../../../UI/User/UserAvatarAndName';
import ConnectionRequest from '../../../../buttons/ConnectionRequest';
import { PROFILE_CONTEXTS } from '../../../../../constants/contextTracking';

export const NewConnectionRow = (props) => {
  const { userId, showConnectionRequestBtn, hideIgnoreBtn } = props;

  return (
    <tr className={`new-connection-row`}>
      <td className="new-connection-row-name-cell">
        <UserAvatarAndName
          avatarSize="small"
          displayNameStyle="fullName"
          profileLinkQuery={`?context=${PROFILE_CONTEXTS.CONNECTIONS}`}
          userId={userId}
          withLink
        />
      </td>
      <td className="new-connection-row-connect-action-cell">
        {showConnectionRequestBtn && (
          <ConnectionRequest
            userId={userId}
            viewingContext={'New Connection Notification'}
            btnSizeClassName="btn-small"
            hideIgnoreBtn={hideIgnoreBtn}
          />
        )}
      </td>
    </tr>
  );
};
