import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { FlatButton } from '../../buttons';
import { Body5, Body6 } from '../../../lib/nvstr-common-ui.es';

const Wrapper = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid ${({ theme }) => theme.themeColors.text};
`;
const Content = styled.div`
  padding: 16px;
  position: relative;
  z-index: 2;
`;
const ButtonWrapper = styled.div``;
const MessageContent = styled.div``;
const ActionsWrapper = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SkeletonBannerActionsUnder = ({
  headingText,
  message,
  subMessage,
  ctaButtonText,
  onCTAClick,
  onDismiss,
  ContainerComponent,

  children,
}) => {
  const theme = React.useContext(ThemeContext);
  const textColor = theme.themeColors.text;
  const ctaBtnTextColor = theme.themeColors.appBackground;

  return (
    <ContainerComponent>
      <Wrapper>
        <Content>
          <MessageContent>
            {headingText && <Body6 bold>{headingText}</Body6>}
            {message && (
              <div style={{ paddingTop: headingText ? 4 : 0 }}>
                <Body5 isSmall>{message}</Body5>
              </div>
            )}
            {subMessage && (
              <div style={{ paddingTop: message ? 4 : 0 }}>
                <Body5 isSmall>{subMessage}</Body5>
              </div>
            )}
          </MessageContent>
          {children}
          <ActionsWrapper>
            <ButtonWrapper>
              <FlatButton color={ctaBtnTextColor} textColor={textColor} onClick={onCTAClick}>
                {ctaButtonText}
              </FlatButton>
            </ButtonWrapper>
            <ButtonWrapper>
              <FlatButton color={ctaBtnTextColor} textColor={textColor} onClick={onDismiss}>
                Dismiss
              </FlatButton>
            </ButtonWrapper>
          </ActionsWrapper>
        </Content>
      </Wrapper>
    </ContainerComponent>
  );
};
