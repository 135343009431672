import React from 'react';
import { ExpandedPageBackground, Page } from '../layout';
import styled from 'styled-components';
import { Body5 } from '../../lib/nvstr-common-ui.es';
import LoadingSpinner from '../Images/LoadingSpinner';
import UnstyledLoadingSpinner from '../Images/UnstyledLoadingSpinner';

const Content = styled.div`
  padding: 24px;
  text-align: center;
`;
const LoadingWrapper = styled.div`
  text-align: center;
`;

const LoadingText = styled.div`
  padding-top: 8px;
  text-align: center;
`;

export const LoadingPage = ({ message, pageProps }) => {
  return (
    <ExpandedPageBackground>
      <Page height={'160px'} {...pageProps}>
        <Content>
          <LoadingWrapper>
            <UnstyledLoadingSpinner />
          </LoadingWrapper>
          <LoadingText>
            <Body5>{message || 'Loading...'}</Body5>
          </LoadingText>
        </Content>
      </Page>
    </ExpandedPageBackground>
  );
};
