import React from 'react';
import { TruncateString } from '../UI/UtilityComponents/TruncateString';

export const SecurityName = (props) => {
  const { name, className } = props;

  return (
    <span className={`security-name ${className || ''}`}>
      <TruncateString string={name} />
    </span>
  );
};
