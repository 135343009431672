import { logMetricsTrackingEvent } from '../../../actions';

export const REFERRAL_TYPES = {
  email: 'Email',
  shareLink: 'Share Link',
  shareMessaging: 'Share Messaging',

  manualEmail: 'Manual Email',
  facebook: 'Facebook',
  twitter: 'Twitter',
  linkedIn: 'LinkedIn',

  phoneContacts: 'Phone Contacts',
  gmailContact: 'Gmail Contacts',

  askShare: 'Ask Why Share',
};

const PRESS_REFERRAL_CTA = {
  send: (properties = {}) => {
    // 'Context'
    const event = 'Press Referral CTA';
    logMetricsTrackingEvent(event, properties)();
  },
};

const USE_REFERRAL_ACTION = {
  send: (referralType, p = {}) => {
    // referralType: REFERRAL_TYPES

    const event = 'Send Referral';
    logMetricsTrackingEvent(event, {
      ...p,
      'Referral Type': referralType,
    })();
  },
};

const trackingEvents = {
  PRESS_REFERRAL_CTA,

  USE_REFERRAL_ACTION,
};

export default trackingEvents;
