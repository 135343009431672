import React from 'react';
import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import Icon from '../../components/misc/Icon';

class DropdownWithStrings extends React.Component {
  render() {
    return (
      <fieldset className={`standalone-dropdown-container ${this._returnName()} ${this._returnClassName()}`}>
        {this._renderLabel()}
        {this._renderDropdown()}
        {this.props.showArrowIcons && (
          <React.Fragment>
            <div className={'dropdown-input-icons'}>
              <Icon
                size={'small'}
                icon={'fa-sort-asc'}
                colorClassName={' '}
                style={this.props.arrowIconStyleTop || {}}
                // you must apply css styling for position style={{ position: 'absolute', top: '10px', right: '10px' }}
              />
            </div>
            <div className={'dropdown-input-icons'}>
              <Icon
                size={'small'}
                icon={'fa-sort-desc'}
                colorClassName={' '}
                style={this.props.arrowIconStyleBot || {}}
                // you must apply css styling for position style={{ position: 'absolute', top: '10px', right: '10px' }}
              />
            </div>
          </React.Fragment>
        )}
      </fieldset>
    );
  }

  _renderLabel = () => {
    return this._shouldShowLabel() && <label className={'form-label'}>{this._returnLabel()}</label>;
  };

  _renderDropdown = () => {
    return (
      <select name={this._returnName()} value={this._returnValue().name} onChange={this._handleDropdownSelection}>
        {this._returnOptionValues().map((value, i) => this._renderOption(value, i))}
      </select>
    );
  };

  _renderOption = (value, i) => {
    return (
      <option
        value={value.name}
        key={`${this._returnName()}-${value.name}-${i}`}
        className={`option-${this._returnName()}-${value.name}`}
        disabled={this._isDisabled(value)}
      >
        {this._returnOptionText(value)}
      </option>
    );
  };

  _returnOptionText = (value) => {
    const text = value.displayName;
    if (this._isDisabled(value)) {
      let disabledReasonText = `${text} - Disabled`;
      if (value.disabledReason) {
        disabledReasonText = `${text} - ${value.disabledReason()}`;
      }
      return disabledReasonText;
    }

    return text;
  };

  _renderErrorMessage = () => {
    const isError = !!this.props.errorMessage;
    const errorMessage = isError ? this.props.errorMessage : '';
    return <p className={'text-field-error-message'}>{errorMessage}</p>;
  };

  _returnOptionValues = () => {
    const values = this._returnOptionValuesFromProps();
    const options = this._returnOptionValuesFromProps();
    if (this._shouldIncludeLabelAsSelection()) {
      return [this._returnLabelValue(), ...options];
    }
    return options;
  };

  _returnLabelValue = () => ({
    id: null,
    name: this._returnLabel(),
    displayName: this._returnLabel(),
    isEnabled: () => true,
    disabledReason: () => null,
  });

  _returnOptionValuesFromProps = () => this.props.options;

  _returnName = () => this.props.name || '';

  _shouldIncludeLabelAsSelection = () => this.props.includeLabelAsSelection;

  _returnLabel = () => this.props.label;

  _shouldShowLabel = () => {
    if (isUndefinedOrNull(this._returnLabel())) {
      return false;
    }
    return this.props.forceShowLabel || this._returnLabel() !== this._returnValue();
  };

  _returnValue = () => this.props.value;

  _convertSelectionValueToValue = (selectionNameValue) => {
    return this._returnOptionValues().filter(
      (value) => (value.name ? value.name.toString() : null) === selectionNameValue
    )[0];
  };

  _returnClassName = () => this.props.className || '';

  _isDisabled = (value) => {
    if (value && !('isEnabled' in value)) {
      return false;
    }
    return !value.isEnabled();
  };

  _handleDropdownSelection = (e) => {
    const selectionNameValue = e.target.value;
    const value = this._convertSelectionValueToValue(selectionNameValue);
    if (this._isDisabled(value)) {
      return false;
    }
    this.props.handleSelection(this._returnName(), value);
    this._handleAdditionalActions(value);
  };

  _handleAdditionalActions = (value) => {
    if (this.props.handleAdditionalActions) {
      this.props.handleAdditionalActions(value, this._returnName());
    }
  };
}

export default DropdownWithStrings;
