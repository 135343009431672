import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../../actions/index';
import CreateThoughtContainer from '../../../Thoughts/CreateThoughtContainer';
import ThoughtContainer from '../../../Thoughts/ThoughtContainer';
import AskAnyone from '../../../buttons/AskAnyone';
import BasicSecurityDataWrapper from '../../../SecurityData/BasicSecurityDataWrapper';
import SecurityIdentifierDisplay from '../../../UI/SecurityIdentifierDisplay';
import Button from '../../../../components/buttons/Button';
import LoadingIcon from '../../../../components/misc/LoadingIcon';
import { InlineUserList } from '../../../UI/InlineUserList';
import { DisappearingComponent } from '../../../UI/DisappearingComponent';
import { findIdea } from '../../../../helpers/ideaHelpers';
import { isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import { isSecurityDataLoading } from '../../../../helpers/securitiesHelpers';
import { getThoughtImpressionEventName, ThoughtEventNames } from '../../../../constants/tracking';
import { PROFILE_CONTEXTS } from '../../../../constants/contextTracking';
import { FlatButton, SkeletonButton } from '../../../../main/components/buttons';
import { Container } from '../../../../main/components/ui';

const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
`;
const SeeMoreWrapper = styled.div`
  button {
    padding: 8px 8px 4px 0;
  }
`;
const ProButtonWrapper = styled.div`
  button {
    padding: 4px 6px;
  }
`;
const ConButtonWrapper = styled.div`
  button {
    padding: 4px 6px;
  }
`;

class AskNotification extends Component {
  constructor() {
    super();
    this.state = {
      showAll: false,
      isCreatingThoughtForSecurityId: null, // security_id -> used to toggle open create thought component
      addingThoughtType: null, // used to default ThoughtType of create thought
      sentAskActionInCurrentRenderLifecycle: false, // used to toggle a message to user that we will send a notif to asker on your idea current status
    };
  }

  componentDidMount() {
    this._getSecuritiesData().then((response) => {
      this.props.onLoadComplete();
    });
  }

  _getSecuritiesData = () => {
    return this.props.actions.quickFetchSecuritiesData(
      this._returnNotificationSecurities().map((sec) => sec.security_id)
    );
  };

  render() {
    return this._isAskDirectedAtCurrentUser() ? this._renderAskCurrentUser() : this._renderAskOtherUsers();
  }

  _renderAskCurrentUser = () => {
    return (
      <div className="ask-notification ask-current-user">
        {this._returnNotificationAsks().map((ask) => (
          <div key={`ask_${ask.id}_${this._returnNotificationTimestamp()}`}>
            <InlineUserList
              userIds={[ask.asker.user_id]}
              suffix={
                <span className="inline-punctuation">
                  <span className="inline-conjunction  ">asked you about</span>
                  <SecurityIdentifierDisplay
                    securityId={ask.security.security_id}
                    symbol={ask.security.symbol}
                    inFeed
                    showSymbol
                  />
                </span>
              }
              profileLinkQuery={`?context=${PROFILE_CONTEXTS.NOTIFICATION}`}
              withAvatar
            />
            <div className={'ask-question-bubble-container'}>
              <div className={'ask-question-bubble main-bg-color-match  '}>{`${ask.question}`}</div>
            </div>
            {ask.thought && (
              <div className={'was-asked-thought-container'}>
                <BasicSecurityDataWrapper
                  securityId={ask.security.security_id}
                  ignoreImpressionTracking={this.props.ignoreImpressionTracking}
                >
                  <ThoughtContainer
                    key={`ask-notification-thought-container-${ask.thought.id}`}
                    notificationIndex={this.props.notificationIndex}
                    thoughtId={ask.thought.id}
                    securityId={ask.security.security_id}
                    viewingContext={getThoughtImpressionEventName('ASK_NOTIFICATION_IN_FEED')}
                    scrollContainerSelector={window}
                    ignoreImpressionTracking={this.props.ignoreImpressionTracking}
                    inFeed={this.props.inFeed}
                    inActivityPageFeed={this.props.inActivityPageFeed}
                    showThoughtHeading
                    showAlternateOpinionActionStyle
                    isUserAuthed
                    fetchThoughtData
                    hideOpinionCounts
                    withThoughtLink
                    hideShareLink
                  />
                </BasicSecurityDataWrapper>
              </div>
            )}
            <div className={'respond-container'}>
              {this._shouldRenderCreateThoughtForAsk(ask) ? (
                this._renderCreateThoughtForAsk(ask, true)
              ) : (
                <div className={''}>
                  <div className={'respond-now-action-container'}>
                    <p className="ask-respond-heading">
                      <span className="inline-punctuation">{'Respond by posting a Pro or Con for'}</span>
                      <span style={{ display: 'inline-block' }}>
                        <SecurityIdentifierDisplay
                          securityId={ask.security.security_id}
                          symbol={ask.security.symbol}
                          inFeed
                          showSymbol
                        />
                        <span>{'.'}</span>
                      </span>
                    </p>
                    <FlatButton onClick={this._bindRespondNowActionToHandler(ask)}>Respond Now</FlatButton>
                  </div>
                </div>
              )}
            </div>
            {ask.idea && this._renderAskIdeaActionContainer(ask)}
            {ask.thought && this._renderAskThoughtActionContainer(ask)}
            {!ask.thought && !ask.idea && this._renderAskSecurityActionContainer(ask)}
          </div>
        ))}
      </div>
    );
  };

  _renderAskOtherUsers = () => {
    return (
      <div className="ask-notification ask-others">
        <span className=" ">
          <InlineUserList
            userIds={this._returnNotificationAskers().map((user) => user.user_id)}
            additionalOthers={this._returnNotification().community_asker_count}
            listLengthLimit={3}
            profileLinkQuery={`?context=${PROFILE_CONTEXTS.NOTIFICATION}`}
            pluralAnonymousMessage={'People are asking about'}
            singularAnonymousMessage={'Stocks being asked about'}
            beAnonymous
            withAvatar
          />
        </span>
        <div className={'asked-securities-list-container'}>
          {this._isRespondingToASecurity()
            ? this._renderPromptToAddSecurityThought(this._returnRespondingToSecurity())
            : this._areSecuritiesLoading()
            ? this._renderLoading()
            : this._renderSecuritiesAskList()}
        </div>
      </div>
    );
  };

  _areSecuritiesLoading = () => {
    const securities = this._returnNotificationSecurities();
    const ids = securities.map((sec) => sec.security_id);
    return ids.every((id) => isSecurityDataLoading(id, this.props.securities));
  };

  _renderLoading = () => {
    return (
      <div className={'loading-securities-for-ask-notif'}>
        <LoadingIcon icon="fading-3balls" size="small" />
      </div>
    );
  };

  _renderPromptToAddSecurityThought = (security) => {
    return (
      <div className={''}>
        {this._renderAskedSecurityListItem(security)}
        {this._renderCreateThought(security)}
      </div>
    );
  };

  _renderAskedSecurityListItem = (security, withActions) => {
    const { security_id, symbol, name } = security;
    return (
      <div
        key={`ask_security_${security_id}_${this._returnNotificationTimestamp()}`}
        className={'asked-security-list-item-container'}
      >
        <div className={'ask-security-identifier-container  '}>
          <SecurityIdentifierDisplay
            securityId={security_id}
            symbol={symbol}
            name={name}
            prefix={'('}
            suffix={')'}
            showSymbol
            showName
            limitNameWidth
            inFeed
          />
        </div>
        {withActions && this._renderAskActionButtons(security)}
      </div>
    );
  };

  _renderSecuritiesAskList = () => {
    const notificationSecurities = this._returnNotificationSecurities();
    return (
      <div>
        {notificationSecurities
          .slice(0, this.state.showAll ? notificationSecurities.length : 5)
          .map((security) => this._renderAskedSecurityListItem(security, true))}
        {notificationSecurities.length > 5 && (
          <SeeMoreWrapper>
            <FlatButton
              transparent
              onClick={() =>
                this.setState({
                  ...this.state,
                  showAll: !this.state.showAll,
                })
              }
            >
              Show {this.state.showAll ? 'Less' : 'More'}
            </FlatButton>
          </SeeMoreWrapper>
        )}
      </div>
    );
  };

  _renderAskIdeaActionContainer = (ask) => {
    return this._hasRespondedWithIdeaAction(ask)
      ? this._renderAskActionSubmitted(ask)
      : this._renderIdeaActionPrompt(ask);
  };

  _renderAskActionSubmitted = (ask) => {
    return (
      this.state.sentAskActionInCurrentRenderLifecycle && (
        <DisappearingComponent timeoutLengthToDisappear={5000}>
          <div className={'success-action-message idea-updated-message-from-ask'}>
            <p>{`We will send ${ask.asker.first_name} an update.`}</p>
          </div>
        </DisappearingComponent>
      )
    );
  };

  _renderIdeaActionPrompt = (ask) => {
    return (
      <div className={'idea-action-container'}>
        <p className="ask-respond-heading">
          <span>{'Nothing more to say on'}</span>
          <span>&nbsp;</span>
          <span>
            <SecurityIdentifierDisplay
              securityId={ask.security.security_id}
              symbol={ask.security.symbol}
              showSymbol
              inFeed
            />
          </span>
          <span>&nbsp;</span>
          <span>
            {`right now? Then just let ${ask.asker.first_name} know if you still like ${
              ask.idea.idea_type.id === 1 ? 'shorting it.' : 'it.'
            }`}
          </span>
        </p>
        <Container
          className={`ask-action-buttons-container ${ask.idea.idea_type.id === 1 ? 'short-position' : ''}`}
          row
          verticallyCenter
          gap={4}
        >
          <SkeletonButton small onClick={this._bindReaffirmIdeaButtonToHandler(ask)}>
            {ask.idea.idea_type.id === 1 ? 'Still Like Shorting It' : 'Still Like It'}
          </SkeletonButton>
          <SkeletonButton small onClick={this._bindDeactivateIdeaButtonToHandler(ask)}>
            Not Anymore
          </SkeletonButton>
        </Container>
      </div>
    );
  };

  _renderAskThoughtActionContainer = (ask) => {
    return null;
  };

  _renderAskSecurityActionContainer = (ask) => {
    return null;
  };

  _renderAskActionButtons = (security) => {
    return (
      <ActionButtonsWrapper className={'ask-notif-post-thought-action-buttons'}>
        <ProButtonWrapper>
          <FlatButton onClick={this._bindAddThoughtButtonToHandler(security, 'pro')}>+ Pro</FlatButton>
        </ProButtonWrapper>
        <ConButtonWrapper>
          <FlatButton onClick={this._bindAddThoughtButtonToHandler(security, 'con')}>+ Con</FlatButton>
        </ConButtonWrapper>
        <AskAnyone
          viewingContext={'Ask Notif'}
          securityId={security.security_id}
          customAskType={'askNotif'}
          customAskTypeButtonRenderer={'_renderAskButtonWithIcon'}
          customAskTypeDisabledButtonRenderer={'_renderAskButtonWithIcon'}
          openFormInModal
        />
      </ActionButtonsWrapper>
    );
  };

  _renderCreateThoughtForAsk = (ask, askedCurrentUser) => this._renderCreateThought(ask.security, askedCurrentUser);

  _renderCreateThought = (security, askedCurrentUser) => {
    return (
      <CreateThoughtContainer
        key={`ask-thought-${security.security_id}`}
        customMessageWithSymbol={() => `Respond with a Pro or Con for ${security.symbol}.`}
        securityId={security.security_id}
        thoughtType={this.state.addingThoughtType}
        handleFinishCreatingThought={this._handleFinishCreatingThought}
        handleCancelCreatingThought={this._handleCancelCreatingThought}
        viewingContext={ThoughtEventNames.CREATE_THOUGHT_ASK_NOTIFICATION_RESPONSE}
        loadSecurityDataIfNecessary
      />
    );
  };

  _bindReaffirmIdeaButtonToHandler = (ask) => {
    return () => this._handleReaffirmIdeaClickFromAsk(ask);
  };

  _setSentAskActionInCurrentRenderLifecycle = () => {
    this.setState(() => ({
      sentAskActionInCurrentRenderLifecycle: true,
    }));
  };

  _handleAdditionalIdeaActionInAsk = () => {
    this._setSentAskActionInCurrentRenderLifecycle();
  };

  _handleReaffirmIdeaClickFromAsk = (ask) => {
    this._handleAdditionalIdeaActionInAsk();
    this.props.actions.reaffirmIdea(ask.idea);
  };

  _bindDeactivateIdeaButtonToHandler = (ask) => {
    return () => this._handleDeactivateIdeaClickFromAsk(ask);
  };

  _handleDeactivateIdeaClickFromAsk = (ask) => {
    this._handleAdditionalIdeaActionInAsk();
    this.props.actions.deactivateIdea(ask.idea);
  };

  _bindAddThoughtButtonToHandler = (security, thoughtType) => {
    return () => this._handleAddThoughtClick(security, thoughtType);
  };

  _bindRespondNowActionToHandler = (ask) => {
    return () => this._handleRespondNowClick(ask.security);
  };

  _handleRespondNowClick = (security) => {
    this._logRespondNowClick(security);
    this.setState(() => ({
      isCreatingThoughtForSecurityId: security.security_id,
    }));
  };

  _handleAddThoughtClick = (security, thoughtTypeName) => {
    this._logAskProConClick(security, thoughtTypeName);
    this.setState((prevState) => ({
      isCreatingThoughtForSecurityId: security.security_id,
      addingThoughtType: thoughtTypeName,
    }));
  };

  _clearCreatingThought = () => {
    this.setState((prevState) => ({
      isCreatingThoughtForSecurityId: null,
      addingThoughtType: null,
    }));
  };

  _handleCancelCreatingThought = () => {
    this._clearCreatingThought();
  };

  _handleFinishCreatingThought = () => {
    this._clearCreatingThought();
    this._getUpdatedNotifications();
  };

  _getUpdatedNotifications = () => {
    this.props.actions.getUpdatedNotifications({ batch_index: 0, page: 1 }).then((response) => {
      this.props.actions.getUnseenNotificationsCount();
      return response;
    });
  };

  _isRespondingToASecurity = () => !isUndefinedOrNull(this._returnRespondingToSecurityId());

  _returnRespondingToSecurity = () =>
    this._returnNotificationSecurities().filter(
      (security) => security.security_id === this._returnRespondingToSecurityId()
    )[0];

  _returnRespondingToSecurityId = () => this.state.isCreatingThoughtForSecurityId;

  _shouldRenderCreateThoughtForAsk = (ask) => this._returnRespondingToSecurityId() === ask.security.security_id;

  _hasRespondedWithIdeaAction = (ask) => {
    const ideaLastRefTime = this._returnStoreIdeaFromAsk(ask).last_reaffirmed_at_timestamp * 1000;
    const notifTimestamp = this._returnNotificationTimestamp();
    const isIdeaInactive = !this._returnStoreIdeaFromAsk(ask).active;
    return ideaLastRefTime > notifTimestamp || isIdeaInactive;
  };

  _returnNotificationTimestamp = () => this.props.notification.timestamp * 1000;

  _returnNotificationAsks = () => this._returnNotification().asks;

  _returnNotificationAskers = () => this._returnNotification().askers;

  _returnNotificationSecurities = () =>
    this._returnNotification().securities || [this._returnNotification().asks[0].security];

  _returnNotification = () => this.props.notification;

  _isAskDirectedAtCurrentUser = () => this._returnNotificationAsks();

  _returnStoreIdeaFromAsk = (ask) => {
    const idea = ask.idea;
    const ideaId = idea.idea_id;
    const storeIdea = findIdea(ideaId, this._returnIdeaStore());
    return storeIdea;
  };

  _returnIdeaStore = () => this.props.ideas;

  _logRespondNowClick = (security) => {
    const event = 'Add Thought Panel Opened';
    const securityId = security.security_id;
    const properties = {
      Context: 'Ask Notification',
      'Concernable Id': securityId,
      'Concernable Type': 'Security',
      'Security ID': securityId,
      'Stock Symbol': security.symbol,
      'Respond Now Click': true,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logAskProConClick = (security, thoughtTypeName) => {
    const thoughtType = thoughtTypeName === 'pro' ? 'Pro' : 'Con';
    const event = 'Add Thought Panel Opened';
    const securityId = security.security_id;
    const properties = {
      Context: 'Ask Notification',
      'Thought Type': thoughtType,
      'Concernable Id': securityId,
      'Concernable Type': 'Security',
      'Security ID': securityId,
      'Stock Symbol': security.symbol,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => ({
  ideas: state.ideas,
  securities: state.securities,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(AskNotification);

export default connectedComponent;
