import React from 'react';
import { BaseScroll } from './BaseScroll';
import { throwError } from '../../helpers/devToolHelpers';
import { isUndefinedOrNull } from '../../helpers/generalHelpers';

/*

  <BaseScroll
    $scrollElement -> $('') <jquery selector> -> for a custom parent element, if you do not want the BaseScroll ref element
    className -> <string> -> custom class selector
    style -> <object> -> custom style, use js syntax for css properties
    distanceFromBottomToTriggerInfiniteScroll -> int
  >
    <children>
  </BaseScroll>

*/

export class InfiniteScroll extends React.Component {
  constructor() {
    super();
    this.state = {
      _scrollTriggers: [], // push to array to trigger mock scroll event in BaseScroll
    };
    this._isInfiniteScrollTriggered = false;
  }

  componentDidMount() {
    if (!this.props.id) {
      throwError('Must supply a unique ID to InfiniteScroll');
    }
  }

  _returnDefaultDistanceFromBottomToTriggerInfiniteScroll = () => 600;

  _returnDistanceFromBottomToTriggerInfiniteScroll = () => {
    if (isUndefinedOrNull(this.props.distanceFromBottomToTriggerInfiniteScroll)) {
      return this._returnDefaultDistanceFromBottomToTriggerInfiniteScroll();
    }
    return this.props.distanceFromBottomToTriggerInfiniteScroll;
  };

  render() {
    return (
      <div
        ref={(el) => (this.InfiniteScroll = el)}
        id={this.props.id}
        className={this.props.className || ''}
        style={this.props.style || {}}
      >
        <BaseScroll
          $scrollElement={this.props.$scrollElement}
          onScroll={this.handleScroll}
          scrollTriggers={this.state._scrollTriggers}
          renderChildrenWithoutScrollActions
        >
          {this.props.children}
        </BaseScroll>
      </div>
    );
  }

  isInfiniteScrollTriggered = () => this._isInfiniteScrollTriggered;

  handleScroll = (scrollData) => {
    if (!this.props.ignoreInfiniteScroll && this._didTriggerInfiniteScroll(scrollData)) {
      this._setInfiniteScrollTriggered();
      this.props.onInfiniteScroll().then((shouldUpdate) => {
        this._clearInfiniteScrollTriggered();
        if (shouldUpdate) {
          this._handleAfterInfiniteScrollEventComplete();
        }
      });
    }
  };

  _triggerInfiniteScrollCheck = () => {
    this.setState((prevState) => ({
      _scrollTriggers: [...prevState._scrollTriggers, 1],
    }));
  };

  _handleAfterInfiniteScrollEventComplete = () => {
    setTimeout(this._triggerInfiniteScrollCheck, 600);
  };

  _didTriggerInfiniteScroll = (scrollData) =>
    scrollData.scrollDistance >= this._calculateScrollDistanceInfiniteScrollTriggerPoint(scrollData) &&
    !this.isInfiniteScrollTriggered();

  _$returnInfiniteScrollElement = () => $(`#${this.props.id}`);

  _calculateScrollDistanceInfiniteScrollTriggerPoint = (scrollData) =>
    scrollData.scrollableDistance - this._returnDistanceFromBottomToTriggerInfiniteScroll();

  _calculateOffsetBottomOfInfiniteScrollElement = () => scrollableDistance;

  _setInfiniteScrollTriggered = () => {
    this._isInfiniteScrollTriggered = true;
  };

  _clearInfiniteScrollTriggered = () => {
    this._isInfiniteScrollTriggered = false;
  };
}
