import React from 'react';

import UserContainer from '../UserContainer';
import UserName from '../../../components/user/UserName';

import Icon from '../../../components/misc/Icon';
import IconButton from '../../../components/buttons/IconButton';

class UserMessageRecipient extends React.Component {
  constructor() {
    super();

    this.state = {
      _showErrorMessage: false,

      _timeoutForErrorMessageShow: null,
    };
  }

  componentDidMount() {
    if (this._isError()) {
      this._onUserHasError();
    }
  }

  componentWillUnmount() {
    this._clearTimeoutForErrorMessageShow();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this._isError(prevProps) && this._isError()) {
      this._onUserHasError();
    }
  }

  render() {
    return (
      <div
        className={`${this.props.className || ''}`}
        onMouseOver={this._handleMouseOver}
        onMouseOut={this._handleMouseOut}
        onClick={this._handleClick}
      >
        {this._isError() ? this._renderCannotAskUserErrorIcon() : null}
        {this._shouldDisplayAskNotAllowedForUserMessage() ? this._renderCannotAskUserErrorMessage() : null}
        {this.props.isCTL ? (
          <div className="user-container" style={{ textAlign: 'left' }}>
            <span>Community Thought Leader</span>
          </div>
        ) : (
          <UserContainer userId={this._returnUserId()}>
            <UserName displayNameStyle="fullName" withLink={false} />
          </UserContainer>
        )}
        {this._renderRemoveUserButton()}
      </div>
    );
  }

  _renderCannotAskUserErrorIcon = () => {
    return (
      <div className={`ask-user-unavailable-icon`}>
        <Icon icon={'fa-exclamation-circle'} size="medium" colorClassName={'error-text-color'} />
      </div>
    );
  };

  _renderCannotAskUserErrorMessage = () => {
    return (
      <div className={`ask-user-unavailable-message-container fade-in-up-quick`}>
        <div className={`info-tooltip-container component-bg`}>
          <div className={`info-tooltip-container-arrow component-bg`}></div>
          <div className={`text-container`}>{this._returnErrorMessage()}</div>
        </div>
      </div>
    );
  };

  _renderRemoveUserButton = () => {
    return (
      <div className={`remove-user-from-to-button-container`}>
        <IconButton
          icon="fa-times fa-times-thin"
          colorClassName="secondary-text-color"
          handleClick={this._handleRemove}
        />
      </div>
    );
  };

  _handleMouseOver = () => {
    this._setIsHovering();
    this._setShowErrorMessage();
  };

  _handleMouseOut = () => {
    this._clearIsHovering();
    if (!this._returnTimeoutForErrorMessageShow()) {
      this._createAndSaveShortTimeoutToHideErrorMessage();
    }
  };

  _handleClick = () => {
    this._setShowErrorMessage();
    this._createAndSaveTimeoutToHideErrorMessage();
  };

  _handleRemove = () => {
    this.props.handleRemove(this._returnUserId());
  };

  _onUserHasError = () => {
    this._setShowErrorMessage();
    this._createAndSaveTimeoutToHideErrorMessage();
  };

  _returnUserId = () => this.props.userId;
  _isError = (props) => (props || this.props).isError;
  _returnErrorMessage = () => this.props.errorMessage;

  _showErrorMessage = (state) => (state || this.state)._showErrorMessage;
  _returnTimeoutForErrorMessageShow = () => this.state._timeoutForErrorMessageShow;
  _isHovering = () => this.state._isHovering;

  _shouldDisplayAskNotAllowedForUserMessage = () => this._isError() && this._showErrorMessage();

  _setShowErrorMessage = () => {
    this.setState(() => ({
      _showErrorMessage: true,
    }));
  };

  _clearShowErrorMessage = () => {
    this.setState(() => ({
      _showErrorMessage: false,
    }));
  };

  _setIsHovering = () => {
    this.setState(() => ({
      _isHovering: true,
    }));
  };

  _clearIsHovering = () => {
    this.setState(() => ({
      _isHovering: false,
    }));
  };

  _clearTimeoutForErrorMessageShow = () => {
    const timeout = this._returnTimeoutForErrorMessageShow();
    if (timeout) {
      clearTimeout(timeout);
    }
  };

  _createAndSaveShortTimeoutToHideErrorMessage = () => this._createAndSaveTimeoutToHideErrorMessage(600);
  _createAndSaveTimeoutToHideErrorMessage = (customLength) => {
    const timeout = setTimeout(() => {
      if (!this._isHovering()) {
        this._clearShowErrorMessage();
      }
      this._removeTimeoutFromState();
    }, customLength || 3000);

    this.setState(() => ({
      _timeoutForErrorMessageShow: timeout,
    }));
  };

  _removeTimeoutFromState = () => {
    this.setState(() => ({
      _timeoutForErrorMessageShow: null,
    }));
  };
}

export default UserMessageRecipient;
