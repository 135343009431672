import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import * as Actions from '../../actions/index';

import InputForm from '../UI/InputForm';
import Icon from '../../components/misc/Icon';
import TextAreaForm from '../UI/TextAreaForm';
import { TrackingEvents } from '../../utils/tracking/events';
import { REFERRAL_TYPES } from '../../utils/tracking/events/referral';
import styled from 'styled-components';

const PersonalMessageFormModalWrapper = styled.div`
  .textarea-form-submit {
  }
`;
const Wrapper = styled.div`
  input {
    background: transparent !important;
    border-radius: 5px !important;
    border: 1px solid ${({ theme }) => theme.themeColors.text} !important;
  }
`;

class InviteFriends extends Component {
  constructor() {
    super();
    this.state = {
      _isSendingInvite: false,

      _email: null,
    };
  }

  render() {
    if (this.props.inputOnly) {
      return this._renderInviteForm();
    }
    return (
      <Wrapper className={`invite-friends-container`}>
        <div className={'connection-cell'}>
          {!this.props.hideHeading && <h5>Invite Friends</h5>}
          {this._renderInviteForm()}
        </div>
      </Wrapper>
    );
  }

  _renderInviteForm = () => {
    return this._shouldShowPersonalizeInviteModal() ? (
      <InputForm
        className={'input-form-as-panel-styling'}
        placeholder={this._returnPlaceholder()}
        icon={this._returnInviteFriendIcon()}
        loadingText={'Inviting'}
        handleSubmit={this._handleEmailFormSubmit}
        ignoreAfterSubmitActions
        ignoreLoadingStates
        clearAfterSubmit
      />
    ) : (
      <InputForm
        className={'input-form-as-panel-styling'}
        placeholder={this._returnPlaceholder()}
        icon={this._returnInviteFriendIcon()}
        loadingText={'Inviting'}
        handleSubmit={this._submitInviteWithOnlyEmail}
      />
    );
  };

  _openModalToEnterPersonalMessage = () => {
    this.props.actions.showModal({
      className: 'edit-personal-message-modal',
      contentComponent: this._createPersonalMessageForm(),
      dismissable: true,
      size: 'wide',
      overrideDismiss: this._handlePersonalMessageModalDismiss,
      overrideOverlayDismiss: this._handlePersonalMessageModalDismiss,
    });
  };

  _createPersonalMessageForm = () => {
    return (
      <PersonalMessageFormModalWrapper className={'personal-message-form-modal'}>
        <div className={'page-heading'}>
          <h2>Personalize your invitation</h2>
        </div>
        <TextAreaForm
          defaultValue={this._returnDefaultPersonalInviteMessage()}
          handleSubmit={this._handlePersonalMessageSubmit}
          renderSubmitButton
          autoFocus
        />
      </PersonalMessageFormModalWrapper>
    );
  };

  _handleEmailFormSubmit = (email) => {
    this._setEmail(email);
    this._logEmailInviteFormSubmit();
    this._openModalToEnterPersonalMessage();
  };

  _handlePersonalMessageSubmit = (message) => {
    return this._submitInvite(this._getEmail(), message);
  };

  _handlePersonalMessageModalDismiss = () => {
    this.props.actions.hideModal();
    this._logDismissedPersonalInviteMessageModal();
  };

  _submitInviteWithDefaultMessage = () => {
    return this._submitInvite(this._getEmail(), this._returnDefaultPersonalInviteMessage());
  };

  _submitInviteWithOnlyEmail = (email) => {
    return this._submitInvite(email, null);
  };

  _submitInvite = (email, message) => {
    TrackingEvents.referral.USE_REFERRAL_ACTION.send(REFERRAL_TYPES.email);
    const context = this.props.shareLinkContext;
    return this.props.actions.sendUserInvite(email, message, context).then((response) => {
      const wasSuccess = response && response.data && response.data.status === 'Invite Friend - Invite Send';
      const message =
        response && response.data && response.data.message
          ? response.data.message
          : 'Something went wrong. Please try again later.';
      this.props.actions.showModal({
        contentComponent: this._returnAfterInviteModalComponent(wasSuccess, message),
        dismissable: true,
        size: 'wide',
      });
      if (response && response.data) {
        const wasMessageDefault = message === this._returnDefaultPersonalInviteMessage();
        this._logInvite(response.data.status, response.data.additional_metrics, wasMessageDefault);
      }
      return wasSuccess;
    });
  };

  _returnAfterInviteModalComponent = (wasSuccess, message) => {
    return wasSuccess ? (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        <div className={'invite-sent-container'}>
          <Icon icon={'fa-check-circle'} colorClassName={'success-text-color'} size={'xlarge'} />
          Sent
        </div>
        <div className={'invite-sent-message'}>You can let your friend know that an invite is on the way!</div>
      </div>
    ) : (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {message || 'Something went wrong. Please try again later.'}
      </div>
    );
  };

  _returnInviteFriendIcon = () => ({
    icon: 'fa-paper-plane',
    color: '#2d3e55',
    size: 'x-small',
    customClass: 'rounded-edge-button invite-friends-icon',
    background: '#F7F4F2',
  });

  _returnCurrentUser = () => this.props.currentUser;
  _returnCurrentUserInviteTestGroup = () => 1; // not using an A/B Test for now
  _shouldShowPersonalizeInviteModal = () => this._returnCurrentUserInviteTestGroup() === 1;

  _returnPlaceholder = () => this.props.placeholder || 'Email address to invite';

  _returnDefaultPersonalInviteMessage = () => {
    const { apiCopy } = this.props;
    const defaultMessage = `Join me on ${PRODUCT_DISPLAY_NAME}, the next-generation stock brokerage, and experience the power of investing with technology and collective intelligence.`;
    let message = defaultMessage;
    return apiCopy || defaultMessage;
  };

  _isSendingInvite = () => this.state._isSendingInvite;

  _getEmail = () => this.state._email;
  _setEmail = (email) => {
    this.setState(() => ({
      _email: email,
    }));
  };

  _returnAdditionalLogProperties = () => {
    return {
      ...(this.props.additionalPropertiesForEventLogging || {}),
    };
  };

  _logInvite = (eventName, additionalLogPropertiesFromAPI, wasMessageDefault) => {
    if (this.props.inviteContext) {
      const event = `${eventName}`;
      const properties = {
        Context: this.props.inviteContext,
        ...this._returnAdditionalLogProperties(),
        ...additionalLogPropertiesFromAPI,
        'Default Message': wasMessageDefault,
      };
      this.props.actions.logMetricsTrackingEvent(event, properties);
    } else {
      console.error('Missing inviteContext in InviteFriends for sending email invite');
    }
  };

  _logEmailInviteFormSubmit = () => {
    const event = 'Submitted Invite Email Form';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logDismissedPersonalInviteMessageModal = () => {
    const event = 'Dismissed Personal Invite Message Modal';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    apiCopy: state.application.emailInviteCopy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteFriends);
