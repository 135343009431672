import React from 'react';

const convertToPx = (v) => `${v}px`;
export const Spacing = ({
  name,
  horizontal,
  vertical,
  all,
  top,
  bottom,
  left,
  right,
  style: additionalStyling,
  children,
}) => {
  let style = {};
  if (all) {
    style.paddingLeft = convertToPx(all);
    style.paddingRight = convertToPx(all);
    style.paddingTop = convertToPx(all);
    style.paddingBottom = convertToPx(all);
  }
  if (horizontal) {
    style.paddingLeft = convertToPx(horizontal);
    style.paddingRight = convertToPx(horizontal);
  }
  if (vertical) {
    style.paddingTop = convertToPx(vertical);
    style.paddingBottom = convertToPx(vertical);
  }
  if (top) {
    style.paddingTop = convertToPx(top);
  }
  if (bottom) {
    style.paddingBottom = convertToPx(bottom);
  }
  if (left) {
    style.paddingLeft = convertToPx(left);
  }
  if (right) {
    style.paddingRight = convertToPx(right);
  }

  return (
    <div name={name} style={{ ...style, ...additionalStyling }}>
      {children}
    </div>
  );
};
