import React from 'react';
import { connect } from 'react-redux';

import { Counter } from '../UI/Counter';

import { returnCharsTilFirstWhitespace } from '../../helpers/generalHelpers';

class NotificationsCounter extends React.Component {
  componentDidMount() {
    if (this.props.useToUpdateTitleAndTabIcon) {
      this._handleUpdateIconAndTitleForNotification();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.useToUpdateTitleAndTabIcon) {
      if (this._didNotificationCountChangeFromZero(prevProps)) {
        this._onNewNotificationsCountChangeFromZero();
      }
      if (this._didNotificationCountIncrement(prevProps)) {
        this._onNewNotificationsCountIncrement();
      }
      if (this._didNotificationCountClear(prevProps)) {
        this._onNotificationsCountClear();
      }
    }
  }

  render() {
    return <Counter count={this._returnNewNotificationsCount()} className={this.props.className} />;
  }

  _onNewNotificationsCountChangeFromZero = () => {
    this._updateTabIconToShowNewNotifications();
  };

  _onNewNotificationsCountIncrement = () => {
    this._handleUpdateTabTitle();
  };

  _onNotificationsCountClear = () => {
    this._handleUpdateTabTitle();
    this._updateTabIconToShowNoNotifications();
  };

  _handleUpdateIconAndTitleForNotification = () => {
    this._handleUpdateTabTitle();
    if (this._areNewNotifications()) {
      this._updateTabIconToShowNewNotifications();
    } else {
      this._updateTabIconToShowNoNotifications();
    }
  };

  _handleUpdateTabTitle = () => {
    if (!this._isTitleUpToDate()) {
      this._updateTabTitle(`${this._returnNotificationTitlePrefix()}${this._returnTitleWithoutNotificationCount()}`);
    }
  };

  _updateTabIconToShowNewNotifications = () => {
    // window._updateIconsToShowNotifications();
  };

  _updateTabIconToShowNoNotifications = () => {
    // window._updateIconsToShowNoNotifications();
  };

  _updateTabTitle = (title) => {
    document.title = title;
  };

  _isTitleUpToDate = () => {
    const currentNotificationTitlePrefix = returnCharsTilFirstWhitespace(document.title);
    return currentNotificationTitlePrefix === this._returnNotificationTitlePrefix().split(' ')[0];
  };

  _returnTitleWithoutNotificationCount = () => {
    const title = document.title;
    if (this._isNotificationCountInTitle()) {
      const notificationCountInTitle = title.split(' ')[0];
      return title.split(`${notificationCountInTitle} `)[1];
    } else {
      return title;
    }
  };

  _isNotificationCountInTitle = () => /^[(][\d]/.test(document.title);

  _didNotificationCountChange = (prevProps) =>
    this._returnNewNotificationsCount(prevProps) !== this._returnNewNotificationsCount();

  _didNotificationCountChangeFromZero = (prevProps) =>
    this._returnNewNotificationsCount(prevProps) === 0 && this._returnNewNotificationsCount() > 0;

  _didNotificationCountIncrement = (prevProps) =>
    this._returnNewNotificationsCount(prevProps) < this._returnNewNotificationsCount();

  _didNotificationCountClear = (prevProps) =>
    this._returnNewNotificationsCount(prevProps) !== 0 && this._returnNewNotificationsCount() === 0;

  _areNewNotifications = () => this._returnNewNotificationsCount() > 0;

  _returnNotificationTitlePrefix = () =>
    this._returnNewNotificationsCount() > 0 ? `(${this._returnNotificationCountForDisplay()}) ` : '';

  _returnNotificationCountForDisplay = () =>
    this._returnNewNotificationsCount() > 9 ? '9+' : this._returnNewNotificationsCount();

  _returnNewNotificationsCount = (props) => this._returnNotificationsStore(props).unseenCount;

  _returnNotificationsStore = (props) => (props || this.props).notifications;
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications,
  };
};

export default connect(mapStateToProps)(NotificationsCounter);
