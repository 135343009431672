import React from 'react';
import styled from 'styled-components';
import { toTitleCase } from '../../../../helpers/generalHelpers';
import { Body5 } from '../../../../main/lib/nvstr-common-ui.es';

const Row = styled.tr`
  td {
    height: 76px;
  }
`;
const Wrapper = styled.div`
  padding: 0 0 16px 0;
  table {
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
  }

  tr {
    border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
  }

  td {
    padding: 8px 0;
    ul {
      margin: 0;
      list-style: none;
    }
  }
`;

const UploadDocumentReferenceTable = ({
  requiredDocumentCategoryList,
  documentCategoryToAcceptableDocumentListLookup,
}) => {
  return (
    <Wrapper>
      <table>
        <thead>
          <tr>
            <th style={{ paddingBottom: '4px' }}>
              <Body5 isSmall isLowContrast>
                Verification Requirement
              </Body5>
            </th>
            <th style={{ textAlign: 'right', paddingBottom: '4px' }}>
              <Body5 isSmall isLowContrast>
                Accepted Documents
              </Body5>
            </th>
          </tr>
        </thead>
        <tbody>
          {requiredDocumentCategoryList.map((docCategory) => (
            <Row key={docCategory}>
              <td className="border-accent" style={{ textAlign: 'left' }}>
                <div>{toTitleCase(docCategory)}</div>
                {docCategory === 'address' && (
                  <div className="document-upload-subcategory secondary-text-color">
                    Must show your name and the address on your application
                  </div>
                )}
              </td>
              <td className="border-accent" style={{ textAlign: 'right' }}>
                <ul>
                  {documentCategoryToAcceptableDocumentListLookup[docCategory].map((documentTypeString) => (
                    <li key={documentTypeString}>{documentTypeString}</li>
                  ))}
                </ul>
              </td>
            </Row>
          ))}
        </tbody>
      </table>
    </Wrapper>
  );
};

export default UploadDocumentReferenceTable;
