import React from 'react';
import LoadingIcon from '../../components/misc/LoadingIcon';
import { NewsArticle } from '@src/containers/News/NewNewsArticle';

const NewsList = (props) => {
  const {
    viewingContext,
    scrollContainerSelector,
    newsData,
    isUpdatingNews,
    isLoadingMoreNews,
    securityId,
    isCurrentUserAuthed,
  } = props;

  const _renderNews = () => {
    return newsData.map((newsData, i) => (
      <NewsArticle
        key={`news-article-${securityId}-${i}`}
        securityId={securityId}
        newsData={newsData}
        viewingContext={viewingContext}
        scrollContainerSelector={scrollContainerSelector}
        isCurrentUserAuthed={isCurrentUserAuthed}
      />
    ));
  };

  const _renderIsLoadingMoreNews = () => (
    <div className={`loading-more-news-container`}>
      <LoadingIcon icon="fading-3balls" size="small" style={{ marginRight: '30px' }} />
      <span className={``}>Loading more news...</span>
    </div>
  );

  const _renderIsUpdatingNews = () => (
    <div className={`updating-news-container`}>
      <LoadingIcon icon="fading-3balls" size="small" style={{ marginRight: '30px' }} />
      <span className={``}>Updating news...</span>
    </div>
  );

  return (
    <div className={`news-list-container`} style={{ padding: '4px' }}>
      {isUpdatingNews && _renderIsUpdatingNews()}
      {_renderNews()}
      {isLoadingMoreNews && _renderIsLoadingMoreNews()}
    </div>
  );
};

export default NewsList;
