import {
  getCurrentUserKarmaLabel,
  getCurrentUserKarmaLabelLong,
  getShowKarma,
  getShowKarmaAnimationTypeTestGroup,
  getKarmaShowMaxPointsReachedTestGroup,
} from '../helpers/currentUserHelpers';

import { getCurrentUserFromState } from './currentUser';

export const getKarmaStoreFromState = (state) => state.karma;

export const getShowKarmaFromState = (state) => getShowKarma(getCurrentUserFromState(state));

export const getKarmaTotalFromState = (state) => getKarmaStoreFromState(state).karmaTotal;

export const getKarmaAnimationTestGroupFromState = (state) =>
  getShowKarmaAnimationTypeTestGroup(getCurrentUserFromState(state));

export const getKarmaShowMaxPointsReachedTestGroupFromState = (state) =>
  getKarmaShowMaxPointsReachedTestGroup(getCurrentUserFromState(state));

export const getCurrentUserKarmaLabelFromState = (state) => getCurrentUserKarmaLabel(getCurrentUserFromState(state));

export const getCurrentUserKarmaLabelLongFromState = (state) =>
  getCurrentUserKarmaLabelLong(getCurrentUserFromState(state));

export const getReachedDailyMaxKarmaStateFromState = (state) => getKarmaStoreFromState(state).reachedDailyMax;
