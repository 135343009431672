import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { FlatButton } from '../../buttons';
import { Body5, Body6 } from '../../../lib/nvstr-common-ui.es';
import { colorPalette } from '../../../lib/nvstr-utils.es';
import { Gradient2 } from '../../../../assets/gradients/Gradient2';

const Wrapper = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid ${({ theme }) => theme.themeColors.text};
`;
const Content = styled.div`
  padding: 16px;
  position: relative;
  z-index: 2;
`;
const ButtonWrapper = styled.div``;
const MessageContent = styled.div``;
const ActionsWrapper = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SideBySide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const GradientWrapper = styled.div`
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 72px;
  width: 72px;
  border-radius: 5px;

  margin-right: 16px;
`;
const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const IconWrapper = styled.div`
  position: relative;
  z-index: 2;

  svg {
    height: 28px;
    width: 28px;

    path {
      fill: ${(theme) => colorPalette.primary.charcoal};
    }
  }
`;

const GradientIcon = ({ children }) => {
  return (
    <GradientWrapper>
      <Gradient>
        <Gradient2 />
      </Gradient>
      <IconWrapper>{children}</IconWrapper>
    </GradientWrapper>
  );
};

export const IconSkeletonBannerActionsUnder = ({
  headingText,
  message,
  subMessage,
  ctaButtonText,
  onView,
  onCTAClick,
  onDismiss,
  Icon,
  ContainerComponent,
}) => {
  const theme = React.useContext(ThemeContext);
  const textColor = theme.themeColors.text;
  const ctaBtnTextColor = theme.themeColors.appBackground;

  React.useEffect(() => {
    onView();
  }, []);

  return (
    <ContainerComponent>
      <Wrapper>
        <Content>
          <SideBySide>
            <GradientIcon>{Icon}</GradientIcon>
            <MessageContent>
              {headingText && <Body6 bold>{headingText}</Body6>}
              {message && (
                <div style={{ paddingTop: headingText ? 4 : 0 }}>
                  <Body5 isSmall>{message}</Body5>
                </div>
              )}
              {subMessage && (
                <div style={{ paddingTop: message ? 4 : 0 }}>
                  <Body5 isSmall>{subMessage}</Body5>
                </div>
              )}
            </MessageContent>
          </SideBySide>
          <ActionsWrapper>
            <ButtonWrapper>
              <FlatButton color={ctaBtnTextColor} textColor={textColor} onClick={onCTAClick}>
                {ctaButtonText}
              </FlatButton>
            </ButtonWrapper>
            <ButtonWrapper>
              <FlatButton color={ctaBtnTextColor} textColor={textColor} onClick={onDismiss}>
                Dismiss
              </FlatButton>
            </ButtonWrapper>
          </ActionsWrapper>
        </Content>
      </Wrapper>
    </ContainerComponent>
  );
};
