import { logMetricsTrackingEvent } from '@src/actions';

const logEvents = false;

export const logEvent = (name, properties) => {
  if (logEvents) {
    console.log('sending event tracking log', name, properties);
  }

  logMetricsTrackingEvent(name, properties)();
};

export class TrackingEvent {
  constructor(eventName, defaultProperties = {}) {
    this.eventName = eventName;
    this.defaultProperties = defaultProperties;
  }

  send = (properties = {}) => {
    logEvent(this.eventName, { ...properties, ...this.defaultProperties });
  };
}
