import React from 'react';
import { useSelector } from 'react-redux';

export const useOptimizerStatus = () => {
  const optimizerStore = useSelector((state) => state.optimizer);
  return optimizerStore.isOptimizerRunning;
};

export const useOptimizerData = () => {
  const optimizedPositionLookup = useSelector((state) => state.optimizer.data);

  // detailed_message
  // lastUpdated
  // optimized_cash
  // optimized_positions_by_security_id
  // portfolio_stats
  // // current
  // // // beta
  // // // expected_dividends
  // // // expected_dividends_percentage
  // // // profit_loss
  // // // profit_loss_percentage
  // // // projected_value
  // // // risk
  // // // risk_confidence_max
  // // // risk_confidence_max_percentage
  // // // risk_confidence_min
  // // // risk_confidence_min_percentage
  // // // risk_percentage
  // // // total_value
  // // delta
  // // metrics
  // // optimized
  // // risk_confidence_max_percentile
  // // risk_confidence_min_percentile
  // // risk_confidence_multiplier
  // status: "success"
  // summary_message: "Optimizer is analyzing portfolio"
  return optimizedPositionLookup;
};

export const useHasOptimizerData = () => {
  const data = useOptimizerData();
  return 'portfolio_stats' in data;
};
