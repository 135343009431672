export const riskDisclaimers = [
  {
    text:
      'The source for all historical stock performance is the website of Dr. Aswath Damodaran at NYU Stern School of Business',
    link: 'https://pages.stern.nyu.edu/~adamodar/New_Home_Page/datafile/histretSP.html',
  },
  {
    slides: [1],
    text:
      'From 1928-2020, the largest one-year drop in the S&P 500 Index, including dividends, was 43.84% in 1931, and the largest one-year gain was 52.56% in 1954.',
  },
  {
    slides: [2],
    text: 'The geometric mean annual return of the S&P 500 Index from 1928-2020, including dividends, was 9.8%.',
  },
  {
    slides: [3, 4, 5],
    text:
      'There is no guarantee that a portfolio that targets high or low risk, or high or low returns, will achieve these objectives.  Past performance does not indicate future results.  Investing in stocks is inherently risky, and no investing strategy can completely predict or eliminate these risks.',
  },
];

export const ivsDisclaimers = [
  {
    text:
      'The source for all historical stock performance is the website of Dr. Aswath Damodaran at NYU Stern School of Business',
    link: 'https://pages.stern.nyu.edu/~adamodar/New_Home_Page/datafile/histretSP.html',
  },
  {
    text: 'The source for inflation data is US Bureau of Labor Statistics: ',
    link: 'https://data.bls.gov/cgi-bin/surveymost',
  },
  {
    text: 'The source for historical bank interest rates (CDs) is Bankrate:',
    link: 'https://www.bankrate.com/banking/cds/historical-cd-interest-rates/',
  },
  {
    slides: [1],
    text:
      'The average rate of inflation from 2009-2020 was 1.33% and the average interest rate on a bank savings account was 0.24%.',
  },
  {
    slides: [1],
    text: 'The source for inflation statistics is the BLS:',
    link: 'https://www.bls.gov/bls/inflation.htm',
  },
  {
    slides: [1],
    text: 'The source for bank savings account interest rates is the St. Louis Fed:',
    link: 'https://fred.stlouisfed.org/series/CD6NRNJ',
  },
  {
    slides: [1],
    text: 'Based on data from the BLS, since 2009, the average annual inflation rate was about 1.34%',
  },
  {
    slides: [1],
    text:
      'According to data from the Fed, since 2009, the average annual interest rate on savings deposit accounts was about 24 bps',
  },
  {
    slides: [2],
    text: 'The geometric mean annual return of the S&P 500 Index from 1928-2020, including dividends, was 9.8%.',
  },
  {
    slides: [3, 4],
    text:
      'Past performance of the stock market is not indicative of future results.  There is no guarantee that a portfolio of stocks will go up in value, appreciate more than inflation, or exhibit high or low volatility during any given period.  Investing in stocks is inherently risky, and no investing strategy can completely predict or eliminate these risks.',
  },
];

export const diversificationDisclaimers = [
  {
    text:
      'There is no guarantee that a diversified portfolio will perform better or similar to, or exhibit volatility similar to or lower than, a concentrated portfolio over any given period.',
  },
  {
    text:
      'Fisher and Lorie wrote a paper published in 1970 that suggested a 30-stock portfolio provides significant diversification:',
    link: 'https://www.jstor.org/stable/2352105?seq=1#metadata_info_tab_contents',
  },
  {
    text:
      'Reilly and Brown (first edition published in 1979) suggest 12-18 stocks could provide 90% of the benefits of diversification:',
    link: 'https://www.cengage.com/c/investment-analysis-and-portfolio-management-11e-reilly/9781305262997',
  },
  {
    text:
      'Prof. Haran Segram of NYU says 20-25 stocks are required to have a diversified portfolio, and holdings beyond that may offer only marginal diversification benefits:',
    link: 'https://money.com/diversification-how-many-stocks/',
  },
  {
    text:
      'Fund manager Joel Greenblatt in his book You Can Be a Stock Market Genius claims that 93% of stock-specific risk is eliminated with a portfolio of 16 stocks, and 96% with 32 stocks:',
    link: 'https://www.simonandschuster.com/books/You-Can-Be-a-Stock-Market-Genius/Joel-Greenblatt/9780684840079',
  },
];

export const compoundingDisclaimers = [
  {
    text:
      'The source for all historical stock performance is the website of Dr. Aswath Damodaran at NYU Stern School of Business: ',
    link: 'https://pages.stern.nyu.edu/~adamodar/New_Home_Page/datafile/histretSP.html',
  },
  {
    slides: [3, 4, 5],
    text:
      'There is no guarantee that a portfolio that targets high or low risk, or high or low returns, will achieve these objectives.  Past performance does not indicate future results.  Investing in stocks is inherently risky, and no investing strategy can completely predict or eliminate these risks.',
    link: 'https://www.investor.gov/financial-tools-calculators/calculators/compound-interest-calculator',
  },
  {
    slides: [3, 4, 5],
    text:
      'Past performance does not indicate future results.  Investing in stocks is inherently risky, and no investing strategy can completely predict or eliminate these risks.',
  },
];

export const dividendDisclaimers = [
  {
    text:
      'Tornado does not recommend any specific investment strategy or investing in stocks with any specific characteristics such as paying dividends.  Educational material and quotes are for informational purposes only and are not recommendations, investment advice, or solicitations to buy or sell securities.  Quotes may not represent the views of Nvstr Financial LLC dba Tornado (“Tornado”) or its affiliates.',
  },
  {
    text:
      'The source for all historical stock performance is the website of Dr. Aswath Damodaran at NYU Stern School of Business:',
    link: 'https://pages.stern.nyu.edu/~adamodar/New_Home_Page/datafile/histretSP.html',
  },
  {
    slides: [1],
    text: 'The source for the claim that ⅓ of the total equity return for the S&P500 is from dividends is S&P Global:',
    link: 'https://www.spglobal.com/spdji/en/documents/education/practice-essentials-the-importance-of-dividends.pdf',
  },
];

export const optimizationDisclaimers = [
  {
    text:
      'There is no guarantee that an optimized portfolio will exhibit higher returns, lower volatility, or other improved characteristics relative to a portfolio that has not been optimized.  Please see Section 8 of Tornado’s Terms of Service (link) for important information and disclosures regarding optimization and the Optimizer tool.',
  },
  {
    slides: [4],
    text:
      ' Tornado’s Optimizer is based in part on the concept of Modern Portfolio Theory, which was pioneered by the economist Harry Markowitz in 1952 and for which he was awarded a Nobel Prize in Economics.',
  },
  {
    slides: [5],
    text:
      'One study in the Journal of Portfolio Management suggests that there could be as much as 2% annual out-performance vs. the market just from periodically adjusting position weightings to minimize portfolio volatility. Past performance does not indicate future results.  Investing in stocks is inherently risky, and no investing strategy can completely predict or eliminate these risks.',
    link: 'https://www.researchgate.net/publication/228318906_Minimum_Variance_Portfolio_Composition',
  },
];

export const dollarCostDisclaimers = [
  {
    text:
      'There is no guarantee that dollar cost averaging, or any other investing strategy, will be profitable, outperform an index, or avoid losses that could be associated with a different investing strategy.  Past performance does not indicate future results.  Investing in stocks is inherently risky, and no investing strategy can completely predict or eliminate these risks.',
  },
  {
    slides: [4],
    text: 'Quotes may not represent the views of Nvstr Financial LLC dba Tornado (“Tornado”) or its affiliates.',
  },
];

export const concentrationDisclaimers = [
  {
    text:
      'Quotes may not represent the views of Nvstr Financial LLC dba Tornado (“Tornado”) or its affiliates.  There is no guarantee that a diversified portfolio will perform better or similar to, or exhibit volatility similar to or lower than, a concentrated portfolio over any given period.',
  },
  {
    text:
      'Fisher and Lorie wrote a paper published in 1970 that suggested a 30-stock portfolio provides significant diversification:',
    link: 'https://www.jstor.org/stable/2352105?seq=1#metadata_info_tab_contents',
  },
  {
    text:
      'Reilly and Brown (first edition published in 1979) suggest 12-18 stocks could provide 90% of the benefits of diversification:',
    link: 'https://www.cengage.com/c/investment-analysis-and-portfolio-management-11e-reilly/9781305262997',
  },
  {
    text:
      'Prof. Haran Segram of NYU says 20-25 stocks are required to have a diversified portfolio, and holdings beyond that may offer only marginal diversification benefits:',
    link: 'https://money.com/diversification-how-many-stocks/',
  },
  {
    text:
      'Fund manager Joel Greenblatt in his book You Can Be a Stock Market Genius claims that 93% of stock-specific risk is eliminated with a portfolio of 16 stocks, and 96% with 32 stocks:',
    link: 'https://www.simonandschuster.com/books/You-Can-Be-a-Stock-Market-Genius/Joel-Greenblatt/9780684840079',
  },
];

export const etfsDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];

export const valuationDisclaimers = [
  {
    text:
      'Quotes may not represent the views of Nvstr Financial LLC dba Tornado (“Tornado”) or its affiliates.  Tornado does not recommend any specific stocks, ETFs, or investment strategies, such as buying “value” stocks.  Not investment advice.',
  },
];

export const earningsReportsDisclaimers = [
  {
    text:
      'The source for all historical stock performance is the website of Dr. Aswath Damodaran at NYU Stern School of Business',
    link: 'https://pages.stern.nyu.edu/~adamodar/New_Home_Page/datafile/histretSP.html',
  },
];

export const orderTypesDisclaimers = [
  {
    text:
      'There is no guarantee that any order will be filled successfully.  For educational purposes only.  Not investment advice.',
  },
];

export const fractionalDisclaimers = [
  {
    text:
      'Some securities available on Tornado are not eligible for fractional share trading.  Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];

export const gettingStarted12Disclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];

export const whenToSellDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];

export const businessRiskDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/how-risky-is-my-stock-start-with-the-business-d41166383ae0',
    blogPostName: 'How risky is my stock? Start with the business.',
  },
];

export const ratiosDisclaimers = [
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies. Stocks with high or low multiples may perform better or worse than other stocks. Not investment advice.',
  },
];

export const communityDisclaimers = [
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies. Stocks mentioned by members of the Tornado community may perform better or worse than other stocks. Not investment advice.',
  },
];

export const marketCapitalizationDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies. Not investment advice.',
  },
];

export const insiderTransactionsDisclaimers = [
  {
    blogPostName: 'Insider Buying: Pay Attention',
    blogPost: 'https://blog.tornado.com/insider-buying-pay-attention-6d3ee3a7a08b',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Stocks bought or sold by insiders may perform better or worse than other stocks.  Quotes may not represent the views of Nvstr Financial LLC dba Tornado (“Tornado”) or its affiliates.  Not investment advice.',
  },
];

export const tamDisclaimers = [
  {
    blogPostName: 'What is TAM and why is it critical to understanding stocks?',
    blogPost: 'https://blog.tornado.com/what-is-tam-and-why-is-it-critical-to-understanding-stocks-39c44384b436',
  },
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies. Not investment advice.',
  },
];

export const growthStocksDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/growth-value-and-cyclical-stocks-deexport constructing-the-market-e08b31915442',
    blogPostName: 'Growth value and cyclical stocks deexport constructing the market',
  },
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];

export const epsDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/what-is-eps-and-how-can-i-use-it-to-understand-stocks-1d08d9aa54ef',
    blogPostName: 'What is EPS and how can i use it to understand stocks?',
  },
  {
    blogPost: 'https://blog.tornado.com/what-are-consensus-estimates-and-why-are-they-important-348f89b2ecf7',
    blogPostName: 'What are consensus estimates and why are they important?',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  EPS is just one of many factors that may affect stock prices.  Not investment advice.',
  },
];

export const retailDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];

export const consensusEstimatesDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/what-are-consensus-estimates-and-why-are-they-important-348f89b2ecf7',
    blogPostName: 'What are consensus estimates and why are they important?',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Consensus estimates are predictions that may vary widely from realized results.  Stock prices of companies that outperform or underperform estimates may go up or down.  Consensus estimates are just one of many factors to consider when forming an opinion about a stock.  Not investment advice.',
  },
];

export const marketIndicesDisclaimers = [
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  It is not possible to invest directly in an index.  Past performance does not guarantee future results.',
  },
  {
    text: 'Check out the full article: ',
    link: 'https://www.nasdaq.com/education/dow-nasdaq-sp-500%3A-what-does-it-all-mean',
  },
  {
    text: "Check out Nasdaq's Stock Screener",
    link: 'https://www.nasdaq.com/market-activity/stocks/screener',
  },
];

export const stocksMarketsIndexesDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
  {
    text: 'Check out the full article: ',
    link: 'https://www.nasdaq.com/education/the-stock-market%3A-where-buyers-and-sellers-meet',
  },
];

export const esgDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/esg-how-to-include-social-responsibility-in-your-portfolio-65359dba704',
    blogPostName: 'ESG - How to include social responsibility in your portfolio',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Stocks with high or low ESG scores may perform better or worse than other stocks.  ESG scores are just one of many factors that may affect stock prices.  Not investment advice.',
  },
];

export const valueStocksDisclaimers = [
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Value stocks may perform better or worse than other stocks.  Valuation metrics are just one of many factors that may affect stock prices.  Not investment advice.',
  },
  {
    text: 'Read our blog post for more information: ',
    link: 'https://blog.tornado.com/growth-value-and-cyclical-stocks-deexport constructing-the-market-e08b31915442',
  },
];

export const lateralsDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/laterals-what-ifs-and-the-importance-of-ripple-effects-7de4c493053c',
    blogPostName: 'Laterals - "What ifs," and the importance of ripple effects',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Laterals are just one of many factors that may affect stock prices.  Past performance of laterals analysis does not guarantee future results.  Not investment advice.',
  },
];

export const depositDisclaimers = [
  {
    text:
      'Past performance of the stock market is not indicative of future results.  There is no guarantee that a portfolio of stocks will go up in value, appreciate more than inflation, or exhibit high or low volatility during any given period.  Investing in stocks is inherently risky, and no investing strategy can completely predict or eliminate these risks.',
  },
];

export const investingVsTradingDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/traders-vs-investors-fdb3c616ff7a',
    blogPostName: 'Traders vs. Investors',
  },
  {
    text: 'Article on excessive trading in the NY Times.',
    link:
      'https://www.nytimes.com/2015/10/03/your-money/a-tax-to-curb-excessive-trading-could-be-a-boon-to-returns.html',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  Long-term investing strategies may perform better or worse than short-term trading strategies.  Past performance does not guarantee future results.',
  },
];

export const sp500Disclaimers = [
  {
    blogPost: 'https://blog.tornado.com/whats-the-s-p-500-and-what-can-i-learn-from-it-71e99a6c5922',
    blogPostName: "What's the S&P500, and what can I learn from it?",
  },
  {
    text: 'Check out the full fact sheet: ',
    link:
      'https://www.spglobal.com/spdji/en/idsenhancedfactsheet/file.pdf?calcFrequency=M&force_download=true&hostIdentifier=48190c8c-42c4-46af-8d1a-0cd5db894797&indexId=340',
  },
  {
    text: 'See more on the S&P500 here: ',
    link: 'https://www.spglobal.com/spdji/en/indices/equity/sp-500/#overview',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  It is not possible to invest directly in an index.  ETF performance may diverge from that of the underlying index.  Past performance does not guarantee future results.',
  },
];

export const investmentGoalsDisclaimers = [
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Stocks that pay dividends may perform better or worse than other stocks. Past performance does not guarantee future results.  Not investment advice.',
  },
];

export const marketTimingDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/confused-by-market-swings-consider-dollar-cost-averaging-4b2ffe0fffda',
    blogPostName: 'Confused by Market Swings? Consider Dollar Cost Averaging',
  },
  {
    text:
      'Bill Miller discussed market timing, including the claim that the US stock market has historically increased in value in 70% of calendar years since World War II, in a recent letter:',
    link: 'https://millervalue.com/bill-miller-3q-2021-market-letter/',
  },
  {
    text:
      'Capital Group conducted additional studies of hypothetical market timing vs. long-term investing strategies:',
    link:
      'https://www.capitalgroup.com/individual/planning/investing-fundamentals/time-not-timing-is-what-matters.html',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  Long-term investing strategies may perform better or worse than short-term trading strategies.  Past performance does not guarantee future results.',
  },
];

export const lifeInsuranceDisclaimers = [
  {
    text: 'Check out our blog: ',
    link: 'https://tornado.com/blog',
  },
];

export const bestowLifeInsuranceDisclaimers = [
  {
    text: 'Check out our blog: ',
    link: 'https://tornado.com/blog',
  },
];

export const additionalLifeInsuranceCoverage = [
  {
    text: 'Check out our blog: ',
    link: 'https://tornado.com/blog',
  },
];

export const youNeedABudgetDisclaimers = [
  {
    text: 'Check out our blog: ',
    link: 'https://tornado.com/blog',
  },
];

export const accreditedDebtReliefDisclaimers = [
  {
    text: 'Check out our blog: ',
    link: 'https://tornado.com/blog',
  },
];
export const morningBrewDisclaimers = [
  {
    text: 'Check out our blog: ',
    link: 'https://tornado.com/blog',
  },
];
export const edXDisclaimers = [
  {
    text: 'Check out our blog: ',
    link: 'https://tornado.com/blog',
  },
];
export const trustAndWillDisclaimers = [
  {
    text: 'Check out our blog: ',
    link: 'https://tornado.com/blog',
  },
];

export const stocksAndWealthDisclaimers = [
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  Past performance does not guarantee future results.',
    // link: '',
  },
];

export const comparingStocksDisclaimers = [
  {
    text: 'Read more on the Global Industry Classification Standard (GICS) here: ',
    link: 'https://www.investopedia.com/terms/g/gics.asp',
  },
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];

export const bigTechDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
    // link: '',
  },
];

export const interestRatesDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
    // link: '',
  },
];

export const marginsDisclaimers = [
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  Margins are just one of many factors that may affect stock prices.  ',
    // link: '',
  },
];

export const valuationMultiplesDisclaimers = [
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  Valuation multiples are just one of many factors that may affect stock prices.  Past performance does not guarantee future results.',
    // link: '',
  },
];

export const esgInvestingDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/esg-how-to-include-social-responsibility-in-your-portfolio-65359dba704',
    blogPostName: 'ESG - How to include social responsibility in your portfolio',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  ESG scores are just one of many factors that may affect stock prices.',
    link: '',
  },
  {
    text:
      'Deloitte projects that half of all professionally managed assets in the US could have an ESG mandate by 2025: ',
    link: 'https://www2.deloitte.com/us/en/insights/industry/financial-services/esg-investing-performance.html',
  },
];

export const esgRatingsDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/esg-how-to-include-social-responsibility-in-your-portfolio-65359dba704',
    blogPostName: 'ESG - How to include social responsibility in your portfolio',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  ESG scores are just one of many factors that may affect stock prices.',
  },
];

export const informationTechnologyDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
  {
    text: 'Information about the information technology sector and S&P indices is available here: ',
    link: 'https://www.spglobal.com/spdji/en/indices/equity/sp-500-information-technology-sector',
  },
  {
    text: 'Data on corporate IT spending is sourced from Gartner: ',
    link:
      'https://www.gartner.com/en/newsroom/press-releases/2022-01-18-gartner-forecasts-worldwide-it-spending-to-grow-five-point-1-percent-in-2022',
  },
];

export const healthcareDisclaimers = [
  {
    text: 'Check out more info on S&P500 - Health Care Sector',
    link: ' https://www.spglobal.com/spdji/en/indices/equity/sp-500-health-care-sector',
  },
  {
    text: 'Check out more info on XLV - Health Care Select Sector SPDR Fund',
    link: 'https://www.ssga.com/us/en/intermediary/etfs/funds/the-health-care-select-sector-spdr-fund-xlv',
  },
  {
    text: 'Check out the fact sheet here: ',
    link:
      'https://www.spglobal.com/spdji/en/idsenhancedfactsheet/file.pdf?calcFrequency=M&force_download=true&hostIdentifier=48190c8c-42c4-46af-8d1a-0cd5db894797&indexId=340',
  },
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice. ',
  },
];

export const consumerDiscretionaryDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice. ',
  },
  {
    text: 'Information about the consumer discretionary sector and S&P indices is available here: ',
    link: 'https://www.spglobal.com/spdji/en/indices/equity/sp-500-consumer-discretionary-sector',
  },
  {
    text: 'Check out the full fact sheet here: ',
    link:
      'https://www.spglobal.com/spdji/en/idsenhancedfactsheet/file.pdf?calcFrequency=M&force_download=true&hostIdentifier=48190c8c-42c4-46af-8d1a-0cd5db894797&indexId=340',
  },
  {
    text: 'See more on S&P 500 Consumer Discretionary Sector here: ',
    link: 'https://www.spglobal.com/spdji/en/indices/equity/sp-500-consumer-discretionary-sector',
  },
  {
    text: 'See more on XLY - Consumer Discretionary Select Sector SPDR Fund here: ',
    link: 'https://www.ssga.com/us/en/intermediary/etfs/funds/the-consumer-discretionary-select-sector-spdr-fund-xly',
  },
];

export const famousFemaleInvestorsDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];

export const howMuchToInvestDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];

export const choosingYourFirstInvestmentDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];

export const portfoliosDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];

export const marketDownturnsDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];

export const pricingPowerDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];

export const tenKDisclaimers = [
  {
    text: 'More information about 10-Ks is available from the SEC here: ',
    link: 'https://www.investor.gov/introduction-investing/getting-started/researching-investments/how-read-10-k',
  },
  {
    text: 'Warren Buffet quote source: ',
    link: 'https://www.cnbc.com/2018/03/27/warren-buffetts-key-tip-for-success-read-500-pages-a-day.html',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice. Quotes do not necessarily represent the views of Nvstr Financial LLC dba Tornado (“Tornado”) or its affiliates.',
  },
];

export const guidanceDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/what-is-eps-and-how-can-i-use-it-to-understand-stocks-1d08d9aa54ef',
    blogPostName: 'What is EPS and How Can I Use it to Understand Stocks?',
  },
  {
    blogPost: 'https://blog.tornado.com/what-are-consensus-estimates-and-why-are-they-important-348f89b2ecf7',
    blogPostName: 'What are Consensus Estimates and Why are they Important?',
  },
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice. ',
  },
];

export const ROIDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/what-makes-a-good-business-warren-buffett-on-return-on-capital-8763b0a1a858',
    blogPostName: 'What Makes a Good Business? Warren Buffett on Return on Capital',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  ROI and other profitability measures are just one of many factors that may affect stock prices.',
  },
];

export const earningsCallsDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/7-ways-to-unlock-value-from-earnings-calls-e67e6c52b27b',
    blogPostName: '7 Ways to Unlock Value from Earnings Calls',
  },
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice. ',
  },
];

export const QQQDisclaimers = [
  {
    text: 'More information about this topic is available here:',
    link: 'https://www.invesco.com/us/financial-products/etfs/product-detail?productId=QQQ',
  },
  {
    text:
      'Tornado does not recommend QQQ or any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];

export const buyingStockDisclaimers = [
  {
    text:
      'Stock investing may not be appropriate for all investors.  Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice. ',
  },
];

export const portfolioOptimizationDisclaimers = [
  {
    text:
      'The projections or other information generated by the Optimizer function regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual investment results and are not guarantees of future results. Information outputted from the Optimizer is not, and is not to be export construed as, a recommendation.',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  Please see Tornado’s Terms of Service for more information.',
  },
  {
    text: 'Terms of Service',
    link: 'https://www.tornado.com/tos',
  },
];

export const yourStockWatchlistDisclaimers = [
  {
    text:
      'Stock investing may not be appropriate for all investors.  Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice. ',
  },
];

export const prosConsDisclaimers = [
  {
    text:
      'Pros and cons are not recommendations of any specific stocks, ETFs, or investment strategies.  Not investment advice. ',
  },
];

export const inAppAudioDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice. ',
  },
];

export const newConstructsDisclaimers = [
  {
    text:
      'Tornado does not recommend any specific investment strategy.  Educational material and quotes are for informational purposes only and are not recommendations, investment advice, or solicitations to buy or sell securities.  Quotes may not represent the views of Nvstr Financial LLC dba Tornado (“Tornado”) or its affiliates.',
  },
];

export const upstartDisclaimers = [
  {
    text:
      'Tornado does not recommend any specific investment strategy.  Educational material and quotes are for informational purposes only and are not recommendations, investment advice, or solicitations to buy or sell securities.  Quotes may not represent the views of Nvstr Financial LLC dba Tornado (“Tornado”) or its affiliates.',
  },
];

export const galatDisclaimers = [
  {
    text:
      'Stock investing may not be appropriate for all investors. Tornado does not recommend any specific stocks, ETFs, or investment strategies, or provide investment advice. Wealth Foundry is not affiliated with Tornado or its parent company. ',
  },
];

export const womenLedDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
  {
    text: '',
    link: 'https://cabotwealth.com/daily/growth-stocks/public-companies-run-by-women-homage-ginsburg/',
  },
  {
    text: '',
    link: 'https://www.cvshealth.com/news-and-insights/press-releases/meet-our-new-ceo-karen-s-lynch',
  },
  {
    text: '',
    link: 'https://hbr.org/2022/04/hershey-ceo-michele-buck-on-empowering-internal-change-agents',
  },
  {
    text: '',
    link: 'https://www.citigroup.com/citi/about/leaders/jane-fraser-bio.html',
  },
  {
    text: '',
    link:
      'https://www.usatoday.com/story/news/2019/02/04/amazon-names-starbucks-exec-rosalind-brewer-first-black-board-member/2769779002/',
  },
];

export const tornadoValuesDisclaimers = [
  {
    text:
      'Stock investing may not be appropriate for all investors. Tornado does not recommend any specific stocks, ETFs, or investment strategies. Not investment advice.',
  },
];

export const exclusiveTornadoPerksDisclaimers = [
  {
    text:
      'There is no guarantee that an optimized portfolio will exhibit higher returns, lower volatility, or other improved characteristics relative to a portfolio that has not been optimized.  Please see Tornado’s Terms of Service for important information and disclosures regarding optimization and the Optimizer tool.',
  },
  {
    text:
      'Tornado’s Optimizer is based in part on the concept of Modern Portfolio Theory, which was pioneered by the economist Harry Markowitz in 1952 and for which he was awarded a Nobel Prize in Economics.',
  },
  {
    text: 'Please read the Learn & Earn program rules for important information and disclosures.',
  },
  {
    text: 'Terms of Service',
    link: 'https://www.tornado.com/tos',
  },
  {
    text: 'Learn & Earn Program Rules',
    link: 'https://www.tornado.com/learn-and-earn-rules',
  },
];
export const habitsOfSuccessFulInvestorsDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies. Not investment advice.',
  },
];
export const howWeSupportYourInvestingJourneyDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
  {
    text: 'Please read the Learn & Earn program rules for important information and disclosures.',
  },
  {
    text: 'Learn & Earn Program Rules',
    link: 'https://www.tornado.com/learn-and-earn-rules',
  },
];
export const meetCTLsDisclaimers = [
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  CTL content is not a recommendation or investment advice.',
  },
];
export const amazonDisclaimers = [
  {
    text:
      'Tornado does not recommend AMZN or any other specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
  {
    text: 'Sources:',
  },
  {
    text: '',
    link: 'https://s2.q4cdn.com/299287126/files/doc_financials/2021/q4/f965e5c3-fded-45d3-bbdb-f750f156dcc9.pdf ',
  },
  {
    text: '',
    link: 'https://www.sec.gov/ix?doc=/Archives/edgar/data/1018724/000101872422000005/amzn-20211231.htm',
  },
];
export const lossesDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
  {
    text: 'Source: ',
    link: 'https://www.forbes.com/advisor/investing/stock-market-correction/',
  },
];
export const whatYouCanLearnFromBuyingAStockDisclaimers = [
  {
    text:
      'Stock investing may not be appropriate for all investors.  Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];
export const moatsDisclaimers = [
  {
    text:
      'Quotes are for educational purposes only and may not represent the views of Nvstr Financial LLC dba Tornado (“Tornado”) or its affiliates. Tornado does not recommend any specific stocks, ETFs, or investment strategies. Not investment advice.',
  },
  {
    text: 'Source: ',
    link: 'https://blog.tornado.com/what-makes-a-good-business-warren-buffett-on-apple-inc-b6039e5b04ae',
  },
];
export const appleDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/what-makes-a-good-business-warren-buffett-on-apple-inc-b6039e5b04ae ',
    blogPostName: 'What Makes a Good Business? Warren Buffett on Apple Inc.',
  },
  {
    text:
      'Tornado does not recommend AAPL or any other specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];
export const themes2022Disclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];
export const pceIndexDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/how-to-assess-inflation-part-ii-pce-439fb02a845b',
    blogPostName: 'How to Assess Inflation: Part II, PCE',
  },
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice. ',
  },
  {
    text: 'CPI and PCE data sources:',
    link: 'https://www.bls.gov/cpi/',
  },
  {
    text: '',
    link: 'https://www.bea.gov/data/personal-consumption-expenditures-price-index',
  },
];
export const googleDisclaimers = [
  {
    text:
      'Tornado does not recommend GOOG, GOOGL or any specific stocks, ETFs, or investment strategies.  Not investment advice. ',
  },
  {
    text: 'Alphabet revenue data source (Alphabet 2021 10-K report):',
    link: 'https://www.sec.gov/ix?doc=/Archives/edgar/data/1652044/000165204422000019/goog-20211231.html',
  },
];
export const cpiIndexDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice. ',
  },
  {
    text: 'Source for CPI data:',
    link: 'https://www.bls.gov/news.release/archives/cpi_03102022.htm',
  },
];
export const inflationDisclaimers = [
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  Views expressed by Warren Buffett may not reflect the views of Nvstr Financial LLC dba Tornado or its affiliates.',
  },
  {
    text: 'Source for CPI inflation data:',
    link: 'https://www.bls.gov/data/',
  },
  {
    text: 'Source for PCE inflation data:',
    link: 'https://www.bea.gov/data/personal-consumption-expenditures-price-index',
  },
  {
    text: 'Source for views of Warren Buffett:',
    link: 'https://www.cnbc.com/2018/02/12/warren-buffett-explains-how-to-invest-in-stocks-when-inflation-rises.html',
  },
];
export const competitionDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/laterals-what-ifs-and-the-importance-of-ripple-effects-7de4c493053c',
    blogPostName: 'Laterals, “What Ifs,” and the Importance of Ripple Effects',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice. Competitor performance is just one of many factors that may affect stock prices.',
  },
];
export const capitalIntensityDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/how-risky-is-my-stock-start-with-the-business-d41166383ae0 ',
    blogPostName: 'How Risky is My Stock? Start with the Business.',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice. Capital intensity is just one of many factors that may affect stock prices. Views expressed by Warren Buffett and Charlie Munger may not reflect the views of Nvstr Financial LLC dba Tornado or its affiliates.',
  },
  {
    text: 'More information on business risk can be found on Tornado’s blog here:',
    link: 'https://blog.tornado.com/how-risky-is-my-stock-start-with-the-business-d41166383ae0',
  },
  {
    text: 'Source of data for Boeing (2021 10-K report): ',
    link: 'https://www.sec.gov/ix?doc=/Archives/edgar/data/12927/000001292722000010/ba-20211231.htm',
  },
  {
    text: 'Source of data for Google (2021 10-K report): ',
    link: 'https://www.sec.gov/ix?doc=/Archives/edgar/data/1652044/000165204422000019/goog-20211231.htm',
  },
  {
    text: 'More information on capital intensity can be found here:',
    link: 'https://www.investopedia.com/terms/c/capitalintensive.asp',
  },
];
export const investInFutureDisclaimers = [
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  Views expressed by Stanley Druckenmiller may not reflect the views of Nvstr Financial LLC dba Tornado or its affiliates.',
  },
  {
    text: 'Source for Stanley Druckenmiller quote:',
    link:
      'https://finance.yahoo.com/news/stanley-druckenmillers-no-1-piece-of-advice-for-novice-investors-173232751.html',
  },
  {
    text: 'More information about stock price discounting can be found here:',
    link: 'https://www.investopedia.com/terms/d/discounting-mechanism.asp',
  },
];
export const banksDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
  {
    text: 'More information on sources of bank income can be found here:',
    link:
      'https://www.clevelandfed.org/publications/economic-commentary/2019/ec-201914-trends-in-the-noninterest-income-of-banks',
  },
];
export const financialSectorDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies. Not investment advice.',
  },
  {
    text: 'More information about financials in the S&P 500 can be found here:',
    link: 'https://www.spglobal.com/spdji/en/indices/equity/sp-500-financials-sector',
  },
  {
    text: '',
    link:
      'https://www.spglobal.com/spdji/en/idsenhancedfactsheet/file.pdf?calcFrequency=M&force_download=true&hostIdentifier=48190c8c-42c4-46af-8d1a-0cd5db894797&indexId=340 ',
  },
  {
    text: 'Information about the S&P 500 financials ETF (XLF) can be found here:',
    link: 'https://www.ssga.com/us/en/intermediary/etfs/funds/the-financial-select-sector-spdr-fund-xlf',
  },
];
export const stocksNotTheEconomyDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/the-stock-market-is-not-the-economy-4ea9b349aaf6',
    blogPostName: 'The Stock Market is Not the Economy',
  },
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies. Not investment advice.',
  },
  {
    text: 'Source for GDP data:',
    link: 'https://www.bea.gov/news/2022/gross-domestic-product-third-quarter-2022-advance-estimate',
  },
  {
    text: 'Source for company revenue data:',
    link: 'https://www.yardeni.com/pub/stmktbriefrev.pdf',
  },
];
export const communicationsServicesSectorDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies. Not investment advice.',
  },
  {
    text: 'More information about communications companies in the S&P 500 can be found here: ',
    link: 'https://www.spglobal.com/spdji/en/indices/equity/sp-500-communication-services-sector ',
  },
  {
    link:
      'https://www.spglobal.com/spdji/en/idsenhancedfactsheet/file.pdf?calcFrequency=M&force_download=true&hostIdentifier=48190c8c-42c4-46af-8d1a-0cd5db894797&indexId=340',
  },
  {
    text: 'Information about the S&P 500 Communications ETF can be found here:',
    link: 'https://www.ssga.com/us/en/intermediary/etfs/funds/the-communication-services-select-sector-spdr-fund-xlc ',
  },
  {
    text: 'More information about this sector can be found here:',
    link:
      'https://www.baronfunds.com/sites/default/files/Baron%20Insight%20-%20An%20Overview%20of%20the%20New%20Communication%20Services%20GICS%20Sector_0.pdf',
  },
  {
    link:
      'https://www.cnbc.com/2022/11/03/charter-ceo-tom-rutlege-says-pain-to-come-as-tv-gives-way-to-streaming.html ',
  },
  {
    link: 'https://www.lightreading.com/5g/wireless-customer-growth-to-remain-strong-in-q3---analysts/d/d-id/780959',
  },
];
export const ebitaDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/what-is-ebitda-and-how-can-i-use-it-d96d7792a209',
    blogPostName: 'What is EBITDA, and How Can I Use It?',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice. EBITDA is just one of many factors that may affect stock prices. ',
  },
];
export const rule72Disclaimers = [
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice. Past performance does not guarantee future results.  Views expressed by Peter Lynch may not reflect the views of Nvstr Financial LLC dba Tornado or its affiliates.',
  },
  {
    text: 'Source for Peter Lynch quote:',
    link: 'https://libquotes.com/peter-lynch/quote/lbd5n9m',
  },
];
export const bankRateDisclaimers = [
  {
    text:
      'This site is part of an affiliate sales network and receives compensation for sending traffic to partner sites, such as CreditCards.com. This compensation may impact how and where links appear on this site. This site does not include all financial companies or all available financial offers.',
  },
  {
    text:
      'Going to CardMatch and applying to CardMatch will not earn you anything on Tornado, including cash for investing or XP.',
  },
];
export const coveredDisclaimers = [
  {
    text: '',
  },
];
export const booksForInvestorsDisclaimers = [
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  Opinions expressed in these books do not necessarily reflect the views of Nvstr Financial LLC dba Tornado or its affiliates.',
  },
];
export const shareClassesDisclaimers = [
  {
    text: '',
    link: 'https://www.sec.gov/ix?doc=/Archives/edgar/data/1652044/000165204422000019/goog-20211231.htm ',
  },
  {
    text:
      'Tornado does not recommend GOOG, GOOGL, or any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];
export const risksOfLeveragedETFsDisclaimers = [
  {
    text: 'More information on this topic is available from the SEC:',
    link: 'https://www.sec.gov/investor/pubs/leveragedetfs-alert ',
  },
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];
export const inverseETFsDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];
export const amazonCompetitiveLandscapeDisclaimers = [
  {
    text: '',
    link: 'https://www.sec.gov/ix?doc=/Archives/edgar/data/1018724/000101872422000005/amzn-20211231.htm',
  },
  {
    text:
      'Tornado does not recommend AMZN or any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];
export const industrialsDisclaimers = [
  {
    text: 'Information about the industrials sector and S&P indices is available here: ',
    link: 'https://www.spglobal.com/spdji/en/indices/equity/sp-500-industrials-sector ',
  },
  {
    text: 'Information about the XLI ETF is available here:',
    link: 'https://www.ssga.com/us/en/intermediary/etfs/funds/the-industrial-select-sector-spdr-fund-xli ',
  },
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];
export const dividendsAndStockReturnsDisclaimers = [
  {
    text: 'Source for statistics about dividend contributions to stock returns:',
    link: 'https://www.cnbc.com/2022/09/09/how-dividends-work-add-to-stock-returns-chief-investment-strategist.html',
  },
  {
    text: 'More information on the dividend discount model is available here:',
    link: 'https://pages.stern.nyu.edu/~adamodar/pdfiles/valn2ed/ch13.pdf',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice. Past performance does not guarantee future results.',
  },
];
export const dividendsDynamicsTypesDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];
export const dividendsImportantDatesDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];
export const geOverviewDisclaimers = [
  {
    text: 'Information on GE’s results and corporate strategy is available here:',
    link: 'https://www.ge.com/sites/default/files/ge_webcast_presentation_04262022.pdf',
  },
  {
    text:
      'Tornado does not recommend GE or any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
  // TBDL:
  // {
  //   blogPost: '',
  //   blogPostName: '',
  // },
];
export const geBullAndBearDisclaimers = [
  {
    text: '',
    link:
      'https://www.marketwatch.com/articles/general-electric-breakup-stock-larry-culp-51636741225?mod=mw_quote_news_seemore ',
  },
  {
    text: '',
    link:
      'https://www.marketwatch.com/articles/general-electric-breakup-stock-larry-culp-51636741225?mod=mw_quote_news_seemore ',
  },
  {
    text: '',
    link: 'https://www.wsj.com/articles/ges-nemesis-an-eerily-prescient-bear-11559905201 ',
  },
  {
    text: '',
    link:
      'https://www.marketwatch.com/articles/this-ge-bear-says-the-companys-power-unit-is-overvalued-51637083222?mod=mw_quote_news_seemore ',
  },
  {
    text: '',
    link:
      'https://www.marketwatch.com/articles/general-electric-breakup-stock-larry-culp-51636741225?mod=mw_quote_news_seemore',
  },
  {
    text: '',
    link:
      'https://www.marketwatch.com/articles/general-electric-breakup-stock-larry-culp-51636741225?mod=mw_quote_news_seemore',
  },
  {
    text: '',
    link: 'https://www.wsj.com/articles/ges-nemesis-an-eerily-prescient-bear-11559905201',
  },
  {
    text: '',
    link:
      'https://www.marketwatch.com/articles/this-ge-bear-says-the-companys-power-unit-is-overvalued-51637083222?mod=mw_quote_news_seemore',
  },
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];
export const geCompetitorsDisclaimers = [
  {
    text: '',
    link: 'https://www.sec.gov/ix?doc=/Archives/edgar/data/40545/000004054522000008/ge-20211231.htm',
  },
  {
    text: '',
    link:
      'https://www.marketwatch.com/articles/general-electric-breakup-stock-larry-culp-51636741225?mod=mw_quote_news_seemore ',
  },
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];
export const stockMarketCatalystsDisclaimers = [
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];
export const companyDebtDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/how-risky-is-my-stock-start-with-the-business-d41166383ae0',
    blogPostName: 'How Risky is My Stock? Start with the Business.',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  Views expressed by Warren Buffett may not reflect the views of Nvstr Financial LLC dba Tornado or its affiliates.',
  },
];
export const cashFlowDisclaimers = [
  {
    blogPost:
      'https://blog.tornado.com/how-to-think-like-warren-buffett-about-the-intrinsic-value-of-a-stock-87656ef75dc7',
    blogPostName: 'How to Think Like Warren Buffett About the Intrinsic Value of a Stock',
  },
  {
    text: 'For more thoughts from Professor Damodaran:',
    link:
      'https://www.barrons.com/podcasts/streetwise/the-dean-of-valuation-talks-tesla-and-college-tuition/82941c53-68b8-48a2-8536-3bfcc580701b?page=1 ',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  Views expressed by Warren Buffett may not reflect the views of Nvstr Financial LLC dba Tornado or its affiliates.',
  },
];
export const cashFlowAndValuationDisclaimers = [
  {
    blogPost:
      'https://blog.tornado.com/how-to-think-like-warren-buffett-about-the-intrinsic-value-of-a-stock-87656ef75dc7',
    blogPostName: 'How to Think Like Warren Buffett About the Intrinsic Value of a Stock',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  Views expressed by Warren Buffett may not reflect the views of Nvstr Financial LLC dba Tornado or its affiliates.',
  },
];
export const marketCrashesDisclaimers = [
  {
    text: 'Source of Bill Miller quote:',
    link: 'https://millervalue.com/bill-miller-3q-2021-market-letter/ ',
  },
  {
    text: 'Source for S&P 500 return data:',
    link: 'https://pages.stern.nyu.edu/~adamodar/New_Home_Page/datafile/histretSP.html',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice. Past performance does not guarantee future results. Views expressed by Bill Miller may not reflect the views of Nvstr Financial LLC dba Tornado or its affiliates.',
  },
];
export const expenseRatiosDisclaimers = [
  {
    text: 'Source for ETF expense ratio data:',
    link: 'https://etfdb.com/compare/highest-expense-ratio/ ',
  },
  {
    text: 'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.',
  },
];
export const capitalGainsDisclaimers = [
  {
    text: 'More information on tax loss harvesting is available here:',
    link: 'https://investor.vanguard.com/investor-resources-education/taxes/offset-gains-loss-harvesting',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment or tax advice.',
  },
];
export const riskToleranceDisclaimers = [
  {
    blogPost: 'https://blog.tornado.com/traders-vs-investors-fdb3c616ff7a ',
    blogPostName: 'Traders vs. Investors',
  },
  {
    text: 'Source for Charlies Munger quote: ',
    link: 'https://chipublib.bibliocommons.com/v2/record/S126C1870101/quotations ',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice.  Views expressed by Charlie Munger and Warren Buffett may not reflect the views of Nvstr Financial LLC dba Tornado or its affiliates.',
  },
];
export const sizingStockPositionsDisclaimers = [
  {
    text: 'Source for Stanley Druckenmiller quote:',
    link:
      'https://moneyweek.com/investments/investment-strategy/605020/stan-druckenmiller-position-size-really-matters',
  },
  {
    text: 'Source for David Rolfe quote:',
    link: 'http://mastersinvest.com/positionsizingquotes',
  },
  {
    text:
      'Tornado does not recommend any specific stocks, ETFs, or investment strategies.  Not investment advice. Views expressed by third parties may not reflect the views of Nvstr Financial LLC dba Tornado or its affiliates.',
  },
];

export const benzingaProDisclaimers = [
  {
    text: 'Check out our blog: ',
    link: 'https://tornado.com/blog',
  },
];
export const arroDisclaimers = [
  {
    text: 'Check out our blog: ',
    link: 'https://tornado.com/blog',
  },
];

export const templateDisclaimers = [
  {
    blogPost: '',
    blogPostName: '',
  },
  {
    text: '',
    link: '',
  },
  {
    text: '',
  },
];
