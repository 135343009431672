import { LOADING_SECURITIES_DATA, ADD_SECURITIES } from '../constants';

const defaultState = {
  loadingSecuritiesList: [],
  lookup: {},
  earningsAnnouncements: null,
  isLoadingEarningsAnnouncements: false,
};

export default function securitiesReducer(state = defaultState, action) {
  let ids, securityId, securityData;
  switch (action.type) {
    case LOADING_SECURITIES_DATA:
      ids = action.payload;
      return {
        ...state,
        loadingSecuritiesList: [...state.loadingSecuritiesList, ...ids],
      };

    case ADD_SECURITIES:
      if (action.payload.length > 0) {
        const formatSecuritiesToDictionary = {};
        ids = [];
        action.payload.forEach((security) => {
          securityId = security.security_id;
          ids.push(securityId);
          formatSecuritiesToDictionary[securityId] = {
            ...state.lookup[securityId],
            ...security,
          };
        });
        return {
          ...state,
          lookup: {
            ...state.lookup,
            ...formatSecuritiesToDictionary,
          },
          loadingSecuritiesList: state.loadingSecuritiesList.filter((loadingIds) => !ids.includes(loadingIds)),
        };
      } else {
        securityData = action.payload;
        securityId = securityData.security_id;
        return {
          ...state,
          lookup: {
            ...state.lookup,
            [securityId]: {
              ...state.lookup[securityId],
              ...securityData,
            },
          },
          loadingSecuritiesList: state.loadingSecuritiesList.filter((loadingIds) => loadingIds !== securityId),
        };
      }

    default:
      return state;
  }
}
