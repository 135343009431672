import { newsAdapter } from '../adapters/newsAdapter';
import { fetchImageFromURL } from './imagesActions';

import {
  ADD_LOADING_STATE_FOR_NEWS,
  ADD_LOADING_STATE_FOR_NEWS_IMAGE,
  ADD_NEWS,
  ADD_NEWS_IMAGE,
  ADD_ERROR_STATE_FOR_NEWS,
  ADD_NEWS_IMAGE_FAILURE_STATE,
  CLEAR_NEWS_DATA_FOR_SECURITY,
} from '../constants';

import { createOpenGraphObject } from '../helpers/thoughtsHelpers';
import { saveImpressionToStorageIfUnrecorded } from '../helpers/impressionHelpers';
import { throwError } from '../helpers/devToolHelpers';

export const fetchNews = (params) => {
  // DOES NOT SUPPORT MULTIPLE SECURITY IDEAS -> WAITING FOR API REFACTOR TO RETURN A DICTIONARY LOOKUP BY ID
  const securityIds = Array.isArray(params.securityIds) ? params.securityIds : [params.securityIds];
  const securityId = securityIds[0];
  const loadingSecurityNews = {};
  securityIds.forEach((id) => {
    loadingSecurityNews[id] = null;
  });

  return function (dispatch) {
    dispatch({
      type: ADD_LOADING_STATE_FOR_NEWS,
      payload: { securityId },
    });

    return newsAdapter.fetchNews(params).then((response) => {
      if (response.data) {
        const newsData = response.data.news[0] || [];
        const payload = {
          securityId,
          newsData: newsData,
        };
        dispatch({
          type: ADD_NEWS,
          payload,
        });
      } else {
        const error = 'Something went wrong. Please try again later';
        const newsErrors = {};
        securityIds.forEach((id) => {
          newsErrors[id] = error;
        });
        dispatch({
          type: ADD_ERROR_STATE_FOR_NEWS,
          payload: { newsErrors },
        });
      }
      return response;
    });
  };
};

export const fetchNewsImage = (newsData) => {
  const url = newsData.open_graph_object && newsData.open_graph_object.url;
  return function (dispatch) {
    dispatch({
      type: ADD_LOADING_STATE_FOR_NEWS_IMAGE,
      payload: { url },
    });

    return fetchImageFromURL(url)(dispatch).then((response) => {
      if (response && response.status === 200) {
        var data = response.data;
        if (!data.title && newsData.open_graph_object) {
          data.title = newsData.open_graph_object.title;
        }
        const openGraphObject = createOpenGraphObject(data);

        dispatch({
          type: ADD_NEWS_IMAGE,
          payload: { url, openGraphObject },
        });
      } else {
        // handle failure
        dispatch({
          type: ADD_NEWS_IMAGE_FAILURE_STATE,
          payload: { url },
        });
      }
      return response;
    });
  };
};

export const saveNewsOgo = (url, ogo) => {
  return function (dispatch) {
    const openGraphObject = createOpenGraphObject(ogo);
    dispatch({
      type: ADD_NEWS_IMAGE,
      payload: { url, openGraphObject },
    });
    return true;
  };
};

export const clearNewsDataForSecurity = (securityId) => {
  return function (dispatch) {
    dispatch({
      type: CLEAR_NEWS_DATA_FOR_SECURITY,
      payload: { securityId },
    });
    return true;
  };
};

export const sendNewsViewEvent = (trackingData) => {
  const { id } = trackingData;
  if (!id || typeof id !== 'number') {
    throwError('Invalid id sent to sendNewsClickEvent', true);
  }
  return function () {
    return newsAdapter.sendNewsViewEvent(trackingData);
  };
};

export const sendNewsClickEvent = (trackingData) => {
  return function () {
    const { id, clickType, context } = trackingData;
    const clickTypes = ['link', 'more', 'pro', 'con'];
    if (!clickTypes.includes(clickType) || !id || typeof id !== 'number') {
      throwError('Invalid click type or id sent to sendNewsClickEvent', true);
    }
    saveImpressionToStorageIfUnrecorded('viewNews', id, context);
    return newsAdapter.sendNewsClickEvent(trackingData);
  };
};
