import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const Card = styled.div`
  flex: 1;
  padding: 16px 24px;
  border-radius: 5px;
  text-align: left;
  cursor: default;
  overflow: hidden; // card should not have scroll

  background: ${({ theme, transparentBackground }) =>
    transparentBackground ? 'transparent' : theme.themeColors.component};
  color: ${({ theme }) => theme.themeColors.text};

  ${({ height }) => (height ? `height: ${height};` : '')};

  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 390px) {
    padding: 16px;
  }
  @media (max-width: 340px) {
    padding: 8px;
  }
`;

export const CardRow = ({ items, cardProps = {} }) => {
  return (
    <Wrapper>
      {items.map((el, i) => (
        <Card key={i} {...cardProps}>
          {el}
        </Card>
      ))}
    </Wrapper>
  );
};
