import React from 'react';
import { connect } from 'react-redux';

import SecurityIdentifierDisplay from '../../../../UI/SecurityIdentifierDisplay';
import { saveImpressionToStorageIfUnrecorded } from '../../../../../helpers/thoughtsHelpers';
import { sendThoughtClickEvent } from '../../../../../actions';

class SeeMoreOnSecurityLink extends React.PureComponent {
  handleClick = () => {
    const { thoughtId, viewingContext, metadata, notificationIndex, dispatch } = this.props;
    const clickType = 'more';
    saveImpressionToStorageIfUnrecorded(clickType, thoughtId, viewingContext);
    sendThoughtClickEvent(thoughtId, clickType, viewingContext, metadata, notificationIndex)(dispatch);
  };

  render() {
    const { security } = this.props;
    return (
      <div className={'thought-notification-see-more-on-security-container'} onClick={this.handleClick}>
        <SecurityIdentifierDisplay
          customClassName={'link-btn link-btn-std-color btn-small'}
          securityId={security.security_id}
          symbol={security.symbol}
          prefixString={'See more on'}
          activeTab={'thoughts'}
          showSymbol
        />
      </div>
    );
  }
}

export default connect()(SeeMoreOnSecurityLink);
