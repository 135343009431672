import { logMetricsTrackingEvent } from '@src/actions';
import { ADJUST_EVENT_NAMES, logAdjustEvent } from '@src/main/lib/nvstr-common-ui.es';

const VIEW_TRADE_PAGE = {
  send: (properties) => {
    const event = 'View Trade Page';
    logMetricsTrackingEvent(event, properties)();

    logAdjustEvent(ADJUST_EVENT_NAMES.VIEW_TRADE_SCREEN);
  },
};

const PLACED_TRADE = {
  send: (properties) => {
    const event = 'Create Order';
    logMetricsTrackingEvent(event, properties)();

    logAdjustEvent(ADJUST_EVENT_NAMES.PLACED_LIVE_TRADE);
  },
};

const CREATE_OPTIMIZED_ORDERS_ERROR = {
  send: (properties) => {
    const event = 'Create Optimized Orders Error';
    logMetricsTrackingEvent(event, properties)();
  },
};

const trackingEvents = {
  CREATE_OPTIMIZED_ORDERS_ERROR,
  PLACED_TRADE,
  VIEW_TRADE_PAGE,
};

export default trackingEvents;
