import {
  ADD_AVAILABLE_RISK_PROFILES,
  ADD_INVESTMENT_OBJECTIVE_SETTINGS,
  UPDATE_INVESTMENT_OBJECTIVE_SETTINGS,
} from '../constants';

const defaultState = {
  riskProfiles: [],
  investmentObjectiveSettings: {},
};

export default function investmentObjectivesReducer(state = defaultState, action) {
  let optimizerData, investmentObjectiveSettings;

  switch (action.type) {
    case ADD_AVAILABLE_RISK_PROFILES:
      const riskProfiles = action.payload.riskProfiles;

      return {
        ...state,
        riskProfiles,
      };

    case ADD_INVESTMENT_OBJECTIVE_SETTINGS:
      investmentObjectiveSettings = action.payload.investmentObjectiveSettings;

      return {
        ...state,
        investmentObjectiveSettings,
      };

    case UPDATE_INVESTMENT_OBJECTIVE_SETTINGS:
      investmentObjectiveSettings = action.payload.investmentObjectiveSettings;

      return {
        ...state,
        investmentObjectiveSettings: {
          ...state.investmentObjectiveSettings,
          ...investmentObjectiveSettings,
        },
      };

    default:
      return state;
  }
}
