import React from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import { customChartTooltip, generateChartColor, DEFAULT_DATA_SET_PROPERTIES } from '../../helpers/chartHelpers';
import { formatLocaleString } from '../../helpers/generalHelpers';
import { formatForDisplay } from '../../helpers/displayHelpers';
import { FONT } from '@src/appConfig';

const Wrapper = styled.div`
  max-height: 400px;
  padding-bottom: 16px;
`;

const _returnChartAxisValueFormatted = (value) => {
  if (value < 0.0001) {
    return formatLocaleString(value, {
      maximumFractionDigits: 0,
      currency: 'USD',
    });
  }
  if (value < 0.01) {
    return formatLocaleString(value, {
      minimumFractionDigits: 3,
      maximumFractionDigits: 4,
      currency: 'USD',
    });
  }
  if (value < 0.1) {
    return formatLocaleString(value, {
      minimumFractionDigits: 3,
      maximumFractionDigits: 4,
      currency: 'USD',
    });
  }
  if (value < 1) {
    return formatLocaleString(value, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: 'USD',
    });
  }
  if (value < 10) {
    return formatLocaleString(value, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: 'USD',
    });
  }
  return formatLocaleString(value, {
    maximumFractionDigits: 2,
    currency: 'USD',
  });
};

const _returnLineChartOptions = (config) => {
  return {
    maintainAspectRatio: true,
    animation: {
      duration: 220,
    },
    scales: {
      x: {
        grid: {
          display: false,
          color: 'rgba(220, 220, 220, 0)',
        },

        ticks: {
          display: false,
          font: {
            color: '#ccc',
            size: 12,
            family: FONT.normal,
          },
          autoSkip: false,
        },
      },
      y: {
        grid: {
          color: 'rgba(235, 235, 235, 0.4)',
        },
        min: 0,
        position: 'left',
        ticks: {
          beginAtZero: true,
          font: {
            color: '#ccc',
            size: 12,
            family: FONT.normal,
          },
          callback: function (value, index, values) {
            if (config.yAxesAfterBuildTicks) {
              if (index === 0) {
                return config.yAxesAfterBuildTicks[1];
              }
              if (index === values.length - 1) {
                return config.yAxesAfterBuildTicks[0];
              }
            } else {
              return _returnChartAxisValueFormatted(value);
            }
          },
        },
      },
    },
    plugins: {
      legend: false,
      tooltip: {
        enabled: !config.disableTooltips,
        callbacks: {
          title: function (tooltipItem) {
            const item = tooltipItem[0];
            const { label: xLabel, dataset } = item;
            const { label } = dataset;
            return `${xLabel}`;
          },
          label: function (context) {
            return '';
          },
          afterBody: function (tooltipItem) {
            const item = tooltipItem[0];
            const { raw: rawValue } = item;
            return `${formatForDisplay(rawValue, 'price')}`;
          },
        },
      },
    },
  };
};

const EquityChart = ({ color, chartData, additionalDatasets }) => {
  const formatChartData = () => {
    // the canvas is passed into this function thru the data prop on the chart component
    const { labels, values } = chartData;

    const datasets = [
      {
        data: values,

        borderWidth: 3,
        borderColor: 'rgba(36, 217, 121, 1.0)',
        label: '',

        backgroundColor: 'rgba(36, 217, 121, 0.2)',
        pointHoverBackgroundColor: generateChartColor(color, '0.75'),

        ...DEFAULT_DATA_SET_PROPERTIES,
      },
      ...(additionalDatasets || []),
    ];
    return {
      labels: labels,
      datasets,
    };
  };

  const options = _returnLineChartOptions({});
  const data = formatChartData();
  return (
    <Wrapper className={'equity-chart'}>
      <Line data={data} options={options} />
    </Wrapper>
  );
};

export default EquityChart;
