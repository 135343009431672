import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';
import { getThoughtImpressionEventName } from '../../constants/tracking';

import Panel from '../../components/layout/Panel';
import HeadingWithGradientLine from '../UI/HeadingWithGradientLine';
import Button from '../../components/buttons/Button';

import BasicSecurityDataWrapper from '../SecurityData/BasicSecurityDataWrapper';
import ThoughtsDataContainer from '../Thoughts/ThoughtsDataContainer';
import ThoughtsPanel from '../Thoughts/ThoughtsPanel';
import IconButton from '../../components/buttons/IconButton';

import { SecuritySymbol } from '../Security/SecuritySymbol';
import { SecurityName } from '../Security/SecurityName';

import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import { isLimitOrder, isStopOrder, returnDisplayStringOrderOperation } from '../../helpers/ordersHelpers';
import { consoleError } from '../../helpers/devToolHelpers';
import { FlatButton, SkeletonButton } from '../../main/components/buttons';
import { Container } from '@src/main/components/ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 90vh;
  max-height: 1000px;

  display: flex;
  flex-direction: column;
  overflow: auto;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const ThoughtsPanelWrapper = styled.div`
  .watchlist-thoughts-panel-container {
    box-shadow: none !important;
  }
`;

class TradeAddProConPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _didCreateThoughtOrAddThoughtOpinion: false,
      _wasThoughtActionCompleted: false, // used to log if panel was skipped
      _expandedThoughtSectionSecurityId: null,
    };
  }

  componentDidMount() {
    this._fetchSecurityData();
    const firstOrderSecurityId = this._returnSecurityIdOfFirstOrder();
    if (firstOrderSecurityId) {
      this._setOpenRow(this._returnOrdersList()[0].securityId);
    }
    this._logViewAddProConPanel();
  }

  componentWillUnmount() {
    this._logCloseAddProConPanel();
  }

  render() {
    if (!this._returnOrdersList() || this._returnOrdersList().length === 0) {
      consoleError('Hiding TradeAddProConPanel because no orders present in props', this.props);
      return null;
    }

    return (
      <Wrapper className="trade-add-pro-con-panel">
        <ContentWrapper>
          <div className={`heading-container`}>
            <HeadingWithGradientLine text={'Keep track of the reasons behind your investments'} />
          </div>
          {this.props.hideClose ? null : (
            <div className={`close-button-container`}>
              <IconButton
                icon="fa-times fa-times-thin"
                colorClassName="secondary-text-color"
                handleClick={this._handleCloseAddProConPanel}
              />
            </div>
          )}

          <div className={`orders-list-with-thoughts-panel-container`}>
            {this._returnOrdersList().map((orderData) => {
              const { securityId } = orderData;
              return (
                <div key={`order-row-thoughts-panel-${securityId}`} className={`order-row-container`}>
                  <div className={`order-row`}>
                    {this._renderSecurityIdentifier(securityId)}
                    {this._renderOrderSummaryCell(orderData)}
                    {this._isMoreThanOneRow() && this._renderOrderActionCell(securityId)}
                  </div>

                  {this._isRowExpanded(securityId) && this._renderThoughtsPanel(securityId)}
                </div>
              );
            })}
          </div>
        </ContentWrapper>
        <Container centerAll>
          <FlatButton fullWidth onClick={this._handleCloseAddProConPanel}>
            Complete
          </FlatButton>
        </Container>
      </Wrapper>
    );
  }

  _renderThoughtsPanel = (securityId) => {
    return (
      <ThoughtsPanelWrapper>
        <BasicSecurityDataWrapper securityId={securityId}>
          <ThoughtsDataContainer getThoughtData>
            <ThoughtsPanel
              viewingContext={getThoughtImpressionEventName('AFTER_TRADE_THOUGHTS_PANEL')}
              scrollContainerSelector={'.trade-add-pro-con-panel'}
              handleAfterThoughtAction={this._setThoughtActionCompleted}
              afterTrade
            />
          </ThoughtsDataContainer>
        </BasicSecurityDataWrapper>
      </ThoughtsPanelWrapper>
    );
  };

  _renderSecurityIdentifier = (securityId) => {
    return (
      <div className={`order-list-row-cell security-identifier-container`}>
        <BasicSecurityDataWrapper securityId={securityId} useLoadingComponent>
          <SecuritySymbol />
          <SecurityName className="secondary-text-color" />
        </BasicSecurityDataWrapper>
      </div>
    );
  };

  _renderOrderSummaryCell = (orderData) => {
    const orders = orderData.orderDataSummaryList;
    return (
      <div className={`order-list-row-cell order-summary justify-center-all-list-items`}>
        <div>{orders.map((orderSummaryData) => this._renderOrderSummaryLine(orderSummaryData, orderData))}</div>
      </div>
    );
  };

  _renderOrderActionCell = (securityId) => {
    return (
      <div className={`order-list-row-cell order-row-action-cell`}>
        {this._isRowExpanded(securityId) ? (
          <SkeletonButton onClick={this._bindSecurityIdToOrderRowCloseClick(securityId)}>Hide</SkeletonButton>
        ) : (
          <SkeletonButton onClick={this._bindSecurityIdToOrderRowOpenClick(securityId)}>Why</SkeletonButton>
        )}
      </div>
    );
  };

  _renderOrderSummaryLine = (orderSummaryData, orderData) => {
    const { securityId } = orderData;
    const { shares, operation } = orderSummaryData;
    return (
      <div key={`order-summary-line-${operation}-${securityId}`} className={`order-summary-line`}>
        <p>
          <span className={`text-group`}>
            {`${isStopOrder(orderSummaryData) ? 'Stop ' : ''}${isLimitOrder(orderSummaryData) ? 'Limit ' : ''}Order`}
            &nbsp;
          </span>
          <span className={`text-group`}>
            {(isStopOrder(orderSummaryData) || isLimitOrder(orderSummaryData)) && <span className={``}>&nbsp;</span>}
            <span
              className={`text-with-spacing-right ${
                returnDisplayStringOrderOperation(operation) === 'Buy' ? 'pro-text-color' : 'con-text-color'
              }`}
            >
              {returnDisplayStringOrderOperation(operation)}
            </span>
            <span className={``}>{`${shares} share${shares > 1 ? 's' : ''}`}</span>
          </span>
        </p>
      </div>
    );
  };

  _bindSecurityIdToOrderRowOpenClick = (securityId) => {
    return () => this._handleOrderRowOpenClick(securityId);
  };

  _setOpenRow = (securityId) => {
    this.setState(() => ({
      _expandedThoughtSectionSecurityId: securityId,
    }));
  };

  _returnSecurityIdOfFirstOrder = () => this._returnOrdersList()[0] && this._returnOrdersList()[0].securityId;

  _wasThoughtActionCompleted = () => this.state._wasThoughtActionCompleted;

  _setThoughtActionCompleted = () => {
    this.setState(() => ({
      _wasThoughtActionCompleted: true,
    }));
  };

  _handleOrderRowOpenClick = (securityId) => {
    this._setOpenRow(securityId);
  };

  _bindSecurityIdToOrderRowCloseClick = (securityId) => {
    return () => this._handleOrderRowCloseClick(securityId);
  };

  _handleOrderRowCloseClick = (securityId) => {
    this.setState(() => ({
      _expandedThoughtSectionSecurityId: null,
    }));
  };

  _handleCloseAddProConPanel = () => {
    this.props.closePanel();
  };

  _returnExpandedThoughtSectionSecurityId = () => this.state._expandedThoughtSectionSecurityId;

  _isRowExpanded = (securityId) =>
    this._returnExpandedThoughtSectionSecurityId() === securityId && !isUndefinedOrNull(securityId);

  _returnOrdersList = () => this.props.orders || [];

  _isMoreThanOneRow = () => this._returnOrdersList().length > 1;

  _returnSecurityIdsToGetData = () => this._returnOrdersList().map((orderData) => orderData.securityId);

  _fetchSecurityData = () => {
    this.props.actions.quickFetchSecuritiesData(this._returnSecurityIdsToGetData());
  };

  _logViewAddProConPanel = () => {
    const event = 'Viewed Add Pro/Con Panel';
    const properties = {
      Context: 'After Trade',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logCloseAddProConPanel = () => {
    if (!this._wasThoughtActionCompleted()) {
      this._logSkippedAddProConPanel();
    }
    const event = 'Closed Add Pro/Con Panel';
    const properties = {
      Context: 'After Trade',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logSkippedAddProConPanel = () => {
    const event = 'Skipped Pros/Cons After Trade';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TradeAddProConPanel);
