import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Spacing } from '@src/main/components/layout';
import { Container } from '@src/main/components/ui';
import { Body5 } from '@src/main/lib/nvstr-common-ui.es';
import { FlatButton, SkeletonButton } from '@src/main/components/buttons';
import { LessonContentImport } from '@src/main/containers/learn/admin/LessonContentImport';
import { DATA_KEYS } from '@src/main/containers/learn/admin/index';
import { learnTypes } from '@src/main/constants/actionTypes';

const PhraseButtonWrapper = styled.div`
  button {
    padding: 2px 8px;
  }
`;

const LessonPageEntryWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 100px;
    width: 100%;
    padding: 16px;
  }
`;

const DisclaimerOptionWrapper = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
`;

const MetadataInput = ({ name, keyName, keyNames, state, onChange }) => {
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const v = e.target.value;
    onChange(keyName, v);
  };

  if (keyNames) {
    const handleChangeMulti = (k) => (e) => {
      const v = e.target.value;
      onChange(k, v);
    };
    return (
      <LessonPageEntryWrapper>
        <Container bottom={4}>
          <Body5>{name}</Body5>
        </Container>
        <Container top={8} row>
          {keyNames.map((k) => (
            <Container row key={k} right={8} width={80}>
              <input onChange={handleChangeMulti(k)} value={state[k]} />
            </Container>
          ))}
        </Container>
      </LessonPageEntryWrapper>
    );
  }

  const onAddCust = () => {
    const v = state[keyName];
    dispatch({
      type: learnTypes.updateLearnPreviewLesson,
      payload: { phrase: '$!' + v },
    });
  };
  const onDelCust = () => {
    const v = state[keyName];
    dispatch({
      type: learnTypes.updateLearnPreviewLesson,
      payload: { phrase: v.split('$!')[1] },
    });
  };
  const hasCustPhrase = state[keyName].slice(0, 2) === '$!';
  return (
    <LessonPageEntryWrapper>
      <Container bottom={4} row verticallyCenter>
        <Body5>{name}</Body5>
        {keyName === 'phrase' && (
          <Container left={8}>
            <PhraseButtonWrapper>
              {hasCustPhrase ? (
                <FlatButton onClick={onDelCust}>Custom Phrase</FlatButton>
              ) : (
                <SkeletonButton onClick={onAddCust}>Use Custom Phrase</SkeletonButton>
              )}
            </PhraseButtonWrapper>
          </Container>
        )}
      </Container>
      <Container top={8} row>
        <input onChange={handleChange} value={state[keyName]} />
      </Container>
    </LessonPageEntryWrapper>
  );
};

const LessonMetadataEntry = ({ isEditMode, onChange, state }) => {
  return (
    <LessonPageEntryWrapper>
      {isEditMode && <MetadataInput name={'Lesson ID'} keyName={DATA_KEYS.id} onChange={onChange} state={state} />}

      <Spacing top={8}>
        <MetadataInput name={'Lesson Name'} keyName={DATA_KEYS.name} onChange={onChange} state={state} />
      </Spacing>
      <Spacing top={8}>
        <MetadataInput name={'Lesson Heading'} keyName={DATA_KEYS.heading} onChange={onChange} state={state} />
      </Spacing>
      <Spacing top={8}>
        <MetadataInput name={'Lesson Phrase'} keyName={DATA_KEYS.phrase} onChange={onChange} state={state} />
      </Spacing>
      <Spacing top={8}>
        <MetadataInput
          name={'Lesson Levels'}
          keyNames={[DATA_KEYS.minLevel, DATA_KEYS.maxLevel]}
          onChange={onChange}
          state={state}
        />
      </Spacing>
    </LessonPageEntryWrapper>
  );
};

const DisclaimerOption = ({ disclaimer, blogDisclaimer, dispatch }) => {
  if (disclaimer) {
    const { id, text, link } = disclaimer;
    const handleTextChange = (e) => {
      const v = e.target.value;
      dispatch({
        type: learnTypes.updateDisclaimer,
        payload: {
          id,
          text: v,
          link,
        },
      });
    };
    const handleDelete = () => {
      dispatch({
        type: learnTypes.deleteDisclaimer,
        payload: {
          id,
        },
      });
    };
    const handleLinkChange = (e) => {
      const v = e.target.value;
      dispatch({
        type: learnTypes.updateDisclaimer,
        payload: {
          id,
          text,
          link: v,
        },
      });
    };
    return (
      <div>
        <div>
          <Container bottom={4}>
            <Body5>Text</Body5>
          </Container>
          <Container top={8} row>
            <input onChange={handleTextChange} value={text} />
          </Container>
        </div>
        <Container top={8}>
          <Container bottom={4}>
            <Body5>Link</Body5>
          </Container>
          <Container top={8} row>
            <input onChange={handleLinkChange} value={link} />
          </Container>

          <Container top={8} row>
            <FlatButton transparent onClick={handleDelete}>
              Delete
            </FlatButton>
          </Container>
        </Container>
      </div>
    );
  } else {
    const { id, blogPostName, blogPost } = blogDisclaimer;
    const handlePostNameChange = (e) => {
      const v = e.target.value;
      dispatch({
        type: learnTypes.updateBlogDisclaimer,
        payload: {
          id,
          blogPostName: v,
          blogPost,
        },
      });
    };
    const handlePostLinkChange = (e) => {
      const v = e.target.value;
      dispatch({
        type: learnTypes.updateBlogDisclaimer,
        payload: {
          id,
          blogPostName,
          blogPost: v,
        },
      });
    };
    const handlePostDelete = () => {
      dispatch({
        type: learnTypes.deleteBlogDisclaimer,
        payload: {
          id,
        },
      });
    };
    return (
      <div>
        <div>
          <Container bottom={4}>
            <Body5>Blog Post Name</Body5>
          </Container>
          <Container top={8} row>
            <input onChange={handlePostNameChange} value={blogPostName} />
          </Container>
        </div>
        <Container top={8}>
          <Container bottom={4}>
            <Body5>Blog Post Link</Body5>
          </Container>
          <Container top={8} row>
            <input onChange={handlePostLinkChange} value={blogPost} />
          </Container>

          <Container top={8} row>
            <FlatButton transparent onClick={handlePostDelete}>
              Delete
            </FlatButton>
          </Container>
        </Container>
      </div>
    );
  }
};

export const LessonEntry = ({ isEditMode, onChange, state }) => {
  const dispatch = useDispatch();

  const onAddDisclaimer = () => {
    dispatch({
      type: learnTypes.addDisclaimer,
    });
  };
  const onAddBlogDisclaimer = () => {
    dispatch({
      type: learnTypes.addBlogDisclaimer,
    });
  };

  return (
    <div>
      <LessonMetadataEntry isEditMode={isEditMode} onChange={onChange} state={state} />
      <Spacing top={8}>
        <LessonPageEntryWrapper>
          <LessonContentImport onChange={onChange} label={'Content'} key={DATA_KEYS.contents} />
        </LessonPageEntryWrapper>
      </Spacing>

      {Object.keys(state.blogDisclaimers).map((k) => (
        <LessonPageEntryWrapper key={k}>
          <DisclaimerOptionWrapper>
            <DisclaimerOption blogDisclaimer={state.blogDisclaimers[k]} dispatch={dispatch} />
          </DisclaimerOptionWrapper>
        </LessonPageEntryWrapper>
      ))}
      {isEditMode ? (
        <Container top={24}>
          <Body5>If you edit the blog post, you must re-enter all disclaimers</Body5>
        </Container>
      ) : null}
      <Spacing top={16}>
        <FlatButton onClick={onAddBlogDisclaimer}>{'Add Blog Post'}</FlatButton>
      </Spacing>
      {isEditMode ? (
        <Container top={24}>
          <Body5>If you edit the disclaimers, you must re-enter all disclaimers including blog posts</Body5>
        </Container>
      ) : null}
      {Object.keys(state.disclaimers).map((k) => (
        <LessonPageEntryWrapper key={k}>
          <DisclaimerOptionWrapper>
            <DisclaimerOption disclaimer={state.disclaimers[k]} dispatch={dispatch} />
          </DisclaimerOptionWrapper>
        </LessonPageEntryWrapper>
      ))}

      <Spacing top={16}>
        <FlatButton onClick={onAddDisclaimer}>{'Add Disclaimer'}</FlatButton>
      </Spacing>
    </div>
  );
};
