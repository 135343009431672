import { ifExists } from '../helpers/generalHelpers';

export const getSecuritiesStoreFromState = (state) => state.securities;

export const getSecuritiesPriceStoreFromState = (state) => state.securitiesPrice;

export const getSecuritiesPriceLookupFromState = (state) => getSecuritiesPriceStoreFromState(state).securities;

export const getSecuritiesLookupFromState = (state) => getSecuritiesStoreFromState(state).lookup;

export const getSecurityFromState = (id, state) => getSecuritiesLookupFromState(state)[id] || null;

export const getSecuritySymbolFromState = (id, state) => ifExists(getSecurityFromState(id, state), 'symbol');

export const getSecurityPriceHistoryLookupFromState = (state) => state.securitiesPriceHistory;

export const getSecurityStaticPriceDataFromState = (id, state) => {
  return getSecuritiesPriceLookupFromState(state)[id] || null;
};

export const getSecurityStaticCurrentPriceFromState = (id, state) => {
  const priceData = getSecurityStaticPriceDataFromState(id, state);
  if (!priceData) {
    return null;
  }
  return priceData.current_price;
};
