import React from 'react';

class ScriptLoader extends React.Component {
  constructor(props) {
    super(props);
    this.ScriptLoaderRef = React.createRef();
  }

  componentDidMount() {
    this._loadScript();
  }

  shouldComponentUpdate() {
    return false; // component won't update so script is manipulated
  }

  render() {
    return <div ref={this.ScriptLoaderRef} />;
  }

  _loadScript = () => {
    const { type, id } = this.props;
    const uri = this._returnURI();
    const tag = document.createElement('script');
    if (id) {
      tag.id = id;
    }
    tag.src = uri;
    tag.async = true;
    if (type) {
      tag.type = type;
    }
    tag.onload = () => {
      this.handleOnLoad();
    };
    tag.onerror = (error) => {
      this.handleOnLoadFail(error);
    };
    const scriptLoaderElement = this.ScriptLoaderRef.current;
    scriptLoaderElement.parentNode.insertBefore(tag, scriptLoaderElement);
  };

  _returnURI = () => this.props.uri;

  handleOnLoad = () => {
    this.props.onLoad();
  };

  handleOnLoadFail = () => {
    this.props.onLoadFail();
  };
}

export default ScriptLoader;
