import React from 'react';
import { AddBankAccountForm } from '../../../containers/forms/AddBankAccountForm';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/paths';

export const LinkBankMicroDeposits = () => {
  const navigate = useNavigate();
  const handleContinue = () => {
    navigate(ROUTES.FUNDING.build());
  };

  return <AddBankAccountForm onContinue={handleContinue} />;
};
