import { colorThemeActions } from '@src/constants';
import {
  defaultCustomColorTheme,
  generateComputedColors,
  getStartingColorTheme,
  getStartingColorThemeName,
} from '@src/main/utils/themes';

const defaultState = {
  value: getStartingColorThemeName(),
  customTheme: getStartingColorTheme(),
};

export default function colorThemeReducer(state = defaultState, action) {
  switch (action.type) {
    case colorThemeActions.set:
      const value = action.payload;
      if (value !== state.value) {
        return {
          ...state,
          value,
        };
      } else {
        return state;
      }

    case colorThemeActions.setCustomColorThemeColorDefinition:
      const colors = action.payload;
      const computedColors = generateComputedColors(colors);
      return {
        ...state,
        customTheme: {
          ...state.customTheme,
          ...colors,
          ...computedColors,
        },
      };

    case colorThemeActions.resetCustomColorTheme:
      return {
        ...state,
        customTheme: {
          ...defaultCustomColorTheme,
        },
      };

    default:
      return state;
  }
}
