import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import { returnSecurityIdFromNotification } from '../../helpers/notificationsHelpers';
import { isRequestingSecurityData, isSecurityDataAvailable } from '../../helpers/securitiesHelpers';

class NotificationSecuritiesDataGetter extends React.Component {
  componentDidMount() {
    this._getSecuritiesDataIfNeeded();
  }

  componentDidUpdate() {
    this._getSecuritiesDataIfNeeded();
  }

  render() {
    return null;
  }

  _getSecuritiesDataIfNeeded = () => {
    const securityIdsInNotifications = this.returnAllSecuritiesInNotifications();
    const securitiesByIdNeedData = [];
    securityIdsInNotifications.forEach((id) => {
      if (this._shouldGetSecurityData(id)) {
        securitiesByIdNeedData.push(id);
      }
    });
    if (securitiesByIdNeedData.length > 0) {
      this.getDataForSecurities(securitiesByIdNeedData);
    }
  };

  _shouldGetSecurityData = (id) =>
    !isRequestingSecurityData(id, this.returnSecuritiesStore()) &&
    !isSecurityDataAvailable(id, this.returnSecuritiesStore());

  returnAllSecuritiesInNotifications = () => {
    const notifications = this.returnAllNotifications();
    const securityIdsInNotifications = notifications
      .map((notification) => returnSecurityIdFromNotification(notification))
      .filter((id) => id !== null);
    return securityIdsInNotifications.filter((id, i) => securityIdsInNotifications.indexOf(id) === i);
  };

  getDataForSecurities = (securities) => this.props.actions.quickFetchSecuritiesData(securities);

  returnAllNotifications = () => this.returnNotificationStore().notificationsList;

  returnNotificationStore = () => this.props.notifications;

  _isSecurityDataAvailable = (securityId) =>
    this.returnSecurityFromSecurityId(securityId) && this.returnSecurityFromSecurityId(securityId).name;

  returnSecurityFromSecurityId = (securityId) => this.returnSecuritiesStoreLookup()[securityId];

  returnSecuritiesStoreLookup = () => this.returnSecuritiesStore().lookup;

  returnSecuritiesStore = () => this.props.securities;
}

const mapStateToProps = (state) => {
  return {
    securities: state.securities,
    notifications: state.notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSecuritiesDataGetter);
