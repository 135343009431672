import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as Actions from '../../../../actions/index';
import LinkTo from '../../../Links/LinkTo';
import { snakeToHyphens } from '../../../../helpers/generalHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';

export class ExternalPositionsBasicCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? (
          this.props.loadingCellComponent
        ) : !this._isUnmappedSecurity() ? (
          <LinkTo
            to={this._returnLinkForHandlingClick()}
            className={''}
            additionalClickAction={this.props.logSecurityPanelOpenClick}
            child={this._renderCellContent()}
            noTextDecoration
          />
        ) : (
          this._renderUnmappedCellContent()
        )}
      </td>
    );
  }

  _isUnmappedSecurity = () => {
    return this.props.unmappedSecurity;
  };

  _isLoading = () => {
    const { securityId } = this.props;
    const security = this.props.securities.lookup[securityId];

    const result =
      // position not loaded
      this._isUnmappedSecurity() === undefined ||
      // if position loaded and is mapped security, but securities not loaded for it
      (this._isUnmappedSecurity() === false &&
        (!(securityId in this.props.securities.lookup) || !security.symbol || !security.name));

    return result;
  };

  _returnLinkForHandlingClick = () => {
    const securityId = this.props.securityId;
    if (!securityId) {
      return null;
    }
    const openSecurityPanelConfig = {
      securityId,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    return <></>;
  };

  _renderUnmappedCellContent = () => {
    return (
      <span className={`security-table-day-change-container`}>
        <span className={`security-table-daily-price-change-container`}></span>
        <span className={`security-table-daily-price-change-container`}></span>
      </span>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    securities: state.securities,
    externalPositions: state.externalPositions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(ExternalPositionsBasicCell);

export default composedComponent;
