import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeSecuritiesTracker, LivePricePollingManager } from '../classes/LivePricePollingManager';
import { quickFetchSecuritiesData } from '@src/actions';

let gettingDataForSecurityIds = [];

export const LivePriceTracking = ({ securityIds }) => {
  const dispatch = useDispatch();

  const securitiesStore = useSelector((state) => state.securities.lookup);

  React.useEffect(() => {
    const needsData = [];
    const trackingSecurities = [];

    securityIds?.forEach((sid) => {
      const stock = securitiesStore[sid];
      if (stock) {
        const { id, symbol } = stock;
        gettingDataForSecurityIds = gettingDataForSecurityIds.filter((a_id) => a_id !== id);
        trackingSecurities.push({ id, symbol });
      } else {
        const isAlreadyGetting = gettingDataForSecurityIds.includes(sid);
        if (!isAlreadyGetting) {
          gettingDataForSecurityIds.push(sid);
          needsData.push(sid);
        }
      }
    });

    if (needsData.length) {
      quickFetchSecuritiesData([needsData])(dispatch);
    }

    if (trackingSecurities) {
      activeSecuritiesTracker.addTempSecurities(trackingSecurities);
    }

    return () => {
      activeSecuritiesTracker.removeTempSecurities(trackingSecurities);
    };
  }, [securityIds, securitiesStore]);

  return null;
};
