import React from 'react';
import { useSelector } from 'react-redux';
import { useCurrentUserId } from './useCurrentUserId';
import { getUserIdeasLookupBySecurityId } from '../../../helpers/ideaHelpers';
import { usePositionsLookup } from '../portfolio/usePositions';
import { doArraysContainSameElementValues } from '@src/helpers/generalHelpers';

export const IDEA_FILTERS = {
  nonPositionIdeas: 'npi',
};

const filterIdeas = ({ positions, ideasUserId, ideaList, filters }) => {
  const ideas = getUserIdeasLookupBySecurityId(ideasUserId, ideaList) || {};

  switch (filters) {
    case IDEA_FILTERS.nonPositionIdeas: {
      const filterOutSecurityIds = Object.keys(positions).filter((id) => positions[id].shares !== 0);
      const filteredIdeas = {};
      Object.keys(ideas)
        .filter((id) => !filterOutSecurityIds.includes(id))
        .map((id) => {
          filteredIdeas[id] = ideas[id];
        });
      return filteredIdeas;
    }

    default: {
      return ideas;
    }
  }
};

export const useCurrentIdeas = (userId, filters) => {
  const ideasUserId = userId || useCurrentUserId();
  const ideaStore = useSelector((state) => state.ideas);
  const positions = usePositionsLookup();

  const [ideas, setIdeas] = React.useState(
    filterIdeas({ positions, ideasUserId, ideaList: ideaStore.ideaList, filters })
  );

  React.useEffect(
    () => {
      const filteredIdeas = filterIdeas({ positions, ideasUserId, ideaList: ideaStore.ideaList, filters });
      setIdeas(filteredIdeas);
    },
    [ideasUserId, ideaStore, filters]
  );

  return ideas;
};

const sortIdeasList = (keys, lookup) => {
  return keys
    .map((k) => lookup[k])
    .sort((a, b) => {
      const bSymbol = b.security.symbol;
      const aSymbol = a.security.symbol;

      if (aSymbol < bSymbol) {
        return -1;
      }
      if (aSymbol > bSymbol) {
        return 1;
      }
      return 0;
    });
};

export const useSortedCurrentIdeasList = () => {
  const ideasLookup = useCurrentIdeas();
  const ideasListBySecurityId = Object.keys(ideasLookup);

  const [prevIdeasList, setPrevIdeas] = React.useState(ideasListBySecurityId);
  const [sortedIdeas, setIdeas] = React.useState(sortIdeasList(ideasListBySecurityId, ideasLookup));

  React.useEffect(
    () => {
      const newIdeasList = Object.keys(ideasLookup);
      if (!doArraysContainSameElementValues(newIdeasList, prevIdeasList)) {
        setPrevIdeas(newIdeasList);
        setIdeas(sortIdeasList(ideasListBySecurityId, ideasLookup));
      }
    },
    [ideasLookup, prevIdeasList]
  );

  return sortedIdeas;
};

const isSortOrderTheSame = (a, b) => {
  if (!a || !b) return false;
  if (a.length !== b.length) return false;
  return a.every((el, i) => el === b[i]);
};

const sortIdeasListForOptimizedPositions = (
  securityIds,
  lookup,
  optimizedPositionLookup = {},
  positionsLookup = {}
) => {
  securityIds.forEach((k) => {
    const idea = lookup[k];
    const securityId = idea.security.id;
    lookup[securityId] = idea;
  });

  const optimizedPositionSecurityIds = securityIds.filter((sid) => Math.abs(optimizedPositionLookup[sid]?.shares) > 0);
  const allocatedPositionSecurityIds = securityIds.filter((sid) => Math.abs(positionsLookup[sid]?.shares) > 0);

  const allocatedAndNotOptimizedIdeas = allocatedPositionSecurityIds
    .filter((sid) => optimizedPositionSecurityIds.indexOf(sid) < 0)
    .map((sid) => lookup[sid]);

  const bothAllocatedCombined = [...optimizedPositionSecurityIds, ...allocatedPositionSecurityIds];

  const nonAllocatedPositionIdeas = securityIds
    .filter((sid) => bothAllocatedCombined.indexOf(sid) < 0)
    .map((sid) => lookup[sid]);
  const activeNonAllocatedPositionIdeas = nonAllocatedPositionIdeas.filter((i) => i.active);
  const notActiveNonAllocatedPositionIdeas = nonAllocatedPositionIdeas.filter((i) => !i.active);

  const optimizedSorted = optimizedPositionSecurityIds
    .map((sid) => lookup[sid])
    .sort((a, b) => {
      const aId = a.security.id;
      const bId = b.security.id;
      const aValue = optimizedPositionLookup[aId]?.value || 0;
      const bValue = optimizedPositionLookup[bId]?.value || 0;
      return bValue - aValue;
    });
  const allocatedSorted = allocatedAndNotOptimizedIdeas.sort((a, b) => {
    const aId = a.security.id;
    const bId = b.security.id;
    const aValue = positionsLookup[aId]?.value || 0;
    const bValue = positionsLookup[bId]?.value || 0;
    return bValue - aValue;
  });
  const activeNonAllocatedSorted = activeNonAllocatedPositionIdeas.sort((a, b) => {
    const aSymbol = a.security.symbol;
    const bSymbol = b.security.symbol;
    if (aSymbol < bSymbol) {
      return -1;
    }
    if (aSymbol > bSymbol) {
      return 1;
    }
    return 0;
  });
  const inactiveNonAllocatedSorted = notActiveNonAllocatedPositionIdeas.sort((a, b) => {
    const aSymbol = a.security.symbol;
    const bSymbol = b.security.symbol;
    if (aSymbol < bSymbol) {
      return -1;
    }
    if (aSymbol > bSymbol) {
      return 1;
    }
    return 0;
  });
  return [...optimizedSorted, ...allocatedSorted, ...activeNonAllocatedSorted, ...inactiveNonAllocatedSorted];
};

const sortIdeasListOnAllocationSize = (securityIds, lookup, optimizedPositionLookup = {}, positionsLookup = {}) => {
  securityIds.forEach((k) => {
    const idea = lookup[k];
    const securityId = idea.security.id;
    lookup[securityId] = idea;
  });

  const allocatedPositionSecurityIds = securityIds.filter((sid) => Math.abs(positionsLookup[sid]?.shares) > 0);
  const allocatedPositionIdeas = allocatedPositionSecurityIds.map((sid) => lookup[sid]);
  const allocatedSorted = allocatedPositionIdeas.sort((a, b) => {
    const aId = a.security.id;
    const bId = b.security.id;
    const aValue = positionsLookup[aId]?.value || 0;
    const bValue = positionsLookup[bId]?.value || 0;
    return bValue - aValue;
  });

  const watchlistSecurityIds = securityIds.filter(
    (sid) => (Math.abs(positionsLookup[sid]?.shares) || 0) === 0 && lookup[sid].active
  );
  const watchlistPositionIdeas = watchlistSecurityIds.map((sid) => lookup[sid]);
  const watchlistSorted = watchlistPositionIdeas.sort((a, b) => {
    const aSymbol = a.security.symbol;
    const bSymbol = b.security.symbol;
    if (aSymbol < bSymbol) {
      return -1;
    }
    if (aSymbol > bSymbol) {
      return 1;
    }
    return 0;
  });

  return [...allocatedSorted, ...watchlistSorted];

  const nonAllocatedPositionSecurityIds = securityIds.filter((sid) => Math.abs(positionsLookup[sid]?.shares) === 0);
  const nonAllocatedPositionIdeas = securityIds
    .filter((sid) => nonAllocatedPositionSecurityIds.indexOf(sid) < 0)
    .map((sid) => lookup[sid]);

  const nonAllocatedSorted = nonAllocatedPositionIdeas.sort((a, b) => {
    const aId = a.security.id;
    const bId = b.security.id;
    const aValue = positionsLookup[aId]?.symbol;
    const bValue = positionsLookup[bId]?.symbol;
    return bValue - aValue;
  });

  // return [...allocatedSorted, ...nonAllocatedSorted];
};

export const useSortedOptimizedIdeasList = () => {
  const ideasLookup = useCurrentIdeas();
  const ideasListBySecurityId = Object.keys(ideasLookup);
  const optimizedPositionLookup = useSelector((state) => state.optimizer.data?.optimized_positions_by_security_id);
  const positionsLookup = usePositionsLookup();

  const [prevIdeasList, setPrevIdeas] = React.useState(ideasListBySecurityId);
  const [sortedIdeas, setIdeas] = React.useState(
    sortIdeasListOnAllocationSize(ideasListBySecurityId, ideasLookup, optimizedPositionLookup, positionsLookup)
  );

  React.useEffect(
    () => {
      const sorted = sortIdeasListOnAllocationSize(
        ideasListBySecurityId,
        ideasLookup,
        optimizedPositionLookup,
        positionsLookup
      );
      if (!isSortOrderTheSame(sorted, prevIdeasList)) {
        setPrevIdeas(sorted);
        setIdeas(sorted);
      }
    },
    [ideasLookup, prevIdeasList, optimizedPositionLookup]
  );

  return sortedIdeas;
};
