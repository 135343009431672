import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PRODUCT_NAME, PRODUCT_NAMES } from '@src/appConfig';
import { logMetricsTrackingEvent, cancelSubscription } from '../../actions';

import { ButtonContainer } from '../buttons/ButtonContainer';
import InComponentSuccessBanner from '../UI/InComponentSuccessBanner';
import LoadingIcon from '../../components/misc/LoadingIcon';
import ContactSupportIssue from '../../components/UI/messaging/ContactSupportIssue';

class CancelSubscriptionForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      _isUnsubscribing: false,

      _didUnsubscribe: false,
      _wasUnsubscribeError: false,
    };
  }

  render() {
    return (
      <div>
        <div className={'action-container'}>
          <div className="close-account-btn-container">
            {this._isUnsubscribing() && this._renderIsUnsubscribingMessage()}
            {!this._isUnsubscribing() && (
              <ButtonContainer
                buttonWrapperClassName="cancel-nvstr-plan-btn"
                buttonClassName="link-btn link-btn-low-contrast"
                handleClick={this._handleClickUnsubscribeBtn}
                handleConfirmAction={this._handleUnsubscribeFromSubscriptionClick}
                handleCancelAction={this._handleCancelUnsubscribeFromSubscriptionClick}
                text={`Cancel ${PRODUCT_NAMES.Tornado} Subscription`}
                customConfirmationMessage={
                  <div className="nvstr-prime-cancel-confirmation">
                    <p>{'Are you sure you wish to cancel?'}</p>
                    <p>
                      {
                        'Your subscription will end at the end of the current billing cycle. You’ll then pay a commission for each trade placed after that.'
                      }
                    </p>
                  </div>
                }
                customConfirmationButtonText={['Yes', 'Nevermind']}
                needsConfirmation
              />
            )}
            {this._didUnsubscribe() && this._renderUnsubscribeSuccess()}
            {this._wasUnsubscribeError() && this._renderUnsubscribeError()}
          </div>
        </div>
      </div>
    );
  }

  _renderIsUnsubscribingMessage = () => {
    return (
      <div className={'is-unsubscribing'} style={{ textAlign: 'center' }}>
        <LoadingIcon icon="fading-3balls" size="small" style={{ marginRight: '8px' }} />
        <span>{'Cancelling...'}</span>
      </div>
    );
  };

  _renderUnsubscribeSuccess = () => {
    return (
      <div className={'unsubscribe-success'}>
        <InComponentSuccessBanner
          message={`${PRODUCT_NAMES.Tornado} Subscription was cancelled`}
          onDismiss={this.resetUnsubscribedStatus}
        />
      </div>
    );
  };

  _renderUnsubscribeError = () => {
    return (
      <div className={'unsubscribe-error'} style={{ textAlign: 'center' }}>
        <p className="error-message">{'Something went wrong. Please try again.'}</p>
        <ContactSupportIssue />
      </div>
    );
  };

  _handleClickUnsubscribeBtn = () => {
    this._logClickedUnsubscribe();
  };

  _handleUnsubscribeFromSubscriptionClick = () => {
    this.unsubscribeFromSubscription();
  };

  _handleCancelUnsubscribeFromSubscriptionClick = () => {
    this._logClickedCancelUnsubscribe();
  };

  _shouldShowUnsubscribedMessage = () => this.state._didUnsubscribe;

  _didUnsubscribe = () => this.state._didUnsubscribe;

  _isUnsubscribing = () => this.state._isUnsubscribing;

  _wasUnsubscribeError = () => this.state._wasUnsubscribeError;

  unsubscribeFromSubscription = () => {
    this._setIsUnsubscribing();
    cancelSubscription().then((response) => {
      this._clearIsUnsubscribing();
      if (response.ok) {
        this._showUnsubscribedMessage();
      } else {
        this._showUnsubscribeErrorMessage();
      }
    });
  };

  _showUnsubscribedMessage = () => {
    this.setState(() => ({
      _didUnsubscribe: true,
      _isUnsubscribing: false,
    }));
  };

  resetUnsubscribedStatus = () => {
    this.setState(() => ({
      _didUnsubscribe: false,
    }));
  };

  _setIsUnsubscribing = () => {
    this.setState(() => ({
      _isUnsubscribing: true,
    }));
  };

  _clearIsUnsubscribing = () => {
    this.setState(() => ({
      _isUnsubscribing: false,
    }));
  };

  _showUnsubscribeErrorMessage = () => {
    this.setState(() => ({
      _wasUnsubscribeError: true,
      _isUnsubscribing: false,
    }));
  };

  _logClickedCancelUnsubscribe = () => {
    const event = 'Canceled Unsubscribe from Tornado Subscription';
    const properties = {};
    logMetricsTrackingEvent(event, properties)();
  };

  _logClickedUnsubscribe = () => {
    const event = 'Clicked Unsubscribe from Tornado Subscription';
    const properties = {};
    logMetricsTrackingEvent(event, properties)();
  };
}

export default connect()(CancelSubscriptionForm);
