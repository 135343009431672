import React from 'react';
import CompTable from './CompTable';

const CompareContainer = (props) => {
  return (
    <div className="compare-container">
      <div className="compare-table-container">
        <CompTable securityId={props.securityId} />
      </div>
    </div>
  );
};

export default CompareContainer;
