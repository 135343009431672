import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  & > svg {
    width: 18px;
    height: 18px;
  }
  & g,
  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function PeopleIcon() {
  return (
    <Wrapper>
      <svg viewBox="0 0 18 12" fill="none">
        <path
          d="M6.5 7.458c-1.95 0-5.833.975-5.833 2.917v1.458h11.667v-1.458c0-1.942-3.884-2.917-5.834-2.917zm-3.883 2.709c.7-.484 2.392-1.042 3.883-1.042 1.492 0 3.184.558 3.884 1.042H2.617zM6.5 6a2.92 2.92 0 002.917-2.917A2.92 2.92 0 006.5.167a2.92 2.92 0 00-2.916 2.916A2.92 2.92 0 006.5 6zm0-4.167c.692 0 1.25.559 1.25 1.25 0 .692-.558 1.25-1.25 1.25-.691 0-1.25-.558-1.25-1.25 0-.691.559-1.25 1.25-1.25zm5.867 5.675c.967.7 1.633 1.634 1.633 2.867v1.458h3.334v-1.458c0-1.683-2.917-2.642-4.967-2.867zM11.5 6a2.92 2.92 0 002.917-2.917A2.92 2.92 0 0011.5.167c-.45 0-.866.108-1.25.291.525.742.834 1.65.834 2.625 0 .975-.309 1.884-.834 2.625.384.184.8.292 1.25.292z"
          fill="#1F1F1F"
        />
      </svg>
    </Wrapper>
  );
}

export default PeopleIcon;
