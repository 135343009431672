import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../actions/index';

import NvstrXSignup from './Signups/NvstrXSignup';

class Playground extends React.Component {
  render() {
    return <NvstrXSignup />;
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const component = connect(mapStateToProps, mapDispatchToProps)(Playground);

export default component;
