import { parseQueryString, createQueryString } from './routerHelpers';
import { logMetricsTrackingEvent } from '../actions/trackingActions';

export const switchToTab = (tab, navigate, config, pathname) => {
  if (!config || (config && !config.ignoreEventLog)) {
    const event = 'Clicked Security Panel Tab';
    const properties = { Tab: tab };
    logMetricsTrackingEvent(event, properties)();
  }

  const currentLocation = location;
  const query = parseQueryString(currentLocation.search);
  query.activeTab = tab;
  const newQueryString = createQueryString(query);

  const newLocation = (pathname ? pathname : currentLocation.pathname) + newQueryString;

  navigate(newLocation);
};

export const closePanel = (navigate) => {
  const currentLocation = location;
  const newLocation = currentLocation.pathname;
  navigate(newLocation);
};
