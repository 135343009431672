import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  & > svg {
    width: 18px;
    height: 18px;
  }
  & g,
  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function HistoryIcon() {
  return (
    <Wrapper>
      <svg viewBox="0 0 19 16" fill="none">
        <path
          d="M10.833.5a7.5 7.5 0 00-7.5 7.5h-2.5l3.242 3.242.058.116L7.5 8H5a5.83 5.83 0 015.833-5.833A5.829 5.829 0 0116.666 8a5.829 5.829 0 01-5.833 5.833 5.786 5.786 0 01-4.117-1.716L5.533 13.3a7.462 7.462 0 005.3 2.2 7.5 7.5 0 000-15zM10 4.667v4.166l3.541 2.1.642-1.066-2.933-1.742V4.667H10z"
          fill="#1F1F1F"
        />
      </svg>
    </Wrapper>
  );
}

export default HistoryIcon;
