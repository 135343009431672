import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
  background: ${({ theme }) => theme.themeColors.badge};
  color: ${({ theme }) => theme.themeColors.buttonText};
  * {
    color: ${({ theme }) => theme.themeColors.buttonText};
  }
`;
export const Counter = (props) => {
  const { count, className, showDotOnly } = props;

  if ((count === 0 || !count) && !showDotOnly) {
    return null;
  }

  const formattedCount = count > 9 ? '9+' : count;
  return (
    <Wrapper className={`counter notification-circle-color ${className || ''}`}>
      {!showDotOnly && <span className={`counter-number-container`}>{formattedCount}</span>}
    </Wrapper>
  );
};
