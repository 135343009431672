import React from 'react';
import styled from 'styled-components';
import { useIdea } from '../../hooks/ideas/useIdea';
import { useSortedOptimizedIdeasList } from '../../hooks/user/useCurrentIdeas';
import { useSecurity } from '../../hooks/securities/useSecurity';
import { formatSecurityPropertyValue } from '@src/helpers/securitiesHelpers';
import { Body5, Body6, CheckCircle, H5 } from '@src/main/lib/nvstr-common-ui.es';
import InfoIcon, { InfoIconWrapper } from '../../../containers/UI/InfoIcon';
import { usePositionAllocation } from '@src/main/hooks/portfolio/usePositionAllocation';
import {
  PositionAllocationPercentValue,
  PositionAllocationShareValue,
  PositionAllocationValue,
} from '../../components/text/ui/positions';
import { useOptimizedPosition } from '../../hooks/portfolio/useOptimizedPosition';
import { Container } from '../../components/ui';
import { ArrowCircle } from '../../assets/svgs/ArrowUpCircle';
import { FlatButton } from '../../components/buttons';
import { OptimizerAnalysisSummary } from '@src/containers/Optimizer/components/OptimizerAnalysisSummary';
import { useNavigateToSecurity } from '@src/main/hooks/securities/useNavigateToSecurity';
import { Target } from '@src/main/icons/svg/Target';
import { TrackingEvents } from '@src/utils/tracking/events';
import { logMetricsTrackingEvent, showComponentModal } from '@src/actions';
import { ORDER_TRANSACTION_TYPES } from '@src/main/constants/orders';
import { useDispatch } from 'react-redux';
import { COMPONENT_MODAL_TYPES } from '@src/main/constants';
import { useNavigate } from 'react-router';
import { ROUTES } from '@src/constants/paths';

const Wrapper = styled.div``;
const Header = styled.div`
  padding: 16px 24px;

  @media (max-width: 840px) {
    padding: 16px 16px;
  }
`;
const TableWrapper = styled.div``;
const HeadingRowWrapper = styled.div`
  box-sizing: border-box;

  display: grid;
  grid-template-columns: minmax(228px, 1fr) minmax(120px, 1fr) minmax(260px, 1fr);
  grid-column-gap: 8px;

  padding: 16px 24px;

  @media (max-width: 840px) {
    padding: 16px 16px;
  }
  @media (max-width: 692px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(40px, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;

    > div:first-child {
      grid-area: 1 / 1 / 2 / 3;
      padding-top: 0;
      padding-bottom: 0;
    }
    > div:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
    }
    > div:nth-child(3) {
      grid-area: 2 / 2 / 3 / 3;
    }
  }

  @media (max-width: 460px) {
    padding-left: 16px;
    padding-right: 8px;
  }
`;
const RowWrapper = styled.div`
  box-sizing: border-box;

  display: grid;
  grid-template-columns: minmax(228px, 1fr) minmax(120px, 1fr) minmax(260px, 1fr);
  grid-column-gap: 8px;
  padding: 16px 24px;
  border: 1px solid transparent;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};

  @media (max-width: 840px) {
    padding: 16px 16px;
  }
  @media (max-width: 692px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(40px, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;

    > div:first-child {
      grid-area: 1 / 1 / 2 / 3;
      padding-top: 0;
      padding-bottom: 0;
    }
    > div:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
    }
    > div:nth-child(3) {
      grid-area: 2 / 2 / 3 / 3;
    }
  }

  @media (max-width: 460px) {
    padding-left: 16px;
    padding-right: 8px;
  }
`;

const rowHeight = 100;
const NameWrapper = styled.div`
  color: ${({ theme }) => theme.themeColors.secondaryText};
`;
const IdeaReturnWrapper = styled.div``;
const SymbolWrapper = styled.div``;
const IdentifierCellWrapper = styled.div`
  height: ${rowHeight}px;
  cursor: pointer;
  padding: 0 8px 0 0;
  @media (max-width: 692px) {
    height: 40px;

    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    gap: 12px;

    ${SymbolWrapper} {
      min-width: 72px;
    }
    ${IdeaReturnWrapper} {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
    }
  }
  > div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
const ExpectedReturn = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 800;
`;
const PriceTargetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  svg {
    height: 15px;
    width: 15px;
    margin-left: 5px;

    path {
      fill: ${({ theme, type }) => theme.themeColors.negative};
    }
  }
`;
const Conviction = styled.div`
  font-size: 12px;
  line-height: 22px;
  font-weight: 200;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const PriceTarget = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
`;
const IdentifierCell = ({ securityId, ideaId }) => {
  const navigate = useNavigateToSecurity(securityId);
  const idea = useIdea(ideaId);
  const { security: ideaSecurity } = idea;
  const { name, symbol } = ideaSecurity;
  const { featured } = useSecurity(securityId);
  const { idea_type, conviction, price_target, expected_return, horizon, active } = idea || {};

  const priceTargetDisplay = formatSecurityPropertyValue(price_target, 'current_price');
  const expectedReturnDisplay = formatSecurityPropertyValue(expected_return, 'percentage');

  const handleClick = () => {
    navigate();
  };

  return (
    <IdentifierCellWrapper onClick={handleClick}>
      <SymbolWrapper>
        <Body6>{symbol?.toUpperCase()}</Body6>
      </SymbolWrapper>
      <NameWrapper top={4} style={{ display: 'none' }}>
        <Body5 isLowContrast thin>
          {name}
        </Body5>
      </NameWrapper>
      {featured && active ? (
        <IdeaReturnWrapper>
          {isNaN(price_target) || price_target === null ? (
            <ExpectedReturn>{expectedReturnDisplay}</ExpectedReturn>
          ) : (
            <PriceTargetWrapper>
              <PriceTarget>{'$' + priceTargetDisplay}</PriceTarget>
              <Target />
            </PriceTargetWrapper>
          )}
          <Conviction>{conviction.name} conviction</Conviction>
        </IdeaReturnWrapper>
      ) : null}
    </IdentifierCellWrapper>
  );
};

const PositionAllocationCellWrapper = styled.div`
  text-align: left;
`;
const AllocationDetailRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
const AllocationCell = ({ securityId }) => {
  const { shares, value, percentageOfPortfolio } = usePositionAllocation(securityId);

  if (isNaN(shares) || shares === 0) return <PositionAllocationCellWrapper />;
  return (
    <PositionAllocationCellWrapper>
      <PositionAllocationValue value={value} />
      <AllocationDetailRow>
        <PositionAllocationShareValue value={shares} />
        <div style={{ paddingLeft: '4px' }}>
          <PositionAllocationPercentValue value={percentageOfPortfolio * 100} />
        </div>
      </AllocationDetailRow>
    </PositionAllocationCellWrapper>
  );
};

const OptimizedPositionAllocationCellWrapper = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 200px;
`;
const OptimizedChangeIconWrapper = styled.div`
  padding-top: 2px;
  padding-right: 6px;
  line-height: 1;

  svg {
    height: 24px;
    width: 24px;

    path {
      fill: ${({ theme, delta }) =>
        delta === 0
          ? theme.themeColors.secondaryText
          : delta > 0
          ? theme.themeColors.positive
          : theme.themeColors.negative};
    }
  }
`;
const TradeActionWrapper = styled.div`
  padding-top: 4px;
  button {
    padding: 4px 12px;
  }
`;

const OptimizerPositionAnalysis = ({ securityId }) => {
  const data = useOptimizedPosition(securityId);

  if (!data) return null;

  const {
    avg_correl_group,
    avg_correl_summary,
    hist_volatility_group,
    hist_volatility_summary,
    proj_return_group,
    proj_return_summary,
  } = data;
  const correlation = {
    type: 'avg_correl',
    group: avg_correl_group,
    summary: avg_correl_summary,
  };
  const volatility = {
    type: 'hist_volatility',
    group: hist_volatility_group,
    summary: hist_volatility_summary,
  };
  const projectedReturn = {
    type: 'proj_return',
    group: proj_return_group,
    summary: proj_return_summary,
  };

  const optimizerAnalysisSummaryList = [projectedReturn, volatility, correlation];
  return (
    <OptimizerAnalysisSummary securityId={securityId} optimizerAnalysisSummaryList={optimizerAnalysisSummaryList} />
  );
};

const AnalysisCell = ({ securityId, ideaId }) => {
  return (
    <OptimizedPositionAllocationCellWrapper>
      <OptimizerPositionAnalysis securityId={securityId} />
    </OptimizedPositionAllocationCellWrapper>
  );
};

const OptimizedAllocationCell = ({ securityId, ideaId }) => {
  const dispatch = useDispatch();
  const { symbol } = useSecurity(securityId);
  const { active } = useIdea(ideaId);
  const currentPosition = usePositionAllocation(securityId);
  const optimizedPosition = useOptimizedPosition(securityId);

  const optimizedShares = optimizedPosition?.shares || 0;
  const optimizedValue = optimizedPosition?.value || 0;
  const optimizedAllocationPercent = optimizedPosition?.allocation_percent || 0;

  const currentPositionShares = currentPosition?.shares || 0;
  const currentPositionValue = currentPosition?.value || 0;

  const delta = optimizedShares - currentPositionShares;
  const deltaValue = optimizedValue - currentPositionValue;

  if (optimizedShares === 0 && currentPositionShares === 0) {
    return <OptimizedPositionAllocationCellWrapper />;
  }

  const handleTrade = () => {
    const properties = {
      'Security Id': securityId,
      'Security Symbol': symbol,
      'Order Creation Type': 'Single Optimization',
    };
    TrackingEvents.orders.PLACED_TRADE.send(properties);

    logMetricsTrackingEvent('Clicked Create Single Optimized Order', properties)();

    const transactionType = delta > 0 ? ORDER_TRANSACTION_TYPES.buy : ORDER_TRANSACTION_TYPES.sell;
    const orderShares = parseFloat(Math.abs(delta).toFixed(5)).toString(10);

    const m = {
      type: COMPONENT_MODAL_TYPES.order,
      props: {
        shares: orderShares,
        transactionType,
        securityId,
        isFromOptimizer: true,
      },
    };
    showComponentModal(m)(dispatch);
  };

  return (
    <OptimizedPositionAllocationCellWrapper>
      <Container row>
        <OptimizedChangeIconWrapper delta={delta}>
          {delta === 0 && Math.abs(optimizedShares) > 0 ? (
            <CheckCircle />
          ) : (
            <ArrowCircle up={delta > 0} down={delta < 0} />
          )}
        </OptimizedChangeIconWrapper>
        <Container>
          <PositionAllocationValue value={optimizedValue} />
          <AllocationDetailRow>
            <PositionAllocationShareValue value={optimizedShares} />
            <div style={{ paddingLeft: '4px' }}>
              <PositionAllocationPercentValue value={optimizedAllocationPercent} />
            </div>
          </AllocationDetailRow>
          {Math.abs(deltaValue) > 0 && (
            <TradeActionWrapper>
              <FlatButton onClick={handleTrade}>Trade</FlatButton>
            </TradeActionWrapper>
          )}
        </Container>
      </Container>
      <Container>{active && <OptimizerPositionAnalysis securityId={securityId} />}</Container>
    </OptimizedPositionAllocationCellWrapper>
  );
};

const Row = ({ ideaId }) => {
  const idea = useIdea(ideaId);
  return (
    <RowWrapper>
      <IdentifierCell securityId={idea.security.id} ideaId={ideaId} />
      <AllocationCell securityId={idea.security.id} ideaId={ideaId} />
      <AnalysisCell securityId={idea.security.id} ideaId={ideaId} />
    </RowWrapper>
  );
};

const Table = () => {
  const ideas = useSortedOptimizedIdeasList();

  return (
    <TableWrapper>
      <HeadingRowWrapper>
        <Container>
          <InfoIconWrapper>
            <Body5 isLowContrast thin>
              Expected Return
            </Body5>
            <InfoIcon word={'tooltip_expected_return'} style={{ top: '3px', right: '-18px' }} />
          </InfoIconWrapper>
        </Container>
        <div>
          <InfoIconWrapper>
            <Body5 isLowContrast thin>
              Current Allocation
            </Body5>
            <InfoIcon word={'tooltip_allocation'} style={{ top: '3px', right: '-18px' }} />
          </InfoIconWrapper>
        </div>
        <div>
          <InfoIconWrapper>
            <Body5 isLowContrast thin>
              Analysis
            </Body5>
            {/*<InfoIcon word={'tooltip_optimized_allocation'} style={{ top: '3px', right: '-18px' }} />*/}
          </InfoIconWrapper>
        </div>
      </HeadingRowWrapper>
      {ideas.map((i) => (
        <Row key={i.id} ideaId={i.id} />
      ))}
    </TableWrapper>
  );
};

const TradeAllButtonWrapper = styled.div`
  button {
    padding: 6px 16px;
  }
`;
export const PortfolioAnalysisTable = () => {
  const navigate = useNavigate();

  const handleTradeAll = () => {
    navigate(ROUTES.CREATE_OPTIMIZED_ORDER_CART.build());
  };
  return (
    <Wrapper>
      <Header>
        <Container row verticallyCenter spaceBetween>
          {/*<H5>Portfolio Analysis</H5>*/}
          {null && (
            <TradeAllButtonWrapper>
              <FlatButton onClick={handleTradeAll}>Trade All</FlatButton>
            </TradeAllButtonWrapper>
          )}
        </Container>
      </Header>
      <Table />
    </Wrapper>
  );
};
