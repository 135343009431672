import React from 'react';
import InviteFriends from './InviteFriends';
import ImportConnections from './ImportConnections';
import SearchUsers from './SearchUsers';
import { isProductionEnv } from '../../helpers/generalHelpers';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0 auto;
`;

const ConnectionsHeaderComponents = () => {
  const isDev = !isProductionEnv();
  return (
    <div className={`page`} style={{ marginBottom: 0 }}>
      <Wrapper className={`connection-header container`}>
        <InviteFriends inviteContext={'Connections Header'} />
        <ImportConnections />
        <SearchUsers />
      </Wrapper>
    </div>
  );
};

export default ConnectionsHeaderComponents;
