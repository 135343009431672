import { ADD_SYMBOL_LOOKUP } from '@src/constants';

const defaultState = {};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_SYMBOL_LOOKUP: {
      if (Array.isArray(action.payload)) {
        const l = {};
        action.payload.forEach((d) => {
          const [symbol, sid] = Object.entries(d)[0];
          l[symbol] = sid;
        });
        return {
          ...state,
          ...l,
        };
      } else {
        const d = action.payload;
        const [symbol, sid] = Object.entries(d)[0];

        if (state[symbol] === sid) return state;

        return {
          ...state,
          [symbol]: sid,
        };
      }
    }

    default:
      return state;
  }
}
