import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PRODUCT_NAME } from '@src/appConfig';
import { returnPathTo } from '../../constants/paths';
import * as Actions from '../../actions/index';
import { sendFacebookTrackingEvent } from '../../constants/facebookTracking';

import Page from '../../components/layout/Page';
import SubscriptionPaymentContainer from '../Payment/SubscriptionPaymentContainer';
import HeadingWithGradientLine from '../UI/HeadingWithGradientLine';
import PlanExplanationCard from './components/PlanExplanationCard';
import PageLoading from '../../components/PageLoading';

import RestrictHeight from '../ExtraFunctionalityComponents/RestrictHeight';

import { formatForDisplay } from '../../helpers/displayHelpers';
import { getSubscriptionConfig } from '../../actions';
import { throwError } from '../../helpers/devToolHelpers';
import {
  isCurrentUserOnboarding,
  isCurrentUserSubscriber,
  isEligibleForSubscription,
  returnCurrentUserThemeTestGroups,
  returnCurrentUserSubscriptionPlanName,
} from '../../helpers/currentUserHelpers';
import { getRandomInt, isUndefinedOrNull } from '../../helpers/generalHelpers';
import { withRouter } from '../../main/utils';

class NvstrXSignup extends React.Component {
  constructor() {
    super();
    this.state = {
      _isRewardsModalDismissed: false,

      _isInitializingPlanPrice: true,
      _isInitializingCurrentUserData: true,

      _subscriptionCost: null,
      _subscriptionDiscountedCost: null,
      _planId: null,

      _extraFeaturesABTestGroup: getRandomInt(2) === 1,
    };
  }

  componentDidMount() {
    this.getNvstrPrimePrice();
    this.refreshCurrentUserData().then((response) => {
      if (!response || !('user_id' in response)) {
        return this._setErrorWhileInitializing('Something went wrong, please try again.');
      } else {
        const currentUser = response;
        this._setInitCurrentUserDataComplete();
        if (!isEligibleForSubscription(currentUser)) {
          this.handleAlreadySubscriberRedirect();
        }
        if (isCurrentUserSubscriber(currentUser)) {
          this.handleAlreadySubscriberRedirect();
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this._isInitializing(prevState) && !this._isInitializing()) {
      this.onInitComplete();
    }
  }

  onInitComplete = () => {
    const currentUser = this._returnCurrentUser();
    if (isEligibleForSubscription(currentUser) && !isCurrentUserSubscriber(currentUser)) {
      this._logViewSubscriptionSelectionScreen();
    }
  };

  returnPlanCardContentHeight = () => (this.isInShowExtraFeaturesABTestGroup() ? '110px' : '95px');

  render() {
    if (this._isInitializing()) {
      return <PageLoading />;
    }
    return (
      <div className={`nvstr-x-sign-up-container`}>
        <Page>
          <div className="heading-container">
            <HeadingWithGradientLine text={'Choose a plan'} />
          </div>
          <div className="plans-container">
            <PlanExplanationCard
              contentHeight={this.returnPlanCardContentHeight()}
              additionalClassName="box-border-accent"
              btnClassName={'btn btn-small-tall btn-primary-color'}
              headingText={'Basic'}
              feature={'$4.50 per trade'}
              featurePrice={'No monthly fee'}
              {...(this.isInShowExtraFeaturesABTestGroup()
                ? {
                    featurePaymentMethod: <span>&nbsp;</span>,
                    featurePaymentMethodExplanation: <span>&nbsp;</span>,
                  }
                : {})}
              handleContinueClick={this.handleNvstrBasicClick}
            />
            <PlanExplanationCard
              contentHeight={this.returnPlanCardContentHeight()}
              additionalClassName="box-border-accent"
              btnClassName={'btn btn-small-tall btn-secondary-color'}
              headingText={this.returnNvstrPremiumSubscriptionName()}
              {...(this._isDiscountedFirstMonthAvailable()
                ? {
                    renderFeatures: () => (
                      <React.Fragment>
                        <p
                          className="plan-feature"
                          style={{
                            fontWeight: 500,
                          }}
                        >
                          Unlimited commission-free trades
                        </p>
                        <p
                          className="plan-feature-explanation"
                          style={{
                            fontWeight: 400,
                          }}
                        >
                          <span className="">{`${this.returnNvstrPrimeDiscountedDisplayPrice()}`}</span>
                          <span>&nbsp;</span>
                          <span>for first month</span>
                        </p>
                        <p
                          className="plan-feature-explanation secondary-text-color"
                          style={{
                            fontSize: '10px',
                            fontWeight: 400,
                            marginTop: '2px',
                          }}
                        >
                          {`Just ${this.returnNvstrPrimeDisplayPrice()} per month after`}
                        </p>
                        {this.isInShowExtraFeaturesABTestGroup() ? (
                          <React.Fragment>
                            <p className="plan-feature-payment secondary-text-color">
                              {'Pay by credit card, cancel anytime'}
                            </p>
                            <p className="plan-feature-payment-explanation secondary-text-color"></p>
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    ),
                  }
                : {
                    feature: `${this.returnNvstrPrimeDisplayPrice()} per month`,
                    featurePrice: 'Unlimited commission-free trades',
                    ...(this.isInShowExtraFeaturesABTestGroup()
                      ? {
                          featurePaymentMethod: 'Pay by credit card, cancel anytime',
                          featurePaymentMethodExplanation: '',
                        }
                      : {}),
                  })}
              handleContinueClick={this.handleNvstrOneClick}
            />
          </div>
        </Page>
      </div>
    );
  }

  isInShowExtraFeaturesABTestGroup = () => this.state._extraFeaturesABTestGroup;

  renderSubscriptionPaymentModal = () => {
    const modal = {
      description: '<credit card entry form>',
      className: 'subscription-payment-modal',
      contentComponent: (
        <RestrictHeight heightOffsetBuffer={20} screenHeight>
          <SubscriptionPaymentContainer
            handleContinue={this.handlePaymentComplete}
            handleCancel={this.handleCancelPayment}
            onPaymentComplete={this.onPaymentComplete}
            charges={[
              {
                title: this._returnSubscriptionChargeTitle(),
                description: 'Monthly',
                value: this._returnSubscriptionCost(),
                discountedValue: this._isDiscountedFirstMonthAvailable()
                  ? this._returnSubscriptionDiscountedCost()
                  : null,
              },
            ]}
          />
        </RestrictHeight>
      ),
      dismissable: false,
    };
    this.props.actions.showModal(modal);
  };

  handleNvstrOneClick = () => {
    this.renderSubscriptionPaymentModal();
    this._logChoosePlan('Subscription');
  };

  onPaymentComplete = () => {
    this._setCurrentUserSubscriber();
    this._logSubmitPaymentComplete();
  };

  _setCurrentUserSubscriber = () => {
    this.props.actions.setCurrentUserSubscriber();
  };

  _returnSubscriptionChargeTitle = () => `${PRODUCT_NAME} ${this.returnNvstrPremiumSubscriptionName()}`;

  handleDismissRedeemingRewardsModal = () => {
    this._setDismissRewardsModal();
    this._logDismissRewardsModal();
  };

  handleNvstrBasicClick = () => {
    this._logChoosePlan('Basic');
    this.props.navigate(returnPathTo('funding'));
  };

  handleCancelPayment = () => {
    this.props.actions.hideModal();
    this._logCancelSubscriptionPayment();
  };

  handlePaymentComplete = () => {
    this.props.actions.hideModal();
    this.props.navigate(returnPathTo('funding'));
  };

  handleAlreadySubscriberRedirect = () => {
    this._isOnboarding()
      ? this.props.navigate(returnPathTo('funding'))
      : this.props.navigate(returnPathTo('dashboard'));
  };

  _isInitializing = (state = this.state) =>
    this._isInitializingPlanPrice(state) || this._isInitializingCurrentUserData(state);

  _isInitializingPlanPrice = (state = this.state) => state._isInitializingPlanPrice;

  _isInitializingCurrentUserData = (state = this.state) => state._isInitializingCurrentUserData;

  _returnSubscriptionCost = () => this.state._subscriptionCost;

  _returnSubscriptionDiscountedCost = () => this.state._subscriptionDiscountedCost;

  _isDiscountedFirstMonthAvailable = () => !isUndefinedOrNull(this._returnSubscriptionDiscountedCost());

  _returnSubscriptionPlanId = () => this.state._planId;

  _shouldShowOnboardingRewardRedemptionModal = () =>
    this._isOnboarding() && this._hasRewardAwaitingRedemption() && !this._isRedeemingRewardsModalDismissed();

  _isOnboarding = () => isCurrentUserOnboarding(this._returnCurrentUser());

  _isCurrentUserSubscriber = () => isCurrentUserSubscriber(this._returnCurrentUser());

  _returnCurrentUser = () => this.props.currentUser;

  _hasRewardAwaitingRedemption = () => this._returnCurrentUser().referral_rewards_not_redeemed_count > 0;

  _isRedeemingRewardsModalDismissed = () => this.state._isRewardsModalDismissed;

  _setDismissRewardsModal = () => {
    this.setState(() => ({
      _isRewardsModalDismissed: true,
    }));
  };

  returnNvstrPremiumSubscriptionName = () => returnCurrentUserSubscriptionPlanName(this._returnCurrentUser());

  returnNvstrPrimeDisplayPrice = () => formatForDisplay(this._returnSubscriptionCost(), 'price');

  returnNvstrPrimeDiscountedDisplayPrice = () => formatForDisplay(this._returnSubscriptionDiscountedCost(), 'price');

  getNvstrPrimePrice = () => {
    getSubscriptionConfig().then((response) => {
      if (response.ok) {
        this._handleInitComplete(response);
      } else {
        this._setErrorWhileInitializing(response.error);
      }
    });
  };

  _handleInitComplete = (response) => {
    if (!response.available_plans) {
      this._setErrorWhileInitializing('Something went wrong, please try again.');
      return throwError('Received invalid subscription config', true, {
        response,
      });
    }
    const plans = response.available_plans;
    const plan = plans[0];
    const planId = plan.plan_id;
    const subCost = plan.subscription_cost;
    const subDiscountedCost = plan.first_month_cost;
    this._setPlanId(planId);
    this._setSubscriptionCost(subCost);
    this._setSubscriptionDiscountedCost(subDiscountedCost);
    this._setInitPlanPriceComplete();
  };

  _setInitPlanPriceComplete = () => {
    this.setState(() => ({
      _isInitializingPlanPrice: false,
    }));
  };

  _setInitCurrentUserDataComplete = () => {
    this.setState(() => ({
      _isInitializingCurrentUserData: false,
    }));
  };

  _setErrorWhileInitializing = (error) => {
    this._logErrorDuringInit(error);
    this.setState(() => ({
      _initError: error,
    }));
  };

  _setSubscriptionDiscountedCost = (cost) => {
    this.setState(() => ({
      _subscriptionDiscountedCost: cost,
    }));
  };

  _setSubscriptionCost = (cost) => {
    this.setState(() => ({
      _subscriptionCost: cost,
    }));
  };

  _setPlanId = (planId) => {
    this.setState(() => ({
      _planId: planId,
    }));
  };

  refreshCurrentUserData = () => this.props.actions.fetchCurrentUserQuickInfo();

  _logErrorDuringInit = (error) => {
    const event = 'Error occurred during Tornado Subscription Decision Initial Load';
    const properties = {
      Error: error,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logDismissRewardsModal = () => {
    const event = 'Dismiss Rewards Modal';
    const properties = {
      Context: 'Onboarding',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logCancelSubscriptionPayment = () => {
    const event = 'Cancel Subscription Signup';
    const properties = {
      'Subscription Name': this.returnNvstrPremiumSubscriptionName(),
      'Plan ID': this._returnSubscriptionPlanId(),
      'Shown Extra Payment Details': this.isInShowExtraFeaturesABTestGroup(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logSubmitPaymentComplete = () => {
    const event = 'Completed Payment';
    const properties = {
      'Plan ID': this._returnSubscriptionPlanId(),
      'Subscription Name': this.returnNvstrPremiumSubscriptionName(),
      'Shown Extra Payment Details': this.isInShowExtraFeaturesABTestGroup(),
    };
    sendFacebookTrackingEvent('Completed Initial Subscription Payment', {
      themeTestGroups: returnCurrentUserThemeTestGroups(this._returnCurrentUser()),
      subscriptionType: this._returnSubscriptionPlanId(),
      subscriptionValue: this._returnSubscriptionCost(),
    });
    this.props.actions.logMetricsTrackingEvent(event, properties);
    this._logChoosePlan('Subscription');
  };

  _logViewSubscriptionSelectionScreen = () => {
    const event = 'Viewed Subscription Selection';
    const properties = {
      'Subscription Name': this.returnNvstrPremiumSubscriptionName(),
      'Plan ID': this._returnSubscriptionPlanId(),
      'Shown Extra Payment Details': this.isInShowExtraFeaturesABTestGroup(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logChoosePlan = (planChosen) => {
    const event = 'Selected Subscription Plan';
    const properties = {
      'Plan Chosen': planChosen,
      'Subscription Name': this.returnNvstrPremiumSubscriptionName(),
      'Shown Extra Payment Details': this.isInShowExtraFeaturesABTestGroup(),
      'Plan ID': this._returnSubscriptionPlanId(),
    };
    sendFacebookTrackingEvent('Select Subscription Plan', {
      themeTestGroups: returnCurrentUserThemeTestGroups(this._returnCurrentUser()),
      subscriptionType: planChosen === 'Basic' ? 'Basic' : this._returnSubscriptionPlanId(),
      subscriptionValue: planChosen === 'Subscription' ? this._returnSubscriptionCost() : 0,
    });
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(NvstrXSignup);

export default withRouter(connectedComponent);
