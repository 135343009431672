import {
  FETCH_NEW_NOTIFICATIONS,
  ADD_TO_NEW_NOTIFICATIONS_LIST,
  ADD_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS,
  REFRESH_NOTIFICATIONS,
  UPDATE_NEW_NOTIFICATIONS_COUNT,
  CLEAR_NOTIFICATIONS,
  CLEAR_NEW_NOTIFICATIONS,
  CLEAR_NEW_NOTIFICATIONS_ALERT_EXPAND,
} from '../constants';

const defaultState = {
  autoexpand: false,

  loadingCount: true,
  loading: true,

  unseenCount: 0,

  notificationsList: [], // notifications feed/ page notifications
  page: null,
  batch_index: null,
  didLastReturnNotifications: false,
  didReturnNotifications: false,
};

const filterNotificationPayloadForDuplicateThoughtNotifications = (notifications, state) => {
  const thoughtNotifs = notifications.filter((notifData) => notifData.type === 'thought');
  const notifThoughtIds = thoughtNotifs.map((notifData) => notifData.data.thought.id);

  const notifsInStore = state.notificationsList;
  const thoughtNotifsInStore = notifsInStore.filter((notifData) => notifData.type === 'thought');
  const notifsInStoreThoughtIds = thoughtNotifsInStore.map((notifData) => notifData.data.thought.id);

  const notifsFilteredForDuplicateThoughts = notifications.filter(
    (notifData) => notifData.type !== 'thought' || !notifsInStoreThoughtIds.includes(notifData.data.thought.id)
  );
  return notifsFilteredForDuplicateThoughts;
};

export default function notificationsReducer(state = defaultState, action) {
  let count, notifications, autoexpand, page, batch_index, didReturnNotifications, forceRefresh;
  switch (action.type) {
    case FETCH_NEW_NOTIFICATIONS:
      return { ...state };

    case UPDATE_NEW_NOTIFICATIONS_COUNT:
      count = action.payload.count || 0;
      autoexpand = action.payload.autoexpand;

      return {
        ...state,
        loadingCount: false,
        autoexpand,
        unseenCount: count,
      };

    case ADD_NOTIFICATIONS:
      notifications =
        filterNotificationPayloadForDuplicateThoughtNotifications(action.payload.notifications, state) || [];
      page = action.payload.page;
      batch_index = action.payload.batch_index;
      didReturnNotifications = action.payload.didReturnNotifications;

      if (state.page !== page - 1 && page !== 1) {
        return state;
      }
      return {
        ...state,
        loading: false,

        notificationsList: [...state.notificationsList, ...notifications],
        page,
        batch_index,
        didLastReturnNotifications: state.didReturnNotifications,
        didReturnNotifications,
      };

    case UPDATE_NOTIFICATIONS:
      notifications = action.payload.notifications || [];
      page = action.payload.page;
      batch_index = action.payload.batch_index;
      didReturnNotifications = action.payload.didReturnNotifications;
      forceRefresh = action.payload.forceRefresh;
      return {
        ...state,
        loading: false,

        notificationsList: [...notifications],
        page,
        batch_index,
        didLastReturnNotifications: true,
        didReturnNotifications,
      };

    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
        notificationsList: [],
        page: null,
        batch_index: null,
        didLastReturnNotifications: false,
        didReturnNotifications: false,
      };

    case CLEAR_NEW_NOTIFICATIONS:
      return {
        ...state,
        autoexpand: false,
        unseenCount: 0,
      };

    case CLEAR_NEW_NOTIFICATIONS_ALERT_EXPAND:
      return {
        ...state,
        autoexpand: false,
      };

    default:
      return state;
  }
}
