import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';
import { VERSION } from '../../config';

const URL = `${BASEURL}/api/v1`;

export const learnAdapter = {
  fetchCompletedLearnLessons: () => {
    return sendRequest('get', [`${URL}/completed_lessons`]);
  },
  fetchAllLearnLessons: () => {
    return sendRequest('get', [`${URL}/lessons`]);
  },
  fetchNextLearnLesson: () => {
    const options = { ignoreAuth: true };
    return sendRequest('get', [`${URL}/lessons/next?platform=web&appVersion=${VERSION}`], options);
  },
  postLearnLessonProgress: ({ data }) => {
    // const {
    //   lesson_id,
    //   page_number,
    //   completed,
    // } = data;
    const options = { ignoreAuth: true };
    const requestConfig = data;
    return sendRequest('post', [`${URL}/lesson_progress`, requestConfig], options);
  },
  postRecurringInvestmentChartData: ({ data }) => {
    const requestConfig = data;
    return sendRequest('post', [`${URL}/lesson_drop_downs_record`, requestConfig]);
  },
  getLesson: (id) => {
    const requestConfig = { lesson_id: id };
    return sendRequest('get', [`${URL}/full_lesson_get`, requestConfig]);
  },
};
