import React from 'react';
import { defineFontSettings, FloatingLabelDropdown } from '@src/main/lib/nvstr-common-ui.es';
import { colorPalette } from '@src/main/lib/nvstr-utils.es';
import { Text } from '../../../../../components/text';
import { Spacing } from '../../../../../components/layout';
import { formatDataValue } from '../../../../../../helpers/securitiesHelpers';
import { ReturnsChart } from '../../../../charts/ReturnsChart';
import { DepositButton } from '../../../../buttons';
import { FlatButton } from '../../../../../components/buttons';
import { customParseFloat } from '../../../../../../helpers/numberHelpers';
import { doXTimes } from '../../../../../../helpers/usefulFuncs';
import styled from 'styled-components';
import { calculateRecurringInvestmentEndingValue } from '../../../../../utils';
import { TrackingEvents } from '../../../../../../utils/tracking/events';
import { postRecurringInvestmentChartData } from '../../../../../../actions';
import Space from '../../../../../../components/UI/Space';
import {
  startingInvestmentItems,
  recurringInvestmentItems,
  // recurringRateItems,
  horizonYearItems,
  returnRateItems,
} from '../../../../../constants';

const DepositButtonWrapper = styled.div`
  padding: 24px 0 8px 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const createDataset = (data) => {
  return {
    label: 'Compounded Value',
    data,

    fill: false,
    borderColor: colorPalette.primary.charcoal,
    borderCapStyle: 'butt',
    pointBorderColor: 'rgba(0,0,0, 0)',
    pointBackgroundColor: 'rgba(0,0,0, 0)',
    pointBorderWidth: 1,
    pointHoverRadius: 3,
    pointHoverBackgroundColor: colorPalette.primary.charcoal,
    pointHoverBorderColor: colorPalette.primary.charcoal,
    pointHoverBorderWidth: 2,
    pointHitRadius: 10,
  };
};

const calculateChartData = ({ startingValue, recurringDepositAmount, recurringRate, horizonYears, returnRate }) => {
  const dataset = {
    labels: [0],
    data: [startingValue],
  };

  const { totalValue, timeStepCalculator } = calculateRecurringInvestmentEndingValue({
    startingValue,
    recurringDepositAmount,
    recurringRate,
    horizonYears,
    returnRate,
  });

  const createDataPoints = (i) => {
    const year = i + 1;
    const endingValue = parseFloat(timeStepCalculator(year).toFixed(2));

    dataset.labels.push(year);
    dataset.data.push(endingValue);
  };
  doXTimes(horizonYears, createDataPoints);

  return { ...dataset, totalValue: totalValue };
};

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InputWrapper = styled.div`
  padding: 0 0 3px 0;

  select {
    border: 1px solid ${colorPalette.primary.charcoal} !important;
    color: ${colorPalette.primary.charcoal} !important;
    border-radius: 6px;
    padding: 4px;
    height: 32px;
    width: ${({ size }) => (size === 'small' ? '64px' : size === 'medium' ? '80px' : '104px')};
    background: transparent !important;
    -webkit-appearance: auto;
    margin: 0 auto;

    option {
      background: transparent !important;
      color: ${colorPalette.primary.charcoal} !important;
    }
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 8px;
`;

const TextWrapper = styled.div`
  line-height: 32px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const fontSettings = defineFontSettings(
  {
    fontSize: '16px',
    lineHeight: '28px',
  },
  {
    fontSize: '20px',
    lineHeight: '28px',
  }
);
const totalValueFontSettings = defineFontSettings(
  {
    fontSize: '24px',
    lineHeight: '28px',
  },
  {
    fontSize: '28px',
    lineHeight: '28px',
  }
);

export const RecurringInvestmentReturns = ({
  lessonId,
  defaultInputs,
  inRecurringDepositTestGroup,
  showDepositCompletedModal,
  handleDepositComplete,
  handleDepositSkip,
}) => {
  const [startingValue, setStartingValue] = React.useState(defaultInputs.startingValue);
  const [recurringDepositAmount, setRecurringDepositAmount] = React.useState(defaultInputs.recurringDepositAmount);
  const [recurringRate, setRecurringRate] = React.useState(defaultInputs.recurringRate);
  const [horizonYears, setHorizonYears] = React.useState(defaultInputs.horizonYears);
  const [returnRate, setReturnRate] = React.useState(defaultInputs.returnRate);

  const inputs = React.useMemo(
    () => {
      return {
        startingValue,
        recurringDepositAmount,
        recurringRate,
        horizonYears,
        returnRate,
      };
    },
    [startingValue, recurringDepositAmount, recurringRate, horizonYears, returnRate]
  );

  const [totalValue, setTotalValue] = React.useState(0);
  const [dataset, setDataset] = React.useState(null);

  const onContinueClick = () => {
    handleDepositSkip();
  };

  const onStartingValueChange = (n) => (v) => {
    const formattedValue = parseInt(v, 10);
    setStartingValue(formattedValue);
    TrackingEvents.learn.RECURRING_INVESTMENT_TOOL_FORM_INPUT_CHANGE.send({
      'Initial Investment Value': formattedValue,
      'Input Name': 'Initial Investment Value',
      'Input Value': formattedValue,
    });
  };

  const onRecurringRateChange = (n) => (v) => {
    setRecurringRate(v);
    TrackingEvents.learn.RECURRING_INVESTMENT_TOOL_FORM_INPUT_CHANGE.send({
      'Recurring Rate': v,
      'Input Name': 'Recurring Rate',
      'Input Value': v,
    });
  };

  const onRecurringDepositAmountChange = (n) => (v) => {
    const formattedValue = parseInt(v, 10);

    setRecurringDepositAmount(formattedValue);
    TrackingEvents.learn.RECURRING_INVESTMENT_TOOL_FORM_INPUT_CHANGE.send({
      'Recurring Deposit Amount': formattedValue,
      'Input Name': 'Recurring Deposit Amount',
      'Input Value': formattedValue,
    });
  };

  const onHorizonYearsChange = (n) => (v) => {
    const formattedValue = parseInt(v, 10);

    setHorizonYears(formattedValue);
    TrackingEvents.learn.RECURRING_INVESTMENT_TOOL_FORM_INPUT_CHANGE.send({
      'Horizon Years': formattedValue,
      'Input Name': 'Horizon Years',
      'Input Value': formattedValue,
    });
  };

  const onReturnRateChange = (n) => (v) => {
    const formattedValue = parseFloat(v);

    setReturnRate(formattedValue);
    TrackingEvents.learn.RECURRING_INVESTMENT_TOOL_FORM_INPUT_CHANGE.send({
      'Return Rate': formattedValue,
      'Input Name': 'Return Rate',
      'Input Value': formattedValue,
    });
  };

  React.useEffect(
    () => {
      const { labels, data, totalValue } = calculateChartData(inputs);
      setTotalValue(totalValue);
      const dataset = createDataset(data);
      setDataset({
        labels,
        datasets: [dataset],
      });
    },
    [inputs]
  );

  React.useEffect(
    () => {
      TrackingEvents.learn.RECURRING_INVESTMENT_TOOL_FORM_UPDATE.send({
        'Initial Investment Value': startingValue,
        'Recurring Deposit Amount': recurringDepositAmount,
        'Recurring Rate': recurringRate,
        'Horizon Years': horizonYears,
        'Return Rate': returnRate,
      });

      const form = {
        starting_value: startingValue,
        recurring_deposit_amount: recurringDepositAmount,
        horizon_years: horizonYears,
        return_rate: returnRate,
      };
      postRecurringInvestmentChartData(form);
    },
    [startingValue, recurringDepositAmount, recurringRate, horizonYears, returnRate]
  );

  const textColor = colorPalette.primary.charcoal;

  const usingRecurringSelector = false;
  return (
    <div>
      <BodyWrapper>
        <TextWrapper>
          <Text fontSettings={fontSettings} color={textColor}>
            An initial investment of
            <Space />
          </Text>
        </TextWrapper>
        <InputWrapper>
          <FloatingLabelDropdown
            label={null}
            items={startingInvestmentItems}
            value={startingValue}
            onChange={onStartingValueChange}
            name="start"
            error={null}
          />
        </InputWrapper>
        <TextWrapper>
          <Text fontSettings={fontSettings} color={textColor}>
            <Space />
            and a<Space />
          </Text>
        </TextWrapper>
        <TextWrapper>
          <Text fontSettings={fontSettings} color={textColor}>
            recurring
            <Space />
          </Text>
        </TextWrapper>
        {usingRecurringSelector ? (
          <InputWrapper>
            <FloatingLabelDropdown
              label={null}
              items={recurringRateItems}
              value={recurringRate}
              onChange={onRecurringRateChange}
              name="start"
              error={null}
            />
          </InputWrapper>
        ) : (
          <TextWrapper>
            <Text fontSettings={fontSettings} color={textColor}>
              monthly
              <Space />
            </Text>
          </TextWrapper>
        )}

        <TextWrapper>
          <Row>
            <Text fontSettings={fontSettings} color={textColor}>
              investment of
              <Space />
            </Text>
            <InputWrapper size="medium">
              <FloatingLabelDropdown
                label={null}
                items={recurringInvestmentItems}
                value={recurringDepositAmount}
                onChange={onRecurringDepositAmountChange}
                name="start"
                error={null}
              />
            </InputWrapper>
          </Row>
        </TextWrapper>

        <TextWrapper>
          <Text fontSettings={fontSettings} color={textColor}>
            <Space />
            can grow to
            <Space />
          </Text>
        </TextWrapper>

        <TextWrapper>
          <Text fontSettings={totalValueFontSettings} color={textColor} bold>
            ${formatDataValue(totalValue, 'priceFloat')}
          </Text>
        </TextWrapper>

        <TextWrapper>
          <Text fontSettings={fontSettings} color={textColor}>
            <Space />
            after
            <Space />
          </Text>
        </TextWrapper>

        <FlexContainer>
          <InputWrapper size="small">
            <FloatingLabelDropdown
              label={null}
              items={horizonYearItems}
              value={horizonYears}
              onChange={onHorizonYearsChange}
              name="start"
              error={null}
            />
          </InputWrapper>

          <TextWrapper>
            <Text fontSettings={fontSettings} color={textColor}>
              <Space />
              years
              <Space />
            </Text>
          </TextWrapper>
        </FlexContainer>

        <TextWrapper>
          <Text fontSettings={fontSettings} color={textColor}>
            with an
            <Space />
          </Text>
        </TextWrapper>
        <TextWrapper>
          <Text fontSettings={fontSettings} color={textColor}>
            average yearly
            <Space />
          </Text>
        </TextWrapper>

        <Row>
          <TextWrapper>
            <Text fontSettings={fontSettings} color={textColor}>
              return of
              <Space />
            </Text>
          </TextWrapper>

          <InputWrapper size="small">
            <FloatingLabelDropdown
              label={null}
              items={returnRateItems}
              value={returnRate}
              onChange={onReturnRateChange}
              name="start"
              error={null}
            />
          </InputWrapper>
        </Row>
      </BodyWrapper>

      <Spacing top={16}>{dataset && <ReturnsChart dataset={dataset} />}</Spacing>

      {null && (
        <DepositButtonWrapper>
          <DepositButton
            amount={startingValue}
            trackingMetadata={{ lessonId }}
            recurringAmount={inRecurringDepositTestGroup ? recurringDepositAmount : null}
            onDepositComplete={handleDepositComplete}
            color={colorPalette.primary.charcoal}
            textColor={colorPalette.primary.oak}
            buttonProps={{ overrideContext: 'Lesson Completed' }}
            fullWidth
            allowEmailAuth
          />
        </DepositButtonWrapper>
      )}
      {/*<div style={{ padding: '0 0', textAlign: 'center' }}>*/}
      {/*  <FlatButton transparent height={20} onClick={onContinueClick} fullWidth color={textColor}>*/}
      {/*    CONTINUE*/}
      {/*  </FlatButton>*/}
      {/*</div>*/}
    </div>
  );
};
