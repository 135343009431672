import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import SectionHeading from './components/SectionHeading';
import Button from '../../components/buttons/Button';
import TextField from '../../components/form/TextField';
import RenderAfterDelay from '../ExtraFunctionalityComponents/RenderAfterDelay';

import { createInputComponent, handleFocus, handleBlur, handleChange } from '../../helpers/formHelpers';
import { FlatButton } from '@src/main/components/buttons';
import { Container } from '@src/main/components/ui';

class EditPasswordForm extends React.Component {
  constructor() {
    super();
    this.state = {
      _isSubmitting: false,

      formData: {
        current_password: '',
        password: '',
      },
      focusedField: null,
      focusNote: {},
      errors: {},

      inputs: [
        {
          type: TextField,
          typeName: 'TextField',
          name: 'current_password',
          label: 'Current Password',
          required: true,
          props: {
            customWrapperClass: 'password-field',
            inputType: 'password',
            autocompleteValue: 'new-password',
          },
        },
        {
          type: TextField,
          typeName: 'TextField',
          name: 'password',
          label: 'New Password',
          required: true,
          props: {
            customWrapperClass: 'password-field',
            inputType: 'password',
            autocompleteValue: 'new-password',
          },
        },
      ],
    };
  }

  render() {
    return (
      <div className={`edit-password-form-container`}>
        <Container bottom={24}>
          <SectionHeading headingText={'Change Password'} />
        </Container>
        <div className={`form-input-container`}>
          {this._createInputElement(this._returnFormInputs()[0])}
          {this._createInputElement(this._returnFormInputs()[1])}
          {this._isValidToSubmit() && (
            <div className={`change-password-submit-button-container`}>
              {this.isSavingFormData() ? (
                <FlatButton onClick={() => false}>Submitting</FlatButton>
              ) : (
                <FlatButton onClick={this.handleChangePasswordSubmitClick}>Submit</FlatButton>
              )}
            </div>
          )}
          {
            <RenderAfterDelay shouldRender={this.isSavingFormData()} timeDelayToRender={5} minTimeLengthToRender={900}>
              <div className={`saving-message secondary-text-color`}>Submitting...</div>
            </RenderAfterDelay>
          }
        </div>
      </div>
    );
  }

  handleChangePasswordSubmitClick = () => {
    this.submitNewPassword(
      this.getCurrentFormValueForCurrentPasswordField(),
      this.getCurrentFormValueForNewPasswordField()
    );
  };

  submitNewPassword = (currentPassword, newPassword) => {
    this._startSubmitting();
    this.props.actions.changePassword(currentPassword, newPassword).then((statusObj) => {
      this.clearPassfordField();
      this._stopSubmitting();
      const wasSuccess = statusObj.success;
      if (wasSuccess) {
        this._showPasswordChangedSuccessMessage();
      } else {
        const errorMessage = statusObj.error;
        this._showSaveFailedErrorMessage(errorMessage);
      }
    });
  };

  getCurrentFormValueForField = (fieldName) => this.state.formData[fieldName];

  getCurrentFormValueForCurrentPasswordField = () => this.getCurrentFormValueForField('current_password') || '';

  getCurrentFormValueForNewPasswordField = () => this.getCurrentFormValueForField('password') || '';

  isSavingFormData = () => this.state._isSubmitting;

  _clearErrors = () => {
    this.setState(() => ({
      errors: {},
    }));
  };

  _createFormError = (message) => {
    this.setState(() => ({
      errors: {
        password: message,
      },
    }));
  };

  _startSubmitting = () => {
    this.setState(() => ({
      _isSubmitting: true,
    }));
  };

  _stopSubmitting = () => {
    this.setState(() => ({
      _isSubmitting: false,
    }));
  };

  clearPassfordField = () => {
    this.setState(() => ({
      formData: {
        password: '',
      },
    }));
  };

  _showPasswordChangedSuccessMessage = () => {
    const message = 'Password was updated successfully';
    const contentComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {message}
      </div>
    );
    this.props.actions.showModal({
      contentComponent,
      size: 'wide',
      dismissable: true,
    });
  };

  _showSaveFailedErrorMessage = (errorMessage) => {
    const message = errorMessage || 'Something went wrong. Please try again.';
    const contentComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {message}
      </div>
    );
    this.props.actions.showModal({
      contentComponent,
      size: 'wide',
      dismissable: true,
    });
  };

  _isCurrentPasswordEntered = () => this.getCurrentFormValueForCurrentPasswordField().length > 0;

  _isNewPasswordEntered = () => this.getCurrentFormValueForNewPasswordField().length > 0;

  _isValidToSubmit = () => this._isCurrentPasswordEntered() && this._isNewPasswordEntered();

  _returnFormInputs = () => this.state.inputs;

  _createInputElement = (input) => {
    return createInputComponent.call(this, input, this._returnInputDefaultProps.call(this));
  };

  _returnInputDefaultProps() {
    return {
      handleFocus: handleFocus.bind(this),
      handleBlur: handleBlur.bind(this),
      handleChange: handleChange.bind(this),
      isFocused: function (name) {
        return this.state.focusedField === name;
      }.bind(this),
      getFocusNote: function (name) {
        if ('focusNote' in this.state) {
          return this.state.focusNote[name];
        }
      }.bind(this),
      getErrorMessage: function (name) {
        return this.state.errors[name];
      }.bind(this),
      getValue: function (name) {
        return this.state.formData[name];
      }.bind(this),
    };
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPasswordForm);
