import React from 'react';
import { useSelector } from 'react-redux';

export const FUNDAMENTAL_SECURITY_DATA_KEYS = {
  projectedRevenueGrowth: 'Projected Revenue Growth',
  forwardPE: 'Forward P/E',
};

export const useFundamentalData = (securityId) => {
  const store = useSelector((state) => state.fundamentalData.securities[securityId]);
  return store;
};
