import React from 'react';
import { useSelector } from 'react-redux';
import { createTimeInstance, formatLocalizedDateTime } from '../../../helpers/timeHelpers';
import { MARKET_STATES } from '../../../constants';

const debugFormat = (time) => formatLocalizedDateTime('asOf', createTimeInstance(time));

const actions = {
  update: 'u',
};

const initialState = {
  isOpen: null,

  prevCloseTime: null, // NOTE: API will return the next day if the market is open
  nextOpenTime: null, // NOTE: API will return the next day if the market is open
  nextCloseTime: null, // NOTE: API will return the next day if the market is open

  isMarketOpeningToday: null,
};

function reducer(state, action) {
  switch (action.type) {
    case actions.update: {
      const p = action.payload;
      if (
        state.isOpen !== p.isOpen ||
        state.nextOpenTime !== p.nextOpenTime ||
        state.prevCloseTime !== p.prevCloseTime ||
        state.nextCloseTime !== p.nextCloseTime ||
        state.isMarketOpeningToday !== p.isMarketOpeningToday
      ) {
        return {
          ...p,
        };
      }
      return state;
    }

    default: {
      return state;
    }
  }
}

const calcMarketState = (marketHoursData) => {
  const { prev_market_close_timestamp, next_market_open_timestamp, next_market_close_timestamp } = marketHoursData;

  // debug open with below:
  // const nextOpenTimeMS = createTimeInstance(nextOpenTime * 1000)
  //   .subtract(30, 'minutes')
  //   .valueOf();

  // NOTE: API will return the next day if the market is open
  const prevCloseTimeMS = prev_market_close_timestamp;
  const nextOpenTimeMS = next_market_open_timestamp;
  const nextCloseTimeMS = next_market_close_timestamp;
  // NOTE: API will return the next day if the market is open

  const currentTime = createTimeInstance().valueOf();
  const isOpenBasedOnNextOpenTime = currentTime >= nextOpenTimeMS;
  const isMarketOpen =
    formatLocalizedDateTime('l', createTimeInstance(nextOpenTimeMS)) !==
      formatLocalizedDateTime('l', createTimeInstance(nextCloseTimeMS)) || isOpenBasedOnNextOpenTime;
  const isMarketOpeningToday =
    formatLocalizedDateTime('l', createTimeInstance(nextOpenTimeMS)) ===
    formatLocalizedDateTime('l', createTimeInstance()); // NOTE: API will return the next day if the market is open

  return {
    isOpen: isMarketOpen,
    isMarketOpeningToday,

    prevCloseTime: prevCloseTimeMS,
    nextOpenTime: nextOpenTimeMS,
    nextCloseTime: nextCloseTimeMS,
  };
};

export const useIsMarketOpenStatus = () => {
  const state = useSelector((state) => state.application.marketState);
  return {
    isOpen: state === MARKET_STATES.open,
    isInExtendedTrading: state !== MARKET_STATES.open,
  };
};
export const useIsMarketOpen = () => {
  // getting market open data is required and should never be the reducer initial value of null
  const { prev_market_close_timestamp, next_market_open_timestamp, next_market_close_timestamp } = useSelector(
    (state) => state.application.marketHoursData
  );

  const [state, dispatch] = React.useReducer(reducer, initialState, () =>
    calcMarketState({
      prev_market_close_timestamp,
      next_market_open_timestamp,
      next_market_close_timestamp,
    })
  );

  React.useEffect(() => {
    const payload = calcMarketState({
      prev_market_close_timestamp,
      next_market_open_timestamp,
      next_market_close_timestamp,
    });
    dispatch({ type: actions.update, payload });
  }, [prev_market_close_timestamp, next_market_open_timestamp, next_market_close_timestamp]);

  // isOpen
  // nextOpenTime
  // prevCloseTime
  // nextCloseTime
  // isMarketOpeningToday
  return state;
};
