import React from 'react';

const LoadingNotification = (props) => {
  const { position } = props;

  const loadingProfileFirstRowCover = {
    height: `${15}px`,
    top: `${15}px`,
    left: `${50}px`,
    right: `${0}px`,
  };
  const loadingProfileSecondRowCover = {
    height: `${15}px`,
    top: `${45}px`,
    left: `${50}px`,
    right: `${0}px`,
  };
  const loadingProfileAvatarEndCover = {
    height: `${100}px`,
    top: `${0}px`,
    left: `${50}px`,
    width: '15px',
    right: `auto`,
  };
  // const loadingMutualConnectionBottomCover = {
  //   height: `${ 70 }px`,
  //   top: `${ 100 }px`,
  //   left: `${ 0 }px`,
  //   right: `${ 0 }px`,
  // }
  // const loadingConnectButtonBottomCover = {
  //   height: `${ 80 }px`,
  //   top: `${ 110 }px`,
  //   left: `${ 0 }px`,
  //   right: `${ 0 }px`,
  // }

  return (
    <div className={`notification loading-notification border-accent`} style={position}>
      <div className={`animated-background`}>
        <div className={`loading-animation-covers`} style={loadingProfileFirstRowCover}></div>
        <div className={`loading-animation-covers`} style={loadingProfileSecondRowCover}></div>
        <div className={`loading-animation-covers`} style={loadingProfileAvatarEndCover}></div>
      </div>
    </div>
  );
};

export default LoadingNotification;
