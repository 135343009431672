export const FUNDING_TRANSACTION_TYPES = {
  DEPOSIT: 'Deposit',
  WITHDRAWAL: 'Withdrawal',
};

export const FUNDING_TRANSACTIONS = [FUNDING_TRANSACTION_TYPES.DEPOSIT, FUNDING_TRANSACTION_TYPES.WITHDRAWAL];

export const FUNDING_TRANSACTION_STATUSES = {
  COMPLETE: 'complete',
  REJECTED: 'rejected',
};

export const getTransactionType = (transactionData) => transactionData.type;

export const getTransactionStatus = (transactionData) => transactionData.status;

export const getTransactionDate = (transactionData) => transactionData.time;

export const getTransactionAmount = (transactionData) => transactionData.amount;

export const getTransactionEquityImpact = (transactionData) => {
  return 1 * transactionData.amount;
};

export const wasTransactionCompleted = (transactionData) =>
  getTransactionStatus(transactionData) === FUNDING_TRANSACTION_STATUSES.COMPLETE;

export const isTransactionFunding = (transactionData) =>
  FUNDING_TRANSACTIONS.includes(getTransactionType(transactionData));

export const filterFundingTransactionsGroupedByDate = (transactionsHistory) => {
  const groupedTransactions = [];

  transactionsHistory.forEach((transactionGroup) => {
    const fundingTransactions = transactionGroup.transactions.filter((transactionData) =>
      isTransactionFunding(transactionData)
    );

    if (fundingTransactions.length > 0) {
      const fundingTransactionsData = {
        date: transactionGroup.date,
        transactions: fundingTransactions,
      };
      groupedTransactions.push(fundingTransactionsData);
    }
  });

  return groupedTransactions;
};

export const filterFundingCompletedTransactions = (transactionsHistory) => {
  let transactions = [];

  transactionsHistory.forEach((transactionGroup) => {
    const fundingTransactions = transactionGroup.transactions.filter(
      (d) => isTransactionFunding(d) && wasTransactionCompleted(d)
    );
    transactions = [...transactions, ...fundingTransactions];
  });
  return transactions;
};
