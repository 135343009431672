import React from 'react';
import styled from 'styled-components';
import ReactSlider from 'react-slider';

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 6px;
`;

const StyledThumb = styled.div`
  height: 16px;
  line-height: 16px;
  width: 16px;
  text-align: center;
  background-color: ${({ theme }) => '#fff'};
  border-radius: 50%;
  cursor: grab;
  position: relative;
  top: -4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  outline: none;
`;

const Thumb = (props, state) => <StyledThumb {...props} />;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${(props) =>
    props.index === 2
      ? props.theme.themeColors.primaryCtaButton
      : props.index === 1
      ? props.theme.themeColors.lowContrastBorder
      : props.theme.themeColors.primaryCtaButton};
  border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const Slider = (props) => <StyledSlider renderTrack={Track} renderThumb={Thumb} {...props} />;

export default Slider;
