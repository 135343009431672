import React from 'react';
import { connect } from 'react-redux';

import { getViewpointOrgIdFromNameFromState } from '../../selectors/viewpoints';

import ViewpointProfile from './ViewpointProfile';
import { unslugifyName } from '../../helpers/generalHelpers';

class ViewpointProfileContainer extends React.PureComponent {
  render() {
    const { viewpointOrgId } = this.props;
    return <ViewpointProfile id={viewpointOrgId} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const name = unslugifyName(ownProps.nameParam);

  if (!name)
    return {
      viewpointOrgId: null,
    };

  return {
    viewpointOrgId: getViewpointOrgIdFromNameFromState(state, name.toLowerCase()),
  };
};

export default connect(mapStateToProps)(ViewpointProfileContainer);
