import React, { Component } from 'react';
import { connect } from 'react-redux';

import ChartUpWhite from '../../../../images/chart-up-white.png';
import ChartDownWhite from '../../../../images/chart-down-white.png';
import ChartUpGreen from '../../../../images/chart-up-green.png';
import ChartDownRed from '../../../../images/chart-down-red.png';

import ThoughtContainer from '../../../../containers/Thoughts/ThoughtContainer';
import UserContainer from '../../../users/UserContainer';
import ProfileAvatar from '../../../../components/user/ProfileAvatar';
import UserName from '../../../../components/user/UserName';
import SecurityIdentifierDisplay from '../../../UI/SecurityIdentifierDisplay';
import BasicSecurityDataWrapper from '../../../SecurityData/BasicSecurityDataWrapper';
import { NotificationHeading } from '../NotificationHeading';
import { Timestamp } from '../../../UI/Timestamp';

import { isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import { returnCurrentUserId } from '../../../../helpers/currentUserHelpers';
import { getThoughtImpressionEventName } from '../../../../constants/tracking';
import { isInt } from '../../../../helpers/numberHelpers';
import { PROFILE_CONTEXTS } from '../../../../constants/contextTracking';

export class ThoughtNotification extends Component {
  render() {
    return (
      <div
        className={`react-thought-notification ${
          this._shouldRenderProfileAvatarHeading() ? 'with-notification-heading' : ''
        }`}
      >
        {this._shouldRenderProfileAvatarHeading() && (
          <NotificationHeading userIds={this._returnUserIdsForNotificationHeading()} />
        )}
        {this._renderThoughtNotificationMessage()}
        {this._renderThought()}
        {!this._shouldHideNotificationTimestamp() && (
          <Timestamp className={'notification-timestamp'} epochTime={this._returnNotificationTimestamp()} />
        )}
      </div>
    );
  }

  _renderThoughtNotificationMessage = () => {
    return (
      <div className={'react-thought-notification-message'}>
        {this._isThoughtAgree() && this._renderThoughtAgreeNotificationMessage()}
        {this._isThoughtDisagree() && this._renderThoughtDisagreeNotificationMessage()}
        {this._isThoughtTrending() && this._renderThoughtTrendingNotificationMessage()}
        {this._isThoughtFromCommunity() && this._renderThoughtCommunityNotificationMessage()}
      </div>
    );
  };

  _returnUserIdsForNotificationHeading = () => this._returnNotificationData().all_users.map((user) => user.id);

  _isThoughtAgree = () => !isUndefinedOrNull(this._returnThoughtAgreeData());

  _isThoughtDisagree = () => !isUndefinedOrNull(this._returnThoughtDisagreeData());

  _isThoughtAgreeOnly = () => isUndefinedOrNull(this._returnThoughtDisagreeData());

  _doesThoughtHaveBothAgreeAndDisagree = () => this._isThoughtAgree() && this._isThoughtDisagree();

  _renderThoughtAgreeNotificationMessage = () => {
    const thoughtOpinionedData = this._returnThoughtOpinionedData('agree');
    return this._renderThoughtNotificationFullSubMessage(thoughtOpinionedData);
  };

  _renderThoughtTrendingNotificationMessage = () => {
    return (
      <div className={'thought-notification-full-message'}>
        <span className={'community-prefix-phrase prefix-phrase'}>{this._returnTrendingThoughtPrefixPhrase()}</span>
        {this._renderThoughtType()}
        {this._renderThoughtTypeToSecurityConjunction()}
        {this._renderSecurity(true)}
        <span className={'community-suffix-phrase'}>{this._returnCommunityThoughtSuffixPhrase()}</span>
      </div>
    );
  };

  _renderThoughtCommunityNotificationMessage = () => {
    return (
      <div className={'thought-notification-full-message'}>
        <span className={'community-prefix-phrase prefix-phrase'}>{this._returnCommunityThoughtPrefixPhrase()}</span>
        {this._renderThoughtType()}
        {this._renderThoughtTypeToSecurityConjunction()}
        {this._renderSecurity(true)}
        <span className={'community-suffix-phrase'}>{this._returnCommunityThoughtSuffixPhrase()}</span>
      </div>
    );
  };

  _renderThoughtNotificationFullSubMessage = (thoughtOpinionedData) => {
    return (
      <div className={'thought-notification-full-message'}>
        {this._renderUsersPhrase(thoughtOpinionedData)}
        {this._renderThoughtActionPhrase(thoughtOpinionedData)}
        {this._renderThoughtPhrase(thoughtOpinionedData)}
        {this._renderThoughtType()}
        {this._renderThoughtTypeToSecurityConjunction()}
        {this._renderSecurity()}
      </div>
    );
  };

  _renderThoughtNotificationAlternateSubMessage = (thoughtOpinionedData) => {
    return (
      <div className={'thought-notification-alt-message'}>
        {this._renderUsersPhrase(thoughtOpinionedData)}
        {this._renderThoughtActionPhrase(thoughtOpinionedData)}
      </div>
    );
  };

  _renderThoughtDisagreeNotificationMessage = () => {
    const thoughtOpinionedData = this._returnThoughtOpinionedData('disagree');
    return this._doesThoughtHaveBothAgreeAndDisagree()
      ? this._renderThoughtNotificationAlternateSubMessage(thoughtOpinionedData)
      : this._renderThoughtNotificationFullSubMessage(thoughtOpinionedData);
  };

  _renderThought = () => {
    return (
      <BasicSecurityDataWrapper securityId={this._returnSecurity().security_id}>
        <ThoughtContainer
          key={`notification-thought-container-${this._returnThoughtId()}`}
          notificationIndex={this._returnNotificationIndex()}
          thoughtId={this._returnThoughtId()}
          isUserAuthed={true}
          handleAfterThoughtAction={this.props.handleAfterThoughtAction}
          handleAdditionalPromptForThoughtAfterDisagreeAction={
            this.props.handleAdditionalPromptForThoughtAfterDisagreeAction
          }
          viewingContext={getThoughtImpressionEventName('FEED')}
          metadata={this._returnMetadata()}
          ignoreImpressionTracking={this.props.ignoreImpressionTracking}
          scrollContainerSelector={this.props.scrollContainerSelector || window}
          inFeed={this.props.inFeed}
          inActivityPageFeed={this.props.inActivityPageFeed}
          showAskCTLLink
          showAlternateOpinionActionStyle
          hideOpinionCounts
          withThoughtLink
          useShareInModal
        />
      </BasicSecurityDataWrapper>
    );
  };

  _returnCSSClassForOpinion = (thoughtOpinionedData) => {
    if (this._isActionPhraseAnOpinion(thoughtOpinionedData)) {
      return this._returnThoughtActionPhrase(thoughtOpinionedData).toLowerCase().indexOf('dis') >= 0
        ? 'disagree-text-color'
        : 'agree-text-color';
    } else {
      return ' ';
    }
  };

  _renderThoughtActionPhrase = (thoughtOpinionedData) => {
    return (
      <span className={`thought-action-phrase ${this._returnCSSClassForOpinion(thoughtOpinionedData)}`}>
        {this._returnThoughtActionPhrase(thoughtOpinionedData)}
      </span>
    );
  };

  _renderThoughtPhrase = (thoughtOpinionedData) => {
    const rawThoughtPhrase = this._returnThoughtPhrase(thoughtOpinionedData);
    if (this._isThoughtAuthorInPhrase(rawThoughtPhrase)) {
      const phrase = rawThoughtPhrase.split(' $$')[0];
      const user = this._returnThoughtAuthor(thoughtOpinionedData);
      const isPossessive = true;
      return (
        <span className={'thought-phrase  '}>
          {phrase}
          &nbsp;
          {this._renderUser(user, isPossessive)}
        </span>
      );
    } else {
      return <span className={'thought-phrase  '}>{rawThoughtPhrase}</span>;
    }
  };

  _renderThoughtType = () => {
    return (
      <span className={`thought-type-phrase ${this._returnThoughtTypeColorClass()}`}>
        {this._returnThoughtType().toUpperCase()}
      </span>
    );
  };

  _renderThoughtTypeToSecurityConjunction = () => {
    return <span className={'security-conjunction-phrase  '}>{'for'}</span>;
  };

  _renderSecurity = (isTrendingOrCommunityThought) => {
    const security = this._returnSecurity();
    return (
      <SecurityIdentifierDisplay
        securityId={security.security_id}
        symbol={security.symbol}
        name={security.name}
        prefix={'('}
        suffix={')'}
        activeTab={isTrendingOrCommunityThought ? 'thoughts' : null}
        showSymbol
        showName
        limitNameWidth
        inFeed
      />
    );
  };

  _renderUsersPhrase = (thoughtOpinionedData) => {
    const usersString = this._returnUsersPhrase(thoughtOpinionedData);
    const users = this._returnUsersShareOpinion(thoughtOpinionedData);

    if (!usersString) {
      return null;
    }

    const renderUserPhraseConjunction = (str, key) => {
      return (
        <span key={key} className={'user-phrase-conjunction  '}>
          {str}
        </span>
      );
    };
    const key = `thought-${this._returnThoughtId()}`;
    const renderNodes = [];
    let stack = [];
    let nextUserIndex = 0;
    const escapeChar = this._returnUserEscapeChar()[0];
    for (let i = 0; i < usersString.length; i++) {
      const char = usersString[i];
      if (char === escapeChar && stack.length > 0 && stack[0] !== escapeChar) {
        renderNodes.push(renderUserPhraseConjunction(stack.join(''), `conjunction-${key}-${i}`));
        stack = [];
      }
      stack.push(char);
      if (stack[0] === escapeChar && stack[1] === escapeChar) {
        const user = users[nextUserIndex];
        const isPossessive = false;
        renderNodes.push(this._renderUser(user, isPossessive, `user-${user.user_id}-${key}-${i}`));
        stack = [];
        nextUserIndex += 1;
      }
    }
    if (stack.length > 0) {
      renderNodes.push(renderUserPhraseConjunction(stack.join(''), `conjunction-${key}-last`));
      stack = [];
    }
    return <span className={'thought-users-phrase'}>{renderNodes}</span>;
  };

  _renderUser = (user, isPossessive, key) => {
    const additionalProps = {};
    if (key) {
      additionalProps.key = key;
    }
    return (
      <UserContainer
        key={`user-${user.user_id}-in-thought-${this._returnThoughtId()}`}
        {...additionalProps}
        user={user}
        userId={user.user_id}
      >
        {!this._shouldRenderProfileAvatarHeading() && (
          <ProfileAvatar profileLinkQuery={`?context=${PROFILE_CONTEXTS.NOTIFICATION}`} />
        )}
        <UserName isPossessive={isPossessive} profileLinkQuery={`?context=${PROFILE_CONTEXTS.NOTIFICATION}`} useYou />
      </UserContainer>
    );
  };

  _returnSrcBadgeIcon = () => {
    if (this._returnThoughtType().toLowerCase() === 'pro') {
      return this._isThoughtTrending() || this._isThoughtFromCommunity() ? ChartUpWhite : ChartUpGreen;
    } else {
      return this._isThoughtTrending() || this._isThoughtFromCommunity() ? ChartDownWhite : ChartDownRed;
    }
  };

  _returnBadgeClassNames = () => {
    if (this._isThoughtTrending() || this._isThoughtFromCommunity()) {
      return 'badge-icon gradient';
    } else {
      return 'badge-icon badge-grey';
    }
  };

  _shouldRenderProfileAvatarHeading = () =>
    this.props.renderProfileAvatarHeading && !this._isThoughtTrending() && !this._isThoughtFromCommunity();

  _returnThoughtCommunityType = () => this._returnNotificationData().from_community_type;

  _isThoughtTrending = () => this._returnThoughtCommunityType() && this._returnThoughtCommunityType() === 'trending';

  _isThoughtFromCommunity = () => this._returnThoughtCommunityType() && this._returnThoughtCommunityType() === 'fresh';

  _returnTrendingThoughtPrefixPhrase = () => 'Trending';

  _returnCommunityThoughtPrefixPhrase = () => 'New';

  _returnCommunityThoughtSuffixPhrase = () => 'from the community';

  _returnCurrentUser = () => this.props.currentUser;

  _returnCurrentUserId = () => returnCurrentUserId(this._returnCurrentUser());

  _isUserCurrentUser = (userId) => this._returnCurrentUserId() === userId;

  _returnThought = () => this._returnNotificationData().thought;

  _returnThoughtType = () => (this._returnThought().thought_type.id === 0 ? 'Pro' : 'Con');

  _returnThoughtTypeColorClass = () =>
    this._returnThought().thought_type.id === 0 ? 'pro-text-color' : 'con-text-color';

  _returnThoughtOpinionedData = (key) => this._returnNotificationData()[key];

  _isThoughtAuthorInPhrase = (rawThoughtPhrase) => rawThoughtPhrase.indexOf(this._returnUserEscapeChar()) >= 0;

  _returnThoughtActionPhrase = (thoughtOpinionedData) => thoughtOpinionedData.action_phrase;

  _returnThoughtPhrase = (thoughtOpinionedData) =>
    typeof thoughtOpinionedData.thought_phrase === 'string' ? thoughtOpinionedData.thought_phrase.trim() : '';

  _returnUsersPhrase = (thoughtOpinionedData) => thoughtOpinionedData.users_phrase;

  _returnUsersShareOpinion = (thoughtOpinionedData) => thoughtOpinionedData.users;

  _isActionPhraseAnOpinion = (thoughtOpinionedData) =>
    this._returnThoughtActionPhrase(thoughtOpinionedData).indexOf('agree') >= 0;

  _returnThoughtAuthor = (thoughtOpinionedData) => thoughtOpinionedData.users[thoughtOpinionedData.users.length - 1];

  _returnUserEscapeChar = () => '$$';

  _returnThoughtAgreeData = () => this._returnNotificationData().agree;

  _returnThoughtDisagreeData = () => this._returnNotificationData().disagree;

  _returnSecurity = () => this._returnNotificationData().security;

  _returnNotificationTimestamp = () => this._returnNotificationData().time;

  _shouldHideNotificationTimestamp = () => this._returnNotificationData().show_timestamp === false;

  _returnNotification = () => this._findThoughtNotificationInNotificationsList();

  _returnThoughtId = () => this.props.notification.data.thought.id;

  _returnMetadata = () => this.props.notification.data.metadata;

  _findThoughtNotificationInNotificationsList = () =>
    this._returnNotificationsList().filter(
      (notif) => notif && notif.type === 'thought' && notif.data.thought.id === this._returnThoughtId()
    )[0];

  _returnNotificationsList = () => this._returnNotificationsStore().notificationsList;

  _returnNotificationsStore = () => this.props.notifications;

  _returnNotificationData = () => this._returnNotification().data;

  _returnNotificationIndex = () => {
    const index = this.props.notificationIndex;
    if (!isInt(index)) {
      console.error('Thought Notification Missing Index');
    }
    return index;
  };
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications,
  };
};

const connectedComponent = connect(mapStateToProps, null)(ThoughtNotification);

export default connectedComponent;
