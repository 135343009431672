import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useCurrentUser, useLiveAccount } from '../../hooks/user';
import { logMetricsTrackingEvent, submitLiveTradingSignUpForm } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import { H5, Body5, FloatingLabelTextInput, Lock } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { useFormik } from 'formik';
import { ContactNameSchema } from '../../constants/formValidationSchemas';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const InputWrapper = styled.div`
  padding-top: 40px;

  input {
    border: none !important;
    -webkit-appearance: auto;
    background: transparent !important;
  }
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 8px;
`;
const PrivacyMessage = styled.div`
  padding-top: 24px;
`;
const IconMessage = styled.div`
  padding-top: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  text-align: left;

  svg {
    height: 24px;
    width: 24px;

    fill: ${({ theme }) => theme.themeColors.text};

    margin-right: 16px;
  }
`;
const Actions = styled.div`
  padding-top: 36px;
  text-align: center;
`;

const getInitialValuesFrom = (currentUser, liveAccount) => {
  const { first_name, last_name } = currentUser;
  const { given_name, family_name } = liveAccount;
  return {
    given_name: first_name || given_name || '',
    family_name: last_name || family_name || '',
  };
};

const massageFormData = (v) => v;

const FORM_PAGE_NAME = 'contact';
const FULL_NAME_MAX_LENGTH = 30;
const FULL_NAME_TOO_LONG_ERROR_MESSAGE =
  'Your full name is longer than the maximum length we can accept, please use initials where appropriate.';

export const EnterContactName = ({ onContinue, onboardingState }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const liveAccount = useLiveAccount();

  const [isReady, setIsReady] = React.useState(true);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const form = useFormik({
    initialValues: getInitialValuesFrom(currentUser, liveAccount),
    validationSchema: ContactNameSchema,
    enableReinitialize: false,
  });
  const { values, errors, setFieldValue, validateForm } = form;

  const handleChange = React.useCallback(
    (name) => (value) => {
      setFieldValue(name, value, false);
    },
    [setFieldValue]
  );

  const submitForm = React.useCallback((values) => {
    const apiFormData = massageFormData(values);
    setIsSubmitting(true);
    return submitLiveTradingSignUpForm(apiFormData, FORM_PAGE_NAME)(dispatch);
  }, []);

  const handleSubmit = React.useCallback(async () => {
    const { given_name, family_name } = values;

    const event = 'Submitted Contact Name Form';
    logMetricsTrackingEvent(event)();

    const validation = await validateForm();

    let additionalValidationPassed = true;
    const fullName = given_name + ' ' + family_name;
    if (fullName.length > FULL_NAME_MAX_LENGTH) {
      additionalValidationPassed = false;
      setFormError(
        'Your full name is longer than the maximum length we can accept, please use initials where appropriate.'
      );
    }

    if (Object.keys(validation).length === 0 && additionalValidationPassed) {
      const { status } = await submitForm(values);
      if (status === 200) {
        return {
          ok: true,
        };
      } else {
        setFormError('Something went wrong, please try again');
        setIsSubmitting(false);
        return {
          ok: false,
        };
      }
    } else {
      logMetricsTrackingEvent('Failed Live Account Validations', {
        Fields: Object.entries(validation),
        'Additional Validation': additionalValidationPassed,
        'Form Name': 'name',
      })();
      // could log errors but no form validations on this form
      setIsSubmitting(false);
      return {
        errors: true,
      };
    }
  }, [values]);

  const handleContinueClick = React.useCallback(async () => {
    const { ok } = await handleSubmit();
    if (ok) onContinue(null, onboardingState);
  }, [handleSubmit]);

  React.useEffect(() => {
    const { given_name, family_name } = values;
    const fullName = given_name + ' ' + family_name;

    if (fullName.length <= FULL_NAME_MAX_LENGTH && formError === FULL_NAME_TOO_LONG_ERROR_MESSAGE) {
      setFormError(null);
    }
  }, [values]);

  React.useEffect(() => {
    logMetricsTrackingEvent('View Contact Form')();
    logMetricsTrackingEvent('View Contact Name Form')();
  }, []);

  if (!isReady) return <LoadingPage />;

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.enterName}>
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>What is your legal name?</H5>
          </Heading>
          <Message>
            <Body5>Your full legal name as it appears on your license or passport is required.</Body5>
          </Message>

          <InputWrapper>
            <FloatingLabelTextInput
              name={'given_name'}
              value={values.given_name}
              label={'First Name'}
              onChange={handleChange}
              error={errors.given_name || null}
            />
          </InputWrapper>
          <InputWrapper>
            <FloatingLabelTextInput
              name={'family_name'}
              value={values.family_name}
              label={'Last Name'}
              onChange={handleChange}
              error={errors.family_name || null}
            />
          </InputWrapper>

          <PrivacyMessage>
            <IconMessage>
              <Lock />
              <Body5>This information will be kept private and it will never appear on your public profile.</Body5>
            </IconMessage>
          </PrivacyMessage>

          <Actions>
            <FormState error={formError} isSubmitting={isSubmitting} />
            <Container fullWidth centerAll>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleContinueClick}>
                Continue
              </FlatButton>
            </Container>
          </Actions>
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
