import React from 'react';
import { connect } from 'react-redux';
import { openOptimizerPanel, logMetricsTrackingEvent } from '../../actions';

import Button from '../../components/buttons/Button';
import { FlatButton } from '../../main/components/buttons';

class OpenOptimizerPanelButton extends React.PureComponent {
  render() {
    return (
      <FlatButton small onClick={this.handleClick}>
        View Optimizer
      </FlatButton>
    );
  }

  handleClick = () => {
    openOptimizerPanel()(this.props.dispatch);
    this.logViewOptimizerButtonClick();
    this.props.additionalClickAction && this.props.additionalClickAction();
  };

  logViewOptimizerButtonClick = () => {
    const event = 'Clicked Optimizer Button';
    const properties = {
      Context: this.props.viewingContext,
    };
    logMetricsTrackingEvent(event, properties)();
  };
}

export default connect()(OpenOptimizerPanelButton);
