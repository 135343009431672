import React from 'react';
import { connect } from 'react-redux';
import { hideVerticalNav, logMetricsTrackingEvent } from '@src/actions';
import LinkTo from '../../Links/LinkTo';
import { isCurrentUserOnboarding } from '@src/helpers/currentUserHelpers';
import { ElementPositionContext } from '@src/context';
import { enums } from '@src/main/lib/nvstr-utils.es';
import styled from 'styled-components';
import Icon from '../../../components/misc/Icon';
import { FullLogo } from '@src/main/components/logo/FullLogo';

const PositionWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
`;

const ButtonCustom = styled.div`
  height: 30px;
  width: 30px;
  cursor: pointer;

  i {
    font-size: 16px;
  }

  div {
    position: absolute;
    top: 0;
    right: 4px;
  }
`;

const Header = (props) => {
  const { nav, isOnboarding, dispatch } = props;
  const { alwaysOpen } = nav;

  const logHomeClick = () => {
    const event = 'Clicked Link To Root';
    const properties = {
      'In Onboarding': isOnboarding,
    };
    logMetricsTrackingEvent(event, properties)(dispatch);
  };

  const collapseNav = () => {
    if (!alwaysOpen) {
      hideVerticalNav()(dispatch);
    }
  };

  const navLogoContainerClass = () => {
    return 'nav-logo-container';
  };

  return (
    <ElementPositionContext.Provider value={{ elementPosition: enums.node_location.header }}>
      <div className="vertical-nav-header" style={{ position: 'relative' }}>
        <LinkTo
          to={'/'}
          customContainerStyle={{
            display: 'block',
          }}
          className={`${navLogoContainerClass()}`}
          additionalClickAction={logHomeClick}
          child={<FullLogo isSmall />}
          scrollToTop
        />
        {!alwaysOpen && (
          <PositionWrapper>
            <ButtonCustom onClick={collapseNav}>
              <div>
                <Icon icon="fa fa-times fa-times-thin" colorClassName=" " />
              </div>
            </ButtonCustom>
          </PositionWrapper>
        )}
      </div>
    </ElementPositionContext.Provider>
  );
};

const mapStateToProps = (state) => {
  return {
    nav: state.nav,
    isOnboarding: isCurrentUserOnboarding(state.currentUser),
  };
};

export default connect(mapStateToProps)(Header);
