import { BASEURL, axios } from '@src/adapters';
import { sendSessionStartEvents } from '../../../../utils/tracking/amplitude';
import itly from '../../../../utils/itly/itly.service';
import { INTERNAL_STORAGE_KEYS, InternalStorage } from '../../../../utils/storage';
import { logMetricsTrackingEvent } from '@src/actions';

export const extendedFetch = (url, method, body, params_headers) => {
  const username = 'nvstr';
  const password = 'investbettertomorrow';
  const headers = new Headers();

  headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');
  headers.set('Authorization', 'Basic ' + btoa(username + ':' + password));
  params_headers?.forEach((e) => {
    headers.set(e[0], e[1]);
  });

  return fetch(url, {
    method: method,
    credentials: 'include',
    headers,
    body,
  });
};

const saveDeviceRiskData = async (sessionRiskId) => {
  try {
    const getCSRFAfterLogin = await extendedFetch(`${BASEURL}/api/v1/util/csrf`, 'GET');
    const getCSRFAfterLoginData = await getCSRFAfterLogin.json();
    const { csrf_token: sessionRiskCSRFToken } = getCSRFAfterLoginData;

    extendedFetch(
      `${BASEURL}/device_risk`,
      'POST',
      JSON.stringify({
        device_session_id: sessionRiskId,
      }),
      [['X-CSRF-Token', sessionRiskCSRFToken]]
    );
  } catch (e) {
    console.error(e);
  }
};

const saveCSRFToken = async () => {
  try {
    const response = await extendedFetch(`${BASEURL}/api/v1/util/csrf`, 'GET', null, [
      // ['X-CSRF-Token', csrf_token],
    ]);

    const data2 = await response.json();
    const { csrf_token: token } = data2;

    axios.defaults.headers.common['X-CSRF-Token'] = token;
    window.localStorage.setItem('X-CSRF-Token', token);

    return {
      token,
    };
  } catch (e) {
    console.error(e);
    return {
      token: null,
    };
  }
};

export async function signIn({ email, password, rememberMe }) {
  try {
    const sessionRiskId = InternalStorage.getItemFromStorage(INTERNAL_STORAGE_KEYS.SOCURE_SESSION_RISK_ID);

    //-- initial CSRF token - throwaway - figure out why this is needed? - EH
    const getCSRF1 = await extendedFetch(`${BASEURL}/api/v1/util/csrf`, 'GET');
    const getCSRFBeforeLogin = await extendedFetch(`${BASEURL}/api/v1/util/csrf`, 'GET');
    const data = await getCSRFBeforeLogin?.json();
    const { csrf_token } = data;

    const signIn = await extendedFetch(
      `${BASEURL}/users/sign_in`,
      'POST',
      JSON.stringify({
        user: {
          email,
          password,
          remember_me: rememberMe,
        },
      }),
      [['X-CSRF-Token', csrf_token]]
    );
    const { error: errorMessage, route_to } = await signIn.json();
    if (errorMessage) {
      const incorrectEmailOrPasswordError = 'The email or password you entered is not valid.';
      return { errorMessage };
    }

    rememberMe === '1' && InternalStorage.setItemInStorage(INTERNAL_STORAGE_KEYS.EMAIL, email);
    await saveDeviceRiskData(sessionRiskId);
    const { token } = await saveCSRFToken();

    return {
      isAuthed: true,
      route_to,
      csrfToken: token,
    };
  } catch (e) {
    console.error(e);
    const errorMessage = 'An error occurred, please try to Sign In again.';
    return {
      errorMessage,
    };
  }
}

export async function isSignedIn() {
  try {
    const getCSRFBeforeLogin = await extendedFetch(`${BASEURL}/api/v1/util/csrf`, 'GET');
    const data = await getCSRFBeforeLogin?.json();
    const { csrf_token, is_signed_in } = data;

    if (is_signed_in) {
      window.localStorage.setItem('X-CSRF-Token', csrf_token);
      axios.defaults.headers.common['X-CSRF-Token'] = csrf_token;
      return {
        isAuthed: true,
        csrfToken: csrf_token,
      };
    } else {
      return {
        isAuthed: false,
      };
    }
  } catch (e) {
    console.error(e);
    if (window.location.pathname !== '/app/login') window.location = '/app/login';
    return {
      isAuthed: false,
    };
  }
}

export async function signOut() {
  try {
    const CSRF_TOKEN = window.localStorage.getItem('X-CSRF-Token');
    const getCSRF = await extendedFetch(`${BASEURL}/users/sign_out`, 'DELETE', null, [['X-CSRF-Token', CSRF_TOKEN]]);
    const data = await getCSRF.json();
  } catch (e) {
    console.error(e);
  }
}

export async function forgotPasswordSubmit({ email, recaptchaValue, userPasswordComplete, userPasswordError }) {
  try {
    // -- Get initial CSRF token
    const getCSRF = await extendedFetch(`${BASEURL}/api/v1/util/csrf`, 'GET');
    const data = await getCSRF.json();
    const { csrf_token } = data;

    // Forgot Password Post call
    const signIn = await extendedFetch(
      `${BASEURL}/users/password`,
      'POST',
      JSON.stringify({
        user: {
          email,
          'g-recaptcha-response': recaptchaValue,
        },
      }),
      [['X-CSRF-Token', csrf_token]]
    );

    const signInData = await signIn.json();

    if (signInData.error) {
      userPasswordError(signInData.error);
    } else {
      userPasswordComplete();
    }
  } catch (e) {
    userPasswordError('An Error occured please. Please try again.');
    console.error(e);
  }
}

export async function resetPasswordSubmit({
  password1,
  password2,
  resetPasswordToken,
  userPasswordComplete,
  userPasswordError,
}) {
  try {
    // -- Get initial CSRF token
    const getCSRF = await extendedFetch(`${BASEURL}/api/v1/util/csrf`, 'GET');
    const data = await getCSRF.json();
    const { csrf_token } = data;

    // Reset Password PUT call
    const signIn = await extendedFetch(
      `${BASEURL}/users/password`,
      'PUT',
      JSON.stringify({
        user: {
          reset_password_token: resetPasswordToken,
          password: password1,
          password_confirmation: password2,
        },
      }),
      [['X-CSRF-Token', csrf_token]]
    );

    const signInData = await signIn.json();

    if (signInData.error) {
      logMetricsTrackingEvent('Reset Password Error', { 'Error Message': signInData.error })();
      userPasswordError('An error occurred, please try again. If problem persists, please contact support.');
    } else {
      userPasswordComplete();
    }
  } catch (e) {
    userPasswordError('An error occurred, please try again.');
    console.error(e);
  }
}
