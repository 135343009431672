import { SHOW_MODAL, HIDE_MODAL, CONFIRM_MODAL } from '../constants';
import { TrackIteratively } from '../utils/itly';
import getModalText from '../utils/getModalText';

export const showModal = (modal) => {
  const { description, message, contentComponent, customBody } = modal;
  const text = description || message || getModalText(contentComponent) || getModalText(customBody);

  const properties = {
    context: window.location.pathname,
    is_error: contentComponent?.props?.is_error || false,
    message: text,
    modal_type: 'Modal',
    url: window.location.pathname,
    url_query: window.location.search,
  };
  TrackIteratively.generic.MODAL_VIEWED.send(properties);

  return function (dispatch) {
    dispatch({
      type: SHOW_MODAL,
      payload: modal,
    });
  };
};

export const hideModal = () => {
  return function (dispatch) {
    dispatch({
      type: HIDE_MODAL,
    });
  };
};

export const confirmModal = () => {
  return function (dispatch) {
    dispatch({
      type: CONFIRM_MODAL,
    });
  };
};
