import { toCapitalCase } from './generalHelpers';
import { PRODUCT_NAME } from '@src/appConfig';

export const serializerDefinitions = {
  serializerFunctions: {
    convertBankAccountType: function (source, data) {
      return convertBankAccountType(source, data);
    },
    convertTransactionAmount: function (source, data) {
      return convertTransactionAmount(source, data);
    },
    convertMicroDepositVerificationAmount: function (source, data) {
      return convertMicroDepositVerificationAmount(source, data);
    },
    convertFundingOperation: function (source, data) {
      return convertFundingOperation(source, data);
    },
  },

  serializeFields: {
    bank_account_type: 'convertBankAccountType',
    amount: 'convertTransactionAmount',
    funding_operation: 'convertFundingOperation',
    amount1: 'convertMicroDepositVerificationAmount',
    amount2: 'convertMicroDepositVerificationAmount',
  },

  booleanFields: {},
};

export const canVerifyBankAccount = (account) => {
  const { micro_deposits_ready, needs_micro_deposit_verification, can_accept_transfers } = account;
  return micro_deposits_ready && needs_micro_deposit_verification && can_accept_transfers;
};

export const convertBankAccountType = (source, bank_account_type) => {
  if (source === 'fromApi') {
    return toCapitalCase(bank_account_type);
  } else {
    return bank_account_type.toUpperCase();
  }
};

export const convertTransactionAmount = (source, amount) => {
  if (source === 'fromFront') {
    return typeof amount === 'string' ? amount.replace(/,/g, '') : amount;
  } else {
    return amount;
  }
};

export const convertMicroDepositVerificationAmount = (source, amount) => {
  if (source === 'fromFront') {
    return '.' + amount;
  } else {
    return amount;
  }
};

export const convertFundingOperation = (source, transaction) => {
  if (source === 'fromFront') {
    if (transaction === `From ${PRODUCT_NAME}`) {
      return 'Withdraw';
    } else if (transaction === `To ${PRODUCT_NAME}`) {
      return 'Deposit';
    }
  } else {
    return transaction;
  }
};
