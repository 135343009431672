import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../../../actions/index';

import { snakeToHyphens, toCapitalCase } from '../../../../helpers/generalHelpers';
import { findUserIdeaForSecurity } from '../../../../helpers/ideaHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';

export class IdeaBuySellCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;

    if (!this._isSecurityFeatured()) {
      return <td className={`security-table-${snakeToHyphens(columnKeyName)}`} style={{ cursor: 'default' }}></td>;
    }
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`} style={{ cursor: 'default' }}>
        {this._isLoading() ? this.props.loadingCellComponent : this._renderCell()}
      </td>
    );
  }

  _renderCell = () => {
    return <div className={'tcell-container'}>{this._renderCellContent()}</div>;
  };

  _isLoading = () => {
    const { userId, securityId } = this.props;
    const currentUserId = this.props.currentUser.user_id;
    const security = this.props.securities.lookup[securityId];

    const ideaUserId = userId || currentUserId;
    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    const ideaId = idea && idea.idea_id;

    return !security || !('name' in security) || (!this.props.idea && !ideaId);
  };

  _returnLinkForHandlingClick = () => {
    const { userId, securityId } = this.props;
    const currentUserId = this.props.currentUser.user_id;
    const ideaUserId = userId || currentUserId;

    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    const ideaId = idea && idea.idea_id;

    const additionalProps = {
      activeTab: 'ideas',
      ideaId,
    };
    const openSecurityPanelConfig = {
      securityId,
      additionalProps,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    const { userId, securityId } = this.props;
    const currentUserId = this.props.currentUser.user_id;

    const ideaUserId = userId || currentUserId;
    const ideaList = this.props.ideas.ideaList;
    const idea = this.props.idea || findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    const ideaType = idea.idea_type ? idea.idea_type.name : null;

    return (
      <span className={'security-table-idea-trade'}>
        {ideaType !== null
          ? ideaType.toLowerCase() === 'buy'
            ? this._renderBuyButton()
            : this._renderSellButton()
          : null}
      </span>
    );
  };

  _navigateToPlaceOrder = (orderType) =>
    this.props.navigate(`/orders?operation=${orderType}&security_id=${this._returnSecurityId()}`);
  _handleBuyClick = () => {
    const orderType = 'buy';
    this._logButtonClick(orderType);
    return this._navigateToPlaceOrder(orderType);
  };
  _handleSellClick = () => {
    const orderType = 'sell';
    this._logButtonClick(orderType);
    return this._navigateToPlaceOrder(orderType);
  };
  _returnIdeaList = () => this.props.ideas.ideaList;
  _returnIdea = () =>
    findUserIdeaForSecurity(this.props.currentUser.user_id, this._returnSecurityId(), this._returnIdeaList());
  _returnSecurityId = () => this.props.securityId;
  _returnSecuritySymbol = () => this._returnSecurity().symbol;
  _isSecurityFeatured = () => this._returnSecurity().featured;
  _returnSecurity = () => this._returnSecuritiesDict()[this.props.securityId];
  _returnSecuritiesDict = () => this._returnSecuritiesStore().lookup;
  _returnSecuritiesStore = () => this.props.securities;

  _renderBuyButton = () => {
    return (
      <span onClick={this._handleBuyClick} className={'skel-btn skel-btn-buy-color btn-small trade-buy-sell-btn'}>
        <span>Buy</span>
      </span>
    );
  };

  _renderSellButton = () => {
    return (
      <span onClick={this._handleSellClick} className={'skel-btn skel-btn-sell-color btn-small trade-buy-sell-btn'}>
        Sell
      </span>
    );
  };

  _logButtonClick = (orderType) => {
    const event = 'Clicked Buy/Sell Button';
    const properties = {
      context: 'Dashboard Ideas',
      'Order Type': toCapitalCase(orderType),
      'Security ID': this._returnSecurityId(),
      Symbol: this._returnSecuritySymbol(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    ideas: state.ideas,
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(IdeaBuySellCell);

export default composedComponent;
