import { bannerAdapter } from '../adapters/bannerAdapter';

import { FETCH_BANNER, SHOW_BANNER, DISMISS_BANNER } from '../constants';

export const fetchBanner = (currentUser, currentRoute) => {
  return function (dispatch) {
    return bannerAdapter.fetchBanner().then((response) => {
      if (response.data && response.data.banner) {
        const banner = response.data.banner;
        const status = banner.status;
        dispatch({
          type: SHOW_BANNER,
          payload: { banner },
        });
      } else {
        // TODO: failed
      }
      return response;
    });
  };
};

export const showBanner = (banner) => {
  return function (dispatch) {
    dispatch({
      type: SHOW_BANNER,
      payload: { banner },
    });
    return banner;
  };
};

export const dismissBanner = () => {
  return function (dispatch) {
    dispatch({
      type: DISMISS_BANNER,
    });

    return bannerAdapter.dismissBanner();
  };
};
