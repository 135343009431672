import React from 'react';
import { useSelector } from 'react-redux';

const pullData = (d) => {
  if (!d) return null;

  const { is_short_allowed, is_halted } = d;
  return {
    is_short_allowed,
    is_halted,
  };
};

const didChange = (prev, current) => {
  if (!prev && !current) return false;
  if (!prev) return true;

  const { is_short_allowed: prev_is_short_allowed, is_halted: prev_is_halted } = pullData(prev);

  const { is_short_allowed: current_is_short_allowed, is_halted: current_is_halted } = pullData(current);

  return prev_is_short_allowed === current_is_short_allowed && prev_is_halted === current_is_halted;
};

export const useSecurityFlags = (id) => {
  const storeSecurityData = useSelector((state) => state.securitiesPrice.securities[id]);
  const [flags, setFlags] = React.useState(pullData(storeSecurityData));

  React.useEffect(
    () => {
      if (didChange(flags, storeSecurityData) || !flags) {
        setFlags(pullData(storeSecurityData));
      }
    },
    [storeSecurityData, id]
  );

  return flags;
};
