import React from 'react';
import { connect } from 'react-redux';

import VerticalNavItem from '../../components/VerticalNavItem';
import { returnTopPerformersUserIdFromCollectivesStore } from '../../../../helpers/collectiveHelpers';
import TrophyIcon from '@src/main/components/Images/TrophyIcon';

const TopPerformers = (props) => {
  const { collectiveUserId } = props;

  return (
    <VerticalNavItem
      name="Top Performers"
      customIcon={<TrophyIcon />}
      route={`/users/${collectiveUserId}/public_profile`}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    collectiveUserId: returnTopPerformersUserIdFromCollectivesStore(state.collectives),
  };
};

export default connect(mapStateToProps)(TopPerformers);
