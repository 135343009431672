import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { COMPONENT_MODAL_TYPES } from '@src/main/constants';
import { CreateOrderController } from '@src/main/containers/Orders/CreateOrderController';
import { hideComponentModal } from '@src/actions';
import { Close } from '@src/main/lib/nvstr-common-ui.es';
import { LiveOrderPricing } from '@src/main/containers/Orders/LiveOrderPricing';
import { CreateCartOrderController } from '@src/main/containers/Orders/CreateCartOrderController';
import { LivePriceTracking } from '@src/main/Managers/LivePriceTracking';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: ${({ theme }) => theme.themeColors.overlay};
  z-index: 50000;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const Modal = styled.div`
  position: relative;
  background: ${({ theme }) => theme.themeColors.componentNoOpacity};
  border-radius: 5px;
  z-index: 50001;
`;
const CloseModalIconWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;

  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.themeColors.border};
    }
  }

  z-index: 50001;
`;

const CreateOrderModal = ({ isFromOptimizer, transactionType, orderType, shares, securityId }) => {
  const dispatch = useDispatch();
  const [initFormData] = React.useState({
    transactionType,
    orderType,
    shares,
  });

  const handleComplete = () => {
    hideComponentModal()(dispatch);
  };
  return (
    <CreateOrderController
      securityId={securityId}
      initFormData={initFormData}
      isFromOptimizer={isFromOptimizer}
      onComplete={handleComplete}
      inModal
    />
  );
};

const CreateCartOrderModal = ({ transactionType, orderType, shares, securityId, isFromOptimizer }) => {
  const dispatch = useDispatch();
  const [securityIds] = React.useState([securityId]);
  const [initFormData] = React.useState({
    transactionType,
    orderType,
    shares,
  });

  const handleComplete = () => {
    hideComponentModal()(dispatch);
  };
  return (
    <>
      <LivePriceTracking securityIds={securityIds} />
      <CreateCartOrderController
        securityId={securityId}
        initFormData={initFormData}
        isFromOptimizer={isFromOptimizer}
        onComplete={handleComplete}
      />
    </>
  );
};

const UpdateCartOrderModal = ({
  isFromOptimizer,
  orderId,
  transactionType,
  orderType,
  shares,
  limitPrice,
  stopPrice,
  securityId,
}) => {
  const dispatch = useDispatch();
  const [securityIds] = React.useState([securityId]);
  const [initFormData] = React.useState({
    transactionType,
    orderType,
    shares,
    limitPrice,
    stopPrice,
  });

  const handleComplete = () => {
    hideComponentModal()(dispatch);
  };
  return (
    <>
      <LivePriceTracking securityIds={securityIds} />
      <CreateCartOrderController
        securityId={securityId}
        orderId={orderId}
        initFormData={initFormData}
        isFromOptimizer={isFromOptimizer}
        onComplete={handleComplete}
      />
    </>
  );
};

const LiveOrderPricingModal = ({ securityId }) => {
  const dispatch = useDispatch();
  const onDismissLivePricing = () => {
    hideComponentModal()(dispatch);
  };
  return <LiveOrderPricing securityId={securityId} onDismiss={onDismissLivePricing} />;
};
const renderer = {
  [COMPONENT_MODAL_TYPES.order]: CreateOrderModal,
  [COMPONENT_MODAL_TYPES.createCartOrder]: CreateCartOrderModal,
  [COMPONENT_MODAL_TYPES.editCartOrder]: UpdateCartOrderModal,
  [COMPONENT_MODAL_TYPES.liveLastPrice]: LiveOrderPricingModal,
};

export const ComponentModal = () => {
  const dispatch = useDispatch();
  const { type, props: modalProps } = useSelector((state) => state.application.componentModal);

  React.useEffect(() => {
    //
  }, []);

  if (!type) {
    return null;
  }

  const onDismiss = () => {
    hideComponentModal()(dispatch);
  };
  const handleOverlayClick = () => {
    onDismiss();
  };

  const Component = renderer[type];
  return (
    <Overlay onClick={handleOverlayClick}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <CloseModalIconWrapper onClick={onDismiss}>
          <Close width={16} />
        </CloseModalIconWrapper>
        <Component {...modalProps} />
      </Modal>
    </Overlay>
  );
};
