import React from 'react';
import { useSelector } from 'react-redux';
import { doArraysContainSameElementValues } from '../../../helpers/generalHelpers';
import { useSecurityLookup } from '@src/main/hooks/securities/useSecurity';

const getActualPositionsFromPortfolioData = (data) => {
  const actualPositions = {};
  const securityIds = Object.keys(data);
  securityIds.forEach((id) => {
    const pos = data[id];
    if (Math.abs(pos.shares) > 0) {
      actualPositions[id] = pos;
    }
  });
  return actualPositions;
};

export const usePositionsLookup = () => {
  const storePositions = useSelector((state) => state.portfolio.positions);
  const [positions, setPositions] = React.useState(getActualPositionsFromPortfolioData(storePositions));

  React.useEffect(
    () => {
      setPositions(getActualPositionsFromPortfolioData(storePositions));
    },
    [storePositions]
  );
  return positions;
};

const findUnfeaturedPositions = (positions, securitiesLookup) => {
  const unfeatured = [];
  positions?.forEach((securityId) => {
    const s = securitiesLookup[securityId];
    if (s?.featured === false) {
      unfeatured.push(securityId);
    }
  });
  return unfeatured;
};

export const useUnfeaturedPositions = () => {
  const positions = usePositionsList();
  const securitiesLookup = useSecurityLookup();

  const [unfeaturedPositions, setUnfeaturedPositions] = React.useState(
    findUnfeaturedPositions(positions, securitiesLookup)
  );

  React.useEffect(
    () => {
      const nextUnfeaturedPositions = findUnfeaturedPositions(positions, securitiesLookup);
      if (!doArraysContainSameElementValues(unfeaturedPositions, nextUnfeaturedPositions)) {
        setUnfeaturedPositions(nextUnfeaturedPositions);
      }
    },
    [unfeaturedPositions, positions, securitiesLookup]
  );

  return unfeaturedPositions;
};

const emptyList = [];
const parsePositionsLookupToListBySecurityId = (lookup) => {
  if (!lookup) return emptyList;
  const list = Object.values(lookup);
  return list.filter((l) => 'shares' in l && Math.abs(l.shares) > 0).map((l) => l.security_id);
};

export const usePositionsList = () => {
  const storePositionsLookup = useSelector((state) => state.portfolio.positions);
  const [positionsList, setPositions] = React.useState(parsePositionsLookupToListBySecurityId(storePositionsLookup));

  React.useEffect(
    () => {
      const newPositionsList = parsePositionsLookupToListBySecurityId(storePositionsLookup);
      if (!doArraysContainSameElementValues(newPositionsList, positionsList)) {
        setPositions(newPositionsList);
      }
    },
    [storePositionsLookup, positionsList]
  );

  return positionsList;
};
