import React from 'react';

export const GraduationCap = () => {
  return (
    <svg viewBox="0 0 24 18">
      <g>
        <path d="M12,3L1,9L12,15L21,10.09V17H23V9M5,13.18V17.18L12,21L19,17.18V13.18L12,17L5,13.18Z" />
      </g>
    </svg>
  );
};
