import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  & > svg {
    width: 18px;
    height: 18px;
  }
  & g,
  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function LightningIcon() {
  return (
    <Wrapper>
      <svg viewBox="0 0 11 18" fill="none">
        <path
          d="M3.998 18h-1l1-7h-3.5c-.88 0-.33-.75-.31-.78C1.478 7.94 3.418 4.54 6.008 0h1l-1 7h3.51c.4 0 .62.19.4.66C5.968 14.55 3.998 18 3.998 18z"
          fill="#1F1F1F"
        />
      </svg>
    </Wrapper>
  );
}

export default LightningIcon;
