import React from 'react';

import SecurityFilterSelection from './SecurityFilterSelection';

import { getSecuritiesFilterList } from '../../../helpers/portfolioExplorerHelpers';

const SecuritiesFilterPropertiesList = (props) => {
  const query = props.filterNameQuery || '';

  // TODO: CHANGE getSecuritiesFilterList to AJAX call to API ENDPOINT
  const filters = getSecuritiesFilterList().filter(
    (filter) => filter.slice(0, query.length).toLowerCase() === query.toLowerCase()
  );

  return (
    <div className="securities-filter-list">
      {filters.map((filter) => (
        <SecurityFilterSelection
          key={`${filter}-selection`}
          filterName={filter}
          handleClick={props.handleFilterNameSelection}
        />
      ))}
      {filters.length === 0 && <div className="securities-filter-list-empty">No matches found.</div>}
    </div>
  );
};

export default SecuritiesFilterPropertiesList;
