import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import LoadingIcon from '../misc/LoadingIcon';
import UserContainer from '../../containers/users/UserContainer';
import ProfileAvatar from '../user/ProfileAvatar';
import UserName from '../user/UserName';

import { formatSecurityDataValue } from '../../helpers/securitiesHelpers';
import { isIdeaUsingPriceTarget, isThoughtLeaderIdea, getSecurityIdFromIdea } from '../../helpers/ideaHelpers';

export class IdeaListItem extends Component {
  handleClick = () => {
    const ideaId = this.props.ideaId;
    const idea = this.props.ideas.ideaList[ideaId];

    const newActiveIdea = idea || {
      idea_id: null,
      status: 'user creating idea',
    };
    this.props.handleClick(newActiveIdea);
  };

  render() {
    const userCreatingIdea = this.props.userCreatingIdea;
    const activeIdea = this.props.activeIdea;
    const ideaId = this.props.ideaId;
    const idea = this.props.ideas.ideaList[ideaId];

    if (userCreatingIdea || !idea) {
      return this.renderUserCreatingIdeaView();
    }

    const ideaUserId = idea.user_id;
    const ideaType = idea.idea_type.name;
    const ideaTypeColor = idea.idea_type.id === 0 ? 'buy-text-color' : 'sell-text-color';
    const priceExpectation = isIdeaUsingPriceTarget(idea)
      ? formatSecurityDataValue(idea.price_target, 'price_target')
      : formatSecurityDataValue(idea.expected_return, 'expected_return');

    if (isThoughtLeaderIdea(idea)) {
      return (
        <div
          className={`idea-list-item-container border-accent thought-leader-list-item table-touch-scrolling ${
            activeIdea ? 'active' : ''
          }`}
          onClick={this.handleClick}
        >
          <div className="tl-idea-quick-info-container">
            <div className={`idea-type ${ideaTypeColor}`}>{'Thought Leader'}</div>
          </div>
          <div className="idea-tl-user-container">
            <UserContainer userId={ideaUserId}>
              <UserName withLink={false} />
            </UserContainer>
          </div>
        </div>
      );
    }

    return (
      <div
        className={`idea-list-item-container border-accent table-touch-scrolling ${
          activeIdea ? 'active component-bg' : ''
        }`}
        onClick={this.handleClick}
      >
        <div className="idea-user-container">
          <UserContainer userId={ideaUserId}>
            <ProfileAvatar withLink={false} size={this.props.screenWidth >= 850 ? 'nav' : 'small'} />
            <UserName withLink={false} inline={this.props.screenWidth < 850} />
          </UserContainer>
        </div>
        <div className="idea-quick-info-container">
          <span className={`list-idea-type ${ideaTypeColor}`}>{ideaType}</span>
          {this.props.screenWidth >= 850 && this._renderSecuritySymbol()}
          <div className="idea-price-target">{priceExpectation}</div>
          {this.props.screenWidth < 850 && this._renderSecuritySymbol()}
        </div>
      </div>
    );
  }

  _renderSecuritySymbol = () => {
    const ideaId = this.props.ideaId;
    const idea = this.props.ideas.ideaList[ideaId];
    const security = this.props.securities.lookup[getSecurityIdFromIdea(idea)] || {};
    return (
      <div className={`list-security-symbol stacked-list-security-symbol secondary-text-color`}>
        {security.symbol ? security.symbol.toUpperCase() : ''}
      </div>
    );
  };

  renderUserCreatingIdeaView = () => {
    const activeIdea = this.props.activeIdea;

    return (
      <div
        className={`idea-list-item-container border-accent table-touch-scrolling ${
          activeIdea ? 'active component-bg' : ''
        }`}
        onClick={this.handleClick}
      >
        <div className="idea-user-container">
          <UserContainer userId={this.props.currentUserId}>
            <ProfileAvatar withLink={false} size={this.props.screenWidth >= 850 ? 'nav' : 'small'} />
            <UserName withLink={false} inline={this.props.screenWidth < 850} />
          </UserContainer>
        </div>
        <div className="idea-quick-info-container creating-idea">
          <LoadingIcon icon="fading-3balls" size="small" style={{ marginRight: '8px' }} />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    ideas: state.ideas,
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdeaListItem);
