import React from 'react';
import { ONBOARDING_PATHS } from '../../constants/onboarding';
import { ACCOUNT_TYPES } from '../../constants';
import { Plaid } from '../funding/interstitial/Plaid';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';

export const LinkBankPlaid = ({ onPlaidContinue, onPathChange, onboardingState }) => {
  const handlePlaidContinue = React.useCallback((hadLinkSuccess) => {
    onPlaidContinue(hadLinkSuccess);
  }, []);

  const handleLinkFailure = React.useCallback(() => {
    onPathChange(ONBOARDING_PATHS.postFundingSteps, onboardingState);
  }, []);

  const handleMissingLiveAccount = React.useCallback(() => {
    onPathChange(null, onboardingState, { accountType: ACCOUNT_TYPES.paper });
  }, []);

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.linkBankAccountPlaid}>
      <Plaid
        onPlaidContinue={handlePlaidContinue}
        onPlaidLinkFailure={handleLinkFailure}
        onMissingLiveAccount={handleMissingLiveAccount}
      />
    </LocationContext.Provider>
  );
};
