import Icon from '../../components/misc/Icon';
import React from 'react';

const PlaceholderImage = () => (
  <span className={'news-image-container missing-image'}>
    <span className={'missing-image-icon-container'}>
      <Icon icon="fa-newspaper-o" colorClassName="eye-catching-standalone-text-color" size="supersize" />
    </span>
  </span>
);

export default PlaceholderImage;
