import React from 'react';
import styled from 'styled-components';
import { createDropdownItem, FloatingLabelDropdown } from '../../lib/nvstr-common-ui.es';
import { useBankAccounts } from '../../hooks/funding/useBankAccounts';
import { buildBankIdentifierFromBankAccount } from '../../utils';

const Wrapper = styled.div`
  select {
    border-top: none;
    border-right: none;
    border-left: none;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
`;

export const SelectBankAccount = ({ value, error, onChange }) => {
  const bankAccounts = useBankAccounts();
  const items = [
    createDropdownItem({
      disabled: false,
      value: '',
      text: 'Please select...',
    }),
    ...bankAccounts.map((bankAccount) =>
      createDropdownItem({
        disabled: false,
        value: bankAccount.id.toString(),
        text: buildBankIdentifierFromBankAccount(bankAccount),
      })
    ),
  ];
  return (
    <Wrapper>
      <FloatingLabelDropdown
        name={'bankAccount'}
        label={'Bank Account'}
        items={items}
        value={value}
        onChange={onChange}
        error={error}
      />
    </Wrapper>
  );
};
