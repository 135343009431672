import { LOCK_APP_SCROLL, UNLOCK_APP_SCROLL } from '../constants';

export const lockAppScroll = () => {
  return function (dispatch) {
    dispatch({
      type: LOCK_APP_SCROLL,
    });
  };
};

export const unlockAppScroll = () => {
  return function (dispatch) {
    dispatch({
      type: UNLOCK_APP_SCROLL,
    });
  };
};
