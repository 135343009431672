import { askAdapter } from '../adapters/askAdapter';

import { UPDATE_ASK_PERMISSION } from '../constants';

export const checkAskPermission = (askData) => {
  return function (dispatch) {
    return askAdapter.checkAskPermission(askData).then((response) => {
      const wasAjaxSuccess = response && response.data;
      const warning = response && response.data && response.data.warning ? response.data.warning : null;

      if (wasAjaxSuccess) {
        const isPermitted = response.data.status === 'permitted';
        const isNotPermitted = response.data.status === 'error';
        const isNotPermittedReason = response.data.error;

        dispatch({
          type: UPDATE_ASK_PERMISSION,
          payload: {
            askData,
            isPermitted,
            isNotPermitted,
            isNotPermittedReason,
          },
        });
      }

      return {
        ok: wasAjaxSuccess,
        warning,
      };
    });
  };
};

export const sendAsk = (askData) => {
  return function (dispatch) {
    return askAdapter.sendAsk(askData).then((response) => {
      const ok =
        response &&
        response.data &&
        response.data.asks &&
        Array.isArray(response.data.asks) &&
        response.data.asks.every((ask) => !ask.error);
      const warning = response && response.data && response.data.warning ? response.data.warning : null;

      return {
        ok,
        warning,
        rawResponse: response,
      };
    });
  };
};
