import { SEND_ASK, UPDATE_ASK_PERMISSION } from '../constants';

const defaultState = {
  userLookup: {},
  /*
      [user_id]: {
        security: {
          [security_id]: <reason not permitted>
        }
        idea:
        thought:
      }
    */
};

export default function askReducer(state = defaultState, action) {
  const payload = action.payload || {};
  const askData = payload.askData || {};
  const { isPermitted, isNotPermitted, isNotPermittedReason } = payload;
  const askType = askData.askType;
  const askTypeKey = `${askType}_id`;
  const askTypeId = askData[askTypeKey];
  const userId = askData.asked_user_id || 'CTL';

  const prevUserData = state.userLookup[userId] || {};
  const prevUserAskTypeData = prevUserData[askType] || {};

  switch (action.type) {
    case SEND_ASK:
      return state;

    case UPDATE_ASK_PERMISSION:
      return {
        ...state,
        userLookup: {
          ...state.userLookup,
          [userId]: {
            ...prevUserData,
            [askType]: {
              ...prevUserAskTypeData,
              [askTypeId]: isNotPermitted ? isNotPermittedReason : false,
            },
          },
        },
      };

    default:
      return state;
  }
}
