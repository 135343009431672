import React from 'react';
import { connect } from 'react-redux';
import { hideModal, clearOrderBasket, showModal, logMetricsTrackingEvent } from '../../../../actions';

import Button from '../../../../components/buttons/Button';
import LoadingIcon from '../../../../components/misc/LoadingIcon';

import { createBasicErrorModal } from '../../../../constants/modals';
import { snakeToHyphens } from '../../../../helpers/generalHelpers';
import { findUserIdeaForSecurity } from '../../../../helpers/ideaHelpers';
import { TrackingEvents } from '../../../../utils/tracking/events';
import { FlatButton } from '@src/main/components/buttons';
import styled from 'styled-components';

const TradeButtonWrapper = styled.div`
  button {
    background-color: ${({ theme, isEmphasized }) =>
      isEmphasized ? theme.themeColors.primaryCtaButton : 'transparent'};
    color: ${({ theme, isEmphasized }) => (isEmphasized ? theme.themeColors.buttonText : theme.themeColors.text)};
  }
`;

export class TradeCell extends React.PureComponent {
  render() {
    const columnKeyName = this.props.column.keyName;

    if (!this._isIdeaActive()) {
      return <td className={`security-table-${snakeToHyphens(columnKeyName)}`}></td>;
    }

    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        <div className={'security-table-optimizer-allocation-and-day-change-container security-table-trade-container'}>
          <TradeButtonWrapper className={'security-table-trade'} isEmphasized={this._shouldEmphasizeOptimizedTrade()}>
            <FlatButton onClick={this._handleCreateOptimizedOrder}>Trade</FlatButton>
          </TradeButtonWrapper>
        </div>
      </td>
    );
  }

  _handleCreateOptimizedOrder = () => {
    const { securityId, navigate, dispatch } = this.props;

    this._logCreateOptimizedOrderClick();
    this._showCreatingOrdersModal();

    clearOrderBasket()(dispatch).then((response) => {
      if (response && response.ok) {
        setTimeout(() => {
          hideModal()(dispatch);
          navigate(`/orders?operation=null&security_id=${securityId}`);
        }, 250);
      } else {
        const modalMessage = 'Could not create order. Please try again later.';
        const modal = {
          contentComponent: createBasicErrorModal(modalMessage),
          dismissable: true,
        };
        showModal(modal)(dispatch);
      }
    });
  };

  _showCreatingOrdersModal = () => {
    const modalMessage = 'Loading';
    const contentComponent = (
      <div className={'creating-optimized-orders component-bg'}>
        <LoadingIcon size="medium" />
        <div style={{ marginTop: '4px', marginLeft: '8px', fontSize: '24px' }}>{modalMessage}</div>
      </div>
    );
    const modal = {
      contentComponent,
      size: 'wide',
      dismissable: false,
    };
    showModal(modal)(this.props.dispatch);
  };

  _shouldEmphasizeOptimizedTrade = () => true;

  _returnNonEmphasizedTradeButtonClasses = () => 'btn skel-btn skel-btn-primary-color btn-xsmall btn-fixed-width-50';

  _returnEmphasizedTradeButtonClasses = () => 'btn btn-primary-color btn-xsmall btn-fixed-width-50';

  _returnIdeaList = () => this.props.ideas.ideaList;

  _returnIdea = () =>
    findUserIdeaForSecurity(this.props.currentUser.user_id, this._returnSecurityId(), this._returnIdeaList());

  _isIdeaActive = () => !this._returnIdea() || this._returnIdea().active;

  _returnSecurityId = () => this.props.securityId;

  _logCreateOptimizedOrderClick = () => {
    this._logCreateOrder();
    const { securityId } = this.props;

    const event = 'Clicked Create Single Optimized Order';
    const properties = {
      'Security Id': securityId,
      'Security Symbol': this.props.security.symbol,
    };
    logMetricsTrackingEvent(event, properties)();
  };

  _logCreateOrder = () => {
    // new amp event style that merges all order types
    const { securityId } = this.props;
    const properties = {
      'Security Id': securityId,
      'Security Symbol': this.props.security.symbol,
      'Order Creation Type': 'Single Optimization',
    };
    TrackingEvents.orders.PLACED_TRADE.send(properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    ideas: state.ideas,
  };
};

export default connect(mapStateToProps)(TradeCell);
