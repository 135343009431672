import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

import { useSecurity } from '@src/main/hooks/securities/useSecurity';
import { Page } from '@src/main/components/layout';
import { Container } from '@src/main/components/ui';
import { Body2, Body3, Body5, CheckCircle, H5 } from '@src/main/lib/nvstr-common-ui.es';
import { CreateOrderForm } from '@src/main/containers/Orders/CreateOrderForm';
import { SkeletonButton } from '@src/main/components/buttons';
import { ORDER_TRANSACTION_TYPES, ORDER_TYPES, ORDER_VALUE_TYPES } from '@src/main/constants/orders';
import TradeAddProConPanel from '@src/containers/Orders/TradeAddProConPanel';
import { sortAndFormatOrdersDataForAddProConPanel } from '@src/helpers/ordersHelpers';
import { ROUTES } from '@src/constants/paths';
import { useCurrentIdeas } from '@src/main/hooks/user';
import {
  addIdea,
  deleteAllOrdersV1,
  fetchCurrentUserQuickInfo,
  getOrders,
  getOrdersV1,
  logMetricsTrackingEvent,
} from '@src/actions';
import { PlaceOrderWarnings } from '@src/main/containers/Orders/PlaceOrderWarnings';
import { createAndPlaceOrderV1 } from '@src/actions';
import { Warning } from '@src/main/icons/svg/Warning';
import { pluralize } from '@src/helpers/usefulFuncs';
import { LoadingAnimation } from '@src/main/components/ui/LoadingAnimation';
import { useOrder } from '@src/main/hooks/orders/useOrder';
import { DISPLAY_FORMAT_TYPES, formatValueTo } from '@src/main/utils/numbers';
import { displayOrderType } from '@src/main/utils/orders';
import { TrackingEvents } from '@src/utils/tracking/events';
import { useIsMarketOpen, useIsMarketOpenStatus } from '@src/main/hooks/securities/useIsMarketOpen';
import { FixedBackgroundOverlay } from '@src/main/components/layout/FixedBackgroundOverlay';

const width = '440px';

const LoadingSpinnerWrapper = styled.div``;
const CheckIconWrapper = styled.div`
  svg {
    height: 64px;
    width: 64px;

    * {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  min-width: 300px;
  justify-content: space-between;
`;
const WarningIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 28px;
    width: 28px;

    fill: ${({ theme }) => theme.themeColors.error};
  }
`;
const PlaceOrderHeading = styled.div`
  svg {
    fill: ${({ theme }) => theme.themeColors.positive} !important;
  }
`;
const PlaceOrderHeadingText = styled.div`
  h5 {
    line-height: 1.1;
  }
`;
const OrderStatusRow = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 8px;

  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
`;

const STATES = {
  unSubmitted: 'unsub',
  isSubmitting: 'issub',
  acceptWarnings: 'accW',
  error: 'err',
  placed: 'placed',
  postTrade: 'postTrade',
};

const massageDataForApi = (d) => {
  const { transactionType, orderType, orderValueType, shares, dollarAmount, limitPrice, stopPrice } = d;
  const orderValue =
    orderValueType === ORDER_VALUE_TYPES.shares
      ? { shares: parseFloat(shares) }
      : { cash_amount: parseFloat(dollarAmount) };
  const additionalInputs = {};
  if (orderType === ORDER_TYPES.limit || orderType === ORDER_TYPES.stopLimit) {
    additionalInputs.limit_price = parseFloat(limitPrice);
  }
  if (orderType === ORDER_TYPES.stop || orderType === ORDER_TYPES.stopLimit) {
    additionalInputs.stop_price = parseFloat(stopPrice);
  }

  return {
    operation: transactionType,
    type: orderType,
    is_from_optimizer: false,

    ...orderValue,
    ...additionalInputs,
  };
};

const createIdea = (sid, data) => {
  const { transactionType } = data;
  return {
    security: {
      security_id: sid,
    },
    idea_type: transactionType === ORDER_TRANSACTION_TYPES.buy ? { id: 0, name: 'buy' } : { id: 1, name: 'sell' },
  };
};

const OrderStatus = ({ id }) => {
  const dispatch = useDispatch();
  const { isOpen } = useIsMarketOpenStatus();
  const order = useOrder(id);

  React.useEffect(() => {
    let interval = null;
    const isFilled = order?.filled_shares === order?.shares;
    if (isOpen && !isFilled) {
      interval = setInterval(() => {
        getOrdersV1()(dispatch);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [order, isOpen]);

  React.useEffect(() => {
    if (order?.filled_shares > 0) {
      fetchCurrentUserQuickInfo()(dispatch);
    }
  }, [order]);

  if (!order) return null;

  return (
    <Container column centerAll fullWidth>
      <OrderStatusRow>
        <Body2 thin>Filled:</Body2>
        <Container>
          <Body2 thin>
            {order.filled_shares}&nbsp;{'/'}
          </Body2>
          <Body2 thin> {order.shares} sh</Body2>
        </Container>
      </OrderStatusRow>
      {order.filled_shares > 0 ? (
        <OrderStatusRow>
          <Body2 thin>Avg. Fill Price:</Body2>
          <Container>
            <Body2 thin>{formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, order.average_fill_price)}</Body2>
          </Container>
        </OrderStatusRow>
      ) : null}
    </Container>
  );
};

export const CreateOrderController = ({ securityId, initFormData, isFromOptimizer, onComplete, inModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { symbol } = useSecurity(securityId);
  const ideasLookupBySecurityId = useCurrentIdeas();

  const [state, setState] = React.useState(STATES.unSubmitted);
  const [order, setOrder] = React.useState(null);
  const [placedOrders, setPlacedOrders] = React.useState(null);

  const [error, setError] = React.useState(null);
  const [orderWarnings, setOrderWarnings] = React.useState(null);

  const onCancel = () => {
    setOrder(null);
    setPlacedOrders(null);
    setError(null);
    setOrderWarnings(null);
    setState(STATES.unSubmitted);
  };

  const handleDismissAddProConPanel = () => {
    if (onComplete) {
      onComplete();
    } else {
      navigate(ROUTES.TRADE.build());
    }
  };

  const handlePlacedOrderContinue = () => {
    setState(STATES.postTrade);
  };

  const resubmitOrder = async (order, orderWarnings) => {
    setState(STATES.isSubmitting);
    const response = await createAndPlaceOrderV1(
      order,
      orderWarnings?.map((w) => w.type)
    )(dispatch);
    handlePlaceOrdersResponse(response);
  };

  const handlePlaceOrdersResponse = async (response, params_order, params_warnings) => {
    const { data, isPlaced, order, withWarnings, error } = response;
    if (isPlaced) {
      setPlacedOrders([order]);
      setState(STATES.placed);

      const properties = {
        'Using Basket': isFromOptimizer,
        'From Optimizer': isFromOptimizer,

        'Order Creation Type': 'Manual',
        'Security ID': securityId,
        'Security Symbol': symbol,
      };
      logMetricsTrackingEvent('Create Manual Trade', properties)();
      TrackingEvents.orders.PLACED_TRADE.send(properties);
      return;
    }

    if (withWarnings) {
      setOrderWarnings(data.warnings);
      setState(STATES.acceptWarnings);
      return;
    }

    if (error === 'User already has an order for this security') {
      await deleteAllOrdersV1()(dispatch);
      resubmitOrder(params_order, params_warnings);
      return;
    }
    setError(error);
    setState(STATES.error);
    return;
  };

  const onAcceptAllWarnings = async () => {
    setState(STATES.isSubmitting);
    const response = await createAndPlaceOrderV1(
      order,
      orderWarnings.map((w) => w.type)
    )(dispatch);
    handlePlaceOrdersResponse(response, order, orderWarnings);
  };

  const onSubmit = async (securityId, formData) => {
    setState(STATES.isSubmitting);

    const hasIdea = securityId in ideasLookupBySecurityId;
    if (!hasIdea) {
      const { ok, data, error } = await addIdea(createIdea(securityId, formData))(dispatch);
      const alreadyExistsErrMessage = 'User already has an idea for this security. Send the idea_id to update it.';
      const ideaInResponse = data?.ideas && data.ideas[0];
      const ideaError = ideaInResponse?.error;
      if (!ok && ideaError !== alreadyExistsErrMessage) {
        console.error(ideaError);
        setError(ideaError || 'Something went wrong, please refresh page and try again.');
        setState(STATES.error);
        return;
      }
    }

    const order = {
      security_id: securityId,
      ...massageDataForApi(formData),
      is_from_optimizer: !!isFromOptimizer,
    };
    setOrder(order);
    const response = await createAndPlaceOrderV1(order)(dispatch);
    handlePlaceOrdersResponse(response, order, null);
  };

  if (state === STATES.isSubmitting) {
    return (
      <FixedBackgroundOverlay hide={inModal}>
        <Page width={width}>
          <Wrapper>
            <Container flex={1} column centerAll>
              <Container centerAll>
                <LoadingSpinnerWrapper>
                  <LoadingAnimation withLogo />
                </LoadingSpinnerWrapper>
              </Container>
              <Container top={16}>
                <Body5 thin>Placing Order</Body5>
              </Container>
            </Container>
          </Wrapper>
        </Page>
      </FixedBackgroundOverlay>
    );
  }
  if (state === STATES.placed) {
    const order = placedOrders[0];
    const orderType = order.type;
    const orderTransactionType = order.operation;
    const shares = order.shares;
    return (
      <FixedBackgroundOverlay hide={inModal}>
        <Page width={width}>
          <Container>
            <Wrapper>
              <Container>
                <PlaceOrderHeading>
                  <Container row verticallyCenter>
                    <Container top={4} right={12}>
                      <CheckIconWrapper>
                        <CheckCircle />
                      </CheckIconWrapper>
                    </Container>

                    <Container>
                      <PlaceOrderHeadingText>
                        <H5>{symbol} Order Placed</H5>
                      </PlaceOrderHeadingText>
                      <Container top={3}>
                        <Body5 thin>
                          Your {displayOrderType(orderType)} order to {orderTransactionType} {shares}{' '}
                          {pluralize(shares, 'share')} has been placed
                        </Body5>
                      </Container>
                    </Container>
                  </Container>
                </PlaceOrderHeading>
              </Container>
              <Container flex={1}>
                <OrderStatus id={order.id} />
              </Container>
            </Wrapper>
          </Container>
          <Container centerAll>
            <SkeletonButton fullWidth onClick={handlePlacedOrderContinue}>
              Continue
            </SkeletonButton>
          </Container>
        </Page>
      </FixedBackgroundOverlay>
    );
  }
  if (state === STATES.acceptWarnings) {
    return (
      <FixedBackgroundOverlay hide={inModal}>
        <Page width={width}>
          <Wrapper>
            <PlaceOrderWarnings
              ordersCount={1}
              isFromOptimizer={isFromOptimizer}
              warnings={orderWarnings}
              handleAcceptAllWarnings={onAcceptAllWarnings}
              handleWarningsCancel={onCancel}
            />
          </Wrapper>
        </Page>
      </FixedBackgroundOverlay>
    );
  }
  if (state === STATES.error) {
    return (
      <Page width={width}>
        <Wrapper>
          <Container fullWidth>
            <Container row centerAll>
              <WarningIconWrapper>
                <Warning />
              </WarningIconWrapper>
              <Container left={9} verticallyCenter>
                <H5>Order not placed</H5>
              </Container>
            </Container>
            <Container top={24} row centerAll>
              <Body5>{error}</Body5>
            </Container>
          </Container>
          <Container row centerAll>
            <SkeletonButton fullWidth onClick={onCancel}>
              Dismiss
            </SkeletonButton>
          </Container>
        </Wrapper>
      </Page>
    );
  }
  if (state === STATES.postTrade) {
    return (
      <Page>
        <TradeAddProConPanel
          orders={sortAndFormatOrdersDataForAddProConPanel(placedOrders)}
          closePanel={handleDismissAddProConPanel}
          hideClose
        />
      </Page>
    );
  }

  if (state === STATES.unSubmitted) {
    return (
      <CreateOrderForm
        securityId={securityId}
        initFormData={initFormData}
        isFromOptimizer={isFromOptimizer}
        onSubmit={onSubmit}
      />
    );
  }
  return null;
};
