import React from 'react';
import FacebookIcon from '../assets/SVGS/FacebookIcon';
import GmailIcon from '../assets/SVGS/GmailIcon';
import FAQ from '../containers/UI/Disclaimers/FAQ';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import { H5, Body5, CheckCircle } from '../main/lib/nvstr-common-ui.es';
import { logMetricsTrackingEvent } from '../actions';
import styled from 'styled-components';
import { FlatButton } from '../main/components/buttons';
import { API_URL, BASEURL } from '../adapters';

export const returnIconForNetwork = (network) => {
  const iconLookup = {
    Facebook: <FacebookIcon />,
    Gmail: <GmailIcon />,
  };
  return iconLookup[network] || console.error('No icon found for ' + network);
};

export const handleInterstitalModalClose = (networkPath) => {
  window.location = networkPath;
};

export const bindNetworkDataToInterstitialModalCloseHandler = (network) => {
  const linkToPath = API_URL + network.link_to_path;
  return () => handleInterstitalModalClose(linkToPath);
};

const FAQTextWrapper = styled.div`
  margin: 0 auto;
  max-width: 400px;

  * {
    color: ${({ theme }) => theme.themeColors.text} !important;
  }
`;
const FeatureRowWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 400px;
  max-width: 100%;

  padding-bottom: 16px;

  &:last-child {
    padding-bottom: 0;
  }
`;
const FeatureIconWrapper = styled.div`
  padding-top: 2px;
  margin-right: 10px;

  svg {
    width: 16px;
    height: 16px;
  }

  path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;
const FeatureRow = ({ text }) => {
  return (
    <FeatureRowWrapper>
      <FeatureIconWrapper>
        <CheckCircle width={18} />
      </FeatureIconWrapper>
      {typeof text === 'string' ? <Body5>{text}</Body5> : text}
    </FeatureRowWrapper>
  );
};
const FeatureBody = styled.div`
  padding: 16px 0;
  margin: 0 auto;
  max-width: 376px;
`;
export const createInterstitialModalComponent = (network) => {
  const networkName = network.network;
  return (
    <div className={`data-security-message-container`}>
      <div className="secure-padlock">
        <i className="fa fa-lock fa-3" />
      </div>

      <div className={`heading`}>Your privacy is important to us.</div>

      <FeatureBody>
        <FeatureRow
          text={`Your ${networkName} login credentials are never made available to ${PRODUCT_DISPLAY_NAME}`}
        />
        <FeatureRow text={'We will never contact anyone without your consent'} />
        <FeatureRow text={'Transfer of your information is encrypted end-to-end'} />
      </FeatureBody>

      <FAQTextWrapper>
        <FAQ openInNewTab />
      </FAQTextWrapper>
      <div className={`continue-button`}>
        <FlatButton fullWidth onClick={bindNetworkDataToInterstitialModalCloseHandler(network)}>
          Continue
        </FlatButton>
      </div>
    </div>
  );
};

export const returnImportInterstitialModal = (network) => ({
  className: 'import-interstitial-modal',
  contentComponent: createInterstitialModalComponent(network),
  dismissable: true,
  size: 'wide',
  overrideDismiss: bindNetworkDataToInterstitialModalCloseHandler(network),
});

export const showImportInterstitialModal = (network, actions) => {
  actions.showModal(returnImportInterstitialModal(network));
};

export const logSeenInterstitialModal = (networkName) => {
  const event = 'View Import Connections InterstitialModal';
  const properties = {
    Network: networkName,
  };
  logMetricsTrackingEvent(event, properties)();
};

export const handleImportClick = (network, actions) => {
  const networkName = network.network;
  showImportInterstitialModal(network, actions);
  logSeenInterstitialModal(networkName);
};
