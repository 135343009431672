import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import Dropdown from '../Inputs/Dropdown';

import LoadingIcon from '../../components/misc/LoadingIcon';
import RenderAfterDelay from '../ExtraFunctionalityComponents/RenderAfterDelay';

import { renderInput } from '../../helpers/inputHelpers';

class EditPreferencesForm extends React.Component {
  constructor() {
    super();
    this.state = {
      _didInit: false,
      _isSaving: false,

      formData: {},
      focusedField: null,
      focusNote: {},
      errors: {},
    };
  }

  componentDidMount() {
    const currentUser = this.props.currentUser;
    const formData = {
      privacy: this._returnOptionValueFromValueName(this._returnPrivacyTypes(), currentUser.privacy_preference),
      email_frequency: this._returnOptionValueFromValueName(
        this._returnEmailFrequencyTypes(),
        currentUser.email_frequency_preference
      ),
    };
    this.setState(() => ({ formData }));
    this._setInitComplete();
  }

  render() {
    if (!this._didInit()) {
      return <div className={`edit-profile-form-container`}></div>;
    }
    return (
      <div className={`edit-profile-form-container`}>
        <div className={`saving-form-message-container`}>
          <RenderAfterDelay shouldRender={this.isSavingFormData()} timeDelayToRender={5} minTimeLengthToRender={900}>
            <div className={`centered-loading-icon`}>
              <LoadingIcon icon="fading-3balls" size="small" />
              <div
                className="secondary-text-color"
                style={{
                  paddingLeft: '22px',
                  lineHeight: '22px',
                  fontSize: '13px',
                  fontWeight: '300',
                }}
              >
                Saving...
              </div>
            </div>
          </RenderAfterDelay>
        </div>
        <div className={`edit-preferences-form-container`}>
          {this._renderPrivacyDropdown()}
          {this._renderEmailFrequencyDropdown()}
        </div>
      </div>
    );
  }

  handleDropdownChange = (name, value) => {
    if (name === 'privacy') {
      this._setPrivacyDropdownFormValue(value);
      this._savePrivacyPreference(value.name);
    } else if (name === 'email_frequency') {
      this._setEmailFrequencyDropdownFormValue(value);
      this._saveEmailFrequencyPreference(value.name);
    }
  };

  _setEmailFrequencyDropdownFormValue = (value) => {
    this._setFormDataValue('email_frequency', value);
  };

  _setPrivacyDropdownFormValue = (value) => {
    this._setFormDataValue('privacy', value);
  };

  _returnOptionValueFromValueName = (options, valueName) => {
    let value = null;
    options.forEach((optionData) => {
      if (optionData.name === valueName) {
        value = optionData;
      }
    });
    return value;
  };

  _setFormDataValue = (key, value) => {
    this.setState((prevState) => ({
      formData: {
        ...this.state.formData,
        [key]: value,
      },
    }));
  };

  _renderPrivacyDropdown = () => {
    const input = this._createPrivacyDropdownInputData();
    return this._renderInput(input);
  };

  _renderEmailFrequencyDropdown = () => {
    const input = this._createEmailFrequencyDropdownInputData();
    return this._renderInput(input);
  };

  _createPrivacyDropdownInputData = () => ({
    type: Dropdown,
    typeName: 'Dropdown',
    name: 'privacy',
    label: 'Privacy',
    required: true,
    props: {
      handleChange: this.handleDropdownChange,
      styling: 'dropdown-inline-left',
      values: this._returnPrivacyTypes(),
      includeLabelAsSelection: false,
      forceShowLabel: true,
      showArrowIcons: true,
    },
  });

  _createEmailFrequencyDropdownInputData = () => ({
    type: Dropdown,
    typeName: 'Dropdown',
    name: 'email_frequency',
    label: 'Email Frequency',
    required: true,
    props: {
      handleChange: this.handleDropdownChange,
      styling: 'dropdown-inline-left',
      values: this._returnEmailFrequencyTypes(),
      includeLabelAsSelection: false,
      forceShowLabel: true,
      showArrowIcons: true,
    },
  });

  _renderInput = (input) => {
    return renderInput.call(this, input);
  };

  handleSaveNewFieldData = (name, value) => {
    this.submitNewFieldData(name, value);
  };

  _savePrivacyPreference = (value) => this.handleSaveNewFieldData('privacy', value);

  _saveEmailFrequencyPreference = (value) => this.handleSaveNewFieldData('email_frequency', value);

  submitNewFieldData = (name, value) => {
    this._startSaving();
    this.props.actions.updateUserProperty({ [name]: value }).then((wasSuccess) => {
      this._stopSaving();
      if (!wasSuccess) {
        this._showSaveFailedErrorMessage();
      }
    });
  };

  getCurrentSavedValueForField = (fieldName) => this.props.currentUser[fieldName];

  getCurrentFormValueForField = (fieldName) => this.state.formData[fieldName];

  isSavingFormData = () => this.state._isSaving;

  _returnPrivacyTypes = () => [
    {
      id: 0,
      name: 'private_privacy',
      displayName: 'Private',
      isEnabled: () => true,
      disabledReason: () => null,
    },
    {
      id: 1,
      name: 'connections_privacy',
      displayName: 'Connections',
      isEnabled: () => true,
      disabledReason: () => null,
    },
    {
      id: 2,
      name: 'public_privacy',
      displayName: 'Public',
      isEnabled: () => true,
      disabledReason: () => null,
    },
  ];

  _returnEmailFrequencyTypes = () => [
    {
      id: 0,
      name: 'weekly',
      displayName: 'Weekly',
      isEnabled: () => true,
      disabledReason: () => null,
    },
    {
      id: 1,
      name: 'daily',
      displayName: 'Daily',
      isEnabled: () => true,
      disabledReason: () => null,
    },
    {
      id: 2,
      name: 'never',
      displayName: 'Never',
      isEnabled: () => true,
      disabledReason: () => null,
    },
    {
      id: 3,
      name: 'default',
      displayName: 'Default',
      isEnabled: () => true,
      disabledReason: () => null,
    },
  ];

  _startSaving = () => {
    this.setState(() => ({
      _isSaving: true,
    }));
  };

  _stopSaving = () => {
    this.setState(() => ({
      _isSaving: false,
    }));
  };

  _showSaveFailedErrorMessage = () => {
    const message = 'Unable to save. Please reload page and try again.';
    const contentComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {message}
      </div>
    );
    this.props.actions.showModal({
      contentComponent,
      size: 'wide',
      dismissable: true,
    });
  };

  _didInit = () => this.state._didInit;

  _setInitComplete = () => {
    this.setState(() => ({
      _didInit: true,
    }));
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPreferencesForm);
