import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../../../actions/index';
import { snakeToHyphens, isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import { formatDataValue } from '../../../../helpers/securitiesHelpers';
import { findUserIdeaForSecurity } from '../../../../helpers/ideaHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';
import { getCurrentUserFromState } from '../../../../selectors/currentUser';
import { usePosition } from '../../../../main/hooks/portfolio/usePosition';
import { useCoveredCallCount } from '../../../../main/hooks/orders/useCoveredCallCount';
import { FlatButton } from '../../../../main/components/buttons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/paths';
import { useUserOptionsEnabled } from '../../../../main/hooks/orders/useUserOptionsEnabled';
import { usePriceData } from '../../../../main/hooks/securities/usePriceData';
import { useEquityValue } from '../../../../main/hooks/portfolio/useEquityData';

const ButtonWrapper = styled.div`
  button {
    padding: 0;

    * {
      color: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }
`;

const CoveredCallAction = ({ securityId }) => {
  const navigate = useNavigate();

  const position = usePosition(securityId);
  const coveredCallCount = useCoveredCallCount(securityId);

  const { isEnabled } = useUserOptionsEnabled();

  if (!position || !isEnabled) return null;

  if (coveredCallCount === null) return null;

  const shares = position.shares;
  if (shares < 100 || shares - coveredCallCount * 100 < 100) return null;

  const hc = () => {
    navigate(ROUTES.COVERED_CALL_WIZARD.build(securityId));
  };

  return (
    <ButtonWrapper>
      <FlatButton transparent onClick={hc}>
        Add Covered Call
      </FlatButton>
    </ButtonWrapper>
  );
};

const Cell = ({ securityId }) => {
  const { price } = usePriceData(securityId);
  const { shares } = usePosition(securityId);
  const { value: equity, isLoading } = useEquityValue();

  const value = price * shares;
  const allocationPercentage = value / equity;

  if (shares === 0 || isUndefinedOrNull(shares) || isLoading || isNaN(value) || price === null || isNaN(price)) {
    return null;
  }
  return (
    <span className={`security-table-allocation-container`}>
      <span className={`security-table-allocation-container`}>
        <span className={`security-table-allocation-total-value  `}>{formatDataValue(value, 'price')}</span>
        <span className={`multi-text-box-inline`}>
          <span className={`security-table-allocation-total-value-shares  `}>
            {`${formatDataValue(shares, 'shares')} sh`}
          </span>
          <span className={`security-table-allocation-total-value-percentage  `}>
            {formatDataValue(allocationPercentage, 'percentage')}
          </span>
        </span>
      </span>
    </span>
  );
};

export class PortfolioAllocationCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? this.props.loadingCellComponent : this._renderCellContent()}
      </td>
    );
  }

  // doesn't check for itself in positions store for loading because the rendering of the table is assuming the data is there to start populating rows
  _isLoading = () => {
    const securityId = this.props.securityId;
    return !this.props.securities.lookup[securityId] || !('name' in this.props.securities.lookup[securityId]);
  };

  _returnLinkForHandlingClick = () => {
    const { userId, securityId } = this.props;
    const currentUserId = this.props.currentUser.user_id;
    const ideaUserId = userId || currentUserId;

    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    const ideaId = idea && idea.idea_id;

    const additionalProps = {
      activeTab: 'ideas',
      ideaId,
    };
    const openSecurityPanelConfig = {
      securityId,
      additionalProps,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    return <Cell securityId={this.props.securityId} />;
  };
}

const mapStateToProps = (state) => {
  const currentUser = getCurrentUserFromState(state);
  return {
    currentUser,
    securities: state.securities,
    ideas: state.ideas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioAllocationCell);
