import React from 'react';
import { Text } from '../../text';
import { Body1, defineFontSettings, BaseText } from '../../../lib/nvstr-common-ui.es';
import { colorPalette } from '../../../lib/nvstr-utils.es';
import styled from 'styled-components';
import { Container } from '../../ui';

const HeadingWrapper = styled.div`
  width: 100%;
  padding-top: 24px;

  @media (max-width: 450px) {
    padding-top: 16px;
  }
  @media (max-width: 358px) {
    padding-top: 8px;
  }
`;
const LessonHeadingTextWrapper = styled.div`
  padding-top: 4px;
  .base-text {
    font-size: ${({ isSmall }) => (isSmall ? '48px' : '72px')};
    line-height: ${({ isSmall }) => (isSmall ? '54px' : '80px')};
    * {
      line-height: ${({ isSmall }) => (isSmall ? '54px' : '80px')};
    }
  }

  @media (max-width: 550px) {
    .base-text {
      font-size: ${({ isSmall }) => (isSmall ? '36px' : '48px')};
      line-height: ${({ isSmall }) => (isSmall ? '40px' : '58px')};
      * {
        line-height: ${({ isSmall }) => (isSmall ? '40px' : '58px')};
      }
    }
  }
  @media (max-width: 358px) {
    .base-text {
      font-size: ${({ isSmall }) => (isSmall ? '24px' : '36px')};
      line-height: ${({ isSmall }) => (isSmall ? '28px' : '48px')};
      * {
        line-height: ${({ isSmall }) => (isSmall ? '28px' : '48px')};
      }
    }
  }
`;
const LessonLearnAboutHeadingTextWrapper = styled.div`
  padding-top: 8px;
  .base-text {
    font-size: ${({ isSmall }) => (isSmall ? '16px' : '24px')};
    line-height: ${({ isSmall }) => (isSmall ? '24px' : '36px')};
    * {
      line-height: ${({ isSmall }) => (isSmall ? '24px' : '36px')};
    }
  }

  @media (max-width: 550px) {
    padding-top: 8px;
    .base-text {
      font-size: ${({ isSmall }) => (isSmall ? '16px' : '24px')};
      line-height: ${({ isSmall }) => (isSmall ? '24px' : '36px')};
      * {
        line-height: ${({ isSmall }) => (isSmall ? '24px' : '36px')};
      }
    }
  }
  @media (max-width: 358px) {
    .base-text {
      font-size: 14px;
      line-height: 18px;
      * {
        line-height: 18px;
      }
    }
  }
`;

export const LearnLessonHeading = ({ activeScreenIndex, lessonHeading, lessonReward }) => {
  const textColor = colorPalette.primary.charcoal;

  const isFirstSlide = activeScreenIndex === 0;
  const shrinkHeading = lessonHeading.length > 8;

  const fontSettings = defineFontSettings(
    {
      fontSize: '16px',
      lineHeight: '24px',
    },
    {
      fontSize: '24px',
      lineHeight: '28px',
    }
  );

  return (
    <HeadingWrapper>
      {isFirstSlide ? (
        <>
          <LessonLearnAboutHeadingTextWrapper isSmall={shrinkHeading}>
            <BaseText letterSpacing={0.8} color={textColor} thin uppercase>
              Learn about
            </BaseText>
          </LessonLearnAboutHeadingTextWrapper>

          <LessonHeadingTextWrapper isSmall={shrinkHeading}>
            <BaseText bold uppercase color={textColor}>
              {lessonHeading}
            </BaseText>
          </LessonHeadingTextWrapper>
          {lessonReward > 0 && (
            <Container top={8}>
              <Body1 color={textColor}>{`EARN $${lessonReward}`}</Body1>
            </Container>
          )}
        </>
      ) : (
        <>
          <Text
            isSmall={shrinkHeading}
            fontSettings={fontSettings}
            letterSpacing={0.8}
            color={textColor}
            thin
            uppercase
          >
            {'Learn about '}
          </Text>
          <Text
            isSmall={shrinkHeading}
            fontSettings={fontSettings}
            letterSpacing={0.8}
            color={textColor}
            bold
            uppercase
          >
            {lessonHeading}
          </Text>
        </>
      )}
    </HeadingWrapper>
  );
};
