import React from 'react';
import RebalancerPanel from '../Rebalancer/RebalancerPanel';
import RebalancerPage from '../Rebalancer/RebalancerPage';
import OptimizerPanel from '../Optimizer/OptimizerPanel/OptimizerPanel';
import { getIsUsingRebalancerForPortfolioOptimization } from '../../helpers/portfolioOptimizationHelpers';

class PortfolioOptimization extends React.PureComponent {
  render() {
    const { renderOnPage } = this.props;
    const isUsingRebalancerForOptimization = getIsUsingRebalancerForPortfolioOptimization();
    return null;

    if (isUsingRebalancerForOptimization) {
      return renderOnPage ? <RebalancerPage /> : <RebalancerPanel />;
    } else {
      return <OptimizerPanel renderOnPage={renderOnPage} />;
    }
  }
}

export default PortfolioOptimization;
