import { investmentObjectivesAdapter } from '../adapters/investmentObjectivesAdapter';

import {
  ADD_AVAILABLE_RISK_PROFILES,
  ADD_INVESTMENT_OBJECTIVE_SETTINGS,
  UPDATE_INVESTMENT_OBJECTIVE_SETTINGS,
} from '../constants';

export const getRiskProfiles = (securityIds) => {
  // GET api/optimize/settings/risk_profiles

  return function (dispatch) {
    return investmentObjectivesAdapter.getRiskProfiles(securityIds).then((response) => {
      if (response.data) {
        const riskProfiles = response.data.risk_profiles;
        dispatch({
          type: ADD_AVAILABLE_RISK_PROFILES,
          payload: { riskProfiles },
        });
      } else {
        console.error('Request to populate risk profiles failed.');
      }
      return response;
    });
  };
};

export const getInvestmentObjectiveSettings = () => {
  // GET ‘api/optimize/settings’
  return function (dispatch) {
    return investmentObjectivesAdapter.getInvestmentObjectiveSettings().then((response) => {
      if (response.data) {
        const investmentObjectiveSettings = response.data;
        dispatch({
          type: ADD_INVESTMENT_OBJECTIVE_SETTINGS,
          payload: { investmentObjectiveSettings },
        });
        return response.data;
      } else {
        return { error: 'Unable to get investment objective settings' };
      }
    });
  };
};

export const saveInvestmentObjectiveSettings = (settings) => {
  // PUT ‘api/optimize/settings’
  return function (dispatch) {
    dispatch({
      type: UPDATE_INVESTMENT_OBJECTIVE_SETTINGS,
      payload: { investmentObjectiveSettings: settings },
    });

    return investmentObjectivesAdapter.saveInvestmentObjectiveSettings(settings).then((response) => {
      return response;
    });
  };
};
