import React from 'react';
import styled from 'styled-components';
import { useIdea } from '../../hooks/ideas/useIdea';
import { Container } from '../../components/ui';
import { usePosition } from '../../hooks/portfolio/usePosition';
import { useSecurity } from '../../hooks/securities/useSecurity';
import { PriceChange } from '../../components/securities/PriceChange';
import { Body5, Checkbox, Close } from '../../lib/nvstr-common-ui.es';
import { PRODUCT_NAME } from '../../../appConfig';
import { formatSecurityPropertyValue } from '../../../helpers/securitiesHelpers';
import { Target } from '../../icons/svg/Target';
import InfoIcon from '../../../containers/UI/InfoIcon';
import { removeIdea, updateIdea } from '../../../actions';
import { useDispatch } from 'react-redux';
import { useSortedCurrentIdeasList } from '@src/main/hooks/user/useCurrentIdeas';
import { returnSecurityPanelUrl } from '@src/helpers/securityPanelHelpers';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { FlatButton } from '@src/main/components/buttons';
import { ROUTES } from '@src/constants/paths';

const Wrapper = styled.div`
  * {
    box-sizing: border-box;
  }
`;
const ListWrapper = styled.ol`
  margin: 0px;
  padding: 0px;
`;

const IdentifierWrapper = styled.div`
  padding-right: 8px;
`;
const IdeaDataWrapper = styled.div`
  padding-right: 8px;
`;
const PositionDataWrapper = styled.div`
  padding-right: 8px;
`;
const ItemWrapper = styled.li`
  display: grid;
  grid-template-columns: 30% 30% 30% 10%;
  list-style: none;

  padding: 16px 24px;
  height: ${({ heading }) => (heading ? '44px' : '120px')};

  border-bottom: 1px solid ${({ theme }) => theme.themeColors.appBackground};

  :hover {
    ${({ heading, theme }) =>
      heading ? '' : `cursor: pointer; border: 1px solid ${theme.themeColors.primaryCtaButton};`};
  }

  .cell {
    --column-width-min: 155px;
    min-width: 155px;
  }

  .action-cell {
    --column-width-min: 40px;
    min-width: 40px;
  }

  @media (max-width: 600px) {
    grid-template-columns: 45% 45% 10%;
    ${PositionDataWrapper} {
      display: none;
    }
    .current-alloc {
      display: none;
    }
  }
  @media (max-width: 450px) {
    padding: 16px 8px;
  }
`;
const ActionsWrapper = styled.div`
  --column-width-min: 155px;
  min-width: 155px;
`;
const Symbol = styled.div`
  font-size: 18px;
  line-height: 22px;
`;
const PriceChangeWrapper = styled.div`
  * {
    font-size: 12px;
    line-height: 20px;
    font-weight: 200;
  }
`;
const Name = styled.div`
  font-size: 12px;
  line-height: 22px;
  font-weight: 200;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const AllocValue = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 200;
`;
const IdeaTypeWrapper = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 800;

  color: ${({ theme, type }) => (type === 0 ? theme.themeColors.positive : theme.themeColors.negative)};
`;
const Unfeatured = styled.div`
  padding: 0 6px;
  font-size: 10px;
  line-height: 14px;
`;
const HeadingCell = styled.div`
  position: relative;
  font-size: 12px;
  line-height: 20px;
  font-weight: 200;
  opacity: var(--de-emphasized-text-opacity);
`;
const ExpectedReturn = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
`;
const PriceTargetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  svg {
    height: 15px;
    width: 15px;
    margin-left: 5px;

    path {
      fill: ${({ theme, type }) => theme.themeColors.negative};
    }
  }
`;
const DeleteWrapper = styled.div`
  height: 88px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;

  path {
    fill: ${({ theme }) => theme.themeColors.text};
  }

  > div {
    padding: 0px 8px;
    opacity: 0.3;

    :hover {
      opacity: 1;
      cursor: pointer;
    }
  }
`;
const PriceTarget = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
`;
const InOptimizerWrapper = styled.div`
  padding-top: 2px;
  height: 24px;

  > div {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
  }

  label {
    width: 80px;
  }
  * {
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
  }
`;

const ListHeadings = () => {
  return (
    <ItemWrapper heading>
      <HeadingCell className={'cell'} />
      <HeadingCell className={'cell'}>
        Your Expected Return
        <InfoIcon word={'tooltip_expected_return_idea_panel'} style={{ top: '2px', left: '120px' }} position={'left'} />
      </HeadingCell>
      <HeadingCell className={'cell current-alloc'}>Current Allocation</HeadingCell>
      <HeadingCell className={'cell action-cell'} />
    </ItemWrapper>
  );
};

const IdeaItem = ({ id }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const idea = useIdea(id);

  const {
    security: ideaSecurityBasicData,

    idea_type,
    active,
    conviction,
    price_target,
    expected_return,
    horizon,
  } = idea || {};

  const { id: securityId } = ideaSecurityBasicData || {};
  const { name, symbol, featured } = useSecurity(securityId);
  const { shares, allocation_percent, value: positionValue } = usePosition(securityId);

  const positionValueDisplay = formatSecurityPropertyValue(positionValue, 'current_price');
  const priceChangeDisplay = formatSecurityPropertyValue(shares, 'shares');
  const allocationPercentDisplay = formatSecurityPropertyValue(allocation_percent, 'current_price_change_percent');

  const priceTargetDisplay = formatSecurityPropertyValue(price_target, 'current_price');
  const expectedReturnDisplay = formatSecurityPropertyValue(expected_return, 'percentage');

  const onInOptimizerToggle = () => () => {
    const updatedIdea = {
      ...idea,
      active: !active,
    };
    updateIdea(updatedIdea, idea)(dispatch);
  };
  const onDeleteIdea = () => {
    removeIdea(idea)(dispatch);
  };
  const onOpenSecurityPanel = () => {
    const additionalProps = {};
    const openSecurityPanelConfig = {
      location,
      securityId,
      additionalProps,
    };
    const urlToOpenSecurityPanel = returnSecurityPanelUrl(openSecurityPanelConfig);
    navigate(urlToOpenSecurityPanel);
  };

  if (!idea_type) {
    return null;
  }

  return (
    <ItemWrapper>
      <IdentifierWrapper className={'cell'} onClick={onOpenSecurityPanel}>
        <Symbol>{symbol}</Symbol>
        <Name>{name}</Name>

        <PriceChangeWrapper>
          <PriceChange securityId={securityId} showCurrentPrice />
        </PriceChangeWrapper>
        {featured === false ? (
          <div>
            <span>&nbsp;</span>
          </div>
        ) : (
          <IdeaTypeWrapper type={idea_type.id}>
            {idea_type.id === 0 ? 'BUY' : idea_type.id === 1 ? 'SELL' : ''}
          </IdeaTypeWrapper>
        )}
      </IdentifierWrapper>
      {featured ? (
        <IdeaDataWrapper className={'cell'} onClick={onOpenSecurityPanel}>
          {isNaN(price_target) || price_target === null ? (
            <ExpectedReturn>{expectedReturnDisplay}</ExpectedReturn>
          ) : (
            <PriceTargetWrapper>
              <PriceTarget>{'$' + priceTargetDisplay}</PriceTarget>
              <Target />
            </PriceTargetWrapper>
          )}
          <Name>{conviction.name} conviction</Name>
          {featured && (
            <InOptimizerWrapper>
              <Checkbox
                name={'In-Optimizer'}
                label={'Active'}
                value={active}
                onChange={onInOptimizerToggle}
                stopPropagation
              />
            </InOptimizerWrapper>
          )}
        </IdeaDataWrapper>
      ) : (
        <Unfeatured onClick={onOpenSecurityPanel}>
          <span>This instrument is no longer available on {PRODUCT_NAME}</span>
        </Unfeatured>
      )}
      <PositionDataWrapper className={'cell'} onClick={onOpenSecurityPanel}>
        {Math.abs(shares) > 0 ? (
          <>
            <ExpectedReturn>{'$' + positionValueDisplay}</ExpectedReturn>
            <AllocValue>{priceChangeDisplay + ' sh'}</AllocValue>
            <AllocValue>{allocationPercentDisplay}</AllocValue>
          </>
        ) : (
          <div />
        )}
      </PositionDataWrapper>
      <ActionsWrapper className={'cell action-cell'}>
        {!(Math.abs(shares) > 0) && (
          <DeleteWrapper onClick={onDeleteIdea}>
            <Close width={18} />
          </DeleteWrapper>
        )}
      </ActionsWrapper>
    </ItemWrapper>
  );
};

export const MyIdeasList = () => {
  const navigate = useNavigate();
  const ideas = useSortedCurrentIdeasList();

  const handleGoExplore = () => {
    navigate(ROUTES.EXPLORE_STOCKS.build());
  };

  if (!ideas || ideas.length === 0) {
    return (
      <Container top={24} left={24}>
        <Container bottom={96}>
          <Body5>You have no securities on your watchlist.</Body5>
          <Container top={24}>
            <FlatButton onClick={handleGoExplore}>Go Explore</FlatButton>
          </Container>
        </Container>
      </Container>
    );
  }
  return (
    <Wrapper>
      <ListWrapper>
        <ListHeadings />
        {ideas.map((idea) => (
          <IdeaItem key={idea.id} id={idea.id} />
        ))}
      </ListWrapper>
    </Wrapper>
  );
};
