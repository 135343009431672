import application from './application';
import banners from './banners';
import ideas from './ideas';
import learn from './learn';
import liveTradingSignUp from './liveTradingSignup';
import funding from './funding';
import referral from './referral';
import onboarding from './onboarding';
import orders from './orders';
import security from './security';
import thoughts from './thoughts';
import user from './user';
import banner from './banner';

export const TrackingEvents = {
  application,
  banners,
  funding,
  ideas,
  learn,
  liveTradingSignUp,
  referral,
  onboarding,
  orders,
  security,
  thoughts,
  user,
  banner,
};
