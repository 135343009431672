import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useCurrentUser, useLiveAccount } from '../../hooks/user';
import { logMetricsTrackingEvent, submitLiveTradingSignUpForm } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import { H5, Body5, Lock, Toggle, FloatingLabelTextInput, createToggleItem } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { useFormik } from 'formik';
import { DisclosuresSchema } from '../../constants/formValidationSchemas';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const ToggleFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const ToggleWrapper = styled.div`
  margin-left: 10px;
  min-width: 140px;
`;
const InputWrapper = styled.div`
  padding-top: 40px;

  input {
    border: none !important;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
`;
const AdditionalInputWrapper = styled.div`
  padding: 16px 12px 0 12px;
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 8px;
`;
const PrivacyMessage = styled.div`
  padding-top: 24px;
`;
const IconMessage = styled.div`
  padding-top: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  text-align: left;

  svg {
    height: 24px;
    width: 24px;

    fill: ${({ theme }) => theme.themeColors.text};

    margin-right: 16px;
  }
`;
const Actions = styled.div`
  padding-top: 24px;
  text-align: center;
`;

const getInitialValuesFrom = (currentUser, liveAccount) => {
  const {
    is_control_person,
    is_affiliated_exchange_or_finra,
    is_politically_exposed,

    company_symbols,
    firm_name,
    immediate_family,
    political_organization,
  } = liveAccount;
  return {
    is_control_person: is_control_person || '',
    is_affiliated_exchange_or_finra: is_affiliated_exchange_or_finra || '',
    is_politically_exposed: is_politically_exposed || '',

    company_symbols: company_symbols || '',
    firm_name: firm_name || '',
    immediate_family: immediate_family || '',
    political_organization: political_organization || '',
  };
};

const toggleItems = [createToggleItem({ text: 'Yes', value: 'YES' }), createToggleItem({ text: 'No', value: 'NO' })];

const massageFormData = (v) => v;

const FORM_PAGE_NAME = 'disclosures';

export const EnterDisclosures = ({ onContinue, onboardingState }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const liveAccount = useLiveAccount();

  const [isReady, setIsReady] = React.useState(true);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const form = useFormik({
    initialValues: getInitialValuesFrom(currentUser, liveAccount),
    validationSchema: DisclosuresSchema,
    enableReinitialize: false,
  });
  const { values, errors, setFieldValue, validateForm } = form;

  const handleChange = React.useCallback(
    (name) => (value) => {
      setFieldValue(name, value, false);
    },
    [setFieldValue]
  );

  const submitForm = React.useCallback((values) => {
    const apiFormData = massageFormData(values);
    setIsSubmitting(true);
    return submitLiveTradingSignUpForm(apiFormData, FORM_PAGE_NAME)(dispatch);
  }, []);

  const handleSubmit = React.useCallback(async () => {
    logMetricsTrackingEvent('Submitted Disclosures Form')();

    const validation = await validateForm();
    if (Object.keys(validation).length === 0) {
      const { status } = await submitForm(values);
      if (status === 200) {
        return {
          ok: true,
        };
      } else {
        setFormError('Something went wrong, please try again');
        setIsSubmitting(false);
        return {
          ok: false,
        };
      }
    } else {
      logMetricsTrackingEvent('Failed Live Account Validations', {
        Fields: Object.entries(validation),
        'Form Name': 'disclosures',
      })();

      // could log errors but no form validations on this form
      setIsSubmitting(false);
      return {
        errors: true,
      };
    }
  }, [values]);

  const handleContinueClick = React.useCallback(async () => {
    const { ok } = await handleSubmit();
    if (ok) onContinue(null, onboardingState);
  }, [handleSubmit]);

  React.useEffect(() => {
    logMetricsTrackingEvent('View Disclosures Form')();
  }, []);

  if (!isReady) return <LoadingPage />;

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.disclosures}>
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>Do you have any disclosures?</H5>
          </Heading>

          <Message>
            <Body5>I'm (or a member of my immediate family is)...</Body5>
          </Message>

          <InputWrapper>
            <ToggleFieldWrapper>
              <div>
                <Body5>
                  A director, 10% shareholder, or a senior officer of a <b>publicly traded company.</b>
                </Body5>
              </div>
              <ToggleWrapper>
                <Toggle
                  name={'is_control_person'}
                  value={values.is_control_person}
                  label={null}
                  items={toggleItems}
                  onChange={handleChange}
                  error={errors.is_control_person || null}
                />
              </ToggleWrapper>
            </ToggleFieldWrapper>

            {values.is_control_person === 'YES' && (
              <AdditionalInputWrapper>
                <FloatingLabelTextInput
                  name={'company_symbols'}
                  value={values.company_symbols}
                  label={'Stock Symbol of Company'}
                  onChange={handleChange}
                  error={errors.company_symbols || null}
                />
              </AdditionalInputWrapper>
            )}
          </InputWrapper>
          <InputWrapper>
            <ToggleFieldWrapper>
              <div>
                <Body5>
                  Employed by or associated with a <b>member of</b> either a <b>stock exchange</b> or <b>FINRA</b>.
                </Body5>
              </div>
              <ToggleWrapper>
                <Toggle
                  name={'is_affiliated_exchange_or_finra'}
                  value={values.is_affiliated_exchange_or_finra}
                  label={null}
                  items={toggleItems}
                  onChange={handleChange}
                  error={errors.is_affiliated_exchange_or_finra || null}
                />
              </ToggleWrapper>
            </ToggleFieldWrapper>
            {values.is_affiliated_exchange_or_finra === 'YES' && (
              <AdditionalInputWrapper>
                <FloatingLabelTextInput
                  name={'firm_name'}
                  value={values.firm_name}
                  label={'Name of Firm'}
                  onChange={handleChange}
                  error={errors.firm_name || null}
                />
                <Message>
                  <Body5>
                    If you need duplicate statements and confirms sent to a 3rd party, send us a message at
                    compliance@tornado.com
                  </Body5>
                </Message>
              </AdditionalInputWrapper>
            )}
          </InputWrapper>
          <InputWrapper>
            <ToggleFieldWrapper>
              <div>
                <Body5>
                  A senior military, government, or political official in a <b>non-US country.</b>
                </Body5>
              </div>
              <ToggleWrapper>
                <Toggle
                  name={'is_politically_exposed'}
                  value={values.is_politically_exposed}
                  label={null}
                  items={toggleItems}
                  onChange={handleChange}
                  error={errors.is_politically_exposed || null}
                />
              </ToggleWrapper>
            </ToggleFieldWrapper>
            {values.is_politically_exposed === 'YES' && (
              <>
                <AdditionalInputWrapper>
                  <FloatingLabelTextInput
                    name={'immediate_family'}
                    value={values.immediate_family}
                    label={'Full Name of Person'}
                    onChange={handleChange}
                    error={errors.immediate_family || null}
                  />
                </AdditionalInputWrapper>

                <AdditionalInputWrapper>
                  <FloatingLabelTextInput
                    name={'political_organization'}
                    value={values.political_organization}
                    label={'Name of Organization'}
                    onChange={handleChange}
                    error={errors.political_organization || null}
                  />
                </AdditionalInputWrapper>
              </>
            )}
          </InputWrapper>

          <PrivacyMessage>
            <IconMessage>
              <Lock />
              <Body5>This information will be kept private and it will never appear on your public profile.</Body5>
            </IconMessage>
          </PrivacyMessage>

          <Actions>
            <FormState error={formError} isSubmitting={isSubmitting} />
            <Container fullWidth centerAll>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleContinueClick}>
                Continue
              </FlatButton>
            </Container>
          </Actions>
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
