import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 20px;
  height: 20px;
  & > svg {
    width: 100%;
    height: 100%;
  }

  & g,
  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function DashboardIcon() {
  return (
    <Wrapper>
      <svg fill="none" viewBox="0 0 24 24">
        <path
          d="M19 5v2h-4V5h4M9 5v6H5V5h4m10 8v6h-4v-6h4M9 17v2H5v-2h4M21 3h-8v6h8V3zM11 3H3v10h8V3zm10 8h-8v10h8V11zm-10 4H3v6h8v-6z"
          fill="#000"
        />
      </svg>
    </Wrapper>
  );
}

export default DashboardIcon;
