import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const collectivesAdapter = {
  fetchCollective: (userId) => {
    const options = { ignoreAuth: false };
    const config = {};

    return sendRequest('get', [`${URL}/users?user_ids=${userId}`, {}, config], options).then((response) => response);
  },

  fetchCollectives: () => {
    const options = { ignoreAuth: true };
    const config = {};

    return sendRequest('get', [`${URL}/collectives`, {}, config], options).then((response) => response);
  },
};
