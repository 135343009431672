import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import SearchContainer from '../UI/Search/SearchContainer';

class CreateAllocationConstraintContainer extends Component {
  render() {
    return (
      <div className={`create-allocation-constraint-container`}>
        <SearchContainer
          id="create_allocation_constraint_search"
          icon="fa-search"
          parentComponent="CreateAllocationConstraintContainer"
          placeholder="Search Stocks..."
          iconTopPos={'10px'}
          handleSecurityQuerySelection={this._handleSecurityQuerySelection}
          autoFocus
        />
      </div>
    );
  }

  _handleSecurityQuerySelection = (querySecurityData) => {
    if (!querySecurityData) {
      return false;
    }
    const sanitizedQuerySecurityData = {
      security_id: querySecurityData.id,
    };
    // remove id key since it will be a foreign key for allocation constraint obj
    const keys = Object.keys(querySecurityData);
    keys.forEach((key) => {
      if (key !== 'id') {
        sanitizedQuerySecurityData[key] = querySecurityData[key];
      }
    });
    this.props.handleAllocationConstraintCreation(sanitizedQuerySecurityData);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    optimizer: state.optimizer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAllocationConstraintContainer);
