import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import LoadingIcon from '../../components/misc/LoadingIcon';
import TableCell from './TableCell';
import { getSecurityIdFromIdea } from '../../helpers/ideaHelpers';

class TableRow extends Component {
  render() {
    const { columns, concernable, concernableType } = this.props;
    const idea = concernableType === 'idea' ? concernable : null;
    const securityId = concernableType === 'idea' ? getSecurityIdFromIdea(idea) : null;
    const security = concernableType === 'security' ? concernable : this.props.securities.lookup[securityId];
    const plaidSecurityId = concernable && 'plaid_security_id' in concernable ? concernable['plaid_security_id'] : null;
    const unmappedSecurity =
      concernable && 'unmapped_security' in concernable ? concernable['unmapped_security'] : null;
    if (!this.props.skipLoadingCheck && (!security || !('symbol' in security) || (idea && !idea.idea_id))) {
      return this._renderLoadingRow(columns);
    }

    return (
      <tr className={`security-table-security-row border-accent ${this.props.isFooterRow ? 'footer-row' : ''}`}>
        {columns.map((column, i) => (
          <TableCell
            key={`security-table-${column.keyName}-${
              concernableType || `custom-${this.props.customRowIndex || 'footer'}-${i}`
            }-${concernable ? concernable[`${concernableType}_id`] : 'cell'}`}
            column={column}
            securityId={security ? security.security_id : null}
            plaidSecurityId={plaidSecurityId}
            unmappedSecurity={unmappedSecurity}
            ideaId={idea ? idea.idea_id : null}
            security={security || null}
            idea={idea}
            concernable={concernable}
            additionalTableCellProps={this.props.additionalTableCellProps}
            {...(this.props.additionalTableCellProps || {})}
          />
        ))}
      </tr>
    );
  }

  _renderLoadingRow = (columns) => {
    return (
      <tr className={'security-table-security-row border-accent'}>
        <td className={'security-table-loading-row'} key={`row-${this.props.rowIndex}`} colSpan={columns.length}>
          <LoadingIcon icon="fading-3balls" size="small" style={{ marginRight: '8px' }} />
        </td>
      </tr>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableRow);
