import React from 'react';
import styled from 'styled-components';
import { formatForDisplay } from '@src/helpers/displayHelpers';
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Body5 } from '../../lib/nvstr-common-ui.es';
import { colorPalette } from '../../lib/nvstr-utils.es';
import { useWindowSize } from '@src/main/hooks/util';

Chart.register(...registerables);

const ChartWrapper = styled.div`
  padding-top: 16px;

  position: relative;
  margin: auto;
  max-width: 600px;
  width: 95%;
  height: ${({ height }) => (height ? `${height}` : 'auto')};

  @media (max-width: 420px) {
    padding-top: 0;
    //max-width: 260px;
  }
`;
const ReturnDisclaimerText = styled.div`
  padding-top: 12px;
  text-align: center;

  @media (max-width: 420px) {
    padding-top: 0;
  }
`;
const ForceColor = styled.div`
  color: ${colorPalette.primary.charcoal} !important;
`;

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,

  scales: {
    y: {
      ticks: {
        callback: function(value) {
          return formatForDisplay(value, 'priceInt');
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        rotation: 0,
        callback: function() {
          return '';
        },
      },
    },
  },

  animation: false,

  plugins: {
    legend: {
      display: false,
    },

    tooltip: {
      enabled: true,
      callbacks: {
        title: function(tooltipItem) {
          const item = tooltipItem[0];
          const { label } = item;
          return `Year ${label}`;
        },
        label: function(context) {
          return '';
        },
        afterBody: function(tooltipItem) {
          const item = tooltipItem[0];
          const { raw: rawValue } = item;
          return formatForDisplay(rawValue, 'price');
        },
      },
    },
  },
};

const MAX_HEIGHT_TO_FIT = 776;

export const ReturnsChart = ({ dataset }) => {
  const { height } = useWindowSize();
  const chartRef = React.useRef(null);
  if (!dataset) return null;

  return (
    <div>
      <ChartWrapper height={height < MAX_HEIGHT_TO_FIT ? '160px' : '220px'}>
        <Line ref={chartRef} type="line" data={dataset} options={chartOptions} />
      </ChartWrapper>

      <ReturnDisclaimerText>
        <Body5 isLowContrast thin>
          <ForceColor>Past returns do not guarantee future results.</ForceColor>
        </Body5>
      </ReturnDisclaimerText>
    </div>
  );
};
