import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.li`
  background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};
`;

export const SearchResult = (props) => {
  const handleMouseOver = () => {
    props.makeQueryResultActive && props.makeQueryResultActive(props.result);
  };
  const shouldBeBold = (resultString) => {
    const queryArray = props.query.toLowerCase().split('');
    const resultStringArray = resultString.toLowerCase().split('');

    return queryArray.every((letter, i) => letter === resultStringArray[i]);
  };

  const generateResultText = () => {
    if (props.resultType === 'security') {
      const security = props.result;
      const securityName = security.name;
      return (
        <span className={'security-search-result limit-name-width'}>
          <span className={shouldBeBold(security.symbol) ? 'bold' : ''}>{`${security.symbol}`}</span>
          <span
            className={!shouldBeBold(security.symbol) && shouldBeBold(securityName) ? 'bold' : ''}
            style={{ marginLeft: '2px' }}
          >
            {` ${securityName}`}
          </span>
        </span>
      );
    }
  };

  const resultDisplayText = props.noResults ? 'Your search returned no results.' : generateResultText();

  return (
    <Wrapper
      id={`${props.resultType}-${props.result ? props.result.id : 'no-results'}`}
      className={`search-result ${props.isActive ? 'active' : ''} border-accent`}
      style={props.style}
      onMouseOver={handleMouseOver}
    >
      {resultDisplayText}
    </Wrapper>
  );
};

export default SearchResult;
