import React, { Component } from 'react';
import { connect } from 'react-redux';

import { showInfoIconModal } from '../../actions/index';
import ToolTip from '../../main/icons/Tooltip';
import { withRouter } from '../../main/utils';
import styled from 'styled-components';

export const InfoIconWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Wrapper = styled.span`
  svg {
    * {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

class InfoIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
    };
  }

  componentDidMount = () => {
    this._silenceClickEvents();
  };

  render() {
    const style = this.props.style || {};
    const conditionalProps = this.props.id ? { id: this.props.id } : {};

    return (
      <Wrapper
        ref={(el) => (this.Tooltip = el)}
        tabIndex="-1"
        className={`react-info-icon ${this.props.className || ''}`}
        onFocus={this.handleFocus}
        style={style}
        {...conditionalProps}
      >
        <ToolTip width={'13px'} />
      </Wrapper>
    );
  }

  handleShowTooltip = () => {
    const { word, symbol, customMessage, CustomComponent, className, id, dispatch } = this.props;
    const conditionalProps = id ? { id: id } : {};

    const data = {
      isOldStyle: true,

      elementRef: this.Tooltip,

      definitionKey: word,
      symbol,

      customDefinition: customMessage,
      CustomComponent,

      className,
      conditionalProps,
    };
    showInfoIconModal(data)(dispatch);
  };

  handleFocus = () => {
    this.handleShowTooltip();
  };

  _returnReferenceToDomNode = () => this.Tooltip;

  _returnCustomMessage = () => this.props.customMessage;

  _silenceClickEvents = () => {
    const tooltipDomNode = this._returnReferenceToDomNode();
    const $tooltipDomNode = window.$(tooltipDomNode);
    $tooltipDomNode.on('click', function(e) {
      e.stopImmediatePropagation();
    });
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const connectedComponent = connect(mapStateToProps)(InfoIcon);
export default withRouter(connectedComponent);
