import React from 'react';

import SecurityIdentifierDisplay from '../../../../UI/SecurityIdentifierDisplay';
import NumberWithChangeIndicator from '../../../../../components/UI/NumberWithChangeIndicator';
import { UserAvatarList } from '../../../../users/UserAvatarList';

import { ViewUsersListContexts } from '../../../../../constants/types/context';
import { logMetricsTrackingEvent } from '../../../../../actions';

export class IdeaPerformanceRow extends React.PureComponent {
  constructor() {
    super();
    this.MAX_SHOW_AVATAR_COUNT = 2;
  }

  getLinkFromUserId = (userId) => {
    const { performance } = this.props;
    const { ideas_from } = performance;

    const ideas = ideas_from || [];
    const userIdToIdeaIdLookup = {};
    ideas.forEach((ideaUserData) => {
      userIdToIdeaIdLookup[ideaUserData.user.user_id] = ideaUserData.idea.idea_id;
    });

    const ideaId = userIdToIdeaIdLookup[userId];
    const currentURLQuery = window.location.search;
    const currentURLQueryPrependString = currentURLQuery.length > 0 ? `${currentURLQuery}&` : '?';
    return `${currentURLQueryPrependString}panel=idea&ideaId=${ideaId}`;
  };

  render() {
    const { performance, notificationKeyString } = this.props;
    const { security, percent_change, ideas_from } = performance;

    const ideas = ideas_from || [];
    const userIds = ideas.map((ideaUserData) => ideaUserData.user.user_id);
    const userIdToIdeaIdLookup = {};
    ideas.forEach((ideaUserData) => {
      userIdToIdeaIdLookup[ideaUserData.user.user_id] = ideaUserData.idea.idea_id;
    });

    return (
      <div key={`${notificationKeyString}_${security.security_id}`} className="idea-perf-row">
        <div className={'security-identifier-string-container security-identifier-symbol-string'}>
          <SecurityIdentifierDisplay securityId={security.security_id} symbol={security.symbol} showSymbol inFeed />
        </div>
        <div className={'security-identifier-string-container security-identifier-company-name-string'}>
          <SecurityIdentifierDisplay
            securityId={security.security_id}
            name={security.name}
            showName
            limitNameWidth
            inFeed
          />
        </div>
        <NumberWithChangeIndicator
          valueFormat={'percentageFloat'}
          value={percent_change}
          coloredText
          showPlus
          hideIcon
        />
        <UserAvatarList
          userIds={userIds}
          // getLinkFromUserId={this.getLinkFromUserId}
          getLinkFromUserId={null}
          maxShowIdCount={this.MAX_SHOW_AVATAR_COUNT}
          logEllipsisClick={this.logEllipsisClick}
          showEllipsisUserListAfter
        />
      </div>
    );
  }

  getTimePeriod = () => {
    const { notification } = this.props;
    const { start_phrase } = notification;
    const isDaily = start_phrase.toLowerCase() === 'daily';
    const isPastWeek = start_phrase.toLowerCase() === 'past week';
    const isPastMonth = start_phrase.toLowerCase() === 'monthly';
    let timePeriod = null;
    if (isDaily) {
      timePeriod = 'daily';
    }
    if (isPastWeek) {
      timePeriod = 'weekly';
    }
    if (isPastMonth) {
      timePeriod = 'monthly';
    }
    return timePeriod;
  };

  logEllipsisClick = () => {
    const timePeriod = this.getTimePeriod();
    const event = 'Clicked View Users List';
    const properties = {
      Context: ViewUsersListContexts.IDEA_PERFORMANCE_NOTIFICATION,
      'Time Period': timePeriod,
    };
    logMetricsTrackingEvent(event, properties)();
  };
}
