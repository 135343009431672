export const generateGradientStyling = (userId) => {
  if (userId === null) {
    return {
      background: `#aaa`,
    };
  }

  let userIdString = userId.toString();
  let gradientColorArray = [];
  switch (userIdString[userIdString.length - 1]) {
    case '1':
      gradientColorArray = ['#fff', '#6BB166', '#6BB166'];
      break;
    case '2':
      gradientColorArray = ['#fff', '#028fea', '#028fea'];
      break;
    case '3':
      gradientColorArray = ['#fff', '#808ea0', '#808ea0'];
      break;
    case '4':
      gradientColorArray = ['#fff', '#cdcea8', '#cdcea8'];
      break;
    case '5':
      gradientColorArray = ['#fff', '#808ea0', '#808ea0'];
      break;
    case '6':
      gradientColorArray = ['#fff', '#2d3e55', '#2d3e55'];
      break;
    case '7':
      gradientColorArray = ['#fff', '#6BB166', '#6BB166'];
      break;
    case '8':
      gradientColorArray = ['#fff', '#028fea', '#028fea'];
      break;
    case '9':
      gradientColorArray = ['#fff', '#cdcea8', '#cdcea8'];
      break;
    case '0':
      gradientColorArray = ['#fff', '#2d3e55', '#2d3e55'];
      break;
    default:
      gradientColorArray = ['#fff', '#6BB166', '#6BB166'];
      break;
  }

  return {
    // background: `${gradientColorArray[gradientColorArray.length - 1]}`,
    // background: `-webkit-linear-gradient(left top, ${ gradientColorArray.join(',') } )`,
    // background: `-o-linear-gradient(bottom right, ${ gradientColorArray.join(',') } )`,
    // background: `-moz-linear-gradient(bottom right, ${ gradientColorArray.join(',') } )`,
    // background: `linear-gradient(to bottom right, ${ gradientColorArray.join(',') } )`
  };
};

export const convertSizeToStyling = (avatarSize, sizeOffset) => {
  let size = '100';
  let paddingTop = '31';
  let fontSize = '42';
  sizeOffset = sizeOffset || 0;

  switch (avatarSize) {
    case 'email':
      size = 20 - sizeOffset;
      fontSize = 10;
      break;
    case 'small':
      size = 26 - sizeOffset;
      fontSize = 10;
      break;
    case 'nav':
      size = 40 - sizeOffset;
      fontSize = 14;
      break;
    case 'medium':
      size = 50 - sizeOffset;
      fontSize = 22;
      break;
    case 'large':
      size = 75 - sizeOffset;
      fontSize = 36;
      break;
    case 'profile':
      size = 100 - sizeOffset;
      fontSize = 36;
      break;
    case 'x_large':
      size = 196 - sizeOffset;
      fontSize = 92 - sizeOffset;
      break;
    default:
      break;
  }
  return {
    profile_avatar_container: { height: `${size}px`, width: `${size}px` },
    user_initials_container: {
      fontSize: `${fontSize}px`,
    },
    pixelSize: size,
  };
};
