import React from 'react';

import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import FAQ from '../UI/Disclaimers/FAQ';
import Icon from '../../components/misc/Icon';
import Button from '../../components/buttons/Button';
import { logMetricsTrackingEvent } from '../../actions/trackingActions';
import { FlatButton } from '@src/main/components/buttons';
import styled from 'styled-components';
import { Container } from '../../main/components/ui';
import { OP_CO_NAME, PRODUCT_NAMES } from '../../appConfig';

const SecurityImplementationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  max-width: 420px;
  margin: 0 auto;
  padding: 0 10px 5px 20px;
  text-align: left;

  i {
    font-size: 15px !important;
    line-height: 18px;
    margin-right: 8px;
  }

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const HeadingIconWrapper = styled.div`
  font-size: 24px;
  padding: 8px 0;
`;
const LinkButtonWrapper = styled.div`
  button {
    padding: 4px 8px;
  }
`;

export const DataSecurityMessage = ({
  showFundingMessaging,
  showFaqSection,
  showDisclaimer,
  startCollapsed,
  showAdditionalInsuranceMessage,
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  React.useEffect(() => {
    const event = 'View Security Message Panel In Funding';
    const properties = {};
    logMetricsTrackingEvent(event, properties)();
  }, []);

  const onToggleClick = () => {
    if (isCollapsed) {
      const event = 'Clicked Open Security Message Panel In Funding';
      const properties = {};
      logMetricsTrackingEvent(event, properties)();
    }

    setIsCollapsed(!isCollapsed);
  };

  const CheckIcon = <Icon icon="fa-check-circle-o" colorClassName="success-text-color" size="small" />;

  return (
    <div
      className={`
          data-security-message-container
          border-accent
          ${startCollapsed ? '' : 'always-open'}
          ${isCollapsed && startCollapsed ? 'collapsed' : ''}
          ${!isCollapsed && startCollapsed ? 'open' : ''}
        `}
    >
      <LinkButtonWrapper className={'open-btn'}>
        <FlatButton transparent onClick={onToggleClick}>
          How is my information secured?
        </FlatButton>
      </LinkButtonWrapper>

      <LinkButtonWrapper className={'hide-btn'}>
        <FlatButton transparent onClick={onToggleClick}>
          Hide
        </FlatButton>
      </LinkButtonWrapper>

      <HeadingIconWrapper>
        <i className="fa fa-lock fa-3" />
      </HeadingIconWrapper>

      <div className={'heading'}>At {PRODUCT_DISPLAY_NAME}, we take security seriously.</div>

      <div className={'implementation-list'}>
        <SecurityImplementationWrapper className={'security-implementation'}>
          {CheckIcon}
          <span className={'inline-text'}>We protect your data with bank level security</span>
        </SecurityImplementationWrapper>
        <SecurityImplementationWrapper className={'security-implementation'}>
          {CheckIcon}
          <span className={'inline-text'}>{"We don't store your online banking credentials"}</span>
        </SecurityImplementationWrapper>
        {showFundingMessaging && (
          <>
            {showAdditionalInsuranceMessage ? (
              <SecurityImplementationWrapper className={'security-implementation'}>
                {CheckIcon}
                <span className={'inline-text'}>
                  <span className={'inline-text'}>
                    Your brokerage account is protected up to $500,000 by
                    {'\u00A0'}
                  </span>
                  <a
                    href="https://www.sipc.org/for-investors/what-sipc-protects"
                    target="_blank"
                    rel="no-follow noopener noreferrer"
                  >
                    SIPC
                  </a>
                  <span className={'inline-text'}>{", and up to $37.5 million by Lloyd's of London"}</span>
                </span>
              </SecurityImplementationWrapper>
            ) : (
              <SecurityImplementationWrapper className={'security-implementation'}>
                {CheckIcon}
                <span className={'inline-text'}>
                  <span className={'inline-text'}>
                    Your brokerage account is protected up to $500,000 by
                    {'\u00A0'}
                  </span>
                  <a
                    href="https://www.sipc.org/for-investors/what-sipc-protects"
                    target="_blank"
                    rel="no-follow noopener noreferrer"
                  >
                    SIPC
                  </a>
                </span>
              </SecurityImplementationWrapper>
            )}
          </>
        )}
        <SecurityImplementationWrapper className={'security-implementation'}>
          {CheckIcon}
          <span className={'inline-text'}>
            <span className={'inline-text'}>We're a member of FINRA - check us out on{'\u00A0'}</span>
            <a
              href="https://brokercheck.finra.org/firm/summary/269966"
              target="_blank"
              rel="no-follow noopener noreferrer"
            >
              BrokerCheck
            </a>
          </span>
        </SecurityImplementationWrapper>
      </div>
      {showFaqSection && <FAQ />}
      {showDisclaimer && (
        <div className={'insurance-disclaimer'}>
          <p>
            {OP_CO_NAME} is a member of SIPC. SIPC provides insurance coverage of up to $500,00, including up to
            $250,000 of cash. Your account is also protected by additional insurance up to $37.5 million, including
            $900,000 of cash, underwritten by Lloyd's of London. Insurance does not cover market losses.
          </p>
        </div>
      )}
    </div>
  );
};

export default DataSecurityMessage;
