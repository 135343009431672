import React from 'react';
import { defineFontSettings, Body5 } from '@src/main/lib/nvstr-common-ui.es';
import { colorPalette } from '@src/main/lib/nvstr-utils.es';
import { customParseFloat } from '../../../../../../helpers/numberHelpers';
import { LearnLessonBody } from '../../../../../components/learn/subComponents';
import { calculateRecurringInvestmentEndingValue } from '../../../../../utils';
import styled from 'styled-components';
import { Text } from '../../../../../components/text';
import { Spacing } from '../../../../../components/layout';
import { formatForDisplay } from '../../../../../../helpers/displayHelpers';
import { RECURRING_DEPOSIT_RATES } from '../../../../../constants';
import { doXTimes } from '../../../../../../helpers/usefulFuncs';

const Wrapper = styled.div`
  padding: 24px 8px;
`;
const TimeValueRowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
`;
const TimeWrapper = styled.div`
  width: 100px;
`;
const DollarSignWrapper = styled.div`
  width: 20px;
`;
const ValueWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
`;
const LargestValueWrapper = styled.div`
  position: relative;
`;
const HideValue = styled.div`
  opacity: 0;
`;

const fontSettings = defineFontSettings(
  {
    fontSize: '14px',
    lineHeight: '24px',
  },
  {
    fontSize: '18px',
    lineHeight: '24px',
  }
);

const TimeValueRow = ({ investmentTimeHorizon, largestValue, time, value, color }) => {
  const fromNow = investmentTimeHorizon - time;
  const timeDisplay = fromNow === 0 ? 'NOW:' : `${fromNow} years:`;
  return (
    <TimeValueRowWrapper>
      <TimeWrapper>
        <Text fontSettings={fontSettings} color={color} bold>
          {timeDisplay}
        </Text>
      </TimeWrapper>
      <DollarSignWrapper>
        <Text fontSettings={fontSettings} color={color} bold>
          $
        </Text>
      </DollarSignWrapper>
      <LargestValueWrapper>
        <HideValue>
          <Text fontSettings={fontSettings} color={color} bold>
            {formatForDisplay(largestValue, 'price').split('$')[1]}
          </Text>
        </HideValue>
        <ValueWrapper>
          <Text fontSettings={fontSettings} color={color} bold>
            {formatForDisplay(value, 'price').split('$')[1]}
          </Text>
        </ValueWrapper>
      </LargestValueWrapper>
    </TimeValueRowWrapper>
  );
};

const RecurringTimeValueLoss = ({ data, color }) => {
  return data.map((d) => (
    <TimeValueRow
      key={d.time}
      investmentTimeHorizon={data[0].time}
      largestValue={data[0].value}
      time={d.time}
      value={d.value}
      color={color}
    />
  ));
};

export const RecurringInvestmentMissedOpportunity = ({ defaultInputs }) => {
  const { startingValue, recurringDepositAmount, recurringRate, horizonYears, returnRate } = defaultInputs;
  const textColor = colorPalette.primary.charcoal;

  const [recurringTimeValueData, setRecurringTimeValueData] = React.useState([]);

  React.useEffect(
    () => {
      const { timeStepCalculator } = calculateRecurringInvestmentEndingValue({
        startingValue,
        recurringDepositAmount,
        recurringRate,
        horizonYears,
        returnRate,
      });

      const createTimeValueRows = (horizonYears) => {
        const intervals = [horizonYears];
        doXTimes(3, (x) => {
          const interval = horizonYears - 5 * (x + 1);
          if (interval > 0) {
            intervals.push(interval);
          }
        });
        return intervals.map((interval) => {
          return {
            time: interval,
            value: parseFloat(timeStepCalculator(interval).toFixed(2)),
          };
        });
      };

      const rtvd = createTimeValueRows(horizonYears);
      setRecurringTimeValueData(rtvd);
    },
    [defaultInputs]
  );

  const textBodyData = [
    {
      text: 'The longer you wait to start investing, the less you may make.',
    },
  ];

  return (
    <Wrapper>
      <LearnLessonBody data={textBodyData} textColor={textColor} />
      <Spacing top={24}>
        <Text fontSettings={fontSettings} color={textColor} bold>
          If you start:
        </Text>
      </Spacing>
      <Spacing top={24}>
        <RecurringTimeValueLoss data={recurringTimeValueData} color={textColor} />
      </Spacing>
      <Spacing top={24}>
        <Body5 color={textColor}>
          {`Calculated with an initial investment of $${startingValue} and a ${
            recurringRate === RECURRING_DEPOSIT_RATES.monthly ? 'monthly' : 'yearly'
          } recurring investment of $${recurringDepositAmount} with an average yearly return of ${returnRate *
            100}% over ${horizonYears} years `}
        </Body5>
      </Spacing>
    </Wrapper>
  );
};
