import { SHOW_INFO_ICON_MODAL, HIDE_INFO_ICON_MODAL } from '../constants';

export const showInfoIconModal = (data) => {
  return function (dispatch) {
    dispatch({
      type: SHOW_INFO_ICON_MODAL,
      payload: data,
    });
  };
};

export const hideInfoIconModal = () => {
  return function (dispatch) {
    dispatch({
      type: HIDE_INFO_ICON_MODAL,
    });
  };
};
