export const requireLogin = function(_window) {
  (_window || window).location = '/?loginRequired=true';
};

export const requestRequiresAuthentication = (response) => {
  if (!response) {
    return null;
  }
  if (
    response.error &&
    response.error.response &&
    response.error.response.status &&
    response.error.response.status === 401
  ) {
    setTimeout(() => {
      window.location = '/?loginRequired=true';
    }, 2000);
  }
};
