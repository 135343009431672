import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { TrackingEvents } from '../../../utils/tracking/events';
import { REFERRAL_TYPES } from '../../../utils/tracking/events/referral';
import { logMetricsTrackingEvent } from '../../../actions';
import { ROUTES } from '../../../constants/paths';
import { useCurrentUser } from '../../hooks/user';
import { SkeletonBannerActionsUnder } from './templates/SkeletonBannerActionsUnder';
import { pickOne, roll } from '../../../helpers/usefulFuncs';
import { Facebook } from '../../../containers/Share/Facebook';
import { Twitter } from '../../../containers/Share/Twitter';
import { LinkedIn } from '../../../containers/Share/LinkedIn';
import {
  REFERRAL_SHARE_LINK_CHANNELS,
  REFERRAL_SHARE_LINK_CONTEXTS,
  useCurrentUserReferral,
} from '../../hooks/user/useCurrentUserReferral';
import { PRODUCT_DISPLAY_FULL_NAME } from '../../../appConfig';

// removed: 3.28.22
// const tg1 = {
//   id: 0,
//   message:
//     'Have friends or family members that could benefit from Tornado? Invite them! For a limited time, referrals get you both a chance at up to $1,000 to invest.',
//   ctaButtonText: null,
// };

const tg2 = {
  id: 1,
  message:
    "Successful investors know the value of debating ideas. For a limited time, invite a friend and you'll both get up to $1,000 to invest when they make a deposit.",
  ctaButtonText: null,
};

const tg3 = {
  id: 2,
  message:
    "See what your friends are trading. For a limited time, invite a friend and you'll both get up to $1,000 to invest when they make a deposit.",
  ctaButtonText: null,
};

const tg4 = {
  id: 4,
  message:
    'Investing is for everyone. Refer a friend and you’ll both get up to $1,000 to invest when they make a deposit! Limited time only.',
  ctaButtonText: null,
};

const tg5 = {
  id: 5,
  message: `Get up to $1,000 to invest when you refer a friend to ${PRODUCT_DISPLAY_FULL_NAME}! Limited time only.`,
  ctaButtonText: null,
};

const tg6 = {
  id: 6,
  message: 'Limited time only! Refer a friend and you’ll both get up to $1,000 to invest when they make a deposit.',
  ctaButtonText: null,
};

const tg7 = {
  id: 7,
  message: `Invite a friend to join ${PRODUCT_DISPLAY_FULL_NAME} and you’ll both get up to $1,000 to invest when they make a deposit. Limited time only!`,
  ctaButtonText: null,
};

const highPriorityTgs = [tg2, tg3];
const lowPriorityTgs = [tg4, tg5, tg6, tg7];

const selectTg = () => {
  if (roll(0.5)) {
    return pickOne(highPriorityTgs);
  } else {
    return pickOne(lowPriorityTgs);
  }
};

const Content = styled.div`
  padding-top: 16px;

  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const SocialButtonWrapper = styled.div`
  padding-top: 6px;
  padding-bottom: 2px;

  .social-button {
    display: inline-block;
  }
`;

const networks = {
  Facebook: 'Facebook',
  Twitter: 'Twitter',
  LinkedIn: 'LinkedIn',
};

export const ReferralActionBanner = (props) => {
  const { encodedInviteLink: encodedShareLink } = useCurrentUserReferral(
    REFERRAL_SHARE_LINK_CONTEXTS.FEED,
    REFERRAL_SHARE_LINK_CHANNELS.SOCIAL
  );

  const navigate = useNavigate();
  const [testGroup] = React.useState(selectTg);

  const extendedHandleCTAClick = () => {
    const eventProperties = {
      'Test Group Id': testGroup.id,
    };
    navigate(ROUTES.REWARDS.build());

    props.onCTAClick(eventProperties);
  };

  const logSocialClick = (network) => {
    const event = 'Referrals - Click Social Share';
    const properties = {
      Network: network,
      Context: 'Rewards Page',
    };
    logMetricsTrackingEvent(event, properties)();

    const networkReferralTypeConversion = {
      Facebook: REFERRAL_TYPES.facebook,
      Twitter: REFERRAL_TYPES.twitter,
      LinkedIn: REFERRAL_TYPES.linkedIn,
    };
    const networkReferralType = networkReferralTypeConversion[network];
    TrackingEvents.referral.USE_REFERRAL_ACTION.send(networkReferralType);
  };

  React.useEffect(() => {
    const eventProperties = {
      'Test Group Id': testGroup.id,
    };
    props.onView(eventProperties);
  }, []);

  return (
    <SkeletonBannerActionsUnder
      {...props}
      message={testGroup.message}
      onCTAClick={extendedHandleCTAClick}
      ctaButtonText={'Invite Friends'}
    >
      <Content>
        <div style={{ textAlign: 'center' }}>
          <SocialButtonWrapper>
            <Facebook url={encodedShareLink} additionalActionOnClick={() => logSocialClick(networks.Facebook)} />
          </SocialButtonWrapper>
        </div>
        <div style={{ textAlign: 'center' }}>
          <SocialButtonWrapper>
            <Twitter
              url={encodedShareLink}
              message={
                '.@tornadoinvest is a new, collaborative way to #invest. Plus they will give you up to $1,000 #free cash to invest when you make a deposit. #InvestBetterTogether.'
              }
              additionalActionOnClick={() => logSocialClick(networks.Twitter)}
            />
          </SocialButtonWrapper>
        </div>
        <div style={{ textAlign: 'center' }}>
          <SocialButtonWrapper>
            <LinkedIn url={encodedShareLink} additionalActionOnClick={() => logSocialClick(networks.LinkedIn)} />
          </SocialButtonWrapper>
        </div>
      </Content>
    </SkeletonBannerActionsUnder>
  );
};
