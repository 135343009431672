import { LOADING_SECURITY_ORDER_PRICE_DATA, ADD_SECURITY_ORDER_PRICE_DATA } from '../constants';

const defaultState = {
  securities: {},
};

export default function securitiesOrderPriceReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_SECURITY_ORDER_PRICE_DATA:
      const securitiesPriceData = action.payload.securitiesPriceData;
      const updatedPriceData = {};
      securitiesPriceData.forEach((securityData) => {
        updatedPriceData[securityData.security_id] = {
          ...(state.securities[securityData.security_id] || {}),
          loading: false,
          ...securityData,
        };
      });
      return {
        ...state,
        securities: {
          ...state.securities,
          ...updatedPriceData,
        },
      };

    case LOADING_SECURITY_ORDER_PRICE_DATA:
      const securitiesListById = action.payload.securitiesListById;
      const newLoadingPriceData = {};
      securitiesListById.forEach((id) => {
        newLoadingPriceData[id] = {
          ...(state.securities[id] || {}),
          loading: true,
        };
      });
      return {
        ...state,
        securities: {
          ...state.securities,
          ...newLoadingPriceData,
        },
      };

    default:
      return state;
  }
}
