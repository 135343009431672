import {
  Calendar,
  Microchip,
  Bank,
  Fire,
  Heartbeat,
  Plug,
  PercentSign,
  Tree,
  Money,
  Compass,
  Lightbulb,
} from '../lib/nvstr-common-ui.es';
import { SECURITY_FILTER_PRESETS } from '@src/main/constants/securityFilterPreset';

class SecurityFilterTag {
  constructor({ type, icon, iconSize, iconColor, text, presetFilter, eventName }) {
    this.type = type;
    this.eventName = eventName;

    this.icon = icon;
    this.iconSize = iconSize;
    this.iconColor = iconColor;

    this.displayText = text;
    this.presetFilter = presetFilter;
  }
}

export const SECURITY_FILTER_TAG = {
  UPCOMING_EARNINGS: new SecurityFilterTag({
    type: 'upcoming_earnings',
    icon: Calendar,
    iconSize: 14,
    iconColor: null,
    text: 'Upcoming Earnings',
    eventName: 'Upcoming Earnings',
    presetFilter: SECURITY_FILTER_PRESETS.UPCOMING_EARNINGS,
  }),
  TECH_SECTOR: new SecurityFilterTag({
    type: 'tech_sector',
    icon: Microchip,
    iconSize: 14,
    iconColor: null,
    text: 'Tech Sector',
    eventName: 'Tech Sector',
    presetFilter: SECURITY_FILTER_PRESETS.TECH_SECTOR,
  }),
  FINANCIAL_SECTOR: new SecurityFilterTag({
    type: 'financial_sector',
    icon: Bank,
    iconSize: 14,
    iconColor: null,
    text: 'Financial Sector',
    eventName: 'Financial Sector',
    presetFilter: SECURITY_FILTER_PRESETS.FINANCIAL_SECTOR,
  }),
  TRENDING_STOCKS: new SecurityFilterTag({
    type: 'trending_stocks',
    icon: Fire,
    iconSize: 14,
    iconColor: null,
    text: 'Trending Stocks',
    eventName: 'Trending Stocks',
    presetFilter: SECURITY_FILTER_PRESETS.FINANCIAL_SECTOR,
  }),
  HEALTHCARE_SECTOR: new SecurityFilterTag({
    type: 'healthcare_sector',
    icon: Heartbeat,
    iconSize: 16,
    iconColor: null,
    text: 'Healthcare Sector',
    eventName: 'Healthcare Sector',
    presetFilter: SECURITY_FILTER_PRESETS.HEALTHCARE_SECTOR,
  }),
  ENERGY_SECTOR: new SecurityFilterTag({
    type: 'energy_sector',
    icon: Plug,
    iconSize: 14,
    iconColor: null,
    text: 'Energy Sector',
    eventName: 'Energy Sector',
    presetFilter: SECURITY_FILTER_PRESETS.ENERGY_SECTOR,
  }),
  JUICY_DIVIDENDS: new SecurityFilterTag({
    type: 'juicy_dividends',
    icon: PercentSign,
    iconSize: 14,
    iconColor: null,
    text: 'Juicy Dividends',
    eventName: 'Juicy Dividends',
    presetFilter: SECURITY_FILTER_PRESETS.JUICY_DIVIDENDS,
  }),
  GROWTH_STOCKS: new SecurityFilterTag({
    type: 'growth_stocks',
    icon: Tree,
    iconSize: 14,
    iconColor: null,
    text: 'Growth Stocks',
    eventName: 'Growth Stocks',
    presetFilter: SECURITY_FILTER_PRESETS.GROWTH_STOCKS,
  }),
  VALUE_STOCKS: new SecurityFilterTag({
    type: 'value_stocks',
    icon: Money,
    iconSize: 14,
    iconColor: null,
    text: 'Value Stocks',
    eventName: 'Value Stocks',
    presetFilter: SECURITY_FILTER_PRESETS.VALUE_STOCKS,
  }),
  FAMOUS_INVESTORS: new SecurityFilterTag({
    type: 'famous_investors',
    icon: Compass,
    iconSize: 16,
    iconColor: null,
    text: 'Famous Investors',
    eventName: 'Famous Investors',
    presetFilter: SECURITY_FILTER_PRESETS.FAMOUS_INVESTORS,
  }),
  FRIENDS_IDEAS: new SecurityFilterTag({
    type: 'connection_ideas',
    icon: Lightbulb,
    iconSize: 16,
    iconColor: null,
    text: "Friends' Ideas",
    eventName: "Friends' Ideas",
    presetFilter: SECURITY_FILTER_PRESETS.FRIENDS_IDEAS,
  }),
};
