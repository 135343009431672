import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const plaidInvestmentAdapter = {
  getPlaidInvestmentConfigFromApi: () => {
    const options = { ignoreAuth: true };
    return sendRequest('get', [`${URL}/investment_accounts/instant/config`], options);
  },

  linkInvestmentAccountWithPlaid: (params) => {
    return sendRequest('post', [`${URL}/investment_accounts/account`, params]);
  },

  deleteInvestmentAccountWithPlaid: (plaidAccountId) => {
    return sendRequest('delete', [`${URL}/investment_accounts/account/${plaidAccountId}`]);
  },

  getPlaidInvestmentAccountLinkToken: (plaidAccountId) => {
    return sendRequest('get', [`${URL}/investment_accounts/account/${plaidAccountId}/link_token`]);
  },

  getPlaidInvestmentAccountPortfolioSync: (plaidAccountId) => {
    return sendRequest('get', [`${URL}/investment_accounts/account/${plaidAccountId}/portfolio_sync`]);
  },
};
