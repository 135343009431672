import React from 'react';
import styled from 'styled-components';
import { BaseText, defineFontSettings } from '../../../../lib/nvstr-common-ui.es';

export const TableSymbol = (props) => {
  const fontSettings = defineFontSettings(
    { fontSize: '18px', lineHeight: '24px' },
    { fontSize: '20px', lineHeight: '24px' }
  );
  return (
    <BaseText fontSettings={fontSettings} {...props} thin>
      {props.children}
    </BaseText>
  );
};

const TableNameWrapper = styled.div`
  flex: 1;
  min-width: 0; /* or some value */

  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const TableName = (props) => {
  const { children, width } = props;
  const fontSettings = defineFontSettings(
    { fontSize: '12px', lineHeight: '18px' },
    { fontSize: '12px', lineHeight: '18px' }
  );
  return (
    <TableNameWrapper width={width}>
      <BaseText fontSettings={fontSettings} isLowContrast {...props} thin>
        {children}
      </BaseText>
    </TableNameWrapper>
  );
};

export const InlinePriceValue = (props) => {
  const fontSettings = defineFontSettings(
    { fontSize: '12px', lineHeight: '14px' },
    { fontSize: '12px', lineHeight: '14px' }
  );
  return (
    <BaseText fontSettings={fontSettings} {...props}>
      {props.children}
    </BaseText>
  );
};
