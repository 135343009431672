import React from 'react';

import Button from '../../components/buttons/Button';

import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import { isInputValid } from '../../helpers/inputHelpers';
import { logMetricsTrackingEvent } from '../../actions/trackingActions';
import { FlatButton } from '../../main/components/buttons';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  text-align: center !important;

  > div {
    text-align: center !important;
  }

  button {
    * {
      font-size: 10px !important;
      line-height: 14px !important;
    }
    padding: 4px 8px !important;
  }
`;

class TextNumberField extends React.Component {
  constructor() {
    super();

    this.state = {
      warnings: [],
    };
  }

  render() {
    return (
      <div className={`text-number-field ${this._returnName()} ${this._returnClassName()}`}>
        <fieldset className={`text-field ${this.props.size === 'small' ? 'field-small' : ''} `}>
          {this._renderPrefix()}
          {this._renderSuffix()}
          {this._renderLabel()}
          {this._renderInput()}
          {this._renderWarnings()}
          {this._renderUnderline()}
          {this._renderErrorMessage()}
        </fieldset>
      </div>
    );
  }

  _renderPrefix = () => {
    if (this.props.prefix) {
      return <div className={`input-prefix`}>{this.props.prefix}</div>;
    } else {
      return null;
    }
  };

  _renderSuffix = () => {
    if (this.props.suffix) {
      return <div className={`input-suffix`}>{this.props.suffix}</div>;
    } else {
      return null;
    }
  };

  _renderLabel = () => {
    if (this.props.hideLabel) {
      return null;
    }
    return (
      <label
        htmlFor={this._returnLabel()}
        className={this._isFocusedOrHasValue() && this._isFloatingLabelStyle() ? 'text-field-lifted-label' : ''}
      >
        {this._returnLabel()}
      </label>
    );
  };

  _renderInput = () => {
    return (
      <input
        type="number"
        name={this._returnName()}
        className={this._returnInputClassName()}
        onChange={this._handleChange}
        placeholder={this._returnPlaceholder()}
        value={this._returnValue()}
        step={this.props.step || '0.01'}
        dir={this.props.dir || 'ltr'}
        autoFocus={this.props.autoFocus}
        autoComplete={this.props.autoComplete}
        onBlur={this._handleBlur}
      />
    );
  };

  _returnWarnings = () => this.state.warnings;

  _areWarnings = () => this.state.warnings.length > 0;

  _setWarnings = (warnings) => {
    const warningsNormalized = Array.isArray(warnings) ? warnings : [warnings];
    const uniqueWarnings = warningsNormalized.filter((warning) => this._returnWarnings().indexOf(warning) < 0);
    this.setState((prevState) => ({
      warnings: [...prevState.warnings, ...uniqueWarnings],
    }));
  };

  _clearWarnings = () => {
    this.setState((prevState) => ({
      warnings: [],
    }));
  };

  _handleDismissWarnings = () => {
    this._clearWarnings();
    this._logDismissedWarnings();
  };

  _renderWarning = (message, key) => {
    return (
      <p key={key} className={'text-center'}>
        {message}
      </p>
    );
  };

  _renderDismissWarningsBtn = () => {
    return (
      <ButtonWrapper>
        <FlatButton onClick={this._handleDismissWarnings}>Dismiss</FlatButton>
      </ButtonWrapper>
    );
  };

  _renderWarnings = () => {
    if (this._areWarnings()) {
      return (
        <div className={`input-warnings-container component-bg border-accent`}>
          {this._returnWarnings().map((warning, i) =>
            this._renderWarning(warning, `warning-${this._returnLabel().split(' ').join('-')}-${i}`)
          )}
          {this._renderDismissWarningsBtn()}
        </div>
      );
    } else {
      return null;
    }
  };

  _handleBlur = () => {
    this._validateFormForWarnings();
  };

  _validateFormForWarnings = (value) => {
    if (this.props.validateForWarnings) {
      const warnings = this.props.validateForWarnings(value || this._returnFormValue());
      if (warnings.length > 0) {
        this._setWarnings(warnings);
        return warnings;
      } else {
        this._clearWarnings();
      }
    }
    return [];
  };

  _renderUnderline = () => {
    if (this._isFloatingLabelStyle()) {
      const name = this._returnName();
      const isError = !!this.props.getErrorMessage(name);
      const errorMessage = isError ? this.props.getErrorMessage(name) : '';
      return <div className={`after ${isError && !this._isFocused() ? 'error-underline' : ''}`}></div>;
    } else {
      return null;
    }
  };

  _isError = () => !!this._returnErrorMessage();

  _returnErrorMessage = () => this.props.getErrorMessage(this._returnName());

  _renderErrorMessage = () => {
    const errorMessage = this._isError() ? this._returnErrorMessage() : '';
    if (!this._isError() && this.props.autoHideErrors) {
      return null;
    }
    return <p className={`text-field-error-message`}>{errorMessage}</p>;
  };

  _returnInputClassName = () => (this._isError() ? 'is-error' : '');

  _returnClassName = () => this.props.styling || '';

  _isFloatingLabelStyle = () => this._returnClassName().indexOf('floating-label-text-field') >= 0;

  _returnName = () => this.props.name || '';

  _returnLabel = () => this.props.label || '';

  _returnPlaceholder = () => this.props.placeholder || '';

  _hasValue = () => !isUndefinedOrNull(this._returnValue());

  _returnFormValue = () => this.props.getValue(this._returnName());

  _returnValue = () => {
    const value = this._returnFormValue();
    return isUndefinedOrNull(value) ? this._returnLabel() : value;
  };

  _isFocused = () => true; // TODO:

  _isFocusedOrHasValue = () => true; // TODO:

  _isDisabled = (value) => {
    if (value !== 'isEnabled') {
      return false;
    }
    return !value.isEnabled();
  };

  _handleChange = (e) => {
    let value = e.target.value;
    if (isInputValid(this._returnRestrictionChars(), value)) {
      // used to change the input, for example -> round the input entered if the user surpasses allowable decimal contraints
      if (this.props.customInputEntryOverride) {
        value = this.props.customInputEntryOverride(value);
      }

      if (this._areWarnings()) {
        this._validateFormForWarnings(value);
      }

      this.props.handleChange(this._returnName(), value);
      this._saveOnChange(value);
    } else {
      if (value === '') {
        this.props.handleChange(this._returnName(), '');
      }
    }
  };

  _returnRestrictionChars = () => this.props.inputRestrictionChars;

  // used if you want to persist after change, saveOnChange must be a function that makes the ajax call
  _saveOnChange = (data) => {
    // TODO:
    // this might not work and prolly doesn't apply with out a debounce or unfocus event
    // if (this.props.saveOnChange) {
    //   const data = { [`${this.props.objectType}_id`]: this.props.getObjectId(this.props.objectType), [name]: selectionValues[i] };
    //   this.props.saveOnChange(data);
    // }
  };

  _logDismissedWarnings = () => {
    const event = 'Dismissed Warning On Input';
    const properties = {
      Field: this._returnLabel(),
      Warning: this._returnWarnings().join('. '),
    };
    logMetricsTrackingEvent(event, properties)();
  };
}

export default TextNumberField;
