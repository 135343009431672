import { ORDER_TRANSACTION_TYPES, ORDER_TYPES, ORDER_VALUE_TYPES } from '@src/main/constants/orders';

const WARNING_THRESHOLD_VALUE = 0.1;
const warningThresholdDisplayValue = (100 * WARNING_THRESHOLD_VALUE).toFixed(0);

const ERROR_MESSAGES = {
  CANNOT_SHORT_ERROR: 'You cannot sell this instrument short because it is not easy to borrow.',
  STOP_PRICE_GREATER_THAN_LAST_PRICE: "Stop price can't be greater than last price",
  STOP_PRICE_LESS_THAN_LAST_PRICE: "Stop price can't be less than last price",
};

const ORDER_WARNING_MESSAGES = {
  SHORT_SELLING_WITH_BUY_IDEA_WARNING: 'You currently have a buy idea for this stock.',
  GOING_LONG_WITH_SELL_IDEA_WARNING: 'You currently have a sell idea for this stock.',
  STOP_PRICE_OUTSIDE_THRESHOLD_LAST_PRICE: `Your stop price is more than ${warningThresholdDisplayValue}% away from the last price.`,
  STOP_PRICE_OUTSIDE_THRESHOLD_LIMIT_PRICE: `Your stop price is more than ${warningThresholdDisplayValue}% away from your limit price.`,
  LIMIT_PRICE_OUTSIDE_THRESHOLD_LAST_PRICE: `Your limit price is more than ${warningThresholdDisplayValue}% away from the last price.`,
};

class _OrderFormHelper {
  isOrderOperationBuying = (order) => ORDER_TRANSACTION_TYPES.buy === order.transactionType;
  isOrderOperationSelling = (order) => ORDER_TRANSACTION_TYPES.sell === order.transactionType;

  getLimitPriceFromOrder = (order) => order.limitPrice;
  getStopPriceFromOrder = (order) => order.stopPrice;
  isOrderALimitOrderType = (order) => order.orderType === ORDER_TYPES.limit;
  isOrderAStopOrderType = (order) => order.orderType === ORDER_TYPES.stop;
  isOrderAStopLimitOrderType = (order) => order.orderType === ORDER_TYPES.stopLimit;

  isShortSelling = ({ order, currentShares }) => {
    const isSelling = this.isOrderOperationSelling(order);
    return isSelling && currentShares <= 0;
  };

  didFailShortSellValidation = ({ order, isShortAllowed, currentShares }) => {
    return this.isShortSelling({ order, currentShares }) && !isShortAllowed;
  };

  didFailBuyingStopPriceValidation = ({ order, currentPrice }) => {
    const isBuying = this.isOrderOperationBuying(order);
    const isStopOrder = this.isOrderAStopOrderType(order);
    const stopPrice = this.getStopPriceFromOrder(order);
    if (!isBuying || !isStopOrder || stopPrice === '' || currentPrice === 0) {
      return false;
    }
    return stopPrice < currentPrice;
  };

  didFailSellingStopPriceValidation = ({ order, currentPrice }) => {
    const isSelling = this.isOrderOperationSelling(order);
    const isStopOrder = this.isOrderAStopOrderType(order);
    const stopPrice = this.getStopPriceFromOrder(order);
    if (!isSelling || !isStopOrder || stopPrice === '' || currentPrice === 0) {
      return false;
    }
    return stopPrice > currentPrice;
  };

  generateOrderValidationWarnings = ({ order, idea, currentShares }) => {
    const hasNoPosition = Math.abs(currentShares) === 0;
    const hasBuyIdea = idea?.idea_type?.id === 0;
    const hasSellIdea = idea?.idea_type?.id === 1;

    const { shares, transactionType } = order;
    const intShares = shares === '' ? 0 : parseInt(shares, 10);
    const hasEnteredSharesAmount = intShares > 0;

    const warnings = [];

    if (hasEnteredSharesAmount && transactionType === ORDER_TRANSACTION_TYPES.buy && hasSellIdea && hasNoPosition) {
      warnings.push(ORDER_WARNING_MESSAGES.GOING_LONG_WITH_SELL_IDEA_WARNING);
    }
    if (hasEnteredSharesAmount && transactionType === ORDER_TRANSACTION_TYPES.sell && hasBuyIdea && hasNoPosition) {
      warnings.push(ORDER_WARNING_MESSAGES.SHORT_SELLING_WITH_BUY_IDEA_WARNING);
    }
    return warnings;
  };

  generateOrderValidationErrors = ({ order, isShortAllowed, currentShares, currentPrice }) => {
    const errors = [];

    const calcPercentageDifference = (v1, v2) => (v1 - v2) / v2;
    const calcWithinRange = (v1, v2) => Math.abs(calcPercentageDifference(v1, v2)) > WARNING_THRESHOLD_VALUE;

    const limitPrice = this.getLimitPriceFromOrder(order);
    const stopPrice = this.getStopPriceFromOrder(order);

    const hasLimitPrice = limitPrice !== '';
    const hasStopPrice = stopPrice !== '';
    const isStopOrder = this.isOrderAStopOrderType(order);
    const isStopLimitOrder = this.isOrderAStopLimitOrderType(order);
    const isLimitOrder = this.isOrderALimitOrderType(order);

    if (hasStopPrice && (isStopOrder || isStopLimitOrder) && calcWithinRange(stopPrice, currentPrice)) {
      errors.push(['stopPrice', ORDER_WARNING_MESSAGES.STOP_PRICE_OUTSIDE_THRESHOLD_LAST_PRICE]);
    }
    if (hasLimitPrice && (isLimitOrder || isStopLimitOrder) && calcWithinRange(limitPrice, currentPrice)) {
      errors.push(['limitPrice', ORDER_WARNING_MESSAGES.LIMIT_PRICE_OUTSIDE_THRESHOLD_LAST_PRICE]);
    }

    if (this.didFailShortSellValidation({ order, isShortAllowed, currentShares })) {
      errors.push(['transactionType', ERROR_MESSAGES.CANNOT_SHORT_ERROR]);
    }
    if (this.didFailBuyingStopPriceValidation({ order, currentPrice })) {
      errors.push(['stopPrice', ERROR_MESSAGES.STOP_PRICE_LESS_THAN_LAST_PRICE]);
    }
    if (this.didFailSellingStopPriceValidation({ order, currentPrice })) {
      errors.push(['stopPrice', ERROR_MESSAGES.STOP_PRICE_GREATER_THAN_LAST_PRICE]);
    }
    return errors;
  };

  fieldHasValue = (key, value) => {
    if (value === '') return false;

    const fieldsThatRequireGreaterThanZeroValue = ['shares', 'dollarAmount', 'limitPrice', 'stopPrice'];
    if (fieldsThatRequireGreaterThanZeroValue.includes(key)) {
      const numV = parseFloat(value);
      if (numV === 0) return false;
    }

    return true;
  };

  fieldHasNonZeroValue = (key, value) => {
    const fieldsThatRequireGreaterThanZeroValue = ['shares', 'dollarAmount', 'limitPrice', 'stopPrice'];
    if (fieldsThatRequireGreaterThanZeroValue.includes(key)) {
      const numV = parseFloat(value);
      if (numV === 0) return false;
    }
    return true;
  };

  getMissingOrInvalidFields = (state) => {
    const { orderType, orderValueType } = state;

    const requiredKeys = ['transactionType', 'orderType'];

    if (orderValueType === ORDER_VALUE_TYPES.shares) {
      requiredKeys.push('shares');
    } else {
      requiredKeys.push('dollarAmount');
    }

    if (orderType === ORDER_TYPES.limit || orderType === ORDER_TYPES.stopLimit) {
      requiredKeys.push('limitPrice');
    }
    if (orderType === ORDER_TYPES.stop || orderType === ORDER_TYPES.stopLimit) {
      requiredKeys.push('stopPrice');
    }

    const fieldValidations = requiredKeys.map((key) => {
      const value = state[key];
      const required = !this.fieldHasValue(key, value);
      if (required) {
        return [key, 'Required'];
      }
      const hasValidValue = this.fieldHasNonZeroValue(key, value);
      if (!hasValidValue) {
        return [key, 'Cannot be 0'];
      }
      return [key, null];
    });
    const invalidFields = fieldValidations.filter((d) => {
      const [key, error] = d;
      return !!error;
    });
    return invalidFields;
  };
}

export const OrderFormHelper = new _OrderFormHelper();
