import React from 'react';
import Icon from '../../components/misc/Icon';

export const Facebook = (props) => {
  const returnMessage = () => props.message || '';
  const returnURL = () => props.url;
  const returnLink = () => `http://www.facebook.com/sharer.php?u=${returnURL()}`;
  const handleClick = () => {
    if (props.additionalActionOnClick) {
      props.additionalActionOnClick();
    }
  };

  return (
    <div className={`social-button facebook`}>
      <a
        id="facebook_share"
        className={`${props.className || ''}`}
        href={returnLink()}
        target={'_blank'}
        onClick={handleClick}
      >
        <Icon icon={'fa fa-facebook'} />
      </a>
    </div>
  );
};
