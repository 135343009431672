import { logMetricsTrackingEvent } from '../../../actions';
import { sendFacebookTrackingEvent } from '../../../constants/facebookTracking';

const SIGN_IN = {
  send: (properties) => {
    // 'User': current_user.analytics_id,
    // 'Onboarding End Test Group': current_user ? current_user.test_group_for("onboarding_end") : nil,
    const event = 'Sign In';
    logMetricsTrackingEvent(event, properties)();
  },
};

const SIGNED_IN_X_TIMES = {
  send: (properties) => {
    if (!properties.times) {
      return console.error('Missing sign in count');
    }
    const { times } = properties;
    const event = `Signed In ${times} Times`;
    logMetricsTrackingEvent(event, properties)();

    sendFacebookTrackingEvent('Sign In Count', {
      times,
    });
  },
};

const SIGNED_IN_AT_LEAST_X_TIMES = {
  send: (properties) => {
    if (!properties.times) {
      return console.error('Missing sign in count');
    }
    const { times } = properties;
    const event = `Signed In At Least ${times} Times`;
    logMetricsTrackingEvent(event, properties)();

    sendFacebookTrackingEvent('Sign In At Least Count', {
      times,
    });
  },
};

const VIEW_FINISH_SETTING_UP_ACCOUNT_SCREEN = {
  send: (properties) => {
    const event = 'View Finish Setting Up Account Screen';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_FINISH_SETTING_UP_ACCOUNT = {
  send: (properties) => {
    const event = 'Press Finish Setting Up Account';
    logMetricsTrackingEvent(event, properties)();
  },
};

const CLICK_SEND_EMAIL_DOWNLOAD_APP_TO_UPLOAD_DOCS = {
  send: (properties) => {
    const event = 'Click Send Email To Download App To Upload Docs';
    logMetricsTrackingEvent(event, properties)();
  },
};

const CLICK_DOWNLOAD_APP_TO_UPLOAD_DOCS = {
  send: (properties) => {
    const event = 'Click Download App To Upload Docs';
    logMetricsTrackingEvent(event, properties)();
  },
};

const SENT_SUPPORT_MESSAGE = {
  send: (properties) => {
    const event = 'Sent Support Message';
    logMetricsTrackingEvent(event, properties);
  },
};

const trackingEvents = {
  SIGN_IN,
  SIGNED_IN_X_TIMES,
  SIGNED_IN_AT_LEAST_X_TIMES,

  VIEW_FINISH_SETTING_UP_ACCOUNT_SCREEN,
  PRESS_FINISH_SETTING_UP_ACCOUNT,

  CLICK_SEND_EMAIL_DOWNLOAD_APP_TO_UPLOAD_DOCS,
  CLICK_DOWNLOAD_APP_TO_UPLOAD_DOCS,

  SENT_SUPPORT_MESSAGE,
};

export default trackingEvents;
