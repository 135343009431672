import React from 'react';
import styled from 'styled-components';
import { BaseButton } from '.';
import { Row } from '@src/main/lib/nvstr-common-ui.es';
import LoadingSpinner from '../Images/LoadingSpinner';

export const ButtonWrap = styled.div`
  & button {
    background: none !important;
    color: ${({ theme }) => theme.themeColors.text};
  }
`;

const LoadingButtonWrap = styled.div`
  & button {
    background: none !important;
    border: none !important;
  }

  span {
    font-size: 12px;
    line-height: 14px;
  }
`;

const IconWrapper = styled.span`
  display: inline-block;
  margin-right: 8px;

  i {
    font-size: 14px;
  }
`;

const SpinnerWrapper = styled.span`
  div {
    height: 15px;
    width: 15px;

    margin: 0 8px 0 -1px;
  }

  svg {
    height: 15px;
    width: 15px;
  }
`;

export const RefreshButton = (props) => {
  const { isRefreshing, onClick } = props;

  return isRefreshing ? (
    <LoadingButtonWrap>
      <BaseButton disabled height="36px" width="112px" padding="4px 0" onClick={onClick}>
        <Row hAlign="center" vAlign="center">
          <SpinnerWrapper>
            <LoadingSpinner isSmall />
          </SpinnerWrapper>
          <span style={{ display: 'inline-block' }}>Refreshing</span>
        </Row>
      </BaseButton>
    </LoadingButtonWrap>
  ) : (
    <ButtonWrap>
      <BaseButton
        height="36px"
        width="112px"
        padding="4px 0"
        border="none"
        onClick={onClick}
        style={{ whiteSpace: 'nowrap', border: 'none' }}
      >
        <div style={{ width: '112px', textAlign: 'left' }}>
          <IconWrapper>
            <i className={`fa fa-refresh`} style={{ fontFamily: 'FontAwesome' }} />
          </IconWrapper>
          <span style={{ display: 'inline-block', paddingBottom: '1px' }}>Refresh</span>
        </div>
      </BaseButton>
    </ButtonWrap>
  );
};
