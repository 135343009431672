import dayjs from 'dayjs'; // https://github.com/iamkun/dayjs
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { throwError } from './devToolHelpers';
import { isUndefinedOrNull } from './generalHelpers';

dayjs.extend(advancedFormat); // use plugin
dayjs.extend(relativeTime); // use plugin

export const moment = dayjs;

window.moment = dayjs;

export const createTimeInstance = (v) => {
  if (v === null) {
    return null;
  }
  return moment(v);
};

export const parseDate = (date) => moment(date);

export const isDateBefore = (dateBefore, date) => dateBefore.isBefore(date);

export const setDateBack = (length, value) => moment().subtract(length, value);

export const formatLocalizedDateTime = (format, moment) => {
  if (moment === null || moment === undefined) {
    return null;
  }

  /*
    Time	            LT	  8: 30 PM
                    	LTS	  8: 30: 25 PM
    DATE	            L    	09 / 04 / 1986
                      l	     9 / 4 / 1986
                      ls	    9 / 4 / 86
                      LL   	September 4, 1986
                      ll	  Sep 4, 1986
                      LLL	  September 4, 1986 8: 30 PM
                      lll	  Sep 4, 1986 8: 30 PM
                    	LLLL	Thursday, September 4, 1986 8: 30 PM
                      llll	Thu, Sep 4, 1986 8: 30 PM
  */
  const lookup = {
    Do: 'Do',

    api: 'YYYY[-]MM[-]DD',
    api2: 'MM[-]DD[-]YYYY',

    MD: 'MMMM Do',

    LT: 'h[:]mm A',
    LTS: 'h[:]mm[:]ss A',

    l: 'M[/]D[/]YYYY',
    ls: 'M[/]D[/]YY',
    ll: 'MMMM D[,] YYYY',
    ll2: 'MMM D[,] YYYY',
    lll: 'MMM D[,]YYYY h[:]mm A',
    llll: 'ddd[,] MMM D[,]YYYY h[:]mm[:]ss A',

    '-l': 'M[-]D[-]YYYY',

    L: 'MM[/]DD[/]YYYY',
    LL: 'MMMM Do[,] YYYY',
    LLL: 'MMMM[/]DD[/]YYYY h[:]mm A',

    '-L': 'MM[-]DD[-]YYYY',
    '-LL': 'MMMM[-]DD[-]YYYY',
    '-LLL': 'MMMM[-]DD[-]YYYY h[:]mm A',

    day: 'dddd',

    'day-date': 'dddd[,] MMMM D[,] YYYY',

    LLLL: 'dddd[,] MMMM D[,]YYYY h[:]mm A',
    YYYY: 'YYYY',

    asOf: 'MMM DD[,] YYYY h[:]mm A',
  };
  const dayjsFormat = lookup[format] || throwError('format not available for formatLocalizedDateTime function');
  return moment.format(dayjsFormat);
};

export const parseAPIDate = (value) => {
  if (value instanceof Date) {
    return value;
  }
  if (isUndefinedOrNull(value)) {
    return null;
  }
  try {
    // value comes in as UTC time
    const apiDate = value.split('-');
    const [year, month, day] = apiDate;
    const date = new Date(year, month - 1, day);
    // update to local time
    return createTimeInstance(date).toDate();
  } catch (error) {
    return null;
  }
};

export const formatToUntilMessage = (timeInstance) => {
  const timeStr = formatLocalizedDateTime('LT', timeInstance);
  const dateStr = timeInstance.format('MMMM Do');
  return `${timeStr} EST on ${dateStr}`;
};

export const getWeekNumber = (date) => {
  var oneJan = new Date(date.getFullYear(), 0, 1);
  var numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000));
  return Math.ceil((date.getDay() + 1 + numberOfDays) / 7);
};

export const returnThisYear = () => {
  const timeNow = moment();
  return timeNow.format('YYYY');
};

export const returnThisMonth = () => {
  const timeNow = moment();
  return timeNow.format('MMM');
};

export const returnThisDay = () => {
  const timeNow = moment();
  return timeNow.format('D');
};

export const returnLastYear = () => {
  const timeNow = moment();
  const timeLastYear = timeNow.subtract(1, 'year');
  const lastYearStr = timeLastYear.format('YYYY');
  return lastYearStr;
};

export const getTimeNowEpoch = () => moment().valueOf();

export const getStartOfDayTodayEpochValue = () => new Date().setHours(0, 0, 0, 0);

export const getDaysSinceDate = (date) => {
  const sinceDate = moment(date);
  const today = moment();
  return today.diff(sinceDate, 'days');
};

export const getDaysUntilDate = (date) => {
  const futureDate = moment(date);
  const today = moment();
  return futureDate.diff(today, 'days');
};

export const isWithinTimeLength = (msTimeLength, time) => {
  const timeNow = createTimeInstance();
  const timeToCheckAgainst = createTimeInstance(time);
  const expirationTime = timeToCheckAgainst.valueOf() + msTimeLength;
  return timeNow.isBefore(expirationTime);
};
export const isAfterTimeLength = (msTimeLength, time) => {
  const timeNow = createTimeInstance();
  const timeToCheckAgainst = createTimeInstance(time);
  const expirationTime = timeToCheckAgainst.valueOf() + msTimeLength;
  return timeNow.isAfter(expirationTime);
};

export const getPrevDay = (dayCodeInt) => {
  // 0 === 'Sunday'
  const dayNow = parseInt(createTimeInstance().format('d'), 10);
  if (dayNow === dayCodeInt) {
    return createTimeInstance().day(dayCodeInt - 7);
  } else {
    return createTimeInstance().day(dayCodeInt);
  }
};
