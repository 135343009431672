import React from 'react';

import UserContainer from '../../../users/UserContainer';
import ProfileAvatar from '../../../../components/user/ProfileAvatar';
import UserName from '../../../../components/user/UserName';
import MutualConnectionsList from './MutualConnectionsList';

import Button from '../../../../components/buttons/Button';
import IconButton from '../../../../components/buttons/IconButton';
import { PROFILE_CONTEXTS } from '../../../../constants/contextTracking';
import { FlatButton } from '@src/main/components/buttons';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  button {
    padding: 4px 8px;
  }
`;

const UserYouMayKnowCard = (props) => {
  const { position } = props;
  const userId = props.item.user_id;
  const mutualConnectionsUserIds = props.item.mutual_connections;
  const handleConnectionRequest = () => {
    props.handleConnectionRequest(userId);
  };
  const handleDismissConnectionSuggestion = () => {
    props.handleDismissConnectionSuggestion(userId);
  };
  return (
    <div className={'user-you-may-know-container component-bg card'} style={position}>
      <div className={'dismiss-btn-container'}>
        <IconButton
          icon=" fa fa-times fa-times-thin"
          colorClassName="secondary-text-color"
          size="medium"
          handleClick={handleDismissConnectionSuggestion}
        />
      </div>
      <UserContainer containerClass={'card-heading-user'} userId={userId}>
        <ProfileAvatar
          size="nav"
          showNameTag={false}
          profileLinkQuery={`?context=${PROFILE_CONTEXTS.SUGGESTED_CONNECTION}`}
        />
        <UserName
          displayNameStyle={'fullName'}
          profileLinkQuery={`?context=${PROFILE_CONTEXTS.SUGGESTED_CONNECTION}`}
        />
      </UserContainer>
      <MutualConnectionsList
        userIds={mutualConnectionsUserIds || []}
        userId={userId}
        maxUserNamesToShow={2}
        emptyString={'Suggested for you'}
        useScrollIfToTall
      />
      <ButtonWrapper className={'connect-btn-container'}>
        {props.checkIfRequestedConnection && props.checkIfRequestedConnection(userId) ? (
          <FlatButton handleClick={() => {}} text="Sent">
            Sent
          </FlatButton>
        ) : (
          <FlatButton onClick={handleConnectionRequest}>Connect</FlatButton>
        )}
      </ButtonWrapper>
    </div>
  );
};

export default UserYouMayKnowCard;
