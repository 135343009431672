import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';
import { formatIdeasToSendToAPI } from '../helpers/ideaHelpers';

const URL = `${BASEURL}/api/ideas`;

export const ideasAdapter = {
  viewIdea: (ideaId) => {
    return sendRequest('post', [`${URL}/${ideaId}/view`]).then((response) => response);
  },

  getIdeasForSecurity: (securityIds) => {
    const options = { ignoreAuth: true };
    const key = 'security_ids';
    const securityIdsCSV = securityIds.join(',');
    return sendRequest(
      'get',
      [`${URL}?${key}=${securityIdsCSV}&include_self=true&include_connections=true&include_thought_leaders=true`],
      options,
      'getIdeasForSecurity'
    ).then((response) => response);
  },

  getIdeasForUsers: (userIds) => {
    return sendRequest('get', [`${URL}?user_ids=${userIds}`]).then((response) => response);
  },

  getIdeas: (ideaIds) => {
    return sendRequest('get', [`${URL}?ids=${ideaIds}`]).then((response) => response);
  },

  getCurrentUserIdeas: () => {
    return sendRequest('get', [`${URL}`]).then((response) => response);
  },

  fetchIdeas: (userId) => {
    let url = userId ? `${URL}?user_ids=${userId}` : `${URL}`;
    return sendRequest('get', [url]).then((response) => response);
  },

  fetchSecurityIdFromIdeaId: (ideaIds) => {
    let url = `${BASEURL}/api/idea_securities`;
    const ids = Array.isArray(ideaIds) ? ideaIds.join(',') : ideaIds;
    const params = { ids };

    return sendRequest('get', [url, { params }]).then((response) => response);
  },

  fetchCommunityIdeas: () => {
    const options = { ignoreAuth: true };
    let url = `${BASEURL}/api/community_ideas`;
    return sendRequest('get', [url], options).then((response) => response);
  },

  addIdeas: (unformattedIdeasToSendToAPI) => {
    let url = `${URL}`;
    const ideas = formatIdeasToSendToAPI(unformattedIdeasToSendToAPI);
    return sendRequest('post', [url, { ideas }]).then((response) =>
      response && response.data ? response.data : response
    );
  },

  addIdea: (unformattedIdeasToSendToAPI) => {
    let url = `${URL}`;
    const ideas = formatIdeasToSendToAPI(unformattedIdeasToSendToAPI);
    return sendRequest('post', [url, { ideas }]);
  },

  copyIdea: (ideaId) => {
    let url = `${URL}/${ideaId}/copy`;
    return sendRequest('post', [url, {}]).then((response) => (response && response.data ? response.data : response));
  },

  updateIdea: (idea) => {
    let url = `${URL}`;
    return sendRequest('put', [url, { ideas: [idea] }]).then((response) => {
      if (response && 'data' in response) {
        return response.data;
      } else {
        return {
          ...response,
          unauthorized: response.status === 401,
        };
      }
    });
  },

  removeIdea: (idea) => {
    let url = `${URL}/${idea.idea_id || idea.id}`;
    return sendRequest('delete', [url]).then((response) => {
      if ('data' in response) {
        return response.data;
      } else {
        return {
          ...response,
          unauthorized: response.status === 401,
        };
      }
    });
  },

  fillCurrentUserWithETFIdeas: () => {
    return sendRequest('post', [`${BASEURL}/api/ideas/default`]).then((response) => response);
  },

  reaffirmIdea: (ideaId) => {
    return sendRequest('post', [`${URL}/${ideaId}/reaffirm`, {}]).then((response) =>
      response && response.data ? response.data : response
    );
  },
};
