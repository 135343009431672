import { LOADING_USERS_IDEA_PERFORMANCES, ADD_USERS_PERFORMANCES } from '../constants';

const defaultState = {
  userDict: {},
};

export default function userIdeaPerformanceReducer(state = defaultState, action) {
  let newData;

  switch (action.type) {
    case LOADING_USERS_IDEA_PERFORMANCES:
      const userIds = action.payload.userIds;
      newData = {};
      userIds.forEach((id) => (newData[id] = {}));
      return {
        ...state,
        userDict: {
          ...state.userDict,
          ...newData,
        },
      };

    case ADD_USERS_PERFORMANCES:
      const users = action.payload.users;
      newData = {};
      users.forEach(
        (user) =>
          (newData[user.user_id] = {
            performance_today: user.performance_today,
            performance_this_month: user.performance_this_month,
            performance_last_month: user.performance_last_month,
            performance_last_week: user.performance_last_week,
          })
      );
      return {
        ...state,
        userDict: {
          ...state.userDict,
          ...newData,
        },
      };

    default:
      return state;
  }
}
