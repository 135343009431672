import { logMetricsTrackingEvent } from '../../../actions';
import { TrackingEvent } from '../index';
// import { ADJUST_EVENT_NAMES, logAdjustEvent } from '@src/main/lib/nvstr-common-ui.es';

const VIEW_CONTACT_FORM = {
  send: (properties = {}) => {
    const event = 'View Contact Form';
    logMetricsTrackingEvent(event, properties)();
  },
};

const VIEW_CONTACT_RESUBMIT_FORM = {
  send: (properties = {}) => {
    const event = 'View Contact Resubmit Form';
    logMetricsTrackingEvent(event, properties)();
  },
};

const SUBMITTED_UNVERIFIED_ADDRESS = {
  send: (properties = {}) => {
    const event = 'Submitted Unverified Address';
    logMetricsTrackingEvent(event, properties)();
  },
};

const RESUBMITTED_ADDRESS_SUCCESSFULLY = {
  send: (properties = {}) => {
    const event = 'Resubmitted Address Successfully';
    logMetricsTrackingEvent(event, properties)();
  },
};

const SUBMITTED_VERIFIED_ADDRESS = {
  send: (properties = {}) => {
    const event = 'Submitted Verified Address';
    logMetricsTrackingEvent(event, properties)();
  },
};

const VIEW_VERIFY_ADDRESS_MODAL = {
  send: (properties = {}) => {
    const event = 'View Verify Address Modal';
    logMetricsTrackingEvent(event, properties)();
  },
};

const KEEP_UNVERIFIED_ADDRESS = {
  send: (properties = {}) => {
    const event = 'Kept Unverified Address';
    logMetricsTrackingEvent(event, properties)();
  },
};

const RESUBMITTED_AFTER_UNVERIFIED_ADDRESS = {
  send: (properties = {}) => {
    const event = 'Resubmitted After Unverified Address';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_ADDRESS_AUTOCOMPLETE_SUGGESTION = {
  send: (properties = {}) => {
    const event = 'Press Address Autocomplete Selection';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_CANCEL_VERIFY_ADDRESS = {
  send: (properties = {}) => {
    const event = 'Press Cancel Address Verification';
    logMetricsTrackingEvent(event, properties)();
  },
};

const VIEW_ADDITIONAL_DOCUMENTS_SUBMISSION_PAGE = {
  send: (properties = {}) => {
    const event = 'View Additional Documents Submission Page';
    logMetricsTrackingEvent(event, properties)();
  },
};

const CLICK_COMPLETE_ADDITIONAL_DOCUMENTS_SUBMISSION_PAGE = {
  send: (properties = {}) => {
    const event = 'Click Complete - Additional Documents Submission Page';
    logMetricsTrackingEvent(event, properties)();
  },
};

const START_SOCURE_DOCUMENT_CAPTURE_FLOW = {
  send: (properties = {}) => {
    const event = 'Start Socure Document Capture Flow';
    logMetricsTrackingEvent(event, properties)();
  },
};

const LINK_PLAID_ACCOUNT_REQUEST_FAILURE = {
  send: (properties) => {
    const event = 'Link Plaid Bank Account Request Failure';
    logMetricsTrackingEvent(event, properties)();
  },
};
const LINK_PLAID_BROKERAGE_ACCOUNT_REQUEST_FAILURE = {
  send: (properties) => {
    const event = 'Link Plaid Brokerage Account Request Failure';
    logMetricsTrackingEvent(event, properties)();
  },
};

const COMPLETED_BANK_ACCOUNT_LINK = new TrackingEvent('Completed Bank Account Link');

const trackingEvents = {
  VIEW_CONTACT_FORM,
  VIEW_CONTACT_RESUBMIT_FORM,

  SUBMITTED_UNVERIFIED_ADDRESS,
  SUBMITTED_VERIFIED_ADDRESS,

  VIEW_VERIFY_ADDRESS_MODAL,

  PRESS_ADDRESS_AUTOCOMPLETE_SUGGESTION,
  PRESS_CANCEL_VERIFY_ADDRESS,

  KEEP_UNVERIFIED_ADDRESS,
  RESUBMITTED_AFTER_UNVERIFIED_ADDRESS,

  START_SOCURE_DOCUMENT_CAPTURE_FLOW,

  RESUBMITTED_ADDRESS_SUCCESSFULLY,

  VIEW_ADDITIONAL_DOCUMENTS_SUBMISSION_PAGE,
  CLICK_COMPLETE_ADDITIONAL_DOCUMENTS_SUBMISSION_PAGE,

  LINK_PLAID_ACCOUNT_REQUEST_FAILURE,
  LINK_PLAID_BROKERAGE_ACCOUNT_REQUEST_FAILURE,

  COMPLETED_BANK_ACCOUNT_LINK,
};

export default trackingEvents;
