import React from 'react';

import CommunityPositionLabel from './CommunityPositionLabel';
import CommunityPositionMeter from './CommunityPositionMeter';

export const CommunityPositionContainer = (props) => {
  const positionSize = props.community_position;
  var calculated_length = props.community_position >= 20 ? 100 : (props.community_position / 20) * 100;
  const width_css = calculated_length < 0.5 && calculated_length > 0.0 ? '1px' : calculated_length + '%';
  const position = props.position === 'long' ? 'position-left' : 'position-right';

  return (
    <div className={`community-position-container ${position}`}>
      <CommunityPositionLabel label={positionSize} position={props.position} />
      <CommunityPositionMeter community_position={width_css} position={props.position} />
    </div>
  );
};

export default CommunityPositionContainer;
