import React from 'react';

const REFERRAL_PAGE_HEADING_COPY_A = {
  heading: 'Two Heads Are Better Than One. Especially in Investing.',
  subtext: (
    <div className={'rewards-sub-heading-container'}>
      <p>{'Invest better with help from your friends.'}</p>
      <p>{'When you bounce ideas off people you trust, everyone gets smarter.'}</p>
    </div>
  ),
};

const REFERRAL_PAGE_HEADING_COPY_B = {
  heading: 'Two Heads Are Better Than One. Especially in Investing.',
  subtext: (
    <div className={'rewards-sub-heading-container'}>
      <p>{'When you bounce ideas off people you trust, everyone gets smarter.'}</p>
      <p>{"That's what the pros do."}</p>
    </div>
  ),
};

const REFERRAL_PAGE_HEADING_COPY_C = {
  heading: 'Invest better with help from your friends.',
  subtext: (
    <div className={'rewards-sub-heading-container'}>
      <p>{'Two heads are better than one. Especially in investing.'}</p>
      <p>{'When you bounce ideas off people you trust, everyone gets smarter.'}</p>
    </div>
  ),
};

const REFERRAL_PAGE_HEADING_COPY_D = {
  heading: 'You and a friend can both get up to $1,000 to invest free when they make a deposit.',
  subtext: (
    <div className={'rewards-sub-heading-container'}>
      <p>{'Two heads are better than one. Especially in investing.'}</p>
      <p>{'When you bounce ideas off people you trust, everyone gets smarter.'}</p>
    </div>
  ),
};

export const REFERRAL_PAGE_HEADING_COPY_VERSIONS_BY_AB_GROUP = {
  a: REFERRAL_PAGE_HEADING_COPY_A,
  b: REFERRAL_PAGE_HEADING_COPY_B,
  c: REFERRAL_PAGE_HEADING_COPY_C,
  d: REFERRAL_PAGE_HEADING_COPY_D,
};
