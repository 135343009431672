import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Body1, Body2, defineFontSettings } from '../../lib/nvstr-common-ui.es';
import { colorPalette } from '../../lib/nvstr-utils.es';
import { Text } from '../text';
import { FlatButton } from '../buttons';
import { ROUTES } from '../../../constants/paths';
import { TrackingEvents } from '../../../utils/tracking/events';
import { useCurrentUser, useIsLiveTrading } from '../../hooks/user';
import { LearnShareCTA } from './LearnShareCTA';
import { useNextLesson } from '../../hooks/learn/useNextLesson';
import { isUndefinedOrNull } from '../../../helpers/usefulFuncs';
import { useLearnLesson } from '../../hooks/learn/useLearnLesson';
import { formatForDisplay } from '../../../helpers/displayHelpers';
import { TrackIteratively } from '../../../utils/itly';
import { LearnScreen } from './LearnScreen';
import { canRegisterLiveTrading } from '../../../helpers/currentUserHelpers';
import GiftImage from '../../../assets/images/misc/Gift_700px.png';
import { CheckCircleFilled } from '../../icons';
import { Container } from '../ui';
import { ExtraContent } from './subComponents/ExtraContent';

const fontSettings = defineFontSettings(
  {
    fontSize: '18px',
    lineHeight: '24px',
  },
  {
    fontSize: '24px',
    lineHeight: '28px',
  }
);

const rewardAmountFontSettings = defineFontSettings(
  {
    fontSize: '36px',
    lineHeight: '44px',
  },
  {
    fontSize: '64px',
    lineHeight: '70px',
  }
);

const GiftImageWrapper = styled.div`
  text-align: center;
  height: 140px;
  margin-top: 0px;

  img {
    margin-top: -48px;
    width: 260px;
  }

  @media (max-width: 600px) {
    height: 120px;
    margin-top: 0px;
    img {
      width: 200px;
    }
  }
  @media (max-width: 420px) {
    height: 88px;
    img {
      width: 180px;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  button {
    width: 268px;
  }
`;

const IconWrapper = styled.div`
  padding-top: 36px;
  text-align: center;
  g {
    fill: ${colorPalette.primary.charcoal};
  }
`;

const SuccessDisplay = () => {
  const textColor = colorPalette.primary.charcoal;

  const fontSettings = defineFontSettings(
    {
      fontSize: '18px',
      lineHeight: '24px',
    },
    {
      fontSize: '36px',
      lineHeight: '42px',
    }
  );
  return (
    <div>
      <IconWrapper>
        <CheckCircleFilled />
      </IconWrapper>

      <Container top={16} centerAll>
        <Text fontSettings={fontSettings} bold center color={textColor}>
          Lesson complete
        </Text>
      </Container>
    </div>
  );
};

const EarnRewardsAfterDepositLearnRewardEarnedCTA = ({ lockedRewardsAmount }) => {
  const textColor = colorPalette.primary.charcoal;

  const navigate = useNavigate();

  const message =
    lockedRewardsAmount > 0
      ? `Finish setting up your account to access your ${formatForDisplay(
          lockedRewardsAmount,
          'rewardAmount'
        )} in rewards.`
      : 'Finish setting up your account to access your rewards.';
  const message2 = 'Invest in yourself, and we will too.';

  React.useEffect(() => {
    TrackingEvents.learn.VIEW_ACCESS_REWARDS_WITH_DEPOSIT_CTA.send({
      'Locked Rewards': lockedRewardsAmount,
    });
  }, []);

  const handleDepositPress = () => {
    TrackingEvents.learn.PRESS_ACCESS_REWARDS_WITH_DEPOSIT_CTA.send({
      'Locked Rewards': lockedRewardsAmount,
    });

    navigate(ROUTES.FUNDING.build());
  };

  return (
    <>
      <div style={{ margin: '0 auto', padding: '16px 0 0 0', maxWidth: '350px', textAlign: 'center' }}>
        <Body2 color={textColor} bold>
          {message}
        </Body2>
        <div style={{ margin: '0 auto', padding: '8px 0 0 0' }}>
          <Body1 color={textColor}>{message2}</Body1>
        </div>
      </div>
      <div style={{ textAlign: 'center', padding: '0' }}>
        <div style={{ margin: '0 auto', padding: '16px 0 0 0' }}>
          <ButtonWrapper>
            <FlatButton
              color={colorPalette.primary.charcoal}
              textColor={colorPalette.primary.oak}
              onClick={handleDepositPress}
            >
              Deposit Now
            </FlatButton>
          </ButtonWrapper>
        </div>
      </div>
    </>
  );
};

const DepositDoubleRewardLearnRewardEarnedCTA = () => {
  const textColor = colorPalette.primary.charcoal;

  const navigate = useNavigate();

  const message = 'Want to double your reward?';
  const message2 = 'For a limited time, double this reward with a deposit of at least $5.';
  const message3 = 'Invest in yourself, and we will too.';

  React.useEffect(() => {
    TrackingEvents.learn.VIEW_DOUBLE_REWARD_WITH_DEPOSIT_CTA.send();
  }, []);

  const handleDepositPress = () => {
    TrackingEvents.learn.PRESS_DOUBLE_REWARD_WITH_DEPOSIT_CTA.send();

    navigate(ROUTES.FUNDING.build());
  };

  return (
    <>
      <div style={{ margin: '0 auto', padding: '16px 0 0 0', maxWidth: '350px', textAlign: 'center' }}>
        <Body2 color={textColor} bold>
          {message}
        </Body2>
        <div style={{ margin: '0 auto', padding: '8px 0 0 0' }}>
          <Body1 color={textColor}>{message2}</Body1>
        </div>
        <div style={{ margin: '0 auto', padding: '8px 0 0 0' }}>
          <Body1 color={textColor}>{message3}</Body1>
        </div>
      </div>
      <div style={{ textAlign: 'center', padding: '0' }}>
        <div style={{ margin: '0 auto', padding: '16px 0 0 0' }}>
          <ButtonWrapper>
            <FlatButton
              color={colorPalette.primary.charcoal}
              textColor={colorPalette.primary.oak}
              onClick={handleDepositPress}
            >
              Deposit Now
            </FlatButton>
          </ButtonWrapper>
        </div>
      </div>
    </>
  );
};

const DefaultLearnRewardEarnedCTA = ({
  lessonId,
  rewardAmount,
  lockedRewardsAmount,
  hasRewardScreenPartnerCallout,
}) => {
  const textColor = colorPalette.primary.charcoal;
  const { nextLessonId } = useNextLesson();

  const liveTradingMessage = `You'll see ${formatForDisplay(
    rewardAmount,
    'price'
  )} added to your account in two business days`;

  return (
    <div style={{ margin: '0 auto', padding: '16px 0 0 0', maxWidth: '250px', textAlign: 'center' }}>
      <Body1 color={textColor} thin>
        {liveTradingMessage}
      </Body1>
    </div>
  );
};

const LearnRewardCTA = ({
  lessonId,
  rewardAmount,
  lockedRewardsAmount,
  completedLessonCount,
  hasRewardScreenPartnerCallout,
}) => {
  const isLiveTrading = useIsLiveTrading();
  const { messagingStates } = useNextLesson();

  if (messagingStates.should_see_learn_and_earn_doubled_with_deposit && isLiveTrading && completedLessonCount === 5) {
    return <DepositDoubleRewardLearnRewardEarnedCTA lockedRewardsAmount={lockedRewardsAmount} />;
  }

  if (messagingStates.should_see_learn_and_earn_payout_requires_deposit && isLiveTrading && completedLessonCount >= 5) {
    return <EarnRewardsAfterDepositLearnRewardEarnedCTA lockedRewardsAmount={lockedRewardsAmount} />;
  }

  return (
    <DefaultLearnRewardEarnedCTA
      hasRewardScreenPartnerCallout={hasRewardScreenPartnerCallout}
      lockedRewardsAmount={lockedRewardsAmount}
      rewardAmount={rewardAmount}
      lessonId={lessonId}
    />
  );
};

const NextLessonAction = () => {
  const navigate = useNavigate();
  const textColor = colorPalette.primary.charcoal;
  const hoverTextColor = colorPalette.primary.oak;

  const onNextLessonClick = () => {
    navigate(ROUTES.DASHBOARD.build());
    setTimeout(() => {
      navigate(ROUTES.LEARN_AND_EARN_NEXT_LESSON.build());
    }, 200);
  };

  return (
    <FlatButton color={textColor} textColor={hoverTextColor} onClick={onNextLessonClick} fullWidth>
      NEXT LESSON
    </FlatButton>
  );
};

const OpenLiveAccountCTA = ({ textColor, lockedRewardsAmount }) => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const isRegisterLTAvailable = canRegisterLiveTrading(currentUser);

  const message =
    lockedRewardsAmount > 0
      ? `Open a live brokerage account with us to access the ${formatForDisplay(
          lockedRewardsAmount,
          'rewardAmount'
        )} you have in rewards`
      : `Open a live brokerage account with us to access the rewards`;

  const handleOpenLivePress = () => {
    navigate(ROUTES.LIVE_TRADING_SIGNUP_START.build());
  };

  if (!isRegisterLTAvailable) {
    return null;
  }
  return (
    <>
      <div style={{ margin: '0 auto', padding: '0 0 16px 0', maxWidth: '250px', textAlign: 'center' }}>
        <Body1 color={textColor} thin>
          {message}
        </Body1>
      </div>
      <ButtonWrapper>
        <FlatButton
          color={colorPalette.primary.charcoal}
          textColor={colorPalette.primary.oak}
          onClick={handleOpenLivePress}
          fullWidth
        >
          Open Live Brokerage Account
        </FlatButton>
      </ButtonWrapper>
    </>
  );
};

const RewardEarnedHeading = ({ rewardAmount, textColor }) => {
  return (
    <>
      <GiftImageWrapper>
        <img src={GiftImage} alt={'gift image'} />
      </GiftImageWrapper>

      <Container top={24} style={{ textAlign: 'center' }}>
        <Text fontSettings={fontSettings} bold center color={textColor}>
          {'You earned'}
        </Text>
        <Container top={16} centerAll>
          <Text fontSettings={rewardAmountFontSettings} color={textColor}>{`$${rewardAmount}`}</Text>
        </Container>
      </Container>
    </>
  );
};

export const LearnRewardEarned = (props) => {
  const { lessonId, rewardAmount, lockedRewardsAmount, completedLessonCount } = props;
  const xpAmount = 200;
  const { lesson: lessonData } = useLearnLesson(lessonId);
  const { name: lessonName, sponsorId } = lessonData;
  const { nextLessonId } = useNextLesson();
  const hasMoreLessonsAvailable = !isUndefinedOrNull(nextLessonId);

  const navigate = useNavigate();
  const textColor = colorPalette.primary.charcoal;

  const currentUser = useCurrentUser();
  const hasFunded = currentUser.has_funded;
  const hasApprovedLiveAccount = currentUser.is_live_trading_approved;

  const onDismiss = () => {
    if (!hasFunded && completedLessonCount > 3 && hasApprovedLiveAccount) {
      setTimeout(() => {
        navigate(ROUTES.FINISH_ACCOUNT_SETUP.build());
      }, 200);
    } else {
      navigate(ROUTES.DASHBOARD.build());
    }
  };

  const handleClose = () => {
    navigate(ROUTES.DASHBOARD.build());
  };

  React.useEffect(() => {
    TrackingEvents.learn.VIEW_LEARN_REWARD_SCREEN.send({
      'Sponsor ID': sponsorId,
      'Lesson ID': lessonId,
      Subject: lessonName,
      Amount: rewardAmount,
      'XP Amount': xpAmount,
      'Locked Rewards': lockedRewardsAmount,
    });

    TrackIteratively.learn.LEARN_LESSON_REWARD_VIEWED.send({
      lesson_id: lessonId,
      lesson_name: lessonName,
      xp_possible: 200,
    });
  }, []);

  const OpenLiveCTAComponent = <OpenLiveAccountCTA lockedRewardsAmount={lockedRewardsAmount} textColor={textColor} />;

  // const ShareCTAComponent = (
  //   <ShareLessonCTA lessonId={lessonId} textColor={textColor} context={'Learn Reward Earned'} />
  // );
  const ExtraContentComponent = <ExtraContent textColor={textColor} lessonId={lessonId} />;

  return (
    <LearnScreen onDismiss={onDismiss}>
      <Container flex={1} columnCenter>
        {rewardAmount > 0 ? (
          <div>
            <RewardEarnedHeading rewardAmount={rewardAmount} textColor={textColor} />
            <LearnRewardCTA {...props} lessonId={lessonId} />
          </div>
        ) : (
          <div>
            <SuccessDisplay />
          </div>
        )}
        {!!OpenLiveCTAComponent && <Container top={24}>{OpenLiveCTAComponent}</Container>}
      </Container>

      <Container columnCenter fullWidth top={8}>
        {!!ExtraContentComponent && (
          <Container top={16} centerAll fullWidth>
            {ExtraContentComponent}
          </Container>
        )}
        {/*{!!ShareCTAComponent && <Container bottom={16}>{ShareCTAComponent}</Container>}*/}
        <Container top={16} centerAll fullWidth style={{ textAlign: 'center' }}>
          {hasMoreLessonsAvailable ? <NextLessonAction /> : <LearnShareCTA textColor={textColor} />}
        </Container>
      </Container>
    </LearnScreen>
  );
};
