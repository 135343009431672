import { GlobalColors } from '../constants/colors';

import { customParseFloat } from './numberHelpers';
import {
  formatLocaleString,
  isUndefinedOrNull,
  returnBlankArrayWithNullElements,
  hexToColorObj,
} from './generalHelpers';

export const CHART_PERFORMANCE_COMPARISON_COLOR_INDEXES = [
  hexToColorObj(GlobalColors.green),
  hexToColorObj(GlobalColors.orange),
  hexToColorObj(GlobalColors.mediumYellow),
  hexToColorObj(GlobalColors.red),
  hexToColorObj(GlobalColors.magenta),
];

export const INTRADAY_TIME_PERIOD = 'one_day';

export const INTRADAY_TIME_PERIODS = ['one_day', 'two_days'];

export const DEFAULT_DATA_SET_PROPERTIES = {
  lineTension: 0.1,
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderColor: 'rgba(61, 183, 53, 0)',
  pointBackgroundColor: 'rgba(61, 183, 53, 0)',
  pointBorderWidth: 1,
  pointHoverBorderColor: 'rgba(220,220,220,1)',
  pointHoverBorderWidth: 2,
  pointHitRadius: 10,
  spanGaps: true,
};

export const customChartTooltip = function (tooltipModel) {
  // Tooltip Element
  var tooltipEl = document.getElementById('chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem.lines;
  }

  // Remove color box
  tooltipModel.displayColors = tooltipModel.body.length > 1;

  // Set Text
  if (tooltipModel.body) {
    // Set Label Title with formatted value
    const firstItemDataSet = tooltipModel.body[0].lines[0];
    var labelName = firstItemDataSet.label;
    tooltipModel.title = [labelName];
    var titleLines = [labelName];
    titleLines.forEach(function (title) {
      innerHtml += '<tr><th>' + title + '</th></tr>';
    });

    // set value for tooltip body
    tooltipModel.body.forEach((body) => {
      const itemDataSet = body.lines[0];
      var value = customParseFloat(itemDataSet.value);
      var formattedParsedLabelValueString = formatLocaleString(value, {
        minimumFractionDigits: 2,
        maximumFractionDigits: value < 1 ? 4 : 2,
      });
      var formattedLabelValue = '$' + formattedParsedLabelValueString;
      body.lines[0] = 'Price: ' + formattedLabelValue;
    });

    // Set Tooltip with formatted values
    var bodyLines = tooltipModel.body.map(getBody);
    var innerHtml = '<thead>';
    innerHtml += '</thead><tbody>';
    bodyLines.forEach(function (body, i) {
      var colors = tooltipModel.labelColors[i];
      var style = 'background:' + colors.backgroundColor;
      style += '; border-color:' + colors.borderColor;
      style += '; border-width: 2px';
      var span = '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
      innerHtml += '<tr><td>' + span + body + '</td></tr>';
    });
    innerHtml += '</tbody>';
    var tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  // `this` will be the overall tooltip
  var position = this._chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = position.left + tooltipModel.caretX + 'px';
  tooltipEl.style.top = position.top + tooltipModel.caretY + 'px';
  tooltipEl.style.fontFamily = tooltipModel._fontFamily;
  tooltipEl.style.fontSize = tooltipModel.fontSize;
  tooltipEl.style.fontStyle = tooltipModel._fontStyle;
  tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
};

export const isIntraOrTwoDayTimePeriod = (timePeriod) => {
  return INTRADAY_TIME_PERIODS.includes(timePeriod);
};

export const isIntradayTimePeriod = (timePeriod) => {
  return INTRADAY_TIME_PERIOD === timePeriod;
};

export const getCountOfIntervalsInTradingDay = () => {
  const minutesMarketIsOpen = 6 * 60 + 30;
  const minutesInInterval = 5;
  const countOfIntervals = minutesMarketIsOpen / minutesInInterval;
  return countOfIntervals;
};

export const addBlanksForIntradayMissingIntervals = (rawPriceHistoryArray) => {
  const countOfIntervals = getCountOfIntervalsInTradingDay();
  const blankPriceHistory = returnBlankArrayWithNullElements(countOfIntervals);
  return blankPriceHistory.map((el, index) => rawPriceHistoryArray[index] || { price: null, time: null });
};

export const addBlanksForTwoDayMissingIntervals = (rawPriceHistoryArray) => {
  const countOfIntervals = getCountOfIntervalsInTradingDay() * 2;
  const blankPriceHistory = returnBlankArrayWithNullElements(countOfIntervals);
  return blankPriceHistory.map((el, index) => rawPriceHistoryArray[index] || { price: null, time: null });
};

export const generateChartColor = (colorObj, opacity) => {
  if (!colorObj) {
    return `rgba( ${200}, ${255}, ${200}, ${0.8})`;
  }

  return `rgba( ${colorObj.red}, ${colorObj.green}, ${colorObj.blue}, ${opacity})`;
};

export const generateChartDatasetComparison = (chartData, comparisonChartData, timePeriod) => {
  let priceDataHistoryArray = comparisonChartData;
  if (isIntraOrTwoDayTimePeriod(timePeriod)) {
    if (isIntradayTimePeriod(timePeriod)) {
      priceDataHistoryArray = addBlanksForIntradayMissingIntervals(comparisonChartData);
    } else {
      priceDataHistoryArray = addBlanksForTwoDayMissingIntervals(comparisonChartData);
    }
  }

  const ratio = chartData[0].price / priceDataHistoryArray[0].price;
  const priceHistoryArray = priceDataHistoryArray.map((date) => date.price);

  return priceHistoryArray.map((price, i) => (isUndefinedOrNull(price) ? null : price * ratio));
};

export const generateChartDatasetComparisonForEquity = (chartData, comparisonChartData, transactions, timePeriod) => {
  let priceDataHistoryArray = comparisonChartData;
  if (isIntraOrTwoDayTimePeriod(timePeriod)) {
    if (isIntradayTimePeriod(timePeriod)) {
      priceDataHistoryArray = addBlanksForIntradayMissingIntervals(comparisonChartData);
    } else {
      priceDataHistoryArray = addBlanksForTwoDayMissingIntervals(comparisonChartData);
    }
  }

  let firstIndexOfValue = null;
  for (let i = 0; i < chartData.length; i++) {
    const datapoint = chartData[i];
    if (firstIndexOfValue === null) {
      const price = datapoint.price;
      if (price > 0) {
        firstIndexOfValue = i;
      }
    }
  }

  const ratio = chartData[firstIndexOfValue].price / priceDataHistoryArray[firstIndexOfValue].price;
  const priceHistoryArray = priceDataHistoryArray.map((date) => date.price);

  return priceHistoryArray.map((price, i) => (isUndefinedOrNull(price) ? null : price * ratio));
};
