import React from 'react';
import Icon from '../../../../components/misc/Icon';
import Button from '../../../../components/buttons/Button';
import SuccessDisplay from '../../../../components/UI/SuccessDisplay';
import { toCapitalCase } from '../../../../helpers/generalHelpers';
import { FlatButton } from '../../../../main/components/buttons';

const OrdersPlacedMessage = (props) => {
  const { orderCount, requiresApproval, handleDismiss } = props;
  // const orderCount = 1; // always one order no basket in app
  const orderString = orderCount > 1 ? 'orders' : 'order';

  if (requiresApproval) {
    return (
      <div className="orders-placed-message-container" style={{ textAlign: 'center' }}>
        <div>
          <div className={`orders-modal-message-container`}>
            <div className={`heading-icon-container`}>
              <Icon size="xlarge" icon="fa-check-circle" colorClassName={'success-text-color'} />
            </div>
            <div style={{ marginTop: '4px' }}>
              <p className="main-message">{`Your ${orderString} were placed successfully, and are awaiting approval.`}</p>
            </div>
            <div style={{ marginTop: '10px', marginBottom: '6px' }}>
              <p className="sub-message secondary-text-color">
                {`We will let you know when the approval status of the ${orderString} are updated.`}
              </p>
            </div>
          </div>
        </div>
        <div className={`orders-modal-message-action-container`}>
          <FlatButton transparent onClick={handleDismiss}>
            Close
          </FlatButton>
        </div>
      </div>
    );
  }

  return (
    <div className="orders-placed-message-container">
      <SuccessDisplay
        message={`${toCapitalCase(orderString)} placed`}
        closeBtnText={'Continue'}
        onCloseClick={handleDismiss}
        customMessageStyle={{
          marginTop: '-30px',
          fontSize: '24px',
        }}
      />
    </div>
  );
};

export default OrdersPlacedMessage;
