import { learnTypes } from '../main/constants/actionTypes';
import { slugifyLessonName } from '../main/utils/learn';
import { localLessons } from '../main/constants/learn/lessonDefinitions';

export const emptyLessonPreview = {
  disclaimerId: 1,
  blogDisclaimerId: 1,

  id: '',
  name: '',
  heading: '',
  phrase: '',
  minLevel: '',
  maxLevel: '',
  contents: [],
  blogDisclaimers: {},
  disclaimers: {},
};

const defaultState = {
  previewLesson: emptyLessonPreview,
  lessonData: {},
  lookup: {},
  lessonNameLookup: {},
  completed_lessons: [],
  nextLessonId: null,
  nextLessonReward: null,
  nextLessonRewardAtXP: null,
  nextLessonCompletionXP: null,
  completedLessonCount: null,
  hoursUntilNextLesson: null,
  status: null,
};

const createLocalLessonList = () => {
  const localLessonsList = Object.values(localLessons);
  return localLessonsList.map((l) => {
    return {
      id: l.id,
      description: l.name,
      preview_phrase: l.phraseDisplay,
    };
  });
};

const createLookup = (lessons) => {
  const lookup = {};
  lessons.forEach((l) => {
    lookup[l.id] = l;
  });
  return lookup;
};

const createLessonNameLookup = (lessons) => {
  const lookup = {};
  lessons.forEach((l) => {
    const slug = slugifyLessonName(l.description);
    lookup[slug] = l.id;
  });
  return lookup;
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case learnTypes.reset: {
      return {
        ...state,
        previewLesson: emptyLessonPreview,
      };
    }
    case learnTypes.updateLearnPreviewLesson: {
      const data = action.payload;
      return {
        ...state,
        previewLesson: {
          ...state.previewLesson,
          ...data,
        },
      };
    }

    case learnTypes.addBlogDisclaimer: {
      const nextId = state.previewLesson.blogDisclaimerId + 1;
      const newDisclaimer = {
        id: nextId,
        blogPost: '',
        blogPostName: '',
      };
      return {
        ...state,
        previewLesson: {
          ...state.previewLesson,
          blogDisclaimerId: nextId,
          blogDisclaimers: {
            ...state.previewLesson.blogDisclaimers,
            [nextId]: newDisclaimer,
          },
        },
      };
    }
    case learnTypes.updateBlogDisclaimer: {
      const updated = {
        ...action.payload,
      };
      return {
        ...state,
        previewLesson: {
          ...state.previewLesson,
          blogDisclaimers: { ...state.previewLesson.blogDisclaimers, [updated.id]: updated },
        },
      };
    }
    case learnTypes.deleteBlogDisclaimer: {
      const ds = { ...state.previewLesson.blogDisclaimers };
      delete ds[action.payload.id];
      return {
        ...state,
        previewLesson: {
          ...state.previewLesson,
          blogDisclaimers: ds,
        },
      };
    }

    case learnTypes.addDisclaimer: {
      const nextId = state.previewLesson.disclaimerId + 1;
      const newDisclaimer = {
        id: nextId,
        link: '',
        text: '',
      };
      return {
        ...state,
        previewLesson: {
          ...state.previewLesson,
          disclaimerId: nextId,
          disclaimers: {
            ...state.previewLesson.disclaimers,
            [nextId]: newDisclaimer,
          },
        },
      };
    }
    case learnTypes.updateDisclaimer: {
      const updated = {
        ...action.payload,
      };
      return {
        ...state,
        previewLesson: {
          ...state.previewLesson,
          disclaimers: { ...state.previewLesson.disclaimers, [updated.id]: updated },
        },
      };
    }
    case learnTypes.deleteDisclaimer: {
      const ds = { ...state.previewLesson.disclaimers };
      delete ds[action.payload.id];
      return {
        ...state,
        previewLesson: {
          ...state.previewLesson,
          disclaimers: ds,
        },
      };
    }

    case learnTypes.addLessonData: {
      const data = action.payload;
      return {
        ...state,
        lessonData: {
          ...state.lessonData,
          [data.id]: data,
        },
      };
    }

    case learnTypes.updateCompletedLessons: {
      const completed_lessons = action.payload;
      return {
        ...state,
        completed_lessons,
      };
    }

    case learnTypes.updateLookup: {
      const lessons = [...createLocalLessonList(), ...action.payload];
      const lookup = createLookup(lessons);
      const lessonNameLookup = createLessonNameLookup(lessons);
      return {
        ...state,
        lookup,
        lessonNameLookup,
      };
    }

    case learnTypes.setNextLesson: {
      const {
        nextLessonId,
        nextLessonReward,
        nextLessonRewardAtXP,
        nextLessonCompletionXP,
        completedLessonCount,
        hoursUntilNextLesson,
        messagingStates,
        status,
      } = action.payload;
      return {
        ...state,
        nextLessonId,
        nextLessonReward,
        nextLessonRewardAtXP,
        nextLessonCompletionXP,
        completedLessonCount,
        hoursUntilNextLesson,
        messagingStates,
        status,
      };
    }

    default:
      return state;
  }
}
