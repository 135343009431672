import { trackingEvents } from '@src/main/lib/nvstr-utils.es';

import ItlyTrackWrapper from './ItlyTrackWrapper';

const PRO_CON_VIEWED = new ItlyTrackWrapper(trackingEvents.proConViewed);
const SECURITY_VIEWED = new ItlyTrackWrapper(trackingEvents.securityViewed);
const PAGE_VIEWED = new ItlyTrackWrapper(trackingEvents.pageViewed);
const IDEA_VIEWED = new ItlyTrackWrapper(trackingEvents.ideaViewed);

const viewedTrackIteratively = {
  PRO_CON_VIEWED,
  SECURITY_VIEWED,
  PAGE_VIEWED,
  IDEA_VIEWED,
};

export default viewedTrackIteratively;
