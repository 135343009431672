import React from 'react';
import { returnPathTo } from '../../../constants/paths';
import { logMetricsTrackingEvent } from '../../../actions';

import Button from '../../../components/buttons/Button';
import { withRouter } from '../../../main/utils';
import { FlatButton } from '@src/main/components/buttons';

class SeeFullPositions extends React.Component {
  render() {
    return (
      <div className={`see-full-positions-action`}>
        <FlatButton handleClick={this._handleClick}>View Position Details</FlatButton>
      </div>
    );
  }

  _navigateToPositionsPage = () => {
    this.props.navigate(returnPathTo('positions'));
  };

  _handleClick = () => {
    this._logClick();
    this._navigateToPositionsPage();
  };

  _logClick = () => {
    const event = 'Clicked See Full Positions';
    const properties = {
      Context: this.props.viewingContext,
    };
    logMetricsTrackingEvent(event, properties)();
  };
}

export default withRouter(SeeFullPositions);
