import { createBasicErrorModal } from '../constants/modals';

export const handleNoResponseInAction = (response, dispatch, resetAction) => {
  if (!response) {
    const modalMessage = 'Request could not be completed. Please try again later.';
    const modal = {
      contentComponent: createBasicErrorModal(modalMessage),
      dismissable: true,
      size: 'wide',
    };
    dispatch({
      type: 'SHOW_MODAL',
      payload: modal,
    });

    if (resetAction) {
      resetAction();
    }
    return true;
  }
  return false;
};
