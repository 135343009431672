import React from 'react';
import EditPreferencesForm from './EditPreferencesForm';
import styled from 'styled-components';

const Wrapper = styled.div`
  input {
    //border: none;
    line-height: 29px;

    //-webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }

  select {
    //border: none;

    //-webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
`;

const EditPreferences = (props) => {
  return (
    <Wrapper className={`edit-preferences-container`}>
      <EditPreferencesForm />
    </Wrapper>
  );
};

export default EditPreferences;
