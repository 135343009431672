import itly from './itly.service';
import { PRODUCT_PLATFORMS, trackedRouteStore } from '../../main/lib/nvstr-utils.es';

try {
  const { missingRoutes: nonIgnoredMissingRoutes, duplicatedRoutes } = trackedRouteStore._scanForMissingWebappRoutes();
  const ignoredMissingRoutes = [];
  const missingRoutes = nonIgnoredMissingRoutes.filter((r) => !ignoredMissingRoutes.includes(r.routeName));

  if (missingRoutes.length > 0) {
    console.warn('Missing app routes in route tracking', missingRoutes);
    console.log(missingRoutes.map((m) => m.routeName));
  } else {
    console.log('No routes are missing in tracked routes');
  }

  if (duplicatedRoutes.length > 0) {
    console.warn('App routes are duplicated', duplicatedRoutes);
  } else {
    console.log('No routes are duplicated in tracked routes');
  }
} catch (e) {
  console.error(e);
}

class ItlyTrackWrapper {
  constructor(eventName) {
    this.name = eventName;
  }

  send(properties = {}) {
    const { location } = properties;
    if (location) {
      const sanitizedPath = location.slice(0, 4) === '/app' ? location.slice(4, location.length) : location;
      const trackingRouteName = trackedRouteStore.getTrackingNameForRoute(sanitizedPath, PRODUCT_PLATFORMS.webApp);
      if (trackingRouteName === null) console.warn('missing tracking screen name: ' + sanitizedPath);
      properties.location = trackingRouteName;
    }

    itly.track(this.name, properties);
  }
}

export default ItlyTrackWrapper;
