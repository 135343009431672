import React from 'react';
import SecurityDataGetter from '../../../SecurityData/SecurityDataGetter';
import { PriceTargetIdeaTable } from './IdeaPriceTargetNotificationComponents/PriceTargetIdeaTable';
import { isIdeaABuy, isIdeaASell, getSecurityIdFromIdea } from '../../../../helpers/ideaHelpers';
import { formatLocalizedDateTime, moment } from '../../../../helpers/timeHelpers';
import OpenOptimizationPanelButton from '../../../buttons/OpenOptimizationPanelButton';

export const IdeaPriceTargetNotification = (props) => {
  return null;
};

export const old_IdeaPriceTargetNotification = (props) => {
  const { notification } = props;
  const { exceeded_price_target_infos, owner, timestamp } = notification;

  const priceTargetInfoOfIdeasAbovePriceTarget = exceeded_price_target_infos.filter((priceTargetInfo) =>
    isIdeaABuy(priceTargetInfo.idea)
  );

  const priceTargetInfoOfIdeasBelowPriceTarget = exceeded_price_target_infos.filter((priceTargetInfo) =>
    isIdeaASell(priceTargetInfo.idea)
  );

  let isAPositionInAPriceTargetExceededIdea = false;
  const securityIdsFromAllIdeasInNotification = [];
  exceeded_price_target_infos.forEach((priceTargetInfo) => {
    const idea = priceTargetInfo.idea;
    const securityId = getSecurityIdFromIdea(idea);
    if (!securityIdsFromAllIdeasInNotification.includes(securityId)) {
      securityIdsFromAllIdeasInNotification.push(securityId);
    }
    if (priceTargetInfo.has_position) {
      isAPositionInAPriceTargetExceededIdea = true;
    }
  });

  const notificationTimeObj = moment(timestamp * 1000);
  const notificationCreationDateString = formatLocalizedDateTime('ls', notificationTimeObj);

  const exceededPriceTargetInfosWithPositions = exceeded_price_target_infos.filter(
    (exceeded_price_target_info) => exceeded_price_target_info.has_position
  );
  const hasMultiPositions = exceededPriceTargetInfosWithPositions.length > 1;
  const stocksWithPositions = exceededPriceTargetInfosWithPositions.map(
    (exceeded_price_target_info) => exceeded_price_target_info.idea.security.symbol
  );
  const lastStockTicker = stocksWithPositions.length > 1 ? stocksWithPositions.pop() : null;

  let positionsString = stocksWithPositions.join(', ');
  if (lastStockTicker) {
    positionsString += `, and ${lastStockTicker}`;
  }

  return (
    <div className={`idea-price-target-notification-container`}>
      <SecurityDataGetter securityIds={securityIdsFromAllIdeasInNotification} getBasicSecurityData />
      <div className={``}>{`Stocks exceeding your price target - ${notificationCreationDateString}`}</div>
      {priceTargetInfoOfIdeasAbovePriceTarget.length > 0 && (
        <PriceTargetIdeaTable
          heading={
            <span>
              <span className="buy-text-color">{'Buy'}</span>
              <span>&nbsp;</span>
              <span>{'Ideas Above Price Target'}</span>
            </span>
          }
          priceTargetInfos={priceTargetInfoOfIdeasAbovePriceTarget}
        />
      )}
      {priceTargetInfoOfIdeasBelowPriceTarget.length > 0 && (
        <PriceTargetIdeaTable
          heading={
            <span>
              <span className="sell-text-color">{'Sell'}</span>
              <span>&nbsp;</span>
              <span>{'Ideas Below Price Target'}</span>
            </span>
          }
          priceTargetInfos={priceTargetInfoOfIdeasBelowPriceTarget}
        />
      )}
      {isAPositionInAPriceTargetExceededIdea && (
        <div className={`price-target-action-message`}>
          <div className={`message-line`}>
            {`Optimize to automatically exit position${hasMultiPositions ? 's' : ''} in ${positionsString}`}
          </div>
          <div className={`action-container`}>
            <OpenOptimizationPanelButton viewingContext={'Price Target Exceeded Notification'} />
          </div>
        </div>
      )}
    </div>
  );
};
