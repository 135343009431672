import React from 'react';

import IconButton from '../../components/buttons/IconButton';

const TextExpander = (props) => {
  const { text, defaultExpandedState } = props;

  const [isExpanded, setIsExpanded] = React.useState(!!defaultExpandedState);

  const onExpandPress = () => {
    setIsExpanded(true);
  };

  const onCollapsePress = () => {
    setIsExpanded(false);
  };

  return isExpanded ? (
    <div>
      <span className={'secondary-text-color'}>{text}</span>
      <div
        style={{
          textAlign: 'right',
          width: '100%',
        }}
      >
        <IconButton
          size={'small'}
          icon="fa-chevron-up"
          colorClassName="secondary-text-color"
          handleClick={onCollapsePress}
        />
      </div>
    </div>
  ) : (
    <div
      className={'default-flex-space-between'}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        width: '100%',
      }}
    >
      <div
        className={'secondary-text-color'}
        style={{
          width: '85%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <span className={'secondary-text-color'}>{text}</span>
      </div>
      <div style={{ marginLeft: 8 }}>
        <IconButton
          size={'small'}
          icon="fa-chevron-down"
          colorClassName="secondary-text-color"
          handleClick={onExpandPress}
        />
      </div>
    </div>
  );
};

export default TextExpander;
