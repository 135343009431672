import React from 'react';

import InfoIcon from '../UI/InfoIcon';

import { snakeToHyphens } from '../../helpers/generalHelpers';
import { getFilterDisplayName } from '../../constants/finData';

const TableHeading = (props) => {
  const columns = props.columns;
  return (
    <thead>
      <tr className={`security-table-security-row`}>
        {columns.map((col) => {
          const heading = typeof col === 'string' ? getFilterDisplayName(col) : col.headingDisplayName;
          const key = typeof col === 'string' ? col : col.keyName;
          return (
            <th
              key={`security-table-${key}-heading`}
              className={`security-table-${snakeToHyphens(key)}`}
              style={{ cursor: 'default' }}
            >
              <span style={{ position: 'relative' }}>
                <span className="table-heading-text-color">{heading}</span>
                {col.infoIcon && (
                  <InfoIcon
                    word={`tooltip_${col.customInfoIconName || col.keyName}`}
                    style={{ top: '0px', right: '-21px' }}
                    position={col.infoIconPosition || 'default'}
                  />
                )}
              </span>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHeading;
