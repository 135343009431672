import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  & > svg {
    width: 18px;
    height: 18px;
  }

  & g,
  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function ExchangeIcon() {
  return (
    <Wrapper>
      <svg viewBox="0 0 16 12" fill="none">
        <path
          d="M3.825 5.167L.5 8.5l3.325 3.333v-2.5h5.842V7.667H3.825v-2.5zM15.5 3.5L12.175.167v2.5H6.333v1.666h5.842v2.5L15.5 3.5z"
          fill="#1F1F1F"
        />
      </svg>
    </Wrapper>
  );
}

export default ExchangeIcon;
