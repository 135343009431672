import { useSelector } from 'react-redux';
import { getCurrentUserFromState } from '../../../selectors/currentUser';
import { shouldUseStreamingPrices } from '../../../helpers/currentUserHelpers';
import { useIsLiveTrading } from './useIsLiveTrading';

export const useCurrentUser = () => {
  const currentUser = useSelector(getCurrentUserFromState);
  return currentUser;
};

export const useIsLivePricingEnabled = () => {
  const currentUser = useCurrentUser();
  return shouldUseStreamingPrices(currentUser);
};

export const useIsDelayedPricesEnabled = () => {
  const currentUser = useCurrentUser();
  return !shouldUseStreamingPrices(currentUser);
};

export const useIsExtendedPricingEnabled = () => {
  const isLiveTrading = useIsLiveTrading();
  return isLiveTrading;
};
