import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Body1, Body3, defineFontSettings } from '../lib/nvstr-common-ui.es';
import { Text } from '../components/text';
import { SkeletonButton } from '../components/buttons';
import { parseQueryString } from '../../helpers/routerHelpers';
import styled from 'styled-components';
import { TrackingEvents } from '../../utils/tracking/events';
import { TrackIteratively } from '../../utils/itly';
import { useLearnLesson } from '@src/main/hooks/learn/useLearnLesson';
import { Container } from '@src/main/components/ui';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 10;

  display: flex;
  flex-direction: column;
  flex: 1;
`;
const LinkWrapper = styled.div`
  padding: 4px 0 0 0;
  word-break: break-word;
`;
const ContentWrapper = styled.div`
  background: ${({ theme }) => theme.themeColors.componentNoOpacity};

  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding: 0 24px;
`;
const ContentBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const fontSettings = defineFontSettings(
  {
    fontSize: '16px',
    lineHeight: '24px',
  },
  {
    fontSize: '24px',
    lineHeight: '28px',
  }
);

const DisclaimerLine = ({ data, color }) => {
  const { text, slides, link, blogPostName, blogPost } = data;

  if (blogPost) {
    return (
      <div style={{ padding: '0 0 16px 0' }}>
        <Body3>Read more in our blog post here: </Body3>
        {(blogPost || link) && (
          <LinkWrapper>
            <Body3>
              <a target={'_blank'} href={blogPost || link}>
                {blogPost ? blogPostName : link}
              </a>
            </Body3>
          </LinkWrapper>
        )}
      </div>
    );
  }

  return (
    <div style={{ padding: '0 0 16px 0' }}>
      <Body3>{text}</Body3>
      {link && (
        <LinkWrapper>
          <Body3>
            <a href={link}>{link}</a>
          </Body3>
        </LinkWrapper>
      )}
    </div>
  );
};

const LearnAndEarnMoreInfo = ({ lessonId }) => {
  const d = useLearnLesson(lessonId);
  const { disclaimers, heading, name } = d?.lesson || {};

  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    TrackingEvents.learn.VIEW_LEARN_DISCLAIMER_SCREEN.send({
      Subject: name,
      'Lesson ID': lessonId,
    });

    TrackIteratively.learn.LEARN_LESSON_DISCLAIMER_VIEWED.send({
      lesson_id: lessonId,
      lesson_name: name,
      xp_possible: 200,
    });
  }, []);

  const handleClose = () => {
    if (searchParams.has('showMoreInfo')) {
      searchParams.delete('showMoreInfo');
      setSearchParams(searchParams);
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <ContentBodyWrapper>
          <div style={{ padding: '24px 0' }}>
            {heading && (
              <Text uppercase fontSettings={fontSettings} bold>
                {`Learn About ${heading}`}
              </Text>
            )}
            <div style={{ padding: '8px 0 0 0' }}>
              <Body1>More Information</Body1>
            </div>
          </div>

          <div style={{ padding: '8px 0 0 0 ' }}>
            {!disclaimers || disclaimers.length === 0 ? (
              <div>
                <Body3>Check out more interesting articles at our blog: </Body3>
                <a href={'https://www.tornado.com/blog'} target="_blank">
                  tornado.com/blog
                </a>
              </div>
            ) : (
              disclaimers.map((d, i) => <DisclaimerLine key={i} data={d} />)
            )}
          </div>
        </ContentBodyWrapper>
        <Container top={36} bottom={16} centerAll>
          <SkeletonButton onClick={handleClose} buttonProps={{ width: '225px' }} fullWidth>
            GO BACK
          </SkeletonButton>
        </Container>
      </ContentWrapper>
    </Wrapper>
  );
};

export default LearnAndEarnMoreInfo;
