import React from 'react';
import styled from 'styled-components';
// import { CheckCircleFilled } from '../../icons/svg/CheckCircleFilled';
import { H5, Body6 } from '../../lib/nvstr-common-ui.es';
import { Page } from '../layout';
import { FlatButton } from '../buttons';

const RetryAction = styled.div`
  padding: 16px 0 0 0;
  display: flex;
  justify-content: center;
`;

export const Error = ({ message, width, onRetry }) => {
  return (
    <Page width={width}>
      <div style={{ padding: '36px 0', textAlign: 'center' }}>
        <div style={{ padding: '16px 0 0 0', textAlign: 'center' }}>
          <Body6>{message}</Body6>
        </div>
      </div>
      <RetryAction>
        <FlatButton fullWidth onClick={onRetry}>
          Retry
        </FlatButton>
      </RetryAction>
    </Page>
  );
};
