import React from 'react';
import { isFunction } from '../../helpers/generalHelpers';
import styled from 'styled-components';

const Wrapper = styled.div`
  border-radius: 6px;
  overflow: hidden;

  border: 1px solid ${({ theme }) => theme.themeColors.border};
`;

class Toggle extends React.Component {
  constructor() {
    super();
    this.state = {
      showDisabledMessage: false,
      disabledMessageTarget: null,
      timeoutToShowMessage: null,
    };
  }

  componentWillUnmount() {
    this._hideDisabledMessage();
  }

  render() {
    return (
      <div className={`toggle-container ${this._returnName()} ${this._returnToggleContainerClass()}`}>
        {this._renderToggles()}
        {this._renderErrorMessage()}
      </div>
    );
  }

  _renderToggles = () => {
    return (
      <div className="toggle-switch-container squared-toggle">
        <Wrapper className="toggle-switch with-extra-props">
          {[0, 1].map((i) => this._renderToggle(i))}
          {this._renderDisabledMessage()}
        </Wrapper>
      </div>
    );
  };

  _renderToggle = (i) => {
    const value = this._returnValues()[i];
    const text = value.display;
    const isDisabled = this._isDisabled(value);
    return (
      <div
        key={`toggle-${this._returnName()}${i}`}
        className={`
          ${i === 0 ? 'toggle-left' : 'toggle-right'}
          ${this._isActive(i)}
          ${this._returnToggleClass(value)}
        `}
        onClick={this._bindIndexToHandleToggleClick(i)}
        onMouseEnter={this._bindHandleMouseEnter(value)}
        onMouseLeave={this._bindHandleMouseLeave(value)}
      >
        {text}
      </div>
    );
  };

  _shouldShowDisabledMessage = () => this.state.showDisabledMessage;
  _returnTargetOfDisabledMessage = () => this.state.disabledMessageTarget;
  _returnClassForDisabledMessage = () => {
    if (!this._returnValues()[0] && this._returnTargetOfDisabledMessage()) {
      return null;
    }
    return this._returnValues()[0].id === this._returnTargetOfDisabledMessage().id
      ? 'left-toggle-disabled'
      : 'right-toggle-disabled';
  };
  _returnDisabledMessage = () => this.props.returnDisabledMessage(this._returnTargetOfDisabledMessage());
  _renderDisabledMessage = () => {
    if (!this._shouldShowDisabledMessage()) {
      return null;
    }
    return (
      <div className={`toggle-disabled-message ${this._returnClassForDisabledMessage()}`}>
        <div className={`toggle-disabled-message-arrow`}></div>
        {this._returnDisabledMessage()}
      </div>
    );
  };

  _isError = () => !!this._returnErrorMessage();
  _returnErrorMessage = () => this.props.getErrorMessage(this._returnName());

  _renderErrorMessage = () => {
    const errorMessage = this._isError() ? this._returnErrorMessage() : '';
    if (!this._isError() && this.props.autoHideErrors) {
      return null;
    }
    return <p className={`text-field-error-message`}>{errorMessage}</p>;
  };

  _returnName = () => this.props.name || '';
  _returnValue = () => this.props.getValue(this._returnName());
  _returnValues = () => this.props.values;
  _returnToggleContainerClass = (value) => {
    const classes = [];
    if (this._isError()) {
      classes.push('is-error');
    }
    return classes.join(' ');
  };
  _returnToggleClass = (value) => {
    const classes = [];
    if (this.props.toggleButtonClass) {
      classes.push(this.props.toggleButtonClass);
    }
    if (this._isDisabled(value)) {
      classes.push('toggle-disabled');
    }
    return classes.join(' ');
  };

  _isActive = (i) => {
    const values = this._returnValues();
    const value = values[i];
    const activeValue = this._returnValue();
    return activeValue && activeValue.id === value.id ? 'toggle-active' : '';
  };

  _isDisabled = (value) => {
    if (this.props.isDisabled && isFunction(this.props.isDisabled)) {
      return this.props.isDisabled(value);
    } else {
      return false;
    }
  };

  _bindIndexToHandleToggleClick = (i) => {
    const values = this._returnValues();
    const value = values[i];
    if (this._isDisabled(value)) {
      return () => this._showDisabledMessage(value);
    }
    return () => {
      this._handleToggleClick(value);
      this._saveOnChange(value);
    };
  };

  _handleToggleClick = (value) => {
    this.props.handleChange(this._returnName(), value);
    this._saveOnChange();
  };

  _bindHandleMouseEnter = (value) => {
    if (this._isDisabled(value)) {
      return () => this._handleMouseEnter(value);
    } else {
      return this._doNothing;
    }
  };
  _bindHandleMouseLeave = (value) => {
    if (this._isDisabled(value)) {
      return () => this._handleMouseLeave(value);
    } else {
      return this._doNothing;
    }
  };

  _showDisabledMessage = (value) => {
    const state = value
      ? {
          showDisabledMessage: true,
          disabledMessageTarget: value,
        }
      : {
          showDisabledMessage: true,
        };
    this.setState((prevState) => ({
      ...state,
    }));
  };
  _showDelayedDisabledMessage = (value) => {
    const timeoutToShowMessage = this._createShowDisabledMessageTimeout();
    this.setState((prevState) => ({
      disabledMessageTarget: value,
      timeoutToShowMessage,
    }));
  };
  _hideDisabledMessage = () => {
    if (this.state.timeoutToShowMessage) {
      window.clearTimeout(this.state.timeoutToShowMessage);
    }
    this.setState((prevState) => ({
      showDisabledMessage: false,
      disabledMessageTarget: null,
      timeoutToShowMessage: null,
    }));
  };
  _handleMouseEnter = (value) => {
    this._showDelayedDisabledMessage(value);
  };
  _handleMouseLeave = () => {
    this._hideDisabledMessage();
  };
  _returnDelayForDisabledMessageHoverToShowMessage = () => 300;
  _createShowDisabledMessageTimeout = () => {
    const _this = this;
    const timeout = window.setTimeout(
      _this._showDisabledMessage,
      _this._returnDelayForDisabledMessageHoverToShowMessage()
    );
    return timeout;
  };

  // used if you want to persist after change, saveOnChange must be a function that makes the ajax call
  _saveOnChange = (data) => {
    // TODO:
    // this might not work
    // if (this.props.saveOnChange) {
    //   const data = { [`${this.props.objectType}_id`]: this.props.getObjectId(this.props.objectType), [name]: selectionValues[i] };
    //   this.props.saveOnChange(data);
    // }
  };

  _doNothing = () => false;
}

export default Toggle;
