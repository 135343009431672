import { logMetricsTrackingEvent } from '../../../actions';

const SET_INVESTING_EXPERIENCE = {
  send: (properties) => {
    // Value
    const event = 'Set Investing Experience';
    logMetricsTrackingEvent(event, properties)();
  },
};

const VIEW_ANNUAL_MEMBERSHIP_OPT_IN = {
  send: (properties) => {
    const event = 'View Annual Membership Opt-In';
    logMetricsTrackingEvent(event, properties)();
  },
};

const CLICK_ANNUAL_MEMBERSHIP_OPT_IN_ACTION = {
  send: (properties) => {
    // Action: <Upgrade : Decline>
    const event = 'Click Annual Membership Opt-In Action';
    logMetricsTrackingEvent(event, properties)();
  },
};

const CLICK_ANNUAL_MEMBERSHIP_OPT_IN_CONFIRMATION_MODAL_ACTION = {
  send: (properties) => {
    // Action: <Upgrade : Decline>
    const event = 'Click Annual Membership Opt-In Confirmation Modal Action';
    logMetricsTrackingEvent(event, properties)();
  },
};

const ANNUAL_MEMBERSHIP_OPT_IN_REQUEST_SUCCESS = {
  send: (properties) => {
    const event = 'Annual Membership Opt-In Request Success';
    logMetricsTrackingEvent(event, properties)();
  },
};
const ANNUAL_MEMBERSHIP_OPT_IN_REQUEST_FAILURE = {
  send: (properties) => {
    // Error Message: <message>
    const event = 'Annual Membership Opt-In Request Failure';
    logMetricsTrackingEvent(event, properties)();
  },
};

const trackingEvents = {
  SET_INVESTING_EXPERIENCE,

  VIEW_ANNUAL_MEMBERSHIP_OPT_IN,
  CLICK_ANNUAL_MEMBERSHIP_OPT_IN_ACTION,
  CLICK_ANNUAL_MEMBERSHIP_OPT_IN_CONFIRMATION_MODAL_ACTION,

  ANNUAL_MEMBERSHIP_OPT_IN_REQUEST_SUCCESS,
  ANNUAL_MEMBERSHIP_OPT_IN_REQUEST_FAILURE,
};

export default trackingEvents;
