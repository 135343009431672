import { sendRequestV1 } from '@src/helpers/ajaxHelpers';
import { BASEURL } from '@src/adapters';
import { sleep } from '@src/helpers/usefulFuncs';

const V1_URL = `${BASEURL}/api/v1`;

export const updateLiveAccount = async (data) => {
  const response = await sendRequestV1({
    method: 'patch',
    url: `${V1_URL}/brokerage_account`,
    body: { brokerage_account: data },
  });
  const { status, errorMessage } = response;
  if (status === 200) {
    return {
      ok: true,
    };
  } else {
    return {
      ok: false,
      message: errorMessage,
    };
  }
};
