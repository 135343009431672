import React from 'react';

const SecurityFilterSelection = (props) => {
  const { filterName } = props;

  function handleClick() {
    return props.handleClick(filterName);
  }

  return (
    <div className="security-filter-selection selectable-item" onClick={handleClick}>
      {filterName}
    </div>
  );
};

export default SecurityFilterSelection;
