import React from 'react';

import LoadingIcon from '../../components/misc/LoadingIcon';

import { formatSecurityPropertyValue } from '../../helpers/securitiesHelpers';
import { isUndefinedOrNull } from '../../helpers/generalHelpers';

const SecurityDataComponent = (props) => {
  const {
    className,

    isLoading,
    security,
    property,

    additionalFormatting,

    hideIfNA,
    renderInLabel,
    containerClassName,
    labelClassName,
    labelText,
    valueClassName,
  } = props;

  const value = security[property];
  const formattedValue = formatSecurityPropertyValue(value, property);
  const displayValue = additionalFormatting ? additionalFormatting(formattedValue) : formattedValue;

  if (isLoading) {
    return <LoadingIcon icon="fading-3balls" size="small" />;
  }

  if (hideIfNA && isUndefinedOrNull(value)) {
    return null;
  }

  if (renderInLabel) {
    return (
      <div className={`${containerClassName}`}>
        <div className={`${labelClassName}`}>{labelText}</div>
        <div className={`${valueClassName}`}>{displayValue}</div>
      </div>
    );
  } else {
    return <div className={`security-data-component ${className || ''}`}>{displayValue}</div>;
  }
};

export default SecurityDataComponent;
