import { SAVE_APP_STORAGE, REMOVE_KEY_APP_STORAGE } from '../constants';

const defaultState = {};

export default function applicationReducer(state = defaultState, action) {
  const payload = action.payload;

  switch (action.type) {
    case SAVE_APP_STORAGE:
      return {
        ...state,
        ...payload,
      };

    case REMOVE_KEY_APP_STORAGE:
      let newState = {};
      const keysInState = Object.keys(state);
      keysInState.forEach((key) => {
        if (key !== payload) {
          newState[key] = state[key];
        }
      });
      return newState;

    default:
      return state;
  }
}
