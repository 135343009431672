import React from 'react';

import { UserAvatarList } from '../../users/UserAvatarList';
import ThumbsUp from '../../../assets/SVGS/ThumbsUp';
import ThumbsDown from '../../../assets/SVGS/ThumbsDown';

import { toCapitalCase } from '../../../helpers/generalHelpers';
import { CommunityAvatar } from '../Avatars/CommunityAvatar';
import { logMetricsTrackingEvent } from '../../../actions';
import { PROFILE_CONTEXTS } from '../../../constants/contextTracking';

export const ThoughtNotificationUserOpinionRow = (props) => {
  const {
    opinionType,
    userIds,
    totalCount,

    isCTLThought,
    isSocialStyle,
  } = props;

  const opinionLabelString = `${toCapitalCase(opinionType)}d`;
  const isAgree = opinionType === 'agree';
  const icon = isAgree ? <ThumbsUp height={'14px'} width={'14px'} /> : <ThumbsDown height={'14px'} width={'14px'} />;
  const opinionColorClassName = isAgree ? 'agree-text-color' : 'disagree-text-color';

  const communityMembersCount = totalCount - userIds.length;

  const logNonAgreeElementClick = () => {
    const event = 'Click Non Agree/ Disagree Button';
    const properties = {
      'Is CTL Thought': isCTLThought,
      'Is Agree': isAgree,
      'Is Social Style': isSocialStyle,
    };
    logMetricsTrackingEvent(event, properties)();
  };

  const renderCommunityMembersItem = () => {
    return (
      <li className="">
        <div className={`user-container`}>
          <CommunityAvatar withLink={false} />
          <div className={`user-name-container   text-decoration-none ignore-pointer`}>
            {`${communityMembersCount} Community Member${communityMembersCount > 1 ? 's' : ''}`}
          </div>
        </div>
      </li>
    );
  };

  return (
    <div
      className={`thought-notification-user-row ${isAgree ? 'is-agree' : 'is-disagree'}`}
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <div className={`user-avatars-container`}>
        <UserAvatarList
          userIds={userIds}
          maxShowIdCount={4}
          profileLinkQuery={`?context=${PROFILE_CONTEXTS.NOTIFICATION}`}
          additionalCount={communityMembersCount}
          additionalListItem={communityMembersCount > 0 ? renderCommunityMembersItem() : null}
          showEllipsisUserListAfter
        />
      </div>
      <div className={`thought-opinion-label`} onClick={logNonAgreeElementClick}>
        <div className={`thought-opinion-string  `}>{opinionLabelString}</div>
      </div>
    </div>
  );
};
