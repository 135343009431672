import React, { useState } from 'react';
import { connect } from 'react-redux';

import { getUpdatedNotifications, logMetricsTrackingEvent, postUpdatedLastSeenNotificationTime } from '../../actions';

import NewNotificationsCount from '../../dataContainers/notifications/NewNotificationsCount';
import Button from '../../components/buttons/Button';
import { sendFacebookTrackingEvent } from '../../constants/facebookTracking';
import { returnCurrentUserThemeTestGroups } from '../../helpers/currentUserHelpers';

const NotificationsFeedRefreshButton = (props) => {
  const {
    currentUser,

    dispatch,
  } = props;

  const [isRefreshing, setIsRefreshing] = useState(false);

  const logRefreshFeedClick = () => {
    sendFacebookTrackingEvent('Click Feed Refresh Button', {
      themeTestGroups: returnCurrentUserThemeTestGroups(currentUser),
    });
    const event = 'Clicked Refresh On Notifications Feed';
    const properties = {};
    logMetricsTrackingEvent(event, properties)();
  };
  const forceRefreshGetUpdatedNotifications = () => {
    const config = {
      batch_index: 0,
      page: 1,
      forceRefresh: true,
    };
    return getUpdatedNotifications(config)(dispatch);
  };

  const handleRefreshFeedClick = () => {
    setIsRefreshing(true);
    forceRefreshGetUpdatedNotifications().then((response) => {
      setIsRefreshing(false);
      postUpdatedLastSeenNotificationTime()(dispatch);
    });
    logRefreshFeedClick();
  };

  return (
    <NewNotificationsCount>
      {(unseenCount, unseenCountDisplay, areUnseenNotifications) =>
        areUnseenNotifications && (
          <div
            style={{
              position: 'absolute',
              top: '-11px',
              left: 0,
              right: 0,
              textAlign: 'center',
              zIndex: 2,
            }}
          ></div>
        )
      }
    </NewNotificationsCount>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps)(NotificationsFeedRefreshButton);
