// LEARN ACTIONS
export const learnTypes = {
  addLessonData: 'ald',
  updateCompletedLessons: 'ucl',
  updateLookup: 'update-lookup',
  setNextLesson: 'snl',
  updateLearnPreviewLesson: 'ulpl',

  reset: 'reset',

  addBlogDisclaimer: 'abd',
  updateBlogDisclaimer: 'ubd',
  deleteBlogDisclaimer: 'dbd',

  addDisclaimer: 'ad',
  updateDisclaimer: 'ud',
  deleteDisclaimer: 'dd',
};
