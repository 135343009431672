import { reverseKeys } from '../helpers/generalHelpers';

const filterFieldToDisplayNameDict = {
  revenue_ltm: 'Revenue LTM',
  price_to_tangible_book_value: 'Price / Tangible Book',
  analyst_2yr_growth_projection: 'Projected Revenue Growth',
  projected_revenue_growth: 'Proj. Rev. Growth',
  thought_leaders: 'Owned by Thought Leaders',
  connections: "On Connections' Watchlists",
  owned_by: 'Owned By',
  sentiment: 'Investor Sentiment',
  current_price: 'Price',
  current_price_change: 'Price Change',
  current_price_change_percent: 'Price Change %',
  dividend_yield: 'Dividend Yield',
  exchange: 'Exchange',
  backward_pe_ratio: 'Price / Earnings',
  forward_pe_ratio_this_year: 'Forward P/E Curr Yr', // Note: "this_year" on the front end corresponds to what is called "Forward P/E Curr Yr" in the data
  forward_pe_ratio_next_year: 'Forward P/E', // Note: "next_year" on the front end corresponds to what is called just "Forward P/E" in the data
  forward_pe_ratio_next_next_year: 'Forward P/E Next Yr', // Note: "next_next_year" on the front end corresponds to what is called just "Forward P/E Next Yr" in the data
  forward_eps_this_quarter: 'Forward EPS Curr Qtr',
  forward_eps_this_year: 'Forward EPS Curr Yr',
  market_cap: 'Market Cap',
  net_debt: 'Net Debt',
  sector: 'Sectors',
  idea_type: 'Idea Type',
  collective_intelligence: 'Collective Intelligence',
  correlation: 'Avg. Correlation',

  price_target: 'Price Target',
  expected_return: 'Expected Return',
  expected_dividend: 'Expected Dividend',
  conviction: 'Conviction',
  thesis: 'Thesis',
  horizon: 'Horizon',
};

export const getFilterDisplayName = (filterName, reverse) => {
  if (reverse) {
    const fieldName = reverseKeys(filterFieldToDisplayNameDict)[filterName];
    return fieldName;
  }

  const fieldName = filterFieldToDisplayNameDict[filterName];
  return fieldName;
};
