import { adminAdapter } from '@src/adapters/adminAdapter';

export const postLessonToApi = async (lessonData) => {
  const response = await adminAdapter.postLessonToApi(lessonData);
  const { status, data } = response;
  if (status === 200) {
    return {
      ok: true,
      data,
    };
  }
  return {
    error: true,
  };
};
export const publishLesson = async (lesson_id) => {
  const response = await adminAdapter.publishLesson(lesson_id);
  const { status, data } = response;
  if (status === 200) {
    return {
      ok: true,
      data,
    };
  }
  return {
    error: true,
  };
};
