import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  & > svg {
    width: 18px;
    height: 18px;
  }
  & g,
  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function LightBulbIcon() {
  return (
    <Wrapper>
      <svg viewBox="0 0 12 17" fill="none">
        <path
          d="M3.333 15.833c0 .459.375.834.834.834H7.5a.836.836 0 00.833-.834V15h-5v.833zM5.833 0A5.84 5.84 0 000 5.833a5.83 5.83 0 002.5 4.784V12.5c0 .458.375.833.833.833h5a.836.836 0 00.834-.833v-1.883a5.83 5.83 0 002.5-4.784A5.84 5.84 0 005.833 0zm2.375 9.25l-.708.5v1.917H4.167V9.75l-.709-.5a4.164 4.164 0 01-1.791-3.417c0-2.3 1.866-4.166 4.166-4.166 2.3 0 4.167 1.866 4.167 4.166A4.164 4.164 0 018.208 9.25z"
          fill="#1F1F1F"
        />
      </svg>
    </Wrapper>
  );
}

export default LightBulbIcon;
