import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../../../actions/index';

import Icon from '../../../../components/misc/Icon';
import MutualConnectionRow from './MutualConnectionRow';

import { userFullName } from '../../../../helpers/usersHelpers';
import { InlineUserList } from '../../../UI/InlineUserList';
import { TruncateHeightWrapper } from '../../../UI/TruncateHeightWrapper';

class MutualConnectionsList extends Component {
  render() {
    const { userIds, emptyString } = this.props;
    return (
      <div className={`mutual-connections-list-container ${userIds.length === 0 ? 'text-center no-decoration' : ''}`}>
        {this.renderMutualConnectionUsers()}
        {userIds.length === 0 && <div style={{ textAlign: 'center', width: '100%' }}>{emptyString}</div>}
      </div>
    );
  }

  renderMutualConnectionUsers = () => {
    const { userIds, useScrollIfToTall } = this.props;
    if (userIds.length === 0) return null;
    return (
      <div
        className={`mutual-connections-container ${
          useScrollIfToTall ? 'mutual-connections-container-scroll' : ''
        } secondary-text-color`}
      >
        <Icon icon={'fa-users'} colorClassName="secondary-text-color" size="small" />
        <InlineUserList
          userIds={userIds}
          listLengthLimit={'maxUserNamesToShow' in this.props ? this.props.maxUserNamesToShow : 2}
          userNameProps={{ displayNameStyle: 'fullName' }}
          handleOtherClick={this._handleMutualConnectionsListClick}
        />
      </div>
    );
  };

  _handleMutualConnectionsListClick = () => {
    if (this.props.userIds.length > 0) {
      this.showMutualConnectionModal();
    }
  };

  showMutualConnectionModal = () => {
    const event = 'View Mutual Connections';
    const properties = { context: 'Suggested Connection' };
    this.props.actions.logMetricsTrackingEvent(event, properties);

    const modal = {
      contentComponent: this.createMutualConnectionModal(),
      dismissable: true,
      size: 'wide',
    };
    this.props.actions.showModal(modal);
  };

  createMutualConnectionModal = () => {
    const { userIds, userId } = this.props;
    return (
      <div className={`modal-mutual-connection`}>
        <div className={`modal-mutual-connection-heading`}>
          {`${userIds.length} connection${userIds.length > 1 ? 's' : ''} in common with ${userFullName(
            this.props.users.userList[userId]
          )}`}
        </div>
        <div className={`modal-mutual-connection-list`}>
          {userIds.map((userId, i) => (
            <MutualConnectionRow key={`mutual-connection-${userId}`} userId={userId} />
          ))}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MutualConnectionsList);
