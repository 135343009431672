import React from 'react';

import TableRow from './TableRow';

const TableBody = (props) => {
  const columns = props.columns;

  const concernables = props.concernables;
  const concernableType = concernables.type;
  const concernableList = concernables.list;

  return (
    <tbody>
      {concernableList.map((concernable, i) => (
        <TableRow
          key={`security-table-security-row-${i}`}
          rowIndex={i}
          columns={columns}
          concernable={concernable}
          concernableType={concernableType}
          additionalTableCellProps={props.additionalTableCellProps}
        />
      ))}
      {props.footerRowColumns && (
        <TableRow
          key={'table-footer-row'}
          columns={props.footerRowColumns}
          skipLoadingCheck={true}
          concernable={null}
          concernableType={null}
          isFooterRow={true}
          additionalTableCellProps={props.additionalTableCellProps}
        />
      )}
      {props.customTrailingRows &&
        props.customTrailingRows.map((columns, i) => (
          <TableRow
            key={'table-footer-row'}
            customRowIndex={i}
            columns={columns}
            skipLoadingCheck={true}
            concernable={null}
            concernableType={null}
            isFooterRow={true}
            additionalTableCellProps={props.additionalTableCellProps}
          />
        ))}
    </tbody>
  );
};

export default TableBody;
