import React from 'react';
import { isFloatOrInteger } from '../../helpers/numberHelpers';

const withWindowSize = (WrappedComponent) =>
  class WindowSize extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        __sizing: null, // just used to force an update
      };
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          windowWidth={this._returnWindowMonitorSize().width}
          windowHeight={this._returnWindowMonitorSize().height}
        />
      );
    }

    componentDidMount() {
      this._addWindowResizeListener();
    }

    componentWillUnmount() {
      this._removeWindowResizeListener();
    }

    _addWindowResizeListener = () => {
      window.$(window).on('resize', this._updateComponent);
    };

    _removeWindowResizeListener = () => {
      const $window = window.$(window);
      $window.off('resize', this._updateComponent);
    };

    _updateComponent = () => {
      this.setState(() => ({
        __sizing: this._returnWindowMonitorSize(),
      }));
    };

    _returnWindowMonitorSize = () => {
      const monitorSize = this._getWindowMonitorSize();
      // assumes that if one works both should, can't think of a situation where this might !true
      const isValidSize = this._isValidSize(monitorSize.width);
      return isValidSize ? monitorSize : this._returnFallbackSize();
    };

    _isValidSize = (n) => isFloatOrInteger(n);

    _getWindowMonitorSize = () => {
      const $monitor = window.$('#window-monitor');
      if (!$monitor[0]) {
        return {
          height: window.$(window).height(),
          width: window.$(window).width(),
        };
      }

      return {
        height: $monitor.height(),
        width: $monitor.width(),
      };
    };

    _returnFallbackSize = () => {
      const $window = window.$(window);
      return {
        height: $window.height(),
        width: $window.width(),
      };
    };
  };

export default withWindowSize;
