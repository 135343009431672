import React from 'react';

import Opinion from './Opinion';

export const AgreeDisagreeContainer = (props) => {
  const { userOpinion } = props;
  const hasAgreed = userOpinion && userOpinion === 'agree';
  const hasDisAgreed = userOpinion && userOpinion === 'disagree';

  const shouldShowOpinions =
    !(props.counts.agree === 1 && props.counts.disagree === 0 && props.userOpinion === 'agree') || !props.inFeed;
  if (shouldShowOpinions) {
    const isDefaultStyle = props.styleType === 'default';
    if (isDefaultStyle) {
      return (
        <div className="agree-disagree-action-container">
          <Opinion
            opinion="agree"
            hasSelectedOpinion={hasAgreed || hasDisAgreed}
            userOpinion={props.userOpinion}
            handleClick={props.handleClick}
            styleType={props.styleType}
            inFeed={props.inFeed}
            inActivityPageFeed={props.inActivityPageFeed}
          />
          <Opinion
            opinion="disagree"
            userOpinion={props.userOpinion}
            handleClick={props.handleClick}
            hasSelectedOpinion={hasAgreed || hasDisAgreed}
            styleType={props.styleType}
            inFeed={props.inFeed}
            inActivityPageFeed={props.inActivityPageFeed}
          />
        </div>
      );
    }

    return (
      <div className="agree-disagree-action-container">
        <Opinion
          opinion="agree"
          hasSelectedOpinion={hasAgreed || hasDisAgreed}
          userOpinion={props.userOpinion}
          handleClick={props.handleClick}
          styleType={props.styleType}
          inFeed={props.inFeed}
          inActivityPageFeed={props.inActivityPageFeed}
        />
        <Opinion
          opinion="disagree"
          hasSelectedOpinion={hasAgreed || hasDisAgreed}
          userOpinion={props.userOpinion}
          handleClick={props.handleClick}
          styleType={props.styleType}
          inFeed={props.inFeed}
          inActivityPageFeed={props.inActivityPageFeed}
        />
      </div>
    );
  } else {
    return null;
  }
};
export default AgreeDisagreeContainer;
