import React from 'react';
import Button from '../../../components/buttons/Button';

const PlanExplanationCard = (props) => {
  const {
    contentHeight,
    additionalClassName,

    btnClassName,
    primaryColor,

    renderFeatures,

    headingText,
    feature,
    featurePrice,
    featurePaymentMethod,
    featurePaymentMethodExplanation,

    handleContinueClick,
  } = props;
  return (
    <div
      className={`plan-card ${additionalClassName || ''} center-all-list-items`}
      style={{
        flexWrap: 'wrap',
      }}
    >
      <div
        style={{
          height: contentHeight,
          width: '100%',
        }}
      >
        <div className={`plan-card-heading`}>
          <h2 style={{ color: primaryColor }}>{headingText}</h2>
        </div>
        <div className={`plan-features-container`}>
          {renderFeatures ? (
            renderFeatures()
          ) : (
            <React.Fragment>
              <p className="plan-feature">{feature}</p>
              <p className="plan-feature-explanation">{featurePrice}</p>
              {featurePaymentMethod && <p className="plan-feature-payment">{featurePaymentMethod}</p>}
              {featurePaymentMethodExplanation && (
                <p
                  className="plan-feature-payment-explanation"
                  style={{
                    marginTop: '1px',
                  }}
                >
                  {featurePaymentMethodExplanation}
                </p>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
      <div className={`continue-container`}>
        <Button customClass={btnClassName} text={'Continue'} handleClick={handleContinueClick} />
      </div>
    </div>
  );
};

export default PlanExplanationCard;
