import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  & > svg {
    width: 18px;
    height: 18px;
  }

  & g,
  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function DollarIcon() {
  return (
    <Wrapper>
      <svg viewBox="0 0 9 16" fill="none">
        <path
          d="M4.833 7.083c-1.891-.491-2.5-1-2.5-1.791 0-.909.842-1.542 2.25-1.542 1.484 0 2.034.708 2.084 1.75h1.841c-.058-1.433-.933-2.75-2.675-3.175V.5h-2.5v1.8C1.717 2.65.417 3.7.417 5.308c0 1.925 1.591 2.884 3.916 3.442 2.084.5 2.5 1.233 2.5 2.008 0 .575-.408 1.492-2.25 1.492-1.716 0-2.391-.767-2.483-1.75H.267c.1 1.825 1.466 2.85 3.066 3.192V15.5h2.5v-1.792c1.625-.308 2.917-1.25 2.917-2.958 0-2.367-2.025-3.175-3.917-3.667z"
          fill="#1F1F1F"
        />
      </svg>
    </Wrapper>
  );
}

export default DollarIcon;
