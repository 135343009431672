import React from 'react';
import { breakpoints, SCREEN_SIZES } from '../../lib/nvstr-utils.es';

const parseBreakpointToInt = (v) => parseFloat(v.split('px')[0]);

const getScreenSize = () => {
  const width = window.innerWidth;
  if (width <= parseBreakpointToInt(breakpoints.mobile)) return SCREEN_SIZES.mobile;
  if (width <= parseBreakpointToInt(breakpoints.desktop)) return SCREEN_SIZES.tablet;
  return SCREEN_SIZES.desktop;
};

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = React.useState(getScreenSize());

  const setScreenSizeState = React.useCallback(
    (newScreenSize) => {
      if (newScreenSize !== screenSize) {
        setScreenSize(newScreenSize);
      }
    },
    [screenSize]
  );

  React.useEffect(
    () => {
      const handleResize = () => {
        setScreenSizeState(getScreenSize());
      };

      window.$(window).on('resize', handleResize);
      return () => window.$(window).off('resize', handleResize);
    },
    [setScreenSizeState]
  );

  return screenSize;
};
