import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../../../actions/index';

import Dropdown from '../../../StandaloneInputs/DropdownWithStrings';
import Button from '../../../../components/buttons/Button';

import {
  moment,
  returnThisDay,
  returnThisYear,
  returnThisMonth,
  formatLocalizedDateTime,
  returnLastYear,
} from '../../../../helpers/timeHelpers';
import styled from 'styled-components';
import { FlatButton } from '@src/main/components/buttons';

const WrapperFormContainer = styled.div`
  flex-wrap: wrap;
  width: 100%;
`;

const Wrapper = styled.div`
  input {
    //border: none;
    line-height: 29px;

    //-webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }

  select {
    //border: none;

    //-webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
`;

class PortfolioMetrics extends React.Component {
  constructor() {
    super();
    this.state = {
      formData: {
        from_date: {
          day: '1',
          month: 'Jan',
          year: returnLastYear() || '2020',
        },
        to_date: {
          day: returnThisDay() || '31',
          month: returnThisMonth() || 'Jan',
          year: returnThisYear() || '2020',
        },
      },

      _isCalculating: false,
      _requestErrorMessage: null,
      _portfolioMetrics: null,
    };
  }

  componentDidMount() {
    // automatically request results with default date params
    this._handleSubmit();
  }

  render() {
    return (
      <div className={'history-portfolio-metrics-container'}>
        {this._renderHeading()}
        {this._renderForm()}
        {this._isCalculating() && this._renderCalculating()}
        {!this._isCalculating() && this._returnPortfolioMetrics() && this._renderPortfolioMetrics()}
        {!this._isCalculating() &&
          !this._returnPortfolioMetrics() &&
          this._returnRequestErrorMessage() &&
          this._renderErrorMessage()}
      </div>
    );
  }

  _renderHeading = () => {
    return <div className={'portfolio-metrics-heading-container'}>Realized Risk Metrics</div>;
  };

  _renderForm = () => {
    return (
      <Wrapper className={'portfolio-metrics-form-container'}>
        {this._renderFormInputs()}
        <div className={'portfolio-metrics-submit'}>
          <FlatButton handleClick={this._handleSubmit}>Calculate</FlatButton>
        </div>
      </Wrapper>
    );
  };

  _renderFormInputs = () => {
    return (
      <div className={'portfolio-metrics-form-inputs'}>
        <div className={'portfolio-metrics-dropdown-container'}>
          <WrapperFormContainer className={'date-range-form-container flex-center-child'}>
            <div className={'from-date-range-inputs-container'}>
              <div className={'date-range-container'}>
                <Dropdown
                  name={'from-month'}
                  value={this._returnFromMonthValue()}
                  options={this._returnMonthSelectionOptions()}
                  handleSelection={this._handleDropdownSelection}
                  arrowIconStyleTop={{ top: '15px', right: '5px' }}
                  arrowIconStyleBot={{ top: '15px', right: '5px' }}
                  showArrowIcons
                />
                <Dropdown
                  name={'from-day'}
                  value={this._returnFromDayValue()}
                  options={this._returnDaySelectionOptions()}
                  handleSelection={this._handleDropdownSelection}
                  arrowIconStyleTop={{ top: '15px', right: '5px' }}
                  arrowIconStyleBot={{ top: '15px', right: '5px' }}
                  showArrowIcons
                />
                <Dropdown
                  name={'from-year'}
                  value={this._returnFromYearValue()}
                  options={this._returnYearSelectionOptions()}
                  handleSelection={this._handleDropdownSelection}
                  arrowIconStyleTop={{ top: '15px', right: '5px' }}
                  arrowIconStyleBot={{ top: '15px', right: '5px' }}
                  showArrowIcons
                />
              </div>
            </div>

            <div className={'date-range-separator'}>-</div>

            <div className={'to-date-range-inputs-container'}>
              <div className={'date-range-container'}>
                <Dropdown
                  name={'to-month'}
                  value={this._returnToMonthValue()}
                  options={this._returnMonthSelectionOptions()}
                  handleSelection={this._handleDropdownSelection}
                  arrowIconStyleTop={{ top: '15px', right: '5px' }}
                  arrowIconStyleBot={{ top: '15px', right: '5px' }}
                  showArrowIcons
                />
                <Dropdown
                  name={'to-day'}
                  value={this._returnToDayValue()}
                  options={this._returnDaySelectionOptions()}
                  handleSelection={this._handleDropdownSelection}
                  arrowIconStyleTop={{ top: '15px', right: '5px' }}
                  arrowIconStyleBot={{ top: '15px', right: '5px' }}
                  showArrowIcons
                />
                <Dropdown
                  name={'to-year'}
                  value={this._returnToYearValue()}
                  options={this._returnYearSelectionOptions()}
                  handleSelection={this._handleDropdownSelection}
                  arrowIconStyleTop={{ top: '15px', right: '5px' }}
                  arrowIconStyleBot={{ top: '15px', right: '5px' }}
                  showArrowIcons
                />
              </div>
            </div>
          </WrapperFormContainer>
        </div>
      </div>
    );
  };

  _renderPortfolioMetricValue = (value, isPercent) => {
    let returnedValue;
    try {
      returnedValue = value && typeof value === 'number' ? `${value.toFixed(2)}${isPercent ? '%' : ''}` : 'N/A';
    } catch (e) {
      returnedValue = 'N/A';
    }
    return returnedValue;
  };

  _renderPortfolioMetrics = () => {
    return (
      <div className={'portfolio-metrics-results-container'}>
        <table>
          <tbody>
            <tr>
              <td className={'secondary-text-color'}>Portfolio Total Return</td>
              <td>{this._renderPortfolioMetricValue(this._returnPortfolioTotalReturn(), true)}</td>
            </tr>
            <tr>
              <td className={'secondary-text-color'}>Portfolio Annualized Average Return</td>
              <td>{this._renderPortfolioMetricValue(this._returnPortfolioAnnualizedAvgReturn(), true)}</td>
            </tr>
            <tr>
              <td className={'secondary-text-color'}>Sharpe Ratio</td>
              <td>{this._renderPortfolioMetricValue(this._returnSharpeRatio(), false)}</td>
            </tr>
            <tr>
              <td className={'secondary-text-color'}>Beta</td>
              <td>{this._renderPortfolioMetricValue(this._returnBeta(), false)}</td>
            </tr>
            <tr>
              <td className={'secondary-text-color'}>Alpha</td>
              <td>{this._renderPortfolioMetricValue(this._returnAlpha(), true)}</td>
            </tr>
            <tr>
              <td colSpan={2} style={{ height: '10px', width: '50px' }} />
            </tr>
            <tr>
              <td colSpan={2} style={{ textAlign: 'center' }}>
                Benchmark - S&P500 (SPY)
              </td>
            </tr>
            <tr>
              <td className={'secondary-text-color'}>Benchmark Total Return</td>
              <td>{this._renderPortfolioMetricValue(this._returnBenchmarkTotalReturn(), true)}</td>
            </tr>
            <tr>
              <td className={'secondary-text-color'}>Benchmark Annualized Average Return</td>
              <td>{this._renderPortfolioMetricValue(this._returnBenchmarkAnnualizedAvgReturn(), true)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  _renderErrorMessage = () => {
    return (
      <div className={'portfolio-metrics-results-container'}>
        <div style={{ fontSize: '12px', textAlign: 'center' }}>{this._returnRequestErrorMessage()}</div>
      </div>
    );
  };

  _renderCalculating = () => {
    return <div style={{ fontSize: '10px', textAlign: 'center' }}>Calculating...</div>;
  };

  _isCalculating = () => this.state._isCalculating;
  _setIsCalculating = () => {
    this.setState(() => ({
      _isCalculating: true,
    }));
  };
  _clearIsCalculating = () => {
    this.setState(() => ({
      _isCalculating: false,
    }));
  };

  _convertAPIErrorMessageToDisplayString = (error) => {
    const dict = {
      'No current user': 'Session timed out. Please login to continue',
      'Account has insufficient history':
        'We require at least 10 trading days of history to calculate realized risk metrics. If you just signed up recently, please check back soon.',
      'Invalid date parameter': 'Invalid date range. Please double check the start and end dates.',
    };
    if (error === 'No current user') {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    return dict[error] || 'Realized risk metrics are currently unavailable. Please try again later.';
  };
  _returnRequestErrorMessage = () => this.state._requestErrorMessage;
  _setRequestErrorMessage = (error) => {
    this.setState(() => ({
      _requestErrorMessage: this._convertAPIErrorMessageToDisplayString(error),
    }));
  };
  _clearRequestErrorMessage = () => {
    this.setState(() => ({
      _requestErrorMessage: null,
    }));
  };

  _setPortfolioMetrics = (portfolioMetrics) => {
    this.setState(() => ({
      _portfolioMetrics: portfolioMetrics,
    }));
  };
  _clearPortfolioMetrics = () => {
    this.setState(() => ({
      _portfolioMetrics: null,
    }));
  };

  _returnPortfolioMetrics = () => this.state._portfolioMetrics;
  _returnPortfolioTotalReturn = () => this.state._portfolioMetrics.portfolio.total_return;
  _returnPortfolioAnnualizedAvgReturn = () => this.state._portfolioMetrics.portfolio.annualized_average_return;
  _returnBenchmarkTotalReturn = () => this.state._portfolioMetrics.benchmark.total_return;
  _returnBenchmarkAnnualizedAvgReturn = () => this.state._portfolioMetrics.benchmark.annualized_average_return;
  _returnSharpeRatio = () => this.state._portfolioMetrics.sharpe_ratio;
  _returnBeta = () => this.state._portfolioMetrics.beta;
  _returnAlpha = () => this.state._portfolioMetrics.alpha;

  _returnYearSelectionOptions = () => {
    var yearStart = 2015;
    var currentYear = parseInt(formatLocalizedDateTime('YYYY', moment()), 10);
    var years = [];
    for (let i = yearStart; i <= currentYear; i++) {
      years.push(i.toString());
    }
    return years;
  };

  _returnDaySelectionOptions = () => {
    var days = [];
    for (let i = 1; i <= 31; i++) {
      days.push(i.toString());
    }
    return days;
  };

  _convertMonthStrToNum = (monthStr) => {
    const dict = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    };
    return dict[monthStr];
  };

  _returnMonthSelectionOptions = () => [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  _returnRequestFormData = () => ({
    start_date: `${this._convertMonthStrToNum(
      this._returnFromMonthValue()
    )}-${this._returnFromDayValue()}-${this._returnFromYearValue()}`,
    end_date: `${this._convertMonthStrToNum(
      this._returnToMonthValue()
    )}-${this._returnToDayValue()}-${this._returnToYearValue()}`,
  });

  _returnFormData = () => this.state.formData;
  _returnFromDateRangeData = () => this._returnFormData().from_date;
  _returnToDateRangeData = () => this._returnFormData().to_date;

  _returnFromDayValue = () => this._returnFromDateRangeData().day;
  _returnFromMonthValue = () => this._returnFromDateRangeData().month;
  _returnFromYearValue = () => this._returnFromDateRangeData().year;
  _returnToDayValue = () => this._returnToDateRangeData().day;
  _returnToMonthValue = () => this._returnToDateRangeData().month;
  _returnToYearValue = () => this._returnToDateRangeData().year;

  _handleDropdownSelection = (name, value) => {
    this._updateFormDataValue(name, value);
    this._clearRequestErrorMessage();
  };

  _updateFormDataValue = (name, value) => {
    const dateRangeKey = name.split('-')[0];
    const dateRangeDateType = name.split('-')[1];
    if (['to', 'from'].includes(dateRangeKey)) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          [`${dateRangeKey}_date`]: {
            ...prevState.formData[`${dateRangeKey}_date`],
            [dateRangeDateType]: value,
          },
        },
      }));
    }
  };

  _handleStartRequest = () => {
    this._clearPortfolioMetrics();
    this._clearRequestErrorMessage();
    this._setIsCalculating();
  };

  _handleSubmit = () => {
    this._handleStartRequest();
    this._logRequestPortfolioMetrics();
    return this._getPortfolioMetrics().then((responseData) => {
      this._clearIsCalculating();
      this._handleGetPortfolioMetricsResponse(responseData);
    });
  };

  _getPortfolioMetrics = () => {
    return this.props.actions.fetchHistoryPortfolioMetricsData(this._returnRequestFormData());
  };

  _handleGetPortfolioMetricsResponse = (responseData) => {
    if (responseData) {
      if (responseData.error) {
        const error = responseData.error;
        this._setRequestErrorMessage(error);
        this._logErrorGettingPortfolioMetrics(error);
      } else {
        const portfolioMetrics = responseData.portfolio_metrics;
        this._setPortfolioMetrics(portfolioMetrics);
      }
    } else {
      const error = 'Request failed';
      this._setRequestErrorMessage(error);
      this._logErrorGettingPortfolioMetrics(error);
    }
  };

  _logRequestPortfolioMetrics = () => {
    const event = 'Requested Portfolio Metrics';
    const properties = {
      'Start Date': `${this._returnFromMonthValue()}-${this._returnFromDayValue()}-${this._returnFromYearValue()}`,
      'End Date': `${this._returnToMonthValue()}-${this._returnFromDayValue()}-${this._returnToYearValue()}`,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logErrorGettingPortfolioMetrics = (error) => {
    const event = 'Received Error Requesting Portfolio Metrics';
    const properties = {
      'Error Message': error,
      'Start Date': `${this._returnFromMonthValue()}-${this._returnFromDayValue()}-${this._returnFromYearValue()}`,
      'End Date': `${this._returnToMonthValue()}-${this._returnFromDayValue()}-${this._returnToYearValue()}`,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PortfolioMetrics);
