import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  & > svg {
    width: 18px;
    height: 18px;
  }
  & g,
  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function RewardIcon() {
  return (
    <Wrapper>
      <svg viewBox="0 0 18 17" fill="none">
        <path
          d="M15.667 4H13.85c.092-.258.15-.542.15-.833a2.497 2.497 0 00-4.583-1.375L9 2.35l-.416-.567A2.51 2.51 0 006.5.667a2.497 2.497 0 00-2.5 2.5c0 .291.059.575.15.833H2.334C1.409 4 .675 4.742.675 5.667l-.008 9.166A1.66 1.66 0 002.334 16.5h13.333c.925 0 1.667-.742 1.667-1.667V5.667A1.66 1.66 0 0015.667 4zM11.5 2.333c.459 0 .834.375.834.834A.836.836 0 0111.5 4a.836.836 0 01-.833-.833c0-.459.375-.834.833-.834zm-5 0c.459 0 .834.375.834.834A.836.836 0 016.5 4a.836.836 0 01-.833-.833c0-.459.375-.834.833-.834zm9.167 12.5H2.334v-1.666h13.333v1.666zm0-4.166v2.5H2.334v-7.5h4.233L4.834 8.025 6.184 9l1.983-2.7L9 5.167 9.834 6.3 11.817 9l1.35-.975-1.733-2.358h4.233v5z"
          fill="#1F1F1F"
        />
      </svg>
    </Wrapper>
  );
}

export default RewardIcon;
