export const normalizeAskDataForAPI = (askData) => {
  if (askData.askedCTL) {
    return {
      asked_community_thought_leader: true,
      security_id: askData.securityId,
      idea_id: askData.ideaId,
      thought_id: askData.thoughtId,
      question: askData.question,
    };
  } else {
    return {
      asked_user_id: askData.userId,
      security_id: askData.securityId,
      idea_id: askData.ideaId,
      thought_id: askData.thoughtId,
      question: askData.question,
    };
  }
};
