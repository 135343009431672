import React from 'react';
import { connect, useSelector } from 'react-redux';

import ProgressBar from '../../../components/UI/ProgressBar';
import Icon from '../../../components/misc/Icon';
import styled from 'styled-components';

const IconWrapper = styled.div`
  color: ${({ theme, success }) => (success ? theme.themeColors.positive : theme.themeColors.border)};
`;

const ProgressRow = (props) => {
  const {
    success,

    text,
    hide,
  } = props;

  if (hide) return null;

  const icon = 'fa-check';

  return (
    <>
      <span
        style={{
          display: 'inline-block',
          margin: '0 0 2px 0',
        }}
      >
        <IconWrapper
          success={success}
          style={{
            display: 'inline-block',
            textAlign: 'center',
            width: '18px',
          }}
        >
          <Icon icon={icon} size="medium" />
        </IconWrapper>
        <span
          className="  bold-text"
          style={{
            display: 'inline-block',
            padding: '0px 0px 1px 4px',
            verticalAlign: 'bottom',
          }}
        >
          {text}
        </span>
      </span>
      <br />
    </>
  );
};

const RewardsProgress = ({ hideHeading }) => {
  const store = useSelector((state) => state.rewards);
  const {
    completed_referral_count,
    referral_count,

    requiring_ach_link_referral_count,
    requiring_live_approval_referral_count,
    requiring_trade_referral_count,
    requiring_deposit_referral_count,
  } = store;

  const hasReferrals = referral_count > 0;

  if (!hasReferrals) {
    return (
      <div className={'rewards-progress-container text-center'}>
        <span className="secondary-text-color">So far no friends have begun signing up.</span>
      </div>
    );
  }

  return (
    <div className={'rewards-progress-container'}>
      <div className={'referrals-progress-message'}>
        {!hideHeading && (
          <>
            <span
              className="  bold-text"
              style={{
                display: 'inline-block',
                margin: '0 0 2px 0',
              }}
            >
              {'Your rewards progress'}
            </span>
            <br />
          </>
        )}

        <ProgressRow text={`${referral_count} ${referral_count === 1 ? 'friend' : 'friends'} signed up`} success />
        <ProgressRow
          text={`${requiring_live_approval_referral_count} ${
            requiring_live_approval_referral_count === 1 ? 'needs' : 'need'
          } a live trading account`}
          hide={requiring_live_approval_referral_count === 0}
        />
        <ProgressRow
          text={`${requiring_ach_link_referral_count} ${
            requiring_ach_link_referral_count === 1 ? 'needs' : 'need'
          } to link a bank account`}
          hide={requiring_ach_link_referral_count === 0}
        />
        <ProgressRow
          text={`${requiring_trade_referral_count} ${
            requiring_trade_referral_count === 1 ? 'needs' : 'need'
          } to place a trade`}
          hide={requiring_trade_referral_count === 0}
        />
        <ProgressRow
          text={`${requiring_deposit_referral_count} ${
            requiring_deposit_referral_count === 1 ? 'needs' : 'need'
          } to complete a deposit`}
          hide={requiring_deposit_referral_count === 0}
        />
        <ProgressRow
          text={`${completed_referral_count} ${completed_referral_count === 1 ? 'has' : 'have'} completed everything`}
          success={referral_count > 0 && referral_count === completed_referral_count}
        />
      </div>

      <div className="">
        <div className="rewards-progress-requirement-message secondary-text-color text-center">
          {completed_referral_count === referral_count
            ? 'Invite more friends to earn more rewards!'
            : 'For you to earn your rewards, friends need to do the above'}
        </div>

        <div className="rewards-progress-progress-bar-container">
          <ProgressBar
            completedCount={completed_referral_count}
            totalCount={referral_count}
            LabelComponent={
              <span
                className={'success-text-color bold-text'}
                style={{
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '125px',
                  textAlign: 'left',
                }}
              >
                You&apos;ve earned {completed_referral_count.toString()}&nbsp;
                {completed_referral_count === 1 ? 'reward' : 'rewards'} so far
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default RewardsProgress;
