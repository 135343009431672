import React from 'react';
import styled from 'styled-components';
import { colorPalette } from '@src/main/lib/nvstr-utils.es';

const FlipSwitchWrapper = styled.div`
  .flip-switch-container input[type='checkbox'].flip-switch:checked + div {
    background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
  }

  .switch-knob {
    left: ${({ checked }) => (checked ? 'auto' : '-4px')};
    right: ${({ checked }) => (!checked ? 'auto' : '-4px')};
  }

  .switch-track {
    position: relative;
    background-color: ${({ theme }) => theme.themeColors.border};
  }

  .switch-track .switch-knob {
    background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    position: absolute;
    width: 26px !important;
    height: 26px !important;
    top: -2px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .switch-track.active .switch-knob {
    width: 26px !important;
    height: 26px !important;
    top: -2px;
    left: auto;
    right: -4px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
`;

export const ToggleSwitch = (props) => {
  const { label, name, value, isDisabled, handleClick } = props;

  const doNothingFunc = () => {
    // click handler is on a diff element to increase the click area
    return false;
  };

  return (
    <FlipSwitchWrapper checked={value}>
      <div className={`flip-switch-container ${isDisabled ? 'disabled' : ''}`}>
        <label className="flip-switch-label-container" name={name}>
          {label}
          <input type="checkbox" name={name} className="flip-switch" checked={value} onChange={doNothingFunc} />
          <div className="switch-track" onClick={handleClick}>
            <div className="switch-knob" />
          </div>
        </label>
      </div>
    </FlipSwitchWrapper>
  );
};
export default ToggleSwitch;
