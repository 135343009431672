import React from 'react';
import { connect } from 'react-redux';

import { createTimeInstance, formatLocalizedDateTime } from '../../helpers/timeHelpers';
import {
  getAvailablePromotionsFromState,
  getLimitedTimeDepositBonusRewardFromAvailablePromotions,
} from '../../selectors/currentUser';
import { logErrorEvent } from '../../actions';

class LimitedTimeDepositBonusBanner extends React.PureComponent {
  render() {
    return (
      <div>
        <span className={' '}>{this._generateBannerText()}</span>
      </div>
    );
  }

  _generateBannerText = () => {
    // expires_at
    // reward_type: reward_type,
    // duration: duration,
    // duration_phrase: duration_phrase,
    // deposit_minimum_amount: deposit_minimum_amount,
    // reward_amount: reward_amount,

    const { limitedTimeDepositBonusAward } = this.props;
    if (!limitedTimeDepositBonusAward) return null;

    const { expires_at, deposit_minimum_amount, reward_amount, is_card_flip } = limitedTimeDepositBonusAward;
    const rewardAmount = reward_amount;
    const minDepositRequiredAmount = deposit_minimum_amount;
    const expiry = createTimeInstance(expires_at);
    const expiryDisplay = formatLocalizedDateTime('day-date', expiry);

    const isCashReward = reward_amount > 0;
    const isRandomReward = is_card_flip;
    if (isCashReward) {
      return `Get an additional $${rewardAmount} after depositing $${minDepositRequiredAmount} or more until ${expiryDisplay}`;
    } else if (isRandomReward) {
      return `For a limited time, get a funding bonus (Up to $1,000) after depositing $${minDepositRequiredAmount} or more until ${expiryDisplay}`;
    } else {
      return '';
    }
  };
}

const mapStateToProps = (state) => {
  const availablePromotions = getAvailablePromotionsFromState(state);
  return {
    limitedTimeDepositBonusAward: getLimitedTimeDepositBonusRewardFromAvailablePromotions(availablePromotions),
  };
};

export default connect(mapStateToProps)(LimitedTimeDepositBonusBanner);
