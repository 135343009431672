export const ELEMENT_COLOR_NAMES = {
  nvstrRewardCardFront: 'nvstrRewardCardFront',
  nvstrRewardCardBack: 'nvstrRewardCardBack',
  nvstrReferralRewardProbability: 'nvstrReferralRewardProbability',
  nvstrRewardValueColor: 'nvstrRewardValueColor',
  nvstrRedeemableRewardCard: 'nvstrRedeemableRewardCard',
  nvstrRedeemableRewardCardStripe: 'nvstrRedeemableRewardCardStripe',
  rewardsIconColor: 'rewardsIconColor',

  statusBarColor: 'statusBarColor',
  statusBarContent: 'statusBarContent',

  eyeCatchingActionButton: 'eyeCatchingActionButton',

  bgColor: 'bgColor',
  bgAccentColor: 'bgAccentColor',
  bgBorderShadow: 'bgBorderShadow',
  darkThemeBgColor: 'darkThemeBgColor',
  componentBg: 'componentBg',
  componentBgFullOpacity: 'componentBgFullOpacity',
  componentBgHighlight: 'componentBgHighlight',
  launchBg: 'launchBg',
  disabledBg: 'disabledBg',
  overlayBg: 'overlayBg',

  eyeCatchingBgTextColor: 'eyeCatchingBgTextColor',
  eyeCatchingBg: 'eyeCatchingBg',
  eyeCatchingBgShadow: 'eyeCatchingBgShadow',

  primaryTextColor: 'primaryTextColor',
  dropdownItemColor: 'dropdownItemColor',
  lightThemePrimaryTextColor: 'lightThemePrimaryTextColor',
  darkThemePrimaryTextColor: 'darkThemePrimaryTextColor',

  secondaryTextColor: 'secondaryTextColor',
  inactiveTextColor: 'inactiveTextColor',
  headingTextColor: 'headingTextColor',
  thoughtBannerTextColor: 'thoughtBannerTextColor',
  coloredBgTextColor: 'coloredBgTextColor',
  eyeCatchingTextColor: 'eyeCatchingTextColor',
  labelTextColor: 'labelTextColor',
  inputTextColor: 'inputTextColor',
  inputActiveTextColor: 'inputActiveTextColor',
  errorTextColor: 'errorTextColor',
  warningTextColor: 'warningTextColor',
  placeholderTextColor: 'placeholderTextColor',
  primaryDropdownTextColor: 'primaryDropdownTextColor',
  communityCountAvatarBgColor: 'communityCountAvatarBgColor',

  borderAccent: 'borderAccent',
  tableBorder: 'tableBorder',
  darkerBorderAccent: 'darkerBorderAccent',
  inputBorderAccent: 'inputBorderAccent',

  infoIcon: 'infoIcon',
  counterBgColor: 'counterBgColor',
  counterTextColor: 'counterTextColor',

  communityAvatarBg: 'communityAvatarBg',
  defaultAvatarBg: 'defaultAvatarBg',
  defaultAvatarText: 'defaultAvatarText',

  headerNavBg: 'headerNavBg',
  navBarBg: 'navBarBg',
  navBarBgShadow: 'navBarBgShadow',
  navBarBottomBorder: 'navBarBottomBorder',

  headerNavItemColor: 'headerNavItemColor',
  activeFooterNavItemColor: 'activeFooterNavItemColor',
  inactiveFooterNavItemColor: 'inactiveFooterNavItemColor',

  cardShadow: 'cardShadow',

  increaseColor: 'increaseColor',
  decreaseColor: 'decreaseColor',
  neutralColor: 'neutralColor',

  buyColor: 'buyColor',
  sellColor: 'sellColor',

  ctlBannerBg: 'ctlBannerBg',

  thoughtBubbleColor: 'thoughtBubbleColor',
  thoughtEmbeddedLinkBgColor: 'thoughtEmbeddedLinkBgColor',
  thoughtBannerIconColor: 'thoughtBannerIconColor',

  proColor: 'proColor',
  conColor: 'conColor',
  agreeColor: 'agreeColor',
  disagreeColor: 'disagreeColor',
  increaseColorDark: 'increaseColorDark',
  decreaseColorDark: 'decreaseColorDark',
  moneyColor: 'moneyColor',
  successColor: 'successColor',
  successHighlight: 'successHighlight',
  successContrast: 'successContrast',
  failureColor: 'failureColor',
  fireColor: 'fireColor',

  primaryBtnColor: 'primaryBtnColor',
  primaryBtnTextColor: 'primaryBtnTextColor',
  secondaryBtnColor: 'secondaryBtnColor',
  secondaryBtnTextColor: 'secondaryBtnTextColor',
  primaryBtnColorAlt: 'primaryBtnColorAlt',

  disabledBtnTextColor: 'disabledBtnTextColor',
  disabledBtnColor: 'disabledBtnColor',

  feedFilterTagBg: 'feedFilterTagBg',
  feedFilterTagText: 'feedFilterTagText',

  linkColor: 'linkColor',
  linkColorAlt: 'linkColorAlt',
  linkColorLowContrast: 'linkColorLowContrast',

  searchIconColor: 'searchIconColor',

  chartAxisColor: 'chartAxisColor',

  loadingIcon: 'loadingIcon',
  refreshIndicatorIOS: 'refreshIndicatorIOS',

  blueGreenGradient: 'blueGreenGradient',
  haloGradient: 'haloGradient',

  modalTrayBg: 'modalTrayBg',
  modalTrayActionRowBg: 'modalTrayActionRowBg',

  warning: 'warning',

  inputBg: 'inputBg',
  inputIconColor: 'inputIconColor',

  frameColor: 'frameColor',
  topFrameColor: 'topFrameColor',
  bottomFrameColor: 'bottomFrameColor',

  darkThemeTopFrameColor: 'darkThemeTopFrameColor',
  darkThemeBottomFrameColor: 'darkThemeBottomFrameColor',

  toggleSwitchTrackToggledOn: 'toggleSwitchTrackToggledOn',
  toggleSwitchTrackToggledOff: 'toggleSwitchTrackToggledOff',

  depositPickerButtonBg: 'depositPickerButtonBg',
  depositPickerButtonText: 'depositPickerButtonText',
  depositPickerSelectedButtonBg: 'depositPickerSelectedButtonBg',
  depositPickerSelectedButtonTextColor: 'depositPickerSelectedButtonTextColor',

  white: 'white',
  transparent: 'transparent',
  forceCharcoal: 'forceCharcoal',
};
