import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const bannerAdapter = {
  fetchBanner: () => {
    const options = { ignoreAuth: true };
    const config = {};

    return sendRequest('get', [`${URL}/banner`, {}, config], options).then((response) => response);
  },
  dismissBanner: () => {
    const options = { ignoreAuth: true };
    const config = {};

    return sendRequest('post', [`${BASEURL}/live_trading_banner`, {}, config], options).then((response) => response);
  },
};
