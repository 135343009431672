import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../../actions/index';

import ReusableSearchContainer from './components/ReusableSearchContainer';

import LoadingIcon from '../../../components/misc/LoadingIcon';

import { returnCurrentUserId } from '../../../helpers/currentUserHelpers';
import { limitArraySize } from '../../../helpers/generalHelpers';
import { isUserPrivate } from '../../../helpers/usersHelpers';
import { isConnectionsDataLoadingForUser } from '../../../helpers/userConnectionsHelpers';

class ConnectionsSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _queryResults: null,
    };
  }

  render() {
    return (
      <div className={`connections-search-container`}>
        {this._isInitializing() ? (
          this._renderLoading()
        ) : (
          <ReusableSearchContainer
            placeholder={this.props.placeholder || 'Enter a name'}
            viewingContext={'Ask Why To Search'}
            queryResults={this.returnQueryResults()}
            handleResultSelection={this.props.handleResultSelection}
            onAfterQueryChange={this.onAfterQueryChange}
            customKeyStrokeHandlers={this.props.customKeyStrokeHandlers}
            focusOnResultsMount
            autoFocus={this.props.autoFocus}
          />
        )}
      </div>
    );
  }

  _renderLoading = () => {
    return (
      <div className={`loading-connections`}>
        <LoadingIcon icon={null} size="small" style={{ marginRight: '8px' }} />
        <div className={`text-container`}>Loading Connections</div>
      </div>
    );
  };

  _isInitializing = () => !this._isCurrentUserConnectionsDataLoaded();

  _isCurrentUserConnectionsDataLoaded = () => !this._isLoadingConnections();

  _isLoadingConnections = () =>
    isConnectionsDataLoadingForUser(this._returnCurrentUserId(), this._returnUserConnectionsStore());

  _returnCurrentUser = () => this.props.currentUser;

  _returnCurrentUserId = () => this._returnCurrentUser().user_id;

  _returnUserConnectionsStore = () => this.props.userConnections;

  returnQueryResults = () => this.state._queryResults;

  onAfterQueryChange = (newQueryValue) => {
    if (newQueryValue === '') {
      this._clearQueryResults();
    } else {
      this._getAndSetQueryResults(newQueryValue);
    }

    if (this.props.onAfterQueryChange) {
      this.props.onAfterQueryChange(newQueryValue);
    }
  };

  _getAndSetQueryResults = (newQueryValue) => {
    const queryResults = this._getQueryResults(newQueryValue);
    const formattedQueryResults = this._formatQueryResultsForUsers(queryResults);
    const filterUsingCustomFilterFunc = this.props.customExcludeResultsFunc
      ? formattedQueryResults.filter((result) => this.props.customExcludeResultsFunc(result))
      : formattedQueryResults;
    this._setQueryResults(filterUsingCustomFilterFunc);
  };

  _getQueryResults = (queryValue) => this._returnUsersMatchingQuery(queryValue);

  _formatQueryResultsForUsers = (queryResults) => queryResults.map((userData) => ({ type: 'user', data: userData }));

  _setQueryResults = (value) => {
    this.setState(() => ({
      _queryResults: value,
    }));
  };

  _clearQueryResults = () => {
    this.setState(() => ({
      _queryResults: null,
    }));
  };

  handleResultSelection = (selectionObj) => {
    this.props.handleResultSelection(selectionObj);
  };

  _returnResultsMaxSize = () => 10;

  _returnUsersMatchingQuery = (queryValue) => {
    const results = this._returnCurrentUserNonPrivateConnectionsListByUserObject().filter((user) => {
      const firstName = typeof user.first_name === 'string' ? user.first_name.toLowerCase() : '';
      const lastName = typeof user.last_name === 'string' ? user.last_name.toLowerCase() : '';
      const name = `${firstName} ${lastName}`;
      return name.indexOf(queryValue.toLowerCase()) >= 0;
    });
    return limitArraySize(results, this._returnResultsMaxSize());
  };

  _returnCurrentUserId = () => returnCurrentUserId(this._returnCurrentUser());

  _returnCurrentUser = () => this.props.currentUser;

  _returnUserFromUserId = (userId) => this._returnUsersLookup()[userId];

  _returnUsersLookup = () => this._returnUsersStore().userList;

  _returnUsersStore = () => this.props.users;

  _returnCurrentUseronnectionsListByUserId = () => this._returnConnectionsLookup()[this._returnCurrentUserId()];

  _returnCurrentUserNonPrivateConnectionsListByUserObject = () =>
    this._returnCurrentUserConnectionsListByUserObject().filter((user) => !isUserPrivate(user));

  _returnCurrentUserConnectionsListByUserObject = () =>
    this._returnCurrentUserConnectionsListByUserId().map((userId) => this._returnUserFromUserId(userId));

  _returnCurrentUserConnectionsListByUserId = () =>
    this._returnConnectionsLookup()[this._returnCurrentUserId()].connections;

  _returnConnectionsLookup = () => this._returnConnectionsStore().userDict;

  _returnConnectionsStore = () => this.props.userConnections;
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    users: state.users,
    userConnections: state.userConnections,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConnectionsSearch);
