import React from 'react';

export const CommunityPositionLabel = (props) => {
  const position = props.position === 'long' ? 'abs-position-right' : 'abs-position-left';
  const show = props.community_position === 0 ? 'hidden' : '';

  const positionSize =
    Math.round(props.label) >= 20
      ? '>20'
      : props.label < 1.0 && props.label > 0.0
      ? props.label.toFixed(1) < 0.1
        ? '<0.1'
        : props.label.toFixed(1)
      : Math.round(props.label);

  return (
    <div className={`${show}`}>
      <span className={`community-position-label ${position} secondary-text-color`}>{`${positionSize}%`}</span>
    </div>
  );
};

export default CommunityPositionLabel;
