import { throwError } from './devToolHelpers';
import { formatLocaleString } from './generalHelpers';
import { replaceAll } from '@src/main/lib/nvstr-utils.es';

// https://stackoverflow.com/questions/3885817/how-do-i-check-that-a-number-is-float-or-integer/20779354#20779354
export const isInt = (n) => {
  return Number.isInteger(n);
};

// https://stackoverflow.com/questions/3885817/how-do-i-check-that-a-number-is-float-or-integer/20779354#20779354
export const isFloat = (n) => {
  return n === +n && n !== (n | 0);
};

export const extraDecimalPlaceForSmallNum = (number) => {
  return number < 2 ? 2 : 1;
};

export const truncateNumber = (number, decimalPlaces) => {
  if (typeof number === 'string') {
    number = customParseFloat(number);
  }
  if (!(isInt(number) || isFloat(number))) {
    return number;
  }
  if (decimalPlaces === null) {
    return number;
  }

  return typeof decimalPlaces === 'number' ? number.toFixed(decimalPlaces) : number.toFixed(decimalPlaces(number));
};

export var convertPriceToNumberWithCommas = (price, useAbsoluteValue, roundToNearestInt) => {
  if (price === undefined || price === null) {
    return null;
  }
  // do not pass in a decimal longer than hundredths, not currently rounding .999 to 1.0

  const value = useAbsoluteValue
    ? Math.abs(customParseFloat(typeof price === 'string' ? price.replace('$', '') : price))
    : customParseFloat(typeof price === 'string' ? price.replace('$', '') : price);
  return formatLocaleString(value, {
    minimumFractionDigits: roundToNearestInt ? 0 : 2,
    maximumFractionDigits: roundToNearestInt ? 0 : 2,
  });
};

export const returnChangeSymbolPrefix = (value) => {
  return value === 0 ? '' : value >= 0 ? '+' : '-';
};

export const returnChangeAdverbalPrefix = (value) => {
  return value === 0 ? 'No Change For' : value >= 0 ? 'Higher' : 'Lower';
};

export const returnChangeVerbalPrefix = (value) => {
  return value === 0 ? 'No Change For' : value >= 0 ? 'More' : 'Less';
};

export const finance_notation = (num, noDecimal) => {
  num = typeof num === 'string' ? customParseFloat(num) : num;
  const fin_noted_num =
    Math.abs(Number(num)) >= 1.0e12
      ? Math.abs(Number(num) / 1.0e12).toFixed(noDecimal ? 0 : 1) + 'T'
      : Math.abs(Number(num)) >= 1.0e9
      ? Math.abs(Number(num) / 1.0e9).toFixed(noDecimal ? 0 : 1) + 'B'
      : Math.abs(Number(num)) >= 1.0e6
      ? Math.abs(Number(num) / 1.0e6).toFixed(noDecimal ? 0 : 1) + 'M'
      : Math.abs(Number(num)) >= 1.0e3
      ? Math.abs(Number(num) / 1.0e3).toFixed(noDecimal ? 0 : 1) + 'K'
      : isInt(Math.abs(Number(num)))
      ? Math.abs(Number(num))
      : Math.abs(Number(num)).toFixed(2);

  return num > 0 ? fin_noted_num : `(${fin_noted_num})`;
};

export const getRandomIntegerInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const customParseFloat = (value) => {
  const normalizedValue = typeof value === 'string' ? replaceAll(value, ',', '') : value;
  return parseFloat(normalizedValue);
};

export const isFloatOrInteger = (n) => {
  // https://stackoverflow.com/questions/3885817/how-do-i-check-that-a-number-is-float-or-integer
  return Number(n) === n || n % 1 !== 0;
};

export const roundToDecimalPlace = (float, decimalPlaceInt = 0) => {
  if (!isInt(decimalPlaceInt)) {
    return throwError(`decimalPlaceInt must be an int received: ${typeof decimalPlaceInt}`);
  }
  return Number(Math.round(float + 'e' + decimalPlaceInt) + 'e-' + decimalPlaceInt);
};

export const countDecimalPlaces = (float) => {
  const floatStr = float.toString();
  const decimalStr = floatStr.split('.')[1]; // ?
  return decimalStr ? decimalStr.length : 0;
};

export const isInteger = (n) => {
  return Number.isInteger(n);
};
