import { ORDER_TRANSACTION_TYPES, ORDER_TYPES } from '@src/main/constants/orders';
import { toTitleCase } from '@src/helpers/generalHelpers';

export const displayOrderType = (type, titlecase) => {
  if (ORDER_TYPES.stopLimit === type) {
    return titlecase ? 'Stop Limit' : 'stop limit';
  }
  return titlecase ? toTitleCase(type) : type;
};

export const approximateValueOfOrder = (order, currentStockPrice) => {
  if (!currentStockPrice) return null;
  const { shares, operation, type, limit_price, stop_price } = order;
  const orderOperationValue = operation === ORDER_TRANSACTION_TYPES.buy ? 1 : -1;

  let price = currentStockPrice;
  if (type === ORDER_TYPES.limit) {
    price = limit_price;
  }
  if (type === ORDER_TYPES.stop) {
    price = stop_price;
  }
  if (type === ORDER_TYPES.stopLimit) {
    price = limit_price;
  }

  return shares * price * orderOperationValue;
};
