import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const onboardingAdapter = {
  endOnboarding: (currentUserId) => {
    return sendRequest('put', [`${URL}/users/${currentUserId}/end_onboarding`]);
  },
};
