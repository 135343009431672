import React from 'react';

import Icon from '../../components/misc/Icon';

const IconWithString = (props) => {
  return (
    <div className={`icon-with-string-container`}>
      <Icon {...props.icon} />
      <div className={`text-container ${props.textClassName || ''}`}>{props.string}</div>
    </div>
  );
};

export default IconWithString;
