import { SelectAccountType } from '../pages/onboarding/SelectAccountType';
import { EnterInvestingExperience } from '../pages/onboarding/EnterInvestingExperience';
import { EnterInvestorProfile } from '../pages/onboarding/EnterInvestorProfile';
import { EnterContactName } from '../pages/onboarding/EnterContactName';
import { EnterContactAddress } from '../pages/onboarding/EnterContactAddress';
import { EnterContactPhoneNumber } from '../pages/onboarding/EnterContactPhoneNumber';
import { EnterIdentity } from '../pages/onboarding/EnterIdentity';
import { EnterDateOfBirth } from '../pages/onboarding/EnterDateOfBirth';
import { EnterSSN } from '../pages/onboarding/EnterSSN';
import { EnterCitizenshipStatus } from '../pages/onboarding/EnterCitizenshipStatus';
import { EnterDisclosures } from '../pages/onboarding/EnterDisclosures';
import { AcceptAgreements } from '../pages/onboarding/AcceptAgreements';
import { SubmittedLiveAccountApplication } from '../pages/onboarding/SubmittedLiveAccountApplication';
import { RedeemRewards } from '../pages/onboarding/RedeemRewards';
import { LinkBankAccount } from '../pages/onboarding/LinkBankAccount';
import { SelectAccountPrivacy } from '../pages/onboarding/SelectAccountPrivacy';
import { EnterDefaultExpectedReturn } from '../pages/onboarding/EnterDefaultExpectedReturn';
import { UpdateUnverifiedInfo } from '../pages/onboarding/UpdateUnverifiedInfo';
import { SelectVPC } from '../pages/onboarding/SelectVPC';
import AlmostThere from '../pages/onboarding/AlmostThere';
import { EnterDeposit } from '../pages/onboarding/EnterDeposit';
import { SelectInterests } from '../pages/onboarding/SelectInterests';
import { EditWatchlist } from '../pages/onboarding/EditWatchlist';
import { EndOnboarding } from '../pages/onboarding/EndOnboarding';
import { EnterDisclosuresEmployment } from '../pages/onboarding/EnterDisclosuresEmployment';

export const ONBOARDING_STATES = {
  initialization: 'initialization',
  accountTypeSelection: 'accountTypeSelection',
  selectInvestingExperience: 'selectInvestingExperience',

  enterInvestorProfile: 'enterInvestorProfile',
  enterContactName: 'enterContactName',
  enterContactAddress: 'enterContactAddress',
  enterContactPhoneNumber: 'enterContactPhoneNumber',
  enterIdentity: 'enterIdentity',
  enterDOB: 'enterDOB',
  enterSSN: 'enterSSN',
  enterCitizenshipCountry: 'enterCitizenshipCountry',
  enterDisclosures: 'enterDisclosures',
  enterEmploymentDisclosures: 'enterEmploymentDisclosures',
  enterAgreements: 'enterAgreements',
  submittedLiveAccountApplication: 'submittedLiveAccountApplication',

  redeemRewards: 'redeemRewards',
  redeemRewardsAfterDeposit: 'redeemRewardsAfterDeposit',
  linkBankAccount: 'linkBankAccount',
  depositFunds: 'depositFunds',
  almostThere: 'almostThere',

  socureApplication: 'socureApplication',

  selectAccountPrivacy: 'selectAccountPrivacy',
  selectVPC: 'selectVPC',
  enterDefaultExpectedReturn: 'enterDefaultExpectedReturn',
  selectInterests: 'selectInterests',
  editWatchlist: 'editWatchlist',

  end: 'end',
};

export const onboardingStateToComponentLookup = {
  [ONBOARDING_STATES.accountTypeSelection]: SelectAccountType,
  [ONBOARDING_STATES.selectInvestingExperience]: EnterInvestingExperience,
  // [ONBOARDING_STATES.enterInvestorProfile]: EnterInvestorProfile,
  [ONBOARDING_STATES.enterContactName]: EnterContactName,
  [ONBOARDING_STATES.enterContactAddress]: EnterContactAddress,
  [ONBOARDING_STATES.enterContactPhoneNumber]: EnterContactPhoneNumber,
  [ONBOARDING_STATES.enterIdentity]: EnterIdentity,
  [ONBOARDING_STATES.enterDOB]: EnterDateOfBirth,
  [ONBOARDING_STATES.enterSSN]: EnterSSN,

  [ONBOARDING_STATES.enterCitizenshipCountry]: EnterCitizenshipStatus,
  [ONBOARDING_STATES.enterDisclosures]: EnterDisclosures,
  [ONBOARDING_STATES.enterEmploymentDisclosures]: EnterDisclosuresEmployment,
  [ONBOARDING_STATES.enterAgreements]: AcceptAgreements,
  [ONBOARDING_STATES.submittedLiveAccountApplication]: SubmittedLiveAccountApplication,

  [ONBOARDING_STATES.socureApplication]: UpdateUnverifiedInfo,
  [ONBOARDING_STATES.redeemRewards]: RedeemRewards,
  [ONBOARDING_STATES.redeemRewardsAfterDeposit]: RedeemRewards,
  [ONBOARDING_STATES.linkBankAccount]: LinkBankAccount,

  [ONBOARDING_STATES.depositFunds]: EnterDeposit,
  [ONBOARDING_STATES.almostThere]: AlmostThere,

  [ONBOARDING_STATES.selectInterests]: SelectInterests,
  [ONBOARDING_STATES.editWatchlist]: EditWatchlist,

  [ONBOARDING_STATES.selectAccountPrivacy]: SelectAccountPrivacy,
  [ONBOARDING_STATES.selectVPC]: SelectVPC,
  [ONBOARDING_STATES.enterDefaultExpectedReturn]: EnterDefaultExpectedReturn,

  [ONBOARDING_STATES.end]: EndOnboarding,
};

const accountSelectionPath = [ONBOARDING_STATES.accountTypeSelection];
const liveAccountApplicationPath = [
  ONBOARDING_STATES.accountTypeSelection,
  ONBOARDING_STATES.selectInvestingExperience,

  // ONBOARDING_STATES.enterInvestorProfile,
  ONBOARDING_STATES.enterContactName,
  ONBOARDING_STATES.enterContactAddress,
  ONBOARDING_STATES.enterContactPhoneNumber,
  ONBOARDING_STATES.enterIdentity,
  ONBOARDING_STATES.enterDOB,
  ONBOARDING_STATES.enterSSN,
  ONBOARDING_STATES.enterCitizenshipCountry,
  ONBOARDING_STATES.enterDisclosures,
  ONBOARDING_STATES.enterEmploymentDisclosures,
  ONBOARDING_STATES.enterAgreements,
  ONBOARDING_STATES.submittedLiveAccountApplication,
];
const onboardedLiveAccountApplication = [
  ONBOARDING_STATES.selectInvestingExperience,
  // ONBOARDING_STATES.enterInvestorProfile,
  ONBOARDING_STATES.enterContactName,
  ONBOARDING_STATES.enterContactAddress,
  ONBOARDING_STATES.enterContactPhoneNumber,
  ONBOARDING_STATES.enterIdentity,
  ONBOARDING_STATES.enterDOB,
  ONBOARDING_STATES.enterSSN,
  ONBOARDING_STATES.enterCitizenshipCountry,
  ONBOARDING_STATES.enterDisclosures,
  ONBOARDING_STATES.enterEmploymentDisclosures,
  ONBOARDING_STATES.enterAgreements,
  ONBOARDING_STATES.submittedLiveAccountApplication,
];
const postLiveAccountApplication = [
  ONBOARDING_STATES.selectInvestingExperience,
  ONBOARDING_STATES.submittedLiveAccountApplication,

  ONBOARDING_STATES.redeemRewards,
  ONBOARDING_STATES.linkBankAccount,
  ONBOARDING_STATES.depositFunds,
  ONBOARDING_STATES.almostThere,
  ONBOARDING_STATES.redeemRewardsAfterDeposit,

  ONBOARDING_STATES.selectAccountPrivacy,
  // ONBOARDING_STATES.selectVPC,
  ONBOARDING_STATES.enterDefaultExpectedReturn,

  ONBOARDING_STATES.selectInterests,
  ONBOARDING_STATES.editWatchlist,
  ONBOARDING_STATES.end,
];
const postLiveAccountApplicationNonOnboarding = [
  ONBOARDING_STATES.submittedLiveAccountApplication,

  ONBOARDING_STATES.redeemRewards,
  ONBOARDING_STATES.linkBankAccount,
  ONBOARDING_STATES.depositFunds,
  ONBOARDING_STATES.almostThere,
  ONBOARDING_STATES.redeemRewardsAfterDeposit,
  ONBOARDING_STATES.end,
];
const postFundingSteps = [
  ONBOARDING_STATES.selectInvestingExperience,
  ONBOARDING_STATES.submittedLiveAccountApplication,

  ONBOARDING_STATES.redeemRewards,

  ONBOARDING_STATES.selectAccountPrivacy,
  // ONBOARDING_STATES.selectVPC,
  ONBOARDING_STATES.enterDefaultExpectedReturn,

  ONBOARDING_STATES.selectInterests,
  ONBOARDING_STATES.editWatchlist,
  ONBOARDING_STATES.end,
];
const socureOnboardingPath = [
  ONBOARDING_STATES.selectInvestingExperience,
  ONBOARDING_STATES.submittedLiveAccountApplication,
  ONBOARDING_STATES.socureApplication,

  ONBOARDING_STATES.redeemRewards,
  ONBOARDING_STATES.linkBankAccount,
  ONBOARDING_STATES.depositFunds,
  ONBOARDING_STATES.almostThere,
  ONBOARDING_STATES.redeemRewardsAfterDeposit,

  ONBOARDING_STATES.selectAccountPrivacy,
  // ONBOARDING_STATES.selectVPC,
  ONBOARDING_STATES.enterDefaultExpectedReturn,

  ONBOARDING_STATES.selectInterests,
  ONBOARDING_STATES.editWatchlist,
  ONBOARDING_STATES.end,
];
const socureNonOnboardingPath = [
  ONBOARDING_STATES.submittedLiveAccountApplication,
  ONBOARDING_STATES.socureApplication,

  ONBOARDING_STATES.redeemRewards,
  ONBOARDING_STATES.linkBankAccount,
  ONBOARDING_STATES.depositFunds,
  ONBOARDING_STATES.almostThere,
  ONBOARDING_STATES.redeemRewardsAfterDeposit,
  ONBOARDING_STATES.end,
];

const paperOnboardingPath = [
  ONBOARDING_STATES.accountTypeSelection,
  ONBOARDING_STATES.selectInvestingExperience,
  ONBOARDING_STATES.selectAccountPrivacy,
  ONBOARDING_STATES.enterDefaultExpectedReturn,
  ONBOARDING_STATES.selectInterests,
  ONBOARDING_STATES.editWatchlist,
  ONBOARDING_STATES.end,
];
const endOnboardingPath = [ONBOARDING_STATES.end];

export const ONBOARDING_PATHS = {
  accountSelection: accountSelectionPath,

  end: endOnboardingPath,
  paper: paperOnboardingPath,

  liveAccountApplication: liveAccountApplicationPath,
  postLiveAccountApplication: postLiveAccountApplication,
  postLiveAccountApplicationNonOnboarding,
  postFundingSteps: postFundingSteps,

  socure: socureOnboardingPath,
  socureNonOnboardingPath,

  onboardedLiveAccountApplication: onboardedLiveAccountApplication,
};
