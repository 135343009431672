import React from 'react';
import { useSelector } from 'react-redux';

export const useUserIdeasPerformance = (userId) => {
  const store = useSelector((state) => state.userIdeaPerformance.userDict);

  // if (this._isCollectiveUser()) {
  //   const { performance_today, performance_this_month, performance_last_month } = this.props.user;
  //   return {
  //     performance_today,
  //     performance_this_month,
  //     performance_last_month,
  //   };
  // }

  return userId in store ? store[userId] : null;
};
