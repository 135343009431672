import React from 'react';

class WarningNotice extends React.PureComponent {
  constructor() {
    super();

    this.DEFAULT_ICON_SIZE = 18;
  }
  render() {
    const { text } = this.props;

    return (
      <div className="warning-notice-container">
        <div>
          <i
            className="fa fa-exclamation-circle icon-in-btn ignore-click warning-text-color"
            style={{ fontSize: this.DEFAULT_ICON_SIZE }}
          ></i>
        </div>
        <span className="text-container">{text}</span>
      </div>
    );
  }
}

export default WarningNotice;
