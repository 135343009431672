import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import * as Actions from '../../actions/index';
import SecurityTable from '../SecurityTable/SecurityTable';
import PageLoading from '../../components/PageLoading';
import { PageHeading } from '../../components/UI/PageHeading';
import { findUserIdeas, getSecurityIdFromIdea } from '../../helpers/ideaHelpers';
import { sortAlphabetically } from '../../helpers/generalHelpers';
import { withRouter } from '../../main/utils';

class MyIdeasContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentContainerWidth: 1065,
      loadedIdeas: false,
    };
  }

  componentDidMount() {
    this._updatePageTitle();
    this._logPageView();
    const currentUserId = this.props.currentUser.user_id;
    const ideaList = this.props.ideas.ideaList;
    const ideasForSecurityTable = findUserIdeas(currentUserId, ideaList);
    const currentUsersIdeasLoading = this.props.ideas.loadingCurrentUserIdeas;

    if (!currentUsersIdeasLoading) {
      this.setState(() => ({
        loadedIdeas: true,
      }));
      this.fetchSecurityDataForIdeas(ideasForSecurityTable);
    }

    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate() {
    const _this = this;
    const currentUserId = this.props.currentUser.user_id;
    const ideaList = this.props.ideas.ideaList;
    const ideasForSecurityTable = findUserIdeas(currentUserId, ideaList);
    const currentUsersIdeasLoading = this.props.ideas.loadingCurrentUserIdeas;

    if (!currentUsersIdeasLoading && !this.state.loadedIdeas) {
      this.setState(
        () => ({
          loadedIdeas: true,
        }),
        () => _this.fetchSecurityDataForIdeas(ideasForSecurityTable)
      );
    }
  }

  render() {
    if (this.props.ideas.loadingCurrentUserIdeas) {
      return (
        <div className={`my-ideas-panel`}>
          <PageLoading style={{ zIndex: 10000 }} flatStyle />
        </div>
      );
    }

    return (
      <div className={`my-ideas-panel`} ref={(el) => (this.contentContainer = el)}>
        {this.props.isPanel === 'panel' && (
          <i
            className="fa fa-times fa-times-thin"
            colorClassName="secondary-text-color"
            onClick={this.handleMyIdeasPanelClose}
          />
        )}
        <PageHeading className="my-ideas-panel">My Ideas</PageHeading>
        <SecurityTable concernables={this._returnConcernables()} />
      </div>
    );
  }

  _returnConcernables = () => ({
    type: 'idea',
    list: sortAlphabetically({ type: 'ideas', list: this._returnMyIdeas() }),
  });

  _returnMyIdeas = () => {
    const currentUserId = this.props.currentUser.user_id;
    const ideaList = this.props.ideas.ideaList;
    return findUserIdeas(currentUserId, ideaList);
  };

  fetchSecurityDataForIdeas = (ideasForSecurityTable) => {
    const securityIds = ideasForSecurityTable.map((idea) => getSecurityIdFromIdea(idea));
    this.props.actions.quickFetchSecuritiesData(securityIds);
    this.props.actions.fetchSecuritiesPriceData(securityIds);
    this.props.actions.getIdeasForSecurity(securityIds);
  };

  handleMyIdeasPanelClose = () => {
    this.props.closePanel();
  };

  handleResize = (e) => {
    if ('contentContainer' in this && this.contentContainer) {
      this.setState((prevState) => {
        return {
          contentContainerWidth: this.contentContainer.offsetWidth,
        };
      });
    }
  };

  _updatePageTitle = () => {
    document.title = `${PRODUCT_DISPLAY_NAME} - My Ideas`;
  };

  _logPageView = () => {
    const event = 'View My Ideas';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    ideas: state.ideas,
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MyIdeasContainer)
);
