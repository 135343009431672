import React, { Component } from 'react';
import { connect } from 'react-redux';

import NewComponent from '../../components/PortfolioExplorer/SecuritiesFilter/NewComponent';
import ShowComponent from '../../components/PortfolioExplorer/SecuritiesFilter/ShowComponent';

import BasicTextField from '../../components/form/BasicTextField';
import MoneyField from '../../components/form/MoneyField';
import Toggle from '../../components/form/Toggle';
import Selection from '../../components/form/Selection';

import { convertToCamelCase, getFilterCreateBodyType } from '../../helpers/portfolioExplorerHelpers';
import { includes, findIndexOfObjectInArray } from '../../helpers/generalHelpers';
import { getValueNotation, formatSecurityPropertyValue } from '../../helpers/securitiesHelpers';

import {
  handleSelectionClick,
  handleToggleClick,
  handleChange,
  validateFormBeforeSubmit,
} from '../../helpers/formHelpers';
import styled from 'styled-components';

const Wrapper = styled.div`
  .securities-filter-container {
    background-color: ${({ theme }) => theme.themeColors.appBackground};
    border: 1px solid ${({ theme }) => theme.themeColors.border};
  }
  .securities-filter-properties-container {
    background-color: ${({ theme }) => theme.themeColors.component};
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    border-top: none;
  }
  .securities-filter-list-container {
    background-color: ${({ theme }) => theme.themeColors.appBackground};
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    border-top: none;
  }
`;

class SecuritiesFilter extends Component {
  constructor() {
    super();

    this.state = {
      filterName: null,
      formData: {},
      errors: {},
      inputs: [
        {
          type: BasicTextField,
          name: 'filterNameQuery',
          props: {
            placeholder: 'Search...',
          },
        },
        {
          type: Toggle,
          typeName: 'Toggle',
          name: 'operator',
          required: true,
          props: {
            values: ['>', '<'],
            selectionValues: ['Greater Than', 'Less Than'],
            selectionValuesClass: 'filter-operator-toggle',
          },
        },
        {
          type: BasicTextField,
          typeName: 'BasicTextField',
          name: 'filterValue',
          required: true,
          props: {
            placeholder: 'Enter a value...',
          },
        },
        {
          type: Selection,
          name: 'selection',
          // required: true, technically required but not listed to prevent validation for breaking because not always a selection
          props: {
            fetchSelectionOptions: true,
            selectionOptions: [],
            selectionValuesClass: 'filter-operator-toggle',
          },
        },
      ],
      editing: false,
    };
  }

  componentDidMount() {
    this.setFilterPropertiesToState(this.props.filter);

    // focus search field
    if (
      this.props.editMode &&
      this.filterNameQuery &&
      this.filterNameQuery.children &&
      this.filterNameQuery.children[0].children &&
      this.filterNameQuery.children[0].children[0].children[0]
    ) {
      this.filterNameQuery.children[0].children[0].children[0].focus();
    }
  }

  setFilterPropertiesToState = (filter) => {
    const formattedFilter = {
      name: filter.name,
    };
    if ('operator' in filter) {
      formattedFilter.operator = filter.operator;
    }
    if ('value' in filter) {
      formattedFilter.filterValue = filter.value;
    }
    if ('ids' in filter) {
      formattedFilter.selection = filter.ids;
    }
    this.setState((prevState) => {
      return {
        filterName: filter.name,
        formData: { ...formattedFilter },
      };
    });
  };

  handleCreateFilter = () => {
    const data = this.state.formData;
    const isASelection = 'selection' in data || getFilterCreateBodyType(this.state.filterName) === 'selection';

    if (isASelection) {
      if (!data.selection || (!!data.selection && data.selection.length === 0)) {
        this.setState((prevState) => ({
          errors: {
            ...this.state.errors,
            selection: 'Make a selection.',
          },
        }));
        return false;
      }
    } else {
      const validated = validateFormBeforeSubmit.call(this);
      if (!validated) {
        return false;
      }
    }

    const filter = {
      name: this.state.filterName,
      operator: 'selection' in this.state.formData ? '||' : this.state.formData.operator,
      [isASelection ? 'ids' : 'value']: isASelection ? this.state.formData.selection : this.state.formData.filterValue,
    };

    this.props.filterProps.createFilter(filter);
    this.handleEditStop(filter);
    this.props.filterProps.cancelCreateFilter();
  };

  handleUpdateFilter = () => {
    const data = this.state.formData;
    const isASelection = 'selection' in data || getFilterCreateBodyType(this.state.filterName) === 'selection';

    if (isASelection) {
      if (!!data.selection && data.selection.length === 0) {
        this.setState((prevState) => ({
          errors: {
            ...this.state.errors,
            selection: 'Make a selection.',
          },
        }));
        return false;
      }
    } else {
      const validated = validateFormBeforeSubmit.call(this);
      if (!validated) {
        return false;
      }
    }

    const filter = {
      id: 'filter' in this.props ? this.props.filter.id : null,
      name: this.state.filterName,
      operator: 'selection' in data ? '||' : data.operator,
      [isASelection ? 'ids' : 'value']: isASelection ? data.selection : data.filterValue,
    };

    this.props.filterProps.updateFilter(filter);
    this.handleEditStop();
    this.props.filterProps.cancelCreateFilter();
  };

  clearCreateFilterForm = () => {
    this.props.filterProps.cancelCreateFilter();
    this.setState({
      filterName: null,
      formData: {},
      editing: false,
    });
  };

  handleFilterNameSelection = (value) => {
    this.setState((prevState, props) => {
      return {
        filterName: value,
        formData: {
          name: value,
        },
      };
    });
  };

  handleEditClick = () => {
    this.setState((prevState) => {
      return {
        editing: true,
      };
    });
  };

  handleEditStop = () => {
    this.setState((prevState) => {
      return {
        editing: false,
      };
    });
  };

  cancelCreateFilter = () => {
    this.handleEditStop();
    this.props.filterProps.cancelCreateFilter();
  };

  returnInputDefaultProps = () => {
    return {
      handleToggleClick: handleToggleClick.bind(this),
      handleChange: handleChange.bind(this),
      handleSelectionClick: handleSelectionClick.bind(this),
      isFocused: function (name) {
        return this.state.focusedField === name;
      }.bind(this),
      getSelectionOptions: function () {
        const selectionFieldName = this.state.filterName;
        if (!!selectionFieldName) {
          const camelizedSelectionFieldName = convertToCamelCase(selectionFieldName);
          return this.props.portfolioExplorer.selections[camelizedSelectionFieldName];
        }
      }.bind(this),
      getSelectionFieldName: function () {
        const selectionFieldName = this.state.filterName;
        return convertToCamelCase(selectionFieldName);
      }.bind(this),
      getDisplaySelectionLabel: function (name, selectionItem) {
        return formatSecurityPropertyValue(selectionItem, name);
      }.bind(this),
      getValueNotation: function (name) {
        return getValueNotation(name);
      }.bind(this),
      getFocusNote: function (name) {
        if ('focusNote' in this.state) {
          return this.state.focusNote[name];
        }
      }.bind(this),
      getErrorMessage: function (name) {
        return this.state.errors[name];
      }.bind(this),
      getValue: function (name) {
        return this.state.formData[name];
      }.bind(this),
      isSelected: function (i, selectionOptionData, valuesSelected) {
        const key = Object.keys(selectionOptionData[i]).filter((k) => k.slice(k.length - 2, k.length) == 'id')[0];
        return findIndexOfObjectInArray(key, selectionOptionData[i], valuesSelected) !== false;
      },
    };
  };

  createInputRef = (inputName, input) => {
    this[inputName] = input;
  };

  inputProps = () => {
    return {
      returnInputDefaultProps: this.returnInputDefaultProps,
      createInputRef: this.createInputRef,
      inputs: this.state.inputs,
    };
  };

  removeFilter = () => {
    this.props.filterProps.removeFilter(this.props.filter);
  };

  renderComponent = () => {
    if (this.props.editMode || this.state.editing) {
      // create filter/ edit filter
      return (
        <NewComponent
          userEditing={this.state.editing}
          filter={{ ...this.props.filter, ...this.state.formData }}
          filterName={this.state.filterName}
          filterNameQuery={this.state.formData.filterNameQuery}
          handleCreateFilter={this.handleCreateFilter}
          cancelCreateFilter={this.cancelCreateFilter}
          handleUpdateFilter={this.handleUpdateFilter}
          removeFilter={this.removeFilter}
          clearCreateFilterForm={this.clearCreateFilterForm}
          handleFilterNameSelection={this.handleFilterNameSelection}
          inputProps={this.inputProps()}
        />
      );
    }

    // display filter
    return (
      <ShowComponent
        filter={this.props.filter}
        editing={this.state.editing}
        activeFilter={this.props.filter.name === this.props.filterProps.activeFilter}
        removeFilter={this.removeFilter}
        handleEditClick={this.handleEditClick}
        handleFilterHoverOrClick={this.props.filterProps.handleFilterHoverOrClick}
        handleFilterHoverLeave={this.props.filterProps.handleFilterHoverLeave}
      />
    );
  };

  handleFilterClick = (e) => {
    if (!this.state.editing) {
      this.setState((prevState) => ({
        editing: true,
      }));
    }
  };

  render() {
    return (
      <Wrapper
        ref={(el) => (this.filterContainer = el)}
        className="securities-filter-wrapper-container"
        onClick={this.handleFilterClick}
      >
        {this.renderComponent()}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    portfolioExplorer: state.portfolioExplorer,
  };
};

export default connect(mapStateToProps, null)(SecuritiesFilter);
