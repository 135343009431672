import React from 'react';
import { useLocation } from 'react-router-dom';
import { enums } from '@src/main/lib/nvstr-utils.es';
import getButtonText from '../../getButtonText';
import { TrackIteratively } from '../index';
import { ElementPositionContext, LocationContext } from '../../../context';

export const useButtonEvent = (props) => {
  const location = useLocation();
  const { elementPosition } = React.useContext(ElementPositionContext);
  const locationContext = React.useContext(LocationContext);

  return React.useCallback(
    (e) => {
      props.stopPropagation && e.stopPropagation();
      const onClickHandler = props.onClick || props.handleClick;
      onClickHandler && onClickHandler(e);

      try {
        let text = props.text;
        if (!text) {
          text = getButtonText(props.children);
        }
        const btnPosition = elementPosition || enums.node_location.body;

        const properties = {
          button_type: props.buttonType || enums.button_types.button,
          context: props.overrideContext || locationContext || location.pathname,
          description: text || props.icon,
          position: btnPosition,
          platform: enums.platform.web,
          url: window.location.pathname,
          url_query: window.location.search,
        };
        TrackIteratively.generic.BUTTON_CLICKED.send(properties);
      } catch (e) {
        console.error('TrackIteratively -> useButtonEvent -> catch', e.message);
      }
    },
    [props, location, elementPosition]
  );
};
