/*
  NOTES ON THIS CONFIG FILE

    showOnRoute -> to show on any route, enter root '/' and exact = false
*/
import { PRODUCT_NAME } from '@src/appConfig';
const _tourConfig = {
  1: {
    shouldShowArrow: (windowWidth, data) => {
      return data.horizontalListPage === 1;
    },
    returnArrowStyle: (windowWidth) => {
      const allWidths = {
        height: '75px',
        width: '75px',
      };
      return allWidths;
    },
    returnStyle: (windowWidth) => {
      const allWidths = {
        top: '1px',
        bottom: '-5px',
        right: '-2px',
        left: '-24px',
        zIndex: 100,
      };
      return allWidths;
    },
    returnColorScheme: (windowWidth) => {
      return {
        stepBgColorClassName: 'tour-collective-step-color',
        arrowColorClassName: 'tour-collective-step-arrow-color',
        circleColorClassName: 'tour-collective-step-circle-color',
        btnColorClassName: 'tour-collective-step-btn-color',
      };
    },
    setupActions: function(windowWidth) {
      // context of function must be set to the <HighlightComponent/>
      if (!this.props.actions) {
        console.error('setupActions needs access to actions');
      }
      if (!this.props.horizontalList) {
        console.error('setupActions needs access to horizontalList store');
      }

      this.props.actions.resetHorizontalListPage();

      if (windowWidth < 985) {
        this.props.actions.hideVerticalNav();
      }
    },
    returnWindowMessaging: function(windowWidth) {
      return {
        heading: 'Ideas from top performers',
        body: `See popular investment ideas from your connections and the whole ${PRODUCT_NAME} community. You can also see ideas from the best performers on ${PRODUCT_NAME}.`,
      };
    },
    showOnRoute: {
      route: '/',
      exact: true, // exact is used to specify only show on root dir
    },
    returnTourWindowStyle: function(windowWidth) {
      const smallMobile = {
        height: '235px',
        bottom: 'auto',
        top: '55px',
      };
      const mobile = {
        height: '250px',
        bottom: 'auto',
        top: '55px',
      };
      const desktop = {};
      return windowWidth < 500 ? (windowWidth < 350 ? smallMobile : mobile) : desktop;
    },
  },

  2: {
    shouldShowArrow: (windowWidth) => {
      return windowWidth >= 985;
    },
    returnArrowStyle: (windowWidth) => {
      const allWidths = {
        bottom: '-118px',
        right: '15px',
      };
      return allWidths;
    },
    returnStyle: (windowWidth) => {
      const allWidths = {
        top: '-15px',
        bottom: '-15px',
        right: '30px',
        left: '2px',
        zIndex: 100,
      };
      return allWidths;
    },
    returnColorScheme: (windowWidth) => {
      return {
        stepBgColorClassName: 'tour-explorer-step-color',
        arrowColorClassName: 'tour-explorer-step-arrow-color',
        circleColorClassName: 'tour-explorer-step-circle-color',
        btnColorClassName: 'tour-explorer-step-btn-color',
      };
    },
    setupActions: function(windowWidth) {
      // context of function must be set to the <HighlightComponent/>
      if (!this.props.actions) {
        console.error('setupActions needs access to actions');
      }

      if (windowWidth < 985) {
        this.props.actions.showVerticalNav();
      }
    },
    returnWindowMessaging: function(windowWidth) {
      return {
        heading: 'Discover new stocks and save them to your portfolio',
        body: `Looking for new ideas?  Explore the universe of investments with data from Capital IQ and ${PRODUCT_NAME}, and find stocks based on what\'s important to you.`,
      };
    },
    showOnRoute: {
      route: '/',
      exact: false,
    },
    returnTourWindowStyle: function(windowWidth) {
      const smallMobile = {
        height: '235px',
        bottom: 'auto',
        top: '55px',
      };
      const mobile = {
        height: '250px',
        bottom: 'auto',
        top: '55px',
      };
      const desktop = {};
      return windowWidth < 500 ? (windowWidth < 350 ? smallMobile : mobile) : desktop;
    },
  },

  3: {
    shouldShowArrow: (windowWidth) => {
      return windowWidth >= 985;
    },
    returnArrowStyle: (windowWidth) => {
      const allWidths = {
        bottom: '-120px',
        right: '45px',
      };
      return allWidths;
    },
    returnColorScheme: (windowWidth) => {
      return {
        stepBgColorClassName: 'tour-connections-step-color',
        arrowColorClassName: 'tour-connections-step-arrow-color',
        circleColorClassName: 'tour-connections-step-circle-color',
        btnColorClassName: 'tour-connections-step-btn-color',
      };
    },
    returnStyle: (windowWidth) => {
      const allWidths = {
        top: '-15px',
        bottom: '-15px',
        right: '40px',
        left: '2px',
        zIndex: 100,
      };
      return allWidths;
    },
    setupActions: function(windowWidth) {
      // context of function must be set to the <HighlightComponent/>
      if (!this.props.actions) {
        console.error('setupActions needs access to actions');
      }

      if (windowWidth < 985) {
        this.props.actions.showVerticalNav();
      }
    },
    returnWindowMessaging: function(windowWidth) {
      return {
        heading: 'Connect with trusted friends and other smart investors',
        body: `When you connect with friends on ${PRODUCT_NAME}, you can see which investments they like - and save them to your portfolio.  You can also get insightful feedback on your own investment ideas.`,
      };
    },
    showOnRoute: {
      route: '/',
      exact: false,
    },
    returnTourWindowStyle: function(windowWidth) {
      const mobile = {
        height: '225px',
        bottom: '10px',
      };
      const desktop = {};
      return windowWidth < 500 ? mobile : desktop;
    },
  },

  4: {
    shouldShowArrow: (windowWidth) => {
      return true;
    },
    returnArrowStyle: (windowWidth) => {
      const allWidths = {
        bottom: '-110px',
        right: '-73px',
        WebkitTransform: 'scaleX(-1) rotate(45deg)',
        MozTransform: 'scaleX(-1) rotate(45deg)',
        OTransform: 'scaleX(-1) rotate(45deg)',
        MsTransform: 'scaleX(-1) rotate(45deg)',
        transform: 'scaleX(-1) rotate(45deg)',
      };
      return allWidths;
    },
    returnColorScheme: (windowWidth) => {
      return {
        stepBgColorClassName: 'tour-notifications-step-color',
        arrowColorClassName: 'tour-notifications-step-arrow-color',
        circleColorClassName: 'tour-notifications-step-circle-color',
        btnColorClassName: 'tour-notifications-step-btn-color',
      };
    },
    returnStyle: (windowWidth) => {
      const allWidths = {
        top: '-13px',
        bottom: '-13px',
        right: '-14px',
        left: '-13px',
        zIndex: 100,
      };
      return allWidths;
    },
    setupActions: function(windowWidth) {
      // context of function must be set to the <HighlightComponent/>
      if (!this.props.actions) {
        console.error('setupActions needs access to actions');
      }

      if (windowWidth < 985) {
        this.props.actions.hideVerticalNav();
      }
    },
    returnWindowMessaging: function(windowWidth) {
      return {
        heading: 'See what new investing insights are trending in the community today',
        body: `Join the conversation, and benefit from the collective experience of the ${PRODUCT_NAME} community.`,
      };
    },
    showOnRoute: {
      route: '/',
      exact: false,
    },
    returnTourWindowStyle: function(windowWidth) {
      const mobile = {
        height: '250px',
        bottom: 'auto',
        top: '275px',
      };
      const desktop = {};
      return windowWidth < 500 ? mobile : desktop;
    },
  },

  // OPTIMIZER STEP
  5: {
    shouldShowArrow: (windowWidth) => {
      return false;
      return windowWidth > 450;
    },
    returnColorScheme: (windowWidth) => {
      return {
        stepBgColorClassName: 'tour-optimizer-step-color',
        arrowColorClassName: 'tour-optimizer-step-arrow-color',
        circleColorClassName: 'tour-optimizer-step-circle-color',
        btnColorClassName: 'tour-optimizer-step-btn-color',
      };
    },
    returnArrowStyle: (windowWidth) => {
      const allWidths = {
        bottom: '-119px',
        right: '-33px',
        WebkitTransform: 'scaleX(-1) rotate(28deg)',
        MozTransform: 'scaleX(-1) rotate(28deg)',
        OTransform: 'scaleX(-1) rotate(28deg)',
        MsTransform: 'scaleX(-1) rotate(28deg)',
        transform: 'scaleX(-1) rotate(28deg)',
      };
      return allWidths;
    },
    returnStyle: (windowWidth) => {
      const greaterThan550 = {
        top: '-15px',
        bottom: '-15px',
        right: '-20px',
        left: '-20px',
        zIndex: 100,
      };
      const lessThan550 = {
        top: '-5px',
        bottom: '-5px',
        right: '-10px',
        left: '-10px',
        zIndex: 100,
      };
      const lessThan450 = {
        top: '-9px',
        bottom: '-11px',
        right: '1px',
        left: '0px',
        zIndex: 100,
      };
      return windowWidth > 450 ? (windowWidth > 550 ? greaterThan550 : lessThan550) : lessThan450;
    },
    setupActions: function(windowWidth) {
      // context of function must be set to the <HighlightComponent/>
      if (!this.props.actions) {
        console.error('setupActions needs access to actions');
      }

      if (windowWidth < 450) {
        this.props.actions.showVerticalNav();
      } else {
        this.props.actions.hideVerticalNav();
      }
    },
    returnWindowMessaging: function(windowWidth) {
      return {
        heading: 'Portfolio analysis',
        body: [
          'See data on volatility, beta, and projected dividends as well as projected value based on the price targets you set.',
          // "It's built on independent, Nobel Prize-winning research on portfolio allocation.  It finds customized allocations that optimize your risk/reward based on the investment ideas you've saved - and your own specific goals.",
        ],
        // heading: 'Smart portfolio allocation is always one click away',
        // body: [
        //   'Not sure how much to allocate to each stock in your portfolio?  The Optimizer is constantly crunching the numbers.',
        //   "It's built on independent, Nobel Prize-winning research on portfolio allocation.  It finds customized allocations that optimize your risk/reward based on the investment ideas you've saved - and your own specific goals.",
        // ],
      };
    },
    showOnRoute: {
      route: '/',
      exact: false,
    },
  },

  end: {
    shouldShowArrow: (windowWidth) => {
      return true;
    },
    returnColorScheme: (windowWidth) => {
      return {
        gradient: true,
        stepBgColorClassName: 'tour-end-step-color',
        arrowColorClassName: 'tour-end-step-arrow-color',
        circleColorClassName: 'tour-end-step-circle-color',
        btnColorClassName: 'tour-end-step-btn-color',
      };
    },
    returnArrowStyle: (windowWidth) => {
      const allWidths = {
        bottom: '63px',
        right: '-26px',
        WebkitTransform: 'rotate(228deg) scale(.75)',
        MozTransform: 'rotate(228deg) scale(.75)',
        OTransform: 'rotate(228deg) scale(.75)',
        MsTransform: 'rotate(228deg) scale(.75)',
        transform: 'rotate(228deg) scale(.75)',
      };
      return allWidths;
    },
    returnStyle: (windowWidth) => {
      const allWidths = {
        top: '-15px',
        bottom: '-15px',
        right: '40px',
        left: '10px',
        zIndex: 100,
      };
      return allWidths;
    },
    setupActions: function(windowWidth) {
      if (windowWidth < 985) {
        this.props.actions.showVerticalNav();
      }
    },
    returnWindowMessaging: function(windowWidth) {
      return {
        heading: 'Thanks for joining the community',
        body: 'You can replay the tour at any time by clicking "Take A Tour" in the sidebar.',
      };
    },
    showOnRoute: {
      route: '/',
      exact: false,
    },
  },
};

// context of function must be set to the <HighlightComponent/>
export const returnTourConfig = function(step) {
  if (!this.props.actions) {
    console.error('returnTourConfig needs access to actions');
  }
  const config = _tourConfig[step] || _tourConfig.end;
  if (!config) {
    console.error(`config data not found for step ${step}`);
  }
  return config;
};

export const returnCountOfStepsInTour = function() {
  return Object.keys(_tourConfig).length;
};

export const isEndOfTour = function(step) {
  return !_tourConfig[step];
};
