import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../../../actions/index';

import AskAnyone from '../../../buttons/AskAnyone';

import { truncateName, snakeToHyphens, isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import { findUserIdeaForSecurity } from '../../../../helpers/ideaHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';

export class AskWhyCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? this.props.loadingCellComponent : this._renderCellContent()}
      </td>
    );
  }

  _isLoading = () => {
    const { userId, securityId } = this.props;
    const ideaList = this.props.ideas.ideaList;
    const currentUserId = this.props.currentUser.user_id;
    const ideaUserId = userId || currentUserId;

    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    const isIdeaMissing = idea === null;

    return isIdeaMissing || this.props.ideas.loadingCurrentUserIdeas || !(securityId in this.props.securities.lookup);
  };

  _returnLinkForHandlingClick = () => {
    const securityId = this.props.securityId;
    const openSecurityPanelConfig = {
      securityId,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    const { userId, securityId } = this.props;
    const ideaList = this.props.ideas.ideaList;
    const currentUserId = this.props.currentUser.user_id;
    const ideaUserId = userId || currentUserId;
    const isUserTheCurrentUser = userId === currentUserId;

    if (isUserTheCurrentUser) {
      return null;
    }

    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    const isIdeaMissing = idea === null;

    if (isIdeaMissing) {
      return null;
    }

    return (
      <div className={`security-table-ask-why-container`}>
        <AskAnyone viewingContext={'Profile Ideas Panel'} ideaId={idea.idea_id} buttonWidth={95} openFormInModal />
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    ideas: state.ideas,
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(AskWhyCell);

export default composedComponent;
