import React from 'react';

import IconButton from '../../buttons/IconButton';

import { convertToCamelCase } from '../../../helpers/portfolioExplorerHelpers';
import { convertValuesFromNotation } from '../../../helpers/securitiesHelpers';

import { displayFinancialValue, formatSecurityPropertyValue } from '../../../helpers/securitiesHelpers';
import { getFilterDisplayName } from '../../../constants/finData';

const ShowComponent = (props) => {
  const filter = props.filter;
  const operator = props.filter.operator;
  const value = props.filter.value;
  const ids = props.filter.ids;
  const filterName = props.filter.name;
  const filterWithExpression = operator === '>' || operator === '<';
  const camelizedSelectionFieldName = convertToCamelCase(filterName);
  const isCreateMode = (!filterWithExpression && props.activeFilter) || filterWithExpression;

  const displayFilterValue = (fieldName, value) =>
    displayFinancialValue(getFilterDisplayName(fieldName, true), convertValuesFromNotation(fieldName, value));

  const handleRemoveFilter = () => props.removeFilter(filter);

  return (
    <div className="securities-filter-wrapper card-shadow-styling">
      <div
        className={`securities-filter-container main-bg-color-match border-accent ${
          isCreateMode ? 'securities-filter-container-create' : ''
        }`}
      >
        <div className={`securities-filter-name-container`}>
          <div className={`securities-filter-name securities-filter-name-small`}>{filterName}</div>
          <div className="securities-filter-edit-button-container">
            <IconButton
              customClass="no-shadow main-bg-color-stroke-match"
              style={{
                position: 'absolute',
                top: '1px',
                right: '-6px',
                height: '20px',
                width: '20px',
              }}
              icon="fa fa-times fa-times-thin"
              size="medium"
              colorClassName="secondary-text-color"
              handleClick={handleRemoveFilter}
            />
          </div>
        </div>
      </div>
      {filterWithExpression && (
        <div className="securities-filter-properties-container component-bg border-accent">
          <div className="securities-filter-properties">
            <div className="securities-filter-operator">{operator === '>' ? 'Greater than' : 'Less than'}</div>
            <div className="securities-filter-value">{displayFilterValue(filterName, value)}</div>
          </div>
        </div>
      )}
      {props.activeFilter && !filterWithExpression && (
        <div className="securities-filter-properties-container component-bg securities-filter-selection-properties-container scrollable border-accent">
          <div className="securities-filter-properties">
            {ids.map((selectionItem, i) => (
              <div key={`${filterName}-selection-${i}`} className="securities-filter-selection-values">
                {formatSecurityPropertyValue(selectionItem, camelizedSelectionFieldName)}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowComponent;
