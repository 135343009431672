import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  overflow: visible;
  width: 40px;
  height: 40px;
  position: relative;
  top: 5px;
  left: 5px;
  & > svg {
    width: 25px;
    height: 25px;
  }
`;

function OptimizeArrow(props) {
  return (
    <Wrapper>
      <svg viewBox="0 0 20 20" fill="none">
        <path
          d="M17.997 5.6c-.028-.333-.345-.607-.693-.6h-2.782c-1.391 0-2.087 1.334-2.087 1.334h3.19l-4.58 4.39-2.293-2.197a.718.718 0 00-.984 0l-5.565 5.335a.649.649 0 000 .943c.272.26.713.26.985 0l5.074-4.864 2.292 2.197a.705.705 0 00.49.197c.185 0 .362-.07.49-.197l5.075-4.86v3.056S18 9.668 18 8.334V5.667c0-.023 0-.043-.003-.067z"
          fill="#222"
          className="optimizer-arrow-icon"
        />
      </svg>
    </Wrapper>
  );
}

export default OptimizeArrow;
