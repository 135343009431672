import React, { Component } from 'react';

class AnimatedOverlay extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    // currently this behavior change is not needed
    if (this.props.showOverlay) {
      $('body').css('overflow', 'hidden');
    } else if (prevProps.showOverlay && !this.props.showOverlay) {
      $('body').css('overflow', 'auto');
    }
  }

  componentWillUnmount() {
    // currently this behavior change is not needed
    if (this.props.showOverlay) {
      $('body').css('overflow', 'auto');
    }
  }

  render() {
    return (
      <div className={`overlay-wrapper ${this._returnCSSClasses()}`}>
        <div
          className={`panel-overlay opacity-animation ${this.determineOpacity()} ${this._returnCustomClass()}`}
          onClick={this.handleClick}
        />
        {this.props.children}
      </div>
    );
  }

  handleClick = (e) => this.props.handleClick();

  showOverlay = () => this.props.showOverlay;
  determineOpacity = () => (this.showOverlay() ? '' : 'overlay-hidden');

  _returnCustomClass = () => this.props.customClass;
  _returnCSSClasses = () => {
    let classNames = [];
    if (this.props.additionalClasses) {
      classNames.push(this.props.additionalClasses);
    }
    if (this.props.fixedCenter) {
      classNames.push('overlay-fixed-center');
    }
    if (this.props.light) {
      classNames.push('light-overlay');
    }
    return classNames.join(' ');
  };
}

export default AnimatedOverlay;
