import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';
import { INTERNAL_STORAGE_KEYS, InternalStorage } from '../utils/storage';

const URL = `${BASEURL}/api`;

export const liveAccountAdapter = {
  getLiveTradingAccount: () => {
    return sendRequest('get', [`${URL}/v1/brokerage_account`]).then((response) => response);
  },

  submitLiveTradingSignUpForm: (form, formPageName) => {
    const formWithDefaults = {
      ...form,
      platform: 'web', // {{ios|android|web}},
      device_session_id: InternalStorage.getItemFromStorage(INTERNAL_STORAGE_KEYS.SOCURE_SESSION_RISK_ID).data, // {{obtained_from_SocureSDK}}
    };
    return sendRequest('post', [
      `${URL}/v1/brokerage_account`,
      { brokerage_account: formWithDefaults, form_page_name: formPageName },
    ]);
  },

  updateLiveTradingAccount: (form) => {
    return sendRequest('patch', [`${URL}/v1/brokerage_account`, { brokerage_account: form }]).then((response) =>
      response ? response.data : response
    );
  },

  getBankAccounts: () => {
    const options = { ignoreAuth: true };
    return sendRequest('get', [`${URL}/v1_1/bank_accounts`], options).then((response) =>
      response ? response.data : response
    );
  },

  getUserAvailableFunds: () => {
    return sendRequest('get', [`${URL}/v1_1/bank_accounts/amount_available`]);
  },

  submitAddBankAccountForm: (form) =>
    sendRequest('post', [`${URL}/live_accounts/funding`, { apex_ach_relationship: form }]),

  linkAccountWithPlaid: (params) => {
    return sendRequest('post', [`${URL}/live_accounts/funding/instant`, params]).then((response) =>
      response ? response.data : response
    );
  },

  getLiveAccountDocumentationRequirements: () => {
    return sendRequest('get', [`${URL}/live_accounts/documentation_types`]);
  },

  submitLiveAccountDocument: (formData) => {
    return sendRequest('post', [`${URL}/live_accounts/documentation`, formData]);
  },

  fetchFurtherDocumentationStatus: () => {
    const options = { ignoreAuth: true };
    return sendRequest('get', [`${URL}/live_accounts/identify`], options);
  },

  removeBankAccount: (id) => {
    return sendRequest('delete', [`${URL}/v1/bank_accounts/${id}`]);
  },

  getDepositPickerAmounts: () => {
    return sendRequest('get', [`${URL}/v1/bank_accounts/deposit_amounts`]);
  },

  verifyBankAccount: (form) => {
    const { id } = form;
    return sendRequest('post', [`${URL}/v1/bank_accounts/${id}/verify`, form]);
  },

  postPaperTransfer: (values) => {
    return sendRequest('post', [`${URL}/v1/bank_accounts/${values.account_id}/transfers`, values]);
  },

  depositFunds: (values) => {
    return sendRequest('post', [`${URL}/v1/bank_accounts/${values.account_id}/transfers`, values]);
  },

  depositFundsEmailAuth: (values) => {
    return sendRequest('post', [`${URL}/v1/bank_accounts/${values.account_id}/lesson_deposits`, values]);
  },

  withdrawFunds: (values, acceptWarnings) => {
    const { account_id } = values;
    const transactionParams = { ...values };
    if (acceptWarnings) {
      transactionParams.override_warnings = acceptWarnings;
    }
    return sendRequest('post', [`${URL}/v1/bank_accounts/${account_id}/transfers`, transactionParams]);
  },

  getFundingRewardAmount: (amounts) => {
    const amountsCSV = amounts.join(',');
    return sendRequest('get', [`${URL}/funding_rewards?amounts=${amountsCSV}`]);
  },

  postFundingRewardAmount: (amounts) => {
    const body = { amounts };
    return sendRequest('post', [`${URL}/funding_rewards`, body]);
  },

  patchInstantLinkBankAccount: ({ data }) => {
    const { tornadoBankAccountId } = data;
    const body = { ...data, verify_method: 'instant' };
    return sendRequest('patch', [`${URL}/v1/bank_accounts/${tornadoBankAccountId}`, body]);
  },

  createRecurringDeposit: ({ data, allowEmailAuth }) => {
    const { account_id } = data;
    const body = { ...data };
    if (allowEmailAuth) {
      return sendRequest('post', [`${URL}/v1/bank_accounts/${account_id}/lesson_recurring_deposits`, body]);
    }
    return sendRequest('post', [`${URL}/v1/bank_accounts/${account_id}/recurring_transfers/create`, body]);
  },

  updateRecurringDeposit: ({ data }) => {
    const { account_id } = data;
    const body = { ...data };
    return sendRequest('patch', [`${URL}/v1/bank_accounts/${account_id}/recurring_transfers/cancel`, body]);
  },

  cancelRecurringDeposit: ({ data }) => {
    const { account_id } = data;
    const body = { ...data };
    return sendRequest('delete', [`${URL}/v1/bank_accounts/${account_id}/recurring_transfers/cancel`, body]);
  },
};
