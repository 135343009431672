import React from 'react';

import { createInputComponent } from '../../../helpers/formHelpers';

const FilterExpressionList = (props) => {
  const selectionInput = props.inputs[3];

  return <div>{createInputComponent(selectionInput, props.returnInputDefaultProps(), selectionInput.name)}</div>;
};

export default FilterExpressionList;
