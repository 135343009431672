import React from 'react';
import Badge from './Badge';
import InfoIcon from './InfoIcon';
import styled from 'styled-components';
import { Body1, Body5 } from '../../main/lib/nvstr-common-ui.es';
import { Container } from '../../main/components/ui';

const InlineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  //border: 1px solid ${({ theme }) => theme.themeColors.border};
  //border-radius: 5px;
`;
const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const RankWrapper = styled.div`
  margin-left: 12px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  img {
    height: 44px;
    width: 44px;

    margin-right: 4px;
  }

  .rank-label {
    font-size: 16px;
    line-height: 18px;
  }
`;

const UserPortfolioRank = (props) => {
  const { user, inline } = props;
  const { most_recent_rank } = user;
  if (!most_recent_rank) {
    return null;
  }
  const { badge, label } = most_recent_rank;
  const isIncomplete = badge === 'incomplete';

  if (inline) {
    return (
      <InlineWrapper>
        <LabelWrapper>
          <Body1>Portfolio Rank</Body1>
        </LabelWrapper>
        <RankWrapper>
          {isIncomplete ? (
            <>
              <div className="sell-text-color" style={{ fontSize: '16px', margin: '10px 0px' }}>
                <Body5 isLowContrast>{label}</Body5>
              </div>
              <Container style={{ position: 'relative' }} height={16}>
                <InfoIcon word={'portfolio_rank'} style={{ top: '0px' }} />
              </Container>
            </>
          ) : (
            <>
              <Badge badgeName={badge} />
              {label && (
                <div className={'rank-label'}>
                  <Body1> {label}</Body1>
                </div>
              )}
              <Container style={{ position: 'relative' }} height={16}>
                <InfoIcon word={'portfolio_rank'} style={{ top: '0px' }} />
              </Container>
            </>
          )}
        </RankWrapper>
      </InlineWrapper>
    );
  }

  return (
    <div className={`profile-user-ranking-summary`}>
      <div className={`main-label`}>
        <span style={{ position: 'relative' }}>
          Portfolio Rank
          <InfoIcon word={'portfolio_rank'} style={{ top: 'auto', bottom: '1px' }} />
        </span>
      </div>
      {badge === 'incomplete' ? (
        <>
          <div className="sell-text-color" style={{ fontSize: '16px', margin: '10px 0px' }}>
            {label}
          </div>
          <div className="secondary-text-color" style={{ fontSize: '12px', fontWeight: '300' }}>
            This user has not placed a trade yet
          </div>
        </>
      ) : (
        <>
          <Badge badgeName={badge} />
          {label && <div>{label}</div>}
        </>
      )}
    </div>
  );
};

export default UserPortfolioRank;
