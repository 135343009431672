import { fetchCurrentUserQuickInfo } from './currentUserActions';
import { viewpointAdapter } from '../adapters/viewpointAdapter';

import { UPDATE_VIEWPOINT_DATA, VIEWPOINT_SUBSCRIBE_COMPLETE, VIEWPOINT_SUBSCRIBE_CANCELLED } from '../constants';

export const fetchAllViewpointData = () => {
  return function (dispatch) {
    return viewpointAdapter.fetchAllViewpointData().then((response) => {
      const { status, data } = response;

      if (status === 200) {
        const orgs = data.viewpoint_orgs;
        const featuredSubscriptionIdsDisplayOrder = data.onboarding_display_sequence;
        const defaultSelection = data.onboarding_default_selection;
        dispatch({
          type: UPDATE_VIEWPOINT_DATA,
          payload: {
            orgs,
            featuredSubscriptionIdsDisplayOrder,
            defaultSelection,
          },
        });

        return {
          ok: true,
          data,
        };
      } else {
        return {
          error: true,
        };
      }
    });
  };
};

export const subscribeToViewpoint = (params) => {
  const USER_UNABLE_TO_SUBSCRIBE = 'Unable to subscribe at this time';
  const ALREADY_SUBBED_ERROR = 'Current user is already subscribed to that viewpoint';
  const VIEWPOINT_NOT_ELIGIBLE_FOR_SUBS = 'Specified viewpoint can not be subscribed to';
  const ALREADY_USED_FREE_SUB = 'Request expects a free subscription but the subscription will not be free';

  return function (dispatch) {
    const { id, isFree } = params;

    return viewpointAdapter.subscribeToViewpoint(params).then((response) => {
      const { data } = response;
      const errorMessage = (data && data.error) || 'Something went wrong. Please try again.';

      if (data && data.status === 'success') {
        const updateRequests = [fetchCurrentUserQuickInfo()(dispatch), fetchAllViewpointData()(dispatch)];
        return Promise.all(updateRequests).then((responses) => {
          if (!responses[1].ok) {
            // if refresh data fails, just do a quick modification of store
            dispatch({
              type: VIEWPOINT_SUBSCRIBE_COMPLETE,
              payload: { id, isFree },
            });
          }

          return {
            ok: true,
          };
        });
      } else {
        const isFreeSubAlreadyUsed = errorMessage === ALREADY_USED_FREE_SUB;

        let displayErrorMessage = errorMessage;

        if (errorMessage === ALREADY_SUBBED_ERROR) {
          displayErrorMessage = 'You are already subscribed to this viewpoint.';
        }
        if (errorMessage === USER_UNABLE_TO_SUBSCRIBE) {
          displayErrorMessage = 'Something went wrong. Please try again later.';
        }
        if (errorMessage === VIEWPOINT_NOT_ELIGIBLE_FOR_SUBS) {
          displayErrorMessage = 'Something went wrong. Please try again later.';
        }
        if (errorMessage === ALREADY_USED_FREE_SUB) {
          displayErrorMessage = 'Something went wrong. Please try again later.';
        }

        const returnResponse = {
          error: true,
          errorMessage: displayErrorMessage,

          isAlreadySubbed: errorMessage === ALREADY_SUBBED_ERROR,
          isUserUnableToSub: errorMessage === USER_UNABLE_TO_SUBSCRIBE,
          isViewpointNotEligibleForSubs: errorMessage === VIEWPOINT_NOT_ELIGIBLE_FOR_SUBS,
          isFreeSubAlreadyUsed,
        };

        if (isFreeSubAlreadyUsed) {
          return fetchAllViewpointData()(dispatch).then((r) => returnResponse);
        } else {
          return returnResponse;
        }
      }
    });
  };
};

export const cancelViewpointSubscription = (viewpointOrgId) => {
  const UNEXPECTED_ERROR = 'Unable to unsubscribe at this time';
  const NOT_SUBSCRIBED_ERROR = 'Current user is not subscribed to that viewpoint';

  return function (dispatch) {
    return viewpointAdapter.cancelViewpointSubscription(viewpointOrgId).then((response) => {
      const { status, errorMessage } = response;

      if (status === 200) {
        const updateRequests = [fetchCurrentUserQuickInfo()(dispatch), fetchAllViewpointData()(dispatch)];
        return Promise.all(updateRequests).then((responses) => {
          if (!responses[1].ok) {
            // if refresh data fails, just do a quick modification of store
            dispatch({
              type: VIEWPOINT_SUBSCRIBE_CANCELLED,
              payload: id,
            });
          }

          return {
            ok: true,
          };
        });
      } else {
        let displayErrorMessage = 'Something went wrong. Please try again later.';
        if (errorMessage === UNEXPECTED_ERROR) {
          displayErrorMessage = 'Unable to unsubscribe at this time. Please try again later.';
        }
        if (errorMessage === NOT_SUBSCRIBED_ERROR) {
          displayErrorMessage = 'Something went wrong. You are not subscribed to that viewpoint.';
          fetchAllViewpointData()(dispatch);
        }
        return {
          error: true,
          errorMessage: displayErrorMessage,
        };
      }
    });
  };
};

// export const fetchStocksCovered = params => {
//   return function (dispatch) {
//     return viewpointAdapter.fetchStocksCovered({ data: params, dispatch })
//       .then(response => {
//         const {
//           status,
//           data
//         } = response;
//
//         if (status === 200) {
//           const { securities } = data;
//
//           if (securities.length > 0) {
//             dispatch({
//               type: ADD_SECURITIES_DESCRIPTIONS,
//               payload: securities,
//             })
//           }
//
//           return {
//             ok: true,
//             securities,
//             securityIds: securities.map(s => s.id)
//           }
//         }
//         else {
//           return {
//             error: true,
//           }
//         }
//       })
//   }
// }
