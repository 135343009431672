import React from 'react';
import styled from 'styled-components';
import { fetchSecuritiesPriceData, quickFetchSecuritiesData } from '../../actions';
import { usePositionsLookup } from '../../main/hooks/portfolio/usePositions';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { returnSecurityPanelUrl } from '../../helpers/securityPanelHelpers';
import { TableName, TableSymbol } from '../../main/components/text/ui/securities';
import { Price, PRICE_DISPLAY_TYPES } from '../../main/components/securities/Price';
import { useSecurity } from '../../main/hooks/securities/useSecurity';
import { formatDataValue } from '../../helpers/securitiesHelpers';
import { Body5 } from '../../main/lib/nvstr-common-ui.es';
import { TableHeadingCellLabel } from '../../main/components/text/TableHeadingCellLabel';
import InfoIcon from '../UI/InfoIcon';
import { usePositionPerformance } from '../../main/hooks/portfolio/usePositionPerformance';
import { usePositionAllocation } from '../../main/hooks/portfolio/usePositionAllocation';
import {
  PositionAllocationPercentValue,
  PositionAllocationShareValue,
  PositionAllocationValue,
  PositionChangeValue,
  PositionPercentChangeValue,
} from '../../main/components/text/ui/positions';
import { useElementSize } from '../../main/hooks/util';
import { FlatButton } from '../../main/components/buttons';
import { usePortfolioCashData } from '../../main/hooks/portfolio/useEquityData';
import { ExtendedTradingPrice } from '../../main/containers/Securities/ExtendedTradingPrice';

const getPositionSecurityIds = (positions) => {
  return Object.keys(positions);
};

const getSymbolFromSecurity = (security) => security.symbol;
const sortAlphabetically = (securities) =>
  securities.sort((a, b) => {
    if (!getSymbolFromSecurity(a)) {
      console.error('There was no symbol to sort by.', a);
      return [];
    }
    if (!getSymbolFromSecurity(b)) {
      console.error('There was no symbol to sort by.', b);
      return [];
    }

    if (getSymbolFromSecurity(a) < getSymbolFromSecurity(b)) return -1;
    if (getSymbolFromSecurity(a) > getSymbolFromSecurity(b)) return 1;
    return 0;
  });

const convertPositionsToList = (positions) => Object.values(positions);

const Wrapper = styled.div`
  position: relative;
`;
const TableWrapper = styled.div`
  border-radius: 5px;
  background: ${({ theme }) => theme.themeColors.component};
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  border: 1px solid transparent;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.appBackground};
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
    border-radius: 5px;
    transition: 200ms all;
  }
`;
const TableHeadings = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.appBackground};
`;
const Cell = styled.div`
  flex: 1;
  min-width: ${({ numofcolumns }) => (numofcolumns === 4 ? '25%' : '33.3%')};
  max-width: ${({ numofcolumns }) => (numofcolumns === 4 ? '25%' : '33.3%')};

  text-align: left;
`;
const CellLink = styled(Link)`
  flex: 1;
  min-width: ${({ numofcolumns }) => (numofcolumns === 4 ? '25%' : '33.3%')};
  max-width: ${({ numofcolumns }) => (numofcolumns === 4 ? '25%' : '33.3%')};

  text-align: left;
  cursor: pointer;

  &:hover {
    text-decoration: none;

    * {
      text-decoration: none;
    }
  }
`;
const IdentifierCell = styled(CellLink)`
  padding: 16px;
  cursor: pointer;
`;
const TodayChangeCell = styled(CellLink)`
  padding: 16px 16px 16px 0;
`;
const TotalChangeCell = styled(CellLink)`
  padding: 16px 16px 16px 0;
`;
const AllocationCell = styled(CellLink)`
  padding: 16px 16px 16px 0;
`;
const IdentifierHeadingCell = styled(Cell)`
  padding: 0 16px 0 0;
  text-align: left;
`;
const TodayChangeHeadingCell = styled(Cell)`
  text-align: left;
  padding: 0 16px 0 0;
`;
const TotalChangeHeadingCell = styled(Cell)`
  text-align: left;
  padding: 0 16px 0 0;
`;
const AllocationHeadingCell = styled(Cell)`
  padding: 0 16px 0 0;

  svg {
    opacity: 0.6;
  }
`;
const PriceWrapper = styled.div`
  padding-top: 2px;

  &.de-emphasize {
    color: ${({ theme }) => theme.themeColors.text} !important;
    opacity: 0.6 !important;

    * {
      color: ${({ theme }) => theme.themeColors.text} !important;
    }
  }
`;
const TodayChangeCellWrapper = styled.div`
  text-align: left;
  opacity: ${({ value }) => (value === 0 ? '0.6' : '1')};
`;
const TotalChangeCellWrapper = styled.div`
  text-align: left;
  opacity: ${({ value }) => (value === 0 ? '0.6' : '1')};
`;
const PositionAllocationCellWrapper = styled.div`
  text-align: left;
`;
const AllocationDetailRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
const CashRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const CashLabelCell = styled.div`
  padding: 16px;
`;
const CashValueCell = styled(Cell)`
  padding: 16px 16px 16px 0;
  text-align: left;
`;

const TodayChange = ({ securityId }) => {
  const {
    todayChange: { value, percent },
  } = usePositionPerformance(securityId);

  if (isNaN(value)) return null;
  return (
    <TodayChangeCellWrapper value={value}>
      <div>
        <PositionChangeValue value={value} />
      </div>
      <div>
        <PositionPercentChangeValue value={percent} />
      </div>
    </TodayChangeCellWrapper>
  );
};

const TotalChange = ({ securityId }) => {
  const {
    totalChange: { value, percent },
  } = usePositionPerformance(securityId);

  if (isNaN(value)) return null;
  return (
    <TotalChangeCellWrapper value={value}>
      <div>
        <PositionChangeValue value={value} />
      </div>
      <div>
        <PositionPercentChangeValue value={percent} />
      </div>
    </TotalChangeCellWrapper>
  );
};

const PositionAllocation = ({ securityId }) => {
  const { shares, value, percentageOfPortfolio } = usePositionAllocation(securityId);

  if (isNaN(shares)) return null;
  return (
    <PositionAllocationCellWrapper>
      <PositionAllocationValue value={value} />
      <AllocationDetailRow>
        <PositionAllocationShareValue value={shares} />
        <div style={{ paddingLeft: '4px' }}>
          <PositionAllocationPercentValue value={percentageOfPortfolio * 100} />
        </div>
      </AllocationDetailRow>
    </PositionAllocationCellWrapper>
  );
};

const CashRow = ({ numofcolumns }) => {
  const { value, percentageOfEquity } = usePortfolioCashData();
  return (
    <CashRowWrapper>
      <CashLabelCell>
        <Body5>Cash</Body5>
      </CashLabelCell>
      <div />
      <CashValueCell numofcolumns={numofcolumns}>
        <div>
          <Body5>{formatDataValue(value, 'price')}</Body5>
        </div>
        <div>
          <Body5>{formatDataValue(percentageOfEquity, 'percentage')}</Body5>
        </div>
      </CashValueCell>
    </CashRowWrapper>
  );
};

const Row = ({ position, numofcolumns, isTodayChangeShowing }) => {
  const location = useLocation();
  const { security_id: securityId, symbol } = position;
  const { name } = useSecurity(securityId);
  const additionalProps = {};
  const openSecurityPanelConfig = {
    location,
    securityId,
    additionalProps,
  };
  const urlToOpenSecurityPanel = returnSecurityPanelUrl(openSecurityPanelConfig);
  return (
    <RowWrapper>
      {numofcolumns === 4 ? (
        <>
          <IdentifierCell to={urlToOpenSecurityPanel} numofcolumns={numofcolumns}>
            <div>
              <TableSymbol>{symbol}</TableSymbol>
            </div>
            <div>
              <TableName>{name}</TableName>
            </div>
            <PriceWrapper>
              <Price securityId={securityId} type={PRICE_DISPLAY_TYPES.inline} />
            </PriceWrapper>
            <ExtendedTradingPrice securityId={securityId} ContainerProps={{ top: 3, style: { opacity: 0.6 } }} />
          </IdentifierCell>
          <TodayChangeCell to={urlToOpenSecurityPanel} numofcolumns={numofcolumns}>
            <TodayChange securityId={securityId} />
          </TodayChangeCell>
          <TotalChangeCell to={urlToOpenSecurityPanel} numofcolumns={numofcolumns}>
            <TotalChange securityId={securityId} />
          </TotalChangeCell>
          <AllocationCell to={urlToOpenSecurityPanel} numofcolumns={numofcolumns}>
            <PositionAllocation securityId={securityId} />
          </AllocationCell>
        </>
      ) : (
        <>
          <IdentifierCell to={urlToOpenSecurityPanel} numofcolumns={numofcolumns}>
            <div>
              <TableSymbol>{symbol}</TableSymbol>
            </div>
            <div>
              <TableName>{name}</TableName>
            </div>
            <PriceWrapper>
              <Price securityId={securityId} type={PRICE_DISPLAY_TYPES.inline} />
            </PriceWrapper>
            <ExtendedTradingPrice securityId={securityId} ContainerProps={{ top: 3, style: { opacity: 0.6 } }} />
          </IdentifierCell>
          {isTodayChangeShowing ? (
            <TodayChangeCell to={urlToOpenSecurityPanel} numofcolumns={numofcolumns}>
              <TodayChange securityId={securityId} />
            </TodayChangeCell>
          ) : (
            <TotalChangeCell to={urlToOpenSecurityPanel} numofcolumns={numofcolumns}>
              <TotalChange securityId={securityId} />
            </TotalChangeCell>
          )}
          <AllocationCell to={urlToOpenSecurityPanel} numofcolumns={numofcolumns}>
            <PositionAllocation securityId={securityId} />
          </AllocationCell>
        </>
      )}
    </RowWrapper>
  );
};

const ToggleChangeActionWrapper = styled.div`
  position: absolute;
  top: -24px;
  left: 0;
  right: 0;

  height: 24px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: row;

  opacity: 0.4;
  * {
    font-size: 10px !important;
  }
`;
const ToggleChangeColumn = ({ value, onToggle }) => {
  const handleClick = () => {
    onToggle(value);
  };
  return (
    <ToggleChangeActionWrapper>
      <FlatButton buttonProps={{ style: { padding: '5px 16px' } }} transparent onClick={handleClick}>
        {value === 'day' ? 'Show Total Change' : 'Show Today Change'}
      </FlatButton>
    </ToggleChangeActionWrapper>
  );
};

const Table = (props) => {
  const { renderIfNoPositions, ContainerComponent } = props;

  const dispatch = useDispatch();
  const ref = React.useRef(null);

  const [setRef, size] = useElementSize();
  const [numofcolumns, setNumOfColumns] = React.useState(3);
  const [isTodayChangeShowing, setIsTodayChangeShowing] = React.useState(true);

  const onToggleChangeColumn = React.useCallback((v) => {
    setIsTodayChangeShowing(v === 'total');
  }, []);

  React.useEffect(
    () => {
      setRef(ref);
    },
    [ref]
  );

  React.useEffect(
    () => {
      const tableWidth = size.width;
      const nextNumOfColumns = isNaN(tableWidth) ? 3 : tableWidth > 555 ? 4 : 3;
      setNumOfColumns(nextNumOfColumns);
    },
    [size]
  );

  const fetchDataForSecurities = (securityIds) => {
    quickFetchSecuritiesData(securityIds)(dispatch);
    fetchSecuritiesPriceData(securityIds)(dispatch);
  };

  const positions = usePositionsLookup();

  React.useEffect(
    () => {
      const securityIds = getPositionSecurityIds(getPositionSecurityIds(positions));
      fetchDataForSecurities(securityIds);
    },
    [positions]
  );

  if (getPositionSecurityIds(positions).length === 0) {
    if (renderIfNoPositions) {
      const Component = renderIfNoPositions;
      return <Component container={ContainerComponent} />;
    }

    return null;
  }

  const sortedPositions = sortAlphabetically(convertPositionsToList(positions));

  return (
    <ContainerComponent>
      <Wrapper>
        {numofcolumns === 3 && (
          <ToggleChangeColumn value={isTodayChangeShowing ? 'day' : 'total'} onToggle={onToggleChangeColumn} />
        )}
        <TableWrapper ref={ref}>
          <TableHeadings>
            {numofcolumns === 4 ? (
              <>
                <IdentifierHeadingCell numofcolumns={numofcolumns} />
                <TodayChangeHeadingCell numofcolumns={numofcolumns}>
                  <TableHeadingCellLabel>Today Change</TableHeadingCellLabel>
                </TodayChangeHeadingCell>
                <TotalChangeHeadingCell numofcolumns={numofcolumns}>
                  <TableHeadingCellLabel>Total Change</TableHeadingCellLabel>
                </TotalChangeHeadingCell>
                <AllocationHeadingCell numofcolumns={numofcolumns}>
                  <div>
                    <span style={{ position: 'relative' }}>
                      <TableHeadingCellLabel>Allocation</TableHeadingCellLabel>
                      <InfoIcon
                        word={`tooltip_allocation`}
                        style={{ top: '3px', right: '-18px' }}
                        position={'default'}
                      />
                    </span>
                  </div>
                </AllocationHeadingCell>
              </>
            ) : (
              <>
                <IdentifierHeadingCell numofcolumns={numofcolumns} />
                {isTodayChangeShowing ? (
                  <TodayChangeHeadingCell numofcolumns={numofcolumns}>
                    <TableHeadingCellLabel>Today Change</TableHeadingCellLabel>
                  </TodayChangeHeadingCell>
                ) : (
                  <TotalChangeHeadingCell numofcolumns={numofcolumns}>
                    <TableHeadingCellLabel>Total Change</TableHeadingCellLabel>
                  </TotalChangeHeadingCell>
                )}
                <AllocationHeadingCell numofcolumns={numofcolumns}>
                  <div>
                    <span style={{ position: 'relative' }}>
                      <TableHeadingCellLabel>Allocation</TableHeadingCellLabel>
                      <InfoIcon
                        word={`tooltip_allocation`}
                        style={{ top: '3px', right: '-18px' }}
                        position={'default'}
                      />
                    </span>
                  </div>
                </AllocationHeadingCell>
              </>
            )}
          </TableHeadings>
          {sortedPositions.map((p, i) => (
            <Row
              key={`${p.security_id}-${i}`}
              position={p}
              numofcolumns={numofcolumns}
              isTodayChangeShowing={isTodayChangeShowing}
            />
          ))}
          <CashRow numofcolumns={numofcolumns} isTodayChangeShowing={isTodayChangeShowing} />
        </TableWrapper>
      </Wrapper>
    </ContainerComponent>
  );
};

export default Table;
