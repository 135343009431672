import React from 'react';
import { isInt, isFloat } from '../../../helpers/numberHelpers';
import { formatLocaleString } from '../../../helpers/generalHelpers';

const DisplayPercentageComponent = (props) => {
  const { decimalPlaces, showZeroWithNoDecimal, groupValuesUnder, rawValue } = props;

  if (!isInt(rawValue) && !isFloat(rawValue)) {
    console.error(
      'DisplayPercentageComponent requires rawValue to be an integer or float, received ' + typeof rawValue
    );
  }

  const returnValue = (rawValue) => {
    const absRawValue = Math.abs(rawValue);
    const isNegative = rawValue < 0;

    if (absRawValue === 0 && showZeroWithNoDecimal) {
      return '0';
    }

    if (groupValuesUnder !== undefined && absRawValue < groupValuesUnder) {
      return `< ${(isNegative ? -1 : 1) * groupValuesUnder}`;
    }

    if (decimalPlaces !== undefined) {
      return formatLocaleString(rawValue, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      });
    }
    return rawValue;
  };

  const value = returnValue(rawValue);
  return <span className={`percentage-display`}>{`${value}%`}</span>;
};

export default DisplayPercentageComponent;
