import { BASEURL } from '.';
import { sendRequest } from '../helpers/ajaxHelpers';
import { normalizeAskDataForAPI } from '../helpers/askHelpers';
import { createQueryString } from '../helpers/routerHelpers';

const URL = `${BASEURL}/api`;

export const askAdapter = {
  checkAskPermission: (askData) => {
    /*
      params[:asked_user_id]
  
      params[:security_id]
      params[:idea_id]
      params[:thought_id]
    */
    const query = askData;
    const queryString = createQueryString(query);
    return sendRequest('get', [`${URL}/asks/permitted${queryString}`]).then((response) => response);
  },

  sendAsk: (askData) => {
    /*
      asks -> array of
        {
          asked_user_id -> required
          security_id -> required
          question -> required

          idea_id
          thought_id
        }
    */
    const asks = Array.isArray(askData)
      ? askData.map((askData) => normalizeAskDataForAPI(askData))
      : [normalizeAskDataForAPI(askData)];
    const body = { asks };

    return sendRequest('post', [`${URL}/asks`, body]).then((response) => response);
  },
};
