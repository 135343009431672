import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 17px;
  height: 17px;
  & > svg {
    width: 17px;
    height: 17px;
  }

  & > svg {
    display: block;
    overflow: visible;
    animation-name: spin-cycle;
  }
  & g,
  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function SearchIcon() {
  return (
    <Wrapper>
      <svg width={16} height={16} viewBox="0 0 16 16" fill="none">
        <path
          d="M10.917 9.667h-.659l-.233-.225a5.393 5.393 0 001.308-3.525 5.416 5.416 0 10-5.416 5.416 5.393 5.393 0 003.525-1.308l.225.233v.659l4.166 4.158 1.242-1.242-4.158-4.166zm-5 0a3.745 3.745 0 01-3.75-3.75 3.745 3.745 0 013.75-3.75 3.745 3.745 0 013.75 3.75 3.745 3.745 0 01-3.75 3.75z"
          fill="#201F2C"
        />
      </svg>
    </Wrapper>
  );
}

export default SearchIcon;
