import React from 'react';

import TradeTransactionFee from './components/TradeTransactionFee';
import MoneyNumber from '../../../UI/DataTypes/MoneyNumber';
import Number from '../../../UI/DataTypes/Number';

import { isOrderOperationTypeBuying } from '../../../../helpers/ordersHelpers';
import { truncateName } from '../../../../helpers/generalHelpers';

const TradeTransaction = (props) => {
  const {
    type,
    sub_type,
    security,

    shares,
    price,
    amount,
    fees,

    transaction_id,
    time,
  } = props;
  const tradeTypeColorClass = isOrderOperationTypeBuying(sub_type.toLowerCase()) ? 'buy-text-color' : 'sell-text-color';
  const { symbol, name } = security;
  return (
    <li className={`trade-transaction-item`}>
      <div className={`trade-transaction-details`}>
        <div className={`transaction-identifier`}>
          <div className={`transaction-type`}>{type}</div>
          <div className={`transaction-sub-type transaction-trade-type ${tradeTypeColorClass}`}>{sub_type}</div>
        </div>
        <div className={`inline-grouping`}>
          <div className={`transaction-security-identifier`}>
            <div className={`security-symbol`}>{symbol}</div>
            <div className={`security-name secondary-text-color`}>{truncateName(name, 25)}</div>
          </div>
          <div className={`transaction-trade-details`}>
            <div className={`transaction-trade-pricing`}>
              <span className={`trade-shares-price transaction-amount`}>
                {`${shares} sh @`}
                <Number className="trade-price-amount" value={price ? price : 0} type={'float'} />
              </span>
              <MoneyNumber className="trade-amount transaction-amount" value={amount} />
            </div>
            {fees.map((feeData, i) => (
              <TradeTransactionFee key={i} {...feeData} />
            ))}
          </div>
        </div>
      </div>
    </li>
  );
};

export default TradeTransaction;
