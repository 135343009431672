import React from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../hooks/user/useUser';
import { customParseFloat } from '@src/helpers/numberHelpers';
import { isUserThoughtLeader } from '@src/helpers/usersHelpers';

import { ThoughtLeaderProfile } from './user/ThoughtLeaderProfile';
import { CommunityUserProfile } from './user/CommunityUserProfile';
import PageLoading from '../../components/PageLoading';
import { getUser } from '@src/actions';
import { useDispatch } from 'react-redux';

export const UserProfile = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const userId = id && customParseFloat(id);
  const user = useUser(userId);
  const isThoughtLeader = isUserThoughtLeader(user);

  React.useEffect(() => {
    getUser(userId)(dispatch);
  }, []);

  if (!user) {
    return <PageLoading />;
  }

  if (isThoughtLeader) {
    return <ThoughtLeaderProfile userId={userId} />;
  }

  return <CommunityUserProfile key={userId} userId={userId} />;
};
