export const VALIDATION_MESSAGES = {
  requiredField: 'Required',
  validName: 'Cannot use restricted characters.',
  validEmail: 'Please enter a valid email.',
  passwordLength: 'Please enter a password with at least 8 characters.',
};

const isEmptyValidation = (v) => (v.trim() === '' ? VALIDATION_MESSAGES.requiredField : null);

const validName = (v) => (['.', '@'].some((c) => v.includes(c)) ? VALIDATION_MESSAGES.validName : null);

const passwordLengthValidation = (v) => (v.length < 8 ? VALIDATION_MESSAGES.passwordLength : null);

const validEmailValidation = (v) => (false ? VALIDATION_MESSAGES.validEmail : null);

const fieldValidation = {
  firstName: [(v) => isEmptyValidation(v), (v) => validName(v)],
  lastName: [(v) => isEmptyValidation(v), (v) => validName(v)],
  email: [(v) => isEmptyValidation(v), (v) => validEmailValidation(v)],
  password: [(v) => isEmptyValidation(v), (v) => passwordLengthValidation(v)],
};

export const signUpValidation = (form) => {
  const formErrors = {};
  const fieldNames = Object.keys(form);
  fieldNames.forEach((name) => {
    let errors = [];
    const validations = fieldValidation[name];
    if (Array.isArray(validations)) {
      validations.forEach((validation) => {
        const error = validation(form[name]);
        if (error) {
          errors.push(error);
        }
      });
    }
    if (errors.length > 0) {
      formErrors[name] = errors;
    }
  });
  return { errors: formErrors, isValid: Object.keys(formErrors).length === 0 };
};
