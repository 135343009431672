import React from 'react';
import styled from 'styled-components';
import LinkTo from '../Links/LinkTo';
import {
  fetchFurtherDocumentationStatus,
  hideSearchHeader,
  logMetricsTrackingEvent,
  showSearchHeader,
  showVerticalNav,
} from '../../actions';
import IconButton from '../../components/buttons/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import NavItem from './components/NavItem';
import SearchContainer from '../UI/Search/SearchContainer';
import OpenPortfolioOptimizationPanelButton from '../buttons/Nav/OpenPortfolioOptimizationPanelButton';
import KarmaHeaderDisplay from '../Karma/KarmaHeaderDisplay';
import UserProfileNav from './components/UserProfileNav';
import { useNavigate, useLocation } from 'react-router-dom';
import { useNextLesson } from '../../main/hooks/learn/useNextLesson';
import { ROUTES } from '../../constants/paths';
import { Counter } from '../UI/Counter';
import { GraduationCap } from '../../main/assets/svgs';
import SearchIcon from '@src/main/components/Images/SearchIcon';
import { useCurrentUser, useIsOnboarding } from '../../main/hooks/user';
import { useIsAccountClosed } from '../../main/hooks/user/useIsAccountClosed';
import LoadingIcon from '../../components/misc/LoadingIcon';
import { useWindowSize } from '../../main/hooks/util';
import { createQueryString, parseQueryString } from '../../helpers/routerHelpers';
import { FullLogo } from '@src/main/components/logo/FullLogo';
import { MobileLogo } from '@src/main/components/logo/MobileLogo';

const LogoShortWrapper = styled.div`
  line-height: 1;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  svg {
    height: 20px;
    g {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 64px;
  line-height: 1;
  padding-top: 2px;
`;

const LoadingNavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 64px;
`;

const MobileNavBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 64px;
`;

const MobileNavLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 64px;
`;

const NavActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 64px;
  padding-left: 8px;

  > div {
    margin-right: 16px;

    @media (max-width: 460px) {
      margin-right: 8px;
    }
  }
  > div:last-child {
    margin-right: 0;
  }

  #nav_securities_search_container {
    width: 280px;

    @media (max-width: 660px) {
      width: 180px;
    }
  }
`;

const HeaderWrapper = styled.header`
  height: 64px;
  background-color: ${({ theme }) => theme.themeColors.header};
  width: 100%;
  box-shadow: none;
  border: none;
  border-bottom: ${({ theme }) => `1px solid ${theme.themeColors.lowContrastBorder}`};

  svg {
    g {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

const HeaderBodyWrapper = styled.div`
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 24px;

  @media (max-width: 976px) {
    padding: 0 16px;
  }

  box-shadow: none;
  border: none;
`;

const _logHomeClick = () => {
  const event = 'Clicked Link To Root';
  logMetricsTrackingEvent(event)();
};

const _logSecuritySearchSelectionInNav = () => {
  const event = 'Open Security Panel';
  const properties = {
    Component: 'Navbar Search',
    Context: 'Security Card',
  };
  logMetricsTrackingEvent(event, properties)();
};

const ActiveSearchBodyWrapper = styled.div`
  padding-left: 16px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const SimpleHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ActiveSearchBarWrapper = styled.div`
  width: 100%;
  padding-right: 16px;

  #nav_securities_search_container {
    width: 100% !important;
  }
`;

const SearchIconWrap = styled.div`
  cursor: pointer;
  div {
    width: 18px;
    height: 18px;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

const LearnIconWrapper = styled.div`
  position: relative;
  cursor: pointer;

  .counter {
    position: absolute;
    top: 0px;
    right: -4px;

    height: 12px;
    min-width: 12px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const ActionRequiredWrapper = styled.div`
  position: relative;
  cursor: pointer;
  padding-top: 4px;
  padding-left: 1px;

  .counter {
    position: absolute;
    top: 0;
    right: -4px;

    height: 12px;
    min-width: 12px;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

const searchInputToIconWindowWidthThreshold = 550;

const LearnLink = () => {
  const navigate = useNavigate();
  const { nextLessonId, areNoMoreLessons } = useNextLesson();
  const hasLessons = nextLessonId > 0;

  const handleClick = () => {
    if (hasLessons) {
      navigate(ROUTES.LEARN_AND_EARN_NEXT_LESSON.build());
    } else if (areNoMoreLessons) {
      navigate(ROUTES.LEARN_AND_EARN_HOME.build());
    } else {
      navigate(ROUTES.LEARN_AND_EARN_NEXT_LESSON.build());
    }
  };

  return (
    <LearnIconWrapper onClick={handleClick}>
      {hasLessons && <Counter count={0} showDotOnly />}
      <GraduationCap />
    </LearnIconWrapper>
  );
};

const VerifyInfoAction = ({ children }) => {
  const navigate = useNavigate();

  const [actionRequired, setActionRequired] = React.useState(false);

  const handleClick = () => {
    navigate(ROUTES.UPDATE_VERIFICATION_INFO.build());
  };

  React.useEffect(() => {
    const setup = async () => {
      const { isRejected: isSocureRejected, isUserActionRequired } = await fetchFurtherDocumentationStatus();
      if (isUserActionRequired) {
        setActionRequired(true);
      }
    };
    setup();
  }, []);

  return actionRequired ? (
    <span onClick={handleClick}>
      <NavItem>
        <ActionRequiredWrapper>
          <Counter count={0} showDotOnly />
          <svg viewBox="0 0 24 24">
            <path d="M22,3H2C0.91,3.04 0.04,3.91 0,5V19C0.04,20.09 0.91,20.96 2,21H22C23.09,20.96 23.96,20.09 24,19V5C23.96,3.91 23.09,3.04 22,3M22,19H2V5H22V19M14,17V15.75C14,14.09 10.66,13.25 9,13.25C7.34,13.25 4,14.09 4,15.75V17H14M9,7A2.5,2.5 0 0,0 6.5,9.5A2.5,2.5 0 0,0 9,12A2.5,2.5 0 0,0 11.5,9.5A2.5,2.5 0 0,0 9,7M14,7V8H20V7H14M14,9V10H20V9H14M14,11V12H18V11H14" />
          </svg>
        </ActionRequiredWrapper>
      </NavItem>
    </span>
  ) : null;
};

const SearchInputContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleSecurityQuerySelection = (security) => {
    if (!security) {
      return console.error('No security was received', security);
    }

    hideSearchHeader()(dispatch);

    const pathname = location.pathname;
    const query = location.search;
    const queryObj = parseQueryString(query);

    const showInPanel = queryObj.panel || pathname.indexOf('/securities/') < 0;
    if (showInPanel) {
      const query = {
        panel: 'security',
        securityId: security.id,
      };
      const queryString = createQueryString(query);
      navigate(location.pathname + queryString);
      _logSecuritySearchSelectionInNav();
    } else {
      // View Quote event triggers in Security Heading
      navigate(`/securities/${security.id}`);
    }
    window.$('input').blur();
  };

  return (
    <SearchContainer
      id="nav_securities_search"
      icon="fa-search"
      parentComponent="Navbar"
      placeholder="Search Stocks..."
      handleSecurityQuerySelection={handleSecurityQuerySelection}
    />
  );
};

const Search = () => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const showMobileVersion = width < 552;

  const handleMobileSearchIconClick = () => showSearchHeader()(dispatch);

  return showMobileVersion ? (
    <div onClick={handleMobileSearchIconClick}>
      <SearchIconWrap>
        <SearchIcon />
      </SearchIconWrap>
    </div>
  ) : (
    <SearchInputContainer />
  );
};

const EmptyNav = () => {
  return (
    <HeaderWrapper>
      <HeaderBodyWrapper>
        <LogoWrapper>
          <LinkTo
            to={'/'}
            additionalClickAction={_logHomeClick}
            child={<FullLogo isSmall />}
            fullPageLoad
            scrollToTop
          />
        </LogoWrapper>
      </HeaderBodyWrapper>
    </HeaderWrapper>
  );
};

const LoadingNav = () => {
  return (
    <HeaderWrapper>
      <HeaderBodyWrapper>
        <LoadingNavWrapper>
          <LogoWrapper>
            <FullLogo isSmall />
          </LogoWrapper>
          <div style={{ paddingRight: '24px', paddingBottom: '6px' }}>
            <LoadingIcon icon="fading-3balls" size="small" />
          </div>
        </LoadingNavWrapper>
      </HeaderBodyWrapper>
    </HeaderWrapper>
  );
};

const HamburgerBuns = styled.div`
  margin-left: 0;
  margin-right: 16px;
  cursor: pointer;
`;

const OptimizerButtonWrapper = styled.div`
  @media (max-width: 976px) {
    display: none;
  }
`;

const HamburgerPatty = styled.div`
  display: block;
  background-color: ${({ theme }) => theme.themeColors.text};
  height: 2px;
  width: 20px;

  margin: 4px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
`;

const NavHamburger = () => {
  const dispatch = useDispatch();

  const handleHamburgerClick = () => {
    showVerticalNav()(dispatch);
  };

  return (
    <HamburgerBuns onClick={handleHamburgerClick}>
      <HamburgerPatty />
      <HamburgerPatty />
      <HamburgerPatty />
    </HamburgerBuns>
  );
};

export const HorizontalNav = (props) => {
  const { usingSpecialAccessToken, forceEmptyNav, forceLoading } = props;

  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const currentUser = useCurrentUser();
  const isOnboarding = useIsOnboarding();
  const isAccountClosed = useIsAccountClosed();
  const isPublic = !currentUser || !currentUser.id;

  const isNavSearchActive = useSelector((state) => state.nav.showSearchHeader);

  const isMobile = width < 976;

  const hideNavActiveSearch = () => hideSearchHeader()(dispatch);

  if (usingSpecialAccessToken || forceEmptyNav) {
    return <EmptyNav />;
  }

  if (forceLoading) {
    return <LoadingNav />;
  }

  if (isAccountClosed) {
    if (isMobile) {
      return (
        <HeaderWrapper>
          <HeaderBodyWrapper>
            <MobileNavBody>
              <MobileNavLeft>
                <NavHamburger />
                <LogoWrapper>
                  <LogoShortWrapper>
                    <LinkTo
                      to={'/'}
                      additionalClickAction={_logHomeClick}
                      child={<MobileLogo />}
                      fullPageLoad={false}
                      scrollToTop
                    />
                  </LogoShortWrapper>
                </LogoWrapper>
              </MobileNavLeft>
              <NavActionsWrapper>
                <UserProfileNav />
              </NavActionsWrapper>
            </MobileNavBody>
          </HeaderBodyWrapper>
        </HeaderWrapper>
      );
    } else {
      return (
        <HeaderWrapper>
          <HeaderBodyWrapper>
            <SimpleHeaderWrapper>
              <LogoWrapper />
              <NavActionsWrapper>
                <UserProfileNav />
              </NavActionsWrapper>
            </SimpleHeaderWrapper>
          </HeaderBodyWrapper>
        </HeaderWrapper>
      );
    }
  }

  if (isOnboarding || isPublic) {
    return (
      <HeaderWrapper>
        <HeaderBodyWrapper>
          <SimpleHeaderWrapper>
            <LogoWrapper>
              <FullLogo isSmall />
            </LogoWrapper>
            {!isPublic && (
              <NavActionsWrapper>
                <UserProfileNav />
              </NavActionsWrapper>
            )}
          </SimpleHeaderWrapper>
        </HeaderBodyWrapper>
      </HeaderWrapper>
    );
  }

  if (isMobile) {
    return (
      <HeaderWrapper>
        <HeaderBodyWrapper>
          <MobileNavBody>
            <MobileNavLeft>
              <NavHamburger />
              <LogoWrapper>
                <LogoShortWrapper>
                  <LinkTo
                    to={'/'}
                    additionalClickAction={_logHomeClick}
                    child={<MobileLogo />}
                    fullPageLoad={false}
                    scrollToTop
                  />
                </LogoShortWrapper>
              </LogoWrapper>
            </MobileNavLeft>
            {isNavSearchActive ? (
              <ActiveSearchBodyWrapper>
                <ActiveSearchBarWrapper>
                  <SearchInputContainer />
                </ActiveSearchBarWrapper>
                <div>
                  <IconButton
                    customClass="no-shadow"
                    icon="fa-times-thin"
                    size="medium"
                    colorClassName="secondary-text-color"
                    handleClick={hideNavActiveSearch}
                  />
                </div>
              </ActiveSearchBodyWrapper>
            ) : (
              <NavActionsWrapper>
                <Search />
                <OptimizerButtonWrapper>
                  <OpenPortfolioOptimizationPanelButton />
                </OptimizerButtonWrapper>
                <LearnLink />
                <VerifyInfoAction />
                <KarmaHeaderDisplay />
                <UserProfileNav />
              </NavActionsWrapper>
            )}
          </MobileNavBody>
        </HeaderBodyWrapper>
      </HeaderWrapper>
    );
  }

  return (
    <HeaderWrapper>
      <HeaderBodyWrapper>
        <NavActionsWrapper>
          <Search />
          <OptimizerButtonWrapper>
            <OpenPortfolioOptimizationPanelButton />
          </OptimizerButtonWrapper>
          <LearnLink />
          <VerifyInfoAction />
          <KarmaHeaderDisplay />
          <UserProfileNav />
        </NavActionsWrapper>
      </HeaderBodyWrapper>
    </HeaderWrapper>
  );
};
