import React from 'react';

import SecurityIdentifierDisplay from '../../../../UI/SecurityIdentifierDisplay';
import BasicSecurityDataWrapper from '../../../../SecurityData/BasicSecurityDataWrapper';

import {
  returnDisplayPhraseStringOrderOperationPresentTense,
  isOrderASell,
  isOrderACover,
  isOrderOperationOfTypeBuying,
  getOperationFromOrderData,
} from '../../../../../helpers/ordersHelpers';
import { toCapitalCase } from '../../../../../helpers/generalHelpers';

export const OrderInNotificationRow = (props) => {
  const { order, orderIndex, orders, position } = props;

  const { before, after } = position;

  const orderCountInSequence = orders.length;
  const isMultiOrderSequence = orderCountInSequence > 1;
  const isLastOrderInSequenceForSecurity = orderIndex + 1 === orderCountInSequence;

  const securityId = order.security_id;
  const orderOperation = getOperationFromOrderData(order);

  const wasASellOrderInSequence = orders.some((order) => isOrderASell(order));
  const wasACoverOrderInSequence = orders.some((order) => isOrderACover(order));

  const didExit = after === 'flat';
  const shouldShowExitMessage = didExit && isLastOrderInSequenceForSecurity;
  const exitMessageString = shouldShowExitMessage ? 'exited' : '';

  const stillLong = before === 'long' && after === 'long';
  const stillShort = before === 'short' && after === 'short';

  const didReversePosition = (before === 'short' && after === 'long') || (before === 'long' && after === 'short');
  const didLessenPositionSize = (stillLong && wasASellOrderInSequence) || (stillShort && wasACoverOrderInSequence);

  const useTimeContextString = didReversePosition || didLessenPositionSize;
  const timeContextString = didReversePosition ? 'now' : 'still';

  const positionTypeString = after;

  const orderOperationPhrase = returnDisplayPhraseStringOrderOperationPresentTense(orderOperation);
  const orderOperationPhraseTextClassName = isOrderOperationOfTypeBuying(orderOperation)
    ? 'buy-text-color'
    : 'sell-text-color';
  const positionTextClassName = positionTypeString === 'long' ? 'buy-text-color' : 'sell-text-color';

  return (
    <tr className={`order-actions-list-item-container`}>
      <td className={``}>
        <span className={orderOperationPhraseTextClassName}>{orderOperationPhrase}</span>
      </td>
      <td className={``}>
        <div className={`order-action-list-item-security-identifier-container`}>
          <BasicSecurityDataWrapper securityId={securityId}>
            <SecurityIdentifierDisplay prefix={'('} suffix={')'} showSymbol showName limitNameWidth inFeed />
          </BasicSecurityDataWrapper>
        </div>
      </td>
      <td>
        {isLastOrderInSequenceForSecurity && (useTimeContextString || shouldShowExitMessage) && (
          <div className={`phrase`}>
            <span>(</span>
            {useTimeContextString && (
              <span>
                <span>{`${timeContextString}`}</span>
                <span>&nbsp;</span>
                <span className={positionTextClassName}>{`${positionTypeString}`}</span>
              </span>
            )}
            {shouldShowExitMessage && exitMessageString}
            <span>)</span>
          </div>
        )}
      </td>
    </tr>
  );
};
