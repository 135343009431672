import React from 'react';
import RebalancerButton from './RebalancerButton';
import OptimizerButton from './OptimizerButton';
import { getIsUsingRebalancerForPortfolioOptimization } from '../../../helpers/portfolioOptimizationHelpers';

class OpenPortfolioOptimizationPanelButton extends React.PureComponent {
  render() {
    const isUsingRebalancerForOptimization = getIsUsingRebalancerForPortfolioOptimization();

    return isUsingRebalancerForOptimization ? (
      <RebalancerButton {...this.props} />
    ) : (
      <OptimizerButton {...this.props} />
    );
  }
}

export default OpenPortfolioOptimizationPanelButton;
