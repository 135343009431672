import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import ThoughtsDataContainer from '../Thoughts/ThoughtsDataContainer';
import ThoughtsContainer from '../Thoughts/ThoughtsContainer';
import DropdownStringOptions from '../StandaloneInputs/DropdownWithStrings';
import DropdownObjectOptions from '../StandaloneInputs/DropdownWithObjects';
import Checkbox from '../StandaloneInputs/Checkbox';

import { returnCurrentUserId, returnUserConnectionsFromUserConnectionsStore } from '../../helpers/currentUserHelpers';
import { userFullName, sortCurrentUserToTop, convertUserIdsToUsers } from '../../helpers/usersHelpers';
import { returnAllUniqUserIdsWhoHaveOpinionsOnThoughtsForSecurity } from '../../helpers/thoughtsHelpers';

import { logMetricsTrackingEvent } from '../../actions/trackingActions';
import { getSubscribedViewpointIdsFromState, getViewpointLookupFromState } from '../../selectors/viewpoints';
import { getDisplayNameFromViewpointOrg } from '../../helpers/viewpointHelpers';
import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  fieldset {
    padding-top: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .standalone-checkbox-container label {
    padding: 0px 0 4px 8px;
  }
`;
const SortInputsWrapper = styled.div`
  select {
    background-color: transparent;
    -webkit-appearance: auto;
    border-bottom: 1px solid ${({ theme }) => theme.themeColors.text};
  }

  .dropdown-input-icons {
    display: none;
  }
`;

const ALL_CONNECTIONS_FILTER_OPTION = {
  id: 0,
  name: 'none',
  displayName: 'All Connections',
};

const CTL_FILTER_OPTION = {
  id: 'ctl',
  name: 'ctl',
  displayName: 'Community Thought Leaders',
};

const VIEWPOINTS_FILTER_OPTION = {
  id: 'viewpoints',
  name: 'viewpoints',
  displayName: 'Viewpoint Contributors',
};

const DEFAULT_CONNECTIONS_FILTER_VALUE = ALL_CONNECTIONS_FILTER_OPTION;

const CUSTOM_CONNECTIONS_FILTER_OPTIONS = [ALL_CONNECTIONS_FILTER_OPTION, CTL_FILTER_OPTION, VIEWPOINTS_FILTER_OPTION];

class ThoughtsListWithSortOptions extends React.Component {
  constructor() {
    super();
    this.state = {
      _sortBy: null,
      _filterByConnection: DEFAULT_CONNECTIONS_FILTER_VALUE,
      include_community_thoughts_filter: this._returnConnectionsThoughtFilterDefaultValue(),
    };
  }

  render() {
    return (
      <div className={'security-card-thoughts-panel-container'}>
        <ThoughtsDataContainer
          security={this._returnSecurity()}
          sortBy={this.returnThoughtsSortByKey()}
          sortOptionsComponent={this.renderSortOptionsComponent()}
          filterBy={this.returnThoughtsFilters()}
          flatLoadingStyle={this.props.flatLoadingStyle}
          getThoughtData
          {...(this.props.ThoughtsDataContainerProps || {})}
        >
          <ThoughtsContainer
            scrollContainerSelector={this.props.scrollContainerSelector}
            distanceFromBottomToTriggerInfiniteScroll={this.props.distanceFromBottomToTriggerInfiniteScroll}
            thoughtDisplayContext={'thoughtList'}
            useInfiniteScroll={this.props.useInfiniteScroll}
          />
        </ThoughtsDataContainer>
      </div>
    );
  }

  renderSortOptionsComponent = () => {
    return (
      <div className={'thoughts-sort-options-container'}>
        <div className={'thoughts-sort-options'}>
          <SortInputsWrapper className={'thoughts-sort-options-position-container'}>
            {this._renderSortThoughtsByDropdown()}
            {this.props.showConnectionsThoughtsFilter &&
              this._doConnectionsHaveThoughtsForSecurity() &&
              this._renderOnlyShowUserThoughtsFilter()}
            {this.props.showConnectionsThoughtsFilter &&
              this._doConnectionsHaveThoughtsForSecurity() &&
              this.props.isUserAuthed &&
              this._renderIncludeCommunityThoughtsFilter()}
          </SortInputsWrapper>
        </div>
      </div>
    );
  };

  _renderSortThoughtsByDropdown = () => {
    return (
      <div className={'thoughts-sort-filter-option-container'}>
        <DropdownStringOptions
          name={'thoughts_sort'}
          label={'Order By'}
          value={this._returnCurrentThoughtsSort()}
          options={this._returnSortOptions()}
          handleSelection={this._handleDropdownSelection}
          showArrowIcons
        />
      </div>
    );
  };

  _renderIncludeCommunityThoughtsFilter = () => {
    return (
      <CheckboxWrapper className={'thoughts-sort-filter-option-container filter-thoughts-by-container'}>
        <Checkbox
          name={'include_community_thoughts_filter'}
          label={'Include Community'}
          value={this._returnIncludeCommunityThoughtsFilterValue()}
          handleClick={this._handleCheckboxClick}
          isDisabled={this._isIncludeCommunityThoughtsFilterDisabled()}
          overrideValueWhenDisabledTo={false}
          useOverrideValueWhenDisabled
        />
      </CheckboxWrapper>
    );
  };

  _renderOnlyShowUserThoughtsFilter = () => {
    return (
      <div className={'thoughts-sort-filter-option-container filter-by-connections'}>
        <DropdownObjectOptions
          name={'thoughts_filter_by_user'}
          label={'Show From'}
          value={this._returnCurrentFilterByConnection()}
          options={this._returnOptionsListForFilterByConnections()}
          handleSelection={this._handleFilterByConnectionSelection}
          showArrowIcons
        />
      </div>
    );
  };

  _isIncludeCommunityThoughtsFilterDisabled = () => this._isThoughtsFromFilterActive();

  _returnSortOptions = () => ['Highest Rated', 'Most Recent'];

  _returnDefaultSortOption = () => 'Most Recent';

  _convertSortOptionDisplayToSortKey = (sortDisplayString) => {
    const dict = {
      'Highest Rated': 'currentUserNewThoughtsThenBestAgreeDisagreeDifferential',
      'Most Recent': 'mostRecent',
    };
    return dict[sortDisplayString];
  };

  _convertFilterNameToLogKey = (filterName) => {
    const dict = {
      include_community_thoughts_filter: 'Include Community Thoughts',
    };
    return dict[filterName];
  };

  returnThoughtsFilters = () => {
    const filters = [];
    if (!this._returnIncludeCommunityThoughtsFilterValue()) {
      filters.push({ name: 'include_community_thoughts_filter' });
    }
    if (this._isThoughtsFromFilterActive()) {
      filters.push({
        name: 'thoughts_from_filter_user_id',
        data: {
          viewpointOrgId: this._returnCurrentFilterByViewpointOrgId(),
          userId: this._returnCurrentFilterByConnectionUserId(),
          id: this._returnCurrentFilterByConnectionId(),
        },
      });
    }
    return filters;
  };

  _returnCurrentFilterByConnection = () => this.state._filterByConnection;

  _returnCurrentFilterByViewpointOrgId = () => this._returnCurrentFilterByConnection().viewpointOrgId;

  _returnCurrentFilterByConnectionUserId = () => this._returnCurrentFilterByConnection().userId;

  _returnCurrentFilterByConnectionId = () => this._returnCurrentFilterByConnection().id;

  _isThoughtsFromFilterActive = () => this._returnCurrentFilterByConnectionId() !== 0;

  _returnOptionsListForFilterByConnections = () => {
    const { subscribedViewpointIds } = this.props;
    const currentUserAndConnectionsUsersWhoHaveThoughts =
      this._returnCurrentUserAndConnectionsUsersWhoHaveThoughtsForSecurity();
    const users = sortCurrentUserToTop(currentUserAndConnectionsUsersWhoHaveThoughts, this._returnCurrentUserId(), {
      sort: 'alphabetically',
    });
    const subscribedViewpoints = subscribedViewpointIds.map((id) => this.props.viewpointOrgLookup[id]);

    return [
      ...CUSTOM_CONNECTIONS_FILTER_OPTIONS,
      ...subscribedViewpoints.map((org) => ({
        viewpointOrgId: org.id,
        id: org.id,
        name: org.id,
        displayName: getDisplayNameFromViewpointOrg(org),
        isEnabled: () => true,
        disabledReason: () => null,
      })),
      ...users.map((user) => ({
        userId: user.user_id,
        id: user.user_id,
        name: user.user_id,
        displayName: user.user_id === this._returnCurrentUserId() ? 'Me' : userFullName(user),
        isEnabled: () => true,
        disabledReason: () => null,
      })),
    ];
  };

  _handleFilterByConnectionSelection = (name, selection) => {
    this._setFilterConnectionBy(selection);
    this._logFilterConnectionBySelection(selection);
  };

  _handleDropdownSelection = (name, selection) => {
    this._setSortBy(selection);
    this._logSortOptionSelection(selection);
  };

  _handleCheckboxClick = (name) => {
    const value = !this._returnIncludeCommunityThoughtsFilterValue();
    this._toggleFilterValue(name);
    this._logFilterClick(name, value);
  };

  _setSortBy = (sort) => {
    this.setState(() => ({
      _sortBy: sort,
    }));
  };

  _setFilterConnectionBy = (value) => {
    this.setState(() => ({
      _filterByConnection: value,
    }));
  };

  _toggleFilterValue = (name) => {
    this.setState((prevState) => ({
      [name]: !prevState[name],
    }));
  };

  _returnUserIdsWhoHaveThoughtsForSecurity = () =>
    returnAllUniqUserIdsWhoHaveOpinionsOnThoughtsForSecurity(this._returnThoughtsStore(), this._returnSecurityId());

  _returnCurrentUserAndConnectionsUserIdsWhoHaveThoughtsForSecurity = () => {
    return this._returnUserIdsWhoHaveThoughtsForSecurity().filter((id) =>
      [this._returnCurrentUserId(), ...this._returnCurrentUserConnectionUserIds()].includes(id)
    );
  };

  _returnCurrentUserAndConnectionsUsersWhoHaveThoughtsForSecurity = () =>
    convertUserIdsToUsers(
      this._returnCurrentUserAndConnectionsUserIdsWhoHaveThoughtsForSecurity(),
      this._returnUsersStore(),
      this._returnCurrentUser()
    );

  _doConnectionsHaveThoughtsForSecurity = () =>
    this._returnCurrentUserAndConnectionsUserIdsWhoHaveThoughtsForSecurity().length > 0;

  returnThoughtsSortByKey = () => this._convertSortOptionDisplayToSortKey(this._returnCurrentThoughtsSort());

  _returnCurrentThoughtsSort = () => this.state._sortBy || this._returnDefaultSortOption();

  _returnConnectionsThoughtFilterDefaultValue = () => true;

  _returnIncludeCommunityThoughtsFilterValue = () => this.state.include_community_thoughts_filter;

  _returnSecurity = () => this.props.security;

  _returnSecurityId = () => this._returnSecurity().security_id;

  _returnCurrentUserConnectionUsers = () =>
    returnUserConnectionsFromUserConnectionsStore(
      this._returnCurrentUserId(),
      this._returnUserConnectionStore(),
      this._returnUsersStore()
    );

  _returnCurrentUserConnectionUserIds = () => this._returnCurrentUserConnectionUsers().map((user) => user.user_id);

  _returnThoughtsStore = (props) => (props || this.props).thoughts;

  _returnUsersStore = (props) => (props || this.props).users;

  _returnUserConnectionStore = (props) => (props || this.props).userConnections;

  _returnCurrentUser = () => this.props.currentUser;

  _returnCurrentUserId = () => returnCurrentUserId(this.props.currentUser);

  _logSortOptionSelection = (selection) => {
    const event = 'Changed Pro/Con Sort';
    const properties = {
      Sort: selection,
      ...(this.props.viewingContext ? { Context: this.props.viewingContext } : {}),
    };
    logMetricsTrackingEvent(event, properties)();
  };

  _logFilterConnectionBySelection = (selection = {}) => {
    const { viewpointOrgId, user_id, id } = selection;
    const userId = user_id || id === 'ctl' ? 'CTL' : 'none';
    const event = 'Changed Connection Thoughts Filter';
    const properties = {
      'Viewpoint Org ID': viewpointOrgId,
      'User ID': userId,
      ...(this.props.viewingContext ? { Context: this.props.viewingContext } : {}),
    };
    logMetricsTrackingEvent(event, properties)();
  };

  _logFilterClick = (filterName, value) => {
    const event = 'Toggled Thoughts Filter';
    const properties = {
      Name: this._convertFilterNameToLogKey(filterName),
      Value: value,
      ...(this.props.viewingContext ? { Context: this.props.viewingContext } : {}),
    };
    logMetricsTrackingEvent(event, properties)();
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    users: state.users,
    userConnections: state.userConnections,
    thoughts: state.thoughts,
    subscribedViewpointIds: getSubscribedViewpointIdsFromState(state),
    viewpointOrgLookup: getViewpointLookupFromState(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThoughtsListWithSortOptions);
