import { Body5, Warning } from '../../lib/nvstr-common-ui.es';
import React from 'react';
import styled from 'styled-components';

const WarningIconWrapper = styled.div`
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  svg {
    height: 16px;
    width: 16px;

    path {
      opacity: 0.6;
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const WarningNotice = ({ message }) => {
  return (
    <Row>
      <WarningIconWrapper>
        <Warning />
      </WarningIconWrapper>
      <Row>
        <Body5 isSmall isLowContrast>
          {message}
        </Body5>
      </Row>
    </Row>
  );
};
