import React from 'react';

import { includes, findIndexOfObjectInArray } from '../../helpers/generalHelpers';

import SelectionOption from './subComponents/SelectionOption';

export const Selection = (props) => {
  const customStyle = props.style;
  const name = props.name;
  const label = props.label;
  const note = props.note;
  const valuesSelected = props.getValue(name) || [];

  let selectionOptions, selectionOptionData, selectionOptionField;
  if (props.fetchSelectionOptions) {
    selectionOptionData = props.getSelectionOptions();
    selectionOptionField = props.getSelectionFieldName();
    if (selectionOptionData) {
      selectionOptions = selectionOptionData.map((d) => props.getDisplaySelectionLabel(selectionOptionField, d));
    } else {
      selectionOptions = [];
    }
  } else {
    selectionOptions = props.selectionOptions;
  }

  const isError = !!props.getErrorMessage(name);
  const errorMessage = isError ? props.getErrorMessage(name) : '';
  const errorHighlight = isError ? 'error-input' : '';
  const hideElement = !!note ? '' : 'hidden';
  const styling = ('styling' in props && props.styling) || 'selection-field';

  const isSelected = (i, value) => {
    return props.isSelected(i, selectionOptionData, valuesSelected) || includes(value, valuesSelected);
  };

  if (!selectionOptions) {
    return <div>Loading</div>;
  }

  return (
    <fieldset className={`${styling} scrollable`}>
      <div name={name} className={errorHighlight}>
        {selectionOptions.map((value, i) => (
          <SelectionOption
            key={`${name}-${i}`}
            obj={props.fetchSelectionOptions && selectionOptionData[i]}
            value={value}
            inputName={name}
            handleSelectionClick={props.handleSelectionClick}
            selected={isSelected(i, value)}
          />
        ))}
      </div>

      {isError && <p className={`text-field-error-message`}>{errorMessage}</p>}
    </fieldset>
  );
};

export default Selection;
