import React from 'react';
import { useNextLesson } from './useNextLesson';
import { fetchNextLearnLesson, postLearnLessonProgress, showModal } from '../../../actions';
import { TrackingEvents } from '../../../utils/tracking/events';
import { createBasicErrorModal } from '../../../constants/modals';
import { useDispatch } from 'react-redux';
import { useLearnLesson } from './useLearnLesson';
import { useLessonRewardData } from './useLessonRewardData';

export const useLearnLessonState = (lessonId, isPublic) => {
  const dispatch = useDispatch();

  const lessonData = useLearnLesson(lessonId);
  const { name: lessonName, lessonContents, sponsorId } = lessonData;
  const { completedLessonCount: apiCompletedLessonCount } = useNextLesson();
  const { lessonReward, willLessonCompletionGrantReward, lessonsAwayFromNextReward } = useLessonRewardData();

  const [completedLessonCount, setCompletedLessonCount] = React.useState(apiCompletedLessonCount || 0);
  const [lockedRewardsAmount, setLockedRewardsAmount] = React.useState(null);
  const [rewardAmount, setRewardAmount] = React.useState(null);
  const [earnedXPOnly, setEarnedXPOnly] = React.useState(null);
  const [completedPublicLesson, setCompletedPublicLesson] = React.useState(false);
  const [isCompletingLesson, setIsCompletingLesson] = React.useState(false);

  const onComplete = async () => {
    if (isPublic) {
      setCompletedPublicLesson(true);
      TrackingEvents.learn.COMPLETE_LEARN_LESSON.send({
        Subject: lessonName,
        'Lesson ID': lessonId,
        'Sponsor ID': sponsorId,
        'Reward Amount': null,
        'Locked Rewards': null,
      });
      return;
    }

    setIsCompletingLesson(true);

    const response = await postLearnLessonProgress({
      lesson_id: lessonId,
      page_number: lessonContents?.length || 0,
      completed: true,
    })(dispatch);
    const { ok, data, errorMessage } = response;
    if (ok) {
      await fetchNextLearnLesson()(dispatch); // get data for next lesson

      setCompletedLessonCount(completedLessonCount + 1);

      const { reward_amount: rewardAmountEarned, value: xpValue, locked_rewards } = data;

      setLockedRewardsAmount(locked_rewards);

      if (rewardAmountEarned > 0) {
        setRewardAmount(rewardAmountEarned);
      } else {
        setEarnedXPOnly(true);
      }

      TrackingEvents.learn.COMPLETE_LEARN_LESSON.send({
        Subject: lessonName,
        'Lesson ID': lessonId,
        'Sponsor ID': sponsorId,
        'Reward Amount': rewardAmountEarned,
        'Locked Rewards': locked_rewards,
      });
    } else {
      const modal = {
        contentComponent: createBasicErrorModal(errorMessage),
        size: 'wide',
        dismissable: true,
      };
      showModal(modal)(dispatch);

      setIsCompletingLesson(false);
    }
  };

  return {
    rewardAmount,
    earnedXPOnly,
    lockedRewardsAmount,
    completedLessonCount,
    isCompletingLesson,
    completedPublicLesson,
    lessonReward,

    onComplete,
  };
};
