import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components/layout';
import { sendFacebookTrackingEvent } from '../../../constants/facebookTracking';
import { logMetricsTrackingEvent, verifyBankAccount } from '../../../actions';
import { FlatButton } from '../../components/buttons';
import {
  H3,
  Body5,
  createDropdownItem,
  FloatingLabelDropdown,
  FloatingLabelTextInput,
  INPUT_MASK_TYPES,
} from '../../lib/nvstr-common-ui.es';
import { colorPalette } from '../../lib/nvstr-utils.es';
import { FormState } from '../../components/form/FormState';
import { customParseFloat } from '../../../helpers/numberHelpers';
import { useDispatch } from 'react-redux';
import { useBankAccounts } from '../../hooks/funding/useBankAccounts';
import { CheckCircleFilled } from '../../icons/svg/CheckCircleFilled';
import { ROUTES } from '../../../constants/paths';
import { pluralizeIfAppropriate } from '../../../helpers/generalHelpers';
import { isUndefinedOrNull } from '../../../helpers/usefulFuncs';
import { Container } from '@src/main/components/ui';

const Content = styled.div`
  h3 {
    margin: 0;
  }
`;
const InputWrapper = styled.div`
  padding-top: 40px;

  select {
    border-top: none;
    border-right: none;
    border-left: none;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
  input {
    border: none;

    -webkit-appearance: auto;
    background: transparent !important;
  }
`;
const ActionsWrapper = styled.div`
  padding-top: 24px;
`;
const Error = styled.div`
  padding-bottom: 8px;

  * {
    color: ${colorPalette.secondary.red};
  }
`;

const IconWrapper = styled.div`
  padding-top: 36px;
  text-align: center;
  svg {
    height: 36px;
    width: 36px;
  }
  path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

export const VerifyBankAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [bankAccountId, setBankAccountId] = React.useState(null);
  const [bankAccountFilter] = React.useState({ isVerificationReady: true });

  const bankAccounts = useBankAccounts(bankAccountFilter);

  React.useEffect(
    () => {
      if (bankAccounts.length === 1) {
        const bankAccount = bankAccounts[0];
        setBankAccountId(bankAccount.id);
      }
    },
    [bankAccounts]
  );

  const [amount1, setAmount1] = React.useState('0.');
  const [amount2, setAmount2] = React.useState('0.');

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);
  const [secondaryError, setSecondaryError] = React.useState(null);
  const [verificationSuccess, setVerificationSuccess] = React.useState(false);

  const handleAmount1Change = (n) => (v) => {
    setAmount1(v);
  };
  const handleAmount2Change = (n) => (v) => {
    setAmount2(v);
  };
  const handleBankAccountChange = (n) => (v) => {
    setBankAccountId(v);
  };

  const validateValues = () => {
    if (['', '--', null].includes(bankAccountId)) {
      setFormError('Please select a bank account.');
      return false;
    }

    if (!isNaN(amount1) && customParseFloat(amount1) > 0 && !isNaN(amount2) && customParseFloat(amount2) > 0) {
      setFormError(null);
      return true;
    } else {
      setFormError('Both amounts are required and should be less than one dollar');
      return false;
    }
  };

  const handleContinue = async () => {
    navigate(ROUTES.FUNDING.build());
  };

  const handleSubmit = async () => {
    logMetricsTrackingEvent('Submitted Micro Transaction Amounts')();

    setFormError(null);
    setSecondaryError(null);
    const isValid = validateValues();
    if (!isValid) {
      logMetricsTrackingEvent('Submitted Micro Transaction Amounts With Form Errors')();
      return null;
    }

    setIsSubmitting(true);

    const form = {
      id: bankAccountId,
      amount1: parseFloat(amount1),
      amount2: parseFloat(amount2),
    };
    const { ok, errorMessage, microDepositAttemptsRemaining } = await verifyBankAccount(form)(dispatch);
    if (ok) {
      const params = {};
      sendFacebookTrackingEvent('Completed Microdeposit Bank Account Registration', params);
      logMetricsTrackingEvent('Submitted Correct Micro Transaction Amounts')();
      setVerificationSuccess(true);
    } else {
      let secondaryError = null;

      if (isUndefinedOrNull(microDepositAttemptsRemaining)) setFormError(errorMessage);

      if (errorMessage === 'Microdeposits are not ready yet') {
        const message = 'You must re-link this bank account';
        setFormError(message);
      }

      if (microDepositAttemptsRemaining >= 0) {
        logMetricsTrackingEvent('Submitted Incorrect Micro Transaction Amounts', {})();
      }

      if (microDepositAttemptsRemaining === 0) {
        const message =
          'You have reached the maximum amount of verification requests. Please re-add the bank account to start the add bank account verification process again.';
        setFormError(message);
      }

      if (microDepositAttemptsRemaining > 0) {
        const message = `The amounts entered were not correct. Please double check your account and try again. Please note, the order matters. The first amount value should be the first amount deposited in your bank account.`;
        setFormError(message);

        const secondaryError = `${microDepositAttemptsRemaining} ${pluralizeIfAppropriate(
          'attempt',
          microDepositAttemptsRemaining,
          { ignorePrefix: true }
        )} remaining.`;
        setSecondaryError(secondaryError);
      }

      if (secondaryError) setSecondaryError(secondaryError);

      setIsSubmitting(false);
    }
  };

  const bankAccountItems = [
    createDropdownItem({
      text: 'Please select',
      value: '',
    }),
    createDropdownItem({
      text: '--',
      value: '--',
    }),
    ...bankAccounts.map((a) =>
      createDropdownItem({
        text: `${a.account_type} (...${a.account_number_ending})`,
        value: a.id,
      })
    ),
  ];

  if (verificationSuccess) {
    return (
      <Page width={'600px'}>
        <Content>
          <IconWrapper>
            <CheckCircleFilled />
            <H3>Verification Completed</H3>
          </IconWrapper>
          <ActionsWrapper>
            <Container fullWidth centerAll>
              <FlatButton fullWidth onClick={handleContinue}>
                Continue
              </FlatButton>
            </Container>
          </ActionsWrapper>
        </Content>
      </Page>
    );
  }

  if (bankAccounts.length === 0) {
    return (
      <Page width={'600px'}>
        <Content>
          <H3>Verify Bank Account</H3>
          <div>
            <Body5 isLowContrast isSmall>
              You have no bank accounts needing or ready for verification at this time.
            </Body5>
          </div>
        </Content>
      </Page>
    );
  }

  return (
    <Page width={'600px'}>
      <Content>
        <H3>Verify Bank Account</H3>
        <div>
          <Body5 isLowContrast isSmall>
            For Security, two micro-deposits were deposited and withdrawn from your account recently. You will have to
            verify these amounts to confirm account ownership.
          </Body5>
        </div>

        <div>
          <InputWrapper>
            <FloatingLabelDropdown
              name={'bankAccount'}
              value={bankAccountId}
              label={'Bank Account'}
              items={bankAccountItems}
              onChange={handleBankAccountChange}
              error={null}
            />
          </InputWrapper>
          <InputWrapper>
            <FloatingLabelTextInput
              name={'amount1'}
              value={amount1}
              prefix={'$'}
              label={'First Amount'}
              onChange={handleAmount1Change}
              maskType={INPUT_MASK_TYPES.centsAmount}
              error={null}
              autoFocus
            />
          </InputWrapper>
          <InputWrapper>
            <FloatingLabelTextInput
              name={'amount2'}
              value={amount2}
              prefix={'$'}
              label={'Second Amount'}
              onChange={handleAmount2Change}
              maskType={INPUT_MASK_TYPES.centsAmount}
              error={null}
            />
          </InputWrapper>
        </div>
        <ActionsWrapper>
          <FormState error={formError} isSubmitting={isSubmitting} />
          {secondaryError && (
            <Error>
              <Body5>{secondaryError}</Body5>
            </Error>
          )}
          <Container fullWidth centerAll>
            <FlatButton fullWidth onClick={handleSubmit}>
              Submit
            </FlatButton>
          </Container>
        </ActionsWrapper>
      </Content>
    </Page>
  );
};
