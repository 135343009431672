import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../../../actions/index';

import LinkTo from '../../../Links/LinkTo';

import { truncateName, snakeToHyphens } from '../../../../helpers/generalHelpers';
import { findUserIdeaForSecurity } from '../../../../helpers/ideaHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';

export class IdentifierWithIdeaTypeCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? (
          this.props.loadingCellComponent
        ) : (
          <LinkTo
            to={this._returnLinkForHandlingClick()}
            className={''}
            additionalClickAction={this.props.logSecurityPanelOpenClick}
            child={this._renderCellContent()}
            noTextDecoration
          />
        )}
      </td>
    );
  }

  _isLoading = () => {
    const { userId, securityId, currentUser } = this.props;
    const ideaUserId = userId || currentUser.user_id;
    const security = this.props.securities.lookup[securityId];
    const ideaStore = this.props.ideas;
    const ideaList = ideaStore.ideaList;

    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    const isLoadingIdeaData = ideaStore.loadingCurrentUserIdeas || !security || !idea;
    return !security || !('symbol' in security) || !('name' in security) || isLoadingIdeaData;
  };

  _returnLinkForHandlingClick = () => {
    const securityId = this.props.securityId;
    const openSecurityPanelConfig = {
      securityId,
      additionalProps: {
        activeTab: 'ideas',
        ideaId: this._returnIdea().idea_id,
      },
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _returnIdea = () => {
    const { userId, securityId } = this.props;
    const ideaUserId = userId || this.props.currentUser.user_id;
    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    return idea;
  };

  _renderCellContent = () => {
    const { securityId } = this.props;
    const security = this.props.securities.lookup[securityId];
    const { name, symbol } = security;

    const idea = this._returnIdea();
    const ideaTypeName = idea.idea_type ? idea.idea_type.name : null;
    const isIdeaTypeBuy = ideaTypeName.toLowerCase() === 'buy';

    return (
      <span className="security-table-identifier">
        <span className="security-table-symbol  ">{symbol}</span>
        <span className="security-table-company-name truncate-text-overflow secondary-text-color">
          {truncateName(name, 18)}
        </span>
        <span className={`security-table-idea-type  ${isIdeaTypeBuy ? 'buy-text-color' : 'sell-text-color'}`}>
          {ideaTypeName}
        </span>
      </span>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    ideas: state.ideas,
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(IdentifierWithIdeaTypeCell);

export default composedComponent;
