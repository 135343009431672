import React from 'react';

import { NewConnectionRow } from './NewConnectionRow';
import { UserAvatarAndName } from '../../../../UI/User/UserAvatarAndName';
import { Timestamp } from '../../../../UI/Timestamp';
import MaxRowsList from '../../../../UI/MaxRowsList';
import { PROFILE_CONTEXTS } from '../../../../../constants/contextTracking';

export const ConnectionHasNewConnectionsNotification = (props) => {
  const { notification } = props;
  const { user, connections, timestamp } = notification;

  const actionConnectionUserId = user.user_id;
  const notificationConnectionsUserIds = connections.map((user) => user.user_id);

  return (
    <div className={`connection-notification-container`}>
      <div className={`new-connection-notification-message`}>
        <UserAvatarAndName
          userId={actionConnectionUserId}
          profileLinkQuery={`?context=${PROFILE_CONTEXTS.NOTIFICATION}`}
          withLink
        />
        <div className={`message`}>has new connections</div>
      </div>
      <div className={`new-connections-list notification-indent`}>
        <MaxRowsList
          ids={notificationConnectionsUserIds}
          containerStyles={{
            padding: '5px 23px', // used to indent past avatar
          }}
        >
          {(ids) => (
            <table>
              <tbody>
                {ids.map((userId) => (
                  <NewConnectionRow
                    key={`new-connection-notif-row-${userId}`}
                    userId={userId}
                    showConnectionRequestBtn
                    hideIgnoreBtn
                  />
                ))}
              </tbody>
            </table>
          )}
        </MaxRowsList>
      </div>
      <Timestamp className={'notification-timestamp'} epochTime={timestamp} />
    </div>
  );
};
