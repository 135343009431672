import React from 'react';
import { connect } from 'react-redux';

import { getKarmaTotalFromState } from '../../selectors/karma';

class AnimatedPoint extends React.PureComponent {
  componentDidMount() {
    this.animationCompleteTimeout = this._createAnimationCompletionTimeout();
  }

  componentWillUnmount() {
    if (this.animationCompleteTimeout) clearTimeout(this.animationCompleteTimeout);
  }

  _createAnimationCompletionTimeout = () => {
    const { value } = this.props;

    const TIME_LENGTH = 600;

    return setTimeout(() => this._onAnimationComplete(value), TIME_LENGTH);
  };

  _onAnimationComplete = (value) => {
    const { onAnimationComplete } = this.props;
    onAnimationComplete && onAnimationComplete(value);
  };

  render() {
    const { value } = this.props;

    return (
      <div
        className={'karma-earned-animation'}
        style={{
          position: 'absolute',
          top: '1px',
          right: '2px',
          width: 18,
          transform: 'scale(1.25)',
        }}
      >
        <div className={'success-text-color'}>
          <span>{`+${value}`}</span>
        </div>
      </div>
    );
  }
}

class KarmaPointEarnedAnimation extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      animationRendererStack: [],
      animationValue: props.karmaTotal,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { karmaTotal: prevKarmaTotal } = prevProps;
    const { karmaTotal } = this.props;

    if (prevKarmaTotal === null && karmaTotal !== null) this.handleKarmaValueInitialSet(karmaTotal);

    if (prevKarmaTotal !== karmaTotal && prevKarmaTotal !== null && !isNaN(prevKarmaTotal) && !isNaN(karmaTotal))
      this.handleKarmaTotalChange(prevKarmaTotal, karmaTotal);
  }

  render() {
    const { animationRendererStack, animationValue } = this.state;
    const { children } = this.props;

    return (
      <div>
        {children(animationValue)}

        <div style={{}}>
          {animationRendererStack.map((v, i) => (
            <AnimatedPoint key={`${i}-${v}`} value={v} onAnimationComplete={this.handleAnimationComplete} />
          ))}
        </div>
      </div>
    );
  }

  handleKarmaValueInitialSet = (value) => {
    this._setAnimationValue(value);
  };

  handleAnimationComplete = (value) => {
    this._setAnimationValue(value, true);
  };

  handleKarmaTotalChange = (prev, current) => {
    if (current < prev) return;

    const changeAmount = current - prev;
    this.setState((prev) => ({
      animationRendererStack: [...prev.animationRendererStack, changeAmount],
    }));
  };

  _setAnimationValue = (v, isIncrement) => {
    this.setState((prevState) => ({
      animationValue: isIncrement ? prevState.animationValue + v : v,
    }));
  };
}

const mapStateToProps = (state) => {
  return {
    karmaTotal: getKarmaTotalFromState(state),
  };
};

export default connect(mapStateToProps)(KarmaPointEarnedAnimation);
