import React from 'react';
import styled from 'styled-components';
import { Overlay } from './Overlay';

const Wrapper = styled.div`
  position: relative;
  padding: ${({ noPadding }) => (noPadding ? '0px' : '16px 24px')};
  margin: 16px;
  border-radius: 5px;
  text-align: left;
  cursor: default;
  overflow: hidden; // card should not have scroll

  max-width: ${({ width }) => width || '800px'};

  background: ${({ theme }) => theme.themeColors.componentNoOpacity};
  color: ${({ theme }) => theme.themeColors.text};

  @media (max-width: 390px) {
    padding: 16px;
  }
  @media (max-width: 340px) {
    padding: 8px;
  }
`;

export const Modal = (props) => {
  const { children, restrictDismiss, onDismiss, modalProps } = props;

  const handleModalClick = (e) => {
    e.stopPropagation(); // prevent overlay click from registering
  };

  return (
    <Overlay {...props}>
      <Wrapper {...(modalProps || {})} onClick={handleModalClick}>
        {children}
      </Wrapper>
    </Overlay>
  );
};
