import React from 'react';
import { ThoughtNotificationUserOpinionRow } from './ThoughtNotificationUserOpinionRow';
import { isThoughtFromCommunityThoughtLeader } from '../../../helpers/thoughtsHelpers';
import styled from 'styled-components';

const ThoughtNotificationUserOpinionsContianer = styled.div`
  display: flex;
  flex-direction: row;

  & > .thought-notification-user-row {
    width: 50%;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    & > .thought-notification-user-row {
      width: 100%;
    }
  }
`;

export const ThoughtNotificationUserOpinionRows = (props) => {
  const {
    thought,
    isSocialStyle,

    agreeUserIds,
    communityAgreeCount,
    disagreeUserIds,
    communityDisagreeCount,
  } = props;

  const isCommunityThoughtLeaderThought = thought ? isThoughtFromCommunityThoughtLeader(thought) : false;

  return (
    (communityAgreeCount > 0 || communityDisagreeCount > 0) && (
      <ThoughtNotificationUserOpinionsContianer>
        {communityAgreeCount > 0 && (
          <ThoughtNotificationUserOpinionRow
            userIds={agreeUserIds}
            totalCount={communityAgreeCount}
            opinionType="agree"
            isCTLThought={isCommunityThoughtLeaderThought}
            isSocialStyle={isSocialStyle}
          />
        )}
        {communityDisagreeCount > 0 && (
          <ThoughtNotificationUserOpinionRow
            userIds={disagreeUserIds}
            totalCount={communityDisagreeCount}
            opinionType="disagree"
            isCTLThought={isCommunityThoughtLeaderThought}
            isSocialStyle={isSocialStyle}
          />
        )}
      </ThoughtNotificationUserOpinionsContianer>
    )
  );
};
