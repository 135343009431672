import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import * as Actions from '../../actions/index';

import PageLoading from '../../components/PageLoading';

import PresetFilterSelection from './PresetFilterSelection';
import SecuritiesSelection from './SecuritiesSelection';

import { convertFilterNameToMetricsTrackingEventProperty } from '../../helpers/trackingHelpers';
import { getPortfolioExplorerDefaultFilterCards } from '../../helpers/portfolioExplorerHelpers';
import { findUserIdeas, userHasIdeaForSecurity, getSecurityIdFromIdea } from '../../helpers/ideaHelpers';
import { createQueryString } from '../../helpers/routerHelpers';

import { sendFacebookTrackingEvent } from '../../constants/facebookTracking';
import { returnCurrentUserThemeTestGroups, returnCurrentUserId } from '../../helpers/currentUserHelpers';
import { returnConnectionDataForUser } from '../../helpers/userConnectionsHelpers';
import { createBasicErrorModal } from '../../constants/modals';
import { TrackingEvents } from '../../utils/tracking/events';

class PortfolioExplorer extends Component {
  constructor() {
    super();
    this.state = {
      wasError: false,

      presetFilterList: [],
      presetFilter: null,
      presetFilterCardSize: 'default',
      filters: [],
      filterIdCounter: 1,

      showSecuritiesSelectionComponent: false,
      initialFilterResultsCount: 12,
      filterResultsCountNeeded: 12,

      isIncreasingFilterResultsCount: false,
      noMoreFilterResultsAvailable: false,
      cardRowDisplaying: 0,
      width: window.innerWidth,
      cardWidth: 160,
      paddingBetweenCards: 25,
      offsetToFitArrows: 100,
      filterSecuritiesRequestCounter: 1,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    document.title = `${PRODUCT_DISPLAY_NAME} - Explore`;
    this._logViewPortfolioExplorer();
    this._refreshData();
    const connections = this.returnCurrentUserConnections() || [];
    const ideas = this.returnCurrentUserIdeas();
    const showConnectionsCard = !!connections.length;
    const width = this.state.width || window.innerWidth;
    const showDiversifyingCard = !!ideas.length;
    this.props.actions.getFilterSelectionOptions().then((resp) => {
      if (!resp || Object.keys(resp).length < 3) {
        this.setState(() => ({
          wasError: true,
        }));
        const modalMessage = `Something went wrong. Please refresh and try again.`;
        const modal = {
          contentComponent: createBasicErrorModal(modalMessage),
          size: 'wide',
          dismissable: true,
        };
        this.props.actions.showModal(modal);
        return null;
      }

      this.setState(() => ({
        presetFilterList: getPortfolioExplorerDefaultFilterCards(
          resp,
          showConnectionsCard,
          showDiversifyingCard,
          Math.floor(width / (width > 650 ? 175 : 135)),
          this._returnCurrentUserId()
        ),
      }));
      if (ideas.length > 0) {
        const securityIds = [];
        ideas.forEach((idea) => {
          securityIds.push(getSecurityIdFromIdea(idea));
        });
        const combinedSecurityIds = [...ideas.map((idea) => getSecurityIdFromIdea(idea)), ...securityIds];
        const uniqueCombinedSecurityIds = combinedSecurityIds.filter((el, i) => combinedSecurityIds.indexOf(el) == i);
        // check if data is needed for these securities
        this._fetchDataForSecurities(uniqueCombinedSecurityIds);
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.width) {
      this.handleResize();
    }
    const securityResultsCountNeeded = 12;
    this.updateFilterResults(prevState, securityResultsCountNeeded);
    const totalSecuritiesCount = this.getSecuritiesToDisplayInSecuritiesList().length;
    const totalRows = Math.ceil(totalSecuritiesCount / this.calcCardsInARow()) - 1;
    if (totalRows < this.state.cardRowDisplaying && this.state.cardRowDisplaying !== 0) {
      this.changeFilterResultDisplayRow('Previous');
    }
  }

  render() {
    if (this.state.wasError) {
      return (
        <div
          className={'portfolio-explorer-container'}
          style={{ textAlign: 'center', margin: '15px' }}
          ref={(el) => (this.portfolioExplorerContainer = el)}
        >
          <span style={{ paddingTop: '50px' }}>Something went wrong, refresh and try again.</span>
        </div>
      );
    }

    if (this.state.presetFilterList.length < 1) {
      return <PageLoading />;
    }

    return (
      <div
        className={`portfolio-explorer-container  ${!this.state.presetFilter ? 'preset-filter-parent' : ''}`}
        style={this.state.showSecuritiesSelectionComponent ? { overflow: 'hidden' } : { overflow: 'visible' }}
        ref={(el) => (this.portfolioExplorerContainer = el)}
      >
        <div>
          <PresetFilterSelection
            presetFilterList={this.state.presetFilterList}
            presetFilterCardSize={this.state.presetFilterCardSize}
            handleFilterCardClick={this.setPresetFilter}
          />
          <SecuritiesSelection
            showFilterView={this.state.showSecuritiesSelectionComponent}
            addToSecurityTable={this.addToSecurityTable}
            securityIdsFromFilterResults={this.getSecuritiesToDisplayInSecuritiesList()}
            ideasForSecurityTable={this.returnIdeasForSecurityTable()}
            loadingFilters={this.isLoadingNewFilters()}
            isLoadingMoreFilterResults={this.isLoadingMoreFilterResults()}
            filters={this.state.filters}
            filterConstructionProps={this.filterConstructionProps()}
            presetFilter={this.state.presetFilter}
            presetFilters={this.state.presetFilter ? this.state.presetFilter.filters : []}
            updateFilters={this.updateFilters}
            expandSecurityCard={this.handleExpandSecurityCard}
            expandSecurityCardFromTable={this.handleExpandSecurityCardFromTable}
            cardRowDisplaying={this.state.cardRowDisplaying}
            width={this.state.width}
            cardWidth={this.state.cardWidth}
            paddingBetweenCards={this.state.paddingBetweenCards}
            offsetToFitArrows={this.state.offsetToFitArrows}
            cardsInARow={this.calcCardsInARow}
            changeFilterResultDisplayRow={this.changeFilterResultDisplayRow}
            navigate={this.props.navigate}
          />
        </div>
      </div>
    );
  }

  shouldIncreaseFilterResultsCount = () => {
    const filterResultsAvailable = !this.state.noMoreFilterResultsAvailable;
    const filtersAreApplied = this.state.filters.length > 0;
    const securitiesInList = this.getSecuritiesToDisplayInSecuritiesList();
    const cardsPerPage = this.calcCardsInARow();
    const currentPage = this.state.cardRowDisplaying;
    const totalPages = Math.ceil(securitiesInList.length / cardsPerPage);
    const lastPageIsNextPage = currentPage === totalPages - 2;
    const lastPageIsNotAFullPage = this.getSecuritiesOnLastPage().length !== cardsPerPage;
    return (
      filtersAreApplied &&
      filterResultsAvailable &&
      ((lastPageIsNextPage && lastPageIsNotAFullPage) || totalPages === currentPage + 1)
    );
  };

  increaseFilterResultsCount = () => {
    const newCount = this.state.filterResultsCountNeeded + this.state.initialFilterResultsCount;
    this.sendFilterSecuritiesRequest(newCount).then((response) => {
      this.setState(() => ({
        isIncreasingFilterResultsCount: false,
      }));
    });
    this.setState(() => ({
      filterResultsCountNeeded: newCount,
    }));
  };

  updateFilterResults = (prevState) => {
    // if filter length is different or filters object is different ( change in filters )
    if (prevState.filters.length !== this.state.filters.length || prevState.filters !== this.state.filters) {
      this.sendFilterSecuritiesRequest(this.state.initialFilterResultsCount).then((response) => {
        this.setState((prevState) => ({
          cardRowDisplaying: 0,
          filterResultsCountNeeded: prevState.initialFilterResultsCount,
          isIncreasingFilterResultsCount: false,
          noMoreFilterResultsAvailable: false,
        }));
      });
    } else if (this.shouldIncreaseFilterResultsCount() && !this.state.isIncreasingFilterResultsCount) {
      this.setState(() => ({
        isIncreasingFilterResultsCount: true,
      }));
      this.increaseFilterResultsCount();
    }
  };

  sendFilterSecuritiesRequest = (count) => {
    const filterResultsCount = count || this.state.initialFilterResultsCount;
    const newFilterSecuritiesRequest = this.props.actions.filterSecurities(
      filterResultsCount,
      this.state.filters,
      this.state.filterSecuritiesRequestCounter
    );
    this.setState((prevState) => ({
      filterSecuritiesRequestCounter: prevState.filterSecuritiesRequestCounter + 1,
    }));
    return newFilterSecuritiesRequest.then((idList) => {
      if (idList.length > 0) {
        if (count > idList.length) {
          this.setState(() => ({
            noMoreFilterResultsAvailable: true,
          }));
        }
        return this._fetchFilterCardDataForSecurities(idList);
      }
    });
  };

  getSecuritiesOnLastPage = () => {
    const cardsPerPage = this.calcCardsInARow();
    const securitiesInList = this.getSecuritiesToDisplayInSecuritiesList();
    const totalPages = Math.ceil(securitiesInList.length / cardsPerPage);
    return securitiesInList.slice(totalPages * cardsPerPage - cardsPerPage, totalPages * cardsPerPage);
  };

  userIsOnLastPage = () => {
    const currentPage = this.state.cardRowDisplaying;
    const cardsPerPage = this.calcCardsInARow();
    const securitiesInList = this.getSecuritiesToDisplayInSecuritiesList();
    const totalPages = Math.ceil(securitiesInList.length / cardsPerPage);
    return currentPage === totalPages - 1;
  };

  _fetchFilterCardDataForSecurities = (securityIds) => {
    this.props.actions.quickFetchSecuritiesData(securityIds);
    this.props.actions.fetchSecuritiesFundamentalsData(securityIds);
    this.props.actions.fetchSecuritiesPriceData(securityIds);
    this.props.actions.getIdeasForSecurity(securityIds);
    this.props.actions.fetchSecuritiesPriceHistoryData(securityIds, 'one_year');
  };

  _fetchDataForSecurities = (securityIds) => {
    this.props.actions.quickFetchSecuritiesData(securityIds);
    this.props.actions.fetchSecuritiesPriceData(securityIds);
    this.props.actions.getIdeasForSecurity(securityIds);
  };

  setPresetFilter = (presetFilter) => {
    this.setState((prevState) => ({
      presetFilter: prevState.presetFilterList[presetFilter],
      filters: [
        ...prevState.presetFilterList[presetFilter].filters.map((f, i) => ({
          ...f,
          id: i + 1,
        })),
      ],
      filterIdCounter: prevState.presetFilterList[presetFilter].filters.length + 1,
    }));
    this.showSecuritiesSelectionComponent();
  };

  showSecuritiesSelectionComponent = () => {
    this.setState(() => ({
      showSecuritiesSelectionComponent: true,
    }));
    setTimeout(() => {
      if ($('.portfolio-explorer-securities-filter-builder-container').length > 0) {
        $('html, body').animate(
          {
            scrollTop: $('.portfolio-explorer-securities-filter-builder-container').offset().top - 120,
          },
          300
        );
      }
    }, 500);
  };

  createFilter = (filter) => {
    const event = 'Created Filter';
    const filterName = filter.name;
    const trackingFilterName = convertFilterNameToMetricsTrackingEventProperty(filterName);
    const properties = { Filter: trackingFilterName };
    this.props.actions.logMetricsTrackingEvent(event, properties);
    this.setState((prevState) => ({
      filters: [...prevState.filters, { ...filter, id: prevState.filterIdCounter }],
      filterIdCounter: prevState.filterIdCounter + 1,
    }));
  };

  updateFilter = (filter) => {
    this.setState((prevState) => ({
      filters: prevState.filters.map((f) => (f.id === filter.id ? filter : f)),
    }));
  };

  removeFilter = (filter) => {
    const event = 'Remove Filter';
    const filterName = filter.name;
    const trackingFilterName = convertFilterNameToMetricsTrackingEventProperty(filterName);
    const properties = { Filter: trackingFilterName };
    this.props.actions.logMetricsTrackingEvent(event, properties);

    const filterId = filter.id;
    const newFilterList = this.state.filters.filter((filter) => filter.id !== filterId);
    this.setState({ filters: newFilterList });
  };

  filterConstructionProps = () => ({
    createFilter: this.createFilter,
    updateFilter: this.updateFilter,
    removeFilter: this.removeFilter,
  });

  addToOptimizer = (securityId) => {
    this.props.actions.addIdeas([{ security: { security_id: securityId } }]).then((response) => {
      const ideasWithErrors = response.ideas.filter((idea) => idea.status === 'error');
      if (ideasWithErrors.length === 0) {
        const params = {
          themeTestGroups: returnCurrentUserThemeTestGroups(this.props.currentUser),
          accountType: this.props.currentUser.is_live_trading ? 'Live' : 'Paper',
        };
        sendFacebookTrackingEvent('Added Idea', params);

        TrackingEvents.ideas.ADD_IDEA.send();
      }
    });
  };

  addToSecurityTable = (securityId) => {
    if (!userHasIdeaForSecurity(this._returnCurrentUserId(), securityId, this.props.ideas.ideaList)) {
      this.props.actions.addIdeas([{ security: { security_id: securityId } }]).then((response) => {
        const ideasWithErrors = response.ideas.filter((idea) => idea.status === 'error');
        if (ideasWithErrors.length === 0) {
          const params = {
            themeTestGroups: returnCurrentUserThemeTestGroups(this.props.currentUser),
            accountType: this.props.currentUser.is_live_trading ? 'Live' : 'Paper',
          };
          sendFacebookTrackingEvent('Added Idea', params);

          TrackingEvents.ideas.ADD_IDEA.send();
        }
      });
    }
  };

  changeFilterResultDisplayRow = (direction) => {
    const lookup = {
      Previous: -1,
      Next: 1,
    };
    const event = `Clicked ${direction} Security Card Page`;
    this.props.actions.logMetricsTrackingEvent(event, {
      Page: this.state.cardRowDisplaying,
    });

    this.setState((prevState) => ({
      cardRowDisplaying: prevState.cardRowDisplaying + lookup[direction],
    }));
  };

  isLoadingNewFilters = () => this.props.portfolioExplorer.loadingFilters && !this.state.isIncreasingFilterResultsCount;

  isLoadingMoreFilterResults = () => this.state.isIncreasingFilterResultsCount && this.userIsOnLastPage();

  calcCardsInARow = () =>
    Math.floor(
      (this.state.width - this.state.offsetToFitArrows) / (this.state.cardWidth + this.state.paddingBetweenCards)
    );

  getSecuritiesToDisplayInSecuritiesList = () => this.props.portfolioExplorer.securities;

  _refreshData = () => {
    this.props.actions.getCurrentUserConnections();
  };

  returnCurrentUserConnections = () =>
    returnConnectionDataForUser(this._returnCurrentUserId(), this.props.userConnections);

  returnCurrentUserIdeas = () => findUserIdeas(this._returnCurrentUserId(), this.props.ideas.ideaList);

  returnIdeasForSecurityTable = () => {
    const currentUserIdeas = this.returnCurrentUserIdeas();
    const ideasBeingCreatedKeys = Object.keys(this.props.ideas.userCreatingIdeas).map((id) => parseInt(id, 10));
    const ideasBeingCreated = ideasBeingCreatedKeys.map((id) => this.props.ideas.userCreatingIdeas[id]);
    return [...ideasBeingCreated, ...currentUserIdeas];
  };

  handleExpandSecurityCard = (query) => {
    const event = 'Open Security Panel';
    const properties = {
      Component: 'Portfolio Explorer',
      Context: 'Security Card',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);

    const queryString = createQueryString(query);
    this.props.navigate(location.pathname + queryString);
  };

  handleExpandSecurityCardFromTable = (query) => {
    const queryString = createQueryString(query);
    this.props.navigate(location.pathname + queryString);
  };

  handleResize = () => {
    let cardWidth = window.innerWidth > 700 ? 160 : 115;
    let paddingBetweenCards = window.innerWidth > 700 ? 25 : 20;
    let offsetToFitArrows = window.innerWidth > 700 ? 100 : 50;
    let containerWidth =
      (this.portfolioExplorerContainer && this.portfolioExplorerContainer.offsetWidth) || window.innerWidth;

    this.setState({
      width: containerWidth > 850 ? 850 : containerWidth,
      cardWidth,
      paddingBetweenCards,
      offsetToFitArrows,
    });
  };

  _returnCurrentUser = () => this.props.currentUser;

  _returnCurrentUserId = () => returnCurrentUserId(this._returnCurrentUser());

  _logViewPortfolioExplorer = () => {
    const event = 'View Portfolio Explorer';
    this.props.actions.logMetricsTrackingEvent(event);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    ideas: state.ideas,
    portfolioExplorer: state.portfolioExplorer,
    userConnections: state.userConnections,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PortfolioExplorer);
