import { trackingEvents } from '@src/main/lib/nvstr-utils.es';

import ItlyTrackWrapper from './ItlyTrackWrapper';

const ONBOARDING_COMPLETED = new ItlyTrackWrapper(trackingEvents.onboardingCompleted);
const ONBOARDING_FUNDING_SKIPPED = new ItlyTrackWrapper(trackingEvents.onboardingFundingSkipped);

const onboardingTrackIteratively = {
  ONBOARDING_COMPLETED,
  ONBOARDING_FUNDING_SKIPPED,
};

export default onboardingTrackIteratively;
