import { getSecurityIdFromIdea } from './ideaHelpers';

export const isRailsNotificationTemplate = (notification) => {
  return notification && 'prerendered_html' in notification;
};

export const createMarkup = (notificationHTML) => {
  return { __html: notificationHTML };
};

export const removeLIfromMarkup = (htmlString) => {
  const length = htmlString.length;
  let startFromClippedStringIndex = null;
  let endAtClippedStringIndex = null;

  for (let i = 0; i < length; i++) {
    const char = htmlString[i];
    if (char === '>') {
      startFromClippedStringIndex = i + 1;
      break;
    }
  }

  for (let i = length; i > 0; i--) {
    const char = htmlString[i];
    if (char === '<') {
      endAtClippedStringIndex = i;
      break;
    }
  }
  return htmlString.slice(startFromClippedStringIndex, endAtClippedStringIndex);
};

export const convertNotificationGETConfigToQueryString = (config) => {
  if (typeof config !== 'object' || (!'batch_index' && !('page' in config))) {
    return '';
  }
  let string = '?';
  if ('batch_index' in config) {
    string += `batch_index=${config.batch_index}`;
  }
  if ('page' in config) {
    const prefix = string.length > 1 ? '&' : '';
    string += `${prefix}page=${config.page}`;
  }
  return string;
};

export const returnSecurityIdFromNotification = (notification) => {
  if (notification.data && notification.data.security && notification.data.security.security_id) {
    return notification.data.security.security_id;
  }
  if (notification.data && notification.data.security_id) {
    return notification.data.security_id;
  }
  if (notification.ideas && notification.ideas[0] && getSecurityIdFromIdea(notification.ideas[0])) {
    return getSecurityIdFromIdea(notification.ideas[0]);
  }

  return null;
};
