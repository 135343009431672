import { findUserIdeaForSecurity } from './ideaHelpers';
import { isUndefinedOrNull } from './generalHelpers';
import { throwError } from './devToolHelpers';

const permittedResponseKeys = [
  'buying_power',
  'is_market_open',
  'has_free_trading',
  'trade_credit',
  'not_placed_orders',
  'placed_orders',
  'awaiting_approval_orders',
];

const orderActionPhraseLookup = {
  'Sold short': 'sell_short',
  Sold: 'sell',
  Bought: 'buy',
  'Bought to cover': 'buy_to_cover',
  'Sold and sold short': 'sold_and_short',
  'Covered and bought': 'cover_and_buy',
  'Bought and sold': 'buy_and_sell',
};

const operationsLookup = {
  sell_short: 'Sold Short',
  sell: 'Sold',
  buy: 'Bought',
  buy_to_cover: 'Covered',
};

const operationsPhraseLookupPresentTense = {
  sell_short: 'Sell Short',
  sell: 'Sell',
  buy: 'Buy',
  buy_to_cover: 'Buy To Cover',
};

const operationsPhraseLookupPastTense = {
  sell_short: 'Sold short',
  sell: 'Sold',
  buy: 'Bought',
  buy_to_cover: 'Bought to cover',
};

const multiOrdersOperationsLookup = {
  sell_short: ['Sold short'],
  sell: ['Sold'],
  buy: ['Bought'],
  buy_to_cover: ['Bought to cover'],
  sold_and_short: ['Sold', 'sold short'],
  cover_and_buy: ['Covered', 'bought'],
  buy_and_sell: ['Bought', 'sold'],
};

const operationsToDisplayStringLookup = {
  sell_short: 'Sell',
  sell: 'Sell',
  buy: 'Buy',
  buy_to_cover: 'Buy',
};

const subOperationsToDisplayStringLookup = {
  sell_short: 'Short',
  buy_to_cover: 'Cover',
};
const subOperationsLookup = {
  sell_short: true,
  buy_to_cover: true,
};

const statusLookup = {
  pending: '',
  submitted: '',
  partially_filled: 'Filling',
  filled: '',
  cancel_submitted: 'Cancel Requested',
  canceled: 'Canceled',
  rejected: 'Rejected',
  expired: 'Expired',
};

const cancelledStatusLookup = {
  canceled: true,
  rejected: true,
  expired: true,
};

const unfilledOrderStatuses = [
  'pending',
  'submitted',
  'partially_filled',
  'cancel_pending',
  'cancel_submitted',
  'stopped',
];

export const areUnfilledOrders = (orders) =>
  orders.some((order) => unfilledOrderStatuses.some((status) => order.execution_status === status));

export const getOperationFromOrderData = (orderData) => orderData.operation;

export const cleanGetOrdersResponseData = (response) => {
  const data = response.data;
  const permittedData = {};
  permittedResponseKeys.forEach((key) => (permittedData[key] = data[key]));
  return permittedData;
};

export const isOrderOperationBuying = (order) =>
  getOperationFromOrderData(order) === 'buy' || getOperationFromOrderData(order) === 'buy_to_cover';

export const isOrderOperationSelling = (order) =>
  getOperationFromOrderData(order) === 'sell' || getOperationFromOrderData(order) === 'sell_short';

export const isOrderOperationTypeBuying = (orderOperation) =>
  orderOperation === 'buy' || orderOperation === 'buy_to_cover';

export const isOrderOperationTypeSelling = (orderOperation) =>
  orderOperation === 'sell' || orderOperation === 'sell_short';

export const isLimitOrder = (order) => !isUndefinedOrNull(order.limit_price);

export const isStopOrder = (order) => !isUndefinedOrNull(order.stop_price);

export const returnStandaloneDisplayStringOrderOperationPastTense = (operation) => {
  return operationsLookup[operation];
};

export const returnDisplayPhraseStringOrderOperationPresentTense = (operation) => {
  return operationsPhraseLookupPresentTense[operation];
};

export const returnDisplayPhraseStringOrderOperationPastTense = (operation) => {
  return operationsPhraseLookupPastTense[operation];
};

export const returnListOfDisplayPhraseStringMultiOrdersPastTense = (orders) => {
  const orderOperationsList = [];
  orders.forEach((order) => orderOperationsList.push(getOperationFromOrderData(order)));

  const isOrderOperationsAllSame = orderOperationsList.every((op) => op === orderOperationsList[0]);
  if (isOrderOperationsAllSame) {
    return multiOrdersOperationsLookup[getOperationFromOrderData(orders[0])];
  }

  if (orderOperationsList.includes('buy') && orderOperationsList.includes('sell')) {
    return multiOrdersOperationsLookup['buy_and_sell'];
  }

  if (orderOperationsList.includes('sell_short') && orderOperationsList.includes('sell')) {
    return multiOrdersOperationsLookup['sold_and_short'];
  }

  if (orderOperationsList.includes('buy_to_cover') && orderOperationsList.includes('buy')) {
    return multiOrdersOperationsLookup['cover_and_buy'];
  }

  throwError('Missing case for order notification returnDisplayPhraseStringMultiOrdersPastTense', true, {
    orders,
    orderOperationsList,
  });
};

export const returnDisplayStringLimitOrderOperationPastTense = (operation) => {
  return operationsLookup[operation];
};

export const returnDisplayStringOrderOperation = (operation) => {
  return operationsToDisplayStringLookup[operation];
};

export const hasSubOperation = (order) => {
  return subOperationsLookup[getOperationFromOrderData(order)];
};

export const returnDisplayStringOrderSubOperation = (operation) => {
  return subOperationsToDisplayStringLookup[operation];
};

export const returnOrderDisplayStatus = (order) => {
  return statusLookup[order.execution_status] || '';
};

export const isACancelledStatus = (order) => {
  return cancelledStatusLookup[order.execution_status] || false;
};

export const calculateOrderApproxValue = (
  isSell,
  shares,
  marketPrice,
  isLimitOrder,
  limitPrice,
  isStopOrder,
  stopPrice
) => {
  var price;
  if (isSell) {
    if (isLimitOrder) {
      if (limitPrice > marketPrice) {
        price = limitPrice;
      } else if (isStopOrder) {
        if (stopPrice < marketPrice) {
          if (stopPrice > limitPrice) {
            price = stopPrice;
          } else {
            price = limitPrice;
          }
        } else {
          price = marketPrice;
        }
      } else {
        price = marketPrice;
      }
    } else if (isStopOrder) {
      if (stopPrice < marketPrice) {
        price = stopPrice;
      } else {
        price = marketPrice;
      }
    } else {
      price = marketPrice;
    }
    return price * shares * -1;
  } else {
    if (isLimitOrder) {
      if (limitPrice < marketPrice) {
        price = limitPrice;
      } else if (isStopOrder) {
        if (stopPrice > marketPrice) {
          if (stopPrice < limitPrice) {
            price = stopPrice;
          } else {
            price = limitPrice;
          }
        } else {
          price = marketPrice;
        }
      } else {
        price = marketPrice;
      }
    } else if (isStopOrder) {
      if (stopPrice > marketPrice) {
        price = stopPrice;
      } else {
        price = marketPrice;
      }
    } else {
      price = marketPrice;
    }
    return price * shares;
  }
};

export const calculateOrderApproxShares = (dollarAmount, marketPrice) => {
  const totalShares = dollarAmount / marketPrice;
  return (totalShares && totalShares.toFixed(5)) || '';
};

export const isOrderAShort = (order) => getOperationFromOrderData(order) === 'sell_short';

export const isOrderACover = (order) => getOperationFromOrderData(order) === 'buy_to_cover';

export const isOrderASell = (order) => getOperationFromOrderData(order) === 'sell';

export const isOrderABuy = (order) => getOperationFromOrderData(order) === 'buy';

export const isOrderOperationOfTypeBuying = (orderOperation) => ['buy_to_cover', 'buy'].includes(orderOperation);

export const convertOrderActionPhraseToOrderOperation = (orderActionPhrase) => {
  return orderActionPhraseLookup[orderActionPhrase];
};

export const isOrderActionPhraseOfTypeBuying = (orderActionPhrase) =>
  ['buy_to_cover', 'buy'].includes(convertOrderActionPhraseToOrderOperation(orderActionPhrase));

export const createIdeaDataFromOrder = (order) => ({
  security: {
    security_id: order.security_id,
  },
  idea_type: order.operation.toLowerCase() === 'buy' ? { id: 0, name: 'buy' } : { id: 1, name: 'sell' },
});

export const createIdeasFromOrdersIfNoIdeaExists = (orders, currentUserId, ideasLookup) => {
  const ideas = [];
  orders.forEach(
    (order) =>
      !findUserIdeaForSecurity(currentUserId, order.security_id, ideasLookup) &&
      ideas.push(createIdeaDataFromOrder(order))
  );
  return ideas;
};

export const calculateCommissionsFromGeneratedOrders = (orders) =>
  orders.reduce((sum, order) => sum + (order.commission || 0), 0);

export const isOptimizedTrade = (orders) => orders.some((order) => order.is_from_optimizer);

export const isOrderSubdivied = (order) => !!order.subdivision;

export const formatOrderSummaryData = (order) => {
  const formattedOrder = {};
  formattedOrder.shares = order.shares;
  formattedOrder.operation = order.operation;
  if (isLimitOrder(order)) {
    formattedOrder.limit_price = order.limit_price;
  }
  if (isStopOrder(order)) {
    formattedOrder.stop_price = order.stop_price;
  }
  return formattedOrder;
};

export const formatOrderDataForAddProConPanel = (order) => {
  /*
    {
      orderId: 1,
      orderDataSummaryList: [
        {
          shares: int,
          operation: buy/sell,
          value: float,
          limit_price: float,
          stop_price: float,
        },
      ],
      securityId: id<int>,
    }
  */
  const formattedOrder = {};
  formattedOrder.orderId = order.order_id;
  formattedOrder.securityId = order.security_id;
  formattedOrder.orderDataSummaryList = [];
  formattedOrder.orderDataSummaryList.push(formatOrderSummaryData(order));
  if (isOrderSubdivied(order)) {
    formattedOrder.orderDataSummaryList.push(formatOrderSummaryData(order.subdivision));
  }
  return formattedOrder;
};

export const sortOrdersForAddProConPanel = (orders) => {
  return orders.sort((a, b) => {
    let sharesA = 0;
    a.orderDataSummaryList.forEach((order) => (sharesA = sharesA + order.shares));
    let sharesB = 0;
    b.orderDataSummaryList.forEach((order) => (sharesB = sharesB + order.shares));

    if (sharesA > sharesB) return -1;
    if (sharesA < sharesB) return 1;
    return 0;
  });
};

export const sortAndFormatOrdersDataForAddProConPanel = (orders) => {
  const formatedOrders = orders.map((order) => formatOrderDataForAddProConPanel(order));
  return sortOrdersForAddProConPanel(formatedOrders);
};
