import React from 'react';

import Icon from '../../../components/misc/Icon';

const NeverShowDollarAmt = (props) => {
  const message = 'Remember, your connections can never see your position dollar or share amounts, only percentages.';
  const icon = (
    <Icon
      icon={'fa-info-circle'}
      size="small"
      colorClassName={'warning-text-color'}
      style={{ margin: '0 8px 1px 0' }}
    />
  );

  return (
    <div className={`disclaimer-styling nvstr-dollar-amounts-disclaimer`}>
      {icon}
      <span className="secondary-text-color">{message}</span>
    </div>
  );
};

export default NeverShowDollarAmt;
