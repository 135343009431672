import { footerAdapter } from '../adapters/footerAdapter';

import { ADD_FOOTER_PARTIAL, FOOTER_LOAD_ERROR } from '../constants';

export const getFooterPartial = () => {
  return function (dispatch) {
    return footerAdapter.getFooterPartial().then((response) => {
      if (response && response.data && response.data.partial) {
        dispatch({
          type: ADD_FOOTER_PARTIAL,
          payload: response.data.partial,
        });
      } else {
        dispatch({
          type: FOOTER_LOAD_ERROR,
        });
      }
    });
  };
};
