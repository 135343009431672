import React from 'react';
import { Page } from '@src/main/components/layout';
import { Body2, Body3, Body5, Body6, H3 } from '@src/main/lib/nvstr-common-ui.es';
import { Container } from '@src/main/components/ui';
import { PRODUCT_NAME } from '@src/appConfig';
import { logMetricsTrackingEvent } from '@src/actions';
import InfoIcon from '@src/containers/UI/InfoIcon';
import { useFamousInvestors } from '@src/main/hooks/user/useFamousInvestors';
import { LoadingPage } from '@src/main/components/page';
import { FlatButton } from '@src/main/components/buttons';
import styled from 'styled-components';
import { getSecuritySymbolFromIdea } from '@src/helpers/ideaHelpers';
import { useNavigate } from 'react-router';

const FamousInvestorsListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 108px;
  padding: 8px 16px;

  border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  cursor: pointer;

  :hover {
    border: 1px solid;
    border-radius: 5px;
    border-color: ${({ theme }) => theme.themeColors.hoverComponentBackground};
  }

  @media (max-width: 800px) {
    padding: 8px;
  }
`;

const separateLeaderAndTitle = (p) => {
  const [leader, title] = p.split(', ');
  return [leader, title];
};

function FamousInvestorsListItem({ company, leader: leaderAndTitle, user_id }) {
  const navigate = useNavigate();

  const [name, title] = separateLeaderAndTitle(leaderAndTitle);

  const handleClick = () => {
    navigate(`/users/${user_id}/public_profile`);
  };

  const doNothing = () => null;
  return (
    <FamousInvestorsListItemWrapper onClick={handleClick}>
      <Container right={8}>
        <Body2>{name}</Body2>
        <Container>
          <Body5>{title}</Body5>
        </Container>
        <Container top={2}>
          <Body5 isLowContrast thin>
            {company}
          </Body5>
        </Container>
      </Container>
      <Container>
        <FlatButton onClick={doNothing}>View</FlatButton>
      </Container>
    </FamousInvestorsListItemWrapper>
  );
}

function getNameFromFamousInvestor(a) {
  return a.leader;
}

const sortFamousInvestorsList = (famousInvestors) => {
  return famousInvestors.sort((a, b) => {
    if (getNameFromFamousInvestor(a) < getNameFromFamousInvestor(b)) return -1;
    if (getNameFromFamousInvestor(a) > getNameFromFamousInvestor(b)) return 1;
    return 0;
  });
};

function FamousInvestorsList({ famousInvestors }) {
  return sortFamousInvestorsList(famousInvestors).map((f) => <FamousInvestorsListItem key={f.user_id} {...f} />);
}

export const FamousInvestors = () => {
  const { famousInvestors, isLoading } = useFamousInvestors();

  React.useEffect(() => {
    document.title = `${PRODUCT_NAME} - Famous Investors`;

    const event = 'View Thought Leaders';
    const properties = {
      'From React Router': true,
    };
    logMetricsTrackingEvent(event, properties)();
  }, []);

  return (
    <Page width={'700px'}>
      <H3>Famous Investors</H3>
      <Container>
        <Body5>Explore the portfolios of famous investors</Body5>
        <InfoIcon
          word={`tooltip_thought_leader`}
          position={`right`}
          style={{
            position: 'relative',
            display: 'inline-block',
            top: '2px',
            left: '4px',
          }}
        />
      </Container>

      <Container>
        {isLoading || famousInvestors === null ? (
          <Container top={24}>
            <LoadingPage flatStyle />
          </Container>
        ) : (
          <Container top={24}>
            <FamousInvestorsList famousInvestors={famousInvestors} />
          </Container>
        )}
      </Container>
    </Page>
  );
};
