import React from 'react';
import { throwError } from '../../helpers/devToolHelpers';
import { cloneBasicObject } from '../../helpers/generalHelpers';

/*

  <BaseScroll
    $scrollElement -> $('') <jquery selector> -> for a custom parent element, if you do not want the BaseScroll ref element
    onScroll ->  (scrollData, prevScrollData) => <function> -> fire on scroll
    className -> <string> -> custom class selector
    style -> <object> -> custom style, use js syntax for css properties
    scrollTriggers -> is an array, if length of array changes, this will fire a handleScroll event
  >
    <children>
  </BaseScroll>

  scrollData = {
    scrollTop
    scrollHeight
    scrollDistance
    scrollDistanceToBottom
    scrollDirection -> 'up', 'down', null(init, hasn't scrolled)
  }

  scrollEvents = {
    onScroll
    //TODO:
    onReachBottom
    onReachTop
    onReachCustomPoint
  }

*/

export class BaseScroll extends React.Component {
  constructor() {
    super();
    this._setScrollData({
      scrollTop: 0,
      scrollHeight: 0,
      scrollDistance: 0,
      scrollDistanceToBottom: 0,
      scrollDirection: null,
    });
  }

  componentDidMount() {
    this._initialize();
  }

  componentDidUpdate(prevProps) {
    if (this.props.scrollTriggers && this.props.scrollTriggers.length !== prevProps.scrollTriggers.length) {
      const el = this._$returnScrollElement();
      this._handleScroll(el);
    }
  }

  componentWillUnmount() {
    this._terminate();
  }

  render() {
    const style = this.props.style || {};
    return (
      <div
        ref={(el) => (this.BaseScroll = el)}
        className={`react-base-scroll-container ${this.props.className || ''}`}
        style={style}
      >
        {this._renderChildren()}
      </div>
    );
  }

  _renderChildren = () =>
    this.props.renderChildrenWithoutScrollActions
      ? this.props.children
      : React.Children.map(this._returnChildren(), (child) =>
          React.cloneElement(child, this.returnScrollChildrenProps())
        );

  _returnChildren = () =>
    Array.isArray(this.props.children) ? this.props.children.filter((child) => child) : this.props.children;

  returnScrollChildrenProps = () => {
    return {
      scrollData: this.returnScrollData(),
    };
  };

  returnScrollData = () => this._scrollData;

  _setScrollData = (data) => (this._scrollData = data);

  _updateScrollData = (el) => {
    const data = {
      scrollTop: this._calcScrollTop(el),
      scrollHeight: this._calcScrollHeight(el),
      scrollableDistance: this._calcScrollableDistance(el),
      scrollDistance: this._calcScrollDistance(el),
      scrollDistanceToBottom: this._calcScrollDistanceToBottom(el),
      scrollDirection: this._calcScrollDirection(el),
    };
    // set scroll data after calculations so current scroll data is actually the prevScrollData
    this._setScrollData(data);
    return data;
  };

  _calcScrollDirection = (el) => (this.returnScrollData().scrollTop < this._calcScrollTop(el) ? 'down' : 'up');

  _calcScrollTop = (el) => $(el).scrollTop();

  _calcScrollableDistance = (el) => {
    const rect = this._$returnBaseScrollNode()[0].getBoundingClientRect();
    const height = rect.height;
    const top = rect.top;
    const scrollTop = this._calcScrollTop(el);
    return height + top + scrollTop;
  };

  _calcScrollOffsetTop = (el) => $(el).offset().top;

  _calcScrollHeight = (el) => $(el).height();

  _calcScrollContainerHeight = () => this._$returnScrollElement().height();

  _calcScrollDistance = (el) => this._calcScrollContainerHeight(el) + this._calcScrollTop(el);

  _calcScrollDistanceToBottom = (el) => this._calcScrollableDistance(el) - this._calcScrollDistance(el);

  _initialize = () => {
    this._initScrollListener();
  };

  _terminate = () => {
    this._removeScrollListener();
  };

  _handleScroll = (e) => {
    const target = e && e.originalEvent instanceof Event ? e.target : e;
    const prevScrollData = cloneBasicObject(this.returnScrollData());
    const scrollData = this._updateScrollData(target);
    this.props.onScroll && this.props.onScroll(scrollData, prevScrollData);
    return scrollData;
  };

  _initScrollListener = () => {
    if (!this._$returnScrollElement()) {
      throwError('Missing scroll element', true, {
        props: this.props,
      });
    }
    this._$returnScrollElement().on('scroll', this._handleScroll);
  };

  _removeScrollListener = () => {
    this._$returnScrollElement().off('scroll', this._handleScroll);
  };

  _$returnContainerElementHeight = () => this._$returnBaseScrollNode().height();

  _$returnContainerElement = () => this._$returnBaseScrollNode();

  _$returnScrollElement = () => this.props.$scrollElement || this._$returnBaseScrollNode();

  _returnBaseScrollNode = () => this.BaseScroll;

  _$returnBaseScrollNode = () => {
    if (!this.$BaseScroll) {
      this.$BaseScroll = $(this._returnBaseScrollNode());
    }
    return this.$BaseScroll;
  };
}
