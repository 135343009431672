import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {
  getPlaidConfigFromApi,
  linkAccountWithPlaid,
  logErrorEvent,
  logMetricsTrackingEvent,
  patchInstantLinkBankAccount,
  showModal,
} from '../../../../actions';
import { ExpandedPageBackground, Page } from '../../../components/layout';
import { LoadingPage } from '../../../components/page';
import { H5, Body5 } from '../../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../../components/buttons';
import { TrackingEvents } from '../../../../utils/tracking/events';
import { isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import { lerpBankAccountScaleToBurstValue, sendFacebookLTVEvent } from '../../../../constants/facebookTracking';
import { createBasicErrorModal } from '../../../../constants/modals';
import { CheckCircleFilled } from '../../../icons';
import { getPlaidLinkToken } from '../../../../actions/plaidActions';
import { LoadingOverlay } from '../../../components/ui';
import { usePlaidLink } from 'react-plaid-link';
import { REINIT_PLAID_STATE_KEY } from './PlaidReinitialization';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const Message = styled.div`
  padding-top: 24px;
`;
const Actions = styled.div`
  padding-top: 8px;
  text-align: center;
`;
const Content = styled.div`
  h5 {
    margin: 0;
  }
`;
const ActionsWrapper = styled.div`
  padding-top: 24px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const IconWrapper = styled.div`
  padding-top: 36px;
  text-align: center;
  svg {
    height: 36px;
    width: 36px;
  }
  path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

export const getPlaidWebhookUrl = () => {
  let baseUrl = window.origin;
  if (!baseUrl) {
    baseUrl = window.location.origin;
  }
  const url_suffix = 'plaid_webhook';
  const url = baseUrl + '/' + url_suffix;
  return url;
};

export const Plaid = (props) => {
  const {
    inline,
    onMissingLiveAccount,
    reauthToken,

    // not used in this component, but required for pass thru:
    // // onPlaidCancel,
    // // onPlaidContinue,
    // // onPlaidLinkSuccess,
    // // onPlaidLinkFailure,
    // // bankAccountId: tornadoBankAccountId,
  } = props;

  const [isReady, setIsReady] = React.useState(false);
  const [initFailed, setInitFailed] = React.useState(false);

  const [environment, setEnvironment] = React.useState(null);
  const [plaidAccountToken, setPlaidAccountToken] = React.useState(null);
  const [publicKey, setPublicKey] = React.useState(null);
  const [products, setProducts] = React.useState(null);

  React.useEffect(() => {
    const setup = async () => {
      try {
        const products = ['auth', 'identity'];
        let linkToken = null;

        const { ok: configOk, environment, public_key, error } = await getPlaidConfigFromApi()();

        if (error === 'User does not have a live trading account') {
          logMetricsTrackingEvent('Paper account was in live account onboarding path')();
          onMissingLiveAccount();
          return;
        }

        if (!reauthToken) {
          const { ok, link_token } = await getPlaidLinkToken();
          if (ok) {
            linkToken = link_token;
          } else {
            setInitFailed(true);
            return;
          }
        }

        const plaidAccountToken = reauthToken || linkToken;

        if (!configOk || error || !plaidAccountToken) {
          setInitFailed(true);
          return;
        }

        setPlaidAccountToken(plaidAccountToken);
        setEnvironment(environment);
        setPublicKey(public_key);
        setProducts(products);
        setIsReady(true);
        return null;
      } catch (e) {
        console.error('Plaid Init Failure catch', e);
      }
    };

    setup();
    logMetricsTrackingEvent('View Link Bank Account')();
  }, []);

  if (inline) {
    if (initFailed) return null;
    if (!isReady) return <LoadingOverlay />;
  }

  if (initFailed) {
    return (
      <Page width={'376px'}>
        <ContentWrapper>
          <Message>
            <Body5>Something went wrong. The page did not fully load. Try reloading the page.</Body5>
          </Message>
          <Actions>
            <FlatButton fullWidth onClick={() => window.location.reload()}>
              Reload Page
            </FlatButton>
          </Actions>
        </ContentWrapper>
      </Page>
    );
  }

  if (!isReady) return <LoadingPage />;

  return (
    <PlaidFlow
      {...props}
      environment={environment}
      plaidAccountToken={plaidAccountToken}
      publicKey={publicKey}
      products={products}
    />
  );
};

const PlaidFlow = ({
  inline,

  onPlaidCancel,
  onPlaidContinue,
  onPlaidLinkSuccess,
  onPlaidLinkFailure,

  bankAccountId: tornadoBankAccountId,

  reauthToken,
  environment,
  plaidAccountToken,
  publicKey,
  products,
}) => {
  const dispatch = useDispatch();
  const [hidePlaid, setHidePlaid] = React.useState(false);
  const [isLinkingAccount, setIsLinkingAccount] = React.useState(null);
  const [wasLinkSuccessful, setWasLinkSuccessful] = React.useState(false);

  const showError = React.useCallback((errorMessage) => {
    const modal = {
      contentComponent: createBasicErrorModal(errorMessage),
      dismissable: true,
      size: 'wide',
    };
    showModal(modal)(dispatch);
  }, []);

  const handleContinue = React.useCallback(() => {
    return onPlaidContinue(true);
  }, []);

  const handleCancel = React.useCallback(() => {
    onPlaidCancel && onPlaidCancel();
    return onPlaidContinue(false);
  }, []);

  const onLinkFailedContinue = React.useCallback((errorMessage) => {
    onPlaidLinkFailure(errorMessage);
  }, []);

  const onSuccess = async (public_token, metadata) => {
    setIsLinkingAccount(true);
    setHidePlaid(true);

    const properties = {
      Type: 'On Success',
      'Link Session Id': metadata.link_session_id,
      'Request Id': metadata.request_id,
    };
    logMetricsTrackingEvent('Plaid Event', properties)();

    let response;
    const params = {
      public_token: public_token,
      account_id: metadata.account_id,
    };

    if (reauthToken) {
      response = await patchInstantLinkBankAccount({ tornadoBankAccountId, ...params })(dispatch);
      const { bankAccountId } = response;
      onPlaidLinkSuccess && onPlaidLinkSuccess(bankAccountId);
      setWasLinkSuccessful(true);
      return;
    }

    response = await linkAccountWithPlaid(params)(dispatch);
    const { ok, error, account } = response;
    if (ok) {
      const available_balance_scale = account.available_balance_scale;
      onPlaidLinkSuccess && onPlaidLinkSuccess(account.id);

      if (!isUndefinedOrNull(available_balance_scale)) {
        const value = lerpBankAccountScaleToBurstValue(available_balance_scale);
        sendFacebookLTVEvent(value);
      }

      TrackingEvents.liveTradingSignUp.COMPLETED_BANK_ACCOUNT_LINK.send();

      setWasLinkSuccessful(true);
    } else {
      const defaultErrorMessage =
        'Something went wrong. Unable to link bank account at this time. Please try again later.';
      let errorMessage = defaultErrorMessage;
      if (error === 'The selected account is already linked. Please try a different account.') {
        errorMessage = error;
      }
      showError(errorMessage);
      TrackingEvents.liveTradingSignUp.LINK_PLAID_ACCOUNT_REQUEST_FAILURE.send({
        'Error Message': response.error || 'unknown',
      });
      onLinkFailedContinue(errorMessage);
      setIsLinkingAccount(false);
    }
  };

  const onExit = async (err, metadata) => {
    setIsLinkingAccount(false);

    // The user exited the Link flow.
    // metadata contains information about the institution
    // that the user selected and the most recent API request IDs.
    // Storing this information can be helpful for support.
    if (err != null) {
      logMetricsTrackingEvent('Plaid API Error', { err, metadata });
    }

    const properties = {
      Type: 'On Exit',
      'Link Session Id': metadata.link_session_id,
      'Request Id': metadata.request_id,
    };
    logMetricsTrackingEvent('Plaid Event', properties);

    handleCancel();
  };

  const onEvent = async (eventName, metadata) => {
    // Optionally capture Link flow events, streamed through
    // this callback as your users connect an Item to Plaid.
    // For example:
    // eventName = "TRANSITION_VIEW"
    // metadata  = {
    //   link_session_id: "123-abc",
    //   mfa_type:        "questions",
    //   timestamp:       "2017-09-14T14:42:19.350Z",
    //   view_name:       "MFA",
    // }

    let event = 'Plaid Event';
    const { link_session_id, request_id, error_type, error_code, error_message } = metadata;
    const properties = {
      Type: eventName,
      'Link Session Id': link_session_id,
      'Request Id': request_id,
      'Error Type': error_type,
      'Error Code': error_code,
      'Error Message': error_message,
    };
    logMetricsTrackingEvent(event, properties)();
  };

  const [config] = React.useState({
    clientName: 'Tornado',
    env: environment,
    key: publicKey,
    selectAccount: true,
    product: products,
    token: plaidAccountToken,
    webhook: getPlaidWebhookUrl(),

    receivedRedirectUri: null, // no re-init on this page

    onSuccess,
    onExit,
    onEvent,
  });

  const { open, exit, ready, error } = usePlaidLink(config);

  React.useEffect(
    () => {
      if (hidePlaid) {
        exit();
      }
    },
    [hidePlaid]
  );

  React.useEffect(
    () => {
      const reinitState = {
        wasReauth: !!reauthToken,
        linkToken: plaidAccountToken,
        bankAccountId: tornadoBankAccountId,
        pathname: window.location.pathname,
        search: window.location.search,
      };
      localStorage.setItem(REINIT_PLAID_STATE_KEY, JSON.stringify(reinitState));

      if (ready) {
        open();
      }

      return () => {
        exit();
      };
    },
    [ready, open]
  );

  if (inline) {
    if (wasLinkSuccessful || error) return null;

    if (isLinkingAccount) {
      return <LoadingOverlay message={'Linking Bank Account...'} />;
    }

    return null;
  }

  if (error) {
    return (
      <Page width={'376px'}>
        <ContentWrapper>
          <Message>
            <Body5>Something went wrong. The page did not fully load. Try reloading the page.</Body5>
          </Message>
          <Actions>
            <FlatButton fullWidth onClick={() => window.location.reload()}>
              Reload Page
            </FlatButton>
          </Actions>
        </ContentWrapper>
      </Page>
    );
  }

  if (wasLinkSuccessful) {
    return (
      <Page width={'600px'}>
        <Content>
          <IconWrapper>
            <CheckCircleFilled />
            <H5>Bank Link Completed</H5>
          </IconWrapper>
          <ActionsWrapper>
            <FlatButton fullWidth onClick={handleContinue}>
              Continue
            </FlatButton>
          </ActionsWrapper>
        </Content>
      </Page>
    );
  }

  if (isLinkingAccount) {
    return <LoadingPage message={'Linking Bank Account...'} />;
  }

  return <ExpandedPageBackground />;
};
