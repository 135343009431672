import { plaidInvestmentAdapter } from '../adapters/plaidInvestmentAdapter';

export const getPlaidInvestmentConfigFromApi = () => plaidInvestmentAdapter.getPlaidInvestmentConfigFromApi();

export const linkInvestmentAccountWithPlaid = (params) => plaidInvestmentAdapter.linkInvestmentAccountWithPlaid(params);

export const deleteInvestmentAccountWithPlaid = (plaidAccountId) =>
  plaidInvestmentAdapter.deleteInvestmentAccountWithPlaid(plaidAccountId);

export const getPlaidInvestmentAccountLinkToken = (plaidAccountId) =>
  plaidInvestmentAdapter.getPlaidInvestmentAccountLinkToken(plaidAccountId);

export const getPlaidInvestmentAccountPortfolioSync = (plaidAccountId) =>
  plaidInvestmentAdapter.getPlaidInvestmentAccountPortfolioSync(plaidAccountId);
