import React from 'react';
import { UserAvatarList } from '../../users/UserAvatarList';

export const NotificationHeading = (props) => {
  const { userIds, showEllipsisUserListAfter, showEllipsisUserListAfterIfExceedMaxShow, maxShowIdCount } = props;
  return (
    <div className={`notification-avatar-heading-container`}>
      <UserAvatarList
        userIds={userIds}
        maxShowIdCount={maxShowIdCount || 10}
        showEllipsisUserListAfterIfExceedMaxShow={showEllipsisUserListAfterIfExceedMaxShow}
        showEllipsisUserListAfter={showEllipsisUserListAfter}
      />
    </div>
  );
};
