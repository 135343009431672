import React from 'react';
import styled from 'styled-components';
import { Body5, Place, Row } from '@src/main/lib/nvstr-common-ui.es';
import { DollarAmount } from '../molecules/DollarAmount';
import { RefreshButton } from '../buttons/RefreshButton';
import MarketMonitor from '../../../containers/UI/MarketMonitor';
import NumberWithChangeIndicator from '../../../components/UI/NumberWithChangeIndicator';
import InfoIcon from '../../../containers/UI/InfoIcon';
import { securityDataFormatTable } from '../../../helpers/securitiesHelpers';
import { returnChangeSymbolPrefix } from '../../../helpers/numberHelpers';
import { Container, Spacing, WarningNotice } from '../ui';
import { SvgLoadingIcon, SVG_LOADING_ICON_TYPES } from '../ui/SvgLoadingIcon';
import { useDispatch } from 'react-redux';
import { showModal } from '@src/actions';
import { convertHexToRGBA } from '@src/main/lib/nvstr-utils.es';
import DepositPromoModal from '@src/components/modals/DepositPromoModal';
import { Modal } from '@src/main/components/layout';
import { FlatButton } from '@src/main/components/buttons';
import { MissingPricingBanner } from '@src/main/components/Portfolio/MissingPricesBanner';

const DesktopWrapper = styled.div`
  width: 100%;
  font-size: 18px;
  border-radius: 4px;

  background-color: ${({ theme }) => theme.themeColors.component};
`;

const PaddingWrapper = styled.div`
  padding: 16px 24px;
`;

const Separator = styled.div`
  border: 1.2px solid ${({ theme }) => theme.themeColors.appBackground};
`;

const Label = styled.span`
  display: block;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  opacity: var(--de-emphasized-text-opacity);
`;

const PortfolioValueWrapper = styled.div`
  line-height: 36px;
`;

const PortfolioValue = styled.span`
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.02em;
  font-weight: 500;
`;

const PendingEquityWrapper = styled.div`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;

  padding-top: 8px;
`;

const PortfolioValueContainer = styled.div`
  display: inline-block;
  min-width: 210px;
`;

const RowWrapper = styled(Row)`
  justify-content: space-between;
`;

const PortfolioDataCellWrapper = styled.div`
  min-width: 160px;

  span {
    font-size: 12px;
    line-height: 20px;
  }

  .value-wrapper {
    font-weight: 600;
  }
`;

const MarketOverviewWrapper = styled.div`
  /* padding-top: 10px; */
  min-width: 200px;

  span {
    font-size: 12px;
    line-height: 20px;
  }
`;

const FooterItem = styled.div`
  padding-right: 24px;
`;

const ExtendedHoursDayChange = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 200;

  * {
    font-size: 12px;
    line-height: 18px;
    font-weight: 200;
  }
`;
const ExtendedHoursValue = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 200;

  opacity: 0.6;
`;
const ExtendedHoursAsOfValue = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 200;
`;

const DayChangeWrapper = styled.div`
  font-size: 12px;
  line-height: 20px;
  padding-top: 8px;
`;

const FooterItemText = styled.div`
  font-size: 12px;
  line-height: 20px;
  //opacity: var(--de-emphasized-text-opacity);

  .lt-d-last {
    font-size: 12px;
    line-height: 20px;
    opacity: var(--de-emphasized-text-opacity);
  }
`;

export const DesktopPortfolioSummary = ({
  missingPricesForSecurityIds,
  isPortfolioLoading,
  portfolioValue,
  pendingEquityValue,
  pendingEquityReasons,
  dayChange,
  cashBalance,
  extendedTradingPrefix,
  isOpen,
  isInExtendedTrading,
  isExtendedPricingEnabled,
  extendedHoursValue,
  extendedHoursValueAsOfTimeDisplay,
  showRefreshButton,
  pricingAsOfTimeDisplay,
  isRefreshing,
  handleRefresh,
  isLiveTrading,
  isLiveTradingApproved,
}) => {
  const isShowingExtendedPricing = !isOpen && !isExtendedPricingEnabled;
  const showAsOfPricingDisplay = pricingAsOfTimeDisplay && isShowingExtendedPricing;
  return (
    <DesktopWrapper>
      <PaddingWrapper>
        <RowWrapper>
          <PortfolioValueContainer>
            <Label>Portfolio Value</Label>
            <Container top={8}>
              <PortfolioValueWrapper>
                {isPortfolioLoading ? (
                  <Container centerAll height={36} width={80}>
                    <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.balls} />
                  </Container>
                ) : (
                  <PortfolioValue>
                    <DollarAmount value={portfolioValue} />
                  </PortfolioValue>
                )}
              </PortfolioValueWrapper>
            </Container>
            {showAsOfPricingDisplay && (
              <Container top={0}>
                <FooterItemText>{pricingAsOfTimeDisplay}</FooterItemText>
              </Container>
            )}
            {null && (
              <>
                {isExtendedPricingEnabled && isInExtendedTrading && extendedHoursValue !== null && (
                  <Container top={4}>
                    <ExtendedHoursValue>
                      <Container row verticallyCentered flexWrap>
                        {extendedTradingPrefix}: <DollarAmount value={extendedHoursValue} />
                        &nbsp;
                        <NumberWithChangeIndicator
                          valueFormat={'percentage'}
                          value={dayChange.extended.percentage}
                          customDisplayValue={`${returnChangeSymbolPrefix(
                            dayChange.extended.percentage
                          )}${securityDataFormatTable.float(Math.abs(dayChange.extended.percentage))}%`}
                          hideIcon
                        />
                        <Spacing left={6} />
                        <ExtendedHoursAsOfValue>{extendedHoursValueAsOfTimeDisplay}</ExtendedHoursAsOfValue>
                      </Container>
                    </ExtendedHoursValue>
                  </Container>
                )}
              </>
            )}
            {!isLiveTrading && (
              <Container top={4}>
                <WarningNotice message={'This is a simulated account - no real money is at risk.'} />
              </Container>
            )}
            {missingPricesForSecurityIds?.length > 0 && (
              <Container top={8} bottom={4}>
                <MissingPricingBanner missingPricesForSecurityIds={missingPricesForSecurityIds} />
              </Container>
            )}
            {pendingEquityValue > 0 && (
              <PendingEquityWrapper>
                <Place style={{ display: 'inline-block' }}>
                  <span>{isLiveTradingApproved ? 'Pending: ' : 'Pending account approval: '}</span>
                  <DollarAmount value={pendingEquityValue} />
                  <InfoIcon
                    customMessage={pendingEquityReasons}
                    style={{ top: '0px', right: '-18px' }}
                    trackingMessage={'Pending Equity Reasons'}
                  />
                </Place>
              </PendingEquityWrapper>
            )}
          </PortfolioValueContainer>
          <Row>
            <PortfolioDataCellWrapper>
              <Label>Day Change</Label>
              <DayChangeWrapper>
                {isPortfolioLoading || (dayChange && (dayChange.value === null || isNaN(dayChange.value))) ? (
                  <Container centerAll height={36} width={80}>
                    <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.balls} />
                  </Container>
                ) : (
                  <Container>
                    <Container row verticallyCentered>
                      <NumberWithChangeIndicator
                        valueFormat={'price'}
                        value={dayChange.value}
                        customDisplayValue={`${returnChangeSymbolPrefix(
                          dayChange.value
                        )}$${securityDataFormatTable.float(Math.abs(dayChange.value))}`}
                        coloredText
                      />
                      <Spacing left={6} />
                      <NumberWithChangeIndicator
                        valueFormat={'percentage'}
                        value={dayChange.percentage}
                        customDisplayValue={`${returnChangeSymbolPrefix(
                          dayChange.percentage
                        )}${securityDataFormatTable.float(Math.abs(dayChange.percentage))}%`}
                        coloredText
                        hideIcon
                      />
                    </Container>
                  </Container>
                )}
              </DayChangeWrapper>
            </PortfolioDataCellWrapper>
            <PortfolioDataCellWrapper>
              <Label className=" ">Cash Balance</Label>
              <div
                style={{
                  fontSize: '12px',
                  lineHeight: '20px',
                  paddingTop: '8px',
                }}
              >
                <span>{!isNaN(cashBalance) && <DollarAmount value={cashBalance} />}</span>
              </div>
            </PortfolioDataCellWrapper>
            <MarketOverviewWrapper>
              <Label className=" ">Market</Label>
              <div
                style={{
                  fontSize: '12px',
                  lineHeight: '20px',
                  paddingTop: '8px',
                }}
              >
                <MarketMonitor />
              </div>
            </MarketOverviewWrapper>
          </Row>
        </RowWrapper>
      </PaddingWrapper>

      {showRefreshButton && (
        <>
          <Separator />

          <PaddingWrapper>
            <Row vAlign="center">
              <FooterItem>
                <RefreshButton isRefreshing={isRefreshing} onClick={handleRefresh} />
              </FooterItem>
            </Row>
          </PaddingWrapper>
        </>
      )}
    </DesktopWrapper>
  );
};
