import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const rewardsAdapter = {
  getRewardsData: () => {
    const options = { ignoreAuth: false };
    return sendRequest('get', [`${URL}/referral_rewards`], options).then((response) => response);
  },

  prepareFundingReward: () => {
    const body = {
      choose_from_count: 1,
    };
    const options = { ignoreAuth: false };
    return sendRequest('post', [`${URL}/funding_rewards/prepare`, body], options).then((response) => response);
  },

  redeemFundingReward: () => {
    const body = {
      which_index: 0,
    };
    const options = { ignoreAuth: false };
    return sendRequest('post', [`${URL}/funding_rewards/redeem`, body], options).then((response) => response);
  },

  prepareReferralReward: () => {
    const body = {
      choose_from_count: 1,
    };
    const options = { ignoreAuth: false };
    return sendRequest('post', [`${URL}/referral_rewards/prepare`, body], options).then((response) => response);
  },

  redeemReferralReward: () => {
    const body = {
      which_index: 0,
    };
    const options = { ignoreAuth: false };
    return sendRequest('post', [`${URL}/referral_rewards/redeem`, body], options).then((response) => response);
  },
};
