import React from 'react';
import CurrentUserDataWrapper from '../users/CurrentUserDataWrapper';
import LiveAccountSettingsForm from './LiveAccountSettingsForm';
import EditEmailForm from './EditEmailForm';
import EditPasswordForm from './EditPasswordForm';
import EditSubscriptionBilling from './EditSubscriptionBilling';
import ViewSubscriptionsPage from './components/ViewSubscriptionsPage';
import CloseAccountAction from './CloseAccountAction';
import { Container } from '@src/main/components/ui';
import { FlatButton } from '@src/main/components/buttons';
import { useNavigate } from 'react-router-dom';
import { webappRoutes } from '@src/main/lib/nvstr-utils.es';

const SectionContainer = ({ children }) => {
  return <Container bottom={24}>{children}</Container>;
};

const EditAccount = () => {
  return (
    <div className={`edit-account-form-container`}>
      <SectionContainer>
        <LiveAccountSettingsForm />
      </SectionContainer>

      <CurrentUserDataWrapper renderIf="hasSubscribed">
        <SectionContainer>
          <EditSubscriptionBilling />
        </SectionContainer>
      </CurrentUserDataWrapper>

      <CurrentUserDataWrapper renderIf="isEligibleSubscriptionsNotSubscribed">
        <SectionContainer>
          <ViewSubscriptionsPage />
        </SectionContainer>
      </CurrentUserDataWrapper>

      <SectionContainer>
        <EditEmailForm />
      </SectionContainer>

      <SectionContainer>
        <EditPasswordForm />
      </SectionContainer>

      <SectionContainer>
        <CloseAccountAction />
      </SectionContainer>
    </div>
  );
};

export default EditAccount;
