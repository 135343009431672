import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Page } from '../components/layout';
import { Container } from '../components/ui';
import OrderHistoryLink from '../../containers/Orders/OrderHistoryLink';
import OrdersPlacedToday from '../../containers/Orders/OrdersPlacedToday';
import OrdersAwaitingApproval from '../../containers/Orders/OrdersAwaitingApproval';
import {
  fetchCurrentUserQuickInfo,
  getOrders,
  getOrdersV1,
  logMetricsTrackingEvent,
  showComponentModal,
} from '@src/actions';
import { areUnfilledOrders } from '@src/helpers/ordersHelpers';
import { CreateOrderSearch } from '../containers/Orders/CreateOrderSearch';
import { useIsOrderCartActive } from '@src/main/hooks/orders/useIsOrderCartActive';
import { useSearchParams } from 'react-router-dom';
import { FlatButton } from '@src/main/components/buttons';
import { CreateOptimizedCartController } from '@src/main/containers/Orders/CreateOptimizedCartController';
import { OrderCartController } from '@src/main/containers/Orders/OrderCartController';
import { UserTradingAccountInfos } from '@src/main/containers/Orders/UserTradingAccountInfo';
import { PRODUCT_NAME } from '@src/appConfig';
import { useOrdersAwaitingApproval, usePlacedOrders } from '@src/main/hooks/orders/useOrdersData';
import { useIsMobileSize, useWindowSize } from '@src/main/hooks/util';
import { ORDER_TRANSACTION_TYPES } from '@src/main/constants/orders';
import { COMPONENT_MODAL_TYPES } from '@src/main/constants';
import { TrackingEvents } from '@src/utils/tracking/events';

const Wrapper = styled.div``;

const OrderHistory = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobileSize();
  const { width: windowWidth } = useWindowSize();

  const placedOrders = usePlacedOrders();
  const awaitingApprovalOrders = useOrdersAwaitingApproval();

  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const showRefreshButton = areUnfilledOrders(placedOrders) || awaitingApprovalOrders.length > 0;

  const refreshUserData = () => {
    getOrdersV1()(dispatch);
    return fetchCurrentUserQuickInfo()(dispatch);
  };

  const handleRefresh = () => {
    setIsRefreshing(true);
    logMetricsTrackingEvent('Clicked Refresh Order Statuses', {})();
    const requests = [getOrdersV1()(dispatch), refreshUserData()];
    Promise.all(requests).then((r) => {
      setIsRefreshing(false);
    });
  };

  return (
    <>
      <OrdersPlacedToday
        isMobile={isMobile}
        windowWidth={windowWidth}
        isRefreshing={isRefreshing}
        showRefreshButton={showRefreshButton}
        handleRefresh={handleRefresh}
        refreshUserData={refreshUserData}
        ordersPlacedTodayList={placedOrders}
      />
      <OrdersAwaitingApproval
        isMobile={isMobile}
        windowWidth={windowWidth}
        isRefreshing={isRefreshing}
        showRefreshButton={showRefreshButton}
        handleRefresh={handleRefresh}
        refreshUserData={refreshUserData}
        ordersAwaitingApprovalList={awaitingApprovalOrders}
      />
    </>
  );
};

export const Orders = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const isOrderCartActive = useIsOrderCartActive();

  const [createOptimizedCart, setCreateOptimizedCart] = React.useState(false);
  const [isShowingCart, setIsShowingCart] = React.useState(false);
  const showOrderCart = isShowingCart || searchParams.get('cart') === '1';

  const openOrderFromFromURL = (operation, securityId) => {
    const m = {
      type: COMPONENT_MODAL_TYPES.order,
      props: {
        transactionType: operation,
        securityId,
        isFromOptimizer: false,
      },
    };
    showComponentModal(m)(dispatch);
    logMetricsTrackingEvent('Order Form Opened From URL', {
      Operation: operation,
      'Security ID': securityId,
    })();
  };

  React.useEffect(() => {
    getOrdersV1()(dispatch);
    getOrders()(dispatch);

    TrackingEvents.orders.VIEW_TRADE_PAGE.send();
    document.title = `${PRODUCT_NAME} - Trade`;
  }, []);

  React.useEffect(
    () => {
      const isCreateOptimizedCartPresent = searchParams.get('oo') === '1';
      if (isCreateOptimizedCartPresent) {
        setCreateOptimizedCart(true);
        setSearchParams({});
      }
    },
    [searchParams]
  );

  // TO SUPPORT OLD ROUTING
  React.useEffect(
    () => {
      const isOperationParamPresent = [ORDER_TRANSACTION_TYPES.buy, ORDER_TRANSACTION_TYPES.sell].includes(
        searchParams.get('operation')
      );
      if (isOperationParamPresent) {
        openOrderFromFromURL(searchParams.get('operation'), searchParams.get('security_id'));
        setSearchParams({});
      }
    },
    [searchParams]
  );

  const handleShowCartClick = () => {
    setIsShowingCart(true);
  };

  const handleCreateOptimizedCartComplete = () => {
    setCreateOptimizedCart(false);
    setIsShowingCart(true);
  };

  const handleOrderBasketComplete = () => {
    setCreateOptimizedCart(false);
    setIsShowingCart(false);
    setSearchParams({});
  };

  const handleCreateOptimizedCartCancel = () => {
    setCreateOptimizedCart(false);
    setIsShowingCart(false);
    setSearchParams({});
  };

  if (createOptimizedCart) {
    return (
      <CreateOptimizedCartController
        onComplete={handleCreateOptimizedCartComplete}
        onCancel={handleCreateOptimizedCartCancel}
      />
    );
  }

  if (showOrderCart) {
    return <OrderCartController onComplete={handleOrderBasketComplete} />;
  }

  return (
    <Page>
      <Wrapper>
        <UserTradingAccountInfos />
        <Container top={24}>
          <CreateOrderSearch />
        </Container>
        {isOrderCartActive && (
          <Container top={48} bottom={28}>
            <FlatButton onClick={handleShowCartClick}>Continue Optimizer Trading</FlatButton>
          </Container>
        )}
        <OrderHistory />
        <OrderHistoryLink />
      </Wrapper>
    </Page>
  );
};
