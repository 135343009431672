import React from 'react';
import { connect } from 'react-redux';
import { referralRewardLimit } from '../../../helpers/currentUserHelpers';

class RewardLimitReachedMessage extends React.PureComponent {
  render() {
    const { rewardPrizeLimit } = this.props;

    return (
      <div className={'max-prize-limit-message-container on-rewards-page  '}>
        <div className={'text-wrapper-container'}>
          {`You've reached the maximum total cash reward of $${rewardPrizeLimit}. You are no longer eligible to receive additional cash rewards.`}
        </div>
        <div className={'text-wrapper-container'}>{'Thank you for helping share intelligent investing.'}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const currentUser = state.currentUser;
  return {
    rewardPrizeLimit: referralRewardLimit(currentUser),
  };
};

export default connect(mapStateToProps)(RewardLimitReachedMessage);
