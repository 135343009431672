import React from 'react';
import VerticalNavItem from '../../components/VerticalNavItem';
import BookmarkIcon from '@src/main/components/Images/BookmarkIcon';
import CompassIcon from '@src/main/components/Images/CompassIcon';
import { Search } from '@src/main/assets/svgs/Search';

export const WatchlistVNavRoute = () => (
  <VerticalNavItem name="Watchlist" customIcon={<BookmarkIcon />} route="/ideas" />
);
export const ExploreStocksVNavRoute = () => (
  <VerticalNavItem
    name={'Search'}
    customIcon={<Search />}
    route="/ideas/explore"
    highlightOnTour={{
      tourStep: 2,
    }}
  />
);
export const ExplorerVNavRoute = () => (
  <VerticalNavItem name={'Explorer'} customIcon={<CompassIcon />} route="/ideas/portfolio_explorer" />
);
