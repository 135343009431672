import React from 'react';
import { connect } from 'react-redux';
import { openRebalancerPanel, logMetricsTrackingEvent } from '../../actions';
import Button from '../../components/buttons/Button';

class OpenRebalancerPanelButton extends React.PureComponent {
  render() {
    return null;
  }

  handleClick = () => {
    openRebalancerPanel()(this.props.dispatch);
    this.log();
    this.props.additionalClickAction && this.props.additionalClickAction();
  };

  log = () => {
    const event = 'Clicked Open Rebalancer Button';
    const properties = {
      Context: this.props.viewingContext,
    };
    logMetricsTrackingEvent(event, properties)();
  };
}

export default connect()(OpenRebalancerPanelButton);
