import {
  finance_notation,
  isFloat,
  convertPriceToNumberWithCommas,
  extraDecimalPlaceForSmallNum,
  customParseFloat,
} from './numberHelpers';
import {
  quickFetchSecuritiesData,
  fetchSecuritiesFundamentalsData,
  fetchSecuritiesPriceData,
} from '../actions/securitiesActions';

import { throwError } from './devToolHelpers';
import { getSecurityIdFromIdea } from './ideaHelpers';
import { formatLocaleString } from './generalHelpers';

export const securityDataFormatTable = {
  connections: (value) =>
    Array.isArray(value)
      ? value.map((v) => `${v.first_name} ${v.last_name}`)
      : `${value.first_name} ${value.last_name}`,
  exchange: (value) => (Array.isArray(value) ? value.map((v) => `${v.description}`) : `${value.description}`),
  sector: (value) => (Array.isArray(value) ? value.map((v) => `${v.description}`) : `${value.description}`),
  sectors: (value) => (Array.isArray(value) ? value.map((v) => `${v.description}`) : `${value.description}`),
  thought_leaders: (value) =>
    Array.isArray(value) ? value.map((v) => `${v.company} - ${v.leader}`) : `${value.company} - ${value.leader}`,
  conviction: (value) => `${value.name}`,
  thesis: (value) => `${value.name}`,
  horizon: (value) => `${value.name}`,
  idea_type: (value) => `${value.name}`,
  number: (value) => finance_notation(value),
  numberNoDecimal: (value) => finance_notation(value, true),
  smallNumber: (value) => {
    return formatLocaleString(customParseFloat(value), {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  },
  shares: (value) => {
    return formatLocaleString(customParseFloat(value), {
      minimumFractionDigits: 0,
      maximumFractionDigits: 5,
    });
  },
  float: (value) => {
    return formatLocaleString(customParseFloat(value), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },
  priceFloat: (value) => {
    const floatValue = customParseFloat(value);
    return formatLocaleString(floatValue, {
      minimumFractionDigits: 2,
      maximumFractionDigits: floatValue < 1 ? 4 : 2,
    });
  },
  price: (value) =>
    `${customParseFloat(convertPriceToNumberWithCommas(value)) < 0 ? '-' : ''}$${convertPriceToNumberWithCommas(
      value,
      true
    )}`,
  priceNearestDollar: (value) =>
    `${customParseFloat(convertPriceToNumberWithCommas(value)) < 0 ? '-' : ''}$${convertPriceToNumberWithCommas(
      Math.round(value),
      true,
      true
    )}`,
  price_fin_notation: (value) => `$${finance_notation(value)}`,
  price_fin_notation_no_decimal: (value) => `$${finance_notation(value, true)}`,
  ratio: (value) => {
    const decimalPlace = extraDecimalPlaceForSmallNum(value);
    return formatLocaleString(customParseFloat(value), {
      minimumFractionDigits: decimalPlace,
      maximumFractionDigits: decimalPlace,
    });
  },
  percentage: (value, options) => {
    let decimalPlace = 2;
    if (options) {
      if (options.decimalPlace) {
        decimalPlace = options.decimalPlace;
      }
    }
    const formattedValue = isFloat(value)
      ? formatLocaleString(customParseFloat(value), {
          minimumFractionDigits: decimalPlace,
          maximumFractionDigits: decimalPlace,
        })
      : value;
    return formattedValue + '%';
  },
  percentageFloat: (rawValue, options) => {
    let decimalPlace = 2;
    if (options) {
      if (options.decimalPlace) {
        decimalPlace = options.decimalPlace;
      }
    }
    let value = isFloat(rawValue) ? rawValue : customParseFloat(rawValue);
    const formattedValue = formatLocaleString(value, {
      minimumFractionDigits: decimalPlace,
      maximumFractionDigits: decimalPlace,
    });
    return formattedValue + '%';
  },
  unformatted: (value) => value,
};

const determineSecurityDataFormatType = (field) => {
  const table = {
    symbol: 'unformatted',
    name: 'unformatted',

    connections: 'connections',
    owned_by: 'connections',
    thought_leaders: 'thought_leaders',
    exchange: 'exchange',
    sector: 'sector',
    sectors: 'sectors',
    conviction: 'conviction',
    thesis: 'thesis',
    horizon: 'horizon',
    idea_type: 'idea_type',
    owned_as_of: 'unformatted',
    admin_created_date: 'unformatted',

    'Price / Sales': 'ratio',
    'Forward P/S Curr Yr': 'ratio',
    'Forward P/S': 'ratio',
    'Forward P/S Next Yr': 'ratio',
    'Total Enterprise Value': 'number',
    Beta: 'ratio',

    market_cap: 'number',
    'Market Cap': 'number',
    net_debt: 'number',
    'Net Debt': 'number',
    revenue_ltm: 'number',
    'Revenue LTM': 'number',
    'Net Income LTM': 'number',

    projected_revenue_growth: 'percentage',
    'Projected Revenue Growth': 'percentage',
    analyst_2yr_growth_projection: 'percentage',
    price_to_tangible_book_value: 'ratio',
    'Price / Tangible Book': 'ratio',
    sentiment: 'ratio',

    current_price: 'priceFloat',
    current_price_change: 'priceFloat',

    price_target: 'price',
    backward_pe_ratio: 'ratio',
    'Price / Earnings': 'ratio',
    forward_pe_ratio_this_year: 'ratio',
    'Forward P/E Curr Yr': 'ratio',
    forward_pe_ratio_next_year: 'ratio',
    'Forward P/E': 'ratio',
    forward_pe_ratio_next_next_year: 'ratio',
    'Forward P/E Next Yr': 'ratio',

    'Forward EPS Curr Qtr': 'price',
    'Forward EPS Curr Yr': 'price',
    'Forward EPS': 'price',
    'Forward EPS Next Yr': 'price',

    'Net Debt / EBITDA': 'ratio',
    'Forward Net Debt / EBITDA Curr Yr': 'ratio',
    'Forward Net Debt / EBITDA': 'ratio',
    'Total Debt / EBITDA': 'ratio',
    'Forward Total Debt / EBITDA Curr Yr': 'ratio',
    'Forward Total Debt / EBITDA': 'ratio',
    'EV / EBITDA': 'ratio',
    'Forward EV / EBITDA Curr Yr': 'ratio',
    'Forward EV / EBITDA': 'ratio',

    current_price_change_percent: 'percentage',
    dividend_yield: 'percentage',
    'Dividend Yield': 'percentage',
    expected_dividend: 'percentage',
    expected_return: 'percentage',
    allocation: 'percentage',
    optimized_allocation: 'percentage',

    percentage: 'percentage',
    ratio: 'ratio',
    number: 'number',
    float: 'float',
    price: 'price',

    correlation: 'percentage',
  };

  if (!table[field]) {
    return 'unformatted';
  }

  return table[field];
};

export const findSecurity = function (securityId, securitiesStore) {
  if (!securityId) {
    throwError(['Error: no securityId provided', arguments]);
    return null;
  }
  if (!securitiesStore || !('lookup' in securitiesStore)) {
    throwError(['Error: invalid security store provided', arguments]);
    return null;
  }
  const security = securitiesStore.lookup[securityId];
  if (!security) {
    return null;
  }
  return security;
};

const formatNullValue = (property) => {
  if (property === 'price_target') {
    return '';
  } else if (property === 'allocation' || property === 'optimized_allocation') {
    return '0%';
  } else {
    return 'N/A';
  }
};

export const formatSecurityPropertyValue = (value, property) => {
  if (!value && value !== 0) {
    return formatNullValue(property);
  }

  const formatToValueType = determineSecurityDataFormatType(property);
  if (!(formatToValueType in securityDataFormatTable)) {
    return formatNullValue(property);
  }

  return securityDataFormatTable[formatToValueType](value);
};

export const formatSecurityDataValue = (value, field) => {
  if (!value && value !== 0) {
    if (field === 'price_target') {
      return '';
    } else if (field === 'allocation' || field === 'optimized_allocation') {
      return '0%';
    } else {
      return 'N/A';
    }
  }
  const type = determineSecurityDataFormatType(field);

  if (!(type in securityDataFormatTable)) {
    return 'N/A';
  }

  // TODO: needs refactoring when there are tests -> implement one solution for this across entire front-end
  return (
    (determineSecurityDataFormatType(field) in securityDataFormatTable || '') &&
    securityDataFormatTable[determineSecurityDataFormatType(field)](value)
  );
};

export const formatDataValue = (value, type) => {
  if (!value && value !== 0) {
    return 'N/A';
  }

  if (!(type in securityDataFormatTable)) {
    return 'N/A';
  }

  const newValue = securityDataFormatTable[type](value);
  return newValue;
};

export const displaySecurityData = (field, security) => {
  const value = security[field];
  if (!value && value !== 0) {
    return 'N/A';
  }

  return (
    (determineSecurityDataFormatType(field) in securityDataFormatTable || '') &&
    securityDataFormatTable[determineSecurityDataFormatType(field)](value)
  );
};

export const displayFinancialValue = (field, value) => {
  if (!value && value !== 0) {
    return 'N/A';
  }

  return (
    (determineSecurityDataFormatType(field) in securityDataFormatTable || '') &&
    securityDataFormatTable[determineSecurityDataFormatType(field)](value)
  );
};

const determineInputWidth = (id) => {
  const element = $(`#${id}`);
  return element && element.width();
};

export const truncateNameToWidth = (string, id) => {
  const inputWidth = determineInputWidth(id);
  const maxChar = Math.floor(inputWidth / 14);

  if (string.length >= maxChar) {
    return string.substring(0, maxChar - 1) + '...';
  } else {
    return string;
  }
};

export const truncateNameWithWidth = (string, width, offsetValue) => {
  const maxChar = Math.floor(width / offsetValue);
  if (string.length >= maxChar) {
    return string.substring(0, maxChar - 1) + '...';
  } else {
    return string;
  }
};

export const objectPropFoundInArray = (prop, obj, array) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i][prop] === obj[prop]) {
      return true;
    }
  }
  return false;
};

export const determineIfAnimating = (prop, obj, array) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === obj[prop]) {
      return true;
    }
  }
  return false;
};

export const addInterestSentiment = (sentiment, ideas) => {
  const array = [];
  ideas.forEach((idea) => {
    idea.interest = 1;
    idea.sentiment = sentiment;
    array.push(idea);
  });
  return array;
};

export const isLoadingFundamentalData = (securityId, fundamentalDataStore) => {
  return !(securityId in fundamentalDataStore.securities);
};

export const getSecurityValueFromStore = (securityId, property, securitiesStore, fundamentalDataStore) => {
  if (!securityId) {
    throwError(['Error: no securityId provided', arguments]);
    return null;
  }
  if (!property) {
    throwError(['Error: no property provided', arguments]);
    return null;
  }
  if (typeof property !== 'string') {
    throwError([`Error: expecting property value of a string but got ${typeof property}`, arguments]);
    return null;
  }
  if (!securitiesStore || !('lookup' in securitiesStore)) {
    throwError(['Error: invalid security store provided', arguments]);
    return null;
  }
  if (!fundamentalDataStore || !('securities' in fundamentalDataStore)) {
    throwError(['Error: invalid fundamental data store provided', arguments]);
    return null;
  }

  const securityData = securitiesStore.lookup[securityId];
  const fundamentalData = fundamentalDataStore.securities[securityId];
  const allSecurityData = { ...securityData, ...fundamentalData };
  const value = allSecurityData[property];
  const isDataStillLoading = isLoadingFundamentalData(securityId, fundamentalDataStore);
  if (value === undefined && isDataStillLoading) {
    return { loading: true, value };
  }
  return { value };
};

export const getValueNotation = function (fieldName) {
  const conversion = {
    1000000000000: 'trillions',
    1000000000: 'billions',
    1000000: 'millions',
    1000: 'thousands',
    100: 'hundreds',
  };

  const value = convertValuesFromNotation(fieldName);
  return conversion[value] || null;
};

export const convertValuesFromNotation = (fieldName, value) => {
  const fieldDict = {
    'Net Debt': 1000000,
    'Market Cap': 1000000,
    'Revenue LTM': 1000000,
  };

  // used for getValueNotation
  if (value === undefined) {
    return fieldDict[fieldName];
  }

  return fieldDict[fieldName] ? fieldDict[fieldName] * value : value;
};

export const fetchDataForSecurities = function (securityIds, config) {};

const _sortSecuritiesAlphabetically = (securities) => {
  const sortedSecurities = securities.sort((a, b) => {
    if (!a.symbol) {
      console.error('There was no symbol to sort by.', a);
      return [];
    }
    if (!b.symbol) {
      console.error('There was no symbol to sort by.', b);
      return [];
    }

    if (a.symbol < b.symbol) return -1;
    if (a.symbol > b.symbol) return 1;
    return 0;
  });
  return sortedSecurities;
};

export const sortListOfSecurities = (securities, sortOrder) => {
  if (sortOrder === 'alphabetically') {
    return _sortSecuritiesAlphabetically(securities);
  }

  console.error('Sort order not specified');
};

export const fetchSecurityDataForIdeas = (dispatch, ideas) => {
  const securities = ideas.map((idea) => getSecurityIdFromIdea(idea));
  const dataAPICalls = [quickFetchSecuritiesData, fetchSecuritiesFundamentalsData, fetchSecuritiesPriceData];

  return Promise.all(dataAPICalls.map((apiCall) => apiCall(securities)(dispatch)));
};

export const returnPricingForSecurity = (securityId, securitiesPriceStore) => {
  if (!securityId) {
    return console.error('No securityId provided to returnPricingForSecurity');
  }
  if (!securitiesPriceStore || !('securities' in securitiesPriceStore)) {
    return console.error('Invalid or missing securitiesPriceStore provided to returnPricingForSecurity');
  }

  const allSecuritiesPriceData = securitiesPriceStore.securities;
  const securityPriceData = allSecuritiesPriceData[securityId] || {};
  return securityPriceData;
};

export const convertIdeasToSecurityIds = (ideas) => ideas.map((idea) => getSecurityIdFromIdea(idea));

/* DEPRECATED METHODS ABOVE */
/* DEPRECATED METHODS ABOVE */
/* DEPRECATED METHODS ABOVE */

export const returnSecurityStoreLookup = (securitiesStore) => securitiesStore.lookup;

export const returnSecurityLoadingList = (securitiesStore) => securitiesStore.loadingSecuritiesList;

export const returnSecurityFromStore = function (securityId, securitiesStore) {
  if (!securityId) {
    throwError('Error: no securityId provided to returnSecurity', true);
  }
  if (!securitiesStore || !('lookup' in securitiesStore)) {
    throwError('Error: invalid security store provided provided to returnSecurity', true);
  }
  const security = returnSecurityStoreLookup(securitiesStore)[securityId];
  return security || null;
};

export const isRequestingSecurityData = (securityId, securitiesStore) =>
  returnSecurityLoadingList(securitiesStore).includes(securityId);

export const isSecurityDataLoading = (securityId, securitiesStore) =>
  isRequestingSecurityData(securityId, securitiesStore) && !returnSecurityFromStore(securityId, securitiesStore);

export const isSecurityDataUpdating = (securityId, securitiesStore) =>
  isRequestingSecurityData(securityId, securitiesStore) && returnSecurityFromStore(securityId, securitiesStore);

export const isSecurityDataAvailable = (securityId, securitiesStore) => {
  const security = returnSecurityFromStore(securityId, securitiesStore);
  return security && Object.keys(security).length > 0;
};

export const getSecuritySymbolFromSecurity = (security) => security.symbol;

export const getSecurityIdsAndSymbolsFromSecurities = (securities) => {
  const securityIds = [];
  const securitySymbols = [];
  securities.forEach((s) => {
    securityIds.push(s.id);
    securitySymbols.push(getSecuritySymbolFromSecurity(s));
  });
  return {
    securityIds,
    securitySymbols,
  };
};
