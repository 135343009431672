import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useCurrentUser, useLiveAccount } from '../../hooks/user';
import { logMetricsTrackingEvent, submitLiveTradingSignUpForm } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import { H5, Bank, Body5, Lock, Paper, Shield } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { useFormik } from 'formik';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { PRODUCT_NAME } from '@src/appConfig';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 466px;
  margin: 0 auto;
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 24px;
`;
const IconMessage = styled.div`
  padding-top: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  text-align: left;

  svg {
    height: 28px;
    width: 28px;

    * {
      fill: ${({ theme }) => theme.themeColors.text};
    }

    margin-right: 16px;
  }
`;
const BankWrapper = styled.div`
  svg {
    height: 24px;
    width: 24px;
  }
`;
const PaperWrapper = styled.div`
  svg {
    height: 26px;
    width: 26px;
  }
`;
const ShieldWrapper = styled.div`
  svg {
    height: 24px;
    width: 24px;
  }
`;
const Actions = styled.div`
  padding-top: 24px;
  text-align: center;
`;

const getInitialValuesFrom = (liveAccount) => {
  return {
    acknowledged_patriot_act: true,
  };
};

const massageFormData = (v) => v;

const FORM_PAGE_NAME = 'identity';

export const EnterIdentity = ({ onContinue, onboardingState }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const liveAccount = useLiveAccount();

  const [isReady, setIsReady] = React.useState(true);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const form = useFormik({
    initialValues: getInitialValuesFrom(currentUser, liveAccount),
    enableReinitialize: false,
  });
  const { values, errors, setFieldValue, validateForm } = form;

  const submitForm = React.useCallback((values) => {
    const apiFormData = massageFormData(values);
    setIsSubmitting(true);
    return submitLiveTradingSignUpForm(apiFormData, FORM_PAGE_NAME)(dispatch);
  }, []);

  const handleSubmit = React.useCallback(
    async () => {
      logMetricsTrackingEvent('Submitted Identity Form')();

      const { status } = await submitForm(values);
      if (status === 200) {
        return {
          ok: true,
        };
      } else {
        setFormError('Something went wrong, please try again');
        setIsSubmitting(false);
        return {
          ok: false,
        };
      }
    },
    [values]
  );

  const handleContinueClick = React.useCallback(
    async () => {
      const { ok } = await handleSubmit();
      if (ok) onContinue(null, onboardingState);
    },
    [handleSubmit]
  );

  React.useEffect(() => {
    logMetricsTrackingEvent('View Identity Form')();
  }, []);

  const handlePatriotActClick = () => {
    const event = 'Clicked Patriot Act Link';
    logMetricsTrackingEvent(event)();
  };

  const handleBrokerCheckClick = () => {
    const event = 'Clicked Broker Check Link';
    logMetricsTrackingEvent(event)();
  };

  const handleWhyDoWeNeedThisClick = () => {
    const event = 'Clicked "Why Do We Need This?" Link';
    logMetricsTrackingEvent(event)();
  };

  if (!isReady) return <LoadingPage />;

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.identity}>
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>Identity Information</H5>
          </Heading>

          <Message>
            <IconMessage>
              <BankWrapper>
                <Bank />
              </BankWrapper>
              <Body5>
                Federal law requires {PRODUCT_NAME} and all broker-dealers to verify your identity. You can find out
                more about the SEC and USA Patriot Act requirements{' '}
                <a
                  href="https://www.sec.gov/fast-answers/answersbd-persinfohtm.html"
                  target="_blank"
                  rel="no-follow noopener noreferrer"
                  onClick={handlePatriotActClick}
                >
                  here
                </a>
                .
              </Body5>
            </IconMessage>
          </Message>

          <Message>
            <IconMessage>
              <PaperWrapper>
                <Paper />
              </PaperWrapper>
              <Body5>
                We are registered with the SEC and FINRA. You can review our BrokerCheck page{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={'https://brokercheck.finra.org/firm/summary/269966'}
                  onClick={handleBrokerCheckClick}
                >
                  here
                </a>
                .
              </Body5>
            </IconMessage>
          </Message>

          <Message>
            <IconMessage>
              <ShieldWrapper>
                <Shield />
              </ShieldWrapper>
              <Body5>
                Your information is protected with a 128-bit encryption and stored securely. We do not sell your
                personally identifiable information.
              </Body5>
            </IconMessage>
          </Message>

          <Message>
            <IconMessage>
              <Lock />
              <Body5>This information will be kept private and it will never appear on your public profile.</Body5>
            </IconMessage>
          </Message>

          <Actions>
            <Container bottom={8} fullWidth centerAll>
              <a
                href="https://www.sec.gov/fast-answers/answersbd-persinfohtm.html"
                target="_blank"
                rel="no-follow noopener noreferrer"
                onClick={handleWhyDoWeNeedThisClick}
              >
                <FlatButton transparent fullWidth onClick={() => false}>
                  Why do we need this?
                </FlatButton>
              </a>
            </Container>

            <FormState error={formError} isSubmitting={isSubmitting} />
            <Container fullWidth centerAll>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleContinueClick}>
                Continue
              </FlatButton>
            </Container>
          </Actions>
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
