import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { logMetricsTrackingEvent, submitLiveTradingSignUpForm } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import {
  Body5,
  Lock,
  FloatingLabelDropdown,
  createDropdownItem,
  Toggle,
  FloatingLabelTextInput,
  createToggleItem,
  FloatingLabelExpiryDatePicker,
  H5,
} from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { useLiveAccount, useCurrentUser } from '../../hooks/user';
import { useFormik } from 'formik';
import { IdentityCitizenshipSchema } from '../../constants/formValidationSchemas';
import { createTimeInstance, formatLocalizedDateTime } from '../../../helpers/timeHelpers';
import { listCountryNames, listVisaTypes } from '../../../helpers/formHelpers';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const AdditionalInputs = styled.div`
  padding-top: 24px;
`;
const AdditionalHeader = styled.div`
  text-align: center;
`;
const VisaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const InputWrapper = styled.div`
  padding-top: 16px;
`;
const CountryDropdown = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.text};
`;

const FormWrapper = styled.div`
  padding-top: 40px;

  input {
    border: none !important;
    line-height: 29px;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }

  select {
    border: none !important;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
  .DayPicker-Day {
    padding: 1em;
  }
  .DayPicker-NavBar {
    span:first-child {
      right: 0;
    }
    span:last-child {
      right: 0;
    }
  }
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 24px;
`;
const IconMessage = styled.div`
  padding-top: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  text-align: left;

  svg {
    height: 24px;
    width: 24px;

    fill: ${({ theme }) => theme.themeColors.text};

    margin-right: 16px;
  }
`;
const Actions = styled.div`
  padding-top: 36px;
  text-align: center;
`;

const citizenshipCountryItems = [
  createDropdownItem({ text: 'Citizenship Country', value: '' }),
  createDropdownItem({ text: '---', value: '---', disabled: true }),
  ...listCountryNames(true).map((c) => createDropdownItem({ text: c, value: c })),
];

const birthCountryItems = [
  createDropdownItem({ text: 'Birth Country', value: '' }),
  createDropdownItem({ text: '---', value: '---', disabled: true }),
  ...listCountryNames().map((c) => createDropdownItem({ text: c, value: c })),
];

const permanentResidentToggleItems = [
  createToggleItem({ text: 'Yes', value: 'YES' }),
  createToggleItem({ text: 'No', value: 'NO' }),
];

const visaTypes = [
  createDropdownItem({ text: '--', value: '', disabled: true }),
  ...listVisaTypes().map((c) => createDropdownItem({ text: c, value: c })),
];

const massageAPIDate = (d) => {
  if (!d) {
    return null;
  }

  const [year, month, day] = d.split('-');
  return createTimeInstance(`${month}/${day}/${year}`).toDate();
};

const getInitialValuesFrom = (currentUser, liveAccount) => {
  const {
    citizenship_country,
    is_permanent_resident,
    green_card_number,
    green_card_expiration_date,
    birth_country,
    visa_type,
    visa_number,
    visa_expiration_date,
  } = liveAccount;
  return {
    citizenship_country: citizenship_country || '',

    is_permanent_resident: is_permanent_resident || '',

    green_card_number: green_card_number || '',
    green_card_expiration_date: massageAPIDate(green_card_expiration_date) || new Date().toString(),

    birth_country: birth_country || '',
    visa_type: visa_type || '',
    visa_number: visa_number || '',
    visa_expiration_date: massageAPIDate(visa_expiration_date) || new Date().toString(),
  };
};

const massageDate = (v) => {
  return formatLocalizedDateTime('api', createTimeInstance(v));
};

const massageFormData = (formValues) => {
  const {
    citizenship_country,
    is_permanent_resident,
    green_card_number,
    green_card_expiration_date,
    birth_country,
    visa_type,
    visa_number,
    visa_expiration_date,
  } = formValues;
  const result = {};

  if (citizenship_country === 'United States of America') {
    result.citizenship_country = citizenship_country;
  } else if (is_permanent_resident === 'YES') {
    result.is_permanent_resident = is_permanent_resident;
    result.citizenship_country = citizenship_country;
    result.green_card_number = green_card_number;
    result.green_card_expiration_date = massageDate(green_card_expiration_date);
  } else {
    result.citizenship_country = citizenship_country;
    result.is_permanent_resident = is_permanent_resident;

    result.birth_country = birth_country;
    result.visa_type = visa_type;
    result.visa_number = visa_number;
    result.visa_expiration_date = massageDate(visa_expiration_date);
  }
  return result;
};

const FORM_PAGE_NAME = 'identity';

export const EnterCitizenshipStatus = ({ onContinue, onboardingState }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const liveAccount = useLiveAccount();

  const [isReady, setIsReady] = React.useState(true);
  const [showAdditionalInputs, setShowAdditionalInputs] = React.useState(false);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const form = useFormik({
    initialValues: getInitialValuesFrom(currentUser, liveAccount),
    validationSchema: IdentityCitizenshipSchema,
    enableReinitialize: false,
  });
  const { values, errors, setFieldValue, validateForm } = form;

  const handleChange = React.useCallback(
    (name) => (value) => {
      if (typeof value === 'object') {
        const formattedValue = formatLocalizedDateTime('L', createTimeInstance(value));
        setFieldValue(name, formattedValue, false);
        return;
      }
      setFieldValue(name, value, false);
    },
    [setFieldValue]
  );

  const submitForm = React.useCallback((values) => {
    const apiFormData = massageFormData(values);
    setIsSubmitting(true);
    return submitLiveTradingSignUpForm(apiFormData, FORM_PAGE_NAME)(dispatch);
  }, []);

  const handleSubmit = React.useCallback(async () => {
    logMetricsTrackingEvent('Submitted Identity Citizenship Form')();

    const validation = await validateForm();
    if (Object.keys(validation).length === 0) {
      const { status } = await submitForm(values);
      if (status === 200) {
        return {
          ok: true,
        };
      } else {
        setFormError('Something went wrong, please try again');
        setIsSubmitting(false);
        return {
          ok: false,
        };
      }
    } else {
      logMetricsTrackingEvent('Failed Live Account Validations', {
        Fields: Object.entries(validation),
        'Form Name': 'citizenship',
      })();
      // could log errors but no form validations on this form
      setIsSubmitting(false);
      return {
        errors: true,
      };
    }
  }, [values]);

  const handleContinueClick = React.useCallback(async () => {
    const { ok } = await handleSubmit();
    if (ok) onContinue(null, onboardingState);
  }, [handleSubmit]);

  React.useEffect(() => {
    logMetricsTrackingEvent('View Identity Citizenship Form')();
  }, []);

  React.useEffect(() => {
    if (!['', 'United States of America'].includes(values.citizenship_country)) {
      if (!showAdditionalInputs) {
        setShowAdditionalInputs(true);
      }
    }

    if (['', 'United States of America'].includes(values.citizenship_country)) {
      if (showAdditionalInputs) {
        setShowAdditionalInputs(false);
      }
    }
  }, [values, showAdditionalInputs]);

  if (!isReady) return <LoadingPage />;

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.enterCitizenship}>
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>What is your citizenship status?</H5>
          </Heading>

          <FormWrapper>
            <CountryDropdown>
              <FloatingLabelDropdown
                name={'citizenship_country'}
                value={values.citizenship_country}
                label={''}
                items={citizenshipCountryItems}
                onChange={handleChange}
                error={errors.citizenship_country || null}
              />
            </CountryDropdown>

            {showAdditionalInputs && (
              <AdditionalInputs>
                <AdditionalHeader>
                  <Body5>Are you a permanent resident of the USA?</Body5>
                </AdditionalHeader>
                <InputWrapper>
                  <Toggle
                    name={'is_permanent_resident'}
                    value={values.is_permanent_resident}
                    label={''}
                    items={permanentResidentToggleItems}
                    onChange={handleChange}
                    error={errors.is_permanent_resident || null}
                  />
                </InputWrapper>

                {values.is_permanent_resident === null ||
                values.is_permanent_resident === '' ? null : values.is_permanent_resident === 'YES' ? (
                  <>
                    <InputWrapper>
                      <FloatingLabelTextInput
                        name={'green_card_number'}
                        value={values.green_card_number}
                        label={'USCIS#'}
                        onChange={handleChange}
                        error={errors.green_card_number || null}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <FloatingLabelExpiryDatePicker
                        name={'green_card_expiration_date'}
                        value={createTimeInstance(values.green_card_expiration_date).toDate()}
                        label={'Expiration'}
                        onChange={handleChange}
                        error={errors.green_card_expiration_date || null}
                      />
                    </InputWrapper>
                  </>
                ) : (
                  <>
                    <InputWrapper>
                      <CountryDropdown>
                        <FloatingLabelDropdown
                          name={'birth_country'}
                          value={values.birth_country}
                          label={''}
                          items={birthCountryItems}
                          onChange={handleChange}
                          error={errors.birth_country || null}
                        />
                      </CountryDropdown>
                    </InputWrapper>
                    <VisaWrapper>
                      <InputWrapper
                        style={{
                          marginRight: '8px',
                          width: '80px',
                        }}
                      >
                        <CountryDropdown>
                          <FloatingLabelDropdown
                            name={'visa_type'}
                            value={values.visa_type}
                            label={'Visa Type'}
                            items={visaTypes}
                            onChange={handleChange}
                            error={errors.visa_type || null}
                          />
                        </CountryDropdown>
                      </InputWrapper>
                      <InputWrapper
                        style={{
                          width: '100%',
                        }}
                      >
                        <FloatingLabelTextInput
                          name={'visa_number'}
                          value={values.visa_number}
                          label={'Visa Number'}
                          onChange={handleChange}
                          error={errors.visa_number || null}
                        />
                      </InputWrapper>
                    </VisaWrapper>
                    <InputWrapper>
                      <FloatingLabelExpiryDatePicker
                        name={'visa_expiration_date'}
                        value={createTimeInstance(values.visa_expiration_date).toDate()}
                        label={'Expiration'}
                        onChange={handleChange}
                        error={errors.visa_expiration_date || null}
                      />
                    </InputWrapper>
                  </>
                )}
              </AdditionalInputs>
            )}
          </FormWrapper>

          <Message>
            <IconMessage>
              <Lock />
              <Body5>This information will be kept private and it will never appear on your public profile.</Body5>
            </IconMessage>
          </Message>

          <Actions>
            <FormState error={formError} isSubmitting={isSubmitting} />
            <Container fullWidth centerAll>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleContinueClick}>
                Continue
              </FlatButton>
            </Container>
          </Actions>
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
