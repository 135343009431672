import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';
import { moment, formatLocalizedDateTime } from '../helpers/timeHelpers';
import { PlatformContextTypes } from '../constants';

const URL = `${BASEURL}/api`;

export const newsAdapter = {
  fetchNews: (params) => {
    // SYMBOL MAY RETURN DATA BUT REDUCER DOES NOT SUPPORT IT
    const options = { ignoreAuth: true };
    // can send csv s_ids or symbols
    let CSV = '';
    let type = '';
    if ('securityIds' in params) {
      const securityIds = params.securityIds;
      CSV = Array.isArray(securityIds) ? securityIds.join(',') : securityIds;
      type = 'security_id';
    } else {
      const symbols = params.symbols;
      CSV = Array.isArray(symbols) ? symbols.join(',') : symbols;
      type = 'symbol';
    }

    let dateRangeParam = '';
    if (params.dateRange) {
      const startDate = moment(params.dateRange[0]);
      const endDate = moment(params.dateRange[1]);
      const startDateFormatted = formatLocalizedDateTime('-l', startDate);
      const endDateFormatted = formatLocalizedDateTime('-l', endDate);
      dateRangeParam = `&start_date=${startDateFormatted}&end_date=${endDateFormatted}`;
    }

    return sendRequest('get', [`${URL}/securities/news?${type}=${CSV}${dateRangeParam}`], options).then(
      (response) => response
    );
  },

  sendNewsViewEvent: (trackingData) => {
    const { id, securityId, context, indexInFeed, metadata } = trackingData;
    const body = {
      context,
      platform: PlatformContextTypes.WEB,
      ...(indexInFeed >= 0 ? { index_in_feed: indexInFeed } : {}),
      ...(metadata ? { metadata } : {}),
      ...(securityId ? { context_security_id: securityId } : {}),
    };
    return sendRequest('post', [`${URL}/securities/news/${id}/view`, body]);
  },

  sendNewsClickEvent: (trackingData) => {
    const { id, clickType, context, metadata, indexInFeed, securityId } = trackingData;
    const body = {
      click_type: clickType,
      context,
      platform: PlatformContextTypes.WEB,
      ...(indexInFeed >= 0 ? { index_in_feed: indexInFeed } : {}),
      ...(metadata ? { metadata } : {}),
      ...(securityId ? { context_security_id: securityId } : {}),
    };
    return sendRequest('post', [`${URL}/securities/news/${id}/click`, body]);
  },
};
