import { LOCK_APP_SCROLL, UNLOCK_APP_SCROLL } from '../constants';

const defaultState = {
  isAppScrollLocked: false,
};

export default function scrollReducer(state = defaultState, action) {
  // definitions for reducer case statements
  let payload = action.payload;

  switch (action.type) {
    case LOCK_APP_SCROLL:
      return {
        ...state,
        isAppScrollLocked: true,
      };

    case UNLOCK_APP_SCROLL:
      return {
        ...state,
        isAppScrollLocked: false,
      };

    default:
      return state;
  }
}
