import React from 'react';
import { useSelector } from 'react-redux';
/*
    price
    priceAsOf

    delayedPrice,
    delayedPriceAsOf

    change,
    percentChange

    open,
    close,

    extendedPrice,
    extendedChange,
    extendedChangePercent,
    extendedAsOf

    previousClose
 */
export const usePriceLookup = () => {
  const d = useSelector((state) => state.livePrice.lookupById);
  return d;
};

export const usePriceData = (securityId) => {
  const [noData] = React.useState({});
  const d = useSelector((state) => state.livePrice.lookupById[securityId]);
  return d || noData;
};
