import React from 'react';

import ProfileAvatar from '../../../../components/user/ProfileAvatar';
import ConnectionRequest from '../../../buttons/ConnectionRequest';
import LinkTo from '../../../Links/LinkTo';

import {
  userFullName,
  userIsThoughtLeader,
  displayThoughtLeaderCompany,
  displayThoughtLeaderName,
} from '../../../../helpers/usersHelpers';

const UserListItem = (props) => {
  const { user, isCurrentUser } = props;
  const name = isCurrentUser ? 'Me' : userFullName(user);
  const { city, state } = user;
  const userLocation = city && state ? `${city}, ${state}` : city ? `${city}` : state ? `${state}` : null;

  const isUserThoughtLeader = userIsThoughtLeader(user);

  const renderThoughtLeader = () => {
    const company = displayThoughtLeaderCompany(user);
    const thoughtLeaderName = displayThoughtLeaderName(user);

    const linkChild = (
      <span className={'user-list-item-container component-bg-with-hover-feedback border-accent'}>
        <span className={'user-list-avatar-container'}>
          <ProfileAvatar size={'medium'} user={user} withLink={false} isThoughtLeader={true} />
        </span>

        <span className={'user-name-and-location-container'}>
          <span className={'user-full-name'}>{company}</span>
          <span className={'user-location secondary-text-color'}>{thoughtLeaderName}</span>
        </span>

        <span className={'user-list-item-action-container'}>
          <span className={'btn btn-primary-color btn-small-tall'}>View Ideas</span>
        </span>
      </span>
    );

    return (
      <LinkTo
        to={`/users/${user.user_id}/public_profile`}
        className={''}
        child={linkChild}
        scrollToTop
        noTextDecoration
      />
    );
  };

  const renderUser = () => {
    const linkChild = (
      <span className={'user-list-item-container component-bg-with-hover-feedback border-accent'}>
        <span className={'user-list-avatar-container'}>
          <ProfileAvatar
            size={'medium'}
            user={user}
            withLink={false}
            userIsCurrentUser={user.is_current_user}
            isThoughtLeader={false}
          />
        </span>

        <span className={'user-name-and-location-container'}>
          <span className={'user-full-name'}>{name}</span>
          {userLocation && <span className={'user-location'}>{userLocation}</span>}
        </span>

        <ConnectionRequest
          userId={user.user_id}
          viewingContext={
            props.viewingContext || console.error('UserListItem missing viewingContext prop for connect request event')
          }
        />
      </span>
    );

    return (
      <LinkTo
        to={`/users/${user.user_id}/public_profile`}
        className={''}
        child={linkChild}
        scrollToTop
        noTextDecoration
      />
    );
  };

  return isUserThoughtLeader ? renderThoughtLeader() : renderUser();
};

export default UserListItem;
