import React from 'react';

import LoadingIcon from '../misc/LoadingIcon';
import Icon from '../misc/Icon';
import { useButtonEvent } from '../../utils/itly/hooks/useButtonEvent';
import { enums } from '@src/main/lib/nvstr-utils.es';

const AddButton = (props) => {
  const onClick = useButtonEvent(props);
  const handleClick = (e) => {
    if ('handleClick' in props && props.handleClick) {
      if (props.stopPropagation) {
        e.stopPropagation();
      }
      if (props.preventDefault) {
        e.preventDefault();
      }
      // props.handleClick();
      onClick();
    }
  };

  const conditionalProps = {};
  if (props.id) {
    conditionalProps.id = props.id;
  }
  const btnClass = props.className || '';
  const text =
    props.text === 'Loading' ? (
      <LoadingIcon icon="fading-3balls" size="small" style={{ marginRight: '8px' }} />
    ) : (
      props.text
    );

  return (
    <div
      className={`add-button-container ${btnClass}`}
      onClick={handleClick}
      {...conditionalProps}
      style={props.style || {}}
    >
      <div className={`add-button-icon-container`}>
        <Icon icon={props.icon || 'fa-plus'} size={props.size || 'medium'} />
      </div>
      <div className={`add-button-text-container`}>{text}</div>
    </div>
  );
};

AddButton.defaultProps = {
  buttonType: enums.button_types.button,
};

export default AddButton;
