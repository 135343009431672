import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import { returnPathTo } from '../../constants/paths';
import * as Actions from '../../actions/index';

import { Messaging } from '../../constants';

import LinkTo from '../Links/LinkTo';

import { returnCurrentUserSubscriptionPlanName } from '../../helpers/currentUserHelpers';

import { consoleError } from '../../helpers/devToolHelpers';

class SubscriptionPlanMessaging extends React.Component {
  render() {
    return (
      <div
        className={`
          in-app-message-container
          ${this.props.additionalClassNames || ''}
          primary-cta-bg
        `}
      >
        <span className={``}>{this._returnMessageToDisplay()}</span>
        <LinkTo
          to={returnPathTo('subscriptions')}
          className="link-btn link-btn-in-primary-cta-bg-color"
          additionalClickAction={this._handleLinkClick}
          text={'Learn more'}
          styling={{ marginLeft: '7px' }}
          scrollToTop
        />
      </div>
    );
  }

  _handleLinkClick = () => {
    this._logClickDetailsInSubscriptionPlanMessaging();
  };

  _returnCurrentUser = () => this.props.currentUser;

  _logClickDetailsInSubscriptionPlanMessaging = () => {
    const event = 'Clicked Learn More Subscription Plan Messaging';
    const properties = {
      Context: this.props.viewingContext,
      //'AB Test Group': 0
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _returnMessageToDisplay = () =>
    `Join ${PRODUCT_DISPLAY_NAME} ${returnCurrentUserSubscriptionPlanName(
      this._returnCurrentUser()
    )} and trade with $0 commissions.`;
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionPlanMessaging);
