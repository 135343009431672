import { LOADING_USER_MUTUAL_CONNECTIONS, ADD_USER_MUTUAL_CONNECTIONS } from '../constants';

const defaultState = {
  userDict: {},
};

export default function userMutualConnections(state = defaultState, action) {
  let users;
  const userDictData = {};

  switch (action.type) {
    case LOADING_USER_MUTUAL_CONNECTIONS:
      const userIds = action.payload.userIds;
      userIds.forEach((id) => {
        userDictData[id] = {};
      });
      return {
        ...state,
        userDict: {
          ...state.userDict,
          ...userDictData,
        },
      };

    case ADD_USER_MUTUAL_CONNECTIONS:
      users = action.payload.users;
      users.forEach((user) => {
        userDictData[user.user_id] = {
          mutual_connections: user.mutual_connections,
        };
      });
      return {
        ...state,
        userDict: {
          ...state.userDict,
          ...userDictData,
        },
      };

    default:
      return state;
  }
}
