import React from 'react';
import { PRODUCT_NAME } from '@src/appConfig';

export const INFO_ICON_WORDS = {
  orderType: 'tooltip_order_type',
  bidAskOrder: 'tooltip_bid_ask_order',
  fractionalTradingDisabled: 'tooltip_fractional_trading_disabled',
  fractionalTradingDisabledStopOrders: 'tooltip_fractional_trading_disabled_stop_orders',
  orderTypePaperTrading: 'tooltip_order_type_paper_trading',
};

const definitions = {
  // Positions Page
  tooltip_price_target: 'Prediction for the future price of the stock or ETF at the time horizon.',

  [INFO_ICON_WORDS.orderTypePaperTrading]: `Only market orders are enabled for Simulated Trading Accounts.`,
  [INFO_ICON_WORDS.fractionalTradingDisabledStopOrders]: `Trading shares in fractions or trading by dollar amounts is not currently supported for stop and stop limit orders.`,
  [INFO_ICON_WORDS.fractionalTradingDisabled]: `Trading shares in fractions or trading by dollar amounts is not currently supported for this instrument.`,
  [INFO_ICON_WORDS.orderType]: `<p>Each order type is a tool you can use to accomplish your goal:</p><p>A "Market Order" allows you to buy or sell as soon as possible at the prevailing market price when the stock market is open.</p><p>A "Limit Order" allows you to specify a price (the Limit Price) where you want your trade to occur.  Your trade will be executed at the limit price or better, or it won't be executed at all.</p><p>A "Stop Order" allows you to specify a price (the Stop Price) where if the prevailing market price goes above, if buy order, or goes below, if sell order, the order will be executed as soon as possible at the prevailing market price when the stock market is open, or it won't be executed at all.</p><p></p>`,
  tooltip_expected_return: `<p>Your projection for how much the stock price will appreciate over the time horizon, plus dividends.</p><p>You can customize your projected price appreciation (or set a price target) by editing your idea.  The optimizer will take this into account when determining your ideal allocations.</p><p>When you create an idea, the projected price appreciation is initially set to your default expected return. You can update your default expected return anytime in the <a href=\'${
    window.location.pathname
  }?optimizer=1\'>optimizer settings</a>.</p>`,

  tooltip_expected_return_idea_panel:
    'Your projection for how much the stock price will appreciate from the current price over your selected time horizon, excluding dividends. This value remains fixed regardless of movements in the stock price.',

  tooltip_expected_return_minus_dividends:
    'Your projection for how much the stock price will appreciate over the time horizon, excluding dividends.',

  tooltip_allocation:
    'The number of shares of this stock or ETF in the portfolio. A negative value indicates a short position.',

  tooltip_optimized_allocation:
    'These position sizes would maximize your projected returns, subject to your risk constraints and investment objectives.',

  tooltip_analysis:
    'The Optimizer analyzes your investment ideas in the context of your entire portfolio. It gives you information about the return, volatility and how diversifying each position can be. Return tells you how much you can expect to gain from a position for that stock or ETF. Volatility reflects how much the price of the stock or ETF has fluctuated in the past. Diversifying indicates how complementary the stock or ETF is to your portfolio, based on whether it’s correlated with your other stocks or ETFs.',

  // Funding Page
  tooltip_nvstr_paper_trading: `This is a simulated bank account that you can use to move simulated funds in and out of your ${PRODUCT_NAME} account. You can deposit up to a total of $1,000,000 of simulated funds into your ${PRODUCT_NAME} account.`,

  tooltip_only_bank_account:
    'You must have at least one linked account. To remove this account, please add another external account first.',

  tooltip_funding_see_tos: "See <a href='/terms_of_service' target='_blank'>Terms of Service</a> for details.",

  //  Trade Page
  tooltip_buying_power:
    'The approximate value of additional securities you can buy or sell short.  If you exceed your available cash, trades will be funded via a margin loan.  The interest rate on margin loans is currently 6.5% per year.',

  tooltip_credit_earned_paper:
    "Credit will be applied to commissions you pay on live trades after you've funded your account.",
  tooltip_credit_earned: 'Credit will be applied to commissions you pay on trades.',
  tooltip_commission_credit: 'Credit will be applied to your account in 1-2 business days.',

  tooltip_manual_order_panel: 'You must create a new idea for #{symbol} when you take a position in it.',

  tooltip_bid_ask:
    'Bid is the approximate price you will get if you’re selling shares. Ask is the approximate price you will get if you’re buying shares.',

  [INFO_ICON_WORDS.bidAskOrder]: `<p><b>Bid</b>&nbsp;is the highest price at which the security can be sold in the market.</p><p><b>Ask</b>&nbsp;is the lowest price at which the security can be bought in the market.</p><p>These prices are determined by market participants.  Quantity is the number of shares available to buy or sell at the quoted price.</p>`,

  tooltip_sec_rule_for_short_sale:
    "The SEC <a href='https://www.sec.gov/rules/final/34-50103.htm' target='_blank'>requires</a> short and long sales to be marked separately, even if they are for the same stock.",

  tooltip_order_form_sec_rule_for_short_sale:
    "The SEC <a href='https://www.sec.gov/rules/final/34-50103.htm' target='_blank'>requires</a> short and long sales to be marked separately, so this order will be placed as two orders",

  tooltip_order_form_sec_rule_for_long_sale:
    'Buy to cover and buy orders must be placed separately, so this order will be placed as two orders.',

  tooltip_requires_trade_approval: `After you submit orders on ${PRODUCT_NAME}, you will be notified by email when your orders are approved or rejected. If they are approved, they will be placed automatically. Any warnings that occur during order placement, such as the market being closed or exceeding your maximum leverage setting, will be ignored and the orders will be placed anyway.`,

  //  Ideas
  tooltip_ask_why: 'Ask your connection to add additional thoughts on why they are interested in this stock.',

  tooltip_direction: 'Will the price go up (buy) or down (sell)?',

  tooltip_conviction:
    'Strength of belief in the idea. This will affect the maximum possible position size the optimizer can suggest for this stock.',

  tooltip_expected_dividend:
    'The annualized dividend yield expected from this stock (defaults to historical or analyst projection if available).',

  tooltip_horizon:
    'The time it will take to reach the expected return or price target. Long Term is 12 months, Short Term is 6 months.',

  tooltip_thesis: 'General category of reasoning behind this idea.',

  tooltip_thesis_value: 'Valuation metrics suggest stock is mispriced',
  tooltip_thesis_event: 'Specific catalyst will reprice the stock',
  tooltip_thesis_growth: 'End markets are growing fast',
  tooltip_thesis_dividend: 'Invest for income',
  tooltip_thesis_momentum: 'Supply/demand causing recent movement to persist',
  tooltip_thesis_safety: 'High quality, stable stock',
  tooltip_thesis_management: "Invest based on quality of the company's leadership team",
  tooltip_thesis_macro: 'Economic trends will affect the value of this company',
  tooltip_thesis_turnaround: 'Rehabilitation of the company will unlock value',

  //  Financial Summary
  tooltip_price_earnings_ratio:
    'The ratio of the share price to earnings per share for the past year. A low positive value indicates the stock or ETF is priced inexpensively relative to its earnings.',

  tooltip_backward_pe_ratio:
    'The ratio of the share price to earnings per share for the past year. A low positive value indicates the stock or ETF is priced inexpensively relative to its earnings.',

  tooltip_forward_pe_ratio_this_year:
    'Price / Earnings using analyst consensus earnings estimates for the current fiscal year. Indicates how the stock is priced relative to future earnings expectations.',

  tooltip_forward_pe_ratio_next_year:
    'Price / Earnings using analyst consensus earnings estimates for the next fiscal year. Indicates how the stock is priced relative to future earnings expectations.',

  tooltip_forward_pe_ratio_next_next_year:
    'Price / Earnings using analyst consensus earnings estimates for the fiscal year after next. Indicates how the stock is priced relative to future earnings expectations.',

  tooltip_forward_eps_this_quarter:
    'Analyst consensus earnings per share for the current fiscal quarter. Indicates future earnings expectations per share.',

  tooltip_forward_eps_this_year:
    'Analyst consensus earnings per share for the current fiscal year. Indicates future earnings expectations per share.',

  tooltip_price_to_tangible_book_value:
    'The ratio of the share price to the company’s tangible book value per share. Tangible book value is a way to measure the value of the assets owned by the company.',

  tooltip_dividend_yield: 'The annualized cash dividend declared as a percentage per share.',

  tooltip_market_cap: 'The total value of all shares of the company.',

  tooltip_trusted_contact:
    'If we need to reach out to you and are unable to get in touch, the trusted contact is another person we can contact. They do not have financial authority over the account, but would be able to communicate about limited aspects of your account if we were unable to reach you and it was urgent. More information available in our FAQs <a href="/faq?section=trusted-contact">here</a>',

  tooltip_net_debt:
    'Total debt minus cash and short term investments. Parenthesis "()" indicate a negative value, which means the company has net cash.',

  tooltip_avg_analyst_two_yr_rev_growth:
    'Analyst 2-Yr Projected Revenue Growth is based on consensus revenue estimates for the current fiscal year and two fiscal years forward, sourced from S&P Global Market Intelligence.  Certain contributor estimates may be excluded by S&P Global Market Intelligence, for example, if an estimate is stale, or a major event such as M&A occurs and an estimate has not been revised or affirmed.',

  tooltip_analyst_2yr_growth_projection:
    'Analyst 2-Yr Projected Revenue Growth is based on consensus revenue estimates for the current fiscal year and two fiscal years forward, sourced from S&P Global Market Intelligence.  Certain contributor estimates may be excluded by S&P Global Market Intelligence, for example, if an estimate is stale, or a major event such as M&A occurs and an estimate has not been revised or affirmed.',

  tooltip_projected_revenue_growth:
    'Analyst 2-Yr Projected Revenue Growth is based on consensus revenue estimates for the current fiscal year and two fiscal years forward, sourced from S&P Global Market Intelligence.  Certain contributor estimates may be excluded by S&P Global Market Intelligence, for example, if an estimate is stale, or a major event such as M&A occurs and an estimate has not been revised or affirmed.',

  //  Investment Objectives
  tooltip_short_sales_allowed:
    'A short sale is when you sell borrowed shares of a stock or ETF, with the expectation it will go down. If the price of the shares goes down, the borrowed shares can be bought back, which generates a net profit for the short seller.  If the share price goes up, losses are generated.  Since a stock can go up indefinitely, in theory, these losses are unbounded.',

  tooltip_max_leverage:
    'Leverage is the ratio of the total value of the non-cash assets in the portfolio to the net value of the account. A Max Leverage value less than one indicates you want to keep a portion of the account allocated to cash. A Max Leverage value greater than one indicates that you are willing to take on a margin loan.',

  tooltip_cash_reserve_minimum: 'The minimum amount of cash you want to maintain in the account.',

  tooltip_portfolio_beta_constraint:
    'The portfolio beta is a measure of how closely it moves with the broader market.  For example, a beta of 1.5 indicates if the broad market moves up or down, your portfolio may return roughly 1.5x that amount.',

  tooltip_portfolio_volatility_invest_obj:
    'The maximum volatility you want to allow when determining the optimal position sizes.  Volatility is a measure of risk which indicates how much your portfolio is likely to move up or down.  It is based on the standard deviation of historical price movements.',

  tooltip_minimum_dividend_income: 'The total value of expected dividends you want the portfolio to earn in a year.',

  tooltip_max_position_allocation:
    'The maximum fraction of your total portfolio that you want any single position in a stock or concentrated ETF to take.',

  tooltip_max_diversified_etf_allocation:
    'The maximum fraction of your total portfolio that you want any single position in a diversified ETF to take.',

  tooltip_allocation_constraint:
    'Minimum and maximum position sizes (in shares) that you want to maintain in a specific stock or ETF. The optimizer will take these ranges into account and attempt to keep that position size within them, regardless of whether that’s otherwise optimal.',

  // Optimizer Panel
  tooltip_projected_return:
    'The projected 1-year gain on your portfolio given your current price targets, expected dividends, and your current and optimized allocations; also the difference between your current and optimized projected gains, shown in $ and %.',

  tooltip_portfolio_volatility:
    'The maximum volatility you want to allow when determining the optimal position sizes.  Volatility is a measure of risk which indicates how much your portfolio is likely to move up or down.  It is based on the standard deviation of historical price movements.',

  tooltip_estimated_dividends:
    'The difference between projected dividends you may receive over the next year with your current and optimized portfolios.  For example, if the current portfolio is projected to pay $100 in dividends and the new portfolio is projected to pay $110, this number would be +$10.',

  tooltip_market_beta:
    'The difference in market exposure between your current and optimized portfolios.  For example, if your current portfolio has a beta of 1.0 (same exposure as the broad market) and your optimized portfolio has a beta of 0.9 (lower exposure than the market), this value would be 0.9 - 1.0 = -0.1.',

  // Thoughts
  //typo explanation needs fixing
  thought_explanation:
    'Each Pro or Con is a short comment on why this stock or ETF might go up or down. You can also include a link to an article.',

  //  Misc
  tooltip_thought_leader:
    "Famous Investor positions are sourced from publicly available information about the recent positions of institutional investors. Famous Investor positions are provided for informational purposes only and are not recommendations. More details can be found <a href='/terms_of_service#section9' target='_blank'>here</a>.",

  tooltip_default_expected_return_percentage:
    "Historically, stock prices have gone up by around 7% each year (excluding dividends). We'll use this value to default the expected returns on your ideas. You can always change them later.",

  top_connections_ideas_today:
    "Performance numbers reflect the average percent change in each of your connections' stocks.",

  performance_numbers: "Performance numbers reflect the average percent daily change in a user's ideas.",

  portfolio_rank: `<p>Rank refers to percentile rank of user's portfolio profit/loss for the previous calendar week relative to all other ${PRODUCT_NAME} users.</p><p>Unranked refers to users in the bottom 50%.</p><p>Incomplete refers to users that have not yet placed a trade.</p>`,

  tooltip_collective_user: `Ideas from top performing ${PRODUCT_NAME} users (annualized returns in the top 10th percentile)`,

  tooltip_nvstr_community_chart: `Long and short % values refer to the percentage of ${PRODUCT_NAME} community members who have long and short ideas respectively.`,

  tooltip_community_thought_leader: `<p>Community Thought Leaders are select members of the ${PRODUCT_NAME} community who are compensated for their contributions. They include former Wall Street pros - for example, one spent 10+ years at a $2 billion fund. Another was formerly an Institutional Investor-ranked analyst at Citigroup and Barclays.</p><p><a href='/faq?section=community-thought-leaders' target='_blank'>See here</a> for details, including how you can become one.</p><p style="margin-top: 3px">Community Thought Leader content is not a recommendation or investment advice.</p>`,

  tooltip_viewpoint: `<p>Viewpoint Contributors are financial content creators who have chosen to share their insights with the ${PRODUCT_NAME} community and may be compensated by ${PRODUCT_NAME}.</p><p style="margin-top: 5px"><a href='/faq?section=viewpoint-contributors' target='_blank'>See here</a> for details, including how you can become one.</p><p style="margin-top: 9px">Viewpoint Contributor content is not a recommendation or investment advice.</p>`,

  tooltip_external_positions_unmapped_security: `This instrument is not currently available on ${PRODUCT_NAME}.`,

  tooltip_recurring_deposit_day_selection: `If you select the 31st and there are only 30 days in the month, the deposit will be made on the 30th.`,
};

export const getDefinition = function(word, symbol = 'this security') {
  return definitions[word] ? definitions[word] : '';
};

export const convertInfoIconWordKeyToEventName = (word) =>
  ({
    tooltip_price_target: 'Idea Price Target',
    tooltip_expected_return: 'Idea Expected Return',
    tooltip_expected_return_minus_dividends: 'Expected Return Minus Dividends',
    tooltip_allocation: 'Allocation',
    tooltip_optimized_allocation: 'Optimized Allocation',
    tooltip_analysis: 'Analysis',
    tooltip_nvstr_paper_trading: 'Paper Trading',
    tooltip_only_bank_account: 'Only Bank Account',
    tooltip_funding_see_tos: 'See ToS',
    tooltip_buying_power: 'Buying Power',
    tooltip_credit_earned_paper: 'Credit Earned With Paper Account',
    tooltip_credit_earned: 'Credit Earned',
    tooltip_commission_credit: 'Commission Credit',
    tooltip_manual_order_panel: 'Manual Order Panel',
    tooltip_bid_ask: 'Bid Ask Pricing',
    tooltip_sec_rule_for_short_sale: 'SEC Rule For Short Sale',
    tooltip_requires_trade_approval: 'Trade Approval Required',
    tooltip_ask_why: 'Ask Why',
    tooltip_direction: 'Idea Direction',
    tooltip_conviction: 'Idea Conviction',
    tooltip_expected_dividend: 'Idea Expected Dividend',
    tooltip_horizon: 'Idea Horizon',
    tooltip_thesis: 'Idea Thesis',
    tooltip_thesis_value: 'Idea Thesis Value',
    tooltip_thesis_event: 'Idea Thesis Event',
    tooltip_thesis_growth: 'Idea Thesis Growth',
    tooltip_thesis_dividend: 'Idea Thesis Dividend',
    tooltip_thesis_momentum: 'Idea Thesis Momentum',
    tooltip_thesis_safety: 'Idea Thesis Safety',
    tooltip_thesis_management: 'Idea Thesis Management',
    tooltip_thesis_macro: 'Idea Thesis Macro',
    tooltip_thesis_turnaround: 'Idea Thesis Turnaround',
    tooltip_price_earnings_ratio: 'P/E Ratio',
    tooltip_backward_pe_ratio: 'Backward P/E Ratio',
    tooltip_forward_pe_ratio_this_year: 'Forward P/E Ratio (This Year)',
    tooltip_forward_pe_ratio_next_year: 'Forward P/E Ratio (Next Year)',
    tooltip_forward_pe_ratio_next_next_year: 'Forward P/E Ratio (Year After Next)',
    tooltip_price_to_tangible_book_value: 'Price to Tangible Book Value',
    tooltip_dividend_yield: 'Dividend Yield',
    tooltip_market_cap: 'Market Cap',
    tooltip_net_debt: 'Net Debt',
    tooltip_avg_analyst_two_yr_rev_growth: 'Avg. Analyst 2 Year Revenue Growth',
    tooltip_analyst_2yr_growth_projection: 'Analyst 2 Year Revenue Growth',
    tooltip_projected_revenue_growth: 'Avg. Analyst 2 Year Revenue Growth',
    tooltip_short_sales_allowed: 'Short Sales Allowed',
    tooltip_max_leverage: 'Max Leverage',
    tooltip_cash_reserve_minimum: 'Cash Reserve Minimum',
    tooltip_portfolio_beta_constraint: 'Portfolio Beta Constraint',
    tooltip_portfolio_volatility_invest_obj: 'Portfolio Volatility Investment Objective',
    tooltip_minimum_dividend_income: 'Minimum Dividend Income',
    tooltip_max_position_allocation: 'Max Position Allocation',
    tooltip_max_diversified_etf_allocation: 'Max Diversified ETF Allocation',
    tooltip_allocation_constraint: 'Allocation Constraint',
    tooltip_projected_return: 'Projected Return',
    tooltip_portfolio_volatility: 'Portfolio Volatility',
    tooltip_estimated_dividends: 'Estimated Dividends',
    tooltip_market_beta: 'Market Beta',
    thought_explanation: 'Thought Explanation',
    tooltip_thought_leader: 'Thought Leader',
    tooltip_default_expected_return_percentage: 'Default Expected Return Percentage',
    top_connections_ideas_today: 'Top Connections Ideas (Today)',
    performance_numbers: 'Performance Numbers',
    tooltip_collective_user: 'Collective User',
    tooltip_nvstr_community_chart: 'Tornado Community Chart',
    tooltip_community_thought_leader: 'Community Thought Leader',
    tooltip_viewpoint: 'Viewpoint',
    tooltip_karma: 'Karma',
  }[word]);

export const returnCurrentUserPendingEquityReasonsJSX = (currentUser) => {
  const reasons = currentUser.pending_equity_reasons;
  if (reasons) {
    return (
      <>
        {reasons.map((msg, i) => (
          <div key={`pending-equity-reason-${i}`} style={{ padding: 3 }}>
            {' '}
            {msg}{' '}
          </div>
        ))}
      </>
    );
  } else {
    return 'Loading';
  }
};
