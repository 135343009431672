import React, { Component } from 'react';
import { snakeToHyphens } from '../../../../helpers/generalHelpers';
import { formatDataValue } from '../../../../helpers/securitiesHelpers';
import { usePortfolioCashData } from '../../../../main/hooks/portfolio/useEquityData';

const Cell = ({ loadingCellComponent }) => {
  const { value, percentageOfEquity, isLoading } = usePortfolioCashData();

  if (isLoading) {
    return loadingCellComponent;
  }
  return (
    <div className="security-table-portfolio-cash-cell">
      <div className={`portfolio-cash`}>{formatDataValue(value, 'price')}</div>
      <div className={`portfolio-cash-percentage-of-equity`}>{formatDataValue(percentageOfEquity, 'percentage')}</div>
    </div>
  );
};

export class PortfolioCashCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;
    return <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>{this._renderCellContent()}</td>;
  }

  _renderCellContent = () => {
    return <Cell loadingCellComponent={this.props.loadingCellComponent} />;
  };
}

export default PortfolioCashCell;
