import React from 'react';
import { useDispatch } from 'react-redux';

import { fetchFurtherDocumentationStatus, getBankAccounts, getLiveTradingAccount, showModal } from '../../../actions';
import { isCurrentUserOnboarding, returnCurrentUserThemeTestGroups } from '../../../helpers/currentUserHelpers';
import { useCurrentUser, useLiveAccount } from '../../hooks/user';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../constants/paths';
import { ExpandedPageBackground, Page } from '../../components/layout';
import { parseQueryString } from '../../../helpers/routerHelpers';
import { ACCOUNT_TYPES } from '../../constants';
import { LoadingPage } from '../../components/page';
import { isUndefined, isUndefinedOrNull } from '../../../helpers/usefulFuncs';
import { sendFacebookTrackingEvent } from '../../../constants/facebookTracking';
import { ONBOARDING_PATHS, ONBOARDING_STATES, onboardingStateToComponentLookup } from '../../constants/onboarding';
import { ReloadButton } from '../../../containers/buttons/Reload';
import { Body5 } from '../../lib/nvstr-common-ui.es';
import { signOut } from '../../containers/SignIn/utils/services';

const getPrevState = (currentState, path) => {
  const index = path.indexOf(currentState);
  if (index < 1) {
    return path[0];
  }

  return path[index - 1];
};

const getNextState = (currentState, path) => {
  const index = path.indexOf(currentState);
  if (index < 0) {
    return path[0];
  }

  return path[index + 1];
};

export const OnboardingHome = () => {
  const [initialLoadPath, setInitialLoadPath] = React.useState(null);
  const [isReady, setIsReady] = React.useState(false);

  const [onboardingState, setOnboardingState] = React.useState(ONBOARDING_STATES.initialization);
  const [accountTypePath, setAccountTypePath] = React.useState(null);
  const [onboardingPath, setOnboardingPath] = React.useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const currentUser = useCurrentUser();
  const liveAccount = useLiveAccount();

  React.useEffect(() => {
    const setup = async (currentUser) => {
      if (currentUser.allow_live_trading && !currentUser.is_token_authed) {
        const requiredDataRequests = [
          getLiveTradingAccount(),
          getBankAccounts(),
          () => fetchFurtherDocumentationStatus(),
        ];

        const responses = await Promise.all(requiredDataRequests.map((r) => r(dispatch)));
        const failedRequests = responses.filter((r) => !r.ok);

        const { liveTradingAccount: liveAccount } = responses[0];
        const { isRejected: isSocureRejected, isUserActionRequired } = responses[2];

        if (failedRequests.length === 0) {
          const applicationStatus = liveAccount?.status;
          const accountApplicationSubmitted =
            !isUndefined(applicationStatus) &&
            applicationStatus?.toLowerCase() !== 'incomplete' &&
            applicationStatus?.toLowerCase() !== 'rejected';

          if (!isUndefinedOrNull(currentUser.default_expected_return)) {
            setAccountTypePath(accountApplicationSubmitted ? ACCOUNT_TYPES.live : ACCOUNT_TYPES.paper);
            setOnboardingPath(ONBOARDING_PATHS.end);
            setOnboardingState(ONBOARDING_STATES.end);
            setIsReady(true);
            return;
          }

          if (isSocureRejected || applicationStatus?.toLowerCase() === 'rejected') {
            const path = ONBOARDING_PATHS.paper;
            setOnboardingPath(path);
            setOnboardingState(ONBOARDING_STATES.selectAccountPrivacy);
            setIsReady(true);
            return;
          }

          if (isUserActionRequired) {
            const path = ONBOARDING_PATHS.socure;
            setOnboardingPath(path);
            setOnboardingState(ONBOARDING_STATES.socureApplication);
            setIsReady(true);
            return;
          }

          if (accountApplicationSubmitted) {
            const path = ONBOARDING_PATHS.postLiveAccountApplication;
            setOnboardingPath(path);
            setOnboardingState(ONBOARDING_STATES.redeemRewards);
            setIsReady(true);
            return;
          }

          const path = ONBOARDING_PATHS.accountSelection;
          setOnboardingPath(path);
          const state = getNextState(onboardingState, path);
          setOnboardingState(state);
          setIsReady(true);
        } else {
          const modal = {
            contentComponent: (
              <div className={`reload-message-container`}>
                <p>Something went wrong. Please check your internet connection and reload the page.</p>
                <ReloadButton />
              </div>
            ),
            size: 'wide',
          };
          showModal(modal)(dispatch);
        }
      } else {
        if (currentUser.is_token_authed) {
          signOut();
        } else {
          const path = ONBOARDING_PATHS.paper;
          setOnboardingPath(path);
          setOnboardingState(ONBOARDING_STATES.selectInvestingExperience);
          setIsReady(true);
        }
      }
    };

    const parseQuery = (currentUser) => {
      const query = parseQueryString(location.search);
      if (query.email_confirmed === 'true') {
        sendFacebookTrackingEvent('Email Confirmed', {
          themeTestGroups: returnCurrentUserThemeTestGroups(currentUser),
        });
      }

      if (query.path) {
        setInitialLoadPath(query.path);
      }
    };

    if (isCurrentUserOnboarding(currentUser)) {
      parseQuery(currentUser);
      setup(currentUser);
    } else {
      navigate(ROUTES.DASHBOARD.build());
    }
  }, []);

  const onGoBack = React.useCallback(
    () => {
      const prevOnboardingState = getPrevState(onboardingState, onboardingPath);
      setOnboardingState(prevOnboardingState);
    },
    [onboardingState, onboardingPath]
  );

  const onPathChange = React.useCallback(
    (path, onboardingState, data = {}) => {
      const { accountType } = data;

      if (path) {
        setOnboardingPath(path);
        const nextState = data.nextState || getNextState(onboardingState, path);
        setOnboardingState(nextState);
        return;
      }

      // CAN ADD SCREEN TRANSITION SPECIFIC LOGIC HERE
      switch (onboardingState) {
        default: {
          if (accountType === ACCOUNT_TYPES.live) {
            setAccountTypePath(ACCOUNT_TYPES.live);
            setOnboardingPath(ONBOARDING_PATHS.liveAccountApplication);
            const nextState = ONBOARDING_STATES.selectInvestingExperience;
            setOnboardingState(nextState);
          }
          if (accountType === ACCOUNT_TYPES.paper) {
            setAccountTypePath(ACCOUNT_TYPES.paper);
            setOnboardingPath(ONBOARDING_PATHS.paper);
            const nextState = ONBOARDING_STATES.selectInvestingExperience;
            setOnboardingState(nextState);
          }
        }
      }
    },
    [accountTypePath, onboardingPath]
  );

  const onContinue = React.useCallback(
    (data, onboardingState) => {
      // CAN ADD SCREEN TRANSITION SPECIFIC LOGIC HERE
      switch (onboardingState) {
        default: {
          const nextState = getNextState(onboardingState, onboardingPath);
          setOnboardingState(nextState);
        }
      }
    },
    [accountTypePath, onboardingPath]
  );

  const onSkip = React.useCallback(
    (data, onboardingState) => {
      // CAN ADD SCREEN TRANSITION SPECIFIC LOGIC HERE
      switch (onboardingState) {
        case ONBOARDING_STATES.linkBankAccount: {
          setOnboardingState(ONBOARDING_STATES.selectAccountPrivacy);
          break;
        }

        default: {
          const nextState = getNextState(onboardingState, onboardingPath);
          setOnboardingState(nextState);
        }
      }
    },
    [accountTypePath, onboardingPath]
  );

  const renderOnboardingState = React.useCallback(
    () => {
      const Component = onboardingStateToComponentLookup[onboardingState];

      if (Component) {
        const props = {};
        return (
          <Component
            {...props}
            accountTypePath={accountTypePath}
            onboardingState={onboardingState}
            onPathChange={onPathChange}
            onSkip={onSkip}
            onContinue={onContinue}
          />
        );
      }

      if (!initialLoadPath || !onboardingState) {
        return (
          <ExpandedPageBackground>
            <Page>
              <Body5>Something went wrong, refresh the page.</Body5>
            </Page>
          </ExpandedPageBackground>
        );
      }

      return (
        <ExpandedPageBackground>
          <Page>
            <div>{onboardingState}</div>
          </Page>
        </ExpandedPageBackground>
      );
    },
    [onboardingState, initialLoadPath]
  );

  if (!isReady) return <LoadingPage />;

  return renderOnboardingState();
};
