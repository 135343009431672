import React from 'react';

import { logMetricsTrackingEvent } from '../../actions';
import { convertPresetFilterCardToMetricsTrackingEventProperty } from '../../helpers/trackingHelpers';

import Icon from '../misc/Icon';
import styled from 'styled-components';

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.themeColors.text};
  background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};

  .securities-filter-card-description {
    border-top: 1px solid ${({ theme }) => theme.themeColors.text};

    * {
      color: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

const SecuritiesFilterCard = (props) => {
  const { icon, title, description, colorScheme, name } = props.presetFilter;
  const { position } = props;

  function handleClick() {
    const event = 'Clicked Preset Filter Card';
    const filterName = convertPresetFilterCardToMetricsTrackingEventProperty(name);
    const properties = { Filter: filterName };
    logMetricsTrackingEvent(event, properties)();

    props.handleClick(name);
  }

  function handleMouseOver() {
    props.handleMouseOver(name);
  }

  function handleMouseOut() {
    props.handleMouseOut(name);
  }

  const renderSmallCard = () => {
    return (
      <div
        className={'small-securities-filter-card-container'}
        style={{ ...position, background: colorScheme }}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
      >
        <div className="securities-filter-card-icon">
          <Icon icon={icon} size="medium" />
        </div>
        <div className="small-securities-filter-card-title">{title}</div>
      </div>
    );
  };

  const renderHoverCard = () => {
    return (
      <div
        className={'small-securities-filter-card-container small-securities-filter-card-description-container'}
        style={{ ...position, background: colorScheme }}
        onClick={handleClick}
        onMouseOut={handleMouseOut}
      >
        <div className="small-securities-filter-card-description" style={{ background: colorScheme }}>
          <div className="securities-filter-card-description-text">{description}</div>
        </div>
      </div>
    );
  };

  if (props.size === 'small' && !props.isHovered) {
    return renderSmallCard();
  }

  if (props.size === 'small' && props.isHovered) {
    return renderHoverCard();
  }

  return (
    <Wrapper
      className={'securities-filter-card-container'}
      style={position}
      onClick={handleClick}
      onMouseOut={handleMouseOut}
    >
      <div className="securities-filter-card-icon">
        <Icon icon={icon} size="large" />
      </div>
      <div className="securities-filter-card-title-container">
        <div className="securities-filter-card-title">{title}</div>
      </div>
      <div className="securities-filter-card-description">
        <div className="securities-filter-card-description-text">{description}</div>
      </div>
    </Wrapper>
  );
};

export default SecuritiesFilterCard;
