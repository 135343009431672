// PUBLIC

export const getPortfolioExplorerDefaultFilterCards = (
  data,
  showConnectionsCard,
  showDiversifyingCard,
  cardsPerRow,
  currentUserId
) => {
  const presetFiltersLookup = {
    'Create Custom Screen': {
      presetFilter: {
        title: 'Create Custom Screen',
        description: "Search for stocks based on what's important to you.",
        name: 'defineMyOwn',
        icon: 'fa-user',
        colorScheme: '#AFAFAF',
      },
      filters: [],
    },
    'Growth Stocks': {
      presetFilter: {
        title: 'Growth Stocks',
        description: 'Rapidly growing revenue in recent years',
        name: 'growthStocks',
        icon: 'fa-tree',
        colorScheme: '#3db735',
      },
      filters: [
        {
          name: 'Projected Revenue Growth',
          operator: '>',
          value: 20,
        },
        {
          name: 'Projected Revenue Growth',
          operator: '<',
          value: 200,
        },
        {
          name: 'Market Cap',
          operator: '>',
          value: 1000,
        },
        {
          name: 'Revenue LTM',
          operator: '>',
          value: 1000,
        },
      ],
    },
    "Friends' Ideas": {
      presetFilter: {
        title: "Friends' Ideas",
        description: "Your trusted connections' ideas",
        name: 'friendsIdeasStocks',
        icon: 'fa-lightbulb-o',
        colorScheme: '#2d3e55',
      },
      filters: [
        {
          name: "On Connections' Watchlists",
          operator: '||',
          ids: data.connections,
        },
      ],
    },
    'Famous Investors': {
      presetFilter: {
        title: 'Famous Investors',
        description: "Well-known Thought Leaders' ideas",
        name: 'famousInvestorsStocks',
        icon: 'fa-compass',
        colorScheme: '#eecc06',
      },
      filters: [
        {
          name: 'Owned by Thought Leaders',
          operator: '||',
          ids: data.thought_leaders,
        },
      ],
    },
    'Value Stocks': {
      presetFilter: {
        title: 'Value Stocks',
        description: 'Priced inexpensively given earnings power',
        name: 'valueStocks',
        icon: 'fa-money',
        colorScheme: '#6f6f86',
      },
      filters: [
        {
          name: 'Forward P/E',
          operator: '<',
          value: 12,
        },
        {
          name: 'Forward P/E',
          operator: '>',
          value: 0,
        },
        {
          name: 'Market Cap',
          operator: '>',
          value: 1000,
        },
        {
          name: 'Revenue LTM',
          operator: '>',
          value: 1000,
        },
      ],
    },
    'Juicy Dividends': {
      presetFilter: {
        title: 'Juicy Dividends',
        description: 'High dividend yields',
        name: 'dividends',
        icon: 'fa-percent',
        colorScheme: '#ff9c22',
      },
      filters: [
        {
          name: 'Dividend Yield',
          operator: '>',
          value: 3,
        },
        {
          name: 'Market Cap',
          operator: '>',
          value: 1000,
        },
        {
          name: 'Revenue LTM',
          operator: '>',
          value: 1000,
        },
      ],
    },
    'Price / Tangible Book': {
      presetFilter: {
        title: 'Price / Tangible Book',
        description: 'Trading at less than tangible book value',
        name: 'discountToBookValue',
        icon: 'fa-book',
        colorScheme: '#7a4e19',
      },
      filters: [
        {
          name: 'Price / Tangible Book',
          operator: '<',
          value: 1,
        },
        {
          name: 'Price / Tangible Book',
          operator: '>',
          value: 0,
        },
        {
          name: 'Market Cap',
          operator: '>',
          value: 1000,
        },
        {
          name: 'Revenue LTM',
          operator: '>',
          value: 1000,
        },
      ],
    },
    'Collective Intelligence': {
      presetFilter: {
        title: 'Collective Intelligence',
        description: 'Stocks that people similar to you are investing in',
        name: 'likeMeStocks',
        icon: 'fa-users',
        colorScheme: '#028fea',
      },
      filters: [
        //TODO: implement this as a sort instead of a filter
        {
          name: 'Collective Intelligence',
          operator: '||',
          ids: [''],
        },
      ],
    },
    'Technology Sector': {
      presetFilter: {
        title: 'Technology Sector',
        description: 'See stocks or ETFs in the technology sector',
        name: 'techSector',
        icon: 'fa-hdd-o',
        colorScheme: '#6f6f86',
      },
      filters: [
        {
          name: 'Sectors',
          operator: '||',
          // OLD (Xpressfeed)
          // {sector_id: 54, description: "Internet Software and Services"}
          // {sector_id: 55, description: "IT Services"}
          // {sector_id: 56, description: "Software"}
          // {sector_id: 57, description: "Communications Equipment"}
          // {sector_id: 58, description: "Technology Hardware, Storage and Peripherals"}
          // {sector_id: 59, description: "Electronic Equipment, Instruments and Components"}
          // {sector_id: 60, description: "Semiconductors and Semiconductor Equipment"}
          // NEW (Xignite)
          // {"sector_id": 25,"description": "Computer Communications"},
          // {"sector_id": 26,"description": "Computer Peripherals"},
          // {"sector_id": 27,"description": "Computer Processing Hardware"},
          // {"sector_id": 66,"description": "Information Technology Services"},
          // {"sector_id": 70,"description": "Internet Software/Services"},
          // {"sector_id": 103,"description": "Packaged Software"},
          // {"sector_id": 121,"description": "Semiconductors"},
          // {"sector_id": 130,"description": "Telecommunications Equipment"},
          ids: getSectorsDataSubsetByIds(data.sectors, [25, 26, 27, 66, 70, 103, 121, 130]),
        },
      ],
    },
    'Financial Sector': {
      presetFilter: {
        title: 'Financial Sector',
        description: 'See stocks or ETFs in the financial sector',
        name: 'financeSector',
        icon: 'fa-university',
        colorScheme: '#6BB166',
      },
      filters: [
        {
          name: 'Sectors',
          operator: '||',
          // OLD (Xpressfeed)
          // {sector_id: 46, description: "Banks"}
          // {sector_id: 47, description: "Thrifts and Mortgage Finance"}
          // {sector_id: 48, description: "Diversified Financial Services"}
          // {sector_id: 49, description: "Consumer Finance"}
          // {sector_id: 50, description: "Capital Markets"} - removed
          // NEW (Xignite)
          // {"sector_id": 46,"description": "Finance/Rental/Leasing"},
          // {"sector_id": 47,"description": "Financial Conglomerates"},
          // {"sector_id": 67,"description": "Insurance Brokers/Services"},
          // {"sector_id": 71,"description": "Investment Banks/Brokers"},
          // {"sector_id": 72,"description": "Investment Managers"},
          // {"sector_id": 74,"description": "Life/Health Insurance"},
          // {"sector_id": 76, "description": "Major Banks"},
          // {"sector_id": 90,"description": "Multi-Line Insurance"},
          // {"sector_id": 109,"description": "Property/Casualty Insurance"},
          // {"sector_id": 118,"description": "Regional Banks"},
          // {"sector_id": 120,"description": "Savings Banks"},
          // {"sector_id": 124,"description": "Specialty Insurance"},
          ids: getSectorsDataSubsetByIds(data.sectors, [46, 47, 67, 71, 72, 74, 76, 90, 109, 118, 120, 124]),
        },
      ],
    },
    'Healthcare Sector': {
      presetFilter: {
        title: 'Healthcare Sector',
        description: 'See stocks or ETFs in the healthcare sector',
        name: 'healthcareSector',
        icon: 'fa-heartbeat',
        colorScheme: '#ff412c',
      },
      filters: [
        {
          name: 'Sectors',
          operator: '||',
          // OLD (Xpressfeed)
          // {sector_id: 40, description: "Healthcare Equipment and Supplies"}
          // {sector_id: 41, description: "Healthcare Providers and Services"}
          // {sector_id: 42, description: "Health Care Technology"}
          // {sector_id: 44, description: "Pharmaceuticals"}
          // {sector_id: 45, description: "Life Sciences Tools and Services"}
          // NEW (Xignite)
          // {"sector_id": 14,"description": "Biotechnology"},
          // {"sector_id": 60,"description": "Hospital/Nursing Management"},
          // {"sector_id": 78,"description": "Managed Health Care"},
          // {"sector_id": 81,"description": "Medical Distributors"},
          // {"sector_id": 82,"description": "Medical Specialties"},
          // {"sector_id": 83,"description": "Medical/Nursing Services"},
          // {"sector_id": 105,"description": "Pharmaceuticals: Generic"},
          // {"sector_id": 106,"description": "Pharmaceuticals: Major"},
          // {"sector_id": 107,"description": "Pharmaceuticals: Other"},
          // {"sector_id": 122,"description": "Services to the Health Industry"},
          ids: getSectorsDataSubsetByIds(data.sectors, [14, 60, 78, 81, 82, 83, 105, 106, 107, 122]),
        },
      ],
    },
    'Energy Sector': {
      presetFilter: {
        title: 'Energy Sector',
        description: 'See stocks or ETFs in the energy sector',
        name: 'energySector',
        icon: 'fa-plug',
        colorScheme: '#eecc06',
      },
      filters: [
        {
          name: 'Sectors',
          operator: '||',
          // OLD (Xpressfeed)
          // {sector_id: 1, description: "Energy Equipment and Services"}
          // {sector_id: 2, description: "Oil, Gas and Consumable Fuels"}
          // {sector_id: 11, description: "Electrical Equipment"}
          // {sector_id: 63, description: "Electric Utilities"}
          // {sector_id: 64, description: "Gas Utilities"}
          // {sector_id: 65, description: "Multi-Utilities"}
          // {sector_id: 66, description: "Water Utilities"}
          // {sector_id: 67, description: "Independent Power and Renewable Electricity Producers"}
          // NEW (Xignite)
          // {"sector_id": 6,"description": "Alternative Power Generation"},
          // {"sector_id": 23,"description": "Coal"},
          // {"sector_id": 31,"description": "Contract Drilling"},
          // {"sector_id": 36,"description": "Electric Utilities"},
          // {"sector_id": 37,"description": "Electrical Products"},
          // {"sector_id": 38,"description": "Electronic Components"},
          // {"sector_id": 39,"description": "Electronic Equipment/Instruments"},
          // {"sector_id": 40,"description": "Electronic Production Equipment"},
          // {"sector_id": 55,"description": "Gas Distributors"},
          // {"sector_id": 68,"description": "Integrated Oil"},
          // {"sector_id": 95,"description": "Oil & Gas Pipelines"},
          // {"sector_id": 96,"description": "Oil & Gas Production"},
          // {"sector_id": 97,"description": "Oil Refining/Marketing"},
          // {"sector_id": 98,"description": "Oilfield Services/Equipment"},
          // {"sector_id": 137,"description": "Water Utilities"},
          ids: getSectorsDataSubsetByIds(data.sectors, [6, 23, 31, 36, 37, 38, 39, 40, 55, 68, 95, 96, 97, 98, 137]),
        },
      ],
    },
    'Diversifying Stocks': {
      presetFilter: {
        title: 'Diversifying Stocks',
        description: 'Stocks with low correlation to your existing ideas',
        name: 'diversifyingStocks',
        icon: 'fa-pie-chart',
        colorScheme: '#111111',
      },
      filters: [
        {
          name: 'Avg. Correlation',
          operator: '<',
          value: 20.0,
        },
        {
          name: 'Market Cap',
          operator: '>',
          value: 1000,
        },
        {
          name: 'Collective Intelligence',
          operator: '||',
          ids: [''],
        },
      ],
    },
  };

  const trailingPresetFilters = [
    'Juicy Dividends',
    'Price / Tangible Book',
    'Create Custom Screen',
    'Technology Sector',
    'Financial Sector',
    'Healthcare Sector',
    'Energy Sector',
  ];

  const filtersFourWide = [
    'Value Stocks',
    'Famous Investors',
    'Diversifying Stocks',
    'Collective Intelligence',
    'Growth Stocks',
    "Friends' Ideas",
    ...trailingPresetFilters,
  ];
  const filtersThreeWide = [
    'Value Stocks',
    'Famous Investors',
    'Diversifying Stocks',
    'Collective Intelligence',
    'Growth Stocks',
    "Friends' Ideas",
    ...trailingPresetFilters,
  ];
  const filtersTwoWide = [
    'Value Stocks',
    'Diversifying Stocks',
    'Famous Investors',
    'Collective Intelligence',
    'Growth Stocks',
    "Friends' Ideas",
    ...trailingPresetFilters,
  ];

  const listByCardsPerRow = {
    2: filtersTwoWide,
    3: filtersThreeWide,
    4: filtersFourWide,
  };

  const listOfPresetFilters = listByCardsPerRow[cardsPerRow.toString()] || listByCardsPerRow['4'];

  const presetFilters = [];
  listOfPresetFilters.forEach((filterName) => {
    if (
      (showConnectionsCard || filterName !== "Friends' Ideas") &&
      (showDiversifyingCard || filterName !== 'Diversifying Stocks')
    ) {
      presetFilters.push(presetFiltersLookup[filterName]);
    }
  });

  const defaultFilters = {};

  const addFilter = (presetFilter, filters) => {
    // presetFilter = { title, description, name, icon, colorScheme }
    // filters = [ { name, operator, value, sort } ]
    defaultFilters[presetFilter.name] = {
      name: presetFilter.name,
      presetFilter,
      filters,
    };
  };

  presetFilters.forEach((filter) => {
    addFilter(filter.presetFilter, filter.filters);
  });

  return defaultFilters;
};

export const convertToCamelCase = (fieldName) => {
  const exceptions = {
    "On Connections' Watchlists": 'connections',
    'Owned by Thought Leaders': 'thought_leaders',
  };

  return exceptions[fieldName] || fieldName.toLowerCase().split(' ').join('_');
};

export const getSecuritiesFilterList = () => {
  return [
    'Price / Earnings',
    'Forward P/E',
    'Forward P/E Next Yr',
    'Price / Tangible Book',
    'Market Cap',
    'Net Debt',
    'Price',
    'Sectors',
    "On Connections' Watchlists",
    'Owned by Thought Leaders',
    'Revenue LTM',
    'Projected Revenue Growth',
    'Dividend Yield',
    'Avg. Correlation',
  ];
};

export const getFilterCreateBodyType = (filterName) => {
  const dict = {
    'Revenue LTM': 'expression',
    'Price / Earnings': 'expression',
    'Forward P/E': 'expression',
    'Forward P/E Next Yr': 'expression',
    'Price / Tangible Book': 'expression',
    'Market Cap': 'expression',
    'Dividend Yield': 'expression',
    'Projected Revenue Growth': 'expression',
    "On Connections' Watchlists": 'selection',
    Sectors: 'selection',
    'Owned by Thought Leaders': 'selection',
    'Net Debt': 'expression',
    Price: 'expression',
    'Collective Intelligence': 'selection',
    'Avg. Correlation': 'expression',
  };

  const type = dict[filterName];

  if (!type) {
    return null;
  }

  return type;
};

export const getSectorsDataSubsetByIds = (sectorsData, subsetIds) => {
  return sectorsData.filter((sector) => subsetIds.includes(sector.sector_id));
};
