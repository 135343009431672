export const PRODUCT_PARTNERS = {
  ladder: 'ladder',
  youNeedABudget: 'youNeedABudget',
  bestow: 'bestow',
  lemonade: 'lemonade',
  wagmo: 'wagmo',
  vspdirect: 'vspdirect',
  newConstructs: 'newConstructs',
  upstart: 'upstart',
  galat: 'galat',
  bankrate: 'bankrate',
  covered: 'covered',
  accreditedDebtRelief: 'accreditedDebtRelief',
  morningBrew: 'morningBrew',
  edX: 'edX',
  trustAndWill: 'trustAndWill',
  benzinga: 'benzinga',
  arro: 'arro',
};
