import Axios from 'axios';
import { LOCAL_API_DOMAIN } from '../../config';

export const API_URL = import.meta.env.VITE_API_URL || LOCAL_API_DOMAIN;
export const BASEURL = API_URL;

console.log('api:' + BASEURL);

const _axios = new Axios.create({
  baseURL: BASEURL,
  withCredentials: true,
});

const CSRF_TOKEN = window.localStorage && window.localStorage.getItem('X-CSRF-Token');
if (CSRF_TOKEN) {
  _axios.defaults.headers.common['X-CSRF-Token'] = CSRF_TOKEN;
}
_axios.defaults.headers.common['Accept'] = 'application/json';
_axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export const axios = _axios;
