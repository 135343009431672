import { serializerDefinitions as serializerLiveTradingDefinitions } from './liveTradingHelpers';
import { serializerDefinitions as serializerFundingDefinitions } from './fundingHelpers';
import { serializerDefinitions as serializerCurrentUserDefinitions } from './currentUserHelpers';

// endpoint for example 'liveTrading' or 'funding'
// source can be 'fromApi' or 'fromFront'
export const serializeApi = (endpoint, source, data) => {
  const dataKeys = Object.keys(data);

  const serializer = {
    funding: serializerFundingDefinitions,
    liveTrading: serializerLiveTradingDefinitions,
    currentUser: serializerCurrentUserDefinitions,
  };

  const serializerFunctions = serializer[endpoint].serializerFunctions;
  const serializeFields = serializer[endpoint].serializeFields;

  const fieldsToSerialize = dataKeys.filter((key) => key in serializeFields);
  const fieldsToKeep = dataKeys.filter((key) => !(key in serializeFields));

  let formData = {};

  for (let i = 0; i < fieldsToSerialize.length; i++) {
    const key = fieldsToSerialize[i];
    const serializerOutput = serializerFunctions[serializeFields[key]](source, data[key], key);

    if (serializerOutput && typeof serializerOutput === 'object') {
      formData = { ...formData, ...serializerOutput };
    } else {
      formData[key] = serializerOutput;
    }
  }
  for (let i = 0; i < fieldsToKeep.length; i++) {
    const key = fieldsToKeep[i];
    formData[key] = data[key];
  }

  return formData;
};

export const normalizeSecurityPriceDataResponse = (securityPriceDataResponse, priceQuoteTimeStamp) => {
  const acceptedProperties = [
    'featured',
    'is_halted',
    'is_short_allowed',

    'current_price',
    'current_price_change',
    'current_price_change_percent',

    'last_price',
    'last_size',

    'bid_price',
    'bid_size',

    'ask_price',
    'ask_size',
  ];
  const sanitizedData = {
    ...priceQuoteTimeStamp,
  };
  const keys = Object.keys(securityPriceDataResponse);
  keys
    .filter((key) => acceptedProperties.includes(key))
    .forEach((key) => {
      sanitizedData[key] = securityPriceDataResponse[key];
    });

  return {
    id: securityPriceDataResponse.security_id,
    ...sanitizedData,
  };
};
