import React from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import InfoIcon from '../../containers/UI/InfoIcon';
import { TextWrapper } from '../../containers/UI/TextWrapper';

import {
  userShortName,
  userFullName,
  userIsThoughtLeader,
  displayThoughtLeaderCompany,
  displayThoughtLeaderName,
  userShortNamePossessive,
  userFullNamePossessive,
  isUserCollective,
  userDisplayName,
  userDisplayNamePossessive,
  userSecondaryDisplayNamePossessive,
  userSecondaryDisplayName,
} from '../../helpers/usersHelpers';
import { returnPathTo } from '../../constants/paths';
import { getViewpointDisplayNameFromState } from '../../selectors/viewpoints';
import styled from 'styled-components';

export const DISPLAY_NAME_TYPE = {
  FULL_NAME: 'fullName',
  SHORT_NAME: 'shortName',
  PROFILE_NAME: 'profileName',
  PROFILE_SECONDARY_NAME: 'secondaryProfileName',
};

const UserNameWrapper = styled.div`
  a * {
    color: ${({ theme }) => theme.themeColors.text} !important;
  }
`;

export class UserName extends React.PureComponent {
  render() {
    const {
      user: propsUser,
      ideaId,
      profileLinkQuery,
      hide,
      inline,
      customClass,

      viewpointOrgName,
      isViewpointOrg,
    } = this.props;

    if (hide) return null;

    const user = propsUser || {};

    const userId = isViewpointOrg ? null : user.user_id;
    const withLink = 'withLink' in this.props ? this.props.withLink : true;
    const isThoughtLeader = userIsThoughtLeader(user);
    const isCollective = isUserCollective(user);
    const query = profileLinkQuery || '';

    const linkTo =
      'linkTo' in this.props
        ? this.props.linkTo
        : isThoughtLeader
        ? `/users/${userId}/public_profile${query}`
        : isViewpointOrg
        ? returnPathTo('viewpoint_profile', viewpointOrgName)
        : ideaId
        ? `/ideas/${ideaId}`
        : `/users/${userId}/public_profile${query}`;

    const linkWrapper = (children) => {
      const el = withLink ? Link : 'span';
      const props = withLink ? { to: linkTo } : { className: 'link-wrapper' };
      return React.createElement(el, props, children);
    };

    return (
      <UserNameWrapper className={`user-name-container ${inline ? 'inline-name' : ''} ${customClass || ''}`}>
        {isCollective ? (
          <span className={'info-icon-text-wrapper'}>
            <span className=" ">{linkWrapper(this._generateUserDisplayNameComponent())}</span>
            <InfoIcon
              className="collective-info-icon"
              word={'tooltip_collective_user'}
              style={{ top: '2px' }}
              position={'top-right'}
            />
          </span>
        ) : (
          <span className=" ">{linkWrapper(this._generateUserDisplayNameComponent())}</span>
        )}
      </UserNameWrapper>
    );
  }

  _generateUserDisplayNameString = () => {
    const {
      user,
      displayNameStyle,
      userIsCurrentUser,
      showCurrentUserFullName,
      isPossessive,
      useYou,

      displayText,
      isViewpointOrg,
    } = this.props;

    if (isViewpointOrg) return displayText;

    if (userIsCurrentUser && !showCurrentUserFullName) {
      return isPossessive ? 'My' : useYou ? 'You' : 'Me';
    }

    if (isUserCollective(user)) return 'Top 10%';

    if (userIsThoughtLeader(user)) return `${displayThoughtLeaderCompany(user)} - ${displayThoughtLeaderName(user)}`;

    if (displayNameStyle === DISPLAY_NAME_TYPE.FULL_NAME) {
      return isPossessive ? userFullNamePossessive(user) : userFullName(user);
    }

    if (displayNameStyle === DISPLAY_NAME_TYPE.SHORT_NAME) {
      return isPossessive ? userShortNamePossessive(user) : userShortName(user);
    }

    if (displayNameStyle === DISPLAY_NAME_TYPE.PROFILE_NAME) {
      return isPossessive ? userDisplayNamePossessive(user) : userDisplayName(user);
    }

    if (displayNameStyle === DISPLAY_NAME_TYPE.PROFILE_SECONDARY_NAME) {
      return isPossessive ? userSecondaryDisplayNamePossessive(user) : userSecondaryDisplayName(user);
    }

    return isPossessive ? userFullNamePossessive(user) : userFullName(user);
  };

  _generateUserDisplayNameComponent = () => {
    const { punctuation, isLoading } = this.props;

    const generateDisplayName = this._generateUserDisplayNameString();

    if (!generateDisplayName) {
      return null;
    }

    if (isLoading) {
      return null;
    }

    const nameStrings = generateDisplayName.split(' ');
    return nameStrings.map((string, i) => (
      <TextWrapper
        key={`${string}-${i}`}
        className={i < nameStrings.length - 1 ? '' : punctuation ? 'inline-block' : ''}
      >
        {string}
        {i === nameStrings.length - 1 ? <span>{punctuation}</span> : <span>&#32;</span>}
      </TextWrapper>
    ));
  };
}

const mapStateToProps = (state, ownProps) => {
  const { isViewpointOrg, viewpointOrgId } = ownProps;
  if (isViewpointOrg) {
    return {
      viewpointOrgName: getViewpointDisplayNameFromState(state, viewpointOrgId),
    };
  } else {
    return {};
  }
};

export default connect(mapStateToProps)(UserName);
