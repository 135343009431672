import React from 'react';
import { LinkTo } from '../../Links/LinkTo';
import { returnPathTo } from '@src/constants/paths';
import { Body5 } from '@src/main/lib/nvstr-common-ui.es';
import { SUPPORT_EMAIL } from '@src/appConfig';

const FAQ = (props) => {
  return (
    <div className={`faq-disclaimer`}>
      <Body5>
        <span className={``}>Still have questions? We're here to help! Check the{'\u00A0'}</span>
        {props.openInNewTab ? (
          <a href={returnPathTo('faq')} target={'_blank'} className="">
            {'FAQ'}
          </a>
        ) : (
          <LinkTo to={returnPathTo('faq')} className="" child={'FAQ'} />
        )}
        <span className={``}>
          {'\u00A0'}or contact us anytime at{'\u00A0'}
        </span>
        <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
      </Body5>
    </div>
  );
};

export default FAQ;
