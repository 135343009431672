import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;
const CURRENT_USER_ENDPOINT_URL = `${URL}/current_user`;

export const currentUserAdapter = {
  fetchCurrentUserQuickInfo: (token = {}) => {
    const auth = { ...token };
    const params = auth;
    const options = { ignoreAuth: true };

    return sendRequest('get', [`${URL}/users?include_self=true&include_portfolio=true`, { params }], options);
  },

  refreshCurrentUserPositionsInfo: () => {
    const options = { ignoreAuth: true };

    return sendRequest('get', [`${URL}/users?include_self=true&include_portfolio=true`], options).then(
      (response) => response.data
    );
  },

  refreshCurrentUserExternalPositionsInfo: (token = {}) => {
    const url = `${URL}/current_user/external_positions`;

    const auth = { ...token };
    const params = auth;

    return sendRequest('get', [url, { params }]);
  },

  fetchOptionsPositions: () => {
    return sendRequest('get', [`${URL}/v1/options_trading_get`]);
  },

  createOptionsPositions: (params) => {
    return sendRequest('post', [`${URL}/v1/options_trading_post`, params]);
  },

  fetchEditUserProfilePage: () => {
    return sendRequest('get', [`${BASEURL}/users/edit`]);
  },

  postSeenMarginCall: () => {
    return sendRequest('post', [`${URL}/live_accounts/dismiss_margin_call_message`]);
  },

  changeColorScheme: (desiredColorScheme) => {
    const options = { ignoreAuth: true };
    return sendRequest('post', [`${URL}/color_schemes`, { color_scheme: desiredColorScheme }], options);
  },

  updateUserProperty: (changedUserFormData, ignoreAuth) => {
    const options = { ignoreAuth };
    return sendRequest('put', [`${CURRENT_USER_ENDPOINT_URL}`, { user: changedUserFormData }], options).then(
      (response) => (response && response.data ? response.data : response)
    );
  },

  postAgreedToBDTerms: () => {
    return sendRequest('post', [`${CURRENT_USER_ENDPOINT_URL}/agree_to_terms`]);
  },

  closeAccount: () => {
    return sendRequest('delete', [`${CURRENT_USER_ENDPOINT_URL}`]);
  },

  postAvatarUploadImage: (formData) => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    return sendRequest('post', [`${CURRENT_USER_ENDPOINT_URL}/avatar`, formData, config]);
  },

  deleteAvatarUploadImage: () => {
    return sendRequest('delete', [`${CURRENT_USER_ENDPOINT_URL}/avatar`]);
  },

  changeEmail: (currentPassword, newEmail) => {
    const body = {
      user: {
        current_password: currentPassword,
        email: newEmail,
      },
    };
    return sendRequest('patch', [`${CURRENT_USER_ENDPOINT_URL}/email`, body]);
  },

  changePassword: (currentPassword, newPassword) => {
    const body = {
      user: {
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: newPassword,
      },
    };
    return sendRequest('patch', [`${CURRENT_USER_ENDPOINT_URL}/password`, body]);
  },

  resendRequiredDocsEmail: () => {
    return sendRequest('post', [`${URL}/live_accounts/documentation/request_notice`]);
  },

  startMembership: () => {
    return sendRequest('post', [`${CURRENT_USER_ENDPOINT_URL}/start_membership`]);
  },
};
