import React from 'react';

import InfoIcon from '../UI/InfoIcon';
import ChangeArrow from '../../components/misc/ChangeArrow';

import { formatSecurityDataValue } from '../../helpers/securitiesHelpers';
import { calcPercIncrease } from '../../helpers/generalHelpers';

const ThoughtLeaderIdea = (props) => {
  const { idea, security } = props;

  const currentPrice = security.current_price;

  const position = idea.idea_type.idea_type_id === 0 ? 'Buy' : 'Sell';
  const performance = {
    thisMonth: idea.this_month_perf,
    prevMonth: idea.prev_month_perf,
  };

  const projectedRevenueGrowth = security.analyst_2yr_growth_projection;
  const forwardPE = security.forward_pe_ratio_next_year;
  const ownedAsOf = idea.admin_created_date;

  return (
    <div className="idea-content thought-leader-idea">
      <div className="left-idea-panel">
        <div className="idea-performance-container">
          <p className="secondary-heading-text-color">Performance</p>
          <div className="sub-label secondary-text-color">This Month</div>
          <div className="value">
            <ChangeArrow value={performance.thisMonth} />
            {formatSecurityDataValue(Math.abs(performance.thisMonth), 'percentage')}
          </div>
          <div className="sub-label secondary-text-color">Previous Month</div>
          <div className="value">
            <ChangeArrow value={performance.prevMonth} />
            {formatSecurityDataValue(Math.abs(performance.prevMonth), 'percentage')}
          </div>
        </div>
      </div>
      <div className="right-idea-panel">
        <div className="projected-revenue-growth-container">
          <div className="idea-label">
            <span className="secondary-text-color">{'Projected Revenue Growth'}</span>
            <InfoIcon word={`tooltip_projected_revenue_growth`} style={{ marginTop: '1px' }} position={'right'} />
          </div>
          <div className="idea-value">
            {formatSecurityDataValue(projectedRevenueGrowth, 'analyst_2yr_growth_projection')}
          </div>
        </div>
        <div className="forward-pe-container">
          <div className="idea-label">
            <span className="secondary-text-color">{'Forward P/E'}</span>
            <InfoIcon word={`tooltip_forward_pe_ratio_next_year`} position={'right'} style={{ marginTop: '1px' }} />
          </div>
          <div className="idea-value">{formatSecurityDataValue(forwardPE, 'forward_pe_ratio_next_year')}</div>
        </div>
        <div className="owned-as-of-container">
          <div className="idea-label">
            <span className="secondary-text-color">{'Owned As Of'}</span>
          </div>
          <div className="idea-value">{formatSecurityDataValue(ownedAsOf, 'owned_as_of')}</div>
        </div>
      </div>
    </div>
  );
};

export default ThoughtLeaderIdea;
