import React from 'react';
import amplitude from 'amplitude-js';
import { LOG_AMPLITUDE_EVENTS } from '@src/appConfig';
import { isCurrentUserOnboarding } from '@src/helpers/currentUserHelpers';
import { UTM_KEYS, UTMTrackingKeys } from '@src/constants/types/utm';
import { getDaysSinceDate, getWeekNumber, moment } from '@src/helpers/timeHelpers';
import { TrackingEvents } from './events';
import { sendFacebookLTVEvent } from '@src/constants/facebookTracking';

let didInit = false;

let PUBLIC_AMPLITUDE_KEY = import.meta.env.VITE_PUBLIC_AMPLITUDE_KEY;
if (!PUBLIC_AMPLITUDE_KEY) {
  PUBLIC_AMPLITUDE_KEY = '5d38cf63e73716fbf73bbe6811283360';
  console.warn('amplitude key not found, defaulting...');
}

export const logAmplitudeEvent = async (eventName, properties = {}) => {
  amplitude.getInstance('legacyEvents').logEvent(eventName, properties);
};

const logSignInEvents = (sessionData) => {
  const analyticsId = sessionData.analytics_id;
  const signInCount = sessionData.sign_in_count;
  const signupDate = sessionData.signed_up_at;
  const isLiveTrading = sessionData.is_live_trading;
  const daysSinceSignup = getDaysSinceDate(signupDate);

  if (signInCount === 5 && daysSinceSignup <= 7 && isLiveTrading) sendFacebookLTVEvent(5);

  TrackingEvents.application.SIGN_IN.send({
    User: analyticsId,
    'Onboarding End Test Group': sessionData.onboarding_end_test_group,
  });

  const SIGN_IN_MILESTONES = [10, 20, 50];
  if (SIGN_IN_MILESTONES.includes(signInCount)) {
    TrackingEvents.application.SIGNED_IN_X_TIMES.send({
      User: analyticsId,
      'Onboarding End Test Group': sessionData.onboarding_end_test_group,
    });
  }

  const SIGN_IN_MILESTONE_THRESHOLDS = [20, 50];
  SIGN_IN_MILESTONE_THRESHOLDS.forEach((times) => {
    if (signInCount >= times) {
      TrackingEvents.application.SIGNED_IN_AT_LEAST_X_TIMES.send({
        times,
      });
    }
  });
};

export const sendSessionStartEvents = (currentUserSessionData) => {
  logSignInEvents(currentUserSessionData);
};

export const buildUserTrackingProperties = (currentUserSessionData, currentUser) => {
  const userProperties = {};

  const signUpAtMoment = moment(currentUser.signed_up_at);
  userProperties['Sign Up Year'] = signUpAtMoment.format('YYYY');
  userProperties['Sign Up Month'] = signUpAtMoment.format('M');
  userProperties['Sign Up Week'] = getWeekNumber(signUpAtMoment.toDate());

  userProperties['Membership Opt In Test Group'] = currentUser.membership_opt_in_test_group;
  userProperties['Show Mobile App Review Prompt Test Group'] = currentUser.show_mobile_app_review_prompt_test_group;

  userProperties['Show Funding CTA Banner Test Group'] = currentUser.show_funding_cta_banner_test_group;
  userProperties['Show Funding CTA Modal Test Group'] = currentUser.show_funding_cta_modal_test_group;

  userProperties['Show Fractional Trading Test Group'] = currentUser.show_fractional_trading_test_group;
  userProperties['Sign Up Reward Requires Deposit Test Group'] = currentUser.sign_up_reward_requires_deposit_test_group;

  userProperties['Inbound From Ad'] = currentUser.inbound_from_ad;
  userProperties['App Store Config'] = currentUser.app_store_cfg;

  userProperties['On Waitlist'] = currentUser.is_on_waitlist;
  userProperties['Live Account'] = currentUser.is_live_trading;
  userProperties['Nvstr Employee'] = currentUser.is_nvstr_employee;
  userProperties['Tornado Employee'] = currentUser.is_nvstr_employee;
  userProperties['Current Show Compare SPY Price History Preference'] = currentUser.show_compare_spy_price_history;
  userProperties['Current Color Scheme Preference'] = currentUser.app_color_theme;

  if (currentUser.started_live_trading_at) {
    userProperties['Started Live Trading At'] = currentUser.started_live_trading_at;
  }

  userProperties['Account Type Selection Test Group'] = '0';
  userProperties['Inbound From Ad'] = false;

  userProperties['Subscriber'] = currentUserSessionData.is_subscriber;
  userProperties['Age Range'] = currentUserSessionData.age_range;
  userProperties['Gender'] = currentUserSessionData.gender;
  userProperties['Is Live Trading'] = currentUserSessionData.is_live_trading;
  userProperties['Gender'] = currentUserSessionData.gender;
  userProperties['Is Student'] = currentUser.is_student;
  userProperties['In Onboarding'] = isCurrentUserOnboarding(currentUser);
  userProperties['Is Onboarding'] = isCurrentUserOnboarding(currentUser);

  userProperties['Analysis Nav Tab Badge Test Group'] = currentUserSessionData.analysis_nav_tab_badge_test_group;
  userProperties['Analysis Nav Tab Label Test Group'] = currentUserSessionData.analysis_nav_tab_label_test_group;
  userProperties['Ask Nav Tab Label Test Group'] = currentUserSessionData.ask_nav_tab_label_test_group;
  userProperties['Choose Reward Cards Test Group'] = currentUserSessionData.choose_reward_cards_test_group;
  userProperties['Default Activity Tab Test Group'] = currentUserSessionData.default_activity_tab_test_group;
  userProperties['Default Color Scheme Test Group'] = currentUserSessionData.default_color_scheme_test_group;
  userProperties['Default Show Compare SPY Price History'] =
    currentUserSessionData.default_show_compare_spy_price_history_test_group;
  userProperties['Explore Nav Tab Label'] = currentUserSessionData.explore_nav_tab_label_test_group;
  userProperties['Funding Security Message Test Group'] = currentUserSessionData.funding_security_message_test_group;
  userProperties['Hide Microdeposit Option Test Group'] = currentUserSessionData.hide_microdeposit_option_test_group;
  userProperties['Invite Friends Message Style'] = currentUserSessionData.invite_friends_message_style_test_group;
  userProperties['No Basket Manual Order Test Group'] = currentUserSessionData.no_basket_manual_order_test_group;
  userProperties['No Button Manual Order Test Group'] = currentUserSessionData.no_button_manual_order_test_group;
  userProperties['Onboarding End Test Group'] = currentUserSessionData.onboarding_end_test_group;
  userProperties['Prominent Refresh Buttons Test Group'] = currentUserSessionData.prominent_refresh_buttons_test_group;
  userProperties['Sign Up Bonus Panel Follow Up Test Group'] =
    currentUserSessionData.sign_up_bonus_panel_follow_up_test_group;
  userProperties['Subscription Plan Name Test Group'] = currentUserSessionData.subscription_plan_name_test_group;
  userProperties['Show Funding Amount Buttons Test Group'] =
    currentUserSessionData.show_funding_amount_buttons_test_group;
  userProperties['Splash Color Theme Test Group'] = currentUserSessionData.splash_color_theme_test_group;
  userProperties['Splash Message Theme Test Group'] = currentUserSessionData.splash_message_theme_test_group;
  userProperties['Thought Notif Style Test Group'] = currentUserSessionData.thought_notif_style_test_group;
  userProperties['Top Performers In Nav Test Group'] = currentUserSessionData.top_performers_in_nav_group;

  userProperties['Has Membership'] = currentUserSessionData.has_membership;
  userProperties['Has Paper Membership'] = currentUserSessionData.has_paper_membership;
  userProperties['Membership Waive Fee Minimum Equity'] = currentUserSessionData.membership_waive_fee_min_equity;

  userProperties['Rewards Require ACH Link Test Group'] = currentUserSessionData.rewards_require_ach_link_test_group;
  userProperties['Show Funding Insurance Message Test Group'] =
    currentUserSessionData.show_funding_insurance_message_test_group;
  userProperties['Onboarding Requires ACH Link Test Group'] =
    currentUserSessionData.onboarding_requires_ach_link_test_group;
  userProperties['Show Credit Card Payment Option Test Group'] =
    currentUserSessionData.show_credit_card_payment_option_test_group;
  userProperties['Reject Low Balance ACH Links Test Group'] =
    currentUserSessionData.reject_low_balance_ach_links_test_group;
  userProperties['Show Thought Posted At In App Feed Test Group'] =
    currentUserSessionData.show_thought_posted_at_in_app_feed_test_group;
  userProperties['Show_Rewards Red Dot In App Test Group'] =
    currentUserSessionData.show_rewards_red_dot_in_app_test_group;
  userProperties['Onboarding Rewards Test Group'] = currentUserSessionData.onboarding_rewards_test_group;
  userProperties['Alternative Email From Domain Test Group'] = currentUserSessionData.alt_email_from_domain_test_group;
  userProperties['Limit One Email Per Day Test Group'] = currentUserSessionData.limit_one_email_per_day_test_group;

  userProperties['Has Linked Bank Account'] = currentUserSessionData.has_linked_bank_account;
  userProperties['Has Linked Payment Card'] = currentUserSessionData.has_linked_payment_source;
  userProperties['Streaming Prices Test Group'] = currentUserSessionData.streaming_prices_test_group;

  userProperties['Default Stock Panel To Financial Tab Test Group'] =
    currentUserSessionData.default_stock_panel_to_financial_tab_test_group;
  userProperties['Weekly Idea Perf Notif Test Group'] =
    currentUserSessionData.weekly_idea_perf_notif_threshold_test_group;
  userProperties['Autoconnected To CTLs Test Group'] = currentUserSessionData.autoconnected_to_ctls_test_group;
  userProperties['Show Algo Selected Thoughts In Feed Test Group'] =
    currentUserSessionData.show_algo_selected_thoughts_in_feed;
  userProperties['Show Mobile App Review Test Group'] = currentUserSessionData.show_mobile_app_review_prompt_test_group;

  userProperties['Show Community Points Test Group'] = currentUserSessionData.show_community_points_test_group;
  userProperties['Community Points Label Name'] = 'XP';
  userProperties['Community Points Total'] = currentUserSessionData.community_points_total;
  userProperties['Community Points Display Style Test Group'] =
    currentUserSessionData.community_points_display_style_test_group;
  userProperties['Show Hit Max Community Points Test Group'] =
    currentUserSessionData.show_hit_max_community_points_test_group;

  userProperties['Show Feed Filters Test Group'] = currentUserSessionData.show_feed_filters_test_group;

  userProperties['Thought Leaders Labeled Famous Investors Test Group'] =
    currentUserSessionData.thought_leaders_labeled_famous_investors_test_group;
  userProperties['Redeem Rewards After Funding Test Group'] =
    currentUserSessionData.sign_up_reward_requires_deposit_test_group;
  userProperties['Open Plaid After Application Submission Test Group'] =
    currentUserSessionData.open_plaid_after_application_submission_test_group;
  userProperties['Learn And Earn Requires Deposit Test Group'] =
    currentUserSessionData.learn_and_earn_requires_deposit_test_group;

  userProperties['Preset Deposit Amounts Test Group'] = currentUserSessionData.preset_deposit_amounts_test_group;

  const fiftyFiftyFEGroup = currentUserSessionData.id % 2 === 0;
  userProperties['Socure Upload Flow Test Group'] = fiftyFiftyFEGroup ? 'DocV Flow' : 'Manual Upload Flow';

  const totalSubCount = currentUserSessionData.total_subscription_count;
  if (totalSubCount !== null) userProperties['Viewpoint Subscription Count'] = totalSubCount;

  return userProperties;
};

export const setTrackingUserProperties = (userProperties) => {
  if (!didInit) {
    console.warn('amplitude still initializing');
    return;
  }

  amplitude.getInstance('legacyEvents').setUserProperties(userProperties);

  if (LOG_AMPLITUDE_EVENTS) {
    console.log('setting user tracking properties', Object.entries(userProperties));
  }
};

export const setUTMUserProperties = (currentUser) => {
  const userProperties = {};

  UTM_KEYS.forEach((key) => {
    const trackingKey = UTMTrackingKeys[key];
    if (key in currentUser) {
      userProperties[trackingKey] = currentUser[key];
    } else {
      userProperties[trackingKey] = null;
    }
  });

  if (Object.keys(userProperties).length === 0) return null;

  setTrackingUserProperties(userProperties);
  amplitude.getInstance('legacyEvents').setUserProperties(userProperties);
};

export const setTrackingUserProperty = (key, value) => {
  const identify = new amplitude.Identify();
  identify.set(key, value);
  amplitude.getInstance('legacyEvents').identify(identify);

  if (LOG_AMPLITUDE_EVENTS) {
    console.log('setting user tracking property', [key, value]);
  }
};

export const initializeAmplitudeClient = (amplitudeUserId) => {
  const instance = amplitude.getInstance('legacyEvents').init(PUBLIC_AMPLITUDE_KEY, amplitudeUserId); // initializes default instance of Amplitude client
  didInit = true;

  if (LOG_AMPLITUDE_EVENTS) {
    console.log('Amplitude initializing', {
      PUBLIC_AMPLITUDE_KEY,
      amplitudeUserId,
    });
  }

  const identify = new amplitude.Identify().set('User', amplitudeUserId);
  amplitude.getInstance('legacyEvents').identify(identify);
};
