import React from 'react';
import { connect } from 'react-redux';
import { getCurrentUserFromState } from '../../../../selectors/currentUser';
import { getAskInNavTestGroupFromCurrentUser } from '../../../../helpers/currentUserHelpers';

import VerticalNavItem from '../../components/VerticalNavItem';
import ConversationIcon from '@src/main/components/Images/ConversationIcon';

const AskACTL = (props) => {
  const { testGroup } = props;

  if (testGroup === 0) {
    return null;
  }

  const testGroupDisplayStrings = {
    1: 'Ask A CTL',
    2: 'Ask',
  };

  const routeName = testGroupDisplayStrings[testGroup];
  return <VerticalNavItem name={routeName} customIcon={<ConversationIcon />} route="/ask" />;
};

const mapStateToProps = (state) => {
  const currentUser = getCurrentUserFromState(state);
  return {
    testGroup: getAskInNavTestGroupFromCurrentUser(currentUser),
  };
};

export default connect(mapStateToProps)(AskACTL);
