import React from 'react';
import styled from 'styled-components';
import { MobileLogo } from '@src/main/components/logo/MobileLogo';
import { convertHexToRGBA } from '@src/main/lib/nvstr-utils.es';
import { PRODUCT_NAME, PRODUCT_NAMES } from '@src/appConfig';

const WithLogoWrapper = styled.div`
  position: relative;
  line-height: 1;
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 7px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-radius: 100px;
  background: ${({ theme }) => convertHexToRGBA(theme.themeColors.appBackground, 0.5)};

  svg {
    padding-top: 5px;
    height: 32px;
    width: 32px;

    opacity: 0.35;
  }
`;
const Wrapper = styled.svg`
  height: ${({ size }) => (size === 'small' ? '32px' : '64px')};
  width: ${({ size }) => (size === 'small' ? '32px' : '64px')};

  .outline-stroke {
    stroke: ${({ theme }) => theme.themeColors.appBackground};
    stroke-opacity: 1;
  }
  .rotating-stroke {
    stroke: ${({ theme }) => theme.themeColors.primaryCtaButton};
    stroke-opacity: 0.8;
  }
`;

const Icon = ({ size }) => {
  return (
    <Wrapper viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" size={size}>
      <g fill="none" fillRule="evenodd" className={'outline-stroke'}>
        <g transform="translate(1 1)" strokeWidth="2px">
          <circle cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18" className={'rotating-stroke'}>
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="700ms"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </Wrapper>
  );
};
export function LoadingAnimation({ withLogo, size }) {
  if (withLogo && PRODUCT_NAME === PRODUCT_NAMES.Tornado) {
    return (
      <WithLogoWrapper>
        <Icon />
        <LogoWrapper>
          <MobileLogo />
        </LogoWrapper>
      </WithLogoWrapper>
    );
  }
  return <Icon size={size} />;
}
