import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import SearchContainer from '../UI/Search/SearchContainer';
import CreateThoughtContainer from './CreateThoughtContainer';
import LinkTo from '../Links/LinkTo';
import { returnPathTo } from '../../constants/paths';
import { ThoughtEventNames } from '../../constants/tracking';
import { logMetricsTrackingEvent } from '../../actions/index';

export class AddThoughtInFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      security: null,
    };
  }

  render() {
    return (
      <div
        className="create-thought-in-feed-container"
        ref={(el) => {
          this.addAnyThoughtFormContainer = el;
        }}
      >
        {this._isThoughtComponentOpen() ? this._renderCreateThought() : this._renderStockInput()}
      </div>
    );
  }

  _renderCreateThought = () => {
    return (
      <CreateThoughtContainer
        security={this._returnSecurity()}
        handleCreateThought={this.createThought}
        handleCancelCreatingThought={this.cancelCreatingThought}
        thoughtPreSuccessDelayAdditionalAction={this.getUpdateToDateNotifications}
        thoughtPostSuccessAdditionalAction={this._onPostThoughtSuccess}
        viewingContext={ThoughtEventNames.CREATE_THOUGHT_FROM_DASHBOARD_FEED}
      />
    );
  };

  _renderStockInput = () => {
    return (
      <div className={`default-flex input-form-as-panel-styling`}>
        <SearchContainer
          id="add-thought-security-search"
          customIcon={'fa-commenting'}
          autoFocus={false}
          parentComponent="Feed"
          placeholder={this._generateAddThoughtHeadingMessage()}
          handleSecurityQuerySelection={this._handleSecuritySelection}
          ignoreClearQueryAfterSelection
        />
        {this._renderInviteFriendsButton()}
      </div>
    );
  };

  _renderInviteFriendsButton = () => {
    return (
      <div className={`invite-friends-from-feed`}>
        <LinkTo
          to={returnPathTo('referrals')}
          className="link-btn"
          additionalClickAction={this._logInviteFriendsFromFeedClick}
          text={'Invite A Friend'}
          scrollToTop
        />
      </div>
    );
  };

  _renderHeading = () => {
    return <div className="thought-heading-message  ">{this._generateAddThoughtHeadingMessage()}</div>;
  };

  getUpdateToDateNotifications = () => {
    if (!this.props.getUpdatedNotifications) {
      console.error('AddThoughtFeed is missing getUpdatedNotifications prop');
    }
    if (!this.props.sendUpdatedLastSeenNotifTime) {
      console.warn('AddThoughtFeed is missing sendUpdatedLastSeenNotifTime prop');
    }
    this.props.getUpdatedNotifications().then((response) => {
      if (this.props.sendUpdatedLastSeenNotifTime) {
        this.props.sendUpdatedLastSeenNotifTime();
      }
      return response;
    });
  };

  _handleSecuritySelection = (security) => {
    this._selectSecurityForThought(security);
    this._openThoughtCreationComponent();
    logMetricsTrackingEvent('Selected Security Above Feed', {})();
  };

  createThought = (thought) => this._createThought(thought);

  _createThought = (thought) => {
    const security = this._returnSecurity();
    const formattedThought = {
      security_id: this._returnSecurityId(),
      ...thought,
    };

    return this.props.actions.createThought([formattedThought]).then((response) => {
      if (response.error) {
        this._showCreateThoughtErrorModal(response);
      } else {
        logMetricsTrackingEvent('Created Thought Above Feed', {})();
      }
      return response;
    });
  };

  _showCreateThoughtErrorModal = (response) => {
    const generalComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {`Could not save thought. Please try again later.`}
      </div>
    );
    const unfeaturedMsgComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {`Cannot create a new thought for this security.  Security is currently unavailable.`}
      </div>
    );
    const modal = {
      contentComponent:
        response.thoughtsWithErrors &&
        response.thoughtsWithErrors[0].error === "Can't create a thought for an un-featured security."
          ? unfeaturedMsgComponent
          : generalComponent,
      dismissable: true,
    };
    this.props.actions.showModal(modal);
  };

  _onPostThoughtSuccess = () => {
    this._resetComponentToDefaultState();
  };

  cancelCreatingThought = () => {
    this._logCancelCreateThoughtInFeed();
    this._clearSecurity();
    this._closeThoughtCreationComponent();
  };

  _resetComponentToDefaultState = () => {
    this._clearSecurity();
    this._closeThoughtCreationComponent();
  };

  _clearSecurity = () => {
    this.setState(() => ({
      security: null,
    }));
  };

  _isThoughtComponentOpen = () => this.state.isOpen;

  _selectSecurityForThought = (security) => {
    this.setState(() => ({
      security: security,
    }));
  };

  _openThoughtCreationComponent = () => {
    this.setState(() => ({
      isOpen: true,
    }));
  };

  _closeThoughtCreationComponent = () => {
    this.setState(() => ({
      isOpen: false,
    }));
  };

  _returnSearchQuerySecurity = () => (this.state.security ? this.state.security : {});
  _returnSecurityId = () => this._returnSearchQuerySecurity().id;
  _returnSecuritySymbol = () => this._returnSearchQuerySecurity().symbol;

  _returnSecurity = () => ({
    ...this._returnSearchQuerySecurity(),
    security_id: this._returnSecurityId(),
  });

  _generateAddThoughtHeadingMessage = () => 'What stock is on your mind?';

  _logCancelCreateThoughtInFeed = () => {
    const event = 'Cancel Create Thought In Feed';
    const properties = {
      'Security ID': this._returnSecurityId(),
      'Security Symbol': this._returnSecuritySymbol(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logInviteFriendsFromFeedClick = () => {
    const event = 'Clicked Invite A Friend';
    const properties = {
      Context: 'Feed',
      'With Invite Friends Messaging': this.props.showingInviteFriendsMessaging,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddThoughtInFeed);
