import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../../../actions/index';

import LinkTo from '../../../Links/LinkTo';
import FundamentalData from '../../../SecurityData/FundamentalData';
import InfoIcon from '../../../UI/InfoIcon';

import { truncateName, snakeToHyphens, isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import { findUserIdeaForSecurity } from '../../../../helpers/ideaHelpers';

import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';

export class ThoughtLeaderIdeaDataCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? this.props.loadingCellComponent : this._renderCellContent()}
      </td>
    );
  }

  _isLoading = () => {
    const { securityId } = this.props;

    return (
      this.props.ideas.loadingCurrentUserIdeas ||
      !(securityId in this.props.securities.lookup) ||
      !('name' in this.props.securities.lookup[securityId])
    );
  };

  _returnLinkForHandlingClick = () => {
    const { userId, securityId } = this.props;
    const ideaUserId = userId;

    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    const ideaId = idea && idea.idea_id;

    const additionalProps = {
      activeTab: 'ideas',
      ideaId,
    };
    const openSecurityPanelConfig = {
      securityId,
      additionalProps,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    const { userId, securityId } = this.props;
    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(userId, securityId, ideaList);
    const ideaCreatedDate = idea.admin_created_date;
    const formattedDate =
      ideaCreatedDate && typeof ideaCreatedDate === 'string'
        ? ideaCreatedDate[0] === '0'
          ? ideaCreatedDate.slice(1)
          : ideaCreatedDate
        : ideaCreatedDate;

    return (
      <span className={`security-table-tl-idea-container`}>
        <span className={`security-table-tl-idea-data`}>
          <span className={`security-table-idea-performance-time-period`}>
            <span className={`security-table-tl-idea-performance-label`}>
              <span style={{ position: 'relative' }}>
                {'Proj. Rev. Growth'}
                <InfoIcon word={'tooltip_projected_revenue_growth'} style={{ top: '1px', lineHeight: 1.4 }} />
              </span>
            </span>
            <span className={`security-table-tl-idea-performance-value`}>
              <FundamentalData hideIfNA={false} securityId={securityId} property={'Projected Revenue Growth'} />
            </span>
          </span>
          <span className={`security-table-idea-performance-time-period`}>
            <span className={`security-table-tl-idea-performance-label`}>
              <span style={{ position: 'relative' }}>
                {'Fwd P/E Ratio'}
                <InfoIcon word={'tooltip_forward_pe_ratio_next_year'} style={{ top: '1px', lineHeight: 1.4 }} />
              </span>
            </span>
            <span className={`security-table-tl-idea-performance-value`}>
              <FundamentalData hideIfNA={false} securityId={securityId} property={'Forward P/E'} />
            </span>
          </span>
          <span className={`security-table-idea-performance-time-period`}>
            <span className={`security-table-tl-idea-performance-label`}>
              <span style={{ position: 'relative' }}>{'Owned As Of'}</span>
            </span>
            <span className={`security-table-tl-idea-performance-value`}>{formattedDate}</span>
          </span>
        </span>
      </span>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    ideas: state.ideas,
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(ThoughtLeaderIdeaDataCell);

export default composedComponent;
