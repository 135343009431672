import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '../../components/buttons/IconButton';
import { formatForDisplay } from '@src/helpers/displayHelpers';
import styled from 'styled-components';
import { logMetricsTrackingEvent, postSeenMarginCall } from '../../actions/index';
import { useCurrentUser } from '@src/main/hooks/user';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.themeColors.error};
`;

const MarginCallBanner = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const [isDismissed, setIsDismissed] = React.useState(false);

  const _handleDismiss = () => {
    postSeenMarginCall()(dispatch);
    setIsDismissed(true);
    logMetricsTrackingEvent('Clicked Dismiss Margin Call Banner', {})();
  };

  const hasMarginCall = currentUser.should_see_margin_call_notice;
  const marginCallAmount = currentUser.margin_call_amount;

  const marginCallAmountDisplay = formatForDisplay(marginCallAmount, 'price');

  if (!hasMarginCall) {
    return null;
  }
  if (isDismissed) {
    return null;
  }

  const message = `You currently have a ${marginCallAmountDisplay} margin call on your account. Please deposit cash, or liquidate some of your positions to create excess margin.`;
  return (
    <Wrapper className={`margin-call-banner-container fade-in-drop`}>
      <div className={`margin-call-banner-message`}>{message}</div>
      <IconButton
        icon="fa fa-times fa-times-thin"
        color="#fff"
        size="large"
        background="rgba(0,0,0,0)"
        handleClick={_handleDismiss}
      />
    </Wrapper>
  );
};

export default MarginCallBanner;
