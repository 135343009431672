import React from 'react';
import { connect } from 'react-redux';

import { getKarmaAnimationTestGroupFromState, getKarmaTotalFromState } from '../../selectors/karma';

class KarmaEarnedBackgroundFlash extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      animationStack: [],
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { karmaTotal: prevKarmaTotal } = prevProps;
    const { karmaTotal } = this.props;

    if (prevKarmaTotal !== karmaTotal && prevKarmaTotal !== null && !isNaN(prevKarmaTotal) && !isNaN(karmaTotal))
      this.handleKarmaTotalChange(prevKarmaTotal, karmaTotal);
  }

  animate = () => {
    this.setState((prevState) => ({
      animationStack: [...prevState.animationStack, prevState.animationStack.length + 1],
    }));
  };

  render() {
    return this.state.animationStack.map((a, i) => (
      <div
        key={`karma-earned-bg-flash-${i}`}
        style={{}}
        className={'karma-earned-bg karma-earned-bg-flash-animation success-highlight-bg-color'}
      />
    ));
  }

  handleKarmaTotalChange = (prev, current) => {
    const { inTestGroup } = this.props;

    if (!inTestGroup) return;

    if (current < prev) return;

    this.animate();
  };
}

const mapStateToProps = (state) => {
  return {
    inTestGroup: getKarmaAnimationTestGroupFromState(state) === 1,
    karmaTotal: getKarmaTotalFromState(state),
  };
};

export default connect(mapStateToProps)(KarmaEarnedBackgroundFlash);
