import React from 'react';
import { useSelector } from 'react-redux';

export const usePosition = (securityId) => {
  const positions = useSelector((state) => state.portfolio.positions);
  const [position, setPosition] = React.useState(positions[securityId] || {});

  React.useEffect(() => {
    const storePosition = positions[securityId];
    if (storePosition !== position && !!storePosition) {
      setPosition(storePosition)
    }
  }, [positions]);

  return position;
};
