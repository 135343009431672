import React, { Component } from 'react';

import Icon from '../../../components/misc/Icon';
import styled from 'styled-components';
import { Body5 } from '../../../main/lib/nvstr-common-ui.es';
import { Container } from '@src/main/components/ui';
import { SkeletonButton } from '@src/main/components/buttons';

const AnalysisSummaryWrapper = styled.div`
  .optimizer-good-value-color {
    color: ${({ theme }) => theme.themeColors.positive};
  }
  .optimizer-medium-value-color {
    color: ${({ theme }) => theme.themeColors.warning};
  }
  .optimizer-bad-value-color {
    color: ${({ theme }) => theme.themeColors.negative};
  }
`;

export const OptimizerAnalysisTypes = {
  AvgCorrel: 'avg_correl',
  HistVolatility: 'hist_volatility',
  ProjReturn: 'proj_return',
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  height: 80px;
`;

const IconWrapper = styled.div`
  svg {
    height: 18px;
    width: 18px;

    fill: ${({ theme, group }) =>
      group === 1
        ? theme.themeColors.negative
        : group === 2
        ? theme.themeColors.warning
        : group === 3
        ? theme.themeColors.positive
        : theme.themeColors.text};
  }
`;

const OptimizerAnalysisTypeIcon = ({ type, group }) => {
  if (type === OptimizerAnalysisTypes.AvgCorrel) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M11,2V22C5.9,21.5 2,17.2 2,12C2,6.8 5.9,2.5 11,2M13,2V11H22C21.5,6.2 17.8,2.5 13,2M13,13V22C17.7,21.5 21.5,17.8 22,13H13Z" />
      </svg>
    );
  }
  if (type === OptimizerAnalysisTypes.HistVolatility) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
      </svg>
    );
  }
  if (type === OptimizerAnalysisTypes.ProjReturn) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M16,11.78L20.24,4.45L21.97,5.45L16.74,14.5L10.23,10.75L5.46,19H22V21H2V3H4V17.54L9.5,8L16,11.78Z" />
      </svg>
    );
  }
};

const DescriptionsWrapper = styled.div`
  //background: ${({ theme, group }) => theme.themeColors.appBackground};
  width: 196px;
`;
const DescriptionWrapper = styled.div`
  background: transparent;

  border: ${({ theme, group }) =>
    `1px solid ${
      group === 1
        ? theme.themeColors.negative
        : group === 2
        ? theme.themeColors.warning
        : group === 3
        ? theme.themeColors.positive
        : theme.themeColors.text
    }`};
  border-radius: 5px;
  padding: 1px 8px;
  margin-bottom: 3px;

  ${IconWrapper} {
    padding-top: 2px;
    line-height: 1;

    * {
      line-height: 1;
    }
  }

  * {
    color: ${({ theme, group }) =>
      group === 1
        ? theme.themeColors.negative
        : group === 2
        ? theme.themeColors.warning
        : group === 3
        ? theme.themeColors.positive
        : theme.themeColors.text} !important;
    fill: ${({ theme, group }) =>
      group === 1
        ? theme.themeColors.negative
        : group === 2
        ? theme.themeColors.warning
        : group === 3
        ? theme.themeColors.positive
        : theme.themeColors.text} !important;
  }
`;
const HideDescriptions = styled.div`
  position: absolute;
  bottom: -25px;
  right: 0px;

  button {
    padding: 2px 6px;
  }
`;

export const OptimizerAnalysisSummary = ({ optimizerAnalysisSummaryList, securityId }) => {
  return (
    <Wrapper>
      <DescriptionsWrapper>
        {optimizerAnalysisSummaryList.map(({ type, group, summary }) => (
          <DescriptionWrapper key={`${summary}-${securityId}`} group={group}>
            <Container verticallyCenter spaceBetween>
              <Body5>{summary}</Body5>
              <IconWrapper group={group}>
                <OptimizerAnalysisTypeIcon type={type} group={group} />
              </IconWrapper>
            </Container>
          </DescriptionWrapper>
        ))}
      </DescriptionsWrapper>
    </Wrapper>
  );
};

export const OldOptimizerAnalysisSummary = ({ optimizerAnalysisSummaryList, securityId }) => {
  const [showDescriptions, setShowDescriptions] = React.useState(false);
  const [clickShowDescriptions, setClickShowDescriptions] = React.useState(false);

  const onClick = () => {
    setClickShowDescriptions(true);
  };
  const onDismiss = () => {
    setClickShowDescriptions(false);
  };
  const onEnter = () => {
    setShowDescriptions(true);
  };
  const onLeave = () => {
    setShowDescriptions(false);
  };

  return (
    <Wrapper onMouseEnter={onEnter} onMouseLeave={onLeave}>
      {(showDescriptions || clickShowDescriptions) && (
        <DescriptionsWrapper>
          {optimizerAnalysisSummaryList.map(({ type, group, summary }) => (
            <DescriptionWrapper key={`${summary}-${securityId}`} group={group}>
              <Container verticallyCenter spaceBetween>
                <Body5>{summary}</Body5>
                <IconWrapper group={group}>
                  <OptimizerAnalysisTypeIcon type={type} group={group} />
                </IconWrapper>
              </Container>
            </DescriptionWrapper>
          ))}
          {clickShowDescriptions && (
            <HideDescriptions>
              <SkeletonButton secondary onClick={onDismiss}>
                Hide
              </SkeletonButton>
            </HideDescriptions>
          )}
        </DescriptionsWrapper>
      )}
      {optimizerAnalysisSummaryList.map(({ type, group, summary }) => (
        <IconWrapper key={`${type}-${securityId}`} tabIndex="0" group={group} onClick={onClick}>
          <OptimizerAnalysisTypeIcon type={type} group={group} />
        </IconWrapper>
      ))}
    </Wrapper>
  );
};
