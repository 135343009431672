import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AskAnyone from '../buttons/AskAnyone';
import { userHasIdea, findUserIdeaForSecurity } from '../../helpers/ideaHelpers';
import { createQueryString, parseQueryString } from '../../helpers/routerHelpers';
import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import { TrackIteratively } from '../../utils/itly';
import { analyticsPageNames } from '../../utils/itly/analyticTypes';
import { ROUTES } from '../../constants/paths';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUserOptionsEnabled } from '../../main/hooks/orders/useUserOptionsEnabled';
import { FlatButton, SkeletonButton } from '../../main/components/buttons';
import styled from 'styled-components';
import { useSecurity } from '../../main/hooks/securities/useSecurity';
import { useCurrentUser, useCurrentUserId, useIsOnboarding } from '../../main/hooks/user';
import { useLocation } from 'react-router';
import { useSecurityPrice } from '../../main/hooks/securities/useSecurityPrice';
import { addIdeas, logMetricsTrackingEvent } from '@src/actions';
import { useExtendedSearchParams } from '@src/main/hooks/util/useExtendedSearchParams';
import { returnCurrentUserThemeTestGroups } from '@src/helpers/currentUserHelpers';
import { sendFacebookTrackingEvent } from '@src/constants/facebookTracking';
import { TrackingEvents } from '@src/utils/tracking/events';

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  max-width: 600px;
  margin: 0 auto;
  width: 100%;

  button {
    padding: 10px 16px;
  }

  #buy-button {
    border-color: ${({ theme }) => theme.themeColors.positive};
    color: ${({ theme }) => theme.themeColors.positive};

    * {
      color: ${({ theme }) => theme.themeColors.positive};
    }

    &:hover {
      border-color: ${({ theme }) => theme.themeColors.positive};
      background-color: ${({ theme }) => theme.themeColors.positive};
      color: ${({ theme }) => theme.themeColors.buttonText};

      * {
        color: ${({ theme }) => theme.themeColors.buttonText};
      }
    }
  }

  #sell-button {
    border-color: ${({ theme }) => theme.themeColors.negative};

    color: ${({ theme }) => theme.themeColors.negative};

    * {
      color: ${({ theme }) => theme.themeColors.negative};
    }

    &:hover {
      border-color: ${({ theme }) => theme.themeColors.negative};
      background-color: ${({ theme }) => theme.themeColors.negative};
      color: ${({ theme }) => theme.themeColors.buttonText};

      * {
        color: ${({ theme }) => theme.themeColors.buttonText};
      }
    }
  }

  .c,
  .d {
    button {
      width: 108px;
    }
  }

  @media (max-width: 700px) {
    display: grid;
    grid-gap: 8px;
    align-items: flex-start;
    justify-items: flex-start;
    width: 408px;
    margin: 0 auto;

    .a {
      grid-column: 1;
      grid-row: 1;
    }
    .b {
      grid-column: 2;
      grid-row: 1;
    }
    .c {
      grid-column: 1;
      grid-row: 2;
      justify-self: flex-end;
      text-align: right;
    }
    .d {
      grid-column: 2;
      grid-row: 2;
      text-align: left;
    }

    > div:first-child,
    > div:nth-child(2) {
      button {
        width: 200px;
      }
    }
  }

  @media (max-width: 540px) {
    display: grid;
    width: 100%;

    grid-gap: 8px;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;

    > div:first-child,
    > div:nth-child(2) {
      button {
        width: 225px;
      }
    }

    .a {
      width: 100%;
      grid-column: 1 / span 2;
      grid-row: 1;
      text-align: center;
    }
    .b {
      width: 100%;
      grid-column: 1 / span 2;
      grid-row: 2;
      text-align: center;
    }
    .c {
      grid-column: 1;
      grid-row: 3;
      text-align: right;
      button {
        width: 108px;
      }
    }
    .d {
      grid-column: 2;
      grid-row: 3;
      text-align: left;
      button {
        width: 108px;
      }
    }
  }
`;
const Wrapper = styled.div`
  padding: 0 16px 24px 16px;
`;
const GridWrapper = styled.div``;

const TradeOptionsButton = ({ securityId, buttonWidth }) => {
  const navigate = useNavigate();
  const hc = () => {
    navigate(ROUTES.TRADE_OPTIONS.build(securityId));
  };

  return <FlatButton onClick={hc}>Trade Options</FlatButton>;
};

const SecondaryAction = ({ securityId }) => {
  const { isEnabled } = useUserOptionsEnabled();

  return isEnabled ? (
    <TradeOptionsButton securityId={securityId} />
  ) : (
    <AskAnyone viewingContext={'Security Panel'} securityId={securityId} />
  );
};

const IdeaAction = ({ securityId, handleAddIdeas }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useExtendedSearchParams();

  const currentUserId = useCurrentUserId();
  const currentUser = useCurrentUser();
  const ideas = useSelector((state) => state.ideas);
  const { symbol } = useSecurity(securityId);

  const isCurrentUserIdeasLoading = ideas.loadingCurrentUserIdeas;
  const isUserCreatingIdeaForThisSecurity = ideas.userCreatingIdeas[securityId];
  const currentUserIdeaIdForSecurity = findUserIdeaForSecurity(currentUserId, securityId, ideas.ideaList)?.idea_id;

  const doesCurrentUserHaveIdeas = !isCurrentUserIdeasLoading && userHasIdea(currentUserId, securityId, ideas.ideaList);

  const onAddIdea = () => {
    logMetricsTrackingEvent('Clicked Add Idea', {
      'Stock Symbol': symbol,
      'Security ID': securityId,
      Context: 'Security Panel',
    })();

    const newIdeas = [{ security: { security_id: securityId } }];

    const nextQueryParams = {
      activeTab: 'ideas',
      ideaId: 'null',
    };
    setSearchParams(nextQueryParams);

    return addIdeas(newIdeas)(dispatch).then((response) => {
      const ideasWithErrors = response.ideas.filter((idea) => idea.status === 'error');
      if (ideasWithErrors.length === 0) {
        const params = {
          themeTestGroups: returnCurrentUserThemeTestGroups(currentUser),
          accountType: currentUser.is_live_trading ? 'Live' : 'Paper',
        };
        sendFacebookTrackingEvent('Added Idea', params);

        TrackingEvents.ideas.ADD_IDEA.send();
      }
    });
  };

  const onViewMyIdea = () => {
    const userIdea = findUserIdeaForSecurity(currentUserId, securityId, ideas.ideaList);

    logMetricsTrackingEvent('Clicked Edit Idea', {
      'Stock Symbol': symbol,
      'Security ID': securityId,
      Context: 'Security Panel',
    })();

    const properties = {
      idea_type: userIdea?.idea_type.name.toLowerCase(),
      ticker_symbol: userIdea?.security.symbol,
      context: analyticsPageNames[location.pathname] || location.pathname,
    };
    TrackIteratively.viewed.IDEA_VIEWED.send({
      ...properties,
      url: window.location.pathname,
      url_query: window.location.search,
    });

    const nextQueryParams = {
      activeTab: 'ideas',
      ideaId: userIdea.id,
    };
    setSearchParams(nextQueryParams);
  };

  const isCurrentUserIdeaBeingDisplayed = () => {
    const currentLocation = location;
    const query = parseQueryString(currentLocation.search);
    const currentUserIdea = findUserIdeaForSecurity(currentUserId, securityId, ideas.ideaList);
    const isCurrentUserIdeaInQueryString =
      query.activeTab === 'ideas' &&
      query.ideaId &&
      currentUserIdea &&
      query.ideaId.toString() === currentUserIdea.idea_id.toString();
    const isCurrentUserIdeaDisplayedAsDefaultIdea =
      query.activeTab === 'ideas' && (isUndefinedOrNull(query.ideaId) || query.ideaId === 'null');

    return doesCurrentUserHaveIdeas && (isCurrentUserIdeaInQueryString || isCurrentUserIdeaDisplayedAsDefaultIdea);
  };

  if (isCurrentUserIdeaBeingDisplayed()) {
    return (
      <FlatButton disabled onClick={() => false}>
        Viewing Idea
      </FlatButton>
    );
  }

  if (isCurrentUserIdeasLoading || isUserCreatingIdeaForThisSecurity) {
    return (
      <FlatButton disabled onClick={() => false}>
        Loading
      </FlatButton>
    );
  }
  return (
    <FlatButton onClick={currentUserIdeaIdForSecurity ? onViewMyIdea : onAddIdea}>
      {currentUserIdeaIdForSecurity ? 'View My Idea' : `Save ${symbol} to My Ideas`}
    </FlatButton>
  );
};

const NewSecurityCardActionButtons = ({ securityId, handleAddIdeas, handleBuyClick, handleSellClick }) => {
  const isOnboarding = useIsOnboarding();
  const { featured: isFeatured, symbol } = useSecurity(securityId);
  const { is_halted } = useSecurityPrice(securityId);
  const showTradeButtons = !isOnboarding && isFeatured && !is_halted;

  return (
    <Wrapper>
      <ActionsWrapper>
        <GridWrapper className={'a'}>
          <IdeaAction securityId={securityId} handleAddIdeas={handleAddIdeas} />
        </GridWrapper>
        <GridWrapper className={'b'}>
          <SecondaryAction securityId={securityId} />
        </GridWrapper>
        {showTradeButtons && (
          <GridWrapper className={'c'}>
            <SkeletonButton buttonProps={{ id: 'buy-button' }} onClick={handleBuyClick}>
              Buy
            </SkeletonButton>
          </GridWrapper>
        )}
        {showTradeButtons && (
          <GridWrapper className={'d'}>
            <SkeletonButton buttonProps={{ id: 'sell-button' }} onClick={handleSellClick}>
              Sell
            </SkeletonButton>
          </GridWrapper>
        )}
      </ActionsWrapper>

      {is_halted && (
        <div className="security-action-btn-group security-card-halted-msg">
          Trading in this security is currently halted by the exchange.
        </div>
      )}
    </Wrapper>
  );
};

export default NewSecurityCardActionButtons;
