import React from 'react';
import { connect } from 'react-redux';

import LoadingIcon from '../../../../components/misc/LoadingIcon';

import { isLoadingFundamentalData } from '../../../../helpers/securitiesHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';

class CompTableRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.columns = this._buildColumns();
  }

  render() {
    const securityId = this.props.securityId;
    if (this.isLoading()) {
      return (
        <tr id={`security-row-${securityId}`} className="border-accent">
          <td className={`security-table-loading-row`} colSpan={2}>
            <LoadingIcon icon="fading-3balls" size="small" style={{ marginRight: '8px' }} />
          </td>
        </tr>
      );
    }

    return (
      <tr
        id={`security-row-${securityId}`}
        style={{
          cursor: this._isNotCurrentSecurityShowingInPanel() ? 'pointer' : 'default',
        }}
        onClick={this._handleClick}
      >
        {this.columns.map((column, i) => this.renderColumn(column, i))}
      </tr>
    );
  }

  _handleClick = () => {
    if (this._isNotCurrentSecurityShowingInPanel()) {
      this.props.navigate(this._returnLinkForHandlingClick());
    }
  };

  _isNotCurrentSecurityShowingInPanel = () => !this.props.isSecurityInDetail;
  _returnSecurityId = () => this.props.securityId;

  _returnLinkForHandlingClick = () => {
    const additionalProps = {
      activeTab: 'thoughts',
    };
    const openSecurityPanelConfig = {
      securityId: this._returnSecurityId(),
      additionalProps,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  renderColumn = (column, i) => {
    // sample column ->
    // {
    //   name: 'forward_price_to_sales_next_yr',
    //   displayName: `Forward P/S (${ ( new Date()).getFullYear() + 1 })`,
    //   dataKey: 'Forward P/S Next Yr',
    //   cell: FormattedDataCell,
    // },
    const { securityId, handleRemoveSecurity, isSecurityInDetail } = this.props;
    const property = column.dataKey;
    const name = column.name;
    const defaultProps = {
      name,
      property,
      securityId,
      isSecurityInDetail,
      onRemove: handleRemoveSecurity,
    };
    const props = {
      ...defaultProps,
      key: `security-${securityId}-col-${column.name}`,
    };
    const children = {};
    return React.createElement(column.cell, props, children);
  };

  isLoading = () => {
    const { securityId, fundamentalData } = this.props;
    return isLoadingFundamentalData(securityId, fundamentalData);
  };

  _buildColumns = () => {
    const groupData = this.props.columnGroups;
    const columnsInOrder = [];
    groupData.forEach((group) => {
      group.columns.forEach((column) => {
        columnsInOrder.push(column);
      });
    });
    return columnsInOrder;
  };
}

const mapStateToProps = (state) => {
  return {
    securities: state.securities,
    fundamentalData: state.fundamentalData,
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps)(CompTableRow);
