import React from 'react';
import styled from 'styled-components';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '../../appConfig';

import { createDropdownItem, FloatingLabelDropdown } from '../../main/lib/nvstr-common-ui.es';
import { FlatButton } from '../../main/components/buttons';
import { updateUserProperty } from '../../actions';
import { useDispatch } from 'react-redux';

const OptionsExperienceContent = styled.div``;

const InputContainer = styled.div`
  select {
    border-top: none;
    border-left: none;
    border-right: none;
    -webkit-appearance: auto;

    margin: 0 auto;
    max-width: 300px;
    background: transparent !important;
  }
`;

const Heading = styled.div`
  padding-top: 24px;
  padding-bottom: 40px;
`;

const ContinueWrapper = styled.div`
  padding-top: 40px;
  text-align: center;

  button {
    width: 225px;
  }
`;

const experienceToAPIItems = {
  'A lot of options experience': { id: 3, description: 'A lot of options experience' },
  'Some options experience': { id: 2, description: 'Some options experience' },
  'No options experience': { id: 0, description: 'No options experience' },
  "I'm not interested in trading options": { id: 4, description: "I'm not interested in trading options" },
};

const experienceLevelItems = [
  createDropdownItem({ text: 'A lot of options experience', value: 'A lot of options experience' }),
  createDropdownItem({ text: 'Some options experience', value: 'Some options experience' }),
  createDropdownItem({ text: 'No options experience', value: 'No options experience' }),
  createDropdownItem({ text: '--', value: '--', disabled: true }),
  createDropdownItem({ text: "I'm not interested in trading options", value: "I'm not interested in trading options" }),
];

export const OptionsExperienceEntry = ({ initialValue, onContinue }) => {
  const dispatch = useDispatch();
  const [optionsExperience, setOptionsExperience] = React.useState(initialValue || null);
  const [optionsExperienceError, setOptionsExperienceError] = React.useState(null);

  React.useEffect(() => {
    if (initialValue) {
      setOptionsExperience(initialValue.description);
    }
  }, []);

  const onExperienceChange = (name) => (value) => {
    setOptionsExperience(value);
    setOptionsExperienceError(null);
  };

  const handleContinue = () => {
    if (!optionsExperience || optionsExperience === '--') {
      setOptionsExperienceError('Required');
      return;
    }

    updateUserProperty({
      options_trading_experience: experienceToAPIItems[optionsExperience],
    })(dispatch);

    onContinue(optionsExperience);
  };

  return (
    <OptionsExperienceContent>
      <Heading>
        <div className="full-page-card-heading">
          <span className={'text-wrap-wrapper'}>How much options trading experience</span>
          <span className={'text-wrap-wrapper'}>do you have?</span>
        </div>

        <div className="gradient-border-container">
          <div className="gradient-border" />
        </div>
      </Heading>

      <InputContainer>
        <FloatingLabelDropdown
          name={'options_experience'}
          label={null}
          value={optionsExperience}
          onChange={onExperienceChange}
          items={experienceLevelItems}
          error={optionsExperienceError}
        />
      </InputContainer>

      <div className="full-page-card-content">
        {`${PRODUCT_DISPLAY_NAME} makes sophisticated investing accessible to everyone from first time investors to seasoned experts.`}
      </div>

      <ContinueWrapper>
        <FlatButton onClick={handleContinue}>Continue</FlatButton>
      </ContinueWrapper>
    </OptionsExperienceContent>
  );
};
