import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import SecurityContainer from './SecurityContainer';

import LoadingIcon from '../../components/misc/LoadingIcon';
import IconButton from '../../components/buttons/IconButton';

import {
  handleSelectionClick,
  handleToggleClick,
  handleChange,
  handleSubmit,
  validateFormBeforeSubmit,
  createInputComponent,
  getRequiredFields,
  isDefaultDropdownSelection,
} from '../../helpers/formHelpers';
import styled from 'styled-components';

const SlideCoverWrapper = styled.div`
  background-color: ${({ theme }) => theme.themeColors.appBackground};
`;

class SecuritiesList extends Component {
  prevFilterList = () => {
    this.props.changeFilterResultDisplayRow('Previous');
  };

  nextFilterList = () => {
    this.props.changeFilterResultDisplayRow('Next');
  };

  determineCardPosition = (i, row) => {
    const rowWidth = this.props.width;
    const cardWidthTotalSpace = this.props.cardWidth + this.props.paddingBetweenCards;
    const cardsCanFitInRow = this.props.cardsInARow();
    const marginOffset = rowWidth >= 850 ? 0 : 15;

    const startingOffset =
      (rowWidth - cardsCanFitInRow * cardWidthTotalSpace) / 2 - marginOffset + this.props.paddingBetweenCards / 2;

    const cardRowIndexPositions = [startingOffset];

    const addCardPositions = (array) => {
      for (let i = 1; i < cardsCanFitInRow; i++) {
        array.push(cardWidthTotalSpace * i + startingOffset);
      }
    };
    addCardPositions(cardRowIndexPositions);

    const cardRowIndex = i % cardsCanFitInRow;
    const cardRow = Math.floor(i / cardsCanFitInRow);
    const rowOffset = cardRow - row;
    const top = '15px';
    const left = `${cardRowIndexPositions[cardRowIndex] + rowOffset * rowWidth}px`;

    return { top, left };
  };

  render() {
    const securities = this.props.securities;
    return (
      <div className="clearfix">
        <div
          className={`portfolio-explorer-securities-list-container`}
          style={{ height: '330px' }}
          ref={(el) => {
            this.securityListContainer = el;
          }}
        >
          <div className="securities-filter-card-list-arrow-container securities-filter-card-list-left-arrow-container">
            {this.props.cardRowDisplaying !== 0 && (
              <IconButton
                customClass="circle-fill-button horizontal-list-change-page-btn"
                style={{ padding: '3px 5px 0 0' }}
                skinny={true}
                showShadow={true}
                icon="fa-chevron-left"
                handleClick={this.prevFilterList}
              />
            )}
          </div>
          <SlideCoverWrapper className="left-security-card-slide-cover "></SlideCoverWrapper>
          {securities.map((securityId, i) => (
            <SecurityContainer
              key={`portfolio-explorer-securities-list-${securityId}`}
              width={this.props.cardWidth}
              filters={this.props.filters}
              ideas={this.props.ideas.ideaList}
              currentUserId={this.props.currentUser.user_id}
              currentUserHasEquity={this.props.currentUser.equity > 0}
              securityId={securityId}
              securitiesData={this.props.securitiesData.lookup}
              handleSwipe={this.props.handleSwipe}
              securityIndex={i}
              position={this.determineCardPosition(i, this.props.cardRowDisplaying)}
              expandSecurityCard={this.props.expandSecurityCard}
              navigate={this.props.navigate}
              actions={this.props.actions}
            />
          ))}
          <div className="securities-filter-card-list-arrow-container securities-filter-card-list-right-arrow-container">
            {this.props.cardRowDisplaying < Math.ceil(securities.length / this.props.cardsInARow()) - 1 && (
              <IconButton
                customClass="circle-fill-button horizontal-list-change-page-btn"
                style={{ padding: '3px 0 0 4px' }}
                skinny={true}
                showShadow={true}
                icon="fa-chevron-right"
                handleClick={this.nextFilterList}
              />
            )}
          </div>
          <SlideCoverWrapper className="right-security-card-slide-cover"></SlideCoverWrapper>
          <div className="filter-message-container">
            {securities.length === 0 &&
              this.props.filters.length === 0 &&
              !this.props.loadingFilters &&
              !this.props.userSearching && (
                <div className="no-security-results component-bg-faded secondary-text-color">
                  {'Your filter results will show here. Choose what to filter by from the dropdown.'}
                </div>
              )}
            {securities.length === 0 &&
              this.props.filters.length > 0 &&
              !this.props.loadingFilters &&
              !this.props.userSearching && (
                <div className="no-security-results component-bg-faded secondary-text-color">
                  {'No securities were found within the filter parameters.'}
                </div>
              )}
            {securities.length === 0 && this.props.loadingFilters && (
              <div className="no-security-results component-bg-faded secondary-text-color">
                <LoadingIcon size="medium" style={{ marginRight: '15px' }} />
                {'Filtering securities...'}
              </div>
            )}
          </div>
        </div>
        <div className="portfolio-explorer-disclaimer secondary-text-color">
          {
            'Securities shown are widely held and broadly fit the investment criteria selected. Matches are sorted by default sort criteria and are not investment recommendations. Press the arrow buttons to see more results.'
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    securitiesData: state.securities,
    currentUser: state.currentUser,
    ideas: state.ideas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecuritiesList);
