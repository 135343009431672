import React from 'react';
import { useSelector } from 'react-redux';
import { isUndefinedOrNull } from '@src/helpers/generalHelpers';

/*
  average_fill_price: null
  can_be_canceled: true
  cash_amount: 24
  commission: 0
  display_sec_rule: false
  executed_at: null
  execution_status: "pending"
  filled_shares: 0
  fractional_order: true
  id: 7402
  is_from_optimizer: false
  is_placed: true
  is_subdivision: false
  limit_price: 82
  operation: "buy"
  order_id: 7402
  security_id: 5399
  shares: 0.30896
  stop_price: null
  type: "limit"
  user_id: 1120
 */

export const useOrder = (id) => {
  const storeOrder = useSelector((state) => state.u_orders.ordersLookup[id]);
  const [order, setOrder] = React.useState(storeOrder);

  React.useEffect(
    () => {
      if (order !== storeOrder && !isUndefinedOrNull(storeOrder)) {
        setOrder(storeOrder);
      }
    },
    [storeOrder, order]
  );

  return order;
};

export const useNotPlacedOrder = (id) => {
  const storeOrder = useSelector((state) => state.u_orders.ordersLookup[id]);
  const [order, setOrder] = React.useState(storeOrder);

  React.useEffect(
    () => {
      if (order !== storeOrder && !isUndefinedOrNull(storeOrder)) {
        setOrder(storeOrder);
      }
    },
    [storeOrder, order]
  );

  return order;
};
