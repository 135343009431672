import React from 'react';

export const TextNumberField = (props) => {
  const name = props.name;
  const label = props.label;
  const value = props.getValue(name) || '';

  const customStyle = props.style || {};

  const isError = !!props.getErrorMessage(name);
  const errorMessage = isError ? props.getErrorMessage(name) : '';

  const isFocused = props.isFocused(name);
  const focusedOrValue = (!!value && value.length > 0) || isFocused;

  const showFocusNote = !!props.focusNote && isFocused;
  const focusNote = props.getFocusNote(name);

  return (
    <div className="floating-label-text-field">
      <fieldset className={`text-field ${props.size === 'small' ? 'field-small' : ''} `} style={customStyle}>
        {showFocusNote && <div className="focus-note eye-catching-bg">{focusNote}</div>}

        <input
          type="text"
          name={name}
          onFocus={() => {
            props.handleFocus(name);
          }}
          onBlur={() => {
            props.handleBlur(value, name);
          }}
          onChange={(e) => {
            props.handleChange(e, name);
          }}
          value={value}
          step="0.01"
          dir={props.dir || 'ltr'}
        />

        <label htmlFor={label} className={focusedOrValue ? 'text-field-lifted-label' : ''}>
          {label}
        </label>

        <div className={`after ${isError && !isFocused ? 'error-underline' : ''}`}></div>

        <p className={`text-field-error-message`}>{errorMessage}</p>
      </fieldset>
    </div>
  );
};

export default TextNumberField;
