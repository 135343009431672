import * as globalsActions from './globalsActions';

import * as actionMessageActions from './actionMessageActions';
import * as adminActions from './adminActions';
import * as applicationActions from './applicationActions';
import * as appStorageActions from './appStorageActions';
import * as askActions from './askActions';
import * as authenticationActions from './authenticationActions';
import * as bannerActions from './bannerActions';
import * as collectivesActions from './collectivesActions';
import * as currentUserActions from './currentUserActions';
import * as errorReportingActions from './errorReportingActions';
import * as documentsActions from './documentsActions';
import * as footerActions from './footerActions';
import * as ideasActions from './ideasActions';
import * as karmaActions from './karmaActions';
import * as horizontalListActions from './horizontalListActions';
import * as historyActions from './historyActions';
import * as ideaExplorerActions from './ideaExplorerActions';
import * as imagesActions from './imagesActions';
import * as importConnectionsActions from './importConnectionsActions';
import * as infoIconActions from './infoIconActions';
import * as investmentObjectivesActions from './investmentObjectivesActions';
import * as learnActions from './learnActions';
import * as liveAccountActions from './liveAccountActions';
import * as modalActions from './modalActions';
import * as navActions from './navActions';
import * as newsActions from './newsActions';
import * as notificationsActions from './notificationsActions';
import * as onboardingActions from './onboardingActions';
import * as optimizerActions from './optimizerActions';
import * as rebalancerActions from './rebalancerActions';
import * as ordersActions from './ordersActions';
import * as paymentActions from './paymentActions';
import * as plaidActions from './plaidActions';
import * as plaidInvestmentActions from './plaidInvestmentActions';
import * as portfolioActions from './portfolioActions';
import * as portfolioExplorerActions from './portfolioExplorerActions';
import * as rewardsActions from './rewardsActions';
import * as scrollActions from './scrollActions';
import * as searchActions from './searchActions';
import * as securitiesActions from './securitiesActions';
import * as securityPriceStreamActions from './securityPriceStreamActions';
import * as thoughtsActions from './thoughtsActions';
import * as tourActions from './tourActions';
import * as trackingActions from './trackingActions';
import * as viewpointActions from './viewpointActions';
import * as userMutualConnectionsActions from './userMutualConnectionsActions';
import * as usersActions from './usersActions';
import { getIsUsingRebalancerForPortfolioOptimization } from '../helpers/portfolioOptimizationHelpers';

import * as applicationV1Actions from './v1/application';
import * as ordersV1Actions from './v1/orders';

// V1 Actions

export const sendSupportMessage = (message) => applicationV1Actions.sendSupportMessage(message);

export const getOrdersV1 = () => ordersV1Actions.getOrdersV1();
export const createAndPlaceOrderV1 = (order, acceptedWarnings) =>
  ordersV1Actions.createAndPlaceOrderV1(order, acceptedWarnings);
export const placeOrdersV1 = (orderIds, acceptedWarnings) => ordersV1Actions.placeOrdersV1(orderIds, acceptedWarnings);
export const deleteOrderV1 = (id) => ordersV1Actions.deleteOrderV1(id);
export const createOrderV1 = (order) => ordersV1Actions.createOrderV1(order);
export const updateOrderInCartV1 = (orderId, order) => ordersV1Actions.updateOrderInCartV1(orderId, order);
export const deleteAllOrdersV1 = () => ordersV1Actions.deleteAllOrdersV1();

// LEGACY ACTIONS

// GLOBALS ACTIONS
export const setAppHeight = (value) => globalsActions.setAppHeight(value);

// ADMIN ACTIONS
export const postLessonToApi = (lessonData) => adminActions.postLessonToApi(lessonData);
export const publishLesson = (lesson_id) => adminActions.publishLesson(lesson_id);

// ACTION MESSAGE ACTIONS
export const showActionMessage = (messageData) => actionMessageActions.showActionMessage(messageData);

export const dismissActionMessage = (messageData) => actionMessageActions.dismissActionMessage(messageData);

// APPLICATION ACTIONS
export const showComponentModal = (s) => applicationActions.showComponentModal(s);
export const hideComponentModal = () => applicationActions.hideComponentModal();

export const setMarketState = (s) => applicationActions.setMarketState(s);
export const addMarketMonitorSecurities = (s) => applicationActions.addMarketMonitorSecurities(s);

export const fetchDataTypes = (field) => applicationActions.fetchDataTypes(field);
export const fetchAboveFeedBannerData = (bannerType) => applicationActions.fetchAboveFeedBannerData(bannerType);
export const getIsMarketOpen = () => applicationActions.getIsMarketOpen();
export const fetchEmailInviteCopy = () => applicationActions.fetchEmailInviteCopy();

export const getStaticCopy = () => applicationActions.getStaticCopy();

export const registerAttributionIdentifiers = (attributionIdentifiers) =>
  applicationActions.registerAttributionIdentifiers(attributionIdentifiers);

export const setSessionRiskId = (value) => applicationActions.setSessionRiskId(value);

// ASK ACTIONS
export const checkAskPermission = (askData) => askActions.checkAskPermission(askData);

export const sendAsk = (askData) => askActions.sendAsk(askData);

// AUTHENTICATION ACTIONS
export const logoutUser = () => authenticationActions.logoutUser();

// BANNER ACTIONS
export const fetchBanner = (currentUser, currentRoute) => bannerActions.fetchBanner(currentUser, currentRoute);

export const showBanner = (banner) => bannerActions.showBanner(banner);

export const dismissBanner = () => bannerActions.dismissBanner();

// COLLECTIVES ACTIONS
export const fetchCollectives = () => collectivesActions.fetchCollectives();

export const fetchCollective = (userId) => collectivesActions.fetchCollective(userId);

// CURRENT USER ACTIONS
export const fetchCurrentUserQuickInfo = (token) => currentUserActions.fetchCurrentUserQuickInfo(token);

export const refreshCurrentUserExternalPositionsInfo = (token) =>
  currentUserActions.refreshCurrentUserExternalPositionsInfo(token);

export const fetchOptionsPositions = () => currentUserActions.fetchOptionsPositions();

export const createOptionsPositions = (params) => currentUserActions.createOptionsPositions(params);

export const convertCurrentUserToLiveTrading = () => currentUserActions.convertCurrentUserToLiveTrading();

export const updateUserProperty = (changedUserFormData) => currentUserActions.updateUserProperty(changedUserFormData);

export const refreshCurrentUserPositionsInfo = () => currentUserActions.refreshCurrentUserPositionsInfo();

export const fetchEditUserProfilePage = () => currentUserActions.fetchEditUserProfilePage();

export const removeEditUserProfilePage = () => currentUserActions.removeEditUserProfilePage();

export const postSeenMarginCall = () => currentUserActions.postSeenMarginCall();

export const changeColorScheme = (desiredColorScheme) => currentUserActions.changeColorScheme(desiredColorScheme);

export const setCurrentUserSubscriber = () => currentUserActions.setCurrentUserSubscriber();

export const postAvatarUploadImage = (formData) => currentUserActions.postAvatarUploadImage(formData);

export const deleteAvatarUploadImage = () => currentUserActions.deleteAvatarUploadImage();

export const changePassword = (currentPassword, newPassword) =>
  currentUserActions.changePassword(currentPassword, newPassword);

export const changeEmail = (currentPassword, newEmail) => currentUserActions.changeEmail(currentPassword, newEmail);

export const postAgreedToBDTerms = () => currentUserActions.postAgreedToBDTerms();

export const closeAccount = () => currentUserActions.closeAccount();

export const startMembership = () => currentUserActions.startMembership();

export const resendRequiredDocsEmail = () => currentUserActions.resendRequiredDocsEmail();

// DOCUMENTS ACTIONS
export const getDocuments = (formData) => documentsActions.getDocuments(formData);

// ERROR REPORTING ACTIONS
export const logErrorEvent = (errorObj, properties, config) =>
  errorReportingActions.logErrorEvent(errorObj, properties, config);

// FOOTER ACTIONS
export const getFooterPartial = () => footerActions.getFooterPartial();

// HORIZONTAL LIST ACTIONS
export const incrementHorizontalListPage = () => horizontalListActions.incrementHorizontalListPage();

export const decrementHorizontalListPage = () => horizontalListActions.decrementHorizontalListPage();

export const resetHorizontalListPage = () => horizontalListActions.resetHorizontalListPage();

// HISTORY ACTIONS
export const fetchHistoryTransactionData = () => historyActions.fetchHistoryTransactionData();

export const fetchHistoryEquityData = () => historyActions.fetchHistoryEquityData();

export const fetchHistoryPortfolioMetricsData = (formData) => historyActions.fetchHistoryPortfolioMetricsData(formData);

export const cancelFundingTransaction = (id) => historyActions.cancelFundingTransaction(id);

// IDEA ACTIONS
export const getIdeasForSecurity = (securityIds) => ideasActions.getIdeasForSecurity(securityIds);

export const getIdeasForUsers = (userIds) => ideasActions.getIdeasForUsers(userIds);

export const getIdeas = (ideaIds) => ideasActions.getIdeas(ideaIds);

export const getCurrentUserIdeas = () => ideasActions.getCurrentUserIdeas();

export const fetchIdeas = (userId) => ideasActions.fetchIdeas(userId);

export const fetchCommunityIdeas = () => ideasActions.fetchCommunityIdeas();

export const addIdea = (idea) => ideasActions.addIdea(idea);
export const addIdeas = (ideas) => ideasActions.addIdeas(ideas);

export const copyIdea = (sourceIdea, userHasIdea) => ideasActions.copyIdea(sourceIdea, userHasIdea);

export const updateIdea = (idea, ideaToRollbackTo) => ideasActions.updateIdea(idea, ideaToRollbackTo);

export const removeIdea = (idea) => ideasActions.removeIdea(idea);

export const fetchSecurityIdFromIdeaId = (ideaId) => ideasActions.fetchSecurityIdFromIdeaId(ideaId);

export const viewIdea = (ideaId) => ideasActions.viewIdea(ideaId);

export const deactivateIdea = (idea) => ideasActions.deactivateIdea(idea);

export const reaffirmIdea = (idea) => ideasActions.reaffirmIdea(idea);

export const fillCurrentUserWithETFIdeas = () => ideasActions.fillCurrentUserWithETFIdeas();

// IDEA EXPLORER ACTIONS
export const importIdeas = (suggestionRequestId) => ideaExplorerActions.importIdeas(suggestionRequestId);

export const initializeIdeas = (count) => ideaExplorerActions.initializeIdeas(count);

export const resetIdeas = () => ideaExplorerActions.resetIdeas();

export const refreshWell = () => ideaExplorerActions.refreshWell();

export const updateSuggestions = (suggestionRequestId, valuedIdeas, ideaWell) =>
  ideaExplorerActions.updateSuggestions(suggestionRequestId, valuedIdeas, ideaWell);

export const makeSuggestion = (count) => ideaExplorerActions.makeSuggestion(count);

export const dropIdea = (idea, pile) => ideaExplorerActions.dropIdea(idea, pile);

export const dismissIdea = (idea) => ideaExplorerActions.dismissIdea(idea);

export const completeExploration = (ideas, ajaxCompletionFunc) =>
  ideaExplorerActions.completeExploration(ideas, ajaxCompletionFunc);

// IMAGE ACTIONS
export const fetchImageFromURL = (url) => imagesActions.fetchImageFromURL(url);

// IMPORT CONNECTIONS ACTIONS
export const getImportConnectionsPage = () => importConnectionsActions.getImportConnectionsPage();

export const removeImportConnectionsPage = () => importConnectionsActions.removeImportConnectionsPage();

export const getImportConnectionsNetworks = () => importConnectionsActions.getImportConnectionsNetworks();

export const getImportContacts = () => importConnectionsActions.getImportContacts();

// INFOICON ACTIONS
export const showInfoIconModal = (data) => infoIconActions.showInfoIconModal(data);

export const hideInfoIconModal = () => infoIconActions.hideInfoIconModal();

// INVESTMENT OBJ ACTIONS
export const getRiskProfiles = () => investmentObjectivesActions.getRiskProfiles();

export const getInvestmentObjectiveSettings = () => investmentObjectivesActions.getInvestmentObjectiveSettings();

export const saveInvestmentObjectiveSettings = (settings) =>
  investmentObjectivesActions.saveInvestmentObjectiveSettings(settings);

// APP STORAGE ACTIONS
export const saveToAppStorage = (data) => appStorageActions.saveToAppStorage(data);

export const removeKeyAppStorage = (key) => appStorageActions.removeKeyAppStorage(key);

// KARMA ACTIONS
export const addKarmaPoints = (value) => karmaActions.addKarmaPoints(value); // dev only

// LEARN ACTIONS
export const updateLessonPreview = (d) => learnActions.updateLessonPreview(d);
export const fetchCompletedLearnLessons = () => learnActions.fetchCompletedLearnLessons();
export const fetchAllLearnLessons = () => learnActions.fetchAllLearnLessons();
export const fetchNextLearnLesson = () => learnActions.fetchNextLearnLesson();
export const postLearnLessonProgress = (data) => learnActions.postLearnLessonProgress(data);
export const postRecurringInvestmentChartData = (data) => learnActions.postRecurringInvestmentChartData(data);

export const getLesson = (id) => learnActions.getLesson(id);

// LIVE ACCOUNT ACTIONS

export const createRecurringDeposit = (params, allowEmailAuth) =>
  liveAccountActions.createRecurringDeposit(params, allowEmailAuth);
export const updateRecurringDeposit = (params) => liveAccountActions.updateRecurringDeposit(params);
export const cancelRecurringDeposit = (accountId) => liveAccountActions.cancelRecurringDeposit(accountId);

export const createBankAccountNotice = (id, notice) => liveAccountActions.createBankAccountNotice(id, notice);

export const clearBankAccountNotice = (id) => liveAccountActions.clearBankAccountNotice(id);

export const removeFundingAccountNotice = () => liveAccountActions.removeFundingAccountNotice();

export const getDepositPickerAmounts = () => liveAccountActions.getDepositPickerAmounts();

export const getLiveTradingAccount = () => liveAccountActions.getLiveTradingAccount();

export const submitLiveTradingSignUpForm = (form, formPageName) =>
  liveAccountActions.submitLiveTradingSignUpForm(form, formPageName);

export const updateLiveTradingAccount = (form) => liveAccountActions.updateLiveTradingAccount(form);

export const getBankAccounts = (config) => liveAccountActions.getBankAccounts(config);

export const getUserAvailableFunds = (config) => liveAccountActions.getUserAvailableFunds(config);

export const removeBankAccount = (id) => liveAccountActions.removeBankAccount(id);

export const submitAddBankAccountForm = (form) => liveAccountActions.submitAddBankAccountForm(form);

export const linkAccountWithPlaid = (params) => liveAccountActions.linkAccountWithPlaid(params);

export const patchInstantLinkBankAccount = (params) => liveAccountActions.patchInstantLinkBankAccount(params);

export const startLinkingAccountWithPlaid = () => liveAccountActions.startLinkingAccountWithPlaid();

export const cancelLinkingAccountWithPlaid = () => liveAccountActions.cancelLinkingAccountWithPlaid();

export const verifyBankAccount = (form) => liveAccountActions.verifyBankAccount(form);

export const postPaperTransfer = (values) => liveAccountActions.postPaperTransfer(values);
export const depositFunds = (values, allowEmailAuth) => liveAccountActions.depositFunds(values, allowEmailAuth);
export const withdrawFunds = (values, acceptWarnings) => liveAccountActions.withdrawFunds(values, acceptWarnings);

//Deprecated
export const sendBankAccountTransaction = (id, form, acceptWarnings) =>
  liveAccountActions.sendBankAccountTransaction(id, form, acceptWarnings);

export const getFundingRewardAmount = (amounts) => liveAccountActions.getFundingRewardAmount(amounts);

export const postFundingRewardAmount = (amounts) => liveAccountActions.postFundingRewardAmount(amounts);

export const getLiveAccountDocumentationRequirements = () =>
  liveAccountActions.getLiveAccountDocumentationRequirements();

export const submitLiveAccountDocument = (formData) => liveAccountActions.submitLiveAccountDocument(formData);

export const fetchFurtherDocumentationStatus = (ignore401) =>
  liveAccountActions.fetchFurtherDocumentationStatus(ignore401);

// MODAL ACTIONS
export const showModal = (modal) => modalActions.showModal(modal);

export const hideModal = () => modalActions.hideModal();

export const confirmModal = () => modalActions.confirmModal();

// NAV ACTIONS
export const forceUndockVerticalNav = () => navActions.forceUndockVerticalNav();

export const releaseForceUndockVerticalNav = () => navActions.releaseForceUndockVerticalNav();

export const toggleAlwaysOpenVerticalNav = () => navActions.toggleAlwaysOpenVerticalNav();

export const toggleVerticalNav = () => navActions.toggleVerticalNav();

export const showVerticalNav = () => navActions.showVerticalNav();

export const hideVerticalNav = () => navActions.hideVerticalNav();

export const showSearchHeader = () => navActions.showSearchHeader();

export const hideSearchHeader = () => navActions.hideSearchHeader();

// NEWS ACTIONS
export const fetchNews = (params) => newsActions.fetchNews(params);

export const fetchNewsImage = (url) => newsActions.fetchNewsImage(url);

export const saveNewsOgo = (url, ogo) => newsActions.saveNewsOgo(url, ogo);

export const clearNewsDataForSecurity = (securityId) => newsActions.clearNewsDataForSecurity(securityId);

export const sendNewsViewEvent = (trackingData) => newsActions.sendNewsViewEvent(trackingData);

export const sendNewsClickEvent = (trackingData) => newsActions.sendNewsClickEvent(trackingData);

// NOTIFICATION ACTIONS
export const getUnseenNotificationsCount = () => notificationsActions.getUnseenNotificationsCount();

export const getNotifications = (config) => notificationsActions.getNotifications(config);

export const getUpdatedNotifications = (config) => notificationsActions.getUpdatedNotifications(config);

export const clearNotifications = (config) => notificationsActions.clearNotifications(config);

export const clearNewNotificationsAlertExpand = (config) =>
  notificationsActions.clearNewNotificationsAlertExpand(config);

export const postUpdatedLastSeenNotificationTime = (config) =>
  notificationsActions.postUpdatedLastSeenNotificationTime(config);

export const fetchPerformanceRankingNotificationData = (dateStr) =>
  notificationsActions.fetchPerformanceRankingNotificationData(dateStr);

// ONBOARDING ACTIONS
export const endOnboarding = (currentUserId) => onboardingActions.endOnboarding(currentUserId);

// ORDERS ACTIONS

export const createOrders = (orders, options) => ordersActions.createOrders(orders, options);

export const clearOrderBasket = () => ordersActions.clearOrderBasket();

export const getOrders = (options) => ordersActions.getOrders(options);

export const createAndPlaceOrder = (order, acceptedWarnings) =>
  ordersActions.createAndPlaceOrder(order, acceptedWarnings);

export const placeOrders = (orderIds, acceptedWarnings) => ordersActions.placeOrders(orderIds, acceptedWarnings);

export const addOrderToBasket = (order) => ordersActions.addOrderToBasket(order);

export const updateOrderInBasket = (order) => ordersActions.updateOrderInBasket(order);

export const cancelPlacedOrder = (orderId) => ordersActions.cancelPlacedOrder(orderId);

export const deleteOrder = (orderId) => ordersActions.deleteOrder(orderId);

export const dismissDayTraderMessage = () => ordersActions.dismissDayTraderMessage();

export const getOrderCommission = (orderData) => ordersActions.getOrderCommission(orderData);

// REBALANCER ACTIONS
export const openRebalancerPanel = () => rebalancerActions.openRebalancerPanel();

export const closeRebalancerPanel = () => rebalancerActions.closeRebalancerPanel();

export const runRebalancer = (rebalancePositionsList, config) =>
  rebalancerActions.runRebalancer(rebalancePositionsList, config);

export const createRebalancerOrders = (rebalancePositionsList) =>
  rebalancerActions.createRebalancerOrders(rebalancePositionsList);

// OPTIMIZER ACTIONS
export const openOptimizerPanel = () => optimizerActions.openOptimizerPanel();

export const closeOptimizerPanel = () => optimizerActions.closeOptimizerPanel();

export const runOptimizer = (customOptimizerSettings, delay) => {
  const isUsingRebalancer = getIsUsingRebalancerForPortfolioOptimization();
  if (isUsingRebalancer) {
    // do nothing since optimizer is not enabled
    return () => (dispatch) => {};
  }
  return optimizerActions.runOptimizer(customOptimizerSettings, delay);
};

export const createOptimizedOrders = () => optimizerActions.createOptimizedOrders();

export const clearOptimizerCooldown = () => optimizerActions.clearOptimizerCooldown();

export const getOptimizerAllocationConstraints = () => optimizerActions.getOptimizerAllocationConstraints();

export const createOptimizerAllocationConstraints = (securityId) =>
  optimizerActions.createOptimizerAllocationConstraints(securityId);

export const updateOptimizerAllocationConstraints = (allocationConstraintObj) =>
  optimizerActions.updateOptimizerAllocationConstraints(allocationConstraintObj);
export const deleteOptimizerAllocationConstraint = (optimizerConstraintId) =>
  optimizerActions.deleteOptimizerAllocationConstraint(optimizerConstraintId);
export const moveAllocationConstraintToTopOfList = (securityId) =>
  optimizerActions.moveAllocationConstraintToTopOfList(securityId);

// OPTIMIZATION ACTIONS
export const refreshOptimizationData = () => {
  const isUsingRebalancer = getIsUsingRebalancerForPortfolioOptimization();
  const method = isUsingRebalancer ? runRebalancer : runOptimizer;
  return method();
};

// PAYMENT ACTIONS
export const getANetKeys = () => paymentActions.getANetKeys();

export const getSubscriptionConfig = () => paymentActions.getSubscriptionConfig();

export const getSubscriptionData = () => paymentActions.getSubscriptionData();

export const updateSubscriptionData = (token, billingInfo) => paymentActions.updateSubscriptionData(token, billingInfo);

export const sendAnetCreditCardPaymentTokenForSubscriptionPayment = (token, billingInfo) =>
  paymentActions.sendAnetCreditCardPaymentTokenForSubscriptionPayment(token, billingInfo);

export const cancelSubscription = () => paymentActions.cancelSubscription();

export const getPaymentSourcesData = (config) => paymentActions.getPaymentSourcesData(config);

export const sendAnetCreditCardPaymentTokenForPaymentSource = (token, billingInfo) =>
  paymentActions.sendAnetCreditCardPaymentTokenForPaymentSource(token, billingInfo);

// PLAID ACTIONS
export const showPlaidOverlay = (additionalActions, additionalConfig) =>
  plaidActions.showPlaidOverlay(additionalActions, additionalConfig);

export const hidePlaidOverlay = () => plaidActions.hidePlaidOverlay();

export const getPlaidConfigFromApi = () => plaidActions.getPlaidConfigFromApi();

export const getPlaidLinkToken = () => plaidActions.getPlaidLinkToken();

export const linkInvestmentAccountWithPlaid = (params) => plaidInvestmentActions.linkInvestmentAccountWithPlaid(params);

export const deleteInvestmentAccountWithPlaid = (plaidAccountId) =>
  plaidInvestmentActions.deleteInvestmentAccountWithPlaid(plaidAccountId);

export const getPlaidInvestmentAccountLinkToken = (plaidAccountId) =>
  plaidInvestmentActions.getPlaidInvestmentAccountLinkToken(plaidAccountId);

export const getPlaidInvestmentAccountPortfolioSync = (plaidAccountId) =>
  plaidInvestmentActions.getPlaidInvestmentAccountPortfolioSync(plaidAccountId);

export const getPlaidInvestmentConfigFromApi = () => plaidInvestmentActions.getPlaidInvestmentConfigFromApi();

// PORTFOLIO ACTIONS
export const fetchUserPortfolio = (userId) => portfolioActions.fetchUserPortfolio(userId);

// PORTFOLIO EXPLORER ACTIONS
export const addSecurityToPortfolioExplorer = (securityId) =>
  portfolioExplorerActions.addSecurityToPortfolioExplorer(securityId);

export const getFilterSelectionOptions = () => portfolioExplorerActions.getFilterSelectionOptions();

export const filterSecurities = (count, filters, counter) =>
  portfolioExplorerActions.filterSecurities(count, filters, counter);

// REWARDS ACTIONS
export const getRewardsData = (isUpdating) => rewardsActions.getRewardsData(isUpdating);

export const prepareFundingReward = () => rewardsActions.prepareFundingReward();

export const redeemFundingReward = () => rewardsActions.redeemFundingReward();

export const prepareReferralReward = () => rewardsActions.prepareReferralReward();

export const redeemReferralReward = () => rewardsActions.redeemReferralReward();

// SCROLL ACTIONS
export const lockAppScroll = () => scrollActions.lockAppScroll();

export const unlockAppScroll = () => scrollActions.unlockAppScroll();

// SEARCH ACTIONS
export const querySecurities = (query) => searchActions.querySecurities(query);

export const clearQuery = () => searchActions.clearQuery();

// SECURITIES ACTIONS
export const quickFetchSecuritiesData = (securityIds) => securitiesActions.quickFetchSecuritiesData(securityIds);

export const fetchSecuritiesFundamentalsData = (securityIds) =>
  securitiesActions.fetchSecuritiesFundamentalsData(securityIds);

export const fetchSecuritiesPriceData = (securityIds) => securitiesActions.fetchSecuritiesPriceData(securityIds);

export const fetchAndReturnSecuritiesPriceData = (securities, key) =>
  securitiesActions.fetchSecuritiesPriceData(securities, key);

export const fetchSecuritiesOrderPriceData = (securities) =>
  securitiesActions.fetchSecuritiesOrderPriceData(securities);

export const findSecuritiesMatchingFilters = (selectionKeys) =>
  securitiesActions.findSecuritiesMatchingFilters(selectionKeys);

export const viewSecurity = (securityId) => securitiesActions.viewSecurity(securityId);

export const fetchSimilarCompanies = (securityId) => securitiesActions.fetchSimilarCompanies(securityId);

export const getFilteredSecurities = (filters, count) => securitiesActions.getFilteredSecurities(filters, count);

export const getStocksYouMayBeInterestedIn = () => securitiesActions.getStocksYouMayBeInterestedIn();

export const getUpcomingEarningsAnnouncements = () => securitiesActions.getUpcomingEarningsAnnouncements();

export const fetchSecuritiesPriceHistoryData = (securities, timePeriod) =>
  securitiesActions.fetchSecuritiesPriceHistoryData(securities, timePeriod);

export const updateLivePrices = (securitiesPriceData) => securitiesActions.updateLivePrices(securitiesPriceData);

// SECURITY PRICE STREAM ACTIONS
export const getSecuritiesPriceStreamConfig = () => securityPriceStreamActions.getSecuritiesPriceStreamConfig();

export const savePriceStreamUpdate = (securityPriceData) =>
  securityPriceStreamActions.savePriceStreamUpdate(securityPriceData);

export const addSecuritiesToPriceStreaming = (ids, symbols) =>
  securityPriceStreamActions.addSecuritiesToPriceStreaming(ids, symbols);

// TOUR ACTIONS
export const updateTourStep = (newStep, currentUserId) => tourActions.updateTourStep(newStep, currentUserId);

export const endTour = (currentUserId) => tourActions.endTour(currentUserId);

export const restartTour = (currentUserId) => tourActions.restartTour(currentUserId);

// THOUGHTS ACTIONS
export const createThought = (thought) => thoughtsActions.createThought(thought);

export const fetchThoughts = (securities) => thoughtsActions.fetchThoughts(securities);

export const sendThoughtOpinion = (thought, opinion, currentUserId) =>
  thoughtsActions.sendThoughtOpinion(thought, opinion, currentUserId);

export const getThoughtData = (thoughtId) => thoughtsActions.getThoughtData(thoughtId);

export const getThoughtFeedSuggestion = (data) => thoughtsActions.getThoughtFeedSuggestion(data);

export const sendThoughtViewEvent = (id, context, metadata, notificationIndex) =>
  thoughtsActions.sendThoughtViewEvent(id, context, metadata, notificationIndex);

export const sendThoughtClickEvent = (id, clickType, context, metadata, notificationIndex) =>
  thoughtsActions.sendThoughtClickEvent(id, clickType, context, metadata, notificationIndex);

// TRACKING ACTIONS
export const logMetricsTrackingEvent = (event, properties, config) =>
  trackingActions.logMetricsTrackingEvent(event, properties, config);

// VIEWPOINT ACTIONS
export const fetchAllViewpointData = () => viewpointActions.fetchAllViewpointData();

export const subscribeToViewpoint = (params) => viewpointActions.subscribeToViewpoint(params);

export const cancelViewpointSubscription = (viewpointOrgId) =>
  viewpointActions.cancelViewpointSubscription(viewpointOrgId);

// export const fetchStocksCovered = (params) => viewpointActions.fetchStocksCovered(params);

// USER MUTUAL CONNECTIONS ACTIONS
export const fetchMutualConnections = (userIds, config) =>
  userMutualConnectionsActions.fetchMutualConnections(userIds, config);

// USERS ACTIONS
export const emailMeAppDownloadLink = () => usersActions.emailMeAppDownloadLink();

export const getUser = (userId, config) => usersActions.getUser(userId, config);

export const getUserConnections = (userId) => usersActions.getUserConnections(userId);

export const getUserIdeaPerformances = (userIds) => usersActions.getUserIdeaPerformances(userIds);

export const removeConnection = (currentUserId, userId, config) =>
  usersActions.removeConnection(currentUserId, userId, config);

export const getCurrentUserConnections = () => usersActions.getCurrentUserConnections();

export const fetchThoughtLeaders = () => usersActions.fetchThoughtLeaders();

export const fetchTopConnections = () => usersActions.fetchTopConnections();

export const fetchPeopleYouMayKnow = () => usersActions.fetchPeopleYouMayKnow();

export const getPendingConnectionRequestUsers = () => usersActions.getPendingConnectionRequestUsers();

export const sendConnectionRequest = (connectUserId, context) =>
  usersActions.sendConnectionRequest(connectUserId, context);

export const acceptConnectionRequest = (connectUserId, context, currentUserId) =>
  usersActions.acceptConnectionRequest(connectUserId, context, currentUserId);

export const ignoreConnectionRequest = (connectUserId, context) =>
  usersActions.ignoreConnectionRequest(connectUserId, context);

export const viewProfile = (userId, context) => usersActions.viewProfile(userId, context);

export const viewViewpointOrgProfile = (userId, context) => usersActions.viewViewpointOrgProfile(userId, context);

export const viewSuggestedConnection = (userIds, context) => usersActions.viewSuggestedConnection(userIds, context);

export const dismissConnectionSuggestion = (dismissUserId, dismissalType) =>
  usersActions.dismissConnectionSuggestion(dismissUserId, dismissalType);

export const queryUsers = (queryString) => usersActions.queryUsers(queryString);

export const sendUserInvite = (email, message, context) => usersActions.sendUserInvite(email, message, context);

export const fetchAnnualMembershipEligibility = () => usersActions.fetchAnnualMembershipEligibility();
export const startAnnualMembership = () => usersActions.startAnnualMembership();
