import { useNotPlacedOrder } from '@src/main/hooks/orders/useOrder';
import { Container } from '@src/main/components/ui';
import React from 'react';
import styled from 'styled-components';
import { useSecurity } from '@src/main/hooks/securities/useSecurity';
import { FlatButton } from '@src/main/components/buttons';
import { ORDER_TRANSACTION_TYPES, ORDER_TYPES } from '@src/main/constants/orders';
import { Body3, Body5, Body7 } from '@src/main/lib/nvstr-common-ui.es';
import { DISPLAY_FORMAT_TYPES, formatValueTo } from '@src/main/utils/numbers';
import { usePriceData } from '@src/main/hooks/securities/usePriceData';
import { deleteOrderV1, logMetricsTrackingEvent, showComponentModal } from '@src/actions';
import { useDispatch } from 'react-redux';
import { COMPONENT_MODAL_TYPES } from '@src/main/constants';
import { approximateValueOfOrder, displayOrderType } from '@src/main/utils/orders';

const Wrapper = styled.div`
  box-sizing: border-box;

  display: grid;
  grid-template-columns: minmax(140px, 1fr) minmax(140px, 1fr) minmax(100px, 1fr);
  grid-column-gap: 8px;
  padding: 16px 8px;
  border: 1px solid transparent;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};

  @media (max-width: 840px) {
    grid-template-columns: minmax(80px, 1fr) minmax(80px, 1fr) minmax(60px, 1fr);
  }
`;

const IdentifierCell = styled.div``;
const OrderDataCell = styled.div`
  text-align: center;
`;
const OrderActionsCell = styled.div``;
const OrderOperationText = styled.div`
  * {
    color: ${({ theme, operation }) =>
      operation === ORDER_TRANSACTION_TYPES.buy ? theme.themeColors.positive : theme.themeColors.negative} !important;
  }
`;

export const UnplacedOrder = ({ id }) => {
  const dispatch = useDispatch();

  const order = useNotPlacedOrder(id);
  const { security_id: securityId, shares, operation, type, limit_price, stop_price, is_from_optimizer } = order;
  const { symbol } = useSecurity(securityId);
  const { price } = usePriceData(securityId);

  const handleSeeLivePricing = () => {
    const properties = {
      Context: 'Order',
    };
    logMetricsTrackingEvent('Clicked See More Pricing', properties)();

    const modal = {
      type: COMPONENT_MODAL_TYPES.liveLastPrice,
      props: {
        securityId,
      },
    };
    showComponentModal(modal)(dispatch);
  };
  const handleEdit = () => {
    logMetricsTrackingEvent('Clicked Edit Order', {})();

    const m = {
      type: COMPONENT_MODAL_TYPES.editCartOrder,
      props: {
        isFromOptimizer: is_from_optimizer,
        orderId: id,
        transactionType: operation,
        orderType: type,
        shares,
        limitPrice: limit_price,
        stopPrice: stop_price,
        securityId,
      },
    };
    showComponentModal(m)(dispatch);
  };
  const handleDelete = () => {
    const properties = {
      'Order ID': id,
      'Is From Optimizer': is_from_optimizer,
      'Is Subdivided': false,
      'Security ID': securityId,
      'Security Symbol': symbol,
    };
    logMetricsTrackingEvent('Remove Order', properties)();

    deleteOrderV1(id)(dispatch);
  };
  return (
    <Wrapper>
      <IdentifierCell>
        <Container row>
          <Container right={6}>
            <OrderOperationText operation={operation}>
              <Body3 bold>{operation.toUpperCase()}</Body3>
            </OrderOperationText>
          </Container>
          <Container>
            <Body3 bold>{symbol || ''}</Body3>
          </Container>
        </Container>
        <Container>
          <Body5>{formatValueTo(DISPLAY_FORMAT_TYPES.SHARES, shares)}</Body5>
        </Container>
        {[ORDER_TYPES.stopLimit, ORDER_TYPES.market].includes(type) && <Body7>{displayOrderType(type, true)}</Body7>}
        {[ORDER_TYPES.limit, ORDER_TYPES.stopLimit].includes(type) && (
          <Container>
            <Body7 thin>
              Limit @&nbsp;
              {formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, limit_price)}
            </Body7>
          </Container>
        )}
        {[ORDER_TYPES.stop, ORDER_TYPES.stopLimit].includes(type) && (
          <Container>
            <Body7 thin>
              Stop @&nbsp;
              {formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, stop_price)}
            </Body7>
          </Container>
        )}
      </IdentifierCell>
      <OrderDataCell>
        <Container>
          <Body3 bold>{formatValueTo(DISPLAY_FORMAT_TYPES.STOCK_PRICE, price)}</Body3>
        </Container>
        {null && (
          <Container>
            <Body5 isLowContrast thin>
              {formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, approximateValueOfOrder(order, price))}
            </Body5>
          </Container>
        )}
        <Container>
          <FlatButton small transparent onClick={handleSeeLivePricing}>
            MORE
          </FlatButton>
        </Container>
      </OrderDataCell>
      <OrderActionsCell>
        <Container column alignRight>
          <FlatButton transparent small onClick={handleEdit}>
            Edit
          </FlatButton>
          <FlatButton transparent small onClick={handleDelete}>
            Delete
          </FlatButton>
        </Container>
      </OrderActionsCell>
    </Wrapper>
  );
};
