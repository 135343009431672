import React from 'react';
import { fetchFurtherDocumentationStatus } from '../../actions';

import { ROUTES } from '../../constants/paths';
import { SOCURE_DECISION_STATUS } from '../../constants/types/Socure';
import { INTERNAL_STORAGE_KEYS, InternalStorage } from '../../utils/storage';

import ContactSupportMessage from '../../components/UI/ContactSupportMessage';
import PageLoading from '../../components/PageLoading';
import Page from '../../components/layout/Page';
import { withRouter } from '../../main/utils';

class LiveAccountSubmissionStatus extends React.PureComponent {
  constructor() {
    super();

    const { isInitialized, data } = InternalStorage.getItemFromStorage(INTERNAL_STORAGE_KEYS.SOCURE_REVIEW_STATUS);
    this.state = {
      isLoading: true,
      decisionStatus: isInitialized ? data?.decisionStatus || null : null,
    };
  }

  componentDidMount() {
    this._attemptRedirectToProperSubmissionPage();
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) return <PageLoading />;

    return (
      <Page className="upload-document-verification-page">
        <ContactSupportMessage />
      </Page>
    );
  }

  _attemptRedirectToProperSubmissionPage = () => {
    const { decisionStatus } = this.state;

    if (decisionStatus === null) return this._fetchLiveAccountDocumentationRequirementsAndRedirect();

    this._redirectForLiveAccountSubmissionStatus(decisionStatus);
  };

  _redirectForLiveAccountSubmissionStatus = (status) => {
    const { navigate } = this.props;
    const { decisionStatus } = this.state;

    if (decisionStatus === SOCURE_DECISION_STATUS.FURTHER_DOCUMENTS_REQUIRED) {
      navigate(ROUTES.UPLOAD_REQUIRED_DOCUMENTS.build());
    } else if (decisionStatus === SOCURE_DECISION_STATUS.ADDRESS_RESUBMIT) {
      navigate(ROUTES.RESUBMIT_ADDRESS.build());
    } else {
      navigate(ROUTES.DASHBOARD.build());
    }
  };

  _fetchLiveAccountDocumentationRequirementsAndRedirect = async () => {
    this.setState(() => ({
      isLoading: true,
    }));

    const { decisionStatus, wasNetworkError } = await fetchFurtherDocumentationStatus();

    if (wasNetworkError) {
      // don't repeat too fast
      setTimeout(() => {
        this._fetchLiveAccountDocumentationRequirementsAndRedirect();
      }, 1000);
      return;
    }

    this._redirectForLiveAccountSubmissionStatus(decisionStatus);
  };
}

export default withRouter(LiveAccountSubmissionStatus);
