import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAndReturnSecuritiesPriceData,
  fetchCurrentUserQuickInfo,
  fetchSecuritiesPriceData,
  fetchTopConnections,
  getRewardsData,
  getUnseenNotificationsCount,
  getUpdatedNotifications,
  logMetricsTrackingEvent,
  postUpdatedLastSeenNotificationTime,
  runOptimizer,
} from '../../../actions';
import { sendFacebookTrackingEvent } from '../../../constants/facebookTracking';
import {
  inProminentRefreshButtonTestGroup,
  returnCurrentUserIdeasSecurityIds,
  returnCurrentUserThemeTestGroups,
} from '../../../helpers/currentUserHelpers';
import { useCurrentUser } from '../user/useCurrentUser';
import { useCurrentUserId } from '../user/useCurrentUserId';

export const useRefreshPortfolioData = (isFeedShowing) => {
  const dispatch = useDispatch();

  const [isRefreshing, setIsRefreshing] = React.useState(false);

  const currentUserId = useCurrentUserId();
  const currentUser = useCurrentUser();
  const ideaStore = useSelector((state) => state.ideas);

  const inProminentBtnTestGroup = inProminentRefreshButtonTestGroup(currentUser);
  const currentUserTheme = returnCurrentUserThemeTestGroups(currentUser);

  const fetchUpdatedNotifications = (additionalConfig = {}) => {
    const config = {
      batch_index: 0,
      page: 1,
      ...additionalConfig,
    };
    return getUpdatedNotifications(config)(dispatch).then((response) => {
      if (isFeedShowing) {
        postUpdatedLastSeenNotificationTime()(dispatch).then(() => {
          getUnseenNotificationsCount()(dispatch);
        });
      } else {
        getUnseenNotificationsCount()(dispatch);
      }
      return response;
    });
  };

  const refreshMarketMonitorData = () => {
    const key = 'symbol';
    const securitySymbolsList = ['SPY', 'QQQ'];
    fetchAndReturnSecuritiesPriceData(securitySymbolsList, key)(dispatch);
  };

  const refreshCurrentUserData = () => {
    return fetchCurrentUserQuickInfo()(dispatch).then((response) => {
      if (
        response &&
        response.error &&
        response.error.response &&
        response.error.response.status &&
        response.error.response.status === 401
      ) {
        window.location = '/?sessionExpired=true';
      }
      return response;
    });
  };

  const refreshSecurityPrices = () => {
    const securityIds = returnCurrentUserIdeasSecurityIds(currentUserId, ideaStore);
    return fetchSecuritiesPriceData(securityIds)(dispatch);
  };

  const logRefresh = () => {
    sendFacebookTrackingEvent('Click Portfolio Refresh Button', {
      themeTestGroups: currentUserTheme,
    });

    const event = 'Clicked Refresh Dashboard Data';
    const properties = {
      'Button Style': inProminentBtnTestGroup ? 'Prominent' : 'Default',
    };
    logMetricsTrackingEvent(event, properties)();
  };

  const handleRefresh = async () => {
    if (isRefreshing) return;

    setIsRefreshing(true);

    logRefresh();

    runOptimizer()(dispatch);

    await Promise.all([
      refreshCurrentUserData(),
      getRewardsData()(dispatch),
      fetchTopConnections()(dispatch),
      refreshSecurityPrices(),
      refreshMarketMonitorData(),
      fetchUpdatedNotifications({ forceRefresh: true }),
    ]);

    setIsRefreshing(false);
  };

  return {
    isRefreshing,
    handleRefresh,
  };
};
