import { BASEURL } from '@src/adapters';
import { PRODUCT_NAME, PRODUCT_PARTNER_ID } from '../../../../appConfig';

export async function signUp({
  firstName,
  lastName,
  email,
  password,
  rememberMe,
  userSignUpComplete,
  userSignUpError,
  recaptchaValue,
}) {
  try {
    // -- Get initial CSRF token
    const getCSRF = await fetch(`${BASEURL}/api/v1/util/csrf`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const data = await getCSRF.json();
    const { csrf_token } = data;
    const extraProperties = {};

    if (PRODUCT_PARTNER_ID) {
      extraProperties.white_label_partner_id = PRODUCT_PARTNER_ID;
    }

    const signUp = await fetch(`${BASEURL}/users`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        user: {
          first_name: firstName,
          last_name: lastName,
          email,
          password,
          terms_of_service: '1',
          'g-recaptcha-response': recaptchaValue,
          ...extraProperties,
        },
      }),
    });

    const signUpData = await signUp.json();
    if (signUpData.error) {
      userSignUpError(signUpData.error);
    } else {
      userSignUpComplete();
    }
  } catch (e) {
    console.error(e);
  }
}
