import React from 'react';
import { LogoFull, LogoShort } from '@src/main/lib/nvstr-common-ui.es';
import { PRODUCT_NAME, PRODUCT_NAMES } from '@src/appConfig';
import styled from 'styled-components';

const ByWrapper = styled.div`
  height: 12px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  span {
    font-size: 9px;
    line-height: 18px;
    color: ${({ theme }) => theme.themeColors.text};
  }

  svg {
    margin-top: 1px;
    margin-left: 4px;

    height: 12px;
    width: 70px;
    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

export const TornadoLogoWrapper = styled.div`
  path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

const ByTornadoLogo = () => {
  return (
    <ByWrapper>
      <span>by</span>
      <LogoFull />
    </ByWrapper>
  );
};

export const MobileLogo = (props) => {
  if (PRODUCT_NAME === PRODUCT_NAMES.Tornado) {
    return (
      <TornadoLogoWrapper>
        <LogoShort {...props} />
      </TornadoLogoWrapper>
    );
  }
  return <LogoShort {...props} />;
};
