import React from 'react';
import { Spacing } from './Spacing';
import { isInteger } from '../../../helpers/numberHelpers';

export const Container = (props) => {
  const {
    name,
    style: additionalStyling,
    children,

    height,
    width,
    fullWidth,
    maxWidth,
    maxHeight,
    textCenter,
    gap,

    borderRadius,

    flex,
    flexWrap,
    centerAll,
    verticallyCenter,
    spaceBetween,
    spaceAround,
    row,
    column,
    relative,
    columnCenter,
    alignCenter,
    alignRight,
    background,
    border,
    borderBottom,
    zIndex,
    debugBorder,
  } = props;

  const style = {};

  if (background) {
    style.backgroundColor = background;
  }
  if (textCenter) {
    style.textAlign = 'center';
  }
  if (border) {
    style.borderWidth = 1;
    style.borderColor = border;
  }
  if (debugBorder) {
    style.borderWidth = 1;
    style.borderStyle = 'solid';
    style.borderColor = typeof debugBorder === 'string' ? debugBorder : '#e1a';
  }
  if (borderBottom) {
    style.borderBottomWidth = 1;
    style.borderBottomColor = borderBottom;
  }
  if (gap) {
    style.gap = gap;
  }
  if (isInteger(borderRadius)) {
    style.borderRadius = borderRadius;
  }
  if (isInteger(flex)) {
    style.display = 'flex';
    style.flex = flex;
  }
  if (flexWrap) {
    style.flexWrap = 'wrap';
  }
  if (isInteger(height)) {
    style.height = height;
  }
  if (isInteger(width)) {
    style.width = width;
  }
  if (fullWidth) {
    style.width = '100%';
  }

  if (isInteger(maxWidth)) {
    style.maxWidth = maxWidth;
  }
  if (isInteger(maxHeight)) {
    style.maxHeight = maxHeight;
  }

  if (isInteger(zIndex)) {
    style.zIndex = zIndex;
  }

  if (column) {
    style.display = 'flex';
    style.flexDirection = 'column';
  }

  if (centerAll) {
    style.display = 'flex';
    style.alignItems = 'center';
    style.justifyContent = 'center';
  }

  if (verticallyCenter) {
    style.display = 'flex';
    style.flexDirection = 'row';
    style.alignItems = 'center';
  }

  if (columnCenter) {
    style.display = 'flex';
    style.flexDirection = 'column';
    style.alignItems = 'center';
    style.justifyContent = 'flex-start';
  }

  if (alignCenter) {
    style.display = 'flex';
    style.flexDirection = 'row';
    style.justifyContent = 'center';
  }

  if (row) {
    style.display = 'flex';
    style.flexDirection = 'row';
  }

  if (alignRight) {
    style.flexDirection = column ? 'column' : 'row';
    style[column ? 'alignItems' : 'justifyContent'] = 'flex-end';
  }

  if (spaceBetween) {
    style.justifyContent = 'space-between';
  }

  if (spaceAround) {
    style.justifyContent = 'space-around';
  }

  if (relative) {
    style.position = 'relative';
  }

  return (
    <Spacing {...props} style={{ ...style, ...(additionalStyling || {}) }}>
      {children}
    </Spacing>
  );
};
