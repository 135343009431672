import { createStore, applyMiddleware } from 'redux';

import rootReducer from './reducers/index';
import reduxThunk from 'redux-thunk';

const addLoggingToDispatch = (store) => {
  /* eslint-disable no-console */
  const rawDispatch = store.dispatch;
  if (!console.group) {
    return rawDispatch;
  }

  return (action) => {
    const returnValue = rawDispatch(action);
    return returnValue;
  };
  /* eslint-enable no-console */
};

const configureStore = (customStore) => {
  const store = customStore
    ? createStore(rootReducer, customStore, applyMiddleware(reduxThunk))
    : createStore(rootReducer, applyMiddleware(reduxThunk));

  // if ( window.origin === 'http://localhost:3000' || window.origin === 'https://d1-forerunner.nvstr.com/' || window.origin === 'https://s1-forerunner.nvstr.com/' ) {
  //   store.dispatch = addLoggingToDispatch(store);
  // }

  // if (module && module.hot) {
  //   // Enable Webpack hot module replacement for reducers
  //   module.hot.accept('./reducers', () => {
  //     const nextRootReducer = rootReducer;
  //     store.replaceReducer(nextRootReducer);
  //   });
  // }

  return store;
};

export default configureStore;
