import React from 'react';

export const TooltipBodyOnly = (props) => {
  const { bgClassName, style } = props;
  return (
    <div
      className={`tooltip-body ${bgClassName || 'main-bg-color-match'} fade-in-up-quick border-accent`}
      style={style || {}}
    >
      {props.children}
    </div>
  );
};
