import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as Actions from '../../actions/index';
import { withRouter } from '../../main/utils';
import SearchContainer from '../UI/Search/SearchContainer';

import { openSecurityPanel } from '../../helpers/securityPanelHelpers';
import { findUserIdeaForSecurity } from '../../helpers/ideaHelpers';
import { sendFacebookTrackingEvent } from '../../constants/facebookTracking';
import { returnCurrentUserThemeTestGroups } from '../../helpers/currentUserHelpers';
import { TrackingEvents } from '../../utils/tracking/events';
import styled from 'styled-components';

const Wrapper = styled.div`
  input {
    border: none !important;
  }
  .add-to-portfolio-container .default-search-container i.query-icon {
    top: 10px;
  }
  .add-to-portfolio-container .default-search-container {
    margin: 0 auto;
  }
  .add-to-portfolio-container .default-search-container {
    max-width: 300px;
  }
  .add-to-portfolio-container .default-search-container .search-results-container {
    left: -25px;
    right: -25px;
  }
  @media (max-width: 1075px) {
    .add-to-portfolio-container .default-search-container .search-results-container {
      left: 0px;
      right: 0px;
    }
  }
  .add-to-portfolio-container .default-search-container i.query-icon {
    left: 13px;
    z-index: 5;
  }
  @media (max-width: 500px) {
    .add-to-portfolio-container .default-search-container {
      max-width: 275px;
    }
    .add-to-portfolio-container .default-search-container .search-results-container {
      left: 0px;
      right: 0px;
    }
  }
`;

class AddToPortfolio extends Component {
  render() {
    return (
      <Wrapper className={`add-to-portfolio-container`}>
        <SearchContainer
          id="add-to-portfolio-search"
          customIcon={this.props.icon || 'fa-plus'}
          showPlus={this.props.showPlus}
          autoFocus={false}
          parentComponent="PortfolioTable"
          placeholder={this.props.placeholder || 'Add to Portfolio'}
          handleSecurityQuerySelection={this._createIdeaFromSearchQuery}
          dismissAfterSelection
        />
      </Wrapper>
    );
  }

  _fetchDataForSecurity = (securityId) => {
    this.props.actions.quickFetchSecuritiesData([securityId]);
    this.props.actions.fetchSecuritiesPriceData([securityId]);
    this.props.actions.getIdeasForSecurity([securityId]);
  };

  _createIdeaFromSearchQuery = (query) => {
    if (!query) {
      // means they clicked something that wasn't a search result that triggered action so do nothing
      return false;
    }
    const querySecurity = query;
    const securityId = querySecurity.id;
    this._fetchDataForSecurity(securityId);

    const userId = this.props.currentUser.user_id;
    const ideaList = this.props.ideas.ideaList;
    const existingIdea = findUserIdeaForSecurity(userId, securityId, ideaList);
    const doesUserHaveAnExistingIdea = !!existingIdea;
    if (doesUserHaveAnExistingIdea) {
      const updatedIdea = {
        ...existingIdea,
        active: true,
      };
      this.props.actions.updateIdea(updatedIdea, existingIdea);
    } else {
      this.props.actions.addIdeas([{ security: { security_id: securityId } }]).then((response) => {
        const ideasWithErrors = response.ideas.filter((idea) => idea.status === 'error');
        if (ideasWithErrors.length === 0) {
          const params = {
            themeTestGroups: returnCurrentUserThemeTestGroups(this.props.currentUser),
            accountType: this.props.currentUser.is_live_trading ? 'Live' : 'Paper',
          };
          sendFacebookTrackingEvent('Added Idea', params);

          TrackingEvents.ideas.ADD_IDEA.send();
        }
      });
    }

    const trackingData = {
      properties: {
        Component: 'Add to Portfolio',
        Context: 'Security Panel',
      },
    };
    const additionalProps = {
      activeTab: 'ideas',
    };
    const openSecurityPanelConfig = {
      securityId,
      additionalProps,
      navigate: this.props.navigate,
      trackingData,
    };

    if (this.props.shouldOpenSecurityPanelOnSelection !== false) {
      openSecurityPanel({ ...openSecurityPanelConfig });
    }
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    ideas: state.ideas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddToPortfolio);

export default composedComponent;
