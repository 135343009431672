import React from 'react';
import { useSelector } from 'react-redux';

const getPosition = (lookup, securityId) => {
  if (!lookup) return null;
  return lookup[securityId];
};
export const useOptimizedPosition = (securityId) => {
  const optimizedPositionLookup = useSelector((state) => state.optimizer.data?.optimized_positions_by_security_id);
  const [data, setData] = React.useState(getPosition(optimizedPositionLookup, securityId));

  React.useEffect(() => {
    const pos = getPosition(optimizedPositionLookup, securityId);
    if (pos) {
      setData(pos);
    }
  }, [optimizedPositionLookup, securityId]);

  // value
  // shares
  // allocation_percent

  // avg_correl_summary: "Very Diversifying"
  // avg_correl_group: 3
  // hist_volatility_summary: "High Volatility"
  // hist_volatility_group: 1
  // proj_return_summary: "Low Return"
  // proj_return_group: 1
  // pos_comment_group: 4
  // pos_comment: "Your W idea may not have a high enough return relative to its volatility to be included in your Optimized portfolio."
  return data;
};
