import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as Actions from '../../../actions/index';
import { withRouter } from '../../../main/utils';
import Page from '../../../components/layout/Page';
import AnimatedOverlay from '../../../components/layout/AnimatedOverlay';
import PageLoading from '../../../components/PageLoading';
import OptimizerGraph from './OptimizerGraph';
import InvestmentObjectivesContainer from '../../InvestmentObjectives/InvestmentObjectivesContainer';
import IconButton from '../../../components/buttons/IconButton';
import InfoIcon from '../../UI/InfoIcon';
import LoadingIcon from '../../../components/misc/LoadingIcon';
import { securityDataFormatTable, formatDataValue } from '../../../helpers/securitiesHelpers';
import {
  returnChangeSymbolPrefix,
  returnChangeAdverbalPrefix,
  returnChangeVerbalPrefix,
} from '../../../helpers/numberHelpers';
import { isUndefinedOrNull } from '../../../helpers/generalHelpers';
import { findUserIdeas } from '../../../helpers/ideaHelpers';
import {
  createBasicErrorModal,
  createBasicErrorModalWithOkayButton,
  createAndShowConfirmationModal,
} from '../../../constants/modals';
import { moment, formatLocalizedDateTime } from '../../../helpers/timeHelpers';
import { returnPathTo, ROUTES } from '../../../constants/paths';
import { parseQueryString } from '../../../helpers/routerHelpers';
import { TrackingEvents } from '../../../utils/tracking/events';
import { TrackIteratively } from '../../../utils/itly';
import { BaseButton, FlatButton } from '../../../main/components/buttons';
import styled from 'styled-components';
import Icon from '@src/components/misc/Icon';
import { LoadingAnimation } from '@src/main/components/ui/LoadingAnimation';

const NextButtonWrapper = styled.div`
  button {
    padding: 8px 12px;
  }
`;

const TabButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 2px 1px 1px 1px;

  cursor: pointer;

  height: 30px;
  width: 30px;

  border-radius: 4px;

  background-color: ${({ theme, isActive }) =>
    isActive ? theme.themeColors.primaryCtaButton : theme.themeColors.component};
  color: ${({ theme, isActive }) => (isActive ? theme.themeColors.buttonText : theme.themeColors.text)};

  * {
    color: ${({ theme, isActive }) => (isActive ? theme.themeColors.buttonText : theme.themeColors.text)};
  }

  &:hover {
    opacity: 0.8;
  }
`;

class OptimizerPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panelWidth: window.$(window).width() * 0.95,

      activePanel: 'graph',
      showInvestmentObjectivesPanel: false,
      showCreatingOptimizedOrdersMessage: false,

      areUnsavedChangesInInvestmentObjectives: false,
      saveInvestmentObjectives: false,
    };
  }

  // componentDidMount() {
  //   window.addEventListener('resize', this.handleResize);
  //   this.handleResize();
  //   if (!this._shouldRenderAsPanel()) {
  //     this._logViewOptimizer();
  //   }
  // }
  //
  // componentDidUpdate(prevProps) {
  //   const queryString = this.props.location.search;
  //   const query = parseQueryString(queryString);
  //   const isOptimizerShowing = this.props.optimizer.showPanel || this._isOnPortfolioAnalysisPage();
  //   if (query.auto_expand === 'optimizer' && !isOptimizerShowing && !this._isOnPortfolioAnalysisPage()) {
  //     this.props.actions.openOptimizerPanel();
  //     this.handleResize();
  //   }
  //
  //   if (query.auto_expand === 'investment_objectives') {
  //     // if the optimizer is not showing first show it, componentDidUpdate will retrigger when showing and then open InvestmentObjectivesPanel
  //     if (!isOptimizerShowing && !this._isOnPortfolioAnalysisPage()) {
  //       this.props.actions.openOptimizerPanel();
  //       this.handleResize();
  //     } else {
  //       if (!this.state.showInvestmentObjectivesPanel) {
  //         this.showInvestmentObjectivesPanel();
  //         this._logAutoExpandInvestmentObjectives();
  //       }
  //     }
  //   }
  //
  //   if (query.queue_auto_expand === 'optimizer' && !this.props.optimizer.showPanel && !('panel' in query)) {
  //     this.props.actions.openOptimizerPanel();
  //     this.handleResize();
  //   }
  //
  //   if (!prevProps.optimizer.showPanel && this.props.optimizer.showPanel) {
  //     this._logViewOptimizer();
  //     this.handleResize();
  //     this._logItlyModalViewed();
  //   }
  //
  //   // will not show the message if its the initial optimizer run -> this.isLoading()
  //   if (!this.isOptimizerRunning(prevProps) && this.isOptimizerRunning() && !this.isLoading()) {
  //     this._onOptimizerStartRunning();
  //   }
  //
  //   if (this.isOptimizerRunning(prevProps) && !this.isOptimizerRunning() && !this.isLoading()) {
  //     this._onOptimizerEndRunning();
  //   }
  // }

  // componentWillUnmount() {
  //   if (this.state.staleDataCheckFunction) {
  //     clearInterval(this.state.staleDataCheckFunction);
  //   }
  //   window.removeEventListener('resize', this.handleResize);
  // }

  _shouldRenderAsPanel = () => !this.props.renderOnPage;

  _shouldShowOptimizer = () => this.props.optimizer.showPanel || this.props.renderOnPage;

  render() {
    return null;

    if (!this._shouldShowOptimizer() || !this._currentUserHasIdeas() || this._isADifferentPanelOpenOnPage()) {
      return null;
    }

    return this._shouldRenderAsPanel() ? this._renderOptimizerAsPanel() : this._renderOptimizerOnPage();
  }

  _renderOptimizerOnPage = () => {
    return (
      <Page>
        <div className={'optimizer-container optimizer-on-page'}>
          {this.state.showCreatingOptimizedOrdersMessage && this.renderCreatingOptimizedOrdersMessage()}
          {this.renderPanel()}
        </div>
      </Page>
    );
  };

  _renderOptimizerAsPanel = () => {
    const showOptimizerPanel = this._shouldShowOptimizer();

    return (
      <div className={`optimizer-panel-container ${!showOptimizerPanel ? 'hidden' : ''}`}>
        <AnimatedOverlay showOverlay={showOptimizerPanel} handleClick={this.closeOptimizerPanel} fixedCenter>
          <div
            className={`panel-container fade-in-up alternate-panel-bg-color ${
              this.isOptimizerRunning() && !this.isLoading() ? 'lock-scrolling' : ''
            }`}
            style={this.state.showCreatingOptimizedOrdersMessage ? { borderRadius: 0 } : {}}
            ref={(el) => (this.panelContainer = el)}
          >
            {this.state.showCreatingOptimizedOrdersMessage && this.renderCreatingOptimizedOrdersMessage()}
            <IconButton
              customClass="no-shadow"
              style={{
                position: 'absolute',
                top: '12px',
                right: '16px',
                zIndex: 100002,
              }}
              icon="fa-times fa-times-thin"
              size="medium"
              colorClassName="secondary-text-color"
              handleClick={this.closeOptimizerPanel}
            />
            {this.renderPanel()}
          </div>
        </AnimatedOverlay>
      </div>
    );
  };

  handleCreateOptimizedOrders = () => {
    if (this.state.areUnsavedChangesInInvestmentObjectives) {
      this.showCreatingOptimizedOrdersMessage();
      this._saveInvestmentObjectives();
    } else {
      this._createOptimizedOrders();
    }
  };

  _createOptimizedOrders = () => {
    this.closeOptimizerPanel();
    this.props.navigate(ROUTES.CREATE_OPTIMIZED_ORDER_CART.build());
  };

  _registerUnsavedChangesInInvestmentObjectives = () => {
    this.setState((prevState) => ({
      areUnsavedChangesInInvestmentObjectives: true,
    }));
  };

  _resetUnsavedChangesInInvestmentObjectives = () => {
    // SHOULD NOT BE USED BY INVESTMENT OBJ, that should only terminate with a new page load
    this.setState((prevState) => ({
      areUnsavedChangesInInvestmentObjectives: false,
    }));
  };

  _saveInvestmentObjectives = () => {
    // HACK: RETHINK: updates state which is passed to invest obj as prop to tell it to save -> React anti-pattern but only way to keep Investment Objectives Container separate enough to be reusable.
    this.setState((prevState) => ({
      saveInvestmentObjectives: true,
    }));
  };

  _internalCreateOptimizedOrdersHandler = () => {
    // HACK: part of _saveInvestmentObjectives hack, used by investment objectives to create optimized orders after saving
    this.hideCreatingOptimizedOrdersMessage();
    this._createOptimizedOrders();
  };

  _resetSaveInvestmentObjectives = () => {
    this.setState((prevState) => ({
      saveInvestmentObjectives: false,
    }));
  };

  _currentUserHasIdeas = () => {
    const ideaList = this.props.ideas.ideaList;
    const currentUserId = this.props.currentUser.user_id;
    return findUserIdeas(currentUserId, ideaList).length > 0;
  };

  isOptimizerRunning = (props) => (props || this.props).optimizer.isOptimizerRunning;

  renderCreatingOptimizedOrdersMessage = () => {
    return (
      <div className={'creating-optimized-orders-overlay'}>
        <div className={'creating-optimized-orders component-bg'} style={{ maxWidth: '320px ' }}>
          <LoadingAnimation size={'small'} />
          <div style={{ marginTop: '4px', marginLeft: '8px', fontSize: '24px' }}>{'Saving'}</div>
        </div>
      </div>
    );
  };

  calcGraphWidth = () => {
    const panelWidth = this.state.panelWidth;
    const portfolioListWidth = 250;

    if (panelWidth > 864) {
      const buffer = 35;
      const summaryWidth = 250;
      return panelWidth - summaryWidth - portfolioListWidth - buffer;
    } else if (panelWidth < 652) {
      return panelWidth - 30;
    } else if (panelWidth < 716) {
      return panelWidth - portfolioListWidth - 50;
    } else {
      return panelWidth - portfolioListWidth;
    }
  };

  _isOptimizerDataAvailable = () => this.props.optimizer.data && this.props.optimizer.data.portfolio_stats;

  renderPanel = () => {
    if (this.isLoading()) {
      return (
        <div className={'initializing-optimizer-panel'}>
          <PageLoading size="medium" flatStyle />
        </div>
      );
    }

    return this._isOptimizerDataAvailable() ? (
      <div className={'react-optimizer'}>
        <div className={'optimizer-output-container'}>
          {this.renderOptimizerFeedback()}
          {this.renderOptimizerOutputPanel()}
        </div>
        {this.renderOptimizerActionButtons()}
        {this.renderOptimizerDisclaimer()}

        <InvestmentObjectivesContainer
          show={this.state.showInvestmentObjectivesPanel}
          hideComponent={this.hideInvestmentObjectivesPanel}
          isPanel={this._shouldRenderAsPanel()}
          $scrollContainer={
            this._shouldRenderAsPanel()
              ? window.$('.optimizer-panel-container .panel-container')
              : window.$('html, body')
          }
          hideCreatingOptimizedOrdersMessage={this.hideCreatingOptimizedOrdersMessage}
          saveInvestmentObjectives={this.state.saveInvestmentObjectives}
          internalCreateOptimizedOrdersHandler={this._internalCreateOptimizedOrdersHandler}
          registerUnsavedChangesInInvestmentObjectives={this._registerUnsavedChangesInInvestmentObjectives}
          resetUnsavedChangesInInvestmentObjectives={this._resetUnsavedChangesInInvestmentObjectives}
          resetSaveInvestmentObjectives={this._resetSaveInvestmentObjectives}
        />
      </div>
    ) : (
      this._renderOptimizerDataUnavailable()
    );
  };

  _renderOptimizerDataUnavailable = () => {
    return (
      <div className={'optimizer-unavailable-message'}>
        <p>The optimizer is currently unavailable. Check back later.</p>
        <div className={'optimizer-refresh-container'}>
          <FlatButton onClick={this._runOptimizer}>Refresh</FlatButton>
        </div>
      </div>
    );
  };

  renderOptimizerOutputPanel = () => {
    const panel =
      this.state.activePanel === 'graph' ? (
        <div className={'graph-panel-container'}>
          {this.renderOptimizerGraph()}
          {this.renderOptimizedPortfolioList()}
        </div>
      ) : (
        this.renderOptimizerOutput()
      );

    return (
      <div className={'optimizer-output-panel-container'}>
        {this.renderPanelSelectionButtons()}

        <div className={'optimizer-output-panel'}>{panel}</div>
      </div>
    );
  };

  returnOptimizerData = () => {
    const data = this.props.optimizer.data;
    const portfolioStats = data.portfolio_stats;
    const optimizerMessage = data.detailed_message;
    const currentPortfolioStats = portfolioStats.current;
    const optimizedPortfolioStats = portfolioStats.optimized;
    const portfolioDelta = portfolioStats.delta;
    const optimizerLastUpdatedDate = moment(data.lastUpdated);
    const optimizerLastUpdated = `${formatLocalizedDateTime('l', optimizerLastUpdatedDate)} ${formatLocalizedDateTime(
      'LT',
      optimizerLastUpdatedDate
    )}`;
    const optimizedAllocationData = data.optimized_positions_by_security_id;
    return {
      data,
      optimizerMessage,
      portfolioStats,
      currentPortfolioStats,
      optimizedPortfolioStats,
      portfolioDelta,
      optimizerLastUpdated,
      optimizedAllocationData,
    };
  };

  renderOptimizerFeedback = () => {
    const {
      portfolioStats,
      optimizerMessage,
      currentPortfolioStats,
      optimizedPortfolioStats,
      optimizerLastUpdated,
    } = this.returnOptimizerData();
    const userHasFunding =
      !isUndefinedOrNull(currentPortfolioStats.total_value) && currentPortfolioStats.total_value > 0;
    const accuracy = portfolioStats.risk_confidence_max_percentile;

    const currentProjectedValue = userHasFunding
      ? securityDataFormatTable.priceNearestDollar(currentPortfolioStats.projected_value)
      : securityDataFormatTable.percentage(currentPortfolioStats.profit_loss_percentage);

    const currentPortfolioMaxRiskConfidenceValue = userHasFunding
      ? securityDataFormatTable.price_fin_notation_no_decimal(currentPortfolioStats.risk_confidence_min)
      : securityDataFormatTable.percentage(currentPortfolioStats.risk_confidence_min_percentage);

    const currentProjectedValueAccuracyMessage = `${accuracy}% chance of being above ${currentPortfolioMaxRiskConfidenceValue}`;

    const optimizedProjectedValue = userHasFunding
      ? securityDataFormatTable.priceNearestDollar(optimizedPortfolioStats.projected_value)
      : securityDataFormatTable.percentage(optimizedPortfolioStats.profit_loss_percentage);

    const optimizedPortfolioMaxRiskConfidenceValue = userHasFunding
      ? securityDataFormatTable.price_fin_notation_no_decimal(optimizedPortfolioStats.risk_confidence_min)
      : securityDataFormatTable.percentage(optimizedPortfolioStats.risk_confidence_min_percentage);

    const optimizedProjectedValueAccuracyMessage = `${accuracy}% chance of being above ${optimizedPortfolioMaxRiskConfidenceValue}`;

    const lastOptimizerUpdateMessage = optimizerLastUpdated ? `Last updated ${optimizerLastUpdated}` : '';

    return (
      <div className={'optimizer-feedback-container'}>
        <div className={'optimizer-message optimizer-emphasized-text-color'}>{optimizerMessage}</div>

        <div className="portfolio-comparison-container">
          {userHasFunding && (
            <div className={'current-projected-value'}>
              <div className={'text-label secondary-heading-text-color'}>Current Projected Value</div>
              <div className={'value  '}>{currentProjectedValue}</div>
              <div className={'accuracy secondary-text-color'}>{currentProjectedValueAccuracyMessage}</div>
            </div>
          )}

          <div className={'optimized-projected-value'}>
            <div className={'text-label secondary-heading-text-color'}>
              {`Optimized Projected ${userHasFunding ? 'Value' : 'Return'}`}
            </div>
            <div className={'value optimizer-emphasized-text-color'}>{optimizedProjectedValue}</div>
            <div className={'accuracy secondary-text-color'}>{optimizedProjectedValueAccuracyMessage}</div>
          </div>
        </div>

        <div className={'optimizer-last-updated secondary-text-color'}>{lastOptimizerUpdateMessage}</div>
      </div>
    );
  };

  renderOptimizedAllocation = (security) => {
    const allocationPercent = this._returnPortfolioAllocationPercent(security);
    const optimizedAllocationPercent = this._returnOptimizedPortfolioAllocationPercent(security);
    return (
      <tr className={'optimizer-panel-optimized-allocation'} key={`optimized-allocation-${security.security_id}`}>
        <td className={'security-symbol'}>{security.symbol}</td>
        <td className={`security-allocation ${allocationPercent === '-' && 'pad-right'}`}>{allocationPercent}</td>
        <td className={`security-allocation optimized ${optimizedAllocationPercent === '-' && 'pad-right'}`}>
          {optimizedAllocationPercent}
        </td>
      </tr>
    );
  };

  _renderOptimizedAllocationTableBody = (securitiesSortedByHighestAllocation) => {
    if (this._isOptimizedAllocationTableLoading()) {
      return this._renderOptimizedAllocationLoadingTable();
    } else {
      return (
        <table>
          <thead>
            <tr>
              <th className="security-symbol" />
              <th className="security-allocation secondary-text-color">Current</th>
              <th className="security-allocation optimized optimizer-emphasized-text-color">Optimized</th>
            </tr>
          </thead>
          <tbody>
            {securitiesSortedByHighestAllocation.map((security) => this.renderOptimizedAllocation(security))}
            <tr>
              <td style={{ padding: '11px' }} />
              <td />
            </tr>
          </tbody>
        </table>
      );
    }
  };

  _isOptimizedAllocationTableLoading = () => {
    return this.props.portfolio.loading;
  };

  _renderOptimizedAllocationLoadingTable = () => {
    return (
      <div className={'optimizer-panel-optimized-allocation text-center'}>
        <LoadingIcon icon="fading-3balls" size="small" style={{ marginRight: '8px' }} />
      </div>
    );
  };

  _returnPortfolioAllocationPercent = (security) => {
    const securityId = security.security_id;
    const positions = this.props.portfolio.positions;
    const allocationValue = positions[securityId] ? positions[securityId].allocation_percent : 0;
    return allocationValue === 0
      ? '-'
      : securityDataFormatTable.percentage(allocationValue, {
          decimalPlace: 1,
        });
  };

  _returnOptimizedPortfolioAllocationPercent = (security) => {
    const allocationValue = security.allocation_percent;
    return allocationValue === 0
      ? '-'
      : securityDataFormatTable.percentage(allocationValue, {
          decimalPlace: 1,
        });
  };

  _returnListOfSecuritiesInOptimizedAllocationSortedByAbsValueAllocation = () => {
    const { optimizedAllocationData } = this.returnOptimizerData();
    const listOfSecurityIdsInOptimizedAllocation = Object.keys(optimizedAllocationData);
    const listOfSecuritiesInOptimizedAllocation = listOfSecurityIdsInOptimizedAllocation.map(
      (id) => optimizedAllocationData[id]
    );
    const securitiesSortedByHighestAllocation = listOfSecuritiesInOptimizedAllocation.sort(
      (a, b) => Math.abs(b.allocation_percent) - Math.abs(a.allocation_percent)
    );
    return securitiesSortedByHighestAllocation;
  };

  renderOptimizedPortfolioList = () => {
    const securitiesSortedByHighestAllocation = this._returnListOfSecuritiesInOptimizedAllocationSortedByAbsValueAllocation();

    return (
      <div className={'wrapper'} style={{ margin: '0 auto' }}>
        <div className={'optimized-portfolio-list-container'}>
          <div className={'heading secondary-heading-text-color'}>Optimized Allocations</div>
          {this._renderOptimizedAllocationTableBody(securitiesSortedByHighestAllocation)}
        </div>
        <div className={'optimizer-panel-transparent-gradient to-bottom'} />
      </div>
    );
  };

  renderOptimizerGraph = () => {
    const { portfolioStats, currentPortfolioStats, optimizedPortfolioStats } = this.returnOptimizerData();

    const userHasFunding =
      !isUndefinedOrNull(currentPortfolioStats.total_value) && currentPortfolioStats.total_value > 0;
    const usingPercentages = !userHasFunding;
    const currentPortfolioValue = currentPortfolioStats.total_value || 0;
    const currentProjectedReturn = usingPercentages
      ? currentPortfolioStats.profit_loss_percentage
      : currentPortfolioStats.profit_loss;
    const optimizedProjectedReturn = usingPercentages
      ? optimizedPortfolioStats.profit_loss_percentage
      : optimizedPortfolioStats.profit_loss;
    const currentPortfolioRisk = currentPortfolioStats[usingPercentages ? 'risk_percentage' : 'risk'];
    const optimizedPortfolioRisk = optimizedPortfolioStats[usingPercentages ? 'risk_percentage' : 'risk'];

    const optimizerPortfolioData = {
      current_profit: currentProjectedReturn,
      current_risk: currentPortfolioRisk,
      current_value: currentPortfolioValue,
      optimized_profit: optimizedProjectedReturn,
      optimized_risk: optimizedPortfolioRisk,
      risk_confidence_multiplier: portfolioStats.risk_confidence_multiplier || 0.674489750196082,
    };

    return (
      <OptimizerGraph
        usingPercentages={usingPercentages}
        optimizerPortfolioData={optimizerPortfolioData}
        panelWidth={this.state.panelWidth}
      />
    );
  };

  renderOptimizerOutput = () => {
    const { currentPortfolioStats, optimizedPortfolioStats, portfolioDelta } = this.returnOptimizerData();
    const userHasFunding =
      !isUndefinedOrNull(currentPortfolioStats.total_value) && currentPortfolioStats.total_value > 0;

    const currentPortfolioProjectedValue = currentPortfolioStats.projected_value || 0;
    const optimizedPortfolioProjectedValue = optimizedPortfolioStats.projected_value || 0;

    const currentPortfolioTotalValue = currentPortfolioStats.total_value || 0;
    const currentProjectedReturnPercentage = currentPortfolioStats.profit_loss_percentage || 0;
    const optimizedPortfolioTotalValue = optimizedPortfolioStats.total_value || 0;
    const optimizedProjectedReturnPercentage = optimizedPortfolioStats.profit_loss_percentage || 0;

    const currentProjectedReturnValue = currentPortfolioProjectedValue - currentPortfolioTotalValue;
    const optimizedProjectedReturnValue = optimizedPortfolioProjectedValue - optimizedPortfolioTotalValue;

    const currentProjectedReturn = userHasFunding
      ? securityDataFormatTable.price(currentProjectedReturnValue)
      : securityDataFormatTable.percentage(currentProjectedReturnPercentage);

    const optimizedProjectedReturn = userHasFunding
      ? securityDataFormatTable.price(optimizedProjectedReturnValue)
      : securityDataFormatTable.percentage(optimizedProjectedReturnPercentage);

    const returnDifferenceVsCurrent = portfolioDelta.profit_loss || 0;
    const displayReturnDifferenceVsCurrent = `${returnChangeSymbolPrefix(
      returnDifferenceVsCurrent
    )}${securityDataFormatTable.price(Math.abs(returnDifferenceVsCurrent))}`;
    const returnsMessage = `${returnChangeVerbalPrefix(returnDifferenceVsCurrent)} Returns`;

    const currentRiskValue = currentPortfolioStats.risk || 0;
    const currentRiskPercentage = currentPortfolioStats.risk_percentage || 0;
    const optimizedRiskValue = optimizedPortfolioStats.risk || 0;
    const optimizedRiskPercentage = optimizedPortfolioStats.risk_percentage || 0;
    const riskDifferenceVsCurrent = optimizedRiskValue - currentRiskValue;

    const currentRisk = userHasFunding
      ? securityDataFormatTable.price(currentRiskValue)
      : securityDataFormatTable.percentage(currentRiskPercentage);
    const optimizedRisk = userHasFunding
      ? securityDataFormatTable.price(optimizedRiskValue)
      : securityDataFormatTable.percentage(optimizedRiskPercentage);

    const displayRiskDifferenceVsCurrent = `${returnChangeSymbolPrefix(
      riskDifferenceVsCurrent
    )}${securityDataFormatTable.price(Math.abs(riskDifferenceVsCurrent))}`;
    const riskMessage = `${returnChangeVerbalPrefix(riskDifferenceVsCurrent)} Risk`;

    const optimizedDividendIncomePercentage = optimizedPortfolioStats.expected_dividends_percentage;
    const displayOptimizedDividendIncomePercentage = securityDataFormatTable.percentage(
      optimizedPortfolioStats.expected_dividends_percentage
    );

    const currentDividendIncome = userHasFunding
      ? securityDataFormatTable.price(currentPortfolioStats.expected_dividends)
      : '0%';

    const optimizedDividendIncome = userHasFunding
      ? securityDataFormatTable.price(optimizedPortfolioStats.expected_dividends)
      : displayOptimizedDividendIncomePercentage;

    const dividendMessage = userHasFunding
      ? `${returnChangeVerbalPrefix(portfolioDelta.expected_dividends)} Dividends`
      : `${returnChangeVerbalPrefix(optimizedDividendIncomePercentage)} Dividends`;

    const estimatedChangeInDividends = userHasFunding
      ? `${returnChangeSymbolPrefix(portfolioDelta.expected_dividends)}${securityDataFormatTable.price(
          Math.abs(portfolioDelta.expected_dividends)
        )}`
      : `${returnChangeSymbolPrefix(optimizedDividendIncomePercentage)}${securityDataFormatTable.percentage(
          Math.abs(optimizedDividendIncomePercentage)
        )}`;

    const estimatedChangeInBeta = `${returnChangeSymbolPrefix(portfolioDelta.beta)}${securityDataFormatTable.float(
      Math.abs(portfolioDelta.beta)
    )}`;
    const currentBeta = securityDataFormatTable.float(currentPortfolioStats.beta);
    const optimizedBeta = securityDataFormatTable.float(optimizedPortfolioStats.beta);
    const betaMessage = `${returnChangeAdverbalPrefix(estimatedChangeInBeta)} Beta`;

    return (
      <div className={'optimizer-data-output-container'}>
        <div className={'optimizer-column-group'}>
          <div className={'optimizer-column-container'}>
            <div className={'column-heading'}>
              <span style={{ position: 'relative' }}>
                <span className="secondary-heading-text-color">{'Projected Return'}</span>
                <InfoIcon
                  // eslint-disable-next-line no-undef
                  word={'tooltip_projected_return'}
                  style={{
                    top: $(window).width() > 450 ? '1px' : '-1px',
                    lineHeight: 1.4,
                  }}
                />
              </span>
            </div>
            <div className={'column-explanation secondary-text-color'}>Projected 1-year change in portfolio value</div>
            {userHasFunding && (
              <div className={'difference-vs-current'}>
                <div className={'value optimizer-emphasized-text-color'}>{displayReturnDifferenceVsCurrent}</div>
                <div className={'text-label optimizer-emphasized-text-color'}>{returnsMessage}</div>
              </div>
            )}
            <div className={'current-value'}>
              <div className={'text-label secondary-heading-text-color'}>{'Current'}</div>
              <div className={'value secondary-heading-text-color'}>{currentProjectedReturn}</div>
            </div>
            <div className={'optimized-value'}>
              <div className={'text-label secondary-heading-text-color'}>{'Optimized'}</div>
              <div className={'value secondary-heading-text-color'}>{optimizedProjectedReturn}</div>
            </div>
          </div>

          <div className={'optimizer-column-container'}>
            <div className={'column-heading'}>
              <span style={{ position: 'relative' }}>
                <span className="secondary-heading-text-color">{'Portfolio Volatility'}</span>
                <InfoIcon
                  // eslint-disable-next-line no-undef
                  word={'tooltip_portfolio_volatility'}
                  style={{
                    top: $(window).width() > 450 ? '1px' : '-1px',
                    lineHeight: 1.4,
                  }}
                  position={$(window).width() > 725 ? 'default' : 'right'}
                />
              </span>
            </div>
            <div className={'column-explanation secondary-text-color'}>Standard deviation of projected returns ($)</div>
            {userHasFunding && (
              <div className={'difference-vs-current'}>
                <div className={'value optimizer-emphasized-text-color'}>{displayRiskDifferenceVsCurrent}</div>
                <div className={'text-label optimizer-emphasized-text-color'}>{riskMessage}</div>
              </div>
            )}
            <div className={'current-value'}>
              <div className={'text-label secondary-heading-text-color'}>{'Current'}</div>
              <div className={'value secondary-heading-text-color'}>{currentRisk}</div>
            </div>
            <div className={'optimized-value'}>
              <div className={'text-label secondary-heading-text-color'}>{'Optimized'}</div>
              <div className={'value secondary-heading-text-color'}>{optimizedRisk}</div>
            </div>
          </div>
        </div>

        <div className={'optimizer-column-group'}>
          <div className={'optimizer-column-container'}>
            <div className={'column-heading'}>
              <span style={{ position: 'relative' }}>
                <span className="secondary-heading-text-color">{'Est. Dividends'}</span>
                <InfoIcon
                  // eslint-disable-next-line no-undef
                  word={'tooltip_estimated_dividends'}
                  style={{
                    top: $(window).width() > 450 ? '1px' : '-1px',
                    lineHeight: 1.4,
                  }}
                />
              </span>
            </div>
            <div className={'column-explanation secondary-text-color'}>Projected dividend income over 1 year</div>

            <div className={'difference-vs-current'}>
              <div className={'value optimizer-emphasized-text-color'}>{estimatedChangeInDividends}</div>
              <div className={'text-label optimizer-emphasized-text-color'}>{dividendMessage}</div>
            </div>
            <div className={'current-value'}>
              <div className={'text-label secondary-heading-text-color'}>{'Current'}</div>
              <div className={'value secondary-heading-text-color'}>{currentDividendIncome}</div>
            </div>
            <div className={'optimized-value'}>
              <div className={'text-label secondary-heading-text-color'}>{'Optimized'}</div>
              <div className={'value secondary-heading-text-color'}>{optimizedDividendIncome}</div>
            </div>
          </div>

          <div className={'optimizer-column-container'}>
            <div className={'column-heading'}>
              <span style={{ position: 'relative' }}>
                <span className="secondary-heading-text-color">{'Market Beta'}</span>
                <InfoIcon
                  // eslint-disable-next-line no-undef
                  word={'tooltip_market_beta'}
                  style={{
                    top: $(window).width() > 450 ? '1px' : '-1px',
                    lineHeight: 1.4,
                  }}
                  position="right"
                />
              </span>
            </div>
            <div className={'column-explanation secondary-text-color'}>Exposure to broad stock market</div>
            <div className={'percentage-difference-vs-current'}>
              <div className={'value optimizer-emphasized-text-color'}>{estimatedChangeInBeta}</div>
              <div className={'text-label optimizer-emphasized-text-color'}>{betaMessage}</div>
            </div>
            <div className={'current-value'}>
              <div className={'text-label secondary-heading-text-color'}>{'Current'}</div>
              <div className={'value secondary-heading-text-color'}>{currentBeta}</div>
            </div>
            <div className={'optimized-value'}>
              <div className={'text-label secondary-heading-text-color'}>{'Optimized'}</div>
              <div className={'value secondary-heading-text-color'}>{optimizedBeta}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderOptimizerActionButtons = () => {
    const { currentPortfolioStats } = this.returnOptimizerData();
    const userHasFunding =
      !isUndefinedOrNull(currentPortfolioStats.total_value) && currentPortfolioStats.total_value > 0;
    return (
      <div className={'optimizer-action-btn-container'}>
        <div className={'refresh-btn-container icon-grey-color'}>
          <IconButton icon="fa-refresh" size="medium" background="rgba(0,0,0,0)" handleClick={this._runOptimizer} />
        </div>
        <div className={'settings-btn-container icon-grey-color'}>
          <IconButton
            icon="fa-wrench"
            size="medium"
            background="rgba(0,0,0,0)"
            handleClick={
              this.state.showInvestmentObjectivesPanel
                ? this.hideInvestmentObjectivesPanel
                : this.showInvestmentObjectivesPanel
            }
          />
        </div>
        <div className={'create-optimized-orders-btn-container'}>
          <NextButtonWrapper className="wrapper">
            <FlatButton onClick={this.handleCreateOptimizedOrders} disabled={!userHasFunding}>
              {this.state.areUnsavedChangesInInvestmentObjectives ? 'Save and Next' : 'Next'}
            </FlatButton>
            {!userHasFunding && (
              <div className="react-info-icon">
                <span className={'info-icon-tooltip-container right-aligned'}>
                  <span className="info-icon-tooltip-container-arrow" />
                  <span className="info-icon-tooltip-content">
                    {`To Optimize you need at least ${formatDataValue(
                      this.props.currentUser.min_equity_required_to_use_optimizer,
                      'priceNearestDollar'
                    )} in your account.`}
                  </span>
                </span>
              </div>
            )}
          </NextButtonWrapper>
        </div>
      </div>
    );
  };

  _runOptimizer = () => {
    this.props.actions.runOptimizer();
  };

  renderOptimizerDisclaimer = () => {
    return (
      <div className={'optimizer-disclaimer'}>
        <span className="disclaimer-text-color">
          {
            'Projected return is based on the price targets and other information in your ideas. Risk is based on recent historical data. Results may vary with each use and over time. Conviction levels affect the maximum possible allocation for each position. Any "short term" (6 month) idea will be annualized. IMPORTANT: The projections or other information generated by the Optimizer regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual investment results and are not guarantees of future results. More details can be found '
          }
        </span>
        <a
          href={'/terms_of_service/#section8'}
          target="_blank"
          rel="noopener noreferrer"
          className="disclaimer-text-color"
        >
          {'here'}
        </a>
        <span className="disclaimer-text-color">
          {
            '. The output of the Optimizer is not a recommendation.  You may modify or cancel any or all of the templated orders [on the order screen after clicking the "Next" button].  Please consider whether any trade is appropriate for your risk tolerance, investment goals, and other preferences before transacting.'
          }
        </span>
      </div>
    );
  };

  renderPanelSelectionButtons = () => {
    const panels = this.returnAvailablePanels();
    return (
      <div className={'optimizer-panel-selection-buttons-container'}>
        {panels.map((panel) => this.renderPanelSelectionButton(panel))}
      </div>
    );
  };

  renderPanelSelectionButton = (panel) => {
    const isActive = (panel) => {
      return this.state.activePanel === panel.name;
    };
    const handleClick = () => {
      this.handlePanelSelection(panel);
    };

    return (
      <TabButtonWrapper
        isActive={isActive(panel)}
        className={`optimizer-panel-selection-button ${isActive(panel) && 'active'}`}
        key={`optimizer-selection-button-${panel.name}`}
      >
        <div onClick={handleClick}>
          <Icon icon={panel.icon} size={'medium'} color={null} background={null} />
        </div>
      </TabButtonWrapper>
    );
  };

  returnAvailablePanels = () => {
    const panels = [
      {
        name: 'graph',
        icon: 'fa-line-chart',
      },
      {
        name: 'numbers',
        icon: 'fa-list-ul',
      },
    ];
    return panels;
  };

  handlePanelSelection = (panel) => {
    this.setState((prevState) => ({
      activePanel: panel.name,
    }));
  };

  isLoading = () => {
    return this.props.optimizer.loading;
  };

  removeOptimizerAutoExpandFromUrl = () => {
    const navigate = this.props.navigate;
    const location = this.props.location;
    const queryString = location.search;
    const query = parseQueryString(queryString);

    const removeKeyFromObjectIfValue = (keyToRemove, value, obj) => {
      const keys = Object.keys(query);
      const filteredKeys = keys.filter((key) => key !== keyToRemove && value !== obj[key]);
      const filteredObject = {};
      filteredKeys.forEach((key) => {
        filteredObject[key] = obj[key];
      });
      return filteredObject;
    };

    const filteredQuery = removeKeyFromObjectIfValue('auto_expand', 'optimizer', query);
    navigate(filteredQuery);
  };

  closeOptimizerPanel = () => {
    this.removeOptimizerAutoExpandFromUrl();
    this.hideInvestmentObjectivesPanel();
    this.props.actions.closeOptimizerPanel();
  };

  showInvestmentObjectivesPanel = () => {
    this.setState((prevState) => ({
      showInvestmentObjectivesPanel: true,
    }));
  };

  hideInvestmentObjectivesPanel = () => {
    this.setState((prevState) => ({
      showInvestmentObjectivesPanel: false,
    }));
  };

  showCreatingOptimizedOrdersMessage = () => {
    // eslint-disable-next-line no-undef
    $('.optimizer-panel-container .panel-container').css('overflow', 'hidden');
    this.setState((prevState) => ({
      showCreatingOptimizedOrdersMessage: true,
    }));
  };

  hideCreatingOptimizedOrdersMessage = () => {
    // eslint-disable-next-line no-undef
    $('.optimizer-panel-container .panel-container').css('overflow', 'auto');
    this.setState((prevState) => ({
      showCreatingOptimizedOrdersMessage: false,
    }));
  };

  handleResize = () => {
    const width = window.$(this.panelContainer).width() || window.$(window).width() * 0.95;
    this.setState(() => ({
      panelWidth: width,
    }));
  };

  _returnOptimizerIsRunningActionMessageData = () => ({
    id: 'optimizer-is-running',
    type: 'optimizer',
    isDismissable: false,
  });
  _showOptimizerIsRunningActionMessage = () => {
    this.props.actions.showActionMessage(this._returnOptimizerIsRunningActionMessageData());
  };

  _hideOptimizerIsRunningActionMessage = () => {
    this.props.actions.dismissActionMessage(this._returnOptimizerIsRunningActionMessageData());
  };

  _isOnAnalysisPageOrOptimizerPanelOpen = () => {
    return this.props.location.pathname === '/portfolio' || this._shouldShowOptimizer();
  };

  _isADifferentPanelOpenOnPage = () => {
    const location = window.location;
    const query = parseQueryString(location.search);
    return 'panel' in query;
  };

  _onOptimizerStartRunning = () => {
    if (this._isOnAnalysisPageOrOptimizerPanelOpen() && !this._isADifferentPanelOpenOnPage()) {
      this._showOptimizerIsRunningActionMessage();
    }
  };

  _onOptimizerEndRunning = () => {
    this._hideOptimizerIsRunningActionMessage();
  };

  _isOnPortfolioAnalysisPage = () => this.props.location.pathname === returnPathTo('portfolioAnalysis');

  _logViewOptimizer = () => {
    const event = 'View Optimizer';
    const properties = {
      Context: this._shouldRenderAsPanel() ? 'Panel' : 'Analysis Page',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logItlyModalViewed = () => {
    const properties = {
      context: location.pathname,
      is_error: false,
      message: this.props.optimizer.data.summary_message || 'modal message',
      modal_type: 'OptimizerPanel',
      url: window.location.pathname,
      url_query: window.location.search,
    };
    TrackIteratively.generic.MODAL_VIEWED.send(properties);
  };

  _logAutoExpandInvestmentObjectives = () => {
    const event = 'Auto Expanded Investment Objectives';
    const properties = {
      Context: this._shouldRenderAsPanel() ? 'Panel' : 'Analysis Page',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    optimizer: state.optimizer,
    portfolio: state.portfolio,
    ideas: state.ideas,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(OptimizerPanel);

export default composedComponent;
