import React from 'react';
import { Container } from '@src/main/components/ui';
import { Body3, Body5 } from '@src/main/lib/nvstr-common-ui.es';
import { FlatButton } from '@src/main/components/buttons';

const enhanceMessage = ({ warningType }) => {
  // const orderString = ordersCount === 1 ? 'order' : 'orders';
  // const orderPossessiveString = ordersCount === 1 ? 'it' : 'them';
  const messageLookup = {
    market_closed: 'The market is open from 9:30am ET to 4pm ET on weekdays except holidays.',
  };
  return messageLookup[warningType] || null;
};

const createAdditionalMessagingForWarning = ({ warningType, ordersCount, isFromOptimizer }) => {
  const orderString = ordersCount === 1 ? 'order' : 'orders';
  const orderPossessiveString = ordersCount === 1 ? 'it' : 'them';
  const messageLookup = {
    market_closed: isFromOptimizer
      ? `Click "Place Orders" if you want ${orderPossessiveString} to be sent automatically when the market reopens. If the ${orderString} came from the Optimizer, consider re-running the Optimizer when the market is open instead.`
      : `Place the ${orderString} again if you want ${orderPossessiveString} to be sent automatically when the market reopens.`,
  };
  return messageLookup[warningType] || null;
};

export const PlaceOrderWarnings = ({
  ordersCount,
  isFromOptimizer,
  warnings,

  handleAcceptAllWarnings,
  handleWarningsCancel,
}) => {
  const [warningQueuePosition, setWarningQueuePosition] = React.useState(0);

  const displayWarningQueuePosition = warningQueuePosition + 1;
  const warningsCount = warnings.length;
  const isMultipleWarnings = warningsCount > 1;

  const activeWarning = warnings[warningQueuePosition];
  const { message, type } = activeWarning;
  const additionalMessage = createAdditionalMessagingForWarning({ warningType: type, ordersCount, isFromOptimizer });
  const enhancedMessage = enhanceMessage({ warningType: type, ordersCount, isFromOptimizer });

  const handlePlaceAnyway = () => {
    const didAcceptAllWarnings = warningQueuePosition + 1 === warningsCount;
    if (didAcceptAllWarnings) {
      handleAcceptAllWarnings(warnings);
    } else {
      setWarningQueuePosition((prevQueuePos) => prevQueuePos + 1);
    }
  };
  const onCancel = () => {
    handleWarningsCancel('warnings', warnings.map((warningData) => warningData.type));
  };

  return (
    <>
      <Container flex={1} column>
        {isMultipleWarnings && (
          <Container bottom={24}>
            <Body5 centered>{`${displayWarningQueuePosition} of ${warningsCount}`}</Body5>
          </Container>
        )}
        <Container top={8}>
          <Body3 centered>{message}</Body3>
        </Container>
        {enhancedMessage && (
          <Container top={8}>
            <Body5 centered>{enhancedMessage}</Body5>
          </Container>
        )}
        {additionalMessage && (
          <Container top={16}>
            <Body5 centered>{additionalMessage}</Body5>
          </Container>
        )}
      </Container>
      <Container top={24}>
        <Container>
          <FlatButton fullWidth onClick={handlePlaceAnyway}>
            Place Orders
          </FlatButton>
        </Container>
        <Container top={8}>
          <FlatButton fullWidth onClick={onCancel} transparent>
            Cancel
          </FlatButton>
        </Container>
      </Container>
    </>
  );
};
