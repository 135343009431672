import React from 'react';
import { isUserThoughtLeader } from '@src/helpers/usersHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { fetchThoughtLeaders } from '@src/actions';

const _getFamousInvestorsFromUsersStore = (usersStore) => {
  const usersDict = usersStore.userList;
  const userIdsList = Object.keys(usersDict);
  const thoughtLeaderIds = userIdsList.filter((id) => isUserThoughtLeader(usersDict[id]));
  return thoughtLeaderIds.map((id) => usersDict[id]);
};

export const useFamousInvestors = (fetchData) => {
  const dispatch = useDispatch();
  const usersStore = useSelector((state) => state.users);

  const [investors, setInvestors] = React.useState(_getFamousInvestorsFromUsersStore(usersStore));
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchInvestors = async () => {
      setIsLoading(true);
      const { ok } = await fetchThoughtLeaders()(dispatch);
      setIsLoading(false);
    };
    if (fetchData) fetchInvestors();
  }, []);

  React.useEffect(() => {
    const users = _getFamousInvestorsFromUsersStore(usersStore);
    setInvestors(users);
  }, [usersStore]);
  return { famousInvestors: investors, isLoading };
};
