import React from 'react';
import styled from 'styled-components';
import { BaseText, ValueChangeText, defineFontSettings } from '../../../../lib/nvstr-common-ui.es';
import { formatForDisplay } from '../../../../../helpers/displayHelpers';

export const PositionChangeValue = (props) => {
  const fontSettings = defineFontSettings(
    { fontSize: '18px', lineHeight: '24px' },
    { fontSize: '20px', lineHeight: '24px' }
  );
  const rawValue = props.value;
  const displayValue = formatForDisplay(rawValue, 'price');
  const prefix = rawValue === 0 ? '' : rawValue > 0 ? '+' : '';
  return (
    <ValueChangeText value={rawValue}>
      <BaseText fontSettings={fontSettings} {...props} thin>
        {prefix + displayValue}
      </BaseText>
    </ValueChangeText>
  );
};
export const PositionPercentChangeValue = (props) => {
  const fontSettings = defineFontSettings(
    { fontSize: '12px', lineHeight: '18px' },
    { fontSize: '12px', lineHeight: '18px' }
  );

  const rawValue = props.value;
  const displayValue = formatForDisplay(rawValue, 'percent');
  const prefix = rawValue === 0 ? '' : rawValue > 0 ? '+' : '';
  return (
    <ValueChangeText value={rawValue}>
      <BaseText fontSettings={fontSettings} {...props}>
        {prefix + displayValue}
      </BaseText>
    </ValueChangeText>
  );
};

export const PositionAllocationValue = (props) => {
  const fontSettings = defineFontSettings(
    { fontSize: '18px', lineHeight: '24px' },
    { fontSize: '20px', lineHeight: '24px' }
  );

  const rawValue = props.value;
  const displayValue = formatForDisplay(rawValue, 'price');
  return (
    <BaseText fontSettings={fontSettings} {...props} thin>
      {displayValue}
    </BaseText>
  );
};
export const PositionAllocationPercentValue = (props) => {
  const fontSettings = defineFontSettings(
    { fontSize: '12px', lineHeight: '18px' },
    { fontSize: '12px', lineHeight: '18px' }
  );

  const rawValue = props.value;
  const displayValue = formatForDisplay(rawValue, 'percent');
  return (
    <BaseText fontSettings={fontSettings} {...props}>
      {displayValue}
    </BaseText>
  );
};

const PositionAllocationShareValueWrapper = styled.span`
  word-break: normal;
  white-space: nowrap;
`;
export const PositionAllocationShareValue = (props) => {
  const fontSettings = defineFontSettings(
    { fontSize: '12px', lineHeight: '18px' },
    { fontSize: '12px', lineHeight: '18px' }
  );

  const rawValue = props.value;
  const displayValue = formatForDisplay(rawValue, 'shares');
  return (
    <PositionAllocationShareValueWrapper>
      <BaseText fontSettings={fontSettings} {...props}>
        {displayValue}
      </BaseText>
    </PositionAllocationShareValueWrapper>
  );
};
