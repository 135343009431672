import React from 'react';
import IconButton from '../../../../../components/buttons/IconButton';

class RemoveCell extends React.PureComponent {
  onRemove = () => {
    const { securityId, onRemove } = this.props;
    onRemove(securityId);
  };

  render() {
    return (
      <td className="comp-table-stock-remove-cell">
        <div
          className={`remove-icon-container secondary-text-color`}
          style={{
            visibility: this.props.isSecurityInDetail ? 'hidden' : 'visible',
            pointerEvents: this.props.isSecurityInDetail ? 'none' : 'all',
          }}
        >
          <IconButton
            icon="fa fa-times fa-times-thin"
            color="secondary-text-color"
            size="medium"
            handleClick={this.onRemove}
          />
        </div>
      </td>
    );
  }
}

export default RemoveCell;
