import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useDispatch } from 'react-redux';

import { useSecurity } from '@src/main/hooks/securities/useSecurity';
import { Page } from '@src/main/components/layout';
import { Container } from '@src/main/components/ui';
import { Body5, CheckCircle, H5 } from '@src/main/lib/nvstr-common-ui.es';
import { CreateOrderForm } from '@src/main/containers/Orders/CreateOrderForm';
import { SkeletonButton } from '@src/main/components/buttons';
import { ORDER_TRANSACTION_TYPES, ORDER_TYPES, ORDER_VALUE_TYPES } from '@src/main/constants/orders';
import { useCurrentIdeas } from '@src/main/hooks/user';
import { addIdea, createOrderV1, logMetricsTrackingEvent, updateOrderInCartV1 } from '@src/actions';
import { Warning } from '@src/main/icons/svg/Warning';
import { pluralize } from '@src/helpers/usefulFuncs';
import { LoadingAnimation } from '@src/main/components/ui/LoadingAnimation';
import { displayOrderType } from '@src/main/utils/orders';
import { FixedBackgroundOverlay } from '@src/main/components/layout/FixedBackgroundOverlay';

const LoadingSpinnerWrapper = styled.div``;
const CheckIconWrapper = styled.div`
  svg {
    height: 64px;
    width: 64px;

    * {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  min-width: 300px;
  justify-content: space-between;
`;
const WarningIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 28px;
    width: 28px;

    fill: ${({ theme }) => theme.themeColors.error};
  }
`;

const STATES = {
  unSubmitted: 'unsub',
  isSubmitting: 'issub',
  error: 'err',
  created: 'created',
};

const width = '440px';

const massageDataForApi = (d) => {
  const { transactionType, orderType, orderValueType, shares, dollarAmount, limitPrice, stopPrice } = d;
  const orderValue =
    orderValueType === ORDER_VALUE_TYPES.shares
      ? { shares: parseFloat(shares) }
      : { cash_amount: parseFloat(dollarAmount) };
  const additionalInputs = {};
  if (orderType === ORDER_TYPES.limit || orderType === ORDER_TYPES.stopLimit) {
    additionalInputs.limit_price = parseFloat(limitPrice);
  }
  if (orderType === ORDER_TYPES.stop || orderType === ORDER_TYPES.stopLimit) {
    additionalInputs.stop_price = parseFloat(stopPrice);
  }

  return {
    operation: transactionType,
    type: orderType,
    is_from_optimizer: false,

    ...orderValue,
    ...additionalInputs,
  };
};

const createIdea = (sid, data) => {
  const { transactionType } = data;
  return {
    security: {
      security_id: sid,
    },
    idea_type: transactionType === ORDER_TRANSACTION_TYPES.buy ? { id: 0, name: 'buy' } : { id: 1, name: 'sell' },
  };
};

const PlaceOrderHeading = styled.div`
  svg {
    fill: ${({ theme }) => theme.themeColors.positive} !important;
  }
`;
const PlaceOrderHeadingText = styled.div`
  h5 {
    line-height: 1.1;
  }
`;

export const CreateCartOrderController = ({ orderId, securityId, initFormData, isFromOptimizer, onComplete }) => {
  const dispatch = useDispatch();
  const isUpdateMode = !!orderId;
  const { symbol } = useSecurity(securityId);
  const ideasLookupBySecurityId = useCurrentIdeas();

  const [state, setState] = React.useState(STATES.unSubmitted);

  const [order, setOrder] = React.useState(null);
  const [createdOrder, setCreatedOrder] = React.useState(null);

  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (isUpdateMode) {
      logMetricsTrackingEvent('Edit Trade Panel Open', {
        'Using Basket': isFromOptimizer,
        'From Optimizer': isFromOptimizer,

        'Security ID': securityId,
        'Security Symbol': symbol,
      })();
    } else {
      logMetricsTrackingEvent('Manual Trade Panel Open', {
        'Using Basket': isFromOptimizer,
        'From Optimizer': isFromOptimizer,

        'Security ID': securityId,
        'Security Symbol': symbol,
      })();
    }
  }, []);

  const onCancel = () => {
    setOrder(null);
    setCreatedOrder(null);
    setError(null);
    setState(STATES.unSubmitted);
  };

  const handleCreatedOrderContinue = () => {
    onComplete();
  };

  const onSubmit = async (securityId, formData) => {
    setState(STATES.isSubmitting);

    const hasIdea = securityId in ideasLookupBySecurityId;
    if (!hasIdea) {
      const { ok, data, error } = await addIdea(createIdea(securityId, formData))(dispatch);
      const alreadyExistsErrMessage = 'User already has an idea for this security. Send the idea_id to update it.';
      const ideaInResponse = data?.ideas && data.ideas[0];
      const ideaError = ideaInResponse?.error;
      if (!ok && ideaError !== alreadyExistsErrMessage) {
        console.error(ideaError);
        setError(ideaError || 'Something went wrong, please refresh page and try again.');
        setState(STATES.error);
        return;
      }
    }

    const order = {
      security_id: securityId,
      ...massageDataForApi(formData),
    };
    setOrder(order);

    let response;
    if (isUpdateMode) {
      response = await updateOrderInCartV1(orderId, order)(dispatch);
    } else {
      response = await createOrderV1(order)(dispatch);
    }
    const { data, ok } = response;

    if (ok) {
      setCreatedOrder(data);
      setState(STATES.created);

      const eventName = isUpdateMode ? 'Edit Manual Trade' : 'Create Manual Trade';
      logMetricsTrackingEvent(eventName, {
        'Using Basket': isFromOptimizer,
        'From Optimizer': isFromOptimizer,

        'Security ID': securityId,
        'Security Symbol': symbol,
      })();
      return;
    }

    const error = 'Something went wrong. Please try again.';
    setError(error);
    setState(STATES.error);
    return;
  };

  if (state === STATES.created) {
    const { type, operation, shares } = createdOrder;
    return (
      <FixedBackgroundOverlay hide>
        <Page width={width}>
          <Container>
            <Wrapper>
              <Container>
                <PlaceOrderHeading>
                  <Container row verticallyCenter>
                    <Container top={4} right={12}>
                      <CheckIconWrapper>
                        <CheckCircle />
                      </CheckIconWrapper>
                    </Container>

                    <Container>
                      <PlaceOrderHeadingText>
                        <H5>{symbol} Order Saved</H5>
                      </PlaceOrderHeadingText>
                      <Container top={3}>
                        <Body5 thin>
                          Your {displayOrderType(type)} order to {operation} {shares} {pluralize(shares, 'share')} has
                          been {isUpdateMode ? 'updated in your cart.' : 'added to your cart.'}
                        </Body5>
                      </Container>
                    </Container>
                  </Container>
                </PlaceOrderHeading>
              </Container>
            </Wrapper>
          </Container>
          <Container centerAll>
            <SkeletonButton fullWidth onClick={handleCreatedOrderContinue}>
              Continue
            </SkeletonButton>
          </Container>
        </Page>
      </FixedBackgroundOverlay>
    );
  }
  if (state === STATES.isSubmitting) {
    return (
      <FixedBackgroundOverlay hide>
        <Page width={width}>
          <Wrapper>
            <Container flex={1} column centerAll>
              <Container centerAll>
                <LoadingSpinnerWrapper>
                  <LoadingAnimation withLogo />
                </LoadingSpinnerWrapper>
              </Container>
              <Container top={16}>
                <Body5 thin>Saving Order...</Body5>
              </Container>
            </Container>
          </Wrapper>
        </Page>
      </FixedBackgroundOverlay>
    );
  }

  if (state === STATES.error) {
    return (
      <Page width={width}>
        <Wrapper>
          <Container fullWidth>
            <Container row centerAll>
              <WarningIconWrapper>
                <Warning />
              </WarningIconWrapper>
              <Container left={9} verticallyCenter>
                <H5>Order not saved</H5>
              </Container>
            </Container>
            <Container top={24} row centerAll>
              <Body5>{error}</Body5>
            </Container>
          </Container>
          <Container row centerAll>
            <SkeletonButton fullWidth onClick={onCancel}>
              Dismiss
            </SkeletonButton>
          </Container>
        </Wrapper>
      </Page>
    );
  }

  if (state === STATES.unSubmitted) {
    return (
      <CreateOrderForm
        securityId={securityId}
        initFormData={initFormData}
        isFromOptimizer={isFromOptimizer}
        onSubmit={onSubmit}
      />
    );
  }
  return null;
};
