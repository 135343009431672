import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  & > svg {
    width: 18px;
    height: 18px;
  }
  & g,
  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function BookmarkIcon() {
  return (
    <Wrapper>
      <svg viewBox="0 0 12 16" fill="none">
        <path
          d="M10.167.5H1.834C.917.5.167 1.25.167 2.167V15.5L6 13l5.834 2.5V2.167c0-.917-.75-1.667-1.667-1.667zm0 12.5L6 11.183 1.834 13V2.167h8.333V13z"
          fill="#1F1F1F"
        />
      </svg>
    </Wrapper>
  );
}

export default BookmarkIcon;
