import React from 'react';

import InfoIcon from '../UI/InfoIcon';

import { formatSecurityDataValue } from '../../helpers/securitiesHelpers';
import { userShortNamePossessive } from '../../helpers/usersHelpers';
import { isIdeaUsingPriceTarget, calculateExpectedReturn } from '../../helpers/ideaHelpers';
import { getIdeaPhraseFor } from '../../helpers/terminologyHelpers';

const UserIdeaDiff = (props) => {
  const { idea, security, user, screenWidth, isCurrentUsersIdea, currentPrice } = props;

  const ideaUsingPriceTarget = isIdeaUsingPriceTarget(idea);
  const expectedReturn = calculateExpectedReturn({
    idea,
    security,
    currentPrice,
  });
  const priceTarget = ideaUsingPriceTarget ? idea.price_target : expectedReturn;
  return (
    <div className="diff-idea-content">
      <div className="diff-heading">
        {!isCurrentUsersIdea
          ? `${userShortNamePossessive(user)} ${getIdeaPhraseFor('noun')}`
          : `Current ${getIdeaPhraseFor('noun')}`}
      </div>

      <div className="price-target">
        <div className="idea-label">
          {ideaUsingPriceTarget ? 'Price Target' : 'Expected Return'}
          <InfoIcon
            word={`tooltip_${ideaUsingPriceTarget ? 'price_target' : 'expected_return'}`}
            symbol={(security || {}).symbol}
            style={{ top: '0px' }}
          />
        </div>
        <div className="idea-value">
          {formatSecurityDataValue(priceTarget, ideaUsingPriceTarget ? 'price_target' : 'expected_return')}
        </div>
        {ideaUsingPriceTarget && (
          <div className="idea-sub-value" style={ideaUsingPriceTarget ? { paddingBottom: '5px' } : {}}>
            {formatSecurityDataValue(expectedReturn, 'expected_return')}
          </div>
        )}
        {!ideaUsingPriceTarget && (
          <div className="idea-sub-value" style={ideaUsingPriceTarget ? { paddingBottom: '5px' } : {}}>
            {' '}
          </div>
        )}
      </div>
      <div className="conviction">
        <div className="idea-label">
          Conviction
          <InfoIcon
            word={`tooltip_conviction`}
            symbol={(security || {}).symbol}
            position={screenWidth <= 750 ? 'right' : 'default'}
            style={{ top: '0px' }}
          />
        </div>
        <div className="idea-value">{idea.conviction.name}</div>
      </div>
      <div className="expected-dividend">
        <div className="idea-label">
          Expected Dividend
          <InfoIcon word={`tooltip_expected_dividend`} symbol={(security || {}).symbol} style={{ top: '0px' }} />
        </div>
        <div className="idea-value">{formatSecurityDataValue(idea.expected_dividend, 'expected_dividend')}</div>
      </div>
      <div className="thesis">
        <div className="idea-label">
          Thesis
          <InfoIcon
            word={`tooltip_thesis`}
            symbol={(security || {}).symbol}
            position={screenWidth <= 750 ? 'right' : 'default'}
            style={{ top: '0px' }}
          />
        </div>
        <div className="idea-value">
          <span className="info-icon-text-wrapper">
            {idea.thesis ? idea.thesis.name : 'None'}
            {idea.thesis && (
              <InfoIcon
                word={`tooltip_thesis_${idea.thesis.name.toLowerCase()}`}
                symbol={(security || {}).symbol}
                position={screenWidth <= 750 ? 'right' : 'default'}
                style={{ top: '1px' }}
              />
            )}
          </span>
        </div>
      </div>
      <div className="horizon">
        <div className="idea-label">
          Horizon
          <InfoIcon
            word={`tooltip_horizon`}
            symbol={(security || {}).symbol}
            position={screenWidth <= 750 ? 'right' : 'default'}
            style={{ top: '0px' }}
          />
        </div>
        <div className="idea-value">{idea.horizon.name}</div>
      </div>
    </div>
  );
};

export default UserIdeaDiff;
