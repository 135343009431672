import React from 'react';
import { connect } from 'react-redux';
import { getCurrentUserKarmaLabelFromState, getCurrentUserKarmaLabelLongFromState } from '../../selectors/karma';
import KarmaTotalValue from './KarmaTotalValue';
import KarmaLabel from './KarmaLabel';
import { PRODUCT_DISPLAY_FULL_NAME } from '../../appConfig';

class KarmaMoreInfo extends React.PureComponent {
  render() {
    const { text, longText } = this.props;

    return (
      <div style={{ maxWidth: '400px' }}>
        <div className={'text-center'}>
          <KarmaLabel fontSize={'14px'} useLongVersion />
        </div>
        <div className={'text-center'} style={{ marginBottom: '10px' }}>
          <KarmaTotalValue fontSize={'24px'} />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <span>
            {`${text} (${longText}) are a unique ${PRODUCT_DISPLAY_FULL_NAME} feature that allows users to track their progress and engagement with the platform. Users can accumulate ${text} by completing daily Learn & Earn lessons, engaging with community Pros and Cons, or posting their own.`}
          </span>
        </div>
        <div>
          <span>{`${text} serve as a measure of a user’s learning and interaction on ${PRODUCT_DISPLAY_FULL_NAME}. Keep learning and interacting to earn ${text} and enjoy the benefits of your progress!`}</span>
        </div>
      </div>
    );
  }
}

KarmaMoreInfo.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    text: getCurrentUserKarmaLabelFromState(state),
    longText: getCurrentUserKarmaLabelLongFromState(state),
  };
};

export default connect(mapStateToProps)(KarmaMoreInfo);
