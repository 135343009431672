import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import IconButton from '../../components/buttons/IconButton';
import LoadingIcon from '../../components/misc/LoadingIcon';

const InputFormWrapper = styled.div`
  .input-form-input-container {
    position: relative;
  }
  .input-form-input-container .rounded-edge-button {
    position: absolute;
    top: 9px;
    right: 12px;
    height: 28px;
    width: 28px;
    border-radius: 4px;
  }
  .input-form-input-container .rounded-edge-button i {
    position: absolute;
    top: 5px;
    left: 4px;
  }
  .input-form-input-container .rounded-edge-button:hover {
    background: #ddd !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }
  .input-form-input-container .invite-friends-icon i {
    top: 8px;
    left: 8px;
  }
  .input-form-input-container .find-friends-icon i {
    top: 8px;
    left: 8px;
  }
  input {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    padding: 12px 10px 13px 25px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    line-height: 1;
    outline: none;
  }
  .input-form-input-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    background: #eee;
    opacity: 0.6;
    z-index: 1;
  }
`;

class InputForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',

      submitting: false,
    };
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      this._setValue(this.props.defaultValue);
    }
  }

  render() {
    return (
      <InputFormWrapper>
        <form onSubmit={this.handleSubmit}>
          <div className={`input-form-input-container ${this.props.className || ''}`}>
            {this._isSubmitting() && this._renderLoadingState()}
            <input
              type="text"
              value={this.state.inputValue}
              placeholder={this.props.placeholder || ''}
              onChange={this.handleChange}
            />
            {this.props.icon && <IconButton {...this.props.icon} handleClick={this.handleSubmit} />}
          </div>
        </form>
      </InputFormWrapper>
    );
  }

  _renderLoadingState = () => {
    return (
      <div className={`input-form-input-loading`}>
        <LoadingIcon size={'small'} style={{ marginRight: '8px' }} />
        <span>{this._returnLoadingText()}</span>
      </div>
    );
  };

  handleChange = (e) => {
    const value = e.target.value;
    this._setValue(value);
  };

  handleSubmit = (e) => {
    e && e.preventDefault && e.preventDefault(); // hacky but this function also gets called from a button press in addition to form
    $('input').blur();

    if (this._isSubmitting()) {
      console.error('Already submitting form');
      return false;
    }

    const inputValue = this.state.inputValue;
    if (inputValue === '') {
      return false;
    }

    this.startSubmitting();
    const request = this.props.handleSubmit(inputValue);
    if (this.props.clearAfterSubmit) {
      // only used if ignoreAfterSubmitActions is true or if you really want to do this before the request response
      this.clearInput();
    }
    if (!this.props.ignoreAfterSubmitActions) {
      request.then((ajaxWasSuccessful) => {
        this.stopSubmitting();
        if (ajaxWasSuccessful) {
          this.clearInput();
        }
      });
    }
    return request;
  };

  clearInput = () => {
    this.setState({ inputValue: '' });
  };

  _setValue = (value) => {
    this.setState((prevState) => ({
      inputValue: value,
    }));
  };

  startSubmitting = () => {
    if (!this._shouldIgnoreLoadingStates()) {
      this.setState((prevState) => ({
        submitting: true,
      }));
    }
  };

  stopSubmitting = () => {
    if (!this._shouldIgnoreLoadingStates()) {
      this.setState((prevState) => ({
        submitting: false,
      }));
    }
  };

  _isSubmitting = () => this.state.submitting;
  _returnLoadingText = () => this.props.loadingText || 'Submitting';
  _shouldIgnoreLoadingStates = () => this.props.ignoreLoadingStates;
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(InputForm);
