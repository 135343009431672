import { historyAdapter } from '../adapters/historyAdapter';
import {
  ADD_HISTORY_TRANSACTIONS_DATA,
  GET_HISTORY_TRANSACTIONS_DATA,
  ADD_HISTORY_EQUITY_DATA,
  GET_HISTORY_EQUITY_DATA,
  CANCEL_FUNDING_TRANSACTION,
} from '../constants';

export const fetchHistoryTransactionData = () => {
  return function (dispatch) {
    dispatch({
      type: GET_HISTORY_TRANSACTIONS_DATA,
    });
    return historyAdapter.fetchHistoryTransactionData().then((response) => {
      if (response && response.data) {
        dispatch({
          type: ADD_HISTORY_TRANSACTIONS_DATA,
          payload: response.data.history,
        });
      } else {
        console.error('Something went wrong attempting to load transaction history');
      }
    });
  };
};

export const fetchHistoryEquityData = () => {
  return function (dispatch) {
    dispatch({
      type: GET_HISTORY_EQUITY_DATA,
    });
    return historyAdapter.fetchHistoryEquityData().then((response) => {
      if (response && response.data && response.data.equity_history) {
        dispatch({
          type: ADD_HISTORY_EQUITY_DATA,
          payload: response.data,
        });
      } else {
        console.error('Something went wrong attempting to load equity history data');
      }
    });
  };
};

export const fetchHistoryPortfolioMetricsData = (formData) => {
  return function (dispatch) {
    return historyAdapter.fetchHistoryPortfolioMetricsData(formData).then((response) => {
      if (response && response.data) {
        return response.data;
      } else {
        console.error('Something went wrong attempting to load portfolio metrics data');
      }
    });
  };
};

export const cancelFundingTransaction = (id) => {
  return function (dispatch) {
    return historyAdapter.cancelFundingTransaction(id).then((response) => {
      const wasSuccessful = response && response.data && response.data.status && response.data.status === 'canceled';
      if (wasSuccessful) {
        const transaction = response.data.transaction;
        dispatch({
          type: CANCEL_FUNDING_TRANSACTION,
          payload: { id, transaction },
        });
        return {
          success: true,
        };
      } else {
        return {
          error: (response && response.data && response.data.error) || 'Something went wrong. Please try again.',
        };
      }
    });
  };
};
