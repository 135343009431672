import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as Actions from '../../../actions/index';
import PageLoading from '../../../components/PageLoading';
import Button from '../../../components/buttons/Button';
import LoadingIcon from '../../../components/misc/LoadingIcon';
import { useSecurity } from '@src/main/hooks/securities/useSecurity';
import styled from 'styled-components';
import { PriceQuote } from '@src/main/components/securities/PriceQuote';
import { FlatButton } from '@src/main/components/buttons';
import { useIsLiveTrading } from '@src/main/hooks/user';
import { quickFetchSecuritiesData } from '../../../actions/index';
import { LoadingSpinner } from '@src/main/lib/nvstr-common-ui.es';

const IdentifierWrapper = styled.div``;
const SymbolWrapper = styled.div`
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
`;
const NameWrapper = styled.div`
  font-size: 14px;
  line-height: 24px;
  max-width: 240px;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 540px) {
    max-width: 180px;
  }

  @media (max-width: 410px) {
    max-width: 110px;
  }
`;
const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  p {
    margin: 0 !important;
  }
`;
const SeeMorePricingWrapper = styled.div`
  button {
    padding: 4px 0 8px 12px;
  }
`;
const BuyingPowerWrapper = styled.div`
  font-size: 14px;
  line-height: 18px;
`;

const Identifier = ({ securityId, buyingPowerDisplay }) => {
  const { symbol, name } = useSecurity(securityId);

  return (
    <IdentifierWrapper>
      <SymbolWrapper>
        <p>{symbol}</p>
      </SymbolWrapper>
      <NameWrapper>
        <p>{name}</p>
      </NameWrapper>
      <BuyingPowerWrapper>
        <p>{`Buying Power: ${buyingPowerDisplay}`}</p>
      </BuyingPowerWrapper>
    </IdentifierWrapper>
  );
};
const LoadingWrapper = styled.div`
  text-align: center;
`;

export const OrderHeader = ({ securityId, handleSeeMorePricingClick, buyingPowerDisplay }) => {
  const dispatch = useDispatch();
  const { name } = useSecurity(securityId);
  const isLiveTrading = useIsLiveTrading();

  React.useEffect(() => {
    if (!name) {
      quickFetchSecuritiesData([securityId])(dispatch);
    }
  }, []);

  if (!name) {
    return (
      <LoadingWrapper>
        <LoadingSpinner />
      </LoadingWrapper>
    );
  }

  return (
    <div>
      <TopWrapper>
        <Identifier securityId={securityId} buyingPowerDisplay={buyingPowerDisplay} />
        <div>
          <PriceQuote securityId={securityId} />
          {isLiveTrading && (
            <SeeMorePricingWrapper>
              <FlatButton transparent onClick={handleSeeMorePricingClick}>
                See More Pricing
              </FlatButton>
            </SeeMorePricingWrapper>
          )}
        </div>
      </TopWrapper>
    </div>
  );
};

export default OrderHeader;
