import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const paymentAdapter = {
  getPaymentConfig: () => {
    return sendRequest('get', [`${URL}/subscription/config`]);
  },

  getANetKeys: () => {
    return sendRequest('get', [`${URL}/payment_sources/config`]);
  },

  getSubscriptionData: () => {
    return sendRequest('get', [`${URL}/subscription`]);
  },

  updateSubscriptionData: (token, billingInfo) => {
    const body = {
      payment_token: token,
      billing_info: billingInfo,
    };
    return sendRequest('patch', [`${URL}/subscription`, body]);
  },

  sendAnetCreditCardPaymentTokenForSubscriptionPayment: (token, billingInfo) => {
    const body = {
      payment_token: token,
      billing_info: billingInfo,
    };
    return sendRequest('post', [`${URL}/subscription`, body]).then((response) => response);
  },

  cancelSubscription: () => {
    return sendRequest('post', [`${URL}/subscription/cancel`]).then((response) => response);
  },

  getPaymentSourcesData: () => {
    return sendRequest('get', [`${URL}/payment_sources`]);
  },

  // TODO API not implemented yet
  // updatePaymentSourceData: (token, billingInfo) => {
  //   const body = {
  //     payment_token: token,
  //     billing_info: billingInfo
  //   }
  //   return sendRequest('patch', [`${URL}/payment_sources`, body]);
  // },

  sendAnetCreditCardPaymentTokenForPaymentSource: (token, billingInfo) => {
    const body = {
      payment_token: token,
      billing_info: billingInfo,
    };
    return sendRequest('post', [`${URL}/payment_sources`, body]).then((response) => response);
  },

  // TODO API not implemented yet
  // removePaymentSource: () => {
  //   return sendRequest('post', [`${URL}/payment_sources/cancel`])
  //     .then(response => response)
  // },
};
