import { ADD_TOUR_DATA, UPDATE_TOUR_DATA } from '../constants';

const defaultState = {
  loaded: false,
  tour_step: null,
  is_on_tour: null,
};

export default function tourReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_TOUR_DATA:
      return {
        ...state,
        loaded: true,
        ...action.payload,
      };

    case UPDATE_TOUR_DATA:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
