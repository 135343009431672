import React from 'react';

const ThumbsDown = (props) => {
  const id = props.id || 'thumbs-up';
  const fill = '#fff'; // set by css color template
  const border = '#fff'; // set by css color template
  const height = props.height || '20px';
  const width = props.width || '20px';
  return (
    <svg
      className="thumbs-down-svg"
      height={height}
      width={width}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Thumbs Down</title>
      <defs></defs>
      <g id={`g-${id}`} stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect id={`rect-${id}`} fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="16" height="16" />
        <path
          id={`cuff-${id}`}
          d="M16.5,2.5 C16.2238576,2.5 16,2.72385763 16,3 L16,11.4998859 C16,11.7760282 16.2238576,11.9998859 16.4999998,11.9998859 L18.499992,11.9998851 C18.7761342,11.9998851 18.9999918,11.7760277 18.999992,11.4998854 L19,2.99999924 C19,2.72385686 18.7761424,2.49999924 18.5000002,2.49999924 L16.5000002,2.5 Z"
          stroke={border}
          fill={fill}
          transform="translate(17.500000, 7.250000) scale(-1, -1) translate(-17.500000, -7.250000) "
        />
        <path
          id={`hand-${id}`}
          d="M1.5,17 C1.5,17.2761424 1.72385763,17.5 2,17.5 L10.0553824,17.5 C10.2680548,17.5 10.4574546,17.3654689 10.5274917,17.1646597 L13.4920746,8.66465966 C13.5105369,8.61172486 13.5199653,8.55606203 13.5199653,8.5 C13.5199653,8.22385763 13.2961076,8 13.0199653,8 L6.87669197,8 L7.78181697,3.92693751 C7.88803948,3.44893623 7.74268962,2.9497964 7.39644661,2.60355339 C7.17749519,2.38460197 6.82250481,2.38460197 6.60355339,2.60355339 L1.64644661,7.56066017 C1.55267842,7.65442836 1.5,7.78160532 1.5,7.91421356 L1.5,17 Z"
          stroke={border}
          fill={fill}
          transform="translate(7.509983, 9.969670) scale(-1, -1) translate(-7.509983, -9.969670) "
        />
      </g>
    </svg>
  );
};

export default ThumbsDown;
