import React from 'react';
import LoadingIcon from '../misc/LoadingIcon';
import ChartContainer from '../Charts/ChartContainer';
import { CustomDataWrapper } from '../../containers/DataWrappers/CustomDataWrapper';
import { truncateName } from '../../helpers/ideaVisualizerHelpers';
import { getFilterDisplayName } from '../../constants/finData';
import { findIndexOfObjectInArray } from '../../helpers/generalHelpers';
import { userHasIdeaForSecurity } from '../../helpers/ideaHelpers';
import { getIdeaPhraseFor } from '../../helpers/terminologyHelpers';
import { logMetricsTrackingEvent } from '../../actions';
import { FlatButton, SkeletonButton } from '../../main/components/buttons';
import styled from 'styled-components';

const CardWrapper = styled.div`
  background: ${({ theme }) => theme.themeColors.componentNoOpacity};
  border: 1px solid ${({ theme }) => theme.themeColors.border};

  .x-axis-label {
    color: ${({ theme }) => theme.themeColors.secondaryText};
  }
  p {
    color: ${({ theme }) => theme.themeColors.text};
  }
`;
const IdeaButtonWrapper = styled.div`
  font-size: 14px;
  line-height: 16px;

  button {
    padding: 4px 10px;
  }
`;
const TradeButtonWrapper = styled.div`
  font-size: 14px;
  line-height: 16px;

  button {
    width: 60px;
    padding: 4px 8px;
  }
`;
const FinancialsWrapper = styled.div`
  background-color: ${({ theme }) => theme.themeColors.appBackground};
  color: ${({ theme }) => theme.themeColors.text};
  margin: 4px 0px;
  font-size: 14px;
  line-height: 18px;

  .field {
    font-size: 9px;
    line-height: 12px;
  }
`;

export const Security = (props) => {
  const {
    isLoading,

    security,
    position,
    width,
    currentUserHasEquity,

    hideSourceCard,
    handleSecurityCardCollapse,

    currentUserId,
    ideas,
    addIdea,

    filters,

    navigate,
  } = props;

  if (isLoading) {
    return (
      <CardWrapper
        className={`
          portfolio-explorer-security-container
          portfolio-explorer-result-card-background
          border-accent
          idea-card
          ${hideSourceCard ? 'hidden' : ''}
        `}
        style={{
          width: `${width || 160}px`,
          height: '300px',
          ...(position || {}),
        }}
      >
        <span className={'tile-symbol'}>
          <LoadingIcon icon="fading-3balls" size="medium" />
        </span>
      </CardWrapper>
    );
  }

  const style = position;
  const securityId = security.security_id;
  const symbol = security.symbol ? security.symbol.toUpperCase() : '';
  const longSymbol = security.symbol.length > 4 ? 'long-symbol' : '';
  const companyName =
    !!security && security.name ? (security.name.length > 14 ? truncateName(security.name, 14) : security.name) : '';
  const isHalted = security.is_halted;

  const expandSecurityCard = (e, additionalProps) => {
    const securityCard = {
      panel: 'security',
      securityId: securityId,
      ...additionalProps,
    };
    props.expandSecurityCard(securityCard);
  };

  const handleTradeClick = (orderType) => {
    if (handleSecurityCardCollapse) {
      handleSecurityCardCollapse();
    }
    navigate(`/orders?operation=${orderType.toLowerCase()}&security_id=${securityId}`);
  };

  const handleBuyClick = () => {
    handleTradeClick('buy');
    const event = 'Clicked Trade Button In Porfolio Explorer Security Card';
    const properties = {
      Operation: 'Buy',
      'Security Id': securityId,
      'Security Symbol': symbol,
    };
    logMetricsTrackingEvent(event, properties)();
  };

  const handleSellClick = () => {
    handleTradeClick('sell');
    const event = 'Clicked Trade Button In Porfolio Explorer Security Card';
    const properties = {
      Operation: 'Sell',
      'Security Id': securityId,
      'Security Symbol': symbol,
    };
    logMetricsTrackingEvent(event, properties)();
  };

  const viewIdea = (e) => {
    const event = 'Clicked View Idea';
    const properties = {
      'Stock Symbol': symbol,
      'Security ID': securityId,
      Context: 'Security Card Button',
    };
    logMetricsTrackingEvent(event, properties)();
    const activeTab = 'ideas';
    expandSecurityCard(e, { activeTab });
  };

  const returnFinancialFilters = () => {
    const defaultFinancialFilters = [
      { name: 'Price / Earnings' },
      { name: 'Market Cap' },
      { name: "On Connections' Watchlists" },
    ];
    const financialFilters =
      filters &&
      filters.length > 0 &&
      !(
        filters.length === 1 &&
        (filters[0].name === 'Collective Intelligence' || filters[0].name === 'Avg. Correlation')
      )
        ? filters.filter((f, i) => findIndexOfObjectInArray('name', f, filters) == i)
        : defaultFinancialFilters;

    // add additional filters if not already added
    if (financialFilters.every((filter) => filter.name !== 'Price / Earnings')) {
      financialFilters.push({ name: 'Price / Earnings' });
    }
    if (financialFilters.every((filter) => filter.name !== 'Market Cap')) {
      financialFilters.push({ name: 'Market Cap' });
    }
    return financialFilters;
  };

  return (
    <CardWrapper
      id={securityId}
      className={`
        portfolio-explorer-security-container
        border-accent
        portfolio-explorer-result-card-background
        idea-card
        large-security-tile
        ${hideSourceCard ? 'hidden' : ''}
      `}
      style={{
        width: `${props.width || 160}px`,
        height: currentUserHasEquity ? '325px' : '300px',
        ...style,
      }}
      onClick={expandSecurityCard}
    >
      <p className={`tile-symbol ${longSymbol}  `}>{symbol}</p>
      <p className="tile-company-name secondary-text-color">{companyName}</p>
      <div className={'large-security-info-container'}>
        <ChartContainer
          securityId={securityId}
          symbol={symbol}
          defaultTimePeriod={'one_year'}
          showXAxisLabel={'1 Year'}
          lockTimePeriod
          applyPaddingToYAxisMinMax
          chartStyle={'minimal'}
        />
        <FinancialsWrapper className="security-filter-financials-container">
          <div className="security-filter-financials gradient">
            {returnFinancialFilters().map((field) => {
              return (
                <CustomDataWrapper
                  key={getFilterDisplayName(field.name, 'reverse') + '_' + symbol}
                  securityId={securityId}
                  propertyDisplayName={field.name}
                  propertyKeyName={getFilterDisplayName(field.name, 'reverse')}
                />
              );
            })}
          </div>
        </FinancialsWrapper>
        <IdeaButtonWrapper>
          {userHasIdeaForSecurity(currentUserId, securityId, ideas) ? (
            <FlatButton width={100} stopPropagation onClick={viewIdea}>
              {getIdeaPhraseFor('view')}
            </FlatButton>
          ) : (
            <FlatButton width={100} stopPropagation onClick={addIdea}>
              {getIdeaPhraseFor('add')}
            </FlatButton>
          )}
        </IdeaButtonWrapper>
        {currentUserHasEquity && !isHalted && (
          <TradeButtonWrapper className="portfolio-explorer-security-card-trade-buttons">
            <SkeletonButton stopPropagation onClick={handleBuyClick}>
              Buy
            </SkeletonButton>
            <SkeletonButton stopPropagation onClick={handleSellClick}>
              Sell
            </SkeletonButton>
          </TradeButtonWrapper>
        )}
        {isHalted && (
          <div className="portfolio-explorer-security-card-halted">
            Trading in this security is currently halted by the exchange.
          </div>
        )}
      </div>
    </CardWrapper>
  );
};

export default Security;
