import React from 'react';
import { SUPPORT_EMAIL } from '@src/appConfig';

const NvstrCompanyInfo = (props) => {
  const { className } = props;
  const streetAddress = '135 Madison Avenue';
  const cityAddress = 'New York, NY 10016';
  const phoneNumber = '844 - 466 - 8787';
  const emailAddress = SUPPORT_EMAIL;
  const email = <a href={`mailto:${emailAddress}`}>{emailAddress}</a>;
  return (
    <div className={`nvstr-company-info-container ${className}`}>
      <p>{streetAddress}</p>
      <p>{cityAddress}</p>
      <p>{email}</p>
    </div>
  );
};

export default NvstrCompanyInfo;
