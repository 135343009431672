import {
  GET_LIVE_TRADING_ACCOUNT,
  LIVE_TRADING_SUBMIT_STATUS,
  LIVE_TRADING_SUBMIT_FORM,
  LIVE_TRADING_ACCOUNT_UPDATE,
  REJECT_LIVE_TRADING_ACCOUNT,
  GET_BANK_ACCOUNTS,
  ADD_BANK_ACCOUNT,
  REMOVE_BANK_ACCOUNT,
  VERIFY_BANK_ACCOUNT_APPROVED,
  VERIFY_BANK_ACCOUNT_MAX_ATTEMPTS_REACHED,
  CREATE_BANK_ACCOUNT_NOTICE,
  CLEAR_BANK_ACCOUNT_NOTICE,
  CREATE_FUNDING_FORM_NOTICE,
  CLEAR_FUNDING_FORM_NOTICE,
  TRANSACTION_ACCEPTED,
  START_LINKING_PLAID_ACCOUNT,
  CANCEL_LINKING_PLAID_ACCOUNT,
  SHOW_MODAL,
} from '../constants';

import { serializeApi } from '../helpers/apiHelpers';

import { liveAccountAdapter } from '../adapters/liveAccountAdapter';
import { logErrorEvent } from './errorReportingActions';

import { createBasicErrorModal } from '../constants/modals';
import { PROMOTIONS } from '../constants/types/promotions';
import { formatForDisplay } from '../helpers/displayHelpers';
import { INTERNAL_STORAGE_KEYS, InternalStorage } from '../utils/storage';
import { isSSNObfuscated } from '../helpers/generalHelpers';
import { SOCURE_DECISION_STATUS, SOCURE_DOCUMENT_TYPE_FLAGS } from '../constants/types/Socure';

import { getIsIdentifyServiceOnCooldown, updateSocureFlowData } from '../utils/liveTrading';
import { PRODUCT_NAME, PRODUCT_NAMES } from '../appConfig';
import { TrackingEvents } from '../utils/tracking/events';
import * as Sentry from '@sentry/react';

const MAX_ATTEMPTS_ERROR =
  'The amounts you entered are not correct. Reached maximum confirmation failures. Account cancelled.';

export const getLiveTradingAccount = () => {
  return function(dispatch) {
    return liveAccountAdapter.getLiveTradingAccount().then((response) => {
      if (response && response.data) {
        const formData = serializeApi('liveTrading', 'fromApi', response.data);
        dispatch({
          type: GET_LIVE_TRADING_ACCOUNT,
          payload: formData,
        });
        return {
          ok: true,
          liveTradingAccount: formData || {},
        };
      } else {
        return {
          ok: false,
          liveTradingAccount: {},
        };
      }
    });
  };
};

export const getUserAvailableFunds = () => {
  return liveAccountAdapter.getUserAvailableFunds();
};

export const submitLiveTradingSignUpForm = (form, formPageName) => {
  const formData = serializeApi('liveTrading', 'fromFront', form);
  if (
    'social_security_number' in formData &&
    formData.social_security_number &&
    formData.social_security_number.length > 0 &&
    isSSNObfuscated(formData.social_security_number)
  ) {
    delete formData.social_security_number;
  }

  return function(dispatch) {
    dispatch({
      type: LIVE_TRADING_SUBMIT_FORM,
      payload: form,
    });

    return liveAccountAdapter.submitLiveTradingSignUpForm(formData, formPageName).then((response) => {
      const validationError = response && response.data && response.data.error;
      const alreadySubmittedError = 'Your live trading application was already submitted.';
      if (validationError === alreadySubmittedError) {
        window.location.reload();
      }

      if (response && response.data && response.data.status) {
        const formData = serializeApi('liveTrading', 'fromApi', response.data);
        dispatch({
          type: LIVE_TRADING_SUBMIT_STATUS,
          payload: formData,
        });

        if (response.data.status === 'REJECTED') {
          dispatch({
            type: REJECT_LIVE_TRADING_ACCOUNT,
          });
        }
      }
      return response;
    });
  };
};

export const updateLiveTradingAccount = (form) => {
  const formData = serializeApi('liveTrading', 'fromFront', form);
  if (
    'social_security_number' in formData &&
    formData.social_security_number &&
    formData.social_security_number.length > 0 &&
    isSSNObfuscated(formData.social_security_number)
  ) {
    delete formData.social_security_number;
  }

  return function(dispatch) {
    return liveAccountAdapter.updateLiveTradingAccount(formData).then((response) => {
      dispatch({
        type: LIVE_TRADING_SUBMIT_STATUS,
        payload: response,
      });

      if (response && response.message === 'Account updates were successfully submitted.') {
        dispatch({
          type: LIVE_TRADING_ACCOUNT_UPDATE,
          payload: form,
        });
      }

      return response;
    });
  };
};

export const getBankAccounts = (config) => {
  return function(dispatch) {
    return liveAccountAdapter.getBankAccounts().then((responseData) => {
      if (responseData) {
        const serializedResponseData = serializeApi('funding', 'fromApi', responseData);

        dispatch({
          type: GET_BANK_ACCOUNTS,
          payload: serializedResponseData,
        });
        return { ok: true, data: serializedResponseData };
      } else {
        if (config && config.ignoreError) {
          return { error: true };
        } else {
          return { error: true };
        }
      }
    });
  };
};

export const submitAddBankAccountForm = (form) => {
  return function(dispatch) {
    return liveAccountAdapter.submitAddBankAccountForm(form).then((response) => {
      if (response?.status === 200) {
        const { data } = response;
        const { error } = data;
        if (error) {
          return { error: 'Something went wrong. Please try again.' };
        }

        dispatch({
          type: ADD_BANK_ACCOUNT,
          payload: data,
        });
        return { ok: true, data };
      } else {
        return { error: 'Something went wrong. Please try again.' };
      }
    });
  };
};

export const linkAccountWithPlaid = (params) => {
  return function(dispatch) {
    dispatch({
      type: CLEAR_FUNDING_FORM_NOTICE,
    });
    return liveAccountAdapter.linkAccountWithPlaid(params).then((response) => {
      if (response && !('error' in response)) {
        const serializedResponseData = serializeApi('funding', 'fromApi', response);
        dispatch({
          type: ADD_BANK_ACCOUNT,
          payload: serializedResponseData,
        });

        return { ok: true, ...response };
      } else {
        let message = 'Something went wrong. Please try again.';
        if (response && response.error) {
          message = response.error;
        }
        const notice = { message, style: 'error' };
        dispatch({
          type: CREATE_FUNDING_FORM_NOTICE,
          payload: notice,
        });

        return { error: true, ...response };
      }
    });
  };
};

export const patchInstantLinkBankAccount = (params) => {
  return function(dispatch) {
    return liveAccountAdapter.patchInstantLinkBankAccount({ data: params, dispatch }).then((response) => {
      const { status, data, errorMessage } = response;
      // always return true for now, nbd if this fails
      return {
        ok: true,
        bankAccountId: params.tornadoBankAccountId,
        available_balance_scale: null,
      };
      //   if (status === 200) {
      //     dispatch({
      //       type: ADD_BANK_ACCOUNT,
      //       payload: data,
      //     });
      //     return {
      //       ok: true,
      //       bankAccountId: data.account.id,
      //       available_balance_scale: data.account.available_balance_scale,
      //     };
      //   } else {
      //     const message = data && data.error ? data.error : errorMessage;
      //     return {
      //       error: true,
      //       errorMessage: message,
      //     };
      //   }
    });
  };
};

export const startLinkingAccountWithPlaid = () => {
  return function(dispatch) {
    dispatch({
      type: CLEAR_FUNDING_FORM_NOTICE,
    });
    dispatch({
      type: START_LINKING_PLAID_ACCOUNT,
    });
  };
};

export const cancelLinkingAccountWithPlaid = () => {
  return function(dispatch) {
    dispatch({
      type: CANCEL_LINKING_PLAID_ACCOUNT,
    });
  };
};

export const removeBankAccount = (id) => {
  return function(dispatch) {
    return liveAccountAdapter.removeBankAccount(id).then((response) => {
      const { status, errorMessage } = response;
      if (status === 200) {
        dispatch({
          type: REMOVE_BANK_ACCOUNT,
          payload: { id },
        });
        return { ok: true };
      } else {
        return {
          errorMessage: response?.data?.error || 'Something went wrong. Please try again later.',
        };
      }
    });
  };
};
export const getDepositPickerAmounts = () => {
  return function(dispatch) {
    return liveAccountAdapter.getDepositPickerAmounts().then((response) => {
      const { status, data } = response;
      if (status === 200) {
        const { accounts } = data;
        return { ok: true, accounts };
      } else {
        return { errorMessage: response?.data?.error || 'Something went wrong. Please try again later.' };
      }
    });
  };
};

export const removeFundingAccountNotice = () => {
  return function(dispatch) {
    dispatch({
      type: CLEAR_FUNDING_FORM_NOTICE,
    });
  };
};

export const getLiveAccountDocumentationRequirements = () => {
  return liveAccountAdapter.getLiveAccountDocumentationRequirements().then((response) => {
    if (response && response.data && !response.error) {
      return {
        ok: true,
        data: response.data,
      };
    }
    return {
      error: 'Something went wrong',
    };
  });
};

export const submitLiveAccountDocument = (formData) => {
  return liveAccountAdapter.submitLiveAccountDocument(formData).then((response) => {
    if (response?.status === 200) {
      return {
        ok: true,
        docData: response.data,
      };
    } else {
      const errorMessage = response?.data?.error || 'Something went wrong. Please try again.';
      return {
        ok: false,
        error: errorMessage,
      };
    }
  });
};

export const fetchFurtherDocumentationStatus = (ignore401) => {
  const defaultClearResponse = {
    decision: null,
    image_capture_qr_code: null,
    image_capture_url: null,

    requires_ssn_document: null,
    requires_non_us_citizen_document: null,
  };

  const isOnCooldown = getIsIdentifyServiceOnCooldown();

  if (isOnCooldown) {
    const response = updateSocureFlowData(defaultClearResponse);
    return Promise.resolve(response);
  }

  // TODO: remove this testing logic
  // return Promise.resolve({
  //   status: 200,
  //   data: {
  //     decision: 'refer',
  //     // decision: 'resubmit',
  //     image_capture_url: 'https://verify.socure.com/websdk/alkj1231jkjlas231',
  //     requires_ssn_document: true,
  //     requires_non_us_citizen_document: true,
  //   },
  // }).then((d) => updateSocureFlowData(d.data));

  return liveAccountAdapter.fetchFurtherDocumentationStatus(ignore401).then((response) => {
    if (response && response.data) {
      /*
          {
            decision: {{accept|reject|refer|resubmit|review}},
            image_capture_qr_code: {{base64 image data}},
            image_capture_url: {{url}}
          }
        */
      const { data } = response;
      return {
        ok: true,
        ...updateSocureFlowData(data),
      };
    } else {
      return {
        ...defaultClearResponse,
        error: 'Something went wrong. Please try again later.',
      };
    }
  });
};

export const createBankAccountNotice = (id, notice) => {
  return function(dispatch) {
    dispatch({
      type: CLEAR_FUNDING_FORM_NOTICE,
    });
    dispatch({
      type: CREATE_BANK_ACCOUNT_NOTICE,
      payload: { id, message: notice.message, style: notice.style },
    });
  };
};

export const verifyBankAccount = (form) => {
  const { id } = form;

  return function(dispatch) {
    return liveAccountAdapter
      .verifyBankAccount(form)
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: VERIFY_BANK_ACCOUNT_APPROVED,
            payload: { id },
          });
          return { ok: true };
        } else {
          const errorMessage = response?.raw_response?.data?.error || 'Something went wrong. Please try again later.';
          const microDepositAttemptsRemaining = response?.raw_response?.data?.micro_deposit_attempts_remaining;
          return { errorMessage, microDepositAttemptsRemaining };
        }
      })
      .catch((err) => {
        logErrorEvent(err)();
        const message = 'Something went wrong. Please try again later.';
        const errorModal = {
          contentComponent: createBasicErrorModal(message),
          size: 'wide',
          redirect: false,
          dismissable: true,
        };
        dispatch({
          type: SHOW_MODAL,
          payload: errorModal,
        });
      });
  };
};

export const postPaperTransfer = (values) => {
  // const { amount, funding_operation } = values;
  return function(dispatch) {
    return liveAccountAdapter.postPaperTransfer(values).then((response) => {
      if (response?.status === 200) {
        // TODO: enable quick update of equity on f/e
        // dispatch({
        //   type: PAPER_TRANSACTION_ACCEPTED,
        //   payload: {
        //     transactionType: funding_operation,
        //     amount: parseFloat(amount),
        //   },
        // });
        return {
          ok: true,
        };
      } else {
        return {
          error: true,
          errorMessage:
            response?.raw_response?.data?.message ||
            response?.raw_response?.data?.error ||
            response?.data?.error ||
            'Something went wrong, please try again later.',
        };
      }
    });
  };
};

export const depositFunds = (values, allowEmailAuth) => {
  const { amount, account_id } = values;
  return async function(dispatch) {
    const response = await (allowEmailAuth
      ? liveAccountAdapter.depositFundsEmailAuth(values)
      : liveAccountAdapter.depositFunds(values));
    const { status } = response;
    if (status === 200) {
      const { message, reward } = response.data;
      const { public_token: plaidAccountToken, error } = response.data;
      const errorMessage = error || 'Something went wrong. Please try again.';

      const reauthErrorMessage = 'Please reauthenticate with your financial institution.';
      if (errorMessage === reauthErrorMessage) {
        return {
          error: true,
          reauthPlaid: true,
          needsPlaidReauth: true,
          plaidAccountToken,
          errorMessage,
        };
      }

      let noticeMessage = message;
      let additionalReturnData = {};
      const automaticLimitedTimeBonuses = [
        PROMOTIONS.LIMITED_TIME_DEPOSIT_BONUS.FUNDING_PROMO_CASH_15_FOR_100,
        PROMOTIONS.LIMITED_TIME_DEPOSIT_BONUS.FUNDING_PROMO_CASH_20_FOR_20,
        PROMOTIONS.LIMITED_TIME_DEPOSIT_BONUS.FUNDING_PROMO_CASH_50_FOR_1000,
      ];
      const wasLimitedTimeBonusApplied = reward && automaticLimitedTimeBonuses.includes(reward.reward_type);
      const wasFundingRewardCardFlipEarned = reward && reward.is_card_flip;
      if (wasLimitedTimeBonusApplied) {
        const rewardAmount = formatForDisplay(reward.reward_amount, 'int');
        noticeMessage = `You'll get the $${rewardAmount} reward when the deposit completes. ${noticeMessage}`;
      }
      if (wasFundingRewardCardFlipEarned) {
        additionalReturnData = {
          ...additionalReturnData,
          gotoFundingRewardRedemption: true,
          depositSuccessMessage: message,
        };
      }

      dispatch({
        type: TRANSACTION_ACCEPTED,
        payload: {
          transactionType: 'deposit',
          amount: parseFloat(amount),
        },
      });

      return {
        ok: true,
        ...additionalReturnData,
        noticeMessage,
      };
    } else {
      let data = response.data || response.raw_response?.data || null;
      const errorMessage = data?.error || 'Something went wrong. Please try again.';

      const reauthErrorMessage = 'Please reauthenticate with your financial institution.';
      if (errorMessage === reauthErrorMessage) {
        return {
          error: true,
          reauthPlaid: true,
          needsPlaidReauth: true,
          plaidAccountToken: data?.public_token,
          errorMessage,
        };
      }

      return {
        error: true,
        errorMessage:
          response?.raw_response?.data?.message ||
          response?.raw_response?.data?.error ||
          response?.data?.error ||
          'Something went wrong, please try again later.',
      };
    }
  };
};

export const withdrawFunds = (values, acceptedWarnings) => {
  const { amount, account_id } = values;

  return function(dispatch) {
    return liveAccountAdapter
      .withdrawFunds(values, acceptedWarnings)
      .then((response) => {
        if (response?.data?.warning || response?.raw_response?.data?.warning) {
          return { warning: true, warningMessage: response?.data?.warning || response?.raw_response?.data?.warning };
        }

        if (response?.status === 200) {
          const { data } = response;
          const { public_token: plaidAccountToken, error } = data;

          const errorMessage = error || 'Something went wrong, please try again later.';
          const reauthErrorMessage = 'Please reauthenticate with your financial institution.';
          if (errorMessage === reauthErrorMessage || error) {
            // debug
            const scopeData = {
              status: response?.status,
              data: response?.data,
              error: response?.error,
              raw_response: response?.raw_response,
            };
            const scope = new Sentry.Scope().setContext('state', scopeData);
            Sentry.captureException(new Error('API Error in withdraw'), () => scope);
            // debug end

            return {
              error: true,
              reauthPlaid: true,
              needsPlaidReauth: true,
              plaidAccountToken,
              errorMessage,
            };
          }

          dispatch({
            type: TRANSACTION_ACCEPTED,
            payload: {
              transactionType: 'withdrawal',
              amount: parseFloat(amount),
            },
          });

          return {
            ok: true,
          };
        } else {
          let data = response?.data || response.raw_response?.data || null;
          const errorMessage = data?.error || 'Something went wrong, please try again later.';

          // debug
          const scopeData = {
            status: response?.status,
            data: response?.data,
            error: response?.error,
            raw_response: response?.raw_response,
          };
          const scope = new Sentry.Scope().setContext('state', scopeData);
          Sentry.captureException(new Error('API Error in withdraw'), () => scope);
          // debug end

          return { error: true, errorMessage };
        }
      })
      .catch((err) => {
        logErrorEvent(err, { inWithdraw: true })();
        const message = 'Something went wrong, please try again later.';
        return {
          error: true,
          errorMessage: message,
        };
      });
  };
};

// DEPRECATED
export const sendBankAccountTransaction = (id, form, acceptWarnings) => {
  const serializedFormData = serializeApi('funding', 'fromFront', form);

  return function(dispatch) {
    dispatch({
      type: CLEAR_FUNDING_FORM_NOTICE,
    });
    return liveAccountAdapter
      .sendBankAccountTransaction(id, serializedFormData, acceptWarnings)
      .then((response) => {
        if (!response) {
          return {
            error: true,
            errorMessage: 'Something went wrong, please try again later.',
          };
        }

        const { public_token, error, warning, message, reward } = response;

        if (warning) {
          let result = {
            error: true,
            errorMessage: 'Something went wrong, please try again later.',
          };
          if (response && response.warning) {
            result = { warning: true, errorMessage: response.warning };
          }
          return result;
        }

        const success = !error;
        if (success) {
          let noticeMessage = message;
          let additionalReturnData = {};

          const automaticLimitedTimeBonuses = [
            PROMOTIONS.LIMITED_TIME_DEPOSIT_BONUS.FUNDING_PROMO_CASH_15_FOR_100,
            PROMOTIONS.LIMITED_TIME_DEPOSIT_BONUS.FUNDING_PROMO_CASH_20_FOR_20,
            PROMOTIONS.LIMITED_TIME_DEPOSIT_BONUS.FUNDING_PROMO_CASH_50_FOR_1000,
          ];
          const wasLimitedTimeBonusApplied = reward && automaticLimitedTimeBonuses.includes(reward.reward_type);
          const wasFundingRewardCardFlipEarned = reward && reward.is_card_flip;

          if (wasLimitedTimeBonusApplied) {
            const rewardAmount = formatForDisplay(reward.reward_amount, 'int');
            noticeMessage = `You'll get the $${rewardAmount} reward when the deposit completes. ${noticeMessage}`;
          }

          const { funding_operation, amount } = form;

          dispatch({
            type: TRANSACTION_ACCEPTED,
            payload: {
              transactionType: funding_operation === `From ${PRODUCT_NAMES.Tornado}` ? 'withdrawal' : 'deposit',
              amount: parseFloat(amount),
            },
          });
          dispatch({
            type: CREATE_BANK_ACCOUNT_NOTICE,
            payload: { id, message: noticeMessage, style: 'success' },
          });

          if (wasFundingRewardCardFlipEarned) {
            additionalReturnData = {
              ...additionalReturnData,
              gotoFundingRewardRedemption: true,
              depositSuccessMessage: message,
            };
          }

          return {
            ...additionalReturnData,
            success: true,
            ok: true,
          };
        } else {
          const message = error || 'Something went wrong, please try again later.';
          const reauthErrorMessage = 'Please reauthenticate with your financial institution.';
          const needsPlaidReauthentication = message === reauthErrorMessage;
          const plaidAccountToken = public_token;
          if (needsPlaidReauthentication) {
            return {
              error: true,
              reauthPlaid: true,
              plaidAccountToken,
              errorMessage: message,
            };
          } else {
            dispatch({
              type: CREATE_BANK_ACCOUNT_NOTICE,
              payload: { id, message, style: 'error' },
            });
            return { error: true, errorMessage: message };
          }
        }
      })
      .catch((err) => {
        logErrorEvent(err)();
        const message = 'Something went wrong, please try again later.';
        dispatch({
          type: CREATE_BANK_ACCOUNT_NOTICE,
          payload: { id, message, style: 'error' },
        });
        console.error(err);
        return {
          error: true,
          errorMessage: message,
        };
      });
  };
};

export const getFundingRewardAmount = async (amounts) => {
  const response = await liveAccountAdapter.getFundingRewardAmount(amounts);
  if (response?.status === 200) {
    return {
      ok: true,
      data: response.data,
    };
  } else {
    return {
      ok: false,
    };
  }
};

export const postFundingRewardAmount = async (amounts) => {
  const normalizedAmounts = Array.isArray(amounts) ? amounts : [amounts];
  const response = await liveAccountAdapter.postFundingRewardAmount(normalizedAmounts);
  if (response?.status === 200) {
    return {
      ok: true,
      data: response.data,
    };
  } else {
    return {
      ok: false,
    };
  }
};

export const clearBankAccountNotice = (accountId) => {
  return function(dispatch) {
    dispatch({
      type: CLEAR_BANK_ACCOUNT_NOTICE,
      payload: { id: accountId },
    });
  };
};

export const createRecurringDeposit = (data, allowEmailAuth) => {
  return function(dispatch) {
    return liveAccountAdapter.createRecurringDeposit({ dispatch, data, allowEmailAuth }).then((response) => {
      const { status, data, errorMessage } = response;
      if (status === 200) {
        return {
          ok: true,
        };
      } else {
        const message = data && data.error ? data.error : errorMessage;
        return {
          error: true,
          errorMessage: message,
        };
      }
    });
  };
};

export const updateRecurringDeposit = (data) => {
  return function(dispatch) {
    return liveAccountAdapter.updateRecurringDeposit({ dispatch, data }).then((response) => {
      const { status, data, errorMessage } = response;
      if (status === 200) {
        return {
          ok: true,
        };
      } else {
        const message = data && data.error ? data.error : errorMessage;
        return {
          error: true,
          errorMessage: message,
        };
      }
    });
  };
};

export const cancelRecurringDeposit = (account_id) => {
  return function(dispatch) {
    const data = { account_id };
    return liveAccountAdapter.cancelRecurringDeposit({ dispatch, data }).then((response) => {
      const { status, data, errorMessage } = response;
      if (status === 200) {
        return {
          ok: true,
        };
      } else {
        const message = data && data.error ? data.error : errorMessage;
        return {
          error: true,
          errorMessage: message || 'Something went wrong, please try again later.',
        };
      }
    });
  };
};
