import { currentUserAdapter } from '../adapters/currentUserAdapter';

import { serializeResponseForPortfolioData } from '../helpers/portfolioHelpers';
import { serializeApi, normalizeSecurityPriceDataResponse } from '../helpers/apiHelpers';

import { setDefaultUserTrackingProperties } from './trackingActions';
import { UTM_KEYS, UTMTrackingKeys } from '../constants/types/utm';
import { consoleError } from '../helpers/devToolHelpers';

import {
  ADD_CURRENT_USER_QUICK_INFO,
  UPDATE_USER_PROPERTY,
  CONVERT_CURRENT_USER_TO_LIVE_TRADING,
  ADD_PORTFOLIO_DATA,
  UPDATE_PORTFOLIO_DATA,
  UPDATE_EXTERNAL_POSITIONS_DATA,
  START_LOADING_EXTERNAL_POSITIONS_DATA,
  USER_AUTHENTICATION_SUCCESS,
  USER_AUTHENTICATION_FAILURE,
  ADD_EDIT_PROFILE_PAGE,
  REMOVE_EDIT_PROFILE_PAGE,
  ADD_TOUR_DATA,
  ADD_SECURITY_PRICE_DATA,
  SET_SUBSCRIBER,
  UPLOAD_AVATAR_IMAGE,
  DELETE_AVATAR_IMAGE,
  AGREE_TO_BD_TERMS,
  START_MEMBERSHIP,
  UPDATE_KARMA_POINTS,
  OptionsActionTypes,
  colorThemeActions,
} from '../constants';

import { getKarmaTotalFromCurrentUser } from '../helpers/currentUserHelpers';
import {
  buildUserTrackingProperties,
  setTrackingUserProperties,
  setUTMUserProperties,
} from '../utils/tracking/amplitude';
import { requireLogin } from '../helpers/authHelpers';
import { activeSecuritiesTracker, LivePricePollingManager } from '@src/main/classes/LivePricePollingManager';
import { COLOR_THEME_NAMES } from '@src/main/lib/nvstr-utils.es';

export const fetchCurrentUserQuickInfo = (token) => {
  return function (dispatch) {
    return currentUserAdapter.fetchCurrentUserQuickInfo(token).then((response) => {
      const responseData = response && response.data;
      if (responseData && 'users' in responseData && responseData.users.length === 1) {
        const serializedResponse = serializeApi('currentUser', 'fromApi', responseData.users[0]);
        const currentUser = serializedResponse;

        dispatch({
          type: ADD_TOUR_DATA,
          payload: {
            tour_step: serializedResponse.tour_step,
            is_on_tour: serializedResponse.is_on_tour,
          },
        });

        const defaultTrackingProperties = {};
        UTM_KEYS.forEach((key) => {
          const trackingKey = UTMTrackingKeys[key];
          if (key in serializedResponse) {
            defaultTrackingProperties[trackingKey] = serializedResponse[key];
          } else {
            defaultTrackingProperties[trackingKey] = null;
          }
        });
        const whiteLabelKey = 'white_label_partner';
        if (whiteLabelKey in serializedResponse) {
          defaultTrackingProperties['White Label Partner'] = serializedResponse[whiteLabelKey];
        }
        setDefaultUserTrackingProperties(defaultTrackingProperties);
        const userProperties = buildUserTrackingProperties(serializedResponse, serializedResponse);
        setTrackingUserProperties(userProperties);
        setUTMUserProperties(serializedResponse);

        dispatch({
          type: ADD_CURRENT_USER_QUICK_INFO,
          payload: serializedResponse,
        });

        const portfolioData = serializeResponseForPortfolioData(responseData);
        dispatch({
          type: ADD_PORTFOLIO_DATA,
          payload: { portfolioData },
        });

        const positionsAndIdeasSecurities = Object.values(portfolioData.positions).map((p) => ({
          id: p.security_id,
          symbol: p.symbol,
        }));
        activeSecuritiesTracker.addSecurities(positionsAndIdeasSecurities);

        dispatch({
          type: USER_AUTHENTICATION_SUCCESS,
        });

        dispatch({
          type: UPDATE_KARMA_POINTS,
          payload: getKarmaTotalFromCurrentUser(currentUser),
        });

        return serializedResponse;
      } else {
        const didUserBecomeUnauthed = response && response.status === 401;
        if (didUserBecomeUnauthed) {
          console.error('User not authenticated.');
          dispatch({
            type: USER_AUTHENTICATION_FAILURE,
          });
          return {
            error: 'Authentication failure',
            response,
          };
        } else {
          return response;
        }
      }
    });
  };
};

export const refreshCurrentUserPositionsInfo = () => {
  return function (dispatch) {
    return currentUserAdapter.refreshCurrentUserPositionsInfo().then((response) => {
      if (response && 'users' in response && response.users.length === 1) {
        const serializedResponse = serializeApi('currentUser', 'fromApi', response.users[0]);
        const portfolioData = serializeResponseForPortfolioData(response);
        dispatch({
          type: UPDATE_PORTFOLIO_DATA,
          payload: { portfolioData },
        });

        const positions = response.users[0].positions;
        const listOfSecurityIdsInPortfolio = Object.keys(positions);
        const securitiesData = listOfSecurityIdsInPortfolio.map((id) => positions[id]);
        const securitiesPriceData = securitiesData.map((securityData) =>
          normalizeSecurityPriceDataResponse(securityData)
        );
        dispatch({
          type: ADD_SECURITY_PRICE_DATA,
          payload: {
            securitiesPriceData,
          },
        });
        return serializedResponse;
      } else {
        console.error('User not authenticated.');
        return {};
      }
    });
  };
};

export const refreshCurrentUserExternalPositionsInfo = (token) => {
  return function (dispatch) {
    dispatch({
      type: START_LOADING_EXTERNAL_POSITIONS_DATA,
    });
    return currentUserAdapter.refreshCurrentUserExternalPositionsInfo(token).then((response) => {
      const responseData = response && response.data;
      if (responseData && 'user_id' in responseData) {
        const portfolioData = responseData;
        dispatch({
          type: UPDATE_EXTERNAL_POSITIONS_DATA,
          payload: { portfolioData },
        });

        return {};
      } else {
        consoleError(['Error calling refreshCurrentUserExternalPositionsInfo().']);
        return {};
      }
    });
  };
};

export const fetchOptionsPositions = () => {
  return function (dispatch) {
    return currentUserAdapter.fetchOptionsPositions().then((response) => {
      const { data: positions, status } = response;
      if (status === 200) {
        const positionsEntries = Object.entries(positions);
        const positionsFormatted = positionsEntries
          .filter((pEntry) => {
            const [id, position] = pEntry;
            return position.security_id;
          })
          .map((pEntry) => {
            const [id, position] = pEntry;
            return {
              id,
              ...position,
            };
          });

        dispatch({
          type: OptionsActionTypes.updateOptionsPositions,
          payload: positionsFormatted,
        });

        return {
          ok: true,
        };
      } else {
        return {
          ok: false,
          error: 'Something went wrong',
        };
      }
    });
  };
};

export const createOptionsPositions = (params) => {
  return function (dispatch) {
    return currentUserAdapter.createOptionsPositions(params).then((response) => {
      const { data: position, status } = response;
      if (status === 200) {
        // dispatch({
        //   type: OptionsActionTypes.addOptionsPosition,
        //   payload: position,
        // });
        return {
          ok: true,
        };
      } else {
        return {
          ok: false,
          error: 'Something went wrong',
        };
      }
    });
  };
};

export const updateUserProperty = (changedUserFormData) => {
  const serializedProperty = serializeApi('currentUser', 'fromFront', changedUserFormData);
  const key = Object.keys(changedUserFormData)[0];

  return function (dispatch) {
    return currentUserAdapter.updateUserProperty(serializedProperty).then((response) => {
      if (response && response.status === 'updated') {
        dispatch({
          type: UPDATE_USER_PROPERTY,
          payload: { [key]: response[key] },
        });
      } else if (response && response.raw_response && response.raw_response.status === 404) {
        console.warn('not found, redirecting', response);
        const rootUrl = window.origin + '/';
        if (window.location.href !== rootUrl) {
          requireLogin(window);
          return { error: 'Login Required' };
        }
      }
      return response && response.status === 'updated';
    });
  };
};

export const convertCurrentUserToLiveTrading = () => {
  return function (dispatch) {
    dispatch({
      type: CONVERT_CURRENT_USER_TO_LIVE_TRADING,
    });
  };
};

export const setCurrentUserSubscriber = () => {
  return function (dispatch) {
    dispatch({
      type: SET_SUBSCRIBER,
    });
  };
};

export const fetchEditUserProfilePage = () => {
  return function (dispatch) {
    return currentUserAdapter.fetchEditUserProfilePage().then((response) => {
      if (response && response.data) {
        dispatch({
          type: ADD_EDIT_PROFILE_PAGE,
          payload: {
            page: response.data.partial,
          },
        });
      } else {
        console.error('Something went wrong fetching page.');
      }
      return response;
    });
  };
};

export const removeEditUserProfilePage = () => {
  return function (dispatch) {
    dispatch({
      type: REMOVE_EDIT_PROFILE_PAGE,
    });
  };
};

export const postSeenMarginCall = () => {
  return function (dispatch) {
    return currentUserAdapter.postSeenMarginCall();
  };
};

export const changeColorScheme = (desiredColorScheme) => {
  return function (dispatch) {
    dispatch({
      type: colorThemeActions.set,
      payload: desiredColorScheme,
    });
    if (desiredColorScheme === COLOR_THEME_NAMES.custom) {
      return null;
    } else {
      return currentUserAdapter.changeColorScheme(desiredColorScheme);
    }
  };
};

export const postAgreedToBDTerms = () => {
  return function (dispatch) {
    return currentUserAdapter.postAgreedToBDTerms().then((response) => {
      const wasSuccess = response && response.data && response.data.status && response.data.status === 'success';
      if (wasSuccess) {
        dispatch({
          type: AGREE_TO_BD_TERMS,
        });
        return {
          success: true,
        };
      } else {
        return {
          error: 'Something went wrong. Please try again.',
        };
      }
    });
  };
};

export const postAvatarUploadImage = (formData) => {
  return function (dispatch) {
    return currentUserAdapter.postAvatarUploadImage(formData).then((response) => {
      const wasSuccess = response && response.data && response.data.status === 'success';
      const newAvatarImageUrl = response && response.data && response.data.avatar_url;
      if (wasSuccess && newAvatarImageUrl) {
        dispatch({
          type: UPLOAD_AVATAR_IMAGE,
          payload: newAvatarImageUrl,
        });
        return {
          success: true,
        };
      } else {
        return {
          error: 'Could not upload avatar, please try again.',
        };
      }
    });
  };
};

export const deleteAvatarUploadImage = () => {
  return function (dispatch) {
    return currentUserAdapter.deleteAvatarUploadImage().then((response) => {
      const wasSuccess = response && response.data && response.data.status === 'success';
      const newAvatarImageUrl = response && response.data && response.data.avatar_url;
      if (wasSuccess && newAvatarImageUrl) {
        dispatch({
          type: DELETE_AVATAR_IMAGE,
          payload: newAvatarImageUrl,
        });
        return {
          success: true,
        };
      } else {
        return {
          error: 'Could not delete avatar, please try again.',
        };
      }
    });
  };
};

export const changePassword = (currentPassword, newPassword) => {
  return function (dispatch) {
    return currentUserAdapter.changePassword(currentPassword, newPassword).then((response) => {
      const wasRequestSent = response && response.data;
      if (wasRequestSent) {
        const responseData = response.data;
        const wasChangedSuccessfully = responseData.status && responseData.status === 'success';
        if (wasChangedSuccessfully) {
          return {
            success: true,
          };
        } else {
          return {
            error: (response && response.data && response.data.error) || 'Something went wrong. Please try again.',
            status: 'error',
          };
        }
      } else {
        return {
          status: 'error',
          error: 'Something went wrong. Please try again.',
        };
      }
    });
  };
};

export const changeEmail = (currentPassword, newEmail) => {
  return function (dispatch) {
    return currentUserAdapter.changeEmail(currentPassword, newEmail).then((response) => {
      const wasRequestSent = response && response.data;
      if (wasRequestSent) {
        const responseData = response.data;
        const wasChangedSuccessfully = responseData.status && responseData.status === 'success';
        if (wasChangedSuccessfully) {
          return {
            success: true,
          };
        } else {
          return {
            error: (response && response.data && response.data.error) || 'Something went wrong. Please try again.',
            status: 'error',
          };
        }
      } else {
        return {
          status: 'error',
          error: 'Something went wrong. Please try again.',
        };
      }
    });
  };
};

export const closeAccount = () => {
  return function (dispatch) {
    // for f/e testing, can mock api response
    // const error = 'Something went wrong. Check your internet connection and try again.';
    // const actions = null;

    // const error = null;
    // const actions = [
    //   'Sell out of your positions',
    //   'Withdraw your remaining funds',
    //   'Cancel your pending orders'
    // ]
    // const response = {
    //   ok: false,
    //   actions,
    //   error,
    // }
    // const mock = response;
    // return new Promise((resolve, reject) => {
    //   setTimeout(
    //     () => resolve(mock),
    //     1000
    //   )
    // });
    return currentUserAdapter.closeAccount().then((response) => {
      const ok = response && response.data && response.data.status && response.data.status === 'success';
      if (ok) {
        return {
          ok: true,
        };
      } else {
        const hasActionsToCompleteBeforeClose = response && response.data && response.data.actions;

        return {
          ok: false,
          actions: hasActionsToCompleteBeforeClose,
          error: !hasActionsToCompleteBeforeClose
            ? 'Something went wrong. Check your internet connection and try again.'
            : null,
        };
      }
    });
  };
};

export const resendRequiredDocsEmail = () => {
  return function (dispatch) {
    return currentUserAdapter.resendRequiredDocsEmail().then((response) => {
      const wasSuccess = response && response.data && response.data.status && response.data.status === 'success';
      if (wasSuccess) {
        return {
          success: true,
        };
      } else {
        return {
          error: (response && response.data && response.data.error) || 'Something went wrong. Please try again later.',
        };
      }
    });
  };
};

export const startMembership = () => {
  return function (dispatch) {
    return currentUserAdapter.startMembership().then((response) => {
      const wasSuccess = response && response.data && response.data.status && response.data.status === 'success';
      if (wasSuccess) {
        dispatch({
          type: START_MEMBERSHIP,
        });
        return {
          success: true,
        };
      } else {
        return {
          error: 'Something went wrong. Please try again.',
        };
      }
    });
  };
};
