import React from 'react';
import styled from 'styled-components';
import { BaseButton, BaseButtonWrapper } from './BaseButton';
import { Close } from '../../icons';

const ButtonWrapper = styled.div`
  ${BaseButtonWrapper} {
    padding: 4px;
    background: none;
    border: none;
  }
`;

export const CloseButton = ({ onClick }) => {
  return (
    <ButtonWrapper>
      <BaseButton onClick={onClick}>
        <Close width={'18px'} />
      </BaseButton>
    </ButtonWrapper>
  );
};
