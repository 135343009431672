import React from 'react';
import { connect } from 'react-redux';

import { getSubscriptionData, updateSubscriptionData } from '../../actions';
import { showModal } from '../../actions/modalActions';
import { logMetricsTrackingEvent } from '../../actions/trackingActions';

import ANetCreditCard from '../Payment/forms/ANetCreditCard';
import BillingAddress from '../Payment/forms/BillingAddress';
import InComponentSuccessBanner from '../UI/InComponentSuccessBanner';

import Button from '../../components/buttons/Button';
import LoadingIcon from '../../components/misc/LoadingIcon';

import { wasApiRequestSuccessful, returnApiRequestError } from '../../helpers/ajaxHelpers';
import { returnBasicErrorModalPayloadWithCustomMessage } from '../../constants/modals';
import { FlatButton } from '../../main/components/buttons';

class EditSubscriptionBillingForm extends React.Component {
  constructor() {
    super();
    this.state = {
      _isSubmitting: false,

      _initError: false,
      _billingInfoLoadCompleted: false,
      _wasUpdateCompleted: false,

      _showCCForm: false,
      _showAddressForm: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  render() {
    return (
      <div className={`edit-subscription-billing-form-container`}>
        {this.wasPaymentUpdatedSuccessfully() && (
          <InComponentSuccessBanner message={'Updated Successfully'} onDismiss={this._clearPaymentUpdatedSuccess} />
        )}
        {this.shouldShowAForm() ? (
          this.isLoadingBillingInfo() ? (
            <LoadingIcon icon="fading-3balls" size="small" style={{ marginRight: '8px' }} />
          ) : (
            this.renderForm()
          )
        ) : (
          <div className="actions-container">
            <FlatButton onClick={this.handleShowCreditCardForm}>Credit Card</FlatButton>
            <FlatButton onClick={this.handleShowBillingAddressForm}>Billing Address</FlatButton>
          </div>
        )}
      </div>
    );
  }

  renderForm = () =>
    this.shouldShowCCForm() ? (
      <ANetCreditCard
        isSubmitting={this.isSubmitting()}
        startingFormData={this.returnSubscriptionBillingData()}
        submitBtnText={'Save'}
        submitTokenAction={this.handleCreditCardSubmit}
        onPaymentSubmitStart={this.handleFormSubmitStart}
        onPaymentFailure={this.handleUpdateFailure}
        handleCancel={this.handleCancelEdit}
        useIsSubmittingBtn
        hideUseAccountAddress
      />
    ) : (
      <BillingAddress
        isSubmitting={this.isSubmitting()}
        startingFormData={this.returnSubscriptionBillingData()}
        submitBtnText={'Save'}
        onPaymentSubmitStart={this.handleFormSubmitStart}
        handleSubmit={this.handleBillingInfoSubmit}
        handleCancel={this.handleCancelEdit}
        useIsSubmittingBtn
        hideUseAccountAddress
      />
    );

  shouldShowAForm = () => this.shouldShowCCForm() || this.shouldShowAddressForm();

  shouldShowCCForm = () => this.state._showCCForm;

  shouldShowAddressForm = () => this.state._showAddressForm;

  wasInitError = () => this.state._initError;

  handleShowCreditCardForm = () => {
    if (this.wasInitError()) {
      this._showInitFailErrorMessage();
    } else {
      this.setState((prevState) => ({
        _showCCForm: !prevState._showCCForm,
      }));
    }
  };

  handleShowBillingAddressForm = () => {
    if (this.wasInitError()) {
      this._showInitFailErrorMessage();
    } else {
      this.setState((prevState) => ({
        _showAddressForm: !prevState._showAddressForm,
      }));
    }
  };

  handleFormSubmitStart = () => {
    this._setIsSubmitting();
  };

  onBillingInfoLoad = (billingData) => {
    this.cacheStartingBillingData(billingData);
    this.setBillingInfoLoadCompleted();
  };

  cacheStartingBillingData = (billingData) => {
    this.billingData = billingData;
  };

  setBillingInfoLoadCompleted = () => {
    this.setState(() => ({
      _billingInfoLoadCompleted: true,
    }));
  };

  isLoadingBillingInfo = () => !this.state._billingInfoLoadCompleted;

  returnSubscriptionBillingData = () => this.billingData;

  handleBillingInfoSubmit = (formData) => {
    this.sendBillingInfoUpdate(formData);
  };

  handleUpdateComplete = () => {
    this._clearIsSubitting();
    this._setPaymentUpdatedSuccess();
    this.hideForms();
    this.getUpdateSubscriptionBillingData();
  };

  handleUpdateFailure = (errorMessage) => {
    this._clearIsSubitting();
    this._showErrorMessage(errorMessage);
    this._logSubmitPaymentError(errorMessage);
  };

  wasPaymentUpdatedSuccessfully = () => this.state._wasUpdateCompleted;

  _setPaymentUpdatedSuccess = () => {
    this.setState(() => ({
      _wasUpdateCompleted: true,
    }));
  };

  _clearPaymentUpdatedSuccess = () => {
    this.setState(() => ({
      _wasUpdateCompleted: false,
    }));
  };

  isSubmitting = () => this.state._isSubmitting;

  _setIsSubmitting = () => {
    this.setState(() => ({
      _isSubmitting: true,
    }));
  };

  _clearIsSubitting = () => {
    this.setState(() => ({
      _isSubmitting: false,
    }));
  };

  _showErrorMessage = (errorMessage) => {
    showModal(returnBasicErrorModalPayloadWithCustomMessage(errorMessage))(this.props.dispatch);
  };

  handleCreditCardSubmit = (token, billingInfo) => {
    updateSubscriptionData(
      token,
      billingInfo
    )(this.props.dispatch)
      .then((response) => {
        if (response && response.ok) {
          return this.handleUpdateComplete();
        } else {
          const errorMessage = returnApiRequestError(response);
          return this.handleUpdateFailure(errorMessage);
        }
      })
      .catch((err) => console.error('error occurred', err));
  };

  sendBillingInfoUpdate = (formData) => {
    updateSubscriptionData(
      null,
      formData
    )(this.props.dispatch).then((response) => {
      if (response && response.ok) {
        this.handleUpdateComplete();
      } else {
        const errorMessage = returnApiRequestError(response);
        this.handleUpdateFailure(errorMessage);
      }
    });
  };

  handleCancelEdit = () => {
    this.hideForms();
  };

  hideForms = () => {
    this.setState(() => ({
      _showCCForm: false,
      _showAddressForm: false,
    }));
  };

  init = () => {
    getSubscriptionData()(this.props.dispatch).then((response) => {
      if (response && response.ok) {
        const billingData = {
          ...response.billing_info,
          cardHolderFirstName: response.billing_info.first_name,
          cardHolderLastName: response.billing_info.last_name,
          firstName: response.billing_info.first_name,
          lastName: response.billing_info.last_name,
          cardNumber: response.masked_card_number,
        };
        this.onBillingInfoLoad(billingData);
      } else {
        this._setUnableToGetBillingDetails();
        this._showInitFailErrorMessage();
      }
    });
  };

  getUpdateSubscriptionBillingData = () => {
    this.billingData = {};
    this.setState(() => ({
      _billingInfoLoadCompleted: false,
    }));
    getSubscriptionData()(this.props.dispatch).then((response) => {
      if (response && response.ok) {
        const billingData = {
          ...response.billing_info,
          cardHolderFirstName: response.billing_info.first_name,
          cardHolderLastName: response.billing_info.last_name,
          firstName: response.billing_info.first_name,
          lastName: response.billing_info.last_name,
          cardNumber: response.masked_card_number,
        };
        this.onBillingInfoLoad(billingData);
      } else {
        this._setUnableToGetBillingDetails();
        this._showInitFailErrorMessage();
      }
    });
  };

  _showInitFailErrorMessage = () =>
    this._showErrorMessage('Unable to get current billing details. Please refresh and try again.');

  _setUnableToGetBillingDetails = () => {
    this.setState(() => ({
      _initError: true,
    }));
  };

  _logSubmitPaymentError = (errorMessage) => {
    const event = 'Update Subscription Billing Information Failure';
    const properties = {
      Message: errorMessage,
    };
    logMetricsTrackingEvent(event, properties)(this.props.dispatch);
  };
}

export default connect()(EditSubscriptionBillingForm);
