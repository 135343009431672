import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  & > svg {
    width: 18px;
    height: 18px;
  }

  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function ConversationIcon() {
  return (
    <Wrapper>
      <svg viewBox="0 0 18 18" fill="none">
        <path
          d="M11.5 2.333v5.834H3.31l-.492.491-.483.484V2.333H11.5zm.834-1.666H1.5a.836.836 0 00-.833.833v11.667L4 9.833h8.334A.836.836 0 0013.167 9V1.5a.836.836 0 00-.833-.833zM16.5 4h-1.666v7.5H4v1.667c0 .458.375.833.834.833H14l3.334 3.333v-12.5A.836.836 0 0016.5 4z"
          fill="#1F1F1F"
        />
      </svg>
    </Wrapper>
  );
}

export default ConversationIcon;
