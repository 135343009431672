import {
  FORCE_UNDOCK_VERTICAL_NAV,
  RELEASE_FORCE_UNDOCK_VERTICAL_NAV,
  TOGGLE_ALWAYS_OPEN_VERTICAL_NAV,
  TOGGLE_VERTICAL_NAV,
  SHOW_VERTICAL_NAV,
  HIDE_VERTICAL_NAV,
  SET_SHOW_SEARCH_HEADER,
  SET_HIDE_SEARCH_HEADER,
  Config,
} from '../constants';

const defaultState = {
  alwaysOpen: window.$(window).width() > Config.desktopMaxWidth,
  collapsed: true,
  showSearchHeader: false,
};

export default function navReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_SHOW_SEARCH_HEADER:
      return {
        ...state,
        showSearchHeader: true,
      };

    case SET_HIDE_SEARCH_HEADER:
      return {
        ...state,
        showSearchHeader: false,
      };

    case FORCE_UNDOCK_VERTICAL_NAV:
      return {
        ...state,
        alwaysOpen: false,
        collapsed: true,
      };

    case RELEASE_FORCE_UNDOCK_VERTICAL_NAV:
      return {
        ...state,
        ...defaultState,
      };

    case TOGGLE_ALWAYS_OPEN_VERTICAL_NAV:
      return {
        ...state,
        alwaysOpen: !state.alwaysOpen,
      };

    case TOGGLE_VERTICAL_NAV:
      return {
        ...state,
        collapsed: !state.collapsed,
      };

    case SHOW_VERTICAL_NAV:
      return {
        ...state,
        collapsed: false,
      };

    case HIDE_VERTICAL_NAV:
      return {
        ...state,
        collapsed: true,
      };

    default:
      return state;
  }
}
