import React from 'react';
import {
  DashboardVNavRoute,
  DigitalAnalystVNavRoute,
  FundingPageVNavRoute,
  LearnVNavRoute,
  PortfolioPageVNavRoute,
  RewardsPageVNavRoute,
  TradePageVNavRoute,
} from './routeGroups/Portfolio';
import {
  AskACTLVNavRoute,
  ConnectionsVNavRoute,
  ThoughtLeadersVNavRoute,
  TopPerformersVNavRoute,
} from './routeGroups/People';
import { ExplorerVNavRoute, ExploreStocksVNavRoute, WatchlistVNavRoute } from './routeGroups/Ideas';
import {
  EDocumentsVNavRoute,
  HistoryVNavRoute,
  LiveTradingSignupVNavRoute,
  SupportVNavRoute,
  TourVNavRoute,
} from './routeGroups/Account';
import { AdminRoutesGroup } from '@src/containers/Nav/verticalNavComponents/routeGroups/Admin';
import { useCurrentUser } from '@src/main/hooks/user';
import VerticalNavGroup from '@src/containers/Nav/components/VerticalNavGroup';
import VerticalNavItem from '@src/containers/Nav/components/VerticalNavItem';
import DashboardIcon from '@src/main/components/Images/DashboardIcon';

export const VerticalNavRoutes = () => {
  const currentUser = useCurrentUser();
  const isAccountClosed = currentUser.is_account_closed;
  const inNewOrderTestGroup = currentUser.new_vnav_order_test_group === 1;

  if (isAccountClosed) {
    return (
      <>
        <VerticalNavGroup groupName="Portfolio">
          <VerticalNavItem name="Dashboard" customIcon={<DashboardIcon />} route="/" />
        </VerticalNavGroup>

        <VerticalNavGroup groupName="Account">
          <SupportVNavRoute />
          <EDocumentsVNavRoute />
          <HistoryVNavRoute />
        </VerticalNavGroup>
      </>
    );
  }

  if (inNewOrderTestGroup) {
    return (
      <>
        <VerticalNavGroup groupName="Portfolio">
          <DashboardVNavRoute />
          <WatchlistVNavRoute />
          <FundingPageVNavRoute />
          <LiveTradingSignupVNavRoute />
          <LearnVNavRoute />
        </VerticalNavGroup>

        <VerticalNavGroup groupName="Analysis">
          <PortfolioPageVNavRoute />
          <DigitalAnalystVNavRoute />
          <ExplorerVNavRoute />
          <ExploreStocksVNavRoute />
        </VerticalNavGroup>

        <VerticalNavGroup groupName="Account">
          <SupportVNavRoute />
          <EDocumentsVNavRoute />
          <RewardsPageVNavRoute />
          <HistoryVNavRoute />
          <TourVNavRoute />
        </VerticalNavGroup>

        <AdminRoutesGroup />
      </>
    );
  }

  return (
    <>
      <VerticalNavGroup groupName="Portfolio">
        <DashboardVNavRoute />
        <PortfolioPageVNavRoute />
        <TradePageVNavRoute />
        <FundingPageVNavRoute />
        <LearnVNavRoute />
        <RewardsPageVNavRoute />
        <LiveTradingSignupVNavRoute />
      </VerticalNavGroup>

      <VerticalNavGroup groupName="People">
        <AskACTLVNavRoute />
        <ConnectionsVNavRoute />
        <ThoughtLeadersVNavRoute />
        <TopPerformersVNavRoute />
      </VerticalNavGroup>

      <VerticalNavGroup groupName="Ideas">
        <WatchlistVNavRoute />
        <ExploreStocksVNavRoute />
        <ExplorerVNavRoute />
      </VerticalNavGroup>

      <VerticalNavGroup groupName="Account">
        <SupportVNavRoute />
        <EDocumentsVNavRoute />
        <HistoryVNavRoute />
        <TourVNavRoute />
      </VerticalNavGroup>

      <AdminRoutesGroup />
    </>
  );
};
