import { rewardsAdapter } from '../adapters/rewardsAdapter';

import {
  LOADING_REWARDS_DATA,
  GET_REWARDS_DATA,
  GET_REWARDS_DATA_ERROR,
  RECEIVED_REFERRAL_REWARD_REDEMPTION,
  RECEIVED_FUNDING_REWARD_REDEMPTION,
  ADD_USERS,
} from '../constants';

export const getRewardsData = (isUpdating) => {
  return function (dispatch) {
    if (!isUpdating) {
      dispatch({
        type: LOADING_REWARDS_DATA,
      });
    }
    return rewardsAdapter.getRewardsData().then((response) => {
      const wasSuccess = response && response.data;
      if (wasSuccess) {
        const rewardsData = response.data;
        const users = response.data.live_trading_approved_connections;
        dispatch({
          type: ADD_USERS,
          payload: users,
        });
        dispatch({
          type: GET_REWARDS_DATA,
          payload: rewardsData,
        });
        return {
          ok: true,
          data: response.data,
        };
      } else {
        dispatch({
          type: GET_REWARDS_DATA_ERROR,
        });
        return {
          ok: false,
        };
      }
    });
  };
};

const _handlePrepareResponse = (response) => {
  if (!response || !response.data) {
    return {
      error: 'Unable to complete request, please check internet connection.',
    };
  }
  return response.data;
};

export const prepareFundingReward = () => {
  return rewardsAdapter.prepareFundingReward().then((response) => _handlePrepareResponse(response));
};

export const prepareReferralReward = () => {
  return rewardsAdapter.prepareReferralReward().then((response) => _handlePrepareResponse(response));
};

export const redeemFundingReward = () => {
  return function (dispatch) {
    return rewardsAdapter.redeemFundingReward().then((response) => {
      if (response && response.data) {
        const rewardData = response.data;
        dispatch({
          type: RECEIVED_FUNDING_REWARD_REDEMPTION,
          payload: rewardData,
        });
        return {
          ok: true,
          data: rewardData,
        };
      } else {
        return { ok: false };
      }
    });
  };
};

export const redeemReferralReward = () => {
  return function (dispatch) {
    return rewardsAdapter.redeemReferralReward().then((response) => {
      if (response && response.data) {
        const rewardData = response.data;
        dispatch({
          type: RECEIVED_REFERRAL_REWARD_REDEMPTION,
          payload: rewardData,
        });
        return {
          ok: true,
          data: rewardData,
        };
      } else {
        return { ok: false };
      }
    });
  };
};
