import React from 'react';
import UserContainer from '../../../users/UserContainer';
import ProfileAvatar from '../../../../components/user/ProfileAvatar';
import UserName from '../../../../components/user/UserName';
import { PROFILE_CONTEXTS } from '../../../../constants/contextTracking';

const MutualConnectionRow = (props) => {
  const { userId } = props;

  return (
    <div className={'mutual-connection-row'}>
      <UserContainer userId={userId}>
        <ProfileAvatar
          size="nav"
          showNameTag={false}
          profileLinkQuery={`?context=${PROFILE_CONTEXTS.SUGGESTED_CONNECTION}`}
        />
        <UserName
          displayNameStyle={'fullName'}
          profileLinkQuery={`?context=${PROFILE_CONTEXTS.SUGGESTED_CONNECTION}`}
        />
      </UserContainer>
    </div>
  );
};

export default MutualConnectionRow;
