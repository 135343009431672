import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { returnPathTo } from '../../constants/paths';
import { useNavigate } from 'react-router-dom';
import { defineFontSettings } from '../../main/lib/nvstr-common-ui.es';
import { colorPalette } from '../../main/lib/nvstr-utils.es';
import { hideModal, logMetricsTrackingEvent } from '../../actions';
import { FlatButton } from '../../main/components/buttons';
import { Text } from '../../main/components/text';
import Icon from '../misc/Icon';

const fontSettings = defineFontSettings(
  {
    fontSize: '16px',
    lineHeight: '24px',
  },
  {
    fontSize: '18px',
    lineHeight: '28px',
  }
);

const ActionWrapper = styled.div`
  padding-top: 24px;
`;
const BodyWrapper = styled.div`
  border-radius: 5px;
  position: relative;
  padding: 24px;
  background-color: ${colorPalette.secondary.promoPurple};
  text-align: center;
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 8px;
  cursor: pointer;
`;
const TextWrapper = styled.div``;

const Body = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleContinue = () => {
    hideModal()(dispatch);

    const event = 'Press Continue Deposit Promo Modal';
    logMetricsTrackingEvent(event)();

    navigate(returnPathTo('funding'));
  };

  const handleDismiss = () => {
    hideModal()(dispatch);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <BodyWrapper onClick={stopPropagation}>
      <CloseButtonWrapper onClick={handleDismiss}>
        <Icon icon="fa-times-thin" colorClassName="secondary-text-color" />
      </CloseButtonWrapper>

      <TextWrapper>
        <Text fontSettings={fontSettings} color={colorPalette.primary.oak} center>
          Limited time only! Get $25 to invest when you make a $25 deposit
        </Text>
      </TextWrapper>
      <ActionWrapper>
        <FlatButton
          color={colorPalette.primary.oak}
          textColor={colorPalette.secondary.promoPurple}
          onClick={handleContinue}
        >
          Get your bonus now
        </FlatButton>
      </ActionWrapper>
    </BodyWrapper>
  );
};

class DepositPromoModal extends React.PureComponent {
  componentDidMount() {
    const event = 'View Deposit Promo Modal';
    logMetricsTrackingEvent(event)();
  }

  render() {
    return <Body />;
  }
}

export default DepositPromoModal;
