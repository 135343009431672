import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import IdeaShow from '../Ideas/IdeaShow';
import IdeaList from '../Ideas/IdeaList';
import Button from '../../components/buttons/Button';

import {
  findIdeasForSecurity,
  isThoughtLeaderIdea,
  findIdea,
  isLoadingIdeaDataForSecurity,
} from '../../helpers/ideaHelpers';
import { parseQueryString, createQueryString } from '../../helpers/routerHelpers';
import { getIdeaPhraseFor } from '../../helpers/terminologyHelpers';
import { convertFieldNameToMetricsTrackingEventProperty } from '../../helpers/trackingHelpers';
import { sendFacebookTrackingEvent } from '../../constants/facebookTracking';
import { returnCurrentUserThemeTestGroups } from '../../helpers/currentUserHelpers';

import PageLoading from '../../components/PageLoading';
import { TrackingEvents } from '../../utils/tracking/events';
import { withRouter } from '../../main/utils';
import { FlatButton } from '../../main/components/buttons';
import { Container } from '../../main/components/ui';

export class IdeasContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,

      showIdeaLoading: false,
      userUpdatingIdea: false,
      userAddingPriceTarget: false,
      askedWhy: false,
      loading: true,

      formData: {},
      errors: {},
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    this._fetchIdeasData();
    this._fetchThoughtsData();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentWillReceiveProps(nextProps) {
    // if ideas are not being fetched from server
    if (!nextProps.ideas.loadingCurrentUserIdeas && !nextProps.isLoading) {
      const securityId = nextProps.security.security_id;
      const ideaId = nextProps.additionalTabData ? nextProps.additionalTabData.ideaId : null;
      const ideas = findIdeasForSecurity(nextProps.security, nextProps.ideas.ideaList);
      const ideasToDisplay = ideas.filter((idea) => !idea.is_deleted);

      if (ideasToDisplay.length > 0) {
        // user just started creating a new idea
        if (
          nextProps.ideas.userCreatingIdeas[securityId] &&
          !(ideasToDisplay.filter((i) => i.idea_id.toString() === ideaId).length === 0)
        ) {
          const newIdea = { idea_id: null };
          this.updateActiveIdea(newIdea);
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const ideaId = this.props.additionalTabData ? this.props.additionalTabData.ideaId : null;
    if (this.state.userUpdatingIdea && ideaId !== null && ideaId !== 'null') {
      const newIdea = { idea_id: null };
      this.updateActiveIdea(newIdea);
    }

    if (this.props.askingWhyFromNotif && !this.state.dismissedAskedFromNotif) {
      if (!this.state.askedWhy) {
        this.setState((prevState) => ({
          askedWhy: true,
          askingWhyFromNotif: true,
        }));
        this._clearIsAskingWhyFromURL();
      }
    }
  }

  render() {
    const userCreatingIdea = this.props.ideas.userCreatingIdeas[this.props.security.security_id];
    const { currentUserIdeaId, thoughtLeaderIdeasIds, connectionsIdeasIds } = this.parseIdeasByOwner();

    if (this._isLoadingIdeasForThisSecurity()) {
      return <PageLoading pageName="Ideas" />;
    }

    if (!userCreatingIdea && !this.state.userUpdatingIdea && !this.convertActiveIdeaToIdea()) {
      return this.renderNoConnectionIdeas();
    }

    return (
      <div className="ideas-container">
        <div className="ideas-panel-container">
          <IdeaList
            thoughtLeaderIdeasIds={thoughtLeaderIdeasIds}
            connectionsIdeasIds={connectionsIdeasIds}
            currentUserIdeaId={currentUserIdeaId}
            currentUserId={this.props.currentUserId}
            activeIdea={this.props.ideas.ideaList[(this.convertActiveIdeaToIdea() || {}).idea_id]}
            userCreatingIdea={userCreatingIdea}
            handleClick={this.handleIdeaListItemClick}
            screenWidth={this.state.screenWidth}
            userUpdatingIdea={this.state.userUpdatingIdea}
          />
          <IdeaShow
            key={`show-idea-${
              this.convertActiveIdeaToIdea() ? (this.convertActiveIdeaToIdea() || {}).idea_id : 'blank'
            }`}
            actions={this.props.actions}
            navigate={this.props.navigate}
            security={this.props.security}
            idea={
              this.state.userUpdatingIdea || this.state.userCreatingIdea
                ? { idea_id: null }
                : this.convertActiveIdeaToIdea()
            }
            ideaOwner={this.findIdeaOwner()}
            screenWidth={this.state.screenWidth}
            updateIdea={this.updateIdea}
            updateActiveIdea={this.props.actions.updateActiveIdea}
            removeIdea={this.removeIdea}
            currentUser={this.props.currentUser}
            userCreatingIdea={userCreatingIdea}
            userUpdatingIdea={this.state.userUpdatingIdea}
            userAddingPriceTarget={this.state.userAddingPriceTarget}
            currentUsersIdea={this.currentUsersIdea()}
            isCurrentUserIdeasLoading={this.props.ideas.loading}
            startUserUpdatingIdea={this.startUserUpdatingIdea}
            clearUserUpdatingIdea={this.clearUserUpdatingIdea}
            handleSaveIdea={this.handleSaveIdea}
            handleAddPriceTarget={this.handleAddPriceTarget}
            handleRemovePriceTarget={this.handleRemovePriceTarget}
            users={this.props.users}
            askedWhy={this.state.askedWhy}
            dismissAskWhyMessage={this.dismissAskWhyMessage}
            viewIdea={this.props.actions.viewIdea}
            isPriceLoading={this._isPriceLoading()}
            currentPrice={this._returnCurrentPrice()}
          />
        </div>
      </div>
    );
  }

  _clearIsAskingWhyFromURL = () => {
    const path = this.props.location.pathname;
    const queryString = this.props.location.search;
    const query = parseQueryString(queryString);
    const keysToFilter = ['askingWhyFromNotif'];
    const queryKeys = Object.keys(query).filter((key) => !keysToFilter.includes(key));
    if (queryKeys.length > 0) {
      const filteredQuery = {};
      queryKeys.forEach((key) => (filteredQuery[key] = query[key]));
      const newQueryString = createQueryString(filteredQuery);
      this.props.navigate(path + newQueryString);
    }
  };

  _isLoadingIdeasForThisSecurity = (props = this.props) => props.ideas.loadingCurrentUserIdeas || props.isLoading;

  handleResize = (e) => {
    this.setState({
      screenWidth: window.innerWidth,
    });
  };

  activeIdeaId = () => {
    const ideaId = this.props.additionalTabData ? this.props.additionalTabData.ideaId : null;
    const securityId = this.props.security.security_id;
    const ideasToDisplay = this._returnIdeasForSecurity();
    if (
      this.props.ideas.userCreatingIdeas[securityId] &&
      ideasToDisplay.filter((i) => i.idea_id.toString() === ideaId).length === 0
    ) {
      return null;
    } else if (ideasToDisplay.filter((i) => i.idea_id.toString() === ideaId).length > 0) {
      return parseFloat(ideaId);
    } else {
      return this.determineIdeaIsActive().idea_id;
    }
  };

  determineIdeaIsActive = (nextProps) => {
    const _props = nextProps || this.props;
    const ideasToDisplay = this._returnIdeasForSecurity(_props);
    const currentUserIdea = ideasToDisplay.filter((idea) => idea.user_id === _props.currentUserId)[0];
    const firstTLIdea = ideasToDisplay.filter((idea) => isThoughtLeaderIdea(idea))[0];
    const firstConnectionIdea = ideasToDisplay.filter(
      (idea) => !isThoughtLeaderIdea(idea) && idea.user_id !== _props.currentUserId
    )[0];
    return currentUserIdea || firstTLIdea || firstConnectionIdea || { idea_id: null };
  };

  currentUsersIdea = () => {
    const userCreatingIdea = this.props.ideas.userCreatingIdeas[this.props.security.security_id];
    const ideas = this._returnIdeasForSecurity();
    return userCreatingIdea || ideas.filter((idea) => idea.user_id === this.props.currentUserId).length > 0
      ? ideas.filter((idea) => idea.user_id === this.props.currentUserId)[0] || {}
      : null;
  };

  handleIdeaListItemClick = (idea) => {
    this.updateActiveIdea(idea);

    this.setState((prevState) => ({
      userAddingPriceTarget: false,
    }));
  };

  updateActiveIdea = (idea) => {
    const navigate = this.props.navigate;
    const currentLocation = location;
    const query = parseQueryString(currentLocation.search);
    query.ideaId = idea ? idea.idea_id : null;
    const newQueryString = createQueryString(query);
    const newLocation = currentLocation.pathname + newQueryString;
    navigate(newLocation);
  };

  startUserUpdatingIdea = () => {
    const idea = { idea_id: null, status: 'user creating idea' };

    this.setState((prevState) => ({
      userUpdatingIdea: true,
    }));
  };

  clearUserUpdatingIdea = () => {
    this.setState((prevState) => ({
      userUpdatingIdea: false,
    }));

    if (
      this.props.additionalTabData &&
      (this.props.additionalTabData.ideaId === 'null' || this.props.additionalTabData.ideaId === undefined)
    ) {
      this.updateActiveIdea(this.determineIdeaIsActive());
    }
  };

  showUpdateFailModal = () => {
    const generalComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {`Could not save idea update. Please try again later.`}
      </div>
    );

    const modal = {
      contentComponent: generalComponent,
      dismissable: true,
    };
    this.props.actions.showModal(modal);
  };

  showRemoveFailModal = () => {
    const generalComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {`Could not remove idea. Please try again later.`}
      </div>
    );

    const modal = {
      contentComponent: generalComponent,
      dismissable: true,
    };
    this.props.actions.showModal(modal);
  };

  updateIdea = (updatedProps) => {
    if (this.convertActiveIdeaToIdea()) {
      const ideaToRollbackTo = this.convertActiveIdeaToIdea();
      const idea = {
        idea_id: this.convertActiveIdeaToIdea().idea_id,
        ...updatedProps,
      };
      this.props.actions.updateIdea(idea, ideaToRollbackTo).then((ajaxWasSuccessful, response) => {
        if (ajaxWasSuccessful) {
          const field = Object.keys(updatedProps).filter((key) => key !== 'idea_id')[0];
          const trackingField = convertFieldNameToMetricsTrackingEventProperty(field);
          const event = 'Idea Updated';
          const security = this.props.security;
          const properties = {
            Field: trackingField,
            'Stock Symbol': security.symbol,
            'Security ID': security.security_id,
          };
          this.props.actions.logMetricsTrackingEvent(event, properties);
        } else {
          this.showUpdateFailModal();
        }
      });
    }
  };

  removeIdea = (idea) => {
    const ideas = findIdeasForSecurity(this.props.security, this.props.ideas.ideaList);
    const ideasToDisplay = ideas;
    const firstTLIdea = ideasToDisplay.filter((idea) => isThoughtLeaderIdea(idea))[0];
    const firstConnectionIdea = ideasToDisplay.filter(
      (idea) => !isThoughtLeaderIdea(idea) && idea.user_id !== this.props.currentUserId
    )[0];

    let newActiveIdea = null;
    if (firstTLIdea) {
      newActiveIdea = firstTLIdea;
    } else if (firstConnectionIdea) {
      newActiveIdea = firstConnectionIdea;
    }
    this.updateActiveIdea(newActiveIdea);
    return this.props.removeIdea(idea).then((response) => {
      if ('error' in response) {
        this.showRemoveFailModal();
      }
    });
  };

  handleAddPriceTarget = (idea) => {
    if (!idea) {
      this.setState((prevState) => ({
        userAddingPriceTarget: true,
      }));
      return true;
    }

    const event = 'Add Price Target';
    const security = this.props.security;
    const properties = {
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);

    const rollbackIdea = findIdea(idea.idea_id, this.props.ideas);
    this.props.actions.updateIdea(idea, rollbackIdea).then((ajaxWasSuccessful) => {
      if (!ajaxWasSuccessful) {
        this.setState((prevState) => ({
          userAddingPriceTarget: false,
        }));
      }
    });

    this.setState((prevState) => ({
      userAddingPriceTarget: true,
    }));
  };

  handleRemovePriceTarget = (ideaId) => {
    const event = 'Remove Price Target';
    const security = this.props.security;
    const properties = {
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);

    const rollbackIdea = findIdea(ideaId, this.props.ideas);
    const defaultExpectedReturn =
      this.props.currentUser.default_expected_return * (rollbackIdea.idea_type.id === 0 ? 1 : -1);
    const idea = {
      idea_id: ideaId,
      price_target: null,
      expected_return: defaultExpectedReturn,
    };

    if (
      rollbackIdea.price_target === null &&
      rollbackIdea.expected_return !== null &&
      rollbackIdea.expected_return !== undefined
    ) {
      // will allow a user to get out of an awkward state where they have an expected return but can't get out of entering a price target
      this.setState((prevState) => ({
        userAddingPriceTarget: false,
      }));
      return null;
    }
    this.props.actions.updateIdea(idea, rollbackIdea).then((ajaxWasSuccessful) => {
      if (ajaxWasSuccessful) {
        this.setState((prevState) => ({
          userAddingPriceTarget: false,
        }));
      }
    });
  };

  convertActiveIdeaToIdea = () => {
    return this.props.ideas.ideaList[this.activeIdeaId()];
  };

  dismissAskWhyMessage = () => {
    this.setState((prevState) => ({
      askedWhy: false,
      askingWhyFromNotif: false,
      dismissedAskedFromNotif: true,
    }));
  };

  findIdeaOwner = () => {
    const idea = this.convertActiveIdeaToIdea();
    if (idea && idea.user_id) {
      return this.props.users.userList[idea.user_id]
        ? this.props.users.userList[idea.user_id]
        : this.props.currentUser.user_id === idea.user_id
        ? this.props.currentUser
        : null;
    }
    return null;
  };

  handleSaveIdea = (sourceIdea) => {
    const isCopyingIdea = !!sourceIdea;
    const userHasIdea = !!this.currentUsersIdea();

    if (isCopyingIdea) {
      return this.props.actions.copyIdea(sourceIdea, userHasIdea).then((response) => {
        this.clearUserUpdatingIdea();
        // HACK: needs refactor for better data to judge success off of
        const ajaxWasSuccessful = !response.error && response.status === 'created';
        if (ajaxWasSuccessful) {
          const params = {
            themeTestGroups: returnCurrentUserThemeTestGroups(this.props.currentUser),
            accountType: this.props.currentUser.is_live_trading ? 'Live' : 'Paper',
          };
          sendFacebookTrackingEvent('Copy Idea', params);
        }
      });
    } else {
      const idea = {
        security: { security_id: this.props.security.security_id },
      };
      return this.props.actions.addIdeas([idea]).then((response) => {
        const ideasWithErrors = response.ideas.filter((idea) => idea.status === 'error');
        if (ideasWithErrors.length === 0) {
          const params = {
            themeTestGroups: returnCurrentUserThemeTestGroups(this.props.currentUser),
            accountType: this.props.currentUser.is_live_trading ? 'Live' : 'Paper',
          };
          sendFacebookTrackingEvent('Added Idea', params);

          TrackingEvents.ideas.ADD_IDEA.send();
        }
        this.clearUserUpdatingIdea();
      });
    }
  };

  renderNoConnectionIdeas = () => {
    return this.props.security.featured ? (
      <div className="security-card-no-ideas-message">
        <span>{getIdeaPhraseFor('first')}</span>
        <Container top={24}>
          <FlatButton onClick={this.handleSaveIdea}>{getIdeaPhraseFor('add')}</FlatButton>
        </Container>
      </div>
    ) : (
      <div className="public-no-thoughts-for-idea-container" style={{ paddingBottom: '16px' }}>
        <span className="stand-alone-text">{getIdeaPhraseFor('first-unfeatured')}</span>
      </div>
    );
  };

  _returnIdeasForSecurity = (props) =>
    findIdeasForSecurity((props || this.props).security, (props || this.props).ideas.ideaList).filter(
      (idea) => !idea.is_deleted
    );

  parseIdeasByOwner = () => {
    const ideas = this._returnIdeasForSecurity();
    const tlIdeas = ideas.filter((idea) => isThoughtLeaderIdea(idea));
    const userIdeas = ideas.filter((idea) => !isThoughtLeaderIdea(idea) && idea.user_id !== this.props.currentUserId);
    const currentUserIdea = ideas.filter((idea) => idea.user_id === this.props.currentUserId)[0];

    const thoughtLeaderIdeasIds = tlIdeas.map((idea) => idea.idea_id);
    const connectionsIdeasIds = userIdeas.map((idea) => idea.idea_id);
    const currentUserIdeaId = currentUserIdea ? currentUserIdea.idea_id : null;

    return {
      thoughtLeaderIdeasIds,
      connectionsIdeasIds,
      currentUserIdeaId,
    };
  };

  _isPriceLoading = () => !this._returnCurrentPriceData() || this._returnCurrentPriceData().loading;

  _returnCurrentPriceData = () => this.props.securitiesPrice.securities[this.props.security.security_id];

  _returnCurrentPrice = () => (this._returnCurrentPriceData() ? this._returnCurrentPriceData().current_price : {});

  _fetchIdeasData = () => this.props.actions.getIdeasForSecurity([this._returnSecurityId()]);

  _fetchThoughtsData = () => this.props.actions.fetchThoughts([this._returnSecurityId()]);

  _returnSecurityId = () => this.props.security.security_id;
}

const mapStateToProps = (state) => {
  return {
    ideas: state.ideas,
    users: state.users,
    currentUser: state.currentUser,
    securitiesPrice: state.securitiesPrice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(IdeasContainer);

export default withRouter(connectedComponent);
