import {
  FORCE_UNDOCK_VERTICAL_NAV,
  RELEASE_FORCE_UNDOCK_VERTICAL_NAV,
  TOGGLE_ALWAYS_OPEN_VERTICAL_NAV,
  TOGGLE_VERTICAL_NAV,
  SHOW_VERTICAL_NAV,
  HIDE_VERTICAL_NAV,
  SET_SHOW_SEARCH_HEADER,
  SET_HIDE_SEARCH_HEADER,
} from '../constants';

export const forceUndockVerticalNav = () => {
  return function (dispatch) {
    dispatch({
      type: FORCE_UNDOCK_VERTICAL_NAV,
    });
  };
};

export const releaseForceUndockVerticalNav = () => {
  return function (dispatch) {
    dispatch({
      type: RELEASE_FORCE_UNDOCK_VERTICAL_NAV,
    });
  };
};

export const toggleAlwaysOpenVerticalNav = () => {
  return function (dispatch) {
    dispatch({
      type: TOGGLE_ALWAYS_OPEN_VERTICAL_NAV,
    });
  };
};

export const toggleVerticalNav = () => {
  return function (dispatch) {
    dispatch({
      type: TOGGLE_VERTICAL_NAV,
    });
  };
};

export const showVerticalNav = () => {
  return function (dispatch) {
    dispatch({
      type: SHOW_VERTICAL_NAV,
    });
  };
};

export const hideVerticalNav = () => {
  return function (dispatch) {
    dispatch({
      type: HIDE_VERTICAL_NAV,
    });
  };
};

export const showSearchHeader = () => {
  return function (dispatch) {
    dispatch({
      type: SET_SHOW_SEARCH_HEADER,
    });
  };
};

export const hideSearchHeader = () => {
  return function (dispatch) {
    dispatch({
      type: SET_HIDE_SEARCH_HEADER,
    });
  };
};
