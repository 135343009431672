import React, { useState } from 'react';
import Button from '../../components/buttons/Button';
import { FlatButton } from '../../main/components/buttons';

const MaxRowsList = (props) => {
  const {
    ids,

    containerStyles,
    children,
  } = props;

  const maxItemsToShowUnexpanded = 5;

  const [showAllItems, setShowAllItems] = useState(false);

  const handleShowAllClick = () => setShowAllItems(true);

  const handleShowLessClick = () => setShowAllItems(false);

  const moreItemsThanMaxToShow = ids.length > maxItemsToShowUnexpanded;

  const maxItemsIds = moreItemsThanMaxToShow && ids.slice(0, maxItemsToShowUnexpanded);

  const idsToRender = moreItemsThanMaxToShow ? (showAllItems ? ids : maxItemsIds) : ids;

  return (
    <div>
      {children(idsToRender)}
      {moreItemsThanMaxToShow && (
        <div style={containerStyles}>
          {showAllItems ? (
            <FlatButton small transparent onClick={handleShowLessClick}>
              See Less
            </FlatButton>
          ) : (
            <FlatButton small transparent onClick={handleShowAllClick}>
              See All
            </FlatButton>
          )}
        </div>
      )}
    </div>
  );
};

export default MaxRowsList;
