import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PublicPage } from '../components/page/PublicPage';
import { PublicLearnLessonSelector } from './LearnAndEarn';
import LearnAndEarnMoreInfo from './LearnAndEarnMoreInfo';
import { useDispatch } from 'react-redux';
import { fetchCurrentUserQuickInfo } from '../../actions';

export const LearnAndEarnMoreInfoPublic = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <PublicPage>
      <LearnAndEarnMoreInfo navigate={navigate} location={location} />
    </PublicPage>
  );
};

export const LearnAndEarnShare = () => {
  const dispatch = useDispatch();
  const { name } = useParams();

  React.useEffect(() => {
    const init = async () => {
      const response = await fetchCurrentUserQuickInfo()(dispatch);
    };
    init();
  }, []);

  return (
    <PublicPage>
      <PublicLearnLessonSelector name={name} />
    </PublicPage>
  );
};
