import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../../../actions/index';

import LinkTo from '../../../Links/LinkTo';
import NumberWithChangeIndicator from '../../../../components/UI/NumberWithChangeIndicator';

import { snakeToHyphens } from '../../../../helpers/generalHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';

export class CollectiveUserIdeaPerformanceCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? (
          this.props.loadingCellComponent
        ) : (
          <LinkTo
            to={this._returnLinkForHandlingClick()}
            className={''}
            additionalClickAction={this.props.logSecurityPanelOpenClick}
            child={this._renderCellContent()}
            noTextDecoration
          />
        )}
      </td>
    );
  }

  _isLoading = () => {
    return false;
  };

  _returnLinkForHandlingClick = () => {
    const { securityId } = this.props;

    const additionalProps = {
      activeTab: 'ideas',
    };
    const openSecurityPanelConfig = {
      securityId,
      additionalProps,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _returnCollectiveUser = () => {
    const { userId } = this.props;
    const store = this.props.collectives;
    const lookup = store.lookup;
    return lookup[userId];
  };

  _returnPerformanceForSecurity = () => {
    const { securityId } = this.props;
    const securityData = this._returnCollectiveUser().securities;
    const securityDataObj = securityData.filter((objData) => objData.security.security_id === securityId)[0];
    return securityDataObj.performance;
  };

  _renderCellContent = () => {
    // prevents js errors due to collective user data not being present
    if (!this._returnCollectiveUser().securities) {
      return (
        <span className={`security-table-idea-performance-container`}>
          <span className={`security-table-allocation-container`}></span>
        </span>
      );
    }

    const performanceData = this._returnPerformanceForSecurity();
    if (!performanceData) {
      // return empty div rather than null, otherwise LinkTo will throw an exception
      return <div />;
    }
    const performanceLastMonth = performanceData.performance_last_month;
    const performanceThisMonth = performanceData.performance_this_month;
    const performanceToday = performanceData.performance_today;

    return (
      <span className={`security-table-idea-performance-container`}>
        <span className={`security-table-allocation-container`}>
          <span className={`security-table-idea-performance-time-period`}>
            <span className={`security-table-idea-performance-label secondary-text-color`}>{'Today'}</span>
            <span className={`security-table-idea-performance-value`}>
              <NumberWithChangeIndicator
                customClass={'profile-portfolio'}
                valueFormat={'percentage'}
                value={performanceToday}
                coloredText={true}
              />
            </span>
          </span>
          <span className={`security-table-idea-performance-time-period`}>
            <span className={`security-table-idea-performance-label secondary-text-color`}>{'This Month'}</span>
            <span className={`security-table-idea-performance-value`}>
              <NumberWithChangeIndicator
                customClass={'profile-portfolio'}
                valueFormat={'percentage'}
                value={performanceThisMonth}
                coloredText={true}
              />
            </span>
          </span>
          <span className={`security-table-idea-performance-time-period`}>
            <span className={`security-table-idea-performance-label secondary-text-color`}>{'Last Month'}</span>
            <span className={`security-table-idea-performance-value`}>
              <NumberWithChangeIndicator
                customClass={'profile-portfolio'}
                valueFormat={'percentage'}
                value={performanceLastMonth}
                coloredText={true}
              />
            </span>
          </span>
        </span>
      </span>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    collectives: state.collectives,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(CollectiveUserIdeaPerformanceCell);

export default composedComponent;
