import { failIfUndefinedOrNull } from './devToolHelpers';

export const calculateTotalChangeForPosition = (priceData) => {
  const { current_price, shares, total_cost } = priceData;

  if (failIfUndefinedOrNull([current_price, shares, total_cost])) {
    return {
      totalChange: null,
      totalChangePercent: null,
    };
  }

  const totalChange = current_price * shares - total_cost;
  const totalCostAbs = Math.abs(total_cost);
  const totalChangePercent = totalCostAbs === 0 ? 0 : (totalChange / totalCostAbs) * 100;
  return {
    totalChange,
    totalChangePercent,
  };
};

export const calculateDayChangeForPosition = (priceData) => {
  const {
    unrealized_shares_transacted_today,
    cost_of_unrealized_shares_transacted_today,

    unrealized_shares_as_of_yesterday,

    current_price,
    previous_close_price,
  } = priceData;

  if (
    failIfUndefinedOrNull([
      unrealized_shares_transacted_today,
      cost_of_unrealized_shares_transacted_today,

      unrealized_shares_as_of_yesterday,

      current_price,
      previous_close_price,
    ])
  ) {
    return {
      todayChange: null,
      todayChangePercent: null,
    };
  }
  const todayChange =
    unrealized_shares_as_of_yesterday * current_price -
    unrealized_shares_as_of_yesterday * previous_close_price +
    (unrealized_shares_transacted_today * current_price - cost_of_unrealized_shares_transacted_today);

  const todayCostBasis = Math.abs(
    unrealized_shares_as_of_yesterday * previous_close_price + cost_of_unrealized_shares_transacted_today
  );
  const todayChangePercent = todayCostBasis === 0 ? 0 : (todayChange / todayCostBasis) * 100;
  return {
    todayChange,
    todayChangePercent,
  };
};

export const getTextColorTypeBasedOnValue = (value) => {
  return value === 0 ? 'neutral' : value >= 0 ? 'increase' : 'decrease';
};
