import {
  ADD_PORTFOLIO_DATA,
  ADD_USER_PORTFOLIO_DATA,
  UPDATE_PORTFOLIO_DATA,
  START_LOADING_PORTFOLIO_DATA,
  USER_PORTFOLIO_DATA_ERROR,
  REMOVE_IDEA,
} from '../constants';
import { getSecurityNameFromIdea } from '../helpers/ideaHelpers';

const defaultState = {
  loading: true,
  positions: {}, // positions is a dictionary with the securityId as a key for current user
  userPortfolioDict: {},
};

export default function portfolioReducer(state = defaultState, action) {
  let portfolioData, userId, idea;

  switch (action.type) {
    case START_LOADING_PORTFOLIO_DATA:
      userId = action.payload.userId;

      return {
        ...state,
        userPortfolioDict: {
          ...state.userPortfolioDict,
          [userId]: {},
        },
      };

    case ADD_PORTFOLIO_DATA: // Current User only
      portfolioData = action.payload.portfolioData;

      return {
        ...state,
        ...portfolioData,
        loading: false,
      };

    case ADD_USER_PORTFOLIO_DATA: // All other users
      portfolioData = action.payload.portfolioData;
      userId = action.payload.userId;

      return {
        ...state,
        userPortfolioDict: {
          ...state.userPortfolioDict,
          [userId]: {
            securities: {
              ...portfolioData,
            },
          },
        },
      };

    case REMOVE_IDEA:
      idea = action.payload;
      userId = idea.user_id;

      const updatedPortfolioData = {};
      const userPortfolioData = state.userPortfolioDict[userId];
      const userPortfolio = userPortfolioData ? userPortfolioData.securities : {};
      const portfolioSecurityKeys = Object.keys(userPortfolio);

      portfolioSecurityKeys.forEach((id) => {
        if (id.toString() !== getSecurityNameFromIdea(idea).toString()) {
          updatedPortfolioData[id] = userPortfolio[id];
        }
      });

      return {
        ...state,
        userPortfolioDict: {
          ...state.userPortfolioDict,
          [userId]: {
            securities: {
              ...updatedPortfolioData,
            },
          },
        },
      };

    case USER_PORTFOLIO_DATA_ERROR: // All other users
      const error = action.payload.error;
      userId = action.payload.userId;

      return {
        ...state,
        userPortfolioDict: {
          ...state.userPortfolioDict,
          [userId]: { error },
        },
      };

    default:
      return state;
  }
}
