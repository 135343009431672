import React from 'react';
import { useDispatch } from 'react-redux';
import { Page } from '@src/main/components/layout';
import {
  Body5,
  createDropdownItem,
  FloatingLabelDropdown,
  FloatingLabelTextInput,
  Lock,
  H5,
  INPUT_MASK_TYPES,
  Body1,
} from '@src/main/lib/nvstr-common-ui.es';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { listCountryNames, listUsStateNames } from '@src/helpers/formHelpers';
import { useIsLiveTrading } from '@src/main/hooks/user';
import { FormState } from '@src/main/components/form/FormState';
import { Container } from '@src/main/components/ui';
import { FlatButton, SkeletonButton } from '@src/main/components/buttons';
import { getLiveTradingAccount, logMetricsTrackingEvent, updateLiveTradingAccount } from '@src/actions';
import { TrustedContactSchema } from '@src/main/constants/formValidationSchemas';
import { EventNames, TrackingEvent } from '@src/main/lib/nvstr-utils.es';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@src/constants/paths';
import { ActionCompleted, LoadingPage } from '@src/main/components/page';
import { updateLiveAccount } from '@src/actions/v1/currentUser';
import { useTrustedContact } from '@src/main/hooks/user/useTrustedContact';
import InfoIcon, { InfoIconWrapper } from '@src/containers/UI/InfoIcon';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const InputsWrapper = styled.div`
  input {
    border: none;
  }
  select {
    border-top: none;
    border-left: none;
    border-right: none;
    -webkit-appearance: auto;
  }
  input,
  select {
    background: transparent !important;
  }
`;
const InputWrapper = styled.div`
  padding-top: 16px;
  margin-right: 8px;

  input {
    border-bottom: none !important;
  }
  select {
    height: 44px;
    margin-top: 2px;
  }
`;
const InlineInputs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  input {
    margin-right: 8px;
  }
  input:last-child {
    margin-right: 0;
  }
`;
const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const PrivacyMessage = styled.div`
  padding-top: 24px;
`;
const IconMessage = styled.div`
  padding-top: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  text-align: left;

  svg {
    height: 24px;
    width: 24px;

    fill: ${({ theme }) => theme.themeColors.text};

    margin-right: 16px;
  }
`;
const Actions = styled.div`
  padding-top: 40px;
  padding-bottom: 8px;
  text-align: center;
`;

const stateDropdownItems = [
  createDropdownItem({ text: '--', value: '', disabled: true }),
  ...listUsStateNames().map((s) => createDropdownItem({ text: s, value: s })),
];
const countryItems = [
  createDropdownItem({ text: '--', value: '', disabled: true }),
  ...listCountryNames(true).map((s) => createDropdownItem({ text: s, value: s })),
];

const emptyForm = {
  trusted_contact_name: '',
  trusted_contact_email: '',
  trusted_contact_relationship: '',
  trusted_contact_street_address: '',
  trusted_contact_city: '',
  trusted_contact_state: '',
  trusted_contact_postal_code: '',
  trusted_contact_apartment: '',
  trusted_contact_phone_number: '',
  trusted_contact_country: '',
};

const generateDefaultInitialValues = (tc) => {
  if (!tc || !tc.trusted_contact_name) {
    return emptyForm;
  }
  return tc;
};

const wasBlank = (obj) => {
  return Object.values(obj).every((v) => v === '');
};

const TrustedContactAddressDisplay = () => {
  const trustedContact = useTrustedContact();
  const {
    trusted_contact_apartment,
    trusted_contact_city,
    trusted_contact_country,
    trusted_contact_postal_code,
    trusted_contact_state,
    trusted_contact_street_address,
  } = trustedContact;

  if (!trusted_contact_street_address) {
    return null;
  }

  return (
    <Container top={8}>
      <Container>
        <Body5 thin>{`${trusted_contact_street_address} ${trusted_contact_apartment}`}</Body5>
      </Container>
      <Container>
        <Body5 thin>{`${trusted_contact_city} ${trusted_contact_state} ${trusted_contact_postal_code}`}</Body5>
      </Container>

      <Container>
        <Body5 thin>{`${trusted_contact_country}`}</Body5>
      </Container>
    </Container>
  );
};

const CurrentTrustedContact = ({ showHeading }) => {
  const trustedContact = useTrustedContact();

  if (!trustedContact || !trustedContact.trusted_contact_name) {
    return <Container>No current trusted contact saved.</Container>;
  }

  const {
    trusted_contact_name: name,
    trusted_contact_relationship: relationship,
    trusted_contact_phone_number: phoneNumber,
    trusted_contact_email: email,
  } = trustedContact;

  return (
    <Container>
      {showHeading && (
        <Container>
          <Body5 bold>Current:</Body5>
        </Container>
      )}

      <Container top={4} left={6}>
        <Container top={4}>
          <Body5 thin>{name}</Body5>
        </Container>

        <Container>
          <Body5 thin>{relationship}</Body5>
        </Container>

        <Container>
          <Body5 thin>{phoneNumber}</Body5>
        </Container>

        <Container>
          <Body5 thin>{email}</Body5>
        </Container>

        <TrustedContactAddressDisplay />
      </Container>
    </Container>
  );
};

export const TrustedContact = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLiveTrading = useIsLiveTrading();
  const trustedContact = useTrustedContact();
  const [initialValues, setInitialValues] = React.useState(generateDefaultInitialValues(trustedContact));

  const [isReady, setIsReady] = React.useState(false);
  const [isUpdateMode, setIsUpdateMode] = React.useState(false);
  const [isAddingPhoneNumber, setIsAddingPhoneNumber] = React.useState(false);
  const [isAddingEmail, setIsAddingEmail] = React.useState(false);
  const [isComplete, setIsComplete] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isDeleteComplete, setIsDeleteComplete] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  React.useEffect(
    () => {
      const notLoaded = wasBlank(initialValues);
      if (notLoaded && !!trustedContact?.name) {
        setInitialValues(generateDefaultInitialValues(trustedContact));
      }
    },
    [trustedContact]
  );

  React.useEffect(() => {
    const init = async () => {
      await getLiveTradingAccount()(dispatch);
      setIsReady(true);
    };
    init();
  }, []);

  const gotoDashboard = () => {
    navigate('/');
  };

  const onSaveOrDeleteContinue = () => {
    setIsUpdateMode(false);
    setIsAddingPhoneNumber(false);
    setIsAddingEmail(false);
    setIsComplete(false);
    setIsDeleting(false);
    setIsDeleteComplete(false);
    setIsSubmitting(false);
    setFormError(null);
    setInitialValues(generateDefaultInitialValues(trustedContact));
  };

  const form = useFormik({
    initialValues,
    validationSchema: TrustedContactSchema,
    enableReinitialize: true,
  });
  const { values, errors, setFieldValue, validateForm } = form;

  const handleChange = React.useCallback(
    (name) => (value) => {
      setFieldValue(name, value, false);
    },
    [setFieldValue]
  );

  const handleAddPhoneNumberClick = () => {
    setIsAddingPhoneNumber(true);
  };

  const handleAddEmailClick = () => {
    setIsAddingEmail(true);
  };

  const sendToApi = React.useCallback(async (values) => {
    setIsSubmitting(true);
    const response = await updateLiveAccount(values);
    if (response?.ok) {
      await getLiveTradingAccount()(dispatch);
    }
    return response;
  }, []);

  const handleEditPress = () => {
    setIsUpdateMode(true);
  };

  const handleDeletePress = async () => {
    setIsDeleting(true);
    const { ok, errorMessage } = await sendToApi(emptyForm);
    if (ok) {
      setIsDeleteComplete(true);
      return {
        ok: true,
      };
    } else {
      setFormError('Something went wrong, please try again');
      setIsSubmitting(false);
      return {
        ok: false,
      };
    }
  };

  const handleSubmit = React.useCallback(
    async () => {
      const validation = await validateForm();
      if (Object.keys(validation).length === 0) {
        TrackingEvent.create(EventNames.submitForm, { Context: 'Trusted Contact' });

        const { ok, errorMessage } = await sendToApi(values);
        if (ok) {
          setIsComplete(true);
          return {
            ok: true,
          };
        } else {
          setFormError('Something went wrong, please try again');
          setIsSubmitting(false);
          return {
            ok: false,
          };
        }
      } else {
        TrackingEvent.create(EventNames.submitFormValidationFailed, { Context: 'Trusted Contact' });
        setIsSubmitting(false);
        return {
          errors: true,
        };
      }
    },
    [values]
  );

  if (!isReady) {
    return <LoadingPage message={'Loading...'} pageProps={{ width: '440px' }} />;
  }

  if (!isLiveTrading) {
    return (
      <Page width={'440px'}>
        <ContentWrapper>
          <Container top={8} horizontal={8}>
            <Container top={8}>
              <Heading>
                <H5>Enter Trusted Contact</H5>
                <Container left={6} verticallyCenter>
                  <InfoIcon
                    position="right"
                    word={'tooltip_trusted_contact'}
                    style={{ position: 'relative', height: '10px', left: '2px' }}
                  />
                </Container>
              </Heading>
            </Container>
            <Container top={24} bottom={16}>
              <Body1>
                Please wait until your brokerage account is approved. This can be accessed at any time in the settings
                menu.
              </Body1>
            </Container>
            <Container top={24} bottom={16} centerAll>
              <SkeletonButton fullWidth onClick={gotoDashboard}>
                Dismiss
              </SkeletonButton>
            </Container>
          </Container>
        </ContentWrapper>
      </Page>
    );
  }

  if (isComplete || isDeleteComplete) {
    return (
      <ActionCompleted
        message={`Trusted Contact ${isComplete ? 'Saved' : 'Deleted'}`}
        width={'440px'}
        onContinue={onSaveOrDeleteContinue}
      />
    );
  }

  if (isDeleting) {
    return <LoadingPage message={'Deleting Trusted Contact...'} pageProps={{ width: '440px' }} />;
  }

  if (isUpdateMode) {
    return (
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>Enter Trusted Contact</H5>
            <Container left={6} verticallyCenter>
              <InfoIcon
                position="right"
                word={'tooltip_trusted_contact'}
                style={{ position: 'relative', height: '10px', left: '2px' }}
              />
            </Container>
          </Heading>

          <Container top={16}>
            <CurrentTrustedContact showHeading />
          </Container>

          <Container top={16}>
            <InputsWrapper>
              <InputWrapper style={{ width: '100%' }}>
                <FloatingLabelTextInput
                  name={'trusted_contact_name'}
                  value={values.trusted_contact_name}
                  label={'Full/Legal Name'}
                  onChange={handleChange}
                  error={errors.trusted_contact_name || null}
                  forceLiftedState
                />
              </InputWrapper>
              <InputWrapper style={{ width: '100%' }}>
                <FloatingLabelTextInput
                  name={'trusted_contact_relationship'}
                  value={values.trusted_contact_relationship}
                  label={'Relationship'}
                  onChange={handleChange}
                  error={errors.trusted_contact_relationship || null}
                  forceLiftedState
                />
              </InputWrapper>

              <Container top={16}>
                <Body5>Contact Method</Body5>
                <Container top={4}>
                  <Body5 isLowContrast>At least one contact method is required</Body5>
                </Container>
                <Container horizontal={8}>
                  {isAddingPhoneNumber || !!values.trusted_contact_phone_number ? (
                    <InputWrapper style={{ width: '100%' }}>
                      <FloatingLabelTextInput
                        name={'trusted_contact_phone_number'}
                        value={values.trusted_contact_phone_number}
                        label={'Phone Number'}
                        onChange={handleChange}
                        error={errors.trusted_contact_phone_number || null}
                        maskType={INPUT_MASK_TYPES.phone}
                        autoFocus
                        forceLiftedState
                      />
                    </InputWrapper>
                  ) : (
                    <Container top={16}>
                      <SkeletonButton onClick={handleAddPhoneNumberClick}>+ Add Phone Number</SkeletonButton>
                    </Container>
                  )}
                  {isAddingEmail || !!values.trusted_contact_email ? (
                    <InputWrapper style={{ width: '100%' }}>
                      <FloatingLabelTextInput
                        name={'trusted_contact_email'}
                        value={values.trusted_contact_email}
                        label={'Email'}
                        onChange={handleChange}
                        error={errors.trusted_contact_email || null}
                        autoFocus
                        forceLiftedState
                      />
                    </InputWrapper>
                  ) : (
                    <Container top={16}>
                      <SkeletonButton onClick={handleAddEmailClick}>+ Add Email</SkeletonButton>
                    </Container>
                  )}
                </Container>
              </Container>
            </InputsWrapper>

            <Container top={36}>
              <Body5 isLowContrast>Optional </Body5>
            </Container>
            <InputsWrapper>
              <InlineInputs>
                <InputWrapper style={{ width: '80%' }}>
                  <FloatingLabelTextInput
                    name={'trusted_contact_street_address'}
                    value={values.trusted_contact_street_address}
                    label={'Street Address'}
                    onChange={handleChange}
                    error={errors.trusted_contact_street_address || null}
                  />
                </InputWrapper>

                <InputWrapper>
                  <FloatingLabelTextInput
                    name={'trusted_contact_apartment'}
                    value={values.trusted_contact_apartment}
                    label={'Apartment'}
                    onChange={handleChange}
                    error={errors.trusted_contact_apartment || null}
                  />
                </InputWrapper>
              </InlineInputs>
              <InlineInputs>
                <InputWrapper style={{ width: '50%' }}>
                  <FloatingLabelTextInput
                    name={'trusted_contact_city'}
                    value={values.trusted_contact_city}
                    label={'City'}
                    onChange={handleChange}
                    error={errors.trusted_contact_city || null}
                  />
                </InputWrapper>

                <InputWrapper style={{ width: '20%' }}>
                  <FloatingLabelDropdown
                    name={'trusted_contact_state'}
                    value={values.trusted_contact_state}
                    label={'State'}
                    items={stateDropdownItems}
                    onChange={handleChange}
                    error={errors.trusted_contact_state || null}
                  />
                </InputWrapper>

                <InputWrapper style={{ width: '30%' }}>
                  <FloatingLabelTextInput
                    name={'trusted_contact_postal_code'}
                    value={values.trusted_contact_postal_code}
                    label={'Zip Code'}
                    onChange={handleChange}
                    error={errors.trusted_contact_postal_code || null}
                  />
                </InputWrapper>
              </InlineInputs>
              <InputWrapper>
                <FloatingLabelDropdown
                  name={'trusted_contact_country'}
                  items={countryItems}
                  value={values.trusted_contact_country}
                  label={'Country'}
                  onChange={handleChange}
                  error={errors.trusted_contact_country || null}
                />
              </InputWrapper>
            </InputsWrapper>

            <PrivacyMessage>
              <IconMessage>
                <Lock />
                <Body5>This information will be kept private and it will never appear on your public profile.</Body5>
              </IconMessage>
            </PrivacyMessage>
          </Container>

          <Actions>
            <FormState error={formError} isSubmitting={isSubmitting} />
            <Container fullWidth centerAll>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleSubmit}>
                Save
              </FlatButton>
            </Container>
          </Actions>
        </ContentWrapper>
      </Page>
    );
  }

  return (
    <Page width={'600px'}>
      <ContentWrapper>
        <Heading>
          <H5>Trusted Contact</H5>
          <Container left={6} verticallyCenter>
            <InfoIcon
              position="right"
              word={'tooltip_trusted_contact'}
              style={{ position: 'relative', height: '10px', left: '2px' }}
            />
          </Container>
        </Heading>

        <Container top={16}>
          <CurrentTrustedContact />
        </Container>

        <Actions>
          <Container fullWidth centerAll>
            <FlatButton fullWidth onClick={handleEditPress}>
              {!!trustedContact?.trusted_contact_name ? 'Edit' : 'Add'}
            </FlatButton>
          </Container>
          {!!trustedContact?.trusted_contact_name ? (
            <Container fullWidth centerAll top={8}>
              <FlatButton transparent fullWidth onClick={handleDeletePress}>
                Delete Saved Contact
              </FlatButton>
            </Container>
          ) : null}
        </Actions>
      </ContentWrapper>
    </Page>
  );
};
