import React from 'react';

export const YesNoToggleWithLabel = (props) => {
  function isActive(activeValue, value) {
    return activeValue === value ? 'toggle-active' : '';
  }

  function removeBTag(string) {
    const splitString = string.split('</b>');
    const boldString = splitString[0];
    const restString = splitString[1];

    return (
      <span>
        <span className="bold-text">{boldString}</span> <span>{restString}</span>
      </span>
    );
  }

  function displayLabel(label) {
    const strings = label.split('<b>');
    const stringsMixedJSX = strings.map((string, i) =>
      string.indexOf('</b>') >= 0 ? <span key={i}>{removeBTag(string)}</span> : <span key={i}>{string}</span>
    );

    return stringsMixedJSX;
  }

  const label = props.label;
  const name = props.name;
  const value = props.getValue(name);
  const additionalClasses = props.additionalClasses || '';

  const showAdditionalInputs =
    props.toggleAdditionalInfoFieldsIf instanceof Function
      ? props.toggleAdditionalInfoFieldsIf(value)
      : props.toggleAdditionalInfoFieldsIf === value;
  const showAdditionalInputsIfNot =
    props.toggleAdditionalInfoFieldsIf instanceof Function
      ? props.toggleAdditionalInfoFieldsIfNot(value)
      : props.toggleAdditionalInfoFieldsIf !== value && value !== undefined && value !== null;

  const isError = !!props.getErrorMessage(name);
  const errorMessage = isError ? props.getErrorMessage(name) : '';

  return (
    <div>
      <div className={`toggle-container ${additionalClasses}`}>
        <div className="toggle-label-container">{displayLabel(label)}</div>
        <div className="toggle-switch-container toggle-with-label">
          <div className="toggle-switch">
            <div
              className={`toggle-left ${isActive(value, props.values[0])}`}
              onClick={() => {
                props.handleToggleClick(name, 'YES');
              }}
            >
              {'Yes'}
            </div>
            <div
              className={`toggle-right ${isActive(value, props.values[1])}`}
              onClick={() => {
                props.handleToggleClick(name, 'NO');
              }}
            >
              {'No'}
            </div>
          </div>
          <p className={`text-field-error-message`}>{errorMessage}</p>
        </div>
      </div>
      {props.msgIfTrue && showAdditionalInputs && (
        <div className="toggle-additional-message-container">{props.msgIfTrue}</div>
      )}
      {showAdditionalInputs && (
        <div className="toggle-additional-inputs-container">{props.children.additionalInfoFields}</div>
      )}
      {showAdditionalInputsIfNot && (
        <div className="toggle-additional-inputs-container">{props.children.additionalInfoFieldsIfNot}</div>
      )}
    </div>
  );
};

export const ToggleWithLabel = (props) => {
  function isActive(activeValue, value) {
    return activeValue === value ? 'toggle-active' : '';
  }

  function removeBTag(string) {
    const splitString = string.split('</b>');
    const boldString = splitString[0];
    const restString = splitString[1];

    return (
      <span>
        <span className="bold-text">{boldString}</span> <span>{restString}</span>
      </span>
    );
  }

  function displayLabel(label) {
    const strings = label.split('<b>');
    const stringsMixedJSX = strings.map((string, i) =>
      string.indexOf('</b>') >= 0 ? <span key={i}>{removeBTag(string)}</span> : <span key={i}>{string}</span>
    );

    return stringsMixedJSX;
  }

  const label = props.label;
  const name = props.name;
  const value = props.getValue(name);
  const additionalClasses = props.additionalClasses || '';

  const showAdditionalInputs =
    props.toggleAdditionalInfoFieldsIf instanceof Function
      ? props.toggleAdditionalInfoFieldsIf(value)
      : props.toggleAdditionalInfoFieldsIf === value;
  const showAdditionalInputsIfNot =
    props.toggleAdditionalInfoFieldsIf instanceof Function
      ? props.toggleAdditionalInfoFieldsIfNot(value)
      : props.toggleAdditionalInfoFieldsIf !== value && value !== undefined && value !== null;

  const isError = !!props.getErrorMessage(name);
  const errorMessage = isError ? props.getErrorMessage(name) : '';

  return (
    <div>
      <div className={`toggle-container ${additionalClasses}`}>
        <div className="toggle-label-container">{displayLabel(label)}</div>
        <div className="toggle-switch-container toggle-with-label">
          <div className="toggle-switch">
            <div
              className={`toggle-left ${isActive(value, props.values[0])}`}
              onClick={() => {
                props.handleToggleClick(name, true);
              }}
            >
              {'Yes'}
            </div>
            <div
              className={`toggle-right ${isActive(value, props.values[1])}`}
              onClick={() => {
                props.handleToggleClick(name, false);
              }}
            >
              {'No'}
            </div>
          </div>
          <p className={`text-field-error-message`}>{errorMessage}</p>
        </div>
      </div>
      {props.msgIfTrue && showAdditionalInputs && (
        <div className="toggle-additional-message-container">{props.msgIfTrue}</div>
      )}
      {showAdditionalInputs && (
        <div className="toggle-additional-inputs-container">{props.children.additionalInfoFields}</div>
      )}
      {showAdditionalInputsIfNot && (
        <div className="toggle-additional-inputs-container">{props.children.additionalInfoFieldsIfNot}</div>
      )}
    </div>
  );
};

export default ToggleWithLabel;
