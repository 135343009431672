import React from 'react';
import { moment } from '../../helpers/timeHelpers';

export const Timestamp = (props) => {
  const { className, epochTime } = props;
  const returnDisplayTimeString = () => {
    const timeNow = moment();
    const timestamp = epochTime * 1000;
    const timestampMoment = moment(timestamp);
    const timestampReadableDayTimeSinceString = timestampMoment.fromNow();
    return timestampReadableDayTimeSinceString;
  };
  return <div className={`timestamp ${className || ''} secondary-text-color`}>{returnDisplayTimeString()}</div>;
};
