import React from 'react';
import SecurityData from '../../../../SecurityData/SecurityData';

class FormattedDataCell extends React.PureComponent {
  render() {
    const { name, securityId, property } = this.props;
    return (
      <td className={`comp-table-stock-formatted-data-cell ${name}-cell`}>
        <SecurityData securityId={securityId} property={property} naIndicator={'-'} />
      </td>
    );
  }
}

export default FormattedDataCell;
