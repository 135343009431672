import React from 'react';
import { BodyPanel } from '../../UI/Layout/BodyPanel';
import MeasureSize from '../../ExtraFunctionalityComponents/MeasureSize';
import { PanelCard } from '../../UI/Layout/PanelCard';
import TopConnections from '../../TopConnectionsPerformance/TopConnections';
import DashboardPositionsTable from '../../Dashboard/DashboardPositionsTable';
import DashboardIdeasTable from '../../Dashboard/DashboardIdeasTable';
import SeeFullPositions from '../../Dashboard/components/SeeFullPositions';
import { DashboardFooter } from '../../UI/Disclaimers/DashboardFooter';
import { useDispatch } from 'react-redux';
import { useSecurity } from '../../../main/hooks/securities/useSecurity';
import { quickFetchSecuritiesData } from '../../../actions';
import { formatForDisplay } from '../../../helpers/displayHelpers';
import { createTimeInstance, formatLocalizedDateTime } from '../../../helpers/timeHelpers';
import { Body3, Body5 } from '@src/main/lib/nvstr-common-ui.es';
import { colorPalette } from '@src/main/lib/nvstr-utils.es';
import { pluralizeIfAppropriate, toCapitalCase, truncateName } from '../../../helpers/generalHelpers';
import styled from 'styled-components';
import { FlatButton } from '../../../main/components/buttons';
import { ROUTES } from '../../../constants/paths';
import { useOptionPositions } from '../../../main/hooks/orders/useOptionPositions';
import { useCurrentIdeas, useCurrentUser } from '../../../main/hooks/user';
import { Container } from '@src/main/components/ui';
import { useNavigate } from 'react-router';
import { PageCard } from '@src/main/components/layout';

const OptionsTodayWrapper = styled.div`
  background: ${({ theme }) => theme.themeColors.component};
  padding: 24px 0 0 0;

  table {
    width: 100%;
  }

  th {
    text-align: center;
  }

  td {
    border-bottom: 1px solid ${({ theme }) => theme.themeColors.appBackground};
    padding: 16px 0;
  }

  td:first-child {
    padding-left: 24px;
    max-width: 160px;
  }
  td:last-child,
  th:last-child {
    text-align: center;
    padding-right: 24px;
  }
  td:nth-last-child(2),
  th:nth-last-child(2) {
    text-align: center;
  }

  td:nth-last-child(2) {
    text-align: center;

    * {
      color: ${colorPalette.secondary.brightGreen};
    }
  }
`;

const SellWrapper = styled.div`
  button {
    padding: 4px 0px;

    * {
      color: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }
`;

const OptionRow = ({ securityId, position }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const security = useSecurity(securityId);
  const symbol = security ? security.symbol : '';
  const name = security ? security.name : '';

  React.useEffect(() => {
    if (!security) {
      quickFetchSecuritiesData([securityId])(dispatch);
    }
  }, []);

  const {
    id,
    exercise_price,
    expiration_date,
    qty,
    option_type,
    operation,
    order_type,
    executed_at,
    price,
    // limit_price,
  } = position;

  const hc = () => {
    navigate(ROUTES.TRADE_OPTIONS.build(null, id, null));
  };

  const totalAllocation = price * qty * 100;
  const percentageChange = Math.random() / 100;
  const priceChange = totalAllocation * percentageChange;
  return (
    <tr>
      <td>
        <div style={{ fontSize: '18px' }}>{symbol}</div>
        <Body5 isLowContrast>{truncateName(name, 24)}</Body5>
        <div>{`${formatForDisplay(
          parseFloat(exercise_price),
          'price'
        )} ${option_type.toUpperCase()} ${formatLocalizedDateTime('L', createTimeInstance(expiration_date))}`}</div>
      </td>
      <td>
        <div>
          <Body3>{`+${formatForDisplay(priceChange, 'price')}`}</Body3>
        </div>
        <div>
          <Body5>{`+${formatForDisplay(percentageChange * 100, 'percent')}`}</Body5>
        </div>
      </td>

      <td>
        <div>
          <Body3>{`${formatForDisplay(totalAllocation, 'price')}`}</Body3>
        </div>
        <Body5>{`${qty} ${pluralizeIfAppropriate('Contract', qty, {
          ignorePrefix: true,
        })}`}</Body5>
        <SellWrapper>
          <FlatButton transparent onClick={hc}>
            Sell
          </FlatButton>
        </SellWrapper>
      </td>
    </tr>
  );
};

const OptionsPositionTable = ({ Container }) => {
  const positions = useOptionPositions();
  if (!positions || positions.length === 0) return null;

  return (
    <Container>
      <OptionsTodayWrapper>
        <table>
          <thead>
            <th />
            <th>Total Change</th>
            <th>Allocation</th>
          </thead>
          <tbody>
            {positions.map((p) => (
              <OptionRow key={p.id} securityId={p.security_id} position={p} />
            ))}
          </tbody>
        </table>
      </OptionsTodayWrapper>
    </Container>
  );
};

const Tables = () => {
  const navigate = useNavigate();
  const ideas = useCurrentIdeas(null, null);
  const ideasCount = Object.keys(ideas).length;

  const handleGoExplore = () => {
    navigate(ROUTES.EXPLORE_STOCKS.build());
  };

  if (ideasCount === 0) {
    return (
      <PanelCard>
        <PageCard>
          <Container top={8} left={24}>
            <Container bottom={8}>
              <Body5>Stocks you own and stocks on your watchlist will appear here.</Body5>
              <Container top={24}>
                <Body5>Not sure where to start?</Body5>
                <Container top={24} />
                <FlatButton onClick={handleGoExplore}>Start Exploring Here</FlatButton>
              </Container>
            </Container>
          </Container>
        </PageCard>
      </PanelCard>
    );
  }
  return (
    <>
      <DashboardPositionsTable renderIfNoPositions={null} ContainerComponent={PanelCard} />
      <DashboardIdeasTable showHeading />
      <PanelCard>
        <SeeFullPositions viewingContext="Dashboard" />
      </PanelCard>
    </>
  );
};

export const LeftDashboardPanel = (props) => {
  const { isMobileActivePanel, bodyPanelStyle, affixWrapperStyle, handleMutation } = props;
  const currentUser = useCurrentUser();
  const inHideTopConnections = currentUser.hide_top_performers_test_group || 0;
  return (
    <BodyPanel className={`left-dashboard-body-panel ${isMobileActivePanel ? 'active' : ''}`} style={bodyPanelStyle}>
      <div className={'affix-wrapper'} style={affixWrapperStyle}>
        <MeasureSize onMutation={handleMutation} setInitialSize useThrottle>
          {!inHideTopConnections && (
            <PanelCard>
              <TopConnections size="small" />
            </PanelCard>
          )}
          <Tables />
          <DashboardFooter />
        </MeasureSize>
      </div>
    </BodyPanel>
  );
};
