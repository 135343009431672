import React from 'react';
import { isUndefinedOrNull } from '../../../helpers/generalHelpers';

export const TruncateString = (props) => {
  const { string, width } = props;

  const style = !isUndefinedOrNull(width)
    ? {
        width: `${width}px`,
      }
    : {};
  return (
    <span className={`limit-name-width`} style={style}>
      {string}
    </span>
  );
};
