import { trackingEvents } from '@src/main/lib/nvstr-utils.es';
import ItlyTrackWrapper from './ItlyTrackWrapper';

const BANK_LINK_SKIPPED = new ItlyTrackWrapper(trackingEvents.bankLinkSkipped);

const bankTrackIteratively = {
  BANK_LINK_SKIPPED,
};

export default bankTrackIteratively;
