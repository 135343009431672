import { logMetricsTrackingEvent } from '../../../actions';
import { ADJUST_EVENT_NAMES, logAdjustEvent } from '@src/main/lib/nvstr-common-ui.es';

const VIEW_QUOTE = {
  send: (properties) => {
    // 'From Email'
    // 'Stock Symbol'
    // 'Security ID'
    // 'User'
    // 'HTTP Referrer'
    // 'Inbound HTTP Referrer:'
    // 'Promo Code'
    const event = 'View Quote';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_EXPLORE_PRESET_FILTER = {
  send: (properties) => {
    // Name: <string>

    const event = 'Pressed Explore Preset Filter';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_VIEW_SECURITY_LIST_ITEM = {
  send: (properties) => {
    // Security ID
    // Context
    const event = 'Press View Security In List';
    logMetricsTrackingEvent(event, properties)();
  },
};

const trackingEvents = {
  VIEW_QUOTE,

  PRESS_VIEW_SECURITY_LIST_ITEM,
  PRESS_EXPLORE_PRESET_FILTER,
};

export default trackingEvents;
