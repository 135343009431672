import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withWindowSize from '../HOCS/withWindowSize';
import { withRouter } from '../../main/utils';
import * as Actions from '../../actions/index';
import { consoleLog, deepDiffMapper } from '../../helpers/devToolHelpers';
import { useAdvancedLogging, useStoreLoggingDiff } from '../../appConfig';

let shouldLog = useAdvancedLogging;
let shouldLogDiff = useStoreLoggingDiff;

export class StoreLogger extends React.Component {
  componentDidUpdate(prevProps) {
    let diff = 'set shouldLogDiff to true to enable';
    if (shouldLogDiff) {
      diff = deepDiffMapper.map(prevProps, this.props);
    }
    if (shouldLog) {
      consoleLog('store', {
        prevProps,
        props: this.props,
        diff,
      });
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(
  withWindowSize,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(StoreLogger);

export default composedComponent;
