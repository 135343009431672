import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.svg`
  path {
    ${({ color, theme }) => (color === 'text' ? `fill: ${theme.themeColors.text};` : '')};
  }
`;

export const CheckCircleFilled = ({ textColor }) => {
  const color = textColor ? 'text' : 'auto';
  return (
    <Wrapper color={color} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M30 0C13.44 0 0 13.44 0 30C0 46.56 13.44 60 30 60C46.56 60 60 46.56 60 30C60 13.44 46.56 0 30 0ZM24 45L9 30L13.23 25.77L24 36.51L46.77 13.74L51 18L24 45Z"
          fill="#353432"
        />
      </g>
    </Wrapper>
  );
};
