import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { defineFontSettings, Body5 } from '../lib/nvstr-common-ui.es';
import { colorPalette } from '../lib/nvstr-utils.es';
import { FlatButton } from '../components/buttons';
import { Text } from '../components/text';
import { Spacing } from '../components/layout';
import { ROUTES } from '@src/constants/paths';
import { useDispatch, useSelector } from 'react-redux';
import { useNextLesson } from '../hooks/learn/useNextLesson';
import { useBasicLearnLessonData, useLearnLesson } from '../hooks/learn/useLearnLesson';
import { TrackingEvents } from '@src/utils/tracking/events';
import { LEARN_STATUS_MESSAGES } from '../constants/learn';
import { buildLearnLessonPhraseMessage, slugifyLessonName } from '../utils/learn';
import { filterDuplicates } from '@src/helpers/usefulFuncs';
import { LearnScreen } from '../components/learn';
import { LEARN_SCREENS } from '../constants/learn/lessonDefinitions';
import { logUserAction } from '@src/actions/v1/application';
import { USER_ACTION_CONTEXTS, USER_ACTION_EVENT_NAMES } from '@src/main/constants/userActions';
import { pickOne } from '@src/main/lib/nvstr-utils.es';

// const partnerLessonSelectionProbabilities = {
//   300000: 0.05,
//   300001: 0.75,
//   300013: 0.05,
//   300008: 0.05,
//   300009: 0.05,
//
//   300002: 0.01,
//   300003: 0.01,
//   300004: 0.01,
//   300007: 0.01,
//   300012: 0.01,
//   300014: 0.01,
//   300015: 0.01,
//   300016: 0.01,
//   300017: 0.01,
//   300018: 0.01,
//   300019: 0.01,
// };
// paused 300005, 300006, - galat
const partnerOfferLessons = [
  300000,
  300001,
  300002,
  300003,
  300004,
  300007,
  300008,
  300009,
  300012,
  300013,
  300014,
  300016,
  300017,
  300018,
  300019,
];
const priorityPartnerLessonsInOrder = [300001, 300000, 300013, 300008, 300009, 300018, 300019];

const pickLearnLessonWithPriority = (uncompletedOfferLessons, priorityPartnerLessonsInOrder) => {
  let priorityLessonId = null;
  priorityPartnerLessonsInOrder.forEach((item) => {
    if (uncompletedOfferLessons.includes(item) && !priorityLessonId) {
      priorityLessonId = item;
    }
  });
  if (priorityLessonId) {
    return priorityLessonId;
  }

  return pickOne(uncompletedOfferLessons);
};

const PrevLessonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${colorPalette.primary.charcoal};
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.16);
  }
`;
const LessonDefinitionRow = styled.div`
  height: 74px;
  display: flex;
  flex: 1;
  align-items: center;
`;
const ContainerWrapper = styled.div`
  overflow: auto;
  max-height: calc(100vh - 120px);
  padding: 16px;
  width: 100%;

  @media (max-width: 615px) {
    max-height: calc(100vh - 64px);
  }
`;
const BannerWrapper = styled.div`
  position: relative;
  padding: 16px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.3);

  display: flex;
  flex-direction: row;
  align-items: center;
`;
const LessEyeCatchingBannerWrapper = styled.div`
  position: relative;
  padding: 16px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #1a1a1a;
  //background-color: rgba(255, 255, 255, 0.3);

  display: flex;
  flex-direction: row;
  align-items: center;
`;
const NewLessonIdentifierWrapper = styled.div`
  display: flex;
  flex: 1;
  padding-right: 16px;
  flex-direction: column;
`;
const NewLessonStartButtonWrapper = styled.div``;
const Overrides = styled.div`
  > div {
    padding: 0px !important;
  }
`;

const lessonNameFontSettings = defineFontSettings(
  {
    fontSize: '18px',
    lineHeight: 1.2,
  },
  {
    fontSize: '18px',
    lineHeight: 1.2,
  }
);

const fontSettings = defineFontSettings(
  {
    fontSize: '24px',
    lineHeight: 1.2,
  },
  {
    fontSize: '28px',
    lineHeight: 1.2,
  }
);

const PrevLessonRow = ({ lessonId, heading, previewPhrase, textColor }) => {
  const navigate = useNavigate();
  // const { lesson: lessonData } = useLearnLesson(lessonId);
  // const { name, heading, phraseDisplay: previewPhrase } = lessonData || {};

  const handlePress = () => {
    TrackingEvents.learn.PRESS_VIEW_PREVIOUS_COMPLETED_LESSON.send({
      'Lesson ID': lessonId,
      Subject: heading,
    });
    navigate(ROUTES.LEARN_AND_EARN.build(slugifyLessonName(heading)));
  };

  return (
    <PrevLessonWrapper>
      <LessonDefinitionRow onClick={handlePress}>
        <Spacing left={16}>
          <Text fontSettings={lessonNameFontSettings} bold color={textColor}>
            {heading}
          </Text>
          <Spacing top={4}>
            <Body5 color={textColor}>{buildLearnLessonPhraseMessage(previewPhrase)}</Body5>
          </Spacing>
        </Spacing>
      </LessonDefinitionRow>
    </PrevLessonWrapper>
  );
};

const getAddedCompletedLibraryLessons = (completedLesson) => {
  const completedLessonIds = completedLesson.map((l) => l.id);
  const lessons = completedLessonIds.map((id) => LEARN_SCREENS[id]);
  const hasInvestingLesson = lessons.filter((l) => l && l.name === 'Investing').length > 0;
  if (hasInvestingLesson) {
    return [];
  }

  const investingLessonForcedId = 100003;
  const investingLessonForced = LEARN_SCREENS[investingLessonForcedId];
  const formatted = {
    id: investingLessonForced.id,
    heading: investingLessonForced.heading,
    preview_phrase: investingLessonForced.phraseDisplay,
    is_core_content: true,
  };
  return [formatted];
};

const PreviousViewLearns = ({ header }) => {
  const textColor = colorPalette.primary.charcoal;
  const completedLessons = useSelector((state) => state.learn.completed_lessons);

  const [libraryLessons, setLibraryLessonIds] = React.useState(null);

  React.useEffect(
    () => {
      const lessons = [...completedLessons, ...getAddedCompletedLibraryLessons(completedLessons)].filter(
        (lesson) => lesson?.is_core_content || lesson?.id?.toString().slice(0, 1) === '3'
      );

      setLibraryLessonIds(filterDuplicates(lessons));
    },
    [completedLessons]
  );

  if (!libraryLessons || libraryLessons.length < 1) {
    return null;
  }

  return (
    <div>
      {header}
      {libraryLessons.map((l) => (
        <PrevLessonRow
          key={l.id}
          lessonId={l.id}
          heading={l.heading}
          previewPhrase={l.preview_phrase}
          textColor={textColor}
        />
      ))}
    </div>
  );
};

const NextLessonBanner = ({ lessonId }) => {
  const navigate = useNavigate();
  const { description: name, preview_phrase: phraseDisplay } = useBasicLearnLessonData(lessonId);
  const textColor = colorPalette.primary.charcoal;

  const onPress = () => {
    navigate(ROUTES.LEARN_AND_EARN.build(slugifyLessonName(name)));
  };

  return (
    <BannerWrapper>
      <NewLessonIdentifierWrapper>
        <div>
          <Text fontSettings={lessonNameFontSettings} bold color={textColor}>
            {name}
          </Text>
        </div>
        <Spacing top={4} left={2}>
          <Body5 color={textColor}>{buildLearnLessonPhraseMessage(phraseDisplay)}</Body5>
        </Spacing>
      </NewLessonIdentifierWrapper>
      <NewLessonStartButtonWrapper>
        <FlatButton width={100} color={textColor} onClick={onPress} textColor={colorPalette.primary.oak}>
          Start
        </FlatButton>
      </NewLessonStartButtonWrapper>
    </BannerWrapper>
  );
};

const PartnerLessonOfferBanner = ({ lessonId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lesson: lessonData } = useLearnLesson(lessonId);
  const { heading, name } = lessonData;
  const textColor = colorPalette.primary.charcoal;

  React.useEffect(() => {
    TrackingEvents.learn.VIEW_PARTNER_PROMO_LESSON_BANNER.send({
      'Lesson ID': lessonId,
      Subject: name,
    });

    logUserAction({
      event_name: USER_ACTION_EVENT_NAMES.viewPartnerLessonBanner,
      context: USER_ACTION_CONTEXTS.learnHome,
      value: lessonId,
    })(dispatch);
  }, []);

  const onPress = () => {
    TrackingEvents.learn.PRESS_VIEW_PARTNER_PROMO_LESSON.send({
      'Lesson ID': lessonId,
      Subject: name,
    });

    logUserAction({
      event_name: USER_ACTION_EVENT_NAMES.viewPartnerLesson,
      context: USER_ACTION_CONTEXTS.learnLesson,
      value: lessonId,
    })(dispatch);

    navigate(ROUTES.LEARN_AND_EARN.build(slugifyLessonName(name)));
  };

  return (
    <LessEyeCatchingBannerWrapper>
      <NewLessonIdentifierWrapper>
        <div>
          <Text fontSettings={lessonNameFontSettings} bold color={textColor}>
            {heading}
          </Text>
        </div>
        <Spacing top={4} left={2}>
          <Body5 color={textColor}>Find out more and access a special offer.</Body5>
        </Spacing>
      </NewLessonIdentifierWrapper>
      <NewLessonStartButtonWrapper>
        <FlatButton width={100} color={textColor} onClick={onPress} textColor={colorPalette.primary.oak}>
          Start
        </FlatButton>
      </NewLessonStartButtonWrapper>
    </LessEyeCatchingBannerWrapper>
  );
};

const PartnerLessonOfferController = ({ HeaderComponent }) => {
  const completedLessonIds = useSelector((state) => state.learn.completed_lessons).map((d) => d.id);
  const [lessonId, setLessonId] = React.useState(null);

  React.useEffect(
    () => {
      const uncompletedOfferLessons = partnerOfferLessons.filter((id) => !completedLessonIds.includes(id));
      if (uncompletedOfferLessons.length > 0) {
        const selectedLesson = pickLearnLessonWithPriority(uncompletedOfferLessons, priorityPartnerLessonsInOrder);
        setLessonId(selectedLesson);
      }
    },
    [completedLessonIds, lessonId]
  );

  if (lessonId === null) return null;

  return (
    <div horizontal={16}>
      {HeaderComponent}
      <div top={16}>
        <PartnerLessonOfferBanner lessonId={lessonId} />
      </div>
    </div>
  );
};

const LearnAndEarnPage = () => {
  const textColor = colorPalette.primary.charcoal;

  const { nextLessonId, status } = useNextLesson();
  // const status = null;
  // const nextLessonId = null;

  const defaultMessage = 'Come back tomorrow for more new lessons!';
  const message = LEARN_STATUS_MESSAGES[status];
  const displayMessage = message || defaultMessage;

  return (
    <Overrides>
      <LearnScreen>
        <ContainerWrapper>
          <Spacing top={12}>
            <Text fontSettings={fontSettings} color={textColor} bold>
              LEARN & EARN
            </Text>
          </Spacing>
          <Spacing horizontal={16} top={4}>
            {nextLessonId ? (
              <Spacing top={16}>
                <Spacing left={2}>
                  <Body5 color={textColor}>Your new lesson is ready!</Body5>
                </Spacing>
                <Spacing top={16} bottom={16}>
                  <NextLessonBanner lessonId={nextLessonId} />
                </Spacing>
              </Spacing>
            ) : (
              <Spacing top={16} bottom={16}>
                <Body5 color={textColor}>{displayMessage}</Body5>
                <Spacing top={16} />
              </Spacing>
            )}
          </Spacing>
          <Spacing top={4}>
            <PartnerLessonOfferController
              HeaderComponent={
                <Spacing bottom={8}>
                  <Body5 color={textColor}>PARTNER LESSONS WITH SPECIAL OFFERS</Body5>
                </Spacing>
              }
            />
          </Spacing>

          <Spacing top={16}>
            <PreviousViewLearns
              header={
                <div>
                  <Body5 color={textColor}>REVISIT LESSONS YOU'VE ALREADY COMPLETED</Body5>
                </div>
              }
            />
          </Spacing>
        </ContainerWrapper>
      </LearnScreen>
    </Overrides>
  );
};

export default LearnAndEarnPage;
