import React from 'react';
import IconButton from '../../../components/buttons/IconButton';

export const OpenCreateThoughtPanelButton = (props) => {
  const { thoughtType } = props;

  const handleClick = () => props.handleClick(thoughtType);
  const isPro = thoughtType.toLowerCase() === 'pro';

  return (
    <IconButton
      icon={'fa-plus'}
      size="medium"
      customClass={`${
        isPro ? 'add-pro' : 'add-con'
      } create-thought-button circle-button create-${thoughtType.toLowerCase()}s`}
      handleClick={handleClick}
    />
  );
};
