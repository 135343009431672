import React from 'react';
import ReactDOM from 'react-dom';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Provider } from 'react-redux';
import Main from './containers/Main';
import configureStore from './configureStore';
import registerServiceWorker from './registerServiceWorker';
import { ResizeObserver } from '@juggle/resize-observer';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { DIST, VERSION } from '../config';
import { TrackingEvent } from '@src/main/lib/nvstr-utils.es';
import { logMetricsTrackingEvent } from '@src/actions';
import { redactEmailParams } from '@src/helpers/usefulFuncs';

const DEV_MODE = import.meta.env.MODE === 'development';
const store = configureStore();

const logSentryErrorsInDevelopment = false;

try {
  if (!DEV_MODE || logSentryErrorsInDevelopment) {
    Sentry.init({
      dsn: 'https://af84f95bedef4a8fb0fb42f5682c6554@o158945.ingest.sentry.io/5858933',
      environment: DEV_MODE ? 'development' : 'production',
      release: VERSION,
      dist: DIST,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
      ],
      beforeSend(event, hint) {
        // const error = hint.originalException;
        redactEmailParams(event);
        return event;
      },
      tracesSampleRate: 1.0,
      sanitizeKeys: [
        /_token$/,
        /email/i,
        /password/i,
        /annual_income_usd_max/i,
        /total_investible_assets_usd_min/i,
        /total_investible_assets_usd_max/i,
        /requested_change_ssn/i,
        /is_permanent_resident/i,
        /birth_country/i,
        /green_card_number/i,
        /green_card_expiration_date/i,
        /visa_type/i,
        /visa_number/i,
        /visa_expiration_date/i,
        /phone_number/i,
        /annual_income_usd_min/i,
        /firm_name/i,
        /is_affiliated_exchange_or_finra/i,
        /political_organization/i,
        /immediate_family/i,
        /is_politically_exposed/i,
        /company_symbols/i,
        /is_control_person/i,
        /citizenship_country/i,
        /date_of_birth/i,
        /social_security_number/i,
        /accounts/i,
        /margin_call_amount/i,
        /funds_available/i,
        /amount1/i,
        /amount2/i,
        /amount/i,
        /funding_operation/i,
        /apex_ach_transfer_id/i,
        /bank_routing_number/i,
        /bank_account_type/i,
        '/bank_account/i',
      ],
      autoBreadcrumbs: {
        xhr: false, // XMLHttpRequest
        console: true, // console logging
        dom: true, // DOM interactions, i.e. clicks/typing
        location: true, // url changes, including pushState/popState
        sentry: true, // sentry events
      },
    });
  }

  window.ResizeObserver = ResizeObserver;

  TrackingEvent.addEventSender((name, properties) => logMetricsTrackingEvent(name, properties)());
} catch (error) {
  console.error(error);
}

ReactDOM.render(
  <Provider store={store}>
    <Main />
  </Provider>,
  document.getElementById('root')
);

registerServiceWorker();
