import { logMetricsTrackingEvent } from '../../../actions';
import { ADJUST_EVENT_NAMES, logAdjustEvent } from '@src/main/lib/nvstr-common-ui.es';

const ADD_IDEA = {
  send: (properties) => {
    const event = 'Clicked Add Idea';
    logMetricsTrackingEvent(event, properties)();

    logAdjustEvent(ADJUST_EVENT_NAMES.ADDED_IDEA);
  },
};

const CLICK_TRADE_BUTTON_DASHBOARD_IDEAS = {
  send: (properties) => {
    // Order Type -> ('Buy', 'Sell', null)
    // Security ID

    const event = 'Click Trade Button Dashboard Ideas';
    logMetricsTrackingEvent(event, properties)();
  },
};

const trackingEvents = {
  ADD_IDEA,

  CLICK_TRADE_BUTTON_DASHBOARD_IDEAS,
};

export default trackingEvents;
