import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const importConnectionsAdapter = {
  getImportConnectionsPage: () => {
    return sendRequest('get', [`${URL}/import_page`]).then((response) => response);
  },

  getImportConnectionsNetworks: () => {
    return sendRequest('get', [`${URL}/connections/import/networks`]).then((response) => response);
  },

  getImportContacts: () => {
    return sendRequest('get', [`${URL}/connections/import/contacts`]).then((response) => response);
  },
};
