import React from 'react';

import Badge from '../../../UI/Badge';
import InfoIcon from '../../../UI/InfoIcon';
import { UserAvatarList } from '../../../users/UserAvatarList';

import { ViewUsersListContexts } from '../../../../constants/types/context';
import { logMetricsTrackingEvent } from '../../../../actions';
import { PROFILE_CONTEXTS } from '../../../../constants/contextTracking';

class PerformanceRankingNotification extends React.PureComponent {
  constructor() {
    super();
    this.displayedConnectionBadges = ['elite', 'platinum', 'gold', 'silver', 'bronze', 'unranked'];
  }

  render() {
    const ownRankData = this.getOwnRankData();
    return (
      <div className="ranking-performance-notification">
        {this.renderNotificationHeading()}
        {this.renderOwnPerformanceRank(ownRankData)}
        {this.hasConnectionBadgeData() && (
          <div className="connections-ranking-performance-container">
            <div className="connections-ranking-performance-heading  ">Your Connections</div>
            <div className="connections-ranking-performance-container">
              {this.displayedConnectionBadges.map((badgeName) => this.renderBadgeRow(badgeName))}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderNotificationHeading = () => (
    <div className="ranking-performance-heading">
      <span className={`info-icon-text-wrapper`}>
        <span className={`heading  `}>Your Portfolio Rank This Week</span>
        <InfoIcon word={'portfolio_rank'} style={{ top: 'auto', bottom: '1px' }} />
      </span>
    </div>
  );

  renderOwnPerformanceRank = (ownRankData) => {
    const badgeName = ownRankData.badge;
    const badgeLabel = ownRankData.label;
    if (badgeName === 'incomplete') {
      return (
        <div className="own-ranking-performance-badge-container">
          <div className="own-ranking-performance-badge-container-incomplete badge-name sell-text-color">
            Incomplete
          </div>
          <div className="own-ranking-performance-badge-container-incomplete  ">
            Build your portfolio by trading a stock and see where you rank next week.
          </div>
        </div>
      );
    } else {
      return (
        <div className="own-ranking-performance-badge-container">
          <div className="ranking-performance-badge-container">
            <Badge badgeName={badgeName} />
          </div>
          <div className="ranking-performance-badge-label  ">
            <span className="badge-name">{badgeLabel}</span>
          </div>
        </div>
      );
    }
  };

  renderBadgeRow = (badgeName) => {
    const badgeData = this.returnConnectionBadgeData(badgeName);
    if (!badgeData) {
      return null;
    }
    // const badgeDisplayName = toCapitalCase(badgeData.badge.badge);
    const badgeLabel = badgeData.badge.label;
    const users = badgeData.users;
    const userIds = users.map((userData) => userData.id);
    if (userIds.length === 0) {
      return null;
    }
    return (
      <div key={badgeName} className="ranking-performance-row">
        <div className="ranking-performance-badge-cell">
          <Badge badgeName={badgeName} />
        </div>
        <div className="ranking-performance-label-cell">
          <div className="ranking-performance-badge-label  ">
            <span className="badge-name">{badgeLabel}</span>
          </div>
        </div>
        <div className="ranking-performance-users-cell">
          <UserAvatarList
            userIds={userIds}
            profileLinkQuery={`?context=${PROFILE_CONTEXTS.NOTIFICATION}`}
            maxShowIdCount={this.getUserListMaxShowCount()}
            logEllipsisClick={() => this.logEllipsisClick(badgeName)}
            showEllipsisUserListAfter
          />
        </div>
      </div>
    );
  };

  logEllipsisClick = (badge) => {
    const event = 'Clicked View Users List';
    const properties = {
      Context: ViewUsersListContexts.PERFORMANCE_RANKING_NOTIFICATION,
      Badge: badge,
    };
    logMetricsTrackingEvent(event, properties)();
  };

  getUserListMaxShowCount = () => {
    const screenWidth = $(window).width();
    if (screenWidth > 400) {
      return 5;
    } else if (screenWidth < 350) {
      return 2;
    } else {
      return 3;
    }
  };

  getNotificationData = () => this.props.notification;

  getOwnRankData = () => this.getNotificationData().user_badge;

  hasConnectionBadgeData = () => {
    const connectionBadges = this.getNotificationData().connection_badges;
    return this.displayedConnectionBadges.filter((badge) => badge in connectionBadges).length > 0;
  };

  returnConnectionBadgeData = (badgeName) => this.getNotificationData().connection_badges[badgeName];
}

export default PerformanceRankingNotification;
