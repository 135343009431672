import React from 'react';
import { fetchFurtherDocumentationStatus } from '../actions';

export const getLiveAccountApprovalStatus = async (ignore401) => {
  const resp = await fetchFurtherDocumentationStatus(ignore401);
  return {
    ...resp,
    isRejected: resp.wasRejected,
  };
};

export const checkIfFurtherDocumentationIsRequired = async (ignore401) => {
  const { isUserActionRequired } = await getLiveAccountApprovalStatus(ignore401);
  return isUserActionRequired;
};
