import React from 'react';
import { useSelector } from 'react-redux';
import QueryBuilder from '../../../classes/QueryBuilder';
import { getCurrentUserFromState } from '../../../selectors/currentUser';

export const REFERRAL_SHARE_LINK_CONTEXTS = {
  LESSON: 'l',
  REWARDS_SCREEN: 'r',
  FEED: 'f',
  CONNECTIONS_SCREEN: 'c',
  GMAIL_IMPORT: 'g',
};

export const REFERRAL_SHARE_LINK_CHANNELS = {
  LINK: 'l',
  EMAIL: 'e',
  SMS: 'm',
  SOCIAL: 's',
};

const buildQueryStringForParams = (params = {}) => {
  const { context, channel } = params;
  const queryString = new QueryBuilder();
  context && queryString.add('cn', context);
  channel && queryString.add('ch', channel);
  return queryString.getQueryString();
};

const formatReferralDataFromUser = (user, params) => {
  const { encoded_share_url, share_url } = user;
  const queryString = buildQueryStringForParams(params);
  const encodedWithParams = `${encoded_share_url}${queryString}`;
  const linkWithParams = `${share_url}${queryString}`;
  return {
    encodedInviteLink: encodedWithParams,
    inviteLink: linkWithParams,
  };
};

export const useCurrentUserReferral = (context, channel) => {
  const storeUser = useSelector((state) => getCurrentUserFromState(state));
  const [data, setData] = React.useState(formatReferralDataFromUser(storeUser, { context, channel }));

  React.useEffect(() => {
    setData(formatReferralDataFromUser(storeUser, { context, channel }));
  }, [storeUser, context, channel]);

  return data;
};
