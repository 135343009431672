import React from 'react';
import { truncateName, isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import MoneyNumber from '../../../UI/DataTypes/MoneyNumber';

const SecurityTransaction = (props) => {
  const {
    id,

    type,
    account_name,
    sub_type,
    adjustment_type,
    shares,
    amount,
    security,

    time,
  } = props;

  const { symbol, name } = security;
  const subType = account_name || sub_type;
  return (
    <li className={`trade-transaction-item security-transaction-item`}>
      <div className={`trade-transaction-details`}>
        <div className={`transaction-identifier`}>
          <div className={`transaction-type`}>{type}</div>
          <div className={`transaction-sub-type secondary-text-color`}>{subType}</div>
        </div>
        <div className={`inline-grouping`}>
          <div className={`transaction-security-identifier`}>
            <div className={`security-symbol`}>{symbol}</div>
            <div className={`security-name secondary-text-color`}>{truncateName(name, 25)}</div>
          </div>
          <div className={`transaction-trade-details`}>
            <div className={`transaction-trade-pricing`}>
              <span className={`trade-shares-price transaction-amount`}>{`${shares} sh ${adjustment_type}`}</span>
              {!isUndefinedOrNull(amount) && <MoneyNumber className="trade-amount transaction-amount" value={amount} />}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default SecurityTransaction;
