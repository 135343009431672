import { getMonthFromString, getMonthFromNumber, reverseKeys } from './generalHelpers';

import Dropdown from '../components/form/Dropdown';
import ToggleWithLabel from '../components/form/ToggleWithLabel';
import TextField from '../components/form/TextField';
import TextNumberField from '../components/form/TextNumberField';
import Hidden from '../components/form/Hidden';
import DateField from '../components/form/DateField';
import Checkbox from '../components/form/Checkbox';
import SocialTextField from '../components/form/SocialTextField';
import MaskedTextField from '../components/form/MaskedTextField';

import { listUsStateNames, listCountryNames, listVisaTypes } from './formHelpers';

export const investorProfileInputs = () => {
  return [
    {
      type: Dropdown,
      typeName: 'Dropdown',
      name: 'risk_profile',
      label: 'Select your Risk Profile',
      required: true,
      props: {
        arrowIconTop: 23,
        showLabel: true,
        values: ['Conservative', 'Moderate', 'Aggressive'],
        restrictedValues: [{ value: 'Existing Custom Profile', exists: null }],
        // note: 'You can change this at any time in your Investment Objectives settings.',
        center: true,
        showArrowIcons: true,
      },
    },
    {
      type: Dropdown,
      typeName: 'Dropdown',
      name: 'total_investible_assets',
      label: 'What are your total investible assets?',
      props: {
        arrowIconTop: 23,
        values: [
          'Less than $25k',
          '$25k - $50k',
          '$50k - $100k',
          '$100k - $250k',
          '$250k - $500k',
          '$500k - $1M',
          'More than $1M',
        ],
        note: 'Optional',
        center: true,
        showArrowIcons: true,
      },
    },
    {
      type: Dropdown,
      typeName: 'Dropdown',
      name: 'annual_income',
      label: 'What is your total annual income?',
      props: {
        arrowIconTop: 23,
        values: ['Less than $25k', '$25k - $50k', '$50k - $100k', 'More than $100k'],
        note: 'Optional',
        center: true,
        showArrowIcons: true,
      },
    },
  ];
};

export const resubmitContactInputs = () => {
  return [
    {
      type: TextField,
      typeName: 'TextField',
      name: 'contact_street_address',
      label: 'Address',
      required: true,
      props: { autoComplete: 'off' },
    },
    {
      type: TextField,
      typeName: 'TextField',
      name: 'contact_apartment',
      label: 'Apartment',
      props: { autoComplete: 'off' },
    },
    {
      type: TextField,
      typeName: 'TextField',
      name: 'contact_city',
      label: 'City',
      required: true,
      props: { autoComplete: 'off' },
    },
    {
      type: Dropdown,
      typeName: 'Dropdown',
      name: 'contact_state',
      label: 'State',
      required: true,
      props: {
        styling: 'dropdown-inline-left',
        size: 'small',
        values: listUsStateNames(),
        showArrowIcons: true,
      },
    },
    {
      type: TextField,
      typeName: 'TextField',
      name: 'contact_postal_code',
      label: 'Zip',
      required: true,
      props: {
        size: 'small',
        props: { autoComplete: 'off' },
      },
    },
    {
      type: Dropdown,
      typeName: 'Dropdown',
      name: 'contact_country',
      label: 'Country',
      required: true,
      props: {
        styling: 'dropdown-inline-left',
        values: ['United States of America'],
        hideLabel: true,
        showArrowIcons: true,
      },
    },
  ];
};

export const contactInputs = () => {
  return [
    {
      type: TextField,
      typeName: 'TextField',
      name: 'given_name',
      label: 'First Name',
      required: true,
      props: {},
    },
    {
      type: TextField,
      typeName: 'TextField',
      name: 'family_name',
      label: 'Last Name',
      required: true,
      props: {},
    },
    {
      type: TextField,
      typeName: 'TextField',
      name: 'contact_street_address',
      label: 'Address',
      required: true,
      props: { autoComplete: 'off' },
    },
    {
      type: TextField,
      typeName: 'TextField',
      name: 'contact_apartment',
      label: 'Apartment',
      props: { autoComplete: 'off' },
    },
    {
      type: TextField,
      typeName: 'TextField',
      name: 'contact_city',
      label: 'City',
      required: true,
      props: { autoComplete: 'off' },
    },
    {
      type: Dropdown,
      typeName: 'Dropdown',
      name: 'contact_state',
      label: 'State',
      required: true,
      props: {
        styling: 'dropdown-inline-left',
        size: 'small',
        values: listUsStateNames(),
        showArrowIcons: true,
      },
    },
    {
      type: TextField,
      typeName: 'TextField',
      name: 'contact_postal_code',
      label: 'Zip',
      required: true,
      props: {
        size: 'small',
        props: { autoComplete: 'off' },
      },
    },
    {
      type: Dropdown,
      typeName: 'Dropdown',
      name: 'contact_country',
      label: 'Country',
      required: true,
      props: {
        styling: 'dropdown-inline-left',
        values: ['United States of America'],
        hideLabel: true,
        showArrowIcons: true,
      },
    },
    {
      type: TextNumberField,
      typeName: 'TextNumberField',
      name: 'phone_number',
      label: 'Phone Number',
      required: true,
      props: {},
    },
  ];
};

export const identityInputs = () => {
  return [
    {
      type: SocialTextField,
      typeName: 'SocialTextField',
      name: 'social_security_number',
      label: 'Social Security Number',
      required: true,
      password: true,
      props: {
        style: { marginTop: '43px' },
      },
    },
    {
      type: DateField,
      typeName: 'DateField',
      name: 'date_of_birth',
      label: 'Date of Birth',
      required: true,
      props: {},
    },
    {
      type: Dropdown,
      typeName: 'Dropdown',
      name: 'citizenship_country',
      label: 'Citizenship Country',
      required: true,
      props: {
        style: { paddingTop: '0px' },
        center: true,
        values: listCountryNames(),
        toggleAdditionalInfoFieldsIf: function(value) {
          return value !== undefined && value !== 'United States of America' && value !== 'Citizenship Country';
        },
        additionalInfoFields: [
          {
            type: ToggleWithLabel,
            typeName: 'ToggleWithLabel',
            name: 'is_permanent_resident',
            label: 'Are you a permanent resident of the USA?',
            required: true,
            props: {
              values: [true, false],
              toggleAdditionalInfoFieldsIf: true,
              additionalClasses: 'toggle-two-lines',
              additionalInfoFields: [
                {
                  type: TextNumberField,
                  typeName: 'TextNumberField',
                  name: 'green_card_number',
                  label: 'USCIS#',
                  required: true,
                  props: {
                    style: { marginTop: '36px' },
                  },
                },
                {
                  type: DateField,
                  typeName: 'DateField',
                  name: 'green_card_expiration_date',
                  label: 'Expiration Date',
                  required: true,
                  props: {
                    yearMin: new Date().getFullYear(),
                    yearMax: parseFloat(new Date().getFullYear()) + 10,
                  },
                },
              ],
              additionalInfoFieldsIfNot: [
                {
                  type: Dropdown,
                  typeName: 'Dropdown',
                  name: 'birth_country',
                  label: 'Birth Country',
                  required: true,
                  props: {
                    styling: 'dropdown-inline-left three-forths-dropdown-width',
                    values: listCountryNames(),
                    showArrowIcons: true,
                  },
                },
                {
                  type: Dropdown,
                  typeName: 'Dropdown',
                  name: 'visa_type',
                  label: 'Visa Type',
                  required: true,
                  props: {
                    styling: 'dropdown-inline-left',
                    size: 'small',
                    values: listVisaTypes(),
                    showArrowIcons: true,
                  },
                },
                {
                  type: TextNumberField,
                  typeName: 'TextNumberField',
                  name: 'visa_number',
                  label: 'Visa#',
                  required: true,
                  props: {
                    style: { marginTop: '36px' },
                  },
                },
                {
                  type: DateField,
                  typeName: 'DateField',
                  name: 'visa_expiration_date',
                  label: 'Visa Expiration Date',
                  required: true,
                  props: {
                    yearMin: new Date().getFullYear(),
                    yearMax: parseFloat(new Date().getFullYear()) + 10,
                  },
                },
              ],
            },
          },
        ],
        showArrowIcons: true,
      },
    },
    {
      type: Checkbox,
      typeName: 'Checkbox',
      name: 'acknowledged_ecbsv_agreement',
      required: true,
      props: {
        values: [true, false],
      },
    },
    {
      type: Hidden,
      typeName: 'Hidden',
      name: 'acknowledged_patriot_act',
    },
  ];
};

export const disclosuresInputs = () => {
  return [
    {
      type: ToggleWithLabel,
      typeName: 'ToggleWithLabel',
      name: 'is_control_person',
      label: 'A director, 10% shareholder, or a senior officer of a <b>publicly traded company.</b>',
      required: true,
      props: {
        values: [true, false],
        toggleAdditionalInfoFieldsIf: true,
        additionalInfoFields: [
          {
            type: TextField,
            typeName: 'TextField',
            name: 'company_symbols',
            label: 'Stock Symbol of Company',
            required: true,
            props: {},
          },
        ],
      },
    },
    {
      type: ToggleWithLabel,
      typeName: 'ToggleWithLabel',
      name: 'is_affiliated_exchange_or_finra',
      label: 'Employed by or associated with a <b>member of</b> either a <b>stock exchange</b> or <b>FINRA</b>.',
      required: true,
      props: {
        values: [true, false],
        toggleAdditionalInfoFieldsIf: true,
        msgIfTrue:
          'If you need duplicate statements and confirms sent to a 3rd party, send us a message at compliance@nvstr.com',
        additionalInfoFields: [
          {
            type: TextField,
            typeName: 'TextField',
            name: 'firm_name',
            label: 'Name of Firm',
            required: true,
            props: {},
          },
        ],
      },
    },
    {
      type: ToggleWithLabel,
      typeName: 'ToggleWithLabel',
      name: 'is_politically_exposed',
      label: 'A senior military, government, or political official in a <b>non-US country.</b>',
      required: true,
      props: {
        values: [true, false],
        toggleAdditionalInfoFieldsIf: true,
        additionalInfoFields: [
          {
            type: TextField,
            typeName: 'TextField',
            name: 'immediate_family',
            label: 'Full Name of Person',
            required: true,
            props: {},
          },
          {
            type: TextField,
            typeName: 'TextField',
            name: 'political_organization',
            label: 'Name of Organization',
            required: true,
            props: {},
          },
        ],
      },
    },
  ];
};

export const agreementsInputs = (hasOptionsTradingExperience) => {
  const base = [
    {
      type: Checkbox,
      typeName: 'Checkbox',
      name: 'acknowledged_account_agreement',
      required: true,
      props: {
        values: [true, false],
      },
    },
  ];
  if (hasOptionsTradingExperience) {
    base.push({
      type: Checkbox,
      typeName: 'Checkbox',
      name: 'acknowledged_options_agreement',
      required: true,
      props: {
        values: [true, false],
      },
    });
  }
  return base;
};

export const serializerDefinitions = {
  serializerFunctions: {
    convertRiskProfile: function(source, data) {
      return convertRiskProfile(source, data);
    },
    convertAnnualIncome: function(source, data) {
      if (source === 'fromApi') {
        if (data === null) {
          return {};
        }
        return convertAnnualIncome(source, { annual_income_usd_min: data });
      }
      return convertAnnualIncome(source, data);
    },
    convertTotalInvestibleAssets: function(source, data) {
      if (source === 'fromApi') {
        if (data === null) {
          return {};
        }
        return convertTotalInvestibleAssets(source, {
          total_investible_assets_usd_min: data,
        });
      }
      return convertTotalInvestibleAssets(source, data);
    },
  },

  serializeFields: {
    risk_profile: 'convertRiskProfile',
    annual_income_usd_min: 'convertAnnualIncome',
    annual_income: 'convertAnnualIncome',
    total_investible_assets_usd_min: 'convertTotalInvestibleAssets',
    total_investible_assets: 'convertTotalInvestibleAssets',
  },

  booleanFields: {},
};

export const convertRiskProfile = (source, risk_profile) => {
  const dict = {
    conservative: 'Conservative',
    moderate: 'Moderate',
    aggressive: 'Aggressive',
    custom: 'Existing Custom Profile',
  };

  if (source === 'fromApi') {
    return dict[risk_profile];
  }
  return reverseKeys(dict)[risk_profile];
};

export const convertAnnualIncome = (source, annualIncome) => {
  const minDict = {
    0: 'Less than $25k',
    25000: '$25k - $50k',
    50000: '$50k - $100k',
    100000: 'More than $100k',
  };
  const maxDict = {
    'Less than $25k': '24999',
    '$25k - $50k': '49999',
    '$50k - $100k': '99999',
    'More than $100k': null,
  };

  if (source === 'fromFront' && !annualIncome) {
    return {};
  }
  if (source === 'fromFront') {
    return {
      annual_income_usd_min: reverseKeys(minDict)[annualIncome],
      annual_income_usd_max: maxDict[annualIncome],
    };
  } else {
    const annualIncomeMin = annualIncome.annual_income_usd_min;
    return { annual_income: minDict[annualIncomeMin] || null };
  }
};

export const convertTotalInvestibleAssets = (source, totalInvestibleAssets) => {
  if (!totalInvestibleAssets) return {};

  const totalInvestibleMin = totalInvestibleAssets.total_investible_assets_usd_min;
  const minDict = {
    0: 'Less than $25k',
    25000: '$25k - $50k',
    50000: '$50k - $100k',
    100000: '$100k - $250k',
    250000: '$250k - $500k',
    500000: '$500k - $1M',
    1000000: 'More than $1M',
  };
  const maxDict = {
    'Less than $25k': '24999',
    '$25k - $50k': '49999',
    '$50k - $100k': '99999',
    '$100k - $250k': '249999',
    '$250k - $500k': '499999',
    '$500k - $1M': '999999',
    'More than $1M': null,
  };

  if (source === 'fromApi') {
    return { total_investible_assets: minDict[totalInvestibleMin] || null };
  }

  return {
    total_investible_assets_usd_min: reverseKeys(minDict)[totalInvestibleAssets],
    total_investible_assets_usd_max: maxDict[totalInvestibleAssets],
  };
};

export const convertCompanySymbols = (symbols) => {
  return symbols.join(', ');
};

export const convertImmediateFamily = (family) => {
  return family.join(', ');
};

export const convertDate = (source, dob, inputName) => {
  if (!dob) {
    return null;
  }
  if (source === 'fromApi') {
    if (!dob) {
      return { year: null, month: null, day: null };
    }

    const dateArray = dob.split('-');
    const year = dateArray[0];
    const month = getMonthFromNumber(dateArray[1]);
    const day = dateArray[2];
    return { [inputName]: { year, month, day } };
  }
  const { year, month, day } = dob;
  return `${year}-${getMonthFromString(month)}-${day}`;
};

export const convertSocialSecurityNumber = (source, socialSecurityNumber) => {
  if (source === 'fromApi') {
    if (socialSecurityNumber === '***-**-****') {
      return '';
    }
    return socialSecurityNumber;
  }

  if (socialSecurityNumber[0] === '*') {
    return {};
  }
  return socialSecurityNumber;
};

export const convertBoolToYesNo = (source, value) => {
  if (value === null) {
    return null;
  }

  if (source === 'fromApi') {
    return !!value && value.toLowerCase() === 'yes';
  }
  return value ? 'YES' : 'NO';
};

export const convertAcknowledgementToBool = (source, value) => {
  if (source === 'fromApi') {
    return {
      acknowledged_account_agreement: !!value,
    };
  }
  return { acknowledged_agreements_at: !!value };
};

export const calcLTVEventTotalBasedOnDeposit = (amount) => {
  if (amount < 25) return 0;
  if (amount > 100000) return 21;
  return Math.round(2.93 * Math.pow(amount, 0.171));
};

const sentLTStartStorageKey = 'started-lt-onboarding';
export const hasSentLiveTradingStartLTVEvent = () => {
  try {
    const storedValue = window.localStorage.getItem(sentLTStartStorageKey);
    return storedValue === '1';
  } catch {
    return false;
  }
};

export const setHasSentLiveTradingStartLTVEvent = () => {
  try {
    window.localStorage.setItem(sentLTStartStorageKey, '1');
  } catch {
    return false;
  }
};

export const getVerifiableBankAccountIdsFromState = (state) => {
  //! very expensive! since returning new object every run
  const liveAccountStore = state.liveAccount;
  const liveAccounts = liveAccountStore.liveAccounts;

  if (!liveAccounts) {
    return [];
  }

  const bankAccountsLookup = liveAccounts.accounts || {};
  const bankAccountIds = Object.keys(bankAccountsLookup);
  const bankAccounts = bankAccountIds.map((id) => bankAccountsLookup[id]);
  return bankAccounts.filter((acct) => acct.is_balance_verifiable).map((acct) => acct.id);
};
