import React, { Component } from 'react';
import withWindowSize from '../../HOCS/withWindowSize';
import { DropdownItem } from './components/DropdownItem';

import { throwError } from '../../../helpers/devToolHelpers';
import styled from 'styled-components';

/**
 * WARNING:::::::: React click event listeners will not fire in this component
 *
 * Parent of this component needs to have position relative around the click area for this to correctly track click events
 */

const Wrapper = styled.ul`
  li {
    * {
      color: ${({ theme }) => theme.themeColors.text} !important;
    }
  }
  li:hover {
    * {
      color: ${({ theme }) => theme.themeColors.primaryCtaButton} !important;
    }
  }
`;

const DropdownWrapper = styled.div`
  background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};
`;

class NewGenericDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _isVisible: false,
      _usingHeight: false,

      width: null, // TODO: Keep track of width for positioning
      containerHeight: null,
    };

    this._heightBuffer = 75;
  }

  componentDidMount() {
    this._setShowDropdownListener();
    this._setHideDropdownListener();
    this._handleResize();

    // did dropdown exceed window height
    if ($(this.DropdownContainerNode).height() > this.props.windowHeight - this._heightBuffer) {
      this._setUsingHeight();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // did dropdown exceed window height
    if (
      $(this.DropdownContainerNode).height() > this.props.windowHeight - this._heightBuffer &&
      !this.isUsingHeight()
    ) {
      this._setUsingHeight();
    }

    // did become visible
    if (!this.isVisible(prevState) && this.isVisible()) {
      this._handleResize();
      this._setDropdownBodyClickListener();
    }
    // did become visible
    if (this.isVisible(prevState) && !this.isVisible()) {
      this._removeDropdownBodyClickListener();
    }
  }

  componentWillUnmount() {
    this._removeShowDropdownListener();
    this._removeHideDropdownListener();
  }

  render() {
    // NOTE: the element that is displaying this dropdown and the focal point for the display should be `css positioned` and there should be no other elements in-between that are positioned. Otherwise the monitor will fail to accurately size itself.
    return (
      <div className="react-dropdown-wrapper">
        <div className="react-dropdown-montior" ref={(el) => (this.DropdownMonitorNode = el)} />
        {this.isVisible() &&
          this.props.returnDropdownButtonOverlayElement &&
          this.props.returnDropdownButtonOverlayElement()}
        {this.isVisible() && (
          <DropdownWrapper
            id={this.props.id}
            className={`react-dropdown fade-in-up-quick ${this.props.customClass}`}
            ref={(el) => (this.DropdownContainerNode = el)}
            style={this._returnDropdownStyle()}
          >
            <div
              className="react-dropdown-body selectable-dropdown-list-color-styling"
              style={this.isUsingHeight() ? { maxHeight: `${this.state.containerHeight - 2}px` } : {}}
            >
              {this._renderDropdownItems()}
            </div>
          </DropdownWrapper>
        )}
      </div>
    );
  }

  _renderDropdownItems = () => {
    return (
      <Wrapper className="generic-dropdown-item-list-container">
        {this._returnDropdownItems().map((itemData, i) => (
          <DropdownItem key={itemData.key || i} itemData={itemData} closeDropdown={this.handleHideDropdown} />
        ))}
      </Wrapper>
    );
  };

  _returnDropdownItems = () => this.props.dropdownItemDataList;

  isUsingHeight = (state = this.state) => state._usingHeight;

  isVisible = (state = this.state) => state._isVisible;

  handleShowDropdown = () => {
    if (!this.isVisible()) {
      if (this.props.additionalShowFunc) {
        this.props.additionalShowFunc();
      }
      this._setVisible();
    }
  };

  handleHideDropdown = () => {
    if (this.isVisible()) {
      if (this.props.additionalHideFunc) {
        this.props.additionalHideFunc();
      }
      this._setInvisible();
    }
  };

  _handleResize = () => {
    const dropdownHeightToWindowHeightOffset = 125;
    const height = this.props.windowHeight - dropdownHeightToWindowHeightOffset;
    if (this.state.containerHeight !== height) {
      this._setContainerHeight(height);
    }
  };

  _returnDefaultStyling = () => ({ width: '150px' });

  _returnDropdownStyle = () => ({
    ...this._returnDefaultStyling(),
    ...this.adjustTopPosition(),
    ...this.adjustLeftPositionBasedOnWidth(),
    ...this.props.style,
    ...(this.isUsingHeight() ? { maxHeight: `${this.state.containerHeight}px` } : {}),
  });

  adjustTopPosition = () => {
    if (this.props.topOffset && this.props.topOffset.indexOf('%') >= 0) {
      throwError('Percentage offsets are not supported');
    }
    const height = $(this.DropdownMonitorNode).height();
    const defaultOffset = 9;
    const offset = this.props.topOffset ? this.props.topOffset.split('px')[0] : defaultOffset;
    const top = `${parseFloat(height) + parseFloat(offset)}px`;
    return { top };
  };

  adjustLeftPositionBasedOnWidth = () => {
    if (this.props.style.width && this.props.style.width.indexOf('%') >= 0) {
      throwError('Percentage widths are not supported', `supplied => ${this.props.style.width}`);
    }
    const monitorWidth = $(this.DropdownMonitorNode).width();
    const width = this.props.style.width ? this.props.style.width.split('px')[0] : 150;
    const offset = monitorWidth / 2 + 17;
    const left = `-${parseFloat(width) - parseFloat(offset)}px`;
    return { left };
  };

  _onDropdownMonitorClick = (e) => {
    e.stopPropagation();
    this.handleShowDropdown();
  };

  _onDropdownClick = (e) => {
    e.stopPropagation();
    // this.handleShowDropdown();
  };

  _setDropdownBodyClickListener = () => {
    $(this.DropdownContainerNode).on('click', this._onDropdownClick);
  };

  _removeDropdownBodyClickListener = () => {
    $(this.DropdownContainerNode).off('click', this._onDropdownClick);
  };

  _setShowDropdownListener = () => {
    $(this.DropdownMonitorNode).on('click', this._onDropdownMonitorClick);
  };

  _removeShowDropdownListener = () => {
    $(this.DropdownMonitorNode).off('click', this._onDropdownMonitorClick);
  };

  _setHideDropdownListener = () => {
    $(document).on('click', this.handleHideDropdown);
  };

  _removeHideDropdownListener = () => {
    $(document).off('click"', this.handleHideDropdown);
  };

  _setUsingHeight = () => {
    this.setState(() => ({
      _usingHeight: true,
    }));
  };

  _setVisible = () => {
    this.setState(() => ({
      _isVisible: true,
    }));
  };

  _setInvisible = () => {
    this.setState(() => ({
      _isVisible: false,
    }));
  };

  _setContainerHeight = (height) => {
    this.setState(() => ({
      containerHeight: height,
    }));
  };
}

export default withWindowSize(NewGenericDropdown);
