import { BASEURL } from '.';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const applicationAdapter = {
  fetchDataTypes: (field) => {
    return sendRequest('get', [`${URL}/${field}_types`]).then((response) => (response ? response.data : response));
  },

  getIsMarketOpen: () => {
    const options = { ignoreAuth: true };
    return sendRequest('get', [`${URL}/v1/util/market_open`], options);
  },

  registerAttributionIdentifiers: (attributionIdentifiers) => {
    const body = { identifiers: attributionIdentifiers };
    const options = { ignoreAuth: true };
    return sendRequest('post', [`${URL}/user_attribution_identifiers`, body], options);
  },

  fetchAboveFeedBannerData: ({ bannerType, dispatch }) => {
    const options = { ignoreAuth: true };
    return sendRequest('get', [`${URL}/above_feed_banner?type=${bannerType}`], options);
  },

  fetchEmailInviteCopy: ({ dispatch }) => {
    const options = { ignoreAuth: true };
    return sendRequest('get', [`${URL}/v1/invites/invite_email_text`], options);
  },

  getStaticCopy: () => {
    const options = { ignoreAuth: true };
    return sendRequest('get', [`${URL}/v1/static`], options);
  },
};
