import React from 'react';
import { connect } from 'react-redux';

import VerticalNavItem from '../../components/VerticalNavItem';
import RewardsCounter from '../../../../containers/Counters/RewardsCounter';

import { isReferralProgramActive } from '../../../../helpers/currentUserHelpers';
import RewardIcon from '@src/main/components/Images/RewardIcon';

const Rewards = (props) => {
  const {
    isReferralProgramActive,

    notRedeemedCount,
  } = props;

  const hasRedeemableRewards = notRedeemedCount > 0;

  const showRoute = hasRedeemableRewards || isReferralProgramActive;

  return (
    showRoute && (
      <VerticalNavItem
        name="Rewards"
        customIcon={<RewardIcon />}
        customAdditionalElement={
          <RewardsCounter className="vertical-nav-notifications-counter notification-circle-color" />
        }
        route="/referrals"
        accentedTextColor
      />
    )
  );
};

const mapStateToProps = (state) => {
  const rewardsStore = state.rewards;
  return {
    isReferralProgramActive: isReferralProgramActive(state.currentUser),
    notRedeemedCount: rewardsStore.not_redeemed_count,
  };
};

export default connect(mapStateToProps)(Rewards);
