import { thoughtsAdapter } from '../adapters/thoughtsAdapter';

import { quickFetchSecuritiesData } from './securitiesActions';

import {
  ADD_THOUGHTS,
  CREATE_THOUGHT,
  UPDATE_THOUGHT,
  LOADING_THOUGHTS_FOR_SECURITY,
  TRACK_SUGGESTED_THOUGHT,
  ADD_KARMA_POINTS,
  REACHED_MAX_DAILY_KARMA,
} from '../constants';

import {
  updateThought,
  saveImpressionToStorageIfUnrecorded,
  getKarmaGainStructFromThought,
  getKarmaValueFromKarmaGainStruct,
  didReachMaxDailyKarma,
} from '../helpers/thoughtsHelpers';

import { throwError } from '../helpers/devToolHelpers';

export const createThought = (thought) => {
  return function (dispatch) {
    return thoughtsAdapter.createThought(thought).then((response) => {
      if (response && response.status === 200 && response.data?.thoughts) {
        const savedThought = response.data.thoughts[0];
        const didThoughtSave = savedThought && savedThought.id > 0;
        if (didThoughtSave) {
          dispatch({
            type: CREATE_THOUGHT,
            payload: {
              thought: savedThought,
            },
          });
        } else {
          return {
            error: true,
            thoughtsWithErrors: [savedThought],
          };
        }
      } else {
        return {
          error: true,
        };
      }
      return response;
    });
  };
};

export const getSecurityIdsFromThoughts = (thoughts) => {
  const securityIds = [];
  thoughts?.forEach((thought) => {
    const security_id = thought ? thought.security_id : null;
    if (!security_id) return;

    if (!securityIds.includes(security_id)) securityIds.push(security_id);
  });
  return securityIds;
};

export const fetchThoughts = (security_ids) => {
  if (!Array.isArray(security_ids)) {
    console.error('security_ids should be an array of security ids');
    return false;
  }
  return function (dispatch) {
    dispatch({
      type: LOADING_THOUGHTS_FOR_SECURITY,
      payload: security_ids,
    });

    return thoughtsAdapter.fetchThoughts(security_ids).then((response) => {
      if (response && response.data && 'thoughts' in response.data) {
        const thoughts = response.data.thoughts;
        const thoughtLookup = {};
        thoughts.forEach((thought) => {
          const securityId = thought.security_id;
          if (!thoughtLookup[securityId]) {
            thoughtLookup[securityId] = [thought];
          } else {
            thoughtLookup[securityId].push(thought);
          }
        });
        const payload = security_ids.map((id) => ({
          securityId: id,
          thoughts: thoughtLookup[id],
        }));
        dispatch({
          type: ADD_THOUGHTS,
          payload,
        });
      }
      return response;
    });
  };
};

export const getThoughtData = (thoughtId) => {
  return function (dispatch) {
    return thoughtsAdapter.getThoughtData(thoughtId).then((response) => {
      if (!response || !response.data) {
        return response;
      }

      const thought = response.data;
      const securityId = thought.security_id;
      dispatch({
        type: ADD_THOUGHTS,
        payload: [
          {
            securityId,
            thoughts: [thought],
          },
        ],
      });
      return response;
    });
  };
};

export const fetchRecentThoughts = (params) => {
  return function (dispatch) {
    return thoughtsAdapter.fetchRecentThoughts(params).then((response) => {
      const { status, errorMessage } = response;

      if (status === 200 && response?.data?.thoughts) {
        const thoughts = response.data.thoughts;
        const thoughtLookup = {};
        thoughts.forEach((thought) => {
          const securityId = thought.security_id;
          if (!thoughtLookup[securityId]) {
            thoughtLookup[securityId] = [thought];
          } else {
            thoughtLookup[securityId].push(thought);
          }
        });

        const securityIds = getSecurityIdsFromThoughts(thoughts);
        quickFetchSecuritiesData(securityIds)(dispatch);

        dispatch({
          type: ADD_THOUGHTS,
          payload: securityIds.map((id) => ({
            securityId: id,
            thoughts: thoughtLookup[id],
          })),
        });

        return {
          ok: true,
          thoughts,
        };
      } else {
        return {
          error: true,
          errorMessage,
        };
      }
    });
  };
};

export const sendThoughtOpinion = (thought, opinion, currentUserId) => {
  return function (dispatch) {
    const updatedThought = updateThought(thought, opinion, currentUserId);
    if (opinion === 'agree' || opinion === 'disagree') {
      dispatch({
        type: UPDATE_THOUGHT,
        payload: { thought: updatedThought },
      });
    }

    return thoughtsAdapter.sendThoughtOpinion(updatedThought).then((response) => {
      if (response && response.data && (response.status === 200 || response.status === 204)) {
        const thought = response.data;

        const karma = getKarmaGainStructFromThought(thought);
        if (!!karma) {
          const karmaPointsEarned = getKarmaValueFromKarmaGainStruct(karma);

          if (didReachMaxDailyKarma(karma) && karmaPointsEarned === 0) {
            dispatch({
              type: REACHED_MAX_DAILY_KARMA,
            });
          } else {
            dispatch({
              type: ADD_KARMA_POINTS,
              payload: karmaPointsEarned,
            });
          }
        }
        return {};
      } else {
        // revert thought opinion, return an error message
        dispatch({
          type: UPDATE_THOUGHT,
          payload: { thought: thought },
        });
        return {
          error: true,
          opinion,
        };
      }
    });
  };
};

export const getThoughtFeedSuggestion = (data) => {
  return function (dispatch) {
    return thoughtsAdapter.getThoughtFeedSuggestion(data).then((response) => {
      const wasSuccess = response && response.data && response.data.thoughts && response.data.thoughts.length > 0;
      if (wasSuccess) {
        const thoughts = response.data.thoughts;
        const thought = thoughts[0];
        dispatch({
          type: ADD_THOUGHTS,
          payload: [{ thoughts, securityId: thought.security_id }],
        });
        dispatch({
          type: TRACK_SUGGESTED_THOUGHT,
          payload: thought.id,
        });
      }
      return response;
    });
  };
};

export const sendThoughtViewEvent = (id, context, metadata, notificationIndex) => {
  if (!id || typeof id !== 'number') {
    throwError('Invalid id sent to sendThoughtClickEvent', true);
  }
  return function () {
    return thoughtsAdapter.sendThoughtViewEvent(id, context, metadata, notificationIndex);
  };
};

export const sendThoughtClickEvent = (id, clickType, context, metadata, notificationIndex) => {
  const clickTypes = ['text', 'link', 'agree', 'disagree', 'more', 'share'];
  if (!clickTypes.includes(clickType) || !id || typeof id !== 'number') {
    throwError('Invalid click type or id sent to sendThoughtClickEvent', true);
  }
  saveImpressionToStorageIfUnrecorded('view', id, context);
  return function () {
    return thoughtsAdapter.sendThoughtClickEvent(id, clickType, context, metadata, notificationIndex);
  };
};
