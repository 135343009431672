export const buildFileData = (file, reader) => {
  const url = reader.result;
  const base64ImageData = url;
  const contentType = url.split('data:')[1].split(';')[0];

  const byteCharacters = atob(base64ImageData.substr(`data:${contentType};base64,`.length));
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  const documentBlob = URL.createObjectURL(blob);

  return {
    file,
    reader,
    url,
    blob,
    documentBlob,
  };
};
