import React, { Component } from 'react';

import RailsNotification from './RailsNotification';
import NewsComponent from '../../News/NewsComponent';

import IdeaPerformanceNotification from './types/IdeaPerformanceNotification';
import PerformanceRankingNotification from './types/PerformanceRankingNotification';
import ThoughtFeedContainer from '../../Thoughts/ThoughtFeedContainer';
import AskNotification from './types/AskNotification';
import { ConnectionNotification } from './types/ConnectionNotification';
import OrderNotification from './types/OrderNotification';
import IdeaActionNotification from './types/IdeaActionNotification';
import { IdeaPriceTargetNotification } from './types/IdeaPriceTargetNotification';
import ForwardEPSChangeNotification from './types/ForwardEPSChangeNotification';
import BasicSecurityDataWrapper from '../../SecurityData/BasicSecurityDataWrapper';

import { isRailsNotificationTemplate } from '../../../helpers/notificationsHelpers';
import { getThoughtImpressionEventName } from '../../../constants/tracking';
import { NewsArticle } from '@src/containers/News/NewNewsArticle';

/*
  ask: this._renderAskNotification,
  idea_action: this._renderIdeaActionNotification,
  idea_performance: this._renderIdeaPerformanceNotification,
  price_target: this._renderIdeaPriceTargetNotification,
  news: this._renderNewsNotification,
  connection: this._renderConnectionNotification,
  order: this._renderOrderNotification,
  thought: this._renderThoughtNotification,
  thought_clean_style: this._renderSocialThoughtNotification,
*/

class Notification extends React.PureComponent {
  constructor() {
    super();

    this.NOTIFICATION_KEYS_WITH_LOADING_EVENTS = ['ask', 'thought', 'thought_clean_style'];

    this.RENDERER_LOOKUP_TABLE = {
      ask: this._renderAskNotification,
      connection: this._renderConnectionNotification,
      forward_eps_change: this._renderForwardEPSChangeNotification,
      idea_action: this._renderIdeaActionNotification,
      idea_performance: this._renderIdeaPerformanceNotification,
      news: this._renderNewsNotification,
      order: this._renderOrderNotification,
      performance_ranking: this._renderPerformanceRankingNotification,
      price_target: this._renderIdeaPriceTargetNotification,
      thought: this._renderThoughtNotification,
      thought_clean_style: this._renderSocialThoughtNotification,
    };
  }

  componentDidMount() {
    // check notif type, send onLoadCompleteEvent if notif doesn't have a load
    const notificationType = this._returnNotificationType();
    const hasLoadingHandler = this.NOTIFICATION_KEYS_WITH_LOADING_EVENTS.includes(notificationType);
    if (!hasLoadingHandler) {
      this.props.onLoadComplete();
    }
  }

  render() {
    const notification = this._returnNotification();
    return isRailsNotificationTemplate(notification)
      ? this._renderRailsNotification(notification)
      : this._renderNonRailsNotification(notification);
  }

  _renderNewsNotification = () => {
    const { notification, notificationIndex } = this.props;
    return (
      <NewsArticle
        securityId={notification.security_id}
        notificationIndex={notificationIndex}
        newsData={notification.data}
        viewingContext={'Feed'}
        scrollContainerSelector={window}
        recordImpressionOnMount={this.props.recordImpressionOnMount}
        size={this.props.size}
        inFeed={this.props.inFeed}
        inActivityPageFeed={this.props.inActivityPageFeed}
        isCurrentUser
        isNotification
        isCurrentUserAuthed
      />
    );
  };

  _renderIdeaPerformanceNotification = () => {
    const { notification } = this.props;
    return <IdeaPerformanceNotification notification={notification} />;
  };

  _renderPerformanceRankingNotification = () => {
    const { notification } = this.props;
    return <PerformanceRankingNotification notification={notification} />;
  };

  _renderIdeaPriceTargetNotification = () => {
    const { notification } = this.props;
    return <IdeaPriceTargetNotification notification={notification} />;
  };

  _renderForwardEPSChangeNotification = () => {
    const { notification } = this.props;
    return <ForwardEPSChangeNotification notification={notification} />;
  };

  _renderIdeaActionNotification = () => {
    const { notification } = this.props;
    return <IdeaActionNotification notification={notification} />;
  };

  _renderConnectionNotification = () => {
    const { notification } = this.props;
    return <ConnectionNotification notification={notification} />;
  };

  _renderOrderNotification = () => {
    const { notification } = this.props;
    return <OrderNotification notification={notification} />;
  };

  _renderThoughtNotification = () => {
    const { notification, notificationIndex } = this.props;
    return (
      <BasicSecurityDataWrapper
        securityId={notification.data.security.security_id}
        ignoreImpressionTracking={!this.props.isNotificationListVisible || !this.props.isNotificationBatchLoaded}
        onLoadComplete={this.props.onLoadComplete}
        key={`${notification.data.thought.id}-${notification.data.time}`}
      >
        <ThoughtFeedContainer
          ignoreImpressionTracking={!this.props.isNotificationListVisible || !this.props.isNotificationBatchLoaded}
          startingList={[{ notification }]}
          viewingContext={getThoughtImpressionEventName('THOUGHT_CAROUSEL_IN_FEED')}
          inFeed={this.props.inFeed}
          inActivityPageFeed={this.props.inActivityPageFeed}
          notificationIndex={notificationIndex}
          showSeeAllThoughtsLink
          linkToSecurity
          withThoughtLink
        />
      </BasicSecurityDataWrapper>
    );
  };

  _renderSocialThoughtNotification = () => {
    const { notification, notificationIndex } = this.props;
    return (
      <BasicSecurityDataWrapper
        securityId={notification.data.security.security_id}
        ignoreImpressionTracking={!this.props.isNotificationListVisible || !this.props.isNotificationBatchLoaded}
        onLoadComplete={this.props.onLoadComplete}
        key={`${notification.data.thought.id}-${notification.data.time}`}
      >
        <ThoughtFeedContainer
          ignoreImpressionTracking={!this.props.isNotificationListVisible || !this.props.isNotificationBatchLoaded}
          startingList={[{ notification }]}
          viewingContext={getThoughtImpressionEventName('THOUGHT_CAROUSEL_IN_FEED')}
          inFeed={this.props.inFeed}
          inActivityPageFeed={this.props.inActivityPageFeed}
          notificationIndex={notificationIndex}
          thoughtCleanStyle
          linkToSecurity
          withThoughtLink
        />
      </BasicSecurityDataWrapper>
    );
  };

  _renderAskNotification = () => (
    <AskNotification
      notification={this.props.notification}
      ignoreImpressionTracking={!this.props.isNotificationListVisible || !this.props.isNotificationBatchLoaded}
      onLoadComplete={this.props.onLoadComplete}
      notificationIndex={this.props.notificationIndex}
      inActivityPageFeed={this.props.inActivityPageFeed}
      inFeed={this.props.inFeed}
    />
  );

  _returnDefaultComponentRenderer = () => <div></div>;

  _convertNotificationTypeToRender = () => {
    const notificationType = this._returnNotificationType();
    const renderer = this.RENDERER_LOOKUP_TABLE[notificationType];
    if (!renderer) {
      console.error(`Could not find renderer for notification type: ${notificationType}`);
    }

    return renderer || this._returnDefaultComponentRenderer;
  };

  _returnNotificationComponentToRender = () => {
    const componentRenderer = this._convertNotificationTypeToRender();
    return componentRenderer();
  };

  _renderNonRailsNotification = () => {
    return (
      <li className={`notification ${this.props.inFeed ? '' : 'row'}`}>
        {this._returnNotificationComponentToRender(this._returnNotification())}
      </li>
    );
  };

  _renderRailsNotification = () => {
    return <RailsNotification notification={this._returnNotification().prerendered_html} />;
  };

  _returnNotification = () => this.props.notification;

  _returnNotificationType = () => this._returnNotification().type || 'rails';
}

export default Notification;
