import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import { returnPathTo } from '../../constants/paths';
import * as Actions from '../../actions/index';

import PositionVisualizer from './PositionVisualizer';

import PageLoading from '../../components/PageLoading';
import Page from '../../components/layout/Page';
import LinkTo from '../Links/LinkTo';
import { PageHeading } from '../../components/UI/PageHeading';

import { createQueryString } from '../../helpers/routerHelpers';
import { returnBasicSomethingWentWrongErrorModalPayload } from '../../constants/modals';
import { FlatButton } from '../../main/components/buttons';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
  max-width: 520px;
  margin: 0 auto;

  padding: 24px 0 0 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const SeeIdeasButton = ({ collectiveId, onClick }) => {
  const navigate = useNavigate();
  const url = returnPathTo('profile', collectiveId);
  const hc = () => {
    onClick();
    navigate(url);
  };
  return (
    <FlatButton transparent small onClick={hc}>
      See Ideas
    </FlatButton>
  );
};

class IdeaLab extends Component {
  constructor() {
    super();
    this.state = {
      _isCommunityIdeaSecuritiesLoading: true,
      _communityIdeaSecurities: [],
    };
  }

  componentDidMount() {
    this._getCommunityIdeas().then((response) => {
      this._handleGetCommunityIdeasResponse(response);
    });
    this._updatePageTitle();
    this._logViewPageEvent();
  }

  render() {
    return (
      <div className={'community-page-container'}>
        {!this._isCollectivesLoading() && this.renderCollectivesHeading()}
        <Page>
          <PageHeading className="community-page-heading">Popular Ideas in the Community</PageHeading>
          <div className="idea-lab-container">
            {this._isLoading() ? (
              <PageLoading flatStyle />
            ) : (
              <PositionVisualizer
                navigate={this.props.navigate}
                location={this.props.location}
                securities={this.returnCommunityIdeasSecuritiesList()}
                openExpandedSecurityCard={this.openExpandedSecurityCard}
              />
            )}
          </div>
        </Page>
      </div>
    );
  }

  renderCollectivesHeading = () => {
    return (
      <Wrapper>
        <span className={'message'}>Check out the ideas of the current top performers in {PRODUCT_DISPLAY_NAME}.</span>
        <SeeIdeasButton onClick={this._logSeeCollectiveIdeasClick} collectiveId={this._returnCollectiveId()} />
      </Wrapper>
    );
  };

  returnCommunityIdeasSecuritiesList = () => this.state._communityIdeaSecurities;

  openExpandedSecurityCard = (query) => {
    const queryString = createQueryString(query);
    this.props.navigate(location.pathname + queryString);
  };

  _setLoadingComplete = () => {
    this.setState(() => ({
      _isCommunityIdeaSecuritiesLoading: false,
    }));
  };

  _saveSecuritiesToCommunityIdeaSecurities = (securities) => {
    this.setState(() => ({
      _communityIdeaSecurities: securities,
    }));
  };

  _getCommunityIdeas = () => this.props.actions.fetchCommunityIdeas();

  _handleGetCommunityIdeasResponse = (response) => {
    if (response && response.data && response.data.securities) {
      this._saveSecuritiesToCommunityIdeaSecurities(response.data.securities);
    } else {
      this.props.actions.showModal(returnBasicSomethingWentWrongErrorModalPayload());
    }
    this._setLoadingComplete();
  };

  _isCommunityIdeaSecuritiesLoading = () => this.state._isCommunityIdeaSecuritiesLoading;

  _isLoading = () => this._isCommunityIdeaSecuritiesLoading() || this._isCollectivesLoading();

  _returnCollectivesStore = () => this.props.collectives;

  _returnCollectivesUsersLookup = () => this.props.collectives.lookup;

  _isCollectivesLoading = () => this._returnCollectivesStore().loading;

  _returnCollectiveId = () => Object.keys(this.props.collectives.lookup)[0];

  _returnCollectiveUser = () => this._returnCollectivesUsersLookup()[this._returnCollectiveId()];

  _updatePageTitle = () => {
    document.title = `${PRODUCT_DISPLAY_NAME} - Community`;
  };

  _logViewPageEvent = () => {
    const event = 'View Ideas Community';
    const properties = {
      'From React Router': true,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logSeeCollectiveIdeasClick = () => {
    const event = 'Clicked See Collective Ideas';
    const properties = {
      Context: 'Community Page',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    collectives: state.collectives,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdeaLab);
