import React, { Component } from 'react';
import { Page } from '@src/main/components/layout';
import { Body5, H5 } from '@src/main/lib/nvstr-common-ui.es';
import { Container } from '@src/main/components/ui';
import { PRODUCT_NAME } from '@src/appConfig';
import { logMetricsTrackingEvent } from '@src/actions';
import { MyIdeasList } from '../containers/Securities/MyIdeasList';
import styled from 'styled-components';
import SearchContainer from '../../containers/UI/Search/SearchContainer';
import { findUserIdeaForSecurity } from '../../helpers/ideaHelpers';
import { returnCurrentUserThemeTestGroups } from '../../helpers/currentUserHelpers';
import { sendFacebookTrackingEvent } from '../../constants/facebookTracking';
import { TrackingEvents } from '../../utils/tracking/events';
import { openSecurityPanel } from '../../helpers/securityPanelHelpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  addIdeas,
  fetchSecuritiesPriceData,
  getIdeasForSecurity,
  quickFetchSecuritiesData,
  updateIdea,
} from '../../actions';
import { useCurrentUserId, useIsLiveTrading } from '../hooks/user';
import { SearchIconWrap } from '../../containers/UI/Search/components/SearchInput';
import { activeSecuritiesTracker, LivePricePollingManager } from '../classes/LivePricePollingManager';

const HeadingWrapper = styled.div`
  padding: 0 24px;

  @media (max-width: 450px) {
    padding: 0 8px;
  }
`;
const AddToWatchlistWrapper = styled.div`
  ${SearchIconWrap} {
    top: 16px;

    svg {
      height: 22px;
      width: 22px;

      path {
        fill: ${({ theme }) => theme.themeColors.text};
      }
    }
  }
  input {
    font-size: 16px;
    line-height: 18px;

    border: 1px solid ${({ theme }) => theme.themeColors.text} !important;
    border-radius: 5px !important;
    padding: 16px 16px 16px 40px;
  }
  .default-search-container {
    max-width: 300px;
  }
  .default-search-container .search-results-container {
    left: 0px;
    right: 0px;
  }
  .default-search-container i.query-icon {
    left: 13px;
    z-index: 5;
  }
`;

const AddToWatchlist = ({}) => {
  const dispatch = useDispatch();
  const userId = useCurrentUserId();
  const isLiveTrading = useIsLiveTrading();
  const ideaList = useSelector((state) => state.ideas.ideaList);

  const _fetchDataForSecurity = async (securityId) => {
    fetchSecuritiesPriceData([securityId])(dispatch);
    getIdeasForSecurity([securityId])(dispatch);
    const response = await quickFetchSecuritiesData([securityId])(dispatch);
    const securities = response?.securities;
    if (securities && securities[0]) {
      const security = securities[0];
      const d = {
        id: security.id,
        symbol: security.symbol,
      };

      activeSecuritiesTracker.addSecurities([d]);
    }
  };

  const _createIdeaFromSearchQuery = (query) => {
    if (!query) {
      // means they clicked something that wasn't a search result that triggered action so do nothing
      return false;
    }
    const querySecurity = query;
    const securityId = querySecurity.id;
    _fetchDataForSecurity(securityId);

    const existingIdea = findUserIdeaForSecurity(userId, securityId, ideaList);
    const doesUserHaveAnExistingIdea = !!existingIdea;
    if (doesUserHaveAnExistingIdea) {
      const updatedIdea = {
        ...existingIdea,
        active: true,
      };
      updateIdea(updatedIdea, existingIdea)(dispatch);
    } else {
      addIdeas([{ security: { security_id: securityId } }])(dispatch).then((response) => {
        const ideasWithErrors = response?.ideas?.filter((idea) => idea.status === 'error');
        if (ideasWithErrors.length === 0) {
          const params = {
            accountType: isLiveTrading ? 'Live' : 'Paper',
          };
          sendFacebookTrackingEvent('Added Idea', params);
          TrackingEvents.ideas.ADD_IDEA.send();
        }
      });
    }
  };
  return (
    <AddToWatchlistWrapper className={`add-to-watchlist-container`}>
      <SearchContainer
        id="add-to-watchlist-search"
        showPlus
        autoFocus={false}
        placeholder={'Add to Watchlist'}
        handleSecurityQuerySelection={_createIdeaFromSearchQuery}
        dismissAfterSelection
      />
    </AddToWatchlistWrapper>
  );
};

export const Watchlist = () => {
  React.useEffect(() => {
    document.title = `${PRODUCT_NAME} - Watchlist`;

    const event = 'View My Ideas';
    const properties = {};
    logMetricsTrackingEvent(event, properties)();
  }, []);

  return (
    <Page width={'800px'} noPadding>
      <HeadingWrapper>
        <Container top={20}>
          <H5>Watchlist</H5>
        </Container>
        {null && (
          <Container top={8}>
            <Body5>Keep your watchlist stocks organized</Body5>
          </Container>
        )}
      </HeadingWrapper>

      <Container top={24} left={16}>
        <AddToWatchlist />
      </Container>
      <Container top={24}>
        <MyIdeasList />
      </Container>
    </Page>
  );
};
