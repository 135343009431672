import React from 'react';
import OpenRebalancerPanelButton from './OpenRebalancerPanelButton';
import OpenOptimizerPanelButton from './OpenOptimizerPanelButton';
import { getIsUsingRebalancerForPortfolioOptimization } from '../../helpers/portfolioOptimizationHelpers';

class OpenOptimizationPanelButton extends React.PureComponent {
  render() {
    const isUsingRebalancerForOptimization = getIsUsingRebalancerForPortfolioOptimization();
    return isUsingRebalancerForOptimization ? <OpenRebalancerPanelButton /> : <OpenOptimizerPanelButton />;
  }
}

export default OpenOptimizationPanelButton;
