import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import NewsList from './NewsList';
import PageLoading from '../../components/PageLoading';
import Icon from '../../components/misc/Icon';

import { InfiniteScroll } from '../Scroll/InfiniteScroll';

import { moment, formatLocalizedDateTime } from '../../helpers/timeHelpers';

class NewsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdatingNews: false,
      isLoadingMoreNewsArticles: false,
      noNewsLogSent: false,
    };
  }

  componentDidMount() {
    if (!this._isNewsDataAlreadyAvailable()) {
      this._fetchNews();
    }
  }

  componentWillUnmount() {
    this.props.actions.clearNewsDataForSecurity(this._returnSecurityId());
  }

  componentDidUpdate() {
    if (this._isNoNews() && !this.state.noNewsLogSent) {
      this._handleLoggingOfNoNews();
    }
  }

  render() {
    return <div className={`news-container`}>{this._renderNewsContainer()}</div>;
  }

  _renderNewsContainer = () => {
    if (this._isLoading()) {
      return this._renderLoading();
    } else if (this._wasErrorGettingNews()) {
      return this._renderError();
    } else {
      return (
        <InfiniteScroll
          id="security-news-list-infinite-scroll"
          $scrollElement={this.props.isInPanel ? $('.scroll-panel-container') : $(window)}
          onInfiniteScroll={this.handleInfiniteScroll}
          ignoreInfiniteScroll={this.props.ignoreInfiniteScroll || !this._isMoreNewsAvailable()}
          distanceFromBottomToTriggerInfiniteScroll={400}
        >
          {this._renderNewsList()}
        </InfiniteScroll>
      );
    }
  };

  _renderLoading = () => {
    return <PageLoading loadingMessage="Loading news" />;
  };

  _renderError = () => {
    return <div className={``}>There was an error getting the news. Please try again later.</div>;
  };

  _renderNewsList = () => {
    if (!this.state.isLoadingMoreNewsArticles && this._isNoNews()) {
      return this._renderNoNewsMessage();
    }
    return (
      <NewsList
        securityId={this.props.securityId}
        viewingContext={this.props.viewingContext}
        scrollContainerSelector={this.props.scrollContainerSelector}
        newsData={this._returnNewsStoreDataForSecurity()}
        isUpdatingNews={this.isUpdatingNews()}
        isLoadingMoreNews={this.isLoadingMoreNews()}
        isCurrentUserAuthed={this.isCurrentUserAuthed()}
      />
    );
  };

  handleInfiniteScroll = () => {
    if (this._isMoreNewsAvailable() && !this.isLoadingMoreNews()) {
      this._logInfiniteScrollTriggered();
      return this._fetchMoreNews();
    }
    return new Promise((res) => res(false));
  };

  _handleLoggingOfNoNews = () => {
    this.setState(() => ({
      noNewsLogSent: true,
    }));
    this._logNoNews();
  };

  _isMoreNewsAvailable = () => !('isMoreNewsAvailable' in this.state) || this.state.isMoreNewsAvailable;

  _updateNoMoreNewsAvailable = () => {
    this.setState((prevState) => ({
      isMoreNewsAvailable: false,
    }));
  };

  _returnTodayAsDateRange = () => {
    const today = formatLocalizedDateTime('L', moment()); // 05/31/2018
    return [today, today];
  };

  _fetchMoreNews = () => {
    const dateRange = this.state.newsArticleDateRange;
    const expandedDateRange = this._increaseNewsDateRange(dateRange);
    const currentNewsArticleCount = this._returnNewsArticleCount();
    return this._fetchNews(expandedDateRange, currentNewsArticleCount);
  };

  _fetchNews = (dateRange, currentNewsArticleCount) => {
    const today = formatLocalizedDateTime('L', moment()); // 05/31/2018
    dateRange = dateRange || [today, today];
    const params = {
      securityIds: this._returnSecurityId(),
      dateRange,
    };
    this._startLoadingMoreNewsArticles();
    return this.props.actions.fetchNews(params).then((response) => {
      const wasAjaxSuccessful = response.data && response.data.news;
      if (wasAjaxSuccessful) {
        const wasMinNewsArticleResultsMet = this._wasMinNewsArticleResultsMet(response, currentNewsArticleCount);
        const wasDateRangeLimitReached = this._wasDateRangeLimitReached(dateRange);
        if (wasMinNewsArticleResultsMet || wasDateRangeLimitReached) {
          if (wasDateRangeLimitReached) {
            this._updateNoMoreNewsAvailable();
          }
          this._saveDateRangeForNewsArticles(dateRange);
          this._stopLoadingMoreNewsArticles();
        } else {
          const expandedDateRange = this._increaseNewsDateRange(dateRange);
          // will recursively check and stop when either it finds enough articles or reaches max time period
          return this._fetchNews(expandedDateRange);
        }
      } else {
        this._handleFetchNewsError();
      }
      return response;
    });
  };

  _handleFetchNewsError = () => {
    this._stopLoadingMoreNewsArticles();
    const error = new Error('Something went wrong fetching more news articles.');
    Actions.logErrorEvent(error)();
  };

  _wasMinNewsArticleResultsMet = (response, currentNewsArticleCount) => {
    currentNewsArticleCount = currentNewsArticleCount || 0;
    const min = 5 + currentNewsArticleCount;
    const currentCountOfNewsArticles = this._returnNewsArticleCount();
    return currentCountOfNewsArticles >= min;
  };

  _wasDateRangeLimitReached = (dateRange) => {
    const rangeLimitInterval = 30;
    const rangeLimitType = 'days';

    const startDate = dateRange[0];
    const endDate = formatLocalizedDateTime('L', moment());
    const rangeLimitFromToday = moment(endDate).subtract(rangeLimitInterval, rangeLimitType);
    return moment(startDate).isBefore(rangeLimitFromToday);
  };

  _returnNewsArticleCount = () => {
    return this._returnNewsStoreDataForSecurity() ? this._returnNewsStoreDataForSecurity().length : 0;
  };

  _returnNewsArticleCountFromResponse = (response) => {
    const newsData = response.data.news[0];
    return newsData.length;
  };

  _increaseNewsDateRange = (dateRange) => {
    const startDate = dateRange ? dateRange[0] : this._returnTodayAsDateRange()[0];
    const endDate = formatLocalizedDateTime('L', moment());
    const isRangeLongerThanAWeek = moment(startDate).isBefore(moment(endDate).subtract(7, 'days'));
    const intervalToSubtractFromDate = isRangeLongerThanAWeek ? 7 : 1;
    const newStartDate = moment(startDate).subtract(intervalToSubtractFromDate, 'days');
    const dayBeforeStartDate = moment(startDate).subtract(1, 'days');
    const newEndDate = intervalToSubtractFromDate === 1 ? newStartDate : dayBeforeStartDate;
    return [newStartDate, newEndDate];
  };

  _renderNoNewsMessage = () => {
    return (
      <div className={`no-news-message`}>
        <Icon size="xlarge" color="#aaa" icon="fa-newspaper-o" />
        <div className={`icon-sub-text`}>There is no recent news available.</div>
      </div>
    );
  };

  isLoadingMoreNews = () => {
    const isLoadingMoreNews = this.state.isLoadingMoreNewsArticles;
    const isNewsResults = this._returnNewsStoreDataForSecurity() && this._returnNewsStoreDataForSecurity().length > 0;
    return isLoadingMoreNews && isNewsResults;
  };

  isUpdatingNews = () => this.state.isUpdatingNews;

  isLoadingMoreNewsButNoCurrentResults = () => {
    const isLoadingMoreNews = this.state.isLoadingMoreNewsArticles;
    const isNewsResults = this._returnNewsStoreDataForSecurity() && this._returnNewsStoreDataForSecurity().length === 0;
    return isLoadingMoreNews && isNewsResults;
  };

  _returnNewsData = () => this.props.news;

  _returnAllSecuritiesNewsData = () => this._returnNewsData().newsBySecurityId;

  _returnNewsStoreDataForSecurity = () => this._returnAllSecuritiesNewsData()[this._returnSecurityId()];

  _isNoNews = () => {
    const isNoNewsResults =
      this._returnNewsStoreDataForSecurity() && this._returnNewsStoreDataForSecurity().length === 0;
    const isNotLoading = !this.state.isLoadingMoreNewsArticles && !this._isLoading();
    return isNotLoading && isNoNewsResults;
  };

  _isLoading = () => {
    const securityId = this.props.securityId;
    const newsData = this._returnAllSecuritiesNewsData();
    return (
      !(securityId in newsData) ||
      (securityId in newsData && !newsData[securityId] && !this._wasErrorGettingNews()) ||
      this.isLoadingMoreNewsButNoCurrentResults()
    );
  };

  _returnErrorGettingNews = () => {
    const securityId = this.props.securityId;
    const newsData = this._returnAllSecuritiesNewsData();
    return newsData[securityId] && newsData[securityId].error;
  };

  _isNewsDataAlreadyAvailable = () => !!this._returnNewsStoreDataForSecurity();

  _wasErrorGettingNews = () => !!this._returnErrorGettingNews();

  _returnDateRangeForNewsArticles = () => this.state.dateRange;

  isCurrentUserAuthed = () => 'user_id' in this.props.currentUser;

  _saveDateRangeForNewsArticles = (dateRange) => {
    this.setState((prevState) => ({
      newsArticleDateRange: dateRange,
    }));
  };

  _startLoadingMoreNewsArticles = () => {
    this.setState(() => ({
      isLoadingMoreNewsArticles: true,
    }));
  };

  _stopLoadingMoreNewsArticles = () => {
    this.setState(() => ({
      isLoadingMoreNewsArticles: false,
    }));
  };

  _startUpdatingNews = () => {
    this.setState(() => ({
      isUpdatingNews: true,
    }));
  };

  _endUpdatingNews = () => {
    this.setState(() => ({
      isUpdatingNews: false,
    }));
  };

  _returnSecurityId = () => this.props.securityId;

  _logNoNews = () => {
    const event = 'No News Articles Available';
    const properties = {
      'Security ID': this._returnSecurityId(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logInfiniteScrollTriggered = () => {
    const event = 'Load Additional News Articles';
    const properties = {
      'Security ID': this._returnSecurityId(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    news: state.news,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsContainer);
