import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withWindowSize from '../HOCS/withWindowSize';
import { withRouter } from '@src/main/utils';
import { Thresholds } from '@src/constants';
import * as Actions from '../../actions/index';
import InfoIcon from '../UI/InfoIcon';
import IconButton from '../../components/buttons/IconButton';
import LoadingIcon from '../../components/misc/LoadingIcon';
import { isUndefinedOrNull, snakeToTitleCase } from '@src/helpers/generalHelpers';
import {
  isOrderOperationBuying,
  returnDisplayStringOrderOperation,
  hasSubOperation,
  returnDisplayStringOrderSubOperation,
  isOrderOperationSelling,
  calculateOrderApproxValue,
} from '@src/helpers/ordersHelpers';
import { formatForDisplay } from '@src/helpers/displayHelpers';
import { returnOrderDisplayStatus, isACancelledStatus } from '@src/helpers/ordersHelpers';
import { returnSecurityPanelUrl } from '@src/helpers/securityPanelHelpers';
import { FlatButton } from '@src/main/components/buttons';

const FilledSizeExecutedAt = styled.div`
  text-align: center;
  padding-top: 3px;

  * {
    font-size: 10px !important;
    line-height: 14px;
  }
`;

const PlacedOrderWrapper = styled.div`
  width: 100%;
`;

export class Order extends Component {
  constructor() {
    super();
    this.state = {
      _isCancelingOrder: false,
    };
  }

  componentDidMount() {
    if (!this._isSecurityDataAvailable()) {
      this._getSecurityData();
      this._refreshSecurityPrices();
    }
  }

  render() {
    if (this._isSecurityDataLoading()) {
      return (
        <div className="order-loading-container order-container border-accent">
          <LoadingIcon icon="fading-3balls" size="small" />
        </div>
      );
    }
    if (this._isPlacedOrder()) {
      return this._renderPlacedOrderContainer();
    } else {
      return this._isSubdivided() ? this._renderSubdividedOrder() : this._renderNonSubdividedOrder();
    }
  }

  _renderNonSubdividedOrder = () => {
    return (
      <div className={`order-container border-accent ${this._returnOrderContainerClasses()}`}>
        {this._renderOrder(this._returnOrder())}
      </div>
    );
  };

  _renderSubdividedOrder = () => {
    return (
      <div className={`order-container border-accent ${this._returnOrderContainerClasses()}`}>
        {this._renderOrder(this._returnOrder())}
        {this._renderOrder(this._returnSubdivisionOrder())}
      </div>
    );
  };

  _renderOrder = (order) => {
    if (this._isMobileSize()) {
      return this._renderMobileOrder(order);
    }
    return (
      <div className={`order-body ${this._returnSubDividedOrderContainerClasses(order)}`}>
        {this._renderOrderTypeAndOperation(order)}
        {this._renderOrderIdentifier(order)}

        <div className={`order-last-price-container`}>
          {!this._isSubdivision(order) && (
            <div>
              <span className={`order-last-price`}>{`${this._returnDisplayStringForLastPrice(order)}`}</span>
              {this._isLiveTrading() && (
                <span className={`order-see-more-pricing link-btn`} onClick={this._handleSeeMorePricingClick}>
                  {'MORE'}
                </span>
              )}
            </div>
          )}
        </div>
        <div className={`order-current-position-container`}>
          <span className={`order-current-position`}>
            {!this._isSubdivision(order) && `${this._returnCurrentPosition(order)}`}
          </span>
        </div>
        <div className={`order-size-container`}>
          <span className={`order-size`}>{`${this._returnShares(order)}`}</span>
        </div>
        <div className={`order-approx-value-container`}>
          <span className={`order-approx-value  `}>{`${this._returnDisplayStringForApproxValue(order)}`}</span>
        </div>
        {!this._isSubdivision(order) && (
          <div className={`order-actions`}>
            {this._renderEdit()}
            {this._renderDelete()}
          </div>
        )}
      </div>
    );
  };

  _renderMobileOrder = (order) => {
    return (
      <div className={`order-body ${this._returnSubDividedOrderContainerClasses(order)}`}>
        {this._renderOrderTypeAndOperation(order)}
        {this._renderOrderIdentifier(order)}

        <div className={`order-last-price-container ${this._isMobileSize() ? 'mobile' : ''}`}>
          {!this._isSubdivision(order) && (
            <div>
              <span className={`order-last-price`}>{`${this._returnDisplayStringForLastPrice(order)}`}</span>
              {this._isLiveTrading() && (
                <span className={`order-see-more-pricing`} onClick={this._handleSeeMorePricingClick}>
                  {'MORE'}
                </span>
              )}
            </div>
          )}
        </div>
        <div className={`order-size-container ${this._isMobileSize() ? 'mobile' : ''}`}>
          <span className={`order-size`}>{`${this._returnShares(order)}`}</span>
          <span className={`order-approx-value`}>{`~ ${this._returnDisplayStringForApproxValue(order)}`}</span>
        </div>
        {!this._isSubdivision(order) && (
          <div className={`order-actions`}>
            {this._renderEdit()}
            {this._renderDelete()}
          </div>
        )}
      </div>
    );
  };

  _renderSubdividedPlacedOrder = () => {
    return (
      <div className={`order-container border-accent ${this._returnOrderContainerClasses()}`}>
        {this._renderOrder(this._returnOrder())}
        {this._renderOrder(this._returnSubdivisionOrder())}
      </div>
    );
  };

  _renderPlacedOrderContainer = () => {
    if (this._isSubdivided()) {
      return (
        <div className={`order-container border-accent ${this._returnOrderContainerClasses()}`}>
          {this._renderPlacedOrder(this._returnOrder())}
          {this._renderPlacedOrder(this._returnSubdivisionOrder())}
        </div>
      );
    } else {
      return (
        <div className={`order-container border-accent ${this._returnOrderContainerClasses()}`}>
          {this._renderPlacedOrder(this._returnOrder())}
        </div>
      );
    }
  };

  _renderPlacedOrder = (order) => {
    return (
      <PlacedOrderWrapper className={`order-body placed-order ${this._returnSubDividedOrderContainerClasses(order)}`}>
        {this._renderOrderTypeAndOperation(order)}
        {this._renderOrderIdentifier(order)}
        <div className={`order-fill-container`}>
          <span className={`order-fill-size`}>{`${this._returnDisplayStringForFilledQty(order)}`}</span>
          {this._isFilledOrPartiallyFilledOrder(order) && (
            <span className={`order-fill-price secondary-text-color`}>
              {`Avg. Price: ${this._returnDisplayStringForAvgFillPrice(order)}`}
            </span>
          )}
          {this._isMobileSize() && (
            <FilledSizeExecutedAt>
              <span className={`order-executed-at`}>{`${this._returnDisplayStringForOrderExecutionTime(order)}`}</span>
            </FilledSizeExecutedAt>
          )}
        </div>
        {!this._isMobileSize() && (
          <div className={`order-executed-at-container`}>
            <span className={`order-executed-at`}>{`${this._returnDisplayStringForOrderExecutionTime(order)}`}</span>
          </div>
        )}
        {this._isSubdivision(order) ? (
          <div className={`cancel-order-container border-accent`}>
            {this._renderOrderStatus(order, { showOrderStatus: true })}
          </div>
        ) : (
          <div className={`cancel-order-container border-accent`}>
            {this.props.allowCancel && this._renderCancelOrder()}
          </div>
        )}
      </PlacedOrderWrapper>
    );
  };

  _renderOrderTypeAndOperation = (order) => {
    return (
      <div className={`order-type-and-operation`}>
        <div className={`order-operation ${this._returnColorClassForOperation(order)}`}>
          {this._returnDisplayStringForOperation(order)}
        </div>
        {this._hasSubOperation(order) && (
          <div className={`order-sub-operation ${this._returnColorClassForOperation(order)}`}>
            {this._returnDisplayStringForSubOperation(order)}
            {this._shouldDisplaySECRule(order) && this._renderSECRule()}
          </div>
        )}
        <div className={`order-type`}>{this._returnDisplayStringForOrderType(order)}</div>
        {this._isStopOrder() && (
          <div className={`order-type-price`}>{this._returnDisplayStringForStopPrice(order)}</div>
        )}
        {this._isLimitOrder() && (
          <div className={`order-type-price`}>{this._returnDisplayStringForLimitPrice(order)}</div>
        )}
      </div>
    );
  };

  _renderOrderIdentifier = (order) => {
    return (
      <div
        className="order-security-identifier"
        onClick={this._handleIdentifierClick}
        style={this._isPlacedOrder() ? { cursor: 'pointer' } : {}}
      >
        {!this._isSubdivision(order) && (
          <div>
            <span className="order-security-symbol  ">{this._returnSecuritySymbol()}</span>
            <span className="order-security-company-name secondary-text-color">{this._returnSecurityName()}</span>
          </div>
        )}
      </div>
    );
  };

  _renderSECRule = () => {
    return (
      <InfoIcon
        word={'tooltip_sec_rule_for_short_sale'}
        symbol={this._returnSecuritySymbol()}
        style={{ position: 'relative', top: '0', left: '2px' }}
      />
    );
  };

  _isACancelledStatus = () => isACancelledStatus(this._returnOrder());

  _renderCancelOrder = () => (this._isCancelingOrder() ? this._renderCancelingOrder() : this._renderOrderStatus());

  _returnOrderStatus = (order) => returnOrderDisplayStatus(order || this._returnOrder());

  _renderOrderStatus = (order, config) => {
    if (config && config.showOrderStatus) {
      return <div className={`order-status`}>{this._returnOrderStatus(order)}</div>;
    }
    if (this._isCancellable()) {
      return this._renderCancelOrderButton();
    } else {
      return <div className={`order-status`}>{this._returnOrderStatus(order)}</div>;
    }
  };

  _renderCancelOrderButton = () => {
    return (
      <FlatButton transparent small onClick={this._handleCancelClick}>
        Cancel
      </FlatButton>
    );
  };

  _renderEdit = () => {
    return (
      <div className="button-icon-container">
        <IconButton
          size="large"
          colorClassName={'link-text-color'}
          icon="fa-pencil"
          background="rgba(0,0,0,0)"
          handleClick={this.handleEditClick}
        />
      </div>
    );
  };

  _renderDelete = () => {
    return (
      <div className="button-icon-container">
        <IconButton
          size="large"
          colorClassName={'link-text-color'}
          icon="fa-trash-o"
          background="rgba(0,0,0,0)"
          handleClick={this._handleDeleteClick}
        />
      </div>
    );
  };

  _returnOrderContainerClasses = () => {
    const classes = [];
    if (this._isSubdivided()) {
      classes.push('sub-divided-order');
    }
    if (this._returnWindowWidth() <= 900) {
      classes.push('condensed-order-container border-accent');
    }
    if (this._isMobileSize()) {
      classes.push('mobile-order-container border-accent');
    }
    if (this._isPlacedOrder() && this._isACancelledStatus()) {
      classes.push('order-cancelled component-bg-faded');
    }
    return classes.join(' ');
  };

  _returnSubDividedOrderContainerClasses = (order) => {
    const classes = [];
    classes.push(`order-${this._returnOrderId(order)}`);
    return classes.join(' ');
  };

  _isMobileSize = () => this.props.renderMobileSize || this._returnWindowWidth() <= Thresholds.widthForOrderMobileView;

  _isLiveTrading = () => this.props.isLiveTrading;

  _returnWindowWidth = () => this.props.windowWidth;

  _returnOrder = () => this.props.order;

  _returnOrderId = (order) => (order || this._returnOrder()).order_id;

  _isFilledOrPartiallyFilledOrder = (order) => this._returnFillShares(order || this._returnOrder()) > 0;

  _isCancellable = () => this._returnOrder().can_be_canceled;

  _hasSubOperation = (order) => hasSubOperation(order || this._returnOrder());

  _isSubdivided = (order) => !!(order || this._returnOrder()).subdivision;

  _isSubdivision = (order) => !!(order || this._returnOrder()).is_subdivision;

  _shouldDisplaySECRule = (order) => !!(order || this._returnOrder()).display_sec_rule;

  _returnSubdivisionOrder = () => this._returnOrder().subdivision;

  _returnCurrentPosition = (order) => this.props.currentPosition || 0;

  _returnShares = (order) => order.shares;

  _returnLastPrice = () => this.props.lastPrice;

  _returnApproxValue = (order) => {
    return calculateOrderApproxValue(
      isOrderOperationSelling(order),
      this._returnShares(order),
      this._returnLastPrice(),
      this._isLimitOrder(),
      this._returnLimitPrice(order),
      this._isStopOrder(),
      this._returnStopPrice(order)
    );
  };

  _returnOperation = (order) => order.operation;

  _returnOrderType = (order) => order.type;

  _returnLimitPrice = (order) => order.limit_price;

  _returnStopPrice = (order) => order.stop_price;

  _returnCommission = (order) => order.commission;

  _returnFillShares = (order) => order.filled_shares;

  _returnOrderExecutionTime = (order) => order.executed_at;

  _returnAvgFillPrice = (order) => order.average_fill_price;

  _returnDisplayStringForFilledQty = (order) => `${this._returnFillShares(order)} / ${this._returnShares(order)}`;

  _returnDisplayStringForLimitPrice = (order) =>
    `${this._isMobileSize() ? '' : '@ '}${formatForDisplay(this._returnLimitPrice(order), 'price')}${
      !this._isMobileSize() && this._isStopOrder() && this._isLimitOrder() ? ' limit' : ''
    }`;

  _returnDisplayStringForStopPrice = (order) =>
    `${this._isMobileSize() ? '' : '@ '}${formatForDisplay(this._returnStopPrice(order), 'price')}${
      !this._isMobileSize() && this._isStopOrder() && this._isLimitOrder() ? ' stop' : ''
    }`;

  _returnDisplayStringForLastPrice = (order) => formatForDisplay(this._returnLastPrice(order), 'price');

  _returnDisplayStringForApproxValue = (order, roundWholeNum) => {
    const val = roundWholeNum ? Math.round(this._returnApproxValue(order)) : this._returnApproxValue(order);
    if (Number.isNaN(val)) {
      return '';
    }
    return formatForDisplay(val, 'priceInt');
  };

  _returnDisplayStringForOrderType = (order) => snakeToTitleCase(this._returnOrderType(order));

  _returnDisplayStringForAvgFillPrice = (order) => formatForDisplay(this._returnAvgFillPrice(order), 'price');

  _returnDisplayStringForOrderExecutionTime = (order) =>
    isUndefinedOrNull(this._returnOrderExecutionTime(order))
      ? ''
      : formatForDisplay(this._returnOrderExecutionTime(order), 'time');

  _returnDisplayStringForOperation = (order) => returnDisplayStringOrderOperation(this._returnOperation(order));

  _returnDisplayStringForSubOperation = (order) => returnDisplayStringOrderSubOperation(this._returnOperation(order));

  _returnColorClassForOperation = (order) => (isOrderOperationBuying(order) ? 'buy-text-color' : 'sell-text-color');

  _handleSeeMorePricingClick = () => {
    this._logSeeMorePricingClick();
    this.props.handleSeeMorePricingClick(this._returnSecurityId());
  };

  _handleIdentifierClick = () => {
    if (this._isPlacedOrder()) {
      this._logOpenSecurityPanel();
      this._openSecurityPanel();
    }
  };

  _openSecurityPanel = () => {
    const additionalProps = {
      activeTab: 'ideas',
    };
    const openSecurityPanelConfig = {
      securityId: this._returnSecurityId(),
      additionalProps,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    const url = returnSecurityPanelUrl(openSecurityPanelConfig);
    this.props.navigate(url);
  };

  _handleCancelResponse = async (response) => {
    const wasSuccess = response && response.data && response.data.status === 'canceled';
    if (wasSuccess) {
      await this._refreshUserData();
      this._logCancelOrder();
    } else {
      const error =
        (response && response.data && response.data.error) || 'Something went wrong. Please try again later.';
      const component = (
        <div className="modal-message" style={{ paddingTop: '0px' }}>
          {`${error}`}
        </div>
      );
      const modal = {
        contentComponent: component,
        dismissable: true,
      };
      this._logCancelOrderFailed(error);
      this.props.actions.showModal(modal);
    }
  };

  _handleCancelClick = async () => {
    this._startCancelingOrder();
    const response = await this._cancelPlacedOrder();
    await this._handleCancelResponse(response);
    this._stopCancelingOrder();
  };

  handleEditClick = () => this.props.handleEditOrder(this._returnOrderId());

  _handleDeleteClick = () => {
    this._logDeleteOrder();
    this._deleteOrder();
  };

  _cancelPlacedOrder = () => {
    return this.props.actions.cancelPlacedOrder(this._returnOrderId());
  };

  _startCancelingOrder = () => {
    this.setState(() => ({
      _isCancelingOrder: true,
    }));
  };
  _stopCancelingOrder = () => {
    this.setState(() => ({
      _isCancelingOrder: false,
    }));
  };
  _isCancelingOrder = () => this.state._isCancelingOrder;
  _renderCancelingOrder = () => {
    return (
      <div className={`canceling-order-container border-accent`}>
        <LoadingIcon />
        <span className={`text-container`}>Canceling</span>
      </div>
    );
  };
  _deleteOrder = () => this.props.actions.deleteOrder(this._returnOrderId());

  _isLimitOrder = () => this._returnOrder().type === 'limit' || this._returnOrder().type === 'stop_limit';

  _isStopOrder = () => this._returnOrder().type === 'stop' || this._returnOrder().type === 'stop_limit';

  _isPlacedOrder = () => this._returnOrder().is_placed;

  _isFromOptimizer = () => this._returnOrder().is_from_optimizer;

  _returnSecurityName = () => this._returnSecurity().name;

  _returnSecuritySymbol = () => this._returnSecurity().symbol;

  _returnSecurity = () => this._returnSecuritiesLookup()[this._returnSecurityId()] || {};

  _returnSecuritiesLookup = () => this._returnSecuritiesStore().lookup;

  _returnSecuritiesStore = () => this.props.securities;

  _returnSecurityId = () => this._returnOrder().security_id;

  _isSecurityDataAvailable = () => 'symbol' in this._returnSecurity() && 'name' in this._returnSecurity();

  _isSecurityDataLoading = () => !this._isSecurityDataAvailable();

  _getSecurityData = () => this.props.actions.quickFetchSecuritiesData([this._returnSecurityId()]);

  _refreshSecurityPrices = () => this.props.actions.fetchSecuritiesPriceData([this._returnSecurityId()]);

  _refreshUserData = () => this.props.refreshUserData();

  _logSeeMorePricingClick = () => {
    const event = 'Clicked See More Pricing';
    const properties = {
      Context: 'Order',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logCancelOrder = () => {
    const event = 'Request Order Cancel';
    const properties = {
      'Order ID': this._returnOrderId(),
      'Is From Optimizer': this._isFromOptimizer(),
      'Is Subdivided': this._isSubdivided(),
      'Security ID': this._returnSecurityId(),
      'Security Symbol': this._returnSecuritySymbol(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logCancelOrderFailed = (message) => {
    const event = 'Request Order Cancel Failed';
    const properties = {
      'Order ID': this._returnOrderId(),
      'Error Message': message,
      'Is From Optimizer': this._isFromOptimizer(),
      'Is Subdivided': this._isSubdivided(),
      'Security ID': this._returnSecurityId(),
      'Security Symbol': this._returnSecuritySymbol(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logDeleteOrder = () => {
    const event = 'Remove Order';
    const properties = {
      'Order ID': this._returnOrderId(),
      'Is From Optimizer': this._isFromOptimizer(),
      'Is Subdivided': this._isSubdivided(),
      'Security ID': this._returnSecurityId(),
      'Security Symbol': this._returnSecuritySymbol(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logOpenSecurityPanel = () => {
    const event = 'Open Security Panel';
    const properties = {
      Context: 'Placed Order',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    portfolio: state.portfolio,
    securities: state.securities,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(
  withWindowSize,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Order);

export default composedComponent;
