import { useCallback, useState } from 'react';
import { useEventListener } from './useEventListener';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

export function useElementSize() {
  const [ref, setRef] = useState(null);

  const [size, setSize] = useState({
    width: null,
    height: null,
  });

  const handleSize = useCallback(() => {
    const width = ref?.current?.offsetWidth;
    const height = ref?.current?.offsetHeight;
    const nextWidth = isNaN(width) ? null : width;
    const nextHeight = isNaN(height) ? null : height;
    if (size.width !== nextWidth || size.height !== nextHeight) {
      setSize({
        width: nextWidth,
        height: nextHeight,
      });
    }
  }, [ref?.current]);

  useEventListener('resize', handleSize);

  useIsomorphicLayoutEffect(() => {
    handleSize();
  }, [ref?.current?.offsetHeight, ref?.current?.offsetWidth]);

  return [setRef, size];
}
