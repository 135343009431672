import React from 'react';
import Icon from '../../components/misc/Icon';
import { CHART_PERFORMANCE_COMPARISON_COLOR_INDEXES } from '../../helpers/chartHelpers';
import { convertColorObjectToHex } from '../../helpers/generalHelpers';

const PerformanceComparisonSecurityTag = ({ index, securityId, symbol, onRemove }) => {
  const handleRemoveClick = () => {
    onRemove(securityId);
  };

  const color = CHART_PERFORMANCE_COMPARISON_COLOR_INDEXES[index];

  return (
    <div className="chart-performance-comparison-security-tag border-accent ">
      <div className="flex-center-child">
        <div style={{ textAlign: 'center', minWidth: '25px' }}>
          <span className="security-symbol  ">{symbol}</span>
          <div className="tag-color-display" style={{ marginBottom: '5px' }}>
            <div
              style={{
                height: '2px',
                backgroundColor: convertColorObjectToHex(color),
              }}
            ></div>
          </div>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div className="remove-tag-button-container" onClick={handleRemoveClick}>
          <Icon icon="fa-times-thin" colorClassName="secondary-text-color" size="large" />
        </div>
      </div>
    </div>
  );
};

export default PerformanceComparisonSecurityTag;
