import { learnAdapter } from '../adapters/learnAdapter';
import { learnTypes } from '../main/constants/actionTypes';
import { ADD_KARMA_POINTS } from '../constants';
import { OVERRIDE_CURRENT_NEXT_LESSON_DATA } from '../../config';

export const getLesson = (id) => {
  return async function (dispatch) {
    const { status, data, errorMessage } = await learnAdapter.getLesson(id);
    if (status === 200) {
      dispatch({
        type: learnTypes.addLessonData,
        payload: data.lesson_content,
      });
      return {
        ok: true,
        data,
      };
    } else {
      return {
        error: true,
        errorMessage,
      };
    }
  };
};

export const fetchAllLearnLessons = (url) => {
  return function (dispatch) {
    return learnAdapter.fetchAllLearnLessons({ data: { url }, dispatch }).then((response) => {
      const { status, data, errorMessage } = response;
      if (status === 200) {
        const { lessons } = data;
        dispatch({
          type: learnTypes.updateLookup,
          payload: lessons,
        });

        return {
          ok: true,
          data,
          errorMessage,
        };
      } else {
        return {
          error: true,
          data,
          errorMessage,
        };
      }
    });
  };
};

export const fetchNextLearnLesson = () => {
  return function (dispatch) {
    return learnAdapter.fetchNextLearnLesson({ dispatch }).then((response) => {
      const { status, data, errorMessage } = response;
      if (status === 200) {
        const {
          next_lesson_id,
          next_reward_xp,
          next_reward_value,
          next_lesson_xp_value,
          hours_until_next_lesson,
          completed_lesson_count,
          message_type,
          // page_number,

          should_see_learn_and_earn_doubled_with_deposit,
          should_see_learn_and_earn_payout_requires_deposit,
        } = data;
        dispatch({
          type: learnTypes.setNextLesson,
          payload: {
            nextLessonId: next_lesson_id,
            nextLessonReward: next_reward_value,
            nextLessonRewardAtXP: next_reward_xp,
            nextLessonCompletionXP: next_lesson_xp_value,
            completedLessonCount: completed_lesson_count,
            hoursUntilNextLesson: hours_until_next_lesson,
            messagingStates: {
              should_see_learn_and_earn_doubled_with_deposit,
              should_see_learn_and_earn_payout_requires_deposit,
            },
            status: message_type || null,
            ...OVERRIDE_CURRENT_NEXT_LESSON_DATA,
          },
        });
        return {
          ok: true,
          data,
          errorMessage,
        };
      } else {
        return {
          error: true,
          data,
          errorMessage,
        };
      }
    });
  };
};

export const updateLessonPreview = (d) => {
  return function (dispatch) {
    dispatch({
      type: learnTypes.updateLearnPreviewLesson,
      payload: d,
    });
  };
};

export const fetchCompletedLearnLessons = () => {
  return function (dispatch) {
    return learnAdapter.fetchCompletedLearnLessons({ dispatch }).then((response) => {
      const { status, data, errorMessage } = response;
      if (status === 200) {
        const { completed_lessons, completed_lessons_with_more_params } = data;
        dispatch({
          type: learnTypes.updateCompletedLessons,
          payload: completed_lessons_with_more_params,
        });

        return {
          ok: true,
          data,
          errorMessage,
        };
      } else {
        return {
          error: true,
          data,
          errorMessage,
        };
      }
    });
  };
};

export const postLearnLessonProgress = (data) => {
  return function (dispatch) {
    return learnAdapter.postLearnLessonProgress({ data, dispatch }).then((response) => {
      const { status, data, errorMessage } = response;
      if (status === 200) {
        const { value: karmaPointsEarned } = data;
        if (karmaPointsEarned > 0) {
          dispatch({
            type: ADD_KARMA_POINTS,
            payload: karmaPointsEarned,
          });
        }

        return {
          ok: true,
          data,
          errorMessage,
        };
      } else {
        return {
          error: true,
          data,
          errorMessage,
        };
      }
    });
  };
};

export const postRecurringInvestmentChartData = (data) => {
  return learnAdapter.postRecurringInvestmentChartData({ data });
};
