import {
  ADD_EXTERNAL_POSITIONS_DATA,
  UPDATE_EXTERNAL_POSITIONS_DATA,
  START_LOADING_EXTERNAL_POSITIONS_DATA,
} from '../constants';

const defaultState = {
  loading: true,
  accounts: {},
  num_accounts: 0,
};

export default function externalPositionsReducer(state = defaultState, action) {
  let portfolioData, returnState, num_accounts;

  switch (action.type) {
    case START_LOADING_EXTERNAL_POSITIONS_DATA: // Current User only
      return {
        ...state,
        loading: true,
      };

    case ADD_EXTERNAL_POSITIONS_DATA: // Current User only
      portfolioData = action.payload.portfolioData;

      returnState = {
        ...state,
        ...portfolioData,
        loading: false,
        num_accounts: get_num_accounts(portfolioData),
      };

      return returnState;

    case UPDATE_EXTERNAL_POSITIONS_DATA: // Current User only
      portfolioData = action.payload.portfolioData;

      returnState = {
        ...state,
        ...portfolioData,
        loading: false,
        num_accounts: get_num_accounts(portfolioData),
      };

      return returnState;

    default:
      return state;
  }
}

function get_num_accounts(portfolioData) {
  let num_accounts = 0;
  if (portfolioData.externalPositions && portfolioData.externalPositions.accounts) {
    num_accounts = Object.keys(this.props.externalPositions.accounts).length;
  }

  return num_accounts;
}
