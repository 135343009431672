import React, { Component } from 'react';

class Overlay extends Component {
  componentDidMount() {
    // currently this behavior change is not needed
    window.$('body').css('overflow', 'hidden');
  }

  componentWillUnmount() {
    // currently this behavior change is not needed
    window.$('body').css('overflow', 'auto');
  }

  handleClick = (e) => {
    if (e.target.classList.contains('panel-overlay')) {
      this.props.onClick && this.props.onClick();
    }
  };

  render() {
    const { customClass, children } = this.props;

    return (
      <div className={`panel-overlay fade-in ${customClass}`} onClick={this.handleClick}>
        {children}
      </div>
    );
  }
}

Overlay.defaultProps = {
  customClass: '',
};

export default Overlay;
