import React from 'react';
import FundamentalData from '../SecurityData/FundamentalData';
import BasicSecurityDataWrapper from '../SecurityData/BasicSecurityDataWrapper';
import IdeasListDataWrapper from './IdeasListDataWrapper';
import ThoughtLeadersDataWrapper from './ThoughtLeadersDataWrapper';
import ConnectionsDataWrapper from './ConnectionsDataWrapper';

import { IdeasListDataWrapperDisplay } from './displayComponents/IdeasListDataWrapperDisplay';
import SecurityDataComponent from '../SecurityData/SecurityDataComponent';
import { activeSecuritiesTracker, LivePricePollingManager } from '../../main/classes/LivePricePollingManager';
import { useSecurity } from '../../main/hooks/securities/useSecurity';
import { useSecurityPrice } from '../../main/hooks/securities/useSecurityPrice';

const PriceData = ({ securityId }) => {
  const { symbol } = useSecurity(securityId);
  const { price } = useSecurityPrice(securityId);

  React.useEffect(() => {
    if (symbol) activeSecuritiesTracker.addTempSecurities([{ id: securityId, symbol }]);

    return () => {
      if (symbol) activeSecuritiesTracker.removeTempSecurities([symbol]);
    };
  }, [symbol]);

  React.useEffect(() => {}, []);

  return <span>{price}</span>;
};

export const CustomDataWrapper = (props) => {
  const returnPropertyNameToComponentWithDataLookup = () => ({
    revenue_ltm: FundamentalData,
    backward_pe_ratio: FundamentalData,
    forward_pe_ratio_this_year: FundamentalData,
    forward_pe_ratio_next_year: FundamentalData,
    forward_pe_ratio_next_next_year: FundamentalData,
    price_to_tangible_book_value: FundamentalData,
    market_cap: FundamentalData,
    dividend_yield: FundamentalData,
    analyst_2yr_growth_projection: FundamentalData,
    net_debt: FundamentalData,

    price: PriceData,
    current_price: PriceData,

    sectors: BasicSecurityDataWrapper,

    connections: [IdeasListDataWrapper, ConnectionsDataWrapper],
    thought_leaders: [IdeasListDataWrapper, ThoughtLeadersDataWrapper],

    collective_intelligence: null,
    correlation: null,
  });

  const returnPropertyNameToComponentWithDataDisplayLookup = () => ({
    sectors: SecurityDataComponent,

    connections: IdeasListDataWrapperDisplay,
    thought_leaders: IdeasListDataWrapperDisplay,

    revenue_ltm: null,
    backward_pe_ratio: null,
    forward_pe_ratio_this_year: null,
    forward_pe_ratio_next_year: null,
    forward_pe_ratio_next_next_year: null,
    price_to_tangible_book_value: null,
    market_cap: null,
    dividend_yield: null,
    analyst_2yr_growth_projection: null,
    net_debt: null,
    price: null,
    current_price: null,
    collective_intelligence: null,
    correlation: null,
  });

  const returnPropsForDataDisplayComponents = () => ({
    sectors: {
      className: 'security-sector',
      property: 'sector',
    },
    connections: {
      className: '',
      filters: ['connections'],
    },
    thought_leaders: {
      className: '',
      filters: ['thought_leaders'],
    },
  });

  const returnLargeFieldPropertyKeysList = () => [
    'revenue_ltm',
    'backward_pe_ratio',
    'forward_pe_ratio_this_year',
    'forward_pe_ratio_next_year',
    'forward_pe_ratio_next_next_year',
    'price_to_tangible_book_value',
    'market_cap',
    'dividend_yield',
    'analyst_2yr_growth_projection',
    'net_debt',
    'price',
    'current_price',
    'collective_intelligence',
    'correlation',
  ];

  const { propertyDisplayName, propertyKeyName } = props;

  const isLargeFieldValue = returnLargeFieldPropertyKeysList().includes(propertyKeyName);

  const dataWrapperEl = returnPropertyNameToComponentWithDataLookup()[propertyKeyName];
  const dataWrapperProps = {
    ...props,
    hideIfNA: true,
    property: propertyKeyName,
    className: '',
    renderInLabel: true,
    containerClassName: 'filter-field-container',
    labelClassName: 'field',
    labelText: propertyDisplayName,
    valueClassName: `field-value ${isLargeFieldValue ? 'large-field-value' : ''}`,
  };
  const dataWrapperDisplayEl = returnPropertyNameToComponentWithDataDisplayLookup()[propertyKeyName];

  let children = null;
  if (dataWrapperDisplayEl) {
    const childrenProps = {
      ...props,
      ...(returnPropsForDataDisplayComponents()[propertyKeyName] || {}),
    };
    children = React.createElement(dataWrapperDisplayEl, childrenProps, null);
  }
  if (!dataWrapperEl) {
    return null;
  } else if (Array.isArray(dataWrapperEl)) {
    const child = React.createElement(dataWrapperEl[1], dataWrapperProps, children);
    const parentWrapper = React.createElement(dataWrapperEl[0], dataWrapperProps, child);
    return parentWrapper;
  } else {
    const parentWrapper = React.createElement(dataWrapperEl, dataWrapperProps, children);
    return parentWrapper;
  }
};
