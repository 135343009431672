import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  & > svg {
    width: 18px;
    height: 18px;
  }
  & g,
  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function ShareIcon() {
  return (
    <Wrapper>
      <svg viewBox="0 0 16 18" fill="none">
        <path
          d="M13 12.4c-.633 0-1.2.25-1.633.642L5.425 9.583C5.467 9.392 5.5 9.2 5.5 9c0-.2-.033-.392-.075-.583L11.3 4.992a2.49 2.49 0 001.7.675c1.383 0 2.5-1.117 2.5-2.5 0-1.384-1.117-2.5-2.5-2.5a2.497 2.497 0 00-2.5 2.5c0 .2.033.391.075.583L4.7 7.175A2.49 2.49 0 003 6.5 2.497 2.497 0 00.5 9c0 1.383 1.117 2.5 2.5 2.5.658 0 1.25-.258 1.7-.675l5.933 3.467a2.354 2.354 0 00-.066.541A2.436 2.436 0 0013 17.267a2.436 2.436 0 002.433-2.434A2.436 2.436 0 0013 12.4zm0-10.067c.458 0 .833.375.833.834A.836.836 0 0113 4a.836.836 0 01-.833-.833c0-.459.375-.834.833-.834zm-10 7.5A.836.836 0 012.167 9c0-.458.375-.833.833-.833.458 0 .833.375.833.833A.836.836 0 013 9.833zm10 5.85a.836.836 0 01-.833-.833c0-.458.375-.833.833-.833.458 0 .833.375.833.833a.836.836 0 01-.833.833z"
          fill="#1F1F1F"
        />
      </svg>
    </Wrapper>
  );
}

export default ShareIcon;
