import React, { Component } from 'react';

import { logMetricsTrackingEvent } from '../../actions';

import SecuritiesFilter from '../../containers/PortfolioExplorer/SecuritiesFilter';
import IconButton from '../../components/buttons/IconButton';

class SecuritiesFilterList extends Component {
  constructor(props) {
    super();

    this.state = {
      filters: [...props.presetFilters],
      isCreatingFilter: props.presetFilters.length === 0 && props.securitiesLength === 0,
      activeFilter: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.securitiesLength === 0 &&
      prevProps.presetFilters.length === 0 &&
      (prevProps.filters.length !== this.props.filters.length ||
        prevProps.securitiesLength !== this.props.securitiesLength)
    ) {
      this.setState((prevState) => ({
        isCreatingFilter: false,
      }));
    }
  }

  startCreatingFilter = () => {
    const event = 'Clicked Add Filter';
    logMetricsTrackingEvent(event)();

    this.setState({ isCreatingFilter: true });
  };

  cancelCreateFilter = () => {
    const event = 'Clicked Cancel Create Filter';
    logMetricsTrackingEvent(event)();

    this.setState({ isCreatingFilter: false });
  };

  handleFilterHoverOrClick = (filterName) => {
    if (this.state.activeFilter !== filterName) {
      this.setState((prevState, prevProps) => {
        return {
          activeFilter: prevState.activeFilter !== filterName ? filterName : null,
        };
      });
    }
  };

  handleFilterHoverLeave = () => {
    this.setState({
      activeFilter: null,
    });
  };

  filterProps = () => {
    return {
      createFilter: this.props.filterConstructionProps.createFilter,
      updateFilter: this.props.filterConstructionProps.updateFilter,
      removeFilter: this.props.filterConstructionProps.removeFilter,
      isCreatingFilter: this.state.isCreatingFilter,
      activeFilter: this.state.activeFilter,
      handleFilterHoverOrClick: this.handleFilterHoverOrClick,
      handleFilterHoverLeave: this.handleFilterHoverLeave,
      cancelCreateFilter: this.cancelCreateFilter,
    };
  };

  render() {
    const filters = this.props.filters;
    const filterProps = this.filterProps();
    return (
      <div className="portfolio-explorer-securities-filter-builder-container" style={{ paddingTop: '16px' }}>
        <div className="heading secondary-text-color">Filter by:</div>
        <div className="filters-container">
          {filters.map((filter, i) => (
            <SecuritiesFilter
              key={`filter-${filter.name}-${i}`}
              editMode={false}
              filterProps={filterProps}
              filter={filter}
            />
          ))}
          {this.state.isCreatingFilter && (
            <SecuritiesFilter
              key={`creating-filter-form`}
              editMode={true}
              filterProps={filterProps}
              filter={{ name: null }}
            />
          )}
          {!this.state.isCreatingFilter && (
            <IconButton
              icon="fa-plus"
              size="medium"
              customClass="add-filter-btn horizontal-list-change-page-btn"
              handleClick={this.startCreatingFilter}
              ignoreShadow
            />
          )}
        </div>
      </div>
    );
  }
}

export default SecuritiesFilterList;
