import React from 'react';

import Icon from './Icon';

const ChangeArrow = (props) => {
  const value = props.value;
  const style = props.style || {};

  const increaseStyle = { padding: '9px 3px 0 0', verticalAlign: 'top' };
  const decreaseStyle = { padding: '0px 3px 0px 0px', verticalAlign: 'top' };

  return (
    <Icon
      icon={value >= 0 ? 'fa-sort-asc' : 'fa-sort-desc'}
      color={value >= 0 ? '#3db735' : '#ff7666'}
      style={{
        fontSize: '24px',
        ...(value > 0 ? increaseStyle : decreaseStyle),
      }}
    />
  );
};

export default ChangeArrow;
