import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useCurrentUser } from '../../hooks/user';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import { H5, Body5, createDropdownItem, FloatingLabelDropdown } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { logMetricsTrackingEvent, updateUserProperty } from '@src/actions';
import { LocationContext } from '@src/context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { PRODUCT_NAME } from '@src/appConfig';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const InputWrapper = styled.div`
  padding-top: 36px;

  select {
    border-top: none;
    border-left: none;
    border-right: none;

    -webkit-appearance: auto;
    background: transparent !important;
  }
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 8px;
`;
const Disclaimer = styled.div`
  padding-top: 24px;
`;
const Actions = styled.div`
  padding-top: 48px;
  text-align: center;
`;

const generateDefaultReturnValues = () => {
  var a = [];
  for (let i = 0; i <= 35; i += 0.5) {
    a.push(i.toFixed(1));
  }
  return a;
};

const dropdownItems = generateDefaultReturnValues().map((v) =>
  createDropdownItem({ text: `${v}%`, value: parseFloat(v) })
);

const getHasInvestedBefore = (investing_experience) => {
  return investing_experience.id !== 0;
};

export const EnterDefaultExpectedReturn = ({ onContinue, onboardingState }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const [isReady, setIsReady] = React.useState(true);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [formError, setFormError] = React.useState(null);

  const [defaultExpectedReturn, setDefaultExpectedReturn] = React.useState(
    currentUser.default_expected_return !== null ? currentUser.default_expected_return : 7.0
  );

  const hasInvestedBefore = getHasInvestedBefore(currentUser.investing_experience);

  React.useEffect(() => {}, []);

  const handleContinueClick = React.useCallback(async () => {
    if (isSubmitting) {
      return;
    }

    if (defaultExpectedReturn === '') {
      setError('Selection is required');
      logMetricsTrackingEvent('Failed Live Account Validations', {
        Fields: ['default_expected_return', 'Required'],
        'Form Name': 'default expected return',
      })();
      return;
    }

    setIsSubmitting(true);
    const ok = await updateUserProperty({
      default_expected_return: defaultExpectedReturn,
    })(dispatch);
    if (ok) {
      onContinue(null, onboardingState);
    } else {
      setFormError('Something went wrong, please try again.');
      setIsSubmitting(false);
    }
  }, [onContinue, onboardingState, defaultExpectedReturn, isSubmitting]);

  if (!isReady) return <LoadingPage />;

  const handleDropdownSelection = (name) => (v) => {
    setDefaultExpectedReturn(v);
  };

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.defaultExpectedReturnSelect}>
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>Let's get started</H5>
          </Heading>

          <Message>
            {hasInvestedBefore ? (
              <Body5>How much do you think your investments will go up per year (on average)?</Body5>
            ) : (
              <Body5>For now we’ll assume that hypothetically, the investments you select will go up</Body5>
            )}
          </Message>

          <InputWrapper>
            <FloatingLabelDropdown
              name={'default_expected_return'}
              value={defaultExpectedReturn}
              label={''}
              items={dropdownItems}
              onChange={handleDropdownSelection}
              error={error}
            />
          </InputWrapper>

          <Message>
            <Body5>per year on average</Body5>
          </Message>

          <Actions>
            <FormState error={formError} isSubmitting={isSubmitting} />
            <Container fullWidth centerAll>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleContinueClick}>
                Continue
              </FlatButton>
            </Container>
          </Actions>
          <Disclaimer>
            <Body5>
              {`Past returns are not necessarily indicative of future results. IMPORTANT: The projections or other information generated by ${PRODUCT_NAME}'s analysis tools regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual investment results and are not guarantees of future results.`}
            </Body5>
          </Disclaimer>
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
