import { ADD_HISTORY_DOCUMENTS_DATA, ADD_HISTORY_EQUITY_DATA, GET_HISTORY_EQUITY_DATA } from '../../constants';

const defaultState = {
  is_enough_for_chart: null,
  equity_history: null,
  isLoadingEquityHistory: false,

  documents: null,
};

export default function historyReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_HISTORY_DOCUMENTS_DATA:
      return {
        ...state,
        documents: action.payload,
      };

    case GET_HISTORY_EQUITY_DATA:
      return {
        ...state,
        isLoadingEquityHistory: true,
      };

    case ADD_HISTORY_EQUITY_DATA:
      return {
        ...state,
        isLoadingEquityHistory: false,
        is_enough_for_chart: action.payload.is_enough_for_chart,
        equity_history: action.payload.equity_history,
      };

    default:
      return state;
  }
}
