import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import { Config } from '../../constants';
import CreateThoughtContainer from '../Thoughts/CreateThoughtContainer';

class CreateThoughtFromNewsContainer extends Component {
  componentDidMount() {
    this._logNewsProConClick();
  }

  render() {
    const newsTrackingData = this.props.newsTrackingData;
    const viewingContext = newsTrackingData['Context'] ? newsTrackingData['Context'] : 'News';
    return (
      <CreateThoughtContainer
        key={`news-thought-${this._returnSecurityId()}-${this.props.ogo.url}`}
        customClassContainer={'news-component news-thought-container'}
        customMessageWithSymbol={this._returnHeadingGeneratorFunc()}
        security={this._returnSecurity()}
        ogo={this.props.ogo}
        thoughtType={this._returnThoughtType()}
        handleFinishCreatingThought={this.props.handleFinishCreatingThought}
        handleCancelCreatingThought={this.props.handleCancelCreatingThought}
        maxCharacterLimitForThought={this.returnMaxThoughtCharLimit()}
        viewingContext={viewingContext}
        isFromNews
      />
    );
  }

  returnMaxThoughtCharLimit = () => Config.maxCharacterLimitForThought - 25; // -25 to account for link in thought

  _returnHeadingGeneratorFunc = () => {
    return () => `Share this as a Pro or Con for ${this._returnSecuritySymbol()} and see what the community thinks.`;
  };

  _isSecurityDataAvailable = () => {
    return (
      this._returnSecurity() &&
      !('security_id' in this._returnSecurity()) &&
      !('featured' in this._returnSecurity()) &&
      !('name' in this._returnSecurity()) &&
      !('symbol' in this._returnSecurity())
    );
  };
  _fetchSecurityData = () => this.props.actions.quickFetchSecuritiesData([this._returnSecurityId()]);

  _returnThoughtType = () => this.props.thoughtType.name.toLowerCase();

  _returnSecurityId = () => this.props.securityId;
  _returnSecuritySymbol = () => (this._returnSecurity() ? this._returnSecurity().symbol : 'this security');

  _returnSecurity = () => this._returnSecuritiesLookup()[this._returnSecurityId()] || {};
  _returnSecuritiesLookup = () => this._returnSecuritiesStore().lookup;
  _returnSecuritiesStore = () => this.props.securities;

  _logNewsProConClick = () => {
    const thoughtType = this._returnThoughtType() === 'pro' ? 'Pro' : 'Con';
    const newsTrackingData = this.props.newsTrackingData;

    const event = 'Add Thought Panel Opened';
    const properties = {
      'Thought Type': thoughtType,
      ...newsTrackingData,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateThoughtFromNewsContainer);
