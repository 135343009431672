import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { LoadingPageNames } from '../../constants';
import * as Actions from '../../actions/index';

import PageLoading from '../../components/PageLoading';
import TabbedContainer from '../UI/TabbedContainer';
import ThoughtFeedContainer from './ThoughtFeedContainer';
import BasicSecurityDataWrapper from '../SecurityData/BasicSecurityDataWrapper';
import CreateThoughtContainer from './CreateThoughtContainer';

import { ThoughtsListOnly } from './components/ThoughtsListOnly';
import { ThoughtListHeading } from './components/ThoughtListHeading';
import { AddThoughtCTA } from './components/AddThoughtCTA';
import { OpenCreateThoughtPanelButton } from '../Thoughts/components/OpenCreateThoughtPanelButton';

import { toCapitalCase } from '../../helpers/generalHelpers';
import { TrackIteratively } from '../../utils/itly';
import { enums } from '@src/main/lib/nvstr-utils.es';
import { ElementPositionContext } from '../../context';
import styled from 'styled-components';

class ThoughtsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _activeTab: 'Pro',

      _shouldCollapseThoughtList: window.innerWidth < 800,

      _isCreateThoughtOpen: false,
      _createThoughtType: null, // 'pro' || 'con' || null
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this._handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleResize);
  }

  render() {
    return (
      <div className={`watchlist-thoughts-panel-container`}>
        {this._renderThoughtCarousel()}
        {this._shouldRenderThoughtListAsSingleColumn() ? this.renderOneColLayout() : this.renderTwoColLayout()}
      </div>
    );
  }

  _renderThoughtCarousel = () => {
    return (
      <div className={`thoughts-carousel`}>
        <BasicSecurityDataWrapper securityId={this._returnSecurityId()}>
          <ThoughtFeedContainer
            viewingContext={this.props.viewingContext}
            handleAfterThoughtAction={this.props.handleAfterThoughtAction}
            afterTrade={this.props.afterTrade}
            scrollContainerSelector={this.props.scrollContainerSelector}
            promptForThoughtAtEndOfList
            allowCarouselWrapping
            allowSkip
            logFirstItemView
          />
        </BasicSecurityDataWrapper>
      </div>
    );
  };

  _renderProCol = (config) => {
    return (
      <div className={``}>
        {config.showHeading && (
          <ThoughtListHeading
            handleOpenCreateThoughtPanelClick={this._handleAddThoughtClick}
            thoughtType={'pro'}
            showAddButton={this._shouldShowProAddThoughtBtn()}
          />
        )}
        {this._shouldShowProAddThoughtCTA() && (
          <BasicSecurityDataWrapper securityId={this._returnSecurityId()}>
            <AddThoughtCTA thoughtType={'pro'} handleClick={this._handleAddThoughtClick} />
          </BasicSecurityDataWrapper>
        )}
        {this._isCreatingProThoughtActive() && this._renderCreateThoughtComponent()}
        {this._isLoading() ? (
          <PageLoading pageName={LoadingPageNames.thoughts} flatStyle />
        ) : (
          <ThoughtsListOnly
            thoughts={this._returnCurrentUserThoughtsFor('pro')}
            securityId={this._returnSecurityId()}
            thoughtType={'pro'}
            viewingContext={this.props.viewingContext}
            afterTrade={this.props.afterTrade}
            handleAfterThoughtAction={this.props.handleAfterThoughtAction}
            scrollContainerSelector={this.props.scrollContainerSelector}
            useShareInModal
            isUserAuthed
          />
        )}
      </div>
    );
  };

  _renderConCol = (config) => {
    return (
      <div className={``}>
        {config.showHeading && (
          <ThoughtListHeading
            handleOpenCreateThoughtPanelClick={this._handleAddThoughtClick}
            thoughtType={'con'}
            showAddButton={this._shouldShowConAddThoughtBtn()}
          />
        )}

        {this._shouldShowConAddThoughtCTA() && (
          <BasicSecurityDataWrapper securityId={this._returnSecurityId()}>
            <AddThoughtCTA thoughtType={'con'} handleClick={this._handleAddThoughtClick} />
          </BasicSecurityDataWrapper>
        )}
        {this._isCreatingConThoughtActive() && this._renderCreateThoughtComponent()}
        {this._isLoading() ? (
          <PageLoading pageName={LoadingPageNames.thoughts} flatStyle />
        ) : (
          <ThoughtsListOnly
            thoughts={this._returnCurrentUserThoughtsFor('con')}
            securityId={this._returnSecurityId()}
            thoughtType={'con'}
            viewingContext={this.props.viewingContext}
            afterTrade={this.props.afterTrade}
            handleAfterThoughtAction={this.props.handleAfterThoughtAction}
            scrollContainerSelector={this.props.scrollContainerSelector}
            useShareInModal
            isUserAuthed
          />
        )}
      </div>
    );
  };

  _renderCreateThoughtComponent = () => {
    return (
      <CreateThoughtContainer
        viewingContext={this.props.viewingContext}
        security={this._returnSecurity()}
        thoughtType={this._returnCreatingThoughtType()}
        afterTrade={this.props.afterTrade}
        handleCancelCreatingThought={this.handleCancelCreatingThought}
        thoughtPreSuccessDelayAdditionalAction={this.getUpdateToDateNotifications}
        thoughtPostSuccessAdditionalAction={this._onPostThoughtSuccess}
      />
    );
  };

  renderTwoColLayout = () => {
    const config = {};
    return (
      <div className="react-thoughts-container clearfix">
        <div className="left-panel">{this._renderProCol(config)}</div>
        <div className="right-panel">{this._renderConCol(config)}</div>
      </div>
    );
  };

  renderOneColLayout = () => {
    const options = { defaultActiveTab: 'pro' };
    const proConColConfig = {};
    return (
      <div className="react-thoughts-container clearfix">
        <TabbedContainer
          options={options}
          handleTabClickAdditionalAction={this.handleTabClickAdditionalAction}
          tabs={[
            {
              name: 'pro',
              activeCustomStyle: 'pros-list-mobile-tab-styling',
            },
            {
              name: 'con',
              activeCustomStyle: 'cons-list-mobile-tab-styling',
            },
          ]}
          contentComponents={[this._renderProCol(proConColConfig), this._renderConCol(proConColConfig)]}
        />
      </div>
    );
  };

  _returnThoughtAddGoal = () => 3;

  _isLoading = () => this.props.isLoading;

  _shouldShowAddThoughtBtnFor = (thoughtType) =>
    thoughtType === 'pro' ? this._shouldShowProAddThoughtBtn('pro') : this._shouldShowConAddThoughtBtn('con');

  _shouldShowProAddThoughtBtn = () => !this._isCreatingThoughtForType('pro');

  _shouldShowConAddThoughtBtn = () => !this._isCreatingThoughtForType('con');

  _shouldShowProAddThoughtCTA = () => !this._isCreatingThoughtForType('pro');

  _shouldShowConAddThoughtCTA = () => !this._isCreatingThoughtForType('con');

  _isCreatingProThoughtActive = () => this._isCreatingThoughtForType('pro');

  _isCreatingConThoughtActive = () => this._isCreatingThoughtForType('con');

  _handleAddThoughtClick = (thoughtType) => {
    this.handleCreatingThought(thoughtType);
  };

  _isCreateThoughtOpen = () => this.state._isCreateThoughtOpen;

  _returnCreatingThoughtType = () => this.state._createThoughtType;

  _isCreatingThoughtForType = (thoughtType) =>
    this._isCreateThoughtOpen() && this._returnCreatingThoughtType() === thoughtType;

  _returnCurrentUserThoughtsFor = (thoughtType) =>
    thoughtType === 'pro' ? this.props.currentUserPros : this.props.currentUserCons;

  _handleCreateProClick = () => {
    this.handleCreatingThought('pro');
    this._logCreateThoughtClick('pro');
  };

  _handleCreateConClick = () => {
    this.handleCreatingThought('con');
    this._logCreateThoughtClick('con');
  };

  bindHandleOpenCreateThoughtPanelClick = (thoughtType) => () => this.handleOpenCreateThoughtPanelClick(thoughtType);

  handleOpenCreateThoughtPanelClick = (thoughtType) =>
    thoughtType === 'pro' ? this._handleCreateProClick() : this._handleCreateConClick();

  handleAfterThoughtAction = () => {
    if (this.props.handleAfterThoughtAction) {
      this.props.handleAfterThoughtAction();
    }
  };

  handleTabClickAdditionalAction = (tabName) => {
    const thoughtType = tabName;
    if (this._isCreateThoughtOpen()) {
      this.setState(() => ({
        _createThoughtType: thoughtType,
      }));
    }
    this._logThoughtTabClick(tabName);
    this._logItlyTabClick(tabName);
  };

  handleCreatingThought = (thoughtType) => {
    this.setState((prevState) => ({
      _isCreateThoughtOpen: true,
      _createThoughtType: thoughtType,
    }));
    this._logStartCreatingThoughtInThoughtPanel();
  };

  _onPostThoughtSuccess = () => {
    this._closeCreateThoughtPanel();
    this.handleAfterThoughtAction();
  };

  _closeCreateThoughtPanel = () => {
    this.setState((prevState) => ({
      _isCreateThoughtOpen: false,
      _createThoughtType: null,
    }));
  };

  handleCancelCreatingThought = () => {
    this._closeCreateThoughtPanel();
    this._logCancelCreatingThoughtInThoughtPanel();
  };

  _returnSecurity = () => this.props.security;

  _returnSecurityId = () => this._returnSecurity().security_id;

  _returnCurrentUser = () => this.props.currentUser;

  _isUserAuthed = () => 'user_id' in this._returnCurrentUser();

  _areNoThoughtsForSecurity = () => this.props.areNoThoughtsForSecurity;

  _areNoCurrentUserThoughtsForSecurity = () => this.props.areNoCurrentUserThoughtsForSecurity;

  _areCurrentUserProThoughts = () => this.props.areCurrentUserProThoughts;

  _areCurrentUserConThoughts = () => this.props.areCurrentUserConThoughts;

  _returnFilterThoughtsByUserId = () => this.props.userId;

  _shouldShowUserThoughtsOnly = () => !!this._returnFilterThoughtsByUserId();

  _shouldRenderThoughtListAsSingleColumn = () =>
    this.state._shouldCollapseThoughtList || this.props.lockSingleColumnLayout;

  _handleResize = () => {
    if (window.innerWidth < 800 && !this.state._shouldCollapseThoughtList) {
      this.setState({
        _shouldCollapseThoughtList: true,
      });
    } else if (window.innerWidth >= 800 && this.state._shouldCollapseThoughtList) {
      this.setState({
        _shouldCollapseThoughtList: false,
      });
    }
  };

  _logStartCreatingThoughtInThoughtPanel = () => {
    const event = 'Add Thought Panel Opened';
    const properties = {
      Context: this.props.viewingContext,
      'Security ID': this._returnSecurityId(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logCancelCreatingThoughtInThoughtPanel = () => {
    const event = 'Cancelled Creating Thought';
    const properties = {
      Context: this.props.viewingContext,
      'Security ID': this._returnSecurityId(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logThoughtTabClick = (tab) => {
    const event = 'Clicked Thoughts Tab';
    const properties = {
      Tab: tab,
      Context: this.props.viewingContext,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logItlyTabClick = (tabName) => {
    const { elementPosition } = this.context;

    const properties = {
      context: location.pathname,
      position: elementPosition || enums.node_location.body,
      platform: enums.platform.web,
      description: tabName,
      url: window.location.pathname,
      url_query: window.location.search,
    };
    TrackIteratively.generic.TAB_VIEWED.send(properties);
  };

  _logCreateThoughtClick = (thoughtType) => {
    const event = 'Add Thought Panel Opened';
    const properties = {
      'Security ID': this._returnSecurityId(),
      Context: 'Thoughts Panel',
      'Concernable Type': 'Security',
      'Concernable Id': this._returnSecurityId(),
      'Thought Type': toCapitalCase(thoughtType),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

ThoughtsPanel.contextType = ElementPositionContext;

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThoughtsPanel);
