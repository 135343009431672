import React from 'react';
import Slider from '@src/components/Slider/StyledSlider';
import { Body7, Place } from '@src/main/lib/nvstr-common-ui.es';
import { Container } from '@src/main/components/ui';
import styled from 'styled-components';

const SliderWrapper = styled.div`
  max-width: 324px;
`;
const OrderSizeFilterSlider = (props) => {
  return (
    <SliderWrapper>
      <Container>
        <Body7>{`Do not place orders smaller than $${props.value}`}</Body7>
      </Container>
      <Container top={6}>
        <Slider
          pearling={true}
          min={props.min}
          max={props.max}
          step={props.step || 1}
          value={props.value}
          onChange={props.handleChange}
          withBars
        />
      </Container>
    </SliderWrapper>
  );
};

export default OrderSizeFilterSlider;
