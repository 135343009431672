import React from 'react';
import VerticalNavItem from '../../components/VerticalNavItem';
import AskACTL from '../routeItems/AskACTL';
import TopPerformers from '../routeItems/TopPerformers';
import ConnectionRequestsCounter from '../../../Counters/ConnectionRequestsCounter';
import PeopleIcon from '@src/main/components/Images/PeopleIcon';
import LightBulbIcon from '@src/main/components/Images/LightBulbIcon';
import { useCurrentUser } from '@src/main/hooks/user';
import { returnThoughtLeaderIdentifier } from '@src/selectors/currentUser';

export const AskACTLVNavRoute = () => <AskACTL />;

export const ConnectionsVNavRoute = () => (
  <VerticalNavItem
    name="Connections"
    customIcon={<PeopleIcon />}
    customAdditionalElement={
      <ConnectionRequestsCounter className="vertical-nav-notifications-counter notification-circle-color" />
    }
    route="/users/connections"
    highlightOnTour={{
      tourStep: 3,
    }}
  />
);

export const ThoughtLeadersVNavRoute = () => {
  const currentUser = useCurrentUser();
  const thoughtLeaderIdentifier = returnThoughtLeaderIdentifier(currentUser);

  return (
    <VerticalNavItem
      name={`${thoughtLeaderIdentifier}s`}
      customIcon={<LightBulbIcon />}
      route="/users/thought_leaders"
    />
  );
};

export const TopPerformersVNavRoute = () => {
  const enableTopPerformers = false;

  if (enableTopPerformers) return <TopPerformers />;
  return null;
};
