import React from 'react';
import { Line } from 'react-chartjs-2';
import { customChartTooltip } from '../../helpers/chartHelpers';
import { formatLocaleString } from '../../helpers/generalHelpers';
import { formatForDisplay } from '../../helpers/displayHelpers';
import { FONT } from '@src/appConfig';

const buildColor = (colorObj, opacity) => {
  return `rgba( ${colorObj.red}, ${colorObj.green}, ${colorObj.blue}, ${opacity})`;
};

const _returnChartAxisValueFormatted = (value) => {
  if (value < 0.0001) {
    return formatLocaleString(value, {
      maximumFractionDigits: 0,
      currency: 'USD',
    });
  }
  if (value < 0.01) {
    return formatLocaleString(value, {
      minimumFractionDigits: 3,
      maximumFractionDigits: 4,
      currency: 'USD',
    });
  }
  if (value < 0.1) {
    return formatLocaleString(value, {
      minimumFractionDigits: 3,
      maximumFractionDigits: 4,
      currency: 'USD',
    });
  }
  if (value < 1) {
    return formatLocaleString(value, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: 'USD',
    });
  }
  if (value < 10) {
    return formatLocaleString(value, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: 'USD',
    });
  }
  return formatLocaleString(value, {
    maximumFractionDigits: 2,
    currency: 'USD',
  });
};

const _returnLineChartOptions = (config) => {
  return {
    maintainAspectRatio: config.maintainAspectRatio || false,
    showXLabels: 5,
    animation: {
      duration: 220,
    },
    scales: {
      xAxis: {
        grid: {
          display: false,
          color: config.xAxesGridLinesColor || 'rgba(220, 220, 220, 0)',
          ...(config.xAxesGridLines || {}),
        },

        ticks: {
          display: false,
          font: {
            color: config.yAxesLabelFontColor || '#ccc',
            size: config.yAxesLabelFontSize || 12,
            family: FONT.normal,
          },
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      yAxis: {
        grid: {
          drawBorder: false,
          color: config.yAxesGridLinesColor || 'rgba(235, 235, 235, 0.4)',
          ...(config.yAxesGridLines || {}),
        },
        position: config.yAxesLabelPosition || 'right',
        ticks: {
          ...(config.yAxesTicks || {}),
          font: {
            color: config.yAxesLabelFontColor || '#ccc',
            size: config.yAxesLabelFontSize || 12,
            family: FONT.normal,
          },
          callback: function (value, index, values) {
            if (config.yAxesAfterBuildTicks) {
              if (index === 0) {
                return config.yAxesAfterBuildTicks[1];
              }
              if (index === values.length - 1) {
                return config.yAxesAfterBuildTicks[0];
              }
            } else {
              return _returnChartAxisValueFormatted(value);
            }
          },
        },
      },
    },
    plugins: {
      legend: config.showLegend || false,

      tooltip: {
        enabled: !config.disableTooltips,
        callbacks: {
          title: function (tooltipItem) {
            const item = tooltipItem[0];
            const { label: xLabel, dataset } = item;
            const { label } = dataset;
            return `${label} - ${xLabel}`;
          },
          label: function (context) {
            return '';
          },
          afterBody: function (tooltipItem) {
            const item = tooltipItem[0];
            const { raw: rawValue } = item;
            return `Price: ${formatForDisplay(rawValue, 'price')}`;
          },
        },
      },
    },
  };
};

class SecurityChart extends React.Component {
  render() {
    const { color, chartData, symbol, config } = this.props;

    const formatChartData = (canvas) => {
      const { labels, prices, additionalDataset } = chartData;

      const styling = {
        fill: !config.hideFill,

        lineTension: 0.1,
        borderWidth: 2,
        borderCapStyle: 'round',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        pointBorderWidth: 1,
        pointHoverRadius: 6,
        spanGaps: true,
      };

      const datasets = [
        {
          label: symbol,
          data: prices,

          fill: !config.hideFill,
          backgroundColor: 'rgba(36, 217, 121, 0.2)',
          borderColor: 'rgba(36, 217, 121, 1.0)',

          pointBorderColor: 'rgba(61, 183, 53, 0)',
          pointBackgroundColor: 'rgba(61, 183, 53, 0)',

          pointHoverBackgroundColor: buildColor(color, '0.75'),
          pointHoverBorderColor: 'rgba(220, 220, 220, 1)',

          ...styling,
        },
      ];

      if (additionalDataset) {
        datasets.push({
          label: 'SPY',
          data: additionalDataset,

          backgroundColor: 'rgba(200, 200, 220, 0.25)',
          borderColor: config.customBorderColor || 'rgba(200, 200, 220, 0.5)',

          pointBorderColor: 'rgba(61, 183, 53, 0)',
          pointBackgroundColor: 'rgba(61, 183, 53, 0)',

          pointHoverBackgroundColor: 'rgba(200,200,220,.5)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',

          ...styling,
        });
      }

      return {
        labels,
        datasets,
      };
    };

    const data = formatChartData();
    const options = _returnLineChartOptions(config);

    if (!data.datasets || data.datasets.length === 0) {
      return <div className={'fin-chart'} />;
    }
    return (
      <div className={'fin-chart'}>
        <Line type={'line'} data={data} options={options} height={config.height} width={config.width} />
        {config.xAxisLabel && <div className="x-axis-label">{config.xAxisLabel}</div>}
      </div>
    );
  }
}

export default SecurityChart;
