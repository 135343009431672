import { optimizerAdapter } from '../adapters/optimizerAdapter';

import {
  OPEN_OPTIMIZER_PANEL,
  CLOSE_OPTIMIZER_PANEL,
  RUN_OPTIMIZER,
  NEW_OPTIMIZATION_RECEIVED,
  UPDATE_LAST_OPTIMIZED_TIMESTAMP,
  ADD_OPTIMIZER_ALLOCATION_CONSTRAINTS,
  CREATE_TEMPORARY_OPTIMIZER_ALLOCATION_CONSTRAINT,
  CREATE_OPTIMIZER_ALLOCATION_CONSTRAINT,
  UPDATE_OPTIMIZER_ALLOCATION_CONSTRAINT,
  DELETE_OPTIMIZER_ALLOCATION_CONSTRAINT,
  MOVE_OPTIMIZER_ALLOCATION_CONSTRAINT_TO_TOP,
} from '../constants';
import { moment } from '../helpers/timeHelpers';

export const openOptimizerPanel = () => {
  return function(dispatch) {
    // dispatch({
    //   type: OPEN_OPTIMIZER_PANEL,
    // });
  };
};

export const closeOptimizerPanel = () => {
  window.$('html, body').animate(
    {
      scrollTop: 0,
    },
    300
  );

  return function(dispatch) {
    // dispatch({
    //   type: CLOSE_OPTIMIZER_PANEL,
    // });
  };
};

export const runOptimizer = (customOptimizerSettings, delay) => {
  const optimizerCalledTimestamp = moment().valueOf();

  return function(dispatch) {
    dispatch({
      type: RUN_OPTIMIZER,
      payload: {
        optimizerData: {
          summary_message: 'Optimizer is analyzing portfolio',
        },
        optimizerCallTimestamp: optimizerCalledTimestamp,
      },
    });

    return optimizerAdapter.runOptimizer(customOptimizerSettings).then((response) => {
      const wasRequestSuccessful =
        typeof response === 'object' && response.data && response.data.optimized_positions_by_security_id;
      const optimizerData = wasRequestSuccessful ? response.data : { status: 'failed' };
      optimizerData.lastUpdated = optimizerCalledTimestamp;

      if (wasRequestSuccessful) {
        setTimeout(() => {
          dispatch({
            type: NEW_OPTIMIZATION_RECEIVED,
            payload: {
              optimizerData,
              optimizerCallTimestamp: optimizerCalledTimestamp,
            },
          });
        }, delay || 1);
      } else {
        setTimeout(() => {
          // updating
          dispatch({
            type: UPDATE_LAST_OPTIMIZED_TIMESTAMP,
            payload: {
              optimizerCallTimestamp: optimizerCalledTimestamp,
            },
          });
        }, delay || 1);
      }

      return response;
    });
  };
};

export const clearOptimizerCooldown = (options) => {
  // POST api/optimize/clear_cooldown
  return function(dispatch) {
    return optimizerAdapter.clearOptimizerCooldown().then((response) => {
      return response;
    });
  };
};

export const createOptimizedOrders = () => {
  return function(dispatch) {
    return optimizerAdapter.createOptimizedOrders();
  };
};

export const getOptimizerAllocationConstraints = () => {
  // GET ‘api/optimizer/constraints’
  return function(dispatch) {
    return optimizerAdapter.getOptimizerAllocationConstraints().then((response) => {
      const requestWasSuccessful = response.data;
      if (requestWasSuccessful) {
        const optimizeConstraints = response.data.optimize_constraints;
        dispatch({
          type: ADD_OPTIMIZER_ALLOCATION_CONSTRAINTS,
          payload: { optimizeConstraints },
        });
      } else {
        // TASK: TODO
      }
      return response;
    });
  };
};

export const createOptimizerAllocationConstraints = (querySecurityData) => {
  // POST ‘api/optimizer/constraints’
  return function(dispatch) {
    const tempAllocationConstraint = { ...querySecurityData, loading: true };
    dispatch({
      type: CREATE_TEMPORARY_OPTIMIZER_ALLOCATION_CONSTRAINT,
      payload: { optimizeConstraint: tempAllocationConstraint },
    });

    return optimizerAdapter.createOptimizerAllocationConstraints(querySecurityData).then((response) => {
      const requestWasSuccessful = true;
      // TASK: TODO
      if (requestWasSuccessful) {
        const optimizeConstraint = response.data.optimize_constraints;
        dispatch({
          type: CREATE_OPTIMIZER_ALLOCATION_CONSTRAINT,
          payload: { optimizeConstraint },
        });
      } else {
        // TASK: TODO
      }
      return response;
    });
  };
};

export const updateOptimizerAllocationConstraints = (optimizeConstraintObj) => {
  // PUT ‘api/optimizer/constraints’
  return function(dispatch) {
    dispatch({
      type: UPDATE_OPTIMIZER_ALLOCATION_CONSTRAINT,
      payload: { optimizeConstraint: optimizeConstraintObj },
    });

    return optimizerAdapter.updateOptimizerAllocationConstraints(optimizeConstraintObj).then((response) => {
      // TASK: TODO:
      // const ajaxWasSuccessful = true;
      // if ( ajaxWasSuccessful ) {

      // }
      // else {

      // }
      return response;
    });
  };
};

export const deleteOptimizerAllocationConstraint = (optimizerConstraintId) => {
  // DELETE ‘api/optimizer/constraints’
  return function(dispatch) {
    dispatch({
      type: DELETE_OPTIMIZER_ALLOCATION_CONSTRAINT,
      payload: { optimizerConstraintId },
    });

    return optimizerAdapter.deleteOptimizerAllocationConstraint(optimizerConstraintId).then((response) => {
      // const ajaxWasSuccessful = true;
      // if ( ajaxWasSuccessful ) {
      // }
      // else {
      // }
      // return response;
    });
  };
};

export const moveAllocationConstraintToTopOfList = (securityId) => {
  // DELETE ‘api/optimizer/constraints’
  return function(dispatch) {
    dispatch({
      type: MOVE_OPTIMIZER_ALLOCATION_CONSTRAINT_TO_TOP,
      payload: { securityId },
    });
  };
};
