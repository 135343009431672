import React from 'react';

const TourArrow = (props) => {
  const id = props.id || 'tour-arrow';
  const className = props.className;
  const height = props.height || '200px';
  const width = props.width || '200px';
  return (
    <svg
      className={className}
      height={height}
      width={width}
      viewBox="0 0 1321 1611"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Arrow</title>
      <g id={`g-${id}`} stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          id={`path-${id}`}
          d="M-353.33746,1167.17869 C-353.33746,1167.17869 -369.027564,1148.92087 -400.407773,1112.40525 C-66.2957934,816.952123 257.027123,669.22556 569.560977,669.22556 C813.209415,669.22556 1050.98155,737.534154 1282.87738,874.151341 L1365.09613,758.643529 L1562.04145,1117.14353 L1159.04535,1059.37009 L1242.83832,934.721654 C1017.46722,797.286084 781.185974,733.745719 533.994571,744.10056 C149.150821,760.221654 -167.388241,1002.16306 -353.33746,1167.17869 Z"
          transform="translate(580.816837, 918.202123) rotate(235.000000) translate(-580.816837, -918.202123) "
        />
      </g>
    </svg>
  );
};

export default TourArrow;
