import React from 'react';
import Icon from '../misc/Icon';
import styled from 'styled-components';

const TextFieldWrapper = styled.div`
  display: block;
  margin: 0 auto 10px auto;

  fieldset {
    display: block;
    width: auto;
    margin: 0;
  }

  input {
    border: none;

    font-size: 20px;
    margin-top: 15px;
    max-width: 80px;
    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  input {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.themeColors.text};

    font-size: 20px;
    margin-top: 15px;
    max-width: 80px;
    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
`;

export const SocialTextField = (props) => {
  const { name, label, error, value, handleChange, handleFocus: onFocusEffect, handleBlur } = props;

  const [showing, setShowing] = React.useState(false);
  const [part1, setPart1] = React.useState('');
  const [part2, setPart2] = React.useState('');
  const [part3, setPart3] = React.useState('');
  const part1Ref = React.useRef();
  const part2Ref = React.useRef();
  const part3Ref = React.useRef();

  const focusedOrValue = true;

  React.useEffect(() => {
    handleChange({ target: { value: `${part1 || ''}-${part2 || ''}-${part3 || ''}` } }, name);
  }, [part1, part2, part3, name]);

  const onFocus = React.useCallback(
    (inputIndex) => () => {
      onFocusEffect();
      if (part1 === '' || value === '--') {
        if (inputIndex > 0) {
          part1Ref?.current?.focus();
        }
      }
      if (part2 === '') {
        if (inputIndex > 1) {
          part2Ref?.current?.focus();
        }
      }
    },
    [part1, value]
  );

  return (
    <TextFieldWrapper>
      <fieldset className={`text-field`}>
        <InputsWrapper>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
            <input
              type={showing ? 'text' : 'password'}
              style={{ width: '30%', textAlign: 'center' }}
              name={'first_three'}
              onFocus={onFocus(0)}
              onBlur={() => {
                handleBlur(value, name);
              }}
              onChange={(e) => {
                if (e.target.value.length < 4) {
                  if (e.target.value.length === 3) part2Ref.current.focus();
                  setPart1(e.target.value);
                }
              }}
              value={part1}
              step="0.01"
              ref={part1Ref}
            />
            {focusedOrValue && <div style={{ marginTop: '15px', marginRight: '1px', marginLeft: '1px' }}>-</div>}
            <input
              type={showing ? 'text' : 'password'}
              name={'middle_two'}
              style={{ width: '20%', textAlign: 'center' }}
              onFocus={onFocus(1)}
              onBlur={() => {
                handleBlur(value, name);
              }}
              onChange={(e) => {
                if (e.target.value.length < 3) {
                  if (e.target.value.length === 2) part3Ref.current.focus();
                  setPart2(e.target.value);
                }
              }}
              onKeyDown={(e) => {
                if (e.target.value.length === 0 && e.key === 'Backspace') {
                  part1Ref.current.focus();
                }
              }}
              min={0}
              max={99}
              value={part2}
              ref={part2Ref}
            />
            {focusedOrValue && <div style={{ marginTop: '15px', marginRight: '1px', marginLeft: '1px' }}>-</div>}
            <input
              type={showing ? 'text' : 'password'}
              name={'last_four'}
              style={{ width: '40%', textAlign: 'center' }}
              onFocus={onFocus(2)}
              onBlur={() => {
                handleBlur(value, name);
              }}
              onChange={(e) => {
                if (e.target.value.length < 5) {
                  setPart3(e.target.value);
                }
              }}
              onKeyDown={(e) => {
                if (e.target.value.length === 0 && e.key === 'Backspace') {
                  part2Ref.current.focus();
                }
              }}
              value={part3}
              ref={part3Ref}
            />
          </div>
          <a onClick={() => setShowing(!showing)} style={{ marginRight: '8px', marginTop: '15px', cursor: 'pointer' }}>
            <Icon icon={showing ? 'fa fa-eye-slash' : 'fa fa-eye'} />
          </a>
        </InputsWrapper>
        <label htmlFor={label} className={focusedOrValue ? 'text-field-lifted-label' : ''}>
          {label}
        </label>

        <div className={`after ${error ? 'error-underline' : ''}`} />

        {error && <p className={`text-field-error-message`}>{error}</p>}
      </fieldset>
    </TextFieldWrapper>
  );
};

export default SocialTextField;
