export const BANK_ACCOUNT_FILTER_TYPES = {
  depositReady: 'dr',
  needsVerification: 'nv',
  isVerificationReady: 'ivr',
};

export const filterBankAccounts = (bankAccounts, filter) => {
  switch (filter) {
    case BANK_ACCOUNT_FILTER_TYPES.depositReady: {
      return bankAccounts.filter((account) => account.can_accept_transfers);
    }
    case BANK_ACCOUNT_FILTER_TYPES.needsVerification: {
      return bankAccounts.filter((account) => account.needs_micro_deposit_verification);
    }
    case BANK_ACCOUNT_FILTER_TYPES.isVerificationReady: {
      return bankAccounts.filter((account) => account.needs_micro_deposit_verification && account.micro_deposits_ready);
    }
    default: {
      console.error('no filter supplied: filterBankAccounts');
      return bankAccounts;
    }
  }
};

export const canVerifyBankAccount = (account) => {
  const { micro_deposits_ready, needs_micro_deposit_verification, can_accept_transfers } = account;
  return micro_deposits_ready && needs_micro_deposit_verification && can_accept_transfers;
};

export const isBankAccountVerified = (account) => {
  const { can_accept_transfers, needs_micro_deposit_verification } = account;
  return can_accept_transfers && !needs_micro_deposit_verification;
};

export const isBankAccountActive = (account) => {
  const { can_accept_transfers } = account;
  return can_accept_transfers;
};

export const canBankAccountAcceptDeposits = (account) => {
  const { can_accept_transfers } = account;
  return can_accept_transfers;
};

export const buildBankIdentifierFromBankAccount = (bankAccount) => {
  const { account_type, account_number_ending } = bankAccount;
  return `${account_type.toUpperCase()} (...${account_number_ending})`;
};
