import { createDropdownItem } from '../lib/nvstr-common-ui.es';
import { doXTimes } from '../../helpers/usefulFuncs';

export const startingInvestmentItems = [
  createDropdownItem({ text: '$100', value: 100 }),
  createDropdownItem({ text: '$250', value: 250 }),
  createDropdownItem({ text: '$500', value: 500 }),
  createDropdownItem({ text: '$1,000', value: 1000 }),
  createDropdownItem({ text: '$2,500', value: 2500 }),
  createDropdownItem({ text: '$5,000', value: 5000 }),
  createDropdownItem({ text: '$10,000', value: 10000 }),
  createDropdownItem({ text: '$25,000', value: 25000 }),
  createDropdownItem({ text: '$50,000', value: 50000 }),
  createDropdownItem({ text: '$100,000', value: 100000 }),
];
export const recurringInvestmentItems = [
  createDropdownItem({ text: '$0', value: 0 }),
  createDropdownItem({ text: '$10', value: 10 }),
  createDropdownItem({ text: '$20', value: 20 }),
  createDropdownItem({ text: '$50', value: 50 }),
  createDropdownItem({ text: '$100', value: 100 }),
  createDropdownItem({ text: '$250', value: 250 }),
  createDropdownItem({ text: '$500', value: 500 }),
  createDropdownItem({ text: '$1,000', value: 1000 }),
  createDropdownItem({ text: '$2,500', value: 2500 }),
  createDropdownItem({ text: '$5,000', value: 5000 }),
];
export const recurringRateItems = [
  createDropdownItem({ text: 'monthly', value: 'monthly' }),
  createDropdownItem({ text: 'yearly', value: 'yearly' }),
];
export const horizonYearItems = [
  createDropdownItem({ text: '5', value: 5 }),
  createDropdownItem({ text: '10', value: 10 }),
  createDropdownItem({ text: '15', value: 15 }),
  createDropdownItem({ text: '20', value: 20 }),
  createDropdownItem({ text: '25', value: 25 }),
  createDropdownItem({ text: '30', value: 30 }),
  createDropdownItem({ text: '35', value: 35 }),
  createDropdownItem({ text: '40', value: 40 }),
  createDropdownItem({ text: '45', value: 45 }),
  createDropdownItem({ text: '50', value: 50 }),
  createDropdownItem({ text: '55', value: 55 }),
  createDropdownItem({ text: '60', value: 60 }),
  createDropdownItem({ text: '65', value: 65 }),
];
const _returnRateItems = [];
const popReturnRateItems = (i) => {
  _returnRateItems.push(createDropdownItem({ text: `${i + 1}%`, value: (i + 1) / 100 }));
};
doXTimes(50, popReturnRateItems);

export const returnRateItems = _returnRateItems;
