import { authenticationAdapter } from '../adapters/authenticationAdapter';
import itly from '../utils/itly/itly.service';

export const logoutUser = () => {
  return function (dispatch) {
    return authenticationAdapter.logoutUser().then((response) => {
      if (window.localStorage) {
        window.localStorage.removeItem('X-CSRF-Token');
      }
      itly.reset();
      window.location = '/app/login';
      return response;
    });
  };
};
