import React, { Component } from 'react';

import { IdeaPerformanceHeading } from './IdeaPerformanceComponents/IdeaPerformanceHeading';
import { IdeaPerformanceList } from './IdeaPerformanceComponents/IdeaPerformanceList';
import Button from '../../../../components/buttons/Button';
import { FlatButton } from '../../../../main/components/buttons';

class IdeaPerformanceNotification extends Component {
  constructor() {
    super();
    this.state = {
      isExpanded: false,
    };
  }

  render() {
    return (
      <div className="idea-perf-notif-container">
        <IdeaPerformanceHeading notification={this.props.notification} />
        <IdeaPerformanceList
          notification={this.props.notification}
          performanceList={this.props.notification.performances}
          isExpanded={this.state.isExpanded}
          maxRowsCollapsed={this.returnMaxPerformanceRowsCollapsedCount()}
          notificationKeyString={`ideas-perf-notif-${this.props.notification.adjective}-${this.props.notification.start_phrase}-${this.props.notification.end_phrase}`}
        />
        {this.isIdeaPerformanceListCollapsable() &&
          (this.isIdeaPerformanceListExpanded() ? (
            <div className="idea-perf-list see-more-less-button-container notification-indent">
              <FlatButton transparent onClick={this.handleSeeLessClick}>
                LESS
              </FlatButton>
            </div>
          ) : (
            <div className="idea-perf-list see-more-less-button-container notification-indent">
              <FlatButton transparent onClick={this.handleSeeMoreClick}>
                MORE
              </FlatButton>
            </div>
          ))}
      </div>
    );
  }

  returnMaxPerformanceRowsCollapsedCount = () => 5;

  returnIdeaPerformances = () => this.props.notification.performances;

  isIdeaPerformanceListCollapsable = () =>
    this.returnIdeaPerformances().length > this.returnMaxPerformanceRowsCollapsedCount();

  isIdeaPerformanceListExpanded = () => this.state.isExpanded;

  handleSeeMoreClick = () => {
    this.toggleSeeMore();
  };

  handleSeeLessClick = () => {
    this.toggleSeeMore();
  };

  toggleSeeMore = () => {
    this.setState((prevState) => {
      return {
        isExpanded: !prevState.isExpanded,
      };
    });
  };
}

export default IdeaPerformanceNotification;
