import React from 'react';
import styled from 'styled-components';

export const ColorChangeText = styled.div`
  color: ${({ theme, value }) =>
    value > 0 ? theme.themeColors.positive : value < 1 ? theme.themeColors.negative : theme.themeColors.text};

  * {
    color: ${({ theme, value }) =>
      value > 0
        ? theme.themeColors.positive
        : value < 1
        ? theme.themeColors.negative
        : theme.themeColors.text} !important;
  }
`;
