import React from 'react';
import { getViewpointMonthlyFeeFromViewpointOrg } from '@src/helpers/viewpointHelpers';
import { formatForDisplay } from '@src/helpers/displayHelpers';
import { PRODUCT_DISPLAY_FULL_NAME } from '@src/appConfig';

class MonthlyFee extends React.PureComponent {
  render() {
    const { org } = this.props;
    const { is_free } = org;

    const monthlyFee = getViewpointMonthlyFeeFromViewpointOrg(org);
    if (is_free) {
      return <span className={'secondary-text-color'}>{`Free with ${PRODUCT_DISPLAY_FULL_NAME} Membership`}</span>;
    } else {
      return <span className={'secondary-text-color'}>{`${formatForDisplay(monthlyFee, 'price')} per month`}</span>;
    }
  }
}

export default MonthlyFee;
