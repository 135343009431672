import React from 'react';
import { ChangeIndicator } from '@src/main/lib/nvstr-common-ui.es';
import styled from 'styled-components';
import { usePriceData } from '@src/main/hooks/securities/usePriceData';
import { formatSecurityPropertyValue } from '@src/helpers/securitiesHelpers';
import { ColorChangeText } from '@src/main/components/molecules/ColorChangeText';
import { useSecurity } from '../../hooks/securities/useSecurity';
import { Body5 } from '../../lib/nvstr-common-ui.es';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 6px;

  opacity: ${({ value }) => (value === 0 ? '0.6' : '1')};

  margin: 0;
`;

const PriceChangeIndicatorWrapper = styled.div``;

const PriceWrapper = styled.div``;

export const PriceChange = ({ securityId, showCurrentPrice }) => {
  const { name, featured, symbol } = useSecurity(securityId);
  const { price, change, percentChange } = usePriceData(securityId);

  const priceDisplay = formatSecurityPropertyValue(price, 'current_price');
  const priceChangeDisplay = formatSecurityPropertyValue(change, 'current_price_change');
  const percentChangeDisplay = formatSecurityPropertyValue(percentChange, 'current_price_change_percent');

  if (name && !featured) {
    return null;
  }

  if (isNaN(price) || price === null) {
    return (
      <Wrapper value={change}>
        <Body5>...</Body5>
      </Wrapper>
    );
  }

  if (showCurrentPrice) {
    return (
      <Wrapper value={change}>
        <PriceWrapper value={change}>{priceDisplay}</PriceWrapper>
        <PriceChangeIndicatorWrapper>
          <ChangeIndicator value={change} size={18} />
        </PriceChangeIndicatorWrapper>

        <ColorChangeText value={change}>
          {change > 0 ? '+' : ''}
          {percentChangeDisplay}
        </ColorChangeText>
      </Wrapper>
    );
  }

  return (
    <Wrapper value={change}>
      <PriceChangeIndicatorWrapper>
        <ChangeIndicator value={change} size={18} />
      </PriceChangeIndicatorWrapper>
      <ColorChangeText value={change}>
        {change > 0 ? '+' : ''}
        {priceChangeDisplay}
      </ColorChangeText>
      <ColorChangeText value={change}>
        {change > 0 ? '+' : ''}
        {percentChangeDisplay}
      </ColorChangeText>
    </Wrapper>
  );
};
