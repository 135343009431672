import React from 'react';
import LoadingIcon from '../../components/misc/LoadingIcon';

export class MultiSelectItem {
  constructor({ Component, props, value }) {
    this.Component = Component;
    this.props = props;
    this.value = value;
  }
}

const MultiSelect = (props) => {
  const {
    header,
    separator,

    isLoading,
    multiSelectItems,
    itemsContainerStyle,

    onSelect,
    defaultState,

    useRadioButtonBehavior,
    children,
  } = props;

  const [values, setValues] = React.useState(defaultState !== null ? defaultState : []);

  const isSelected = (i) => {
    const v = multiSelectItems[i].value;
    return values.some((value) => value === v);
  };

  const toggleSelected = (i) => {
    const value = multiSelectItems[i].value;
    const isItemSelected = isSelected(i);
    if (isItemSelected) {
      setValues(values.filter((activeValue) => activeValue !== value));
    } else {
      if (useRadioButtonBehavior) {
        setValues([value]);
      } else {
        setValues([...values, value]);
      }
    }
  };

  const handleSelectPress = (i) => {
    const selection = multiSelectItems[i].value;
    onSelect && onSelect(selection);
    toggleSelected(i);
  };

  if (isLoading) {
    return (
      <div>
        <div
          className={'flex-center-child'}
          style={{
            height: 75,
          }}
        >
          <LoadingIcon />
        </div>
      </div>
    );
  }

  return (
    <div>
      {header}
      <div>
        {multiSelectItems.map((multiSelectItem, i) => (
          <div key={i}>
            <multiSelectItem.Component
              {...multiSelectItem.props}
              isSelected={isSelected(i)}
              handleClick={() => handleSelectPress(i)}
            />
            {separator}
          </div>
        ))}
      </div>
      {children(values)}
    </div>
  );
};

MultiSelect.defaultProps = {
  itemsContainerStyle: {},
};

export default MultiSelect;
