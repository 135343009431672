import React from 'react';
import { IdeaPerformanceRow } from './IdeaPerformanceRow';

export const IdeaPerformanceList = (props) => {
  const { notification, performanceList, notificationKeyString, isExpanded, maxRowsCollapsed } = props;

  const renderPerformanceList = (performanceList) =>
    performanceList.map((performanceData, index) => (
      <IdeaPerformanceRow
        key={`notificationKeyString-${index}`}
        performance={performanceData}
        notification={notification}
        notificationKeyString={notificationKeyString}
      />
    ));

  const alwaysShowPerformanceList = performanceList.slice(0, maxRowsCollapsed);
  const collapsedPerformanceList = performanceList.slice(maxRowsCollapsed);
  return (
    <div>
      <div className="idea-perf-list notification-indent">{renderPerformanceList(alwaysShowPerformanceList)}</div>
      {isExpanded && (
        <div className="idea-perf-list notification-indent">{renderPerformanceList(collapsedPerformanceList)}</div>
      )}
    </div>
  );
};
