import React from 'react';
import { PRODUCT_DISPLAY_NAME } from '@src/appConfig';
import FireEventOnMount from '../ExtraFunctionalityComponents/FireEventOnMount';
import EditProfile from './EditProfile';
import EditAccount from './EditAccount';
import EditPreferences from './EditPreferences';
import { parseQueryString, createQueryString } from '../../helpers/routerHelpers';
import { withRouter } from '../../main/utils';
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { H5 } from '@src/main/lib/nvstr-common-ui.es';
import { useCurrentUser } from '@src/main/hooks/user';
import { logMetricsTrackingEvent } from '@src/actions';

const Wrapper = styled.div`
  padding: 8px 16px 0 16px;
  min-height: 60vh;
`;
const HeaderWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
`;

const HeaderTabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  max-width: 800px;
  margin: 0 auto;
`;
const ComponentWrapper = styled.div`
  padding: 24px 16px 48px 16px;
  max-width: 800px;
  margin: 0 auto;
`;

const HeaderTextWrapper = styled.div`
  padding: 16px 16px;
  max-width: 800px;
  margin: 0 auto;
`;

const TabWrapper = styled.div`
  padding: 8px 16px;
  cursor: pointer;

  &.active {
    color: ${({ theme }) => theme.themeColors.primaryCtaButton};
    border-bottom: 2px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
  }

  &:hover {
    color: ${({ theme }) => theme.themeColors.primaryCtaButton};
  }
`;

const Tab = ({ name, value, isActive }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClick = () => {
    const params = {
      tab: value,
    };
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams(params)}`,
    };
    navigate(options, { replace: true });

    const event = 'Clicked Tab';
    const properties = {
      Tab: value,
    };
    logMetricsTrackingEvent(event, properties)();
  };
  return (
    <TabWrapper onClick={onClick} className={isActive ? 'active' : ''}>
      {name}
    </TabWrapper>
  );
};

const getTabFromSearchParams = (searchParams) => searchParams.get('tab');

const TabbedScreen = ({ defaultTab, tabs, componentLookup }) => {
  const [searchParams] = useSearchParams();
  const { first_name, last_name } = useCurrentUser();

  const [activeSection, setActiveSection] = React.useState(getTabFromSearchParams(searchParams) || defaultTab);

  React.useEffect(
    () => {
      const tab = getTabFromSearchParams(searchParams);
      if (tab !== activeSection && tab) {
        setActiveSection(tab);
      }
    },
    [searchParams, activeSection]
  );
  return (
    <div>
      <HeaderWrapper>
        <HeaderTextWrapper>
          <H5>{`${first_name} ${last_name}`}</H5>
        </HeaderTextWrapper>
        <HeaderTabsWrapper>
          {tabs.map((t) => (
            <Tab key={t} isActive={activeSection === t} name={componentLookup[t].displayText} value={t} />
          ))}
        </HeaderTabsWrapper>
      </HeaderWrapper>

      <ComponentWrapper>{componentLookup[activeSection]?.renderer()}</ComponentWrapper>
    </div>
  );
};

const TAB_NAMES = {
  editProfile: 'profile',
  account: 'account',
  preferences: 'preferences',
};

const componentLookup = {
  [TAB_NAMES.editProfile]: {
    name: 'profile',
    displayText: 'Profile',

    renderer: () => <EditProfile />,
  },
  [TAB_NAMES.account]: {
    name: 'account',
    displayText: 'Account',

    renderer: () => <EditAccount />,
  },
  [TAB_NAMES.preferences]: {
    name: 'preferences',
    displayText: 'Preferences',

    renderer: () => <EditPreferences />,
  },
};

const tabs = [TAB_NAMES.editProfile, TAB_NAMES.account, TAB_NAMES.preferences];

const UserSettingsContainer = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    document.title = `${PRODUCT_DISPLAY_NAME} - Account Settings`;

    const defaultToPrefTabIfEmailUnsubscribe = () => {
      const { pathname, search } = location;
      const query = parseQueryString(search);
      if (query.account === '1') {
        delete query.account;
        query.tab = TAB_NAMES.preferences;
        navigate(`${pathname}${createQueryString(query)}`);
      }
    };
    defaultToPrefTabIfEmailUnsubscribe();
  }, []);

  return (
    <Wrapper>
      <FireEventOnMount eventName={'View Account Settings'} />
      <TabbedScreen defaultTab={TAB_NAMES.editProfile} componentLookup={componentLookup} tabs={tabs} />
    </Wrapper>
  );
};

export default withRouter(UserSettingsContainer);
