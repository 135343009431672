import React, { Component, Children } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../../../actions/index';

import {
  isLoadingFundamentalData,
  getSecurityValueFromStore,
  formatSecurityPropertyValue,
} from '../../../../helpers/securitiesHelpers';

class CompTableAggregationRow extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const columns = this.props.columns;
    const aggregation = this.props.aggregation;

    return (
      <tr id={`aggregation-row-${aggregation.label}`} className="comp-table-aggregation-row secondary-text-color">
        <td className="aggregator-label" colSpan={this.props.returnAggColSpan}>
          {this.props.aggregation.label}
        </td>
        {columns.map((column, i) => this.renderColumn(column, i))}
      </tr>
    );
  }

  renderColumn = (column, i) => {
    const securityIds = this.props.securityIds;
    const property = column.dataKey;
    const name = column.name;

    const values =
      property === 'Dividend Yield'
        ? securityIds.map((id) => this.getValue(id, property) || 0)
        : securityIds.map((id) => this.getValue(id, property)).filter((value) => value !== null);
    const value = values.length > 0 ? this.props.aggregation.aggFunc(values) : null;
    const formattedValue = this.formatValue(value, property);

    return (
      <td key={`aggregation-${this.props.aggregation.label}-col-${name}`}>
        {formattedValue === 'N/A' && this.props.naIndicator ? '-' : formattedValue}
      </td>
    );
  };

  getValue = (securityId, property) => {
    const securitiesStore = this.props.securities;
    const fundamentalDataStore = this.props.fundamentalData;
    const valueObj = getSecurityValueFromStore(securityId, property, securitiesStore, fundamentalDataStore);
    return valueObj ? valueObj.value : null;
  };

  formatValue = (value, property) => {
    return formatSecurityPropertyValue(value, property);
  };

  isLoading = () => {
    const securityId = this.props.securityId;
    const fundamentalDataStore = this.props.fundamentalData;

    return isLoadingFundamentalData(securityId, fundamentalDataStore);
  };
}

const mapStateToProps = (state) => {
  return {
    securities: state.securities,
    fundamentalData: state.fundamentalData,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompTableAggregationRow);
