import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../../../actions/index';

import PageLoading from '../../../../components/PageLoading';

class CompTableBody extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <thead>
        {this.renderColumnGroupHeadings()}
        {this.renderColumnHeadings()}
      </thead>
    );
  }

  renderColumnGroupHeadings = () => {
    const groupData = this.props.columnGroups;
    const groups = groupData.map((group) => ({
      span: group.columns.length,
      name: group.name,
      displayName: group.heading,
    }));
    return (
      <tr>
        {groups.map((group) => (
          <th
            key={group.name}
            colSpan={group.span}
            className={`comp-table-column-group-heading border-accent ${group.displayName ? 'show-heading' : ''}`}
          >
            {`${group.displayName ? group.displayName : ''}`}
          </th>
        ))}
      </tr>
    );
  };

  renderColumnHeadings = () => {
    const groupData = this.props.columnGroups;
    const columnsInOrder = [];
    // add columns from groups
    groupData.forEach((group) => {
      group.columns.forEach((column) => {
        columnsInOrder.push(column);
      });
    });

    return (
      <tr className="main-headings-row border-accent">
        {columnsInOrder.map((column) => (
          <th key={column.name} className={`comp-table-column-heading secondary-text-color`}>
            {`${column.displayName}`}
          </th>
        ))}
      </tr>
    );
  };
}

// const mapStateToProps = (state) => {
//   return {
//     securities: state.securities,
//     fundamentalData: state.fundamentalData,
//     currentUser: state.currentUser,
//   }
// }

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(CompTableBody);
