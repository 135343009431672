import React from 'react';
import styled from 'styled-components';
import { FlatButton } from '../buttons';
import { Gradient1 } from '../../../assets/gradients/Gradient1';
import { Body6, defineFontSettings } from '../../lib/nvstr-common-ui.es';
import { colorPalette } from '../../lib/nvstr-utils.es';
import { TrackingEvents } from '../../../utils/tracking/events';
import { ROUTES } from '../../../constants/paths';
import { useNavigate } from 'react-router-dom';
import { useBankAccounts } from '../../hooks/funding/useBankAccounts';
import { Text } from '../text';

const Wrapper = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  max-width: 600px;
  margin: 0 auto;
`;
const Content = styled.div`
  padding: 16px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  position: relative;
  z-index: 2;
`;
const BgContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;
  z-index: 0;

  svg {
    width: 220%;
  }
`;
const ButtonWrapper = styled.div`
  padding-left: 16px;
`;

export const VerifyBank = ({ onView, onCTAClick, ContainerComponent }) => {
  const navigate = useNavigate();

  const [filter] = React.useState({ isVerificationReady: true });
  const verifyReadyBankAccounts = useBankAccounts(filter);

  const ref = React.useRef();
  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  const textColor = colorPalette.primary.charcoal;

  const [fontSettings] = React.useState(
    defineFontSettings(
      {
        fontSize: '12px',
        lineHeight: 1.2,
      },
      {
        fontSize: '12px',
        lineHeight: 1.2,
      }
    )
  );

  React.useEffect(() => {
    TrackingEvents.funding.VIEW_VERIFY_BANK_BANNER.send();
    onView();
  }, []);

  React.useEffect(
    () => {
      if (ref.current) {
        setHeight(ref.current.parentElement.clientHeight);
        setWidth(ref.current.parentElement.clientWidth);
      }
    },
    [ref]
  );

  const handleClick = () => {
    TrackingEvents.funding.CLICK_VERIFY_BANK_BANNER.send();
    onCTAClick();

    navigate(ROUTES.VERIFY_BANK_ACCOUNT.build());
  };

  if (verifyReadyBankAccounts < 1) {
    return null;
  }

  return (
    <ContainerComponent>
      <Wrapper ref={ref}>
        <BgContainer>
          <Gradient1 height={height} width={width} />
        </BgContainer>
        <Content>
          <div>
            <Body6 bold color={textColor}>
              Bank Verification Ready
            </Body6>
            <div style={{ paddingTop: 4, lineHeight: 1.2 }}>
              <Text fontSettings={fontSettings}>
                Microdeposits were deposited and withdrawn from your bank account. Enter them now to finish linking.
              </Text>
            </div>
          </div>
          <ButtonWrapper>
            <FlatButton color={textColor} textColor={colorPalette.primary.oak} onClick={handleClick}>
              Verify Now
            </FlatButton>
          </ButtonWrapper>
        </Content>
      </Wrapper>
    </ContainerComponent>
  );
};
