export const CUSTOM_LEARN_LESSON_COMPONENT_TYPES = {
  learnDeposit: 'learnDeposit',
  learnRecurringDeposit: 'learnRecurringDeposit',
  learnCommunity: 'learnCommunity',
  learnReferral: 'learnReferral',
};

export const LEARN_LESSON_TYPES = {
  text: 'text',
  custom: 'custom',
};

export const LEARN_CONTENT_TYPES = {
  text: 'text',
  sponsorIntro: 'sponsorIntro',
  sponsorOutro: 'sponsorOutro',
  productPartnerOutro: 'productPartnerOutro',
  ladderQuoteTool: 'ladderQuoteTool',
};

export const LEARN_STATUS_TYPES = {
  noMoreLessons: 'NO_MORE_LESSONS',
  liveAccountAndDepositRequired: 'LIVE_ACCOUNT_AND_DEPOSIT_REQUIRED',
  depositRequired: 'DEPOSIT_REQUIRED',
  onCooldown: 'COME_BACK_TOMORROW',
};

export const EXTRA_CONTENT_TYPES = {
  security: 'security',
};
