import { throwError } from '../helpers/devToolHelpers';

/* ****************************************** */
/******* Thought Tracking Event Names ******* */
/* ****************************************** */

const ThoughtImpressionEventNames = {
  FEED: 'Feed',
  THOUGHT_CAROUSEL_IN_FEED: 'Thought Carousel - Feed',
  THOUGHT_CAROUSEL_IN_AFTER_TRADE_PANEL: 'Thought Carousel - After Trade',
  THOUGHT_CAROUSEL_IN_WATCHLIST_ONBOARDING_PANEL: 'Watchlist Thoughts Panel',
  AFTER_TRADE_THOUGHTS_PANEL: 'Thoughts Panel - After Trade',
  ASK_NOTIFICATION_IN_FEED: 'Thought Ask From Feed',
  SECURITY_PANEL_PRO_CON_TAB: 'Security Panel',
  SECURITY_PANEL_IDEA_TAB: 'Idea Panel',
  CREATE_ASK_ANYONE: 'Ask About Thought Form',
  THOUGHT_PAGE: 'Thought Page',
  EMAIL: 'Email', // no use case outside of server email generation
};

export const getThoughtImpressionEventName = (key) => {
  const value = ThoughtImpressionEventNames[key];
  if (!value) {
    throwError(`Could not find ${key} in ThoughtImpressionEventNames`, true, {
      ThoughtImpressionEventNames,
    });
  }
  return value || 'Unknown';
};

/* ****************************************** */
/*  ****** MISC Tracking Event Names ******* */
/* ****************************************** */

export const ThoughtEventNames = {
  CREATE_THOUGHT_FROM_NO_THOUGHTS_PROMPT: 'Create Thought - Empty Thought List',
  CREATE_THOUGHT_FROM_DASHBOARD_FEED: 'Create Thought In Dashboard Feed',
  CREATE_THOUGHT_ASK_NOTIFICATION_RESPONSE: 'Ask Notification Response',
  CREATE_THOUGHT_MODAL: 'Create Thought Modal',
};
// amplitude event names will go here
