import React from 'react';
import { pickOne } from '@src/helpers/usefulFuncs';

const testGroups = [
  {
    id: 1,
    buildMessageWithLink: (link) => {
      return `Tornado is a modern investing platform backed by A-Rod and Marc Lore. It was designed by ex-hedge fund pros to empower anyone who wants to become a better investor, no matter where you're starting from. 

Right now they're giving people up to $1,000 cash to invest when you join and make a deposit.

Here's my invite link:
${link}

This link could stop working anytime since they're always updating the bonuses. See the site for details. Just let me know once you're signed up!`;
    },
  },
  {
    id: 8,
    buildMessageWithLink: (link) => {
      return `Hey, Check this out! Tornado is a modern investing platform designed by ex-hedge fund pros to empower anyone who wants to become a better investor, no matter where you're starting from. Join with me 👉 ${link}`;
    },
  },
  {
    id: 9,
    buildMessageWithLink: (link) => {
      return `Invest in stocks with me on Tornado and get up to $1,000 to invest with your first deposit 👉 ${link}`;
    },
  },
];

export const useReferralCopy = (shareLink) => {
  const [message, setMessage] = React.useState('');
  const [testGroupId, setTestGroupId] = React.useState(null);

  React.useEffect(() => {
    const testGroup = pickOne(testGroups);
    const { id, buildMessageWithLink } = testGroup;
    const m = buildMessageWithLink(shareLink);
    setMessage(m);
    setTestGroupId(id);
  }, []);

  return {
    message,
    testGroupId,
  };
};
