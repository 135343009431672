export const SOCURE_DECISION_STATUS = {
  FURTHER_DOCUMENTS_REQUIRED: 'further_documents_required',
  ADDRESS_RESUBMIT: 'address_resubmit',
  REJECTED: 'rejected',
};

export const SOCURE_DOCUMENT_TYPE_FLAGS = {
  IDENTITY: 'identity',
  REQUIRES_SSN_CARD: 'rsc',
  REQUIRES_NON_US_CITIZEN_CARD: 'rnucc',
  SELFIE: 'headshot',
};
