import styled, { keyframes } from 'styled-components';
import React from 'react';

const ModalWrapper = styled.div`
  z-index: 99999;
  * {
    z-index: 99999;
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const FixedOverlay = styled.div`
  animation: ${fadeIn} 0.3s ease-out;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background: ${({ theme }) => theme.themeColors.overlay};
  z-index: 99998;
`;

export const FixedBackgroundOverlay = ({ hide, children }) => {
  if (hide) return children;

  return (
    <>
      <ModalWrapper>{children}</ModalWrapper>
      <FixedOverlay />
    </>
  );
};
