import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withWindowSize from '../HOCS/withWindowSize';
import * as Actions from '../../actions/index';
import { OptimizerRunning } from './types/OptimizerRunning';
import { isVerticalNavDisplaying } from '../../helpers/navHelpers';

export class ActionMessageContainer extends Component {
  render() {
    return <div className={`action-message-container`}>{this._renderActionMessages()}</div>;
  }

  _renderActionMessages = () => {
    return (
      <ul>
        {this._returnActionMessagesToDisplay().map((messageData, i) => this._renderActionMessage(messageData, i))}
      </ul>
    );
  };

  _renderActionMessage = (messageData, i) => {
    return (
      <li
        key={`action-message-display-${messageData.type}-${i}`}
        className={`action-message-position-container fade-in-down-quick`}
        style={{
          top: `${this._returnActionMessageTopPosition(i)}px`,
          left: `${this._returnActionMessageLeftPosition(i)}px`,
        }}
      >
        {this._returnActionMessageRenderer()[messageData.type]}
      </li>
    );
  };

  _returnActionMessageRenderer = () => ({});

  _returnActionMessageTopPosition = (i) => {
    const actionMessageHeight = 30;
    const indexValue = i + 1;
    return (
      this._returnTopOffset() +
      (indexValue * actionMessageHeight - actionMessageHeight) +
      indexValue * this._returnPaddingBetweenActionMessages()
    );
  };

  _returnActionMessageLeftPosition = (i) => {
    const containerUsableWidthWithOffset = (this._returnWindowWidth() + this._returnLeftOffset()) / 2;
    // if change width, change in css too
    const actionMessageWidth = 305;
    return containerUsableWidthWithOffset - actionMessageWidth / 2;
  };

  _returnActionMessagesToDisplay = () => this._returnActionMessageStore().displayingMessagesList;
  _returnActionMessagesToDisplayCount = () => this._returnActionMessagesToDisplay().length;

  _areMessagesLongerThanWindow = () =>
    this._returnWindowHeight() <=
    this._returnTopOffset() +
      this._returnActionMessagesToDisplayCount() * (35 + this._returnPaddingBetweenActionMessages());

  _returnTopOffset = () => (false ? 100 : 50);
  _returnLeftOffset = () => (this._isVerticalNavOpen() ? 184 : 0);
  _returnPaddingBetweenActionMessages = () => this.props.paddingBetween || 15;

  _isVerticalNavOpen = () => isVerticalNavDisplaying(this._returnNavStore());

  _returnActionMessageStore = () => this.props.actionMessage;
  _returnBannerStore = () => this.props.banner;
  _returnNavStore = () => this.props.nav;

  _returnWindowHeight = () => this.props.windowHeight;
  _returnWindowWidth = () => this.props.windowWidth;
}

const mapStateToProps = (state) => {
  return {
    actionMessage: state.actionMessage,
    banner: state.banner,
    nav: state.nav,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(withWindowSize, connect(mapStateToProps, mapDispatchToProps))(ActionMessageContainer);

export default composedComponent;
