import React from 'react';

class Input extends React.Component {
  render() {
    const {
      name,
      value,
      type,
      placeholder,

      autoFocus,

      handleChange,
      handleFocus,
      handleBlur,
    } = this.props;

    return (
      <div className={`reusable-search-input-container`}>
        <fieldset>
          <input
            name={name}
            type={type || 'text'}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            autoFocus={autoFocus}
          />
        </fieldset>
      </div>
    );
  }
}

export default Input;
