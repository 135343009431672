import React, { Component } from 'react';
import { logMetricsTrackingEvent } from '../../actions/trackingActions';

class ExpandableTextField extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    this._validateProps();
  }

  render() {
    return (
      <div className={`expand-text-field-container ${this._returnAdditionalClasses()}`}>{this._renderComponent()}</div>
    );
  }

  _renderComponent = () => {
    return this._isTextFieldOpen() ? this._renderExpandedField() : this._renderCollapsedField();
  };

  _returnAdditionalClasses = () => {
    return this._isTextFieldOpen() ? 'expanded' : '';
  };

  _renderOpenLink = () => {
    return (
      <div className={`action-btn-container`}>
        <span onClick={this._handleOpen} className={`${this.props.linkClassName || 'link-btn'} open-text-field-button`}>
          {this._returnOpenLinkText()}
        </span>
      </div>
    );
  };

  _renderCloseLink = () => {
    return (
      <div className={`action-btn-container`}>
        <span onClick={this._handleClose} className={`link-btn link-btn-low-contrast collapse-text-field-button`}>
          Close
        </span>
      </div>
    );
  };

  _renderCollapsedField = () => {
    return this._renderOpenLink();
  };

  _renderExpandedField = () => {
    return (
      <div className={``}>
        {this._renderText()}
        {this._renderCloseLink()}
      </div>
    );
  };

  _handleOpen = () => {
    this._logOpenTextField();
    this._openTextField();
  };

  _handleClose = () => {
    this._closeTextField();
  };

  _isTextFieldOpen = () => this.state.isOpen;

  _isTextAString = () => this._returnText() && typeof this._returnText() === 'string';

  _renderText = () => {
    return this._isTextAString() ? (
      <p className="eye-catching-standalone-text-color">{this._returnText()}</p>
    ) : (
      this._returnText()
    );
  };

  _returnOpenLinkText = () => this.props.openLinkText;

  _returnText = () => this.props.text;

  _returnLabel = () => this.props.label;

  _openTextField = () => {
    this.setState(() => ({
      isOpen: true,
    }));
  };

  _closeTextField = () => {
    this.setState(() => ({
      isOpen: false,
    }));
  };

  _logOpenTextField = () => {
    const event = `Opened ${this._returnLabel()} Text Field`;
    const properties = {};
    logMetricsTrackingEvent(event, properties)();
  };

  _validateProps = () => {
    if (!this._returnLabel()) {
      console.error('ExpandableTextField requires a label prop to record tracking');
    }
    if (!this._returnText()) {
      console.error('ExpandableTextField requires a text prop to render');
    }
    if (!this._returnOpenLinkText()) {
      console.error('ExpandableTextField requires a openLinkText prop to render');
    }
  };
}

export default ExpandableTextField;
