import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import { returnCurrentUserId } from '../../helpers/currentUserHelpers';
import { consoleError, throwError } from '../../helpers/devToolHelpers';
import {
  getViewpointIdFromThought,
  isThoughtFromCommunityThoughtLeader,
  isThoughtFromViewpointAuthor,
} from '../../helpers/thoughtsHelpers';

/*
  <ThoughtsDataContainer>
    {children}
  </ThoughtsDataContainer>

  Required Props for <ThoughtsDataContainer/>
    securityId or security -> security is preferable for components requiring display of security symbols as well as determining if featured
      or
    thoughtId -> thoughtId will put the component into a single thought mode

  -----------------------------------------------

  Optional Props
    userId -> will filter and only return thoughts in which an agree or disagree is present with that userId

    generally used together:
      idea -> obj -> used for logging and custom messaging when displayed in context of UserIdeas
      showAsThoughtsInIdea -> bool -> used for logging
 */

class ThoughtsDataContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proThoughtsOrderedList: [],
      conThoughtsOrderedList: [],
    };
  }

  componentDidMount() {
    if (this.props.getThoughtData) {
      this._getThoughtData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this._didThoughtCountChange(prevProps)) {
      this._onThoughtCountChange(prevProps);
    }

    if (this._returnSortOption(prevProps) !== this._returnSortOption()) {
      this._handleSortChange();
    }

    if (this._didThoughtFiltersChange(prevProps)) {
      this._handleSortChange();
    }
  }

  render() {
    return <div className={'thoughts-data-container'}>{this._renderChildrenWithThoughtDataProps()}</div>;
  }

  _renderChildrenWithThoughtDataProps = () => {
    return React.Children.map(this.props.children, (child) =>
      React.cloneElement(
        child,
        this._isUsingSingleThoughtData()
          ? this.returnSingleThoughtDataChildrenProps()
          : this.isLoading()
          ? this.returnLoadingThoughtDataChildrenProps()
          : this.returnThoughtDataChildrenProps()
      )
    );
  };

  _isUsingSingleThoughtData = () => this.props.thoughtId;

  returnLoadingThoughtDataChildrenProps = () => ({
    ...this.props,
    thoughtsStore: this._returnThoughtsStore(),
    security: this._returnSecurity(),
    securityId: this._returnSecurityId(),
    isLoading: this.isLoading(),
  });

  returnSingleThoughtDataChildrenProps = () => ({
    ...this.props,

    isLoading: this.isLoading(),
    thought: this.returnSingleThoughtData(),
    thoughtId: this._returnThoughtId(),

    security: this._returnSecurity(),
    securityId: this._returnSecurityId(),

    thoughtsStore: this._returnThoughtsStore(),
  });

  returnThoughtDataChildrenProps = () => ({
    ...this.props,

    thoughtsStore: this._returnThoughtsStore(),
    security: this._returnSecurity(),
    securityId: this._returnSecurityId(),
    isLoading: this.isLoading(),
    isThoughtDataLoading: this._isThoughtDataLoading(),
    isThoughtDataAvailable: this._isThoughtDataAvailable(),

    areNoThoughtsForSecurity: this._areNoThoughtsForSecurity(),
    areProThoughts: this._areProThoughts(),
    areConThoughts: this._areConThoughts(),

    areNoCurrentUserThoughtsForSecurity: this._areNoCurrentUserThoughtsForSecurity(),
    areCurrentUserProThoughts: this._areCurrentUserProThoughts(),
    areCurrentUserConThoughts: this._areCurrentUserConThoughts(),

    currentUserPros: this._returnSortedCurrentUserPros(),
    currentUserCons: this._returnSortedCurrentUserCons(),

    allThoughtsForSecurity: this._returnAllThoughtsForSecurity(),

    pros: this._returnProThoughts(),
    cons: this._returnConThoughts(),

    sortedPros: this._returnSortedProThoughts(),
    sortedCons: this._returnSortedConThoughts(),
  });

  _handleSortChange = () => {
    this._sortAndSaveThoughts();
  };

  isLoading = (props) => !this._isThoughtDataAvailable(props) || this._isThoughtDataLoading(props);

  _getThoughtData = () => {
    this.props.actions.fetchThoughts([this._returnSecurityId()]).then((response) => {
      if (response && response.data && response.data.thoughts) {
        this._onThoughtLoad();
      }
    });
  };

  _returnSecurity = () => this.props.security;

  _returnSecurityId = () => this._returnSecurity().security_id || this.props.securityId;

  _returnIdea = () => this.props.idea;

  _shouldShowAsThoughtsInIdea = () => this.props.showAsThoughtsInIdea;

  _returnCurrentUserConnectionUserIds = (props) => {
    const userId = this._returnCurrentUserId();
    const connectionStore = this._returnUserConnectionStore(props);
    if (!(userId in connectionStore)) {
      consoleError('User connections data not found in connection store', {
        connectionStore,
      });
    }
    return connectionStore[userId].connections || [];
  };
  _returnUserConnectionStore = (props) => (props || this.props).userConnections.userDict;

  _returnCurrentUser = () => this.props.currentUser;

  _returnCurrentUserId = () => returnCurrentUserId(this._returnCurrentUser());

  _isUserAuthed = () => 'user_id' in this._returnCurrentUser();

  _returnThoughtsStore = (props) => (props || this.props).thoughts;

  _returnThoughtsSecurityLookup = (props) => this._returnThoughtsStore(props).securityLookup;

  _returnThoughtSecurityData = (props) => this._returnThoughtsSecurityLookup(props)[this._returnSecurityId()] || {};

  _returnSecurityThoughtLookup = (props) => this._returnThoughtSecurityData(props).thoughtLookup || null;

  _isThoughtDataLoading = (props) => this._returnThoughtSecurityData(props).loading;

  _isThoughtDataAvailable = (props) => !isUndefinedOrNull(this._returnSecurityThoughtLookup(props));

  _isAConnectionThoughtOrOwnThought = (thought) => {
    const opinionUserIds = [...thought.agree_user_ids, ...thought.disagree_user_ids];
    return opinionUserIds.some((id) =>
      [this._returnCurrentUserId(), ...this._returnCurrentUserConnectionUserIds()].includes(id)
    );
  };

  _isUsersThought = (thought, userId) => {
    const opinionUserIds = [...thought.agree_user_ids, ...thought.disagree_user_ids];
    return opinionUserIds.some((id) => userId === id);
  };

  _filterNonConnectionThoughts = (filterData, thoughts) =>
    thoughts.filter((thought) => this._isAConnectionThoughtOrOwnThought(thought));

  _filterOnlyShowUserThoughts = (filterData, thoughts) => {
    const { viewpointOrgId, userId, id } = filterData;

    if (id === 'ctl') {
      return thoughts.filter((thought) => isThoughtFromCommunityThoughtLeader(thought));
    } else if (id === 'viewpoints') {
      return thoughts.filter((thought) => isThoughtFromViewpointAuthor(thought));
    } else if (!isUndefinedOrNull(viewpointOrgId)) {
      return thoughts.filter((thought) => getViewpointIdFromThought(thought) === viewpointOrgId);
    } else {
      return thoughts.filter((thought) => this._isUsersThought(thought, userId));
    }
  };

  _convertThoughtFilterToFilterFunc = (thoughtFilter) => {
    const dict = {
      include_community_thoughts_filter: this._filterNonConnectionThoughts,
      thoughts_from_filter_user_id: this._filterOnlyShowUserThoughts,
    };
    return (
      dict[thoughtFilter.name] || console.error(`Thought filter: ${thoughtFilter.name}, does not exist`, thoughtFilter)
    );
  };

  _didThoughtFiltersChange = (props) => {
    if (!this._returnThoughtFilters(props) || !this._returnThoughtFilters()) {
      return false;
    }
    if (this._returnThoughtFilters(props).length !== this._returnThoughtFilters().length) {
      return true;
    }

    // JSON stringify not performant, refactor needed, but should have nominal impact as it only fires if the lengths are not equal
    if (
      this._returnThoughtFilters(props).length > 0 &&
      this._returnThoughtFilters(props).every(
        (filterData, i) => JSON.stringify(filterData) !== JSON.stringify(this._returnThoughtFilters()[i])
      )
    ) {
      return true;
    }
    if (
      this._returnThoughtFilters().length > 0 &&
      this._returnThoughtFilters().every(
        (filterData, i) => JSON.stringify(filterData) !== JSON.stringify(this._returnThoughtFilters(props)[i])
      )
    ) {
      return true;
    }

    return false;
  };

  _returnThoughtFilters = (props) => (props || this.props).filterBy;

  _applyThoughtFilters = (thoughts) => {
    const filterList = this._returnThoughtFilters();
    if (!filterList) {
      return thoughts;
    }

    let filteredThoughts = thoughts;
    filterList.forEach((filter) => {
      const applyThoughtFilterFunc = this._convertThoughtFilterToFilterFunc(filter);
      filteredThoughts = applyThoughtFilterFunc(filter.data, thoughts);
    });
    return filteredThoughts;
  };

  _returnSortOption = (props) => (props || this.props).sortBy || this._returnDefaultSortOption();

  _returnDefaultSortOption = () => 'currentUserNewThoughtsThenBestAgreeDisagreeDifferential';

  _returnSortedProThoughts = () => this.state.proThoughtsOrderedList;

  _returnSortedConThoughts = () => this.state.conThoughtsOrderedList;

  returnSingleThoughtData = () => {
    return this._returnAllThoughtsForSecurity().filter((thought) => thought.id === this._returnThoughtId())[0] || null;
  };

  _returnThoughtId = () => this.props.thoughtId || throwError('No thoughtId was supplied', true, { props: this.props });

  _returnAllThoughtsForSecurity = (props) => {
    const thoughtsLookup = this._returnSecurityThoughtLookup(props);
    if (!thoughtsLookup) {
      if (!(props || this.props).getThoughtData) {
        consoleError(`No thought data present for security: ${this._returnSecurityId()}`);
      }
      return [];
    }
    const thoughtIds = Object.keys(thoughtsLookup);
    const thoughts = thoughtIds.map((id) => thoughtsLookup[id]);
    return thoughts;
  };

  _filterThoughtsByUser = (userId, thoughts) =>
    thoughts.filter((t) => t.agree_user_ids.includes(userId) || t.disagree_user_ids.includes(userId));

  _returnThoughtsForSecurity = (props) =>
    this._shouldShowUserThoughtsOnly()
      ? this._filterThoughtsByUser(this._returnFilterThoughtsByUserId(), this._returnAllThoughtsForSecurity(props))
      : this._returnAllThoughtsForSecurity(props);

  _filterThoughtsForPros = (thoughts) => thoughts.filter((thought) => thought.thought_type.id === 0);

  _filterThoughtsForCons = (thoughts) => thoughts.filter((thought) => thought.thought_type.id === 1);

  _returnProThoughts = (props) => this._filterThoughtsForPros(this._returnThoughtsForSecurity(props));

  _returnConThoughts = (props) => this._filterThoughtsForCons(this._returnThoughtsForSecurity(props));

  _returnCurrentUserPros = () => this._filterThoughtsByUser(this._returnCurrentUserId(), this._returnProThoughts());

  _returnCurrentUserCons = () => this._filterThoughtsByUser(this._returnCurrentUserId(), this._returnConThoughts());

  _returnCountForProThoughts = (props) => this._returnProThoughts(props).length;

  _returnCountForConThoughts = (props) => this._returnConThoughts(props).length;

  _returnCountForCurrentUserProThoughts = () => this._returnCurrentUserPros().length;

  _returnCountForCurrentUserConThoughts = () => this._returnCurrentUserCons().length;

  _areThoughtsFor = (thoughtType) => (thoughtType === 'pro' ? this._areProThoughts() : this._areConThoughts());

  _areProThoughts = () => this._returnCountForProThoughts() > 0;

  _areConThoughts = () => this._returnCountForConThoughts() > 0;

  _areCurrentUserProThoughts = () => this._returnCountForCurrentUserProThoughts() > 0;

  _areCurrentUserConThoughts = () => this._returnCountForCurrentUserConThoughts() > 0;

  _areNoThoughtsForSecurity = () => !this._areProThoughts() && !this._areConThoughts();

  _areNoCurrentUserThoughtsForSecurity = () => !this._areCurrentUserProThoughts() && !this._areCurrentUserConThoughts();

  _returnFilterThoughtsByUserId = () => this.props.userId;

  _shouldShowUserThoughtsOnly = () => !!this._returnFilterThoughtsByUserId();

  _returnDefaultSortedThoughtsFor = (thoughtType) =>
    thoughtType === 'pro' ? this._returnDefaultSortedProThoughts() : this._returnDefaultSortedConThoughts();

  _returnDefaultSortedProThoughts = () =>
    this._sortThoughtsBy('currentUserNewThoughtsThenBestAgreeDisagreeDifferential', this._returnProThoughts());

  _returnDefaultSortedConThoughts = () =>
    this._sortThoughtsBy('currentUserNewThoughtsThenBestAgreeDisagreeDifferential', this._returnConThoughts());

  _returnSortedCurrentUserPros = () =>
    this._sortThoughtsBy('currentUserNewThoughtsThenBestAgreeDisagreeDifferential', this._returnCurrentUserPros());

  _returnSortedCurrentUserCons = () =>
    this._sortThoughtsBy('currentUserNewThoughtsThenBestAgreeDisagreeDifferential', this._returnCurrentUserCons());

  _filterAndSortThoughtsForNewCurrentUserPosts = (thoughts) => {
    const thoughtsFilteredForCurrentUserNewThoughts = thoughts.filter((thought) => thought.showAtTop);
    const sortedThoughtsFilteredForCurrentUserNewThoughtsNewestFirst = thoughtsFilteredForCurrentUserNewThoughts.sort(
      (a, b) => {
        if (a.id > b.id) return -1;
        if (a.id < b.id) return 1;
        return 0;
      }
    );
    return sortedThoughtsFilteredForCurrentUserNewThoughtsNewestFirst;
  };

  _filterAndSortThoughtsForNonNewCurrentUserPosts = (thoughts) => {
    const nonNewCurrentUserPosts = thoughts.filter((thought) => !thought.showAtTop);
    const sortByBestDifferentialThoughts = this._sortThoughtsForBestAgreeDisagreeDifferential(nonNewCurrentUserPosts);
    return sortByBestDifferentialThoughts;
  };

  _sortThoughtsForBestAgreeDisagreeDifferential = (thoughts) => {
    return thoughts.sort((a, b) => {
      const aAgrees = a.agree_count;
      const aDisagrees = a.disagree_count;
      const aDifferential = aAgrees - aDisagrees;

      const bAgrees = b.agree_count;
      const bDisagrees = b.disagree_count;
      const bDifferential = bAgrees - bDisagrees;

      if (aDifferential > bDifferential) return -1;
      if (aDifferential < bDifferential) return 1;
      if (aDifferential === bDifferential && aAgrees > bAgrees) return -1;
      if (aDifferential === bDifferential && aAgrees < bAgrees) return 1;
      return 0;
    });
  };

  _sortThoughtsForMostRecent = (thoughts) => thoughts.sort((a, b) => b.posted_at_timestamp - a.posted_at_timestamp);

  _sortThoughtsBy = (sortType, thoughts) => {
    const sortOptions = [
      'currentUserNewThoughtsThenBestAgreeDisagreeDifferential',
      'bestAgreeDisagreeDifferential',
      'mostRecent',
    ];
    if (!sortOptions.includes(sortType)) {
      return console.error(`${sortType} is not a valid sort option`);
    }

    if (sortType === 'currentUserNewThoughtsThenBestAgreeDisagreeDifferential') {
      const currentUserNewPostsSorted = this._filterAndSortThoughtsForNewCurrentUserPosts(thoughts);
      const nonCurrentUserNewPostsSorted = this._filterAndSortThoughtsForNonNewCurrentUserPosts(thoughts);
      return [...currentUserNewPostsSorted, ...nonCurrentUserNewPostsSorted];
    } else if (sortType === 'bestAgreeDisagreeDifferential') {
      return this._sortThoughtsForBestAgreeDisagreeDifferential(thoughts);
    } else if (sortType === 'mostRecent') {
      return this._sortThoughtsForMostRecent(thoughts);
    } else {
      return thoughts;
    }
  };

  _didThoughtDataLoad = (prevProps) => {
    const wasFirstTimeLoadComplete = !this._isThoughtDataAvailable(prevProps) && this._isThoughtDataAvailable();
    const wasLoadingEventFinished = this._isThoughtDataLoading(prevProps) && !this._isThoughtDataLoading();
    return wasFirstTimeLoadComplete || wasLoadingEventFinished;
  };

  _didThoughtCountChange = (prevProps) => {
    if (!this._isThoughtDataAvailable(prevProps) || !this._isThoughtDataAvailable()) {
      return false;
    }
    const prevProCount = this._returnCountForProThoughts(prevProps);
    const prevConCount = this._returnCountForConThoughts(prevProps);

    const currentProCount = this._returnCountForProThoughts();
    const currentConCount = this._returnCountForConThoughts();

    return prevProCount + prevConCount !== currentProCount + currentConCount;
  };

  // fires only if this component gets its own thought data
  _onThoughtLoad = () => {
    this._sortAndSaveThoughts();
  };

  _onThoughtCountChange = (prevProps) => {
    this._sortAndSaveThoughts();
  };

  _saveAllThoughtsListOrder = () => {
    const proThoughtsOrderedList = this._applyThoughtFilters(
      this._sortThoughtsBy(this._returnSortOption(), this._returnProThoughts())
    );
    const conThoughtsOrderedList = this._applyThoughtFilters(
      this._sortThoughtsBy(this._returnSortOption(), this._returnConThoughts())
    );
    this.setState(() => ({
      proThoughtsOrderedList,
      conThoughtsOrderedList,
    }));
  };

  _sortAndSaveThoughts = () => {
    this._saveAllThoughtsListOrder();
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    userConnections: state.userConnections,
    thoughts: state.thoughts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThoughtsDataContainer);
