import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { logMetricsTrackingEvent, submitLiveTradingSignUpForm } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import {
  Body5,
  createDropdownItem,
  FloatingLabelDropdown,
  FloatingLabelTextInput,
  Lock,
  H5,
} from '../../lib/nvstr-common-ui.es';
import { FlatButton, SkeletonButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { useLiveAccount } from '../../hooks/user';
import { useFormik } from 'formik';
import { ContactAddressSchema } from '../../constants/formValidationSchemas';
import { listUsStateNames } from '../../../helpers/formHelpers';
import AddressUtil from '../../../classes/AddressUtil';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const InputsWrapper = styled.div`
  input {
    border: none;
  }
  select {
    border-top: none;
    border-left: none;
    border-right: none;
    -webkit-appearance: auto;
  }
  input,
  select {
    background: transparent !important;
  }
`;
const InputWrapper = styled.div`
  padding-top: 16px;
  margin-right: 8px;

  input {
    border-bottom: none !important;
  }
  select {
    height: 44px;
    margin-top: 2px;
  }
`;
const InlineInputs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  input {
    margin-right: 8px;
  }
  input:last-child {
    margin-right: 0;
  }
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 8px;
`;
const PrivacyMessage = styled.div`
  padding-top: 24px;
`;
const IconMessage = styled.div`
  padding-top: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  text-align: left;

  svg {
    height: 24px;
    width: 24px;

    fill: ${({ theme }) => theme.themeColors.text};

    margin-right: 16px;
  }
`;
const Actions = styled.div`
  padding-top: 40px;
  text-align: center;
`;
const AddressSuggestionWrapper = styled.div`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.themeColors.text};
  margin-top: 16px;
  margin-right: 8px;
`;
const AddressSuggestionItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid ${({ theme }) => theme.themeColors.text};
  padding: 8px 16px;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: ${({ theme }) => theme.themeColors.primaryCtaButton};
    cursor: pointer;
    * {
      color: ${({ theme }) => theme.themeColors.component};
    }
  }
`;
const VerificationAddressSuggestionItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 8px 16px;

  &:last-child {
    border-bottom: none;
  }
`;

const stateDropdownItems = [
  createDropdownItem({ text: '--', value: '', disabled: true }),
  ...listUsStateNames().map((s) => createDropdownItem({ text: s, value: s })),
];

class AddressHelpers {
  massageAddressSuggestionData = (suggestion) => {
    if ('streetLine' in suggestion) {
      const { streetLine: street, secondary: street2, city, state, zipcode } = suggestion;
      return {
        street,
        street2,
        city,
        state,
        zipcode,
      };
    }

    if ('contact_street_address' in suggestion) {
      const {
        contact_street_address: street,
        contact_apartment: street2,
        contact_city: city,
        contact_state: state,
        contact_postal_code: zipcode,
      } = suggestion;
      return {
        street,
        street2,
        city,
        state,
        zipcode,
      };
    }

    const { street, street2, city, state, zipcode } = suggestion;
    return {
      street,
      street2,
      city,
      state,
      zipcode,
    };
  };

  convertSuggestionToFormValues = (suggestion) => {
    const {
      street: contact_street_address,
      street2: contact_apartment,
      city: contact_city,
      state: contact_state,
      zipcode: contact_postal_code,
    } = addressHelpers.massageAddressSuggestionData(suggestion);
    return {
      contact_street_address,
      contact_apartment,
      contact_city,
      contact_state,
      contact_postal_code,
    };
  };

  massageFormData = (v) => {
    return {
      ...v,
      contact_country: 'United States of America',
    };
  };
}

const getInitialValuesFrom = (liveAccount) => {
  const {
    contact_street_address,
    contact_apartment,
    contact_city,
    contact_state,
    contact_postal_code,
    // contact_country,
  } = liveAccount;

  return {
    contact_street_address: contact_street_address || '',
    contact_apartment: contact_apartment || '',
    contact_city: contact_city || '',
    contact_state: contact_state || '',
    contact_postal_code: contact_postal_code || '',

    contact_country: 'United States of America',
  };
};

const defaultBlankInitialValues = {
  contact_street_address: '',
  contact_apartment: '',
  contact_city: '',
  contact_state: '',
  contact_postal_code: '',

  contact_country: 'United States of America',
};

const addressHelpers = new AddressHelpers();

export const VerificationAddressSuggestion = ({ suggestion }) => {
  const addressData = addressHelpers.massageAddressSuggestionData(suggestion);
  const util = new AddressUtil({ addressData });

  return (
    <VerificationAddressSuggestionItemWrapper>
      <Body5>{util.formatAddressToTextString()}</Body5>
    </VerificationAddressSuggestionItemWrapper>
  );
};

export const AddressSuggestion = ({ suggestion, onSelection }) => {
  const addressData = addressHelpers.massageAddressSuggestionData(suggestion);
  const util = new AddressUtil({ addressData });

  const handleClick = () => {
    const { streetLine: street, secondary: street2, city, state, zipcode } = suggestion;
    onSelection({
      contact_street_address: street,
      contact_apartment: street2,
      contact_city: city,
      contact_state: state,
      contact_postal_code: zipcode,
    });
  };

  return (
    <AddressSuggestionItemWrapper onClick={handleClick}>
      <Body5>{util.formatAddressToTextString()}</Body5>
      <Body5>SELECT</Body5>
    </AddressSuggestionItemWrapper>
  );
};

export const AddressSuggestionList = ({ list, onEnterAddressManually, onAddressSuggestionSelection, hide }) => {
  const handleEnterManuallyClick = () => {
    onEnterAddressManually();
  };

  if (hide) return null;

  if (list.length === 0) {
    return (
      <AddressSuggestionWrapper>
        <div style={{ textAlign: 'center', padding: '16px' }}>
          <Body5>No addresses match the entered street address</Body5>
        </div>
        <div style={{ textAlign: 'center', padding: ' 0 16px 16px 16px' }}>
          <SkeletonButton onClick={handleEnterManuallyClick}>Enter Address Manually</SkeletonButton>
        </div>
      </AddressSuggestionWrapper>
    );
  }
  return (
    <>
      <AddressSuggestionWrapper>
        {list.map((item, i) => (
          <AddressSuggestion key={i} suggestion={item} onSelection={onAddressSuggestionSelection} />
        ))}
      </AddressSuggestionWrapper>
      <div style={{ textAlign: 'center', padding: '16px 16px 16px 16px' }}>
        <SkeletonButton onClick={handleEnterManuallyClick}>Enter Address Manually</SkeletonButton>
      </div>
    </>
  );
};

let _activeAutocompleteQuery = null;

const _buildAddressAutoCompleteQueryFromForm = (values) => {
  const {
    contact_street_address: street,
    contact_apartment: street2,
    contact_city: city,
    contact_state: state,
    contact_postal_code: zipcode,
  } = values;
  const addressData = {
    street,
    street2,
    city,
    state,
    zipcode,
  };
  const util = new AddressUtil({ addressData });
  return util.formatAddressToTextString();
};

const mapFormToAddressUtilData = (values) => {
  const {
    contact_street_address: street,
    contact_apartment: street2,
    contact_city: city,
    contact_state: state,
    contact_postal_code: zipcode,
  } = values;
  return {
    street,
    street2,
    city,
    state,
    zipcode,
  };
};

const getAddressDataFromForm = (formData) => {
  const {
    contact_street_address: street,
    contact_apartment: street2,
    contact_city: city,
    contact_state: state,
    contact_postal_code: zipcode,
  } = formData;
  return {
    street,
    street2,
    city,
    state,
    zipcode,
  };
};

const validateAddressEntered = (values) => {
  const addressUtil = new AddressUtil({
    mappedAddressData: getAddressDataFromForm(values),
  });
  return addressUtil.verifyAddress();
};

export const EnterContactAddress = ({ isResubmit, onContinue, onboardingState }) => {
  const dispatch = useDispatch();
  const liveAccount = useLiveAccount();

  const [isReady, setIsReady] = React.useState(true);
  const [FORM_PAGE_NAME] = React.useState(isResubmit ? 'address_resubmit' : 'contact');

  const useInitialValues = !isResubmit;
  const initialValues = useInitialValues ? getInitialValuesFrom(liveAccount) : defaultBlankInitialValues;
  const hasInitialValues = initialValues.contact_street_address !== '';

  const [showAddressConfirmation, setShowAddressConfirmation] = React.useState(false);
  const [addressVerificationSuggestions, setAddressVerificationSuggestions] = React.useState(null);

  const [showAddressSuggestions, setShowAddressSuggestions] = React.useState(!hasInitialValues);
  const [addressSuggestions, setAddressSuggestions] = React.useState([]);
  const [showUsingOwnAddressWarning, setShowUsingOwnAddressWarning] = React.useState(false);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const form = useFormik({
    initialValues,
    validationSchema: ContactAddressSchema,
    enableReinitialize: false,
  });
  const { values, errors, setFieldValue, validateForm } = form;

  const onAddressSuggestionSelection = React.useCallback(
    (selection) => {
      const { contact_street_address, contact_apartment, contact_city, contact_state, contact_postal_code } = selection;

      setFieldValue('contact_street_address', contact_street_address, false);
      setFieldValue('contact_apartment', contact_apartment, false);
      setFieldValue('contact_city', contact_city, false);
      setFieldValue('contact_state', contact_state, false);
      setFieldValue('contact_postal_code', contact_postal_code, false);

      setShowAddressSuggestions(false);
    },
    [setFieldValue]
  );

  const handleChange = React.useCallback(
    (name) => (value) => {
      setFieldValue(name, value, false);
    },
    [setFieldValue]
  );

  const submitForm = React.useCallback((values) => {
    const apiFormData = addressHelpers.massageFormData(values);
    setIsSubmitting(true);
    return submitLiveTradingSignUpForm(apiFormData, FORM_PAGE_NAME)(dispatch);
  }, []);

  const handleSubmit = React.useCallback(
    async (overrideValues) => {
      const event = 'Submitted Contact Address Form';
      logMetricsTrackingEvent(event)();

      const validation = await validateForm();
      if (Object.keys(validation).length === 0) {
        const { status } = await submitForm(overrideValues || values);
        if (status === 200) {
          return {
            ok: true,
          };
        } else {
          setFormError('Something went wrong, please try again');
          setIsSubmitting(false);
          return {
            ok: false,
          };
        }
      } else {
        logMetricsTrackingEvent('Failed Live Account Validations', {
          Fields: Object.entries(validation),
          'Form Name': 'address',
        })();
        // could log errors but no form validations on this form
        setIsSubmitting(false);
        return {
          errors: true,
        };
      }
    },
    [values]
  );

  const handleAddressEntryContinueClick = React.useCallback(async () => {
    const validation = await validateForm();
    if (Object.keys(validation).length > 0) {
      return {
        errors: true,
      };
    }

    const { isValid, suggestions, givenAddress, verifiedAddress, wasGivenAddressMatchedExact } =
      await validateAddressEntered(values);
    if (isValid || suggestions === null) {
      setIsSubmitting(true);
      const { ok } = await handleSubmit();
      if (ok) {
        onContinue(null, onboardingState);
      } else {
        setIsSubmitting(false);
      }
    } else {
      if (suggestions.length > 0) {
        setAddressVerificationSuggestions(suggestions);
        setShowAddressConfirmation(true);
      } else {
        setShowUsingOwnAddressWarning(true);
      }
    }
  }, [values, handleSubmit]);

  const handleEditAddressClick = React.useCallback(async () => {
    setShowAddressConfirmation(false);
    setShowUsingOwnAddressWarning(false);
  }, []);

  const handleConfirmedUseMyEnteredAddressClick = React.useCallback(async () => {
    setIsSubmitting(true);
    const { ok } = await handleSubmit(values);
    if (ok) {
      logMetricsTrackingEvent('Submitted Contact Form')();
      onContinue(null, onboardingState);
    } else {
      setIsSubmitting(false);
    }
  }, [values, handleSubmit, onContinue]);

  const handleUseMyEnteredAddressClick = React.useCallback(async () => {
    setShowUsingOwnAddressWarning(true);
  }, [handleSubmit]);

  const handleConfirmationContinueClick = React.useCallback(async () => {
    setIsSubmitting(true);

    const address = addressVerificationSuggestions[0];
    const verifiedAddressValues = addressHelpers.convertSuggestionToFormValues(address);
    const { ok } = await handleSubmit(verifiedAddressValues);
    if (ok) {
      onContinue(null, onboardingState);
    } else {
      setIsSubmitting(false);
    }
  }, [addressVerificationSuggestions, handleSubmit, onContinue]);

  const onEnterAddressManually = React.useCallback(() => {
    setShowAddressSuggestions(false);
  }, [setShowAddressSuggestions]);

  React.useEffect(() => {
    logMetricsTrackingEvent('View Contact Address Form')();
  }, []);

  const fetchAddressSuggestions = React.useCallback(
    async (values) => {
      const query = _buildAddressAutoCompleteQueryFromForm(values);
      _activeAutocompleteQuery = query;

      const addressUtil = new AddressUtil({ addressData: mapFormToAddressUtilData(values) });
      const suggestions = await addressUtil.getAutocompleteSuggestions();
      if (suggestions === null) {
        setAddressSuggestions([]);
        return;
      }

      const { streetLine: street, secondary: street2, city, state, zipcode } = suggestions[0];
      const addressData = {
        street,
        street2,
        city,
        state,
        zipcode,
      };
      const util = new AddressUtil({ addressData });
      const firstSuggestionQuery = util.formatAddressToTextString();
      const isSuggestionTheSameAsQuery = query !== firstSuggestionQuery; // don't offer a suggestion that is same as the inputs

      if (query === _activeAutocompleteQuery && isSuggestionTheSameAsQuery) {
        setAddressSuggestions(suggestions);
      }
    },
    [setAddressSuggestions]
  );

  React.useEffect(() => {
    if (showAddressSuggestions) fetchAddressSuggestions(values);
  }, [values, fetchAddressSuggestions, showAddressSuggestions]);

  if (!isReady) return <LoadingPage />;

  if (showUsingOwnAddressWarning) {
    return (
      <LocationContext.Provider value={TRACKING_ROUTE_NAMES.enterContactAddress}>
        <Page width={'600px'}>
          <ContentWrapper>
            <Heading>
              <H5>Confirm Address</H5>
            </Heading>

            <Message>
              <Body5>
                You can use the original address entered, but this may cause delays in processing your application.
              </Body5>
            </Message>

            <div style={{ paddingTop: '24px' }}>
              <VerificationAddressSuggestion suggestion={values} />
            </div>

            <Actions>
              <FormState error={formError} isSubmitting={isSubmitting} />
              <Container fullWidth centerAll>
                <FlatButton transparent isDisabled={isSubmitting} fullWidth onClick={handleEditAddressClick}>
                  Edit Address
                </FlatButton>
              </Container>
              <Container top={8} fullWidth centerAll>
                <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleConfirmedUseMyEnteredAddressClick}>
                  Yes, Use Address
                </FlatButton>
              </Container>
            </Actions>
          </ContentWrapper>
        </Page>
      </LocationContext.Provider>
    );
  }

  if (showAddressConfirmation) {
    return (
      <LocationContext.Provider value={TRACKING_ROUTE_NAMES.enterContactAddress}>
        <Page width={'600px'}>
          <ContentWrapper>
            <Heading>
              <H5>Confirm Address</H5>
            </Heading>

            <Message>
              <Body5>
                We were unable to automatically verify the address entered. We found this address below, if correct,
                press continue.
              </Body5>
            </Message>

            <div style={{ paddingTop: '24px' }}>
              {null && (
                <Message>
                  <Body5>{`Address Suggestions (${addressVerificationSuggestions.length})`}</Body5>
                </Message>
              )}

              {addressVerificationSuggestions.map((s, i) => (
                <div key={i}>
                  <VerificationAddressSuggestion suggestion={s} />
                </div>
              ))}
            </div>

            <Actions>
              <FormState error={formError} isSubmitting={isSubmitting} />
              <Container fullWidth centerAll>
                <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleConfirmationContinueClick}>
                  Continue
                </FlatButton>
              </Container>
              <Container top={8} fullWidth centerAll>
                <FlatButton transparent isDisabled={isSubmitting} fullWidth onClick={handleUseMyEnteredAddressClick}>
                  Use the address that I entered
                </FlatButton>
              </Container>
            </Actions>
          </ContentWrapper>
        </Page>
      </LocationContext.Provider>
    );
  }

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.enterContactAddress}>
      <Page width={'600px'}>
        <ContentWrapper>
          {isResubmit ? (
            <>
              <Heading>
                <H5>Re-enter Address</H5>
              </Heading>

              <Message>
                <Body5>We were unable to verify the address you previously entered.</Body5>
              </Message>
              <Message>
                <Body5>Must be a residential address in the United States. P.O. boxes are not accepted.</Body5>
              </Message>
            </>
          ) : (
            <>
              <Heading>
                <H5>What is your address?</H5>
              </Heading>

              <Message>
                <Body5>Must be a residential address in the United States. P.O. boxes are not accepted.</Body5>
              </Message>
            </>
          )}

          <InputsWrapper>
            <InlineInputs>
              <InputWrapper style={{ width: '80%' }}>
                <FloatingLabelTextInput
                  name={'contact_street_address'}
                  value={values.contact_street_address}
                  label={'Street Address'}
                  onChange={handleChange}
                  error={errors.contact_street_address || null}
                />
              </InputWrapper>

              <InputWrapper>
                <FloatingLabelTextInput
                  name={'contact_apartment'}
                  value={values.contact_apartment}
                  label={'Apartment'}
                  onChange={handleChange}
                  error={errors.contact_apartment || null}
                />
              </InputWrapper>
            </InlineInputs>

            {showAddressSuggestions ? (
              <AddressSuggestionList
                onAddressSuggestionSelection={onAddressSuggestionSelection}
                onEnterAddressManually={onEnterAddressManually}
                list={addressSuggestions}
                hide={values.contact_street_address === ''}
              />
            ) : (
              <>
                <InlineInputs>
                  <InputWrapper style={{ width: '50%' }}>
                    <FloatingLabelTextInput
                      name={'contact_city'}
                      value={values.contact_city}
                      label={'City'}
                      onChange={handleChange}
                      error={errors.contact_city || null}
                    />
                  </InputWrapper>

                  <InputWrapper style={{ width: '20%' }}>
                    <FloatingLabelDropdown
                      name={'contact_state'}
                      value={values.contact_state}
                      label={'State'}
                      items={stateDropdownItems}
                      onChange={handleChange}
                      error={errors.contact_state || null}
                    />
                  </InputWrapper>

                  <InputWrapper style={{ width: '30%' }}>
                    <FloatingLabelTextInput
                      name={'contact_postal_code'}
                      value={values.contact_postal_code}
                      label={'Zip Code'}
                      onChange={handleChange}
                      error={errors.contact_postal_code || null}
                    />
                  </InputWrapper>
                </InlineInputs>
                <InputWrapper>
                  <FloatingLabelDropdown
                    name={'contact_country'}
                    items={[
                      createDropdownItem({ text: 'United States of America', value: 'United States of America' }),
                    ]}
                    value={values.contact_country}
                    label={'Country'}
                    onChange={handleChange}
                    error={errors.contact_country || null}
                  />
                </InputWrapper>
              </>
            )}
          </InputsWrapper>

          <PrivacyMessage>
            <IconMessage>
              <Lock />
              <Body5>This information will be kept private and it will never appear on your public profile.</Body5>
            </IconMessage>
          </PrivacyMessage>

          <Actions>
            <FormState error={formError} isSubmitting={isSubmitting} />
            <Container fullWidth centerAll>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleAddressEntryContinueClick}>
                Continue
              </FlatButton>
            </Container>
          </Actions>
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
