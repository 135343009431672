import React from 'react';
import { CheckCircleFilled } from '@src/main/icons';
import { H5, Body5 } from '../../lib/nvstr-common-ui.es';
import { Page } from '../layout';
import styled from 'styled-components';
import { FlatButton } from '../buttons';

const ContinueAction = styled.div`
  padding: 16px 0 0 0;
  display: flex;
  justify-content: center;
`;

export const ActionCompleted = ({ message, width, onContinue, children }) => {
  return (
    <Page width={width}>
      <div style={{ padding: '36px 0', textAlign: 'center' }}>
        <CheckCircleFilled textColor />
        <div style={{ padding: '16px 0 0 0', textAlign: 'center' }}>
          <H5>{message}</H5>
        </div>
      </div>
      {children}
      <ContinueAction>
        <FlatButton fullWidth onClick={onContinue}>
          Continue
        </FlatButton>
      </ContinueAction>
    </Page>
  );
};
