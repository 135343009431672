import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/index';
import { logMetricsTrackingEvent } from '../../actions';
import { ROUTES } from '../../constants/paths';

import FullPageCard from '../../components/layout/FullPageCard';
import FeaturedViewpointSelection from './FeaturedViewpointSelection';
import HeadingWithGradientLine from '../UI/HeadingWithGradientLine';
import ViewpointSubscriptionCard from './components/ViewpointSubscriptionCard';
import Button from '../../components/buttons/Button';
import StatusModal from '../../components/modals/StatusModal';

import {
  doesCurrentUserHaveFreeSubSlot,
  isCurrentUserOnboarding,
  returnCurrentUserOnboardingTestGroup,
  returnCurrentUserThemeTestGroups,
} from '../../helpers/currentUserHelpers';

import { getAllFeaturedSubscriptionViewpointIdsFromState } from '../../selectors/viewpoints';
import { createBasicErrorModal } from '../../constants/modals';
import { findUserIdeas } from '../../helpers/ideaHelpers';
import { withRouter } from '../../main/utils';
import { FlatButton } from '../../main/components/buttons';
import { PRODUCT_DISPLAY_FULL_NAME } from '../../appConfig';

class FeaturedSubscriptionSelectionPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showMakeSelectionError: false,
    };
  }

  componentDidMount() {
    const { featuredViewpointOrgIds, hasFreeViewpointSelectionAvailable } = this.props;

    const eventName = 'View Viewpoint Welcome Screen';
    logMetricsTrackingEvent(eventName)();

    if (featuredViewpointOrgIds.length === 0 || !hasFreeViewpointSelectionAvailable) {
      this.handleNavigation();
    }
  }

  render() {
    const { showMakeSelectionError } = this.state;
    const { featuredViewpointOrgIds } = this.props;

    return (
      <FullPageCard showMobileCardStyling>
        <div>
          <div className={'text-center'} style={{ margin: '0 auto' }}>
            <HeadingWithGradientLine text={'Premium content for free'} additionalClasses={'full-page-card-heading'} />
          </div>

          <div className="text-center" style={{ marginTop: 15 }}>
            <span className="secondary-text-color">
              Your {PRODUCT_DISPLAY_FULL_NAME} membership comes with access to 1 of our premium Viewpoint contributors
              for free.
            </span>
          </div>

          <div className="" style={{ marginTop: 15 }}>
            {featuredViewpointOrgIds.length > 1 ? (
              <FeaturedViewpointSelection onContinue={this.handleContinue} />
            ) : (
              <ViewpointSubscriptionCard id={featuredViewpointOrgIds[0]} showBio hideActions />
            )}
          </div>

          {featuredViewpointOrgIds.length === 1 ? (
            <div
              style={{
                textAlign: 'center',
                paddingTop: '25px',
              }}
            >
              <FlatButton onClick={this.handleSingleViewpointContinueClick}>Continue</FlatButton>
            </div>
          ) : null}

          {showMakeSelectionError && (
            <div className="error-text-color text-center" style={{ marginTop: 15 }}>
              <span>You must make a selection to continue</span>
            </div>
          )}
        </div>
      </FullPageCard>
    );
  }

  handleSingleViewpointContinueClick = () => {
    const { featuredViewpointOrgIds } = this.props;
    const selections = [featuredViewpointOrgIds[0]];
    this.handleContinue(selections);
  };

  handleContinue = (selections) => {
    if (selections.length === 0) {
      this.setState(() => ({
        showMakeSelectionError: true,
      }));
      return null;
    }

    const eventName = 'Press Continue Viewpoint Welcome Screen';
    logMetricsTrackingEvent(eventName)();

    this.handleSubscription(selections);
  };

  handleSubscription = (selections) => {
    const selection = selections[0];

    const statusModal = {
      contentComponent: <StatusModal text={'Saving'} />,
      dismissable: false,
    };
    this.props.actions.showModal(statusModal);

    setTimeout(() => this._subscribeToViewpoint(selection), 500);
  };

  _subscribeToViewpoint = (id) => {
    return this.props.actions.subscribeToViewpoint({ viewpointOrgId: id, isFree: true }).then((response) => {
      const { ok, errorMessage } = response;
      if (ok) {
        this.props.actions.hideModal();
        this.onCompleteSubscription();
      } else {
        const errorModal = {
          contentComponent: createBasicErrorModal(errorMessage || 'Something went wrong. Please try again.'),
          dismissable: true,
        };
        this.props.actions.showModal(errorModal);
      }
    });
  };

  onCompleteSubscription = () => {
    this.handleNavigation();
  };

  handleNavigation = () => {
    const { isOnboarding, navigate } = this.props;

    if (isOnboarding) {
      navigate(ROUTES.PRIVACY_SELECTION.build());
    } else {
      navigate(ROUTES.DASHBOARD.build());
    }
  };
}

const mapStateToProps = (state) => {
  const currentUser = state.currentUser;
  const currentUserId = currentUser.id;

  const ideaCount = findUserIdeas(currentUserId, state.ideas.ideaList).length;

  const userData = {
    themeTestGroups: returnCurrentUserThemeTestGroups(currentUser),
    ideaCount,
    userId: currentUserId,
    isLiveAccount: true,
    abTestGroup: returnCurrentUserOnboardingTestGroup(currentUser),
  };

  return {
    isOnboarding: isCurrentUserOnboarding(currentUser),
    featuredViewpointOrgIds: getAllFeaturedSubscriptionViewpointIdsFromState(state),
    hasFreeViewpointSelectionAvailable: doesCurrentUserHaveFreeSubSlot(currentUser),

    userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(FeaturedSubscriptionSelectionPage);
export default withRouter(connectedComponent);
