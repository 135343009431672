import React from 'react';
import SecurityIdentifierDisplay from '../../../../UI/SecurityIdentifierDisplay';
import { ThoughtTypeWithIcon } from '../../../../UI/Thought/ThoughtTypeWithIcon';

class ThoughtNotificationThoughtHeading extends React.PureComponent {
  render() {
    const { security, isThoughtTypePro } = this.props;

    return (
      <div className={'thought-notification-thought-heading-container'}>
        <SecurityIdentifierDisplay
          securityId={security.security_id}
          symbol={security.symbol}
          name={security.name}
          prefix={'('}
          suffix={')'}
          inFeed
          showSymbol
          showName
          limitNameWidth
        />
        <ThoughtTypeWithIcon isThoughtTypePro={isThoughtTypePro} />
      </div>
    );
  }
}

export default ThoughtNotificationThoughtHeading;
