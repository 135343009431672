import React from 'react';

class SizeReader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      width: 0,
    };
  }

  componentDidMount() {
    const { updateShortlyAfterMount } = this.props;
    const _this = this;

    if (updateShortlyAfterMount) {
      setTimeout(() => {
        _this.setSize();
      }, 50);
    }

    _this.setSize();

    window.addEventListener('resize', this.setSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setSize);
  }

  render() {
    const { height, width } = this.state;
    const { className, children } = this.props;
    return (
      <div ref={(el) => (this.DOMNode = el)} className={`measure-size-wrapper ${className}`}>
        {children(height, width)}
      </div>
    );
  }

  _returnDOMNode = () => this.DOMNode;

  _$returnDOMNode = () => window.$(this.DOMNode);

  _returnSizing = () => ({
    height: this._getHeight(),
    width: this._getWidth(),
  });

  setSize = () => {
    const height = this._getHeight();
    const width = this._getWidth();

    this.setState((prevState) => {
      const { height: prevHeight, width: prevWidth } = prevState;
      if (prevHeight !== height || prevWidth !== width) {
        return {
          height,
          width,
        };
      } else {
        return prevState;
      }
    });
  };

  _getHeight = () => this._$returnDOMNode().height();
  _getWidth = () => this._$returnDOMNode().width();
}

SizeReader.defaultProps = {
  className: '',
};

export default SizeReader;
