import styled from 'styled-components';
import { Row } from '@src/main/lib/nvstr-common-ui.es';

export const LogoWrap = styled('div')`
  position: relative;
  top: -20px;
  padding: 10px 0;
`;

export const LogoMobileWrap = styled('div')`
  position: relative;
  top: 20px;
  padding: 0;
  display: none;
`;

export const ValidationWrap = styled('div')`
  color: ${({ theme }) => theme.themeColors.error} !important;
  font-size: 12px;
`;

export const FormWrap = styled.div.attrs((props) => ({
  issigningup: props.issigningup || 'false',
}))`
  position: relative;

  ${({ issigningup }) =>
    issigningup === 'true'
      ? 'opacity: 0; position: absolute; top: 0; bottom: 0; overflow: scroll; pointer-events: none;'
      : ''}

  input {
    border: 1px solid ${({ theme }) => theme.themeColors.text} !important;
    border-radius: 5px !important;
  }
`;

export const LoadingCover = styled.div`
  width: 360px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 100px 0 100px 0;
`;

export const LeftArea = styled('div')`
  position: absolute;
  left: 0;
  height: 100vh;
  width: 50%;
  background: ${(props) => props.theme.colorPalette.primary.oak};
  border-right: 1px solid rgba(0, 0, 0, 0.03);
`;

export const RightArea = styled('div')`
  position: absolute;
  left: 50%;
  height: 100vh;
  width: 50%;
  background: #fff;
  min-height: 780px;
`;

export const LeftBox = styled('div')`
  position: absolute;
  right: 0;
  width: 440px;
  max-width: 440px;
  height: 100vh;
  padding: 60px 0 0 10px;
`;

export const RightBox = styled('div')`
  position: absolute;
  left: 0;
  width: calc(100% - 48px);
  max-width: 540px;
  height: 100vh;
  padding: ${(props) => props.padding || '60px 0px 0 80px'};
`;

export const Title = styled('div')`
  font-size: 30px;
  padding: 20px 0 30px 0;
  font-weight: 500;
`;

export const SubTitle = styled('div')`
  font-size: 18px;
  font-weight: 300;
  margin: 5px 0 20px;
  line-height: 1.4em;
`;

export const FormTitle = styled('div')`
  font-size: 24px;
  font-weight: 600;
`;

export const SignSwitchLink = styled('div')`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
`;

export const TermsAndConditions = styled('div')`
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;

  a {
    color: ${({ theme }) => theme.themeColors.text};
    font-weight: 700;
  }
`;

export const Agreement = styled('div')`
  padding: 0;
`;
export const BonusDisclaimer = styled('div')`
  padding: 8px 0 0 0;
`;

export const RegisteredEmail = styled('div')`
  font-size: 16px;
  font-weight: 400;
  & > b {
    font-weight: 800;
  }
`;

export const ExtraOptions = styled('div')`
  position: relative;
  margin: -8px 0 0 0;
  padding: 0 0 10px 0;
  width: 100%;
  max-width: 460px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled('div')`
  z-index: 10;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100vw;
  min-height: 100vh;

  @media only screen and (max-width: 900px) {
    width: 100vw;
    max-width: 600px;
    margin: 0 auto;

    ${LoadingCover} {
      padding: 0;
      & > div {
        position: relative;
        left: -20px;
      }
    }

    ${RightBox} {
      padding: 40px;
    }
    ${LeftArea} {
      display: none;
    }
    ${RightArea} {
      background: ${(props) => props.theme.colorPalette.primary.oak};
      left: 0;
      padding: 0;
      width: 100%;
      & input[type='text'],
      & input[type='email'],
      & input[type='password'] {
        width: 100%;
      }
      ${ExtraOptions} {
        width: 100%;
      }
      ${LogoMobileWrap} {
        display: block;
      }
    }

    ${ValidationWrap} {
      top: 118px;
    }
  }

  @media only screen and (min-width: 801px) and (max-width: 920px) {
    width: 920px;
    ${RightBox} {
      padding-left: 40px;
    }
  }

  @media only screen and (max-width: 450px) {
    ${RightBox} {
      padding-top: 10px;
      width: 100vw;
      max-width: 100vw;
    }
  }

  @media only screen and (min-width: 920px) and (max-width: 1279px) {
    width: 100vw;
    ${RightBox} {
      padding-left: 40px;
    }
  }

  @media only screen and (min-width: 801px) and (max-height: 720px) {
    ${LeftBox} {
      padding-top: 35px;
    }
    ${RightBox} {
      padding-top: 0px;
    }
  }

  @media (max-width: 426px) {
    ${RightBox} {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;
