import React from 'react';

import LoadingIcon from '../../../components/misc/LoadingIcon';

const NavItem = (props) => {
  if (props.restricted) {
    return null;
  }

  if (props.loading) {
    return <LoadingIcon icon="fading-3balls" size="small" />;
  }

  return <div className={`react-nav-item-container ${props.additionalClasses}`}>{props.children}</div>;
};

export default NavItem;
