import React from 'react';
import Icon from '../../components/misc/Icon';

export const Twitter = (props) => {
  const returnMessage = () => props.message || '';
  const returnEncodedMessage = () => encodeURIComponent(returnMessage());
  const returnURL = () => props.url;
  const returnLink = () => `https://twitter.com/share?url=${returnURL()}&text=${returnEncodedMessage()}`;
  const handleClick = () => {
    if (props.additionalActionOnClick) {
      props.additionalActionOnClick();
    }
  };

  return (
    <div className={`social-button twitter`}>
      <a
        id="twitter_share"
        className={`${props.className || ''}`}
        href={returnLink()}
        target={'_blank'}
        onClick={handleClick}
      >
        <Icon icon={'fa fa-twitter'} />
      </a>
    </div>
  );
};
