import React from 'react';

import UserContainer from '../../../../containers/users/UserContainer';
import ProfileAvatar from '../../../user/ProfileAvatar';
import UserName from '../../../user/UserName';
import { PROFILE_CONTEXTS } from '../../../../constants/contextTracking';

export const Idea = (props) => {
  const {
    user,
    userId,
    idea,
    showNameTag,
    handleHover,
    handleHoverEnd,

    hideLabels,
  } = props;

  const handleIdeaHover = () => {
    return handleHover(idea && idea.idea_id);
  };

  return (
    <div className="position-visualizer-idea-container">
      <UserContainer userId={user ? user.user_id : userId}>
        <ProfileAvatar
          size="small"
          ideaType={idea && idea.idea_type && idea.idea_type.name}
          profileLinkQuery={`?context=${PROFILE_CONTEXTS.IDEA}`}
          showNameTag={showNameTag}
          handleMouseOver={handleIdeaHover}
          handleMouseOut={handleHoverEnd}
        />
        <UserName hide={hideLabels} />
      </UserContainer>
    </div>
  );
};

export default Idea;
