import React from 'react';
import styled from 'styled-components';
import { Body5 } from '../../lib/nvstr-common-ui.es';
import { colorPalette } from '../../lib/nvstr-utils.es';
import UnstyledLoadingSpinner from '../Images/UnstyledLoadingSpinner';

const Error = styled.div`
  padding-bottom: 8px;

  * {
    color: ${colorPalette.secondary.red};
  }
`;
const Submitting = styled.div`
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const SubmittingIcon = styled.div`
  margin-right: 8px;

  div,
  svg {
    margin: 0;
    height: 18px;
    width: 18px;
  }
`;

export const FormState = ({ isSubmitting, error }) => {
  return (
    <div>
      {error && (
        <Error>
          <Body5>{error}</Body5>
        </Error>
      )}
      {isSubmitting && (
        <Submitting>
          <SubmittingIcon>
            <UnstyledLoadingSpinner />
          </SubmittingIcon>
          <Body5>Sending...</Body5>
        </Submitting>
      )}
    </div>
  );
};
