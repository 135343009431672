import React from 'react';
import { usePosition } from './usePosition';
import { usePriceData } from '../securities/usePriceData';
import { useEquityValue } from './useEquityData';

export const usePositionAllocation = (securityId) => {
  const [allocData, setAllocData] = React.useState({ isLoading: true });

  const { price } = usePriceData(securityId);
  const { shares } = usePosition(securityId);
  const { value: equity, isLoading: isEquityValueLoading } = useEquityValue();

  React.useEffect(() => {
    if (shares === 0) {
      setAllocData({
        isLoading: false,
        shares: 0,
        allocationValue: 0,
        allocationPercentage: 0,
      });
    }

    if (isEquityValueLoading || price === null || isNaN(price)) {
      setAllocData({
        isLoading: true,
      });
    }

    if (!isEquityValueLoading) {
      const allocationValue = price * shares;
      const allocationPercentage = allocationValue / equity;

      setAllocData({
        shares,
        value: allocationValue,
        percentageOfPortfolio: allocationPercentage,
      });
    }
  }, [shares, price, equity, isEquityValueLoading]);

  return allocData;
};
