import React from 'react';

class WidthLayoutManager extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      width: null,
    };
  }

  componentDidMount() {
    this._addWindowResizeListener();
    this.onUpdateSize();
  }

  componentWillUnmount() {
    this._removeWindowResizeListener();
  }

  render() {
    const { className, style } = this.props;
    const { width } = this.state;

    return (
      <div ref={(el) => (this.Container = el)} className={className} style={style || {}}>
        {width > 0 && this.renderElements()}
      </div>
    );
  }

  renderElements = () => {
    const {
      widthThreshold,

      greaterThanThresholdLayout,
      lessThanThresholdLayout,
    } = this.props;
    const { width } = this.state;

    if (width > widthThreshold) {
      return greaterThanThresholdLayout(width);
    } else {
      return lessThanThresholdLayout(width);
    }
  };

  _addWindowResizeListener = () => {
    window.addEventListener('resize', this.onUpdateSize);
  };

  _removeWindowResizeListener = () => {
    window.removeEventListener('resize', this.onUpdateSize);
  };

  onUpdateSize = () => {
    const width = this._getSize();
    this.setState((prevState) => {
      if (width !== prevState.width) {
        return {
          width,
        };
      } else {
        return {};
      }
    });
  };

  _getSize = () => {
    const $el = window.$(this.Container);
    const width = $el.width();
    if (width > 0) {
      return width;
    }
    return null;
  };
}

export default WidthLayoutManager;
