import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSecuritiesOrderPriceData, fetchSecuritiesPriceData } from '@src/actions';
import { Page } from '@src/main/components/layout';
import UnstyledLoadingSpinner from '@src/main/components/Images/UnstyledLoadingSpinner';
import { FlatButton, SkeletonButton } from '@src/main/components/buttons';
import { ArrowLeft } from '@src/main/icons/svg/ArrowLeft';
import { formatLocaleString } from '@src/helpers/generalHelpers';
import { useSecurity } from '@src/main/hooks/securities/useSecurity';
import { Body3, Body5, Body7, H5 } from '@src/main/lib/nvstr-common-ui.es';
import { Container } from '@src/main/components/ui';
import InfoIcon, { InfoIconWrapper } from '@src/containers/UI/InfoIcon';
import { INFO_ICON_WORDS } from '@src/helpers/infoIconHelpers';
import { DISPLAY_FORMAT_TYPES, formatValueTo } from '@src/main/utils/numbers';
import { LoadingAnimation } from '@src/main/components/ui/LoadingAnimation';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  padding-top: 24px;
  min-height: 320px;

  overflow: auto;
`;

const DismissAction = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const PriceListWrapper = styled.div`
  position: relative;
`;
const LoadingWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: -4px;
  bottom: -0px;
  left: -4px;
  z-index: 1;
  background: ${({ theme }) => theme.themeColors.overlay};
  border-radius: 2px;

  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    height: 48px;
    width: 48px;
  }
`;
const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  padding-top: 4px;
  svg {
    height: 20px;
    width: 20px;
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;
const BackHeaderWrapper = styled.div``;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
`;

const BackHeader = ({ onDismiss }) => {
  return (
    <BackHeaderWrapper>
      <IconWrapper onClick={onDismiss}>
        <ArrowLeft />
      </IconWrapper>
    </BackHeaderWrapper>
  );
};

const PricingDataDisplay = ({
  symbol,
  displayLastPrice,
  lastPriceSize,
  bidSize,
  displayBidPrice,
  askSize,
  displayAskPrice,
}) => {
  return (
    <Container>
      <Container>
        <H5>{`${symbol} Pricing`}</H5>
      </Container>
      <Container top={6}>{"Last Price is provided by NASDAQ's real-time last sale feed."}</Container>
      <PriceListWrapper>
        {!displayLastPrice ? (
          <LoadingWrapper>
            <LoadingAnimation />
          </LoadingWrapper>
        ) : null}
        <Container top={16}>
          <Row>
            <Body3>Last Price </Body3>
            {displayLastPrice ? (
              <Container>
                <Body3>{displayLastPrice}</Body3>
                <Body7 isLowContrast={false}> QTY </Body7>
                <Body3>{formatValueTo(DISPLAY_FORMAT_TYPES.COMMA_SEPARATED_INT, lastPriceSize)}</Body3>
              </Container>
            ) : (
              <Container />
            )}
          </Row>

          <Row>
            <InfoIconWrapper>
              <Body3>Bid</Body3>
              <InfoIcon word={INFO_ICON_WORDS.bidAskOrder} style={{ top: '4px', right: '-18px' }} />
            </InfoIconWrapper>
            {displayBidPrice ? (
              <Container>
                <Body3>{`${displayBidPrice}`}</Body3>
                <Body7 isLowContrast={false}> QTY </Body7>
                <Body3>{formatValueTo(DISPLAY_FORMAT_TYPES.COMMA_SEPARATED_INT, bidSize)}</Body3>
              </Container>
            ) : (
              <Container />
            )}
          </Row>

          <Row>
            <InfoIconWrapper>
              <Body3>Ask</Body3>
              <InfoIcon word={INFO_ICON_WORDS.bidAskOrder} style={{ top: '4px', right: '-18px' }} />
            </InfoIconWrapper>
            {displayAskPrice ? (
              <Container>
                <Body3>{`${displayAskPrice}`}</Body3>
                <Body7 isLowContrast={false}> QTY </Body7>
                <Body3>{formatValueTo(DISPLAY_FORMAT_TYPES.COMMA_SEPARATED_INT, askSize)}</Body3>
              </Container>
            ) : (
              <Container />
            )}
          </Row>
        </Container>
      </PriceListWrapper>

      <Container top={16}>
        <Body5>{'Bid / Ask is aggregated and delayed 15 minutes.'}</Body5>
      </Container>
    </Container>
  );
};

export const LiveOrderPricing = ({ securityId, onDismiss }) => {
  const dispatch = useDispatch();

  const [isReady, setIsReady] = React.useState(false);
  const [error, setError] = React.useState(false);

  const store = useSelector((state) => state.securitiesOrderPrice);
  const { symbol } = useSecurity(securityId);

  const init = async () => {
    const securities = [securityId];
    const responses = await Promise.all([
      fetchSecuritiesPriceData(securities)(dispatch),
      fetchSecuritiesOrderPriceData(securities)(dispatch),
    ]);
    const ok = responses[0].status === 200 && responses[1].status === 200;
    if (ok) {
      if (error) setError(false);
      setIsReady(true);
    } else {
      setError(true);
    }
  };

  const handleRetry = () => {
    init();
  };

  React.useEffect(() => {
    init();
  }, []);

  if (error) {
    return (
      <Page width={'440px'}>
        <BackHeader onDismiss={onDismiss} />
        <Wrapper>
          <Container>
            <Body3>Something went wrong getting the pricing data, please try again.</Body3>
            <Container top={24}>
              <FlatButton onClick={handleRetry}>Retry</FlatButton>
            </Container>
          </Container>
        </Wrapper>
        <DismissAction>
          <SkeletonButton fullWidth onClick={onDismiss}>
            Go Back
          </SkeletonButton>
        </DismissAction>
      </Page>
    );
  }

  if (!isReady) {
    return (
      <Page width={'440px'}>
        <BackHeader onDismiss={onDismiss} />
        <Wrapper>
          <PricingDataDisplay symbol={symbol} />
        </Wrapper>
        <DismissAction>
          <SkeletonButton fullWidth onClick={onDismiss}>
            Go Back
          </SkeletonButton>
        </DismissAction>
      </Page>
    );
  }

  const formatFloatForPriceDisplay = (float) => {
    return (
      '$' +
      formatLocaleString(float, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };

  const pricingData = store.securities[securityId];
  const lastPrice = pricingData.last_price;
  const displayLastPrice = formatFloatForPriceDisplay(lastPrice);
  const lastPriceSize = pricingData.last_size;
  const bidPrice = pricingData.bid_price;
  const bidSize = pricingData.bid_size;
  const displayBidPrice = formatFloatForPriceDisplay(bidPrice);
  const askPrice = pricingData.ask_price;
  const askSize = pricingData.ask_size;
  const displayAskPrice = formatFloatForPriceDisplay(askPrice);
  return (
    <Page width={'440px'}>
      <BackHeader onDismiss={onDismiss} />
      <Wrapper>
        <PricingDataDisplay
          symbol={symbol}
          displayLastPrice={displayLastPrice}
          lastPriceSize={lastPriceSize}
          bidSize={bidSize}
          displayBidPrice={displayBidPrice}
          askSize={askSize}
          displayAskPrice={displayAskPrice}
        />
      </Wrapper>
      <DismissAction>
        <SkeletonButton fullWidth onClick={onDismiss}>
          Go Back
        </SkeletonButton>
      </DismissAction>
    </Page>
  );
};
