import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import tornadoSmallWhite from '/assets/tornado-small-light.svg';
import { generateGradientStyling, convertSizeToStyling } from '../../helpers/avatarHelpers';
import {
  userIsThoughtLeader,
  displayThoughtLeaderInitials,
  userFullName,
  isUserCollective,
} from '../../helpers/usersHelpers';
import { shouldShowLiveTradingAvatar } from '../../helpers/currentUserHelpers';
import { getViewpointDisplayNameFromState } from '../../selectors/viewpoints';
import { returnPathTo } from '../../constants/paths';

const InnerAvatarWrapper = styled.span`
  background-color: ${({ theme }) => theme.themeColors.avatarBackgroundColor};

  * {
    color: ${({ theme }) => theme.themeColors.buttonText} !important;
  }
  &:hover {
    color: ${({ theme }) => theme.themeColors.buttonText};
  }
`;

const AvatarWrap = styled('div')`
  .profile-image-ph {
    content: url('/app/assets/tornado-small-dark.svg') !important;
  }
`;

const tornadoSmallWrap = styled('div')`
  position: relative;
  top: 5px;
`;

const haloThicknessDict = {
  email: 1,
  small: 1,
  nav: 2,
  medium: 2,
  large: 2,
  profile: 2,
  x_large: 4,
};

class ProfileAvatar extends React.PureComponent {
  render() {
    const {
      size: propsSize,
      withLink: propsWithLink,

      user: propsUser,

      linkTo,
      ideaId,
      isThoughtLeader,

      viewpointOrgName,
      isViewpointOrg,
      viewpointOrgId,
      imageSrc: propsImageSrc,
      initials,

      ideaType,
      userIsCurrentUser,

      profileLinkQuery,
      showNameTag,
    } = this.props;

    const tornadoLogoSmall = tornadoSmallWhite;

    const user = propsUser || {};

    const showAsLiveTrading = shouldShowLiveTradingAvatar(user);

    const withLink = 'withLink' in this.props ? propsWithLink : true;
    const userId = isViewpointOrg ? null : user.user_id;

    const gradientId = isViewpointOrg ? viewpointOrgId : userId;
    const imageSrc = propsImageSrc || user.avatar_url;
    const size = propsSize || 'small';

    const userHasHardCodedImage = isUserCollective(user);
    const hasProfileAvatarImage =
      userHasHardCodedImage || (imageSrc && typeof imageSrc === 'string' && imageSrc.indexOf('missing') < 0);

    const userInitials = userIsThoughtLeader(user)
      ? displayThoughtLeaderInitials(user)
      : isUserCollective(user)
      ? ''
      : isViewpointOrg
      ? initials
      : `${user.first_name[0]}${user.last_name[0]}`.toUpperCase();

    const handleHover = () => ('handleHover' in this.props ? this.props.handleHover() : false); // deprecated, use handleMouseOver

    const handleHoverEnd = () => ('handleHoverEnd' in this.props ? this.props.handleHoverEnd() : false); // deprecated, use handleMouseOut

    const haloThickness = haloThicknessDict[size] || 1;
    const sizeOffset = haloThickness * 2;
    const logoImageSrc = tornadoLogoSmall;

    const collectivePositionAdjust =
      size === 'profile'
        ? { position: 'relative', top: '12px', left: '-1px' }
        : { position: 'relative', top: '3px', left: '-1px' };

    const profileImage = (
      <img
        src={isUserCollective(user) ? logoImageSrc : imageSrc}
        className={isUserCollective(user) ? 'profile-image profile-image-ph' : 'profile-image'}
        alt="profile-picture"
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverEnd}
        style={isUserCollective(user) ? collectivePositionAdjust : null}
      />
    );

    const textContainerStyle = {
      ...convertSizeToStyling(size, sizeOffset * 2).user_initials_container,
      ...convertSizeToStyling(size, sizeOffset * 2).profile_avatar_container,
    };
    if (haloThickness < 2) {
      textContainerStyle.paddingTop = '1px';
    }

    const defaultAvatar = (
      <span
        className="default-avatar-text-container"
        style={{
          ...textContainerStyle,
          height: '100%',
          width: '100%',
          color: '#fff',
          fontWeight: 600,
        }}
      >
        {userInitials.toUpperCase()}
      </span>
    );

    const avatarChildren = hasProfileAvatarImage ? profileImage : defaultAvatar;

    const returnChildrenContainer = (children) => (
      <AvatarWrap>
        <span className="avatar-wrapper">
          <span
            className={'inner-avatar-border'}
            style={{
              position: 'absolute',
              top: `${haloThickness}px`,
              right: `${haloThickness}px`,
              bottom: `${haloThickness}px`,
              left: `${haloThickness}px`,
              borderRadius: '200px',
            }}
          >
            <InnerAvatarWrapper
              className={`inner-avatar-container ${isUserCollective(user) ? 'collective-avatar-bg' : ''}`}
              style={{
                ...generateGradientStyling(gradientId),
                position: 'absolute',
                top: `${haloThickness}px`,
                right: `${haloThickness}px`,
                bottom: `${haloThickness}px`,
                left: `${haloThickness}px`,
                borderRadius: '200px',
              }}
            >
              {children}
            </InnerAvatarWrapper>
          </span>
          {showNameTag && <span className="name-label">{userIsCurrentUser ? 'Me' : userFullName(user)}</span>}
          {ideaType && (
            <div className="drop-shadow-container">
              <div className="drop-shadow-container-top">
                <div
                  className={`drop-shadow-bottom ${
                    ideaType && ideaType.toLowerCase() === 'buy' ? 'drop-shadow-green' : 'drop-shadow-red'
                  }`}
                />
              </div>
              <div className="drop-shadow-container-bottom">
                <div
                  className={`drop-shadow-top ${
                    ideaType && ideaType.toLowerCase() === 'buy' ? 'drop-shadow-green' : 'drop-shadow-red'
                  }`}
                />
              </div>
            </div>
          )}
        </span>
      </AvatarWrap>
    );

    const avatarContainerClass = hasProfileAvatarImage ? 'profile-image-container' : 'default-avatar-container';
    const componentProps = {
      className: `avatar-container ${avatarContainerClass} ${
        showAsLiveTrading ? 'live-trading-halo' : ''
      } avatar-${size} ${isUserCollective(user) ? 'collective-user' : ''}`,
      onMouseEnter: handleHover,
      onMouseLeave: handleHoverEnd,
      style: {
        ...convertSizeToStyling(size, 0).profile_avatar_container,
        ...(withLink ? {} : { cursor: 'default' }),
      },
    };

    if (withLink) {
      const query = profileLinkQuery || '';
      let url =
        linkTo ||
        (isThoughtLeader
          ? `/users/${userId}/public_profile${query}`
          : withLink && ideaId
          ? `/ideas/${ideaId}`
          : `/users/${userId}/public_profile${query}`);

      if (isViewpointOrg) {
        url = returnPathTo('viewpoint_profile', viewpointOrgName);
      }
      componentProps.to = url;
    }

    const renderLoadingAvatar = () => {
      const loadingAvatarChildren = <div className={'loading-bg'} />;
      const className = componentProps.className;
      const style = componentProps.style;
      return (
        <span className={`user-is-loading ${className}`} style={style}>
          {returnChildrenContainer(loadingAvatarChildren)}
        </span>
      );
    };

    const _isLoading = () => this.props.isLoading;

    const handleMouseOver = () => {
      return this.props.handleMouseOver && this.props.handleMouseOver();
    };
    const handleMouseOut = () => {
      return this.props.handleMouseOut && this.props.handleMouseOut();
    };

    const renderAvatar = () => {
      componentProps.onMouseEnter = handleMouseOver;
      componentProps.onMouseLeave = handleMouseOut;
      return React.createElement(withLink ? Link : 'div', componentProps, returnChildrenContainer(avatarChildren));
    };

    return _isLoading() ? renderLoadingAvatar() : renderAvatar();
  }
}

const mapStateToProps = (state, ownProps) => {
  const { isViewpointOrg, viewpointOrgId } = ownProps;
  if (isViewpointOrg) {
    return {
      viewpointOrgName: getViewpointDisplayNameFromState(state, viewpointOrgId),
    };
  } else {
    return {};
  }
};

export default connect(mapStateToProps)(ProfileAvatar);
