import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as Actions from '../../../../actions/index';
import LinkTo from '../../../Links/LinkTo';
import { truncateName, snakeToHyphens } from '../../../../helpers/generalHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';

export class SecurityIdentifierWithCurrentPriceCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? (
          this.props.loadingCellComponent
        ) : (
          <LinkTo
            to={this._returnLinkForHandlingClick()}
            className={''}
            additionalClickAction={this.props.logSecurityPanelOpenClick}
            child={this._renderCellContent()}
            noTextDecoration
          />
        )}
      </td>
    );
  }

  _isLoading = () => {
    const { securityId } = this.props;
    const security = this.props.securities.lookup[securityId];

    return !(securityId in this.props.securities.lookup) || !security.symbol || !security.name;
  };

  _returnLinkForHandlingClick = () => {
    const securityId = this.props.securityId;
    const openSecurityPanelConfig = {
      securityId,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    const securityId = this.props.security.security_id;
    const security = this.props.securities.lookup[securityId];
    const { name, symbol } = security;

    return (
      <span className="security-table-identifier-with-price-no-change">
        <span className="security-table-symbol  ">{symbol}</span>

        <span className="security-table-company-name truncate-text-overflow secondary-text-color">
          {truncateName(name, $(window).width() > 525 ? 33 : 20)}
        </span>
      </span>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(SecurityIdentifierWithCurrentPriceCell);

export default composedComponent;
