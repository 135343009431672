import { securitiesAdapter } from '../adapters/securitiesAdapter';

import { ADD_ADDITIONAL_STREAMING_SECURITY, STREAMING_PRICES_UPDATE } from '../constants';

export const getSecuritiesPriceStreamConfig = () => {
  return securitiesAdapter.getSecuritiesPriceStreamConfig().then((response) => {
    const ok = response && response.data;
    if (ok) {
      return {
        ...response.data,
      };
    } else {
      return {
        error: true,
      };
    }
  });
};

export const savePriceStreamUpdate = (securityPriceData) => {
  return function (dispatch) {
    dispatch({
      type: STREAMING_PRICES_UPDATE,
      payload: securityPriceData,
    });
  };
};

export const addSecuritiesToPriceStreaming = (ids, symbols) => {
  return function (dispatch) {
    dispatch({
      type: ADD_ADDITIONAL_STREAMING_SECURITY,
      payload: { ids, symbols },
    });
  };
};
