import React from 'react';

const TransactionYearSeparator = (props) => {
  const { year } = props;
  return (
    <div className={`transactions-group-year-separator-container border-accent`}>
      <h3>{year}</h3>
    </div>
  );
};

export default TransactionYearSeparator;
