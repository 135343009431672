import React from 'react';
import { LogoFull } from '@src/main/lib/nvstr-common-ui.es';
import { PRODUCT_NAME, PRODUCT_NAMES } from '@src/appConfig';
import styled from 'styled-components';

export const PartnerLogoWrapper = styled.div``;

export const TornadoLogoWrapper = styled.div`
  path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

export const TornadoFullLogo = (props) => {
  return (
    <TornadoLogoWrapper>
      <LogoFull {...props} />
    </TornadoLogoWrapper>
  );
};

export const FullLogo = (props) => {
  if (PRODUCT_NAME === PRODUCT_NAMES.Tornado) {
    return (
      <TornadoLogoWrapper>
        <LogoFull {...props} />
      </TornadoLogoWrapper>
    );
  }
  return <LogoFull {...props} />;
};
