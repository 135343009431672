import { BASEURL } from '.';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const userMutualConnectionsAdapter = {
  fetchMutualConnections: (userIds) => {
    return sendRequest('get', [`${URL}/users?user_ids=${userIds}&include_mutual_connections=true`]).then(
      (response) => response
    );
  },
};
