import { sendRequestV1 } from '@src/helpers/ajaxHelpers';
import { BASEURL } from '@src/adapters';
import { sleep } from '@src/helpers/usefulFuncs';

const V1_URL = `${BASEURL}/api/v1`;

export const logUserAction = (data) => {
  return async function(dispatch) {
    const response = await sendRequestV1({
      method: 'post',
      url: `${V1_URL}/current_user/user_action`,
      body: data,
    });
    const { status, errorMessage } = response;

    if (status === 200) {
      return {
        ok: true,
      };
    } else {
      return {
        ok: false,
        message: errorMessage,
      };
    }
  };
};

export const sendSupportMessage = (message) => {
  return async function(dispatch) {
    const data = {
      message,
    };
    const response = await sendRequestV1({
      method: 'post',
      url: `${V1_URL}/support`,
      body: data,
    });
    const { status, errorMessage } = response;

    if (status === 200) {
      return {
        ok: true,
      };
    } else {
      return {
        ok: false,
        message: errorMessage,
      };
    }
  };
};
