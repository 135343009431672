import React from 'react';

const TransactionListHeading = (props) => {
  const { headingText } = props;
  return (
    <div className={`transactions-list-heading-container`}>
      <h2>{headingText}</h2>
    </div>
  );
};

export default TransactionListHeading;
