import React from 'react';
import { PRODUCT_NAME, PRODUCT_NAMES } from '@src/appConfig';
import CheckoutForm from './CheckoutForm';

const SubscriptionPaymentContainer = (props) => {
  const { charges, onPaymentComplete, handleContinue, handleCancel } = props;

  return (
    <div className="subscription-payment-container">
      <CheckoutForm
        viewingContext={`${PRODUCT_NAMES.Tornado} Subscription`}
        handleContinue={handleContinue}
        handleCancel={handleCancel}
        onPaymentComplete={onPaymentComplete}
        charges={charges}
      />
    </div>
  );
};

export default SubscriptionPaymentContainer;
