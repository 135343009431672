import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  & .circle-loading-spinner {
    border: 4px rgba(109, 86, 251, 0.25) solid;
    border-top: 4px ${({ theme }) => theme.themeColors.primaryCtaButton} solid;
  }
`;

const FormLoadingOverlay = (props) => {
  const show = props.show ? '' : 'hidden';

  const defaultSpinner = { iconClass: 'fa fa-spinner spin', style: {} };
  const spinner = props.spinner ? props.spinner : defaultSpinner;
  const customSpinnerOffsetStyle = spinner.style || {};
  const overlayCustomStyle = props.overlayStyle || {};

  return (
    <Wrapper
      className={`
        form-loading-overlay
        ${show}
        ${props.scrollRestriction ? 'scroll-restriction' : ''}
        ${props.className || ''}
      `}
      style={overlayCustomStyle}
    >
      <div className="form-loading-overlay-message-container">
        <div className="form-loading-overlay-message">
          <i className={spinner.iconClass} style={{ ...customSpinnerOffsetStyle }} />
          <span>{props.message}</span>
        </div>
      </div>
    </Wrapper>
  );
};

export default FormLoadingOverlay;
