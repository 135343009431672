import { FullLogo, PartnerLogoWrapper, TornadoLogoWrapper } from '@src/main/components/logo/FullLogo';
import { SubTitle, Title } from '@src/main/components/login/LoginStyles';
import React from 'react';
import styled from 'styled-components';
import { PRODUCT_DISPLAY_FULL_NAME, PRODUCT_NAME, PRODUCT_NAMES } from '../../../appConfig';
import { Container } from '@src/main/components/ui';

const TextGroup = styled.div``;

const MessagingWrapper = styled.div`
  max-width: 440px;
  margin: 0 0 0 auto;

  ${PartnerLogoWrapper} {
    svg {
      height: 60px;
      width: 241px;
    }
  }
  ${TornadoLogoWrapper} {
    span {
      font-size: 16px;
    }
    svg {
      height: 40px;
      width: 216px;
    }
  }

  @media (max-width: 875px) {
    padding: 24px 0 0 0;
    max-width: unset;
    margin: 0;
    text-align: center;

    ${TextGroup} {
      display: ${({ hideOnMobile, shortened }) => (hideOnMobile || shortened ? 'none' : 'block')};
    }

    ${PartnerLogoWrapper} {
      svg {
        height: 43px;
        width: 175px;
      }
    }
    ${TornadoLogoWrapper} {
      width: 159px;
      margin: 0 auto;
      span {
        font-size: 16px;
      }

      svg {
        height: 30px;
        width: 138px;
      }
    }

    ${SubTitle} {
      padding: 0;
      text-align: center;
      max-width: 380px;
      margin: 0 auto;
    }
  }
`;

export const PublicPageMessaging = ({ hideOnMobile, shortened }) => {
  if (PRODUCT_NAME === PRODUCT_NAMES.TheStreet) {
    return (
      <MessagingWrapper hideOnMobile={hideOnMobile} shortened={shortened}>
        <FullLogo />
        <Container top={16}>
          <TextGroup>
            <SubTitle>
              Your one-stop investing resource for premier financial content and trading from TheStreet.
            </SubTitle>
          </TextGroup>
        </Container>
      </MessagingWrapper>
    );
  }
  return (
    <MessagingWrapper hideOnMobile={hideOnMobile} shortened={shortened}>
      <FullLogo />
      <TextGroup>
        <Title>
          <b>TEACH YOUR MONEY</b>
          <br /> HOW TO MAKE MONEY
        </Title>
        <SubTitle>
          Buy, sell, and trade with {PRODUCT_DISPLAY_FULL_NAME}, the only investing platform that helps you invest in
          yourself.
        </SubTitle>
      </TextGroup>
    </MessagingWrapper>
  );
};
