import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  .toggle-left {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .toggle-right {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .toggle-left.thought-type-toggle {
    color: ${({ theme }) => theme.themeColors.proColor};
    border: 1px solid ${({ theme }) => theme.themeColors.proColor};
    border-right: 0.75px solid ${({ theme }) => theme.themeColors.border};
  }
  .toggle-right.thought-type-toggle {
    color: ${({ theme }) => theme.themeColors.conColor};
    border: 1px solid ${({ theme }) => theme.themeColors.conColor};
    border-left: 0.75px solid ${({ theme }) => theme.themeColors.border};
  }
  .toggle-left.thought-type-toggle.toggle-active {
    color: ${({ theme }) => theme.themeColors.buttonText};
    border-color: ${({ theme }) => theme.themeColors.proColor};
    background-color: ${({ theme }) => theme.themeColors.proColor};
  }
  .toggle-right.thought-type-toggle.toggle-active {
    color: ${({ theme }) => theme.themeColors.buttonText};
    border-color: ${({ theme }) => theme.themeColors.conColor};
    background-color: ${({ theme }) => theme.themeColors.conColor};
  }

  .toggle-left:hover {
    opacity: 0.8;
    background-color: ${({ theme }) => theme.themeColors.proColor};
    color: ${({ theme }) => theme.themeColors.buttonText} !important;
  }
  .toggle-right:hover {
    opacity: 0.8;
    background-color: ${({ theme }) => theme.themeColors.conColor};
    color: ${({ theme }) => theme.themeColors.buttonText} !important;
  }
`;

const Toggle = (props) => {
  function isActive(i, value, values, selectionValues) {
    if (typeof value === 'object') {
      const activeValue = values[i];

      return activeValue !== null && value !== null && activeValue.id === value.id ? 'toggle-active' : '';
    } else if (values && values.length > 0) {
      const activeValue = typeof values[i] === 'string' ? values[i].toLowerCase() : values[i];
      const val = typeof value === 'string' ? value.toLowerCase() : value;

      return activeValue === val ? 'toggle-active' : '';
    } else {
      const activeValue =
        typeof selectionValues[i] === 'string' ? selectionValues[i].toLowerCase() : selectionValues[i];
      const val = typeof value === 'string' ? value.toLowerCase() : value;

      return activeValue === val ? 'toggle-active' : '';
    }
  }

  const name = props.name;
  const value = props.getValue(name);
  const selectionValues = props.selectionValues;
  const values = props.values;

  function handleToggleClick(name, i) {
    if (typeof props.getValue(name) === 'object') {
      return () => {
        props.handleToggleClick(name, values[i]);

        if (props.saveOnChange) {
          const data = {
            [`${props.objectType}_id`]: props.getObjectId(props.objectType),
            [name]: values[i],
          };
          props.saveOnChange(data);
        }
        $('.idea-value').blur();
      };
    } else if (values && values.length > 0) {
      return () => {
        props.handleToggleClick(name, values[i]);

        if (props.saveOnChange) {
          const data = {
            [`${props.objectType}_id`]: props.getObjectId(props.objectType),
            [name]: values[i],
          };
          props.saveOnChange(data);
        }
        $('.idea-value').blur();
      };
    } else {
      return () => {
        props.handleToggleClick(name, selectionValues[i]);

        if (props.saveOnChange) {
          const data = {
            [`${props.objectType}_id`]: props.getObjectId(props.objectType),
            [name]: selectionValues[i],
          };
          props.saveOnChange(data);
        }

        $('.idea-value').blur();
      };
    }
  }

  const isError = !!props.getErrorMessage(name);
  const errorMessage = isError ? props.getErrorMessage(name) : '';

  return (
    <Wrapper>
      <div className="toggle-container">
        <div className="toggle-switch-container">
          <div className="toggle-switch">
            <div
              className={`toggle-left ${isActive(0, value, values, selectionValues)} ${
                props.selectionValuesClass || ''
              }`}
              onClick={handleToggleClick(name, 0)}
            >
              {selectionValues[0]}
            </div>
            <div
              className={`toggle-right ${isActive(1, value, values, selectionValues)} ${
                props.selectionValuesClass || ''
              }`}
              onClick={handleToggleClick(name, 1)}
            >
              {selectionValues[1]}
            </div>
          </div>
          <p className={`text-field-error-message`}>{errorMessage}</p>
        </div>
      </div>
    </Wrapper>
  );
};
export default Toggle;
