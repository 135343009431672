import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';
import { PlatformContextTypes } from '../constants';

const URL = `${BASEURL}/api/thoughts`;
const v1URL = `${BASEURL}/api/v1/thoughts`;

export const thoughtsAdapter = {
  createThought: (thought) => {
    const body = {
      thoughts: Array.isArray(thought) ? thought : [thought],
    };
    return sendRequest('post', [`${URL}`, body]);
  },

  getThoughtData: (id) => {
    return sendRequest('get', [`${URL}/${id}`]).then((response) => response);
  },

  fetchThoughts: (security_ids) => {
    const key = 'security_id';
    const securitiesCSV = security_ids.join(',');
    return sendRequest('get', [`${URL}?${key}=${securitiesCSV}`]).then((response) => response);
  },

  sendThoughtOpinion: (updatedThought) => {
    return sendRequest('post', [`${URL}/${updatedThought.id}/agree`, updatedThought]);
  },

  getThoughtFeedSuggestion: (data) => {
    const { securityId, excludeThoughtIdsCSV } = data;
    return sendRequest('get', [`${URL}/next?security_id=${securityId}&exclude_thought_id=${excludeThoughtIdsCSV}`]);
  },

  sendThoughtViewEvent: (id, context, metadata = {}, notificationIndex) => {
    return sendRequest('post', [
      `${URL}/${id}/view`,
      {
        context,
        platform: PlatformContextTypes.WEB,
        metadata,
        index_in_feed: notificationIndex,
      },
    ]);
  },

  fetchRecentThoughts: (data) => {
    const url = data?.userId
      ? `${v1URL}/recent?user_id=${data.userId}&count=${data.count}`
      : `${v1URL}/recent?viewpoint_id=${data.viewpointId}&count=${data.count}`;

    return sendRequest('get', [url]);
  },

  sendThoughtClickEvent: (id, clickType, context, metadata = {}, notificationIndex) => {
    return sendRequest('post', [
      `${URL}/${id}/click`,
      {
        click_type: clickType,
        context,
        platform: PlatformContextTypes.WEB,
        metadata,
        index_in_feed: notificationIndex,
      },
    ]);
  },
};
