import React from 'react';
import styled from 'styled-components';
import VerticalNavItem from '../../components/VerticalNavItem';
import Analysis from '../routeItems/Analysis';
import DashboardIcon from '@src/main/components/Images/DashboardIcon';
import ExchangeIcon from '@src/main/components/Images/ExchangeIcon';
import DollarIcon from '@src/main/components/Images/DollarIcon';
import { useCurrentUser, usePromotions } from '@src/main/hooks/user';
import { GraduationCap } from '@src/main/assets/svgs';
import { useBankAccounts } from '@src/main/hooks/funding/useBankAccounts';
import Rewards from '../routeItems/Rewards';

const Wrapper = styled.div`
  margin-left: -2px;

  i {
    font-size: 16px;
  }
`;

const LearnIconWrapper = styled.div`
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const LearnVNavRoute = () => {
  return (
    <Wrapper>
      <VerticalNavItem
        name={'Learn and Earn'}
        customIcon={
          <LearnIconWrapper>
            <GraduationCap />
          </LearnIconWrapper>
        }
        route={'/learn-and-earn-home'}
        newRoute
      />
    </Wrapper>
  );
};

export const DashboardVNavRoute = () => {
  return <VerticalNavItem name="Dashboard" customIcon={<DashboardIcon />} route="/" />;
};

const RobotIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>robot-outline</title>
      <path d="M17.5 15.5C17.5 16.61 16.61 17.5 15.5 17.5S13.5 16.61 13.5 15.5 14.4 13.5 15.5 13.5 17.5 14.4 17.5 15.5M8.5 13.5C7.4 13.5 6.5 14.4 6.5 15.5S7.4 17.5 8.5 17.5 10.5 16.61 10.5 15.5 9.61 13.5 8.5 13.5M23 15V18C23 18.55 22.55 19 22 19H21V20C21 21.11 20.11 22 19 22H5C3.9 22 3 21.11 3 20V19H2C1.45 19 1 18.55 1 18V15C1 14.45 1.45 14 2 14H3C3 10.13 6.13 7 10 7H11V5.73C10.4 5.39 10 4.74 10 4C10 2.9 10.9 2 12 2S14 2.9 14 4C14 4.74 13.6 5.39 13 5.73V7H14C17.87 7 21 10.13 21 14H22C22.55 14 23 14.45 23 15M21 16H19V14C19 11.24 16.76 9 14 9H10C7.24 9 5 11.24 5 14V16H3V17H5V20H19V17H21V16Z" />
    </svg>
  );
};
export const DigitalAnalystVNavRoute = () => {
  return (
    <VerticalNavItem
      name="Digital Analyst"
      customIcon={<RobotIcon />}
      shouldUseWindowToNavigate
      route="https://ai.tornado.com"
    />
  );
};

export const PortfolioPageVNavRoute = () => {
  return <Analysis />;
};
export const TradePageVNavRoute = () => {
  return <VerticalNavItem name="Trade" customIcon={<ExchangeIcon />} route="/orders" />;
};
export const FundingPageVNavRoute = () => {
  const currentUser = useCurrentUser();
  const inShowFundingBonusBadge = currentUser.show_red_dot_funding_page_when_bonus_test_group === 1;
  const { isFundingBonusEligible } = usePromotions();
  const [filter] = React.useState({ isVerificationReady: true });
  const verifyReadyBankAccounts = useBankAccounts(filter);
  const fundingBadgeCount =
    verifyReadyBankAccounts.length > 0 || (inShowFundingBonusBadge && isFundingBonusEligible) ? 0 : null;

  return (
    <VerticalNavItem name={'Funding'} customIcon={<DollarIcon />} route="/funding" badgeCount={fundingBadgeCount} />
  );
};
export const RewardsPageVNavRoute = () => {
  return <Rewards />;
};
