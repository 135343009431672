import React from 'react';
import { connect } from 'react-redux';
import { closeRebalancerPanel } from '../../actions';

import AnimatedOverlay from '../../components/layout/AnimatedOverlay';
import RebalancerContainer from './RebalancerContainer';
import { parseQueryString } from '../../helpers/routerHelpers';
import { withRouter } from '../../main/utils';

class RebalancerPanel extends React.PureComponent {
  render() {
    const { showPanel } = this.props;

    return (
      <div className={`optimizer-panel-container ${!showPanel ? 'hidden' : ''}`}>
        <AnimatedOverlay showOverlay={showPanel} handleClick={this.handleClose} fixedCenter>
          <div
            className={`panel-container fade-in-up alternate-panel-bg-color ${
              this.shouldLockScroll() ? 'lock-scrolling' : ''
            }`}
          >
            <RebalancerContainer inPanel />
          </div>
        </AnimatedOverlay>
      </div>
    );
  }

  handleClose = () => {
    closeRebalancerPanel()(this.props.dispatch);
    this._removeAutoExpandFromUrl();
  };

  _removeAutoExpandFromUrl = () => {
    const navigate = this.props.navigate;
    const location = this.props.location;
    const queryString = location.search;
    const query = parseQueryString(queryString);

    const removeKeyFromObjectIfValue = (keyToRemove, value, obj) => {
      const keys = Object.keys(query);
      const filteredKeys = keys.filter((key) => key !== keyToRemove && value !== obj[key]);
      const filteredObject = {};
      filteredKeys.forEach((key) => {
        filteredObject[key] = obj[key];
      });
      return filteredObject;
    };

    const filteredQuery = removeKeyFromObjectIfValue('auto_expand', 'rebalancer', query);
    navigate(filteredQuery);
  };

  shouldLockScroll = () => {
    const { isLoading, isRebalancerRunning } = this.props;
    return isRebalancerRunning && !isLoading;
  };
}

const mapStateToProps = (state) => {
  return {
    showPanel: state.rebalancer.showPanel,
    isLoading: state.rebalancer.loading,
    isRebalancerRunning: state.rebalancer.isRebalancerRunning,
  };
};

const connectedComponent = connect(mapStateToProps)(RebalancerPanel);
export default withRouter(connectedComponent);
