import React from 'react';

import UserContainer from './UserContainer';
import ProfileAvatar from '../../components/user/ProfileAvatar';
import UserName from '../../components/user/UserName';

export const BasicUserList = (props) => {
  const { userIds, getLinkFromUserId, profileLinkQuery, additionalListItem } = props;

  return (
    <div className={`basic-users-list-container`}>
      <ul>
        {userIds.map((id) => (
          <li key={id}>
            <UserContainer userId={id}>
              <ProfileAvatar
                profileLinkQuery={profileLinkQuery}
                {...(getLinkFromUserId ? { linkTo: getLinkFromUserId(id) } : {})}
              />
              <UserName
                profileLinkQuery={profileLinkQuery}
                {...(getLinkFromUserId ? { linkTo: getLinkFromUserId(id) } : {})}
              />
            </UserContainer>
          </li>
        ))}
        {additionalListItem}
      </ul>
    </div>
  );
};
