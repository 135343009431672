import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${({ theme, isLong }) => (isLong ? theme.themeColors.positive : theme.themeColors.negative)};
`;
export const CommunityPositionMeter = (props) => {
  const { position, community_position } = props;

  return (
    <Wrapper
      isLong={position.toLowerCase() === 'long'}
      className={`community-position-meter ${position}`}
      style={{ width: `${community_position}` }}
    ></Wrapper>
  );
};

export default CommunityPositionMeter;
