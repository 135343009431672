import React from 'react';

import LoadingIcon from '../../components/misc/LoadingIcon';

import { snakeToHyphens } from '../../helpers/generalHelpers';
import { formatSecurityDataValue } from '../../helpers/securitiesHelpers';
import { convertFieldNameToMetricsTrackingEventProperty } from '../../helpers/trackingHelpers';

import BlankCell from './cells/BlankCells/BlankCell';
import OptimizerBlankCell from './cells/BlankCells/OptimizerBlankCell';
import CashCell from './cells/PortfolioCells/CashCell';

import SecurityIdentifierCell from './cells/IdentifierCells/SecurityIdentifierCell';
import SecurityIdentifierWithCurrentPriceCell from './cells/IdentifierCells/SecurityIdentifierWithCurrentPriceCell';
import SecurityIdentifierWithIdeaTypeCell from './cells/IdentifierCells/SecurityIdentifierWithIdeaTypeCell';
import SecurityIdentifierSymbolCell from './cells/IdentifierCells/SecurityIdentifierSymbolCell';
import SecurityIdentifierWithPriceNoChangeCell from './cells/IdentifierCells/SecurityIdentifierWithPriceNoChangeCell';
import SecurityIdentifierWithIdeaTypeCollective from './cells/IdentifierCells/SecurityIdentifierWithIdeaTypeCollective';

import PortfolioCashCell from './cells/PortfolioCells/PortfolioCashCell';
import OptimizerCashCell from './cells/PortfolioCells/OptimizerCashCell';
import PortfolioIdeaCell from './cells/PortfolioCells/PortfolioIdeaCell';
import PortfolioAllocationCell from './cells/PortfolioCells/PortfolioAllocationCell';
import PortfolioAllocationAndDayChangeCell from './cells/PortfolioCells/PortfolioAllocationAndDayChangeCell';
import OptimizerToggleCell from './cells/OptimizerCells/OptimizerToggleCell';
import OptimizedAllocationWithTradeCell from './cells/OptimizerCells/OptimizedAllocationWithTradeCell';
import TradeAllOptimizedOrdersCell from './cells/OptimizerCells/TradeAllOptimizedOrdersCell';

import IdeaTypeCell from './cells/IdeaCells/IdeaTypeCell';
import IdeaBuySellCell from './cells/IdeaCells/IdeaBuySellCell';
import AskWhyCell from './cells/IdeaCells/AskWhyCell';
import ViewIdeaCell from './cells/IdeaCells/ViewIdeaCell';
import IdeaDayAndTotalChangeCell from './cells/IdeaCells/IdeaDayAndTotalChangeCell';
import IdeaPerformanceCell from './cells/IdeaCells/IdeaPerformanceCell';
import CollectiveUserIdeaPerformanceCell from './cells/IdeaCells/CollectiveUserIdeaPerformanceCell';
import ThoughtLeaderIdeaDataCell from './cells/IdeaCells/ThoughtLeaderIdeaDataCell';
import CollectiveUserIdeaDataCell from './cells/IdeaCells/CollectiveUserIdeaDataCell';
import ExpectedReturnCell from './cells/IdeaCells/ExpectedReturnCell';
import RemoveIdeaCell from './cells/IdeaCells/RemoveIdeaCell';
import TradeCell from './cells/PortfolioCells/TradeCell';

import ProfileSecurityAllocationCell from './cells/AllocationCells/ProfileSecurityAllocationCell';
import SecurityAllocationCell from './cells/AllocationCells/SecurityAllocationCell';
import PositionAllocationPercentageCell from './cells/AllocationCells/PositionAllocationPercentageCell';

import ExternalPositionDayChangePercentCell from './cells/ExternalPositionsCells/ExternalPositionDayChangePercentCell';
import ExternalPositionTotalChangePercentCell from './cells/ExternalPositionsCells/ExternalPositionTotalChangePercentCell';
import ExternalPositionAmountCell from './cells/ExternalPositionsCells/ExternalPositionAmountCell';
import ExternalPositionsSecurityIdentifierCell from './cells/ExternalPositionsCells/ExternalPositionsSecurityIdentifierCell';

import CurrentPriceCell from './cells/PricingCells/CurrentPriceCell';
import { logMetricsTrackingEvent } from '../../actions/trackingActions';
import { throwError } from '../../helpers/devToolHelpers';
import { withRouter } from '../../main/utils';

const TableCell = (props) => {
  const _returnLookupTable = () => ({
    blank: BlankCell,
    optimizer_blank: OptimizerBlankCell,
    cash_cell: CashCell,
    identifier: SecurityIdentifierCell,
    identifier_symbol: SecurityIdentifierSymbolCell,
    identifier_with_current_price: SecurityIdentifierWithCurrentPriceCell,
    identifier_with_current_price_no_change: SecurityIdentifierWithPriceNoChangeCell,
    identifier_with_idea_type: SecurityIdentifierWithIdeaTypeCell,
    portfolio_cash: PortfolioCashCell,
    optimizer_cash: OptimizerCashCell,
    portfolio_idea_cell: PortfolioIdeaCell,
    portfolio_allocation: PortfolioAllocationCell,
    trade_all_optimized_orders: TradeAllOptimizedOrdersCell,
    allocation_and_day_change: PortfolioAllocationAndDayChangeCell,
    trade: TradeCell,
    optimized_allocation_with_trade: OptimizedAllocationWithTradeCell,
    idea_performance_cell: IdeaPerformanceCell,
    identifier_with_idea_type_collective: SecurityIdentifierWithIdeaTypeCollective,
    collective_user_idea_performance_cell: CollectiveUserIdeaPerformanceCell,
    idea_buy_sell: IdeaBuySellCell,
    view_idea: ViewIdeaCell,
    idea_day_and_total_change: IdeaDayAndTotalChangeCell,
    external_position_identifier: ExternalPositionsSecurityIdentifierCell,
    external_position_amount_cell: ExternalPositionAmountCell,
    external_position_day_change_percentage_cell: ExternalPositionDayChangePercentCell,
    external_position_total_change_percentage_cell: ExternalPositionTotalChangePercentCell,
    idea_type: IdeaTypeCell,
    ask_why: AskWhyCell,
    allocation: SecurityAllocationCell,
    profile_security_allocation_cell: ProfileSecurityAllocationCell,
    position_allocation_percentage_cell: PositionAllocationPercentageCell,
    thought_leader_data_cell: ThoughtLeaderIdeaDataCell,
    collective_user_data_cell: CollectiveUserIdeaDataCell,
    current_price: CurrentPriceCell,
    expected_return: ExpectedReturnCell,
    active: OptimizerToggleCell,
    remove_idea: RemoveIdeaCell,
  });

  const _returnCellType = () => {
    const cell = _returnLookupTable()[_returnColumnKeyName()];
    if (!cell) {
      throwError(`Missing cell lookup in TableCell.js for ${_returnColumnKeyName()}`, true);
    }
    return cell;
  };

  const _returnColumnKeyName = () => props.column.keyName;

  const _renderCell = () => {
    const cellType = _returnCellType();
    const cellProps = {
      loadingCellComponent: <LoadingIcon icon="fading-3balls" size="small" style={{ marginRight: '8px' }} />,
      logSecurityPanelOpenClick: _logSecurityPanelOpenClick,

      navigate: props.navigate,
      location: props.location,
      ...props,
    };

    return React.createElement(cellType, cellProps);
  };

  const _renderDefaultCell = () => {
    const { concernable } = props;
    const columnKeyName = _returnColumnKeyName();
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {formatSecurityDataValue(concernable[columnKeyName], columnKeyName)}
      </td>
    );
  };

  const _hasCustomCellRender = () => {
    const columnKeyName = _returnColumnKeyName();
    return _returnLookupTable()[columnKeyName] || null;
  };

  const _logSecurityPanelOpenClick = (additionalProperties) => {
    const cell = _returnColumnKeyName();
    const trackingCell = convertFieldNameToMetricsTrackingEventProperty(cell);

    const event = 'Open Security Panel';
    const properties = {
      Context: 'Security Table',
      'Table Cell': trackingCell,
      ...additionalProperties,
    };
    logMetricsTrackingEvent(event, properties);
  };

  return _hasCustomCellRender() ? _renderCell() : _renderDefaultCell();
};

export default withRouter(TableCell);
