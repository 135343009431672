import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export * from './calculations';
export * from './funding';
export * from './inputValidation';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    // mocking history namespace for now
    return <Component navigate={navigate} location={location} {...props} />;
  };

  return Wrapper;
};

export const withLocation = (Component) => {
  const Wrapper = (props) => {
    const location = useLocation();
    return <Component location={location} {...props} />;
  };

  return Wrapper;
};
