import React from 'react';
import { Page } from '@src/main/components/layout';
import { Container, Spacing } from '@src/main/components/ui';
import { Body5 } from '@src/main/lib/nvstr-common-ui.es';
import styled from 'styled-components';
import { FlatButton, SkeletonButton } from '@src/main/components/buttons';
import { SECURITY_FILTER_TAG } from '@src/main/classes/SecurityFilterTag';
import { useColorTheme } from '@src/main/hooks/util';
import { SECURITY_FILTER_TYPES } from '@src/main/constants/securityFilter';
import { useDispatch } from 'react-redux';
import {
  fetchSecuritiesPriceData,
  getStocksYouMayBeInterestedIn,
  getUpcomingEarningsAnnouncements,
  quickFetchSecuritiesData,
} from '@src/actions';
import SecurityList, { SECURITY_LIST_CONTEXTS } from '@src/main/containers/Securities/SecurityList';
import { TrackingEvents } from '@src/utils/tracking/events';
import SecurityFilterApiAdapter from '@src/main/classes/SecurityFilterApiAdapter';
import { useNavigate } from 'react-router';
import { STATIC_ROUTES } from '@src/constants/paths';
import { LivePriceTracking } from '../Managers/LivePriceTracking';

const FilterTagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border-radius: 8px;
  height: 40px;
  padding: 0 12px;

  background-color: transparent;
  * {
    color: ${({ theme }) => theme.themeColors.text};
  }

  svg {
    height: 18px;
    width: 18px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }

  :hover {
    opacity: 0.9;
    cursor: pointer;
  }
`;

const FilterGroupScrollContainer = styled.div`
  overflow: scroll;
  padding-top: 16px;

  width: 100%;
`;

const FilterTagGroup = styled.div`
  flex: 0;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 170px;
  min-width: 665px;
`;

const filterTagRows = [
  [
    // SECURITY_FILTER_TAG.UPCOMING_EARNINGS,
    SECURITY_FILTER_TAG.TRENDING_STOCKS,
    SECURITY_FILTER_TAG.FAMOUS_INVESTORS,
    SECURITY_FILTER_TAG.FRIENDS_IDEAS,
  ],
  [
    SECURITY_FILTER_TAG.TECH_SECTOR,
    SECURITY_FILTER_TAG.FINANCIAL_SECTOR,
    SECURITY_FILTER_TAG.HEALTHCARE_SECTOR,
    SECURITY_FILTER_TAG.ENERGY_SECTOR,
  ],
  [SECURITY_FILTER_TAG.JUICY_DIVIDENDS, SECURITY_FILTER_TAG.GROWTH_STOCKS, SECURITY_FILTER_TAG.VALUE_STOCKS],
];

const FormLabel = ({ children }) => {
  return <div>{children}</div>;
};

const FilterTag = ({ filter, Icon, iconProps, onPress }) => {
  const handlePress = () => {
    return onPress(filter);
  };

  return (
    <FilterTagWrapper onClick={handlePress} Color={iconProps.color}>
      <Icon {...iconProps} />
      <Spacing left={8}>
        <Body5>{filter.displayText}</Body5>
      </Spacing>
    </FilterTagWrapper>
  );
};

const Tag = (props) => {
  const { filter, iconProps: _props_iconProps, setActiveFilter } = props;
  const colorTheme = useColorTheme();

  const handlePress = () => {
    TrackingEvents.security.PRESS_EXPLORE_PRESET_FILTER.send({
      Name: filter.eventName,
    });

    setActiveFilter(filter);
  };

  const size = filter.size || 14;
  const color = colorTheme[filter.iconColor] || colorTheme.text;

  const iconProps = {
    ...(_props_iconProps || {}),
    size,
    color,
  };

  return <FilterTag {...props} Icon={filter.icon} iconProps={iconProps} onPress={handlePress} />;
};

const FilteredListHeading = ({ activeFilter }) => {
  return (
    <Container>
      <FormLabel bold>{activeFilter.displayText}</FormLabel>
    </Container>
  );
};

const FilteredSecurityList = ({ activeFilter, context }) => {
  const dispatch = useDispatch();

  const [securityIds, setSecurityIds] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(
    () => {
      const handleFilterChange = async (activeFilter) => {
        switch (activeFilter.type) {
          case SECURITY_FILTER_TYPES.UPCOMING_EARNINGS: {
            setIsLoading(true);
            const {
              ok,
              securityIds,
              // securities,
            } = await getUpcomingEarningsAnnouncements()(dispatch);
            if (ok) {
              setIsLoading(false);
              setSecurityIds(securityIds);
            } else {
              setError('This list is unavailable at this time, please try again later');
            }
            break;
          }
          default: {
            setIsLoading(true);

            const adapter = new SecurityFilterApiAdapter({
              filters: activeFilter.presetFilter.filters,
              count: 20,
            });

            adapter.get(dispatch).then((response) => {
              const { ok, securityIds } = response;
              setIsLoading(false);
              if (ok) {
                setSecurityIds(securityIds);
                quickFetchSecuritiesData(securityIds)(dispatch);
                fetchSecuritiesPriceData(securityIds)(dispatch);
              } else {
                setError('This list is unavailable at this time, please try again later');
              }
            });
            break;
          }
        }
      };
      handleFilterChange(activeFilter);
    },
    [activeFilter]
  );

  if (error) {
    return (
      <Container>
        <FilteredListHeading activeFilter={activeFilter} />
        <Container top={16}>{error}</Container>
      </Container>
    );
  }

  return (
    <Container>
      <FilteredListHeading activeFilter={activeFilter} />
      <Container top={16}>
        <LivePriceTracking securityIds={securityIds} />
        <SecurityList isLoading={isLoading} securityIds={securityIds} context={context} />
      </Container>
    </Container>
  );
};

const StocksYouMayBeInterestedIn = () => {
  const dispatch = useDispatch();

  const [isStocksYouMayBeInterestedInLoading, setIsStocksYouMayBeInterestedInLoading] = React.useState(true);
  const [stocksYouMayBeInterestedInSecurityIds, setStocksYouMayBeInterestedInSecurityIds] = React.useState(null);
  const wasError = !isStocksYouMayBeInterestedInLoading && stocksYouMayBeInterestedInSecurityIds === null;

  const _getStocksYouMayBeInterestedIn = async () => {
    const response = await getStocksYouMayBeInterestedIn()(dispatch);
    const { ok, securityIds } = response;

    if (ok) {
      setStocksYouMayBeInterestedInSecurityIds(securityIds);

      quickFetchSecuritiesData(securityIds)(dispatch);
      fetchSecuritiesPriceData(securityIds)(dispatch);
    }

    setIsStocksYouMayBeInterestedInLoading(false);
  };

  React.useEffect(() => {
    _getStocksYouMayBeInterestedIn();
  }, []);

  const handleTryAgainPress = () => {
    setIsStocksYouMayBeInterestedInLoading(true);
    getStocksYouMayBeInterestedIn();
  };

  return (
    <Container>
      <Container>
        <FormLabel bold>Stocks you may be interested in</FormLabel>
      </Container>
      {wasError ? (
        <Container top={36} textCenter>
          <Body5>Something went wrong.</Body5>
          <Container vertical={16}>
            <FlatButton onClick={handleTryAgainPress}>Try again</FlatButton>
          </Container>
        </Container>
      ) : (
        <Container top={12}>
          <LivePriceTracking securityIds={stocksYouMayBeInterestedInSecurityIds} />
          <SecurityList
            isLoading={isStocksYouMayBeInterestedInLoading}
            securityIds={stocksYouMayBeInterestedInSecurityIds}
            context={SECURITY_LIST_CONTEXTS.STOCKS_YOU_MAY_BE_INTERESTED_IN}
          />
        </Container>
      )}
    </Container>
  );
};

export const Explore = () => {
  const navigate = useNavigate();
  const [activeFilter, setActiveFilter] = React.useState(null);

  const handleSeePopularStocksClick = () => {
    navigate(STATIC_ROUTES.popularStocks);
  };

  return (
    <Page transparentBackground>
      <Container top={16}>
        <Container>
          <FormLabel bold>Popular Filters</FormLabel>
        </Container>
        <FilterGroupScrollContainer>
          <FilterTagGroup>
            {filterTagRows.map((row, i) => (
              <Container
                key={`row-${i}`}
                row
                style={{
                  flexWrap: 'wrap',
                  alignItems: 'flex-start',
                }}
              >
                {row.map((f, i) => (
                  <Container key={i} bottom={16} right={16}>
                    <Tag filter={f} setActiveFilter={setActiveFilter} />
                  </Container>
                ))}
              </Container>
            ))}
          </FilterTagGroup>
        </FilterGroupScrollContainer>
      </Container>
      <Container top={16}>
        {activeFilter === null ? (
          <StocksYouMayBeInterestedIn />
        ) : (
          <FilteredSecurityList
            key={activeFilter.type}
            activeFilter={activeFilter}
            context={SECURITY_LIST_CONTEXTS.EXPLORE_PRESET_FILTER}
          />
        )}
      </Container>
      <Container top={24} row verticallyCentered>
        <SkeletonButton onClick={handleSeePopularStocksClick} transparent>
          See Popular Stocks
        </SkeletonButton>
      </Container>
    </Page>
  );
};
