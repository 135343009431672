import React from 'react';
import MoneyNumber from '../../../UI/DataTypes/MoneyNumber';

const TransactionAmount = (props) => {
  const { className, isVoid, value } = props;
  return (
    <span className={`transaction-amount ${isVoid ? 'transaction-voided' : ''}`}>
      <MoneyNumber className={``} value={value} />
    </span>
  );
};

export default TransactionAmount;
