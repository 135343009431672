import React from 'react';
import { useSelector } from 'react-redux';
import FundingBonusBanner from './FundingBonusBanner';
import LimitedTimeDepositBonusBanner from './LimitedTimeDepositBonusBanner';
import {
  getAvailablePromotionsFromState,
  getLimitedTimeDepositBonusRewardFromAvailablePromotions,
} from '../../selectors/currentUser';
import {
  isCurrentUserEligibleForFundingMatchReward,
  isCurrentUserEligibleForFundingReward,
} from '../../helpers/currentUserHelpers';
import { useCurrentUser } from '../../main/hooks/user';

const FundingBonusBannerRenderer = ({ containerStyle }) => {
  const currentUser = useCurrentUser();
  const availablePromotions = useSelector((state) => getAvailablePromotionsFromState(state));

  const isFundingBonusEligible = isCurrentUserEligibleForFundingReward(currentUser);
  const limitedTimeDepositBonusPromo = getLimitedTimeDepositBonusRewardFromAvailablePromotions(availablePromotions);
  const isFundingMatchBonusEligible = isCurrentUserEligibleForFundingMatchReward(currentUser);
  const isDepositBonusPromoEligible = !!limitedTimeDepositBonusPromo;

  if (!isFundingBonusEligible) return null;

  if (isDepositBonusPromoEligible) {
    return (
      <div style={containerStyle}>
        <LimitedTimeDepositBonusBanner />
      </div>
    );
  } else {
    return <div style={containerStyle}>{isFundingMatchBonusEligible && <FundingBonusBanner />}</div>;
  }
};

FundingBonusBannerRenderer.defaultProps = {
  containerStyle: {},
};

export default FundingBonusBannerRenderer;
