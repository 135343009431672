import React from 'react';
import { logMetricsTrackingEvent } from '../../actions/trackingActions';
import { throwError } from '../../helpers/devToolHelpers';

class FireEventOnMount extends React.Component {
  componentDidMount() {
    this._fireEvent();
    this._runAdditionalAction();
  }

  render() {
    return null;
  }

  _fireEvent = () => {
    const event = this.props.eventName;
    const properties = this.props.eventProperties || {};
    if (!event) {
      throwError('Must supply eventName prop to FireEventOnMount', true);
    }
    logMetricsTrackingEvent(event, properties)();
  };

  _runAdditionalAction = () => {
    this.props.additionalAction && this.props.additionalAction();
  };
}

export default FireEventOnMount;
