import { documentsAdapter } from '../adapters/documentsAdapter';

export const getDocuments = (formData) => {
  return function (dispatch) {
    return documentsAdapter.getDocuments(formData).then((response) => {
      if (response && response.data) {
        return response.data;
      } else {
        return { error: 'Something went wrong, try again.' };
      }
    });
  };
};
