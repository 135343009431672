import React from 'react';
import withWindowSize from '../HOCS/withWindowSize';

/******************
  <GenericImpressionTracker
    id -> required
    scrollContainer -> selector for scroll container to listen to for scroll view impression based tracking
    containerElementSelector -> selector for most parent element for view/ display tracking

    wasAlreadyRecorded -> func
    recordImpression -> func
    saveImpressionToStorage -> func

    recordImpressionOnMount -> bool -> will only record an impression on mount
  />
*******************/

export class GenericImpressionTracker extends React.Component {
  componentDidMount() {
    this._initialize();
  }

  componentWillUnmount() {
    if (!this._isTerminated) {
      this._terminate();
    }
  }

  render() {
    return <div ref={(el) => (this.GenericImpressionTracker = el)}></div>;
  }

  returnId = () => this.props.id;

  returnContainerElementSelector = () => this.props.containerElementSelector;

  _checkForImpression = () => {
    if (!this._wasImpressionRecorded() && this._isItemVisibleWithinWindow()) {
      this._handleRecordImpression();
    }
  };

  _isItemVisibleWithinWindow = () => {
    const rect = this._getThoughtBoundingClientRect();
    if (rect) {
      const distanceThoughtMidPointFromTopOfWindow = rect.top + rect.height / 2;
      const wasScrolledPast = rect.top + rect.height < 0;
      const isAboveBottomOfWindow = distanceThoughtMidPointFromTopOfWindow < this._returnWindowHeight();
      return isAboveBottomOfWindow && !wasScrolledPast;
    } else {
      return false;
    }
  };

  _wasImpressionRecorded = () => this.props.wasAlreadyRecorded();

  _saveImpressionToStorageIfUnrecorded = () => this.props.saveImpressionToStorage();

  _postImpressionToServer = () => this.props.recordImpression();

  _handleRecordImpression = () => {
    if (this._wasImpressionRecorded() || this._isRecordingImpression) {
      return false;
    }
    this._isRecordingImpression = true; // stops attempts at recording more than once, but if request fails has the flexibility to send again
    this._postImpressionToServer().then((response) => {
      if (response && response.data && response.data.status === 'success') {
        this._saveImpressionToStorageIfUnrecorded();
        this._isTerminated = true;
        this._terminate(); // stop listening to scroll events after impression is tracked
      } else {
        this._isRecordingImpression = false;
      }
    });
  };

  _initialize = () => {
    if (this.props.recordImpressionOnMount) {
      this._handleRecordImpression();
    } else {
      this._initScrollListener();
      this._checkForImpression();
    }
  };

  _terminate = () => {
    this._removeScrollListener();
  };

  _handleScroll = (e) => {
    this._checkForImpression(e);
  };

  _getThoughtBoundingClientRect = () => {
    const el = this._$returnContainerElement();
    return el && el[0] ? el[0].getBoundingClientRect() : null;
  };

  _$returnContainerElement = () => $(this._returnDOMNode()).closest(this.returnContainerElementSelector());

  _returnDOMNode = () => this.GenericImpressionTracker;

  _initScrollListener = () => {
    this._$returnScrollElement().on('scroll', this._handleScroll);
  };

  _returnWindowHeight = () => this.props.windowHeight || $(window).height();

  _removeScrollListener = () => {
    this._$returnScrollElement().off('scroll', this._handleScroll);
  };

  _$returnScrollElement = () => $(this.props.scrollContainerSelector);
}

export default withWindowSize(GenericImpressionTracker);
