import React from 'react';
import styled from 'styled-components';
import { BaseButton, BaseButtonWrapper } from '.';
import { enums } from '../../lib/nvstr-utils.es';
import { ELEMENT_COLOR_NAMES } from '@src/main/constants/elementColors';

export const ButtonWrap = styled.div`
  ${({ fullWidth }) => (fullWidth ? `width: 100%; max-width: 380px;` : '')};

  ${BaseButtonWrapper} {
    ${({ fullWidth }) => (fullWidth ? `width: 100%; max-width: 380px;` : '')};
    background: none;
    border: ${({ theme }) => '1px solid ' + theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.primaryCtaButton};
    fill: ${({ theme }) => theme.themeColors.primaryCtaButton};

    ${({ small, smallNoVert }) => (smallNoVert ? `padding: 0px 8px` : small ? `padding: 4px 8px` : '')};

    * {
      color: ${({ theme }) => theme.themeColors.primaryCtaButton};
      fill: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }

    :hover {
      background: ${({ theme }) => theme.themeColors.primaryCtaButton};
      border: ${({ theme }) => '1px solid ' + theme.themeColors.primaryCtaButton};
      color: ${({ theme }) => theme.themeColors.buttonText};
      fill: ${({ theme }) => theme.themeColors.buttonText};

      * {
        color: ${({ theme }) => theme.themeColors.buttonText};
        fill: ${({ theme }) => theme.themeColors.buttonText};
      }
    }
  }
`;
export const StaticColorButtonWrap = styled.div`
  ${({ fullWidth }) => (fullWidth ? `width: 100%; max-width: 380px;` : '')};

  ${BaseButtonWrapper} {
    ${({ fullWidth }) => (fullWidth ? `width: 100%; max-width: 380px;` : '')};
    background: none;
    color: ${({ color }) => color};
    border: 1px solid ${({ color }) => color};

    ${({ small, smallNoVert }) => (smallNoVert ? `padding: 0px 8px` : small ? `padding: 4px 8px` : '')};

    * {
      color: ${({ textColor }) => textColor};
      fill: ${({ textColor }) => textColor};
    }

    :hover {
      background: ${({ color }) => color};
      border: 1px solid ${({ color }) => color};
      color: ${({ hoverTextColor }) => hoverTextColor};

      * {
        color: ${({ hoverTextColor }) => hoverTextColor};
        fill: ${({ hoverTextColor }) => hoverTextColor};
      }
    }
  }
`;
export const SecondaryButtonWrap = styled.div`
  ${BaseButtonWrapper} {
    ${({ fullWidth }) => (fullWidth ? `width: 100%; max-width: 380px;` : '')};
    background: none;
    color: ${({ theme }) => theme.colorTheme[ELEMENT_COLOR_NAMES.primaryTextColor]};
    fill: ${({ theme }) => theme.colorTheme[ELEMENT_COLOR_NAMES.primaryTextColor]};
    border: 1px solid ${({ theme }) => theme.colorTheme[ELEMENT_COLOR_NAMES.primaryTextColor]};

    ${({ small, smallNoVert }) => (smallNoVert ? `padding: 0px 8px` : small ? `padding: 4px 8px` : '')};

    * {
      color: ${({ theme }) => theme.colorTheme[ELEMENT_COLOR_NAMES.primaryTextColor]};
      fill: ${({ theme }) => theme.colorTheme[ELEMENT_COLOR_NAMES.primaryTextColor]};
    }

    :hover {
      background: ${({ theme }) => theme.colorTheme[ELEMENT_COLOR_NAMES.primaryTextColor]};
      color: ${({ theme }) => theme.themeColors.buttonText};
      fill: ${({ theme }) => theme.themeColors.buttonText};
      border: 1px solid ${({ theme }) => theme.colorTheme[ELEMENT_COLOR_NAMES.primaryTextColor]};
      opacity: 0.8;

      * {
        color: ${({ theme }) => theme.themeColors.buttonText};
        fill: ${({ theme }) => theme.themeColors.buttonText};
      }
    }
  }
`;
export const DisabledButtonWrap = styled.div`
  ${BaseButtonWrapper} {
    ${({ fullWidth }) => (fullWidth ? `width: 100%; max-width: 380px;` : '')}
    background: none;
    border: ${({ theme }) => '1px solid ' + theme.themeColors.primaryCtaButton};
    color: ${({ theme, color }) => (color ? color : theme.themeColors.primaryCtaButton)};

    ${({ small, smallNoVert }) => (smallNoVert ? `padding: 0px 8px` : small ? `padding: 4px 8px` : '')};
    
    * {
      color: ${({ theme, color }) => (color ? color : theme.themeColors.primaryCtaButton)};
    }

      opacity: 0.40;
    cursor: not-allowed;
  }

    :hover {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
`;

export const SkeletonButton = (props) => {
  const {
    fullWidth,
    onClick,
    children,
    buttonProps,
    secondary,
    disabled,
    color,
    textColor,
    hoverTextColor,
    small,
  } = props;

  if (disabled) {
    return (
      <DisabledButtonWrap fullWidth={fullWidth} small={small}>
        <BaseButton type="button" buttonType={enums.button_types.button} {...buttonProps} onClick={onClick}>
          <b>{children}</b>
        </BaseButton>
      </DisabledButtonWrap>
    );
  }
  if (secondary) {
    return (
      <SecondaryButtonWrap fullWidth={fullWidth} small={small}>
        <BaseButton type="button" buttonType={enums.button_types.button} {...buttonProps} onClick={onClick}>
          <b>{children}</b>
        </BaseButton>
      </SecondaryButtonWrap>
    );
  }
  if (color) {
    return (
      <StaticColorButtonWrap fullWidth={fullWidth} small={small} color={color} hoverTextColor={hoverTextColor}>
        <BaseButton
          type="button"
          buttonType={enums.button_types.button}
          fullWidth={fullWidth}
          {...buttonProps}
          onClick={onClick}
        >
          <b>{children}</b>
        </BaseButton>
      </StaticColorButtonWrap>
    );
  }
  return (
    <ButtonWrap fullWidth={fullWidth} small={small}>
      <BaseButton type="button" buttonType={enums.button_types.button} {...buttonProps} onClick={onClick}>
        <b>{children}</b>
      </BaseButton>
    </ButtonWrap>
  );
};

SkeletonButton.defaultProps = {
  buttonProps: {},
};
