import React, { Component } from 'react';

class FullPageCard extends Component {
  render() {
    return (
      <div className={`full-page-card component-bg ${this.props.className || ''} ${this._showMobileCardStyling()}`}>
        {this.props.children}
      </div>
    );
  }

  _showMobileCardStyling = () => {
    return this.props.showMobileCardStyling ? 'mobile-page-card' : '';
  };
}

export default FullPageCard;
