import React from 'react';

const ChartUp = (props) => {
  const { className, id } = props;
  return (
    <svg
      {...(id ? { id } : {})}
      className={`chart-up-icon ${className || ''}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 228.34 165.37"
    >
      <title>chart-up</title>
      <g>
        <path d="M30.38,124.21a8.18,8.18,0,0,0,.91-.72L95,59.78c2.39-2.39,4.09-2.38,6.51,0q12.14,12.12,24.27,24.27c.31.31.57.66.78.9l51.76-51.76L176.07,31,159.72,14.65c-1.45-1.46-1.72-2.7-1-4.32a3.42,3.42,0,0,1,3.46-2.06q18.24,0,36.48,0h11.4c2.89,0,4.13,1.25,4.13,4.18q0,10.92,0,21.84,0,12.78,0,25.56a3.76,3.76,0,0,1-1.61,3.53,3.51,3.51,0,0,1-4.76-.63c-4.11-4.09-13.21-13.19-17.31-17.3a12.17,12.17,0,0,1-.87-1.13c-.52.48-.86.79-1.19,1.12l-57.89,57.89-.85.84c-2,2-3.87,2-5.84,0Q111.59,91.9,99.29,79.59a12.59,12.59,0,0,1-.85-1L41.59,135.41Z" />
        <path d="M8.89,157.1H228.34v8.27H0V0H8.89Z" />
      </g>
    </svg>
  );
};

export default ChartUp;
