import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/index';
import GenericInputField from '../../components/form/GenericInputField';
import Slider from '@src/components/Slider/StyledSlider';
import LoadingIcon from '../../components/misc/LoadingIcon';
import Icon from '../../components/misc/Icon';
import { isUndefinedOrNull, toCapitalCase } from '../../helpers/generalHelpers';
import { createInputComponent } from '../../helpers/formHelpers';
import { customParseFloat } from '../../helpers/numberHelpers';
import { buildConfirmationComponent } from '../../helpers/uiHelpers';
import { DISPLAY_FORMAT_TYPES, formatValueTo } from '../../main/utils/numbers';
import { usePriceData } from '../../main/hooks/securities/usePriceData';

// Allocation Constraint Obj Data Structure
// :id => optimize_constraint.id,
// :user_id => optimize_constraint.user_id,
// :security_id => optimize_constraint.security_id,
// :min_shares => optimize_constraint.min,
// :max_shares => optimize_constraint.max

function CurrentPrice({ securityId }) {
  const { price } = usePriceData(securityId);
  return <div className={`optimize-constraint-current-price`}>{formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, price)}</div>;
}

class AllocationConstraint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: [
        {
          type: GenericInputField,
          typeName: 'GenericInputField',
          name: 'min_shares',
          label: 'Min',
          props: {
            displayValueIfNoValueAndUnfocused: 'None',
            hidePrefixSuffixIfNoValue: true,
            hideErrors: true,
            suffix: 'shs',
            labelAboveField: true,
            handleChangeFunc: 'handleChange',
            afterChangeFunc: this._afterTextInputChange,
            additionalBlurAction: this._handleAllocationConstraintInputBlur,
          },
        },
        {
          type: GenericInputField,
          typeName: 'GenericInputField',
          name: 'max_shares',
          label: 'Max',
          props: {
            displayValueIfNoValueAndUnfocused: 'None',
            hidePrefixSuffixIfNoValue: true,
            hideErrors: true,
            suffix: 'shs',
            labelAboveField: true,
            handleChangeFunc: 'handleChange',
            afterChangeFunc: this._afterTextInputChange,
            additionalBlurAction: this._handleAllocationConstraintInputBlur,
          },
        },
      ],
      formData: {},
      errors: {},
      warnings: {},
      warningTimeout: null,
    };
  }

  componentDidMount() {
    // if no security data avail get the data needed for the constraint
    const securityId = this.props.security_id;
    if (isUndefinedOrNull(this.props.symbol) || isUndefinedOrNull(this.props.name)) {
      this.props.actions.quickFetchSecuritiesData([securityId]);
    }
    this.props.actions.fetchSecuritiesPriceData([securityId]);

    this._setMinMaxSharesFromProps();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const newWarningRendered = !prevState.warning && this.state.warning;

    if (newWarningRendered) {
      this._setWarningTimeout();
    }

    if (newWarningRendered) {
      this._clearWarningTimeout();
      this._setWarningTimeout();
    }
  }

  componentWillUnmount(prevProps, prevState, snapshot) {
    this._clearWarningTimeout();
  }

  render() {
    // has a loading state that temp shows the name and stuff returned from the search query while it waits for official data from server
    return this._isLoading() ? this._renderLoadingState() : this._renderComponent();
  }

  _isLoading = () => {
    const { symbol, name } = this._returnSymbolAndName();
    const valuesToCheck = [symbol, name];
    return valuesToCheck.some((v) => isUndefinedOrNull(v)) || this._isInitialPriceLoading();
  };

  _renderLoadingState = () => {
    return (
      <div className={`optimize-constraint-container border-accent loading`}>
        <LoadingIcon icon="fading-3balls" size="small" />
      </div>
    );
  };

  _renderComponent = () => {
    return (
      <div className={`optimize-constraint-container border-accent`}>
        {this._renderDeleteAllocationConstraintButton()}

        <div className={`optimize-constraint`}>
          {this._renderStockIndicatorComponents()}
          {this._renderTextFields()}
        </div>

        {this._renderSlider()}

        {this._renderWarning()}
      </div>
    );
  };

  _returnCurrentUserEquity = () => {
    if (this.props.currentUserEquity === undefined) {
      console.error('Missing user equity');
    }
    return this.props.currentUserEquity;
  };

  _returnCurrentUserBuyingPower = () => {
    // TODO: add endpoint to get user buying power from;
    return this._returnCurrentUserEquity() * 1.25;
  };

  _setWarningTimeout = () => {
    const timeoutInterval = window.setTimeout(() => this._clearWarning(), 5000);
    this.setState((prevState) => ({
      warningTimeout: timeoutInterval,
    }));
  };

  _clearWarningTimeout = () => {
    this.setState((prevState) => ({
      warningTimeout: null,
    }));
  };

  _clearWarning = () => {
    this.setState((prevState) => ({
      warning: null,
    }));
  };

  _handleAllocationConstraintInputBlur = (inputName) => {
    const handleUpdate = () => {
      this._updateAllocationConstraint().then((response) => {
        window.setTimeout(() => {
          this.props.runOptimizer();
        }, 500);
      });
    };

    this._validateAndUpdateInputs(inputName, handleUpdate);
  };

  _afterTextInputChange = (newValue, inputName, oldValue) => {
    // does nothing for now
  };

  _validateAndUpdateInputs = (inputName, cb) => {
    // validate
    const value = this.state.formData[inputName];
    const { errors, validInputObj } = this._validateInputAndReturnErrorsAndClosestValidInput(value, inputName);
    const inputIsWithinMinMaxBounds = errors.length === 0;

    if (!inputIsWithinMinMaxBounds) {
      // if invalid, change to the max bound and set error
      this.setState(
        (prevState) => ({
          formData: {
            ...prevState.formData,
            ...validInputObj,
          },
          warning: errors[0],
        }),
        cb
      );
    } else {
      cb();
    }
  };

  _validateInputAndReturnErrorsAndClosestValidInput = (rawValue, inputName) => {
    const value = customParseFloat(rawValue) || 0;
    const minShares = isUndefinedOrNull(this.state.formData.min_shares)
      ? null
      : customParseFloat(this.state.formData.min_shares) || 0;
    const maxShares = isUndefinedOrNull(this.state.formData.max_shares)
      ? null
      : customParseFloat(this.state.formData.max_shares) || 0;
    const inputTypeValue = toCapitalCase(inputName.slice(0, 3));
    const userChangedMinValue = inputName.toLowerCase() === 'min_shares';
    const oppositeInput = userChangedMinValue ? 'max_shares' : 'min_shares';
    const oppositeValue = this.state.formData[oppositeInput];

    let errors = [];
    let validInputObj = {};

    const inputMaxBound = this._calculateBoundsForMinMaxAllocation()[inputName];
    const valueIsNegative = value < 0;

    const isValueWithinBounds = Math.abs(value) <= inputMaxBound;
    if (!isValueWithinBounds) {
      errors = [`${inputTypeValue} value for shares was greater than your buying power`];
      validInputObj = {
        [inputName]: valueIsNegative ? inputMaxBound * -1 : inputMaxBound,
      };
      if (userChangedMinValue && !isUndefinedOrNull(oppositeValue) && oppositeValue !== '') {
        validInputObj[oppositeInput] = valueIsNegative ? inputMaxBound * -1 : inputMaxBound;
      }
      return { errors, validInputObj };
    }

    // TODO: Better name - validation comparing min max
    const minOrMaxNegative = userChangedMinValue ? value < 0 || maxShares < 0 : value < 0 || minShares < 0;
    const isMinMaxValid =
      userChangedMinValue && !isUndefinedOrNull(oppositeValue) ? value < maxShares : value > minShares;
    if (!isMinMaxValid && !minOrMaxNegative && !isUndefinedOrNull(oppositeValue)) {
      const errorMsg = userChangedMinValue
        ? `${inputTypeValue} value for shares was greater than the Max value`
        : `${inputTypeValue} value for shares was less than the Min value`;
      errors = [errorMsg];
      validInputObj = {
        [oppositeInput]: value,
      };
      return { errors, validInputObj };
    }

    // TODO: Better name - validation for a negative min value
    // IMPORTANT: These validations are for text field if the min actually means minimum amount, not lower bound of range..
    // if ( minOrMaxNegative && !isUndefinedOrNull( oppositeValue ) ) {

    //   if ( userChangedMinValue && value < maxShares && maxShares < 0 ) {
    //     const errorMsg = 'Min value for short was greater than max'
    //     errors = [ errorMsg ];
    //     validInputObj = {
    //       [oppositeInput]: value
    //     };
    //     return { errors, validInputObj };
    //   }
    //   if ( !userChangedMinValue && value > minShares && minShares < 0 ) {
    //     const errorMsg = 'Max value for short was less than min'
    //     errors = [ errorMsg ];
    //     validInputObj = {
    //       [oppositeInput]: value
    //     };
    //     return { errors, validInputObj };
    //   }

    //   if ( minShares > 0 ) {
    //     const errorMsg = 'Min value for short was greater than max'
    //     errors = [ errorMsg ];
    //     validInputObj = {
    //       [oppositeInput]: value
    //     };
    //     return { errors, validInputObj };
    //   }

    //   if ( maxShares > 0 ) {
    //     const errorMsg = 'Invalid constraint, cannot mix short and long constraints'
    //     errors = [ errorMsg ];
    //     validInputObj = {
    //       [oppositeInput]: value
    //     };
    //     return { errors, validInputObj };
    //   }
    // }

    return { errors, validInputObj };
  };

  _returnSymbolAndName = () => {
    const securityId = this.props.security_id;
    const securitiesData = this.props.securities.lookup[securityId];

    const symbol = isUndefinedOrNull(this.props.symbol) ? securitiesData && securitiesData.symbol : this.props.symbol;
    const name = isUndefinedOrNull(this.props.name) ? securitiesData && securitiesData.name : this.props.name;
    return { symbol, name };
  };

  _calculateBoundsForMinMaxAllocation = () => {
    // used for validations
    const equity = this._returnCurrentUserEquity();
    const securityCurrentPrice = this._returnCurrentPriceForSecurity();
    const maxAllocation = Math.floor(equity / securityCurrentPrice);

    return {
      min_shares: maxAllocation === 0 ? maxAllocation : maxAllocation,
      max_shares: maxAllocation === 0 ? maxAllocation : maxAllocation,
    };
  };

  _setMinMaxSharesFromProps = () => {
    const { min_shares, max_shares } = this.props;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        min_shares: min_shares,
        max_shares: max_shares,
      },
    }));
  };

  _getValueForSlider = (inputName) => {
    const maxAllocationBound = this._calculateBoundsForMinMaxAllocation().max_shares;
    const min = maxAllocationBound * -1;
    const max = maxAllocationBound * 1;

    const data = this.state.formData;
    const rawValue = data[inputName];
    if (isUndefinedOrNull(rawValue) || rawValue === '' || rawValue === '-') {
      return inputName === 'min_shares' ? min : max;
    } else {
      return customParseFloat(rawValue);
    }
  };
  _handleSliderChange = (values) => {
    const min = values[0];
    const max = values[1];

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [`min_shares`]: min,
        [`max_shares`]: max,
      },
    }));
  };
  _handleAfterSliderChange = () => {
    this._updateAllocationConstraint();
    this.props.runOptimizer();
  };

  _updateAllocationConstraint = () => {
    // have to send :id, :min_shares, :max_shares, if min or max is missing, will get set to null
    const optimizeConstraint = {
      id: this.props.id,
      min_shares: this.state.formData.min_shares,
      max_shares: this.state.formData.max_shares,
    };

    return this.props.actions.updateOptimizerAllocationConstraints(optimizeConstraint).then((response) => {
      // TASK: TODO
      // handle failure
      const ajaxWasSuccessful = true;
      if (ajaxWasSuccessful) {
        return true;
      } else {
        return false;
      }
    });
  };

  _deleteAllocationConstraint = () => {
    const optimizeConstraintId = this.props.id;
    this.props.actions.deleteOptimizerAllocationConstraint(optimizeConstraintId).then((response) => {
      this.props.runOptimizer();
    });
  };

  _forceConfirmationOfDeleteAction = () => {
    this.setState((prevState) => ({
      showConfirmation: true,
    }));
  };

  // component rendering functions
  _renderConfirmationComponent = () => {
    const show = this.state.showConfirmation;
    if (show) {
      const config = {
        confirmationMessage: 'Are you sure you wish to delete this constraint?',
        confirmationAction: this._deleteAllocationConstraint,

        confirmationButtonText: 'Delete',
        confirmationButtonClassName: 'btn btn-red btn-small-tall',
        cancelButtonClassName: 'btn btn-flat btn-flat-grey btn-small-tall',
      };
      return buildConfirmationComponent.call(this, config).component;
    } else {
      return null;
    }
  };

  _renderWarning = () => {
    const isWarning = !!this.state.warning;
    const warningMessage = this.state.warning;
    if (!isWarning) {
      return null;
    }
    return (
      <div className={`optimize-constraint-warning`}>
        <Icon icon="fa-exclamation-triangle" size="small" colorClassName={'warning-text-color'} />
        <div className={``}>{warningMessage}</div>
      </div>
    );
  };

  _renderSlider = () => {
    const maxAllocationBound = this._calculateBoundsForMinMaxAllocation().max_shares;
    const min = maxAllocationBound * -1;
    const max = maxAllocationBound * 1;

    const minValue = this._getValueForSlider('min_shares');
    const maxValue = this._getValueForSlider('max_shares');

    const value = [minValue, maxValue];

    return (
      <div className={`optimize-constraint-slider`}>
        <Slider
          pearling={true}
          min={min}
          max={max}
          step={1}
          value={value}
          defaultValue={[min, max]}
          onChange={this._handleSliderChange}
          onAfterChange={this._handleAfterSliderChange}
          withBars
        />
      </div>
    );
  };

  _renderDeleteAllocationConstraintButton = () => {
    return (
      <div className={`remove-optimize-constraint-container border-accent`}>
        <i
          className="fa fa-times fa-times-thin secondary-text-color optimize-constraint-remove-icon"
          onClick={this._forceConfirmationOfDeleteAction}
        ></i>
        {this._renderConfirmationComponent()}
      </div>
    );
  };

  _renderInput = (input) => {
    return createInputComponent.call(this, input, null, input.name);
  };

  _renderErrorMessage = () => {
    // ajax failures
    return <div className={`optimize-constraint-error`}></div>;
  };

  _renderStockIndicatorComponents = () => {
    return (
      <div className={`stock-indicator-container`}>
        {this._renderSymbol()}
        {this._renderName()}
        <CurrentPrice securityId={this.props.security_id} />
      </div>
    );
  };

  _renderSymbol = () => {
    const { symbol } = this._returnSymbolAndName();
    return <div className={`security-component-symbol optimize-constraint-symbol  `}>{symbol}</div>;
  };
  _renderName = () => {
    const { name } = this._returnSymbolAndName();
    return <div className={`security-component-name optimize-constraint-name secondary-text-color`}>{name}</div>;
  };

  _renderTextFields = () => {
    const minInput = this.state.inputs[0];
    const maxInput = this.state.inputs[1];

    return (
      <div className={`optimize-constraint-text-fields input-fields-container`}>
        {this._renderInput(minInput)}
        {this._renderInput(maxInput)}
      </div>
    );
  };

  _returnCurrentPriceForSecurity = () => {
    const price = this._returnSecurityPriceData() && this._returnSecurityPriceData().current_price;
    return price || null;
  };

  _isPriceLoading = () => !this._returnSecurityPriceData() || this._returnSecurityPriceData().loading;
  _isPriceLoadingWithPreviousPriceDataAvailable = () => this._isPriceLoading() && this._returnCurrentPriceForSecurity();
  _isInitialPriceLoading = () => this._isPriceLoading() && !this._isPriceLoadingWithPreviousPriceDataAvailable();

  _returnSecurityId = () => this.props.security_id;
  _returnSecuritiesPriceStore = () => this.props.securitiesPrice;
  _returnAllSecuritiesPriceData = () => this._returnSecuritiesPriceStore().securities;
  _returnSecurityPriceData = () => this._returnAllSecuritiesPriceData()[this._returnSecurityId()] || {};
}

const mapStateToProps = (state) => {
  return {
    securities: state.securities,
    securitiesPrice: state.securitiesPrice,
    optimizer: state.optimizer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllocationConstraint);
