export const getDescriptionFromViewpointOrg = (data) => data.description;

export const getDisplayNameFromViewpointOrg = (data) => data.name;

export const getInitialsFromViewpointOrg = (data) => getDisplayNameFromViewpointOrg(data)[0];

export const getViewpointMonthlyFeeFromViewpointOrg = (org) => org.monthly_fee;

export const getAdminUserIdFromViewpointOrg = (org) => org.avatar_user_id;

export const getAvatarUrlFromViewpointOrg = (org) => org.avatar_url;

export const isViewpointOrgSubscriptionFree = (org) => org.is_free;

export const isViewpointOrgSubscriptionFreeEligible = (org) => org.is_free_eligible;

export const isViewpointOrgSubscribable = (org) => org.can_subscribe;

export const didCancelViewpointOrgSubscription = (org) => org.is_canceled;

export const isSubscribedToViewpointOrg = (org) => org.is_subscribed && !didCancelViewpointOrgSubscription(org);

export const isSubscribedButCancelledToViewpointOrg = (org) =>
  org.is_subscribed && didCancelViewpointOrgSubscription(org);

export const canResubscribeToViewpointOrg = (org) =>
  didCancelViewpointOrgSubscription(org) && isSubscribedToViewpointOrg(org);
