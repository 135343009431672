import React from 'react';
import { connect } from 'react-redux';
import { getCurrentUserKarmaLabelFromState, getCurrentUserKarmaLabelLongFromState } from '../../selectors/karma';

class KarmaLabel extends React.PureComponent {
  render() {
    const {
      text,
      longText,
      useLongVersion,

      fontSize,
    } = this.props;

    return (
      <span
        className={' '}
        style={{
          fontSize: fontSize,
          lineHeight: fontSize,
        }}
      >
        {useLongVersion ? longText : text}
      </span>
    );
  }
}

KarmaLabel.defaultProps = {
  textProps: {},
  fontSize: '14px',
};

const mapStateToProps = (state) => {
  return {
    text: getCurrentUserKarmaLabelFromState(state),
    longText: getCurrentUserKarmaLabelLongFromState(state),
  };
};

export default connect(mapStateToProps)(KarmaLabel);
