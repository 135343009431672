import React from 'react';
import { Page } from '../../components/layout';
import AvailableRewards from '../../../containers/Rewards/components/AvailableRewards';
import { H3 } from '../../lib/nvstr-common-ui.es';
import styled from 'styled-components';
import ShareLink from '../../../containers/People/ShareLink';
import GmailImportButton from '../../../containers/ImportConnections/ImportButtons/GmailImport';
import ReferralCashRewardsDisclaimer from '../../../containers/UI/Disclaimers/ReferralCashRewards';
import {
  referralRewardLimit,
  referralRewardRequiresACHLink,
  referralRewardRequiresTrade,
} from '../../../helpers/currentUserHelpers';
import { useCurrentUser } from '../../hooks/user';

const HeadingWrapper = styled.div`
  text-align: center;

  @media (max-width: 450px) {
    h3 {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;
const AvailableRewardsWrapper = styled.div`
  .rewards-available-heading {
    padding: 0;

    * {
      font-size: 24px;
      line-height: 28px;
    }
  }

  @media (max-width: 450px) {
    .rewards-available-heading {
      * {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
`;

const InviteByEmailWrapper = styled.div`
  position: relative;
  padding-top: 24px;

  .connection-cell {
    height: auto;
  }
`;

const GmailImportWrapper = styled.div`
  position: relative;
  padding-top: 36px;
  padding-bottom: 24px;

  .gmail-import-button-container {
    padding: 0;
  }
`;

const SHARE_LINK_MESSAGE_TEST_GROUP = 'c';
const HEADING_COPY_TEST_GROUP = 'a';

const TEST_GROUP_TRACKING_DATA = {
  'AB Test Group': HEADING_COPY_TEST_GROUP,
  'Share Link Message AB Test Group': SHARE_LINK_MESSAGE_TEST_GROUP,
};

export const InviteFriends = ({ context }) => {
  const currentUser = useCurrentUser();
  const maxPrizeLimit = referralRewardLimit(currentUser) || 1000;
  const doesReferralRewardRequiresTrade = referralRewardRequiresTrade(currentUser);
  const doesReferralRewardRequiresACHLink = referralRewardRequiresACHLink(currentUser);

  return (
    <Page>
      <HeadingWrapper>
        <H3>When you invite friends</H3>
      </HeadingWrapper>
      <AvailableRewardsWrapper>
        <AvailableRewards showHeading />
      </AvailableRewardsWrapper>
      <div style={{ paddingTop: '24px' }}>
        <div className={'referrals-share-link-container'}>
          <ShareLink
            shareContext={'Rewards Page'}
            additionalPropertiesForEventLogging={TEST_GROUP_TRACKING_DATA}
            shareLinkMessageABTestGroup={SHARE_LINK_MESSAGE_TEST_GROUP}
          />
        </div>

        <InviteByEmailWrapper>
          <InviteByEmail
            placeholder={"Or enter a friend's email to invite"}
            inviteContext={'Invite Friends Page'}
            additionalPropertiesForEventLogging={TEST_GROUP_TRACKING_DATA}
            hideHeading
          />
        </InviteByEmailWrapper>

        <GmailImportWrapper>
          <GmailImportButton />
        </GmailImportWrapper>

        <div>
          <ReferralCashRewardsDisclaimer
            rewardLimit={maxPrizeLimit}
            requiresTrade={doesReferralRewardRequiresTrade}
            requiresACHLink={doesReferralRewardRequiresACHLink}
          />
        </div>
      </div>
    </Page>
  );
};
