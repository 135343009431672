import { isFloat } from '../../helpers/numberHelpers';
import { createTimeInstance, formatLocalizedDateTime } from '../../helpers/timeHelpers';
import { throwError } from '../../helpers/devToolHelpers';

export const getDecimalAccuracy = (paramValue) => {
  if (isNaN(paramValue)) return 0;
  const v = typeof paramValue !== 'string' ? paramValue.toString() : paramValue;
  if (v.indexOf('.') < 0) return 0;
  const decimalValue = v.split('.')[1];
  return decimalValue?.length || 0;
};

export const DISPLAY_FORMAT_TYPES = {
  STOCK_PRICE: 'stock price',
  PRICE: 'price',
  INTEGER: 'integer',
  COMMA_SEPARATED_INT: 'comma separated integer',
  PERCENTAGE: 'percentage',
  RATIO: 'ratio',
  DATE: 'date',
  DAY: 'day',
  SHARES: 'shares',
  REWARD_AMOUNT: 'rewardAmount',
};

export const formatNumber = (num, options) => {
  return new Intl.NumberFormat('en-US', options).format(num);
};

const _formatter = {
  [DISPLAY_FORMAT_TYPES.PRICE]: (value, config) => {
    const { prefix, stricterDecimals, showPlus, hideDollarSign, asInt } = config;

    const isNegative = value < 0;
    const absValue = Math.abs(value);
    const minimumFractionDigits = asInt ? 0 : 2;
    const maximumFractionDigits = asInt ? 0 : value < 1 ? (stricterDecimals && value < 0.01 ? 4 : 2) : 2;

    let displayStr = prefix || '';
    displayStr += isNegative ? '-' : showPlus ? '+' : '';
    displayStr += hideDollarSign ? '' : '$';
    displayStr += formatNumber(absValue, {
      minimumFractionDigits,
      maximumFractionDigits,
    });
    return displayStr;
  },
  [DISPLAY_FORMAT_TYPES.STOCK_PRICE]: (value, config) => {
    const { prefix, stricterDecimals, showPlus, hideDollarSign, asInt } = config;

    const isNegative = value < 0;
    const absValue = Math.abs(value);
    const minimumFractionDigits = asInt ? 0 : 2;
    const maximumFractionDigits = asInt ? 0 : value < 1 ? (stricterDecimals && value < 0.01 ? 4 : 2) : 2;

    let displayStr = prefix || '';
    displayStr += isNegative ? '-' : showPlus ? '+' : '';
    displayStr += formatNumber(absValue, {
      minimumFractionDigits,
      maximumFractionDigits,
    });
    return displayStr;
  },
  [DISPLAY_FORMAT_TYPES.REWARD_AMOUNT]: (value, config) => {
    const { prefix, showPlus, hideDollarSign } = config;

    const isNegative = value < 0;
    const absValue = Math.abs(value);

    let displayStr = prefix || '';
    displayStr += isNegative ? '-' : showPlus ? '+' : '';
    displayStr += hideDollarSign ? '' : '$';

    if (isFloat(value)) {
      displayStr += formatNumber(absValue, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      displayStr += formatNumber(absValue, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    return displayStr;
  },
  [DISPLAY_FORMAT_TYPES.INTEGER]: (value) => {
    const minimumFractionDigits = 0;
    const maximumFractionDigits = 0;
    return formatNumber(value, {
      minimumFractionDigits,
      maximumFractionDigits,
    });
  },
  [DISPLAY_FORMAT_TYPES.COMMA_SEPARATED_INT]: (value) => {
    const minimumFractionDigits = 0;
    const maximumFractionDigits = 0;
    return formatNumber(value, {
      minimumFractionDigits,
      maximumFractionDigits,
    });
  },
  [DISPLAY_FORMAT_TYPES.RATIO]: (value) => {
    const minimumFractionDigits = 0;
    const maximumFractionDigits = 1;
    return formatNumber(value, {
      minimumFractionDigits,
      maximumFractionDigits,
    });
  },
  [DISPLAY_FORMAT_TYPES.PERCENTAGE]: (value) => {
    const minimumFractionDigits = 0;
    const maximumFractionDigits = 2;
    return `${formatNumber(value, {
      minimumFractionDigits,
      maximumFractionDigits,
    })}%`;
  },
  [DISPLAY_FORMAT_TYPES.SHARES]: (value) => {
    const minimumFractionDigits = 0;
    const maximumFractionDigits = 5;
    return `${formatNumber(value, {
      minimumFractionDigits,
      maximumFractionDigits,
    })} sh`;
  },
  [DISPLAY_FORMAT_TYPES.DATE]: (timeInstance) => {
    return formatLocalizedDateTime('ll', timeInstance);
  },
  [DISPLAY_FORMAT_TYPES.DAY]: (timeInstance) => {
    return formatLocalizedDateTime('Do', timeInstance);
  },
};

export const formatValueTo = (formatType, value, config = {}) => {
  if (!(formatType in _formatter)) {
    console.error('Missing type', { formatType });
    return throwError('Type not found in _formatter', { formatType });
  }
  if (isNaN(value)) {
    return '';
  }
  return _formatter[formatType](value, config);
};

export const formatDateValueTo = (value, config = {}) => {
  const { prefix, suffix, type } = config;

  const timeInstance = createTimeInstance(value);
  let formattedDateValueForDisplay = null;

  if (type === 'untilDate') {
    formattedDateValueForDisplay = formatToUntilMessage(timeInstance);
  } else if (type === 'time') {
    formattedDateValueForDisplay = formatLocalizedDateTime('LTS', timeInstance);
  } else if (type === 'shortDate') {
    formattedDateValueForDisplay = formatLocalizedDateTime('ll', timeInstance);
  } else {
    formattedDateValueForDisplay = formatLocalizedDateTime('lll', timeInstance);
  }

  let displayStr = prefix || '';
  displayStr += formattedDateValueForDisplay;
  displayStr += suffix || '';
  return displayStr;
};
