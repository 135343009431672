import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import * as Actions from '../../actions/index';
import Page from '../../components/layout/Page';
import PageLoading from '../../components/PageLoading';
import Button from '../../components/buttons/Button';
import { handleImportClick, returnIconForNetwork } from '../../helpers/importConnectionsHelpers';
import { FlatButton } from '@src/main/components/buttons';

export class ImportConnectionsContainer extends React.Component {
  componentDidMount() {
    this._getImportConnectionsNetworks();
  }

  render() {
    return this._isLoading() && !this._isUpdating() ? this._renderLoading() : this._renderPage();
  }

  _renderLoading = () => <PageLoading />;

  _renderPage = () => {
    return (
      <Page className="import-container">
        {this._renderHeader()}
        {this._renderNetworks()}
        <p
          className="secondary-text-color"
          style={{
            fontSize: '16px',
            fontWeight: '300',
            margin: '25px 0 0 0',
          }}
        >
          You can select which contacts or friends you want to send invites to.
        </p>
      </Page>
    );
  };

  _renderHeader = () => {
    return (
      <div className={``}>
        <div className={`heading`}>
          <h2>You can invest better with the help of&nbsp;your&nbsp;friends.</h2>
        </div>
        <div className={`sub-heading`}>
          <p className="secondary-text-color">
            See who you already know on {PRODUCT_DISPLAY_NAME}
            &nbsp;by&nbsp;importing&nbsp;connections.
          </p>
        </div>
      </div>
    );
  };

  _renderNetworks = () => {
    return (
      <div className={`networks-list-container`}>
        <ul className="provider-list">{this._returnImportNetworks().map((network) => this._renderNetwork(network))}</ul>
      </div>
    );
  };

  _renderNetwork = (network) => {
    const networkName = network.network;
    return (
      <li key={`networks-list-network-${networkName}`} className={`social-import-row`}>
        <div className={`social-import`}>
          <div className={`social-import-icon ${networkName.toLowerCase()}`}>{returnIconForNetwork(networkName)}</div>

          <div className={`social-import-text`}>{networkName}</div>
        </div>

        <div className={`social-import-btn`}>
          <FlatButton handleClick={this._bindNetworkDataToClickHandler(network)}>
            {network.can_import_email ? 'Import' : 'Import'}
          </FlatButton>
        </div>
      </li>
    );
  };

  _bindNetworkDataToClickHandler = (network) => {
    return () => handleImportClick(network, this.props.actions);
  };

  _isLoading = () => this._returnImportConnectionsStore().isLoadingNetworks;

  _isUpdating = () => this._isLoading() && this._hasNetworksDataLoaded();

  _hasNetworksDataLoaded = () => this._returnImportNetworks().length > 0;

  _returnImportNetworks = (props) => this._returnImportConnectionsStore(props).networks;

  _returnImportConnectionsStore = (props) => (props || this.props).importConnections;

  _getImportConnectionsNetworks = () => this.props.actions.getImportConnectionsNetworks();

  _logNetworkClick = (network) => {
    const event = 'Click Import Button';
    const properties = {
      Network: network,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    importConnections: state.importConnections,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ImportConnectionsContainer);

export default composedComponent;
