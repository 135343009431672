import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';
import { createQueryString } from '../helpers/routerHelpers';

const URL = `${BASEURL}/api`;

export const documentsAdapter = {
  getDocuments: (formData) => {
    const queryString = createQueryString(formData);
    return sendRequest('get', [`${URL}/documents${queryString}`]).then((response) => response);
  },
};
