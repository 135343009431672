import React from 'react';
import ToggleSwitch from '../StandaloneInputs/ToggleSwitch';

export const ActionSwitchNoEventHandling = (props) => {
  const { className, name, value } = props;
  return (
    <div className={`action-switch-container ${className || ''}`}>
      <ToggleSwitch name={name} value={value} />
    </div>
  );
};
