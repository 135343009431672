import React from 'react';

import BasicSecurityDataWrapper from '../../../../SecurityData/BasicSecurityDataWrapper';
import SecurityDataComponent from '../../../../SecurityData/SecurityDataComponent';

import { truncateName } from '../../../../../helpers/generalHelpers';

const StockIdentifier = (props) => {
  const truncateCompanyName = (name) => {
    return truncateName(name, 26);
  };
  return (
    <td className="comp-table-stock-identifier-cell">
      <BasicSecurityDataWrapper securityId={props.securityId}>
        <SecurityDataComponent
          className={'security-name'}
          property={'name'}
          additionalFormatting={truncateCompanyName}
        />
        <SecurityDataComponent className={'security-symbol secondary-text-color'} property={'symbol'} />
        <SecurityDataComponent className={'security-sector secondary-text-color'} property={'sector'} />
      </BasicSecurityDataWrapper>
    </td>
  );
};

export default StockIdentifier;
