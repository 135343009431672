import React from 'react';
import styled from 'styled-components';
import { colorPalette } from '../../../lib/nvstr-utils.es';

const BarItem = styled.div`
  height: 3px;
  width: 10px;
  background: ${colorPalette.primary.charcoal};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.2)};
`;

const Container = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
`;

const BarContainer = styled.div`
  padding: 0 4px;
`;

const LessonRewardWrapper = styled.div`
  opacity: 0.2;
  margin-left: 4px;
`;

export const CarouselPositionIndicator = ({ activeIndex, itemsTotal, lessonReward }) => {
  const checkIsActive = (i, activeIndex) => i === activeIndex;
  const items = [...new Array(itemsTotal)];
  return (
    <Container>
      {items.map((el, i) => (
        <BarContainer key={i}>
          <BarItem isActive={checkIsActive(i, activeIndex)} />
        </BarContainer>
      ))}
      {lessonReward > 0 && (
        <LessonRewardWrapper>
          <b>{`$${lessonReward}`}</b>
        </LessonRewardWrapper>
      )}
    </Container>
  );
};
