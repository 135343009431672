import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withWindowSize from '../HOCS/withWindowSize';
import styled from 'styled-components';

import * as Actions from '../../actions/index';

import ThoughtContainer from '../Thoughts/ThoughtContainer';
import AskAnyoneForm from '../UI/AskAnyone/AskAnyoneForm';
import BasicSecurityDataWrapper from '../SecurityData/BasicSecurityDataWrapper';

import Button from '../../components/buttons/Button';

import { findSecurity } from '../../helpers/securitiesHelpers';
import { findUser } from '../../helpers/usersHelpers';
import { findIdea, getSecurityIdFromIdea, getSecuritySymbolFromIdea } from '../../helpers/ideaHelpers';
import { getThoughtImpressionEventName } from '../../constants/tracking';
import { parseQueryString } from '../../helpers/routerHelpers';

import ShareIcon from '@src/main/components/Images/ShareIcon';
import DiscussIcon from '@src/main/components/Images/DiscussIcon';
import { withRouter } from '../../main/utils';
import { FlatButton, SkeletonButton } from '../../main/components/buttons';
/*
  Required Props for <ReusableSearchContainer/>

    at lease one of props below is required
      securityId -> used for collecting data for the display of form and submission of ask why request
      ideaId -> used for collecting data for the display of form and submission of ask why request
      thoughtId -> used for collecting data for the display of form and submission of ask why request

  -----------------------------------------------

  Optional Props
    buttonWidth -> * must have a css class for  btn-fixed-width-<width supplied>
    autoOpen -> will default AskAnyoneForm open
    openFormInModal -> bool -> will default AskAnyoneForm to open in a modal

    group below: *all must be supplied together
      customAskType: string
      customAskTypeButtonRenderer: string -> allows to create a custom button for the ask btn
      customAskTypeDisabledButtonRenderer: string -> allows to create a custom button for the ask btn
      *** NOTE: if using a custom render you must use a pre-existing render or make your own
*/

const ButtonWrapper = styled.div`
  button {
    padding: 4px 8px;
  }
`;
const ThoughtButtonWrapper = styled.div`
  button {
    padding: 8px;

    > span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    svg {
      height: 18px;
      width: 18px;

      margin-right: 3px;
    }
  }
`;

const IconWrap = styled('div')`
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
`;

class AskAnyone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _isAskWhyFormOpen: props.autoOpen,
    };
  }

  componentDidMount() {
    const query = parseQueryString(this.props.location.search);
    // only works on security page/ panel
    if (query.open_ask_form === '1' && this._returnAskType() === 'security') {
      this._openAskWhyForm();
    }

    if (this.props.openOnMount) {
      this._openAskWhyForm();
    }
  }

  render() {
    return (
      <div className={`ask-why-container ${this.props.containerClass}`}>
        {!this.props.hideButton && this._renderButton()}
        {this._isAskWhyFormOpen() && this._renderAskWhyFormPositionedUnderButton()}
      </div>
    );
  }

  _renderButton = () => {
    return !this._isAskWhyFormOpen() ? this._renderAskAnyoneButton() : this._renderAskAnyoneButtonDisabled();
  };

  _useCustomAskType = () => this._returnCustomAskType();

  _returnCustomAskType = () => this.props.customAskType;

  _returnCustomAskTypeButtonRenderer = () => this.props.customAskTypeButtonRenderer;

  _returnCustomAskTypeDisabledButtonRenderer = () => this.props.customAskTypeDisabledButtonRenderer;

  _renderAskButtonSmall = () => {
    return (
      <ButtonWrapper>
        <FlatButton onClick={this._handleClick}>Ask Why</FlatButton>
      </ButtonWrapper>
    );
  };

  _renderAskButtonSmallDisabled = () => {
    return (
      <ButtonWrapper>
        <FlatButton disabled onClick={this._handleClickDisabled}>
          Ask Why
        </FlatButton>
      </ButtonWrapper>
    );
  };

  _renderAskButtonWithIcon = () => {
    return (
      <ButtonWrapper>
        <FlatButton transparent onClick={this._handleClick}>
          <span>
            <IconWrap>
              <ShareIcon />
            </IconWrap>
            <span>Ask</span>
          </span>
        </FlatButton>
      </ButtonWrapper>
    );
  };

  _renderAskAnyoneButton = () => {
    if (this._useCustomAskType()) {
      if (!this._returnCustomAskTypeButtonRenderer()) {
        console.error('Need to supply customAskTypeButtonRenderer prop');
        return null;
      }
      if (!this[this._returnCustomAskTypeButtonRenderer()]) {
        console.error(
          `Need to create a render function ${this._returnCustomAskTypeButtonRenderer()} in AskAnyone component`
        );
        return null;
      }

      return this[this._returnCustomAskTypeButtonRenderer()]();
    }
    if (this._returnAskType() === 'thought') {
      return this._renderAskAnyoneThoughtButton();
    } else if (this._returnAskType() === 'security') {
      return this._renderAskAnyoneSecurityButton();
    } else {
      return this._renderAskWhyButton();
    }
  };

  _renderAskAnyoneButtonDisabled = () => {
    if (this._useCustomAskType()) {
      if (!this._returnCustomAskTypeDisabledButtonRenderer()) {
        console.error('Need to supply customAskTypeButtonRenderer prop');
        return null;
      }
      if (!this[this._returnCustomAskTypeDisabledButtonRenderer()]) {
        console.error(
          `Need to create a render function ${this._returnCustomAskTypeDisabledButtonRenderer()} in AskAnyone component`
        );
        return null;
      }

      return this[this._returnCustomAskTypeDisabledButtonRenderer()]();
    }
    if (this._returnAskType() === 'thought') {
      return this._renderAskAnyoneThoughtButtonDisabled();
    } else if (this._returnAskType() === 'security') {
      return this._renderAskAnyoneSecurityButtonDisabled();
    } else {
      return this._renderAskWhyButton();
    }
  };

  _renderAskAnyoneSecurityButton = () => {
    return (
      <SkeletonButton width={this._returnButtonWidth()} onClick={this._handleClick}>
        Ask Someone
      </SkeletonButton>
    );
  };

  _renderAskAnyoneSecurityButtonDisabled = () => {
    return (
      <FlatButton width={this._returnButtonWidth()} disabled onClick={this._handleClickDisabled}>
        Ask Someone
      </FlatButton>
    );
  };

  _renderAskAnyoneThoughtButton = () => {
    return (
      <ThoughtButtonWrapper>
        <FlatButton width={this._returnButtonWidth()} transparent onClick={this._handleClick}>
          <IconWrap>
            <ShareIcon />
          </IconWrap>
          SHARE
        </FlatButton>
      </ThoughtButtonWrapper>
    );
  };

  _renderAskAnyoneThoughtButtonDisabled = () => {
    return (
      <ThoughtButtonWrapper>
        <FlatButton transparent width={this._returnButtonWidth()} onClick={this._handleClickDisabled} disabled>
          <IconWrap>
            <ShareIcon />
          </IconWrap>
          SHARE
        </FlatButton>
      </ThoughtButtonWrapper>
    );
  };

  _renderAskCTLThoughtButton = () => {
    return (
      <ThoughtButtonWrapper>
        <FlatButton transparent width={this._returnButtonWidth()} onClick={this._handleClick}>
          <IconWrap>
            <DiscussIcon />
          </IconWrap>
          ASK CTL
        </FlatButton>
      </ThoughtButtonWrapper>
    );
  };

  _renderAskCTLThoughtButtonDisabled = () => {
    return (
      <ThoughtButtonWrapper>
        <FlatButton disabled width={this._returnButtonWidth()} transparent onClick={this._handleClickDisabled}>
          <IconWrap>
            <DiscussIcon />
          </IconWrap>
          Ask CTL
        </FlatButton>
      </ThoughtButtonWrapper>
    );
  };

  _renderAskWhyButton = () => {
    return (
      <SkeletonButton width={this._returnButtonWidth()} onClick={this._handleClick}>
        Ask Why
      </SkeletonButton>
    );
  };

  _renderAskWhyForm = () => {
    return (
      <AskAnyoneForm
        askType={this._returnAskType()}
        securitySymbol={this._returnSecuritySymbol()}
        securityId={this._returnSecurityId()}
        ideaId={this._returnIdeaId()}
        thoughtId={this._returnThoughtId()}
        setToUserIds={this._returnIdeaOwnerUserId()}
        thoughtContext={this.props.thoughtContext}
        notificationIndex={this.props.notificationIndex}
        thoughtMetadata={this.props.thoughtMetadata}
        viewingContext={this.props.viewingContext}
        handleAfterAction={this.props.handleAfterAction}
        handleClose={this._handleAskFormClose}
        handleCancel={this._handleAskFormCancel}
        socialShareText={this.props.socialShareText}
        thoughtSocialShareLink={this.props.thoughtSocialShareLink}
        allowSocialShare={this.props.allowSocialShare}
        inModal={this._shouldOpenFormInModal()}
        defaultAskingCTL={this.props.defaultAskingCTL}
        showTooltipArrow
      />
    );
  };

  _renderAskWhyFormPositionedUnderButton = () => {
    return (
      <div
        className={`ask-anyone-form-position-container fade-in-up-quick ${
          this.props.centerFormWithAskButton ? 'centered' : ''
        }`}
      >
        {this._renderAskWhyForm()}
      </div>
    );
  };

  _renderThought = () => {
    return (
      <BasicSecurityDataWrapper securityId={this._returnSecurityId()} getSecurityDataIfNeeded>
        <ThoughtContainer
          thoughtId={this._returnThoughtId()}
          securityId={this._returnSecurityId()}
          scrollContainerSelector={this.props.scrollContainerSelector}
          viewingContext={getThoughtImpressionEventName('CREATE_ASK_ANYONE')}
          isUserAuthed={true}
          hideOpinionCounts
          hideOpinionActions
          showThoughtHeading
        />
      </BasicSecurityDataWrapper>
    );
  };

  _renderAskWhyFormInModal = () => {
    return (
      <div className={'ask-anyone-form-modal'}>
        {this._renderAskWhyForm()}
        {this._returnThoughtId() && this._renderThought()}
      </div>
    );
  };

  _isAskWhyFormOpen = () => this.state._isAskWhyFormOpen;

  _returnAskType = () => {
    if (this._returnIdeaId()) {
      return 'idea';
    } else if (this._returnThoughtId()) {
      return 'thought';
    } else if (this._returnSecurityId()) {
      return 'security';
    } else {
      return console.error('Could not infer askType based on props given to <AskWhy/>');
    }
  };

  _returnDefaultButtonWidth = () => 125;

  _returnButtonWidth = () => this.props.buttonWidth || this._returnDefaultButtonWidth();

  _shouldOpenFormInModal = () => this.props.openFormInModal;

  _shouldOpenFormInMobileModal = () => this.props.openFormInModalOnMobile && this.props.windowWidth < 600;

  _handleOpenAskWhyForm = () => {
    this._openAskWhyForm();
    this._logOpenAskWhyForm();
  };

  _openAskWhyForm = () => {
    if (this._shouldOpenFormInModal() || this._shouldOpenFormInMobileModal()) {
      this._showAskAnyoneFormModal();
    } else {
      this._setAskWhyFormOpen();
    }
  };

  _showAskAnyoneFormModal = () => {
    const component = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {this._renderAskWhyFormInModal()}
      </div>
    );
    const modal = {
      contentComponent: component,
      size: 'wide',
      dismissable: true,
      className: 'ask-someone-modal',
      restrictHeight: true,
    };
    this.props.actions.showModal(modal);
  };

  _closeModal = () => this.props.actions.hideModal();

  _handleAskFormCancel = () => {
    if (this._shouldOpenFormInModal()) {
      this._closeModal();
    } else {
      this._clearAskWhyFormOpen();
    }
    this.props.additionalDismissAskWhyAction && this.props.additionalDismissAskWhyAction();
    this.props.onCancel && this.props.onCancel();
  };

  _handleAskFormClose = () => {
    if (this._shouldOpenFormInModal()) {
      this._closeModal();
    } else {
      this._clearAskWhyFormOpen();
    }
    this.props.additionalDismissAskWhyAction && this.props.additionalDismissAskWhyAction();
  };

  _setAskWhyFormOpen = () => {
    this.setState(() => ({
      _isAskWhyFormOpen: true,
    }));
  };

  _clearAskWhyFormOpen = () => {
    this.setState(() => ({
      _isAskWhyFormOpen: false,
    }));
  };

  _handleClick = () => {
    this._handleOpenAskWhyForm();
  };

  _handleClickDisabled = () => {
    // nothing for now
  };

  _returnIdea = () => (this._returnIdeaId() ? findIdea(this._returnIdeaId(), this._returnIdeasStore()) : null);

  _returnIdeaId = () => this.props.ideaId;

  _returnIdeasStore = () => this.props.ideas;

  _returnThoughtId = () => this.props.thoughtId;

  _returnSecurityId = () => (this._returnIdea() ? getSecurityIdFromIdea(this._returnIdea()) : this.props.securityId);

  _returnSecuritySymbol = () =>
    this._returnSecurity()
      ? this._returnSecurity().symbol
      : this._returnIdea()
      ? getSecuritySymbolFromIdea(this._returnIdea())
      : null;

  _returnSecurity = () => findSecurity(this._returnSecurityId(), this._returnSecuritiesStore());

  _returnSecuritiesStore = () => this.props.securities;

  _returnIdeaOwnerUserId = () => (this._returnIdea() ? this._returnIdea().user_id : null);

  _returnCurrentUser = () => this.props.currentUser;

  _returnUsersStore = () => this.props.users;

  _logOpenAskWhyForm = () => {
    const event = 'Opened Ask Why Form';
    const properties = {
      Context: this.props.viewingContext,
      'Ask Type': this._returnAskType(),
      'Security ID': this._returnSecurityId(),
      'Security Symbol': this._returnSecuritySymbol(),
      'In Modal': !!this._shouldOpenFormInModal(),
      'Is Ask CTL': this.props.defaultAskingCTL,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    ideas: state.ideas,
    securities: state.securities,
    users: state.users,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(withWindowSize, connect(mapStateToProps, mapDispatchToProps))(AskAnyone);

export default withRouter(composedComponent);
