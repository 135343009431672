import React from 'react';
import withWindowSize from '../HOCS/withWindowSize';

const WidthMediaQueryRenderer = (props) => {
  const {
    maxWidth,

    windowWidth,

    children,
  } = props;

  if (windowWidth > maxWidth) {
    return null;
  }

  return children;
};

export default withWindowSize(WidthMediaQueryRenderer);
