import React from 'react';
import { useDispatch } from 'react-redux';
import { postLearnLessonProgress } from '@src/actions';
import { colorPalette } from '../../lib/nvstr-utils.es';
import { CarouselPositionIndicator } from './subComponents/CarouselPositionIndicator';
import { TrackingEvents } from '@src/utils/tracking/events';
import { useLearnLesson } from '../../hooks/learn/useLearnLesson';
import { TrackIteratively } from '@src/utils/itly';
import { LearnItemContent, LearnLessonHeading, LearnLessonActions, LearnCarouselContent } from './subComponents';
import { Container } from '../ui';

const LearnCarousel = ({ lessonId, onComplete, lessonReward }) => {
  const textColor = colorPalette.primary.charcoal;
  const dispatch = useDispatch();

  const { lesson: lessonData } = useLearnLesson(lessonId);

  const {
    name: lessonName,
    // type,
    heading: lessonHeading,
    lessonContents,
    sponsorId,
    moreOnTopicUrl: moreOnLessonTopicUrl,
  } = lessonData;

  const [activeScreenIndex, setActiveScreenIndex] = React.useState(0);

  const metadata = React.useMemo(
    () => {
      return {
        lessonId,
        lessonName,
        lessonHeading,
        sponsorId,
        moreOnLessonTopicUrl,
      };
    },
    [lessonId, lessonName, lessonHeading, sponsorId, moreOnLessonTopicUrl]
  );
  const items = React.useMemo(
    () => {
      return lessonContents.map((c) => ({
        contents: c,
        metadata,
      }));
    },
    [lessonContents, sponsorId, metadata]
  );
  const itemsTotal = items.length;

  const previousScreen = () => {
    const newIndex = activeScreenIndex - 1;
    if (newIndex >= 0 && newIndex < itemsTotal) {
      setActiveScreenIndex(newIndex);
    }
  };

  const advanceScreen = () => {
    const newIndex = activeScreenIndex + 1;
    if (newIndex > 0 && newIndex < itemsTotal) {
      setActiveScreenIndex(newIndex);
    }
  };

  // log lesson screen view
  React.useEffect(
    () => {
      const pageNumber = activeScreenIndex + 1;
      TrackingEvents.learn.VIEW_LEARN_LESSON_SCREEN.send({
        'Sponsor ID': sponsorId,
        'Lesson ID': lessonId,
        Subject: lessonName,
        Slide: pageNumber,
      });

      TrackIteratively.learn.LEARN_LESSON_VIEWED.send({
        lesson_id: lessonId,
        lesson_name: lessonName,
        xp_possible: 200,
      });

      const progressParams = {
        lesson_id: lessonId,
        page_number: pageNumber,
        completed: false,
      };
      postLearnLessonProgress(progressParams)(dispatch);
      console.log('log view', pageNumber);
    },
    [activeScreenIndex]
  );

  return (
    <Container flex={1} column style={{ position: 'relative' }} fullWidth>
      <LearnLessonHeading
        metadata={metadata}
        lessonHeading={lessonHeading}
        lessonReward={lessonReward}
        activeScreenIndex={activeScreenIndex}
      />

      <LearnCarouselContent
        items={items}
        metadata={metadata}
        lessonReward={lessonReward}
        activeScreenIndex={activeScreenIndex}
        advanceScreen={advanceScreen}
        previousScreen={previousScreen}
      >
        <LearnItemContent item={items[activeScreenIndex]} textColor={textColor} />
      </LearnCarouselContent>

      <CarouselPositionIndicator lessonReward={lessonReward} activeIndex={activeScreenIndex} itemsTotal={itemsTotal} />

      <LearnLessonActions
        lessonId={lessonId}
        metadata={metadata}
        onComplete={onComplete}
        advanceScreen={advanceScreen}
        isLastScreen={itemsTotal === activeScreenIndex + 1}
      />
    </Container>
  );
};

export default LearnCarousel;
