import React from 'react';
import { getUserAvailableFunds } from '../../../actions';

const ACTIONS = {
  startFetch: 'sf',
  dataReceived: 'dr',
  fetchFailed: 'ff',
};

const initialState = {
  isInitialized: false,
  isLoading: false,

  fundsAvailableByAccountId: {},
  fundsAvailable: null,
  reasons: [],
};

const concatSimilarReasons = (reasons) => {
  const uniqueReasons = [];
  reasons.forEach((r) => {
    const { reason } = r;
    if (!uniqueReasons.includes(reason)) uniqueReasons.push(reason);
  });
  const c = [];
  uniqueReasons.forEach((uniqueReason) => {
    let totalAmount = 0;
    reasons.forEach((r) => {
      const { amount, reason } = r;
      if (uniqueReason === reason) totalAmount += amount;
    });
    c.push({
      amount: totalAmount,
      reason: uniqueReason,
    });
  });
  return c;
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.startFetch:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.dataReceived: {
      const {
        funds_available: fundsAvailable,
        withdrawal_availability: reasons,
        funds_available_by_account_id,
      } = action.payload;

      return {
        ...state,
        isInitialized: true,
        isLoading: false,
        fundsAvailable,
        fundsAvailableByAccountId: funds_available_by_account_id || {},
        reasons: concatSimilarReasons(reasons),
      };
    }

    case ACTIONS.fetchFailed:
      return {
        ...state,
        isLoading: false,
      };

    default:
      throw new Error();
  }
}

export const useWithdrawalFunds = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState, () => initialState);

  const fetchAvailableFunds = React.useCallback(async () => {
    dispatch({
      type: ACTIONS.startFetch,
    });
    const { status, data } = await getUserAvailableFunds();
    if (status === 200) {
      dispatch({
        type: ACTIONS.dataReceived,
        payload: data,
      });
    } else {
      dispatch({
        type: ACTIONS.fetchFailed,
      });
    }
  }, []);

  React.useEffect(() => {
    if (!state.isLoading && !state.isInitialized) {
      fetchAvailableFunds();
    }
  }, [state]);

  return state;
};
