import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/paths';
import { Page } from '../../components/layout';
import { H3, Body5 } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { DepositForm } from '../../containers/forms/DepositForm';
import FundingBonusBannerRenderer from '../../../components/banners/FundingBonusBannerRenderer';
import { CheckCircleFilled } from '../../icons/svg/CheckCircleFilled';
import { usePromotions } from '../../hooks/user';
import { LinkBankPlaid } from './LinkBankPlaid';
import { Container } from '@src/main/components/ui';

const Content = styled.div`
  h3 {
    margin: 0;
  }
`;
const BannerStyle = styled.div`
  margin: 8px auto 16px auto;
  padding: 16px;
  border-radius: 5px;
  border: 1px dashed ${({ theme }) => theme.themeColors.text};
`;
const TransferCompletedWrapper = styled.div`
  text-align: center;
`;
const TransferCompletedIconWrapper = styled.div`
  svg {
    height: 36px;
    width: 36px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

export const DepositFunds = () => {
  const navigate = useNavigate();
  const { isShowingFundingPromo } = usePromotions();

  const [isTransferCompleted, setTransferCompleted] = React.useState(false);
  const [noticeMessage, setNoticeMessage] = React.useState(false);

  const [showDepositPicker] = React.useState(true);

  const [plaidReauthToken, setPlaidReauthToken] = React.useState(null);
  const [cachedFormValues, setCachedFormValues] = React.useState(null);

  const onContinue = (success, props = {}) => {
    const { noticeMessage: serverMessage } = props;
    if (serverMessage) setNoticeMessage(serverMessage);

    setTransferCompleted(true);
  };

  const handleContinue = () => {
    navigate(ROUTES.FUNDING.build());
  };

  const onPlaidReauthRequired = React.useCallback(
    async (plaidAccountToken, formValues) => {
      setCachedFormValues(formValues);
      setPlaidReauthToken(plaidAccountToken);
    },
    [setCachedFormValues, setPlaidReauthToken]
  );

  const handleReauthContinue = React.useCallback(
    async (success) => {
      setPlaidReauthToken(null);
    },
    [setPlaidReauthToken]
  );

  if (plaidReauthToken !== null) {
    return (
      <LinkBankPlaid
        bankAccountId={cachedFormValues.account_id}
        onContinue={handleReauthContinue}
        reauthToken={plaidReauthToken}
      />
    );
  }

  if (isTransferCompleted) {
    return (
      <Page width={'600px'}>
        <Content>
          <TransferCompletedWrapper>
            <TransferCompletedIconWrapper>
              <CheckCircleFilled />
              <H3>Deposit Initiated</H3>
              <div style={{ paddingTop: '8px' }}>
                <Body5>{noticeMessage || 'Please allow 2-3 business days to process this request.'}</Body5>
              </div>
            </TransferCompletedIconWrapper>
            <Container top={24} centerAll fullWidth>
              <FlatButton fullWidth onClick={handleContinue}>
                Continue
              </FlatButton>
            </Container>
          </TransferCompletedWrapper>
        </Content>
      </Page>
    );
  }

  return (
    <Page width={'600px'}>
      <Content>
        <H3>Add Funding</H3>

        {isShowingFundingPromo && (
          <BannerStyle>
            <FundingBonusBannerRenderer />
          </BannerStyle>
        )}

        <DepositForm
          initialFormValues={cachedFormValues}
          showDepositPicker={showDepositPicker}
          onContinue={onContinue}
          onPlaidReauthRequired={onPlaidReauthRequired}
        />
      </Content>
    </Page>
  );
};
