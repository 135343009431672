import styled from 'styled-components';
import { Body4, Body5 } from '../../lib/nvstr-common-ui.es';
import { Container } from '../../components/ui';
import { ColorChangeText } from '../../components/molecules/ColorChangeText';
import { DISPLAY_FORMAT_TYPES, formatValueTo } from '../../utils/numbers';
import { useUserIdeasPerformance } from '../../hooks/ideas/useIdeaPerformance';
import { SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../components/ui/SvgLoadingIcon';
import React from 'react';
import InfoIcon from '../../../containers/UI/InfoIcon';

export const IdeaPerformanceWrapper = styled.div`
  min-width: 300px;
  margin-left: 10%;

  border: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  border-radius: 5px;
  padding: 24px 0;
`;
const IdeaPerformanceHeadingWrapper = styled.div`
  padding: 0 24px 24px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
`;
const IdeaPerformanceDataWrapper = styled.div`
  padding: 24px 24px 0 24px;
`;
const IdeaPerformanceHorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: space-around;
`;
const IdeaPerformanceDataPointWrapper = styled.div`
  .datapoint-value {
    padding-top: 8px;
    font-size: 16px;
    line-height: 18px;
  }
`;

const InfoIconWrapper = styled.span`
  position: relative;
  path {
    fill: ${({ theme }) => theme.themeColors.secondaryText};
  }
`;

const IdeaPerformanceDataPoint = ({ data }) => {
  return (
    <IdeaPerformanceDataPointWrapper>
      <div>
        <Body5 isLowContrast>{data.name}</Body5>
      </div>
      <Container height={18}>
        <ColorChangeText value={data.value} className={'datapoint-value'}>
          <Body5 bold>{formatValueTo(DISPLAY_FORMAT_TYPES.PERCENTAGE, data.value)}</Body5>
        </ColorChangeText>
      </Container>
    </IdeaPerformanceDataPointWrapper>
  );
};

export const IdeaPerformanceData = ({ userId }) => {
  const performance = useUserIdeasPerformance(userId);
  if (!performance) {
    return (
      <IdeaPerformanceHorizontalWrapper>
        <Container centerAll height={36}>
          <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.balls} />
        </Container>
      </IdeaPerformanceHorizontalWrapper>
    );
  }

  const { performance_last_month, performance_this_month, performance_today } = performance;
  const datapoints = [
    { name: 'Today', value: performance_today },
    { name: 'This Month', value: performance_this_month },
    { name: 'Last Month', value: performance_last_month },
  ];
  return (
    <IdeaPerformanceHorizontalWrapper>
      {datapoints.map((dp) => (
        <IdeaPerformanceDataPoint key={dp.name} data={dp} />
      ))}
    </IdeaPerformanceHorizontalWrapper>
  );
};

export const IdeaPerformance = ({ userId }) => {
  return (
    <IdeaPerformanceWrapper>
      <IdeaPerformanceHeadingWrapper>
        <InfoIconWrapper>
          <Body4 isLowContrast thin>
            Idea Performance
          </Body4>
          <InfoIcon word={'performance_numbers'} style={{ top: '3px', bottom: '1px' }} />
        </InfoIconWrapper>
      </IdeaPerformanceHeadingWrapper>

      <IdeaPerformanceDataWrapper>
        <IdeaPerformanceData userId={userId} />
      </IdeaPerformanceDataWrapper>
    </IdeaPerformanceWrapper>
  );
};
