import React from 'react';

import CreateThoughtContainer from './CreateThoughtContainer';
import AskAnyone from '../../containers/buttons/AskAnyone';

import { getIdeaPhraseFor } from '../../helpers/terminologyHelpers';
import { ThoughtEventNames } from '../../constants/tracking';

const NoThoughtsComponent = (props) => {
  if (!props.isUserAuthed) {
    return (
      <div className="public-no-thoughts-for-idea-container">
        <div className="stand-alone-text">
          {`The community has not entered any thoughts for ${props.security.symbol}. Sign in or join the community and add your thoughts.`}
        </div>
      </div>
    );
  }

  if (props.showAsThoughtsInIdea && props.userIsCurrentUser) {
    return (
      <div className="no-thoughts-for-idea-container">
        <div className="stand-alone-text" style={{ paddingBottom: '16px' }}>
          {`You have not entered any thoughts with your ${getIdeaPhraseFor('noun').toLowerCase()}.`}
        </div>

        <div className="no-current-user-thoughts">
          <CreateThoughtContainer
            security={props.security}
            handleCreateThought={props.handleCreateThought}
            viewingContext={ThoughtEventNames.CREATE_THOUGHT_FROM_NO_THOUGHTS_PROMPT}
          />
        </div>
      </div>
    );
  }

  if (props.showAsThoughtsInIdea) {
    if (props.idea.is_ask_why_available_to_current_user && props.idea.active) {
      return (
        <div className="no-thoughts-for-user-container">
          <div className="stand-alone-text">
            {`Want to hear more about this ${getIdeaPhraseFor('noun').toLowerCase()}?`}
          </div>
          <AskAnyone
            viewingContext={'Ideas Panel'}
            ideaId={props.idea.idea_id}
            centerFormWithAskButton
            openFormInModalOnMobile
          />
        </div>
      );
    } else {
      if (!props.userIsCurrentUser) {
        return null;
      }
    }
  }

  return (
    <div className="no-thoughts-for-security-container">
      <CreateThoughtContainer
        security={props.security}
        handleCreateThought={props.handleCreateThought}
        viewingContext={ThoughtEventNames.CREATE_THOUGHT_FROM_NO_THOUGHTS_PROMPT}
      />
    </div>
  );
};

export default NoThoughtsComponent;
