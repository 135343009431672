import React from 'react';
import { connect } from 'react-redux';

import { Counter } from '../UI/Counter';
import { returnPendingReceivedConnectionRequestsCount } from '../../helpers/connectionsHelpers';

class ConnectionRequestsCounter extends React.Component {
  render() {
    return <Counter count={this._returnNewNotificationsCount()} className={this.props.className} />;
  }

  _returnNewNotificationsCount = (props) => returnPendingReceivedConnectionRequestsCount(this._returnUsersStore());
  _returnUsersStore = () => this.props.users;
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
  };
};

export default connect(mapStateToProps)(ConnectionRequestsCounter);
