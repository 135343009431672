import React from 'react';

import { createInputComponent } from '../../../helpers/formHelpers';

const FilterExpressionForm = (props) => {
  const operatorToggle = props.inputs[1];
  const valueInput = props.inputs[2];

  return (
    <div>
      <div className="operator-toggle-container">
        {createInputComponent(operatorToggle, props.returnInputDefaultProps(), operatorToggle.name)}
      </div>
      <div className="value-input-container transparent-input-bg">
        {createInputComponent(
          valueInput,
          { ...props.returnInputDefaultProps(), fieldName: props.filterName },
          valueInput.name
        )}
      </div>
    </div>
  );
};

export default FilterExpressionForm;
