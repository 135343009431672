import {
  ADD_HISTORY_TRANSACTIONS_DATA,
  GET_HISTORY_TRANSACTIONS_DATA,
  CANCEL_FUNDING_TRANSACTION,
} from '../../constants';

const defaultState = {
  hasLoadedPreviously: false,
  isLoadingTransactionHistory: true,
  transactions: [],
};

export default function historyTransactionsReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_HISTORY_TRANSACTIONS_DATA:
      return {
        ...state,
        isLoadingTransactionHistory: true,
      };

    case ADD_HISTORY_TRANSACTIONS_DATA:
      return {
        ...state,
        hasLoadedPreviously: true,
        isLoadingTransactionHistory: false,
        transactions: action.payload,
      };

    case CANCEL_FUNDING_TRANSACTION:
      const id = action.payload.id;
      const transaction = action.payload.transaction;
      const updatedTransactions = [];
      state.transactions.forEach((groupedTransactionData) => {
        if (groupedTransactionData.transactions.some((transactionData) => transactionData.funding_transfer_id === id)) {
          const newGroupedTransactionData = {
            date: groupedTransactionData.date,
          };
          const newTransactionsData = groupedTransactionData.transactions.map((transactionData) => {
            if (transactionData.funding_transfer_id === id) {
              return transaction;
            } else {
              return transactionData;
            }
          });
          newGroupedTransactionData.transactions = newTransactionsData;
          updatedTransactions.push(newGroupedTransactionData);
        } else {
          updatedTransactions.push(groupedTransactionData);
        }
      });
      return {
        ...state,
        transactions: updatedTransactions,
      };

    default:
      return state;
  }
}
