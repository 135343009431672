import React from 'react';

import ThoughtContainer from '../ThoughtContainer';
import BasicSecurityDataWrapper from '../../SecurityData/BasicSecurityDataWrapper';
import { returnSecurityIdFromThought } from '../../../helpers/thoughtsHelpers';
import { InfiniteScroll } from '../../Scroll/InfiniteScroll';

export class ThoughtsListOnly extends React.Component {
  constructor(props) {
    super(props);

    const defaultStartingTotalThoughtsToShowCount = props.startingTotalThoughtsToShowCount || 5;
    this.state = {
      _totalThoughtsToShowCount: defaultStartingTotalThoughtsToShowCount,
    };
  }

  render() {
    if (this.props.noContainer) {
      return this._shouldUseInfiniteScroll()
        ? this._renderThoughtsListWithInfiniteScroll()
        : this._renderThoughtsList();
    } else {
      return (
        <div
          key={`thoughts-list-${this._returnThoughtType()}-${this._returnSecurityId()}`}
          className="thought-list-container"
        >
          <div className={`thoughts-list clearfix`}>
            {this._shouldUseInfiniteScroll()
              ? this._renderThoughtsListWithInfiniteScroll()
              : this._renderThoughtsList()}
          </div>
        </div>
      );
    }
  }

  _renderThoughtsListWithInfiniteScroll = () => {
    return (
      <InfiniteScroll
        id={`${this._returnThoughtType()}-thoughts-list-infinite-scroll`}
        $scrollElement={$(this.props.scrollContainerSelector)}
        onInfiniteScroll={this.handleInfiniteScroll}
        distanceFromBottomToTriggerInfiniteScroll={this.props.distanceFromBottomToTriggerInfiniteScroll || 50}
        ignoreInfiniteScroll={this.props.ignoreInfiniteScroll || this.isNoMoreThoughtsToShowInList()}
      >
        {this._renderThoughtsList()}
      </InfiniteScroll>
    );
  };

  _renderThoughtsList = () => {
    return (
      <div>
        {this._returnThoughtsToDisplay().map((thought) => (
          <BasicSecurityDataWrapper
            key={`${thought.type}-${thought.id}`}
            securityId={returnSecurityIdFromThought(thought)}
            isUserAuthed={this._isUserAuthed()}
          >
            <ThoughtContainer
              thoughtId={thought.id}
              isUserAuthed={this._isUserAuthed()}
              afterTrade={this.props.afterTrade}
              handleAfterThoughtAction={this.props.handleAfterThoughtAction}
              viewingContext={this.props.viewingContext}
              useShareInModal={this.props.useShareInModal}
              scrollContainerSelector={this.props.scrollContainerSelector}
              thoughtDisplayContext={this.props.thoughtDisplayContext}
              showAlternateOpinionActionStyle
            />
          </BasicSecurityDataWrapper>
        ))}
        {this._shouldShowPaddingBelowList() && this._renderBelowLastThoughtPadding()}
      </div>
    );
  };

  _shouldUseInfiniteScroll = () => this.props.useInfiniteScroll;

  _returnThoughtsToDisplay = () =>
    this._shouldUseInfiniteScroll()
      ? this._returnThoughts().slice(0, this._returnTotalThoughtsToShowCount())
      : this._returnThoughts();

  _returnTotalThoughtsToShowCount = () => this.state._totalThoughtsToShowCount;

  handleInfiniteScroll = () => {
    return this._incrementTotalThoughtsToShowCountAndReturnPromise();
  };

  _incrementTotalThoughtsToShowCountAndReturnPromise = () => {
    return new Promise((resolvePromise) => {
      this.setState(
        (prevState) => ({
          _totalThoughtsToShowCount: prevState._totalThoughtsToShowCount + 1,
        }),
        resolvePromise
      );
    });
  };

  isNoMoreThoughtsToShowInList = () => this._returnTotalThoughtsToShowCount() >= this._returnTotalThoughtsCount();

  _useListFadeFrame = () => this.props.useListFadeFrame;

  _shouldShowPaddingBelowList = () => this.props.showPaddingBelowList;

  _renderBelowLastThoughtPadding = () => <div style={{ height: '88px', width: '100%', color: '#fff' }}>.</div>;

  _returnTotalThoughtsCount = () => this._returnThoughts().length;

  _returnThoughts = () => this.props.thoughts;

  _returnSecurityId = () => this.props.securityId;

  _returnThoughtType = () => this.props.thoughtType;

  _isProThoughtType = () => this._returnThoughtType().toLowerCase() === 'pro';

  _isUserAuthed = () => this.props.isUserAuthed;
}

export default ThoughtsListOnly;
