import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Actions from '../actions/index';
import { withRouter } from '../main/utils';
import * as Sentry from '@sentry/react';

// PROTECTED ROUTES
import PanelRenderer from '../paths/PanelRenderer';
import DashboardContainer from '../containers/Dashboard/DashboardContainer';
import RewardsPage from '../containers/Rewards/RewardsPage';
import RewardRedemptionPage from '../containers/Rewards/RewardRedemptionPage';
import PrivacySettingsPage from '../containers/Account/PrivacySettingsPage';
import NvstrXSignup from '../containers/Signups/NvstrXSignup';
import BlankPage from '../containers/UI/BlankPage';
import DocumentUpload from '../containers/Account/DocumentUpload/DocumentUpload';
import LiveAccountSubmissionStatus from '../containers/Account/LiveAccountSubmissionStatus';
import AskPage from '../pages/AskPage';
import Footer from '../containers/UI/Footer';
import DocumentsPage from '../containers/Pages/Documents';
import BDTerms from '../containers/Onboarding/BDTerms';
import { isCurrentUserOnboarding } from '../helpers/currentUserHelpers';
import LearnAndEarnPage from '../main/pages/LearnAndEarn';
import LearnAndEarnProgressPage from '../main/pages/LearnAndEarnProgress';
import LearnAndEarnMoreInfo from '../main/pages/LearnAndEarnMoreInfo';
import { InviteFriends } from '../main/pages/referrals/InviteFriends';
import { CoveredCallWizard } from '../main/pages/wizards/CoveredCallWizard';
import { EnterOptionsTradingExperience } from '../main/pages/onboarding';
import { ClosedAccountDashboardContainer } from '../containers/Dashboard/ClosedAccountDashboardContainer';
import HistoryPage from '../containers/History/Screens/HistoryPage';
import TradePage from '../containers/Orders/TradePage';
// import OptionsOptIn from '../main/pages/OptionsOptIn';
// import TradeOption from '../main/pages/orders/TradeOption';
import { OnboardingHome } from '../main/pages/onboarding/Home';
import { UpdateUnverifiedInfo } from '../main/pages/onboarding/UpdateUnverifiedInfo';
import { Funding } from '../main/pages/funding/Funding';
import { ROUTES, STATIC_ROUTES } from '../constants/paths';
import { LinkBankAccount } from '../main/pages/funding/LinkBankAccount';
import { DepositFunds } from '../main/pages/funding/DepositFunds';
import { WithdrawFunds } from '../main/pages/funding/WithdrawFunds';
import { VerifyBankAccount } from '../main/pages/funding/VerifyBankAccount';
import { NonOnboardingLiveTradingSignUpHome } from '../main/pages/NonOnboardingLiveTradingSignUpHome';
import { PlaidBrokerageLink } from '../main/pages/PlaidBrokerageLink';
import { FinishAccountSetup } from '../main/pages/funding/interstitial/FinishAccountSetup';
import Rewards from '../main/pages/Rewards';
import { ManualDocumentUpload } from '../main/pages/ManualDocumentUpload';
import { ImportFailure } from '../containers/ImportConnections/ImportFailure';
import ImportResults from '../containers/ImportConnections/ImportResults';
import PortfolioExplorer from '../containers/PortfolioExplorer/PortfolioExplorer';
import Playground from '../containers/Playground';
import IdeaLab from '../containers/IdeaLab/IdeaLab';
import Page from '../components/layout/Page';
import { parseQueryString } from '../helpers/routerHelpers';
import SecurityCardContainer from '../containers/SecurityCard/SecurityCardContainer';
import ChartContainer from '../components/Charts/ChartContainer';
import UserSettingsContainer from '../containers/Account/UserSettingsContainer';
import PeopleContainer from '../containers/People/PeopleContainer';
import FeaturedSubscriptionSelectionPage from '../containers/viewpoints/FeaturedSubscriptionSelectionPage';
import ExploreViewpointsPage from '../containers/viewpoints/ExploreViewpointsPage';
import ViewpointSubscriptionsManagementPage from '../containers/viewpoints/ViewpointSubscriptionsManagementPage';
import ViewpointProfileContainer from '../containers/viewpoints/ViewpointProfileContainer';
import NetworksList from '../containers/ImportConnections/NetworksList';
import ThoughtPage from '../containers/Thoughts/ThoughtPage';
import styled from 'styled-components';
import { ManageRecurringDeposits } from '../main/pages/funding/ManageRecurringDeposits';
import LearnAndEarnHome from '../main/pages/LearnAndEarnHome';
import { Modal } from '../main/components/layout';
import { _Dev } from '../main/pages/_Dev';
import { CreateRecurringDeposits } from '../main/pages/funding/CreateRecurringDeposits';
import { PortfolioAnalysis } from '../main/pages/PortfolioAnalysis';
import { Explore } from '@src/main/pages/Explore';
import { FamousInvestors } from '@src/main/pages/FamousInvestors';
import { LearnAndEarnEntry } from '../main/pages/LearnAndEarnEntry';
import { LearnAndEarnLessonReview } from '../main/pages/LearnAndEarnLessonReview';
import { Watchlist } from '../main/pages/MyIdeas';
import { UserProfile } from '../main/pages/UserProfile';
import { Orders } from '../main/pages/Orders';
import { LearnAndEarnEntryDebug } from '@src/main/pages/LearnAndEarnEntryDebug';
import { CreateOrder } from '@src/main/pages/CreateOrder';
import { ORDER_TRANSACTION_TYPES, ORDER_TYPES } from '@src/main/constants/orders';
import { Support } from '@src/main/pages/Support';
import { webappRoutes } from '@src/main/lib/nvstr-utils.es';
import { TrustedContact } from '@src/main/pages/interstitial/TrustedContact';
import { ProConGenerator } from '@src/main/pages/ProConGenerator';
import { LearnAndEarnLessonContentEditor } from '../main/pages/LearnAndEarnLessonContentEditor';
import { ThemeBuilder } from '@src/main/pages/ThemeBuilder';

const SecurityPageWrapper = styled.div`
  margin: 0 auto;
  max-width: 1400px;
`;

const SecurityWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id: securityIdentifier } = useParams();
  const securityIdentifierKey = isNaN(securityIdentifier) ? 'symbol' : 'securityId';
  const securityIdentifierValue =
    securityIdentifierKey === 'securityId' ? parseInt(securityIdentifier, 10) : securityIdentifier.toUpperCase();
  const query = parseQueryString(location.search);
  const activeTab = query.activeTab || (query.thought ? 'thoughts' : null);
  const additionalTabData = query.ideaId ? { ideaId: query.ideaId } : null;
  const panelIsShowing = query.panel;

  return (
    <SecurityPageWrapper>
      <SecurityCardContainer
        key={`security-page-${securityIdentifierKey}-${securityIdentifier}`}
        securityIdentifier={{
          [securityIdentifierKey]: securityIdentifierValue,
        }}
        activeTab={activeTab}
        additionalTabData={additionalTabData}
        navigate={navigate}
        location={location}
        panelIsShowing={panelIsShowing}
        isPanel={false}
      />
    </SecurityPageWrapper>
  );
};

const EnhancedRoute = ({ Component }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return <Component navigate={navigate} location={location} />;
};

const AuthedSignUpRedirect = () => <Navigate to={'/'} replace={true} />;

const AdminPriceChartsWrapper = () => {
  const location = useLocation();
  const query = parseQueryString(location.search);
  const securityIdsCSV = query.ids;
  const securityIds = securityIdsCSV ? securityIdsCSV.split(',').map((id) => parseInt(id)) : [];
  return (
    <Page>
      {securityIds.map((secId) => (
        <div className="security-card-container-side-panel security-card-fin-panel" key={`${secId}-outerdiv`}>
          <div className="security-panel-heading-chart-container" key={`${secId}-innerdiv`}>
            {secId}
            <ChartContainer key={secId} chartContext={'Admin'} securityId={secId} />
          </div>
        </div>
      ))}
    </Page>
  );
};

const ActivityPageRedirect = () => {
  return <Navigate to="/?tab=feed" />;
};

const ViewpointProfileWrapper = () => {
  const { name } = useParams();
  return <ViewpointProfileContainer nameParam={name} />;
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const ThoughtPageWrapper = () => {
  const { id } = useParams();
  return <ThoughtPage key={`thought-page-thought-id-${id}`} thoughtId={id} />;
};

class RouterContainer extends React.PureComponent {
  render() {
    if (this.props.hidden) {
      return null;
    }

    if (this._isClosedAccount()) {
      return (
        <div id="main-router" className={'routes-container'}>
          <SentryRoutes>
            <Route key="terms" path="/terms" element={<EnhancedRoute Component={BDTerms} />} />,
            <Route
              key="privacy_settings"
              path="/privacy_settings"
              element={<EnhancedRoute Component={PrivacySettingsPage} />}
            />
            <Route
              key="live_accounts/documentation"
              path="/live_accounts/documentation"
              element={<EnhancedRoute Component={ManualDocumentUpload} />}
            />
            <Route key="documents" path="/documents" element={<EnhancedRoute Component={DocumentsPage} />} />,
            <Route
              key="upload_required_documents"
              path="/upload-required-documents"
              element={<EnhancedRoute Component={UpdateUnverifiedInfo} />}
            />
            <Route
              key="live_accounts/documentation"
              path="/live_accounts/documentation"
              element={<EnhancedRoute Component={ManualDocumentUpload} />}
            />
            <Route path="/portfolio/history" element={<EnhancedRoute Component={HistoryPage} />} />,
            <Route path="/support" element={<Support />} />,
            <Route path="/" element={<EnhancedRoute Component={ClosedAccountDashboardContainer} />} />,
          </SentryRoutes>
        </div>
      );
    }

    if (this._isUserAuthenticated()) {
      return (
        <div id="main-router" className={'routes-container'}>
          <SentryRoutes>
            {this._renderPublicRoutes()}
            {this._renderProtectedRoutes()}
          </SentryRoutes>
          {this._shouldRenderFooter() && <Footer />}
        </div>
      );
    }

    return (
      <div id="main-router" className={'routes-container'}>
        <SentryRoutes>{this._renderPublicRoutes()}</SentryRoutes>
        {this._shouldRenderFooter() && <Footer />}
      </div>
    );
  }

  _renderPublicRoutes = () => {
    const showAdminPriceCharts =
      window.origin &&
      (window.origin.includes('s1-forerunner.nvstr.com') ||
        window.origin.includes('d1-forerunner.nvstr.com') ||
        window.origin.includes('localhost'));

    return (
      <>
        <Route key="securitiesId" path="/securities/:id" element={<SecurityWrapper />} />

        <Route path="/thoughts/:id/:thoughtSlug" element={<ThoughtPageWrapper />} />
        <Route path="/thoughts/:id" element={<ThoughtPageWrapper />} />

        <Route path="/viewpoint_contributors/:name" element={<ViewpointProfileWrapper />} />
        <Route
          key="covered-call-wizard"
          path="/covered-call-wizard"
          element={<EnhancedRoute Component={CoveredCallWizard} />}
        />
        {showAdminPriceCharts ? (
          <Route key="admin-price-charts" path="/admin-price-charts" element={<AdminPriceChartsWrapper />} />
        ) : null}
      </>
    );
  };

  _renderProtectedRoutes = () => (
    <>
      {/*<Route key="dev" path="/dev" element={<_Dev />} />,*/}
      {this.props.isContentEditor && (
        <Route key="learn-and-earn-entry" path="/learn-and-earn-entry" element={<LearnAndEarnEntry />} />
      )}
      {this.props.isContentEditor && (
        <Route
          key="learn-and-earn-content-editor"
          path="/learn-and-earn-content-editor"
          element={<LearnAndEarnLessonContentEditor />}
        />
      )}
      {this.props.isTornadoEmployee && (
        <Route
          key="learn-and-earn-entry-debug"
          path="/learn-and-earn-entry-debug"
          element={<LearnAndEarnEntryDebug />}
        />
      )}
      {this.props.isTornadoEmployee && (
        <Route
          key="learn-and-earn-lesson-review"
          path="/learn-and-earn-lesson-review"
          element={<LearnAndEarnLessonReview />}
        />
      )}
      {this.props.isTornadoEmployee && <Route key="theme-builder" path="/theme-builder" element={<ThemeBuilder />} />}
      {this.props.currentUser.is_ctl_team && (
        <Route key="pro-con-generator" path="/pro-con-generator" element={<ProConGenerator />} />
      )}
      <Route key="terms" path="/terms" element={<EnhancedRoute Component={BDTerms} />} />
      <Route key="onboarding" path="/get-started" element={<EnhancedRoute Component={OnboardingHome} />} />
      <Route key="onboarding-legacy-path" path="/onboard" element={<EnhancedRoute Component={OnboardingHome} />} />
      <Route
        key="live-account-signup"
        path="/live_accounts/new"
        element={<EnhancedRoute Component={NonOnboardingLiveTradingSignUpHome} />}
      />
      <Route
        key="external-brokerage-link"
        path="/link-brokerage-account"
        element={<EnhancedRoute Component={PlaidBrokerageLink} />}
      />
      <Route
        key="finish-account-setup"
        path="/finish-account-setup"
        element={<EnhancedRoute Component={FinishAccountSetup} />}
      />
      <Route
        key="live_account_submission_status"
        path="/live-account-submission-status"
        element={<EnhancedRoute Component={LiveAccountSubmissionStatus} />}
      />
      <Route
        key="upload_required_documents"
        path="/upload-required-documents"
        element={<EnhancedRoute Component={UpdateUnverifiedInfo} />}
      />
      <Route
        key="live_accounts/documentation"
        path="/live_accounts/documentation"
        element={<EnhancedRoute Component={ManualDocumentUpload} />}
      />
      <Route key="invite-friends" path="/invite-friends" element={<EnhancedRoute Component={InviteFriends} />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/order/:symbol" element={<CreateOrder />} />
      <Route path="/buy/:symbol" element={<CreateOrder transactionType={ORDER_TRANSACTION_TYPES.buy} />} />
      <Route path="/sell/:symbol" element={<CreateOrder transactionType={ORDER_TRANSACTION_TYPES.sell} />} />
      <Route path="/order" element={<CreateOrder />} />
      <Route exact path="/ideas/portfolio_explorer" element={<EnhancedRoute Component={PortfolioExplorer} />} />
      <Route exact path="/ideas/explore" element={<EnhancedRoute Component={Explore} />} />
      <Route exact path="/ideas/playground" element={<EnhancedRoute Component={Playground} />} />
      <Route exact path="/ideas/community" element={<EnhancedRoute Component={IdeaLab} />} />
      <Route path="/ideas" element={<Watchlist />} />
      <Route path="/watchlist" element={<Watchlist />} />
      <Route path="/users/:id/public_profile" element={<UserProfile />} />
      <Route path="/users/sign_up" exact element={<AuthedSignUpRedirect />} />
      <Route path="/users/edit" element={<UserSettingsContainer />} />
      <Route path="/users/connections" element={<PeopleContainer />} />
      <Route path="/users/thought_leaders" element={<FamousInvestors />} />
      <Route path="/support" element={<Support />} />
      <Route
        key="featured_subscriptions"
        path="/viewpoint_contributors/featured"
        element={<FeaturedSubscriptionSelectionPage />}
      />
      <Route
        key="explore_viewpoint_subscriptions"
        path="/viewpoint_contributors/explore"
        element={<ExploreViewpointsPage />}
      />
      <Route
        key="viewpoint_subscriptions"
        path="/viewpoint_contributors/manage"
        element={<ViewpointSubscriptionsManagementPage />}
      />
      <Route path="/notifications" element={<ActivityPageRedirect />} />;
      <Route exact path="/invites/provider" element={<EnhancedRoute Component={NetworksList} />} />
      <Route exact path="/invites/:network/contact_callback" element={<EnhancedRoute Component={ImportResults} />} />
      <Route exact path="/invites/:network/contact_failure" element={<EnhancedRoute Component={ImportFailure} />} />
      <Route
        key={'trusted-contact'}
        path={webappRoutes.trustedContact}
        element={<EnhancedRoute Component={TrustedContact} />}
      />
      <Route
        key={'funding-recurring-deposits'}
        path={ROUTES.RECURRING_DEPOSITS.build()}
        element={<EnhancedRoute Component={ManageRecurringDeposits} />}
      />
      <Route
        key={'create-recurring-deposits'}
        path={STATIC_ROUTES.createRecurringDeposits}
        element={<EnhancedRoute Component={CreateRecurringDeposits} />}
      />
      <Route
        key={'funding-deposit'}
        path={ROUTES.DEPOSIT_FUNDS.build()}
        element={<EnhancedRoute Component={DepositFunds} />}
      />
      <Route
        key={'funding-withdraw'}
        path={ROUTES.WITHDRAW_FUNDS.build()}
        element={<EnhancedRoute Component={WithdrawFunds} />}
      />
      <Route
        key={'funding-link-bank'}
        path={ROUTES.LINK_BANK.build()}
        element={<EnhancedRoute Component={LinkBankAccount} />}
      />
      <Route
        key={'funding-link-bank'}
        path={ROUTES.VERIFY_BANK_ACCOUNT.build()}
        element={<EnhancedRoute Component={VerifyBankAccount} />}
      />
      <Route key={'portfolio-funding'} path="/portfolio/funding" element={<EnhancedRoute Component={Funding} />} />
      <Route key={'funding'} path={ROUTES.FUNDING.build()} element={<EnhancedRoute Component={Funding} />} />
      <Route path="/portfolio/history" element={<EnhancedRoute Component={HistoryPage} />} />
      <Route path="/portfolio" element={<EnhancedRoute Component={PortfolioAnalysis} />} />
      <Route
        key="privacy_settings"
        path="/privacy_settings"
        element={<EnhancedRoute Component={PrivacySettingsPage} />}
      />
      <Route key="ask" path="/ask" element={<EnhancedRoute Component={AskPage} />} />
      <Route key="referrals" path="/referrals" element={<EnhancedRoute Component={Rewards} />} />
      <Route
        key="rewards_redemption"
        path="/rewards_redemption"
        element={<EnhancedRoute Component={RewardRedemptionPage} />}
      />
      <Route key="learn-and-earn" path="/learn-and-earn" element={<EnhancedRoute Component={LearnAndEarnPage} />} />
      <Route key="learn-and-earn-home" path="/learn-and-earn-home" element={<LearnAndEarnHome />} />
      <Route
        key="learn-and-earn-progress"
        path="/learn-and-earn-progress"
        element={<EnhancedRoute Component={LearnAndEarnProgressPage} />}
      />
      <Route
        key="learn-and-earn-more-info"
        path="/learn-and-earn-more-info"
        element={<EnhancedRoute Component={LearnAndEarnMoreInfo} />}
      />
      <Route key="subscriptions" path="/subscriptions" element={<EnhancedRoute Component={NvstrXSignup} />} />
      <Route path="/thoughts/:id/:thoughtSlug" element={<ThoughtPageWrapper />} />
      <Route path="/thoughts/:id" element={<ThoughtPageWrapper />} />
      <Route exact path="/contacts/success" element={<EnhancedRoute Component={ImportResults} />} />
      <Route exact path="/contacts/failure" element={<EnhancedRoute Component={ImportFailure} />} />
      <Route key="share" path="/share" element={<EnhancedRoute Component={DashboardContainer} />} />
      <Route key="documents" path="/documents" element={<EnhancedRoute Component={DocumentsPage} />} />
      {this._isOnboarding() ? (
        <Route path="/" element={<EnhancedRoute Component={OnboardingHome} />} />
      ) : (
        <Route path="/*" element={<EnhancedRoute Component={DashboardContainer} />} />
      )}
    </>
  );

  _shouldRenderFooter = () => this.props.location.pathname.indexOf('share') < 0 && !this._isOnDashboard();

  _isOnDashboard = () => !this._isOnboarding() && this.props.location.pathname === '/';

  _isOnboarding = () => isCurrentUserOnboarding(this._returnCurrentUser());

  _isUserAuthenticated = () => this._returnCurrentUser() && !!this._returnCurrentUser().user_id;

  _isClosedAccount = () => this._returnCurrentUser() && this._returnCurrentUser().is_account_closed;

  _returnCurrentUser = () => this.props.currentUser;
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    isTornadoEmployee: state.currentUser.is_nvstr_employee,
    isContentEditor: state.currentUser.is_content_editor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(RouterContainer);
export default withRouter(connected);
