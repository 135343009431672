import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import PageLoading from '../../components/PageLoading';

import { createQueryString, parseQueryString } from '../../helpers/routerHelpers';
import { closePanel } from '../../helpers/navigationHelpers';

class IdeaCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      securityId: null,
      loadingSecurityId: true,

      // not implemented
      invalidIdea: false,
      notConnectedToIdeaOwner: false,
    };
  }

  componentDidMount() {
    const ideaId = this.props.ideaId;

    const request = this.props.actions.fetchSecurityIdFromIdeaId(ideaId);
    request.then((ideaSecurities) => {
      const data = ideaSecurities[0];
      const securityId = data?.security_id;
      const ideaId = data?.id;
      const errorMessage = data?.error;

      const stateUpdate = {};

      if (securityId) {
        stateUpdate.securityId = securityId;

        if (errorMessage) {
          this.createOpenPanelErrorMessage(errorMessage);
        }
      } else {
        this.createOpenPanelErrorMessage(errorMessage);
      }

      this.setState((prevState) => ({
        ...stateUpdate,
        loadingSecurityId: false,
      }));
    });
  }

  componentDidUpdate() {
    if (!this.state.loadingSecurityId) {
      const queryString = this.props.location.search;
      const ideaQuery = parseQueryString(queryString);
      const securityId = this.state.securityId;

      let query = {
        panel: 'security',
        securityId: securityId,
        activeTab: 'ideas',
        ideaId: ideaQuery.ideaId,
      };
      query = { ...ideaQuery, ...query, ...this.filterQueryKeys(ideaQuery) };

      const securityPanelQuery = createQueryString(query);
      this.props.navigate(this.props.location.pathname + securityPanelQuery);
    }
  }

  filterQueryKeys = (query) => {
    const standardKeys = ['panel', 'securityId', 'activeTab', 'ideaId'];
    const filteredQuery = {};

    const queryKeys = Object.keys(query);
    const filteredKeys = queryKeys.filter((key) => !standardKeys.includes(key));
    filteredKeys.forEach((key) => {
      filteredQuery[key] = query[key];
    });

    return filteredQuery;
  };

  createOpenPanelErrorMessage = (errorMessage) => {
    const userDoesNotHavePermissionToViewIdea = errorMessage === 'User does not have permission to view Idea.';
    if (userDoesNotHavePermissionToViewIdea) {
      errorMessage = 'You do not have permission to view that idea.';
      closePanel(this.props.navigate);
    }

    const ideaNoLongerExists = errorMessage === 'Idea no longer exists.';
    const queryString = this.props.location.search;
    const ideaQuery = parseQueryString(queryString);

    if (ideaNoLongerExists && ideaQuery.removedIdea) {
      // don't show modal
      return true;
    }

    const component = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {errorMessage}
      </div>
    );
    const modal = {
      contentComponent: component,
      dismissable: true,
    };
    this.props.actions.showModal(modal);
  };

  render() {
    if (this.state.loadingSecurityId) {
      return (
        <div className={`security-card-container loading`}>
          <PageLoading style={{ zIndex: 10000 }} flatStyle={true} />
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    ideas: state.ideas,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdeaCard);
