import React from 'react';
import styled from 'styled-components';

const ProgressBarWrapper = styled.div`
  overflow: hidden;

  border: 1px solid ${({ theme }) => theme.themeColors.border};

  //box-shadow: inset 0px 1px 2px 0px ${({ theme }) => theme.themeColors.border},
  //  0px 1px 0px -1px ${({ theme }) => theme.themeColors.border};
  //box-shadow: inset 0px 1px 2px 0px rgba(255, 255, 255, 0.5), 0px 1px 0px 0px #cccccc;
`;
const FillWrapper = styled.div`
  background-color: ${({ theme, color }) => (color ? color : theme.themeColors.primaryCtaButton)};

  min-width: 6px; // min fill prevents border radius overflow at start
  margin-left: -1px;
`;

const ProgressBar = ({
  completedCount,
  totalCount,

  LabelComponent,
}) => {
  const actualFillPercent = (completedCount / totalCount) * 100;
  const displayedFillPercent = Math.min(100, Math.max(1, actualFillPercent));

  return (
    <div className={'ui-progress-bar-container'}>
      <ProgressBarWrapper className={'ui-progress-bar'} style={{}}>
        <FillWrapper
          className={'fill'}
          style={{
            width: `${displayedFillPercent}%`,
            borderTopRightRadius: displayedFillPercent < 100 ? '4px' : '20px',
            borderBottomRightRadius: displayedFillPercent < 100 ? '4px' : '20px',
          }}
        />
      </ProgressBarWrapper>
      <div className="label-container">{LabelComponent}</div>
      <div className={'ui-progress-bar-count'}>{`${completedCount} / ${totalCount}`}</div>
    </div>
  );
};

export default ProgressBar;
