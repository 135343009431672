import React from 'react';

import LoadingIcon from '../../../../components/misc/LoadingIcon';
import EquityChart from '../../../../components/Charts/EquityChart';

import { customParseFloat } from '../../../../helpers/numberHelpers';
import { moment, formatLocalizedDateTime } from '../../../../helpers/timeHelpers';

const DEFAULT_CHART_CONFIG = {
  height: 200,
  width: 500,
  disableTooltips: false,
  pointRadius: 1,
  pointHoverRadius: 5,
  label: 'Value',
};

const Loading = () => {
  const loadingDivStyle = {
    textAlign: 'center',
    marginTop: '15px',
    marginLeft: '0',
    height: `${200}px`,
    width: '100%',
    paddingTop: '50px',
  };
  return (
    <div className={'loading-price-history-container'} style={loadingDivStyle}>
      <LoadingIcon icon="fading-3balls" size="small" />
    </div>
  );
};

const UpdatingMessage = ({ show }) => {
  if (!show) {
    return null;
  }

  const loadingDivStyle = {
    textAlign: 'center',
    marginTop: '-15px',
    marginLeft: '0',
    height: `${25}px`,
    width: '100%',
  };

  return (
    <div className={'loading-price-history-container'} style={loadingDivStyle}>
      <LoadingIcon icon="fading-3balls" size="small" />
      <div
        className="secondary-text-color"
        style={{
          display: 'inline-block',
          marginLeft: '18px',
        }}
      >
        Updating
      </div>
    </div>
  );
};

class EquityHistory extends React.PureComponent {
  render() {
    const {
      activeTab,
      isGettingEquityChartData,
      isUpdatingEquityChartData,
      additionalDatasets,

      TabsRenderer,
      PerformanceComparisonSecuritiesRenderer,
    } = this.props;

    if (isGettingEquityChartData) {
      return <Loading />;
    }

    const hidePerfComparison = activeTab && activeTab.name === 'max';
    return (
      <div className={'history-equity-container'}>
        {this._getEquityChartData() !== null && (
          <div className={'equity-chart-container'}>
            <UpdatingMessage show={isUpdatingEquityChartData} />

            <div className="equity-chart-wrapper">
              <EquityChart
                chartData={this._generateChartData()}
                // additionalDatasets={hidePerfComparison ? null : additionalDatasets}
              />
              {TabsRenderer}
              {/*{hidePerfComparison ? null : PerformanceComparisonSecuritiesRenderer}*/}
            </div>
          </div>
        )}
      </div>
    );
  }

  _generateChartData = () => {
    const values = this._generateChartValues();
    return {
      labels: this._generateChartLabels(),
      values,
      xAxisTicks: [],
      yAxisTicks: [],
    };
  };

  _generateChartLabels = () => {
    return this._getEquityChartData().map((equityData) => {
      if (equityData.time === null) {
        return null;
      }
      const epoch = equityData.time * 1000;
      const date = moment(epoch);
      return `${formatLocalizedDateTime('l', date)}`;
    });
  };

  _generateChartValues = () => {
    return this._getEquityChartData().map((equityData) => customParseFloat(equityData.equity));
  };

  _getEquityChartData = () => this.props.chartData;

  _getChartConfig = () => DEFAULT_CHART_CONFIG;
}

export default EquityHistory;
