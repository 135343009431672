import React from 'react';
import { Container } from '../components/ui';
import { Body5, Body6, LoadingSpinner } from '../lib/nvstr-common-ui.es';
import { Page } from '../components/layout';
import styled from 'styled-components';
import { FlatButton, SkeletonButton } from '@src/main/components/buttons';
import { LEARN_LESSON_TYPES } from '@src/main/constants/learn/types';
import { CheckCircleFilled } from '@src/main/icons';
import { postLessonToApi } from '@src/actions';
import { LessonEntry, LessonPreview } from '@src/main/containers/learn/admin';
import { coreContentScreens } from '@src/main/constants/learn/lessonDefinitions';
import { useDispatch, useSelector } from 'react-redux';
import { learnTypes } from '@src/main/constants/actionTypes';
import { emptyLessonPreview } from '@src/reducers/learnReducer';

const ErrorWrapper = styled.div`
  * {
    color: ${({ theme }) => theme.themeColors.error};
  }
`;
const CheckWrapper = styled.div`
  svg {
    height: 36px;
    width: 36px;

    path {
      fill: ${({ theme }) => theme.themeColors.positive};
    }
  }
`;
const PageWrapper = styled.div`
  display: flex;
`;
const EditModeWrapper = styled.div`
  button {
    border-radius: 0;
    width: 140px !important;
  }
`;

export const LearnAndEarnEntry = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.learn.previewLesson);

  const [isEditMode, setIsEditMode] = React.useState(false);
  const [isSubmittingToApi, setIsSubmittingToApi] = React.useState(false);
  const [isSaved, setIsSaved] = React.useState(false);
  const [savedLessonId, setSavedLessonId] = React.useState(null);
  const [error, setError] = React.useState(null);

  const onClear = () => {
    dispatch({
      type: learnTypes.reset,
    });
  };

  const onChange = (key, value) => {
    dispatch({
      type: learnTypes.updateLearnPreviewLesson,
      payload: {
        [key]: value,
      },
    });
  };

  const massageStateToDataForApi = (state) => {
    const { id, name, heading, phrase, minLevel, maxLevel, contents, blogDisclaimers, disclaimers } = state;
    const concatDisclaimers = [...Object.values(blogDisclaimers), ...Object.values(disclaimers)];

    if (isEditMode) {
      const formData = { lesson_id: id };
      if (name !== '') formData.name = name;
      if (heading !== '') formData.heading = heading;
      if (phrase !== '') formData.preview_phrase = phrase;
      if (minLevel !== '') formData.min_investing_experience = minLevel;
      if (maxLevel !== '') formData.max_investing_experience = maxLevel;
      if (contents.length) formData.json_lesson_content = contents;
      if (Object.keys(blogDisclaimers).length || Object.keys(disclaimers).length)
        formData.json_disclaimers_content = JSON.stringify(concatDisclaimers);
      return formData;
    } else {
      return {
        description: name,
        heading: heading,

        preview_phrase: phrase,

        content_type: LEARN_LESSON_TYPES.text,
        is_core_content: true,
        page_count: contents.length,
        min_investing_experience: minLevel,
        max_investing_experience: maxLevel,
        sponsor_id: null,

        json_lesson_content: contents,
        json_disclaimers_content: JSON.stringify(concatDisclaimers),
      };
    }
  };

  const handleAddAnotherPress = () => {
    setIsSaved(false);
  };

  const onSaveToApi = async () => {
    window.$('html, body').animate(
      {
        scrollTop: 0,
      },
      300
    );
    setIsSubmittingToApi(true);
    const form = massageStateToDataForApi(state);
    const { error, data } = await postLessonToApi(form);
    setIsSubmittingToApi(false);
    if (!error) {
      setSavedLessonId(data.lesson_id);
      setIsSaved(true);
      onClear();
    } else {
      setError('Something went wrong, try again.');
    }
  };

  return (
    <PageWrapper>
      <Container left={16} top={24}>
        <LessonPreview />
      </Container>

      <Page transparentBackground>
        {isSaved ? (
          <Container style={{ textAlign: 'center' }} top={72}>
            <CheckWrapper>
              <CheckCircleFilled />
            </CheckWrapper>
            <Container top={4}>
              <Body6>Saved</Body6>
            </Container>
            <Container top={4}>
              <Body6>Lesson ID: {savedLessonId}</Body6>
            </Container>
            <Container top={32}>
              <FlatButton onClick={handleAddAnotherPress}>Add Another</FlatButton>
            </Container>
          </Container>
        ) : isSubmittingToApi ? (
          <Container top={72}>
            <Container style={{ textAlign: 'center' }}>
              <CheckWrapper>
                <LoadingSpinner />
              </CheckWrapper>
              <Container top={16}>
                <Body6>Saving to server...</Body6>
              </Container>
            </Container>
          </Container>
        ) : (
          <div style={{ width: '600px' }}>
            <Container>
              <EditModeWrapper>
                {isEditMode ? (
                  <Container row centerAll>
                    <SkeletonButton onClick={() => setIsEditMode(false)}>Create</SkeletonButton>
                    <FlatButton onClick={() => setIsEditMode(true)}>Edit</FlatButton>
                  </Container>
                ) : (
                  <Container row centerAll>
                    <FlatButton onClick={() => setIsEditMode(false)}>Create</FlatButton>
                    <SkeletonButton onClick={() => setIsEditMode(true)}>Edit</SkeletonButton>
                  </Container>
                )}
              </EditModeWrapper>
            </Container>
            <Container top={16}>
              <LessonEntry isEditMode={isEditMode} onChange={onChange} state={state} dispatch={dispatch} />
            </Container>
            <Container top={64}>
              {error && (
                <ErrorWrapper>
                  <Container bottom={8}>
                    <Body5>{error}</Body5>
                  </Container>
                </ErrorWrapper>
              )}
              <Container top={8} centerAll>
                <FlatButton fullWidth onClick={onSaveToApi}>
                  Save
                </FlatButton>
              </Container>
              {isEditMode ? (
                <Container top={8}>
                  <Body5>
                    If you save edits, any input field that has a value will overwrite the value saved on the server.
                  </Body5>
                </Container>
              ) : null}
              <Container top={48} centerAll>
                <SkeletonButton color={'red'} hoverTextColor={'white'} fullWidth onClick={onClear}>
                  Clear
                </SkeletonButton>
              </Container>
            </Container>
          </div>
        )}
      </Page>
    </PageWrapper>
  );
};
