import React from 'react';

class PageDivider extends React.PureComponent {
  render() {
    const { style } = this.props;

    return <div className="page-divider border-accent" style={style}></div>;
  }
}

PageDivider.defaultProps = {
  style: {},
};

export default PageDivider;
