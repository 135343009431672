import React from 'react';
import { defineFontSettings } from '../../lib/nvstr-common-ui.es';
import styled from 'styled-components';
import { Text } from '../text';
import UnstyledLoadingSpinner from '../Images/UnstyledLoadingSpinner';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;

  > div:first-child {
    margin-right: 16px;
  }
`;
const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  text-align: center;
`;
const MessageText = styled.div`
  padding-top: 2px;
`;
const VerticalMessageText = styled.div`
  padding-top: 16px;
`;

export const LoadingMessage = ({ message, vertical, color }) => {
  const fontSettings = defineFontSettings(
    {
      fontSize: '16px',
      lineHeight: '28px',
    },
    {
      fontSize: '20px',
      lineHeight: '28px',
    }
  );

  if (vertical) {
    return (
      <VerticalWrapper>
        <UnstyledLoadingSpinner color={color} />
        <VerticalMessageText>
          <Text color={color} fontSettings={fontSettings}>
            {message || 'Loading...'}
          </Text>
        </VerticalMessageText>
      </VerticalWrapper>
    );
  }
  return (
    <Wrapper>
      <UnstyledLoadingSpinner color={color} />
      <MessageText>
        <Text color={color} fontSettings={fontSettings}>
          {message || 'Loading...'}
        </Text>
      </MessageText>
    </Wrapper>
  );
};
