import React from 'react';
import Header from './Header';
import Body from './Body';
import styled from 'styled-components';

const OuterWrapper = styled.div`
  height: 100%;
  min-width: 185px;
  z-index: 1100;
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};

  position: fixed;
  top: 0;
  left: 0;
  min-width: 185px;
  z-index: 1100;
  padding-bottom: 100px;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.themeColors.text};
  }
`;

const DesktopVerticalNav = (props) => {
  return (
    <OuterWrapper>
      <Wrapper>
        <Header />
        <Body />
      </Wrapper>
    </OuterWrapper>
  );
};

export default DesktopVerticalNav;
