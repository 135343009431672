import React from 'react';
import Button from '../components/buttons/Button';
import { FlatButton } from '../main/components/buttons';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};

  button {
    padding: 4px;
  }
`;

export const buildConfirmationComponent = function (customConfig) {
  // requires parent component to have a CSS position property

  // defaults
  const dismissConfirmationComponent = () => {
    this.setState((prevState) => ({
      showConfirmation: null,
    }));
  };
  const defaultMessage = 'This action cannot be undone, are you sure you wish to continue?';

  const confirmationAction = customConfig.confirmationAction || console.error('You must supply a confirmation action');
  const cancelAction = customConfig.cancelAction || dismissConfirmationComponent;
  const confirmationMessage = customConfig.confirmationMessage || defaultMessage;

  const confirmationButton = {
    className: customConfig.confirmationButtonClassName || 'btn btn-confirm-color btn-small-tall',
    text: customConfig.confirmationButtonText || 'Confirm',
  };
  const cancelButton = {
    className: customConfig.cancelButtonClassName || 'btn btn-cancel-color btn-small-tall',
    text: customConfig.cancelButtonText || 'Cancel',
  };

  const component = (
    <Wrapper className={`confirmation-tooltip-component-container`}>
      <div className={`confirmation-message-container`}>{confirmationMessage}</div>
      <div className={`confirmation-buttons-container`}>
        <FlatButton onClick={confirmationAction}>{confirmationButton.text}</FlatButton>
        <FlatButton onClick={cancelAction}>{cancelButton.text}</FlatButton>
      </div>
    </Wrapper>
  );
  return {
    confirmationAction,
    cancelAction,
    confirmationMessage,
    component,
  };
};
