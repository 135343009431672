import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/index';
import {
  findUser,
  isConnectionRequestSentExpired,
  hasRequestedConnection,
  isRequestingConnection,
  canRequestConnection,
  isAConnection,
} from '../../helpers/usersHelpers';
import styled from 'styled-components';
import { FlatButton } from '../../main/components/buttons';

const ConnectAcceptButtonWrapper = styled.div`
  button {
    width: 80px;
    padding: 0px 0;
  }
`;
const IgnoreButtonWrapper = styled.div`
  button {
    width: 80px;
    padding: 4px 0;
  }
`;

class ConnectionRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSendingConnectionRequest: false,
    };
  }

  render() {
    return (
      <span
        className={`
        connection-request-container
        ${this._hasRequestedConnection() ? 'waiting-approval' : ''}
        ${this._canRequestConnection() ? 'can-request-connection' : ''}
      `}
      >
        {this._renderButtonMessage()}
        {this._renderButtonState()}
      </span>
    );
  }

  _renderButtonMessage = () => {
    if (this._shouldShowMessage()) {
      return <span className={'button-message'}>{this._returnButtonMessage()}</span>;
    } else {
      return null;
    }
  };

  _renderButtonState = () => {
    if (this._isUserSelf() || this._returnUser() === null) {
      return null;
    }

    if (this._isAcceptingConnection()) {
      // other user requested already
      return this.renderAcceptingAnswerConnectionRequestButton();
    } else if (this._hasExpiredConnectionRequestSent()) {
      // if user request expired
      return this.renderRerequestConnectionRequestButton();
    } else if (this._hasRequestedConnection()) {
      // current user requested already
      return this.renderAnswerConnectionRequestButton();
    } else if (this._isRequestingConnection()) {
      // current user requested already
      return this.renderAlreadyRequestedButton();
    } else if (this._isAConnection()) {
      // connected
      return this.renderAlreadyConnected();
    } else {
      // available to request
      return this.renderConnectionRequestButton();
    }
  };

  renderConnectionRequestButton = () => {
    return (
      <ConnectAcceptButtonWrapper>
        <FlatButton smallNoVert onClick={this._handleConnectionRequestClick}>
          Connect
        </FlatButton>
      </ConnectAcceptButtonWrapper>
    );
  };

  renderRerequestConnectionRequestButton = () => {
    return (
      <ConnectAcceptButtonWrapper>
        <FlatButton small onClick={this._handleConnectionRequestClick}>
          Resend
        </FlatButton>
      </ConnectAcceptButtonWrapper>
    );
  };

  renderAlreadyRequestedButton = () => {
    return (
      <FlatButton transparent small onClick={this._ignoreClick}>
        Sent
      </FlatButton>
    );
  };

  renderAlreadyConnected = () => {
    return (
      <FlatButton transparent small onClick={this._ignoreClick}>
        Connected
      </FlatButton>
    );
  };

  _returnButtonMessage = () => {
    if (this._hasRequestedConnection()) {
      const userFirstName = this.props.userFirstName;
      return `${userFirstName} has requested to connect with you.`;
    }
    if (this._canRequestConnection()) {
      return 'Do you want to send a connection request?';
    }
    if (this._isRequestingConnection()) {
      const userFirstNamePossessive = this.props.userFirstNamePossessive;
      return `Connection request is awaiting ${userFirstNamePossessive} approval.`;
    }

    return null;
  };

  renderAnswerConnectionRequestButton = () => {
    return (
      <span className={'approve-connection-request-buttons-container'}>
        <ConnectAcceptButtonWrapper>
          <FlatButton onClick={this._handleApproveConnectionClick}>Connect</FlatButton>
        </ConnectAcceptButtonWrapper>
        {!this.props.hideIgnoreBtn && (
          <IgnoreButtonWrapper>
            <FlatButton transparent onClick={this._handleIgnoreConnectionClick}>
              Ignore
            </FlatButton>
          </IgnoreButtonWrapper>
        )}
      </span>
    );
  };

  renderAcceptingAnswerConnectionRequestButton = () => {
    return (
      <span className={'approve-connection-request-buttons-container'}>
        <ConnectAcceptButtonWrapper>
          <FlatButton transparent disabled onClick={this._ignoreClick}>
            Connecting
          </FlatButton>
        </ConnectAcceptButtonWrapper>
        {!this.props.hideIgnoreBtn && (
          <IgnoreButtonWrapper>
            <FlatButton transparent onClick={this._handleIgnoreConnectionClick}>
              Ignore
            </FlatButton>
          </IgnoreButtonWrapper>
        )}
      </span>
    );
  };

  _handleConnectionRequestClick = () => {
    this._sendConnectionRequestRequest();
    this._logConnectionRequestEvent();
  };

  _sendConnectionRequestRequest = () => {
    const userId = this._returnUserId();
    return this.props.actions.sendConnectionRequest(userId).then((response) => {
      const wasSuccessful = response && response.data && !('error' in response.data);
      if (!wasSuccessful) {
        this.createConnectionRequestFailureMessage();
      }
    });
  };

  _startAcceptingConnectionRequest = () => {
    this.setState(() => ({
      isAcceptingConnectionRequest: true,
    }));
  };

  _endAcceptingConnectionRequest = () => {
    this.setState(() => ({
      isAcceptingConnectionRequest: false,
    }));
  };

  _handleApproveConnectionClick = () => {
    this._startAcceptingConnectionRequest();
    const currentUserId = this._returnCurrentUserId();
    const userId = this._returnUserId();
    return this.props.actions.acceptConnectionRequest(userId, 'profile', currentUserId).then((response) => {
      const wasSuccessful = response && response.data;
      this._endAcceptingConnectionRequest();
      if (!wasSuccessful) {
        this.createConnectionRequestFailureMessage();
      }
    });
  };

  _handleIgnoreConnectionClick = () => {
    const userId = this._returnUserId();
    return this.props.actions.ignoreConnectionRequest(userId).then((response) => {
      const wasSuccessful = response && response.data && response.data.status === 'success';
      if (!wasSuccessful) {
        this.createConnectionRequestFailureMessage();
      }
    });
  };

  _shouldShowMessage = () => this._returnButtonMessage() && this.props.showMessage;

  _isAcceptingConnection = () => this.state.isAcceptingConnectionRequest;

  _returnBtnSizeClassName = () => this.props.btnSizeClassName || 'btn-small-tall';

  _ignoreClick = () => false;

  _hasExpiredConnectionRequestSent = () => isConnectionRequestSentExpired(this._returnUser());

  _hasRequestedConnection = () => hasRequestedConnection(this._returnUser());

  _isRequestingConnection = () => isRequestingConnection(this._returnUser());

  _canRequestConnection = () => canRequestConnection(this._returnUser());

  _isAConnection = () => isAConnection(this._returnUser());

  _returnUserId = () => this.props.userId;

  _returnUser = () => findUser(this._returnUserId(), this.props.users, this.props.currentUser);

  _returnCurrentUser = () => this.props.currentUser;

  _returnCurrentUserId = () => this._returnCurrentUser().user_id;

  _isUserSelf = () => this._returnCurrentUserId() === this._returnUserId();

  createConnectionRequestFailureMessage = () => {
    const generalComponent = (
      <span className="modal-message" style={{ paddingTop: '0px' }}>
        {'Could not request connection at this time. Please try again later.'}
      </span>
    );
    this.props.actions.showModal({
      contentComponent: generalComponent,
      dismissable: true,
      size: 'wide',
    });
  };

  _logConnectionRequestEvent = () => {
    const viewingContext = this.props.viewingContext;
    if (!viewingContext) {
      console.error('Please supply a viewingContext prop to log where the click event is coming from.');
    }
    const event = 'Clicked Send Connection Request';
    const properties = {
      Context: viewingContext,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionRequest);
