import React from 'react';
import styled from 'styled-components';
import { logMetricsTrackingEvent } from '../../actions';
import { PRODUCT_DISPLAY_NAME } from '../../appConfig';
import { Page } from '../../main/components/layout';
import { SkeletonButton } from '../../main/components/buttons';
import { Body1, Body3 } from '../../main/lib/nvstr-common-ui.es';
import { ROUTES } from '../../constants/paths';
import { EMAILS } from '../../main/constants';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@src/main/hooks/user';
import { SupportEmail } from '@src/components/UI/messaging/ContactSupport';

const AccountClosedWrapper = styled.div`
  text-align: center;
  padding-top: 24px;
`;

const MessageWrapper = styled.div`
  text-align: center;

  padding-top: 16px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding-top: 24px;
`;

export const ClosedAccountDashboardContainer = ({}) => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const isActionToReopenAvailable = currentUser.is_action_required_to_reopen_account;

  React.useEffect(() => {
    document.title = `${PRODUCT_DISPLAY_NAME} - Dashboard`;

    const event = 'View Dashboard';
    logMetricsTrackingEvent(event)();
  }, []);

  const handleViewEDocsPress = React.useCallback(() => {
    navigate(ROUTES.E_DOCS.build());
  }, []);

  return (
    <Page>
      <AccountClosedWrapper>
        {isActionToReopenAvailable ? (
          <Body1 bold center>
            Please check your email for next steps and contact <SupportEmail>{EMAILS.support}</SupportEmail> if you have
            any questions.
          </Body1>
        ) : (
          <>
            <Body1 bold center>
              This account has been closed.
            </Body1>
            <MessageWrapper>
              <Body3>
                If you did not request this, or you believe this was in error, please contact{' '}
                <SupportEmail>{EMAILS.support}</SupportEmail>
              </Body3>
            </MessageWrapper>
          </>
        )}
      </AccountClosedWrapper>
      <ActionsContainer>
        <SkeletonButton fullWidth onClick={handleViewEDocsPress}>
          View Account Documents
        </SkeletonButton>
      </ActionsContainer>
    </Page>
  );
};
