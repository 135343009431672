export const REGEX_DICT = {
  // advanced url needs more testing
  // URL_ADVANCED: new RegExp(/(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/ig),
  URL: new RegExp(/https?:\/\/[\S]+/g),
  ONLY_POSITIVE_INTEGERS: new RegExp('^([0-9]+)$'),
  ONLY_INTEGERS: new RegExp('^(-?[0-9])$|^(-?[0-9]+)$'),
  VALID_NUMBER: new RegExp('^(-?[0-9]+.?[0-9]*)$'),
  VALID_POSITIVE_NUMBER: new RegExp('^([0-9]+.?[0-9]*)$'),

  PO_BOX: new RegExp('\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b', 'i'),

  PHONE_DASH: new RegExp('^[2-9]\\d{2}-[2-9]\\d{2}-\\d{4}$'),
  PHONE_NO_DASH: new RegExp('^[0-9]{10}$'),

  SSN: new RegExp(/^\d{3}-?\d{2}-?\d{4}$/),
  SSN_NO_DASH: new RegExp('^[0-9]{9}$'),

  IDENTIFICATION_NUMBER: new RegExp('^([0-9]+[-]?[0-9]*[-]?[0-9]*)$'),

  CALIFORNIA_RULES: new RegExp(/^[a-z-.'\s?]+$/i),
};
