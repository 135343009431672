import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withWindowSize from '../../HOCS/withWindowSize';
import { withRouter } from '../../../main/utils';
import * as Actions from '../../../actions/index';

import TourArrow from '../../../assets/SVGS/TourArrow';

import { returnCurrentUserId, isCurrentUserOnboarding } from '../../../helpers/currentUserHelpers';
import { returnTourConfig, isEndOfTour } from '../../../constants/tour';
import styled from 'styled-components';

const Wrapper = styled.span`
  border: 3px solid ${({ theme }) => theme.themeColors.primaryCtaButton} !important;

  .stand-alone-arrow {
    //fill: 3px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
    * {
      fill: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }
`;

export class HighlightComponent extends Component {
  constructor() {
    super();
    this.state = {
      forceHighlightToRender: false,
      timeoutForForceRender: null,
    };
  }

  componentDidMount() {
    if (this._showHighlight()) {
      this.initializedHighlightAdditionalFunctionality = true;
      this._addClickListenerToParentNode();
      this._runSetupActions();
    }
  }

  componentWillUnmount() {
    this._removeClickListenerToParentNode();

    if (this.state.timeoutForForceRender) {
      this._clearTimeoutForForceRender();
    }
  }

  componentDidUpdate(prevProps) {
    if (this._showHighlight() && !this.initializedHighlightAdditionalFunctionality) {
      this.initializedHighlightAdditionalFunctionality = true;
      this._addClickListenerToParentNode();
      this._runSetupActions();
    }
    if (this._showHighlight(prevProps) && !this._showHighlight()) {
      this._removeClickListenerToParentNode();
    }

    if (!this._showHighlight()) {
      this.initializedHighlightAdditionalFunctionality = false;
    }

    if (this.props.isEndOfTourHighlight && this._isOnTour(prevProps) && !this._isOnTour()) {
      this._forceHighlightToRender();
    }
  }

  render() {
    if (!this._showHighlight()) {
      return null;
    }

    return (
      <Wrapper
        ref={(el) => (this.HighlightComponent = el)}
        className={`highlight-component ${
          this.props.isEndOfTourHighlight ? 'no-animations' : ''
        } ${this._returnCircleColorSchemeStyling()}`}
        style={this._returnStyling()}
      >
        {this._renderArrow()}
      </Wrapper>
    );
  }

  _renderArrow = () => this._shouldShowArrow() && this._returnArrow();

  _returnArrow = () => {
    return (
      <span
        className={`stand-alone-arrow ${this.props.isEndOfTourHighlight ? 'no-animations' : ''}`}
        style={this._returnArrowStyling()}
      >
        <TourArrow
          id={`tour-arrow-step-${this._returnHighlightTourStep()}`}
          className={this._returnArrowColorSchemeStyling()}
          height={this._returnConfigArrowStyle().height || '100px'}
          width={this._returnConfigArrowStyle().width || '100px'}
        />
      </span>
    );
  };

  _returnConfig = () => returnTourConfig.call(this, this._returnHighlightTourStep());

  _returnConfigArrowStyle = () =>
    this._returnConfig().returnArrowStyle ? this._returnConfig().returnArrowStyle(this._returnScreenWidth()) : {};

  _returnConfigStyle = () =>
    this._returnConfig().returnStyle ? this._returnConfig().returnStyle(this._returnScreenWidth()) : {};

  _runSetupActions = () =>
    this._returnConfig().setupActions && this._returnConfig().setupActions.call(this, this._returnScreenWidth());

  _returnConfigShowOnRoute = () => this._returnConfig().showOnRoute;

  _shouldShowArrow = () =>
    this._returnConfig().shouldShowArrow &&
    this._returnConfig().shouldShowArrow(this._returnScreenWidth(), {
      horizontalListPage: this.props.horizontalList.page,
    });

  _returnArrowStyling = () =>
    this._returnConfig().returnArrowStyle ? this._returnConfig().returnArrowStyle(this._returnScreenWidth()) : {};

  _returnColorScheme = () =>
    this._returnConfig().returnColorScheme ? this._returnConfig().returnColorScheme(this._returnScreenWidth()) : {};

  _returnCircleColorSchemeStyling = () =>
    this._returnColorScheme() ? this._returnColorScheme().circleColorClassName : '';

  _returnArrowColorSchemeStyling = () =>
    this._returnColorScheme() ? this._returnColorScheme().arrowColorClassName : '';

  _isLoading = () => !this._returnTourData().loaded;

  _showHighlight = (props = this.props) => {
    return (
      this._isOnCorrectPage() &&
      (this._shouldForceHighlightToRender() ||
        (this.props.isEndOfTourHighlight && isEndOfTour(this._returnUserTourStep()) && this._isOnTour(props)) ||
        (this._isOnTour(props) && this._returnUserTourStep(props) === this._returnHighlightTourStep(props)))
    );
  };

  _shouldForceHighlightToRender = () => this.state.forceHighlightToRender;

  _returnStyling = () => ({
    ...this._returnDefaultProperties(),
    ...this._returnConfigStyle(),
  });

  _returnDefaultProperties = () => ({
    position: 'absolute',
    top: '-5px',
    bottom: '-5px',
    right: '-5px',
    left: '-5px',
    zIndex: 1,
  });

  _logClickedTourAction = () => {
    if (!this.props.isEndOfTourHighlight && this._showHighlight()) {
      const event = 'Clicked Action In Tour';
      const properties = {
        'Current Tour Step': this._returnUserTourStep(),
      };
      this.props.actions.logMetricsTrackingEvent(event, properties);
    }
  };

  _handleParentClick = () => this._logClickedTourAction();

  _addClickListenerToParentNode = () => $(this._returnRefToParentNode()).on('click', this._handleParentClick);

  _removeClickListenerToParentNode = () => $(this._returnRefToParentNode()).off('click', this._handleParentClick);

  _returnRefToParentNode = () => this._$returnDOMnode().parent();

  _isParentPositionRelative = () => this._returnRefToParentNode().css('position') === 'relative';

  _returnScreenWidth = () => this.props.windowWidth;

  _$returnDOMnode = () => $(this._returnDOMnode());

  _returnDOMnode = () => this.HighlightComponent;

  _returnTourData = (props) => (props || this.props).tour;

  _returnUserTourStep = (props) => this._returnTourData(props).tour_step;

  _isOnTour = (props) => !this._isOnboarding(props) && this._returnTourData(props).is_on_tour;

  _isOnboarding = (props) => isCurrentUserOnboarding(this._returnCurrentUser(props));

  _returnHighlightTourStep = (props) => (props || this.props).tourStep;

  _returnCurrentUser = (props) => (props || this.props).currentUser || this.props.currentUser;

  _returnCurrentUserId = () => returnCurrentUserId(this.props.currentUser);

  _returnLocation = () => this.props.location;

  _returnLocationPathname = () => this._returnLocation().pathname;

  _returnLocationQuery = () => this._returnLocation().search;

  _returnFullURLMinusDomain = () => this._returnLocationPathname() + this._returnLocationQuery();

  _isOnCorrectPage = () => {
    const routeConfig = this._returnConfigShowOnRoute();
    if (routeConfig.exact) {
      return this._returnLocationPathname() === routeConfig.route;
    } else {
      return this._returnLocationPathname().indexOf(routeConfig.route) >= 0;
    }
  };

  _createTimeoutToResetForceRender = () => setTimeout(this._resetForceHighlightToRender, 2000);

  _clearTimeoutForForceRender = () => clearTimeout(this.state.timeoutForForceRender);

  _forceHighlightToRender = () => {
    this.setState(() => ({
      forceHighlightToRender: true,
      timeoutForForceRender: this._createTimeoutToResetForceRender(),
    }));
  };

  _resetForceHighlightToRender = () => {
    this.setState(() => ({
      forceHighlightToRender: false,
    }));
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    tour: state.tour,
    horizontalList: state.horizontalList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(
  withRouter,
  withWindowSize,
  connect(mapStateToProps, mapDispatchToProps)
)(HighlightComponent);

export default composedComponent;
