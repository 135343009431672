import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { showModal, logMetricsTrackingEvent, hideModal } from '../../actions';

import AskAnyone from '../buttons/AskAnyone';
import SearchContainer from '../UI/Search/SearchContainer';
import { PageHeading } from '../../components/UI/PageHeading';
import Button from '../../components/buttons/Button';

import { getRandomIntegerInRange } from '../../helpers/numberHelpers';
import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import { FlatButton } from '../../main/components/buttons';

export const MessageWrap = styled('div')`
  & div {
    border-radius: 6px;
    padding: 20px;
  }
`;

const AskCTLMessaging = (props) => {
  const {
    additionalClassNames,

    dispatch,
  } = props;

  const messages = [
    'Click a button, and get insights from Community Thought Leaders. They have a lot of experience - for example, one was an Institutional Investor-ranked analyst at Citi and Barclays, and another spent 15 years at a $2bn fund.',
    'Learn more about any stock in a click. Just ask a Community Thought Leader. They have a lot of experience - for example, one was an Institutional Investor-ranked analyst at Citi and Barclays, and another spent 15 years at a $2bn fund.',
    'Click a button, and get the Pros and Cons for any stock. Community Thought Leaders have a lot of experience - for example, one was an Institutional Investor-ranked analyst at Citi and Barclays, and another spent 15 years at a $2bn fund.',
    'Click a button, and get the Pros and Cons for any stock.',
  ];

  const [messageGroup, setMessageGroup] = useState(null);
  const [securityId, setSecurityId] = useState(null);

  const logViewAskCTL = (groupNumber) => {
    const message = messages[groupNumber];
    const event = 'View Ask CTL Messaging';
    const properties = {
      Context: 'Feed',
      'AB Group': groupNumber,
      Message: message,
    };
    logMetricsTrackingEvent(event, properties)();
  };

  useEffect(() => {
    // 1 and 2 have been dropped, select between 0 and 3
    const groupNumber = getRandomIntegerInRange(0, 1) === 1 ? 3 : 0;
    setMessageGroup(groupNumber);
    logViewAskCTL(groupNumber);
  }, []);

  if (isUndefinedOrNull(messageGroup)) {
    return null;
  }

  const handleStockSelection = (security) => {
    hideModal()(dispatch);
    setSecurityId(security.id);
  };

  const message = messages[messageGroup];

  const logAskCTLClick = () => {
    const event = 'Clicked Ask CTL Message';
    const properties = {
      'AB Group': messageGroup,
      Message: message,
    };
    logMetricsTrackingEvent(event, properties)();
  };

  const handleAskCTL = () => {
    openSelectStockAskModal();
    logAskCTLClick();
  };

  const openSelectStockAskModal = () => {
    const component = (
      <div>
        <PageHeading className=" ">Pick a stock to ask about</PageHeading>
        <div style={{ padding: '25px 0 0 0' }}>
          <SearchContainer
            id="ask_ctl_stock_search"
            icon="fa-search"
            parentComponent="Ask CTL"
            placeholder="Search Stocks..."
            handleSecurityQuerySelection={handleStockSelection}
          />
        </div>
      </div>
    );
    const selectStockAskModal = {
      contentComponent: component,
      size: 'wide',
      className: 'ask-someone-security-search-modal',
      dismissable: true,
    };
    showModal(selectStockAskModal)(dispatch);
  };

  const handleAskAnyoneClose = () => {
    setSecurityId(null);
  };

  return (
    <MessageWrap>
      <div className={additionalClassNames || ''}>
        <span>{message}</span>
        <span>
          <FlatButton transparent smallNoVert onClick={handleAskCTL}>
            Ask Now
          </FlatButton>
        </span>
        <div style={{ display: 'none' }}>
          {!isUndefinedOrNull(securityId) && (
            <AskAnyone
              containerClass="ask-ctl-share-button"
              customAskType={`askCTL`}
              customAskTypeButtonRenderer={`_renderAskCTLThoughtButton`}
              customAskTypeDisabledButtonRenderer={`_renderAskCTLThoughtButtonDisabled`}
              viewingContext={`Ask CTL Messaging`}
              securityId={securityId}
              buttonWidth={50}
              handleAfterAction={handleAskAnyoneClose}
              defaultAskingCTL
              openOnMount
              openFormInModal
            />
          )}
        </div>
      </div>
    </MessageWrap>
  );
};

export default connect()(AskCTLMessaging);
