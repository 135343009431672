import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import LoadingIcon from '../../components/misc/LoadingIcon';
import { didFirstLevelPropsValueChange } from '../../helpers/generalHelpers';

/*

  <BasicSecurityDataWrapper
    securityId -> required
    customLoadingComponent -> jsx -> will display component supplied when in loading state
    getSecurityDataIfNeeded -> bool -> will call API to get security data if not in store
    getSecurityData -> bool -> will call API to get security data on mount
  >
    {children}
  </BasicSecurityDataWrapper>

*/

class BasicSecurityDataWrapper extends Component {
  componentDidMount() {
    if (this.props.getSecurityData || this.props.getSecurityDataIfNeeded) {
      if (!this.props.getSecurityDataIfNeeded || (this.props.getSecurityDataIfNeeded && !this.isLoading())) {
        this._getSecurityData().then((response) => {
          this._onLoadComplete();
        });
      } else {
        this._onLoadComplete();
      }
    } else {
      this._onLoadComplete();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this._returnSecurity() != this._returnSecurity(nextProps)) {
      return true;
    }
    const excludeKeys = ['actions', 'securities'];
    if (didFirstLevelPropsValueChange(this.props, nextProps, excludeKeys)) {
      return true;
    }
    return false;
  }

  render() {
    if (this.isLoading() && this._useLoadingComponent()) {
      return this.props.customLoadingComponent || <LoadingIcon icon="fading-3balls" size="small" />;
    }

    if (this.isLoading() && this._shouldHideWhenLoading()) {
      return null;
    }

    return <span className={`basic-security-data-wrapper`}>{this._renderChildrenWithSecurityDataProps()}</span>;
  }

  _renderChildrenWithSecurityDataProps = () => {
    return React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, this.returnSecurityDataProps())
    );
  };

  returnSecurityDataProps = () => ({
    isLoading: this.isLoading(), // is more of an initial load completed value

    securityId: this._returnSecurityId(),
    ...this._returnSecurity(),
    security: this._returnSecurity(),
  });

  isLoading = (props) => !(props || this.props).securityId || !('name' in this._returnSecurity(props));

  _shouldHideWhenLoading = () => this.props.hideWhenLoading;

  _useLoadingComponent = () => this.props.useLoadingComponent || this.props.customLoadingComponent;

  _returnSecurityId = () => this.props.securityId;

  _getSecurityData = () => this.props.actions.quickFetchSecuritiesData([this._returnSecurityId()]);

  _returnSecurity = (props) => this._returnSecuritiesLookup(props)[this._returnSecurityId()] || {};

  _returnSecuritiesLookup = (props) => this._returnSecuritiesStore(props).lookup;

  _returnSecuritiesStore = (props) => (props || this.props).securities;

  _onLoadComplete = () => this.props.onLoadComplete && this.props.onLoadComplete();
}

const mapStateToProps = (state) => {
  return {
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicSecurityDataWrapper);
