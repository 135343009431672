import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  & > svg {
    width: 18px;
    height: 18px;
  }
  & g,
  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function TrophyIcon() {
  return (
    <Wrapper>
      <svg viewBox="0 0 16 16" fill="none">
        <path
          d="M13.833 2.167h-1.666V.5H3.833v1.667H2.167C1.25 2.167.5 2.917.5 3.833v.834c0 2.125 1.6 3.858 3.658 4.116a4.175 4.175 0 003.009 2.467v2.583H3.833V15.5h8.334v-1.667H8.833V11.25a4.175 4.175 0 003.009-2.467C13.9 8.525 15.5 6.792 15.5 4.667v-.834c0-.916-.75-1.666-1.667-1.666zm-11.666 2.5v-.834h1.666v3.184a2.509 2.509 0 01-1.666-2.35zm5.833 5a2.507 2.507 0 01-2.5-2.5v-5h5v5c0 1.375-1.125 2.5-2.5 2.5zm5.833-5c0 1.083-.7 2-1.666 2.35V3.833h1.666v.834z"
          fill="#1F1F1F"
        />
      </svg>
    </Wrapper>
  );
}

export default TrophyIcon;
