import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useCurrentUser, useLiveAccount } from '../../hooks/user';
import { logMetricsTrackingEvent, submitLiveTradingSignUpForm } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import { H5, Body5, FloatingLabelTextInput, Lock, INPUT_MASK_TYPES } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { useFormik } from 'formik';
import { ContactPhoneNumberSchema } from '../../constants/formValidationSchemas';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const InputWrapper = styled.div`
  padding-top: 32px;

  input {
    border: none !important;
    -webkit-appearance: auto;
    background: transparent !important;
  }
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 24px;
`;
const IconMessage = styled.div`
  padding-top: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  text-align: left;

  svg {
    height: 24px;
    width: 24px;

    fill: ${({ theme }) => theme.themeColors.text};

    margin-right: 16px;
  }
`;
const Actions = styled.div`
  padding-top: 64px;
  text-align: center;
`;

const getInitialValuesFrom = (currentUser, liveAccount) => {
  const { phone_number } = liveAccount;
  return {
    phone_number: phone_number || '',
  };
};

const massageFormData = (v) => {
  return v;
};

const FORM_PAGE_NAME = 'contact';

export const EnterContactPhoneNumber = ({ onContinue, onboardingState }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const liveAccount = useLiveAccount();

  const [isReady, setIsReady] = React.useState(true);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const form = useFormik({
    initialValues: getInitialValuesFrom(currentUser, liveAccount),
    validationSchema: ContactPhoneNumberSchema,
    enableReinitialize: false,
  });
  const { values, errors, setFieldValue, validateForm } = form;

  const handleChange = React.useCallback(
    (name) => (value) => {
      setFieldValue(name, value, false);
    },
    [setFieldValue]
  );

  const submitForm = React.useCallback((values) => {
    const apiFormData = massageFormData(values);
    setIsSubmitting(true);
    return submitLiveTradingSignUpForm(apiFormData, FORM_PAGE_NAME)(dispatch);
  }, []);

  const handleSubmit = React.useCallback(async () => {
    logMetricsTrackingEvent('Submitted Contact Phone Number Form')();
    logMetricsTrackingEvent('Submitted Contact Form')();

    const validation = await validateForm();

    if (Object.keys(validation).length === 0) {
      const { status } = await submitForm(values);
      if (status === 200) {
        return {
          ok: true,
        };
      } else {
        setFormError('Something went wrong, please try again');
        setIsSubmitting(false);
        return {
          ok: false,
        };
      }
    } else {
      logMetricsTrackingEvent('Failed Live Account Validations', {
        Fields: Object.entries(validation),
        'Form Name': 'phone number',
      })();
      // could log errors but no form validations on this form
      setIsSubmitting(false);
      return {
        errors: true,
      };
    }
  }, [values]);

  const handleContinueClick = React.useCallback(async () => {
    const { ok } = await handleSubmit();
    if (ok) onContinue(null, onboardingState);
  }, [handleSubmit]);

  React.useEffect(() => {
    logMetricsTrackingEvent('View Contact Phone Number Form')();
  }, []);

  if (!isReady) return <LoadingPage />;

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.enterPhoneNumber}>
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>What is your phone number?</H5>
          </Heading>

          <InputWrapper>
            <FloatingLabelTextInput
              name={'phone_number'}
              value={values.phone_number}
              label={'Phone Number'}
              onChange={handleChange}
              error={errors.phone_number || null}
              maskType={INPUT_MASK_TYPES.phone}
              autoFocus
            />
          </InputWrapper>

          <Message>
            <IconMessage>
              <Lock />
              <Body5>This information will be kept private and it will never appear on your public profile.</Body5>
            </IconMessage>
          </Message>

          <Actions>
            <FormState error={formError} isSubmitting={isSubmitting} />
            <Container fullWidth centerAll>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleContinueClick}>
                Continue
              </FlatButton>
            </Container>
          </Actions>
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
