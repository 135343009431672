import React from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchFurtherDocumentationStatus,
  getBankAccounts,
  getLiveTradingAccount,
  logErrorEvent,
  showModal,
} from '@src/actions';
import { returnCurrentUserThemeTestGroups } from '@src/helpers/currentUserHelpers';
import { useCurrentUser, useLiveAccount } from '../hooks/user';
import { useNavigate, useLocation } from 'react-router-dom';
import { ExpandedPageBackground, Page } from '../components/layout';
import { parseQueryString } from '@src/helpers/routerHelpers';
import { LoadingPage } from '../components/page';
import { isUndefined } from '@src/helpers/usefulFuncs';
import { sendFacebookTrackingEvent } from '@src/constants/facebookTracking';
import { ONBOARDING_PATHS, ONBOARDING_STATES, onboardingStateToComponentLookup } from '../constants/onboarding';
import { ACCOUNT_TYPES } from '../constants';
import { ReloadButton } from '@src/containers/buttons/Reload';
import { Body5, Body2 } from '../lib/nvstr-common-ui.es';
import { signOut } from '../containers/SignIn/utils/services';
import { SUPPORT_EMAIL } from '@src/appConfig';
import { returnPathTo } from '@src/constants/paths';

const getPrevState = (currentState, path) => {
  if (!path) {
    return null;
  }

  const index = path?.indexOf(currentState);
  if (index < 1) {
    return path[0];
  }

  return path[index - 1];
};

const getNextState = (currentState, path) => {
  if (!path) {
    return null;
  }

  const index = path.indexOf(currentState);
  if (index < 0) {
    return path[0];
  }

  return path[index + 1];
};

export const NonOnboardingLiveTradingSignUpHome = () => {
  const [initialLoadPath, setInitialLoadPath] = React.useState(null);
  const [isReady, setIsReady] = React.useState(false);

  const [cannotUseLiveTrading, setCannotUseLiveTrading] = React.useState(false);
  const [applicationIsPending, setApplicationIsPending] = React.useState(false);
  const [onboardingState, setOnboardingState] = React.useState(ONBOARDING_STATES.initialization);
  const [accountTypePath, setAccountTypePath] = React.useState(null);
  const [onboardingPath, setOnboardingPath] = React.useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const currentUser = useCurrentUser();
  const liveAccount = useLiveAccount();

  React.useEffect(() => {
    const setup = async (currentUser) => {
      if (currentUser.allow_live_trading && !currentUser.is_token_authed) {
        const requiredDataRequests = [
          getLiveTradingAccount(),
          getBankAccounts(),
          () => fetchFurtherDocumentationStatus(),
        ];

        const responses = await Promise.all(requiredDataRequests.map((r) => r(dispatch)));
        const failedRequests = responses.filter((r) => !r.ok);

        const { liveTradingAccount: liveAccount } = responses[0];
        const { isRejected: isSocureRejected, isUserActionRequired } = responses[2];

        if (failedRequests.length === 0) {
          const applicationStatus = liveAccount?.status;
          const accountApplicationSubmitted =
            !isUndefined(applicationStatus) &&
            applicationStatus?.toLowerCase() !== 'incomplete' &&
            applicationStatus?.toLowerCase() !== 'rejected';

          if (isSocureRejected || applicationStatus?.toLowerCase() === 'rejected') {
            setCannotUseLiveTrading(true);
            setIsReady(true);
            return;
          }

          if (isUserActionRequired) {
            const path = ONBOARDING_PATHS.socure;
            setOnboardingPath(path);
            setOnboardingState(ONBOARDING_STATES.socureApplication);
            setIsReady(true);
            return;
          }

          if (accountApplicationSubmitted) {
            setApplicationIsPending(true);
            setIsReady(true);
            return;
          }

          const path = ONBOARDING_PATHS.onboardedLiveAccountApplication;
          setOnboardingPath(path);
          const state = getNextState(onboardingState, path);
          setOnboardingState(state);
          setIsReady(true);
        } else {
          const modal = {
            contentComponent: (
              <div className={`reload-message-container`}>
                <p>Something went wrong. Please check your internet connection and reload the page.</p>
                <ReloadButton />
              </div>
            ),
            size: 'wide',
          };
          showModal(modal)(dispatch);
        }
      } else {
        if (currentUser.is_token_authed) {
          signOut();
        } else {
          setCannotUseLiveTrading(true);
          setIsReady(true);
        }
      }
    };

    const parseQuery = (currentUser) => {
      const query = parseQueryString(location.search);
      if (query.email_confirmed === 'true') {
        sendFacebookTrackingEvent('Email Confirmed', {
          themeTestGroups: returnCurrentUserThemeTestGroups(currentUser),
        });
      }

      if (query.path) {
        setInitialLoadPath(query.path);
      }
    };

    parseQuery(currentUser);
    setup(currentUser);
  }, []);

  const onGoBack = React.useCallback(
    () => {
      if (!onboardingPath) {
        logErrorEvent('No onboarding path selected', { onboardingPath, onboardingState })();
        setOnboardingState(ONBOARDING_STATES.initialization);
      } else {
        const prevOnboardingState = getPrevState(onboardingState, onboardingPath);
        setOnboardingState(prevOnboardingState);
      }
    },
    [onboardingState, onboardingPath]
  );

  const onPathChange = React.useCallback(
    (path, onboardingState, data = {}) => {
      const { accountType } = data;

      if (path) {
        setOnboardingPath(path);
        const nextState =
          data.nextState || (path ? getNextState(onboardingState, path) : ONBOARDING_STATES.initialization);
        setOnboardingState(nextState);
        return;
      }

      // CAN ADD SCREEN TRANSITION SPECIFIC LOGIC HERE
      switch (onboardingState) {
        default: {
          if (accountType === ACCOUNT_TYPES.live) {
            setAccountTypePath(ACCOUNT_TYPES.live);
            setOnboardingPath(ONBOARDING_PATHS.liveAccountApplication);
            const nextState = ONBOARDING_STATES.selectInvestingExperience;
            setOnboardingState(nextState);
          }
          if (accountType === ACCOUNT_TYPES.paper) {
            setAccountTypePath(ACCOUNT_TYPES.paper);
            setOnboardingPath(ONBOARDING_PATHS.paper);
            const nextState = ONBOARDING_STATES.selectInvestingExperience;
            setOnboardingState(nextState);
          }
        }
      }
    },
    [accountTypePath, onboardingPath]
  );

  const onContinue = React.useCallback(
    (data, onboardingState) => {
      // CAN ADD SCREEN TRANSITION SPECIFIC LOGIC HERE
      switch (onboardingState) {
        default: {
          const nextState = onboardingPath
            ? getNextState(onboardingState, onboardingPath)
            : ONBOARDING_STATES.initialization;
          setOnboardingState(nextState);
        }
      }
    },
    [accountTypePath, onboardingPath]
  );

  const onSkip = React.useCallback(
    (data, onboardingState) => {
      // CAN ADD SCREEN TRANSITION SPECIFIC LOGIC HERE
      switch (onboardingState) {
        case ONBOARDING_STATES.linkBankAccount: {
          setOnboardingState(ONBOARDING_STATES.selectAccountPrivacy);
          break;
        }

        default: {
          const nextState = onboardingPath
            ? getNextState(onboardingState, onboardingPath)
            : ONBOARDING_STATES.initialization;
          setOnboardingState(nextState);
        }
      }
    },
    [accountTypePath, onboardingPath]
  );

  const renderOnboardingState = React.useCallback(
    () => {
      const Component = onboardingStateToComponentLookup[onboardingState];

      if (Component) {
        const props = {};
        return (
          <Component
            {...props}
            accountTypePath={accountTypePath}
            onboardingState={onboardingState}
            onPathChange={onPathChange}
            onSkip={onSkip}
            onContinue={onContinue}
          />
        );
      }

      if (!initialLoadPath || !onboardingState) {
        console.error('no path/state', {
          onboardingPath,
          accountTypePath,
          onboardingState,
        });
        return (
          <ExpandedPageBackground>
            <Page>
              <Body5>Something went wrong, refresh the page.</Body5>
            </Page>
          </ExpandedPageBackground>
        );
      }

      return (
        <ExpandedPageBackground>
          <Page>
            <div>{onboardingState}</div>
          </Page>
        </ExpandedPageBackground>
      );
    },
    [onboardingState, initialLoadPath]
  );

  if (!isReady) return <LoadingPage />;

  if (applicationIsPending) {
    return (
      <Page>
        <Body5>Your live brokerage account application is still being processed.</Body5>
      </Page>
    );
  }

  if (cannotUseLiveTrading) {
    return (
      <Page>
        <div>
          <Body2>You are not eligible for a live brokerage account at this time.</Body2>
        </div>
        <div style={{ paddingTop: '16px' }}>
          <Body5>
            Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> to if you wish to discuss the status
            of your application
          </Body5>
        </div>
        <div style={{ paddingTop: '8px' }}>
          <Body5>
            More information is available about the requirements for opening a live brokerage account in our{' '}
            <a href={returnPathTo('faq') + '?id=37'} target={'_blank'} className="">
              {'FAQs'}
            </a>
          </Body5>
        </div>
      </Page>
    );
  }

  return renderOnboardingState();
};
