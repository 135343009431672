import React from 'react';

export class DisappearingComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      _clearRenderTimeout: null,
      _shouldRenderNull: false,
    };
  }

  componentDidMount() {
    this._createAndSaveRenderTimeout();
  }

  componentWillUnmount() {
    this._clearTimeout();
  }

  render() {
    return this.state._shouldRenderNull ? null : this.props.children;
  }

  _returnDefaultConfig = () => ({
    defaultTimeoutLengthToDisappear: 3000,
  });

  _returnTimeoutLengthToDisappear = () =>
    this.props.timeoutLengthToDisappear || this._returnDefaultConfig().defaultTimeoutLengthToDisappear;

  _setRenderNull = () => {
    this.setState(() => ({
      _shouldRenderNull: true,
    }));
  };

  _saveRenderTimeout = (timeout) => {
    if (this.state._clearRenderTimeout) {
      this._clearTimeout();
    }
    this.setState(() => ({
      _clearRenderTimeout: timeout,
    }));
  };

  _clearTimeout = () => {
    clearTimeout(this.state._clearRenderTimeout);
  };

  _createClearRenderTimeout = () => {
    return setTimeout(this._setRenderNull, this._returnTimeoutLengthToDisappear());
  };

  _createAndSaveRenderTimeout = () => {
    this._saveRenderTimeout(this._createClearRenderTimeout());
  };
}
