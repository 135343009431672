import {
  getDescriptionFromViewpointOrg,
  getDisplayNameFromViewpointOrg,
  getViewpointMonthlyFeeFromViewpointOrg,
} from '../helpers/viewpointHelpers';

export const getViewpointsStoreFromState = (state) => state.viewpoints;

export const getViewpointLookupFromState = (state) => getViewpointsStoreFromState(state).lookup;

export const getViewpointOrgIdFromUserIdLookupFromState = (state) =>
  getViewpointsStoreFromState(state).adminUserIdToViewpointLookup;

export const getViewpointOrgIdFromUserIdFromState = (state, userId) => {
  const lookup = getViewpointOrgIdFromUserIdLookupFromState(state);
  return lookup[userId] || null;
};

export const getViewpointOrgDataFromState = (state, id) => {
  const org = getViewpointLookupFromState(state)[id];
  if (!org) return null;

  return org;
};

export const getAllViewpointIdsFromState = (state) => getViewpointsStoreFromState(state).allViewpointIds;

export const getNameToViewpointOrgIdLookupFromState = (state) => getViewpointsStoreFromState(state).nameToIdLookup;

export const getAllFeaturedSubscriptionViewpointIdsFromState = (state) =>
  getViewpointsStoreFromState(state).featuredSubscriptionIds;

export const getFeaturedSubscriptionViewpointIdsDisplayOrderFromState = (state) =>
  getViewpointsStoreFromState(state).featuredSubscriptionIdsDisplayOrder;

export const getSubscriptionViewpointDefaultSelectionFromState = (state) =>
  getViewpointsStoreFromState(state).defaultSelection || null;

export const getSubscribedViewpointIdsFromState = (state) => getViewpointsStoreFromState(state).subscriptionIds;

export const getViewpointOrgAdminUserIdsFromState = (state) => state.viewpointAdminUserIds;

export const getViewpointAvatarURLFromState = (state, id) => {
  const org = getViewpointOrgDataFromState(state, id);
  if (org === null) return null;

  return null;
};

export const getViewpointDisplayNameFromState = (state, id) => {
  const org = getViewpointOrgDataFromState(state, id);
  if (org === null) return null;

  return getDisplayNameFromViewpointOrg(org);
};

export const getViewpointInitialsFromState = (state, id) => {
  const name = getViewpointDisplayNameFromState(state, id);
  if (name === null) return null;

  return name[0];
};

export const getViewpointDescriptionFromState = (state, id) => {
  const org = getViewpointOrgDataFromState(state, id);
  if (org === null) return null;

  return getDescriptionFromViewpointOrg(org);
};

export const getViewpointMonthlyFeeFromState = (state, id) => {
  const org = getViewpointOrgDataFromState(state, id);
  if (org === null) return null;

  return getViewpointMonthlyFeeFromViewpointOrg(org);
};

export const getIsSubscribedToViewpointFromState = (state, id) => {
  const org = getViewpointOrgDataFromState(state, id);
  if (org === null) return null;

  return org.is_subscribed;
};

export const getIsUserViewpointOrgAdminFromState = (state, userId) => {
  const adminUserIds = getViewpointOrgDataFromState(state);
  return adminUserIds.includes(userId);
};

export const getViewpointOrgIdFromNameFromState = (state, name) => {
  const lookup = getNameToViewpointOrgIdLookupFromState(state);
  return lookup[name] || null;
};
