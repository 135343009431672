import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const rebalancerAdapter = {
  runRebalancer: (rebalancePositionsList, config) => {
    const options = { ignoreAuth: true };
    const params = {
      desired_allocations: rebalancePositionsList ? rebalancePositionsList.toAPI() : [],
    };
    return sendRequest('post', [`${URL}/rebalance`, params], options);
  },

  createRebalancerOrders: (rebalancePositionsList) => {
    const params = {
      desired_allocations: rebalancePositionsList ? rebalancePositionsList.toAPI() : [],
    };
    return sendRequest('post', [`${URL}/rebalance/orders`, params]);
  },
};
