import React from 'react';
import { useSelector } from 'react-redux';
import { TRANSACTION_TYPES } from '../../constants/transactionTypes';

export const useBankTransfers = () => {
  const historyTransactionsStore = useSelector((state) => state.historyTransactions);
  const {
    isLoadingTransactionHistory,
    hasLoadedPreviously,
    transactions: groupedByDateTransactions,
  } = historyTransactionsStore;

  const [transactions, setTransactions] = React.useState([]);

  React.useEffect(() => {
    const filterTransactions = (transactions) =>
      transactions.filter((t) => [TRANSACTION_TYPES.Deposit, TRANSACTION_TYPES.Withdrawal].includes(t.type));

    const newTransactions = [];
    groupedByDateTransactions?.forEach((t) => {
      const { transactions } = t;
      const filtered = filterTransactions(transactions);

      if (filtered.length > 0) {
        const newGroupedTransactionsFiltered = { ...t };
        newGroupedTransactionsFiltered.transactions = filtered;
        newTransactions.push(newGroupedTransactionsFiltered);
      }
    });

    setTransactions(newTransactions);
  }, [groupedByDateTransactions]);

  return {
    isLoading: isLoadingTransactionHistory,
    isUpdating: isLoadingTransactionHistory && hasLoadedPreviously,
    isLoaded: hasLoadedPreviously,
    transactions,
  };
};
