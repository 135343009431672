import { createTimeInstance, formatLocalizedDateTime } from '../../../helpers/timeHelpers';
import { Body5, Body6 } from '../../lib/nvstr-common-ui.es';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useBankTransfers } from '../../hooks/funding/useBankTransfers';
import { cancelFundingTransaction, fetchHistoryTransactionData, showModal } from '../../../actions';
import styled from 'styled-components';
import { formatForDisplay } from '../../../helpers/displayHelpers';
import { FlatButton } from '../buttons';
import UnstyledLoadingSpinner from '../Images/UnstyledLoadingSpinner';
import { createBasicErrorModal } from '../../../constants/modals';

const TransferHistoryWrapper = styled.div`
  padding-top: 24px;
`;
const TransferHistoryBody = styled.div`
  padding-top: 16px;
`;
const TransferDateGroup = styled.div`
  padding-top: 16px;
`;
const TransferDate = styled.div`
  //padding-top: 16px;
`;
const Transfers = styled.div`
  padding: 16px 0;
`;
const TransferWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  padding: 16px 8px;

  border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
`;
const TransferLeftColumn = styled.div`
  padding-right: 16px;
`;
const TransferRightColumn = styled.div`
  text-align: right;
`;
const Amount = styled.div`
  text-decoration: ${({ isVoid }) => (isVoid ? 'line-through' : 'none')};
`;
const RemovingIconWrapper = styled.div`
  height: 32px;
  width: 32px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    height: 18px;
    width: 18px;
  }
`;
const CancelButtonWrapper = styled.div`
  button {
    padding: 8px 0 0 8px;
  }
`;

const TransferListDateGroup = ({ transactionGroup }) => {
  const { date, transactions } = transactionGroup;
  const dateDisplay = formatLocalizedDateTime('ll2', createTimeInstance(date));
  return (
    <TransferDateGroup>
      <TransferDate>
        <Body5>{dateDisplay}</Body5>
      </TransferDate>
      <Transfers>
        {transactions.map((t, i) => (
          <TransferListItem key={`${i} - transfer`} transaction={t} />
        ))}
      </Transfers>
    </TransferDateGroup>
  );
};

const parseStatus = (s) => {
  if (!s) return null;

  const convertValueToDisplayDict = {
    pending: 'Processing',
    processing: 'Processing',
    cancelation_pending: 'Cancellation Pending',
    canceled: 'Canceled',
    complete: 'Complete',
    rejected: 'Rejected',
  };
  const displayString = convertValueToDisplayDict[s];
  return displayString || null;
};

const TransferListItem = ({ transaction }) => {
  const dispatch = useDispatch();

  const { type, account_name, can_be_canceled, amount, status, is_void, funding_transfer_id } = transaction;

  const [isCanceling, setIsCanceling] = useState(false);
  const [cancelRequested, setCancelRequested] = useState(false);

  const handleCancel = async () => {
    setIsCanceling(true);

    const response = await cancelFundingTransaction(funding_transfer_id)(dispatch);
    if (!response.success) {
      const modal = {
        contentComponent: createBasicErrorModal('Something went wrong, please refresh the page and try again.'),
        dismissable: true,
        size: 'widt',
      };
      showModal(modal)(dispatch);
    } else {
      setCancelRequested(true);
    }
  };

  const displayStatus = parseStatus(status);
  return (
    <TransferWrapper>
      <TransferLeftColumn>
        <div>
          <Body5>{type}</Body5>
        </div>
        <div>
          <Body5 isSmall thin>
            {account_name}
          </Body5>
        </div>
      </TransferLeftColumn>

      <TransferRightColumn>
        <Amount isVoid={is_void}>
          <Body5>{formatForDisplay(amount, 'price')}</Body5>
        </Amount>
        {!!displayStatus && (
          <div>
            <Body5 isSmall thin>
              {displayStatus}
            </Body5>
          </div>
        )}
        {can_be_canceled &&
          (isCanceling ? (
            <RemovingIconWrapper>
              <UnstyledLoadingSpinner />
            </RemovingIconWrapper>
          ) : cancelRequested ? (
            <div>
              <Body5 isSmall thin>
                Cancellation Requested
              </Body5>
            </div>
          ) : (
            <CancelButtonWrapper>
              <FlatButton transparent onClick={handleCancel}>
                Cancel
              </FlatButton>
            </CancelButtonWrapper>
          ))}
      </TransferRightColumn>
    </TransferWrapper>
  );
};

export const TransferHistoryList = () => {
  const dispatch = useDispatch();
  const { transactions, isLoading } = useBankTransfers();

  React.useEffect(() => {
    fetchHistoryTransactionData()(dispatch);
  }, []);

  return (
    <TransferHistoryWrapper>
      <Body6>Transfer History</Body6>
      <TransferHistoryBody>
        {transactions.length > 0 ? (
          transactions.map((d, i) => <TransferListDateGroup key={`date-group-${i}`} transactionGroup={d} />)
        ) : isLoading ? (
          <div style={{ padding: '24px 0 0 0 ' }}>
            <Body5>Loading...</Body5>
          </div>
        ) : (
          <div style={{ padding: '24px 0 0 0 ' }}>
            <Body5>You have not initiated any transfers.</Body5>
          </div>
        )}
      </TransferHistoryBody>
    </TransferHistoryWrapper>
  );
};
