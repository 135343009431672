import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../../../actions/index';

import TransactionListHeading from '../TransactionListHeading';
import GroupedTransactionsList from '../GroupedTransactionsList';
import LinkTo from '../../../../containers/Links/LinkTo';

class FundingTransactionsHistory extends Component {
  constructor(props) {
    super(props);
    this.transactionsActions = {
      handleCancelClick: this.handleCancelClick,
    };
  }

  render() {
    const API_URL = import.meta.env.VITE_API_URL || '';
    return (
      <div className={`transactions-table-container`}>
        {!this.isInitLoading() && (
          <>
            <TransactionListHeading headingText={'History'} />
            <div style={{ margin: '10px 0px', textAlign: 'right' }}>
              <LinkTo
                to={`${API_URL}/portfolio/history/equity/csv`}
                text="Download Account Value History"
                styling={{ fontSize: '11px' }}
                fullPageLoad
              />
            </div>
            <div style={{ margin: '10px 0px', textAlign: 'right' }}>
              <LinkTo
                to={`${API_URL}/portfolio/history/transactions/csv`}
                text="Download Transaction History"
                styling={{ fontSize: '11px' }}
                fullPageLoad
              />
            </div>
          </>
        )}
        <GroupedTransactionsList
          isLoading={this.isInitLoading()}
          isUpdating={this.isUpdating()}
          isError={!this.isUpdating() && !this.isInitLoading() && !this.returnTransactionsGroupedByDate()}
          groupedTransactionsByDate={this.returnTransactionsGroupedByDate()}
          actions={this.transactionsActions}
        />
      </div>
    );
  }

  handleCancelClick = (id) => {
    return this.props.actions.cancelFundingTransaction(id).then((response) => {
      if (!response.success) {
        this._showErrorMessage(response.error);
      }
    });
  };

  _showErrorMessage = (message) => {
    message = message || 'Something went wrong. Please try again.';
    const contentComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {message}
      </div>
    );
    this.props.actions.showModal({
      contentComponent,
      size: 'wide',
      dismissable: true,
    });
  };

  _returnTransactionType = (transactionData) => transactionData.type;

  returnTransactionsGroupedByDate = () => this.returnTransactionsData();

  returnTransactionsData = () => this.returnTransactionsStore().transactions;

  isInitLoading = (props = this.props) =>
    this.returnTransactionsStore(props).isLoadingTransactionHistory && !this.hasLoadedDataPreviously();

  hasLoadedDataPreviously = (props = this.props) => this.returnTransactionsStore(props).hasLoadedPreviously;

  isUpdating = (props = this.props) =>
    this.returnTransactionsStore(props).isLoadingTransactionHistory && this.hasLoadedDataPreviously();

  returnTransactionsStore = (props = this.props) => this.props.historyTransactions;
}

const mapStateToProps = (state) => {
  return {
    historyTransactions: state.historyTransactions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FundingTransactionsHistory);
