import React from 'react';
import { connect } from 'react-redux';

import LoadingIcon from '../misc/LoadingIcon';

class StatusModal extends React.PureComponent {
  render() {
    const { text } = this.props;

    return (
      <div>
        <div className="modal-message" style={{ paddingTop: '0px', textAlign: 'center' }}>
          <div>
            <LoadingIcon />
            {/*<LoadingIcon icon='fading-3balls' size='small' style={ { } }/>*/}
          </div>
          <div style={{ marginTop: 5 }}>
            <span className={' '}>{text}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(StatusModal);
