import React from 'react';
import { usePriceData } from '@src/main/hooks/securities/usePriceData';
import { useSecurity } from '@src/main/hooks/securities/useSecurity';
import { usePosition } from '@src/main/hooks/portfolio/usePosition';
import { activeSecuritiesTracker, LivePricePollingManager } from '@src/main/classes/LivePricePollingManager';
import { formatSecurityPropertyValue } from '@src/helpers/securitiesHelpers';
import { PriceChange } from '@src/main/components/securities/PriceChange';
import styled from 'styled-components';
import { ExtendedTradingPrice } from '@src/main/containers/Securities/ExtendedTradingPrice';

const QuoteWrapper = styled.div`
  min-width: 126px;

  p {
    margin: 0 !important;
  }
`;

const PriceWrapper = styled.div`
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;

  text-align: right;
`;

const PriceChangeWrapper = styled.div`
  font-size: 14px;
  line-height: 24px;

  > div {
    justify-content: flex-end;
  }
`;

const ExtendedPriceWrapper = styled.div`
  * {
    font-size: 12px;
    line-height: 16px;

    color: ${({ theme }) => theme.themeColors.secondaryText} !important;
  }
`;

export const PriceQuote = ({ securityId }) => {
  const [addedToPricePoll, setAddedToPricePoll] = React.useState(false);
  const { price } = usePriceData(securityId);
  const { symbol } = useSecurity(securityId);
  const { shares } = usePosition(securityId);
  const hasPosition = Math.abs(shares) > 0;

  React.useEffect(() => {
    if (symbol && !addedToPricePoll && !hasPosition) {
      activeSecuritiesTracker.addSecurities([{ id: securityId, symbol }]);
      setAddedToPricePoll(true);
    }
  }, [symbol, addedToPricePoll, shares]);

  React.useEffect(() => {
    const onUnmount = () => {
      if (addedToPricePoll) {
        activeSecuritiesTracker.removeSecurities([symbol]);
      }
    };

    return onUnmount;
  }, [addedToPricePoll]);

  if (isNaN(price)) return null;

  const priceDisplay = formatSecurityPropertyValue(price, 'current_price');
  return (
    <QuoteWrapper>
      <PriceWrapper>
        <p>{priceDisplay}</p>
      </PriceWrapper>

      <PriceChangeWrapper>
        <PriceChange securityId={securityId} />
      </PriceChangeWrapper>

      <ExtendedPriceWrapper>
        <ExtendedTradingPrice
          securityId={securityId}
          showPrice
          showTimestamp
          align="flex-end"
          ContainerProps={{ top: 1 }}
        />
      </ExtendedPriceWrapper>
    </QuoteWrapper>
  );
};
