import React from 'react';

import { sendNewsViewEvent } from '../../../actions';

import GenericImpressionTracker from '../GenericImpressionTracker';

import { wasImpressionRecorded, saveImpressionToStorageIfUnrecorded } from '../../../helpers/impressionHelpers';

export const NewsImpressionTracker = (props) => {
  const {
    newsId,
    notificationIndex,
    securityId,
    viewingContext,
    scrollContainerSelector,
    containerElementSelector,
    metadata,
  } = props;
  const impressionType = `viewNews`;

  const trackingData = {
    id: newsId,
    securityId,
    context: viewingContext,
    indexInFeed: notificationIndex,
    metadata: metadata,
  };

  const wasAlreadyRecorded = () => wasImpressionRecorded(impressionType, newsId, viewingContext);
  const recordImpression = () => sendNewsViewEvent(trackingData)();
  const saveImpressionToStorage = () => saveImpressionToStorageIfUnrecorded(impressionType, newsId, viewingContext);

  return (
    <div className={`news-impression-tracker-container`}>
      <GenericImpressionTracker
        id={newsId}
        wasAlreadyRecorded={wasAlreadyRecorded}
        recordImpression={recordImpression}
        saveImpressionToStorage={saveImpressionToStorage}
        containerElementSelector={containerElementSelector}
        scrollContainerSelector={scrollContainerSelector}
      />
    </div>
  );
};
