import React from 'react';
import { createMarkup, removeLIfromMarkup } from '../../../helpers/notificationsHelpers';

const RailsNotification = (props) => {
  const notificationHTML = props.notification;
  const notificationHTMLWithoutLI = removeLIfromMarkup(notificationHTML);
  const markup = createMarkup(notificationHTMLWithoutLI);

  return <li className="notification row" dangerouslySetInnerHTML={markup} />;
};

export default RailsNotification;
