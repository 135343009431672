import {
  FILTER_SECURITIES,
  START_FILTERING_LOADING,
  END_FILTERING_LOADING,
  GET_FILTER_SELECTIONS,
  ADD_SECURITY_TO_PORTFOLIO_EXPLORER,
  REMOVE_SECURITY_FROM_PORTFOLIO_EXPLORER,
} from '../constants';

const defaultState = {
  loadingFilters: false,
  securities: [],
  securitiesInPortfolioOptimizer: [],
  selections: { loading: true },

  filterResultsCounterReturned: [],
  counter: 0,
};

export default function portfolioExplorerReducer(state = defaultState, action) {
  let securityId, security, securities, securityIds, counter;
  let stateCopy = { ...state };

  switch (action.type) {
    case FILTER_SECURITIES:
      securityIds = action.payload.securityIds;
      counter = action.payload.counter;
      const isMostRelevantFilter = state.filterResultsCounterReturned.some(
        (counterReturned) => counterReturned > counter
      );
      if (counter === state.counter || isMostRelevantFilter) {
        return {
          ...state,
          securities: [...securityIds],
          filterResultsCounterReturned: [...state.filterResultsCounterReturned, counter],
        };
      } else {
        return {
          ...state,
          filterResultsCounterReturned: [...state.filterResultsCounterReturned, counter],
        };
      }

    case START_FILTERING_LOADING:
      return { ...state, loadingFilters: true, counter: state.counter + 1 };

    case END_FILTERING_LOADING:
      return { ...state, loadingFilters: false };

    case GET_FILTER_SELECTIONS:
      const { thought_leaders, connections, sectors } = action.payload;
      return {
        ...state,
        selections: { loading: false, thought_leaders, connections, sectors },
      };

    case ADD_SECURITY_TO_PORTFOLIO_EXPLORER:
      securityId = action.payload;
      return {
        ...state,
        securitiesInPortfolioOptimizer: [...state.securitiesInPortfolioOptimizer, securityId],
      };

    case REMOVE_SECURITY_FROM_PORTFOLIO_EXPLORER:
      securityId = action.payload;
      return {
        ...state,
        securitiesInPortfolioOptimizer: state.securitiesInPortfolioOptimizer.filter((id) => id !== securityId),
      };

    default:
      return state;
  }
}
