import React from 'react';
import { useSelector } from 'react-redux';
import { useCurrentUserId } from './useCurrentUserId';
import { useCurrentUser } from './useCurrentUser';

export const useUser = (userId) => {
  const currentUserId = useCurrentUserId();
  const currentUser = useCurrentUser();
  const userStore = useSelector((state) => state.users.userList);
  const collectivesStore = useSelector((state) => state.collectives.lookup);

  return currentUserId === userId ? currentUser : collectivesStore[userId] || userStore[userId];
};
