import React from 'react';
import copy from 'copy-to-clipboard';
import { logMetricsTrackingEvent } from '../../../actions';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const buildTextAreaId = (elementId) => `${elementId}_text_field`;

const CopiedMessage = () => (
  <div className={'tooltip-box-container main-bg-color-match border-accent share-copied'}>
    <div className={'tooltip-message  '}>{'Copied!'}</div>
  </div>
);

const InvisibleTextField = ({ elementId, text }) => {
  const id = buildTextAreaId(elementId);
  return (
    <textarea
      id={id}
      readOnly={1}
      value={text}
      style={{
        opacity: 0,
        height: '2px',
        width: '2px',
      }}
    />
  );
};

class CopyUtil extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showCopiedMessage: false,
      copiedMessageTimeout: null,
    };

    this.SHOW_COPY_MESSAGE_TIME_LENGTH = 3000;
  }

  componentWillUnmount() {
    if (this._returnCopiedMessageTimeout()) clearTimeout(this._returnCopiedMessageTimeout());
  }

  render() {
    const {
      elementId,
      copyValue,

      children,
    } = this.props;

    return (
      <div
        id={elementId}
        onClick={this._handleCopyClick}
        style={{ zIndex: 1, position: 'relative', display: 'inline-block' }}
      >
        <Wrapper>
          {children}
          {copyValue && <InvisibleTextField elementId={elementId} text={copyValue} />}
        </Wrapper>
        {this._shouldShowCopiedMessage() && (
          <div className={'copied-message-container'} style={this._buildCopiedMessagePositionStyle()}>
            <CopiedMessage />
          </div>
        )}
      </div>
    );
  }

  _buildCopiedMessagePositionStyle = () => {
    const { elementId } = this.props;
    const $el = window.$(`#${elementId}`);
    const $messageEl = window.$(`#${elementId} .copied-message-container`);

    const elHeight = $el.height();

    const offset = 10;
    const tooltipArrowHeight = 8;
    const messageElHeight = $messageEl.height() + tooltipArrowHeight + offset;

    const left = '0px';
    // const left = `${(elHeight + messageElHeight) * -1}px`;
    const bottom = `${(elHeight + messageElHeight) * -1}px`;

    return {
      position: 'absolute',
      bottom,
      left,
    };
  };

  _handleCopyClick = () => {
    const { elementId, textAreaId, onCopyEffect } = this.props;

    if (textAreaId) {
      const textAreaEl = document.getElementById(`${textAreaId}`);
      copy(textAreaEl.value);
    } else {
      /* Select the field */
      const targetTextAreaElementId = buildTextAreaId(elementId);
      window.$(`#${targetTextAreaElementId}`)[0].select();

      /* Copy the text inside the text field */
      document.execCommand('copy');

      /* Unselect the field */
      window.getSelection().removeAllRanges();
    }

    onCopyEffect && onCopyEffect();

    /* Alert the copied text */
    this._showCopiedMessage();
    this._setTimeoutForCopiedMessage();
    this._logCopiedShareLinkClick();
  };

  _returnCopiedMessageTimeout = () => this.state.copiedMessageTimeout;

  _shouldShowCopiedMessage = () => this.state.showCopiedMessage;

  _showCopiedMessage = () => {
    this.setState(() => ({
      showCopiedMessage: true,
    }));
  };
  _hideCopiedMessage = () => {
    this.setState(() => ({
      showCopiedMessage: false,
    }));
  };

  _createTimeoutForCopiedMessage = () => {
    const timeout = window.setTimeout(this._hideCopiedMessage, this.SHOW_COPY_MESSAGE_TIME_LENGTH);
    return timeout;
  };

  _setTimeoutForCopiedMessage = () => {
    const timeout = this._createTimeoutForCopiedMessage();
    this.setState(() => ({
      copiedMessageTimeout: timeout,
    }));
  };

  _returnAdditionalLogProperties = () => {
    const { shareContext, additionalPropertiesForEventLogging } = this.props;
    return {
      Context: shareContext || null,
      ...additionalPropertiesForEventLogging,
    };
  };

  _logCopiedShareLinkClick = () => {
    const { eventName } = this.props;
    const properties = {
      'Copy Succeeded': true,
      ...this._returnAdditionalLogProperties(),
    };
    logMetricsTrackingEvent(eventName, properties)();
  };
}

CopyUtil.defaultProps = {
  additionalPropertiesForEventLogging: {},
};

export default CopyUtil;
