import React, { Component } from 'react';
import styled from 'styled-components';
import { colorPalette } from '@src/main/lib/nvstr-utils.es';

import Icon from '../misc/Icon';

import { toCapitalCase } from '../../helpers/generalHelpers';

const OptionValuesWrapper = styled.div`
  .option-value {
    background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};
    color: ${({ theme }) => theme.themeColors.text};
  }
  .option-value.selected,
  .option-value:hover {
    background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.buttonText};
  }
`;

export class CustomDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOptions: props.autoOpen,
    };
  }

  componentDidMount() {
    if (this.props.autoOpen) {
      this[`${this.props.name}-dropdown`].focus();
    }
  }

  handleBlur = () => {
    this.setState((prevState) => ({
      showOptions: false,
    }));

    if (!('ignoreBlur' in this.props)) {
      const name = this.props.name;
      const _props_value = this.props.getValue(name);
      const value = _props_value !== null && _props_value !== undefined ? _props_value : undefined;
      this.props.handleBlur(value, name);
    }

    if ('additionalBlurAction' in this.props) {
      this.props.additionalBlurAction();
    }
  };

  handleDropdownClick = (e) => {
    this.setState((prevState) => ({
      showOptions: true,
    }));

    var $element = `.dropdown-${this.props.name} .option-values-container`;
    var dropdownOptionHeight = 35;

    // the values() is used for situations where values are an array of options coming from the server :: CAREFUL its async, cannot assume they will always be there
    const values = Array.isArray(this.props.values) ? this.props.values : this.props.values();

    const restrictedValuesToShow =
      ('restrictedValues' in this.props && this.props.restrictedValues.filter((value) => value.exists)) || [];
    const optionValues = [...values, ...restrictedValuesToShow.map((option) => option.value)];
    var indexOfSelectedOptionInAllOptions = optionValues.indexOf(this.props.getValue(this.props.name));

    const scrollDistance = indexOfSelectedOptionInAllOptions * dropdownOptionHeight;

    if (scrollDistance > 0) {
      setTimeout(() => {
        $($element).animate(
          {
            scrollTop: indexOfSelectedOptionInAllOptions * dropdownOptionHeight,
          },
          1
        );
      }, 50);
    }
  };

  handleOptionClick = (e, name, value) => {
    this.props.handleCustomDropdownClick(e, name, value);

    if (this.props.saveOnChange) {
      const data = {
        [`${this.props.objectType}_id`]: this.props.getObjectId(this.props.objectType),
        [name]: value,
      };
      this.props.saveOnChange(data);
    }

    if (this.props.blurOnOptionClick) {
      this[`${this.props.name}-dropdown`].blur();
    } else {
      this.handleBlur();
    }
  };

  boundOptionClick = (name, value) => {
    return (e) => {
      e.stopPropagation();
      this.handleOptionClick(e, name, value);
    };
  };

  showOptionValue = (value, isSelectedValue) => {
    const rawValue = typeof value === 'object' ? ('name' in value ? value.name : value.description) : value;
    let displayValue = rawValue;
    if (this.props.convertValuesToCapitalCase && typeof displayValue === 'string') {
      displayValue = toCapitalCase(displayValue);
    }
    return isSelectedValue ? displayValue : <span style={{ color: '#ccc' }}>{displayValue}</span>;
  };

  render() {
    const name = this.props.name;
    const label = this.props.label;
    const note = this.props.note;
    const required = this.props.required;
    const suffix = this.props.suffix;
    const value =
      this.props.getValue(name) !== null && this.props.getValue(name) !== undefined
        ? this.props.getValue(name)
        : undefined;
    const defaultValue = this.props.defaultValue;
    const currentSelectedValue = value || defaultValue || label || `No ${name}`;

    const showAdditionalInputs =
      this.props.toggleAdditionalInfoFieldsIf instanceof Function
        ? this.props.toggleAdditionalInfoFieldsIf(value)
        : this.props.toggleAdditionalInfoFieldsIf === value;

    const isError = !!this.props.getErrorMessage(name);
    const errorMessage = isError ? this.props.getErrorMessage(name) : '';
    const hideElement = !!note ? '' : 'hidden';
    const styling = ('styling' in this.props && this.props.styling) || 'dropdown-centered';
    const style = this.props.style || {};

    const values = Array.isArray(this.props.values) ? this.props.values : this.props.values();

    const restrictedValuesToShow =
      ('restrictedValues' in this.props && this.props.restrictedValues.filter((value) => value.exists)) || [];
    const optionValues = [...values, ...restrictedValuesToShow.map((option) => option.value)];
    if (!required && (defaultValue || label)) {
      if (!this.props.hideLabel) {
        optionValues.unshift(defaultValue || label);
      }
    }
    const offsetOptions = this.props.offset || {};
    return (
      <fieldset
        className={`dropdown-${name} ${styling} ${this.props.center ? 'text-center' : ''} ${
          this.props.size === 'small' ? 'field-small' : ''
        }`}
        style={style}
      >
        {this.props.showLabel && !(label === value || value === undefined) && <label>{label}</label>}
        <div
          tabIndex="0"
          className={`custom-dropdown two-elements`}
          onClick={this.handleDropdownClick}
          onBlur={this.handleBlur}
          ref={(el) => {
            this[`${name}-dropdown`] = el;
          }}
        >
          {this.showOptionValue(currentSelectedValue, value)}
          {this.state.showOptions && (
            <OptionValuesWrapper className={`option-values-container`} style={offsetOptions}>
              <div className={`option-values-list`}>
                {optionValues.map((value, i) => (
                  <div
                    key={`${name}-${i}`}
                    className={`
                            option-value
                            ${this.props.valueStyles ? this.props.valueStyles[i] : ''}
                            ${
                              (typeof value === 'object'
                              ? value.id === currentSelectedValue.id
                              : value === currentSelectedValue)
                                ? 'selected'
                                : ''
                            }`}
                    onClick={this.boundOptionClick(name, value)}
                  >
                    {this.showOptionValue(value, true)}
                  </div>
                ))}
              </div>
            </OptionValuesWrapper>
          )}
          {suffix && <div className="custom-dropdown-suffix">{suffix}</div>}
          {this.props.showArrowIcons && (
            <Icon
              size={'small'}
              icon={'fa-sort-asc'}
              colorClassName={' '}
              style={{ position: 'absolute', top: '9px', right: '10px' }}
            />
          )}
          {this.props.showArrowIcons && (
            <Icon
              size={'small'}
              icon={'fa-sort-desc'}
              colorClassName={' '}
              style={{ position: 'absolute', top: '10px', right: '10px' }}
            />
          )}
        </div>
        <div className={`dropdown-note ${hideElement}`}>{note}</div>
        {!('ignoreBlur' in this.props) && <p className={`text-field-error-message`}>{errorMessage}</p>}
        {showAdditionalInputs && (
          <div className="toggle-additional-inputs-container">{this.props.children.additionalInfoFields}</div>
        )}
      </fieldset>
    );
  }
}

export default CustomDropdown;
