import React, { PureComponent } from 'react';

import { returnPathTo } from '../../../constants/paths';

import SectionHeading from './SectionHeading';
import Button from '../../../components/buttons/Button';

import { logMetricsTrackingEvent } from '../../../actions/trackingActions';
import { withRouter } from '../../../main/utils';
import { FlatButton } from '../../../main/components/buttons';

class ViewSubscriptionsPage extends PureComponent {
  render() {
    return (
      <div>
        <SectionHeading headingText={'Subscriptions'} />
        <div className={`action-container`}>
          <FlatButton onClick={this.handleViewPlansClick}>View Plans</FlatButton>
        </div>
      </div>
    );
  }

  handleViewPlansClick = () => {
    this.props.navigate(returnPathTo('subscriptions'));
    this.logClickViewPlans();
  };

  logClickViewPlans = () => {
    const event = 'Clicked View Subscription Plans';
    const properties = {};
    logMetricsTrackingEvent(event, properties)();
  };
}

export default withRouter(ViewSubscriptionsPage);
