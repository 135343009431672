import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as Actions from '../../../../actions/index';
import { withRouter } from '../../../../main/utils';
import IdeaDeleteOptions from '../../../Ideas/components/IdeaDeleteOptions';
import IconButton from '../../../../components/buttons/IconButton';

import { snakeToHyphens, isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import { findUserIdeaForSecurity, handleExitPosition } from '../../../../helpers/ideaHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';

export class RemoveIdeaCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showIdeaDeleteOptions: false,
    };
  }

  render() {
    const columnKeyName = this.props.column.keyName;
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? this.props.loadingCellComponent : this._renderCellContent()}
      </td>
    );
  }

  _isLoading = () => {
    const securityId = this.props.security.security_id;
    const security = this.props.securities.lookup[securityId];

    return !security || !('name' in security);
  };

  _renderCellContent = () => {
    const security = this._returnSecurity();

    return (
      <div className={`security-table-remove-idea`}>
        <div className={`wrapper`}>
          <IconButton
            size="medium"
            handleClick={this._handleRemoveIdeaClick}
            icon="fa-trash-o"
            colorClassName="secondary-text-color"
          />
          {this.state.showIdeaDeleteOptions && (
            <IdeaDeleteOptions
              symbol={security.symbol}
              handleExitPosition={this._handleExitPosition}
              handleKeepPosition={this._handleKeepPosition}
            />
          )}
        </div>
      </div>
    );
  };

  _doesUserHavePositionInIdea = () => {
    const { securityId, currentUser } = this.props;
    const positions = currentUser.positions;
    return securityId in positions && positions[securityId].shares && positions[securityId].shares !== 0;
  };

  _returnIdea = () => {
    const { userId, securityId } = this.props;
    const currentUserId = this.props.currentUser.user_id;
    const ideaUserId = userId || currentUserId;
    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    return this.props.idea || idea;
  };
  _returnSecurity = () => this.props.securities.lookup[this.props.securityId];

  _showIdeaDeleteOptions = () => {
    this.setState(() => ({
      showIdeaDeleteOptions: true,
    }));
  };

  _hideIdeaDeleteOptions = () => {
    this.setState(() => ({
      showIdeaDeleteOptions: false,
    }));
  };

  _handleKeepPosition = () => {
    this._hideIdeaDeleteOptions();
    this._logChoseKeepPosition();
  };

  _handleExitPosition = () => {
    handleExitPosition.call(this);
  };

  _logChoseKeepPosition = () => {
    const security = this._returnSecurity();
    const idea = this._returnIdea();

    const event = 'Choose Keep Position In Idea';
    const properties = {
      'Idea Id': idea.idea_id,
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
      Context: 'Security Table',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logEventForRemoveIdeaClick = () => {
    const security = this._returnSecurity();
    const idea = this._returnIdea();

    const event = 'Clicked Delete Idea';
    const properties = {
      'Idea Id': idea.idea_id,
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
      Context: 'Security Table',
      'Has Position': this._doesUserHavePositionInIdea(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _removeIdea = () => {
    const idea = this._returnIdea();
    this._logEventForRemoveIdeaClick();
    return this.props.actions.removeIdea(idea);
  };

  _handleRemoveIdeaClick = () => {
    if (this._doesUserHavePositionInIdea()) {
      this._logEventForRemoveIdeaClick();
      this._showIdeaDeleteOptions();
    } else {
      this._removeIdea();
    }
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    ideas: state.ideas,
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(RemoveIdeaCell);

export default composedComponent;
