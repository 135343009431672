import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as Actions from '../../../../actions/index';
import { truncateNameSafe, isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';
import InfoIcon from '../../../UI/InfoIcon';
import { ExternalPositionsBasicCell } from './ExternalPositionsBasicCell';

export class ExternalPositionsSecurityIdentifierCell extends ExternalPositionsBasicCell {
  _getDisplayIdentifier = (symbol, name) => {
    if (this._isUnmappedSecurity()) {
      //if an option
      //(ticker)YYMMDD(C or P)(strike)
      // example NFLX180201C00355000
      var myRe = new RegExp('([A-Za-z]+)[0-9]{6}([CP])[0-9]{8}');
      const regexResult = myRe.exec(symbol);
      if (regexResult) {
        const ticker_symbol = regexResult[1];
        const call_put = regexResult[2] === 'C' ? 'Call' : 'Put';
        symbol = ticker_symbol + ' ' + call_put;
      }
    }
    return [symbol, name];
  };

  _returnLinkForHandlingClick = () => {
    const securityId = this.props.securityId;
    if (!securityId) {
      return null;
    }
    const openSecurityPanelConfig = {
      securityId,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    const securityId = this.props.security.security_id;
    const plaidSecurityId = this.props.plaidSecurityId;
    if (this._isUnmappedSecurity()) {
      const pos =
        this.props.externalPositions.accounts[
          this.props.additionalTableCellProps.external_positions.plaid_account_id_filter
        ].unmapped_positions[plaidSecurityId];
      let name = pos['name'];
      let symbol = pos['symbol'];

      if (isUndefinedOrNull(name)) {
        name = 'N/A';
      }
      if (isUndefinedOrNull(symbol)) {
        symbol = 'N/A';
      }

      [symbol, name] = this._getDisplayIdentifier(symbol, name);
      const display_length = $(window).width() > 525 ? 33 : 20;

      return (
        <span className="security-table-identifier">
          <span className="security-table-symbol secondary-text-color">
            {symbol}
            <InfoIcon
              word={'tooltip_external_positions_unmapped_security'}
              style={{ position: 'relative', display: 'inline-block' }}
            />
          </span>

          <span className="security-table-company-name truncate-text-overflow secondary-text-color">
            {truncateNameSafe(name, display_length, truncateNameSafe(symbol, display_length, 'N/A'))}
          </span>

          <span className={`multi-text-box-inline flex-justify-left`}>
            <span className="security-table-current-price"></span>

            <span className="security-table-current-price-change"></span>
          </span>
        </span>
      );
    }

    const security = this.props.securities.lookup[securityId];
    const { name, symbol } = security;

    return (
      <span className="security-table-identifier">
        <span className="security-table-symbol  ">{symbol}</span>

        <span className="security-table-company-name truncate-text-overflow secondary-text-color">
          {truncateNameSafe(name, $(window).width() > 525 ? 33 : 20)}
        </span>

        <span className={`multi-text-box-inline flex-justify-left`}>
          <span className="security-table-current-price"></span>

          <span className="security-table-current-price-change"></span>
        </span>
      </span>
    );
  };

  _renderUnmappedCellContent = () => {
    return this._renderCellContent();
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    securities: state.securities,
    externalPositions: state.externalPositions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(
  ExternalPositionsSecurityIdentifierCell
);

export default composedComponent;
