import React from 'react';
import { usePosition } from './usePosition';
import { usePriceData } from '../securities/usePriceData';
import { useExtendedTradingHours } from '../securities/useExtendedTradingHours';

const actions = {
  updateTodayChange: 'utc',
  updateTotalChange: 'utotc',

  updateTodayAndTotalChange: 'utatotc',
};

const initialState = {
  todayChange: {},
  totalChange: {},
};

function reducer(state, action) {
  switch (action.type) {
    case actions.updateTodayChange:
      return {
        ...state,
        ...action.payload,
      };

    case actions.updateTotalChange:
      return {
        ...state,
        ...action.payload,
      };

    case actions.updateTodayAndTotalChange:
      return {
        ...state,
        ...action.payload,
      };
    default: {
      console.error('missing case in usePosPerf reducer');
      return state;
    }
  }
}

const _calculateDayChangeForPosition = (position, price, previousClosePrice) => {
  const {
    previous_close_price,

    unrealized_shares_transacted_today,
    cost_of_unrealized_shares_transacted_today,

    unrealized_shares_as_of_yesterday,
  } = position;

  const availablePrevClose = previousClosePrice || previous_close_price;

  const value =
    unrealized_shares_as_of_yesterday * price -
    unrealized_shares_as_of_yesterday * availablePrevClose +
    (unrealized_shares_transacted_today * price - cost_of_unrealized_shares_transacted_today);
  const todayCostBasis = Math.abs(
    unrealized_shares_as_of_yesterday * availablePrevClose + cost_of_unrealized_shares_transacted_today
  );
  const percent = todayCostBasis === 0 ? 0 : (value / todayCostBasis) * 100;
  return {
    value,
    percent,
  };
};

const _calculateTotalChangeForPosition = (position, price) => {
  const { shares, total_cost } = position;
  const value = price * shares - total_cost;
  const totalCostAbs = Math.abs(total_cost);
  const percent = totalCostAbs === 0 ? 0 : (value / totalCostAbs) * 100;
  return {
    value,
    percent,
  };
};

const _buildInitialValues = (position, price, previousClosePrice) => {
  const todayChange = _calculateDayChangeForPosition(position, price, previousClosePrice);
  const totalChange = _calculateTotalChangeForPosition(position, price, previousClosePrice);
  return {
    todayChange,
    totalChange,
  };
};

export const usePositionPerformance = (securityId) => {
  const position = usePosition(securityId);
  const { price, previousClose } = usePriceData(securityId);
  const { isPreMarket } = useExtendedTradingHours();

  const [state, dispatch] = React.useReducer(reducer, initialState, () =>
    _buildInitialValues(position, price, previousClose)
  );

  React.useEffect(
    () => {
      const todayChangeData = _calculateDayChangeForPosition(position, price, previousClose);
      const totalChangeData = _calculateTotalChangeForPosition(position, price, previousClose);
      dispatch({
        type: actions.updateTodayAndTotalChange,
        payload: {
          todayChange: isPreMarket
            ? {
                value: 0,
                percent: 0,
              }
            : todayChangeData,
          totalChange: totalChangeData,
        },
      });
    },
    [position, securityId, price, previousClose]
  );

  return state;
};
