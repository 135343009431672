import React from 'react';
import styled from 'styled-components';
import {
  Agreement,
  BonusDisclaimer,
  FormTitle,
  FormWrap,
  LoadingCover,
  RegisteredEmail,
  SignSwitchLink,
  SubTitle,
  TermsAndConditions,
  ValidationWrap,
} from '@src/main/components/login/LoginStyles';
import { parseQueryString } from '@src/helpers/routerHelpers';
import { signUpValidation } from '@src/main/containers/SignUp/utils/SignUpValidation';
import { signUp } from '@src/main/containers/SignUp/utils/services';
import Loading from '@src/main/components/login/Loading';
import InputField from '@src/main/components/form/InputField';
import ReCAPTCHA from 'react-google-recaptcha';
import { getRecaptchaKey } from '../../../../config';
import { FlatButton } from '@src/main/components/buttons';
import { Link } from 'react-router-dom';
import { Container } from '@src/main/components/ui';
import { BASE_PRODUCT_DOMAIN, DOMAIN, PRODUCT_DISPLAY_FULL_NAME, PRODUCT_NAME, PRODUCT_NAMES } from '@src/appConfig';
import { isSignedIn } from '@src/main/containers/SignIn/utils/services';

const LoginWrapper = styled.div`
  * {
    color: ${({ theme }) => theme.themeColors.text};

    :hover {
      text-decoration: none;
      opacity: 0.8;
    }
  }
`;

const InputErrorWrapper = styled.div`
  position: relative;
  padding-bottom: 16px;
`;
const InputErrorPosition = styled.div`
  position: absolute;
  top: -10px;
  left: 12px;
`;

const SignUpTermsAndConditions = () => {
  return (
    <TermsAndConditions>
      <Agreement>
        By signing up you agree to our{' '}
        <a href={`https://${DOMAIN}/terms_of_service`} target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>
        ,{' '}
        <a href={`https://${DOMAIN}/privacy_policy`} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>{' '}
        and{' '}
        <a href={`https://${DOMAIN}/referral_rules`} target="_blank" rel="noopener noreferrer">
          Referral Program Rules
        </a>
      </Agreement>
      {null && (
        <BonusDisclaimer>
          <span>
            For a limited time only. Offer does not apply to simulated trading accounts. Other restrictions apply. See{' '}
            <a href={`https://${DOMAIN}/rewards_rules`} target="_blank" rel="noopener noreferrer">
              Program Rules
            </a>
            {' for details.'}
          </span>
        </BonusDisclaimer>
      )}
    </TermsAndConditions>
  );
};

const InputError = ({ name, formErrors }) => {
  const errors = formErrors[name];
  if (!errors) return null;

  return (
    <InputErrorWrapper>
      <InputErrorPosition>
        <ValidationWrap>{errors[0]}</ValidationWrap>
      </InputErrorPosition>
    </InputErrorWrapper>
  );
};

export const SignUpForm = () => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [isSigningUp, setIsSigningUp] = React.useState(false);
  const [signUpComplete, setSignUpComplete] = React.useState(false);

  const [formErrors, setFormErrors] = React.useState({});
  const [submissionError, setSubmissionError] = React.useState(null);

  const [isEmailSignup, setIsEmailSignup] = React.useState(false);
  const [isLinkingPartnerAccount, setIsLinkingPartnerAccount] = React.useState(false);

  const recaptchaRef = React.createRef();

  React.useEffect(() => {
    const checkIfAlreadyAuthed = async () => {
      const { isAuthed } = await isSignedIn();
      if (isAuthed) {
        window.location = '/app/';
      }
    };
    checkIfAlreadyAuthed();
  }, []);

  React.useEffect(() => {
    if (PRODUCT_NAME === PRODUCT_NAMES.TheStreet) {
      setIsLinkingPartnerAccount(true);
    }

    const query = parseQueryString(window.location.search);
    if (query.email) {
      setIsEmailSignup(true);
      const emailParam = decodeURIComponent(query.email);
      setEmail(emailParam);
      if (query.linking) {
        setIsLinkingPartnerAccount(true);
      }
    }
  }, []);

  const userSignUpComplete = () => {
    setIsSigningUp(false);
    setSignUpComplete(true);
  };
  const onClear = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setIsEmailSignup(false);
    setIsLinkingPartnerAccount(false);
  };

  const userSignUpError = (errorMessage) => {
    setIsSigningUp(false);
    setSubmissionError(errorMessage);
  };

  const userSignUp = (e) => {
    e?.preventDefault();

    const validationResult = signUpValidation({
      firstName,
      lastName,
      email,
      password,
    });

    if (validationResult.isValid) {
      setIsSigningUp(true);
      setFormErrors({});
      setSubmissionError(null);
      const recaptchaValue = recaptchaRef.current.getValue();
      signUp({
        firstName,
        lastName,
        email,
        password,
        rememberMe: 1,
        recaptchaValue,
        userSignUpComplete,
        userSignUpError,
      });
    } else {
      setFormErrors(validationResult.errors);
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      userSignUp(e);
    }
  };

  if (signUpComplete) {
    return (
      <FormWrap>
        <Container top={8}>
          <SubTitle>We've sent a confirmation link to your email address. </SubTitle>
          <SubTitle>Please check your email, and click the link to activate your account.</SubTitle>
          <Container>
            <LoginWrapper>
              <b>
                <Link to="/login">Log In</Link>
              </b>
            </LoginWrapper>
          </Container>
        </Container>
      </FormWrap>
    );
  }

  return (
    <div onKeyDown={(e) => handleKeyUp(e)}>
      {isSigningUp && (
        <LoadingCover>
          <Loading />
        </LoadingCover>
      )}
      <FormWrap issigningup={isSigningUp.toString()}>
        <form>
          {isLinkingPartnerAccount ? (
            <>
              <FormTitle>Getting Started</FormTitle>
              <Container top={8}>
                <SignSwitchLink>
                  Linking your account gives you access to {PRODUCT_DISPLAY_FULL_NAME} and all of your exclusive member
                  content
                </SignSwitchLink>
              </Container>
              <Container top={8}>
                <SignSwitchLink>
                  Be sure to use your same email on {BASE_PRODUCT_DOMAIN} to ensure you will have access to all of your
                  premium content.
                </SignSwitchLink>
              </Container>
            </>
          ) : (
            <>
              <FormTitle>Getting Started</FormTitle>
              <Container top={6}>
                <SignSwitchLink>
                  Already have an account? <Link to="/login">Log In</Link>
                </SignSwitchLink>
              </Container>
            </>
          )}
          {isEmailSignup && (
            <Container top={isLinkingPartnerAccount ? 0 : 16}>
              <RegisteredEmail>
                {isLinkingPartnerAccount ? (
                  <>
                    Continue sign up for <b>{email}</b>, please enter your details below to complete registration and
                    link your {PRODUCT_NAME}.com account.
                  </>
                ) : (
                  <>
                    Continue sign up for <b>{email}</b>, please enter your details below to complete registration
                  </>
                )}
              </RegisteredEmail>
            </Container>
          )}
          <Container top={16}>
            <InputField
              id="first-name-field"
              placeholder={'First Name'}
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <InputError name="firstName" formErrors={formErrors} />
          </Container>

          <InputField
            id="last-name-field"
            placeholder={'Last Name'}
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <InputError name="lastName" formErrors={formErrors} />

          {!isEmailSignup && (
            <InputField
              id="email-field"
              placeholder={'Email'}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            />
          )}
          <InputError name="email" formErrors={formErrors} />

          <InputField
            id="password-field"
            placeholder={'Password'}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
          />
          <InputError name="password" formErrors={formErrors} />

          <ReCAPTCHA ref={recaptchaRef} sitekey={getRecaptchaKey()} />

          {isEmailSignup && (
            <Container top={4}>
              <FlatButton transparent fullWidth onClick={onClear}>
                Clear
              </FlatButton>
            </Container>
          )}
          <Container top={isEmailSignup ? 4 : 16}>
            <FlatButton id="sign-up-submit" fullWidth onClick={userSignUp}>
              Sign Up
            </FlatButton>
          </Container>

          {submissionError && (
            <Container top={4}>
              <ValidationWrap>{submissionError}</ValidationWrap>
            </Container>
          )}
          <Container top={16}>
            <SignUpTermsAndConditions />
          </Container>
        </form>
      </FormWrap>
      {isLinkingPartnerAccount && (
        <Container top={8}>
          <SignSwitchLink>
            Already have a Tornado.com account? <Link to="/login?force=true">Log In</Link>
          </SignSwitchLink>
        </Container>
      )}
    </div>
  );
};
