import {
  ADD_IMPORT_CONNECTIONS_PAGE,
  REMOVE_IMPORT_CONNECTIONS_PAGE,
  ADD_IMPORT_NETWORKS,
  LOADING_IMPORT_CONNECTIONS_PAGE,
  LOADING_IMPORT_NETWORKS,
  LOADING_IMPORT_CONTACTS,
  ADD_IMPORT_CONTACTS,
} from '../constants';

const defaultState = {
  isLoading: false,
  isLoadingNetworks: false,
  isLoadingContacts: false,

  networks: [],
  contactsData: {},

  partial: null,
};

export default function importConnectionsReducer(state = defaultState, action) {
  switch (action.type) {
    case LOADING_IMPORT_CONNECTIONS_PAGE:
      return {
        ...state,
        isLoading: true,
      };

    case LOADING_IMPORT_NETWORKS:
      return {
        ...state,
        isLoadingNetworks: true,
      };

    case LOADING_IMPORT_CONTACTS:
      return {
        ...state,
        isLoadingContacts: true,
      };

    case ADD_IMPORT_CONNECTIONS_PAGE:
      return {
        ...state,
        isLoading: false,
        partial: action.payload.partial,
      };

    case REMOVE_IMPORT_CONNECTIONS_PAGE:
      return {
        ...state,
        partial: null,
      };

    case ADD_IMPORT_CONTACTS:
      return {
        ...state,
        isLoadingContacts: false,
        contactsData: {
          ...action.payload,
        },
      };

    case ADD_IMPORT_NETWORKS:
      const networks = action.payload.networks;
      const uniqNetworks = networks.filter((network) => state.networks.every((n) => n.network !== network.network));
      return {
        ...state,
        isLoadingNetworks: false,
        networks: [...state.networks, ...uniqNetworks],
      };

    default:
      return state;
  }
}
