import { logMetricsTrackingEvent } from '../../../actions';

const VIEW_VERIFY_BANK_BANNER = {
  send: (properties) => {
    const event = 'View Verify Bank Banner';
    logMetricsTrackingEvent(event, properties)();
  },
};
const CLICK_VERIFY_BANK_BANNER = {
  send: (properties) => {
    const event = 'Click Verify Bank Banner';
    logMetricsTrackingEvent(event, properties)();
  },
};
const CHOOSE_LINK_BANK_ACCOUNT_OPTION = {
  send: (properties) => {
    // Mode: Plaid Microdeposits
    const event = 'Choose Link Bank Account Option';
    logMetricsTrackingEvent(event, properties)();
  },
};
const SUBMIT_DEPOSIT_FORM = {
  send: (properties) => {
    const event = 'Submit Deposit Form';
    logMetricsTrackingEvent(event, properties)();
  },
};
const SUBMIT_DEPOSIT_FORM_SUCCESS = {
  send: (properties) => {
    const event = 'Submit Deposit Form Success';
    logMetricsTrackingEvent(event, properties)();
  },
};
const SUBMIT_DEPOSIT_FORM_FAILURE = {
  send: (properties) => {
    const event = 'Submit Deposit Form Failure';
    logMetricsTrackingEvent(event, properties)();
  },
};
const PRESS_REMOVE_RECURRING_DEPOSIT = {
  send: () => {
    const event = 'Click Remove Recurring Deposit';
    logMetricsTrackingEvent(event)();
  },
};

const PRESS_MANAGE_RECURRING_DEPOSITS = {
  send: () => {
    const event = 'Pressed Manage Recurring Deposits';
    logMetricsTrackingEvent(event)();
  },
};
const PRESS_START_CREATE_RECURRING_DEPOSIT = {
  send: (properties) => {
    const event = 'Press Start Create Recurring Deposit';
    logMetricsTrackingEvent(event, properties)();
  },
};
const RECURRING_DEPOSIT_ADDED_SUCCESS = {
  send: (properties) => {
    const event = 'Recurring Deposit Added Success';
    logMetricsTrackingEvent(event, properties)();
  },
};
const RECURRING_DEPOSIT_ADDED_FAILURE = {
  send: (properties) => {
    const event = 'Recurring Deposit Added Failure';
    logMetricsTrackingEvent(event, properties)();
  },
};
const RECURRING_DEPOSIT_CANCELED_SUCCESS = {
  send: (properties) => {
    const event = 'Recurring Deposit Canceled Success';
    logMetricsTrackingEvent(event, properties)();
  },
};

const trackingEvents = {
  CLICK_VERIFY_BANK_BANNER,
  VIEW_VERIFY_BANK_BANNER,

  CHOOSE_LINK_BANK_ACCOUNT_OPTION,
  PRESS_REMOVE_RECURRING_DEPOSIT,
  PRESS_MANAGE_RECURRING_DEPOSITS,

  SUBMIT_DEPOSIT_FORM,
  SUBMIT_DEPOSIT_FORM_SUCCESS,
  SUBMIT_DEPOSIT_FORM_FAILURE,

  PRESS_START_CREATE_RECURRING_DEPOSIT,
  RECURRING_DEPOSIT_ADDED_SUCCESS,
  RECURRING_DEPOSIT_ADDED_FAILURE,
  RECURRING_DEPOSIT_CANCELED_SUCCESS,
};

export default trackingEvents;
