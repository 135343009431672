import { BASEURL } from '.';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api/v1`;

export const adminAdapter = {
  postLessonToApi: (lessonData) => {
    return sendRequest('post', [`${URL}/lesson_save`, lessonData]);
  },
  publishLesson: (lesson_id) => {
    return sendRequest('post', [`${URL}/lesson_publish`, { lesson_id, publish: true }]);
  },
};
