import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/index';
import LoadingIcon from '../../components/misc/LoadingIcon';
import {
  isLoadingFundamentalData,
  formatSecurityPropertyValue,
  getSecurityValueFromStore,
} from '../../helpers/securitiesHelpers';
import { usePriceData } from '../../main/hooks/securities/usePriceData';
import { DISPLAY_FORMAT_TYPES, formatValueTo } from '../../main/utils/numbers';

// only use if you do not know the specific data source the data is coming from ie. all are not Fundamental Data, etc.

// DEPRECATED: not as performant, avoid new uses
// DEPRECATED: not as performant, avoid new uses
// DEPRECATED: not as performant, avoid new uses
// DEPRECATED: not as performant, avoid new uses
// DEPRECATED: not as performant, avoid new uses
// DEPRECATED: not as performant, avoid new uses

const PriceRender = ({ property, securityId }) => {
  const { price, change, percentChange } = usePriceData(securityId);

  if (property === 'current_price') {
    return <span>{formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, price)}</span>;
  }
  if (property === 'current_price_change') {
    return <span>{formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, change)}</span>;
  }
  if (property === 'current_price_change_percent') {
    return <span>{formatValueTo(DISPLAY_FORMAT_TYPES.PERCENTAGE, percentChange)}</span>;
  }

  return null;
};

class SecurityData extends Component {
  render() {
    const value = this.formatValue();
    if (
      (this.props.hideIfNA && value === 'N/A' && !this._isPropertyPricing()) ||
      (this.hideIfLoading && this.isLoading())
    ) {
      return null;
    }

    if (this.isLoading()) {
      return <LoadingIcon icon="fading-3balls" size="small" />;
    }

    return (
      <span className={this.props.customClass || ''} style={this.props.customStyle || {}}>
        {this._renderValue()}
      </span>
    );
  }

  _renderPriceData = () => {
    const property = this.props.property;
    const securityId = this.props.securityId;
    return <PriceRender securityId={securityId} property={property} />;
  };

  _isPropertyPricing = () => {
    const property = this.props.property;
    const pricingProperties = ['current_price', 'current_price_change', 'current_price_change_percent'];
    return pricingProperties.some((pricingProperty) => property === pricingProperty);
  };

  _renderValue = () => {
    const value = this.formatValue();
    return this._isPropertyPricing() ? this._renderPriceData() : value;
  };

  isLoading = () => {
    // HACK: Fund data will take the longest on avg.
    return isLoadingFundamentalData(this.props.securityId, this.props.fundamentalData);
  };

  getValue = (property) => {
    const securityId = this.props.securityId;
    const securitiesStore = this.props.securities;
    const fundamentalDataStore = this.props.fundamentalData;

    return getSecurityValueFromStore(securityId, property, securitiesStore, fundamentalDataStore).value;
  };

  formatValue = () => {
    const property = this.props.property;
    const value = this.getValue(property);
    const formattedValue = formatSecurityPropertyValue(value, property);

    return this.props.naIndicator && formattedValue === 'N/A' ? this.props.naIndicator : formattedValue;
  };
}

const mapStateToProps = (state) => {
  return {
    securities: state.securities,
    fundamentalData: state.fundamentalData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityData);
