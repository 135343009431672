import { LEARN_LESSON_TYPES, CUSTOM_LEARN_LESSON_COMPONENT_TYPES, EXTRA_CONTENT_TYPES } from './types';
import { buildLearnLessonPhraseMessage, slugifyLessonName } from '../../utils/learn';
import { PRODUCT_PARTNERS } from './partners';
import * as Content from './content';
import * as Disclaimers from './disclaimers';

export const coreContentScreens = {
  1: {
    id: 1,
    name: 'Risk',
    heading: 'Risk',
    phraseDisplay: 'risk',
    type: LEARN_LESSON_TYPES.text,
    content: Content.riskContent,
    disclaimers: Disclaimers.riskDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  2: {
    id: 2,
    name: 'Investing vs. Saving',
    heading: 'Investing vs. Saving',
    phraseDisplay: 'investing vs. saving',
    type: LEARN_LESSON_TYPES.text,
    content: Content.ivsContent,
    disclaimers: Disclaimers.ivsDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  3: {
    id: 3,
    name: 'Diversification',
    heading: 'Diversification',
    phraseDisplay: 'diversification',
    type: LEARN_LESSON_TYPES.text,
    content: Content.diversificationContent,
    disclaimers: Disclaimers.diversificationDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  4: {
    id: 4,
    name: 'Compounding',
    heading: 'Compounding',
    phraseDisplay: 'compounding',
    type: LEARN_LESSON_TYPES.text,
    content: Content.compoundingContent,
    disclaimers: Disclaimers.compoundingDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  5: {
    id: 5,
    name: 'Dividends',
    heading: 'Dividends',
    phraseDisplay: 'dividends',
    type: LEARN_LESSON_TYPES.text,
    content: Content.dividendContent,
    disclaimers: Disclaimers.dividendDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  6: {
    id: 6,
    name: 'Optimization',
    heading: 'Optimization',
    phraseDisplay: 'optimization',
    type: LEARN_LESSON_TYPES.text,
    content: Content.optimizationContent,
    disclaimers: Disclaimers.optimizationDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  7: {
    id: 7,
    name: 'Dollar Cost Averaging',
    heading: 'Dollar Cost Averaging',
    phraseDisplay: 'dollar cost averaging',
    type: LEARN_LESSON_TYPES.text,
    content: Content.dollarCostContent,
    disclaimers: Disclaimers.dollarCostDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  8: {
    id: 8,
    name: 'Concentration',
    heading: 'Concentration',
    phraseDisplay: 'concentration',
    type: LEARN_LESSON_TYPES.text,
    content: Content.concentrationContent,
    disclaimers: Disclaimers.concentrationDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  9: {
    id: 9,
    name: 'ETFs',
    heading: 'ETFs',
    phraseDisplay: 'ETFs',
    type: LEARN_LESSON_TYPES.text,
    content: Content.etfsContent,
    disclaimers: Disclaimers.etfsDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  10: {
    id: 10,
    name: 'Valuation',
    heading: 'Valuation',
    phraseDisplay: 'valuation',
    type: LEARN_LESSON_TYPES.text,
    content: Content.valuationContent,
    disclaimers: Disclaimers.valuationDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  11: {
    id: 11,
    name: 'Earnings Reports',
    heading: 'Earnings Reports',
    phraseDisplay: 'earnings reports',
    type: LEARN_LESSON_TYPES.text,
    content: Content.earningsReportsContent,
    disclaimers: Disclaimers.earningsReportsDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },

  13: {
    id: 13,
    name: 'Fractional Shares',
    heading: 'Fractional Shares',
    phraseDisplay: 'fractional shares',
    type: LEARN_LESSON_TYPES.text,
    content: Content.fractionalContent,
    disclaimers: Disclaimers.fractionalDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  14: {
    id: 14,
    name: 'Getting Started',
    heading: 'Getting Started',
    phraseDisplay: '$!How to get started',
    type: LEARN_LESSON_TYPES.text,
    content: Content.gettingStarted12Content,
    disclaimers: Disclaimers.gettingStarted12Disclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  15: {
    id: 15,
    name: 'When to Sell',
    heading: 'When to Sell',
    phraseDisplay: 'when to sell',
    type: LEARN_LESSON_TYPES.text,
    content: Content.whenToSellContent,
    disclaimers: Disclaimers.whenToSellDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  16: {
    id: 16,
    name: 'Business Risk',
    heading: 'Business Risk',
    phraseDisplay: 'business risk',
    type: LEARN_LESSON_TYPES.text,
    content: Content.businessRiskContent,
    disclaimers: Disclaimers.businessRiskDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  17: {
    id: 17,
    name: 'Ratios',
    heading: 'Ratios',
    phraseDisplay: 'ratios',
    type: LEARN_LESSON_TYPES.text,
    content: Content.ratiosContent,
    disclaimers: Disclaimers.ratiosDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  18: {
    id: 18,
    name: 'Community',
    heading: 'Community',
    phraseDisplay: 'community',
    type: LEARN_LESSON_TYPES.text,
    content: Content.communityContent,
    disclaimers: Disclaimers.communityDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  19: {
    id: 19,
    name: 'Market Capitalization',
    heading: 'Market Capitalization',
    phraseDisplay: 'market capitalization',
    type: LEARN_LESSON_TYPES.text,
    content: Content.marketCapitalizationContent,
    disclaimers: Disclaimers.marketCapitalizationDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  20: {
    id: 20,
    name: 'EPS',
    heading: 'EPS',
    phraseDisplay: 'EPS',
    type: LEARN_LESSON_TYPES.text,
    content: Content.epsContent,
    disclaimers: Disclaimers.epsDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  21: {
    id: 21,
    name: 'Growth Stocks',
    heading: 'Growth Stocks',
    phraseDisplay: 'growth stocks',
    type: LEARN_LESSON_TYPES.text,
    content: Content.growthStocksContent,
    disclaimers: Disclaimers.growthStocksDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  22: {
    id: 22,
    name: 'TAM',
    heading: 'TAM',
    phraseDisplay: 'TAM',
    type: LEARN_LESSON_TYPES.text,
    content: Content.tamContent,
    disclaimers: Disclaimers.tamDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  23: {
    id: 23,
    name: 'Insider Transactions',
    heading: 'Insider Transactions',
    phraseDisplay: 'insider transactions',
    type: LEARN_LESSON_TYPES.text,
    content: Content.insiderTransactionsContent,
    disclaimers: Disclaimers.insiderTransactionsDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  24: {
    id: 24,
    name: 'Retail',
    heading: 'Retail',
    phraseDisplay: 'retail',
    type: LEARN_LESSON_TYPES.text,
    content: Content.retailContent,
    disclaimers: Disclaimers.retailDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  25: {
    id: 25,
    name: 'Consensus Estimates',
    heading: 'Consensus Estimates',
    phraseDisplay: 'consensus estimates',
    type: LEARN_LESSON_TYPES.text,
    content: Content.consensusEstimatesContent,
    disclaimers: Disclaimers.consensusEstimatesDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },

  26: {
    id: 26,
    name: 'Market Indices',
    heading: 'Market Indices',
    phraseDisplay: 'market indices',
    type: LEARN_LESSON_TYPES.text,
    content: Content.marketIndicesContent,
    disclaimers: Disclaimers.marketIndicesDisclaimers,
    metadata: {
      isCoreContent: true,
    },

    sponsorId: 1,
    moreOnTopicUrl: 'https://www.nasdaq.com/education/dow-nasdaq-sp-500%3A-what-does-it-all-mean',
  },

  27: {
    id: 27,
    name: 'Stocks, Markets & Indices',
    heading: 'Stocks, Markets & Indices',
    phraseDisplay: 'stocks, markets & indices',
    type: LEARN_LESSON_TYPES.text,
    content: Content.stocksMarketsIndexesContent,
    disclaimers: Disclaimers.stocksMarketsIndexesDisclaimers,
    metadata: {
      isCoreContent: true,
    },

    sponsorId: 1,
    moreOnTopicUrl: 'https://www.nasdaq.com/education/the-stock-market%3A-where-buyers-and-sellers-meet',
  },

  12: {
    id: 12,
    name: 'Order Types',
    heading: 'Order Types',
    phraseDisplay: 'order types',
    type: LEARN_LESSON_TYPES.text,
    content: Content.orderTypesContent,
    disclaimers: Disclaimers.orderTypesDisclaimers,
    metadata: {
      isCoreContent: true,
    },

    sponsorId: 1,
    moreOnTopicUrl: 'https://www.nasdaq.com/education/what-is-a-stock-order',
  },

  28: {
    id: 28,
    name: 'ESG',
    heading: 'ESG',
    phraseDisplay: 'ESG',
    type: LEARN_LESSON_TYPES.text,
    content: Content.esgContent,
    disclaimers: Disclaimers.esgDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },

  29: {
    id: 29,
    name: 'Value Stocks',
    heading: 'Value Stocks',
    phraseDisplay: 'value stocks',
    type: LEARN_LESSON_TYPES.text,
    content: Content.valueStocksContent,
    disclaimers: Disclaimers.valueStocksDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },

  30: {
    id: 30,
    name: 'Laterals',
    heading: 'Laterals',
    phraseDisplay: 'laterals',
    type: LEARN_LESSON_TYPES.text,
    content: Content.lateralsContent,
    disclaimers: Disclaimers.lateralsDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },

  31: {
    id: 31,
    name: 'Investing vs. Trading',
    heading: 'Investing vs. Trading',
    phraseDisplay: 'investing vs. trading',
    type: LEARN_LESSON_TYPES.text,
    content: Content.investingVsTradingContent,
    disclaimers: Disclaimers.investingVsTradingDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  32: {
    id: 32,
    name: 'S&P 500',
    heading: 'S&P 500',
    phraseDisplay: 'S&P 500',
    type: LEARN_LESSON_TYPES.text,
    content: Content.sp500Content,
    disclaimers: Disclaimers.sp500Disclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  33: {
    id: 33,
    name: 'Investment Goals',
    heading: 'Investment Goals',
    phraseDisplay: 'investment goals',
    type: LEARN_LESSON_TYPES.text,
    content: Content.investmentGoalsContent,
    disclaimers: Disclaimers.investmentGoalsDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  34: {
    id: 34,
    name: 'Market Timing',
    heading: 'Market Timing',
    phraseDisplay: 'market timing',
    type: LEARN_LESSON_TYPES.text,
    content: Content.marketTimingContent,
    disclaimers: Disclaimers.marketTimingDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },

  35: {
    id: 35,
    name: 'Stocks and Wealth',
    heading: 'Stocks and Wealth',
    phraseDisplay: 'the relation of stocks and wealth',
    type: LEARN_LESSON_TYPES.text,
    content: Content.stocksAndWealthContent,
    disclaimers: Disclaimers.stocksAndWealthDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  36: {
    id: 36,
    name: 'Comparing Stocks',
    heading: 'Comparing Stocks',
    phraseDisplay: 'ways to compare stocks',
    type: LEARN_LESSON_TYPES.text,
    content: Content.comparingStocksContent,
    disclaimers: Disclaimers.comparingStocksDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  37: {
    id: 37,
    name: 'Big Tech',
    heading: 'Big Tech',
    phraseDisplay: 'big tech',
    type: LEARN_LESSON_TYPES.text,
    content: Content.bigTechContent,
    disclaimers: Disclaimers.bigTechDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },

  38: {
    id: 38,
    name: 'Interest Rates',
    heading: 'Interest Rates',
    phraseDisplay: 'the impact of interest rates on the market',
    type: LEARN_LESSON_TYPES.text,
    content: Content.interestRatesContent,
    disclaimers: Disclaimers.interestRatesDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  39: {
    id: 39,
    name: 'Margins',
    heading: 'Margins',
    phraseDisplay: 'margins',
    type: LEARN_LESSON_TYPES.text,
    content: Content.marginsContent,
    disclaimers: Disclaimers.marginsDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  40: {
    id: 40,
    name: 'Valuation - Multiples',
    heading: 'Valuation - Multiples',
    phraseDisplay: 'valuation multiples',
    type: LEARN_LESSON_TYPES.text,
    content: Content.valuationMultiplesContent,
    disclaimers: Disclaimers.valuationMultiplesDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },

  41: {
    id: 41,
    name: 'ESG - Investing',
    heading: 'ESG - Investing',
    phraseDisplay: 'investing in ESG',
    pages: 6,
    type: LEARN_LESSON_TYPES.text,
    content: Content.esgInvestingContent,
    disclaimers: Disclaimers.esgInvestingDisclaimers,
    metadata: {
      isCoreContent: true,
    },
  },
  42: {
    id: 42,
    name: 'ESG - Ratings',
    heading: 'ESG - Ratings',
    phraseDisplay: 'ESG ratings',
    type: LEARN_LESSON_TYPES.text,
    pages: 7,
    metadata: {
      isCoreContent: true,
    },

    content: Content.esgRatingsContent,
    disclaimers: Disclaimers.esgRatingsDisclaimers,
  },
  43: {
    id: 43,
    name: 'Information Technology',
    heading: 'Information Technology',
    phraseDisplay: 'the information technology sector',
    pages: 7,
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.informationTechnologyContent,
    disclaimers: Disclaimers.informationTechnologyDisclaimers,
  },
  44: {
    id: 44,
    name: 'Health Care',
    heading: 'Health Care',
    phraseDisplay: 'the health care sector',
    pages: 7,
    type: LEARN_LESSON_TYPES.text,
    metadata: {
      isCoreContent: true,
    },

    content: Content.healthcareContent,
    disclaimers: Disclaimers.healthcareDisclaimers,
  },
  45: {
    id: 45,
    name: 'Consumer Discretionary',
    heading: 'Consumer Discretionary',
    phraseDisplay: 'consumer discretionary',
    pages: 7,
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.consumerDiscretionaryContent,
    disclaimers: Disclaimers.consumerDiscretionaryDisclaimers,
  },
  46: {
    id: 46,
    name: 'Famous Female Investors',
    heading: 'Famous Female Investors',
    phraseDisplay: '$!Who are some famous female investors',
    pages: 6,
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.famousFemaleInvestorsContent,
    disclaimers: Disclaimers.famousFemaleInvestorsDisclaimers,
  },
  47: {
    id: 47,
    name: 'How much to invest',
    heading: 'How much to invest',
    phraseDisplay: 'factored into the decision of how much to invest',
    pages: 6,
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.howMuchToInvestContent,
    disclaimers: Disclaimers.howMuchToInvestDisclaimers,
  },

  49: {
    id: 49,
    name: 'Portfolios',
    heading: 'Portfolios',
    phraseDisplay: 'portfolios',
    pages: 5,
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.portfoliosContent,
    disclaimers: Disclaimers.portfoliosDisclaimers,
  },
  50: {
    id: 50,
    name: 'Market Downturns',
    heading: 'Market Downturns',
    phraseDisplay: 'market downturns',
    pages: 6,
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.marketDownturnsContent,
    disclaimers: Disclaimers.marketDownturnsDisclaimers,
  },

  51: {
    id: 51,
    name: 'Pricing Power',
    heading: 'Pricing Power',
    phraseDisplay: 'pricing power',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.pricingPowerContent,
    disclaimers: Disclaimers.pricingPowerDisclaimers,
  },
  52: {
    id: 52,
    name: '10-K',
    heading: '10-K',
    phraseDisplay: '10-K',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.tenKContent,
    disclaimers: Disclaimers.tenKDisclaimers,
  },
  53: {
    id: 53,
    name: 'Guidance',
    heading: 'Guidance',
    phraseDisplay: 'guidance',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.guidanceContent,
    disclaimers: Disclaimers.guidanceDisclaimers,
  },
  54: {
    id: 54,
    name: 'ROI',
    heading: 'ROI',
    phraseDisplay: 'ROI',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.ROIContent,
    disclaimers: Disclaimers.ROIDisclaimers,
  },
  55: {
    id: 55,
    name: 'Earnings Calls',
    heading: 'Earnings Calls',
    phraseDisplay: 'earnings calls',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.earningsCallsContent,
    disclaimers: Disclaimers.earningsCallsDisclaimers,
  },
  56: {
    id: 56,
    name: 'QQQ',
    heading: 'QQQ',
    phraseDisplay: 'QQQ',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.QQQContent,
    disclaimers: Disclaimers.QQQDisclaimers,
  },
  57: {
    id: 57,
    name: 'Buying a Stock',
    heading: 'Buying a Stock',
    phraseDisplay: 'buying a stock',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.buyingStockContent,
    disclaimers: Disclaimers.buyingStockDisclaimers,
  },
  58: {
    id: 58,
    name: 'Portfolio Optimization',
    heading: 'Portfolio Optimization',
    phraseDisplay: 'portfolio optimization',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.portfolioOptimizationContent,
    disclaimers: Disclaimers.portfolioOptimizationDisclaimers,
  },
  59: {
    id: 59,
    name: 'Your Stock Watchlist',
    heading: 'Your Stock Watchlist',
    phraseDisplay: 'your stock watchlist',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.yourStockWatchlistContent,
    disclaimers: Disclaimers.yourStockWatchlistDisclaimers,
  },
  60: {
    id: 60,
    name: 'Pros & Cons',
    heading: 'Pros & Cons',
    phraseDisplay: 'pros & cons',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.prosConsContent,
    disclaimers: Disclaimers.prosConsDisclaimers,
  },
  61: {
    id: 61,
    name: 'In-app Audio',
    heading: 'In-app Audio',
    phraseDisplay: 'in-app audio',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.inAppAudioContent,
    disclaimers: Disclaimers.inAppAudioDisclaimers,
  },
  62: {
    id: 62,
    name: 'Women-led companies',
    heading: 'Women-led companies you can invest in',
    phraseDisplay: '$!What are some women-led companies you can invest in?',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.womenLedContent,
    disclaimers: Disclaimers.womenLedDisclaimers,
  },
  63: {
    id: 63,
    name: 'Tornado’s Values',
    heading: 'Tornado’s Values',
    phraseDisplay: '$!What are Tornado’s Values?',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.tornadoValuesContent,
    disclaimers: Disclaimers.tornadoValuesDisclaimers,
  },
  64: {
    id: 64,
    name: 'Exclusive Tornado Perks',
    heading: 'Exclusive Tornado Perks',
    phraseDisplay: '$!What are the exclusive Tornado perks?',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.exclusiveTornadoPerksContent,
    disclaimers: Disclaimers.exclusiveTornadoPerksDisclaimers,
  },
  65: {
    id: 65,
    name: 'Habits of Successful Investors',
    heading: 'Habits of Successful Investors',
    phraseDisplay: 'habits of successful investors',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.habitsOfSuccessFulInvestorsContent,
    disclaimers: Disclaimers.habitsOfSuccessFulInvestorsDisclaimers,
  },
  66: {
    id: 66,
    name: 'How We Support Your Investing Journey',
    heading: 'How We Support Your Investing Journey',
    phraseDisplay: "$!Let's get started!",
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.howWeSupportYourInvestingJourneyContent,
    disclaimers: Disclaimers.howWeSupportYourInvestingJourneyDisclaimers,
  },
  67: {
    id: 67,
    name: 'Meet our CTLs',
    heading: 'Meet our CTLs',
    phraseDisplay: '$!Who are the CTLs? (Community Thought Leaders)',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.meetCTLsContent,
    disclaimers: Disclaimers.meetCTLsDisclaimers,
  },
  68: {
    id: 68,
    name: 'Amazon',
    heading: 'Amazon',
    phraseDisplay: "$!Let's take a closer look at the Amazon stock.",
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.amazonContent,
    disclaimers: Disclaimers.amazonDisclaimers,
  },
  69: {
    id: 69,
    name: 'Losses',
    heading: 'Losses',
    phraseDisplay: '$!What you can do when your stock is down.',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.lossesContent,
    disclaimers: Disclaimers.lossesDisclaimers,
  },
  70: {
    id: 70,
    name: 'What You Can Learn From Buying a Stock',
    heading: 'What You Can Learn From Buying a Stock',
    phraseDisplay: "$!Let's get started!",
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.whatYouCanLearnFromBuyingAStockContent,
    disclaimers: Disclaimers.whatYouCanLearnFromBuyingAStockDisclaimers,
  },
  71: {
    id: 71,
    name: 'Moats',
    heading: 'Moats',
    phraseDisplay: 'Moats',
    metadata: {
      isCoreContent: true,
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.moatsContent,
    disclaimers: Disclaimers.moatsDisclaimers,
  },
  72: {
    id: 72,
    name: 'Apple',
    heading: 'Apple',
    phraseDisplay: "$!Let's take a closer look at the Apple Inc. stock.",
    metadata: {
      isCoreContent: true,
      extraContent: {
        type: EXTRA_CONTENT_TYPES.security,
        id: 286,
        message: 'Check out the AAPL (Apple Inc.) stock page to see more pro/cons.',
      },
    },

    type: LEARN_LESSON_TYPES.text,
    content: Content.appleContent,
    disclaimers: Disclaimers.appleDisclaimers,
  },
  73: {
    id: 73,
    name: '2022 Themes',
    heading: '2022 Themes',
    phraseDisplay: "$!Let's take a closer look at the themes from 2022.",
    metadata: {
      isCoreContent: true,
    },
    pages: 7,
    type: LEARN_LESSON_TYPES.text,
    content: Content.themes2022Content,
    disclaimers: Disclaimers.themes2022Disclaimers,
  },
  74: {
    id: 74,
    name: 'Inflation Measure: PCE',
    heading: 'Inflation Measure: PCE',
    phraseDisplay: '$!What is the inflation measure, PCE index?',
    metadata: {
      isCoreContent: true,
    },
    pages: 7,
    type: LEARN_LESSON_TYPES.text,
    content: Content.pceIndexContent,
    disclaimers: Disclaimers.pceIndexDisclaimers,
  },
  75: {
    id: 75,
    name: 'Google, Inc.',
    heading: 'Google, Inc.',
    phraseDisplay: "$!Let's take a closer look at the Google, Inc. stock.",
    metadata: {
      isCoreContent: true,
    },
    pages: 7,
    type: LEARN_LESSON_TYPES.text,
    content: Content.googleContent,
    disclaimers: Disclaimers.googleDisclaimers,
  },
  76: {
    id: 76,
    name: 'Inflation Measure: CPI',
    heading: 'Inflation Measure: CPI',
    phraseDisplay: '$!What is the inflation measure, CPI index?',
    metadata: {
      isCoreContent: true,
    },
    pages: 7,
    type: LEARN_LESSON_TYPES.text,
    content: Content.cpiIndexContent,
    disclaimers: Disclaimers.cpiIndexDisclaimers,
  },
  77: {
    id: 77,
    name: 'Inflation',
    heading: 'Inflation',
    phraseDisplay: 'inflation',
    metadata: {
      isCoreContent: true,
    },
    pages: 7,

    type: LEARN_LESSON_TYPES.text,
    content: Content.inflationContent,
    disclaimers: Disclaimers.inflationDisclaimers,
  },
  78: {
    id: 78,
    name: 'Know the Competition',
    heading: 'Know the Competition',
    phraseDisplay: '$!How much should you know about your stocks competition?',
    metadata: {
      isCoreContent: true,
    },
    pages: 7,

    type: LEARN_LESSON_TYPES.text,
    content: Content.competitionContent,
    disclaimers: Disclaimers.competitionDisclaimers,
  },
  79: {
    id: 79,
    name: 'Capital Intensity and Stock Risk',
    heading: 'Capital Intensity and Stock Risk',
    phraseDisplay: '$!How does capital intensity relate to stock risk?',
    metadata: {
      isCoreContent: true,
    },
    pages: 8,

    type: LEARN_LESSON_TYPES.text,
    content: Content.capitalIntensityContent,
    disclaimers: Disclaimers.capitalIntensityDisclaimers,
  },
  80: {
    id: 80,
    name: 'Invest in the Future, not the Present',
    heading: 'Invest in the Future, not the Present',
    phraseDisplay: "$!Let's take a closer look at what causes stock prices to change.",
    metadata: {
      isCoreContent: true,
    },
    pages: 7,
    type: LEARN_LESSON_TYPES.text,
    content: Content.investInFutureContent,
    disclaimers: Disclaimers.investInFutureDisclaimers,
  },
  81: {
    id: 81,
    name: 'Banks',
    heading: 'Banks',
    phraseDisplay: "$!Let's take a closer look at bank stocks.",
    metadata: {
      isCoreContent: true,
    },
    pages: 8,
    type: LEARN_LESSON_TYPES.text,
    content: Content.banksContent,
    disclaimers: Disclaimers.banksDisclaimers,
  },
  82: {
    id: 82,
    name: 'Financial Sector',
    heading: 'Financial Sector',
    phraseDisplay: "$!Let's take a closer look at the financial sector.",
    metadata: {
      isCoreContent: true,
    },
    pages: 6,
    type: LEARN_LESSON_TYPES.text,
    content: Content.financialSectorContent,
    disclaimers: Disclaimers.financialSectorDisclaimers,
  },
  83: {
    id: 83,
    name: 'Stocks Aren’t the Economy',
    heading: 'Stocks Aren’t the Economy',
    phraseDisplay:
      "$!It's important to understand that the stock market and the economy are two distinct entities, let's look at why.",
    metadata: {
      isCoreContent: true,
    },
    pages: 7,
    type: LEARN_LESSON_TYPES.text,
    content: Content.stocksNotTheEconomyContent,
    disclaimers: Disclaimers.stocksNotTheEconomyDisclaimers,
  },
  84: {
    id: 84,
    name: 'The Communications Services Sector',
    heading: 'Communications Services Sector',
    phraseDisplay: "$!Let's take a closer look at the communications services sector.",
    metadata: {
      isCoreContent: true,
    },
    pages: 6,
    type: LEARN_LESSON_TYPES.text,
    content: Content.communicationsServicesSectorContent,
    disclaimers: Disclaimers.communicationsServicesSectorDisclaimers,
  },
  85: {
    id: 85,
    name: 'EBITDA',
    heading: 'EBITDA',
    phraseDisplay: '$!What is EBITDA?',
    metadata: {
      isCoreContent: true,
    },
    pages: 8,
    //Levels 1-4

    type: LEARN_LESSON_TYPES.text,
    content: Content.ebitaContent,
    disclaimers: Disclaimers.ebitaDisclaimers,
  },
  86: {
    id: 86,
    name: 'Rule of 72',
    heading: 'Rule of 72',
    phraseDisplay: '$!What is the Rule of 72?',
    metadata: {
      isCoreContent: true,
    },
    pages: 7,
    //Levels 1-4

    type: LEARN_LESSON_TYPES.text,
    content: Content.rule72Content,
    disclaimers: Disclaimers.rule72Disclaimers,
  },
  87: {
    id: 87,
    name: 'Books for Investors',
    heading: 'Books for Investors',
    phraseDisplay: 'some books for investors to read',
    metadata: {
      isCoreContent: true,
    },
    //Levels 1-4
    pages: 6,
    type: LEARN_LESSON_TYPES.text,
    content: Content.booksForInvestorsContent,
    disclaimers: Disclaimers.booksForInvestorsDisclaimers,
  },
  88: {
    id: 88,
    name: 'Share Classes - GOOG vs GOOGL',
    heading: 'Share Classes - GOOG vs GOOGL',
    phraseDisplay: 'share classes (e.g. GOOG vs GOOGL)',
    metadata: {
      isCoreContent: true,
    },
    // Levels 1-3
    pages: 7,

    type: LEARN_LESSON_TYPES.text,
    content: Content.shareClassesContent,
    disclaimers: Disclaimers.shareClassesDisclaimers,
  },
  89: {
    id: 89,
    name: 'Potential Risks of Leveraged ETFs',
    heading: 'Potential Risks of Leveraged ETFs',
    phraseDisplay: 'the potential risks of leveraged ETFs',
    metadata: {
      isCoreContent: true,
    },
    // Levels 1-4
    pages: 6,
    type: LEARN_LESSON_TYPES.text,
    content: Content.risksOfLeveragedETFsContent,
    disclaimers: Disclaimers.risksOfLeveragedETFsDisclaimers,
  },
  90: {
    id: 90,
    name: 'Inverse ETFs',
    heading: 'Inverse ETFs',
    phraseDisplay: 'Inverse ETFs',
    metadata: {
      isCoreContent: true,
    },
    // Levels 1-3
    pages: 7,
    type: LEARN_LESSON_TYPES.text,
    content: Content.inverseETFsContent,
    disclaimers: Disclaimers.inverseETFsDisclaimers,
  },
  91: {
    id: 91,
    name: 'Amazon & Competitive Landscape',
    heading: 'Amazon & Competitive Landscape',
    phraseDisplay: 'the competitive landscape of Amazon (AMZN)',
    metadata: {
      isCoreContent: true,
    },
    // Levels 1-3
    pages: 8,
    type: LEARN_LESSON_TYPES.text,
    content: Content.amazonCompetitiveLandscapeContent,
    disclaimers: Disclaimers.amazonCompetitiveLandscapeDisclaimers,
  },
  92: {
    id: 92,
    name: 'Learn about Industrials',
    heading: 'Learn about Industrials',
    phraseDisplay: '$!What is the Industrials Sector?',
    metadata: {
      isCoreContent: true,
    },
    // Levels 1-3
    pages: 8,
    type: LEARN_LESSON_TYPES.text,
    content: Content.industrialsContent,
    disclaimers: Disclaimers.industrialsDisclaimers,
  },
  93: {
    id: 93,
    name: 'Dividends and Stock Returns',
    heading: 'Dividends and Stock Returns',
    phraseDisplay: 'dividends and stock returns',
    metadata: {
      isCoreContent: true,
    },
    // Levels 1-3
    pages: 8,
    type: LEARN_LESSON_TYPES.text,
    content: Content.dividendsAndStockReturnsContent,
    disclaimers: Disclaimers.dividendsAndStockReturnsDisclaimers,
  },
  94: {
    id: 94,
    name: 'Dividends: Dynamics & Types',
    heading: 'Dividends: Dynamics & Types',
    phraseDisplay: 'the dynamics of the different dividend types',
    metadata: {
      isCoreContent: true,
    },
    // Levels 1-3
    pages: 6,
    type: LEARN_LESSON_TYPES.text,
    content: Content.dividendsDynamicsTypesContent,
    disclaimers: Disclaimers.dividendsDynamicsTypesDisclaimers,
  },
  95: {
    id: 95,
    name: 'Dividends: Important Dates',
    heading: 'Dividends: Important Dates',
    phraseDisplay: 'important dates to consider around dividend stocks',
    metadata: {
      isCoreContent: true,
    },
    // Levels 1-3
    pages: 9,
    type: LEARN_LESSON_TYPES.text,
    content: Content.dividendsImportantDatesContent,
    disclaimers: Disclaimers.dividendsImportantDatesDisclaimers,
  },
  96: {
    id: 96,
    name: 'General Electric: Overview',
    heading: 'General Electric: Overview',
    phraseDisplay: "$!Let's take a look at the General Electric (GE) stock.",
    metadata: {
      isCoreContent: true,
    },
    //Levels 1-4
    pages: 7,
    type: LEARN_LESSON_TYPES.text,
    content: Content.geOverviewContent,
    disclaimers: Disclaimers.geOverviewDisclaimers,
  },
  97: {
    id: 97,
    name: 'GE: Bull and Bear Cases',
    heading: 'GE: Bull and Bear Cases',
    phraseDisplay: "$! Let's look at the bull and bear cases for General Electric (GE).",
    metadata: {
      isCoreContent: true,
    },
    //Levels 1-4
    pages: 8,
    type: LEARN_LESSON_TYPES.text,
    content: Content.geBullAndBearContent,
    disclaimers: Disclaimers.geBullAndBearDisclaimers,
  },
  98: {
    id: 98,
    name: 'GE’s competitors',
    heading: 'GE’s competitors',
    phraseDisplay: 'GE’s competitors',
    metadata: {
      isCoreContent: true,
    },
    //Levels 1-3
    pages: 5,
    type: LEARN_LESSON_TYPES.text,
    content: Content.geCompetitorsContent,
    disclaimers: Disclaimers.geCompetitorsDisclaimers,
  },
  99: {
    id: 99,
    name: 'Stock and Market Catalysts',
    heading: 'Stock and Market Catalysts',
    phraseDisplay: '$!What are some concepts behind stock and market catalysts?',
    metadata: {
      isCoreContent: true,
    },
    //Levels 1-3
    pages: 7,
    type: LEARN_LESSON_TYPES.text,
    content: Content.stockMarketCatalystsContent,
    disclaimers: Disclaimers.stockMarketCatalystsDisclaimers,
  },
  100: {
    id: 100,
    name: 'Company Debt',
    heading: 'Company Debt',
    phraseDisplay: 'company debt',
    metadata: {
      isCoreContent: true,
    },
    //Levels 1-3
    pages: 6,
    type: LEARN_LESSON_TYPES.text,
    content: Content.companyDebtContent,
    disclaimers: Disclaimers.companyDebtDisclaimers,
  },
  101: {
    id: 101,
    name: 'Cash Flow',
    heading: 'Cash Flow',
    phraseDisplay: 'cash flow',
    metadata: {
      isCoreContent: true,
    },
    //Levels 1-2
    pages: 7,
    type: LEARN_LESSON_TYPES.text,
    content: Content.cashFlowContent,
    disclaimers: Disclaimers.cashFlowDisclaimers,
  },
  102: {
    id: 102,
    name: 'Cash Flow & Valuation',
    heading: 'Cash Flow & Valuation',
    phraseDisplay: 'cash flow and valuation',
    metadata: {
      isCoreContent: true,
    },
    //Levels 1-3
    pages: 6,
    type: LEARN_LESSON_TYPES.text,
    content: Content.cashFlowAndValuationContent,
    disclaimers: Disclaimers.cashFlowAndValuationDisclaimers,
  },
  103: {
    id: 103,
    name: 'Market Crashes',
    heading: 'Market Crashes',
    phraseDisplay: 'market crashes',
    metadata: {
      isCoreContent: true,
    },
    //Levels 1-3
    pages: 6,
    type: LEARN_LESSON_TYPES.text,
    content: Content.marketCrashesContent,
    disclaimers: Disclaimers.marketCrashesDisclaimers,
  },
  104: {
    id: 104,
    name: 'Expense Ratios',
    heading: 'Expense Ratios',
    phraseDisplay: 'expense ratios',
    metadata: {
      isCoreContent: true,
    },
    //Levels 1-3
    pages: 6,
    type: LEARN_LESSON_TYPES.text,
    content: Content.expenseRatiosContent,
    disclaimers: Disclaimers.expenseRatiosDisclaimers,
  },
  105: {
    id: 105,
    name: 'Capital Gains & Taxes',
    heading: 'Capital Gains & Taxes',
    phraseDisplay: 'capital gains and taxes',
    metadata: {
      isCoreContent: true,
    },
    //Levels 1-3
    pages: 6,
    type: LEARN_LESSON_TYPES.text,
    content: Content.capitalGainsContent,
    disclaimers: Disclaimers.capitalGainsDisclaimers,
  },
  106: {
    id: 106,
    name: 'Risk Tolerance',
    heading: 'Risk Tolerance',
    phraseDisplay: 'risk tolerance',
    metadata: {
      isCoreContent: true,
    },
    //Levels 1-2
    pages: 6,
    type: LEARN_LESSON_TYPES.text,
    content: Content.riskToleranceContent,
    disclaimers: Disclaimers.riskToleranceDisclaimers,
  },
  107: {
    id: 107,
    name: 'Sizing Stock Positions',
    heading: 'Sizing Stock Positions',
    phraseDisplay: '$!How do investors size their stock positions?',
    metadata: {
      isCoreContent: true,
    },
    //Levels 1-2
    pages: 6,
    type: LEARN_LESSON_TYPES.text,
    content: Content.sizingStockPositionsContent,
    disclaimers: Disclaimers.sizingStockPositionsDisclaimers,
  },
  // xx: {
  //   id: xx,
  //   name: '',
  //   heading: '',
  //   phraseDisplay: '',
  //   metadata: {
  //     isCoreContent: true,
  //   },
  //
  //   type: LEARN_LESSON_TYPES.text,
  //   content: Content.xContent,
  //   disclaimers: Disclaimers.xDisclaimers,
  // },
};
const depositLessonContentScreens = {
  100000: {
    id: 100000,
    name: 'Compounding Returns Lesson',

    heading: 'Investing',
    phraseDisplay: 'investing',
    type: LEARN_LESSON_TYPES.custom,
    content: null,
    disclaimers: Disclaimers.depositDisclaimers,
    componentType: CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnDeposit,
    componentData: {
      textBodyData: [
        {
          text: 'As Benjamin Franklin put it, "',
        },
        {
          text: 'Money makes money. ',
          bold: true,
        },
        {
          text: ' And the money that money makes, makes money."',
        },
      ],
    },
  },
  100001: {
    id: 100001,
    name: 'Compounding Returns Lesson',

    heading: 'Investing',
    phraseDisplay: 'investing',
    type: LEARN_LESSON_TYPES.custom,
    content: null,
    disclaimers: Disclaimers.depositDisclaimers,
    componentType: CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnDeposit,
    componentData: {
      textBodyData: [
        {
          text: 'If there was an eighth wonder of the world, what would it be?',
        },
        {
          text: 'Some say Albert Einstein believed it was this critical investing concept:',
          isNewLine: true,
        },
        {
          text: ' compound interest',
          bold: true,
        },
      ],
    },
  },
  100002: {
    id: 100002,
    name: 'Compounding Returns Lesson',

    heading: 'Investing',
    phraseDisplay: 'investing',
    type: LEARN_LESSON_TYPES.custom,
    content: null,
    disclaimers: Disclaimers.depositDisclaimers,
    componentType: CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnDeposit,
    componentData: {
      textBodyData: [
        {
          text: 'Compound interest is a key concept',
          bold: true,
        },
        {
          text: ' in investing that may not be talked about enough. Simplistically, this is the idea that ',
        },
        {
          text: 'your gains can snowball over time',
          bold: true,
        },
        {
          text: ', growing exponentially.',
        },
      ],
    },
  },

  100003: {
    id: 100003,
    name: 'Compounding Returns Lesson',

    heading: 'Investing',
    phraseDisplay: 'investing',
    type: LEARN_LESSON_TYPES.custom,
    content: null,
    disclaimers: Disclaimers.depositDisclaimers,
    metadata: {
      inLibrary: true,
      enableRecurringDeposit: true,
    },
    componentType: CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnDeposit,
    componentData: {
      textBodyData: [
        {
          text: 'As Benjamin Franklin put it, "',
        },
        {
          text: 'Money makes money. ',
          bold: true,
        },
        {
          text: ' And the money that money makes, makes money."',
        },
      ],
    },
  },
  100004: {
    id: 100004,
    name: 'Compounding Returns Lesson',

    heading: 'Investing',
    phraseDisplay: 'investing',
    type: LEARN_LESSON_TYPES.custom,
    content: null,
    disclaimers: Disclaimers.depositDisclaimers,
    metadata: {
      enableRecurringDeposit: true,
    },
    componentType: CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnDeposit,
    componentData: {
      textBodyData: [
        {
          text: 'If there was an eighth wonder of the world, what would it be?',
        },
        {
          text: 'Some say Albert Einstein believed it was this critical investing concept:',
          isNewLine: true,
        },
        {
          text: ' compound interest',
          bold: true,
        },
      ],
    },
  },
  100005: {
    id: 100005,
    name: 'Compounding Returns Lesson',

    heading: 'Investing',
    phraseDisplay: 'investing',
    type: LEARN_LESSON_TYPES.custom,
    content: null,
    disclaimers: Disclaimers.depositDisclaimers,
    metadata: {
      enableRecurringDeposit: true,
    },
    componentType: CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnDeposit,
    componentData: {
      textBodyData: [
        {
          text: 'Compound interest is a key concept',
          bold: true,
        },
        {
          text: ' in investing that may not be talked about enough. Simplistically, this is the idea that ',
        },
        {
          text: 'your gains can snowball over time',
          bold: true,
        },
        {
          text: ', growing exponentially.',
        },
      ],
    },
  },
  100006: {
    id: 100006,
    name: 'Compounding Returns Lesson',

    heading: 'Investing',
    phraseDisplay: 'investing',
    type: LEARN_LESSON_TYPES.custom,
    content: null,
    disclaimers: Disclaimers.depositDisclaimers,
    metadata: {
      enableRecurringDeposit: true,
    },
    componentType: CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnDeposit,
    componentData: {
      textBodyData: [
        {
          text: '“My wealth has come from a combination of living in America, some lucky genes, and ',
        },
        {
          text: 'compound interest',
          bold: true,
        },
        {
          text: '.”',
        },
        {
          text: ' — Warren Buffett',
          isNewLine: true,
          rightAlign: true,
        },
      ],
    },
  },
  100007: {
    id: 100007,
    name: 'Compounding Returns Lesson',

    heading: 'Investing',
    phraseDisplay: 'investing',
    type: LEARN_LESSON_TYPES.custom,
    content: null,
    disclaimers: Disclaimers.depositDisclaimers,
    metadata: {
      enableRecurringDeposit: true,
    },
    componentType: CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnDeposit,
    componentData: {
      textBodyData: [
        {
          text: '“The elementary mathematics of ',
        },
        {
          text: 'compound interest is one of the most important models there is on earth',
          bold: true,
        },
        {
          text: '.”',
        },
        {
          text: '- Charlie Munger',
          isNewLine: true,
          rightAlign: true,
        },
      ],
    },
  },
  100008: {
    id: 100008,
    name: 'Compounding Returns Lesson',

    heading: 'Investing',
    phraseDisplay: 'investing',
    type: LEARN_LESSON_TYPES.custom,
    content: null,
    disclaimers: Disclaimers.depositDisclaimers,
    metadata: {
      enableRecurringDeposit: true,
    },
    componentType: CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnDeposit,
    componentData: {
      textBodyData: [
        {
          text: 'Warren Buffett and Charlie Munger describe ',
        },
        {
          text: 'compound interest',
          bold: true,
        },
        {
          text: ' as "being at the top of a very large hill ... with ',
        },
        {
          text: 'a snowball and getting it rolling downhill',
          bold: true,
        },
        {
          text: '.”',
        },
      ],
    },
  },
  100009: {
    id: 100009,
    name: 'Compounding Returns Lesson',

    heading: 'Investing',
    phraseDisplay: 'investing',
    type: LEARN_LESSON_TYPES.custom,
    content: null,
    disclaimers: Disclaimers.depositDisclaimers,
    metadata: {
      enableRecurringDeposit: true,
    },
    componentType: CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnDeposit,
    componentData: {
      textBodyData: [
        {
          text: '"The ',
        },
        {
          text: 'effects of compounding',
          bold: true,
        },
        {
          text: ' even moderate returns over many years are compelling, if not ',
        },
        {
          text: 'downright mind-boggling',
          bold: true,
        },
        {
          text: '."',
        },
        {
          text: '- Seth Klarman',
          isNewLine: true,
          rightAlign: true,
        },
      ],
    },
  },
  100010: {
    id: 100010,
    name: 'Compounding Returns Lesson',

    heading: 'Investing',
    phraseDisplay: 'investing',
    type: LEARN_LESSON_TYPES.custom,
    content: null,
    disclaimers: Disclaimers.depositDisclaimers,
    metadata: {
      enableRecurringDeposit: true,
    },
    componentType: CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnDeposit,
    componentData: {
      textBodyData: [
        {
          text:
            "\"Good investing isn't necessarily about earning the highest returns. It's about earning pretty good returns that you can stick with for a long period of time. ",
        },
        {
          text: "That's when compounding runs wild",
          bold: true,
        },
        {
          text: '."',
        },
        {
          text: '- Morgan Housel',
          isNewLine: true,
          rightAlign: true,
        },
      ],
    },
  },
  100011: {
    id: 100011,
    name: 'Compounding Returns Lesson',

    heading: 'Investing',
    phraseDisplay: 'investing',
    type: LEARN_LESSON_TYPES.custom,
    content: null,
    disclaimers: Disclaimers.depositDisclaimers,
    metadata: {
      enableRecurringDeposit: true,
    },
    componentType: CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnDeposit,
    componentData: {
      textBodyData: [
        {
          text: '"Over a sufficiently long time, compound growth at a small rate will ',
        },
        {
          text: 'vastly exceed',
          bold: true,
        },
        {
          text: ' any rate of arithmetic growth, no matter how large!"',
        },
        {
          text: '- Ed Thorp',
          isNewLine: true,
          rightAlign: true,
        },
      ],
    },
  },
  100012: {
    id: 100012,
    name: 'Compounding Returns Lesson',
    heading: 'Investing',
    phraseDisplay: 'investing',
    type: LEARN_LESSON_TYPES.custom,
    content: null,
    disclaimers: Disclaimers.depositDisclaimers,
    metadata: {
      enableRecurringDeposit: true,
    },
    componentType: CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnDeposit,
    componentData: {
      textBodyData: [
        {
          text: '"But 200 years of continuous compounding has produced a return of more than a million times. ',
        },
        {
          text: 'Compounding truly is astonishing',
          bold: true,
        },
        {
          text: '.”',
        },
        {
          text: '- Li Lu',
          isNewLine: true,
          rightAlign: true,
        },
      ],
    },
  },
  100013: {
    id: 100013,
    name: 'Compounding Returns Lesson',

    heading: 'Investing',
    phraseDisplay: 'investing',
    type: LEARN_LESSON_TYPES.custom,
    content: null,
    disclaimers: Disclaimers.depositDisclaimers,
    metadata: {
      enableRecurringDeposit: true,
    },
    componentType: CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnDeposit,
    componentData: {
      textBodyData: [
        {
          text:
            '“Enjoy the magic of compounding returns. Even modest investments made in one’s early 20s are likely to ',
        },
        {
          text: 'grow to staggering amounts over the course of an investment lifetime',
          bold: true,
        },
        {
          text: '.”',
        },
        {
          text: '- John C. Bogle',
          isNewLine: true,
          rightAlign: true,
        },
      ],
    },
  },
  100014: {
    id: 100014,
    name: 'Compounding Returns Lesson',

    heading: 'Investing',
    phraseDisplay: 'investing',
    type: LEARN_LESSON_TYPES.custom,
    content: null,
    disclaimers: Disclaimers.depositDisclaimers,
    metadata: {
      enableRecurringDeposit: true,
    },
    componentType: CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnDeposit,
    componentData: {
      textBodyData: [
        {
          text: 'As Benjamin Franklin put it, "',
        },
        {
          text: 'Money makes money. ',
          bold: true,
        },
        {
          text: ' And the money that money makes, makes money."',
        },
      ],
    },
  },
};
const referralContentScreens = {
  100100: {
    id: 100100,
    name: 'Refer A Friend',
    heading: 'Community',
    phraseDisplay: 'community',
    type: LEARN_LESSON_TYPES.custom,
    content: null,
    disclaimers: Disclaimers.communityDisclaimers,
    metadata: {
      useDefaultPromo: true,
    },
    componentType: CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnCommunity,
  },
  100101: {
    id: 100101,
    name: 'Refer A Friend',
    heading: 'Community',
    phraseDisplay: 'community',
    type: LEARN_LESSON_TYPES.custom,
    content: null,
    disclaimers: Disclaimers.communityDisclaimers,
    metadata: {},
    componentType: CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnCommunity,
  },

  101100: {
    id: 101100,
    name: 'Refer A Friend',
    heading: 'Refer A Friend',
    phraseDisplay: 'the reward for referring a friend',
    type: LEARN_LESSON_TYPES.text,
    content: Content.referAFriendContent,
    disclaimers: Disclaimers.communityDisclaimers,
    metadata: {
      useDefaultPromo: true,
      useReferralCTA: true,
    },
  },
  101101: {
    id: 101101,
    name: 'Refer A Friend',
    heading: 'Refer A Friend',
    phraseDisplay: 'the reward for referring a friend',
    type: LEARN_LESSON_TYPES.text,
    content: Content.referAFriendContentAlt50Bonus,
    disclaimers: Disclaimers.communityDisclaimers,
    metadata: {
      useReferralCTA: true,
    },
  },
};
const partnerContentScreens = {
  300000: {
    id: 300000,
    name: 'Wagmo',
    heading: 'Pet Insurance',
    phraseDisplay: 'Pet Insurance',
    type: LEARN_LESSON_TYPES.text,
    content: Content.wagmoContent,
    disclaimers: {},
    metadata: {
      disabled: true,
      partnerName: PRODUCT_PARTNERS.wagmo,
      moreInfoOverride: {
        url:
          'https://wagmo.4qxjn9.net/c/3467601/1424950/11106?subId1=300000%253D&subId2=external_ID%253D&u=https%3A%2F%2Fwagmo.io%2Fblog%2Frising-cost-of-pet-ownership',
      },
    },
  },
  300001: {
    id: 300001,
    name: 'Lemonade',
    heading: 'Renters Insurance',
    phraseDisplay: '$!What is Renters Insurance',
    type: LEARN_LESSON_TYPES.text,
    content: Content.lemonadeContent,
    disclaimers: {},
    metadata: {
      partnerName: PRODUCT_PARTNERS.lemonade,
      moreInfoOverride: {
        hidden: true,
      },
    },
  },
  300002: {
    id: 300002,
    name: 'VSP Direct',
    heading: 'Vision Insurance',
    phraseDisplay: 'Vision Insurance',
    type: LEARN_LESSON_TYPES.text,
    content: Content.vspdirectContent,
    disclaimers: {},
    metadata: {
      partnerName: PRODUCT_PARTNERS.vspdirect,
      moreInfoOverride: {
        url: 'https://www.vspdirect.com/faq/vision-plan-use',
      },
    },
  },
  300003: {
    id: 300003,
    name: 'New Constructs',
    heading: 'Return on Invested Capital',
    phraseDisplay: 'Return on Invested Capital',
    type: LEARN_LESSON_TYPES.text,
    content: Content.roicContent,
    disclaimers: Disclaimers.newConstructsDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.newConstructs,
    },
  },
  300004: {
    id: 300004,
    name: 'Upstart',
    heading: 'Tackling Debt',
    phraseDisplay: 'Tackling Debt',
    type: LEARN_LESSON_TYPES.text,
    content: Content.upstartContent,
    disclaimers: Disclaimers.upstartDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.upstart,
      moreInfoOverride: {
        hidden: true,
      },
    },
  },
  300005: {
    id: 300005,
    name: 'Wealth Foundry - Planning',
    heading: 'Making a Plan',
    phraseDisplay: 'Making a Plan',
    type: LEARN_LESSON_TYPES.text,
    content: Content.galatMakingAPlanContent,
    disclaimers: Disclaimers.galatDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.galat,
    },
  },
  300006: {
    id: 300006,
    name: 'Wealth Foundry - Selecting Stocks',
    heading: 'Selecting Stocks For Your Plan',
    phraseDisplay: 'Selecting Stocks For Your Plan',
    type: LEARN_LESSON_TYPES.text,
    content: Content.galatSelectingStocksContent,
    disclaimers: Disclaimers.galatDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.galat,
    },
  },
  300007: {
    id: 300007,
    name: 'Bankrate',
    heading: 'Credit Card Types',
    phraseDisplay: 'credit card types',
    type: LEARN_LESSON_TYPES.text,
    content: Content.bankRateContent,
    disclaimers: Disclaimers.bankRateDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.bankrate,
    },
    pages: 7,
  },
  300008: {
    id: 300008,
    name: 'Covered - Types of Car Insurance',
    heading: 'Types of Car Insurance',
    phraseDisplay: 'all the types of car insurance',
    type: LEARN_LESSON_TYPES.text,
    content: Content.typesOfCarInsuranceStocksContent,
    disclaimers: Disclaimers.coveredDisclaimers,
    pages: 9,
    metadata: {
      partnerName: PRODUCT_PARTNERS.covered,
      moreInfoOverride: {
        url: 'https://www.iii.org/fact-statistic/facts-statistics-uninsured-motorists ',
      },
    },
  },
  300009: {
    id: 300009,
    name: 'Covered - How much car insurance you need',
    heading: 'How Much Car Insurance You Need',
    phraseDisplay: 'how much car insurance you need',
    type: LEARN_LESSON_TYPES.text,
    content: Content.howMuchCarInsuranceNeededContent,
    disclaimers: Disclaimers.coveredDisclaimers,
    pages: 9,
    metadata: {
      partnerName: PRODUCT_PARTNERS.covered,
      moreInfoOverride: {
        url: 'https://www.iii.org/fact-statistic/facts-statistics-uninsured-motorists ',
      },
    },
  },
  300010: {
    id: 300010,
    name: 'Ladder Term Life Insurance',
    heading: 'Term Life Insurance',
    phraseDisplay: 'term life insurance',
    type: LEARN_LESSON_TYPES.text,
    content: Content.ladderLifeInsuranceGenericWithOutro,
    disclaimers: Disclaimers.lifeInsuranceDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.ladder,
    },
  },
  300011: {
    id: 300011,
    name: 'Bestow Term Life Insurance',
    heading: 'Term Life Insurance',
    phraseDisplay: 'term life insurance',
    type: LEARN_LESSON_TYPES.text,
    content: Content.bestowLifeInsuranceContent,
    disclaimers: Disclaimers.bestowLifeInsuranceDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.bestow,
    },
  },
  300012: {
    id: 300012,
    name: 'Ladder Additional Life Insurance Coverage',
    heading: 'Additional Life Insurance Coverage',
    phraseDisplay: 'additional life insurance coverage',
    type: LEARN_LESSON_TYPES.text,
    content: Content.additionalLifeInsuranceCoverage,
    disclaimers: Disclaimers.additionalLifeInsuranceCoverage,
    metadata: {
      partnerName: PRODUCT_PARTNERS.ladder,
    },
  },
  300013: {
    id: 300013,
    name: 'Personalized Money Management',
    heading: 'Personalized Money Management',
    phraseDisplay: 'personalized money management',
    type: LEARN_LESSON_TYPES.text,
    content: Content.youNeedABudgetContent,
    disclaimers: Disclaimers.youNeedABudgetDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.youNeedABudget,
    },
  },
  300014: {
    id: 300014,
    name: 'Accredited Debt Relief',
    heading: 'Consolidating Debt',
    phraseDisplay: 'consolidating debt',
    type: LEARN_LESSON_TYPES.text,
    page: 6,
    content: Content.accreditedDebtReliefContent,
    disclaimers: Disclaimers.accreditedDebtReliefDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.accreditedDebtRelief,
    },
  },
  300015: {
    id: 300015,
    name: 'Morning Brew',
    heading: 'Tracking Business News',
    phraseDisplay: 'tracking business news',
    type: LEARN_LESSON_TYPES.text,
    page: 7,
    content: Content.morningBrewContent,
    disclaimers: Disclaimers.morningBrewDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.morningBrew,
    },
  },
  300016: {
    id: 300016,
    name: 'edX',
    page: 6,
    heading: 'Expanding Your Knowledge',
    phraseDisplay: 'expanding your knowledge',
    type: LEARN_LESSON_TYPES.text,
    content: Content.edXContent,
    disclaimers: Disclaimers.edXDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.edX,
    },
  },
  300017: {
    id: 300017,
    name: 'Trust & Will',
    page: 7,
    heading: 'Estate Planning',
    phraseDisplay: 'estate planning',
    type: LEARN_LESSON_TYPES.text,
    content: Content.trustAndWillContent,
    disclaimers: Disclaimers.trustAndWillDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.trustAndWill,
    },
  },
  300018: {
    id: 300018,
    name: 'Benzinga Pro',
    page: 5,
    heading: 'Benzinga Pro',
    phraseDisplay: '$!Learn about combining financial news and trading ideas.',
    type: LEARN_LESSON_TYPES.text,
    content: Content.benzingaContent,
    disclaimers: Disclaimers.benzingaProDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.benzinga,
    },
  },
  300019: {
    id: 300019,
    name: 'Arro',
    page: 8,
    heading: 'Building Credit',
    phraseDisplay: '$!Learn about building credit.',
    type: LEARN_LESSON_TYPES.text,
    content: Content.arroContent,
    disclaimers: Disclaimers.arroDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.arro,
    },
  },
};

const ladderContentScreens = {
  200000: {
    id: 200000,
    name: 'Term Life Insurance',
    heading: 'Term Life Insurance',
    phraseDisplay: 'term life insurance',
    type: LEARN_LESSON_TYPES.text,
    content: Content.ladderLifeInsuranceMillenialsWithOutro,
    disclaimers: Disclaimers.lifeInsuranceDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.ladder,
    },
  },
  200001: {
    id: 200001,
    name: 'Term Life Insurance',
    heading: 'Term Life Insurance',
    phraseDisplay: 'term life insurance',
    type: LEARN_LESSON_TYPES.text,
    content: Content.ladderLifeInsuranceMillenials,
    disclaimers: Disclaimers.lifeInsuranceDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.ladder,
      inShowPartnerCalloutTestGroup: true,
      disabled: true,
    },
  },
  200002: {
    id: 200002,
    name: 'Term Life Insurance',
    heading: 'Term Life Insurance',
    phraseDisplay: 'term life insurance',
    type: LEARN_LESSON_TYPES.text,
    content: Content.ladderLifeInsuranceGenericWithOutro,
    disclaimers: Disclaimers.lifeInsuranceDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.ladder,
    },
  },
  200003: {
    id: 200003,
    name: 'Term Life Insurance',
    heading: 'Term Life Insurance',
    phraseDisplay: 'term life insurance',
    type: LEARN_LESSON_TYPES.text,
    content: Content.ladderLifeInsuranceGeneric,
    disclaimers: Disclaimers.lifeInsuranceDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.ladder,
      inShowPartnerCalloutTestGroup: true,
      disabled: true,
    },
  },
  200004: {
    id: 200004,
    name: 'Term Life Insurance',
    heading: 'Term Life Insurance',
    phraseDisplay: 'term life insurance',
    type: LEARN_LESSON_TYPES.text,
    content: Content.ladderLifeInsuranceMillenialsWithQuoteTool,
    disclaimers: Disclaimers.lifeInsuranceDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.ladder,
      appOnly: true,
      usingLadderQuoteTool: true,
    },
  },
  200005: {
    id: 200005,
    name: 'Term Life Insurance',
    heading: 'Term Life Insurance',
    phraseDisplay: 'term life insurance',
    type: LEARN_LESSON_TYPES.text,
    content: Content.ladderLifeInsuranceGenericWithQuoteTool,
    disclaimers: Disclaimers.lifeInsuranceDisclaimers,
    metadata: {
      partnerName: PRODUCT_PARTNERS.ladder,
      appOnly: true,
      usingLadderQuoteTool: true,
    },
  },
};

export const localLessons = {
  ...depositLessonContentScreens,
  ...referralContentScreens,
  ...partnerContentScreens,
  ...ladderContentScreens,
};

export const LEARN_SCREENS = {
  ...coreContentScreens,
  ...depositLessonContentScreens,
  ...referralContentScreens,
  ...partnerContentScreens,
  ...ladderContentScreens,
};

const lessonNameLookup = {};
const screensList = Object.values(LEARN_SCREENS);
screensList.forEach((s) => {
  const { id, name } = s;
  lessonNameLookup[slugifyLessonName(name)] = id;
});

export const LEARN_LESSON_NAME_LOOKUP = lessonNameLookup;

const _debugPrintPhrases = () => {
  const phrases = Object.values(LEARN_SCREENS).map((l) => l.phraseDisplay);
  const messages = phrases.map((p) => buildLearnLessonPhraseMessage(p));
  console.log(messages);
};
