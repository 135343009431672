import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { getRewardsData, logMetricsTrackingEvent } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import RewardRedemptionContainer from '../../../containers/Rewards/RewardRedemptionContainer';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;

export const RedeemRewards = ({ onContinue, onboardingState }) => {
  const dispatch = useDispatch();

  const [isReady, setIsReady] = React.useState(false);

  const handleContinueClick = React.useCallback(async () => {
    return onContinue(null, onboardingState);
  }, []);

  React.useEffect(() => {
    const setup = async () => {
      const { data, ok } = await getRewardsData()(dispatch);
      setIsReady(true);
    };

    logMetricsTrackingEvent('View Live Account Application Submitted Form')();

    setup();
  }, []);

  if (!isReady) return <LoadingPage message={'Checking for rewards'} />;

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.redeemRewards}>
      <Page width={'600px'}>
        <ContentWrapper>
          <RewardRedemptionContainer handleContinue={handleContinueClick} inPage />
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
