import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  & > svg {
    width: 18px;
    height: 18px;
  }
  & g,
  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function PieCharteIcon() {
  return (
    <Wrapper>
      <svg viewBox="0 0 18 18" fill="none">
        <path
          d="M9 .667A8.336 8.336 0 00.667 9c0 4.6 3.733 8.333 8.333 8.333S17.334 13.6 17.334 9 13.6.667 9 .667zm6.609 7.5H9.834V2.392a6.669 6.669 0 015.775 5.775zM2.334 9c0-3.392 2.55-6.2 5.833-6.608v13.216C4.884 15.2 2.334 12.392 2.334 9zm7.5 6.608V9.833h5.775a6.668 6.668 0 01-5.775 5.775z"
          fill="#1F1F1F"
        />
      </svg>
    </Wrapper>
  );
}

export default PieCharteIcon;
