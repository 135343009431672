import React from 'react';

import UserContainer from '../users/UserContainer';
import ProfileAvatar from '../../components/user/ProfileAvatar';
import UserName from '../../components/user/UserName';
import { TextWrapper } from './TextWrapper';
import { AvatarPositionContainer } from '../../components/user/AvatarPositionContainer';

import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import { Wrapper } from './Wrapper';

export const InlineUserList = (props) => {
  const {
    key, // string -> unique descriptor of list to prevent it from conflicting with other lists
    userIds, // array of userIds
    additionalOthers, // int -> will add this to the others count

    beAnonymous, // bool -> does not use user names if true
    pluralAnonymousMessage, // string -> used to customize anonymous message
    singularAnonymousMessage, // string -> used to customize anonymous message

    withAvatar, // bool
    avatarProps, // custom props to put on <ProfileAvatar/>
    userNameProps, // custom props to put on <UserName/>

    listLengthLimit, // will limit how many names are displayed and display x others, if undefined or null will not limit display
    includePeriod, // will place a period after rendering users

    handleOtherClick, // used to attach an event listener to 'x others'

    returnUserSpecificProps, // func -> (userId) => used to add user specific props to ProfileAvatar and UserName

    profileLinkQuery,
    suffix,
  } = props;

  const defaultPluralAnonymousMessage = 'Someone';
  const defaultSingularAnonymousMessage = 'People';

  const isSuffix = !isUndefinedOrNull(suffix);
  const isListLimited = !isUndefinedOrNull(listLengthLimit);
  const listLength = userIds.length;

  const usersToDisplayCount =
    listLength !== 0 && isListLimited && listLength >= listLengthLimit
      ? listLength === listLengthLimit + 1
        ? additionalOthers || 0 > 0
          ? listLengthLimit
          : listLengthLimit + 1
        : listLengthLimit
      : listLength; // avoids showing 1 others and will just show name, ie. limit 3 will show 2 names if list is 3 or less

  const userIdsToDisplayList = userIds.slice(0, usersToDisplayCount);
  const countOfOthers = listLength - usersToDisplayCount + (additionalOthers || 0);
  const totalCount = (usersToDisplayCount || 0) + (countOfOthers || 0);

  const allAvatarProps = {
    ...(avatarProps || {}),
  };
  const allUserNameProps = {
    ...(userNameProps || {}),
  };

  const _handleOtherClick = () => {
    if (props.handleOtherClick) {
      props.handleOtherClick();
    }
  };

  const _renderOthers = () => (
    <span onClick={_handleOtherClick}>
      {`and ${countOfOthers} other${countOfOthers === 1 ? '' : 's'}${includePeriod ? '.' : ''}`}
    </span>
  );

  const _renderUser = (userId, conjunction, punctuation) => (
    <span
      className={`inline-user-list-item-container ${withAvatar ? 'with-avatars' : 'without-avatars'} border-accent`}
    >
      <UserContainer userId={userId}>
        {withAvatar && (
          <AvatarPositionContainer>
            <ProfileAvatar
              profileLinkQuery={profileLinkQuery}
              {...allAvatarProps}
              {...(returnUserSpecificProps ? returnUserSpecificProps(userId) : {})}
            />
          </AvatarPositionContainer>
        )}
        {conjunction ? (
          <Wrapper
            elements={[
              <UserName
                profileLinkQuery={profileLinkQuery}
                {...allUserNameProps}
                {...(returnUserSpecificProps ? returnUserSpecificProps(userId) : {})}
              />,
              conjunction,
            ]}
          ></Wrapper>
        ) : (
          <UserName
            {...allUserNameProps}
            {...(returnUserSpecificProps ? returnUserSpecificProps(userId) : {})}
            punctuation={punctuation}
          />
        )}
      </UserContainer>
    </span>
  );

  // conjunction and punctuation are separated so that username will prevent a break around a suffixed punctuation but allow it when using 'and'
  const _renderConjunction = (i) => {
    if (i === usersToDisplayCount - 2 && countOfOthers === 0) {
      return <TextWrapper className="inline-conjunction">and</TextWrapper>;
    }
    return null;
  };

  const _renderPunctuation = (i) => {
    if (listLength === 1) {
      return null;
    }
    if (i === usersToDisplayCount && countOfOthers > 0) {
      // in current logic case is not rendering punctuation
      return null;
    }
    if (i === usersToDisplayCount - 1 && countOfOthers === 0) {
      return includePeriod && <TextWrapper className="inline-punctuation">.</TextWrapper>;
    }
    return <TextWrapper className="inline-punctuation">,</TextWrapper>;
  };

  const _renderUsersToDisplay = () => (
    <span className={``}>
      {userIdsToDisplayList.map((userId, i) => (
        <span key={`${key || 'inline-user-list'}-user-${userId}`} style={{ display: 'inline' }}>
          {_renderUser(userId, _renderConjunction(i), _renderPunctuation(i))}
        </span>
      ))}
    </span>
  );

  /***********************************/
  /***** Component render logic ******/
  /***********************************/

  if (listLength === 0 && countOfOthers === 0) {
    return null;
  }

  if (beAnonymous) {
    return (
      <span className={`inline-user-list-container`}>
        {totalCount === 1
          ? singularAnonymousMessage || defaultSingularAnonymousMessage
          : pluralAnonymousMessage || defaultPluralAnonymousMessage}
        {isSuffix && suffix}
      </span>
    );
  }

  if (listLength === 0 && countOfOthers > 0) {
    return (
      <span className={`inline-user-list-container`}>
        {countOfOthers === 1 ? 'Someone' : `${countOfOthers} people`}
        {isSuffix && suffix}
      </span>
    );
  }

  return (
    <span className={`inline-user-list-container`}>
      {_renderUsersToDisplay()}
      {countOfOthers > 0 && _renderOthers()}
      {isSuffix && suffix}
    </span>
  );
};
