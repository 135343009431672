import React from 'react';
import Button from '../buttons/Button';
import { FlatButton } from '../../main/components/buttons';

const LargeTextField = (props) => {
  const name = props.name;
  const label = props.label;
  const value = props.getValue(name) || '';

  const customStyle = props.style || {};

  const errorAction = props.errorAction;

  const isError = !!props.getErrorMessage(name);
  const errorMessage = isError ? props.getErrorMessage(name) : '';

  const isFocused = props.isFocused(name);
  const focusedOrValue = (!!value && value.length > 0) || value >= 0 || value <= 0 || isFocused;

  const showFocusNote = !!props.focusNote && isFocused;
  const focusNote = props.getFocusNote(name);

  const customClass = props.customClass;

  const prefix = props.prefix;
  const suffix = props.suffix;
  const showErrors = !props.hideErrors;
  const silentErrorCheck = props.silentErrorCheck(name, value);

  const handleChange = (e) => {
    props[props.handleChangeFunc](e, name, null, props.inputWarningProps, props.validateAfterChange);
  };

  const refocusElement = () => {
    $("[name*='" + name + "']")[0].focus();
  };

  const handleFocus = () => {
    props.handleFocus(name);

    props.createWarningsForInput(name, props);

    if ($("[name*='" + name + "']")[0] && $("[name*='" + name + "']")[0].classList.contains('ignore-input-warnings')) {
      $("[name*='" + name + "']")[0].classList.remove('ignore-input-warnings');
    }
  };

  const handleBlur = (p, e) => {
    if (props.blockingErrors && (isError || silentErrorCheck)) {
      props.manualValidation(name, value);
      refocusElement();
      return false;
    }

    if (p && p.nativeEvent.relatedTarget && p.nativeEvent.relatedTarget.id === 'input-warning-dismiss-button') {
      return false;
    }

    if (props.saveOnChange) {
      const data = {
        [`${props.objectType}_id`]: props.getObjectId(props.objectType),
        [name]: value,
      };
      props.saveOnChange(data);
    }

    props.handleBlur(value, name);

    if ('additionalBlurAction' in props) {
      props.additionalBlurAction();
    }
  };

  return (
    <div className={`${customClass || 'generic-input'}`}>
      <fieldset className={`${props.size === 'small' ? 'field-small' : ''}`}>
        {showFocusNote && <div className="focus-note eye-catching-bg">{focusNote}</div>}

        <div className="parent-wrapper">
          <textarea
            type="text"
            name={name}
            autoFocus={props.autoFocus}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={props.placeholder || ''}
            value={value}
          />
          {focusedOrValue && <div className="input-prefix">{prefix}</div>}
          {focusedOrValue && <div className="input-suffix">{suffix}</div>}
        </div>

        {label && (
          <label htmlFor={label} className={focusedOrValue ? 'text-field-lifted-label' : ''}>
            {label}
          </label>
        )}

        {props.showUnderline && <div className={`after ${isError && !isFocused ? 'error-underline' : ''}`}></div>}

        {showErrors &&
          (props.popOutErrorMessage ? (
            isError ? (
              <div className="generic-input-error-container">
                <div className="generic-input-error-arrow"></div>
                <div className="generic-input-error-content">{errorMessage}</div>
                {errorAction && (
                  <div className="generic-input-error-action-container">
                    <FlatButton id={props.errorAction.id} onClick={props.errorAction.handleClick}>
                      {props.errorAction.text}
                    </FlatButton>
                  </div>
                )}
              </div>
            ) : (
              false
            )
          ) : (
            <p className={`text-field-error-message`}>{errorMessage}</p>
          ))}
      </fieldset>
    </div>
  );
};

export default LargeTextField;
