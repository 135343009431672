import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {
  DEEP_LINK_DOMAIN,
  GRADIENT_COLORS,
  PRODUCT_DISPLAY_FULL_NAME,
  PRODUCT_DISPLAY_NAME,
  PRODUCT_NAME,
} from '@src/appConfig';
import { useCurrentUser } from '../../hooks/user';
import {
  returnCurrentUserThemeTestGroups,
  currentUserMembershipMonthlyFeeAmount,
  currentUserMembershipWaiveFeeMinEquity,
} from '@src/helpers/currentUserHelpers';
import { returnPathTo } from '@src/constants/paths';
import { createBasicErrorModalWithCloseButtonAction } from '@src/constants/modals';
import { sendFacebookTrackingEvent, sendFacebookLTVEvent } from '@src/constants/facebookTracking';
import { hasSentLiveTradingStartLTVEvent, setHasSentLiveTradingStartLTVEvent } from '@src/helpers/liveTradingHelpers';
import { setItemToStorage, STORAGE_KEYS } from '@src/utils/storage';
import { TrackIteratively } from '@src/utils/itly';
import { Page } from '../../components/layout';
import { FlatButton } from '../../components/buttons';
import { H5, Body5 } from '../../lib/nvstr-common-ui.es';
import { ACCOUNT_TYPES, MIN_REWARD_SIGN_UP } from '../../constants';
import LinkTo from '../../../containers/Links/LinkTo';
import Space from '../../../components/UI/Space';
import { hideModal, logMetricsTrackingEvent, showModal } from '@src/actions';
import { LocationContext } from '@src/context';
import { TRACKING_ROUTE_NAMES, enums, colorPalette, isMobileDevice } from '../../lib/nvstr-utils.es';
import { PremiumAccountFeaturesList } from '@src/main/components/onboarding/PremiumAccountFeatures';
import { Container } from '@src/main/components/ui';

const PageWrapper = styled.div`
  margin: 0 auto;
  max-width: 460px;
`;
const HeadingWrapper = styled.div`
  h3 {
    margin: 0;
  }
`;
const SubHeadingWrapper = styled.div`
  padding-top: 16px;
`;
const FeaturesWrapper = styled.div`
  padding-top: 24px;
`;
const ActionsWrapper = styled.div`
  padding-top: 40px;
  text-align: center;
  * {
    max-width: 460px !important;
    margin: 0 auto;
  }
`;
const LiveAccountActionWrapper = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: center;
`;
const PaperAccountActionWrapper = styled.div`
  padding-top: 8px;
  display: flex;
  justify-content: center;
`;
const GradientBannerWrapper = styled.div`
  background: ${({ colors }) => `linear-gradient(to right, ${colors[0]}, ${colors[1]})`};
  width: 100%;
  border-radius: 5px;

  * {
    color: ${({ theme }) => colorPalette.primary.charcoal} !important;
  }
`;

function generateHeading(headingTestGroup) {
  switch (headingTestGroup) {
    case 0:
      return 'Open Brokerage Account';
    case 1:
      return 'Enable Live Trading';
    case 2:
      return 'Activate Live Trading';
    case 3:
      return 'Activate in-app trading';
    case 4:
      return 'Enable trading in app';
    default:
      return 'Open Brokerage Account';
  }
}

function generateCTAButtonText(headingTestGroup) {
  switch (headingTestGroup) {
    case 0:
      return 'Continue';
    case 1:
      return 'Enable';
    case 2:
      return 'Activate';
    case 3:
      return 'Activate';
    case 4:
      return 'Enable';
    default:
      return 'Continue';
  }
}

function generateSimulatedAccountButtonText(onboardingWelcomeCopyTestGroup) {
  switch (onboardingWelcomeCopyTestGroup) {
    case 5:
      return 'Not Now';
    case 6:
      return 'Not Now';
    case 7:
      return 'Not Now';
    default:
      return 'Open a free simulated account';
  }
}

const useOpenMobileApp = () => {
  React.useEffect(() => {
    let potentiallyOpenedMobileApp = false;

    const onMobileWindowRefocus = () => {
      if (potentiallyOpenedMobileApp) {
        potentiallyOpenedMobileApp = false;
        setTimeout(() => {
          window.location.reload();
        }, 50);
      }
    };

    const attemptToOpenInMobileApp = () => {
      if (window.$(window).width() < 700 || isMobileDevice()) {
        try {
          // add listener to detect blur which means success
          addListeners();

          // this will fail silently if not able to find nvstr mobile app
          setTimeout(() => {
            window.location.href = `${DEEP_LINK_DOMAIN}://`;
          }, 600);
        } catch (error) {
          console.error(error);
        }
      }
    };

    const onMobileOpenPotentialSuccess = () => {
      potentiallyOpenedMobileApp = true;
    };

    const addListeners = () => {
      // eslint-disable-next-line no-undef
      const $window = window.$(window);
      $window.on('blur', onMobileOpenPotentialSuccess);
      $window.on('focus', onMobileWindowRefocus);
    };

    const removeListeners = () => {
      // eslint-disable-next-line no-undef
      const $window = window.$(window);
      $window.off('blur', onMobileOpenPotentialSuccess);
      $window.off('focus', onMobileWindowRefocus);
    };

    attemptToOpenInMobileApp();
    return removeListeners();
  }, []);

  return null;
};

const GradientBanner = ({ Text, short }) => {
  const colors = GRADIENT_COLORS;
  const height = short ? 66 : 80;
  return (
    <GradientBannerWrapper colors={colors} style={{ height: height + 'px' }}>
      <Container all={8} verticallyCenter height={height}>
        {Text}
      </Container>
    </GradientBannerWrapper>
  );
};

const FundingBonusBanner = () => {
  const currentUser = useCurrentUser();
  const isShortMessage = currentUser.short_funding_bonus_message_test_group === 1;

  const TextComponent = isShortMessage ? (
    <Body5>
      <Body5 bold>Get up to $1,000 </Body5>
      <Body5 bold>when you make your first&nbsp;deposit.</Body5>
    </Body5>
  ) : (
    <Body5>
      <Body5>For a limited time, </Body5>
      <Body5 bold>get up to $1,000 </Body5>
      <Body5>when you make your first deposit.</Body5>
    </Body5>
  );
  return <GradientBanner Text={TextComponent} short={isShortMessage} />;
};

export const SelectAccountType = ({ onPathChange, onboardingState }) => {
  const dispatch = useDispatch();

  const currentUser = useCurrentUser();
  const isStudent = currentUser.is_student;
  const onboarding_welcome_copy_test_group = currentUser.onboarding_welcome_copy_test_group || 0;
  const inBrokerageAccountOptInTestGroup = onboarding_welcome_copy_test_group >= 5;
  const headingTestGroup = currentUser.open_brokerage_account_heading_test_group;

  const membershipFee = currentUserMembershipMonthlyFeeAmount(currentUser);
  const waivedAccountSize = currentUserMembershipWaiveFeeMinEquity(currentUser);
  const hasWaiverForAccountSize = waivedAccountSize !== null;
  const waiverForAccountSizeMessageInsert = hasWaiverForAccountSize
    ? `waived for accounts with $${waivedAccountSize / 1000}K+ `
    : null;

  useOpenMobileApp();

  React.useEffect(() => {
    const event = 'View Onboarding';
    const properties = {
      Context: 'Intro Screen',
    };
    logMetricsTrackingEvent(event, properties)();
  }, []);

  const showModalForSkippingReward = React.useCallback(() => {
    const modalMessage = (
      <span>
        <span>
          {`Just a reminder, simulated trading accounts aren't eligible for the current reward offer ( $${MIN_REWARD_SIGN_UP} to $1,000 free cash to invest when you make a deposit -`}
        </span>
        <LinkTo
          to={returnPathTo('rewards')}
          className="link-btn"
          additionalClickAction={() => {
            hideModal()(dispatch);
          }}
          text={'details here'}
          styling={{ padding: '0px 6px' }}
          scrollToTop
        />
        <span>{').'}</span>
      </span>
    );
    const modal = {
      contentComponent: createBasicErrorModalWithCloseButtonAction(modalMessage, () => hideModal()(dispatch)),
      dismissable: true,
      size: 'wide',
    };
    showModal(modal)(dispatch);
  }, []);

  const handleLiveAccountClick = React.useCallback(() => {
    const event = 'Chose Live Trading Onboarding Path';
    logMetricsTrackingEvent(event)();

    sendFacebookTrackingEvent('Selected Live Account', {
      themeTestGroups: returnCurrentUserThemeTestGroups(currentUser),
    });

    TrackIteratively.account.ACCOUNT_TYPE_SELECTED.send({
      account_type: enums.account_types.live,
      url: window.location.pathname,
      url_query: window.location.search,
    });

    if (!hasSentLiveTradingStartLTVEvent()) {
      setHasSentLiveTradingStartLTVEvent();
      sendFacebookLTVEvent(1);
    }

    onPathChange(null, onboardingState, { accountType: ACCOUNT_TYPES.live });
  }, []);

  const handlePaperAccountClick = React.useCallback(() => {
    setItemToStorage(STORAGE_KEYS.submittedApexAccount, false); // resets this item in storage in case user makes second account

    const event = 'Chose Paper Trading Onboarding Path';
    logMetricsTrackingEvent(event)();

    sendFacebookTrackingEvent('Selected Paper Account', {
      themeTestGroups: returnCurrentUserThemeTestGroups(currentUser),
    });

    TrackIteratively.account.ACCOUNT_TYPE_SELECTED.send({
      account_type: enums.account_types.simulated,
      url: window.location.pathname,
      url_query: window.location.search,
    });

    if (currentUser.eligible_for_sign_up_cash_reward) {
      showModalForSkippingReward();
    }

    onPathChange(null, onboardingState, { accountType: ACCOUNT_TYPES.paper });
  }, []);

  if (inBrokerageAccountOptInTestGroup) {
    return (
      <LocationContext.Provider value={TRACKING_ROUTE_NAMES.accountTypeSelection}>
        <Page width={'540px'}>
          <PageWrapper>
            <HeadingWrapper>
              <H5>{generateHeading(headingTestGroup)}</H5>
            </HeadingWrapper>

            <Container top={18}>
              <FundingBonusBanner />
            </Container>
            <SubHeadingWrapper>
              <Body5 bold>
                {membershipFee
                  ? `Your ${PRODUCT_DISPLAY_FULL_NAME} brokerage account (${membershipFee.toFixed(2)}/mo) includes`
                  : `Your ${PRODUCT_DISPLAY_FULL_NAME} brokerage account includes`}
              </Body5>
            </SubHeadingWrapper>

            <FeaturesWrapper>
              <PremiumAccountFeaturesList isLegacy={onboarding_welcome_copy_test_group < 5} />
            </FeaturesWrapper>

            <ActionsWrapper>
              {isStudent && (
                <Container left={4}>
                  <Body5>
                    *Premium membership fee waived for active students
                    <Space />
                    <a href={'/faq?id=94'} target={'_blank'}>
                      See here for current promotion details.
                    </a>
                  </Body5>
                </Container>
              )}
              {waiverForAccountSizeMessageInsert && (
                <Container left={4}>
                  <Body5>*Premium membership fee {waiverForAccountSizeMessageInsert}</Body5>
                </Container>
              )}
              <LiveAccountActionWrapper>
                <FlatButton fullWidth onClick={handleLiveAccountClick}>
                  {generateCTAButtonText(headingTestGroup)}
                </FlatButton>
              </LiveAccountActionWrapper>
              <PaperAccountActionWrapper>
                <FlatButton transparent onClick={handlePaperAccountClick}>
                  Not now
                </FlatButton>
              </PaperAccountActionWrapper>
            </ActionsWrapper>
          </PageWrapper>
        </Page>
      </LocationContext.Provider>
    );
  }

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.accountTypeSelection}>
      <Page width={'540px'}>
        <PageWrapper>
          <HeadingWrapper>
            <H5>{`Welcome to ${PRODUCT_DISPLAY_NAME}`}</H5>
          </HeadingWrapper>
          <Container top={16}>
            <FundingBonusBanner />
          </Container>
          <SubHeadingWrapper>
            <Body5>You are just moments away from...</Body5>
          </SubHeadingWrapper>

          <FeaturesWrapper>
            <PremiumAccountFeaturesList isLegacy={onboarding_welcome_copy_test_group < 5} />
          </FeaturesWrapper>

          <ActionsWrapper>
            {isStudent && (
              <Container left={4}>
                <Body5>
                  *Premium membership fee waived for active students
                  <Space />
                  <a href={'/faq?id=94'} target={'_blank'}>
                    See here for current promotion details.
                  </a>
                </Body5>
              </Container>
            )}
            {waiverForAccountSizeMessageInsert && (
              <Container left={4}>
                <Body5>*Premium membership fee {waiverForAccountSizeMessageInsert}</Body5>
              </Container>
            )}
            <LiveAccountActionWrapper>
              <FlatButton fullWidth onClick={handleLiveAccountClick}>
                Continue
              </FlatButton>
            </LiveAccountActionWrapper>
            <PaperAccountActionWrapper>
              <FlatButton transparent onClick={handlePaperAccountClick}>
                Open A FREE SIMULATED ACCOUNT
              </FlatButton>
            </PaperAccountActionWrapper>
          </ActionsWrapper>
        </PageWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
