import React from 'react';
import { connect } from 'react-redux';
import { showModal, hideModal, logMetricsTrackingEvent, closeAccount } from '../../actions';
import CloseAccountInformation from './CloseAccountInformation';
import LoadingIcon from '../../components/misc/LoadingIcon';
import Space from '../../components/UI/Space';
import Icon from '../../components/misc/Icon';
import ContactSupportMessage from '../../components/UI/ContactSupportMessage';

class CloseAccount extends React.PureComponent {
  render() {
    const { children } = this.props;
    return children(this.onCloseAccountStart);
  }

  onCloseAccountStart = () => {
    this._logCloseAccountStartClick();

    const Component = <CloseAccountInformation onContinue={this.onCloseAccount} onCancel={this.onCancel} />;
    const modal = {
      contentComponent: Component,
      dismissable: true,
      size: 'wide',
    };
    showModal(modal)(this.props.dispatch);
  };

  onCloseAccount = () => {
    this._logContinueCloseAccount();
    this._showProcessingClose();
    this._sendCloseAccountRequest();
  };

  onCancel = () => {
    this._logCancelCloseAccount();
    hideModal()(this.props.dispatch);
  };

  _sendCloseAccountRequest = () => {
    closeAccount()(this.props.dispatch).then((response) => this._handleCloseAccountRequestResponse(response));
  };

  _handleCloseAccountRequestResponse = (response) => {
    hideModal()(this.props.dispatch);

    if (response.ok) {
      this._showCloseSuccess();
      this._logCloseAccountSuccess();
    } else {
      this._showCloseFailure(response);
      this._logCloseAccountFailure();
    }
  };

  _showProcessingClose = () => {
    const Component = (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <span>
          <LoadingIcon />
        </span>
        <Space />
        <Space />
        <Space />
        <span>Processing</span>
      </div>
    );
    const modal = {
      contentComponent: Component,
      dismissable: false,
    };
    showModal(modal)(this.props.dispatch);
  };

  _triggerAfterCloseSuccessReload = () => {
    window.location = '/';
  };

  _showCloseSuccess = () => {
    const Component = (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <div className="flex-center-child">
          <Icon colorClassName={'success-text-color'} icon={'fa-check-circle'} size={'xlarge'} />
          <Space />
          <Space />
          <span
            style={{
              fontSize: 18,
            }}
          >
            Success
          </span>
        </div>
        <p
          style={{
            paddingTop: '15px',
          }}
        >
          The page will reload shortly and reflect the changes. If the page does
          <Space />
          not
          <Space />
          reload
          <Space />
          click
          <Space />
          <a onClick={this._triggerAfterCloseSuccessReload}>here</a>
        </p>
      </div>
    );
    const modal = {
      contentComponent: Component,
      dismissable: true,
      size: 'wide',
    };
    showModal(modal)(this.props.dispatch);

    setTimeout(() => this._triggerAfterCloseSuccessReload(), 5000);
  };

  _showCloseFailure = (response) => {
    const ErrorBodyComponent = response.error ? (
      <div
        style={{
          paddingTop: '10px',
        }}
      >
        <p
          style={{
            textAlign: 'center',
          }}
        >
          {response.error}
        </p>
      </div>
    ) : (
      <div>
        <p>Please complete the steps below before closing your account.</p>
        {response.actions.map((action, i) => (
          <p key={i}>{`${i + 1}. ${action}`}</p>
        ))}
      </div>
    );

    const Component = (
      <div>
        <p
          style={{
            fontSize: '18px',
            textAlign: 'center',
          }}
        >
          Unable to close account
        </p>
        {ErrorBodyComponent}
        <div style={{ textAlign: 'center', paddingTop: '10px' }}>
          <ContactSupportMessage />
        </div>
      </div>
    );
    const modal = {
      contentComponent: Component,
      dismissable: true,
      size: 'wide',
    };
    showModal(modal)(this.props.dispatch);
  };

  /**********
    LOGS
  ***********/

  _logCloseAccountStartClick = () => {
    const event = 'Clicked Close Account';
    const properties = {};
    logMetricsTrackingEvent(event, properties)();
  };

  _logContinueCloseAccount = () => {
    const event = 'Submit Close Account Request';
    const properties = {};
    logMetricsTrackingEvent(event, properties)();
  };

  _logCloseAccountSuccess = () => {
    const event = 'Close Account Success';
    const properties = {};
    logMetricsTrackingEvent(event, properties)();
  };

  _logCloseAccountFailure = () => {
    const event = 'Close Account Failed';
    const properties = {};
    logMetricsTrackingEvent(event, properties)();
  };

  _logCancelCloseAccount = () => {
    const event = 'Cancel Close Account';
    const properties = {};
    logMetricsTrackingEvent(event, properties)();
  };
}

export default connect()(CloseAccount);
