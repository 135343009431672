import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 20px;
  height: 20px;
  & > svg {
    width: 100%;
    height: 100%;
  }
  & circle,
  & g,
  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function SearchIcon() {
  return (
    <Wrapper>
      <svg height={24} viewBox="0 0 24 24" width={24}>
        <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z" />
        <circle cx={12} cy={9} r={2.5} />
      </svg>
    </Wrapper>
  );
}

export default SearchIcon;
