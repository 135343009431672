import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import IconButton from '../../components/buttons/IconButton';
import LoadingIcon from '../../components/misc/LoadingIcon';
import FormLoadingOverlay from '../../components/overlays/FormLoadingOverlay';
import Submit from '../../components/buttons/Submit';

class TextAreaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',

      submitting: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.defaultValue) {
      this._setValue(this.props.defaultValue);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _isSubmitting = () => this.state.submitting;
  _returnLoadingText = () => this.props.loadingText || 'Submitting';

  render() {
    return (
      <div className={`textarea-form-container`}>
        <form onSubmit={this.handleSubmit}>
          <div className={`textarea-form-textarea-container`}>
            {this._isSubmitting() && this._renderLoadingState()}
            <textarea
              type="text"
              value={this.state.inputValue}
              placeholder={this.props.placeholder || ''}
              onChange={this.handleChange}
              autoFocus={this.props.autoFocus}
            />
            {this.props.icon && <IconButton {...this.props.icon} handleClick={this.handleSubmit} />}
            {this.props.renderSubmitButton && (
              <div className={`textarea-form-submit-container`}>
                <Submit className="textarea-form-submit" type="submit" value="Send" fullWidth />
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }

  _renderLoadingState = () => {
    return (
      <FormLoadingOverlay
        spinner={{
          iconClass: 'circle-loading-spinner circle-loading-spinner-medium',
          style: { marginBottom: '-7px' },
        }}
        message={this._returnLoadingText()}
        show
      />
    );
  };

  handleChange = (e) => {
    const value = e.target.value;
    this._setValue(value);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    $('input').blur();
    if (this.state.submitting) {
      console.error('Already submitting form');
      return false;
    }

    const inputValue = this.state.inputValue;
    if (inputValue === '' && this.props.requireUserToEnterValueBeforeSubmit) {
      return false;
    }
    this.startSubmitting();
    return this.props.handleSubmit(inputValue).then((ajaxWasSuccessful) => {
      // this._isMounted checks to prevent changing state of an unmounted component
      this._isMounted && this.stopSubmitting();
      if (ajaxWasSuccessful) {
        this._isMounted && this.clearInput();
      }
    });
  };

  _setValue = (value) => {
    this.setState((prevState) => ({
      inputValue: value,
    }));
  };

  clearInput = () => {
    this._setValue('');
  };

  startSubmitting = () => {
    this.setState((prevState) => ({
      submitting: true,
    }));
  };

  stopSubmitting = () => {
    this.setState((prevState) => ({
      submitting: false,
    }));
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(TextAreaForm);
