import React from 'react';
import { VerifyBank } from './VerifyBank';
import { LearnAndEarnCTAFeedBanner } from './LearnAndEarnCTAFeedBanner';
import styled from 'styled-components';
import { useBankAccounts } from '../../hooks/funding/useBankAccounts';
import { SelectVPCBanner } from './SelectVPCBanner';
import { useNextLesson } from '../../hooks/learn/useNextLesson';
import {
  doesCurrentUserHaveFreeSubSlot,
  isCurrentUserEligibleForFundingReward,
} from '../../../helpers/currentUserHelpers';
import { useCurrentUser } from '../../hooks/user';
import { TrackingEvents } from '../../../utils/tracking/events';
import { ReferralBanner } from './ReferralBanner';
import { NextLearnLessonBanner } from './NextLearnLessonBanner';
import { FundingBonusBanner } from './FundingBonusBanner';
import { ReferralActionBanner } from './ReferralActionBanner';
import { isUndefinedOrNull, pickOne, roll } from '../../../helpers/usefulFuncs';
import { DownloadAppBanner } from './DownloadAppBanner';

// changing the string values will change the event property tracking
const BANNERS = {
  learn: 'Start Learn & Earn',
  verifyBank: 'Verify Bank Account',
  selectVPC: 'Select Free VPC',

  nextLearnLesson: 'Learn',
  referral: 'Referral',
  fundingBonus: 'Funding Bonus',

  referralAction: 'Referral Action',
  downloadApp: 'Download App',
};

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

class DismissBannerManager {
  constructor() {
    this.storageKey = 'dafb';
  }

  // for debugging/ resetting dismisses
  // _clearLocalStorage = () => {
  // window.localStorage.removeItem('dafb');
  // }

  isBannerDismissed = (type) => {
    const d = this.getDismissedBannerData();
    return d[type]?.is;
  };

  setDismissedBanner = (type, metadata = {}) => {
    if (!type) {
      console.error('Cannot dismiss banner', type);
      return;
    }

    const d = this.getDismissedBannerData();
    const updatedData = {
      ...d,
      [type]: {
        is: true,
        metadata,
      },
    };

    window.localStorage.setItem(this.storageKey, JSON.stringify(updatedData));
  };

  getDismissedBannerData = () => {
    try {
      const stringifiedData = window.localStorage.getItem(this.storageKey);
      return JSON.parse(stringifiedData) || {};
    } catch (e) {
      console.error(e);
      return {};
    }
  };
}

const dismissBannerManager = new DismissBannerManager();

const BannerRenderer = (props) => {
  const { bannerType } = props;

  let Component = null;
  switch (bannerType) {
    case BANNERS.downloadApp:
      Component = <DownloadAppBanner {...props} ContainerComponent={Wrapper} />;
      break;
    case BANNERS.referralAction:
      Component = <ReferralActionBanner {...props} ContainerComponent={Wrapper} />;
      break;
    case BANNERS.fundingBonus:
      Component = <FundingBonusBanner {...props} ContainerComponent={Wrapper} />;
      break;
    case BANNERS.nextLearnLesson:
      Component = <NextLearnLessonBanner {...props} ContainerComponent={Wrapper} />;
      break;
    case BANNERS.referral:
      Component = <ReferralBanner {...props} ContainerComponent={Wrapper} />;
      break;
    case BANNERS.verifyBank:
      Component = <VerifyBank {...props} ContainerComponent={Wrapper} />;
      break;
    case BANNERS.learn:
      Component = <LearnAndEarnCTAFeedBanner {...props} ContainerComponent={Wrapper} />;
      break;
    case BANNERS.selectVPC:
      Component = <SelectVPCBanner {...props} ContainerComponent={Wrapper} />;
      break;
    default:
      Component = null;
  }

  return Component;
};

// instant priority banners
// BANNERS.verifyBank,
// BANNERS.learn,

// priority banners
// BANNERS.selectVPC,
// BANNERS.nextLearnLesson,

const useSelectAboveFeedBanner = () => {
  const [isReady, setIsReady] = React.useState(false);

  const currentUser = useCurrentUser();
  const [filter] = React.useState({ isVerificationReady: true });
  const verifyReadyBankAccounts = useBankAccounts(filter);
  const { completedLessonCount, nextLessonId } = useNextLesson();
  const hasFreeViewpointSelectionAvailable = doesCurrentUserHaveFreeSubSlot(currentUser);

  React.useEffect(() => {
    setIsReady(true);
  }, []);

  const getAvailableBanners = () => {
    const availableBannerTypes = [];

    const dismissableBannerTypes = [BANNERS.referral, BANNERS.referralAction];

    if (!currentUser.has_downloaded_app) dismissableBannerTypes.push(BANNERS.downloadApp);

    if (isCurrentUserEligibleForFundingReward(currentUser)) {
      dismissableBannerTypes.push(BANNERS.fundingBonus);
    }

    if (completedLessonCount === 0) return [BANNERS.learn];

    if (!isUndefinedOrNull(nextLessonId)) {
      return [BANNERS.nextLearnLesson];
    }

    if (verifyReadyBankAccounts.length > 0) return [BANNERS.verifyBank];

    // VPC disabled
    // if (hasFreeViewpointSelectionAvailable) {
    //   availableBannerTypes.push(BANNERS.selectVPC);
    // }

    dismissableBannerTypes.forEach((bannerType) => {
      if (!dismissBannerManager.isBannerDismissed(bannerType)) availableBannerTypes.push(bannerType);
    });

    return availableBannerTypes;
  };

  const selectBanner = React.useCallback(() => {
    return pickOne(getAvailableBanners());
  }, []);

  return {
    isReady,
    selectBanner,
  };
};

const _BannerController = (props) => {
  const [bannerType, setBannerType] = React.useState(null);
  const [isDismissed, setIsDismissed] = React.useState(false);
  const { isReady, selectBanner } = useSelectAboveFeedBanner();

  React.useEffect(() => {
    if (isReady && !bannerType && !isDismissed) {
      const selectedBanner = selectBanner();
      if (selectedBanner !== null) setBannerType(selectedBanner);
    }
  }, [isReady, bannerType]);

  const handleView = React.useCallback(
    (properties = {}) => {
      TrackingEvents.banners.VIEW_BANNER.send({
        'Is Above Feed': true,
        'Banner Type': bannerType,
        ...properties,
      });
    },
    [bannerType]
  );
  const handleCTAClick = React.useCallback(
    (properties = {}) => {
      TrackingEvents.banners.CLICK_BANNER_CTA.send({
        'Is Above Feed': true,
        'Banner Type': bannerType,
        ...properties,
      });
    },
    [bannerType]
  );
  const handleDismissClick = React.useCallback(
    (properties = {}) => {
      setIsDismissed(true);
      setBannerType(null);

      dismissBannerManager.setDismissedBanner(bannerType);

      TrackingEvents.banners.DISMISS_BANNER_CTA.send({
        'Is Above Feed': true,
        'Banner Type': bannerType,
        ...properties,
      });
    },
    [bannerType]
  );

  return (
    <BannerRenderer
      {...props}
      onView={handleView}
      onCTAClick={handleCTAClick}
      onDismiss={handleDismissClick}
      bannerType={bannerType}
    />
  );
};

class BannerController extends React.PureComponent {
  render() {
    return <_BannerController {...this.props} />;
  }
}

export default BannerController;
