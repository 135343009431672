export const GlobalColors = {
  mainBgColor: '#F7F4F2',
  textColor: '#2b2b2b',

  lightestGrey: '#f7f7f7',
  lightGrey: '#dbdbdb',
  mediumGrey: '#c5c5c5',
  darkerMediumGrey: '#B5B5B5',
  darkGrey: '#707070',
  darkerGrey: '#4D4D4D',
  darkestGrey: '#2D2D2D',
  grey: '#aaa',

  greyLine: '#f1f1f1',

  bgFade: 'rgba(45, 62, 85, 0.1)',

  alertGreen: '#60C778',
  green1: '#6BB166',
  green2: '#CBE7CA',
  green2Hover: 'rgb(194, 221, 191)',
  green3: '#E6FFE6',
  highlightGreen: '#8DC389',
  brightGreen: '#c1f588',

  gradientGreen: '#3dd735',

  splashGreen: '#3ac538',
  splashGreenHover: '#30bb2e',

  green: '#3db735',
  green4: '#3db735',
  green4Hover: '#36a62e',

  brightOrange: '#ff9c22',

  orange: '#ff9c22',
  orangeHover: '#ea942c',

  bullseyeIcon: '#ff7666',

  red: '#ff7666',
  redHover: '#f85947',
  warningBg: '#faf2f1',

  magenta: '#d11976',

  darkBlue: '#2d3e55',
  darkBlueHover: '#0c1118',
  mediumBlue: '#808ea0',
  lightBlue: '#e3eaf3',

  brightBlue: '#028fea',
  brightBlueHover: '#007ccf',

  gold: '#E8CE12',

  lightYellow: '#fffff4',
  brightYellow: '#ffff00',
  yellow: '#eecc06',
  mediumYellow: '#f0e697',
  darkYellow: '#dfe0c7',
  darkerYellow: '#cdcea8',

  popoverArrowColor: '#2d3e55',
  popoverBgColor: '#2d3e55',
};
