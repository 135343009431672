import React from 'react';
import SecurityIdentifierDisplay from '../../../UI/SecurityIdentifierDisplay';
import AskAnyone from '../../../buttons/AskAnyone';
import { InlineUserList } from '../../../UI/InlineUserList';
import { Timestamp } from '../../../UI/Timestamp';
import { getSecurityIdFromIdea, getSecuritySymbolFromIdea, getSecurityNameFromIdea } from '@src/helpers/ideaHelpers';
import { pluralizeIfAppropriate } from '@src/helpers/generalHelpers';
import { throwError } from '@src/helpers/devToolHelpers';
import { PROFILE_CONTEXTS } from '@src/constants/contextTracking';

class IdeaActionNotification extends React.Component {
  render() {
    const ideaActionTypeRenderFunction = this._returnRendererForIdeaActionType();
    return <div className="idea-action-notif-container">{ideaActionTypeRenderFunction()}</div>;
  }

  _renderIdeaAction = () => {
    return (
      <div className={'idea-action-message-container'}>
        <div className={'idea-action-message'}>
          {this._renderInlineUserList()}
          {this._renderIdeaActionPartOnePhrase()}
          {this._renderIdeaActionPartTwoPhrase()}
        </div>
        {this._renderIdeasInRows(true)}
      </div>
    );
  };

  _renderIdeaActionPartOnePhrase = () => {
    return <span className={'idea-action-phrase  '}>{this._returnPhrasePartOneJSXForIdeaActionType()}</span>;
  };

  _renderIdeaActionPartTwoPhrase = () => {
    return <span className={'idea-action-phrase  '}>{this._returnPhrasePartTwoJSXForIdeaActionType()}</span>;
  };

  // phrase is broken up with spans for line breaking purposes, otherwise entire phrase would wrap under name if not enough width available
  _returnPhrasePartOneJSXByIdeaActionTypeLookup = () => ({
    create: <span className="inline-block-fragment-14px-font">added to</span>,
    update: <span>{`updated ${pluralizeIfAppropriate('idea', this._returnNotificationIdeas())}.`}</span>,
    destroy: (
      <span className="inline-block-fragment-14px-font">{`removed ${pluralizeIfAppropriate(
        'stock',
        this._returnNotificationIdeas()
      )}`}</span>
    ),
    reaffirm: (
      <span className="inline-block-fragment-14px-font">{`reaffirmed their ${pluralizeIfAppropriate(
        'idea',
        this._returnNotificationIdeas(),
        { ignorePrefix: true }
      )}`}</span>
    ),
    reactivate: (
      <span className="inline-block-fragment-14px-font">{`is considering ${pluralizeIfAppropriate(
        'new stock',
        this._returnNotificationIdeas()
      )}`}</span>
    ),
    deactivate: (
      <span className="inline-block-fragment-14px-font">{`is no longer considering ${pluralizeIfAppropriate(
        'stock',
        this._returnNotificationIdeas(),
        { pluralPrefix: 'some ' }
      )}`}</span>
    ),
  });

  // phrase is broken up with spans for line breaking purposes, otherwise entire phrase would wrap under name if not enough width available
  _returnPhrasePartTwoJSXByIdeaActionTypeLookup = () => ({
    create: <span>their watchlist.</span>,
    update: <span></span>,
    destroy: <span>from their watchlist.</span>,
    reaffirm: (
      <span>{`on ${pluralizeIfAppropriate('stock', this._returnNotificationIdeas(), {
        pluralPrefix: 'some ',
      })}.`}</span>
    ),
    reactivate: <span>for their portfolio.</span>,
    deactivate: <span>for their portfolio.</span>,
  });

  _returnPhrasePartOneJSXForIdeaActionType = () =>
    this._returnPhrasePartOneJSXByIdeaActionTypeLookup()[this._returnNotificationActionType()] ||
    throwError(`Missing internal phrase for idea_action: ${this._returnNotificationActionType()}`, true);

  _returnPhrasePartTwoJSXForIdeaActionType = () =>
    this._returnPhrasePartTwoJSXByIdeaActionTypeLookup()[this._returnNotificationActionType()] ||
    throwError(`Missing internal phrase for idea_action: ${this._returnNotificationActionType()}`, true);

  _renderIdeaCreateNotification = () => {
    return (
      <div className={'idea-create-notification idea-action-notification-body-container'}>
        <div className={'idea-action-notification-body'}>{this._renderIdeaAction()}</div>
        <Timestamp className={'notification-timestamp'} epochTime={this._returnNotificationTimestamp()} />
      </div>
    );
  };

  _renderIdeaUpdateNotification = () => {
    return (
      <div className={'idea-update-notification idea-action-notification-body-container'}>
        <div className={'idea-action-notification-body'}>{this._renderIdeaAction()}</div>
        <Timestamp className={'notification-timestamp'} epochTime={this._returnNotificationTimestamp()} />
      </div>
    );
  };

  _renderIdeaDestroyNotification = () => {
    return (
      <div className={'idea-destroy-notification idea-action-notification-body-container'}>
        <div className={'idea-action-notification-body'}>{this._renderIdeaAction()}</div>
        <Timestamp className={'notification-timestamp'} epochTime={this._returnNotificationTimestamp()} />
      </div>
    );
  };

  _renderIdeaReaffirmNotification = () => {
    return (
      <div className={'idea-reaffirm-notification idea-action-notification-body-container'}>
        <div className={'idea-action-notification-body'}>{this._renderIdeaAction()}</div>
        <Timestamp className={'notification-timestamp'} epochTime={this._returnNotificationTimestamp()} />
      </div>
    );
  };

  _renderIdeaReactivateNotification = () => {
    return (
      <div className={'idea-reactivate-notification idea-action-notification-body-container'}>
        <div className={'idea-action-notification-body'}>{this._renderIdeaAction()}</div>
        <Timestamp className={'notification-timestamp'} epochTime={this._returnNotificationTimestamp()} />
      </div>
    );
  };

  _renderIdeaDeactivateNotification = () => {
    return (
      <div className={'idea-deactivate-notification idea-action-notification-body-container'}>
        <div className={'idea-action-notification-body'}>{this._renderIdeaAction()}</div>
        <Timestamp className={'notification-timestamp'} epochTime={this._returnNotificationTimestamp()} />
      </div>
    );
  };

  _renderInlineUserList = (additionalProps = {}) => {
    const userIdsList = [this._returnIdeaOwner().user_id];
    return (
      <InlineUserList
        profileLinkQuery={`?context=${PROFILE_CONTEXTS.NOTIFICATION}`}
        userIds={userIdsList}
        {...additionalProps}
        withAvatar
      />
    );
  };

  _renderIdeasInRows = (includeAskWhy) => {
    return (
      <ul className={'idea-list-in-notification notification-indent'}>
        {this._returnNotificationIdeas().map((idea) => this._renderIdeaRow(idea, includeAskWhy))}
      </ul>
    );
  };

  _renderIdeaRow = (idea, includeAskWhy) => {
    return (
      <li
        key={`security-list-in-notification-row-idea-${idea.idea_id}`}
        className={'security-list-in-notification-row'}
        style={{ justifyContent: 'space-between' }}
      >
        {
          <SecurityIdentifierDisplay
            securityId={getSecurityIdFromIdea(idea)}
            symbol={getSecuritySymbolFromIdea(idea)}
            name={getSecurityNameFromIdea(idea) || 'This should be a name'}
            prefix={'('}
            suffix={')'}
            showSymbol
            showName
            limitNameWidth
            inFeed
          />
        }
        {includeAskWhy && this._renderAskWhy(idea)}
      </li>
    );
  };

  _renderAskWhy = (idea) => {
    return (
      <AskAnyone
        viewingContext={`Ideas Action Notification - ${this._returnNotificationActionTypeDisplayString()}`}
        ideaId={idea.idea_id}
        customAskType={'askBtnSmall'}
        customAskTypeButtonRenderer={'_renderAskButtonSmall'}
        customAskTypeDisabledButtonRenderer={'_renderAskButtonSmallDisabled'}
        openFormInModal
      />
    );
  };

  // _renderListOfUserIdeaLinks = (userIdeasObjFromList, key) => {
  //   const userIdsList = userIdeasObjFromList.map(userIdeaObj => userIdeaObj.user.user_id);
  //   const userIdToIdeaIdLookup = {}
  //   userIdeasObjFromList.forEach(userIdeaObj => {
  //     userIdToIdeaIdLookup[userIdeaObj.user.user_id] = userIdeaObj.idea.idea_id
  //   })
  //   const additionalProps = {
  //     returnUserSpecificProps: function(userId) {
  //       const ideaId = userIdToIdeaIdLookup[userId];
  //       const currentURLQuery = window.location.search;
  //       const currentURLQueryPrependString = window.location.search.length > 0 ? `${window.location.search}&` : '?';
  //       const linkTo = `${currentURLQueryPrependString}panel=idea&ideaId=${ideaId}`;
  //       return { linkTo };
  //     }
  //   }
  //   return this._renderInlineUserList(additionalProps);
  // }

  _rendererForIdeaActionTypeLookup = () => ({
    create: this._renderIdeaCreateNotification,
    update: this._renderIdeaUpdateNotification,
    destroy: this._renderIdeaDestroyNotification,
    reaffirm: this._renderIdeaReaffirmNotification,
    reactivate: this._renderIdeaReactivateNotification,
    deactivate: this._renderIdeaDeactivateNotification,
  });

  _returnRendererForIdeaActionType = () => {
    return (
      this._rendererForIdeaActionTypeLookup()[this._returnNotificationActionType()] ||
      throwError(`Missing internal render for idea_action: ${this._returnNotificationActionType()}`, true)
    );
  };

  _returnNotification = () => this.props.notification;

  _returnNotificationActionType = () => this._returnNotification().action_type;

  _returnNotificationActionTypeDisplayString = () => {
    const lookup = {
      create: 'Create',
      update: 'Update',
      destroy: 'Delete',
      reaffirm: 'Reaffirm',
      reactivate: 'Reactivate',
      deactivate: 'Deactivate',
    };
    return (
      lookup[this._returnNotificationActionType()] ||
      throwError(
        `Missing internal _returnNotificationActionTypeDisplayString for idea_action: ${this._returnNotificationActionType()}`,
        true
      )
    );
  };

  _returnNotificationIdeas = () => this._returnNotification().ideas;

  _returnIdeaOwner = () => this._returnNotification().owner;

  _isMoreThanOneIdeaInAction = () => this._returnNotificationIdeas().length > 0;

  _returnNotificationSecurityIds = () => this._returnNotificationIdeas().map((idea) => getSecurityIdFromIdea(idea));

  _returnNotificationTimestamp = () => this._returnNotification().timestamp;
}

export default IdeaActionNotification;
