import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME, SUPPORT_EMAIL } from '@src/appConfig';
import * as Actions from '../../actions/index';
import styled from 'styled-components';
import { enums } from '@src/main/lib/nvstr-utils.es';
import SecurityCardBody from './SecurityCardBody';
import SecurityHeading from '../../components/SecurityCard/SecurityHeading';
import SecurityStackedRow from '../../components/IdeaLab/PositionVisualizer/SecurityStackedRow';
import ChartContainer from '../../components/Charts/ChartContainer';
import PageLoading from '../../components/PageLoading';
import Icon from '../../components/misc/Icon';
import SecurityCardActionButtons from './SecurityCardActionButtons';
import NvstrCommunityPositionChart from '../Community/NvstrCommunityPositionChart';
import IdeasListDataWrapper from '../DataWrappers/IdeasListDataWrapper';
import { parseQueryString, createQueryString } from '@src/helpers/routerHelpers';
import { switchToTab } from '@src/helpers/navigationHelpers';
import { sendFacebookTrackingEvent, sendFacebookLTVEvent } from '@src/constants/facebookTracking';
import {
  returnCurrentUserThemeTestGroups,
  doesCurrentUserHavePositionIn,
  isCurrentUserLiveTrading,
} from '@src/helpers/currentUserHelpers';
import { createSuccessModal, createThoughtModal, createBasicErrorModal } from '@src/constants/modals';
import { TrackingEvents } from '@src/utils/tracking/events';
import { TrackIteratively } from '@src/utils/itly';
import { ElementPositionContext } from '@src/context';
import { FlatButton } from '@src/main/components/buttons';
import { ROUTES } from '@src/constants/paths';

const SecurityPanelsWrapper = styled('div')`
  white-space: nowrap;
`;

const SecurityActionsWrapper = styled('div')`
  background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};
`;

const SecurityCardWrap = styled('div')`
  background-color: ${({ theme }) => theme.themeColors.appBackground};

  ${SecurityPanelsWrapper} {
    background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};
  }

  .security-card-tab-content {
    background-color: ${({ theme }) => theme.themeColors.appBackground};
  }

  .thought-container {
    background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};
  }

  & .thought-text-container {
    padding: 10px;
  }

  & .thought-container {
    max-width: 100%;
    margin: 0 auto 8px auto;
  }

  & .react-thoughts-container .thought-list {
    padding-top: 0;
  }

  & .thought-opinion-data-container {
    padding: 5px 0 10px 25px;
  }

  & .thought-actions-container {
    padding: 5px 0 5px 20px;
  }

  & .security-card-container-side-panel {
    padding: 20px;
  }

  & .security-card-container-side-panel.security-card-heading-panel {
    padding: 20px 40px 20px 20px;
  }

  & .security-card-heading-panel,
  & .security-card-fin-panel {
    margin: 0;
  }

  & .hr-standard.border-accent {
    display: none;
  }

  & .drop-shadow-container {
    display: none;
  }

  .flat-tab-color-style {
    color: ${({ theme }) => theme.themeColors.text};
    border-bottom: 2px solid transparent;
    border-radius: 0;

    &:hover {
      border-bottom: 2px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
      background: transparent;

      * {
        color: ${({ theme }) => theme.themeColors.primaryCtaButton};
      }
    }
  }

  .flat-tab-color-style.active {
    color: ${({ theme }) => theme.themeColors.primaryCtaButton};
    background: transparent;
    border-bottom: 2px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
  }

  & .default-search-container .default-search-container {
    position: relative;
    top: 7px;
  }

  .idea-show-container {
    border-color: ${({ theme }) => theme.themeColors.componentNoOpacity};
  }

  .page-loading.component-bg {
    background: transparent !important;
  }
`;

const tabNameToEventTabName = {
  headlines: 'News',
  thoughts: 'Pros/Cons',
  ideas: 'Ideas',
  compare: 'Compare',
};

class SecurityCardContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      _isInitializing: true,
      securityId: null,

      contentContainerWidth: 1065,

      activeTab: props.activeTab || props.defaultActiveTab || 'thoughts',
      additionalTabData: props.additionalTabData || null,
      defaultActiveTab: props.defaultActiveTab || 'thoughts',

      didDisplayThoughtRecordedMessage: false,
    };
  }

  componentDidMount() {
    this._initializeResizeListener();
    this._initialize();

    const event = 'View Stock Panel Tab';
    const properties = {
      'Is Initial Tab': 'True',
      Tab: tabNameToEventTabName[this.state.activeTab],
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);

    if (this.cachedPageTitle) {
      document.title = this.cachedPageTitle;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this._updatePageTitle();

    if (this._didUrlQueryChange(prevProps)) {
      this._updateStateFromURLQuery(prevProps);
    }

    if (this._didInitializeComplete(prevState)) {
      this._onInitializeComplete();
      this._handleFromEmailNavigationUI();
    }

    if (!this._isSecurityFeatured() && this._isThoughtPanelOverlayOpen) {
      this._isThoughtPanelOverlayOpen = false;
      this.props.actions.hideModal();
    }
  }

  render() {
    if (this._isLoading()) {
      return (
        <SecurityCardWrap>
          <div className={'security-card-container loading'}>
            <PageLoading style={{ zIndex: 10000 }} flatTransparent />
          </div>
        </SecurityCardWrap>
      );
    }

    const displayContext = this._isInPanel() ? 'panel' : 'page';
    const userIsAuthenticated = 'user_id' in this.props.currentUser;
    const security = this._returnSecurity();
    const isSecurityFeatured = 'featured' in security ? security.featured : true;
    return (
      <SecurityCardWrap>
        <div
          id={this._returnSecurityId()}
          className={'security-card-container'}
          ref={(el) => (this.contentContainer = el)}
        >
          {displayContext === 'panel' && (
            <div className={'security-panel-close-btn'} onClick={this.props.handleSecurityCardCollapse}>
              <i className="fa fa-times" />
            </div>
          )}
          <SecurityPanelsWrapper>
            <div className="security-card-container-side-panel security-card-heading-panel">
              <SecurityHeading
                contentContainerWidth={this.state.contentContainerWidth}
                isSecurityFeatured={isSecurityFeatured}
                securityId={this._returnSecurityId()}
                security={security}
                symbol={security.symbol.toUpperCase()}
                name={security.name}
                sector={security.sector}
                description={security.description}
                userIsAuthenticated={userIsAuthenticated}
                currentUser={this.props.currentUser}
                logEventForDescription={this.logEventForDescription}
              />
            </div>
            <div className="security-card-container-side-panel security-card-fin-panel">
              {!isSecurityFeatured && (
                <div className="security-unfeatured-message">
                  <Icon icon="fa-exclamation-circle" color="#eecc06" size="large" style={{ marginRight: '8px' }} />
                  This instrument is not currently available to trade
                </div>
              )}
              {!isSecurityFeatured && this._doesCurrentUserHavePosition() && (
                <div className="security-unfeatured-trade-message">
                  <p>
                    <span className="inline-fragment">Contact support to exit this position at</span>
                    <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
                  </p>
                </div>
              )}
              {userIsAuthenticated && isSecurityFeatured && (
                <IdeasListDataWrapper securityId={this._returnSecurityId()} security={security}>
                  <SecurityStackedRow security={security} containerStyling={{ paddingTop: 0 }} hideLabel />
                </IdeasListDataWrapper>
              )}
              {userIsAuthenticated && isSecurityFeatured && <NvstrCommunityPositionChart security={security} />}
              <div className="security-panel-heading-chart-container">
                <ChartContainer
                  chartContext={'Security Card'}
                  securityId={this._returnSecurityId()}
                  symbol={this._returnSecurity().symbol}
                  userIsAuthenticated={userIsAuthenticated}
                  compareToSpyOption
                />
              </div>
            </div>
          </SecurityPanelsWrapper>
          {userIsAuthenticated && (
            <SecurityActionsWrapper>
              <SecurityCardActionButtons
                securityId={this._returnSecurityId()}
                security={security}
                location={this.props.location}
                handleBuyClick={this.handleBuyClick}
                handleSellClick={this.handleSellClick}
              />
            </SecurityActionsWrapper>
          )}
          <SecurityCardBody
            security={security}
            securityId={this._returnSecurityId()}
            contentContainerWidth={this.state.contentContainerWidth}
            removeIdea={this.props.actions.removeIdea}
            currentUserId={this.props.currentUser.user_id}
            activeTab={this.state.activeTab}
            defaultActiveTab={this.state.defaultActiveTab}
            additionalTabData={this.state.additionalTabData}
            askingWhyFromNotif={this.props.askingWhyFromNotif}
            handleTabClick={this.handleTabClick}
            users={this.props.users}
            userIsAuthenticated={userIsAuthenticated}
            actions={this.props.actions}
            isInPanel={this._isInPanel()}
          />
        </div>
      </SecurityCardWrap>
    );
  }

  handleTabClick = (tab, config) => {
    this._logItlyTabClick(tab);
    sendFacebookTrackingEvent('Click Stock Panel Tab', {
      themeTestGroups: returnCurrentUserThemeTestGroups(this.props.currentUser),
    });

    const event = 'View Stock Panel Tab';
    const properties = {
      'Is Initial Tab': 'False',
      Tab: tabNameToEventTabName[tab],
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);

    switchToTab(tab, this.props.navigate, config, this.props.location.pathname);
  };

  handleTradeClick = (orderType) => {
    const navigate = this.props.navigate;

    if (this.props.handleSecurityCardCollapse) {
      this.props.handleSecurityCardCollapse();
    }
    navigate(ROUTES.CREATE_ORDER_SYMBOL.build(orderType, this._returnSecurity().symbol, this._returnSecurity().id));
  };

  handleBuyClick = () => {
    this.handleTradeClick('buy');
    this._logTradeButtonClick('Buy');
  };

  handleSellClick = () => {
    this.handleTradeClick('sell');
    this._logTradeButtonClick('Sell');
  };

  _isLoading = () => {
    return (
      this._isInitializing() ||
      !(this._returnSecurityId() in this.props.securities.lookup) ||
      !this._returnSecurityName()
    );
  };

  _isSecurityFeatured = () =>
    this._returnSecurity() && 'featured' in this._returnSecurity() ? this._returnSecurity().featured : true;

  _returnSecurityIdentifier = () => this.props.securityIdentifier;

  _returnSecurityId = () => this.state.securityId;

  _returnSecurityName = () => (this._returnSecurity() ? this._returnSecurity().name : null);

  _returnSecurity = () => this.props.securities.lookup[this._returnSecurityId()];

  _isSecurityIdentifierAnId = () => {
    const keys = Object.keys(this._returnSecurityIdentifier());
    return keys[0] === 'securityId';
  };

  _doesCurrentUserHavePosition = () =>
    doesCurrentUserHavePositionIn(this._returnSecurityId(), this._returnPortfolioStore());

  _returnPortfolioStore = () => this.props.portfolio;

  _shouldDisplayThoughtRecordedMessage = () => {
    const query = parseQueryString(this.props.location.search);
    const didUserRespondToThoughtInEmail = query.thought;
    const shouldDisplayMessage = !this.state.didDisplayThoughtRecordedMessage && didUserRespondToThoughtInEmail;
    return shouldDisplayMessage;
  };

  _returnThoughtRecordedMessageModal = () => {
    const component = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        <div className={'thought-recorded-message-container'}>
          <Icon size="large" icon="fa-check-circle" colorClassName={'success-text-color'} />
          <div className={'thought-recorded-message'}>{'Your response was saved.'}</div>
        </div>
      </div>
    );

    const modal = {
      contentComponent: component,
      dismissable: true,
      size: 'wide',
    };
    return modal;
  };

  _displayThoughtRecordedMessage = () => {
    const modal = this._returnThoughtRecordedMessageModal();
    this.props.actions.showModal(modal);

    this.setState(() => ({
      didDisplayThoughtRecordedMessage: true,
    }));
  };

  _handleFromEmailNavigationUI = () => {
    if (this._shouldDisplayThoughtRecordedMessage()) {
      this._displayThoughtRecordedMessage();
      const ignoreEventLog = true;
      const config = {
        ignoreEventLog,
      };
      this.handleTabClick('thoughts', config);
    }

    if (this._isResponding()) {
      this._openThoughtPanelOverlay();
    } else if (this._isEmailResponse()) {
      this._createAskWhyResponseSavedModal();
    }
  };

  _createAskWhyResponseSavedModal = () => {
    const modalProps = {
      successMessage: 'Response saved',
      additionalSuccessMessaging: 'We will share your response with your connection.',
    };
    const modal = createSuccessModal(modalProps);
    return this.props.actions.showModal(modal);
  };

  _openThoughtPanelOverlay = () => {
    this._isThoughtPanelOverlayOpen = true;
    const modal = createThoughtModal({
      securityId: parseInt(this._returnSecurityId(), 10),
      hideModal: this.props.actions.hideModal,
      thoughtPostSuccessAdditionalAction: () => {
        // this action gets called after post success message is auto-closed
        this.props.actions.hideModal();
        this._isThoughtPanelOverlayOpen = false;
        this._createAndShowAddAnotherThoughtModal();
      },
      customMessageWithSymbol: (symbol) => `Respond by posting a Pro or Con for ${symbol}`,
    });
    this.props.actions.showModal(modal);
  };

  _handleThoughtOverlayPanelClose = () => {
    this.props.actions.hideModal();
    this._isThoughtPanelOverlayOpen = false;
    switchToTab('thoughts', this.props.navigate);
  };

  _createAndShowAddAnotherThoughtModal = () => {
    const component = (
      <div className={''}>
        <h2>Do you want to add another thought?</h2>
        <div className={'modal-action-btn-container'}>
          <FlatButton onClick={this._openThoughtPanelOverlay}>Yes</FlatButton>
        </div>
      </div>
    );
    this.props.actions.showModal({
      className: 'add-another-thought-message-modal',
      contentComponent: component,
      dismissable: true,
      size: 'wide',
      overrideDismiss: this._handleThoughtOverlayPanelClose,
      overrideOverlayDismiss: this._handleThoughtOverlayPanelClose,
    });
  };

  _isResponding = () => {
    const queryString = this.props.location.search;
    const query = parseQueryString(queryString);
    return 'auth_id' in query && 'token' in query && 'responding' in query;
  };

  _isEmailResponse = () => {
    const queryString = this.props.location.search;
    const query = parseQueryString(queryString);
    return 'auth_id' in query && 'token' in query && 'responded' in query;
  };

  getSecurity = (props) => (props || this.props).securities.lookup[this._returnSecurityId()];

  _fetchDataForSecurity = (securityId) => {
    this.props.actions.quickFetchSecuritiesData([securityId]);
    this.props.actions.fetchSecuritiesFundamentalsData([securityId]);
    this.props.actions.fetchSecuritiesPriceData([securityId]);
    this.props.actions.getIdeasForSecurity([securityId]);
  };

  _initializeResizeListener() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  _initialize() {
    if (this._isSecurityIdentifierAnId()) {
      this._handleInitialization({
        securityId: this._returnSecurityIdentifier().securityId,
      });
    } else {
      this._convertSecurityIdentifierToSecurityId().then((securityId) => {
        if (securityId) {
          this._handleInitialization({ securityId });
        }
      });
    }
  }

  _convertSecurityIdentifierToSecurityId = () => {
    const symbol = this._returnSecurityIdentifier().symbol;
    return this.props.actions.quickFetchSecuritiesData([symbol]).then((response) => {
      const wasSuccess = response && response.securities && response.securities[0];
      if (wasSuccess) {
        return response.securities[0].security_id;
      } else {
        this._handleConvertSecurityIdentifierToSecurityIdError(response);
        return null;
      }
    });
  };

  _handleConvertSecurityIdentifierToSecurityIdError = (response) => {
    const modalMessage = 'Something went wrong. Please try again later.';
    const modal = {
      contentComponent: createBasicErrorModal(modalMessage),
      dismissable: true,
    };
    this.props.actions.showModal(modal);
  };

  _handleInitialization = (props) => {
    this.setState(() => ({
      _isInitializing: false,
      securityId: props.securityId,
    }));
  };

  _isInitializing = () => this.state._isInitializing;

  _didInitializeComplete = (prevState) => prevState._isInitializing && !this.state._isInitializing;

  _onInitializeComplete = () => {
    this.setState(() => ({
      activeTab: this.props.activeTab,
      additionalTabData: this.props.additionalTabData,
    }));
    this._fetchDataForSecurity(this._returnSecurityId());
    this._logViewSecurity();
  };

  _updateStateFromURLQuery = (prevProps) => {
    const stateChanges = {};
    this._returnUrlQueryChangeKeys(prevProps).forEach((prop) => {
      stateChanges[prop] = this.props[prop];
    });

    this.setState(() => ({
      ...stateChanges,
    }));
  };

  _logViewSecurity = () => {
    const isLiveTrading = isCurrentUserLiveTrading(this.props.currentUser);

    sendFacebookTrackingEvent('View Quote', {
      themeTestGroups: returnCurrentUserThemeTestGroups(this.props.currentUser),
    });

    if (this._returnSecurityId()) {
      this.props.actions.viewSecurity(this._returnSecurityId());
    }

    if (isLiveTrading) sendFacebookLTVEvent(1);
  };

  logEventForDescription = () => {
    const security = this.getSecurity();
    const event = 'View More Stock Description';
    const properties = {
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logTradeButtonClick = (orderType) => {
    const { symbol, security_id } = this.getSecurity();

    const event = 'Clicked Trade Button in Security Panel';
    const properties = {
      'Stock Symbol': symbol,
      'Security ID': security_id,
      Context: 'Security Panel',
      'Order Type': orderType,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logItlyTabClick = (description = 'tabClicked') => {
    const { location } = this.props;
    const { elementPosition } = this.context;

    const properties = {
      context: location.pathname,
      position: elementPosition || enums.node_location.body,
      platform: enums.platform.web,
      description,
      url: window.location.pathname,
      url_query: window.location.search,
    };
    TrackIteratively.generic.TAB_VIEWED.send(properties);
  };

  _updateTitleWithTempTitle = () => {
    document.title = `${PRODUCT_DISPLAY_NAME} - Stock Quotes`;
  };

  _updatePageTitle = () => {
    if (!this.updatedTitle && this._returnSecurityName()) {
      this.updatedTitle = true;
      this.cachedPageTitle = document.title;
      document.title = `${PRODUCT_DISPLAY_NAME} - Stock Quote - ${this._returnSecurityName()}`;
    }
  };

  _didUrlQueryChange = (prevProps) => this._returnUrlQueryChangeKeys(prevProps).length > 0;

  _returnUrlQueryChangeKeys = (prevProps) => {
    const properties = ['activeTab', 'additionalTabData', 'securityId'];
    const newProperties = properties.filter((prop) => this.props[prop] !== prevProps[prop]);
    return newProperties;
  };

  _isInPanel = () => this.props.isPanel;

  handleResize = () => {
    if ('contentContainer' in this && this.contentContainer) {
      this.setState(() => {
        return {
          contentContainerWidth: this.contentContainer.offsetWidth,
        };
      });
    }
  };
}

SecurityCardContainer.contextType = ElementPositionContext;

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    portfolio: state.portfolio,
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const component = connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurityCardContainer);

export default component;
