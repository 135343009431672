import React from 'react';
import styled from 'styled-components';
import UploadAvatar from './UploadAvatar';
import EditProfileForm from './EditProfileForm';

const Wrapper = styled.div`
  input {
    line-height: 29px;
    background: transparent !important;
    cursor: pointer;
  }

  select {
    background: transparent !important;
    cursor: pointer;
  }
`;

const EditProfile = (props) => {
  return (
    <Wrapper className={`edit-profile-container`}>
      <UploadAvatar />
      <EditProfileForm />
    </Wrapper>
  );
};

export default EditProfile;
