import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withWindowSize from '../HOCS/withWindowSize';

import * as Actions from '../../actions/index';

import IconButton from '../../components/buttons/IconButton';

import { findIdKey } from '../../helpers/generalHelpers';

/*
  config = {
    style, -> applies to container
    height,
    itemWidth,
    paddingBetweenItems,
  }
*/

class HorizontalList extends Component {
  componentDidMount() {
    if (!this.props.id) {
      throwError('Must provide unique id to HorizontalList');
    }
  }

  componentWillUnmount() {
    this.props.actions.resetHorizontalListPage();
  }

  componentDidUpdate(prevProps, prevState) {
    const prevList = this.returnListOfItems(prevProps);
    const newList = this.returnListOfItems();

    const idsInView = this.returnIdsInView();
    const didPageChange = this._returnCurrentPage(prevProps) !== this._returnCurrentPage();
    const didListJustCompleteLoading = this.isListLoading(prevProps) && !this.isListLoading();
    const hasLoadingPreviouslyCompleted = !this.isListLoading(prevProps) && !this.isListLoading();
    const didUserJustDismissAnItem = prevList.length - 1 === newList.length;
    const itemCountOnCurrentPage = idsInView.length;
    const isCurrentPageEmpty = itemCountOnCurrentPage === 0;
    const isOnFirstPage = this._returnCurrentPage() === 1;

    if (didPageChange || didListJustCompleteLoading) {
      if (this._shouldLogItemView()) {
        this.props.logItemView(idsInView);
      }
    }

    if (hasLoadingPreviouslyCompleted && didUserJustDismissAnItem) {
      if (this._shouldLogItemView()) {
        const newItem = idsInView[idsInView.length - 1];
        if (newItem) {
          this.props.logItemView([newItem]);
        }
      }
    }

    if (!isOnFirstPage && isCurrentPageEmpty) {
      this.changePage('prev');
    }
  }

  render() {
    return (
      <div id={this.props.id} className={`horizontal-list-container`} style={this.customStyling()}>
        {this.renderList()}
      </div>
    );
  }

  _shouldLogItemView = () => this.props.logItemView;

  customStyling = () => {
    const height = this.props.height + 5;
    const styleProps = { ...(this.props.style || {}), height };
    return styleProps;
  };

  renderLeftGradient = () => {
    return null;
    // !this.isFirstPage() && (
    //   <div className={`transparent-gradient to-left ${this.props.additionalEdgeFadeClassName || ''}`}></div>
    // );
  };

  renderRightGradient = () => {
    return null;
    // !this.isLastPage() && (
    //   <div className={`transparent-gradient to-right ${this.props.additionalEdgeFadeClassName || ''}`}></div>
    // );
  };

  renderPrevArrow = () => {
    if (this.isFirstPage() || this.isListLoading()) return null;
    return (
      <div className="securities-filter-card-list-arrow-container securities-filter-card-list-left-arrow-container horizontal-list-arrow horizontal-list-arrow-left">
        <IconButton
          customClass="circle-fill-button horizontal-list-change-page-btn"
          style={{ padding: '3px 5px 0 0' }}
          icon="fa-chevron-left"
          handleClick={this.gotoPrevPage}
          skinny
          showShadow
        />
      </div>
    );
  };

  renderNextArrow = () => {
    if (this.isLastPage() || this.isListLoading()) return null;
    return (
      <div className="securities-filter-card-list-arrow-container securities-filter-card-list-right-arrow-container horizontal-list-arrow horizontal-list-arrow-right">
        <IconButton
          customClass="circle-fill-button horizontal-list-change-page-btn"
          style={{ padding: '3px 0 0 4px' }}
          icon="fa-chevron-right"
          handleClick={this.gotoNextPage}
          skinny
          showShadow
        />
      </div>
    );
  };

  renderListItem = (item, i) => {
    const listItemProps = this.props.listItemProps;
    const defaultProps = {
      key: `suggested-connection-${item[findIdKey(item)] || i}`,
      item,
      ...listItemProps,
      position: this.determineItemPosition(i, this._returnCurrentPage() - 1),
    };

    if (this.isListLoading()) {
      const customLoadingCard = this.props.loadingCardComponent;
      if (!customLoadingCard) {
        return console.error(`Custom loading card is required if passing in isLoading prop`);
      }
      return React.createElement(customLoadingCard, defaultProps);
    }

    const { componentName, componentProps } = this.props;
    const props = {
      ...defaultProps,
      showHighlight: i === 1 && this.isFirstPage() && this.props.contextForTrackingOnPageChange === 'Top Connections',
    };
    const children = null;
    return React.createElement(componentName, props, children);
  };

  renderList = () => {
    const height = this.props.height + 5;
    return (
      <div className={`horizontal-list`}>
        {this.renderLeftGradient()}
        {this.renderPrevArrow()}
        <div className={`horizontal-list-overflow-container`} style={{ height: `${height}px` }}>
          {this.returnListOfItems().map((item, i) => this.renderListItem(item, i))}
        </div>
        {this.renderRightGradient()}
        {this.renderNextArrow()}
      </div>
    );
  };

  returnIdsInView = () => {
    const itemsPerPage = this.determineItemsPerPage();
    const page = this._returnCurrentPage();
    const items = this.returnListOfItems();

    const endIndexOfList = page * itemsPerPage;
    const startIndexOfList = endIndexOfList - itemsPerPage;
    const itemsToReturn = items.slice(startIndexOfList, endIndexOfList);
    const itemIdsViewed = itemsToReturn.map((item) => item[findIdKey(item)]);
    return itemIdsViewed;
  };

  isListLoading = (props) => (props || this.props).isLoading;

  shouldShowArrowButton = (direction) => {
    if (direction === 'prev' && this.isFirstPage()) {
      return false;
    }
    if (direction === 'next' && this.isLastPage()) {
      return false;
    }
    return true;
  };

  gotoPrevPage = () => {
    const context = this.props.contextForTrackingOnPageChange;
    const customTrackingEvent = this.props.customTrackingEvent;
    if (context || customTrackingEvent) {
      const event = customTrackingEvent || `Clicked Previous ${context} Page`;
      const properties = this.props.logArrowDirectionClickInEvent ? { Direction: 'Prev' } : {};
      this.props.actions.logMetricsTrackingEvent(event, properties);
    }
    this.changePage('prev');
  };

  gotoNextPage = () => {
    const context = this.props.contextForTrackingOnPageChange;
    const customTrackingEvent = this.props.customTrackingEvent;
    if (context || customTrackingEvent) {
      const event = customTrackingEvent || `Clicked Next ${context} Page`;
      const properties = this.props.logArrowDirectionClickInEvent ? { Direction: 'Next' } : {};
      this.props.actions.logMetricsTrackingEvent(event, properties);
    }
    this.changePage('next');
  };

  changePage = (direction) => {
    direction === 'prev'
      ? this.props.actions.decrementHorizontalListPage()
      : this.props.actions.incrementHorizontalListPage();

    if (this.props.onPageChangeAction) {
      const itemsInView = this.returnIdsInView();
      this.props.onPageChangeAction(itemsInView);
    }
  };

  determineItemsPerPage = () => {
    const itemWidth = this.props.itemWidth;
    const paddingBetweenItems = this.props.paddingBetweenItems === undefined ? 25 : this.props.paddingBetweenItems;
    const paddingForArrows = this.props.paddingForArrows === undefined ? 50 : this.props.paddingForArrows;
    const containerWidth = this._returnContainerWidth() - 2 * paddingForArrows;
    return Math.floor((containerWidth + paddingBetweenItems) / (itemWidth + paddingBetweenItems));
  };

  determineItemPosition = (i, row) => {
    const paddingBetweenItems = this.props.paddingBetweenItems === undefined ? 25 : this.props.paddingBetweenItems;
    const paddingForArrows = this.props.paddingForArrows === undefined ? 50 : this.props.paddingForArrows;
    const itemWidth = this.props.itemWidth;
    const cardWidthTotalSpace = itemWidth + paddingBetweenItems;
    const cardsCanFitInRow = this.determineItemsPerPage();
    const containerWidth = this._returnContainerWidth() - 2 * paddingForArrows;
    const totalItems = this.returnListOfItems().length;

    const itemsPerPage = totalItems > cardsCanFitInRow ? cardsCanFitInRow : totalItems;
    const widthComponentPageWillTakeUp = itemsPerPage * cardWidthTotalSpace - paddingBetweenItems;

    const customOffset = 2;
    const startingOffset = (containerWidth - widthComponentPageWillTakeUp) / 2 + customOffset;

    const rowOffset = row;
    const top = '0px';
    const standardPosition = cardWidthTotalSpace * i + startingOffset;
    const left = `${standardPosition - rowOffset * (cardWidthTotalSpace * cardsCanFitInRow)}px`;

    return { top, left };
  };

  _returnHorizontalListStore = (props) => (props || this.props).horizontalList;

  _returnCurrentPage = (props) => this._returnHorizontalListStore(props).page;

  isFirstPage = () => this._returnCurrentPage() === 1;

  isLastPage = () => this._returnCurrentPage() === this.determineTotalPages();

  determineTotalPages = () => {
    const totalItems = this.props.items.length;
    return Math.ceil(totalItems / this.determineItemsPerPage());
  };

  _returnContainerWidth = () => {
    const offsetSoItemGetsCutoff = 100;
    let containerWidth = $(`#${this.props.id}`).width();
    if (!containerWidth) {
      containerWidth = this.props.windowWidth / 2; // can make this any width since this component will cutoff anything too long
    }
    return containerWidth + offsetSoItemGetsCutoff;
  };

  returnArrayOfLoadingItems = () => {
    const count = this.determineItemsPerPage() + 1;
    const loadingItemsArray = [];
    for (let i = 0; i < count; i++) {
      loadingItemsArray.push({});
    }
    return loadingItemsArray;
  };

  returnListOfItems = (props) => {
    props = props || this.props;
    const itemsArray = this.isListLoading(props) ? this.returnArrayOfLoadingItems() : props.items;
    if (!itemsArray) {
      return console.error(`Items prop is required`);
    }
    if (!Array.isArray(itemsArray)) {
      return console.error(`Items prop must be an array, received ${typeof itemsArray}`);
    }
    return itemsArray;
  };
}

const mapStateToProps = (state) => {
  return {
    horizontalList: state.horizontalList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(withWindowSize, connect(mapStateToProps, mapDispatchToProps))(HorizontalList);

export default composedComponent;
