import React from 'react';
import { throwError } from '../../../helpers/devToolHelpers';

class ClickEventComponent extends React.Component {
  componentDidMount() {
    if (!this.props.id) {
      throwError('A valid/unique ID must be supplied to ClickEventComponent');
    }
    this._setClickListener();
  }

  componentWillUnmount() {
    this._removeClickListener();
  }

  render() {
    return (
      <span id={this.props.id} className="click-wrapper">
        {this.props.children}
      </span>
    );
  }

  handleClick = (e) => {
    this.props.handleClick(e);
  };

  _onClick = (e) => {
    this.handleClick(e);
  };

  _setClickListener = () => {
    $(`#${this.props.id}`).on('click', this._onClick);
  };

  _removeClickListener = () => {
    $(window).off('click', `#${this.props.id}`, this._onClick);
  };
}

export default ClickEventComponent;
