import React from 'react';

class Page extends React.PureComponent {
  render() {
    return (
      <div
        className={`react-page-container component-bg ${this._showMobileCardStyling()} ${this.props.className || ''}`}
        style={this.props.overrideStyle || {}}
      >
        {this.props.children}
      </div>
    );
  }

  _showMobileCardStyling = () => (this.props.showMobileCardStyling ? 'mobile-page-card' : '');
}

export default Page;
