import React from 'react';
import { PRODUCT_NAME, PRODUCT_NAMES } from '@src/appConfig';
import { Container } from '@src/main/components/ui';
import { useCurrentUser, useIsOnboarding } from '@src/main/hooks/user';
import {
  currentUserMembershipMonthlyFeeAmount,
  currentUserMembershipMonthlyFreeTradeLimit,
} from '@src/helpers/currentUserHelpers';
import { Body5, CheckCircle } from '@src/main/lib/nvstr-common-ui.es';
import styled from 'styled-components';

const optimizerOneLineFeature = {
  text:
    'A smarter brokerage account with simple portfolio optimization based on independent, Nobel Prize-winning research.',
};
const explorerFeature = {
  text: 'Discover new stocks that diversify your portfolio.',
};
const communityFeature = {
  text: 'Connect with a community of intelligent investors.',
};
const learningContentFeature = {
  text: 'Personalized learning content with investable rewards.',
};
const ctlFeature = {
  text: 'Access to former Institutional Investor-ranked and multibillion dollar fund analysts.',
};
const proAndConsFeature = {
  text: 'Community insight on the pros and cons of different investments.',
};
const webOptimizerFeature = {
  text:
    'One-click portfolio optimization & rebalancing, based on independent, Nobel Prize-winning research. (web access)',
};
const theStreetFeature = {
  text: 'Personalized news and content from TheStreet',
};
const learnAndEarnFeature = {
  text: 'Learn & Earn with rewards to level-up your investing, whether you’re a beginner or a pro.',
};
const realtimeMarketDataFeature = {
  text: 'Real-time market data.',
};
const newsFeature = {
  text: 'News and updates tailored to your interests.',
};
const portfolioResourcesFeature = {
  text: 'Resources to support your portfolio & personal financial development.',
};
const expandedOptimizerFeature = {
  text: 'One-click portfolio optimization & rebalancing. (web access)',
  subBullets: [
    'Optimize your position sizing to seek maximum expected returns and diversification.',
    'Control for volatility, correlations, beta, and more.',
    'Based on independent Nobel Prize-winning research.',
  ],
};

const whiteLabelSpecificBullets = [];
if (PRODUCT_NAME === PRODUCT_NAMES.TheStreet) {
  whiteLabelSpecificBullets.push(theStreetFeature);
}

const legacyGroup = [portfolioResourcesFeature, learningContentFeature, newsFeature];
const controlGroup = [
  // optimizerOneLineFeature,
  explorerFeature,
  communityFeature,
  learningContentFeature,
  ...whiteLabelSpecificBullets,
];
const analystsGroup = [
  ctlFeature,
  proAndConsFeature,
  // webOptimizerFeature,
  learnAndEarnFeature,
  realtimeMarketDataFeature,
  ...whiteLabelSpecificBullets,
];
const toolsGroup = [
  // expandedOptimizerFeature,
  ctlFeature,
  communityFeature,
  learnAndEarnFeature,
  realtimeMarketDataFeature,
  ...whiteLabelSpecificBullets,
];

const buildFeaturesCopyListForTestGroup = (testGroup) => {
  if (testGroup === 0 || testGroup === 1 || testGroup === 2 || testGroup === 3 || testGroup === 4) {
    return legacyGroup;
  }
  if (testGroup === 5) {
    return controlGroup;
  }
  if (testGroup === 6) {
    return analystsGroup;
  }
  if (testGroup === 7) {
    return toolsGroup;
  }
  return toolsGroup;
};

const FeatureRowWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 400px;
  max-width: 100%;
`;
const FeatureIconWrapper = styled.div`
  padding-top: 2px;
  margin-right: 10px;

  svg {
    width: 18px;
    height: 18px;
  }

  path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;
const IconBulletListItem = ({ item }) => {
  const { text } = item;
  return (
    <FeatureRowWrapper>
      <FeatureIconWrapper>
        <CheckCircle width={20} />
      </FeatureIconWrapper>
      <Body5>{text}</Body5>
    </FeatureRowWrapper>
  );
};

const IconBulletList = ({ list }) => {
  const itemSpacing = 16;
  return list.map((item, i) => (
    <Container key={i} row bottom={i !== list.length - 1 ? itemSpacing : 0}>
      <IconBulletListItem item={item} />
    </Container>
  ));
};

export const PremiumAccountFeaturesList = ({ isLegacy }) => {
  const isOnboarding = useIsOnboarding();
  const currentUser = useCurrentUser();
  const membershipFee = currentUserMembershipMonthlyFeeAmount(currentUser);
  const onboarding_welcome_copy_test_group = currentUser.onboarding_welcome_copy_test_group || 0;
  const freeTradesPerMonth = currentUserMembershipMonthlyFreeTradeLimit(currentUser);
  const commissionTradesLineItem = {
    text: isLegacy
      ? `Up to ${freeTradesPerMonth} commission-free trades per month for $${membershipFee.toFixed(2)}/mo`
      : `Up to ${freeTradesPerMonth} commission-free trades per month`,
  };

  const featuresCopyList = isOnboarding
    ? buildFeaturesCopyListForTestGroup(onboarding_welcome_copy_test_group)
    : controlGroup;
  const [list] = React.useState([...featuresCopyList, commissionTradesLineItem]);
  return <IconBulletList list={list} icon={<CheckCircle />} />;
};
