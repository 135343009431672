import { searchAdapter } from '../adapters/searchAdapter';

import {
  ADD_SECURITIES,
  QUERY_SECURITIES,
  QUERY_RESULTS,
  CLEAR_QUERY,
  SHOW_MODAL,
  ADD_SYMBOL_LOOKUP,
} from '../constants';
import { createBasicErrorModal } from '../constants/modals';

export const querySecurities = (query) => {
  return function(dispatch) {
    dispatch({
      type: QUERY_SECURITIES,
      payload: query,
    });

    return searchAdapter.querySecurities(query).then((response) => {
      const wasSuccess = response && response.data && response.data.securities;
      if (wasSuccess) {
        const securities = response.data.securities;
        dispatch({
          type: ADD_SECURITIES,
          payload: securities.map((security) => ({
            ...security,
            security_id: security.id,
          })),
        });
        dispatch({
          type: ADD_SYMBOL_LOOKUP,
          payload: securities.map((security) => ({
            [security.symbol]: security.id,
          })),
        });
        dispatch({
          type: QUERY_RESULTS,
          payload: {
            results: securities,
            query,
          },
        });
        return {
          ok: wasSuccess,
          results: securities,
        };
      } else {
        const errorModal = {
          contentComponent: createBasicErrorModal('Something went wrong. Please try again.'),
          size: 'wide',
          dismissable: true,
        };
        dispatch({
          type: SHOW_MODAL,
          payload: errorModal,
        });
        return {
          error: true,
        };
      }
    });
  };
};

export const clearQuery = () => {
  return function(dispatch) {
    dispatch({
      type: CLEAR_QUERY,
    });
  };
};
