import { SHOW_ACTION_MESSAGE, DISMISS_ACTION_MESSAGE } from '../constants';
import { consoleError } from '../helpers/devToolHelpers';

const defaultState = {
  displayingMessagesList: [],
};

export default function actionMessageReducer(state = defaultState, action) {
  let messageData = action.payload || {};
  let messageDataId = messageData.id;
  let currentActionMessageList = state.displayingMessagesList;

  switch (action.type) {
    case SHOW_ACTION_MESSAGE:
      if (currentActionMessageList.some((messageData) => messageData.id === messageDataId)) {
        consoleError(`Ignoring 'SHOW_ACTION_MESSAGE' action, duplicate id found in actionMessage store`, state);
        return state;
      } else {
        return {
          ...state,
          displayingMessagesList: [...currentActionMessageList, messageData],
        };
      }

    case DISMISS_ACTION_MESSAGE:
      // messageData must have an id to dismiss
      return {
        ...state,
        displayingMessagesList: [...currentActionMessageList.filter((messageData) => messageData.id !== messageDataId)],
      };

    default:
      return state;
  }
}
