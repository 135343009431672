import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../actions/index';

import { ThoughtFeedItem } from '../Thoughts/ThoughtFeedItem';
import IconButton from '../../components/buttons/IconButton';
import MeasureSize from '../ExtraFunctionalityComponents/MeasureSize';

import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import { throwError } from '../../helpers/devToolHelpers';

/*
  logFirstItemView -> bool - determines if should log view of first item in list
  wrapToBeginning -> bool - if user gets to end of list and advances, go to beginning
  hideAdvanceOnLastItem -> bool - hides advance button if viewing last item in list
 */

class DynamicHeightCarousel extends React.Component {
  constructor() {
    super();
    this.state = {
      _viewingIndex: 0,

      _renderedItemHeightTrackingLookup: {},
    };
  }

  componentDidMount() {
    this._initListeners();

    if (this._returnItemsListCount() === 1) {
      this._onViewItem(true, this._returnViewingIndex());
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this._returnItemsListCount(prevProps) < this._returnItemsListCount()) {
      // if the length of list increases, set viewing index to last item in list
      this.onListCountIncrease();
    }

    const didViewingIndexChange = this._returnViewingIndex(prevState) !== this._returnViewingIndex();
    if (didViewingIndexChange) {
      this._onViewItem(false, this._returnViewingIndex());
    }

    const didViewFirstItemAfterInit = this._returnItemsListCount(prevProps) === 0 && this._returnItemsListCount() === 1;
    if (didViewFirstItemAfterInit) {
      this._onViewItem(true, this._returnViewingIndex());
    }
  }

  componentWillUnmount() {
    this._removeListeners();
  }

  render() {
    if (this._returnItemsListCount() === 0) {
      return null;
    }
    return (
      <div ref={(el) => (this._containerElNode = el)} className={'dynamic-height-carousel thoughts-feed-container'}>
        <div
          className={'thoughts-feed-sliding-pane'}
          style={{
            width: this._getPaneWidth(),
            transform: `translateX(${this._returnPaneXPosition()}px)`,
            ...this._returnAdditionalPaneStylingAttributes(),
          }}
        >
          {this._returnItemsList().map((itemData, i) => this._renderItem(itemData, i))}
        </div>
      </div>
    );
  }

  _getPaneWidth = () => {
    const width = this._returnContainerWidth() * (this._returnItemsList() || []).length;
    return isNaN(width) ? '100%' : width;
  };

  _renderCarouselControls = () => {
    if ('renderCustomCarouselControls' in this.props) {
      return this.props.renderCustomCarouselControls(
        this._renderPrevButton,
        this._renderNextButton,
        this._shouldRenderShowPreviousButton(),
        this._shouldRenderShowAdvanceButton()
      );
    }
    return (
      <div className={'dynamic-height-carousel-controls-container'}>
        {this._shouldRenderShowPreviousButton() && this._renderPrevButton()}
        {this._shouldRenderShowAdvanceButton() && this._renderNextButton()}
      </div>
    );
  };

  _renderPrevButton = () => {
    return (
      <IconButton
        icon="fa fa-angle-left"
        colorClassName={'primary-CTA-btn-text-color'}
        size="large"
        customClass="circle-button prev-button"
        handleClick={this._handlePreviousClick}
      />
    );
  };

  _renderNextButton = () => {
    return (
      <IconButton
        icon="fa fa-angle-right"
        colorClassName={'primary-CTA-btn-text-color'}
        size="large"
        customClass="circle-button next-button"
        handleClick={this._handleAdvanceClick}
      />
    );
  };

  _renderItem = (itemData, i) => {
    return (
      <div
        key={`carousel-item-${this.props.returnIdFromItemData(itemData)}-${i}`}
        className={'dynamic-height-carousel-item thought-feed-thought-container'}
        style={{
          width: `${this._returnContainerWidth()}px`,
        }}
      >
        <MeasureSize onMutation={this._bindIndexToHandleMeasureSizeEvent(i)} setInitialSize updateShortlyAfterMount>
          <ThoughtFeedItem index={i} data={itemData} {...this.props.itemProps} />
          {this._renderCarouselControls()}
        </MeasureSize>
      </div>
    );
  };

  _isItemActive = (itemIndex) => itemIndex === this._returnActiveIndex();

  _returnActiveIndex = () => this._returnViewingIndex();

  _isViewingLastItem = () => this._returnViewingIndex() + 1 === this._returnItemsListCount();

  _handlePreviousClick = () => {
    this.props.handlePreviousClick(this._decrementViewingIndex, this._returnViewingIndex());
    if (this.props.additionalPreviousClickAction) {
      this.props.additionalPreviousClickAction();
    }
    // this._logPreviousClick();
  };

  _handleAdvanceClick = () => {
    this.props.handleAdvanceClick(this._incrementViewingIndex, this._returnViewingIndex());
    if (this.props.additionalAdvanceClickAction) {
      this.props.additionalAdvanceClickAction();
    }
    // this._logAdvanceClick();
  };

  onListCountIncrease = () => {
    this._setViewingIndexToLastItem();
  };

  _allowAdvanceOnLastItem = () => this.props.hideAdvanceOnLastItem;

  _isAdvanceRestricted = () => {
    if (this._allowAdvanceOnLastItem()) {
      return this._isViewingLastItem();
    }
    return this._areNoMoreItems() && !this._shouldWrapToBeginning() && this._isViewingLastItem();
  };

  _shouldWrapToBeginning = () => this.props.wrapToBeginning;

  _canAdvance = () => {
    if (this._isNoMoreItemsAndOneItemInList()) {
      return false;
    }
    return !this._isViewingLastItem() || (this._isViewingLastItem() && !this._isAdvanceRestricted());
  };

  _canPrevious = () => this._returnViewingIndex() > 0;

  _isNoMoreItemsAndOneItemInList = () => this._returnItemsListCount() === 1 && this._areNoMoreItems();

  _areNoMoreItems = () => this.props.areNoMoreItems;

  _shouldRenderShowPreviousButton = () => this._canPrevious();

  _shouldRenderShowAdvanceButton = () => this._canAdvance();

  _returnItemsListCount = (props) => this._returnItemsList(props).length;

  _returnItemsList = (props) => (props || this.props).list;

  _returnViewingIndex = (state) => (state || this.state)._viewingIndex;

  _setViewingIndexToStart = () => {
    if (this._returnItemsListCount() === 0) {
      return throwError('There are no items to show', true, {
        state: this.state,
        props: this.props,
      });
    }
    this.setState((prevState) => ({
      _viewingIndex: 0,
    }));
  };

  _setViewingIndexToLastItem = () => {
    if (this._returnItemsListCount() === 0) {
      return throwError('There are no items to show', true, {
        state: this.state,
        props: this.props,
      });
    }
    this.setState((prevState) => ({
      _viewingIndex: this._returnItemsListCount() - 1,
    }));
  };

  _incrementViewingIndex = () => {
    if (this._returnViewingIndex() === this._returnItemsListCount() - 1) {
      if (!this._shouldWrapToBeginning()) {
        return throwError('Cannot increment viewing index past list count, there will be no item to show', true, {
          state: this.state,
          props: this.props,
        });
      }
      this._setViewingIndexToStart();
    } else {
      this.setState((prevState) => ({
        _viewingIndex: prevState._viewingIndex + 1,
      }));
    }
  };

  _decrementViewingIndex = () => {
    if (this._returnViewingIndex() === 0) {
      return throwError('Cannot decrement viewing index past 0', true, {
        state: this.state,
        props: this.props,
      });
    }
    this.setState((prevState) => ({
      _viewingIndex: prevState._viewingIndex - 1,
    }));
  };

  _initListeners = () => {
    this._addResizeListener();
  };

  _removeListeners = () => {
    this._removeResizeListener();
  };

  _addResizeListener = () => {
    window.$(window).on('resize', this._handleResize);
  };

  _removeResizeListener = () => {
    const $window = window.$(window);
    $window.off('resize', this._handleResize);
  };

  _bindIndexToHandleMeasureSizeEvent = (index) => (sizing) => this._handleMeasureSizeEvent(index, sizing);

  _handleMeasureSizeEvent = (index, sizing) => this._setContainerHeight(index, sizing.height);

  _setContainerHeight = (index, height) => {
    this.setState((prevState) => ({
      _renderedItemHeightTrackingLookup: {
        ...prevState._renderedItemHeightTrackingLookup,
        [index]: height,
      },
    }));
  };

  _returnPaneWidth = () => (this._returnContainerWidth() + 20) * this._returnItemsListCount();

  _returnPaneXPosition = () => this._returnContainerWidth() * this._returnViewingIndex() * -1;

  _returnAdditionalPaneStylingAttributes = () =>
    this._shouldUseFixedHeight()
      ? {
          height: `${this._returnContainerHeight()}px`,
        }
      : {};

  _shouldUseFixedHeight = () => !isUndefinedOrNull(this._returnContainerHeight());

  _returnThoughtsRenderedListHeightLookup = () => this.state._renderedItemHeightTrackingLookup;

  _returnContainerHeight = () => this._returnThoughtsRenderedListHeightLookup()[this._returnViewingIndex()];

  _returnContainerWidth = () => {
    const width = this._$returnContainerNode().width();
    return width;
  };

  _$returnContainerNode = () => window.$(this._returnContainerNode());

  _returnContainerNode = () => this._containerElNode;

  _handleResize = () => {
    this.setState(() => ({
      containerWidth: this._returnContainerWidth(),
    }));
  };

  _onViewItem = (isFirstItemView, viewingIndex) => {
    if (!isFirstItemView || (isFirstItemView && this.props.logFirstItemView)) {
      const itemData = this._returnItemsList()[viewingIndex];
      this._logViewItem(itemData);
    }
  };

  _logViewItem = (itemData) => {
    if (this.props.additionalViewItemAction) {
      this.props.additionalViewItemAction(itemData);
    }
  };

  _logPreviousClick = () => {
    // TODO: currently implemented thru props ie. additionalPreviousClickAction
    throwError('not implemented');
  };

  _logAdvanceClick = () => {
    // TODO: currently implemented thru props ie. additionalAdvanceClickAction
    throwError('not implemented');
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(null, mapDispatchToProps))(DynamicHeightCarousel);
export default composedComponent;
