import React from 'react';
import { connect } from 'react-redux';

import { fetchAllViewpointData, logMetricsTrackingEvent, viewViewpointOrgProfile } from '../../actions';

import { getViewpointOrgDataFromState } from '../../selectors/viewpoints';

import PageLoading from '../../components/PageLoading';
import Page from '../../components/layout/Page';
import ProfileAvatar from '../../components/user/ProfileAvatar';
import UserName from '../../components/user/UserName';
import ViewpointSubscribeButton, { VIEWPOINT_SUBSCRIBE_BUTTON_TYPES } from './ViewpointSubscribeButton';
import CanSubscribe from './CanSubscribe';
import ViewpointProfilePosts from './ViewpointProfilePosts';
import LinkTo from '../Links/LinkTo';

import {
  getAvatarUrlFromViewpointOrg,
  getDescriptionFromViewpointOrg,
  getDisplayNameFromViewpointOrg,
  getInitialsFromViewpointOrg,
  isSubscribedToViewpointOrg,
} from '../../helpers/viewpointHelpers';
import { parseQueryString } from '../../helpers/routerHelpers';
import { PRODUCT_DISPLAY_FULL_NAME } from '../../appConfig';

class ViewpointProfile extends React.PureComponent {
  componentDidMount() {
    const { viewpointOrg, dispatch } = this.props;

    if (viewpointOrg === null) {
      fetchAllViewpointData()(dispatch);
    } else {
      this.logView();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { viewpointOrg } = this.props;

    if (this._didLogView && viewpointOrg !== null) this.logView();
  }

  render() {
    const {
      viewpointOrg,
      // names,
    } = this.props;

    if (viewpointOrg === null) {
      return (
        <div>
          {this.renderHeaderBuffer()}
          <PageLoading />
        </div>
      );
    }

    const { id } = viewpointOrg;

    const overrideStyle = {
      maxWidth: '950px',
    };

    const description = getDescriptionFromViewpointOrg(viewpointOrg);

    return (
      <div>
        {this.renderHeaderBuffer()}
        <Page overrideStyle={overrideStyle}>
          <div className={'viewpoint-profile-container'}>
            <div className={'viewpoint-profile-header'}>
              <ProfileAvatar
                viewpointOrgId={id}
                isViewpointOrg
                imageSrc={getAvatarUrlFromViewpointOrg(viewpointOrg)}
                initials={getInitialsFromViewpointOrg(viewpointOrg)}
                size={'large'}
                withLink={false}
              />
              <div className={'viewpoint-name'} style={{ paddingLeft: '15px' }}>
                <UserName
                  viewpointOrgId={id}
                  isViewpointOrg
                  displayText={getDisplayNameFromViewpointOrg(viewpointOrg)}
                  withLink={false}
                />
              </div>
            </div>

            <div
              className={'text-center viewpoint-description-container'}
              style={{ margin: '10px auto 0 auto', maxWidth: '600px' }}
            >
              <span className={'secondary-text-color'}>{description}</span>
            </div>

            <div style={{ margin: '24px auto 0 auto', maxWidth: '500px' }}>
              <ViewpointProfilePosts viewpointId={id} />
            </div>

            <CanSubscribe id={id}>
              <div className={'text-center subscribe-action-container flex-center-child'}>
                <div className={'dotted-line-banner border-accent'} style={{ marginTop: '15px' }}>
                  {this._isAuthed() ? (
                    <span className={' '}>Get access to all their premium content when you subscribe.</span>
                  ) : (
                    <span className={' '}>
                      Get access to all their premium content when you signup for an {PRODUCT_DISPLAY_FULL_NAME} account
                      and subscribe.
                    </span>
                  )}
                  {this._isAuthed() ? (
                    <div style={{ paddingTop: '15px' }}>
                      <ViewpointSubscribeButton id={id} buttonType={VIEWPOINT_SUBSCRIBE_BUTTON_TYPES.SOLID} />
                    </div>
                  ) : (
                    <div style={{ paddingTop: '15px' }}>
                      <LinkTo
                        to="/signup"
                        className="btn btn-primary-color btn-small-tall"
                        text="Sign Up"
                        fullPageLoad
                      />
                    </div>
                  )}
                </div>
              </div>
            </CanSubscribe>
          </div>
        </Page>
      </div>
    );
  }

  _isAuthed = () => this.props.isAuthed;

  renderHeaderBuffer = () => {
    if (this._isAuthed()) return null;

    return <div style={{ paddingTop: '75px' }}></div>;
  };

  logView = () => {
    const { id, viewpointOrg, dispatch } = this.props;

    this._didLogView = true;

    const eventName = 'View Viewpoint Profile';
    const properties = {
      ID: id,
      'Is Subscribed': isSubscribedToViewpointOrg(viewpointOrg),
    };
    logMetricsTrackingEvent(eventName, properties)();

    const query = parseQueryString(window.location.search);
    viewViewpointOrgProfile(id, query.context);
  };
}

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;

  if (id === null)
    return {
      viewpointOrg: null,
    };

  return {
    isAuthed: state.authentication.isAuthenticated,
    viewpointOrg: getViewpointOrgDataFromState(state, id),
  };
};

export default connect(mapStateToProps)(ViewpointProfile);
