import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { hideVerticalNav, logMetricsTrackingEvent } from '../../../actions';

import LinkTo from '../../Links/LinkTo';
import Icon from '../../../components/misc/Icon';
import HighlightComponent from '../../UI/Tour/HighlightComponent';
import { Body5 } from '../../../main/lib/nvstr-common-ui.es';
import { isUndefinedOrNull } from '../../../helpers/usefulFuncs';

const CustomIconWrap = styled('span')`
  padding: 0 10px 0 0;

  svg {
    height: 18px;
    width: 18px;
  }
`;

const NewRouteWrapper = styled.span`
  color: ${({ theme }) => theme.themeColors.primaryCtaButton};
`;

const Wrapper = styled.span`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-size: 14px;
  font-weight: 300;
  border: none;
  transition: all 0.15s ease-out;
  cursor: pointer;

  padding: 8px 8px 8px 10px;

  background: transparent;

  .react-vertical-nav-item-name {
    color: ${({ theme }) => theme.themeColors.text};
    fill: ${({ theme }) => theme.themeColors.text};
  }
  * {
    fill: ${({ theme }) => theme.themeColors.text};
  }

  a:hover,
  a:active,
  a:visited {
    background: ${({ theme }) => theme.themeColors.text};
    color: ${({ theme }) => theme.themeColors.component};

    * {
      color: ${({ theme }) => theme.themeColors.component};
      fill: ${({ theme }) => theme.themeColors.component};
    }
  }

  &.active-nav-item {
    background: ${({ theme }) => theme.themeColors.text};

    * {
      color: ${({ theme }) => theme.themeColors.component};
      fill: ${({ theme }) => theme.themeColors.component};
    }
  }

  span {
    display: block;
  }

  .react-vertical-nav-item-icon {
    width: 28px;
    line-height: 0.8;
    text-align: center;
  }
`;

const BadgeWrapper = styled.div`
  background: ${({ theme }) => theme.themeColors.primaryCtaButton};
  height: 16px;
  width: 16px;
  border-radius: 16px;

  position: absolute;
  top: 9px;
  right: 10px;
  z-index: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  * {
    font-size: 10px !important;
    line-height: 12px !important;
    color: ${({ theme }) => theme.themeColors.buttonText};
  }
`;

const Badge = ({ count }) => {
  return <BadgeWrapper>{count > 0 && <Body5>{count}</Body5>}</BadgeWrapper>;
};

const VerticalNavItem = (props) => {
  const {
    name,
    route,
    query: _props_query,
    icon,

    newRoute,
    shouldMatchFirstSubDir,
    subDir,
    accentedTextColor,
    customAdditionalElement,
    badgeCount,

    highlightOnTour,

    shouldUseWindowToNavigate,
    additionalClickAction,

    noCollapse,

    customIcon,
  } = props;

  const dispatch = useDispatch();
  const nav = useSelector((state) => state.nav);
  const { alwaysOpen } = nav;

  const location = useLocation();
  const { pathname } = location;

  const [isHovered, setIsHovered] = useState(false);

  const query = _props_query || '';
  const isActiveRoute = pathname === route;
  const isActiveRouteThruSubDir = shouldMatchFirstSubDir && pathname.indexOf(`/${subDir}/`) >= 0;

  const showHighlight = isActiveRoute || isActiveRouteThruSubDir || isHovered;

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const logVerticalNavItemClick = () => {
    const event = 'Clicked Vertical Nav Item';
    const properties = {
      tabName: name,
    };
    logMetricsTrackingEvent(event, properties)();
  };

  const logNotificationsRouteClick = () => {
    const event = 'Notifications Icon Clicked';
    const properties = {
      Context: 'Vertical Nav',
    };
    logMetricsTrackingEvent(event, properties)();
  };

  const collapseNav = () => {
    if (!alwaysOpen && !noCollapse) {
      hideVerticalNav()(dispatch);
    }
  };

  const handleClick = () => {
    collapseNav();
    additionalClickAction && additionalClickAction();
    logVerticalNavItemClick(name);
    name === 'Notifications' && logNotificationsRouteClick();
  };

  const returnLinkConstructorData = () => {
    return {
      type: LinkTo,
      props: {
        to: route + query,
        additionalClickAction: handleClick,
        child: returnChildrenContainer(),
        onMouseOver: handleMouseOver,
        onMouseOut: handleMouseOut,
        scrollToTop: true,
        noTextDecoration: true,
      },
    };
  };

  const returnAnchorConstructorData = () => {
    return {
      type: 'a',
      props: {
        className: 'no-text-decoration',
        href: route + query,
        onClick: handleClick,
        onMouseEnter: handleMouseOver,
        onMouseLeave: handleMouseOut,
        target: '_blank',
      },
    };
  };

  const returnSpanConstructorData = () => {
    return {
      type: 'span',
      props: {
        className: 'no-text-decoration',
        onClick: handleClick,
        onMouseEnter: handleMouseOver,
        onMouseLeave: handleMouseOut,
      },
    };
  };

  const returnChildrenContainer = () => {
    return (
      <Wrapper
        className={`
          vertical-nav-group-item-container
          vertical-nav-group-item
          ${showHighlight ? 'active-nav-item' : ''}
          ${accentedTextColor ? 'accented-nav-item' : ''}
        `}
      >
        {highlightOnTour && <HighlightComponent {...highlightOnTour} />}

        {customIcon ? (
          <CustomIconWrap>{customIcon}</CustomIconWrap>
        ) : (
          <span className={`react-vertical-nav-item-icon`}>
            <Icon icon={icon} size="nav" background="rgba(0,0,0,0)" />
          </span>
        )}
        <span className={`react-vertical-nav-item-name`}>
          <span className={`spacing-wrapper`}>
            {name}
            {newRoute && <NewRouteWrapper className="new-route-container new-nav-route-color">NEW</NewRouteWrapper>}
          </span>
        </span>
        {customAdditionalElement}
        {!isUndefinedOrNull(badgeCount) && <Badge count={badgeCount} />}
      </Wrapper>
    );
  };

  const createReactRouterLink = () => {
    const constructorData = route
      ? shouldUseWindowToNavigate
        ? returnAnchorConstructorData()
        : returnLinkConstructorData()
      : returnSpanConstructorData();

    return React.createElement(constructorData.type, constructorData.props, returnChildrenContainer());
  };

  return createReactRouterLink();
};

export default VerticalNavItem;
