import React from 'react';
import withWindowSize from '../../HOCS/withWindowSize';

import { sendThoughtViewEvent } from '../../../actions/thoughtsActions';

import { wasThoughtImpressionRecorded, saveImpressionToStorageIfUnrecorded } from '../../../helpers/thoughtsHelpers';
import { logMetricsTrackingEvent } from '../../../actions';
import { TrackIteratively } from '../../../utils/itly';
import { compose } from 'redux';
import { connect } from 'react-redux';

/******************
  ignoreImpressionTracking -> bool -> won't send events ******* used as a render bool in ThoughtContainer wrapping this component

  <ThoughtImpressionTracker
    thoughtId -> required
    scrollContainer -> selector for scroll container to listen to for scroll view impression based tracking
    recordImpressionOnMount -> bool -> will only record an impression on mount
  />

*******************/

export class ThoughtImpressionTracker extends React.Component {
  componentDidMount() {
    this._initialize();
  }

  componentWillUnmount() {
    this._terminate();
  }

  render() {
    return <div ref={(el) => (this.ThoughtImpressionTracker = el)}></div>;
  }

  _checkForImpression = () => {
    if (!this._wasImpressionRecorded() && this._isThoughtVisibleWithinWindow()) {
      this._handleRecordImpression();
    }
  };

  _isThoughtVisibleWithinWindow = () => {
    const rect = this._getThoughtBoundingClientRect();
    const distanceThoughtMidPointFromTopOfWindow = rect.top + rect.height / 2;
    const wasScrolledPast = rect.top + rect.height < 0;
    const isAboveBottomOfWindow = distanceThoughtMidPointFromTopOfWindow < this._returnWindowHeight();
    return isAboveBottomOfWindow && !wasScrolledPast;
  };

  _wasImpressionRecorded = () =>
    wasThoughtImpressionRecorded('view', this._returnThoughtId(), this.props.viewingContext);

  _saveImpressionToStorageIfUnrecorded = () =>
    saveImpressionToStorageIfUnrecorded('view', this._returnThoughtId(), this.props.viewingContext);

  _postViewThoughtImpression = () =>
    sendThoughtViewEvent(
      this._returnThoughtId(),
      this.props.viewingContext,
      this.props.metadata,
      this.props.notificationIndex
    )();

  _handleRecordImpression = () => {
    if (this._wasImpressionRecorded() || this._isRecordingImpression) {
      return false;
    }
    this._isRecordingImpression = true; // stops attempts at recording more than once, but if request fails has the flexibility to send again

    const metadata = this.props.metadata || {};
    const { algorithm_type, feed_sequence_type, reason_in_feed } = metadata;
    const event = 'View Thought';
    const properties = {
      'Feed Sequence': algorithm_type,
      'Top of Feed Algorithm': feed_sequence_type,
      'Reason In Feed': reason_in_feed,
      Context: this.props.viewingContext,
    };
    logMetricsTrackingEvent(event, properties)();
    this._logItlyProConViewed();
    this._postViewThoughtImpression().then((response) => {
      if (response && response.data && response.data.status === 'success') {
        this._saveImpressionToStorageIfUnrecorded();
      } else {
        this._isRecordingImpression = false;
      }
    });
  };

  _logItlyProConViewed = () => {
    const { thought_type, securitySymbol, viewingContext } = this.props;
    const properties = {
      context: viewingContext,
      pro_or_con: thought_type?.toLowerCase() || '',
      ticker_symbol: securitySymbol || '',
      url: window.location.pathname,
      url_query: window.location.search,
    };
    TrackIteratively.viewed.PRO_CON_VIEWED.send(properties);
  };

  _initialize = () => {
    if (this.props.recordImpressionOnMount) {
      this._handleRecordImpression();
    } else {
      this._initScrollListener();
      // allow for element to animate height if needed before checking for impressions
      setTimeout(this._checkForImpression(), 600);
    }
  };

  _terminate = () => {
    this._removeScrollListener();
  };

  _handleScroll = (e) => {
    this._checkForImpression(e);
  };

  _getThoughtBoundingClientRect = () => {
    const el = this._$returnThoughtContainerElement();
    return el && el[0] ? el[0].getBoundingClientRect() : null;
  };

  _$returnThoughtContainerElement = () => $(this._returnDOMNode()).closest('.thought-container');

  _returnDOMNode = () => this.ThoughtImpressionTracker;

  _initScrollListener = () => {
    this._$returnScrollElement().on('scroll', this._handleScroll);
  };

  _returnWindowHeight = () => this.props.windowHeight || $(window).height();

  _removeScrollListener = () => {
    this._$returnScrollElement().off('scroll', this._handleScroll);
  };

  _$returnScrollElement = () => $(this.props.scrollContainerSelector);

  _returnThoughtId = () => this.props.thoughtId;
}

const mapStateToProps = (state, ownProps) => {
  const security = state.thoughts.securityLookup[ownProps.securityId];

  if (security) {
    const thought_type = security.thoughtLookup[ownProps.thoughtId]?.thought_type?.name;
    return {
      thought_type,
    };
  }
  return {};
};

export default compose(withWindowSize, connect(mapStateToProps))(ThoughtImpressionTracker);
