import { paymentAdapter } from '../adapters/paymentAdapter';

import { SHOW_MODAL } from '../constants';
import { createBasicErrorModal } from '../constants/modals';

export const getSubscriptionConfig = () => {
  return paymentAdapter.getSubscriptionConfig().then((response) => {
    if (response && response.data && 'available_plans' in response.data) {
      return {
        ok: true,
        ...response.data,
      };
    } else {
      return {
        error: 'Something went wrong. Please try again.',
      };
    }
  });
};

export const getANetKeys = () => {
  return paymentAdapter.getANetKeys().then((response) => {
    if (response && response.data && 'client_key' in response.data && 'login_id' in response.data) {
      return {
        ok: true,
        client_key: response.data.client_key,
        login_id: response.data.login_id,
      };
    } else {
      return {
        ok: false,
        error: true,
        errorMessage: 'Something went wrong. Please refresh and try again.',
      };
    }
  });
};

export const sendAnetCreditCardPaymentTokenForSubscriptionPayment = (token, billingInfo) => {
  return paymentAdapter.sendAnetCreditCardPaymentTokenForSubscriptionPayment(token, billingInfo);
};

export const getSubscriptionData = () => {
  return function (dispatch) {
    return paymentAdapter.getSubscriptionData().then((response) => {
      if (response && response.data && response.data.billing_info) {
        return {
          ok: true,
          ...response.data,
        };
      } else {
        const modalMessage = 'Something went wrong. Please refresh and try again.';
        const modal = {
          contentComponent: createBasicErrorModal(modalMessage),
          dismissable: true,
          size: 'wide',
        };
        dispatch({
          type: SHOW_MODAL,
          payload: modal,
        });
        return {
          error: true,
          errorMessage: 'Something went wrong. Please refresh and try again.',
        };
      }
    });
  };
};

export const updateSubscriptionData = (token, billingInfo) => {
  return function (dispatch) {
    return paymentAdapter.updateSubscriptionData(token, billingInfo).then((response) => {
      if (response && response.data && response.data.status && response.data.status === 'success') {
        return {
          ok: true,
          ...response.data,
        };
      } else {
        const modalMessage =
          (response && response.data && response.data.error) || 'Something went wrong. Please refresh and try again.';
        const modal = {
          contentComponent: createBasicErrorModal(modalMessage),
          dismissable: true,
          size: 'wide',
        };
        dispatch({
          type: SHOW_MODAL,
          payload: modal,
        });
        return {
          error: true,
          errorMessage: modalMessage,
        };
      }
    });
  };
};

export const cancelSubscription = () => {
  return paymentAdapter.cancelSubscription().then((response) => {
    if (response && response.data && response.data.status && response.data.status === 'success') {
      return {
        ok: true,
        ...response.data,
      };
    } else {
      const error = response
        ? response.data
          ? response.data.error
          : response.error
        : 'Something went wrong. Please try again.';
      return {
        error,
        response,
      };
    }
  });
};

export const getPaymentSourcesData = (config) => {
  return function (dispatch) {
    return paymentAdapter.getPaymentSourcesData().then((response) => {
      if (response && response.data && response.data.payment_sources) {
        return {
          ok: true,
          ...response.data,
        };
      } else {
        if (config && config.ignoreError) {
          return { error: true };
        } else {
          const modalMessage = 'Something went wrong. Please refresh and try again.';
          const modal = {
            contentComponent: createBasicErrorModal(modalMessage),
            dismissable: true,
            size: 'wide',
          };
          dispatch({
            type: SHOW_MODAL,
            payload: modal,
          });
          return {
            error: true,
            errorMessage: 'Something went wrong. Please refresh and try again.',
          };
        }
      }
    });
  };
};

export const sendAnetCreditCardPaymentTokenForPaymentSource = (token, billingInfo) => {
  return paymentAdapter.sendAnetCreditCardPaymentTokenForPaymentSource(token, billingInfo);
};
