export const STORAGE_KEYS = {
  EQUITY_CHART_PERFORMANCE_CHART_PERFORMANCE_SECURITIES: 'EQUITY_CHART_PERFORMANCE_CHART_PERFORMANCE_SECURITIES',
  DISMISSED_FINISH_SETTING_UP_ACCOUNT_MODAL: 'DISMISSED_FINISH_SETTING_UP_ACCOUNT_MODAL',
  DISMISSED_FUND_BANK_ACCOUNT_BANNER: 'DISMISSED_FUND_BANK_ACCOUNT_BANNER',
  SOCURE_UPLOAD_COOLDOWN: 'SOCURE_UPLOAD_COOLDOWN',

  DISMISSED_MEMBERSHIP_OPT_IN_BANNER: 'DISMISSED_MEMBERSHIP_OPT_IN_BANNER',

  submittedApexAccount: 'SAA',

  USING_CUSTOM_THEME: 'USING_CUSTOM_THEME',
  PREVIOUS_CUSTOM_THEME: 'PREVIOUS_CUSTOM_THEME',
};

export const getItemFromStorage = (key) => {
  const value = window.localStorage.getItem(key);
  return typeof value === 'string' ? value : value !== null ? JSON.parse(value) : null;
};

export const setItemToStorage = (key, value) => {
  const storageValue = typeof value === 'string' ? value : JSON.stringify(value);
  window.localStorage.setItem(key, storageValue);
};

export const clearItemFromStorage = (key) => {
  window.localStorage.removeItem(key);
};

export const INTERNAL_STORAGE_KEYS = {
  SOCURE_REVIEW_STATUS: 'socureReviewStatus',
  SOCURE_SESSION_RISK_ID: 'socureSessionRiskId',

  NAVIGATION_INTENT: 'nav-intent',
  EMAIL: 'e',
};

/*
  <item> => temporary storage outside of redux for use when dispatch is not always available or implementation doesn't require redux
    {
      isInitialized: <bool> - has data been set
      data: <any> - stored data
    }
*/

class _InternalStorage {
  constructor() {
    this._storage = this._initStorage();

    this._listenersLookupByKey = {};
    this._listenerIdCount = 0;
  }

  /*
    Storage Methods
  */

  getItemFromStorage = (key) => this._storage[key];

  setItemInStorage = (key, value) => {
    const newValue = {
      isInitialized: true,
      data: value,
    };
    this._storage[key] = newValue;
    this._runListeners(key, newValue);
  };

  clearItemInStorage = (key) => {
    this._storage[key] = {
      isInitialized: false,
      data: null,
    };
  };

  /*
    Listener Methods
  */

  registerDataChangeListener = (key, cb) => {
    const listener = this._createListener(key, cb);
    this._addListenerToLookup(key, listener);
    const currentValue = this.getItemFromStorage(key);
    return { listener, currentValue };
  };

  removeDataChangeListener = (listener) => {
    const listeners = this._getListenersOfKey(listener.key);
    if (Array.isArray(listeners)) listeners.filter((l) => l.id !== listener.id);
  };

  /*
    "Private" Methods
  */

  _initStorage = () => {
    const storage = {};
    const storageKeys = Object.keys(INTERNAL_STORAGE_KEYS);
    storageKeys.forEach((k) => {
      const keyName = INTERNAL_STORAGE_KEYS[k];
      storage[keyName] = {
        isInitialized: false,
        data: null,
      };
    });
    return storage;
  };

  _runListeners = (key, newValue) => {
    const listeners = this._getListenersOfKey(key);
    if (Array.isArray(listeners)) listeners.forEach((l) => l.run(newValue));
  };

  _getListenersOfKey = (key) => this._listenersLookupByKey[key];

  _addListenerToLookup = (key, listener) => {
    Array.isArray(this._listenersLookupByKey[key])
      ? this._listenersLookupByKey[key].push(listener)
      : (this._listenersLookupByKey[key] = [listener]);
  };

  _createListener = (key, cb) => {
    const id = ++this._listenerIdCount;
    const listener = {
      id,
      key,
      run: cb,
    };
    return listener;
  };
}

export const InternalStorage = new _InternalStorage();
