import { Body5, Body6, convertHexToRGBA } from '../../lib/nvstr-common-ui.es';
import { formatLocaleString } from '../../../helpers/generalHelpers';
import { useDispatch } from 'react-redux';
import React from 'react';
import { getDepositPickerAmounts, getFundingRewardAmount } from '../../../actions';
import styled from 'styled-components';
import { formatForDisplay } from '../../../helpers/displayHelpers';
import UnstyledLoadingSpinner from '../Images/UnstyledLoadingSpinner';

export const DepositOptionsBody = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
`;
export const DepositOptionWrapper = styled.div`
  transition: all 0.2s;

  text-align: center;
  margin: 0 0 16px 16px;

  height: 66px;
  width: 124px;
  border: 2px solid ${({ theme }) => theme.themeColors.text};
  border-radius: 5px;

  flex-grow: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ theme, isSelected }) =>
    isSelected
      ? `
        transform: scale(1.05);

        background: ${theme.themeColors.primaryCtaButton};
        border: 2px solid ${theme.themeColors.primaryCtaButton};
        * {
          color: ${theme.themeColors.buttonText};
        }
      `
      : ''}

  &:hover {
    transform: scale(1.05);

    background: ${({ theme }) => theme.themeColors.primaryCtaButton};
    border: 2px solid ${({ theme }) => convertHexToRGBA(theme.themeColors.primaryCtaButton, 1)};
    * {
      color: ${({ theme }) => theme.themeColors.buttonText};
    }
  }
`;
export const DepositOptionValue = styled.div``;
export const DepositOptionBonus = styled.div`
  * {
    font-size: 12px;
  }
`;
const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  padding-left: 16px;

  svg {
    height: 24px;
    width: 24px;
  }
`;

const baseDepositAmounts = [50, 100, 500, 1000];

const DepositOption = ({ amount, bonusReward, isSelected, onSelect }) => {
  const handleClick = () => onSelect(amount.toString());
  return (
    <DepositOptionWrapper className={isSelected ? 'is-selected' : ''} isSelected={isSelected} onClick={handleClick}>
      <div>
        <DepositOptionValue>
          <Body6 bold>{'$' + formatLocaleString(amount)}</Body6>
        </DepositOptionValue>
        {bonusReward > 0 && (
          <DepositOptionBonus>
            <Body5>{'+ $' + formatLocaleString(bonusReward) + ' Bonus'}</Body5>
          </DepositOptionBonus>
        )}
      </div>
    </DepositOptionWrapper>
  );
};

const getBonusAmountFromBonusAmounts = (v, bonusAmounts) => {
  let bonus = 0;
  bonusAmounts.forEach((r) => {
    if (r.amount === v) {
      bonus = r.reward;
    }
  });
  return bonus;
};

export const DepositAmountPicker = ({ amounts, bankAccountId, value, onSelect }) => {
  const dispatch = useDispatch();

  const [userDepositAccountAmounts, setUserDepositAccountAmounts] = React.useState({});
  const [depositSelectableAmounts, setDepositSelectableAmounts] = React.useState(amounts || baseDepositAmounts);

  const [isReady, setIsReady] = React.useState(true);
  const [isDepositAmountsReady, setIsDepositAmountsReady] = React.useState(!!amounts || false);

  const [bonusAmounts, setBonusAmounts] = React.useState([]);

  React.useEffect(() => {
    const fetchDepositAmounts = async () => {
      const response = await getDepositPickerAmounts()(dispatch);
      const { ok, accounts } = response;
      if (ok) {
        const lookup = {};
        accounts.forEach((a) => {
          const { account_id, deposit_amounts } = a;
          if (deposit_amounts.length > 0) lookup[account_id] = deposit_amounts;
        });
        setUserDepositAccountAmounts(lookup);
      }
      setIsDepositAmountsReady(true);
    };
    if (!amounts) fetchDepositAmounts();
  }, []);

  React.useEffect(() => {
    if (userDepositAccountAmounts[bankAccountId] && !amounts)
      setDepositSelectableAmounts(userDepositAccountAmounts[bankAccountId]);
  }, [bankAccountId, userDepositAccountAmounts]);

  React.useEffect(() => {
    const getBonusAmounts = async (v) => {
      const { ok, data } = await getFundingRewardAmount(v);
      if (ok) {
        const { eligible, rewards } = data;
        if (eligible) {
          setBonusAmounts(rewards);
        } else {
          setBonusAmounts([]);
        }
      } else {
        setTimeout(() => {
          getBonusAmounts(v);
        }, 1000);
      }
    };
    if (depositSelectableAmounts) getBonusAmounts(depositSelectableAmounts);
  }, [depositSelectableAmounts, dispatch]);

  if (!isDepositAmountsReady)
    return (
      <LoadingWrapper>
        <UnstyledLoadingSpinner />
      </LoadingWrapper>
    );

  return (
    <DepositOptionsBody>
      {depositSelectableAmounts.map((a) => (
        <DepositOption
          key={a}
          amount={a}
          bonusReward={getBonusAmountFromBonusAmounts(a, bonusAmounts)}
          isSelected={formatForDisplay(a, 'price').split('$')[1] === value}
          onSelect={onSelect}
        />
      ))}
    </DepositOptionsBody>
  );
};
