import React from 'react';
import TransactionAmount from '../TransactionComponents/TransactionAmount';

const FeeTransaction = (props) => {
  const {
    className,

    type,
    account_name,
    amount,

    transaction_id,
    time,
  } = props;

  return (
    <li
      className={`
        fee-transaction-item
        ${className || ''}
      `}
    >
      <div className={`fee-transaction-details`}>
        <div className={`fee-transaction-identifier`}>
          <span className={`transaction-identifier`}>
            <span className={`transaction-type`}>{type}</span>
          </span>
          {account_name && (
            <span className={`transaction-sub-type transaction-fee-account-name secondary-text-color`}>
              {account_name}
            </span>
          )}
        </div>
        <TransactionAmount className={`transaction-amount`} isVoid={false} value={amount} />
      </div>
    </li>
  );
};

export default FeeTransaction;
