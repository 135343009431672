import React, { useState } from 'react';
import styled from 'styled-components';
import { PageCard } from '@src/main/components/layout';
import { PageCardWrapper } from '@src/main/components/layout/PageCard';
import { PublicPageMessaging } from '@src/main/components/public/PublicPageMessaging';
import { SplitPage } from '@src/main/components/layout/SplitPage';
import { Link } from 'react-router-dom';
import { forgotPasswordSubmit } from '@src/main/containers/SignIn/utils/services';
import {
  FormTitle,
  FormWrap,
  LoadingCover,
  SignSwitchLink,
  ValidationWrap,
} from '@src/main/components/login/LoginStyles';
import { Body5, Body6, Column, Place, Text } from '@src/main/lib/nvstr-common-ui.es';
import Loading from '@src/main/components/login/Loading';
import InputField from '@src/main/components/form/InputField';
import ReCAPTCHA from 'react-google-recaptcha';
import { getRecaptchaKey } from '../../../config';
import { FlatButton } from '@src/main/components/buttons';
import { Container } from '@src/main/components/ui';
import { PRODUCT_DISPLAY_FULL_NAME } from '../../appConfig';

const SignInFormWrapper = styled.div`
  ${PageCardWrapper} {
    padding: 24px;

    -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  }
`;

function ForgotPasswordForm() {
  const [email, setEmail] = useState('');

  const [isLoading, setIsloading] = useState(false);
  const [forgotPasswordComplete, setForgotPasswordComplete] = useState(false);
  const [isFailedValidation, setIsFailedValidation] = useState(true);
  const [validationMessage, setValidationMessage] = useState('');

  const recaptchaRef = React.createRef();

  const userPasswordComplete = () => {
    setIsloading(false);
    setForgotPasswordComplete(true);
  };

  const userPasswordError = (errorMessage) => {
    setIsloading(false);
    setIsFailedValidation(true);
    setValidationMessage(errorMessage);
  };

  const userPassword = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsloading(true);
    const isEmailValid = email.trim() !== '';

    if (isEmailValid) {
      setIsFailedValidation(false);
      const recaptchaValue = recaptchaRef.current.getValue();
      forgotPasswordSubmit({
        email,
        recaptchaValue,
        userPasswordComplete,
        userPasswordError,
      });
    } else {
      setIsFailedValidation(true);
      setIsloading(false);
      setValidationMessage('Please enter your email address.');
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      userPassword(e);
    }
  };

  var webUrl = 'https://' + window.location.hostname;

  if (forgotPasswordComplete)
    return (
      <Column>
        <div>
          <Container bottom={16}>
            <FormTitle>Check Your Email</FormTitle>
          </Container>
          <Body5>
            If an active {PRODUCT_DISPLAY_FULL_NAME} account is associated with this email address, we'll send you an
            email with instructions on how to reset your password.
          </Body5>
          <Container top={16}>
            <SignSwitchLink>
              Back to <Link to="/login">Sign In</Link>
            </SignSwitchLink>
          </Container>
        </div>
      </Column>
    );

  if (isLoading) {
    return (
      <LoadingCover>
        <Loading text={'Submitting...'} />
      </LoadingCover>
    );
  }

  return (
    <div onKeyDown={(e) => handleKeyUp(e)}>
      <FormWrap>
        <form>
          <Body6 bold>Forgot your password?</Body6>

          <Container top={16}>
            <InputField
              id="email-field"
              placeholder={'Email'}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            />
          </Container>

          <Place padding="0px 0 10px 0">
            <ReCAPTCHA ref={recaptchaRef} sitekey={getRecaptchaKey()} />
          </Place>

          {isFailedValidation ? (
            <Container top={8} bottom={16}>
              <ValidationWrap>{validationMessage}</ValidationWrap>
            </Container>
          ) : null}

          <FlatButton id="forgot-password-submit" fullWidth onClick={userPassword}>
            Submit
          </FlatButton>

          <Container top={8} left={2}>
            <SignSwitchLink>
              Back to <Link to="/login">Sign In</Link>
            </SignSwitchLink>
          </Container>
        </form>
      </FormWrap>
    </div>
  );
}

export const ForgotPassword = () => {
  return (
    <SplitPage
      LeftComponent={<PublicPageMessaging shortened />}
      RightComponent={
        <SignInFormWrapper>
          <PageCard>
            <ForgotPasswordForm />
          </PageCard>
        </SignInFormWrapper>
      }
    />
  );
};
