import { SET_APP_HEIGHT } from '../constants';

export const setAppHeight = (value) => {
  return function (dispatch) {
    dispatch({
      type: SET_APP_HEIGHT,
      payload: value,
    });
  };
};
