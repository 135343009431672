import React from 'react';
import { Page } from '../../../components/layout';
import { FlatButton, SkeletonButton } from '../../../components/buttons';
import FundingBonusBanner from '../../../../components/banners/FundingBonusBanner';
import { Body5, Check, Close, H5 } from '../../../lib/nvstr-common-ui.es';
import { colorPalette } from '../../../lib/nvstr-utils.es';
import { hexToRgbA } from '../../../../helpers/generalHelpers';
import { useNavigate } from 'react-router-dom';
import { useBankAccounts } from '../../../hooks/funding/useBankAccounts';
import { useCurrentUser } from '../../../hooks/user';
import styled from 'styled-components';
import { Gradient } from '../../../components/backgrounds';
import { useNextLesson } from '../../../hooks/learn/useNextLesson';
import { TrackingEvents } from '../../../../utils/tracking/events';
import { ROUTES } from '../../../../constants/paths';

const ContrastedBackgroundSection = styled.div`
  background: ${hexToRgbA(colorPalette.primary.charcoal, 0.1)};
  padding: 16px;
  border-radius: 5px;
`;
const ProgressBarWrapper = styled.div`
  background: ${hexToRgbA(colorPalette.primary.charcoal, 0.1)};
  padding: 1px;
  border-radius: 2px;
`;

const ProgressBarFill = styled.div`
  background: ${hexToRgbA(colorPalette.primary.charcoal, 1)};
  height: 22px;
  border-radius: 2px;
  min-width: 36px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const ProgressItemWrapper = styled.div`
  background: ${hexToRgbA(colorPalette.primary.oak, 0.5)};
  padding: 8px;
  border-radius: 5px;
`;
const ProgressItemContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  height: 24px;
  padding-right: 12px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 24px;
  text-align: center;

  button {
    width: 240px;
  }
`;

const ProgressBar = ({ percentage }) => {
  return (
    <ProgressBarWrapper>
      <ProgressBarFill style={{ width: `${percentage}%` }}>
        <div style={{ paddingRight: '8px' }}>
          <Body5 bold color={colorPalette.primary.oak}>{`${percentage}%`}</Body5>
        </div>
      </ProgressBarFill>
    </ProgressBarWrapper>
  );
};

const ProgressItem = ({ text, status }) => {
  const textColor = colorPalette.primary.charcoal;

  return (
    <ProgressItemWrapper>
      <ProgressItemContent>
        <IconWrapper>
          {status ? (
            <Check width={16} color={colorPalette.secondary.brightGreen} />
          ) : (
            <Close width={14} color={colorPalette.secondary.red} />
          )}
        </IconWrapper>
        <div>
          <Body5 color={textColor}>{text}</Body5>
        </div>
      </ProgressItemContent>
    </ProgressItemWrapper>
  );
};

export const FinishAccountSetup = () => {
  const textColor = colorPalette.primary.charcoal;
  const hoverTextColor = colorPalette.primary.oak;

  const navigate = useNavigate();
  const { completedLessonCount } = useNextLesson();

  const bankAccounts = useBankAccounts();
  const hasBankLinked = bankAccounts.length > 0;
  const currentUser = useCurrentUser();
  const hasFunded = currentUser.has_funded;
  const isApprovedLT = currentUser.is_live_trading_approved;

  const linkBankActionMessage = 'Link your bank account and add funding to invest';
  const addFundsActionMessage = 'Add funding to invest';
  const actionMessage = hasBankLinked ? addFundsActionMessage : linkBankActionMessage;

  const ltAccountProgress = (isApprovedLT ? 1 : 0) * 70;
  const leProgress = (completedLessonCount > 0 ? 1 : 0) * 20;
  const fundingProgress = (hasFunded ? 1 : 0) * 10;
  const progressPercentage = ltAccountProgress + leProgress + fundingProgress;

  React.useEffect(() => {
    TrackingEvents.application.VIEW_FINISH_SETTING_UP_ACCOUNT_SCREEN.send();
  }, []);

  const handlePress = React.useCallback(
    () => {
      if (progressPercentage === 100) {
        navigate(ROUTES.DASHBOARD.build());
        return;
      }

      TrackingEvents.application.PRESS_FINISH_SETTING_UP_ACCOUNT.send();

      if (progressPercentage === 10) {
        navigate(ROUTES.LIVE_TRADING_SIGNUP_START.build());
        return;
      }

      if (completedLessonCount === 0) {
        navigate(ROUTES.LEARN_AND_EARN_NEXT_LESSON.build());
        return;
      }

      if (hasBankLinked) {
        navigate(ROUTES.DEPOSIT_FUNDS.build());
      } else {
        navigate(ROUTES.LINK_BANK.build());
      }
    },
    [navigate, bankAccounts, hasFunded]
  );

  return (
    <Page width={'500px'} style={{ position: 'relative', overflow: 'hidden' }}>
      <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }}>
        <Gradient />
      </div>
      <div style={{ position: 'relative', zIndex: 2 }}>
        <div>
          <div style={{ paddingTop: '8px' }}>
            <H5 color={textColor} heading>
              {progressPercentage === 100
                ? 'Account setup completed'
                : progressPercentage > 70
                ? 'Your account setup is almost complete'
                : 'Finish your account setup'}
            </H5>
          </div>

          <div style={{ paddingTop: '24px' }}>
            <ContrastedBackgroundSection>
              <div style={{ padding: '0 4px 8px 4px' }}>
                <Body5 bold color={textColor}>
                  Current Progress
                </Body5>
              </div>
              <ProgressBar percentage={progressPercentage} />
              <div style={{ paddingTop: '12px' }}>
                <div style={{ paddingBottom: '8px' }}>
                  <ProgressItem text={'Open live brokerage account'} status={isApprovedLT} />
                </div>
                <div style={{ paddingBottom: '8px' }}>
                  <ProgressItem text={'Start learning and earning'} status={completedLessonCount > 0} />
                </div>
                <ProgressItem text={actionMessage} status={hasFunded} />
              </div>
              <ButtonWrapper>
                <FlatButton color={textColor} textColor={colorPalette.primary.oak} onClick={handlePress}>
                  Let&apos;s Finish
                </FlatButton>
              </ButtonWrapper>
            </ContrastedBackgroundSection>
          </div>
        </div>
        <div style={{ padding: '16px 0' }}>
          <FundingBonusBanner color={colorPalette.primary.charcoal} />
          <ButtonWrapper>
            <SkeletonButton color={textColor} textColor={hoverTextColor} onClick={handlePress}>
              Continue
            </SkeletonButton>
          </ButtonWrapper>
        </div>
      </div>
    </Page>
  );
};
