import React from 'react';
import { Container } from '../components/ui';
import { Body5, Body6, H3, H5, LoadingSpinner } from '../lib/nvstr-common-ui.es';
import { Page } from '../components/layout';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { FlatButton } from '@src/main/components/buttons';
import { BASEURL } from '@src/adapters';
import { sendRequest } from '@src/helpers/ajaxHelpers';
import { FormState } from '@src/main/components/form/FormState';

const PageWrapper = styled.div`
  display: flex;
`;

const Label = styled.div`
  padding-bottom: 8px;
`;

const RatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding-top: 16px;
`;

const Circle = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 24px;

  border: 2px solid ${({ theme }) => theme.themeColors.border};
  background-color: ${({ theme, filled }) => (filled ? theme.themeColors.primaryCtaButton : 'transparent')};
  cursor: pointer;
`;

const RatingSaveButtonWrapper = styled.div`
  padding-left: 16px;

  button,
  div {
    height: 24px;
  }

  button {
    padding: 0 16px;
  }
`;

const FormWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
    -webkit-appearance: auto !important;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 100px;
    width: 100%;
    padding: 16px;
  }
`;

const ResultRenderer = ({ data }) => {
  const endOfPros = data.indexOf('Cons:');
  const cons = data.slice(endOfPros, data.length);
  const pros = data.split('Cons:')[0];
  const prosList = pros.split(/\r\n|\r|\n/);
  return (
    <Container>
      <Container top={24}>
        {/*<Container bottom={8}>*/}
        {/*  <Body5>{'Pros:'}</Body5>*/}
        {/*</Container>*/}
        {prosList.map((t, i) => (
          <Container key={i}>
            <Body5>{t}</Body5>
          </Container>
        ))}
        <Container top={24}>
          {/*<Container bottom={8}>*/}
          {/*  <Body5>{'Cons:'}</Body5>*/}
          {/*</Container>*/}
          {cons.split(/\r?\n/).map((t, i) => (
            <Container key={i}>
              <Body5>{t}</Body5>
            </Container>
          ))}
        </Container>
      </Container>
    </Container>
  );
};

const gptModels = {
  GPT_4: 'gpt-4',
  GPT_4_0314: 'gpt-4-0314',
  GPT_4_32k: 'gpt-4-32k',
  GPT_4_32k_0314: 'gpt-4-32k-0314',
  GPT_3_5_turbo: 'gpt-3.5-turbo',
  GPT_3_5_turbo_0301: 'gpt-3.5-turbo-0301',
  // a: 'text-davinci-003',
  // a: 'text-davinci-002',
  // a: 'code-davinci-002',
};

const gptModelsList = [
  gptModels.GPT_4,
  gptModels.GPT_4_0314,
  gptModels.GPT_4_32k,
  gptModels.GPT_4_32k_0314,
  gptModels.GPT_3_5_turbo,
  gptModels.GPT_3_5_turbo_0301,
];

const circles = [1, 1, 1, 1, 1];
const shouldFill = (index, rating) => {
  return index < rating;
};
const RatingRenderer = ({ resultId, onSaveRating }) => {
  const [savedResultId, setSavedResultId] = React.useState(null);
  const [rating, setRating] = React.useState(0);
  const [hoverRating, setHoverRating] = React.useState(0);

  const handleClick = (i) => {
    setRating(i + 1);
  };
  const handleHoverEnter = (i) => {
    setHoverRating(i + 1);
  };
  const handleHoverExit = (i) => {
    setHoverRating(0);
  };
  const onSave = () => {
    onSaveRating(rating);
    setSavedResultId(resultId);
  };

  if (resultId === savedResultId) return null;

  return (
    <RatingWrapper>
      {circles.map((c, i) => (
        <Circle
          filled={shouldFill(i, hoverRating > 0 ? hoverRating : rating)}
          onClick={() => handleClick(i)}
          onMouseEnter={() => handleHoverEnter(i)}
          onMouseLeave={() => handleHoverExit(i)}
        />
      ))}
      {rating > 0 && (
        <RatingSaveButtonWrapper>
          <FlatButton onClick={onSave}>Save</FlatButton>
        </RatingSaveButtonWrapper>
      )}
    </RatingWrapper>
  );
};

export const ProConGenerator = () => {
  const dispatch = useDispatch();

  const [phrase, setPhrase] = React.useState(
    'Summarize this article in 4 bullets each consisting of 100 maximum characters and categorize them as pros and cons:'
  );
  const [model, setModel] = React.useState(gptModels.GPT_4);
  const [entry, setEntry] = React.useState('');
  const [result, setResult] = React.useState('');
  const [resultId, setResultId] = React.useState(null);

  const [isSubmittingToApi, setIsSubmittingToApi] = React.useState(false);
  const [error, setError] = React.useState(null);

  const onClear = () => {
    setEntry('');
    setPhrase('');
    setResult('');
  };

  const onPhraseChange = (e) => {
    const v = e.target.value;
    setPhrase(v);
  };
  const onEntryChange = (e) => {
    const v = e.target.value;
    setEntry(v);
  };
  const onModelChange = (e) => {
    const v = e.target.value;
    setModel(v);
  };

  const onSubmitPress = () => {
    setResult('');
    onSaveToApi();
  };

  const onSaveToApi = async () => {
    const form = { text_or_url: entry, phrase, model };
    const URL = `${BASEURL}/api/v1/chatgpt_content`;
    setIsSubmittingToApi(true);
    const response = await sendRequest('post', [`${URL}`, form]);
    if (response?.status === 200) {
      const content = response?.data?.content;
      const formatted = JSON.parse(content);
      setResult(formatted);
      setResultId(response?.data?.id || null);
    } else {
      setError('Something went wrong.');
    }
    setIsSubmittingToApi(false);
  };

  const onSaveRating = async (rating) => {
    if (!resultId) {
      console.error('no result id');
      return null;
    }
    const form = { rating, id: resultId };
    const URL = `${BASEURL}/api/v1/chatgpt_rating`;
    const response = await sendRequest('post', [`${URL}`, form]);
  };

  return (
    <PageWrapper>
      <Page>
        <Container>
          <H5>Pro/Con Generator</H5>

          <Container top={24}>
            <FormWrapper>
              <Container>
                <Label>Prompt</Label>
                <textarea onChange={onPhraseChange} value={phrase} />
              </Container>

              <Container top={16}>
                <Label>Model</Label>
                <select onChange={onModelChange} value={model}>
                  {gptModelsList.map((m) => (
                    <option key={m} value={m}>
                      {m}
                    </option>
                  ))}
                </select>
              </Container>

              <Container top={16}>
                <Label>Context</Label>
                <textarea onChange={onEntryChange} value={entry} />
              </Container>
            </FormWrapper>
          </Container>
          <Container top={24}>
            <Container>
              <Body5>Result: </Body5>
              <Container>
                <ResultRenderer data={result} />
              </Container>
            </Container>
          </Container>
          {resultId && (
            <Container top={24}>
              <Container>
                <Body5>Rating: </Body5>
                <Container>
                  <RatingRenderer onSaveRating={onSaveRating} />
                </Container>
              </Container>
            </Container>
          )}
        </Container>
        <Container top={30}>
          <FormState isSubmitting={isSubmittingToApi} error={error} />

          <Container row centerAll>
            <FlatButton fullWidth onClick={onSubmitPress}>
              Submit
            </FlatButton>
          </Container>
          <Container row centerAll>
            <FlatButton transparent onClick={onClear}>
              Clear
            </FlatButton>
          </Container>
        </Container>
      </Page>
    </PageWrapper>
  );
};
