import React from 'react';
import { connect } from 'react-redux';
import { ElementPositionContext } from '@src/context';
import { enums } from '@src/main/lib/nvstr-utils.es';
import { withRouter } from '@src/main/utils';
import styled from 'styled-components';
import { DOMAIN } from '@src/appConfig';
import { logMetricsTrackingEvent } from '@src/actions';

const Wrapper = styled.div`
  * {
    color: ${({ theme }) => theme.themeColors.text} !important;
  }

  .footer-body {
    margin: 0 auto;
    padding: 24px 24px;
    max-width: 1200px;
  }

  .footer-page-links {
    margin: 0 auto;
    max-width: 300px;
  }

  .footer-social-links {
    margin: 0 auto;
    padding: 16px 0;
    max-width: 160px;
  }

  .other-logos {
    margin: 0;
    padding: 16px 0;

    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    img {
      max-width: 76px;
    }
  }

  a:hover {
    text-decoration: none;
    opacity: 0.8;
  }
  li {
    list-style: none;
  }
  .social-icon {
    color: ${({ theme }) => theme.themeColors.appBackground};
  }

  .social-icon-bg {
    i {
      color: ${({ theme }) => theme.themeColors.appBackground} !important;
    }
    background-color: ${({ theme }) => theme.themeColors.text};
  }
  .footer-body a:hover,
  .footer-body a:active,
  .footer-body a:visited,
  .footer-body a:focus {
    color: ${({ theme }) => theme.themeColors.text};
  }

  .social-icon-bg {
    display: block;
    text-align: center;
    height: 32px;
    width: 32px;
    padding: 4px 6px;
    border-radius: 6px;
  }
  .social-icon {
    font-size: 24px;
  }
  .social-icon-lg {
    font-size: 36px;
  }
  .footer-provider-logo {
    background-repeat: no-repeat;
    background-size: contain;
    height: 25px;
    width: 125px;
  }
  .footer-react-container {
    position: relative;
  }
  .footer-react-container .new.grey-footer {
    padding-top: 35px;
  }
  .footer-react-container .footer-logo-container {
    display: none;
  }

  .dashboard-page footer {
    border-top: none !important;

    .footer-react-container {
      position: relative;
    }
    .footer-page-links ul {
      display: flex;
      flex-direction: column;
    }
    .footer-page-links li {
      width: 100%;
      text-align: left;
    }
    .footer-social-links {
      position: absolute;
      top: 20px;
      right: 0px;
      left: 0px;
      height: 75px;
    }
  }
  .logo {
    margin-bottom: 15px;
  }
  .logo img {
    max-width: 76px;
  }
  .links {
    margin-bottom: 5px;
    overflow: auto;
  }
  .links ul {
    list-style: none;
    -webkit-padding-start: 0px;
  }
  .links ul li {
    float: left;
    padding-right: 40px;
    padding-bottom: 15px;
  }
  .links ul li:last-child {
    padding-right: 0px;
  }
  .links ul li a {
    text-align: center;
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: 400;
  }
  .text-copy {
    font-size: 10px;
    clear: both;
    margin-bottom: 15px;
  }
  .copyright {
    font-size: 10px;
    margin-bottom: 15px;
  }
  @media (max-width: 420px) {
    .other-logos img {
      max-width: 60px;
    }
  }
  @media (max-width: 320px) {
    .other-logos img {
      max-width: 55px;
    }
  }

  .s-and-p {
    //background-image: url(/app/assets/s_and_p_logo.png);
    background-image: url(/app/assets/s_and_p_logo_grey.png);
    height: 31px;
    width: 112px;
  }
  .xignite {
    //background-image: url(/app/assets/xignite_logo.png);
    background-image: url(/app/assets/xignite_logo_grey.png);
    height: 21px;
    width: 82px;
  }
  .nasdaq {
    //background-image: url(/app/assets/nasdaq_logo.png);
    background-image: url(/app/assets/nasdaq_logo_grey.png);
    height: 26px;
    width: 93px;
  }
  .sipc {
    //background-image: url(/app/assets/sipc_logo.png);
    background-image: url(/app/assets/sipc_logo_grey.png);
    height: 35px;
    width: 60px;
  }
  @media (max-width: 500px) {
    .s-and-p {
      height: 22px;
      width: 85px;
    }
    .xignite {
      height: 13px;
      width: 55px;
    }
    .nasdaq {
      height: 16px;
      width: 61px;
    }
    .sipc {
      height: 25px;
      width: 45px;
    }
  }
`;

class Footer extends React.Component {
  componentDidMount() {
    if (!this.props.footer.isLoading && this.props.footer.partial) {
      this._onAfterPartialRender();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.footer.isLoading && !this.props.footer.isLoading && this.props.footer.partial) {
      this._onAfterPartialRender();
    }
  }

  render() {
    if (this._isLoading()) {
      return null;
    }

    return (
      <ElementPositionContext.Provider value={{ elementPosition: enums.node_location.footer }}>
        <Wrapper className={`footer-react-container`}>{this._renderFooterPartial()}</Wrapper>
      </ElementPositionContext.Provider>
    );
  }

  _renderFooterPartial = () => {
    const htmlString = this._returnFooterPartial();
    const markup = { __html: htmlString };
    return <div dangerouslySetInnerHTML={markup} />;
  };

  _onAfterPartialRender = () => {
    this._callJSForPartial();
    this._overwriteFooterLinkActions();
  };

  _$returnFooterLinksContainer = () => $('.footer-page-links');
  _createEventListenerForPage = (route, $node) => {
    $node.on('click', (e) => {
      e.preventDefault();
      this.props.navigate(route);
    });
  };

  _overwriteFooterLinkActions = () => {
    const tornadoDomain = 'https://tornado.com';

    this._$returnFooterLinksContainer()
      .find('li[title*="About"] a')
      .attr('href', `${tornadoDomain}/about`)
      .attr('target', '_blank');

    this._$returnFooterLinksContainer()
      .find('li[title*="Enterprise"]')
      .hide();

    this._$returnFooterLinksContainer()
      .find('li[title*="Press Kit"]')
      .hide();

    this._$returnFooterLinksContainer()
      .find('li[title*="Blog"] a')
      .attr('href', `${tornadoDomain}/blog`)
      .attr('target', '_blank');

    this._$returnFooterLinksContainer()
      .find('li[title*="FAQ"] a')
      .attr('href', `https://${DOMAIN}/faq`)
      .attr('target', '_blank');

    this._$returnFooterLinksContainer()
      .find('li[title*="Legal"] a')
      .attr('href', `https://${DOMAIN}/legal`)
      .attr('target', '_blank');

    this._$returnFooterLinksContainer()
      .find('li[title*="Terms of Service"] a')
      .attr('href', `https://${DOMAIN}/terms_of_service`)
      .attr('target', '_blank');

    this._$returnFooterLinksContainer()
      .find('li[title*="Privacy Policy"] a')
      .attr('href', `https://${DOMAIN}/privacy_policy`)
      .attr('target', '_blank');

    this._$returnFooterLinksContainer()
      .find('li[title*="Margin Disclosure"] a')
      .attr('href', `https://${DOMAIN}/margin_disclosure`)
      .attr('target', '_blank');
  };

  _callJSForPartial = () => {
    $('#blog_link').click(function(event) {
      logMetricsTrackingEvent('Blog Link Clicked')({
        'In App': true,
      });
    });
    $('#press_link').click(function(event) {
      logMetricsTrackingEvent('Press Kit Link Clicked')({
        'In App': true,
      });
    });
    $('#nvstr_footer_facebook').click(function(event) {
      logMetricsTrackingEvent('Footer - Click Social Link')({
        Network: 'Facebook',
        'In App': true,
      });
    });
    $('#nvstr_footer_twitter').click(function(event) {
      logMetricsTrackingEvent('Footer - Click Social Link')({
        Network: 'Twitter',
        'In App': true,
      });
    });
    $('#nvstr_footer_instagram').click(function(event) {
      logMetricsTrackingEvent('Footer - Click Social Link')({
        Network: 'Instagram',
        'In App': true,
      });
    });

    const pathname = window.location.pathname;
    if (pathname === '/about') {
      const footer = $('footer.new')[0];
      footer && footer.classList.remove('grey-footer');
    }
  };

  _isLoading = () => this._returnFooterStore().isLoading;

  _returnFooterPartial = () => this._returnFooterStore().partial;
  _returnFooterStore = () => this.props.footer;
}

const mapStateToProps = (state) => {
  return {
    footer: state.footer,
  };
};

export default withRouter(connect(mapStateToProps)(Footer));
