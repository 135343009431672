import { USER_AUTHENTICATION_SUCCESS, USER_AUTHENTICATION_FAILURE } from '../constants';

const defaultState = {
  isAuthenticating: true,
  isAuthenticated: false,
};

export default function authenticationReducer(state = defaultState, action) {
  switch (action.type) {
    case USER_AUTHENTICATION_SUCCESS:
      // should only be set after currentUser is loaded into store
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
      };

    case USER_AUTHENTICATION_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
      };

    default:
      return state;
  }
}
