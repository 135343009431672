import React from 'react';
import { colorPalette } from '../../../lib/nvstr-utils.es';
import { BaseText } from '../../../lib/nvstr-common-ui.es';
import styled from 'styled-components';
import Icon from '../../../../components/misc/Icon';
import { LearnItemContent } from './LearnItemContent';
import { Container } from '../../ui';

// const LEARN_ITEM_MIN_HEIGHT = `${window.innerHeight * 0.25}px`;

const LeftClickPanel = styled.div`
  position: absolute;
  top: 0;
  bottom: 56px;
  left: -16px;

  width: 60px;
  opacity: 0.2;
  cursor: pointer;

  z-index: 4;
`;
const RightClickPanel = styled.div`
  position: absolute;
  top: 0;
  bottom: 56px;
  right: -16px;

  width: 60px;
  opacity: 0.2;
  cursor: pointer;

  z-index: 4;
`;

const CenterItem = styled.div`
  display: flex;
  flex: 1 1 0%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  min-height: ${({ minHeight }) => minHeight};
  padding: 24px 0 24px 0;
  width: 100%;
`;

const ItemContainer = styled.div`
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
`;

const FlexItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const LeftArrowContainer = styled.div`
  padding-right: 16px;
  color: ${colorPalette.primary.charcoal};
`;
const RightArrowContainer = styled.div`
  padding-left: 16px;
  color: ${colorPalette.primary.charcoal};
  text-align: right;
  ${({ isEnd }) => (isEnd ? `opacity: 0;` : '')}

  svg {
    height: 22px;
    width: 22px;
    opacity: 0.2;
  }
`;

const TextWrapper = styled.div`
  .base-text {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: 1000px) {
    .base-text {
      font-size: 18px;
      line-height: 26px;
    }
  }
  @media (max-width: 480px) {
    .base-text {
      font-size: 16px;
      line-height: 24px;
    }
  }
  @media (max-width: 358px) {
    .base-text {
      font-size: 15px;
      line-height: 22px;
    }
  }
`;

const EndIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" />
    </svg>
  );
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M23,12L20.56,9.22L20.9,5.54L17.29,4.72L15.4,1.54L12,3L8.6,1.54L6.71,4.72L3.1,5.53L3.44,9.21L1,12L3.44,14.78L3.1,18.47L6.71,19.29L8.6,22.47L12,21L15.4,22.46L17.29,19.28L20.9,18.46L20.56,14.78L23,12M10,17L6,13L7.41,11.59L10,14.17L16.59,7.58L18,9L10,17Z" />
    </svg>
  );
};

export const LearnCarouselContent = ({
  items,
  activeScreenIndex,
  previousScreen,
  advanceScreen,
  hideTapPanels,
  children,
}) => {
  // const activeItem = items[activeScreenIndex];
  const itemsTotal = items.length;
  const showPrev = activeScreenIndex > 0;
  const isLast = activeScreenIndex === itemsTotal - 1;
  return (
    <Container name={'LearnCarouselContent'} flex={1} column relative fullWidth>
      {!hideTapPanels ? <LearnCarouselPreviousButtonPanel previousScreen={previousScreen} /> : null}
      <CenterItem>
        <ItemContainer>
          <FlexItemContainer>
            {showPrev && !hideTapPanels ? (
              <LeftArrowContainer>
                <Icon icon="fa-chevron-left" />
              </LeftArrowContainer>
            ) : null}

            <div style={{ width: '100%' }}>{children}</div>

            {!hideTapPanels ? (
              isLast ? (
                <RightArrowContainer isEnd={isLast}>
                  <Icon icon="fa-chevron-right" />
                </RightArrowContainer>
              ) : (
                <RightArrowContainer isEnd={isLast}>
                  <Icon icon="fa-chevron-right" />
                </RightArrowContainer>
              )
            ) : null}
          </FlexItemContainer>
        </ItemContainer>
      </CenterItem>
      {!hideTapPanels ? <LearnCarouselAdvanceButtonPanel advanceScreen={advanceScreen} /> : null}
    </Container>
  );
};

export const NoBreakText = ({ text }) => {
  const splitText = text.split(' ');
  return splitText.map((t, i) =>
    splitText.length === i + 1 ? <span key={i}>{t}</span> : <span key={i}>{t}&nbsp;</span>
  );
};

export const LearnLessonItemText = ({ data, textColor }) => {
  const { text, small, bold, noWrap, isPaddedNewLine, lineBreak } = data;

  if (lineBreak) {
    return <div style={{ padding: '16px 0 0 0' }} />;
  }
  if (isPaddedNewLine) {
    return (
      <p style={{ margin: '16px 0 0 0' }}>
        <BaseText isSmall={small} thin={!bold} bold={bold} color={textColor}>
          {noWrap ? <NoBreakText text={text} /> : text}
        </BaseText>
      </p>
    );
  }
  return (
    <BaseText isSmall={small} thin={!bold} bold={bold} color={textColor}>
      {noWrap ? <NoBreakText text={text} /> : text}
    </BaseText>
  );
};

export const LearnLessonItemParagraph = ({ paragraph, textColor }) => {
  return (
    <TextWrapper>
      {paragraph.map((d, i) => (
        <LearnLessonItemText key={i} data={d} textColor={textColor} />
      ))}
    </TextWrapper>
  );
};

export const LearnCarouselAdvanceButtonPanel = ({ advanceScreen }) => {
  return <RightClickPanel onClick={advanceScreen} />;
};

export const LearnCarouselPreviousButtonPanel = ({ previousScreen }) => {
  return <LeftClickPanel onClick={previousScreen} />;
};

export { LearnLessonBody } from './LearnLessonBody';
export { LearnLessonHeading } from './LearnLessonHeading';
export { LearnItemContent } from './LearnItemContent';
export { LearnLessonActions } from './LearnLessonActions';
