import React from 'react';
import { connect } from 'react-redux';

import Button from '../../components/buttons/Button';
import MultiSelect, { MultiSelectItem } from '../Inputs/MultiSelect';
import InfoIconTooltip from '../UI/InfoIconTooltip';
import SelectableViewpointCard from './components/SelectableViewpointCard';

import {
  getAllFeaturedSubscriptionViewpointIdsFromState,
  getFeaturedSubscriptionViewpointIdsDisplayOrderFromState,
  getSubscriptionViewpointDefaultSelectionFromState,
} from '../../selectors/viewpoints';
import { FlatButton } from '../../main/components/buttons';

class FeaturedViewpointSelection extends React.PureComponent {
  render() {
    const { featuredViewpointOrgIds, defaultViewpointSelection } = this.props;

    const isSelectionAvailable = featuredViewpointOrgIds.length > 1;
    const shouldAutoSelect = featuredViewpointOrgIds.length === 1 || defaultViewpointSelection !== null;
    const defaultAutoSelectionId =
      defaultViewpointSelection !== null ? [defaultViewpointSelection] : [featuredViewpointOrgIds[0]];

    return (
      <div className={'featured-viewpoint-selection-container'} style={{ paddingTop: '15px' }}>
        <MultiSelect
          header={
            isSelectionAvailable ? (
              <div className="text-center" style={{ marginBottom: 10 }}>
                <span className="  text-center">Select one of our featured free contributors</span>
                <span style={{ marginLeft: '8px' }}>
                  <InfoIconTooltip definitionKey={'tooltip_viewpoint'} />
                </span>
              </div>
            ) : null
          }
          isLoading={false}
          multiSelectItems={this._generateMultiSelectItems()}
          defaultState={shouldAutoSelect ? defaultAutoSelectionId : null}
          itemsContainerStyle={{ flex: 1 }}
          separator={<div style={{ height: 10 }} />}
          useRadioButtonBehavior
        >
          {(values) => (
            <div
              style={{
                textAlign: 'center',
                paddingTop: '25px',
              }}
            >
              <FlatButton onClick={this.handleContinueClick(values)}>Continue</FlatButton>
            </div>
          )}
        </MultiSelect>
      </div>
    );
  }

  handleContinueClick = (values) => {
    return () => {
      this.props.onContinue(values);
    };
  };

  _generateMultiSelectItems = () => {
    const { orderedFeaturedViewpointIds } = this.props;
    return orderedFeaturedViewpointIds.map(
      (id) =>
        new MultiSelectItem({
          Component: SelectableViewpointCard,
          props: {
            id,
          },
          value: id,
        })
    );
  };
}

const mapStateToProps = (state) => {
  return {
    featuredViewpointOrgIds: getAllFeaturedSubscriptionViewpointIdsFromState(state),
    orderedFeaturedViewpointIds: getFeaturedSubscriptionViewpointIdsDisplayOrderFromState(state),
    defaultViewpointSelection: getSubscriptionViewpointDefaultSelectionFromState(state),
  };
};

export default connect(mapStateToProps)(FeaturedViewpointSelection);
