import React from 'react';
import Overlay from './Overlay';
import withWindowSize from '../../containers/HOCS/withWindowSize';

class Panel extends React.PureComponent {
  render() {
    return this._useWithoutOverlay() ? this._renderWithoutOverlay() : this._renderWithOverlay();
  }

  _renderWithOverlay = () => {
    return <Overlay onClick={this.handleClick}>{this._renderPanel()}</Overlay>;
  };

  _renderWithoutOverlay = () => this._renderPanel();

  _renderPanel = () => {
    const {
      windowHeight,
      windowWidth,
      childrenWithWindowSize,

      children,
      className,
    } = this.props;
    return (
      <div className={`panel-container fade-in-up component-bg ${className}`} style={this._returnPanelStyling()}>
        <div className={'scroll-panel-container'}>
          {childrenWithWindowSize ? children(windowHeight, windowWidth) : children}
        </div>
      </div>
    );
  };

  _returnPanelStyling = () => ({
    ...(this.props.heightPercentage ? { height: this._returnPanelHeight() } : {}),
    ...(this.props.style || {}),
  });

  _returnPanelHeight = () => `${this.props.windowHeight * (this.props.heightPercentage || 1)}px`;

  _useWithoutOverlay = () => this.props.withoutOverlay;

  handleClick = () => {
    // feature => if no handleOverlayClick is provided, user cannot dismiss panel from overlay
    if (this.props.handleOverlayClick) {
      this.props.handleOverlayClick();
    }
  };
}

Panel.defaultProps = {
  className: '',
};

export default withWindowSize(Panel);
