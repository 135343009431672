import React from 'react';
import styled from 'styled-components';
import { ExpandedPageBackground, Page } from '../components/layout';
import { defineFontSettings } from '../lib/nvstr-common-ui.es';
import { colorPalette } from '../lib/nvstr-utils.es';
import { Gradient } from '../components/backgrounds';
import { Text } from '../components/text';
import Icon from '../../components/misc/Icon';
import { CarouselPositionIndicator } from '../components/learn/subComponents/CarouselPositionIndicator';
import { FlatButton, SkeletonButton } from '../components/buttons';

const itemMinHeight = `${window.innerHeight * 0.35}px`;

const RightClickPanel = styled.div`
  position: absolute;
  top: 0;
  bottom: 56px;
  right: -24px;

  width: 50px;
  opacity: 0.2;
  cursor: pointer;

  z-index: 4;
`;

const LeftClickPanel = styled.div`
  position: absolute;
  top: 0;
  bottom: 56px;
  left: -24px;

  width: 50px;
  opacity: 0.2;
  cursor: pointer;

  z-index: 4;
`;

const AdvanceButtonPanel = ({ advanceScreen }) => {
  return <RightClickPanel onClick={advanceScreen} />;
};
const PreviousButtonPanel = ({ previousScreen }) => {
  return <LeftClickPanel onClick={previousScreen} />;
};

const Container = styled.div`
  position: relative;
  z-index: 2;
  flex-direction: column;
  flex: 0 1 auto;
  display: flex;
  height: unset;

  select {
    color: ${({ theme }) => colorPalette.primary.charcoal} !important;
    border-color: ${({ theme }) => colorPalette.primary.charcoal} !important;
  }
  option {
    color: ${({ theme }) => colorPalette.primary.charcoal} !important;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const CenterItem = styled.div`
  display: table;

  min-height: ${({ minHeight }) => minHeight};
  padding: 24px 0 24px 0;
  width: 100%;
`;

const ItemContainer = styled.div`
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
`;

const FlexItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const LeftArrowContainer = styled.div`
  padding-right: 16px;
  color: ${colorPalette.primary.charcoal};
`;

const RightArrowContainer = styled.div`
  padding-left: 16px;
  color: ${colorPalette.primary.charcoal};
  text-align: right;
`;

const ActionWrapper = styled.div`
  padding: 2px 0 8px 0;
  text-align: center;

  button {
    width: 225px;
  }
`;

const Item = ({ wizard, contents, textColor, activeScreenIndex, itemsTotal }) => {
  const isFirstSlide = activeScreenIndex === 0;
  const showPrev = activeScreenIndex > 0;
  const showNext = activeScreenIndex < itemsTotal - 1;

  const shrinkHeading = wizard.length > 8;
  const fontSettings = defineFontSettings(
    {
      fontSize: '18px',
      lineHeight: '24px',
    },
    {
      fontSize: '24px',
      lineHeight: '28px',
    }
  );
  const topicFontSettings = defineFontSettings(
    {
      fontSize: '36px',
      lineHeight: '44px',
    },
    {
      fontSize: '64px',
      lineHeight: '68px',
    }
  );

  return (
    <div>
      <div style={{ paddingTop: '36px', height: '160px' }}>
        {isFirstSlide ? (
          <div>
            <Text
              isSmall={shrinkHeading}
              fontSettings={fontSettings}
              letterSpacing={0.8}
              color={textColor}
              thin
              uppercase
            >
              Learn about
            </Text>
            <div style={{ paddingTop: '8px' }}>
              <Text bold uppercase fontSettings={topicFontSettings} isSmall={shrinkHeading} color={textColor}>
                {wizard}
              </Text>
            </div>
          </div>
        ) : (
          <div>
            <Text
              isSmall={shrinkHeading}
              fontSettings={fontSettings}
              letterSpacing={0.8}
              color={textColor}
              bold
              uppercase
            >
              {wizard}
            </Text>
            <Text
              isSmall={shrinkHeading}
              fontSettings={fontSettings}
              letterSpacing={0.8}
              color={textColor}
              thin
              uppercase
            >
              {' Wizard'}
            </Text>
          </div>
        )}
      </div>

      <CenterItem minHeight={itemMinHeight}>
        <ItemContainer>
          <FlexItemContainer>
            {showPrev ? (
              <LeftArrowContainer>
                <Icon icon="fa-chevron-left" />
              </LeftArrowContainer>
            ) : (
              <div style={{ width: '28px', paddingRight: '28px' }} />
            )}
            <div style={{ width: '100%' }}>{contents}</div>
            {showNext ? (
              <RightArrowContainer>
                <Icon icon="fa-chevron-right" />
              </RightArrowContainer>
            ) : (
              <div style={{ width: '28px', paddingRight: '28px' }} />
            )}
          </FlexItemContainer>
        </ItemContainer>
      </CenterItem>
    </div>
  );
};

const Carousel = ({ carouselData, textColor, onComplete }) => {
  const { id, wizard, content } = carouselData;

  const itemsTotal = content.length;
  const [activeScreenIndex, setActiveScreenIndex] = React.useState(0);
  const contents = content[activeScreenIndex];
  const isLastScreen = itemsTotal === activeScreenIndex + 1;

  const advanceScreen = () => {
    const newIndex = Math.min(itemsTotal - 1, activeScreenIndex + 1);
    setActiveScreenIndex(newIndex);
  };

  const previousScreen = () => {
    const newIndex = Math.max(0, activeScreenIndex - 1);
    setActiveScreenIndex(newIndex);
  };

  React.useEffect(
    () => {
      const pageNumber = activeScreenIndex + 1;
    },
    [activeScreenIndex]
  );

  return (
    <Container>
      <ContentContainer>
        <PreviousButtonPanel previousScreen={previousScreen} />
        <Item
          wizard={wizard}
          contents={contents}
          activeScreenIndex={activeScreenIndex}
          itemsTotal={itemsTotal}
          textColor={textColor}
          advanceScreen={advanceScreen}
          previousScreen={previousScreen}
        />
        <AdvanceButtonPanel advanceScreen={advanceScreen} />
      </ContentContainer>

      <CarouselPositionIndicator activeIndex={activeScreenIndex} itemsTotal={itemsTotal} />
      <ActionWrapper>
        {isLastScreen ? (
          <FlatButton color={colorPalette.primary.charcoal} textColor={colorPalette.primary.oak} onClick={onComplete}>
            CONTINUE
          </FlatButton>
        ) : (
          <SkeletonButton
            color={colorPalette.primary.charcoal}
            textColor={colorPalette.primary.oak}
            onClick={advanceScreen}
          >
            CONTINUE
          </SkeletonButton>
        )}
      </ActionWrapper>
    </Container>
  );
};

export const Wizard = ({ onComplete, carouselData, learnScreenLessonName }) => {
  const textColor = colorPalette.primary.charcoal;

  return (
    <ExpandedPageBackground>
      <Page expandheight={'true'} width={'600px'} style={{ position: 'relative', overflow: 'hidden' }}>
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }}>
          <Gradient />
        </div>

        <Carousel textColor={textColor} carouselData={carouselData} onComplete={onComplete} />
      </Page>
    </ExpandedPageBackground>
  );
};
