import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../../actions/index';

import UserContainer from '../../users/UserContainer';
import ProfileAvatar from '../../../components/user/ProfileAvatar';
import UserName from '../../../components/user/UserName';
import MutualConnectionsList from '../PeopleYouMayKnow/components/MutualConnectionsList';
import ConnectionRequest from '../../buttons/ConnectionRequest';

import PageLoading from '../../../components/PageLoading';
import LoadingIcon from '../../../components/misc/LoadingIcon';

import { userFullName, isConnectionRequestSentExpired } from '../../../helpers/usersHelpers';
import { returnCurrentUserId } from '../../../helpers/currentUserHelpers';
import { PROFILE_CONTEXTS } from '../../../constants/contextTracking';

class PendingConnectionRequest extends Component {
  render() {
    return <div className={``}>{this._renderUser()}</div>;
  }

  _renderUser = () => {
    return (
      <span className={`pending-connection-request-container border-accent`}>
        <UserContainer userId={this._returnUserId()}>
          <ProfileAvatar size={'medium'} profileLinkQuery={`?context=${PROFILE_CONTEXTS.CONNECTIONS}`} />
        </UserContainer>
        <span className={`user-name-and-info-container`}>
          <UserContainer userId={this._returnUserId()}>
            <UserName displayNameStyle="fullName" profileLinkQuery={`?context=${PROFILE_CONTEXTS.CONNECTIONS}`} />
          </UserContainer>
          {this._renderMutualConnections()}
        </span>
        {this._renderActionButtons()}
      </span>
    );
  };

  _renderActionButtons = () => {
    return (
      <div className={`connection-request-actions`}>
        <ConnectionRequest userId={this._returnUserId()} viewingContext={'Connections Page'} />
      </div>
    );
  };

  _hasReceivedConnectionRequest = () => this.props.requestType === 'received';

  _renderMutualConnections = () => {
    if (this._isGettingMutualConnections()) {
      return this._renderLoadingMutualConnections();
    }
    if (!this._hasMutualConnections()) {
      return null;
    }
    return (
      <MutualConnectionsList
        userIds={this._returnUserMutualConnections()}
        userId={this._returnUserId()}
        maxUserNamesToShow={2}
        emptyString={''}
      />
    );
  };

  _renderLoadingMutualConnections = () => {
    return (
      <div className={`loading-mutual-connections`}>
        <LoadingIcon icon="fading-3balls" size="small" />
      </div>
    );
  };

  _isMutualConnectionsDataAvailable = () =>
    this._returnUserMutualConnectionLookup()[this._returnUserId()] &&
    this._returnUserMutualConnectionLookup()[this._returnUserId()].mutual_connections;
  _isGettingMutualConnections = () =>
    this._returnUserId() in this._returnUserMutualConnectionLookup() && !this._isMutualConnectionsDataAvailable();

  _hasMutualConnections = () =>
    this._isMutualConnectionsDataAvailable() && this._returnUserMutualConnections().length > 0;

  _returnUserMutualConnections = () =>
    this._returnUserMutualConnectionLookup()[this._returnUserId()].mutual_connections;

  _returnUserMutualConnectionLookup = () => this._returnUserMutualConnectionStore().userDict;
  _returnUserMutualConnectionStore = () => this.props.userMutualConnections;

  _returnUser = () => this._returnUserList()[this._returnUserId()];
  _returnUserId = () => this.props.userId;
  _returnUserStore = () => this.props.users;
  _returnUserList = () => this._returnUserStore().userList;

  _returnCurrentUser = () => this.props.currentUser;
  _returnCurrentUserId = () => returnCurrentUserId(this._returnCurrentUser());
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    users: state.users,
    userMutualConnections: state.userMutualConnections,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingConnectionRequest);
