import { logMetricsTrackingEvent } from '../actions';

const _logEvent = (eventName, properties = {}) => {
  logMetricsTrackingEvent(eventName, properties);
};

const _orderEventHandlersLookup = {
  click: {
    placeOrders: (wasWarnings) => {
      const eventName = 'Clicked Place Orders';
      const properties = {
        'Was Warnings': wasWarnings,
      };
      _logEvent(eventName, properties);
    },
  },
};

export const eventTrackingHandlers = {
  orderEvents: {
    ..._orderEventHandlersLookup,
  },
};
