import { SHOW_INFO_ICON_MODAL, HIDE_INFO_ICON_MODAL } from '../constants';

const defaultState = {
  show: false,
  data: null,
};

export default function infoIconReducer(state = defaultState, action) {
  switch (action.type) {
    case SHOW_INFO_ICON_MODAL:
      return {
        ...state,
        show: true,
        data: action.payload,
      };

    case HIDE_INFO_ICON_MODAL:
      return {
        ...state,
        show: false,
        data: null,
      };

    default:
      return state;
  }
}
