export const signInValidation = ({ email, password }) => {
  const validateStates = {
    email: email.trim() !== '',
    password: password.trim() !== '',
  };

  const failedFieldsLength = Object.values(validateStates).filter((value) => {
    return value === false;
  }).length;

  const failedValidation = failedFieldsLength > 0;

  if (!failedValidation) {
    return {
      isValid: true,
    };
  }

  if (failedValidation && failedFieldsLength > 1) {
    return {
      isValid: false,
      validationField: 'multiple',
    };
  }

  if (failedValidation && failedFieldsLength === 1) {
    const validationField = Object.entries(validateStates).filter(([key, value]) => {
      return value === false;
    })[0];

    const fieldName = validationField[0];

    return {
      isValid: false,
      validationField: fieldName,
    };
  }
};
