import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideVerticalNav } from '../../../actions';
import WidthMediaQueryRenderer from '../../ExtraFunctionalityComponents/WidthMediaQueryRenderer';
import OpenPortfolioOptimizationPanelButton from '../../buttons/Nav/OpenPortfolioOptimizationPanelButton';
import { useCurrentUser } from '../../../main/hooks/user';
import { Container } from '../../../main/components/ui';
import styled from 'styled-components';
import { VerticalNavRoutes } from '@src/containers/Nav/verticalNavComponents/VerticalNavRoutes';

const VerticalNavBodyWrapper = styled.div`
  height: calc(100vh - 64px);

  border-top: none !important;
  border-bottom: none !important;
  border-left: none !important;
  // border-right: 1px solid ${({ theme }) => theme.themeColors.border};
  overflow: auto;

  .vertical-nav-group-item-container,
  .vertical-nav-group-item-container.active-nav-item {
    border: none;
  }

  .new-route-container {
    font-size: 10px;
    position: absolute;
    top: -4px;
    right: -25px;
  }
`;

const DocHelperIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>robot-happy-outline</title>
      <path d="M10.5 15.5C10.5 15.87 10.4 16.2 10.22 16.5C9.88 15.91 9.24 15.5 8.5 15.5S7.12 15.91 6.78 16.5C6.61 16.2 6.5 15.87 6.5 15.5C6.5 14.4 7.4 13.5 8.5 13.5S10.5 14.4 10.5 15.5M23 15V18C23 18.55 22.55 19 22 19H21V20C21 21.11 20.11 22 19 22H5C3.9 22 3 21.11 3 20V19H2C1.45 19 1 18.55 1 18V15C1 14.45 1.45 14 2 14H3C3 10.13 6.13 7 10 7H11V5.73C10.4 5.39 10 4.74 10 4C10 2.9 10.9 2 12 2S14 2.9 14 4C14 4.74 13.6 5.39 13 5.73V7H14C17.87 7 21 10.13 21 14H22C22.55 14 23 14.45 23 15M21 16H19V14C19 11.24 16.76 9 14 9H10C7.24 9 5 11.24 5 14V16H3V17H5V20H19V17H21V16M15.5 13.5C14.4 13.5 13.5 14.4 13.5 15.5C13.5 15.87 13.61 16.2 13.78 16.5C14.12 15.91 14.76 15.5 15.5 15.5S16.88 15.91 17.22 16.5C17.4 16.2 17.5 15.87 17.5 15.5C17.5 14.4 16.61 13.5 15.5 13.5Z" />
    </svg>
  );
};
const Body = () => {
  const nav = useSelector((state) => state.nav);
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const isAccountClosed = currentUser.is_account_closed;
  const { alwaysOpen } = nav;

  const collapseNav = () => {
    if (!alwaysOpen) {
      hideVerticalNav()(dispatch);
    }
  };

  if (isAccountClosed) {
    return (
      <VerticalNavBodyWrapper className="vertical-nav-body">
        <VerticalNavRoutes />

        <div style={{ padding: '25px' }} />
      </VerticalNavBodyWrapper>
    );
  }

  return (
    <VerticalNavBodyWrapper className="vertical-nav-body">
      <WidthMediaQueryRenderer maxWidth={976}>
        <Container horizontal={8}>
          <OpenPortfolioOptimizationPanelButton closeNav={collapseNav} inVerticalNav />
        </Container>
      </WidthMediaQueryRenderer>
      <VerticalNavRoutes />
      <div style={{ padding: '25px' }} />
    </VerticalNavBodyWrapper>
  );
};

export default Body;
