import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { withRouter } from '../../main/utils';
import { PageHeading } from '../../components/UI/PageHeading';
import { createBasicErrorModal } from '../../constants/modals';
import { parseQueryString } from '../../helpers/routerHelpers';
import { renderInput } from '../../helpers/inputHelpers';
import { returnCurrentUserId } from '../../helpers/currentUserHelpers';
import {
  sortUsersByLastMonthPerf,
  sortUsersByLastName,
  sortUsersByLastWeekPerf,
  sortUsersByThisMonthPerf,
  sortUsersByTodayPerf,
} from '../../helpers/sortHelpers';
import * as Actions from '../../actions/index';
import ConnectionRow from './components/ConnectionRow';
import ConnectionsList from '../Profile/ConnectionsList';
import Dropdown from '../Inputs/Dropdown';
import LiveTradingUsers, { StyledLiveTradingUsersDisclaimer } from '../UI/Disclaimers/LiveTradingUsers';
import Page from '../../components/layout/Page';
import PageLoading from '../../components/PageLoading';

class ConnectionsTable extends Component {
  constructor(props) {
    super(props);

    this.SORT_BY_KEYS = {
      LAST_NAME: 'last_name',
      PERF_TODAY: 'today',
      PERF_PREV_WEEK: 'prev_week',
      PERF_PREV_MONTH: 'prev_month',
      PERF_THIS_MONTH: 'this_month',
    };

    this.PERF_SORT_BY_KEYS = [
      this.SORT_BY_KEYS.PERF_TODAY,
      this.SORT_BY_KEYS.PERF_PREV_WEEK,
      this.SORT_BY_KEYS.PERF_PREV_MONTH,
      this.SORT_BY_KEYS.PERF_THIS_MONTH,
    ];

    this.SORT_BY_OPTIONS = [
      {
        id: 0,
        key: this.SORT_BY_KEYS.LAST_NAME,
        name: this.SORT_BY_KEYS.LAST_NAME,
        displayName: 'Last Name',
        isEnabled: () => true,
        disabledReason: () => null,
      },
      {
        id: 1,
        key: this.SORT_BY_KEYS.PERF_TODAY,
        name: this.SORT_BY_KEYS.PERF_TODAY,
        displayName: 'Today',
        isEnabled: () => true,
        disabledReason: () => null,
      },
      {
        id: 2,
        key: this.SORT_BY_KEYS.PERF_THIS_MONTH,
        name: this.SORT_BY_KEYS.PERF_THIS_MONTH,
        displayName: 'This Month',
        isEnabled: () => true,
        disabledReason: () => null,
      },
      {
        id: 3,
        key: this.SORT_BY_KEYS.PERF_PREV_MONTH,
        name: this.SORT_BY_KEYS.PERF_PREV_MONTH,
        displayName: 'Prev Month',
        isEnabled: () => true,
        disabledReason: () => null,
      },
      {
        id: 4,
        key: this.SORT_BY_KEYS.PERF_PREV_WEEK,
        name: this.SORT_BY_KEYS.PERF_PREV_WEEK,
        displayName: 'Prev Week',
        isEnabled: () => true,
        disabledReason: () => null,
      },
    ];

    this.SORT_KEY_EVENT_NAMES = {
      [this.SORT_BY_KEYS.LAST_NAME]: 'Last Name',
      [this.SORT_BY_KEYS.PERF_TODAY]: 'Today',
      [this.SORT_BY_KEYS.PERF_THIS_MONTH]: 'This Month',
      [this.SORT_BY_KEYS.PERF_PREV_MONTH]: 'Prev Month',
      [this.SORT_BY_KEYS.PERF_PREV_WEEK]: 'Prev Week',
    };

    this.SORT_FUNC = {
      [this.SORT_BY_KEYS.LAST_NAME]: sortUsersByLastName,
      [this.SORT_BY_KEYS.PERF_TODAY]: sortUsersByTodayPerf,
      [this.SORT_BY_KEYS.PERF_THIS_MONTH]: sortUsersByThisMonthPerf,
      [this.SORT_BY_KEYS.PERF_PREV_MONTH]: sortUsersByLastMonthPerf,
      [this.SORT_BY_KEYS.PERF_PREV_WEEK]: sortUsersByLastWeekPerf,
    };

    this.DropdownInput = {
      type: Dropdown,
      typeName: 'Dropdown',
      name: '_sortByKeyObj',
      label: 'Order By',
      required: true,
      props: {
        styling: 'dropdown-inline-left',
        values: this.SORT_BY_OPTIONS,
        includeLabelAsSelection: false,
        handleAdditionalActions: this._handleSortChange,
        showArrowIcons: true,
      },
    };

    this.DEFAULT_SORT_KEY = this._convertSortByKeyToSortByKeyObj(this.SORT_BY_KEYS.LAST_NAME);
    this.state = {
      _isLoadingConnectionPerformance: true,
      _wasError: false,

      formData: {
        _sortByKeyObj: this.DEFAULT_SORT_KEY,
      },
    };
  }

  componentDidMount() {
    this._parseDefaultSortFromURL();
    this._getConnectionData();
    this._getConnectionsPerformance();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this._parseDefaultSortFromURL();
    }

    const didJustConnectWithNewUser = this._returnConnectionsCount(prevProps) !== this._returnConnectionsCount();
    if (didJustConnectWithNewUser) {
      const prevConnectionsList = this._returnConnectionListByUserIdForTable(prevProps);
      const currentConnectionsList = this._returnConnectionListByUserIdForTable();
      const newConnectionUserId = currentConnectionsList.filter((id) => !prevConnectionsList.includes(id))[0];
      this._getUserPerformance(newConnectionUserId);
    }

    if (!prevState._wasError && this.state._wasError) {
      this._renderErrorModalMessage();
    }
  }

  render() {
    return (
      <div className={'connections-table-panel'}>
        <div className={'table-header-outside-container'}>
          <StyledLiveTradingUsersDisclaimer />
        </div>
        <Page className={'connections-table-page-container'}>
          <PageHeading>Connections</PageHeading>
          <ConnectionRow
            userId={this._returnCurrentUserId()}
            showWeeklyInsteadOfDailyPerf={this._returnSortByKey() === this.SORT_BY_KEYS.PERF_PREV_WEEK}
          />
          <div className={'connections-table-sort-options'}>
            {renderInput.call(this, this.DropdownInput)}
            {this.state._isLoadingConnectionPerformance && (
              <div className="loading-connection-performances-message-container">
                <p className="secondary-text-color">Loading Idea Performance...</p>
              </div>
            )}
          </div>
          <div className={'connections-table-container'}>
            {this._forceTableLoadingState() ? (
              <PageLoading flatStyle />
            ) : (
              <div>
                <ConnectionsList
                  connectionListById={this._sortConnectionsListReturnUserIds()}
                  userId={this._returnCurrentUserId()}
                  isCurrentUsersProfile={true}
                  renderType="connectionsTable"
                  dontGetData
                  showWeeklyInsteadOfDailyPerf={this._returnSortByKey() === this.SORT_BY_KEYS.PERF_PREV_WEEK}
                />
              </div>
            )}
          </div>
        </Page>
      </div>
    );
  }

  _forceTableLoadingState = () => (this._isSortingByPerformance() ? this.state._isLoadingConnectionPerformance : false);

  _renderErrorModalMessage = () => {
    const modalMessage = 'An error occurred while loading connections. Please try again later.';
    const modal = {
      contentComponent: createBasicErrorModal(modalMessage),
      dismissable: true,
    };
    this.props.actions.showModal(modal);
  };

  _isSortingByPerformance = () => this.PERF_SORT_BY_KEYS.includes(this._returnSortByKey());

  _getConnectionData = () => {
    const userId = this._returnCurrentUserId();
    this.props.actions.getUserConnections(userId);
  };

  _getConnectionsPerformance = () => {
    const userIds = this._returnConnectionListByUserIdForTable();
    this.props.actions.getUserIdeaPerformances(userIds).then((response) => {
      const wasSuccess = response && response.data;
      if (wasSuccess) {
        this._handleGetConnectionPerformance();
      } else {
        this._handleGetConnectionPerformanceError();
      }
    });
  };

  _getUserPerformance = (userId) => {
    this.props.actions.getUserIdeaPerformances([userId]);
  };

  _handleGetConnectionPerformance = () => {
    this._setCompleteLoading();
  };

  _handleGetConnectionPerformanceError = () => {
    this._setCompleteLoading();
    this._setErrorLoading();
  };

  _returnConnectionsDataForSortFunc = () => {
    return this._returnConnectionListByUserIdForTable().map((userId) => ({
      user_id: userId,
      ...this._returnUser(userId),
      ...this._returnUserIdeaPerformanceData(userId),
    }));
  };

  _returnConnectionListByUserIdForTable = (props) => {
    const userId = this._returnCurrentUserId();
    const connectionStore = this._returnUserConnectionStore(props);
    if (!(userId in connectionStore)) {
      // console.error('User data not found in connection store', { connectionStore });
    }
    return connectionStore[userId].connections || [];
  };

  _returnConnectionsCount = (props) => this._returnConnectionListByUserIdForTable(props).length;

  _returnUserConnectionStore = (props) => (props || this.props).userConnections.userDict;

  _returnUserIdeaPerformanceData = (userId) => {
    const userIdeaPerformanceStore = this._returnUserIdeaPerformanceStoreLookup();
    return userId in userIdeaPerformanceStore ? userIdeaPerformanceStore[userId] : null;
  };

  _handleSortChange = (changeValue, name) => {
    this._updateURLwithSortParam({ key: changeValue.key });
    this._logSortChange(changeValue.key);
  };

  _convertSortByKeyToSortByKeyObj = (sortKey) => this.SORT_BY_OPTIONS.filter((obj) => obj.key === sortKey)[0];

  _setSortBy = (param) => {
    const data = typeof param === 'object' ? param : this._convertSortByKeyToSortByKeyObj(param);
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        _sortByKeyObj: data,
      },
    }));
  };

  _returnSortByKeyObj = () => this.state.formData._sortByKeyObj;

  _returnSortByKey = () => this._returnSortByKeyObj().key;

  _sortConnectionsListReturnUserIds = () => {
    const users = this._returnConnectionsDataForSortFunc();
    const sortedUsers = this.SORT_FUNC[this._returnSortByKey()](users);
    return sortedUsers.map((user) => user.user_id);
  };

  _returnUserIdeaPerformanceStoreLookup = () => this._returnUserIdeaPerformanceStore().userDict;

  _returnUserIdeaPerformanceStore = () => this.props.userIdeaPerformance;

  _returnUsersStore = () => this.props.users;

  _returnUserList = () => this._returnUsersStore().userList;

  _returnUser = (userId) => this._returnUserList()[userId];

  _returnCurrentUser = () => this.props.currentUser;

  _returnCurrentUserId = () => returnCurrentUserId(this._returnCurrentUser());

  _parseDefaultSortFromURL = () => {
    const queryString = this.props.location.search;
    const query = parseQueryString(queryString);
    const sortParam = query.sort;
    if (sortParam) {
      const isSortParamValid = this.SORT_BY_OPTIONS.some((option) => option.key === sortParam);
      if (isSortParamValid) {
        this._setSortBy(sortParam);
      }
    } else {
      if (!('panel' in query)) {
        this._updateURLwithSortParam({ replaceHistory: true });
      }
    }
  };

  _updateURLwithSortParam = (config) => {
    const key = config && config.key ? config.key : this._returnSortByKey();
    const newUrl = `${this.props.location.pathname}?sort=${key}`;
    if (config && config.replaceHistory) {
      this.props.navigate(newUrl);
    } else {
      this.props.navigate(newUrl);
    }
  };

  _setErrorLoading = () => {
    this.setState(() => ({
      _wasError: true,
    }));
  };

  _setCompleteLoading = () => {
    this.setState(() => ({
      _isLoadingConnectionPerformance: false,
    }));
  };

  _logSortChange = (sortKey) => {
    const event = 'Changed Connections Table Sort';
    const properties = {
      'Current Sort': this.SORT_KEY_EVENT_NAMES[this._returnSortByKey()],
      'Sorted By': this.SORT_KEY_EVENT_NAMES[sortKey],
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    userConnections: state.userConnections,
    userIdeaPerformance: state.userIdeaPerformance,
    users: state.users,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ConnectionsTable);

export default composedComponent;
