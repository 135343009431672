import React from 'react';
import { connect } from 'react-redux';
import { parseDate, isDateBefore } from '../../../helpers/timeHelpers';

class EquityHistoryData extends React.PureComponent {
  render() {
    const {
      hasEnoughDataToDisplay,
      isLoadingEquityHistory,

      children,
    } = this.props;

    const chartData = hasEnoughDataToDisplay ? this._getEquityHistoryDataFilteredForTimePeriod() : null;
    const isLoading = isLoadingEquityHistory && !hasEnoughDataToDisplay;
    const isUpdating = isLoadingEquityHistory && hasEnoughDataToDisplay;
    return children(chartData, isLoading, isUpdating);
  }

  _getEquityHistoryDataFilteredForTimePeriod = () => {
    const { activeTab } = this.props;

    const timeFilterCutoff = activeTab.cutoffDate;
    if (timeFilterCutoff) {
      return this._getRawEquityHistoryData().filter(
        (timeData) => !isDateBefore(parseDate(timeData.time * 1000), timeFilterCutoff)
      );
    } else {
      return this._getRawEquityHistoryData();
    }
  };

  _getRawEquityHistoryData = () => this.props.equity_history;
}

const mapStateToProps = (state) => {
  const historyStore = state.history;
  return {
    equity_history: historyStore.equity_history,
    hasEnoughDataToDisplay: historyStore.is_enough_for_chart,
    isLoadingEquityHistory: historyStore.isLoadingEquityHistory,
  };
};

export default connect(mapStateToProps)(EquityHistoryData);
