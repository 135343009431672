import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../../../actions/index';

import LinkTo from '../../../Links/LinkTo';
import Rating from '../../../../components/misc/RatingDisplay';
import IconWithString from '../../../UI/IconWithString';

import { snakeToHyphens, isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import {
  isIdeaUsingPriceTarget,
  calculateExpectedReturn,
  findUserIdeaForSecurity,
} from '../../../../helpers/ideaHelpers';
import { formatDataValue } from '../../../../helpers/securitiesHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';

export class PortfolioIdeaCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;

    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? (
          this.props.loadingCellComponent
        ) : (
          <LinkTo
            to={this._returnLinkForHandlingClick()}
            className={''}
            additionalClickAction={this.props.logSecurityPanelOpenClick}
            child={this._renderCellContent()}
            noTextDecoration
          />
        )}
      </td>
    );
  }

  _isLoading = () => {
    const { userId, securityId } = this.props;
    const security = this.props.securities.lookup[securityId];

    const ideaUserId = userId || this.props.currentUser.user_id;
    const isLoading = this.props.ideas.loadingCurrentUserIdeas || !(securityId in this.props.securities.lookup);

    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    return isLoading || !idea || !security || this._isPriceLoading();
  };

  _returnLinkForHandlingClick = () => {
    const { userId, securityId } = this.props;
    const currentUserId = this.props.currentUser.user_id;
    const ideaUserId = userId || currentUserId;

    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    const ideaId = idea && idea.idea_id;

    const additionalProps = {
      activeTab: 'ideas',
      ideaId,
    };
    const openSecurityPanelConfig = {
      securityId,
      additionalProps,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    const { userId, securityId } = this.props;
    const security = this.props.securities.lookup[securityId];
    const ideaUserId = userId || this.props.currentUser.user_id;

    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);

    const isHorizonShortTerm = idea.horizon && idea.horizon.id === 0;
    const conviction = idea.conviction;
    const ideaUsingPriceTarget = isIdeaUsingPriceTarget(idea);
    const currentPrice = this._returnCurrentPrice();
    const rawExpectedReturn = calculateExpectedReturn({
      idea,
      security,
      currentPrice,
    });
    const expectedReturn = formatDataValue(rawExpectedReturn, 'percentage');
    const priceTarget = ideaUsingPriceTarget ? formatDataValue(idea.price_target, 'price') : null;

    return (
      <span className="security-table-portfolio-idea-cell">
        <span className={`security-table-expected-return-container`}>
          <span className={`expected-return  `}>{expectedReturn}</span>
          {priceTarget && (
            <span className={`price-target`}>
              <IconWithString string={priceTarget} textClassName={' '} icon={this._returnBullseyeIconProps()} />
            </span>
          )}
        </span>
        {isHorizonShortTerm && <span className={`security-table-horizon secondary-text-color`}>Short Term</span>}
        <span className={`security-table-conviction`}>
          <span className={`idea-value secondary-text-color`}>
            <Rating
              name="conviction"
              label=""
              value={conviction}
              maxRating={5}
              ratingDescriptionSuffix={' Conviction'}
              hideRatingCircles={true}
            />
          </span>
        </span>
      </span>
    );
  };

  _isAPreviousValueAvailable = () => !isUndefinedOrNull(this._returnCurrentPrice());

  _isPriceLoading = () =>
    (!this._returnCurrentPriceData() || this._returnCurrentPriceData().loading) && !this._isAPreviousValueAvailable();

  _returnCurrentPriceData = () => this.props.securitiesPrice.securities[this.props.securityId];

  _returnCurrentPrice = () => (this._returnCurrentPriceData() ? this._returnCurrentPriceData().current_price : {});

  _returnBullseyeIconProps = () => ({
    icon: 'fa-bullseye',
    colorClassName: 'bullseye-icon-color',
    size: 'small',
    style: {
      fontSize: '16px',
      position: 'absolute',
      top: '0px',
      left: '-17px',
    },
  });
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    ideas: state.ideas,
    portfolio: state.portfolio,
    securities: state.securities,
    securitiesPrice: state.securitiesPrice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(PortfolioIdeaCell);

export default composedComponent;
