import { logMetricsTrackingEvent } from '../../../actions';
import { ADJUST_EVENT_NAMES, logAdjustEvent } from '@src/main/lib/nvstr-common-ui.es';

const COMPLETED_LIVE_SIGNUP = {
  send: (properties) => {
    const event = 'Finish Onboarding';
    logMetricsTrackingEvent(event, properties)();

    logAdjustEvent(ADJUST_EVENT_NAMES.COMPLETED_LIVE_SIGNUP);
  },
};

const COMPLETED_PAPER_SIGNUP = {
  send: (properties) => {
    const event = 'Finish Onboarding';
    logMetricsTrackingEvent(event, properties)();

    logAdjustEvent(ADJUST_EVENT_NAMES.COMPLETED_PAPER_SIGNUP);
  },
};

const trackingEvents = {
  COMPLETED_LIVE_SIGNUP,
  COMPLETED_PAPER_SIGNUP,
};

export default trackingEvents;
