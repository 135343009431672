import { SET_APP_HEIGHT } from '../../constants';

const defaultState = {
  value: null,
};

export default function appHeightReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_APP_HEIGHT:
      const value = action.payload;
      if (value !== state.value) {
        return {
          value,
        };
      } else {
        return state;
      }

    default:
      return state;
  }
}
