import { orderActions } from '@src/constants';

const defaultState = {
  // is_market_open: null, // DO NOT USE THIS VALUE

  ordersLookup: {},

  not_placed_orders: [],
  placed_orders: [],
  awaiting_approval_orders: [],
  deleted_orders: [], // used in event delete fails and need to recreate order
};

const buildLookupFromPayload = (p) => {
  const l = {};
  const { not_placed_orders, placed_orders, awaiting_approval_orders, deleted_orders } = p;

  not_placed_orders?.forEach((o) => {
    l[o.id] = o;
  });
  placed_orders?.forEach((o) => {
    l[o.id] = o;
  });
  awaiting_approval_orders?.forEach((o) => {
    l[o.id] = o;
  });
  deleted_orders?.forEach((o) => {
    l[o.id] = o;
  });

  return l;
};

export default function ordersReducer(state = defaultState, action) {
  switch (action.type) {
    case orderActions.getAllOrdersData: {
      const p = action.payload;
      const lookupUpdates = buildLookupFromPayload(p);
      return {
        ...state,
        ...p,
        ordersLookup: {
          ...state.ordersLookup,
          ...lookupUpdates,
        },
      };
    }

    case orderActions.placedOrders: {
      const orders = action.payload;
      const newOrdersLookupData = {};
      orders.forEach((o) => (newOrdersLookupData[o.id] = o));
      return {
        ...state,
        ordersLookup: {
          ...state.ordersLookup,
          ...newOrdersLookupData,
        },
        placed_orders: [...state.placed_orders, ...orders],
      };
    }

    case orderActions.createCartOrder: {
      const order = action.payload;
      return {
        ...state,
        ordersLookup: {
          ...state.ordersLookup,
          [order.id]: order,
        },
        not_placed_orders: [...state.not_placed_orders, order],
      };
    }

    case orderActions.updateOrder: {
      const order = action.payload;
      return {
        ...state,
        ordersLookup: {
          ...state.ordersLookup,
          [order.id]: order,
        },
        not_placed_orders: state.not_placed_orders.map((o) => (o.id === order.id ? order : o)),
      };
    }

    case orderActions.deleteOrder: {
      const orderId = action.payload;
      return {
        ...state,
        not_placed_orders: state.not_placed_orders.filter((o) => o.id !== orderId),
      };
    }

    case orderActions.deleteAllOrders: {
      return {
        ...state,
        not_placed_orders: [],
      };
    }

    default:
      return state;
  }
}
