import React from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { returnSecurityPanelUrl } from '../../helpers/securityPanelHelpers';
import { getDefaultStockPanelToFinancialTabTestGroupFromCurrentUser } from '../../helpers/currentUserHelpers';

class LinkWrapper extends React.PureComponent {
  render() {
    const {
      customClassName,
      withLink,
      linkTo,

      children,
    } = this.props;

    const el = withLink ? Link : 'span';
    const defaultProps = {
      className: `security-identifier-display   ${customClassName || ''}`,
    };
    const props = withLink ? { to: linkTo } : {};

    return React.createElement(el, { ...defaultProps, ...props }, children);
  }
}

class SecurityIdentifierDisplay extends React.PureComponent {
  render() {
    const {
      // required
      symbol,
      name,
      showSymbol,
      showName,

      // optional
      securityId,
      prefixString,
      prefix,
      suffix,
      linkToPage,
      customClassName,
      limitNameWidth,
      activeTab,

      securityPanelTestGroup,
      inFeed,
    } = this.props;
    const location = window.location;
    const withLink = 'withLink' in this.props ? this.props.withLink : true;

    let defaultActiveTab = 'ideas';
    if (inFeed) {
      defaultActiveTab = securityPanelTestGroup === 1 ? 'compare' : 'ideas';
    }
    const additionalProps = {
      activeTab: activeTab || defaultActiveTab,
    };
    const openSecurityPanelConfig = {
      location,
      securityId,
      additionalProps,
    };

    const urlToOpenSecurityPanel = returnSecurityPanelUrl(openSecurityPanelConfig);
    const linkTo = linkToPage
      ? `/securities/${securityId}?activeTab=${activeTab || 'ideas'}`
      : `${urlToOpenSecurityPanel}`;

    const renderPrefixString = prefixString ? (
      <span className={'security-symbol-prefix-string text'}>{prefixString}</span>
    ) : null;
    const renderSecuritySymbol = showSymbol ? <span className={'security-symbol text'}>{symbol}</span> : null;
    const renderSecurityName = showName ? (
      <span className={`security-name text ${limitNameWidth ? 'limit-name-width' : ''}`}>{name}</span>
    ) : null;
    const renderPrefix = prefix ? <span className={'prefix prefix-suffix text'}>{prefix}</span> : null;
    const renderSuffix = suffix ? <span className={'suffix prefix-suffix text'}>{suffix}</span> : null;

    return (
      <LinkWrapper customClassName={customClassName} withLink={withLink} linkTo={linkTo}>
        <span>
          {renderPrefixString}
          {renderSecuritySymbol}
          {renderPrefix}
          {renderSecurityName}
          {renderSuffix}
        </span>
      </LinkWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    securityPanelTestGroup: getDefaultStockPanelToFinancialTabTestGroupFromCurrentUser(state.currentUser),
  };
};

export default connect(mapStateToProps)(SecurityIdentifierDisplay);
