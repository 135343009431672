import React, { Component } from 'react';

import IconButton from '../../components/buttons/IconButton';
import NewGenericDropdown from '../UI/Dropdowns/NewGenericDropdown';

import { logMetricsTrackingEvent } from '../../actions';

class MoreActionsButton extends Component {
  render() {
    return (
      <div className={'more-actions-button-container'}>
        <IconButton
          customClass="connections-table-more-user-actions horizontal-list-change-page-btn"
          icon={'fa fa-ellipsis-h'}
          size="small"
          handleClick={this._doNothing}
        />
        <NewGenericDropdown
          id={this.props.dropdownId}
          style={{ width: '160px' }}
          additionalShowFunc={this._logMoreActionsClick}
          dropdownItemDataList={this.props.dropdownChildrenDataList}
        />
      </div>
    );
  }

  _doNothing = () => false;

  _logMoreActionsClick = () => {
    if (!this.props.eventContext) {
      return console.error('MoreActions is missing this.props.eventContext');
    }
    const event = 'Clicked More Actions';
    const properties = {
      Context: this.props.eventContext,
    };
    logMetricsTrackingEvent(event, properties)();
  };
}

export default MoreActionsButton;
