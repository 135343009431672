import React from 'react';
import { Gradient } from '../backgrounds';
import { Page } from '../layout';
import { Text } from '../text';
import { Body2, defineFontSettings, Body5 } from '../../lib/nvstr-common-ui.es';
import { colorPalette, SCREEN_SIZES, COLOR_THEME_NAMES } from '../../lib/nvstr-utils.es';
import { FlatButton } from '../buttons';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/paths';
import styled from 'styled-components';
import { TrackingEvents } from '../../../utils/tracking/events';
import { useCurrentUserId } from '../../hooks/user';
import { useLearnLesson } from '../../hooks/learn/useLearnLesson';
import { TrackIteratively } from '../../../utils/itly';
import { CheckCircleFilled } from '../../icons';
import { PRODUCT_DISPLAY_FULL_NAME } from '../../../appConfig';

const IconWrapper = styled.div`
  text-align: center;
  padding-top: 2px;
  padding-right: 16px;

  svg {
    height: 24px;
    width: 24px;
    g {
      fill: ${colorPalette.primary.charcoal};
    }
  }
`;

const ButtonWrapper = styled.div`
  button {
    width: 268px;
  }
`;

const SuccessTileWrapper = styled.div`
  max-width: ${({ width }) => width || '800px'};
  position: relative;
  margin: 12px auto;
  padding: 16px 24px;
  border-radius: 5px;
  text-align: left;
  cursor: default;

  background: ${({ theme, transparentBackground }) =>
    transparentBackground
      ? 'transparent'
      : theme.screenSize === SCREEN_SIZES.mobile
      ? theme.colorThemeName === COLOR_THEME_NAMES.light
        ? colorPalette.secondary.white
        : colorPalette.primary.charcoal
      : theme.themeColors.component};
  color: ${({ theme }) => theme.themeColors.text};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;

    max-width: 100%;
    width: 100%;
    height: ${({ expandHeight, height }) =>
      height || expandHeight ? 'calc(100vh - 64px)' : 'unset'}; // 64px === nav height
    max-height: ${({ expandHeight }) => (expandHeight ? '800px' : 'unset')}; // 64px === nav height
  }

  @media (max-width: 390px) {
    padding: 16px;
  }
  @media (max-width: 340px) {
    padding: 8px;
  }
`;

const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const fontSettings = defineFontSettings(
  {
    fontSize: '18px',
    lineHeight: '24px',
  },
  {
    fontSize: '36px',
    lineHeight: '42px',
  }
);

const SuccessDisplay = () => {
  const textColor = colorPalette.primary.charcoal;

  const fontSettings = defineFontSettings(
    {
      fontSize: '18px',
      lineHeight: '24px',
    },
    {
      fontSize: '24px',
      lineHeight: '36px',
    }
  );
  return (
    <SuccessWrapper>
      <IconWrapper>
        <CheckCircleFilled />
      </IconWrapper>

      <Text fontSettings={fontSettings} color={textColor}>
        Lesson complete
      </Text>
    </SuccessWrapper>
  );
};

const ToHomeCTA = ({ lessonId }) => {
  const navigate = useNavigate();
  const { lesson: lessonData } = useLearnLesson(lessonId);
  const { name: lessonName, sponsorId, metadata } = lessonData;

  const textColor = colorPalette.primary.charcoal;
  const hoverTextColor = colorPalette.primary.oak;

  const handleHomePress = () => {
    TrackingEvents.learn.PRESS_TO_HOME_SHARED_LEARN_LESSON.send({
      'Lesson ID': lessonId,
    });

    navigate(ROUTES.DASHBOARD.build());
  };

  return (
    <div style={{ textAlign: 'center', padding: '16px 0' }}>
      <ButtonWrapper>
        <FlatButton color={textColor} textColor={hoverTextColor} onClick={handleHomePress}>
          To Home
        </FlatButton>
      </ButtonWrapper>
    </div>
  );
};

const SignupCTA = ({ lessonId }) => {
  const navigate = useNavigate();
  const { lesson: lessonData } = useLearnLesson(lessonId);
  const { name: lessonName, sponsorId, metadata } = lessonData;

  const textColor = colorPalette.primary.charcoal;
  const hoverTextColor = colorPalette.primary.oak;

  const fontSettings = defineFontSettings(
    {
      fontSize: '18px',
      lineHeight: '24px',
    },
    {
      fontSize: '36px',
      lineHeight: '48px',
    }
  );

  const handleSignUpNowPress = () => {
    TrackingEvents.learn.PRESS_SIGNUP_NOW_SHARED_LEARN_LESSON.send({
      'Sponsor ID': sponsorId,
      'Lesson ID': lessonId,
      Subject: lessonName,
    });

    navigate(ROUTES.SIGNUP.build());
  };

  return (
    <div style={{ textAlign: 'center', padding: '16px 0 24px 0' }}>
      <Text fontSettings={fontSettings} bold center color={textColor}>
        Want more?
      </Text>

      <div style={{ textAlign: 'center', padding: '36px 0 0 0' }}>
        <div style={{ maxWidth: '540px', margin: '0 auto' }}>
          <Body2>
            Sign up for a FREE {PRODUCT_DISPLAY_FULL_NAME} account to get access to daily Learn & Earn content and start
            earning rewards!
          </Body2>
        </div>
      </div>

      <div style={{ textAlign: 'center', padding: '16px 0 0 0' }}>
        <Body5 bold>Investors on {PRODUCT_DISPLAY_FULL_NAME} grow together.</Body5>
      </div>

      <div style={{ textAlign: 'center', padding: '36px 0 16px 0' }}>
        <ButtonWrapper>
          <FlatButton color={textColor} textColor={hoverTextColor} onClick={handleSignUpNowPress}>
            SIGN UP NOW
          </FlatButton>
        </ButtonWrapper>
      </div>
    </div>
  );
};

export const LearnPublicLessonComplete = (props) => {
  const { lessonId } = props;

  const currentUserId = useCurrentUserId();
  const isLoggedIn = !!currentUserId;
  const { lesson: lessonData } = useLearnLesson(lessonId);
  const { name: lessonName, sponsorId } = lessonData;

  React.useEffect(() => {
    TrackingEvents.learn.VIEW_SHARED_LEARN_LESSON_COMPLETE_SCREEN.send({
      'Sponsor ID': sponsorId,
      'Lesson ID': lessonId,
      Subject: lessonName,
    });

    TrackIteratively.learn.LEARN_LESSON_REWARD_VIEWED.send({
      lesson_id: lessonId,
      lesson_name: lessonName,
      xp_possible: 0,
    });
  }, []);

  return (
    <div>
      <SuccessTileWrapper>
        <SuccessDisplay />
      </SuccessTileWrapper>
      <Page maxWidth={'600px'} style={{ position: 'relative', overflow: 'hidden' }}>
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }}>
          <Gradient />
        </div>
        <div style={{ position: 'relative', zIndex: 2 }}>
          {isLoggedIn ? <ToHomeCTA lessonId={lessonId} /> : <SignupCTA lessonId={lessonId} />}
        </div>
      </Page>
    </div>
  );
};
