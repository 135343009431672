import { getSecuritySymbolFromIdea } from './ideaHelpers';
import { REGEX_LOOKUP } from '../constants/regex';

const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT || 'development';

export const stripLeadingAndTrailingWhiteSpace = function(string) {
  const stringArray = string.split('');
  let leading = true;
  let trailing = true;

  let leadingI = 0;
  let trailingI = string.length - 1;

  while (leading) {
    if (stringArray[leadingI] === ' ' && leadingI !== trailingI) {
      leadingI = leadingI + 1;
    } else {
      leading = false;
    }
  }

  while (trailing) {
    if (stringArray[trailingI] === ' ' && trailingI !== 0) {
      trailingI = trailingI - 1;
    } else {
      trailing = false;
    }
  }

  return string.slice(leadingI, trailingI + 1);
};

export const truncateName = (word, length) => {
  if (word.length <= length) {
    return word;
  }

  if (word[length - 1] === ' ') {
    return `${word.slice(0, length - 2)}...`;
  }
  return `${word.slice(0, length - 1)}...`;
};

export const truncateNameSafe = (word, length, nullVal) => {
  if (isUndefinedOrNull(word)) {
    if (nullVal === undefined) {
      return word;
    } else {
      return nullVal;
    }
  }

  return truncateName(word, length);
};

export function convertObjectToArray(object) {
  const keys = Object.keys(object);
  return keys.map((key) => object[key]);
}

export function convertArrayToObject(array, primaryKey) {
  const obj = {};
  if (primaryKey === undefined) {
    array.forEach((el) => (obj[Object.keys(el)[0]] = el[Object.keys(el)[0]]));
  } else {
    array.forEach((el) => (obj[el[primaryKey]] = { ...el }));
  }
  return obj;
}

export function calcPercIncrease(prevNum, currNum) {
  return ((currNum - prevNum) / prevNum) * 100;
}

export const findIdKey = (obj) => {
  const keys = Object.keys(obj);
  const idKey = keys.filter((key) => key.slice(key.length - 3, key.length) === '_id')[0];
  return idKey;
};

export const isUndefinedOrNull = (v) => {
  return v === null || v === undefined;
};

export function filterObject(object, expression) {
  const objectArray = convertObjectToArray(object);
  return objectArray.filter(expression);
}

export function scrollToTop(customSpeed) {
  const speed = isUndefinedOrNull(customSpeed) ? 500 : customSpeed;
  return window.$('html, body').animate(
    {
      scrollTop: 0,
    },
    speed
  );
}

export function objectMap(object, cb) {
  const keys = Object.keys(object);
  const arrayOfObjProps = keys.map((key) => cb(key, object[key])).filter((el) => el);
  return convertArrayToObject(arrayOfObjProps);
}

export function includes(element, array) {
  for (let i = 0; i < array.length; i++) {
    const el = array[i];
    if (el === element) {
      return true;
    }
  }
  return false;
}

export function sizeStepper(direction, currentSize) {
  const sizes = ['small', 'medium', 'large'];
  const directionNum = direction === 'expand' ? 1 : -1;
  const currentSizeIndex = sizes.indexOf(currentSize);
  if (
    (currentSizeIndex === 0 && direction === 'collapse') ||
    (currentSizeIndex === sizes.length - 1 && direction === 'expand')
  ) {
    return currentSize;
  }
  return sizes[currentSizeIndex + directionNum];
}

export function denestArray(array) {
  return [].concat.apply([], array);
}

export function reverseKeys(obj) {
  const keys = Object.keys(obj);
  const newObj = {};

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    newObj[obj[key]] = key;
  }

  return newObj;
}

export function snakeToTitleCase(string) {
  if (string.indexOf('_') >= 0) {
    return string
      .split('_')
      .map((string) => toCapitalCase(string))
      .join(' ');
  }
  return toCapitalCase(string);
}

export function snakeToHyphens(string) {
  if (string.indexOf('_') >= 0) {
    return string.split('_').join('-');
  }
  return string;
}

export function toLowerCase(string) {
  if (!string) {
    console.error('Param was not valid, received:', string);
    return string;
  }
  if (typeof string !== 'string') {
    console.error(`Param was not a string, received:, ${typeof string}`);
    return string;
  }
  return string.toLowerCase();
}

export function toCapitalCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function toTitleCase(string) {
  return string
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.substr(1).toLowerCase())
    .join(' ');
}

export function findIndexOfObjectInArray(uniqueKey, object, array) {
  for (let i = 0; i < array.length; i++) {
    const obj = array[i];
    if (obj[uniqueKey] === object[uniqueKey]) {
      return i;
    }
  }
  return false;
}

export function findElementInArray(obj, array) {
  const key = Object.keys(obj)[0];

  for (let i = 0; i < array.length; i++) {
    const el = array[i];
    if (el[key] === obj[key]) {
      return el;
    }
  }
  return null;
}

export const formatLocaleString = (value, properties = {}) => {
  const locale = 'en-US';
  return value.toLocaleString(locale, properties);
};

export const formatMoneyString = (string) => {
  // commenting out for now, do not see any references to this elsewhere in codebase
  // if (global && global.Intl) {
  //   const formatter = new Intl.NumberFormat('en-US', {
  //     style: 'currency',
  //     currency: 'USD',
  //     minimumFractionDigits: 2,
  //   });
  //   return formatter.format(string);
  // } else {
  return formatLocaleString(string, {
    style: 'currency',
    currency: 'USD',
  });
  // }
};

export const countCharFrequency = (char, string) => {
  let count = 0;
  for (let i = 0; i < string.length; i++) {
    if (string[i] === char) {
      count++;
    }
  }
  return count;
};

var months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getMonthFromString = (m) => {
  return new Date(Date.parse(m + ' 1, 2012')).getMonth() + 1;
};

export const getMonthFromNumber = (n) => {
  return months[n - 1];
};

export const getIdKey = (obj) => {
  return Object.keys(obj).filter((k) => k.slice(k.length - 2, k.length) === 'id')[0];
};

export const convertNumToMonth = (numStr) => {
  switch (numStr) {
    case '1':
      return 'Jan';

    case '2':
      return 'Feb';

    case '3':
      return 'Mar';

    case '4':
      return 'Apr';

    case '5':
      return 'May';

    case '6':
      return 'Jun';

    case '7':
      return 'Jul';

    case '8':
      return 'Aug';

    case '9':
      return 'Sept';

    case '10':
      return 'Oct';

    case '11':
      return 'Nov';

    case '12':
      return 'Dec';

    default:
      console.error(`Error: Did not receive num string representation of a month from 1 - 12. Received ${numStr}`);
      return '';
  }
};

export const returnBlankArrayWithNullElements = (count) => {
  const array = [];
  for (let i = 0; i < count; i++) {
    array[i] = null;
  }
  return array;
};

export const filterArrayForDuplicates = (array) => {
  return array.filter((el, i) => array.indexOf(el) === i);
};

export const isFunction = (functionToCheck) => {
  // https://stackoverflow.com/questions/5999998/how-can-i-check-if-a-javascript-variable-is-function-type
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
};

export const limitArraySize = (array, size) => {
  return array.slice(0, size);
};

export const returnLastElement = (arr) => arr.slice(-1)[0] || null;

export const sortAlphabetically = (config) => {
  if (config.type === 'ideas') {
    const ideas = config.list;
    const sortedList = ideas.sort((a, b) => {
      if (!getSecuritySymbolFromIdea(a)) {
        console.error('There was no symbol to sort by.', a);
        return [];
      }
      if (!getSecuritySymbolFromIdea(b)) {
        console.error('There was no symbol to sort by.', b);
        return [];
      }

      if (getSecuritySymbolFromIdea(a) < getSecuritySymbolFromIdea(b)) return -1;
      if (getSecuritySymbolFromIdea(a) > getSecuritySymbolFromIdea(b)) return 1;
      return 0;
    });
    return sortedList;
  }
  if (config.type === 'securities') {
    const securities = config.list;
    const sortedList = securities.sort((a, b) => {
      if (!a.symbol) {
        console.error('There was no symbol to sort by.', a);
        return [];
      }
      if (!b.symbol) {
        console.error('There was no symbol to sort by.', b);
        return [];
      }

      if (a.symbol < b.symbol) return -1;
      if (a.symbol > b.symbol) return 1;
      return 0;
    });
    return sortedList;
  }
  if (config.type === 'string') {
    const list = config.list;
    const sortedList = list.sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });
    return sortedList;
  } else {
    console.error('Please specify type and list');
  }
};

export const pluralizeIfAppropriate = (word, listOrCount, config = {}) => {
  let shouldBePlural;
  if (Array.isArray(listOrCount)) {
    shouldBePlural = listOrCount.length !== 1;
  } else {
    shouldBePlural = listOrCount !== 1;
  }
  const wordStartsWithVowel = ['a', 'e', 'i', 'o', 'u'].indexOf(word[0]) >= 0;
  const pluralPrefix = config.pluralPrefix || '';
  const prefix = !shouldBePlural ? (wordStartsWithVowel ? 'an ' : 'a ') : pluralPrefix;
  const suffix = shouldBePlural ? 's' : '';
  return `${config.ignorePrefix ? '' : prefix}${word}${suffix}`;
};

export const returnCharsTilFirstWhitespace = (string) => {
  let returnString = '';
  for (let i = 0; i < string.length; i++) {
    const char = string[i];
    if (char !== ' ') {
      returnString += char;
    } else {
      break;
    }
  }
  return returnString;
};

export const clickPreventDefault = (e) => {
  e.preventDefault();
};

export const clickStopPropagation = (e) => {
  e.stopPropagation();
};

export const cloneBasicObject = (obj) => {
  const target = {};
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      target[prop] = obj[prop];
    }
  }
  return target;
};

export const preventClickThruLink = (e) => {
  e.stopPropagation();
};

export const didFirstLevelPropsValueChange = (prevProps, nextProps, excludeKeys) => {
  const prevPropsKeys = Object.keys(prevProps).filter((key) => !excludeKeys.includes(key));
  const nextPropsKeys = Object.keys(nextProps).filter((key) => !excludeKeys.includes(key));
  let wasChange = false;
  prevPropsKeys.forEach((key) => {
    if (prevProps[key] !== nextProps[key]) {
      wasChange = true;
    }
  });
  nextPropsKeys.forEach((key) => {
    if (prevProps[key] !== nextProps[key]) {
      wasChange = true;
    }
  });
  return wasChange;
};

export const darkenColor = (intensity, RGBString) => {
  var p = intensity;
  var c = RGBString;
  var i = parseInt;
  var r = Math.round;
  var [a, b, c, d] = c.split(',');
  var P = p < 0;
  var t = P ? 0 : 255 * p;
  var P = P ? 1 + p : 1 - p;
  return (
    'rgb' +
    (d ? 'a(' : '(') +
    r(i(a[3] === 'a' ? a.slice(5) : a.slice(4)) * P + t) +
    ',' +
    r(i(b) * P + t) +
    ',' +
    r(i(c) * P + t) +
    (d ? ',' + d : ')')
  );
};

export const darkenColorForHover = (RGBString) => darkenColor(-0.2, RGBString);

export const removeKeyFromObject = (obj, keyToRemove) => {
  const filteredObj = {};
  const keys = Object.keys(filteredObj).filter((key) => key !== keyToRemove);
  keys.forEach((key) => (filteredObj[key] = obj[key]));
  return filteredObj;
};

export const getRandomInt = (maxExclusive) => {
  return Math.floor(Math.random() * Math.floor(maxExclusive));
};

export const doArraysContainSameElementValues = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every((el) => arr2.includes(el));
};

export const ifExists = (obj, key) => (obj && obj[key]) || null;

export const burstEmit = (amount, config) => {
  if (amount < 1) return;

  const { burst, burstSendDelay, event } = config;

  const sendMultipleEvents = (sendAmount, delay = 0) => {
    setTimeout(() => {
      for (let index = 0; index < sendAmount; index++) {
        event();
      }
    }, delay);
  };

  if (amount <= burst) {
    sendMultipleEvents(amount);
  } else {
    const burstsNeeded = Math.ceil(amount / burst);
    let eventsLeftToSend = amount;
    for (let i = 0; i < burstsNeeded; i++) {
      const sendingAmount = eventsLeftToSend > burst ? burst : eventsLeftToSend;
      sendMultipleEvents(sendingAmount, burstSendDelay * i);
      eventsLeftToSend -= sendingAmount;
    }
  }
};

export const roundFloat = (v, decimalPlace = 1) => {
  return parseFloat(v.toFixed(decimalPlace));
};

export const lerp = (value1, value2, amount) => {
  let scaleAmount = amount;
  if (amount < 0) {
    scaleAmount = 0;
  }
  if (amount > 1) {
    scaleAmount = 1;
  }
  return value1 + (value2 - value1) * scaleAmount;
};

export const isInRange = (x, min, max) => {
  return x >= min && x <= max;
};

const _componentToHex = (c) => {
  var hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const hexToColorObj = (hex) => {
  const rgb = hexToRgb(hex);
  return {
    red: rgb.r,
    green: rgb.g,
    blue: rgb.b,
  };
};

export const rgbToHex = (r, g, b) => {
  return '#' + _componentToHex(r) + _componentToHex(g) + _componentToHex(b);
};

export const convertColorObjectToHex = ({ red, green, blue }) => {
  return '#' + _componentToHex(red) + _componentToHex(green) + _componentToHex(blue);
};

export const isSSNObfuscated = (str) => {
  return str.slice(0, 3) === '***';
};

export const buildLookupObjectFromArray = (array = [], primaryKey = 'id', isKeyLookup) => {
  const lookup = {};
  array.forEach((el) => (lookup[el[primaryKey]] = isKeyLookup ? true : { ...el }));
  return lookup;
};

export const possessiveize = (string) => {
  if (string[string.length - 1] === 's') {
    return `${string}'`;
  }
  return `${string}'s`;
};

export const slugifyName = (string) => {
  if (string.indexOf('-') >= 0) return console.error('name has invalid characters')();

  return string.split(' ').join('-');
};

export const unslugifyName = (string) => {
  return string.split('-').join(' ');
};

export const isYoutubeLink = (url) => url.match(REGEX_LOOKUP.YOUTUBE_LINK_PARSE) !== null;

export const isAudioLink = (url) => {
  if (!url) return false;
  return ['.mp3', '.mp4'].includes(url.slice(url.length - 4, url.length));
};

export const getYoutubeVideoId = (url) => {
  const value = url.match(REGEX_LOOKUP.YOUTUBE_LINK_PARSE);
  return value[1];
};

export const doObjectsContainSameKeysAndValues = (o1, o2, config) => {
  return JSON.stringify(o1) === JSON.stringify(o2);
};

export const applyStyleWhenTrue = (bool, style) => (bool ? style : {});

export const convertObjectKeyValuesFromUndefinedOrNullToBlankString = (o) => {
  const result = {};
  Object.keys(o).forEach((k) => {
    const v = o[k];
    result[k] = isUndefinedOrNull(v) ? '' : v;
  });
  return result;
};

export const isProductionEnv = () => ENVIRONMENT === 'production';

export const isMobileBrowser = () => {
  let check = false;
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const isSafari = () => {
  return navigator.userAgent.indexOf('Safari') !== -1;
};

export const hexToRgbA = (hex, a) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${a})`;
  }
  return false;
};
