import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';
import { createQueryString } from '../helpers/routerHelpers';

const URL = `${BASEURL}/api`;

export const historyAdapter = {
  fetchHistoryTransactionData: () => {
    return sendRequest('get', [`${URL}/history/transactions`]).then((response) => response);
  },

  fetchHistoryEquityData: () => {
    return sendRequest('get', [`${URL}/history/equity`]).then((response) => response);
  },

  fetchHistoryPortfolioMetricsData: (formData) => {
    const queryString = createQueryString(formData);
    return sendRequest('get', [`${URL}/history/portfolio_metrics${queryString}`]).then((response) => response);
  },

  cancelFundingTransaction: (id) => {
    const body = {
      id,
    };
    return sendRequest('post', [`${URL}/live_accounts/funding/transaction/cancel`, body]).then((response) => response);
  },
};
