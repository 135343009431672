import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/index';
import PageLoading from '../../components/PageLoading';
import Icon from '../../components/misc/Icon';
import LoadingIcon from '../../components/misc/LoadingIcon';
import Slider from '@src/components/Slider/StyledSlider';
import { CustomDropdown } from '../../components/form/CustomDropdown';
import Switch from '../../components/form/Switch';
import GenericInputField from '../../components/form/GenericInputField';
import InfoIcon from '../UI/InfoIcon';
import { formatDataValue } from '../../helpers/securitiesHelpers';
import { customParseFloat } from '../../helpers/numberHelpers';
import { toCapitalCase, isInRange } from '../../helpers/generalHelpers';
import { createInputComponent } from '../../helpers/formHelpers';
import { scrollToElement, runWhenElIsRendered } from '../../helpers/pageHelpers';
import AllocationConstraintsList from './AllocationConstraintsList';
import { createAndShowConfirmationModal, createBasicErrorModal } from '../../constants/modals';
import { FlatButton } from '../../main/components/buttons';
import { Container } from '../../main/components/ui';

const InvestmentPanelWrapper = styled.div`
  padding: 0 0 25px 0;

  .risk-profile-container {
    max-width: 600px;
  }
  .advanced-investment-objectives-container {
    max-width: 600px;
    margin: 0 auto;
  }
  .investment-objectives-action-buttons {
  }
`;

const MainOptimizerSettings = styled.div`
  label {
    text-align: left !important;
  }
  input,
  .custom-dropdown,
  select {
    text-align: left !important;
    .custom-dropdown-suffix {
      left: 45px;
      right: auto;
    }
    border-radius: 0 !important;
    border-bottom: 1px solid ${({ theme }) => theme.themeColors.text} !important;
  }
`;

const generateDefaultReturnValues = () => {
  var a = [];
  for (let i = 0; i <= 35; i += 0.5) {
    a.push(i.toFixed(1));
  }
  return a;
};

class InvestmentObjectivesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdvancedInvestmentObjectivesPanel: false,
      loading: true,

      inputs: [
        {
          type: CustomDropdown,
          typeName: 'CustomDropdown',
          name: 'risk_profile',
          label: 'Risk Profile',
          required: true,
          props: {
            // show label shows above dropdown
            showLabel: true,
            // hide label restricts selection of the label from available options, usual setting if the dropdown will have a pre-selected valid value
            hideLabel: true,
            convertValuesToCapitalCase: true,
            showArrowIcons: true,
            values: this.returnAvailableRiskProfiles,
            defaultValue: 'Please select',
            center: 'true',
            styling: 'dropdown-centered default-expected-return-dropdown',
            offset: { top: '-5px' },
            afterChangeCallback: this._handleAfterRiskProfileChange,
          },
        },
        {
          type: CustomDropdown,
          typeName: 'CustomDropdown',
          name: 'default_projected_return',
          label: 'Default Projected Return',
          required: true,
          props: {
            // show label shows above dropdown
            showLabel: true,
            // hide label restricts selection of the label from available options, usual setting if the dropdown will have a pre-selected valid value
            hideLabel: true,
            showArrowIcons: true,
            suffix: '%',
            defaultValue: 'Please select',
            required: true,
            center: 'true',
            styling: 'dropdown-centered default-expected-return-dropdown',
            offset: { top: '-5px' },
            values: generateDefaultReturnValues(),
          },
        },

        // Advanced Investment Inputs
        {
          type: Switch,
          typeName: 'Switch',
          name: 'allow_short_sales',
          label: '',
          props: {
            afterChangeFunc: this._handleAfterSettingChange,
          },
        },
        {
          type: Switch,
          typeName: 'Switch',
          name: 'enable_cash_reserve_min',
          label: '',
          props: {
            checkIfIsDisabled: () => {
              return this.state.formData.allow_short_sales;
            },
          },
        },
        {
          type: Switch,
          typeName: 'Switch',
          name: 'enable_dividend_income_min',
          label: '',
          props: {
            afterChangeFunc: this._handleAfterSettingChange,
          },
        },
        {
          type: GenericInputField,
          typeName: 'GenericInputField',
          name: 'leverage_max',
          label: null,
          required: true,
          props: {
            handleChangeFunc: 'handleChange',
            returnMinMaxValidation: () => {
              return { min: 0, max: 1.0 }; // all margin account features removed on 1/22/21
            },
            popOutErrorMessage: true,
            blockingErrors: true,
            checkIfIsDisabled: () => {
              return this.state.formData.enable_cash_reserve_min;
            },
            afterChangeFunc: this._handleAfterSettingChangeMaxLeverage,
            additionalBlurAction: this._handleAfterSettingChange,
          },
        },
        {
          type: GenericInputField,
          typeName: 'GenericInputField',
          name: 'cash_reserve_min',
          label: null,
          required: false,
          props: {
            handleChangeFunc: 'handleChange',
            returnMinMaxValidation: () => {
              return {
                min: 0,
                max: customParseFloat(this.props.currentUser.equity),
              };
            },
            checkIfIsDisabled: () => {
              return !this.state.formData.enable_cash_reserve_min || this.state.formData.allow_short_sales;
            },
            prefix: '$',
            popOutErrorMessage: true,
            afterChangeFunc: this._handleAfterSettingChangeCashReserveMin,
            additionalBlurAction: this._handleAfterSettingChange,
            blockingErrors: true,
          },
        },
        {
          type: GenericInputField,
          typeName: 'GenericInputField',
          name: 'beta_constraint_min',
          label: null,
          required: false,
          props: {
            handleChangeFunc: 'handleChange',
            returnMinMaxValidation: () => {
              return { min: -1, max: 3 };
            },
            popOutErrorMessage: true,
            additionalBlurAction: this._handleAfterSettingChange,
            blockingErrors: true,
          },
        },
        {
          type: GenericInputField,
          typeName: 'GenericInputField',
          name: 'beta_constraint_max',
          label: null,
          required: false,
          props: {
            handleChangeFunc: 'handleChange',
            returnMinMaxValidation: () => {
              return { min: -1, max: 3 };
            },
            popOutErrorMessage: true,
            additionalBlurAction: this._handleAfterSettingChange,
            blockingErrors: true,
          },
        },
        {
          type: GenericInputField,
          typeName: 'GenericInputField',
          name: 'single_diversified_etf_allocation_max',
          label: null,
          required: false,
          props: {
            handleChangeFunc: 'handleChange',
            returnMinMaxValidation: () => {
              return { min: 0, max: 50 };
            },
            suffix: '%',
            popOutErrorMessage: true,
            additionalBlurAction: this._handleAfterSettingChange,
            blockingErrors: true,
          },
        },
        {
          type: GenericInputField,
          typeName: 'GenericInputField',
          name: 'annual_dividend_income_min',
          label: null,
          required: false,
          props: {
            handleChangeFunc: 'handleChange',
            returnMinMaxValidation: () => {
              return {
                min:
                  this.props.currentUser.equity > 0
                    ? customParseFloat((this.props.currentUser.equity * -0.01).toFixed())
                    : -1000,
                max:
                  this.props.currentUser.equity > 0
                    ? customParseFloat((this.props.currentUser.equity * 0.05).toFixed())
                    : 100000 * 0.05,
              };
            },
            prefix: '$',
            popOutErrorMessage: true,
            additionalBlurAction: this._handleAfterSettingChange,
            blockingErrors: true,
          },
        },
        {
          type: GenericInputField,
          typeName: 'GenericInputField',
          name: 'volatility_max',
          label: null,
          required: false,
          props: {
            handleChangeFunc: 'handleChange',
            suffix: '%',
            popOutErrorMessage: true,
            additionalBlurAction: this._handleAfterSettingChange,
            blockingErrors: true,
          },
        },
        {
          type: GenericInputField,
          typeName: 'GenericInputField',
          name: 'single_position_allocation_max',
          label: null,
          required: false,
          props: {
            handleChangeFunc: 'handleChange',
            returnMinMaxValidation: () => {
              return { min: 0, max: 50 };
            },
            suffix: '%',
            popOutErrorMessage: true,
            additionalBlurAction: this._handleAfterSettingChange,
            blockingErrors: true,
          },
        },
      ],
      formData: {},
      warnings: {},
      errors: {},

      resetSaveTimeout: null,
      savingInvestmentObjectives: false,
      savedInvestmentObjectives: false,
    };
  }

  componentDidMount() {
    const requestsToInitializeData = [
      this.props.actions.getRiskProfiles(),
      this.props.actions.getInvestmentObjectiveSettings(),
    ];

    Promise.all(requestsToInitializeData).then((responses) => {
      this.setupInvestmentObjectiveForms(responses);
      this.setState(() => ({ loading: false }));
    });
  }

  componentWillUnmount() {
    const resetSaveTimeout = this.state.resetSaveTimeout;
    if (resetSaveTimeout) {
      window.clearTimeout(resetSaveTimeout);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.saveInvestmentObjectives && this.props.saveInvestmentObjectives) {
      this.saveInvestmentObjectiveSettings().then((ajaxWasSuccess) => {
        if (ajaxWasSuccess) {
          window.setTimeout(() => {
            this.props.internalCreateOptimizedOrdersHandler();
          }, 2000);
        } else {
          this.props.resetSaveInvestmentObjectives();
          this.props.hideCreatingOptimizedOrdersMessage();
        }
      });
    }

    // if done loading panel
    if (this.isLoading(prevState) && !this.isLoading()) {
      setTimeout(() => {
        scrollToElement(window.$('.investment-objectives-panel'), this.props.$scrollContainer, 150, 85);
      }, 600);
    }
    if (!prevProps.show && this.props.show) {
      this._logViewInvestmentObjectives();
      setTimeout(() => {
        scrollToElement(window.$('.investment-objectives-panel'), this.props.$scrollContainer, 150, 85);
      }, 600);
    }
    // hiding showing panel
    if (prevProps.show && !this.props.show) {
      this.revertValuesBackToServerValues();
      this.hideAdvancedInvestmentObjectivesPanel();

      setTimeout(function () {
        window.$('.optimizer-panel-container .panel-container').animate(
          {
            scrollTop: 0,
          },
          600
        );
      }, 600);
    }
  }

  _renderInput = (input) => {
    return createInputComponent.call(this, input, null, input.name);
  };

  render() {
    const show = this.props.show;
    const defaultReturnInput = this.state.inputs[1];

    if (!show) {
      return null;
    }
    if (this.isLoading()) {
      return <PageLoading />;
    }

    return (
      <InvestmentPanelWrapper
        className={`investment-objectives-panel
          ${this.state.showAdvancedInvestmentObjectivesPanel ? 'advanced-objectives' : ''}
          `}
      >
        <div className={`investment-objectives-settings`}>
          <MainOptimizerSettings>
            {this.renderRiskProfile()}

            <div className={`default-projected-return-container`}>
              <div className={`wrapper`}>{this._renderInput(defaultReturnInput)}</div>
            </div>
          </MainOptimizerSettings>
          <div className={`show-advanced-container`}>
            <span
              className={`show-advanced-container-btn`}
              onClick={
                this.state.showAdvancedInvestmentObjectivesPanel
                  ? this.hideAdvancedInvestmentObjectivesPanel
                  : this.showAdvancedInvestmentObjectivesPanel
              }
            >
              <Icon icon="fa-sliders" colorClassName="link-text-color" size="small" style={{ marginRight: '5px' }} />
              <span className={`btn-text link-text-color`}>
                {`${this.state.showAdvancedInvestmentObjectivesPanel ? 'Hide' : 'Show'} Advanced Investment Objectives`}
              </span>
            </span>
          </div>

          <div className={`advanced-investment-objectives-container`}>
            {this.state.showAdvancedInvestmentObjectivesPanel && this.renderAdvancedInvestmentObjectivesPanel()}
          </div>
        </div>
        <div className={`investment-objectives-action-buttons`}>
          <Container right={16}>
            <FlatButton transparent width={'100px'} onClick={this.revertValuesBackToServerValues}>
              Revert
            </FlatButton>
          </Container>
          {this._renderSaveButton()}
        </div>
      </InvestmentPanelWrapper>
    );
  }

  _calculateLeverageFromCashReserveMinimum = (cashReserveMinValue, equity) => {
    return cashReserveMinValue === 0 ? 1 : equity / cashReserveMinValue;
  };

  _calculateCashReserveMinimumFromMaxLeverage = (maxLeverageValue, equity) => {
    return equity * maxLeverageValue;
  };

  _handleAfterSettingChangeMaxLeverage = (newMaxLeverageValue) => {
    // Disabled For Now
    // const equity = this.props.currentUser.equity;
    // const cashReserveMin = this._calculateCashReserveMinimumFromMaxLeverage( newMaxLeverageValue, equity );
    // this.setState( prevState => ({
    //   formData: {
    //     ...prevState.formData,
    //     cash_reserve_min: cashReserveMin.toFixed(2)
    //   }
    // }))
  };

  _handleAfterSettingChangeCashReserveMin = (newCashReserveMinValue) => {
    // Disabled For Now
    // const equity = this.props.currentUser.equity;
    // const maxLeverage = this._calculateLeverageFromCashReserveMinimum( newCashReserveMinValue, equity );
    // this.setState( prevState => ({
    //   formData: {
    //     ...prevState.formData,
    //     leverage_max: maxLeverage.toFixed(2)
    //   }
    // }))
  };

  _startSavingInvestmentObjectives = () => {
    this.setState((prevState) => ({
      savingInvestmentObjectives: true,
    }));
  };

  _resetSaveInvestmentObjectivesState = () => {
    this.setState((prevState) => ({
      savingInvestmentObjectives: false,
      savedInvestmentObjectives: false,
      resetSaveTimeout: null,
    }));
  };

  _updateToSavedInvestmentObjectives = () => {
    this.setState((prevState) => ({
      savedInvestmentObjectives: true,
      savingInvestmentObjectives: false,
    }));
  };

  _clearSavedInvestmentObjectives = () => {
    this.setState((prevState) => ({
      savedInvestmentObjectives: false,
    }));
  };

  _resetSaveRenderTimeout = () => {
    const lengthOfTimeToDisplaySavedMessage = 3500;
    const timeout = window.setTimeout(this._clearSavedInvestmentObjectives, lengthOfTimeToDisplaySavedMessage);
    this.setState((prevState) => ({
      resetSaveTimeout: timeout,
    }));
  };

  _renderSaveButton = () => {
    if (!this.state.savingInvestmentObjectives && !this.state.savedInvestmentObjectives) {
      return this.renderSave();
    }
    if (this.state.savingInvestmentObjectives) {
      return this._renderSaving();
    }
    if (this.state.savedInvestmentObjectives) {
      return this._renderSaved();
    }
  };

  renderSave = () => {
    return (
      <FlatButton width={'150px'} onClick={this.saveInvestmentObjectiveSettings}>
        Save Changes
      </FlatButton>
    );
  };

  _renderSaving = () => {
    return (
      <FlatButton width={'150px'} onClick={() => false} disabled={true}>
        Saving
      </FlatButton>
    );
  };

  _renderSaved = () => {
    return (
      <FlatButton width={'150px'} onClick={() => false} disabled={true}>
        Saved
      </FlatButton>
    );
  };

  _updateSaveButtonToSaved = () => {
    this._updateToSavedInvestmentObjectives();
    this._resetSaveRenderTimeout();
  };

  _handleSaveFailure = () => {
    const modalMessage = 'Could not save. Please try again later.';
    const modal = {
      contentComponent: createBasicErrorModal(modalMessage),
      dismissable: true,
    };

    this.props.actions.showModal(modal);

    this._resetSaveInvestmentObjectivesState();
  };

  _sanitizeSettingValues = (settings) => {
    // HACK: Assumes all setting values are going to be bool, null, or float -> will need more logic if this changes
    const keys = Object.keys(settings);
    const sanitizedSettings = {};

    keys.forEach((key) => {
      const value = settings[key];
      let newValue = value;
      if (typeof value === 'string' && value.toLowerCase() === 'none') {
        newValue = null;
      }
      if (typeof value === 'string' && value.toLowerCase() !== 'none') {
        newValue = customParseFloat(value);
      }
      sanitizedSettings[key] = newValue;
    });
    return sanitizedSettings;
  };

  saveInvestmentObjectiveSettings = () => {
    const currentSettings = this.state.formData;
    const serverSettings = this.props.investmentObjectives.investmentObjectiveSettings;
    const settingsThatChanged = this._compareAndReturnDataThatChanged(currentSettings, serverSettings);
    const sanitizedSettings = this._sanitizeSettingValues(settingsThatChanged);
    this._startSavingInvestmentObjectives();

    return this.props.actions.saveInvestmentObjectiveSettings(sanitizedSettings).then((response) => {
      const ajaxWasSuccess = response && response.status !== 'error' && !response.error;
      if (ajaxWasSuccess) {
        // delay a bit for smoothness
        setTimeout(() => {
          this._updateSaveButtonToSaved();
          // HACK: Used to sync with Optimizer Panel
          if (this.props.resetUnsavedChangesInInvestmentObjectives) {
            this.props.resetUnsavedChangesInInvestmentObjectives();
          }
        }, 500);
        return true;
      } else {
        this._handleSaveFailure();
        return false;
      }
    });
  };

  _compareAndReturnDataThatChanged = (currentData, previousData) => {
    const keysInCurrentData = Object.keys(currentData);
    const keysWithDifferentValues = keysInCurrentData.filter((key) => currentData[key] !== previousData[key]);
    const changedData = {};
    keysWithDifferentValues.forEach((key) => {
      changedData[key] = currentData[key];
    });

    return changedData;
  };

  setupInvestmentObjectiveForms = (responses) => {
    const investmentObjectiveSettingsResponse = responses[1];
    const {
      risk_profile,
      allow_short_sales,
      beta_constraint_max,
      beta_constraint_min,
      cash_reserve_min,
      default_projected_return,
      enable_dividend_income_min,
      leverage_max,
      single_diversified_etf_allocation_max,
      single_position_allocation_max,
      volatility_max,
      annual_dividend_income_min,
      enable_cash_reserve_min,
    } = investmentObjectiveSettingsResponse;

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        risk_profile,
        allow_short_sales,
        beta_constraint_max,
        beta_constraint_min,
        cash_reserve_min,
        default_projected_return,
        enable_dividend_income_min,
        leverage_max,
        single_diversified_etf_allocation_max,
        single_position_allocation_max,
        volatility_max,
        annual_dividend_income_min,
        enable_cash_reserve_min,
      },
    }));
  };

  revertValuesBackToServerValues = () => {
    const {
      risk_profile,
      allow_short_sales,
      beta_constraint_max,
      beta_constraint_min,
      cash_reserve_min,
      default_projected_return,
      enable_dividend_income_min,
      leverage_max,
      single_diversified_etf_allocation_max,
      single_position_allocation_max,
      volatility_max,
      annual_dividend_income_min,
      enable_cash_reserve_min,
    } = this.props.investmentObjectives.investmentObjectiveSettings;

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        risk_profile,
        allow_short_sales,
        beta_constraint_max,
        beta_constraint_min,
        cash_reserve_min,
        default_projected_return,
        enable_dividend_income_min,
        leverage_max,
        single_diversified_etf_allocation_max,
        single_position_allocation_max,
        volatility_max,
        annual_dividend_income_min: annual_dividend_income_min
          ? annual_dividend_income_min.toFixed(2)
          : annual_dividend_income_min,
        enable_cash_reserve_min,
      },
    }));
    if (this.props.resetUnsavedChangesInInvestmentObjectives) {
      this.props.resetUnsavedChangesInInvestmentObjectives();
    }
  };

  returnAvailableRiskProfiles = () => {
    return this.props.investmentObjectives.riskProfiles;
  };

  renderRiskProfileDescription = () => {
    const riskProfiles = this.returnAvailableRiskProfiles();
    const currentSelectedRiskProfile = this.state.formData.risk_profile;
    const riskProfileData = riskProfiles.filter((profile) => profile.id === currentSelectedRiskProfile.id)[0];
    const message = riskProfileData.description;
    const riskProfileNameStartsWithVowel = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u'].includes(
      riskProfileData.name[0]
    );
    const prefix = (
      <span>
        <span>{`${riskProfileNameStartsWithVowel ? 'An' : 'A'} `}</span>
        <span className={`bold`}>{`${toCapitalCase(riskProfileData.name)}`}</span>
        <span>{` risk profile means `}</span>
      </span>
    );

    return (
      <div className={`risk-profile-description`}>
        {prefix}
        <span>{message}</span>
      </div>
    );
  };

  renderRiskProfile = () => {
    const areRiskProfilesLoaded = this.props.investmentObjectives.riskProfiles.length > 0;

    if (areRiskProfilesLoaded) {
      const riskProfileInput = this.state.inputs[0];
      return (
        <div className={`risk-profile-container`}>
          <div className={`risk-profile-input-container`}>
            {createInputComponent.call(this, riskProfileInput, null, riskProfileInput.name)}
          </div>
          {this.renderRiskProfileDescription()}
        </div>
      );
    } else {
      return (
        <div className={`loading-risk-profiles`}>
          <LoadingIcon icon="fading-3balls" size="small" />
          <div>Risk Profiles</div>
        </div>
      );
    }
  };

  renderAdvancedInvestmentObjectivesPanel = () => {
    const shortSalesToggle = this.state.inputs[2];
    const useCashReserveMinToggle = this.state.inputs[3];
    const useMinimumDividendIncomeToggle = this.state.inputs[4];

    const maxLeverageTextInput = this.state.inputs[5];
    const cashReserveMinTextInput = this.state.inputs[6];
    const betaConstraintMinTextInput = this.state.inputs[7];
    const betaConstraintMaxTextInput = this.state.inputs[8];
    const maxSingleETFAllocTextInput = this.state.inputs[9];
    const minimumDividendIncomeTextInput = this.state.inputs[10];
    const maxVolatilityTextInput = this.state.inputs[11];
    const maxPositionTextInput = this.state.inputs[12];

    const currentPortfolio = this.props.optimizer.data.portfolio_stats.metrics;

    return (
      <div className={`investment-objectives-sliders`}>
        <div className={`advanced-investment-objective-container border-accent short-sales-allowed-container`}>
          <div className={`label-input-container`}>
            <div className={`text-container`}>
              <div className={`text-label`}>
                <div className={`info-icon-text-wrapper`}>
                  Allow Short Sales
                  <InfoIcon
                    word="tooltip_short_sales_allowed"
                    style={{ top: '4px', left: '-17px', right: 'auto' }}
                    position={'left'}
                  />
                </div>
              </div>
            </div>
            <div className={`investment-objective-input`}>{this._renderInput(shortSalesToggle)}</div>
            {!this.props.currentUser.is_allowed_to_use_leverage && (
              <div className={`investment-objective-min-eq-msg`}>
                {`You must have at least ${formatDataValue(
                  this.props.currentUser.min_equity_required_to_use_leverage,
                  'priceNearestDollar'
                )} in your account to sell short.`}
              </div>
            )}
          </div>
        </div>

        <div className={`advanced-investment-objective-container border-accent max-leverage-container`}>
          {!this.state.formData.allow_short_sales && (
            <div className={`label-input-container`}>
              <div className={`text-container`}>
                <div className={`text-label`}>
                  <div className={`info-icon-text-wrapper`}>Set Cash Reserve Minimum</div>
                </div>
                <div className={`sub-text-label`}>
                  {`Current Cash Balance: ${formatDataValue(currentPortfolio.cash_balance, 'price')}`}
                </div>
              </div>
              <div className={`investment-objective-input`}>{this._renderInput(useCashReserveMinToggle)}</div>
            </div>
          )}

          {!this.state.formData.enable_cash_reserve_min && (
            <div className={`react-conditional-wrapper`}>
              <div className={`label-input-container`}>
                <div className={`text-container`}>
                  <div className={`text-label`}>
                    <div className={`info-icon-text-wrapper`}>
                      Max Leverage
                      <InfoIcon
                        word="tooltip_max_leverage"
                        style={{ top: '4px', left: '-17px', right: 'auto' }}
                        position={'left'}
                      />
                    </div>
                  </div>
                  <div className={`sub-text-label`}>
                    {`Current Leverage: ${formatDataValue(currentPortfolio.leverage, 'ratio')}`}
                  </div>
                </div>
                <div className={`text-input-container`}>
                  {createInputComponent.call(this, maxLeverageTextInput, null, maxLeverageTextInput.name)}
                </div>
              </div>

              <div className={`label-input-container slider`} style={{ marginBottom: '0px' }}>
                <div className={`slider-container`}>
                  <Slider
                    pearling={true}
                    min={0}
                    max={1.0} // all margin account features removed on 1/22/21
                    step={0.01}
                    value={this._returnValueFormattedForSlider(this.state.formData.leverage_max)}
                    onChange={this.createSlideOnChangeFunction('leverage_max')}
                    onAfterChange={this._handleAfterSettingChange}
                    withBars
                    disabled={this.state.formData.enable_cash_reserve_min}
                  />
                </div>
              </div>
              {!this.props.currentUser.is_allowed_to_use_leverage && (
                <div className={`investment-objective-min-eq-msg`}>
                  {`You must have at least ${formatDataValue(
                    this.props.currentUser.min_equity_required_to_use_leverage,
                    'priceNearestDollar'
                  )} in your account to use leverage.`}
                </div>
              )}
            </div>
          )}
          {this.state.formData.enable_cash_reserve_min && (
            <div className={`advanced-investment-objective-container border-accent cash-reserve-container`}>
              <div className={`label-input-container`}>
                <div className={`text-container`}>
                  <div className={`text-label`}>
                    <div className={`info-icon-text-wrapper`}>
                      Cash Reserve Minimum
                      <InfoIcon
                        word="tooltip_cash_reserve_minimum"
                        style={{ top: '4px', left: '-17px', right: 'auto' }}
                        position={'left'}
                      />
                    </div>
                  </div>
                </div>
                <div className={`investment-objective-input`}>
                  <div className={`text-input-container`}>
                    {createInputComponent.call(this, cashReserveMinTextInput, null, cashReserveMinTextInput.name)}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={`advanced-investment-objective-container border-accent portfolio-beta-container`}>
          <div className={`label-input-container`}>
            <div className={`text-container`}>
              <div className={`text-label`}>
                <div className={`info-icon-text-wrapper`}>
                  Portfolio Beta
                  <InfoIcon
                    word="tooltip_portfolio_beta_constraint"
                    style={{ top: '4px', left: '-17px', right: 'auto' }}
                    position={'left'}
                  />
                </div>
              </div>
              <div className={`sub-text-label`}>
                {`Current Beta: ${formatDataValue(currentPortfolio.beta, 'ratio')}`}
              </div>
            </div>

            <div className={`min-max-input-containers`}>
              <div className={`text-input-container`}>
                {createInputComponent.call(this, betaConstraintMinTextInput, null, betaConstraintMinTextInput.name)}
              </div>
              <div className={`text-input-container`}>
                {createInputComponent.call(this, betaConstraintMaxTextInput, null, betaConstraintMaxTextInput.name)}
              </div>
            </div>
          </div>

          <div className={`label-input-container slider`}>
            <div className={`slider-container`}>
              <Slider
                pearling={true}
                min={-1}
                max={3}
                step={0.05}
                value={[
                  this._returnValueFormattedForSlider(this.state.formData.beta_constraint_min),
                  this._returnValueFormattedForSlider(this.state.formData.beta_constraint_max),
                ]}
                onChange={this.createSlideOnChangeFunction('beta_constraint', {
                  multiSlider: true,
                })}
                onAfterChange={this._handleAfterSettingChange}
                withBars
              />
            </div>
          </div>
        </div>

        <div className={`advanced-investment-objective-container border-accent portfolio-volatility-container`}>
          <div className={`label-input-container`}>
            <div className={`text-container`}>
              <div className={`text-label`}>
                <div className={`info-icon-text-wrapper`}>
                  Portfolio Volatility
                  <InfoIcon
                    word="tooltip_portfolio_volatility_invest_obj"
                    style={{ top: '4px', left: '-17px', right: 'auto' }}
                    position={'left'}
                  />
                </div>
              </div>
              <div className={`sub-text-label`}>
                {`Current Volatility: ${formatDataValue(currentPortfolio.volatility, 'percentage')}`}
              </div>
            </div>

            <div className={`text-input-container`}>
              {createInputComponent.call(this, maxVolatilityTextInput, null, maxVolatilityTextInput.name)}
            </div>
          </div>

          <div className={`investment-objective-input`}>
            <div className={`slider-container`}>
              <Slider
                pearling={true}
                min={5}
                max={50}
                step={0.25}
                value={this._returnValueFormattedForSlider(this.state.formData.volatility_max)}
                onChange={this.createSlideOnChangeFunction('volatility_max')}
                onAfterChange={this._handleAfterSettingChange}
                withBars
              />
            </div>
          </div>
        </div>

        <div className={`advanced-investment-objective-container border-accent minimum-dividend-income-container`}>
          <div className={`label-input-container`}>
            <div className={`text-container`}>
              <div className={`text-label`}>
                <div className={`info-icon-text-wrapper`}>Set Minimum Dividend Income</div>
              </div>
            </div>
            <div className={`investment-objective-input`}>
              {createInputComponent.call(
                this,
                useMinimumDividendIncomeToggle,
                null,
                useMinimumDividendIncomeToggle.name
              )}
            </div>
          </div>

          {this.state.formData.enable_dividend_income_min && (
            <div className={`react-multi-conditional-element-wrapper`}>
              <div className={`label-input-container`}>
                <div className={`text-container`}>
                  <div className={`text-label`}>
                    <div className={`info-icon-text-wrapper`}>
                      Minimum Dividend Income
                      <InfoIcon
                        word="tooltip_minimum_dividend_income"
                        style={{ top: '4px', left: '-17px', right: 'auto' }}
                        position={'left'}
                      />
                    </div>
                    {this.props.currentUser.equity !== 0 && (
                      <div className="multiple-sub-text-label-containers">
                        <div className={`sub-text-label`}>
                          {`Current Expected Dividend Income: ${formatDataValue(
                            currentPortfolio.annual_dividend_income,
                            'price'
                          )}`}
                        </div>
                        <div className="sub-text-label">
                          {`${formatDataValue(
                            currentPortfolio.annual_dividend_income === 0
                              ? 0
                              : (currentPortfolio.annual_dividend_income / this.props.currentUser.equity) * 100,
                            'percentage'
                          )} of equity`}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={`text-input-container`}>
                  {createInputComponent.call(
                    this,
                    minimumDividendIncomeTextInput,
                    null,
                    minimumDividendIncomeTextInput.name
                  )}
                  <div className={`dividend-percent-of-equity sub-text-label`}>
                    {`${formatDataValue(
                      this.state.formData.annual_dividend_income_min === 0
                        ? 0
                        : (this.state.formData.annual_dividend_income_min / this.props.currentUser.equity) * 100,
                      'percentage'
                    )} of equity`}
                  </div>
                </div>
              </div>
              <div className={`label-input-container slider`}>
                <div className={`slider-container`}>
                  <Slider
                    pearling={true}
                    min={
                      this.props.currentUser.equity > 0
                        ? customParseFloat((this.props.currentUser.equity * -0.01).toFixed())
                        : -1000
                    }
                    max={
                      this.props.currentUser.equity > 0
                        ? customParseFloat((this.props.currentUser.equity * 0.05).toFixed())
                        : 100000 * 0.05
                    }
                    step={5}
                    value={this._returnValueFormattedForSlider(this.state.formData.annual_dividend_income_min)}
                    onChange={this.createSlideOnChangeFunction('annual_dividend_income_min')}
                    onAfterChange={this._handleAfterSettingChange}
                    withBars
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={`advanced-investment-objective-container max-diversified-etf-allocation-container`}>
          <div className={`label-input-container`}>
            <div className={`text-container`}>
              <div className={`text-label`}>
                <div className={`info-icon-text-wrapper`}>
                  Max Position Allocation
                  <InfoIcon
                    word="tooltip_max_diversified_etf_allocation"
                    style={{ top: '4px', left: '-17px', right: 'auto' }}
                    position={'left'}
                  />
                </div>
              </div>
              <div className={`text-label`}>(Diversified ETFs)</div>
            </div>
            <div className={`text-input-container`}>
              {createInputComponent.call(this, maxSingleETFAllocTextInput, null, maxSingleETFAllocTextInput.name)}
            </div>
          </div>

          <div className={`investment-objective-input`}>
            <div className={`slider-container`}>
              <Slider
                pearling={true}
                min={0}
                max={50}
                step={0.25}
                value={this._returnValueFormattedForSlider(this.state.formData.single_diversified_etf_allocation_max)}
                onChange={this.createSlideOnChangeFunction('single_diversified_etf_allocation_max')}
                onAfterChange={this._handleAfterSettingChange}
                withBars
              />
            </div>
          </div>
        </div>

        <div className={`advanced-investment-objective-container border-accent max-position-container`}>
          <div className={`label-input-container`}>
            <div className={`text-container`}>
              <div className={`text-label`}>
                <div className={`info-icon-text-wrapper`}>
                  Max Position Allocation
                  <InfoIcon
                    word="tooltip_max_position_allocation"
                    style={{ top: '4px', left: '-17px', right: 'auto' }}
                    position={'left'}
                  />
                </div>
              </div>
              <div className={`text-label`}>(Single Stocks)</div>
              <div className={`sub-text-label`}>
                {`Current Largest Allocation: ${formatDataValue(currentPortfolio.largest_allocation, 'percentage')}`}
              </div>
            </div>

            <div className={`text-input-container`}>
              {createInputComponent.call(this, maxPositionTextInput, null, maxPositionTextInput.name)}
            </div>
          </div>

          <div className={`investment-objective-input`}>
            <div className={`slider-container`}>
              <Slider
                pearling={true}
                min={0}
                max={50}
                step={0.25}
                value={this._returnValueFormattedForSlider(this.state.formData.single_position_allocation_max)}
                onChange={this.createSlideOnChangeFunction('single_position_allocation_max')}
                onAfterChange={this._handleAfterSettingChange}
                withBars
              />
            </div>
          </div>
        </div>

        <div className={`advanced-investment-objective-container border-accent allocation-constraint-container`}>
          <div className={`label-input-container`}>
            <div className={`text-label`}>
              <div className={`info-icon-text-wrapper`}>
                Allocation Constraints
                <InfoIcon
                  word="tooltip_allocation_constraint"
                  style={{ top: '4px', left: '-17px', right: 'auto' }}
                  position={'left'}
                />
              </div>
              <div className={`sub-text-label`}>Changes to allocation constraints are automatically saved.</div>
            </div>
          </div>

          <AllocationConstraintsList runOptimizer={this._runOptimizer} />
        </div>
      </div>
    );
  };

  _returnValueFormattedForSlider = (value) => {
    if (value === undefined || value === null) {
      return 0;
    }
    return customParseFloat(value);
  };

  handleTextInvestmentObjectiveSettingChange = (name) => {
    return (e) => {
      const value = e.target.value;

      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }));
    };
  };

  _returnRiskProfilePresetDefaults = (preset) => {
    const presets = {
      conservative: {
        allow_short_sales: false,
        leverage_max: 1,
        enable_cash_reserve_min: false,
        cash_reserve_min: null,
        beta_constraint_min: 0,
        beta_constraint_max: 1.5,
        volatility_max: 10,
        enable_dividend_income_min: false,
        annual_dividend_income_min: null,
        single_position_allocation_max: 15,
        single_diversified_etf_allocation_max: 35,
      },
      moderate: {
        allow_short_sales: true,
        leverage_max: 1,
        enable_cash_reserve_min: false,
        cash_reserve_min: null,
        beta_constraint_min: -0.5,
        beta_constraint_max: 1.5,
        volatility_max: 20,
        enable_dividend_income_min: false,
        annual_dividend_income_min: null,
        single_position_allocation_max: 25,
        single_diversified_etf_allocation_max: 35,
      },
      aggressive: {
        allow_short_sales: true,
        leverage_max: 1.25,
        enable_cash_reserve_min: false,
        cash_reserve_min: null,
        beta_constraint_min: -0.8,
        beta_constraint_max: 2,
        volatility_max: 30,
        enable_dividend_income_min: false,
        annual_dividend_income_min: null,
        single_position_allocation_max: 35,
        single_diversified_etf_allocation_max: 50,
      },
    };
    return presets[preset] || console.error('Invalid risk profile preset');
  };

  _showRiskProfileChangeConfirmationModal = (customConfig) => {
    const message = 'Changing your risk profile will change all your investment objectives. Want to continue?';
    const { handleContinue, handleCancel } = customConfig;

    const config = {
      message,
      handleContinue,
      handleCancel,
      continueButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      continueButtonClassName: 'btn btn-primary-color btn-small-tall btn-fixed-width-80',
    };
    createAndShowConfirmationModal.call(this, config);
  };

  _handleAfterRiskProfileChange = (newValue, inputName, oldValue) => {
    const handleCancel = () => {
      this.props.actions.hideModal();

      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          risk_profile: oldValue,
        },
      }));
    };
    const handleContinue = () => {
      this.props.actions.hideModal();

      const preset = newValue.name.toLowerCase();
      const defaultData = this._returnRiskProfilePresetDefaults(preset);
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          ...defaultData,
        },
      }));

      const newOptimizerSettings = this._sanitizeCustomOptimizerSettings(defaultData);
      this._runOptimizer(newOptimizerSettings);
    };

    const didNotSelectCustom = newValue.id !== 3;
    const userSelectedNewValue = newValue.id !== oldValue.id;
    if (didNotSelectCustom && userSelectedNewValue) {
      const config = {
        handleContinue,
        handleCancel,
      };
      this._showRiskProfileChangeConfirmationModal(config);
    }
  };

  createSlideOnChangeFunction = (name, config) => {
    // Callback called on every value change.

    if (config && config.multiSlider) {
      return (values) => {
        const min = values[0];
        const max = values[1];

        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            [`${name}_min`]: min,
            [`${name}_max`]: max,
          },
        }));
      };
    } else {
      return (value) => {
        const handleAfterChange = () => {
          if (name === 'leverage_max') {
            this._handleAfterSettingChangeMaxLeverage(value);
          }
          if (name === 'cash_reserve_min') {
            this._handleAfterSettingChangeCashReserveMin(value);
          }
        };
        this.setState(
          (prevState) => ({
            formData: {
              ...prevState.formData,
              [name]: value,
            },
          }),
          handleAfterChange
        );
      };
    }
  };

  _returnSettingsForRiskProfilePreset = (id) => {
    // TASK: TODO
    const settings = {};

    return settings;
  };

  _handleAfterSettingChange = (newValue, inputName, oldValue) => {
    // Callback called only after moving a handle has ended or when a new value is set by clicking on the slider, or after text field blur.
    const overrideOptimizerSettings = {};

    if (inputName === 'allow_short_sales' && newValue === true) {
      if (this.state.formData.enable_cash_reserve_min) {
        overrideOptimizerSettings.enable_cash_reserve_min = false;
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            enable_cash_reserve_min: false,
          },
        }));
      }
    }

    // bug left: when an input is calling this on blur newValue is input name, and both values are undefined
    if (newValue === 'volatility_max') {
      const min = 5;
      const max = 50;
      const value = this.state.formData.volatility_max;

      if (!isInRange(value, min, max)) {
        const inRangeCorrection = value < min ? min : max;
        overrideOptimizerSettings.volatility_max = inRangeCorrection;
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            volatility_max: inRangeCorrection,
          },
        }));
      }
    }

    const riskProfiles = this.returnAvailableRiskProfiles();
    const customRiskProfile = riskProfiles.filter((profile) => profile.id === 3)[0];

    if (this.state.formData.risk_profile.id !== 3) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          risk_profile: customRiskProfile,
        },
      }));
    }

    const optimizerSettings = {
      ...this.state.formData,
      ...overrideOptimizerSettings,
    };
    this._runOptimizer(optimizerSettings);

    // HACK: used to keep in sync with the optimizer panel
    if (this.props.registerUnsavedChangesInInvestmentObjectives) {
      this.props.registerUnsavedChangesInInvestmentObjectives();
    }
  };

  _sanitizeCustomOptimizerSettings = (optimizerSettings) => {
    if (!optimizerSettings.enable_cash_reserve_min) {
      optimizerSettings.cash_reserve_min = null;
      optimizerSettings.enable_cash_reserve_min = false;
    } else {
      optimizerSettings.leverage_max = null;
      optimizerSettings.enable_cash_reserve_min = true;
    }
    const settingsKeys = Object.keys(optimizerSettings);
    const sanitizedCustomOptimizerSettings = {};
    settingsKeys.forEach((key) => {
      const valueIsABool = optimizerSettings[key] === true || optimizerSettings[key] === false;
      sanitizedCustomOptimizerSettings[key] = valueIsABool
        ? optimizerSettings[key]
        : customParseFloat(optimizerSettings[key]);
    });
    return sanitizedCustomOptimizerSettings;
  };

  _runOptimizer = (customOptimizerSettings) => {
    const currentCustomUserSettings = {
      ...this.state.formData,
    };
    const optimizerSettings = customOptimizerSettings || currentCustomUserSettings;
    const sanitizedCustomOptimizerSettings = this._sanitizeCustomOptimizerSettings(optimizerSettings);
    this.props.actions.clearOptimizerCooldown();
    this.props.actions.runOptimizer(sanitizedCustomOptimizerSettings);
  };

  isLoading = (state) => (state || this.state).loading;

  hideInvestmentObjectivesPanel = () => {
    this.props.hideComponent();
  };

  createOptimizedOrders = () => {
    this.showCreatingOptimizedOrdersMessage();
  };

  _logViewAdvancedInvestmentObjectives = () => {
    const event = 'View Investment Objectives Advanced';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  showAdvancedInvestmentObjectivesPanel = () => {
    this.setState((prevState) => ({
      showAdvancedInvestmentObjectivesPanel: true,
    }));
    this._logViewAdvancedInvestmentObjectives();

    const $el = $('.investment-objectives-sliders');
    runWhenElIsRendered($el, this._scrollToAdvancedObjectivesTop);
    // var $el = $('.investment-objectives-sliders');
    // runWhenElIsRendered($el, () => scrollToElement($el, $('html, body'), 150, 85));
  };

  hideAdvancedInvestmentObjectivesPanel = () => {
    this.setState((prevState) => ({
      showAdvancedInvestmentObjectivesPanel: false,
    }));
  };

  showCreatingOptimizedOrdersMessage = () => {
    this.setState((prevState) => ({
      showCreatingOptimizedOrdersMessage: true,
    }));
  };

  hideCreatingOptimizedOrdersMessage = () => {
    this.setState((prevState) => ({
      showCreatingOptimizedOrdersMessage: false,
    }));
  };

  _scrollToAdvancedObjectivesTop = () => {
    const $el = $('.investment-objectives-sliders');
    scrollToElement($el, this.props.$scrollContainer, 150, 85);
  };

  _logViewInvestmentObjectives = () => {
    const event = 'View Investment Objectives';
    this.props.actions.logMetricsTrackingEvent(event);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    optimizer: state.optimizer,
    investmentObjectives: state.investmentObjectives,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentObjectivesContainer);
