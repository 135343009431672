import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as Actions from '../../../../actions/index';
import LinkTo from '../../../Links/LinkTo';
import NumberWithChangeIndicator from '../../../../components/UI/NumberWithChangeIndicator';
import { snakeToHyphens } from '../../../../helpers/generalHelpers';
import { findUserIdeaForSecurity } from '../../../../helpers/ideaHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';

export class IdeaPerformanceCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? (
          this.props.loadingCellComponent
        ) : (
          <LinkTo
            to={this._returnLinkForHandlingClick()}
            className={''}
            additionalClickAction={this.props.logSecurityPanelOpenClick}
            child={this._renderCellContent()}
            noTextDecoration
          />
        )}
      </td>
    );
  }

  _isLoading = () => {
    const { userId, securityId, isThoughtLeader } = this.props;
    const security = this.props.securities.lookup[securityId] || {};
    const isSecurityDataLoading = !('symbol' in security) || !('name' in security); // not a requirement but makes the loading frame more unison

    let isLoading = false;
    let portfolioData = null;
    let idea = null;

    if (isThoughtLeader) {
      idea = this.props.idea;
      isLoading = !idea || isSecurityDataLoading;
    } else {
      const userPortfolioLookup = this.props.portfolio.userPortfolioDict;
      const userPortfolioData = userPortfolioLookup && userPortfolioLookup[userId];
      portfolioData = userPortfolioData && userPortfolioData.securities;
      isLoading = !portfolioData || isSecurityDataLoading;
    }

    return isLoading;
  };

  _returnIdea = () => {
    const { userId, securityId } = this.props;
    const ideaUserId = userId || this.props.currentUser.user_id;
    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    return idea;
  };

  _returnLinkForHandlingClick = () => {
    const { securityId } = this.props;

    const idea = this._returnIdea();
    const ideaId = idea && idea.idea_id;

    const additionalProps = {
      activeTab: 'ideas',
      ideaId,
    };
    const openSecurityPanelConfig = {
      securityId,
      additionalProps,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    const { userId, securityId, isThoughtLeader } = this.props;
    const security = this.props.securities.lookup[securityId] || {};

    let portfolioData = null;
    let idea = null;

    if (isThoughtLeader) {
      idea = this.props.idea;
    } else {
      const userPortfolioLookup = this.props.portfolio.userPortfolioDict;
      const userPortfolioData = userPortfolioLookup && userPortfolioLookup[userId];
      portfolioData = userPortfolioData && userPortfolioData.securities;
    }

    const ideaPerformanceData = isThoughtLeader ? idea : portfolioData[securityId];

    const performanceLastMonth = isThoughtLeader
      ? idea.prev_month_perf
      : ideaPerformanceData
      ? ideaPerformanceData.performance_last_month
      : 0;
    const performanceThisMonth = isThoughtLeader
      ? idea.this_month_perf
      : ideaPerformanceData
      ? ideaPerformanceData.performance_this_month
      : 0;
    const performanceToday = isThoughtLeader
      ? security.current_price_change_percent
      : ideaPerformanceData
      ? ideaPerformanceData.performance_today
      : 0;

    return (
      <span className={`security-table-idea-performance-container`}>
        <span className={`security-table-allocation-container`}>
          <span className={`security-table-idea-performance-time-period`}>
            <span className={`security-table-idea-performance-label secondary-text-color`}>{'This Month'}</span>
            <span className={`security-table-idea-performance-value`}>
              <NumberWithChangeIndicator
                customClass={'profile-portfolio'}
                valueFormat={'percentage'}
                value={performanceThisMonth}
                coloredText={true}
              />
            </span>
          </span>
          <span className={`security-table-idea-performance-time-period`}>
            <span className={`security-table-idea-performance-label secondary-text-color`}>{'Last Month'}</span>
            <span className={`security-table-idea-performance-value`}>
              <NumberWithChangeIndicator
                customClass={'profile-portfolio'}
                valueFormat={'percentage'}
                value={performanceLastMonth}
                coloredText={true}
              />
            </span>
          </span>
        </span>
      </span>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    ideas: state.ideas,
    portfolio: state.portfolio,
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(IdeaPerformanceCell);

export default composedComponent;
