import React from 'react';
import { connect } from 'react-redux';

import { hideInfoIconModal, logMetricsTrackingEvent } from '../../actions';

import Overlay from '../../components/layout/Overlay';
import withWindowSize from '../HOCS/withWindowSize';

import { convertInfoIconWordKeyToEventName, getDefinition, INFO_ICON_WORDS } from '../../helpers/infoIconHelpers';
import { createMarkup } from '../../helpers/notificationsHelpers';

const MOBILE_LEFT_PADDING = 10;
const MOBILE_BOTTOM_PADDING = 10;

const getDefinitionKeyFromProps = (props) => {
  const { data } = props;
  return data.definitionKey;
};

const generateOldStyleDefinition = (definitionKey, symbol) => {
  const TOOLTIP_KEY_LIST_WITH_HTML_MARKUP = [
    INFO_ICON_WORDS.orderType,
    INFO_ICON_WORDS.bidAskOrder,
    'tooltip_trusted_contact',
    'tooltip_thought_leader',
    'tooltip_expected_return',
    'tooltip_sec_rule_for_short_sale',
    'tooltip_order_form_sec_rule_for_short_sale',
    'tooltip_order_form_sec_rule_for_long_sale',
    'tooltip_community_thought_leader',
    'tooltip_funding_see_tos',
    'portfolio_rank',
  ];

  if (TOOLTIP_KEY_LIST_WITH_HTML_MARKUP.includes(definitionKey)) {
    const definition = getDefinition(definitionKey, symbol);
    const markup = createMarkup(definition);
    return <span dangerouslySetInnerHTML={markup} />;
  }

  if (Array.isArray(definitionKey)) {
    return definitionKey.map((w, i) => (
      <span key={`tooltip-${w}-${i}`} style={i === 1 ? { paddingTop: '15px' } : {}}>
        {getDefinition(typeof w === 'object' ? w.property : w, symbol)}
      </span>
    ));
  } else {
    return getDefinition(typeof definitionKey === 'object' ? definitionKey.property : definitionKey, symbol);
  }
};

const generateDefinitionMarkup = (props) => {
  const { data } = props;
  const { symbol } = data;
  const definitionKey = getDefinitionKeyFromProps(props);
  const definitionStringMarkup = getDefinition(definitionKey, symbol);
  return createMarkup(definitionStringMarkup);
};

const generateDefinitionComponent = (props) => {
  return <span dangerouslySetInnerHTML={generateDefinitionMarkup(props)} />;
};

const generateTooltipContentComponent = (props) => {
  const { data } = props;
  const { isOldStyle, customDefinition, symbol } = data;
  const definitionKey = getDefinitionKeyFromProps(props);

  const hasCustomMessage = customDefinition;

  return hasCustomMessage
    ? customDefinition
    : isOldStyle
    ? generateOldStyleDefinition(definitionKey, symbol)
    : generateDefinitionComponent(props);
};

const Tooltip = (props) => {
  const { style, windowHeight, windowWidth, height, width, CustomComponent } = props;

  const defaultStyles = {
    padding: '15px',
    borderRadius: 4,
    minHeight: 100,
  };

  const classStyles = 'main-bg-color-match';

  let Content = null;
  if (CustomComponent) {
    Content = (
      <div style={{}}>
        <CustomComponent {...props} />
      </div>
    );
  } else {
    Content = <div style={{ fontSize: '12px' }}>{generateTooltipContentComponent(props)}</div>;
  }

  const showMobileStyle = windowWidth < 600;
  if (showMobileStyle) {
    return (
      <div
        className={classStyles}
        style={{
          ...defaultStyles,
          position: 'absolute',
          maxHeight: windowHeight - 100,
          maxWidth: 400,
          margin: '0 auto',
          bottom: MOBILE_BOTTOM_PADDING,
          left: MOBILE_LEFT_PADDING,
          right: MOBILE_LEFT_PADDING,
        }}
      >
        {Content}
      </div>
    );
  }

  return (
    <div
      className={classStyles}
      style={{
        ...(style || {}),
        ...defaultStyles,
        height,
        width,
      }}
    >
      {Content}
    </div>
  );
};

class InfoIconModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.INFO_ICON_SIZE_PIXELS = 14;
    this.INFO_ICON_TOP_OFFSET = 3;

    this.INITIAL_STATE_DEFAULTS = {
      prerenderForSize: false,
      prerenderForSizeComplete: false,
      canFullRender: false,
      infoIconSize: null,
    };

    this.POSITION_TYPES = {
      BOTTOM_RIGHT: 'bottom-right',
      BOTTOM_LEFT: 'bottom-left',
      TOP_LEFT: 'top-left',
      TOP_RIGHT: 'top-right',
    };

    this.POSITION_TYPE_CALC_LOOKUP = {
      [this.POSITION_TYPES.BOTTOM_RIGHT]: this._calcBottomRightPositionedValues,
      [this.POSITION_TYPES.BOTTOM_LEFT]: this._calcBottomLeftPositionedValues,
      [this.POSITION_TYPES.TOP_LEFT]: this._calcTopLeftPositionedValues,
      [this.POSITION_TYPES.TOP_RIGHT]: this._calcTopRightPositionedValues,
    };

    this.state = {
      ...this.INITIAL_STATE_DEFAULTS,
    };
  }

  componentDidMount() {
    const { show } = this.props;
    if (show) this.onShow();
  }

  componentWillUnmount() {
    this.onHide();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { show: prevShow } = prevProps;
    const { show } = this.props;
    const { prerenderForSize, prerenderForSizeComplete, canFullRender } = this.state;
    const {
      prerenderForSize: prevPreRenderForSize,
      prerenderForSizeComplete: prevPrerenderForSizeComplete,
      canFullRender: prevCanFullRender,
    } = prevState;

    if (!prevShow && show) {
      this.onShow();
    }

    if (!prevPreRenderForSize && prerenderForSize) {
      this.onPrerenderForSize();
    }

    if (!prevPrerenderForSizeComplete && prerenderForSizeComplete) {
      this.onPrerenderForSizeComplete();
    }

    if (!prevCanFullRender && canFullRender) {
      this.onRenderComplete();
    }

    if (prevShow && !show) {
      this.onHide();
    }
  }

  render() {
    const { show, data } = this.props;

    const { prerenderForSize, canFullRender, infoIconSize } = this.state;

    if (!show) return null;

    if (canFullRender)
      return (
        <Overlay onClick={this._handleOverlayClick} customClass={'priority-z-index'}>
          <Tooltip
            {...this.props}
            {...infoIconSize}
            CustomComponent={data.CustomComponent}
            style={this._buildTooltipPositionStyle()}
          />
        </Overlay>
      );

    if (prerenderForSize) {
      return (
        <div
          id={'info_icon_prerender_container'}
          style={{
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            pointerEvents: 'none',
            maxWidth: 400 - MOBILE_LEFT_PADDING * 2,
          }}
        >
          <Tooltip {...this.props} CustomComponent={data.CustomComponent} {...infoIconSize} />
        </div>
      );
    }

    // this case can be hit after show is flipped to true but prerenderForSize has not triggered
    return null;
  }

  onShow = () => {
    this.setState(() => ({
      ...this.INITIAL_STATE_DEFAULTS,
      prerenderForSize: true,
    }));

    const definitionKey = getDefinitionKeyFromProps(this.props);
    const event = 'Clicked Info Icon';

    const message = !!this.props.data.CustomComponent
      ? null
      : this.props.data.isOldStyle
      ? this.props.data.trackingMessage ||
        this.props.data.customDefinition ||
        getDefinition(this.props.word, this.props.symbol)
      : this.props.data.customDefinition || generateDefinitionMarkup(this.props);

    const name = convertInfoIconWordKeyToEventName(definitionKey);
    const properties = {
      Name: typeof name === 'string' ? name : null,
      Message: typeof message === 'string' ? message : null,
    };
    logMetricsTrackingEvent(event, properties)();
  };

  onHide = () => {
    this.setState(() => ({
      ...this.INITIAL_STATE_DEFAULTS,
    }));
  };

  onPrerenderForSize = () => {
    const { show } = this.props;
    if (!show) return null;

    const size = this._getInfoIconPrerenderedSize();
    this.setState(() => ({
      infoIconSize: size,
      prerenderForSizeComplete: true,
    }));
  };

  onPrerenderForSizeComplete = () => {
    const { show } = this.props;
    if (!show) return null;

    // basically check to make sure you can render, TODO: add some sort of fallback to retry
    const { infoIconSize } = this.state;
    if (infoIconSize === null) {
      return null;
    }

    this.setState(() => ({
      canFullRender: true,
    }));
  };

  onRenderComplete = () => {
    // log any events?
  };

  _getInfoIconPrerenderedSize = () => {
    const $el = window.$('#info_icon_prerender_container');
    if (!$el) {
      return {
        height: 200,
        width: 200,
      };
    }

    const height = $el.outerHeight(true);
    const width = $el.outerWidth(true);

    if (height === 0 || width === 0) {
      return {
        height: 200,
        width: 200,
      };
    }

    return {
      height,
      width: Math.min(width, 400),
    };
  };

  _getClickedIconPosition = () => {
    const { data } = this.props;
    if (data === null) return null;

    const { elementRef } = data;

    const el = elementRef.current ? elementRef.current : elementRef;
    if (!el) return null;

    const htmlPage = window.$('html');
    const htmlPageScrollOffset = {
      top: htmlPage.scrollTop(),
      left: htmlPage.scrollLeft(),
    };
    const { top, left } = window.$(el).offset();
    return {
      top: top - htmlPageScrollOffset.top,
      left: left - htmlPageScrollOffset.left,
    };
  };

  _getBottomRightAnchorPosition = () => {
    const { data } = this.props;
    if (data === null) return null;

    const { elementRef } = data;

    const el = elementRef.current ? elementRef.current : elementRef;
    if (!el) return null;

    const htmlPage = window.$('html');
    const htmlPageScrollOffset = {
      top: htmlPage.scrollTop(),
      left: htmlPage.scrollLeft(),
    };
    const { top, left } = window.$(el).offset();
    const height = window.$(el).height();
    const width = window.$(el).width();
    return {
      top: top - htmlPageScrollOffset.top + height,
      left: left - htmlPageScrollOffset.left + width,
    };
  };

  _buildTooltipPositionStyle = () => {
    const { data } = this.props;
    const { useBottomRightAsAnchor } = data;

    const iconPosition = useBottomRightAsAnchor ? this._getBottomRightAnchorPosition() : this._getClickedIconPosition();
    if (!iconPosition) return {};

    const commonPositionStyles = {
      position: 'absolute',
    };

    if (useBottomRightAsAnchor) {
      const bottomRightAnchorFit = this._calcBottomLeftPositionedValues(iconPosition);
      if (bottomRightAnchorFit === null) return {};

      const { top, left } = bottomRightAnchorFit;
      return {
        ...commonPositionStyles,
        top,
        left,
      };
    }

    const bestPositionFit = this._getBestPositionFit();
    if (bestPositionFit === null) {
      // TODO: handle this case, although should never exist
      return {};
    }

    const { top, left } = bestPositionFit;
    return {
      ...commonPositionStyles,
      top,
      left,
    };
  };

  _getBestPositionFit = () => {
    const iconPosition = this._getClickedIconPosition();

    let bestFit = null;
    const availablePositionTypes = Object.keys(this.POSITION_TYPES);
    availablePositionTypes.forEach((type) => {
      if (bestFit !== null) return;

      const position = this.POSITION_TYPE_CALC_LOOKUP[this.POSITION_TYPES[type]](iconPosition);
      if (position !== null) bestFit = position;
    });
    return bestFit;
  };

  _calcBottomRightPositionedValues = (iconPosition) => {
    const { infoIconSize } = this.state;
    if (infoIconSize === null) return null;

    const { height, width } = infoIconSize;
    const { top, left } = iconPosition;
    const { windowWidth, windowHeight } = this.props;

    const posTop = top + this.INFO_ICON_SIZE_PIXELS + this.INFO_ICON_TOP_OFFSET;
    const posLeft = left;

    const edgeY = posTop + height;
    const edgeX = posLeft + width;

    const willFitX = windowWidth >= edgeX;
    const willFitY = windowHeight >= edgeY;
    if (!willFitX || !willFitY) return null;

    return {
      top: posTop,
      left: posLeft,
    };
  };

  _calcTopRightPositionedValues = (iconPosition) => {
    const { infoIconSize } = this.state;
    if (infoIconSize === null) return null;

    const { height, width } = infoIconSize;
    const { top, left } = iconPosition;
    const { windowWidth } = this.props;

    const posTop = top - height - this.INFO_ICON_TOP_OFFSET;
    const posLeft = left;

    const edgeY = posTop;
    const edgeX = posLeft + width;

    const willFitX = windowWidth >= edgeX;
    const willFitY = edgeY >= 0;
    if (!willFitX || !willFitY) return null;

    return {
      top: posTop,
      left: posLeft,
    };
  };

  _calcTopLeftPositionedValues = (iconPosition) => {
    const { infoIconSize } = this.state;
    if (infoIconSize === null) return null;

    const { height, width } = infoIconSize;
    const { top, left } = iconPosition;

    const posTop = top - height - this.INFO_ICON_TOP_OFFSET;
    const posLeft = left - width + this.INFO_ICON_SIZE_PIXELS;

    const edgeY = posTop;
    const edgeX = posLeft;

    const willFitX = edgeX >= 0;
    const willFitY = edgeY >= 0;
    if (!willFitX || !willFitY) return null;

    return {
      top: posTop,
      left: posLeft,
    };
  };

  _calcBottomLeftPositionedValues = (iconPosition) => {
    const { infoIconSize } = this.state;
    if (infoIconSize === null) return null;

    const { height, width } = infoIconSize;
    const { top, left } = iconPosition;
    const { windowHeight } = this.props;

    const posTop = top + this.INFO_ICON_SIZE_PIXELS + this.INFO_ICON_TOP_OFFSET;
    const posLeft = left - width + this.INFO_ICON_SIZE_PIXELS;

    const edgeY = posTop + height;
    const edgeX = posLeft;

    const willFitX = edgeX >= 0;
    const willFitY = windowHeight >= edgeY;
    if (!willFitX || !willFitY) return null;

    return {
      top: posTop,
      left: posLeft,
    };
  };

  _handleOverlayClick = () => {
    const { dispatch } = this.props;
    hideInfoIconModal()(dispatch);
  };
}

const mapStateToProps = (state) => {
  return {
    show: state.infoIcon.show,
    data: state.infoIcon.data,
  };
};

const connectedComponent = connect(mapStateToProps)(InfoIconModal);
export default withWindowSize(connectedComponent);
