import React from 'react';

import ChargeHeader from './components/ChargeHeader';
import ChargeRow from './components/ChargeRow';

const ChargeSummary = (props) => {
  const { columns, charges } = props;
  return (
    <div className={`charge-summary-container`}>
      <ChargeHeader columns={columns} />
      {charges.map((chargeData, i) => (
        <ChargeRow key={i} chargeData={chargeData} />
      ))}
      <div className="line-break"></div>
    </div>
  );
};

export default ChargeSummary;
