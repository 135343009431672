import { ADD_KARMA_POINTS } from '../constants';

export const addKarmaPoints = (value) => {
  return function (dispatch) {
    dispatch({
      type: ADD_KARMA_POINTS,
      payload: value,
    });
    return true;
  };
};
