import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserFromState } from '../../../selectors/currentUser';
import { fetchDataTypes, updateUserProperty } from '../../../actions';

export const useUserOptionsEnabled = () => {
  // const dispatch = useDispatch();
  const user = useSelector(getCurrentUserFromState);

  const setOptionsEnabled = React.useCallback(() => {
    // updateUserProperty({
    //   is_options_enabled: true,
    // })(dispatch);
  }, []);

  return {
    optionsExperience: user.options_trading_experience,
    isEnabled: false,
    canUseFeature: false,
    setOptionsEnabled,
  };

  // return {
  //   optionsExperience: user.options_trading_experience,
  //   isEnabled: user.is_options_enabled,
  //   canUseFeature: user.is_options_enabled,
  //   setOptionsEnabled,
  // };
};
