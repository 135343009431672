import React from 'react';
import { SupportContact } from '../../../constants/types/support';

class ContactSupportIssue extends React.PureComponent {
  render() {
    const { style } = this.props;

    return (
      <p className={'contact-support-message'} style={style}>
        If issue persists, contact <a href={SupportContact.EMAIL.HREF}>{SupportContact.EMAIL.LINK_TEXT}</a> for more
        information.
      </p>
    );
  }
}

ContactSupportIssue.defaultProps = {
  style: {},
};

export default ContactSupportIssue;
