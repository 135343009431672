import React from 'react';
import { useSelector } from 'react-redux';

const filterAccount = (account, filters) => {
  if (filters?.needsVerification) {
    return account.needs_micro_deposit_verification;
  }
  if (filters?.isVerificationReady) {
    return account.needs_micro_deposit_verification && account.micro_deposits_ready;
  }

  return true;
};

export const useBankAccounts = (filters) => {
  const bankAccountsLookup = useSelector((state) => state.liveAccount.liveAccounts?.accounts);
  const bankAccountsInStore = Object.keys(bankAccountsLookup || {}).map((account_id) => bankAccountsLookup[account_id]);

  const [bankAccounts, setBankAccounts] = React.useState(
    bankAccountsInStore.filter((account) => account.can_accept_transfers && filterAccount(account, filters))
  );

  React.useEffect(() => {
    setBankAccounts(
      bankAccountsInStore.filter((account) => account.can_accept_transfers && filterAccount(account, filters))
    );
  }, [bankAccountsLookup, filters]);

  return bankAccounts;
};
