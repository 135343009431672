import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../../../actions/index';
import { createBasicErrorModal } from '../../../../constants/modals';

import Button from '../../../../components/buttons/Button';
import LoadingIcon from '../../../../components/misc/LoadingIcon';
import BlankCell from '../BlankCells/BlankCell';

import { snakeToHyphens, isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import { returnPathTo } from '../../../../constants/paths';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';
import { getIsUsingRebalancerForPortfolioOptimization } from '../../../../helpers/portfolioOptimizationHelpers';
import { TrackingEvents } from '../../../../utils/tracking/events';
import { FlatButton } from '../../../../main/components/buttons';
import { LoadingAnimation } from '@src/main/components/ui/LoadingAnimation';

export class TradeAllOptimizedOrdersCell extends Component {
  render() {
    const isUsingRebalancer = getIsUsingRebalancerForPortfolioOptimization();
    if (isUsingRebalancer) return <BlankCell />;

    const columnKeyName = this.props.column.keyName;
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? this.props.loadingCellComponent : this._renderCellContent()}
      </td>
    );
  }

  _isLoading = () => {
    return (
      !('positions' in this.props.currentUser) || !('optimized_positions_by_security_id' in this.props.optimizer.data)
    );
  };

  _returnLinkForHandlingClick = () => {
    const securityId = this.props.securityId;
    const openSecurityPanelConfig = {
      securityId,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    return <div className="security-table-optimizer-trade-all-cell"></div>;
  };

  _handleTradeAllClick = () => {
    const currentOptimization = this.props.optimizer.data.optimized_positions_by_security_id;
    const currentAllocations = this.props.currentUser.positions;

    const modalMessage = 'Creating Orders';
    const contentComponent = (
      <div className={'creating-optimized-orders component-bg'} style={{ maxWidth: '320px ' }}>
        <LoadingAnimation size={'small'} />
        <div style={{ marginTop: '4px', marginLeft: '8px', fontSize: '24px' }}>{modalMessage}</div>
      </div>
    );
    const modal = {
      contentComponent,
      size: 'wide',
      dismissable: false,
    };
    this.props.actions.showModal(modal);

    // parse trades to determine shares needed
    const securityIdsInOptimizeTrade = Object.keys(currentOptimization);
    const securityIdsInOptimizeTradeWhereUserHasPosition = securityIdsInOptimizeTrade.filter(
      (id) => id in currentAllocations
    );

    // this would happen if user added an idea after page load
    const securityIdsInOptimizeTradeWhereUserHasNoPositionData = securityIdsInOptimizeTrade.filter(
      (id) => !(id in currentAllocations)
    );

    const trades = [];
    securityIdsInOptimizeTradeWhereUserHasNoPositionData.forEach((id) => {
      const totalSharesDesired = currentOptimization[id].shares;

      if (totalSharesDesired === 0) {
        // skip, has optimal amount
      } else {
        const operation = totalSharesDesired > 0 ? 'buy' : 'sell';
        const shares = Math.abs(totalSharesDesired);
        const type = 'market';
        const is_from_optimizer = true;
        const tradeObj = {
          security_id: id,
          operation,
          shares,
          type,
          is_from_optimizer,
        };
        trades.push(tradeObj);
      }
    });

    // determine what trade has to be to satisfy optimal shares
    securityIdsInOptimizeTradeWhereUserHasPosition.forEach((id) => {
      const totalSharesDesired = currentOptimization[id].shares;
      const currentPosition = currentAllocations[id].shares;
      const totalVsCurrentDifference = totalSharesDesired - currentPosition;
      if (totalVsCurrentDifference === 0) {
        // skip, has optimal amount
      } else {
        const operation = totalVsCurrentDifference > 0 ? 'buy' : 'sell';
        const shares = Math.abs(totalVsCurrentDifference);
        const type = 'market';
        const is_from_optimizer = true;
        const tradeObj = {
          security_id: id,
          operation,
          shares,
          type,
          is_from_optimizer,
        };
        trades.push(tradeObj);
      }
    });

    this.props.actions.createOrders(trades, { clearOrdersBefore: true }).then((response) => {
      const ajaxWasSuccessful = response && response.data && !response.data.error;
      if (ajaxWasSuccessful) {
        this._logCreateOrders(trades);
        setTimeout(() => {
          this.props.actions.hideModal();
          this.props.navigate(returnPathTo('orders'));
        }, 250);
      } else {
        const modalMessage = 'Could not create orders. Please try again later.';
        const modal = {
          contentComponent: createBasicErrorModal(modalMessage),
          dismissable: true,
        };
        this.props.actions.showModal(modal);
      }
    });
  };

  _logCreateOrders = (trades) => {
    // new amp event style that merges all order types
    const securityIds = trades.map((tradeData) => tradeData.security_id);
    const properties = {
      'Security Ids': securityIds.join(','),
      'Order Creation Type': 'Full Optimization',
    };
    TrackingEvents.orders.PLACED_TRADE.send(properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    optimizer: state.optimizer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(TradeAllOptimizedOrdersCell);

export default composedComponent;
