import React from 'react';
import { useSelector } from 'react-redux';

import Icon from '../../components/misc/Icon';
import { Facebook } from '../Share/Facebook';
import { Twitter } from '../Share/Twitter';
import { LinkedIn } from '../Share/LinkedIn';
import { Email } from '../Share/Email';

import { sendFacebookTrackingEvent } from '../../constants/facebookTracking';
import CopyUtil from '../UI/Utils/CopyUtil';
import { logMetricsTrackingEvent } from '../../actions/index';
import { useReferralCopy } from '../../main/hooks/referrals/useReferralCopy';
import { useCurrentUser } from '../../main/hooks/user';
import { TrackingEvents } from '../../utils/tracking/events';
import { REFERRAL_TYPES } from '../../utils/tracking/events/referral';
import {
  REFERRAL_SHARE_LINK_CHANNELS,
  REFERRAL_SHARE_LINK_CONTEXTS,
  useCurrentUserReferral,
} from '../../main/hooks/user/useCurrentUserReferral';

const ShareMessage = ({ shareLinkContext, shareContext, additionalPropertiesForEventLogging }) => {
  const { inviteLink: shareLink } = useCurrentUserReferral(shareLinkContext, REFERRAL_SHARE_LINK_CHANNELS.LINK);

  const { message, testGroupId } = useReferralCopy(shareLink);

  const _logShareLinkManualCopy = () => {
    const event = 'Referrals - Click Share Link';
    const properties = {
      'Test Group Id': testGroupId,
    };
    logMetricsTrackingEvent(event, properties)();

    const params = { network: 'Share Link' };
    sendFacebookTrackingEvent('Social Share', params);

    TrackingEvents.referral.USE_REFERRAL_ACTION.send(REFERRAL_TYPES.shareMessaging);
  };

  const logReferralAction = () => {
    TrackingEvents.referral.USE_REFERRAL_ACTION.send(REFERRAL_TYPES.shareMessaging);
  };

  return (
    <div>
      <textarea id={'share_link_field'} readOnly={1} onClick={_logShareLinkManualCopy} value={message} />
      <div className={'flex-space-between'}>
        <div
          className={'copy-button-container'}
          style={{
            display: 'inline-block',
          }}
        >
          <CopyUtil
            elementId={'share_link_button'}
            targetTextAreaElementId={'#share_link_field'}
            textAreaId={'share_link_field'}
            eventName={'Clicked Share Link Copy'}
            additionalPropertiesForEventLogging={{ 'Test Group Id': testGroupId }}
            onCopyEffect={logReferralAction}
          >
            <div className={'copy-button'}>
              <div
                id={'share_link_button'}
                className={'btn share-link-copy-btn rewards-copy-btn-color'}
                style={{ padding: '8px 15px 8px 13px' }}
              >
                <Icon id="share_link_button_icon" icon={'fa fa-files-o'} size="nav" />
                <span style={{ marginLeft: '5px' }}>Copy</span>
              </div>
            </div>
          </CopyUtil>
        </div>
        <SocialShares
          message={message}
          testGroupId={testGroupId}
          shareContext={shareContext}
          additionalPropertiesForEventLogging={additionalPropertiesForEventLogging}
        />
      </div>
    </div>
  );
};

const EmailShare = ({ message, testGroupId }) => {
  const log = () => {
    const network = 'Email';
    const event = 'Referrals - Click Social Share';
    const properties = {
      Network: network,
      'Test Group Id': testGroupId,
    };
    logMetricsTrackingEvent(event, properties)();

    const params = { network };
    sendFacebookTrackingEvent('Social Share', params);

    TrackingEvents.referral.USE_REFERRAL_ACTION.send(REFERRAL_TYPES.manualEmail);
  };
  return <Email to={''} subject={'A new, collaborative way to invest'} body={message} additionalActionOnClick={log} />;
};

const ShareLink = ({ shareContext, additionalPropertiesForEventLogging }) => {
  return (
    <div className={'share-link-container'}>
      <ShareMessage
        shareLinkContext={REFERRAL_SHARE_LINK_CONTEXTS.REWARDS_SCREEN}
        shareContext={shareContext}
        additionalPropertiesForEventLogging={additionalPropertiesForEventLogging}
      />
      <div className={'clearfix'} />
    </div>
  );
};

const SocialShares = ({ message, testGroupId, shareContext, additionalPropertiesForEventLogging }) => {
  const currentUser = useCurrentUser();
  const encodedShareUrl = currentUser.encoded_share_url;

  let eventProperties = {};
  if (shareContext) {
    eventProperties = {
      Context: shareContext,
      ...(additionalPropertiesForEventLogging || {}),
    };
  } else {
    eventProperties = {
      ...(additionalPropertiesForEventLogging || {}),
    };
  }

  const _logFacebookShareLinkClick = () => {
    const network = 'Facebook';
    const event = 'Referrals - Click Social Share';
    const properties = {
      Network: network,
      ...eventProperties,
    };
    logMetricsTrackingEvent(event, properties)();

    const params = { network };
    sendFacebookTrackingEvent('Social Share', params);

    TrackingEvents.referral.USE_REFERRAL_ACTION.send(REFERRAL_TYPES.facebook);
  };

  const _logTwitterShareLinkClick = () => {
    const network = 'Twitter';
    const event = 'Referrals - Click Social Share';
    const properties = {
      Network: network,
      ...eventProperties,
    };
    logMetricsTrackingEvent(event, properties)();

    const params = { network };
    sendFacebookTrackingEvent('Social Share', params);

    TrackingEvents.referral.USE_REFERRAL_ACTION.send(REFERRAL_TYPES.twitter);
  };

  const _logLinkedinShareLinkClick = () => {
    const network = 'LinkedIn';
    const event = 'Referrals - Click Social Share';
    const properties = {
      Network: network,
      ...eventProperties,
    };
    logMetricsTrackingEvent(event, properties)();

    const params = { network };
    sendFacebookTrackingEvent('Social Share', params);

    TrackingEvents.referral.USE_REFERRAL_ACTION.send(REFERRAL_TYPES.linkedIn);
  };

  return (
    <div className={'share-link-social-links social-links-new'}>
      <Facebook url={encodedShareUrl} additionalActionOnClick={_logFacebookShareLinkClick} />
      <Twitter
        url={encodedShareUrl}
        message={
          '.@tornadoinvest is a new, collaborative way to #invest. Plus they will give you up to $1,000 cash to invest when you make a deposit. #InvestBetterTogether.'
        }
        additionalActionOnClick={_logTwitterShareLinkClick}
      />
      <LinkedIn url={encodedShareUrl} additionalActionOnClick={_logLinkedinShareLinkClick} />
      <EmailShare message={message} testGroupId={testGroupId} />
    </div>
  );
};

export default ShareLink;
