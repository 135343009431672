import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../actions/index';

import LoadingIcon from '../../components/misc/LoadingIcon';
import InfoIcon from './InfoIcon';

import { formatDataValue } from '../../helpers/securitiesHelpers';

class UserIdeasPerformance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetchingUserData: false,
    };
  }

  componentDidMount() {
    this._ifNeededFetchUserPerformanceData();
  }

  render() {
    if (this._isUserPrivate()) {
      return this._renderPrivateMessage();
    }

    if (this._isLoading() || !this.props.user) {
      return this._renderLoading();
    }

    return (
      <div className={'user-idea-performance-container'}>
        {this._renderHeading()}
        {this._renderTimePeriods()}
        {this.props.showInlineInfoIcon && (
          <InfoIcon word={'performance_numbers'} style={{ top: '11px', right: '-15px' }} position="right" />
        )}
      </div>
    );
  }

  _renderPrivateMessage = () => {
    return <div className={'user-is-private secondary-text-color'}>Private</div>;
  };

  _renderHeading = () => {
    return (
      <div className={'main-label'}>
        <span style={{ position: 'relative' }}>
          Idea Performance
          <InfoIcon word={'performance_numbers'} style={{ top: 'auto', bottom: '1px' }} />
        </span>
      </div>
    );
  };

  _renderTimePeriods = () => {
    var timePeriods = [];
    if (this.props.showWeeklyInsteadOfDaily) {
      timePeriods.push({
        label: 'Last Week',
        performanceKeyName: 'performance_last_week',
      });
    } else {
      timePeriods.push({
        label: 'Today',
        performanceKeyName: 'performance_today',
      });
    }
    timePeriods.push({
      label: 'This Month',
      performanceKeyName: 'performance_this_month',
    });
    timePeriods.push({
      label: 'Last Month',
      performanceKeyName: 'performance_last_month',
    });

    return (
      <div className={'user-idea-performance'}>
        {timePeriods.map((timePeriod) => this._renderTimePeriod(timePeriod))}
      </div>
    );
  };

  _renderTimePeriod = (timePeriod) => {
    const { label, performanceKeyName } = timePeriod;
    return (
      <div key={`performance-time-period-${performanceKeyName}`} className={'performance-time-period'}>
        {this._renderLabel(label)}
        {this._renderValue(performanceKeyName)}
      </div>
    );
  };

  _renderLabel = (label) => {
    return <div className={'time-label secondary-text-color'}>{label}</div>;
  };

  _renderValue = (performanceKeyName) => {
    const performance = this._returnUserIdeaPerformanceData();
    if (!performance) {
      return null;
    }
    const rawValue = performance[performanceKeyName];
    const value = formatDataValue(rawValue, 'percentage');
    const neutralColorClassName = 'no-change-text-color';
    const increaseColorClassName = 'increase-change-text-color';
    const decreaseColorClassName = 'decrease-change-text-color';
    const colorClassName =
      rawValue === 0 ? neutralColorClassName : rawValue > 0 ? increaseColorClassName : decreaseColorClassName;
    const prefix = rawValue === 0 ? '' : rawValue > 0 ? '+' : ''; // '-' not needed as will show if number is negative
    return <div className={`time-period-performance-value ${colorClassName}`}>{`${prefix}${value}`}</div>;
  };

  _renderLoading = () => {
    return (
      <div className={'user-idea-performance-loading'}>
        {null && <LoadingIcon icon="fading-3balls" size="small" style={{ marginLeft: '25px' }} />}
      </div>
    );
  };

  _isLoading = () =>
    this._isCollectiveUser()
      ? this.props.forceLoading
      : !this._isUserIdeaPerformanceDataAvailable() || this.props.forceLoading;

  _isLoadingCurrentUser = () => !('user_id' in this.props.currentUser);

  _fetchUserIdeaPerformance = () => {
    const userId = this._returnUserId();
    const config = {
      include: ['performance'],
    };
    this.props.actions.getUser(userId, config);
  };

  _ifNeededFetchUserPerformanceData = () => {
    if (this.props.dontGetData) {
      return false;
    }
    if (!this._isUserIdeaPerformanceDataAvailable() && !this.isFetchingUserData) {
      this.isFetchingUserData = true;
      this._fetchUserIdeaPerformance();
    }
  };

  _isUserIdeaPerformanceDataAvailable = () => {
    const userId = this._returnUserId();
    const userIdeaPerformanceStore = this._returnUserIdeaPerformanceStoreLookup();
    return userId in userIdeaPerformanceStore && userIdeaPerformanceStore[userId].performance_today !== undefined;
  };

  _returnUserIdeaPerformanceData = () => {
    if (this._isCollectiveUser()) {
      const { performance_today, performance_this_month, performance_last_month } = this.props.user;
      return {
        performance_today,
        performance_this_month,
        performance_last_month,
      };
    }
    const userId = this._returnUserId();
    const userIdeaPerformanceStore = this._returnUserIdeaPerformanceStoreLookup();
    return userId in userIdeaPerformanceStore ? userIdeaPerformanceStore[userId] : null;
  };

  _returnUserIdeaPerformanceStoreLookup = () => this._returnUserIdeaPerformanceStore().userDict;

  _returnUserIdeaPerformanceStore = () => this.props.userIdeaPerformance;

  _isCollectiveUser = () => this.props.userIsCollectiveUser;

  _isUserPrivate = () => (this._returnUser() ? this._returnUser().is_private : false);

  _returnUser = () => this._returnUserList()[this._returnUserId()];

  _returnUserList = () => this._returnUserStore().userList;

  _returnUserStore = () => this.props.users;

  _returnUserId = () => this.props.userId;
}

const mapStateToProps = (state) => {
  return {
    userIdeaPerformance: state.userIdeaPerformance,
    users: state.users,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(
  // withWindowSize,
  connect(mapStateToProps, mapDispatchToProps)
)(UserIdeasPerformance);

export default composedComponent;
