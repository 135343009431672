import React from 'react';
import Button from '../../components/buttons/Button';
import styled from 'styled-components';
import { FlatButton } from '../../main/components/buttons';
import { Container } from '../../main/components/ui';
import Icon from '../../components/misc/Icon';
import { Refresh } from '../../main/icons';
import { Spacing } from '../../main/components/layout';
import LoadingIcon from '../../components/misc/LoadingIcon';

export const ButtonWrap = styled('div')`
  & button {
    text-transform: uppercase;
    box-shadow: none;
    padding: 5px 20px;
    border-radius: 6px;
    font-size: 12px;
    border: none !important;
    width: 110px !important;
  }

  svg {
    height: 14px;
    width: 14px;
    fill: ${({ theme }) => theme.themeColors.buttonText};
  }
`;

export const SolidButtonWrap = styled('div')`
  & button {
    text-transform: uppercase;
    box-shadow: none;
    padding: 5px 20px;
    border-radius: 6px;
    font-size: 12px;
    border: none !important;
    width: 110px !important;
  }
  svg {
    height: 14px;
    width: 14px;
    fill: ${({ theme }) => theme.themeColors.buttonText};
  }
`;

const PositionWrapper = styled.div`
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ProminentRefreshButton = (props) => {
  const { isUpdating, handleClick, isSolid } = props;

  const Wrapper = isSolid ? SolidButtonWrap : ButtonWrap;
  return (
    <Wrapper className={'prominent-refresh-button-container'}>
      {isUpdating ? (
        <FlatButton onClick={() => false} disabled>
          <Container centerAll relative height={14}>
            <PositionWrapper>...</PositionWrapper>
          </Container>
        </FlatButton>
      ) : (
        <FlatButton onClick={handleClick}>
          <Container centerAll>
            <Container centerAll right={4}>
              <Refresh />
            </Container>
            <span>Refresh</span>
          </Container>
        </FlatButton>
      )}
    </Wrapper>
  );
};

export default ProminentRefreshButton;
