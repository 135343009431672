import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/index';
import { Config } from '@src/constants';
import LargeTextField from '../../components/form/LargeTextField';
import Toggle from '../../components/form/Toggle';
import ThoughtLink from './ThoughtLink';
import InfoIcon from '../UI/InfoIcon';
import IconButton from '../../components/buttons/IconButton';
import FormLoadingOverlay from '../../components/overlays/FormLoadingOverlay';
import PageLoading from '../../components/PageLoading';
import WidthLayoutManager from '../UI/Utils/WidthLayoutManager';
import { createInputComponent } from '@src/helpers/formHelpers';
import { removeUrlFromThought, getUrlsFromString } from '@src/helpers/thoughtsHelpers';
import { TrackingEvents } from '@src/utils/tracking/events';
import { FlatButton } from '@src/main/components/buttons';

const ThoughtActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0 0 0;
`;

class CreateThoughtContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: ('addThoughtFormContainer' in this && this.addThoughtFormContainer.offsetWidth) || null,

      inputs: [
        {
          type: LargeTextField,
          typeName: 'LargeTextField',
          name: 'thought',
          label: null,
          props: {
            customClass: 'thought-input',
            placeholder:
              'e.g., Early reviews of upcoming product have been excellent. (You can also paste a link to an article here.)',
            handleChangeFunc: 'handleThoughtTextChange',
            autoFocus: false,
            popOutErrorMessage: true,
          },
        },
        {
          type: Toggle,
          typeName: 'Toggle',
          name: 'thought_type',
          props: {
            values: [{ id: 0, name: 'Pro' }, { id: 1, name: 'Con' }],
            selectionValues: ['Pro', 'Con'],
            selectionValuesClass: 'thought-type-toggle',
          },
        },
      ],

      formData: {},
      openGraphObject: null,

      errors: {},
      warnings: {},
      customError: null,
      customWarning: null,
      // used to determine when to hide warning
      prevThoughtText: null,

      creatingThought: false,
      loadingPreviewImage: false,

      isPostingThought: false,
      wasThoughtPosted: false,
      wasErrorWhilePostingThought: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this._updateThoughtTypeFromProps();
    this._saveOgoToState();
    this.handleResize();
    if (this.props.loadSecurityDataIfNecessary) {
      this.props.actions.quickFetchSecuritiesData([this.props.securityId]);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this._isLoading(prevProps) && !this._isLoading()) {
      this._onLoad();
    }
    if (prevProps.thoughtType !== this.props.thoughtType) {
      this._updateThoughtTypeFromProps();
    }

    if (prevState.formData !== this.state.formData) {
      if (this.state.customError) {
        this.createErrorsForInvalidThought();
      }

      if (this.state.prevThoughtText !== this.state.formData.thought) {
        this.setState((prevState) => ({
          customWarning: null,
        }));
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const thoughtTextField = this.state.inputs[0];
    const thoughtProConToggle = this.state.inputs[1];

    if (this._isLoading()) {
      return this._renderLoading();
    }

    return (
      <div className={this.props.customClassContainer || ''}>
        <div
          className={`
          react-add-thought-form
          ${this.props.inModal ? '' : 'component-bg'}
          border-accent
        `}
          ref={(el) => {
            this.addThoughtFormContainer = el;
          }}
        >
          {'handleCancelCreatingThought' in this.props && (
            <IconButton
              icon="fa-times-thin"
              colorClassName="secondary-text-color"
              size="medium"
              style={{ position: 'absolute', top: '0px', right: '5px' }}
              handleClick={this.cancelCreatingThought}
            />
          )}
          <div className="thought-heading-message">{this._renderCreateThoughtHeadingMessage()}</div>
          <div className="thought-field-row">
            {createInputComponent.call(this, thoughtTextField, null, thoughtTextField.name)}
          </div>

          <WidthLayoutManager
            className={'thought-field-sub-row'}
            widthThreshold={380}
            greaterThanThresholdLayout={(width) => (
              <>
                <div className="thought-pro-con-toggle-container">
                  {createInputComponent.call(this, thoughtProConToggle, null, thoughtProConToggle.name)}
                  <div style={{ paddingLeft: '15px' }}>
                    {this._didSelectProOrCon() && (
                      <div
                        className={`
                          selected-pro-con-message
                          ${this._isProSelected() ? 'pro-text-color' : 'con-text-color'}`}
                      >
                        {`A reason ${this._returnSecurity().symbol || 'this stock'} may go ${
                          this._isProSelected() ? 'up' : 'down'
                        }`}
                      </div>
                    )}
                  </div>
                </div>
                {this._renderThoughtCharCounter()}
              </>
            )}
            lessThanThresholdLayout={(width) => (
              <div style={{ width: '100%' }}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className="thought-pro-con-toggle-container">
                    {createInputComponent.call(this, thoughtProConToggle, null, thoughtProConToggle.name)}
                  </div>
                  {this._renderThoughtCharCounter()}
                </div>
                <div style={{ width: '100%', paddingTop: '5px' }}>
                  {this._didSelectProOrCon() && (
                    <div
                      className={`
                        selected-pro-con-message
                        ${this._isProSelected() ? 'pro-text-color' : 'con-text-color'}`}
                    >
                      {`A reason ${this._returnSecurity().symbol || 'this stock'} may go ${
                        this._isProSelected() ? 'up' : 'down'
                      }`}
                    </div>
                  )}
                </div>
              </div>
            )}
          />

          <ThoughtLink
            loadingPreview={this._isLoadingPreviewImage()}
            ogo={this._returnOgo()}
            showPreview={this._returnOgo() !== null}
            imageUrl={this._returnOgo() && this._returnOgo().images_url}
            title={this._returnOgo() && this._returnOgo().title}
            url={this._returnOgo() && this._returnOgo().url}
            handleRemovePreviewImage={this.handleRemovePreviewImage}
            updateImageSrc={this.updateImageSrc}
            thoughtId={null}
            thoughtType={this._returnFormThoughtType()}
            stockSymbol={this._returnSecurity().symbol}
            securityId={this._returnSecurity().security_id}
            viewingContext={this.props.viewingContext}
            // openVideoInPanel
          />
          {this.state.customError && <div className="custom-error">{this.state.customError}</div>}
          {this.state.customWarning && <div className="custom-warning">{this.state.customWarning}</div>}
          {this._renderThoughtSavingOverlay()}
          {this._renderThoughtSavedOverlay()}
          <ThoughtActionButtonsWrapper className="add-thought-action-buttons">
            {this._isLoadingPreviewImage() ? (
              <FlatButton onClick={this._doNothing}>Loading</FlatButton>
            ) : (
              <FlatButton fullWidth onClick={this.handleThoughtCreate}>
                Post
              </FlatButton>
            )}
          </ThoughtActionButtonsWrapper>
        </div>
      </div>
    );
  }

  _doNothing = () => false;

  _isLoading = (props) => !this._returnSecurity(props);

  _isLoadingPreviewImage = () => this.props.isLoadingCustomImage || this.state.loadingPreviewImage;

  _renderLoading = () => <PageLoading flatStyle />;

  updateImageSrc = (src) => {
    this.setState((prevState) => ({
      openGraphObject: {
        ...prevState.openGraphObject,
        image_link: src,
      },
    }));
  };

  _renderThoughtCharCounter = () => {
    return (
      <div
        className={`add-thought-char-counter ${this._isThoughtTooLong() ? 'thought-too-long error-text-color' : ' '}`}
      >
        {`${this._returnCurrentThoughtCharLength()}/${this._returnMaxThoughtCharLimit()}`}
      </div>
    );
  };

  _renderThoughtSavingOverlay = () => {
    return (
      <FormLoadingOverlay
        show={this._isPostingThought()}
        spinner={{
          iconClass: 'circle-loading-spinner circle-loading-spinner-medium',
          style: { marginBottom: '-7px' },
        }}
        message="Posting"
        className="posting-thought-overlay"
      />
    );
  };

  _renderThoughtSavedOverlay = () => {
    return (
      <FormLoadingOverlay
        show={this._wasThoughtPosted()}
        spinner={{
          iconClass: 'fa fa-check-circle success-text-color',
          style: {
            marginBottom: '-7px',
          },
        }}
        message="Posted"
      />
    );
  };

  _clearThought = () => {
    this.setState(() => ({
      formData: {},
      openGraphObject: null,

      errors: {},
      warnings: {},
      customError: null,
      customWarning: null,

      prevThoughtText: null,
      creatingThought: false,
      loadingPreviewImage: false,

      isPostingThought: false,
      wasThoughtPosted: false,
      wasErrorWhilePostingThought: false,
    }));
  };

  _setIsPostingThought = () => {
    this.setState(() => ({
      isPostingThought: true,
    }));
  };

  _postThoughtWasSuccess = () => {
    this.setState(() => ({
      isPostingThought: false,
      wasThoughtPosted: true,
    }));
  };

  _postThoughtWasFailure = () => {
    this.setState(() => ({
      isPostingThought: false,
      wasThoughtPosted: false,
      wasErrorWhilePostingThought: true,
    }));
  };

  _isThoughtTooLong = (string) =>
    (string ? string.length : this._returnCurrentThoughtCharLength()) > this._returnMaxThoughtCharLimit();

  _createThoughtTypeObj = (thoughtType) => {
    if (thoughtType && typeof thoughtType === 'object') {
      return thoughtType;
    } else if (thoughtType === 'Pro') {
      return { id: 0, name: 'Pro' };
    } else if (thoughtType === 'Con') {
      return { id: 1, name: 'Con' };
    } else {
      return thoughtType;
    }
  };

  // will allow true if it doesn't have the data to determine if featured, which requires the api to validate this
  // this requirement was added when this was put into the feed
  _isSecurityUnfeatured = () =>
    this._returnSecurity() && 'featured' in this._returnSecurity() && !this._returnSecurity().featured;

  _returnMaxThoughtCharLimit = () => this.props.maxCharacterLimitForThought || Config.maxCharacterLimitForThought;

  _returnSecurityId = () => this.props.securityId || this.props.security.security_id;

  _returnSecurity = (props) => this._returnSecuritiesLookupData(props)[this._returnSecurityId()];

  _returnSecuritiesLookupData = (props) => this._returnSecurityStore(props).lookup;

  _returnSecurityStore = (props) => (props || this.props).securities;

  _isThoughtValid = () => this._wasThoughtEntered() && this._wasThoughtTypeEntered() && !this._isThoughtTooLong();

  _wasThoughtEntered = () => this._returnFormThoughtText() && this._returnFormThoughtText().length > 0;

  _wasThoughtTypeEntered = () => this._returnFormThoughtType();

  _returnFormData = () => this.state.formData;

  _returnFormThoughtText = () => this._returnFormData().thought;

  _returnFormThoughtType = () => this._returnFormData().thought_type;

  _didSelectProOrCon = () => !!(this._isProSelected() || this._isConSelected());

  _isProSelected = () => {
    const thoughtType = this._returnFormThoughtType();
    if (!thoughtType) {
      return false;
    }
    return thoughtType.id === 0;
  };

  _isConSelected = () => {
    const thoughtType = this._returnFormThoughtType();
    if (!thoughtType) {
      return false;
    }
    return thoughtType.id === 1;
  };

  _returnOgo = () => this.state.openGraphObject;

  _returnFormThoughtTypeFormatted = () => this._createThoughtTypeObj(this._returnFormThoughtType());

  _returnFormThoughtTextFormatted = () => {
    if (this._returnOgo()) {
      return removeUrlFromThought(this._returnFormThoughtText());
    }
    return this._returnFormThoughtText();
  };

  _createThoughtFormattedForPost = () => ({
    security_id: this._returnSecurityId(),
    response_to_thought_id: this.props.logFromDisagreeThoughtId || null,
    thought: this._returnFormThoughtTextFormatted(),
    thought_type: this._returnFormThoughtTypeFormatted(),
    open_graph_object: this._returnOgo() || null,
  });

  _isPostingThought = () => this.state.isPostingThought;

  _wasThoughtPosted = () => this.state.wasThoughtPosted;

  handleThoughtCreate = () => {
    if (this._isPostingThought()) {
      return false;
    }

    if (this._isThoughtValid()) {
      this._setIsPostingThought();
      return this._delayActionForAnimation(this.createThought, this._returnThoughtPostingAnimationMinLength());
    } else {
      return this.createErrorsForInvalidThought();
    }
  };

  _returnThoughtPostingAnimationMinLength = () => 1000;

  _returnThoughtPostedAnimationMinLength = () => 2000;

  _delayActionForAnimation = (action, delayLength) => setTimeout(action, delayLength);

  _handleThoughtPostSuccessAdditionalAction = () => {
    if (this.props.thoughtPostSuccessAdditionalAction) {
      this.props.thoughtPostSuccessAdditionalAction();
    }
  };

  _defaultCreateThought = (thought) => {
    return this.props.actions.createThought([thought]).then((response) => {
      if (response && response.error) {
        this._createErrorPostingThoughtModal(response);
      } else {
        this._logPostedThought(thought);
      }
      return response;
    });
  };

  _returnCreateThoughtAction = () => {
    const requestAction =
      'handleCreateThought' in this.props ? this.props.handleCreateThought : this._defaultCreateThought;
    return requestAction;
  };

  createThought = () => {
    if (!this._isPostingThought()) {
      this._setIsPostingThought();
    }

    const thought = this._createThoughtFormattedForPost();
    return this._returnCreateThoughtAction()(thought).then((response) => {
      if (!response.error) {
        this._postThoughtWasSuccess();

        // This is used to kick off ajax calls and will create a speedier feel
        this._thoughtPreSuccessDelayAdditionalAction();

        this._delayActionForAnimation(() => {
          this._onThoughtPostComplete();
        }, this._returnThoughtPostedAnimationMinLength());
      } else {
        // Error message is usually handled by the parent displaying this component
        this._postThoughtWasFailure();
      }
    });
  };

  _thoughtPreSuccessDelayAdditionalAction = () => {
    if (this.props.thoughtPreSuccessDelayAdditionalAction) {
      this.props.thoughtPreSuccessDelayAdditionalAction();
    }
  };

  _onThoughtPostComplete = () => {
    this._clearThought();
    this._handleFinishCreatingThought();
    this._handleThoughtPostSuccessAdditionalAction();
  };

  _handleFinishCreatingThought = () => {
    if (this.props.handleFinishCreatingThought) {
      this.props.handleFinishCreatingThought();
    } else {
      return true;
    }
  };

  createErrorsForInvalidThought = () => {
    const formData = this.state.formData;
    let error = null;

    if (this._isThoughtTooLong()) {
      error = 'Thought is too long';
      this.setState((prevState) => ({
        customError: error,
      }));
      return false;
    }

    if (!(formData.thought && formData.thought.length > 0)) {
      error = 'Please enter a thought';
      this.setState((prevState) => ({
        customError: error,
      }));
      return false;
    }

    if (!formData.thought_type) {
      error = 'Please select Pro or Con';
      this.setState((prevState) => ({
        customError: error,
      }));
      return false;
    }

    this.setState((prevState) => ({
      customError: error,
    }));
  };

  handleThoughtCreateCancel = () => {
    if (this.props.handleCancelCreatingThought) {
      this.props.handleCancelCreatingThought();
    }
  };

  cancelCreatingThought = () => {
    this._logCancelCreatingThought();
    this.props.handleCancelCreatingThought();
  };

  _createErrorPostingThoughtModal = (response) => {
    const generalComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {'Could not save thought. Please try again later.'}
      </div>
    );
    const unfeaturedMsgComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {'Cannot create a new thought for this security.  Security is currently unavailable.'}
      </div>
    );
    const modal = {
      contentComponent:
        response.thoughtsWithErrors &&
        response.thoughtsWithErrors[0].error === "Can't create an thought for an un-featured security."
          ? unfeaturedMsgComponent
          : generalComponent,
      dismissable: true,
    };
    this.props.actions.showModal(modal);
  };

  handleRemovePreviewImage = () => {
    if (this._returnOgo()) {
      this.setState((prevState) => ({
        openGraphObject: {
          ...prevState.openGraphObject,
          images_url: null,
          image_link: null,
        },
      }));
    }
    this._logRemoveImageClick();
  };

  _renderCreateThoughtHeadingMessage = () => {
    const style = this._generateThoughtHeadingStyle();
    const message = this._generateCreateThoughtHeadingMessage();
    return (
      <div style={style}>
        {this.props.customThoughtHeadingComponent || (
          <React.Fragment>
            <span className=" " style={{ display: 'inline' }}>
              {message}
            </span>
            <div style={{ display: 'inline-block', position: 'relative' }}>
              <InfoIcon word={'thought_explanation'} style={{ top: '-13px' }} position={'right'} />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  _generateThoughtHeadingStyle = () => {
    if (this.state.width <= 400 && this.state.width >= 350) {
      return { fontSize: '16px' };
    } else if (this.state.width < 300) {
      return { fontSize: '12px' };
    } else if (this.state.width < 350) {
      return { fontSize: '14px' };
    } else {
      return { fontSize: '18px' };
    }
  };

  _generateCreateThoughtHeadingMessage = () => {
    const message = this.props.isAReply
      ? `Enter a Pro or Con for ${this._returnSecurity().symbol} to help your connections understand this idea.`
      : `Enter a Pro or Con for ${this._returnSecurity().symbol} and see what the community thinks.`;
    return message;
  };

  _returnCurrentThoughtCharLength = () => {
    const thoughtText = this.state.formData.thought || '';
    const fullLength = thoughtText.length;
    const extraLength = this._charactersToSubtractFromUrls(thoughtText);
    const length = fullLength - extraLength;

    return length;
  };

  _charactersToSubtractFromUrls = (thoughtText) => {
    const urlMaxLength = 23;
    let extraLength = 0;
    const urls = getUrlsFromString(thoughtText);
    if (urls && urls.constructor === Array) {
      for (var i = 0; i < urls.length; i++) {
        if (urls[i].length > urlMaxLength) {
          extraLength += urls[i].length - urlMaxLength;
        }
      }
    }
    return extraLength;
  };

  _updateThoughtTypeFromProps = () => {
    const thoughtType = this.props.thoughtType
      ? this.props.thoughtType.toLowerCase() === 'pro' || this.props.thoughtType.toLowerCase() === 'pros'
        ? { id: 0, name: 'Pro' }
        : { id: 1, name: 'Con' }
      : null;

    this.setState((prevState) => ({
      formData: {
        ...this.state.formData,
        thought_type: thoughtType,
      },
    }));
  };

  _saveOgoToState = () => {
    const ogo = this.props.ogo;
    if (ogo) {
      this.setState((prevState) => ({
        openGraphObject: ogo,
        formData: {
          ...prevState.formData,
          thought: `${ogo.url} `,
        },
      }));
    }
  };

  _returnContext = () => {
    return this.props.viewingContext
      ? {
          Context: this.props.viewingContext,
        }
      : {};
  };

  _onLoad = () => {
    this.handleResize();
  };

  handleResize = () => {
    this.setState({
      width:
        ('addThoughtFormContainer' in this &&
          this.addThoughtFormContainer &&
          this.addThoughtFormContainer.offsetWidth) ||
        450,
    });
  };

  _logPostedThought = (thought) => {
    const security = this._returnSecurity();
    const properties = {
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
      'Thought Type': thought.thought_type.name,
      'From Disagree': this.props.logFromDisagree,
      'In Response Thought ID': this.props.logFromDisagreeThoughtId,
      ...this._returnContext(),
    };
    TrackingEvents.thoughts.ADD_THOUGHT.send(properties);
  };

  _logCancelCreatingThought = () => {
    const event = 'Cancelled Creating Thought';
    const properties = {
      'Is From News': this.props.isFromNews,
      ...this._returnContext(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logRemoveImageClick = () => {
    const event = 'Clicked Remove Thought Image';
    const properties = {
      'Is From News': this.props.isFromNews,
      ...this._returnContext(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateThoughtContainer);
