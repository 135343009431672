import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom';
import { logMetricsTrackingEvent } from '../../../actions';

const HeaderWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
`;
export const HeaderTabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  margin: 0 auto;

  width: ${({ width }) => width || '100%'};
  max-width: ${({ maxWidth }) => maxWidth || '800px'};
`;
export const ComponentWrapper = styled.div`
  padding: 24px 16px 48px 16px;
  margin: 0 auto;
  width: ${({ width }) => width || '100%'};
  max-width: ${({ maxWidth }) => maxWidth || '800px'};
`;
const TabWrapper = styled.div`
  padding: 8px 16px;
  cursor: pointer;

  &.active {
    color: ${({ theme }) => theme.themeColors.primaryCtaButton};
    border-bottom: 2px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
  }

  &:hover {
    color: ${({ theme }) => theme.themeColors.primaryCtaButton};
  }
`;

const Tab = ({ name, value, isActive }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClick = () => {
    const params = {
      tab: value,
    };
    const options = {
      pathname: location.pathname,
      search: `?${createSearchParams(params)}`,
    };
    navigate(options, { replace: true });

    const event = 'Clicked Tab';
    const properties = {
      Tab: value,
    };
    logMetricsTrackingEvent(event, properties)();
  };
  return (
    <TabWrapper onClick={onClick} className={isActive ? 'active' : ''}>
      {name}
    </TabWrapper>
  );
};

const getTabFromSearchParams = (searchParams) => searchParams.get('tab');

// EXAMPLE:
// const TAB_NAMES = {
//   editProfile: 'profile',
//   account: 'account',
//   preferences: 'preferences',
// };
//
// const componentLookup = {
//   [TAB_NAMES.editProfile]: {
//     name: 'profile',
//     displayText: 'Profile',
//
//     renderer: () => <EditProfile />,
//   },
//   [TAB_NAMES.account]: {
//     name: 'account',
//     displayText: 'Account',
//
//     renderer: () => <EditAccount />,
//   },
//   [TAB_NAMES.preferences]: {
//     name: 'preferences',
//     displayText: 'Preferences',
//
//     renderer: () => <EditPreferences />,
//   },
// };
// const tabs = [TAB_NAMES.editProfile, TAB_NAMES.account, TAB_NAMES.preferences];

export const TabbedScreen = ({ defaultTab, tabs, componentLookup, rendererProps = {} }) => {
  const [searchParams] = useSearchParams();

  const [activeSection, setActiveSection] = React.useState(getTabFromSearchParams(searchParams) || defaultTab);

  React.useEffect(
    () => {
      const tab = getTabFromSearchParams(searchParams);
      if (tab !== activeSection && tab) {
        setActiveSection(tab);
      }
    },
    [searchParams, activeSection]
  );
  return (
    <div>
      <HeaderWrapper>
        <HeaderTabsWrapper>
          {tabs.map((t) => (
            <Tab key={t} isActive={activeSection === t} name={componentLookup[t].displayText} value={t} />
          ))}
        </HeaderTabsWrapper>
      </HeaderWrapper>

      <ComponentWrapper>{componentLookup[activeSection]?.renderer(rendererProps)}</ComponentWrapper>
    </div>
  );
};

// <TabbedScreen defaultTab={TAB_NAMES.editProfile} componentLookup={componentLookup} tabs={tabs} />
