import styled from 'styled-components';
import { usePortfolioPendingCash } from '@src/main/hooks/portfolio/useEquityData';
import { Body5 } from '@src/main/lib/nvstr-common-ui.es';
import { DollarAmount } from '@src/main/components/molecules/DollarAmount';
import InfoIcon, { InfoIconWrapper } from '@src/containers/UI/InfoIcon';
import { useCurrentUser, useIsLiveTrading } from '@src/main/hooks/user';
import { pluralize } from '@src/helpers/usefulFuncs';
import { Container } from '@src/main/components/ui';
import { BuyingPower } from '@src/main/containers/Orders/BuyingPower';
import React from 'react';

const PendingEquityWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const PendingEquity = () => {
  const { pendingEquityValue, pendingEquityReasons } = usePortfolioPendingCash();
  if (pendingEquityValue > 0) {
    return (
      <PendingEquityWrapper>
        <InfoIconWrapper>
          <Body5>Pending Equity: </Body5>
          <DollarAmount value={pendingEquityValue} />
          <InfoIcon
            customMessage={pendingEquityReasons}
            style={{ top: '2px', right: '-18px' }}
            trackingMessage={'Pending Equity Reasons'}
          />
        </InfoIconWrapper>
      </PendingEquityWrapper>
    );
  }

  return null;
};

const FreeTradesCountMessage = () => {
  const currentUser = useCurrentUser();
  const isUpdating = currentUser?.loading;

  const isMembershipActive = currentUser.is_membership_active;
  const freeTradeCount = currentUser.membership_free_trade_count;
  if (isMembershipActive) {
    const message = `You have ${freeTradeCount} commission-free ${pluralize(freeTradeCount, 'trade')}.`;
    return (
      <Container top={8} bottom={0}>
        {isMembershipActive && (
          <Container bottom={2}>
            <Body5 bold>Membership Active{' - '}</Body5>
            <Body5>{isUpdating ? 'Updating free trade count' : message}</Body5>
          </Container>
        )}
      </Container>
    );
  }
  if (isMembershipActive) {
    const message = `You have ${freeTradeCount} commission-free ${pluralize(freeTradeCount, 'trade')}.`;
    return (
      <Container top={8} bottom={16}>
        {isMembershipActive && (
          <Container bottom={2}>
            <Body5 bold>Membership Active</Body5>
          </Container>
        )}
        <Container>
          <Body5>{isUpdating ? 'Updating free trade count' : message}</Body5>
        </Container>
      </Container>
    );
  }
  return null;
};

const RequireTradeApprovalNotice = () => {
  const isLiveTrading = useIsLiveTrading();
  const requires = useCurrentUser().requires_trade_approval;
  const show = requires && isLiveTrading;

  if (!show) {
    return null;
  }

  return (
    <Container top={4}>
      <span className="info-icon-text-wrapper">
        <Body5>Note that your account is configured to require pre-clearance approval for all orders.</Body5>
        <InfoIcon word={'tooltip_requires_trade_approval'} style={{ top: 'auto', bottom: '1px' }} />
      </span>
    </Container>
  );
};

export const UserTradingAccountInfos = () => {
  return (
    <>
      <BuyingPower />
      <PendingEquity />
      <FreeTradesCountMessage />
      <RequireTradeApprovalNotice />
    </>
  );
};
