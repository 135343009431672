import React from 'react';
import Button from '../../../components/buttons/Button';
import { FlatButton } from '../../../main/components/buttons';

const OrderErrors = (props) => {
  const { errors, closeBtnText, onCloseClick } = props;

  return (
    <div className="modal-message" style={{ paddingTop: '0px' }}>
      {errors.map((errorMessage, i) => (
        <p key={i} style={{ fontSize: '16px' }}>
          {errorMessage}
        </p>
      ))}
      <div style={{ marginTop: '15px' }}>
        <FlatButton transparent onClick={onCloseClick}>
          {closeBtnText || 'Close'}
        </FlatButton>
      </div>
    </div>
  );
};

export default OrderErrors;
