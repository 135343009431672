import React from 'react';
import styled from 'styled-components';
import LoadingSpinner from '../../../../main/components/Images/LoadingSpinner';

const Wrapper = styled.div`
  > div:first-child {
    height: auto;
    width: auto;

    margin-right: 16px;
    margin-left: 0px;
  }
`;

const PlacingOrdersText = styled.span`
  display: block;

  font-size: 24px;
  line-height: 28px;
`;

const PlacingOrders = (props) => {
  return (
    <Wrapper className="placing-orders-loading-message-container flex-center-child">
      <LoadingSpinner />
      <PlacingOrdersText>{'Placing Orders'}</PlacingOrdersText>
    </Wrapper>
  );
};

export default PlacingOrders;
