import { portfolioExplorerAdapter } from '../adapters/portfolioExplorerAdapter';
import { usersAdapter } from '../adapters/usersAdapter';
import { filter } from '../helpers/filterHelpers';

import {
  ADD_CONNECTIONS,
  ADD_THOUGHT_LEADERS,
  FILTER_SECURITIES,
  START_FILTERING_LOADING,
  END_FILTERING_LOADING,
  GET_FILTER_SELECTIONS,
  ADD_SECURITY_TO_PORTFOLIO_EXPLORER,
} from '../constants';

export const getFilterSelectionOptions = () => {
  return function (dispatch) {
    const requests = [];
    requests.push(
      usersAdapter.getCurrentUserConnections().then((response) => {
        const wasSuccess = response && response.data;
        if (wasSuccess) {
          const connections = response.data.connections;
          const filteredForPrivateAndWaitlistedUsers = connections.filter(
            (user) => !(user.is_private || user.is_on_waitlist)
          );
          return { connections: filteredForPrivateAndWaitlistedUsers };
        } else {
          return { connections: [] };
        }
      })
    );
    requests.push(usersAdapter.fetchThoughtLeaders());
    requests.push(portfolioExplorerAdapter.getSectors());
    return Promise.all(requests).then((responses) => {
      const payload = {};
      responses.forEach((r) => {
        if (!r) {
          return null;
        } else {
          const keys = Object.keys(r); // response only has one key
          return (payload[keys[0]] = r[keys]);
        }
      });
      if ('connections' in payload) {
        dispatch({
          type: ADD_CONNECTIONS,
          payload: payload.connections,
        });
      }
      if ('thought_leaders' in payload) {
        dispatch({
          type: ADD_THOUGHT_LEADERS,
          payload: payload.thought_leaders,
        });
      }
      dispatch({
        type: GET_FILTER_SELECTIONS,
        payload,
      });

      // returned so hard coded preset filters can get the info to populate fields
      return payload;
    });
  };
};

export const addSecurityToPortfolioExplorer = (securityId) => {
  return function (dispatch) {
    dispatch({
      type: ADD_SECURITY_TO_PORTFOLIO_EXPLORER,
      payload: securityId,
    });
  };
};

export const filterSecurities = (count, filters, counter) => {
  return function (dispatch) {
    dispatch({
      type: START_FILTERING_LOADING,
    });
    return portfolioExplorerAdapter.filterSecurities(count, filters).then((response) => {
      const securityIdList = response.map((s) => s.security_id);
      dispatch({
        type: FILTER_SECURITIES,
        payload: {
          securityIds: securityIdList,
          counter,
        },
      });
      dispatch({
        type: END_FILTERING_LOADING,
      });
      return securityIdList;
    });
  };
};
