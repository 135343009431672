import { createQueryString, parseQueryString } from './routerHelpers';
import { logMetricsTrackingEvent } from '../actions/trackingActions';

const defaultArgsOpenSecurityPanel = {
  securityId: undefined,
  additionalProps: {},
  trackingData: undefined,
  navigate: undefined,
  location: undefined,
};

export const openSecurityPanel = (
  { securityId, panel, additionalProps, trackingData, navigate } = {
    ...defaultArgsOpenSecurityPanel,
  }
) => {
  if (!navigate) {
    console.error('Must supply router navigate to open panel');
  }

  const securityCard = {
    panel: panel || 'security',
    securityId: securityId,
    ...additionalProps,
  };

  if (trackingData) {
    const event = 'Open Security Panel';
    const properties = {
      ...trackingData.properties,
    };
    logMetricsTrackingEvent(event, properties)();
  }

  const queryString = createQueryString(securityCard);
  navigate(location.pathname + queryString);
};

export const returnSecurityPanelUrl = (
  { securityId, panel, additionalProps, location, navigate } = {
    ...defaultArgsOpenSecurityPanel,
  }
) => {
  if (!location) {
    console.error('Must supply router navigate to open panel');
  }
  const securityCard = {
    panel: panel || 'security',
    securityId: securityId,
    ...additionalProps,
  };
  const keysToLeaveInQuery = ['tab'];
  const currentQueryObj = parseQueryString(location.search);
  const currentQueryObjKeys = Object.keys(currentQueryObj);
  const queryKeysToKeep = currentQueryObjKeys.filter((key) => keysToLeaveInQuery.includes(key));
  const dataFromCurrentQueryToLeave = {};
  queryKeysToKeep.forEach((key) => {
    dataFromCurrentQueryToLeave[key] = currentQueryObj[key];
  });
  const currentPath = location.pathname;
  const newQuery = {
    ...dataFromCurrentQueryToLeave,
    ...securityCard,
  };
  const queryString = createQueryString(newQuery);
  return currentPath + queryString;
};
