import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import PendingConnectionRequestList from './PendingConnectionRequestList';
import Page from '../../components/layout/Page';

import LoadingIcon from '../../components/misc/LoadingIcon';
import PageLoading from '../../components/PageLoading';
import { PageHeading } from '../../components/UI/PageHeading';

import {
  isRequestingConnection,
  hasRequestedConnection,
  isActiveConnectionRequestSent,
  sortUserListAlphabetically,
} from '../../helpers/usersHelpers';
import { TrackIteratively } from '../../utils/itly';
import { enums } from '@src/main/lib/nvstr-utils.es';
import { ElementPositionContext } from '../../context';

class PendingConnectionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _isUpdating: false,
      activeTab: props.activeTab || this._returnDefaultActiveTab(),
    };
  }

  componentDidMount() {
    this._fetchConnectionRequestData();
  }

  componentDidUpdate(prevProps, prevState) {
    const connectionRequestDataReceived = prevState._isUpdating && !this.state._isUpdating;
    if (connectionRequestDataReceived) {
      this._fetchMutualConnections();
    }

    if (this._didCountOfTabsWithContentChange(prevProps)) {
      this._onCountOfTabsWithContentChange();
    }
  }

  render() {
    if (this._isLoading()) {
      return this._renderLoading();
    }

    if (!this._areConnectionRequests()) {
      return this._renderPlaceHolder();
    }

    return (
      <Page className="pending-connection-requests-page-container">
        <div className={`pending-connection-requests-container`}>
          {this._renderHeader()}
          {this._renderTabs()}
          {this._renderTabContent()}
        </div>
      </Page>
    );
  }

  _renderLoading = () => this._renderPlaceHolder();

  _isUpdating = () => this.state._isUpdating;
  _renderIsUpdating = () => {
    return (
      <span className={`updating-message`}>
        <LoadingIcon icon={'circle-loading-spinner'} size="small" style={{ marginRight: '8px' }} />
        <span className={`text`}>Updating</span>
      </span>
    );
  };

  _renderPlaceHolder = () => {
    return <div style={{ padding: '7.5px' }}></div>;
  };

  _renderHeader = () => {
    return (
      <PageHeading className="connection-requests-heading">
        Connection Requests
        {this._isUpdating() && this._renderIsUpdating()}
      </PageHeading>
    );
  };

  _renderTabs = () => {
    return (
      <div className={`connection-requests-tabs-container`}>
        {this._returnTabsWithContent().map((tab) => this._renderTab(tab))}
      </div>
    );
  };

  _renderTab = (tab) => {
    return (
      <div
        key={`connection-requests-tab-${tab.name}`}
        className={`panel-selection-tab flat-tab-color-style connection-requests-tab ${
          this._isTabActive(tab) ? 'active' : ''
        }`}
        onClick={this._bindTabToClickHandler(tab)}
      >
        <span className={``}>{tab.displayName}</span>
      </div>
    );
  };

  _renderTabCount = (tab) => {
    const count =
      tab.name === 'received'
        ? this._returnPendingReceivedConnectionRequestsCount()
        : this._returnPendingSentConnectionRequestsCount();
    return <span className={`tab-notif-count`}>{count}</span>;
  };

  _tabContentRenderer = () => {
    const renders = {
      received: this._renderPendingReceivedConnectionRequests,
      sent: this._renderPendingSentConnectionRequests,
    };
    return renders[this._returnActiveTabName()]();
  };

  _renderTabContent = () => {
    return <div className={`pending-connections-tab-content`}>{this._tabContentRenderer()}</div>;
  };

  _renderPendingReceivedConnectionRequests = () => {
    return (
      <PendingConnectionRequestList
        userIds={this._returnPendingReceivedConnectionRequestsUserIds()}
        requestType={'received'}
        maxResultSize={this._returnMaxResultsForPendingConnections()}
      />
    );
  };

  _renderPendingSentConnectionRequests = () => {
    return (
      <PendingConnectionRequestList
        userIds={this._returnSortedPendingSentConnectionRequestsUserIds()}
        requestType={'sent'}
        maxResultSize={this._returnMaxResultsForPendingConnections()}
      />
    );
  };

  _isLoading = () => this._returnUsersStore().loadingPendingConnections;

  _returnMaxResultsForPendingConnections = () => 3;

  _areConnectionRequests = (props) =>
    this._arePendingSentConnectionRequests(props) || this._arePendingReceivedConnectionRequests(props);

  _arePendingSentConnectionRequests = (props) => this._returnPendingSentConnectionRequestsCount(props) > 0;
  _arePendingReceivedConnectionRequests = (props) => this._returnPendingReceivedConnectionRequestsCount(props) > 0;

  _returnUsersStore = (props) => (props || this.props).users;
  _returnUserList = (props) => this._returnUsersStore(props).userList;

  _returnConnectionRequestData = (props) =>
    Object.keys(this._returnUserList(props)).map((userId) => this._returnUserList(props)[userId]);

  _sortPendingSentConnectionRequests = () => {
    const connectionRequests = this._returnPendingSentConnectionRequests();
    const activeConnectionRequests = connectionRequests.filter((user) => isActiveConnectionRequestSent(user));
    const sortedActiveConnectionRequests = sortUserListAlphabetically(activeConnectionRequests);

    const expiredConnectionRequests = connectionRequests.filter((user) => !isActiveConnectionRequestSent(user));
    const sortedExpiredConnectionRequests = sortUserListAlphabetically(expiredConnectionRequests);

    return [...sortedActiveConnectionRequests, ...sortedExpiredConnectionRequests];
  };
  _returnSortedPendingSentConnectionRequestsUserIds = () =>
    this._sortPendingSentConnectionRequests().map((user) => user.user_id);

  _returnPendingSentConnectionRequests = (props) =>
    this._returnConnectionRequestData(props).filter((user) => isRequestingConnection(user));
  _returnPendingSentConnectionRequestsCount = (props) => this._returnPendingSentConnectionRequests(props).length;
  _returnPendingSentConnectionRequestsUserIds = () =>
    this._returnPendingSentConnectionRequests().map((user) => user.user_id);

  _returnPendingReceivedConnectionRequests = (props) =>
    this._returnConnectionRequestData(props).filter((user) => hasRequestedConnection(user));
  _returnPendingReceivedConnectionRequestsCount = (props) =>
    this._returnPendingReceivedConnectionRequests(props).length;
  _returnPendingReceivedConnectionRequestsUserIds = () =>
    this._returnPendingReceivedConnectionRequests().map((user) => user.user_id);

  _returnCountOfPendingReceivedConnectionRequests = () => this._returnPendingReceivedConnectionRequests().length;
  _returnDefaultActiveTab = () => {
    if (this._arePendingReceivedConnectionRequests() || !this._areConnectionRequests()) {
      return 'received';
    } else {
      return 'sent';
    }
  };
  _returnActiveTabName = () => this.state.activeTab;
  _setActiveTab = (tab) => {
    this.setState(() => ({
      activeTab: tab.name,
    }));
  };
  _isTabActive = (tab) => this._returnActiveTabName() === tab.name;
  _returnTabs = () => [
    {
      name: 'received',
      displayName: 'Received',
      hasContent: this._arePendingReceivedConnectionRequests,
    },
    {
      name: 'sent',
      displayName: 'Sent',
      hasContent: this._arePendingSentConnectionRequests,
    },
  ];
  _returnTabTrackingProperty = (tab) => {
    const dict = {
      received: 'Received',
      sent: 'Sent',
    };
    return dict[tab.name];
  };

  _returnTabsWithContent = (props) => this._returnTabs().filter((tab) => tab.hasContent(props));

  _setIsUpdating = () => {
    this.setState(() => ({
      _isUpdating: true,
    }));
  };
  _unsetIsUpdating = () => {
    this.setState(() => ({
      _isUpdating: false,
    }));
  };

  _fetchConnectionRequestData = () => {
    this._setIsUpdating();
    return this.props.actions.getPendingConnectionRequestUsers().then((response) => {
      this._unsetIsUpdating();
    });
  };

  _fetchMutualConnections = () => {
    const userIds = this._returnPendingReceivedConnectionRequestsUserIds();
    return this.props.actions.fetchMutualConnections(userIds);
  };

  _bindTabToClickHandler = (tab) => () => this._handleTabClick(tab);

  _handleTabClick = (tab) => {
    this._setActiveTab(tab);
    this._logTabClick(tab);
    this._logItlyTabClick(tab);
  };

  _didCountOfTabsWithContentChange = (prevProps) =>
    this._returnTabsWithContent(prevProps).length !== this._returnTabsWithContent().length;

  _onCountOfTabsWithContentChange = () => {
    // set the only tab with content to be the active tab
    const listOfTabsWithContent = this._returnTabsWithContent() || [];
    if (listOfTabsWithContent.length === 1) {
      const onlyTabWithContent = listOfTabsWithContent[0];
      this._setActiveTab(onlyTabWithContent);
    }
  };

  _logTabClick = (tab) => {
    const event = 'Clicked Pending Connections Tab';
    const properties = {
      Tab: this._returnTabTrackingProperty(tab),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logItlyTabClick = (tab) => {
    const { elementPosition } = this.context;
    const properties = {
      context: location.pathname,
      position: elementPosition || enums.node_location.body,
      platform: enums.platform.web,
      description: this._returnTabTrackingProperty(tab),
      url: window.location.pathname,
      url_query: window.location.search,
    };

    TrackIteratively.generic.TAB_VIEWED.send(properties);
  };
}

PendingConnectionsContainer.contextType = ElementPositionContext;

const mapStateToProps = (state) => {
  return {
    users: state.users,
    currentUser: state.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingConnectionsContainer);
