import React from 'react';

import Icon from '../misc/Icon';
import { useButtonEvent } from '../../utils/itly/hooks/useButtonEvent';
import { enums } from '@src/main/lib/nvstr-utils.es';

const IconButton = (props) => {
  const onClick = useButtonEvent(props);
  const btnClass = props.customClass || 'rounded-edge-button' || 'circle-button';
  const icon = props.icon || 'fa-question-circle-o';
  const skinny = props.skinny ? 'icon-skinny' : '';
  const style = props.style || { cursor: 'pointer' };
  const size = props.size || 'large';
  const color = props.color;
  const colorClassName = props.colorClassName;
  const background = props.background || '#fff';
  const hideShadow = props.ignoreShadow
    ? ''
    : (!background || background === '#fff' || background === 'rgba(0,0,0,0)') && !props.showShadow
    ? 'no-shadow'
    : '';

  const customStyle = skinny
    ? {
        cursor: 'pointer',
        ...style,
        WebkitTextStroke: `2px ${props.strokeColor || background}`,
      }
    : {
        cursor: 'pointer',
        ...style,
      };

  if (props.background) {
    customStyle.background = background;
  }

  if (props.color) {
    customStyle.color = color;
  }

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    // props.handleClick(e);
    onClick();
  };

  return (
    <div className={`${btnClass} ${hideShadow} ${skinny}`} style={customStyle} onClick={handleClick}>
      <Icon
        icon={icon}
        color={color}
        colorClassName={colorClassName}
        size={size}
        style={props.handleClick ? { pointerEvents: 'none' } : {}}
      />
    </div>
  );
};

IconButton.defaultProps = {
  buttonType: enums.button_types.button,
};

export default IconButton;
