import React from 'react';

import GroupedByDateTransactions from './GroupedByDateTransactions';
import LoadingIcon from '../../../components/misc/LoadingIcon';

const GroupedTransactionsList = (props) => {
  const { isLoading, isUpdating, isError, groupedTransactionsByDate, actions } = props;

  const shouldShowYearSeparator = (i) => {
    if (i === 0 || !i) {
      return false;
    }
    const currentTransaction = groupedTransactionsByDate[i];
    const currentTransactionYear = currentTransaction.date.split('-')[0];

    const prevTransaction = groupedTransactionsByDate[i - 1];
    const prevTransactionYear = prevTransaction.date.split('-')[0];

    return prevTransactionYear !== currentTransactionYear ? prevTransactionYear : false;
  };

  return (
    <div className={`grouped-transactions-list`}>
      {isLoading ? (
        <div style={{ textAlign: 'center' }}>
          <LoadingIcon
            icon="fading-3balls"
            size="small"
            style={{
              marginLeft: '3px',
              marginBottom: '35px',
            }}
          />
        </div>
      ) : (
        <div>
          {isUpdating && (
            <div style={{ textAlign: 'center', marginBottom: '5px' }}>
              <LoadingIcon
                icon="fading-3balls"
                size="small"
                style={{
                  marginLeft: '3px',
                  marginBottom: '0px',
                }}
              />
              <div
                className="secondary-text-color"
                style={{
                  display: 'inline-block',
                  marginLeft: '18px',
                }}
              >
                Updating
              </div>
            </div>
          )}
          {isError && (
            <div style={{ textAlign: 'center', margin: '16px 0' }}>Something went wrong, please try again later.</div>
          )}
          {groupedTransactionsByDate?.map((groupedTransactionsData, i) => (
            <GroupedByDateTransactions
              key={i}
              className="grouped-transactions"
              {...groupedTransactionsData}
              actions={actions}
              yearSeparator={shouldShowYearSeparator(i)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default GroupedTransactionsList;
