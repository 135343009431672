import React from 'react';

import DiscoverCardLogo from '../../assets/SVGS/DiscoverCardLogo';
import MasterCardLogo from '../../assets/SVGS/MasterCardLogo';
import VisaLogo from '../../assets/SVGS/VisaLogo';
import AMEXLogo from '../../assets/SVGS/AMEXLogo';

export default (props) => {
  return (
    <div className="ccs-accepted-list">
      <VisaLogo />
      <MasterCardLogo />
      <AMEXLogo />
      <DiscoverCardLogo />
    </div>
  );
};
