import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import SecurityChart from './SecurityChart';
import LoadingIcon from '../misc/LoadingIcon';
import Checkbox from '../../containers/StandaloneInputs/Checkbox';

import { createBasicErrorModal } from '../../constants/modals';

import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import { customParseFloat } from '../../helpers/numberHelpers';
import { defaultCompareSpyPriceHistory } from '../../helpers/currentUserHelpers';
import { moment, formatLocalizedDateTime } from '../../helpers/timeHelpers';
import {
  addBlanksForIntradayMissingIntervals,
  addBlanksForTwoDayMissingIntervals,
  isIntradayTimePeriod,
  isIntraOrTwoDayTimePeriod,
} from '../../helpers/chartHelpers';
import styled from 'styled-components';

// required props
// chartContext = Parent element ( used for Amplitude event )

// optional props
// defaultTimePeriod: default starting time period
// chartStyle = minimal/ default

// params = { time_period: time_period_string }
// :one_day
// :one_year
// five_years

// if no data, make sure no infinite loading and displays no chart data message
// select chart style in here -> mini is portfolio card or new dash version
// pass other config

// TODO: height 195 if no ideas and display no price history message

/*
  1d - dot per 15 min
  2d - dot per 15 min
  1m - dot per day
  1yr - 1 dot a week
  5yr - 1 dot a month
  max
 */

const ChartTabWrapper = styled.div`
  &:hover .time-period-label {
    color: ${({ theme }) => theme.themeColors.primaryCtaButton};
  }

  &.active .time-period-label {
    color: ${({ theme }) => theme.themeColors.primaryCtaButton};
  }
  &.active .time-period-underline {
    background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
  }
`;

const TIME_PERIODS = [
  {
    name: 'one_day',
    displayLabel: '1D',
    fullName: '1 Day',
  },
  {
    name: 'two_days',
    displayLabel: '2D',
    fullName: '2 Days',
  },
  {
    name: 'one_month',
    displayLabel: '1M',
    fullName: '1 Month',
  },
  {
    name: 'one_year',
    displayLabel: '1Y',
    fullName: '1 Year',
  },
  {
    name: 'five_years',
    displayLabel: '5Y',
    fullName: '5 Years',
  },
  {
    name: 'max',
    displayLabel: 'Max',
    fullName: 'Max',
  },
];

const TimePeriods = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const SecurityChartWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const TimePeriodSelection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
`;

class SecurityChartContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timePeriod: props.defaultTimePeriod || 'one_year',
      shouldOverlaySpy: defaultCompareSpyPriceHistory(props.currentUser),

      spySecurityId: null,
    };
  }

  componentDidMount() {
    // 1 yr data call made in security card container
    if (!this.props.lockTimePeriod) {
      // timeout to delay these api calls so other more important calls will execute first
      this.props.actions.fetchSecuritiesPriceHistoryData([this.props.securityId], 'one_year');
      setTimeout(() => {
        this.props.actions.fetchSecuritiesPriceHistoryData([this.props.securityId], 'one_day');
        this.props.actions.fetchSecuritiesPriceHistoryData([this.props.securityId], 'two_days');
        this.props.actions.fetchSecuritiesPriceHistoryData([this.props.securityId], 'five_years');
        this.props.actions.fetchSecuritiesPriceHistoryData([this.props.securityId], 'max');
      }, 1000);
    } else {
      if (this.props.defaultTimePeriod !== 'one_year') {
        this.props.actions.fetchSecuritiesPriceHistoryData([this.props.securityId], this.props.defaultTimePeriod);
      }
    }

    if (this.isUsingCompareSpyOption()) {
      this._getSPYSecurityId();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.spySecurityId && !!this.state.spySecurityId) {
      this.onGetSPYSecurityId();
    }
  }

  render() {
    const { lockTimePeriod } = this.props;

    if (this.isLoading()) {
      return this.renderLoadingComponent();
    }
    if (this.isNoChartData()) {
      return <div className={'fin-chart'} />;
    }

    return (
      <SecurityChartWrapper className={'security-chart-container'}>
        {!lockTimePeriod && (
          <TimePeriodSelection className={'time-period-selection-container'}>
            <TimePeriods>
              {TIME_PERIODS.map((t) => this.renderTimePeriodButton(t))}
              {this.isUsingCompareSpyOption() && !this.isAlreadyShowingSpy() && (
                <div className="chart-option-selection-container">
                  <Checkbox
                    name={'compare_to_spy'}
                    label={'Compare SPY'}
                    value={this.state.shouldOverlaySpy}
                    handleClick={this._handleOverlaySpyCheckboxClick}
                  />
                </div>
              )}
            </TimePeriods>
          </TimePeriodSelection>
        )}
        <SecurityChart
          securityId={this.props.securityId}
          symbol={this.props.symbol}
          config={this.returnChartConfig()}
          color={this.determineChartColor()}
          chartData={this.generateChartData()}
          userIsAuthenticated={this.props.userIsAuthenticated}
        />
      </SecurityChartWrapper>
    );
  }

  renderLoadingComponent = () => {
    const loadingDivStyle = {
      textAlign: 'center',
      marginTop: '15px',
      marginLeft: '0',
      height: `${this.props.userIsAuthenticated ? 150 : 200}px`,
      width: '100%',
      paddingTop: '50px',
    };
    return (
      <div className={'loading-price-history-container'} style={loadingDivStyle}>
        Loading Price History
        <br />
        <LoadingIcon icon="fading-3balls" size="small" style={{}} />
      </div>
    );
  };

  renderTimePeriodButton = (timePeriod) => {
    const displayLabel = timePeriod.displayLabel;
    const timePeriodName = timePeriod.name;
    const handleClick = () => {
      this.handleTimePeriodChange(timePeriodName);
    };
    const isActive = timePeriod.name === this.state.timePeriod;
    return (
      <ChartTabWrapper
        className={`time-period-button ${isActive ? 'active ' : ''}`}
        onClick={handleClick}
        key={`${timePeriodName}-chart-button`}
      >
        <div className={'time-period-label'}>{displayLabel}</div>
        <div className={`time-period-underline ${isActive ? 'active' : ''}`} />
      </ChartTabWrapper>
    );
  };

  _handleOverlaySpyCheckboxClick = () => {
    const cacheValue = this.state.shouldOverlaySpy;
    const newValue = !cacheValue;
    this.logOverlaySpyCheckboxClick(newValue);
    this._persistCompareSpyValue(newValue);
    this.setState((prevState) => ({ shouldOverlaySpy: newValue }));
  };

  _persistCompareSpyValue = (value) => {
    return this.props.actions.updateUserProperty({
      show_compare_spy_price_history: value,
    });
  };

  isAlreadyShowingSpy = () => this.props.securityId === this.state.spySecurityId;

  isShowingSpyOverlay = () => this.state.shouldOverlaySpy && !this.isAlreadyShowingSpy();

  isSpyOverlayDataLoading = () => this.isShowingSpyOverlay() && !this.returnAdditionalSecurityPriceTimePeriodData();

  isChartDataLoading = () => !this.returnSecurityPriceTimePeriodData();

  isUsingCompareSpyOption = () => this.props.compareToSpyOption && this.state.timePeriod !== 'max'; // bug comparing stocks that have longer time periods than SPY

  isLoading = () => this.isChartDataLoading() || (this.isUsingCompareSpyOption() && this.isSpyOverlayDataLoading());

  returnChartConfig = () =>
    this.props.chartStyle === 'minimal' ? this.returnMinimalConfig() : this.returnDefaultConfig();

  returnDefaultConfig = () => {
    return {
      height: this.props.userIsAuthenticated ? 150 : 200,
      width: 500,
      disableTooltips: false,
      pointRadius: 1,
      pointHoverRadius: 5,
      xAxesGridLines: {
        drawBorder: false,
        display: false,
        zeroLineWidth: 0,
      },
    };
  };

  returnMinimalConfig = () => {
    const prices = this.generateChartPrices();
    const paddingPercent = prices[0] > 25 ? 0.05 : 0.075;
    const paddingMin = this.props.applyPaddingToYAxisMinMax ? Math.min(...prices) * paddingPercent : 0;
    const paddingMax = this.props.applyPaddingToYAxisMinMax ? Math.max(...prices) * paddingPercent : 0;
    const yAxisMin = Math.floor(Math.min(...prices) - paddingMin);
    const yAxisMax = Math.ceil(Math.max(...prices) + paddingMax);
    return {
      height: 95,
      width: 168,
      disableTooltips: true,
      xAxisLabel: this.props.showXAxisLabel || '',
      yAxesLabelFontSize: 8,
      yAxesLabelFontColor: '#ccc',
      yAxesTicks: {
        min: yAxisMin,
        max: yAxisMax,
      },
      yAxesGridLines: {
        drawBorder: !this.props.hideAllAxes,
        zeroLineWidth: 0,
      },
      xAxesGridLines: {
        drawBorder: false,
        display: false,
        zeroLineWidth: 0,
      },
      borderWidth: 2,
      hideFill: true,
      borderCapStyle: 'round',
      customBorderColor: prices[0] < prices[prices.length - 1] ? 'rgba(1, 205, 3, 1)' : 'rgba(255, 88, 82, 1)',
      pointRadius: 0,
      hideYAxesTicks: true,
      pointHoverRadius: 0,
      yAxesAfterBuildTicks: [yAxisMin, yAxisMax],
    };
  };

  isNoChartData = () => {
    const chartData = this.returnSecurityPriceTimePeriodData();
    return !chartData || chartData.length === 0;
  };

  determineChartColor = () => {
    const priceData = this.returnSecurityPriceTimePeriodData();
    const stockHasGained =
      priceData.length > 0 ? parseFloat(priceData[0].price) < parseFloat(priceData[priceData.length - 1].price) : false;
    return stockHasGained ? { red: '178', green: '255', blue: '172' } : { red: '255', green: '178', blue: '172' };
  };

  handleTimePeriodChange = (timePeriod) => {
    if (TIME_PERIODS.filter((timePeriodObj) => timePeriodObj.name === timePeriod).length === 0) {
      console.error('Attempted to switch chart to a time period that is not supported.');
      return false;
    }

    const context =
      this.props.chartContext ||
      console.error('No context specified for chart, tracking event will be missing that data');
    const timePeriodName = this.convertTimePeriodNameToFullName(timePeriod);
    const event = 'Change Chart Time Period';
    const properties = {
      context: context || 'Undefined',
      'Time Period': timePeriodName,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);

    this.setState((prevState) => ({
      timePeriod,
    }));
  };

  convertTimePeriodNameToFullName = (name) => {
    const timePeriodObj = TIME_PERIODS.filter((timePeriod) => timePeriod.name === (name || this.state.timePeriod))[0];
    return timePeriodObj.fullName;
  };

  returnSecurityPriceTimePeriodData = (props = this.props) => {
    const securityId = props.securityId;
    const timePeriod = this.state.timePeriod;
    const allPriceDataForSecurity = props.securitiesPriceHistory.securitiesList[securityId];
    const priceDataForSecurity = allPriceDataForSecurity ? allPriceDataForSecurity[timePeriod] : null;
    return this.props.chartStyle === 'minimal' && this.state.timePeriod === 'one_year' && priceDataForSecurity
      ? priceDataForSecurity.filter((el, i) => i % 5 === 0)
      : priceDataForSecurity;
  };

  returnAdditionalSecurityPriceTimePeriodData = (props = this.props) => {
    const securityId = this.state.spySecurityId;
    const timePeriod = this.state.timePeriod;
    const allPriceDataForSecurity = props.securitiesPriceHistory.securitiesList[securityId];
    const priceDataForSecurity = allPriceDataForSecurity ? allPriceDataForSecurity[timePeriod] : null;
    return this.props.chartStyle === 'minimal' && this.state.timePeriod === 'one_year' && priceDataForSecurity
      ? priceDataForSecurity.filter((el, i) => i % 5 === 0)
      : priceDataForSecurity;
  };

  generateChartLabels = () => {
    const rawPriceHistoryArray = this.returnSecurityPriceTimePeriodData() || [];
    const priceHistoryArray = this._isIntraOrTwoDayTimePeriod()
      ? this._isIntradayTimePeriod()
        ? addBlanksForIntradayMissingIntervals(rawPriceHistoryArray)
        : addBlanksForTwoDayMissingIntervals(rawPriceHistoryArray)
      : rawPriceHistoryArray;
    return priceHistoryArray.map((priceDate) => {
      if (priceDate.time === null) {
        return null;
      }
      const epoch = priceDate.time * 1000;
      const date = moment(epoch);
      if (this.state.timePeriod === 'one_day' || this.state.timePeriod === 'two_days') {
        return `${formatLocalizedDateTime('l', date)} ${formatLocalizedDateTime('LT', date)}`;
      } else {
        return `${formatLocalizedDateTime('l', date)}`;
      }
    });
  };

  generateChartPrices = () => {
    const rawPriceHistoryArray = this.returnSecurityPriceTimePeriodData() || [];
    const priceHistoryArray = this._isIntraOrTwoDayTimePeriod()
      ? this._isIntradayTimePeriod()
        ? addBlanksForIntradayMissingIntervals(rawPriceHistoryArray)
        : addBlanksForTwoDayMissingIntervals(rawPriceHistoryArray)
      : rawPriceHistoryArray;
    return priceHistoryArray.map((date) => date.price);
  };

  generateAdditionalDatasetChartData = () => {
    const rawPriceHistoryArray = this.returnAdditionalSecurityPriceTimePeriodData() || [];
    const priceDataHistoryArray = this._isIntraOrTwoDayTimePeriod()
      ? this._isIntradayTimePeriod()
        ? addBlanksForIntradayMissingIntervals(rawPriceHistoryArray)
        : addBlanksForTwoDayMissingIntervals(rawPriceHistoryArray)
      : rawPriceHistoryArray;
    const ratio = this.returnSecurityPriceTimePeriodData()[0].price / priceDataHistoryArray[0].price;
    const priceHistoryArray = priceDataHistoryArray.map((date) => date.price);
    return priceHistoryArray.map((price, i) => {
      return isUndefinedOrNull(price) ? null : price * ratio;
    });
  };

  generateChartData = () => {
    const prices = this.generateChartPrices();
    return {
      labels: this.generateChartLabels(),
      prices,
      additionalDataset:
        this.state.shouldOverlaySpy && this.state.spySecurityId && this.isUsingCompareSpyOption()
          ? this.generateAdditionalDatasetChartData()
          : null,
      xAxisTicks: [],
      yAxisTicks: this.buildYaxisTicks(prices),
    };
  };

  buildYaxisTicks = (prices) => {
    prices = prices.map((price) => customParseFloat(price));
    const ticks = [];
    const totalNumOfTicks = 5;
    const min = Math.min(prices);
    const max = Math.max(prices);
    const diff = max - min;
    ticks.push(min);
    for (var i = 1; i <= totalNumOfTicks - 2; i++) {
      ticks.push((diff / totalNumOfTicks - 2) * i);
    }
    ticks.push(max);
    return ticks;
  };

  onGetSPYSecurityId = () => {
    const spySecurityId = this.state.spySecurityId;
    this.props.actions.fetchSecuritiesPriceHistoryData([spySecurityId], 'one_year');
    this.props.actions.fetchSecuritiesPriceHistoryData([spySecurityId], 'one_day');
    this.props.actions.fetchSecuritiesPriceHistoryData([spySecurityId], 'two_days');
    this.props.actions.fetchSecuritiesPriceHistoryData([spySecurityId], 'five_years');
    this.props.actions.fetchSecuritiesPriceHistoryData([spySecurityId], 'max');
  };

  _getSPYSecurityId = () => {
    this.convertSecuritySymbolToSecurityId('SPY').then((securityId) => {
      if (securityId) {
        this.setSpySecurityId(securityId);
      } else {
        this.showCannotOverlaySPYError();
      }
    });
  };

  showCannotOverlaySPYError = () => {
    const modalMessage = 'Unable to show SPY prices at this time. Please try again later.';
    const modal = {
      contentComponent: createBasicErrorModal(modalMessage),
      dismissable: true,
      size: 'wide',
    };
    this.props.actions.showModal(modal);
  };

  setSpySecurityId = (id) => this.setState(() => ({ spySecurityId: id }));

  convertSecuritySymbolToSecurityId = (symbol) => {
    return this.props.actions.quickFetchSecuritiesData([symbol]).then((response) => {
      const wasSuccess = response && response.securities && response.securities[0];
      if (wasSuccess) {
        return response.securities[0].security_id;
      } else {
        return null;
      }
    });
  };

  _returnTimePeriod = () => this.state.timePeriod;

  _isIntraOrTwoDayTimePeriod = () => {
    const currentTimePeriod = this._returnTimePeriod();
    return isIntraOrTwoDayTimePeriod(currentTimePeriod);
  };

  _isIntradayTimePeriod = () => isIntradayTimePeriod(this._returnTimePeriod());

  logOverlaySpyCheckboxClick = (value) => {
    const event = 'Clicked Compare Price History To SPY';
    const properties = {
      'Security ID': this.props.securityId,
      'Toggle On': value,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    securitiesPriceHistory: state.securitiesPriceHistory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityChartContainer);
