import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useCurrentUser, useLiveAccount } from '../../hooks/user';
import { logMetricsTrackingEvent, submitLiveTradingSignUpForm } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import { H5, Body5, Lock, Toggle, FloatingLabelTextInput, createToggleItem } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { useFormik } from 'formik';
import { DisclosuresEmploymentSchema } from '../../constants/formValidationSchemas';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { PRODUCT_NAMES } from '@src/appConfig';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const ToggleFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const ToggleWrapper = styled.div`
  margin-left: 10px;
  min-width: 140px;
`;
const InputWrapper = styled.div`
  padding-top: 40px;

  input {
    border: none;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
`;
const AdditionalInputWrapper = styled.div`
  padding: 16px 12px 0 12px;
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const PrivacyMessage = styled.div`
  padding-top: 24px;
`;
const IconMessage = styled.div`
  padding-top: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  text-align: left;

  svg {
    height: 24px;
    width: 24px;

    fill: ${({ theme }) => theme.themeColors.text};

    margin-right: 16px;
  }
`;
const Actions = styled.div`
  padding-top: 24px;
  text-align: center;
`;

const getInitialValuesFrom = (currentUser, liveAccount) => {
  const { is_tornado_employee } = liveAccount;
  return {
    is_tornado_employee: is_tornado_employee || 'NO',
  };
};

const toggleItems = [createToggleItem({ text: 'Yes', value: 'YES' }), createToggleItem({ text: 'No', value: 'NO' })];

const massageFormData = (v) => v;

const FORM_PAGE_NAME = 'disclosures';

export const EnterDisclosuresEmployment = ({ onContinue, onboardingState }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const liveAccount = useLiveAccount();

  const [isReady, setIsReady] = React.useState(true);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const form = useFormik({
    initialValues: getInitialValuesFrom(currentUser, liveAccount),
    validationSchema: DisclosuresEmploymentSchema,
    enableReinitialize: false,
  });
  const { values, errors, setFieldValue, validateForm } = form;

  const handleChange = React.useCallback(
    (name) => (value) => {
      setFieldValue(name, value, false);
    },
    [setFieldValue]
  );

  const submitForm = React.useCallback((values) => {
    const apiFormData = massageFormData(values);
    setIsSubmitting(true);
    return submitLiveTradingSignUpForm(apiFormData, FORM_PAGE_NAME)(dispatch);
  }, []);

  const handleSubmit = React.useCallback(async () => {
    logMetricsTrackingEvent('Submitted Employment Disclosures Form')();

    const validation = await validateForm();
    if (Object.keys(validation).length === 0) {
      const { status } = await submitForm(values);
      if (status === 200) {
        return {
          ok: true,
        };
      } else {
        setFormError('Something went wrong, please try again');
        setIsSubmitting(false);
        return {
          ok: false,
        };
      }
    } else {
      logMetricsTrackingEvent('Failed Live Account Validations', {
        Fields: Object.entries(validation),
        'Form Name': 'disclosures',
      })();

      // could log errors but no form validations on this form
      setIsSubmitting(false);
      return {
        errors: true,
      };
    }
  }, [values]);

  const handleContinueClick = React.useCallback(async () => {
    const { ok } = await handleSubmit();
    if (ok) onContinue(null, onboardingState);
  }, [handleSubmit]);

  React.useEffect(() => {
    logMetricsTrackingEvent('View Employment Disclosures Form')();
  }, []);

  if (!isReady) return <LoadingPage />;

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.disclosures}>
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>Do you have any disclosures?</H5>
          </Heading>

          <InputWrapper>
            <ToggleFieldWrapper>
              <div>
                <Body5>Are you an employee of or affiliated with {PRODUCT_NAMES.Tornado}?</Body5>
              </div>
              <ToggleWrapper>
                <Toggle
                  name={'is_tornado_employee'}
                  value={values.is_tornado_employee}
                  label={null}
                  items={toggleItems}
                  onChange={handleChange}
                  error={errors.is_tornado_employee || null}
                />
              </ToggleWrapper>
            </ToggleFieldWrapper>
          </InputWrapper>
          {null && (
            <InputWrapper>
              <ToggleFieldWrapper>
                <div>
                  <Body5>Are you an employee of or affiliated with Apex Fintech Solutions?</Body5>
                </div>
                <ToggleWrapper>
                  <Toggle
                    name={'is_affiliated_apex'}
                    value={values.is_affiliated_apex}
                    label={null}
                    items={toggleItems}
                    onChange={handleChange}
                    error={errors.is_affiliated_apex || null}
                  />
                </ToggleWrapper>
              </ToggleFieldWrapper>
            </InputWrapper>
          )}

          <PrivacyMessage>
            <IconMessage>
              <Lock />
              <Body5>This information will be kept private and it will never appear on your public profile.</Body5>
            </IconMessage>
          </PrivacyMessage>

          <Actions>
            <FormState error={formError} isSubmitting={isSubmitting} />
            <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleContinueClick}>
              Continue
            </FlatButton>
          </Actions>
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
