import { imagesAdapter } from '../adapters/imagesAdapter';

import { createThoughtPreviewImageNotHttpsEvent, createNoPreviewImageEvent } from '../helpers/thoughtsHelpers';

export const fetchImageFromURL = (url) => {
  return function (dispatch) {
    return imagesAdapter.fetchImageFromURL(url).then((response) => {
      if (response && response.data) {
        const data = response.data;

        if (data && data.images && data.images[0]) {
          if (data.images[0].split('://')[0] != 'https') {
            createThoughtPreviewImageNotHttpsEvent(url, data.images[0]);
          }
        } else {
          createNoPreviewImageEvent(data);
        }
        return response;
      }
      return response;
    });
  };
};
