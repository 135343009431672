import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const investmentObjectivesAdapter = {
  getRiskProfiles: () => {
    const options = { ignoreAuth: true };
    return sendRequest('get', [`${URL}/optimize/settings/risk_profiles`], options).then((response) => response);
  },

  getInvestmentObjectiveSettings: () => {
    const options = { ignoreAuth: true };
    return sendRequest('get', [`${URL}/optimize/settings`], options).then((response) => response);
  },

  saveInvestmentObjectiveSettings: (settings) => {
    const options = {};
    return sendRequest('put', [`${URL}/optimize/settings`, { ...settings }], options).then((response) => response);
  },
};
