import React from 'react';
import { connect } from 'react-redux';

import { getKarmaTotalFromState } from '../../selectors/karma';
import { formatDataValue } from '../../helpers/securitiesHelpers';

class KarmaTotalValue extends React.PureComponent {
  render() {
    const { karmaPoints: _state_karmaPoints, value, fontSize } = this.props;

    let karmaPoints = _state_karmaPoints;

    if (!isNaN(value)) karmaPoints = value;

    const displayPointsTotal =
      karmaPoints === null || isNaN(karmaPoints) ? '...' : formatDataValue(karmaPoints, 'smallNumber');
    return (
      <span
        className={' '}
        style={{
          fontSize: fontSize,
        }}
      >
        {displayPointsTotal}
      </span>
    );
  }
}

KarmaTotalValue.defaultProps = {
  textProps: {},
  fontSize: '14px',
};

const mapStateToProps = (state) => {
  return {
    karmaPoints: getKarmaTotalFromState(state),
  };
};

export default connect(mapStateToProps)(KarmaTotalValue);
