import React from 'react';
import LinkTo from '../../Links/LinkTo';

import ProfileAvatar from '../../../components/user/ProfileAvatar';
import ConnectionRequest from '../../buttons/ConnectionRequest';

import { userFullName } from '../../../helpers/usersHelpers';

const ConnectionsListItem = (props) => {
  const { user, isCurrentUser } = props;
  const name = isCurrentUser ? 'Me' : userFullName(user);
  const { city, state } = user;
  const userLocation = city && state ? `${city}, ${state}` : city ? `${city}` : state ? `${state}` : null;

  const renderConnectionListItem = () => {
    return (
      <span className={'connections-list-item-container component-bg-with-hover-feedback border-accent'}>
        <span className={'connection-list-avatar-container'}>
          <ProfileAvatar
            size={'medium'}
            user={user}
            withLink={false}
            userIsCurrentUser={user.is_current_user}
            isThoughtLeader={false}
          />
        </span>

        <span className={'user-name-and-location-container'}>
          <span className={'user-full-name  '}>{name}</span>
          {userLocation && <span className={'user-location secondary-text-color'}>{userLocation}</span>}
        </span>

        <ConnectionRequest userId={user.user_id} viewingContext={'Profile'} />
      </span>
    );
  };
  return (
    <LinkTo
      to={`/users/${user.user_id}/public_profile`}
      className={''}
      child={renderConnectionListItem()}
      scrollToTop
      noTextDecoration
    />
  );
};

export default ConnectionsListItem;
