export const buildLessonParagraphs = (body) => {
  const paragraphs = [[]];
  body.forEach((d) => {
    const { isNewLine, isPaddedNewLine } = d;
    if (isNewLine || isPaddedNewLine) {
      paragraphs.push([d]);
    } else {
      paragraphs[paragraphs.length - 1].push(d);
    }
  });
  return paragraphs;
};
