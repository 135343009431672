import React from 'react';
import TransactionAmount from '../TransactionComponents/TransactionAmount';

const FeeWithSubTypeTransaction = (props) => {
  const {
    className,

    type,
    account_name,
    sub_type,
    amount,

    transaction_id,
    time,
  } = props;

  const subType = account_name || sub_type;
  return (
    <li
      className={`
        fee-transaction-item
        ${className || ''}
      `}
    >
      <div className={`fee-transaction-details`}>
        <div className={`fee-transaction-identifier`}>
          <span className={`transaction-identifier`}>
            <div className={`transaction-type`}>{type}</div>
            <div className={`transaction-sub-type secondary-text-color`}>{subType}</div>
          </span>
        </div>
        <TransactionAmount className={`transaction-amount`} isVoid={false} value={amount} />
      </div>
    </li>
  );
};

export default FeeWithSubTypeTransaction;
