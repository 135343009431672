import React from 'react';

class RebalancerDisclaimer extends React.PureComponent {
  render() {
    return (
      <div className={'optimizer-disclaimer'}>
        <span className="disclaimer-text-color">
          {
            'Projected return is based on the price targets and other information in your ideas. Risk is based on recent historical data. Results may vary with each use and over time. Conviction levels affect the maximum possible allocation for each position. Any "short term" (6 month) idea will be annualized. IMPORTANT: The projections or other information generated by the Rebalancer regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual investment results and are not guarantees of future results.'
          }
        </span>
      </div>
    );
  }
}

export default RebalancerDisclaimer;
