import { trackingEvents } from '@src/main/lib/nvstr-utils.es';

import ItlyTrackWrapper from './ItlyTrackWrapper';

const LEARN_LESSON_VIEWED = new ItlyTrackWrapper(trackingEvents.learnLessonViewed);
const LEARN_LESSON_REWARD_VIEWED = new ItlyTrackWrapper(trackingEvents.learnLessonRewardViewed);
const LEARN_LESSON_DISCLAIMER_VIEWED = new ItlyTrackWrapper(trackingEvents.learnLessonDisclaimerViewed);

const events = {
  LEARN_LESSON_VIEWED,
  LEARN_LESSON_REWARD_VIEWED,
  LEARN_LESSON_DISCLAIMER_VIEWED,
};

export default events;
