import { onboardingAdapter } from '../adapters/onboardingAdapter';

import { END_ONBOARDING } from '../constants';

export const endOnboarding = (currentUserId) => {
  return function (dispatch) {
    return onboardingAdapter.endOnboarding(currentUserId).then((response) => {
      dispatch({
        type: END_ONBOARDING,
      });
      return { ok: response.status === 200 };
    });
  };
};
