import { isRequestingConnection, hasRequestedConnection } from './usersHelpers';

const _returnUserList = (userStore) => userStore.userList;
const _returnConnectionRequestData = (userStore) =>
  Object.keys(_returnUserList(userStore)).map((userId) => _returnUserList(userStore)[userId]);

export const returnPendingSentConnectionRequests = (userStore) =>
  _returnConnectionRequestData(userStore).filter((user) => isRequestingConnection(user));
export const returnPendingSentConnectionRequestsCount = (userStore) =>
  returnPendingSentConnectionRequests(userStore).length;

export const returnPendingReceivedConnectionRequests = (userStore) =>
  _returnConnectionRequestData(userStore).filter((user) => hasRequestedConnection(user));
export const returnPendingReceivedConnectionRequestsCount = (userStore) =>
  returnPendingReceivedConnectionRequests(userStore).length;

export const arePendingSentConnectionRequests = (userStore) => returnPendingSentConnectionRequestsCount(userStore) > 0;
export const arePendingReceivedConnectionRequests = (userStore) =>
  returnPendingReceivedConnectionRequestsCount(userStore) > 0;

export const areConnectionRequests = (userStore) =>
  arePendingSentConnectionRequests(userStore) || arePendingReceivedConnectionRequests(userStore);
