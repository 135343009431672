import React from 'react';
import { useSelector } from 'react-redux';
import {
  hasRandomReferralRewardAmount,
  isReferralProgramActive,
  referralRewardLimit,
  referralRewardRequiresACHLink,
} from '../../../helpers/currentUserHelpers';
import { getRewardsDataFromState } from '../../../selectors/rewards';
import { REFERRAL_PAGE_HEADING_COPY_VERSIONS_BY_AB_GROUP } from '../../../containers/Rewards/constants/RewardsCopy';
import { getCurrentUserFromState } from '../../../selectors/currentUser';
import { isUndefinedOrNull } from '../../../helpers/generalHelpers';

const createTestGroup = (currentUser) => {
  const shareLinkMessageTestGroup = 'c';
  const noSubHeadingTestGroup = 'b';
  const headingCopyTestGroup = 'a';

  const headingTestGroup = currentUser.referral_page_heading_test_group === 1 ? 'b' : 'a';
  const referralCTAHeadingTestGroup = currentUser.referral_page_cta_heading_test_group === 1 ? 'b' : 'a';

  return {
    headingCopy: REFERRAL_PAGE_HEADING_COPY_VERSIONS_BY_AB_GROUP[headingCopyTestGroup],
    testGroups: {
      headingCopyTestGroup,
      shareLinkMessageTestGroup,
      noSubHeadingTestGroup,
      headingTestGroup,
      referralCTAHeadingTestGroup,
    },
    trackingProperties: {},
  };
};

const generateRewardsData = ({ currentUser, rewardsStore, testGroup }) => {
  const redeemableReferralRewardsCount = rewardsStore.referral_rewards_not_redeemed_count || 0;
  const redeemableFundingRewardsCount = rewardsStore.funding_rewards_not_redeemed_count || 0;
  const redeemableRewardsCount = redeemableReferralRewardsCount + redeemableFundingRewardsCount;

  const doesUserGetFixedReferralRewardAmount = !hasRandomReferralRewardAmount(currentUser);
  const maxPrizeLimit = referralRewardLimit(currentUser);
  const redeemedRewardsTotal = rewardsStore.referral_reward_total_redeemed;
  const approvedRewardsTotal = rewardsStore.referral_reward_total_approved;
  const didUserReachReferralRewardLimit = redeemedRewardsTotal >= maxPrizeLimit;

  const rewardsRequiringTradeToKeep = rewardsStore.cash_requiring_trade_to_keep;
  const totalCashToKeep = approvedRewardsTotal - rewardsRequiringTradeToKeep;
  const nextRewardExpirationDateFormattedMMDDYY = rewardsStore.next_cash_expiration_date;

  const rewardsRequireACHLink = referralRewardRequiresACHLink(currentUser);

  const initState = {
    isEligibleForReferralProgram: currentUser.is_eligible_for_referral_program,
    isReferralProgramActive: isReferralProgramActive(currentUser),

    redeemableRewardsCount,
    redeemedRewardsTotal,
    redeemableReferralRewardsCount,
    redeemableFundingRewardsCount,
    approvedRewardsTotal,

    didUserReachReferralRewardLimit,
    doesUserGetFixedReferralRewardAmount,
    maxPrizeLimit,

    hasUnearnedRewards: !isUndefinedOrNull(rewardsRequiringTradeToKeep) && rewardsRequiringTradeToKeep > 0,

    fundingRewardsTotalRedeemed: rewardsStore.funding_reward_total_redeemed,
    fundingRewardsTotalApproved: rewardsStore.funding_reward_total_approved,

    funding_reward_total_approved: rewardsStore.funding_reward_total_approved,
    referral_reward_total_approved: rewardsStore.referral_reward_total_approved,
    sign_up_reward_total_approved: rewardsStore.sign_up_reward_total_approved,
    learn_reward_total: rewardsStore.learn_reward_total,

    funding_reward_delayed_payout_amount: rewardsStore.funding_reward_delayed_payout_amount,
    funding_reward_delayed_payout_at: rewardsStore.funding_reward_delayed_payout_at,

    totalCashToKeep,
    nextRewardExpirationDateFormattedMMDDYY,
    rewardsRequireACHLink,
  };
  if (testGroup) initState.testGroup = testGroup;
  return initState;
};

function reducer(state, action) {
  switch (action.type) {
    case 'update': {
      const { currentUser, rewardsStore } = action.payload;
      return {
        ...state,
        ...generateRewardsData({ currentUser, rewardsStore }),
      };
    }
    default:
      throw new Error();
  }
}

export const useRewardsData = () => {
  const currentUser = useSelector(getCurrentUserFromState);
  const rewardsStore = useSelector(getRewardsDataFromState);

  const [testGroup] = React.useState(createTestGroup(currentUser));

  const [state, dispatch] = React.useReducer(reducer, { currentUser, rewardsStore, testGroup }, generateRewardsData);

  React.useEffect(
    () => {
      dispatch({
        type: 'update',
        payload: { currentUser, rewardsStore },
      });
    },
    [currentUser, rewardsStore]
  );

  return state;
};
