import React from 'react';

import LoadingIcon from '../../components/misc/LoadingIcon';
import SecuritiesFilterList from './SecuritiesFilterList';
import SecuritiesList from './SecuritiesList';
import SecurityTable from '../SecurityTable/SecurityTable';

import { getIdeaPhraseFor } from '../../helpers/terminologyHelpers';
import { MyIdeasList } from '@src/main/containers/Securities/MyIdeasList';
import styled from 'styled-components';
import { PageCard } from '@src/main/components/layout';

const TableWrapper = styled.div`
  max-width: 800px;
  margin: 24px auto;
`;

export const SecuritiesSelection = (props) => {
  const { securityIdsFromFilterResults } = props;

  const renderLoadingMessage = () => {
    if (securityIdsFromFilterResults.length > 0 && props.loadingFilters) {
      return (
        <div className="loading-more-securities-container">
          <LoadingIcon size="small" style={{ marginRight: '15px' }} />
          <span className="secondary-text-color">{'Applying filters...'}</span>
        </div>
      );
    } else if (securityIdsFromFilterResults.length > 0 && props.isLoadingMoreFilterResults && !props.loadingFilters) {
      return (
        <div className="loading-more-securities-container">
          <LoadingIcon size="small" style={{ marginRight: '15px' }} />
          <span className="secondary-text-color">{'Loading more results...'}</span>
        </div>
      );
    } else {
      return <div className="loading-more-securities-empty-container"></div>;
    }
  };

  const showSecuritiesList = props.presetFilter || (!props.presetFilter && !!securityIdsFromFilterResults.length);
  return (
    <div className="portfolio-explorer-securities-selection-container">
      {props.showFilterView && (
        <div>
          <SecuritiesFilterList
            filterConstructionProps={props.filterConstructionProps}
            filters={props.filters}
            presetFilters={props.presetFilters}
            updateFilters={props.updateFilters}
            securitiesLength={securityIdsFromFilterResults.length}
          />
          {renderLoadingMessage()}
          {showSecuritiesList && (
            <SecuritiesList
              expandSecurityCard={props.expandSecurityCard}
              handleSwipe={props.addToSecurityTable}
              securities={props.securityIdsFromFilterResults}
              filters={props.filters}
              loadingFilters={props.loadingFilters}
              cardRowDisplaying={props.cardRowDisplaying}
              width={props.width}
              cardWidth={props.cardWidth}
              paddingBetweenCards={props.paddingBetweenCards}
              offsetToFitArrows={props.offsetToFitArrows}
              cardsInARow={props.cardsInARow}
              changeFilterResultDisplayRow={props.changeFilterResultDisplayRow}
              navigate={props.navigate}
            />
          )}
        </div>
      )}
      <div className={`security-table-title`}>{getIdeaPhraseFor('table_title')}</div>
      <TableWrapper>
        <PageCard>
          <MyIdeasList />
        </PageCard>
      </TableWrapper>
    </div>
  );
};

export default SecuritiesSelection;
