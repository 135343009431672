import React from 'react';
import ProfileAvatar from '../../../components/user/ProfileAvatar';
import styled from 'styled-components';
import { useUser } from '../../hooks/user/useUser';
import { Body5, Body6 } from '../../lib/nvstr-common-ui.es';
import { isUserCollective, userDisplayNamePossessive, userFullName } from '../../../helpers/usersHelpers';
import { Container } from '../../components/ui';
import { scrollToTop } from '../../../helpers/pageHelpers';
import { parseQueryString } from '../../../helpers/routerHelpers';
import {
  acceptConnectionRequest,
  fetchMutualConnections,
  fetchUserPortfolio,
  getUser,
  getUserConnections,
  logMetricsTrackingEvent,
  removeConnection,
  sendConnectionRequest,
  viewProfile,
} from '../../../actions';
import { useDispatch } from 'react-redux';
import { PRODUCT_NAME } from '../../../appConfig';
import { Page } from '../../components/layout';
import { CommunityUserIdeaPerformanceTable } from '../../containers/user/CommunityUserIdeaTable';
import { TabbedScreen, ComponentWrapper, HeaderTabsWrapper } from '../../containers/ui/TabbedSection';
import { IdeaPerformance, IdeaPerformanceWrapper } from '../../containers/user/IdeaPerformance';
import { CommunityUserConnectionsList } from '../../containers/user/CommunityUserConnectionsList';
import UserPortfolioRank from '../../../containers/UI/UserPortfolioRank';
import ProfilePosts from '../../../containers/Profile/components/ProfilePosts';
import { LoadingPage } from '../../components/page';
import { useConnectionStatus, CONNECTION_STATUS_TYPES } from '../../hooks/user/useConnections';
import { FlatButton, SkeletonButton } from '../../components/buttons';
import { RemoveUser } from '../../assets/svgs/RemoveUser';
import { useCurrentUserId } from '../../hooks/user';

const ProfileHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const AvatarWrapper = styled.div``;
const IdentityWrapper = styled.div`
  padding: 0 0 0 24px;
`;
const ContentWrapper = styled.div``;
const ProfileBodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 24px 0 0 0;

  .row {
    &:first-child {
      padding-top: 0;
    }
    padding-top: 12px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: block;

    ${IdeaPerformanceWrapper} {
      margin: 24px auto 0 auto;
      max-width: 420px;
    }
  }
`;
const TabbedProfileWrapper = styled.div`
  padding-top: 16px;

  ${ComponentWrapper},
  ${HeaderTabsWrapper} {
    max-width: 1100px;
    width: 95%;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      width: 100%;
    }
  }
`;
const RankWrapper = styled.div``;
const ConnectButtonWrapper = styled.div`
  button {
    padding: 2px 12px 2px 12px;
  }
`;
const ConnectButton = ({ onEffect, userId }) => {
  const dispatch = useDispatch();

  const hc = async () => {
    const event = 'Clicked Send Connection Request';
    const properties = {
      // Context: viewingContext,
    };
    logMetricsTrackingEvent(event, properties)();

    onEffect(true);
    await sendConnectionRequest(userId)(dispatch);
    onEffect(false);
  };
  return (
    <ConnectButtonWrapper>
      <FlatButton onClick={hc}>Connect</FlatButton>
    </ConnectButtonWrapper>
  );
};

const AcceptConnectButtonWrapper = styled.div`
  button {
    padding: 3px 12px 2px 12px;
  }
`;
const AcceptConnectButton = ({ onEffect, userId }) => {
  const dispatch = useDispatch();
  const currentUserId = useCurrentUserId();
  const hc = async () => {
    onEffect(true);
    await acceptConnectionRequest(userId, 'profile', currentUserId)(dispatch);
    onEffect(false);
  };
  return (
    <AcceptConnectButtonWrapper>
      <FlatButton onClick={hc}>Accept Connection</FlatButton>
    </AcceptConnectButtonWrapper>
  );
};

const RemoveConnectButtonWrapper = styled.div`
  button {
    padding: 3px 4px 2px 4px;

    line-height: 1;
    * {
      line-height: 1;
    }
  }
  svg {
    height: 15px;
    width: 15px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const RemoveConnectionButton = ({ onEffect, userId }) => {
  const dispatch = useDispatch();
  const currentUserId = useCurrentUserId();
  const hc = async () => {
    onEffect(true);
    await removeConnection(currentUserId, userId)(dispatch);
    onEffect(false);
  };
  return (
    <RemoveConnectButtonWrapper>
      <SkeletonButton secondary onClick={hc}>
        <RemoveUser />
      </SkeletonButton>
    </RemoveConnectButtonWrapper>
  );
};

const ConnectionStatus = ({ userId }) => {
  const { status, canRequestConnection } = useConnectionStatus(userId);
  // const canRequestConnection = true;
  // const status = CONNECTION_STATUS_TYPES.none;
  const [isStatusChanging, setIsStatusChanging] = React.useState(false);

  const handleStatusChanging = async (v) => {
    setIsStatusChanging(v);
  };

  if (isStatusChanging) {
    return (
      <Container height={25} verticallyCenter>
        <Body5 thin>...</Body5>
      </Container>
    );
  }

  if (status === CONNECTION_STATUS_TYPES.connected) {
    return (
      <Container row verticallyCenter>
        <Body5 thin>Connected</Body5>
        <Container left={8}>
          <RemoveConnectionButton onEffect={handleStatusChanging} userId={userId} />
        </Container>
      </Container>
    );
  } else if (status === CONNECTION_STATUS_TYPES.requestedFromCurrentUser) {
    return (
      <Container row verticallyCenter>
        <Body5 thin>Connection Requested</Body5>
        <Container left={8}>
          <RemoveConnectionButton onEffect={handleStatusChanging} userId={userId} />
        </Container>
      </Container>
    );
  } else if (status === CONNECTION_STATUS_TYPES.requestedToCurrentUser) {
    return <AcceptConnectButton onEffect={handleStatusChanging} userId={userId} />;
  } else {
    if (canRequestConnection) {
      return <ConnectButton onEffect={handleStatusChanging} userId={userId} />;
    }
    return null;
  }
};

const Profile = ({ userId }) => {
  const isMobileSize = false;
  const isSelf = userId === useCurrentUserId();
  const user = useUser(userId);
  const isTornadoEmployee = user.is_nvstr_employee;
  const isPrivate = user.is_private;
  const { city, state, location } = user;
  const employeeDisclaimer =
    "This person is, or within the last 6 months has been, an employee or contractor of Nvstr Financial LLC dba Tornado or its affiliates. Any information disclosed by this individual reflects their personal activity, does not reflect the views of Nvstr Financial LLC dba Tornado or its affiliates, is not a recommendation and does not constitute investment advice. This individual's investments may not be appropriate for you.";
  const userLocation = location
    ? location
    : city && state
    ? `${city}, ${state}`
    : city
    ? `${city}`
    : state
    ? `${state}`
    : null;

  if (isPrivate) {
    return (
      <Container>
        <ProfileHeaderWrapper>
          <AvatarWrapper>
            <ProfileAvatar user={user} size={isMobileSize ? 'large' : 'profile'} withLink={false} showNameTag={false} />
          </AvatarWrapper>
          <IdentityWrapper>
            <Container>
              <Body6>{userFullName(user)}</Body6>
            </Container>
            <Container top={2}>
              <Body5 isLowContrast>Private</Body5>
            </Container>
            {isSelf ? null : (
              <Container top={8}>
                <ConnectionStatus userId={userId} />
              </Container>
            )}
          </IdentityWrapper>
        </ProfileHeaderWrapper>
        <ProfileBodyWrapper>
          <ContentWrapper>
            {isTornadoEmployee && (
              <div className={'row'}>
                <Body5 isLowContrast>{employeeDisclaimer}</Body5>
              </div>
            )}
          </ContentWrapper>
        </ProfileBodyWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <ProfileHeaderWrapper>
        <AvatarWrapper>
          <ProfileAvatar user={user} size={isMobileSize ? 'large' : 'profile'} withLink={false} showNameTag={false} />
        </AvatarWrapper>
        <IdentityWrapper>
          <Container>
            <Body6>{userFullName(user)}</Body6>
          </Container>
          <Container top={2}>
            <Body5 isLowContrast>{userLocation}</Body5>
          </Container>
          {isSelf ? null : (
            <Container top={8}>
              <ConnectionStatus userId={userId} />
            </Container>
          )}
        </IdentityWrapper>
      </ProfileHeaderWrapper>
      <ProfileBodyWrapper>
        <ContentWrapper>
          <RankWrapper>
            <UserPortfolioRank user={user} inline />
          </RankWrapper>
          {user.bio && user.bio.length > 0 ? (
            <div className={'row'}>
              <Body5>{user.bio}</Body5>
            </div>
          ) : null}
          {isTornadoEmployee && (
            <div className={'row'}>
              <Body5 isLowContrast>{employeeDisclaimer}</Body5>
            </div>
          )}
        </ContentWrapper>
        <IdeaPerformance userId={userId} />
      </ProfileBodyWrapper>
    </Container>
  );
};

const _updatePageTitle = (user) => {
  const usersFullName = userFullName(user);
  document.title = `${PRODUCT_NAME} - ${usersFullName}`;
};

const _logProfileView = (user) => {
  const event = 'View User Profile';
  const properties = {
    'From React Router': true,
    'In Onboarding': !user.has_completed_onboarding,
    'On Waitlist': user.is_on_waitlist,
    'Is Connected': user.connection_status_to_current_user === 'connected',
    'Profile User ID': user.analytics_id,
    'Is Collective User': isUserCollective(user),
  };
  logMetricsTrackingEvent(event, properties)();
};

const _logProfileViewToApi = (user) => {
  const query = parseQueryString(window.location.search);
  viewProfile(user.user_id, query.context);
};

const overrideStyle = {
  marginBottom: 0,
};
const tabbedSectionOverrideStyle = {
  ...overrideStyle,
  marginTop: '8px',
  marginBottom: '24px',
};

const TAB_NAMES = {
  ideas: 'ideas',
  connections: 'connections',
  posts: 'posts',
};
const componentLookup = {
  [TAB_NAMES.ideas]: {
    name: 'ideas',
    displayText: 'Portfolio/ Ideas',
    renderer: ({ userId }) => <CommunityUserIdeaPerformanceTable userId={userId} />,
  },
  [TAB_NAMES.connections]: {
    name: 'connections',
    displayText: 'Connections',
    renderer: ({ userId }) => <CommunityUserConnectionsList userId={userId} />,
  },
  [TAB_NAMES.posts]: {
    name: 'posts',
    displayText: 'Posts',
    renderer: ({ userId }) => (
      <div style={{ margin: '8px auto 0 auto', maxWidth: '500px' }}>
        <ProfilePosts userId={userId} />
      </div>
    ),
  },
};

const tabs = [TAB_NAMES.ideas, TAB_NAMES.connections, TAB_NAMES.posts];

const TabbedProfile = ({ userId }) => {
  return (
    <TabbedProfileWrapper>
      <TabbedScreen
        tabs={tabs}
        componentLookup={componentLookup}
        defaultTab={TAB_NAMES.ideas}
        rendererProps={{ userId }}
      />
    </TabbedProfileWrapper>
  );
};

const CommunityUserProfileDisplay = ({ userId }) => {
  const user = useUser(userId);
  const isPrivate = user.is_private;

  React.useEffect(
    () => {
      _logProfileView(user);
      _logProfileViewToApi(user);
      _updatePageTitle(user);
    },
    [userId]
  );

  return (
    <>
      <Page width={'1100px'} style={overrideStyle} mobileTransparentBackground>
        <Profile userId={userId} />
      </Page>
      {isPrivate ? (
        <Page width={'1100px'} style={tabbedSectionOverrideStyle} mobileTransparentBackground>
          <Container vertical={24} centerAll>
            <Body6 center>{`${userDisplayNamePossessive(user)} profile is set to private.`}</Body6>
          </Container>
        </Page>
      ) : (
        <Page width={'1100px'} style={tabbedSectionOverrideStyle} mobileTransparentBackground noPadding>
          <TabbedProfile userId={userId} />
        </Page>
      )}
    </>
  );
};

export const CommunityUserProfile = ({ userId }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(
    () => {
      scrollToTop();

      const init = async () => {
        const config = {
          include: ['performance'],
        };
        getUser(userId, config)(dispatch);
        getUserConnections(userId)(dispatch);
        fetchUserPortfolio(userId)(dispatch);
        fetchMutualConnections([userId])(dispatch);
        await getUser(userId)(dispatch);
        setIsLoading(false);
      };
      init();
    },
    [userId]
  );

  if (isLoading) {
    return <LoadingPage />;
  }
  return <CommunityUserProfileDisplay userId={userId} />;
};
