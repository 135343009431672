import React from 'react';

import CommunityPositionContainer from './CommunityPositionComponents/CommunityPositionContainer';
import IdeasList from './IdeaComponents/IdeasList';
import LinkTo from '../../../containers/Links/LinkTo';

import { truncateName } from '../../../helpers/ideaVisualizerHelpers';
import { returnSecurityPanelUrl } from '../../../helpers/securityPanelHelpers';
import { logMetricsTrackingEvent } from '../../../actions/trackingActions';

class Component extends React.PureComponent {
  render() {
    const {
      security,
      shortIdeas,
      symbol,
      companyName,
      longIdeas,

      buildSecurityPanelLink,
    } = this.props;

    return (
      <div className="visualizer-row border-accent">
        <div className="visualizer-ideas-container text-right">
          <CommunityPositionContainer community_position={security.community_percent_short} position={'short'} />
          <IdeasList ideas={shortIdeas} position={'short'} showIdeaTypeShadow={false} hideLabels />
        </div>
        <LinkTo
          to={buildSecurityPanelLink()}
          className="link-btn-low-contrast"
          additionalClickAction={this._logOpenSecurityPanel}
          child={
            <span className={'security-symbol-cell'}>
              {symbol}
              <br />
              <span className="idea-community-company-name">{companyName}</span>
            </span>
          }
          noTextDecoration
        />
        <div className="visualizer-ideas-container text-left">
          <CommunityPositionContainer community_position={security.community_percent_long} position={'long'} />
          <IdeasList ideas={longIdeas} position={'long'} showIdeaTypeShadow={false} hideLabels />
        </div>
      </div>
    );
  }
}

class CommunityPositionRow extends React.PureComponent {
  render() {
    const { security } = this.props;
    const userIdeas = security.user_ideas;
    const userOwnIdea = userIdeas
      .filter((user_idea) => user_idea.user.current_user)
      .map((userIdea) => ({
        ...userIdea.idea,
        user_id: userIdea.user.user_id,
      }));
    const shortIdeas = userIdeas
      .filter((user_idea) => user_idea.idea.idea_type.id === 1 && !user_idea.user.current_user)
      .map((userIdea) => ({
        ...userIdea.idea,
        user_id: userIdea.user.user_id,
      }));
    const longIdeas = userIdeas
      .filter((user_idea) => user_idea.idea.idea_type.id === 0 && !user_idea.user.current_user)
      .map((userIdea) => ({
        ...userIdea.idea,
        user_id: userIdea.user.user_id,
      }));

    if (userOwnIdea.length > 0) {
      if (userOwnIdea[0].idea_type.id === 0) {
        longIdeas.unshift(userOwnIdea[0]);
      } else {
        shortIdeas.unshift(userOwnIdea[0]);
      }
    }

    const symbol = security.symbol;
    const companyName = security.name.length > 12 ? truncateName(security.name, 12) : security.name;
    return (
      <Component
        security={security}
        shortIdeas={shortIdeas}
        symbol={symbol}
        companyName={companyName}
        longIdeas={longIdeas}
        buildSecurityPanelLink={this.buildSecurityPanelLink}
      />
    );
  }

  buildSecurityPanelLink = () => {
    const { security, navigate, location } = this.props;
    const { id } = security;
    const additionalProps = {
      activeTab: 'ideas',
    };
    const openSecurityPanelConfig = {
      securityId: id,
      additionalProps,
      navigate,
      location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _logOpenSecurityPanel = () => {
    const event = 'Open Security Panel';
    const properties = {
      Component: 'Community Ideas',
      Context: 'Security Panel',
    };
    logMetricsTrackingEvent(event, properties)();
  };
}

export default CommunityPositionRow;
