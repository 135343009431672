import React from 'react';
import { connect } from 'react-redux';
import { ROUTES } from '../../constants/paths';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/index';
import { logMetricsTrackingEvent } from '../../actions';
import FullPageCard from '../../components/layout/FullPageCard';
import HeadingWithGradientLine from '../UI/HeadingWithGradientLine';
import Button from '../../components/buttons/Button';
import Dropdown from '../Inputs/Dropdown';
import LoadingIcon from '../../components/misc/LoadingIcon';
import { renderInput } from '../../helpers/inputHelpers';
import { withRouter } from '../../main/utils';
import { FlatButton } from '../../main/components/buttons';
import { PRODUCT_DISPLAY_FULL_NAME } from '../../appConfig';

class PrivacySettingsPage extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      _didInit: false,
      _isSaving: false,

      formData: {
        privacy: this._returnPrivacyTypes()[0],
      },
      focusedField: null,
      focusNote: {},
      errors: {},
    };
  }

  componentDidMount() {
    const eventName = 'View Onboarding Privacy Selection';
    logMetricsTrackingEvent(eventName)();
  }

  render() {
    const { _isSaving: isSaving } = this.state;

    return (
      <FullPageCard showMobileCardStyling>
        <div className={'privacy-settings-page'}>
          <div className={'text-center'} style={{ margin: '0 auto' }}>
            <HeadingWithGradientLine
              text={'Choose your privacy setting'}
              additionalClasses={'full-page-card-heading'}
            />
          </div>
          <div style={{ margin: '0 auto', maxWidth: '300px', textAlign: 'center' }}>
            <div
              style={{
                marginTop: '25px',
              }}
            >
              <span>Protecting your data is important to us.</span>
            </div>
            <div
              style={{
                marginTop: '15px',
              }}
            >
              <span>
                Choose which setting is best for you for sharing information with the {PRODUCT_DISPLAY_FULL_NAME}{' '}
                community.
              </span>
            </div>
          </div>
          <div
            style={{
              marginTop: '25px',
            }}
          >
            {this._renderPrivacyDropdown()}
          </div>
          <div
            className="text-center"
            style={{
              margin: '15px auto 0 auto',
              maxWidth: '300px',
              textAlign: 'center',
              fontSize: '12px',
            }}
          >
            <span className="secondary-text-color">
              When public is enabled, the {PRODUCT_DISPLAY_FULL_NAME} community can see positions and allocation
              percentages, but never your position dollar or share amounts.
            </span>
          </div>
          <div
            className="text-center"
            style={{
              margin: '35px auto 0 auto',
              maxWidth: '300px',
              textAlign: 'center',
            }}
          >
            {isSaving ? (
              <div className={'saving-form-message-container'}>
                <div className={'centered-loading-icon'}>
                  <LoadingIcon icon="fading-3balls" size="small" />
                  <div
                    className="secondary-text-color"
                    style={{
                      paddingLeft: '22px',
                      lineHeight: '22px',
                      fontSize: '13px',
                      fontWeight: '300',
                    }}
                  >
                    Updating...
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <FlatButton stopPropagation onClick={this.handleContinuePress}>
                    Continue
                  </FlatButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </FullPageCard>
    );
  }

  _renderInput = (input) => {
    return renderInput.call(this, input);
  };

  _renderPrivacyDropdown = () => {
    const input = this._createPrivacyDropdownInputData();
    return this._renderInput(input);
  };

  handleDropdownChange = (name, value) => {
    this._setPrivacyDropdownFormValue(value);
  };

  handleContinuePress = () => {
    const value = this.state.formData.privacy;

    this.handleSubmit(value);
  };

  handleSubmit = (value) => {
    this.setState(() => ({
      _isSaving: true,
    }));

    const eventName = 'Submit Onboarding Privacy Selection';
    const properties = {
      Value: value.name,
    };
    logMetricsTrackingEvent(eventName, properties)();

    this._savePrivacyPreference(value.name).then((success) => {
      this.setState(() => ({
        _isSaving: false,
      }));

      if (success) this.handleAfterNavigation();
    });
  };

  handleAfterNavigation = () => {
    const { navigate } = this.props;
    navigate(ROUTES.DASHBOARD.build());
  };

  _setFormDataValue = (key, value) => {
    this.setState((prevState) => ({
      formData: {
        ...this.state.formData,
        [key]: value,
      },
    }));
  };

  _setPrivacyDropdownFormValue = (value) => {
    this._setFormDataValue('privacy', value);
  };

  _savePrivacyPreference = (value) => {
    const name = 'privacy';

    return this.props.actions.updateUserProperty({ [name]: value }).then((wasSuccess) => {
      if (!wasSuccess) {
        this._showSaveFailedErrorMessage();
      }
      return wasSuccess;
    });
  };

  _showSaveFailedErrorMessage = () => {
    const message = 'Unable to save. Please reload page and try again.';
    const contentComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {message}
      </div>
    );

    const modal = {
      contentComponent,
      size: 'wide',
      dismissable: true,
    };
    this.props.actions.showModal(modal);
  };

  _createPrivacyDropdownInputData = () => ({
    type: Dropdown,
    typeName: 'Dropdown',
    name: 'privacy',
    label: 'Privacy',
    required: true,
    props: {
      handleChange: this.handleDropdownChange,
      styling: 'dropdown-centered',
      values: this._returnPrivacyTypes(),
      includeLabelAsSelection: false,
      forceShowLabel: true,
      showArrowIcons: true,
    },
  });

  _returnPrivacyTypes = () => [
    {
      id: 2,
      name: 'public_privacy',
      displayName: 'Public',
      isEnabled: () => true,
      disabledReason: () => null,
    },
    {
      id: 1,
      name: 'connections_privacy',
      displayName: 'Connections',
      isEnabled: () => true,
      disabledReason: () => null,
    },
    {
      id: 0,
      name: 'private_privacy',
      displayName: 'Private',
      isEnabled: () => true,
      disabledReason: () => null,
    },
  ];
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const connectedComponent = connect(null, mapDispatchToProps)(PrivacySettingsPage);
export default withRouter(connectedComponent);
