import { START_LOADING_PRICE_HISTORY, ADD_PRICE_HISTORY } from '../constants';

const defaultState = {
  securitiesList: {},
};

export default function securitiesPriceHistory(state = defaultState, action) {
  let payload, security, securities, securitiesArray, timePeriod, formatSecuritiesToDictionary;

  switch (action.type) {
    case ADD_PRICE_HISTORY:
      payload = action.payload;
      securities = action.payload.securities;
      timePeriod = payload.timePeriod;

      if (Array.isArray(securities)) {
        securitiesArray = payload.securities;
        formatSecuritiesToDictionary = {};

        securitiesArray.forEach((security) => {
          formatSecuritiesToDictionary[security.security_id] = {
            ...state.securitiesList[security.security_id],
            [timePeriod]: security.price_history,
          };
        });

        return {
          ...state,
          securitiesList: {
            ...state.securitiesList,
            ...formatSecuritiesToDictionary,
          },
        };
      } else {
        security = securities;
        return {
          ...state,
          securitiesList: {
            ...state.securitiesList,
            [security.security_id]: {
              ...state.securitiesList[security.security_id],
              [timePeriod]: security.price_history,
            },
          },
        };
      }

    case START_LOADING_PRICE_HISTORY:
      payload = action.payload;
      securities = payload.securities;
      timePeriod = payload.timePeriod;

      if (Array.isArray(securities)) {
        securitiesArray = payload.securities;
        formatSecuritiesToDictionary = {};

        securitiesArray.forEach((security) => {
          if (state.securitiesList[security.securityId] && !(timePeriod in state.securitiesList[security.securityId])) {
            formatSecuritiesToDictionary[security.security_id] = {
              ...state.securitiesList[security.security_id],
              [timePeriod]: null,
            };
          }
        });

        return {
          ...state,
          securitiesList: {
            ...state.securitiesList,
            ...formatSecuritiesToDictionary,
          },
        };
      } else {
        security = securities;
        const securityId = security.security_id;
        if (state.securitiesList[security.securityId] && !(timePeriod in state.securitiesList[security.securityId])) {
          return {
            ...state,
            securitiesList: {
              ...state.securitiesList,
              [securityId]: {
                ...state.securitiesList[securityId],
                [timePeriod]: state.securitiesList[securityId] ? state.securitiesList[securityId][timePeriod] : null,
              },
            },
          };
        } else {
          return state;
        }
      }

    default:
      return state;
  }
}
