import {
  ADD_ORDER_DATA,
  ADD_PLACED_ORDER,
  CANCEL_ORDER,
  CLEAR_ORDER_BASKET,
  CREATE_ORDERS,
  DELETE_ORDER,
  GETTING_ORDER_DATA,
  orderActions,
  PLACE_ORDERS,
  UNDELETE_ORDER,
  UNDO_CLEAR_ORDER_BASKET,
  UPDATE_ORDER,
} from '@src/constants';
import { ordersAdapter } from '@src/adapters/ordersAdapter';
import { sendRequest, sendRequestV1 } from '@src/helpers/ajaxHelpers';
import { BASEURL } from '@src/adapters';

const V1_URL = `${BASEURL}/api/v1`;

export const getOrdersV1 = () => {
  return async function(dispatch) {
    const response = await sendRequestV1({
      method: 'get',
      url: `${V1_URL}/orders`,
    });
    if (response?.status === 200) {
      dispatch({
        type: orderActions.getAllOrdersData,
        payload: response.data,
      });
    }
    return response;
  };
};

export const createOrderV1 = (order) => {
  return async function(dispatch) {
    const response = await sendRequestV1({
      method: 'post',
      url: `${V1_URL}/orders`,
      body: {
        order,
      },
    });

    if (response?.status === 200) {
      const order = response.data;
      dispatch({
        type: orderActions.createCartOrder,
        payload: order,
      });
      return { ok: true, ...response };
    }
    return response;
  };
};

export const createAndPlaceOrderV1 = (order, acceptedWarnings) => {
  return async function(dispatch) {
    const body = { order };
    if (acceptedWarnings && acceptedWarnings.length > 0) {
      body.override_warnings = acceptedWarnings;
    }
    const response = await sendRequestV1({ method: 'post', url: `${V1_URL}/order/place`, body });
    const { statusCode, data } = response;
    const successStatuses = [200, 207, 422];

    if (successStatuses.includes(statusCode)) {
      if (statusCode === 200) {
        dispatch({
          type: orderActions.placedOrders,
          payload: [data],
        });

        return {
          ok: true,
          isPlaced: true,
          order: data,
        };
      }

      return {
        ok: true,
        withErrors: statusCode === 207,
        withWarnings: statusCode === 422,
        data,
      };
    } else {
      return {
        error: data?.error || 'Something went wrong, please try again.',
      };
    }
  };
};

export const placeOrdersV1 = (order_ids, acceptedWarnings) => {
  return async function(dispatch) {
    const body = {
      order_ids,
    };
    if (acceptedWarnings && acceptedWarnings.length > 0) {
      body.override_warnings = acceptedWarnings;
    }
    const response = await sendRequestV1({ method: 'post', url: `${V1_URL}/orders/place`, body });
    const { status, data } = response;

    const successStatuses = [200, 207, 422];
    if (successStatuses.includes(status)) {
      if (status === 200) {
        dispatch({
          type: orderActions.placedOrders,
          payload: data.orders,
        });
        return {
          ok: true,
          isPlaced: true,
          orders: data.orders,
        };
      }

      return {
        ...response,
        ok: true,
        withErrors: status === 207,
        withWarnings: status === 422 || data?.warnings?.length > 0,
      };
    } else {
      return {
        ...response,
        error: data?.error || 'Something went wrong, please try again.',
      };
    }
  };
};

export const updateOrderInCartV1 = (orderId, order) => {
  return async function(dispatch) {
    const response = await sendRequestV1({
      method: 'patch',
      url: `${V1_URL}/orders/${orderId}`,
      body: order,
    });
    if (response?.status === 200) {
      const order = response.data;
      dispatch({
        type: orderActions.updateOrder,
        payload: order,
      });
      return { ok: true, ...response };
    }
    return response;
  };
};

export const deleteOrderV1 = (orderId) => {
  return async function(dispatch) {
    dispatch({
      type: orderActions.deleteOrder,
      payload: orderId,
    });
    const response = await sendRequestV1({
      method: 'delete',
      url: `${V1_URL}/orders/${orderId}`,
    });

    if (response?.status !== 200) {
      // not needed
      // dispatch({
      //   type: UNDELETE_ORDER,
      //   payload: orderId,
      // });
    }
    return response;
  };
};

export const deleteAllOrdersV1 = () => {
  return async function(dispatch) {
    const response = await sendRequestV1({
      method: 'delete',
      url: `${V1_URL}/orders/delete_all`,
    });
    if (response?.status === 200) {
      dispatch({
        type: orderActions.deleteAllOrders,
      });
      return {
        ok: true,
      };
    } else {
      return { error: 'Could not clear basket, please try again later.' };
    }
  };
};

// export const cancelPlacedOrder = (orderId) => {
//   return function(dispatch) {
//     return ordersAdapter.cancelPlacedOrder(orderId).then((response) => {
//       const wasSuccessful = response && response.data && response.data.status === 'canceled';
//       if (wasSuccessful) {
//         const updatedOrder = response.data;
//         dispatch({
//           type: CANCEL_ORDER,
//           payload: { updatedOrder },
//         });
//       }
//       return response;
//     });
//   };
// };
