import {
  ADD_IDEAS_FOR_SECURITY,
  ADD_USER_IDEAS,
  ADD_CURRENT_USER_IDEAS,
  ADD_IDEAS,
  REFRESHING_IDEA_DATA,
  UPDATE_IDEA,
  CURRENT_USER_CREATING_IDEAS,
  REMOVE_CURRENT_USER_CREATING_IDEAS,
  REMOVE_IDEA,
  LOADING_IDEAS_FOR_SECURITY,
  LOADING_IDEAS_FOR_USER,
  LOADING_IDEAS,
} from '../constants';

import { convertArrayToObject, convertObjectToArray } from '../helpers/generalHelpers';
import { getSecurityIdFromIdea } from '../helpers/ideaHelpers';

import { throwError } from '../helpers/devToolHelpers';

const defaultState = {
  loadingIdeasForUserIdsList: [],
  loadingIdeasForSecurityIdsList: [],

  ideaList: {}, // lookup object, key => idea_id, if loading, key will be present with {loading: true}

  loading: true, // initial load only
  loadingCurrentUserIdeas: true, // initial load only
  userCreatingIdeas: {}, // list used to give instant create idea feedback to user
};

const addIdeasToState = (ideas, state, additionalDataForState = {}) => {
  const newIdeas = convertArrayToObject(ideas, 'idea_id');
  return {
    ...state,
    ...additionalDataForState,
    ideaList: {
      ...state.ideaList,
      ...newIdeas,
    },
  };
};

export default function ideasReducer(state = defaultState, action) {
  let ideas, ids, updatedTempIdeas, currentTempIdeasKeys, removingSecurityIds, loadingIdeaIds;
  const allIdeaIds = Object.keys(state.ideaList);
  const ideaList = state.ideaList;

  switch (action.type) {
    case LOADING_IDEAS_FOR_SECURITY: {
      const loadingIdeaIds = action.payload;
      if (!Array.isArray(loadingIdeaIds)) {
        throwError('Expecting an Array for payload of securityIds');
      }
      return {
        ...state,
        loadingIdeasForSecurityIdsList: [...state.loadingIdeasForSecurityIdsList, ...loadingIdeaIds],
      };
    }

    case LOADING_IDEAS_FOR_USER: {
      const loadingIdeaIds = action.payload;
      if (!Array.isArray(loadingIdeaIds)) {
        throwError('Expecting an Array for payload of userIds');
      }
      return {
        ...state,
        loadingIdeasForUserIdsList: [...state.loadingIdeasForUserIdsList, ...loadingIdeaIds],
      };
    }

    case LOADING_IDEAS: {
      const loadingIdeaIds = action.payload;
      if (!Array.isArray(loadingIdeaIds)) {
        throwError('Expecting an Array for payload of ideaIds');
      }
      const loadingIdeas = {};
      loadingIdeaIds.forEach((id) => {
        loadingIdeas[id] = { loading: true };
      });
      return addIdeasToState(loadingIdeas, state);
    }

    case ADD_IDEAS_FOR_SECURITY: {
      const ideas = action.payload.ideas;
      const ids = action.payload.ids;
      const loadingIdeasForSecurityIdsList = state.loadingIdeasForSecurityIdsList.filter((loadingId) =>
        ids.every((id) => id !== loadingId)
      );
      return addIdeasToState(ideas, state, { loadingIdeasForSecurityIdsList });
    }

    case ADD_USER_IDEAS: {
      const ideas = action.payload.ideas;
      const ids = action.payload.ids;
      const loadingIdeasForUserIdsList = state.loadingIdeasForUserIdsList.filter((loadingId) =>
        ids.every((id) => id !== loadingId)
      );
      return addIdeasToState(ideas, state, { loadingIdeasForUserIdsList });
    }

    case ADD_CURRENT_USER_IDEAS: {
      const ideas = action.payload.ideas;
      return addIdeasToState(ideas, state, { loadingCurrentUserIdeas: false });
    }

    case ADD_IDEAS: {
      // Cannot add more than one idea at a time
      const newIdeas = action.payload.filter((idea) => idea.status !== 'error');
      const newIdeasObj = convertArrayToObject(newIdeas, 'idea_id');
      const removingSecurityIds = newIdeas.map((idea) => getSecurityIdFromIdea(idea));
      const updatedTempIdeas = {};
      const currentTempIdeasKeys = Object.keys(state.userCreatingIdeas).map((id) => parseInt(id, 10));
      currentTempIdeasKeys.forEach((id) =>
        !removingSecurityIds.includes(id) ? (updatedTempIdeas[id] = state.userCreatingIdeas[id]) : false
      );
      return {
        ...state,
        ideaList: {
          ...state.ideaList,
          ...newIdeasObj,
        },
        userCreatingIdeas: updatedTempIdeas,
      };
    }

    case UPDATE_IDEA: {
      const ideaDataToUpdate = action.payload;
      const updatedIdea = {
        ...state.ideaList[ideaDataToUpdate.idea_id],
        ...ideaDataToUpdate,
      };
      return {
        ...state,
        ideaList: {
          ...state.ideaList,
          [updatedIdea.idea_id]: updatedIdea,
        },
      };
    }

    case REFRESHING_IDEA_DATA: {
      const ideaUpdatingSecurityIds = action.payload.securities.map((securityId) => parseInt(securityId));
      const ideaIdsForSecurityIds = [];
      allIdeaIds.forEach((ideaId) => {
        let idea = ideaList[ideaId];
        let securityId = getSecurityIdFromIdea(idea);
        if (ideaUpdatingSecurityIds.includes(securityId)) {
          ideaIdsForSecurityIds.push(idea.idea_id);
        }
      });
      const updatedIdeasObj = {};
      ideaIdsForSecurityIds.forEach((ideaId) => {
        let idea = {
          ...ideaList[ideaId],
          loading: true,
        };
        updatedIdeasObj[ideaId] = idea;
      });
      return {
        ...state,
        ideaList: {
          ...state.ideaList,
          ...updatedIdeasObj,
        },
      };
    }

    case CURRENT_USER_CREATING_IDEAS: {
      // Cannot add more than one idea at a time
      const creatingSecurityId = action.payload;
      let id = Array.isArray(creatingSecurityId) ? creatingSecurityId[0] : creatingSecurityId;
      const creatingSecuritiesObj = {};
      const creatingIdeaData = {
        idea_id: undefined,
        security: { security_id: id },
      };
      creatingSecuritiesObj[id] = creatingIdeaData;
      return {
        ...state,
        userCreatingIdeas: {
          ...state.userCreatingIdeas,
          ...creatingSecuritiesObj,
        },
      };
    }

    case REMOVE_CURRENT_USER_CREATING_IDEAS: {
      // Cannot remove more than one idea at a time
      const removingSecurityIds = action.payload;
      const updatedTempIdeas = {};
      const currentTempIdeasKeys = Object.keys(state.userCreatingIdeas).map((id) => parseInt(id, 10));
      currentTempIdeasKeys.forEach((id) =>
        !removingSecurityIds.includes(id) ? (updatedTempIdeas[id] = state.userCreatingIdeas[id]) : false
      );
      return {
        ...state,
        userCreatingIdeas: updatedTempIdeas,
      };
    }

    case REMOVE_IDEA:
      const ideaToRemove = action.payload;
      const prevIdeaList = { ...state.ideaList };
      const updatedIdeaList = convertObjectToArray(prevIdeaList).filter(
        (idea) => idea.idea_id !== ideaToRemove.idea_id
      );
      const updatedIdeaListObj = convertArrayToObject(updatedIdeaList, 'idea_id');
      return {
        ...state,
        ideaList: updatedIdeaListObj,
      };

    default:
      return state;
  }
}
