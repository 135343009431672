import React from 'react';
import Loading from '@src/main/components/login/Loading';
import LoadingIcon from './misc/LoadingIcon';

const PageLoading = (props) => {
  const {
    // pageName,
    size,
    icon,
    hideLoadingText,
    flatTransparent,
  } = props;

  const marginRight = props.marginRight || '0';
  const loadingMessage = 'Loading'; // hardcoded for now
  // const loadingMessage = props.loadingMessage ?
  //   props.loadingMessage
  //   : !!pageName ? `Loading ${ pageName }` : 'Loading'
  const customClassNameWrapper = props.className || '';
  const style = props.style || {};
  const flatStyle = props.flatStyle || flatTransparent;
  const flatTransparentStyle = flatTransparent ? 'transparent-loading' : '';
  const loadingStyle = flatStyle ? 'flat-page-loading-container' : 'page-loading-container';

  const className = `page-loading ${
    !flatTransparent ? 'component-bg' : ''
  } ${customClassNameWrapper} ${loadingStyle} ${flatTransparentStyle}`;
  return (
    <div className={className} style={style}>
      <div>
        {icon ? (
          <LoadingIcon size={size || 'medium'} icon={icon} style={{ marginRight, marginBottom: '10px' }} />
        ) : (
          <Loading hideText />
        )}
        {!hideLoadingText ? <div className="loading-text">{loadingMessage}</div> : null}
        <div className="stretch-parent-height"></div>
      </div>
    </div>
  );
};

export default PageLoading;
