import { consoleError } from './devToolHelpers';

export const parseQueryString = (query) => {
  if (!query || query.length === 0) {
    return {};
  }

  const keyValues = query.split('?')[1];
  const keyValuesArray = keyValues.split('&');
  const parsedQuery = {};

  keyValuesArray.forEach((string) => {
    const key = string.split('=')[0];
    const value = string.split('=')[1];
    parsedQuery[key] = decodeURI(value);
  });

  return parsedQuery;
};

export const createQueryString = (query, options) => {
  const queryArray = [];
  const keys = Object.keys(query);

  if (keys.length === 0 || !keys) {
    return '';
  }

  keys.forEach((key) => {
    const value = query[key];
    queryArray.push(`${key}=${value}`);
  });

  const queryPrefix = options && options.isAppending ? '&' : '?';
  const queryString = queryPrefix + queryArray.join('&');
  return queryString;
};

export const appendToQuery = (appendQuery, queryString) => {
  if (queryString === '') {
    return createQueryString(appendQuery);
  } else {
    const appendQueryString = createQueryString(appendQuery, {
      isAppending: true,
    });
    return queryString + appendQueryString;
  }
};

export const updateUrlQuery = (navigate, location, queryDataToAdd) => {
  // should be react router history and location
  const currentQuery = parseQueryString(location.search);
  const newQuery = {
    ...currentQuery,
    ...queryDataToAdd,
  };
  const query = createQueryString(newQuery);
  return navigate(`${location.pathname}${query}`);
};

export const removeKeyFromUrlQuery = (navigate, location, key) => {
  // should be react router history and location
  const currentQuery = parseQueryString(location.search);
  delete currentQuery[key];
  const queryString = createQueryString(currentQuery);
  return navigate(`${location.pathname}${queryString}`);
};
