import React from 'react';

import SearchContainer from '../../UI/Search/SearchContainer';
import Button from '../../../components/buttons/Button';
import { FlatButton } from '../../../main/components/buttons';
import { logMetricsTrackingEvent } from '../../../actions';
import { ROUTES } from '../../../constants/paths';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useUserOptionsEnabled } from '../../../main/hooks/orders/useUserOptionsEnabled';

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 16px 0;
  width: 100%;
  max-width: 300px;

  .default-search-container .search-results-container {
    top: 49px;
    left: 0px;
    right: 0px;
  }
`;
const TradeActions = styled.div`
  margin: 0 auto;
  max-width: 300px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TradeAction = styled.div`
  button {
    width: 140px;
  }
`;

const Search = ({ handleSearchSelection }) => {
  return (
    <Wrapper className={`search-stock-for-order-container`}>
      <SearchContainer
        id="add-order-security-search"
        customIcon={'fa-plus'}
        autoFocus={true}
        parentComponent="Trade Page"
        placeholder={'Search Stock or ETF'}
        handleSecurityQuerySelection={handleSearchSelection}
        dismissAfterSelection={true}
      />
    </Wrapper>
  );
};

const CreateNewOrder = ({ customClass, btnClassName, handleButtonClick }) => {
  return (
    <div className={`create-order-button-container ${customClass || ''}`}>
      <FlatButton onClick={handleButtonClick} prefixIcon="fa-plus">
        CREATE NEW ORDER
      </FlatButton>
    </div>
  );
};

const TRADE_TYPES = {
  trade: 'trade',
  options: 'options',
};

export const OpenCreateOrderButton = ({ btnClassName, customClass, inBasket, onSearchSelection }) => {
  const navigate = useNavigate();
  const { isEnabled: isOptionsTradingEnabled } = useUserOptionsEnabled();

  const [tradeType, setTradeType] = React.useState(inBasket || !isOptionsTradingEnabled ? TRADE_TYPES.trade : null);
  const [pressedCreateNewOrder, setPressedCreateNewOrder] = React.useState(false);

  const handleCreateNewOrderClick = () => {
    setPressedCreateNewOrder(true);
  };

  const handleSearchSelection = (security) => {
    if (!security) {
      return false;
    }

    if (tradeType === TRADE_TYPES.trade) {
      onSearchSelection(security);
      return;
    }

    if (tradeType === TRADE_TYPES.options) {
      const securityId = security.id || null;
      navigate(ROUTES.TRADE_OPTIONS.build(securityId));
      return;
    }
  };

  const handleTradeClick = () => {
    const event = 'Click Manual Trade';
    const properties = {};
    logMetricsTrackingEvent(event, properties)();

    setTradeType(TRADE_TYPES.trade);
  };

  const handleOptionsClick = () => {
    setTradeType(TRADE_TYPES.options);
  };

  if (isOptionsTradingEnabled) {
    if (tradeType !== null) {
      return <Search handleSearchSelection={handleSearchSelection} />;
    }

    if (pressedCreateNewOrder) {
      return (
        <TradeActions>
          <TradeAction>
            <FlatButton onClick={handleTradeClick}>Trade</FlatButton>
          </TradeAction>
          <TradeAction>
            <FlatButton onClick={handleOptionsClick}>Options</FlatButton>
          </TradeAction>
        </TradeActions>
      );
    }

    return (
      <CreateNewOrder
        customClass={customClass}
        btnClassName={btnClassName}
        handleButtonClick={handleCreateNewOrderClick}
      />
    );
  } else {
    if (tradeType !== null || pressedCreateNewOrder) {
      return <Search handleSearchSelection={handleSearchSelection} />;
    }

    return (
      <CreateNewOrder
        customClass={customClass}
        btnClassName={btnClassName}
        handleButtonClick={handleCreateNewOrderClick}
      />
    );
  }
};

export default OpenCreateOrderButton;
