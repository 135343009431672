import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withWindowSize from '../../HOCS/withWindowSize';
import AnimatedOverlay from '../../../components/layout/AnimatedOverlay';
import Header from './Header';
import Body from './Body';
import { hideVerticalNav } from '../../../actions';
import { withRouter } from '../../../main/utils';
import styled from 'styled-components';

const OuterWrapper = styled.div`
  background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};

  height: 100%;
  min-width: 185px;
  z-index: 1100;
  padding-bottom: 100px;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  will-change: transform;

  &.open {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    transition: all 330ms ease-out;
  }
  &.closed {
    -webkit-transform: translateX(-103%);
    transform: translateX(-103%);
    transition: all 130ms ease-in;
  }
`;
const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.themeColors.text};
  }
`;

const MobileVerticalNav = (props) => {
  const { location } = props;

  const banner = useSelector((state) => state.banner);
  const nav = useSelector((state) => state.nav);
  const dispatch = useDispatch();
  const { collapsed, alwaysOpen } = nav;

  const isClosed = collapsed && !alwaysOpen;

  const isBannerShowing = false;

  const collapseNav = () => {
    if (!alwaysOpen) {
      hideVerticalNav()(dispatch);
    }
  };

  return (
    <AnimatedOverlay showOverlay={!isClosed} handleClick={collapseNav} customClass={'nav-overlay'}>
      <OuterWrapper
        className={`
          ${isClosed ? 'closed' : 'open'}
        `}
      >
        <Header />
        <Body />
      </OuterWrapper>
    </AnimatedOverlay>
  );
};

export default withWindowSize(withRouter(MobileVerticalNav));
