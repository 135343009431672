import { portfolioAdapter } from '../adapters/portfolioAdapter';
import { quickFetchSecuritiesData } from './securitiesActions';

import {
  ADD_PORTFOLIO_DATA, // Made in current user
  ADD_USER_PORTFOLIO_DATA,
  START_LOADING_PORTFOLIO_DATA,
  USER_PORTFOLIO_DATA_ERROR,
} from '../constants';

// this does not get current user portfolio, see current user actions
export const fetchUserPortfolio = (userId) => {
  return function (dispatch) {
    dispatch({
      type: START_LOADING_PORTFOLIO_DATA,
      payload: { userId },
    });
    return portfolioAdapter.fetchUserPortfolio(userId).then((response) => {
      const wasResponseSuccessful = response && response.data && response.data.users;
      if (wasResponseSuccessful) {
        const user = response.data.users[0];
        const portfolioData = user.positions;
        if (portfolioData) {
          const securityIds = Object.keys(portfolioData);
          quickFetchSecuritiesData(securityIds)(dispatch);
        }
        dispatch({
          type: ADD_USER_PORTFOLIO_DATA,
          payload: {
            userId,
            portfolioData,
          },
        });
      } else {
        const error = 'Something went wrong';
        dispatch({
          type: USER_PORTFOLIO_DATA_ERROR,
          payload: {
            userId,
            error,
          },
        });
      }
      return response;
    });
  };
};
