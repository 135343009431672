import { consoleError } from './devToolHelpers';

/*

  HELPERS FOR GETTING DATA OUT OF USER CONNECTIONS STORE

  STORE DATA STRUCT:

  store = {
    userDict: {
      userDict: {
        if no userId connection data is not loading
        [userId]: {
          if no connections key but userId is available its loading
          connections: [<array of userIds>]
        }
      }
    }
  }

 */

export const returnConnectionDataUserLookup = (userConnectionsStore) => userConnectionsStore.userDict;

export const isConnectionsDataLoadingForUser = (userId, userConnectionsStore) => {
  const lookup = returnConnectionDataUserLookup(userConnectionsStore);
  return userId in lookup && !lookup[userId].connections;
};

export const isConnectionsDataAvailableForUser = (userId, userConnectionsStore) => {
  const lookup = returnConnectionDataUserLookup(userConnectionsStore);
  return lookup[userId] && lookup[userId].connections;
};

const _isDataUnavailable = (userId, userConnectionsStore) =>
  !isConnectionsDataAvailableForUser(userId, userConnectionsStore);

const _recordDataUnavailableMessage = (userId, userConnectionsStore) =>
  consoleError('User connection data was not available in store', {
    userId,
    userConnectionsStore,
  });

export const returnConnectionDataForUser = (userId, userConnectionsStore) => {
  if (_isDataUnavailable(userId, userConnectionsStore)) {
    _recordDataUnavailableMessage(userId, userConnectionsStore);
  }
  const lookup = returnConnectionDataUserLookup(userConnectionsStore);
  return lookup[userId].connections;
};

export const returnConnectionCountForUser = (userId, userConnectionsStore) => {
  return returnConnectionDataForUser(userId, userConnectionsStore).length;
};

export const doesUserHaveConnections = (userId, userConnectionsStore) => {
  return returnConnectionCountForUser(userId, userConnectionsStore) > 0;
};
