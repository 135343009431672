import { INCREMENT_HORIZONTAL_LIST, DECREMENT_HORIZONTAL_LIST, RESET_HORIZONTAL_LIST } from '../constants';

const defaultState = {
  page: 1,
};

export default function horizontalListReducer(state = defaultState, action) {
  switch (action.type) {
    case INCREMENT_HORIZONTAL_LIST:
      return {
        ...state,
        page: state.page + 1,
      };

    case DECREMENT_HORIZONTAL_LIST:
      return {
        ...state,
        page: state.page - 1,
      };

    case RESET_HORIZONTAL_LIST:
      return {
        ...state,
        page: 1,
      };

    default:
      return state;
  }
}
