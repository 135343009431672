import styled from 'styled-components';
import React from 'react';
import { CheckCircle, Body5 } from '../../lib/nvstr-common-ui.es';
import { Spacing } from '../layout';

const CheckBulletRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 16px;
`;
const IconWrapper = styled.div`
  path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

export const CheckBullet = ({ text }) => {
  return (
    <CheckBulletRow style={{ maxWidth: 240, width: '100%' }}>
      <IconWrapper>
        <CheckCircle width={16} />
      </IconWrapper>
      <Spacing left={8}>
        <Body5>{text}</Body5>
      </Spacing>
    </CheckBulletRow>
  );
};
