import React from 'react';
import { SkeletonBannerActionsUnder } from './templates/SkeletonBannerActionsUnder';
import { ROUTES } from '../../../constants/paths';
import { useNavigate } from 'react-router-dom';
import { useIsLiveTrading } from '../../hooks/user';
import { Bank } from '../../../main/lib/nvstr-common-ui.es';
import { IconSkeletonBannerActionsUnder } from './templates/IconSkeletonBannerActionsUnder';

const liveGroupOne = {
  id: 1,
  message: 'For a limited time, get a funding bonus (Up to $1,000) when you make your first deposit',
  subMessage: 'Rules apply, press Continue for details.',
  ctaButtonText: 'Continue',
  route: ROUTES.FUNDING.build(),
};

const paperTradingAltGroupOne = {
  id: 2,
  message:
    'For a limited time, get a funding bonus (Up to $1,000) when you open a live trading account and make your first deposit',
  subMessage: 'Rules apply, press Get Started for details.',
  ctaButtonText: 'Get Started',
  route: ROUTES.LIVE_TRADING_SIGNUP_START.build(),
};

export const FundingBonusBanner = (props) => {
  const navigate = useNavigate();
  const isLiveTrading = useIsLiveTrading();
  const [testGroup] = React.useState(isLiveTrading ? liveGroupOne : paperTradingAltGroupOne);

  const extendedHandleCTAClick = () => {
    const eventProperties = {
      'Test Group Id': testGroup.id,
    };
    navigate(testGroup.route);

    props.onCTAClick(eventProperties);
  };

  return (
    <IconSkeletonBannerActionsUnder
      {...props}
      Icon={<Bank />}
      message={testGroup.message}
      subMessage={testGroup.subMessage}
      onCTAClick={extendedHandleCTAClick}
      ctaButtonText={testGroup.ctaButtonText}
    />
  );
};
