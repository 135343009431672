import React from 'react';
import styled from 'styled-components';
import { colorPalette } from '../lib/nvstr-utils.es';
import { defineFontSettings, Body1, H1 } from '../lib/nvstr-common-ui.es';
import { FlatButton, SkeletonButton } from '../components/buttons';
import LearnCarousel from '../components/learn/LearnCarousel';
import { Text } from '../components/text';
import { Spacing } from '../components/layout';
import { LearnRewardEarned } from '../components/learn/LearnRewardEarned';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../constants/paths';
import { parseQueryString } from '../../helpers/routerHelpers';
import { useSelector } from 'react-redux';
import { useNextLesson } from '../hooks/learn/useNextLesson';
import LoadingSpinner from '../components/Images/LoadingSpinner';
import { isUndefinedOrNull } from '../../helpers/usefulFuncs';
import { useLearnLesson } from '../hooks/learn/useLearnLesson';
import { LearnScreen } from '../components/learn';
import { useLearnLessonState } from '../hooks/learn/useLearnLessonState';
import { LearnDeposit } from '../containers/learn/customLessons/LearnDeposit';
import { LearnPublicLessonComplete } from '../components/learn/LearnPublicLessonComplete';
import { slugifyLessonName } from '../utils/learn';
import { LEARN_STATUS_MESSAGES } from '../constants/learn';
import { LEARN_LESSON_TYPES, CUSTOM_LEARN_LESSON_COMPONENT_TYPES } from '../constants/learn/types';
import { LEARN_LESSON_NAME_LOOKUP } from '../constants/learn/lessonDefinitions';
import { Container } from '../components/ui';
import { tornadoColorPalette } from '../constants/colorPalettes/tornado';
import LearnAndEarnMoreInfo from '@src/main/pages/LearnAndEarnMoreInfo';
import { useSearchParams } from 'react-router-dom';
import { TrackingEvent } from '@src/main/lib/nvstr-utils.es';

const LoadingSpinnerWrapper = styled.div`
  text-align: center;

  @keyframes spin-cycle {
    from {
      fill: ${tornadoColorPalette.primary.charcoal};
      transform: scale(1);
    }
    to {
      fill: rgba(255, 255, 255, 0);
      transform: scale(1);
    }
  }
`;

const componentTypeToComponentLookup = {
  [CUSTOM_LEARN_LESSON_COMPONENT_TYPES.learnDeposit]: LearnDeposit,
};

const LoadingComponent = () => {
  const textColor = colorPalette.primary.charcoal;
  return (
    <LearnScreen>
      <Container top={100}>
        <LoadingSpinnerWrapper>
          <LoadingSpinner />
        </LoadingSpinnerWrapper>

        <div style={{ textAlign: 'center' }}>
          <Body1 color={textColor}>Loading Lesson...</Body1>
        </div>
        <div style={{ height: '300px' }} />
      </Container>
    </LearnScreen>
  );
};

const GettingRewardProgress = () => {
  const textColor = colorPalette.primary.charcoal;

  const fontSettings = defineFontSettings(
    {
      fontSize: '18px',
      lineHeight: '24px',
    },
    {
      fontSize: '36px',
      lineHeight: '42px',
    }
  );

  return (
    <LearnScreen>
      <Container top={24} style={{ textAlign: 'center' }}>
        <Container>
          <LoadingSpinnerWrapper>
            <LoadingSpinner />
          </LoadingSpinnerWrapper>
        </Container>
        <div>
          <Text fontSettings={fontSettings} bold center color={textColor}>
            {'Checking Reward Progress...'}
          </Text>
        </div>
      </Container>

      <div style={{ textAlign: 'center', padding: '148px 0 188px 0' }}>
        <div>
          <Body1 color={textColor}>Keep learning and earning on Tornado.</Body1>
        </div>
        <div style={{ padding: '4px 0 0 0' }}>
          <Body1 color={textColor} bold>
            Invest in yourself, and we will too.
          </Body1>
        </div>
      </div>
    </LearnScreen>
  );
};

const LessonLimitReached = ({ status }) => {
  const navigate = useNavigate();
  const textColor = colorPalette.primary.charcoal;
  const hoverTextColor = colorPalette.primary.oak;

  const defaultMessage = 'Come back tomorrow for more lessons!';
  const message = LEARN_STATUS_MESSAGES[status];

  React.useEffect(() => {
    TrackingEvent.create('View Lesson Cooldown', {
      Message: message,
    });
  }, []);

  const handleClick = () => {
    navigate(ROUTES.DASHBOARD.build());
  };

  return (
    <LearnScreen>
      <Container flex={1} top={24} column fullWidth>
        <Container>
          <H1 bold color={textColor}>
            Learn
          </H1>
        </Container>
        <div>
          <H1 bold color={textColor}>
            & Earn
          </H1>
        </div>

        <Container top={48}>
          <Body1 color={textColor}>{message || defaultMessage}</Body1>
        </Container>
      </Container>
      <Container vertical={16} fullWidth centerAll>
        <SkeletonButton
          color={colorPalette.primary.charcoal}
          hoverTextColor={hoverTextColor}
          onClick={handleClick}
          fullWidth
        >
          Got it
        </SkeletonButton>
      </Container>
    </LearnScreen>
  );
};

const MissingScreenContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const MissingScreenData = ({ lessonId }) => {
  const fontSettings = defineFontSettings(
    {
      fontSize: '36px',
      lineHeight: '48px',
    },
    {
      fontSize: '48px',
      lineHeight: '54px',
    }
  );
  const messageFontSettings = defineFontSettings(
    {
      fontSize: '18px',
      lineHeight: '28px',
    },
    {
      fontSize: '18px',
      lineHeight: '28px',
    }
  );

  const textColor = colorPalette.primary.charcoal;
  const refreshMessage = 'Refresh the page to get the latest Learn & Earn content.';

  React.useEffect(() => {
    TrackingEvent.create('View Lesson Missing', {
      'Lesson ID': lessonId,
    });
  }, []);

  const refreshPage = () => window?.location?.reload();
  return (
    <LearnScreen>
      <MissingScreenContent>
        <Spacing top={36}>
          <Text fontSettings={fontSettings} bold color={textColor}>
            Learn
          </Text>
        </Spacing>
        <Spacing top={0}>
          <Text fontSettings={fontSettings} bold color={textColor}>
            & Earn
          </Text>
        </Spacing>
        <Spacing top={48} bottom={48}>
          <Text fontSettings={messageFontSettings} color={textColor}>
            {refreshMessage}
          </Text>
        </Spacing>
      </MissingScreenContent>

      <Container centerAll fullWidth>
        <FlatButton
          fullWidth
          color={colorPalette.primary.charcoal}
          textColor={colorPalette.primary.oak}
          onClick={refreshPage}
        >
          Refresh
        </FlatButton>
      </Container>
    </LearnScreen>
  );
};

// HELPERS END -------------------

const LearnLessonSelector = () => {
  const navigate = useNavigate();

  const lessonNameLookup = useSelector((state) => state.learn.lessonNameLookup);
  const { nextLessonId, status, areNoMoreLessons, fetchNextLesson } = useNextLesson();

  const [lessonId, setLessonId] = React.useState(null);
  const [isReady, setIsReady] = React.useState(false);
  const [isOnCooldown, setIsOnCooldown] = React.useState(false);
  const [isUsingNextLessonId, setIsUsingNextLessonId] = React.useState(false);

  React.useEffect(() => {
    const { location } = window;
    const search = location?.search || '';
    const query = parseQueryString(search);
    const lessonName = query?.lesson;

    if (lessonName) {
      const lessonId = lessonNameLookup[slugifyLessonName(lessonName)];
      if (lessonId) {
        setLessonId(lessonId);
        setIsReady(true);
      }
    } else {
      setIsUsingNextLessonId(true);

      if (!isUndefinedOrNull(nextLessonId)) {
        setLessonId(nextLessonId);
        setIsReady(true);
      } else {
        const loadingUIDelay = 200;
        setTimeout(fetchNextLesson, loadingUIDelay);
      }
    }
  }, []);

  // handle next lesson api call update
  React.useEffect(
    () => {
      if (isUsingNextLessonId && !isReady && !lessonId && nextLessonId) {
        setLessonId(nextLessonId);
        setIsReady(true);
      }
    },
    [isUsingNextLessonId, nextLessonId]
  );

  // handle lesson cooldown
  React.useEffect(
    () => {
      if (status !== null && !isReady) {
        setIsOnCooldown(true);
        setIsReady(true);
      }
    },
    [status, isReady]
  );

  // handle out of lessons
  React.useEffect(
    () => {
      if (areNoMoreLessons && !isReady) {
        navigate(ROUTES.LEARN_AND_EARN_PROGRESS.build());
      }
    },
    [areNoMoreLessons, isReady]
  );

  if (!isReady) {
    return <LoadingComponent />;
  }

  if ((!lessonId && status !== null) || (!lessonId && isOnCooldown)) return <LessonLimitReached status={status} />;

  return <LearnLesson lessonId={lessonId} />;
};

export const PublicLearnLessonSelector = ({ name: lessonName }) => {
  const lessonSlug = slugifyLessonName(lessonName);

  const [lessonId, setLessonId] = React.useState(null);
  const [isReady, setIsReady] = React.useState(false);
  const [isNotFound, setIsNotFound] = React.useState(false);

  React.useEffect(() => {
    const lessonId = LEARN_LESSON_NAME_LOOKUP[lessonSlug];
    if (lessonId) {
      setLessonId(lessonId);
      setIsReady(true);
    } else {
      setIsNotFound(true);
      setIsReady(true);
    }
  }, []);

  if (!isReady) {
    return <LoadingComponent />;
  }

  if (isNotFound) {
    return <div>Lesson does not exist</div>;
  }

  return <PublicLearnLesson lessonId={lessonId} />;
};

const LearnLessonController = (props) => {
  const { lessonId, lessonData, isPublic } = props;

  const [searchParams] = useSearchParams();
  const showMoreInfo = searchParams.get('showMoreInfo');

  const {
    rewardAmount,
    earnedXPOnly,
    lockedRewardsAmount,
    completedLessonCount,
    completedPublicLesson,
    isCompletingLesson,
    lessonReward,
    onComplete,
  } = useLearnLessonState(lessonId, isPublic);

  const { lessonType, lessonContents, componentType } = lessonData;

  if (
    (lessonType === LEARN_LESSON_TYPES.text && !lessonContents) ||
    (lessonType === LEARN_LESSON_TYPES.custom && !componentType)
  ) {
    window.location = ROUTES.DASHBOARD.build();
    return null;
  }

  if (completedPublicLesson) {
    return <LearnPublicLessonComplete lessonId={lessonId} />;
  }

  if (rewardAmount !== null || earnedXPOnly !== null) {
    const forceState = {
      // rewardAmount: 4,
      // lockedRewardsAmount: 42,
    };
    return (
      <LearnRewardEarned
        lessonId={lessonId}
        rewardAmount={rewardAmount}
        lockedRewardsAmount={lockedRewardsAmount}
        completedLessonCount={completedLessonCount}
        {...forceState}
      />
    );
  }

  if (isCompletingLesson) {
    return <GettingRewardProgress />;
  }

  if (!lessonType) {
    return <MissingScreenData lessonId={lessonId} />;
  }

  if (lessonType === LEARN_LESSON_TYPES.custom) {
    const Component = componentTypeToComponentLookup[componentType];
    return <Component lessonId={lessonId} lessonReward={lessonReward} onComplete={onComplete} />;
  }

  return (
    <LearnScreen>
      {showMoreInfo && <LearnAndEarnMoreInfo lessonId={lessonId} />}
      <LearnCarousel lessonId={lessonId} lessonReward={lessonReward} onComplete={onComplete} />
    </LearnScreen>
  );
};

const PublicLearnLesson = ({ lessonId }) => {
  const { isLoading, lesson: lessonData } = useLearnLesson(lessonId);

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (!lessonData) {
    return <MissingScreenData lessonId={lessonId} />;
  }

  return <LearnLessonController lessonId={lessonId} isPublic />;
};

const LearnLesson = ({ lessonId }) => {
  const { isLoading, lesson: lessonData } = useLearnLesson(lessonId, true);
  if (isLoading) {
    return <LoadingComponent />;
  }

  if (!lessonData) {
    return <MissingScreenData lessonId={lessonId} />;
  }

  return <LearnLessonController lessonData={lessonData} lessonId={lessonId} />;
};

export default LearnLessonSelector;
