import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const plaidAdapter = {
  getPlaidConfigFromApi: () => {
    const options = { ignoreAuth: true };
    return sendRequest('get', [`${URL}/live_accounts/funding/instant/config`], options).then((response) => response);
  },

  getPlaidLinkToken: () => {
    const options = { ignoreAuth: false };
    return sendRequest('get', [`${URL}/v1/bank_accounts/link_token`], options).then((response) => response);
  },
};
