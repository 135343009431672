import { isUndefinedOrNull } from './generalHelpers';

export const consoleLog = function (params) {
  // return null; // use to disable to simulate prod performance better

  // do not log if in production
  let baseUrl = window.origin;
  if (!baseUrl) {
    baseUrl = window.location.origin;
  }
  if (!baseUrl) {
    baseUrl = 'http://localhost:3000';
  }
  if (baseUrl !== 'http://localhost:3000' && baseUrl !== 'https://d1-forerunner.nvstr.com') {
    return false;
  }

  // if params are not an array make it one
  if (!Array.isArray(params)) {
    const argArray = [];

    for (let i = 0; i < arguments.length; i++) {
      const el = arguments[i];
      argArray.push(el);
    }
    params = argArray;
  }

  const uniqueObj = params.map((param) => {
    if (typeof param === 'object') {
      try {
        const stringObj = JSON.stringify(param);
        return JSON.parse(stringObj);
      } catch (error) {
        // catches infinite reference loops
        return 'Could not log, caught: ' + error.message;
      }
    } else {
      // if not an object no need to clone
      return param;
    }
  });
  console.log(
    // params, // original -> has function data
    uniqueObj // cloned -> prevents some inaccuracies due to mutations
  );
};

export const consoleError = function (params) {
  // do not log if in production
  let baseUrl = window.origin;
  if (!baseUrl) {
    baseUrl = window.location.origin;
  }
  if (!baseUrl) {
    baseUrl = 'http://localhost:3000';
  }
  if (baseUrl !== 'http://localhost:3000' && baseUrl !== 'https://d1-forerunner.nvstr.com') {
    return false;
  }
  // if params are not an array make it one
  if (!Array.isArray(params)) {
    const argArray = [];

    for (let i = 0; i < arguments.length; i++) {
      const el = arguments[i];
      argArray.push(el);
    }
    params = argArray;
  }
  console.error(params);
};

const ReactClassComponent = 2;
function traverseRenderedChildren(internalInstance, callback, argument) {
  callback(internalInstance, argument);

  if (internalInstance._renderedComponent) {
    traverseRenderedChildren(internalInstance._renderedComponent, callback, argument);
  } else {
    for (const key in internalInstance._renderedChildren) {
      if (internalInstance._renderedChildren.hasOwnProperty(key)) {
        traverseRenderedChildren(internalInstance._renderedChildren[key], callback, argument);
      }
    }
  }
}

function setPendingForceUpdate(internalInstance, shouldUpdate) {
  if (internalInstance._pendingForceUpdate === false && shouldUpdate(internalInstance)) {
    internalInstance._pendingForceUpdate = true;
  }
}

function forceUpdateIfPending(internalInstance, onUpdate) {
  if (internalInstance._pendingForceUpdate === true) {
    const publicInstance = internalInstance._instance;
    const { updater } = publicInstance;

    if (typeof publicInstance.forceUpdate === 'function') {
      publicInstance.forceUpdate();
    } else if (updater && typeof updater.enqueueForceUpdate === 'function') {
      updater.enqueueForceUpdate(publicInstance);
    }
    onUpdate(internalInstance);
  }
}

function _unsafe__deepForceUpdateStack(instance, shouldUpdate, onUpdate) {
  const internalInstance = instance._reactInternalInstance;
  traverseRenderedChildren(internalInstance, setPendingForceUpdate, shouldUpdate);
  traverseRenderedChildren(internalInstance, forceUpdateIfPending, onUpdate);
}

export default function _unsafe__deepForceUpdate(instance, shouldUpdate = () => true, onUpdate = () => {}) {
  const root = instance._reactInternalFiber || instance._reactInternalInstance;
  if (typeof root.tag !== 'number') {
    // Traverse stack-based React tree.
    return _unsafe__deepForceUpdateStack(instance, shouldUpdate, onUpdate);
  }

  let node = root;
  while (true) {
    if (node.tag === ReactClassComponent && shouldUpdate(node)) {
      const publicInstance = node.stateNode;
      const { updater } = publicInstance;
      if (typeof publicInstance.forceUpdate === 'function') {
        publicInstance.forceUpdate();
      } else if (updater && typeof updater.enqueueForceUpdate === 'function') {
        updater.enqueueForceUpdate(publicInstance);
      }
      onUpdate(node);
    }
    if (node.child) {
      node.child.return = node;
      node = node.child;
      continue;
    }
    if (node === root) {
      return undefined;
    }
    while (!node.sibling) {
      if (!node.return || node.return === root) {
        return undefined;
      }
      node = node.return;
    }
    node.sibling.return = node.return;
    node = node.sibling;
  }
}

export const deepDiffMapper = {
  VALUE_CREATED: 'created',
  VALUE_UPDATED: 'updated',
  VALUE_DELETED: 'deleted',
  VALUE_UNCHANGED: 'unchanged',
  map: function (obj1, obj2) {
    if (this.isFunction(obj1) || this.isFunction(obj2)) {
      throw 'Invalid argument. Function given, object expected.';
    }
    if (this.isValue(obj1) || this.isValue(obj2)) {
      return {
        type: this.compareValues(obj1, obj2),
        data: obj1 === undefined ? obj2 : obj1,
      };
    }

    var diff = {};
    for (var key in obj1) {
      if (this.isFunction(obj1[key])) {
        continue;
      }

      var value2 = undefined;
      if ('undefined' != typeof obj2[key]) {
        value2 = obj2[key];
      }

      diff[key] = this.map(obj1[key], value2);
    }
    for (var key in obj2) {
      if (this.isFunction(obj2[key]) || 'undefined' != typeof diff[key]) {
        continue;
      }

      diff[key] = this.map(undefined, obj2[key]);
    }

    return diff;
  },
  compareValues: function (value1, value2) {
    if (value1 === value2) {
      return this.VALUE_UNCHANGED;
    }
    if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
      return this.VALUE_UNCHANGED;
    }
    if ('undefined' == typeof value1) {
      return this.VALUE_CREATED;
    }
    if ('undefined' == typeof value2) {
      return this.VALUE_DELETED;
    }

    return this.VALUE_UPDATED;
  },
  isFunction: function (obj) {
    return {}.toString.apply(obj) === '[object Function]';
  },
  isArray: function (obj) {
    return {}.toString.apply(obj) === '[object Array]';
  },
  isDate: function (obj) {
    return {}.toString.apply(obj) === '[object Date]';
  },
  isObject: function (obj) {
    return {}.toString.apply(obj) === '[object Object]';
  },
  isValue: function (obj) {
    return !this.isObject(obj) && !this.isArray(obj);
  },
};

export const throwError = (message, includeStackTrace, additionalData) => {
  let stackTrace = '';
  if (includeStackTrace) {
    const err = new Error();
    stackTrace = err.stack;
  }
  const errorMessage = includeStackTrace ? `${message}, ${stackTrace}` : `${message}`;
  if (additionalData) {
    throw {
      message: errorMessage,
      data: additionalData,
    };
  } else {
    throw errorMessage;
  }
};

export const createAsyncAction = (timeout, shouldSucceed, handleSuccess, handleFailure) => {
  return new Promise((res) => {
    return setTimeout(() => {
      return res(shouldSucceed ? handleSuccess() : handleFailure());
    }, timeout);
  });
};

export const failIfUndefinedOrNull = (varList) => {
  return varList.some((el) => isUndefinedOrNull(el));
};
