import React from 'react';
import { connect } from 'react-redux';

import LoadingIcon from '../../components/misc/LoadingIcon';
import RebalanceAllocInput from './RebalanceAllocInput';
import Button from '../../components/buttons/Button';

import { securityDataFormatTable } from '../../helpers/securitiesHelpers';

const RefreshRebalancerDataButton = (props) => {
  return (
    <div
      className="rebalancer-refresh-button-container"
      style={{
        textAlign: 'center',
        marginTop: '5px',
      }}
    >
      <Button
        customClass={'btn btn-secondary-color btn-small'}
        prefixIcon={'fa-refresh'}
        text={'Refresh Analysis'}
        handleClick={props.onClick}
      />
    </div>
  );
};

class RebalancerAllocationsTable extends React.PureComponent {
  constructor() {
    super();
    this.ROUND_ALLOC_PERCENT_DECIMAL_PLACE = 1;
  }

  render() {
    return (
      <div className={'wrapper'} style={{ margin: '0 auto' }}>
        <div className={'wrapper'} style={{ margin: '0 auto' }}>
          <div className={'optimized-portfolio-list-container rebalancer-portfolio-list-container'}>
            <div className={'heading secondary-heading-text-color'}>Rebalanced Allocations</div>
            {this._isRebalancerAllocationTableLoading() ? (
              <div className={'optimizer-panel-optimized-allocation text-center'}>
                <LoadingIcon icon="fading-3balls" size="small" style={{ marginRight: '8px' }} />
              </div>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th className="security-symbol"></th>
                    <th className="security-allocation secondary-text-color">Current</th>
                    <th className="security-allocation optimized optimizer-emphasized-text-color">Rebalanced</th>
                  </tr>
                </thead>
                <tbody>
                  {this._getSortedListOfSecurities().map((security) => this._renderRow(security))}
                  <tr>
                    <td style={{ padding: '11px' }}></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          <div className={'optimizer-panel-transparent-gradient rebalancer-panel-transparent-gradient to-bottom'}></div>
        </div>
        {this.props.rebalancerErrorMessage && (
          <div
            style={{
              paddingBottom: '8px',
              textAlign: 'center',
              maxWidth: '200px',
              margin: '0 auto',
              fontSize: '12px',
            }}
          >
            <span className="error-text-color">{this.props.rebalancerErrorMessage}</span>
          </div>
        )}
        <RefreshRebalancerDataButton onClick={this.props.runRebalancer} />
      </div>
    );
  }

  _renderRow = (security) => {
    const allocationPercent = this._returnPortfolioAllocationPercent(security.security_id);
    const rebalancedAllocationPercent = this._returnRebalancerPortfolioAllocationPercent(security.security_id);
    return (
      <tr
        key={`optimized-allocation-${security.security_id}`}
        className={'optimizer-panel-optimized-allocation  rebalancer-allocation'}
      >
        <td className={'security-symbol'}>{security.symbol}</td>
        <td className={`security-allocation ${allocationPercent === '-' && 'pad-right'}`}>{allocationPercent}</td>
        <td className={`security-allocation optimized ${rebalancedAllocationPercent === '-' ? 'pad-right' : ''}`}>
          <RebalanceAllocInput
            securityId={security.security_id}
            handleChange={this.props.handleRebalancerAllocationChange}
            value={rebalancedAllocationPercent}
            roundToDecimalPlace={this.ROUND_ALLOC_PERCENT_DECIMAL_PLACE}
          />
        </td>
      </tr>
    );
  };

  getRebalancedAllocationData = () => {
    const data = this.props.rebalancer.data;
    return data.rebalanced_positions_by_security_id;
  };

  _getSortedListOfSecurities = () => this._getSortedListOfSecuritiesByAlphabetical();

  _getSortedListOfSecuritiesByAlphabetical = () => {
    const rebalancedAllocationData = this.getRebalancedAllocationData();
    const securityIds = Object.keys(rebalancedAllocationData);
    const securitySymbolLookup = {};
    securityIds.forEach((id) => {
      securitySymbolLookup[id] = rebalancedAllocationData[id].symbol;
    });
    const sortedIds = securityIds.sort((a, b) => {
      if (securitySymbolLookup[a] < securitySymbolLookup[b]) return -1;
      if (securitySymbolLookup[a] > securitySymbolLookup[b]) return 1;
      return 0;
    });
    return sortedIds.map((id) => rebalancedAllocationData[id]);
  };

  _getSortedListOfSecuritiesByHighestRebalancedAllocations = () => {
    const rebalancedAllocationData = this.getRebalancedAllocationData();
    const listOfSecurityIdsInRebalancedAllocation = Object.keys(rebalancedAllocationData);
    const listOfSecuritiesInRebalancedAllocation = listOfSecurityIdsInRebalancedAllocation.map(
      (id) => rebalancedAllocationData[id]
    );
    const securitiesSortedByHighestAllocation = listOfSecuritiesInRebalancedAllocation.sort(
      (a, b) => Math.abs(b.allocation_percent) - Math.abs(a.allocation_percent)
    );
    return securitiesSortedByHighestAllocation;
  };

  _returnPortfolioAllocationPercent = (securityId) => {
    const positions = this.props.portfolio.positions;
    const allocationValue = positions[securityId] ? positions[securityId].allocation_percent : 0;
    return allocationValue === 0
      ? '-'
      : securityDataFormatTable.percentage(allocationValue, {
          decimalPlace: this.ROUND_ALLOC_PERCENT_DECIMAL_PLACE,
        });
  };

  _returnRebalancerPortfolioAllocationPercent = (securityId) => {
    const { rebalanceAllocationsLookup } = this.props;
    const EMPTY_VALUE = ' - ';

    if (rebalanceAllocationsLookup === null) return EMPTY_VALUE;

    const allocationValue = rebalanceAllocationsLookup[securityId];

    if (isNaN(allocationValue) && typeof allocationValue !== 'string') return EMPTY_VALUE;

    return allocationValue;
  };

  _isRebalancerAllocationTableLoading = () =>
    this.props.portfolio.loading || this.props.rebalanceAllocationsLookup === null;
}

const mapStateToProps = (state) => {
  return {
    rebalancer: state.rebalancer,
    portfolio: state.portfolio,

    ideaList: state.ideas.ideaList,
    currentUserId: state.currentUser.user_id,
  };
};

export default connect(mapStateToProps)(RebalancerAllocationsTable);
