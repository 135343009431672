import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { ROUTES } from '../../../constants/paths';

import { Page, Spacing } from '../../components/layout';
import {
  H3,
  Body5,
  createDropdownItem,
  FloatingLabelDropdown,
  FloatingLabelTextInput,
  INPUT_MASK_TYPES,
  Body1,
} from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { useBankAccounts } from '../../hooks/funding/useBankAccounts';
import { CheckCircleFilled } from '../../icons/svg/CheckCircleFilled';
import UnstyledLoadingSpinner from '../../components/Images/UnstyledLoadingSpinner';
import { formatForDisplay } from '../../../helpers/displayHelpers';
import { getBankAccounts, showModal, withdrawFunds } from '../../../actions';
import { useWithdrawalFunds } from '../../hooks/funding/useWithdrawalFunds';
import { customParseFloat } from '../../../helpers/numberHelpers';
import { toTitleCase } from '../../../helpers/generalHelpers';
import { LoadingPage } from '../../components/page';
import { useNavigate } from 'react-router-dom';
import { BANK_ACCOUNT_FILTER_TYPES, filterBankAccounts } from '../../utils';
import { Container } from '@src/main/components/ui';

const Content = styled.div`
  h3 {
    margin: 0;
  }
`;
const ActionsWrapper = styled.div`
  padding-top: 24px;
  text-align: center;
`;
const InputWrapper = styled.div`
  padding-top: 16px;

  select {
    border: none;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.themeColors.text};
  }

  input {
    border: none;

    -webkit-appearance: auto;
    background: transparent !important;
  }
`;
const TransferCompletedWrapper = styled.div`
  text-align: center;
`;
const TransferCompletedIconWrapper = styled.div`
  svg {
    height: 36px;
    width: 36px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const LoadingBankAccountsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  svg {
    height: 16px;
    width: 16px;
  }
`;
const AvailableFundsLoadingIconWrapper = styled.span`
  svg {
    height: 16px;
    width: 16px;
  }
`;
const SeeAvailableFundsDetailsWrapper = styled.div`
  button {
    padding: 16px 0;
  }
`;
const UnavailableFundsListWrapper = styled.div`
  padding-top: 24px;

  overflow: auto;
`;
const AvailableFundReasonWrapper = styled.div`
  padding: 16px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const massageFormData = (v) => {
  return {
    ...v,
    amount: customParseFloat(v.amount),
  };
};

const AvailableFundReason = ({ reason }) => {
  const { amount, available_on, reason: message } = reason;

  return (
    <AvailableFundReasonWrapper>
      <Row>
        <Body5 bold>Amount: </Body5>
        <Body5>{formatForDisplay(amount, 'price')}</Body5>
      </Row>

      <Row>
        <Body5 bold>Reason unavailable: </Body5>
        <Body5>{toTitleCase(message)}</Body5>
      </Row>
    </AvailableFundReasonWrapper>
  );
};

const AvailableFundsDetails = ({ reasons }) => {
  return (
    <Content>
      <H3>Funding</H3>
      <div>
        <Body5>Funds listed below are not eligible to be withdrawn at this time.</Body5>
      </div>
      <UnavailableFundsListWrapper>
        {reasons.map((r, i) => (
          <AvailableFundReason key={'reason -' + i} reason={r} />
        ))}
      </UnavailableFundsListWrapper>
    </Content>
  );
};

export const WithdrawFunds = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bankAccounts = useBankAccounts();
  const {
    isLoading: isLoadingAvailableFunds,
    fundsAvailable: amountAvailableToWithdraw,
    fundsAvailableByAccountId,
    reasons: fundsAvailableReasons,
  } = useWithdrawalFunds();

  const [isReady, setIsReady] = React.useState(false);
  const [isTransferCompleted, setTransferCompleted] = React.useState(false);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const [value, setValue] = React.useState('');
  const [bankAccountId, setBankAccountId] = React.useState('');
  const [fundingOperation] = React.useState('withdraw');

  const [isPendingWarning, setIsPendingWarning] = React.useState(null);
  const [warningMessage, setWarningMessage] = React.useState(null);

  const bankAccountSelectedAvailableToWithdraw = fundsAvailableByAccountId[bankAccountId];

  React.useEffect(() => {
    const init = async () => {
      const { ok, data } = await getBankAccounts()(dispatch);
      if (ok) {
        const depositReadyBankAccounts = filterBankAccounts(data.accounts, BANK_ACCOUNT_FILTER_TYPES.depositReady);
        if (depositReadyBankAccounts.length === 1) {
          setBankAccountId(depositReadyBankAccounts[0].account_id);
        }
        setIsReady(true);
      } else {
        setTimeout(init, 1000);
      }
    };
    init();
  }, []);

  const handleChange = (n) => (v) => {
    setValue(v);
  };

  const handleBankAccountChange = (n) => (v) => {
    setBankAccountId(v);
  };

  const submitForm = React.useCallback(
    (values, acceptWarning) => {
      const apiFormData = massageFormData(values);
      setIsSubmitting(true);
      return withdrawFunds(apiFormData, acceptWarning)(dispatch);
    },
    [bankAccountId]
  );

  const handleContinue = () => {
    navigate(ROUTES.FUNDING.build());
  };

  const handleSubmit = React.useCallback(
    async (throwaway, acceptWarning) => {
      const enteredAmount = value !== '' && customParseFloat(value) > 0;
      if (!enteredAmount) {
        setFormError('You must enter an amount');
        return {
          ok: false,
        };
      }

      if (!bankAccountId) {
        setFormError('You must select a bank account');
        return {
          ok: false,
        };
      }

      if (acceptWarning) {
        setIsPendingWarning(null);
        setWarningMessage(null);
      }

      const { ok, warning, warningMessage, errorMessage } = await submitForm(
        {
          account_id: bankAccountId,
          amount: value,
          funding_operation: fundingOperation,
        },
        acceptWarning
      );
      setIsSubmitting(false);
      if (ok) {
        setTransferCompleted(true);
      } else if (warning) {
        setIsPendingWarning(true);
        setWarningMessage(warningMessage);
      } else {
        setFormError(errorMessage || 'Something went wrong, please try again');
        return {
          ok: false,
        };
      }
    },
    [bankAccountId, value, setIsPendingWarning, setWarningMessage]
  );

  const handleWarningAcceptSubmit = React.useCallback(() => {
    return handleSubmit(null, true);
  }, [handleSubmit]);

  const handleCancelWarning = React.useCallback(() => {
    setIsPendingWarning(null);
    setWarningMessage(null);
  }, [setIsPendingWarning, setWarningMessage]);

  const bankAccountsItems = [
    createDropdownItem({ text: 'Please Select...', value: '' }),
    createDropdownItem({ text: '---', value: '---', disabled: true }),

    ...bankAccounts.map((b) => {
      return createDropdownItem({
        text: `${b.account_type} (...${b.account_number_ending})`,
        value: b.id,
      });
    }),
  ];

  React.useEffect(() => {
    if (bankAccounts.length === 0) getBankAccounts()(dispatch);
  }, []);

  const handleSeeAvailableFundsDetailsClick = () => {
    const modal = {
      contentComponent: <AvailableFundsDetails reasons={fundsAvailableReasons} />,
      size: 'wide',
      dismissable: true,
    };
    showModal(modal)(dispatch);
  };

  if (!isReady) {
    return <LoadingPage />;
  }

  if (isPendingWarning) {
    return (
      <Page width={'600px'}>
        <Content>
          <Body1 bold>{warningMessage}</Body1>
          <ActionsWrapper>
            <FormState error={formError} isSubmitting={isSubmitting} />
            <Container fullWidth centerAll>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleWarningAcceptSubmit}>
                Accept and Submit
              </FlatButton>
            </Container>
            <Container fullWidth centerAll>
              <FlatButton transparent isDisabled={isSubmitting} fullWidth onClick={handleCancelWarning}>
                Cancel
              </FlatButton>
            </Container>
          </ActionsWrapper>
        </Content>
      </Page>
    );
  }

  if (isTransferCompleted) {
    return (
      <Page width={'600px'}>
        <Content>
          <TransferCompletedWrapper>
            <TransferCompletedIconWrapper>
              <CheckCircleFilled />
              <H3>Withdrawal Initiated</H3>
              <div style={{ paddingTop: '8px' }}>
                <Body5>Please allow 3-5 business days to process this request.</Body5>
              </div>
            </TransferCompletedIconWrapper>
            <ActionsWrapper>
              <Container fullWidth centerAll>
                <FlatButton fullWidth onClick={handleContinue}>
                  Continue
                </FlatButton>
              </Container>
            </ActionsWrapper>
          </TransferCompletedWrapper>
        </Content>
      </Page>
    );
  }

  return (
    <Page width={'600px'}>
      <Content>
        <H3>Withdraw Funds</H3>
        <div>
          {bankAccountId !== '' ? (
            isLoadingAvailableFunds ? (
              <Spacing top={0}>
                <Body5>Available to withdraw: Loading...</Body5>
              </Spacing>
            ) : (
              <Spacing top={0}>
                <div>
                  <Body5>{`Available to withdraw to selected account: ${formatForDisplay(
                    bankAccountSelectedAvailableToWithdraw,
                    'price'
                  )}`}</Body5>
                </div>
                {fundsAvailableReasons.length > 0 && (
                  <SeeAvailableFundsDetailsWrapper>
                    <FlatButton transparent onClick={handleSeeAvailableFundsDetailsClick}>
                      See details
                    </FlatButton>
                  </SeeAvailableFundsDetailsWrapper>
                )}
              </Spacing>
            )
          ) : null}
        </div>
        <div>
          <InputWrapper>
            {bankAccounts.length > 0 ? (
              <FloatingLabelDropdown
                name={'bankAccount'}
                value={bankAccountId}
                label={'Bank Account'}
                items={bankAccountsItems}
                onChange={handleBankAccountChange}
                error={null}
              />
            ) : (
              <div>
                <LoadingBankAccountsWrapper>
                  <UnstyledLoadingSpinner />
                  <div style={{ paddingLeft: '8px' }}>
                    <Body5>Loading Bank Accounts, please wait...</Body5>
                  </div>
                </LoadingBankAccountsWrapper>
              </div>
            )}
          </InputWrapper>
          <InputWrapper>
            <FloatingLabelTextInput
              name={'amount'}
              value={value}
              prefix={'$'}
              label={'Amount'}
              onChange={handleChange}
              maskType={INPUT_MASK_TYPES.dollarAmount}
              error={null}
              autoFocus
            />
          </InputWrapper>

          <ActionsWrapper>
            <FormState error={formError} isSubmitting={isSubmitting} />
            <Container fullWidth centerAll>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleSubmit}>
                Submit
              </FlatButton>
            </Container>
          </ActionsWrapper>
        </div>
      </Content>
    </Page>
  );
};
