import React from 'react';
import { connect } from 'react-redux';
import CloseAccount from './CloseAccount';
import { FlatButton } from '@src/main/components/buttons';
import styled from 'styled-components';

const Wrapper = styled.div`
  button {
    padding: 8px 0;
  }
`;

class CloseAccountAction extends React.PureComponent {
  render() {
    return (
      <CloseAccount>
        {(onCloseAccountStart) => (
          <Wrapper className={'close-nvstr-account-container'}>
            <FlatButton transparent onClick={onCloseAccountStart}>
              Close Account
            </FlatButton>
          </Wrapper>
        )}
      </CloseAccount>
    );
  }
}

export default connect()(CloseAccountAction);
