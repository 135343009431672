import React from 'react';
import { useCurrentUser } from '@src/main/hooks/user';
import { ThemeBuilderVNavRoute } from '@src/containers/Nav/verticalNavComponents/routeGroups/Account';
import VerticalNavGroup from '@src/containers/Nav/components/VerticalNavGroup';
import VerticalNavItem from '@src/containers/Nav/components/VerticalNavItem';
import CompassIcon from '@src/main/components/Images/CompassIcon';
import BookmarkIcon from '@src/main/components/Images/BookmarkIcon';

const EditorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>book-edit-outline</title>
      <path d="M6 20H11V22H6C4.89 22 4 21.11 4 20V4C4 2.9 4.89 2 6 2H18C19.11 2 20 2.9 20 4V10.3C19.78 10.42 19.57 10.56 19.39 10.74L18 12.13V4H13V12L10.5 9.75L8 12V4H6V20M22.85 13.47L21.53 12.15C21.33 11.95 21 11.95 20.81 12.15L19.83 13.13L21.87 15.17L22.85 14.19C23.05 14 23.05 13.67 22.85 13.47M13 19.96V22H15.04L21.17 15.88L19.13 13.83L13 19.96Z" />
    </svg>
  );
};

export const AdminRoutesGroup = () => {
  const currentUser = useCurrentUser();
  if (currentUser.is_nvstr_employee || currentUser.is_content_editor || currentUser.is_ctl_team) {
    return (
      <VerticalNavGroup groupName="Admin">
        {currentUser.is_nvstr_employee && (
          <VerticalNavItem name={'Lesson Review'} customIcon={<CompassIcon />} route="/learn-and-earn-lesson-review" />
        )}
        {currentUser.is_content_editor && (
          <VerticalNavItem name="Lesson Entry" customIcon={<BookmarkIcon />} route="/learn-and-earn-entry" />
        )}
        {currentUser.is_content_editor && (
          <VerticalNavItem
            name="Lesson Content Editor"
            customIcon={<EditorIcon />}
            route="/learn-and-earn-content-editor"
          />
        )}
        {currentUser.is_ctl_team && (
          <VerticalNavItem name={'Pro/Con Generator'} customIcon={<CompassIcon />} route="/pro-con-generator" />
        )}
        {currentUser.is_nvstr_employee && <ThemeBuilderVNavRoute />}
      </VerticalNavGroup>
    );
  }
  return null;
};
