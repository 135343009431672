import { canVerifyBankAccount } from '../helpers/fundingHelpers';

const getLiveAccountStore = (state) => (state.liveAccount ? state.liveAccount.liveAccounts : {});

export const getBankAccountLookup = (state) => getLiveAccountStore(state).accounts || {};

export const getAllBankAccountIds = (state) => Object.keys(getBankAccountLookup(state));

export const isBankAccountActive = (id, state) => {
  if (!state) {
    const err = new Error('isBankAccountActive is missing state');
    throw err;
  }

  const lookup = getBankAccountLookup(state);
  const accountData = lookup[id];
  return accountData && accountData.can_accept_transfers;
};

export const hasLinkedBankAccount = (state) => {
  const bankAccountIds = getAllBankAccountIds(state);
  return bankAccountIds.filter((id) => isBankAccountActive(id, state)).length > 0;
};

export const getBankAccountIdsListNeedingVerificationFromState = (state) => {
  const lookup = getBankAccountLookup(state);
  const ids = getAllBankAccountIds(state);
  const idsNeedVerification = [];
  ids.forEach((id) => {
    const account = lookup[id];
    if (canVerifyBankAccount(account)) {
      idsNeedVerification.push(account.id);
    }
  });
  return idsNeedVerification;
};
