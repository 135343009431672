import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withWindowSize from '../HOCS/withWindowSize';
import { PRODUCT_DISPLAY_NAME } from '@src/appConfig';
import * as Actions from '../../actions/index';

import { DashboardAffixScroll } from '../Scroll/DashboardAffixScroll';

import NotificationsCounter from '../Counters/NotificationsCounter';

import {
  returnCurrentUserId,
  isEligibleForSubscription,
  shouldSeeDashboardMessaging,
  isReferralProgramActive,
} from '../../helpers/currentUserHelpers';
import { parseQueryString, createQueryString } from '../../helpers/routerHelpers';
import { removeKeyFromObject } from '../../helpers/generalHelpers';
import PortfolioSummary from '../../main/containers/Portfolio/PortfolioSummary';
import styled from 'styled-components';

const PanelSelectionWrapper = styled.div`
  padding: 8px 0 24px 0;

  .panel-selection-tab {
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.4px;
    width: 160px;
    cursor: pointer;
    height: 40px;

    border: 2px solid;
    border-color: ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.primaryCtaButton};

    &:first-child {
      border-top-left-radius: 5px;

      border-bottom-left-radius: 5px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    &:nth-child(2) {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;

      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:hover {
      border-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
      background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
      color: ${({ theme }) => theme.themeColors.buttonText};
    }

    &.active {
      border-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
      background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
      color: ${({ theme }) => theme.themeColors.buttonText};

      &:hover {
        border-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
        background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
        color: ${({ theme }) => theme.themeColors.buttonText};
      }
    }
  }
`;

const PortfolioSummaryWrapper = styled.div`
  @media (max-width: 1133px) {
    max-width: 600px;
    margin: 0 auto;
  }
`;

class DashboardContainer extends Component {
  constructor() {
    super();
    this.state = {
      _shouldShowInviteFriendsMessaging: false,
      _shouldShowSubscriptionPlanMessaging: false,
      _shouldShowAskCTLMessaging: false,

      _isInfiniteScrollTriggered: false,
    };
  }

  componentDidMount() {
    this._updateLocalTabTracking(this.returnActiveTab());

    this._updatePageTitle();
    this._logDashboardView();
    this._logPanelViews();

    this._removeTabFromURLWhenBeingIgnored();

    this._refreshCurrentUserData();
    this._forceRefreshGetUpdatedNotifications();
  }

  componentDidUpdate() {
    if (this._didTabChange()) {
      this._updateLocalTabTracking(this.returnActiveTab());

      if (this.returnActiveTab() === 'positions') {
        this._logDashboardTableView();
      } else {
        this._logDashboardFeedView();
      }
    }

    this._removeTabFromURLWhenBeingIgnored();
  }

  _removeTabFromURLWhenBeingIgnored = () => {
    const { pathname, search } = window.location;
    const subPath = pathname.split('/app')[1];
    const query = parseQueryString(search);
    // check if tab is in url, but being ignored
    if (this._shouldShowDesktopDashboard() && query.tab) {
      const filteredQuery = removeKeyFromObject(query, 'tab');
      const newUrlQueryString = createQueryString(filteredQuery);
      this.props.navigate(`${subPath}${newUrlQueryString}`);
    }
  };

  _returnDesktopToMobileBreakPoint = () => 1125; // update in NavNotificationContainer too

  render() {
    return (
      <div className={'dashboard-page-container'}>
        <div className={'dashboard-page'}>
          <div className={`dashboard-container ${this._shouldShowDesktopDashboard() ? 'desktop' : 'mobile'}`}>
            <PortfolioSummaryWrapper>
              <PortfolioSummary />
            </PortfolioSummaryWrapper>
            <DashboardAffixScroll
              className={'dashboard-body'}
              layoutType={this._shouldShowDesktopDashboard() ? 'desktop' : 'mobile'}
              windowHeight={this.props.windowHeight}
              shouldShowInviteFriendsMessaging={this._shouldShowInviteFriendsMessaging()}
              shouldShowSubscriptionPlanMessaging={this._shouldShowSubscriptionPlanMessaging()}
              shouldShowAskCTLMessaging={this._shouldShowAskCTLMessaging()}
              getUpdatedNotifications={this.getUpdatedNotifications}
              sendUpdatedLastSeenNotifTime={this.sendUpdatedLastSeenNotifTime}
              activeTab={this.returnActiveTab()}
              tabsRenderer={this.returnRendererPanelSelectionComponent}
              tabNameToPanelNameLookup={this.returnTabNameToPanelNameLookup()}
            />
          </div>
        </div>
      </div>
    );
  }

  /*************   Show invite friends logic    ***************/
  /*************   Show invite friends logic    ***************/
  /*************   Show invite friends logic    ***************/

  _shouldShowInviteFriendsMessaging = () => this.state._shouldShowInviteFriendsMessaging;
  _shouldShowSubscriptionPlanMessaging = () => this.state._shouldShowSubscriptionPlanMessaging;
  _shouldShowAskCTLMessaging = () => this.state._shouldShowAskCTLMessaging;

  _setShowInviteFriendsMessaging = () => {
    this.setState(() => ({
      _shouldShowInviteFriendsMessaging: true,
    }));
  };

  _setHideShowInviteFriendsMessaging = () => {
    this.setState(() => ({
      _shouldShowInviteFriendsMessaging: false,
    }));
  };

  _setShowSubscriptionPlanMessaging = () => {
    this.setState(() => ({
      _shouldShowSubscriptionPlanMessaging: true,
    }));
  };

  _setHideSubscriptionPlanMessaging = () => {
    this.setState(() => ({
      _shouldShowSubscriptionPlanMessaging: false,
    }));
  };

  _setShowAskCTLMessaging = () => {
    this.setState(() => ({
      _shouldShowAskCTLMessaging: true,
    }));
  };

  _setHideAskCTLMessaging = () => {
    this.setState(() => ({
      _shouldShowAskCTLMessaging: false,
    }));
  };

  _checkIfShouldShowMessaging = () => {
    const shouldSeeMessaging = shouldSeeDashboardMessaging(this.props.currentUser);
    if (shouldSeeMessaging) {
      const roll = Math.random();
      const isSubEligible = isEligibleForSubscription(this.props.currentUser);
      const isRefProgActive = isReferralProgramActive(this.props.currentUser);
      const inRange = (value, range) => {
        if (!range) {
          return false;
        }
        return range[0] <= value && value <= range[1];
      };
      var showInviteFriendsMessage = false;
      var showAskCTLMessage = false;
      var showSubsMessage = false;
      if (isRefProgActive && isSubEligible) {
        showInviteFriendsMessage = inRange(roll, [0, 0.3333]);
        showAskCTLMessage = inRange(roll, [0.3333, 0.6666]);
        showSubsMessage = inRange(roll, [0.6666, 1]);
      } else if (isRefProgActive && !isSubEligible) {
        showInviteFriendsMessage = inRange(roll, [0, 0.5]);
        showAskCTLMessage = inRange(roll, [0.5, 1]);
      } else if (!isRefProgActive && isSubEligible) {
        showSubsMessage = inRange(roll, [0, 0.5]);
        showAskCTLMessage = inRange(roll, [0.5, 1]);
      } else {
        showAskCTLMessage = true;
      }
      if (showInviteFriendsMessage) {
        this._setShowInviteFriendsMessaging();
        this._logSeenInviteFriendsMessaging();
      } else if (showSubsMessage) {
        this._setShowSubscriptionPlanMessaging();
        this._logSeenSubscriptionPlanMessaging();
      } else if (showAskCTLMessage) {
        this._setShowAskCTLMessaging();
        // View is logged in CTLMessaging component
      }
    }
  };

  /*************   Refresh notifs methods    ***************/
  /*************   Refresh notifs methods    ***************/
  /*************   Refresh notifs methods    ***************/

  getUpdatedNotifications = (additionalConfig = {}) => {
    const config = this._returnConfigForNotifRequest(additionalConfig);
    return this.props.actions.getUpdatedNotifications(config).then((response) => {
      if (this._isFeedShowing()) {
        this.sendUpdatedLastSeenNotifTime().then((response) => {
          this._getUnseenNotifsCount();
        });
      } else {
        this._getUnseenNotifsCount();
      }
      return response;
    });
  };

  sendUpdatedLastSeenNotifTime = () => {
    return this.props.actions.postUpdatedLastSeenNotificationTime();
  };

  _returnConfigForNotifRequest = (additionalConfig = {}) => ({
    batch_index: 0,
    page: 1,
    ...additionalConfig,
  });

  _getUnseenNotifsCount = () => this.props.actions.getUnseenNotificationsCount();

  /*************   Mobile tab methods    ***************/
  /*************   Mobile tab methods    ***************/
  /*************   Mobile tab methods    ***************/

  returnTabNameToPanelNameLookup = () => ({
    positions: 'leftPanel',
    feed: 'rightPanel',
  });

  returnTabNames = () => ['positions', 'feed'];

  returnRendererPanelSelectionComponent = () => {
    return (
      <PanelSelectionWrapper className={'panel-selection-container'}>
        {this.returnTabNames().map((tabKey) => this._renderPanelSelectionTab(tabKey))}
      </PanelSelectionWrapper>
    );
  };

  _renderPanelSelectionTab = (tabKey) => {
    const activeClass = this._isMobileTabActive(tabKey) ? 'active' : '';
    return (
      <div
        key={`panel-selection-tab-${tabKey}`}
        className={`panel-selection-tab panel-selection-tab-positioning ${tabKey} ${activeClass}`}
        onClick={this._bindTabKeyToMakePanelActive(tabKey)}
      >
        {this._panelTabKeyToDisplayName(tabKey)}
      </div>
    );
  };

  _renderAdditionalTabComponent = (tabKey) => (tabKey === 'feed' ? <NotificationsCounter /> : null);

  _panelTabKeyToDisplayName = (tabKey) => {
    return {
      positions: 'Positions',
      feed: 'Activity',
    }[tabKey];
  };

  _createUrlForPanel = (tabKey) => {
    const path = this._returnLocationPathname();
    const queryString = this._returnLocationSearch();
    const query = parseQueryString(queryString);
    query.tab = tabKey;
    const newQueryString = createQueryString(query);
    return path + newQueryString;
  };

  _makePanelActive = (tabKey) => {
    const url = this._createUrlForPanel(tabKey);
    this.props.navigate(url);
  };

  _bindTabKeyToMakePanelActive = (tabKey) => () => {
    this._logDashboardTabClick(tabKey);
    this._makePanelActive(tabKey);
  };

  _isMobileTabActive = (tabKey) => tabKey === this.returnActiveTab(); // do NOT use if desktop width there are no tabs

  _returnDefaultTab = () => (this._shouldDefaultToPositionsTab() ? 'positions' : 'feed');

  _shouldShowFeed = () => this.returnActiveTab() === 'feed';

  _isFeedShowing = () =>
    this._shouldShowDesktopDashboard() || (!this._shouldShowDesktopDashboard() && this.returnActiveTab() === 'feed');

  _convertTabKeyToEventName = (tabKey) => {
    return (
      {
        positions: 'Positions',
        feed: 'Activity',
      }[tabKey] || console.error(`Tabkey: ${tabKey} -> did not match any event names`)
    );
  };

  _didTabChange = () => this.activeTab !== this.returnActiveTab();

  _getLocalTabTracking = () => this.activeTab;

  _updateLocalTabTracking = (tab) => (this.activeTab = tab);

  _shouldDefaultToPositionsTab = () => this._returnCurrentUser().default_activity_tab_test_group === 0;

  returnActiveTab = () => this._parseTabFromSearchQuery() || this._returnDefaultTab();

  _parseTabFromSearchQuery = () => parseQueryString(this._returnLocationSearch()).tab || null;

  _returnLocationSearch = () => this._returnLocation().search || '';

  _returnLocationPathname = () => this._returnLocation().pathname;

  _returnLocation = () => this.props.location;

  /*************   Misc methods    ***************/
  /*************   Misc methods    ***************/
  /*************   Misc methods    ***************/

  _shouldShowDesktopDashboard = () => this.props.windowWidth >= this._returnDesktopToMobileBreakPoint();

  _returnCurrentUserId = () => returnCurrentUserId(this.props.currentUser);

  _returnCurrentUser = () => this.props.currentUser;

  _forceRefreshGetUpdatedNotifications = () => {
    return this.getUpdatedNotifications({ forceRefresh: true });
  };

  _refreshCurrentUserData = () => {
    return this.props.actions.fetchCurrentUserQuickInfo().then((response) => {
      if (
        response &&
        response.error &&
        response.error.response &&
        response.error.response.status &&
        response.error.response.status === 401
      ) {
        window.location = '/?sessionExpired=true';
      }
      return response;
    });
  };

  _updatePageTitle = () => {
    document.title = `${PRODUCT_DISPLAY_NAME} - Dashboard`;
  };

  _logDashboardView = () => {
    const event = 'View Dashboard';
    this.props.actions.logMetricsTrackingEvent(event);
  };

  _logPanelViews = () => {
    if (this._shouldShowDesktopDashboard()) {
      this._logDashboardFeedView();
      this._logDashboardTableView();
    } else {
      if (this.returnActiveTab() === 'positions') {
        this._logDashboardTableView();
      } else {
        this._logDashboardFeedView();
      }
    }
  };

  _logDashboardTableView = () => {
    const event = 'View Dashboard Positions Table';
    this.props.actions.logMetricsTrackingEvent(event);
  };

  _logDashboardFeedView = () => {
    const event = 'View Dashboard Activity Feed';
    this.props.actions.logMetricsTrackingEvent(event);
  };

  _logDashboardTabClick = (tab) => {
    const event = 'Clicked Dashboard Tab';
    const properties = {
      Tab: this._convertTabKeyToEventName(tab),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logSeenInviteFriendsMessaging = () => {
    const event = 'View Invite Friends Messaging';
    const properties = {
      Context: 'Feed',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logSeenSubscriptionPlanMessaging = () => {
    const event = 'View Subscription Plan Messaging';
    const properties = {
      Context: 'Feed',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(
  withWindowSize,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DashboardContainer);

export default composedComponent;
