import React from 'react';
import styled from 'styled-components';
import { useBuyingPower } from '../../hooks/portfolio/useBuyingPower';
import { formatForDisplay } from '../../../helpers/displayHelpers';
import { DISPLAY_FORMAT_TYPES } from '../../utils/numbers';
import { Body5, H5 } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { Plus } from '../../assets/svgs/Plus';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../constants/paths';

const Wrapper = styled.div``;
const Label = styled.div``;
const Value = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const AddFundingWrapper = styled.div`
  margin-left: 12px;
  button {
    padding: 2px;

    b {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  svg {
    height: 20px;
    width: 20px;
  }
`;

const AddFundingButton = () => {
  const navigate = useNavigate();
  const hc = () => {
    // TODO: funding modal?
    navigate(ROUTES.FUNDING.build());
  };
  return (
    <AddFundingWrapper>
      <FlatButton onClick={hc}>
        <Plus thick />
      </FlatButton>
    </AddFundingWrapper>
  );
};

export const BuyingPower = () => {
  const buyingPower = useBuyingPower();
  return (
    <Wrapper>
      <Label>
        <Body5>Buying Power</Body5>
      </Label>
      <Value>
        <H5>{formatForDisplay(buyingPower, DISPLAY_FORMAT_TYPES.PRICE)}</H5>
        <AddFundingButton />
      </Value>
    </Wrapper>
  );
};
