import React from 'react';
import { SupportContact } from '../../../constants/types/support';
import { Body5 } from '../../../main/lib/nvstr-common-ui.es';
import styled from 'styled-components';

const EmailAnchor = styled.a`
  color: ${({ theme }) => theme.themeColors.primaryCtaButton};
`;
export const SupportEmail = ({ children }) => {
  return <EmailAnchor href={SupportContact.EMAIL.HREF}>{children || SupportContact.EMAIL.LINK_TEXT}</EmailAnchor>;
};
const ContactSupport = () => {
  return (
    <p className={'contact-support-message'}>
      <Body5 isSmall>
        If you need assistance, please contact <a href={SupportContact.EMAIL.HREF}>{SupportContact.EMAIL.LINK_TEXT}</a>.
      </Body5>
    </p>
  );
};

export default ContactSupport;
