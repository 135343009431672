import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/index';
import CreateThoughtFromNewsContainer from './CreateThoughtFromNewsContainer';
import { LinkTo } from '../Links/LinkTo';
import Button from '../../components/buttons/Button';
import LoadingIcon from '../../components/misc/LoadingIcon';
import Image from '../UI/Image';
import { NewsImpressionTracker } from '../Tracking/ImpressionTrackingTypes/NewsImpressionTracker';

import { truncateName } from '../../helpers/generalHelpers';
import { requireLogin } from '../../helpers/authHelpers';
import { moment, formatLocalizedDateTime } from '../../helpers/timeHelpers';
import { createQueryString } from '../../helpers/routerHelpers';
import { sendFacebookTrackingEvent } from '../../constants/facebookTracking';
import { returnCurrentUserThemeTestGroups } from '../../helpers/currentUserHelpers';
import PlaceholderImage from '../UI/PlaceholderImage';
import { withRouter } from '../../main/utils';
import { FlatButton } from '@src/main/components/buttons';
import styled from 'styled-components';
import { useNavigate } from 'react-router';

const ProWrapper = styled.div``;

const ConWrapper = styled.div``;

const NewsWrapper = styled.span`
  background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};

  &:hover {
    opacity: 0.8;
  }
`;

const CreateThoughtButtonWrapper = styled.div`
  margin: 0 auto;
  padding: 16px 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 142px;

  button {
    padding: 4px 12px;
    border: none !important;
    border-radius: 4px;
  }
  ${ProWrapper} {
    button {
      background-color: ${({ theme }) => theme.themeColors.proColor};
      color: ${({ theme }) => theme.themeColors.buttonText};
    }
  }
  ${ConWrapper} {
    button {
      background-color: ${({ theme }) => theme.themeColors.conColor};
      color: ${({ theme }) => theme.themeColors.buttonText};
    }
  }
`;

const MoreInfoLink = ({ to, onClick, text }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(to);
    onClick();
  };
  return (
    <FlatButton transparent onClick={handleClick}>
      {text}
    </FlatButton>
  );
};

class NewsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentWidth: null,
      isCreatingThought: false,
      thoughtType: null,
      didImageLoadFail: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this._handleResize);
    this._handleResize();
    const ogoFromStore = this._returnOgoFromStore();
    if (!ogoFromStore) {
      this._isImageInNewsData() ? this._saveImageToStore() : this._getCustomPreviewImage();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleResize);
  }

  _handleResize = () => {
    this.setState(() => ({
      componentWidth: window.$(this.NewsComponent).width(),
    }));
  };

  returnNewsId = () => this._returnNewsData().id;

  render() {
    if (this._wasErrorGettingNews()) {
      return this._renderError();
    } else {
      if (this.state.isCreatingThought) {
        return this._renderCreatingNewsThought();
      } else {
        return this._renderNews();
      }
    }
  }

  _isLoadingOgo = () => !this._returnOgoFromStore() || this._returnOgoFromStore().loading;

  _renderLoadingCustomImage = () => {
    return (
      <span className={'news-image-container loading'}>
        <LoadingIcon icon="fading-3balls" size="small" />
      </span>
    );
  };

  _renderLoadingSummary = () => {
    return (
      <span className={'news-container loading'}>
        <LoadingIcon icon="fading-3balls" size="small" />
      </span>
    );
  };

  _renderError = () => {
    return <div className={'news-component error'}>There was an error getting the news. Please try again later.</div>;
  };

  _renderCreatingNewsThought = () => {
    return (
      <CreateThoughtFromNewsContainer
        securityId={this._returnSecurityId()}
        thoughtType={this.state.thoughtType}
        ogo={this._returnOgoFromStore()}
        isLoadingCustomImage={this._isLoadingOgo()}
        handleFinishCreatingThought={this._handleFinishCreatingThought}
        handleCancelCreatingThought={this._handleCancelCreatingThought}
        newsTrackingData={this.returnNewsTrackingData()}
      />
    );
  };

  _renderNews = () => {
    const maxWidthForDefaultSize = 557;
    const width = this.state.componentWidth;
    if (width < maxWidthForDefaultSize || this.props.size === 'small') {
      return this._renderMobileSizedNews();
    } else {
      return this._renderDefaultSizeNews();
    }
  };

  _renderDefaultSizeNews = () => {
    return (
      <NewsWrapper
        onClick={this._logNewsArticleClick}
        className={`
          news-component-container
          news-component-container-${this.returnNewsId()}
          news-article
          ${this._alternativeDisplayClasses()}
          ${this._displayAsSize()}
        `}
      >
        {!this.props.ignoreImpressionTracking && (
          <NewsImpressionTracker
            newsId={this.returnNewsId()}
            securityId={this._returnSecurityId()}
            notificationIndex={this.props.notificationIndex}
            metadata={this._returnNewsData().metadata}
            viewingContext={this.props.viewingContext}
            scrollContainerSelector={this.props.scrollContainerSelector}
            containerElementSelector={'.news-component-container'}
            recordImpressionOnMount={this.props.recordImpressionOnMount}
          />
        )}
        <div
          className={`
            news-component
            news-article
          `}
          ref={(el) => (this.NewsComponent = el)}
        >
          <a
            href={this._returnArticleOriginalURL()}
            target="_blank"
            rel="no-follow noopener noreferrer"
            onClick={this._logNewsArticleClick}
          >
            {this._renderImage()}
          </a>
          {this._renderNewsBody()}
        </div>
      </NewsWrapper>
    );
  };

  _renderMobileSizedNews = () => {
    return (
      <NewsWrapper
        className={`
          news-component-container
          news-component-container-${this.returnNewsId()}
          news-article
          ${this._alternativeDisplayClasses()}
          ${this._displayAsSize()}
        `}
      >
        {!this.props.ignoreImpressionTracking && (
          <NewsImpressionTracker
            newsId={this.returnNewsId()}
            securityId={this._returnSecurityId()}
            notificationIndex={this.props.notificationIndex}
            metadata={this._returnNewsData().metadata}
            viewingContext={this.props.viewingContext}
            scrollContainerSelector={this.props.scrollContainerSelector}
            containerElementSelector={'.news-component-container'}
            recordImpressionOnMount={this.props.recordImpressionOnMount}
          />
        )}
        <div
          className={`
            news-component
            news-article
            ${this._alternativeDisplayClasses()}
            ${this._displayAsSize()}
          `}
          ref={(el) => (this.NewsComponent = el)}
        >
          <a
            href={this._returnArticleOriginalURL()}
            target="_blank"
            rel="no-follow noopener noreferrer"
            onClick={this._logNewsArticleClick}
          >
            {this._renderHeadline()}
          </a>
          <a
            href={this._returnArticleOriginalURL()}
            target="_blank"
            rel="no-follow noopener noreferrer"
            onClick={this._logNewsArticleClick}
          >
            {this._renderImage()}
          </a>
        </div>
        {this._renderMobileNewsBody()}
      </NewsWrapper>
    );
  };

  _shouldDisplayAsInActivityPageFeed = () => this.props.inActivityPageFeed;

  _shouldDisplayAsInFeed = () => this.props.inFeed;

  _alternativeDisplayClasses = () =>
    this._shouldDisplayAsInFeed() || this._shouldDisplayAsInActivityPageFeed() ? 'news-in-feed' : '';

  _displayAsSize = () => this.props.size || '';

  _wasErrorGettingNews = () => false; // TODO:

  _returnNewsData = () => {
    const newsData = this.props.newsData;
    if (!newsData) {
      console.error('No news data was passed into NewsComponent');
    }
    return newsData || {};
  };

  _renderImage = () => {
    if (!this._isImageInNewsData() && this._isLoadingOgo()) {
      return this._renderLoadingCustomImage();
    }
    const newsData = this._returnNewsData();
    const ogo = this._returnOgoFromStore();
    const imgLink = ogo ? ogo.images_url || null : newsData.open_graph_object && newsData.open_graph_object.image_link;

    if (!imgLink || this.state.didImageLoadFail) return <PlaceholderImage />;

    return (
      <span
        className={'news-image-container'}
        style={
          this._shouldDisplayAsInFeed()
            ? {
                maxHeight: 'auto',
                minHeight: 'auto',
                width: 'auto',
                maxWidth: 'auto',
              }
            : {}
        }
      >
        <Image
          src={imgLink}
          handleImageLoadFailure={this.handleImageLoadFailure}
          parentClass={'.news-image-container'}
          maxHeight={this._shouldDisplayAsInFeed() ? 300 : null}
          renderPlaceholder
        />
      </span>
    );
  };

  _renderNewsBody = () => {
    return (
      <span className={'news-body'}>
        <a
          href={this._returnArticleOriginalURL()}
          target="_blank"
          rel="no-follow noopener noreferrer"
          onClick={this._logNewsArticleClick}
        >
          {this._renderNewsHeader()}
          {this._renderHeadline()}
          {this._renderSummary()}
        </a>
        {this._renderMoreNewsForTickerLink()}
        {this._renderAddThoughtActionButtons()}
      </span>
    );
  };

  _renderMobileNewsBody = () => {
    return (
      <span className={'news-body'}>
        <a
          href={this._returnArticleOriginalURL()}
          target="_blank"
          rel="no-follow noopener noreferrer"
          onClick={this._logNewsArticleClick}
        >
          {this._renderNewsHeader()}
        </a>
        {this._renderAddThoughtActionButtons()}
        {this._renderMoreNewsForTickerLink()}
      </span>
    );
  };

  _renderNewsHeader = () => {
    return (
      <span className={'news-header'}>
        {this._renderSource()}
        {this._renderTimestamp()}
      </span>
    );
  };

  _renderSource = () => {
    const source = this._returnSource();
    return <span className={'news-source'}>{source}</span>;
  };

  _returnLinkToOpenSecurityPanel = () => {
    const securityCard = {
      panel: 'security',
      securityId: this._returnSecurityId(),
      activeTab: 'headlines',
    };
    const currentPath = this.props.location.pathname;
    const queryString = createQueryString(securityCard);
    return currentPath + queryString;
  };

  _renderMoreNewsForTickerLink = () => {
    if (this._shouldDisplayAsInFeed() || this._shouldDisplayAsInActivityPageFeed()) {
      return (
        <span className={'more-news-button-container'}>
          <MoreInfoLink
            to={this._returnLinkToOpenSecurityPanel()}
            onClick={this._logOpenSecurityPanelFromNewsInFeed}
            text={'More News On ' + this._returnSecurity().symbol}
          />
        </span>
      );
    }
    return null;
  };

  _renderHeadline = () => {
    return <span className={'news-headline'}>{this._returnDisplayHeadline() || ''}</span>;
  };

  _renderSummary = () => {
    if (this._isLoadingOgo()) {
      return (
        <span className={'news-summary loading'}>
          <LoadingIcon icon="fading-3balls" size="small" />
        </span>
      );
    }
    return <span className={'news-summary'}>{this._returnDisplaySummary()}</span>;
  };

  _renderTimestamp = () => {
    const newsData = this._returnNewsData();
    const timeNow = moment();
    const timestamp = newsData.timestamp * 1000;
    const timestampMoment = moment(timestamp);

    const isWithinADay = !timestampMoment.isBefore(timeNow, 'day');
    const timestampReadableDateString = `${formatLocalizedDateTime('l', timestampMoment)} ${formatLocalizedDateTime(
      'LT',
      timestampMoment
    )}`;
    const timestampReadableDayTimeSinceString = timestampMoment.fromNow();

    const timestampReadableString = isWithinADay ? timestampReadableDayTimeSinceString : timestampReadableDateString;

    return <span className={'news-timestamp secondary-text-color border-accent'}>{timestampReadableString}</span>;
  };

  _renderAddThoughtActionButtons = () => {
    return (
      <CreateThoughtButtonWrapper className={'post-thought-action-buttons'}>
        <ProWrapper>
          <FlatButton onClick={this._bindAddThoughtButtonToHandler('pro')}>+ Pro</FlatButton>
        </ProWrapper>
        <ConWrapper>
          <FlatButton onClick={this._bindAddThoughtButtonToHandler('con')}>+ Con</FlatButton>
        </ConWrapper>
      </CreateThoughtButtonWrapper>
    );
  };

  _returnHeadline = () => this._returnNewsData().open_graph_object && this._returnNewsData().open_graph_object.title;

  _returnDisplayHeadline = () => {
    const maxLength = 100;
    const headline = this._returnHeadline();
    return truncateName(headline, maxLength);
  };

  _returnSummary = () => (this._returnOgoFromStore() ? this._returnOgoFromStore().description || '' : '');

  _returnSource = () => this._returnNewsData().source;

  _returnDisplaySummary = () => {
    const maxLength = 250;
    const summary = this._returnSummary();
    return truncateName(summary, maxLength);
  };

  _returnArticleOriginalURL = () => {
    const newsData = this._returnNewsData();
    return newsData.open_graph_object && newsData.open_graph_object.url;
  };

  _returnOgoFromStore = () => this.props.news.ogoByUrl[this._returnArticleOriginalURL()] || null;

  _getCustomPreviewImage = () => {
    const newsData = this._returnNewsData();
    this.props.actions.fetchNewsImage(newsData);
  };

  handleImageLoadFailure = () => {
    this.setState(() => ({
      didImageLoadFail: true,
    }));
  };

  _saveImageToStore = () => {
    const newsData = this._returnNewsData();
    const ogo = newsData.open_graph_object;
    const url = this._returnArticleOriginalURL();
    const headline = this._returnDisplayHeadline();
    const summary = this._returnDisplaySummary();
    const formatXpressfeedOgo = {
      description: summary,
      title: headline,
      url,
      images: [ogo.image_link],
      favicon: null,
    };
    this.props.actions.saveNewsOgo(url, formatXpressfeedOgo);
  };

  _isImageInNewsData = () => {
    const newsData = this._returnNewsData();
    const imgLink = newsData.open_graph_object && newsData.open_graph_object.image_link;
    // Hard coded to ignore image from API since it is such a low quality image
    return !!imgLink && false;
  };

  _bindAddThoughtButtonToHandler = (thoughtType) => {
    return () => {
      this.props.actions.sendNewsClickEvent(this._buildNewsClickTrackingData(thoughtType));
      return this.props.isCurrentUserAuthed ? this._handleAddThoughtClick(thoughtType) : requireLogin.call(this);
    };
  };

  _handleAddThoughtClick = (thoughtTypeName) => {
    const thoughtType = thoughtTypeName.toLowerCase() === 'pro' ? { id: 0, name: 'Pro' } : { id: 1, name: 'Con' };
    this.setState(() => ({
      isCreatingThought: true,
      thoughtType,
    }));
  };

  _clearCreatingThought = () => {
    this.setState(() => ({
      isCreatingThought: false,
      thoughtType: null,
    }));
  };

  _handleCancelCreatingThought = () => {
    this._clearCreatingThought();
  };

  _handleFinishCreatingThought = () => {
    this._clearCreatingThought();
    this.getUpdatedNotifications();
  };

  _returnConfigForNotifRequest = () => ({
    batch_index: 0,
    page: 1,
  });

  getUpdatedNotifications = () => {
    const config = this._returnConfigForNotifRequest();
    this.props.actions.getUpdatedNotifications(config).then((response) => {
      this.props.actions.getUnseenNotificationsCount();
      return response;
    });
  };

  _returnSecurityId = () => this._returnSecurity().id;

  _returnSecurity = () => this._returnNewsData().security || this.props.security;

  returnNewsTrackingData = () => {
    return {
      'Concernable Id': this._returnSecurityId(),
      'Concernable Type': 'Security',
      Source: this._returnSource(),
      URL: this._returnArticleOriginalURL(),
      'Security ID': this._returnSecurityId(),
      'Stock Symbol': this._returnSecurity().symbol,
      Context: this.props.isNotification ? 'News Notification' : 'Security Panel',
    };
  };

  _logOpenSecurityPanelFromNewsInFeed = () => {
    const event = 'Open Security Panel';
    const properties = {
      Component: 'News In Feed Component',
      Context: 'Security Panel',
      Symbol: this._returnSecurity().symbol,
    };
    this.props.actions.sendNewsClickEvent(this._buildNewsClickTrackingData('more'));
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logNewsArticleClick = () => {
    sendFacebookTrackingEvent('Click News Article', {
      themeTestGroups: returnCurrentUserThemeTestGroups(this.props.currentUser),
    });
    const event = 'Clicked News Article';
    const properties = {
      ...this.returnNewsTrackingData(),
    };
    this.props.actions.sendNewsClickEvent(this._buildNewsClickTrackingData('link'));
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _buildNewsClickTrackingData = (clickType) => ({
    id: this.returnNewsId(),
    securityId: this._returnSecurityId(),
    clickType,
    context: this.props.viewingContext,
    indexInFeed: this.props.notificationIndex,
    metadata: this._returnNewsData().metadata,
  });
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    news: state.news,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(NewsComponent);

export default withRouter(connectedComponent);
