import { SAVE_APP_STORAGE, REMOVE_KEY_APP_STORAGE } from '../constants';

export const saveToAppStorage = (data) => {
  return function (dispatch) {
    dispatch({
      type: SAVE_APP_STORAGE,
      payload: data,
    });
  };
};

export const removeKeyAppStorage = (key) => {
  return function (dispatch) {
    dispatch({
      type: REMOVE_KEY_APP_STORAGE,
      payload: key,
    });
  };
};
