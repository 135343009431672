import React from 'react';
import { Plaid } from './interstitial/Plaid';

export const LinkBankPlaid = ({ onContinue, reauthToken }) => {
  const handlePlaidContinue = React.useCallback((hadLinkSuccess) => {
    onContinue(hadLinkSuccess);
  }, []);

  const handleLinkFailure = React.useCallback(() => {
    onContinue(false);
  }, []);

  const handleMissingLiveAccount = React.useCallback(() => {
    onContinue(false);
  }, []);

  return (
    <Plaid
      reauthToken={reauthToken}
      onPlaidContinue={handlePlaidContinue}
      onPlaidLinkFailure={handleLinkFailure}
      onMissingLiveAccount={handleMissingLiveAccount}
    />
  );
};
