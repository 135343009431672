import React from 'react';
import { Page, Spacing } from '../components/layout';
import { DepositButton } from '../containers/buttons';
import { Body5, FloatingLabelTextInput, INPUT_MASK_TYPES } from '../lib/nvstr-common-ui.es';
import styled from 'styled-components';
import { UpdateUnverifiedInfo } from './onboarding/UpdateUnverifiedInfo';

const Wrapper = styled.div`
  select {
    border: none;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.themeColors.text};
  }

  input {
    border: none;

    -webkit-appearance: auto;
    background: transparent !important;
  }
`;

const Container = ({}) => {
  const [v, setV] = React.useState('500.00');
  const handleChange = (name) => (value) => {
    setV(value);
  };
  return <UpdateUnverifiedInfo />;
};

export const _Dev = ({}) => {
  return <Container />;
};
