import React from 'react';
import { PRODUCT_NAME } from '@src/appConfig';
import Button from '../components/buttons/Button';
import Icon from '../components/misc/Icon';
import CreateThoughtContainer from '../containers/Thoughts/CreateThoughtContainer';
import { ThoughtEventNames } from './tracking';
import { FlatButton } from '../main/components/buttons';
import styled from 'styled-components';
import { Body5 } from '../main/lib/nvstr-common-ui.es';

export const createBasicErrorModal = (message) => {
  // todo is_error important for analytics
  return (
    <div is_error={true} className="modal-message" style={{ paddingTop: '0px' }}>
      {message}
    </div>
  );
};

const createBasicSomethingWentWrongErrorModalPayload = () => {
  const message = 'Something went wrong. Please try again.';
  return createBasicErrorModal(message);
};

export const returnBasicErrorModalPayloadWithCustomMessage = (message) => {
  const modalData = {
    contentComponent: createBasicErrorModal(message),
    size: 'wide',
    dismissable: true,
  };
  return modalData;
};

export const returnBasicSomethingWentWrongErrorModalPayload = () => {
  const modalData = {
    contentComponent: createBasicSomethingWentWrongErrorModalPayload(),
    size: 'wide',
    dismissable: true,
  };
  return modalData;
};

export const createBasicErrorModalWithReloadButton = function(message) {
  return (
    <div>
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {message}
      </div>
      <div className="modal-custom-button">
        <FlatButton onClick={() => window.location.reload()}>Reload</FlatButton>
      </div>
    </div>
  );
};

export const createBasicErrorModalWithCloseButtonAction = function(message, action) {
  return (
    <div>
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {message}
      </div>
      <div className="modal-custom-button">
        <FlatButton onClick={action}>Close</FlatButton>
      </div>
    </div>
  );
};

export const createBasicErrorModalWithOkayButton = function(message, handleOkayClick) {
  return (
    <div>
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {message}
      </div>
      <div className="modal-custom-button">
        <FlatButton onClick={handleOkayClick || this.props.actions.hideModal}>Okay</FlatButton>
      </div>
    </div>
  );
};

export const createBasicModalWithOkayButton = function(message) {
  return (
    <div>
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {message}
      </div>
      <div className="modal-custom-button">
        <FlatButton onClick={this.props.actions.hideModal}>Okay</FlatButton>
      </div>
    </div>
  );
};

const ActionsWrapper = styled.div`
  padding-top: 24px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 420px;

  button {
    width: 196px;
  }
`;
const Content = styled.div``;
const Message = styled.div`
  text-align: center;
`;
export const createConfirmationModal = function({ message, secondaryComponent, onContinue, onCancel }) {
  return (
    <div className="modal-message" style={{ paddingTop: '0px' }}>
      <Content>
        <Message>
          <Body5>{message}</Body5>
        </Message>
        {secondaryComponent || null}
        <ActionsWrapper>
          <FlatButton transparent onClick={onCancel}>
            Cancel
          </FlatButton>
          <FlatButton onClick={onContinue}>Continue</FlatButton>
        </ActionsWrapper>
      </Content>
    </div>
  );
};

export const createAndShowConfirmationModal = function(config) {
  // needs to have scope to a container with access to all actions
  // when passing in functions, make sure to pre-bind any necessary params
  const message = config.message;
  const secondaryComponent = config.secondaryComponent || null;
  const handleContinue = config.handleContinue;
  const handleCancel = config.handleCancel || this.props.actions.hideModal;
  const overrideDismiss = handleCancel;

  const component = (
    <div className="modal-message" style={{ paddingTop: '0px' }}>
      <div className={'modal-message-text'}>{message}</div>
      {secondaryComponent}
      <div className={'modal-confirmation-buttons-container'}>
        <FlatButton customClass={config.continueButtonClassName} onClick={handleContinue}>
          {config.continueButtonText || 'Delete'}
        </FlatButton>
        <FlatButton customClass={config.cancelButtonClassName} onClick={handleCancel}>
          {config.cancelButtonText || 'Cancel'}
        </FlatButton>
      </div>
    </div>
  );

  const modal = {
    contentComponent: component,
    size: 'wide',
    dismissable: true,
    overrideDismiss,
  };
  this.props.actions.showModal(modal);
};

export const createSuccessModal = (props) => {
  props = props || {};
  const defaultProps = {
    containerClass: '',
  };
  const component = (
    <div className={'success-action-modal'}>
      <div className={'success-heading-container'}>
        <Icon size="xlarge" icon="fa-check" colorClassName={'success-text-color'} />
        <div style={{ marginTop: '4px', marginLeft: '8px' }}>{props.successMessage || defaultProps.successMessage}</div>
      </div>
      {props.additionalSuccessMessaging && (
        <div className={'additional-success-messaging'}>{props.additionalSuccessMessaging}</div>
      )}
    </div>
  );
  const modal = {
    contentComponent: component,
    dismissable: true,
    size: 'wide',
  };
  return modal;
};

export const createThoughtModal = (props) => {
  props = props || {};
  const component = (
    <div className={'thought-action-modal'}>
      <CreateThoughtContainer
        securityId={props.securityId}
        thoughtPostSuccessAdditionalAction={props.thoughtPostSuccessAdditionalAction}
        customMessageWithSymbol={props.customMessageWithSymbol || null}
        viewingContext={ThoughtEventNames.CREATE_THOUGHT_MODAL}
        inModal
      />
    </div>
  );
  const modal = {
    className: 'add-thought-modal',
    contentComponent: component,
    dismissable: true,
    size: 'wide',
  };
  return modal;
};

export const createContinueModal = (message, actions) => {
  return {
    size: 'wide',
    contentComponent: (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        <div className={'modal-message-text'} style={{ paddingBottom: '15px' }}>
          {message}
        </div>

        <FlatButton className="modal-action-button-container" onClick={actions.onContinue}>
          Continue
        </FlatButton>
      </div>
    ),
    overrideDismiss: actions.onContinue,
    redirect: false,
    dismissable: true,
  };
};
