import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import IconButton from '../../components/buttons/IconButton';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-left: 4px;

  background-color: ${({ theme }) => theme.themeColors.negative};
  //border: 1px solid ${({ theme }) => theme.themeColors.text};

  * {
    color: ${({ theme }) => theme.themeColors.buttonText};
  }
`;
class ImageDelete extends React.Component {
  constructor() {
    super();
    this.state = {
      deletingImage: false,
      didDeleteFail: false,
    };
  }

  render() {
    return (
      <Wrapper className="delete-image-container">
        <IconButton
          icon="fa fa-trash"
          size="large"
          customClass="image-delete-button"
          handleClick={this.handleImageDelete}
        />
      </Wrapper>
    );
  }

  handleImageDelete = () => {
    if (this.state.deletingImage) {
      return false;
    }
    this.setState({
      deletingImage: true,
    });
    this.props.actions.deleteAvatarUploadImage().then((response) => {
      const wasError = response.error;
      if (wasError) {
        this.setState({
          deletingImage: false,
        });
        this._showErrorModal();
      } else {
        // do nothing component will unmount
      }
    });
  };

  _showErrorModal = () => {
    const message = 'Unable to delete avatar. Please reload page and try again.';
    const contentComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {message}
      </div>
    );
    this.props.actions.showModal({
      contentComponent,
      size: 'wide',
      dismissable: true,
    });
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(ImageDelete);
