import React from 'react';

import TransactionsHistory from './TransactionsHistory';

const AccountHistory = (props) => {
  return (
    <div className={`account-history-container`}>
      <TransactionsHistory />
    </div>
  );
};

export default AccountHistory;
