import React from 'react';

export const Gradient = () => {
  return (
    <svg viewBox="0 0 375 812" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="-9" y="-2" width="640" height="814" fill="white" />
      <rect x="-9" y="-2" width="640" height="814" fill="url(#paint0_radial_93:199)" />
      <rect x="-9" y="-2" width="640" height="814" fill="url(#paint1_radial_93:199)" />
      <rect x="-9" y="-2" width="640" height="814" fill="url(#paint2_radial_93:199)" />
      <rect x="-9" y="-2" width="640" height="814" fill="url(#paint3_radial_93:199)" />
      <rect x="-9" y="-2" width="640" height="814" fill="url(#paint4_radial_93:199)" />
      <rect x="-9" y="-2" width="640" height="814" fill="url(#paint5_radial_93:199)" />
      <rect x="-9" y="-2" width="640" height="814" fill="url(#paint6_radial_93:199)" />
      <defs>
        <radialGradient
          id="paint0_radial_93:199"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(631 -1.99998) rotate(90) scale(814 640)"
        >
          <stop stopColor="#DCDBFF" />
          <stop offset="1" stopColor="#DCDBFF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_93:199"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(450.612 98.9772) rotate(90) scale(346.208 272.203)"
        >
          <stop stopColor="#DCDBFF" />
          <stop offset="1" stopColor="#DCDBFF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_93:199"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-42.241 692.476) rotate(90) scale(589.377 360.675)"
        >
          <stop stopColor="#F5665D" />
          <stop offset="0.53125" stopColor="#F5665D" stopOpacity="0.2" />
          <stop offset="1" stopColor="#F5665D" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_93:199"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(487.5 918.649) rotate(123.736) scale(317.805 474.343)"
        >
          <stop stopColor="#9FFBF5" />
          <stop offset="1" stopColor="#9FFBF5" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_93:199"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(129.667 1217.49) rotate(-47.173) scale(300.002 723.228)"
        >
          <stop stopColor="#F5665D" />
          <stop offset="0.635417" stopColor="#F5665D" stopOpacity="0.2" />
          <stop offset="1" stopColor="#F5665D" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_93:199"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(354.878 -255.473) rotate(90) scale(700.658 422.323)"
        >
          <stop stopColor="#F5665D" />
          <stop offset="0.635417" stopColor="#F5665D" stopOpacity="0.2" />
          <stop offset="1" stopColor="#F5665D" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_93:199"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-31.6038 354.511) rotate(-61.0324) scale(600.032 536.046)"
        >
          <stop stopColor="#FCFF7D" />
          <stop offset="0.635417" stopColor="#FCFF7D" stopOpacity="0.2" />
          <stop offset="1" stopColor="#FCFF7D" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};
