import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/index';

import HeadingWithGradientLine from '../UI/HeadingWithGradientLine';
import ViewpointSubscriptionCard from './components/ViewpointSubscriptionCard';

import {
  doesCurrentUserHaveFreeSubSlot,
  returnCurrentUserOnboardingTestGroup,
  returnCurrentUserThemeTestGroups,
} from '../../helpers/currentUserHelpers';

import {
  getAllFeaturedSubscriptionViewpointIdsFromState,
  getAllViewpointIdsFromState,
} from '../../selectors/viewpoints';
import { findUserIdeas } from '../../helpers/ideaHelpers';
import Page from '../../components/layout/Page';
import { withRouter } from '../../main/utils';

const ExploreViewpointsPage = (props) => {
  const { viewpointIds, featuredViewpointIds } = props;

  const displayViewpoints = viewpointIds.filter((id) => featuredViewpointIds.indexOf(id) === -1);

  return (
    <Page showMobileCardStyling>
      <div>
        <div className={'text-center'} style={{ margin: '0 auto' }}>
          <HeadingWithGradientLine text={'Explore Viewpoints'} additionalClasses={'full-page-card-heading'} />
        </div>

        <div className="" style={{ marginTop: 15 }}>
          {featuredViewpointIds.map((viewPointId) => (
            <div key={viewPointId} style={{ marginBottom: '15px' }}>
              <ViewpointSubscriptionCard id={viewPointId} showBio />
            </div>
          ))}
        </div>

        <div className="" style={{ marginTop: 15 }}>
          {displayViewpoints.map((viewPointId) => (
            <div key={viewPointId} style={{ marginBottom: '15px' }}>
              <ViewpointSubscriptionCard id={viewPointId} showBio />
            </div>
          ))}
        </div>
      </div>
    </Page>
  );
};

const mapStateToProps = (state) => {
  const currentUser = state.currentUser;
  const currentUserId = currentUser.id;

  const ideaCount = findUserIdeas(currentUserId, state.ideas.ideaList).length;

  const userData = {
    themeTestGroups: returnCurrentUserThemeTestGroups(currentUser),
    ideaCount,
    userId: currentUserId,
    isLiveAccount: true,
    abTestGroup: returnCurrentUserOnboardingTestGroup(currentUser),
  };

  return {
    viewpointIds: getAllViewpointIdsFromState(state),
    featuredViewpointIds: getAllFeaturedSubscriptionViewpointIdsFromState(state),
    hasFreeViewpointSelectionAvailable: doesCurrentUserHaveFreeSubSlot(currentUser),
    userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(ExploreViewpointsPage);
export default withRouter(connectedComponent);
