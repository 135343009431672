import React from 'react';
import { useNextLesson } from './useNextLesson';
import { useCurrentUser, useIsLiveTrading, useKarma } from '../user';
import { canRegisterLiveTrading } from '../../../helpers/currentUserHelpers';

export const useLessonRewardData = () => {
  const { nextLessonReward, nextLessonCompletionXP, nextLessonRewardAtXP } = useNextLesson();
  const { karmaTotal } = useKarma();

  const [willLessonCompletionGrantReward, setWillLessonCompletionGrantReward] = React.useState(false);
  const [lessonsAwayFromNextReward, setLessonsAwayFromNextReward] = React.useState(false);
  const lessonReward = willLessonCompletionGrantReward ? nextLessonReward : 0;

  const isLiveTrading = useIsLiveTrading();
  const currentUser = useCurrentUser();
  const isRegisterLiveTradingAvailable = canRegisterLiveTrading(currentUser);

  // calc lessons away from reward -> updates are ignored, should only run on mount
  React.useEffect(() => {
    const karmaTotalAfterLesson = karmaTotal + nextLessonCompletionXP;
    const calcWillLessonCompletionGrantReward = nextLessonRewardAtXP <= karmaTotalAfterLesson;

    if (calcWillLessonCompletionGrantReward !== willLessonCompletionGrantReward) {
      setWillLessonCompletionGrantReward(calcWillLessonCompletionGrantReward);
    }

    const calcLessonsAwayFromNextReward = Math.ceil((nextLessonRewardAtXP - karmaTotal) / nextLessonCompletionXP);
    if (calcLessonsAwayFromNextReward !== lessonsAwayFromNextReward) {
      setLessonsAwayFromNextReward(calcLessonsAwayFromNextReward);
    }
  }, []);

  return {
    lessonReward,
    willLessonCompletionGrantReward,
    lessonsAwayFromNextReward: isLiveTrading || isRegisterLiveTradingAvailable ? lessonsAwayFromNextReward : null,
  };
};
