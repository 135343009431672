import React from 'react';

export const DropdownItem = (props) => {
  const { itemData, closeDropdown } = props;
  const dropdownItemChildElementType = itemData.elementType;
  const dropdownItemChildProps = itemData.props;
  const dropdownItemChildChildren = itemData.children || null;
  const dropdownItemHandleClick = dropdownItemChildProps.onClickFunc;
  let handleClick = () => false;

  if (dropdownItemChildProps.shouldTriggerDropdownClose) {
    if (dropdownItemHandleClick) {
      handleClick = () => {
        dropdownItemHandleClick();
        closeDropdown();
      };
    } else {
      handleClick = closeDropdown;
    }
  }
  if (itemData.elementTypeString === 'CustomLink') {
    dropdownItemChildProps.additionalClickAction = handleClick;
  } else {
    dropdownItemChildProps.handleClick = handleClick;
  }

  const children = React.createElement(dropdownItemChildElementType, dropdownItemChildProps, dropdownItemChildChildren);
  return <li className={``}>{children}</li>;
};
