import React from 'react';

export const Heading = (props) => (
  <div className="visualizer-row border-accent" style={{ height: '60px' }}>
    <div className="visualizer-heading clearfix">
      <div className="heading-element-container text-right">
        <span className="low-opacity community-heading-text sell-text-color">Interested in Selling</span>
        <span className="community-label-left community-label secondary-text-color">
          <span style={{ display: 'inline-block' }}>% of</span>
          <span style={{ display: 'inline-block' }}>All Users</span>
        </span>
      </div>
      <div className="heading-element-container text-left">
        <span className="low-opacity community-heading-text buy-text-color">Interested in Buying</span>
        <span className="community-label-right community-label secondary-text-color">
          <span style={{ display: 'inline-block' }}>% of</span>
          <span style={{ display: 'inline-block' }}>All Users</span>
        </span>
      </div>
    </div>
  </div>
);

export default Heading;
