import React from 'react';

import ReusableResult from './ReusableResult';
import MeasureSize from '../../../ExtraFunctionalityComponents/MeasureSize';

import UserContainer from '../../../users/UserContainer';
import ProfileAvatar from '../../../../components/user/ProfileAvatar';
import UserName from '../../../../components/user/UserName';

import { isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import styled from 'styled-components';

const QueryResultsWrapper = styled.div`
  li {
    cursor: pointer;
    background-color: ${({ theme }) => theme.themeColors.component};
    color: ${({ theme }) => theme.themeColors.text};

    &.active,
    &:hover {
      background-color: ${({ theme }) => theme.themeColors.component};
      color: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }
`;

class Results extends React.Component {
  constructor() {
    super();

    this.state = {
      _activeSelection: null,

      _resultsContainerHeight: this._$returnDOMNode().height(),
    };
  }

  componentDidMount() {
    this._initListeners();
  }

  componentWillUnmount() {
    this._removeListeners();
  }

  componentDidUpdate(prevProps) {
    // was not showing results and now is showing results
    if (!this._shouldShowResults(prevProps) && this._shouldShowResults()) {
      this._onShowResults();
    }
  }

  render() {
    if (this._shouldShowResults() && this.props.showANoResultsMessage && !this._areQueryResults()) {
      return this._renderNoResultsMessage();
    }

    if (!this._shouldShowResults() || !this._areQueryResults()) {
      return null;
    }

    return (
      <div
        ref={(el) => (this.ResultsContainer = el)}
        className={`reusable-search-results-container component-bg`}
        style={this._returnStylingForResultsContainer()}
      >
        <MeasureSize onMutation={this._handleResultsSizeChange} setInitialSize updateShortlyAfterMount>
          {this._renderQueryResults()}
        </MeasureSize>
      </div>
    );
  }

  _renderNoResultsMessage = () => {
    return <div className={``}>No results</div>;
  };

  _renderQueryResults = () => {
    return (
      <QueryResultsWrapper className={`query-results-list`}>
        {this._formatQueryResultsWithResultId().map((queryResultObj, i) => this._renderQueryResult(queryResultObj, i))}
      </QueryResultsWrapper>
    );
  };

  _renderQueryResult = (queryResultObj, i) => {
    const { type } = queryResultObj;

    if (type === 'user') {
      return this._renderQueryResultUser(queryResultObj);
    } else if (type === 'security') {
      return null;
      // TODO: unimplemented _renderQueryResultSecurity
      // return this._renderQueryResultSecurity(data);
    } else {
      console.error(`Invalid result type given to <Result/>, received: ${type}`);
      return null;
    }
  };

  _renderQueryResultUser = (resultData) => {
    const userData = resultData.data;
    const userId = userData.user_id;
    return (
      <ReusableResult
        key={`user-${userId}-result-query`}
        className={`user-query-result ${this._isResultActive(resultData) ? 'active' : ''}`}
        resultData={resultData}
        handleMouseOver={this.handleMouseOver}
        handleMouseOut={this.handleMouseOut}
        handleClick={this.handleClick}
      >
        <UserContainer userId={userId}>
          <ProfileAvatar withLink={false} />
          <UserName displayNameStyle="fullName" withLink={false} />
        </UserContainer>
      </ReusableResult>
    );
  };

  _renderQueryResultSecurity = (queryResultData) => {
    return null;
  };

  _shouldShowResults = (props) => (props || this.props).shouldShowResults;

  _returnQueryResults = () => this.props.queryResults;
  _formatQueryResultsWithResultId = () =>
    this._returnQueryResults().map((resultData, i) => ({
      ...resultData,
      resultId: i,
    }));

  _areQueryResults = () => !isUndefinedOrNull(this._returnQueryResults()) && this._returnQueryResults().length > 0;
  _returnActiveSelection = () => this.state._activeSelection;
  _isResultActive = (resultData) =>
    this._returnActiveSelection() && this._returnActiveSelection().resultId === resultData.resultId;

  _returnResultsContainerHeight = () => this.state._resultsContainerHeight;
  _returnResultsContainerBottomPosition = () => `${this._returnResultsContainerHeight() * -1}px`;
  _returnStylingForResultsContainer = () => {
    return this._returnResultsContainerHeight()
      ? {
          bottom: this._returnResultsContainerBottomPosition(),
        }
      : {};
  };

  _onShowResults = () => {
    // used to keep results in frame
    if (this.props.focusOnMount && this._$returnDOMNode()) {
      this._$returnDOMNode().focus();
    }
  };

  _handleResultsSizeChange = (sizing) => {
    this._setResultsContainerHeight(sizing.height);
  };

  handleMouseOver = (resultData) => {
    this._setActiveSelection(resultData);
  };

  handleMouseOut = (resultData) => {
    // might not need to do anything
    // this._clearActiveSelection(resultData);
  };

  handleClick = (resultData) => {
    this._handleResultSelection(resultData);
  };

  _setResultsContainerHeight = (height) => {
    this.setState(() => ({
      _resultsContainerHeight: height,
    }));
  };

  _setActiveSelection = (queryResult) => {
    this.setState(() => ({
      _activeSelection: queryResult,
    }));
  };

  _clearActiveSelection = () => {
    this.setState(() => ({
      _activeSelection: null,
    }));
  };

  _setFirstResultActive = () => {
    const queryResults = this._formatQueryResultsWithResultId();
    const firstResult = queryResults ? queryResults[0] || null : null;
    if (firstResult) {
      this._setActiveSelection(firstResult);
    }
  };

  _findIndexOfActiveQueryResult = () => {
    return this._returnActiveSelection().resultId;
  };

  _cycleAndSetActiveResult = (direction) => {
    const numericDirection = direction === 'prev' ? -1 : 1;

    if (this._returnActiveSelection()) {
      const queryResults = this._formatQueryResultsWithResultId();
      const activeQueryResultIndex = this._findIndexOfActiveQueryResult();
      const newActiveQueryResult = queryResults[activeQueryResultIndex + numericDirection];

      // if no additional query results, stay at current
      this._setActiveSelection(newActiveQueryResult || this._returnActiveSelection());
    } else {
      this._setFirstResultActive();
    }
  };

  _handleResultSelection = (selection) => {
    const userSelection = selection || this._returnActiveSelection();
    if (userSelection) {
      this.props.handleResultSelection(userSelection);
    }
  };

  _handleKeyPress = (e) => {
    const keyCodeToAction = {
      38: () => this._cycleAndSetActiveResult('prev'), // up arrow
      40: () => this._cycleAndSetActiveResult('next'), // down arrow
      13: this._handleResultSelection, // enter key
    };

    const keyCode = e.keyCode;
    const action = keyCodeToAction[keyCode];

    if (action && this._shouldShowResults()) {
      action();
    }
  };

  _returnDOMNode = () => this.ResultsContainer;
  _$returnDOMNode = () => $(this._returnDOMNode());

  _initListeners = () => {
    this._addKeyStrokeListener();
  };

  _removeListeners = () => {
    this._removeKeyStrokeListener();
  };

  _addKeyStrokeListener = () => {
    $(document).on('keydown', this._handleKeyPress);
  };

  _removeKeyStrokeListener = () => {
    $(document).off('keydown', this._handleKeyPress);
  };

  _initClickStopListeners = () => {
    this._$returnDOMNode().on('click', this._stopPropagation);
  };

  _removeClickStopListeners = () => {
    this._$returnDOMNode().off('click', this._stopPropagation);
  };
}

export default Results;
