import { burstEmit, lerp } from '../helpers/generalHelpers';
import { logMetricsTrackingEvent } from '../actions';

const supportedEvents = {
  'Sign In Count': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_name: `Signed_In_${params.times}_Times`,
      value: 1.0,
      currency: 'USD',
    }),
  'Sign In At Least Count': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_name: `Signed_In_At_Least_${params.times}_Times`,
      value: 1.0,
      currency: 'USD',
    }),

  // Account Created, Currently implemented in signup_modal page js
  'Account Created': (params) =>
    _callFacebookTrackingEvent('Lead', {
      content_ids: params.themeTestGroups,
      content_name: 'Account_Created',
      value: 1.0,
      currency: 'USD',
    }),
  'Email Confirmed': (params) =>
    _callFacebookTrackingEvent('Lead', {
      content_ids: params.themeTestGroups,
      content_name: 'Email_Confirmed',
      value: 1.0,
      currency: 'USD',
    }),

  // 'Place Trade': params => _callFacebookTrackingEvent('Purchase', {
  //   content_ids: params.themeTestGroups,
  //   content_name: params.accountType, // 'Live' or 'Paper'
  //   content_type: params.tradeType, // 'Manual' or 'Optimized'
  //   num_items: params.num_items,
  //   value: params.value,
  //   currency: 'USD'
  // }),
  // Don't use a Purchase event for trades anymore
  'Place Trade': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Place Trade - ' + params.accountType + ' - ' + params.tradeType,
      value: 1.0,
      currency: 'USD',
    }),

  'Click Portfolio Refresh Button': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Click_Portfolio_Refresh_Button',
      value: 1.0,
      currency: 'USD',
    }),

  'Click Activity Page Refresh Button': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Click_Activity_Page_Refresh_Button',
      value: 1.0,
      currency: 'USD',
    }),

  'Click Feed Refresh Button': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Click_Feed_Refresh_Button',
      value: 1.0,
      currency: 'USD',
    }),

  'Click Stock Panel Tab': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Click_Stock_Panel_Tab',
      value: 1.0,
      currency: 'USD',
    }),
  'Click News Article': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Click_News_Article',
      value: 1.0,
      currency: 'USD',
    }),

  'Selected Live Account': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Live_Account_Onboarding_Path',
      value: 1.0,
      currency: 'USD',
    }),
  'Selected Paper Account': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Paper_Account_Onboarding_Path',
      value: 1.0,
      currency: 'USD',
    }),
  'Complete Apex Registration': (params) =>
    _callFacebookTrackingEvent('CompleteRegistration', {
      content_ids: params.themeTestGroups,
      content_name: 'Apex_Account',
      value: 1.0,
      currency: 'USD',
    }),
  'Linked Bank Account': (params) =>
    _callFacebookTrackingEvent('AddPaymentInfo', {
      content_ids: params.themeTestGroups,
      content_name: 'Bank_Account',
      value: 1.0,
      currency: 'USD',
    }),
  'Completed Microdeposit Bank Account Registration': (params) =>
    _callFacebookTrackingEvent('CompleteRegistration', {
      content_ids: params.themeTestGroups,
      content_name: 'Bank_Account_Thru_Microdeposits',
      value: 1.0,
      currency: 'USD',
    }),
  'Completed Plaid Bank Account Registration': (params) =>
    _callFacebookTrackingEvent('CompleteRegistration', {
      content_ids: params.themeTestGroups,
      content_name: 'Bank_Account_Thru_Plaid',
      value: 1.0,
      currency: 'USD',
    }),
  'Complete Onboarding': (params) =>
    _callFacebookTrackingEvent('CompleteRegistration', {
      content_ids: params.themeTestGroups,
      content_name: `${params.accountType}_Onboarding`,
      value: 1.0,
      currency: 'USD',
    }),
  'Skip Onboarding Add Bank Account': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Skip_Onboarding_Add_Bank_Account',
      value: 1.0,
      currency: 'USD',
    }),
  'Skip Add Bank Account': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Skip_Add_Bank_Account',
      value: 1.0,
      currency: 'USD',
    }),
  'Skip Onboarding Fund Bank Account': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Skip_Onboarding_Fund_Bank_Account',
      value: 1.0,
      currency: 'USD',
    }),
  'Skip Fund Bank Account': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Skip_Fund_Bank_Account',
      value: 1.0,
      currency: 'USD',
    }),
  'Deposit Funds': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Bank_Account_Deposit',
      value: 1.0,
      currency: 'USD',
    }),
  'Select Subscription Plan': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_name: 'Select_Subscription_Plan',
      content_type: params.subscriptionType,
      value: 1.0,
      currency: 'USD',
    }),
  'Completed Initial Subscription Payment': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_name: 'Completed_Initial_Subscription_Payment',
      content_type: params.subscriptionType,
      value: params.subscriptionValue,
      currency: 'USD',
    }),
  'Deposit Onboarding Funds': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Onboarding_Bank_Account_Deposit',
      value: 1.0,
      currency: 'USD',
    }),
  'Completed Card Payment Link': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Completed_Card_Payment_Link',
      value: 1.0,
      currency: 'USD',
    }),
  'Added Idea': (params) =>
    _callFacebookTrackingEvent('AddToWishlist', {
      content_ids: params.themeTestGroups,
      content_name: params.accountType,
      value: 1.0,
      currency: 'USD',
    }),
  'Copy Idea': (params) =>
    _callFacebookTrackingEvent('AddToWishlist', {
      content_ids: params.themeTestGroups,
      content_name: params.accountType,
      value: 1.0,
      currency: 'USD',
    }),
  'Social Share': (params) =>
    _callFacebookTrackingEvent('SocialShare', {
      value: 1.0,
      currency: 'USD',
      network: params.network,
    }),

  'View Quote': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'View_Quote',
      value: 1.0,
      currency: 'USD',
    }),
  'Clicked Thought': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Clicked_Thought',
      value: 1.0,
      currency: 'USD',
    }),

  'Clicked Performance Leader': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Clicked_Performance_Leader',
      value: 1.0,
      currency: 'USD',
    }),
  'View Thought Leader': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'View_Thought_Leader',
      value: 1.0,
      currency: 'USD',
    }),
  'Add User To Ask Form': (params) =>
    _callFacebookTrackingEvent('ViewContent', {
      content_ids: params.themeTestGroups,
      content_type: 'Add_User_To_Ask_Form',
      value: 1.0,
      currency: 'USD',
    }),
  LTV_Burst: () =>
    _callFacebookTrackingEvent('ViewContent', {
      content_type: 'LTV_Burst',
    }),
};

const eventNameToGtagActionMapping = {
  ViewContent: 'select_content',
  SocialShare: 'share',
  AddToWishlist: 'add_to_wishlist',
  CompleteRegistration: 'sign_up',
  AddPaymentInfo: 'add_payment_info',
  Purchase: 'purchase',
  Lead: 'generate_lead',
};
const _eventNameToGtagAction = (eventName) => {
  return eventName in eventNameToGtagActionMapping ? eventNameToGtagActionMapping[eventName] : eventName;
};

// internal
const _callFacebookTrackingEvent = (eventName, params, config) => {
  if (!eventName || !params) {
    return console.error('You must supply an eventName and params');
  }

  // console.log('fbq', eventName, params);
  if (window.fbq) {
    window.fbq(config && config.isCustom ? 'trackCustom' : 'track', eventName, params);
  }

  const gtagParams = {
    value: 'value' in params ? Math.floor(params.value) : 0,
    event_category:
      ('content_type' in params ? params.content_type : '') +
      ('content_type' in params && 'content_name' in params ? ' | ' : '') +
      ('content_name' in params ? params.content_name : ''),
    event_label:
      'content_ids' in params && Array.isArray(params.content_ids) ? params.content_ids.join(' | ') : '(not set)',
  };
  const gtagAction = _eventNameToGtagAction(eventName);
  // console.log('gtag', gtagAction, gtagParams);
  if (window.gtag) {
    window.gtag('event', gtagAction, gtagParams);
  }
};

// external
export const sendFacebookTrackingEvent = (eventName, params, config) => {
  if (!eventName) {
    return console.error('You must supply an event');
  }

  const event = supportedEvents[eventName];
  if (!event) {
    return console.error(`Facebook Tracking Event: '${eventName}' not found`);
  }

  return event(params);
};

export const lerpBankAccountScaleToBurstValue = (scaleValue) => {
  return Math.round(lerp(5, 25, scaleValue));
};

export const sendFacebookLTVEvent = (value) => {
  if (value < 1) {
    // console.error('value too low');
  }
  const emitterConfig = {
    burst: 3,
    burstSendDelay: 1000,
    event: () => {
      logMetricsTrackingEvent('LTV_Burst', {
        value: 1,
        ltvValue: value,
      })();
      sendFacebookTrackingEvent('LTV_Burst');
    },
  };
  burstEmit(value, emitterConfig);
};
