import React from 'react';
import styled from 'styled-components';
import { ORDER_TRANSACTION_TYPES, ORDER_TYPES, ORDER_VALUE_TYPES } from '@src/main/constants/orders';
import { useSecurity } from '@src/main/hooks/securities/useSecurity';
import { usePosition } from '@src/main/hooks/portfolio/usePosition';
import { usePriceData } from '@src/main/hooks/securities/usePriceData';
import { useBuyingPower } from '@src/main/hooks/portfolio/useBuyingPower';
import { Page } from '@src/main/components/layout';
import { Container, WarningNotice } from '@src/main/components/ui';
import { Body3, Body5, Body7, H5 } from '@src/main/lib/nvstr-common-ui.es';
import { FlatButton } from '@src/main/components/buttons';
import { DISPLAY_FORMAT_TYPES, formatValueTo } from '@src/main/utils/numbers';
import InfoIcon, { InfoIconWrapper } from '@src/containers/UI/InfoIcon';
import { INFO_ICON_WORDS } from '@src/helpers/infoIconHelpers';
import { LiveOrderPricing } from '@src/main/containers/Orders/LiveOrderPricing';
import { PRODUCT_COMMISSION_FEE } from '@src/appConfig';
import { useCurrentUser, useCurrentUserId, useIsLiveTrading } from '@src/main/hooks/user';
import { useSecurityFlags } from '@src/main/hooks/securities/useSecurityFlags';
import { INPUT_FORMAT_PROPERTIES, inputFormatter, validateInput, VALIDATION_PROPERTIES } from '@src/main/utils';
import { SupportEmail } from '@src/components/UI/messaging/ContactSupport';
import { OrderFormHelper } from '@src/main/utils/validators/orderValidation';
import { useIdeaForSecurity } from '@src/main/hooks/ideas/useIdea';
import { Warning } from '@src/main/icons/svg/Warning';
import { logMetricsTrackingEvent, quickFetchSecuritiesData } from '@src/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ACTIONS = {
  updateForm: 'uf',
  setTouched: 'st',
  setSubmitAttempted: 'ssa',
};

const initialState = {
  transactionType: '',
  orderType: ORDER_TYPES.market,

  orderValueType: ORDER_VALUE_TYPES.shares,
  shares: '',
  dollarAmount: '',

  limitPrice: '',
  stopPrice: '',

  touched: [],

  didAttemptSubmit: false, // used for active input validation rather than passive
};

const initState = (d = {}) => {
  const s = {};
  // validate that there is a value so you don't overwrite default values
  Object.entries(d).forEach((o) => {
    const [k, v] = o;
    if (!!v) {
      s[k] = v;
    }
  });
  return {
    ...initialState,
    ...s,
  };
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.updateForm: {
      const updates = action.payload;
      return {
        ...state,
        ...updates,
      };
    }

    case ACTIONS.setTouched: {
      const inputName = action.payload;
      const nextStateTouched = state.touched.includes(inputName) ? state.touched : [...state.touched, inputName];
      return {
        ...state,
        touched: nextStateTouched,
      };
    }
    case ACTIONS.setSubmitAttempted: {
      return {
        ...state,
        didAttemptSubmit: true,
      };
    }

    default:
      throw new Error();
  }
}

const NameWrapper = styled.div`
  padding-left: 5px;

  width: calc(60% - 40px);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 12px;
  color: ${({ theme }) => theme.themeColors.secondaryText};
`;
const FormWrapper = styled.div`
  padding: 24px 24px;
  width: 100%;

  select {
    cursor: pointer;
    appearance: auto;
  }
  input {
    cursor: text;
  }
  select,
  input {
    box-sizing: content-box;
    border: none;
    background: ${({ theme }) => theme.themeColors.appBackground};
    border-radius: 4px;
  }

  @media (max-width: 430px) {
    padding: 16px;
  }
  @media (max-width: 340px) {
    padding: 8px;
  }
`;
const OrderTransactionTypeSelect = styled.select`
  width: 86px;
  font-size: 24px;
  line-height: 28px;
  font-weight: 800;
  padding: 6px 8px;
  margin-left: -2px;
`;
const OrderGenericSelect = styled.select`
  width: 126px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 800;
  padding: 6px 8px;
`;
const DollarInputWrapper = styled.div`
  position: relative;
  input {
    padding-left: 22px;
  }
`;
const DollarSignWrapper = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 6px;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
`;
const OrderInput = styled.input`
  width: 120px;
  text-align: right;
  padding: 6px 8px;

  // hide up/down arrows
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type='number'] {
    -moz-appearance: textfield;
  }
`;
const Label = styled.label`
  flex: 1;
`;
const SubmitButtonWrapper = styled.div`
  padding: 24px 0 0 0;

  > div {
    width: 100% !important;
    max-width: 100% !important;
  }

  button {
    width: 100% !important;
    max-width: 100% !important;
  }
`;
const Value = styled.div`
  button {
    padding: 2px 0px;
  }
`;
const OrderSummaryRow = styled.div`
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  //border: 1px solid red; // debug
`;
const InlineToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  //
`;
const InlineToggleItemWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  ${({ theme, isActive }) => (isActive ? `border-bottom: 1px solid ${theme.themeColors.primaryCtaButton};` : '')}

  * {
    color: ${({ theme, isActive }) => (isActive ? theme.themeColors.primaryCtaButton : theme.themeColors.text)};
  }
`;
const SimulatedTradingNoticeWrapper = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.appBackground};
`;
const ExitPositionButtonWrapper = styled.div`
  button {
    padding: 4px 0px 5px 8px;
  }
`;

const InlineToggleItem = ({ item, onChange, isActive }) => {
  const { label, value } = item;

  const hc = () => {
    onChange(value);
  };
  return (
    <InlineToggleItemWrapper onClick={hc} isActive={isActive}>
      <Body5>{label}</Body5>
    </InlineToggleItemWrapper>
  );
};

const InlineToggle = ({ items, value, onChange }) => {
  return (
    <InlineToggleWrapper>
      <InlineToggleItem item={items[0]} onChange={onChange} isActive={value === items[0].value} />
      <Body5>&nbsp;{'/'}&nbsp;</Body5>
      <InlineToggleItem item={items[1]} onChange={onChange} isActive={value === items[1].value} />
    </InlineToggleWrapper>
  );
};

function parseState(state) {
  const {
    shares: _str_shares,
    dollarAmount: _str_dollarAmount,
    limitPrice: _str_limitPrice,
    stopPrice: _str_stopPrice,
  } = state;

  return {
    shares: _str_shares === '' ? 0 : parseFloat(_str_shares),
    dollarAmount: _str_dollarAmount === '' ? 0 : parseFloat(_str_dollarAmount),
    limitPrice: _str_limitPrice === '' ? 0 : parseFloat(_str_limitPrice),
    stopPrice: _str_stopPrice === '' ? 0 : parseFloat(_str_stopPrice),
  };
}

function calculateApproxOrderValue({ state, price, commissionFee }) {
  const { transactionType, orderValueType, orderType } = state;
  const { shares, dollarAmount, limitPrice, stopPrice } = parseState(state);

  const transactionTypeMultiplier = transactionType === ORDER_TRANSACTION_TYPES.buy ? 1 : -1;

  if (orderValueType === ORDER_VALUE_TYPES.dollars) {
    return transactionTypeMultiplier * dollarAmount;
  }

  const pricePerShare =
    orderType === ORDER_TYPES.limit ? limitPrice : orderType === ORDER_TYPES.stop ? stopPrice : price;
  const fees = commissionFee || 0;
  const estimateTotal = transactionTypeMultiplier * (fees + pricePerShare * shares);
  if (isNaN(estimateTotal)) return 0;
  return estimateTotal;
}

function calcTotalTradeCredits({ state, currentShares, price }) {
  if (currentShares === 0) return 1;

  const { transactionType, orderValueType } = state;
  const { shares: inputShares, dollarAmount } = parseState(state);

  const transactionTypeMultiplier = transactionType === ORDER_TRANSACTION_TYPES.buy ? 1 : -1;
  const shares =
    transactionTypeMultiplier * (orderValueType === ORDER_VALUE_TYPES.shares ? inputShares : price / dollarAmount);

  if (transactionType === ORDER_TRANSACTION_TYPES.buy && currentShares < 0) {
    return Math.abs(shares) > Math.abs(currentShares) ? 2 : 1;
  }
  if (transactionType === ORDER_TRANSACTION_TYPES.sell && currentShares > 0) {
    return Math.abs(shares) > Math.abs(currentShares) ? 2 : 1;
  }
}

const WarningIconWrapper = styled.div`
  padding: 0 4px 0 0;
  display: flex;
  align-items: center;

  svg {
    height: 18px;
    width: 18px;
  }
  fill: ${({ theme }) => theme.themeColors.warning};
`;
const ErrorText = styled.div`
  text-align: ${({ leftAlign }) => (leftAlign ? 'left' : 'right')};
  padding: 0;
  * {
    text-align: right;
    color: ${({ theme }) => theme.themeColors.error};
  }
`;
const InputRowErrorWrapper = styled.div`
  select,
  input {
    border: 3px solid ${({ theme }) => theme.themeColors.error};
  }
`;
const InputErrors = ({ field, formErrors, children, leftAlign }) => {
  const error = formErrors[field];

  if (error) {
    return (
      <InputRowErrorWrapper>
        {children}
        <ErrorText leftAlign={leftAlign}>
          <Body7>{error}</Body7>
        </ErrorText>
      </InputRowErrorWrapper>
    );
  }

  return children;
};

const validateOrderForm = (state, extraData, isSubmit) => {
  const { price: currentPrice, currentShares, isShortAllowed } = extraData;

  let errors = {};

  const fieldsBreakingRules = OrderFormHelper.generateOrderValidationErrors({
    order: state,
    currentShares,
    currentPrice,
    isShortAllowed,
  });
  fieldsBreakingRules.forEach((d) => {
    const [key, error] = d;
    errors[key] = error;
  });

  const invalidFields = OrderFormHelper.getMissingOrInvalidFields(state);
  invalidFields.forEach((d) => {
    const [key, error] = d;
    errors[key] = error;
  });

  // console.log('-----------VALIDATIONS---------------');
  // console.log('fieldsBreakingRules', invalidFields);
  // console.log('invalidFields', invalidFields);

  return errors;
};

export const CreateOrderForm = ({ securityId, initFormData, onSubmit, isFromOptimizer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, formDispatch] = React.useReducer(reducer, initialState, () => initState(initFormData));
  const [showLivePricing, setShowLivePricing] = React.useState(false);

  const [focusedInput, setFocusedInput] = React.useState(null);
  const [formErrors, setFormErrors] = React.useState({});
  const [failedToLoad, setFailedToLoad] = React.useState(false);

  const currentUserId = useCurrentUserId();
  const flags = useSecurityFlags(securityId);

  const security = useSecurity(securityId);
  const { symbol, name, is_fractional_allowed, featured, exchange } = security;
  const isFeatured = !!exchange ? featured : true; // to prevent false not featured frames when security is loading
  const idea = useIdeaForSecurity(currentUserId, securityId);
  const position = usePosition(securityId);
  const currentShares = position?.shares || 0;
  const hasPosition = Math.abs(currentShares) > 0;

  const { price } = usePriceData(securityId);

  const buyingPower = useBuyingPower();
  const isLiveTrading = useIsLiveTrading();
  const { membership_free_trade_count: freeTrades } = useCurrentUser();

  const securityTradeFlags = {
    isShortAllowed: flags?.is_short_allowed,
    isHalted: flags?.is_halted,
    isFractionalAllowed: is_fractional_allowed || hasPosition,
  };
  const fractionalDisallowedOrderTypes = [];

  const getSecurityData = async (securityId) => {
    const response = await quickFetchSecuritiesData([securityId])(dispatch);
    const security = response?.securities[0];
    if (!security) {
      setFailedToLoad(true);
    }
  };

  React.useEffect(() => {
    logMetricsTrackingEvent('Manual Trade Panel Open', {
      'Using Basket': isFromOptimizer,
      'Security ID': securityId,
      'Stock Symbol': symbol,
    })();
  }, []);

  React.useEffect(
    () => {
      if (!exchange) {
        getSecurityData(securityId);
      }
    },
    [securityId]
  );
  React.useEffect(
    () => {
      if (fractionalDisallowedOrderTypes.includes(state.orderType)) {
        if (state.orderValueType === ORDER_VALUE_TYPES.dollars) {
          formDispatch({
            type: ACTIONS.updateForm,
            payload: { orderValueType: ORDER_VALUE_TYPES.shares },
          });
        }
      }
    },
    [state]
  );

  const handleRetryLoad = () => {
    getSecurityData(securityId);
  };
  const handleCancelLoad = () => {
    navigate(-1);
  };

  const handleSubmitPress = () => {
    formDispatch({
      type: ACTIONS.setSubmitAttempted,
    });

    const extraData = {
      price,
      currentShares,
      isShortAllowed: securityTradeFlags?.isShortAllowed,
    };
    const formErrors = validateOrderForm(state, extraData, true);
    if (Object.entries(formErrors).length) {
      logMetricsTrackingEvent('Submitted Invalid Order Form', {
        'Using Basket': isFromOptimizer,
        Errors: Object.values(formErrors),
      })();

      setFormErrors(formErrors);
      return;
    }

    onSubmit(securityId, state);
  };

  const onOrderTypeChange = (e) => {
    const v = e.target.value;
    if ([ORDER_TRANSACTION_TYPES.buy, ORDER_TRANSACTION_TYPES.sell].includes(v)) {
      formDispatch({
        type: ACTIONS.updateForm,
        payload: { transactionType: v },
      });
    }
  };

  const onOrderValueTypeChange = (v) => {
    if ([ORDER_VALUE_TYPES.dollars, ORDER_VALUE_TYPES.shares].includes(v)) {
      formDispatch({
        type: ACTIONS.updateForm,
        payload: { orderValueType: v },
      });
    }
  };

  const handleOrderTypeChange = (e) => {
    const v = e.target.value;
    if ([ORDER_TYPES.market, ORDER_TYPES.limit].includes(v)) {
      formDispatch({
        type: ACTIONS.updateForm,
        payload: { orderType: v },
      });
    }
  };

  const handleOrderSharesValueChange = (e) => {
    const v = inputFormatter(state.shares, e.target.value, { [INPUT_FORMAT_PROPERTIES.float]: true });

    const validationProperties = is_fractional_allowed
      ? {
          [VALIDATION_PROPERTIES.numberInput]: true,
          [VALIDATION_PROPERTIES.maxDecimalAccuracy]: 5,
        }
      : {
          [VALIDATION_PROPERTIES.numberInput]: true,
          [VALIDATION_PROPERTIES.onlyIntegers]: true,
        };
    const isValid = validateInput(state.shares, v, validationProperties);
    if (isValid) {
      formDispatch({
        type: ACTIONS.updateForm,
        payload: { shares: v },
      });
    }
  };

  const handleOrderDollarValueChange = (e) => {
    const v = inputFormatter(state.dollarAmount, e.target.value, { [INPUT_FORMAT_PROPERTIES.float]: true });

    const validationProperties = {
      [VALIDATION_PROPERTIES.numberInput]: true,
      [VALIDATION_PROPERTIES.lockToHundredths]: true,
    };
    const isValid = validateInput(state.dollarAmount, v, validationProperties);
    if (isValid) {
      formDispatch({
        type: ACTIONS.updateForm,
        payload: { dollarAmount: v },
      });
    }
  };

  const handleOrderLimitPriceChange = (e) => {
    const v = inputFormatter(state.limitPrice, e.target.value, {
      [INPUT_FORMAT_PROPERTIES.float]: true,
      [INPUT_FORMAT_PROPERTIES.priceEntry]: true,
    });

    const validationProperties = {
      [VALIDATION_PROPERTIES.numberInput]: true,
      [VALIDATION_PROPERTIES.maxDecimalAccuracy]: 4,
    };
    const isValid = validateInput(state.limitPrice, v, validationProperties);
    if (isValid) {
      formDispatch({
        type: ACTIONS.updateForm,
        payload: { limitPrice: v },
      });
    }
  };

  const handleOrderStopPriceChange = (e) => {
    const v = inputFormatter(state.stopPrice, e.target.value, {
      [INPUT_FORMAT_PROPERTIES.float]: true,
      [INPUT_FORMAT_PROPERTIES.priceEntry]: true,
    });

    const validationProperties = {
      [VALIDATION_PROPERTIES.numberInput]: true,
      [VALIDATION_PROPERTIES.maxDecimalAccuracy]: 4,
    };
    const isValid = validateInput(state.stopPrice, v, validationProperties);
    if (isValid) {
      formDispatch({
        type: ACTIONS.updateForm,
        payload: { stopPrice: v },
      });
    }
  };

  const { transactionType, orderValueType, orderType, shares, dollarAmount, limitPrice, stopPrice } = state;

  const approxValue = calculateApproxOrderValue({ state, price });
  const tradeCreditsNeeded = calcTotalTradeCredits({ state, currentShares, price });
  const commissionFee = freeTrades >= tradeCreditsNeeded ? 0 : tradeCreditsNeeded * PRODUCT_COMMISSION_FEE;
  const hasCommissionFee = commissionFee > 0;

  const handleSeeMorePricing = () => {
    setShowLivePricing(true);
  };

  const onDismissLivePricing = () => {
    setShowLivePricing(false);
  };

  const handleExitPositionClick = () => {
    //   const event = 'Clicked Cover All Shares';

    logMetricsTrackingEvent('Clicked Sell All Shares', {
      'Using Basket': isFromOptimizer,
      'Security ID': securityId,
      'Stock Symbol': symbol,
    })();

    formDispatch({
      type: ACTIONS.updateForm,
      payload: {
        orderValueType: ORDER_VALUE_TYPES.shares,
        shares: Math.abs(currentShares),
      },
    });
  };

  const bindHandleFocus = (name) => {
    return () => setFocusedInput(name);
  };

  const bindHandleBlur = (name) => () => {
    formDispatch({
      type: ACTIONS.setTouched,
      payload: name,
    });
    setFocusedInput(null);
  };

  if (showLivePricing) {
    return <LiveOrderPricing securityId={securityId} onDismiss={onDismissLivePricing} />;
  }

  if (!isFeatured) {
    return (
      <Page width={'440px'} noPadding>
        {!isLiveTrading && (
          <SimulatedTradingNoticeWrapper>
            <WarningNotice message={'This is a simulated account - no real money is at risk.'} />
          </SimulatedTradingNoticeWrapper>
        )}
        <FormWrapper>
          <Container row verticallyCenter>
            <Container bottom={2} fullWidth>
              <Container row style={{ alignItems: 'baseline' }}>
                <H5>{symbol}</H5>
                <NameWrapper>
                  <Body5 thin isLowContrast>
                    {name}
                  </Body5>
                </NameWrapper>
              </Container>
            </Container>
          </Container>

          <Container top={16}>
            <Body3>This instrument is not currently available to trade</Body3>
          </Container>

          {Math.abs(currentShares > 0) ? (
            <Container top={16}>
              <Body3>
                To exit your position, please email <SupportEmail />.
              </Body3>
            </Container>
          ) : null}
        </FormWrapper>
      </Page>
    );
  }
  if (securityTradeFlags?.isHalted) {
    return (
      <Page width={'440px'} noPadding>
        {!isLiveTrading && (
          <SimulatedTradingNoticeWrapper>
            <WarningNotice message={'This is a simulated account - no real money is at risk.'} />
          </SimulatedTradingNoticeWrapper>
        )}
        <FormWrapper>
          <Container row verticallyCenter>
            <Container bottom={2} fullWidth>
              <Container row style={{ alignItems: 'baseline' }}>
                <H5>{symbol}</H5>
                <NameWrapper>
                  <Body5 thin isLowContrast>
                    {name}
                  </Body5>
                </NameWrapper>
              </Container>
            </Container>
          </Container>

          <Container top={16}>
            <Body3>Trading in this security is currently halted by the exchange.</Body3>
          </Container>
        </FormWrapper>
      </Page>
    );
  }

  const computeBestErrorMessage = () => {
    if (Object.entries(formErrors).length) {
      return 'Please fix required fields before submitting.';
    }

    return null;
  };

  const genericFormError = computeBestErrorMessage();
  const formWarnings = OrderFormHelper.generateOrderValidationWarnings({ order: state, idea, currentShares });

  if (failedToLoad && !exchange) {
    return (
      <Page width={'440px'} noPadding>
        <Container top={24} centerAll>
          <Body3>Unable to load this security. Please try again.</Body3>
        </Container>
        <Container row centerAll top={48}>
          <FlatButton fullWidth onClick={handleRetryLoad}>
            Retry
          </FlatButton>
        </Container>
      </Page>
    );
  }
  return (
    <Page width={'440px'} noPadding>
      {!isLiveTrading && (
        <SimulatedTradingNoticeWrapper>
          <WarningNotice message={'This is a simulated account - no real money is at risk.'} />
        </SimulatedTradingNoticeWrapper>
      )}
      <FormWrapper>
        <InputErrors field={'transactionType'} formErrors={formErrors} leftAlign>
          <Container row verticallyCenter>
            <OrderTransactionTypeSelect onChange={onOrderTypeChange} value={transactionType}>
              <option key={`buy`} value={ORDER_TRANSACTION_TYPES.buy}>
                BUY
              </option>
              <option key={`sell`} value={ORDER_TRANSACTION_TYPES.sell}>
                SELL
              </option>
            </OrderTransactionTypeSelect>

            <Container left={12} bottom={2} fullWidth>
              <Container row style={{ alignItems: 'baseline' }}>
                <H5>{symbol}</H5>
                <NameWrapper>
                  <Body5 thin isLowContrast>
                    {name}
                  </Body5>
                </NameWrapper>
              </Container>
            </Container>
          </Container>
        </InputErrors>
        <Container top={16} />

        <InputErrors
          field={orderValueType === ORDER_VALUE_TYPES.shares ? 'shares' : 'dollarAmount'}
          formErrors={formErrors}
        >
          <OrderSummaryRow>
            <Label>
              {securityTradeFlags?.isFractionalAllowed && !fractionalDisallowedOrderTypes.includes(orderType) ? (
                <InlineToggle
                  onChange={onOrderValueTypeChange}
                  value={orderValueType}
                  items={[
                    {
                      label: 'Shares',
                      value: ORDER_VALUE_TYPES.shares,
                    },
                    {
                      label: 'Dollars',
                      value: ORDER_VALUE_TYPES.dollars,
                    },
                  ]}
                />
              ) : (
                <InfoIconWrapper>
                  <Body5>Shares</Body5>
                  <InfoIcon
                    word={
                      fractionalDisallowedOrderTypes.includes(orderType)
                        ? INFO_ICON_WORDS.fractionalTradingDisabledStopOrders
                        : INFO_ICON_WORDS.fractionalTradingDisabled
                    }
                    style={{ top: '2px', right: '-16px' }}
                  />
                </InfoIconWrapper>
              )}
            </Label>
            {orderValueType === ORDER_VALUE_TYPES.shares || !securityTradeFlags?.isFractionalAllowed ? (
              <OrderInput
                type="number"
                name={'shares'}
                onFocus={bindHandleFocus('shares')}
                onBlur={bindHandleBlur('shares')}
                onChange={handleOrderSharesValueChange}
                value={shares}
                autoFocus
              />
            ) : (
              <DollarInputWrapper>
                <OrderInput
                  type="number"
                  name={'dollarAmount'}
                  onFocus={bindHandleFocus('dollarAmount')}
                  onBlur={bindHandleBlur('dollarAmount')}
                  onChange={handleOrderDollarValueChange}
                  value={dollarAmount}
                  autoFocus
                />
                <DollarSignWrapper>$</DollarSignWrapper>
              </DollarInputWrapper>
            )}
          </OrderSummaryRow>
        </InputErrors>

        {Math.abs(currentShares) > 0 ? (
          <OrderSummaryRow>
            <Label>
              <Body5>Current Shares</Body5>
            </Label>
            <Value>{formatValueTo(DISPLAY_FORMAT_TYPES.SHARES, currentShares)}</Value>
          </OrderSummaryRow>
        ) : null}
        {(Math.abs(currentShares) > 0 && transactionType === ORDER_TRANSACTION_TYPES.buy && currentShares < 0) ||
        (transactionType === ORDER_TRANSACTION_TYPES.sell && currentShares > 0) ? (
          <OrderSummaryRow>
            <Label />
            <Value>
              <ExitPositionButtonWrapper>
                <FlatButton transparent onClick={handleExitPositionClick}>
                  {currentShares < 0 ? 'Cover All' : 'Sell All'}
                </FlatButton>
              </ExitPositionButtonWrapper>
            </Value>
          </OrderSummaryRow>
        ) : null}

        <OrderSummaryRow>
          <Label>
            <InfoIconWrapper>
              <Body5>Order Type</Body5>
              <InfoIcon
                word={isLiveTrading ? INFO_ICON_WORDS.orderType : INFO_ICON_WORDS.orderTypePaperTrading}
                style={{ top: '2px', right: '-17px' }}
              />
            </InfoIconWrapper>
          </Label>
          <OrderGenericSelect onChange={handleOrderTypeChange} value={orderType} style={{ width: '88px' }}>
            <option key={`market`} value={ORDER_TYPES.market}>
              Market
            </option>
            <option key={`limit`} value={ORDER_TYPES.limit} disabled={!isLiveTrading}>
              Limit
            </option>
          </OrderGenericSelect>
        </OrderSummaryRow>

        {[ORDER_TYPES.limit, ORDER_TYPES.stopLimit].includes(orderType) ? (
          <InputErrors field={'limitPrice'} formErrors={formErrors}>
            <OrderSummaryRow>
              <Label>
                <Body5>Limit Price</Body5>
              </Label>
              <DollarInputWrapper>
                <OrderInput
                  type="number"
                  name={'limitPrice'}
                  onFocus={bindHandleFocus('limitPrice')}
                  onBlur={bindHandleBlur('limitPrice')}
                  onChange={handleOrderLimitPriceChange}
                  value={limitPrice}
                />
                <DollarSignWrapper>$</DollarSignWrapper>
              </DollarInputWrapper>
            </OrderSummaryRow>
          </InputErrors>
        ) : null}

        {[ORDER_TYPES.stop, ORDER_TYPES.stopLimit].includes(orderType) ? (
          <InputErrors field={'stopPrice'} formErrors={formErrors}>
            <OrderSummaryRow>
              <Label>
                <Body5>Stop Price</Body5>
              </Label>
              <DollarInputWrapper>
                <OrderInput
                  type="number"
                  name={'stopPrice'}
                  onFocus={bindHandleFocus('stopPrice')}
                  onBlur={bindHandleBlur('stopPrice')}
                  onChange={handleOrderStopPriceChange}
                  value={stopPrice}
                />
                <DollarSignWrapper>$</DollarSignWrapper>
              </DollarInputWrapper>
            </OrderSummaryRow>
          </InputErrors>
        ) : null}

        <OrderSummaryRow>
          <Label>
            <Body5>Current Price</Body5>
          </Label>
          <Value>{formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, price)}</Value>
        </OrderSummaryRow>

        <OrderSummaryRow>
          <Label>
            <Body5> </Body5>
          </Label>
          <Value>
            <FlatButton transparent onClick={handleSeeMorePricing}>
              More Pricing
            </FlatButton>
          </Value>
        </OrderSummaryRow>

        <OrderSummaryRow>
          <Label>
            <Body5>Buying Power</Body5>
          </Label>
          <Value>{formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, buyingPower)}</Value>
        </OrderSummaryRow>

        <OrderSummaryRow>
          <Label>
            <Body5>Estimated Value</Body5>
          </Label>
          <Value>{formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, approxValue)}</Value>
        </OrderSummaryRow>

        {hasCommissionFee ? (
          <OrderSummaryRow>
            <Label>
              {tradeCreditsNeeded > 1 ? (
                <InfoIconWrapper>
                  <Body5>Commission Fee</Body5>
                  <InfoIcon
                    word={
                      transactionType === ORDER_TRANSACTION_TYPES.sell
                        ? 'tooltip_order_form_sec_rule_for_short_sale'
                        : 'tooltip_order_form_sec_rule_for_long_sale'
                    }
                    style={{ top: '2px', right: '-17px' }}
                  />
                </InfoIconWrapper>
              ) : (
                <Body5>Commission Fee</Body5>
              )}
            </Label>
            <Value>{formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, commissionFee)}</Value>
          </OrderSummaryRow>
        ) : null}

        <SubmitButtonWrapper>
          {formWarnings.length > 0
            ? formWarnings.map((w) => (
                <Container key={w} bottom={8} centerAll>
                  <WarningIconWrapper>
                    <Warning />
                  </WarningIconWrapper>
                  <Body5>{w}</Body5>
                </Container>
              ))
            : null}
          {genericFormError && (
            <Container bottom={8}>
              <ErrorText style={{ textAlign: 'center' }}>
                <Body5>{genericFormError}</Body5>
              </ErrorText>
            </Container>
          )}
          <FlatButton fullWidth onClick={handleSubmitPress}>
            Submit
          </FlatButton>
        </SubmitButtonWrapper>
      </FormWrapper>
    </Page>
  );
};
