import React from 'react';

const ChartDown = (props) => {
  const { className, id } = props;
  return (
    <svg
      {...(id ? { id } : {})}
      className={`chart-down-icon ${className || ''}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 228.34 165.37"
    >
      <title>chart-down</title>
      <g>
        <path d="M30.38,19.48a9.39,9.39,0,0,1,.91.71L95,83.91c2.39,2.39,4.09,2.38,6.51,0L125.8,59.6c.31-.31.57-.66.78-.91l51.76,51.77-2.27,2.22c-3.62,3.62-12.74,12.73-16.35,16.36-1.45,1.46-1.72,2.7-1,4.32a3.41,3.41,0,0,0,3.46,2q18.24,0,36.48,0h11.4c2.89,0,4.13-1.25,4.13-4.18q0-10.92,0-21.84,0-12.78,0-25.56a3.76,3.76,0,0,0-1.61-3.53,3.52,3.52,0,0,0-4.76.62c-4.11,4.1-13.21,13.2-17.31,17.31a12.17,12.17,0,0,0-.87,1.13c-.52-.49-.86-.79-1.19-1.12L130.59,40.34l-.85-.85c-2-2-3.87-2-5.84,0Q111.59,51.79,99.29,64.1a12.59,12.59,0,0,0-.85,1L41.59,8.27Z" />
        <path d="M8.89,157.1H228.34v8.27H0V0H8.89Z" />
      </g>
    </svg>
  );
};

export default ChartDown;
