import React from 'react';

import TransactionDateHeading from './TransactionDateHeading';
import TransactionYearSeparator from './TransactionYearSeparator';
import TransactionsList from './TransactionsList';

const GroupedByDateTransactions = (props) => {
  const {
    className,
    date,
    transactions,
    actions,

    yearSeparator,
  } = props;
  return (
    <div className={`grouped-by-date-transactions ${className || ''}`}>
      {yearSeparator && <TransactionYearSeparator year={yearSeparator} />}
      <TransactionDateHeading date={date} />
      <TransactionsList transactions={transactions} actions={actions} />
    </div>
  );
};

export default GroupedByDateTransactions;
