import React from 'react';
import { connect } from 'react-redux';

import SectionHeading from './components/SectionHeading';
import EditSubscriptionBillingForm from './EditSubscriptionBillingForm';
import CancelSubscriptionForm from './CancelSubscriptionForm';

import { returnSubscriptionEndDateFromState } from '../../helpers/storeSelectorHelpers';
import { formatLocalizedDateTime, moment } from '../../helpers/timeHelpers';

const EditSubscriptionBilling = (props) => {
  const { subscription_ending_at } = props;

  return (
    <div className={`edit-sub-billing-form-container`}>
      <SectionHeading headingText={'Change Subscription Billing'} />
      {subscription_ending_at ? (
        <div className={`text-center`}>
          <span className=" ">
            {`Subscription will end on ${formatLocalizedDateTime('LL', moment(subscription_ending_at * 1000))}`}
          </span>
        </div>
      ) : (
        <React.Fragment>
          <EditSubscriptionBillingForm />
          <CancelSubscriptionForm />
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  subscription_ending_at: returnSubscriptionEndDateFromState(state),
});

export default connect(mapStateToProps)(EditSubscriptionBilling);
