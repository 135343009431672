import React, { Component } from 'react';
import styled from 'styled-components';
import { useWindowSize } from '@src/main/hooks/util';

const TabHeaderWrapper = styled.div`
  margin: 0 auto;

  .tabbed-container-tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;
const ThoughtTabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;

  height: 48px;
  width: ${({ width }) => width.toString() + 'px'};

  border-top-left-radius: ${({ thoughtType }) => (thoughtType === 'pro' ? '5px' : '0')};
  border-top-right-radius: ${({ thoughtType }) => (thoughtType === 'pro' ? '0' : '5px')};
  border-bottom-right-radius: ${({ thoughtType }) => (thoughtType === 'pro' ? '0' : '5px')};
  border-bottom-left-radius: ${({ thoughtType }) => (thoughtType === 'pro' ? '5px' : '0')};

  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme, isActive }) =>
    isActive ? theme.themeColors.primaryCtaButton : theme.themeColors.primaryCtaButton};

  background-color: ${({ theme, isActive }) =>
    isActive ? theme.themeColors.primaryCtaButton : theme.themeColors.appBackground};
  color: ${({ theme, isActive }) => (isActive ? theme.themeColors.buttonText : theme.themeColors.text)};

  * {
    color: ${({ theme, isActive }) => (isActive ? theme.themeColors.buttonText : theme.themeColors.primaryCtaButton)};
  }

  &:hover {
    color: ${({ theme, isActive }) => (isActive ? theme.themeColors.buttonText : theme.themeColors.primaryCtaButton)};
  }
`;

const ThoughtTab = ({ thoughtType, isActive, handleClick }) => {
  const { width } = useWindowSize();
  const tabWidth = width * 0.5 - 24;

  return (
    <ThoughtTabWrapper thoughtType={thoughtType} isActive={isActive} onClick={handleClick} width={tabWidth}>
      <span>{thoughtType + 's'}</span>
    </ThoughtTabWrapper>
  );
};

export class TabbedContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: null,
      width: null,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.forceTab && this.props.forceTab) {
      this._setTab(this.props.forceTab);
    }
  }

  render() {
    return (
      <div
        className="tabbed-container"
        ref={(el) => {
          this.tabbedContainer = el;
        }}
      >
        <TabHeaderWrapper>{this.renderTabs()}</TabHeaderWrapper>
        {this.renderActiveTabContent()}
      </div>
    );
  }

  renderTabs = () => {
    return (
      <div className="tabbed-container-tabs">
        {this._returnTabs().map((tab, i) =>
          this.renderTab({
            key: `${tab.name}-tab`,
            keyName: `${tab.name.toLowerCase()}-tab`,
            marginLeft: i === 0 ? (100 - this.determineTabWidth() * this._returnTotalTabs()) / 2 : 0,
            width: this.determineTabWidth(),
            isActive: this._isTabActive(tab.name),
            name: tab.name,
            activeCustomStyle: tab.activeCustomStyle,
            handleClick: this.handleTabClick,
            render: tab.render,
          })
        )}
        <div className="tabbed-border-line border-accent" />
      </div>
    );
  };

  renderTab = (props) => {
    const { name, isActive, key, handleClick } = props;
    const bindHandleClick = () => handleClick(name);
    return <ThoughtTab key={key} thoughtType={name} isActive={isActive} handleClick={bindHandleClick} />;
  };

  _returnDefaultActiveTab = () => this.props.defaultActiveTab || 'pro';

  _returnActiveTab = () => this.state.activeTab || this._returnDefaultActiveTab();

  _isTabActive = (tabName) => this._returnActiveTab() === tabName;

  _returnActiveTabIndex = () => (this._returnTabs()[0].name === this._returnActiveTab() ? 0 : 1);

  _returnTabs = () => this.props.tabs;

  _returnTotalTabs = () => this._returnTabs().length;

  renderActiveTabContent = () => this.props.contentComponents[this._returnActiveTabIndex()];

  handleTabClick = (tabName) => {
    if (this.props.handleTabClickAdditionalAction) {
      this.props.handleTabClickAdditionalAction(tabName);
    }
    this._setTab(tabName);
  };

  _setTab = (tabName) => {
    this.setState(() => ({
      activeTab: tabName,
    }));
  };

  determineTabWidth = () => {
    const totalTabs = this._returnTotalTabs();

    if (this.state.width > 531) {
      return (100 - 30) / totalTabs;
    } else if (this.state.width > 471) {
      return (100 - 20) / totalTabs;
    } else if (this.state.width > 411) {
      return (100 - 10) / totalTabs;
    } else {
      return (100 - 4) / totalTabs;
    }
  };

  handleResize = () => {
    this.setState({
      width: ('tabbedContainer' in this && this.tabbedContainer && this.tabbedContainer.offsetWidth) || 450,
    });
  };
}

export default TabbedContainer;
