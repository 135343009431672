import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';
import { convertNotificationGETConfigToQueryString } from '../helpers/notificationsHelpers';

const URL = `${BASEURL}/api`;

export const notificationsAdapter = {
  getUnseenNotificationsCount: () => {
    const options = { ignoreAuth: true };
    return sendRequest('get', [`${URL}/notifications/unseen_count`], options).then((response) => response);
  },

  getNotifications: (config) => {
    const options = { ignoreAuth: false };
    const query = config ? convertNotificationGETConfigToQueryString(config) : '';

    return sendRequest('get', [`${URL}/notifications${query}`], options).then((response) => response);
  },

  postUpdatedLastSeenNotificationTime: () => {
    const options = { ignoreAuth: false };
    return sendRequest('post', [`${URL}/notifications/view`, {}], options).then((response) => response);
  },

  fetchPerformanceRankingNotificationData: (dateStr) => {
    const options = { ignoreAuth: false };
    return sendRequest('get', [`${URL}/latest_ranking_notification?as_of_date=${dateStr}`], options).then(
      (response) => response
    );
  },
};
