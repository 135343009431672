import * as Yup from 'yup';
import { REGEX_DICT } from './regex';
import { isSSNObfuscated, isUndefinedOrNull } from '../../helpers/generalHelpers';
import { createTimeInstance, parseAPIDate } from '../../helpers/timeHelpers';

const citizenshipCountryOptionsThatDontRequireAdditionalInfo = ['United States of America'];

export const doesCitizenCountryRequireAdditionalInfo = (value) => {
  if (value === '' || isUndefinedOrNull(value)) {
    return false;
  }
  return !citizenshipCountryOptionsThatDontRequireAdditionalInfo.includes(value);
};

export const InvestorProfileSchema = Yup.object().shape({
  risk_profile: Yup.string().nullable(),
  total_investible_assets_usd_min: Yup.string().nullable(),
  annual_income_usd_min: Yup.string().nullable(),
});

export const ContactNameSchema = Yup.object().shape({
  given_name: Yup.string()
    .nullable()
    .max(20, 'Your first name is longer than the maximum length we can accept, please use initials where appropriate.')
    .matches(REGEX_DICT.CALIFORNIA_RULES, 'Invalid Characters')
    .required('Required'),

  family_name: Yup.string()
    .nullable()
    .max(20, 'Your last name is longer than the maximum length we can accept, please use initials where appropriate.')
    .matches(REGEX_DICT.CALIFORNIA_RULES, 'Invalid Characters')
    .required('Required'),
});

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const TrustedContactSchema = Yup.object().shape({
  trusted_contact_name: Yup.string()
    .nullable()
    .required('Required')
    .test({
      name: 'is-full-name',
      exclusive: false,
      params: {},
      message: 'Please enter at least first and last name',
      test: function(value) {
        return value.split(' ').length > 1;
      },
    }),
  trusted_contact_relationship: Yup.string()
    .nullable()
    .required('Required'),
  trusted_contact_email: Yup.string().email('Email is not valid'),
  trusted_contact_phone_number: Yup.string().when('trusted_contact_email', {
    is: (v) => v === '' || !v,
    then: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('One contact method is required'),
    otherwise: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  }),
});

export const ContactAddressSchema = Yup.object().shape({
  contact_street_address: Yup.string()
    .nullable()
    .required('Required'),

  contact_apartment: Yup.string().nullable(),

  contact_city: Yup.string()
    .nullable()
    .required('Required'),

  contact_state: Yup.string()
    .nullable()
    .required('Required'),

  contact_postal_code: Yup.string()
    .nullable()
    .matches(/^[0-9]{5}$/, 'Must be 5 digits')
    .required('Required'),

  contact_country: Yup.string()
    .nullable()
    .matches(/\bUnited States of America\b/, 'Address must be in the USA.')
    .required('Required'),
});

export const ContactPhoneNumberSchema = Yup.object().shape({
  phone_number: Yup.string()
    .nullable()
    .matches(REGEX_DICT.PHONE_DASH, 'Phone number is not valid')
    .required('Required'),
});

export const IdentitySSNSchema = Yup.object().shape({
  acknowledged_ecbsv_agreement: Yup.boolean().required('Required'),
  social_security_number: Yup.string()
    .nullable()
    .required('Required')
    .test({
      name: 'is-ssn-valid',
      exclusive: false,
      params: {},
      message: 'Invalid SSN format',
      test: function(value) {
        function areSame(arr) {
          let s = new Set(arr);
          return s.size == 1;
        }

        if (!value) return false;
        if (value[0] == 9) return false;
        if (['000'].includes(value.slice(0, 3))) return false;
        if (['0000'].includes(value.slice(7))) return false;
        const numOnly = value
          .split('-')
          .join('')
          .split('');
        if (areSame(numOnly)) return false;
        if (['123456789'].includes(numOnly.join(''))) return false;

        return value.match(REGEX_DICT.SSN);
      },
    }),
});

export const IdentityDOBSchema = Yup.object().shape({
  date_of_birth: Yup.mixed()
    .nullable()
    .test('check-age', 'Double check entered Date of Birth', (value) => {
      const date = createTimeInstance(value);
      if (date === null) {
        return false;
      }
      const yearsOld = createTimeInstance().diff(date, 'years');
      return yearsOld > 5 && yearsOld < 140;
    })
    .required('Required'),
});

export const IdentityCitizenshipSchema = Yup.object().shape({
  citizenship_country: Yup.string()
    .nullable()
    .required('Required'),

  // acknowledged_patriot_act_at: Yup.string().nullable(),
  // acknowledged_patriot_act: Yup.boolean()
  //   .nullable()
  //   .when('acknowledged_patriot_act_at', {
  //     is: (acknowledged_patriot_act_at) => isUndefinedOrNull(acknowledged_patriot_act_at),
  //     then: Yup.boolean().required('Required'),
  //     otherwise: Yup.boolean(),
  //   }),

  is_permanent_resident: Yup.string()
    .nullable()
    .when('citizenship_country', {
      is: (citizenship_country) => !doesCitizenCountryRequireAdditionalInfo(citizenship_country),
      then: Yup.string().nullable(),
      otherwise: Yup.string().required('Required'),
    }),

  green_card_number: Yup.string()
    .nullable()
    .when(['citizenship_country', 'is_permanent_resident'], {
      is: (citizenship_country, is_permanent_resident) =>
        doesCitizenCountryRequireAdditionalInfo(citizenship_country) && is_permanent_resident === 'YES',
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),

  green_card_expiration_date: Yup.mixed()
    .nullable()
    .when(['citizenship_country', 'is_permanent_resident'], {
      is: (citizenship_country, is_permanent_resident) =>
        doesCitizenCountryRequireAdditionalInfo(citizenship_country) && is_permanent_resident === 'YES',
      then: Yup.mixed().required('Required'),
      otherwise: Yup.mixed().nullable(),
    }),

  birth_country: Yup.string()
    .nullable()
    .nullable()
    .when(['citizenship_country', 'is_permanent_resident'], {
      is: (citizenship_country, is_permanent_resident) =>
        doesCitizenCountryRequireAdditionalInfo(citizenship_country) && is_permanent_resident === 'NO',
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),

  visa_type: Yup.string()
    .nullable()
    .nullable()
    .when(['citizenship_country', 'is_permanent_resident'], {
      is: (citizenship_country, is_permanent_resident) =>
        doesCitizenCountryRequireAdditionalInfo(citizenship_country) && is_permanent_resident === 'NO',
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),

  visa_number: Yup.string()
    .nullable()
    .when(['citizenship_country', 'is_permanent_resident'], {
      is: (citizenship_country, is_permanent_resident) =>
        doesCitizenCountryRequireAdditionalInfo(citizenship_country) && is_permanent_resident === 'NO',
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),

  visa_expiration_date: Yup.mixed()
    .nullable()
    .when(['citizenship_country', 'is_permanent_resident'], {
      is: (citizenship_country, is_permanent_resident) =>
        doesCitizenCountryRequireAdditionalInfo(citizenship_country) && is_permanent_resident === 'NO',
      then: Yup.mixed().required('Required'),
      otherwise: Yup.mixed().nullable(),
    }),
});

export const DisclosuresSchema = Yup.object().shape({
  is_control_person: Yup.string().required('Required'),
  is_politically_exposed: Yup.string().required('Required'),
  is_affiliated_exchange_or_finra: Yup.string().required('Required'),

  company_symbols: Yup.string()
    .nullable()
    .when('is_control_person', {
      is: (is_control_person) => is_control_person === 'YES',
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
  immediate_family: Yup.string()
    .nullable()
    .when('is_politically_exposed', {
      is: (is_politically_exposed) => is_politically_exposed === 'YES',
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
  political_organization: Yup.string()
    .nullable()
    .when('is_politically_exposed', {
      is: (is_politically_exposed) => is_politically_exposed === 'YES',
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
  firm_name: Yup.string()
    .nullable()
    .when('is_affiliated_exchange_or_finra', {
      is: (is_affiliated_exchange_or_finra) => is_affiliated_exchange_or_finra === 'YES',
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(),
    }),
});

export const DisclosuresEmploymentSchema = Yup.object().shape({
  is_tornado_employee: Yup.string().required('Required'),
  // is_affiliated_apex: Yup.string().required('Required'),
});

export const AgreementsSchema = Yup.object().shape({
  acknowledged_account_agreement: Yup.boolean()
    .required('Required')
    .oneOf([true], 'Must acknowledge agreement'),
});

export const BankAccountSchema = Yup.object().shape({
  bank_account_type: Yup.string().required('Required'),
  bank_routing_number: Yup.string()
    .required('Required')
    .matches(/^[0-9]{9}$/, 'Routing number is not valid'),
  bank_account: Yup.string()
    .required('Required')
    .matches(/^[0-9-]{4,17}$/, 'Unable to accept bank account'),
});

export const BankTransactionSchema = Yup.object().shape({
  account_id: Yup.string().required(),
  funding_operation: Yup.string().required(),
  amount: Yup.string().required(),
});

// example properties/ usage
// const InvestorProfileSchema = Yup.object().shape({
//   riskProfile: Yup.number()
//     .moreThan(0, 'Shares cannot be 0')
//     .integer('Shares cannot be fractional, if exiting a position, email support to trade')
//     .required('Required'),
//   type: Yup.string()
//     .required('Required'),
//   operation: Yup.string()
//     .required('Required'),
//   limit_price: Yup.string()
//     .when('type', {
//       is: (type) => isLimitPriceRequired(type),
//       then: Yup.string().required('Required'),
//       otherwise: Yup.string(),
//     }),
//   stop_price: Yup.string()
//     .when('type', {
//       is: (type) => isStopPriceRequired(type),
//       then: Yup.string().required('Required'),
//       otherwise: Yup.string(),
//     })
// });
