import React from 'react';
import { PRODUCT_NAME } from '@src/appConfig';

import EquityHistory from '../components/Sections/EquityHistory';
import AccountHistory from '../components/Sections/AccountHistory';
import PortfolioMetrics from '../components/Sections/PortfolioMetrics';

import Page from '../../../components/layout/Page';
import PageWrapper from '../../ExtraFunctionalityComponents/PageWrapper';
import PageDivider from '../../../components/UI/PageDivider';
import EquityHistoryData from '../components/EquityHistoryData';
import ChartTabs from '../../../components/Charts/ChartTabs';
import PerformanceComparisonSecuritiesManager from '../../charts/PerformanceComparisonSecuritiesManager';
import PortfolioSummary from '../../../main/containers/Portfolio/PortfolioSummary';
import styled from 'styled-components';
import { useCurrentUser } from '../../../main/hooks/user';

const PortfolioSummaryWrapper = styled.div`
  > div:first-child {
    margin-right: 2.5%;
    margin-left: 2.5%;
    width: 95%;
    max-width: 950px;
  }
  > div:first-child {
    width: 100%;
    max-width: 950px;

    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 1199px) {
    > div:first-child {
      margin-right: 2.5%;
      margin-left: 2.5%;
      width: 95%;
      max-width: 950px;
    }
  }
`;

const HistoryPage = () => {
  const currentUser = useCurrentUser();
  const isAccountClosed = currentUser.is_account_closed;
  return (
    <PageWrapper
      eventName={'View History'}
      pageTitle={`${PRODUCT_NAME} - History`}
      className={'history-page-container'}
      requests={['fetchCurrentUserQuickInfo', 'fetchHistoryTransactionData', 'fetchHistoryEquityData']}
    >
      {!isAccountClosed && (
        <PortfolioSummaryWrapper>
          <PortfolioSummary hideRefresh />
        </PortfolioSummaryWrapper>
      )}
      <Page>
        {null && (
          <ChartTabs eventName="Equity Chart Tab Clicked">
            {(activeTab, TabsRenderer) => (
              <EquityHistoryData activeTab={activeTab}>
                {(equityHistory) => (
                  <PerformanceComparisonSecuritiesManager equityHistory={equityHistory}>
                    {(securityIds, additionalDatasets, PerformanceComparisonSecuritiesRenderer) => (
                      <EquityHistory
                        activeTab={activeTab}
                        chartData={equityHistory}
                        additionalDatasets={additionalDatasets}
                        TabsRenderer={TabsRenderer}
                        PerformanceComparisonSecuritiesRenderer={PerformanceComparisonSecuritiesRenderer}
                      />
                    )}
                  </PerformanceComparisonSecuritiesManager>
                )}
              </EquityHistoryData>
            )}
          </ChartTabs>
        )}

        {/*<PageDivider style={{ paddingTop: 25 }} />*/}

        {/*<PortfolioMetrics />*/}

        {/*<PageDivider style={{ paddingTop: 25 }} />*/}

        <AccountHistory />
      </Page>
    </PageWrapper>
  );
};

export default HistoryPage;
