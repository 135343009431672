import { QUERY_SECURITIES, QUERY_RESULTS } from '../constants';

const defaultState = {
  queries: [],
  data: {},
};

// definitions for reducer case statements
let query, results;

export default function searchReducer(state = defaultState, action) {
  switch (action.type) {
    case QUERY_SECURITIES:
      query = action.payload;
      return {
        ...state,
        queries: [...state.queries, query],
      };

    case QUERY_RESULTS:
      query = action.payload.query;
      results = action.payload.results;

      return {
        ...state,
        data: {
          ...state.data,
          [query]: [...results],
        },
      };

    default:
      return state;
  }
}
