import React from 'react';

import { ReloadButton } from '../buttons/Reload';
import Overlay from '../../components/layout/Overlay';
import Panel from '../../components/layout/Panel';

export const ReloadMessage = (props) => {
  const _renderComponent = () => (
    <div className={`reload-message-container`}>
      <p>Something went wrong. Please reload the page.</p>
      <ReloadButton />
    </div>
  );

  if (props.renderComponentOnly) {
    return _renderComponent();
  } else {
    return (
      <Overlay customClass={'priority-z-index'}>
        <Panel className="reload-message-panel component-bg" withoutOverlay>
          {_renderComponent()}
        </Panel>
      </Overlay>
    );
  }
};
