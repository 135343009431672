import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const ordersAdapter = {
  getOrders: () => {
    return sendRequest('get', [`${URL}/orders`]);
  },

  createOrders: (orders) => {
    const body = { orders };
    return sendRequest('post', [`${URL}/orders`, body]);
  },

  placeOrders: (orderIds, acceptedWarnings) => {
    const body = { order_ids: orderIds };
    if (acceptedWarnings && acceptedWarnings.length > 0) {
      body.override_warnings = acceptedWarnings;
    }
    return sendRequest('post', [`${URL}/orders/place`, body]);
  },

  createAndPlaceOrder: (order, acceptedWarnings) => {
    const body = { order };
    if (acceptedWarnings && acceptedWarnings.length > 0) {
      body.override_warnings = acceptedWarnings;
    }
    return sendRequest('post', [`${URL}/order/place`, body]);
  },

  updateOrderInBasket: (order) => {
    const body = order;
    return sendRequest('put', [`${URL}/orders/${order.order_id}`, body]);
  },

  deleteOrder: (orderId) => {
    return sendRequest('delete', [`${URL}/orders/${orderId}`]);
  },

  cancelPlacedOrder: (orderId) => {
    return sendRequest('post', [`${URL}/orders/${orderId}/cancel`]);
  },

  clearOrderBasket: () => {
    return sendRequest('post', [`${URL}/orders/delete_all`]);
  },

  dismissDayTraderMessage: () => {
    return sendRequest('post', [`${URL}/orders/dismiss_day_trader_message`]);
  },

  getOrderCommission: (orderData) => {
    const { order_id } = orderData;
    const url = order_id ? `${URL}/v1/orders/${order_id}/commission` : `${URL}/v1/orders/commission`;
    return sendRequest('get', [url]);
  },
};
