export const UTM_KEYS = ['utm_source', 'utm_content', 'utm_medium', 'utm_term', 'utm_campaign'];

export const UTMTrackingKeys = {
  utm_source: 'UTM Source',
  utm_content: 'UTM Content',
  utm_medium: 'UTM Medium',
  utm_term: 'UTM Term',
  utm_campaign: 'UTM Campaign',
};

export const THE_STREET_NEWS_UTM_CAMPAIGNS = {
  feed: 'feed',
  proCon: 'pro_con',
  newsTab: 'news_tab',
  tsArticle: 'thestreet_article',
};
