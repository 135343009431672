import React from 'react';
import styled from 'styled-components';
import { formatForDisplay } from '../../../helpers/displayHelpers';
import { ValueChangeText } from '../../lib/nvstr-common-ui.es';
import { InlinePriceValue } from '../text/ui/securities';
import { usePriceData } from '../../hooks/securities/usePriceData';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 4px;

  opacity: ${({ value }) => (value === 0 ? '0.6' : '1')};
`;

const PriceWrapper = styled.div``;
const PriceChangePercentWrapper = styled.div``;

export const PRICE_DISPLAY_TYPES = {
  inline: 'i',
};

export const Price = ({ securityId, type }) => {
  const { price, percentChange } = usePriceData(securityId);

  if (price === null || isNaN(price)) {
    return null;
  }

  if (type === PRICE_DISPLAY_TYPES.inline)
    return (
      <ValueChangeText value={percentChange}>
        <Wrapper value={percentChange}>
          <PriceWrapper>
            <InlinePriceValue>{formatForDisplay(price, 'float')}</InlinePriceValue>
          </PriceWrapper>
          <PriceChangePercentWrapper>
            <InlinePriceValue>{formatForDisplay(percentChange, 'percentChange')}</InlinePriceValue>
          </PriceChangePercentWrapper>
        </Wrapper>
      </ValueChangeText>
    );

  return null;
};
