import { isUndefinedOrNull } from './generalHelpers';

export const sortUsersByLastName = (users) =>
  users.sort((a, b) => {
    const bFirstName = b.first_name;
    const bLastName = b.last_name;

    const aFirstName = a.first_name;
    const aLastName = a.last_name;

    const bName = `${bLastName} ${bFirstName}`;
    const aName = `${aLastName} ${aFirstName}`;
    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  });

export const sortUsersByTodayPerf = (users) => {
  const usersNonPrivate = users.filter((user) => !isUndefinedOrNull(user.performance_today));
  const usersPrivate = users.filter((user) => isUndefinedOrNull(user.performance_today));
  usersNonPrivate.sort((a, b) => {
    const bPerformance = b.performance_today;
    const aPerformance = a.performance_today;
    if (aPerformance > bPerformance) {
      return -1;
    }
    if (aPerformance < bPerformance) {
      return 1;
    }
    return 0;
  });
  return [...usersNonPrivate, ...usersPrivate];
};

export const sortUsersByLastMonthPerf = (users) => {
  const usersNonPrivate = users.filter((user) => !isUndefinedOrNull(user.performance_today));
  const usersPrivate = users.filter((user) => isUndefinedOrNull(user.performance_today));
  usersNonPrivate.sort((a, b) => {
    const bPerformance = b.performance_last_month;
    const aPerformance = a.performance_last_month;
    if (aPerformance > bPerformance) {
      return -1;
    }
    if (aPerformance < bPerformance) {
      return 1;
    }
    return 0;
  });
  return [...usersNonPrivate, ...usersPrivate];
};

export const sortUsersByThisMonthPerf = (users) => {
  const usersNonPrivate = users.filter((user) => !isUndefinedOrNull(user.performance_today));
  const usersPrivate = users.filter((user) => isUndefinedOrNull(user.performance_today));
  usersNonPrivate.sort((a, b) => {
    const bPerformance = b.performance_this_month;
    const aPerformance = a.performance_this_month;
    if (aPerformance > bPerformance) {
      return -1;
    }
    if (aPerformance < bPerformance) {
      return 1;
    }
    return 0;
  });
  return [...usersNonPrivate, ...usersPrivate];
};

export const sortUsersByLastWeekPerf = (users) => {
  const usersNonPrivate = users.filter((user) => !isUndefinedOrNull(user.performance_today));
  const usersPrivate = users.filter((user) => isUndefinedOrNull(user.performance_today));
  usersNonPrivate.sort((a, b) => {
    const bPerformance = b.performance_last_week;
    const aPerformance = a.performance_last_week;
    if (aPerformance > bPerformance) {
      return -1;
    }
    if (aPerformance < bPerformance) {
      return 1;
    }
    return 0;
  });
  return [...usersNonPrivate, ...usersPrivate];
};
