import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../../actions/index';

import Checkbox from '../../StandaloneInputs/Checkbox';
import DropdownWithStrings from '../../StandaloneInputs/DropdownWithStrings';
import Input from '../../StandaloneInputs/Input';
import Button from '../../../components/buttons/Button';

import AcceptedCCsList from '../../../containers/UI/AcceptedCCsList';

import { getLiveTradingAccountDataFromLiveAccountState } from '../../../helpers/storeSelectorHelpers';
import { listUsStateNames, listCountryNames } from '../../../helpers/formHelpers';

class CreditCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        cardNumber: '',
        expMonth: '',
        expYear: '',
        cardCode: '',
        cardHolderFirstName: '',
        cardHolderLastName: '',

        street: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      },
      useLiveAccountAddress: !props.hideUseAccountAddress,
      errors: {},
    };
  }

  componentDidMount() {
    this.props.actions.getLiveTradingAccount();
    if (this.props.startingFormData) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          ...this.props.startingFormData,
        },
      }));
    }
  }

  _returnAllFieldNames = () => [
    'cardNumber',
    'expMonth',
    'expYear',
    'cardCode',
    'cardHolderFirstName',
    'cardHolderLastName',

    'street',
    'city',
    'state',
    'zip',
    'country',
  ];

  _isFieldRequired = (fieldName) => {
    const alwaysRequiredFieldsList = [
      'cardNumber',
      'expMonth',
      'expYear',
      'cardCode',
      'cardHolderFirstName',
      'cardHolderLastName',
    ];
    const addressRequiredFields = ['street', 'city', 'state', 'zip', 'country'];
    if (this._shouldUseLiveAccountAddress()) {
      return alwaysRequiredFieldsList.includes(fieldName);
    } else {
      return alwaysRequiredFieldsList.includes(fieldName) || addressRequiredFields.includes(fieldName);
    }
  };

  _setErrorForField = (fieldName, error) => {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [fieldName]: error,
      },
    }));
  };

  _clearErrorForField = (fieldName) => {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [fieldName]: null,
      },
    }));
  };

  _isFieldMissingValue = (value) => value === '';

  _returnRequiredFieldErrorMessage = () => 'Field is required';

  _returnMaskedCardNumberErrorMessage = () => 'Please re-enter card number';

  _validateField = (fieldName, value) => {
    if (this._isFieldRequired(fieldName) && this._isFieldMissingValue(value)) {
      this._setErrorForField(fieldName, this._returnRequiredFieldErrorMessage());
      return false;
    }
    if (fieldName === 'cardNumber' && this.returnCardNumberValue().includes('*')) {
      this._setErrorForField(fieldName, this._returnMaskedCardNumberErrorMessage());
      return false;
    }
    return true;
  };

  _validateForm = () => {
    let isFormValid = true;
    this._returnAllFieldNames().forEach((fieldName) => {
      const isValid = this._validateField(fieldName, this.returnValueForFieldName(fieldName));
      if (!isValid) {
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  _doesFieldHaveError = (fieldName) => !!this.returnFieldError(fieldName);

  render() {
    return (
      <div className={`credit-card-form-container`}>
        <div className={`credit-card-payment-inputs`}>
          <Input
            containerClassName={'credit-card-input card-holder-first-name'}
            type={'text'}
            name={'cardHolderFirstName'}
            label={'First Name'}
            value={this.returnCardHolderFirstNameValue()}
            errorMessage={this.returnFieldError('cardHolderFirstName')}
            handleChange={this.handleInputChange}
            handleBlur={this.handleInputBlur}
            isFloatingStyle
          />
          <Input
            containerClassName={'credit-card-input card-holder-last-name'}
            type={'text'}
            name={'cardHolderLastName'}
            label={'Last Name'}
            value={this.returnCardHolderLastNameValue()}
            errorMessage={this.returnFieldError('cardHolderLastName')}
            handleChange={this.handleInputChange}
            handleBlur={this.handleInputBlur}
            isFloatingStyle
          />
          <Input
            containerClassName={'credit-card-input card-number'}
            type={this.returnCardNumberValue().includes('*') ? 'text' : 'number'}
            name={'cardNumber'}
            label={'Card Number'}
            value={this.returnCardNumberValue()}
            errorMessage={this.returnFieldError('cardNumber')}
            handleChange={this.handleInputChange}
            handleBlur={this.handleInputBlur}
            isFloatingStyle
            renderAdditionalComponentWhenNoValOrFocus={<AcceptedCCsList />}
          />
          <Input
            containerClassName={'credit-card-input expiry-month'}
            type={'number'}
            name={'expMonth'}
            label={'Expiry Month'}
            value={this.returnCardExpiryMonthValue()}
            errorMessage={this.returnFieldError('expMonth')}
            handleChange={this.handleInputChange}
            handleBlur={this.handleInputBlur}
            isFloatingStyle
          />
          <Input
            containerClassName={'credit-card-input expiry-year'}
            type={'number'}
            name={'expYear'}
            label={'Expiry Year'}
            value={this.returnCardExpiryYearValue()}
            errorMessage={this.returnFieldError('expYear')}
            handleChange={this.handleInputChange}
            handleBlur={this.handleInputBlur}
            isFloatingStyle
          />
          <Input
            containerClassName={'credit-card-input cvc-code'}
            type={'number'}
            name={'cardCode'}
            label={'CVC'}
            value={this.returnCardCodeValue()}
            errorMessage={this.returnFieldError('cardCode')}
            handleChange={this.handleInputChange}
            handleBlur={this.handleInputBlur}
            isFloatingStyle
          />
          {!this.props.hideUseAccountAddress && (
            <Checkbox
              name={'use-live-account-address'}
              label={'Use Account Address For My Billing Address'}
              value={this.getUseLiveAccountAddressValue()}
              handleClick={this.handleToggleUseLiveAccountAddress}
            />
          )}
        </div>
        {!this._shouldUseLiveAccountAddress() && this.renderBillingAddressForm()}
        <div className={`form-action-container`}>
          <Button
            customClass={`btn btn-primary-color btn-small-tall btn-fixed-width-95`}
            text={
              this.props.useIsSubmittingBtn && this.props.isSubmitting
                ? 'Loading'
                : this.props.submitBtnText || 'Submit'
            }
            handleClick={this.handleSubmit}
          />
          {!this.props.hideCancelBtn && (
            <Button
              customClass={`btn-flat btn-flat-grey btn-small-tall cancel-btn`}
              text={'Cancel'}
              handleClick={this.props.handleCancel}
            />
          )}
        </div>
      </div>
    );
  }

  renderBillingAddressForm = () => {
    return (
      <div className={`credit-card-billing-address-inputs`}>
        <Input
          containerClassName={'credit-card-input street-address'}
          type={'text'}
          name={'street'}
          label={'Street Address'}
          value={this.returnStreetAddressValue()}
          errorMessage={this.returnFieldError('street')}
          handleChange={this.handleInputChange}
          handleBlur={this.handleInputBlur}
          isFloatingStyle
        />
        <Input
          containerClassName={'credit-card-input city-input'}
          type={'text'}
          name={'city'}
          label={'City'}
          value={this.returnCityValue()}
          errorMessage={this.returnFieldError('city')}
          handleChange={this.handleInputChange}
          handleBlur={this.handleInputBlur}
          isFloatingStyle
        />
        <div className={`credit-card-input state-input`}>
          <DropdownWithStrings
            type={'text'}
            name={'state'}
            label={'State'}
            value={this.returnStateValue()}
            options={this.returnStateDropdownOptions()}
            errorMessage={this.returnFieldError('state')}
            handleSelection={this.handleDropdownSelection}
            handleBlur={this.handleInputBlur}
            includeLabelAsSelection
            showArrowIcons
          />
        </div>
        <Input
          containerClassName={'credit-card-input zip-input'}
          type={'number'}
          name={'zip'}
          label={'Postal Code'}
          value={this.returnZipValue()}
          errorMessage={this.returnFieldError('zip')}
          handleChange={this.handleInputChange}
          handleBlur={this.handleInputBlur}
          isFloatingStyle
        />
        <div className={`credit-card-input country-input`}>
          <DropdownWithStrings
            type={'number'}
            name={'country'}
            label={'Country'}
            value={this.returnCountryValue()}
            options={this.returnCountryDropdownOptions()}
            errorMessage={this.returnFieldError('country')}
            handleSelection={this.handleDropdownSelection}
            handleBlur={this.handleInputBlur}
            includeLabelAsSelection
            showArrowIcons
          />
        </div>
      </div>
    );
  };

  renderBillingAddressData = () => {
    const billingAddressData = this._returnBillingInformation();
    const { street, city, state, zip, country } = billingAddressData;
    return (
      <div className={`credit-card-billing-address-inputs`}>
        <div className={`billing-address-row`}>{street}</div>
        <div className={`billing-address-row`}>{`${city}, ${state} ${zip}`}</div>
        <div className={`billing-address-row`}>{country}</div>
      </div>
    );
  };

  returnFormData = () => this.state.formData;

  returnFormErrorMessageLookup = () => this.state.errors;

  returnValueForFieldName = (fieldName) => this.returnFormData()[fieldName];

  returnFieldError = (fieldName) => this.returnFormErrorMessageLookup()[fieldName];

  returnStateDropdownOptions = () => listUsStateNames();

  returnCountryDropdownOptions = () => listCountryNames();

  handleDropdownSelection = (fieldName, value) => {
    this._setInputValue(fieldName, value);
    if (this._validateField(fieldName, value) && this._doesFieldHaveError(fieldName)) {
      this._clearErrorForField(fieldName);
    }
  };

  handleInputChange = (fieldName, value) => {
    if (this._doesFieldHaveError(fieldName)) {
      if (this._validateField(fieldName, value)) {
        this._clearErrorForField(fieldName);
      }
    }
    if (fieldName === 'expYear') {
      if (value.length > 2) {
        return false;
      }
    }
    this._setInputValue(fieldName, value);
  };

  handleInputBlur = (value, fieldName) => {
    this._validateField(fieldName, value);
  };

  _setInputValue = (fieldName, value) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [fieldName]: value,
      },
    }));
  };

  getUseLiveAccountAddressValue = () => this._shouldUseLiveAccountAddress();

  handleToggleUseLiveAccountAddress = () => {
    this._toggleUseLiveAccountAddress();
  };

  handleSubmit = () => {
    if (this._validateForm()) {
      const formData = {
        ...this._returnCreditCardFormDataForSubmission(),
        ...this._returnBillingInformation(),
      };
      this.props.handleSubmit(formData);
    }
  };

  _returnCreditCardFormDataForSubmission = () => ({
    cardNumber: this.returnValueForFieldName('cardNumber'),
    expMonth: this.returnValueForFieldName('expMonth'),
    expYear: this.returnValueForFieldName('expYear'),
    cardCode: this.returnValueForFieldName('cardCode'),
    cardHolderFirstName: this.returnValueForFieldName('cardHolderFirstName'),
    cardHolderLastName: this.returnValueForFieldName('cardHolderLastName'),
  });

  _returnBillingInformation = () =>
    this._shouldUseLiveAccountAddress()
      ? this.returnLiveAccountAddress()
      : {
          street: this.returnValueForFieldName('street'),
          city: this.returnValueForFieldName('city'),
          state: this.returnValueForFieldName('state'),
          zip: this.returnValueForFieldName('zip'),
          country: this.returnValueForFieldName('country'),
        };

  _shouldUseLiveAccountAddress = () => this.state.useLiveAccountAddress;

  _toggleUseLiveAccountAddress = () => {
    this.setState((prevState) => ({
      useLiveAccountAddress: !prevState.useLiveAccountAddress,
    }));
  };

  returnLiveAccountAddress = () => {
    const {
      contact_street_address,
      contact_apartment,
      contact_city,
      contact_state,
      contact_postal_code,
      contact_country,
    } = this.returnLiveAccountData();
    return {
      street: contact_street_address,
      apartment: contact_apartment,
      city: contact_city,
      state: contact_state,
      zip: contact_postal_code,
      country: contact_country,
    };
  };

  returnCardHolderFirstNameValue = () => this.returnFormData().cardHolderFirstName;
  returnCardHolderLastNameValue = () => this.returnFormData().cardHolderLastName;
  returnCardNumberValue = () => this.returnFormData().cardNumber;
  returnCardExpiryMonthValue = () => this.returnFormData().expMonth;
  returnCardExpiryYearValue = () => this.returnFormData().expYear;
  returnCardCodeValue = () => this.returnFormData().cardCode;

  returnStreetAddressValue = () => this.returnFormData().street;
  returnCityValue = () => this.returnFormData().city;
  returnStateValue = () => this.returnFormData().state;
  returnZipValue = () => this.returnFormData().zip;
  returnCountryValue = () => this.returnFormData().country;

  returnLiveAccountData = () => this.props.liveAccountData;
}

const mapStateToProps = (state) => {
  return {
    liveAccountData: getLiveTradingAccountDataFromLiveAccountState(state.liveAccount),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(CreditCard);

export default connectedComponent;
