import {
  ADD_CURRENT_USER_QUICK_INFO,
  UPDATE_USER_PROPERTY,
  CONVERT_CURRENT_USER_TO_LIVE_TRADING,
  END_ONBOARDING,
  ADD_EDIT_PROFILE_PAGE,
  REMOVE_EDIT_PROFILE_PAGE,
  REJECT_LIVE_TRADING_ACCOUNT,
  USE_TRADING_CREDITS,
  TRANSACTION_ACCEPTED,
  SET_SUBSCRIBER,
  UPLOAD_AVATAR_IMAGE,
  DELETE_AVATAR_IMAGE,
  AGREE_TO_BD_TERMS,
  START_MEMBERSHIP,
} from '../constants';
import { OVERRIDE_CURRENT_USER_DATA } from '../../config';

const defaultState = {
  editProfilePage: null,
};

export default function currentUserReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_CURRENT_USER_QUICK_INFO: {
      return {
        ...state,
        ...action.payload,
        ...OVERRIDE_CURRENT_USER_DATA,
      };
    }

    case UPDATE_USER_PROPERTY:
      const property = action.payload;
      return {
        ...state,
        ...property,
      };

    case AGREE_TO_BD_TERMS:
      return {
        ...state,
        has_agreed_to_bd_terms: true,
      };

    case CONVERT_CURRENT_USER_TO_LIVE_TRADING:
      return {
        ...state,
        is_live_trading: true,
      };

    case END_ONBOARDING:
      return {
        ...state,
        has_completed_onboarding: true,
      };

    case SET_SUBSCRIBER:
      return {
        ...state,
        is_subscriber: true,
      };

    case ADD_EDIT_PROFILE_PAGE:
      return {
        ...state,
        editProfilePage: action.payload.page,
      };

    case REMOVE_EDIT_PROFILE_PAGE:
      return {
        ...state,
        editProfilePage: null,
      };

    case REJECT_LIVE_TRADING_ACCOUNT:
      return {
        ...state,
        allow_live_trading: false,
      };

    case USE_TRADING_CREDITS:
      const tradingCreditsUsed = action.payload.tradingCreditsUsed;
      return {
        ...state,
        free_credit_available: state.free_credit_available - tradingCreditsUsed,
      };

    case TRANSACTION_ACCEPTED:
      return {
        ...state,
        has_funded: true,
        eligible_for_funding_match_reward: false,
      };

    case UPLOAD_AVATAR_IMAGE:
      return {
        ...state,
        avatar_url: action.payload,
      };

    case DELETE_AVATAR_IMAGE:
      return {
        ...state,
        avatar_url: action.payload,
      };

    case START_MEMBERSHIP:
      return state;

    default:
      return state;
  }
}
