import {
  LOADING_REWARDS_DATA,
  GET_REWARDS_DATA,
  GET_REWARDS_DATA_ERROR,
  RECEIVED_RANDOM_REWARD_REDEMPTION,
  RECEIVED_REFERRAL_REWARD_REDEMPTION,
  RECEIVED_FUNDING_REWARD_REDEMPTION,
} from '../constants';

const defaultState = {
  initializing: true,
  loading: false,
  loaded: false,
  wasError: false,

  completed_referral_count: 0,
  referral_count: 0,
  live_trading_approved_referral_count: null,

  cash_requiring_trade_to_keep: null,
  next_cash_expiration_date: null,

  referral_reward_total_redeemed: null,
  referral_reward_total_approved: null,

  funding_reward_total_redeemed: null,
  funding_reward_total_approved: null,

  referral_rewards_not_redeemed_count: null,
  funding_rewards_not_redeemed_count: null,

  options_total_redeemed: null,
};

export default function rewardsReducer(state = defaultState, action) {
  switch (action.type) {
    case LOADING_REWARDS_DATA:
      return {
        ...state,
        loading: true,
      };

    case GET_REWARDS_DATA: {
      const rewardsData = action.payload;
      if (!action.payload) {
        return state;
      }

      const {
        completed_referral_count,
        live_trading_approved_connections,

        live_trading_approved_referral_count,
        referral_count,

        requiring_ach_link_referral_count,
        requiring_live_approval_referral_count,
        requiring_trade_referral_count,
        requiring_deposit_referral_count,

        cash_requiring_trade_to_keep,
        next_cash_expiration_date,
        referral_reward_total_redeemed,
        referral_reward_total_approved,
        funding_reward_total_redeemed,
        funding_reward_total_approved,
        referral_rewards_not_redeemed_count,
        funding_rewards_not_redeemed_count,
        options_total_redeemed,
      } = rewardsData;

      return {
        ...state,

        referral_count,
        live_trading_approved_referral_count,
        completed_referral_count,

        requiring_ach_link_referral_count,
        requiring_live_approval_referral_count,
        requiring_trade_referral_count,
        requiring_deposit_referral_count,

        live_trading_approved_connections,

        cash_requiring_trade_to_keep,
        next_cash_expiration_date,

        referral_reward_total_redeemed,
        referral_reward_total_approved,

        funding_reward_total_redeemed,
        funding_reward_total_approved,

        referral_rewards_not_redeemed_count,
        funding_rewards_not_redeemed_count,
        options_total_redeemed,

        initializing: false,
        loading: false,
        loaded: true,
        wasError: false,
      };
    }

    case GET_REWARDS_DATA_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        wasError: true,
      };

    case RECEIVED_RANDOM_REWARD_REDEMPTION: {
      const rewardData = action.payload;
      const rewardAmount = rewardData.reward_amount;
      return {
        ...state,
        not_redeemed_count: state.not_redeemed_count - 1,
        referral_reward_total_redeemed: state.referral_reward_total_redeemed + rewardAmount,
        cash_requiring_trade_to_keep: state.cash_requiring_trade_to_keep + rewardAmount,
      };
    }

    case RECEIVED_REFERRAL_REWARD_REDEMPTION: {
      const rewardData = action.payload;
      const rewardAmount = rewardData.reward_amount;
      return {
        ...state,
        referral_rewards_not_redeemed_count: state.referral_rewards_not_redeemed_count - 1,
        referral_reward_total_redeemed: state.referral_reward_total_redeemed + rewardAmount,
        cash_requiring_trade_to_keep: state.cash_requiring_trade_to_keep + rewardAmount,
      };
    }

    case RECEIVED_FUNDING_REWARD_REDEMPTION: {
      const rewardData = action.payload;
      const rewardAmount = rewardData.reward_amount;
      return {
        ...state,
        funding_rewards_not_redeemed_count: state.funding_rewards_not_redeemed_count - 1,
        funding_reward_total_redeemed: state.funding_reward_total_redeemed + rewardAmount,
      };
    }

    default:
      return state;
  }
}
