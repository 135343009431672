import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { LearnScreen } from '../../../../components/learn';
import { H3, Body5 } from '../../../../lib/nvstr-common-ui.es';
import { colorPalette } from '../../../../lib/nvstr-utils.es';
import {
  LearnLessonHeading,
  LearnLessonActions,
  LearnCarouselContent,
} from '../../../../components/learn/subComponents';
import { useLearnLesson } from '../../../../hooks/learn/useLearnLesson';
import { LearnDepositIntro } from './screens/Intro';
import { RecurringInvestmentMissedOpportunity } from './screens/RecurringInvestmentMissedOpportunity';
import { LearnDepositAddDeposit } from './screens/AddDeposit';
import { RecurringInvestmentReturns } from './screens/RecurringInvestmentReturns';
import { RECURRING_DEPOSIT_RATES } from '../../../../constants';
import { TrackingEvents } from '../../../../../utils/tracking/events';
import { getDepositPickerAmounts, hideModal, postLearnLessonProgress, showModal } from '../../../../../actions';
import { CheckCircleFilled } from '../../../../icons/svg/CheckCircleFilled';
import { FlatButton } from '../../../../components/buttons';
import { customParseFloat } from '../../../../../helpers/numberHelpers';
import { useCurrentUser } from '../../../../hooks/user';
import { inCurrentUserCustomStartingInvestmentTestGroup } from '../../../../../helpers/currentUserHelpers';
import { startingInvestmentItems, recurringInvestmentItems } from '../../../../constants';
import { LoadingMessage } from '../../../../components/ui';

const defaultRecurringInvestmentInputs = {
  startingValue: 100,
  recurringDepositAmount: 10,
  recurringRate: RECURRING_DEPOSIT_RATES.monthly,
  horizonYears: 40,
  returnRate: 0.09,
};
const higherRecurringInvestmentInputs = {
  startingValue: 100,
  recurringDepositAmount: 20,
  recurringRate: RECURRING_DEPOSIT_RATES.monthly,
  horizonYears: 40,
  returnRate: 0.09,
};
const higherHorizonInvestmentInputs = {
  startingValue: 100,
  recurringDepositAmount: 10,
  recurringRate: RECURRING_DEPOSIT_RATES.monthly,
  horizonYears: 50,
  returnRate: 0.09,
};

const _getBestStartingInvestmentValueMatchForMaxDepositSuggestion = (maxDepositSuggestion) => {
  const values = startingInvestmentItems.map((option) => option.value);
  let bestMatch = values[0];
  values.forEach((v) => {
    if (v <= maxDepositSuggestion) {
      bestMatch = v;
    }
  });
  return bestMatch;
};
const _getBestRecurringInvestmentValueMatchForMaxDepositSuggestion = (maxDepositSuggestion) => {
  const suggestion = maxDepositSuggestion * 0.1;
  const values = recurringInvestmentItems.map((option) => option.value);
  let bestMatch = values[0];
  values.forEach((v) => {
    if (v <= suggestion) {
      bestMatch = v;
    }
  });
  return bestMatch;
};

const _buildCustomStartingRecurringInvestmentInputsFromBankAccountData = (bankAccountAmountDataList) => {
  let maxDepositSuggestion = 0;
  bankAccountAmountDataList?.forEach((d) => {
    const maxAmount = d.deposit_amounts.slice(-1)[0];
    if (maxAmount > maxDepositSuggestion) {
      maxDepositSuggestion = maxAmount;
    }
  });

  const startingValueSuggestion =
    maxDepositSuggestion > 0 ? maxDepositSuggestion : defaultRecurringInvestmentInputs.startingValue;

  const startingValue = _getBestStartingInvestmentValueMatchForMaxDepositSuggestion(startingValueSuggestion);
  const recurringDepositAmount = _getBestRecurringInvestmentValueMatchForMaxDepositSuggestion(startingValueSuggestion);

  return {
    ...defaultRecurringInvestmentInputs,
    startingValue,
    recurringDepositAmount,
  };
};

const Content = styled.div`
  h3 {
    margin: 0;
    line-height: 1.1;
  }
`;
const ActionsWrapper = styled.div`
  padding-top: 24px;
  text-align: center;
`;
const TransferCompletedWrapper = styled.div`
  text-align: center;
`;

const TransferCompletedIconWrapper = styled.div`
  svg {
    height: 36px;
    width: 36px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

const DepositCompleted = () => {
  const dispatch = useDispatch();

  const handleContinue = () => {
    hideModal()(dispatch);
  };
  return (
    <Content>
      <TransferCompletedWrapper>
        <TransferCompletedIconWrapper>
          <CheckCircleFilled />
          <H3>Deposit Initiated</H3>
          <div style={{ paddingTop: '16px' }}>
            <Body5>Please allow 2-3 business days to process this request.</Body5>
          </div>
        </TransferCompletedIconWrapper>
        <ActionsWrapper>
          <FlatButton fullWidth onClick={handleContinue}>
            Continue
          </FlatButton>
        </ActionsWrapper>
      </TransferCompletedWrapper>
    </Content>
  );
};

const defaultTextBodyData = [
  {
    text: 'As Benjamin Franklin put it, "',
  },
  {
    text: 'Money makes money. ',
    bold: true,
  },
  {
    text: ' And the money that money makes, makes money."',
  },
];

const LearnDepositLessonContent = ({ lessonId, onComplete, lessonReward, recurringInputDefaults }) => {
  const dispatch = useDispatch();
  const { lesson: lessonData } = useLearnLesson(lessonId);
  const {
    name: lessonName,
    // type,
    heading: lessonHeading,
    sponsorId,
    moreOnTopicUrl: moreOnLessonTopicUrl,
    metadata: lessonMetadata,
    componentData,
  } = lessonData;
  const inRecurringDepositTestGroup = lessonMetadata?.enableRecurringDeposit;

  const textBodyData = componentData?.textBodyData || defaultTextBodyData;

  const [activeScreenIndex, setActiveScreenIndex] = React.useState(0);

  const handleDepositComplete = async (amount) => {
    TrackingEvents.learn.COMPLETED_DEPOSIT_LEARN_DEPOSIT_LESSON.send({
      Amount: customParseFloat(amount),
      'Lesson ID': lessonId,
    });
    onComplete();
  };

  const handleDepositSkip = () => {
    advanceScreen();
  };

  const lessonContents = [
    <LearnDepositIntro key={1} textBodyData={textBodyData} />,
    <RecurringInvestmentReturns
      lessonId={lessonId}
      defaultInputs={recurringInputDefaults}
      key={2}
      handleDepositComplete={handleDepositComplete}
      handleDepositSkip={handleDepositSkip}
      inRecurringDepositTestGroup={inRecurringDepositTestGroup}
    />,
    <RecurringInvestmentMissedOpportunity defaultInputs={recurringInputDefaults} key={3} />,
    <LearnDepositAddDeposit key={4} onDepositComplete={handleDepositComplete} onComplete={onComplete} />,
  ];

  const metadata = React.useMemo(
    () => {
      return {
        lessonId,
        lessonName,
        lessonHeading,
        sponsorId,
        moreOnLessonTopicUrl,
      };
    },
    [lessonId, lessonName, lessonHeading, sponsorId, moreOnLessonTopicUrl]
  );
  const items = React.useMemo(
    () => {
      return lessonContents.map((c) => ({
        contents: c,
        metadata,
      }));
    },
    [lessonContents, sponsorId, metadata]
  );
  const itemsTotal = items.length;

  const previousScreen = () => {
    const newIndex = Math.max(0, activeScreenIndex - 1);
    setActiveScreenIndex(newIndex);
  };

  const advanceScreen = () => {
    const newIndex = Math.min(itemsTotal - 1, activeScreenIndex + 1);
    setActiveScreenIndex(newIndex);
  };

  // log lesson screen view
  React.useEffect(
    () => {
      const pageNumber = activeScreenIndex + 1;
      TrackingEvents.learn.VIEW_LEARN_LESSON_SCREEN.send({
        'Sponsor ID': sponsorId,
        'Lesson ID': lessonId,
        Subject: lessonName,
        Slide: pageNumber,
      });
      const progressParams = {
        lesson_id: lessonId,
        page_number: pageNumber,
        completed: false,
      };
      postLearnLessonProgress(progressParams)(dispatch);
    },
    [activeScreenIndex]
  );

  return (
    <LearnScreen>
      <LearnLessonHeading
        metadata={metadata}
        lessonHeading={lessonHeading}
        lessonReward={lessonReward}
        activeScreenIndex={activeScreenIndex}
      />

      <LearnCarouselContent
        items={items}
        metadata={metadata}
        lessonReward={lessonReward}
        activeScreenIndex={activeScreenIndex}
        advanceScreen={advanceScreen}
        previousScreen={previousScreen}
        hideTapPanels={activeScreenIndex === 1}
      >
        {lessonContents[activeScreenIndex]}
      </LearnCarouselContent>

      <LearnLessonActions
        lessonId={lessonId}
        metadata={metadata}
        onComplete={onComplete}
        advanceScreen={advanceScreen}
        isLastScreen={itemsTotal === activeScreenIndex + 1}
      />
    </LearnScreen>
  );
};
/*
      { [0, 2].includes(activeScreenIndex) ? (
        <LearnLessonActions
          lessonId={lessonId}
          metadata={metadata}
          onComplete={onComplete}
          advanceScreen={advanceScreen}
          isLastScreen={itemsTotal === activeScreenIndex + 1}
        />
      ) : null}
 */

const LearnDepositLessonInitializer = ({ lessonId, onComplete, lessonReward }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const { deposit_lesson_longer_default_test_group, deposit_lesson_larger_default_test_group } = currentUser;
  const inHigherHorizonTestGroup = deposit_lesson_longer_default_test_group === 1;
  const inHigherRecurringDepositTestGroup = deposit_lesson_larger_default_test_group === 1;

  const [isReady, setIsReady] = React.useState(false);
  const [recurringInputDefaults, setRecurringInputDefaults] = React.useState(
    inHigherHorizonTestGroup
      ? higherHorizonInvestmentInputs
      : inHigherRecurringDepositTestGroup
      ? higherRecurringInvestmentInputs
      : defaultRecurringInvestmentInputs
  );

  React.useEffect(() => {
    const createAndSetRecurringInputDefaults = async () => {
      const { ok, accounts } = await getDepositPickerAmounts()(dispatch);
      if (ok) {
        const values = _buildCustomStartingRecurringInvestmentInputsFromBankAccountData(accounts);
        setRecurringInputDefaults(values);
      }
    };
    const init = async () => {
      const inCustomStartingInvestmentTestGroup = inCurrentUserCustomStartingInvestmentTestGroup(currentUser);
      if (inCustomStartingInvestmentTestGroup) {
        await createAndSetRecurringInputDefaults();
      }
      setIsReady(true);
    };
    init();
  }, []);

  if (!isReady) {
    return (
      <LearnScreen>
        <LoadingMessage message={'Loading Lesson...'} vertical color={colorPalette.primary.charcoal} />
      </LearnScreen>
    );
  }

  return (
    <LearnDepositLessonContent
      lessonId={lessonId}
      onComplete={onComplete}
      lessonReward={lessonReward}
      recurringInputDefaults={recurringInputDefaults}
    />
  );
};

export const LearnDeposit = LearnDepositLessonInitializer;
