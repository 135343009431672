import { LEARN_STATUS_TYPES } from './types';

export const COMPLETED_LESSON_COUNT_TO_PROMPT_APP_REVIEW = 2;
export const SECOND_COMPLETED_LESSON_COUNT_TO_PROMPT_APP_REVIEW = 7;

export const LEARN_STATUS_MESSAGES = {
  [LEARN_STATUS_TYPES.noMoreLessons]: 'More lessons coming soon',
  [LEARN_STATUS_TYPES.liveAccountAndDepositRequired]:
    'Want to unlock more lessons?\n' +
    'Open a live trading account and place your first deposit to complete your account setup.\n' +
    'Put your knowledge into practice and grow your skills. Invest in yourself and we will, too',
  [LEARN_STATUS_TYPES.depositRequired]:
    'Want to unlock more lessons?\n' +
    'Place your first deposit to complete your account setup.\n' +
    'Put your knowledge into practice and grow your skills. Invest in yourself and we will, too.',
  [LEARN_STATUS_TYPES.onCooldown]: 'Come back tomorrow for new lessons!',
};

const _investingExperienceCSV = [
  [1, 1, 4],
  [2, 1, 4],
  [3, 1, 4],
  [4, 1, 3],
  [5, 1, 3],
  [6, 1, 4],
  [7, 1, 4],
  [8, 1, 4],
  [9, 1, 4],
  [10, 1, 3],
  [11, 1, 3],
  [12, 1, 2],
  [13, 1, 3],
  [14, 1, 2],
  [15, 1, 3],
  [16, 1, 4],
  [17, 1, 3],
  [18, 1, 4],
  [19, 2, 4],
  [20, 1, 3],
  [21, 1, 4],
  [22, 1, 4],
  [23, 1, 4],
  [24, 1, 4],
  [25, 2, 4],
  [26, 1, 3],
  [27, 1, 4],
  [28, 1, 4],
  [29, 1, 4],
  [30, 1, 4],
  [31, 1, 3],
  [32, 1, 4],
  [33, 1, 4],
  [34, 1, 4],
  [35, 3, 4],
  [36, 1, 4],
  [37, 1, 3],
  [38, 1, 4],
  [39, 1, 4],
  [40, 2, 4],
  [41, 1, 3],
  [42, 1, 3],
  [43, 1, 3],
  [44, 1, 3],
  [45, 1, 3],
  [46, 1, 4],
  [47, 1, 3],
  [48, 1, 3],
  [49, 1, 3],
  [50, 1, 3],
  [51, 1, 4],
  [52, 1, 4],
  [53, 1, 4],
  [54, 1, 4],
  [55, 1, 4],
  [56, 1, 3],
  [57, 1, 4],
  [58, 1, 4],
  [59, 1, 4],
  [60, 1, 4],
  [61, 1, 4],
  [62, 1, 4],
  [63, 1, 4],
  [64, 1, 4],
  [65, 1, 4],
  [66, 1, 4],
  [67, 1, 4],
  [68, 1, 2],
  [69, 1, 4],
  [70, 1, 2],
  [71, 1, 4],
  [72, 1, 2],
  [73, 1, 4],
  [74, 1, 4],
  [75, 1, 3],
  [76, 1, 4],
  [77, 1, 3],
  [78, 1, 2],
  [79, 1, 3],
  [80, 1, 2],
  [81, 1, 4],
  [82, 1, 4],
  [83, 1, 3],
  [84, 1, 4],
  [85, 1, 4],
  [86, 1, 4],
  [87, 1, 4],
  [88, 1, 3],
  [89, 1, 4],
  [90, 1, 3],
  [91, 1, 3],
  [92, 1, 3],
  [93, 1, 3],
  [94, 1, 3],
  [95, 1, 3],
  [96, 1, 4],
  [97, 1, 4],
  [98, 1, 3],
  [99, 1, 3],
  [100, 1, 3],
  [101, 1, 2],
  [102, 1, 3],
  [103, 1, 3],
  [104, 1, 3],
  [105, 1, 3],
  [106, 1, 2],
  [107, 1, 2],
];

export const createLookupForPastLessonLocalInvestingXPLevels = () => {
  const d = {};
  _investingExperienceCSV.forEach((a) => {
    const [id, min, max] = a;
    d[id] = [min, max];
  });
  return d;
};
