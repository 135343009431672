import React from 'react';
import { connect } from 'react-redux';

import CreditCard from './CreditCard';
import ScriptLoader from '../../ExtraFunctionalityComponents/ScriptLoader';
import { ReloadMessage } from '../../Errors/ReloadMessage';

import { getANetKeys, logErrorEvent, showModal } from '@src/actions';
import { returnBasicErrorModalPayloadWithCustomMessage } from '@src/constants/modals';
import { throwError } from '@src/helpers/devToolHelpers';
// import { getEnvironment } from '../../../utils';

/*
  Component used to get a ANet token for a transaction
  https://developer.authorize.net/hello_world/testing_guide/ <- reference credit card #s to use here

  <ANetCreditCard
    charges -> array of charges for display in checkout form
    onPaymentFailure -> invoked when requests fail for UI display purposes, and any other use case
    onPaymentSubmitStart -> invoked when ajax requests start for UI display purposes, and any other use case
    submitTokenAction -> passes in (token, billing_info) to this function for use in action for payment
    handleCancel -> invoked if user clicks cancel btn in form

    * any props received are forwarded to <CreditCard/>
  />
*/

class ANetCreditCard extends React.Component {
  constructor() {
    super();

    this.state = {
      _aNetScriptLoadFailure: false,

      clientKey: null,
      apiLoginID: null,
    };
  }

  componentDidMount() {
    this.init();
  }

  render() {
    return (
      <React.Fragment>
        <ScriptLoader
          uri={this.returnANetLibraryURI()}
          onLoad={this.handleANetLibraryLoad}
          onLoadFail={this.handleANetLibraryLoadFailure}
        />
        {this._didANetScriptLoadFail() ? (
          <div>
            <ReloadMessage />
          </div>
        ) : (
          <CreditCard {...this.props} handleSubmit={this.handleSubmit} />
        )}
      </React.Fragment>
    );
  }

  returnANetLibraryURI = () => {
    const sandbox = 'https://jstest.authorize.net/v1/Accept.js';
    const production = 'https://js.authorize.net/v1/Accept.js';
    return null;
    // return getEnvironment() === 'production' && window.origin !== 'https://d1-forerunner.nvstr.com'
    //   ? production
    //   : sandbox;
  };

  handleANetLibraryLoad = () => {
    this._loadedANetLib = true;
    dispatchEvent(new Event('load'));
    // for now don't incur a wait
  };

  handleANetLibraryLoadFailure = () => {
    console.error('failed to load ANet Library');
    this._setANetScriptLoadFail();
  };

  _setANetScriptLoadFail = () => {
    this.setState(() => ({
      _aNetScriptLoadFailure: true,
    }));
  };

  _didANetScriptLoadFail = () => this.state._aNetScriptLoadFailure;

  handleAnetResponse = (responseData, billingInfo) => {
    const { messages, opaqueData } = responseData;
    if (opaqueData && opaqueData.dataValue) {
      return this.props.submitTokenAction(opaqueData.dataValue, billingInfo);
    } else {
      const defaultMessage = 'Something went wrong. Please try again later.';
      const errorMessage =
        messages && messages.message && messages.message[0] && messages.message[0].text
          ? messages.message[0].text
          : defaultMessage;
      return this.onPaymentFailure(errorMessage);
    }
  };

  _setANetKeys = (authData) => {
    this.setState(() => ({
      clientKey: authData.clientKey,
      apiLoginID: authData.apiLoginID,
    }));
  };

  _returnANetKeys = () => ({
    clientKey: this.state.clientKey,
    apiLoginID: this.state.apiLoginID,
  });

  submitPaymentToANet = (formData) => {
    this.props.onPaymentSubmitStart();
    const authData = this._returnANetKeys();
    const cardData = {
      cardNumber: formData.cardNumber,
      month: formData.expMonth,
      year: formData.expYear,
      cardCode: formData.cardCode,
    };
    const billingInfo = {
      first_name: formData.cardHolderFirstName,
      last_name: formData.cardHolderLastName,
      street: formData.street,
      city: formData.city,
      state: formData.state,
      zip: formData.zip,
      country: formData.country,
    };
    const secureData = {
      authData,
      cardData,
    };
    const responseHandler = (responseData) => {
      try {
        this.handleAnetResponse(responseData, billingInfo);
      } catch (error) {
        throwError('Error caught in ANet handler', true, {
          error,
        });
      }
    };

    // Accept is defined in global space
    // eslint-disable-next-line no-undef
    Accept.dispatchData(secureData, responseHandler);
  };

  handleSubmit = (formData) => {
    if (this._loadedANetLib && this._loadedANetApiKeys) {
      this.submitPaymentToANet(formData);
    }
  };

  onPaymentFailure = (errorMessage) => {
    if (this.props.onPaymentFailure) {
      this.props.onPaymentFailure(errorMessage);
    } else {
      this.showErrorModal(errorMessage);
    }
  };

  showErrorModal = (errorMessage) => {
    showModal(returnBasicErrorModalPayloadWithCustomMessage(errorMessage))(this.props.dispatch);
  };

  init = () => {
    getANetKeys().then((response) => {
      if (response && response.ok) {
        const authData = {
          clientKey: response.client_key,
          apiLoginID: response.login_id,
        };
        this._loadedANetApiKeys = true; // prevents a load since CC form takes time to complete
        this._setANetKeys(authData);
      } else {
        this._setANetScriptLoadFail();
      }
    });
  };
}

export default connect()(ANetCreditCard);
