import React, { Component } from 'react';

import InfoIcon from '../UI/InfoIcon';
import IconButton from '../../components/buttons/IconButton';
import RatingDisplay from '../../components/misc/RatingDisplay';
import Button from '../../components/buttons/Button';

import { formatSecurityDataValue } from '../../helpers/securitiesHelpers';
import { getFilterDisplayName } from '../../constants/finData';
import { createInputComponent } from '../../helpers/formHelpers';
import { FlatButton } from '../../main/components/buttons';

export class IdeaField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userEditting: props.field.property === 'price_target' && props.userAddingPriceTarget,
    };
  }

  shouldHandleBlur = () => {
    const dict = {
      conviction: true,
      horizon: true,
      active: true,
    };

    return !!dict[this.props.field.property];
  };

  handleBlur = (proxy, e, forceBlur) => {
    if (this.shouldHandleBlur() || forceBlur) {
      this.setState((prevState) => ({
        userEditting: false,
      }));
    }
  };

  handleFocus = () => {
    this.setState((prevState) => ({
      userEditting: true,
    }));
  };

  handleEditClick = () => {
    this.setState((prevState) => ({
      userEditting: true,
    }));
  };

  convert_toHyphen = (word) => {
    return word.split('_').join('-');
  };

  _returnTooltipKey = (property) => {
    if (property === 'expected_return') {
      return 'tooltip_expected_return_idea_panel';
    } else {
      return `tooltip_${property}`;
    }
  };

  renderInfoIcon = (screenWidth, property, security) => {
    const iconPositionDict = {
      price_target: screenWidth > 550 ? { top: '3px' } : { top: '0px' },
      expected_return: screenWidth > 550 ? { top: '3px' } : { top: '0px' },

      conviction: screenWidth > 550 ? { top: '-1px' } : { top: '0px' },

      expected_dividend: screenWidth > 550 ? { top: '-1px' } : { top: '0px' },
      thesis: screenWidth > 550 ? { top: '-1px' } : { top: '0px' },
      horizon: screenWidth > 550 ? { top: '-1px' } : { top: '0px' },
    };

    return (
      <InfoIcon
        word={this._returnTooltipKey(property)}
        symbol={(security || {}).symbol}
        style={iconPositionDict[property] || {}}
        position={this.determineInfoIconPosition(property)}
      />
    );
  };

  renderSubValue = (subValue, subValueProperty) => {
    if ((!!subValue || subValue === 0) && this.props.security.featured) {
      return (
        <div className={`idea-sub-value ${subValueProperty}`}>
          {formatSecurityDataValue(subValue, subValueProperty)}
        </div>
      );
    }
  };

  renderSubAction = (subAction) => {
    if (!!subAction && this.props.security.featured) {
      return <div className="sub-action-button">{subAction}</div>;
    }
  };

  determineInfoIconPosition = (field) => {
    const fieldsRight = {
      expected_dividend: true,
      thesis: true,
      horizon: true,
    };

    return fieldsRight[field] ? 'right' : 'default';
  };

  renderValue = (property, idea) => {
    if (this.props.editable) {
      if (property === 'conviction') {
        return (
          <div
            className="idea-value editable-input input-bg editting"
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            tabIndex={'0'}
          >
            {createInputComponent(this.props.input, this.props.inputProps, this.props.input.name)}
            <IconButton
              icon="fa-pencil"
              size="x-small"
              customClass="editable-input-button"
              colorClassName=" "
              handleClick={this.handleEditClick}
            />
          </div>
        );
      } else {
        const ideaTypeColorClassName = this.props.idea.idea_type.id === 0 ? 'buy-text-color' : 'sell-text-color';

        return (
          <div
            className={`idea-value ${this.props.editable ? 'editable-input input-bg' : ''}`}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            tabIndex={'0'}
          >
            <span className={property === 'idea_type' ? ideaTypeColorClassName : 'input-text-color'}>
              {formatSecurityDataValue(idea[property], property)}
            </span>
            <IconButton
              icon="fa-pencil"
              size="x-small"
              customClass="editable-input-button"
              handleClick={this.handleEditClick}
            />
          </div>
        );
      }
    }

    if (property === 'conviction') {
      return (
        <div className={`idea-value`}>
          <RatingDisplay name="conviction" label="" value={idea[property]} maxRating={5} />
        </div>
      );
    }

    return <div className={`idea-value`}>{formatSecurityDataValue(idea[property], property)}</div>;
  };

  renderValueInput = (property) => {
    const customProps = {};
    if (
      property === 'thesis' ||
      property === 'idea_type' ||
      property === 'price_target' ||
      property === 'expected_return' ||
      property === 'expected_dividend'
    ) {
      customProps.additionalBlurAction = () => {
        this.handleBlur(null, null, true);
      };
    }

    if (property === 'price_target' || property === 'expected_return' || property === 'expected_dividend') {
      customProps.inputWarningProps = {
        idea: this.props.idea,
        security: this.props.security,
        userAddingPriceTarget: this.props.field.property === 'price_target' && this.props.userAddingPriceTarget,
      };
    }

    return (
      <div
        className="idea-value editable-input input-bg editting"
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        tabIndex={'0'}
      >
        {createInputComponent(this.props.input, { ...this.props.inputProps, ...customProps }, this.props.input.name)}
      </div>
    );
  };

  returnDismissWarningFunc = (property) => {
    return () => {
      this.props.dismissWarning(property);
    };
  };

  renderWarning = (property) => {
    const inputWarning = this.props.warnings[property];
    if (!inputWarning) {
      return null;
    }
    return (
      <div className="generic-input-warning-container">
        <div className="generic-input-warning-arrow" />
        <div className="generic-input-warning-content">{'Warning: ' + inputWarning}</div>
        <div className="generic-input-warning-dismiss-container">
          <FlatButton id="input-warning-dismiss-button" transparent onClick={this.returnDismissWarningFunc(property)}>
            Dismiss
          </FlatButton>
        </div>
      </div>
    );
  };

  render() {
    const { screenWidth, security, idea } = this.props;
    const { property, subValueProperty, subValue, subAction, showSubAction } = this.props.field;
    const displayProperty = getFilterDisplayName(property);
    const userEditting = this.state.userEditting;

    if (property === 'idea_type') {
      return (
        <div className={`idea-field ${this.convert_toHyphen(property)}`}>
          {userEditting ? this.renderValueInput(property) : this.renderValue(property, idea)}
        </div>
      );
    } else {
      return (
        <div className={`idea-field ${this.convert_toHyphen(property)}`}>
          <div className="idea-label">
            <span className="secondary-text-color">{displayProperty}</span>
            {this.renderInfoIcon(screenWidth, property, security)}
          </div>

          {userEditting ? this.renderValueInput(property) : this.renderValue(property, idea)}
          {this.renderSubValue(subValue, subValueProperty)}
          {showSubAction && this.renderSubAction(subAction)}
          {this.renderWarning(property)}
        </div>
      );
    }
  }
}

export default IdeaField;
