import React from 'react';

import UserContainer from '../../users/UserContainer';
import ProfileAvatar from '../../../components/user/ProfileAvatar';
import UserName from '../../../components/user/UserName';
import ViewpointData from '../../viewpoints/ViewpointData';
import { PROFILE_CONTEXTS } from '../../../constants/contextTracking';

export const UserAvatarAndName = (props) => {
  const {
    userId,
    viewpointOrgId,

    profileLinkQuery,
    avatarSize,
    displayNameStyle,
    withLink,
    useYou,
  } = props;

  if (viewpointOrgId) {
    return (
      <div className={'user-avatar-and-name-container'}>
        <ViewpointData id={viewpointOrgId}>
          {(id, avatarImgSource, username, userInitials, isLoading, displayName, secondaryDisplayName, org) => {
            return (
              <div className={'user-container'}>
                <ProfileAvatar
                  viewpointOrgId={viewpointOrgId}
                  isViewpointOrg
                  imageSrc={avatarImgSource}
                  initials={userInitials}
                  profileLinkQuery={profileLinkQuery}
                  size={avatarSize || 'small'}
                  withLink={withLink}
                />
                <UserName
                  viewpointOrgId={viewpointOrgId}
                  isViewpointOrg
                  profileLinkQuery={profileLinkQuery}
                  displayText={displayName}
                  withLink={withLink}
                />
              </div>
            );
          }}
        </ViewpointData>
      </div>
    );
  }

  return (
    <div className={'user-avatar-and-name-container'}>
      <UserContainer userId={userId}>
        <ProfileAvatar size={avatarSize || 'small'} profileLinkQuery={profileLinkQuery} withLink={withLink} />
        <UserName
          displayNameStyle={displayNameStyle || 'fullName'}
          profileLinkQuery={profileLinkQuery}
          useYou={useYou}
          withLink={withLink}
        />
      </UserContainer>
    </div>
  );
};
