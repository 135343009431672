import React from 'react';
import { useSelector } from 'react-redux';
import { MARKET_STATES } from '../../../constants';

export const useExtendedTradingHours = () => {
  const marketState = useSelector((state) => state.application.marketState);

  const isPreMarket = marketState === MARKET_STATES.preMarket;
  const isAfterMarket = marketState === MARKET_STATES.afterHours || marketState === MARKET_STATES.closed;
  const isInExtendedTrading = isPreMarket || isAfterMarket;
  return {
    isPreMarket,
    isAfterMarket,
    isInExtendedTrading,
    extendedTradingPrefix: !isInExtendedTrading ? '' : isPreMarket ? 'Pre mkt' : 'Post mkt',
    extendedTradingPrefixShort: !isInExtendedTrading ? '' : isPreMarket ? 'Pre' : 'Post',
  };
};
