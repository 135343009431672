export const VERSION = '2.7.0';
export const DIST = '1';

const POINT_LOCAL_DEV_API = true;
export const LOCAL_API_DOMAIN = POINT_LOCAL_DEV_API ? 'https://dev-api.tornado.com' : 'https://api.tornado.com';

export const DEV_DOMAIN = 'http://localhost:3000';
export const DOMAIN = 'https://www.tornado.com';
export const MODES = {
  dev: 'development',
  prod: 'production',
};

export const OVERRIDE_CURRENT_USER_DATA = {};
// export const OVERRIDE_CURRENT_USER_DATA = {
//   is_ctl_team: true,
// };

export const OVERRIDE_CURRENT_NEXT_LESSON_DATA = {};
// export const OVERRIDE_CURRENT_NEXT_LESSON_DATA = {
//   nextLessonId: 47,
//   nextLessonReward: 5,
//   // nextLessonRewardAtXP: next_reward_xp,
//   // nextLessonCompletionXP: 200
//   completedLessonCount: 3,
//   hoursUntilNextLesson: null,
//   status: null,
//   messagingStates: null,
// };

export const RECAPTCHA_SITEKEY = '6LfWCoQaAAAAAPsJ5kDsGlaB4SWesCg0MajGhEkT';
export const DEV_RECAPTCHA_SITEKEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

export const getRecaptchaKey = () => {
  if (['localhost:3001', 'localhost:3000'].includes(window.location.host)) {
    return DEV_RECAPTCHA_SITEKEY;
  }

  return RECAPTCHA_SITEKEY;
};
