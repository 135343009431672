import { OptionsActionTypes } from '../constants';
import { createTimeInstance } from '../helpers/timeHelpers';

const defaultPositionIdsBySecurityId = {};
const defaultLookup = {};

const mergePositionsIntoState = (positions, state) => {
  const { positionIdsBySecurityId, lookup } = state;

  const newLookup = { ...lookup };
  const newPositionIdsBySecurityId = { ...positionIdsBySecurityId };

  positions.forEach((p) => {
    const { id, security_id: securityId } = p;
    newLookup[id] = p;

    if (!newPositionIdsBySecurityId[securityId]) {
      newPositionIdsBySecurityId[securityId] = [];
    }
    if (!newPositionIdsBySecurityId[securityId].includes(id)) newPositionIdsBySecurityId[securityId].push(id);
  });

  return {
    ...state,
    lookup: newLookup,
    positionIdsBySecurityId: newPositionIdsBySecurityId,
  };
};

const defaultState = {
  isLoaded: false,
  wasErrorLoading: false,
  positionIdsBySecurityId: defaultPositionIdsBySecurityId,
  lookup: defaultLookup,
};

export default function ordersReducer(state = defaultState, action) {
  switch (action.type) {
    case OptionsActionTypes.addOptionsPosition: {
      const position = action.payload;
      return mergePositionsIntoState([position], state);
    }

    case OptionsActionTypes.updateOptionsPositions: {
      const positions = action.payload;
      if (!Array.isArray(positions) || positions.length === 0) return state;

      return { isLoaded: true, ...mergePositionsIntoState(positions, state) };
    }

    default:
      return state;
  }
}
