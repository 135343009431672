import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import * as Actions from '../../actions/index';
import { returnBasicSomethingWentWrongErrorModalPayload } from '../../constants/modals';

import NotificationsList from './NotificationsList';
import LoadingNotification from '../../components/LoadingComponents/LoadingNotification';
import LiveTradingUsersDisclaimer from '../UI/Disclaimers/LiveTradingUsers';
import InviteFriendsMessaging from '../UI/InviteFriendsMessaging';
import { InfiniteScroll } from '../Scroll/InfiniteScroll';
import NotificationsFeedRefreshButton from './NotificationsFeedRefreshButton';
import ProminentRefreshButton from '../UI/ProminentRefreshButton';
import { sendFacebookTrackingEvent } from '../../constants/facebookTracking';
import {
  returnCurrentUserThemeTestGroups,
  shouldSeeDashboardMessaging,
  isReferralProgramActive,
} from '../../helpers/currentUserHelpers';
import styled from 'styled-components';
import { returnLastYear } from '../../helpers/timeHelpers';

const NotificationListRefreshButtonWrapper = styled.div`
  position: relative;
`;
const NotificationListRefreshButtonPositionWrapper = styled.div`
  position: absolute;
  top: -12px;
  left: 0;
  right: 0;

  z-index: 2;
  text-align: center;

  .prominent-refresh-button-container {
    display: inline-block;
  }
`;
const LoadingFeedWrapper = styled.div`
  background: ${({ theme }) => theme.themeColors.componentBg};
`;

export class NotificationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _gotInitialData: false, // is really needed but is a safety to prevent infinite scroll from triggering a data load too soon

      _isGettingData: false,
      _isNoMoreData: false,

      _isGettingMoreNotifications: false,
      _isRefreshingNotifications: false,
    };
  }

  componentDidMount() {
    if (!this._isInFeed()) {
      this.props.actions.postUpdatedLastSeenNotificationTime();
      this._updatePageTitle();
      this._logViewPageEvent();
      this._getInitialNotificationsList();
    } else {
      this._shouldGetNotifications();
    }
  }

  componentWillUnmount() {
    this.props.actions.clearNotifications();
  }

  render() {
    return (
      <InfiniteScroll
        id="notifications-list-infinite-scroll"
        $scrollElement={$(window)}
        onInfiniteScroll={this.handleInfiniteScroll}
        // ignore infinite scroll when loading notifications so it doesn't trigger while the loading frame is animating
        ignoreInfiniteScroll={
          this.props.ignoreInfiniteScroll || this.isNoMoreNotifications() || this._isLoadingNotifications()
        }
      >
        <div
          className={`
            notifications-page-container
            ${this._isLoadingNotifications() ? 'loading' : ' '}
            ${this._inActivityPageFeed() ? 'standalone-page' : ' '}
          `}
        >
          {this._isInFeed() && !this._isLoadingNotifications() && <NotificationsFeedRefreshButton />}
          {!this._isInFeed() &&
            shouldSeeDashboardMessaging(this.props.currentUser) &&
            isReferralProgramActive(this.props.currentUser) && (
              <InviteFriendsMessaging additionalClassNames={''} viewingContext="Activity Page" />
            )}
          <div className={`notifications-live-trading-users-wrapper react-page-alignment-container`}>
            {this._shouldRenderRefreshButton() && (
              <NotificationListRefreshButtonWrapper>
                <NotificationListRefreshButtonPositionWrapper>
                  <ProminentRefreshButton
                    isSolid
                    className="refresh-button-container"
                    isUpdating={this.isRefreshingNotifications()}
                    handleClick={this.handleRefreshData}
                  />
                </NotificationListRefreshButtonPositionWrapper>
              </NotificationListRefreshButtonWrapper>
            )}
            {this._shouldRenderNotificationsDisclaimer() && (
              <LiveTradingUsersDisclaimer className="secondary-text-color" innerBgClass={'main-bg-color-match'} />
            )}
          </div>
          {this._isLoadingNotifications() ? (
            <LoadingFeedWrapper className={`notifications-page react-page-container component-bg`}>
              {this._renderLoading()}
            </LoadingFeedWrapper>
          ) : (
            <div className={`notifications-page react-page-container component-bg`}>
              <NotificationsList
                isVisible={this.props.isVisible || this._inActivityPageFeed()}
                size={this.props.size || 'default'}
                inActivityPageFeed={this._inActivityPageFeed()}
                inFeed={this._isInFeed()}
                isLoadingMoreNotifications={this._isLoadingMoreNotifications()}
                isNoMoreNotifications={this.isNoMoreNotifications()}
                notificationsList={this.returnNotificationsList()}
                actions={this.props.actions}
              />
            </div>
          )}
        </div>
      </InfiniteScroll>
    );
  }

  isGettingMoreNotifications = () => this.state._isGettingMoreNotifications;

  _setIsGettingMoreNotifications = () => {
    this.setState(() => ({
      _isGettingMoreNotifications: true,
    }));
  };

  _clearIsGettingMoreNotifications = () => {
    this.setState(() => ({
      _isGettingMoreNotifications: false,
    }));
  };

  handleInfiniteScroll = () => {
    if (!this.isGettingMoreNotifications() && !this.isNoMoreNotifications()) {
      this._setIsGettingMoreNotifications();
      this._logTriggeredInfiniteScroll();
      return this._getNotifications().then((data) => {
        this._clearIsGettingMoreNotifications();
        return data;
      });
    } else {
      return new Promise((res) => res(false));
    }
  };

  _shouldRenderRefreshButton = () => true; //!this._isInFeed();

  isRefreshingNotifications = () => this.state._isRefreshingNotifications;

  _setIsRefreshingNotifications = () => {
    this.setState(() => ({
      _isRefreshingNotifications: true,
    }));
  };

  _clearIsRefreshingNotifications = () => {
    this.setState(() => ({
      _isRefreshingNotifications: false,
    }));
  };

  _forceRefreshGetUpdatedNotifications = () => {
    return this.props.actions.getUpdatedNotifications({
      batch_index: 0,
      page: 1,
      forceRefresh: true,
    });
  };

  _getUnseenNotificationsCount = () => this.props.actions.getUnseenNotificationsCount();

  _sendSeenNotificationsUpdate = () => this.props.actions.postUpdatedLastSeenNotificationTime();

  handleRefreshData = () => {
    this._setIsRefreshingNotifications();
    this._resetStateIsNoMoreData();
    this._logRefreshClick();
    return this._forceRefreshGetUpdatedNotifications().then(() => {
      this._clearIsRefreshingNotifications();
      this._sendSeenNotificationsUpdate().then(() => this._getUnseenNotificationsCount());
    });
  };

  _renderLoading = () => {
    const maxLoadingItems = [0, 1, 2, 3, 4];
    return maxLoadingItems.map((el) => <LoadingNotification key={`loading-notification-${el + 1}`} />);
  };

  _isInFeed = () => this.props.inFeed;

  _inActivityPageFeed = () => this.props.inActivityPageFeed;

  _shouldRenderNotificationsDisclaimer = () => !this.props.hideNotificationsDisclaimer;

  _isLoadingNotifications = () => this.props.notifications.loading;

  _returnInitialConfigForNotifRequest = () => {
    return {
      batch_index: 0,
      page: 1,
    };
  };

  _getInitialNotificationsList = () => {
    const config = this._returnInitialConfigForNotifRequest();
    this.props.actions.getUpdatedNotifications(config).then(() => {
      this._setCompletedInitialDataGet();
    });
  };

  _shouldGetNotifications = () => {
    if (!this.props.dontGetNotifsOnMount) {
      this._getInitialNotificationsList();
    }
  };

  _isLoadingMoreNotifications = () => this.isGettingMoreNotifications() && !this.isNoMoreNotifications();

  isNoMoreNotifications = () => this.state._isNoMoreData;

  returnNotificationsList = (props) => this._returnNotificationsStore(props).notificationsList;

  _returnNotificationsStore = () => this.props.notifications;

  _returnNextPage = (increaseBatchOverride) => {
    const currentPage = this._returnCurrentPageAndBatchIndex().page;
    const didLastReturnNewNotifs =
      this._didCurrentNotificationAPIRequestReturnNewNotifications() && !increaseBatchOverride;
    return didLastReturnNewNotifs ? (currentPage === null ? 1 : currentPage + 1) : 1;
  };

  _returnNextBatchIndex = (increaseBatchOverride) => {
    const currentBatchIndex = this._returnCurrentPageAndBatchIndex().batch_index;
    const didLastReturnNewNotifs =
      this._didCurrentNotificationAPIRequestReturnNewNotifications() && !increaseBatchOverride;
    return didLastReturnNewNotifs ? currentBatchIndex : currentBatchIndex + 1;
  };

  _returnConfigForNotifRequest = (increaseBatchOverride) => {
    return {
      batch_index: this._returnNextBatchIndex(increaseBatchOverride),
      page: this._returnNextPage(increaseBatchOverride),
    };
  };

  _returnCurrentPageAndBatchIndex = () => {
    const { page, batch_index } = this._returnNotificationsStore();
    return {
      page,
      batch_index,
    };
  };

  _handleNotificationAPIResponse = (response) => {
    const didReturnNewNotifs = this._didNotificationAPIResponseReturnNewNotifications(response);
    if (didReturnNewNotifs) {
      this._clearGettingData();
      return true;
    } else {
      const didLastReturnNewNotifs = this._didLastNotificationAPIRequestReturnNewNotifications();
      if (didLastReturnNewNotifs) {
        return this._getNotifications(true);
      } else {
        this._handleNoMoreNotifications();
        return false;
      }
    }
  };

  _handleNoMoreNotifications = () => this._updateStateIsNoMoreData();

  _handleNotificationAPIError = () => {
    this.props.actions.showModal(returnBasicSomethingWentWrongErrorModalPayload());
  };

  _wasErrorInRequest = (response) => !response || !response.data || 'error' in response;

  _didLastNotificationAPIRequestReturnNewNotifications = () =>
    this._returnNotificationsStore().didLastReturnNotifications;

  _didCurrentNotificationAPIRequestReturnNewNotifications = () =>
    this._returnNotificationsStore().didReturnNotifications;

  _didNotificationAPIResponseReturnNewNotifications = (response) => response.data.notifications.length > 0;

  _getNotifications = (increaseBatchOverride) => {
    return this._requestNotifications(increaseBatchOverride)
      .then((response) => {
        if (!this._wasErrorInRequest(response)) {
          return this._handleNotificationAPIResponse(response);
        } else {
          this._handleNotificationAPIError(response);
          return false;
        }
      })
      .catch((err) => {
        console.error('Something went wrong', err);
      });
  };

  _requestNotifications = (increaseBatchOverride) => {
    const config = this._returnConfigForNotifRequest(increaseBatchOverride);
    return this.props.actions.getNotifications(config);
  };

  _setCompletedInitialDataGet = () => {
    this.setState(() => ({
      _gotInitialData: true,
    }));
  };

  _setGettingData = () => {
    this.setState(() => ({
      _isGettingData: true,
    }));
  };

  _clearGettingData = () => {
    this.setState(() => ({
      _isGettingData: false,
    }));
  };

  _updateStateIsNoMoreData = () => {
    this.setState(() => ({
      _isNoMoreData: true,
    }));
  };

  _resetStateIsNoMoreData = () => {
    this.setState(() => ({
      _isNoMoreData: false,
    }));
  };

  _updatePageTitle = () => {
    document.title = `${PRODUCT_DISPLAY_NAME} - Notifications`;
  };

  _logRefreshClick = () => {
    sendFacebookTrackingEvent('Click Activity Page Refresh Button', {
      themeTestGroups: returnCurrentUserThemeTestGroups(this.props.currentUser),
    });
    const event = 'Clicked Refresh On Notifications Page';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logViewPageEvent = () => {
    const event = 'View Notifications Page';
    const properties = {
      'From React Router': true,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logTriggeredInfiniteScroll = () => {
    const event = 'Infinite Scroll Triggered';
    const properties = {
      context: `Notifications - ${this.props.inFeed ? 'Feed' : 'Activity Page'}`,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    notifications: state.notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const component = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsPage);

export default component;
