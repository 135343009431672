import { combineReducers } from 'redux';

import applicationReducer from './applicationReducer';
import appStorageReducer from './appStorageReducer';

import appHeightReducer from './globalsReducers/appHeightReducer';
import colorThemeReducer from './globalsReducers/colorThemeReducer';

import historyReducer from './historyReducers/historyReducer';
import historyTransactionsReducer from './historyReducers/historyTransactionsReducer';

import actionMessageReducer from './actionMessageReducer';
import activeIdeaReducer from './activeIdeaReducer';
import askReducer from './askReducer';
import authenticationReducer from './authenticationReducer';
import bannerReducer from './bannerReducer';
import collectivesReducer from './collectivesReducer';
import currentUserReducer from './currentUserReducer';
import footerReducer from './footerReducer';
import fundamentalDataReducer from './fundamentalDataReducer';
import horizontalListReducer from './horizontalListReducer';
import ideasReducer from './ideasReducer';
import importConnectionsReducer from './importConnectionsReducer';
import infoIconReducer from './infoIconReducer';
import investmentObjectivesReducer from './investmentObjectivesReducer';
import karmaReducer from './karmaReducer';
import learnReducer from './learnReducer';
import liveAccountReducer from './liveAccountReducer';
import membershipReducer from './membershipReducer';
import modalReducer from './modalReducer';
import navReducer from './navReducer';
import newsReducer from './newsReducer';
import notificationsReducer from './notificationsReducer';
import openAjaxRequestsReducer from './openAjaxRequestsReducer';
import optimizerReducer from './optimizerReducer';
import rebalancerReducer from './rebalancerReducer';
import optionsReducer from './optionsReducer';
import ordersReducer from './ordersReducer';
import onboardingReducer from './onboardingReducer';
import pagesReducer from './pagesReducer';
import externalPositionsReducer from './externalPositionsReducer';
import portfolioReducer from './portfolioReducer';
import portfolioExplorerReducer from './portfolioExplorerReducer';
import rewardsReducer from './rewardsReducer';
import scrollReducer from './scrollReducer';
import symbolToSecurityIdReducer from './symbolToSecurityIdReducer';
import searchReducer from './searchReducer';
import securitiesPriceHistoryReducer from './securitiesPriceHistoryReducer';
import securitiesPriceReducer from './securitiesPriceReducer';
import securitiesOrderPriceReducer from './securitiesOrderPriceReducer';
import securitiesReducer from './securitiesReducer';
import thoughtsReducer from './thoughtsReducer';
import tourReducer from './tourReducer';
import userIdeaPerformanceReducer from './userIdeaPerformanceReducer';
import userConnectionsReducer from './userConnectionsReducer';
import userMutualConnectionsReducer from './userMutualConnectionsReducer';
import usersReducer from './usersReducer';
import viewpointReducer from './viewpointReducer';

import marketMonitorReducer from './marketMonitorReducer';
import livePriceReducer from './livePriceReducer';

import u_ordersReducer from './universal/orders';

const rootReducer = combineReducers({
  // universal
  u_orders: u_ordersReducer,

  // webappSpecific
  appHeight: appHeightReducer,
  colorTheme: colorThemeReducer,

  actionMessage: actionMessageReducer,
  appStorage: appStorageReducer,
  activeIdea: activeIdeaReducer,
  application: applicationReducer,
  ask: askReducer,
  authentication: authenticationReducer,
  banner: bannerReducer,
  collectives: collectivesReducer,
  currentUser: currentUserReducer,
  footer: footerReducer,
  fundamentalData: fundamentalDataReducer,
  history: historyReducer,
  historyTransactions: historyTransactionsReducer,
  horizontalList: horizontalListReducer,
  ideas: ideasReducer,
  importConnections: importConnectionsReducer,
  infoIcon: infoIconReducer,
  investmentObjectives: investmentObjectivesReducer,
  karma: karmaReducer,
  learn: learnReducer,
  liveAccount: liveAccountReducer,
  livePrice: livePriceReducer,
  marketMonitor: marketMonitorReducer,
  membership: membershipReducer,
  modal: modalReducer,
  nav: navReducer,
  notifications: notificationsReducer,
  news: newsReducer,
  onboarding: onboardingReducer,
  optimizer: optimizerReducer,
  orders: ordersReducer,
  options: optionsReducer,
  openAjaxRequests: openAjaxRequestsReducer,
  pages: pagesReducer,
  externalPositions: externalPositionsReducer,
  portfolio: portfolioReducer,
  portfolioExplorer: portfolioExplorerReducer,
  rebalancer: rebalancerReducer,
  rewards: rewardsReducer,
  scroll: scrollReducer,
  search: searchReducer,
  symbolToSecurityId: symbolToSecurityIdReducer,
  securities: securitiesReducer,
  securitiesOrderPrice: securitiesOrderPriceReducer,
  securitiesPrice: securitiesPriceReducer,
  securitiesPriceHistory: securitiesPriceHistoryReducer,
  thoughts: thoughtsReducer,
  tour: tourReducer,
  users: usersReducer,
  userConnections: userConnectionsReducer,
  userMutualConnections: userMutualConnectionsReducer,
  userIdeaPerformance: userIdeaPerformanceReducer,
  viewpoints: viewpointReducer,
});

export default rootReducer;
