import { LEARN_CONTENT_TYPES } from './types';
import { MIN_REWARD_SIGN_UP } from '../index';

export const riskContent = [
  {
    body: [
      {
        text: "Since 1928, there's never been a ",
      },
      {
        text: "16 year stretch where the S&P 500 hasn't gone up.",
        bold: true,
      },
      {
        text: "(Doesn't mean that'll always happen)",
        isNewLine: true,
        small: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'The biggest one year drop ',
        bold: true,
      },
      {
        text: 'in the ',
      },
      {
        text: 'S&P 500 ',
        noWrap: true,
        bold: true,
      },
      {
        text: 'has been ',
      },
      {
        text: '43%',
        bold: true,
      },
      {
        text: '.',
      },
    ],
  },
  {
    body: [
      {
        text: 'On average, ',
      },
      {
        text: 'the S&P 500 has gone up around 9.8% per year.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Reducing risk often means reducing upside.  ',
        bold: true,
      },
      {
        text: "Decide how much you're willing to lose.",
      },
    ],
  },
  {
    body: [
      {
        text: "If you're diversified and have a long horizon, ",
      },
      {
        text: "there's more time to make back short term losses.  ",
        bold: true,
      },
      {
        text: 'Investing regularly helps too.',
      },
    ],
  },
];

export const ivsContent = [
  {
    body: [
      {
        text: 'Money sitting in your bank account may not buy as much in the future - because ',
      },
      {
        text: 'prices of things tend to go up ',
        bold: true,
      },
      {
        text: '(inflation).',
      },
    ],
  },
  {
    body: [
      {
        text: "Banks' interest rates have recently been lower than inflation.",
      },
    ],
  },
  {
    body: [
      {
        text: 'On average, the S&P 500 has gone up around ',
      },
      {
        text: '9.8% per year.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'However, investing in stocks means more risk. The stock market has ',
      },
      {
        text: 'tended to go up in the long run. ',
        bold: true,
      },
      {
        text: 'But in the short term it may go up or down.',
      },
    ],
  },
  {
    body: [
      {
        text: "For money you won't need soon, investing can be a great way to build wealth - and beat inflation.",
      },
    ],
  },
];

export const diversificationContent = [
  {
    body: [
      {
        text: 'Diversification, or ',
      },
      {
        text: 'spreading out your investments, ',
        bold: true,
      },
      {
        text: 'is a key to reducing risk.',
      },
    ],
  },
  {
    body: [
      {
        text: 'It can be a "free lunch" -  ',
      },
      {
        text: 'reducing risk, ',
        bold: true,
      },
      {
        text: 'without necessarily reducing upside.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Broad market indices ',
        bold: true,
      },
      {
        text: 'like the S&P 500 can be a quick way to get diversification.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Diversification is most powerful ',
        bold: true,
      },
      {
        text: 'when the new investments you add are different from ones you already have.',
      },
    ],
  },
  {
    body: [
      {
        text: 'With ',
      },
      {
        text: '20 to 30 stocks ',
        bold: true,
      },
      {
        text:
          'in your portfolio, you can also get much of the diversification benefit of broad market indices like the S&P 500.',
      },
    ],
  },
];

export const compoundingContent = [
  {
    body: [
      {
        text: 'Some say Albert Einstein claimed, "',
      },
      {
        text: 'Compound interest ',
        bold: true,
      },
      {
        text: 'is the eighth wonder of the world."',
      },
    ],
  },
  {
    body: [
      {
        text: 'As Benjamin Franklin put it, "',
      },
      {
        text: 'Money makes money. ',
        bold: true,
      },
      {
        text: ' And the money that money makes, makes money."',
      },
    ],
  },
  {
    body: [
      {
        text: 'Since 1928, the S&P 500 has gone up about ',
      },
      {
        text: '9.8% per year ',
        bold: true,
      },
      {
        text: 'on average. ',
      },
      {
        text: 'At that rate, in ',
      },
      {
        text: "40 years you'd have 42x",
        bold: true,
      },
      {
        text: ' your original investment.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Investing just $100 per month for 40 years would result in over $500k.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Investing ',
      },
      {
        text: 'early and often ',
        bold: true,
      },
      {
        text: ' is a key to building wealth due to the power of compounding.',
      },
    ],
  },
];

export const dividendContent = [
  {
    body: [
      {
        text: 'Dividends are ',
      },
      {
        text: 'cash payouts ',
        bold: true,
      },
      {
        text: 'from stocks.  They offer a way to make money from stocks besides when prices go up.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Not all companies pay dividends, but historically they account for about ',
      },
      {
        text: 'a third of the returns ',
        bold: true,
      },
      {
        text: 'for the stock market.',
      },
    ],
  },
  {
    body: [
      {
        text:
          '“The true investor will do better if he forgets about the market and pays attention to his dividend returns and the operating results of his companies." - Benjamin Graham',
      },
    ],
  },
  {
    body: [
      {
        text: 'There are ',
      },
      {
        text: 'thousands of stocks ',
        bold: true,
      },
      {
        text: 'that pay dividends, and you can explore them on Tornado.',
      },
    ],
  },
];

export const optimizationContent = [
  {
    body: [
      {
        text: 'How you ',
      },
      {
        text: 'size your positions ',
        bold: true,
      },
      {
        text: 'can hugely impact the risk, and upside, of your portfolio.',
      },
    ],
  },
  {
    body: [
      {
        text: 'For example, if a stock is really ',
      },
      {
        text: 'volatile',
        bold: true,
      },
      {
        text: ', meaning the price moves a lot, a ',
      },
      {
        text: 'smaller position',
        bold: true,
      },
      {
        text: ' may make sense.',
      },
    ],
  },
  {
    body: [
      {
        text: 'If a stock is ',
      },
      {
        text: 'very different ',
        bold: true,
      },
      {
        text: "from the rest of your portfolio, that's a reason to have a ",
      },
      {
        text: 'larger position',
        bold: true,
      },
      {
        text: '. It adds diversification.',
      },
    ],
  },
  {
    body: [
      {
        text: "And of course if you think there's a ",
      },
      {
        text: 'lot of upside ',
        bold: true,
      },
      {
        text: "in a stock (relative to the risk) that's a strong reason to have a ",
      },
      {
        text: 'larger position',
        bold: true,
      },
      {
        text: '.',
      },
    ],
  },
  {
    body: [
      {
        text: 'A portfolio optimizer weighs all these together. In just 1 click, you can ',
      },
      {
        text: 'rebalance your portfolio',
        bold: true,
      },
      {
        text: '. The tech is based on Modern Portfolio Theory, which won a Nobel Prize.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Studies suggest optimization can add ',
      },
      {
        text: 'up to 2% in additional returns',
        bold: true,
      },
      {
        text: ' vs. market weightings. Compounded ',
      },
      {
        text: 'over 20+ years, that can amount to 150%+',
        bold: true,
      },
      {
        text: '.  Check out the optimizer to learn more.',
      },
    ],
  },
];

export const dollarCostContent = [
  {
    body: [
      {
        text: 'One way to reduce the risk of ',
      },
      {
        text: 'buying a stock at the wrong time ',
        bold: true,
      },
      {
        text: 'is Dollar Cost Averaging.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Dollar Cost Averaging involves making ',
      },
      {
        text: 'regular purchases over time.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'It helps take the emotion out of investment decisions.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Dollar Cost Averaging guards against the temptation to “buy high" and "sell low” out of fear.',
      },
    ],
  },
  {
    body: [
      {
        text: 'As Warren Buffett put it, “I like buying ',
      },
      {
        text: 'quality merchandise when it is marked down.',
        bold: true,
      },
      {
        text: '”',
      },
    ],
  },
  {
    body: [
      {
        text: 'Staying disciplined about investing regularly is a great way to ',
      },
      {
        text: 'build long term wealth',
        bold: true,
      },
      {
        text: '.',
      },
    ],
  },
];

export const concentrationContent = [
  {
    body: [
      {
        text: 'Warren Buffett once said, "Diversification may ',
      },
      {
        text: 'preserve wealth',
        bold: true,
      },
      {
        text: ', but concentration ',
      },
      {
        text: 'builds wealth',
        bold: true,
      },
      {
        text: '".',
      },
    ],
  },
  {
    body: [
      {
        text:
          "Charlie Munger believes that when it's hard to find good investments, concentrating on a few can be a good idea.",
      },
    ],
  },
  {
    body: [
      {
        text: 'Concentrated positions come with ',
      },
      {
        text: 'more risk',
        bold: true,
      },
      {
        text: ". It's important to understand each investment more deeply and ",
      },
      {
        text: 'be comfortable with the downside',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'With ',
      },
      {
        text: '20 to 30 stocks ',
        bold: true,
      },
      {
        text: 'in your portfolio, you can also get much of the diversification benefit of ',
      },
      {
        text: 'broad market indices',
        bold: true,
      },
      {
        text: ' like the S&P 500.',
      },
    ],
  },
  {
    body: [
      {
        text: 'You can control concentration risk by ',
      },
      {
        text: 'limiting how big each position is ',
        bold: true,
      },
      {
        text: 'as a percentage of your portfolio.',
      },
    ],
  },
  {
    body: [
      {
        text: 'As you explore and evaluate the pros and cons of more stocks, it becomes easier to ',
      },
      {
        text: 'build conviction in your investments',
        bold: true,
      },
      {
        text: '.',
      },
    ],
  },
];

export const etfsContent = [
  {
    body: [
      {
        text: 'Exchange Traded Funds (ETFs) are a ',
      },
      {
        text: 'quick and easy ',
        bold: true,
      },
      {
        text: 'way to invest in the ',
      },
      {
        text: 'broad market',
        bold: true,
      },
      {
        text: ', or a ',
      },
      {
        text: 'specific theme.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'For example, SPY tracks the S&P 500 index, a basket of the largest public companies in the US.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Some ETFs focus on specific investment themes.',
      },
    ],
  },
  {
    body: [
      {
        text: 'There are choices galore, from Electric Vehicles (DRIV, KARS) to Gold Miners (GDX), and more.',
      },
    ],
  },
  {
    body: [
      {
        text: 'ETFs are designed to be ',
      },
      {
        text: 'easy to buy and sell,',
        bold: true,
      },
      {
        text: ' and many have ',
      },
      {
        text: 'low fees',
        bold: true,
      },
      {
        text: '.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Tornado provides access to ',
      },
      {
        text: 'over 2,000 ETFs ',
        bold: true,
      },
      {
        text: 'you can explore.',
      },
    ],
  },
];

export const valuationContent = [
  {
    body: [
      {
        text: "You wouldn't buy something without checking the price first. But for stocks, the ",
      },
      {
        text: "price alone won't tell you if it's a good deal.",
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Depending on the stock, 1 share represents a ',
      },
      {
        text: 'different percentage ',
        bold: true,
      },
      {
        text: 'of the underlying company.',
      },
      {
        text:
          ' So comparing one stock price to another is like comparing a gallon of milk to a half gallon of apple juice.',
        newLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: "A stock's valuation can help reveal ",
      },
      {
        text: 'whether a stock is a good deal',
        bold: true,
      },
      {
        text: '. Ratios like P/E (price divided by earnings per share) allow you to ',
      },
      {
        text: 'compare one stock to another ',
        bold: true,
      },
      {
        text: 'on a more apples-to-apples basis.',
      },
    ],
  },
  {
    body: [
      {
        text:
          "A low P/E doesn't always indicate that a stock is a good bargain - it can be appropriate if a company isn't growing.  Likewise, high growth can justify a high P/E.",
      },
    ],
  },
  {
    body: [
      {
        text: 'No matter how strong the underlying company is, ',
      },
      {
        text: 'paying the right price for its stock is a key',
        bold: true,
      },
      {
        text:
          ' to investing profitably. As Warren Buffett put it, “I like buying quality merchandise when it is marked down.”',
      },
    ],
  },
  {
    body: [
      {
        text: "Ratios like P/E help reveal a stock's true pricing, which can diverge from its fair value. You can ",
      },
      {
        text: "compare stocks' P/E and other metrics ",
        bold: true,
      },
      {
        text: 'to gauge where there may be opportunity.',
      },
    ],
  },
];

export const earningsReportsContent = [
  {
    body: [
      {
        text: "Earnings reports provide a wealth of insight on a company's ",
      },
      {
        text: 'strategy, growth, and profitability',
        bold: true,
      },
      {
        text: '. They typically happen once a quarter.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Companies reveal ',
      },
      {
        text: 'financial performance ',
        bold: true,
      },
      {
        text: '(sales, profits, etc.) and also discuss ',
      },
      {
        text: 'prospects for the future.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: "It's a front row seat to the strategic choices that will determine a company's success, or failure.",
      },
    ],
  },
  {
    body: [
      {
        text:
          'Companies often host a webcast after they report earnings, with Q&A from analysts. This can give you a sense for what ',
      },
      {
        text: 'issues other investors are focused on',
        bold: true,
      },
      {
        text: ' as well.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Stocks can move a lot on earnings reports, so it helps to know ',
      },
      {
        text: 'what the investing community expects',
        bold: true,
      },
      {
        text: ' (e.g., consensus estimates) versus actual performance.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Check out which companies are reporting in the coming weeks.',
      },
    ],
  },
];

export const orderTypesContent = [
  {
    type: LEARN_CONTENT_TYPES.sponsorIntro,
  },
  {
    body: [
      {
        text: 'When you ',
      },
      {
        text: 'place a trade ',
        bold: true,
      },
      {
        text: 'you can specify an order type. These are ',
      },
      {
        text: 'instructions',
        bold: true,
      },
      {
        text: ' that affect when, and at what price, your trade will be executed.',
      },
    ],
  },
  {
    body: [
      {
        text: 'The simplest order is a ',
      },
      {
        text: 'Market order',
        bold: true,
      },
      {
        text: '. This means your buy or sell will occur as soon as possible at the ',
      },
      {
        text: 'prevailing market price',
        bold: true,
      },
      {
        text: ', when the stock market is open.',
      },
    ],
  },
  {
    body: [
      {
        text: "With a Market order you have less control over the exact price you'll get - but ",
      },
      {
        text: "there's more certainty that your trade will be executed.",
      },
    ],
  },
  {
    body: [
      {
        text: 'A ',
      },
      {
        text: 'Limit order ',
        bold: true,
      },
      {
        text:
          'allows you to specify a price (the Limit price) where you want your trade to occur.  Your trade will be executed at the ',
      },
      {
        text: 'Limit price or better',
      },
      {
        text: " - or it won't be executed at all.",
      },
    ],
  },
  {
    body: [
      {
        text: "With a Limit order, you can protect yourself from getting a price you don't like.",
        bold: true,
      },
      {
        text:
          ' But depending on where your Limit price is vs. available market prices, your trade may not end up happening.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'There are other more advanced order types you can explore as well, to more finely specify how your trade will be executed.',
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.sponsorOutro,
  },
];

export const fractionalContent = [
  {
    body: [
      {
        text:
          'Stocks often have prices in the hundreds or even thousands of dollars per share, making it hard to invest if you have a smaller portfolio.',
      },
    ],
  },
  {
    body: [
      {
        text: 'With fractional shares, you can ',
      },
      {
        text: 'buy or sell a smaller slice of a share ',
        bold: true,
      },
      {
        text: 'with as little as $5.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Fractional shares offer a way to start ',
      },
      {
        text: 'building wealth ',
        bold: true,
      },
      {
        text: 'today, with even ',
      },
      {
        text: 'small amounts of money.',
        bold: true,
      },
    ],
  },
];

export const gettingStarted12Content = [
  {
    body: [
      {
        text: 'Anyone can invest ',
        bold: true,
      },
      {
        text: "- it's a great way to build wealth. ",
      },
      {
        text: 'Building your confidence ',
        bold: true,
      },
      {
        text: 'is easy.',
      },
    ],
  },
  {
    body: [
      {
        text: "We'll help you ",
      },
      {
        text: 'learn the key concepts',
        bold: true,
      },
      {
        text: '. And put them into practice.',
      },
    ],
  },
  {
    body: [
      {
        text: "There's an entire community on Tornado to help you understand ",
      },
      {
        text: 'the pros and cons ',
        bold: true,
      },
      {
        text: 'of each investment.',
      },
    ],
  },
  {
    body: [
      {
        text: 'And if you have ',
      },
      {
        text: 'questions about a stock',
        bold: true,
      },
      {
        text: ', you can always ask a Community Thought Leader (CTL).',
      },
    ],
  },
  {
    body: [
      {
        text: 'You can ',
      },
      {
        text: 'start investing with as little as $5',
        bold: true,
      },
      {
        text:
          '. A broad index fund like SPY, which tracks the S&P 500, can help you get started without choosing specific stocks.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Investing regularly',
        bold: true,
      },
      {
        text: ', even in small amounts, is key to ',
      },
      {
        text: 'building long term wealth.',
        bold: true,
      },
      {
        text: "  It's simple to set up a recurring investment, so you never forget.",
      },
    ],
  },
];

export const whenToSellContent = [
  {
    body: [
      {
        text: 'There are at least ',
      },
      {
        text: '3 good reasons',
        bold: true,
      },
      {
        text: ' to sell a stock you own.',
      },
    ],
  },
  {
    body: [
      {
        text: 'The first is when a better opportunity arises.',
      },
    ],
  },
  {
    body: [
      {
        text: 'And the second is when the underlying ',
      },
      {
        text: 'fundamentals change',
        bold: true,
      },
      {
        text: '. For example, a change in the competitive landscape.',
      },
    ],
  },
  {
    body: [
      {
        text: 'A third reason to sell is when a ',
      },
      {
        text: 'stock appreciates',
        bold: true,
      },
      {
        text: " and approaches your price target - i.e., when you think there's ",
      },
      {
        text: 'limited upside going forward.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Staying up to date',
        bold: true,
      },
      {
        text: ' on your stocks, and what other investment opportunities are available, can help you ',
      },

      {
        text: 'feel comfortable knowing when to sell.',
        bold: true,
      },
    ],
  },
];

export const businessRiskContent = [
  {
    body: [
      {
        text: "When you buy a stock, you're buying an ",
      },
      {
        text: 'ownership stake in the company',
        bold: true,
      },
      {
        text: '.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Over the long term, the ',
      },
      {
        text: 'stock’s performance ',
        bold: true,
      },
      {
        text: 'will be tied to issues that drive the underlying ',
      },
      {
        text: "company's performance",
        bold: true,
      },
      {
        text: ': its market, its strategy and the competitive landscape.',
      },
    ],
  },
  {
    body: [
      {
        text: '"We think of ',
      },
      {
        text: 'business risk ',
        bold: true,
      },
      {
        text: 'in terms of what can happen — 5, 10, 15 years from now — that will ',
      },
      {
        text: 'destroy, or modify, or reduce the economic strengths ',
        bold: true,
      },
      {
        text: 'that we perceive currently exist in a business.” - Warren Buffett',
      },
    ],
  },
  {
    body: [
      {
        text: 'Business risk can take many forms',
        bold: true,
      },
      {
        text: ': excessive debt, high capital intensity, commoditization, obsolescence, inflation, and competition.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Understanding the factors that may ',
      },
      {
        text: 'impact a business ',
        bold: true,
      },
      {
        text: 'over time is key.',
      },
    ],
  },
];

export const ratiosContent = [
  {
    body: [
      {
        text: 'How do you tell if a ',
      },
      {
        text: 'stock is undervalued or overvalued',
        bold: true,
      },
      {
        text: '? Ratios like Price / Earnings (P/E) can help.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Investors often use price ratios, sometimes called "multiples", to evaluate a stock versus its ',
      },
      {
        text: 'historical valuation, or to compare different stocks.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: "The P/E ratio measures a company's ",
      },
      {
        text: 'stock price relative to its earnings',
        bold: true,
      },
      {
        text: ". It's how much you’re paying for one dollar’s worth of earnings.",
      },
    ],
  },
  {
    body: [
      {
        text: "Price / Sales is another measure that takes into account total sales.  It's ",
      },
      {
        text: 'often used for growth companies ',
        bold: true,
      },
      {
        text:
          "that may not be profitable yet. That is, when earnings are negative, so a P/E ratio wouldn't make sense.",
      },
    ],
  },
  {
    body: [
      {
        text: 'Price ratios are a starting point for asking other questions. ',
      },
      {
        text: 'There are often reasons why ratios may be high or low. ',
        bold: true,
      },
      {
        text: 'For example, rapidly growing sales may justify a high multiple.',
      },
    ],
  },
  {
    body: [
      {
        text: 'By comparing price ratios, and understanding why they vary, you can better ',
      },
      {
        text: 'assess where there may be opportunity.',
        bold: true,
      },
    ],
  },
];

export const communityContent = [
  {
    body: [
      {
        text: 'Learning from others can be a ',
      },
      {
        text: 'great way to grow ',
        bold: true,
      },
      {
        text: "especially in investing. Tornado's community includes leading voices called Viewpoint Contributors.",
      },
    ],
  },
  {
    body: [
      {
        text: '"The challenge is not to find information, but to filter the information. New investors need to ',
      },
      {
        text: 'experiment with different points of view',
        bold: true,
      },
      {
        text: '."',
      },
      {
        text: '- Daniel Martins, DM Martins',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: '"If the stock market closed for the next five years, ',
      },
      {
        text: 'would you be comfortable ',
        bold: true,
      },
      {
        text: 'holding your stock?"',
      },
      {
        text: '- Richard Chu, Saga Partners',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: '"Don’t get so drawn into the story management is telling that you lose your objectivity. ',
      },
      {
        text: 'Share with friends and discuss',
        bold: true,
      },
      {
        text: ', and then you can see whether you\'re objective or not."',
      },
      {
        text: '- Erick Mokaya, The Transcript',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: '"If you stay open-minded, if you have that passion for learning more about companies and ',
      },
      {
        text: 'improving your knowledge about the world',
        bold: true,
      },
      {
        text: ', that will take you far in investing."',
      },
      {
        text: '- Richard Chu, Saga Partners',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Insights from these, and more, Tornado Viewpoint Contributors can be a ',
      },
      {
        text: 'powerful way to deepen your perspective ',
        bold: true,
      },
      {
        text: 'on investing.',
      },
    ],
  },
];

export const marketCapitalizationContent = [
  {
    body: [
      {
        text: 'What’s a company worth today? ',
        bold: true,
      },
      {
        text: 'One measure investors use is market capitalization, or market cap.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Market cap is the ',
      },
      {
        text: 'total dollar value of a company’s stock',
        bold: true,
      },
      {
        text:
          ': the hypothetical cost if you were to buy up all its outstanding shares at once, at their listed market price.',
      },
    ],
  },
  {
    body: [
      {
        text: "It's a simple formula: number of total outstanding shares x current price per share = market cap",
      },
    ],
  },
  {
    body: [
      {
        text: 'You can use it to ',
      },
      {
        text: 'compare the size of one company to another',
        bold: true,
      },
      {
        text: ', based on how the market values them.',
      },
    ],
  },
  {
    body: [
      {
        text: "Market cap can also help reveal a company's risk profile. ",
        bold: true,
      },
      {
        text:
          ' Often large-cap stocks (over $10 billion market cap) are less risky than small-cap stocks (less than $2 billion market cap).',
      },
    ],
  },
  {
    body: [
      {
        text:
          "It can also lead to key questions.  For example, can an upstart with a large market cap grow enough to justify it?  A company's market cap ",
      },
      {
        text: 'provides insight into how other investors perceive that company.',
        bold: true,
      },
    ],
  },
];

export const insiderTransactionsContent = [
  {
    body: [
      {
        text: 'An insider is an executive, board member or owner of 10%+ of the company. ',
      },
      {
        text: 'It can be helpful to watch what they do.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Peter Lynch said, "Insiders might sell their shares for any number of reasons, but ',
      },
      {
        text: 'they buy for only one: they think the price will rise. ',
        bold: true,
      },
      {
        text: '"',
      },
    ],
  },
  {
    body: [
      {
        text: '"Corporate insiders are a unique class of traders as they possess ',
      },
      {
        text: 'the most intimate information',
        bold: true,
      },
      {
        text: ' about a firm," says Dave Pope, MD S&P Capital IQ.',
      },
    ],
  },
  {
    body: [
      {
        text: 'There are several ways to follow insider purchases. One is to click ',
      },
      {
        text: '"Search Stocks" on Tornado and then "View latest SEC filings"',
        bold: true,
      },
      {
        text: ' at the top of the stock page.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Keeping track of insider buying and selling ',
        bold: true,
      },
      {
        text:
          'can help you build conviction in your investment thesis, risk manage your positions and identify new investments.',
      },
    ],
  },
];

export const tamContent = [
  {
    body: [
      {
        text: 'Total Addressable Market, or TAM, is the ',
      },
      {
        text: 'maximum amount of revenue',
        bold: true,
      },
      {
        text: " a company could possibly generate. It's a key to understanding the upside of fast-growing stocks.",
      },
    ],
  },
  {
    body: [
      {
        text: "A company's TAM can indicate ",
      },
      {
        text: 'how much further growth potential ',
        bold: true,
      },
      {
        text: 'it has.',
      },
    ],
  },
  {
    body: [
      {
        text: 'If a company had grown fast in the past, but revenue is approaching TAM, that may indicate that ',
      },
      {
        text: 'growth could slow',
        bold: true,
      },
      {
        text: ' going forward.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Fast-growing companies with TAMs that are much larger than their current revenue may have ',
      },
      {
        text: 'significant room to keep growing.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: "A company's ability to ",
      },
      {
        text: 'grow its TAM',
        bold: true,
      },
      {
        text: ' (with new products, use cases, or customers) is sometimes under-appreciated.',
      },
    ],
  },
  {
    body: [
      {
        text: "Understanding TAM - and a company's strategy for growing it - is an important way to assess ",
      },
      {
        text: "a stock's potential upside.",
        bold: true,
      },
    ],
  },
];

export const growthStocksContent = [
  {
    body: [
      {
        text: 'Growth stocks have ',
      },
      {
        text: 'faster sales and/or earnings growth ',
        bold: true,
      },
      {
        text: 'than the market average.',
      },
    ],
  },
  {
    body: [
      {
        text: 'They’re often found in ',
      },
      {
        text: 'innovative sectors ',
        bold: true,
      },
      {
        text: 'like tech, alternative energy, biotech, or financial technology.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'They can be big, household-names like Netflix or Amazon with higher-than-average growth - and the potential to keep growing.',
      },
    ],
  },
  {
    body: [
      {
        text: "Growth stocks' prices often ",
      },
      {
        text: "reflects investors' expectations that sales or earnings will be significantly higher ",
        bold: true,
      },
      {
        text: 'in the near future. They often have higher Price / Earnings (P/E) and Price / Sales (P/S) ratios.',
      },
    ],
  },

  {
    body: [
      {
        text: 'Growth stocks may have higher risk too. ',
        bold: true,
      },
      {
        text: 'If growth doesn’t live up to expectations, their stock prices can take a hit.',
      },
      {
        text:
          'Also when interest rates rise, that negatively impacts what many investors are willing to pay for these stocks.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Keeping an eye on ',
      },
      {
        text: "drivers of a company's revenue and earnings growth",
        bold: true,
      },
      {
        text: ', and well as interest rates, can be helpful in assessing how growth stock may perform.',
      },
    ],
  },
];

export const epsContent = [
  {
    body: [
      {
        text: 'Earnings per share, or EPS, indicates how much ',
      },
      {
        text: 'profit a company makes for each share',
        bold: true,
      },
      {
        text: ' of stock.',
      },
    ],
  },
  {
    body: [
      {
        text:
          "EPS is calculated by taking a company's profit - its sales minus expenses - divided by the total shares of stock outstanding.",
      },
    ],
  },
  {
    body: [
      {
        text: 'EPS can sometimes be negative - when a ',
      },
      {
        text: 'company is unprofitable.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          "Since EPS reflects profit for each share, you can compare it to a company's stock share price.  That can help you understand ",
      },
      {
        text: 'how much earnings power is behind each share',
        bold: true,
      },
      {
        text: " - and what you're paying for it when you buy a stock.",
      },
    ],
  },
  {
    body: [
      {
        text: 'Companies typically report their EPS every quarter.',
        bold: true,
      },
      {
        text:
          "  Wall Street analysts often estimate what they think it will be. If EPS is higher than estimates, the stock price sometimes goes up.  If it's lower, the stock price sometimes goes down.",
      },
    ],
  },
  {
    body: [
      {
        text: 'Understanding the ',
      },
      {
        text: 'drivers of EPS can help you understand how a stock may perform',
        bold: true,
      },
      {
        text:
          '.  Are there one-time effects or ongoing trends? Do they reflect a company’s industry as a whole or only that company?',
      },
    ],
  },
];

export const retailContent = [
  {
    body: [
      {
        text: 'Retail stocks include companies that sell ',
      },
      {
        text: 'goods to individuals.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          "Retailers can sell goods for multipurpose use (Walmart, Macy's), home & garden (Home Depot, Lowe's), grocery (Kroger) and specialty - which includes apparel (Gap), electronics (Best Buy), sporting goods (Dicks Sporting Goods), etc.",
      },
    ],
  },
  {
    body: [
      {
        text: 'Retailers offer ',
      },
      {
        text: 'key insight into demographic trends',
        bold: true,
      },
      {
        text:
          " - e.g., from luxury (Nordstrom) to budget (Dollar General), and from teen (Abercrombie & Fitch) to middle age (Chico's).",
      },
    ],
  },
  {
    body: [
      {
        text: 'One key metric for retailers is ',
      },
      {
        text: 'growth in "same store sales."',
        bold: true,
      },
      {
        text:
          ' This measures how much sales grew in existing stores (i.e. excluding growth from just adding new stores).',
      },
    ],
  },
  {
    body: [
      {
        text: 'Investors also watch ',
      },
      {
        text: 'e-commerce growth and profitability',
        bold: true,
      },
      {
        text:
          '. A key metric is "e-commerce penetration," or how much of a retailer\'s total sales are from digital channels.',
      },
    ],
  },
  {
    body: [
      {
        text: '"Omnichannel" strategies are also important. That\'s when retailers use ',
      },
      {
        text: 'digital to drive in-store sales, and vice versa.',
        bold: true,
      },
    ],
  },
];

export const consensusEstimatesContent = [
  {
    body: [
      {
        text: 'Consensus estimates are an ',
      },
      {
        text: "average of Wall Street analysts' predictions",
        bold: true,
      },
      {
        text: " of a company's sales or earnings.",
      },
    ],
  },
  {
    body: [
      {
        text: 'Companies often provide guidance ',
        bold: true,
      },
      {
        text:
          "to help analysts arrive at their estimates. Analysts' individual estimates are averaged together to form a consensus estimate.",
      },
      {
        text: 'Consensus estimates are sometimes called ',
        isNewLine: true,
      },
      {
        text: '"forward" earnings (or forward sales).',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Consensus estimates are widely published.',
        bold: true,
      },
      {
        text:
          ' You can find them on company profiles on Tornado, and other sources like the Wall Street Journal and Yahoo Finance.',
      },
    ],
  },
  {
    body: [
      {
        text: 'When a company reports earnings that exceeds consensus estimates, that can mean things are going ',
      },
      {
        text: 'better than planned. ',
        bold: true,
      },
      {
        text: 'And falling short can indicate ',
      },
      {
        text: 'unexpected issues.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: "Beating (or missing) consensus estimates doesn't always mean a stock will go up (or down).",
      },
      {
        text: ' Sometimes investors may be anticipating that in advance.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Understanding how and why reported earnings differ from consensus estimates is one key to ',
      },
      {
        text: "understanding how a company's prospects are evolving.",
        bold: true,
      },
      {
        text: " It's a great starting point to ask questions and dig deeper.",
      },
    ],
  },
];

export const marketIndicesContent = [
  {
    type: LEARN_CONTENT_TYPES.sponsorIntro,
  },
  {
    body: [
      {
        text: 'The Dow, the S&P 500, and the Nasdaq are market indices. ',
      },
      {
        text: 'A market index is a basket of stocks that can together represent the broader market.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Each index uses a different methodology to select and weight stocks. The goal is to ',
      },
      {
        text: 'provide a quick overview of the market’s performance.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'The Nasdaq Composite ',
        bold: true,
      },
      {
        text:
          'includes almost all stocks that trade on the Nasdaq stock exchange. It provides good insight into how social media, e-commerce, retail, and Silicon Valley trendsetters are performing.',
      },
    ],
  },
  {
    body: [
      {
        text: 'The ',
      },
      {
        text: 'Dow Jones Industrial Average',
        bold: true,
      },
      {
        text:
          " only tracks the performance of 30 large, U.S.-based companies. It gives investors a general idea of what's going on in U.S. markets.",
      },
    ],
  },
  {
    body: [
      {
        text: 'The S&P 500',
        bold: true,
      },
      {
        text:
          " also tracks large, U.S.-based companies, but it includes 500 of them. As a result, many investors see it as a more complete view of what's happening in the overall U.S. market.",
      },
    ],
  },
  {
    body: [
      {
        text: 'Market indices can be great tools for monitoring how the stock market is behaving. ',
      },
      {
        text: 'By understanding the specific focus of each index',
        bold: true,
      },
      {
        text: ', you can deepen your understanding of the market’s performance.',
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.sponsorOutro,
  },
];

export const stocksMarketsIndexesContent = [
  {
    type: LEARN_CONTENT_TYPES.sponsorIntro,
  },
  {
    body: [
      {
        text: 'A stock is a financial asset that represents an economic interest in a business.',
        bold: true,
      },
      {
        text: ' Stocks are the companies you invest in: Apple, Tesla, Amazon, General Electric.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Shares are individual pieces of stock; ',
      },
      {
        text: 'each share is a tiny fraction of ownership.',
        bold: true,
      },
      {
        text: ' One share of Tesla, 10 shares of Apple. Stocks may have millions, even billions of shares.',
      },
    ],
  },
  {
    body: [
      {
        text: 'The stock market is not a stock. ',
      },
      {
        text: 'The market is a large, representative group of stocks:',
        bold: true,
      },
      {
        text: ' all the stocks in a given country, for example, or a big subset of those stocks.',
      },
    ],
  },
  {
    body: [
      {
        text: 'In the U.S., stock markets include the New York Stock Exchange and Nasdaq. ',
      },
      {
        text: "They're where stocks are registered and traded.",
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'To measure their collective performance, stocks are often grouped into indices.',
        bold: true,
      },
      {
        text:
          " Indices share characteristics: they're stocks in the same industry; they're a group of large stocks or a group of small stocks.",
      },
    ],
  },
  {
    body: [
      {
        text: 'The major U.S. indices include the ',
      },
      {
        text: 'Dow Jones Industrial Average',
        bold: true,
      },
      {
        text: ' (DJIA); the ',
      },
      {
        text: "Standard & Poor's 500",
        bold: true,
      },
      {
        text: ' (S&P 500) and the ',
      },
      {
        text: 'NASDAQ Composite',
        bold: true,
      },
      {
        text:
          '. These are often what commentators refer to when they talk about daily or weekly gains or declines in the stock market.',
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.sponsorOutro,
  },
];

export const esgContent = [
  {
    body: [
      {
        text: 'ESG stands for "Environmental, Social, Governance." ',
      },
      {
        text:
          "It's a way of evaluating companies based on principles that go beyond traditional financial considerations.",
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'The "E" centers on environmental challenges, such as ',
      },
      {
        text: 'climate change, natural resource erosion, pollution and waste.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'The "S" covers ',
      },
      {
        text: 'social issues',
        bold: true,
      },
      {
        text:
          ' - for example, product liability, human rights, stakeholder opposition to labor relations and diversity, equity, and inclusion.',
      },
    ],
  },
  {
    body: [
      {
        text: 'The "G" relates to governance concerns such as ',
      },
      {
        text: 'corporate behavior',
        bold: true,
      },
      {
        text: ', a board of directors or executive compensation.',
      },
    ],
  },
  {
    body: [
      {
        text: 'ESG funds are one way to make investments that are aligned with these principles.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Many companies have also begun to outline ESG initiatives in annual "Sustainability" reports.',
      },
    ],
  },
];

export const valueStocksContent = [
  {
    body: [
      {
        text: 'Value stocks are companies that may be ',
      },
      {
        text: "trading below what they're actually worth.",
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: "They can be in any industry, but they're often well-established companies versus upstart growth stocks.",
      },
    ],
  },
  {
    body: [
      {
        text: "They're sometimes ",
      },
      {
        text: 'companies that are out of favor',
        bold: true,
      },
      {
        text:
          ' - for example, due to new competition, growing pains, a change in strategy, or uncertainty about future growth.',
      },
    ],
  },
  {
    body: [
      {
        text: "As a result, a value stock's P/E ratio and other valuation metrics tend to be lower than average.",
      },
      {
        text: 'In other words, ',
        isNewLine: true,
      },
      {
        text: 'they look cheap.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: "It's important to understand why their valuations are discounted. ",
      },
      {
        text: 'Sometimes there are good reasons, which can persist or even worsen.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Value investors are bargain hunters.',
        bold: true,
      },
      {
        text: ' If the stock recovers over time, it may reward patient investors with gains.',
      },
    ],
  },
];

export const lateralsContent = [
  {
    body: [
      {
        text: "Laterals describe how a stock is affected when there's news about a different company.",
      },
      {
        text: "They're ",
        isNewLine: true,
      },
      {
        text: 'ripple effects.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Investors pay close attention to laterals. ',
      },
      {
        text: 'They often have ',
        isNewLine: true,
      },
      {
        text: 'significant positive or negative impacts',
        bold: true,
      },
      {
        text: ' on stock prices.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Lateral effects can be found in ',
      },
      {
        text: 'stocks that operate in the same business',
        bold: true,
      },
      {
        text: ': for example, Home Depot and Lowe’s.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Other laterals are less obvious.',
        bold: true,
      },
      {
        text:
          ' For example, what John Deere says about tractor sales may impact drug company Bayer - because it owns seed company Monsanto.',
      },
    ],
  },
  {
    body: [
      {
        text: 'How do you identify laterals? Following ',
      },
      {
        text: 'competitors and companies with similar businesses',
        bold: true,
      },
      {
        text: ' is a great place to start.',
      },
    ],
  },
  {
    body: [
      {
        text: "Understanding a company's suppliers and customers also helps.",
        bold: true,
      },
      {
        text: " They can provide valuable perspective on anticipated demand for a company's products.",
      },
    ],
  },
];

export const makeDepositJulyContent = [
  {
    body: [
      {
        text: 'Did you know?',
      },
      {
        text: "JULY WAS THE STOCK MARKET'S",
        isNewLine: true,
      },
      {
        text: ' BEST MONTH',
        bold: true,
      },
      {
        text: ' of 2022',
      },
    ],
  },
  {
    body: [
      {
        text: 'What does that mean for you?',
        bold: true,
      },
      {
        text: 'If you’re not investing, you’re missing out on potential returns.',
        isNewLine: true,
      },
      {
        text: 'In fact, in each year since 1945, the US stock market has gone up approximately 70% of the time.',
        isNewLine: true,
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          'When you invest a little each month, you’ll end up paying an average price over time, despite ups and downs in the market. ',
      },
      {
        text: 'Consistency is key. ',
        isNewLine: true,
      },
      {
        text: '“Time in the market beats timing the market.” - Ken Fisher',
        isNewLine: true,
        bold: true,
      },
    ],
  },
];

export const investingVsTradingContent = [
  {
    // Investing and trading are sometimes used interchangeably. But there are very important differences.
    body: [
      {
        text: 'Investing and trading are sometimes used interchangeably. But there are ',
      },
      {
        text: 'very important differences.',
        bold: true,
      },
    ],
  },
  //   Investing involves a long-term focus, with an emphasis on fundamentals (e.g., sales, profits, valuation) that can drive the value of a company over time.
  {
    body: [
      {
        text: 'Investing involves a long-term focus',
        bold: true,
      },
      {
        text:
          ', with an emphasis on fundamentals (e.g., sales, profits, valuation) that can drive the value of a company ',
      },
      {
        text: 'over time.',
        bold: true,
      },
    ],
  },
  //   Investors focus on whether a company can succeed by growing its sales and/or profitability. They may believe a stock's price does not reflect its long-term potential.
  {
    body: [
      {
        text: 'Investors focus on whether a company can succeed by ',
      },
      {
        text: 'growing its sales and/or profitability.',
        bold: true,
      },
      {
        text: ' They may believe a ',
      },
      {
        text: "stock's price does not reflect its long-term potential.",
        bold: true,
      },
    ],
  },
  // Traders apply a more short-term view. They focus on near-term events and dynamics that could cause a stock to go up or down for a shorter period of time.
  {
    body: [
      {
        text: 'Traders apply a more short-term view.',
        bold: true,
      },
      {
        text: ' They focus on near-term ',
      },
      {
        text: 'events and dynamics ',
        bold: true,
      },
      {
        text: 'that could cause a stock to go up or down for a ',
      },
      {
        text: 'shorter period of time.',
        bold: true,
      },
    ],
  },
  //   While both can be successful, there are studies that suggest excessive trading may lead to under performance versus long-term investing.
  {
    body: [
      {
        text: 'While both can be successful, there are studies that suggest ',
      },
      {
        text: 'excessive trading may lead to under performance',
        bold: true,
      },
      {
        text: ' versus long-term investing.',
      },
    ],
  },
  //   Whether you're a trader or an investor there's always more to learn, and more to gain.
  {
    body: [
      {
        text: "Whether you're a trader or an investor there's ",
      },
      {
        text: 'always more to learn, and more to gain.',
        bold: true,
      },
    ],
  },
];

export const sp500Content = [
  {
    body: [
      {
        text: 'The ',
      },
      {
        text: 'S&P 500 ',
        bold: true,
      },
      {
        text: 'is a widely followed index that tracks the performance of the broader US stock market.',
      },
      {
        text: ' It features 500 companies and covers 80% of the US stock market.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'There are several ETFs, or exchange-traded funds, that follow the S&P 500. One of these is SPY.',
      },
    ],
  },
  {
    body: [
      {
        text: 'There are ',
      },
      {
        text: '11 sectors, or groups of similar stocks',
        bold: true,
      },
      {
        text:
          ', included in the S&P 500: technology, healthcare, consumer discretionary, energy, utilities, real estate, etc. Technology has the highest representation, utilities and real estate the lowest.',
      },
    ],
  },
  {
    body: [
      {
        text: "The S&P 500 is a ‘go-to’ measure for many investors. It's used to ",
      },
      {
        text: 'understand how the overall market is performing.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: "Keeping an eye on the differences between your portfolio's performance and the S&P 500's ",
      },
      {
        text: "helps you understand what you're doing well, and what you can do better.",
        bold: true,
      },
    ],
  },
];

export const investmentGoalsContent = [
  {
    body: [
      {
        text: 'Investing in stocks is one way to help ',
      },
      {
        text: 'grow your wealth ',
        bold: true,
      },
      {
        text: 'over time. Yet different people have different needs. ',
      },
      {
        text: 'Your goals can often inform your investment strategy.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'If your goal is to ',
      },
      {
        text: 'generate income',
        bold: true,
      },
      {
        text: ', you might consider stocks that ',
      },
      {
        text: 'pay a dividend.',
        bold: true,
      },
      {
        text: ' Some have grown dividends for 25 consecutive years or more. Dividend stocks can also ',
      },
      {
        text: 'provide a way for investors to earn during rocky market periods ',
        bold: true,
      },
      {
        text: 'when stock prices may be under pressure.',
      },
    ],
  },
  {
    body: [
      {
        text: 'If your goal is ',
      },
      {
        text: 'retirement',
        bold: true,
      },
      {
        text: ', you might consider stocks with ',
      },
      {
        text: 'long-term growth potential.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'If your goal is to ',
      },
      {
        text: 'purchase a house soon',
        bold: true,
      },
      {
        text: ', you might consider',
      },
      {
        text: ' conservative investments',
        bold: true,
      },
      {
        text: ', which can hold their value. Numerous ETFs (exchange-traded funds) may be worth looking at.',
      },
    ],
  },
  {
    body: [
      {
        text: 'If your goal is to ',
      },
      {
        text: 'participate in the overall stock market',
        bold: true,
      },
      {
        text: ', you might consider an ',
      },
      {
        text: 'index fund such as SPY.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Exploring stocks on Tornado can help you find ',
      },
      {
        text: 'investments that can support your own personal goals. ',
        bold: true,
      },
      {
        text: "There's always more to learn, and always more to gain.",
      },
    ],
  },
];

export const marketTimingContent = [
  {
    body: [
      {
        text: 'Market timing ',
        bold: true,
      },
      {
        text: 'is when investors try to ',
      },
      {
        text: '"buy low and sell high" ',
        bold: true,
      },
      {
        text: 'as a strategy.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Market timing has limitations.',
        bold: true,
      },
      {
        text: ' Investor Bill Miller noted that, in each year since 1945, the ',
      },
      {
        text: 'US stock market has gone up approximately 70% of the time.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'In fact, according to a study by the Capital Group, ',
      },
      {
        text: 'market timing can under perform the broad market by as much as 30% over long periods.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Given these challenges, experienced investors sometimes emphasize ',
      },
      {
        text: '"time in the market, not timing the market"',
        bold: true,
      },
      {
        text: ' as it relates to building long-term wealth.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Staying invested over the long term can ',
      },
      {
        text: 'reduce some of the risks related to market timing.',
        bold: true,
      },
    ],
  },
];

export const ladderLifeInsuranceMillenialsWithOutro = [
  {
    body: [
      {
        text: 'Why should life insurance factor into my financial plan?',
        bold: true,
      },
      {
        text:
          'One way to think about life insurance in your personal financial portfolio is to consider it in terms of protecting your future earnings and your loved ones.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Over time people invest in themselves by going to school, learning skills, and building on experiences.',
        bold: true,
      },
      {
        text: ' This leads to better jobs and more money over time.',
      },
      {
        text:
          'Then, they are able to borrow against those future earnings to get a mortgage, a car loan, or secure other personal loans.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          'One of the things life insurance does is take that human capital—your potential future earnings—and protect those earnings for your loved ones.',
        bold: true,
      },
      {
        text: 'Term life insurance protects the human capital risk in your family’s overall financial portfolio.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Is life insurance through work enough?',
        bold: true,
      },
      {
        text:
          'One rule of thumb is 10x your salary. This may sound like a lot, but it could be used to cover quite a bit: a mortgage, debt, higher education, etc.',
        isNewLine: true,
      },
      {
        text: "Plus, if you leave your job you'll likely leave your coverage behind, too.",
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'When should I buy life insurance?',
        bold: true,
      },
      {
        text: 'Unfortunately, you don’t have much control over when the unexpected will occur.',
        isNewLine: true,
      },
      {
        text:
          'And getting coverage early locks in your premium before your age and other factors increase the risk of insuring you.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Is term life insurance expensive?',
        bold: true,
      },
      {
        text: 'Term life insurance may be more affordable than you think.',
        isNewLine: true,
      },
      {
        text:
          'A healthy, non-smoking 30-year-old male would only pay an affordable $35–$40 a month for $1 million in coverage for a 20-year term.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Taken as part of your overall financial plan, ',
      },
      {
        text: 'you can think of term life insurance as having some of the best bang for your buck.',
        bold: true,
      },
      {
        text:
          'Ensuring that the people you care about will be provided for when you’re gone is a true act of love, and something everyone should consider.',
        isNewLine: true,
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];

export const ladderLifeInsuranceMillenialsWithQuoteTool = [
  {
    body: [
      {
        text: 'Why should life insurance factor into my financial plan?',
        bold: true,
      },
      {
        text:
          'One way to think about life insurance in your personal financial portfolio is to consider it in terms of protecting your future earnings and your loved ones.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Over time people invest in themselves by going to school, learning skills, and building on experiences.',
        bold: true,
      },
      {
        text: ' This leads to better jobs and more money over time.',
      },
      {
        text:
          'Then, they are able to borrow against those future earnings to get a mortgage, a car loan, or secure other personal loans.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          'One of the things life insurance does is take that human capital—your potential future earnings—and protect those earnings for your loved ones.',
        bold: true,
      },
      {
        text: 'Term life insurance protects the human capital risk in your family’s overall financial portfolio.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Is life insurance through work enough?',
        bold: true,
      },
      {
        text:
          'One rule of thumb is 10x your salary. This may sound like a lot, but it could be used to cover quite a bit: a mortgage, debt, higher education, etc.',
        isNewLine: true,
      },
      {
        text: "Plus, if you leave your job you'll likely leave your coverage behind, too.",
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'When should I buy life insurance?',
        bold: true,
      },
      {
        text: 'Unfortunately, you don’t have much control over when the unexpected will occur.',
        isNewLine: true,
      },
      {
        text:
          'And getting coverage early locks in your premium before your age and other factors increase the risk of insuring you.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Is term life insurance expensive?',
        bold: true,
      },
      {
        text: 'Term life insurance may be more affordable than you think.',
        isNewLine: true,
      },
      {
        text:
          'A healthy, non-smoking 30-year-old male would only pay an affordable $35–$40 a month for $1 million in coverage for a 20-year term.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Taken as part of your overall financial plan, ',
      },
      {
        text: 'you can think of term life insurance as having some of the best bang for your buck.',
        bold: true,
      },
      {
        text:
          'Ensuring that the people you care about will be provided for when you’re gone is a true act of love, and something everyone should consider.',
        isNewLine: true,
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.ladderQuoteTool,
  },
];

export const ladderLifeInsuranceMillenials = [
  {
    body: [
      {
        text: 'Why should life insurance factor into my financial plan?',
        bold: true,
      },
      {
        text:
          'One way to think about life insurance in your personal financial portfolio is to consider it in terms of protecting your future earnings and your loved ones.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Over time people invest in themselves by going to school, learning skills, and building on experiences.',
        bold: true,
      },
      {
        text: ' This leads to better jobs and more money over time.',
      },
      {
        text:
          'Then, they are able to borrow against those future earnings to get a mortgage, a car loan, or secure other personal loans.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          'One of the things life insurance does is take that human capital—your potential future earnings—and protect those earnings for your loved ones.',
        bold: true,
      },
      {
        text: 'Term life insurance protects the human capital risk in your family’s overall financial portfolio.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Is life insurance through work enough?',
        bold: true,
      },
      {
        text:
          'One rule of thumb is 10x your salary. This may sound like a lot, but it could be used to cover quite a bit: a mortgage, debt, higher education, etc.',
        isNewLine: true,
      },
      {
        text: "Plus, if you leave your job you'll likely leave your coverage behind, too.",
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'When should I buy life insurance?',
        bold: true,
      },
      {
        text: 'Unfortunately, you don’t have much control over when the unexpected will occur.',
        isNewLine: true,
      },
      {
        text:
          'And getting coverage early locks in your premium before your age and other factors increase the risk of insuring you.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Is term life insurance expensive?',
        bold: true,
      },
      {
        text: 'Term life insurance may be more affordable than you think.',
        isNewLine: true,
      },
      {
        text:
          'A healthy, non-smoking 30-year-old male would only pay an affordable $35–$40 a month for $1 million in coverage for a 20-year term.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Taken as part of your overall financial plan, ',
      },
      {
        text: 'you can think of term life insurance as having some of the best bang for your buck.',
        bold: true,
      },
      {
        text:
          'Ensuring that the people you care about will be provided for when you’re gone is a true act of love, and something everyone should consider.',
        isNewLine: true,
      },
    ],
  },
];

export const ladderLifeInsuranceGenericWithOutro = [
  {
    body: [
      {
        text: 'What is life insurance?',
        bold: true,
      },
      {
        text: "At its basic level, it's an agreement between you and a life insurance company.",
        isNewLine: true,
      },
      {
        text:
          'In exchange for a premium, the insurer promises to pay a sum of money upon your death to those you designate as beneficiaries.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Do I need life insurance?',
        bold: true,
      },
      {
        text:
          "To find out, ask yourself the following question: would your absence cause anyone financial strain? If so, the answer is yes. Let's take a look at the 5 top reasons you may need life insurance.",
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: '1. You have a mortgage or other shared debt.',
        bold: true,
      },
      {
        text: 'Consider life insurance if a parent has co-signed a student loan for you, for instance.',
        isNewLine: true,
      },
      {
        text:
          'Have you co-borrowed a mortgage, personal loan, or home equity line of credit with a spouse, partner, or sibling?',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: "2. Your life insurance through work isn't enough.",
        bold: true,
      },
      {
        text:
          'Group life insurance through an employer usually maxes out at a low fixed dollar amount or 1-2 times your annual salary.',
        isNewLine: true,
      },
      {
        text: 'Financial experts recommend 10x your salary as a rule of thumb.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: "3. You contribute a meaningful portion of your family's income.",
        bold: true,
      },
      {
        text: 'If you support (or will support)',
        isNewLine: true,
      },
      {
        text:
          ' a spouse, kids, parents, grandparents, siblings, or others and the loss of your income would affect them, you may need life insurance.',
      },
    ],
  },
  {
    body: [
      {
        text: '4. You have kids.',
        bold: true,
      },
      {
        text:
          "Even if you don't provide income your family relies on, you likely provide care that your family would have to pay for in your absence.",
        isNewLine: true,
      },
      {
        text: 'Life insurance can also meaningfully contribute to college savings.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: '5. You run a business.',
        bold: true,
      },
      {
        text: 'Life insurance can be extra important for small business owners.',
        isNewLine: true,
      },
      {
        text:
          'You might have taken on business debt using personal assets, like your home, as collateral. In that case, life insurance can help.',
        isNewLine: true,
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];

export const ladderLifeInsuranceGenericWithQuoteTool = [
  {
    body: [
      {
        text: 'What is life insurance?',
        bold: true,
      },
      {
        text: "At its basic level, it's an agreement between you and a life insurance company.",
        isNewLine: true,
      },
      {
        text:
          'In exchange for a premium, the insurer promises to pay a sum of money upon your death to those you designate as beneficiaries.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Do I need life insurance?',
        bold: true,
      },
      {
        text:
          "To find out, ask yourself the following question: would your absence cause anyone financial strain? If so, the answer is yes. Let's take a look at the 5 top reasons you may need life insurance.",
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: '1. You have a mortgage or other shared debt.',
        bold: true,
      },
      {
        text: 'Consider life insurance if a parent has co-signed a student loan for you, for instance.',
        isNewLine: true,
      },
      {
        text:
          'Have you co-borrowed a mortgage, personal loan, or home equity line of credit with a spouse, partner, or sibling?',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: "2. Your life insurance through work isn't enough.",
        bold: true,
      },
      {
        text:
          'Group life insurance through an employer usually maxes out at a low fixed dollar amount or 1-2 times your annual salary.',
        isNewLine: true,
      },
      {
        text: 'Financial experts recommend 10x your salary as a rule of thumb.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: "3. You contribute a meaningful portion of your family's income.",
        bold: true,
      },
      {
        text: 'If you support (or will support)',
        isNewLine: true,
      },
      {
        text:
          ' a spouse, kids, parents, grandparents, siblings, or others and the loss of your income would affect them, you may need life insurance.',
      },
    ],
  },
  {
    body: [
      {
        text: '4. You have kids.',
        bold: true,
      },
      {
        text:
          "Even if you don't provide income your family relies on, you likely provide care that your family would have to pay for in your absence.",
        isNewLine: true,
      },
      {
        text: 'Life insurance can also meaningfully contribute to college savings.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: '5. You run a business.',
        bold: true,
      },
      {
        text: 'Life insurance can be extra important for small business owners.',
        isNewLine: true,
      },
      {
        text:
          'You might have taken on business debt using personal assets, like your home, as collateral. In that case, life insurance can help.',
        isNewLine: true,
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.ladderQuoteTool,
  },
];

export const ladderLifeInsuranceGeneric = [
  {
    body: [
      {
        text: 'What is life insurance?',
        bold: true,
      },
      {
        text: "At its basic level, it's an agreement between you and a life insurance company.",
        isNewLine: true,
      },
      {
        text:
          'In exchange for a premium, the insurer promises to pay a sum of money upon your death to those you designate as beneficiaries.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Do I need life insurance?',
        bold: true,
      },
      {
        text:
          "To find out, ask yourself the following question: would your absence cause anyone financial strain? If so, the answer is yes. Let's take a look at the 5 top reasons you may need life insurance.",
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: '1. You have a mortgage or other shared debt.',
        bold: true,
      },
      {
        text: 'Consider life insurance if a parent has co-signed a student loan for you, for instance.',
        isNewLine: true,
      },
      {
        text:
          'Have you co-borrowed a mortgage, personal loan, or home equity line of credit with a spouse, partner, or sibling?',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: "2. Your life insurance through work isn't enough.",
        bold: true,
      },
      {
        text:
          'Group life insurance through an employer usually maxes out at a low fixed dollar amount or 1-2 times your annual salary.',
        isNewLine: true,
      },
      {
        text: 'Financial experts recommend 10x your salary as a rule of thumb.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: "3. You contribute a meaningful portion of your family's income.",
        bold: true,
      },
      {
        text: 'If you support (or will support)',
        isNewLine: true,
      },
      {
        text:
          ' a spouse, kids, parents, grandparents, siblings, or others and the loss of your income would affect them, you may need life insurance.',
      },
    ],
  },
  {
    body: [
      {
        text: '4. You have kids.',
        bold: true,
      },
      {
        text:
          "Even if you don't provide income your family relies on, you likely provide care that your family would have to pay for in your absence.",
        isNewLine: true,
      },
      {
        text: 'Life insurance can also meaningfully contribute to college savings.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: '5. You run a business.',
        bold: true,
      },
      {
        text: 'Life insurance can be extra important for small business owners.',
        isNewLine: true,
      },
      {
        text:
          'You might have taken on business debt using personal assets, like your home, as collateral. In that case, life insurance can help.',
        isNewLine: true,
      },
    ],
  },
];

export const stocksAndWealthContent = [
  {
    body: [
      {
        text: 'Stocks have driven ',
      },
      {
        text: 'superior wealth creation ',
        bold: true,
      },
      {
        text: 'over time. Since 1928, the S&P 500 index of US stocks has returned 9.8% on average, annually.',
      },
    ],
  },
  {
    body: [
      {
        text: 'There are ',
      },

      {
        text: 'multiple ways to invest in stocks',
        bold: true,
      },
      {
        text: '. They include index funds, thematic ETFs and single stocks.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Index Funds hold portfolios of stocks that ',
      },

      {
        text: 'track the returns of indices',
        bold: true,
      },
      {
        text: ', such as the S&P 500 or technology, for example. They have fees, though some are modest.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Thematic ETFs hold portfolios of stocks that might ',
      },

      {
        text: 'reflect a specific theme',
        bold: true,
      },
      {
        text: ', energy exploration or certain segments related to banking or transportation.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Single stocks carry higher risk, but you can ',
      },

      {
        text: 'build a diversified portfolio ',
        bold: true,
      },
      {
        text: 'that reflects your views and investment goals.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Mutual funds are ',
      },
      {
        text: 'portfolios of stocks that provide exposure to an industry or theme',
        bold: true,
      },
      {
        text: '; they have fees, and only 22% beat the S&P 500 over the ten years ending in 2019.',
      },
    ],
  },
  {
    body: [
      {
        text: 'You can ',
      },

      {
        text: 'start investing with as little as $5',
        bold: true,
      },
      {
        text:
          ". If you're not sure where to begin, index funds including the SPY offer broad diversification across US stocks.",
      },
    ],
  },
];

export const comparingStocksContent = [
  {
    body: [
      {
        text: 'Stocks are stakes in businesses that ',
      },
      {
        text: 'operate within a large and diverse economy.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'To ',
      },
      {
        text: 'help investors make sense of the market',
        bold: true,
      },
      {
        text: ', stocks are grouped into ',
      },
      {
        text: '11 sectors',
        bold: true,
      },
      {
        text: '. Examples of sectors are Technology and Energy.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Each sector is made up of ',
      },
      {
        text: 'companies that share similarities',
        bold: true,
      },
      {
        text: ' in how they drive sales and profits. Each sector also includes a number of sub-sectors.',
      },
    ],
  },
  {
    body: [
      {
        text: 'What happens with one stock often impacts other stocks in its sector – think of a ',
      },

      {
        text: '"domino effect".',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'When you look up a stock on Tornado you can see its sector and compare it ',
      },

      {
        text: 'with its peers',
        bold: true,
      },
      {
        text: " – it's a great way to understand how a company stacks up.",
      },
    ],
  },
  {
    body: [
      {
        text: 'Looking at stocks vs their peers ',
      },
      {
        text: 'can yield valuable insight',
        bold: true,
      },
      {
        text: '. Which is growing fastest? Which has better profitability?',
      },
    ],
  },
];

export const bigTechContent = [
  {
    body: [
      {
        text: '"Big tech" refers to ',
      },
      {
        text: 'premiere technology companies',
        bold: true,
      },
      {
        text: ' in the US and includes Apple (AAPL), Amazon (AMZN), Google (GOOG), and Microsoft (MSFT).',
      },
    ],
  },
  {
    body: [
      {
        text: 'Overall, the term "Big tech" can also include companies that are ',
      },
      {
        text: 'dominant in their field',
        bold: true,
      },
      {
        text: ', such as Netflix (NFLX), Nvidia (NVDA), or Meta (FB).',
      },
    ],
  },
  {
    body: [
      {
        text: 'The common thread is the ',
      },
      {
        text: 'use of technology to drive innovation',
        bold: true,
      },
      {
        text: ': FB and GOOG in ads, AAPL in consumer devices, MSFT in software, and AMZN in e-commerce.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Another common theme is ',
      },
      {
        text: 'large market values',
        bold: true,
      },
      {
        text: '. Technology, the broad category or sector, accounts for over 20% of the S&P 500.',
      },
    ],
  },
  {
    body: [
      {
        text: 'While each company in "big tech" has different drivers and risks, they tend to be at the ',
      },
      {
        text: 'forefront of trends',
        bold: true,
      },
      {
        text: ' that impact the economy.',
      },
    ],
  },
  {
    body: [
      {
        text: 'As a result, "Big tech" has also accounted for a large portion of the overall ',
      },
      {
        text: "market's performance ",
        bold: true,
      },
      {
        text: 'over the past decade.',
      },
    ],
  },
];

export const interestRatesContent = [
  {
    body: [
      {
        text: 'Interest rates can have a ',
      },
      {
        text: 'big impact on economic growth and stock prices.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'For the economy, ',
      },
      {
        text: 'rising interest rates ',
        bold: true,
      },
      {
        text: 'can make it harder to borrow money, which can ',
      },
      {
        text: 'decrease business activity',
        bold: true,
      },
      {
        text: ' and reduce economic growth. ',
      },
      {
        text: 'Falling rates ',
        bold: true,
      },
      {
        text: 'can ',
      },
      {
        text: 'stimulate growth.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'For investing, ',
      },
      {
        text: 'higher interest rates can make bonds attractive ',
        bold: true,
      },
      {
        text: 'relative to stocks, causing investment managers to sell stocks and / or buy bonds.',
      },
    ],
  },
  {
    body: [
      {
        text:
          "For stocks, many investors base valuations (the price they're willing to pay today) on projections for future cash flow. ",
      },
      {
        text: 'Rising interest rates can reduce cash flow projections',
        bold: true,
      },
      {
        text: '; falling rates can increase cash flow forecasts.',
      },
    ],
  },
  {
    body: [
      {
        text: 'What drives interest rates? Factors including ',
      },
      {
        text: 'consumer prices, producer prices, employment, government policy, and GDP',
        bold: true,
      },
      {
        text: ' growth.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Interest rates can also be ',
      },
      {
        text: 'influenced by the Federal Reserve',
        bold: true,
      },
      {
        text: ' as it engages in activity designed to raise rates or lower rates.',
      },
    ],
  },
];

export const marginsContent = [
  {
    body: [
      {
        text: 'Profitability is a ',
      },
      {
        text: 'central concept ',
        bold: true,
      },
      {
        text: 'in investing: investors use ',
      },
      {
        text: 'profit margins',
        bold: true,
      },
      {
        text:
          " - how much profit a company makes as a percent of sales - to measure a company's ability to generate earnings.",
      },
    ],
  },
  {
    body: [
      {
        text: 'The most common measures include ',
      },
      {
        text: 'gross margin ',
        bold: true,
      },
      {
        text: 'and ',
      },
      {
        text: 'operating margin; the latter ',
        bold: true,
      },
      {
        text: 'is often referred to as ',
      },
      {
        text: 'EBIT margin ',
        bold: true,
      },
      {
        text: '(Earnings Before Interest and Taxes).',
      },
    ],
  },
  {
    body: [
      {
        text: 'Gross margin measures the ',
      },
      {
        text: 'profit the company makes on its core products or services',
        bold: true,
      },
      {
        text: ". It's calculated by dividing gross profit by sales.",
      },
    ],
  },
  {
    body: [
      {
        text: 'Operating, or EBIT margin, ',
      },
      {
        text: 'measures profit after adding additional expenses ',
        bold: true,
      },
      {
        text: "including salaries, marketing, rent and freight. It's found by dividing operating profit by sales.",
      },
    ],
  },
  {
    body: [
      {
        text: 'Margins should be ',
      },
      {
        text: 'understood in the context of the business',
        bold: true,
      },
      {
        text: '. Microsoft and Google will have much different margins than Wal-Mart and Target.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Investors use margins to find ',
      },
      {
        text: 'best in class operators',
        bold: true,
      },
      {
        text: ", to determine if there's an ",
      },
      {
        text: 'opportunity to improve, and to identify risks',
        bold: true,
      },
      {
        text: ' that could cause a decline.',
      },
    ],
  },
];

export const valuationMultiplesContent = [
  {
    body: [
      {
        text: 'A multiple is a ',
      },
      {
        text: 'simple way',
        bold: true,
      },
      {
        text: ' to assess ',
      },
      {
        text: "a stock's valuation",
        bold: true,
      },
      {
        text: ': is it ',
      },
      {
        text: '"cheap" or "expensive"?',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: "A multiple is a company's ",
      },
      {
        text: 'stock price divided by a metric related to the company',
        bold: true,
      },
      {
        text: ', for example, earnings per share (EPS).',
      },
    ],
  },
  {
    body: [
      {
        text: 'Example: If the price of a stock is $20 and it has $2 in earnings per share (EPS), its ',
      },
      {
        text: 'EPS multiple is 10',
        bold: true,
      },
      {
        text: ', ($20 divided by $2). Said another way, it ',
      },
      {
        text: 'trades at 10x EPS.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Another name for a multiple is a ratio',
        bold: true,
      },
      {
        text: '.  An EPS multiple of 10 is the same as a price to earnings ratio (P/E ratio) of 10.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Multiples can be based on ',
      },
      {
        text: 'future earnings ',
        bold: true,
      },
      {
        text: '(sometimes referred to a "forward multiple"). They can also be based on ',
      },
      {
        text: 'actual historical earnings',
        bold: true,
      },
      {
        text: ' (a "historical multiple").',
      },
    ],
  },
  {
    body: [
      {
        text: 'Multiples can be used to ',
      },
      {
        text: 'compare one stock to another stock',
        bold: true,
      },
      {
        text: ". They can also be used to compare a stock's ",
      },
      {
        text: 'valuation today ',
        bold: true,
      },
      {
        text: 'with its ',
      },
      {
        text: 'valuation at a prior period.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: "A stock's multiple can also reveal insight into ",
      },
      {
        text: 'investor perceptions about its growth',
        bold: true,
      },
      {
        text:
          '. High multiples suggest optimism about growth; low multiples indicate investors may question growth prospects.',
      },
    ],
  },
];

export const esgInvestingContent = [
  {
    body: [
      {
        text: 'ESG is growing significantly, and so are the choices for ',
      },
      {
        text: 'investors who want to align with environmental and social considerations.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Some predict that ',
      },
      {
        text: 'ESG assets',
        bold: true,
      },
      {
        text: ' could make up half of all professionally managed investments by 2025.',
      },
    ],
  },
  {
    body: [
      {
        text: 'ETFs and ‘passive funds’ can be a ',
      },
      {
        text: 'good place to start',
        bold: true,
      },
      {
        text: '. iShares and Vanguard offer low cost choices that are often ',
      },
      {
        text: 'widely diversified across stocks.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'You can also ',
      },
      {
        text: 'target individual companies that have ESG characteristics',
        bold: true,
      },
      {
        text: ', such as Tesla (TSLA), Apple (AAPL), or companies involved in solar energy and other ESG themes.',
      },
    ],
  },
  {
    body: [
      {
        text: "One key way to find suitable ESG investments is to review a company's ",
      },
      {
        text: 'Sustainability Report',
        bold: true,
      },
      {
        text: ' to see if their priorities match your own. These can be found on company websites.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Independent ',
      },
      {
        text: 'ESG ratings',
        bold: true,
      },
      {
        text: ' issued by MSCI, Refinitiv, Sustainalytics, and other firms can also help inform ESG selection.',
      },
    ],
  },
];

export const esgRatingsContent = [
  {
    body: [
      {
        text: 'ESG ',
      },
      {
        text: 'ratings score a company’s commitment',
        bold: true,
      },
      {
        text: ' to three core aspects of ESG: the environment, social issues, and corporate governance.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Raters use a combination of ',
      },
      {
        text: 'public information ',
        bold: true,
      },
      {
        text: '(corporate filings, company updates) and ',
      },
      {
        text: 'third-party data',
        bold: true,
      },
      {
        text: ' from media, academic, regulatory sources.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'ESG ratings are available from a growing list of companies including MSCI, Refinitiv, Sustainalytics, and S&P.',
      },
    ],
  },
  {
    body: [
      {
        text: 'ESG ratings have limitations.',
        bold: true,
      },
      {
        text: ' One source may rate a company high on ESG, while another source may rate that same company lower.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Factors to consider for raters include ',
      },
      {
        text: 'broad coverage',
        bold: true,
      },
      {
        text: ' across sectors and geographies, ',
      },
      {
        text: 'methodology, data quality and experience.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'While ESG ratings are useful, they’re not a substitute for ',
      },
      {
        text: 'doing your own due diligence ',
        bold: true,
      },
      {
        text: 'before you invest.',
      },
    ],
  },
  {
    body: [
      {
        text: "One key way to find suitable ESG investments is to review a company's ",
      },
      {
        text: 'Sustainability Report ',
        bold: true,
      },
      {
        text: 'to see if their priorities match your own. These can be found on company websites.',
      },
    ],
  },
];

export const informationTechnologyContent = [
  {
    body: [
      {
        text: 'The ',
      },
      {
        text: 'Information Technology sector ',
        bold: true,
      },
      {
        text:
          'includes Apple (AAPL), Microsoft (MSFT), Nvidia (NVDA), Adobe (ADBE), Salesforce.com (CRM) and Cisco (CSCO).',
      },
    ],
  },
  {
    body: [
      {
        text: 'It also includes companies that ',
      },
      {
        text: 'rely heavily on technology ',
        bold: true,
      },
      {
        text: 'to operate their businesses, such as Visa (V) and Mastercard (MA).',
      },
    ],
  },
  {
    body: [
      {
        text: 'The sector has the ',
      },
      {
        text: 'highest weighting in the S&P 500',
        bold: true,
      },
      {
        text: ', accounting for 27.1% of the index as of May 31, 2022.',
      },
    ],
  },
  {
    body: [
      {
        text: 'One reason for that is its breadth; the sector is driven by ',
      },
      {
        text: 'global corporate spending ',
        bold: true,
      },
      {
        text: 'on information technology (IT), which is over $4.5 trillion/year.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Corporate IT spending includes a range of ',
      },
      {
        text: 'ongoing digital initiatives',
        bold: true,
      },
      {
        text: ': e-commerce, connectivity, security, cloud.',
      },
    ],
  },
  {
    body: [
      {
        text: 'The ',
      },
      {
        text: 'Nasdaq market ',
        bold: true,
      },
      {
        text: 'features many technology companies, and there are some widely popular ETFs such as the ',
      },
      {
        text: 'QQQ',
        bold: true,
      },
      {
        text: ' that feature technology stocks.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Check out IT by using the filters above the feed in-app and looking up ',
      },
      {
        text: 'specific tickers like XLK ',
        bold: true,
      },
      {
        text: 'with the ticker look-up function (see the magnifying glass icon).',
      },
    ],
  },
];

export const healthcareContent = [
  {
    body: [
      {
        text: 'The ',
      },
      {
        text: 'health care sector ',
        bold: true,
      },
      {
        text:
          'includes Johnson & Johnson (JNJ), UnitedHealth (UNH), Pfizer (PFE), Abbott Laboratories (ABT) and Merck (MRK).',
      },
    ],
  },
  {
    body: [
      {
        text: 'The sector has the ',
      },
      {
        text: 'second highest weighting in the S&P 500',
        bold: true,
      },
      {
        text:
          ', at 14% as of May 2022; it accounts for a broad swath of economic activity, from hospitals to drugs to surgical procedures.',
      },
    ],
  },
  {
    body: [
      {
        text: 'The industry is ',
      },
      {
        text: 'heavily regulated',
        bold: true,
      },
      {
        text:
          ', so changes to government regulations from the FDA and other overseers can have an outsized impact in this sector.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Health care revenues are ',
      },
      {
        text: 'supported by insurance',
        bold: true,
      },
      {
        text: ', so changes there can be a key factor in overall health care spend.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Another driver for the sector is development of ',
      },
      {
        text: 'new pharmaceutical treatments, often called pipelines',
        bold: true,
      },
      {
        text: '; these can be found in investor presentations and SEC filings.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Trends including ',
      },
      {
        text: 'telemedicine and remote care ',
        bold: true,
      },
      {
        text: 'could also have broad implications for the sector.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Check out health care companies by using the ticker look up function (enter ticker like ',
      },
      {
        text: 'XLV',
        bold: true,
      },
      {
        text: 'in the text field next to the magnifying glass) in Tornado',
      },
    ],
  },
];

export const consumerDiscretionaryContent = [
  {
    body: [
      {
        text: 'The ',
      },
      {
        text: 'consumer discretionary sector ',
        bold: true,
      },
      {
        text:
          "includes Amazon (AMZN), Tesla (TSLA), Home Depot (HD), McDonald's (MCD), Nike (NKE), Starbucks (SBUX), Target (TGT), Booking Holdings (BKNG).",
      },
    ],
  },
  {
    body: [
      {
        text: "It's ",
      },
      {
        text: 'geared to consumer spending',
        bold: true,
      },
      {
        text: ' on US goods and services and comprised 11.8% of the S&P 500 as of February 2022.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Key drivers for the sector include ',
      },
      {
        text: 'macro-related factors ',
        bold: true,
      },
      {
        text: 'like housing growth, income, and employment.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Key measures for the sector include ',
      },
      {
        text: 'same store sales, active users ',
        bold: true,
      },
      {
        text: 'and e-commerce revenue / profitability.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Digital trends have ',
      },
      {
        text: 'reshaped the sector ',
        bold: true,
      },
      {
        text: 'and may play an ongoing role in identifying winners and losers.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Check out consumer discretionary companies by using the ticker look up function (enter ticker like ',
      },
      {
        text: 'XLY ',
        bold: true,
      },
      {
        text: 'in the text field next to the magnifying glass) in Tornado',
      },
    ],
  },
];

export const famousFemaleInvestorsContent = [
  {
    body: [
      {
        text: 'Women outearn men when they invest.',
        bold: true,
      },
      {
        text: 'So why do we only ever hear about what Warren Buffet’s doing in the stock market?',
        isNewLine: true,
      },
      {
        text: 'Here are five famous female investors you need to know.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Geraldine Weiss',
        bold: true,
      },
      {
        text:
          'First female investment advisor, who founded Investment Quality Trends after being turned down for work as a stockbroker for being a woman.',
        isNewLine: true,
      },
      {
        text: 'She was a top investment advisor for more than 60 years.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Mellody Hobson',
        bold: true,
      },
      {
        text: 'One of Forbes’ 100 Most Powerful Women and co-chair of Ariel Investments.',
        isNewLine: true,
      },
      {
        text: 'She leads projects that teach financial literacy and increase diversity in finance and investing.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Lubna Olayan',
        bold: true,
      },
      {
        text: 'First Saudi Arabian woman to lead a national bank.',
        isNewLine: true,
      },
      {
        text:
          'She’s the CEO of Olayan Group, an investment bank that has helped diversify the Saudi economy and decrease reliance on oil.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Amy Zhang',
        bold: true,
      },
      {
        text:
          'EVP at Alger, Zhang has been honored by The Wall Street Journal and many others for the strong performance of her stock portfolios and her role in business development.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Lauren Simmons',
        bold: true,
      },
      {
        text: 'Became the youngest woman New York Stock Exchange floor trader at age 22.',
        isNewLine: true,
      },
      {
        text: 'She shares her spending advice on streaming series & at speaking engagements all over the world.',
        isNewLine: true,
      },
    ],
  },
];

export const howMuchToInvestContent = [
  {
    body: [
      {
        text: 'How much should I invest?',
        bold: true,
      },
      {
        text: 'The top question on your mind when you open your first brokerage account.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: '15%',
        bold: true,
      },
      {
        text: 'Standard advice is to invest 15% of your pre-tax income for long-term goals like retirement.',
        isNewLine: true,
      },
      {
        text: 'Good news: Your employer’s 401(k) counts toward that amount!',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: '80%',
        bold: true,
      },
      {
        text: 'Most people should aim to replace 80% of their pre-retirement income for retirement.',
        isNewLine: true,
      },
      {
        text: 'How much you invest now depends on how much you want saved in the long run.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: "What if you can't afford 15%?",
        bold: true,
      },
      {
        text: 'Get started investing with any amount you can!',
        isNewLine: true,
      },
      {
        text: ' The earlier the better,',
        bold: true,
      },
      {
        text: ' because time is the best ',
      },
      {
        text: 'nutrient for growing investments.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Before investing, ',
        bold: true,
      },
      {
        text: 'pay off high-interest credit card debt and build a three-month emergency fund.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Consistency is key.',
        bold: true,
      },
      {
        text:
          'Regular contributions, instead of occasional lump sum investments, could cost less and help your portfolio grow faster.',
        isNewLine: true,
      },
    ],
  },
];

export const choosingYourFirstInvestmentContent = [
  {
    body: [
      {
        text: 'Investing is a big milestone!',
      },
      {
        text: 'Choosing your first investment doesn’t have to be stressful, so here are some ',
        isNewLine: true,
      },
      {
        text: 'tips to make it easy.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Diversify',
        bold: true,
      },
      {
        text: 'Consider multiple companies and industries.',
        isNewLine: true,
      },
      {
        text: 'Staking all your money in one business or sector can make you overly vulnerable to crashes.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Invest in ETFs',
      },
      {
        text: 'Exchange-traded funds put your money into several companies at once, with the ease of one purchase.',
        isNewLine: true,
      },
      {
        text: 'An ETF adds instant diversification to your portfolio.',
        isNewLine: true,
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Track an index',
        bold: true,
      },
      {
        text:
          'An ETF or index fund that tracks an index like the S&P 500 puts your money in hundreds of top-performing stocks.',
        isNewLine: true,
      },
      {
        text: 'The S&P 500 has averaged annual returns above 10% for the past 40 years.',
        isNewLine: true,
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Choose companies you understand. ',
        bold: true,
      },
      {
        text: 'Next, choose individual stocks.',
      },
      {
        text: 'Stick with companies or industries you know well, like the industry you work in.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Invest in fractional shares.',
        bold: true,
      },
      {
        text:
          ' You can invest with any amount of money and own a piece of a share of any company — even the ones with super-pricy shares, like Tesla!',
      },
      {
        text: 'Just decide how much you want to invest, and pick your stock.',
        bold: true,
      },
    ],
  },
];

export const portfoliosContent = [
  {
    body: [
      {
        text: 'What is a portfolio? ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'An investment portfolio is a conceptual basket that represents the cash, stocks, bonds and other products you own.',
      },
    ],
  },
  {
    body: [
      {
        text: "Your portfolio doesn't live within a single product, account or even financial institution. ",
      },
      {
        lineBreak: true,
      },
      {
        text: 'It’s the total of all your investment products and accounts.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'How do you get a portfolio? ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'You have a portfolio as soon as you buy an investment. ',
        bold: true,
      },
      {
        text:
          'You don’t have to sign up or open an account — because your “portfolio” is just a term that refers to the investments you own.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Why are portfolios important? ',
        bold: true,
      },
      {
        text: 'Your portfolio is ',
      },
      {
        text: 'a tool for seeing your full financial picture in one place',
        bold: true,
      },
      {
        text: ' — and following your progress as the value grows!',
      },
    ],
  },
  {
    body: [
      {
        text: 'How do investors use portfolios? ',
        bold: true,
      },
      {
        text: 'Check and ',
      },
      {
        text: 'adjust your portfolio a few times a year ',
        bold: true,
      },
      {
        text: 'to keep its diversity and risk level in line with your goals.',
      },
      {
        text: ' (i.e. It helps you keep your investments safe and growing.)',
      },
    ],
  },
];

export const marketDownturnsContent = [
  {
    body: [
      {
        text: 'What is a down market? a.k.a. “bear market” ',
      },
      {
        text:
          'It happens when stock prices broadly have dropped, continue to drop and are expected to continue to drop for some time. ',
        isNewLine: true,
      },
      {
        text: 'Here’s how to weather a market downturn.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: "Don't panic. ",
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: "Investing is a long game. A temporary dip in prices doesn't have to ruin your plans.",
      },
    ],
  },
  {
    body: [
      {
        text: 'Understand your goals. ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'They can help you understand how the market dip will affect your plans. ',
      },
      {
        lineBreak: true,
      },
      {
        text:
          'An advisor could help keep you on track for retirement and advise on stocks to help maintain short-term gains.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Assess your strategy. ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Pulling money out of your investment accounts in a down market is a formula for losses. ',
      },
      {
        lineBreak: true,
      },
      {
        text: 'When stock prices are low, contributing more could lead to benefits when prices go back up. ',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Keep an eye on consumer staples. ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'We’ll keep buying things like toothpaste, utilities and health care in a slow economy. ',
      },
      {
        text: 'Investing in these can stabilize your portfolio against volatility. "',
      },
    ],
  },
  {
    body: [
      {
        text: 'Look for comparative advantages. ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Pick companies that can withstand competition because of their unique brand, cost, user experience or other advantages.',
      },
    ],
  },
];

export const referAFriendContent = [
  {
    body: [
      {
        text: 'Invite your friends, ',
      },
      {
        text: 'earn up to',
      },
      {
        text: ' $1,000 to invest',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Successful investors know the value of exchanging and debating ideas.',
        bold: true,
      },
      {
        text: '',
        isNewLine: true,
      },
      {
        text:
          'On Tornado, you can use Pros and Cons (found on every stock page) to give your connections feedback on their stock ideas, and get their feedback on yours.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'You can also read the latest thoughts from your network in your Activity feed, which is full of ',
      },
      {
        text: 'high-caliber investing commentary, news, and conversations.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Investors on Tornado grow together.',
        isNewLine: true,
        bold: true,
      },
      {
        text: '',
        isNewLine: true,
      },
      {
        text: 'For a limited time, invite a friend and you can earn up to $1,000 to invest.',
        isNewLine: true,
      },
      {
        text: '',
        isNewLine: true,
      },
      {
        text: `You’re guaranteed to receive at least $${MIN_REWARD_SIGN_UP} to invest.`,
        isNewLine: true,
      },
      {
        text: '',
        isNewLine: true,
      },
      {
        text: 'No catch.',
        bold: true,
      },
    ],
  },
];

export const referAFriendContentAlt50Bonus = [
  {
    body: [
      {
        text: 'Invite your friends, ',
      },
      {
        text: 'earn up to',
      },
      {
        text: ' $1,000 to invest',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Successful investors know the value of exchanging and debating ideas.',
        bold: true,
      },
      {
        text: '',
        isNewLine: true,
      },
      {
        text:
          'On Tornado, you can use Pros and Cons (found on every stock page) to give your connections feedback on their stock ideas, and get their feedback on yours.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'You can also read the latest thoughts from your network in your Activity feed, which is full of ',
      },
      {
        text: 'high-caliber investing commentary, news, and conversations.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Investors on Tornado grow together.',
        isNewLine: true,
        bold: true,
      },
      {
        text: '',
        isNewLine: true,
      },
      {
        text: 'For a limited time, invite a friend and when they sign up, and deposit money you will earn ',
        isNewLine: true,
      },
      {
        text: '$50 per friend, to invest.',
        bold: true,
      },
    ],
  },
];

export const wagmoContent = [
  {
    body: [
      {
        text: '',
      },
    ],
  },
  {
    body: [
      {
        text:
          'Looking strictly at the health costs associated with a pet, we see that pets need a lot of care in a year. ',
      },
      {
        text: 'Between vaccines and basic care, ',
        isNewLine: true,
      },
      {
        text: 'health costs associated with owning a dog or cat can cost up to $750 annually. ',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: "This is just for basic care and doesn't include accidents or critical illness.",
        bold: true,
      },
      {
        text: 'For those emergencies, it is recommended that pet owners keep $1,000-$4,000 on hand.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Of course, the health benefits of pet ownership are well documented. ',
        bold: true,
      },
      {
        text: 'A pet can reduce anxiety and stress and ease loneliness and depression. ',
        isNewLine: true,
      },
      {
        text: 'They can also encourage fun and exercise.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Pet insurance plans can make keeping your pet healthy and happy more accessible. ',
        bold: true,
      },
      {
        text:
          'Plans provide financial protection in case your pet should ever need medical care for unexpecting injuries or illness.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Wagmo offers coverage designed for modern-day pet parents, with a hassle-free digital experience. ',
      },
      {
        text: 'Unlike other pet insurance companies, Wagmo also offers pet wellness separate from pet insurance.',
        isNewLine: true,
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
    extra: {
      body: [
        {
          text: 'Emergency vet coverage in case your pet is in a jam...or ate a container of it.',
        },
        {
          text: "Create a plan based on your family's needs, and you can always add on later.",
        },
      ],
    },
  },
];

export const lemonadeContent = [
  {
    body: [
      {
        text: '',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Maybe you’ve got questions about renters insurance.',
        bold: true,
      },
      {
        text: 'Do you need it? How much does it cost? When should you get it?',
        bold: true,
        isNewLine: true,
      },
      {
        text: 'Let’s unpack renters insurance.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Renters insurance can protect your personal property from damage or loss—inside or outside your home.',
        bold: true,
      },
      {
        text:
          'Plus, you can also protect yourself against damages from someone else’s accidents if they happen in your home.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Do you need renters insurance? A couple of things to consider:',
        isNewLine: true,
      },
      {
        text:
          '1. Your landlord’s insurance covers the building but doesn’t cover your personal property. You likely have more stuff than you realize—and it might be worth more than you think.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: '2. Renters insurance is affordable.',
        bold: true,
      },
      {
        text:
          "You can get a renters insurance policy for as low as $5 per month—and you'll enjoy priceless peace of mind.",
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'When should I buy renters insurance?',
        bold: true,
      },
      {
        text: 'Consider doing it now.',
        isNewLine: true,
      },
      {
        text:
          'If you wait, and your bathroom floods, or your bike is stolen, you won’t be able to quickly purchase renters insurance and have it retroactively cover those damages.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Getting renters insurance is actually pretty simple.',
        bold: true,
      },
      {
        text:
          'When signing up for a policy, you’re able to choose different coverage limits for personal liability, personal property, and so on. ',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'To apply for a renters policy, you won’t need much beyond:',
      },
      {
        text: '    1. Your address',
        isNewLine: true,
      },
      {
        text: '    2. A sense of how much coverage ',
        isNewLine: true,
      },
      {
        text: '        you’ll need',
        isNewLine: true,
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];

export const vspdirectContent = [
  {
    body: [
      {
        text: '',
      },
    ],
  },
  {
    body: [
      {
        text: 'When it comes to your health–eye care can be easily overlooked.',
        bold: true,
      },
      {
        text:
          'Whether or not you wear glasses or contacts, a comprehensive eye exam is often considered essential to your overall health.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'What is vision insurance?',
        bold: true,
      },
      {
        text: 'Vision insurance plans help you take care of your eyes and save you money at the same time.',
        isNewLine: true,
      },
      {
        text:
          'A vision plan helps cover the cost of an annual eye exam, frames or contacts, and lens enhancements like progressive and scratch resistance.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'How does vision insurance work?',
        bold: true,
      },
      {
        text: 'Once enrolled in a vision plan, it covers vision-related expenses when you visit an eye doctor.',
        isNewLine: true,
      },
      {
        text:
          'Visiting an in-network doctor offers the most savings. Vision plans give you defined costs on things like an annual eye exam, frames, lenses, and more.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'How much is vision insurance?',
        bold: true,
      },
      {
        text: 'Vision insurance costs can vary depending on your vision needs and rate in your state.',
        isNewLine: true,
      },

      {
        text: 'You can find plans as low as $13 a month. Typical annual savings with a vision plan is more than $200.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          'VSP® Individual Vision Plans offer personalized coverage with multiple plan options and flexible monthly payments.',
      },
      {
        text: 'They also offer member savings like an Extra $40 to spend on select frame brands.',
        isNewLine: true,
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];

export const pricingPowerContent = [
  {
    body: [
      {
        text: "Pricing power is a company's ability to ",
      },
      {
        text: 'raise prices without reducing its demand.',
        bold: true,
      },
      {
        text: " It's important when considering both ",
      },
      {
        text: 'competition ',
        bold: true,
      },
      {
        text: 'and ',
      },
      {
        text: 'inflation.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Warren Buffett has called pricing power, "',
      },
      {
        text: 'the single most important decision ',
        bold: true,
      },
      {
        text: 'in evaluating a business."',
      },
    ],
  },
  {
    body: [
      {
        text: 'Pricing power can be reflected in ',
      },
      {
        text: 'stable and/or improving margins ',
        bold: true,
      },
      {
        text: 'over time, even as new competition enters.',
      },
    ],
  },
  {
    body: [
      {
        text: "For example, Berkshire Hathaway's famous ",
      },
      {
        text: "See's Candies ",
        bold: true,
      },
      {
        text:
          'was for decades (1972-2006) able to pass through an annual 5.8% price increase, even as the industry evolved.',
      },
    ],
  },
  {
    body: [
      {
        text: 'In 2021, Chipotle raised prices by 8% for a chicken burrito ',
      },
      {
        text: 'without suffering a reduction in demand and said they could raise prices again.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          'Pricing power can also cushion the impact of inflation by allowing a company to offset rising costs as others may suffer margin pressure.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Check out ',
      },
      {
        text: 'earnings transcripts ',
        bold: true,
      },
      {
        text:
          'for management commentary on pricing, and keep an eye out for posts regarding pricing in pros/cons for specific tickers.',
      },
    ],
  },
];

export const tenKContent = [
  {
    body: [
      {
        text: 'The 10-K is an ',
      },
      {
        text: 'annual report ',
        bold: true,
      },
      {
        text: "filed by publicly traded companies – it's required by the SEC.",
      },
    ],
  },
  {
    body: [
      {
        text: "While it can appear daunting, there's ",
      },
      {
        text: 'something for every level of investor ',
        bold: true,
      },
      {
        text: "in a 10-K, from basics on the company's strategy to finer points on cash flow and debt.",
      },
    ],
  },
  {
    body: [
      {
        text: 'The report has five sections',
        bold: true,
      },
      {
        text:
          ': Business, Risk Factors, Selected Financial Data, Management Discussion and Analysis, and Financial Statements and Supplementary Data.',
      },
    ],
  },
  {
    body: [
      {
        text: 'The "Business" section explains ',
      },
      {
        text: 'what the company does',
        bold: true,
      },
      {
        text: ': its markets, its opportunities, and, usually, its ',
      },
      {
        text: 'strategy to drive sales and profits.',
        bold: true,
      },
      {
        text: " It's a must-read.",
      },
    ],
  },
  {
    body: [
      {
        text:
          'The "Risk Factors" section lays out risks to the company\'s industry and business. Some of it\'s boilerplate, but there are often ',
      },
      {
        text: 'valuable insights into potential issues.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: '"Management Discussion and Analysis" explains ',
      },
      {
        text: 'what drove recent results ',
        bold: true,
      },
      {
        text: 'and ties to the "Selected Financial Data" section. It\'s a great way to see ',
      },
      {
        text: 'how the company is executing against its priorities',
        bold: true,
      },
      {
        text: '.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Warren Buffett reportedly once said he read 500 pages like this every day, because ',
      },
      {
        text: '“That’s how knowledge builds up, like compound interest.”',
        bold: true,
      },
    ],
  },
];

export const guidanceContent = [
  {
    body: [
      {
        text: 'Company guidance, sometimes referred to as its ',
      },
      {
        text: '"outlook,"',
        bold: true,
      },
      {
        text: " is a company's ",
      },
      {
        text: 'estimate of its future earnings.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          "It's usually provided for a full year and typically updated each quarter; the use of quarterly outlooks is decreasing as companies try to focus on long-term results.",
      },
    ],
  },
  {
    body: [
      {
        text: 'Guidance can include commentary on ',
      },
      {
        text: 'projected revenues, profit margins, earnings per share, and capital plans ',
        bold: true,
      },
      {
        text: '(investments, dividends, and repurchases). Some or all of these may be provided.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Guidance has a ',
      },
      {
        text: 'direct impact on consensus earnings estimates',
        bold: true,
      },
      {
        text: ", which play into analysts' price targets.",
      },
    ],
  },
  {
    body: [
      {
        text: 'Guidance also ',
      },
      {
        text: 'influences investor perception',
        bold: true,
      },
      {
        text: ' of a company. Can it generate favorable investment returns, or are there growing risks?',
      },
    ],
  },
  {
    body: [
      {
        text: 'Company guidance also has ',
      },
      {
        text: 'ripple effects',
        bold: true,
      },
      {
        text: ': it may be interpreted as an indication of strength – or weakness – ',
      },
      {
        text: 'for competitors or related companies.',
        bold: true,
      },
    ],
  },
];

export const ROIContent = [
  {
    body: [
      {
        text: "How do you measure a stock's performance? One commonly used measure is ",
      },
      {
        text: 'Return on Investment',
        bold: true,
      },
      {
        text: ', or ROI.',
      },
    ],
  },
  {
    body: [
      {
        text: 'ROI takes the ',
      },
      {
        text: 'return ',
        bold: true,
      },
      {
        text: 'of a particular investment and ',
      },
      {
        text: 'divides that by the cost ',
        bold: true,
      },
      {
        text: 'of the investment.',
      },
    ],
  },
  {
    body: [
      {
        text: "If you bought a stock at $10 and it rises to $15, its ROI is 50%. That's ",
      },
      {
        text: 'how much it rose after you bought it, $5, divided by what it cost, $10',
        bold: true,
      },
      {
        text: '. The calculation is ($15 - $10)/$10.',
      },
    ],
  },
  {
    body: [
      {
        text: 'ROI does not account for time. ',
        bold: true,
      },
      {
        text:
          'So a stock with a 50% ROI over one year may be a better investment than a stock with a 50% ROI over 10 years.',
      },
    ],
  },
  {
    body: [
      {
        text: 'In addition to ',
      },
      {
        text: 'measuring stocks',
        bold: true,
      },
      {
        text: ", ROI can be used to compare bonds and/or real estate; it's also widely used by companies to ",
      },
      {
        text: 'measure investments to generate growth.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'There are a number of ',
      },
      {
        text: 'profitability measures related to ROI',
        bold: true,
      },
      {
        text:
          ': these include Return on Assets (ROA), Return on Capital (ROC), Return on Invested Capital (ROIC), Return on Capital Employed (ROCE), and return on equity (ROE).',
      },
    ],
  },
];

export const earningsCallsContent = [
  {
    body: [
      {
        text: 'Earnings calls are a great way to ',
      },
      {
        text: 'stay on top of investments',
        bold: true,
      },
      {
        text: ". They're ",
      },
      {
        text: 'open to everyone',
        bold: true,
      },
      {
        text: ', and they offer key insight into a ',
      },
      {
        text: "company's strategy",
        bold: true,
      },
      {
        text: " and how it's executing against its goals.",
      },
    ],
  },
  {
    body: [
      {
        text: 'Earnings calls are ',
      },
      {
        text: 'held quarterly ',
        bold: true,
      },
      {
        text: 'by public companies. The call will include management ',
      },
      {
        text: 'commentary on recent performance',
        bold: true,
      },
      {
        text: ' as well as their ',
      },
      {
        text: 'outlook for the future.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Calls include industry jargon and measures that are specific to each sector, but ',
      },
      {
        text: 'these can be readily understood with some preparation',
        bold: true,
      },
      {
        text: ' - 10K reports and past calls are useful supplements.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Company ',
      },
      {
        text: 'Investor Relations ',
        bold: true,
      },
      {
        text: 'sites have the earnings calendar as well as links to past calls and company presentations. ',
      },
      {
        text: 'They are also great resources for preparation and ongoing updates.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Most calls include ',
      },
      {
        text: 'Q&A with analysts',
        bold: true,
      },
      {
        text: '. This portion ',
      },
      {
        text: 'highlights key issues ',
        bold: true,
      },
      {
        text: 'and sheds additional light on prospects for near-term and long-term earnings.',
      },
    ],
  },
  {
    body: [
      {
        text: "Stocks can be volatile around earnings, so it's important to ",
      },
      {
        text: 'keep the big picture in mind',
        bold: true,
      },
      {
        text: '. How has ',
      },
      {
        text: 'the outlook ',
        bold: true,
      },
      {
        text: 'for the business changed?',
      },
    ],
  },
  {
    body: [
      {
        text: "Remember, too, that earnings are not reported in a vacuum. One company's call may have important ",
      },
      {
        text: 'implications for a related stock',
        bold: true,
      },
      {
        text: ', and vice versa.',
      },
    ],
  },
];

export const QQQContent = [
  {
    body: [
      {
        text: 'QQQ is a popular exchange-traded fund (ETF) that tracks the ',
      },
      {
        text: 'technology-focused Nasdaq 100 index',
        bold: true,
      },
      {
        text: '.',
      },
    ],
  },
  {
    body: [
      {
        text: 'The Nasdaq 100 includes the 100 ',
      },
      {
        text: 'largest companies listed on the Nasdaq stock market by market capitalization',
        bold: true,
      },
      {
        text: '.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Nearly 50% of QQQ holdings are in the ',
      },
      {
        text: 'technology sector (as of 9/12/2022)',
        bold: true,
      },
      {
        text: '; it also includes heavy weightings in ',
      },
      {
        text: 'consumer stocks ',
        bold: true,
      },
      {
        text: '(17%) and ',
      },
      {
        text: 'communications services',
        bold: true,
      },
      {
        text: ' (17%).',
      },
    ],
  },
  {
    body: [
      {
        text: "The QQQ's top holdings include many widely owned stocks, among them ",
      },
      {
        text: 'Apple, Microsoft, Amazon, and Tesla; ',
        bold: true,
      },
      {
        text: "as a result, it's often viewed as an ETF that's leveraged to innovation.",
      },
    ],
  },
  {
    body: [
      {
        text: 'Like many other ETFs, the QQQ provides an ',
      },
      {
        text: 'effective way to diversify',
        bold: true,
      },
      {
        text: ' across many companies; it also has a ',
      },
      {
        text: 'low relative cost ',
        bold: true,
      },
      {
        text: 'of ownership versus mutual funds.',
      },
    ],
  },
  {
    body: [
      {
        text: 'You can learn more about the ',
      },
      {
        text: 'pros and cons ',
        bold: true,
      },
      {
        text: 'of the QQQ by entering the company ticker in the search field at the top of your dashboard.',
      },
    ],
  },
];

export const buyingStockContent = [
  {
    body: [
      {
        text: 'Buying a stock - even a fractional share - can unlock ',
      },
      {
        text: 'valuable learning opportunities.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'You’ll learn how your stock ',
      },
      {
        text: 'performs compared to the broad markets',
        bold: true,
      },
      {
        text: '. Does it go up or down by a similar amount… or more?',
      },
    ],
  },
  {
    body: [
      {
        text: 'You’ll learn ',
      },
      {
        text: 'how it reacts to the news',
        bold: true,
      },
      {
        text: '. Does it hold up when the economy is under stress? How does news about the sector impact your stock?',
      },
    ],
  },
  {
    body: [
      {
        text: 'You can learn ',
      },
      {
        text: 'what makes the company tick',
        bold: true,
      },
      {
        text: '. What influences sales and profit? How is its strategy evolving over time?',
      },
    ],
  },
  {
    body: [
      {
        text: 'You’ll learn how other investors ',
      },
      {
        text: 'view the stock’s potential',
        bold: true,
      },
      {
        text: '. How does it react when it reports earnings?',
      },
      {
        text: "What are the issues that drive that, and what was already factored into the stock's price?",
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Importantly, ',
      },
      {
        text: 'you’ll also learn about yourself. Learning to manage emotions,',
        bold: true,
      },
      {
        text:
          ' especially when the market is volatile, can be a major part of training yourself to make smart investing decisions.',
      },
    ],
  },
];

export const portfolioOptimizationContent = [
  {
    body: [
      {
        text: 'The Optimizer is unique to Tornado and based on independent, Nobel Prize-winning research.',
        bold: true,
      },
      {
        text: ' Today it’s available on desktop to all accounts with at least a $5 deposit balance. ',
      },
    ],
  },
  {
    body: [
      {
        text: 'The Optimizer continually identifies allocations that seek out the highest projected returns. ',
      },
      {
        text: 'Think of it as an advanced co-pilot that can help you target optimal risk/reward.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'In a single click, you can rebalance from your current portfolio to optimized allocations. ',
        bold: true,
      },
      {
        text: 'It’s that simple.',
      },
    ],
  },
  {
    body: [
      {
        text: 'To access the Optimizer, click the ‘Analysis’ tab on desktop. ',
        bold: true,
      },
      {
        text: 'You’ll see your projected return based on the price targets and other information in your ideas.',
      },
    ],
  },
  {
    body: [
      {
        text: 'It will not make any trades without your approval, so you are not tied to the results. ',
      },
      {
        text:
          'You can simply view the list of optimized trades and, if you prefer, authorize all trades with one click.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          'The Portfolio Optimizer is one of many features designed to help you make more informed investing decisions. Enjoy more intelligent analysis at your fingertips!',
      },
    ],
  },
];

export const yourStockWatchlistContent = [
  {
    body: [
      {
        text: 'One way to keep an eye on the ever-fluctuating stock market is by ',
      },
      {
        text: 'creating your stock watchlist.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Start by looking up companies and industries that you’re keen to invest in, or want to observe, ',
      },
      {
        text: 'via the ‘Search’ magnifying icon at the top of your screen.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          'Once you’re on a stock ticker page in-app, click the three vertical dots to the right of Buy and Sell and select “Add to Watchlist.” ',
        bold: true,
      },
      {
        text: 'At this point you can choose between “I think it’s going up,” “I think it’s going down,” or “Not sure.”',
      },
    ],
  },
  {
    body: [
      {
        text: 'If you\'re on desktop, click the purple "Save ticker to My Ideas" button below the price graph.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Revisit your watchlist',
        bold: true,
      },
      {
        text: 'In-app, click the "Portfolio" icon on the bottom bar ',
        isNewLine: true,
        bold: true,
      },
      {
        text: 'and scroll down to see your watchlist tickers with current price and daily change.',
      },
    ],
  },
  {
    body: [
      {
        text: 'On desktop, click the “My Ideas” icon on the left-hand sidebar.',
        bold: true,
      },
      {
        text:
          ' Here you can see the current price, daily change, your expected return, and the ability to include or exclude from the Portfolio Optimizer.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'Tornado provides personalized news about the stocks you’re most interested in. It’s one of many features designed to help you make more informed investing decisions.',
      },
    ],
  },
];

export const prosConsContent = [
  {
    body: [
      {
        text:
          'Pros and Cons are a great way to give your connections feedback on their stock ideas, and to get their feedback on your ideas. ',
        bold: true,
      },
      {
        text: 'They are short, 250-character comments that other users can agree or disagree with.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'The page for each stock has a section for Pros and Cons from the community. ',
      },
      {
        text: 'You can also read the latest thoughts from your network in your Activity feed.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          'You can write a simple text comment for a Pro or a Con, and you can also add in a specific source/link to discuss. ',
      },
    ],
  },
  {
    body: [
      {
        text:
          'You can let your connections and the Tornado community know about news, opinions, press releases, or any other information that you think might affect stock prices. ',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Paste a link, along with your comment, directly in the text box when you’re adding the Pro or Con.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Who can see my Pro/Con? ',
        bold: true,
      },
      {
        text:
          'Your connections can see that you’ve posted a Pro/Con, and other members of the community will be able to see it, but they won’t see your name.',
      },
    ],
  },
];

export const inAppAudioContent = [
  {
    body: [
      {
        text: 'As the stock market continues to shift, you can ',
      },
      {
        text: 'stay in the know with Tornado audio content! ',
        bold: true,
      },
      {
        text: 'This commentary comes directly from Tornado’s Community Thought Leaders (CTLs).',
      },
    ],
  },
  {
    body: [
      {
        text: 'Our CTLs are Wall Street veterans with ',
      },
      {
        text: 'nearly 50 years of combined experience analyzing and investing in the markets.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'You can also find interviews on a ',
      },
      {
        text: 'wide range of cool topics, ',
        bold: true,
      },
      {
        text: 'from how to build a stock portfolio to what’s happening in China.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'Level up your investing education with Tornado audio. You can find the latest audios next to the Learn & Earn icon in-app. ',
      },
    ],
  },
];

export const roicContent = [
  {
    body: [
      {
        text: 'Profitability ',
        bold: true,
      },
      {
        text: 'is a key driver of long-term stock performance.',
      },
    ],
  },
  {
    body: [
      {
        text: 'How do you tell if a company is ',
      },
      {
        text: 'effective at generating profit',
        bold: true,
      },
      {
        text: '? One measure is Return on Invested Capital, or ',
      },
      {
        text: 'ROIC.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'ROIC takes the ',
      },
      {
        text: 'profits ',
        bold: true,
      },
      {
        text: 'generated by a company and ',
      },
      {
        text: 'divides them by the capital ',
        bold: true,
      },
      {
        text: 'put into the company.',
      },
    ],
  },
  {
    body: [
      {
        text: 'There are important distinctions. Companies ',
      },
      {
        text: 'use different methods of accounting that can make it difficult to get ROIC right.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'You can calculate ROIC on your own, and some companies go into detail on their ',
      },
      {
        text: 'website and investor presentations.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'In addition, there are some firms such as ',
      },
      {
        text: 'New Constructs ',
        bold: true,
      },
      {
        text: 'that ',
      },
      {
        text: 'analyze financial statements and footnotes ',
        bold: true,
      },
      {
        text: 'and make adjustments so investors can use ROIC to compare stocks.',
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];

export const upstartContent = [
  {
    body: [
      {
        text: 'If you have debt, you’re not alone. ',
        bold: true,
      },
      {
        text: 'Credit cards, student loans, and car loans can feel impossible to pay off.',
      },
      {
        text: 'With some preparation and focus, you can make your debt a thing of the past. Here are ',
        isNewLine: true,
      },
      {
        text: 'four strategies to help you tackle your debt',
        bold: true,
      },
      {
        text: ', one dollar at a time.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Review your spending habits.',
        bold: true,
      },
      {
        text:
          'To help avoid overspending in the future, it’s important to understand how your debt accumulated in the first place.',
        isNewLine: true,
      },
      {
        text:
          'Study your credit card statements, identify patterns, and create a budget for an emergency fund. Think strategically.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Pay a little extra.',
        bold: true,
      },
      {
        text:
          'Interest compounds daily on unpaid debts, so when it comes to paying down your total, every little bit helps.',
        isNewLine: true,
      },
      {
        text: 'If you can comfortably do so, pay more than the minimum, make an extra monthly payment, or both!',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Consolidate your debt.',
        bold: true,
      },
      {
        text:
          'Consolidating your debt can give you the chance to combine high-interest balances together into a single loan at a lower rate.',
        isNewLine: true,
      },
      {
        text:
          'This can help you decrease your debt faster, and can help you do it without increasing your payment amount.',
        isNewLine: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Focus on one debt at a time.',
        bold: true,
      },
      {
        text: 'With the debt avalanche method',
        isNewLine: true,
        bold: true,
      },
      {
        text: ', you focus on settling the debts with the highest interest rates first.',
      },
      {
        text: 'Alternatively, the ',
        isNewLine: true,
      },
      {
        text: 'snowball method',
        bold: true,
      },
      {
        text:
          ' tackles the lowest balance first. When you pay off the first “easy” debt in full, then you target the next smallest.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Upstart',
        bold: true,
      },
      {
        text:
          ' is an online lending marketplace that partners with banks to provide personal loans from $1,000-$50,000.',
      },
      {
        text: 'Upstart’s unique model goes beyond traditional metrics to consider your education and experience. ',
        isNewLine: true,
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];

export const galatMakingAPlanContent = [
  {
    body: [
      {
        text: 'Investing is part of ',
      },
      {
        text: 'a broad set of decisions about how you manage your money',
        bold: true,
      },
      {
        text: ', from near-term budgeting to saving or funding school and ultimately, retirement.',
      },
    ],
  },
  {
    body: [
      {
        text: 'While stocks may play a central role in any or all of those plans, ',
      },
      {
        text: ' the first step is simply getting a handle on your money.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Define what you want to accomplish.',
        bold: true,
      },
      {
        text: ' Purchasing a home? Going on a dream vacation? Getting out of debt?',
      },
    ],
  },
  {
    body: [
      {
        text: 'Take stock of where you are today. ',
      },
      {
        text: 'How much money comes in - and how much money goes out?',
        bold: true,
      },
      {
        text: ' Establish a mile marker.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'Financial planning is the process of ',
      },
      {
        text: 'mapping from the present to where you need to be to achieve your goals.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Ultimately, the process will help ',
      },
      {
        text: 'ensure that you support necessities while funding near and longer-term goals.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          'Need help with a plan to connect the dots? George Galat is a financial planner and the owner of Wealth Foundry. He specializes in employment compensation and real estate.',
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];

export const galatSelectingStocksContent = [
  {
    body: [
      {
        text: 'How do you add stocks as part of a comprehensive financial plan?',
        bold: true,
      },
      {
        text: ' Here are some tips from an experienced advisor.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Find a ',
      },
      {
        text: "handful of companies you're interested in ",
        bold: true,
      },
      {
        text: 'and see ',
      },
      {
        text: 'what drives their performance over time.',
        bold: true,
      },
      {
        text: " It's a great way to learn about their businesses.",
      },
    ],
  },
  {
    body: [
      {
        text: 'Consider keeping ',
      },
      {
        text: 'concentration ',
        bold: true,
      },
      {
        text: 'on any one company to ',
      },
      {
        text: 'no more than 5% ',
        bold: true,
      },
      {
        text: 'of a portfolio, and ',
      },
      {
        text: 'take a long-term view ',
        bold: true,
      },
      {
        text: 'of the market.',
      },
    ],
  },
  {
    body: [
      {
        text: "Remember that when you invest can make as much of an impact as what you're investing in. ",
        bold: true,
      },
      {
        text: 'Returns during one period may not match returns in another period. ',
      },
    ],
  },
  {
    body: [
      {
        text: 'Consider the investment axiom: ',
      },
      {
        text: 'time in the market, not timing the market. ',
        bold: true,
      },
      {
        text: "Stocks are inherently volatile but they've generated attractive returns over time. ",
      },
    ],
  },
  {
    body: [
      {
        text:
          'George Galat is a financial planner and the owner of Wealth Foundry. He specializes in equity compensation and real estate.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'George offers personal financial planning exclusive to Tornado users.',
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];

export const womenLedContent = [
  {
    body: [
      {
        text: 'There are several women-led companies you may be interested in adding to your portfolio. ',
      },
      {
        text: 'Let’s take a look at a few of the women steering companies that are doing big things.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'CVS (CVS) Karen Lynch - President & CEO',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Lynch has three decades of experience in the healthcare industry and has been named among Fortune’s Most Powerful Women in Business. ',
      },
      {
        text:
          'She’s helping CVS work toward its mission to make healthcare simpler, more convenient, and more personalized.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'United Parcel Service, Inc. (UPS) Carol Tome - CEO',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Tome has been CEO and director of UPS since June, 2020. Prior to that, she served as Chief Financial Officer of Home Depot, Inc. from 2001 to 2019, and as Executive Vice President of Corporate Services from 2007 to 2019.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'At UPS, Tome is driving a customer-first, people-led, innovation-driven strategy to transform nearly every aspect of its business over the next several years.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Walgreens Boots Alliance (WBA) Rosalind Brewer - CEO',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Brewer was appointed CEO in March 2021, and ',
      },
      {
        text:
          'USA Today referred to her as "one of corporate America\'s most prominent women and black female executives." ',
        bold: true,
      },
      {
        text: 'She previously served as group president and COO of Starbucks, and CEO of Sam’s Club.',
      },
    ],
  },
  {
    body: [
      {
        text: 'The Hershey Co. (HSY)  Michele Buck - CEO ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'In March 2017, Buck became the first female Chairman, President, and CEO of The Hershey Company. Upon becoming CEO, Buck “saw an opportunity for us to dial up our agility and our risk-taking so that we could better seize opportunities.” ',
      },
    ],
  },
  {
    body: [
      {
        text:
          'Buck is focused on growing HSY’s portfolio of brands and investing in capabilities and its workforce to differentially capture growth opportunities in new and existing categories. ',
      },
    ],
  },
  {
    body: [
      {
        text: 'Citibank (C) Jane Fraser - CEO',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Fraser is the first female CEO in the firm’s history. In a volatile market, ',
      },
      {
        text: 'she steered Citi to ',
        bold: true,
      },
      {
        text: 'first-quarter earnings (2022) of over $4 billion.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          'Fraser continues to lead Citi’s strategic business transformation, with a focus on digital upgrades and simplification.',
      },
    ],
  },
];

export const tornadoValuesContent = [
  {
    body: [
      {
        text: 'Thank you for being part of the Tornado community. ',
      },
      {
        text: 'We’re here to support you with tools, education, and community',
        bold: true,
      },
      {
        text: ' to help you make more informed investing decisions.',
      },
      {
        lineBreak: true,
      },
      {
        text:
          'We have a few core values that underscore our commitment to helping you become a more confident investor. ',
      },
    ],
  },
  {
    body: [
      {
        text: 'Money is deeply personal. ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Everyone’s investing story is unique. ',
      },
      {
        text: 'No one starts from the same place. ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'We use the power of community and approachable content to meet every investor where they are now, and get them where they want to go. ',
      },
    ],
  },
  {
    body: [
      {
        text: 'Learning to make smart, long-term investments is life-changing. ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'With a focus on personalized investing education, Tornado helps investors of any background build confidence in their skills – and create the foundation for their financial future.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Everyone can achieve financial wellness.',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'You don’t have to be a genius to become an intelligent investor. ',
      },
      {
        text:
          'With equitable resources and  a supportive community, everyone is capable of making smarter investing decisions. ',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'The investing revolution demands transparency.',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Tornado’s ',
      },
      {
        text:
          'business model supports investors by raising your financial acumen and sharpening your understanding of the concepts that drive long-term performance.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'No matter where you’re starting from, Tornado is committed to helping you achieve your financial goals.',
      },
      {
        text: ' Invest in yourself and we will, too. ',
        bold: true,
      },
    ],
  },
];

export const exclusiveTornadoPerksContent = [
  {
    body: [
      {
        text: 'Every Tornado member has access to powerful ',
      },
      {
        text: 'tools, personalized financial education, and an engaging community ',
        bold: true,
      },
      {
        text: 'to help you make more informed investing decisions.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'Let’s take a look at a few of your perks. ',
      },
    ],
  },
  {
    body: [
      {
        text: 'Personalized Financial Education',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'You can earn cash to invest when you sharpen your financial skills through our Learn & Earn program. Apply your knowledge as you build your portfolio and receive new lessons daily!',
      },
    ],
  },
  {
    body: [
      {
        text: 'A direct line to former Wall Street Analysts ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Have a question about a particular stock? Touch base with one of our Community Thought Leaders (people who have worked for some of the biggest names on Wall Street) and tap into their perspective.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Portfolio Optimization ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Available on desktop to all accounts with at least a $5 deposit, Tornado’s Portfolio Optimizer is based on independent, Nobel Prize-winning research. In a single click, ',
      },
      {
        text: 'it identifies allocations that seek out higher projected returns. ',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Pros and Cons from the Tornado Community',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Use Tornado’s Pro/Con feature to get a temperature check on the latest stock news, or check out the “Collective Intelligence” tab to see what other Tornado investors are investing in. ',
      },
    ],
  },
  {
    body: [
      {
        text: 'Wherever you are on your investing journey, we’re here to support you every step of the way.',
      },
    ],
  },
];

export const habitsOfSuccessFulInvestorsContent = [
  {
    body: [
      {
        text:
          'Successful investors will tell you there’s always more to learn. Here are three things you can do to stay sharp.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Brush up on your knowledge. ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Tornado will give you cash to invest when you complete your daily Learn & Earn lessons. The content is tailored to your skill level — how cool is that?',
      },
    ],
  },
  {
    body: [
      {
        text: 'Gain high-quality wisdom.',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'On Tornado you can ask Community Thought Leaders (people who have worked with some of the biggest names on Wall Street) about any stock and tap into their perspective.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Engage with the right community. ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Use Tornado’s Pro/Con feature to get a temperature check on the latest stock news, or check out the “Collective Intelligence” tab to see what other Tornado investors are investing in. ',
      },
    ],
  },
  {
    body: [
      {
        text: 'Start building a portfolio you believe in. Tornado is here to support you along the way.',
      },
    ],
  },
];

export const howWeSupportYourInvestingJourneyContent = [
  {
    body: [
      {
        text: 'Excessive trading. Groupthink. Hoping to strike gold. ',
      },
      {
        lineBreak: true,
      },
      {
        text: 'While a small few may get lucky, informed ',
      },
      {
        text: 'long-term investing remains a tried and true method of building wealth.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          'Tornado’s sustainable business model allows us to deliver an experience that serves you for the long haul.',
      },
      {
        text: ' Here’s our promise to you:',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'We don’t encourage excessive trading. ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Instead, we’ll give you free cash to invest when you continue your financial education through our ',
      },
      {
        text: 'Learn & Earn ',
        bold: true,
      },
      {
        text: 'program. ',
      },
    ],
  },
  {
    body: [
      {
        text: 'You’ll have access to a community of engaged learners *and* Wall Street veterans. ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'When you ask our professionals about a stock, ',
      },
      {
        text: 'you’ll gain wisdom informed by experience.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Your learning content is personalized to your skill level.',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Daily financial lessons, audio interviews, and in-feed content meet you where you’re at, no matter where you’re starting from.',
      },
    ],
  },
];
export const meetCTLsContent = [
  {
    body: [
      {
        text: 'CTL is an abbreviation for ',
      },
      {
        text: '"Community Thought Leader." ',
        bold: true,
      },
      {
        text: 'CTLs have ',
      },
      {
        text: 'professional investment experience, ',
        bold: true,
      },
      {
        text: "which means they've worked at ",
      },
      {
        text: 'investment banks, mutual funds and/or hedge funds. ',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: "Tornado's CTLs bring ",
      },
      {
        text: 'over five decades of collective investing experience ',
        bold: true,
      },
      {
        text: "to the platform. You'll find their work in ",
      },
      {
        text: 'pro and con posts ',
        bold: true,
      },
      {
        text: 'on specific stocks and in ',
      },
      {
        text: 'blogs, audios, and the Learn & Earn ',
        bold: true,
      },
      {
        text: 'feature.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Meet Won',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Won has over ten years of experience working for an ',
      },
      {
        text: 'asset manager ',
        bold: true,
      },
      {
        text: 'that had $2 billion in AUM. While there, he covered the ',
      },
      {
        text: 'industrial and financial services sectors ',
        bold: true,
      },
      {
        text: 'as well as ',
      },
      {
        text: 'macro-related topics.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Meet Jeff',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Jeff is a former ',
      },
      {
        text: 'stock research analyst ',
        bold: true,
      },
      {
        text: 'for Citigroup and Barclays who focused on the ',
      },
      {
        text: 'retail and consumer sectors. ',
        bold: true,
      },
      {
        text: "He's also served as a financial and ",
      },
      {
        text: 'investor relations consultant ',
        bold: true,
      },
      {
        text: 'for US-listed companies.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Meet Tony',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Tony has over twenty years of Wall Street investing experience. He has worked for investment banks in both ',
      },
      {
        text: 'equity research and trading ',
        bold: true,
      },
      {
        text: 'as well as several long/short hedge funds as both an ',
      },
      {
        text: 'analyst and portfolio manager.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Posts on stocks are labeled "Community Thought Leader" and show up in the feed. You can ',
      },
      {
        text: 'find all CTL posts on a given stock ',
        bold: true,
      },
      {
        text: 'by selecting a ticker and filtering Pro/Con results by "Show from Community Thought Leader." ',
      },
    ],
  },
  {
    body: [
      {
        text: 'You can also get their ',
      },
      {
        text: 'most updated thoughts on stocks ',
        bold: true,
      },
      {
        text: 'via our ',
      },
      {
        text: '"Ask a CTL" feature ',
        bold: true,
      },
      {
        text:
          'which is available in two forms: in-app or desktop navigation or via the "Ask Someone" button (below the performance graph on a stock ticker page).',
      },
    ],
  },
];
export const amazonContent = [
  {
    body: [
      {
        text: 'Great investors do their homework, and that includes ',
      },
      {
        text: 'knowing what makes a company tick. ',
        bold: true,
      },
      {
        text: "Let's take a closer look at ",
      },
      {
        text: 'Amazon (AMZN)',
        bold: true,
      },
      {
        text: '.',
      },
    ],
  },
  {
    body: [
      {
        text: 'In 2021, AMZN generated $469.8 billion in revenue. ',
      },
      {
        text: '87% was from e-commerce',
        bold: true,
      },
      {
        text: ': product sales, third-party seller fees, and Amazon Prime membership fees.',
      },
    ],
  },
  {
    body: [
      {
        text: 'The other 13% of revenue was from ',
      },
      {
        text: 'Amazon Web Services ',
        bold: true,
      },
      {
        text:
          "or AWS; That's Amazon's cloud platform (businesses essentially rent computing power, databases, and analytical tools from Amazon).",
      },
    ],
  },
  {
    body: [
      {
        text:
          "While its revenues are smaller, AWS is growing significantly faster (38% last year) than the e-commerce business, and it's more profitable.",
      },
      {
        lineBreak: true,
      },
      {
        text: 'In 2021, ',
      },
      {
        text: 'AWS accounted for approximately 75% ',
        bold: true,
      },
      {
        text: "of the company's overall ",
      },
      {
        text: 'operating income, despite being only 13% of revenue.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: "That's why it's important to ",
      },
      {
        text: 'monitor both AWS and e-commerce ',
        bold: true,
      },
      {
        text: "and how each may influence AMZN's earnings. You can learn more about AMZN on ",
      },
      {
        text: "Tornado's Pros and Cons, ",
        bold: true,
      },
      {
        text: 'as well as its investor presentations and SEC filings.',
      },
    ],
  },
];

export const lossesContent = [
  {
    body: [
      {
        text: 'What can you do when a stock you own is ',
      },
      {
        text: 'down 10%, 20%, or even 50%?',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          "When a stock index like the S&P 500 is down 10% that's called a correction. Historically this has happened ",
      },
      {
        text: 'about once every two years. ',
        bold: true,
      },
      {
        text:
          'Since the S&P is a broadly diversified measure that includes a subset of US stocks, corrections may occur more frequently for individual stocks.',
      },
    ],
  },
  {
    body: [
      {
        text: 'The first thing to do is homework: ',
      },
      {
        text: "is the company's strategy still sound, ",
        bold: true,
      },
      {
        text: 'or are there ',
      },
      {
        text: 'new threats? ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Has its outlook changed? Are the ',
      },
      {
        text: 'issues fixable',
        bold: true,
      },
      {
        text: ', or do you believe are they likely to ',
      },
      {
        text: 'persist',
        bold: true,
      },
      {
        text: '?',
      },
    ],
  },
  {
    body: [
      {
        text: "If you still like the stock, it's okay to ",
      },
      {
        text: 'do nothing. ',
        bold: true,
      },
      {
        text:
          'You can also buy more shares, which can lower your cost per share. If the business is still sound, the stock may come back.',
      },
    ],
  },
  {
    body: [
      {
        text: 'If you no longer like the stock, you can sell.',
      },
      {
        text: ' Losses are a normal part of investing. ',
        bold: true,
      },
      {
        text: 'The goal is to make sure your portfolio always reflects what you think will happen going forward.',
      },
    ],
  },
  {
    body: [
      {
        text: 'You may also want to consider alternatives. Is there ',
      },
      {
        text: 'another stock with a more favorable outlook ',
        bold: true,
      },
      {
        text: '(or an ETF that provides more diversification) that would be a ',
      },
      {
        text: 'better use of your capital?',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          'Check out Tornado audio interviews, and in-feed content for more on how to think about losses, diversification, and ETFs. ',
      },
    ],
  },
];
export const whatYouCanLearnFromBuyingAStockContent = [
  {
    body: [
      {
        text: 'Let’s start with a basic question: ',
      },
      {
        text: 'what can I learn from buying a stock? ',
        bold: true,
      },
      {
        text: 'For some, it’s a simple matter of profit or loss.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'But, if you look deeper, there’s a whole world that can open up',
        bold: true,
      },
      {
        text: ' – one that holds valuable lessons – lessons that build upon themselves. ',
      },
    ],
  },
  {
    body: [
      {
        text: 'Consider this: ',
      },
      {
        lineBreak: true,
      },
      {
        text: 'You’ll learn how your stock relates to the broad markets. ',
        bold: true,
      },
      {
        text: 'Does it go up or down a similar amount? More? ',
      },
      {
        lineBreak: true,
      },
      {
        text: 'You’ll learn how it reacts to the news. ',
        bold: true,
      },
      {
        text: 'Does it hold up when there’s an oil shock? How about an interest rate hike? Or a broad market selloff?',
      },
    ],
  },
  {
    body: [
      {
        text: 'You’ll learn what makes the company tick. ',
        bold: true,
      },
      {
        text: 'What influences its sales and profit? What’s its strategy…and how is that changing over time?',
      },
      {
        lineBreak: true,
      },
      {
        text: 'You’ll learn about management. ',
        bold: true,
      },
      {
        text: 'Do they set targets they can hit? Are they doing what they say they will?  ',
      },
    ],
  },
  {
    body: [
      {
        text: 'You’ll learn how other investors view the stock’s potential. ',
        bold: true,
      },
      {
        text: 'How does it react to earnings? What are the issues that drive that?',
      },
      {
        lineBreak: true,
      },
      {
        text: 'You’ll learn about the competitive landscape. ',
        bold: true,
      },
      {
        text: 'How does company X influence company Y? ',
      },
    ],
  },
  {
    body: [
      {
        text: 'Finally, you’ll learn about yourself. ',
        bold: true,
      },
      {
        text: 'How comfortable are you taking risk? How does a rise or fall in the price affect you emotionally? ',
      },
    ],
  },
  {
    body: [
      {
        text: 'These aren’t abstract concepts. They’re cues and intel that investors use every day. ',
      },
      {
        lineBreak: true,
      },
      {
        text: 'And one stock can acquaint you with all of it.',
        bold: true,
      },
    ],
  },
];
export const moatsContent = [
  {
    body: [
      {
        text: "Long-term investors often look for 'moats' that can ",
      },
      {
        text: 'support sales and profit ',
        bold: true,
      },
      {
        text: 'for long periods of time.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Moats are ',
      },
      {
        text: 'comparative advantages ',
        bold: true,
      },
      {
        text: 'that in some way enable a company to withstand competition from its peers.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Warren Buffett has argued that, in his opinion, ',
      },
      {
        text: 'a good business must have a moat ',
        bold: true,
      },
      {
        text: 'that can "protect a terrific economic castle." ',
      },
    ],
  },
  {
    body: [
      {
        text: 'Moats can take a variety of forms, ranging from ',
      },
      {
        text: 'brand recognition ',
        bold: true,
      },
      {
        text: '(as with long-time Buffett investments AXP and KO) to ',
      },
      {
        text: 'low-cost leadership ',
        bold: true,
      },
      {
        text: "(as in Buffett's own business, Geico).",
      },
    ],
  },
  {
    body: [
      {
        text: 'Strong comparative advantages are ',
      },
      {
        text: '"wide" ',
        bold: true,
      },
      {
        text: '– not easily overcome – and ',
      },
      {
        text: '"long-lasting"',
        bold: true,
      },
      {
        text: ' – durable for many years.',
      },
    ],
  },
  {
    body: [
      {
        text: 'You ',
      },
      {
        text: 'can monitor a company’s fundamentals (profit margins and sales growth) ',
        bold: true,
      },
      {
        text: 'in Tornado to determine its comparative advantage.',
      },
    ],
  },
];

export const appleContent = [
  {
    body: [
      {
        text: 'Apple is famous for selling iPhones, Macs and iPads, so ',
      },
      {
        text: 'it would be easy to believe that Apple is essentially a hardware company',
        bold: true,
      },
      {
        text: ' - a company that sells physical goods. ',
      },
    ],
  },
  {
    body: [
      {
        text: 'However, ',
      },
      {
        text: 'Apple also has a "Services" business. ',
        bold: true,
      },
      {
        text:
          "It's largely comprised of the App store, but also includes advertising, AppleCare, cloud, digital content, payment, and other services.",
      },
    ],
  },
  {
    body: [
      {
        text:
          'The "Services" business comprises ~20% of revenue. That may not sound like a lot, but it is growing at over 20% each year. Meanwhile, its hardware business is growing at closer to 10% each year. ',
      },
    ],
  },
  {
    body: [
      {
        text:
          'That’s appealing to investors because services are more profitable than hardware. As Apple continues to shift its revenue more toward services, it is possible that that part of the business brings up the overall multiple that is paid for Apple stock. ',
      },
      {
        lineBreak: true,
      },
      {
        text: 'At the same time, Apple needs to fend off competitors such as Android phones.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'Take a look at Pros and Cons regarding Apple stock by looking up AAPL in the search field. Want to know why Warren Buffett likes Apple? Check out our blog post in the "More Info" section below.',
      },
    ],
  },
];

export const themes2022Content = [
  {
    body: [
      {
        text: 'As always, it’s important to monitor themes that emerge through ',
      },
      {
        text: 'corporate earnings cycles.',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Some of these may have ',
      },
      {
        text: 'long-term ramifications for earnings',
        bold: true,
      },
      {
        text: ', while others can reveal ',
      },
      {
        text: 'clues about future economic growth',
        bold: true,
      },
      {
        text: '. Let’s look at a few from 2022.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Inflation',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Many corporations ',
      },
      {
        text: 'expect inflation to last well into 2023',
        bold: true,
      },
      {
        text:
          '. In third-quarter 2022, the CEO of retailer Tractor Supply (TSCO) said he could not envision a scenario in which the company could see deflation in 2023. ',
      },
      {
        lineBreak: true,
      },
      {
        text: 'The inflation issue is impacting a wide swath of industries.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Pricing',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Some companies are generating ',
      },
      {
        text: 'sales growth that is largely dependent on higher pricing',
        bold: true,
      },
      {
        text: '. Volumes have been lower for much of the ',
      },
      {
        text: 'industrial sector',
        bold: true,
      },
      {
        text: ' and traffic and transactions have remained low for much of the ',
      },
      {
        text: 'consumer and retail sector. ',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'A drop in pricing power could be hard for volume/traffic to offset.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Europe',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Amazon, Honeywell, and Caterpillar have discussed ',
      },
      {
        text: 'slowing growth in Europe',
        bold: true,
      },
      {
        text:
          ' in the second half of 2022; Coca-Cola said it’s seen consumers adopt “recessionary behavior” in Europe, with growth in private labels and changes to mix preferences and channels.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Big Tech',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'The third quarter in particular has flipped the script on growth generals Meta, Amazon, Microsoft, and Google, all of which said',
      },
      {
        text: ' revenue trends had slowed',
        bold: true,
      },
      {
        text:
          '. That’s a stark difference with second-quarter 2022, and it’s fueling concern about a broader slowdown in tech.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Housing',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Third-quarter data points have revealed a ',
      },
      {
        text: 'softer market as mortgage rates rise.',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'New home sales tumbled 11% month over month in September and pending home sales declined 10%. Homebuilder Pulte Homes (PHM) noted a slowdown in demand in October.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'You can find more on these and other trends in the Pros and Cons in the Tornado Feed and in our weekly audios.',
      },
    ],
  },
];

export const pceIndexContent = [
  {
    body: [
      {
        text: 'Keeping inflation under control is one of the key mandates of the ',
      },
      {
        text: 'US Federal Reserve',
        bold: true,
      },
      {
        text: ', and inflation is closely followed by investors.',
      },
    ],
  },
  {
    body: [
      {
        text: 'After the CPI (Consumer Price Index), a ',
      },
      {
        text: 'second commonly cited measure of inflation is the PCE index',
        bold: true,
      },
      {
        text: ' (also known as the PCE deflator). It’s also the Fed’s preferred measure of inflation.',
      },
    ],
  },
  {
    body: [
      {
        text: 'PCE stands for personal consumption expenditures and ',
      },
      {
        text: 'encompasses the things (aka, goods and services) you buy',
        bold: true,
      },
      {
        text: ': cars, furniture, electronics, and clothing, among others. ',
      },
      {
        lineBreak: true,
      },
      {
        text: 'It’s significant because consumption comprises the largest portion of GDP. ',
      },
    ],
  },
  {
    body: [
      {
        text:
          'How is it different from CPI (Consumer Price Index)? One key difference is the data sources, as the two use different surveys. ',
      },
      {
        lineBreak: true,
      },
      {
        text: 'The',
      },
      {
        text: ' CPI is based on a survey of what households are purchasing',
        bold: true,
      },
      {
        text: ', while the ',
      },
      {
        text: 'PCE is based on what businesses are selling.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          'Another is that the two measures include different items in their respective baskets. The CPI only includes out-of-pocket expenditures on goods and services, while ',
      },
      {
        text: 'PCE includes expenditures that are not paid for directly',
        bold: true,
      },
      {
        text: '.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'For example, medical care paid by Medicare or Medicaid.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'Services comprise the largest portion of PCE and grew faster than its other main components from 1995–2020. ',
      },
      {
        lineBreak: true,
      },
      {
        text: 'Factors that could influence inflation in services, which include',
      },
      {
        text: ' labor availability and wages',
        bold: true,
      },
      {
        text: ', are important to monitor over time.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'For a more in-depth discussion of the PCE, check out our blog post (link in the "MORE INFO" section below).',
      },
      {
        lineBreak: true,
      },
      {
        text:
          'You can also view Pros and Cons relating to inflation and the overall economy by looking up tickers like SPY on Tornado. ',
      },
    ],
  },
];
export const googleContent = [
  {
    body: [
      {
        text: 'Alphabet Inc. (GOOG), the parent company of Google, is a US technology company that ',
      },
      {
        text: 'operates a premiere search engine',
        bold: true,
      },
      {
        text: ' as well as a range of businesses from cloud computing to YouTube to autonomous driving.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Alphabet generates the bulk of its revenue from Google Search, which gets money from ad sales. ',
      },
      {
        lineBreak: true,
      },
      {
        text: 'However, Search is 60% of overall revenue, which means ',
      },
      {
        text: 'Alphabet generates 40% of revenue from other sources',
        bold: true,
      },
      {
        text: '.',
      },
    ],
  },
  {
    body: [
      {
        text: 'YouTube ',
        bold: true,
      },
      {
        text: 'ads account for nearly 10% of revenue, while ',
      },
      {
        text: 'Google Network ',
        bold: true,
      },
      {
        text:
          'accounts for nearly 12% of revenue. Google Network includes revenue from AdMob, AdSense, and Google Ads Manager.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'Perhaps less well-known is that Alphabet is generating a significant amount of revenue from its cloud business. ',
      },
      {
        text: 'Google Cloud accounts for 8.5% of revenue ',
        bold: true,
      },
      {
        text: 'and is growing rapidly - at nearly 2X the growth rate of the overall company. ',
      },
    ],
  },
  {
    body: [
      {
        text:
          "Cloud is not profitable as of 2022, but over time, Google Cloud could comprise an increasingly larger part of Alphabet's overall revenue mix and ultimately, its profit mix. ",
      },
      {
        lineBreak: true,
      },
      {
        text: 'Meanwhile, other businesses that currently do not generate profit, such as ',
      },
      {
        text: 'autonomous driving',
        bold: true,
      },
      {
        text: ', could ultimately be important.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Given its prominence in search, ',
      },
      {
        text: 'regulatory issues are important to monitor',
        bold: true,
      },
      {
        text:
          ', and Alphabet has faced a number of challenges from US and European regulators related to its search practices.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'Its involvement across many other businesses also makes ',
      },
      {
        text: 'monitoring the competitive landscape ',
        bold: true,
      },
      {
        text: 'important.  ',
      },
    ],
  },
  {
    body: [
      {
        text: 'Check out community Pros and Cons of Alphabet in Tornado. Just enter the ticker ',
      },
      {
        text: 'GOOG',
        bold: true,
      },
      {
        text: ' in the text field next to the magnifying glass. ',
      },
    ],
  },
];
export const cpiIndexContent = [
  {
    body: [
      {
        text: 'Inflation is an ',
      },
      {
        text: 'increase in the price of goods and services',
        bold: true,
      },
      {
        text: " that can erode company cash flows and consumers' standard of living over time. ",
      },
      {
        lineBreak: true,
      },
      {
        text: 'One of the ',
      },
      {
        text: 'mandates of the US Federal Reserve',
        bold: true,
      },
      {
        text: ' is to keep inflation under control.',
      },
    ],
  },
  {
    body: [
      {
        text: 'The CPI, aka the Consumer Price Index, is ',
      },
      {
        text: 'one of two key measures ',
        bold: true,
      },
      {
        text:
          "of inflation. The other is the Fed's preferred measure, which is called the PCE (personal consumption expenditures) index.",
      },
    ],
  },
  {
    body: [
      {
        text:
          'The CPI is a price index, which means it’s a weighted average of prices for a market basket of goods and services bought by households. ',
      },
      {
        lineBreak: true,
      },
      {
        text: 'This basket of goods includes food, energy, shelter, medical care services, and transportation.',
      },
    ],
  },
  {
    body: [
      {
        text: 'The CPI is compiled by the ',
      },
      {
        text: 'Bureau of Labor Statistics',
        bold: true,
      },
      {
        text: ', a unit of the US Department of Labor, and ',
      },
      {
        text: 'released on a monthly basis',
        bold: true,
      },
      {
        text: ', usually during the second week of each month. It’s widely followed by investors.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Housing, food, and energy ',
        bold: true,
      },
      {
        text: ' make up the largest portions of the CPI. You can find a full breakdown in the attached Tornado blog. ',
      },
    ],
  },
  {
    body: [
      {
        text: 'Given that ',
      },
      {
        text: 'housing has the largest weight at over 30%',
        bold: true,
      },
      {
        text: ', it has the most significant impact on the CPI.',
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Housing is also prone to longer cycles than some of the CPI’s other constituents, so factors influencing its prices are important to understand.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'Professional analysts caution against making projections for individual categories, as numerous variables impact the direction and magnitude of price changes. ',
      },
      {
        lineBreak: true,
      },
      {
        text: 'That said, ',
      },
      {
        text: 'breaking up the index by category ',
        bold: true,
      },
      {
        text: 'can give one a sense of what is driving inflation over time.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'Find a fuller discussion on our blog post (link in the "MORE INFO" section).',
      },
      {
        lineBreak: true,
      },
      {
        text:
          'And check out Pros and Cons that impact the economy in tickers like SPY, or impacting housing in tickers like XHB.',
      },
    ],
  },
];
export const inflationContent = [
  {
    body: [
      {
        text: 'Inflation is an ',
      },
      {
        text: 'increase in the prices of goods and services',
        bold: true,
      },
      {
        text: ' in an economy.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'Inflation ',
      },
      {
        text: 'erodes the value of cash over time',
        bold: true,
      },
      {
        text: '.',
      },
    ],
  },
  {
    body: [
      {
        text: 'In that sense, the level of inflation plays an important role in the ',
      },
      {
        text: 'value investors are willing to assign ',
        bold: true,
      },
      {
        text: 'to cash that companies are expected to ',
      },
      {
        text: 'generate in the future',
        bold: true,
      },
      {
        text: '.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'Generally speaking, the lower the inflation the higher the value of future cash flows. ',
      },
    ],
  },
  {
    body: [
      {
        text: 'Inflation can also ',
      },
      {
        text: 'erode consumers’ standard of living',
        bold: true,
      },
      {
        text: ', especially to the extent that wages don’t keep pace with the rate of growth for inflation.',
      },
    ],
  },
  {
    body: [
      {
        text: 'The ',
      },
      {
        text: 'Federal Reserve targets 2% inflation ',
        bold: true,
      },
      {
        text: 'as part of its overall mandate.',
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Suppose inflation is above that target for extended periods of time. In that case, the Fed may use various tools to restrict the level of liquidity in financial markets, ',
      },
      {
        text: 'which can raise interest rates and make it harder for companies and consumers to borrow money.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Investors pay close attention to measures including the ',
      },
      {
        text: 'CPI (Consumer Price Index)',
        bold: true,
      },
      {
        text: ', as well as the ',
      },
      {
        text: 'PCE (Personal Consumption Expenditures)',
        bold: true,
      },
      {
        text: ", which is the Fed's preferred measure.",
      },
    ],
  },
  {
    body: [
      {
        text:
          'During periods of inflation, famous investor Warren Buffett has focused on identifying companies that have ',
      },
      {
        text: 'pricing power ',
        bold: true,
      },
      {
        text: 'and more ',
      },
      {
        text: 'flexible cost structures ',
        bold: true,
      },
      {
        text: 'that enable them to weather inflation better than others.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Check out various factors impacting inflation by looking up pros and cons in tickers like SPY.',
      },
    ],
  },
];
export const competitionContent = [
  {
    body: [
      {
        text: "As you gain experience with investing, it's important to ",
      },
      {
        text: 'monitor each stock’s ecosystem;',
        bold: true,
      },
      {
        text: ' one of the most important parts of that includes ',
      },
      {
        text: 'its competitors',
        bold: true,
      },
      {
        text: '.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Competitors can provide useful insights about ',
      },
      {
        text: 'overall industry trends ',
        bold: true,
      },
      {
        text: 'and ',
      },
      {
        text: 'market share shifts ',
        bold: true,
      },
      {
        text: 'for particular sectors. In addition, they may have ',
      },
      {
        text: 'implications for revenue growth and profit ',
        bold: true,
      },
      {
        text: 'for the companies that operate in each.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Developments at one competitor may also impact another: most ',
      },
      {
        text: 'investors are highly attuned to these ripple effects ',
        bold: true,
      },
      {
        text: 'across sectors. Some of them are short-term while others may signal longer-term changes.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Some competitors are clear-cut',
        bold: true,
      },
      {
        text: ', such as Home Depot (HD) and Lowe’s (LOW). ',
      },
      {
        text: 'Others are less clear.',
        bold: true,
      },
      {
        text:
          ' For example, what Deere (DE) might say about tractor sales in various regions might impact German drug company Bayer AG, which owns seed-focused Monsanto.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Among other things to consider are whether an industry is ',
      },
      {
        text: 'fragmented (many competitors) or consolidated (few competitors) ',
        bold: true,
      },
      {
        text: 'as well as regulatory issues. These may bear on pricing power and barriers to entry.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Competitors can be found by checking out the ',
      },
      {
        text: '"Compare" tab ',
        bold: true,
      },
      {
        text: 'in the desktop version of Tornado, as well as in ',
      },
      {
        text: '10-Ks',
        bold: true,
      },
      {
        text: '. You can also find useful information on company peers in the ',
      },
      {
        text: 'Proxy Filings ',
        bold: true,
      },
      {
        text: '(14A) with the Securities and Exchange Commission.',
      },
    ],
  },
  {
    body: [
      {
        text: 'We also have a resource on competitive dynamics (link in the "MORE INFO" section). ',
      },
    ],
  },
];
export const capitalIntensityContent = [
  {
    body: [
      {
        text: 'Capital intensity refers to the level of investment in ',
      },
      {
        text: 'property, plants, and equipment ',
        bold: true,
      },
      {
        text: 'a company needs to produce its products. Generally speaking, ',
      },
      {
        text: 'high capital intensity ',
        bold: true,
      },
      {
        text: 'indicates a company may have to make ',
      },
      {
        text: 'big investments relative to earnings ',
        bold: true,
      },
      {
        text: 'to run its business.',
      },
    ],
  },
  {
    body: [
      {
        text: "Warren Buffett's partner Charlie Munger has highlighted ",
      },
      {
        text: 'airline manufacturing ',
        bold: true,
      },
      {
        text: 'as a capital intensive business. Boeing (BA) is one example. Other industries could include ',
      },
      {
        text: 'auto, oil and gas, mining, and manufacturing.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'One risk for a company with high capital intensity is the ',
      },
      {
        text: 'inability to grow earnings fast enough ',
        bold: true,
      },
      {
        text:
          'to support its capital expenditures. As Buffett observed, "There are certain businesses that inherently, because of long lead times and heavy capital investment, basically ',
      },
      {
        text: 'have a lot of risk."',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'High capital intensity can ',
      },
      {
        text: 'threaten a company’s existence',
        bold: true,
      },
      {
        text:
          '. High capital investment can be financed with high levels of debt, which can be challenging to pay off during economic downturns. ',
      },
    ],
  },
  {
    body: [
      {
        text: 'So how do you identify a capital-intensive business? One simple measure of capital intensity is the ',
      },
      {
        text: 'ratio of assets to revenue (assets divided by revenue)',
        bold: true,
      },
      {
        text: '.',
      },
    ],
  },
  {
    body: [
      {
        text: 'For example, in 2021, ',
      },
      {
        text: 'Boeing had a ratio of 2.2 ',
        bold: true,
      },
      {
        text: '($138.5b in total assets and $62.2b in revenue). By contrast, ',
      },
      {
        text: 'Google had a much lower ratio of 1.4 ',
        bold: true,
      },
      {
        text: '($359.26b in assets and $257.6b in revenue).',
      },
      {
        lineBreak: true,
      },
      {
        text: 'So as you evaluate a business, try out that simple calculation to gauge risk.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Check our our blog post for more commentary (link in the "MORE INFO" section).',
      },
    ],
  },
];
export const investInFutureContent = [
  {
    body: [
      {
        text: 'Famous investor Stanley Druckenmiller has cautioned investors: "Don\'t invest in the present. ',
      },
      {
        text: 'The present is not what moves stock prices.',
        bold: true,
      },
      {
        text: "\" That's because prices today may already reflect what's known by investors.",
      },
    ],
  },
  {
    body: [
      {
        text: 'According to Druckenmiller, investors should instead ',
      },
      {
        text: 'focus on what earnings might look like 18-24 months from now',
        bold: true,
      },
      {
        text: ". Some of those developments, if they occur, may not be reflected in a stock's price now.",
      },
    ],
  },
  {
    body: [
      {
        text:
          'To illustrate, Druckenmiller uses chemical companies. If you invest when earnings are great, he argues, it could mean capacity is low or demand is high. As that changes – capacity increases or demand falls – ',
      },
      {
        text: 'profit margins could drop ',
        bold: true,
      },
      {
        text: 'and cause earnings to sink.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'The reverse may also be true. When chemical companies are losing money, demand may be falling, which may necessitate a cut in production. So three or four years later, ',
      },
      {
        text: 'profit margins – and earnings – may be way up.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          "Many investors follow these principles, such that some version of the future may already be reflected in present prices. That's what investors mean when they describe something as ",
      },
      {
        text: '"already discounted"',
        bold: true,
      },
      {
        text: " in a stock's price.",
      },
    ],
  },
  {
    body: [
      {
        text: 'While some things are reflected, others may not be: consider a ',
      },
      {
        text: 'new product, new competition,',
        bold: true,
      },
      {
        text: ' or developments that might result in ',
      },
      {
        text: 'economic growth ',
        bold: true,
      },
      {
        text: 'or an ',
      },
      {
        text: 'economic slowdown.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: "The goal for investors is to figure out what's reflected in a stock's price today, and ",
      },
      {
        text: 'what the market may be overlooking ',
        bold: true,
      },
      {
        text: 'that could cause the price to rise or fall in the future.',
      },
    ],
  },
];
export const banksContent = [
  {
    body: [
      {
        text: 'Banks are a ',
      },
      {
        text: 'core part of the Financials sector (XLF) ',
        bold: true,
      },
      {
        text: 'and a key component of the US economy. They make nearly ',
      },
      {
        text: '2/3 of their revenue in interest from loans ',
        bold: true,
      },
      {
        text: 'and nearly ',
      },
      {
        text: '1/3 from fees.',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'For larger banks, fees can be tied to investment banking and stock and bond trading, as well as late payment, mortgage, and servicing fees.',
      },
    ],
  },
  {
    body: [
      {
        text: 'The largest banks, also referred to as ',
      },
      {
        text: 'money centers',
        bold: true,
      },
      {
        text:
          ', include JP Morgan (JPM), Citigroup (C), Bank of America (BAC), and Wells Fargo (WFC). Another type of bank is the ',
      },
      {
        text: 'regional bank',
        bold: true,
      },
      {
        text: ', which includes US Bank (USB), Truist (TFC), and PNC Financial Services (PNC).',
      },
    ],
  },
  {
    body: [
      {
        text: 'An important factor to consider when investing in banks is ',
      },
      {
        text: 'interest rate sensitivity',
        bold: true,
      },
      {
        text: ': the degree to which earnings are influenced by ',
      },
      {
        text: 'changes in interest rates.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: "Banks' earnings are driven by the ",
      },
      {
        text: 'direction of interest rates (up or down) ',
        bold: true,
      },
      {
        text: 'and the shape of the so-called ',
      },
      {
        text: 'yield curve',
        bold: true,
      },
      {
        text: ', which refers to the relationship between short-term and long-term rates.',
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Generally, the steeper the curve (lower rates for the short term, higher rates for the long term), the better, though not always.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Bank earnings are ',
      },
      {
        text: 'also driven by the credit cycle',
        bold: true,
      },
      {
        text: ', which pertains to the ease or difficulty for companies and consumers to borrow money.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Earnings for banks may be ',
      },
      {
        text: 'under pressure when credit deteriorates',
        bold: true,
      },
      {
        text:
          ', as companies and people increasingly have difficulty making their debt payments. The reverse is also true.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Loan growth ',
        bold: true,
      },
      {
        text: 'also comes into play for bank earnings. Generally, loan growth is correlated with economic growth.',
      },
    ],
  },
  {
    body: [
      {
        text: "As always, it's important to ",
      },
      {
        text: 'monitor competition',
        bold: true,
      },
      {
        text: ', not only from other banks but particularly from so-called ',
      },
      {
        text: 'fintechs',
        bold: true,
      },
      {
        text: ': keep an eye out for which of these may be granted bank charters. ',
      },
    ],
  },
];
export const financialSectorContent = [
  {
    body: [
      {
        text: 'The ',
      },
      {
        text: 'financial sector ',
        bold: true,
      },
      {
        text:
          'includes JPMorgan Chase (JPM), Bank of America (BAC), Wells Fargo (WFC), Citigroup (C), and Goldman Sachs (GS) as well as MetLife (MET), Berkshire Hathway (BRK.B), and others. ',
      },
      {
        lineBreak: true,
      },
      {
        text: "It's a ",
      },
      {
        text: 'broad category ',
        bold: true,
      },
      {
        text: 'that has a ',
      },
      {
        text: 'sector weight ',
        bold: true,
      },
      {
        text: 'in the S&P 500 of ',
      },
      {
        text: '~11.2% ',
        bold: true,
      },
      {
        text: '(5/31/2022).',
      },
    ],
  },
  {
    body: [
      {
        text: 'Banks are the largest constituent ',
        bold: true,
      },
      {
        text:
          'of the sector, while the rest is comprised of life insurers, property & casualty insurers, asset managers, as well as a variety of other financial service companies including payday lenders.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Many financials - particularly banks and insurers – are ',
      },
      {
        text: 'interest rate sensitive ',
        bold: true,
      },
      {
        text: '– meaning earnings are significantly influenced by prevailing interest rates.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'You can find the impact of interest rates on earnings (sometimes referred to as sensitivities) in their filings with the SEC, namely the 10-K annual filing.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Another thing to monitor for financials is the ',
      },
      {
        text: 'market share impact from competing technology companies ',
        bold: true,
      },
      {
        text:
          'known as fintechs – the latter include startups and publicly traded firms like Square (SQ) and Paypal (PYPL).',
      },
    ],
  },
  {
    body: [
      {
        text: 'Use Tornado to track the broad sector by using the ticker lookup function (enter ',
      },
      {
        text: 'ticker XLF ',
        bold: true,
      },
      {
        text:
          'in the text field next to the magnifying glass). Also, check out financials by using the filters above the feed in the app.',
      },
    ],
  },
];
export const stocksNotTheEconomyContent = [
  {
    body: [
      {
        text: 'While the two are ',
      },
      {
        text: 'interrelated',
        bold: true,
      },
      {
        text: ", it's important to understand that the stock market and the economy are ",
      },
      {
        text: 'two distinct entities',
        bold: true,
      },
      {
        text: '.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'What’s happening in stocks can often serve as a signal for what may happen in the economy six months to a year down the road. Yet it’s ',
      },
      {
        text: 'not necessarily correct ',
        bold: true,
      },
      {
        text: 'to assume that when the stock market is up, the economy is doing better or vice versa. ',
      },
    ],
  },
  {
    body: [
      {
        text: 'One key reason is the ',
      },
      {
        text: 'number of companies each represents',
        bold: true,
      },
      {
        text: '. Fewer than 6,000 domestic companies are listed on major US exchanges. ',
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Yet, in the overall economy, there are over 30M companies (8M sole proprietorships). In addition, the overall ',
      },
      {
        text: 'economy includes both the private *and* public sectors.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Another key difference is the number of employees represented by the market versus the economy: the ',
      },
      {
        text: 'US economy overall employs over 155M people',
        bold: true,
      },
      {
        text: ', while the companies in the stock market employ only about 1/6 of that number.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'A third difference is the size of the market vs the economy: the US economy as of 3Q22 is estimated at over $25T. The aggregate revenue of the companies in the S&P 500 is under 60% of GDP or approximately $15T. ',
      },
    ],
  },
  {
    body: [
      {
        text: 'In the view of some investors, the ',
      },
      {
        text: 'stock market actually serves as a leading indicator for the economy. ',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'So, just as you do with earnings reports and individual companies, look to macro-related headlines to provide clues about the *future* of the overall economy.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'For a deeper dive into the differences, check out our blog post (line in the "MORE INFO" section below).',
      },
      {
        lineBreak: true,
      },
      {
        text:
          'And track factors impacting both the economy and the stock market by checking out Pros and Cons for tickers like SPY.',
      },
    ],
  },
];
export const communicationsServicesSectorContent = [
  {
    body: [
      {
        text: 'The communications services sector includes ',
      },
      {
        text: 'companies that provide consumer services ',
        bold: true,
      },
      {
        text:
          'via fixed-line, cellular, wireless, high bandwidth and/or fiber optic cable networks. Holdings include Meta Platforms (FB), Alphabet (GOOGL/GOOG), Netflix (NFLX), Verizon (VZ), AT&T (T), T-Mobile (TMUS) and Comcast (CMCSA).',
      },
    ],
  },
  {
    body: [
      {
        text: 'The sector ',
      },
      {
        text: 'also includes Disney (DIS), Live Nation (LYV), Match (MTCH) ',
        bold: true,
      },
      {
        text: 'and some gaming companies. The Communication Services sector weight in the S&P 500 is ~8.8% (5/31/2022)',
      },
    ],
  },
  {
    body: [
      {
        text: 'The sector was reconstituted in ',
      },
      {
        text: '2018 to reflect the evolving nature of the US economy ',
        bold: true,
      },
      {
        text:
          'and a broader focus from just telecommunications services. At that time, changes were also made to the consumer discretionary sector, which now includes more technology companies.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Positive drivers for the Communications Services sector include ',
      },
      {
        text: 'long-term growth in digital advertising and streaming',
        bold: true,
      },
      {
        text: ', though these can be closely tied to overall economic growth and contraction.   ',
      },
    ],
  },
  {
    body: [
      {
        text: 'Negative drivers for the sector include ',
      },
      {
        text: 'cord cutting and the potential for slowing wireless growth. ',
        bold: true,
      },
      {
        text:
          'Changing competitive landscapes for streaming/gaming and costs of content development are additional issues. ',
      },
    ],
  },
  {
    body: [
      {
        text:
          'Check out communication services companies by using the ticker lookup function (enter tickers including ',
      },
      {
        text: 'XLC ',
        bold: true,
      },
      {
        text: 'in the text field next to the magnifying glass) in Tornado.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'Also check out communications services by using the filters above the feed in the app.',
      },
    ],
  },
];
export const ebitaContent = [
  {
    body: [
      {
        text: 'EBITDA refers to ',
      },
      {
        text: 'Earnings Before Interest, Taxes, Depreciation and Amortization',
        bold: true,
      },
      {
        text: '. It focuses on the ',
      },
      {
        text: 'core operations of a business ',
        bold: true,
      },
      {
        text: '- what it’s engaged in on a day-to-day basis. How? By removing from its calculation interest and taxes.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'Calculating interest and taxes relates to financing decisions, while depreciation and amortization relate to a company’s investment decisions. ',
      },
    ],
  },
  {
    body: [
      {
        text: 'Many investors use ',
      },
      {
        text: 'EBITDA margin ',
        bold: true,
      },
      {
        text: '(EBITDA as a percent of total revenues) as a way to evaluate a business over time; it’s also used to ',
      },
      {
        text: 'compare its core operations with other companies.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'One easy way to understand EBITDA is to break it down into its ',
      },
      {
        text: 'two main components, EBIT and DA.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          'The first part of the calculation, EBIT, is what a company earns before it pays interest on its debt and taxes to various governments. ',
      },
      {
        text: 'EBIT is often referred to as operating income',
        bold: true,
      },
      {
        text: ', and it’s reported by most companies on their income statements each quarter.',
      },
    ],
  },
  {
    body: [
      {
        text: 'The DA in EBITDA refers to ',
      },
      {
        text: 'depreciation and amortization. ',
        bold: true,
      },
      {
        text:
          'Depreciation and amortization are accounting principles that seek to spread out the cost and benefit of assets over their useful lives.',
      },
    ],
  },
  {
    body: [
      {
        text: 'EBITDA won’t reveal if company A has a higher ',
      },
      {
        text: 'debt burden ',
        bold: true,
      },
      {
        text: 'than company B, or whether it ',
      },
      {
        text: 'uses its capital ',
        bold: true,
      },
      {
        text: 'for subpar equipment or overpaid for an acquisition: these things definitely matter over the long term.',
      },
    ],
  },
  {
    body: [
      {
        text: 'That said, EBITDA can sharpen your financial skill set and ',
      },
      {
        text: 'help you identify important differences among companies ',
        bold: true,
      },
      {
        text: 'as well as ',
      },
      {
        text: 'opportunities for improvement/pitfalls ',
        bold: true,
      },
      {
        text: 'that may be reflected in stock price performance. ',
      },
      {
        lineBreak: true,
      },
      {
        text: 'You can learn more in our blog post (link in the "MORE INFO" section below).',
      },
    ],
  },
];
export const rule72Content = [
  {
    body: [
      {
        text: 'The Rule of 72 is a ',
      },
      {
        text: 'useful shortcut ',
        bold: true,
      },
      {
        text: 'to quickly determine how many years it might take an investment to double. It demonstrates ',
      },
      {
        text: 'the power of compound interest',
        bold: true,
      },
      {
        text: '.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'According to famed investor Peter Lynch, "The Rule of 72 is useful in determining how fast money will grow. Take the ',
      },
      {
        text: 'annual return ',
        bold: true,
      },
      {
        text: 'from any investment, expressed as a percentage, and ',
      },
      {
        text: 'divide it into 72. ',
        bold: true,
      },
      {
        text: 'The result is the ',
      },
      {
        text: 'number of years it will take to double your money."',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'The formula is this: 72 / annual growth rate % = years to double.  ',
      },
    ],
  },
  {
    body: [
      {
        text:
          'For example, if the stock market is projected to appreciate 9% each year (its historical average), then divide 72 by 9 and you get 8 years to potentially double. That means if you invested $1,000 in the S&P 500 today, that investment ',
      },
      {
        text: 'could be worth $2,000 in 8 years.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'One important ',
      },
      {
        text: 'caveat is the projected growth rate. ',
        bold: true,
      },
      {
        text:
          'Projections are typically based on historical growth rates, but it’s important to consider how these might change over time.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'If interest rates increase 500 bps (basis points), that would likely change the return profile for a stock, as well as the stock market. In that scenario, the stock market may return less - say, only 4%/year, which means it ',
      },
      {
        text: 'could take 18 years for your investment to double. ',
        bold: true,
      },
      {
        text: 'Big difference.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Projected returns aren’t fixed and can change over time, so the Rule of 72 is ',
      },
      {
        text: 'more of a guide than a certainty',
        bold: true,
      },
      {
        text:
          '. You can find historical and projected revenue growth, as well as various other metrics that factor into projections, by looking up tickers in the search field.',
      },
    ],
  },
];
export const bankRateContent = [
  {
    body: [
      {
        text: 'There are so many credit card types, it’s easy to become overwhelmed by the sheer number of choices.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'So, how do you choose the best credit card? ',
        bold: true,
      },
      {
        text: 'Let’s take a look at a few different types, and how they might work for your financial goals.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Rewards Credit Cards',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Rewards credit cards typically give you points or cash back based on a percentage of your spending.',
      },
      {
        lineBreak: true,
      },
      {
        text:
          'If you use a rewards credit card to cover basic purchases like groceries, you can earn rewards for purchases you needed to make anyway.',
      },
    ],
  },
  {
    body: [
      {
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Some credit cards offer 0 percent intro APR on purchases for a limited time, usually up to 18 months.',
      },
      {
        lineBreak: true,
      },
      {
        text:
          'These credit cards can help if you need to make a large purchase and want to pay it back over time without interest.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Student Credit Cards',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Student credit cards are “starter credit cards” geared toward young people with a limited credit history.',
      },
      {
        lineBreak: true,
      },
      {
        text:
          'If used responsibly, signing up for a student credit card can help you build credit and start creating good financial habits.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Travel Credit Cards',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Travel credit cards offer the opportunity to earn rewards such as flexible travel credits for any travel purchase, or points you can transfer to airline or hotel programs.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'With some cards you can earn these perks by making non-travel-related purchases.',
      },
    ],
  },
  {
    body: [
      {
        text: 'There are also several other types of credit cards that may suit your needs.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'If you’re in the market for a credit card, ',
      },
      {
        text: 'Bankrate’s CardMatch is a great tool to use in your search.',
        bold: true,
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];
export const howMuchCarInsuranceNeededContent = [
  {
    body: [
      {
        text: '',
      },
    ],
  },
  {
    body: [
      {
        text: 'Car insurance numbers can seem nebulous and confusing.',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'What will they mean if you get in an accident? How much is enough?',
      },
      {
        lineBreak: true,
      },
      {
        text: 'Lower numbers come alongside lower premiums, but it’s important to make sure you have enough coverage.',
      },
    ],
  },
  {
    body: [
      {
        text: 'So how much car insurance do you need? What will protect you from the majority of situations?',
      },
      {
        lineBreak: true,
      },
      {
        text: 'Everyone’s situation is different.',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Let’s look at a few things to keep in mind when figuring out your needs.',
      },
    ],
  },
  {
    body: [
      {
        text: 'What’s mandated where you live?',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'The easiest way to begin? Find out the minimum car insurance required by your state.',
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Note that these limits are simply your starting point. In most states, you should consider more than the minimum.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Who’s driving your car?',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'The ages, experience levels, and driving records of your car’s drivers should absolutely inform your coverage choices.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'The greater the risk profile, the more coverage you should consider.',
      },
    ],
  },
  {
    body: [
      {
        text: 'How many miles do you put on your car?',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'The more miles you put on the car, the longer you’re on the road and the greater the likelihood you could be involved in an accident.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'In that case, you should consider more coverage.',
      },
    ],
  },
  {
    body: [
      {
        text: 'What’s the weather like?',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Think about where you drive.',
      },
      {
        lineBreak: true,
      },
      {
        text:
          'If you live in an area where severe, volatile, or low-visibility weather patterns are common, you should consider buying more insurance.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Do you have health insurance?',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Even if your state doesn’t require uninsured/underinsured motorist coverage, you should still consider purchasing it.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'According to info from the Insurance Information Institute, about 1 in 8 drivers are uninsured.',
      },
    ],
  },

  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];
export const typesOfCarInsuranceStocksContent = [
  {
    body: [
      {
        text: 'There are six primary types of car insurance.',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Each state requires different types of coverage. Car accidents can happen, and the best time to learn about your policy is before you need to use it.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Liability Coverage',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Liability coverage applies to situations in which ',
      },
      {
        text: 'you are at fault for an accident.',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'This coverage is required in most states and ',
      },
      {
        text: 'helps you meet your legal obligations to others in these situations.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Uninsured/Underinsured Motorist',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Uninsured/underinsured coverage applies to situations in which ',
      },
      {
        text: 'you’re hit by a driver who either doesn’t have auto insurance or has insufficient auto insurance ',
        bold: true,
      },
      {
        text: 'to pay the costs resulting from the accident.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Medical Payments',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Medical payments coverage ',
      },
      {
        text: 'can pay for your or your passengers’ medical expenses ',
        bold: true,
      },
      {
        text: 'due to an auto accident. This coverage can come into play no matter who’s at fault.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'It is not available in all states; when it is, it is almost always optional.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Personal Injury Protection',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Personal injury protection (PIP) coverage — optional in some states; required in others — ',
      },
      {
        text: 'can pay for your or your passengers’ medical expenses AND other costs ',
        bold: true,
      },
      {
        text:
          '(e.g., lost wages, rehabilitation services, funeral costs) due to an auto accident, no matter who’s at fault.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Collision',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Collision coverage may pay for damages to your car when you’re at fault for a collision.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'Basically, this ',
      },
      {
        text: 'covers collision-related property damage that is not covered by liability.',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text: 'Comprehensive',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Comprehensive coverage ',
      },
      {
        text: 'may pay for damages to your car that aren’t the result of a collision.',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Make sure you understand whether your car is insured for actual cash value (ACV) or replacement cost.',
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];

export const bestowLifeInsuranceContent = [
  {
    body: [
      {
        text: 'What is life insurance?',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: "At its basic level, it's an agreement between you and a life insurance company.",
      },
      {
        lineBreak: true,
      },
      {
        text:
          'In exchange for a premium, the insurer promises to pay a sum of money upon your death to those you designate as beneficiaries.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Do I need life insurance?',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'To find out, ask yourself the following question: would your absence cause anyone financial strain?',
      },

      {
        lineBreak: true,
      },
      {
        text: "If so, the answer is yes. Let's take a look at the 5 top reasons you may need life insurance.",
      },
    ],
  },
  {
    body: [
      {
        text: '1. You have a mortgage or other shared debt.',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Consider life insurance if a parent has co-signed a student loan for you, for instance.',
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Have you co-borrowed a mortgage, personal loan, or home equity line of credit with a spouse, partner, or sibling?',
      },
    ],
  },
  {
    body: [
      {
        text: "2. Your life insurance through work isn't enough.",
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          'Group life insurance through an employer usually maxes out at a low fixed dollar amount or 1-2 times your annual salary.',
      },
      {
        lineBreak: true,
      },
      {
        text: 'Financial experts recommend 10x your salary as a rule of thumb.',
      },
    ],
  },
  {
    body: [
      {
        text: "3. You contribute a meaningful portion of your family's income.",
        bold: true,
      },

      {
        lineBreak: true,
      },
      {
        text: 'If you support (or will support)',
      },

      {
        lineBreak: true,
      },
      {
        text:
          ' a spouse, kids, parents, grandparents, siblings, or others and the loss of your income would affect them, you may need life insurance.',
      },
    ],
  },
  {
    body: [
      {
        text: '4. You have kids.',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text:
          "Even if you don't provide income your family relies on, you likely provide care that your family would have to pay for in your absence.",
      },
      {
        lineBreak: true,
      },
      {
        text: 'Life insurance can also meaningfully contribute to college savings.',
      },
    ],
  },
  {
    body: [
      {
        text: '5. You run a business.',
        bold: true,
      },
      {
        lineBreak: true,
      },
      {
        text: 'Life insurance can be extra important for small business owners.',
      },
      {
        lineBreak: true,
      },
      {
        text:
          'You might have taken on business debt using personal assets, like your home, as collateral. In that case, life insurance can help.',
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];
export const additionalLifeInsuranceCoverage = [
  {
    body: [
      {
        text:
          'Investing in your future is important, and one aspect of that is ensuring you have enough life insurance coverage.',
      },
      { lineBreak: true },
      { text: 'Many people rely on the ' },
      {
        text:
          'life insurance coverage provided by their employer, but it may not be enough to fully protect your loved ones. ',
        bold: true,
      },
      { text: 'For example - Employers typically provide 1-2x your salary in coverage. ' },
    ],
  },
  {
    body: [
      {
        text:
          'Understanding the details of your employer-provided life insurance policy, including the amount of coverage and any limitations, can help you determine if you need additional coverage.',
      },
      { lineBreak: true },
      { text: 'It’s also important to consider your current and future needs and goals,', bold: true },
      {
        text:
          ' such as the number of dependents you have and any outstanding debts, like paying off your mortgage, and student loans, or having enough money for retirement. ',
      },
    ],
  },
  {
    body: [
      {
        text:
          'A quick way to estimate the appropriate level of coverage is to multiply your salary by 10x. Or you can assign a value to all your long-term life goals (like paying off your mortgage). ',
      },
      { lineBreak: true },
      { lineBreak: true },
      {
        text: 'This would be your coverage amount. The term would be when you want to complete these goals by. ',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          'If you find that your employer-provided life insurance is lacking, you may want to consider purchasing additional coverage.',
      },
      { lineBreak: true },
      { text: 'By taking the time to understand your options now, ' },
      { text: 'you can avoid being without adequate coverage when it’s too late. ', bold: true },
    ],
  },
  {
    body: [
      { text: 'Keep in mind that life insurance can also have features that can help you save money over time. ' },
      {
        text:
          'Through our partner Ladder, you can review coverage regularly and make changes any time your coverage needs change. ',
        bold: true,
      },
    ],
  },
  {
    body: [
      {
        text:
          'Investing in your future includes protecting your loved ones, and there are options for supplementing what may not be enough coverage from your employer. ',
      },
      { text: 'Term life insurance can be a great place to start.', bold: true },
    ],
  },

  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];

export const youNeedABudgetContent = [
  {
    body: [
      { text: 'Ever found yourself wondering where your hard-earned dollars go', bold: true },
      {
        text:
          '? Learning to better manage your money can help you experience less financial stress. The good news? Money doesn’t have to be messy!',
      },
    ],
  },
  {
    body: [
      { text: 'The magic isn’t in the math—it’s in the method. Here are ' },
      { text: 'a few simple but life-changing rules', bold: true },
      { text: ' that can help you grow your savings and spend wisely.' },
    ],
  },
  {
    body: [
      { text: 'What if every time you got paid, you know where each dollar of that paycheck was going? ' },
      {
        text: 'To see your plan clearly, give each dollar a job instead of using mental math to keep track.',
        bold: true,
      },
    ],
  },
  {
    body: [
      { text: 'Future-proof your money by embracing your true expenses.', bold: true },
      {
        text:
          ' Break budget-busters down into smaller monthly checks to prepare for expenses like car repairs that are a fact of life.',
      },
    ],
  },
  {
    body: [
      { text: ' ' },
      { text: 'Build a flexible budget.', bold: true },
      { text: ' When life changes, change your budget because budgets that don’t bend will break.' },
    ],
  },
  {
    body: [
      { text: 'Enjoy guilt-free spending and effortless saving with a' },
      { text: ' friendly, flexible method for managing your finances.', bold: true },
    ],
  },
];

export const accreditedDebtReliefContent = [
  {
    body: [
      { text: "You're not alone if you’re struggling with different kinds of debt.", bold: true },
      {
        text:
          ' A debt resolution provider is a service provider that can help you consolidate your debt while trying to negotiate and reduce your debt for you. Providers have helped over 200,000 clients and settled over $1 billion in debt.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'They’ll negotiate with your creditors to help reduce the amount you owe. An escrow account is set up under your name, and you’ll make payments until the balance reaches the amount to pay down the reduced total.',
      },
    ],
  },
  {
    body: [
      { text: 'Debt resolution can help you get rid of debt more quickly. Depending on your situation, it' },
      {
        text:
          ' could lower your total debt owed, lowering your monthly payments and helping you become debt free in 12-48 months.',
        bold: true,
      },
    ],
  },
  {
    body: [
      { text: 'Paying' },
      { text: ' just one bill for much of your debt ', bold: true },
      { text: 'can help make it easier to pay your bills.' },
    ],
  },
  {
    body: [
      { text: 'If you’re looking to ' },
      { text: 'pay one bill vs. multiple bills to simplify your finances', bold: true },
      { text: ', debt consolidation may be advantageous.' },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];

export const morningBrewContent = [
  {
    body: [
      {
        text:
          'Keeping up with business news can help you make more informed investing decisions. Let’s look at a few things worth paying attention to. ',
      },
    ],
  },
  {
    body: [
      { text: 'Market Trends', bold: true },
      { text: ': Business news provides insights that can help you identify emerging industries and market trends. ' },
      { text: 'This information can help you make informed decisions about which stocks to buy and sell.', bold: true },
    ],
  },
  {
    body: [
      { text: 'Company Performance:', bold: true },
      { text: ' Business news also provides information on the performance of individual companies. ' },
      { text: 'Understanding how a company is performing financially,', bold: true },
      { text: " as well as its future prospects, can help you decide whether to invest in that company's stock." },
    ],
  },
  {
    body: [
      { text: 'Economic Indicators:', bold: true },
      {
        text:
          ' Business news often covers economic indicators such as inflation rates, interest rates, and GDP growth. These indicators can provide insight into the overall health of the economy and ',
      },
      { text: 'how it may impact your investments', bold: true },
      { text: '.' },
    ],
  },
  {
    body: [
      { text: 'Risk Management:', bold: true },
      { text: ' Staying up-to-date with business news can help you manage investment risks. ' },
      { text: 'News of industry disruptions or negative events affecting a company', bold: true },
      { text: ' can signal a need to sell a stock to minimize losses.' },
    ],
  },
  {
    body: [
      {
        text:
          'In summary, staying informed on business news can help you make informed decisions on which companies to invest in, how to diversify your portfolio, and how to manage risk.',
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];

export const edXContent = [
  {
    body: [
      {
        text:
          'Online learning opportunities offer the flexibility to learn at your own pace and on your own schedule, ',
      },
      { text: 'which is particularly advantageous for individuals with busy schedules or commitments. ', bold: true },
    ],
  },
  {
    body: [
      { text: 'Additionally, ' },
      {
        text:
          'online learning can provide access to a wider range of courses and programs than traditional in-person learning',
        bold: true,
      },
      { text: ', allowing individuals to pursue their specific interests and career goals.' },
    ],
  },
  {
    body: [
      {
        text:
          'The transformative power of education is what unlocks that potential. Yet access to high-quality education has historically been a privilege of the few. ',
      },
    ],
  },
  {
    body: [
      {
        text:
          'edX unlocks access to education, without the barriers of cost or location. It’s home to more than 42 million learners, the majority of top-ranked universities in the world, and industry-leading companies.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'edX offers a wide range of free online courses, as well as opportunities for career advancement certifications. Earn a Master’s Degree, attend a Bootcamp, or pursue Executive Education to build career-critical skills.',
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];

export const trustAndWillContent = [
  {
    body: [
      { text: 'Estate planning', bold: true },
      {
        text:
          " is the process of arranging for the management and disposal of an individual's assets in the event of their incapacitation or death. You may think only very wealthy people need to consider estate planning, but a sound plan can provide benefits to individuals of any income and wealth level.",
      },
      { lineBreak: true },
      {
        text:
          'Although it may be challenging to think about, proper estate planning has several crucial benefits, which we’ll cover in this lesson.',
      },
    ],
  },
  {
    body: [
      { text: 'Asset distribution: ' },
      {
        text:
          'Estate planning enables individuals to decide who will inherit their assets, ensuring that their loved ones are provided for.',
        bold: true,
      },
      {
        text:
          " Without an estate plan, the distribution of assets may be determined by state laws, which may not align with an individual's desires.",
      },
    ],
  },
  {
    body: [
      { text: 'Minimize taxes and fees: ' },
      {
        text: 'Estate planning helps to minimize taxes and other fees that may be incurred during the probate process',
        bold: true,
      },
      { text: ', which can reduce the amount of assets that are passed down to heirs.' },
    ],
  },
  {
    body: [
      { text: 'Avoid family conflicts: ' },
      {
        text: 'Estate planning can help to reduce the potential for family conflicts and disputes over inheritances.',
        bold: true,
      },
      {
        text:
          " By outlining an individual's wishes in a legal document, disagreements and misunderstandings among relatives can be minimized.",
      },
    ],
  },
  {
    body: [
      { text: 'Name guardians for minors: ' },
      { text: 'Estate planning enables individuals to name guardians for their minor children', bold: true },
      { text: ', ensuring that they are cared for in the event of the parents’ untimely passing.' },
    ],
  },
  {
    body: [
      { text: 'Plan for unique circumstances: ' },
      {
        text:
          'Estate planning can be beneficial for individuals with unique circumstances, such as blended families, individuals with disabled dependents, or individuals who own businesses. ',
        bold: true,
      },
      {
        text:
          "An estate plan can help to ensure that these unique circumstances are taken into account and that the individual's wishes are carried out accordingly.",
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];

export const booksForInvestorsContent = [
  {
    body: [
      { text: 'No matter where you are on your investing journey, there’s always more to learn.' },
      { lineBreak: true },
      { text: 'Here are three books to add to your list.', bold: true },
    ],
  },
  {
    body: [
      { text: 'The Snowball: Warren Buffett and the Business of Life' },
      { lineBreak: true },
      { text: 'By Alice Schroeder' },
    ],
  },
  {
    body: [{ text: 'The Intelligent Investor' }, { lineBreak: true }, { text: 'By Benjamin Graham' }],
  },
  {
    body: [{ text: 'Stocks for the Long Run' }, { lineBreak: true }, { text: 'By Jeremy Siegel' }],
  },
  {
    body: [
      { text: '“The best protection against risk is knowing what you are doing.” - Seth Klarman' },
      { lineBreak: true },
      { text: 'Check back daily to continue Learning & Earning.' },
    ],
  },
];

export const shareClassesContent = [
  {
    body: [
      { text: 'Unlike most companies, which have only one ticker, ' },
      { text: 'there are two different ways to purchase shares in Google’s parent company Alphabet', bold: true },
      { text: ': GOOG and GOOGL.' },
    ],
  },
  {
    body: [
      { text: 'GOOGL is classified as a Class A share', bold: true },
      {
        text:
          '. Class A shares provide voting rights, with one vote corresponding to each share. Voting rights are the rights of a shareholder to vote for various corporate decision.',
      },
      { lineBreak: true },
      {
        text:
          'These could include choosing members of the board of directors, issuing stock, pursuing mergers and acquisitions, and other actions.',
      },
    ],
  },
  {
    body: [
      { text: 'GOOG is classified as a Class C share', bold: true },
      { text: '. Unlike Class A shares, Class C shares do ' },
      { text: 'not', bold: true },
      { text: ' provide voting rights.' },
    ],
  },
  {
    body: [
      {
        text:
          'Non-voting shares are not common; they may be created when company founders or directors seek to raise new capital via share offerings without ',
      },
      { text: 'diluting their control.', bold: true },
    ],
  },
  {
    body: [
      {
        text:
          'Alphabet’s two different classes of shares exist because, in 2014, the company issued additional shares - the GOOG Class C shares - but wanted to ',
      },
      { text: 'preserve control for the company’s founders', bold: true },
      { text: '.' },
      { lineBreak: true },
    ],
  },
  {
    body: [
      { text: 'Why choose GOOG? ' },
      { text: 'GOOG, in principle, could trade at a discount to GOOGL', bold: true },
      {
        text:
          ' due to its lack of voting rights. But if you want a say in Google’s business affairs, then buying GOOGL could present that opportunity.',
      },
      { lineBreak: true },
    ],
  },
  {
    body: [
      {
        text:
          'Check out Google parent Alphabet’s 10-K for more information about Google’s different classes of shares (link in the More Info section below): ',
      },
    ],
  },
];
export const risksOfLeveragedETFsContent = [
  {
    body: [
      {
        text:
          'Leveraged ETFs seek to deliver several times (often 2-3x) the performance of the index they track. According to the Securities and Exchange Commission, ',
      },
      { text: 'they carry extra risks', bold: true },
      { text: '.' },
    ],
  },
  {
    body: [
      { text: 'One of the reasons for amplified risk is the way they are constructed: many use ' },
      { text: 'financial derivatives and/or debt', bold: true },
      { text: ' to increase their potential returns.' },
    ],
  },
  {
    body: [
      { text: 'In addition, most leveraged ETFs' },
      { text: ' “reset” daily', bold: true },
      {
        text:
          ', meaning they are designed to achieve their return objectives on a daily basis only (sometimes at the expense of long-term performance).',
      },
    ],
  },
  {
    body: [
      { text: 'Importantly, this could result in leveraged ETFs being ' },
      { text: 'forced to "buy high" and "sell low" ', bold: true },
      { text: 'daily to maintain their target exposure.' },
    ],
  },
  {
    body: [
      { text: 'As a result of all of the above, ' },
      { text: 'performance can differ significantly from the underlying index ', bold: true },
      {
        text:
          'over long time frames. This effect can be magnified in a volatile market. Leveraged ETFs often charge higher fees too.',
      },
    ],
  },
  {
    body: [
      { text: 'In summary, while they may potentially be useful in some situations, leveraged ETFs may be ' },
      { text: 'better suited to short-term, daily trading strategies', bold: true },
      { text: ' versus long-term investments.' },
    ],
  },
];
export const inverseETFsContent = [
  {
    body: [
      { text: 'Inverse ETFs (exchange-traded funds) seek to deliver the ' },
      { text: 'opposite of the performance of the index', bold: true },
      { text: ' they track. They increase in price when the index they track declines in price, and vice versa.' },
    ],
  },
  {
    body: [
      { text: 'In general, inverse ETFs provide a ' },
      { text: 'way to benefit from a bearish, or negative, view of an index', bold: true },
      {
        text:
          ', whether that’s the broad S&P 500 index or industry-specific indices including the semiconductor index or the US dollar index. Indeed, many of these ETFs have the word “bear” in their names.',
      },
    ],
  },
  {
    body: [
      { text: 'An inverse ETF ' },
      { text: 'can also be used as a hedge to offset a long-term holding', bold: true },
      {
        text:
          '. This might come into play if an investor is bullish about a sector for the long term but believes that the sector may decline for a period of time.',
      },
      { lineBreak: true },
      { text: 'Inverse ETFs are also ' },
      { text: 'used by traders', bold: true },
      { text: ' to make money on days when the market declines.' },
    ],
  },
  {
    body: [
      { text: 'While inverse ETFs can increase returns and/or reduce losses in declining markets, there are ' },
      { text: 'significant risks, particularly over longer time horizons', bold: true },
      { text: '. They can lead to losses if the market goes up - and they often charge higher fees.' },
    ],
  },
  {
    body: [
      {
        text:
          "They're also often constructed using financial derivatives that may carry additional risks in extended downturns or abnormal markets.",
      },
      { lineBreak: true },
      { text: 'Since their managers buy and sell derivative contacts daily, there is ' },
      { text: 'no way to guarantee that the inverse ETF will mirror the performance', bold: true },
      { text: ' of the index or stocks it is tracking.' },
    ],
  },
  {
    body: [{ text: 'Over longer timeframes, their performance can differ dramatically from their index.' }],
  },
  {
    body: [
      { text: 'The frequent trading to maintain inverse ETFs often ' },
      { text: 'increases fund expenses ', bold: true },
      { text: 'and some inverse ETFs can carry expense ratios of 1% or more.' },
    ],
  },
];
export const amazonCompetitiveLandscapeContent = [
  {
    body: [
      { text: 'Part of understanding a company is ' },
      { text: 'understanding its industry', bold: true },
      { text: ', and an important facet of that is knowing its competitors. Remember that Amazon’s ' },
      { text: 'key businesses are cloud services and retail', bold: true },
      { text: '.' },
    ],
  },
  {
    body: [
      {
        text:
          'Its burgeoning ad business and Prime services business (which includes streaming) are also important. A ',
      },
      { text: 'full revenue breakdown for 2021', bold: true },
      { text: ' is ' },
      { text: 'here.' },
      { text: ' Let’s look at some major competitors in each business.' },
    ],
  },
  {
    body: [
      { text: 'In ' },
      { text: 'cloud services' },
      {
        text:
          ', key competitors include Google (GOOG), Microsoft (MSFT), Oracle (ORCL), IBM (IBM), and Alibaba (BABA).  ',
      },
    ],
  },
  {
    body: [
      { text: 'In ' },
      { text: 'retail' },
      { text: ', Amazon has numerous competitors, notably well-capitalized ' },
      { text: 'brick & mortar retailers ', bold: true },
      { text: 'including Walmart (WMT), Costco (COST), and Dollar General (DG), and also ' },
      { text: 'pure play e-commerce platforms', bold: true },
      { text: ' including Shopify (SHOP), Wayfair (W), and Etsy (ETSY). ' },
    ],
  },
  {
    body: [
      { text: 'In ' },
      { text: 'advertising' },
      { text: ', Amazon is increasingly competing with mega caps including ' },
      { text: 'Google (GOOG) and Meta (META)', bold: true },
      { text: ', as well as the Trade Desk (TTD).' },
    ],
  },
  {
    body: [
      {
        text:
          'In subscription services, which offer a bundle of digital services including Prime Video, Prime Music, and Prime Reading, competitors include ',
      },
      { text: 'Netflix (NFLX), Disney (DIS), and Apple (AAPL). ', bold: true },
    ],
  },
  {
    body: [
      {
        text:
          'Make sure to continually check out the ticker profile for Amazon (AMZN), but also monitor each of the competitors.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'To identify competitors independently, one source for competitors is the “Compare tab” in the ticker profiles. In addition, companies often list specific competitors in their ',
      },
      { text: '10-Ks', bold: true },
      { text: ', in ' },
      { text: 'proxy filings', bold: true },
      { text: ' (14-A), and in ' },
      { text: 'earnings calls ', bold: true },
      { text: 'and other ' },
      { text: 'investor events', bold: true },
      { text: '.' },
      { lineBreak: true },
      { text: 'You can check out Amazon’s 2021 10-K ', bold: true },
      { text: '(Link in More Info section below)' },
      { text: ':', bold: true },
    ],
  },
];
export const industrialsContent = [
  {
    body: [
      { text: 'The ' },
      { text: 'industrial sector', bold: true },
      {
        text:
          ' includes Honeywell (HON), United Parcel Service (UPS), Raytheon (RTX), Union Pacific (UNP), Boeing (BA), General Electric (GE), Caterpillar (CAT), 3M (MMM) and others.',
      },
    ],
  },
  {
    body: [
      { text: 'Industrial companies account for ' },
      { text: '~7.8% of the S&P 500 ', bold: true },
      { text: '(5/31/2022).' },
    ],
  },
  {
    body: [
      { text: 'Industrials are often thought of as highly ' },
      { text: 'cyclical', bold: true },
      { text: ', with earnings that can be ' },
      { text: 'tied to stages of the economic cycle - early, mid or late', bold: true },
      {
        text:
          '. Different industrials have different exposures to these parts of the cycle, and these are related to end markets.',
      },
    ],
  },
  {
    body: [
      { text: 'End markets', bold: true },
      { text: ' are as ' },
      { text: 'varied as the overall economy', bold: true },
      {
        text:
          ' and include aerospace, auto, construction, defense, semiconductors, electronic components, healthcare, HVAC, oil & gas, telecom, and trucking.',
      },
    ],
  },
  {
    body: [
      { text: 'Industrials can have exposure to ' },
      { text: 'multiple end markets,', bold: true },
      { text: ' not just one, which can ' },
      { text: 'help reduce cyclicality', bold: true },
      { text: ' and smooth earnings over time.' },
    ],
  },
  {
    body: [
      { text: 'Another key driver for industrials is ' },
      { text: 'incremental margin,', bold: true },
      {
        text:
          ' which is essentially how much profit a company generates from additional sales. If you generate $10 profit from $100 additional revenue, the incremental margin is 10%. The higher, the better in general.',
      },
    ],
  },
  {
    body: [
      { text: 'International exposure', bold: true },
      {
        text:
          ' can also be a driver, as a multi-industrial company may want to be exposed to faster-growing economies. ',
      },
      { text: 'Currency movements', bold: true },
      { text: ' and ' },
      { text: 'economic cycles in various countries', bold: true },
      { text: ' are important to monitor.' },
    ],
  },
  {
    body: [
      { text: 'Check out industrials by using the ticker lookup function (enter a ticker like ' },
      { text: 'XLI ', bold: true },
      { text: 'in the text field next to the magnifying glass) in Tornado.' },
      { lineBreak: true },
      { text: 'You can also view industrials by using the filters above the feed in-app.' },
    ],
  },
];
export const dividendsAndStockReturnsContent = [
  {
    body: [
      { text: 'The bedrock of a long-term portfolio often includes ' },
      { text: 'stocks with financial strength', bold: true },
      { text: '. One indication of that is a company’s ability to pay dividends year in and year out.' },
    ],
  },
  {
    body: [
      { text: 'Dividends are ' },
      { text: 'payments a company makes to return capital to its stockholders', bold: true },
      { text: '.' },
      { lineBreak: true },
      {
        text:
          "They're generally paid on a regular basis (often quarterly), usually from operating cash flow, and they are one of the primary ways, in addition to stock price appreciation, that investors earn returns from stocks.",
      },
    ],
  },
  {
    body: [
      { text: 'In fact, according to one theory, ' },
      { text: 'dividend growth', bold: true },
      { text: ' can ' },
      { text: 'drive long-term stock price appreciation', bold: true },
      { text: '. The ' },
      { text: 'dividend discount model/Gordon growth model', bold: true },
      { text: ' shows the relationship between the two.' },
    ],
  },
  {
    body: [
      { text: 'With dividend stocks, you actually ' },
      { text: 'get paid', bold: true },
      { text: ' cash (or more shares) as an owner of the company ' },
      { text: 'while you hold your stock', bold: true },
      { text: '. If they are reinvested, dividends are also a way to dollar cost average over time.' },
    ],
  },
  {
    body: [
      {
        text:
          'How important are dividends to shareholder returns? According to CFRA Research, since 1945, dividends paid and reinvested ',
      },
      { text: 'have contributed 33% ', bold: true },
      { text: 'of the total return in the S&P 500.' },
    ],
  },
  {
    body: [
      { text: 'Not all companies pay dividends. Dividends are ' },
      { text: 'more likely to be paid by well-established companies', bold: true },
      {
        text:
          ' that no longer need to invest as much money back into their business or that generate high amounts of excess cash.',
      },
      { lineBreak: true },
      { text: 'Many high-growth companies do not pay dividends until later in their maturity cycle.' },
    ],
  },
  {
    body: [
      { text: 'It’s important to recognize that a stock with an ' },
      { text: 'unusually high dividend yield ', bold: true },
      { text: 'can indicate that a company may have issues that could result in a cut to its dividend. Dividends ' },
      { text: 'may also be suspended and/or reduced', bold: true },
      { text: ' in times of financial stress for companies.' },
    ],
  },
  {
    body: [
      { text: 'Finally, a ' },
      { text: 'change in policy', bold: true },
      {
        text:
          ' to add or eliminate a dividend may result in a change in the shareholder base with coincident selling and/or buying.',
      },
    ],
  },
];
export const dividendsDynamicsTypesContent = [
  {
    body: [
      { text: 'While growth stocks play a central role in many investment strategies, it may be important to ' },
      { text: 'guard against concentration', bold: true },
      { text: ' in volatile stocks.' },
    ],
  },
  {
    body: [
      { text: 'Buying quality, dividend-paying stocks can be a great way to ' },
      { text: 'generate quarterly or annual income', bold: true },
      { text: ' and ' },
      { text: 'benefit from dollar cost averaging', bold: true },
      { text: ' for your investment portfolio while helping you ' },
      { text: 'diversify your holdings', bold: true },
      { text: '.' },
    ],
  },
  {
    body: [
      { text: 'There are important ' },
      { text: 'dynamics to keep in mind', bold: true },
      { text: ' when buying and selling stocks that pay dividends.' },
    ],
  },
  {
    body: [
      { text: 'First is the type of dividend. The most common is the ' },
      { text: 'cash dividend,', bold: true },
      { text: ' where shareholders receive cash in their brokerage account based on the number of shares they own.' },
    ],
  },
  {
    body: [
      { text: 'You may also have the option to collect a dividend in additional shares of stock, also called a ' },
      { text: 'stock dividend', bold: true },
      { text: '.' },
    ],
  },
  {
    body: [
      { text: 'Some companies elect to pay “' },
      { text: 'Special dividends', bold: true },
      {
        text:
          '” which are usually non-recurring, one-time payments, perhaps due to a spin-off, sale of a division, or excess cash on a company’s balance sheet.',
      },
    ],
  },
];
export const dividendsImportantDatesContent = [
  {
    body: [
      { text: 'There are ' },
      { text: 'key dates', bold: true },
      {
        text:
          ' that come into play with all dividends: the declaration date, the ex-dividend date, the record date, and the payment date.',
      },
    ],
  },
  {
    body: [
      { text: 'The most important of the four is the ' },
      { text: 'ex-dividend date. ', bold: true },
      { text: 'This is the date by which investors must have purchased the stock to receive a dividend.' },
    ],
  },
  {
    body: [
      { text: 'Investors who ' },
      { text: 'purchase the stock', bold: true },
      { text: ' ', bold: true },
      { text: 'on or after the ex-dividend date will not be eligible to receive that particular dividend.' },
      { lineBreak: true },
      { text: 'Investors who ' },
      { text: 'sell the stock', bold: true },
      { text: ' after the ex-dividend date are still entitled to receive the dividend.' },
    ],
  },
  {
    body: [
      {
        text:
          'Let’s look at a real-world example. Let’s say you bought 100 shares of IBM on April 1, 2022, and continue to hold them. IBM currently ',
      },
      { text: 'pays a quarterly dividend', bold: true },
      { text: '.' },
    ],
  },
  {
    body: [
      { text: 'The ' },
      { text: 'declaration date', bold: true },
      {
        text:
          ' for IBM’s third-quarter dividend was July 25, 2022. This is the day IBM’s Board of Directors announced the dividend. It’s largely a book-keeping date for the company.',
      },
    ],
  },
  {
    body: [
      { text: 'The ex-dividend date was August 9, 2022. You must have ' },
      { text: 'purchased the shares before this date', bold: true },
      {
        text:
          ' to be eligible to receive the dividend. The IBM shares you purchased in April qualified for this dividend.',
      },
      { lineBreak: true },
      {
        text:
          'If you wanted to add more shares, however, you must have purchased them prior to the ex-date to have them qualify for the third-quarter dividend. ',
      },
    ],
  },
  {
    body: [
      { text: 'Similarly, if you wanted to sell some of your IBM shares, ' },
      { text: 'you could still receive the dividend by waiting to sell until after the ex-dividend date.', bold: true },
      { lineBreak: true },
      {
        text:
          'The price will adjust by the amount of the dividend, but you’d get the latest dividend even if you no longer want to own a portion (or all) of your IBM shares. ',
      },
    ],
  },
  {
    body: [
      { text: 'As you can see, ' },
      { text: 'being aware of the ex-date when buying and selling shares ', bold: true },
      { text: 'can be important.' },
    ],
  },
  {
    body: [
      { text: 'The ' },
      { text: 'payment date ', bold: true },
      {
        text:
          'was September 10, 2022. This is the day the company sent the money to you, either as a check or directly to your brokerage account.',
      },
    ],
  },
];
export const geOverviewContent = [
  {
    body: [
      { text: 'General Electric (GE) has historically been a ' },
      { text: 'conglomerate', bold: true },
      {
        text:
          ', a corporation made up of many different businesses. At one time, it sold appliances and had a large finance segment.',
      },
    ],
  },
  {
    body: [
      { text: 'Currently, it has ' },
      { text: 'four divisions', bold: true },
      { text: ': Aviation, Healthcare, Renewable Energy, and Power.' },
    ],
  },
  {
    body: [
      {
        text:
          'As of March 2022, Aviation comprised 34% of revenue, Healthcare 16%, Renewable Energy 17%, and Power 21%.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'Under new management that took the helm in 2018, GE is undergoing a transition that now includes a strategy to break itself apart.',
      },
      { lineBreak: true },
      { text: 'The plan is to ' },
      { text: 'spin off two major divisions', bold: true },
      { text: ' – healthcare (in 2023) and renewable energy/power (in 2024) – and ' },
      { text: 'focus on aviation.', bold: true },
    ],
  },
  {
    body: [
      { text: 'In the interim, analysts are focused on whether GE will attain its goal of ' },
      { text: '$7B in free cash flow in 2023', bold: true },
      { text: ' (up from $5.5-6.5B in 2022) as well as continue reducing its debt.' },
    ],
  },
  {
    body: [
      { text: 'Increasingly, attention will likely be more focused on ' },
      { text: 'dynamics relating to the aerospace ', bold: true },
      {
        text:
          'sector, including companies like Boeing and Airbus, as GE recasts itself as an aerospace-focused company.',
      },
    ],
  },
  {
    body: [
      { text: 'Check out community Pros and Cons relating to ' },
      { text: 'GE and Boeing', bold: true },
      { text: ' by searching GE in the ticker lookup.' },
    ],
  },
];
export const geBullAndBearContent = [
  {
    body: [
      { text: 'When deciding whether to buy a stock, it’s important to thoroughly ' },
      { text: 'consider *both* the positives and the negatives.', bold: true },
    ],
  },
  {
    body: [
      { text: 'The positives comprise what’s called a ' },
      { text: '“bull” argument for a stock', bold: true },
      { text: ' – why it might rise in price over time; negatives comprise a ' },
      { text: '“bear” argument', bold: true },
      { text: ' – why it might fall in pride over time.' },
    ],
  },
  {
    body: [
      { text: 'Even after buying a stock, it’s important to ' },
      { text: 'continuously monitor', bold: true },
      { text: ' potentially new and emerging negative factors.' },
    ],
  },
  {
    body: [
      { text: 'GE bulls argue that ' },
      { text: 'planned spin-offs could unlock value', bold: true },
      {
        text:
          ' for all three of the divisions, which may trade at discounts to peers. GE Aviation has been compared to Safran and Raytheon (',
      },
      { text: 'RTX' },
      { text: '), GE Healthcare to Philips (' },
      { text: 'PHG' },
      {
        text:
          ') and Siemens Healthineers, and GE Renewable Energy and Power to Mitsubishi Heavy Industries and Siemens Gamesa Renewable Energy.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'If each of these divisions were to re-rate in line with their peers, analysts have estimated that the sum-of-the-parts could significantly exceed GE’s valuation.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'Meanwhile, a persistent bear, JP Morgan’s Stephen Tusa, argues otherwise. In the past, he argued that the power division, in particular, is overvalued.',
      },
    ],
  },
  {
    body: [
      { text: 'In JP Morgan’s view, that division could still be ' },
      { text: 'saddled with bad deals', bold: true },
      { text: ' made in years past, and ' },
      { text: 'profitability overall may be challenged', bold: true },
      { text: ' as the renewable sector evolves; thus it may not yet merit a re-rating.' },
    ],
  },
  {
    body: [
      { text: 'Regardless, as GE goes through with the spin-offs, tracking dynamics relating to Boeing (' },
      { text: 'BA' },
      { text: ') and Airbus will grow in importance as GE ' },
      { text: 'recasts itself as an aerospace-focused company', bold: true },
      { text: '.' },
    ],
  },
];
export const geCompetitorsContent = [
  {
    body: [
      { text: 'GE has' },
      { text: ' three primary divisions:', bold: true },
      { text: ' GE Aviation, GE Healthcare, and GE Renewable Energy.' },
    ],
  },
  {
    body: [
      { text: 'GE Aviation ' },
      { text: 'manufactures and services jet engines', bold: true },
      { text: '. Its competitors include Boeing (' },
      { text: 'BA' },
      { text: '), Safran, and Raytheon (' },
      { text: 'RTX' },
      {
        text:
          ') (via its Pratt & Whitney subsidiary) – a complicating dynamic is that GE has joint ventures with Pratt & Whitney.',
      },
    ],
  },
  {
    body: [
      { text: 'GE Healthcare provides ' },
      { text: 'medical technology and digitization', bold: true },
      { text: ' and competes with Philips (' },
      { text: 'PHG' },
      { text: ') and Siemens Healthineers, among others.' },
    ],
  },
  {
    body: [
      { text: 'GE Renewable Energy and Power specializes in ' },
      { text: 'wind, solar, and hydroelectric solutions', bold: true },
      {
        text:
          ' for power generation. It competes with Mitsubishi Heavy Industries and Siemens Gamesa Renewable Energy.',
      },
    ],
  },
  {
    body: [
      { text: 'As GE goes through with the spin-offs, though, ' },
      { text: 'tracking dynamics relating to Boeing and Airbus ', bold: true },
      { text: 'will grow in importance as GE recasts itself as an aerospace-focused company.' },
    ],
  },
];
export const stockMarketCatalystsContent = [
  {
    body: [
      { text: 'Catalysts are important concepts in stocks as they can ' },
      { text: 'signal ', bold: true },
      { text: 'long-term and/or sudden ' },
      { text: 'changes in trends', bold: true },
      { text: '.' },
    ],
  },
  {
    body: [
      { text: 'In science, a catalyst refers to a substance that can ' },
      { text: 'increase the rate of a chemical reaction.', bold: true },
      { text: '' },
      { lineBreak: true },
      { text: 'As it relates to investing, a catalyst is used to describe ' },
      { text: 'an event or development that can impact a stock’s price', bold: true },
      { text: ', either positively or negatively.' },
    ],
  },
  {
    body: [
      { text: 'Some catalysts are ' },
      { text: 'company-specific', bold: true },
      { text: '. One example could be an ' },
      { text: 'earnings report.', bold: true },
      { lineBreak: true },
      { text: 'If a company exceeds earnings expectations on a change that was ' },
      { text: 'underappreciated or a surprise ', bold: true },
      { text: 'to investors, that catalyst could cause a stock to go up.' },
    ],
  },
  {
    body: [
      { text: 'Some catalysts can impact ' },
      { text: 'markets more broadly', bold: true },
      { text: '. For example, an ' },
      { text: 'unexpected Federal Reserve announcement ', bold: true },
      {
        text:
          'or a move by producers to expand or contract oil production can move large segments of stocks or even an entire market.',
      },
    ],
  },
  {
    body: [
      { text: 'Many investors ' },
      { text: 'seek to identify potential catalysts in advance', bold: true },
      { text: '. They take positions that may benefit if the catalyst occurs.' },
    ],
  },
  {
    body: [
      { text: 'The thinking is: ' },
      { text: 'this could ultimately happen', bold: true },
      {
        text:
          ', and if it does, it will impact the stock’s valuation (price) as other investors realize it. Finding potential catalysts is a bedrock principle for many institutional investors.    ',
      },
    ],
  },
  {
    body: [
      {
        text:
          'Some catalysts are relatively simple to identify: a failed product launch negatively impacts earnings. Some take a deeper understanding of a company’s ecosystem. You can',
      },
      { text: ' start to identify catalysts by diving into the pros and cons', bold: true },
      { text: ' of tickers on Tornado.com.' },
    ],
  },
];
export const companyDebtContent = [
  {
    body: [
      { text: 'Investor Warren Buffett has identified ' },
      { text: 'debt as a top issue for assessing risk', bold: true },
      {
        text:
          '. If a company has too much debt, in an economic downturn it could have trouble making debt payments and risk bankruptcy.',
      },
    ],
  },
  {
    body: [
      { text: 'Key measures of debt are ' },
      { text: 'how much debt', bold: true },
      { text: ' a company has and ' },
      { text: 'whether it’s short-term', bold: true },
      { text: ' (has to be paid within a year) ' },
      { text: 'or long-term', bold: true },
      { text: ' (paid over multiple years).' },
      { lineBreak: true },
      { text: 'The ' },
      { text: 'interest rate ', bold: true },
      { text: 'on the debt is also important as this impacts the ' },
      { text: 'interest expense', bold: true },
      { text: ' a company has to pay.' },
    ],
  },
  {
    body: [
      { text: 'Information on debt and interest expense can be found in ' },
      { text: 'annual (form 10-K) and quarterly (form 10-Q) filings with the SEC', bold: true },
      { text: '. These include discussions on overall ' },
      { text: 'liquidity', bold: true },
      { text: ' (money the company has to finance its operations), total debt levels, and interest expense.' },
    ],
  },
  {
    body: [
      { text: 'Investors use a number of ' },
      { text: 'financial ratios', bold: true },
      { text: ' to assess debt. These include the ' },
      { text: 'interest coverage ratio', bold: true },
      { text: ', which helps determine a company’s ability to pay down its debt, and the ' },
      { text: 'debt-to-capitalization', bold: true },
      { text: ' ratio, which tells how much debt a company has relative to its total market value.' },
    ],
  },
  {
    body: [
      { text: 'There are ' },
      { text: 'many caveats. ', bold: true },
      {
        text:
          'For example, many companies have debt-to-cap ratios of over 100x. Even Home Depot is over 30x. There are also companies with very little or even no debt. The company’s ',
      },
      { text: 'business and strategy are key factors', bold: true },
      { text: '.' },
    ],
  },
  {
    body: [
      { text: 'Overall, understanding debt – and a ' },
      { text: 'company’s ability to generate cash to pay for it', bold: true },
      {
        text:
          ' – is a key concept as you assess companies. Find more by checking out stock ticker profiles on Tornado.',
      },
    ],
  },
];
export const cashFlowContent = [
  {
    body: [
      {
        text:
          'As Professor Damodaran of New York University says: traders focus on measures such as P/E (Price-earnings ratio), while ',
      },
      { text: 'investors focus on cash flows', bold: true },
      { text: '.' },
    ],
  },
  {
    body: [
      { text: 'Why? As Warren Buffett explains, ' },
      { text: "cash flows are critical to determining a business's worth", bold: true },
      {
        text:
          ', or its "intrinsic value." Buying shares in a business for less than intrinsic value is a key to his style of investing.',
      },
    ],
  },
  {
    body: [
      { text: 'One commonly used measure is ' },
      { text: 'Free Cash Flow (FCF)', bold: true },
      {
        text:
          '. It’s what a business generates from its operations after investments to run and grow its business. It’s calculated by taking operating earnings and subtracting capital expenditures.',
      },
    ],
  },
  {
    body: [
      { text: 'Investors are highly attuned to Free Cash Flow. It can be ' },
      { text: 'used to enhance shareholder returns', bold: true },
      { text: ' via stock repurchases or increasing dividends.' },
      { lineBreak: true },
      { text: 'Improvements in FCF can also ' },
      { text: 'signal a company is getting more efficient', bold: true },
      { text: ' at running its business; declines can imply the opposite.' },
    ],
  },
  {
    body: [
      { text: 'However, ' },
      { text: 'cash flow declines are not always negative.', bold: true },
      {
        text:
          ' A company could be undergoing reinvestment that will ultimately compound. So it’s crucial to go beyond the metrics and understand what the company is actually doing.',
      },
    ],
  },
  {
    body: [
      { text: 'You can ' },
      { text: 'follow trends in cash flow', bold: true },
      { text: " in a company's " },
      { text: 'financial statements', bold: true },
      { text: '.' },
      { lineBreak: true },
      { text: 'In addition, ' },
      { text: 'company earnings calls ', bold: true },
      { text: 'will often discuss cash generation and what a company intends to do with its cash.' },
    ],
  },
  {
    body: [
      { text: 'You can also ' },
      { text: 'forecast FCF', bold: true },
      { text: ' to determine ' },
      { text: 'what a business might be worth', bold: true },
      { text: '. Remember that inflation makes future cash flows worth less, so they have to be discounted. ' },
    ],
  },
];
export const cashFlowAndValuationContent = [
  {
    body: [
      {
        text:
          'What’s a business really worth? As Warren Buffett has explained, one way to determine that is to estimate its',
      },
      { text: ' intrinsic value', bold: true },
      { text: '.' },
    ],
  },
  {
    body: [
      { text: 'According to Buffett, “If we could see, in looking at any business, ' },
      { text: 'what its future cash flows would be', bold: true },
      {
        text:
          ', and discount that back at an appropriate interest rate, that would give us a number for intrinsic value.”',
      },
    ],
  },
  {
    body: [
      { text: 'How to arrive at intrinsic value? The ' },
      { text: 'first step is to estimate cash flow generation', bold: true },
      {
        text:
          ' over the long term. You can use financial statements to calculate annual free cash flow (operating earnings minus capital expenditures) and then extrapolate a reasonable annual growth rate.',
      },
    ],
  },
  {
    body: [
      { text: 'The ' },
      { text: 'next step is to discount the total cash ', bold: true },
      {
        text:
          'that may be generated over the time frame you want to work with – 10 years, 30 years, or even forever. Remember that inflation reduces the value of future cash flows.',
      },
    ],
  },
  {
    body: [
      { text: 'The process is referred to as ' },
      { text: 'Discounted Cash Flow (DCF) valuation', bold: true },
      {
        text:
          '. The results can vary widely, depending on your assumptions for the variables - including the number of years you’re discounting and the rate you’re applying.',
      },
      { lineBreak: true },
      { text: 'DCF is a common yet ' },
      { text: 'highly nuanced concept ', bold: true },
      { text: 'for professional investors.' },
    ],
  },
  {
    body: [
      {
        text:
          'While DCF valuation can seem arcane, the process can help you assess a company’s ability to generate cash, as well as whether future growth is ',
      },
      { text: 'underappreciated, fully appreciated,', bold: true },
      { text: ' ' },
      { text: 'or too expensive. ', bold: true },
    ],
  },
];
export const marketCrashesContent = [
  {
    body: [
      { text: 'A stock market “crash” is typically defined as a ' },
      { text: 'sudden and dramatic decline', bold: true },
      { text: ' in the stock market – it could be a double-digit decline in just a few days. ' },
      { text: 'Think 1929', bold: true },
      { text: '.' },
    ],
  },
  {
    body: [
      { text: 'A crash differs from both a ' },
      { text: 'correction, defined as a 10-20% decline', bold: true },
      { text: ' and a ' },
      { text: 'bear market', bold: true },
      { text: ', typically defined as a' },
      { text: ' decline of 20% or more', bold: true },
      { text: '. The primary difference is in a crash’s short duration and ferocity.' },
    ],
  },
  {
    body: [
      { text: 'After the Crash of 1929, sustained stock market declines ranged from a ' },
      { text: 'few months to a couple of years', bold: true },
      {
        text: '. The biggest one-year drop in the S&P 500 was 43% in 1931. The biggest one-year gain was 52% in 1954.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'The unpredictability of crashes in particular is an argument against market timing. As famed investor Bill Miller has noted, in the post-war period, the US stock market has ',
      },
      { text: 'gone up in approximately 70% of years', bold: true },
      { text: '.' },
    ],
  },
  {
    body: [
      {
        text:
          'That\'s why there\'s an expression, "Time in the market, not timing the market." Said another way, for most investors, it may be ',
      },
      { text: 'better to stay invested', bold: true },
      { text: ' than to try to move in and out ahead of crashes and corrections.' },
    ],
  },
  {
    body: [
      { text: 'You can check out ' },
      { text: 'historical returns', bold: true },
      { text: ' by looking up tickers like SPY in the search field on Tornado.' },
    ],
  },
];
export const expenseRatiosContent = [
  {
    body: [
      { text: 'While ' },
      { text: 'ETFs and index funds do not require cash outlays', bold: true },
      { text: ', they do ' },
      { text: 'include fees', bold: true },
      { text: ' known as expense ratios.' },
    ],
  },
  {
    body: [
      { text: 'Expense ratios reflect ' },
      { text: 'costs and expenses to manage the fund or ETF', bold: true },
      {
        text:
          ' including marketing and paying staff. They can be found on provider websites and in investor materials including',
      },
      { text: ' filings with the Securities and Exchange Commission.', bold: true },
    ],
  },
  {
    body: [
      {
        text:
          'For index funds, expense ratios can be modest. Near the low end of the range is SPY, an index fund that tracks the S&P 500, which charges 9 basis points (bps), or just under 0.1%. Most expense ratios are higher.',
      },
      { lineBreak: true },
      { text: 'Though rare, some ETFs can charge 1000 bps (10%) per year or more in expenses.' },
    ],
  },
  {
    body: [
      { text: 'The key thing to consider: while you don’t pay an expense ratio in cash, it ' },
      { text: 'reduces your annual return.', bold: true },
      { text: ' For example, over 10 years, at a 10% annualized return, an ETF may generate a 259% return.' },
      { lineBreak: true },
      { text: 'A 100 bps expense ratio (1%) would reduce the return to 236%, or ~10% over 10 years.' },
    ],
  },
  {
    body: [
      { text: 'Aside: “bps” is an abbreviation for “basis points.” ' },
      { text: 'One basis point is equivalent to 1/100th of 1% ', bold: true },
      { text: 'or a percentage point.' },
    ],
  },
  {
    body: [
      { text: 'The bottom line? ' },
      { text: 'Pay attention to ETF expense ratios.', bold: true },
      { text: ' Tornado can help you identify expense ratios for ETFs and index funds.' },
      { text: ' Check out Pros and Cons posted by Community Thought Leaders (CTLs) in your feed. ' },
    ],
  },
];
export const capitalGainsContent = [
  {
    body: [
      { text: 'A capital gain is the ' },
      { text: 'profit earned on the sale', bold: true },
      { text: ' of an asset that has increased in value.' },
    ],
  },
  {
    body: [
      { text: 'If you own and subsequently sell a stock, the ' },
      { text: 'amount by which the sale proceeds exceed how much you paid', bold: true },
      { text: ' is the capital gain.' },
    ],
  },
  {
    body: [
      { text: 'Capital gains are ' },
      { text: 'subject to tax', bold: true },
      {
        text:
          '. If you hold a stock for under a year and sell it, you will generally pay higher taxes than if you held it for over a year. The capital gain will be taxed as ordinary income for such short-term gains.',
      },
    ],
  },
  {
    body: [
      { text: 'If you have ' },
      { text: 'losses', bold: true },
      { text: ' on a stock, they ' },
      { text: 'can be used to offset capital gains', bold: true },
      {
        text:
          ' in a given year. Some investors intentionally sell investments at a loss in order to lower taxes, a concept known as "tax-loss harvesting."',
      },
    ],
  },
  {
    body: [
      {
        text:
          'If you have more losses than gains, you can sell more than your gains to reduce ordinary income. The limit: only ',
      },
      { text: '$3,000 in net losses', bold: true },
      { text: ' can be used to offset ordinary income each year.' },
    ],
  },
  {
    body: [
      { text: 'Note that' },
      { text: ' frequent trading', bold: true },
      { text: ' may incur ' },
      { text: 'higher taxes ', bold: true },
      { text: 'and could ' },
      { text: 'drive underperformance', bold: true },
      { text: '. In addition, it ' },
      { text: 'may result in “wash sales”', bold: true },
      { text: ' which are losses that do not qualify for capital gains offsets.' },
      { lineBreak: true },
      { text: 'Learn more about companies by checking out Pros and Cons for specific tickers.' },
    ],
  },
];
export const riskToleranceContent = [
  {
    body: [
      { text: 'Risk tolerance is a fancy way of saying ' },
      { text: '“what’s your stomach for losses?”', bold: true },
      { text: ' It’s a really good question – and a uniquely personal one – as you start investing in stocks.' },
    ],
  },
  {
    body: [
      { text: 'Risk tolerance also gets to ' },
      { text: 'how you feel about volatility', bold: true },
      {
        text:
          '. Do you have a trader or an investor mindset? How will you react if a stock goes down 10% or 20% after you buy it – even if you have no intention to sell? ',
      },
      { text: 'Will it affect your mood? ', bold: true },
      { text: 'Ruin your week?' },
    ],
  },
  {
    body: [
      {
        text: 'As Warren Buffett’s partner Charlie Munger said, “If you’re not willing to react with equanimity to a ',
      },
      { text: 'market price decline of 50%', bold: true },
      { text: ' two or three times a century, you’re ' },
      { text: 'not fit to be a common shareholder', bold: true },
      { text: '.”' },
    ],
  },
  {
    body: [
      { text: 'While that may seem extreme, it’s wise to ask yourself and' },
      { text: ' see how you react to changes', bold: true },
      {
        text:
          ' in stock prices. A quick look at any 2-year stock chart will illustrate how much stocks move up / down, even if they ascend over time.',
      },
    ],
  },
  {
    body: [
      { text: 'Once you’re clear about your tolerance for risk, you ' },
      { text: 'can address it in many ways:', bold: true },
      {
        text:
          ' you can keep your stock investments small as a percent of your net worth; you can gear your investments to large, stable companies vs. small, untested companies.',
      },
    ],
  },
  {
    body: [
      { text: 'You can also ' },
      { text: 'use diversification ', bold: true },
      { text: 'by spreading your exposure over a wide range of stocks. ' },
      { text: 'Market ETFs', bold: true },
      {
        text:
          ' such as the SPY and QQQ can help accomplish this, for example. You can learn more about investment choices and risk on the Tornado blog and in the feed. ',
      },
    ],
  },
];
export const sizingStockPositionsContent = [
  {
    body: [
      { text: 'Position sizing refers to the ' },
      { text: 'amount you’re willing to put into a single investment', bold: true },
      { text: ' in the context of a broader portfolio.' },
    ],
  },
  {
    body: [
      { text: 'Famous investor Stanley Druckenmiller argues, “Sizing is' },
      { text: ' 70% to 80% of the equation', bold: true },
      { text: '. It’s not whether you’re right or wrong, it’s ' },
      { text: 'how much you make when you’re right…', bold: true },
      { text: 'and ' },
      { text: 'how much you lose when you’re wrong', bold: true },
      { text: '.”' },
    ],
  },
  {
    body: [
      { text: 'Sizing is often associated with ' },
      { text: 'trading strategies', bold: true },
      {
        text:
          ', where investors may take large positions on high-conviction ideas for short- or intermediate-term gains. It also has ',
      },
      { text: 'practical applications for long-term investing', bold: true },
      { text: '.' },
    ],
  },
  {
    body: [
      { text: 'For example, sizing can ' },
      { text: 'smooth performance across economic cycles', bold: true },
      {
        text:
          '. During periods of economic weakness, some investors prefer to own larger-sized positions in things that consumers need: utilities, consumer staples, and health care.',
      },
    ],
  },
  {
    body: [
      { text: 'Sizing can' },
      { text: ' ', bold: true },
      { text: 'be used to ' },
      { text: 'manage risk within a portfolio. ', bold: true },
      {
        text:
          'Investor David Rolfe  that any stock in a larger portfolio, say 15-25 stocks, should not exceed 8%-10% of the total.',
      },
      { lineBreak: true },
      { text: 'That ' },
      { text: 'can minimize losses', bold: true },
      { text: ' if a single investment experiences a significant decline.' },
      { lineBreak: true },
    ],
  },
  {
    body: [
      { text: 'Sizing can be also used to ' },
      { text: 'optimize returns. ', bold: true },
      {
        text:
          'Investors can keep positions small in difficult markets (potentially losing less) or take larger positions in more favorable markets (potentially making more).',
      },
      { lineBreak: true },
      {
        text:
          'Many famous investors including Warren Buffett run highly concentrated portfolios that focus on their top ideas. ',
      },
    ],
  },
];

export const benzingaContent = [
  {
    body: [
      {
        text:
          'Staying on top of financial news and trading ideas is essential for investors to make informed and strategic investing decisions. ',
      },
      { lineBreak: true },
      { text: 'Services like Benzinga Pro can build on top of your Tornado experience.' },
      { lineBreak: true },
    ],
  },
  {
    body: [
      {
        text:
          'Benzinga Pro provides detailed research and analysis, including earnings reports, analyst ratings, and financial statements.',
      },
      { lineBreak: true },
      {
        text:
          'Such comprehensive data empowers investors to conduct thorough due diligence and evaluate the financial health and growth prospects of potential investments.',
      },
    ],
  },
  {
    body: [
      {
        text:
          'Benzinga Pro also offers trading ideas and alerts, such as breaking news notifications, unusual options activity, and insider trading alerts.',
      },
      { lineBreak: true },
      {
        text:
          'These insights can provide investors with valuable signals and potential investment opportunities that you might otherwise miss.',
      },
      { lineBreak: true },
    ],
  },
  {
    body: [
      {
        text:
          'Combining a service like Benzinga Pro with your Tornado investing experience can save time and effort in gathering and analyzing financial news and research. ',
      },
    ],
  },

  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];

export const arroContent = [
  {
    body: [
      {
        text:
          "No matter where you're at in your financial journey, learning how to build credit is key to a healthy financial future.",
        bold: true,
      },
      { lineBreak: true },
      {
        text:
          'Whether you’re looking to get credit for the first time or trying to rebuild your credit score, here are a few things to keep in mind.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Many people don’t think about their credit score until they need it.',
        bold: true,
      },
      { lineBreak: true },
      {
        text:
          'When you want to finance a car or a house, your credit score is one of the first things lenders look at—among others—to determine whether to approve your loan and how much interest to charge you.',
      },
    ],
  },
  {
    body: [
      {
        text: 'A few effective ways to build credit include:',
        bold: true,
      },
      { lineBreak: true },
      {
        text: '- Paying down existing debt',
      },
      {
        isNewLine: true,
      },

      {
        text: '- Becoming an authorized user on the credit card of someone you trust',
      },
      {
        isNewLine: true,
      },

      {
        text: '- Making payments on outstanding loans and other bills',
      },
      {
        isNewLine: true,
      },

      {
        text: '- Making on-time payments on a credit card',
      },
    ],
  },
  {
    body: [
      {
        text: 'How to improve your credit score:',
        bold: true,
      },
      { lineBreak: true },
      {
        text: '- Use a credit card responsibly. Keep your balance low (or zero!) and make all payments on time.',
      },
      {
        isNewLine: true,
      },
      {
        text: '- Check your credit report for errors regularly.',
      },
      {
        isNewLine: true,
      },
      {
        text: '- Never close your oldest credit card. Length of credit history is also a factor!',
      },
    ],
  },
  {
    body: [
      {
        text: "Building credit takes time and patience, but it's well worth it in the long run.",
        bold: true,
      },
      { lineBreak: true },
      {
        text:
          'A good credit score can give you access to lower interest rates on loans and credit cards, saving you thousands of dollars over the life of a loan.',
      },
    ],
  },
  {
    body: [
      {
        text: 'Arro is a credit program designed to be fair and inclusive.',
      },
      { lineBreak: true },
      {
        text: 'Arro offers a ',
      },
      {
        text: 'low interest credit card with in-app personal finance activities ',
        bold: true,
      },
      {
        text: 'designed to empower you to improve your financial wellness through learning and goal setting.',
      },
    ],
  },
  {
    type: LEARN_CONTENT_TYPES.productPartnerOutro,
  },
];

export const templateContent = [
  {
    body: [
      {
        text: '',
      },
      {
        text: '',
        bold: true,
      },
      {
        text: '',
      },
    ],
  },
  // {
  //   type: LEARN_CONTENT_TYPES.productPartnerOutro,
  // },
];
