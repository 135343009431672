import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import * as Actions from '../../actions/index';

import PeopleYouMayKnowContainer from './PeopleYouMayKnow/PeopleYouMayKnowContainer';
import ConnectionsHeaderComponents from './ConnectionsHeaderComponents';
import PendingConnectionsContainer from './PendingConnectionsContainer';
import ConnectionsTable from './ConnectionsTable';

import NeverShowDollarAmt from '../UI/Disclaimers/NeverShowDollarAmt';

import PageLoading from '../../components/PageLoading';
import InviteFriendsMessaging from '../UI/InviteFriendsMessaging';

import {
  returnCurrentUserId,
  shouldSeeDashboardMessaging,
  isReferralProgramActive,
} from '../../helpers/currentUserHelpers';
import { isConnectionsDataLoadingForUser } from '../../helpers/userConnectionsHelpers';

class PeopleContainer extends React.PureComponent {
  componentDidMount() {
    document.title = `${PRODUCT_DISPLAY_NAME} - Connections`;
    this._logPageView();
  }

  render() {
    return (
      <div className={'people-page-container'}>
        {shouldSeeDashboardMessaging(this._returnCurrentUser()) && isReferralProgramActive(this.props.currentUser) && (
          <InviteFriendsMessaging additionalClassNames={''} viewingContext="Connections Page" />
        )}
        <PeopleYouMayKnowContainer />
        <ConnectionsHeaderComponents />
        <NeverShowDollarAmt />
        {this._renderPendingConnectionRequests()}
        <ConnectionsTable />
      </div>
    );
  }

  _isLoadingConnections = () =>
    isConnectionsDataLoadingForUser(this._returnCurrentUserId(), this._returnUserConnectionsStore());

  _returnUserConnectionsStore = () => this.props.userConnections;

  _renderLoading = () => {
    return <PageLoading />;
  };

  _renderPendingConnectionRequests = () => {
    return (
      <div className={'pending-connections-request-panel'}>
        <PendingConnectionsContainer />
      </div>
    );
  };

  _returnCurrentUserId = () => returnCurrentUserId(this._returnCurrentUser());

  _returnCurrentUser = () => this.props.currentUser;

  _logPageView = () => {
    const event = 'View My Connections';
    this.props.actions.logMetricsTrackingEvent(event);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    users: state.users,
    userConnections: state.userConnections,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeopleContainer);
