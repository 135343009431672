import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { createRecurringDeposit, depositFunds, getBankAccounts } from '../../../actions';
import { LoadingPage } from '../../components/page';
import {
  Body5,
  FloatingLabelTextInput,
  createDropdownItem,
  FloatingLabelDropdown,
  INPUT_MASK_TYPES,
} from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { useBankAccounts } from '../../hooks/funding/useBankAccounts';
import UnstyledLoadingSpinner from '../../components/Images/UnstyledLoadingSpinner';
import { DepositAmountPicker } from '../../components/funding/DepositAmountPicker';
import { customParseFloat } from '../../../helpers/numberHelpers';
import { formatLocaleString } from '../../../helpers/generalHelpers';
import { TrackingEvents } from '../../../utils/tracking/events';
import { useFundingBonus } from '../../hooks/funding/useFundingBonus';
import { LinkBankButton } from '../buttons';
import { Plaid } from '../../pages/funding/interstitial/Plaid';
import { createTimeInstance, formatLocalizedDateTime } from '../../../helpers/timeHelpers';

const DepositPickerWrapper = styled.div`
  margin-left: -16px;
`;
const InputWrapper = styled.div`
  position: relative;
  padding-top: 16px;

  select {
    border-top: none;
    border-right: none;
    border-left: none;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
  input {
    border: none;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
`;
const Actions = styled.div`
  padding-top: 24px;
  text-align: center;
`;
const LoadingBankAccountsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  svg {
    height: 16px;
    width: 16px;
  }
`;
const CustomAmountBonusWrapper = styled.div`
  padding-top: 8px;
`;
export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const massageFormData = (v) => {
  return {
    ...v,
    amount: customParseFloat(v.amount),
  };
};

const DepositFormOnly = ({
  showLinkBankAccountButton,
  initialFormValues,
  showDepositPicker,
  onContinue,
  trackingMetadata,
  buttonProps,
  onPlaidReauthRequired,
  onApiFailure,
  allowEmailAuth,
}) => {
  const dispatch = useDispatch();
  const bankAccounts = useBankAccounts();

  const [isReady, setIsReady] = React.useState(true);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const [isLoadingBankAccounts, setIsLoadingBankAccounts] = React.useState(true);
  const [value, setValue] = React.useState(initialFormValues?.value || '');
  const [bankAccountId, setBankAccountId] = React.useState(initialFormValues?.account_id || '');
  const [fundingOperation] = React.useState('deposit');
  const { bonusAmount } = useFundingBonus(value);

  const recurringAmount = initialFormValues?.recurringAmount;

  React.useEffect(() => {
    const init = async () => {
      const { ok } = await getBankAccounts()(dispatch);
      if (ok) {
        setIsLoadingBankAccounts(false);
      } else {
        setTimeout(init, 1000);
      }
    };
    if (bankAccounts.length === 0) {
      init();
    } else {
      setIsLoadingBankAccounts(false);
    }
  }, []);

  React.useEffect(() => {
    if (bankAccounts.length === 1 && bankAccountId !== bankAccounts[0].id) setBankAccountId(bankAccounts[0].id);
  }, [bankAccountId, bankAccounts]);

  const handleBankAccountChange = (n) => (v) => {
    setBankAccountId(v);
  };
  const handleSelectDepositAmount = (v) => {
    setValue(v);
  };

  const handleChange = React.useCallback(
    (n) => (v) => {
      setValue(v);
    },
    [setValue]
  );

  const submitForm = React.useCallback(
    async (values) => {
      const apiFormData = massageFormData(values);
      setIsSubmitting(true);

      if (recurringAmount > 0) {
        const recurringForm = {
          amount: recurringAmount,
          start_date: formatLocalizedDateTime('api2', createTimeInstance().add(1, 'month')),
          account_id: bankAccountId,
        };
        const { ok, errorMessage } = await createRecurringDeposit(recurringForm, allowEmailAuth)(dispatch);
        if (ok) {
          TrackingEvents.funding.RECURRING_DEPOSIT_ADDED_SUCCESS.send({
            Amount: recurringAmount,
            'Lesson ID': trackingMetadata?.lessonId || null,
          });
        }
      }

      return depositFunds(apiFormData, allowEmailAuth)(dispatch);
    },
    [bankAccountId, recurringAmount]
  );

  const handleSubmit = React.useCallback(async () => {
    TrackingEvents.funding.SUBMIT_DEPOSIT_FORM.send();

    const enteredAmount = value !== '' && customParseFloat(value) > 0;
    if (!enteredAmount) {
      setFormError('You must enter an amount');
      TrackingEvents.funding.SUBMIT_DEPOSIT_FORM_FAILURE.send({
        Reason: 'No amount entered',
      });

      return {
        ok: false,
      };
    }

    if (!bankAccountId) {
      TrackingEvents.funding.SUBMIT_DEPOSIT_FORM_FAILURE.send({
        Reason: 'No bank selected',
      });
      setFormError('You must select a bank account');
      return {
        ok: false,
      };
    }

    const formValues = {
      account_id: bankAccountId,
      amount: value,
      funding_operation: fundingOperation,
    };
    const { ok, noticeMessage, errorMessage, needsPlaidReauth, plaidAccountToken } = await submitForm(formValues);
    if (ok) {
      setIsSubmitting(false);
      TrackingEvents.funding.SUBMIT_DEPOSIT_FORM_SUCCESS.send();
      onContinue(true, { noticeMessage }, value);
    } else if (needsPlaidReauth) {
      TrackingEvents.funding.SUBMIT_DEPOSIT_FORM_FAILURE.send({
        Reason: 'plaid reauth required',
      });
      onPlaidReauthRequired(plaidAccountToken, formValues, () => handleSubmit());
    } else {
      TrackingEvents.funding.SUBMIT_DEPOSIT_FORM_FAILURE.send({
        Reason: 'API Error',
        'Error Message': errorMessage,
      });
      setFormError(errorMessage || 'Something went wrong, please try again');
      onApiFailure && onApiFailure();
      setIsSubmitting(false);
      return {
        ok: false,
      };
    }
  }, [bankAccountId, value]);

  if (!isReady) return <LoadingPage />;

  const bankAccountsItems =
    bankAccounts.length === 1
      ? [
          createDropdownItem({
            text: `${bankAccounts[0].account_type} (...${bankAccounts[0].account_number_ending})`,
            value: bankAccounts[0].id,
          }),
        ]
      : [
          createDropdownItem({ text: 'Please Select...', value: '' }),
          createDropdownItem({ text: '---', value: '---', disabled: true }),

          ...bankAccounts.map((b) => {
            return createDropdownItem({
              text: `${b.account_type} (...${b.account_number_ending})`,
              value: b.id,
            });
          }),
        ];

  return (
    <div>
      <div>
        <InputWrapper>
          {isLoadingBankAccounts ? (
            <div>
              <LoadingBankAccountsWrapper>
                <UnstyledLoadingSpinner />
                <div style={{ paddingLeft: '8px' }}>
                  <Body5>Loading Bank Accounts, please wait...</Body5>
                </div>
              </LoadingBankAccountsWrapper>
            </div>
          ) : (
            <>
              {bankAccounts.length > 0 && (
                <FloatingLabelDropdown
                  name={'bankAccount'}
                  value={bankAccountId}
                  label={'Bank Account'}
                  items={bankAccountsItems}
                  onChange={handleBankAccountChange}
                  error={null}
                />
              )}
              {showLinkBankAccountButton && (
                <div>
                  <LinkBankButton transparent={bankAccounts.length > 0} />
                </div>
              )}
            </>
          )}
        </InputWrapper>
        <InputWrapper>
          <FloatingLabelTextInput
            name={'amount'}
            value={value}
            prefix={'$'}
            label={'Amount'}
            onChange={handleChange}
            maskType={INPUT_MASK_TYPES.dollarAmount}
            error={null}
            autoFocus
          />
          {!isNaN(customParseFloat(bonusAmount)) && customParseFloat(bonusAmount) >= 0 && (
            <CustomAmountBonusWrapper>
              {customParseFloat(bonusAmount) > 0 ? (
                <Body5>{`+ $${formatLocaleString(bonusAmount)} Bonus`}</Body5>
              ) : (
                <Body5>{`Higher Deposit Amount Required For Funding Bonus`}</Body5>
              )}
            </CustomAmountBonusWrapper>
          )}
        </InputWrapper>

        {showDepositPicker && (
          <DepositPickerWrapper>
            <DepositAmountPicker bankAccountId={bankAccountId} value={value} onSelect={handleSelectDepositAmount} />
          </DepositPickerWrapper>
        )}
      </div>

      <Actions>
        <div style={{ paddingTop: '16px' }}>
          <FormState error={formError} isSubmitting={isSubmitting} />

          <SubmitButtonWrapper style={{ opacity: bankAccounts.length === 0 ? 0.6 : 1 }}>
            <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleSubmit} buttonProps={buttonProps}>
              Submit
            </FlatButton>
          </SubmitButtonWrapper>
        </div>
      </Actions>
    </div>
  );
};

const ReauthHandlingDepositForm = (props) => {
  const [bankAccountId, setBankAccountId] = React.useState(false);
  const [reauthToken, setReauthToken] = React.useState(false);
  const [depositCb, setDepositCb] = React.useState(null);
  const [showPlaid, setShowPlaid] = React.useState(false);

  const onPlaidReauthRequired = React.useCallback(
    async (plaidAccountToken, formValues, depositCb) => {
      setReauthToken(plaidAccountToken);
      setBankAccountId(formValues.account_id);
      setDepositCb(depositCb);
    },
    [setReauthToken, setDepositCb, setBankAccountId]
  );

  const handlePlaidCancel = React.useCallback((hadLinkSuccess) => {
    setShowPlaid(false);
  }, []);

  const handlePlaidLinkSuccess = React.useCallback(async (bankAccountId) => {
    depositCb();
  }, []);

  const handleLinkFailure = React.useCallback(() => {
    // error shown in plaid component
  }, []);

  const handleMissingLiveAccount = React.useCallback(() => {
    window.reload();
  }, []);

  return (
    <>
      {showPlaid && (
        <Plaid
          bankAccountId={bankAccountId}
          reauthToken={reauthToken}
          onPlaidContinue={handlePlaidCancel}
          onPlaidLinkSuccess={handlePlaidLinkSuccess}
          onPlaidLinkFailure={handleLinkFailure}
          onMissingLiveAccount={handleMissingLiveAccount}
          inline
        />
      )}
      <DepositFormOnly {...props} onPlaidReauthRequired={onPlaidReauthRequired} />
    </>
  );
};

export const DepositForm = (props) => {
  const { useReauthHandler } = props;

  if (useReauthHandler) {
    return <ReauthHandlingDepositForm {...props} />;
  } else {
    return <DepositFormOnly {...props} />;
  }
};
