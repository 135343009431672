import { currentUserAdapter } from '../adapters/currentUserAdapter';

import { ADD_TOUR_DATA, UPDATE_TOUR_DATA } from '../constants';

export const updateTourStep = (newStep, currentUserId) => {
  return function (dispatch) {
    dispatch({
      type: UPDATE_TOUR_DATA,
      payload: {
        tour_step: newStep,
      },
    });
    const ignoreAuth = true;
    return currentUserAdapter.updateUserProperty({ tour_step: newStep }, ignoreAuth).then((response) => {
      if (response && response.status !== 'updated') {
        // TODO: handle error
      }
      return response && response.status === 'updated';
    });
  };
};

export const endTour = (currentUserId) => {
  return function (dispatch) {
    dispatch({
      type: UPDATE_TOUR_DATA,
      payload: {
        is_on_tour: false,
      },
    });
    const ignoreAuth = true;
    return currentUserAdapter.updateUserProperty({ is_on_tour: false }, ignoreAuth).then((response) => {
      if (response && response.status !== 'updated') {
        // TODO: handle error
      }
      return response;
    });
  };
};

export const restartTour = (currentUserId) => {
  return function (dispatch) {
    dispatch({
      type: UPDATE_TOUR_DATA,
      payload: {
        tour_step: 1,
        is_on_tour: true,
      },
    });
    const ignoreAuth = true;
    return currentUserAdapter.updateUserProperty({ is_on_tour: true, tour_step: 1 }, ignoreAuth).then((response) => {
      if (response && response.status !== 'updated') {
        // TODO:
      }
      return response;
    });
  };
};
