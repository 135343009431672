import { doXTimes } from '../../helpers/usefulFuncs';
import { RECURRING_DEPOSIT_RATES } from '../constants';

export const calculateRecurringInvestmentEndingValue = ({
  startingValue,
  recurringDepositAmount,
  recurringRate,
  horizonYears,
  returnRate,
}) => {
  const isMonthly = recurringRate === RECURRING_DEPOSIT_RATES.monthly;

  const P = startingValue;
  const r = returnRate;
  const n = isMonthly ? 12 : 1;
  const t = horizonYears;

  // P = the principal investment amount (the initial deposit or loan amount)
  // recurringDeposit = the monthly deposit
  // r = the annual interest rate (decimal)
  // n = the number of times that interest is compounded per unit t
  // t = the time (months, years, etc) the money is invested or borrowed for

  const timeStepCalculator = (t) => {
    const initialInvestmentValue = P * (1 + r / n) ** (n * t);
    const recurringInvestmentValue = recurringDepositAmount * (((1 + r / n) ** (n * t) - 1) / (r / n));

    return initialInvestmentValue + recurringInvestmentValue;
  };

  const totalValue = timeStepCalculator(t);
  return { totalValue, timeStepCalculator };
};

export const calculateRecurringInvestmentEndingValueManual = ({
  startingValue,
  recurringDeposit,
  recurringRate,
  horizonYears,
  returnRate,
}) => {
  const isMonthly = recurringRate === RECURRING_DEPOSIT_RATES.monthly;
  const steps = isMonthly ? horizonYears * 12 : horizonYears;
  const stepReturn = isMonthly ? returnRate / 12 : returnRate;

  let totalContribution = startingValue;
  let totalValue = startingValue;
  let totalInterest = 0;

  const calcStepData = () => {
    const stepStart = totalValue;
    const contribution = recurringDeposit;
    totalContribution += contribution;

    const interestValue = (stepStart + contribution) * stepReturn;
    totalInterest += interestValue;
    totalValue += contribution + interestValue;
  };
  doXTimes(steps, calcStepData);
  return { totalValue, totalInterest, totalContribution };
};
