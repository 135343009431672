import React from 'react';

export const Spacing = ({ top, bottom, left, right, children, center }) => {
  return (
    <div
      style={{
        paddingTop: `${top || 0}px`,
        paddingBottom: `${bottom || 0}px`,
        paddingLeft: `${left || 0}px`,
        paddingRight: `${right || 0}px`,
        textAlign: center ? 'center' : 'left',
        lineHeight: 1,
      }}
    >
      {children}
    </div>
  );
};
