import React from 'react';
import { connect } from 'react-redux';
import { isCurrentUserSubscriber, isEligibleForSubscription } from '../../helpers/currentUserHelpers';

export class CurrentUserDataWrapper extends React.Component {
  render() {
    return this._isUsingRenderIf() ? this._shouldRenderChildren() && this._renderChildren() : this._renderChildren();
  }

  _renderChildren = () => {
    return <div className="current-user-data-wrapper">{this.childrenWithUserProps()}</div>;
  };

  _isUsingRenderIf = () => this._getRenderIfString();

  _getRenderIfString = () => this.props.renderIf;

  _shouldRenderChildren = () => {
    const currentUser = this.returnCurrentUserData();
    if (this._getRenderIfString() === 'hasAvatar') {
      return !currentUser.avatar_url.includes('missing');
    }
    if (this._getRenderIfString() === 'hasSubscribed') {
      return isCurrentUserSubscriber(currentUser);
    }
    if (this._getRenderIfString() === 'isEligibleSubscriptionsNotSubscribed') {
      return isEligibleForSubscription(currentUser) && !isCurrentUserSubscriber(currentUser);
    }
    if (this._getRenderIfString() === 'isEligibleSubscriptions') {
      return isEligibleForSubscription(currentUser);
    } else {
      return true;
    }
  };

  childrenWithUserProps = () => {
    const currentUser = this.returnCurrentUserData();
    const userId = currentUser.user_id;
    const props = {
      ...currentUser,
      userId,
    };
    return React.Children.map(this._returnChildren(), (child) => React.cloneElement(child, props));
  };

  _returnChildren = () => {
    return Array.isArray(this.props.children) ? this.props.children.filter((child) => child) : this.props.children;
  };

  returnCurrentUserData = () => this.props.currentUser;
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, null)(CurrentUserDataWrapper);
