import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { getBankAccounts } from '../../../actions';
import { BaseButtonWrapper, FlatButton } from '../../components/buttons';
import { useBankAccounts } from '../../hooks/funding/useBankAccounts';
import { Plaid } from '../../pages/funding/interstitial/Plaid';

const Wrapper = styled.div`
  ${BaseButtonWrapper} {
    padding-left: ${({ transparent }) => (transparent ? '0px' : '16px')};
  }
`;
export const LinkBankButtonWrapper = styled.div``;

export const LinkBankButton = ({ onLinkComplete, transparent }) => {
  const dispatch = useDispatch();
  const bankAccounts = useBankAccounts();

  const [showPlaid, setShowPlaid] = React.useState(false);

  const fetchBankAccounts = React.useCallback(async () => {
    const { ok } = await getBankAccounts()(dispatch);
    if (!ok) {
      setTimeout(fetchBankAccounts, 1000);
    }
  }, []);

  React.useEffect(() => {
    if (bankAccounts.length === 0) fetchBankAccounts();
  }, []);

  const startBankLink = () => {
    setShowPlaid(true);
  };

  const handleClick = () => {
    startBankLink();
  };

  const handlePlaidCancel = React.useCallback(() => {
    setShowPlaid(false);
  }, []);

  const handlePlaidLinkSuccess = React.useCallback((hadLinkSuccess) => {
    setShowPlaid(false);
    if (hadLinkSuccess) fetchBankAccounts();
    onLinkComplete && onLinkComplete();
  }, []);

  const handleLinkFailure = React.useCallback((errorMessage) => {
    // plaid component will show modal
  }, []);

  const handleMissingLiveAccount = React.useCallback(() => {
    window.reload();
  }, []);

  return (
    <Wrapper transparent={transparent}>
      {showPlaid && (
        <Plaid
          onPlaidContinue={handlePlaidCancel}
          onPlaidLinkSuccess={handlePlaidLinkSuccess}
          onPlaidLinkFailure={handleLinkFailure}
          onMissingLiveAccount={handleMissingLiveAccount}
          inline
        />
      )}
      <LinkBankButtonWrapper>
        <FlatButton
          className={bankAccounts.length > 0 ? 'has-bank-linked' : 'no-bank-linked'}
          fullWidth={!transparent}
          onClick={handleClick}
          transparent={transparent}
        >
          {bankAccounts.length > 0 ? '+ LINK NEW BANK ACCOUNT' : 'LINK YOUR BANK ACCOUNT'}
        </FlatButton>
      </LinkBankButtonWrapper>
    </Wrapper>
  );
};
