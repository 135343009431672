import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as Actions from '../../../../actions/index';
import LinkTo from '../../../Links/LinkTo';
import { snakeToHyphens } from '../../../../helpers/generalHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';

export class CurrentPriceCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;
    return (
      <td
        className={`security-table-${snakeToHyphens(columnKeyName)}`}
        style={this._doesOpenSecurityPanel() ? {} : { cursor: 'default', pointerEvents: 'none' }}
      >
        {this._isLoading() ? this.props.loadingCellComponent : this._renderCell()}
      </td>
    );
  }

  _doesOpenSecurityPanel = () => this.props.openSecurityPanel !== false;

  _renderCell = () => {
    return this._doesOpenSecurityPanel() ? (
      <LinkTo
        to={this._returnLinkForHandlingClick()}
        className={''}
        additionalClickAction={this.props.logSecurityPanelOpenClick}
        child={this._renderCellContent()}
        noTextDecoration
      />
    ) : (
      <div className="tcell-container">{this._renderCellContent()}</div>
    );
  };

  // hardcoded to false because PriceData component has that data
  _isLoading = () => false;

  _returnLinkForHandlingClick = () => {
    const securityId = this.props.securityId;
    const openSecurityPanelConfig = {
      securityId,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    const securityId = this.props.securityId;

    return <span className={`security-table-current-price`}></span>;
  };
}

const mapStateToProps = (state) => {
  return {
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(CurrentPriceCell);

export default composedComponent;
