import React from 'react';

import { UserAvatarListItem } from './components/UserAvatarListItem';
import { TooltipIconButton } from '../UI/TooltipIconButton';
import { BasicUserList } from './BasicUserList';
import { TooltipBodyOnly } from '../UI/TooltipBodyOnly';

import { logMetricsTrackingEvent } from '../../actions';

export class UserAvatarList extends React.Component {
  constructor() {
    super();
    this.state = {
      _containerWidth: null,
    };
  }

  componentDidMount() {
    this._initListeners();
    this._setContainerWidth(this._calcContainerWidth());
  }

  componentWillUnmount() {
    this._destroyListeners();
  }

  render() {
    const { userIds, getLinkFromUserId, profileLinkQuery, additionalCount, additionalListItem } = this.props;
    const displayUserIds = this.isMoreUsersInListThanMax()
      ? userIds.slice(0, this._returnMaxShowItemsCount())
      : userIds;
    return (
      <div ref={(el) => (this.UserAvatarListContainer = el)} className={`user-avatar-list-container`}>
        {this.state._containerWidth !== null &&
          displayUserIds.map((id, i) => (
            <UserAvatarListItem
              key={id}
              profileLinkQuery={profileLinkQuery}
              zIndex={displayUserIds.length - i + 1}
              zIndexOnHover={displayUserIds.length + 2}
              userId={id}
              linkTo={getLinkFromUserId ? getLinkFromUserId(id) : null}
            />
          ))}
        {this.isMoreUsersInListThanMax() || additionalCount ? (
          <UserAvatarListItem
            profileLinkQuery={profileLinkQuery}
            count={additionalCount > 0 ? additionalCount : userIds.length - this._returnMaxShowItemsCount()}
            zIndex={1}
          />
        ) : null}
        {(this.props.showEllipsisUserListAfter ||
          (this.props.showEllipsisUserListAfterIfExceedMaxShow && this.isMoreUsersInListThanMax())) && (
          <TooltipIconButton
            iconProps={{
              icon: 'fa-ellipsis-h',
              customClass: 'ellipsis-button',
            }}
            additionalClickAction={this.handleEllipsisClick}
          >
            <TooltipBodyOnly bgClassName="component-bg" style={{ zIndex: 50 }}>
              <BasicUserList
                userIds={userIds}
                profileLinkQuery={profileLinkQuery}
                getLinkFromUserId={getLinkFromUserId}
                additionalListItem={additionalListItem}
              />
            </TooltipBodyOnly>
          </TooltipIconButton>
        )}
      </div>
    );
  }

  isMoreUsersInListThanMax = () => this.props.userIds.length > 1 + this._returnMaxShowItemsCount();

  _returnMaxShowItemsCount = () => this.props.maxShowIdCount || 2;

  _returnAvatarWidth = () => 26;

  _calcContainerWidth = () => $(this._returnContainerDOMNode()).width();

  _setContainerWidth = (width) => this.setState(() => ({ _containerWidth: width }));

  _returnContainerDOMNode = () => this.UserAvatarListContainer;

  _handleWindowResize = () => this._setContainerWidth(this._calcContainerWidth());

  _initListeners = () => {
    window.addEventListener('resize', this._handleWindowResize);
  };

  _destroyListeners = () => {
    window.removeEventListener('resize', this._handleWindowResize);
  };

  handleEllipsisClick = () => {
    this.props.logEllipsisClick && this.props.logEllipsisClick();

    // event below will still trigger but is deprecated due to hard coded context that is incorrect.
    // const newEvent = 'Clicked See All Users List';
    const event = 'Clicked See List';
    const properties = {
      Context: 'Ideas Performance Notification',
    };
    logMetricsTrackingEvent(event, properties)();
  };
}
