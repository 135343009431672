import {
  GETTING_ORDER_DATA,
  ADD_ORDER_DATA,
  ADD_PLACED_ORDER,
  CLEAR_ORDER_BASKET,
  UNDO_CLEAR_ORDER_BASKET,
  PLACE_ORDERS,
  CREATE_ORDERS,
  UPDATE_ORDER,
  DELETE_ORDER,
  UNDELETE_ORDER,
  CANCEL_ORDER,
} from '../constants';

const defaultState = {
  isLoading: true,
  isLoaded: false,
  isError: false,
  error: null,

  buying_power: null,
  has_free_trading: null,
  trade_credit: null,

  not_placed_orders: [],
  placed_orders: [],
  awaiting_approval_orders: [],

  placedOrdersLookup: {},

  deleted_orders: [], // used in event delete fails and need to recreate order
};

export default function ordersReducer(state = defaultState, action) {
  let orderId, orderIds, orders, orderData, updatedOrder, orderIndex, ordersBefore, ordersAfter, requiresTradeApproval;

  switch (action.type) {
    case GETTING_ORDER_DATA:
      return {
        ...state,
        isLoading: true,
      };

    case ADD_ORDER_DATA: {
      const orderData = action.payload;
      const placedOrders = orderData.placed_orders;
      const placedOrdersLookup = {};
      placedOrders.forEach((o) => {
        placedOrdersLookup[o.id] = o;
      });
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        ...orderData,
        placedOrdersLookup,
      };
    }

    case ADD_PLACED_ORDER: {
      const orders = action.payload;
      const newOrders = {};
      orders.forEach((order) => {
        newOrders[order.id] = order;
      });
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        placedOrdersLookup: {
          ...state.placedOrdersLookup,
          ...newOrders,
        },
      };
    }

    case CREATE_ORDERS:
      orders = action.payload.orders;
      return {
        ...state,
        not_placed_orders: [...state.not_placed_orders, ...orders],
      };

    case UPDATE_ORDER:
      updatedOrder = action.payload.updatedOrder;
      orderIndex = null;
      state.not_placed_orders.forEach((order, i) => {
        if (order.order_id === updatedOrder.order_id) {
          orderIndex = i;
        }
      });
      ordersBefore = state.not_placed_orders.slice(0, orderIndex);
      ordersAfter = state.not_placed_orders.slice(orderIndex + 1);
      return {
        ...state,
        not_placed_orders: [...ordersBefore, updatedOrder, ...ordersAfter],
      };

    case CANCEL_ORDER: {
      updatedOrder = action.payload.updatedOrder;
      orderIndex = null;
      state.placed_orders.forEach((order, i) => {
        if (order.order_id === updatedOrder.order_id) {
          orderIndex = i;
        }
      });
      if (orderIndex === null) {
        requiresTradeApproval = true;
        state.awaiting_approval_orders.forEach((order, i) => {
          if (order.order_id === updatedOrder.order_id) {
            orderIndex = i;
          }
        });
      }

      if (requiresTradeApproval) {
        ordersBefore = state.awaiting_approval_orders.slice(0, orderIndex);
        ordersAfter = state.awaiting_approval_orders.slice(orderIndex + 1);
        return {
          ...state,
          awaiting_approval_orders: [...ordersBefore, updatedOrder, ...ordersAfter],
        };
      } else {
        ordersBefore = state.placed_orders.slice(0, orderIndex);
        ordersAfter = state.placed_orders.slice(orderIndex + 1);
        return {
          ...state,
          placed_orders: [...ordersBefore, updatedOrder, ...ordersAfter],
        };
      }
    }

    case DELETE_ORDER:
      orderId = action.payload;
      orderData = state.not_placed_orders.filter((order) => order.order_id === orderId);
      return {
        ...state,
        not_placed_orders: state.not_placed_orders.filter((order) => order.order_id !== orderId),
        deleted_orders: [...state.deleted_orders, orderData],
      };

    case UNDELETE_ORDER:
      orderId = action.payload;
      orderData = state.deleted_orders.filter((order) => order.order_id === orderId);
      return {
        ...state,
        not_placed_orders: [...state.not_placed_orders, orderData],
        deleted_orders: state.deleted_orders.filter((order) => order.order_id !== orderId),
      };

    // DEPRECATED
    case CLEAR_ORDER_BASKET:
      return {
        ...state,
        not_placed_orders: [],
        deleted_orders: state.not_placed_orders,
      };

    // DEPRECATED
    case PLACE_ORDERS:
      const placedOrders = action.payload.orders;
      const remainingNotPlacedOrders = state.not_placed_orders.filter((order) =>
        placedOrders.every((placedOrder) => placedOrder.order_id !== order.order_id)
      );
      if (requiresTradeApproval) {
        return {
          ...state,
          not_placed_orders: [...remainingNotPlacedOrders],
          awaiting_approval_orders: [...state.awaiting_approval_orders, ...placedOrders],
        };
      }
      return {
        ...state,
        not_placed_orders: [...remainingNotPlacedOrders],
        placed_orders: [...state.placed_orders, ...placedOrders],
      };

    // DEPRECATED
    case UNDO_CLEAR_ORDER_BASKET: {
      const orderIds = action.payload;
      const deletedOrdersRestoring = state.deleted_orders.filter((order) =>
        orderIds.some((id) => order.order_id === id)
      );
      const deletedOrdersNotRestoring = state.deleted_orders.filter(
        (order) => !orderIds.some((id) => order.order_id === id)
      );
      return {
        ...state,
        not_placed_orders: [...deletedOrdersRestoring, ...state.not_placed_orders],
        deleted_orders: [...deletedOrdersNotRestoring],
      };
    }

    default:
      return state;
  }
}
