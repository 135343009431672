import React from 'react';
import styled from 'styled-components';
import { Page } from '@src/main/components/layout';
import { Container } from '@src/main/components/ui';
import { Body5, H3, H5 } from '@src/main/lib/nvstr-common-ui.es';
import { FlatButton } from '@src/main/components/buttons';
import { sendSupportMessage } from '@src/actions';
import { useDispatch } from 'react-redux';
import { FormState } from '@src/main/components/form/FormState';
import { CheckCircleFilled } from '@src/main/icons';
import { TrackingEvents } from '@src/utils/tracking/events';
import { SupportChatBot } from '@src/main/containers/support/SupportChatBot';
import { DOMAIN } from '@src/appConfig';

const Wrapper = styled.div`
  min-height: 500px;
  flex: 1;
`;

const BulletWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
  cursor: pointer;
`;
const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 24px;
  svg {
    height: 24px;
    width: 24px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const TextWrapper = styled.div`
  flex: 1;
`;

const ChevronRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
    </svg>
  );
};
const ChevronWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  svg {
    height: 33px;
    width: 33px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

const HelpIcon = () => {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z" />
    </svg>
  );
};

const ChatIcon = () => {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2M20 16H5.2L4 17.2V4H20V16Z" />
    </svg>
  );
};
const EmailIcon = () => {
  return (
    <svg viewBox="0 0 24 24">
      <path d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
    </svg>
  );
};

const Bullet = ({ Icon, label, onClick }) => {
  return (
    <BulletWrapper onClick={onClick}>
      <IconWrapper>{Icon}</IconWrapper>
      <TextWrapper>{label}</TextWrapper>
      <ChevronWrapper>
        <ChevronRight />
      </ChevronWrapper>
    </BulletWrapper>
  );
};

const Textarea = styled.textarea`
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.themeColors.text};
  border: 1px solid ${({ theme }) => theme.themeColors.border};
  border-radius: 5px;
  width: 100%;
  min-height: 30vh;
`;

const SendSupportRequest = ({ onCancel, onComplete }) => {
  const dispatch = useDispatch();
  const [messageText, setMessageText] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleTextMessageChange = (e) => {
    setMessageText(e.target.value);
  };

  const handleSubmit = () => {
    if (messageText === '') {
      return;
    }

    TrackingEvents.application.SENT_SUPPORT_MESSAGE.send();
    setIsSubmitting(true);

    sendSupportMessage(messageText)(dispatch).then((response) => {
      setIsSubmitting(false);
      if (response.ok) {
        onComplete();
      } else {
        setError(true);
      }
    });
  };

  return (
    <Page width={'600px'}>
      <Wrapper>
        <H5>Send Us A Message</H5>
        <Container top={8}>
          <Body5>Responses will be sent to your email address registered to your account.</Body5>
        </Container>
        <Container top={24}>
          <Textarea onChange={handleTextMessageChange} value={messageText} placeholder={'Enter message...'} />
        </Container>
      </Wrapper>
      <Container top={24}>
        <FormState error={error} isSubmitting={isSubmitting} />
        <Container row centerAll>
          <FlatButton fullWidth disabled={isSubmitting} onClick={handleSubmit}>
            Submit
          </FlatButton>
        </Container>

        <Container top={8} centerAll>
          <FlatButton transparent fullWidth disabled={isSubmitting} onClick={onCancel}>
            Cancel
          </FlatButton>
        </Container>
      </Container>
    </Page>
  );
};

const MessageCompletedIconWrapper = styled.div`
  padding: 24px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Support = () => {
  const [chatUnavailable, setChatUnavailable] = React.useState(false);
  const [showSendMessage, setShowSendMessage] = React.useState(false);
  const [sentMessage, setSentMessage] = React.useState(false);

  const handleContactUs = () => {
    setShowSendMessage(true);
  };

  const handleFaqsClick = () => {
    window?.open(`https://${DOMAIN}/contact`, '_blank').focus();
  };

  const handleChatClick = () => {
    try {
      window?.zE('messenger', 'open');
    } catch (e) {
      setChatUnavailable(true);
    }
  };

  const handleSendSupportMessageComplete = () => {
    setSentMessage(true);
  };

  const handleSendSupportMessageCancel = () => {
    setShowSendMessage(false);
  };

  const onCompleteClick = () => {
    setSentMessage(false);
    setShowSendMessage(false);
  };

  if (sentMessage) {
    return (
      <Page width={'360px'}>
        <MessageCompletedIconWrapper>
          <CheckCircleFilled />
          <H3>Message Sent</H3>
          <Container top={8} centerAll>
            <Body5 center>Our support team will send you an email response.</Body5>
          </Container>
          <Container top={36} horizontal={28} centerAll fullWidth>
            <FlatButton fullWidth onClick={onCompleteClick}>
              Complete
            </FlatButton>
          </Container>
        </MessageCompletedIconWrapper>
      </Page>
    );
  }

  if (showSendMessage) {
    return (
      <SendSupportRequest onCancel={handleSendSupportMessageCancel} onComplete={handleSendSupportMessageComplete} />
    );
  }

  return (
    <Page width={'600px'}>
      <Wrapper>
        <H5>Need Assistance?</H5>
        <Container top={8}>
          <Body5>We are always happy to help!</Body5>
        </Container>
        <Container top={36}>
          <Body5>We have added tons of information to our FAQs so you have access to instant help.</Body5>
        </Container>
        <Container top={16}>
          <Bullet Icon={<HelpIcon />} label={'FAQs'} onClick={handleFaqsClick} />
          <Bullet Icon={<ChatIcon />} label={'Chat'} onClick={handleChatClick} />
        </Container>
        <Container top={44}>
          <Body5>Unable to find the information you need in the FAQs?</Body5>
        </Container>
        <Container top={16}>
          <Bullet Icon={<EmailIcon />} label={'Contact Us'} onClick={handleContactUs} />
        </Container>
      </Wrapper>
      <SupportChatBot />
    </Page>
  );
};
