import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {
  getCurrentUserIdeas,
  getPlaidInvestmentAccountLinkToken,
  getPlaidInvestmentConfigFromApi,
  linkInvestmentAccountWithPlaid,
  logMetricsTrackingEvent,
  refreshCurrentUserExternalPositionsInfo,
  showModal,
} from '../../actions';
import { ExpandedPageBackground, Page } from '../components/layout';
import { LoadingPage } from '../components/page';
import { H3, Body5 } from '../lib/nvstr-common-ui.es';
import { FlatButton } from '../components/buttons';
import { TrackingEvents } from '../../utils/tracking/events';
import { consoleLog } from '../../helpers/devToolHelpers';
import { createBasicErrorModal } from '../../constants/modals';
import { CheckCircleFilled } from '../icons';
import { ROUTES } from '../../constants/paths';
import { useNavigate, useLocation } from 'react-router-dom';
import { parseQueryString } from '../../helpers/routerHelpers';
import { getPlaidWebhookUrl } from './funding/interstitial/Plaid';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const Message = styled.div`
  padding-top: 24px;
`;
const Actions = styled.div`
  padding-top: 8px;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  h3 {
    margin: 0;
  }
`;
const ActionsWrapper = styled.div`
  padding-top: 24px;
  display: flex;
  justify-content: center;
`;
const IconWrapper = styled.div`
  padding-top: 36px;
  text-align: center;
  svg {
    height: 36px;
    width: 36px;
  }
  path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

const parseQueryForPlaidAccountId = (location) => {
  const query = parseQueryString(location.search);
  return query.a || null;
};

export const PlaidBrokerageLink = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const plaidAccountId = parseQueryForPlaidAccountId(location);
  const isRelink = !!plaidAccountId;

  const [isReady, setIsReady] = React.useState(false);
  const [hidePlaid, setHidePlaid] = React.useState(false);
  const [initFailed, setInitFailed] = React.useState(false);
  const [plaid, setPlaid] = React.useState(null);
  const [isLinkingAccount, setIsLinkingAccount] = React.useState(null);
  const [wasLinkSuccessful, setWasLinkSuccessful] = React.useState(false);

  const onPlaidContinue = () => {
    navigate(ROUTES.PORTFOLIO.build());
  };

  const showError = React.useCallback((errorMessage) => {
    const modal = {
      contentComponent: createBasicErrorModal(errorMessage),
      dismissable: true,
      size: 'wide',
    };
    showModal(modal)(dispatch);
  }, []);

  const handleContinue = React.useCallback(() => {
    return onPlaidContinue(true);
  }, []);

  const handleCancel = React.useCallback(() => {
    return onPlaidContinue(false);
  }, []);

  const onLinkFailedContinue = React.useCallback(() => {
    return onPlaidContinue(false);
  }, []);

  React.useEffect(
    () => {
      if (isReady) {
        plaid?.open();
      }
    },
    [isReady]
  );

  React.useEffect(
    () => {
      if (hidePlaid) {
        plaid?.destroy();
      }
    },
    [hidePlaid]
  );

  React.useEffect(() => {
    return () => {
      plaid?.destroy();
    };
  }, []);

  React.useEffect(() => {
    const setup = async () => {
      try {
        const isCDNLoaded = false; // TODO: rewrite with plaid react sdk
        // const isCDNLoaded = await loadPlaidCDN();
        if (isCDNLoaded) {
          let linkToken = null;

          if (isRelink) {
            // To test in sandbox mode, can reset login by going to:
            // http://localhost:3000/api/investment_accounts/account/:plaid_account_id/invalidate_access_token?format=json
            // then refresh the webpage, you should see the re-link button

            const response = await getPlaidInvestmentAccountLinkToken(plaidAccountId);

            consoleLog({ getPlaidInvestmentAccountLinkToken: response });

            if (response.status === 200) {
              linkToken = response?.data?.public_token;
            } else {
              logMetricsTrackingEvent('Plaid relink failed to get config')();
              setInitFailed(true);
            }
          }

          const response = await getPlaidInvestmentConfigFromApi();
          const ok = response?.status === 200;

          if (!ok) {
            setInitFailed(true);
            return;
          }

          const { environment, public_key, error } = response?.data;

          if (error) {
            setInitFailed(true);
            return;
          }

          let didLinkAnAccount = false;

          const onSuccess = async (public_token, metadata) => {
            didLinkAnAccount = true;
            setIsLinkingAccount(true);
            setHidePlaid(true);

            const properties = {
              'Is Relink': isRelink,
              Type: 'On Success',
              'Link Session Id': metadata.link_session_id,
              'Request Id': metadata.request_id,
            };
            logMetricsTrackingEvent('Plaid Investment Event', properties)();

            let params;
            if (isRelink) {
              params = {
                relink_mode: true,
                account_id: props.plaid_account_id,
                public_token: public_token,
                institution_id: metadata.institution.institution_id,
                institution_name: metadata.institution.name,
                plaid_processor_token: public_token,
              };
            } else {
              params = {
                public_token: public_token,
                account_id: metadata.account.id,
                account_name: metadata.account.name,
                account_type: metadata.account.type,
                account_subtype: metadata.account.subtype,
                account_mask: metadata.account.mask,
                institution_id: metadata.institution.institution_id,
                institution_name: metadata.institution.name,
                plaid_processor_token: public_token,
              };
            }

            const response = await linkInvestmentAccountWithPlaid(params);
            if (response?.status === 200) {
              await refreshCurrentUserExternalPositionsInfo()(dispatch);
              if (!isRelink) await getCurrentUserIdeas()(dispatch); // ideas are currently automatically generated so need to refresh

              setWasLinkSuccessful(true);
            } else {
              const serverError = response?.data?.error;
              const defaultErrorMessage =
                'Something went wrong. Unable to link brokerage account at this time. Please try again later.';
              let errorMessage = defaultErrorMessage;
              if (error === 'The selected account is already linked. Please try a different account.') {
                errorMessage = serverError;
              }
              showError(errorMessage);
              TrackingEvents.liveTradingSignUp.LINK_PLAID_BROKERAGE_ACCOUNT_REQUEST_FAILURE.send({
                'Error Message': serverError || 'unknown',
              });
              onLinkFailedContinue();
            }
          };

          const onExit = async (err, metadata) => {
            // The user exited the Link flow.
            // metadata contains information about the institution
            // that the user selected and the most recent API request IDs.
            // Storing this information can be helpful for support.
            if (err != null) {
              console.error('Plaid API Error', { err, metadata });
              logMetricsTrackingEvent('Plaid API Error', { err, metadata });
              setInitFailed(true);
              return;
            }

            const properties = {
              Type: 'On Exit',
              'Link Session Id': metadata?.link_session_id,
              'Request Id': metadata?.request_id,
            };
            logMetricsTrackingEvent('Plaid Investment Event', properties);

            handleCancel();
          };

          const onEvent = async (eventName, metadata = {}) => {
            // Optionally capture Link flow events, streamed through
            // this callback as your users connect an Item to Plaid.
            // For example:
            // eventName = "TRANSITION_VIEW"
            // metadata  = {
            //   link_session_id: "123-abc",
            //   mfa_type:        "questions",
            //   timestamp:       "2017-09-14T14:42:19.350Z",
            //   view_name:       "MFA",
            // }

            let event = 'Plaid Investment Event';
            const { link_session_id, request_id, error_type, error_code, error_message } = metadata;
            const properties = {
              'Is Relink': isRelink,
              Type: eventName,
              'Link Session Id': link_session_id,
              'Request Id': request_id,
              'Error Type': error_type,
              'Error Code': error_code,
              'Error Message': error_message,
            };
            logMetricsTrackingEvent(event, properties)();
          };

          const instanceData = {
            clientName: 'Tornado',
            env: environment,
            key: public_key,

            selectAccount: true,
            token: linkToken,

            product: ['investments'],
            // accountSubtypes: { investments: ['401k', '529', 'ira', 'brokerage', 'hsa', 'keogh'] },
            linkCustomizationName: 'brokerage_link',

            webhook: getPlaidWebhookUrl(), // Optional – use webhooks to get transaction and error updates
            onSuccess,
            onExit,
            onEvent,
          };
          const plaidInstance = window.Plaid.create(instanceData);
          setPlaid(plaidInstance);
          setIsReady(true);
        } else {
          setInitFailed(true);
          setIsReady(true);
        }
      } catch (e) {
        console.error('Plaid Init Failure catch', e);
        setInitFailed(true);
      }
    };
    setup();

    logMetricsTrackingEvent('View Link Bank Account')();
  }, []);

  if (!isReady) return <LoadingPage />;

  if (initFailed) {
    return (
      <Page width={'376px'}>
        <ContentWrapper>
          <Message>
            <Body5>Something went wrong. The page did not fully load. Try reloading the page.</Body5>
          </Message>
          <Actions>
            <FlatButton fullWidth onClick={() => window.location.reload()}>
              Reload Page
            </FlatButton>
          </Actions>
        </ContentWrapper>
      </Page>
    );
  }

  if (wasLinkSuccessful) {
    return (
      <Page width={'600px'}>
        <Content>
          <IconWrapper>
            <CheckCircleFilled />
            <H3>Brokerage Link Completed</H3>
          </IconWrapper>
          <ActionsWrapper>
            <FlatButton fullWidth onClick={handleContinue}>
              Continue
            </FlatButton>
          </ActionsWrapper>
        </Content>
      </Page>
    );
  }

  if (isLinkingAccount) {
    return <LoadingPage message={'Linking Brokerage Account...'} />;
  }

  return <ExpandedPageBackground />;
};
