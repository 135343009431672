import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../actions/index';

import { returnSecurityIdFromThought } from '../../helpers/thoughtsHelpers';
import ThoughtContainer from '../Thoughts/ThoughtContainer';
import BasicSecurityDataWrapper from '../SecurityData/BasicSecurityDataWrapper';
import { ThoughtType } from '../Thoughts/components/ThoughtType';
import SecurityIdentifierDisplay from '../UI/SecurityIdentifierDisplay';
import { getThoughtImpressionEventName } from '../../constants/tracking';
import { fetchRecentThoughts } from '../../actions/thoughtsActions';
import { LoadingSpinner } from '../../main/lib/nvstr-common-ui.es';
import styled from 'styled-components';

const LoadingWrapper = styled.div`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const ViewpointProfilePosts = ({ viewpointId }) => {
  const dispatch = useDispatch();

  const [thoughts, setThoughts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchThoughts = () => {
      setIsLoading(true);
      const params = {
        viewpointId,
        count: 15,
      };
      fetchRecentThoughts(params)(dispatch).then((response) => {
        const { ok, thoughts, errorMessage } = response;
        if (ok) {
          setIsLoading(false);
          setThoughts(thoughts);
        } else {
          setIsLoading(false);
          setError(errorMessage);
        }
      });
    };

    fetchThoughts();
  }, []);

  const _renderThoughtHeadline = (thought) => {
    return (
      <div className={'thought-feed-thought-headline'}>
        <span style={{ marginRight: '5px' }}>
          <ThoughtType thought={thought} />
        </span>
        <span style={{ marginRight: '5px' }}>for</span>
        <span style={{ marginRight: '-1px' }}>
          <BasicSecurityDataWrapper securityId={returnSecurityIdFromThought(thought)}>
            <SecurityIdentifierDisplay prefix={'('} suffix={')'} showSymbol showName limitNameWidth />
          </BasicSecurityDataWrapper>
        </span>
        <span>from the community</span>
      </div>
    );
  };

  if (isLoading)
    return (
      <LoadingWrapper>
        <LoadingSpinner />
      </LoadingWrapper>
    );

  return (
    <div>
      {thoughts.map((thought, index) => {
        return (
          <div className={'react-thought-notification'} key={thought.id}>
            {_renderThoughtHeadline(thought)}
            <BasicSecurityDataWrapper securityId={returnSecurityIdFromThought(thought)}>
              <ThoughtContainer
                thoughtId={thought.id}
                thought={thought}
                viewingContext={getThoughtImpressionEventName('FEED')}
                scrollContainerSelector={window}
                inFeed={true}
                showAlternateOpinionActionStyle
                isUserAuthed
                hideOpinionCounts
                withThoughtLink={true}
                isSuggestedThought
                useShareInModal
              />
            </BasicSecurityDataWrapper>
          </div>
        );
      })}
    </div>
  );
};

export default ViewpointProfilePosts;
