import React from 'react';

import ColorThemeToggle from '../../UI/ColorThemeToggle';

export const DarkThemeToggleNavItem = (props) => {
  const { className } = props;
  return (
    <div className={className || 'dark-theme-toggle-nav-item'}>
      <div className="default-flex-align-center">
        <span>{'Dark Theme'}</span>
        <span>
          <ColorThemeToggle />
        </span>
      </div>
    </div>
  );
};
