import React from 'react';
import { connect } from 'react-redux';

import { Counter } from '../UI/Counter';
import { customParseFloat } from '../../helpers/numberHelpers';
import { filterArrayForDuplicates } from '../../helpers/generalHelpers';

class PortfolioAnalysisActionsCounter extends React.Component {
  render() {
    return <Counter count={this._returnTradeToOptimizedPositionCount()} className={this.props.className} />;
  }

  _returnTradeToOptimizedPositionCount = () => {
    const portfolio = this._returnPortfolioLookup();
    const portfolioListBySecurityId = Object.keys(portfolio);
    const optimizerResults = this._returnOptimizedPositionsBySecurityId();
    const optimizerResultsBySecurityIdList = Object.keys(optimizerResults);
    const optimizedPortfolioList = optimizerResultsBySecurityIdList.filter((id) => this._hasPositionIn(id));
    const securitiesList = filterArrayForDuplicates([...optimizedPortfolioList, ...portfolioListBySecurityId]);
    const emphasizedTradesCount = securitiesList.filter((id) => this._shouldEmphasizeTrade(id)).length;
    const tradesNeededToOptimizePositionCount = emphasizedTradesCount;
    return tradesNeededToOptimizePositionCount;
  };

  _shouldEmphasizeTrade = (securityId) => {
    // Emphasize trade if trade is 2% > equity, > $250, or no current position
    const valueThreshold = 250;

    const valueOfTrade = this._returnValueOfTrade(securityId);
    const equity = this.props.currentUser.equity;
    const equityThreshold = customParseFloat(equity) * 0.02;

    const isGreaterThanEquityThreshold = equityThreshold < Math.abs(valueOfTrade);
    const isGreaterThanValueThreshold = valueThreshold < Math.abs(valueOfTrade);
    const isEnteringNewPosition =
      !this._hasPositionIn(securityId) && this._returnAllocationSharesForSecurityId(securityId) > 0;
    return isGreaterThanEquityThreshold || isGreaterThanValueThreshold || isEnteringNewPosition;
  };

  _returnValueOfTrade = (securityId) => {
    const userPositions = this._returnPortfolioLookup();
    const currentPositionValue = this._hasPositionIn(securityId) ? userPositions[securityId].value : 0;
    const optimizedAllocationValue = this._returnOptimizedAllocationValue(securityId);
    return Math.abs(optimizedAllocationValue - currentPositionValue);
  };

  _returnAllocationSharesForSecurityId = (securityId) => {
    const positionLookup = this._returnPortfolioLookup();
    return securityId in positionLookup ? positionLookup[securityId].shares : 0;
  };

  _returnOptimizedAllocationSharesForSecurityId = (securityId) => {
    const lookup = this._returnOptimizedPositionsBySecurityId();
    return securityId in lookup ? lookup[securityId].shares : 0;
  };

  _hasPositionAndOptimizedAllocationChanged = (securityId) => {
    const positionLookup = this._returnPortfolioLookup();
    const lookup = this._returnOptimizedPositionsBySecurityId();
    const positionAllocationShares = securityId in positionLookup ? positionLookup[securityId].shares : 0;
    const optimizerAllocationShares = securityId in lookup ? lookup[securityId].shares : 0;
    return positionAllocationShares !== optimizerAllocationShares;
  };

  _hasPositionIn = (securityId) => Math.abs(this._returnAllocationSharesForSecurityId(securityId)) > 0;

  _returnPortfolioStore = () => this.props.portfolio;

  _returnPositions = () => this._returnPortfolioStore().positions;

  _returnPortfolioLookup = () => this._returnPortfolioStore().positions || {};

  _returnOptimizedAllocationValue = (securityId) => {
    const securityOptimizationData = this._returnOptimizedPositionsBySecurityId()[securityId];
    return securityOptimizationData ? securityOptimizationData.value : 0;
  };

  _returnOptimizerStore = (props) => (props || this.props).optimizer;

  _returnOptimizerStoreData = (props) => this._returnOptimizerStore(props).data || {};

  _returnOptimizedPositionsBySecurityId = (props) =>
    this._returnOptimizerStoreData(props).optimized_positions_by_security_id || {};
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    optimizer: state.optimizer,
    portfolio: state.portfolio,
  };
};

export default connect(mapStateToProps)(PortfolioAnalysisActionsCounter);
