import React from 'react';
import MoneyNumber from '../../../../UI/DataTypes/MoneyNumber';

const TradeTransactionFee = (props) => {
  const { fee_type, amount } = props;
  return (
    <div className={`transaction-trade-fee`}>
      <span className={`trade-commission-label secondary-text-color`}>{fee_type}</span>
      <MoneyNumber className="commission-fee-amount transaction-amount" value={amount} />
    </div>
  );
};

export default TradeTransactionFee;
