import React from 'react';
import { FlatButton } from '../buttons';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/paths';
import { colorPalette } from '../../lib/nvstr-utils.es';
import { Body3 } from '../../lib/nvstr-common-ui.es';
import styled from 'styled-components';
import { hexToRgbA } from '../../../helpers/generalHelpers';
import { TrackingEvents } from '../../../utils/tracking/events';
import { PRODUCT_DISPLAY_FULL_NAME } from '../../../appConfig';

const Wrapper = styled.div`
  padding: 16px;
  margin: 0 auto;
  max-width: 400px;
  background: ${hexToRgbA(colorPalette.primary.oak, 0.6)};
  border-radius: 6px;
`;

const TextWrapper = styled.div`
  //width: 268px;
  * {
    color: ${colorPalette.primary.charcoal} !important;
  }

  padding-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  button {
    width: 268px;
  }
`;

export const LearnShareCTA = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    const context = 'Learn and Earn Lesson Completion';
    TrackingEvents.referral.PRESS_REFERRAL_CTA.send({
      Context: context,
    });

    navigate(ROUTES.REFER_FRIEND_ACTIONS.build());
  };

  const message = `Share your invite link with a friend and you'll both get up to $1,000 to invest when they make a deposit and start using ${PRODUCT_DISPLAY_FULL_NAME}.`;

  return (
    <Wrapper>
      <TextWrapper>
        <Body3>
          {message}{' '}
          <a href={ROUTES.REFERRAL_RULES.build()} target="_blank">
            See Details.
          </a>
        </Body3>
      </TextWrapper>
      <ButtonWrapper>
        <FlatButton color={colorPalette.primary.charcoal} textColor={colorPalette.primary.oak} onClick={handleClick}>
          SHARE
        </FlatButton>
      </ButtonWrapper>
    </Wrapper>
  );
};
