import {
  ADD_LOADING_STATE_FOR_NEWS,
  ADD_ERROR_STATE_FOR_NEWS,
  ADD_NEWS,
  ADD_LOADING_STATE_FOR_NEWS_IMAGE,
  ADD_NEWS_IMAGE,
  ADD_NEWS_IMAGE_FAILURE_STATE,
  CLEAR_NEWS_DATA_FOR_SECURITY,
} from '../constants';

const defaultState = {
  newsBySecurityId: {},
  ogoByUrl: {},
};

export default function newsReducer(state = defaultState, action) {
  let payload, securityId, newsData, securityNews, newsErrors, loadingSecurityNews;
  switch (action.type) {
    case ADD_LOADING_STATE_FOR_NEWS:
      payload = action.payload;
      securityId = payload.securityId;

      if (state.newsBySecurityId[securityId]) {
        return state;
      } else {
        loadingSecurityNews = { [securityId]: null };
        return {
          ...state,
          newsBySecurityId: {
            ...state.newsBySecurityId,
            ...loadingSecurityNews,
          },
        };
      }

    case ADD_LOADING_STATE_FOR_NEWS_IMAGE:
      payload = action.payload;
      const loadingOgo = { [payload.url]: { loading: true } };
      return {
        ...state,
        ogoByUrl: { ...state.ogoByUrl, ...loadingOgo },
      };

    case ADD_ERROR_STATE_FOR_NEWS:
      payload = action.payload;
      newsErrors = payload.securityIds;

      return {
        ...state,
        newsBySecurityId: { ...state.newsBySecurityId, ...newsErrors },
      };

    case ADD_NEWS_IMAGE_FAILURE_STATE:
      payload = action.payload;
      const failureOgo = {
        [payload.url]: {
          loading: false,
          failed: true,
        },
      };
      return {
        ...state,
        ogoByUrl: { ...state.ogoByUrl, ...failureOgo },
      };

    case ADD_NEWS:
      payload = action.payload;
      securityId = payload.securityId;
      const currentNewsDataInState = state.newsBySecurityId[securityId] || [];
      newsData = payload.newsData;
      return {
        ...state,
        newsBySecurityId: {
          ...state.newsBySecurityId,
          [securityId]: [...currentNewsDataInState, ...newsData],
        },
      };

    case CLEAR_NEWS_DATA_FOR_SECURITY:
      payload = action.payload;
      securityId = payload.securityId;
      const newsCache = state.newsBySecurityId;
      const securityIdsInNewsCache = Object.keys(newsCache);
      const updatedNewsCacheData = {};
      securityIdsInNewsCache.forEach((secId) => {
        if (secId.toString() !== securityId.toString()) {
          updatedNewsCacheData[secId] = newsCache[secId];
        }
      });
      return {
        ...state,
        newsBySecurityId: updatedNewsCacheData,
      };

    case ADD_NEWS_IMAGE:
      payload = action.payload;
      const ogo = {
        [payload.url]: {
          loading: false,
          ...payload.openGraphObject,
        },
      };
      return {
        ...state,
        ogoByUrl: { ...state.ogoByUrl, ...ogo },
      };

    default:
      return state;
  }
}
