import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { bindActionCreators, compose } from 'redux';

import { returnPathTo } from '../../constants/paths';
import * as Actions from '../../actions/index';

import { Messaging } from '../../constants';

import LinkTo from '../Links/LinkTo';

import { consoleError } from '../../helpers/devToolHelpers';

export const MessageWrap = styled('div')`
  max-width: 800px;
  margin: 0 auto;

  & > div {
    border-radius: 6px;
    padding: 20px;
  }

  .people-page-container & > div {
    margin: 20px;
  }
`;

class InviteFriendsMessaging extends React.Component {
  render() {
    return (
      <MessageWrap>
        <div
          className={`
          ${this.props.additionalClassNames || ''}
          ${this._returnABTestGroupStyling()}
        `}
        >
          <span>{Messaging.inviteFriends}</span>
          <span style={{ marginLeft: '4px' }}>
            <LinkTo
              to={returnPathTo('referrals')}
              className="link-btn link-btn-in-eye-catching-bg-color"
              additionalClickAction={this._handleLinkClick}
              text={'Learn more'}
              scrollToTop
            />
          </span>
        </div>
      </MessageWrap>
    );
  }

  _handleLinkClick = () => {
    this._logClickDetailsInInviteFriendsMessaging();
  };

  _returnABTestGroupStyling = () => {
    // TODO: some of these classes are incompatible with the color scheme switching, fix this
    const stylingClassLookup = {
      0: 'default-style',
      1: 'dark-theme-style',
      2: 'card-light-theme-style',
      3: 'gradient-theme-style',
    };
    return stylingClassLookup[this._returnComponentStylingABTestGroup()] || stylingClassLookup[0];
  };

  _returnComponentStylingABTestGroup = () => this._returnCurrentUser().invite_friends_message_style_test_group;

  _returnCurrentUser = () => this.props.currentUser;

  _logClickDetailsInInviteFriendsMessaging = () => {
    const event = 'Clicked See Details Invite Friends Messaging';
    const properties = {
      Context: this.props.viewingContext,
      'AB Test Group': this._returnComponentStylingABTestGroup() || 0,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteFriendsMessaging);
