import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withWindowSize from '../../HOCS/withWindowSize';

import { returnPathTo } from '../../../constants/paths';
import * as Actions from '../../../actions/index';

import UserContainer from '../../users/UserContainer';
import ProfileAvatar from '../../../components/user/ProfileAvatar';
import UserName from '../../../components/user/UserName';

import MoreActionsButton from '../../buttons/MoreActions';
import LinkTo from '../../Links/LinkTo';
import UserIdeasPerformance from '../../../containers/UI/UserIdeasPerformance';

import { returnCurrentUserId } from '../../../helpers/currentUserHelpers';
import ClickEventComponent from '../../UI/EventActionWrappers/ClickEventComponent';
import { PROFILE_CONTEXTS } from '../../../constants/contextTracking';
import { SkeletonButton } from '@src/main/components/buttons';
import styled from 'styled-components';
import { useNavigate } from 'react-router';

const ButtonWrapper = styled.div`
  button {
    padding: 4px 8px;
  }
`;

const ViewIdeasButton = ({ to, onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(to);
    onClick();
  };

  return (
    <ButtonWrapper>
      <SkeletonButton onClick={handleClick}>View Ideas</SkeletonButton>
    </ButtonWrapper>
  );
};

class ConnectionRow extends React.PureComponent {
  render() {
    return this._isMobileSize() ? this._renderMobileConnectionRow() : this._renderDesktopConnectionRow();
  }

  _renderDesktopConnectionRow = () => {
    return (
      <div
        className={`
          connections-row-container border-accent
          ${this._isUserCurrentUser() ? 'is-current-user' : ''}
        `}
      >
        {this._renderUserInfo()}
        {this._renderIdeasPerformance()}
        {this._renderActionButtons()}
      </div>
    );
  };

  _renderMobileConnectionRow = () => {
    return (
      <div
        className={`
          mobile-size
          connections-row-container border-accent
          ${this._isUserCurrentUser() ? 'is-current-user' : ''}
        `}
      >
        <div className={'row-container'}>
          {this._renderUserInfo()}
          {this._renderActionButtons()}
        </div>
        {this._renderIdeasPerformance()}
      </div>
    );
  };

  _renderUserInfo = () => {
    return (
      <span className={'connection-row-user-info'}>
        <span className={'connection-list-avatar-container'}>
          <UserContainer userId={this._returnUserId()}>
            <ProfileAvatar
              size={this._isMobileSize() ? 'nav' : 'medium'}
              profileLinkQuery={`?context=${PROFILE_CONTEXTS.CONNECTIONS}`}
            />
          </UserContainer>
        </span>

        <span className={'user-name-and-location-container'}>
          <UserContainer userId={this._returnUserId()}>
            <UserName displayNameStyle="fullName" profileLinkQuery={`?context=${PROFILE_CONTEXTS.CONNECTIONS}`} />
          </UserContainer>
        </span>
      </span>
    );
  };

  _renderIdeasPerformance = () => {
    return (
      <div className={'connections-table-user-idea-perf'}>
        <UserIdeasPerformance
          userId={this._returnUserId()}
          user={this._returnUser()}
          showInlineInfoIcon
          dontGetData={this.props.dontGetData}
          showWeeklyInsteadOfDaily={this.props.showWeeklyInsteadOfDailyPerf}
        />
      </div>
    );
  };

  _renderActionButtons = () => {
    if (this._isUserCurrentUser()) {
      return <div className={'connections-table-user-actions'}></div>;
    }
    return (
      <div className={'connections-table-user-actions'}>
        {this._renderViewIdeasBtn()}
        {this._renderMoreActionsBtn()}
      </div>
    );
  };

  _returnLinkForHandlingViewIdeasClick = () => returnPathTo('profile', this._returnUserId());

  _renderViewIdeasBtn = () => {
    if (this._isUserPrivate()) {
      return <span className={'empty-view-ideas-col'}></span>;
    }
    return <ViewIdeasButton to={this._returnLinkForHandlingViewIdeasClick()} onClick={this._logViewIdeasClick} />;
  };

  _handleRemoveConnection = () => {
    this.props.actions.removeConnection(this._returnCurrentUserId(), this._returnUserId());
    this._logRemoveConnectionClick();
  };

  _renderMoreActionsBtn = () => {
    const dropdownOptions = [
      {
        elementType: ClickEventComponent,
        elementTypeString: 'ClickEventComponent',
        props: {
          id: `additional-action-connection-row-${this._returnUserId()}`,
          onClickFunc: this._handleRemoveConnection,
          shouldTriggerDropdownClose: true,
        },
        children: (
          <div className="user-settings-nav-item is-updating connection-user-row-more-actions selectable-item">
            <span>Remove Connection</span>
          </div>
        ),
      },
    ];
    return (
      <MoreActionsButton
        dropdownId={`connection-more-action-button-dropdown-${this._returnUserId()}`}
        dropdownChildrenDataList={dropdownOptions}
        eventContext={'Connections Row'}
      />
    );
  };

  _isUserCurrentUser = () => this._returnUserId() === this._returnCurrentUserId();

  _returnUserId = () => this.props.userId;

  _isUserPrivate = () => (this._returnUser() ? this._returnUser().is_private : null);

  _returnUser = () => this._returnUsersList()[this._returnUserId()];

  _returnUsersStore = () => this.props.users;

  _returnUsersList = () => this._returnUsersStore().userList;

  _returnCurrentUser = () => this.props.currentUser;

  _returnCurrentUserId = () => returnCurrentUserId(this._returnCurrentUser());

  _returnWindowWidth = () => this.props.windowWidth;

  _isMobileSize = () => this._returnWindowWidth() <= 650;

  _logViewIdeasClick = () => {
    const event = 'Clicked View Ideas';
    const properties = {
      'User ID': this._returnUserId(),
      Context: 'Connections Page',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logRemoveConnectionClick = () => {
    const event = 'Clicked Remove Connection';
    const properties = {
      'User ID': this._returnUserId(),
      Context: 'Connections Page',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    users: state.users,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(withWindowSize, connect(mapStateToProps, mapDispatchToProps))(ConnectionRow);

export default composedComponent;
