import React from 'react';
import { BaseText, defineFontSettings } from '../../lib/nvstr-common-ui.es';

export const TableHeadingCellLabel = (props) => {
  const fontSettings = defineFontSettings(
    { fontSize: '12px', lineHeight: '14px' },
    { fontSize: '12px', lineHeight: '14px' }
  );
  return (
    <BaseText fontSettings={fontSettings} isLowContrast {...props}>
      {props.children}
    </BaseText>
  );
};
