import React, { Component } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withWindowSize from '../HOCS/withWindowSize';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import { Thresholds } from '../../constants';
import * as Actions from '../../actions/index';
import { withRouter } from '../../main/utils';
import Page from '../../components/layout/Page';
import OrderForm from './OrderForm';
import OrderBasket from './OrderBasket';
import OpenCreateOrderButton from './components/OpenCreateOrderButton';
import OrderHistoryLink from './OrderHistoryLink';
import OrdersData from '../../dataContainers/orders/OrdersData';
import OrdersAwaitingApproval from './OrdersAwaitingApproval';
import PlaceTrades from '../../dataContainers/orders/PlaceTrades';
import OrdersPlacedToday from './OrdersPlacedToday';
import PageLoading from '../../components/PageLoading';
import InfoIcon from '../UI/InfoIcon';
import { PageHeading } from '../../components/UI/PageHeading';
import { isUndefinedOrNull, scrollToTop, formatLocaleString, toCapitalCase } from '../../helpers/generalHelpers';
import {
  returnCurrentUserId,
  isCurrentUserLiveTrading,
  inAutoOpenStockSearchOnTradePageTestGroup,
  inNoOrderBasketTestGroup,
} from '../../helpers/currentUserHelpers';
import { parseQueryString, createQueryString } from '../../helpers/routerHelpers';
import { areUnfilledOrders, isOptimizedTrade } from '../../helpers/ordersHelpers';
import { formatForDisplay } from '../../helpers/displayHelpers';
import { returnCurrentUserPendingEquityReasonsJSX } from '../../helpers/infoIconHelpers';
import { TrackingEvents } from '../../utils/tracking/events';
import { OP_CO_NAME } from '../../appConfig';
import { useCurrentOptionsPositions } from '../../main/hooks/orders/useCurrentOptionsPositions';
import { useSecurity } from '../../main/hooks/securities/useSecurity';
import { useOptionOrderData } from '../../main/hooks/orders/useOptionOrderData';
import { createTimeInstance, formatLocalizedDateTime } from '../../helpers/timeHelpers';
import styled from 'styled-components';
import { Body5 } from '../../main/lib/nvstr-common-ui.es';
import { colorPalette } from '../../main/lib/nvstr-utils.es';
import { quickFetchSecuritiesData } from '../../actions/index';
import { Spacing } from '../../main/components/layout';
import { useCurrentUser } from '../../main/hooks/user';
import { pluralize } from '../../helpers/usefulFuncs';
import { Container } from '../../main/components/ui';

const FreeTradesCountMessage = () => {
  const currentUser = useCurrentUser();
  const isUpdating = currentUser?.loading;

  const isMember = currentUser.has_membership;
  const isMembershipActive = currentUser.is_membership_active;
  const freeTradeCount = currentUser.membership_free_trade_count;
  if (isMember) {
    const message = `You have ${freeTradeCount} commission-free ${pluralize(freeTradeCount, 'trade')}.`;
    return (
      <Container top={8} bottom={16}>
        {isMembershipActive && (
          <Container bottom={5} centerAll>
            <Body5>Membership Active</Body5>
          </Container>
        )}
        <Container centerAll>
          <Body5>{isUpdating ? 'Updating free trade count' : message}</Body5>
        </Container>
      </Container>
    );
  }
  return null;
};

const MembershipStatus = () => {
  const trade_membership_status = useSelector((state) => state.application?.staticCopy?.trade_membership_status);

  if (trade_membership_status && trade_membership_status.length > 0) {
    return (
      <Spacing top={8} bottom={16}>
        {trade_membership_status.map((text, i) => (
          <Spacing top={8} key={i} center>
            <Body5 thin>{text}</Body5>
          </Spacing>
        ))}
      </Spacing>
    );
  }

  return null;
};

const CText = styled.div`
  font-size: 18px;
  font-weight: 200;
  margin: 0;
  padding: 0;
  text-align: left;
`;

const OptionsTodayWrapper = styled.div`
  padding: 24px 0 0 0;

  table {
    width: 100%;
  }

  td {
    border-bottom: 1px solid ${({ theme }) => theme.themeColors.appBackground};
    padding: 16px 0;
  }

  td:last-child,
  th:last-child {
    padding-right: 24px;
    text-align: center;
  }

  td:first-child {
    padding-left: 24px;
  }

  th:nth-last-child(2),
  td:nth-last-child(2) {
    text-align: center;
    * {
      text-align: center;
    }
  }
`;

const Operation = styled.div`
  font-size: 16px;
  color: ${({ operation, theme }) => (operation === 'buy' ? '#23d977' : colorPalette.secondary.red)};
  text-transform: uppercase;
`;

const OptionPlacedToday = ({ securityId, optionId }) => {
  const dispatch = useDispatch();
  const security = useSecurity(securityId);
  const symbol = security ? security.symbol : '';
  const name = security ? security.name : '';

  React.useEffect(() => {
    if (!security) {
      quickFetchSecuritiesData([securityId])(dispatch);
    }
  }, []);

  const option = useOptionOrderData(optionId);
  if (!option) return null;

  const {
    // id,
    exercise_price,
    expiration_date,
    qty,
    option_type,
    operation,
    order_type,
    executed_at,
    price,
    // limit_price,
  } = option;
  return (
    <tr>
      <td>
        <Operation operation={operation}>{operation}</Operation>
        <div>{`${formatForDisplay(parseFloat(exercise_price), 'price')} ${option_type.toUpperCase()}`}</div>
        <div>{formatLocalizedDateTime('L', createTimeInstance(expiration_date))}</div>
      </td>
      <td>
        <div style={{ fontSize: '18px' }}>{symbol}</div>
        <Body5 isLowContrast>{name}</Body5>
      </td>
      <td>
        <p style={{ fontSize: '18px' }}>{`${qty}/${qty}`}</p>
        <div>
          <Body5>{toCapitalCase(order_type)}&nbsp;Order</Body5>
        </div>
        <Body5 isLowContrast>{`Avg Price: ${formatForDisplay(price, 'price')}`}</Body5>
        {/*{order_type === 'limit' && <div>Limit Price: {formatForDisplay(limit_price, 'price')}</div>}*/}
      </td>
      <td>
        <div>{formatLocalizedDateTime('LTS', createTimeInstance(executed_at))}</div>
      </td>
    </tr>
  );
};

const OptionsToday = () => {
  const {
    securityIdsWithOptionsPositions,
    positionIdsBySecurityId,
    optionsPositionLookup,
  } = useCurrentOptionsPositions();
  const debug = false;

  if (!securityIdsWithOptionsPositions || securityIdsWithOptionsPositions.length === 0) return null;

  return (
    <OptionsTodayWrapper>
      <CText>Options placed today</CText>
      <table>
        <thead>
          <th />
          <th />
          <th>Filled/Order Size</th>
          <th>Time</th>
        </thead>
        <tbody>
          {securityIdsWithOptionsPositions.map((sid) =>
            positionIdsBySecurityId[sid].map((oid) => <OptionPlacedToday key={oid} securityId={sid} optionId={oid} />)
          )}
        </tbody>
      </table>
      <div />
      {debug && (
        <div>
          <div>
            <p>securityIdsWithOptionsPositions</p>
            <p style={{ wordBreak: 'break-all' }}>{JSON.stringify(securityIdsWithOptionsPositions)}</p>
          </div>
          <div>
            <p>positionIdsBySecurityId</p>
            <p style={{ wordBreak: 'break-all' }}>{JSON.stringify(positionIdsBySecurityId)}</p>
          </div>
          <div>
            <p>optionsPositionLookup</p>
            <p style={{ wordBreak: 'break-all' }}>{JSON.stringify(optionsPositionLookup)}</p>
          </div>
        </div>
      )}
    </OptionsTodayWrapper>
  );
};

export class TradePage extends Component {
  constructor(props) {
    super();
    this.state = {
      isLoading: true,

      creatingOrderForSecurityId: null,
      isSearchingForStockForOrder: false,
      _isPricingUpdating: false,
      _isUpdatingOrders: false,
      isPlacingOrders: false,
      isError: false,

      forceUseBasket: false, // used when an optimized basket is created to prevent the editting of a single order to change basket to not optimized which would hide the order, this will reset when no non_placed_orders remain

      defaultCreateOrderFormData: {}, // used to prepopulate fields in new order form
    };
  }

  componentDidMount() {
    scrollToTop();
    this._updatePageTitle();
    this._logPageView();

    this._checkURLForOrderFormQuery();

    this._refreshUserData();
    this._getOrderData().then((response) => {
      this.setState(() => ({
        isLoading: false,
      }));

      if (this._isPatternDayTrader()) {
        this._showPatternDayTraderMessage();
        this.dismissPatternDayTraderMessage();
        this._logShowPatternDayTraderMessage();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this._checkURLForOrderFormQuery();
    }

    if (this._arePendingOptimizedOrders() && !this.state.forceUseBasket) {
      this.setState(() => ({
        forceUseBasket: true,
      }));
    }

    if (!this._arePendingOrders() && this.state.forceUseBasket) {
      this.setState(() => ({
        forceUseBasket: false,
      }));
    }
  }

  render() {
    return (
      <div className={`trade-page-container ${this._buildContainerClassName()}`}>
        <Page>{this.renderPage()}</Page>
      </div>
    );
  }

  renderPage = () => {
    if (this._isLoading()) {
      return (
        <div className={`trade-page-loading`}>
          <PageLoading flatStyle />
        </div>
      );
    }

    return (
      <div className={`trade-page`}>
        {!this._arePendingOrders() && this.renderTradePageHeader()}
        <PlaceTrades handleDismiss={this.handleOrderDismiss}>
          {({
            handlePlaceOrders,
            renderPlacingOrdersState,
            showAddProConPanelForPlacedOrders,
            shouldRestrictDismissal,
            wasOrderPlaced,
            isAddProConPanelShowing,
            clearPlaceOrdersState,
          }) => (
            <OrderBasket
              placeOrders={handlePlaceOrders}
              renderPlacingOrdersState={renderPlacingOrdersState}
              headerComponent={this.renderOrderBasketHeader()}
              isSearchingForStockToAdd={this._isSearchingCreatingOrder()}
              handleCreateOrderButtonClick={this.handleCreateOrderButtonClick}
              handleStartCreatingOrderFromSearch={this.handleStartCreatingOrderFromSearch}
              hasFreeTrading={this.hasFreeTrading()}
              hasTradingCredits={this.hasTradingCredits()}
              isUsingTradingCredits={this.isUsingTradingCredits()}
              userTradingCredits={this.returnUserTradingCredits()}
              requiresTradeApproval={this.requiresTradeApproval()}
              showAddProConPanelForPlacedOrders={showAddProConPanelForPlacedOrders}
              shouldRestrictDismissal={shouldRestrictDismissal}
              wasOrderPlaced={wasOrderPlaced}
              isAddProConPanelShowing={isAddProConPanelShowing}
              clearPlaceOrdersState={clearPlaceOrdersState}
            />
          )}
        </PlaceTrades>
        {this._isCreatingOrder() &&
          (this.isInNoOrderBasketTestGroup() ? (
            <PlaceTrades handleDismiss={this.handleOrderDismiss}>
              {({
                handlePlaceOrders,
                renderPlacingOrdersState,
                showAddProConPanelForPlacedOrders,
                shouldRestrictDismissal,
                wasOrderPlaced,
                isAddProConPanelShowing,
              }) =>
                this.renderOrderForm({
                  isUsingBasket: this.isUsingBasket(),
                  handlePlaceOrders,
                  renderPlacingOrdersState,
                  showAddProConPanelForPlacedOrders,
                  shouldRestrictDismissal,
                  wasOrderPlaced,
                  isAddProConPanelShowing,
                })
              }
            </PlaceTrades>
          ) : (
            this.renderOrderForm({ isUsingBasket: true })
          ))}
        <OrdersData>
          {(ordersPlacedTodayList) => (
            <OrdersPlacedToday
              isMobile={this._isMobileView()}
              windowWidth={this.props.windowWidth}
              isRefreshing={this._isUpdatingOrders()}
              showRefreshButton={this._shouldShowRefreshButton()}
              handleRefresh={this._handleOrderStatusesClick}
              refreshUserData={this._refreshUserData}
              ordersPlacedTodayList={ordersPlacedTodayList}
            />
          )}
        </OrdersData>
        <OrdersData>
          {(unused, ordersAwaitingApprovalList) => (
            <OrdersAwaitingApproval
              isMobile={this._isMobileView()}
              windowWidth={this.props.windowWidth}
              isRefreshing={this._isUpdatingOrders()}
              showRefreshButton={this._shouldShowRefreshButton()}
              handleRefresh={this._handleOrderStatusesClick}
              refreshUserData={this._refreshUserData}
              ordersAwaitingApprovalList={ordersAwaitingApprovalList}
            />
          )}
        </OrdersData>
        {/*<OptionsToday />*/}
        <OrderHistoryLink />
      </div>
    );
  };

  renderTradePageHeader = () => {
    return (
      <>
        <div className={`header`}>
          <PageHeading className=" ">Orders</PageHeading>
          <FreeTradesCountMessage />
          <div className={`trade-account-additional-info-container`}>
            {this._renderBuyingPower()}
            {this._renderPendingFundingAmount()}
          </div>
          <MembershipStatus />
          {this._renderTradingCredits()}
          {this._renderRequireTradeApprovalNotice()}
        </div>
        <OpenCreateOrderButton
          inSearchMode={this._isSearchingCreatingOrder()}
          handleButtonClick={this.handleCreateOrderButtonClick}
          onSearchSelection={this.handleStartCreatingOrderFromSearch}
          customClass={'no-orders'}
        />
      </>
    );
  };

  renderOrderForm = (additionalProps = {}) => {
    return (
      <OrderForm
        defaultFormData={this._returnDefaultCreateOrderFormData()}
        securityId={this._returnSecurityId()}
        handleCompleteOrder={this.handleCompleteOrder}
        handleCancelOrder={this.handleCancelOrder}
        {...additionalProps}
      />
    );
  };

  renderOrderBasketHeader = () => (
    <div className={`header`}>
      <PageHeading className=" ">Order Basket</PageHeading>
      <FreeTradesCountMessage />
      {this._renderBuyingPower()}
      {this._renderPendingFundingAmount()}
      {this._renderTradingCredits()}
      {this._renderRequireTradeApprovalNotice()}
    </div>
  );

  _renderBuyingPower = () => {
    return (
      this._shouldShowBuyingPower() && (
        <div className={`user-buying-power-container`}>
          <div className={`value-label`}>
            <span className="info-icon-text-wrapper">
              Buying Power
              <InfoIcon word={'tooltip_buying_power'} style={{ top: '1px', right: '-17px', bottom: '0px' }} />
            </span>
          </div>
          <div className={`value`}>{this._displayFormatOfBuyingPower()}</div>
        </div>
      )
    );
  };

  _renderPendingFundingAmount = () => {
    return (
      this._doesUserHavePendingEquity() && (
        <div className={`user-pending-equity-container`}>
          <div className={`value-label`}>
            <span className="info-icon-text-wrapper">
              Pending
              <InfoIcon
                customMessage={returnCurrentUserPendingEquityReasonsJSX(this._returnCurrentUser())}
                style={{ top: '1px', right: '-17px', bottom: '0px' }}
                trackingMessage={'Pending Equity Reasons Trade Page'}
              />
            </span>
          </div>
          <div className={`value`}>{this._returnDisplayStringPendingEquity()}</div>
        </div>
      )
    );
  };

  _returnTooltipWordForTradingCredits = () =>
    this._isLiveTrading() ? 'tooltip_credit_earned' : 'tooltip_credit_earned_paper';

  _returnFreeTradesMessage = () =>
    this.hasTradingCredits()
      ? `Plus commission-free trades until ${this.returnFreeTradingUntilDisplayTime()}`
      : `Commission-free trades until ${this.returnFreeTradingUntilDisplayTime()}`;

  _renderTradingCredits = () => {
    const showTradingCredits = this.hasFreeTrading() || this.hasTradingCredits();
    if (!showTradingCredits) {
      return null;
    }
    return (
      <div className={`user-trading-credits-container`}>
        <div className={`value-label`}>
          <span className="info-icon-text-wrapper">
            Credit Available
            <InfoIcon word={this._returnTooltipWordForTradingCredits()} style={{ top: 'auto', bottom: '1px' }} />
          </span>
        </div>
        {this.hasTradingCredits() && <div className={`value`}>{this._returnTradeCreditDisplayValue()}</div>}
        <div className={`value`}>
          <FreeTradesCountMessage />
        </div>
        {this.hasFreeTrading() && this.hasTradingCredits() && (
          <div className={`sub-value`}>{this._returnFreeTradesMessage()}</div>
        )}
      </div>
    );
  };

  _renderRequireTradeApprovalNotice = () => {
    if (!this.requiresTradeApproval()) {
      return null;
    }
    return (
      <div className={`user-requires_trade-approval-container`}>
        <div className={`value-label`}>
          <span className="info-icon-text-wrapper">
            <span className=" ">
              Note that your account is configured to require pre-clearance approval for all orders.
            </span>
            <InfoIcon word={'tooltip_requires_trade_approval'} style={{ top: 'auto', bottom: '1px' }} />
          </span>
        </div>
      </div>
    );
  };

  _showPatternDayTraderMessage = () => {
    const contentComponent = (
      <div className="day-trader-notice-container">
        <p>You are considered a pattern day trader due to your trading activity.</p>
        <p>You are required to maintain a minimum account value of $25,000 and are not allowed to use leverage.</p>
        <p>
          You can find more information{' '}
          <a href="https://www.sec.gov/files/daytrading.pdf" target="_blank" rel="no-follow noopener noreferrer">
            here
          </a>
          .
        </p>
        <p>{`${OP_CO_NAME}`} operates this site and provides all brokerage services.</p>
      </div>
    );

    const modal = {
      contentComponent,
      heading: 'Day Trader Notice',
      size: 'wide',
      dismissable: true,
    };
    this.props.actions.showModal(modal);
  };

  _handleOrderStatusesClick = () => {
    this._startRefreshingOrderStatuses();
    this._logRefreshOrderStatusesClick();
    const requests = [this._getOrderData({ ignoreLoading: true }), this._refreshUserData()];
    Promise.all(requests).then((response) => {
      this._stopRefreshingOrderStatuses();
    });
  };

  handleOrderDismiss = () => {
    this.handleCancelOrder();
  };

  handleCancelOrder = () => {
    this._closeOrderForm();
  };

  handleCompleteOrder = () => {
    this._closeOrderForm();
  };

  handleCreateOrderButtonClick = () => {
    this._logCreateManualOrderClick();
    this._startSearchingCreatingOrder();
  };

  _handleOpenCreateOrderFormFromURL = (security_id) => {
    this._logOpenCreateOrderFormFromURL(security_id);
    this._startCreatingOrder(security_id);
  };

  handleStartCreatingOrderFromSearch = (security) => {
    if (!security) {
      return false;
    }
    const securityId = security.id || null;
    this._startCreatingOrder(securityId);
    this._logSearchForSecuritySelection(securityId);
  };

  _isUpdatingOrders = () => this.state._isUpdatingOrders;

  _isMobileView = () => this.props.windowWidth <= Thresholds.widthForOrderMobileView;

  _isLiveTrading = () => isCurrentUserLiveTrading(this._returnCurrentUser());

  returnUserTradingCredits = () => this._returnCurrentUser().free_credit_available;

  hasFreeTrading = () => this._returnCurrentUser().has_free_trading && this._isLiveTrading();

  hasTradingCredits = () => this.returnUserTradingCredits() > 0;

  requiresTradeApproval = () => this._returnCurrentUser().requires_trade_approval && this._isLiveTrading();

  isUsingTradingCredits = () => this._isLiveTrading() && (this.hasFreeTrading() || this.hasTradingCredits());

  _returnFreeTradingUntilTime = () => this._returnCurrentUser().free_trading_until;

  returnFreeTradingUntilDisplayTime = () => formatForDisplay(this._returnFreeTradingUntilTime(), 'timeDate');

  _returnTradeCreditDisplayValue = () => `${formatForDisplay(this.returnUserTradingCredits(), 'price')}`;

  _isLoading = () => this.state.isLoading;

  _isSearchingCreatingOrder = () =>
    inAutoOpenStockSearchOnTradePageTestGroup(this._returnCurrentUser())
      ? true
      : this.state.isSearchingForStockForOrder;

  _isCreatingOrder = () => !isUndefinedOrNull(this._returnSecurityId());

  isUsingBasket = () => !this.isInNoOrderBasketTestGroup() || this.state.forceUseBasket;

  isInNoOrderBasketTestGroup = () => inNoOrderBasketTestGroup(this._returnCurrentUser());

  _returnDefaultCreateOrderFormData = () => this.state.defaultCreateOrderFormData;

  _closeOrderForm = () => {
    this._clearOrderFormQueryFromURL();
    this._endCreatingOrder();
    this._endSearchingCreatingOrder();
  };

  _shouldShowRefreshButton = () => this._areUnfilledOrders() || this._anyOrdersAwaitingApproval();

  _refreshUserData = (config) => this.props.actions.fetchCurrentUserQuickInfo(config);

  _getOrderData = (config) => this.props.actions.getOrders(config);

  _isPatternDayTrader = () => this._returnCurrentUser().should_see_day_trader_notice;

  _arePendingOptimizedOrders = () => {
    if (!this._arePendingOrders()) {
      return false;
    }
    const orders = this._returnOrdersInBasket();
    return isOptimizedTrade(orders);
  };

  _arePendingOrders = () => this._returnOrdersInBasket() && this._returnOrdersInBasket().length > 0;

  _returnOrdersInBasket = () => this._returnOrdersStore().not_placed_orders;

  _returnAllPlacedOrders = () => this._returnOrdersStore().placed_orders;

  _wasOrdersPlacedToday = () => this._returnAllPlacedOrders().length > 0;

  _areUnfilledOrders = () => areUnfilledOrders(this._returnAllPlacedOrders());

  _returnAllAwaitingApprovalOrders = () => this._returnOrdersStore().awaiting_approval_orders;

  _anyOrdersAwaitingApproval = () =>
    this._returnAllAwaitingApprovalOrders() && this._returnAllAwaitingApprovalOrders().length > 0;

  _returnOrdersStore = () => this.props.orders;

  _returnBuyingPower = () => this._returnCurrentUser().buying_power;

  _shouldShowBuyingPower = () => !isUndefinedOrNull(this._returnBuyingPower());

  _displayFormatOfBuyingPower = () => {
    return (
      '$' +
      formatLocaleString(this._returnBuyingPower(), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };

  _doesUserHavePendingEquity = () => this._returnCurrentUserPendingEquity() > 0;

  _returnCurrentUserPendingEquity = () =>
    this._returnCurrentUserTotalEquityIncludingPendingEquity() - this._returnCurrentUserEquity();

  _returnCurrentUserTotalEquityIncludingPendingEquity = () => this._returnCurrentUser().pending_equity || 0;

  _returnDisplayStringPendingEquity = () => formatForDisplay(this._returnCurrentUserPendingEquity(), 'price');

  _returnCurrentUserEquity = () => this._returnCurrentUser().equity;

  _returnCurrentUserId = () => returnCurrentUserId(this.props.currentUser);

  _returnCurrentUser = () => this.props.currentUser;

  _returnSecurityId = () =>
    isUndefinedOrNull(this.state.creatingOrderForSecurityId)
      ? this.state.creatingOrderForSecurityId
      : parseInt(this.state.creatingOrderForSecurityId, 10);

  _startCreatingOrder = (securityId) => {
    this.setState(() => ({
      creatingOrderForSecurityId: securityId,
    }));
  };
  _endCreatingOrder = () => {
    this.setState(() => ({
      creatingOrderForSecurityId: null,
    }));
  };

  _startSearchingCreatingOrder = () => {
    this.setState(() => ({
      isSearchingForStockForOrder: true,
    }));
  };

  _endSearchingCreatingOrder = () => {
    this.setState(() => ({
      isSearchingForStockForOrder: false,
    }));
  };

  _setDefaultCreateOrderFormData = (data) => {
    this.setState((prevState) => ({
      defaultCreateOrderFormData: {
        ...prevState.defaultCreateOrderFormData,
        ...data,
      },
    }));
  };

  _startRefreshingOrderStatuses = () => {
    this.setState(() => ({
      _isUpdatingOrders: true,
    }));
  };

  _stopRefreshingOrderStatuses = () => {
    this.setState(() => ({
      _isUpdatingOrders: false,
    }));
  };

  dismissPatternDayTraderMessage = () => {
    this.props.actions.dismissDayTraderMessage();
  };

  _logPageView = () => {
    TrackingEvents.orders.VIEW_TRADE_PAGE.send();
  };

  _logCreateManualOrderClick = () => {
    const event = 'Click Manual Trade';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logOpenCreateOrderFormFromURL = (security_id) => {
    const event = 'Order Form Opened From URL';
    const defaultFormData = this._createDefaultFormDataFromURLQuery();
    const formData = {
      Operation: defaultFormData.operation ? defaultFormData.operation.display : null,
      'Security ID': security_id,
    };
    const properties = {
      ...formData,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logRefreshOrderStatusesClick = () => {
    const event = 'Clicked Refresh Order Statuses';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logShowPatternDayTraderMessage = () => {
    const event = 'Showing Pattern Day Trader Message';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logSearchForSecuritySelection = (securityId) => {
    const event = 'Selected Result from Order Security Search';
    const properties = {
      'Security Id': securityId,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _updatePageTitle = () => {
    document.title = `${PRODUCT_DISPLAY_NAME} - Trade`;
  };

  _buildContainerClassName = () => {
    const classes = [];
    if (!this._arePendingOrders() && !this._wasOrdersPlacedToday() && !this._anyOrdersAwaitingApproval()) {
      classes.push('no-orders');
    }
    return classes.join(' ');
  };

  _createDefaultFormDataFromURLQuery = () => {
    const queryString = this.props.location.search;
    const query = parseQueryString(queryString);
    const formData = {};

    const queryOperationName = query.operation;
    const validOperations = [{ id: 0, name: 'Buy', display: 'Buy' }, { id: 1, name: 'Sell', display: 'Sell' }];
    if (validOperations.some((op) => op.name.toLowerCase() === queryOperationName.toLowerCase())) {
      const operation = validOperations.filter((op) => op.name.toLowerCase() === queryOperationName.toLowerCase())[0];
      if (operation) {
        formData['operation'] = operation;
      }
    }

    return formData;
  };

  _checkURLForOrderFormQuery = () => {
    const queryString = this.props.location.search;
    const query = parseQueryString(queryString);
    const isOrderFormQuery = 'operation' in query && 'security_id' in query;
    if (isOrderFormQuery) {
      const securityId = query.security_id;
      const formData = this._createDefaultFormDataFromURLQuery();
      this._setDefaultCreateOrderFormData(formData);
      this._handleOpenCreateOrderFormFromURL(securityId);
      this.props.navigate(this.props.location.pathname);
    }
  };

  _clearOrderFormQueryFromURL = () => {
    const path = this.props.location.pathname;
    const queryString = this.props.location.search;
    const query = parseQueryString(queryString);
    const keysToFilter = ['security_id', 'operation'];
    const queryKeys = Object.keys(query).filter((key) => !keysToFilter.includes(key));
    if (queryKeys.length > 0) {
      const filteredQuery = {};
      queryKeys.forEach((key) => (filteredQuery[key] = query[key]));
      const newQueryString = createQueryString(filteredQuery);
      this.props.navigate(path + newQueryString);
    }
  };
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(
  withWindowSize,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TradePage);

export default composedComponent;
