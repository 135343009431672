import React from 'react';
import styled from 'styled-components';
import { Page } from '../../components/layout';
import {
  Body5,
  PlusCircle,
  MinusCircle,
  Body6,
  Bank,
  FloatingLabelTextInput,
  INPUT_MASK_TYPES,
  createDropdownItem,
  FloatingLabelDropdown,
  Body1,
  H3,
} from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/paths';
import {
  createRecurringDeposit,
  getBankAccounts,
  hideModal,
  postPaperTransfer,
  removeBankAccount,
  showModal,
} from '../../../actions';
import { useDispatch } from 'react-redux';
import { isCurrentUserAllowLiveTrading } from '../../../helpers/currentUserHelpers';
import { useCurrentUser, useIsLiveTrading } from '../../hooks/user';
import FundingBonusBannerRenderer from '../../../components/banners/FundingBonusBannerRenderer';
import { useLinkedBankAccounts } from '../../hooks/funding/useLinkedBankAccounts';
import InfoIcon from '../../../containers/UI/InfoIcon';
import { createBasicErrorModalWithOkayButton, createConfirmationModal } from '../../../constants/modals';
import UnstyledLoadingSpinner from '../../components/Images/UnstyledLoadingSpinner';
import { Container, WarningNotice } from '../../components/ui';
import { useBankAccounts } from '../../hooks/funding/useBankAccounts';
import { FormState } from '../../components/form/FormState';
import { OpenBrokerageAccountBanner } from '../../components/banners';
import { usePaperBankAccount } from '../../hooks/funding/usePaperBankAccount';
import { CheckCircleFilled } from '../../icons/svg/CheckCircleFilled';
import { customParseFloat } from '../../../helpers/numberHelpers';
import { usePromotions } from '../../hooks/user';
import { TransferHistoryList } from '../../components/funding/BankTransferHistory';
import { DepositBonusBanner } from '../../../components/banners/DepositBonusBanner';
import { useRecurringDeposits } from '../../hooks/funding/useRecurringDeposits';
import { createTimeInstance, formatLocalizedDateTime } from '../../../helpers/timeHelpers';
import { TrackingEvents } from '../../../utils/tracking/events';

const Content = styled.div`
  h3 {
    margin: 0;
  }
`;
const IconWrapper = styled.div`
  svg {
    height: 24px;
    width: 24px;

    path {
      fill: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }
`;
const BankIconWrapper = styled.div`
  position: relative;

  svg {
    height: 24px;
    width: 24px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;
const BankPlusWrapper = styled.div`
  background: ${({ theme }) => theme.themeColors.buttonText};
  border: 1px solid ${({ theme }) => theme.themeColors.buttonText};

  border-radius: 14px;
  height: 14px;
  width: 14px;

  position: absolute;
  bottom: 4px;
  right: -2px;
  z-index: 1;

  svg {
    position: absolute;
    top: 0;
    left: 0;

    height: 14px;
    width: 14px;

    path {
      fill: ${({ theme }) => theme.themeColors.primaryCtaButton};
    }
  }
`;
const DisabledBankLinkWrapper = styled.div`
  position: relative;
`;
const DisabledBankLink = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;

  background: ${({ theme }) => theme.themeColors.disabled};
  border-radius: 5px;
  cursor: not-allowed;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding: 16px;
  border: 1px solid ${({ theme }) => theme.themeColors.border};
  min-height: 97px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;

  &:hover {
    border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
    border-radius: 2px;
    cursor: pointer;
  }
`;
const ButtonTextWrapper = styled.div`
  padding-left: 12px;
`;
const FundingActionsWrapper = styled.div`
  padding-top: 16px;
`;
const ActionsWrapper = styled.div`
  padding-top: 40px;
`;
const LinkedBanksWrapper = styled.div`
  padding-top: 24px;
`;
const BannerStyle = styled.div`
  margin: 24px auto 0 auto;
  padding: 16px;
  border-radius: 5px;
  border: 1px dashed ${({ theme }) => theme.themeColors.text};
`;
const BankAccountListItemWrapper = styled.div`
  padding: 16px 16px 16px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
`;
const BankAccountListWrapper = styled.div`
  padding: 16px 0 0 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const RemoveButtonWrapper = styled.div`
  button {
    padding-left: 16px;
    padding-right: 4px;
  }
`;
const RemovingIconWrapper = styled.div`
  height: 32px;
  width: 32px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    height: 24px;
    width: 24px;
  }
`;
const InputWrapper = styled.div`
  padding-top: 16px;

  select {
    border: none;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.themeColors.text};
  }

  input {
    border: none;

    -webkit-appearance: auto;
    background: transparent !important;
  }
`;
const Separator = styled.div`
  padding: 36px 0 0 0;
  margin: 0 0 24px 0;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
`;
const PaperTransferHeading = styled.div`
  padding: 8px 0 24px 0;
  text-align: center;
`;
const PaperAccountLoadingWrapper = styled.div`
  text-align: center;
  padding: 8px 0 24px 0;
`;
const TransferCompletedWrapper = styled.div`
  text-align: center;
`;
const TransferCompletedIconWrapper = styled.div`
  svg {
    height: 36px;
    width: 36px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

const BankAccountListItem = ({ bankAccount, canRemove }) => {
  const {
    id,
    account_type,
    account_number_ending,
    needs_micro_deposit_verification,
    micro_deposits_ready,
  } = bankAccount;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isRemoving, setIsRemoving] = React.useState(false);

  const handleVerifyNow = () => {
    navigate(ROUTES.VERIFY_BANK_ACCOUNT.build());
  };

  const handleRemoveBankAccount = async () => {
    setIsRemoving(true);
    const { ok, errorMessage } = await removeBankAccount(id)(dispatch);
    setIsRemoving(false);
    if (ok) {
      const modal = {
        contentComponent: createBasicErrorModalWithOkayButton('Bank account was removed.', () => hideModal()(dispatch)),
        size: 'wide',
      };
      showModal(modal)(dispatch);
    } else {
      const modal = {
        contentComponent: createBasicErrorModalWithOkayButton(errorMessage, () => hideModal()(dispatch)),
        size: 'wide',
      };
      showModal(modal)(dispatch);
    }
  };
  const handleRemoveBankAccountClick = () => {
    const onContinue = () => {
      hideModal()(dispatch);
      handleRemoveBankAccount();
    };
    const onCancel = () => {
      hideModal()(dispatch);
    };
    const modal = {
      contentComponent: createConfirmationModal({
        message: 'Are you sure you want to remove this bank account? It will have to be re-linked to be used again.',
        onContinue,
        onCancel,
      }),
      size: 'wide',
    };
    showModal(modal)(dispatch);
  };

  return (
    <BankAccountListItemWrapper>
      <Row style={{ justifyContent: 'space-between' }}>
        <div>
          <div>
            <Body5>{account_type}</Body5>
            <Body5>{` (...${account_number_ending})`}</Body5>
          </div>
          {needs_micro_deposit_verification && !micro_deposits_ready && (
            <div style={{ paddingTop: '8px' }}>
              <WarningNotice
                message={'Verification is still needed, but we will let you know when the microdeposits will be ready'}
              />
            </div>
          )}
        </div>
        {needs_micro_deposit_verification && micro_deposits_ready && (
          <div>
            <FlatButton onClick={handleVerifyNow}>Verify Now</FlatButton>
          </div>
        )}
        {canRemove && (
          <RemoveButtonWrapper>
            {isRemoving ? (
              <RemovingIconWrapper>
                <UnstyledLoadingSpinner />
              </RemovingIconWrapper>
            ) : (
              <FlatButton transparent onClick={handleRemoveBankAccountClick}>
                Remove
              </FlatButton>
            )}
          </RemoveButtonWrapper>
        )}
      </Row>
    </BankAccountListItemWrapper>
  );
};

const LinkedBankAccountsList = () => {
  const bankAccounts = useLinkedBankAccounts();

  return (
    <LinkedBanksWrapper>
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <Body6>Linked Bank Accounts</Body6>
        {bankAccounts.length === 1 && (
          <InfoIcon
            id={'remove_bank_account_tooltip'}
            word={`tooltip_only_bank_account`}
            position={`right`}
            style={{ top: '8px', right: '-24px' }}
          />
        )}
      </div>

      {bankAccounts.length > 0 ? (
        <BankAccountListWrapper>
          {bankAccounts.map((b) => (
            <BankAccountListItem key={b.id} bankAccount={b} canRemove={bankAccounts.length > 1} />
          ))}
        </BankAccountListWrapper>
      ) : (
        <div style={{ padding: '24px 0 0 0 ' }}>You have not linked any of your bank accounts.</div>
      )}
    </LinkedBanksWrapper>
  );
};

const FundingActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bankAccounts = useBankAccounts();
  const isLiveTrading = useIsLiveTrading();
  const hasMaxLinkedBankAccountsReached = bankAccounts.length > 2;
  const recurringDeposits = useRecurringDeposits();

  const handleDepositPress = () => {
    navigate(ROUTES.DEPOSIT_FUNDS.build());
  };
  const handleWithdrawalPress = () => {
    navigate(ROUTES.WITHDRAW_FUNDS.build());
  };
  const handleAddBankAccountPress = () => {
    navigate(ROUTES.LINK_BANK.build());
  };
  const handleManageRecurringDepositsPress = () => {
    navigate(ROUTES.RECURRING_DEPOSITS.build());
    TrackingEvents.funding.PRESS_MANAGE_RECURRING_DEPOSITS.send();
  };

  return (
    <FundingActionsWrapper>
      {bankAccounts.length > 0 ? (
        <>
          <ButtonWrapper onClick={handleDepositPress}>
            <IconWrapper>
              <PlusCircle />
            </IconWrapper>
            <ButtonTextWrapper>
              <div>
                <Body1 bold>Transfer Money to Tornado</Body1>
              </div>
              <div>
                <Body5>Transfer money from your bank account to your Tornado account</Body5>
              </div>
            </ButtonTextWrapper>
          </ButtonWrapper>

          <ButtonWrapper onClick={handleWithdrawalPress}>
            <IconWrapper>
              <MinusCircle />
            </IconWrapper>
            <ButtonTextWrapper>
              <div>
                <Body1 bold>Transfer Money to Bank</Body1>
              </div>
              <div>
                <Body5>Transfer money from your Tornado account to your bank account</Body5>
              </div>
            </ButtonTextWrapper>
          </ButtonWrapper>
        </>
      ) : null}

      {hasMaxLinkedBankAccountsReached ? (
        <DisabledBankLinkWrapper>
          <DisabledBankLink />
          <div style={{ padding: '8px 16px 0 16px' }}>
            <WarningNotice
              message={
                'Cannot have more than 3 active bank accounts at a time, please remove one to link a different bank account'
              }
            />
          </div>
          <ButtonWrapper onClick={() => false} style={{ pointerEvents: 'none' }}>
            <BankIconWrapper>
              <Bank />
              <BankPlusWrapper>
                <PlusCircle />
              </BankPlusWrapper>
            </BankIconWrapper>
            <ButtonTextWrapper>
              <div>
                <Body1 bold>Link Bank Account</Body1>
              </div>
              <div>
                <Body5>Link your bank account instantly with your bank login or with microdeposit verification</Body5>
              </div>
            </ButtonTextWrapper>
          </ButtonWrapper>
        </DisabledBankLinkWrapper>
      ) : (
        <ButtonWrapper onClick={handleAddBankAccountPress}>
          <BankIconWrapper>
            <Bank />
            <BankPlusWrapper>
              <PlusCircle />
            </BankPlusWrapper>
          </BankIconWrapper>
          <ButtonTextWrapper>
            <div>
              <Body1 bold>Link Bank Account</Body1>
            </div>
            <div>
              <Body5>Link your bank account instantly with your bank login or with microdeposit verification</Body5>
            </div>
          </ButtonTextWrapper>
        </ButtonWrapper>
      )}
      {false && (
        <ButtonWrapper onClick={handleManageRecurringDepositsPress}>
          <BankIconWrapper>
            <Bank />
            <BankPlusWrapper>
              <PlusCircle />
            </BankPlusWrapper>
          </BankIconWrapper>
          <ButtonTextWrapper>
            <div>
              <Body1 bold>Manage Recurring Deposits</Body1>
            </div>
            <div>
              <Body5>Manage your recurring deposit schedule from your bank account to your Tornado account</Body5>
            </div>
          </ButtonTextWrapper>
        </ButtonWrapper>
      )}
    </FundingActionsWrapper>
  );
};

const massageFormData = (v) => {
  return {
    ...v,
    amount: customParseFloat(v.amount),
  };
};

const PaperFunding = ({}) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const isAllowLiveTrading = isCurrentUserAllowLiveTrading(currentUser);
  const paperBankAccount = usePaperBankAccount();
  const bankAccountId = paperBankAccount?.account_id || null;

  const [isReady, setIsReady] = React.useState(false);
  const [isTransferCompleted, setTransferCompleted] = React.useState(false);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const [value, setValue] = React.useState('');
  const [fundingOperation, setFundingOperation] = React.useState('deposit');

  React.useEffect(() => {
    const init = async () => {
      const { ok } = await getBankAccounts()(dispatch);
      if (ok) {
        setIsReady(true);
      } else {
        setTimeout(init, 1000);
      }
    };
    init();
  }, []);

  const handleChange = (n) => (v) => {
    setValue(v);
  };

  const handleFundingOperationChange = (n) => (v) => {
    setFundingOperation(v);
  };

  const submitForm = React.useCallback(
    (values) => {
      const apiFormData = massageFormData(values);
      setIsSubmitting(true);
      return postPaperTransfer(apiFormData)(dispatch);
    },
    [bankAccountId]
  );

  const handleClear = () => {
    setTransferCompleted(false);
    setValue('');
    setFormError(null);
  };

  const handleSubmit = React.useCallback(
    async () => {
      const enteredAmount = value !== '' && parseFloat(value) > 0;
      if (!enteredAmount) {
        setFormError('You must enter an amount');
        return {
          ok: false,
        };
      }

      const { ok, errorMessage } = await submitForm({
        account_id: bankAccountId,
        amount: value,
        funding_operation: fundingOperation,
      });
      if (ok) {
        setIsSubmitting(false);
        setTransferCompleted(true);
      } else {
        setFormError(errorMessage || 'Something went wrong, please try again');
        setIsSubmitting(false);
        return {
          ok: false,
        };
      }
    },
    [bankAccountId, value]
  );

  const fundingOperationItems = [
    createDropdownItem({ text: 'Deposit', value: 'deposit' }),
    createDropdownItem({ text: 'Withdraw', value: 'withdraw' }),
  ];

  return (
    <Page width={'600px'}>
      <Content>
        <H3>Simulated Funding</H3>
        <div>
          <WarningNotice message={'This is a simulated account - no real money is at risk.'} />
        </div>

        {isAllowLiveTrading && (
          <BannerStyle>
            <OpenBrokerageAccountBanner />
          </BannerStyle>
        )}

        <Separator />

        {isTransferCompleted ? (
          <TransferCompletedWrapper>
            <TransferCompletedIconWrapper>
              <CheckCircleFilled />
              <H3>Transfer Completed</H3>
            </TransferCompletedIconWrapper>
            <ActionsWrapper>
              <Container fullWidth centerAll>
                <FlatButton fullWidth onClick={handleClear}>
                  Clear
                </FlatButton>
              </Container>
            </ActionsWrapper>
          </TransferCompletedWrapper>
        ) : (
          <div>
            <PaperTransferHeading>
              <Body6>Transfer Simulated Funds</Body6>
            </PaperTransferHeading>

            {isReady ? (
              <div>
                <InputWrapper>
                  <FloatingLabelDropdown
                    name={'fundingOperation'}
                    value={fundingOperation}
                    label={'Transfer Type'}
                    items={fundingOperationItems}
                    onChange={handleFundingOperationChange}
                    error={null}
                  />
                </InputWrapper>
                <InputWrapper>
                  <FloatingLabelTextInput
                    name={'amount'}
                    value={value}
                    prefix={'$'}
                    label={'Amount'}
                    onChange={handleChange}
                    maskType={INPUT_MASK_TYPES.dollarAmount}
                    error={null}
                    autoFocus
                  />
                </InputWrapper>

                <ActionsWrapper>
                  <FormState error={formError} isSubmitting={isSubmitting} />
                  <Container fullWidth centerAll>
                    <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleSubmit}>
                      Submit
                    </FlatButton>
                  </Container>
                </ActionsWrapper>
              </div>
            ) : (
              <PaperAccountLoadingWrapper>
                <UnstyledLoadingSpinner />
              </PaperAccountLoadingWrapper>
            )}
          </div>
        )}
      </Content>
    </Page>
  );
};

const LiveFunding = ({}) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const { isShowingFundingPromo, isEligibleForDepositPromo } = usePromotions();

  React.useEffect(() => {
    getBankAccounts()(dispatch);
  }, []);

  return (
    <Page width={'600px'}>
      <Content>
        <H3>Funding</H3>

        {isShowingFundingPromo && !isEligibleForDepositPromo && (
          <BannerStyle>
            <FundingBonusBannerRenderer />
          </BannerStyle>
        )}
        {isEligibleForDepositPromo && (
          <BannerStyle>
            <DepositBonusBanner />
          </BannerStyle>
        )}

        <FundingActions />
        <div style={{ padding: '8px' }} />
        <LinkedBankAccountsList />
        <div style={{ padding: '12px' }} />
        <TransferHistoryList />
        <div style={{ padding: '12px' }} />
      </Content>
    </Page>
  );
};

export const Funding = ({}) => {
  const isLiveTrading = useIsLiveTrading();

  return isLiveTrading ? <LiveFunding /> : <PaperFunding />;
};
