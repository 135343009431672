import { INCREMENT_HORIZONTAL_LIST, DECREMENT_HORIZONTAL_LIST, RESET_HORIZONTAL_LIST } from '../constants';

export const incrementHorizontalListPage = () => {
  return function (dispatch) {
    dispatch({
      type: INCREMENT_HORIZONTAL_LIST,
    });
  };
};

export const decrementHorizontalListPage = () => {
  return function (dispatch) {
    dispatch({
      type: DECREMENT_HORIZONTAL_LIST,
    });
  };
};

export const resetHorizontalListPage = () => {
  return function (dispatch) {
    dispatch({
      type: RESET_HORIZONTAL_LIST,
    });
  };
};
