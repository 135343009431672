import React from 'react';
import styled from 'styled-components';
import { colorPalette } from '../../../../../lib/nvstr-utils.es';
import { LearnLessonBody } from '../../../../../components/learn/subComponents';
import { FlatButton, BaseButtonWrapper } from '../../../../../components/buttons';
import { DepositForm, SubmitButtonWrapper } from '../../../../forms/DepositForm';
import { hexToRgbA } from '../../../../../../helpers/generalHelpers';
import {
  DepositOptionsBody,
  DepositOptionWrapper,
  DepositOptionValue,
  DepositOptionBonus,
} from '../../../../../components/funding/DepositAmountPicker';
import { LinkBankButtonWrapper } from '../../../../buttons/LinkBankButton';
import { useBankAccounts } from '../../../../../hooks/funding/useBankAccounts';

const Wrapper = styled.div`
  padding: 0 8px;
`;
const BodyWrapper = styled.div``;
const FormWrapper = styled.div`
  margin: 24px 0 0 0;
  padding: 16px 24px;
  background: ${hexToRgbA(colorPalette.primary.oak, 0.6)};
  border-radius: 5px;

  .sc-input-wrapper {
    border-color: ${colorPalette.primary.charcoal};
  }
  select {
    border-color: ${colorPalette.primary.charcoal} !important;
  }

  .base-text,
  .input-text-prefix,
  input,
  select,
  label {
    color: ${colorPalette.primary.charcoal} !important;
  }
  input {
    line-height: 24px;
  }

  ${DepositOptionsBody} {
    ${DepositOptionWrapper} {
      border-color: ${colorPalette.primary.charcoal};

      &:hover {
        border-color: ${colorPalette.primary.charcoal};
        background: ${colorPalette.primary.charcoal};

        * {
          color: ${colorPalette.primary.oak} !important;
        }
      }
      &.is-selected {
        border-color: ${colorPalette.primary.charcoal};
        background: ${colorPalette.primary.charcoal};

        * {
          color: ${colorPalette.primary.oak} !important;
        }
      }
    }

    @media (max-width: 550px) {
      flex-wrap: wrap;

      ${DepositOptionWrapper} {
        //max-width: 100px;
        height: 46px;
        min-width: 70px;
        width: auto;
      }

      ${DepositOptionValue} {
        * {
          font-size: 12px !important;
          line-height: 18px !important;
        }
      }
      ${DepositOptionBonus} {
        * {
          font-size: 10px !important;
          line-height: 18px !important;
        }
      }
    }
  }

  ${LinkBankButtonWrapper} {
    ${BaseButtonWrapper} {
      border: none;
      background: none;
      color: ${colorPalette.primary.charcoal};

      * {
        color: ${colorPalette.primary.charcoal};
      }

      :hover {
        opacity: 0.8;
        border: none;
        background: none;
        color: ${colorPalette.primary.charcoal};

        * {
          color: ${colorPalette.primary.charcoal};
        }
      }
    }

    .no-bank-linked {
      transition: all 200ms;
      border: ${colorPalette.primary.charcoal};
      background: ${colorPalette.primary.charcoal};
      color: ${colorPalette.primary.oak};

      * {
        color: ${colorPalette.primary.oak};
      }

      :hover {
        opacity: 0.8;
        border: ${colorPalette.primary.charcoal};
        background: ${colorPalette.primary.charcoal};
        color: ${colorPalette.primary.oak};

        * {
          color: ${colorPalette.primary.oak};
        }
      }
    }
  }
  ${SubmitButtonWrapper} {
    ${BaseButtonWrapper} {
      border-color: ${colorPalette.primary.charcoal};
      background: ${colorPalette.primary.charcoal};

      * {
        color: ${colorPalette.primary.oak};
      }

      :hover {
        border-color: ${colorPalette.primary.charcoal};
        background: ${colorPalette.primary.charcoal};

        * {
          color: ${colorPalette.primary.oak};
        }
      }
    }
  }
`;

export const LearnDepositAddDeposit = ({ onDepositComplete, onComplete }) => {
  const textColor = colorPalette.primary.charcoal;

  const bankAccounts = useBankAccounts();
  const [cachedFormValues, setCachedFormValues] = React.useState({
    account_id: bankAccounts?.length > 0 ? bankAccounts[0].id : '',
    value: '100',
  });

  const onEndLessonClick = () => {
    onComplete();
  };
  const handleDepositComplete = (isComplete, serverMessage, amount) => {
    onDepositComplete(amount);
  };

  const textBodyData = [
    {
      text: "Let's start your investing journey with a deposit.",
    },
  ];
  const tempTextBodyData = [
    {
      text: 'You can make a deposit anytime in the funding page.',
    },
  ];

  return (
    <Wrapper>
      <BodyWrapper>
        <LearnLessonBody data={tempTextBodyData} textColor={textColor} />
      </BodyWrapper>
      {/*<div style={{ padding: '24px 0 0 0', textAlign: 'center' }}>*/}
      {/*  <FlatButton*/}
      {/*    transparent*/}
      {/*    height={20}*/}
      {/*    onClick={onEndLessonClick}*/}
      {/*    fullWidth*/}
      {/*    color={textColor}*/}
      {/*    buttonProps={{ overrideContext: 'Lesson Completed' }}*/}
      {/*  >*/}
      {/*    Complete*/}
      {/*  </FlatButton>*/}
      {/*</div>*/}
    </Wrapper>
  );
  return (
    <Wrapper>
      <BodyWrapper>
        <LearnLessonBody data={textBodyData} textColor={textColor} />
        <FormWrapper>
          <DepositForm
            initialFormValues={cachedFormValues}
            onContinue={handleDepositComplete}
            showDepositPicker
            useReauthHandler
            showLinkBankAccountButton
            allowEmailAuth
          />
        </FormWrapper>
      </BodyWrapper>
      <div style={{ padding: '24px 0 0 0', textAlign: 'center' }}>
        <FlatButton
          transparent
          height={20}
          onClick={onEndLessonClick}
          fullWidth
          color={textColor}
          buttonProps={{ overrideContext: 'Lesson Completed' }}
        >
          END LESSON WITHOUT DEPOSIT
        </FlatButton>
      </div>
    </Wrapper>
  );
};
