import React from 'react';
import OrdersPlacedListHeading from './components/OrdersPlacedListHeading';
import Order from './Order';

const OrdersAwaitingApproval = (props) => {
  const {
    isMobile,
    windowWidth,

    isRefreshing,
    showRefreshButton,
    handleRefresh,

    ordersAwaitingApprovalList,

    refreshUserData,
  } = props;

  const noOrdersAwaitingApproval = !ordersAwaitingApprovalList || !(ordersAwaitingApprovalList.length > 0);
  if (noOrdersAwaitingApproval) {
    return null;
  }

  return (
    <div className={`orders-placed-container`}>
      <div className={`header`}>
        <h2 className="heading  ">Orders Awaiting Approval</h2>
      </div>
      <OrdersPlacedListHeading
        isMobile={isMobile}
        windowWidth={windowWidth}
        isRefreshing={isRefreshing}
        showRefreshButton={showRefreshButton}
        handleRefresh={handleRefresh}
      />
      <div className={`orders-placed-list`}>
        {ordersAwaitingApprovalList.map((order) => (
          <Order
            key={`order-${order.order_id}`}
            order={order}
            refreshUserData={refreshUserData}
            windowWidth={windowWidth}
            allowCancel
          />
        ))}
      </div>
    </div>
  );
};

export default OrdersAwaitingApproval;
