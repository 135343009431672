import React from 'react';
import { useSecurity } from '@src/main/hooks/securities/useSecurity';
import { ELEMENT_COLOR_NAMES } from '@src/main/constants/elementColors';
import { TrackingEvents } from '@src/utils/tracking/events';
import { Container, Spacing } from '@src/main/components/ui';
import LoadingIcon from '@src/components/misc/LoadingIcon';
import { Body2, Body5, Body6, Row, Text } from '@src/main/lib/nvstr-common-ui.es';
import NumberWithChangeIndicator from '@src/components/UI/NumberWithChangeIndicator';
import { returnChangeSymbolPrefix } from '@src/helpers/numberHelpers';
import { formatDataValue, securityDataFormatTable } from '@src/helpers/securitiesHelpers';
import { ArrowRight } from '@src/main/assets/svgs/ArrowRight';
import styled from 'styled-components';
import { useNavigateToSecurity } from '@src/main/hooks/securities/useNavigateToSecurity';
import { usePriceData } from '../../hooks/securities/usePriceData';
import { formatValueTo, DISPLAY_FORMAT_TYPES } from '../../utils/numbers';

export const SECURITY_LIST_CONTEXTS = {
  STOCKS_YOU_MAY_BE_INTERESTED_IN: 'Stocks you may be interested in',
  EXPLORE_PRESET_FILTER: 'Explore Preset Filter',
  VIEWPOINT_COVERED_STOCKS: 'Viewpoint Covered Stocks',
  TRENDING_STOCKS: 'Trending Stocks',
};

const SecurityRowWrapper = styled.div`
  height: 80px;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.appBackground};
  background-color: ${({ theme }) => theme.themeColors.component};

  :first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  :last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  :hover {
    opacity: 0.9;
    cursor: pointer;
  }

  @media (max-width: 430px) {
    margin: 0 -16px;
    border-radius: 0;
  }
`;
const NameWrapper = styled.div`
  max-width: 272px;
  padding-right: 4px;

  @media (max-width: 500px) {
    max-width: 160px;
  }

  @media (max-width: 382px) {
    max-width: 128px;
  }
`;

const IconWrapper = styled.div`
  svg {
    height: 22px;
    width: 22px;
  }

  path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function SecurityIdentifier({ name, symbol }) {
  return (
    <Container>
      <Body2>{symbol}</Body2>

      <NameWrapper>
        <Body5 truncate thin>
          {name}
        </Body5>
      </NameWrapper>
    </Container>
  );
}

const SecurityListRow = ({ securityId, context, isFirst, isLast }) => {
  const navigateToSecurity = useNavigateToSecurity(securityId);

  const security = useSecurity(securityId);
  const symbol = security?.symbol;
  const name = security?.name;

  const { price: currentPrice, change: currentPriceChange, percentChange: currentPriceChangePercent } = usePriceData(
    securityId
  );

  const handlePress = () => {
    navigateToSecurity();
    TrackingEvents.security.PRESS_VIEW_SECURITY_LIST_ITEM.send({
      'Security ID': securityId,
      Context: context,
    });
  };

  return (
    <SecurityRowWrapper onClick={handlePress}>
      {!security ? (
        <Container flex={1} centerAll>
          <LoadingIcon />
        </Container>
      ) : (
        <Container row spaceBetween verticallyCenter>
          <SecurityIdentifier name={name} symbol={symbol} />

          <Container row verticallyCenter style={{ minWidth: '160px', flexGrow: 1, justifyContent: 'flex-end' }}>
            {isNaN(currentPrice) ? (
              <Container centerAll>
                <LoadingIcon />
              </Container>
            ) : (
              <Container
                row
                verticallyCenter
                style={{
                  textAlign: 'right',
                }}
              >
                <Container>
                  <Body6>{formatValueTo(DISPLAY_FORMAT_TYPES.STOCK_PRICE, currentPrice)}</Body6>
                  <Row vAlign="center">
                    <NumberWithChangeIndicator
                      valueFormat={'price'}
                      value={currentPriceChange}
                      customDisplayValue={`${returnChangeSymbolPrefix(
                        currentPriceChange
                      )}${securityDataFormatTable.float(Math.abs(currentPriceChange))}`}
                      coloredText
                    />
                    <div style={{ paddingLeft: '6px' }} />
                    <NumberWithChangeIndicator
                      valueFormat={'percentage'}
                      value={currentPriceChangePercent}
                      customDisplayValue={`${returnChangeSymbolPrefix(
                        currentPriceChangePercent
                      )}${securityDataFormatTable.float(Math.abs(currentPriceChangePercent))}%`}
                      coloredText
                      hideIcon
                    />
                  </Row>
                </Container>
              </Container>
            )}

            <Container verticallyCenter left={8}>
              <IconWrapper>
                <ArrowRight />
              </IconWrapper>
            </Container>
          </Container>
        </Container>
      )}
    </SecurityRowWrapper>
  );
};

const SecurityList = (props) => {
  const { isLoading, securityIds } = props;

  if (isLoading) {
    return (
      <Container height={75} centerAll>
        <LoadingIcon icon="fading-3balls" size="large" style={{}} />
      </Container>
    );
  }

  if (!securityIds) {
    return null;
  }

  return securityIds.map((id, i) => <SecurityListRow {...props} key={id} securityId={id} index={i} />);
};

export default SecurityList;
