import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import SectionHeading from './components/SectionHeading';
import Button from '../../components/buttons/Button';
import TextField from '../../components/form/TextField';
import RenderAfterDelay from '../ExtraFunctionalityComponents/RenderAfterDelay';

import { createInputComponent, handleFocus, handleBlur, handleChange } from '../../helpers/formHelpers';
import { FlatButton } from '@src/main/components/buttons';
import { Container } from '@src/main/components/ui';
import styled from 'styled-components';

const ActionWrapper = styled.div`
  text-align: center;
  button {
    padding: 8px 16px;
    width: 160px;
  }
`;
class EditEmailForm extends React.Component {
  constructor() {
    super();
    this.state = {
      _isSubmitting: false,
      _wasSubmitted: false,
      _showChangeEmailButton: true,

      formData: {},
      focusedField: null,
      focusNote: {},
      errors: {},

      inputs: [
        {
          type: TextField,
          typeName: 'TextField',
          name: 'email',
          label: 'Email',
          required: true,
          props: {
            autocompleteValue: 'off',
          },
        },
        {
          type: TextField,
          typeName: 'TextField',
          name: 'current_password',
          label: 'Current Password',
          required: true,
          props: {
            customWrapperClass: 'password-field',
            inputType: 'password',
            autocompleteValue: 'new-password',
          },
        },
      ],
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.formData.email) {
      const currentUser = props.currentUser;
      const formData = {
        email: currentUser.email,
      };
      return { formData };
    }
    return null;
  }

  render() {
    return (
      <div className={`edit-email-form-container`}>
        <Container bottom={16}>
          <SectionHeading headingText={'Change Email Address'} />
        </Container>
        {this._shouldShowChangeEmailButton() ? (
          <ActionWrapper className={`open-email-change-form-button-container action-container`}>
            <FlatButton onClick={this.handleChangeEmailButtonClick}>Change Email</FlatButton>
          </ActionWrapper>
        ) : (
          <div className={`form-input-container`}>{this._renderInputs()}</div>
        )}
        {!this._shouldShowChangeEmailButton() && (
          <div className={`submit-change-email-form-container`}>
            {this.isSubmittingFormData() ? (
              <FlatButton handleClick={() => false}>Submitting</FlatButton>
            ) : (
              <FlatButton onClick={this.handleSubmitClick}>Submit</FlatButton>
            )}
          </div>
        )}
      </div>
    );
  }

  _shouldShowChangeEmailButton = () => this.state._showChangeEmailButton;

  _resetChangeEmailForm = () => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        current_password: '',
      },
      _showChangeEmailButton: true,
    }));
  };

  handleChangeEmailButtonClick = () => {
    this._toggleShowChangeEmailButton();
  };

  _toggleShowChangeEmailButton = () => {
    this.setState((prevState) => ({
      _showChangeEmailButton: !prevState._showChangeEmailButton,
    }));
  };

  handleSubmitClick = () => {
    this.submitNewFieldData();
  };

  submitNewFieldData = () => {
    const password = this.getCurrentFormValueForCurrentPasswordField();
    const email = this.getCurrentFormValueForField('email');
    this._startSaving();
    this.props.actions.changeEmail(password, email).then((statusObj) => {
      const wasSuccess = statusObj.success;
      this._stopSaving();
      if (wasSuccess) {
        this._setWasSubmitted();
        this._showSaveSuccessMessage();
        this._resetChangeEmailForm();
      } else {
        const error = statusObj.error;
        this._resetWasSubmitted();
        this._showSaveFailedErrorMessage(error);
      }
    });
  };

  isSubmittingFormData = () => this.state._isSubmitting;

  _wasSubmitted = () => this.state._wasSubmitted;

  getCurrentFormValueForCurrentPasswordField = () => this.getCurrentFormValueForField('current_password') || '';

  getCurrentSavedValueForField = (fieldName) => this.props.currentUser[fieldName];

  getCurrentFormValueForField = (fieldName) => this.state.formData[fieldName];

  _hasFieldValueChanged = () =>
    this.getCurrentSavedValueForField('email') !== this.getCurrentFormValueForField('email');

  _startSaving = () => {
    this.setState(() => ({
      _isSubmitting: true,
    }));
  };

  _stopSaving = () => {
    this.setState(() => ({
      _isSubmitting: false,
    }));
  };

  _stopSaving = () => {
    this.setState(() => ({
      _isSubmitting: false,
    }));
  };

  _setWasSubmitted = () => {
    this.setState(() => ({
      _wasSubmitted: true,
    }));
  };

  _resetWasSubmitted = () => {
    this.setState(() => ({
      _wasSubmitted: false,
    }));
  };

  _showSaveSuccessMessage = () => {
    const message = 'An email was sent to this new email address for confirmation.';
    const contentComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {message}
      </div>
    );
    this.props.actions.showModal({
      contentComponent,
      size: 'wide',
      dismissable: true,
    });
  };

  _showSaveFailedErrorMessage = (message) => {
    message = message || 'Something went wrong. Please reload page and try again.';
    const contentComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {message}
      </div>
    );
    this.props.actions.showModal({
      contentComponent,
      size: 'wide',
      dismissable: true,
    });
  };

  _renderInputs = () => this._returnFormInputs().map((input) => this._createInputElement(input));

  _returnFormInputs = () => this.state.inputs;

  _createInputElement = (input) => {
    return createInputComponent.call(this, input, this._returnInputDefaultProps.call(this));
  };

  _returnInputDefaultProps() {
    return {
      handleFocus: handleFocus.bind(this),
      handleBlur: handleBlur.bind(this),
      handleChange: handleChange.bind(this),
      isFocused: function (name) {
        return this.state.focusedField === name;
      }.bind(this),
      getFocusNote: function (name) {
        if ('focusNote' in this.state) {
          return this.state.focusNote[name];
        }
      }.bind(this),
      getErrorMessage: function (name) {
        return this.state.errors[name];
      }.bind(this),
      getValue: function (name) {
        return this.state.formData[name];
      }.bind(this),
    };
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEmailForm);
