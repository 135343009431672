import {
  ADD_CURRENT_USER_QUICK_INFO,
  LOADING_CURRENT_USER_CONNECTIONS,
  ADD_CURRENT_USER_CONNECTIONS,
  LOADING_USER_CONNECTIONS,
  ADD_USER_CONNECTIONS,
  ACCEPT_CONNECTION_REQUEST,
  REMOVE_CONNECTION,
  REMOVE_CONNECTION_FAILURE,
} from '../constants';

const defaultState = {
  // refactor currentUserId out of this reducer slice
  currentUserId: null,

  userDict: {
    /*
      [userId]: {
        if no connections key but userId is available its loading
        connections: [<array of userIds>]
      }
     */
  },
};

export default function userConnections(state = defaultState, action) {
  let userId, connectUserId, currentUserId, connections, connectionsById;

  switch (action.type) {
    case ADD_CURRENT_USER_QUICK_INFO: {
      currentUserId = action.payload.user_id;
      if (!state.currentUserId) {
        return {
          ...state,
          currentUserId,
        };
      }
      return state;
    }

    case LOADING_CURRENT_USER_CONNECTIONS: {
      userId = state.currentUserId;
      return {
        ...state,
        userDict: {
          ...state.userDict,
          [userId]: {},
        },
      };
    }

    case ADD_CURRENT_USER_CONNECTIONS: {
      userId = state.currentUserId;
      connections = action.payload.connections;
      connectionsById = connections ? connections.map((user) => user.user_id) : [];
      return {
        ...state,
        userDict: {
          ...state.userDict,
          [userId]: {
            connections: connectionsById,
          },
        },
      };
    }

    case LOADING_USER_CONNECTIONS: {
      userId = action.payload.userId;
      return {
        ...state,
        userDict: {
          ...state.userDict,
          [userId]: {},
        },
      };
    }

    case ADD_USER_CONNECTIONS: {
      connections = action.payload.connections;
      connectionsById = connections ? connections.map((user) => user.user_id) : [];
      userId = action.payload.userId;
      return {
        ...state,
        userDict: {
          ...state.userDict,
          [userId]: {
            connections: connectionsById,
          },
        },
      };
    }

    case ACCEPT_CONNECTION_REQUEST: {
      userId = action.payload.userId;
      connectUserId = action.payload.connectUserId;

      if (userId === connectUserId) return state;

      const currentConnections = state.userDict[userId].connections || [];
      return {
        ...state,
        userDict: {
          ...state.userDict,
          [userId]: {
            connections: [...currentConnections, connectUserId],
          },
        },
      };
    }

    case REMOVE_CONNECTION: {
      userId = action.payload.userId;
      currentUserId = action.payload.currentUserId;
      const currentConnections = state.userDict[currentUserId].connections || [];
      const filteredConnections = currentConnections ? currentConnections.filter((id) => id !== userId) : [];
      return {
        ...state,
        userDict: {
          ...state.userDict,
          [currentUserId]: {
            connections: [...filteredConnections],
          },
        },
      };
    }

    case REMOVE_CONNECTION_FAILURE: {
      userId = action.payload.userId;
      currentUserId = action.payload.currentUserId;
      const currentConnections = state.userDict[currentUserId].connections || [];
      return {
        ...state,
        userDict: {
          ...state.userDict,
          [currentUserId]: {
            connections: [...currentConnections, userId],
          },
        },
      };
    }

    default:
      return state;
  }
}
