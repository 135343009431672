import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import * as Actions from '../../actions/index';
import Page from '../../components/layout/Page';
import { Body5 } from '../../main/lib/nvstr-common-ui.es';
import Dropdown from '../StandaloneInputs/DropdownWithStrings';
import Button from '../../components/buttons/Button';
import FormLoadingOverlay from '../../components/overlays/FormLoadingOverlay';
import Icon from '../../components/misc/Icon';
import { moment, returnThisYear, returnThisMonth, formatLocalizedDateTime } from '../../helpers/timeHelpers';
import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import styled from 'styled-components';
import { FlatButton } from '@src/main/components/buttons';

const DocWrapper = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Wrapper = styled.div`
  select {
    border-top: none;
    border-left: none;
    border-right: none;

    -webkit-appearance: auto;
    background: transparent !important;
  }
`;

class DocumentsPage extends React.Component {
  constructor() {
    super();
    this.state = {
      formData: {
        document_type: '',
        from_date: {
          month: 'Dec',
          year: '2018',
        },
        to_date: {
          month: returnThisMonth() || 'Jan',
          year: returnThisYear() || '',
        },
      },

      _isGettingDocuments: false,
      _wasErrorGettingDocuments: false,
      _documentResults: null,

      noDocumentsWithUserAccountType: false,

      _mousedOverDocumentResultIndex: null,
    };
  }

  componentDidMount() {
    this._logPageView();
    this._setPageTitle();
  }

  render() {
    const { noDocumentsWithUserAccountType } = this.state;
    return (
      <Wrapper>
        <Page className={'react-documents-page'}>
          <div style={{ paddingBottom: '24px' }}>
            {this._returnCurrentUser().is_account_closed && (
              <div className="account-closed">This account is closed</div>
            )}
            {!this._returnCurrentUser().is_account_closed &&
              this._returnCurrentUser().is_live_trading_account_closed && (
                <div className="account-closed">This brokerage account is closed</div>
              )}
            <div style={{ textAlign: 'left' }}>
              <h2>{'E-Documents'}</h2>
            </div>
          </div>
          {noDocumentsWithUserAccountType ? this._renderNoDocumentsWithUserAccountTypeMessage() : this._renderForm()}
          {this._isGettingDocuments() && (
            <FormLoadingOverlay
              show={this._isGettingDocuments()}
              spinner={{
                iconClass: 'circle-loading-spinner circle-loading-spinner-medium',
                style: { marginBottom: '-7px' },
              }}
              message={'Getting Documents'}
            />
          )}
          {this._isDocumentSearchComplete() &&
            (this._areDocumentResults() ? this._renderDocumentResults() : this._renderNoDocumentResults())}
        </Page>
      </Wrapper>
    );
  }

  _renderNoDocumentsWithUserAccountTypeMessage = () => {
    return (
      <div style={{ padding: '50px 0', textAlign: 'center' }}>Documents are not available for simulated accounts</div>
    );
  };

  _renderForm = () => {
    return (
      <div className={'document-search-form-container'}>
        {this._renderFormInputs()}
        {this._wasErrorGettingDocuments() && this._renderErrorMessage()}
        <div className={'document-search-submit'}>
          <FlatButton handleClick={this._handleSubmit}>Search</FlatButton>
        </div>
      </div>
    );
  };

  _renderFormInputs = () => {
    return (
      <div className={'document-search-form-inputs'}>
        <div className={'document-type-dropdown-container'}>
          <Dropdown
            name={'document_type'}
            label={'Document Type'}
            defaultValue={'Please select...'}
            value={this._returnDocumentTypeValue()}
            options={this._returnDocumentTypeSelectionOptions()}
            handleSelection={this._handleDropdownSelection}
          />
        </div>

        <div className={'document-type-dropdown-container'}>
          <div className={'document-date-range-label form-label'}>Date Range</div>

          <div className={'date-range-form-container'}>
            <div className={'from-date-range-inputs-container'}>
              <div className={'date-range-container'}>
                <Dropdown
                  name={'from-month'}
                  value={this._returnFromMonthValue()}
                  options={this._returnMonthSelectionOptions()}
                  handleSelection={this._handleDropdownSelection}
                />
                <Dropdown
                  name={'from-year'}
                  value={this._returnFromYearValue()}
                  options={this._returnYearSelectionOptions()}
                  handleSelection={this._handleDropdownSelection}
                />
              </div>
            </div>

            <div className={'date-range-separator'}>-</div>

            <div className={'to-date-range-inputs-container'}>
              <div className={'date-range-container'}>
                <Dropdown
                  name={'to-month'}
                  value={this._returnToMonthValue()}
                  options={this._returnMonthSelectionOptions()}
                  handleSelection={this._handleDropdownSelection}
                />
                <Dropdown
                  name={'to-year'}
                  value={this._returnToYearValue()}
                  options={this._returnYearSelectionOptions()}
                  handleSelection={this._handleDropdownSelection}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  _renderDocumentResults = () => {
    return (
      <div className={'document-results-container'}>
        <ul className={'document-results-list'}>
          {this._returnDocumentResults().map((documentData, i) => this._renderDocument(documentData, i))}
        </ul>
      </div>
    );
  };

  _renderDocument = (documentData, i) => {
    const { date, url } = documentData;

    const handleClick = () => {
      window.open(url, '_blank');
    };

    return (
      <DocWrapper
        key={`document-result-${date}-${i}`}
        className={'document-result'}
        onMouseOver={this._bindIndexToHandleMouseOver(i)}
      >
        <a className="download-link border-accent" href={url} target="_blank" rel="noopener noreferrer">
          <Icon icon="fa-file-text-o" size="medium" colorClassName={'secondary-text-color'} />
          <span className={'file-description-container'}>
            <span className={'file-name  '}>{date}</span>
            <span className={'file-type secondary-text-color'}>{`PDF - ${this._returnDocumentResultType()}`}</span>
          </span>
        </a>
        {this._isMousedOver(i) && (
          <span className={'download-button-container fade-in-up-quick'}>
            <FlatButton onClick={handleClick}>Download</FlatButton>
          </span>
        )}
      </DocWrapper>
    );
  };

  _renderErrorMessage = () => {
    return (
      <div className={'form-error-message-container'}>
        <span className={'warning-icon-container'}>
          <Icon icon={'fa-exclamation-circle'} colorClassName={'error-text-color'} size={'small'} />
        </span>
        <p>{this._returnErrorMessage()}</p>
      </div>
    );
  };

  _renderNoDocumentResults = () => {
    return (
      <div className={'document-results-container'}>
        <p className={'no-documents-found-message'}>There are no documents available meeting the search criteria.</p>
      </div>
    );
  };

  _returnCurrentUser = () => this.props.currentUser;

  _returnDocumentResultType = () => this.state._documentResultType;

  _convertDocumentTypeToFileType = (documentType) => {
    const lowercaseDocType = documentType.toLowerCase();
    const dict = {
      statements: 'Statement',
      confirms: 'Confirm',
    };
    return dict[lowercaseDocType] || documentType;
  };

  _setDocumentResultType = (type) => {
    this.setState(() => ({
      _documentResultType: this._convertDocumentTypeToFileType(type),
    }));
  };

  _isGettingDocuments = () => this.state._isGettingDocuments;

  _convertAPIErrorMessageToDisplayString = (error) => {
    const dict = {
      'document_type is a required parameter': 'Please select a Document Type',
      'Missing live trading account': 'Documents are not available. Your live account is not currently active',
      'Documents are not available for simulated accounts': 'Documents are not available for simulated accounts',
      'No current user': 'Session timed out. Please login to continue',
    };
    if (error === 'No current user') {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    return dict[error] || 'Something went wrong. Please try again later.';
  };

  _returnErrorMessage = () => this._wasErrorGettingDocuments();
  _wasErrorGettingDocuments = () => this.state._wasErrorGettingDocuments;

  _clearWasErrorGettingDocuments = () => {
    this.setState(() => ({
      _wasErrorGettingDocuments: false,
    }));
  };

  _setDocumentResults = (docs) => {
    this.setState(() => ({
      _documentResults: docs,
    }));
  };
  _clearDocumentResults = () => {
    this.setState(() => ({
      _documentResults: null,
      _documentResultType: null,
    }));
  };

  _bindIndexToHandleMouseOver = (i) => {
    return () => this._handleMouseOver(i);
  };

  _handleMouseOver = (i) => {
    this._setMouseOver(i);
  };

  _returnMousedOverDocumentResultIndex = () => this.state._mousedOverDocumentResultIndex;
  _isMousedOver = (i) =>
    this._returnMousedOverDocumentResultIndex() === i &&
    !isUndefinedOrNull(this._returnMousedOverDocumentResultIndex());

  _setMouseOver = (i) => {
    this.setState(() => ({
      _mousedOverDocumentResultIndex: i,
    }));
  };

  _returnDocumentResults = () => this.state._documentResults;

  _areDocumentResults = () => this._isDocumentSearchComplete() && this._returnDocumentResults().length > 0;

  _isDocumentSearchComplete = () =>
    !isUndefinedOrNull(this._returnDocumentResults()) && Array.isArray(this._returnDocumentResults());

  _returnYearSelectionOptions = () => {
    var yearStart = 2018;
    var currentYear = parseInt(formatLocalizedDateTime('YYYY', moment()), 10);
    var years = [];
    for (let i = yearStart; i <= currentYear; i++) {
      years.push(i.toString());
    }
    return years;
  };

  _convertMonthStrToNum = (monthStr) => {
    const dict = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    };
    return dict[monthStr];
  };

  _returnMonthSelectionOptions = () => [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  _returnDocumentSearchRequestBodyData = () => ({
    document_type: this._returnDocumentTypeValue().toLowerCase(),
    start_date: `${this._convertMonthStrToNum(this._returnFromMonthValue())}-${this._returnFromYearValue()}`,
    end_date: `${this._convertMonthStrToNum(this._returnToMonthValue())}-${this._returnToYearValue()}`,
  });

  _returnDocumentTypeSelectionOptions = () => ['Statements', 'Confirms', 'Tax'];

  _returnFormData = () => this.state.formData;
  _returnFromDateRangeData = () => this._returnFormData().from_date;
  _returnToDateRangeData = () => this._returnFormData().to_date;

  _returnDocumentTypeValue = () => this._returnFormData().document_type;

  _returnFromMonthValue = () => this._returnFromDateRangeData().month;
  _returnFromYearValue = () => this._returnFromDateRangeData().year;
  _returnToMonthValue = () => this._returnToDateRangeData().month;
  _returnToYearValue = () => this._returnToDateRangeData().year;

  _handleDropdownSelection = (name, value) => {
    this._updateFormDataValue(name, value);
    this._clearWasErrorGettingDocuments();
  };

  _isValidDocumentType = (value) => this._returnDocumentTypeSelectionOptions().includes(value);

  _updateFormDataValue = (name, value) => {
    if (name === 'document_type') {
      if (this._isValidDocumentType(value)) {
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            [name]: value,
          },
        }));
      }
    } else {
      const dateRangeKey = name.split('-')[0];
      const dateRangeDateType = name.split('-')[1];
      if (['to', 'from'].includes(dateRangeKey)) {
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            [`${dateRangeKey}_date`]: {
              ...prevState.formData[`${dateRangeKey}_date`],
              [dateRangeDateType]: value,
            },
          },
        }));
      }
    }
  };

  _handleStartSearch = () => {
    this._clearWasErrorGettingDocuments();
    this._clearDocumentResults();
    this.setState(() => ({
      _isGettingDocuments: true,
    }));
    this._setDocumentResultType(this._returnDocumentTypeValue());
  };

  _handleSubmit = async () => {
    this._handleStartSearch();
    this._logSubmitDocumentsSearch();

    const response = await this.props.actions.getDocuments(this._returnDocumentSearchRequestBodyData());

    this.setState(() => ({
      _isGettingDocuments: false,
    }));

    const { error, documents } = response;
    if (error) {
      if (error === 'Documents are not available for simulated accounts' || error === 'Missing live trading account') {
        this.setState(() => ({
          noDocumentsWithUserAccountType: true,
        }));
      }
      this.setState(() => ({
        _wasErrorGettingDocuments: this._convertAPIErrorMessageToDisplayString(error),
      }));
      this._logErrorGettingDocuments(error);
    } else {
      this._setDocumentResults(documents);
    }
  };

  _setPageTitle = () => (document.title = `${PRODUCT_DISPLAY_NAME} - Documents`);

  _logSubmitDocumentsSearch = () => {
    const event = 'Submitted Documents Search';
    const properties = {
      'Document Type': this._returnDocumentTypeValue(),
      'Start Date': `${this._returnFromMonthValue()}-${this._returnFromYearValue()}`,
      'End Date': `${this._returnToMonthValue()}-${this._returnToYearValue()}`,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logErrorGettingDocuments = (error) => {
    const event = 'Received Error Using Documents Search';
    const properties = {
      'Error Message': error,
      'Document Type': this._returnDocumentTypeValue(),
      'Start Date': `${this._returnFromMonthValue()}-${this._returnFromYearValue()}`,
      'End Date': `${this._returnToMonthValue()}-${this._returnToYearValue()}`,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logPageView = () => {
    const event = 'View Documents Page';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentsPage);
