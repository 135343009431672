import React from 'react';
import Icon from '../../components/misc/Icon';
import { isUndefinedOrNull } from '../../helpers/generalHelpers';

class DropdownWithStrings extends React.Component {
  render() {
    const {
      prefixArrowIcons,
      showArrowIcons,

      errorMessage,
    } = this.props;
    return (
      <fieldset
        className={`
          standalone-dropdown-container
          ${this._returnName()}
          ${this._returnClassName()}
          ${this._returnLabel() === this._returnValue() || this._returnValue() === '' ? 'label-is-value' : ''}
        `}
      >
        {this._renderLabel()}
        {prefixArrowIcons && showArrowIcons && this._renderArrowIcons()}
        {this._renderDropdown()}
        {!prefixArrowIcons && showArrowIcons && this._renderArrowIcons()}
        {errorMessage && <p className={'dropdown-error-message error-text-color'}>{errorMessage || ''}</p>}
      </fieldset>
    );
  }

  _renderArrowIcons = () => {
    const { prefixArrowIcons, arrowIconStyleTop, arrowIconStyleBot } = this.props;
    const style = prefixArrowIcons ? { position: 'relative' } : {};
    return (
      <>
        <div className={'dropdown-input-icons'} style={style}>
          <Icon
            size={'small'}
            icon={'fa-sort-asc'}
            colorClassName={' '}
            style={arrowIconStyleTop || {}}
            // you must apply css styling for position style={{ position: 'absolute', top: '10px', right: '10px' }}
          />
        </div>
        <div className={'dropdown-input-icons'} style={style}>
          <Icon
            size={'small'}
            icon={'fa-sort-desc'}
            colorClassName={' '}
            style={arrowIconStyleBot || {}}
            // you must apply css styling for position style={{ position: 'absolute', top: '10px', right: '10px' }}
          />
        </div>
      </>
    );
  };

  _renderLabel = () => {
    return this._shouldShowLabel() && <label className={'form-label'}>{this._returnLabel()}</label>;
  };

  _renderDropdown = () => {
    return (
      <select
        name={this._returnName()}
        value={this._returnValue()}
        className={`
          ${this._returnLabel() === this._returnValue() || this._returnValue() === '' ? 'secondary-text-color' : ''}
          ${this.props.errorMessage ? 'error-text-color' : ''}
          ${this.props.errorMessage ? 'error-border-color' : ''}
        `}
        onChange={this._handleDropdownSelection}
      >
        {this._returnOptionValues().map((value, i) => this._renderOption(value, i))}
      </select>
    );
  };

  _renderOption = (value, i) => {
    return (
      <option
        value={value}
        key={`${this._returnName()}-${value}-${i}`}
        className={`
          option-${this._returnName()}-${value}
          ${this._returnLabel() === value ? 'secondary-text-color' : ' -override'}
        `}
        disabled={this._isDisabled(value)}
      >
        {value}
      </option>
    );
  };

  _renderErrorMessage = () => {
    const isError = !!this.props.errorMessage;
    const errorMessage = isError ? this.props.errorMessage : '';
    return <p className={'text-field-error-message'}>{errorMessage}</p>;
  };

  _returnOptionValues = () => {
    const options = this.props.options;
    const labelValue = this._returnLabel();
    const defaultValue = this.props.defaultValue;
    const isDefaultValue = !!defaultValue;
    const value = this._returnValue();

    if (value !== null && value !== '' && !(value === '' || value === defaultValue) && !options.includes(value)) {
      console.error('Option value is not listed in options');
    }

    if (this._shouldIncludeLabelAsSelection()) {
      return [labelValue, ...options];
    }

    // default value should not be supplied or used if using label as a selection
    if (isDefaultValue) {
      return value === null || value === '' || value === defaultValue ? [defaultValue, ...options] : options;
    }

    return options;
  };

  _returnName = () => this.props.name || '';

  _shouldIncludeLabelAsSelection = () => this.props.includeLabelAsSelection;

  _returnLabel = () => this.props.label;

  _shouldShowLabel = () => {
    if (isUndefinedOrNull(this._returnLabel())) {
      return false;
    }
    const value = this._returnValue();
    return this.props.forceShowLabel || (this._returnLabel() !== value && value !== '');
  };

  _returnValue = () => this.props.value;

  _returnClassName = () => this.props.className || '';

  _isDisabled = (value) => {
    return false; // not currently enabled with strings as values
  };

  _handleDropdownSelection = (e) => {
    let selectionValue = e.target.value;
    if (selectionValue === this._returnLabel()) {
      selectionValue = '';
    }
    this.props.handleSelection(this._returnName(), selectionValue);
  };
}

DropdownWithStrings.defaultProps = {
  arrowIconStyleTop: {},
  arrowIconStyleBot: {},
};

export default DropdownWithStrings;
