import React from 'react';

const GradientLine = (props) => {
  const { width, additionalClasses } = props;

  if (!(width > 0)) {
    return null;
  }
  return (
    <div className={`gradient-border-container ${additionalClasses || ''}`} style={{ width: `${width}px` }}>
      <div className="gradient-border"></div>
    </div>
  );
};

export default GradientLine;
