import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentUser } from '../../hooks/user';
import { logMetricsTrackingEvent, subscribeToViewpoint } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import { H5, Body5 } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import FeaturedViewpointSelection from '../../../containers/viewpoints/FeaturedViewpointSelection';
import ViewpointSubscriptionCard from '../../../containers/viewpoints/components/ViewpointSubscriptionCard';
import { doesCurrentUserHaveFreeSubSlot } from '../../../helpers/currentUserHelpers';
import { getAllFeaturedSubscriptionViewpointIdsFromState } from '../../../selectors/viewpoints';
import { useIsOnboarding } from '../../hooks/user';
import { ROUTES } from '../../../constants/paths';
import { useNavigate } from 'react-router-dom';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { PRODUCT_DISPLAY_FULL_NAME } from '../../../appConfig';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 440px;
  margin: 0 auto;
`;

const Heading = styled.div`
  margin: 0 auto;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 24px;
`;

const Actions = styled.div`
  padding-top: 80px;
  text-align: center;
`;

export const SelectVPC = ({ onContinue, onboardingState }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const isOnboarding = useIsOnboarding();
  const featuredViewpointOrgIds = useSelector(getAllFeaturedSubscriptionViewpointIdsFromState);
  const hasFreeViewpointSelectionAvailable = doesCurrentUserHaveFreeSubSlot(currentUser);

  const [isReady, setIsReady] = React.useState(true);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);
  const [showMakeSelectionError, setShowMakeSelectionError] = React.useState(null);

  React.useEffect(() => {
    logMetricsTrackingEvent('View Viewpoint Welcome Screen')();

    if (featuredViewpointOrgIds.length === 0 || !hasFreeViewpointSelectionAvailable) {
      if (isOnboarding) {
        onContinue(null, onboardingState);
      } else {
        navigate(ROUTES.DASHBOARD.build());
      }
    }
  }, []);

  const handleSubscribeToSelections = async (selections) => {
    if (selections.length === 0) {
      setShowMakeSelectionError(true);
      return null;
    }

    logMetricsTrackingEvent('Press Continue Viewpoint Welcome Screen')();

    const selection = selections[0];
    const response = await subscribeToViewpoint({ viewpointOrgId: selection, isFree: true })(dispatch);
    const { ok } = response;

    if (ok) {
      onContinue(null, onboardingState);
    } else {
      setFormError('Something went wrong, please try again.');
      setIsSubmitting(false);
    }
  };

  const handleSingleViewpointContinueClick = async () => {
    await handleSubscribeToSelections([featuredViewpointOrgIds[0]]);
  };

  if (!isReady) return <LoadingPage />;

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.featuredViewpoints}>
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>Premium Content for Free</H5>
          </Heading>

          <div>
            <Body5>
              Your {PRODUCT_DISPLAY_FULL_NAME} membership comes with access to 1 of our premium Viewpoint contributors
              for free.
            </Body5>
          </div>

          <div style={{ marginTop: 15 }}>
            {featuredViewpointOrgIds.length > 1 ? (
              <div>
                <FeaturedViewpointSelection onContinue={handleSubscribeToSelections} />
                <FormState error={formError} isSubmitting={isSubmitting} />
              </div>
            ) : (
              <ViewpointSubscriptionCard id={featuredViewpointOrgIds[0]} showBio hideActions />
            )}
          </div>

          {showMakeSelectionError && (
            <div className="error-text-color text-center" style={{ marginTop: 15 }}>
              <span>You must make a selection to continue</span>
            </div>
          )}

          {featuredViewpointOrgIds.length === 1 && (
            <Actions>
              <FormState error={formError} isSubmitting={isSubmitting} />
              <Container fullWidth centerAll>
                <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleSingleViewpointContinueClick}>
                  Continue
                </FlatButton>
              </Container>
            </Actions>
          )}
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
