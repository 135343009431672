import React from 'react';

import UserContainer from '../users/UserContainer';
import ProfileAvatar from '../../components/user/ProfileAvatar';
import UserIdeaPerformance from './UserIdeaPerformance';
import UserName from '../../components/user/UserName';
import HighlightComponent from '../UI/Tour/HighlightComponent';
import { PROFILE_CONTEXTS } from '@src/constants/contextTracking';
import styled from 'styled-components';

const Wrapper = styled.div``;

const TopPerformer = (props) => {
  const { item, position } = props;
  const { performance } = item;
  const userId = item.user_id;

  const handleClick = () => {
    if (props.handleClick) {
      props.handleClick(userId);
    }
  };

  return (
    <Wrapper
      key={`top-connection-performer-${userId}`}
      className={`top-performer-container`}
      style={{
        ...position,
        position: 'absolute',
        width: '75px',
      }}
      onClick={handleClick}
    >
      {props.showHighlight && <HighlightComponent tourStep={1} />}
      <UserContainer userId={userId}>
        <ProfileAvatar size="nav" profileLinkQuery={`?context=${PROFILE_CONTEXTS.TOP_PERFORMERS}`} />
        <UserIdeaPerformance performance={performance} />
      </UserContainer>
    </Wrapper>
  );
};

export default TopPerformer;
