import {
  ADD_DATA_TYPES,
  applicationActionTypes,
  MARKET_STATES,
  SET_MARKET_HOURS_DATA,
  SET_MARKET_STATE,
  SET_SESSION_RISK_ID,
  UPDATE_EMAIL_INVITE_COPY,
  UPDATE_STATIC_COPY,
} from '../constants';

const defaultState = {
  resources: {},

  marketState: MARKET_STATES.none,
  marketHoursData: null,

  sessionRiskId: null,

  emailInviteCopy: null,

  componentModal: {},
};

export default function applicationReducer(state = defaultState, action) {
  switch (action.type) {
    case applicationActionTypes.showComponentModal: {
      const modalProps = action.payload;

      return {
        ...state,
        componentModal: modalProps,
      };
    }
    case applicationActionTypes.hideComponentModal: {
      return {
        ...state,
        componentModal: {},
      };
    }

    case ADD_DATA_TYPES:
      const resource = action.payload;

      return {
        ...state,
        resources: {
          ...state.resources,
          ...resource,
        },
      };

    case SET_MARKET_STATE: {
      if (action.payload !== state.marketState) {
        return {
          ...state,
          marketState: action.payload,
        };
      }
      return state;
    }

    case SET_MARKET_HOURS_DATA: {
      return {
        ...state,
        marketHoursData: action.payload,
      };
    }

    case SET_SESSION_RISK_ID: {
      return { state, sessionRiskId: action.payload };
    }

    case UPDATE_EMAIL_INVITE_COPY: {
      return { state, emailInviteCopy: action.payload };
    }
    case UPDATE_STATIC_COPY: {
      return {
        ...state,
        staticCopy: action.payload,
      };
    }

    default:
      return state;
  }
}
