import React from 'react';
import { connect } from 'react-redux';
import { returnPathTo } from '../../constants/paths';

import Button from '../buttons/Button';
import Icon from '../misc/Icon';
import FundingBonusBannerRenderer from '../../components/banners/FundingBonusBannerRenderer';

import { hideModal, logMetricsTrackingEvent } from '../../actions';
import { setItemToStorage, STORAGE_KEYS } from '../../utils/storage';
import { withRouter } from '../../main/utils';
import { FlatButton } from '@src/main/components/buttons';
import { PRODUCT_DISPLAY_FULL_NAME } from '../../appConfig';

const HeaderIcon = () => (
  <div className={'flex-center-child'}>
    <div
      className={'border-accent flex-center-child'}
      style={{
        position: 'relative',
        borderWidth: '3px',
        borderStyle: 'solid',
        borderRadius: 38,
        height: 76,
        width: 76,
        paddingLeft: 7,
        paddingBottom: 2,
      }}
    >
      <Icon icon="fa-bank" customClass="secondary-text-color" size="na" style={{ fontSize: '42px' }} />
      <div
        className={'flex-center-child success-bg'}
        style={{
          position: 'absolute',
          bottom: 10,
          right: 10,

          borderRadius: 10,
          height: 20,
          width: 20,
          paddingLeft: 1,
        }}
      >
        <Icon icon="fa-link" color="#fff" size="na" style={{ fontSize: '14px' }} />
      </div>
    </div>
  </div>
);

class FinishSettingUpAccountModal extends React.PureComponent {
  componentDidMount() {
    const event = 'View Finish Setting Up Your Account Modal';
    logMetricsTrackingEvent(event)();
  }

  render() {
    return (
      <div style={{ width: '100%', maxWidth: '450px' }}>
        <div
          className="modal-message"
          style={{
            paddingTop: '0px',
            textAlign: 'center',
            width: '100%',
            maxWidth: '450px',
          }}
        >
          <div>
            <div
              style={{
                maxWidth: 300,
              }}
            >
              <HeaderIcon />
              <div style={{ margin: '10px 0 0 0' }}>
                <span className={' '} style={{ fontSize: '18px', fontWeight: '500' }}>
                  {'Your account setup is almost complete'}
                </span>
              </div>
              <div
                style={{
                  margin: '10px 0 0 0',
                  fontSize: '14px',
                  fontWeight: '300',
                }}
              >
                <span className={'secondary-text-color'}>
                  Finish setting up your {PRODUCT_DISPLAY_FULL_NAME} account by linking your bank account
                </span>
              </div>
              <div
                style={{
                  margin: '10px 0 0 0',
                  fontSize: '14px',
                  fontWeight: '300',
                }}
              >
                <span className={'secondary-text-color'}>
                  A linked bank account allows you to transfer funds to your {PRODUCT_DISPLAY_FULL_NAME} account to
                  invest as well as pay for subscriptions automatically.
                </span>
              </div>
            </div>
            <div
              style={{
                margin: '15px 0 0 0',
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              <div
                className={'border-accent'}
                style={{
                  borderWidth: '1px',
                  borderStyle: 'dashed',
                  padding: '10px',
                }}
              >
                <FundingBonusBannerRenderer />
              </div>
            </div>
          </div>
          <div className="modal-custom-button">
            <FlatButton onClick={this.handleContinue}>Continue</FlatButton>
          </div>
        </div>
      </div>
    );
  }

  handleContinue = () => {
    const { navigate, dispatch } = this.props;
    hideModal()(dispatch);

    const event = 'Press Continue Finish Setting Up Your Account Modal';
    logMetricsTrackingEvent(event)();

    navigate(returnPathTo('funding'));

    setItemToStorage(STORAGE_KEYS.DISMISSED_FINISH_SETTING_UP_ACCOUNT_MODAL, true);
  };
}

const connectedComponent = connect()(FinishSettingUpAccountModal);
export default withRouter(connectedComponent);
