import React from 'react';
import { useSelector } from 'react-redux';
import { COLOR_THEME_NAMES, themes } from '@src/main/lib/nvstr-utils.es';

export const useColorTheme = (nameOnly) => {
  const storeColorTheme = useSelector((state) => state.colorTheme.value);
  const customColorTheme = useSelector((state) => state.colorTheme.customTheme);

  if (nameOnly) return storeColorTheme;
  return storeColorTheme === COLOR_THEME_NAMES.custom ? customColorTheme : themes[storeColorTheme];
};

export default useColorTheme;
