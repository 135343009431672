import React from 'react';
import Input from './Input';
import Results from './Results';

import { logMetricsTrackingEvent } from '../../../../actions/trackingActions';

/*
  Required Props for <ReusableSearchContainer/>
    context -> used for event logging

    queryResults -> array of queryResult objects following format:
      {
        type: 'security' || 'user',
        data: { ...objectSpecificData }
      }

    handleResultSelection -> function(resultData) { <code> }
    onAfterQueryChange -> function(newQueryValue) { <code> }

  -----------------------------------------------

  Optional Props
    isQueryLoading -> bool
    showANoResultsMessage -> bool -> need to add a no results message

    placeholder -> string

    onAfterResultsChange -> function(results) { <code> }
*/

class ReusableSearchContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      _isFocused: false,

      _queryValue: '',
    };
  }

  componentDidMount() {
    this._initListeners();
  }

  componentWillUnmount() {
    this._removeListeners();
  }

  render() {
    const { placeholder, showANoResultsMessage } = this.props;

    return (
      <div ref={(el) => (this.SearchContainer = el)} className={`reusable-search-container`}>
        <Input
          placeholder={placeholder || 'Enter a value'}
          value={this.returnQueryValue()}
          handleFocus={this._handleFocus}
          handleBlur={this._handleBlur}
          handleChange={this._handleQueryChange}
          autoFocus={this.props.autoFocus}
        />
        <Results
          shouldShowResults={this.isQueryActive()}
          queryResults={this.returnQueryResults()}
          handleResultSelection={this._handleResultSelection}
          showANoResultsMessage={showANoResultsMessage}
          focusOnMount={this.props.focusOnResultsMount}
        />
      </div>
    );
  }

  isQueryLoading = () => this.props.isQueryLoading;
  returnQueryValue = () => this.state._queryValue;
  _isFocused = () => this.state._isFocused;

  isQueryActive = () => this.returnQueryValue() !== '';
  areQueryResultsActive = () => Array.isArray(this.returnQueryResults());

  returnQueryResults = () => this.props.queryResults;
  returnQueryResultsCount = () => (this.areQueryResultsActive() ? this.returnQueryResults().length : 0);

  _returnDOMNode = () => this.SearchContainer;
  _$returnDOMNode = () => $(this._returnDOMNode());

  _setQueryValue = (newValue) => {
    // const _this = this;
    this.setState(
      () => ({
        _queryValue: newValue,
      }),
      () => this._onQueryValueChange(newValue)
    );
  };

  _onQueryValueChange = (newValue) => {
    // can call a function to react to a newValue
    // * best place to fire off ajax requests to get result data

    if (this.props.onAfterQueryChange) {
      this.props.onAfterQueryChange(newValue);
    }
  };

  // TODO:
  // _onQueryResultsChange = results => {
  //   // can call a function to react to a list of results
  //   if (this.props.onAfterResultsChange) {
  //     this.props.onAfterResultsChange(newValue);
  //   }
  // }

  _setFocus = () => {
    this.setState(() => ({
      _isFocused: true,
    }));
  };

  _clearFocus = () => {
    this.setState(() => ({
      _isFocused: false,
    }));
  };

  _handleFocus = () => {
    this._setFocus();
  };
  _handleBlur = () => {
    this._clearFocus();
  };

  _handleQueryChange = (e) => {
    const value = e.target.value;
    this._setQueryValue(value);
  };

  _clearQuery = () => {
    this.setState(() => ({
      _queryValue: '',
    }));
  };

  _handleResultSelection = (selectionObj) => {
    if (!this.props.handleResultSelection) {
      console.error('Missing handleResultSelection prop in <ReusableSearchContainer/>');
    }
    this.props.handleResultSelection(selectionObj);
  };

  _handleCancelQuery = () => {
    if (this.isQueryActive()) {
      const currentQuery = this.returnQueryValue();
      this._clearQuery();
      this._logCancelQuery(currentQuery);
    }
  };

  _returnCustomKeyStrokeHandlers = () => this.props.customKeyStrokeHandlers || {};

  _handleKeyPress = (e) => {
    const keyCodeToAction = {
      27: () => {
        if (this.isQueryActive()) {
          this._handleCancelQuery;
        }
      },
      ...(this._isFocused() ? this._returnCustomKeyStrokeHandlers() : {}),
    };
    const keyCode = e.keyCode;
    const action = keyCodeToAction[keyCode];
    if (action && this._isFocused()) {
      action(this.returnQueryValue());
    }
  };

  _addKeyStrokeListener = () => {
    $(document).on('keydown', this._handleKeyPress);
  };

  _removeKeyStrokeListener = () => {
    $(document).off('keydown', this._handleKeyPress);
  };

  _addDismissActiveQueryListener = () => {
    $(document).on('click', this._handleCancelQuery);
  };

  _removeDismissActiveQueryListener = () => {
    $(document).off('click', this._handleCancelQuery);
  };

  _initListeners = () => {
    this._addKeyStrokeListener();
    // this._addDismissActiveQueryListener();
    this._initClickStopListeners();
  };

  _removeListeners = () => {
    this._removeKeyStrokeListener();
    this._removeDismissActiveQueryListener();
    this._removeClickStopListeners();
  };

  _initClickStopListeners = () => {
    this._$returnDOMNode().on('click', this._stopPropagation);
  };

  _removeClickStopListeners = () => {
    this._$returnDOMNode().off('click', this._stopPropagation);
  };

  _stopPropagation = (e) => e.stopPropagation();

  _logQuerySelection = () => {};

  _logCancelQuery = (currentQuery) => {
    const event = 'Cancelled Search';
    const properties = {
      Context: this.props.viewingContext,
      Query: currentQuery,
      'Results Count': this.returnQueryResultsCount(),
    };
    logMetricsTrackingEvent(event, properties)();
  };
}

export default ReusableSearchContainer;
