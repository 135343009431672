import React from 'react';

import NumberWithChangeIndicator from '../../components/UI/NumberWithChangeIndicator';

const UserIdeaPerformance = (props) => {
  const performance = props.performance;

  return (
    <div className={`top-connection-daily-idea-performance`}>
      <NumberWithChangeIndicator valueFormat={'percentage'} value={performance} coloredText showPlus hideIcon />
    </div>
  );
};

export default UserIdeaPerformance;
