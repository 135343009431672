import React from 'react';

import { toCapitalCase } from '../../helpers/generalHelpers';
import Icon from '../misc/Icon';

export const Dropdown = (props) => {
  const name = props.name;
  const label = props.label;
  const note = props.note;
  const value = props.getValue(name) || undefined;

  // the values() is used for situations where values are an array of options coming from the server :: CAREFUL its async, cannot assume they will always be there
  const values = Array.isArray(props.values) ? props.values : props.values();

  const showAdditionalInputs =
    props.toggleAdditionalInfoFieldsIf instanceof Function
      ? props.toggleAdditionalInfoFieldsIf(value)
      : props.toggleAdditionalInfoFieldsIf === value;

  const isError = !!props.getErrorMessage(name);
  const errorMessage = isError ? props.getErrorMessage(name) : '';
  const errorHighlight = isError ? 'error-input' : '';
  const hideElement = !!note ? '' : 'hidden';
  const styling = ('styling' in props && props.styling) || 'dropdown-centered';
  const style = props.style || {};

  const isFocused = props.isFocused && props.isFocused(name);
  const focusNote = props.getFocusNote && props.getFocusNote(name);
  const showFocusNote = !!focusNote && isFocused;

  const restrictedValuesToShow =
    ('restrictedValues' in props && props.restrictedValues.filter((value) => value.exists)) || [];
  const optionValues = [...values, ...restrictedValuesToShow.map((option) => option.value)];
  if (!props.hideLabel) {
    optionValues.unshift(label);
  }

  const handleFocus = () => {
    if (props.handleFocus) {
      props.handleFocus(name);
    }
  };

  const handleBlur = () => {
    if (!('ignoreBlur' in props)) {
      props.handleBlur(value, name);
    }
  };

  const handleChange = (e) => {
    // HACK NEED MORE CUSTOM CHANGE HANDLER FOR DROPDOWNS THAT IS NOT SHARED WITH INPUTS
    if (typeof values[0] === 'object') {
      const value = e.target.value;

      const valueFromValues = values.filter((val) => val.name === value || val.description === value)[0];
      if (valueFromValues) {
        const newE = { target: { value: valueFromValues } };
        props.handleChange(newE, name);
      } else {
        props.handleChange(e, name);
      }
    } else {
      props.handleChange(e, name);
    }
  };

  const showOptionValue = (value, isSelectedValue) => {
    const rawValue = typeof value === 'object' ? ('name' in value ? value.name : value.description) : value;
    let displayValue = isSelectedValue ? rawValue : rawValue;
    if (props.convertValuesToCapitalCase) {
      displayValue = toCapitalCase(displayValue);
    }
    return displayValue;
  };

  return (
    <fieldset
      className={`${styling} ${props.center ? 'text-center' : ''} ${props.size === 'small' ? 'field-small' : ''}`}
      style={style}
    >
      {showFocusNote && <div className="focus-note eye-catching-bg">{focusNote}</div>}
      {props.showLabel && !(label === value || value === undefined) && <label>{label}</label>}
      <div style={{ display: 'inline-block', position: 'relative' }}>
        <select
          name={name}
          className={errorHighlight}
          value={typeof value === 'object' ? value.name : value || label}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          {optionValues.map((value, i) => (
            <option key={`${name}-${i}`}>{showOptionValue(value)}</option>
          ))}
        </select>
        {props.showArrowIcons && (
          <Icon
            size={'small'}
            icon={'fa-sort-asc'}
            colorClassName={' '}
            style={{ position: 'absolute', top: `${props.arrowIconTop || 18}px`, right: '2px' }}
          />
        )}
        {props.showArrowIcons && (
          <Icon
            size={'small'}
            icon={'fa-sort-desc'}
            colorClassName={' '}
            style={{ position: 'absolute', top: `${props.arrowIconTop + 1 || 19}px`, right: '2px' }}
          />
        )}
      </div>

      <div className={`dropdown-note ${hideElement} secondary-text-color`}>{note}</div>

      {!('ignoreBlur' in props) && <p className={`text-field-error-message`}>{errorMessage}</p>}

      {showAdditionalInputs && (
        <div className="toggle-additional-inputs-container">{props.children.additionalInfoFields}</div>
      )}
    </fieldset>
  );
};

export default Dropdown;
