import { logMetricsTrackingEvent } from '../../../actions';
import { ADJUST_EVENT_NAMES, logAdjustEvent } from '@src/main/lib/nvstr-common-ui.es';

const ADD_THOUGHT = {
  send: (properties) => {
    // 'Stock Symbol'
    // 'Security ID'
    // 'Thought Type'
    // 'From Disagree'
    // 'In Response Thought ID'
    // Context
    const event = 'Thought Added';
    logMetricsTrackingEvent(event, properties)();

    logAdjustEvent(ADJUST_EVENT_NAMES.POSTED_THOUGHT);
  },
};

const trackingEvents = {
  ADD_THOUGHT,
};

export default trackingEvents;
