import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../actions/index';

import AnimatedOverlay from '../../components/layout/AnimatedOverlay';
import Panel from '../../components/layout/Panel';
import PageLoading from '../../components/PageLoading';
import Button from '../../components/buttons/Button';

import { formatLocaleString } from '../../helpers/generalHelpers';
import { FlatButton } from '../../main/components/buttons';

export class LivePricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _isLoading: true,

      showMoreInfo: false, // for explanation of what bid/ask price is
    };
  }

  componentDidMount() {
    this._getPricing().then(() => {
      this._completeLoading();
    });
  }

  render() {
    return this._renderComponent();
  }

  _renderLoading = () => {
    return (
      <div className={''}>
        <PageLoading flatStyle />
      </div>
    );
  };

  _renderComponent = () => {
    return this._returnRender()();
  };

  _returnRender = () => this._returnRenderTypes()[this._returnRenderType()];
  _returnRenderTypes = () => ({
    small: this._renderSmall,
    modal: this._renderModal,
    modalInPanel: this._renderLivePricingModalComponentInPanel,
  });
  _returnRenderType = () => this.props.type || 'small';

  _renderSmall = () => {
    return (
      <div className={'live-pricing-container small'}>
        {this._renderLastSaleNotice()}
        <div className={'live-pricing-body'}>
          {this._renderLastPrice()}
          {this._renderBidAndAskPrice()}
        </div>
        {this._renderMoreInfo()}
        {this._renderBidAskDisclaimer()}
      </div>
    );
  };

  _renderModal = () => {
    return (
      <AnimatedOverlay
        additionalClasses="bid-ask-pricing"
        handleClick={this._handleDismissSeeMorePricing}
        showOverlay
        light
        fixedCenter
      >
        <Panel withoutOverlay>
          {this._isLoading() ? this._renderLoading() : this._renderLivePricingModalComponent()}
        </Panel>
      </AnimatedOverlay>
    );
  };

  _renderLivePricingModalComponentInPanel = () => {
    return (
      <div className={'bid-ask-pricing'}>
        {this._isLoading() ? this._renderLoading() : this._renderLivePricingModalComponent()}
      </div>
    );
  };

  _renderLivePricingModalComponent = () => {
    return (
      <div className={'live-pricing-container modal-type'}>
        {this._renderModalHeading()}
        {this._renderLastSaleNotice()}
        {this._renderLastPrice()}
        {this._renderBidAndAskPrice()}
        {this._renderBidAskDisclaimer()}
        {this._renderMoreInfo()}
        {this._renderModalDismiss()}
      </div>
    );
  };

  _renderModalHeading = () => {
    return <div className={'live-pricing-heading'}>{`${this._returnSecuritySymbol()} Pricing`}</div>;
  };

  _renderModalDismiss = () => {
    return (
      <div className={'live-pricing-dismiss'}>
        <FlatButton onClick={this._handleDismissSeeMorePricing} transparent>
          Dismiss
        </FlatButton>
      </div>
    );
  };

  _renderLastSaleNotice = () => {
    return <p className="last-sale-notice">{"Last Price is provided by NASDAQ's real-time last sale feed."}</p>;
  };

  _renderBidAskDisclaimer = () => {
    return (
      <p className="bid-ask-disclaimer secondary-text-color">{'Bid / Ask is aggregated and delayed 15 minutes.'}</p>
    );
  };

  _renderLastPrice = () => {
    return (
      <div className={'last-price-container'}>
        <div className={'data-label'}>Last Price</div>
        <div className={'value'}>{this._returnDisplayLastPrice()}</div>
        <div className={'qty'}>
          <span className={'data-label qty secondary-text-color'}>QTY</span>
          <span className={'value size'}>{this._returnLastPriceSize()}</span>
        </div>
      </div>
    );
  };

  _renderBidAndAskPrice = () => {
    return (
      <div className={'bid-ask-price-container'}>
        <div className={'data-label'}>{'Bid / Ask'}</div>
        <div className={'value'}>{`${this._returnDisplayBidPrice()} / ${this._returnDisplayAskPrice()}`}</div>
        <div className={'qty'}>
          <span className={'data-label qty secondary-text-color'}>QTY</span>
          <span className={'value size'}>{`${this._returnBidSize()} / ${this._returnAskSize()}`}</span>
        </div>
      </div>
    );
  };

  _formatFloatForPriceDisplay = (float) => {
    return (
      '$' +
      formatLocaleString(float, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };

  _formatFloatForDisplay = (float) => {
    return formatLocaleString(float, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  _returnSecurityId = () => this.props.securityId;
  _returnSecuritySymbol = () => this.props.symbol;
  _returnPricingStore = () => this.props.securitiesOrderPrice;
  _returnPricingData = () => this._returnPricingStore().securities[this._returnSecurityId()];

  _returnLastPrice = () => this._returnPricingData().last_price;
  _returnDisplayLastPrice = () => this._formatFloatForPriceDisplay(this._returnLastPrice());

  _returnLastPriceSize = () => this._returnPricingData().last_size;

  _returnBidPrice = () => this._returnPricingData().bid_price;
  _returnBidSize = () => this._returnPricingData().bid_size;
  _returnDisplayBidPrice = () => this._formatFloatForPriceDisplay(this._returnBidPrice());

  _returnAskPrice = () => this._returnPricingData().ask_price;
  _returnAskSize = () => this._returnPricingData().ask_size;
  _returnDisplayAskPrice = () => this._formatFloatForDisplay(this._returnAskPrice());

  _renderMoreInfo = (config) => {
    const alwaysShow = config && config.alwaysShow;
    const showExplanation = this._shouldShowMoreInfo() || alwaysShow;
    const bid = 'is the highest price at which the security can be sold in the market.';
    const ask = 'is the lowest price at which the security can be bought in the market.';
    const extra =
      'These prices are determined by market participants.  Quantity is the number of shares available to buy or sell at the quoted price.';

    return (
      <div>
        {!showExplanation ? (
          <div className={'pricing-info-explanation-link'}>
            <FlatButton transparent onClick={this._showMoreInfo}>
              What is Bid/ Ask Pricing?
            </FlatButton>
          </div>
        ) : null}
        <div
          className={`pricing-info-explanation height-change-animation slow small ${
            showExplanation ? '' : 'no-height'
          }`}
        >
          <div className={'spacer'}>
            <p>
              <span className="bold-text">Bid&nbsp;</span>
              {bid}
            </p>
            <p>
              <span className="bold-text">Ask&nbsp;</span>
              {ask}
            </p>
            <p>{extra}</p>
            <div className={'text-center'}>
              {alwaysShow ? null : <FlatButton onClick={this._hideMoreInfo}>Hide</FlatButton>}
            </div>
          </div>
        </div>
      </div>
    );
  };

  _isLoading = () => this.state._isLoading;
  _completeLoading = () => {
    this.setState(() => ({
      _isLoading: false,
    }));
  };
  _shouldShowMoreInfo = () => this.state.showMoreInfo;
  _handleDismissSeeMorePricing = () => this.props.handleDismiss();

  _showMoreInfo = () => {
    this.setState(() => ({
      showMoreInfo: true,
    }));
  };
  _hideMoreInfo = () => {
    this.setState(() => ({
      showMoreInfo: false,
    }));
  };

  _getPricing = () => {
    const securities = [this.props.securityId];
    const requests = [
      this.props.actions.fetchSecuritiesPriceData(securities),
      this.props.actions.fetchSecuritiesOrderPriceData(securities),
    ];
    return Promise.all(requests);
  };
}

const mapStateToProps = (state) => {
  return {
    securitiesOrderPrice: state.securitiesOrderPrice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(LivePricing);

export default composedComponent;
