import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import InputForm from '../UI/InputForm';
import SearchUserResultRow from './SearchUserResultRow';
import styled from 'styled-components';

const Wrapper = styled.div`
  input {
    background: transparent !important;
    border-radius: 5px !important;
    border: 1px solid ${({ theme }) => theme.themeColors.text} !important;
  }
`;

class SearchUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };
  }

  render() {
    const findFriendIcon = {
      icon: 'fa-arrow-right',
      color: '#2d3e55',
      size: 'small',
      customClass: 'rounded-edge-button find-friends-icon icon-skinny-bg',
      background: '#F7F4F2',
    };

    return (
      <Wrapper className={`search-friends-container`}>
        <div className={`connection-cell`}>
          <h5>Search People</h5>
          <InputForm
            className={'input-form-as-panel-styling'}
            placeholder={'Search Friends'}
            handleSubmit={this.handleSubmit}
            icon={findFriendIcon}
            loadingText={'Searching'}
          />
        </div>
      </Wrapper>
    );
  }

  createSearchResultsModal = (queryString) => {
    const userIds = this.props.users.queryResults[queryString];

    if (!userIds || userIds.length === 0) {
      return (
        <div className={`search-user-result`}>
          <div className={`search-user-result-heading`}>{`We did not find any users matching your search. `}</div>
        </div>
      );
    }

    return (
      <div className={`search-user-result`}>
        <div className={`search-user-result-heading`}>{`${userIds.length} users matching your search.`}</div>
        <div className={`search-user-result-list`}>
          {userIds.map((userId, i) => (
            <SearchUserResultRow
              key={`mutual-connection-${userId}`}
              userId={userId}
              hideModal={this.props.actions.hideModal}
            />
          ))}
        </div>
      </div>
    );
  };

  handleSubmit = (queryString) => {
    this.setState({ query: queryString });
    return this.props.actions.queryUsers(queryString).then((response) => {
      this.showSearchResults(queryString);
    });
  };

  showSearchResults = (queryString) => {
    const modal = {
      contentComponent: this.createSearchResultsModal(queryString),
      dismissable: true,
      size: 'wide',
    };
    this.props.actions.showModal(modal);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    users: state.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchUsers);
