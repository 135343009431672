import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PageLoading from '../../components/PageLoading';
import { PanelCard } from '../UI/Layout/PanelCard';
import { useCurrentIdeas } from '../../main/hooks/user';
import { IDEA_FILTERS } from '../../main/hooks/user/useCurrentIdeas';
import { fetchSecuritiesPriceData, quickFetchSecuritiesData } from '../../actions/index';
import { usePositionsLookup } from '../../main/hooks/portfolio/usePositions';
import { getSecurityIdFromIdea, getSecuritySymbolFromIdea, isIdeaABuy } from '../../helpers/ideaHelpers';
import { useIdea } from '../../main/hooks/ideas/useIdea';
import { FlatButton } from '../../main/components/buttons';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { TableName, TableSymbol } from '../../main/components/text/ui/securities';
import { Price, PRICE_DISPLAY_TYPES } from '../../main/components/securities/Price';
import { returnSecurityPanelUrl } from '../../helpers/securityPanelHelpers';
import { TableHeading } from '../../main/components/text';
import { TrackingEvents } from '../../utils/tracking/events';
import { useNavigate } from 'react-router-dom';
import { useExtendedTradingHours } from '../../main/hooks/securities/useExtendedTradingHours';
import { ExtendedTradingPrice } from '../../main/containers/Securities/ExtendedTradingPrice';

const sortIdeasAlphabetically = (ideasList) =>
  ideasList.sort((a, b) => {
    if (!getSecuritySymbolFromIdea(a)) {
      console.error('There was no symbol to sort by.', a);
      return [];
    }
    if (!getSecuritySymbolFromIdea(b)) {
      console.error('There was no symbol to sort by.', b);
      return [];
    }

    if (getSecuritySymbolFromIdea(a) < getSecuritySymbolFromIdea(b)) return -1;
    if (getSecuritySymbolFromIdea(a) > getSecuritySymbolFromIdea(b)) return 1;
    return 0;
  });

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid transparent;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.appBackground};

  &:hover,
  &:active {
    border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
    border-radius: 5px;
    transition: 200ms all;
    text-decoration: none;

    * {
      text-decoration: none;
    }
  }
`;

const IdeaIdentifierCell = styled(Link)`
  flex: 1;
  min-width: 0; /* or some value */

  padding: 16px;
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: none;

    * {
      text-decoration: none;
    }
  }
`;

const BuySellCell = styled.div`
  padding: 16px 16px 16px 0;
  text-align: right;
  width: 136px;

  button {
    padding: 8px 16px;
    width: 120px;
  }
`;
const PriceWrapper = styled.div`
  padding-top: 2px;

  &.de-emphasize {
    color: ${({ theme }) => theme.themeColors.text} !important;
    opacity: 0.6 !important;

    * {
      color: ${({ theme }) => theme.themeColors.text} !important;
    }
  }
`;

const Row = ({ ideaId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const idea = useIdea(ideaId);
  const securityId = getSecurityIdFromIdea(idea);
  const isBuyIdea = isIdeaABuy(idea);
  const handleIdeaClick = React.useCallback(() => {}, []);
  const handleBuyClick = React.useCallback(() => {
    navigate(`/orders?operation=buy&security_id=${securityId}`);
    TrackingEvents.ideas.CLICK_TRADE_BUTTON_DASHBOARD_IDEAS.send({
      'Order Type': 'Buy',
      'Security ID': parseInt(securityId, 10),
    });
  }, []);
  const handleSellClick = React.useCallback(() => {
    navigate(`/orders?operation=sell&security_id=${securityId}`);
    TrackingEvents.ideas.CLICK_TRADE_BUTTON_DASHBOARD_IDEAS.send({
      'Order Type': 'Sell',
      'Security ID': parseInt(securityId, 10),
    });
  }, []);

  if (!idea) return null;

  const additionalProps = {};
  const openSecurityPanelConfig = {
    location,
    securityId,
    additionalProps,
  };
  const urlToOpenSecurityPanel = returnSecurityPanelUrl(openSecurityPanelConfig);

  return (
    <RowWrapper>
      <IdeaIdentifierCell to={urlToOpenSecurityPanel} onClick={handleIdeaClick}>
        <div>
          <TableSymbol>{idea.security.symbol}</TableSymbol>
        </div>
        <TableName>{idea.security.name}</TableName>
        <PriceWrapper>
          <Price securityId={securityId} type={PRICE_DISPLAY_TYPES.inline} />
        </PriceWrapper>
        <ExtendedTradingPrice securityId={securityId} ContainerProps={{ top: 3 }} />
      </IdeaIdentifierCell>
      <BuySellCell>
        {isBuyIdea ? (
          <FlatButton fullWidth onClick={handleBuyClick}>
            Buy
          </FlatButton>
        ) : (
          <FlatButton fullWidth onClick={handleSellClick}>
            Sell
          </FlatButton>
        )}
      </BuySellCell>
    </RowWrapper>
  );
};

const TableWrapper = styled.div`
  border-radius: 5px;
  background: ${({ theme }) => theme.themeColors.component};
`;

const Heading = styled.div`
  padding: 16px 0 16px 16px;

  border-bottom: 1px solid ${({ theme }) => theme.themeColors.appBackground};
`;

const Table = ({ ideas, positions }) => {
  const ids = sortIdeasAlphabetically(Object.values(ideas)).map((i) => i.id);
  const [sortedIdeaIds, setSortedIdeaIds] = React.useState(ids);

  React.useEffect(
    () => {
      const ids = sortIdeasAlphabetically(Object.values(ideas)).map((i) => i.id);
      setSortedIdeaIds(ids);
    },
    [ideas]
  );

  const positionsCount = positions ? Object.keys(positions).length : 0;
  return (
    <TableWrapper>
      <Heading>
        <TableHeading>{positionsCount ? 'Watchlist' : 'Watchlist'}</TableHeading>
      </Heading>

      {sortedIdeaIds.map((id) => (
        <Row key={id} ideaId={id} />
      ))}
    </TableWrapper>
  );
};

const IdeasTable = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);

  const [filters] = React.useState(IDEA_FILTERS.nonPositionIdeas);
  const ideas = useCurrentIdeas(null, filters);
  const positions = usePositionsLookup();

  React.useEffect(
    () => {
      const securityIds = Object.keys(ideas);
      if (securityIds.length > 0) {
        quickFetchSecuritiesData(securityIds)(dispatch);
        fetchSecuritiesPriceData(securityIds)(dispatch);
      }
    },
    [ideas, positions]
  );

  if (isLoading) {
    return (
      <PanelCard>
        <PageLoading />
      </PanelCard>
    );
  }

  if (!ideas || ideas.length === 0) {
    return null;
  }

  return (
    <PanelCard>
      <Table ideas={ideas} positions={positions} />
    </PanelCard>
  );
};

export default IdeasTable;
