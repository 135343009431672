import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { clearOptimizerCooldown, createOptimizedOrders, getOrdersV1 } from '@src/actions';
import { Page } from '@src/main/components/layout';
import { TrackingEvents } from '@src/utils/tracking/events';
import { sleep } from '@src/helpers/usefulFuncs';
import { LoadingAnimation } from '@src/main/components/ui/LoadingAnimation';
import { Container } from '@src/main/components/ui';
import { Body2, Body3, Body5 } from '@src/main/lib/nvstr-common-ui.es';
import { FlatButton } from '@src/main/components/buttons';

const Wrapper = styled.div`
  min-height: 300px;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;
const PageWrapper = styled.div`
  margin-bottom: 24%;
`;

const LoadingSpinnerWrapper = styled.div`
  //min-height: 300px;
`;

export const CreateOptimizedCartController = ({ onComplete, onCancel }) => {
  const dispatch = useDispatch();

  const [error, setError] = React.useState(null);
  const [warning, setWarning] = React.useState(null);

  const clearCooldown = async () => {
    const response = await clearOptimizerCooldown()(dispatch);
    return response;
  };

  const createOptOrders = async () => {
    const response = await createOptimizedOrders()(dispatch);
    const wasCreateOrdersSuccessful = response?.data && !response.data.warning && !response.data.error;
    if (wasCreateOrdersSuccessful) {
      const properties = {
        'Order Creation Type': 'Full Optimization',
        'Optimizer Context': 'Panel',
      };
      TrackingEvents.orders.PLACED_TRADE.send(properties);

      await sleep(200);
      const { status, data } = await getOrdersV1()(dispatch);
      if (status === 200) {
        const { not_placed_orders } = data;
        if (not_placed_orders.length > 0) {
          onComplete();
        } else {
          const message = 'Something went wrong. Please try again.';
          setError(message);
        }
      }
    }
    //error
    else {
      const properties = {
        'Error Message': response?.data?.error || 'Unknown',
        'Was Warning': response?.data?.warning,
      };
      TrackingEvents.orders.CREATE_OPTIMIZED_ORDERS_ERROR.send(properties);

      if (!response || !response.data) {
        // Server error
        const message = 'Could not create orders. Please try again later.';
        setError(message);
        return;
      }

      if (response?.data?.warning) {
        setWarning(true);
        return;
      }

      const errorMessage = response?.data?.error?.toLowerCase();

      if (errorMessage === 'Optimizer did not generate any orders'.toLowerCase()) {
        setError(
          'There are no optimized orders to create, you can change your investment objectives or add, update, or delete ideas to change your optimal portfolio'
        );
        return;
      }
      if (errorMessage === 'orders pending'.toLowerCase()) {
        setError(
          'Cannot create optimizer orders when you have orders placed, but not yet completed.  Please try again after your pending orders are complete.'
        );
        return;
      }
      const message = response?.data?.error || 'Something went wrong. Please try again.';
      setError(message);
    }
  };

  React.useEffect(() => {
    createOptOrders();
  }, []);

  const handleRetry = async () => {
    setError(null);
    createOptOrders();
  };

  const handleOptimizeAnyway = async () => {
    setWarning(null);
    await clearCooldown();
    createOptOrders();
  };

  const handleCancel = () => {
    onCancel();
  };

  if (error) {
    return (
      <PageWrapper>
        <Page width={'440px'}>
          <Wrapper>
            <Container top={16}>
              <Container>
                <Body2>Could not complete</Body2>
              </Container>
              <Container top={4}>
                <Body5>{error}</Body5>
              </Container>
            </Container>

            <Container>
              <Container centerAll>
                <FlatButton fullWidth onClick={handleRetry}>
                  Retry
                </FlatButton>
              </Container>

              <Container centerAll top={8}>
                <FlatButton small transparent onClick={handleCancel}>
                  Cancel
                </FlatButton>
              </Container>
            </Container>
          </Wrapper>
        </Page>
      </PageWrapper>
    );
  }

  if (warning) {
    return (
      <PageWrapper>
        <Page width={'440px'}>
          <Wrapper>
            <Container top={24}>
              <Body2 bold>You just optimized your portfolio very recently</Body2>
              <Container top={16}>
                <Body5>
                  Consider waiting until tomorrow or add some new investment ideas, before you optimize again.
                </Body5>
              </Container>
            </Container>

            <Container>
              <Container centerAll>
                <FlatButton fullWidth onClick={handleOptimizeAnyway}>
                  Continue Anyway
                </FlatButton>
              </Container>

              <Container centerAll top={8}>
                <FlatButton small transparent onClick={handleCancel}>
                  Cancel
                </FlatButton>
              </Container>
            </Container>
          </Wrapper>
        </Page>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Page width={'440px'}>
        <Wrapper style={{ minHeight: 'auto' }}>
          <Container top={24}>
            <Container centerAll>
              <LoadingSpinnerWrapper>
                <LoadingAnimation withLogo />
              </LoadingSpinnerWrapper>
            </Container>
          </Container>
          <Container top={32} bottom={20} centerAll>
            <Body3>Creating optimized orders for your review...</Body3>
          </Container>
        </Wrapper>
      </Page>
    </PageWrapper>
  );
};
