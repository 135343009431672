import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withWindowSize from '../../HOCS/withWindowSize';
import * as Actions from '../../../actions/index';
import { withRouter } from '../../../main/utils';
import Button from '../../../components/buttons/Button';

import { returnCurrentUserId, isCurrentUserOnboarding } from '../../../helpers/currentUserHelpers';
import { returnTourConfig, isEndOfTour, returnCountOfStepsInTour } from '../../../constants/tour';
import { getIsUsingRebalancerForPortfolioOptimization } from '../../../helpers/portfolioOptimizationHelpers';
import styled from 'styled-components';
import { FlatButton } from '@src/main/components/buttons';

const EndTourBtnWrapper = styled.span`
  button {
    padding: 0px 8px;
  }
`;

const ContainerWrapper = styled.span`
  border: 2px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
  background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};

  * {
    color: ${({ theme }) => theme.themeColors.text};
  }
`;

export class InformationWindow extends Component {
  componentDidMount() {
    this._checkIfStartingTourAndLog();
  }

  componentDidUpdate(prevProps) {
    this._checkIfStartingTourAndLog();
  }

  render() {
    if (this._isLoading()) {
      return null;
    }
    if (!this._isOnTour() || !this._showHighlight()) {
      return null;
    }
    return this._renderWindow();
  }

  _renderWindow = () => {
    return (
      <ContainerWrapper
        ref={(el) => (this.InformationWindow = el)}
        className={`tour-window ${this._returnColorSchemeStyling()}`}
        style={this._returnStyling()}
      >
        <span className={'body-container'}>
          {this._renderTourWindowHeading()}
          {this._renderTourWindowBody()}
          {this._renderNextButton()}
        </span>

        {isEndOfTour(this._returnUserTourStep()) ? null : this._renderEndTour()}
      </ContainerWrapper>
    );
  };

  _renderTourWindowHeading = () => {
    const text = this._returnTourWindowHeadingText();
    return <span className={'tour-window-heading'}>{text}</span>;
  };

  _renderTourWindowBody = () => {
    let text = this._returnTourWindowBodyText();
    if (Array.isArray(text)) {
      text = text.map((string, i) => (
        <span key={`tour-${i}`} className="text-block">
          {string}
        </span>
      ));
    }
    return <span className={'tour-window-body'}>{text}</span>;
  };
  _returnTourWindowCustomStyle = () =>
    this._returnConfig().returnTourWindowStyle
      ? this._returnConfig().returnTourWindowStyle(this._returnScreenWidth())
      : {};

  _renderNextButton = () => {
    const button = isEndOfTour(this._returnUserTourStep()) ? (
      <FlatButton onClick={this._handleCompleteTourClick}>End</FlatButton>
    ) : (
      <FlatButton onClick={this._handleNextClick}>Next</FlatButton>
    );
    return <span className={'tour-window-next-button-container'}>{button}</span>;
  };

  _renderEndTour = () => {
    return (
      <EndTourBtnWrapper className={'tour-window-end-tour-button-container'}>
        <FlatButton transparent onClick={this._handleEndTourClick}>
          End tour
        </FlatButton>
      </EndTourBtnWrapper>
    );
  };

  _returnTourWindowHeadingText = () => this._returnTourMessaging().heading;

  _returnTourWindowBodyText = () => this._returnTourMessaging().body;

  _returnRefToFooter = () => window.$('footer');

  _addPaddingToFooter = () => this._returnRefToFooter().css('paddingBottom', '200px');

  _removePaddingToFooter = () => this._returnRefToFooter().css('paddingBottom', '0px');

  _returnButtonTextColorSchemeStyling = () => this._returnColorScheme().btnColorClassName;

  _returnColorScheme = () =>
    this._returnConfig().returnColorScheme ? this._returnConfig().returnColorScheme(this._returnScreenWidth()) : '';

  _returnColorSchemeStyling = () => this._returnColorScheme().stepBgColorClassName;

  _handleNextClick = () => {
    this._logNextClick();
    let nextStep = this._returnUserTourStep() + 1;
    const isUsingRebalancer = getIsUsingRebalancerForPortfolioOptimization();
    if (isUsingRebalancer && nextStep === 5) {
      // skip optimizer step
      nextStep++;
    }
    this.props.actions.updateTourStep(nextStep, this._returnCurrentUserId());
  };

  _handleCompleteTourClick = () => {
    this._logCompleteClick();
    this._endTour();
  };

  _handleEndTourClick = () => {
    this._logEndTourClick();
    this._skipToEndOfTour();
  };

  _skipToEndOfTour = () => {
    const nextStep = returnCountOfStepsInTour();
    this.props.actions.updateTourStep(nextStep, this._returnCurrentUserId());
  };

  _checkIfStartingTourAndLog = () => {
    const isOnFirstStep = this._returnUserTourStep() === 1;
    if (isOnFirstStep && this._isOnTour() && !this.hasLoggedTourStart) {
      this.hasLoggedTourStart = true;
      this._logStartTour();
    }
  };

  _endTour = () => {
    this.hasLoggedTourStart = false;
    return this.props.actions.endTour(this._returnCurrentUserId());
  };

  _returnConfig = () => returnTourConfig.call(this, this._returnUserTourStep());

  _returnConfigStyle = () => this._returnConfig().returnStyle(this._returnScreenWidth());

  _runSetupActions = () => this._returnConfig().setupActions.call(this);

  _returnConfigShowOnRoute = () => this._returnConfig().showOnRoute;

  _returnTourMessaging = () => this._returnConfig().returnWindowMessaging(this._returnScreenWidth());

  _returnLocation = () => this.props.location || window.location;

  _returnLocationPathname = () => this._returnLocation().pathname;

  _returnLocationQuery = () => this._returnLocation().search;

  _returnFullURLMinusDomain = () => this._returnLocationPathname() + this._returnLocationQuery();

  _isOnCorrectPage = () => {
    const routeConfig = this._returnConfigShowOnRoute();
    if (routeConfig.exact) {
      return this._returnLocationPathname() === routeConfig.route;
    } else {
      return this._returnLocationPathname().indexOf(routeConfig.route) >= 0;
    }
  };

  _isLoading = () => !this._returnTourData().loaded;
  _showHighlight = (props) => {
    props = props || this.props;
    return this._isOnTour(props) && this._isOnCorrectPage();
  };

  _returnStyling = () => ({
    ...this._returnDefaultProperties(),
    ...this._returnTourWindowCustomStyle(),
  });

  _returnDefaultProperties = () => ({
    left: this._returnScreenWidth() < 985 ? '15px' : '200px',
  });

  _returnScreenWidth = () => this.props.windowWidth;

  _$returnDOMnode = () => window.$(this._returnDOMnode());

  _returnDOMnode = () => this.InformationWindow;

  _returnTourData = (props) => (props || this.props).tour;

  _returnUserTourStep = (props) => this._returnTourData(props).tour_step;

  _isOnTour = (props) => !this._isOnboarding(props) && this._returnTourData(props).is_on_tour;

  _isOnboarding = (props) => isCurrentUserOnboarding(this._returnCurrentUser(props));

  _returnHighlightTourStep = (props) => (props || this.props).tourStep;

  _returnCurrentUser = (props) => (props || this.props).currentUser;

  _returnCurrentUserId = () => returnCurrentUserId(this._returnCurrentUser());

  _logEndTourClick = () => {
    const event = 'End Tour Click';
    const properties = {
      'Tour Step': this._returnUserTourStep(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logCompleteClick = () => {
    const event = 'Complete Tour Click';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logNextClick = () => {
    const event = 'Clicked See Next In Tour';
    const properties = {
      'Current Tour Step': this._returnUserTourStep(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logStartTour = () => {
    const event = 'View First Step In Tour';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    tour: state.tour,
    horizontalList: state.horizontalList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(
  withRouter,
  withWindowSize,
  connect(mapStateToProps, mapDispatchToProps)
)(InformationWindow);

export default composedComponent;
