import React from 'react';
import { connect } from 'react-redux';
import { getViewpointOrgDataFromState } from '../../selectors/viewpoints';
import { isViewpointOrgSubscribable, isSubscribedToViewpointOrg } from '../../helpers/viewpointHelpers';

class CanSubscribe extends React.PureComponent {
  render() {
    const { children, viewpointOrg } = this.props;

    if (isViewpointOrgSubscribable(viewpointOrg) && !isSubscribedToViewpointOrg(viewpointOrg)) {
      return children;
    }

    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    viewpointOrg: getViewpointOrgDataFromState(state, ownProps.id),
  };
};

export default connect(mapStateToProps)(CanSubscribe);
