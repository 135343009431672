import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import { moment } from '../../helpers/timeHelpers';

export class PortfolioOptimizationDataHelper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      _staleOptimizerDataCheckPollingFunction: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isAppInitialized && this.props.isAppInitialized) {
      this._onAuthenticationSuccess();
    }
  }

  componentWillUnmount() {
    this._clearStaleOptimizerDataPolling();
  }

  render() {
    return null;
  }

  _isOnboarding = () => this.props.isCurrentUserOnboarding;

  _onAuthenticationSuccess = () => {
    this._initialOptimizationDataRun().then((response) => {
      this._setupStaleOptimizerDataPolling();
    });
  };

  _setupStaleOptimizerDataPolling = () => {
    const pollingFunc = this._returnPollingFunctionToCheckForStaleOptimizerData();
    this.setState(() => ({
      _staleOptimizerDataCheckPollingFunction: pollingFunc,
    }));
  };

  _clearStaleOptimizerDataPolling = () => {
    if (this.state._staleOptimizerDataCheckPollingFunction) {
      clearInterval(this.state._staleOptimizerDataCheckPollingFunction);
    }
  };

  _returnPollingFunctionToCheckForStaleOptimizerData = () => {
    // every 10 seconds check if data is stale
    return setInterval(this._runOptimizationIfDataIsStale, 10000);
  };

  _initialOptimizationDataRun = () => this.fetchOptimizationData();

  _runOptimizationIfDataIsStale = () => {
    //  don't run if the user is in onboarding
    if (this._isOnboarding()) {
      return null;
    }

    const expiration = 1000 * 60 * 10; // 10 minutes
    const currentTimeInEpoch = moment().valueOf();

    const lastRun = this.props.optimizer.latestOptimizerCallTimestamp;
    const lastRunInEpoch = moment(lastRun).valueOf();
    const expirationDateInEpoch = moment(lastRunInEpoch + expiration).valueOf();

    const isExpired = currentTimeInEpoch > expirationDateInEpoch;

    if (isExpired) {
      this.fetchOptimizationData();
    }
  };

  fetchOptimizationData = () => this.props.actions.runOptimizer();
}

const mapStateToProps = (state) => {
  return {
    optimizer: state.optimizer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioOptimizationDataHelper);
