import React from 'react';
import withCurrentUserId from '../../../HOCS/withCurrentUserId';

import SecurityDataGetter from '../../../SecurityData/SecurityDataGetter';
import { OrdersInNotificationList } from './OrderNotificationComponents/OrdersInNotificationList';
import { UserAvatarAndName } from '../../../UI/User/UserAvatarAndName';
import { Timestamp } from '../../../UI/Timestamp';

import { getUserIdFromUser } from '../../../../helpers/usersHelpers';
import { PROFILE_CONTEXTS } from '../../../../constants/contextTracking';

const _orderNotification = (props) => {
  const { notification, currentUserId } = props;

  const { owner, live, order_actions, timestamp } = notification;

  const userPlacingTradesUserId = getUserIdFromUser(owner);
  const isCurrentUser = userPlacingTradesUserId === currentUserId;

  const securityIdsFromAllOrdersInNotification = [];
  order_actions.forEach((orderActionData) => {
    orderActionData.orders.forEach((order) => {
      const securityId = order.security_id;
      if (!securityIdsFromAllOrdersInNotification.includes(securityId)) {
        securityIdsFromAllOrdersInNotification.push(securityId);
      }
    });
  });

  const notificationMessage = live
    ? `traded ${order_actions.length > 1 ? 'these stocks' : 'this stock'}`
    : `placed simulated trades for ${order_actions.length > 1 ? 'these stocks' : 'this stock'}`;

  return (
    <div className={`trade-notification-container`}>
      <SecurityDataGetter securityIds={securityIdsFromAllOrdersInNotification} getBasicSecurityData />
      <div className={`new-connection-notification-message`}>
        <UserAvatarAndName
          userId={userPlacingTradesUserId}
          profileLinkQuery={`?context=${PROFILE_CONTEXTS.NOTIFICATION}`}
          withLink
          useYou
        />
        <div className={`message`}>{notificationMessage}</div>
      </div>
      <OrdersInNotificationList isLiveTrading={live} orderActionsList={order_actions} />
      <Timestamp className={'notification-timestamp'} epochTime={timestamp} />
    </div>
  );
};

const OrderNotification = withCurrentUserId(_orderNotification);

export default OrderNotification;
