import React, { Component } from 'react';

import Security from '../../components/PortfolioExplorer/Security';

import { logMetricsTrackingEvent } from '../../actions';

class SecurityContainer extends Component {
  render() {
    const security = this.getSecurity();
    return (
      <Security
        isLoading={!security.symbol}
        security={security}
        ideas={this.props.ideas}
        filters={this.props.filters}
        width={this.props.width}
        position={this.props.position}
        currentUserId={this.props.currentUserId}
        currentUserHasEquity={this.props.currentUserHasEquity}
        handleSwipe={this.handleSwipe}
        addIdea={this.handleAddIdea}
        expandSecurityCard={this.props.expandSecurityCard}
        navigate={this.props.navigate}
        actions={this.props.actions}
      />
    );
  }

  getSecurity = () => this.props.securitiesData[this.props.securityId] || {};

  handleSwipe = () => {
    const security = this.getSecurity();
    const event = 'Clicked Add Idea';
    const properties = {
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
      Context: 'Drag Security Card',
    };
    logMetricsTrackingEvent(event, properties)();

    this.props.handleSwipe(this.props.securityId);
  };

  handleAddIdea = () => {
    const security = this.getSecurity();
    const event = 'Clicked Add Idea';
    const properties = {
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
      Context: 'Security Card Button',
    };
    logMetricsTrackingEvent(event, properties)();

    this.props.handleSwipe(this.props.securityId);
  };
}

export default SecurityContainer;
