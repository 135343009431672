import React from 'react';
import { useSelector } from 'react-redux';
import { useCurrentUser } from './useCurrentUser';
import {
  getAvailablePromotionsFromState,
  getLimitedTimeDepositBonusRewardFromAvailablePromotions,
} from '../../../selectors/currentUser';
import {
  getShouldSeeDepositPromoModalFromCurrentUserSession,
  isCurrentUserEligibleForFundingMatchReward,
  isCurrentUserEligibleForFundingReward,
} from '../../../helpers/currentUserHelpers';

export const usePromotions = () => {
  const currentUser = useCurrentUser();
  const availablePromotions = useSelector((state) => getAvailablePromotionsFromState(state));

  const isFundingBonusEligible = isCurrentUserEligibleForFundingReward(currentUser);
  const limitedTimeDepositBonusPromo = getLimitedTimeDepositBonusRewardFromAvailablePromotions(availablePromotions);
  const isFundingMatchBonusEligible = isCurrentUserEligibleForFundingMatchReward(currentUser);
  const isDepositBonusPromoEligible = !!limitedTimeDepositBonusPromo;

  const isEligibleForDepositPromo = getShouldSeeDepositPromoModalFromCurrentUserSession(currentUser);

  return {
    isFundingBonusEligible,
    isFundingMatchBonusEligible,
    isDepositBonusPromoEligible,
    isShowingFundingPromo: limitedTimeDepositBonusPromo || isFundingMatchBonusEligible,
    isEligibleForDepositPromo,
  };
};
