import React from 'react';
import Button from '../buttons/Button';
import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import styled from 'styled-components';
import { FlatButton } from '../../main/components/buttons';

const Wrapper = styled.div`
  input {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.themeColors.border};
  }
`;

const ErrorWrapper = styled.div`
  background-color: ${({ theme }) => theme.themeColors.appBackground};

  * {
    color: ${({ theme }) => theme.themeColors.text};
  }

  button {
    padding: 4px 8px;
  }
`;

export const GenericInputField = (props) => {
  const name = props.name;
  const label = props.label;
  const isFocused = props.isFocused(name);

  const rawValueReturned = props.getValue(name);
  const rawValueSanitizedFromNullAndUndefined = isUndefinedOrNull(rawValueReturned) ? '' : rawValueReturned;
  const rawValue =
    rawValueSanitizedFromNullAndUndefined || rawValueSanitizedFromNullAndUndefined <= 0
      ? rawValueSanitizedFromNullAndUndefined
      : '';
  const displayValueIfNoValueAndUnfocused = props.displayValueIfNoValueAndUnfocused;

  const value = displayValueIfNoValueAndUnfocused
    ? isFocused || rawValue !== ''
      ? rawValue
      : displayValueIfNoValueAndUnfocused
    : rawValue;

  const isDisabled = props.checkIfIsDisabled && props.checkIfIsDisabled();

  const errorAction = props.errorAction;

  const isError = !!props.getErrorMessage(name);
  const errorMessage = isError ? props.getErrorMessage(name) : '';

  const focusedOrValue = (!!value && value.length > 0) || value >= 0 || value <= 0 || value === 0 || isFocused;

  const showFocusNote = !!props.focusNote && isFocused;
  const focusNote = props.getFocusNote(name);

  const customClass = props.customClass;

  const prefix = props.prefix;
  const suffix = props.suffix;
  const showErrors = !props.hideErrors;
  const silentErrorCheck = props.silentErrorCheck(name, value);

  const hidePrefixSuffix =
    props.hidePrefixSuffixIfNoValue &&
    focusedOrValue &&
    displayValueIfNoValueAndUnfocused &&
    displayValueIfNoValueAndUnfocused === value;

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      $('input').blur();
      // was causing saves to fire twice
      // handleBlur()
    }
  };

  const handleChange = (e) => {
    if (!isDisabled) {
      props[props.handleChangeFunc](e, name, null, props.inputWarningProps, props.validateAfterChange);
    }
  };

  const refocusElement = () => {
    $("[name*='" + name + "']")[0].focus();
  };

  const handleFocus = () => {
    props.handleFocus(name);

    if ($("[name*='" + name + "']")[0] && $("[name*='" + name + "']")[0].classList.contains('ignore-input-warnings')) {
      $("[name*='" + name + "']")[0].classList.remove('ignore-input-warnings');
    }
  };

  const handleBlur = (p, e) => {
    if (props.blockingErrors && (isError || silentErrorCheck)) {
      props.manualValidation(name, value);
      refocusElement();
      return false;
    }

    if (p && p.nativeEvent.relatedTarget && p.nativeEvent.relatedTarget.id === 'input-warning-dismiss-button') {
      return false;
    }

    if (props.saveOnChange) {
      const data = {
        [`${props.objectType}_id`]: props.getObjectId(props.objectType),
        [name]: value,
      };
      props.saveOnChange(data);
    }

    props.createWarningsForInput(name, props);
    props.handleBlur(value, name);

    if ('additionalBlurAction' in props) {
      props.additionalBlurAction(name);
    }
  };

  return (
    <Wrapper className={`${customClass || 'generic-input'} ${isDisabled ? 'disabled' : ''} ${name}`}>
      <fieldset className={`${props.size === 'small' ? 'field-small' : ''} `}>
        {showFocusNote && <div className="focus-note eye-catching-bg">{focusNote}</div>}
        {label && props.labelAboveField && (
          <label htmlFor={label} className={focusedOrValue ? 'text-field-lifted-label' : ''}>
            {label}
          </label>
        )}
        <div className="parent-wrapper">
          <input
            type="text"
            name={name}
            autoFocus={props.autoFocus}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            value={value}
            disabled={isDisabled}
          />
          {focusedOrValue && !hidePrefixSuffix && <div className="input-prefix">{prefix}</div>}
          {focusedOrValue && !hidePrefixSuffix && <div className="input-suffix">{suffix}</div>}
        </div>

        {label && !props.labelAboveField && (
          <label htmlFor={label} className={focusedOrValue ? 'text-field-lifted-label' : ''}>
            {label}
          </label>
        )}

        {props.showUnderline && <div className={`after ${isError && !isFocused ? 'error-underline' : ''}`} />}

        {showErrors &&
          (props.popOutErrorMessage ? (
            isError ? (
              <ErrorWrapper className="generic-input-error-container">
                <div className="generic-input-error-arrow" />
                <div className="generic-input-error-content">{errorMessage}</div>
                {errorAction && (
                  <div className="generic-input-error-action-container">
                    <FlatButton
                      id={props.errorAction.id}
                      // customClass={props.errorAction.customClass}
                      onClick={props.errorAction.handleClick}
                    >
                      {props.errorAction.text}
                    </FlatButton>
                  </div>
                )}
              </ErrorWrapper>
            ) : null
          ) : (
            <p className={`text-field-error-message`}>{errorMessage}</p>
          ))}
      </fieldset>
    </Wrapper>
  );
};

export default GenericInputField;
