import React from 'react';
import Button from '../../components/buttons/Button';

import { returnPathTo } from '../../constants/paths';
import { logMetricsTrackingEvent } from '../../actions';
import { withRouter } from '../../main/utils';
import { FlatButton } from '../../main/components/buttons';

const OrderHistoryLink = (props) => {
  const { navigate } = props;

  const navigateToHistory = () => {
    navigate(returnPathTo('history'));
  };

  const logSeeFullOrderHistoryClick = () => {
    const event = 'Clicked See Full Order History';
    const properties = {};
    logMetricsTrackingEvent(event, properties)();
  };

  const handleClick = () => {
    logSeeFullOrderHistoryClick();
    navigateToHistory();
  };

  return (
    <div className={`order-history-container`}>
      <FlatButton onClick={handleClick} transparent>
        See Full Order History
      </FlatButton>
    </div>
  );
};

export default withRouter(OrderHistoryLink);
