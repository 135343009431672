import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useCurrentUser } from '../../hooks/user';
import { logMetricsTrackingEvent, updateUserProperty } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import { H5, Body5, createDropdownItem, FloatingLabelDropdown } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { PRODUCT_DISPLAY_FULL_NAME } from '../../../appConfig';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const InputWrapper = styled.div`
  padding-top: 40px;

  select {
    border-top: none;
    border-left: none;
    border-right: none;

    -webkit-appearance: auto;
    background: transparent !important;
  }
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 8px;
`;

const SettingMessage = styled.div`
  padding-top: 24px;
`;

const Actions = styled.div`
  padding-top: 24px;
  text-align: center;
`;

const privacySettingOptions = [
  createDropdownItem({
    value: 'public_privacy',
    text: 'Public',
  }),
  createDropdownItem({
    value: 'connections_privacy',
    text: 'Connections',
  }),
  createDropdownItem({
    value: 'private_privacy',
    text: 'Private',
  }),
];

const dropdownItems = [
  createDropdownItem({ text: 'Choose your privacy setting', value: '' }),
  createDropdownItem({ text: '---', value: '---', disabled: true }),

  ...privacySettingOptions,
];

export const SelectAccountPrivacy = ({ onContinue, onboardingState }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const [isReady, setIsReady] = React.useState(true);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [formError, setFormError] = React.useState(null);

  const [privacySetting, setPrivacySetting] = React.useState(currentUser.privacy_preference || '');

  React.useEffect(() => {
    const eventName = 'View Onboarding Privacy Selection';
    logMetricsTrackingEvent(eventName)();
  }, []);

  React.useEffect(() => {
    const eventName = 'Select Onboarding Privacy Value';
    const properties = {
      Value: privacySetting,
    };
    logMetricsTrackingEvent(eventName, properties)();
  }, [privacySetting]);

  const handleContinueClick = React.useCallback(async () => {
    if (isSubmitting) {
      return;
    }

    if (privacySetting === '') {
      setError('Selection is required');
      return;
    }

    setIsSubmitting(true);

    const eventName = 'Submit Onboarding Privacy Selection';
    const properties = {
      Value: privacySetting,
    };
    logMetricsTrackingEvent(eventName, properties)();

    const ok = await updateUserProperty({
      privacy: privacySetting,
    })(dispatch);
    if (ok) {
      onContinue(null, onboardingState);
    } else {
      setFormError('Something went wrong, please try again.');
      setIsSubmitting(false);
    }
  }, [onContinue, onboardingState, privacySetting, privacySettingOptions, isSubmitting]);

  if (!isReady) return <LoadingPage />;

  const handleDropdownSelection = (name) => (v) => {
    setPrivacySetting(v);
  };

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.selectPrivacySetting}>
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>Choose your privacy setting</H5>
          </Heading>

          <Message>
            <Body5>
              Protecting your data is important to us. Choose which setting is best for you for sharing information with
              the {PRODUCT_DISPLAY_FULL_NAME} community.
            </Body5>
          </Message>

          <InputWrapper>
            <FloatingLabelDropdown
              name={'privacy'}
              value={privacySetting}
              label={''}
              items={dropdownItems}
              onChange={handleDropdownSelection}
              error={error}
            />
          </InputWrapper>

          <SettingMessage>
            <Body5>
              When public is enabled, the {PRODUCT_DISPLAY_FULL_NAME} community can see positions and allocation
              percentage, but never your position dollar or share amounts.
            </Body5>
          </SettingMessage>

          <Actions>
            <FormState error={formError} isSubmitting={isSubmitting} />
            <Container fullWidth centerAll>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleContinueClick}>
                Continue
              </FlatButton>
            </Container>
          </Actions>
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
