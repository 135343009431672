import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withWindowSize from '../HOCS/withWindowSize';
import { PRODUCT_NAME } from '@src/appConfig';
import * as Actions from '../../actions/index';

import InfoIcon from '../../containers/UI/InfoIcon';
import { returnBasicSomethingWentWrongErrorModalPayload } from '../../constants/modals';
import styled from 'styled-components';

const BarWrapper = styled.div`
  .buy-bg-color {
    background-color: ${({ theme }) => theme.themeColors.positive};
  }

  .sell-bg-color {
    background-color: ${({ theme }) => theme.themeColors.negative};
  }
`;

class NvstrCommunityPositionChart extends React.Component {
  constructor() {
    super();
    this.state = {
      _contentWidth: null,

      _isLoading: true,
      _communityPositionData: {},
    };
  }

  componentDidMount() {
    this._calcAndSetContentWidth();

    this._getCommunityIdeaData().then((response) => {
      this._handleGetCommunityIdeaDataResponse(response);
    });
  }

  componentDidUpdate() {
    this._calcAndSetContentWidth();
  }

  render() {
    if (this._isLoading()) {
      return (
        <div ref={(el) => (this.ContentContainer = el)} className={'nvstr-community-position-chart-container'}></div>
      );
    }

    if (!this._returnCommunityPositionData()) {
      // hide if no data available
      return null;
    }

    if (!this.state._contentWidth) {
      return null;
    }

    return (
      <div ref={(el) => (this.ContentContainer = el)} className={'nvstr-community-position-chart-container'}>
        <div className={'heading-container'}>
          <p
            style={{
              position: 'relative',
              whiteSpace: 'nowrap',
            }}
          >
            <span className={'secondary-text-color'}>{`${PRODUCT_NAME} Community`}</span>
            <InfoIcon
              word={'tooltip_nvstr_community_chart'}
              style={{
                top: '1px',
                bottom: 0,
                position: 'relative',
                display: 'inline-block',
                left: 0,
                marginLeft: '4px',
              }}
            />
          </p>
        </div>
        <div className={'chart-container'}>
          {this._renderChart('long', this._returnValueFor('long'))}
          {this._renderChart('short', this._returnValueFor('short'))}
        </div>
      </div>
    );
  }

  _isLoading = () => this.state._isLoading;

  _renderChart = (positionType, value) => {
    return (
      <div className={'position-chart-container'}>
        <div
          className={`position-chart-label ${positionType}-position-type ${
            positionType.toLowerCase() === 'long' ? 'buy' : 'sell'
          }-text-color`}
        >
          {this._returnLabelFrom(positionType)}
        </div>
        <div className={'position-chart-bar-container'}>{this._renderBar(positionType, value)}</div>
        <div className={'position-chart-value secondary-text-color'}>{this._returnDisplayValue(value)}</div>
      </div>
    );
  };

  _renderBar = (positionType, value) => {
    const maxValue = 20;
    let cappedValue = value > maxValue ? maxValue : Math.ceil(value);
    cappedValue = cappedValue === 0 ? 1 : cappedValue;
    const cappedValuePerc = cappedValue / maxValue;
    const barWidthValue = this._returnBarMaxWidth() * cappedValuePerc;

    return (
      <BarWrapper className={'position-bar-container'}>
        <div
          className={`position-bar border-accent ${positionType}-position-type ${
            positionType.toLowerCase() === 'long' ? 'buy' : 'sell'
          }-bg-color`}
          style={{
            width: `${barWidthValue}px`,
          }}
        />
      </BarWrapper>
    );
  };

  _returnDisplayValue = (value) => {
    if (value < 1) {
      return '< 1%';
    }
    if (value > 20) {
      return '> 20%';
    }
    return `~${Math.round(value)}%`;
  };

  _returnLabelFrom = (positionType) => {
    const lookup = {
      long: 'Long',
      short: 'Short',
    };
    return lookup[positionType];
  };

  _returnValueFor = (positionType) => {
    const lookup = {
      long: this._returnCommunityPercentLong,
      short: this._returnCommunityPercentShort,
    };
    return lookup[positionType]();
  };

  _returnBarWidthBasedOnValue = (value) => {
    const windowWidth = this._returnWindowWidth();
    if (windowWidth > 1200) {
      return value * 16;
    }
    if (windowWidth > 920) {
      return value * 12;
    }
    if (windowWidth > 800) {
      return value * 20;
    }
    if (windowWidth > 700) {
      return value * 16;
    }
    if (windowWidth > 500) {
      return value * 12;
    }
    return value * 6;
  };

  _calcAndSetContentWidth = () => {
    const width = this._returnContentContainerWidth() - this._returnHeadingWidth();
    // needed to prevent infinite recursion
    if (this._getContentWidth() !== width && !isNaN(this._getContentWidth())) {
      this._setContentWidth(width);
    }
  };

  _getCommunityIdeaData = () => this.props.actions.fetchCommunityIdeas();

  _handleGetCommunityIdeaDataResponse = (response) => {
    const wasRequestSuccessful = response && response.data && response.data.securities;
    if (wasRequestSuccessful) {
      let securityData = response.data.securities.filter(
        (communityIdeaSecurity) => communityIdeaSecurity.security_id === this._returnSecurityId()
      )[0];
      if (!securityData) {
        const blankData = {
          community_percent_long: 0,
          community_percent_short: 0,
        };
        securityData = blankData;
      }
      this._saveCommunityPositionData(securityData);
      this._setLoadingComplete();
    } else {
      this.props.actions.showModal(returnBasicSomethingWentWrongErrorModalPayload());
    }
  };

  _setLoadingComplete = () => {
    this.setState(() => ({
      _isLoading: false,
    }));
  };

  _saveCommunityPositionData = (data) => {
    this.setState(() => ({
      _communityPositionData: data,
    }));
  };

  _returnBarMaxWidth = () => this._getContentWidth() - 100;

  _returnHeadingWidth = () => this._$returnHeadingNode().width() || 120;

  _$returnHeadingNode = () => this._$returnContentContainerNode().find('.heading-container');

  _returnContentContainerWidth = () => this._$returnContentContainerNode().width();

  _$returnContentContainerNode = () => window.$(this._returnContentContainerNode());

  _returnContentContainerNode = () => this.ContentContainer;

  _getContentWidth = () => this.state._contentWidth;

  _setContentWidth = (width) => {
    this.setState(() => ({
      _contentWidth: width,
    }));
  };

  _returnCommunityPercentLong = () => this._returnCommunityPositionData().community_percent_long;

  _returnCommunityPercentShort = () => this._returnCommunityPositionData().community_percent_short;

  _returnCommunityPositionData = () => this.state._communityPositionData;

  _returnSecurity = () => this.props.security;

  _returnSecurityId = () => this._returnSecurity().security_id;

  _returnWindowWidth = () => this.props.windowWidth;
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const component = withWindowSize(NvstrCommunityPositionChart);

export default connect(null, mapDispatchToProps)(component);
