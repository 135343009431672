import React from 'react';

const ThumbsUp = (props) => {
  const id = props.id || 'thumbs-up';
  const fill = '#fff'; // set by css color template
  const border = '#fff'; // set by css color template
  const height = props.height || '20px';
  const width = props.width || '20px';
  return (
    <svg
      className="thumbs-up-svg"
      height={height}
      width={width}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Thumbs Up</title>
      <defs></defs>
      <g id={`g-${id}`} stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect id={`rect-${id}`} fillOpacity="0" x="0" y="0" width="16" height="16" />
        <path
          id={`cuff-${id}`}
          d="M7,16.0606602 C7,16.3368025 7.22385763,16.5606602 7.5,16.5606602 L15.5553824,16.5606602 C15.7680548,16.5606602 15.9574546,16.4261291 16.0274917,16.2253198 L18.9920746,7.72531984 C19.0105369,7.67238503 19.0199653,7.6167222 19.0199653,7.56066017 C19.0199653,7.2845178 18.7961076,7.06066017 18.5199653,7.06066017 L12.3229302,7.06066017 L13.4439759,3.41891142 C13.5969614,2.92193395 13.4816454,2.38117663 13.1392287,1.98984316 L12.9987117,1.8292523 C12.9811037,1.80960703 12.9811037,1.80960703 12.9625785,1.79082419 C12.6733646,1.504505 12.2068028,1.50685161 11.9204836,1.7960655 L7.14467288,6.62015984 C7.05199041,6.71377933 7,6.84019352 7,6.97193055 L7,16.0606602 Z"
          stroke={border}
          fill={fill}
        />
        <path
          id={`hand-${id}`}
          d="M2,7 C1.72385763,7 1.5,7.22385763 1.5,7.5 L1.5,15.9998859 C1.5,16.2760282 1.72385763,16.4998859 1.99999981,16.4998859 L3.99999197,16.4998851 C4.2761342,16.4998851 4.49999176,16.2760277 4.49999197,15.9998854 L4.5,7.49999924 C4.5,7.22385686 4.27614237,6.99999924 4.00000019,6.99999924 L2.00000019,7 Z"
          stroke={border}
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default ThumbsUp;
