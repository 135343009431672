/* eslint-disable no-useless-call */
import React from 'react';

import { logMetricsTrackingEvent } from '../actions';

import {
  includes,
  countCharFrequency,
  findIndexOfObjectInArray,
  denestArray,
  getMonthFromString,
  isUndefinedOrNull,
  formatLocaleString,
} from './generalHelpers';
import {
  isLinkInThought,
  isMoreThanOneLink,
  updateValueAndCreateErrorForMultipleLinks,
  isLinkCurrentlyBeingPreviewed,
  getUrlsFromString,
  createOpenGraphObject,
} from './thoughtsHelpers';
import { customParseFloat } from './numberHelpers';
import { parseQueryString } from './routerHelpers';
import { isIdeaUsingPriceTarget } from './ideaHelpers';
import { TrackingEvents } from '../utils/tracking/events';

// PRIVATE ***********

function findChange(valueOrIndex, currentValue, newValue) {
  const addOrDeleteChar = newValue.toString().length < currentValue.toString().length;
  const value = addOrDeleteChar ? currentValue : newValue;
  const compareValue = addOrDeleteChar ? newValue : currentValue;
  for (let i = 0; i < value.length; i++) {
    if (value[i] !== compareValue[i]) {
      if (valueOrIndex === 'index') {
        return i;
      } else {
        return value.slice(i, value.length);
      }
    }
  }
  return null;
}

const removeInvalidChar = (value, regex) => {
  return value
    .split('')
    .filter((el) => regex.test(el))
    .join('');
};

function formatValue(value, format) {
  const serializedValue = value;
  const formattedValue = format.split('');

  for (let i = 0; i < serializedValue.length; i++) {
    formattedValue[formattedValue.indexOf('*')] = serializedValue[i];
  }

  return formattedValue.join('').split('*')[0];
}

function dateNotInPast(value) {
  const dateToday = `${getMonthFromString(value.month)}/${value.day}/${value.year}`;
  const sendDate = new Date(Date.parse(dateToday));
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return sendDate > today;
}

const validations = {
  date_of_birth: {
    validationFunction: function (value) {
      if (!hasValidDate(value)) {
        return 'Date is invalid';
      }
      return null;
    },
  },
  green_card_expiration_date: {
    validationFunction: function (value) {
      if (!hasValidDate(value)) {
        return 'Date is invalid';
      }

      if (!dateNotInPast(value)) {
        return 'Date is in the past.';
      }
      return null;
    },
  },
  visa_expiration_date: {
    validationFunction: function (value) {
      if (!hasValidDate(value)) {
        return 'Date is invalid';
      }

      if (!dateNotInPast(value)) {
        return 'Date is in the past.';
      }
      return null;
    },
  },
  phone_number: {
    inputRestrictionChars: /^\d+$/,
    format: '***-***-****',
    validationFunction: function (value) {
      const format = this.format;
      if (value && format.length !== value.length) {
        return 'Phone Number is not long enough';
      }
      return null;
    },
  },
  contact_postal_code: {
    inputRestrictionChars: /^\d+$/,
    format: '*****',
    validationFunction: function (value) {
      const format = this.format;
      if (value && format.length !== value.length) {
        return 'Zip code is not long enough';
      }
      return null;
    },
  },
  social_security_number: {
    inputRestrictionChars: /^\d+$/,
    format: '***-**-****',
    validationFunction: function (value) {
      const format = this.format;
      if (format.length !== value.length) {
        return 'Social Security Number is not long enough';
      }
      return null;
    },
  },
  bank_account: {
    inputRestrictionChars: /^\d+$/,
    validationFunction: function (value) {
      return null;
    },
  },
  filterValue: {
    inputRestrictionChars: /^[0-9\.]*$/,
  },
  bank_routing_number: {
    inputRestrictionChars: /^\d+$/,
    validationFunction: function (value) {
      if (value.length !== 9) {
        return 'Routing numbers must be 9 digits';
      }
      return null;
    },
  },
  amount: {
    inputRestrictionChars: /^[0-9\,\.]*$/,
    validationFunction: function (value, props, state) {
      // remove max deposit size
      // const numericValue = typeof value === 'string' ? customParseFloat( value ) : value;
      // if ( numericValue > 50000 && state.formData.funding_operation ) {
      //   return 'Cannot deposit or withdraw more than $50k in one day'
      // }

      if (!isUndefinedOrNull(value) && countCharFrequency('.', value) > 0) {
        // let decimalNumbersString = value.split('.')[1];
        if (countCharFrequency('.', value) > 1) {
          return 'Invalid decimal format';
        }
      }
      return null;
    },
  },
  price_target: {
    inputRestrictionChars: /^[0-9\,\.]*$/,
    validationFunction: function (value, props, state) {
      if (countCharFrequency('.', value) > 0) {
        // let decimalNumbersString = value.split('.')[1];
        if (countCharFrequency('.', value) > 1) {
          return 'Invalid decimal format';
        }
      }
      return null;
    },
  },
  expected_return: {
    inputRestrictionChars: /^[0-9\,\.\-]*$/,
    validationFunction: function (value, props, state) {
      if (value.toString().indexOf('-') > 0) {
        return 'Invalid number format';
      }
      if (countCharFrequency('.', value) > 0) {
        // let decimalNumbersString = value.split('.')[1];
        if (countCharFrequency('.', value) > 1) {
          return 'Invalid decimal format';
        }
      }
      return null;
    },
  },
  expected_dividend: {
    inputRestrictionChars: /^[0-9\,\.]*$/,
    validationFunction: function (value, props, state) {
      if (countCharFrequency('.', value) > 0) {
        // let decimalNumbersString = value.split('.')[1];
        if (countCharFrequency('.', value) > 1) {
          return 'Invalid decimal format';
        }
      }
      return null;
    },
  },
  amount1: {
    inputRestrictionChars: /^\d+$/,
    format: '**',
    validationFunction: function (value) {
      return null;
    },
  },
  amount2: {
    inputRestrictionChars: /^\d+$/,
    format: '**',
    validationFunction: function (value) {
      return null;
    },
  },
  leverage_max: {
    inputRestrictionChars: /^[0-9\,\.]*$/,
  },
  cash_reserve_min: {
    inputRestrictionChars: /^[0-9\,\.]*$/,
  },
  beta_constraint_min: {
    inputRestrictionChars: /^[0-9\,\.]*$/,
  },
  beta_constraint_max: {
    inputRestrictionChars: /^[0-9\,\.]*$/,
  },
  single_diversified_etf_allocation_max: {
    inputRestrictionChars: /^[0-9\,\.]*$/,
  },
  annual_dividend_income_min: {
    inputRestrictionChars: /^[0-9\,\.]*$/,
  },
  volatility_max: {
    inputRestrictionChars: /^[0-9\,\.]*$/,
  },
  single_position_allocation_max: {
    inputRestrictionChars: /^[0-9\,\.]*$/,
  },
  min_shares: {
    inputRestrictionChars: /^[0-9\-]*$/,
  },
  max_shares: {
    inputRestrictionChars: /^[0-9\-]*$/,
  },
};

// EXPORTS ************

export const isDefaultDropdownSelection = function (name, value, state) {
  const input = findInput(name, state);
  if (input.type.name === 'Dropdown') {
    return value === input.label;
  }
  return false;
};

function findAllInputs(inputs, additionalInputs) {
  const inputsToSearch = additionalInputs || inputs;

  const additionalInfoInputs = inputsToSearch
    .map((input) =>
      'props' in input && 'additionalInfoFields' in input.props ? input.props.additionalInfoFields : null
    )
    .filter((el) => !!el);
  const additionalInfoInputsIfNot = inputsToSearch
    .map((input) =>
      'props' in input && 'additionalInfoFieldsIfNot' in input.props ? input.props.additionalInfoFieldsIfNot : null
    )
    .filter((el) => !!el);

  const allAdditionalInputs = [...additionalInfoInputs, ...additionalInfoInputsIfNot];
  const allInputs = [...inputs, ...[].concat.apply([], allAdditionalInputs)];

  const denestedAllAdditionalInputs = [...[].concat.apply([], allAdditionalInputs)];

  if (denestedAllAdditionalInputs.length === 0) {
    return inputs;
  }
  return findAllInputs(allInputs, denestedAllAdditionalInputs);
}

function findInput(inputName, state) {
  const inputs = state.inputs;
  const allInputs = findAllInputs(inputs);

  const index = findIndexOfObjectInArray('name', { name: inputName }, allInputs);
  return allInputs[index];
}

function findAllActiveInputs(formData) {
  const inputs = this.state.inputs;

  const allInputs = inputs.map((input) => {
    const aai = denestArray(findAllInputAdditionalInfoFields.call(this, input.name, formData));
    return aai.length > 0 ? [input, ...aai] : [input];
  });
  return denestArray(allInputs);
}

function findAllInputAdditionalInfoFields(inputName, formData) {
  const input = findInput(inputName, this.state);
  const value = (formData || this.state.formData)[inputName];

  const additionalInfoFieldsInProps = 'props' in input && 'toggleAdditionalInfoFieldsIf' in input.props;
  if (!additionalInfoFieldsInProps) {
    return [];
  }

  const findAdditionalInfoFieldsAdditionalInfoFields = (fields) => {
    fields = fields || [];
    const additionalInfoFieldsWithAdditionalInfoFields = fields
      .map((field) => hasAdditionalInfoFields.call(this, field.name) && field)
      .filter((field) => !!field);
    return additionalInfoFieldsWithAdditionalInfoFields.length > 0
      ? [
          fields,
          ...additionalInfoFieldsWithAdditionalInfoFields.map((field) =>
            findAllInputAdditionalInfoFields.call(this, field.name, formData)
          ),
        ]
      : fields;
  };

  if (input.props.toggleAdditionalInfoFieldsIf instanceof Function) {
    if (input.props.toggleAdditionalInfoFieldsIf(value)) {
      return findAdditionalInfoFieldsAdditionalInfoFields(input.props.additionalInfoFields);
    } else {
      return findAdditionalInfoFieldsAdditionalInfoFields(input.props.additionalInfoFieldsIfNot);
    }
  } else {
    if (value === undefined) {
      return [];
    }
    if (input.props.toggleAdditionalInfoFieldsIf === value) {
      return findAdditionalInfoFieldsAdditionalInfoFields(input.props.additionalInfoFields);
    } else {
      return findAdditionalInfoFieldsAdditionalInfoFields(input.props.additionalInfoFieldsIfNot);
    }
  }
}

function hasAdditionalInfoFields(inputName) {
  const input = findInput(inputName, this.state);
  return 'props' in input && 'additionalInfoFields' in input.props && input.props.additionalInfoFields.length > 0;
}

export function hasValue(value) {
  return (!!value && value !== '') || value === 0;
}

function hasValidDate(value) {
  return value && value.month !== 'Month' && value.day !== 'Day' && value.year !== 'Year';
}

function fieldHasAcceptableContent(inputName, value, state) {
  const inputForValidation = findInput(inputName, state);

  switch (inputForValidation.typeName) {
    case 'BasicTextField':
      return hasValue(value);
    case 'LargeTextField':
      return hasValue(value);
    case 'GenericInputField':
      return hasValue(value);
    case 'TextField':
      return hasValue(value);
    case 'TextNumberField':
      return hasValue(value);
    case 'MaskedTextField':
      return hasValue(value);
    case 'SocialTextField':
      return hasValue(value);
    case 'CheckBox':
      return hasValue(value);
    case 'CustomDropdown':
      return !isDefaultDropdownSelection(inputName, value, state) && hasValue(value);
    case 'Dropdown':
      return !isDefaultDropdownSelection(inputName, value, state) && hasValue(value);
    case 'DateField':
      return hasValue(value);
    case 'MoneyField':
      return hasValue(value);
    case 'Toggle':
      // TODO: have it check possible values for matches
      return hasValue(value);
    case 'Checkbox':
      return hasValue(value);
    case 'YesNoToggleWithLabel':
      return value === 'YES' || value === 'NO';
    case 'ToggleWithLabel':
      return value === true || value === false;
    default:
      return false;
  }
}

// export const manuallyValidateFields = function( inputName, value ) {
// OFF BY ONE SETSTATE CALL, NEEDS SOLUTION
//   const formData = this.state.formData;
//   const state = this.state;
//   const value = formData[inputName];

//   let validationErrors;
//   if ('validateFields' in this) {
//     validationErrors = this.validateFields.call( this, value, inputName, state );
//   }
//   else {
//     validationErrors = validateFields.call( this, value, inputName, state );
//   }
//   validationErrors ? validationErrors.forEach( err => { validationErrors[Object.keys(err)[0]] = err[Object.keys(err)[0]] } ) : false

//   this.setState({
//     errors: {
//       ...this.state.errors,
//       ...validationErrors
//     }
//   })
// }

export const validateFields = function (value, inputName, state, ignoreAdditionalFields) {
  const input = findInput(inputName, state);
  const errors = [];
  if (input.required && !fieldHasAcceptableContent(inputName, value, state)) {
    errors.push({ [inputName]: 'Field is required.' });
    // early return since the field is required trumps all other validations
    return errors;
  }

  // check for custom validation
  if (inputName in validations && 'validationFunction' in validations[inputName]) {
    const customValidation = validations[inputName].validationFunction(value, this.props, this.state);
    errors.push({ [inputName]: customValidation });
  }

  if (errors.length === 0) {
    errors.push({ [inputName]: null });
  }

  if (errors.length > 0) {
    return errors;
  } else {
    return [{ [inputName]: null }];
  }
};

function updateStateWithErrors(errors) {
  const inputKeyArray = Object.keys(errors);
  const fieldWithError = inputKeyArray.some((inputName) => !!errors[inputName]);
  if (fieldWithError) {
    this.setState({
      errors: {
        ...this.state.errors,
        ...errors,
      },
    });
  }
  return fieldWithError;
}

export const validateFormBeforeSubmit = function (options, inputs, formData) {
  // options
  //  noRequires: bool => ignore required flags
  //  returnErrors: bool => will return an object with all errors
  //  returnBool: bool => will return a true/false validation confirmation

  // adding inputs and formData arguments is an advanced feature, you should just allow it to take it from the form component thru context

  inputs = inputs || this.state.inputs;
  formData = formData || this.state.formData;
  const state =
    'state' in this
      ? this.state
      : {
          inputs,
          formData,
          debugging: 'this is a dummy state for setCurrentStep of form',
        };
  const _this = 'state' in this ? this : { state, debugging: 'this is a dummy this for setCurrentStep of form' };
  const allInputs = findAllActiveInputs.call({ state });

  const errors = {};

  for (let i = 0; i < allInputs.length; i++) {
    const input = allInputs[i];

    let validationErrors;
    if ('validateFields' in this) {
      // ability to define a component local custom validation function
      validationErrors = this.validateFields.call(this, formData[input.name], input.name, state);
    } else {
      validationErrors = validateFields.call(_this, formData[input.name], input.name, state);
    }

    if (validationErrors) {
      validationErrors.forEach((err) => {
        errors[Object.keys(err)[0]] = err[Object.keys(err)[0]];
      });
    }
  }

  if (options && options.returnBool) {
    const errorKeys = Object.keys(errors);
    return !errorKeys.some((key) => errors[key] !== null);
  }

  if (options && options.returnErrors) {
    return errors;
  } else {
    return !updateStateWithErrors.call(this, errors);
  }
};

export const sanitizeDropdownDefaultsFromFormData = function (data) {
  const formData = data || this.state.formData;
  const submissionForm = {};

  const allInputs = findAllActiveInputs.call(this);
  const inputsToSubmit = allInputs.filter(
    (input, i) => input.typeName !== 'Dropdown' || input.label !== formData[input.name]
  );

  for (let i = 0; i < inputsToSubmit.length; i++) {
    const input = inputsToSubmit[i];
    if (includes(input.name, Object.keys(formData))) {
      submissionForm[input.name] = formData[input.name];
    }
  }
  return submissionForm;
};

export const removeUnchangedDataFromFormData = function () {
  const formData = this.state.formData;
  const formDataKeys = Object.keys(formData);
  const submissionForm = {};

  const changedData = formDataKeys.filter((input) => formData[input] !== this.props.liveAccount.liveTrading[input]);
  for (let i = 0; i < changedData.length; i++) {
    const input = changedData[i];
    submissionForm[input] = formData[input];
  }
  return submissionForm;
};

export const extractFieldsWithErrors = (errorObj) => {
  const keys = Object.keys(errorObj);
  const keysWithErrors = keys.filter((key) => errorObj[key] !== null);
  const errors = {};
  keysWithErrors.forEach((key) => {
    errors[key] = errorObj[key];
  });
  return errors;
};

export const handleUpdate = function () {
  if (validateFormBeforeSubmit.call(this)) {
    this.setState({ updatingAccount: true });
    const changedData = removeUnchangedDataFromFormData.call(this);
    this.props.actions.updateLiveTradingAccount(changedData).then((response) => {
      if (!response || 'error' in response) {
        let message = 'Something went wrong. Please try again.';
        const event = 'Update Apex Account Failure';
        logMetricsTrackingEvent(event)();

        if (response && response.error) {
          message = response.error;
        }
        this.setState({
          updateMessage: message,
          updateError: true,
          updatingAccount: false,
        });
      } else {
        const event = 'Updated Apex Account';
        logMetricsTrackingEvent(event)();
        this.setState({
          updateMessage: response.message,
          updateError: false,
          updatingAccount: false,
        });
      }
    });
    return true;
  }
  return false;
};

export const handleSubmit = function (options) {
  options = options || {};

  if (validateFormBeforeSubmit.call(this) || options.formData) {
    const form = sanitizeDropdownDefaultsFromFormData.call(this, options.formData);
    const formPageName = this.state.formPageName;

    if (Object.keys(form).length > 0) {
      return this.props.actions.submitLiveTradingSignUpForm(form, formPageName);
    }

    return new Promise((resolve, reject) => resolve({ warning: 'No changes made to form' }));
  }

  const validateFormOptions = { returnErrors: true };
  return new Promise((resolve, reject) => reject(validateFormBeforeSubmit.call(this, validateFormOptions)));
};

export const handleAddBankAccountSubmit = function () {
  if (validateFormBeforeSubmit.call(this)) {
    const _this = this;
    _this.setState({ submittingForm: true });
    const form = sanitizeDropdownDefaultsFromFormData.call(this);
    const request = this.props.actions.submitAddBankAccountForm(form);
    return request.then((response) => {
      if (response && !('error' in response)) {
        if (_this.props.hideAddBankAccountForm) {
          _this.props.hideAddBankAccountForm(false, true);
        }
        // don't have to clear the form since it unmounts
        // _this.clearForm();

        TrackingEvents.liveTradingSignUp.COMPLETED_BANK_ACCOUNT_LINK.send();
        return true;
      } else {
        _this.setState({ submittingForm: false });
        return true;
      }
    });
  }
};

export const validateAddBankAccountSubmit = function () {
  const validateFormOptions = { returnErrors: true };
  return validateFormBeforeSubmit.call(this, validateFormOptions);
};

export const handleGenericFormSubmit = function (options) {
  /* options = {
    preInitialActionFunction: // first function to be called, even before form validates
    initialActionFunction: // gets called if form validates but before the ajax is sent,
    submitActionFunction: // gets called to send the ajax
    handleSuccessFunction: // gets called if no error key in response
    handleErrorFunction: // gets called if error key in response
  }
      ******** All functions will be called with the react form component in context
  */
  if ('preInitialActionFunction' in options) {
    options.preInitialActionFunction.call(this);
  }

  const isFormValid = options.ignoreValidation ? true : validateFormBeforeSubmit.call(this);
  if (isFormValid) {
    if ('initialActionFunction' in options) {
      options.initialActionFunction.call(this);
    }
    const form = sanitizeDropdownDefaultsFromFormData.call(this);
    const request = options.submitActionFunction.call(this, form);
    return request.then((response) => {
      if (response && !('error' in response)) {
        if ('handleSuccessFunction' in options) {
          options.handleSuccessFunction.call(this, response);
        }
        return true;
      } else {
        const error =
          response && response.errorMessage ? response.errorMessage : 'Something went wrong. Please try again later.';
        if ('handleErrorFunction' in options) {
          options.handleErrorFunction.call(this, error);
        }
        return false;
      }
    });
  } else {
    return new Promise(function (resolve, reject) {
      reject({ error: 'Form submission has input errors.' });
    });
  }
};

export const handleFocus = function (inputName) {
  this.setState({
    focusedField: inputName,
  });
};

export const handleBlur = function (value, inputName) {
  // TODO: Can cause errors due to lagging behind a setState call, value can sometimes be a previous state render
  const errors = {};
  const validationErrors =
    'validateFields' in this
      ? this.validateFields.call(this, this.state.formData[inputName], inputName, this.state)
      : validateFields.call(this, this.state.formData[inputName], inputName, this.state);

  validationErrors.forEach((err) => {
    errors[Object.keys(err)[0]] = err[Object.keys(err)[0]];
  });

  this.setState({
    focusedField: null,
    errors: {
      ...this.state.errors,
      ...errors,
    },
  });
};

export const handleCustomDropdownClick = function (e, inputName, value) {
  const inputAlreadyInErrorState =
    'errors' in this.state && inputName in this.state.errors && this.state.errors[inputName];
  const errors = {};

  if (inputAlreadyInErrorState) {
    const validationErrors =
      'validateFields' in this
        ? this.validateFields.call(this, value, inputName, this.state)
        : validateFields.call(this, value, inputName, this.state);

    validationErrors.forEach((err) => {
      errors[Object.keys(err)[0]] = err[Object.keys(err)[0]];
    });
  }

  const oldValue = typeof value === 'object' ? { ...this.state.formData[inputName] } : this.state.formData[inputName];
  const input = findInput(inputName, this.state);
  const afterChangeCallback = input.props.afterChangeCallback
    ? () => {
        input.props.afterChangeCallback(value, inputName, oldValue);
      }
    : () => false;

  this.setState(
    (prevState) => ({
      formData: {
        ...this.state.formData,
        [inputName]: value,
      },
      errors: {
        ...this.state.errors,
        ...errors,
      },
    }),
    afterChangeCallback
  );

  return true;
};

const generateInputWarnings = function (inputName, value, state, props) {
  const warnings = [];

  if (inputName === 'expected_return' || inputName === 'price_target') {
    const rawPriceTarget = value;
    const priceTarget = customParseFloat(rawPriceTarget);

    const target_percentage =
      isIdeaUsingPriceTarget(props.idea) || props.userAddingPriceTarget
        ? ((priceTarget - props.security.current_price) / props.security.current_price) * 100
        : value;

    const buyOrSell = props.idea.idea_type.id === 0 ? 'Buy' : 'Sell';

    if (buyOrSell === 'Buy') {
      if (target_percentage > 50) {
        warnings.push(
          "It looks like you think the price will go up a lot. If that's not right, please double check the expected return."
        );
      }
      if (target_percentage < 0) {
        warnings.push(
          'A negative expected return means you think the price will go down. Consider changing this idea to sell.'
        );
      }
    } else {
      if (target_percentage < -50) {
        warnings.push(
          "It looks like you think the price will go down a lot. If that's not right, please double check the expected return."
        );
      }
      if (target_percentage > 0) {
        warnings.push(
          'A positive expected return means you think the price will go up. Consider changing this idea to buy.'
        );
      }
    }
  }

  if (inputName === 'expected_dividend') {
    if (customParseFloat(value) > 5) {
      warnings.push(
        "It looks like you're expecting a dividend yield of more than 5% which is relatively high. If that's not right, please double check your expected dividend."
      );
    }
  }

  return { [inputName]: warnings.length > 0 ? warnings : null };
};

export const silentErrorCheck = function (inputName, value) {
  //limited functionality
  const errors = {};

  const validationErrors =
    'validateFields' in this
      ? this.validateFields.call(this, value, inputName, this.state)
      : validateFields.call(this, value, inputName, this.state);

  validationErrors.forEach((err) => {
    errors[Object.keys(err)[0]] = err[Object.keys(err)[0]];
  });

  return errors[inputName] !== null;
};

export const manualValidation = function (inputName, value) {
  //limited functionality
  const errors = {};

  const validationErrors =
    'validateFields' in this
      ? this.validateFields.call(this, value, inputName, this.state)
      : validateFields.call(this, value, inputName, this.state);

  validationErrors.forEach((err) => {
    errors[Object.keys(err)[0]] = err[Object.keys(err)[0]];
  });

  this.setState({
    errors: {
      ...this.state.errors,
      ...errors,
    },
  });
};

export const handleChange = function (e, inputName, inputCharRestriction, props, forceValidation) {
  // For Date of Birth field or others like it where values are nested inside another object.
  if (typeof inputName === 'object') {
    const mainInputName = Object.keys(inputName)[0];
    const dropdownFieldName = inputName[mainInputName];
    let existingFormInputData = {};

    if (mainInputName in this.state.formData) {
      existingFormInputData = { ...this.state.formData[mainInputName] };
    }

    const value = e.target.value;
    const newValueForField = {
      ...existingFormInputData,
      [dropdownFieldName]: value,
    };

    const inputAlreadyInErrorState =
      'errors' in this.state && mainInputName in this.state.errors && this.state.errors[mainInputName];
    const errors = {};

    if (inputAlreadyInErrorState) {
      const validationErrors =
        'validateFields' in this
          ? this.validateFields.call(this, newValueForField, mainInputName, this.state)
          : validateFields.call(this, newValueForField, mainInputName, this.state);

      validationErrors.forEach((err) => {
        errors[Object.keys(err)[0]] = err[Object.keys(err)[0]];
      });
    }

    const warnings = {};
    this.setState({
      formData: {
        ...this.state.formData,
        [mainInputName]: newValueForField,
      },
      errors: {
        ...this.state.errors,
        ...errors,
      },
      warnings: {
        ...this.state.warnings,
        ...warnings,
      },
    });

    return true;
  }

  const currentValue = this.state.formData[inputName] || '';
  let newValue = e.target.value;

  if (typeof newValue === 'object') {
    const inputAlreadyInErrorState =
      'errors' in this.state && inputName in this.state.errors && this.state.errors[inputName];
    const errors = {};

    if (inputAlreadyInErrorState || forceValidation) {
      const validationErrors =
        'validateFields' in this
          ? this.validateFields.call(this, newValue, inputName, this.state)
          : validateFields.call(this, newValue, inputName, this.state);

      validationErrors.forEach((err) => {
        errors[Object.keys(err)[0]] = err[Object.keys(err)[0]];
      });
    }

    const warnings = minMaxWarning;
    // || generateInputWarnings( inputName, newValue, this.state, props );

    this.setState({
      formData: {
        ...this.state.formData,
        [inputName]: newValue,
      },
      errors: {
        ...this.state.errors,
        ...errors,
      },
      warnings: {
        ...this.state.warnings,
        ...warnings,
      },
    });
  }

  const addingAChar = currentValue.toString().length < newValue.toString().length;
  const changedValue = findChange('value', currentValue, newValue);

  if (!addingAChar) {
    if (changedValue === '-') {
      const indexOfChange = findChange('index', currentValue, newValue);
      newValue = newValue
        .split('')
        .filter((el, i) => i !== indexOfChange && i !== indexOfChange - 1)
        .join('');
    }
  }

  const inputRestriction = inputName in validations && validations[inputName].inputRestrictionChars;
  if (inputRestriction) {
    newValue = removeInvalidChar(newValue, inputRestriction);
  }

  // const obfuscated = inputName in validations && validations[inputName].obfuscated;
  // if (obfuscated) {
  //   const format = inputName in validations && validations[inputName].format;
  //   if (format) {
  //     newValue = formatObfuscated(newValue, format, validations[inputName].obfuscatedCount);
  //   }
  // } else {
  const format = inputName in validations && validations[inputName].format;
  if (format) {
    newValue = formatValue(newValue, format);
  }
  // }

  const inputAlreadyInErrorState =
    'errors' in this.state && inputName in this.state.errors && this.state.errors[inputName];
  const errors = {};

  if (inputAlreadyInErrorState || forceValidation) {
    const validationErrors =
      'validateFields' in this
        ? this.validateFields.call(this, newValue, inputName, this.state)
        : validateFields.call(this, newValue, inputName, this.state);

    validationErrors.forEach((err) => {
      errors[Object.keys(err)[0]] = err[Object.keys(err)[0]];
    });
  }

  const warnings = {};
  // const warnings = generateInputWarnings( inputName, newValue, this.state, props );

  const input = findInput(inputName, this.state);
  let minMaxWarning = null;
  if (!isNaN(newValue)) {
    if (input.props && input.props.returnMinMaxValidation) {
      const { min, max } = input.props.returnMinMaxValidation();
      const newValueParsed = customParseFloat(newValue);
      if (newValueParsed < min) {
        newValue = min;
        minMaxWarning = {
          [input.name]: `${min} is the minimum value accepted.`,
        };
      }
      if (newValueParsed > max) {
        newValue = max;
        minMaxWarning = {
          [input.name]: `${max} is the maximum value accepted.`,
        };
      }
    }
  }

  const afterChangeCallback = () => {
    if (input.props.afterChangeFunc) {
      input.props.afterChangeFunc(newValue, input.name, currentValue);
    }
  };

  this.setState(
    (prevState) => ({
      formData: {
        ...this.state.formData,
        [inputName]: newValue,
      },
      errors: {
        ...this.state.errors,
        ...errors,
      },
      warnings: {
        ...this.state.warnings,
        ...warnings,
      },
    }),
    afterChangeCallback
  );
};

export const handleThoughtTextChange = function (e, inputName, inputCharRestriction, props, forceValidation) {
  let newValue = e.target.value;
  let imageError = null;
  const currentPreviewUrl = this.state.openGraphObject && this.state.openGraphObject.original_link_url;

  if (isLinkInThought(newValue)) {
    const url = getUrlsFromString(newValue)[0];

    if (isMoreThanOneLink(newValue)) {
      const event = 'Multiple Link in Thought Attempt';
      logMetricsTrackingEvent(event)();

      const { sanitizedValue, error } = updateValueAndCreateErrorForMultipleLinks(newValue);
      newValue = sanitizedValue;
      imageError = error || null;

      this.setState((prevState) => ({
        customWarning: error,
        prevThoughtText: newValue,
      }));
    }

    if (!isLinkCurrentlyBeingPreviewed(newValue, currentPreviewUrl)) {
      this.setState((prevState) => ({
        loadingPreviewImage: true,
      }));

      const request = this.props.actions.fetchImageFromURL(url);
      request
        .then((response) => {
          if (response.status === 200) {
            const data = response.data;
            const openGraphObject = createOpenGraphObject(data);

            this.setState((prevState) => ({
              openGraphObject,
              loadingPreviewImage: false,
            }));
          } else {
            this.setState((prevState) => ({
              loadingPreviewImage: false,
            }));
          }
        })
        .catch((error) => {
          this.setState((prevState) => ({
            loadingPreviewImage: false,
          }));
        });
    }
  } else {
    this.setState((prevState) => ({
      openGraphObject: null,
      loadingPreviewImage: false,
    }));
  }

  const inputAlreadyInErrorState =
    'errors' in this.state && inputName in this.state.errors && this.state.errors[inputName];
  const errors = {};

  if (inputAlreadyInErrorState || forceValidation) {
    const validationErrors =
      'validateFields' in this
        ? this.validateFields.call(this, newValue, inputName, this.state)
        : validateFields.call(this, newValue, inputName, this.state);

    validationErrors.forEach((err) => {
      errors[Object.keys(err)[0]] = err[Object.keys(err)[0]];
    });
  }

  const warnings = {};

  if (this._isThoughtTooLong(newValue) && !this._loggedExceededThoughtLimit) {
    this._loggedExceededThoughtLimit = true;
    const event = 'Exceeded Thought Limit';
    this.props.actions.logMetricsTrackingEvent(event);
  }

  this.setState({
    formData: {
      ...this.state.formData,
      [inputName]: newValue,
    },
    errors: {
      ...this.state.errors,
      ...errors,
    },
    warnings: {
      ...this.state.warnings,
      ...warnings,
    },
  });
};

export const createWarningsForInput = function (inputName, props) {
  const value = this.state.formData[inputName];
  const state = this.state;
  const warnings = generateInputWarnings(inputName, value, state, props.inputWarningProps);

  this.setState({
    warnings: {
      ...this.state.warnings,
      ...warnings,
    },
  });
};

export const handleCurrencyChange = function (e, inputName, inputCharRestriction, props, forceValidation) {
  const currentValue = this.state.formData[inputName] || '';
  let newValue = e.target.value;

  const inputRestriction = inputName in validations && validations[inputName].inputRestrictionChars;
  if (inputRestriction) {
    newValue = removeInvalidChar(newValue, inputRestriction);
  }

  const format = inputName in validations && validations[inputName].format;
  if (format) {
    newValue = formatValue(newValue, format);
  }

  const wholeNumbersString = newValue.split('.')[0];
  const numberHasDecimal = countCharFrequency('.', newValue) > 0;
  const decimalNumbersString = numberHasDecimal ? newValue.split('.')[1] || '' : '';

  const wholeNumbersInt = wholeNumbersString ? parseInt(wholeNumbersString.replace(/\,/g, ''), 10) : null;

  if (countCharFrequency('.', newValue) > 1 || (numberHasDecimal && decimalNumbersString.length > 2)) {
    this.setState({
      formData: {
        ...this.state.formData,
        [inputName]: currentValue,
      },
    });
    return false;
  }

  const isUsingArrayErrors = Array.isArray(this.state.errors);
  const errors = isUsingArrayErrors ? [] : {};
  if (!isUsingArrayErrors) {
    const inputAlreadyInErrorState =
      'errors' in this.state && inputName in this.state.errors && this.state.errors[inputName];
    if (inputAlreadyInErrorState || forceValidation) {
      const validationErrors =
        'validateFields' in this
          ? this.validateFields.call(this, newValue, inputName, this.state)
          : validateFields.call(this, newValue, inputName, this.state);

      validationErrors.forEach((err) => {
        errors[Object.keys(err)[0]] = err[Object.keys(err)[0]];
      });
    }
  }

  newValue =
    (wholeNumbersInt || wholeNumbersInt === 0 ? formatLocaleString(wholeNumbersInt) : '') +
    (numberHasDecimal ? '.' + decimalNumbersString : '');

  this.setState((prevState) => ({
    formData: {
      ...this.state.formData,
      [inputName]: newValue,
    },
    errors: isUsingArrayErrors
      ? [...this.state.errors, ...errors]
      : {
          ...this.state.errors,
          ...errors,
        },
  }));
};

export const handleToggleClick = function (inputName, value) {
  // if no value passed in as param, will toggle current value in state as new value
  if (value === undefined) {
    value = !this.state.formData[inputName];
  }

  const errors = {};
  const inputAlreadyInErrorState =
    'errors' in this.state && inputName in this.state.errors && this.state.errors[inputName];

  if (inputAlreadyInErrorState) {
    const validationErrors =
      'validateFields' in this
        ? this.validateFields.call(this, value, inputName, this.state)
        : validateFields.call(this, value, inputName, this.state);

    validationErrors.forEach((err) => {
      errors[Object.keys(err)[0]] = err[Object.keys(err)[0]];
    });
  }

  const additionalInfoFieldsForState = {};
  const additionalInputFields = denestArray(findAllInputAdditionalInfoFields.call(this, inputName));

  if (additionalInputFields.length > 0) {
    additionalInputFields.forEach((field) => {
      additionalInfoFieldsForState[field.name] = null;
    });
  }

  this.setState({
    formData: {
      ...additionalInfoFieldsForState,
      ...this.state.formData,
      [inputName]: value,
    },
    errors: {
      ...additionalInfoFieldsForState,
      ...this.state.errors,
      ...errors,
    },
  });
};

export const handleRatingClick = function (inputName, value, afterChangeCallback) {
  this.setState(
    (prevState) => ({
      formData: {
        ...prevState.formData,
        [inputName]: value,
      },
    }),
    afterChangeCallback
  );
};

export const handleSwitchClick = function (inputName, afterChangeCallback) {
  const value = !this.state.formData[inputName];

  const oldValue = typeof value === 'object' ? { ...this.state.formData[inputName] } : this.state.formData[inputName];
  const bindedAfterChangeCallback = afterChangeCallback
    ? () => {
        afterChangeCallback(value, inputName, oldValue);
      }
    : () => false;

  this.setState(
    (prevState) => ({
      formData: {
        ...prevState.formData,
        [inputName]: value,
      },
    }),
    bindedAfterChangeCallback
  );
};

export const handleSelectionClick = function (inputName, value) {
  const currentSelections = this.state.formData[inputName] || [];
  let newSelections = [];
  if (includes(value, currentSelections)) {
    newSelections = currentSelections.filter((selection) => selection !== value);
  } else {
    newSelections = [...currentSelections, value];
  }

  this.setState({
    formData: {
      ...this.state.formData,
      [inputName]: newSelections,
    },
    errors: {
      ...this.state.errors,
      [inputName]: null,
    },
  });
};

export const setExistingFormData = function (existingFormData) {
  existingFormData = existingFormData || this.props.existingFormData || {};

  const allInputs = findAllActiveInputs.call(this, existingFormData);
  const dataForForm = {};

  for (let i = 0; i < allInputs.length; i++) {
    const input = allInputs[i];

    if (
      input.name in existingFormData &&
      input.typeName !== 'DateField' &&
      (input.name !== 'contact_country' ||
        ((input.name === 'contact_country' || input.name === 'citizenship_country') &&
          existingFormData[input.name] !== ''))
    ) {
      dataForForm[input.name] = existingFormData[input.name];
    }
    if (input.typeName === 'DateField') {
      const savedDate = existingFormData[input.name]; // 1999-02-21
      if (savedDate) {
        dataForForm[input.name] = savedDate;
      }
    }
  }

  const setStateCallBack = () => {
    // used to show errors if user tries to skip ahead in live trading signup process
    const query = parseQueryString(this.props.location.search);
    if (query.showErrors === 'true') {
      validateFormBeforeSubmit.call(this);
    }
  };

  // PRESET HACK, defaults will overwrite existing data, used to avoid significant changes to setExistingFormData, only field that is preset is country for address
  this.setState(
    (prevState) => ({
      formData: {
        ...prevState.formData,
        ...dataForForm,
      },
    }),
    setStateCallBack
  );
};

export const getRequiredFields = function (additionalInputs) {
  const requiredInputs = [...this.state.inputs.filter((input, i) => input.required), ...additionalInputs];
  return requiredInputs.map((input, i) => input.name);
};

const returnInputDefaultProps = function () {
  return {
    handleFocus: handleFocus.bind(this),
    handleBlur: handleBlur.bind(this),
    silentErrorCheck: silentErrorCheck.bind(this),
    handleSwitchClick: handleSwitchClick.bind(this),
    handleToggleClick: handleToggleClick.bind(this),
    handleRatingClick: handleRatingClick.bind(this),
    manualValidation: manualValidation.bind(this),
    handleCustomDropdownClick: handleCustomDropdownClick.bind(this),
    handleChange: handleChange.bind(this),
    handleThoughtTextChange: handleThoughtTextChange.bind(this),
    createWarningsForInput: createWarningsForInput.bind(this),
    handleCurrencyChange: handleCurrencyChange.bind(this),
    isFocused: function (name) {
      return this.state.focusedField === name;
    }.bind(this),
    getFocusNote: function (name) {
      if ('focusNote' in this.state) {
        return this.state.focusNote[name];
      }
    }.bind(this),
    getErrorMessage: function (name) {
      return this.state.errors[name];
    }.bind(this),
    getInputWarning: function (name) {
      return this.state.warnings[name];
    }.bind(this),
    dismissWarning: function (name) {
      this.setState((prevState) => ({
        warnings: { ...this.state.warnings, [name]: null },
      }));
    }.bind(this),
    getValue: function (name) {
      return this.state.formData[name];
    }.bind(this),
    getValueObjectId: function (name) {
      return this.state.formData[name][`${name}_type_id`];
    }.bind(this),
    getObjectId: function (name) {
      return this.props[name][`${name}_id`];
    }.bind(this),
  };
};

export const createInputComponent = function (input, customProps, key) {
  if (!customProps) customProps = returnInputDefaultProps.call(this);

  if (!key) key = input.name;

  const additionalInfoFields =
    'props' in input && !!input.props.additionalInfoFields
      ? input.props.additionalInfoFields.map((child) =>
          createInputComponent(child, customProps, `${key}-child-${child.name}`)
        )
      : [];

  const additionalInfoFieldsIfNot =
    'props' in input && !!input.props.additionalInfoFieldsIfNot
      ? input.props.additionalInfoFieldsIfNot.map((child) =>
          createInputComponent(child, customProps, `${key}-child-${child.name}`)
        )
      : [];

  const props = {
    name: input.name,
    label: input.label,
    required: input.required,
    key,
    ...input.props,
    ...customProps,
  };
  return React.createElement(input.type, props, {
    additionalInfoFields,
    additionalInfoFieldsIfNot,
  });
};

export const listVisaTypes = () => {
  return ['E1', 'E2', 'E3', 'F1', 'H1B', 'L1', 'TN', 'TN1', 'TN2', 'O1'];
};

export const listCountryNames = (usaOnTop) => {
  const countries = [
    'Afghanistan',
    'Åland Islands',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antarctica',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia (Plurinational State of)',
    'Bonaire, Sint Eustatius and Saba',
    'Bosnia and Herzegovina',
    'Botswana',
    'Bouvet Island',
    'Brazil',
    'British Indian Ocean Territory',
    'Brunei Darussalam',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cabo Verde',
    'Cayman Islands',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Christmas Island',
    'Cocos (Keeling) Islands',
    'Colombia',
    'Comoros',
    'Congo',
    'Congo (Democratic Republic of the)',
    'Cook Islands',
    'Costa Rica',
    "Côte d'Ivoire",
    'Croatia',
    'Cuba',
    'Curaçao',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Falkland Islands (Malvinas)',
    'Faroe Islands',
    'Fiji',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia',
    'French Southern Territories',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Heard Island and McDonald Islands',
    'Holy See',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran (Islamic Republic of)',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    "Korea (Democratic People's Republic of)",
    'Korea (Republic of)',
    'Kuwait',
    'Kyrgyzstan',
    "Lao People's Democratic Republic",
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macao',
    'Macedonia (the former Yugoslav Republic of)',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Mexico',
    'Micronesia (Federated States of)',
    'Moldova (Republic of)',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Niue',
    'Norfolk Island',
    'Northern Mariana Islands',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestine, State of',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Pitcairn',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Réunion',
    'Romania',
    'Russian Federation',
    'Rwanda',
    'Saint Barthélemy',
    'Saint Helena, Ascension and Tristan da Cunha',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Martin (French part)',
    'Saint Pierre and Miquelon',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Sint Maarten (Dutch part)',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Georgia and the South Sandwich Islands',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Svalbard and Jan Mayen',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syrian Arab Republic',
    'Taiwan, Province of China',
    'Tajikistan',
    'Tanzania, United Republic of',
    'Thailand',
    'Timor-Leste',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks and Caicos Islands',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom of Great Britain and Northern Ireland',
    'United States Minor Outlying Islands',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Venezuela (Bolivarian Republic of)',
    'Viet Nam',
    'Virgin Islands (British)',
    'Virgin Islands (U.S.)',
    'Wallis and Futuna',
    'Western Sahara',
    'Yemen',
    'Zambia',
    'Zimbabwe',
  ];
  if (usaOnTop) countries.unshift('United States of America');

  return countries;
};

export const listUsStateNames = () => {
  return [
    'AK',
    'AL',
    'AR',
    'AS',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'GU',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VI',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
  ];
};
