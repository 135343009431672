import React from 'react';
import styled from 'styled-components';
import { FlatButton } from '../buttons';
import { useNextLesson } from '../../hooks/learn/useNextLesson';
import { Gradient1 } from '../../../assets/gradients/Gradient1';
import { Body5, Body6 } from '../../lib/nvstr-common-ui.es';
import { colorPalette } from '../../lib/nvstr-utils.es';
import { TrackingEvents } from '../../../utils/tracking/events';
import { ROUTES } from '../../../constants/paths';
import { useNavigate } from 'react-router-dom';
import { useBasicLearnLessonData, useLearnLesson } from '../../hooks/learn/useLearnLesson';
import { buildLearnLessonPhraseMessage } from '../../utils/learn';

const Wrapper = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  max-width: 600px;
  margin: 0 auto;
`;
const Content = styled.div`
  padding: 16px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  position: relative;
  z-index: 2;
`;
const BgContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;
  z-index: 0;

  svg {
    width: 220%;
  }
`;
const ButtonWrapper = styled.div`
  padding-left: 16px;
`;

const buildMessage = (phraseDisplay) => {
  return `${buildLearnLessonPhraseMessage(phraseDisplay)} Find out more and earn rewards.`;
};

export const NextLearnLessonBanner = ({ onView, onCTAClick, ContainerComponent }) => {
  const navigate = useNavigate();
  const { nextLessonId } = useNextLesson();
  const lessonData = useBasicLearnLessonData(nextLessonId);

  const ref = React.useRef();
  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  const textColor = colorPalette.primary.charcoal;

  React.useEffect(() => {
    TrackingEvents.learn.VIEW_LEARN_GET_STARTED_BANNER.send();
    onView();
  }, []);

  React.useEffect(
    () => {
      if (ref.current) {
        setHeight(ref.current.parentElement.clientHeight);
        setWidth(ref.current.parentElement.clientWidth);
      }
    },
    [ref]
  );

  const handleClick = () => {
    onCTAClick();

    navigate(ROUTES.LEARN_AND_EARN_NEXT_LESSON.build());
  };

  if (!nextLessonId) {
    return null;
  }

  const phraseDisplay = lessonData?.preview_phrase || null;

  return (
    <ContainerComponent>
      <Wrapper ref={ref}>
        <BgContainer>
          <Gradient1 height={height} width={width} />
        </BgContainer>
        <Content>
          <div>
            <Body6 bold color={textColor}>
              LEARN & EARN
            </Body6>
            <div style={{ paddingTop: 4 }}>
              <Body5 color={textColor}>{phraseDisplay ? buildMessage(phraseDisplay) : ''}</Body5>
            </div>
          </div>
          <ButtonWrapper>
            <FlatButton color={textColor} textColor={colorPalette.primary.oak} onClick={handleClick}>
              Start
            </FlatButton>
          </ButtonWrapper>
        </Content>
      </Wrapper>
    </ContainerComponent>
  );
};
