import React, { Component } from 'react';
import styled from 'styled-components';
import BasicFinancialsContainer from '../../containers/SecurityCard/BasicFinancialsContainer';
import TruncateComponent from '../misc/TruncateComponent';
import { logMetricsTrackingEvent } from '../../actions';
import { TrackingEvents } from '../../utils/tracking/events';
import { useSecurity } from '@src/main/hooks/securities/useSecurity';
import { PriceQuote } from '@src/main/components/securities/PriceQuote';
import { Body5, Body7 } from '@src/main/lib/nvstr-common-ui.es';

const IdentifierWrapper = styled.div``;

const SymbolWrapper = styled.div`
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
`;
const NameWrapper = styled.div`
  font-size: 14px;
  line-height: 24px;
  max-width: 240px;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 540px) {
    max-width: 180px;
  }

  @media (max-width: 410px) {
    max-width: 110px;
  }
`;
const SectorWrapper = styled.div`
  font-size: 14px;
  line-height: 18px;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  p {
    margin: 0 !important;
  }
`;

const Identifier = ({ securityId }) => {
  const { symbol, name, sector } = useSecurity(securityId);

  return (
    <IdentifierWrapper>
      <SymbolWrapper>
        <p>{symbol}</p>
      </SymbolWrapper>
      <NameWrapper>
        <p>
          <Body5>{name}</Body5>
        </p>
      </NameWrapper>
      <SectorWrapper>
        <p>
          <Body7 isLowContrast>{sector?.description || ''}</Body7>
        </p>
      </SectorWrapper>
    </IdentifierWrapper>
  );
};

export const SecurityHeading = ({ securityId }) => {
  return (
    <TopWrapper>
      <Identifier securityId={securityId} />
      <PriceQuote securityId={securityId} />
    </TopWrapper>
  );
};

class SecurityCardHeading extends Component {
  componentDidMount() {
    this._logViewQuote();
  }

  render() {
    return (
      <div className="security-heading-container">
        <SecurityHeading securityId={this._returnSecurityId()} />
        {this._renderDescription()}
        {this._renderFundamentalData()}
      </div>
    );
  }

  _renderFundamentalData = () => {
    return (
      this._isSecurityFeatured() && (
        <div className="security-basic-financials">
          <div className="security-basic-financials">
            <BasicFinancialsContainer security={this._returnSecurity()} />
          </div>

          <div className="security-heading-footer">
            <div className="fundamental-data-provider secondary-text-color">
              Fundamental data provided by S&P Capital IQ
            </div>
            <div className="sec-filing-link">
              <a
                href={`https://www.sec.gov/cgi-bin/browse-edgar?CIK=${this._returnSecuritySymbol()}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={this._logViewSECFilingEvent}
              >
                View latest SEC Filings
              </a>
            </div>
          </div>
        </div>
      )
    );
  };

  _renderDescription = () => {
    const description = this._returnDescription();
    return (
      <div className="security-description">
        {description && description.length > 0 && (
          <TruncateComponent
            textClassName="secondary-text-color"
            text={description}
            limit={225}
            handleClick={this.props.logEventForDescription}
          />
        )}
      </div>
    );
  };

  _returnSecurityId = () => this.props.securityId;
  _returnSecurity = () => this.props.security;
  _returnSecuritySymbol = () => this._returnSecurity().symbol;
  _isSecurityFeatured = () => this.props.isSecurityFeatured;

  _returnDescription = () => {
    const tempDesc =
      'Apple Inc. designs, manufactures, and markets mobile communication and media devices, and personal computers to consumers, and small and mid-sized businesses; and education, enterprise, and government customers worldwide. The company also sells related software, services, accessories, networking solutions, and third-party digital content and applications. It offers iPhone, a line of smartphones; iPad, a line of multi-purpose tablets; and Mac, a line of desktop and portable personal computers, as well as operating systems comprising iOS, macOS, watchOS, and tvOS. The company also provides iWork, an integrated productivity suite that helps users create, present, and publish documents, presentations, and spreadsheets; and other application software, such as Final Cut Pro, Logic Pro X, and FileMaker Pro. In addition, it offers Apple TV that connects to consumers’ TV and enables them to access digital content directly for streaming high definition video, playing music and games, and viewing photos; Apple Watch, a personal electronic device; and iPod touch, a flash memory-based digital music and media player. Further, the company sells Apple-branded and third-party accessories, such as headphones, displays, storage devices, Beats products, and other connectivity and computing products and supplies. Additionally, it offers iCloud, a cloud service that stores music, photos, contacts, calendars, mail, documents, and others; AppleCare, which offers support options for its customers; and Apple Pay, a cashless payment service. The company sells and delivers digital content and applications through the iTunes Store, App Store, Mac App Store, TV App Store, iBooks Store, and Apple Music. It also sells its products through its retail and online stores, and direct sales force, as well as through third-party cellular network carriers, wholesalers, retailers, and value-added resellers. Apple Inc. was founded in 1977 and is headquartered in Cupertino, California.';

    return window.origin === 'http://localhost:3000' ? tempDesc : this.props.description;
  };

  _logViewQuote = () => {
    const properties = {
      'From Email': 'N/A',
      'Stock Symbol': this._returnSecuritySymbol(),
      'Security ID': this._returnSecurityId(),
      User: this.props.currentUser.analytics_id || null,
      'HTTP Referrer': 'N/A',
      'Inbound HTTP Referrer:': 'N/A',
      'Promo Code': 'N/A',
    };
    TrackingEvents.security.VIEW_QUOTE.send(properties);
  };

  _logViewSECFilingEvent = () => {
    const event = 'Clicked View SEC Filings Link';
    const properties = {
      'Security Symbol': this._returnSecuritySymbol(),
      'Security ID': this._returnSecurityId(),
    };
    logMetricsTrackingEvent(event, properties)();
  };
}

export default SecurityCardHeading;
