import React from 'react';
import CompTableRow from './CompTableRow';
import SearchContainer from '../../../../containers/UI/Search/SearchContainer';

class CompTableBody extends React.PureComponent {
  render() {
    const columnGroups = this.props.columnGroups;
    const securityIds = this.props.securityIds;
    return (
      <tbody>
        <tr id={'padding-row'} className={'padding-row-row border-accent-important'}>
          <td colSpan={this.getColumnCount()}>
            <div style={{ padding: '5px' }}></div>
          </td>
        </tr>
        {securityIds.map((securityId) => (
          <CompTableRow
            key={`comp-table-security-row-${securityId}`}
            isSecurityInDetail={securityId === this.props.securityInDetail.id}
            securityId={securityId}
            columnGroups={columnGroups}
            handleRemoveSecurity={this.props.handleRemoveSecurity}
            navigate={this.props.navigate}
            location={this.props.location}
          />
        ))}
        <tr id={'padding-row'} className={'padding-row-row border-accent-important'}>
          <td>
            <div style={{ padding: '5px' }}></div>
          </td>
        </tr>
      </tbody>
    );
  }

  getColumnCount = () => {
    const groupData = this.props.columnGroups;
    let count = 0;
    // add columns from groups
    groupData.forEach((group) => {
      group.columns.forEach((column) => {
        count++;
      });
    });
    return count;
  };
}

export default CompTableBody;
