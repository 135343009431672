import React from 'react';

export const BasicTextField = (props) => {
  const valueNotationAbbrDict = {
    thousands: 'K',
    millions: 'M',
    billions: 'B',
  };

  const name = props.name;
  const label = props.label;
  const value = props.getValue(name) || '';
  const placeholder = props.placeholder;
  const valueNotation = props.getValueNotation(props.fieldName || name);

  const isError = !!props.getErrorMessage(name);
  const errorMessage = isError ? props.getErrorMessage(name) : '';

  return (
    <div className="basic-text-field">
      <fieldset className={`text-field ${props.size === 'small' ? 'field-small' : ''} `}>
        <input
          type="text"
          ref={props[name]}
          name={name}
          onChange={(e) => {
            props.handleChange(e, name);
          }}
          value={value}
          placeholder={placeholder}
        />

        {!!label && <label htmlFor={props.label}>{label}</label>}
        <div className={`after ${isError ? 'error-underline' : ''}`}></div>

        {!!valueNotation && <p className={`value-notation`}>{`in ${valueNotation}`}</p>}

        <p className={`text-field-error-message`}>{errorMessage}</p>
      </fieldset>
    </div>
  );
};

export default BasicTextField;
