import { Body5, Body6 } from '@src/main/lib/nvstr-common-ui.es';
import { colorPalette } from '@src/main/lib/nvstr-utils.es';
import React from 'react';
import { LearnScreen } from '@src/main/components/learn';
import { Container } from '@src/main/components/ui';
import {
  LearnCarouselContent,
  LearnItemContent,
  LearnLessonActions,
  LearnLessonHeading,
} from '@src/main/components/learn/subComponents';
import { CarouselPositionIndicator } from '@src/main/components/learn/subComponents/CarouselPositionIndicator';
import { buildLearnLessonPhraseMessage } from '@src/main/utils/learn';
import { useSearchParams } from 'react-router-dom';
import LearnAndEarnMoreInfo from '../../../pages/LearnAndEarnMoreInfo';
import { useLearnLesson } from '@src/main/hooks/learn/useLearnLesson';
import { useSelector } from 'react-redux';

export const NextLearnLessonBannerPreview = ({ phraseDisplay }) => {
  const buildMessage = (phraseDisplay) => {
    return `${buildLearnLessonPhraseMessage(phraseDisplay)} Find out more and earn rewards.`;
  };
  return (
    <div>
      <Body6 bold>LEARN & EARN</Body6>
      <div style={{ paddingTop: 4 }}>
        <Body5>
          {phraseDisplay ? buildMessage(phraseDisplay) : 'Start learning about investing and earn rewards.'}
        </Body5>
      </div>
    </div>
  );
};

const textColor = colorPalette.primary.charcoal;

export const LessonContentPreview = ({ lessonId, lessonReward }) => {
  const { lesson: lessonData } = useLearnLesson(lessonId);
  const { name: lessonName, heading: lessonHeading, lessonContents: lessonContents } = lessonData;
  const [activeScreenIndex, setActiveScreenIndex] = React.useState(0);

  const [searchParams] = useSearchParams();
  const showMoreInfo = searchParams.get('showMoreInfo');

  const onComplete = () => null;

  const sponsorId = null;
  const moreOnLessonTopicUrl = null;

  const metadata = React.useMemo(
    () => {
      return {
        lessonName,
        lessonHeading,
        sponsorId,
        moreOnLessonTopicUrl,
      };
    },
    [lessonId, lessonName, lessonHeading, sponsorId, moreOnLessonTopicUrl]
  );
  const items = React.useMemo(
    () => {
      if (!lessonContents) {
        return [{ body: [] }, { body: [] }];
      }
      return lessonContents.map((c) => ({
        contents: c,
        metadata,
      }));
    },
    [lessonContents, sponsorId, metadata]
  );
  const itemsTotal = items?.length || 0;

  const previousScreen = () => {
    const newIndex = Math.max(0, activeScreenIndex - 1);
    setActiveScreenIndex(newIndex);
  };

  const advanceScreen = () => {
    const newIndex = Math.min(itemsTotal - 1, activeScreenIndex + 1);
    setActiveScreenIndex(newIndex);
  };
  return (
    <LearnScreen>
      {showMoreInfo && <LearnAndEarnMoreInfo lessonId={lessonId} />}
      <Container flex={1} column style={{ position: 'relative' }} fullWidth>
        <LearnLessonHeading
          metadata={metadata}
          lessonHeading={lessonHeading}
          lessonReward={lessonReward}
          activeScreenIndex={activeScreenIndex}
        />
        {Array.isArray(items) ? (
          <LearnCarouselContent
            items={items}
            metadata={metadata}
            lessonReward={lessonReward}
            activeScreenIndex={activeScreenIndex}
            advanceScreen={advanceScreen}
            previousScreen={previousScreen}
          >
            <LearnItemContent item={items[activeScreenIndex]} textColor={textColor} />
          </LearnCarouselContent>
        ) : null}
        <CarouselPositionIndicator
          lessonReward={lessonReward}
          activeIndex={activeScreenIndex}
          itemsTotal={itemsTotal}
        />
        <LearnLessonActions
          lessonId={lessonId}
          metadata={metadata}
          onComplete={onComplete}
          advanceScreen={advanceScreen}
          isLastScreen={itemsTotal === activeScreenIndex + 1}
        />
      </Container>
    </LearnScreen>
  );
};

export const LessonPreview = () => {
  const lessonPreview = useSelector((state) => state.learn.previewLesson);
  return (
    <>
      <NextLearnLessonBannerPreview phraseDisplay={lessonPreview.phrase} />
      <Container top={24}>
        <LessonContentPreview lessonId={'preview'} />
      </Container>
    </>
  );
};
