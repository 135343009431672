import { isUndefinedOrNull } from './generalHelpers';
import { findUserIdeas, getSecurityIdFromIdea } from './ideaHelpers';
import { findUserNonCurrentUser } from './usersHelpers';
import { consoleError, consoleLog } from './devToolHelpers';

export const serializerDefinitions = {
  serializerFunctions: {},

  serializeFields: {},
};

export const convertDefaultExpectedReturn = (source, value) => {
  if (source === 'fromApi') {
    return value === null ? null : value.toString();
  }
  return parseFloat(value);
};

export const returnCurrentUserId = (currentUser) => {
  return currentUser.user_id;
};

export const hasCurrentUserAgreedBDTerms = (currentUser) => {
  return currentUser.has_agreed_to_bd_terms;
};

export const returnCurrentUserOnboardingTestGroup = (currentUser) => {
  return currentUser.onboarding_end_test_group || 0;
};

export const isCurrentUserInStreamingPricesTestGroup = (currentUser) => {
  const testGroup = currentUser.streaming_prices_test_group || 1;
  return testGroup === 1;
};

export const inExploreNavTabNameGroup = (currentUser) => {
  return currentUser.explore_nav_tab_label_test_group;
};

export const getAskInNavTestGroupFromCurrentUser = (currentUser) => {
  // 0 - hide
  // 1 - Ask a CTL
  // 2 - Ask
  const group = currentUser.ask_nav_tab_label_test_group;
  return isUndefinedOrNull(group) ? 0 : group;
};

export const inAlternateAnalysisNavTabTestGroup = (currentUser) => {
  return currentUser.analysis_nav_tab_label_test_group === 1;
};

export const doesTradesRequireApproval = (currentUser) => {
  return currentUser.requires_trade_approval;
};

export const inAutoOpenStockSearchOnTradePageTestGroup = (currentUser) => {
  return currentUser.no_button_manual_order_test_group === 1;
};

export const inNoOrderBasketTestGroup = (currentUser) => {
  return currentUser.no_basket_manual_order_test_group === 1;
};

export const inProminentRefreshButtonTestGroup = (currentUser) => {
  return currentUser.prominent_refresh_buttons_test_group === 1;
};

export const inAnalysisNavTabBadgeTestGroup = (currentUser) => {
  return currentUser.analysis_nav_tab_badge_test_group === 1;
};

export const isReferralProgramActive = (currentUser) => {
  return currentUser.is_referral_program_active;
};

export const isCurrentUserOnboarding = (currentUser) => {
  return !currentUser.has_completed_onboarding;
};

export const isEligibleForSubscription = (currentUser) => {
  return currentUser.is_allowed_to_subscribe;
};

export const isCurrentUserSubscriber = (currentUser) => {
  return currentUser.is_subscriber;
};

export const currentUserIsMembershipEligible = (currentUser) => {
  return currentUser.is_membership_eligible;
};

export const currentUserHasMembership = (currentUser) => {
  return currentUser.has_membership;
};

export const currentUserMembershipMonthlyFeeAmount = (currentUser) => {
  return currentUser.membership_monthly_fee_amount;
};

export const currentUserMembershipMonthlyFreeTradeLimit = (currentUser) => {
  return currentUser.membership_monthly_free_trade_limit;
};

export const currentUserDailyPriceAnnualMembershipDisplay = (currentUser) => {
  return currentUser.annual_membership_framed_per_day_test_group === 1;
};

export const inCurrentUserCustomStartingInvestmentTestGroup = (currentUser) => {
  return currentUser.deposit_lesson_default_by_bank_balance_test_group === 1;
};

export const currentUserMembershipWaiveFeeMinEquity = (currentUser) => {
  return currentUser.membership_waive_fee_min_equity;
};

export const currentUserMembershipFreeTradeCount = (currentUser) => {
  return currentUser.membership_free_trade_count;
};

export const currentUserMembershipFeeBalance = (currentUser) => {
  return currentUser.membership_fee_balance;
};

export const currentUserMembershipChargeCycleResetsAt = (currentUser) => {
  return currentUser.membership_fee_charge_cycle_resets_at;
};

export const canRegisterLiveTrading = (currentUser) =>
  isCurrentUserAllowLiveTrading(currentUser) && !isCurrentUserLiveTrading(currentUser);

export const isCurrentUserAllowLiveTrading = (currentUser) => {
  return currentUser.allow_live_trading;
};

export const isCurrentUserLiveTrading = (currentUser) => {
  return currentUser.is_live_trading;
};

export const defaultCompareSpyPriceHistory = (currentUser) => {
  return currentUser.show_compare_spy_price_history;
};

export const isCurrentUserEligibleForFundingReward = (currentUser) => {
  return currentUser.eligible_for_funding_reward;
};

export const isCurrentUserEligibleForFundingMatchReward = (currentUser) => {
  return currentUser.eligible_for_funding_match_reward;
};

export const isFundingBonusActive = (currentUser) => {
  return currentUser.is_funding_rewards_program_active;
};

export const hasDoubleRewards = (currentUser) => currentUser.has_double_rewards;

export const referralRewardLimit = (currentUser) => currentUser.referral_cash_reward_limit;

export const referralRewardRequiresTrade = (currentUser) => currentUser.referral_cash_reward_requires_trade;

export const referralRewardRequiresACHLink = (currentUser) => currentUser.rewards_require_ach_link_test_group === 1;

export const hasRandomReferralRewardAmount = (currentUser) => currentUser.referral_cash_reward_amount_override === null;

export const returnCurrentUserInvestingExperience = (currentUser) => {
  return currentUser.investing_experience;
};

export const returnCurrentUserChooseRewardsTestGroup = (currentUser) => currentUser.choose_reward_cards_test_group || 0;

export const shouldShowOneCardRedemption = (currentUser) => returnCurrentUserChooseRewardsTestGroup(currentUser) === 1;

export const shouldSeeDashboardMessaging = (currentUser) => !currentUser.hide_dashboard_messaging;

export const shouldUseStreamingPrices = (currentUser) => {
  const isEventSourceSupported = 'EventSource' in window;
  return (
    isEventSourceSupported &&
    isCurrentUserInStreamingPricesTestGroup(currentUser) &&
    isCurrentUserLiveTrading(currentUser)
  );
};

export const didCurrentUserCompleteInvestingExperience = (currentUser) => {
  return !isUndefinedOrNull(returnCurrentUserInvestingExperience(currentUser));
};

export const returnCurrentUserThemeTestGroups = (currentUser) => {
  const colorTheme = currentUser.splash_color_theme_test_group;
  const messageTheme = currentUser.splash_message_theme_test_group;
  const facebookContentId = currentUser.splash_theme_facebook_content_id;
  return [facebookContentId, colorTheme, messageTheme];
};

export const returnCurrentUserSubscriptionPlanName = (currentUser) =>
  currentUser.subscription_plan_name_test_group === 1 ? 'Prime' : 'Plus';

export const returnCurrentUserPositions = (portfolioStore) => {
  if (!portfolioStore || !('positions' in portfolioStore) || !portfolioStore.positions) {
    console.error('Invalid portfolio store provided to doesCurrentUserHavePositions');
    return false;
  }
  const positions = portfolioStore.positions;
  const positionsList = Object.keys(positions)
    .map((id) => positions[id])
    .filter((security) => security.shares !== 0 && !isUndefinedOrNull(security.shares));
  return positionsList;
};

export const returnCurrentUserIdeas = (currentUserId, ideasStore) => {
  if (!ideasStore || !('ideaList' in ideasStore)) {
    console.error('Invalid idea store provided to doesCurrentUserHaveIdeas');
    return false;
  }
  const ideaList = ideasStore.ideaList;
  const userIdeas = findUserIdeas(currentUserId, ideaList);
  return userIdeas;
};

export const returnCurrentUserIdeasSecurityIds = (currentUserId, ideasStore) => {
  if (!ideasStore || !('ideaList' in ideasStore)) {
    console.error('Invalid idea store provided to doesCurrentUserHaveIdeas');
    return false;
  }
  return returnCurrentUserIdeas(currentUserId, ideasStore).map((idea) => getSecurityIdFromIdea(idea));
};

export const doesCurrentUserHaveIdeas = (currentUserId, ideasStore) => {
  return returnCurrentUserIdeas(currentUserId, ideasStore).length > 0;
};

export const doesCurrentUserHaveActiveIdeas = (currentUserId, ideasStore) => {
  const ideas = returnCurrentUserIdeas(currentUserId, ideasStore);
  return ideas.filter((idea) => idea.active).length > 0;
};

export const returnCurrentUserPosition = (security_id, portfolioStore) => {
  if (!portfolioStore || !('positions' in portfolioStore) || !portfolioStore.positions) {
    console.error('Invalid portfolio store provided to returnCurrentUserPosition');
    return false;
  }
  const int_security_id = parseInt(security_id);
  return (
    returnCurrentUserPositions(portfolioStore).filter((position) => position.security_id === int_security_id)[0] || null
  );
};

export const returnCurrentUserPositionShares = (security_id, portfolioStore) => {
  const position = returnCurrentUserPosition(security_id, portfolioStore);
  return position ? position.shares : 0;
};

export const doesCurrentUserHavePositionIn = (security_id, portfolioStore) => {
  if (!portfolioStore || !('positions' in portfolioStore) || !portfolioStore.positions) {
    console.error('Invalid portfolio store provided to doesCurrentUserHavePositions');
    return false;
  }
  const position = returnCurrentUserPosition(security_id, portfolioStore);
  return position && Math.abs(position.shares) > 0;
};

export const isOnTour = (currentUser) => currentUser.is_on_tour;

export const returnUserConnectionsUserIdsFromUserConnectionStore = (userId, store) => {
  const connectionStoreUserDict = store.userDict;
  if (!(userId in connectionStoreUserDict)) {
    consoleError('User connections data not found in connection store', {
      connectionStoreUserDict,
    });
  }
  return connectionStoreUserDict[userId].connections || [];
};

export const returnUserConnectionsFromUserConnectionsStore = (userId, userConnectionsStore, usersStore) => {
  return returnUserConnectionsUserIdsFromUserConnectionStore(userId, userConnectionsStore).map((userId) =>
    findUserNonCurrentUser(userId, usersStore)
  );
};

export const getDefaultStockPanelToFinancialTabTestGroupFromCurrentUser = (currentUser) => {
  return currentUser.default_stock_panel_to_financial_tab_test_group;
};

export const shouldShowLiveTradingAvatar = (user) => user.is_live_trading_approved;

export const doesCurrentUserHaveFreeSubSlot = (currentUser) => currentUser.viewpoint_sub_free_slots_available > 0;

export const getShouldSeeAlmostFinishedAccountSetupModalFromCurrentUserSession = (currentUserSessionData) => {
  return currentUserSessionData.show_funding_cta_modal_test_group === 1;
};

export const getShouldSeeDepositPromoModalFromCurrentUserSession = (currentUserSessionData) => {
  return currentUserSessionData.deposit_promo_may_2022_test_group === 1;
};

export const getShouldSeeLinkBankAccountBannerFromCurrentUserSession = (currentUserSessionData) => {
  return currentUserSessionData.show_funding_cta_banner_test_group === 1;
};

export const hasAccessToExternalBrokerageFeature = (currentUser) => {
  return true;
};

export const getShowKarmaTestGroup = (currentUserSessionData) => {
  // return 1; // always show

  return currentUserSessionData.show_community_points_test_group;
};

export const getShowKarma = (currentUserSessionData) => {
  return getShowKarmaTestGroup(currentUserSessionData) === 1;
};

export const getKarmaTotalFromCurrentUser = (currentUser) => {
  return currentUser.community_points_total;
};

export const getCurrentUserKarmaLabel = (currentUser) => {
  return 'XP';
  // return currentUser.id % 2 === 0 ? 'XP' : 'Rep';
};

export const getCurrentUserKarmaLabelLong = (currentUser) => {
  return 'Experience Points';
  // return currentUser.id % 2 === 0 ? 'Experience' : 'Reputation';
};

export const getShowKarmaAnimationTypeTestGroup = (currentUserSessionData) => {
  // return 0; // just change
  // return 1; // bg flash
  // return 2; // animation badge
  return currentUserSessionData.community_points_display_style_test_group;
};

export const getKarmaShowMaxPointsReachedTestGroup = (currentUserSessionData) => {
  // return 1; // show max points hit message
  return currentUserSessionData.show_hit_max_community_points_test_group;
};
