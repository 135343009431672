import React from 'react';
import IconButton from '../../components/buttons/IconButton';

class DeleteButton extends React.PureComponent {
  render() {
    const { onPress } = this.props;

    return (
      <div className="delete-button-container">
        <IconButton
          icon="fa-times fa-times-thin"
          customClass="circle-fill-button error-button-color"
          colorClassName=""
          size="medium"
          handleClick={onPress}
        />
      </div>
    );
  }
}

export default DeleteButton;
