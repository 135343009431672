import { ItlyBrowser as ItlySdk } from '@itly/sdk';
import SegmentPlugin from '@itly/plugin-segment';
// import { SchemaValidatorPlugin } from '@itly/plugin-schema-validator';
// import { trackingEventsValidationSchema } from '../../main/lib/nvstr-utils.es';

const VITE_SEGMENT_PUBLIC_KEY = import.meta.env.VITE_SEGMENT_PUBLIC_KEY || 'nMSBRenoRan8uRNkA2TeHbCknZDYZZDH';
const VITE_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT || 'development';

let environment = VITE_ENVIRONMENT;

export const getSegmentPublicAPIKey = () => VITE_SEGMENT_PUBLIC_KEY;

export const SEGMENT_OPTIONS = {
  trackAppLifecycleEvents: false,
  integrations: {
    Amplitude: true,
    // Adjust: true,
  },
};

const Logger = {
  debug: (e) => {
    // if (environment === 'development') console.log('itly debug: ', { e });
  },
  info: (e) => {
    if (environment === 'development') console.log('itly info: ', { e });
  },
  warn: (e) => {
    if (environment === 'development') console.log('itly warn: ', { e });
  },
  error: (e) => {
    if (environment === 'development') console.log('itly error: ', { e });
  },
};

const disable = false;
const itlyConfig = {
  validation: {
    disabled: false,
    trackInvalid: true,
    errorOnInvalid: false,
  },
  logger: Logger,
  environment,
  disabled: disable,
  plugins: [new SegmentPlugin(getSegmentPublicAPIKey(), SEGMENT_OPTIONS)],
};

if (disable) console.log('---Itly disabled');

class Itly {
  constructor(itlyConfig) {
    if (typeof Itly.instance === 'object') {
      return Itly.instance;
    }
    Itly.instance = this;
    this._itly = new ItlySdk();
    this._itly.load(itlyConfig);
    console.log('Itly initialized:', this._itly);

    this._isDisabled = itlyConfig.disabled === true;
    return this;
  }

  /**
   * Alias a user ID to another user ID.
   * @param {string} userId The user's new ID.
   * @param {string} previousId The user's previous ID.
   * @param {AliasOptions} [options] Options for this alias call.
   */
  alias(userId, previousId, options) {
    if (this._isDisabled) return null;
    this._itly.alias(userId, previousId, options);
  }

  /**
   * Identify a user and set or update that user's properties.
   * @param {string} [userId] The user's ID.
   * @param {Object} properties The user's properties.
   * @param {string} properties.lastName The user's last name.
   * @param {string} properties.firstName The user's first name.
   * @param {IdentifyOptions} [options] Options for this identify call.
   */
  identify(userId, properties, options) {
    if (this._isDisabled) return null;
    try {
      const segmentOptions = options;
      segmentOptions.segment = {
        options: {
          integrations: {
            All: true,
          },
        },
      };
      this._itly.identify(userId, properties, segmentOptions);
    } catch (err) {
      console.log('iterative.ly identify error:', { err });
    }
  }

  /**
   * Associate the current user with a group.
   * @param {string} groupId The group's ID.
   * @param {GroupOptions} [options] Options for this group call.
   */
  group(groupId, options) {
    if (this._isDisabled) return null;
    this._itly.group(groupId, undefined, options);
  }

  /**
   * Track a page view.
   * @param {string} category The page's category.
   * @param {string} name The page's name.
   * @param {PageOptions} [options] Options for this page call.
   */
  page(category, name, options) {
    if (this._isDisabled) return null;
    this._itly.page(category, name, undefined, options);
  }

  /**
   * @param {string} name The event name.
   * @param {object} properties .
   * @example  itly.track(events.emailConfirmed, {})
   */
  track(name, properties) {
    if (this._isDisabled) return null;
    this._itly.track({ name, properties });
  }

  /**
   * Reset (e.g. on logout) all analytics state for the current user and group.
   */
  reset() {
    if (this._isDisabled) return null;
    this._itly.reset();
  }

  flush() {
    if (this._isDisabled) return null;
    return this._itly.flush();
  }
}

const itly = new Itly(itlyConfig);

export default itly;
