import React from 'react';
import styled from 'styled-components';

const SvgWrapper = styled.svg`
  width: 40px;

  rect,
  circle {
    fill: ${({ theme, color }) => color || theme.themeColors.border};
  }
`;

export const SVG_LOADING_ICON_TYPES = {
  balls: '3balls',
  bars: 'bars',
};

export const SvgLoadingIcon = ({ type, color }) => {
  if (type === SVG_LOADING_ICON_TYPES.balls) {
    return (
      <SvgWrapper
        version="1.1"
        id="L4"
        x="0px"
        y="0px"
        viewBox="0 0 76 22"
        enableBackground="new 0 0 0 0"
        xml:space="preserve"
        color={color}
      >
        <circle stroke="none" cx="12" cy="10" r="10">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
        </circle>
        <circle stroke="none" cx="38" cy="10" r="10">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2" />
        </circle>
        <circle stroke="none" cx="64" cy="10" r="10">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3" />
        </circle>
      </SvgWrapper>
    );
  }

  if (type === SVG_LOADING_ICON_TYPES.bars) {
    return (
      <SvgWrapper
        version="1.1"
        id="L9"
        x="0px"
        y="0px"
        viewBox="0 0 30 30"
        enableBackground="new 0 0 0 0"
        xml:space="preserve"
        color={color}
      >
        <rect x="5" y="0" width="4" height="8">
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="translate"
            values="0 0; 0 20; 0 0"
            begin="0"
            dur="0.7s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="13" y="0" width="4" height="8">
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="translate"
            values="0 0; 0 20; 0 0"
            begin="0.2s"
            dur="0.7s"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="21" y="0" width="4" height="8">
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="translate"
            values="0 0; 0 20; 0 0"
            begin="0.4s"
            dur="0.7s"
            repeatCount="indefinite"
          />
        </rect>
      </SvgWrapper>
    );
  }

  return null;
};
