import React, { Component } from 'react';
import { withRouter } from '../../main/utils';

export class CustomLink extends Component {
  componentDidMount() {
    const linkNode = this._returnRefToDOMNode();
    const $link = $(linkNode);
    $link.on('click', this._handleClick);
  }

  _handleClick = (e) => {
    e.preventDefault();

    this.props.navigate(this.props.to);

    if (this.props.additionalClickAction) {
      this.props.additionalClickAction();
    }
  };

  render() {
    return (
      <a ref={(el) => (this.Link = el)} href={this.props.to} className={this.props.className}>
        {this.props.text || null}
      </a>
    );
  }

  _returnRefToDOMNode = () => {
    return this.Link;
  };
}

export default withRouter(CustomLink);
