import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageCard } from '@src/main/components/layout';
import { PageCardWrapper } from '@src/main/components/layout/PageCard';
import { PublicPageMessaging } from '@src/main/components/public/PublicPageMessaging';
import { SplitPage } from '@src/main/components/layout/SplitPage';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isSignedIn, resetPasswordSubmit } from '@src/main/containers/SignIn/utils/services';
import {
  FormTitle,
  FormWrap,
  LoadingCover,
  SignSwitchLink,
  SubTitle,
  ValidationWrap,
} from '@src/main/components/login/LoginStyles';
import { Place } from '@src/main/lib/nvstr-common-ui.es';
import Loading from '@src/main/components/login/Loading';
import InputField from '@src/main/components/form/InputField';
import { FlatButton } from '@src/main/components/buttons';
import { Container } from '@src/main/components/ui';

const SignInFormWrapper = styled.div`
  ${PageCardWrapper} {
    padding: 24px;

    -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  }
`;

function ResetPasswordForm() {
  const navigate = useNavigate();
  const location = useLocation();

  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [resetPasswordToken, setResetPasswordToken] = useState('');

  const [isLoading, setIsloading] = useState(false);
  const [forgotPasswordComplete, setResetPasswordComplete] = useState(false);
  const [isFailedValidation, setIsFailedValidation] = useState(true);
  const [validationMessage, setValidationMessage] = useState('');

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const resetToken = searchParams.get('reset_password_token');
    setResetPasswordToken(resetToken);
  }, []);

  React.useEffect(() => {
    const checkIfAlreadyAuthed = async () => {
      const { isAuthed } = await isSignedIn();
      if (isAuthed) {
        navigate('/app/users/edit?tab=preferences');
      }
    };
    checkIfAlreadyAuthed();
  }, []);

  const userPasswordComplete = () => {
    setIsloading(false);
    setResetPasswordComplete(true);
  };

  const userPasswordError = (errorMessage) => {
    setIsloading(false);
    setIsFailedValidation(true);
    setValidationMessage(errorMessage);
  };

  const userPassword = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsloading(true);
    const passwordLength = password1 && password1.length > 7;
    const passWordsMatch = password1 === password2;
    if (passwordLength && passWordsMatch) {
      setIsFailedValidation(false);
      resetPasswordSubmit({
        password1,
        password2,
        userPasswordComplete,
        userPasswordError,
        resetPasswordToken,
      });
    } else {
      setIsFailedValidation(true);
      setIsloading(false);
      if (!passWordsMatch) {
        setValidationMessage('Please enter the same password twice.');
      } else if (!passwordLength) {
        setValidationMessage('Password must have at least 8 characters.');
      }
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      userPassword(e);
    }
  };

  if (forgotPasswordComplete) {
    return (
      <div>
        <FormTitle>Your Password has been reset. </FormTitle>
        <Container top={24}>
          <SubTitle>
            Continue to <Link to="/login">Sign In</Link>
          </SubTitle>
        </Container>
      </div>
    );
  }
  if (isLoading) {
    return (
      <LoadingCover>
        <Loading text={'Submitting...'} />
      </LoadingCover>
    );
  }

  return (
    <div onKeyDown={(e) => handleKeyUp(e)}>
      <FormWrap>
        <form>
          <Container bottom={24}>
            <FormTitle>Reset Password</FormTitle>
          </Container>

          <InputField
            id="password-field-1"
            placeholder={'New Password'}
            type="password"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            autoComplete="current-password"
          />

          <InputField
            id="password-field-2"
            placeholder={'Confirm New Password'}
            type="password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            autoComplete="current-password"
          />

          {isFailedValidation ? <ValidationWrap>{validationMessage}</ValidationWrap> : null}

          <Container top={16}>
            <FlatButton id="forgot-password-submit" fullWidth onClick={userPassword}>
              Submit
            </FlatButton>
          </Container>
          <Container top={8}>
            <SignSwitchLink>
              Back to <Link to="/login">Sign In</Link>
            </SignSwitchLink>
          </Container>
        </form>
      </FormWrap>
    </div>
  );
}

export const ResetPassword = () => {
  return (
    <SplitPage
      LeftComponent={<PublicPageMessaging />}
      RightComponent={
        <SignInFormWrapper>
          <PageCard>
            <ResetPasswordForm />
          </PageCard>
        </SignInFormWrapper>
      }
    />
  );
};
