import { ordersAdapter } from '../adapters/ordersAdapter';

import {
  GETTING_ORDER_DATA,
  ADD_ORDER_DATA,
  CREATE_ORDERS,
  PLACE_ORDERS,
  UPDATE_ORDER,
  CANCEL_ORDER,
  DELETE_ORDER,
  UNDELETE_ORDER,
  CLEAR_ORDER_BASKET,
  UNDO_CLEAR_ORDER_BASKET,
  ADD_PLACED_ORDER,
} from '../constants';

import { cleanGetOrdersResponseData } from '../helpers/ordersHelpers';

export const getOrders = (options) => {
  return function(dispatch) {
    if (options && options.ignoreLoading) {
      // do nothing for now
    } else {
      dispatch({
        type: GETTING_ORDER_DATA,
      });
    }

    return ordersAdapter.getOrders(options).then((response) => {
      const wasAjaxSuccessful = response && response.data && !response.data.error;
      if (wasAjaxSuccessful) {
        const permittedData = cleanGetOrdersResponseData(response);
        dispatch({
          type: ADD_ORDER_DATA,
          payload: permittedData,
        });
      } else {
        // TODO: handle error
      }
      return response;
    });
  };
};

export const createOrders = (orders, options = {}) => {
  return function(dispatch) {
    if (options.clearOrdersBefore) {
      return clearOrderBasket()(dispatch).then((response) => {
        if (response && response.ok) {
          return createOrders(orders)(dispatch);
        } else {
          return {
            error: 'Something went wrong. Please try again',
          };
        }
      });
    }

    return ordersAdapter.createOrders(orders).then((response) => {
      if (options.redirectTo) {
        window.location = options.redirectTo;
      }
      const wasOrderCreated = response && response.data && response.data[0] && response.data[0].status === 'created';
      if (wasOrderCreated) {
        const createdOrder = response.data;
        const orders = response.data;
        dispatch({
          type: CREATE_ORDERS,
          payload: { orders: createdOrder },
        });
        return {
          ...response,
          wasSuccessful: true,
          orders,
        };
      } else {
        return {
          ...response,
          wasError: true,
        };
      }
    });
  };
};

export const createAndPlaceOrder = (order, acceptedWarnings) => {
  return function(dispatch) {
    return ordersAdapter.createAndPlaceOrder(order, acceptedWarnings).then((response) => {
      const status = (response && response.data && response.data.status) || null;
      const statusesIndicatingOrdersPlaced = ['success', 'success_with_error'];
      const statusesIndicatingMoreActionNeeded = ['warning'];
      const wereOrdersCreated = statusesIndicatingOrdersPlaced.includes(status);
      if (wereOrdersCreated) {
        const placedOrders = response.data.orders;
        dispatch({
          type: PLACE_ORDERS,
          payload: {
            orders: placedOrders,
          },
        });
        return {
          ...response,
          ok: true,
          withErrors: status === 'success_with_error',
          errorMessage: response.data.error,
        };
      } else if (statusesIndicatingMoreActionNeeded.includes(status)) {
        return {
          ...response,
          ok: true,
        };
      } else {
        return {
          ...response,
          error:
            (response && response.data && response.data.error) ||
            (response && response.data && response.data[0] && response.data[0].error) ||
            'Something went wrong, please try again.',
        };
      }
    });
  };
};

export const placeOrders = (orderIds, acceptedWarnings) => {
  return function(dispatch) {
    return ordersAdapter.placeOrders(orderIds, acceptedWarnings).then((response) => {
      const status = (response && response.data && response.data.status) || null;
      const statusesIndicatingOrdersPlaced = ['success', 'success_with_error'];
      const statusesIndicatingMoreActionNeeded = ['warning'];
      const wereOrdersCreated = statusesIndicatingOrdersPlaced.includes(status);
      if (wereOrdersCreated) {
        const placedOrders = response.data.orders;
        dispatch({
          type: PLACE_ORDERS,
          payload: {
            orders: placedOrders,
          },
        });
        return {
          ...response,
          ok: true,
          withErrors: status === 'success_with_error',
          errorMessage: response.data.error,
        };
      } else if (statusesIndicatingMoreActionNeeded.includes(status)) {
        return {
          ...response,
          ok: true,
        };
      } else {
        return {
          ...response,
          error: (response && response.data && response.data.error) || 'Something went wrong, please try again.',
        };
      }
    });
  };
};

export const addOrderToBasket = (order) => {
  return function(dispatch) {
    return createOrders([order])(dispatch);
  };
};

export const updateOrderInBasket = (order) => {
  return function(dispatch) {
    return ordersAdapter.updateOrderInBasket(order).then((response) => {
      const wasOrderUpdated = response && response.data && response.data.status === 'updated';
      if (wasOrderUpdated) {
        const updatedOrder = response.data;
        dispatch({
          type: UPDATE_ORDER,
          payload: { updatedOrder },
        });
        return {
          ...response,
          wasSuccessful: true,
        };
      } else {
        // TODO: handle error
        return {
          ...response,
          wasError: true,
        };
      }
    });
  };
};

export const deleteOrder = (orderId) => {
  return function(dispatch) {
    dispatch({
      type: DELETE_ORDER,
      payload: orderId,
    });
    return ordersAdapter.deleteOrder(orderId).then((response) => {
      if (response && response.data && response.data.status && response.data.status === 'deleted') {
        return {
          ...response,
          ok: true,
        };
      } else {
        dispatch({
          type: UNDELETE_ORDER,
          payload: orderId,
        });
        return {
          ...response,
          error: 'Something went wrong, please refresh and try again.',
        };
      }
    });
  };
};

export const dismissDayTraderMessage = () => {
  return function(dispatch) {
    return ordersAdapter.dismissDayTraderMessage().then((response) => {
      return response;
    });
  };
};

export const cancelPlacedOrder = (orderId) => {
  return function(dispatch) {
    return ordersAdapter.cancelPlacedOrder(orderId).then((response) => {
      const wasSuccessful = response && response.data && response.data.status === 'canceled';
      if (wasSuccessful) {
        const updatedOrder = response.data;
        // dispatch({
        //   type: CANCEL_ORDER,
        //   payload: { updatedOrder },
        // });
      }
      return response;
    });
  };
};

export const clearOrderBasket = (orderIds) => {
  return function(dispatch) {
    dispatch({
      type: CLEAR_ORDER_BASKET,
    });
    return ordersAdapter.clearOrderBasket().then((response) => {
      if (response && response.data && response.data.status && response.data.status === 'deleted') {
        return {
          ok: true,
        };
      } else {
        dispatch({
          type: UNDO_CLEAR_ORDER_BASKET,
          payload: orderIds,
        });
        return { error: 'Could not clear basket, please try again later.' };
      }
    });
  };
};

export const getOrderCommission = (orderData) => {
  return ordersAdapter.getOrderCommission(orderData).then((response) => {
    const ok = response && response.status && response.status === 200;
    if (ok) {
      return {
        ok: true,
        data: response.data,
      };
    } else {
      return {
        ok: false,
        errorMessage: 'Unable to determine commission for trade, please try again.',
      };
    }
  });
};
