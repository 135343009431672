import React from 'react';

const ChargeHeader = (props) => {
  const { columns } = props;
  return (
    <div className={`charge-header-container border-accent`}>
      <div className={`single-charge-info`}>
        <p>{columns[0]}</p>
      </div>
      <div className={`single-charge-value`}>
        <p>{columns[1]}</p>
      </div>
    </div>
  );
};

export default ChargeHeader;
