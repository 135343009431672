import React from 'react';
import styled from 'styled-components';
import { PageCard } from '@src/main/components/layout';
import { PageCardWrapper } from '@src/main/components/layout/PageCard';
import { PublicPageMessaging } from '@src/main/components/public/PublicPageMessaging';
import { SplitPage } from '@src/main/components/layout/SplitPage';
import { SignUpForm } from '@src/main/containers/SignUp/SignUpForm';

const SignInFormWrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;

  ${PageCardWrapper} {
    padding: 24px;

    -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  }
`;

export const SignUp = () => {
  return (
    <SplitPage
      LeftComponent={<PublicPageMessaging hideOnMobile />}
      RightComponent={
        <SignInFormWrapper>
          <PageCard>
            <SignUpForm />
          </PageCard>
        </SignInFormWrapper>
      }
    />
  );
};
