import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { FlatButton } from '@src/main/components/buttons';
import { sendFacebookTrackingEvent } from '@src/constants/facebookTracking';
import { returnCurrentUserThemeTestGroups } from '@src/helpers/currentUserHelpers';
import { useSecurity } from '@src/main/hooks/securities/useSecurity';
import { useCurrentUser } from '@src/main/hooks/user';
import { truncateName } from '@src/helpers/generalHelpers';
import PlaceholderImage from '@src/containers/UI/PlaceholderImage';
import Image from '@src/containers/UI/Image';
import { formatLocalizedDateTime, moment } from '@src/helpers/timeHelpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchNewsImage,
  getUnseenNotificationsCount,
  getUpdatedNotifications,
  logMetricsTrackingEvent,
  sendNewsClickEvent,
} from '@src/actions';
import LoadingIcon from '@src/components/misc/LoadingIcon';
import { NewsImpressionTracker } from '@src/containers/Tracking/ImpressionTrackingTypes/NewsImpressionTracker';
import CreateThoughtFromNewsContainer from '@src/containers/News/CreateThoughtFromNewsContainer';
import { useElementSize } from '@src/main/hooks/util';
import { createQueryString } from '@src/helpers/routerHelpers';
import { useLocation, useSearchParams } from 'react-router-dom';
import { PAGE_PANEL_TYPE } from '../../paths/PagePanelRenderer';
import { parseToSearchParamsLookup } from '../../helpers/usefulFuncs';
import { THE_STREET_NEWS_UTM_CAMPAIGNS } from '@src/constants/types/utm';
import { PRODUCT_NAME, PRODUCT_NAMES } from '@src/appConfig';

const ProWrapper = styled.div``;
const ConWrapper = styled.div``;
const NewsWrapper = styled.span`
  cursor: pointer;
  background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};
  &:hover {
    opacity: 0.8;
  }
`;
const CreateThoughtButtonWrapper = styled.div`
  margin: 0 auto;
  padding: 16px 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 142px;

  button {
    padding: 4px 12px;
    border: none !important;
    border-radius: 4px;
  }
  ${ProWrapper} {
    button {
      background-color: ${({ theme }) => theme.themeColors.proColor};
      color: ${({ theme }) => theme.themeColors.buttonText};
    }
  }
  ${ConWrapper} {
    button {
      background-color: ${({ theme }) => theme.themeColors.conColor};
      color: ${({ theme }) => theme.themeColors.buttonText};
    }
  }
`;

const useOGO = (newsData) => {
  const dispatch = useDispatch();
  const articleOriginalURL = newsData.open_graph_object.url;

  const [isLoading, setIsLoading] = React.useState(true);
  const storeOgo = useSelector((state) => state.news.ogoByUrl[articleOriginalURL]);

  React.useEffect(() => {
    const getImage = async () => {
      await fetchNewsImage(newsData)(dispatch);
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    };
    getImage();
  }, []);

  return { isLoading, ogo: storeOgo };
};

const CreateThoughtActions = ({ onCreatePress, isCurrentUserAuthed }) => {
  const navigate = useNavigate();

  const requireLogin = () => {
    navigate('/?loginRequired=true');
  };

  const onProClick = (e) => {
    e?.stopPropagation();

    if (isCurrentUserAuthed) {
      onCreatePress('pro');
    } else {
      requireLogin(window);
    }
  };
  const onConClick = (e) => {
    e?.stopPropagation();

    if (isCurrentUserAuthed) {
      onCreatePress('con');
    } else {
      requireLogin(window);
    }
  };

  return (
    <CreateThoughtButtonWrapper className={'post-thought-action-buttons'}>
      <ProWrapper>
        <FlatButton onClick={onProClick}>+ Pro</FlatButton>
      </ProWrapper>
      <ConWrapper>
        <FlatButton onClick={onConClick}>+ Con</FlatButton>
      </ConWrapper>
    </CreateThoughtButtonWrapper>
  );
};

const ArticleSummary = ({ isLoadingOgo, articleSummary }) => {
  const maxLength = 250;
  const articleSummaryTruncated = truncateName(articleSummary, maxLength);

  if (isLoadingOgo) {
    return (
      <span className={'news-summary loading'}>
        <LoadingIcon icon="fading-3balls" size="small" />
      </span>
    );
  }
  return <span className={'news-summary'}>{articleSummaryTruncated}</span>;
};

const ArticleTimestamp = ({ newsData }) => {
  const timeNow = moment();
  const timestamp = newsData.timestamp * 1000;
  const timestampMoment = moment(timestamp);

  const isWithinADay = !timestampMoment.isBefore(timeNow, 'day');
  const timestampReadableDateString = `${formatLocalizedDateTime('l', timestampMoment)} ${formatLocalizedDateTime(
    'LT',
    timestampMoment
  )}`;
  const timestampReadableDayTimeSinceString = timestampMoment.fromNow();
  const timestampReadableString = isWithinADay ? timestampReadableDayTimeSinceString : timestampReadableDateString;

  return <span className={'news-timestamp secondary-text-color border-accent'}>{timestampReadableString}</span>;
};

const MoreInfoLink = ({ buildNewsClickTrackingData, securityId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { symbol } = useSecurity(securityId);

  const securityCard = {
    panel: 'security',
    securityId,
    activeTab: 'headlines',
  };
  const currentPath = location.pathname;
  const queryString = createQueryString(securityCard);
  const url = currentPath + queryString;
  const handleClick = (e) => {
    e?.stopPropagation();

    navigate(url);

    const event = 'Open Security Panel';
    const properties = {
      Component: 'News In Feed Component',
      Context: 'Security Panel',
      Symbol: symbol,
    };
    sendNewsClickEvent(buildNewsClickTrackingData('more'))(dispatch);
    logMetricsTrackingEvent(event, properties)();
  };

  return (
    <FlatButton transparent onClick={handleClick}>
      {'More News On ' + symbol}
    </FlatButton>
  );
};

const ArticleImage = ({ inFeed, isLoadingOgo, ogo }) => {
  const [didImageLoadFail, setFailed] = React.useState(false);

  if (isLoadingOgo) {
    return (
      <span className={'news-image-container loading'}>
        <LoadingIcon icon="fading-3balls" size="small" />
      </span>
    );
  }
  const { images_url: imgLink } = ogo || {};

  if (!imgLink || didImageLoadFail) return <PlaceholderImage />;

  const onLoadFail = () => setFailed(true);
  return (
    <span
      className={'news-image-container'}
      style={
        inFeed
          ? {
              maxHeight: 'auto',
              minHeight: 'auto',
              width: 'auto',
              maxWidth: 'auto',
            }
          : {}
      }
    >
      <Image
        src={imgLink}
        handleImageLoadFailure={onLoadFail}
        parentClass={'.news-image-container'}
        maxHeight={inFeed ? 300 : null}
        renderPlaceholder
      />
    </span>
  );
};

const ArticleBody = ({
  securityId,
  isCurrentUserAuthed,
  mobile,
  newsData,
  articleSource,
  inFeed,
  displayHeadline,
  articleSummary,
  isLoadingOgo,
  buildNewsClickTrackingData,
  onCreateThoughtPress,
}) => {
  if (mobile) {
    return (
      <span className={'news-body'}>
        <span className={'news-header'}>
          <span className={'news-source'}>{articleSource}</span>
          <ArticleTimestamp newsData={newsData} />
        </span>

        <CreateThoughtActions onCreatePress={onCreateThoughtPress} isCurrentUserAuthed={isCurrentUserAuthed} />

        {inFeed && (
          <span className={'more-news-button-container'}>
            <MoreInfoLink securityId={securityId} buildNewsClickTrackingData={buildNewsClickTrackingData} />
          </span>
        )}
      </span>
    );
  }

  return (
    <span className={'news-body'}>
      <span className={'news-header'}>
        <span className={'news-source'}>{articleSource}</span>
        <ArticleTimestamp newsData={newsData} />
      </span>

      <span className={'news-headline'}>{displayHeadline}</span>
      <ArticleSummary articleSummary={articleSummary} isLoadingOgo={isLoadingOgo} />
      {inFeed && (
        <span className={'more-news-button-container'}>
          <MoreInfoLink securityId={securityId} buildNewsClickTrackingData={buildNewsClickTrackingData} />
        </span>
      )}
      <CreateThoughtActions onCreatePress={onCreateThoughtPress} isCurrentUserAuthed={isCurrentUserAuthed} />
    </span>
  );
};

export const NewsArticle = ({
  securityId,
  newsData,
  size,
  inFeed,
  isNotification,
  viewingContext,
  notificationIndex,
  ignoreImpressionTracking,
  isCurrentUserAuthed,
  scrollContainerSelector,
  recordImpressionOnMount,
}) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    id: newsArticleId,
    open_graph_object: { title, description },

    source: articleSource,
    metadata,
  } = newsData;
  const articleOriginalURL = newsData.open_graph_object.url;

  const ref = React.useRef(null);
  const [creatingThoughtType, setCreatingThoughtType] = React.useState(null);
  const { isLoading: isLoadingOgo, ogo } = useOGO(newsData);

  const [setRef, { width }] = useElementSize();

  React.useEffect(
    () => {
      setRef(ref);
    },
    [ref]
  );

  const currentUser = useCurrentUser();
  const security = useSecurity(securityId);

  const headline = title || '';

  const headlineMaxLength = 100;
  const displayHeadline = truncateName(headline, headlineMaxLength);

  const articleSummary = ogo?.description || '';

  const buildNewsClickTrackingData = (clickType) => ({
    id: newsArticleId,
    securityId,
    clickType,
    context: viewingContext,
    indexInFeed: notificationIndex,
    metadata,
  });

  const newsTrackingData = {
    'Concernable Id': securityId,
    'Concernable Type': 'Security',
    Source: articleSource,
    URL: articleOriginalURL,
    'Security ID': securityId,
    'Stock Symbol': security.symbol,
    Context: isNotification ? 'News Notification' : 'Security Panel',
  };

  const _logNewsArticleClick = () => {
    sendFacebookTrackingEvent('Click News Article', {
      themeTestGroups: returnCurrentUserThemeTestGroups(currentUser),
    });
    const event = 'Clicked News Article';
    const properties = newsTrackingData;
    sendNewsClickEvent(buildNewsClickTrackingData('link'))(dispatch);
    logMetricsTrackingEvent(event, properties)();
  };

  const onCreateThoughtPress = (type) => {
    setCreatingThoughtType(type);
  };
  const _handleFinishCreatingThought = () => {
    setCreatingThoughtType(null);

    const notifConfig = {
      batch_index: 0,
      page: 1,
    };
    getUpdatedNotifications(notifConfig)(dispatch).then((response) => {
      getUnseenNotificationsCount()(dispatch);
      return response;
    });
  };
  const _handleCancelCreatingThought = () => {
    setCreatingThoughtType(null);
  };
  const onArticleClick = () => {
    // const currentParams = parseToSearchParamsLookup(searchParams);
    // const params = {
    //   ...currentParams,
    //   showing: PAGE_PANEL_TYPE.news,
    //   url: articleOriginalURL,
    // };
    // setSearchParams(params);
    let externalUrl = articleOriginalURL;
    if (articleOriginalURL.includes('thestreet') && PRODUCT_NAMES.TheStreet === PRODUCT_NAME) {
      const campaign = inFeed ? THE_STREET_NEWS_UTM_CAMPAIGNS.feed : THE_STREET_NEWS_UTM_CAMPAIGNS.newsTab;
      const utmParams = `utm_source=tornado&utm_medium=webapp&utm_campaign=${campaign}`;
      externalUrl = `${articleOriginalURL}${articleOriginalURL.includes('?') ? '&' : '?'}${utmParams}`;
    }
    window?.open(externalUrl, '_blank');
    _logNewsArticleClick();
  };

  if (creatingThoughtType !== null) {
    const thoughtType = creatingThoughtType.toLowerCase() === 'pro' ? { id: 0, name: 'Pro' } : { id: 1, name: 'Con' };
    return (
      <div ref={ref}>
        <CreateThoughtFromNewsContainer
          securityId={securityId}
          thoughtType={thoughtType}
          ogo={ogo}
          isLoadingCustomImage={isLoadingOgo}
          handleFinishCreatingThought={_handleFinishCreatingThought}
          handleCancelCreatingThought={_handleCancelCreatingThought}
          newsTrackingData={newsTrackingData}
        />
      </div>
    );
  }

  const maxWidthForDefaultSize = 667;
  if (width < maxWidthForDefaultSize || size === 'small') {
    return (
      <NewsWrapper
        onClick={onArticleClick}
        className={`
          news-component-container
          news-component-container-${newsArticleId}
          news-article
          ${inFeed ? 'news-in-feed' : ''}
          ${size || ''}
        `}
        ref={ref}
      >
        {!ignoreImpressionTracking && (
          <NewsImpressionTracker
            newsId={newsArticleId}
            securityId={securityId}
            notificationIndex={notificationIndex}
            metadata={metadata}
            viewingContext={viewingContext}
            scrollContainerSelector={scrollContainerSelector}
            containerElementSelector={'.news-component-container'}
            recordImpressionOnMount={recordImpressionOnMount}
          />
        )}
        <div
          className={`
            news-component
            news-article
          `}
        >
          <span className={'news-headline'}>{displayHeadline}</span>
          <ArticleImage inFeed={inFeed} isLoadingOgo={isLoadingOgo} ogo={ogo} />
          <ArticleBody
            buildNewsClickTrackingData={buildNewsClickTrackingData}
            inFeed={inFeed}
            securityId={securityId}
            newsData={newsData}
            isCurrentUserAuthed={isCurrentUserAuthed}
            articleSource={articleSource}
            displayHeadline={displayHeadline}
            articleSummary={articleSummary}
            isLoadingOgo={isLoadingOgo}
            onCreateThoughtPress={onCreateThoughtPress}
            mobile
          />
        </div>
      </NewsWrapper>
    );
  } else {
    return (
      <NewsWrapper
        onClick={onArticleClick}
        className={`
          news-component-container
          news-component-container-${newsArticleId}
          news-article
          ${inFeed ? 'news-in-feed' : ''}
          ${size || ''}
        `}
        ref={ref}
      >
        {!ignoreImpressionTracking && (
          <NewsImpressionTracker
            newsId={newsArticleId}
            securityId={securityId}
            notificationIndex={notificationIndex}
            metadata={metadata}
            viewingContext={viewingContext}
            scrollContainerSelector={scrollContainerSelector}
            containerElementSelector={'.news-component-container'}
            recordImpressionOnMount={recordImpressionOnMount}
          />
        )}
        <div
          className={`
            news-component
            news-article
          `}
        >
          <ArticleImage inFeed={inFeed} isLoadingOgo={isLoadingOgo} ogo={ogo} />
          <ArticleBody
            newsData={newsData}
            securityId={securityId}
            articleSource={articleSource}
            articleSummary={articleSummary}
            isLoadingOgo={isLoadingOgo}
            isCurrentUserAuthed={isCurrentUserAuthed}
            inFeed={inFeed}
            displayHeadline={displayHeadline}
            onCreateThoughtPress={onCreateThoughtPress}
          />
        </div>
      </NewsWrapper>
    );
  }
};
