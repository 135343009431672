import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import Idea from '../../components/IdeaLab/PositionVisualizer/IdeaComponents/Idea';

class AdditionalIdeasContainer extends Component {
  constructor() {
    super();

    this.state = {
      showAdditionalIdeas: false,
    };
  }

  handleClick(event) {
    event.preventDefault();
    this.setState({ showAdditionalIdeas: !this.state.showAdditionalIdeas });
  }

  determineOffset() {
    const width = this.determineTooltipWidth.call(this);
    return this.props.position === 'long' || this.props.expandDirection === 'left' ? width * -1 + 42 : -18;
  }

  determineTooltipWidth() {
    const ideas = this.props.ideas;
    const padding = 10;
    const tooltipPictWidthPlusMargin = 31;

    return padding + tooltipPictWidthPlusMargin * ideas.length + 2;
  }

  render() {
    const ideas = this.props.ideas;
    const additionalConnectionIdeas = this.props.ideas.length;
    const show = this.state.showAdditionalIdeas ? '' : 'hidden';
    const expandDirection =
      this.props.expandDirection === 'left' ? 'additional-ideas-expand-left' : 'additional-ideas-expand-right';
    const style = { width: `${this.determineTooltipWidth()}px` };
    const offset = { left: `${this.determineOffset.call(this)}px` };

    if (additionalConnectionIdeas === 0) {
      return <div></div>;
    }

    return (
      <div className="position-visualizer-idea-container">
        <a className="grouped-ideas-button main-bg-color-match border-accent" onClick={this.handleClick.bind(this)}>
          <span className="no-select  ">{`+${additionalConnectionIdeas}`}</span>
        </a>
        <div className={`tooltip-container ${expandDirection} ${show}`} style={{ ...style, ...offset }}>
          <div className="tooltip-body main-bg-color-match border-accent">
            {ideas.map((idea) => (
              <Idea
                key={`idea-${idea.user_id}`}
                userId={idea.user_id}
                idea={this.props.showIdeaTypeShadow && idea}
                showNameTag={idea.idea_id === this.props.ideaBeingHovered}
                hideLabels={this.props.hideLabels}
                handleHover={() => {
                  this.props.handleHover(idea.idea_id);
                }}
                handleHoverEnd={this.props.handleHoverEnd}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default AdditionalIdeasContainer;
