import { getStartOfDayTodayEpochValue } from './timeHelpers';
import { throwError } from './devToolHelpers';

/******************************
  Impression Helpers
*******************************/

const _storage = {};
const _mockStorage = () => ({
  getItem: (key) => _storage[key],
  setItem: (key, value) => (_storage[key] = value),
  removeItem: (key) => delete _storage[key],
});

const _startingStateOfImpressionData = '{}';

const _getStorage = () => window.sessionStorage || _mockStorage();

const _createImpressionKeyFromContext = (impressionType) => impressionType + 'ImpressionData';

export const getImpressionSessionStorageRawDataByType = (impressionType) =>
  _getStorage().getItem(_createImpressionKeyFromContext(impressionType)) || _startingStateOfImpressionData;

export const getImpressionByDayEpochLookupSessionStorageDataByType = (impressionType) =>
  JSON.parse(getImpressionSessionStorageRawDataByType(impressionType));

export const getImpressionIdListSessionStorageDataByType = (impressionType, context) => {
  if (!context) {
    throwError('Missing context for getImpressionIdListSessionStorageDataByType', true);
  }
  const byDayLookup = getImpressionByDayEpochLookupSessionStorageDataByType(impressionType);
  const allIdsList = [];
  const datesEpochList = Object.keys(byDayLookup);
  datesEpochList.forEach((dateEpochKey) => {
    const contextDataLookup = byDayLookup[dateEpochKey];
    const contextImpressions = contextDataLookup[context];
    if (contextImpressions) {
      contextImpressions.forEach((id) => allIdsList.push(id));
    }
  });
  return allIdsList;
};

export const getTodaysImpressionContextLookupSessionStorageData = (impressionType) => {
  return getImpressionByDayEpochLookupSessionStorageDataByType(impressionType)[getStartOfDayTodayEpochValue()] || {};
};

export const getTodaysImpressionContextIdListSessionStorageData = (impressionType, context) => {
  return getTodaysImpressionContextLookupSessionStorageData(impressionType)[context] || [];
};

export const wasImpressionRecorded = (impressionType, id, context) => {
  return getImpressionIdListSessionStorageDataByType(impressionType, context).includes(id);
};

export const saveImpressionDataToImpressionSessionStorage = (impressionType, impressionData) => {
  _getStorage().setItem(_createImpressionKeyFromContext(impressionType), JSON.stringify(impressionData));
  return true;
};

export const saveToImpressionSessionStorage = (impressionType, id, context) => {
  const impressionData = getImpressionByDayEpochLookupSessionStorageDataByType(impressionType, context);
  const todaysImpressionData = getTodaysImpressionContextLookupSessionStorageData(impressionType);
  const todaysImpressionDataInContext = getTodaysImpressionContextIdListSessionStorageData(impressionType, context);
  if (wasImpressionRecorded(impressionType, id, context)) {
    return false;
  }
  todaysImpressionDataInContext.push(id);
  const updatedImpressionData = {
    ...impressionData,
    [getStartOfDayTodayEpochValue()]: {
      ...todaysImpressionData,
      [context]: todaysImpressionDataInContext,
    },
  };
  return saveImpressionDataToImpressionSessionStorage(impressionType, updatedImpressionData);
};

export const saveImpressionToStorageIfUnrecorded = (impressionType, id, context) => {
  if (!wasImpressionRecorded(impressionType, id, context)) {
    return saveToImpressionSessionStorage(impressionType, id, context);
  }
  return false;
};
