import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../../actions/index';

import HorizontalList from '../../UI/HorizontalList';
import UserYouMayKnowCard from './components/UserYouMayKnowCard';
import LoadingCard from './components/LoadingCard';

import { createBasicErrorModal } from '../../../constants/modals';
import { isConnectionsDataLoadingForUser } from '../../../helpers/userConnectionsHelpers';
import { isUndefinedOrNull } from '../../../helpers/generalHelpers';

class PeopleYouMayKnowContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestedConnectionUserIds: [],

      sentNoSuggestionsEvent: false,
    };
  }

  componentDidMount() {
    this.props.actions.fetchPeopleYouMayKnow();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.isLoading() && this.returnListOfPeopleYouMayKnow().length === 0 && !this.state.sentNoSuggestionsEvent) {
      this.setState({ sentNoSuggestionsEvent: true });
      const event = 'No Suggestions For User';
      this.props.actions.logMetricsTrackingEvent(event);
    }
  }

  render() {
    // hide if not loading and no suggestions
    if (!this.isLoading() && this.returnListOfPeopleYouMayKnow().length === 0) {
      return null;
    }

    return (
      <div className={`people-you-may-know-container`}>
        <div className={`people-you-may-know-header`}>{`People You May Know`}</div>
        <HorizontalList
          id="people_you_may_know"
          height={160}
          itemWidth={130}
          paddingBetweenItems={25}
          isLoading={this.isLoading()}
          items={this.returnListOfPeopleYouMayKnow()}
          loadingCardComponent={LoadingCard}
          componentName={UserYouMayKnowCard}
          listItemProps={this.userYouMayKnowCardProps()}
          contextForTrackingOnPageChange={'Connection Suggestions'}
          logItemView={this.logItemView}
          onPageChangeAction={this.logIgnoredCards}
          additionalEdgeFadeClassName="main-bg-transparent-gradient"
        />
        <div className={`content-separator border-accent`}></div>
      </div>
    );
  }

  logIgnoredCards = (userIds) => {
    // filter out connection requests
    const ignoredUserIds = userIds.filter((id) => !this.state.requestedConnectionUserIds.includes(id));
    // send to api - dismissalType = 'Ignored'
    const dismissalType = 'Ignored';
    ignoredUserIds.forEach((id) => {
      this.props.actions.dismissConnectionSuggestion(id, dismissalType);
    });
  };

  logItemView = (userIds) => {
    const validatedUserIds = userIds.filter((id) => !isUndefinedOrNull(id));
    const event = 'Suggested Connections In View';
    const context = 'Connections Page';
    const properties = { context, Users: validatedUserIds };
    this.props.actions.logMetricsTrackingEvent(event, properties);
    this.props.actions.viewSuggestedConnection(validatedUserIds, context);
  };

  userYouMayKnowCardProps = () => {
    const props = {
      checkIfRequestedConnection: (userId) =>
        this.state.requestedConnectionUserIds.filter((id) => id === userId).length > 0,
      handleConnectionRequest: (userId) => {
        this.addRequestedConnectionUserIds(userId);
        const context = 'suggested';
        this.props.actions.sendConnectionRequest(userId, context).then((response) => {
          // event for sending connection request is recorded on the server
          const error = false;
          if (error) {
            this.removeRequestedConnectionUserIds(userId);

            const modalMessage = 'Could not send request. Please try again later.';
            const modal = {
              contentComponent: createBasicErrorModal(modalMessage),
              dismissable: true,
            };
            this.props.actions.showModal(modal);
          } else {
            const event = 'Clicked Send Connection Request';
            const properties = {
              Context: 'People You May Know Suggestion',
            };
            this.props.actions.logMetricsTrackingEvent(event, properties);
          }
        });
      },
      handleDismissConnectionSuggestion: (userId) => {
        const event = 'Dismiss Connection Suggestion';
        this.props.actions.logMetricsTrackingEvent(event);

        const dismissalType = 'Clicked';
        this.props.actions.dismissConnectionSuggestion(userId, dismissalType);
      },
    };

    return props;
  };

  addRequestedConnectionUserIds = (userId) => {
    this.setState((prevState) => ({
      requestedConnectionUserIds: [...prevState.requestedConnectionUserIds, userId],
    }));
  };

  removeRequestedConnectionUserIds = (userId) => {
    this.setState((prevState) => ({
      requestedConnectionUserIds: [...prevState.requestedConnectionUserIds.filter((id) => id !== userId)],
    }));
  };

  returnListOfPeopleYouMayKnow = () => {
    return this.props.users.peopleYouMayKnow.usersYouMayKnow;
  };

  isLoading = () => {
    return this.props.users.peopleYouMayKnow.loading || this._isLoadingConnections();
  };

  _isLoadingConnections = () =>
    isConnectionsDataLoadingForUser(this._returnCurrentUserId(), this._returnUserConnectionsStore());

  _returnCurrentUser = () => this.props.currentUser;

  _returnCurrentUserId = () => this._returnCurrentUser().user_id;

  _returnUserConnectionsStore = () => this.props.userConnections;
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    users: state.users,
    userConnections: state.userConnections,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleYouMayKnowContainer);
