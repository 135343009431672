import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import { withRouter } from '@src/main/utils';
import * as Actions from '../../actions/index';
import SeeConnectionOpinions from './SeeConnectionOpinions';
import AgreeDisagreeContainer from './components/AgreeDisagreeContainer';
import ThoughtLink from './ThoughtLink';
import CreateThoughtContainer from './CreateThoughtContainer';
import AskAnyone from '../buttons/AskAnyone';
import SecurityIdentifierDisplay from '../UI/SecurityIdentifierDisplay';
import ThoughtImpressionTracker from './components/ThoughtImpressionTracker';
import LoadingIcon from '../../components/misc/LoadingIcon';
import Icon from '../../components/misc/Icon';
import Space from '../../components/UI/Space';
import InfoIcon from '../UI/InfoIcon';
import { HighlightAnimation } from '../UI/AnimationComponents/HighlightAnimation';
import CenterAlignItems from '../UI/Layout/CenterAlignItems';
import InfoIconTooltip from '../UI/InfoIconTooltip';
import ViewpointSubscribeButton from '../viewpoints/ViewpointSubscribeButton';
import WidthLayoutManager from '../UI/Utils/WidthLayoutManager';
import { Row } from '@src/main/lib/nvstr-common-ui.es';
import { isUndefinedOrNull, toCapitalCase, clickStopPropagation, truncateName } from '@src/helpers/generalHelpers';
import {
  isThoughtFromCommunityThoughtLeader,
  getFocusThoughtFromUrl,
  saveImpressionToStorageIfUnrecorded,
  isThoughtFromViewpointAuthor,
  getViewpointOrgNameFromThought,
  getViewpointIdFromThought,
} from '@src/helpers/thoughtsHelpers';
import { getUserId } from '@src/helpers/usersHelpers';
import { returnSecurityPanelUrl } from '@src/helpers/securityPanelHelpers';
import { createQueryString, parseQueryString } from '@src/helpers/routerHelpers';
import { scrollToElement } from '@src/helpers/pageHelpers';
import { LinkTo } from '../Links/LinkTo';
import { returnPathTo } from '@src/constants/paths';
import { sendFacebookTrackingEvent } from '@src/constants/facebookTracking';
import { returnCurrentUserThemeTestGroups } from '@src/helpers/currentUserHelpers';
import { moment, formatLocalizedDateTime } from '@src/helpers/timeHelpers';
import { PROFILE_CONTEXTS } from '@src/constants/contextTracking';
import { FlatButton } from '@src/main/components/buttons';

const AgreeActionsWrapper = styled.div`
  .ask-wrapper {
    padding: 0 12px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;
const VoteContainerWrap = styled.div`
  & .agree-disagree-action-container {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
  }

  & .agree-button.alternate-style,
  & .disagree-button.alternate-style {
    width: calc(50% - 5px);
  }

  @media (max-width: 500px) {
    & .agree-disagree-action-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 10px;
    }

    & .agree-button.alternate-style,
    & .disagree-button.alternate-style {
      width: 100%;
    }
  }
`;

export const ThoughtBodyWrap = styled('div')`
  & > div {
    width: 100%;
    max-width: 100%;
    .thought-bubble {
      max-width: 100%;
    }
    .community-thought-leader-banner-container {
      padding: 20px 0;
    }
    .community-thought-leader-banner {
      padding: 10px 0;
    }
  }

  .fa-location-arrow,
  .fa.fa-star {
    color: ${({ theme }) => theme.themeColors.text};
  }

  .thought-viewpoint-banner,
  .community-thought-leader-banner {
    background-color: ${({ theme }) => theme.themeColors.appBackground};
    color: ${({ theme }) => theme.themeColors.text};

    a {
      color: ${({ theme }) => theme.themeColors.text};
    }
  }

  .react-app-container & > div {
    border: 1px solid transparent;
  }

  .link-preview-container,
  .link-preview-description-box {
    border: none;
  }

  .link-preview-description-box {
    background: transparent !important;
  }

  .react-app-container & .con-text-color,
  .react-app-container & .con-text-color *,
  .react-app-container & .pro-text-color,
  .react-app-container & .pro-text-color * {
    padding: 5px;
    font-size: 14px;
  }

  div .thought-bubble {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    border-radius: 6px;
  }
`;

export class ThoughtContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _showDisagreeAddThoughtPrompt: false,

      _isImageLoading: true,
      _showOpinionTooltip: false,
      _opinionInTooltip: false,

      _isThoughtFocused: false,
    };
  }

  componentDidMount() {
    if (this.props.fetchThoughtData) {
      this._handleFetchThoughtData();
    }
    this._checkForFocusThought();
  }

  componentDidUpdate() {
    this._checkForFocusThought();
  }

  render() {
    if (this._isThoughtOrSecurityDataLoading()) {
      return this._renderLoadingThought();
    }
    return this._renderThought();
  }

  // security loading state is needs so thought component knows if security is featured
  _isThoughtOrSecurityDataLoading = () => !this._isSecurityDataAvailable() || !this._returnThought();

  _isLoading = () => this._isThoughtOrSecurityDataLoading() || this._isImageLoading();

  _renderLoadingThought = () => {
    return (
      <div className="thought-is-loading">
        <div className={'loading-thought-message main-bg-color-match'}>
          <LoadingIcon icon="fading-3balls" size="small" style={{ marginRight: '8px' }} />
        </div>
      </div>
    );
  };

  _renderThought = () => {
    return (
      <div
        key={`thought-${this._returnThoughtId()}`}
        ref={(el) => (this.ThoughtContainer = el)}
        className={`thought-container react-thought thought-${this._returnThoughtId()} ${
          this._isViewpointAuthor() ? 'viewpoint-thought' : ''
        }`}
      >
        {this.state._isThoughtFocused && <HighlightAnimation />}
        {!this.props.ignoreImpressionTracking && !this._isLoading() && (
          <ThoughtImpressionTracker
            securitySymbol={this._returnSecurity().symbol}
            securityId={this._returnSecurity().security_id}
            thoughtId={this._returnThoughtId()}
            notificationIndex={this._getNotificationIndex()}
            viewingContext={this.props.viewingContext}
            metadata={this.props.metadata}
            scrollContainerSelector={this.props.scrollContainerSelector}
            recordImpressionOnMount={this.props.recordImpressionOnMount}
          />
        )}
        {this._shouldShowThoughtHeading() && this._renderThoughtHeading()}
        {this._renderThoughtBody()}
        {this._shouldHideOpinionCounts() ? (
          <div className={'opinion-count-empty-spacer'} style={{ padding: '5px 0' }} />
        ) : (
          this._renderThoughtOpinionData()
        )}
        {this._renderAgreeDisagreeButtons()}
        {this._renderThoughtDisagreePrompt()}
      </div>
    );
  };

  _renderThoughtPostedAt = () => {
    return (
      <div
        style={{
          padding: '5px 5px 0px 5px',
          textAlign: 'right',
          fontSize: '10px',
        }}
      >
        <span className={'secondary-text-color'}>{this.returnThoughtPostedAtDisplayString()}</span>
      </div>
    );
  };

  _isCommunityLeaderThought = () => isThoughtFromCommunityThoughtLeader(this._returnThought());

  _isViewpointAuthor = () => isThoughtFromViewpointAuthor(this._returnThought());

  _renderViewpointThoughtBanner = () => {
    return (
      <div className={'community-thought-leader-banner-container'}>
        <div className={'community-thought-leader-banner'}>
          <div className={'banner-text-container'} onClick={clickStopPropagation}>
            <CenterAlignItems>
              <Icon size="small" icon="fa-location-arrow" />
              <WidthLayoutManager
                className={'thought-viewpoint-banner'}
                style={{ width: '100%' }}
                widthThreshold={361}
                greaterThanThresholdLayout={(width) => (
                  <div className={'flex-center-space-between'} style={{ width: '100%' }}>
                    <span className={'font-container'}>
                      <LinkTo
                        to={`${returnPathTo(
                          'viewpoint_profile',
                          getViewpointOrgNameFromThought(this._returnThought())
                        )}?context=${PROFILE_CONTEXTS.THOUGHT_BANNER}`}
                        className="no-text-decoration text-in-eye-catching-bg-color"
                        additionalClickAction={this._logThoughtBannerPress}
                        text={`Viewpoint - ${getViewpointOrgNameFromThought(this._returnThought())}`}
                        scrollToTop
                      />
                      <Space />
                      <Space />
                      <InfoIconTooltip definitionKey={'tooltip_viewpoint'} position={'right'} />
                    </span>
                    <div style={{ paddingRight: '10px' }}>
                      <ViewpointSubscribeButton id={getViewpointIdFromThought(this._returnThought())} />
                    </div>
                  </div>
                )}
                lessThanThresholdLayout={(width) => (
                  <div className={'flex-center-space-between'} style={{ width: '100%' }}>
                    <span className={'font-container'}>
                      <LinkTo
                        to={`${returnPathTo(
                          'viewpoint_profile',
                          getViewpointOrgNameFromThought(this._returnThought())
                        )}?context=${PROFILE_CONTEXTS.THOUGHT_BANNER}`}
                        className="no-text-decoration text-in-eye-catching-bg-color"
                        additionalClickAction={this._logThoughtBannerPress}
                        text={`Viewpoint - ${truncateName(
                          getViewpointOrgNameFromThought(this._returnThought()),
                          Math.ceil(width / 23)
                        )}`}
                        scrollToTop
                      />
                      <Space />
                      <Space />
                      <InfoIconTooltip definitionKey={'tooltip_viewpoint'} position={'right'} />
                    </span>
                    <div style={{ paddingRight: '10px' }}>
                      <ViewpointSubscribeButton id={getViewpointIdFromThought(this._returnThought())} />
                    </div>
                  </div>
                )}
              />
            </CenterAlignItems>
          </div>
        </div>
      </div>
    );
  };

  _renderCTLThoughtBanner = () => {
    return (
      <div className={'community-thought-leader-banner-container'}>
        <div className={'community-thought-leader-banner'}>
          <div className={'banner-text-container'} onClick={clickStopPropagation}>
            <CenterAlignItems>
              <Icon size="small" icon="fa-star" />
              <span className={'font-container'}>
                <LinkTo
                  to={`${returnPathTo('profile', this._returnThoughtAuthorUserId())}?context=${
                    PROFILE_CONTEXTS.THOUGHT_BANNER
                  }`}
                  className="no-text-decoration text-in-eye-catching-bg-color"
                  additionalClickAction={this._logThoughtBannerPress}
                  text={'Community Thought Leader'}
                  scrollToTop
                />
              </span>
              <InfoIconTooltip
                definitionKey={'tooltip_community_thought_leader'}
                position={'right'}
                style={{ paddingTop: '2px' }}
              />
            </CenterAlignItems>
          </div>
        </div>
      </div>
    );
  };

  _logThoughtBannerPress = () => {
    const viewingContext = this.props.viewingContext;
    const event = 'Press Thought Banner';

    const viewpointOrgName = getViewpointOrgNameFromThought(this._returnThought());
    const properties = {
      'User ID': this._returnThoughtAuthorUserId(),

      'Is Viewpoint': !!viewpointOrgName,
      'Viewpoint Org Name': viewpointOrgName,

      'Is CTL': !viewpointOrgName,
      Context: viewingContext,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _renderThoughtBanner = () => {
    if (this._isViewpointAuthor()) {
      return this._renderViewpointThoughtBanner();
    }
    if (this._isCommunityLeaderThought()) {
      return this._renderCTLThoughtBanner();
    }
    return null;
  };

  _renderTooltipOfUsersWho = (opinion) => {
    return (
      this._shouldShowSeeConnectionOpinions() &&
      opinion === this._returnOpinionInTooltip() && (
        <div className={''}>
          <SeeConnectionOpinions
            hideTooltip={this.hideTooltip}
            opinionUserIds={this._returnUserIdsWho(opinion)}
            opinionCount={this._returnCountWho(opinion)}
            autoFocus
          />
        </div>
      )
    );
  };

  _renderAgreeCount = () => {
    return (
      <div className={'agree-count'}>
        <span className="link-btn secondary-link-color" onClick={this._handleAgreeCountClick}>
          {`${this._returnAgreeCount()} Agrees`}
        </span>
        {this._renderTooltipOfUsersWho('agree')}
      </div>
    );
  };

  _renderDisagreeCount = () => {
    return (
      <div className={'disagree-count'}>
        <span className="link-btn secondary-link-color" onClick={this._handleDisagreeCountClick}>
          {`${this._returnDisagreeCount()} Disagrees`}
        </span>
        {this._renderTooltipOfUsersWho('disagree')}
      </div>
    );
  };

  _renderThoughtOpinionData = () => {
    return (
      <div className={'thought-opinion-data-container'}>
        {this._renderAgreeCount()}
        <span className="spacing-hyphen">{'-'}</span>
        {this._renderDisagreeCount()}
      </div>
    );
  };

  _renderThoughtDisagreePrompt = () => {
    return (
      <div
        className={`disagree-thought-container ${
          this._shouldShowDisagreeAddThoughtPrompt() ? 'showing' : 'not-showing'
        }`}
      >
        {this._shouldShowDisagreeAddThoughtPrompt() && (
          <CreateThoughtContainer
            security={this._returnSecurity()}
            viewingContext={'After Disagree'}
            handleCancelCreatingThought={this._handleDismissAdditionalThoughtPrompt}
            thoughtPostSuccessAdditionalAction={this._handleAfterAdditionalThoughtCreated}
            logFromDisagreeThoughtId={this._returnThoughtId()}
            customThoughtHeadingComponent={
              <div className={''}>
                <div className={''}>
                  <div style={{ display: 'inline' }}>{'Please enter a Pro/Con that describes why you disagree.'}</div>
                  <div style={{ display: 'inline-block', position: 'relative' }}>
                    <InfoIcon word={'thought_explanation'} style={{ top: '-12px', left: '3px' }} position={'right'} />
                  </div>
                </div>
                <p className={'secondary-text-color'} style={{ fontSize: '12px', margin: '7px 0 0 0' }}>
                  {"(Remember, it won't always appear near the post above, so make sure it can stand alone)."}
                </p>
              </div>
            }
          />
        )}
      </div>
    );
  };

  _renderAgreeDisagreeButtons = () => {
    const thought = this._returnThought();
    return (
      !this.props.hideOpinionActions && (
        <AgreeActionsWrapper>
          <div className={'ask-wrapper'}>
            {this._shouldShowAskAnyoneButton() && (
              <AskAnyone
                thoughtContext={this.props.viewingContext}
                notificationIndex={this._getNotificationIndex()}
                viewingContext={`Thought${this.props.afterTrade ? ' - After Trade' : ''}`}
                thoughtMetadata={this.props.metadata}
                securityId={this._returnSecurity().security_id}
                thoughtId={this._returnThoughtId()}
                buttonWidth={50}
                openFormInModal={this.props.useShareInModal}
                handleAfterAction={this.props.handleAfterAction}
                socialShareText={`Check out this ${this._returnThoughtType().toUpperCase()} for ${
                  this._returnSecurity().symbol
                } (${truncateName(
                  this._returnSecurity().name,
                  15
                )}) on ${PRODUCT_DISPLAY_NAME}. What do you think about it?`}
                thoughtSocialShareLink={this._returnShareUrl()}
                allowSocialShare
              />
            )}
            {this._shouldShowAskCTLButton() && (
              <AskAnyone
                containerClass="ask-ctl-share-button"
                customAskType={'askCTL'}
                customAskTypeButtonRenderer={'_renderAskCTLThoughtButton'}
                customAskTypeDisabledButtonRenderer={'_renderAskCTLThoughtButtonDisabled'}
                defaultAskingCTL
                thoughtContext={this.props.viewingContext}
                notificationIndex={this._getNotificationIndex()}
                viewingContext={`Thought${this.props.afterTrade ? ' - After Trade' : ''}`}
                thoughtMetadata={this.props.metadata}
                securityId={this._returnSecurity().security_id}
                thoughtId={this._returnThoughtId()}
                buttonWidth={50}
                openFormInModal={true}
                handleAfterAction={this.props.handleAfterAction}
              />
            )}
          </div>

          <VoteContainerWrap>
            <Row>
              <AgreeDisagreeContainer
                counts={{
                  agree: thought.agree_count,
                  disagree: thought.disagree_count,
                }}
                handleClick={this.handleOpinionClick}
                userOpinion={this._determineIfLiked(thought)}
                styleType={this._shouldShowAlternateOpinionActionStyle() ? 'alternate' : 'default'}
                inActivityPageFeed={this.props.inActivityPageFeed}
                inFeed={this.props.inFeed}
              />
            </Row>
          </VoteContainerWrap>
        </AgreeActionsWrapper>
      )
    );
  };

  _isImageLoading = () => this.state._isImageLoading;

  handleThoughtImageLoad = () => {
    this.setState(() => ({
      _isImageLoading: false,
    }));
  };

  _renderThoughtText = () => {
    if (!this._doesThoughtHaveText()) {
      return null;
    }
    const textColorClass = this._returnThoughtType().toLowerCase() === 'pro' ? 'pro-text-color' : 'con-text-color';
    const text = this._returnThoughtText();
    const textSplitLineBreaks = text.split('\n');
    return (
      <span className={`${textColorClass} break-text`} style={{ display: 'block' }}>
        {textSplitLineBreaks.map((text, i) => (
          <React.Fragment key={i}>
            <span style={{ display: 'inline-block' }}>{text}</span>
            <br />
          </React.Fragment>
        ))}
      </span>
    );
  };

  _renderThoughtBody = () => {
    const shouldShowThoughtBanner = this._isCommunityLeaderThought() || this._isViewpointAuthor();
    return (
      <ThoughtBodyWrap>
        <div className={`thought ${this._returnThoughtType()}  ${this._isLinkInThought() ? 'link-in-thought' : ''}`}>
          <div className="thought-text-container">
            <div
              className={`
              thought-bubble
              ${this._isLinkInThought() ? 'link-in-thought' : ''}
            `}
              onClick={this._handleThoughtClick}
              style={this._shouldRenderWithLinkToThought() ? { cursor: 'pointer' } : {}}
            >
              {this.props.customThoughtBubbleHeadingComponent}
              {shouldShowThoughtBanner && this._renderThoughtBanner()}
              {this._renderThoughtText()}
              <ThoughtLink
                style={this._doesThoughtHaveText() ? {} : { marginTop: 0 }}
                ogo={this._returnOgo()}
                onLoad={this.handleThoughtImageLoad}
                thoughtId={this._returnThoughtId()}
                thoughtType={this._returnThoughtType()}
                stockSymbol={this._returnSecurity().symbol}
                securityId={this._returnThoughtSecurityId()}
                viewingContext={this.props.viewingContext}
                notificationIndex={this._getNotificationIndex()}
                metadata={this.props.metadata}
                // openVideoInPanel={this.props.inFeed}
              />
            </div>
            {this._renderThoughtPostedAt()}
          </div>
        </div>
      </ThoughtBodyWrap>
    );
  };

  _shouldShowThoughtHeading = () => this.props.showThoughtHeading;

  _renderThoughtHeading = () => {
    const security = this._returnSecurity();

    if (this.props.useStandaloneThoughtHeading) {
      return (
        <div className={'thought-heading-standalone'}>
          <span
            className={`thought-heading-text thought-type ${
              this._returnThoughtType() === 'pro' ? 'pro-text-color' : 'con-text-color'
            }`}
            style={{ marginRight: '4px' }}
          >
            {this._returnThoughtType()}
          </span>
          <span className={'thought-heading-text'} style={{ marginRight: '4px' }}>
            {'for'}
          </span>
          <SecurityIdentifierDisplay
            securityId={security.security_id}
            symbol={security.symbol}
            name={security.name}
            activeTab={this.props.inFeed ? null : 'thoughts'}
            inFeed={this.props.inFeed}
            prefix={'('}
            suffix={')'}
            showSymbol
            showName
            limitNameWidth
            linkToPage={!this._isUserAuthenticated()}
          />
        </div>
      );
    }
    return (
      <div className={'thought-heading-standalone'}>
        <span
          className={`thought-heading-text thought-type ${
            this._returnThoughtType() === 'pro' ? 'pro-text-color' : 'con-text-color'
          }`}
          style={{ marginRight: '4px' }}
        >
          {this._returnThoughtType()}
        </span>
        <span className={'thought-heading-text'} style={{ marginRight: '4px' }}>
          {'for'}
        </span>
        <SecurityIdentifierDisplay
          securityId={security.security_id}
          symbol={security.symbol}
          name={security.name}
          prefix={'('}
          suffix={')'}
          activeTab={this.props.inFeed ? null : 'thoughts'}
          inFeed={this.props.inFeed}
          linkToPage={!this._isUserAuthenticated()}
          showSymbol
          showName
          limitNameWidth
        />
      </div>
    );
  };

  _postThoughtOpinion = (opinion) => {
    return this.props.actions
      .sendThoughtOpinion(this._returnThought(), opinion, this._returnCurrentUserId())
      .then((response) => {
        if (!response || response.error) {
          this._showPostThoughtErrorModal(opinion);
        }
        return response;
      });
  };

  _handleJoinDiscussionClick = () => {
    window.location = '/app/signup';
  };

  _handleThoughtClick = () => {
    if (this._shouldRenderWithLinkToThought()) {
      this._openSecurityPanelToThought();
      this._logClickedViewThought();
    }
  };

  _openSecurityPanelToThought = () => {
    const additionalProps = {
      activeTab: 'thoughts',
      thoughtId: this._returnThoughtId(),
    };
    const openSecurityPanelConfig = {
      securityId: this._returnThoughtSecurityId(),
      additionalProps,
      location: window.location,
    };
    const url = returnSecurityPanelUrl(openSecurityPanelConfig);
    this.props.navigate(url);
  };

  _shouldShowAskAnyoneButton = () => !this.props.hideShareLink && this._isUserAuthenticated();

  _shouldShowAskCTLButton = () => this.props.showAskCTLLink;

  _didUserDisagree = (opinion) => opinion === 'disagree';

  _returnCountWho = (opinion) => (opinion === 'agree' ? this._returnAgreeCount() : this._returnDisagreeCount());

  _returnAgreeCount = () => this._returnThought().agree_count;

  _returnDisagreeCount = () => this._returnThought().disagree_count;

  _returnUserIdsWho = (opinion) =>
    opinion === 'agree' ? this._returnUserIdsWhoAgree() : this._returnUserIdsWhoDisagree();

  _sortCurrentUserToTop = (arrayOfUserIds) => {
    const isCurrentUserIdPresent = arrayOfUserIds.some((id) => this._returnCurrentUserId() === id);
    if (isCurrentUserIdPresent) {
      return [this._returnCurrentUserId(), ...arrayOfUserIds.filter((id) => this._returnCurrentUserId() !== id)];
    } else {
      return arrayOfUserIds;
    }
  };

  _returnUserIdsWhoAgree = () => this._sortCurrentUserToTop(this._returnThought().agree_user_ids);

  _returnUserIdsWhoDisagree = () => this._sortCurrentUserToTop(this._returnThought().disagree_user_ids);

  _returnCommunityUserCountWho = (opinion) =>
    opinion === 'agree' ? this._returnCommunityUserAgreeCount() : this._returnCommunityUserDisagreeCount();

  _returnCommunityUserAgreeCount = () => this._returnAgreeCount() - this._returnUserIdsWhoAgree().length;

  _returnCommunityUserDisagreeCount = () => this._returnDisagreeCount() - this._returnUserIdsWhoDisagree().length;

  _handleUserDisagreeAction = () => {
    this._showDisagreeAddThoughtPrompt();
  };

  _handleUserAgreeAction = () => {
    this._hideDisagreeAddThoughtPrompt();
  };

  handleAfterThoughtAction = (opinion) => {
    if (this.props.handleAfterThoughtAction) {
      this.props.handleAfterThoughtAction(opinion);
    }
  };

  handleOpinionClick = (opinion) => {
    if (this._isUserAuthenticated()) {
      this._logThoughtAgreeClick(opinion);
      this.handleAfterThoughtAction(opinion);
      if (this._didUserDisagree(opinion)) {
        this._handleUserDisagreeAction();
      } else {
        this._handleUserAgreeAction();
      }
      return this._postThoughtOpinion(opinion);
    } else {
      this._logUnauthenticatedUserOpinionClick(opinion);
      this._showJoinDiscussionModal(opinion);
    }
  };

  _returnThoughtTrackingData = () => ({
    'Thought Id': this._returnThoughtId(),
    'Has Link': !!this._returnOgo(),
    'Thought Type': this._returnThoughtType(),
    'Stock Symbol': this._returnSecurity().symbol,
    'Security ID': this._returnThoughtSecurityId(),
  });

  _showOpinionUsersTooltip = (opinion) => {
    this._logViewOpinionTooltip(opinion);
    return opinion === 'agree' ? this._showAgreeUsersTooltip() : this._showDisagreeUsersTooltip();
  };

  _showTooltip = (opinion) => {
    this.setState(() => ({
      _showOpinionTooltip: true,
      _opinionInTooltip: opinion || null,
    }));
  };

  hideTooltip = () => {
    this.setState(() => ({
      _showOpinionTooltip: null,
      _opinionInTooltip: null,
    }));
  };

  _showAgreeUsersTooltip = () => this._showTooltip('agree');

  _showDisagreeUsersTooltip = () => this._showTooltip('disagree');

  _handleOpinionCountClick = (opinion) => this._showOpinionUsersTooltip(opinion);

  _handleAgreeCountClick = () => this._handleOpinionCountClick('agree');

  _handleDisagreeCountClick = () => this._handleOpinionCountClick('disagree');

  _determineIfLiked = (thought) => {
    if (thought.agree === true) {
      return 'agree';
    } else if (thought.agree === false) {
      return 'disagree';
    } else {
      return 'neutral';
    }
  };

  _returnThoughtSecurityId = () => this.props.securityId || this.props.thought.security_id; // if passing in just a thoughtId, you will also have to pass securityId so the thought data can be retrieved from store

  _returnThoughtId = () => this.props.thoughtId;

  _getNotificationIndex = () => this.props.notificationIndex;

  _returnShareUrl = () => this._returnThought().share_url || `${window.origin}/thoughts/${this._returnThoughtId()}`;

  _returnThought = () => this._returnStoreThought() || this.props.thought;

  _returnThoughtAuthorUserId = () => this._returnThought().author_id;

  _returnStoreThought = () => this._returnSecurityThoughtLookup()[this._returnThoughtId()] || null;

  _returnThoughtsStore = () => this.props.thoughts;

  _returnThoughtsSecurityLookup = () => this._returnThoughtsStore().securityLookup;

  _returnThoughtSecurityData = () => this._returnThoughtsSecurityLookup()[this._returnThoughtSecurityId()] || {};

  _returnSecurityThoughtLookup = () => this._returnThoughtSecurityData().thoughtLookup || {};

  _isSecurityDataAvailable = () => this._returnSecurity() && Object.keys(this._returnSecurity()).length > 0;

  _returnSecurity = () => this.props.security;

  _isSecurityFeatured = () => this._returnSecurity().featured;

  _isUserAuthenticated = () => this.props.isUserAuthed;

  _returnCurrentUser = () => this.props.currentUser;

  _returnCurrentUserId = () => getUserId(this._returnCurrentUser());

  _returnThoughtText = () => this._returnThought().thought;

  returnThoughtPostedAt = () => this._returnThought().posted_at_timestamp || null;

  returnThoughtPostedAtDisplayString = () =>
    this.returnThoughtPostedAt() === null
      ? null
      : formatLocalizedDateTime('ll', moment(this.returnThoughtPostedAt() * 1000));

  _doesThoughtHaveText = () => !isUndefinedOrNull(this._returnThoughtText());

  _returnOgo = () => this._returnThought().open_graph_object;

  _isLinkInThought = () => !!this._returnOgo();

  _doesThoughtHaveType = () => this._returnThought().thought_type && this._returnThought().thought_type.name;

  _returnThoughtType = () => (this._doesThoughtHaveType() ? this._returnThought().thought_type.name.toLowerCase() : '');

  _showPostThoughtErrorModal = (opinion) => {
    const component = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {`Could not save ${opinion}. Please try again later.`}
      </div>
    );
    const modal = {
      contentComponent: component,
      dismissable: true,
    };
    return this.props.actions.showModal(modal);
  };

  _showJoinDiscussionModal = () => {
    const component = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {'Sign in or sign up to join the discussion.'}

        <div className="redirect-to-sign-up-container">
          <FlatButton onClick={this._handleJoinDiscussionClick}>Continue</FlatButton>
        </div>
      </div>
    );
    const modal = {
      contentComponent: component,
      dismissable: true,
      size: 'wide',
    };
    this.props.actions.showModal(modal);
  };

  _shouldShowAlternateOpinionActionStyle = () => this.props.showAlternateOpinionActionStyle;

  _shouldHideOpinionCounts = () => this.props.hideOpinionCounts;

  _shouldRenderWithLinkToThought = () => this.props.withThoughtLink;

  _shouldShowDisagreeAddThoughtPrompt = () => this.state._showDisagreeAddThoughtPrompt;

  _shouldShowSeeConnectionOpinions = () => this.state._showOpinionTooltip;

  _returnOpinionInTooltip = () => this.state._opinionInTooltip;

  _showDisagreeAddThoughtPrompt = () => {
    this.setState(() => ({
      _showDisagreeAddThoughtPrompt: true,
    }));
  };

  _handleAdditionalPromptForThoughtAfterDisagreeAction = () => {
    if (this.props.handleAdditionalPromptForThoughtAfterDisagreeAction) {
      this.props.handleAdditionalPromptForThoughtAfterDisagreeAction();
    }
  };

  _handleAfterAdditionalThoughtCreated = () => {
    this._hideDisagreeAddThoughtPrompt();
    this._handleAdditionalPromptForThoughtAfterDisagreeAction();
  };

  _handleDismissAdditionalThoughtPrompt = () => {
    this._hideDisagreeAddThoughtPrompt();
    this._handleAdditionalPromptForThoughtAfterDisagreeAction();
  };

  _hideDisagreeAddThoughtPrompt = () => {
    this.setState(() => ({
      _showDisagreeAddThoughtPrompt: false,
    }));
  };

  _handleFetchThoughtData = () => {
    this._fetchThoughtsDataForSecurity();
  };

  _fetchSecurityData = () => this.props.actions.quickFetchSecuritiesData([this._returnThoughtSecurityId()]);

  _fetchThoughtsDataForSecurity = () => this.props.actions.fetchThoughts([this._returnThoughtSecurityId()]);

  _checkForFocusThought = () => {
    if (this.props.thoughtDisplayContext !== 'thoughtList') {
      // can only use in thoughts list for now to prevent issues with the same thought being on the screen twice
      return false;
    }
    const focusThoughtId = getFocusThoughtFromUrl(this.props.location);
    if (focusThoughtId === this._returnThoughtId() && !this._isLoading() && !this._didFocusThoughtTrigger) {
      this._didFocusThoughtTrigger = true;
      this._removeFocusThoughtIdFromUrl();
      this._runFocusAnimation();
    }
  };

  _setIsFocused = () => {
    this.setState(() => ({ _isThoughtFocused: true }));
  };

  _runFocusAnimation = () => {
    this._setIsFocused();
    setTimeout(this._scrollToThisThought, 100);
  };

  _scrollToThisThought = () => {
    scrollToElement(
      window.$(this.ThoughtContainer),
      window.$(
        this.props.scrollContainerSelector !== window ? this.props.scrollContainerSelector : window.$('html, body')
      ),
      600,
      75
    );
  };

  _removeFocusThoughtIdFromUrl = () => {
    const queryString = this.props.location.search;
    const query = parseQueryString(queryString);
    if (query.focus_thought_id) {
      delete query.focus_thought_id;
    }
    const filteredQueryString = createQueryString(query);
    this.props.navigate(this.props.location.pathname + filteredQueryString);
  };

  _returnViewingContext = () => {
    return this.props.viewingContext ? { Context: this.props.viewingContext } : {};
  };

  _logViewConnectionsOpinions = () => {
    const event = 'Clicked Thought To View Agreeing Connections';
    this.props.actions.logMetricsTrackingEvent(event);
  };

  _logUnauthenticatedUserOpinionClick = (opinion) => {
    const event = 'Unauthenticated User Clicked ' + opinion;
    const properties = {
      ...this._returnThoughtTrackingData(),
      Opinion: toCapitalCase(opinion),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logViewOpinionTooltip = (opinion) => {
    const event = 'View Users With Thought Opinion';
    const properties = {
      ...this._returnThoughtTrackingData(),
      Opinion: toCapitalCase(opinion),
      'Suggested Thought': this.props.isSuggestedThought,
      ...this._returnViewingContext(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logClickedViewThought = () => {
    const viewingContext = this.props.viewingContext;
    const notificationIndex = this._getNotificationIndex();
    const event = 'Clicked View Thought';
    const properties = {
      ...this._returnThoughtTrackingData(),
      Context: viewingContext,
    };
    sendFacebookTrackingEvent('Clicked Thought', {
      themeTestGroups: returnCurrentUserThemeTestGroups(this.props.currentUser),
    });
    this.props.actions.logMetricsTrackingEvent(event, properties);
    saveImpressionToStorageIfUnrecorded('text', this._returnThoughtId(), viewingContext);
    this.props.actions.sendThoughtClickEvent(
      this._returnThoughtId(),
      'text',
      viewingContext,
      this.props.metadata,
      notificationIndex
    );
  };

  _logThoughtAgreeClick = (opinion) => {
    const viewingContext = this.props.viewingContext;
    const notificationIndex = this._getNotificationIndex();
    const security = this._returnSecurity();
    const event = 'Clicked Thought Agree';
    const properties = {
      ...this._returnThoughtTrackingData(),
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
      Agree: opinion === 'agree',
      'Suggested Thought': this.props.isSuggestedThought,
      ...this._returnViewingContext(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
    saveImpressionToStorageIfUnrecorded(opinion, this._returnThoughtId(), viewingContext);
    this.props.actions.sendThoughtClickEvent(
      this._returnThoughtId(),
      opinion,
      viewingContext,
      this.props.metadata,
      notificationIndex
    );
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    thoughts: state.thoughts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ThoughtContainer);

export default composedComponent;
