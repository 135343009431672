import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../../actions/index';

import PageLoading from '../../../components/PageLoading';

import CompTableHeading from './table/CompTableHeading';
import CompTableBody from './table/CompTableBody';
import CompTableFooter from './table/CompTableFooter';
import SearchContainer from '../../UI/Search/SearchContainer';

import StockIdentifier from './table/cells/StockIdentifier';
import FormattedDataCell from './table/cells/FormattedDataCell';
import RemoveCell from './table/cells/RemoveCell';

import { returnBasicSomethingWentWrongErrorModalPayload } from '../../../constants/modals';
import { withRouter } from '../../../main/utils';
import { activeSecuritiesTracker, LivePricePollingManager } from '../../../main/classes/LivePricePollingManager';
import styled from 'styled-components';

const Wrapper = styled.div`
  //
`;
const SearchWrapper = styled.div`
  margin: 16px;
  max-width: 300px;
`;
const CompTableWrapper = styled.div`
  background: ${({ theme }) => theme.themeColors.componentNoOpacity};
  box-shadow: none !important;
  margin: 16px;

  //margin: 15px auto 25px auto;
  //max-width: 95%;
  border-radius: 7px;
  overflow: auto;
`;

class CompTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this._trackedSecuritySymbols = [];

    this.state = {
      initializing: true,
      tableSecurityIds: [props.securityId],
      userAddedSecurityIds: [],
    };
    this.securityInDetail = { id: props.securityId };
    this.columnGroups = [
      {
        heading: null,
        name: 'basic-stock-data',
        columns: [
          {
            name: 'removeCell',
            displayName: '',
            cell: RemoveCell,
            ignoreForAggregation: true,
          },
          {
            name: 'identifier',
            displayName: '',
            cell: StockIdentifier,
            ignoreForAggregation: true,
          },
          {
            name: 'price',
            displayName: 'Price',
            dataKey: 'current_price',
            cell: FormattedDataCell,
            ignoreForAggregation: true,
          },
          {
            name: 'day_change',
            dataKey: 'current_price_change_percent',
            displayName: 'Day Change',
            cell: FormattedDataCell,
            ignoreForAggregation: true,
          },
        ],
      },
      {
        heading: null,
        name: 'pe',
        columns: [
          {
            name: 'pe',
            displayName: 'P/E',
            dataKey: 'Price / Earnings',
            cell: FormattedDataCell,
          },
          {
            name: 'forward_pe',
            displayName: `Forward P/E (${new Date().getFullYear()})`,
            dataKey: 'Forward P/E Curr Yr',
            cell: FormattedDataCell,
          },
          {
            name: 'forward_pe_next_yr',
            displayName: `Forward P/E (${new Date().getFullYear() + 1})`,
            dataKey: 'Forward P/E',
            cell: FormattedDataCell,
          },
          {
            name: 'forward_eps',
            displayName: `Forward EPS (${new Date().getFullYear()})`,
            dataKey: 'Forward EPS Curr Yr',
            cell: FormattedDataCell,
          },
          {
            name: 'forward_eps_next_yr',
            displayName: `Forward EPS (${new Date().getFullYear() + 1})`,
            dataKey: 'Forward EPS',
            cell: FormattedDataCell,
          },
        ],
      },
      {
        heading: null,
        name: 'capital_structure',
        columns: [
          {
            name: 'market_cap',
            displayName: 'Market Cap',
            dataKey: 'Market Cap',
            cell: FormattedDataCell,
          },
          {
            name: 'net_debt',
            displayName: 'Net Debt',
            dataKey: 'Net Debt',
            cell: FormattedDataCell,
          },
          {
            name: 'dividend_yield',
            displayName: 'Dividend Yield',
            dataKey: 'Dividend Yield',
            cell: FormattedDataCell,
          },
        ],
      },
      {
        heading: null,
        name: 'growth',
        columns: [
          {
            name: 'projected_revenue_growth',
            displayName: 'Projected Revenue Growth',
            dataKey: 'Projected Revenue Growth',
            cell: FormattedDataCell,
          },
          {
            name: 'revenue_ltm',
            displayName: 'Revenue LTM',
            dataKey: 'Revenue LTM',
            cell: FormattedDataCell,
          },
          {
            name: 'net_income_ltm',
            displayName: 'Net Income LTM',
            dataKey: 'Net Income LTM',
            cell: FormattedDataCell,
          },
        ],
      },
      {
        heading: null,
        name: 'misc',
        columns: [
          {
            name: 'price_to_tangible_book',
            displayName: 'Price / Tangible Book',
            dataKey: 'Price / Tangible Book',
            cell: FormattedDataCell,
          },
          {
            name: 'price_to_sales',
            displayName: 'Price / Sales',
            dataKey: 'Price / Sales',
            cell: FormattedDataCell,
          },
          {
            name: 'forward_price_to_sales',
            displayName: `Forward P/S (${new Date().getFullYear()})`,
            dataKey: 'Forward P/S Curr Yr',
            cell: FormattedDataCell,
          },
          {
            name: 'forward_price_to_sales_next_yr',
            displayName: `Forward P/S (${new Date().getFullYear() + 1})`,
            dataKey: 'Forward P/S',
            cell: FormattedDataCell,
          },
          {
            name: 'total_enterprise_value',
            displayName: 'Total Enterprise Value',
            dataKey: 'Total Enterprise Value',
            cell: FormattedDataCell,
          },
          {
            name: 'net_debt_over_ebitda',
            displayName: 'Net Debt / EBITDA',
            dataKey: 'Net Debt / EBITDA',
            cell: FormattedDataCell,
          },
          {
            name: 'forward_net_debt_over_ebitda',
            displayName: `Forward Net Debt / EBITDA (${new Date().getFullYear()})`,
            dataKey: 'Forward Net Debt / EBITDA Curr Yr',
            cell: FormattedDataCell,
          },
          {
            name: 'total_debt_over_ebitda',
            displayName: 'Total Debt / EBITDA',
            dataKey: 'Total Debt / EBITDA',
            cell: FormattedDataCell,
          },
          {
            name: 'forward_total_debt_over_ebitda',
            displayName: `Forward Total Debt / EBITDA (${new Date().getFullYear()})`,
            dataKey: 'Forward Total Debt / EBITDA Curr Yr',
            cell: FormattedDataCell,
          },
          {
            name: 'ev_over_ebitda',
            displayName: 'EV / EBITDA',
            dataKey: 'EV / EBITDA',
            cell: FormattedDataCell,
          },
          {
            name: 'forward_ev_over_ebitda',
            displayName: `Forward EV / EBITDA (${new Date().getFullYear()})`,
            dataKey: 'Forward EV / EBITDA Curr Yr',
            cell: FormattedDataCell,
          },
          {
            name: 'beta',
            displayName: 'Beta',
            dataKey: 'Beta',
            cell: FormattedDataCell,
          },
        ],
      },
    ];
  }

  componentDidMount() {
    this._fetchSecuritiesData([this.props.securityId]);
    this.getSimilarSecurities();
  }

  componentWillUnmount() {
    activeSecuritiesTracker.removeTempSecurities(this._trackedSecuritySymbols);
  }

  getDisplaySecurityIds = () => [...this.state.tableSecurityIds, ...this.state.userAddedSecurityIds];

  render() {
    if (this.state.initializing) {
      return <PageLoading />;
    }
    const { columnGroups } = this;
    const securityIdsInCompTable = this.getDisplaySecurityIds();
    return (
      <Wrapper>
        <SearchWrapper>
          <SearchContainer
            id="comp_table_add_stock"
            customIcon="fa-plus"
            parentComponent="Comp Table"
            placeholder="Add Stocks"
            iconTopPos={'10px'}
            handleSecurityQuerySelection={this.handleSecurityQuerySelection}
          />
        </SearchWrapper>
        <CompTableWrapper className="comp-table-container always-show-scroll">
          <table className="comp-table">
            <CompTableHeading columnGroups={columnGroups} />
            <CompTableBody
              columnGroups={columnGroups}
              securityIds={securityIdsInCompTable}
              securityInDetail={this.securityInDetail}
              handleRemoveSecurity={this.handleRemoveSecurity}
              navigate={this.props.navigate}
              location={this.props.location}
            />
            <CompTableFooter columnGroups={columnGroups} securityIds={securityIdsInCompTable} />
          </table>
        </CompTableWrapper>
      </Wrapper>
    );
  }

  handleSecurityQuerySelection = async (security) => {
    if (!security) {
      return console.error('No security was received', security);
    }
    const { id } = security;
    if (this.getDisplaySecurityIds().includes(id)) {
      $('input').blur();
      return;
    }
    this.logAddSecurity(id);

    this.setState((prevState) => ({
      userAddedSecurityIds: [...prevState.userAddedSecurityIds, id],
    }));

    const responses = await this._fetchSecuritiesData([id]);
    const securityDataResponse = responses[0];
    if (securityDataResponse?.securities) {
      const security = securityDataResponse?.securities[0];
      const { security_id: id, symbol } = security;
      this._trackedSecuritySymbols.push(symbol);
      activeSecuritiesTracker.addTempSecurities([
        {
          symbol,
          id,
        },
      ]);
    }

    window.$('input').blur();
  };

  handleRemoveSecurity = (removeSecurityId) => {
    this.logRemoveSecurity(removeSecurityId);
    this.setState((prevState) => ({
      tableSecurityIds: prevState.tableSecurityIds.filter((id) => id !== removeSecurityId),
      userAddedSecurityIds: prevState.userAddedSecurityIds.filter((id) => id !== removeSecurityId),
    }));
  };

  logAddSecurity = (securityId) => {
    const event = 'Added Security To Comp Table';
    const properties = {
      'Security ID': securityId,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  logRemoveSecurity = (securityId) => {
    const event = 'Removed Security To Comp Table';
    const properties = {
      'Security ID': securityId,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _fetchSecuritiesData = async (securityIds) => {
    return Promise.all([
      this.props.actions.quickFetchSecuritiesData(securityIds),
      this.props.actions.fetchSecuritiesFundamentalsData(securityIds),
      this.props.actions.fetchSecuritiesPriceData(securityIds),
    ]);
  };

  getSimilarSecurities = () => {
    const securityId = this.props.securityId;
    this.props.actions.fetchSimilarCompanies(securityId).then((response) => {
      if (response && response.similar_securities) {
        const { similar_securities: similarSecurities } = response;

        const addToLivePriceSecuritiesData = similarSecurities.map((securityData) => ({
          symbol: securityData.symbol,
          id: securityData.id,
        }));
        activeSecuritiesTracker.addTempSecurities(addToLivePriceSecuritiesData);
        addToLivePriceSecuritiesData.forEach((s) => this._trackedSecuritySymbols.push(s.symbol));

        const comparableSecurityIds = similarSecurities.map((securityData) => securityData.id);
        const allSecurityIds = [securityId, ...comparableSecurityIds];

        this._fetchSecuritiesData(allSecurityIds);

        this.setState((prevState) => ({
          initializing: false,
          tableSecurityIds: allSecurityIds,
        }));
      } else {
        this.props.actions.showModal(returnBasicSomethingWentWrongErrorModalPayload());
      }
    });
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const connectedComponent = connect(null, mapDispatchToProps)(CompTable);

export default withRouter(connectedComponent);
