import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import {
  fetchCurrentUserQuickInfo,
  fetchFurtherDocumentationStatus,
  getLiveTradingAccount,
  logMetricsTrackingEvent,
  showModal,
} from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import { H5, Body5 } from '../../lib/nvstr-common-ui.es';

import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { SOCURE_DECISION_STATUS } from '../../../constants/types/Socure';
import { ONBOARDING_PATHS, ONBOARDING_STATES } from '../../constants/onboarding';
import { useIsOnboarding } from '../../hooks/user';
import { ROUTES } from '../../../constants/paths';
import { useNavigate } from 'react-router-dom';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
  min-height: 400px;
`;

const Heading = styled.div`
  margin: 0 auto;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 24px;
`;
const Actions = styled.div`
  padding-top: 8px;
  text-align: center;
`;

const UnableToOpenBrokerageAccountMessageModal = () => (
  <div className="modal-message" style={{ paddingTop: '0px', textAlign: 'center' }}>
    <Body5>{'We are unable to open a brokerage account for you with the information you provided.'}</Body5>
    <Message>
      <Body5>{`You may continue using ${PRODUCT_DISPLAY_NAME} with a simulated account.`}</Body5>
    </Message>
  </div>
);

export const SubmittedLiveAccountApplication = ({ onContinue, onPathChange, onboardingState }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isReady, setIsReady] = React.useState(false);
  const isOnboarding = useIsOnboarding();

  const handleContinueClick = React.useCallback(async () => {
    onContinue(null, onboardingState);
  }, []);

  const validateOnboardingCurrentUserState = React.useCallback(async () => {
    const currentUser = await fetchCurrentUserQuickInfo()(dispatch);
    const { ok: liveTradingAccountResponseOk, liveTradingAccount: liveAccount } = await getLiveTradingAccount()(
      dispatch
    );

    const currentUserResponseOk = currentUser?.id > 0;
    const socureResponse = await fetchFurtherDocumentationStatus();

    const { decisionStatus } = socureResponse;

    const wasRejected = decisionStatus === SOCURE_DECISION_STATUS.REJECTED || liveAccount?.status === 'REJECTED';
    if (wasRejected) {
      showModal({
        contentComponent: <UnableToOpenBrokerageAccountMessageModal />,
        dismissable: true,
        size: 'wide',
      })(dispatch);
      onPathChange(ONBOARDING_PATHS.paper, onboardingState, { nextState: ONBOARDING_STATES.selectAccountPrivacy });
      return;
    }

    const needsMoreInfo = [
      SOCURE_DECISION_STATUS.ADDRESS_RESUBMIT,
      SOCURE_DECISION_STATUS.FURTHER_DOCUMENTS_REQUIRED,
    ].includes(decisionStatus);
    if (needsMoreInfo) {
      onPathChange(ONBOARDING_PATHS.socure, onboardingState, { nextState: ONBOARDING_STATES.socureApplication });
      return;
    }

    onPathChange(ONBOARDING_PATHS.postLiveAccountApplication, onboardingState, {
      nextState: ONBOARDING_STATES.redeemRewards,
    });
  }, []);

  const validateNonOnboardingCurrentUserState = React.useCallback(async () => {
    const currentUser = await fetchCurrentUserQuickInfo()(dispatch);
    const { ok: liveTradingAccountResponseOk, liveTradingAccount: liveAccount } = await getLiveTradingAccount()(
      dispatch
    );

    const currentUserResponseOk = currentUser?.id > 0;
    const socureResponse = await fetchFurtherDocumentationStatus();

    const { decisionStatus } = socureResponse;

    const wasRejected = decisionStatus === SOCURE_DECISION_STATUS.REJECTED || liveAccount?.status === 'REJECTED';
    if (wasRejected) {
      showModal({
        contentComponent: <UnableToOpenBrokerageAccountMessageModal />,
        dismissable: true,
        size: 'wide',
      })(dispatch);
      navigate(ROUTES.DASHBOARD.build());
      return;
    }

    const needsMoreInfo = [
      SOCURE_DECISION_STATUS.ADDRESS_RESUBMIT,
      SOCURE_DECISION_STATUS.FURTHER_DOCUMENTS_REQUIRED,
    ].includes(decisionStatus);
    if (needsMoreInfo) {
      onPathChange(ONBOARDING_PATHS.socure, onboardingState, { nextState: ONBOARDING_STATES.socureApplication });
      return;
    }

    onPathChange(ONBOARDING_PATHS.postLiveAccountApplication, onboardingState, {
      nextState: ONBOARDING_STATES.redeemRewards,
    });
  }, []);

  React.useEffect(() => {
    logMetricsTrackingEvent('View Live Account Application Submitted Form')();

    isOnboarding ? validateOnboardingCurrentUserState() : validateNonOnboardingCurrentUserState();
  }, []);

  if (!isReady) return <LoadingPage />;

  return (
    <Page width={'600px'}>
      <ContentWrapper>
        <Heading>
          <H5>Live Account Application Submitted</H5>
        </Heading>
      </ContentWrapper>
      <Actions>
        <Container fullWidth centerAll>
          <FlatButton fullWidth onClick={handleContinueClick}>
            Continue
          </FlatButton>
        </Container>
      </Actions>
    </Page>
  );
};
