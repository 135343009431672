import { isUndefinedOrNull } from '@src/helpers/usefulFuncs';
import { REGEX_DICT } from '@src/main/constants/regex';
import { throwError } from '@src/helpers/devToolHelpers';
import { getDecimalAccuracy } from '@src/main/utils/numbers';

export const VALIDATION_PROPERTIES = {
  numberInput: 'numberInput',
  onlyIntegers: 'onlyIntegers',
  allowNegatives: 'allowNegatives',
  maxLength: 'maxLength',
  lockToHundredths: 'lockToHundredths',
  strictDecimals: 'strictDecimals',
  maxDecimalAccuracy: 'maxDecimalAccuracy',
  identificationNumber: 'identificationNumber',
  californiaRules: 'californiaRules',
};

export const INPUT_FORMAT_PROPERTIES = {
  float: 'float',
  priceEntry: 'priceEntry',
};

export const inputFormatter = (currentValue, value, formatProperties = {}) => {
  const { float, priceEntry } = formatProperties;

  let formattedValue = value.toString();

  if (formattedValue[0] === '.' && float) {
    formattedValue = '0' + formattedValue;
  }

  if (priceEntry) {
    if (
      formattedValue === '' ||
      formattedValue === '.' ||
      formattedValue === '0.' ||
      formattedValue === '0.0' ||
      formattedValue === '0.00' ||
      formattedValue === '0.000' ||
      formattedValue.slice(formattedValue.length - 1, formattedValue.length) === '.' ||
      formattedValue.slice(formattedValue.length - 2, formattedValue.length) === '.0'
    )
      return formattedValue;

    const formattedValueFloat = parseFloat(formattedValue);
    const decimalPlaces = getDecimalAccuracy(formattedValueFloat);

    if (formattedValueFloat > 1) {
      return decimalPlaces >= 2 ? formattedValueFloat.toFixed(2) : formattedValueFloat.toFixed(decimalPlaces);
    } else {
      if (decimalPlaces > 4) {
        return formattedValueFloat.toFixed(4);
      } else {
        return formattedValueFloat.toString();
      }
    }
  }

  return formattedValue;
};

export const validateInput = (currentValue, value, validationProperties = {}) => {
  const {
    numberInput,
    onlyIntegers,
    allowNegatives,
    maxLength,
    lockToHundredths,
    strictDecimals,
    maxDecimalAccuracy,
    identificationNumber,
    californiaRules,
  } = validationProperties;

  let isValid = true;
  let formattedValue = value.toString();

  const isDeleting = currentValue.length > formattedValue.length;

  if (formattedValue[0] === '.') {
    formattedValue = '0' + formattedValue;
  }

  const isValueEmpty = formattedValue === '';

  if (maxLength < value.length) {
    return false;
  }

  if (identificationNumber && !isValueEmpty) {
    const isIdentificationNumberCurrentlyFiltered = formattedValue.indexOf('*') >= 0;
    if (isDeleting && isIdentificationNumberCurrentlyFiltered) {
      formattedValue = '';
    } else {
      isValid = REGEX_DICT.IDENTIFICATION_NUMBER.test(formattedValue);
    }
  }

  if (californiaRules && !isValueEmpty) {
    isValid = REGEX_DICT.CALIFORNIA_RULES.test(formattedValue);
  }

  if (numberInput && !isValueEmpty) {
    const numRegex = onlyIntegers
      ? allowNegatives
        ? REGEX_DICT.ONLY_INTEGERS
        : REGEX_DICT.ONLY_POSITIVE_INTEGERS
      : allowNegatives
      ? REGEX_DICT.VALID_NUMBER
      : REGEX_DICT.VALID_POSITIVE_NUMBER;
    isValid = numRegex.test(formattedValue);

    if (lockToHundredths && isValid) {
      const decimalIndex = formattedValue.indexOf('.');
      if (decimalIndex >= 0) {
        isValid = formattedValue.length - decimalIndex < 4;
      }
    }

    if (!isUndefinedOrNull(maxDecimalAccuracy) && formattedValue.includes('.')) {
      const decimalPlaces = formattedValue.split('.')[1].length || 0;
      isValid = decimalPlaces <= maxDecimalAccuracy;
    }

    if (strictDecimals && isValid) {
      const decimalIndex = formattedValue.indexOf('.');
      if (decimalIndex >= 0) {
        const num = parseFloat(formattedValue);
        if (num < 0.01) {
          isValid = formattedValue.length - decimalIndex < 6;
        } else {
          isValid = formattedValue.length - decimalIndex < 4;
        }
      }
    }
  }

  return isValid;
};
