import React, { Component } from 'react';
import { enums } from '@src/main/lib/nvstr-utils.es';

import LoadingIcon from '../misc/LoadingIcon';
import { useButtonEvent } from '../../utils/itly/hooks/useButtonEvent';
import { withRouter } from '../../main/utils';

class BaseButton extends Component {
  componentDidMount() {
    if (this.props.preventDefaultNativeClick) {
      const node = this.buttonNode;
      const $button = $(node);
      $button.on('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        this._internalClickHandler();
      });
    }
  }

  componentWillUnmount() {
    if (this.props.preventDefaultNativeClick) {
      const node = this.buttonNode;
      const $button = window.$(node);
      $button.off('click', $button, this._internalClickHandler);
    }
  }

  render() {
    const containerClass = this.props.className || '';
    if (!containerClass) {
      return this._renderButton();
    }
    return <div className={containerClass}>{this._renderButton()}</div>;
  }

  _internalClickHandler = (e) => this.props.handleClick(e);

  _renderButton = () => {
    const btnClass = this._returnBtnClass();
    const btnStyle = this._returnBtnStyle();
    const conditionalProps = this._returnConditionalProps();
    const text = this._returnText();
    const prefixIcon = this._returnPrefixIcon();
    const suffixIcon = this._returnSuffixIcon();
    return (
      <button
        className={`${btnClass}`}
        style={btnStyle}
        onClick={this._handleClick}
        {...conditionalProps}
        ref={(el) => (this.buttonNode = el)}
      >
        {prefixIcon}
        {text}
        {suffixIcon}
      </button>
    );
  };

  _handleClick = (e) => {
    const { location } = this.props;
    if (
      'handleClick' in this.props &&
      this.props.handleClick &&
      !this.props.disabled &&
      !this.props.preventDefaultNativeClick
    ) {
      // this doesn't work since react doesn't use native events
      if (this.props.stopPropagation) {
        e.stopPropagation();
      }
      if (this.props.preventDefault) {
        e.preventDefault();
      }
      this.props.handleClick();
    }
  };

  _returnText = () => {
    return this.props.text === 'Loading' ? (
      <LoadingIcon icon="fading-3balls" size={this.props.LoadingIconSize || 'small'} style={{ marginRight: '8px' }} />
    ) : (
      this.props.text
    );
  };

  _returnPrefixIcon = () => {
    const { customIcon, prefixIcon } = this.props;
    if (customIcon) {
      return customIcon;
    }
    if (prefixIcon) {
      return (
        <span className="inline-button-icon">
          <i className={`fa ${this.props.prefixIcon}`} />
        </span>
      );
    }
    return null;
  };

  _returnSuffixIcon = () => {
    return (
      this.props.suffixIcon && (
        <span className="inline-button-icon">
          <i className={`fa ${this.props.suffixIcon}`} />
        </span>
      )
    );
  };

  _returnBtnClass = () =>
    this.props.disabled ? this.props.customClass + ' btn-grey-disabled' : this.props.customClass || '';

  _returnBtnStyle = () => this.props.style || {};

  _returnConditionalProps = () => (this.props.id ? { id: this.props.id } : {});
}

const Button = (props) => {
  const onClick = useButtonEvent(props);
  return <BaseButton {...props} handleClick={onClick} />;
};

Button.defaultProps = {
  buttonType: enums.button_types.button,
};

export default withRouter(Button);
