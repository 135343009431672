import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

class PageWrapper extends React.Component {
  componentDidMount() {
    this._setPageTitle();
    this._getInitialData();
    this._logPageView();
  }

  render() {
    return <div className={`page-wrapper-container ${this.props.className || ''}`}>{this.props.children}</div>;
  }

  _getInitialData = () => {
    const requests = this.props.requests;
    if (requests && requests.length > 0) {
      requests.forEach((actionName) => {
        this.props.actions[actionName]();
      });
    }
  };

  _setPageTitle = () => {
    const title = this.props.pageTitle;
    if (!title) {
      return false;
    }
    document.title = title;
  };

  _logPageView = () => {
    const event = this.props.eventName;
    if (!event) {
      return false;
    }
    const properties = this.props.eventProperties || {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(PageWrapper);
