import React from 'react';

import CurrentUserDataWrapper from '../users/CurrentUserDataWrapper';
import UserContainer from '../users/UserContainer';
import ProfileAvatar from '../../components/user/ProfileAvatar';
import ImageUpload from '../Inputs/ImageUpload';
import ImageDelete from '../Inputs/ImageDelete';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100px;

  .current-user-data-wrapper {
    text-align: center;
  }
  .current-user-data-wrapper .user-container {
    display: inline-block;
  }

  .upload-avatar-actions-container {
    //position: absolute;
    //top: 0;
    //left: 0;
    width: 100%;
    z-index: 2;
  }
  .upload-avatar-actions-container .image-upload-button-style-wrapper:hover,
  .upload-avatar-actions-container .image-delete-button:hover {
    opacity: 1;
  }
  .upload-avatar-actions-container .image-upload-button-style-wrapper {
    position: relative;
    height: 26px;
    width: 26px;
    border-radius: 2px;
    opacity: 0.8;
    cursor: pointer;
  }
  .upload-avatar-actions-container .image-upload-button-style-wrapper * {
    cursor: pointer !important;
  }
  .upload-avatar-actions-container .image-upload-button-style-wrapper i {
    position: absolute;
    top: 4.5px;
    left: 5px;
    font-size: 16px !important;
    pointer-events: none;
  }
  .upload-avatar-actions-container .image-upload-button-style-wrapper label {
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    height: 26px;
    width: 26px;
    margin: 0;
    z-index: 2;
  }
  .upload-avatar-actions-container .image-upload-button-style-wrapper input {
    display: none;
  }
  .upload-avatar-actions-container .image-delete-button {
    position: relative;
    height: 26px;
    width: 26px;
    border-radius: 2px;
    opacity: 0.8;
  }
  .upload-avatar-actions-container .image-delete-button i {
    position: absolute;
    top: 4.5px;
    left: 6.5px;
    font-size: 16px !important;
    pointer-events: none;
  }
`;

const UploadAvatar = (props) => {
  return (
    <Wrapper className={`upload-avatar-container`}>
      <CurrentUserDataWrapper>
        <UserContainer>
          <ProfileAvatar size={'profile'} withLink={false} />
        </UserContainer>
      </CurrentUserDataWrapper>
      <div className={`upload-avatar-actions-container`}>
        <ImageUpload />
        <CurrentUserDataWrapper renderIf={'hasAvatar'}>
          <ImageDelete />
        </CurrentUserDataWrapper>
      </div>
    </Wrapper>
  );
};

export default UploadAvatar;
