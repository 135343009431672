import React from 'react';

const ChevronUp = ({ color }) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7,15L12,10L17,15H7Z" fill={color} />
    </svg>
  );
};

export default ChevronUp;
