import { ideaExplorerAdapter } from '../adapters/ideaExplorerAdapter';
import { securitiesAdapter } from '../adapters/securitiesAdapter';

import { removeValuedIdeas } from '../helpers/ideaExplorerHelpers';

import { addInterestSentiment } from '../helpers/securitiesHelpers';

import {
  IMPORT_EXISTING_IDEAS,
  INITIALIZE_SUGGESTIONS,
  ADD_IDEA,
  MAKE_SUGGESTION,
  ADD_SUGGESTIONS,
  UPDATE_INTEREST_AND_SENTIMENT,
  DISMISS_IDEA,
  DROP_IDEA,
  RESET_IDEAS,
  INCREMENT_REQUEST_COUNT,
  START_GENERATING_SUGGESTIONS,
  END_GENERATING_SUGGESTIONS,
  REFRESH_WELL_BASED_ON_INTEREST,
  ADD_SECURITIES,
  SHOW_MODAL,
} from '../constants';
import { getSecurityIdFromIdea } from '../helpers/ideaHelpers';

export const importIdeas = (suggestionRequestId) => {
  return function (dispatch) {
    ideaExplorerAdapter.importIdeas().then((response) => {
      let valuedIdeas = {};

      if ('ideas' in response) {
        const ideas = response.ideas;
        const buyIdeas = ideas.filter((idea) => idea.idea_type === 'Buy');
        const sellIdeas = ideas.filter((idea) => idea.idea_type === 'Sell');

        dispatch({
          type: ADD_SECURITIES,
          payload: [...ideas],
        });
        dispatch({
          type: IMPORT_EXISTING_IDEAS,
          payload: [...ideas],
        });
        valuedIdeas = {
          known: [...addInterestSentiment(1, buyIdeas), ...addInterestSentiment(-1, sellIdeas)],
        };
      } else {
        valuedIdeas = { known: [] };
      }
    });
  };
};

export const initializeIdeas = (count) => {
  return function (dispatch) {
    dispatch({
      type: START_GENERATING_SUGGESTIONS,
    });

    ideaExplorerAdapter.initializeIdeas(count).then((response) => {
      dispatch({
        type: INITIALIZE_SUGGESTIONS,
        payload: { suggestions: response.interest_suggestions, count },
      });
      dispatch({
        type: END_GENERATING_SUGGESTIONS,
      });
    });
  };
};

export const refreshWell = () => {
  return function (dispatch) {
    dispatch({
      type: REFRESH_WELL_BASED_ON_INTEREST,
    });
  };
};

export const updateSuggestions = (suggestionRequestId, countSuggestionsNeeded, valuedIdeas) => {
  return function (dispatch) {
    dispatch({
      type: INCREMENT_REQUEST_COUNT,
    });
    dispatch({
      type: START_GENERATING_SUGGESTIONS,
    });

    ideaExplorerAdapter.updateSuggestions(countSuggestionsNeeded, valuedIdeas).then((response) => {
      dispatch({
        type: ADD_SUGGESTIONS,
        payload: {
          id: suggestionRequestId,
          ideas: response.interest_suggestions,
        },
      });
      dispatch({
        type: UPDATE_INTEREST_AND_SENTIMENT,
        payload: response.interest_suggestions,
      });
      dispatch({
        type: END_GENERATING_SUGGESTIONS,
      });
      dispatch({
        type: REFRESH_WELL_BASED_ON_INTEREST,
      });
    });
  };
};

export const makeSuggestion = (count) => {
  return function (dispatch) {
    dispatch({
      type: MAKE_SUGGESTION,
      payload: count,
    });
  };
};

export const addIdea = (idea) => {
  return function (dispatch) {
    securitiesAdapter.quickFetchSecuritiesData([getSecurityIdFromIdea(idea)]).then((response) => {
      if (response && response.securities) {
        dispatch({
          type: ADD_IDEA,
          payload: response.securities[0],
        });
      }
    });
  };
};

export const dropIdea = (idea, pile) => {
  return function (dispatch) {
    dispatch({
      type: DROP_IDEA,
      payload: { idea, pile },
    });
  };
};

export const dismissIdea = (idea) => {
  return function (dispatch) {
    dispatch({
      type: DISMISS_IDEA,
      payload: idea,
    });
  };
};

export const resetIdeas = () => {
  return function (dispatch) {
    dispatch({
      type: RESET_IDEAS,
    });
  };
};

export const completeExploration = (ideas, ajaxCompletionFunc) => {
  return function (dispatch) {
    let existingIdeas = [];
    let importSuccess = false;

    ideaExplorerAdapter.importIdeas().then((response) => {
      if ('ideas' in response) {
        existingIdeas = response.ideas;
        importSuccess = true;
      }

      if (importSuccess) {
        let existingIdeasLookup = {};
        for (let i = 0; i < existingIdeas.length; i++) {
          existingIdeasLookup[existingIdeas[i].security_id] = existingIdeas[i].idea_id;
        }

        let updatedIdeas = ideas.map((idea) => {
          if (existingIdeasLookup[getSecurityIdFromIdea(idea)] + 1 > 0) {
            return {
              ...idea,
              idea_id: existingIdeasLookup[getSecurityIdFromIdea(idea)],
            };
          }
          return { ...idea };
        });

        ideaExplorerAdapter.completeExploration(updatedIdeas).then((response) => {
          ajaxCompletionFunc();
        });
      } else {
        dispatch({
          type: SHOW_MODAL,
          payload: {
            heading: 'Save Failed, Try Again!',
            icon: 'fa fa-times failed-icon',
            dismissable: true,
          },
        });
      }
    });
  };
};
