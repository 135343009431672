import React from 'react';
import { connect } from 'react-redux';
import { isCurrentUserLiveTrading } from '../../helpers/currentUserHelpers';

import Button from '../../components/buttons/Button';
import Space from '../../components/UI/Space';
import { FlatButton } from '../../main/components/buttons';
import { SUPPORT_EMAIL } from '@src/appConfig';

class CloseAccountInformation extends React.PureComponent {
  render() {
    const { onContinue, onCancel } = this.props;

    return (
      <div className={'close-account-msg-container'}>
        <p>
          Not happy? Please contact us at <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> anytime with any
          questions, comments, or concerns. We&apos;ll do anything we can to make it right.
        </p>
        {this.props.isLiveAccount && (
          <p>We can only close your account if you have a $0 balance and no pending orders or transfers.</p>
        )}
        <div
          className=""
          style={{
            paddingTop: '20px',
            textAlign: 'center',
          }}
        >
          <span>
            <FlatButton onClick={onContinue}>Close Account</FlatButton>

            <Space />
            <Space />
            <Space />
            <Space />
            <Space />
            <Space />
            <Space />
            <Space />

            <FlatButton transparent onClick={onCancel}>
              Cancel
            </FlatButton>
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLiveAccount: isCurrentUserLiveTrading(state.currentUser),
  };
};

export default connect(mapStateToProps)(CloseAccountInformation);
