import React from 'react';
import { postFundingRewardAmount } from '../../../actions';
import { customParseFloat } from '../../../helpers/numberHelpers';

const initialState = {};

const actionTypes = {
  update: 'u',
};

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.update:
      return {
        ...state,
        ...action.payload,
      };
    default:
      throw new Error('missing actiontype');
  }
}

const getBonusAmount = (lookup, amount) => {
  const bonusAmount = lookup[amount];
  if (isNaN(bonusAmount)) {
    return null;
  }
  return bonusAmount;
};

export const useFundingBonus = (amount) => {
  const [bonusAmountLookup, internalDispatch] = React.useReducer(reducer, initialState, () => initialState);
  const [isEligible, setIsEligible] = React.useState(true);

  const getBonusCustomAmount = React.useCallback(
    async (v) => {
      const { ok, data } = await postFundingRewardAmount(customParseFloat(v));
      if (ok && !data?.error) {
        const { eligible, rewards } = data;
        if (eligible !== isEligible) setIsEligible(eligible);

        if (eligible) {
          const bonus = rewards[0]?.reward;
          internalDispatch({
            type: actionTypes.update,
            payload: {
              [amount]: bonus,
            },
          });
        }
      } else {
        const delay = 500;
        setTimeout(() => {
          getBonusCustomAmount(v);
        }, delay);
      }
    },
    [isEligible, setIsEligible, internalDispatch, amount]
  );

  React.useEffect(() => {
    if (!(amount in bonusAmountLookup) && !isNaN(customParseFloat(amount)) && amount !== '') {
      getBonusCustomAmount(amount);
    }
  }, [amount, getBonusCustomAmount]);

  return {
    bonusAmount: getBonusAmount(bonusAmountLookup, amount),
    isEligible,
    getBonusCustomAmount,
  };
};
