import React from 'react';

class MeasureSize extends React.Component {
  componentDidMount() {
    this._validateProps();

    try {
      this._createMutationObserver();
    } catch (error) {
      console.error('Could not create Observer for MeasureSize component', error);
    }

    if (this._shouldSetInitialSize()) {
      this._handleMutationEvent();
    }

    if (this.props.resize) {
      window.addEventListener('resize', this._handleResize);
    }

    // HACK because width is fixed in ThoughtFeed and varies around mounting forcing components to be taller than it needs
    // SIDE EFFECT: Can cause wasted renders, use sparingly
    if (this.props.updateShortlyAfterMount) {
      const _this = this;
      setTimeout(() => {
        _this._handleMutationEvent();
      }, 250);
    }
  }

  componentWillUnmount() {
    if (this.props.resize) {
      window.removeEventListener('resize', this._handleResize);
    }

    if (this._returnObserver()) {
      this._destroyMutationObserver();
    }
  }

  _handleResize = () => {
    const sizing = this._returnSizing();
    this._onMutation(sizing);
  };

  render() {
    return (
      <div ref={(el) => (this.DOMNode = el)} className={`measure-size-wrapper ${this.props.className || ''}`}>
        {this.props.children}
      </div>
    );
  }

  _returnDOMNode = () => this.DOMNode;

  _$returnDOMNode = () => $(this.DOMNode);

  _returnObserver = () => this._observer;

  _setObserver = (observer) => (this._observer = observer);

  _createMutationObserver = () => {
    const targetNode = this._returnDOMNode();

    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = this._handleMutationEvent;

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    this._setObserver(observer);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
  };

  _destroyMutationObserver = () => {
    this._returnObserver().disconnect();
  };

  _handleMutationEvent = () => {
    const sizing = this._returnSizing();
    const height = sizing.height;
    if (height !== this._height) {
      this._height = height;
      if (this.props.useThrottle) {
        this._createMutationTimeout(sizing);
      } else {
        this._onMutation(sizing);
      }
    }
  };

  _clearMutationTimeout = () => {
    clearTimeout(this.mutationTimeout);
  };

  _createMutationTimeout = (sizing) => {
    this._clearMutationTimeout();
    const _this = this;
    const timeout = setTimeout(() => _this._onMutation(sizing), 300);
    this.mutationTimeout = timeout;
  };

  _onMutation = (sizing) => {
    this.props.onMutation && this.props.onMutation(sizing);
  };

  _returnSizing = () => ({
    height: this._getHeight(),
    width: this._getWidth(),
  });

  _shouldSetInitialSize = () => this.props.setInitialSize;

  _getHeight = () => this._$returnDOMNode().height();
  _getWidth = () => this._$returnDOMNode().width();

  _validateProps = () => {
    if (!this.props.onMutation) {
      console.error('Missing onMutation prop for MeasureSize component');
    }
  };
}

export default MeasureSize;
