import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  & > svg {
    width: 18px;
    height: 18px;
  }
  & g,
  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function CompassIcon() {
  return (
    <Wrapper>
      <svg viewBox="0 0 18 18" fill="none">
        <path
          d="M9 .667A8.336 8.336 0 00.667 9c0 4.6 3.733 8.333 8.333 8.333S17.334 13.6 17.334 9 13.6.667 9 .667zm0 15A6.676 6.676 0 012.334 9 6.676 6.676 0 019 2.333 6.676 6.676 0 0115.667 9 6.676 6.676 0 019 15.667zm-4.583-2.084l6.258-2.908 2.909-6.258-6.259 2.908-2.908 6.258zM9 8.083c.509 0 .917.409.917.917A.914.914 0 019 9.917.914.914 0 018.084 9c0-.508.408-.917.916-.917z"
          fill="#1F1F1F"
        />
      </svg>
    </Wrapper>
  );
}

export default CompassIcon;
