import { SHOW_ACTION_MESSAGE, DISMISS_ACTION_MESSAGE } from '../constants';

export const showActionMessage = (messageData) => {
  return function (dispatch) {
    dispatch({
      type: SHOW_ACTION_MESSAGE,
      payload: messageData,
    });
  };
};

export const dismissActionMessage = (messageData) => {
  return function (dispatch) {
    dispatch({
      type: DISMISS_ACTION_MESSAGE,
      payload: messageData,
    });
  };
};
