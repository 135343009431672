import React from 'react';
import { OrderInNotificationRow } from './OrderInNotificationRow';

export const OrdersInNotificationList = (props) => {
  const { orderActionsList } = props;

  return (
    <div className={`order-actions-list-container notification-indent`}>
      <table>
        <tbody>
          {orderActionsList.map((orderActionData, i) =>
            orderActionData.orders.map((order, orderIndex) => (
              <OrderInNotificationRow
                key={`order-action-item-${i}-${orderIndex}`}
                {...orderActionData}
                orderIndex={orderIndex}
                order={order}
              />
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};
