import React from 'react';
import { FlatButton } from '../buttons';
import { Body5 } from '../../lib/nvstr-common-ui.es';
import styled from 'styled-components';
import { ROUTES } from '@src/constants/paths';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  //padding: 16px;
`;
const ButtonWrapper = styled.div`
  padding-top: 16px;
  text-align: center;

  button {
    width: 268px;
  }
`;
const TextWrapper = styled.div`
  text-align: center;
`;

export const OpenBrokerageAccountBanner = ({ copy }) => {
  const navigate = useNavigate();

  const handleOpenLivePress = () => {
    navigate(ROUTES.LIVE_TRADING_SIGNUP_START.build());
  };

  if (copy) {
    return (
      <Wrapper>
        <TextWrapper>
          <Body5 bold>{copy}</Body5>
        </TextWrapper>
        <ButtonWrapper>
          <FlatButton onClick={handleOpenLivePress}>Open Live Brokerage Account</FlatButton>
        </ButtonWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <TextWrapper>
        <Body5 bold>Want to invest real money? Open a live brokerage account with us.</Body5>
      </TextWrapper>
      <TextWrapper style={{ paddingTop: '8px' }}>
        <Body5 isSmall>
          For a limited time, get a funding bonus (Up to $1,000) when you open a live trading account and make your
          first deposit, also you will receive all your rewards from Learn and Earn
        </Body5>
      </TextWrapper>
      <ButtonWrapper>
        <FlatButton onClick={handleOpenLivePress}>Open Live Brokerage Account</FlatButton>
      </ButtonWrapper>
    </Wrapper>
  );
};
