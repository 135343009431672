import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNextLearnLesson } from '../../../actions';
import { isUndefinedOrNull } from '../../../helpers/usefulFuncs';
import { LEARN_STATUS_TYPES } from '../../constants/learn/types';

export const useNextLesson = () => {
  const dispatch = useDispatch();
  const lessonLookup = useSelector((state) => state.learn.lookup);
  const nextLessonReward = useSelector((state) => state.learn.nextLessonReward);
  const nextLessonRewardAtXP = useSelector((state) => state.learn.nextLessonRewardAtXP);
  const nextLessonCompletionXP = useSelector((state) => state.learn.nextLessonCompletionXP);
  const completedLessonCount = useSelector((state) => state.learn.completedLessonCount);
  // const hoursUntilNextLesson = useSelector((state) => state.learn.hoursUntilNextLesson);
  const status = useSelector((state) => state.learn.status);
  const messagingStates = useSelector((state) => state.learn.messagingStates || {});
  const nextLessonId = useSelector((state) => state.learn.nextLessonId);
  const [nextLessonName, setNextLessonName] = React.useState(null);

  const [isGettingNextLesson, setIsGettingNextLesson] = React.useState(false);
  const [areNoMoreLessons, setAreNoMoreLessons] = React.useState(false);

  const fetchNextLesson = React.useCallback(async () => {
    setIsGettingNextLesson(true);

    const response = await fetchNextLearnLesson()(dispatch);
    const { ok, data } = response;

    if (ok) {
      const error = data?.message || data?.error;
      const noMoreLessons = 'No more lessons available';
      if (error === noMoreLessons || status === LEARN_STATUS_TYPES.noMoreLessons) {
        setAreNoMoreLessons(true);
      }
    }

    setIsGettingNextLesson(false);
  }, []);

  React.useEffect(() => {
    const setup = async () => {
      if (isUndefinedOrNull(nextLessonId)) await fetchNextLesson();
    };
    setup();
  }, []);

  React.useEffect(() => {
    const lessonData = lessonLookup[nextLessonId];
    const calculatedNextLessonName = lessonData?.description || null;
    if (calculatedNextLessonName !== nextLessonName) {
      setNextLessonName(calculatedNextLessonName);
    }
  }, [lessonLookup, nextLessonId]);

  return {
    nextLessonId,
    nextLessonName,
    nextLessonReward,
    nextLessonRewardAtXP,
    nextLessonCompletionXP,
    completedLessonCount,
    status,
    messagingStates,
    // hoursUntilNextLesson,

    isGettingNextLesson,
    areNoMoreLessons,

    fetchNextLesson,
  };
};
