import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useCurrentUser, useLiveAccount } from '../../hooks/user';
import { logMetricsTrackingEvent, submitLiveTradingSignUpForm } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import { H5, Body5, Lock, FloatingLabelDateEntry } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { FormState } from '../../components/form/FormState';
import { useFormik } from 'formik';
import { IdentityDOBSchema } from '../../constants/formValidationSchemas';
import { createTimeInstance, formatLocalizedDateTime } from '../../../helpers/timeHelpers';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const InputWrapper = styled.div`
  padding-top: 40px;

  input {
    border: none !important;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 8px;
`;
const PrivacyMessage = styled.div`
  padding-top: 24px;
`;
const IconMessage = styled.div`
  padding-top: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  text-align: left;

  svg {
    height: 24px;
    width: 24px;

    fill: ${({ theme }) => theme.themeColors.text};

    margin-right: 16px;
  }
`;
const Actions = styled.div`
  padding-top: 36px;
  text-align: center;
`;

const getInitialValuesFrom = (currentUser, liveAccount) => {
  const { date_of_birth } = liveAccount;
  if (!date_of_birth) {
    return {
      date_of_birth: '',
    };
  }

  const [year, month, day] = date_of_birth.split('-');
  return {
    date_of_birth: `${month}/${day}/${year}`,
  };
};

const massageFormData = (v) => {
  return {
    date_of_birth: formatLocalizedDateTime('api', createTimeInstance(v.date_of_birth)),
  };
};

const FORM_PAGE_NAME = 'identity';

export const EnterDateOfBirth = ({ onContinue, onboardingState }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const liveAccount = useLiveAccount();

  const [isReady, setIsReady] = React.useState(true);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const form = useFormik({
    initialValues: getInitialValuesFrom(currentUser, liveAccount),
    validationSchema: IdentityDOBSchema,
    enableReinitialize: false,
  });
  const { values, errors, setFieldValue, validateForm } = form;

  const handleChange = React.useCallback(
    (name) => (value) => {
      const formattedValue = formatLocalizedDateTime('L', createTimeInstance(value));
      setFieldValue(name, formattedValue, false);
    },
    [setFieldValue]
  );

  const submitForm = React.useCallback((values) => {
    const apiFormData = massageFormData(values);
    setIsSubmitting(true);
    return submitLiveTradingSignUpForm(apiFormData, FORM_PAGE_NAME)(dispatch);
  }, []);

  const handleSubmit = React.useCallback(async () => {
    logMetricsTrackingEvent('Submitted Identity DOB Form')();

    const validation = await validateForm();
    if (Object.keys(validation).length === 0) {
      const { status } = await submitForm(values);
      if (status === 200) {
        return {
          ok: true,
        };
      } else {
        setFormError('Something went wrong, please try again');
        setIsSubmitting(false);
        return {
          ok: false,
        };
      }
    } else {
      logMetricsTrackingEvent('Failed Live Account Validations', {
        Fields: Object.entries(validation),
        'Form Name': 'birth date',
      })();

      // could log errors but no form validations on this form
      setIsSubmitting(false);
      return {
        errors: true,
      };
    }
  }, [values]);

  const handleContinueClick = React.useCallback(async () => {
    const { ok } = await handleSubmit();
    if (ok) onContinue(null, onboardingState);
  }, [handleSubmit]);

  React.useEffect(() => {
    logMetricsTrackingEvent('View Identity DOB Form')();
  }, []);

  if (!isReady) return <LoadingPage />;

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.enterDOB}>
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>What is your date of birth?</H5>
          </Heading>

          <InputWrapper>
            <FloatingLabelDateEntry
              name={'date_of_birth'}
              initialDate={values.date_of_birth}
              value={values.date_of_birth}
              label={''}
              onChange={handleChange}
              error={errors.date_of_birth || null}
            />
          </InputWrapper>

          <PrivacyMessage>
            <IconMessage>
              <Lock />
              <Body5>This information will be kept private and it will never appear on your public profile.</Body5>
            </IconMessage>
          </PrivacyMessage>

          <Actions>
            <FormState error={formError} isSubmitting={isSubmitting} />
            <Container fullWidth centerAll>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleContinueClick}>
                Continue
              </FlatButton>
            </Container>
          </Actions>
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
