import { SUPPORT_EMAIL } from '@src/appConfig';
export { ELEMENT_SIZES } from './elementSizes';
export * from './funding';
export * from './learnLessonContentInputOptions';

export const EMAILS = {
  support: SUPPORT_EMAIL,
};

export const ACCOUNT_TYPES = {
  paper: 'paper',
  live: 'live',
};

export const MIN_REWARD_SIGN_UP = 5;

export const DOC_REQUIREMENT_LOOKUP = {
  address: [
    "Driver's License",
    'State ID Card',
    'Utility Bill',
    'Residential Lease',
    'Property Deed',
    'Mortgage Statement',
  ],
  identity: ["Driver's License", 'State ID Card', 'Passport'],
  'immigration status': ['Green Card', 'Visa'],
  'social security number': ['Social Security Card', 'Social Security Administration Letter'],
  headshot: ['Photograph of your face'],
  statement: ['Bank Statement'],
};

export const COMPONENT_MODAL_TYPES = {
  order: 'order',
  createCartOrder: 'createCartOrder',
  editCartOrder: 'editCartOrder',
  liveLastPrice: 'liveLastPrice',
};

export const gptModels = {
  GPT_4: 'gpt-4',
  GPT_3_5_turbo: 'gpt-3.5-turbo',

  DAVINCI_3: 'text-davinci-003',
  DAVINCI_2: 'text-davinci-002',
  CURIE_1: 'code-curie-001',

  BABBAGE: 'text-babbage-001',
  ADA: 'text-ada-001',
};
