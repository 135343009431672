import React from 'react';
import { Page } from '../components/layout';
import { useDispatch } from 'react-redux';

import { quickFetchSecuritiesData } from '@src/actions';
import { useParams, useSearchParams } from 'react-router-dom';
import { LoadingPage } from '@src/main/components/page';
import { ORDER_TRANSACTION_TYPES, ORDER_TYPES } from '@src/main/constants/orders';
import { activeSecuritiesTracker, LivePricePollingManager } from '@src/main/classes/LivePricePollingManager';
import { Body5, H5 } from '@src/main/lib/nvstr-common-ui.es';
import { isFloat, isInteger } from '@src/helpers/numberHelpers';
import { Container } from '@src/main/components/ui';
import { TransactionTypeSelectionForm } from '@src/main/containers/Orders/TransactionTypeSelectionForm';
import { CreateOrderController } from '@src/main/containers/Orders/CreateOrderController';
import { useSymbolToSecurityId } from '@src/main/hooks/securities/useSymbolToSecurityId';
import { CreateOrderSearch } from '@src/main/containers/Orders/CreateOrderSearch';
import { usePosition } from '@src/main/hooks/portfolio/usePosition';

const validateTransactionType = (v) =>
  [ORDER_TRANSACTION_TYPES.buy, ORDER_TRANSACTION_TYPES.sell].includes(v) ? v : null;
const validateShares = (str) => {
  const v = parseFloat(str);
  return isInteger(v) || isFloat(v) ? str : null;
};
const validateSecurityId = (str) => {
  const v = parseFloat(str);
  return isInteger(v) || isFloat(v) ? str : null;
};

const TransactionTypeSelector = ({ transactionType: propsTransactionType, securityId, shares }) => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const position = usePosition(securityId);
  const hasPosition = Math.abs(position?.shares) > 0;

  const _params_transactionType = validateTransactionType(searchParams.get('tt'));
  const _params_shares = validateShares(searchParams.get('shares'));

  const [transactionType, setTransactionType] = React.useState(
    _params_transactionType || propsTransactionType || (!hasPosition ? ORDER_TRANSACTION_TYPES.buy : null)
  );

  const [initFormData, setInitFormData] = React.useState({
    transactionType: transactionType || '',
    orderType: ORDER_TYPES.market,
    shares: _params_shares || shares || '',
  });

  const handleTransactionTypeSelect = (v) => {
    setTransactionType(v);
    setInitFormData({
      ...initFormData,
      transactionType: v,
    });
  };

  React.useEffect(() => {
    let marketMonitorSecurities = [];

    const getSecurityData = async (id) => {
      const response = await quickFetchSecuritiesData([id])(dispatch);
      const { securities } = response;
      if (securities) {
        securities.forEach((s) => {
          const { symbol, id } = s;
          marketMonitorSecurities.push({ symbol, id });
        });
        activeSecuritiesTracker.addSecurities(marketMonitorSecurities);
      } else {
      }
    };
    getSecurityData(securityId);

    return () => {
      marketMonitorSecurities.length > 0 &&
        activeSecuritiesTracker.removeSecurities(marketMonitorSecurities.map((s) => s.symbol));
    };
  }, []);

  if (!transactionType)
    return <TransactionTypeSelectionForm securityId={securityId} onSelect={handleTransactionTypeSelect} />;

  if (!securityId) return null;
  return (
    <CreateOrderController
      key={securityId}
      securityId={securityId}
      initFormData={initFormData}
      isFromOptimizer={false}
    />
  );
};

const SecurityFromSymbolSelector = (props) => {
  const { symbol } = useParams();
  const securityIdRef = useSymbolToSecurityId(symbol?.toUpperCase());

  if (!symbol) {
    return (
      <Page>
        <Container top={8}>
          <Body5>What stock are you looking to buy or sell?</Body5>
        </Container>
        <Container top={24}>
          <CreateOrderSearch />
        </Container>
      </Page>
    );
  }
  if (!securityIdRef.securityId) return <LoadingPage />;
  return (
    <TransactionTypeSelector
      key={securityIdRef.securityId}
      {...props}
      securityId={securityIdRef.securityId}
      symbol={null}
    />
  );
};

export const CreateOrder = (props) => {
  // {
  //   transactionType: _props_transactionType,
  //   symbol: _props_symbol,
  //   shares: _props_shares,
  // }

  const [searchParams] = useSearchParams();
  const _params_securityId = validateSecurityId(searchParams.get('sid'));

  const [securityId, setSecurityId] = React.useState(_params_securityId || null);

  React.useEffect(() => {
    const _params_securityId = validateSecurityId(searchParams.get('sid'));
    const nextSid = _params_securityId;

    if (nextSid !== securityId) setSecurityId(nextSid);
  }, [securityId, searchParams]);

  if (!securityId) return <SecurityFromSymbolSelector {...props} />;

  return <TransactionTypeSelector key={securityId} {...props} securityId={securityId} symbol={null} />;
};
