import React, { useState } from 'react';
import withWindowSize from '../HOCS/withWindowSize';

import MeasureSize from './MeasureSize';

const RestrictHeight = (props) => {
  const {
    windowHeight,
    minHeight,
    screenHeight,
    heightOffsetBuffer,

    children,
  } = props;

  const [height, setHeight] = useState(750);

  const windowHeightCalc = windowHeight - heightOffsetBuffer;

  const onHeightChange = (sizing) => {
    const height = sizing.height;
    setHeight(height);
  };

  const isScrollBarNeeded = minHeight ? minHeight > windowHeightCalc : windowHeightCalc < height;
  const style = {
    overflow: isScrollBarNeeded ? 'auto' : 'hidden',
  };

  if (screenHeight && isScrollBarNeeded) {
    style.height = `${windowHeightCalc < height ? windowHeight - heightOffsetBuffer : height}px`;
  }
  return (
    <div style={style}>
      <MeasureSize onMutation={onHeightChange} setInitialSize useThrottle>
        {children}
      </MeasureSize>
    </div>
  );
};

export default withWindowSize(RestrictHeight);
