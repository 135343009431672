import React, { Component } from 'react';

import FormLoadingOverlay from '../../components/overlays/FormLoadingOverlay';

import { logMetricsTrackingEvent } from '../../actions';

import Button from '../../components/buttons/Button';

import Switch from '../../components/form/Switch';
import CustomDropdown from '../../components/form/CustomDropdown';
import Rating from '../../components/form/Rating';
import GenericInputField from '../../components/form/GenericInputField';

import UserIdea from './UserIdea';
import UserIdeaDiff from './UserIdeaDiff';
import ThoughtLeaderIdea from './ThoughtLeaderIdea';
import IdeaShowHeader from './IdeaShowHeader';

import {
  handleFocus,
  handleBlur,
  handleChange,
  handleCurrencyChange,
  handleSwitchClick,
  handleToggleClick,
  silentErrorCheck,
  manualValidation,
  handleCustomDropdownClick,
  handleRatingClick,
  createWarningsForInput,
} from '../../helpers/formHelpers';
import { convertPriceToNumberWithCommas } from '../../helpers/numberHelpers';
import { isThoughtLeaderIdea, handleExitPosition } from '../../helpers/ideaHelpers';
import { userFirstName } from '../../helpers/usersHelpers';
import { getIdeaPhraseFor } from '../../helpers/terminologyHelpers';
import { getIsUsingRebalancerForPortfolioOptimization } from '../../helpers/portfolioOptimizationHelpers';
import { FlatButton } from '../../main/components/buttons';

export class IdeaShow extends Component {
  constructor(props) {
    const isUsingRebalancer = getIsUsingRebalancerForPortfolioOptimization();
    super(props);
    this.state = {
      inputs: [
        {
          type: Switch,
          typeName: 'Switch',
          name: 'active',
          label: isUsingRebalancer ? 'Include in Rebalancer' : 'Include in Optimizer',
          required: true,
          props: {
            saveOnChange: this.props.updateIdea,
            objectType: 'idea',
          },
        },
        {
          type: Rating,
          typeName: 'Rating',
          name: 'conviction',
          label: 'Conviction',
          required: true,
          props: {
            maxRating: 5,
            saveOnChange: this.props.updateIdea,
            objectType: 'idea',
          },
        },
        {
          type: GenericInputField,
          typeName: 'GenericInputField',
          name: 'price_target',
          label: null,
          required: true,
          props: {
            handleChangeFunc: 'handleCurrencyChange',
            prefix: '$',
            autoFocus: true,
            popOutErrorMessage: true,
            blockingErrors: true,
            validateAfterChange: true,
            errorAction: {
              id: null,
              customClass: 'skel-btn skel-btn-trans-white btn-small-skinny',
              handleClick: this._handleRemovePriceTarget,
              text: 'Remove Target',
            },

            saveOnChange: props.handleAddPriceTarget,
            objectType: 'idea',
          },
        },
        {
          type: GenericInputField,
          typeName: 'GenericInputField',
          name: 'expected_return',
          label: null,
          required: true,
          props: {
            handleChangeFunc: 'handleChange',
            suffix: '%',
            autoFocus: true,
            popOutErrorMessage: true,
            blockingErrors: true,
            validateAfterChange: true,

            saveOnChange: this.props.updateIdea,
            objectType: 'idea',
          },
        },
        {
          type: GenericInputField,
          typeName: 'GenericInputField',
          name: 'expected_dividend',
          label: null,
          required: true,
          props: {
            handleChangeFunc: 'handleChange',
            suffix: '%',
            autoFocus: true,
            popOutErrorMessage: true,
            blockingErrors: true,
            validateAfterChange: true,

            saveOnChange: this.props.updateIdea,
            objectType: 'idea',
          },
        },
        {
          type: CustomDropdown,
          typeName: 'CustomDropdown',
          name: 'horizon',
          required: true,
          props: {
            styling: 'default',
            ignoreBlur: true,
            autoOpen: true,
            offset: { top: '-5px' },
            values: [
              { id: 0, name: 'Short Term' },
              { id: 1, name: 'Long Term' },
            ],
            blurOnOptionClick: true,

            saveOnChange: this.props.updateIdea,
            objectType: 'idea',
          },
        },
        {
          type: CustomDropdown,
          typeName: 'CustomDropdown',
          name: 'idea_type',
          required: true,
          props: {
            styling: 'default',
            ignoreBlur: true,
            autoOpen: true,
            offset: { top: '-5px' },
            values: [
              { id: 0, name: 'Buy' },
              { id: 1, name: 'Sell' },
            ],
            valueStyles: ['green-option', 'red-option'],

            saveOnChange: this.props.updateIdea,
            objectType: 'idea',
          },
        },
        {
          type: CustomDropdown,
          typeName: 'CustomDropdown',
          name: 'thesis',
          required: true,
          props: {
            styling: 'default',
            ignoreBlur: true,
            autoOpen: true,
            offset: { top: '-80px' },
            values: [
              { id: 0, name: 'No Thesis' },
              { id: 1, name: 'Value' },
              { id: 2, name: 'Event' },
              { id: 3, name: 'Growth' },
              { id: 4, name: 'Dividend' },
              { id: 5, name: 'Momentum' },
              { id: 6, name: 'Safety' },
              { id: 7, name: 'Management' },
              { id: 8, name: 'Macro' },
              { id: 9, name: 'Turnaround' },
            ],

            saveOnChange: this.props.updateIdea,
            objectType: 'idea',
          },
        },
      ],
      formData: {
        active: true,
      },
      focusedField: null,
      updatingAccount: false,
      errors: {},
      warnings: {},
      loadingFormData: true,
      promptToOverwriteIdea: false,
      showDeleteOptions: false,
    };
  }

  componentDidMount() {
    const idea = this.props.idea;
    if (idea && idea.idea_id) {
      this.updateFormData(idea);

      const security = this.props.security;
      const event = 'View Idea';
      const properties = {
        'Idea Id': idea.idea_id,
        'Stock Symbol': security.symbol,
        'Security ID': security.security_id,
        'Owner User ID': this.props.ideaOwner.analytics_id,
        'From Email': 'N/A', //TODO: add support for getting this out of URL param
        'Thought Leader Idea': isThoughtLeaderIdea(idea),
      };
      logMetricsTrackingEvent(event, properties)();

      this.props.viewIdea(idea.idea_id);
    } else {
      this.setState((prevState) => ({
        loadingFormData: false,
      }));
    }
  }

  componentWillReceiveProps(nextProps) {
    // if user updates their idea
    if (nextProps.idea && this.props.idea && nextProps.idea !== this.props.idea) {
      this.updateFormData(nextProps.idea);
    }
  }

  updateFormData = (idea) => {
    const fields = [
      'active',
      'conviction',
      'horizon',
      'thesis',
      'idea_type',
      'price_target',
      'expected_return',
      'expected_dividend',
    ];
    const formData = {};

    fields.forEach((fieldName) => {
      formData[fieldName] =
        fieldName === 'price_target' ? convertPriceToNumberWithCommas(idea[fieldName]) : idea[fieldName];
    });
    this.setState((prevState) => ({
      formData,
      loadingFormData: false,
    }));
  };

  handleSeeMorePositions = () => {
    const event = 'Clicked See More Positions';
    const properties = {
      Context: 'Thought Leader Idea Panel',
      'Thought Leader ID': this.props.ideaOwner.analytics_id,
    };
    logMetricsTrackingEvent(event, properties)();

    window.location = `/users/${this.props.idea.user_id}/public_profile`;
  };

  saveIdea = (sourceIdea) => {
    const event = 'Clicked Add Idea';
    const security = this.props.security;
    const properties = {
      'Idea Id': this.props.idea.idea_id,
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
      Context: 'Idea Panel',
      'Owner User ID': this.props.ideaOwner.analytics_id,
    };
    logMetricsTrackingEvent(event, properties)();

    const idea = sourceIdea || this.props.idea;
    return this.props.handleSaveIdea(idea);
  };

  saveTLIdea = () => {
    const event = 'Clicked Add Idea';
    const security = this.props.security;
    const properties = {
      'Idea Id': this.props.idea.idea_id,
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
      Context: 'Thought Leader Idea Panel',
      'Thought Leader ID': this.props.ideaOwner.analytics_id,
    };
    logMetricsTrackingEvent(event, properties)();

    return this.handleIdeaOverwrite();
  };

  _showDeleteOptions = () => {
    this.setState(() => ({
      showDeleteOptions: true,
    }));
  };

  _dismissDeleteOptions = () => {
    this.setState(() => ({
      showDeleteOptions: false,
    }));
  };

  handleKeepPosition = () => {
    this._dismissDeleteOptions();
  };

  _handleExitPosition = () => {
    // this function is duplicated in table cell remove idea
    handleExitPosition.call(this);
  };

  removeIdea = () => {
    // logic here is duplicated in the table cell for removing idea as well, make sure to check both places if changing anything
    const currentUser = this.props.currentUser;
    const positions = currentUser.positions;
    const security = this.props.security;
    const securityId = security.security_id;
    const userHasPositionInIdea =
      securityId in positions && positions[securityId].shares && positions[securityId].shares !== 0;
    const event = 'Clicked Delete Idea';
    const properties = {
      'Idea Id': this.props.idea.idea_id,
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
      'Has Position': userHasPositionInIdea,
      Context: 'Idea Panel',
    };
    logMetricsTrackingEvent(event, properties)();

    if (userHasPositionInIdea) {
      return this._showDeleteOptions();
    } else {
      return this.props.removeIdea(this.props.idea);
    }
  };

  handleIdeaOverwrite = () => {
    const idea = this.props.idea;

    const event = 'Clicked Overwrite Idea';
    const security = this.props.security;
    const properties = {
      'Idea Id': idea.idea_id,
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
      Context: 'Idea Panel',
    };
    logMetricsTrackingEvent(event, properties)();

    this.clearPromptForIdeaOverwrite();
    this.props.startUserUpdatingIdea();
    return this.saveIdea(idea);
  };

  handleIdeaOverwriteCancel = () => {
    const event = 'Cancel Overwrite Idea';
    const security = this.props.security;
    const properties = {
      'Idea Id': this.props.idea.idea_id,
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
    };
    logMetricsTrackingEvent(event, properties)();

    this.clearPromptForIdeaOverwrite();
  };

  createPromptForIdeaOverwrite = () => {
    const event = 'View Overwrite Idea Dialog';
    const security = this.props.security;
    const properties = {
      'Idea Id': this.props.idea.idea_id,
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
    };
    logMetricsTrackingEvent(event, properties)();

    this.setState((prevState) => ({
      promptToOverwriteIdea: true,
    }));
  };

  clearPromptForIdeaOverwrite = () => {
    this.setState((prevState) => ({
      promptToOverwriteIdea: false,
    }));
  };

  renderDiff = () => {
    return (
      <div className="idea-overwrite-diff-container">
        <UserIdeaDiff
          idea={this.props.idea}
          user={this.props.users.userList[this.props.idea.user_id]}
          security={this.props.security}
          screenWidth={this.props.screenWidth}
          currentPrice={this.props.currentPrice}
        />
        <UserIdeaDiff
          idea={this.props.currentUsersIdea}
          isCurrentUsersIdea={true}
          security={this.props.security}
          screenWidth={this.props.screenWidth}
          currentPrice={this.props.currentPrice}
        />
      </div>
    );
  };

  renderAskWhyMessage = () => {
    const ideaOwnerName = userFirstName(this.props.ideaOwner);
    const ideaSymbol = this.props.security.symbol.toUpperCase();

    return (
      <div className="ask-why-message-container">
        <div className="ask-why-heading">{`We asked ${ideaOwnerName} to post a thought on ${ideaSymbol}.`}</div>
        <div className="ask-why-content">
          {`We'll let you know when they do. In the meantime feel free to ask other connections about ideas that interest you.`}
        </div>
        <div className="ask-why-dismiss-container">
          <FlatButton onClick={this.props.dismissAskWhyMessage}>Got it</FlatButton>
        </div>
      </div>
    );
  };

  renderIdeaBody = () => {
    if (this.state.promptToOverwriteIdea && this.props.currentUsersIdea !== this.props.idea.idea_id) {
      return this.renderDiff();
    }

    const thoughtLeaderIdea = isThoughtLeaderIdea(this.props.idea);
    return thoughtLeaderIdea ? (
      <ThoughtLeaderIdea security={this.props.security} idea={this.props.idea} screenWidth={this.props.screenWidth} />
    ) : (
      <UserIdea
        security={this.props.security}
        formData={this.state.formData}
        idea={this.props.idea}
        inputs={this.state.inputs.slice(1, this.state.inputs.length)}
        inputProps={this.returnInputDefaultProps()}
        currentUsersIdea={this.props.currentUsersIdea}
        userAddingPriceTarget={this.props.userAddingPriceTarget}
        ideaIsCurrentUsersIdea={this.props.idea.current_users_idea}
        handleAddPriceTarget={this.props.handleAddPriceTarget}
        handleRemovePriceTarget={this._handleRemovePriceTarget}
        promptToOverwriteIdea={this.state.promptToOverwriteIdea}
        screenWidth={this.props.screenWidth}
        warnings={this.state.warnings}
        dismissWarning={this._dismissWarning}
        handleAskWhy={this.props.handleAskWhy}
        isPriceLoading={this.props.isPriceLoading}
        currentPrice={this.props.currentPrice}
        user={
          this.props.currentUser.user_id === this.props.idea.user_id
            ? this.props.currentUser
            : this.props.users.userList[this.props.idea.user_id]
        }
      />
    );
  };

  _handleRemovePriceTarget = () => {
    const ideaId = this.props.idea.idea_id;
    this.props.handleRemovePriceTarget(ideaId);
  };

  isIdeaDataUpdating = () => this.props.idea.loading;

  render() {
    const { idea, userCreatingIdea } = this.props;

    if (this.props.userUpdatingIdea && this.props.idea && this.props.idea.idea_id === null) {
      return this.renderUserUpdatingIdea();
    }

    if (userCreatingIdea) {
      return this.renderUserCreatingIdea();
    }

    if (this.state.loadingFormData || this.isIdeaDataUpdating()) {
      return this.renderLoading();
    }

    const toggleActiveInput = {
      input: this.state.inputs[0],
      defaultProps: this.returnInputDefaultProps.call(this),
    };

    return (
      <div className="idea-show-container component-bg  border-accent">
        <IdeaShowHeader
          ideaUserId={idea.user_id}
          allocation={idea.allocation}
          screenWidth={this.props.screenWidth}
          ideaIsCurrentUsersIdea={idea.current_users_idea}
          thoughtLeaderIdea={isThoughtLeaderIdea(this.props.idea)}
          handleSeeMorePositions={this.handleSeeMorePositions}
          userHasIdea={!!this.props.currentUsersIdea}
          showDeleteOptions={this.state.showDeleteOptions}
          handleExitPosition={this._handleExitPosition}
          handleKeepPosition={this.handleKeepPosition}
          saveIdea={this.saveIdea}
          saveTLIdea={this.saveTLIdea}
          removeIdea={this.removeIdea}
          toggleActiveInput={toggleActiveInput}
          showOverwriteIdeaHeading={this.state.promptToOverwriteIdea}
          createPromptForIdeaOverwrite={this.createPromptForIdeaOverwrite}
          handleIdeaOverwrite={this.handleIdeaOverwrite}
          handleIdeaOverwriteCancel={this.handleIdeaOverwriteCancel}
          currentUser={this.props.currentUser}
          users={this.props.users}
          security={this.props.security}
          idea={this.props.idea}
          additionalDismissAskWhyAction={this.props.dismissAskWhyMessage}
          autoOpenAskWhy={this.props.askedWhy}
        />
        <div className="idea-show-content-container">{this.renderIdeaBody()}</div>
      </div>
    );

    // disable react scroll library for now
    // return (
    //   <div className='idea-show-container border-accent'>
    //     <BasicScrollContainer
    //       scrollDirection='y'
    //     >
    //       <IdeaShowHeader
    //         ideaUserId={ idea.user_id }
    //         allocation={ idea.allocation }
    //         screenWidth={ this.props.screenWidth }
    //         ideaIsCurrentUsersIdea={ idea.current_users_idea }
    //         thoughtLeaderIdea={ isThoughtLeaderIdea( this.props.idea ) }
    //         handleSeeMorePositions={ this.handleSeeMorePositions }
    //         userHasIdea={ !!this.props.currentUsersIdea }
    //         showDeleteOptions={ this.state.showDeleteOptions }
    //         handleExitPosition={ this._handleExitPosition }
    //         handleKeepPosition={ this.handleKeepPosition }
    //         saveIdea={ this.saveIdea }
    //         saveTLIdea={ this.saveTLIdea }
    //         removeIdea={ this.removeIdea }
    //         toggleActiveInput={ toggleActiveInput }
    //         showOverwriteIdeaHeading={ this.state.promptToOverwriteIdea }
    //         createPromptForIdeaOverwrite={ this.createPromptForIdeaOverwrite }
    //         handleIdeaOverwrite={ this.handleIdeaOverwrite }
    //         handleIdeaOverwriteCancel={ this.handleIdeaOverwriteCancel }
    //         currentUser={ this.props.currentUser }
    //         users={ this.props.users }
    //         security={ this.props.security }
    //         idea={ this.props.idea }

    //         additionalDismissAskWhyAction={this.props.dismissAskWhyMessage}
    //         autoOpenAskWhy={this.props.askedWhy}
    //     />

    //       <div className='idea-show-content-container'>
    //         { this.renderIdeaBody() }
    //       </div>
    //     </BasicScrollContainer>
    //   </div>
    // )
  }

  _dismissWarning = (name) => {
    this.setState((prevState) => ({
      warnings: {
        ...this.state.warnings,
        [name]: null,
      },
    }));
  };

  renderUserCreatingIdea = () => {
    return (
      <div className="idea-show-container border-accent">
        <FormLoadingOverlay
          show={true}
          scrollRestriction={true}
          spinner={{
            iconClass: 'circle-loading-spinner circle-loading-spinner-medium',
            style: { marginBottom: '-7px' },
          }}
          overlayStyle={{ background: 'rgba(0,0,0,0)' }}
          message={getIdeaPhraseFor('adding')}
        />
      </div>
    );
  };

  renderUserUpdatingIdea = () => {
    return (
      <div className="idea-show-container border-accent">
        <FormLoadingOverlay
          show={true}
          scrollRestriction={true}
          spinner={{
            iconClass: 'circle-loading-spinner circle-loading-spinner-medium',
            style: { marginBottom: '-7px' },
          }}
          overlayStyle={{ background: 'rgba(0,0,0,0)' }}
          message={getIdeaPhraseFor('updating')}
        />
      </div>
    );
  };

  renderLoading = () => {
    return (
      <div className="idea-show-container border-accent">
        <FormLoadingOverlay
          show={true}
          scrollRestriction={true}
          spinner={{
            iconClass: 'circle-loading-spinner circle-loading-spinner-medium',
            style: { marginBottom: '-7px' },
          }}
          overlayStyle={{ background: 'rgba(0,0,0,0)' }}
          message={'Loading'}
        />
      </div>
    );
  };

  returnInputDefaultProps() {
    return {
      handleFocus: handleFocus.bind(this),
      handleBlur: handleBlur.bind(this),
      silentErrorCheck: silentErrorCheck.bind(this),
      handleSwitchClick: handleSwitchClick.bind(this),
      handleToggleClick: handleToggleClick.bind(this),
      handleRatingClick: handleRatingClick.bind(this),
      manualValidation: manualValidation.bind(this),
      handleCustomDropdownClick: handleCustomDropdownClick.bind(this),
      handleChange: handleChange.bind(this),
      createWarningsForInput: createWarningsForInput.bind(this),
      handleCurrencyChange: handleCurrencyChange.bind(this),
      isFocused: function (name) {
        return this.state.focusedField === name;
      }.bind(this),
      getFocusNote: function (name) {
        if ('focusNote' in this.state) {
          return this.state.focusNote[name];
        }
      }.bind(this),
      getErrorMessage: function (name) {
        return this.state.errors[name];
      }.bind(this),
      getInputWarning: function (name) {
        return this.state.warnings[name];
      }.bind(this),
      dismissWarning: function (name) {
        this.setState((prevState) => ({
          warnings: { ...this.state.warnings, [name]: null },
        }));
      }.bind(this),
      getValue: function (name) {
        return this.state.formData[name];
      }.bind(this),
      getValueObjectId: function (name) {
        return this.state.formData[name][`${name}_type_id`];
      }.bind(this),
      getObjectId: function (name) {
        return this.props[name][`${name}_id`];
      }.bind(this),
    };
  }
}

export default IdeaShow;
