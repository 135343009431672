export const analyticsPageNames = {
  '/': 'Home',
  '/about': 'About Us',
  '/faq': 'FAQ',
  '/legal': 'Legal',
  '/login': 'Login',
  '/404': '404 Error Page',
  '/500': '500 Error Page',
  '/enterprise': 'Enterprise',
  '/funding-bonus-rules': 'Funding Bonus Rules',
  '/margin_disclosure': 'Margin Disclosure',
  '/privacy_policy': 'Privacy Policy',
  '/referral_rules': 'Referral Rules',
  '/surveyrules': 'Surveyrules',
  '/terms_of_service': 'Terms of Service',
  '/viewpoint_guidelines': 'Viewpoint Guidelines',
  '/share/[id]': 'Share',
  '/ideas': 'Ideas Screen',
};
