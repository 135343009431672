import { LOG_AMPLITUDE_EVENTS, PRODUCT_NAME } from '../appConfig';
import { getPageContextEventDataFromLocation } from '../constants/contextTracking';
import { logAmplitudeEvent } from '../utils/tracking/amplitude';

const defaultEventProperties = {
  'Is Mobile App': false,
  'Product Branding': PRODUCT_NAME,
};
const _defaultUserTrackingProperties = {
  'Product Branding': PRODUCT_NAME,
};

export const setDefaultUserTrackingProperties = (properties) => {
  const keys = Object.keys(properties);
  keys.forEach((key) => {
    _defaultUserTrackingProperties[key] = properties[key];
  });
};

const getUrlTrackingProperties = () => {
  try {
    const pathname = window?.location?.pathname.replaceAll('/app', '');
    return {
      URL: pathname,
      'URL Query': window?.location?.search,
    };
  } catch (e) {
    return {
      URL: null,
    };
  }
};

export const logMetricsTrackingEvent = (event, properties) => {
  // Note: in most implementations of this function, dispatch is available if needed to dispatch an action
  return function() {
    if (!event || typeof event !== 'string') {
      return console.error('Invalid event used, expecting a String');
    }

    const defaultProperties = {
      ...getPageContextEventDataFromLocation(),
      ...defaultEventProperties,
      ..._defaultUserTrackingProperties,
    };
    const customEventProperties = properties || {};
    const eventProperties = {
      ...defaultProperties,
      ...customEventProperties,
      ...getUrlTrackingProperties(),
    };

    if (LOG_AMPLITUDE_EVENTS) {
      console.log('sending event tracking log', event, eventProperties);
    }

    return logAmplitudeEvent(event, eventProperties);
  };
};
