import React from 'react';
import styled from 'styled-components';
import { getSecurityIdFromIdea } from '../../../helpers/ideaHelpers';
import { useDispatch } from 'react-redux';
import {
  fetchIdeas,
  fetchSecuritiesFundamentalsData,
  fetchSecuritiesPriceData,
  quickFetchSecuritiesData,
} from '../../../actions';
import { Body5, Body6, ChangeIndicator, Checkbox } from '../../lib/nvstr-common-ui.es';
import { Container } from '../../components/ui';
import { FlatButton } from '../../components/buttons';
import { SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../components/ui/SvgLoadingIcon';
import { activeSecuritiesTracker, LivePricePollingManager } from '../../classes/LivePricePollingManager';
import { useIdea } from '../../hooks/ideas/useIdea';
import { useIdeas } from '../../hooks/user/useIdeas';
import { usePriceData } from '../../hooks/securities/usePriceData';
import { ColorChangeText } from '../../components/molecules/ColorChangeText';
import { DISPLAY_FORMAT_TYPES, formatValueTo } from '../../utils/numbers';
import InfoIcon from '../../../containers/UI/InfoIcon';
import { useNavigateToSecurity } from '../../hooks/securities/useNavigateToSecurity';
import { useSecurity } from '../../hooks/securities/useSecurity';
import { Target } from '../../icons/svg/Target';
import { formatSecurityPropertyValue } from '../../../helpers/securitiesHelpers';

const ErrorWrapper = styled.div`
  padding: 24px;
`;
const TableWrapper = styled.div`
  @media (max-width: 692px) {
    margin-top: -40px;
  }
`;

const _fetchData = async (userId, dispatch, handleSuccess, handleFailure) => {
  const { ok, ideas, errorMessage } = await fetchIdeas(userId)(dispatch);
  if (ok) {
    const ideaList = ideas || [];
    const securityIds = ideaList.map((idea) => getSecurityIdFromIdea(idea));
    const responses = await Promise.all([
      quickFetchSecuritiesData(securityIds)(dispatch),
      fetchSecuritiesFundamentalsData(securityIds)(dispatch),
      fetchSecuritiesPriceData(securityIds)(dispatch),
    ]);
    handleSuccess();
  } else {
    handleFailure();
    console.error(errorMessage);
  }
};

const rowHeight = 116 - 32;

const NameWrapper = styled.div`
  color: ${({ theme }) => theme.themeColors.secondaryText};
`;
const IdentifierCellWrapper = styled.div`
  height: ${rowHeight}px;

  .mobile {
    display: none;
  }

  > div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @media (max-width: 692px) {
    height: 60px;

    ${NameWrapper} {
      padding-left: 8px;
    }
    .mobile-row {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: flex-start;
    }
    .mobile {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: flex-start;
    }
    .non-mobile {
      display: none;
    }

    > div {
      padding-left: 6px;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;
const PerformanceCellWrapper = styled.div`
  height: ${rowHeight}px;
`;
const FundamentalsCellWrapper = styled.div`
  height: ${rowHeight}px;
`;

const RowWrapper = styled.div`
  box-sizing: border-box;

  display: grid;
  grid-template-columns: minmax(120px, 1fr) minmax(228px, 1fr) minmax(260px, 1fr);
  grid-column-gap: 8px;
  padding: 16px 24px;
  border: 1px solid transparent;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  cursor: pointer;

  :hover {
    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
  }

  @media (max-width: 840px) {
    padding: 16px 8px;
  }
  @media (max-width: 692px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(40px, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;

    > div:first-child {
      grid-area: 1 / 1 / 2 / 3;
      padding-top: 0;
      padding-bottom: 0;
    }
    > div:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
    }
    > div:nth-child(3) {
      grid-area: 2 / 2 / 3 / 3;
    }
  }

  @media (max-width: 460px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;
const IdeaTypeWrapper = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 800;

  color: ${({ theme, type }) => (type === 0 ? theme.themeColors.positive : theme.themeColors.negative)};
`;

const IdentifierCell = ({ securityId, ideaId, userId }) => {
  const idea = useIdea(ideaId);
  const { idea_type, security: ideaSecurity, allocation: allocation_percent, active } = idea;
  const { featured } = useSecurity(securityId);
  const { name, symbol } = ideaSecurity;
  const allocationPercentDisplay = formatSecurityPropertyValue(allocation_percent, 'current_price_change_percent');

  return (
    <div>
      <IdentifierCellWrapper>
        <div className={'mobile-row'}>
          <Body6>{symbol?.toUpperCase()}</Body6>
          <NameWrapper top={4}>
            <Body5 isLowContrast thin>
              {name}
            </Body5>
          </NameWrapper>
        </div>
        <div className={'mobile'}>
          {(featured && active) || Math.abs(allocation_percent) > 0 ? (
            <IdeaTypeWrapper type={idea_type.id}>
              {idea_type.id === 0 ? 'LONG' : idea_type.id === 1 ? 'SHORT' : ''}
            </IdeaTypeWrapper>
          ) : (
            <div>
              <span>&nbsp;</span>
            </div>
          )}
          {Math.abs(allocation_percent) > 0 && (
            <NameWrapper top={4}>
              <InfoIconWrapper>
                <Body5 thin>{allocationPercentDisplay} of portfolio</Body5>
                <InfoIcon word={'tooltip_allocation'} style={{ top: '3px', right: '-18px' }} />
              </InfoIconWrapper>
            </NameWrapper>
          )}
        </div>
        <div className={'non-mobile'}>
          {Math.abs(allocation_percent) > 0 && (
            <NameWrapper top={4}>
              <InfoIconWrapper>
                <Body5 thin>{allocationPercentDisplay} of portfolio</Body5>
                <InfoIcon word={'tooltip_allocation'} style={{ top: '3px', right: '-18px' }} />
              </InfoIconWrapper>
            </NameWrapper>
          )}
          {featured && active ? (
            <IdeaTypeWrapper type={idea_type.id}>
              {idea_type.id === 0 ? 'LONG' : idea_type.id === 1 ? 'SHORT' : ''}
            </IdeaTypeWrapper>
          ) : (
            <div>
              <span>&nbsp;</span>
            </div>
          )}
        </div>
      </IdentifierCellWrapper>
    </div>
  );
};

const PerformanceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 6px;
  :first-child {
    padding: 0;
  }
`;
const PerformanceLabel = styled.div`
  width: 80px;
  text-align: right;
  overflow: visible;
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 62px;
  }
`;
const PerformanceChangeWrapper = styled.div`
  text-align: center;
  width: 36px;

  @media (max-width: 600px) {
    width: 26px;
  }
`;
const PerformanceValueWrapper = styled.div`
  width: 66px;
  text-align: left;
`;
const PerformanceValue = ({ value }) => {
  return (
    <PerformanceValueWrapper>
      <ColorChangeText value={value}>
        <Body5>{formatValueTo(DISPLAY_FORMAT_TYPES.PERCENTAGE, value)}</Body5>
      </ColorChangeText>
    </PerformanceValueWrapper>
  );
};
const PerformanceChangeIndicator = ({ value }) => {
  return (
    <PerformanceChangeWrapper>
      <ChangeIndicator value={value} size={16} />
    </PerformanceChangeWrapper>
  );
};

const PerformanceCell = ({ securityId, ideaId }) => {
  const idea = useIdea(ideaId);
  const { prev_month_perf, this_month_perf } = idea;
  const { percentChange } = usePriceData(securityId);
  return (
    <PerformanceCellWrapper>
      <PerformanceRow>
        <PerformanceLabel>
          <Body5 isLowContrast thin>
            Today
          </Body5>
        </PerformanceLabel>
        <PerformanceChangeIndicator value={percentChange} />
        <PerformanceValue value={percentChange} />
      </PerformanceRow>
      {this_month_perf !== null && (
        <PerformanceRow>
          <PerformanceLabel>
            <Body5 isLowContrast thin>
              This Month
            </Body5>
          </PerformanceLabel>
          <PerformanceChangeIndicator value={this_month_perf} />
          <PerformanceValue value={this_month_perf} />
        </PerformanceRow>
      )}
      {prev_month_perf !== null && (
        <PerformanceRow>
          <PerformanceLabel>
            <Body5 isLowContrast thin>
              Last Month
            </Body5>
          </PerformanceLabel>
          <PerformanceChangeIndicator value={prev_month_perf} />
          <PerformanceValue value={prev_month_perf} />
        </PerformanceRow>
      )}
    </PerformanceCellWrapper>
  );
};
const InfoIconWrapper = styled.div`
  position: relative;
  display: inline-block;
  path {
    fill: ${({ theme }) => theme.themeColors.secondaryText};
  }
`;
const ExpectedReturn = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
`;
const PriceTargetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  svg {
    height: 15px;
    width: 15px;
    margin-left: 5px;

    path {
      fill: ${({ theme, type }) => theme.themeColors.negative};
    }
  }
`;
const Conviction = styled.div`
  font-size: 12px;
  line-height: 22px;
  font-weight: 200;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const PriceTarget = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
`;
const FundamentalsCell = ({ securityId, ideaId }) => {
  const idea = useIdea(ideaId);
  const { featured } = useSecurity(securityId);
  const { idea_type, conviction, price_target, expected_return, horizon, active } = idea || {};

  const priceTargetDisplay = formatSecurityPropertyValue(price_target, 'current_price');
  const expectedReturnDisplay = formatSecurityPropertyValue(expected_return, 'percentage');

  return (
    <FundamentalsCellWrapper>
      {featured && active ? (
        <div>
          {isNaN(price_target) || price_target === null ? (
            <ExpectedReturn>{expectedReturnDisplay}</ExpectedReturn>
          ) : (
            <PriceTargetWrapper>
              <PriceTarget>{'$' + priceTargetDisplay}</PriceTarget>
              <Target />
            </PriceTargetWrapper>
          )}
          <Conviction>{conviction.name} conviction</Conviction>
        </div>
      ) : null}
    </FundamentalsCellWrapper>
  );
};

const TableRow = ({ ideaId, userId }) => {
  const idea = useIdea(ideaId);
  const navigateToSecurity = useNavigateToSecurity(idea?.security?.security_id);

  if (!idea) {
    return null;
  }

  const handleRowClick = () => {
    if (securityId) navigateToSecurity();
  };

  const { id, security } = idea;
  const { symbol, security_id: securityId, name } = security;
  return (
    <RowWrapper onClick={handleRowClick}>
      <IdentifierCell symbol={symbol} name={name} securityId={securityId} ideaId={id} userId={userId} />
      <PerformanceCell securityId={securityId} ideaId={id} />
      <FundamentalsCell securityId={securityId} ideaId={id} />
    </RowWrapper>
  );
};

const sortIdeas = (ideas) => {
  if (!ideas) return ideas;
  const getIdeaSymbol = (idea) => idea.security.symbol;

  const allocatedIdeas = ideas
    .filter((i) => Math.abs(i.allocation) > 0)
    .sort((a, b) => {
      const aIdea = Math.abs(a.allocation);
      const bIdea = Math.abs(b.allocation);
      return bIdea - aIdea;
    });
  const activeIdeas = ideas
    .filter((i) => i.active && i.allocation === null)
    .sort((a, b) => {
      if (getIdeaSymbol(a) < getIdeaSymbol(b)) return -1;
      if (getIdeaSymbol(a) > getIdeaSymbol(b)) return 1;
      return 0;
    });
  const inactiveIdeas = ideas
    .filter((i) => !i.active && i.allocation === null)
    .sort((a, b) => {
      if (getIdeaSymbol(a) < getIdeaSymbol(b)) return -1;
      if (getIdeaSymbol(a) > getIdeaSymbol(b)) return 1;
      return 0;
    });
  return [...allocatedIdeas, ...activeIdeas, ...inactiveIdeas];
};
const useSortIdeas = (userId) => {
  const ideas = useIdeas(userId);

  const [sortedIdeas, setSortedIdeas] = React.useState(sortIdeas(ideas));
  React.useEffect(() => {
    setSortedIdeas(sortIdeas(ideas));
  }, [ideas]);
  return sortedIdeas;
};

const TableHeadingWrapper = styled.div`
  box-sizing: border-box;

  display: grid;
  grid-template-columns: minmax(120px, 1fr) minmax(228px, 1fr) minmax(260px, 1fr);
  padding: 2px 24px;

  @media (max-width: 692px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(40px, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;

    > div:first-child {
      grid-area: 1 / 1 / 2 / 3;
      padding-top: 0;
      padding-bottom: 0;
    }
    > div:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
    }
    > div:nth-child(3) {
      grid-area: 2 / 2 / 3 / 3;
    }
  }
`;
const Table = ({ userId }) => {
  const ideas = useSortIdeas(userId);

  if (!ideas) {
    return null;
  }

  return (
    <TableWrapper>
      <TableHeadingWrapper>
        <div></div>
        <Container left={12}>
          <Body5 isLowContrast thin>
            Idea Performance
          </Body5>
        </Container>
        <div>
          <InfoIconWrapper isLowContrast>
            <Body5 isLowContrast thin>
              Expected Return
            </Body5>
            <InfoIcon word={'tooltip_expected_return_idea_panel'} style={{ top: '2px', right: '-20px' }} />
          </InfoIconWrapper>
        </div>
      </TableHeadingWrapper>
      {ideas.map((i) => (
        <TableRow key={i.id} userId={userId} ideaId={i.id} />
      ))}
    </TableWrapper>
  );
};

export const CommunityUserIdeaPerformanceTable = ({ userId }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [didFail, setDidFail] = React.useState(false);

  const ideas = useIdeas(userId);

  React.useEffect(() => {
    const handleSuccess = () => setDidFail(false);
    const handleFailure = () => setDidFail(true);
    const init = async () => {
      setIsLoading(true);
      await _fetchData(userId, dispatch, handleSuccess, handleFailure);
      setIsLoading(false);
    };
    if (!ideas || ideas.length === 0) init();
  }, [userId]);

  React.useEffect(() => {
    let addToLivePriceSecuritiesData;
    const securities = ideas?.map((i) => i.security);
    addToLivePriceSecuritiesData = securities?.map((securityData) => ({
      symbol: securityData.symbol,
      id: securityData.id,
    }));
    addToLivePriceSecuritiesData && activeSecuritiesTracker.addTempSecurities(addToLivePriceSecuritiesData);

    return () => {
      addToLivePriceSecuritiesData &&
        activeSecuritiesTracker.removeTempSecurities(addToLivePriceSecuritiesData?.map((s) => s.symbol));
    };
  }, [ideas]);

  if (isLoading) {
    return (
      <Container centerAll height={80}>
        <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.balls} />
      </Container>
    );
  }

  if (didFail) {
    const onRetry = async () => {
      const handleSuccess = () => setDidFail(false);
      const handleFailure = () => setDidFail(true);
      setIsLoading(true);
      await _fetchData(userId, dispatch, handleSuccess, handleFailure);
      setIsLoading(false);
    };
    return (
      <ErrorWrapper>
        <Body5>Something went wrong getting these positions, please try again.</Body5>
        <Container top={24}>
          <FlatButton onClick={onRetry}>Try Again</FlatButton>
        </Container>
      </ErrorWrapper>
    );
  }

  return <Table userId={userId} />;
};
