import React from 'react';
import { Page } from '../layout';
import { Gradient } from '../backgrounds';
import { useWindowSize } from '../../hooks/util';
import { ELEMENT_SIZES } from '../../constants';
import { CloseButton } from '../buttons';
import styled from 'styled-components';

const LessonContentWrapper = styled.div`
  position: relative;
  overflow: auto;

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 16px 24px;

  @media (max-width: 340px) {
    padding: 8px;
  }
`;

function getForceHeightValue(windowSize) {
  if (windowSize.width < 600) {
    return Math.min(windowSize.height, 1000) - ELEMENT_SIZES.headerNavbar;
  }

  const offset = windowSize.width > 614 ? 24 : 0;
  return windowSize.height > 1000 ? 1000 : windowSize.height - ELEMENT_SIZES.headerNavbar - offset;
}

export const LearnScreen = ({ children, onDismiss }) => {
  const windowSize = useWindowSize();
  const forceHeight = getForceHeightValue(windowSize);
  return (
    <Page
      noPadding
      height={`${forceHeight}px`}
      width={'600px'}
      style={{ position: 'relative', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}
    >
      {onDismiss && (
        <div style={{ position: 'absolute', top: '8px', right: '8px', zIndex: 4 }}>
          <CloseButton onClick={onDismiss} />
        </div>
      )}
      <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, minWidth: '475px' }}>
        <Gradient />
      </div>

      <LessonContentWrapper flex={1} columnCenter style={{ position: 'relative' }}>
        {children}
      </LessonContentWrapper>
    </Page>
  );
};
