import account from './account';
import bank from './bank';
import generic from './generics';
import onboarding from './onboarding';
import learn from './learn';
import viewed from './viewed';

export const TrackIteratively = {
  // -generic
  viewed,
  generic,

  // -themed

  account,
  bank,
  learn,
  onboarding,
};
