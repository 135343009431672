import React from 'react';
import { Container } from '../components/ui';
import { Body5, Body6, LoadingSpinner } from '../lib/nvstr-common-ui.es';
import { Page } from '../components/layout';
import styled from 'styled-components';
import { FlatButton, SkeletonButton } from '@src/main/components/buttons';
import { CheckCircleFilled } from '@src/main/icons';
import { postLessonToApi } from '@src/actions';
import { LessonPreview } from '@src/main/containers/learn/admin';
import { useDispatch, useSelector } from 'react-redux';
import { learnTypes } from '@src/main/constants/actionTypes';
import { getLesson } from '../../actions';

const PageWrapper = styled.div`
  display: flex;
`;
const ErrorWrapper = styled.div`
  * {
    color: ${({ theme }) => theme.themeColors.error};
  }
`;
const CheckWrapper = styled.div`
  svg {
    height: 36px;
    width: 36px;

    path {
      fill: ${({ theme }) => theme.themeColors.positive};
    }
  }
`;

const LessonPageEntryWrapper = styled.div`
  input {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    border-radius: 6px;
    min-height: 100px;
    width: 100%;
    padding: 16px;
  }
`;
const TextEditorWrapper = styled.div`
  padding: 40px 0 0 0;
  input {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    border-radius: 6px;
    line-height: 1.4;
    min-height: 400px;
    width: 100%;
    padding: 16px;
  }
`;

const LessonSelector = ({ onSave }) => {
  const [lessonId, setLessonId] = React.useState('');

  const onChangeId = (e) => {
    setLessonId(e.target.value);
  };

  const handleSave = () => {
    onSave(parseInt(lessonId, 10));
  };
  const handleNext = () => {
    const id = parseInt(lessonId, 10) + 1;
    setLessonId(id.toString(10));
    const data = {
      lessonId: parseInt(id, 10),
    };
    onSave(id.toString(10));
  };
  const handleBack = () => {
    const id = parseInt(lessonId, 10) - 1;
    setLessonId(id.toString(10));
    onSave(id.toString(10));
  };

  return (
    <LessonPageEntryWrapper>
      <Container bottom={8}>
        <Body5>Lesson ID</Body5>
      </Container>
      <input onChange={onChangeId} value={lessonId} style={{ width: '430px' }} />
      <Container top={8} row>
        <SkeletonButton onClick={handleBack}>Prev</SkeletonButton>
        <Container left={8} row>
          <SkeletonButton onClick={handleNext}>Next</SkeletonButton>
        </Container>
        <Container left={8} row>
          <FlatButton onClick={handleSave}>Load Lesson</FlatButton>
        </Container>
      </Container>
    </LessonPageEntryWrapper>
  );
};

const LessonEditor = ({ onSaveToApi, onChange }) => {
  const [lessonId, setLessonId] = React.useState('');
  const [text, setText] = React.useState('');
  const dispatch = useDispatch();
  const getLessonDataFromApi = async (lessonId) => {
    const { data, errorMessage } = await getLesson(lessonId)(dispatch);

    if (data) {
      const {
        lesson_content: { json_lesson_content },
      } = data;
      setText(JSON.stringify(json_lesson_content));
    }
  };

  React.useEffect(() => {
    onChange('contents', text);
  }, [text]);

  const handleLessonSelect = (id) => {
    setLessonId(id);
    getLessonDataFromApi(id);
    onChange('lesson_id', id);
  };
  const onTextChange = (e) => {
    const value = e.target.value;
    setText(value);
  };
  const handleSave = () => {
    onSaveToApi({
      lesson_id: lessonId,
      json_lesson_content: text,
    });
  };
  return (
    <div>
      <LessonSelector onSave={handleLessonSelect} />
      <TextEditorWrapper>
        <textarea value={text} onChange={onTextChange} />
      </TextEditorWrapper>
      <Container top={64}>
        <Container top={8} centerAll>
          <FlatButton fullWidth onClick={handleSave}>
            Save
          </FlatButton>
        </Container>
      </Container>
    </div>
  );
};

export const LearnAndEarnLessonContentEditor = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.learn.previewLesson);

  const [isSubmittingToApi, setIsSubmittingToApi] = React.useState(false);
  const [isSaved, setIsSaved] = React.useState(false);
  const [error, setError] = React.useState(null);

  const onClear = () => {
    dispatch({
      type: learnTypes.reset,
    });
  };

  const onChange = (key, value) => {
    dispatch({
      type: learnTypes.updateLearnPreviewLesson,
      payload: {
        [key]: value,
      },
    });
  };

  const handleAddAnotherPress = () => {
    setIsSaved(false);
  };

  const onSaveToApi = async (form) => {
    window.$('html, body').animate(
      {
        scrollTop: 0,
      },
      300
    );
    setIsSubmittingToApi(true);
    const { error, data } = await postLessonToApi(form);
    setIsSubmittingToApi(false);
    if (!error) {
      setIsSaved(true);
      onClear();
    } else {
      setError('Something went wrong, try again.');
    }
  };

  return (
    <PageWrapper>
      <Container left={16} top={24}>
        <LessonPreview />
      </Container>

      <Page transparentBackground>
        {isSaved ? (
          <Container style={{ textAlign: 'center' }} top={72}>
            <CheckWrapper>
              <CheckCircleFilled />
            </CheckWrapper>
            <Container top={4}>
              <Body6>Saved Edits</Body6>
            </Container>
            <Container top={32}>
              <FlatButton onClick={handleAddAnotherPress}>Edit Another</FlatButton>
            </Container>
          </Container>
        ) : isSubmittingToApi ? (
          <Container top={72}>
            <Container style={{ textAlign: 'center' }}>
              <CheckWrapper>
                <LoadingSpinner />
              </CheckWrapper>
              <Container top={16}>
                <Body6>Saving to server...</Body6>
              </Container>
            </Container>
          </Container>
        ) : (
          <div style={{ width: '600px' }}>
            {error && (
              <ErrorWrapper>
                <Container top={16}>
                  <Body5>{error}</Body5>
                </Container>
              </ErrorWrapper>
            )}
            <Container top={16}>
              <LessonEditor onChange={onChange} onSaveToApi={onSaveToApi} state={state} dispatch={dispatch} />
            </Container>
          </div>
        )}
      </Page>
    </PageWrapper>
  );
};
