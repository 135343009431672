import { useSelector } from 'react-redux';

export const useKarma = () => {
  const karmaTotal = useSelector((state) => state.karma.karmaTotal);
  const hsReachedDailyMax = useSelector((state) => state.karma.reachedDailyMax);

  return {
    karmaTotal,
    hsReachedDailyMax,
  };
};
