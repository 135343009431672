import {
  OPEN_OPTIMIZER_PANEL,
  CLOSE_OPTIMIZER_PANEL,
  RUN_OPTIMIZER,
  NEW_OPTIMIZATION_RECEIVED,
  UPDATE_LAST_OPTIMIZED_TIMESTAMP,
  ADD_OPTIMIZER_ALLOCATION_CONSTRAINTS,
  CREATE_TEMPORARY_OPTIMIZER_ALLOCATION_CONSTRAINT,
  CREATE_OPTIMIZER_ALLOCATION_CONSTRAINT,
  UPDATE_OPTIMIZER_ALLOCATION_CONSTRAINT,
  DELETE_OPTIMIZER_ALLOCATION_CONSTRAINT,
  MOVE_OPTIMIZER_ALLOCATION_CONSTRAINT_TO_TOP,
} from '../constants';
import { consoleError } from '../helpers/devToolHelpers';

const defaultState = {
  loading: true,
  isOptimizerRunning: false,
  latestOptimizerCallTimestamp: null,
  showPanel: false,
  data: {},

  optimizeConstraints: {
    loading: true,
    list: [],
  },
};

export default function optimizerReducer(state = defaultState, action) {
  let optimizerData, optimizeConstraint, optimizeConstraints, currentList, updatedList, optimizerCallTimestamp;

  switch (action.type) {
    case OPEN_OPTIMIZER_PANEL:
      return {
        ...state,
        showPanel: true,
      };

    case CLOSE_OPTIMIZER_PANEL:
      return {
        ...state,
        showPanel: false,
      };

    case RUN_OPTIMIZER:
      optimizerData = action.payload.optimizerData;
      optimizerCallTimestamp = action.payload.optimizerCallTimestamp;

      return {
        ...state,
        isOptimizerRunning: true,
        latestOptimizerCallTimestamp: optimizerCallTimestamp,
        data: {
          ...state.data,
          ...optimizerData,
        },
      };

    case NEW_OPTIMIZATION_RECEIVED:
      optimizerCallTimestamp = action.payload.optimizerCallTimestamp;
      if (state.latestOptimizerCallTimestamp === optimizerCallTimestamp) {
        optimizerData = action.payload.optimizerData;
        return {
          ...state,
          loading: false,
          isOptimizerRunning: false,
          data: {
            ...state.data,
            ...optimizerData,
          },
        };
      } else {
        consoleError('Skipped Optimization: Optimization received but is older than current optimization request');
        return state;
      }

    case UPDATE_LAST_OPTIMIZED_TIMESTAMP:
      optimizerCallTimestamp = action.payload.optimizerCallTimestamp;
      if (state.latestOptimizerCallTimestamp === optimizerCallTimestamp) {
        return {
          ...state,
          loading: false,
          isOptimizerRunning: false,
          data: {
            ...state.data,
            lastUpdated: optimizerCallTimestamp,
          },
        };
      } else {
        consoleError(
          'Skipped Optimization Timestamp Update: Optimization received but is older than current optimization request'
        );
        return state;
      }

    case ADD_OPTIMIZER_ALLOCATION_CONSTRAINTS:
      // can only be used as an init state for now
      optimizeConstraints = action.payload.optimizeConstraints;

      return {
        ...state,
        optimizeConstraints: {
          loading: false,
          list: optimizeConstraints,
        },
      };

    case CREATE_TEMPORARY_OPTIMIZER_ALLOCATION_CONSTRAINT:
      optimizeConstraint = action.payload.optimizeConstraint;
      return {
        ...state,
        optimizeConstraints: {
          ...state.optimizeConstraints,
          list: [optimizeConstraint, ...state.optimizeConstraints.list],
        },
      };

    case CREATE_OPTIMIZER_ALLOCATION_CONSTRAINT:
      // does not support adding more than one at a time
      optimizeConstraint = action.payload.optimizeConstraint;
      optimizeConstraints = Array.isArray(optimizeConstraint) ? optimizeConstraint : [optimizeConstraint];

      currentList = state.optimizeConstraints.list;
      const listFilteredForTemps = currentList.filter((oc) => oc.security_id !== optimizeConstraints[0].security_id);
      updatedList = [...optimizeConstraints, ...listFilteredForTemps];
      return {
        ...state,
        optimizeConstraints: {
          ...state.optimizeConstraints,
          list: updatedList,
        },
      };

    case UPDATE_OPTIMIZER_ALLOCATION_CONSTRAINT:
      optimizeConstraint = action.payload.optimizeConstraint;
      currentList = state.optimizeConstraints.list;
      updatedList = [];

      currentList.forEach((obj) => {
        if (obj.id === optimizeConstraint.id) {
          const updatedConstraint = {
            ...obj,
            ...optimizeConstraint,
          };
          updatedList.push(updatedConstraint);
        } else {
          updatedList.push(obj);
        }
      });

      return {
        ...state,
        optimizeConstraints: {
          ...state.optimizeConstraints,
          list: updatedList,
        },
      };

    case DELETE_OPTIMIZER_ALLOCATION_CONSTRAINT:
      const optimizerConstraintId = action.payload.optimizerConstraintId;
      currentList = state.optimizeConstraints.list;
      const listFilteredForDelete = currentList.filter((oc) => oc.id !== optimizerConstraintId);
      updatedList = listFilteredForDelete;

      return {
        ...state,
        optimizeConstraints: {
          ...state.optimizeConstraints,
          list: updatedList,
        },
      };

    case MOVE_OPTIMIZER_ALLOCATION_CONSTRAINT_TO_TOP:
      const securityId = action.payload.securityId;
      currentList = state.optimizeConstraints.list;
      updatedList = [];

      currentList.forEach((oc) => {
        if (oc.security_id === securityId) {
          updatedList.unshift(oc);
        } else {
          updatedList.push(oc);
        }
      });

      return {
        ...state,
        optimizeConstraints: {
          ...state.optimizeConstraints,
          list: updatedList,
        },
      };

    default:
      return state;
  }
}
