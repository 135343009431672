import React from 'react';
import { LoadingPage } from '../components/page';
import { CardRow, Page, PageCard } from '../components/layout';
import { useDispatch } from 'react-redux';
import { getRewardsData, logMetricsTrackingEvent } from '@src/actions';
import styled from 'styled-components';
import { H5, Body5, Body1, BaseText, defineFontSettings, Copy, Body7 } from '@src/main/lib/nvstr-common-ui.es';
import { useRewardsData } from '../hooks/referrals/useRewardsData';
import RewardRedemptionContainer from '../../containers/Rewards/RewardRedemptionContainer';
import { useCurrentUser, useIsLiveTrading } from '../hooks/user';
import { isCurrentUserAllowLiveTrading } from '@src/helpers/currentUserHelpers';
import { OpenBrokerageAccountBanner } from '../components/banners';
import { pluralizeIfAppropriate } from '@src/helpers/generalHelpers';
import { RewardCard } from '../components/rewards/RewardCard';
import { ROUTES } from '@src/constants/paths';
import { Text } from '../components/text';
import InviteFriends from '../../containers/People/InviteFriends';
import { BaseButtonWrapper, FlatButton } from '../components/buttons';
import GmailImport from '../../containers/ImportConnections/ImportButtons/GmailImport';
import medalImage from '../assets/images/Medal_700px_TB.png';
import { Facebook } from '@src/containers/Share/Facebook';
import { Twitter } from '@src/containers/Share/Twitter';
import { LinkedIn } from '@src/containers/Share/LinkedIn';
import copy from 'copy-to-clipboard';
import Space from '../../components/UI/Space';
import { isInt } from '@src/helpers/numberHelpers';
import { formatForDisplay } from '@src/helpers/displayHelpers';
import RewardsProgress from '../../containers/Rewards/components/RewardsProgress';
import ReferralCashRewardsDisclaimer from '../../containers/UI/Disclaimers/ReferralCashRewards';
import LegacyRewardsPage from '../../containers/Rewards/RewardsPage';
import { useNavigate } from 'react-router-dom';
import { REFERRAL_TYPES } from '@src/utils/tracking/events/referral';
import { TrackingEvents } from '@src/utils/tracking/events';
import {
  REFERRAL_SHARE_LINK_CHANNELS,
  REFERRAL_SHARE_LINK_CONTEXTS,
  useCurrentUserReferral,
} from '../hooks/user/useCurrentUserReferral';
import { PRODUCT_NAME } from '@src/appConfig';
import { Container } from '@src/main/components/ui';
import { createTimeInstance, formatLocalizedDateTime } from '@src/helpers/timeHelpers';
import { formatValueTo, DISPLAY_FORMAT_TYPES } from '@src/main/lib/nvstr-utils.es';

const PageCardSpacer = styled.div`
  padding: 8px;
`;
const OpenBrokerageAccountBannerWrapper = styled.div`
  margin: 16px auto 0 auto;
  max-width: 400px;
  padding: 16px;
  border: 1px dashed ${({ theme }) => theme.themeColors.text};
  border-radius: 5px;
`;

const Heading = () => {
  const rewardsData = useRewardsData();
  const { testGroup, isEligibleForReferralProgram, didReachReferralRewardLimit, isReferralProgramActive } = rewardsData;
  const { testGroups } = testGroup;
  const { headingTestGroup } = testGroups;

  if (!isEligibleForReferralProgram) {
    return null;
  }

  const generateHeadingText = () => {
    if (didReachReferralRewardLimit) {
      return 'Congrats! You reached the maximum reward.';
    }
    if (!isReferralProgramActive) {
      return 'Redeem your rewards.';
    }
    return headingTestGroup === 'a' ? 'Two Heads Are Better Than One. Especially in Investing.' : null;
  };

  const headingText = generateHeadingText();

  if (headingText)
    return (
      <>
        <PageCard transparentBackground>
          <div style={{ textAlign: 'center' }}>{headingTestGroup === 'a' && <H5>{generateHeadingText()}</H5>}</div>
        </PageCard>
        <PageCardSpacer />
      </>
    );

  return null;
};

const RewardValue = styled(Text)`
  text-align: left;
`;
const RewardChance = styled(Text)`
  display: block;
  text-align: left;

  max-width: 68px;
`;

const RewardOpportunity = ({ value, chance }) => {
  const valueFontSettings = defineFontSettings(
    {
      fontSize: '18px',
      lineHeight: '28px',
    },
    {
      fontSize: '18px',
      lineHeight: '28px',
    }
  );
  const chanceFontSettings = defineFontSettings(
    {
      fontSize: '10px',
      lineHeight: '14px',
    },
    {
      fontSize: '10px',
      lineHeight: '14px',
    }
  );

  if (chance === 1) {
    return (
      <div>
        <RewardValue fontSettings={valueFontSettings}>100%</RewardValue>
        <div style={{ paddingTop: '4px' }}>
          <RewardChance fontSettings={chanceFontSettings}>{`chance of at least $${value}`}</RewardChance>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <RewardValue fontSettings={valueFontSettings}>{`$${value}`}</RewardValue>
        <div style={{ paddingTop: '4px' }}>
          <RewardChance fontSettings={chanceFontSettings}>{`1 in ${chance.toString()}`}</RewardChance>
        </div>
        <RewardChance fontSettings={chanceFontSettings}> chance</RewardChance>
      </div>
    );
  }
};
const RewardOddsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LinePosition = styled.div`
  position: relative;
  width: 100%;
  height: 33px;
`;
const Line = styled.div`
  position: absolute;
  top: 0;
  left: -240px;
  right: -240px;

  padding-top: 16px;
  margin-bottom: 16px;
  border-bottom: 2px solid ${({ theme }) => theme.themeColors.appBackground};
`;

const RewardOdds = () => {
  return (
    <RewardOddsRow>
      <RewardOpportunity value={20} chance={20} />
      <RewardOpportunity value={500} chance={2500} />
      <RewardOpportunity value={1000} chance={5000} />
      <RewardOpportunity value={5} chance={1} />
    </RewardOddsRow>
  );
};

const StartRedeemRewardsCard = () => {
  const navigate = useNavigate();
  const { redeemableRewardsCount } = useRewardsData();

  const handleRedeemRewardsClick = () => {
    navigate(ROUTES.REWARD_REDEMPTION.build());

    const properties = {
      'Redeemable Count': redeemableRewardsCount,
    };
    logMetricsTrackingEvent('Clicked Redeem Rewards', properties)();
  };

  return (
    <PageCard>
      <div style={{ textAlign: 'center', paddingTop: '6px' }}>
        <Body1>
          {`You have ${redeemableRewardsCount} new cash ${pluralizeIfAppropriate('reward', redeemableRewardsCount)}!`}
        </Body1>
      </div>

      <div className={'rewards-card-container'}>
        <RewardCard onClick={handleRedeemRewardsClick} />
      </div>
    </PageCard>
  );
};

const InviteFriendsCardContent = styled.div`
  max-width: 376px;
  margin: 0 auto;
`;
const IconHeadingRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
`;
const ImagePosition = styled.div`
  position: relative;
  height: 70px;
  width: 66px;
  min-width: 66px;
  margin-right: 24px;
  overflow: hidden;
`;
const MedalImage = styled.img`
  position: absolute;
  top: -36px;
  left: -45px;
  height: 142px;

  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`;
const InviteFriendsCard = () => {
  const { isEligibleForReferralProgram, testGroup } = useRewardsData();
  const { testGroups } = testGroup;
  const { referralCTAHeadingTestGroup } = testGroups;

  const generateCtaText = () => {
    if (isEligibleForReferralProgram) {
      return referralCTAHeadingTestGroup === 'a' ? (
        <>Invite friends and you both get free cash&nbsp;to&nbsp;invest</>
      ) : (
        <>Invite friends and you both get free cash&nbsp;to&nbsp;invest up to $1,000</>
      );
    } else {
      return referralCTAHeadingTestGroup === 'a' ? (
        <>Invite friends and they get free cash&nbsp;to&nbsp;invest when they make a deposit</>
      ) : (
        <>Invite friends and they get free cash&nbsp;to&nbsp;invest up to $1,000 when they make a deposit</>
      );
    }
  };

  const ctaText = generateCtaText();
  const fontSettings = defineFontSettings(
    {
      fontSize: '16px',
      lineHeight: '24px',
    },
    {
      fontSize: '20px',
      lineHeight: '28px',
    }
  );

  return (
    <PageCard overflowHidden>
      <InviteFriendsCardContent>
        <IconHeadingRow>
          <ImagePosition>
            <MedalImage src={medalImage} />
          </ImagePosition>
          <div>
            <Text fontSettings={fontSettings} bold>
              {ctaText}
            </Text>
          </div>
        </IconHeadingRow>
        <LinePosition>
          <Line />
        </LinePosition>
        <RewardOdds />
      </InviteFriendsCardContent>
    </PageCard>
  );
};
const EmailInputWrapper = styled.div`
  input {
    border: none !important;
    border-bottom: 1px solid ${({ theme }) => theme.themeColors.text} !important;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;

    background: transparent !important;
  }
  .invite-friends-icon {
    right: 0 !important;
  }

  .rounded-edge-button.invite-friends-icon {
    padding: 0;
    height: 28px;
    width: 28px;

    background: ${({ theme }) => theme.themeColors.appBackground} !important;
    border: ${({ theme }) => '1px solid ' + theme.themeColors.appBackground} !important;

    border-radius: 4px;

    transition: all 0.3s;
    outline: none;

    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%) !important;

    * {
      line-height: 1;
      color: ${({ theme }) => theme.themeColors.text} !important;
    }

    :hover {
      opacity: 0.8;
      background: ${({ theme }) => theme.themeColors.text} !important;
      border: ${({ theme }) => '1px solid ' + theme.themeColors.text} !important;

      * {
        color: ${({ theme }) => theme.themeColors.appBackground} !important;
      }
    }
  }
`;
const InviteByEmailCard = () => {
  return (
    <PageCard>
      <div>
        <Body1 bold>Invite by email</Body1>
      </div>
      <EmailInputWrapper>
        <InviteFriends placeholder={'Enter here...'} inviteContext={'Rewards Page'} inputOnly />
      </EmailInputWrapper>
    </PageCard>
  );
};

const SocialButtonWrapper = styled.div`
  padding-top: 6px;
  padding-bottom: 2px;
  .social-button {
    display: inline-block;
  }
`;

const networks = {
  Facebook: 'Facebook',
  Twitter: 'Twitter',
  LinkedIn: 'LinkedIn',
};
const InviteBySocialMediaCard = () => {
  const { encodedInviteLink: encodedShareLink } = useCurrentUserReferral(
    REFERRAL_SHARE_LINK_CONTEXTS.REWARDS_SCREEN,
    REFERRAL_SHARE_LINK_CHANNELS.SOCIAL
  );

  const logSocialClick = (network) => {
    const event = 'Referrals - Click Social Share';
    const properties = {
      Network: network,
      Context: 'Rewards Page',
    };
    logMetricsTrackingEvent(event, properties)();

    const networkReferralTypeConversion = {
      Facebook: REFERRAL_TYPES.facebook,
      Twitter: REFERRAL_TYPES.twitter,
      LinkedIn: REFERRAL_TYPES.linkedIn,
    };
    const networkReferralType = networkReferralTypeConversion[network];
    TrackingEvents.referral.USE_REFERRAL_ACTION.send(networkReferralType);
  };

  const [items] = React.useState([
    <div style={{ textAlign: 'center' }}>
      <SocialButtonWrapper>
        <Facebook url={encodedShareLink} additionalActionOnClick={() => logSocialClick(networks.Facebook)} />
      </SocialButtonWrapper>
      <Body5>Facebook</Body5>
    </div>,
    <div style={{ textAlign: 'center' }}>
      <SocialButtonWrapper>
        <Twitter
          url={encodedShareLink}
          message={
            '.@tornadoinvest is a new, collaborative way to #invest. Plus they will give you up to $1,000 #free cash to invest when you make a deposit. #InvestBetterTogether.'
          }
          additionalActionOnClick={() => logSocialClick(networks.Twitter)}
        />
      </SocialButtonWrapper>
      <Body5>Twitter</Body5>
    </div>,
    <div style={{ textAlign: 'center' }}>
      <SocialButtonWrapper>
        <LinkedIn url={encodedShareLink} additionalActionOnClick={() => logSocialClick(networks.LinkedIn)} />
      </SocialButtonWrapper>
      <Body5>LinkedIn</Body5>
    </div>,
  ]);

  return <CardRow items={items} cardProps={{ height: '100px' }} />;
};
const CopyButtonWrapper = styled.div`
  position: relative;
  padding-bottom: 6px;

  ${BaseButtonWrapper} {
    padding: 0;
    height: 28px;
    width: 28px;

    background: ${({ theme }) => theme.themeColors.appBackground} !important;
    border: ${({ theme }) => '1px solid ' + theme.themeColors.appBackground} !important;
    fill: ${({ theme }) => theme.themeColors.text};

    border-radius: 4px;

    transition: all 0.3s;
    outline: none;

    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%) !important;

    * {
      line-height: 1;
    }

    :hover {
      opacity: 0.8;
      background: ${({ theme }) => theme.themeColors.text} !important;
      border: ${({ theme }) => '1px solid ' + theme.themeColors.text} !important;

      * {
        fill: ${({ theme }) => theme.themeColors.appBackground} !important;
      }
    }
  }

  button {
    svg {
      margin-top: 3px;
    }
  }
`;
const InputMock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  position: relative;
  width: 100%;

  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.themeColors.text};

  color: ${({ theme }) => theme.themeColors.text};
  cursor: default;
`;
const InputValue = styled.input`
  width: 100%;
  background: none !important;
  border: none !important;
  padding: 10px 0 12px 0 !important;
  margin: 0 6px 0 0 !important;
  font-size: 14px;
  line-height: 14px;
  cursor: text;
`;
const CopyIconWrapper = styled.div`
  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.themeColors.text} !important;
    }
  }
`;
const CopyMessageWrapper = styled.div`
  position: absolute;
  top: 37px;
  right: 0px;
  z-index: 100;

  padding: 16px;

  border-radius: 5px;

  background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};
  border: 1px solid ${({ theme }) => theme.themeColors.border};
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%) !important;
`;
const CopyMessage = () => {
  return <CopyMessageWrapper>Copied</CopyMessageWrapper>;
};
const CopyInput = ({ text }) => {
  const inputRef = React.useRef();
  const [showCopyMessage, setShow] = React.useState(false);

  React.useEffect(
    () => {
      if (showCopyMessage) {
        setTimeout(() => {
          setShow(false);
        }, 2000);
      }
    },
    [showCopyMessage]
  );

  const handleClick = () => {
    try {
      /* Select the field */
      inputRef.current.select();

      /* Copy the text inside the text field */
      document.execCommand('copy');

      /* Unselect the field */
      window.getSelection().removeAllRanges();

      logMetricsTrackingEvent('Clicked Share Link Copy', { Context: 'Rewards Page' });
      TrackingEvents.referral.USE_REFERRAL_ACTION.send(REFERRAL_TYPES.shareLink);

      setShow(true);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div>
      <InputMock>
        <InputValue ref={inputRef} value={text} readOnly />
        <CopyButtonWrapper>
          <FlatButton onClick={handleClick}>
            <CopyIconWrapper>
              <Copy />
            </CopyIconWrapper>
          </FlatButton>
          {showCopyMessage && <CopyMessage />}
        </CopyButtonWrapper>
      </InputMock>
    </div>
  );
};
const InviteByShareLinkCard = () => {
  const { inviteLink: shareLink } = useCurrentUserReferral(
    REFERRAL_SHARE_LINK_CONTEXTS.REWARDS_SCREEN,
    REFERRAL_SHARE_LINK_CHANNELS.LINK
  );
  return (
    <PageCard overflow>
      <div>
        <Body1 bold>Share link</Body1>
      </div>
      <CopyInput text={shareLink} />
    </PageCard>
  );
};
const InviteContactsCard = () => {
  return (
    <PageCard transparentBackground>
      <div style={{ textAlign: 'center' }}>
        <GmailImport contactsImport />
      </div>
    </PageCard>
  );
};
const TextRow = styled.div`
  padding-top: 8px;
`;
const RewardsEarnedWrapper = styled.div`
  text-align: left;
`;

const formatRewardValueForDisplay = (value) =>
  isInt(value) ? formatForDisplay(value, 'priceInt') : formatForDisplay(value, 'price');

const RewardTrackingEarnedMessage = () => {
  const { redeemedRewardsTotal, approvedRewardsTotal, didReachMaxPrizeLimit, rewardsRequireACHLink } = useRewardsData();

  if (redeemedRewardsTotal === 0) {
    return null;
  }

  if (approvedRewardsTotal === 0) {
    return (
      <TextRow>
        <Body5 isSmall>{"You'll get"}</Body5>
        <Space />
        <Body5 isSmall>{formatRewardValueForDisplay(redeemedRewardsTotal)}</Body5>
        <Space />
        <Body5 isSmall>
          {`once your account is approved${rewardsRequireACHLink ? ' and you link a valid bank account' : ''}.`}
        </Body5>
      </TextRow>
    );
  }

  return (
    <TextRow>
      <Body5 isSmall>{"You've earned"}</Body5>
      <Space />
      <Body5 isSmall>{`${formatRewardValueForDisplay(approvedRewardsTotal)}`}</Body5>
      <Space />
      <Body5 isSmall>{didReachMaxPrizeLimit ? '.' : 'so far.'}</Body5>
    </TextRow>
  );
};
const RewardTrackingUnearnedMessage = () => {
  const { totalCashToKeep, nextRewardExpirationDateFormattedMMDDYY, rewardsRequiringTradeToKeep } = useRewardsData();
  const hasRewardsToKeep = totalCashToKeep > 0;
  if (hasRewardsToKeep) {
    return (
      <div>
        <Body5 isSmall thin>{`${formatRewardValueForDisplay(totalCashToKeep)} is yours to keep.`}</Body5>
        {rewardsRequiringTradeToKeep > 0 && (
          <div>
            {nextRewardExpirationDateFormattedMMDDYY === null ? (
              <Body5 isSmall thin>
                {`Don't miss out - You need to place a trade to keep the remaining $${rewardsRequiringTradeToKeep}.`}
              </Body5>
            ) : (
              <Body5 isSmall thin>
                {`Don't miss out - You need to place a trade by ${nextRewardExpirationDateFormattedMMDDYY} to keep the remaining $${rewardsRequiringTradeToKeep}.`}
              </Body5>
            )}
          </div>
        )}
      </div>
    );
  }

  if (rewardsRequiringTradeToKeep > 0)
    return (
      <div>
        {nextRewardExpirationDateFormattedMMDDYY === null ? (
          <Body5 isSmall thin>
            {`Don't miss out - You need to place a trade to keep the remaining $${rewardsRequiringTradeToKeep}.`}
          </Body5>
        ) : (
          <Body5 isSmall thin>
            {`Don't miss out - You need to place a trade by ${nextRewardExpirationDateFormattedMMDDYY} to keep the remaining $${rewardsRequiringTradeToKeep}.`}
          </Body5>
        )}
      </div>
    );

  return null;
};
const FundingRewardTrackingEarnedMessage = () => {
  const { fundingRewardsTotalRedeemed, fundingRewardsTotalApproved, rewardsRequireACHLink } = useRewardsData();

  if (fundingRewardsTotalRedeemed === 0) {
    return null;
  }

  if (fundingRewardsTotalApproved === 0) {
    return (
      <TextRow>
        <Body5 isSmall>{"You'll get the funding reward of "}</Body5>
        <Space />
        <Body5 isSmall>{formatRewardValueForDisplay(fundingRewardsTotalRedeemed)}</Body5>
        <Space />
        <Body5 isSmall>
          {`once your account is approved${rewardsRequireACHLink ? ' and you link a valid bank account' : ''}.`}
        </Body5>
      </TextRow>
    );
  }

  return (
    <TextRow>
      <Body5 isSmall thin>
        {"You've earned"}
      </Body5>
      <Space />
      <Body5 isSmall thin>
        {formatRewardValueForDisplay(fundingRewardsTotalApproved)}
      </Body5>
      <Space />
      <Body5 isSmall thin>
        {'in funding rewards so far.'}
      </Body5>
    </TextRow>
  );
};
const RewardsEarned = () => {
  const {
    hasUnearnedRewards,
    redeemedRewardsTotal,
    approvedRewardsTotal,
    fundingRewardsTotalRedeemed,
    fundingRewardsTotalApproved,
  } = useRewardsData();
  const valueFontSettings = defineFontSettings(
    {
      fontSize: '18px',
      lineHeight: '28px',
    },
    {
      fontSize: '36px',
      lineHeight: '48px',
    }
  );

  const rewardsTotal = (redeemedRewardsTotal || 0) + (fundingRewardsTotalRedeemed || 0);
  const nonPendingRewardsTotal = (approvedRewardsTotal || 0) + (fundingRewardsTotalApproved || 0);
  const hasPendingRewards = rewardsTotal !== nonPendingRewardsTotal;
  return (
    <div style={{ paddingTop: '4px' }}>
      <div style={{ padding: '8px 2px' }}>
        <BaseText fontSettings={valueFontSettings} bold>
          {`${formatRewardValueForDisplay(nonPendingRewardsTotal)}`}
        </BaseText>
        {hasPendingRewards && (
          <div>
            <Body5 isSmall isLowContrast thin>
              Some of the rewards may not be accessed until pending actions listed below are completed
            </Body5>
          </div>
        )}
      </div>
      <RewardTrackingEarnedMessage />
      {hasUnearnedRewards && <RewardTrackingUnearnedMessage />}
      <div style={{ paddingTop: '8px' }}>
        <FundingRewardTrackingEarnedMessage />
      </div>
    </div>
  );
};

const RewardsEarnedBreakdown = () => {
  const data = useRewardsData();
  const {
    funding_reward_total_approved,
    referral_reward_total_approved,
    sign_up_reward_total_approved,
    learn_reward_total,
  } = data;

  return (
    <>
      <Container top={16}>
        <Container bottom={8}>
          <Body5>Rewards Earned Breakdown</Body5>
        </Container>
        {sign_up_reward_total_approved > 0 && (
          <Container top={8} left={8} row verticallyCenter>
            <Container width={140}>
              <Body7>Signup Reward:</Body7>
            </Container>
            <Body7>{formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, sign_up_reward_total_approved)}</Body7>
          </Container>
        )}
        {referral_reward_total_approved > 0 && (
          <Container top={8} left={8} row verticallyCenter>
            <Container width={140}>
              <Body7>Referral Rewards:</Body7>
            </Container>
            <Body7>{formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, referral_reward_total_approved)}</Body7>
          </Container>
        )}
        <Container top={8} left={8} row verticallyCenter>
          <Container width={140}>
            <Body7>Learn & Earn Rewards:</Body7>
          </Container>
          <Body7>{formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, learn_reward_total)}</Body7>
        </Container>
        {funding_reward_total_approved > 0 && (
          <Container top={8} left={8} row verticallyCenter>
            <Container width={140}>
              <Body7>Funding Rewards:</Body7>
            </Container>
            <Body7>{formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, funding_reward_total_approved)}</Body7>
          </Container>
        )}
      </Container>
    </>
  );
};

const RewardsDepositBonusSummaryCard = ({ topPadding }) => {
  const { funding_reward_delayed_payout_amount, funding_reward_delayed_payout_at } = useRewardsData();

  const valueFontSettings = defineFontSettings(
    {
      fontSize: '18px',
      lineHeight: '28px',
    },
    {
      fontSize: '36px',
      lineHeight: '48px',
    }
  );

  if (!funding_reward_delayed_payout_amount) {
    return null;
  }

  const displayDate = formatLocalizedDateTime('LL', createTimeInstance(funding_reward_delayed_payout_at * 1000));
  return (
    <Container bottom={topPadding}>
      <PageCard>
        <Container>
          <Body1 bold>Deposit Bonus Program</Body1>
        </Container>
        <Container vertical={8} horizontal={2}>
          <BaseText fontSettings={valueFontSettings} bold>
            {`${formatRewardValueForDisplay(funding_reward_delayed_payout_amount)}`}
          </BaseText>
          {createTimeInstance(funding_reward_delayed_payout_at * 1000).isAfter(createTimeInstance()) && (
            <Container top={4}>
              <Body5 isLowContrast>
                This amount will be paid out on {displayDate}, and available to be withdrawn 1-2 days after that.
              </Body5>
            </Container>
          )}
        </Container>
      </PageCard>
    </Container>
  );
};
const RewardsEarnedSummaryCard = () => {
  const currentUser = useCurrentUser();
  const isLiveTrading = useIsLiveTrading();
  const { isEligibleForReferralProgram } = useRewardsData();

  return (
    <PageCard>
      <div>
        <Body1 bold>Your Rewards Summary</Body1>
      </div>

      {isEligibleForReferralProgram ? (
        <RewardsEarnedWrapper>
          <RewardsEarned />
        </RewardsEarnedWrapper>
      ) : (
        <div style={{ paddingTop: '16px' }}>
          <Body5>{"Sorry, you're not eligible to receive rewards."}</Body5>
        </div>
      )}

      <RewardsEarnedBreakdown />

      {!isLiveTrading && isCurrentUserAllowLiveTrading(currentUser) && (
        <OpenBrokerageAccountBannerWrapper>
          <OpenBrokerageAccountBanner copy={'Open a live brokerage account with us to access your rewards.'} />
        </OpenBrokerageAccountBannerWrapper>
      )}
    </PageCard>
  );
};

const AlternateRewardsPage = () => {
  const dispatch = useDispatch();

  const [isLoading] = React.useState(false);
  const [isRedeemingRewards, setIsRedeemingRewards] = React.useState(false);

  React.useEffect(() => {
    window.document.title = `${PRODUCT_NAME} - Referral Rewards`;
    getRewardsData()(dispatch);

    const properties = {
      // ...this.TEST_GROUP_TRACKING_DATA,
    };
    logMetricsTrackingEvent('View Referral Program', properties)();
  }, []);

  const {
    redeemableRewardsCount,
    isEligibleForReferralProgram,
    didUserReachReferralRewardLimit,
    isReferralProgramActive,
  } = useRewardsData();

  const handleFinishRedeemingRewards = () => {
    setIsRedeemingRewards(false);
  };

  if (isLoading) return <LoadingPage />;

  if (isRedeemingRewards) {
    return (
      <Page transparentBackground>
        <PageCard>
          <RewardRedemptionContainer handleContinue={handleFinishRedeemingRewards} inPage />
        </PageCard>
      </Page>
    );
  }

  return (
    <Page width="720px" transparentBackground>
      <Heading />
      {redeemableRewardsCount > 0 && <StartRedeemRewardsCard />}
      <PageCardSpacer />
      {isReferralProgramActive && <InviteFriendsCard />}
      <PageCardSpacer />
      <InviteByEmailCard />
      <PageCardSpacer />
      <InviteBySocialMediaCard />
      <PageCardSpacer />
      <InviteContactsCard />
      <PageCardSpacer />
      <InviteByShareLinkCard />
      <PageCardSpacer />
      <RewardsDepositBonusSummaryCard topPadding={16} />
      <RewardsEarnedSummaryCard />
      <PageCardSpacer />
      <PageCard>
        {isReferralProgramActive && isEligibleForReferralProgram && !didUserReachReferralRewardLimit && (
          <div style={{ padding: '6px 0 8px 0' }}>
            <RewardsProgress hideHeading />
          </div>
        )}
      </PageCard>
      <PageCardSpacer />
      <PageCard transparentBackground>
        <ReferralCashRewardsDisclaimer />
      </PageCard>
    </Page>
  );
};

const RewardsPage = () => {
  const currentUser = useCurrentUser();
  const showAlternateRewardsPage = currentUser.reward_page_design_test_group === 1;
  return showAlternateRewardsPage ? <AlternateRewardsPage /> : <LegacyRewardsPage />;
};

export default RewardsPage;
