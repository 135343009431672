import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import * as Actions from '../../actions/index';
import PageLoading from '../../components/PageLoading';
import LoadingIcon from '../../components/misc/LoadingIcon';
import AvailableRewards from './components/AvailableRewards';
import { RewardPrizeCard } from './components/RewardPrizeCard';
import ReferralCashRewardsDisclaimer from '../UI/Disclaimers/ReferralCashRewards';
import ShareLink from '../People/ShareLink';
import InviteFriends from '../People/InviteFriends';
import Button from '../../components/buttons/Button';
import Icon from '../../components/misc/Icon';
import {
  referralRewardLimit,
  hasRandomReferralRewardAmount,
  referralRewardRequiresTrade,
  referralRewardRequiresACHLink,
} from '../../helpers/currentUserHelpers';
import { ReloadMessage } from '../Errors/ReloadMessage';
import { createBasicErrorModal } from '../../constants/modals';
import { parseQueryString } from '../../helpers/routerHelpers';
import { H5 } from '../../main/lib/nvstr-common-ui.es';
import { withRouter } from '../../main/utils';
import { REFERRAL_SHARE_LINK_CONTEXTS } from '../../main/hooks/user/useCurrentUserReferral';
import { FlatButton } from '@src/main/components/buttons';
import { Container } from '@src/main/components/ui';

const Heading = () => {
  return (
    <div className={'reward-redemption-heading'}>
      <H5>Redeem your reward</H5>
    </div>
  );
};

class RewardRedemptionContainer extends Component {
  constructor(props) {
    super(props);
    const initialPrizes = [
      {
        id: 0,
        chosen: false,
      },
    ];

    this.INITIAL_PRIZES = initialPrizes;
    this.ANIMATION_LENGTH = 800;

    const { location } = props;
    const { search } = location;
    const query = parseQueryString(search);
    let isReloadingRewardsData = false;
    if (query.rr === '1') {
      isReloadingRewardsData = true;
    }

    this.state = {
      isReloadingRewardsData,

      _showAvailableRewards: true,
      _showPrize: false,
      _showMissedPrizes: false,
      _showOnboardingReferMessagingModal: false,

      _prizes: initialPrizes,
      _prizeResponseData: null,

      _isAnimationFinished: false,

      _showMissedPrizesTimeout: null,
      _selectingPrizeAnimationTimeout: null,

      _wasError: false,
    };
  }

  componentDidMount() {
    document.title = `${PRODUCT_DISPLAY_NAME} - Referral Rewards`;
    const { location } = this.props;
    const { search } = location;
    const query = parseQueryString(search);
    const shouldReloadRewards = query.rr === '1';
    // rr = reload rewards
    if (shouldReloadRewards) {
      this.props.actions.getRewardsData().then((status) => {
        this._prepareRewardRedemption();
        this.setState(() => ({
          isReloadingRewardsData: false,
        }));
        if (!status.ok) {
          const message = 'Something went wrong. Reloading page.';
          this._displayErrorMessageAndReload(message);
        }
      });
    } else {
      this._prepareRewardRedemption();
    }
  }

  render() {
    if (this._isLoading()) {
      return <PageLoading flatStyle />;
    }
    if (!this._isLoaded()) {
      return null;
    }
    if (this._wasError()) {
      return <ReloadMessage renderComponentOnly />;
    }
    if (this.state._showOnboardingReferMessagingModal) {
      return this._renderOnboardingReferMessagingModal();
    }
    return (
      <div>
        {this._renderDismiss()}
        <Heading />
        {this._renderRewardWonMessage()}
        {this._renderMaxPrizeLimitMessage()}
        {this._renderPrizes()}
        {this._renderGettingPrizeMessage()}
        {this._renderRewardsRemainingMessage()}
        {this._renderRedemptionFooterSectionFocusedOnReferral()}
      </div>
    );
  }

  _renderRewardsRemainingMessage = () => {
    if (this._shouldShowPrize()) {
      if (this._hasRedeemableRewards() && !this._didUserReachReferralRewardLimit()) {
        return (
          <Container top={16} fullWidth centerAll>
            <FlatButton onClick={this._handleRedeemAnother}>Redeem Another</FlatButton>
          </Container>
        );
      } else {
        return (
          <Container top={16} fullWidth centerAll>
            <FlatButton onClick={this._handleDismissRedeemingRewardsReferralModal}>Continue</FlatButton>
          </Container>
        );
      }
    }
    return <div className={'after-redemption-action-container'} />;
  };

  _renderGettingPrizeMessage = () => {
    return (
      this._isGettingPrize() && (
        <div className={'getting-reward-message'}>
          <LoadingIcon size="small" style={{ marginRight: '8px' }} />
          <span className={'text'}>Getting Prize</span>
        </div>
      )
    );
  };

  _renderRedemptionFooterSectionFocusedOnReferral = () => {
    return (
      <div className={'show-available-rewards-container'}>
        <AvailableRewards hideOdds={this._doesUserGetFixedReferralRewardAmount()} />
        <ReferralCashRewardsDisclaimer
          rewardLimit={this._returnMaxPrizeLimit()}
          requiresTrade={this._referralRewardRequiresTrade()}
          requiresACHLink={this._referralRewardRequiresACHLink()}
        />
      </div>
    );
  };

  _renderRewardWonMessage = () => {
    if (this._didPrizeExceedLimit(this._returnPrizeAmount()) || this._didUserReachReferralRewardLimit()) {
      return null;
    }
    if (this._shouldShowPrize()) {
      return (
        <div className={'reward-won-message fade-in-up-slow success-text-color'}>
          {`You'll get $${
            !Number.isInteger(this._returnPrizeAmount())
              ? this._returnPrizeAmount().toFixed(2)
              : this._returnPrizeAmount()
          } free!`}
        </div>
      );
    }
    return <div className={'reward-won-message'} />;
  };

  _renderDismiss = () => {
    if (this.props.inPage) {
      return null;
    }
    return (
      <div className={'modal-dismiss'} onClick={this.handleContinue}>
        <Icon icon="fa-times-thin" colorClassName="secondary-text-color" />
      </div>
    );
  };

  _renderPrizes = () => {
    return (
      <div className={'rewards-selection-container'}>
        {this._returnPrizes().map((prizeData) => (
          <RewardPrizeCard
            key={`reward-card-${prizeData.id}`}
            prizeData={prizeData}
            didMakePrizeCardSelection={this.didMakePrizeCardSelection()}
            handleClick={this._handlePrizeCardSelection}
            prizes={this._returnPrizeAmounts()}
            prizeResponseData={this._returnPrizeResponseData()}
            showPrize={this._shouldShowPrize()}
            isSingleCardSelection
          />
        ))}
      </div>
    );
  };

  _renderMaxPrizeLimitMessage = () => {
    if (this._shouldRenderMaxPrizeLimitMessage()) {
      return (
        <div className={'max-prize-limit-message-container in-redemption'}>
          <div className={'text-wrapper-container'}>{this._returnMaxPrizeLimitMessage()}</div>
          <div className={'text-wrapper-container'}>{this._returnMaxPrizeLimitThankYouMessage()}</div>
        </div>
      );
    }
    return null;
  };

  _renderOnboardingReferMessagingModal = () => {
    return (
      <div className={'onboarding-referral-redemption-message-container'}>
        {this._renderDismiss()}

        <div className={'reward-redemption-heading'}>
          <span className={'heading-text'}>{"Invite a friend. You'll both get up to $1,000 cash."}</span>
        </div>

        <div className={'onboarding-referral-message-content-container'}>
          <div className={'message'}>Have friends sign up with your link:</div>
          <ShareLink shareContext={'Rewards Redemption'} />
          <InviteFriends
            shareLinkContext={REFERRAL_SHARE_LINK_CONTEXTS.REWARDS_SCREEN}
            placeholder={"Or enter a friend's email to invite"}
            inviteContext={'Rewards Redemption'}
            hideHeading
          />
        </div>
        <FlatButton onClick={this._handleDismissRedeemingRewardsReferralModal}>Continue</FlatButton>
      </div>
    );
  };

  _returnMaxPrizeLimitMessage = () =>
    `You've reached the maximum total cash reward of $${this._returnMaxPrizeLimit()}. You are no longer eligible to receive additional cash rewards.`;

  _returnMaxPrizeLimitThankYouMessage = () => 'Thank you for helping share intelligent investing.';

  handleContinue = () => {
    this.props.handleContinue();
  };

  _handleDismissRedeemingRewardsReferralModal = () => {
    this.handleContinue();
  };

  _handleRedeemAnother = () => {
    this._prepareRewardRedemption();
    this.setState(() => ({
      _showAvailableRewards: true,
      _showPrize: false,
      _showMissedPrizes: false,
      _prizes: this.INITIAL_PRIZES,
      _prizeResponseData: null,
      _isAnimationFinished: false,
      _showMissedPrizesTimeout: null,
      _selectingPrizeAnimationTimeout: null,
    }));
    this._logRedeemAnother();
  };

  _handlePrizeCardSelection = () => {
    const selectionPrizeCard = this._returnPrizes()[0];
    this._logPrizeCardSelection(selectionPrizeCard);

    this._setSelection(selectionPrizeCard);
    // create animation timeout
    this._createAnimationEndCheckForPrizeData();
    // send post request
    this._redeemReward().then((response) => this._handleRedeemRewardResponse(response));
  };

  _showPrize = () => {
    this.setState(() => ({
      _showPrize: true,
    }));
  };

  _handleRedemptionError = () => {
    this._setWasError();
    return Promise.resolve({ error: true });
  };

  _handleRedeemRewardResponse = (response) => {
    const wasError = !response || !response.data || response.data.status !== 'success';
    if (wasError) {
      return this._handleRedemptionError();
    }

    const prizeData = response.data;
    this._savePrizeDataForAfterAnimation(prizeData);
    // check if animation finished, if so set results and display
    // else wait, queue on animation finish will display results
    if (this.state._isAnimationFinished) {
      this._showPrize();
    }
    this._updateRewardsData();
  };

  _setSelection = (selectionPrizeCard) => {
    const selectedPrizeCard = {
      ...selectionPrizeCard,
      chosen: true,
    };

    const statePrizes = this.state._prizes;
    const updatedPrizes = [];
    if (statePrizes.length === 1) {
      updatedPrizes.push(selectedPrizeCard);
    } else {
      for (let index = 0; index < statePrizes.length; index++) {
        const prize = statePrizes[index];
        if (prize.id === selectedPrizeCard.id) {
          updatedPrizes.push(selectedPrizeCard);
        } else {
          updatedPrizes.push(prize);
        }
      }
    }

    this.setState(() => ({
      _prizes: updatedPrizes,
    }));
  };

  _savePrizeDataForAfterAnimation = (prizeData) => {
    this._setPrizeResponseData(prizeData);
  };

  _createAnimationEndCheckForPrizeData = () => {
    window.setTimeout(() => {
      this._setAnimationFinished();
      if (this._isPrizeAvailable()) {
        // show prize
        this._showPrize();
      }
    }, this.ANIMATION_LENGTH);
  };

  _updateRewardsData = () => {
    const isUpdating = true;
    return this.props.actions.getRewardsData(isUpdating).then((status) => {
      if (!status.ok) {
        const message = 'Something went wrong. Reloading page.';
        this._displayErrorMessageAndReload(message);
      }
    });
  };

  _displayErrorMessageAndReload = (message) => {
    const modal = {
      contentComponent: createBasicErrorModal(message),
      size: 'wide',
      dismissable: false,
    };
    this.props.actions.showModal(modal);
    setTimeout(function() {
      window.location.reload();
    }, 3000);
  };

  _prepareRewardRedemption = () => {
    const redeemableReferralRewardsLeft = this._returnRedeemableReferralRewardsCount();
    const redeemableFundingRewardsLeft = this._returnRedeemableFundingRewardsCount();
    if (redeemableFundingRewardsLeft > 0) {
      Actions.prepareFundingReward().then((responseData) => this._handlePrepareRewardResponse(responseData));
    } else if (redeemableReferralRewardsLeft > 0) {
      Actions.prepareReferralReward().then((responseData) => this._handlePrepareRewardResponse(responseData));
    } else {
      this.handleContinue();
    }
  };

  _redeemReward = () => {
    const redeemableReferralRewardsLeft = this._returnRedeemableReferralRewardsCount();
    const redeemableFundingRewardsLeft = this._returnRedeemableFundingRewardsCount();
    if (redeemableFundingRewardsLeft > 0) {
      return this.props.actions.redeemFundingReward();
    } else if (redeemableReferralRewardsLeft > 0) {
      return this.props.actions.redeemReferralReward();
    } else {
      return this._handleRedemptionError();
    }
  };

  _handlePrepareRewardResponse = (responseData) => {
    const { error } = responseData;
    if (error === 'No rewards to redeem') {
      this.handleContinue();
    } else if (error === 'Already prepared') {
      return null;
    } else if (error) {
      return this._handleRedemptionError();
    }
  };

  _isLoaded = () => this._returnRewardsData().loaded;

  _isLoading = () => this._returnRewardsData().loading || this.state.isReloadingRewardsData;

  _wasError = () => this._returnRewardsData().wasError || this.state._wasError;

  _returnPrizes = () => this.state._prizes;

  _returnPrizeAmount = () => (this._returnPrizeResponseData() ? this._returnPrizeResponseData().reward_amount : 0);

  _returnPrizeAmounts = () => (this._returnPrizeResponseData() ? this._returnPrizeResponseData().possible_amounts : []);

  _isGettingPrize = () =>
    this.didMakePrizeCardSelection() && this.state._isAnimationFinished && !this._isPrizeAvailable();

  _returnPrizeResponseData = () => this.state._prizeResponseData;

  _isPrizeAvailable = () => !!this._returnPrizeResponseData();

  didMakePrizeCardSelection = () => this._returnPrizes().some((prizeData) => this._wasPrizeChosen(prizeData));

  _wasPrizeChosen = (prizeData) => this._returnPrizes()[prizeData.id].chosen;

  _shouldShowPrize = () => this.state._showPrize;

  _returnRewardRedemptionCTAFocusGroup = () => this._returnCurrentUser().sign_up_bonus_panel_follow_up_test_group;

  _shouldRenderMaxPrizeLimitMessage = () => this._didPrizeExceedLimit(this._returnPrizeAmount());

  _returnMaxPrizeLimit = () => referralRewardLimit(this._returnCurrentUser()) || 1000;

  _referralRewardRequiresTrade = () => referralRewardRequiresTrade(this._returnCurrentUser());

  _referralRewardRequiresACHLink = () => referralRewardRequiresACHLink(this._returnCurrentUser());

  _didPrizeExceedLimit = () => this._returnRedeemedRewardsTotal() >= this._returnMaxPrizeLimit();

  _didUserReachReferralRewardLimit = () => this._returnRedeemedRewardsTotal() >= this._returnMaxPrizeLimit();

  _returnRewardsData = () => this.props.rewards;

  _returnRedeemableReferralRewardsCount = () => this._returnRewardsData().referral_rewards_not_redeemed_count || 0;
  _returnRedeemableFundingRewardsCount = () => this._returnRewardsData().funding_rewards_not_redeemed_count || 0;
  _returnRedeemableRewardsCount = () =>
    this._returnRedeemableReferralRewardsCount() + this._returnRedeemableFundingRewardsCount();
  _hasRedeemableRewards = () => this._returnRedeemableRewardsCount() > 0;

  _returnRedeemedRewardsTotal = () => this._returnRewardsData().referral_reward_total_redeemed;

  _returnCurrentUser = () => this.props.currentUser;

  _doesUserGetFixedReferralRewardAmount = () => !hasRandomReferralRewardAmount(this._returnCurrentUser());

  _setWasError = () => {
    this.setState(() => ({
      _wasError: true,
    }));
  };

  _setAnimationFinished = () => {
    this.setState(() => ({
      _isAnimationFinished: true,
    }));
  };

  _setPrizeResponseData = (prizeData) => {
    this.setState(() => ({
      _prizeResponseData: prizeData,
    }));
  };

  _logPrizeCardSelection = (selectionPrizeCard) => {
    const event = 'Selected Reward Prize';
    const properties = {
      Index: selectionPrizeCard.id,
      Type: 'Card',
      'Count of Selection Options': 1,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logRedeemAnother = () => {
    const event = 'Clicked Redeem Another Reward';
    const properties = {
      'Redeemable Count': this._returnRedeemableRewardsCount(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    rewards: state.rewards,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardRedemptionContainer);
export default withRouter(connectedComponent);
