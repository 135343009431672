import React from 'react';
import ChartUp from '../../../assets/SVGS/ChartUp';
import ChartDown from '../../../assets/SVGS/ChartDown';
import styled from 'styled-components';

const Wrapper = styled.div`
  svg {
    fill: ${({ theme, isThoughtTypePro }) =>
      isThoughtTypePro ? theme.themeColors.proColor : theme.themeColors.conColor};
  }
`;

export const ThoughtTypeWithIcon = (props) => {
  const { isThoughtTypePro } = props;

  const thoughtTypeDisplayString = isThoughtTypePro ? 'Pro' : 'Con';
  const thoughtTypeDisplayColorClassName = isThoughtTypePro ? 'pro-text-color' : 'con-text-color';

  const icon = isThoughtTypePro ? <ChartUp /> : <ChartDown />;

  return (
    <Wrapper className={`thought-type-with-icon-container`} isThoughtTypePro={isThoughtTypePro}>
      <span className={`thought-type-icon`}>{icon}</span>
      <span className={`thought-type-string ${thoughtTypeDisplayColorClassName}`}>{thoughtTypeDisplayString}</span>
    </Wrapper>
  );
};
