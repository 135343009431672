import React from 'react';
import { LivePricePollingManager, priceUpdater } from '@src/main/classes/LivePricePollingManager';
import { useDispatch, useSelector } from 'react-redux';
import { updateLivePrices } from '@src/actions';
import { useIsLiveTrading } from '@src/main/hooks/user';
import { MARKET_STATES } from '@src/constants';

export const LivePriceManager = () => {
  const dispatch = useDispatch();
  const isLiveTrading = useIsLiveTrading();
  const marketState = useSelector((state) => state.application.marketState);

  React.useEffect(() => {
    LivePricePollingManager.setIsLiveTrading(isLiveTrading);
  }, [isLiveTrading]);

  React.useEffect(() => {
    if (marketState === MARKET_STATES.open) {
      LivePricePollingManager.setIsMarketOpen(true);
      LivePricePollingManager.setIsInExtendedTrading(false);
    }
    if (marketState === MARKET_STATES.closed) {
      LivePricePollingManager.setIsMarketOpen(false);
      LivePricePollingManager.setIsInExtendedTrading(false);
    }
    if (marketState === MARKET_STATES.afterHours || marketState === MARKET_STATES.preMarket) {
      LivePricePollingManager.setIsMarketOpen(false);
      LivePricePollingManager.setIsInExtendedTrading(true);
    }
  }, [marketState]);

  const savePricesToStore = React.useCallback(
    (priceUpdates) => {
      updateLivePrices(priceUpdates)(dispatch);
    },
    [dispatch]
  );

  React.useEffect(() => {
    const $window = window.$(window);
    LivePricePollingManager.initPoll(savePricesToStore);
    $window.on('blur', () => {
      LivePricePollingManager.cancelPoll();
    });
    $window.on('focus', () => {
      LivePricePollingManager.initPoll(savePricesToStore);
    });
    return () => {
      LivePricePollingManager.cancelPoll();
    };
  }, []);

  React.useEffect(() => {
    priceUpdater.updatePriceUpdateCallback(savePricesToStore);
  }, [savePricesToStore]);

  return null;
};
