import React from 'react';

import SecurityTransaction from './SecurityTransaction';
import FeeTransaction from './FeeTransaction';
import FeeWithSubTypeTransaction from './FeeWithSubTypeTransaction';
import FundingTransaction from './FundingTransaction';

const GeneralTransaction = (props) => {
  const { type } = props;

  // IF ADDING HERE ADD TO APP TOO
  const transactionTypeToComponentNameDict = {
    'Corporate Action': SecurityTransaction,
    'Penny For The Lot': SecurityTransaction,
    Escheatment: SecurityTransaction,
    Liquidation: SecurityTransaction,
    'Position Adjustment': SecurityTransaction,
    'Short Dividend': FeeWithSubTypeTransaction,
    Dividend: FeeWithSubTypeTransaction,
    'Dividend Reinvestment': SecurityTransaction,
    'Interest Charge': FeeWithSubTypeTransaction,
    Interest: FeeWithSubTypeTransaction,
    'Margin Interest': FeeTransaction,
    'ADR Fee': FeeWithSubTypeTransaction,
    'ADR Fee Refund': FeeWithSubTypeTransaction,
    'ACH Return Fee': FeeTransaction,
    'ACH Return Fee Refund': FeeTransaction,
    'ACH NOC Fee': FeeTransaction,
    'ACH NOC Fee Refund': FeeTransaction,
    'Commission Debit': FeeTransaction,
    'Commission Credit': FeeTransaction,
    Reward: FeeTransaction,
    'Reward Correction': FeeTransaction,
    'Reward Reversal': FeeTransaction,
    'Reward Correction Reversal': FeeTransaction,
    'Bonus Reversal': FeeTransaction,
    'Funding Bonus': FeeTransaction,
    'Reorganization Fee': FeeWithSubTypeTransaction,
    Reorganization: FeeWithSubTypeTransaction,
    'Cash In Lieu Charge': FeeWithSubTypeTransaction,
    'Cash In Lieu': FeeWithSubTypeTransaction,
    'Misc. Fee': FeeTransaction,
    'Misc. Refund': FeeTransaction,
    'Paper Document Fee': FeeTransaction,
    'Paper Document Fee Refund': FeeTransaction,
    'Cash Transfer': FeeWithSubTypeTransaction,
    'Cash Transfer Reversal': FeeWithSubTypeTransaction,
    Transfer: SecurityTransaction,
    'Transfer Fee': FeeWithSubTypeTransaction,
    'Transfer Fee Refund': FeeWithSubTypeTransaction,
    'Membership Fee': FundingTransaction, //FeeTransaction, # HACK: use FundingTransaction so we see the status of the ACH transfer when the fee required that
    'Membership Fee Refund': FeeTransaction,
    Split: SecurityTransaction,
    'Reverse Split': SecurityTransaction,
  };
  // IF ADDING HERE ADD TO APP TOO

  const defaultFeeRenderer = FeeTransaction;
  const transactionComponent = transactionTypeToComponentNameDict[type] || defaultFeeRenderer;
  return React.createElement(transactionComponent, props);
};

export default GeneralTransaction;
