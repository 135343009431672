import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../../actions/index';
import GmailIcon from '../../../assets/SVGS/GmailIcon';

import { handleImportClick } from '../../../helpers/importConnectionsHelpers';
import { FlatButton } from '../../../main/components/buttons';
import { Container } from '@src/main/components/ui';

class GmailImportButton extends React.Component {
  render() {
    if (!this._returnGmailImportNetwork()) {
      return null;
    }

    if (this.props.contactsImport) {
      return (
        <Container fullWidth centerAll>
          <FlatButton fullWidth onClick={this._bindNetworkDataToClickHandler(this._returnGmailImportNetwork())}>
            Invite Contacts
          </FlatButton>
        </Container>
      );
    }

    return (
      <div className={`gmail-import-button-container`}>
        {this._renderGmailImportButton()}
        <div className={`import-messaging secondary-text-color`}>Choose who to invite from your Gmail contacts</div>
      </div>
    );
  }

  _renderGmailImportButton = () => {
    return (
      <span
        className={`btn btn-primary-color`}
        onClick={this._bindNetworkDataToClickHandler(this._returnGmailImportNetwork())}
      >
        <span className={`gmail-import-btn-icon`}>
          <GmailIcon />
        </span>
        <span className={`gmail-import-btn-text`}>Gmail Import</span>
      </span>
    );
  };

  _bindNetworkDataToClickHandler = (network) => {
    return () => handleImportClick(network, this.props.actions);
  };

  _returnGmailImportNetwork = () => this._returnImportNetworks().filter((network) => network.network === 'Gmail')[0];

  _returnImportNetworks = (props) => this._returnImportConnectionsStore(props).networks;

  _returnImportConnectionsStore = (props) => (props || this.props).importConnections;
}

const mapStateToProps = (state) => {
  return {
    importConnections: state.importConnections,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GmailImportButton);
