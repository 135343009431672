import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { STATIC_ROUTES } from '../../../constants/paths';
import { Page, Spacing } from '../../components/layout';
import { H3, defineFontSettings, Body1 } from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { Text } from '../../components/text';
import { formatForDisplay } from '../../../helpers/displayHelpers';
import { createTimeInstance } from '../../../helpers/timeHelpers';
import { useRecurringDeposits } from '../../hooks/funding/useRecurringDeposits';
import { cancelRecurringDeposit, getBankAccounts, showModal } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { customParseFloat } from '../../../helpers/numberHelpers';
import { getBankAccountLookup } from '../../../selectors/bankAccount';
import { createBasicErrorModal } from '../../../constants/modals';
import { TrackingEvents } from '../../../utils/tracking/events';

const Content = styled.div`
  h3 {
    margin: 0;
  }
`;
const LeftWrapper = styled.div`
  flex: 1;
`;
const RightWrapper = styled.div``;
const RecurringDepositWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.border};
  padding: 16px;
  display: flex;
  flex-direction: row;
`;
const RecurringDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const ButtonWrapper = styled.div`
  padding-top: 2px;

  button {
    height: 20px;
    padding-right: 0px;
    padding-top: 8px;
    padding-bottom: 4px;
  }
`;

const InlineBankAccountIdentifier = ({ id }) => {
  const bankAccount = useSelector((state) => getBankAccountLookup(state)[id]);
  const { account_type, account_number_ending } = bankAccount;
  const bankAccountType = account_type;
  const bankAccountLastFourDigits = account_number_ending;

  return (
    <>
      {bankAccountType.toUpperCase()} (...{bankAccountLastFourDigits})
    </>
  );
};

const amountFontSettings = defineFontSettings(
  {
    fontSize: '24px',
    lineHeight: '28px',
  },
  {
    fontSize: '24px',
    lineHeight: '28px',
  }
);
const textFontSettings = defineFontSettings(
  {
    fontSize: '14px',
    lineHeight: '28px',
  },
  {
    fontSize: '14px',
    lineHeight: '28px',
  }
);

const RecurringDeposit = ({ account_id, start_date, amount }) => {
  const dispatch = useDispatch();
  const [isRemoving, setIsRemoving] = React.useState(false);

  const handleRemove = async () => {
    if (isRemoving) return;

    setIsRemoving(true);
    TrackingEvents.funding.PRESS_REMOVE_RECURRING_DEPOSIT.send();
    const { ok, errorMessage } = await cancelRecurringDeposit(account_id)(dispatch);
    await getBankAccounts()(dispatch);
    if (ok) {
      TrackingEvents.funding.RECURRING_DEPOSIT_CANCELED_SUCCESS.send();
    } else {
      const modal = {
        contentComponent: createBasicErrorModal(errorMessage),
        dismissable: true,
      };
      showModal(modal)(dispatch);
    }
    setIsRemoving(false);
  };

  return (
    <RecurringDepositWrapper>
      <LeftWrapper>
        <RecurringDateWrapper>
          <Text fontSettings={amountFontSettings} bold>
            {formatForDisplay(createTimeInstance(start_date).add(1, 'day'), 'day')}
          </Text>
          <Spacing row top={3}>
            <Text fontSettings={textFontSettings} lowContrast thin>
              &nbsp;&nbsp;of the month
            </Text>
          </Spacing>
        </RecurringDateWrapper>

        <Spacing top={4}>
          <Text fontSettings={textFontSettings} thin>
            <InlineBankAccountIdentifier id={account_id} />
          </Text>
        </Spacing>
      </LeftWrapper>
      <RightWrapper>
        <div style={{ textAlign: 'right' }}>
          <Text fontSettings={amountFontSettings} bold>
            {formatForDisplay(customParseFloat(amount), 'price')}
          </Text>
        </div>
        <ButtonWrapper>
          {isRemoving ? (
            <FlatButton transparent onClick={() => false} height={20}>
              Canceling
            </FlatButton>
          ) : (
            <FlatButton transparent onClick={handleRemove} height={20}>
              Cancel
            </FlatButton>
          )}
        </ButtonWrapper>
      </RightWrapper>
    </RecurringDepositWrapper>
  );
};

export const ManageRecurringDeposits = () => {
  const recurringDeposits = useRecurringDeposits();
  const navigate = useNavigate();

  const handleCreateClick = () => {
    TrackingEvents.funding.PRESS_START_CREATE_RECURRING_DEPOSIT.send();
    navigate(STATIC_ROUTES.createRecurringDeposits);
  };

  return (
    <Page width={'600px'}>
      <Content>
        <H3>Recurring Deposits</H3>
        {recurringDeposits?.length > 0 ? (
          <Spacing horizontal={0} top={24}>
            {recurringDeposits.map((data, i) => (
              <RecurringDeposit key={`${data.account_id}-${i}-${data.start_date}-${data.amount}`} {...data} />
            ))}
          </Spacing>
        ) : (
          <Spacing horizontal={16} top={24} bottom={24}>
            <Body1>You have no recurring deposits scheduled.</Body1>
            <Spacing top={24}>
              <FlatButton onClick={handleCreateClick}>Create One</FlatButton>
            </Spacing>
          </Spacing>
        )}
      </Content>
    </Page>
  );
};
