import React from 'react';
import { connect } from 'react-redux';
import { PRODUCT_NAME } from '@src/appConfig';
import nLogoAlphaWhite from '../../../images/n_logo_alpha_white.png';
import nLogoAlpha from '../../../images/n_logo_alpha.png';

class NvstrOptionsEarned extends React.PureComponent {
  render() {
    const { hasNvstrOptionsEarned } = this.props;
    if (!hasNvstrOptionsEarned) {
      return null;
    }

    return (
      <div className={'options-earned-section-container'}>
        <div className={'options-earned-heading'}>{`${PRODUCT_NAME} stock options* earned`}</div>
        {this._renderOptionCardsEarned()}
      </div>
    );
  }

  _renderOptionCard = (value, bg, i) => {
    return (
      <div
        key={`option-card-${i}`}
        className={`nvstr-option-card ${value === 5000 ? 'white-out' : ''}`}
        style={{
          background: bg,
        }}
      >
        <div className={`card-shine ${value === 5000 || value === 500 || value === 10 ? 'lighter-streak' : ''}`}></div>
        <div className={'value top'}>
          <div>{value}</div>
        </div>
        <div className={'value bottom'}>
          <div>{value}</div>
        </div>
        <div className={'card-container'}>
          <div className={'option-card-logo'}>
            <img src={value === 5000 ? nLogoAlphaWhite : nLogoAlpha} className={''} alt="nvstr-logo" />
          </div>
        </div>
      </div>
    );
  };

  _renderOptionCardsEarned = () => {
    return <div className={'options-earned-container'}>{this._createOptionCards()}</div>;
  };

  _returnHighestValueSubtrahend = (values, minuend) => {
    let subtrahend = null;
    values.forEach((value) => {
      if (subtrahend === null && minuend - value >= 0) {
        subtrahend = value;
      }
    });
    return subtrahend;
  };

  _returnOptionCardValues = () => [5000, 1000, 500, 100, 50, 10];

  _createCard = (value, i) => {
    let bg;
    if (value === 50) {
      bg = '#D2FBFF';
    } else if (value === 100) {
      bg = '#DCFFD2';
    } else if (value === 500) {
      bg = '#ddd';
    } else if (value === 1000) {
      bg = '#FFE319';
    } else if (value === 5000) {
      bg = '#040404';
    } else {
      bg = '#fff';
    }
    const cardData = {
      value,
      bg,
    };
    return this._renderOptionCard(cardData.value, cardData.bg, i);
  };

  _createOptionCards = () => {
    const optionsCards = [];
    const { nvstrOptionsEarnedTotal } = this.props;
    let unconvertedOptionValueToCard = nvstrOptionsEarnedTotal;
    while (unconvertedOptionValueToCard > 0) {
      const cardValue = this._returnHighestValueSubtrahend(
        this._returnOptionCardValues(),
        unconvertedOptionValueToCard
      );
      unconvertedOptionValueToCard = unconvertedOptionValueToCard - cardValue;
      const card = this._createCard(cardValue, optionsCards.length + 1);
      optionsCards.push(card);
    }
    return optionsCards;
  };
}

const mapStateToProps = (state) => {
  const nvstrOptionsEarnedTotal = state.rewards.options_total_redeemed;
  return {
    nvstrOptionsEarnedTotal,
    hasNvstrOptionsEarned: nvstrOptionsEarnedTotal > 0,
  };
};

export default connect(mapStateToProps)(NvstrOptionsEarned);
