import React from 'react';

const LoadingIcon = (props) => {
  const icon = props.icon || 'circle-loading-spinner';
  const iconSize = {
    xsmall: `${icon}-xsmall`,
    small: `${icon}-small`,
    medium: `${icon}-medium`,
    large: `${icon}-large`,
  };

  const color = { color: props.color || '#2b2b2b' };
  const size = props.size ? iconSize[props.size] : `${icon}-small`;
  const customClass = props.customClass || '';
  const style = props.style || {};

  const iconClass = `fa ${icon} loading-spin default-loading-icon-styles ${size} ${customClass}`;

  return (
    <div
      className={`loading-icon-container ${props.customContainerClass || ''}`}
      style={props.loadingIconContainerStyle || {}}
    >
      <i className={iconClass} style={{ ...style, ...color }}></i>
    </div>
  );
};

export default LoadingIcon;
