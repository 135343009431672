import React from 'react';
import { enums } from '@src/main/lib/nvstr-utils.es';
import { useButtonEvent } from '../../utils/itly/hooks/useButtonEvent';
import { FlatButton } from '@src/main/components/buttons';

const SubmitButton = (props) => {
  const onClick = useButtonEvent(props);
  const text = props.text;
  const additionalClasses = props.customClass ? props.customClass : '';
  return (
    <FlatButton buttonProps={{ type: 'submit' }} className={additionalClasses} onClick={onClick}>
      {text}
    </FlatButton>
  );
};

SubmitButton.defaultProps = {
  buttonType: enums.button_types.button,
};

export default SubmitButton;
