import React from 'react';
import { useSelector } from 'react-redux';
import { logMetricsTrackingEvent } from '../../../actions';
import { Page } from '../../components/layout';
import { FlatButton } from '../../components/buttons';
import { Body5, H5, PlusCircle } from '../../lib/nvstr-common-ui.es';
import styled from 'styled-components';
import { useMembership } from '../../hooks/user/useMembership';
import AnnualMembershipOptIn from '../interstitial/AnnualMembershipOptIn';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { LocationContext } from '../../../context';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import { Container } from '@src/main/components/ui';

const hasAccountNeedingMicrodepositFromState = (state) => {
  const liveAccountsDict = () => state.liveAccount?.liveAccounts?.accounts || {};
  const liveAccountsListById = Object.keys(liveAccountsDict);
  return liveAccountsListById.some((accountId) => liveAccountsDict[accountId].needs_micro_deposit_verification);
};

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const Message = styled.div`
  padding-top: 24px;
`;
const Actions = styled.div`
  padding-top: 8px;
  text-align: center;
`;
const IconWrapper = styled.div`
  width: 36px;
  padding-right: 12px;

  svg {
    height: 16px;
    width: 16px;
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;
const WrapperItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding-bottom: 16px;

  &:last-child {
    padding-bottom: 0;
  }
`;

const AlmostThere = ({ onContinue, onPathChange, onboardingState }) => {
  const isMicroDepositVerification = useSelector(hasAccountNeedingMicrodepositFromState);
  const { isEligible: isAnnualMembershipEligible, isEnrolled: isAnnualMembershipEnrolled } = useMembership();
  const [showAnnualMembershipOptInTest, setShowAnnualMembershipOptInTest] = React.useState(false);

  React.useEffect(() => {
    logMetricsTrackingEvent('View Almost There (Funding) Dialog')();
  }, []);

  const handleContinue = () => {
    logMetricsTrackingEvent('Clicked Continue on Almost There (Funding) Dialog')();

    if (isAnnualMembershipEligible && !isAnnualMembershipEnrolled) {
      setShowAnnualMembershipOptInTest(true);
    } else {
      onContinue(null, onboardingState);
    }
  };

  const handleAnnualMembershipOptInContinue = React.useCallback(async () => {
    onContinue(null, onboardingState);
  }, []);

  if (showAnnualMembershipOptInTest) {
    return <AnnualMembershipOptIn onContinue={handleAnnualMembershipOptInContinue} />;
  }

  const depositLengthOfTimeMessage =
    "After you're approved for live trading, it will take 1-2 business days for the deposit to hit your account.";
  const microDepositNextSteps = (
    <div>
      <WrapperItem>
        <IconWrapper>
          <PlusCircle />
        </IconWrapper>
        <div>
          {
            "The funds will be transferred after you're approved for live trading and we've verified ownership of this account."
          }
        </div>
      </WrapperItem>

      <WrapperItem>
        <IconWrapper>
          <PlusCircle />
        </IconWrapper>
        <div>
          {
            "After you're approved for live trading, verify ownership by entering the 2 small amounts that will appear in your account after 1-2 business days."
          }
        </div>
      </WrapperItem>

      <WrapperItem>
        <IconWrapper>
          <PlusCircle />
        </IconWrapper>
        <div>{'We will also send you an email when the 2 small amounts are visible in your bank account.'}</div>
      </WrapperItem>
    </div>
  );
  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.onboardingAlmostThere}>
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>Transfer Submitted</H5>
          </Heading>
          <div style={{ padding: isMicroDepositVerification ? '5px' : '15px' }} />
          <div className="bullet-point">
            {isMicroDepositVerification ? microDepositNextSteps : depositLengthOfTimeMessage}
          </div>

          <Actions>
            <Message>
              <Body5 bold>{`Let's set up your portfolio and explore ${PRODUCT_DISPLAY_NAME}.`}</Body5>
            </Message>
            <Container top={24} fullWidth centerAll>
              <FlatButton fullWidth onClick={handleContinue}>
                Continue
              </FlatButton>
            </Container>
          </Actions>
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};

export default AlmostThere;
