import React from 'react';
import { MobilePortfolioSummary, DesktopPortfolioSummary } from '../../components/Portfolio';
import { useRefreshPortfolioData } from '../../hooks/portfolio/useRefreshPortfolioData';
import { useCurrentUser, useIsExtendedPricingEnabled, useIsLivePricingEnabled } from '../../hooks/user/useCurrentUser';

import useIsMobileSize from '../../hooks/util/useIsMobileSize';
import {
  useEquityValue,
  usePortfolioCashData,
  usePortfolioDayChange,
  usePortfolioPendingCash,
} from '../../hooks/portfolio/useEquityData';
import { useExtendedTradingHours } from '../../hooks/securities/useExtendedTradingHours';
import { useIsMarketOpen, useIsMarketOpenStatus } from '../../hooks/securities/useIsMarketOpen';
import { useUnfeaturedPositions } from '@src/main/hooks/portfolio/usePositions';

const PortfolioSummary = ({
  isUpdating: _override_isUpdating,
  handleRefreshData: _override_handleRefreshData,
  hideRefresh,
}) => {
  const isMobile = useIsMobileSize();
  const isFeedShowing = !isMobile;
  const currentUser = useCurrentUser();
  const isStreamingPrices = useIsLivePricingEnabled();
  const isExtendedPricingEnabled = useIsExtendedPricingEnabled();
  const { extendedTradingPrefix } = useExtendedTradingHours();

  const { isRefreshing: _hook_isRefreshing, handleRefresh: _hook_handleRefresh } = useRefreshPortfolioData(
    isFeedShowing
  );
  const isRefreshing = _override_isUpdating ? _override_isUpdating : _hook_isRefreshing;
  const handleRefresh = _override_handleRefreshData ? _override_handleRefreshData : _hook_handleRefresh;
  const { value: cashBalance } = usePortfolioCashData();
  const {
    value: portfolioValue,
    missingPricesForSecurityIds,
    isLoading: isPortfolioLoading,
    pricingAsOfTimeDisplay,
    extendedHoursValue,
    extendedHoursValueAsOfTimeDisplay,
  } = useEquityValue();
  const dayChangeData = usePortfolioDayChange();
  const { pendingEquityValue, pendingEquityReasons } = usePortfolioPendingCash();
  const { isOpen, isInExtendedTrading } = useIsMarketOpenStatus();

  const showRefreshButton = hideRefresh ? false : !isStreamingPrices;

  return isMobile ? (
    <MobilePortfolioSummary
      missingPricesForSecurityIds={missingPricesForSecurityIds}
      isPortfolioLoading={isPortfolioLoading}
      isOpen={isOpen}
      isInExtendedTrading={isInExtendedTrading}
      isExtendedPricingEnabled={isExtendedPricingEnabled}
      extendedHoursValue={extendedHoursValue}
      extendedHoursValueAsOfTimeDisplay={extendedHoursValueAsOfTimeDisplay}
      portfolioValue={portfolioValue}
      pendingEquityValue={pendingEquityValue}
      pendingEquityReasons={pendingEquityReasons}
      extendedTradingPrefix={extendedTradingPrefix}
      dayChange={dayChangeData}
      cashBalance={cashBalance}
      isStreamingPrices={isStreamingPrices}
      showRefreshButton={showRefreshButton}
      pricingAsOfTimeDisplay={pricingAsOfTimeDisplay}
      isRefreshing={isRefreshing}
      isLiveTrading={currentUser?.is_live_trading}
      isLiveTradingApproved={currentUser?.is_live_trading_approved}
      handleRefresh={handleRefresh}
    />
  ) : (
    <DesktopPortfolioSummary
      missingPricesForSecurityIds={missingPricesForSecurityIds}
      isPortfolioLoading={isPortfolioLoading}
      isOpen={isOpen}
      isInExtendedTrading={isInExtendedTrading}
      isExtendedPricingEnabled={isExtendedPricingEnabled}
      extendedHoursValue={extendedHoursValue}
      extendedHoursValueAsOfTimeDisplay={extendedHoursValueAsOfTimeDisplay}
      portfolioValue={portfolioValue}
      pendingEquityValue={pendingEquityValue}
      pendingEquityReasons={pendingEquityReasons}
      dayChange={dayChangeData}
      extendedTradingPrefix={extendedTradingPrefix}
      cashBalance={cashBalance}
      isStreamingPrices={isStreamingPrices}
      showRefreshButton={showRefreshButton}
      pricingAsOfTimeDisplay={pricingAsOfTimeDisplay}
      isRefreshing={isRefreshing}
      isLiveTrading={currentUser?.is_live_trading}
      isLiveTradingApproved={currentUser?.is_live_trading_approved}
      handleRefresh={handleRefresh}
    />
  );
};

export default PortfolioSummary;
