import React from 'react';
import { Page, PageCard } from '../components/layout';
import PortfolioSummary from '../containers/Portfolio/PortfolioSummary';
import { useNavigate } from 'react-router-dom';
``;
import { Container, Section, Spacing } from '../components/ui';
import AddToPortfolio from '../../containers/Portfolio/AddToPortfolio';
import { useCurrentIdeas } from '../hooks/user';
import styled from 'styled-components';
import { useIsMobileSize } from '../hooks/util';
import { Body4, Body5, H5 } from '../lib/nvstr-common-ui.es';
import { FlatButton } from '../components/buttons';
import { PortfolioAnalysisTable } from '../containers/Portfolio/PortfolioAnalysisTable';
import { SearchIconWrap } from '@src/containers/UI/Search/components/SearchInput';
import { Optimizer } from '@src/main/containers/Portfolio/Optimizer';
import { useOptimizerData } from '@src/main/hooks/portfolio/useOptimizerData';
import { useHasOptimizerData, useOptimizerStatus } from '../hooks/portfolio/useOptimizerData';
import { runOptimizer } from '@src/actions';
import { useDispatch } from 'react-redux';
import { Refresh } from '../icons';
import { SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../components/ui/SvgLoadingIcon';
import { DOMAIN } from '@src/appConfig';
import { logMetricsTrackingEvent } from '../../actions';

const AddToPortfolioWrapper = styled.div`
  max-width: 320px;

  ${SearchIconWrap} {
    top: 13px;

    svg {
      height: 22px;
      width: 22px;

      path {
        fill: ${({ theme }) => theme.themeColors.text};
      }
    }
  }

  input {
    font-size: 16px;
    line-height: 18px;

    border: 1px solid ${({ theme }) => theme.themeColors.text} !important;
    border-radius: 5px !important;
    padding: 16px 16px 16px 40px;
  }
  .default-search-container {
    max-width: 300px;
  }
  .default-search-container .search-results-container {
    left: 0px;
    right: 0px;
  }
  .default-search-container i.query-icon {
    left: 13px;
    z-index: 5;
  }

  @media (max-width: 900px) {
    .default-search-container .search-results-container {
      left: 0px;
      right: 0px;
    }
  }
`;

const PortfolioAnalysisNotEnabledWrapper = styled.div``;

function PortfolioAnalysisNotEnabled() {
  const navigate = useNavigate();
  const isMobile = useIsMobileSize();

  const handleExplore = () => {
    navigate('/ideas/explore');
  };

  return (
    <Section>
      <PageCard width={isMobile ? '100%' : '1200px'}>
        <PortfolioAnalysisNotEnabledWrapper>
          <H5>Portfolio Analysis</H5>
          <Body4>You do not have any stocks on your watchlist.</Body4>
          <Spacing top={24}>
            <AddToPortfolioWrapper>
              <AddToPortfolio placeholder="Add to Watchlist" showPlus />
            </AddToPortfolioWrapper>
            <Container verticallyCenter left={4}>
              <Body5>Not sure where to start?</Body5>
              <Container>
                <FlatButton transparent onClick={handleExplore}>
                  Start Exploring Here
                </FlatButton>
              </Container>
            </Container>
          </Spacing>
        </PortfolioAnalysisNotEnabledWrapper>
      </PageCard>
    </Section>
  );
}

const OptimizerSummaryHeader = styled.div`
  padding: 24px;
  border-bottom: 1.5px solid ${({ theme }) => theme.themeColors.lowContrastBorder};

  @media (max-width: 840px) {
    padding: 16px 16px;
  }
`;
const DisclaimerWrapper = styled.div`
  padding: 24px;
  border-top: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};

  a {
    color: ${({ theme }) => theme.themeColors.secondaryText};
  }

  @media (max-width: 840px) {
    padding: 16px 16px;
  }
`;
const RefreshOptimizerWrapper = styled.div`
  button {
    padding: 8px 0 8px 8px;

    svg {
      height: 17px;
      width: 17px;
    }
  }

  .mobile {
    display: none;
  }

  @media (max-width: 500px) {
    .non-mobile {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
`;
const OptimizingIconWrapper = styled.div`
  rect {
    fill: ${({ theme }) => theme.themeColors.positive} !important;
  }
`;
const OptimizerSummary = () => {
  const dispatch = useDispatch();
  const isOptimizerRunning = useOptimizerStatus();
  const data = useOptimizerData();

  React.useEffect(() => {
    logMetricsTrackingEvent('View Optimizer', {
      Context: 'Analysis Page',
    })();
    logMetricsTrackingEvent('View Analysis', {})();
  }, []);

  const refreshOptimizer = () => runOptimizer(null, 1800)(dispatch);
  return (
    <>
      <OptimizerSummaryHeader>
        <Container row verticallyCenter spaceBetween>
          <Body4 thin>
            {isOptimizerRunning ? (
              <Container row verticallyCenter>
                <Container centerAll height={20} width={40} right={16}>
                  <OptimizingIconWrapper>
                    <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.bars} />
                  </OptimizingIconWrapper>
                </Container>
                Analyzing your portfolio
              </Container>
            ) : data?.detailed_message ? (
              ''
            ) : (
              'Refresh to see the analysis of your portfolio and watchlist'
            )}
          </Body4>
          <RefreshOptimizerWrapper>
            <FlatButton transparent disabled={isOptimizerRunning} onClick={refreshOptimizer}>
              <div className={'mobile'}>
                <Refresh />
              </div>
              <div className={'non-mobile'}>Refresh</div>
            </FlatButton>
          </RefreshOptimizerWrapper>
        </Container>
      </OptimizerSummaryHeader>
      <Optimizer />
      {true ? (
        <DisclaimerWrapper>
          <Body5 thin isLowContrast>
            Projected return is based on the price targets and other information you have set for the stocks in your
            portfolio and watchlist. Risk is based on recent historical data. Results may vary with each use and over
            time. Conviction levels affect the maximum possible allocation for each position. Any "short term" (6 month)
            idea will be annualized. IMPORTANT: The projections or other information generated by the Analyzer regarding
            the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual investment
            results and are not guarantees of future results. More details can be found{' '}
            <a href={`https://${DOMAIN}/terms_of_service#section8`}>here</a>. The output of the Analyzer is not a
            recommendation. Please consider whether any trade is appropriate for your risk tolerance, investment goals,
            and other preferences before transacting.
          </Body5>
        </DisclaimerWrapper>
      ) : (
        <DisclaimerWrapper>
          <Body4 thin isLowContrast>
            Projected return is based on the price targets and other information in your ideas. Risk is based on recent
            historical data. Results may vary with each use and over time. Conviction levels affect the maximum possible
            allocation for each position. Any "short term" (6 month) idea will be annualized. IMPORTANT: The projections
            or other information generated by the Optimizer regarding the likelihood of various investment outcomes are
            hypothetical in nature, do not reflect actual investment results and are not guarantees of future results.
            More details can be found <a href={`https://${DOMAIN}/terms_of_service#section8`}>here</a>. The output of
            the Optimizer is not a recommendation. You may modify or cancel any or all of the templated orders (orders
            created by clicking the "Trade" button in the Optimized Allocation column). Please consider whether any
            trade is appropriate for your risk tolerance, investment goals, and other preferences before transacting.
          </Body4>
        </DisclaimerWrapper>
      )}
    </>
  );
};

export const PortfolioAnalysis = () => {
  const isMobile = useIsMobileSize();
  const ideas = useCurrentIdeas(null, null);
  const ideasCount = Object.keys(ideas).length;
  const hasOptimizationAvailable = useHasOptimizerData();

  return (
    <Page width={isMobile ? '100%' : '1200px'} transparentBackground noPadding>
      <PortfolioSummary />
      {ideasCount === 0 && <PortfolioAnalysisNotEnabled />}
      {ideasCount > 0 && (
        <Section>
          <PageCard width={isMobile ? '100%' : '1200px'} overflowHidden noPadding>
            <OptimizerSummary />
          </PageCard>
        </Section>
      )}
      {ideasCount > 0 && hasOptimizationAvailable && (
        <Section>
          <PageCard width={isMobile ? '100%' : '1200px'} noPadding>
            <PortfolioAnalysisTable />
          </PageCard>
        </Section>
      )}
    </Page>
  );
};
