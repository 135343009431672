import React from 'react';
import PlaceholderImage from './PlaceholderImage';
import youtubeLogo from '../../assets/images/logos/yt_icon_rgb.png';
import { isUndefinedOrNull } from '../../helpers/generalHelpers';

class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateCounter: 0, // used for force update
      didImageLoadFail: false,
      isImageLoaded: false,
    };
  }

  componentDidMount() {
    this._handleImageLoad();
    this._createImageLoadErrorHandler();
    this._createWindowResizeListener();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.src && this.state.src) {
      const img = this._returnImageNode();
      this._saveOriginalImageSizeToState(img);
    }
  }

  componentWillUnmount() {
    this._cancelImageOnload();
    this._removeWindowResizeListener();
  }

  _createWindowResizeListener = () => {
    window.$(window).on('resize', this._forceUpdate);
  };

  _removeWindowResizeListener = () => {
    const $window = window.$(window);
    $window.off('resize', this._forceUpdate);
  };

  _forceUpdate = () => {
    // used to force component to update by changing state
    this.setState((prevState) => ({
      updateCounter: prevState.updateCounter + 1,
    }));
  };

  render() {
    const { didImageLoadFail } = this.state;
    const { renderPlaceholder, renderVideoButton } = this.props;

    if (didImageLoadFail) return renderPlaceholder ? <PlaceholderImage /> : null;

    return (
      <span className="size-monitor" ref={(el) => (this.Monitor = el)} style={this._returnMonitorContainerStyles()}>
        {renderVideoButton && this._renderVideoButton()}
        {this._renderImage()}
      </span>
    );
  }

  _renderImage = () => {
    if (this._isImageNotHTTPS({ src: this._returnImgSrc() })) {
      return null;
    }
    return (
      <img
        ref={(el) => (this.Image = el)}
        src={this._returnImgSrc()}
        className={'react-image'}
        style={this._isImageLoaded() ? this._returnImageStyling() : {}}
      />
    );
  };

  _renderVideoButton = () => {
    return (
      <>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            zIndex: 1,
          }}
        >
          <div className={'flex-center-child'} style={{ height: '100%', width: '100%' }}>
            <img
              src={youtubeLogo}
              alt="youtube-logo"
              className={'youtube-logo'}
              style={{
                height: 'auto',
                width: '55px',
              }}
            />
          </div>
        </div>
      </>
    );
  };

  _imageLoaded = () => {
    this.setState((prevState) => ({
      isImageLoaded: true,
    }));
  };

  _isImageNotHTTPS = (img) => img.src.indexOf('https') < 0;

  _attemptToGetHTTPSImage = () => {
    const src = this._returnImgSrc();
    const httpsSrc = 'https' + src.slice(4, src.length);
    const httpsImg = document.createElement('img');
    httpsImg.onload = () => {
      this.setState(() => ({
        src: httpsSrc,
      }));
      this._imageLoaded();
      this._handleOnLoadComplete();
      if (this.props.onHTTPSRetrySuccess) {
        this.props.onHTTPSRetrySuccess(httpsSrc);
      }
    };
    httpsImg.onerror = () => {
      this.setState(() => ({
        didImageLoadFail: true,
      }));
      this._handleOnLoadComplete();
    };
    httpsImg.src = httpsSrc;
  };

  _handleOnLoadComplete = () => {
    if (this.props.onLoad) {
      this.props.onLoad();
    }
  };

  _handleImageLoad = () => {
    const img = this._returnImageNode();
    if (img) {
      img.onload = () => {
        this._saveOriginalImageSizeToState(img);
        this._imageLoaded();
        this._handleOnLoadComplete();
      };
    }
    if (this._isImageNotHTTPS({ src: this._returnImgSrc() })) {
      this._attemptToGetHTTPSImage();
    } else {
      this._handleOnLoadComplete();
    }
  };

  _imageFailed = () => {
    this.setState(() => ({
      didImageLoadFail: true,
    }));
  };

  _handleImageLoadError = () => {
    if (this.props.handleImageLoadFailure) {
      this.props.handleImageLoadFailure();
    }
  };

  _createImageLoadErrorHandler = () => {
    const img = this._returnImageNode();
    if (img) {
      img.onerror = () => {
        this._handleImageLoadError();
      };
    }
  };

  _cancelImageOnload = () => {
    const img = this._returnImageNode();
    if (img) {
      img.onload = () => {
        return false;
      };
    }
  };

  _isImageLoaded = () => {
    return this.state.isImageLoaded;
  };

  _saveOriginalImageSizeToState = (img) => {
    const $img = window.$(img);
    const height = $img.height();
    const width = $img.width();
    this.setState((prevState) => ({
      imgHeight: height,
      imgWidth: width,
    }));
  };

  _returnOriginalSizeFromState = () => {
    return {
      height: this.state.imgHeight,
      width: this.state.imgWidth,
    };
  };

  _returnOriginalImageAspectRatio = () => {
    const { width, height } = this._returnOriginalSizeFromState();
    return width / height;
  };

  _returnMonitorContainerStyles = () => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
  };

  _returnImageNode = () => this.Image;

  _returnMonitorSize = () => {
    const monitorNode = this.Monitor;
    const $monitor = window.$(monitorNode);
    return {
      height: $monitor.height(),
      width: $monitor.width(),
    };
  };

  _returnImageContainerSize = () => {
    const monitorNode = this.Monitor;
    const $monitor = window.$(monitorNode);
    return {
      height: $monitor.height(),
      width: $monitor.width(),
    };
  };

  _returnParentClass = () => this.props.parentClass;
  _setParentHeight = (height) => {
    if (this._returnParentClass()) {
      const $imageNode = window.$(this._returnImageNode());
      if ($imageNode[0]) {
        const $parentContainer = window.$($imageNode).closest(this._returnParentClass());
        if ($parentContainer[0]) {
          $parentContainer.height(height);
        }
      }
    }
  };

  _returnImageStyling = () => {
    const size = this._isUsingMaxHeight()
      ? this._calcImageSizeAndPositionWithUnboundHeight()
      : this._calcImageSizeAndPosition();
    if (this._isUsingMaxHeight()) {
      this._setParentHeight(size.height < this._returnMaxHeight() ? size.height : this._returnMaxHeight());
    }

    const doesImageNeedToBeClipped = 'top' in size || 'left' in size;
    let additionalProps = {};
    if (doesImageNeedToBeClipped) {
      const absolutePositioning = {
        position: 'absolute',
        top: `${size.top || 0}px`,
        left: `${size.left || 0}px`,
      };
      additionalProps = absolutePositioning;
    }
    return {
      ...additionalProps,
      height: `${size.height}px`,
      width: `${size.width}px`,
    };
  };

  _resizeImage = () => this.forceUpdate();

  _isImageLargerThanContainer = () => {
    const { width, height } = this._returnImageContainerSize();
    const { imgWidth, imgHeight } = this.state;
    return imgWidth > width || imgHeight > height;
  };

  _isImageWiderThanContainer = () => {
    const { width } = this._returnImageContainerSize();
    const { imgWidth } = this.state;
    return imgWidth > width;
  };

  _isImageTallerThanMaxHeight = () => {
    const { imgHeight } = this.state;
    return imgHeight > this._returnMaxHeight();
  };

  _returnContainerAspectRatio = () => {
    const { width, height } = this._returnImageContainerSize();
    return width / height;
  };

  _calcMissingDimension = (knownDimension, aspectRatio) => {
    const dimensionKey = Object.keys(knownDimension)[0];
    return dimensionKey === 'width' ? knownDimension.width / aspectRatio : knownDimension.height * aspectRatio;
  };

  _isWithinAspectRatioBuffer = () => {
    const buffer = 0.33;
    const containerAspectRatio = this._returnContainerAspectRatio();
    const imageAspectRatio = this._returnOriginalImageAspectRatio();
    return Math.abs(containerAspectRatio - imageAspectRatio) <= buffer;
  };

  _calcImageSizeAndPositionWithUnboundHeight = () => {
    const containerSize = this._returnImageContainerSize();
    const containerWidth = containerSize.width;
    const imageAspectRatio = this._returnOriginalImageAspectRatio();
    const maxHeight = this._returnMaxHeight();

    if (this._isImageWiderThanContainer()) {
      // const { imgWidth } = this.state;
      const height = containerWidth / imageAspectRatio;
      const top = height > maxHeight ? ((height - maxHeight) / 2) * -1 : 0;
      const left = 0;
      return {
        height: height,
        width: containerWidth,
        top,
        left,
      };
    } else {
      const { imgWidth, imgHeight } = this.state;
      // const width = imageAspectRatio * imgHeight;
      const top = 0;
      const left = containerWidth > imgWidth ? `${(containerWidth - imgWidth) / 2}px` : 0;
      return {
        height: imgHeight,
        width: imgWidth,
        top,
        left,
      };
    }
  };

  _calcImageSizeAndPosition = () => {
    const containerSize = this._returnImageContainerSize();
    const containerAspectRatio = this._returnContainerAspectRatio();
    const imageAspectRatio = this._returnOriginalImageAspectRatio();

    if (!this._isImageLargerThanContainer()) {
      const { imgWidth, imgHeight } = this.state;
      return {
        height: imgHeight,
        width: imgWidth,
      };
    }

    // add 15% aspect ratio buffer to clip image with
    if (imageAspectRatio > containerAspectRatio) {
      // width is upper bound

      // if image aspect ratio is within the buffer, instead make the image larger in the container and center it so it clips it to full size
      if (this._isWithinAspectRatioBuffer()) {
        const height = containerSize.height;
        const width = this._calcMissingDimension({ height }, imageAspectRatio);
        const left = Math.abs((width - containerSize.width) / 2) * -1;
        return {
          left,
          height,
          width,
        };
      } else {
        const width = containerSize.width;
        const height = this._calcMissingDimension({ width }, imageAspectRatio);
        return {
          height,
          width,
        };
      }
    } else {
      // height is upper bound

      if (this._isWithinAspectRatioBuffer()) {
        const width = containerSize.width;
        const height = this._calcMissingDimension({ width }, imageAspectRatio);
        const top = Math.abs((height - containerSize.height) / 2) * -1;
        return {
          top,
          height,
          width,
        };
      } else {
        const height = containerSize.height;
        const width = this._calcMissingDimension({ height }, imageAspectRatio);
        return {
          height,
          width,
        };
      }
    }
  };

  _isUsingMaxHeight = () => !isUndefinedOrNull(this._returnMaxHeight());
  _returnMaxHeight = () => this.props.maxHeight;
  _returnImgSrc = () => this.state.src || this.props.src;
}

export default Image;
