import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  & > svg {
    width: 18px;
    height: 18px;
  }
  & g,
  & path {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

function DocumentIcon() {
  return (
    <Wrapper>
      <svg viewBox="0 0 24 24">
        <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
        <path d="M14 17H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z" />
      </svg>
    </Wrapper>
  );
}

export default DocumentIcon;
