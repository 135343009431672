import React from 'react';
import { fetchPerformanceRankingNotificationData } from '../../../actions';

import LoadingIcon from '../../../components/misc/LoadingIcon';
import PerformanceRankingNotification from '../components/types/PerformanceRankingNotification';

class PerformanceRankingModal extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      error: null,
      notificationData: null,
    };
  }

  componentDidMount() {
    this.fetchNotificationData().then((response) => this.handleFetchNotificationDataResponse(response));
  }

  render() {
    return (
      <div className="performance-ranking-modal-container">
        {this.isError() ? this.renderError() : this.isLoading() ? this.renderLoading() : this.renderComponent()}
      </div>
    );
  }

  isLoading = () => this.state.isLoading;

  isError = () => !!this.getErrorMessage();

  getNotificationData = () => this.state.notificationData;

  getPerformanceRankingDateStr = () => this.props.notificationDate;

  getErrorMessage = () => this.state.error;

  renderLoading = () => (
    <div style={{ textAlign: 'center' }}>
      <LoadingIcon icon="fading-3balls" size="small" />
    </div>
  );

  renderComponent = () => {
    return (
      <div>
        <PerformanceRankingNotification notification={this.getNotificationData()} />
        <div style={{ padding: '45px' }}></div>
      </div>
    );
  };

  renderError = () => <div style={{ textAlign: 'center' }}>{this.getErrorMessage()}</div>;

  fetchNotificationData = () => fetchPerformanceRankingNotificationData(this.getPerformanceRankingDateStr());

  handleFetchNotificationDataResponse = (response) => {
    const { ok, data, error } = response;
    if (ok) {
      this.setState((prevState) => ({
        isLoading: false,
        notificationData: data,
      }));
    } else {
      this.setState((prevState) => ({
        isLoading: false,
        error,
      }));
    }
  };
}

export default PerformanceRankingModal;
