import React from 'react';

import FilterExpressionForm from './FilterExpressionForm';
import FilterSelectionList from './FilterSelectionList';

import Button from '../../buttons/Button';
import IconButton from '../../buttons/IconButton';
import { FlatButton } from '../../../main/components/buttons';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
  button {
    padding: 4px 8px;
  }
`;

const CreateFilterProperties = (props) => {
  const formType = props.formType;

  const removeFilter = () => {
    props.removeFilter(props.filter);
  };

  const renderForm = () => {
    const dict = {
      expression: (
        <FilterExpressionForm
          filterName={props.filter.name}
          inputs={props.inputProps.inputs}
          returnInputDefaultProps={props.inputProps.returnInputDefaultProps}
        />
      ),
      selection: (
        <FilterSelectionList
          inputs={props.inputProps.inputs}
          returnInputDefaultProps={props.inputProps.returnInputDefaultProps}
        />
      ),
    };

    return dict[formType];
  };

  const renderCreateButton = () => {
    return (
      <Wrapper>
        <FlatButton
          className="create-filter-btn-container"
          onClick={'id' in props.filter ? props.handleUpdateFilter : props.handleCreateFilter}
        >
          Done
        </FlatButton>
      </Wrapper>
    );
  };

  return (
    <div className="securities-filter-list">
      {renderForm()}
      {renderCreateButton()}
    </div>
  );
};

export default CreateFilterProperties;
