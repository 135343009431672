import React from 'react';
import { connect } from 'react-redux';
import { ROUTES } from '../../constants/paths';

import Page from '../../components/layout/Page';
import RewardRedemptionContainer from './RewardRedemptionContainer';
import { isCurrentUserOnboarding } from '../../helpers/currentUserHelpers';
import { logMetricsTrackingEvent } from '../../actions';
import { parseQueryString } from '../../helpers/routerHelpers';
import { withRouter } from '../../main/utils';

const CONTEXT_STATES = {
  AFTER_FUNDING: 'AFTER_FUNDING',
};

class RewardRedemptionPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.DEFAULT_PAGE_STYLE = {
      maxWidth: 700,
    };

    const { location } = props;
    const { search } = location;
    const query = parseQueryString(search);
    const shouldGotoDashboard = query.dash === '1';
    if (shouldGotoDashboard) {
      this.CONTEXT_STATE = CONTEXT_STATES.AFTER_FUNDING;
    }
  }

  componentWillUnmount() {
    const event = 'Dismiss Rewards Modal';
    const properties = {
      Context: 'Onboarding',
    };
    logMetricsTrackingEvent(event, properties)();
  }

  render() {
    return (
      <Page overrideStyle={this.DEFAULT_PAGE_STYLE}>
        <RewardRedemptionContainer handleContinue={this.handleContinue} inPage />
      </Page>
    );
  }

  handleContinue = () => {
    const { isOnboarding, navigate } = this.props;
    if (!this.CONTEXT_STATE) {
      if (isOnboarding) {
        navigate(ROUTES.FUNDING.build());
      } else {
        navigate(ROUTES.REWARDS.build());
      }
    } else if (this.CONTEXT_STATE === CONTEXT_STATES.AFTER_FUNDING) {
      navigate(ROUTES.DASHBOARD.build());
    }
  };
}

const mapStateToProps = (state) => {
  const currentUser = state.currentUser;
  return {
    isOnboarding: isCurrentUserOnboarding(currentUser),
  };
};

const connectedComponent = connect(mapStateToProps)(RewardRedemptionPage);
export default withRouter(connectedComponent);
