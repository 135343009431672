import React from 'react';
import { BaseText, defineFontSettings } from '../../lib/nvstr-common-ui.es';

export const TableHeading = (props) => {
  const fontSettings = defineFontSettings(
    { fontSize: '12px', lineHeight: '14px', letterSpacing: '0.24px' },
    { fontSize: '12px', lineHeight: '14px', letterSpacing: '0.24px' }
  );
  return (
    <BaseText fontSettings={fontSettings} isLowContrast {...props} thin>
      {props.children}
    </BaseText>
  );
};
