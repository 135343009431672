import React from 'react';
import { useDispatch } from 'react-redux';
import { createTimeInstance, formatLocalizedDateTime } from '../../helpers/timeHelpers';
import { fetchCurrentUserQuickInfo, getIsMarketOpen, getUser, setMarketState } from '../../actions';
import { useIsMarketOpen } from '../hooks/securities/useIsMarketOpen';
import { useCurrentUserId } from '../hooks/user';
import { MARKET_STATES } from '../../constants';

export const preMarketTimeLength = 60 * 60 * 2.5;
export const afterMarketTimeLength = 60 * 60 * 3.5;

const debugFormat = (time) => formatLocalizedDateTime('llll', createTimeInstance(time));

const determineInitialState = (prevCloseTime, nextOpenTime, nextCloseTime) => {
  const currentTime = createTimeInstance().valueOf();

  const willOpenToday =
    formatLocalizedDateTime('api', createTimeInstance()) ===
    formatLocalizedDateTime('api', createTimeInstance(nextOpenTime));
  const didCloseToday =
    formatLocalizedDateTime('api', createTimeInstance()) ===
    formatLocalizedDateTime('api', createTimeInstance(prevCloseTime));

  const preMarketOpensAt = createTimeInstance(nextOpenTime).subtract(preMarketTimeLength, 'seconds').valueOf();
  const afterMarketEndsAt = didCloseToday
    ? createTimeInstance(prevCloseTime).add(afterMarketTimeLength, 'seconds').valueOf()
    : createTimeInstance(nextCloseTime).add(afterMarketTimeLength, 'seconds').valueOf();

  const isNextOpenAfterClose = createTimeInstance(nextOpenTime).isAfter(createTimeInstance(nextCloseTime));
  const isOpen = currentTime >= nextOpenTime || (isNextOpenAfterClose && currentTime < nextCloseTime);

  const isPreMarket = currentTime > preMarketOpensAt && willOpenToday && !isOpen;
  const isAfterMarket = currentTime < afterMarketEndsAt && !isOpen;

  // console.log({
  //   awillOpenToday: willOpenToday,
  //   didCloseToday,
  //   isOpen,
  //   isPreMarket,
  //   isAfterMarket,
  //   isClosed,
  //   isNextOpenAfterClose,
  // });
  // console.log([
  //   'prevCloseTime: ' + debugFormat(prevCloseTime),
  //   'nextOpenTime: ' + debugFormat(nextOpenTime),
  //   'nextCloseTime: ' + debugFormat(nextCloseTime),
  //   'preMarketOpensAt: ' + debugFormat(preMarketOpensAt),
  //   'afterMarketEndsAt: ' + debugFormat(afterMarketEndsAt),
  // ]);

  if (isOpen) return MARKET_STATES.open;
  if (isPreMarket) return MARKET_STATES.preMarket;
  if (isAfterMarket) return MARKET_STATES.afterHours;
  return MARKET_STATES.closed;
};

export const MarketOpenManager = () => {
  const dispatch = useDispatch();
  const currentUserId = useCurrentUserId();
  const { prevCloseTime, nextOpenTime, nextCloseTime } = useIsMarketOpen();

  const [marketState, setLocalMarketState] = React.useState(
    determineInitialState(prevCloseTime, nextOpenTime, nextCloseTime)
  );

  React.useEffect(() => {
    setMarketState(marketState)(dispatch);

    const onFocus = () => {
      getIsMarketOpen()(dispatch);
    };
    window.addEventListener('focus', onFocus);
  }, []);

  React.useEffect(() => {
    const getCurrentUserData = () => {
      const config = {
        include: ['portfolio'],
        isCurrentUser: true,
      };
      return Promise.all([fetchCurrentUserQuickInfo()(dispatch), getUser(currentUserId, config)(dispatch)]);
    };

    console.warn('market state changed: ' + marketState);
    if (marketState === MARKET_STATES.open) {
      getIsMarketOpen()(dispatch);
      getCurrentUserData();
    }
    if (marketState === MARKET_STATES.closed) {
      getIsMarketOpen()(dispatch);
      getCurrentUserData();
    }
  }, [marketState]);

  React.useEffect(() => {
    const checkForEvents = () => {
      const nextState = determineInitialState(prevCloseTime, nextOpenTime, nextCloseTime);
      if (nextState !== marketState) {
        setLocalMarketState(nextState);
        setMarketState(nextState)(dispatch);
      }
    };
    checkForEvents();
    const poll = setInterval(checkForEvents, 1000);
    return () => {
      clearInterval(poll);
    };
  }, [marketState, nextOpenTime, nextCloseTime]);

  return null;
};
