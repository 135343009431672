import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import Icon from '../../components/misc/Icon';
import RenderAfterDelay from '../ExtraFunctionalityComponents/RenderAfterDelay';
import LoadingIcon from '../../components/misc/LoadingIcon';
import styled from 'styled-components';

const Wrapper = styled.div`
  .image-upload-button-style-wrapper {
    margin-right: 4px;
    background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
    // border: 1px solid ${({ theme }) => theme.themeColors.text};

    * {
      color: ${({ theme }) => theme.themeColors.buttonText};
    }
  }
`;
class ImageUpload extends React.Component {
  constructor() {
    super();
    this.state = {
      _submittingImage: false,
      _uploadImage: null,
    };
  }

  render() {
    return (
      <Wrapper className="image-upload-button-container">
        <RenderAfterDelay shouldRender={this.isSubmittingImage()} timeDelayToRender={5} minTimeLengthToRender={900}>
          <div className={`centered-loading-icon`}>
            <LoadingIcon icon="fading-3balls" size="small" />
          </div>
        </RenderAfterDelay>
        <div className="image-upload-button-style-wrapper">
          <label htmlFor="edit-profile-upload-avatar">
            <input
              id="edit-profile-upload-avatar"
              name="edit-profile-upload-avatar"
              type="file"
              className={'invisible-image-upload-btn'}
              onChange={this.handleImageUpload}
            />
          </label>
          <Icon icon={'fa fa-upload'} />
        </div>
      </Wrapper>
    );
  }

  handleImageUpload = (e) => {
    e.preventDefault();
    const image = e.target.files[0];
    this.setState({
      _uploadImage: image,
    });
    const formData = new FormData();
    formData.append('avatar', image);
    this.handleImageSubmit(formData);
  };

  handleImageSubmit = (image) => {
    this.setState({ _submittingImage: true });
    this.props.actions.postAvatarUploadImage(image).then((response) => {
      const wasSuccess = response && response.success;
      if (wasSuccess) {
        this.setState({
          _submittingImage: false,
        });
      } else {
        this.setState({
          _submittingImage: false,
        });
        this._showErrorModal();
      }
    });
  };

  _showErrorModal = () => {
    const message = 'Unable to save avatar. Please try again.';
    const contentComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {message}
      </div>
    );
    this.props.actions.showModal({
      contentComponent,
      size: 'wide',
      dismissable: true,
    });
  };

  isSubmittingImage = () => this.state._submittingImage;
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(ImageUpload);
