import React from 'react';
import { ButtonContainer } from '../buttons/ButtonContainer';

export const AskForConfirmation = (props) => {
  const { message, handleConfirm, handleCancel, customMessage, customButtons } = props;
  return (
    <div className={`ask-for-confirmation-container`}>
      {customMessage || <p className={`confirmation-question  `}>{message}</p>}
      <div className={`action-buttons-container`}>
        <ButtonContainer
          buttonClassName={'btn btn-primary-color btn-small-tall btn-fixed-width-95'}
          handleClick={handleConfirm}
          text={(customButtons && customButtons[0]) || 'Yes'}
        />
        <ButtonContainer
          buttonClassName={'link-btn link-btn-low-contrast btn-small-tall btn-fixed-width-95'}
          text={(customButtons && customButtons[1]) || 'Cancel'}
          handleClick={handleCancel}
        />
      </div>
    </div>
  );
};
