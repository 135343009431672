import { plaidAdapter } from '../adapters/plaidAdapter';

import { SHOW_PLAID_OVERLAY, HIDE_PLAID_OVERLAY } from '../constants';

export const showPlaidOverlay = (additionalActions = {}, additionalConfig = {}) => {
  return function (dispatch) {
    dispatch({
      type: SHOW_PLAID_OVERLAY,
      payload: {
        ...additionalActions,
        ...additionalConfig,
      },
    });
  };
};

export const hidePlaidOverlay = () => {
  return function (dispatch) {
    dispatch({
      type: HIDE_PLAID_OVERLAY,
    });
  };
};

export const getPlaidConfigFromApi = () => {
  return function () {
    return plaidAdapter.getPlaidConfigFromApi().then((response) => {
      if (response?.data && !response?.data?.error) {
        return { ok: true, ...response.data };
      } else {
        return response.data || { error: true };
      }
    });
  };
};

export const getPlaidLinkToken = async () => {
  const response = await plaidAdapter.getPlaidLinkToken();
  if (response?.data && !response?.data?.error) {
    return { ok: true, ...response.data };
  } else {
    return response.data || { error: true };
  }
};
