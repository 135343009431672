import React from 'react';

import { NewConnectionRow } from './NewConnectionRow';
import { Timestamp } from '../../../../UI/Timestamp';

export const ConnectionRequestedNotification = (props) => {
  const { notification } = props;
  const { users, timestamp } = notification;
  const connectionRequestedUserIds = users.map((user) => user.user_id);
  return (
    <div className={`connection-requested-notification-container`}>
      <div className={`new-connection-notification-message`}>
        {`You have ${users.length === 1 ? 'a' : ''} connection request${users.length > 1 ? 's' : ''}`}
      </div>
      <div className={`new-connections-list notification-indent`}>
        <table>
          <tbody>
            {connectionRequestedUserIds.map((userId) => (
              <NewConnectionRow
                key={`new-connection-notif-row-${userId}`}
                userId={userId}
                showConnectionRequestBtn
                hideIgnoreBtn
              />
            ))}
          </tbody>
        </table>
      </div>
      <Timestamp className={'notification-timestamp'} epochTime={timestamp} />
    </div>
  );
};
