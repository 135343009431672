import { PRODUCT_PARTNERS } from '../constants/learn/partners';

export const slugifyLessonName = (name) => {
  if (!name) {
    return '';
  }

  // TODO: refactor to a proper slugification
  let ename = name.toLowerCase().replace('. ', '-');
  ename = ename.replace('.', '');
  ename = ename.replace('.', '');
  ename = ename.replace(' - ', '-');
  ename = ename.replace(' - ', '-');
  ename = ename.replace(' & ', '-');
  ename = ename.replace(', ', '-');
  ename = ename.replace(', ', '-');
  ename = ename.replace(', ', '-');
  ename = ename.replace(',', '-');
  ename = ename.replace(',', '-');
  ename = ename.replace(',', '-');
  ename = ename.replace('&', '');
  ename = ename.replace(' ', '-');
  ename = ename.replace(' ', '-');
  ename = ename.replace(' ', '-');
  ename = ename.replace(' ', '-');
  ename = ename.replace(' ', '-');
  return ename;
};

export const buildPartnerButtonText = (partnerName) => {
  if (partnerName === PRODUCT_PARTNERS.ladder) {
    return 'SEE MY QUOTE';
  }
  if (partnerName === PRODUCT_PARTNERS.bestow) {
    return 'SEE MY QUOTE';
  }
  if (partnerName === PRODUCT_PARTNERS.wagmo) {
    return 'SEE MY QUOTE';
  }
  if (partnerName === PRODUCT_PARTNERS.vspdirect) {
    return 'SEE MY QUOTE';
  }
  if (partnerName === PRODUCT_PARTNERS.upstart) {
    return 'CHECK MY RATE';
  }
  if (partnerName === PRODUCT_PARTNERS.newConstructs) {
    return 'GET ACCESS NOW';
  }
  if (partnerName === PRODUCT_PARTNERS.lemonade) {
    return 'SEE MY QUOTE';
  }
  if (partnerName === PRODUCT_PARTNERS.bankrate) {
    return 'SEE MY OFFERS';
  }
  if (partnerName === PRODUCT_PARTNERS.lemonade) {
    return 'SEE MY QUOTE';
  }
  if (partnerName === PRODUCT_PARTNERS.covered) {
    return 'GET MY QUOTE';
  }
  if (partnerName === PRODUCT_PARTNERS.youNeedABudget) {
    return 'LEARN MORE';
  }
  if (partnerName === PRODUCT_PARTNERS.accreditedDebtRelief) {
    return 'LEARN MORE';
  }
  if (partnerName === PRODUCT_PARTNERS.morningBrew) {
    return 'SUBSCRIBE NOW';
  }
  if (partnerName === PRODUCT_PARTNERS.edX) {
    return 'REGISTER FOR FREE';
  }
  if (partnerName === PRODUCT_PARTNERS.trustAndWill) {
    return 'CREATE YOUR PLAN';
  }
  if (partnerName === PRODUCT_PARTNERS.benzinga) {
    return 'START FREE TRIAL';
  }
  if (partnerName === PRODUCT_PARTNERS.arro) {
    return 'GET STARTED';
  }

  return 'SEE MY QUOTE';
};

export const buildLearnLessonPhraseMessage = (phraseDisplay) => {
  if (!phraseDisplay) return '';
  if (phraseDisplay.slice(0, 2) === '$!') {
    return phraseDisplay.slice(2, phraseDisplay.length);
  }

  const secondWord = phraseDisplay.split(' ')[1];
  const isSecondWordPlural = secondWord && secondWord.slice(-1).toLowerCase() === 's';
  const isPlural =
    phraseDisplay
      .split(' ')[0]
      .slice(-1)
      .toLowerCase() === 's' || isSecondWordPlural;

  return `What ${isPlural ? 'are' : 'is'} ${phraseDisplay}?`;
};

export const buildPartnerExternalURL = (analytics_id, lessonId, partnerName) => {
  if (partnerName === PRODUCT_PARTNERS.ladder) {
    return `https://www.ladderlife.com/tornado?lddr_source=tornado&lddr_name=${'LE' +
      lessonId.toString()}&external_ID=${analytics_id}`;
  }
  if (partnerName === PRODUCT_PARTNERS.arro) {
    return `https://arrofinance.onelink.me/9j7W/o7gk8v2j`;
  }
  if (partnerName === PRODUCT_PARTNERS.wagmo) {
    return `https://wagmo.4qxjn9.net/c/3467601/1424950/11106?subId1=${'LE' +
      lessonId.toString()}&subId2=${analytics_id}`;
  }
  if (partnerName === PRODUCT_PARTNERS.vspdirect) {
    return `http://www.jdoqocy.com/click-100683298-13505679?subId1=${'LE' +
      lessonId.toString()}&external_ID=${analytics_id}`;
  }
  if (partnerName === PRODUCT_PARTNERS.upstart) {
    return `https://upstart.9c65.net/c/3467601/1249505/9083?subId1=${'LE' +
      lessonId.toString()}&subId2=${analytics_id}`;
  }
  if (partnerName === PRODUCT_PARTNERS.newConstructs) {
    return `https://www.newconstructs.com/tornado-email-sign-up/?utm_source=Tornado&utm_medium=Tornado&utm_campaign=TornadoPromotion&utm_content=GoldMembershipInterest&subId1=${'LE' +
      lessonId.toString()}&subId2=${analytics_id}`;
  }
  if (partnerName === PRODUCT_PARTNERS.lemonade) {
    return `https://imp.i146998.net/c/3467601/1033653/11498?subId1=${analytics_id}&sharedid=${lessonId}`;
  }
  if (partnerName === PRODUCT_PARTNERS.galat) {
    return `https://www.wealthfoundry.co/tornado?subId1=${analytics_id}&external_ID=${lessonId}`;
  }
  if (partnerName === PRODUCT_PARTNERS.covered) {
    const medium = lessonId === 300009 ? 'Howmuch' : 'Typesof';
    return `https://getquote.itscovered.com/tornado?utm_source=Auto&utm_medium=${medium}`;
  }
  if (partnerName === PRODUCT_PARTNERS.bankrate) {
    return `https://oc.brcclx.com/t/?lid=26685425&cr=19410&last_updated=1465327559`;
  }
  if (partnerName === PRODUCT_PARTNERS.bestow) {
    return `https://bestow.sjv.io/WDmAWe`;
  }
  if (partnerName === PRODUCT_PARTNERS.youNeedABudget) {
    return 'https://youneedabudget.a4xxmk.net/mgL9Qa';
  }
  if (partnerName === PRODUCT_PARTNERS.accreditedDebtRelief) {
    return 'https://trkac1.com/?a=720&c=15&campaign_id=4067&s1=&s2=&s3=&s4=&s5=&utm_medium=affiliate&utm_source=720&utm_campaign=11-4067';
  }
  if (partnerName === PRODUCT_PARTNERS.morningBrew) {
    return 'https://morningbrew.wvr2.net/c/3467601/1642522/10266';
  }
  if (partnerName === PRODUCT_PARTNERS.edX) {
    return 'https://edx.sjv.io/9gGyJ5';
  }
  if (partnerName === PRODUCT_PARTNERS.trustAndWill) {
    return 'https://trustandwill.sjv.io/c/3467601/1071702/11883';
  }
  if (partnerName === PRODUCT_PARTNERS.benzinga) {
    return 'https://benzinga.sjv.io/5ga172';
  }

  return null;
};

export const buildLadderExternalURL = (analytics_id, lessonId) => {
  return `https://www.ladderlife.com/tornado?lddr_source=tornado&lddr_name=${'LE' +
    lessonId.toString()}&external_ID=${analytics_id}`;
};
