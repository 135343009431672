import { securitiesAdapter } from '../adapters/securitiesAdapter';
import { normalizeSecurityPriceDataResponse } from '../helpers/apiHelpers';

import {
  LOADING_SECURITIES_DATA,
  ADD_SECURITY_FUNDAMENTAL_DATA,
  ADD_SECURITIES,
  START_LOADING_PRICE_HISTORY,
  ADD_PRICE_HISTORY,
  LOADING_SECURITY_ORDER_PRICE_DATA,
  ADD_SECURITY_ORDER_PRICE_DATA,
  ADD_SECURITY_PRICE_DATA,
  LOADING_SECURITY_PRICE_DATA,
  REFRESHING_IDEA_DATA,
  UPDATE_EARNING_ANNOUNCEMENTS,
  livePriceActionTypes,
  ADD_SYMBOL_LOOKUP,
} from '../constants';
import { SECURITY_FILTER_PRESETS } from '@src/main/constants/securityFilterPreset';

export const viewSecurity = (securityId) => {
  return function(dispatch) {
    return securitiesAdapter.viewSecurity(securityId).then((response) => response);
  };
};

export const updateLivePrices = (securitiesPriceData) => {
  return function(dispatch) {
    dispatch({
      type: livePriceActionTypes.updatePrices,
      payload: securitiesPriceData,
    });
  };
};

export const quickFetchSecuritiesData = (securities) => {
  return function(dispatch) {
    dispatch({
      type: LOADING_SECURITIES_DATA,
      payload: securities,
    });
    return securitiesAdapter.quickFetchSecuritiesData(securities).then((response) => {
      if (response) {
        dispatch({
          type: ADD_SECURITIES,
          payload: response && 'securities' in response ? response.securities : [],
        });
        dispatch({
          type: ADD_SYMBOL_LOOKUP,
          payload: response.securities.map((d) => ({ [d.symbol]: d.id })),
        });
        return response;
      } else {
        // TODO: something went wrong
      }
    });
  };
};

export const fetchSecuritiesFundamentalsData = (securities) => {
  return function(dispatch) {
    return securitiesAdapter.fetchSecuritiesFundamentalsData(securities).then((response) => {
      if (response) {
        dispatch({
          type: ADD_SECURITY_FUNDAMENTAL_DATA,
          payload: 'securities' in response ? response.securities : [],
        });
        return response;
      } else {
        // TODO: something went wrong
      }
    });
  };
};

export const fetchSecuritiesPriceHistoryData = (securities, timePeriod) => {
  timePeriod = timePeriod || 'one_year';

  const filterPricesToOneMonthsData = (priceData) => {
    const mostCurrentPrice = priceData[priceData.length - 1];
    const epochSecInMonth = 2680000;
    return priceData.filter((data) => data.time >= mostCurrentPrice.time - epochSecInMonth);
  };

  return function(dispatch) {
    dispatch({
      type: START_LOADING_PRICE_HISTORY,
      payload: {
        securities: securities.map((id) => ({ security_id: id })),
        timePeriod,
      },
    });

    return securitiesAdapter.fetchSecuritiesPriceHistoryData(securities, timePeriod).then((response) => {
      const securitiesPriceData =
        response && 'securities' in response
          ? response.securities
          : securities.map((security_id) => ({
              security_id,
              price_history: [],
            }));
      dispatch({
        type: ADD_PRICE_HISTORY,
        payload: {
          securities: securitiesPriceData,
          timePeriod,
        },
      });
      if (timePeriod === 'one_year') {
        dispatch({
          type: ADD_PRICE_HISTORY,
          payload: {
            securities: securitiesPriceData.map((data) => ({
              security_id: data.security_id,
              price_history: filterPricesToOneMonthsData(data.price_history),
            })),
            timePeriod: 'one_month',
          },
        });
      }
      return response;
    });
  };
};

export const fetchSecuritiesPriceData = (securities, key) => {
  return function(dispatch) {
    dispatch({
      type: LOADING_SECURITY_PRICE_DATA,
      payload: {
        securitiesListById: securities,
      },
    });
    return securitiesAdapter.fetchSecuritiesPriceData(securities, key).then((response) => {
      if (response && response.data && 'securities' in response.data) {
        const securitiesData = response.data.securities;
        const priceQuoteTimeStamp = response.data.price_quotes_info || {};
        const securitiesPriceData = securitiesData.map((securityData) =>
          normalizeSecurityPriceDataResponse(securityData, priceQuoteTimeStamp)
        );
        dispatch({
          type: ADD_SECURITY_PRICE_DATA,
          payload: {
            securitiesPriceData,
          },
        });
        return response;
      } else {
        // TODO: something went wrong
        console.error('Something went wrong getting securities price data');
        return 'Something went wrong getting securities price data';
      }
    });
  };
};

export const fetchSecuritiesOrderPriceData = (securities) => {
  return function(dispatch) {
    dispatch({
      type: LOADING_SECURITY_ORDER_PRICE_DATA,
      payload: {
        securitiesListById: securities,
      },
    });
    return securitiesAdapter.fetchSecuritiesOrderPriceData(securities).then((response) => {
      if (response && response.data && 'securities' in response.data) {
        const securitiesData = response.data.securities;
        const priceQuoteTimeStamp = response.data.price_quotes_info || {};
        const securitiesPriceData = securitiesData.map((securityData) => ({
          ...securityData,
          ...priceQuoteTimeStamp,
        }));
        dispatch({
          type: ADD_SECURITY_ORDER_PRICE_DATA,
          payload: {
            securitiesPriceData,
          },
        });
        return response;
      } else {
        // TODO: something went wrong
        console.error('Something went wrong getting securities price data');
        return 'Something went wrong getting securities price data';
      }
    });
  };
};

export const fetchSimilarCompanies = (securityId) => {
  return function(dispatch) {
    return securitiesAdapter.fetchSimilarCompanies(securityId).then((response) => {
      if (response && 'data' in response && response.data) {
        return response.data;
      } else {
        console.error('Could not get companies to suggest.');
        return { similar_securities: [] };
      }
    });
  };
};

export const findSecuritiesMatchingFilters = (selectionKeys) => {
  return function(dispatch) {
    return securitiesAdapter.findSecuritiesMatchingFilters(selectionKeys).then((response) => {
      const didAjaxSucceed = response && response.data;
      if (didAjaxSucceed) {
        const ideas = response.data.securities;
        return ideas;
      } else {
        return { error: response ? response.error : 'Something went wrong.' };
      }
    });
  };
};

export const getUpcomingEarningsAnnouncements = () => {
  return function(dispatch) {
    return securitiesAdapter.getUpcomingEarningsAnnouncements().then((response) => {
      const { status, data: securities } = response;
      if (status === 200) {
        const securityIds = securities.map((earningAnnouncement) => earningAnnouncement.securityId);
        dispatch({
          type: UPDATE_EARNING_ANNOUNCEMENTS,
          payload: securities,
        });

        dispatch({
          type: ADD_SECURITIES,
          payload: securities,
        });

        return {
          ok: true,
          securityIds,
          securities,
        };
      } else {
        return { ok: false };
      }
    });
  };
};

export const getFilteredSecurities = (filters, count) => {
  return function(dispatch) {
    return securitiesAdapter.getFilteredSecurities(filters, count).then((response) => {
      const { status, data } = response;

      if (status === 200) {
        const { securities } = data;
        const securityIds = securities.map((s) => s.id);

        dispatch({
          type: ADD_SECURITIES,
          payload: securities,
        });

        return {
          ok: true,
          securityIds,
          securities,
        };
      } else {
        return {
          ok: false,
        };
      }
    });
  };
};

export const getStocksYouMayBeInterestedIn = () => {
  return function(dispatch) {
    const presetFilter = SECURITY_FILTER_PRESETS.COLLECTIVE_INTELLIGENCE;
    return getFilteredSecurities(presetFilter.toApi(), 15)(dispatch);
  };
};
