import React from 'react';

import { isFunction, isUndefinedOrNull } from './generalHelpers';
import { handleCurrencyChange } from './formHelpers';

export const isInputValid = (restrictionRegex, value) => (restrictionRegex ? restrictionRegex.test(value) : true);

export const validateInputRequired = function (formClassContext, input) {
  const isRequiredAFunction = isFunction(input.required);
  const isRequired = isRequiredAFunction ? input.required() : input.required;
  if (isRequired) {
    const state = formClassContext.state;
    const formData = state.formData;
    const inputName = input.name;
    const value = formData[inputName];

    if (isUndefinedOrNull(value) || (typeof value === 'string' && value.trim() === '')) {
      return false;
    }
  }
  return true;
};

export const runDefaultValidations = function (formClassContext, input, errors) {
  // TODO: incomplete, was no use case yet

  // check if required
  validateInputRequired(formClassContext, input, errors);

  // is valid context
};

export const handleToggleChange = function (name, value) {
  this.setState((prevState) => ({
    formData: {
      ...prevState.formData,
      [name]: value,
    },
  }));
};

export const handleDropdownChange = function (name, value) {
  this.setState((prevState) => ({
    formData: {
      ...prevState.formData,
      [name]: value,
    },
  }));
};

export const handleNumberInputChange = function (name, value) {
  this.setState((prevState) => ({
    formData: {
      ...prevState.formData,
      [name]: value,
    },
  }));
};

export const returnHandleChangeFunctionForInputs = function (inputType, formClassContext) {
  const changeFuncDict = {
    Toggle: handleToggleChange.bind(formClassContext),
    Dropdown: handleDropdownChange.bind(formClassContext),
    TextNumberField: handleNumberInputChange.bind(formClassContext),
    MoneyField: handleCurrencyChange.bind(formClassContext),
  };
  return (
    changeFuncDict[inputType] ||
    console.error(`Could not find lookup for inputType in returnHandleChangeFunctionForInputs for ${inputType}`)
  );
};

export const returnDefaultGettersSettersForInputs = function (inputType) {
  const formClassContext = this;
  const formData = formClassContext.state.formData || {};
  const errors = formClassContext.state.inputErrors || {};
  const warnings = formClassContext.state.warnings || [];
  const props = formClassContext.props || {};

  return {
    handleChange: returnHandleChangeFunctionForInputs(inputType, formClassContext),
    getErrorMessage: function (name) {
      return errors[name];
    }.bind(formClassContext),
    getInputWarning: function (name) {
      return warnings[name];
    }.bind(formClassContext),
    getValue: function (name) {
      return formData[name];
    }.bind(formClassContext),
    getValueObjectId: function (name) {
      return formData[name][`${name}_type_id`];
    }.bind(formClassContext),
    getObjectId: function (name) {
      return props[name][`${name}_id`];
    }.bind(formClassContext),
  };
};

export const renderInput = function (input, customProps, customKey, children) {
  if (this === undefined) {
    console.error('renderInput must be called with the context of the forms main class component');
    return null;
  }
  if (!input) {
    console.error('Invalid or missing input supplied to renderInput');
    return null;
  }
  if (!input.name) {
    console.error("Input supplied to renderInput is missing 'name' key");
    return null;
  }
  if (!input.type) {
    console.error("Input supplied to renderInput is missing 'type' key");
    return null;
  }
  const _this = this;
  const key = customKey || input.name;
  const props = {
    key,
    name: input.name,
    label: input.label || '',
    required: input.required || false,
    ...returnDefaultGettersSettersForInputs.call(_this, input.typeName),
    ...(input.props || {}),
    ...(customProps || {}),
  };
  return React.createElement(input.type, props, children || null);
};

export const INPUT_REGEX_LOOKUP = {
  FLOAT: new RegExp(/^[0-9\.\-]*$/),
  CALIFORNIA_RULES: new RegExp(/^[a-z-.'\s?]+$/i),
};

export const removeInvalidChar = (value, regex) => {
  return value
    .split('')
    .filter((el) => regex.test(el))
    .join('');
};

export const cleanInputValue = (value, inputRegex) => {
  return removeInvalidChar(value, inputRegex);
};
