import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '../../main/utils';
import {
  logMetricsTrackingEvent,
  openRebalancerPanel,
  createRebalancerOrders,
  getOrders,
  showModal,
  runRebalancer,
  closeRebalancerPanel,
  showActionMessage,
  dismissActionMessage,
} from '../../actions';

import PageLoading from '../../components/PageLoading';
import OptimizerGraph from '../Optimizer/OptimizerPanel/OptimizerGraph';

import RebalancerDisclaimer from './RebalancerDisclaimer';
import RebalancerActions from './RebalancerActions';
import Button from '../../components/buttons/Button';
import LoadingIcon from '../../components/misc/LoadingIcon';
import RebalancerAllocationsTable from './RebalancerAllocationsTable';
import BasicErrorModal from '../../components/modals/BasicErrorModal';

import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import { securityDataFormatTable } from '../../helpers/securitiesHelpers';
import { findUserIdeas } from '../../helpers/ideaHelpers';
import { createBasicErrorModal } from '../../constants/modals';
import { moment, formatLocalizedDateTime } from '../../helpers/timeHelpers';
import { returnPathTo } from '../../constants/paths';
import { parseQueryString } from '../../helpers/routerHelpers';
import { buildRebalancePositionsList } from '../../helpers/rebalancerHelpers';
import { TrackingEvents } from '../../utils/tracking/events';

class RebalancerContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      panelWidth: window.$(window).width() * 0.95,
      showCreatingOrdersMessage: false,

      isEdittingAllocations: false,
      customRebalanceAllocationsLookup: {},
      rebalancerErrorMessage: null,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this._handleResize);
    this._handleResize();

    if (!this._isInPanel()) {
      this._logViewRebalancer();
    }
  }

  componentDidUpdate(prevProps) {
    const { location, rebalancer } = this.props;
    const queryString = location.search;
    const query = parseQueryString(queryString);
    const isRebalancerShowing = rebalancer.showPanel || this._isOnPortfolioAnalysisPage();

    if (query.auto_expand === 'rebalancer' && !isRebalancerShowing && !this._isOnPortfolioAnalysisPage()) {
      this._handleOpenRebalancerPanel();
    }

    if (query.queue_auto_expand === 'rebalancer' && !this.props.rebalancer.showPanel && !('panel' in query)) {
      this._handleOpenRebalancerPanel();
    }

    if (!prevProps.rebalancer.showPanel && this.props.rebalancer.showPanel) {
      this._logViewRebalancer();
      this._handleResize();
    }

    // will not show the message if its the initial optimizer run -> this.isLoading()
    if (!this._isRebalancerRunning(prevProps) && this._isRebalancerRunning() && !this._isLoading()) {
      this._onRebalancerStartRunning();
    }

    if (this._isRebalancerRunning(prevProps) && !this._isRebalancerRunning() && !this._isLoading()) {
      this._onRebalancerEndRunning();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleResize);
  }

  render() {
    if (!this._shouldShowRebalancer() || !this._currentUserHasIdeas() || this._isADifferentPanelOpenOnPage()) {
      return null;
    }

    if (this._isLoading()) {
      return (
        <div className={'initializing-optimizer-panel'}>
          <PageLoading size="medium" flatStyle />
        </div>
      );
    }

    if (!this._isRebalancerDataAvailable()) {
      return (
        <div className={'optimizer-unavailable-message'}>
          <p>The rebalancer is currently unavailable. Check back later.</p>
        </div>
      );
    }

    return (
      <div ref={(el) => (this.panelContainer = el)} className={'react-optimizer'}>
        {this.state.showCreatingOrdersMessage && this.renderCreatingOrdersMessage()}
        <div className={'optimizer-output-container'}>
          {this.renderRebalancerFeedback()}
          <div className={'optimizer-output-panel-container'}>
            <div className={'optimizer-output-panel'}>
              <div className={'graph-panel-container'}>
                <OptimizerGraph
                  usingPercentages={this.isUsingPercentages()}
                  optimizerPortfolioData={this.buildGraphData()}
                  panelWidth={this.state.panelWidth}
                />
                <RebalancerAllocationsTable
                  rebalanceAllocationsLookup={this.getRebalanceAllocationsLookup()}
                  handleRebalancerAllocationChange={this.handleRebalancerAllocationChange}
                  runRebalancer={this.handleRunRebalancer}
                  rebalancerErrorMessage={this.state.rebalancerErrorMessage}
                />
              </div>
            </div>
          </div>
        </div>
        <RebalancerActions
          currentTotalValue={this.getCurrentPortfolioCurrentValue()}
          currentPortfolioStats={this.buildRebalancerData()}
          onCreateOrders={this.handleCreateRebalancerOrders}
        />
        <RebalancerDisclaimer />
      </div>
    );
  }

  renderCreatingOrdersMessage = () => {
    return (
      <div className={'creating-optimized-orders-overlay'}>
        <div className={'creating-optimized-orders component-bg'} style={{ maxWidth: '320px ' }}>
          <LoadingIcon size="medium" />
          <div style={{ marginTop: '4px', marginLeft: '8px', fontSize: '24px' }}>{'Creating Orders'}</div>
        </div>
      </div>
    );
  };

  renderRebalancerFeedback = () => {
    const {
      portfolioStats,
      optimizerMessage,
      currentPortfolioStats,
      optimizedPortfolioStats,
      optimizerLastUpdated,
      rebalancedAllocatedPositionsCount,
    } = this.buildRebalancerData();
    const userHasFunding =
      !isUndefinedOrNull(currentPortfolioStats.total_value) && currentPortfolioStats.total_value > 0;
    const accuracy = portfolioStats.risk_confidence_max_percentile;

    const currentProjectedValue = userHasFunding
      ? securityDataFormatTable.priceNearestDollar(currentPortfolioStats.projected_value)
      : securityDataFormatTable.percentage(currentPortfolioStats.profit_loss_percentage);

    const currentPortfolioMaxRiskConfidenceValue = userHasFunding
      ? securityDataFormatTable.price_fin_notation_no_decimal(currentPortfolioStats.risk_confidence_min)
      : securityDataFormatTable.percentage(currentPortfolioStats.risk_confidence_min_percentage);

    const currentProjectedValueAccuracyMessage = `${accuracy}% chance of being above ${currentPortfolioMaxRiskConfidenceValue.toFixed(
      2
    )}`;

    const optimizedProjectedValue = userHasFunding
      ? securityDataFormatTable.priceNearestDollar(optimizedPortfolioStats.projected_value)
      : securityDataFormatTable.percentage(optimizedPortfolioStats.profit_loss_percentage);

    const optimizedPortfolioMaxRiskConfidenceValue = userHasFunding
      ? securityDataFormatTable.price_fin_notation_no_decimal(optimizedPortfolioStats.risk_confidence_min)
      : securityDataFormatTable.percentage(optimizedPortfolioStats.risk_confidence_min_percentage);

    const optimizedProjectedValueAccuracyMessage =
      rebalancedAllocatedPositionsCount > 0
        ? `${accuracy}% chance of being above ${optimizedPortfolioMaxRiskConfidenceValue.toFixed(2)}`
        : '';

    const lastOptimizerUpdateMessage = optimizerLastUpdated ? `Last updated ${optimizerLastUpdated}` : '';

    return (
      <div className={'optimizer-feedback-container'}>
        <div className={'optimizer-message optimizer-emphasized-text-color'}>{optimizerMessage}</div>

        <div className="portfolio-comparison-container">
          {userHasFunding && (
            <div className={'current-projected-value'}>
              <div className={'text-label secondary-heading-text-color'}>Current Projected Value</div>
              <div className={'value  '}>{currentProjectedValue}</div>
              <div className={'accuracy secondary-text-color'}>{currentProjectedValueAccuracyMessage}</div>
            </div>
          )}

          <div className={'optimized-projected-value'}>
            <div className={'text-label secondary-heading-text-color'}>
              {`Rebalanced Projected ${userHasFunding ? 'Value' : 'Return'}`}
            </div>
            <div className={'value optimizer-emphasized-text-color'}>{optimizedProjectedValue}</div>
            <div className={'accuracy secondary-text-color'}>{optimizedProjectedValueAccuracyMessage}</div>
          </div>
        </div>

        <div className={'optimizer-last-updated secondary-text-color'}>{lastOptimizerUpdateMessage}</div>
      </div>
    );
  };

  handleRebalancerAllocationChange = (securityId, value) => {
    const currentLookup = this.getRebalanceAllocationsLookup();
    this.setState((prevState) => {
      const nextState = {
        isEdittingAllocations: true,
        customRebalanceAllocationsLookup: {
          // sync rebalancer last output
          ...(prevState.isEdittingAllocations ? {} : currentLookup),
          ...prevState.customRebalanceAllocationsLookup,

          [securityId]: value,
        },
      };

      nextState.rebalancerErrorMessage = this.generateAllocationInputError(nextState);
      return nextState;
    });
  };

  generateAllocationInputError = (state) => {
    const ERROR_MESSAGE = 'Allocation percentages can not total to greater than 100%';
    return this.isTotalAllocationTooLarge(state) ? ERROR_MESSAGE : null;
  };

  isTotalAllocationTooLarge = (state = this.state) => {
    const ALLOCATION_LIMIT = 100;
    const { customRebalanceAllocationsLookup } = state;

    let allocationTotal = 0;
    const securityIds = Object.keys(customRebalanceAllocationsLookup);
    securityIds.forEach((id) => {
      const alloc = parseFloat(customRebalanceAllocationsLookup[id]);
      if (!isNaN(alloc)) {
        allocationTotal += alloc;
      }
    });

    return allocationTotal > ALLOCATION_LIMIT;
  };

  getRebalanceAllocationsLookup = () => {
    const { isEdittingAllocations, customRebalanceAllocationsLookup } = this.state;
    const rebalancerAllocationsLookup = this.getRebalancerAllocationsFromLastRun();
    return isEdittingAllocations ? customRebalanceAllocationsLookup : rebalancerAllocationsLookup;
  };

  getRebalancerAllocationsFromLastRun = () => {
    const { rebalancer } = this.props;
    if (!rebalancer.data) {
      return {};
    }

    const { data } = this.props.rebalancer;
    const { rebalanced_positions_by_security_id } = data;
    const rebalancedPositions = rebalanced_positions_by_security_id;

    const lookup = {};
    const rebalancedPositionsSecurityIds = Object.keys(rebalancedPositions);
    rebalancedPositionsSecurityIds.forEach((id) => {
      const position = rebalancedPositions[id];
      lookup[id] = position.allocation_percent;
    });

    return lookup;
  };

  getCurrentPortfolioCurrentValue = () => {
    const { currentPortfolioStats } = this.buildRebalancerData();
    return currentPortfolioStats.total_value || 0;
  };

  _onRebalancerStartRunning = () => {
    if (this._isOnAnalysisPageOrRebalancerPanelOpen() && !this._isADifferentPanelOpenOnPage())
      this._showRebalancerIsRunningActionMessage();
  };

  _onRebalancerEndRunning = () => {
    this._hideRebalancerIsRunningActionMessage();
  };

  handleCreateRebalancerOrders = () => {
    this.showCreatingOrdersMessage();
    this.createRebalancerOrders();
  };

  _handleOpenRebalancerPanel = () => {
    const { dispatch } = this.props;
    openRebalancerPanel()(dispatch);
    this._handleResize();
  };

  _handleClosePanel = () => {
    this._removeAutoExpandFromUrl();
    closeRebalancerPanel()(this.props.dispatch);
  };

  _handleResize = () => {
    const width = window.$(this.panelContainer).width() || window.$(window).width() * 0.95;
    this.setState(() => ({
      panelWidth: width,
    }));
  };

  setRebalancerErrorMessage = (message) => {
    this.setState(() => ({
      rebalancerErrorMessage: message,
    }));
  };

  clearRebalancerErrorMessage = () => this.setRebalancerErrorMessage(null);

  handleRunRebalancer = () => {
    this.clearRebalancerErrorMessage();

    const { customRebalanceAllocationsLookup } = this.state;
    const rebalancePositionsList = buildRebalancePositionsList(customRebalanceAllocationsLookup);
    return this.runRebalancer(rebalancePositionsList);
  };

  runRebalancer = (rebalancePositionsList) => {
    return runRebalancer(rebalancePositionsList)(this.props.dispatch).then((response) => {
      const wasError = !response || !response.data || (response && response.data && response.data.error);
      if (wasError) {
        const errorMessage =
          response && response.data && response.data.error
            ? response.data.error
            : 'Something went wrong. Please try again.';
        this.setRebalancerErrorMessage(errorMessage);
      } else {
        this.setRebalancerErrorMessage(null);
      }
    });
  };

  createRebalancerOrders = () => {
    const { navigate, dispatch } = this.props;
    const { customRebalanceAllocationsLookup } = this.state;

    this.showCreatingOrdersMessage();

    const rebalancePositionsList = buildRebalancePositionsList(customRebalanceAllocationsLookup);
    createRebalancerOrders(rebalancePositionsList)(dispatch).then((response) => {
      const ajaxWasSuccessful = response && response.data && !response.data.warning && !response.data.error;
      if (ajaxWasSuccessful) {
        this._logCreateOrder();
        this.hideCreatingOrdersMessage();
        this._handleClosePanel();
        const handleAfterSuccess = () => {
          // although the response was received there is still some async delay on saving orders
          if (this.props.location.pathname === returnPathTo('trade')) {
            getOrders()(dispatch);
          } else {
            navigate(returnPathTo('trade'));
          }
        };
        setTimeout(handleAfterSuccess, 250);
      } else {
        this.hideCreatingOrdersMessage();

        if (!response || !response.data) {
          // Server error

          const modalMessage = 'Could not create orders. Please try again.';
          const modal = {
            contentComponent: createBasicErrorModal(modalMessage),
            dismissable: true,
          };
          return showModal(modal)(dispatch);
        }

        const errorMessage =
          (response && response.data && response.data.error) ||
          response.data.warning ||
          'Something went wrong, try again.';

        // const noOrdersError = response.data.error === 'Rebalancer did not generate any orders';
        // const noOrdersErrorMsg = 'There are no rebalancer orders to create.'
        // const openOrdersErrorMsg = 'You can\'t rebalance your portfolio right now because you placed orders today that have not yet been filled. Please wait until these orders are filled, or cancel them, then try again.'
        // const errorMessage = noOrdersError ? noOrdersErrorMsg : openOrdersErrorMsg;

        const modal = {
          contentComponent: <BasicErrorModal message={errorMessage} withOkay />,
          dismissable: true,
          size: 'wide',
        };
        return showModal(modal)(dispatch);
      }
    });
  };

  buildRebalancerData = () => {
    const data = this.props.rebalancer.data;
    const portfolioStats = data.portfolio_stats;
    const optimizerMessage = data.detailed_message;
    const currentPortfolioStats = portfolioStats.current;
    const optimizedPortfolioStats = portfolioStats.rebalanced;
    const portfolioDelta = portfolioStats.delta;
    const optimizerLastUpdatedDate = moment(data.lastUpdated);
    const optimizerLastUpdated = `${formatLocalizedDateTime('l', optimizerLastUpdatedDate)} ${formatLocalizedDateTime(
      'LT',
      optimizerLastUpdatedDate
    )}`;
    const optimizedAllocationData = data.rebalanced_positions_by_security_id;
    const rebalancedPositions = optimizedAllocationData;

    const rebalancedPositionsSecurityIds = Object.keys(rebalancedPositions);
    let rebalancedAllocatedPositionsCount = 0;
    rebalancedPositionsSecurityIds.forEach((id) => {
      const position = rebalancedPositions[id];
      if (Math.abs(position.shares) > 0) {
        rebalancedAllocatedPositionsCount += 1;
      }
    });
    return {
      data,
      optimizerMessage,
      portfolioStats,
      currentPortfolioStats,
      optimizedPortfolioStats,
      portfolioDelta,
      optimizerLastUpdated,
      optimizedAllocationData,
      rebalancedPositions,
      rebalancedAllocatedPositionsCount,
    };
  };

  buildGraphData = () => {
    const { portfolioStats, currentPortfolioStats, optimizedPortfolioStats } = this.buildRebalancerData();

    const userHasFunding =
      !isUndefinedOrNull(currentPortfolioStats.total_value) && currentPortfolioStats.total_value > 0;
    const usingPercentages = !userHasFunding;
    const currentPortfolioValue = currentPortfolioStats.total_value || 0;
    const currentProjectedReturn = usingPercentages
      ? currentPortfolioStats.profit_loss_percentage
      : currentPortfolioStats.profit_loss;
    const optimizedProjectedReturn = usingPercentages
      ? optimizedPortfolioStats.profit_loss_percentage
      : optimizedPortfolioStats.profit_loss;
    const currentPortfolioRisk = currentPortfolioStats[usingPercentages ? 'risk_percentage' : 'risk'];
    const optimizedPortfolioRisk = optimizedPortfolioStats[usingPercentages ? 'risk_percentage' : 'risk'];

    return {
      current_profit: currentProjectedReturn,
      current_risk: currentPortfolioRisk,
      current_value: currentPortfolioValue,
      optimized_profit: optimizedProjectedReturn,
      optimized_risk: optimizedPortfolioRisk,
      risk_confidence_multiplier: portfolioStats.risk_confidence_multiplier || 0.674489750196082,
    };
  };

  _shouldShowRebalancer = () => this.props.rebalancer.showPanel || this.props.renderOnPage;

  _isLoading = () => this.props.rebalancer.loading;

  _isRebalancerRunning = (props = this.props) => props.rebalancer.isRebalancerRunning;

  _isRebalancerDataAvailable = () => this.props.rebalancer.data && this.props.rebalancer.data.portfolio_stats;

  isUsingPercentages = () => !this.doesUserHaveFunding();

  doesUserHaveFunding = () => {
    const { currentPortfolioStats } = this.buildRebalancerData();
    if (!currentPortfolioStats) {
      return true;
    }
    const { total_value } = currentPortfolioStats;
    return !isUndefinedOrNull(total_value) && total_value > 0;
  };

  showCreatingOrdersMessage = () => {
    window.$('.optimizer-panel-container .panel-container').css('overflow', 'hidden');
    this.setState(() => ({
      showCreatingOrdersMessage: true,
    }));
  };

  hideCreatingOrdersMessage = () => {
    window.$('.optimizer-panel-container .panel-container').css('overflow', 'auto');
    this.setState(() => ({
      showCreatingOrdersMessage: false,
    }));
  };

  _removeAutoExpandFromUrl = () => {
    const navigate = this.props.navigate;
    const location = this.props.location;
    const queryString = location.search;
    const query = parseQueryString(queryString);

    const removeKeyFromObjectIfValue = (keyToRemove, value, obj) => {
      const keys = Object.keys(query);
      const filteredKeys = keys.filter((key) => key !== keyToRemove && value !== obj[key]);
      const filteredObject = {};
      filteredKeys.forEach((key) => {
        filteredObject[key] = obj[key];
      });
      return filteredObject;
    };

    const filteredQuery = removeKeyFromObjectIfValue('auto_expand', 'rebalancer', query);
    navigate(filteredQuery);
  };

  _returnRebalancerIsRunningActionMessageData = () => ({
    id: 'optimizer-is-running',
    type: 'optimizer',
    isDismissable: false,
  });

  _showRebalancerIsRunningActionMessage = () => {
    showActionMessage(this._returnRebalancerIsRunningActionMessageData())(this.props.dispatch);
  };

  _hideRebalancerIsRunningActionMessage = () => {
    dismissActionMessage(this._returnRebalancerIsRunningActionMessageData())(this.props.dispatch);
  };

  _isOnAnalysisPageOrRebalancerPanelOpen = () =>
    this.props.location.pathname === '/portfolio' || this._shouldShowRebalancer();

  _isADifferentPanelOpenOnPage = () => 'panel' in parseQueryString(window.location.search);

  _isOnPortfolioAnalysisPage = () => this.props.location.pathname === returnPathTo('portfolioAnalysis');

  _currentUserHasIdeas = () => {
    const { currentUserId, ideaList } = this.props;
    const ideas = findUserIdeas(currentUserId, ideaList);
    return ideas && ideas.length > 0;
  };

  buildEqualWeightPortfolioAllocationsLookup = () => {
    const TOTAL_ALLOCATION_PERCENT = 100;

    const { portfolio } = this.props;
    const { positions } = portfolio;
    const lookup = {};
    const securityIds = Object.keys(positions);
    const allocationPercent = TOTAL_ALLOCATION_PERCENT / securityIds.length;

    securityIds.forEach((id) => {
      lookup[id] = allocationPercent;
    });
    return lookup;
  };

  buildCurrentPortfolioAllocationsLookup = () => {
    const { portfolio } = this.props;
    const { positions } = portfolio;
    const lookup = {};
    const securityIds = Object.keys(positions);

    securityIds.forEach((id) => {
      lookup[id] = positions[id].allocation_percent;
    });
    return lookup;
  };

  setRebalancedAllocationsToCurrentAllocations = () => {
    this.setState(() => ({
      customRebalanceAllocationsLookup: this.buildCurrentPortfolioAllocationsLookup(),
    }));
  };

  setRebalancedAllocationsToEqualWeight = () => {
    this.setState(() => ({
      customRebalanceAllocationsLookup: this.buildEqualWeightPortfolioAllocationsLookup(),
    }));
  };

  _isInPanel = () => this.props.inPanel;

  _logViewRebalancer = () => {
    const event = 'View Rebalancer';
    const properties = {
      Context: this._isInPanel() ? 'Panel' : 'Analysis Page',
    };
    logMetricsTrackingEvent(event, properties)();
  };

  _logCreateOrder = () => {
    // new amp event style that merges all order types
    const properties = {
      'Order Creation Type': 'Full Rebalance',
      'Order Context': this._isInPanel() ? 'Panel' : 'Analysis Page',
    };
    TrackingEvents.orders.PLACED_TRADE.send(properties);
  };
}

const mapStateToProps = (state) => {
  return {
    rebalancer: state.rebalancer,
    portfolio: state.portfolio,

    ideaList: state.ideas.ideaList,
    currentUserId: state.currentUser.user_id,
  };
};

const composedComponent = compose(withRouter, connect(mapStateToProps))(RebalancerContainer);
export default composedComponent;
