import React from 'react';

export const ThoughtType = (props) => {
  const { thought } = props;

  const thoughtType = thought.thought_type;
  const thoughtTypeId = thoughtType.id;
  const thoughtTypeName = thoughtType.name.toUpperCase();
  const thoughtTypeColorClass = thoughtTypeId === 0 ? 'pro-text-color' : 'con-text-color';

  return <span className={`thought-type-wrapper ${thoughtTypeColorClass}`}>{thoughtTypeName}</span>;
};
