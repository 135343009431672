import React from 'react';
import { connect } from 'react-redux';

import Space from '../../../components/UI/Space';

import { referralRewardLimit, referralRewardRequiresACHLink } from '../../../helpers/currentUserHelpers';
import { isUndefinedOrNull } from '../../../helpers/generalHelpers';
import { isInt } from '../../../helpers/numberHelpers';
import { formatForDisplay } from '../../../helpers/displayHelpers';

class RewardsEarned extends React.PureComponent {
  render() {
    const { hasUnearnedRewards } = this.props;
    return (
      <div>
        {this._returnRewardTrackingEarnedMessage()}
        {hasUnearnedRewards && this._returnRewardTrackingUnearnedMessage()}
        {this._returnFundingRewardTrackingEarnedMessage()}
      </div>
    );
  }
  _returnFundingRewardTrackingEarnedMessage = () => {
    const { fundingRewardsTotalRedeemed, fundingRewardsTotalApproved, rewardsRequireACHLink } = this.props;

    if (fundingRewardsTotalRedeemed === 0) {
      return null;
    }

    if (fundingRewardsTotalApproved === 0) {
      return (
        <div className={'rewards-earned-container'}>
          <span className={'success-text-color'}>{"You'll get the funding reward of "}</span>
          <Space />
          <span className={'success-text-color'}>{this._formatRewardValueForDisplay(fundingRewardsTotalRedeemed)}</span>
          <Space />
          <span className={'success-text-color'}>
            {`once your account is approved${rewardsRequireACHLink ? ' and you link a valid bank account' : ''}.`}
          </span>
        </div>
      );
    }

    return (
      <div className={'rewards-earned-container'}>
        <span className={'success-text-color'}>{"You've earned"}</span>
        <Space />
        <span className={'success-text-color'}>{this._formatRewardValueForDisplay(fundingRewardsTotalApproved)}</span>
        <Space />
        <span className={'success-text-color'}>{'in funding rewards so far.'}</span>
      </div>
    );
  };

  _returnRewardTrackingEarnedMessage = () => {
    const { redeemedRewardsTotal, approvedRewardsTotal, didReachMaxPrizeLimit, rewardsRequireACHLink } = this.props;

    if (redeemedRewardsTotal === 0) {
      return null;
    }

    if (approvedRewardsTotal === 0) {
      return (
        <div className={'rewards-earned-container'}>
          <span className={'success-text-color'}>{"You'll get"}</span>
          <Space />
          <span className={'success-text-color'}>{this._formatRewardValueForDisplay(redeemedRewardsTotal)}</span>
          <Space />
          <span className={'success-text-color'}>
            {`once your account is approved${rewardsRequireACHLink ? ' and you link a valid bank account' : ''}.`}
          </span>
        </div>
      );
    }

    return (
      <div className={'rewards-earned-container'}>
        <span className={'success-text-color'}>{"You've earned"}</span>
        <Space />
        <span className={'success-text-color'}>{`${this._formatRewardValueForDisplay(approvedRewardsTotal)}`}</span>
        <Space />
        <span className={'success-text-color'}>{didReachMaxPrizeLimit ? '.' : 'so far.'}</span>
      </div>
    );
  };

  _formatRewardValueForDisplay = (value) =>
    isInt(value) ? formatForDisplay(value, 'priceInt') : formatForDisplay(value, 'price');

  _returnRewardTrackingUnearnedMessage = () => {
    const { totalCashToKeep, nextRewardExpirationDateFormattedMMDDYY, rewardsRequiringTradeToKeep } = this.props;
    const hasRewardsToKeep = totalCashToKeep > 0;
    if (hasRewardsToKeep) {
      return (
        <div>
          <div className={'rewards-unearned-container'}>
            <span className={'secondary-text-color'}>
              {`${this._formatRewardValueForDisplay(totalCashToKeep)} is yours to keep.`}
            </span>
          </div>
          <div className={'rewards-unearned-container'}>
            <span className={'secondary-text-color'}>
              {`You'll just need to place a trade by ${nextRewardExpirationDateFormattedMMDDYY} to keep the remaining $${rewardsRequiringTradeToKeep}.`}
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className={'rewards-unearned-container'}>
        <span className={'secondary-text-color'}>
          {`You'll just need to place a trade by ${nextRewardExpirationDateFormattedMMDDYY} to keep it.`}
        </span>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  const currentUser = state.currentUser;
  const rewardsData = state.rewards;

  const redeemedRewardsTotal = rewardsData.referral_reward_total_redeemed;
  const approvedRewardsTotal = rewardsData.referral_reward_total_approved;

  const rewardsRequiringTradeToKeep = rewardsData.cash_requiring_trade_to_keep;
  const totalCashToKeep = approvedRewardsTotal - rewardsRequiringTradeToKeep;
  const nextRewardExpirationDateFormattedMMDDYY = rewardsData.next_cash_expiration_date;

  const maxPrizeLimit = referralRewardLimit(currentUser);

  return {
    hasUnearnedRewards: !isUndefinedOrNull(rewardsRequiringTradeToKeep) && rewardsRequiringTradeToKeep > 0,

    fundingRewardsTotalRedeemed: rewardsData.funding_reward_total_redeemed,
    fundingRewardsTotalApproved: rewardsData.funding_reward_total_approved,

    redeemedRewardsTotal,
    approvedRewardsTotal,
    rewardsRequiringTradeToKeep,

    totalCashToKeep,
    nextRewardExpirationDateFormattedMMDDYY,

    rewardsRequireACHLink: referralRewardRequiresACHLink(currentUser),
    didReachMaxPrizeLimit: redeemedRewardsTotal >= maxPrizeLimit,
  };
};

export default connect(mapStateToProps)(RewardsEarned);
