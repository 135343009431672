import React from 'react';
import { connect } from 'react-redux';
import { PRODUCT_NAME } from '@src/appConfig';
import { LinkTo } from '../../Links/LinkTo';

import { formatForDisplay } from '../../../helpers/displayHelpers';
import { returnPathTo } from '../../../constants/paths';
import { Body5 } from '../../../main/lib/nvstr-common-ui.es';
import {
  referralRewardLimit,
  referralRewardRequiresTrade,
  referralRewardRequiresACHLink,
} from '../../../helpers/currentUserHelpers';
import styled from 'styled-components';

const LinkWrapper = styled.span`
  * {
    color: ${({ theme }) => theme.themeColors.text};
  }
`;
class ReferralCashRewardsDisclaimer extends React.PureComponent {
  render() {
    const referralRulesPath = returnPathTo('referralRules');
    const { requiresTrade, requiresACHLink, rewardLimit } = this.props;
    return (
      <div>
        <div className={'referral-rewards-disclaimer disclaimer'}>
          <Body5 isSmall isLowContrast>{`Free cash in your ${PRODUCT_NAME} brokerage account. `}</Body5>
          {requiresTrade && (
            <Body5 isSmall isLowContrast>
              Must place a trade within 1 month to keep sign up/referral rewards.{' '}
            </Body5>
          )}
          {requiresACHLink && (
            <Body5 isSmall isLowContrast>
              Must link a valid bank account to receive rewards.{' '}
            </Body5>
          )}
          <Body5 isSmall isLowContrast>
            {`Limit ${formatForDisplay(rewardLimit, 'priceInt')} per person. Only valid for live trading accounts. `}
          </Body5>
          <Body5 isSmall isLowContrast>
            <span>{'See the'}</span>
            <LinkWrapper>
              <LinkTo text={' Referral Program Rules '} to={referralRulesPath} scrollToTop />
            </LinkWrapper>
            <span>{'for details. '}</span>
          </Body5>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const currentUser = state.currentUser;
  return {
    requiresTrade: referralRewardRequiresTrade(currentUser),
    requiresACHLink: referralRewardRequiresACHLink(currentUser),
    rewardLimit: referralRewardLimit(currentUser),
  };
};

export default connect(mapStateToProps)(ReferralCashRewardsDisclaimer);
