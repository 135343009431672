import React from 'react';
import { connect } from 'react-redux';

import { ThoughtNotificationUserOpinionRows } from '../../../UI/Thought/ThoughtNotificationUserOpinionRows';
import ThoughtData from '../../../../dataContainers/thoughts/ThoughtData';
import ThoughtContainer from '../../../../containers/Thoughts/ThoughtContainer';
import BasicSecurityDataWrapper from '../../../SecurityData/BasicSecurityDataWrapper';
import { NotificationHeading } from '../NotificationHeading';
import ThoughtNotificationThoughtHeading from './ThoughtNotificationComponents/ThoughtNotificationThoughtHeading';
import SeeMoreOnSecurityLink from './ThoughtNotificationComponents/SeeMoreOnSecurityLink';
import { Timestamp } from '../../../UI/Timestamp';

import { isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import { returnCurrentUserId } from '../../../../helpers/currentUserHelpers';
import { getThoughtImpressionEventName } from '../../../../constants/tracking';
import { isInt } from '../../../../helpers/numberHelpers';
import styled from 'styled-components';

const Wrapper = styled.div`
  .thought-feed-thought-headline {
    * {
      font-size: 14px !important;
      line-height: 18px !important;
      color: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

export class SocialThoughtNotification extends React.PureComponent {
  constructor() {
    super();
    this.VIEWING_CONTEXT = getThoughtImpressionEventName('FEED');
  }
  render() {
    return (
      <Wrapper className={'react-thought-notification react-social-thought-notification'}>
        {this.isNonConnectionThought() ? (
          this.renderNonConnectionThoughtHeading()
        ) : (
          <NotificationHeading
            userIds={this.returnUserIdsForHeading()}
            maxShowIdCount={10}
            showEllipsisUserListAfterIfExceedMaxShow
          />
        )}
        <BasicSecurityDataWrapper securityId={this._returnSecurity().security_id}>
          <ThoughtContainer
            key={`notification-thought-container-${this._returnThoughtId()}`}
            thoughtId={this._returnThoughtId()}
            notificationIndex={this._returnNotificationIndex()}
            handleAfterThoughtAction={this.props.handleAfterThoughtAction}
            handleAdditionalPromptForThoughtAfterDisagreeAction={
              this.props.handleAdditionalPromptForThoughtAfterDisagreeAction
            }
            viewingContext={this.VIEWING_CONTEXT}
            metadata={this._returnMetadata()}
            ignoreImpressionTracking={this.props.ignoreImpressionTracking}
            scrollContainerSelector={this.props.scrollContainerSelector || window}
            isUserAuthed={true}
            inFeed={this.props.inFeed}
            inActivityPageFeed={this.props.inActivityPageFeed}
            showAlternateOpinionActionStyle
            showAskCTLLink
            hideOpinionCounts
            withThoughtLink
            useShareInModal
            customThoughtBubbleHeadingComponent={
              <ThoughtNotificationThoughtHeading
                security={this._returnSecurity()}
                isThoughtTypePro={this.isThoughtTypePro()}
              />
            }
          />
        </BasicSecurityDataWrapper>
        <ThoughtData thoughtId={this._returnThoughtId()} securityId={this._returnSecurity().security_id}>
          {(thought) => (
            <ThoughtNotificationUserOpinionRows
              agreeUserIds={this.returnAgreeUserIds()}
              disagreeUserIds={this.returnDisagreeUserIds()}
              communityAgreeCount={this.returnCommunityAgreeCount()}
              communityDisagreeCount={this.returnCommunityDisagreeCount()}
              thought={thought}
              isSocialStyle
            />
          )}
        </ThoughtData>
        <SeeMoreOnSecurityLink
          security={this._returnSecurity()}
          thoughtId={this._returnThoughtId()}
          notificationIndex={this._returnNotificationIndex()}
          viewingContext={this.VIEWING_CONTEXT}
          metadata={this._returnMetadata()}
        />
        {!this._shouldHideNotificationTimestamp() && (
          <Timestamp className={'notification-timestamp'} epochTime={this._returnNotificationTimestamp()} />
        )}
      </Wrapper>
    );
  }

  renderNonConnectionThoughtHeading = () => (
    <div className={'non-connection-thought-heading'}>
      {this.isThoughtFromCommunity() ? this.returnCommunityThoughtPhrase() : this.returnTrendingThoughtPhrase()}
    </div>
  );

  isNonConnectionThought = () => this.returnUserIdsForHeading().length === 0;

  returnCommunityThoughtPhrase = () => 'New from the community';

  returnTrendingThoughtPhrase = () => 'Trending in the community';

  isThoughtTypePro = () => this._returnThought().thought_type.id === 0;

  returnThoughtCreatorUserId = () =>
    this._returnNotificationData().originator_user ? this._returnNotificationData().originator_user.user_id : null;

  returnAgreeUserIds = () =>
    this._returnThoughtAgreeData() ? this._returnThoughtAgreeData().users.map((user) => user.user_id) : [];

  returnDisagreeUserIds = () =>
    this._returnThoughtDisagreeData() ? this._returnThoughtDisagreeData().users.map((user) => user.user_id) : [];

  returnCommunityAgreeCount = () => (this._returnThoughtAgreeData() ? this._returnThoughtAgreeData().total_count : 0);

  returnCommunityDisagreeCount = () =>
    this._returnThoughtDisagreeData() ? this._returnThoughtDisagreeData().total_count : 0;

  returnUserIdsForHeading = () => [...this.returnAgreeUserIds(), ...this.returnDisagreeUserIds()];

  _isThoughtAgree = () => !isUndefinedOrNull(this._returnThoughtAgreeData());

  _isThoughtDisagree = () => !isUndefinedOrNull(this._returnThoughtDisagreeData());

  _isThoughtAgreeOnly = () => isUndefinedOrNull(this._returnThoughtDisagreeData());

  _doesThoughtHaveBothAgreeAndDisagree = () => this._isThoughtAgree() && this._isThoughtDisagree();

  _returnThoughtCommunityType = () => this._returnNotificationData().from_community_type;

  isThoughtTrending = () => this._returnThoughtCommunityType() && this._returnThoughtCommunityType() === 'trending';

  isThoughtFromCommunity = () => this._returnThoughtCommunityType() && this._returnThoughtCommunityType() === 'fresh';

  _returnCurrentUser = () => this.props.currentUser;

  _returnCurrentUserId = () => returnCurrentUserId(this._returnCurrentUser());

  _isUserCurrentUser = (userId) => this._returnCurrentUserId() === userId;

  _returnThought = () => this._returnNotificationData().thought;

  _returnThoughtType = () => (this._returnThought().thought_type.id === 0 ? 'Pro' : 'Con');

  _returnThoughtAgreeData = () => this._returnNotificationData().agree;

  _returnThoughtDisagreeData = () => this._returnNotificationData().disagree;

  _returnSecurity = () => this._returnNotificationData().security;

  _returnNotificationTimestamp = () => this._returnNotificationData().time;

  _shouldHideNotificationTimestamp = () => this._returnNotificationData().show_timestamp === false;

  _returnThoughtId = () => this.props.notification.data.thought.id;

  _returnMetadata = () => this.props.notification.data.metadata;

  _findThoughtNotificationInNotificationsList = () => {
    return this._returnNotificationsList().filter(
      (notif) => notif && notif.type === 'thought_clean_style' && notif.data.thought.id === this._returnThoughtId()
    )[0];
  };

  _returnNotificationsList = () => this._returnNotificationsStore().notificationsList;

  _returnNotificationsStore = () => this.props.notifications;

  _returnNotificationIndex = () => {
    const index = this.props.notificationIndex;
    if (!isInt(index)) {
      console.error('Thought Notification Missing Index');
    }
    return index;
  };

  _returnNotification = () => this._findThoughtNotificationInNotificationsList();

  _returnNotificationData = () => this._returnNotification().data;
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications,
  };
};

const connectedComponent = connect(mapStateToProps, null)(SocialThoughtNotification);

export default connectedComponent;
