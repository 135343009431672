import React from 'react';
import styled from 'styled-components';
import { useConnections, useSortedConnections } from '../../hooks/user/useConnections';
import { useUser } from '../../hooks/user/useUser';
import { userFullName } from '../../../helpers/usersHelpers';
import ProfileAvatar from '../../../components/user/ProfileAvatar';
import { Container } from '../../components/ui';
import { Body1, Body5, Body6 } from '../../lib/nvstr-common-ui.es';
import { useNavigate } from 'react-router';
import { PROFILE_CONTEXTS } from '../../../constants/contextTracking';

const ConnectionRowWrapper = styled.div`
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border: 1px solid transparent;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};

  cursor: pointer;

  :hover {
    border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
    border-radius: 5px;
  }

  @media (max-width: 420px) {
    padding: 0 8px;
  }
`;
const NameAndPicWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const AvatarWrapper = styled.div``;
const IdentityWrapper = styled.div`
  padding: 0 0 0 24px;
`;

const ConnectionRow = ({ userId }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = React.useState(false);
  const user = useUser(userId);
  const name = userFullName(user);
  const isPrivate = user.is_private;

  const handleClick = () => {
    const search = `?context=${PROFILE_CONTEXTS.CONNECTIONS}`;
    const url = `/users/${userId}/public_profile${search}`;
    navigate(url);
  };
  const handleHover = () => {
    setIsHovered(true);
  };
  const handleHoverEnd = () => {
    setIsHovered(false);
  };
  return (
    <ConnectionRowWrapper onMouseEnter={handleHover} onMouseLeave={handleHoverEnd} onClick={handleClick}>
      <NameAndPicWrapper>
        <AvatarWrapper>
          <ProfileAvatar user={user} size={isHovered ? 'large' : 'medium'} withLink={false} showNameTag={false} />
        </AvatarWrapper>
        <IdentityWrapper>
          <Container>
            <Body1>{name}</Body1>
          </Container>
          {isPrivate && (
            <Container top={2}>
              <Body5 isLowContrast>Private</Body5>
            </Container>
          )}
        </IdentityWrapper>
      </NameAndPicWrapper>
    </ConnectionRowWrapper>
  );
};
export const CommunityUserConnectionsList = ({ userId }) => {
  const { isLoading, sortedConnections: connectionsIdList } = useSortedConnections(userId);

  if (isLoading) {
    return <div />;
  }

  if (!connectionsIdList) {
    return <div>Error</div>;
  }

  if (connectionsIdList.length === 0) {
    return <div>No connections</div>;
  }

  return (
    <div>
      {connectionsIdList.map((cId) => (
        <ConnectionRow key={`${cId}-${userId}`} userId={cId} />
      ))}
    </div>
  );
};
