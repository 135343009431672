import { RebalancePositionsList } from '../constants/types/RebalancePosition';

export const buildRebalancePositionsList = (rebalanceAllocationsLookup) => {
  const rebalancePositionsList = new RebalancePositionsList();
  let securityIds = Object.keys(rebalanceAllocationsLookup);

  if (securityIds.length === 0) return null;

  securityIds = securityIds.map((id) => parseFloat(id));

  securityIds.forEach((id) => {
    const alloc = rebalanceAllocationsLookup[id];
    const sanitizedAlloc = parseFloat(alloc);
    if (isNaN(sanitizedAlloc)) {
      if (alloc !== ' - ') {
        console.error('Allocation was NaN', {
          id,
          alloc,
        });
      }
      rebalancePositionsList.createOrUpdate(id, 0);
    } else {
      rebalancePositionsList.createOrUpdate(id, alloc);
    }
  });
  return rebalancePositionsList;
};
