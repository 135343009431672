import React from 'react';
import styled from 'styled-components';
import { colorPalette, SCREEN_SIZES } from '../../lib/nvstr-utils.es';

const Wrapper = styled.div`
  max-width: ${({ maxWidth }) => maxWidth || '95%'};
  width: ${({ width }) => width || '800px'};
  position: relative;
  margin: 12px auto;
  padding: ${({ noPadding }) => (noPadding ? '0px' : ' 16px 24px')};
  border-radius: 5px;
  text-align: left;
  cursor: default;

  background: ${({ theme, transparentBackground }) =>
    transparentBackground
      ? 'transparent'
      : theme.screenSize === SCREEN_SIZES.mobile
      ? theme.themeColors.componentNoOpacity
      : theme.themeColors.component};
  color: ${({ theme }) => theme.themeColors.text};

  ${({ expandHeight }) =>
    expandHeight ? 'flex: 0 1 auto; display: flex; justify-content: center; height: unset;' : ''}
  min-height: ${({ minHeight }) => minHeight || 'min-content'};
  height: ${({ height }) => height || 'min-content'};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;

    max-width: ${({ width }) => width || '100%'};
    width: 100%;
    height: ${({ expandHeight, height }) =>
      height || (expandHeight ? 'calc(100vh - 64px)' : 'unset')}; // 64px === nav height
    max-height: ${({ expandHeight }) => (expandHeight ? '800px' : 'unset')}; // 64px === nav height

    ${({ theme, mobileTransparentBackground }) =>
      mobileTransparentBackground && theme.screenSize === SCREEN_SIZES.mobile
        ? 'background: ' + theme.themeColors.componentNoOpacity + ';'
        : ''};
  }

  @media (max-width: 430px) {
    padding: ${({ noPadding }) => (noPadding ? '0px' : ' 16px')};
  }
  @media (max-width: 340px) {
    padding: ${({ noPadding }) => (noPadding ? '0px' : ' 8px')};
  }
`;

export const Page = (props) => {
  const { children } = props;

  React.useEffect(() => {
    window.$(window)[0].scrollTo(0, 0, 100);
  }, []);
  return <Wrapper {...props}>{children}</Wrapper>;
};
