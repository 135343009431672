import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withWindowSize from '../HOCS/withWindowSize';

import { OrderOperations, OrderTypes, Thresholds } from '../../constants';
import * as Actions from '../../actions/index';

import Button from '../../components/buttons/Button';
import LoadingIcon from '../../components/misc/LoadingIcon';
import PageLoading from '../../components/PageLoading';

import LivePricing from '../Security/LivePricing';
import InfoIcon from '../UI/InfoIcon';
import OpenCreateOrderButton from './components/OpenCreateOrderButton';
import OrderSizeFilterSlider from './components/OrderSizeFilterSlider';
import Order from './Order';
import OrderForm from './OrderForm';
import OrderBasketListHeading from './components/OrderBasketListHeading';

import {
  isCurrentUserLiveTrading,
  returnCurrentUserPositionShares,
  inNoOrderBasketTestGroup,
} from '../../helpers/currentUserHelpers';
import { formatForDisplay } from '../../helpers/displayHelpers';
import { isUndefinedOrNull, scrollToTop } from '../../helpers/generalHelpers';
import {
  isOrderOperationBuying,
  isOrderOperationSelling,
  calculateOrderApproxValue,
} from '../../helpers/ordersHelpers';
import { returnPricingForSecurity } from '../../helpers/securitiesHelpers';
import { FlatButton } from '../../main/components/buttons';
import styled from 'styled-components';
import { useIsMarketOpen } from '../../main/hooks/securities/useIsMarketOpen';
import { Body5 } from '@src/main/lib/nvstr-common-ui.es';
import { Container } from '@src/main/components/ui';

const PlaceOrdersBtn = styled.div`
  button {
    padding: 8px 10px;
  }
`;
const DeleteAllBtn = styled.div`
  button {
    padding: 4px 0px;
  }
`;

const MarketClosedMessage = () => {
  const { isOpen } = useIsMarketOpen();

  if (isOpen) {
    return null;
  }
  return (
    <div className={`market-closed-message-container`}>
      <div className="react-trading-notice">
        <i className="fa fa-exclamation-circle yellow icon-in-btn" />
        <span>Market is currently closed.</span>
      </div>
    </div>
  );
};

export class OrderBasket extends Component {
  constructor() {
    super();
    this.state = {
      _isLoading: true,
      _isPricingLoaded: false,
      _isPricingUpdating: false,

      _minimumOrderSizeValue: null,

      _isShowingProConPanel: false,
      _groupedOrdersJustPlaced: [], // array of order arrays, added after placing orders

      orderIdInEdit: null,
      showMorePricingSecurityId: null,
    };
  }

  componentDidMount() {
    if (this._didFinishLoading()) {
      this._initializeOrderBasketData();
    }
  }

  componentDidUpdate() {
    if (this._didFinishLoading()) {
      this._initializeOrderBasketData();
    }
  }

  render() {
    return (
      <div className={`order-basket-container`}>
        {this._renderPlaceOrdersState()}
        {this._renderBasket()}
        {this._renderEditOrder()}
        {this._showSeeMorePricing() && (
          <LivePricing
            securityId={this.returnSeeMorePricingSecurityId()}
            symbol={this._returnSecuritySymbol(this._returnSecurityBySecurityId(this.returnSeeMorePricingSecurityId()))}
            handleDismiss={this.handleSeeMorePricingDismiss}
            type={'modal'}
          />
        )}
      </div>
    );
  }

  _renderPlaceOrdersState = () => {
    const component = this.props.renderPlacingOrdersState();
    if (!component) {
      return null;
    }
    return (
      <div className={`creating-optimized-orders-overlay`} onClick={this.handleRenderPlaceOrdersStateOverlayClick}>
        <div className={`creating-orders-panel component-bg`}>{component}</div>
      </div>
    );
  };

  handleRenderPlaceOrdersStateOverlayClick = (e) => {
    if (!e.target.classList.contains('creating-optimized-orders-overlay')) {
      return false; // ignore clicks that are not directly on overlay
    }

    if (this.props.shouldRestrictDismissal) {
      return false; // Don't allow dismissal of overlay if this is running to prevent missing important info
    }

    if (this.props.wasOrderPlaced && !this.props.isAddProConPanelShowing) {
      // prevents clicking the overlay to dismiss placed orders message from skipping showing the add pro con panel
      return this.props.showAddProConPanelForPlacedOrders();
    }

    return this.props.clearPlaceOrdersState();
  };

  _renderBasket = () => {
    if (!this._arePendingOrders()) {
      return <div className={`order-basket-container`} />;
    }

    return (
      <div className={`order-basket-container`}>
        <div className={`order-basket-heading`}>
          <div className={`heading-panel`}>{this.props.headerComponent}</div>
          <div className={`order-summary-panel`}>
            {this._renderBasketActionButtons()}
            {this._renderOrderSummary()}
          </div>
        </div>
        {this._isOptimizedOrderInBasket() && (
          <Container>
            <OrderSizeFilterSlider
              min={0}
              max={this._calculateMaxMinimumOrderValue()}
              value={this._returnMinimumOrderValue()}
              handleChange={this.handleMinimumOrderValueChange}
            />
          </Container>
        )}
        <div className={`order-basket-list`}>
          {this._renderFilteringOrdersBanner()}
          <OrderBasketListHeading
            isMobile={this.shouldShowMobileComponents()}
            windowWidth={this.props.windowWidth}
            isPricingUpdating={this._isPricingUpdating()}
            handleRefreshPricingClick={this._handleRefreshPricingClick}
          />
          {this._returnAllOrdersToDisplay().map((order) => (
            <Order
              key={`order-${order.order_id}`}
              order={order}
              currentPosition={this._returnNumberOfCurrentSharesInPosition(order.security_id)}
              lastPrice={this._returnCurrentPriceForSecurityId(order.security_id)}
              renderMobileSize={this.shouldShowMobileComponents()}
              windowWidth={this.props.windowWidth}
              isLiveTrading={this._isLiveTrading()}
              handleEditOrder={this.handleEditOrder}
              handleSeeMorePricingClick={this.handleSeeMorePricingClick}
            />
          ))}
        </div>
        <div className={`order-basket-note secondary-text-color`}>
          {this._returnAllOrdersToDisplay().length > 1
            ? 'Orders above are not yet placed.  Click the Place Trade button to submit them.'
            : 'Order above is not yet placed.  Click the Place Trade button to submit it.'}
        </div>
        <div className={`order-basket-actions-footer`}>
          {!this.shouldRestrictAddingOrders() && (
            <OpenCreateOrderButton
              btnClassName={`btn skel-btn skel-btn-primary-color btn-small-tall`}
              inSearchMode={this.isSearchingForStockToAdd()}
              inBasket
              handleButtonClick={this.handleCreateOrderButtonClick}
              onSearchSelection={this.handleStartCreatingOrderFromSearch}
            />
          )}
          {this._isOptimizedOrderInBasket() && (
            <Container top={16}>
              <Body5>
                The output of the Optimizer is not a recommendation. You may modify or cancel any or all of the
                templated orders below. Please consider whether any trade is appropriate for your risk tolerance,
                investment goals, and other preferences before transacting.
              </Body5>
            </Container>
          )}
          {this._renderBasketActionButtonsWithMarketClosed()}
        </div>
      </div>
    );
  };

  shouldRestrictAddingOrders = () => {
    if (inNoOrderBasketTestGroup(this._returnCurrentUser()) && this._isOptimizedOrderInBasket()) {
      return true;
    }
    return false;
  };

  _renderMarketClosedMessage = () => {
    return <MarketClosedMessage />;
  };

  _renderOrderSummary = () => {
    return (
      <div className={`order-basket-summary`}>
        {this._renderBuyOrdersValueSummaryRow()}
        {this._renderSellOrdersValueSummaryRow()}
        {this._renderTotalCommissionsRow()}
        {this._renderTotalEstimatedValueSummaryRow()}
        {this._isUsingCommissionCredits() && (
          <div style={{ display: 'block', position: 'relative' }}>
            {this._renderCommissionsCredits()}
            <InfoIcon position={'right'} word={'tooltip_commission_credit'} style={{ top: '3px', right: '0' }} />
          </div>
        )}
      </div>
    );
  };

  _renderOrderSummaryRow = (key, label, value) => {
    if (this._isLoading()) {
      return (
        <div className={`order-summary-row ${key}-summary-row`}>
          <span className="row-label">{`${label}:`}</span>
          <span className="row-value">
            <LoadingIcon icon="fading-3balls" size="small" style={{ marginRight: '8px' }} />
          </span>
        </div>
      );
    } else {
      return (
        <div className={`order-summary-row ${key}-summary-row  `}>
          <span className="row-label">{`${label}:`}</span>
          <span className="row-value">{value}</span>
        </div>
      );
    }
  };

  _renderBuyOrdersValueSummaryRow = () =>
    this._renderOrderSummaryRow(
      'buy-orders',
      'Buy Orders',
      `${this._returnDisplayStringForPriceValue(this._returnTotalBuyOrdersValue())}`
    );

  _renderSellOrdersValueSummaryRow = () =>
    this._renderOrderSummaryRow(
      'sell-orders',
      'Sell Orders',
      `${this._returnDisplayStringForPriceValue(this._returnTotalSellOrdersValue())}`
    );

  _renderTotalCommissionsRow = () =>
    this._renderOrderSummaryRow(
      'commissions',
      'Commissions',
      `${this._returnDisplayStringForPriceValue(this._returnTotalCommissionsValue())}`
    );

  _renderTotalEstimatedValueSummaryRow = () =>
    this._renderOrderSummaryRow(
      'total-estimated-orders-value',
      'Total Estimated Value',
      `${this._returnDisplayStringForPriceValue(this._returnOrdersTotalEstimatedValue())}`
    );

  _renderCommissionsCredits = () =>
    this._isUsingCommissionCredits()
      ? this._renderOrderSummaryRow(
          'commissions-credits-applied-value',
          'Commission Credits To Be Applied',
          `${this._returnDisplayStringForPriceValue(this._returnOrderCommissionCreditsValue())}`
        )
      : null;

  _renderFilteringOrdersBanner = () => {
    const count = this._countOfOrdersFilteredBasedOnMinimumTradeValue();
    const message = this._returnMessageForFilterBanner(count);
    return count > 0 && <div className={`filtered-orders secondary-text-color`}>{message}</div>;
  };

  _returnMessageForFilterBanner = (filterOrderCount) => {
    const isPlural = filterOrderCount > 1;
    return `Filtering ${filterOrderCount} small order${
      isPlural ? 's' : ''
    }. Use the slider above to adjust the minimum order value.`;
  };

  _renderBasketActionButtons = () => {
    return (
      <div className={`order-basket-actions-container`}>
        <div className={`buttons-container`}>
          <DeleteAllBtn>
            <FlatButton
              transparent
              // prefixIcon="fa-trash"
              onClick={this._handleDeleteAllOrders}
            >
              Delete All
            </FlatButton>
          </DeleteAllBtn>
          <PlaceOrdersBtn>
            <FlatButton onClick={this._handlePlaceAllOrders}>Place Orders</FlatButton>
          </PlaceOrdersBtn>
        </div>
      </div>
    );
  };

  _renderBasketActionButtonsWithMarketClosed = () => {
    return (
      <div className={`order-basket-actions-container`}>
        {this._renderMarketClosedMessage()}
        <div className={`buttons-container`}>
          <DeleteAllBtn>
            <FlatButton
              transparent
              // prefixIcon="fa-trash"
              onClick={this._handleDeleteAllOrders}
            >
              Delete All
            </FlatButton>
          </DeleteAllBtn>
          <PlaceOrdersBtn>
            <FlatButton onClick={this._handlePlaceAllOrders}>Place Orders</FlatButton>
          </PlaceOrdersBtn>
        </div>
      </div>
    );
  };

  _renderEditOrder = () => {
    if (!this._isEdittingOrder()) {
      return null;
    }

    const order = this._returnOrderBeingEditted();
    const defaultFormData = this._returnFormDataForForm(order);
    return (
      <OrderForm
        defaultFormData={defaultFormData}
        securityId={order.security_id}
        orderId={order.order_id}
        order={order}
        handleCompleteOrder={this.handleCompleteEditOrder}
        handleCancelOrder={this.handleCancelEditOrder}
        editMode
      />
    );
  };

  _showErrorMessage = (error) => {
    const component = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {error}
      </div>
    );
    const modal = {
      contentComponent: component,
      dismissable: true,
    };
    return this.props.actions.showModal(modal);
  };

  _handlePlaceAllOrders = () => {
    const ordersToPlace = this._returnAllOrdersToDisplay();
    this.props.placeOrders(ordersToPlace);
  };

  _handleRefreshPricingClick = () => {
    this._logUpdatingPricingClick();
    this._startUpdatingPricing();
    const requests = [this._refreshSecurityPrices(), this._refreshUserData()];
    return Promise.all(requests).then((response) => {
      this._stopUpdatingPricing();
    });
  };

  _handleDeleteAllOrders = () => {
    const orderIds = this._returnAllNotPlacedOrderIds();
    this.props.actions.clearOrderBasket(orderIds).then((response) => {
      if (response && response.ok) {
        this._logDeleteAllOrders();
        scrollToTop();
      } else {
        const defaultErrorMessage = 'Something went wrong, could not delete orders at this time.';
        const serverErrorMessage = response && response.error;
        this._showErrorMessage(serverErrorMessage || defaultErrorMessage);
      }
    });
  };

  handleEditOrder = (orderId) => {
    this._setEditOrder(orderId);
    this._logEditOrderClick(orderId);
  };

  handleCompleteEditOrder = () => {
    this._clearEditOrder();
  };

  handleCancelEditOrder = () => {
    this._clearEditOrder();
  };

  handleSeeMorePricingClick = (securityId) => {
    this._setSeeMorePricingSecurityId(securityId);
  };

  handleSeeMorePricingDismiss = () => {
    this._clearSeeMorePricingSecurityId();
  };

  handleMinimumOrderValueChange = (value) => {
    this._setMinimumOrderValue(value);
  };

  handleCreateOrderButtonClick = () => this.props.handleCreateOrderButtonClick();

  handleStartCreatingOrderFromSearch = (security) => this.props.handleStartCreatingOrderFromSearch(security);

  _isLoading = () => this.state._isLoading && !this._isPricingLoaded();

  _renderLoading = () => <PageLoading flatStyle />;

  _isPricingLoaded = () => this.state._isPricingLoaded;

  _refreshUserData = () => {
    const _this = this;
    setTimeout(_this.props.actions.fetchCurrentUserQuickInfo, 250);
  };

  _returnFormDataForForm = (order) => {
    let shares = 0;
    if (order.subdivision) {
      shares += order.shares;
      shares += order.subdivision.shares;
    } else {
      shares += order.shares;
    }
    return {
      operation: OrderOperations[order.operation],
      type: OrderTypes[order.type],
      shares,
      limit_price: order.limit_price,
      stop_price: order.stop_price,
    };
  };

  _returnOrderBeingEditted = () =>
    this._returnAllNotPlacedOrders().filter((order) => order.order_id === this._returnOrderIdBeingEditted())[0];

  _returnOrderIdBeingEditted = () => this.state.orderIdInEdit;

  _isShowingAddProConPanel = () => this.state._isShowingProConPanel;

  _isEdittingOrder = () => !isUndefinedOrNull(this._returnOrderIdBeingEditted());

  _showSeeMorePricing = () => !isUndefinedOrNull(this.state.showMorePricingSecurityId);

  returnSeeMorePricingSecurityId = () => this.state.showMorePricingSecurityId;

  _getOrderData = () => this.props.actions.getOrders();

  _refreshSecurityPrices = () => this.props.actions.fetchSecuritiesPriceData([this._returnAllOrdersSecurityIds()]);

  _returnDataForOrderSummary = () => {
    const rawOrders = this._returnAllNotPlacedOrders();
    const orders = this._filterOrdersBasedOnMinimumTradeValue(rawOrders);

    let buyOrdersValue = 0;
    let sellOrdersValue = 0;
    let commissions = 0;
    let totalEstimatedValue = 0;

    orders.forEach((order) => {
      const limitPrice = order.limit_price;
      const stopPrice = order.stop_price;
      const marketPrice = this._returnCurrentPriceForSecurityId(order.security_id);
      const shares = order.shares;
      const isLimitOrder = limitPrice && limitPrice > 0;
      const isStopOrder = stopPrice && stopPrice > 0;
      commissions += order.commission || 0;
      if (order.subdivision) {
        const subdividedOrder = order.subdivision;
        commissions += subdividedOrder.commission || 0;
      }
      if (isOrderOperationBuying(order)) {
        const buyValue = calculateOrderApproxValue(
          false,
          shares,
          marketPrice,
          isLimitOrder,
          limitPrice,
          isStopOrder,
          stopPrice
        );
        buyOrdersValue += buyValue;
        totalEstimatedValue += buyValue;
      }
      if (isOrderOperationSelling(order)) {
        const sellValue = calculateOrderApproxValue(
          true,
          shares,
          marketPrice,
          isLimitOrder,
          limitPrice,
          isStopOrder,
          stopPrice
        );
        sellOrdersValue += sellValue;
        totalEstimatedValue += sellValue;
      }
    });

    return {
      buyOrdersValue,
      sellOrdersValue,
      commissions,
      totalEstimatedValue,
    };
  };

  _calculateMaxMinimumOrderValue = () => Math.ceil(this._returnCurrentUserEquity() * 0.05);

  _calculateDefaultMinimumOrderValue = () => Math.ceil(this._returnCurrentUserEquity() * 0.01);

  _isFilteringByMinimumOrderValue = () =>
    !isUndefinedOrNull(this._returnMinimumOrderValue()) && this._isOptimizedOrderInBasket();

  _returnMinimumOrderValue = () => this.state._minimumOrderSizeValue;

  _setDefaultMinimumOrderValue = () => this._setMinimumOrderValue(this._calculateDefaultMinimumOrderValue() || 0);

  _isOptimizedOrderInBasket = () => this._returnAllNotPlacedOrders().some((order) => order.is_from_optimizer);

  _returnCurrentPriceForSecurityId = (securityId) => this._returnPricingDataForSecurity(securityId).current_price;

  _returnPricingDataForSecurity = (securityId) =>
    returnPricingForSecurity(securityId, this._returnSecuritiesPriceStore());

  _returnSecuritiesPriceStore = () => this.props.securitiesPrice;

  _returnTotalBuyOrdersValue = () => this._returnDataForOrderSummary().buyOrdersValue;

  _returnTotalSellOrdersValue = () => this._returnDataForOrderSummary().sellOrdersValue;

  _returnTotalCommissionsValue = () => this._returnDataForOrderSummary().commissions;

  _returnOrdersTotalEstimatedValue = () =>
    this._returnDataForOrderSummary().totalEstimatedValue + this._returnTotalCommissionsValue();

  _returnDisplayStringForPriceValue = (value) => formatForDisplay(value, 'price');

  _isLiveTrading = () => isCurrentUserLiveTrading(this._returnCurrentUser());

  _isUsingCommissionCredits = () => this.props.isUsingTradingCredits;

  _hasFreeTrading = () => this.props.hasFreeTrading;

  _hasCommissionCredits = () => this.props.hasTradingCredits;

  _userTradingCredits = () => this.props.userTradingCredits;

  _returnOrderCommissionCreditsValue = () =>
    this._hasFreeTrading()
      ? this._returnTotalCommissionsValue()
      : this._returnTotalCommissionsValue() <= this._userTradingCredits()
      ? this._returnTotalCommissionsValue()
      : this._returnTotalCommissionsValue() - (this._returnTotalCommissionsValue() - this._userTradingCredits());

  isSearchingForStockToAdd = () => this.props.isSearchingForStockToAdd;

  _returnAllNotPlacedOrders = () => this._returnOrdersStore().not_placed_orders || [];

  _returnAllNotPlacedOrderIds = () => this._returnAllNotPlacedOrders().map((order) => order.order_id);

  _areThereNotPlacedOrders = () => this._returnAllNotPlacedOrders().length === 0;

  _returnAllPlacedOrders = () => this._returnOrdersStore().placed_orders || [];

  _isOrderSubdivied = (order) => order.subdivision;

  _returnAllOrders = () => [...this._returnAllNotPlacedOrders(), ...this._returnAllPlacedOrders()];

  _returnAllOrdersSecurityIds = () => {
    const allOrderSecurityIds = this._returnAllOrders().map((order) => order.security_id);
    return allOrderSecurityIds.filter((id, i) => allOrderSecurityIds.indexOf(id) === i);
  };

  // used for value aggregation of subdivided orders so they don't get filtered out based on difference between order values
  _returnOrderEstimatedValue = (order) => {
    const orderValue = Math.abs(order.shares * this._returnCurrentPriceForSecurityId(order.security_id));
    const subDividedOrderValue = order.subdivision
      ? Math.abs(order.subdivision.shares * this._returnCurrentPriceForSecurityId(order.security_id))
      : 0;
    return orderValue + subDividedOrderValue;
  };

  // does not use absolute value
  _returnOrderTrueEstimatedValue = (order) => {
    const orderValue = order.shares * this._returnCurrentPriceForSecurityId(order.security_id);
    const subDividedOrderValue = order.subdivision
      ? order.subdivision.shares * this._returnCurrentPriceForSecurityId(order.security_id)
      : 0;
    return orderValue + subDividedOrderValue;
  };

  _returnOrdersToBePlaced = () => this._filterOrdersBasedOnMinimumTradeValue(this._returnAllNotPlacedOrders());

  _filterOrdersBasedOnMinimumTradeValue = (orders) =>
    this._isFilteringByMinimumOrderValue()
      ? orders.filter((order) => this._returnOrderEstimatedValue(order) > this._returnMinimumOrderValue())
      : orders;

  _filteredOrdersBasedOnMinimumTradeValue = (orders) =>
    this._isFilteringByMinimumOrderValue()
      ? orders.filter((order) => Math.abs(this._returnOrderEstimatedValue(order)) <= this._returnMinimumOrderValue())
      : [];

  _countOfOrdersFilteredBasedOnMinimumTradeValue = () =>
    this._filteredOrdersBasedOnMinimumTradeValue(this._returnAllNotPlacedOrders()).length;

  _returnAllOrdersToDisplay = () => this._filterOrdersBasedOnMinimumTradeValue(this._returnAllNotPlacedOrders());

  _isPricingUpdating = () => this.state._isPricingUpdating;

  _isLoadingOrdersData = () => this._returnOrdersStore().isLoading;

  _arePendingOrders = () => this._returnAllNotPlacedOrders() && this._returnAllNotPlacedOrders().length > 0;

  _returnOrdersStore = () => this.props.orders;

  _returnCurrentUser = () => this.props.currentUser;

  _returnCurrentUserEquity = () => this._returnCurrentUser().equity || 0;

  _isSecurityDataLoadedForAllOrders = () => {
    if (!this._areThereNotPlacedOrders() && !this._isLoadingOrdersData()) {
      return true;
    }
    return this._returnAllNotPlacedOrders().every((order) => {
      const security = this._returnSecurityBySecurityId(order.security_id);
      return this._isSecurityDataAvailable(security);
    });
  };

  _isSecurityDataAvailable = (security) => this._returnSecurityName(security) && this._returnSecuritySymbol(security);

  _returnSecurityName = (security) => security.name;

  _returnSecuritySymbol = (security) => security.symbol;

  _returnSecurityBySecurityId = (securityId) => this._returnSecuritiesLookup()[securityId] || {};

  _returnSecuritiesLookup = () => this._returnSecuritiesStore().lookup;

  _returnSecuritiesStore = () => this.props.securities;

  _returnNumberOfCurrentSharesInPosition = (securityId) =>
    returnCurrentUserPositionShares(securityId, this._returnPortfolioStore());

  _returnPortfolioStore = () => this.props.portfolio;

  _calculateValueOfOrder = (order) => {
    let value = 0;
    const price = this._returnCurrentPriceForSecurityId(order.security_id);
    const operationModifier = isOrderOperationBuying(order) ? 1 : -1;
    value += price * order.shares * operationModifier;
    if (order.subdivision) {
      const subdivisionOrder = order.subdivision;
      const operationModifier = isOrderOperationBuying(subdivisionOrder) ? 1 : -1;
      value += price * subdivisionOrder.shares * operationModifier;
    }
    return value;
  };

  shouldShowMobileComponents = () => this.props.windowWidth <= Thresholds.widthForOrderMobileView;

  _returnStatusesThatIndicateOrderPlaceSuccessOrApproval = () => [
    'submitted',
    'stopped',
    'filled',
    'awaiting_approval',
  ];

  _wasOrderPlacedSuccessfully = (order) =>
    this._returnStatusesThatIndicateOrderPlaceSuccessOrApproval().includes(order.execution_status);

  _wasOrderPlacedSuccessfullyOrNeedsApproval = (order) => {
    const orderPlacedSuccessfullyOrNeedsApprovalStatusList = [
      ...this._returnStatusesThatIndicateOrderPlaceSuccessOrApproval(),
    ];
    return orderPlacedSuccessfullyOrNeedsApprovalStatusList.includes(order.execution_status);
  };

  _wasOptimizedOrderPlacedSuccessfully = (response) =>
    response.orders.some((order) => order.is_from_optimizer && this._wasOrderPlacedSuccessfully(order));

  _countNumOfOrdersPlacedFromResponse = (response) => {
    let count = 0;
    response.orders.forEach((order) => {
      if (this._wasOrderPlacedSuccessfully(order)) {
        count += 1;
      }
      if (this._isOrderSubdivied(order) && this._wasOrderPlacedSuccessfully(order.subdivision)) {
        count += 1;
      }
    });
    return count;
  };

  _calcTotalOrderCommissionFromResponse = (response) => {
    let commissions = 0;
    response.orders.forEach((order) => {
      if (this._wasOrderPlacedSuccessfully(order)) {
        commissions += order.commission;
      }
      if (this._isOrderSubdivied(order) && this._wasOrderPlacedSuccessfully(order.subdivision)) {
        commissions += order.subdivision.commission;
      }
    });
    return commissions;
  };

  _initializeOrderBasketData = () => {
    if (this._isOptimizedOrderInBasket() && isUndefinedOrNull(this._returnMinimumOrderValue())) {
      this._setDefaultMinimumOrderValue();
    }

    if (!this._isGettingPrices) {
      this._isGettingPrices = true; // prevents infinite recursion loop
      this._refreshSecurityPrices().then((response) => {
        if (response && response.data) {
          this._completeLoading();
        }
      });
    }
  };

  _logEditOrderClick = () => {
    const event = 'Clicked Edit Order';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logDeleteAllOrders = () => {
    const event = 'Remove All Orders';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logUpdatingPricingClick = () => {
    const event = 'Clicked Refresh Trade Page Pricing';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _didFinishLoading = () => {
    if (this._isLoading()) {
      const isCurrentUserLoaded = this._returnCurrentUser().user_id;
      const isSecurityDataLoaded = this._isSecurityDataLoadedForAllOrders();
      return isCurrentUserLoaded && isSecurityDataLoaded;
    } else {
      return true;
    }
  };

  _completeLoading = () => {
    if (this.props.onCompleteLoading) {
      this.props.onCompleteLoading();
    }
    this.setState(() => ({
      _isLoading: false,
      _isPricingLoaded: true,
    }));
  };

  _startUpdatingPricing = () => {
    this.setState(() => ({
      _isPricingUpdating: true,
    }));
  };

  _stopUpdatingPricing = () => {
    this.setState(() => ({
      _isPricingUpdating: false,
    }));
  };

  _setEditOrder = (orderId) => {
    this.setState(() => ({
      orderIdInEdit: orderId,
    }));
  };

  _clearEditOrder = () => {
    this.setState(() => ({
      orderIdInEdit: null,
    }));
  };

  _setSeeMorePricingSecurityId = (securityId) => {
    this.setState(() => ({
      showMorePricingSecurityId: securityId,
    }));
  };

  _clearSeeMorePricingSecurityId = () => {
    this.setState(() => ({
      showMorePricingSecurityId: null,
    }));
  };

  _setMinimumOrderValue = (value) => {
    this.setState(() => ({
      _minimumOrderSizeValue: value,
    }));
  };
}

const mapStateToProps = (state) => {
  return {
    ideas: state.ideas,
    orders: state.orders,
    portfolio: state.portfolio,
    currentUser: state.currentUser,
    securities: state.securities,
    securitiesPrice: state.securitiesPrice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(
  withWindowSize,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(OrderBasket);

export default composedComponent;
