import React from 'react';
import styled from 'styled-components';
import { Wizard } from '../LearnWizard';
import {
  Body3,
  createDropdownItem,
  defineFontSettings,
  InlineTextDropdown,
  LoadingSpinner,
} from '../../lib/nvstr-common-ui.es';
import { colorPalette } from '../../lib/nvstr-utils.es';
import { ROUTES } from '../../../constants/paths';
import { useNavigate, useLocation } from 'react-router-dom';
import { Text } from '../../components/text';
import { parseQueryString } from '../../../helpers/routerHelpers';
import { useSecurity } from '../../hooks/securities/useSecurity';
import { fetchSecuritiesPriceData, quickFetchSecuritiesData } from '../../../actions';
import { useDispatch } from 'react-redux';
import { ExpandedPageBackground, Page } from '../../components/layout';
import { createTimeInstance, getDaysUntilDate, formatLocalizedDateTime } from '../../../helpers/timeHelpers';
import { formatDataValue } from '../../../helpers/securitiesHelpers';
import { pluralizeIfAppropriate } from '../../../helpers/generalHelpers';
import { doXTimes, mapXTimes } from '../../../helpers/usefulFuncs';
import { usePosition } from '../../hooks/portfolio/usePosition';

const TextRow = styled.div``;

const Paragraph = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding-top: 16px;
`;

const LoadingSpinnerWrapper = styled.div`
  padding: 80px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingPageContent = styled.div`
  text-align: center;
  min-width: 600px;
`;

const getSecurityIdFromLocation = (location) => {
  const { search } = location;
  const query = parseQueryString(search);
  return query.securityId || null;
};

const expirations = [
  createTimeInstance('12/17/21').valueOf(),
  createTimeInstance('12/31/21').valueOf(),
  createTimeInstance('1/07/22').valueOf(),
  createTimeInstance('1/21/22').valueOf(),
  createTimeInstance('2/18/22').valueOf(),
  createTimeInstance('3/18/22').valueOf(),
];
const strikePrices = [325, 330, 335, 340, 345, 350, 355];

const strikeDropdownItems = strikePrices.map((p) => createDropdownItem({ text: `$${p}`, value: p }));

const expirationDropdownItems = expirations.map((e) =>
  createDropdownItem({ text: getDaysUntilDate(createTimeInstance(e)), value: e })
);

const lookup = {
  325: 11.55,
  330: 8.2,
  335: 5.4,
  340: 3.53,
  345: 3.08,
  350: 1.25,
  355: 0.42,
};

const getPrice = (strikePrice, expirationDate) => {
  const base = lookup[strikePrice];
  const expirationTimeInstance = createTimeInstance(parseInt(expirationDate));
  const daysTilExpiration = getDaysUntilDate(expirationTimeInstance);
  const steps = Math.max(1, Math.ceil(daysTilExpiration / 14));

  return base + base * steps * 0.2;
};

export const CoveredCallWizard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const securityId = getSecurityIdFromLocation(location);

  React.useEffect(() => {
    quickFetchSecuritiesData([securityId])(dispatch);
    fetchSecuritiesPriceData([securityId])(dispatch);
  }, []);

  const security = useSecurity(securityId);
  const securitySymbol = security?.symbol;

  const position = usePosition(securityId);
  const currentShares = isNaN(position?.shares) ? 0 : position?.shares;
  const sharesDropdownItems = mapXTimes(Math.floor(currentShares / 100), (i) =>
    createDropdownItem({ text: ((i + 1) * 100).toString(), value: (i + 1) * 100 })
  );

  if (currentShares < 100) {
    console.error('User does not have enough shares for a covered call');
    return null;
  }

  const [strikePrice, setStrikePrice] = React.useState(strikeDropdownItems[0].value);
  const [shares, setShares] = React.useState(sharesDropdownItems[0].value);
  const [expirationDate, setExpirationDate] = React.useState(expirationDropdownItems[0].value);

  const handleStrikePriceChange = (name) => (value) => {
    setStrikePrice(value);
  };
  const handleContractsChange = (name) => (value) => {
    setShares(value);
  };
  const handleExpiryChange = (name) => (value) => {
    setExpirationDate(value);
  };

  const price = getPrice(strikePrice, expirationDate);

  const onComplete = () => {
    const formData = {
      securityId,
      useParams: 'true',
      price,
      exercise_price: strikePrice,
      expiration_date: expirationDate,
      contracts: Math.round(shares / 100),
    };
    navigate(ROUTES.TRADE_OPTIONS.build(null, null, formData));
  };

  if (!security) {
    return (
      <ExpandedPageBackground>
        <Page expandheight={'true'}>
          <LoadingPageContent>
            <LoadingSpinnerWrapper>
              <LoadingSpinner />
            </LoadingSpinnerWrapper>
            <div>
              <Body3>Loading...</Body3>
            </div>
          </LoadingPageContent>
        </Page>
      </ExpandedPageBackground>
    );
  }

  const textColor = colorPalette.primary.charcoal;
  const small = false;

  const fontSettings = defineFontSettings(
    {
      fontSize: '16px',
      lineHeight: '28px',
    },
    {
      fontSize: '20px',
      lineHeight: '28px',
    }
  );

  const contracts = shares / 100;

  const showPrompt = false;

  const coveredCallWizardContent = [
    <Text fontSettings={fontSettings} isSmall={small} color={textColor}>
      A way to make extra money from your stock positions in exchange for giving up the possibility of very high
      short-term gains
    </Text>,
    <TextRow>
      <Text fontSettings={fontSettings} isSmall={small} color={textColor}>
        You can generate cash from your&nbsp;
      </Text>
      <Text fontSettings={fontSettings} isSmall={small} color={textColor} bold>
        {`${securitySymbol}`}&nbsp;
      </Text>
      <Text fontSettings={fontSettings} isSmall={small} color={textColor}>
        position if you think it won’t go up a lot soon.
      </Text>
    </TextRow>,
    <div>
      {showPrompt && (
        <Text fontSettings={fontSettings} isSmall={small} color={textColor}>
          Customize the menus below to see how much cash you can generate!
        </Text>
      )}
      <Paragraph>
        <span>
          <Text fontSettings={fontSettings} isSmall={small} color={textColor}>
            You can collect&nbsp;
          </Text>
        </span>
        <span>
          <Text fontSettings={fontSettings} isSmall={small} color={textColor}>{`around ${formatDataValue(
            price * shares,
            'price'
          )} `}</Text>
        </span>
        <span>
          <Text fontSettings={fontSettings} isSmall={small} color={textColor}>
            &nbsp;for giving up
          </Text>
        </span>
        <span>
          <Text fontSettings={fontSettings} isSmall={small} color={textColor}>{`the upside beyond `}</Text>
        </span>
        <span>
          <InlineTextDropdown
            name={'exercise_price'}
            value={strikePrice}
            onChange={handleStrikePriceChange}
            items={strikeDropdownItems}
            error={null}
          />
        </span>
        <span>
          <Text fontSettings={fontSettings} isSmall={small} color={textColor}>{`on `}</Text>
        </span>
        <span>
          <InlineTextDropdown
            name={'contracts'}
            value={shares}
            onChange={handleContractsChange}
            items={sharesDropdownItems}
            error={null}
          />
        </span>
        <span>
          <Text
            fontSettings={fontSettings}
            isSmall={small}
            color={textColor}
          >{`shares of your position over the next `}</Text>
        </span>
        <span>
          <InlineTextDropdown
            name={'expiry'}
            value={expirationDate}
            onChange={handleExpiryChange}
            items={expirationDropdownItems}
            error={null}
          />
        </span>
        <span>
          <Text fontSettings={fontSettings} isSmall={small} color={textColor}>{`days`}</Text>
        </span>
      </Paragraph>
    </div>,
    <div>
      <Text fontSettings={fontSettings} isSmall={small} color={textColor}>
        {`This helps offset losses by ${formatDataValue(
          price * shares,
          'price'
        )} if ${securitySymbol} goes down, and you'll make money if ${securitySymbol} goes up to ${formatDataValue(
          parseFloat(strikePrice) + price,
          'price'
        )} by ${formatLocalizedDateTime('LL', createTimeInstance(parseInt(expirationDate, 10)))}.`}
      </Text>
      <Paragraph>
        <Text fontSettings={fontSettings} isSmall={small} color={textColor}>
          If it goes even higher your upside will be capped out.
        </Text>
      </Paragraph>
    </div>,
    <div>
      <Text fontSettings={fontSettings} isSmall={small} color={textColor}>
        {`This is called covered call writing, and involves selling ${Math.round(contracts)} ${pluralizeIfAppropriate(
          'contract',
          contracts,
          {
            ignorePrefix: true,
          }
        )} (${shares} shares) ${formatDataValue(
          strikePrice,
          'price'
        )} ${securitySymbol} CALL option that expires on ${formatLocalizedDateTime(
          'LL',
          createTimeInstance(parseInt(expirationDate, 10))
        )}`}
      </Text>
    </div>,
  ];
  const screenData = {
    id: 1,
    name: 'Covered Calls',
    wizard: 'Covered Calls',
    content: coveredCallWizardContent,
  };

  return <Wizard onComplete={onComplete} carouselData={screenData} />;
};
