import React from 'react';
import { EMAIL_DOMAIN } from '../../appConfig';
import { Body5 } from '../../main/lib/nvstr-common-ui.es';

const ContactSupportMessage = () => {
  return (
    <div
      className="support-actions-container"
      style={{
        marginTop: '10px',
      }}
    >
      <div className="get-support-action">
        <Body5 isSmall>
          <span className="secondary-text-color">Have any questions? </span>
          <a href={`mailto:support@${EMAIL_DOMAIN}`}>Email Support</a>
        </Body5>
      </div>
    </div>
  );
};

export default ContactSupportMessage;
