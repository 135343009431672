class QueryBuilder {
  constructor() {
    this._queryString = '';
  }

  add = (key, value) => {
    this._addToQueryString(key, value);
  };

  addParamsList = (list) => {
    list.forEach((p) => this.add(p.key, p.value));
  };

  getQueryString = () => {
    const str = this._queryString;
    if (str.length > 0) {
      return `?${str}`;
    } else {
      return '';
    }
  };

  _addToQueryString = (key, value) => {
    if (this._queryString === '') {
      this._queryString = `${key}=${value}`;
    } else {
      this._queryString += `&${key}=${value}`;
    }
  };
}

export default QueryBuilder;
