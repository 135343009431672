import React from 'react';
import { Line } from 'react-chartjs-2';
import { formatLocaleString } from '../../../helpers/generalHelpers';

const ChartLegend = () => {
  return (
    <div className="optimizer-chart-legend secondary-text-color">
      {'Shaded areas indicate 25'}
      <sup>{'th'}</sup>
      {' to 75'}
      <sup>{'th'}</sup>
      {' percentiles'}
    </div>
  );
};

const colors = {
  current: [204, 204, 212],
  optimized: [61, 215, 53],
};

const createColorWithOpacity = (color, opacity) => {
  return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${opacity})`;
};

const buildValues = (NUM_OF_PLOTS, portfolioData) => {
  const projectedReturn = calcBoundOfVolatility(
    'upper',
    NUM_OF_PLOTS,
    0,
    portfolioData.current_value,
    portfolioData.current_profit,
    portfolioData
  );
  const projectedMinRange = calcBoundOfVolatility(
    'lower',
    NUM_OF_PLOTS,
    portfolioData.current_risk,
    portfolioData.current_value,
    portfolioData.current_profit,
    portfolioData
  );
  const projectedMaxRange = calcBoundOfVolatility(
    'upper',
    NUM_OF_PLOTS,
    portfolioData.current_risk,
    portfolioData.current_value,
    portfolioData.current_profit,
    portfolioData
  );

  const optimizedReturn = calcBoundOfVolatility(
    'upper',
    NUM_OF_PLOTS,
    0,
    portfolioData.current_value,
    portfolioData.optimized_profit,
    portfolioData
  );
  const optimizedMinReturn = calcBoundOfVolatility(
    'lower',
    NUM_OF_PLOTS,
    portfolioData.optimized_risk,
    portfolioData.current_value,
    portfolioData.optimized_profit,
    portfolioData
  );
  const optimizedMaxReturn = calcBoundOfVolatility(
    'upper',
    NUM_OF_PLOTS,
    portfolioData.optimized_risk,
    portfolioData.current_value,
    portfolioData.optimized_profit,
    portfolioData
  );
  return {
    projectedReturn,
    projectedMinRange,
    projectedMaxRange,
    optimizedReturn,
    optimizedMinReturn,
    optimizedMaxReturn,
  };
};

const calcGraphWidth = (panelWidth) => {
  const portfolioListWidth = 250;

  const buffer = 36;
  if (panelWidth > 864) {
    const summaryWidth = 250;
    return panelWidth - summaryWidth - portfolioListWidth - buffer;
  } else if (panelWidth < 652) {
    return panelWidth - buffer;
  } else if (panelWidth < 716) {
    return panelWidth - portfolioListWidth - 50;
  } else {
    return panelWidth - portfolioListWidth;
  }
};

const sortValuesFromObjIntoArray = function(obj) {
  let array = [];

  for (let key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      array.push(obj[key]);
    }
  }

  return array.sort(function(a, b) {
    return a - b;
  });
};

const convertNumToK = function(num) {
  let shortNum = num / 1000;

  if (num < 11000) {
    return (
      formatLocaleString(shortNum, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }) + 'k'
    );
  } else {
    return (
      formatLocaleString(shortNum, {
        maximumFractionDigits: 0,
      }) + 'k'
    );
  }
};

const calcMinMaxValue = (obj, minMax) => {
  let minMaxValue = null;
  for (let key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      let expression = minMax === 'max' ? minMaxValue < obj[key] : minMaxValue > obj[key];
      if (!minMaxValue) {
        minMaxValue = obj[key];
      } else if (expression) {
        minMaxValue = obj[key];
      }
    }
  }
  return minMaxValue;
};

const buildYaxisTicks = (rangeValues, usingPercentages) => {
  let yAxisTicks = [];
  let min = calcMinMaxValue(rangeValues, 'min');
  let max = calcMinMaxValue(rangeValues, 'max');
  let separation = 0.075 * (max - min);

  // Sort values into array
  let array = sortValuesFromObjIntoArray(rangeValues);

  // Iterate over and add to yAxisTicks if proper separation to avoid overlapping
  for (let i = 0; i < array.length; i++) {
    const value = array[i];
    const formattedValue = usingPercentages ? value + '%' : '$' + formatNumber(value);
    const tick = {
      value,
      label: formattedValue,
    };
    if (yAxisTicks.length === 0 || array[i] >= array[i - 1] + separation) {
      yAxisTicks.push(tick);
    } else {
      yAxisTicks[yAxisTicks.length - 1] = tick;
    }
  }
  return yAxisTicks;
};

const calcBoundOfVolatility = function(bound, num_of_plots, volatility, currentValue, profit, portfolioData) {
  let array = [];
  let inc = bound === 'upper' ? 1 : -1;
  for (let t = 0; t < num_of_plots; t++) {
    // formula = volatility * 25th percentile multiplier * SqRoot of time ratio
    let currentVolatility = volatility * getRiskConfidenceMultiplier(portfolioData) * Math.sqrt(t / num_of_plots);
    let currentProjectedValue = currentValue + profit * (t / num_of_plots);
    array.push(parseFloat((currentProjectedValue + currentVolatility * inc).toFixed(4)));
  }
  return array;
};

const calcMinValueInBoundOfVolatility = function(num_of_plots, volatility, currentValue, profit, portfolioData) {
  let min = null;

  // lower bound
  let inc = -1;

  for (let t = 0; t < num_of_plots; t++) {
    // formula = volatility * 25th percentile multiplier * SqRoot of time ratio
    let currentVolatility = volatility * getRiskConfidenceMultiplier(portfolioData) * Math.sqrt(t / num_of_plots);
    let currentProjectedValue = currentValue + profit * (t / num_of_plots);
    let value = currentProjectedValue + currentVolatility * inc;
    if (!min || value < min) {
      min = value;
    }
  }
  return min;
};

const getRiskConfidenceMultiplier = (portfolioData) => {
  return portfolioData.risk_confidence_multiplier;
};

const generateLabels = function(num_of_plots, firstLabel, lastLabel) {
  let array = [];
  for (let i = 0; i < num_of_plots; i++) {
    array.push('');
  }
  array[0] = firstLabel;
  array[num_of_plots - 1] = lastLabel;
  return array;
};

const createDatasets = ({
  projectedReturn,
  projectedMinRange,
  projectedMaxRange,
  optimizedReturn,
  optimizedMinReturn,
  optimizedMaxReturn,

  isProjectedReturnFlat,
  isOptimizedReturnFlat,

  minValue,
  maxValue,
}) => [
  {
    // label used in lib as a key, must be unique
    label: 7,
    backgroundColor: 'rgba(0,0,0,0)',
    borderColor: 'transparent', // createColorWithOpacity(colors.current, 0.6),
    data: [minValue, maxValue],
    yAxisID: 'y-axis-1',
    fill: true,
    // label: 'Projected value based on current portfolio'
  },
  {
    // label used in lib as a key, must be unique
    label: 2,
    backgroundColor: 'rgba(0,0,0,0)',
    borderColor: createColorWithOpacity(colors.current, 0.6),
    data: projectedReturn,
    yAxisID: 'y-axis-0',
    fill: true,
    // label: 'Projected value based on current portfolio'
  },
  {
    // label used in lib as a key, must be unique
    label: 3,
    backgroundColor: isProjectedReturnFlat ? 'rgba(0,0,0,0)' : createColorWithOpacity(colors.current, 0.36),
    borderColor: 'rgba(0,0,0,0)',
    data: projectedMinRange,
    // label: '75% chance of being greater than',
    yAxisID: 'y-axis-0',

    // fill is used to specify what line/ axis to fill to
    fill: '-1',
  },
  {
    // label used in lib as a key, must be unique
    label: 4,
    backgroundColor: isProjectedReturnFlat ? 'rgba(0,0,0,0)' : createColorWithOpacity(colors.current, 0.36),
    borderColor: 'rgba(0,0,0,0)',
    data: projectedMaxRange,
    yAxisID: 'y-axis-0',
    // label: '75% chance of being less than',

    // fill is used to specify what line/ axis to fill to
    fill: '-2',
  },
  // {
  //   // label used in lib as a key, must be unique
  //   label: 1,
  //   backgroundColor: 'rgba(0,0,0,0)',
  //   borderColor: createColorWithOpacity(colors.optimized, 0.6),
  //   data: optimizedReturn,
  //   yAxisID: 'y-axis-0',
  //   fill: true,
  //   // label: 'Projected value based on optimized portfolio'
  // },
  // {
  //   // label used in lib as a key, must be unique
  //   label: 5,
  //   backgroundColor: isOptimizedReturnFlat ? 'rgba(0,0,0,0)' : createColorWithOpacity(colors.optimized, 0.3),
  //   borderColor: 'rgba(0,0,0,0)',
  //   data: optimizedMinReturn,
  //   // label: '75% chance of being greater than',
  //   yAxisID: 'y-axis-0',
  //
  //   // fill is used to specify what line/ axis to fill to
  //   fill: '-1',
  // },
  // {
  //   // label used in lib as a key, must be unique
  //   label: 6,
  //   backgroundColor: isOptimizedReturnFlat ? 'rgba(0,0,0,0)' : createColorWithOpacity(colors.optimized, 0.3),
  //   borderColor: 'rgba(0,0,0,0)',
  //   data: optimizedMaxReturn,
  //   // label: '75% chance of being less than',
  //   yAxisID: 'y-axis-0',
  //
  //   // fill is used to specify what line/ axis to fill to
  //   fill: '-2',
  // },
];

const formatNumber = (v) => (v < 1000 ? (v < 10 ? v.toFixed(2) : v.toFixed(2)) : convertNumToK(v));

const OptimizerGraph = ({ panelWidth, usingPercentages, optimizerPortfolioData: portfolioData }) => {
  const chartRef = React.useRef(null);

  if (!portfolioData) return null;

  const NUM_OF_PLOTS = 365;
  const GRAPH_MAX_WIDTH = 450;

  // Data
  const labels = generateLabels(NUM_OF_PLOTS, 'Today  ', 'One Year');

  const isProjectedReturnFlat = portfolioData.current_profit === 0;
  const isOptimizedReturnFlat = portfolioData.optimized_profit === 0;

  const {
    projectedReturn,
    projectedMinRange,
    projectedMaxRange,
    optimizedReturn,
    optimizedMinReturn,
    optimizedMaxReturn,
  } = buildValues(NUM_OF_PLOTS, portfolioData);

  // Determine y-axis start and end values
  const rangeValues = {
    projectedReturn: projectedReturn[projectedReturn.length - 1],
    projectedMinRange: projectedMinRange[projectedMinRange.length - 1],
    projectedMaxRange: projectedMaxRange[projectedMaxRange.length - 1],
    // optimizedReturn: optimizedReturn[optimizedReturn.length - 1],
    // optimizedMinReturn: optimizedMinReturn[optimizedMinReturn.length - 1],
    // optimizedMaxReturn: optimizedMaxReturn[optimizedMaxReturn.length - 1],
  };

  const minMaxRangeValues = {
    current_value: portfolioData.current_value,
    minLowerBoundProjected: calcMinValueInBoundOfVolatility(
      NUM_OF_PLOTS,
      portfolioData.current_risk,
      portfolioData.current_value,
      portfolioData.current_profit,
      portfolioData
    ),
    minLowerBoundOptimized: calcMinValueInBoundOfVolatility(
      NUM_OF_PLOTS,
      portfolioData.optimized_risk,
      portfolioData.current_value,
      portfolioData.optimized_profit,
      portfolioData
    ),
    projectedReturn: projectedReturn[projectedReturn.length - 1],
    projectedMinRange: projectedMinRange[projectedMinRange.length - 1],
    projectedMaxRange: projectedMaxRange[projectedMaxRange.length - 1],
    // optimizedReturn: optimizedReturn[optimizedReturn.length - 1],
    // optimizedMinReturn: optimizedMinReturn[optimizedMinReturn.length - 1],
    // optimizedMaxReturn: optimizedMaxReturn[optimizedMaxReturn.length - 1],
  };

  let minValue = calcMinMaxValue(minMaxRangeValues, 'min');
  // Hack to length y axis toward 0 to account for the lower bound of volatility
  minValue = minValue - minValue * 0.05;
  const maxValue = calcMinMaxValue(minMaxRangeValues, 'max');

  const datasets = createDatasets({
    projectedReturn,
    projectedMinRange,
    projectedMaxRange,
    optimizedReturn,
    optimizedMinReturn,
    optimizedMaxReturn,

    isProjectedReturnFlat,
    isOptimizedReturnFlat,

    minValue,
    maxValue,
  });

  const options = {
    maintainAspectRatio: true,
    responsive: true,

    elements: {
      capBezierPoints: false,
      line: {
        borderWidth: 2,
        backgroundColor: 'rgba(0,0,0,0)',
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 0,
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: 'rgba(0, 0, 0, 0)',
      },
    },
    scales: {
      xAxes: {
        id: 'x-axis-0',
        distribution: 'series',
        grid: {
          display: false,
          borderColor: 'transparent',
          color: '#707070',
        },
        ticks: {
          autoSkip: false,
          maxRotation: 0,
        },
      },
      'y-axis-0': {
        position: 'right',
        id: 'y-axis-0',
        min: minValue,
        max: maxValue,
        grid: {
          display: false,
          borderColor: 'transparent',
          // borderColor: '#707070',
          color: '#707070',
        },
        afterTickToLabelConversion: function(axis) {
          const rightTicks = buildYaxisTicks(rangeValues, usingPercentages);
          axis.chart.scales['y-axis-0'].ticks = rightTicks;
        },
      },
      'y-axis-1': {
        min: minValue,
        max: maxValue,
        position: 'left',
        id: 'y-axis-1',
        grid: {
          display: false,
          borderColor: '#707070',
        },
        afterTickToLabelConversion: function(axis) {
          const currentValue = portfolioData.current_value;
          const leftTicks = [{ value: currentValue, label: '$' + formatNumber(currentValue) }];
          axis.chart.scales['y-axis-1'].ticks = leftTicks;
        },
      },
    },
    animation: {
      duration: 300,
    },

    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
  };

  const graphWidth = Math.min(GRAPH_MAX_WIDTH, calcGraphWidth(panelWidth));
  const data = {
    labels,
    datasets,
  };

  if (!datasets || datasets.length === 0)
    return <div className={'optimizer-graph-container'} style={{ width: `${graphWidth}px` }} />;

  return (
    <div className={'optimizer-graph-container'} style={{ width: `${graphWidth}px` }}>
      <Line ref={chartRef} type={'line'} data={data} options={options} />
      <ChartLegend />
    </div>
  );
};

export default OptimizerGraph;
