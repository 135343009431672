import React from 'react';
import GradientLine from './GradientLine';

class HeadingWithGradientLine extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      gradientWidth: 0,
    };
  }

  componentDidMount() {
    this._handleResize();
    window.addEventListener('resize', this._handleResize);
  }

  render() {
    const { text, additionalClasses } = this.props;
    const { gradientWidth } = this.state;

    return (
      <span className={`heading-with-gradient-line ${additionalClasses || ''}`}>
        <span ref={(el) => (this.Heading = el)}>{text}</span>
        <GradientLine width={gradientWidth} />
      </span>
    );
  }

  _handleResize = () => {
    const width = window.$(this.Heading).width();
    this.setGradientWidth(width);
  };

  setGradientWidth = (width) => {
    this.setState(() => ({
      gradientWidth: width,
    }));
  };
}

export default HeadingWithGradientLine;
