import React from 'react';

import CommunityPositionRow from './CommunityPositionRow';
import { InfiniteScroll } from '../../../containers/Scroll/InfiniteScroll';

class CommunityPositionsList extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      paginatedSecuritiesList: [],
    };

    this.PAGINATION_ROW_LENGTH = 15;
  }

  componentDidMount() {
    this.addAdditionalSecuritiesToPagination();
  }

  render() {
    const { navigate, location } = this.props;

    const { paginatedSecuritiesList } = this.state;

    return (
      <div className="">
        <InfiniteScroll
          id="community-positions-list-infinite-scroll"
          $scrollElement={window.$(window)}
          onInfiniteScroll={this.handleInfiniteScroll}
          ignoreInfiniteScroll={this.noMoreRows()}
          distanceFromBottomToTriggerInfiniteScroll={1200}
        >
          {paginatedSecuritiesList.map((security) => (
            <CommunityPositionRow
              key={`security-row-${security.symbol}`}
              security={security}
              navigate={navigate}
              location={location}
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  }

  handleInfiniteScroll = () => {
    this.addAdditionalSecuritiesToPagination();
    return Promise.resolve(true);
  };

  addAdditionalSecuritiesToPagination = () => {
    const { securities } = this.props;
    const currentPaginationIndex = this.state.paginatedSecuritiesList.length;
    const endIndex = currentPaginationIndex + this.PAGINATION_ROW_LENGTH;
    const additionalSecurities = securities.slice(currentPaginationIndex, endIndex + 1);
    this.setState((prevState) => ({
      paginatedSecuritiesList: [...prevState.paginatedSecuritiesList, ...additionalSecurities],
    }));
  };

  noMoreRows = () => this.state.paginatedSecuritiesList.length === this.props.securities.length;
}

export default CommunityPositionsList;
