import React from 'react';
import styled from 'styled-components';
import { FlatButton } from '../../components/buttons';
import { logMetricsTrackingEvent, showModal, submitLiveTradingSignUpForm } from '../../../actions';
import { useFormik } from 'formik';
import { InvestorProfileSchema } from '../../constants/formValidationSchemas';
import { useCurrentUser, useLiveAccount } from '../../hooks/user';
import { useDispatch } from 'react-redux';
import { FormState } from '../../components/form/FormState';
import { H5, Body5, createDropdownItem, FloatingLabelDropdown, Lock } from '../../lib/nvstr-common-ui.es';
import { Page } from '../../components/layout';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';
import { Container } from '@src/main/components/ui';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const InputsWrapper = styled.div`
  text-align: left;

  max-width: 400px;
  margin: 0 auto;

  * {
    text-align: left;
  }

  select {
    border-top: none;
    border-left: none;
    border-right: none;

    -webkit-appearance: auto;

    background: transparent !important;
  }
`;
const Message = styled.div`
  padding-top: 8px;
  text-align: left;
`;
const IconMessage = styled.div`
  padding-top: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  text-align: left;

  svg {
    height: 24px;
    width: 24px;

    fill: ${({ theme }) => theme.themeColors.text};

    margin-right: 16px;
  }
`;
const Actions = styled.div`
  padding-top: 24px;
  text-align: center;
`;
const Heading = styled.div`
  text-align: left;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const InputWrapper = styled.div`
  padding-top: 24px;
  text-align: center;
`;

const riskDropdownOptions = [
  createDropdownItem({ text: 'Conservative', value: 'Conservative' }),
  createDropdownItem({ text: 'Moderate', value: 'Moderate' }),
  createDropdownItem({ text: 'Aggressive', value: 'Aggressive' }),
];
const totalInvestibleAssetsDropdownOptions = [
  createDropdownItem({ text: '---', value: '' }),
  createDropdownItem({ text: 'Less than $25k', value: 'Less than $25k' }),
  createDropdownItem({ text: '$25k - $50k', value: '$25k - $50k' }),
  createDropdownItem({ text: '$50k - $100k', value: '$50k - $100k' }),
  createDropdownItem({ text: '$100k - $250k', value: '$100k - $250k' }),
  createDropdownItem({ text: '$250k - $500k', value: '$250k - $500k' }),
  createDropdownItem({ text: '$500k - $1M', value: '$500k - $1M' }),
  createDropdownItem({ text: 'More than $1M', value: 'More than $1M' }),
];
const annualIncomeDropdownOptions = [
  createDropdownItem({ text: '---', value: '' }),
  createDropdownItem({ text: 'Less than $25k', value: 'Less than $25k' }),
  createDropdownItem({ text: '$25k - $50k', value: '$25k - $50k' }),
  createDropdownItem({ text: '$50k - $100k', value: '$50k - $100k' }),
  createDropdownItem({ text: 'More than $100k', value: 'More than $100k' }),
];

const FORM_PAGE_NAME = 'investor_profile';

const getInitialValuesFromLiveAccount = (liveAccount) => {
  const { risk_profile, total_investible_assets, annual_income } = liveAccount;

  return {
    risk_profile: risk_profile || 'conservative',
    total_investible_assets: total_investible_assets || '',
    annual_income: annual_income || '',
  };
};

const massageFormData = (values) => {
  // const { risk_profile, total_investible_assets, annual_income } = values;
  return values;
};

export const EnterInvestorProfile = ({ onContinue, onboardingState }) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const liveAccount = useLiveAccount();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  const [showRiskProfileSelection, setShowRiskProfileSelection] = React.useState(false);
  const [riskProfileWarningShown, setRiskProfileWarningShown] = React.useState(false);

  const form = useFormik({
    initialValues: getInitialValuesFromLiveAccount(liveAccount),
    validationSchema: InvestorProfileSchema,
    enableReinitialize: false,
  });
  const { values, errors, setFieldValue, validateForm } = form;

  const handleChange = React.useCallback(
    (name) => (value) => {
      setFieldValue(name, value, false);
    },
    [setFieldValue]
  );

  const submitForm = React.useCallback((values) => {
    const apiFormData = massageFormData(values);
    setIsSubmitting(true);
    return submitLiveTradingSignUpForm(apiFormData, FORM_PAGE_NAME)(dispatch);
  }, []);

  const handleSubmit = React.useCallback(async () => {
    const event = 'Submitted Investor Profile Form';
    logMetricsTrackingEvent(event)();

    const validation = await validateForm();
    if (Object.keys(validation).length === 0) {
      const { status } = await submitForm(values);
      if (status === 200) {
        return {
          ok: true,
        };
      } else {
        setFormError('Something went wrong, please try again');
        setIsSubmitting(false);
        return {
          ok: false,
        };
      }
    } else {
      // could log errors but no form validations on this form
      setIsSubmitting(false);
      return {
        errors: true,
      };
    }
  }, [values]);

  const handleContinueClick = React.useCallback(async () => {
    const { ok } = await handleSubmit();
    if (ok) onContinue(null, onboardingState);
  }, [handleSubmit]);

  React.useEffect(() => {
    const viewEvent = 'View Investor Profile Form';
    logMetricsTrackingEvent(viewEvent)();

    const startEvent = 'Started Live Trading Sign Up';
    logMetricsTrackingEvent(startEvent)();

    // if custom setting, just don't allow changing
    if (liveAccount.risk_profile !== 'custom') {
      setShowRiskProfileSelection(true);
    }
  }, []);

  // check for risky behavior
  React.useEffect(() => {
    const shouldShowRiskModal =
      !riskProfileWarningShown && values.risk_profile === 'aggressive' && currentUser.investing_experience?.id === 0;
    if (shouldShowRiskModal) {
      const modal = {
        contentComponent: (
          <div className="risk-profile-alert-modal">
            As an inexperienced investor, an Aggressive risk profile may not be the best choice. Consider changing to
            Moderate or Conservative instead.
          </div>
        ),
        redirect: false,
        size: 'wide',
        dismissable: true,
      };

      showModal(modal)(dispatch);
      setRiskProfileWarningShown(true);
    }
  }, [values, riskProfileWarningShown]);

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.investorProfile}>
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>Update your investor profile</H5>
          </Heading>
          <Message>
            <Body5 isSmall>
              You can change these settings at any time. We encourage you to update these as your finances change.
            </Body5>
          </Message>

          <InputsWrapper>
            {showRiskProfileSelection && (
              <InputWrapper>
                <FloatingLabelDropdown
                  name={'risk_profile'}
                  label={'Select your Risk Profile'}
                  value={values.risk_profile}
                  error={errors.risk_profile || null}
                  items={riskDropdownOptions}
                  onChange={handleChange}
                />
              </InputWrapper>
            )}

            <InputWrapper>
              <FloatingLabelDropdown
                name={'total_investible_assets'}
                label={'What are your total investible assets?'}
                value={values.total_investible_assets}
                error={errors.total_investible_assets || null}
                items={totalInvestibleAssetsDropdownOptions}
                onChange={handleChange}
              />
            </InputWrapper>
            <InputWrapper>
              <FloatingLabelDropdown
                name={'annual_income'}
                label={'What is your total annual income?'}
                value={values.annual_income}
                error={errors.annual_income || null}
                items={annualIncomeDropdownOptions}
                onChange={handleChange}
              />
            </InputWrapper>
          </InputsWrapper>
          <Message>
            <IconMessage>
              <Lock />
              <Body5>This information will be kept private and it will never appear on your public profile.</Body5>
            </IconMessage>
          </Message>

          <Actions>
            <FormState error={formError} isSubmitting={isSubmitting} />
            <Container centerAll fullWidth>
              <FlatButton isDisabled={isSubmitting} fullWidth onClick={handleContinueClick}>
                Continue
              </FlatButton>
            </Container>
          </Actions>
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
