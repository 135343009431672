import React from 'react';
import { returnPathTo } from '../../constants/paths';

import Page from '../../components/layout/Page';
import LinkTo from '../Links/LinkTo';

import { logMetricsTrackingEvent } from '../../actions/trackingActions';
import { useNavigate } from 'react-router';
import { FlatButton } from '../../main/components/buttons';

const ButtonLink = ({ to, handleClick }) => {
  const navigate = useNavigate();

  const hc = () => {
    handleClick();
    navigate(to);
  };
  return <FlatButton onClick={hc}>Start Over</FlatButton>;
};

export const ImportFailure = (props) => {
  const _logStartOverClick = () => {
    const event = 'Clicked Start Over For Import Connections';
    logMetricsTrackingEvent(event)();
  };

  return (
    <Page className={`import-failure-container`}>
      <div className={`heading`}>Sorry, we're unable to import your connections at this time. Please try again.</div>

      <div className={`start-over-btn-container`}>
        <ButtonLink to={returnPathTo('importConnections')} handleClick={_logStartOverClick} />
      </div>
    </Page>
  );
};
