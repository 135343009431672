import React from 'react';
import { connect } from 'react-redux';
import { ROUTES } from '../../constants/paths';
import FullPageCard from '../../components/layout/FullPageCard';
import HeadingWithGradientLine from '../UI/HeadingWithGradientLine';
import { getSubscribedViewpointIdsFromState } from '../../selectors/viewpoints';
import Button from '../../components/buttons/Button';
import ViewpointSubscriptionCard from './components/ViewpointSubscriptionCard';
import ViewpointSubscribeButton, { VIEWPOINT_SUBSCRIBE_BUTTON_TYPES } from './ViewpointSubscribeButton';
import CanSubscribe from './CanSubscribe';
import { logMetricsTrackingEvent } from '../../actions';
import { LinkTo } from '../Links/LinkTo';
import { SkeletonButton } from '../../main/components/buttons';
import { withRouter } from '../../main/utils';
import { PRODUCT_DISPLAY_FULL_NAME } from '../../appConfig';

class FeaturedSubscriptionSelectionPage extends React.PureComponent {
  render() {
    const { currentSubscriptionViewpointIds } = this.props;

    return (
      <FullPageCard showMobileCardStyling>
        <div>
          <div className={'text-center'} style={{ margin: '0 auto' }}>
            <HeadingWithGradientLine text={'Viewpoint Subscriptions'} additionalClasses={'full-page-card-heading'} />
          </div>

          {currentSubscriptionViewpointIds.length === 0 ? (
            <div className={'text-center flex-center-child'}>
              <div className={'dotted-line-banner border-accent'} style={{ marginTop: '15px', maxWidth: '500px' }}>
                <span className={' '}>
                  You have a free featured Viewpoint contributor subscription available with your{' '}
                  {PRODUCT_DISPLAY_FULL_NAME} membership.
                </span>
                <div style={{ paddingTop: '15px' }}>
                  <Button
                    customClass="btn btn-primary-color btn-small-tall"
                    text={'Redeem'}
                    handleClick={this.handleRedeemFreeSubClick}
                  />
                </div>
              </div>
            </div>
          ) : null}

          <div className="viewpoint-subscriptions-list" style={{ marginTop: 25 }}>
            {currentSubscriptionViewpointIds.length > 0 ? (
              currentSubscriptionViewpointIds.map((id) => (
                <div key={id} style={{ marginBottom: '15px' }}>
                  <ViewpointSubscriptionCard id={id} />
                </div>
              ))
            ) : (
              <div className={'text-center'}>
                <span className={'secondary-text-color'}>You are not subscribed to any viewpoints.</span>
              </div>
            )}
            <div
              style={{
                paddingTop: 25,
                textAlign: 'center',
              }}
            >
              <LinkTo
                to={'/viewpoint_contributors/explore'}
                child={<SkeletonButton onClick={() => false}>Explore more Viewpoints</SkeletonButton>}
              />
            </div>
          </div>
        </div>
      </FullPageCard>
    );
  }

  handleRedeemFreeSubClick = () => {
    const { navigate } = this.props;
    navigate(ROUTES.FEATURED_VIEWPOINTS.build());

    const event = 'Click Redeem Free Viewpoint Subscription';
    logMetricsTrackingEvent(event)();
  };

  handleDiscoverViewpointsClick = () => {
    const { navigate } = this.props;
    navigate(ROUTES.FEATURED_VIEWPOINTS.build());
  };
}

const mapStateToProps = (state) => {
  return {
    currentSubscriptionViewpointIds: getSubscribedViewpointIdsFromState(state),
  };
};

const connectedComponent = connect(mapStateToProps)(FeaturedSubscriptionSelectionPage);
export default withRouter(connectedComponent);
