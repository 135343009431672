import React from 'react';
import { Body5 } from '../../main/lib/nvstr-common-ui.es';

export const DepositBonusBanner = () => {
  const message = 'Get $25 to invest when you make a $25 deposit! Limited time only.';
  return (
    <div>
      <Body5>{message}</Body5>
    </div>
  );
};
