import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from '../../../main/utils';
import * as Actions from '../../../actions/index';
import SearchInput from './components/SearchInput';
import SearchResultsList from './components/SearchResultsList';
import styled from 'styled-components';
import { COLOR_THEME_NAMES } from '../../../main/lib/nvstr-utils.es';

export const SearchContainerWrapper = styled.div`
  #nav_securities_search {
    border: ${({ theme }) =>
      theme.colorThemeName === COLOR_THEME_NAMES.dark ? 'none' : `1px solid ${theme.themeColors.border}`};

    border-radius: 5px;
  }
  #comp_table_add_stock,
  #add-thought-security-search {
    border: none;
  }
  #add-to-portfolio-search {
    border: 1px solid ${({ theme }) => theme.themeColors.text};
    border-radius: 5px;
  }
  #ask_ctl_stock_search {
    border: 1px solid ${({ theme }) => theme.themeColors.text};
    border-radius: 5px;
  }
  &#add-order-security-search_container {
    border: 1px solid ${({ theme }) => theme.themeColors.text};
    border-radius: 5px;
    input {
      border: none;
      border-radius: 5px !important; // prevent clipping
    }
  }
  input {
    background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};
    border-color: ${({ theme }) => theme.themeColors.border};
    border: none;
  }
  input::placeholder {
    font-size: 12px !important;
    opacity: 1;
    color: ${({ theme }) => theme.themeColors.text} !important;
  }
`;

class SearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      activeQueryResult: null,
    };
  }

  componentDidMount() {
    // HACK: for showing the nav before auth will cause component to mount and unmount
    this.bindKeyStrokeHandlers();
    this.setHideListener();

    // HACK: react click events will not fire as a result of this, but is needed to keep dropdown from dismissing on a click
    this.stopClickFromBubbling(this.searchContainer);
    this.bindSearchResultClickListener();
  }

  componentWillUnmount() {
    this.unbindHideListener();
    this.unbindKeyStrokeHandlers();
  }

  componentDidUpdate(prevProps, prevState) {
    const arraysHaveSameElements = (arr1, arr2) => arr1.every((el, i) => el === arr2[i]);
    const prevResultsAreDiffThanNewResults = !arraysHaveSameElements(
      this.returnQueryResults(prevProps, prevState).map((el) => el.id),
      this.returnQueryResults().map((el) => el.id)
    );
    const didFreshQueryResultLoad =
      (this.isQueryPending(prevProps, prevState) && !this.isQueryPending() && this.returnQueryResults().length > 0) ||
      (this.returnQueryResults(prevProps, prevState).length > 0 !== this.returnQueryResults().length > 0 &&
        this.returnQueryResults().length > 0) ||
      (prevResultsAreDiffThanNewResults && this.returnQueryResults().length > 0);

    if (didFreshQueryResultLoad) {
      this.makeFirstResultActive();
    }
  }

  render() {
    const className = this.props.className || 'default-search-container';
    const { query } = this.state;

    const id = this.props.id;
    const conditionalContainerProps = {
      ...(id ? { id: id + '_container' } : {}),
    };

    return (
      <SearchContainerWrapper
        {...conditionalContainerProps}
        className={className}
        style={this.props.customStyle || {}}
        ref={(el) => (this.searchContainer = el)}
      >
        <SearchInput
          id={id}
          showPlus={this.props.showPlus}
          className={className}
          handleChange={this.handleChange}
          query={query}
          autoFocus={this.props.autoFocus}
          loading={this.isQueryPending()}
          placeholder={this.props.placeholder}
          gradientIcon={this.props.gradientIcon}
          iconTopPos={this.props.iconTopPos}
          navSearch={true}
        />

        <SearchResultsList
          inputId={this.props.id}
          query={query}
          show={query.length > 0}
          loading={this.isQueryPending()}
          results={this.returnQueryResults()}
          activeQueryResult={this.state.activeQueryResult}
          maxResultsSize={10}
          makeQueryResultActive={this.makeQueryResultActive}
          handleResultClick={this.handleSecurityQuerySelection}
        />
      </SearchContainerWrapper>
    );
  }

  _dismissAfterSelection = () => {
    this.clearQuery();
    this._blur();
  };

  _blur = () => {
    const id = this.props.id;
    if (!id) {
      console.warn('Cannot blur without id');
    }
    $('#' + id).blur();
  };

  bindSearchResultClickListener = () => {
    const $searchContainer = $(this.searchContainer);
    $searchContainer.on('click', (e) => {
      const targetEl = e.target;
      if (targetEl.classList.contains('search-result')) {
        const id = targetEl.id.split('-')[1];
        const queryResults = this.returnQueryResults();
        this.handleSecurityQuerySelection(queryResults.filter((result) => result.id === id)[0]);
      }
    });
  };

  stopClickFromBubbling = (ref) => {
    // HACK: React events are not directly attached to the DOM node, so you cannot use it to stopPropagation, current solution is native events.
    if (!ref) {
      console.error('Missing ref to attach handler to');
      return false;
    }
    $(ref).on('click', (e) => {
      e.stopPropagation();
    });
  };

  setHideListener = () => {
    $(document).on('click', this.cancelQueryOnClickAway);
  };

  unbindHideListener = () => {
    $(document).off('click', this.cancelQueryOnClickAway);
  };

  cancelQueryOnClickAway = () => {
    if (this.state.query.length > 0) {
      this.cancelQuery();
    }
  };

  cancelQuery = () => {
    const query = this.state.query; // set query here to cache the value as its about to be cleared
    this.clearQuery();
    this.sendCancelledSearchEvent(query);
  };

  sendCancelledSearchEvent = (query) => {
    const parentComponent = this.props.parentComponent || 'Unknown';
    if (parentComponent === 'Unknown') {
      console.error('unknown prop supplied to search event');
    }
    const supportedComponents = ['Portfolio Explorer', 'Navbar', 'Trade Page'];
    if (parentComponent && supportedComponents.includes(parentComponent)) {
      this._logCancelledSearch(parentComponent, query);
    }
  };

  bindKeyStrokeHandlers = () => {
    $(document).on('keydown', this.handleKeyPress);
  };

  handleKeyPress = (e) => {
    const keyCodeToAction = {
      38: () => {
        this.cycleThroughResults('prev');
      },
      40: () => {
        this.cycleThroughResults('next');
      },
      13: this.handleSecurityQuerySelection,
      27: this.cancelQuery,
    };
    const keyCode = e.keyCode;
    const isEnterHit = keyCode === 13;
    const action = keyCodeToAction[keyCode];
    const queryIsActive = this.state.query.length > 0;
    const isValidAction = isEnterHit
      ? !this.isQueryPending() && this.returnQueryResults() && this.returnQueryResults().length > 0
      : true;
    if (action && queryIsActive && isValidAction) {
      action();
    }
  };

  unbindKeyStrokeHandlers = () => {
    $(document).off('keydown', this.handleKeyPress);
  };

  searchStore = (props, state) => {
    props = props || this.props;
    state = state || this.state;
    return props.search;
  };

  searchData = (props, state) => {
    props = props || this.props;
    state = state || this.state;
    return this.searchStore(props, state).data;
  };

  makeQueryResultActive = (queryResult) => {
    this.setState((prevState) => ({
      activeQueryResult: queryResult,
    }));
  };

  makeFirstResultActive = () => {
    const queryResults = this.returnQueryResults();
    const firstResult = queryResults ? queryResults[0] || null : null;
    this.makeQueryResultActive(firstResult);
  };

  cycleThroughResults = (direction) => {
    const numericDirection = direction === 'prev' ? -1 : 1;

    const queryResults = this.returnQueryResults();
    const activeQueryResult = this.state.activeQueryResult;
    const activeQueryResultIndex = this.findIndexOfActiveQueryResult();

    const newActiveQueryResult = queryResults[activeQueryResultIndex + numericDirection];
    this.makeQueryResultActive(newActiveQueryResult || activeQueryResult);
  };

  findIndexOfActiveQueryResult = () => {
    const queryResults = this.returnQueryResults();
    for (let i = 0; i < queryResults.length; i++) {
      const element = queryResults[i];
      if (element === this.state.activeQueryResult) {
        return i;
      }
    }
    return null;
  };

  returnQueryResults = (props, state) => {
    props = props || this.props;
    state = state || this.state;
    const query = state.query;
    return this.searchData(props, state)[query] || [];
  };

  returnQueriesRequested = (props, state) => {
    props = props || this.props;
    state = state || this.state;
    return this.searchStore(props, state).queries;
  };

  isQueryPending = (props, state) => {
    props = props || this.props;
    state = state || this.state;
    const query = state.query;
    return !(query in this.searchData(props, state)) && this.returnQueriesRequested(props, state).includes(query);
  };

  getQueryData = () => {
    return this.searchStore().data || {};
  };

  hasQueryBeenRequested = (query) => {
    return this.searchStore().queries.includes(query);
  };

  handleChange = (event) => {
    const input = event.target.value;
    if (input === '' && this.state.query !== '') {
      return this.clearQuery();
    }

    const inputRestrictionRegex = this.props.inputRestrictionRegex || /^([a-zA-Z-&]|\.|\\|\s)+$/;
    if (inputRestrictionRegex.test(input)) {
      this.setState((prevState) => ({
        query: input,
      }));

      if (!this.hasQueryBeenRequested(input)) {
        this.props.actions.querySecurities(input);
      }
    }
  };

  clearQuery = () => {
    this.setState((prevState) => ({
      query: '',
    }));
    this.props.actions.clearQuery();
  };

  handleSecurityQuerySelection = (security) => {
    security = security || this.state.activeQueryResult;
    if (!security) {
      return false;
    }
    this.props.handleSecurityQuerySelection(security);
    if (!this.props.ignoreClearQueryAfterSelection) {
      this.clearQuery();
    }
    if (this.props.dismissAfterSelection) {
      this._dismissAfterSelection();
    }
  };

  _logCancelledSearch = (parentComponentName, query) => {
    const event = 'Cancelled Search';
    const properties = { Component: parentComponentName, Query: query };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    search: state.search,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SearchContainer);

export default composedComponent;
