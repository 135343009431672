import React from 'react';
import { AddBankAccountForm } from '../../containers/forms/AddBankAccountForm';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';

export const LinkBankMicroDeposits = ({ onContinue }) => {
  const handleContinue = () => {
    onContinue();
  };

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.linkBankAccountMicro}>
      <AddBankAccountForm onContinue={handleContinue} />;
    </LocationContext.Provider>
  );
};
