import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { querySecurities } from '@src/actions';

const actions = {
  update: 'u',
};

const initialState = {
  securityId: null,
};

function reducer(state, action) {
  switch (action.type) {
    case actions.update: {
      const p = action.payload;
      const { symbol, securityId } = p;
      if (state.symbol === symbol && state.securityId === securityId) {
        return state;
      }
      return { symbol, securityId };
    }

    default: {
      console.error('missing case in reducer', action);
      return state;
    }
  }
}

export const useSymbolToSecurityId = (symbol) => {
  const dispatch = useDispatch();
  const symbolToSecurityIdLookup = useSelector((state) => state.symbolToSecurityId);
  const [state, localDispatch] = React.useReducer(reducer, initialState, () => ({
    symbol,
    securityId: symbolToSecurityIdLookup[symbol] || null,
  }));

  const getSecurityDataForSymbol = async (symbol) => {
    if (!symbol) {
      console.error('no symbol found', symbol);
      return;
    }
    const response = await querySecurities(symbol)(dispatch);
    const { ok, results } = response;
    if (ok) {
      results.forEach((s) => {
        const { id, symbol: resultSymbol } = s;
        if (resultSymbol === symbol) {
          localDispatch({ type: actions.update, payload: { symbol, securityId: id } });
        }
      });
    } else {
      setTimeout(() => getSecurityDataForSymbol(symbol), 1000);
    }
  };

  React.useEffect(() => {
    const securityId = symbolToSecurityIdLookup[symbol];
    if (!securityId) {
      localDispatch({ type: actions.update, payload: { symbol, securityId: null } });
      getSecurityDataForSymbol(symbol);
    }
  }, []);

  React.useEffect(
    () => {
      if (symbol) {
        getSecurityDataForSymbol(symbol);

        if (symbol !== state.symbol) {
          const securityId = symbolToSecurityIdLookup[symbol];
          if (!securityId) {
            localDispatch({ type: actions.update, payload: { symbol, securityId: null } });
            getSecurityDataForSymbol(symbol);
          } else {
            localDispatch({ type: actions.update, payload: { symbol, securityId } });
          }
        }
      }
    },
    [symbol, state]
  );

  return state;
};
