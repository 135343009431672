import { END_ONBOARDING } from '../constants';

const defaultState = {};

export default function onboardingReducer(state = defaultState, action) {
  switch (action.type) {
    case END_ONBOARDING:
      return state;

    default:
      return state;
  }
}
