import React from 'react';
import styled from 'styled-components';
import { getSecurityIdFromIdea } from '../../../helpers/ideaHelpers';
import { useDispatch } from 'react-redux';
import {
  fetchIdeas,
  fetchSecuritiesFundamentalsData,
  fetchSecuritiesPriceData,
  quickFetchSecuritiesData,
} from '../../../actions';
import { Body5, Body6, ChangeIndicator } from '../../lib/nvstr-common-ui.es';
import { Container } from '../../components/ui';
import { FlatButton } from '../../components/buttons';
import { SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../components/ui/SvgLoadingIcon';
import { activeSecuritiesTracker, LivePricePollingManager } from '../../classes/LivePricePollingManager';
import { useIdea } from '../../hooks/ideas/useIdea';
import { useIdeas } from '../../hooks/user/useIdeas';
import { usePriceData } from '../../hooks/securities/usePriceData';
import { ColorChangeText } from '../../components/molecules/ColorChangeText';
import { DISPLAY_FORMAT_TYPES, formatValueTo } from '../../utils/numbers';
import InfoIcon from '../../../containers/UI/InfoIcon';
import { FUNDAMENTAL_SECURITY_DATA_KEYS, useFundamentalData } from '../../hooks/securities/useFundamentalData';
import { useNavigateToSecurity } from '../../hooks/securities/useNavigateToSecurity';

const ErrorWrapper = styled.div`
  padding: 24px;
`;
const TableWrapper = styled.div``;

const _fetchData = async (userId, dispatch, handleSuccess, handleFailure) => {
  const { ok, ideas, errorMessage } = await fetchIdeas(userId)(dispatch);
  if (ok) {
    const ideaList = ideas || [];
    const securityIds = ideaList.map((idea) => getSecurityIdFromIdea(idea));
    const responses = await Promise.all([
      quickFetchSecuritiesData(securityIds)(dispatch),
      fetchSecuritiesFundamentalsData(securityIds)(dispatch),
      fetchSecuritiesPriceData(securityIds)(dispatch),
    ]);
    handleSuccess();
  } else {
    handleFailure();
    console.error(errorMessage);
  }
};

const rowHeight = 116 - 32;

const NameWrapper = styled.div`
  color: ${({ theme }) => theme.themeColors.secondaryText};
`;
const IdentifierCellWrapper = styled.div`
  height: ${rowHeight}px;

  > div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
const PerformanceCellWrapper = styled.div`
  height: ${rowHeight}px;
`;
const FundamentalsCellWrapper = styled.div`
  height: ${rowHeight}px;
`;

const RowWrapper = styled.div`
  box-sizing: border-box;

  display: grid;
  grid-template-columns: minmax(120px, 1fr) minmax(228px, 1fr) minmax(260px, 1fr);
  grid-column-gap: 8px;
  padding: 16px 24px;
  border: 1px solid transparent;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  cursor: pointer;

  :hover {
    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.themeColors.primaryCtaButton};
  }

  @media (max-width: 692px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(40px, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;

    > div:first-child {
      grid-area: 1 / 1 / 2 / 3;
      padding-top: 0;
      padding-bottom: 0;
    }
    > div:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
    }
    > div:nth-child(3) {
      grid-area: 2 / 2 / 3 / 3;
    }

    ${IdentifierCellWrapper} {
      height: 24px;

      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: flex-start;

      > div {
        padding-left: 6px;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  @media (max-width: 460px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const IdentifierCell = ({ securityId, symbol, name }) => {
  return (
    <IdentifierCellWrapper>
      <Body6>{symbol.toUpperCase()}</Body6>
      <NameWrapper top={4}>
        <Body5 isLowContrast thin>
          {name}
        </Body5>
      </NameWrapper>
    </IdentifierCellWrapper>
  );
};

const PerformanceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 6px;
  :first-child {
    padding: 0;
  }
`;
const PerformanceLabel = styled.div`
  width: 80px;
  text-align: right;
  overflow: visible;
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 62px;
  }
`;
const PerformanceChangeWrapper = styled.div`
  text-align: center;
  width: 36px;

  @media (max-width: 600px) {
    width: 26px;
  }
`;
const PerformanceValueWrapper = styled.div`
  width: 66px;
  text-align: left;
`;
const PerformanceValue = ({ value }) => {
  return (
    <PerformanceValueWrapper>
      <ColorChangeText value={value}>
        <Body5>{formatValueTo(DISPLAY_FORMAT_TYPES.PERCENTAGE, value)}</Body5>
      </ColorChangeText>
    </PerformanceValueWrapper>
  );
};
const PerformanceChangeIndicator = ({ value }) => {
  return (
    <PerformanceChangeWrapper>
      <ChangeIndicator value={value} size={16} />
    </PerformanceChangeWrapper>
  );
};
const PerformanceCell = ({ securityId, ideaId }) => {
  const { prev_month_perf, this_month_perf } = useIdea(ideaId);
  const { percentChange } = usePriceData(securityId);
  return (
    <PerformanceCellWrapper>
      <PerformanceRow>
        <PerformanceLabel>
          <Body5 isLowContrast thin>
            Today
          </Body5>
        </PerformanceLabel>
        <PerformanceChangeIndicator value={percentChange} />
        <PerformanceValue value={percentChange} />
      </PerformanceRow>
      <PerformanceRow>
        <PerformanceLabel>
          <Body5 isLowContrast thin>
            This Month
          </Body5>
        </PerformanceLabel>
        <PerformanceChangeIndicator value={this_month_perf} />
        <PerformanceValue value={this_month_perf} />
      </PerformanceRow>
      <PerformanceRow>
        <PerformanceLabel>
          <Body5 isLowContrast thin>
            Last Month
          </Body5>
        </PerformanceLabel>
        <PerformanceChangeIndicator value={prev_month_perf} />
        <PerformanceValue value={prev_month_perf} />
      </PerformanceRow>
    </PerformanceCellWrapper>
  );
};

const FundamentalsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 6px;

  :first-child {
    padding: 0;
  }
`;
const FundamentalsLabel = styled.div`
  .mobile {
    display: none;
  }
  width: 170px;
  text-align: right;
  padding-top: 6px;
  padding-right: 22px;

  :first-child {
    padding-top: 0;
  }
  position: relative;

  overflow: visible;
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 122px;

    .non-mobile {
      display: none;
    }
    .mobile {
      display: inline-block;
    }
  }
`;
const InfoIconWrapper = styled.div`
  path {
    fill: ${({ theme }) => theme.themeColors.secondaryText};
  }
`;
const FundamentalsValue = styled.div`
  width: 100px;
  text-align: left;

  padding-left: 5px;

  overflow: visible;
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 80px;
  }
`;
const FundamentalsCell = ({ securityId, ideaId }) => {
  const fundamentals = useFundamentalData(securityId);
  const idea = useIdea(ideaId);

  if (!fundamentals || !idea) {
    return <FundamentalsCellWrapper />;
  }

  const projRevGrowth = fundamentals[FUNDAMENTAL_SECURITY_DATA_KEYS.projectedRevenueGrowth];
  const forwardPE = fundamentals[FUNDAMENTAL_SECURITY_DATA_KEYS.forwardPE];
  const ownedAsOfDateFormatted = idea.admin_created_date;
  return (
    <FundamentalsCellWrapper>
      <FundamentalsRow>
        <FundamentalsLabel>
          <Body5 isLowContrast thin>
            <span className={'non-mobile'}>Proj. Revenue Growth</span>
            <span className={'mobile'}>Proj. Rev. Growth</span>
          </Body5>
          <InfoIconWrapper>
            <InfoIcon word={'tooltip_projected_revenue_growth'} style={{ top: '3px', right: '4px' }} />
          </InfoIconWrapper>
        </FundamentalsLabel>
        <FundamentalsValue>
          <Body5>{formatValueTo(DISPLAY_FORMAT_TYPES.PERCENTAGE, projRevGrowth)}</Body5>
        </FundamentalsValue>
      </FundamentalsRow>
      <FundamentalsRow>
        <FundamentalsLabel>
          <Body5 isLowContrast thin>
            Fwd P/E Ratio
          </Body5>
          <InfoIconWrapper>
            <InfoIcon word={'tooltip_forward_pe_ratio_next_year'} style={{ top: '3px', right: '4px' }} />
          </InfoIconWrapper>
        </FundamentalsLabel>
        <FundamentalsValue>
          <Body5>{formatValueTo(DISPLAY_FORMAT_TYPES.RATIO, forwardPE)}</Body5>
        </FundamentalsValue>
      </FundamentalsRow>
      <FundamentalsRow>
        <FundamentalsLabel>
          <Body5 isLowContrast thin>
            Owned As Of
          </Body5>
        </FundamentalsLabel>
        <FundamentalsValue>
          <Body5>{ownedAsOfDateFormatted}</Body5>
        </FundamentalsValue>
      </FundamentalsRow>
    </FundamentalsCellWrapper>
  );
};

const TableRow = ({ ideaId }) => {
  const idea = useIdea(ideaId);
  const navigateToSecurity = useNavigateToSecurity(idea?.security?.security_id);

  if (!idea) {
    return null;
  }

  const handleRowClick = () => {
    if (securityId) navigateToSecurity();
  };

  const { id, security } = idea;
  const { symbol, security_id: securityId, name } = security;
  return (
    <RowWrapper onClick={handleRowClick}>
      <IdentifierCell symbol={symbol} name={name} securityId={securityId} />
      <PerformanceCell securityId={securityId} ideaId={id} />
      <FundamentalsCell securityId={securityId} ideaId={id} />
    </RowWrapper>
  );
};

const Table = ({ userId }) => {
  const ideas = useIdeas(userId);

  if (!ideas) {
    return null;
  }

  return (
    <TableWrapper>
      {ideas.map((i) => (
        <TableRow key={i.id} ideaId={i.id} />
      ))}
    </TableWrapper>
  );
};

export const ThoughtLeaderIdeaPerformanceTable = ({ userId }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [didFail, setDidFail] = React.useState(false);

  const ideas = useIdeas(userId);

  React.useEffect(() => {
    const handleSuccess = () => setDidFail(false);
    const handleFailure = () => setDidFail(true);
    const init = async () => {
      setIsLoading(true);
      await _fetchData(userId, dispatch, handleSuccess, handleFailure);
      setIsLoading(false);
    };
    if (!ideas || ideas.length === 0) init();
  }, []);

  React.useEffect(() => {
    let addToLivePriceSecuritiesData;
    const securities = ideas?.map((i) => i.security);
    addToLivePriceSecuritiesData = securities?.map((securityData) => ({
      symbol: securityData.symbol,
      id: securityData.id,
    }));
    addToLivePriceSecuritiesData && activeSecuritiesTracker.addTempSecurities(addToLivePriceSecuritiesData);

    return () => {
      addToLivePriceSecuritiesData &&
        activeSecuritiesTracker.removeTempSecurities(addToLivePriceSecuritiesData?.map((s) => s.symbol));
    };
  }, [ideas]);

  if (isLoading) {
    return (
      <Container centerAll height={80}>
        <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.balls} />
      </Container>
    );
  }

  if (didFail) {
    const onRetry = async () => {
      const handleSuccess = () => setDidFail(false);
      const handleFailure = () => setDidFail(true);
      setIsLoading(true);
      await _fetchData(userId, dispatch, handleSuccess, handleFailure);
      setIsLoading(false);
    };
    return (
      <ErrorWrapper>
        <Body5>Something went wrong getting these positions, please try again.</Body5>
        <Container top={24}>
          <FlatButton onClick={onRetry}>Try Again</FlatButton>
        </Container>
      </ErrorWrapper>
    );
  }

  return <Table userId={userId} />;
};
