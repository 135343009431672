import React from 'react';
import Icon from '../misc/Icon';
import styled from 'styled-components';

const Fieldset = styled.fieldset`
  .date-field {
    position: relative;
    display: inline-block;
    margin: 0 5px;

    select {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      text-align: left !important;
      text-align-last: left !important;
    }
  }

  .date-field-month {
    width: 42%;
  }
  .date-field-day {
    width: 20.5%;
  }
  .date-field-year {
    width: 26.5%;
  }

  @media (max-width: 339px) {
    .date-field-month {
      width: 40%;
    }
  }
`;

export const DateField = (props) => {
  const months = [
    'Month',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const days = ['Day'];
  for (let i = 1; i <= 31; i++) {
    days.push(i);
  }
  const years = ['Year'];

  const yearRangeMin = props.yearMin !== undefined ? props.yearMin : 1900;
  const yearRangeMax = props.yearMax !== undefined ? props.yearMax : new Date().getFullYear();

  for (let i = yearRangeMin; i <= yearRangeMax; i++) {
    years.push(i);
  }

  function createOption(value, key) {
    return <option key={key}>{value}</option>;
  }

  const label = props.label;
  const name = props.name;

  const values = props.getValue(name);
  const { month, day, year } = values;

  const isError = !!props.getErrorMessage(name);
  const errorMessage = isError ? props.getErrorMessage(name) : '';

  return (
    <Fieldset className="date-dropdown">
      <label>{label}</label>
      <div
        tabIndex="0"
        className={`date-field date-field-month`}
        style={{ position: 'relative', display: 'inline-block' }}
      >
        <Icon
          size={'small'}
          icon={'fa-sort-asc'}
          colorClassName={' '}
          style={{ position: 'absolute', top: '23px', right: '2px' }}
        />
        <Icon
          size={'small'}
          icon={'fa-sort-desc'}
          colorClassName={' '}
          style={{ position: 'absolute', top: '24px', right: '2px' }}
        />
        <select
          name="month"
          value={month}
          onChange={(e) => {
            props.handleChange(e, { [name]: 'month' });
          }}
        >
          {months.map((month, i) => createOption(month, name + '-' + 'month' + '-' + month))}
        </select>
      </div>

      <div
        tabIndex="0"
        className={`date-field date-field-day`}
        style={{ position: 'relative', display: 'inline-block' }}
      >
        <Icon
          size={'small'}
          icon={'fa-sort-asc'}
          colorClassName={' '}
          style={{ position: 'absolute', top: '23px', right: '2px' }}
        />
        <Icon
          size={'small'}
          icon={'fa-sort-desc'}
          colorClassName={' '}
          style={{ position: 'absolute', top: '24px', right: '2px' }}
        />
        <select
          name="day"
          value={day}
          onChange={(e) => {
            props.handleChange(e, { [name]: 'day' });
          }}
        >
          {days.map((day, i) => createOption(day, name + '-' + 'day' + '-' + day))}
        </select>
      </div>
      <div
        tabIndex="0"
        className={`date-field date-field-year`}
        style={{ position: 'relative', display: 'inline-block' }}
      >
        <Icon
          size={'small'}
          icon={'fa-sort-asc'}
          colorClassName={' '}
          style={{ position: 'absolute', top: '23px', right: '2px' }}
        />
        <Icon
          size={'small'}
          icon={'fa-sort-desc'}
          colorClassName={' '}
          style={{ position: 'absolute', top: '24px', right: '2px' }}
        />
        <select
          name="year"
          value={year}
          onChange={(e) => {
            props.handleChange(e, { [name]: 'year' });
          }}
        >
          {years.map((year, i) => createOption(year, name + '-' + 'year' + '-' + year))}
        </select>
      </div>

      <p className={`text-field-error-message date-error`}>{errorMessage}</p>
    </Fieldset>
  );
};

export default DateField;
