import React from 'react';
import styled from 'styled-components';

import { FlatButton } from '../../main/components/buttons';
import { AgreementCheckbox, Body5 } from '../../main/lib/nvstr-common-ui.es';

const OptionsExperienceContent = styled.div`
  select {
    border-top: none;
    border-left: none;
    border-right: none;
    -webkit-appearance: auto;

    margin: 0 auto;
    max-width: 300px;
  }
`;

const InputContainer = styled.div``;

const Heading = styled.div`
  padding-top: 24px;
  padding-bottom: 40px;
`;

const ContinueWrapper = styled.div`
  padding-top: 40px;
  text-align: center;

  button {
    width: 225px;
  }
`;

const SubHeading = styled.div`
  padding-top: 0;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
`;

const AgreementWrapper = styled.div`
  padding-top: 24px;
  max-width: 500px;
  margin: 0 auto;
`;

const Agreement = styled.div`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.themeColors.border};
  padding: 16px;
  height: 300px;
  overflow: scroll;
`;
const CheckboxWrapper = styled.div`
  padding: 16px;
`;

export const OptionsAgreement = ({ onContinue }) => {
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [agreementsError, setAgreementsError] = React.useState(null);

  const onCheckboxChange = (name) => (value) => {
    setAgreementsError(null);
    setCheckboxValue(value);
  };

  const handleContinue = () => {
    if (!checkboxValue) {
      setAgreementsError('Required');
      return;
    }

    onContinue();
  };

  return (
    <OptionsExperienceContent>
      <Heading>
        <div className="full-page-card-heading">
          <span className={'text-wrap-wrapper'}>Options Agreement</span>
        </div>

        <div className="gradient-border-container">
          <div className="gradient-border" />
        </div>
      </Heading>

      <SubHeading>
        <Body5 isLowContrast>
          Please read through the agreement and acknowledge you understand your rights and obligations
        </Body5>
      </SubHeading>

      <AgreementWrapper>
        <Agreement>TBD</Agreement>
        <CheckboxWrapper>
          <AgreementCheckbox
            label={'I have read the Options Trading Agreement and I understand my rights and obligations under it.'}
            value={checkboxValue}
            onChange={onCheckboxChange}
            error={agreementsError}
          />
        </CheckboxWrapper>
      </AgreementWrapper>

      <ContinueWrapper>
        <FlatButton onClick={handleContinue}>Continue</FlatButton>
      </ContinueWrapper>
    </OptionsExperienceContent>
  );
};
