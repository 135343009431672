import { PROMOTIONS } from '../constants/types/promotions';
import { isCurrentUserLiveTrading, isCurrentUserOnboarding } from '../helpers/currentUserHelpers';

export const getCurrentUserFromState = (state) => state.currentUser;

export const getCurrentUserIdFromState = (state) => state.currentUser.id;

export const getIsCurrentUserLiveTradingFromState = (state) => isCurrentUserLiveTrading(getCurrentUserFromState(state));

export const getReferralCashRewardRequiresLiveAccountFromState = (state) =>
  getCurrentUserFromState(state).referral_cash_reward_requires_live_account;

export const getIsOnboardingFromState = (state) => isCurrentUserOnboarding(getCurrentUserFromState(state));

export const getAvailablePromotionsFromState = (state) => getCurrentUserFromState(state).available_promo_rewards;

export const returnThoughtLeaderIdentifier = (currentUser) =>
  currentUser.thought_leaders_labeled_famous_investors_test_group === 1 ? 'Famous Investor' : 'Thought Leader';

export const getLimitedTimeDepositBonusRewardFromAvailablePromotions = (availablePromos) => {
  if (!availablePromos) return null;

  let limitedTimeDepositPromo = null;
  availablePromos.forEach((availablePromotion) => {
    const qualifyingPromos = [
      PROMOTIONS.LIMITED_TIME_DEPOSIT_BONUS.FUNDING_PROMO_CASH_15_FOR_100,
      PROMOTIONS.LIMITED_TIME_DEPOSIT_BONUS.FUNDING_PROMO_REWARD_RANDOM_FOR_20,
      PROMOTIONS.LIMITED_TIME_DEPOSIT_BONUS.FUNDING_PROMO_CASH_20_FOR_20,
      PROMOTIONS.LIMITED_TIME_DEPOSIT_BONUS.FUNDING_PROMO_CASH_50_FOR_1000,
    ];
    if (qualifyingPromos.includes(availablePromotion.reward_type)) limitedTimeDepositPromo = availablePromotion;
  });
  return limitedTimeDepositPromo;
};
