import React from 'react';
import styled from 'styled-components';
import { Place, Row } from '@src/main/lib/nvstr-common-ui.es';
import { DollarAmount } from '../molecules/DollarAmount';
import InfoIcon from '../../../containers/UI/InfoIcon';
import MarketMonitor from '../../../containers/UI/MarketMonitor';
import { securityDataFormatTable } from '@src/helpers/securitiesHelpers';
import { returnChangeSymbolPrefix } from '@src/helpers/numberHelpers';
import NumberWithChangeIndicator from '../../../components/UI/NumberWithChangeIndicator';
import LoadingIcon from '../../../components/misc/LoadingIcon';
import { Container, Spacing, WarningNotice } from '../ui';
import { SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../ui/SvgLoadingIcon';
import { MissingPricingBanner } from '@src/main/components/Portfolio/MissingPricesBanner';

const MobileWrapper = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  font-size: 18px;
  border-radius: 4px;

  background-color: ${({ theme }) => theme.themeColors.componentNoOpacity};
`;
const PaddingWrapper = styled.div`
  padding: 16px;
`;
const Separator = styled.div`
  border: 1.2px solid ${({ theme }) => theme.themeColors.appBackground};
`;
const Label = styled.span`
  display: block;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  opacity: var(--de-emphasized-text-opacity);
  font-family: 'Bolton-Bold', sans-serif;
`;
const PortfolioValue = styled.span`
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.02em;

  @media (max-width: 365px) {
    font-size: 24px;
    line-height: 28px;
  }
`;
const PendingEquityWrapper = styled.div`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;

  padding-top: 8px;
`;
const PortfolioValueWrapper = styled.div`
  display: inline-block;
  min-width: 180px;
`;
const RowWrapper = styled(Row)`
  justify-content: space-between;
`;
const Spacer = styled.div`
  padding-left: 6px;
`;
const DayChangeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 2px;
`;
const PercentageChangeWrapper = styled.div``;
const PortfolioDataCellWrapper = styled.div`
  text-align: right;
  * {
    text-align: right;
  }

  i {
    font-size: 16px;
    line-height: 22px;
  }

  span {
    font-size: 12px;
    line-height: 20px;
  }

  .value-wrapper {
    font-weight: 600;
  }

  .fa-minus {
    top: -2px !important;
  }

  @media (max-width: 500px) {
    ${Label} {
      display: none;
    }
    ${DayChangeWrapper} {
      padding-top: 16px;
      display: block;
    }
    ${Spacer} {
      display: none;
    }
    ${PercentageChangeWrapper} {
      font-size: 12px !important;
    }
  }
`;
const MarketOverviewWrapper = styled.div`
  padding: 16px;

  span {
    font-size: 12px;
    line-height: 20px;
  }
`;
const FooterItem = styled.div`
  padding-right: 16px;
  padding-bottom: 16px;
`;
const FooterItemText = styled.div`
  font-size: 12px;
  line-height: 20px;
  opacity: var(--de-emphasized-text-opacity);

  .lt-d-last {
    font-size: 12px;
    line-height: 20px;
    opacity: var(--de-emphasized-text-opacity);
  }
`;
const ExtendedHoursValue = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 200;

  opacity: 0.6;
`;

export const MobilePortfolioSummary = ({
  missingPricesForSecurityIds,
  isPortfolioLoading,
  portfolioValue,
  pendingEquityValue,
  pendingEquityReasons,
  dayChange,
  isOpen,
  isInExtendedTrading,
  isExtendedPricingEnabled,
  extendedTradingPrefix,
  extendedHoursValue,
  extendedHoursValueAsOfTimeDisplay,
  pricingAsOfTimeDisplay,
  isLiveTradingApproved,
  isLiveTrading,
}) => {
  const isShowingExtendedPricing = !isOpen && !isExtendedPricingEnabled;
  const showAsOfPricingDisplay = pricingAsOfTimeDisplay && isShowingExtendedPricing;
  return (
    <div>
      <MobileWrapper>
        <PaddingWrapper>
          <RowWrapper>
            <PortfolioValueWrapper>
              <Label>Portfolio Value</Label>
              <Container top={8}>
                {isPortfolioLoading ? (
                  <Container centerAll height={36} width={80}>
                    <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.balls} />
                  </Container>
                ) : (
                  <PortfolioValue>
                    <DollarAmount value={portfolioValue} />
                  </PortfolioValue>
                )}
              </Container>
              {showAsOfPricingDisplay && (
                <FooterItem>
                  <FooterItemText>{pricingAsOfTimeDisplay}</FooterItemText>
                </FooterItem>
              )}
              {!isLiveTrading && (
                <div style={{ paddingTop: '8px' }}>
                  <WarningNotice message={'This is a simulated account - no real money is at risk.'} />
                </div>
              )}
              {pendingEquityValue > 0 && (
                <PendingEquityWrapper>
                  <Place style={{ display: 'inline-block' }}>
                    <span>{isLiveTradingApproved ? 'Pending: ' : 'Pending account approval: '}</span>
                    <DollarAmount value={pendingEquityValue} />
                    <InfoIcon
                      customMessage={pendingEquityReasons}
                      style={{ top: '0px', right: '-18px' }}
                      trackingMessage={'Pending Equity Reasons'}
                    />
                  </Place>
                </PendingEquityWrapper>
              )}
            </PortfolioValueWrapper>
            <Row>
              <PortfolioDataCellWrapper>
                <Label>Day Change</Label>
                <DayChangeWrapper>
                  {isPortfolioLoading || (dayChange && (dayChange.value === null || isNaN(dayChange.value))) ? (
                    <Row vAlign="center" padding="0 28px 0 0px">
                      <LoadingIcon icon="fading-3balls" size="small" style={{}} />
                    </Row>
                  ) : (
                    <>
                      <NumberWithChangeIndicator
                        valueFormat={'price'}
                        value={dayChange.value}
                        customDisplayValue={`${returnChangeSymbolPrefix(
                          dayChange.value
                        )}$${securityDataFormatTable.float(Math.abs(dayChange.value))}`}
                        coloredText
                      />
                      <Spacer />
                      <PercentageChangeWrapper>
                        <NumberWithChangeIndicator
                          valueFormat={'percentage'}
                          value={dayChange.percentage}
                          customDisplayValue={`${returnChangeSymbolPrefix(
                            dayChange.percentage
                          )}${securityDataFormatTable.float(Math.abs(dayChange.percentage))}%`}
                          coloredText
                          hideIcon
                        />
                      </PercentageChangeWrapper>
                    </>
                  )}
                </DayChangeWrapper>
              </PortfolioDataCellWrapper>
            </Row>
          </RowWrapper>
          {null && (
            <>
              {isExtendedPricingEnabled && isInExtendedTrading && extendedHoursValue !== null && (
                <ExtendedHoursValue>
                  <Container top={8} row verticallyCentered flexWrap>
                    {extendedTradingPrefix}:&nbsp;
                    <DollarAmount value={extendedHoursValue} />
                    &nbsp;
                    <NumberWithChangeIndicator
                      valueFormat={'percentage'}
                      value={dayChange.extended.percentage}
                      customDisplayValue={`${returnChangeSymbolPrefix(
                        dayChange.extended.percentage
                      )}${securityDataFormatTable.float(Math.abs(dayChange.extended.percentage))}%`}
                      hideIcon
                    />
                    <Spacing left={6} />
                    {extendedHoursValueAsOfTimeDisplay}
                  </Container>
                </ExtendedHoursValue>
              )}
            </>
          )}

          {missingPricesForSecurityIds?.length > 0 && (
            <Container top={8}>
              <MissingPricingBanner missingPricesForSecurityIds={missingPricesForSecurityIds} />
            </Container>
          )}
        </PaddingWrapper>
        <Separator />
        <MarketOverviewWrapper>
          <MarketMonitor inline />
        </MarketOverviewWrapper>
      </MobileWrapper>
    </div>
  );
};
