import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const footerAdapter = {
  getFooterPartial: () => {
    return sendRequest('get', [`${URL}/footer`]).then((response) => response);
  },
};
