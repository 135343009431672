import React from 'react';

/*
  Required Props:
    children,
    resultData -> any data needed to pass into event handlers

  Optional Props:
    className,

    handleMouseOver,
    handleMouseOut,
    handleClick,

*/

class ReusableResult extends React.Component {
  componentDidMount() {
    this._addListeners();
  }

  componentWillUnount() {
    this._removeListeners();
  }

  render() {
    const { className } = this.props;

    return (
      <li
        ref={(el) => (this.Result = el)}
        className={`query-result ${className || ''}`}
        onMouseOver={this._handleMouseOver}
      >
        {this.props.children}
      </li>
    );
  }

  _returnResultData = () => this.props.resultData;

  _handleClick = () => {
    if (this.props.handleClick) {
      this.props.handleClick(this._returnResultData());
    }
  };

  _handleMouseOver = () => {
    if (this.props.handleMouseOver) {
      this.props.handleMouseOver(this._returnResultData());
    }
  };

  _handleMouseOut = () => {
    if (this.props.handleMouseOut) {
      this.props.handleMouseOut(this._returnResultData());
    }
  };

  _returnDOMNode = () => this.Result;
  _$returnDOMNode = () => $(this._returnDOMNode());

  _addListeners = () => {
    this._$returnDOMNode().on('click', this._handleClick);
  };

  _removeListeners = () => {
    this._$returnDOMNode().off('click', this._handleClick);
  };
}

export default ReusableResult;
