export const ContextTypes = {
  SECURITY_PANEL: 'Security Panel',
  NEWS_NOTIFICATION: 'News Notification',
  FEED: 'Feed',
  NOTIFICATIONS_FEED: 'Notifications - Feed',
};

export const ViewUsersListContexts = {
  IDEA_PERFORMANCE_NOTIFICATION: 'Idea Performance Notification',
  PERFORMANCE_RANKING_NOTIFICATION: 'Performance Ranking Notification',
};
