export const ORDER_TRANSACTION_TYPES = {
  buy: 'buy',
  sell: 'sell',
};

export const ORDER_TYPES = {
  market: 'market',
  limit: 'limit',
  stop: 'stop',
  stopLimit: 'stop_limit',
};

export const ORDER_VALUE_TYPES = {
  dollars: 'dollars',
  shares: 'shares',
};
