import { logMetricsTrackingEvent } from '../../../actions';

const VIEW_SELECT_VPC_BANNER = {
  send: (properties) => {
    const event = 'View Select VPC Banner';
    logMetricsTrackingEvent(event, properties)();
  },
};
const CLICK_SELECT_VPC_BANNER = {
  send: (properties) => {
    const event = 'Click Select VPC Banner';
    logMetricsTrackingEvent(event, properties)();
  },
};

const VIEW_BANNER = {
  send: (properties) => {
    const event = 'View Banner';
    logMetricsTrackingEvent(event, properties)();
  },
};
const CLICK_BANNER_CTA = {
  send: (properties) => {
    const event = 'Click Banner CTA';
    logMetricsTrackingEvent(event, properties)();
  },
};
const DISMISS_BANNER_CTA = {
  send: (properties) => {
    const event = 'Dismiss Banner';
    logMetricsTrackingEvent(event, properties)();
  },
};

const trackingEvents = {
  VIEW_SELECT_VPC_BANNER,
  CLICK_SELECT_VPC_BANNER,

  VIEW_BANNER,
  CLICK_BANNER_CTA,
  DISMISS_BANNER_CTA,
};

export default trackingEvents;
