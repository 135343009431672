import React from 'react';
import { useSelector } from 'react-redux';
import { isUndefinedOrNull } from '@src/helpers/generalHelpers';

export const useSecurityLookup = () => {
  const store = useSelector((state) => state.securities.lookup);
  return store;
};

const getSecurityFromState = (state, id) => state.securities.lookup[id] || null;

export const useSecurity = (id) => {
  const storeSecurity = useSelector((state) => getSecurityFromState(state, id));

  const [security, setSecurity] = React.useState(storeSecurity || {});

  React.useEffect(
    () => {
      if (security !== storeSecurity && !isUndefinedOrNull(storeSecurity)) {
        setSecurity(storeSecurity);
      }
    },
    [storeSecurity, security]
  );

  return security;
};
