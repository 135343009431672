import React from 'react';
import styled from 'styled-components';
import { Container } from '@src/main/components/ui';
import { Body5 } from '@src/main/lib/nvstr-common-ui.es';
import { DATA_KEYS } from '@src/main/containers/learn/admin/index';
import { FlatButton } from '@src/main/components/buttons';

const DebugWrapper = styled.div`
  * {
    color: ${({ theme }) => theme.themeColors.text} !important;
  }
`;

const extractLessonTextFromNode = (node, lessonContent) => {
  let lessonData = {};
  const { children, innerText, nodeName, style } = node;
  if (children?.length > 0) {
    children.forEach((n) => extractLessonTextFromNode(n, lessonContent));
    return;
  }
  if (nodeName === 'SPAN') {
    lessonData.text = innerText;

    const fontWeight = style.fontWeight;
    if (fontWeight === '700') {
      lessonData.bold = true;
    }
  }
  if (nodeName === 'BR') {
    lessonData.lineBreak = true;
  }
  lessonContent.push(lessonData);
};

const isSlideStart = (text) => {
  // Slide 1:
  return text.slice(0, 5) === 'Slide' && text.slice(7, 8) === ':';
};

let nodeId = 1;
const doesNodeHaveMoreNodes = (node) => {
  nodeId += 1;
  return node.children?.length > 0;
};

const parseNodeContentIntoPageContent = (node, pages) => {
  const { innerText } = node;
  if (isSlideStart(innerText)) {
    pages.push([]);
  } else {
    const currentPage = pages[pages.length - 1];
    if (currentPage) {
      currentPage.push(node);
    }
  }
};

const parseBodyNodes = (node, pages) => {
  if (doesNodeHaveMoreNodes(node)) {
    const childrenNodes = node.children;
    childrenNodes.forEach((node) => {
      parseBodyNodes(node, pages);
    });
  } else {
    parseNodeContentIntoPageContent(node, pages);
  }
};

const parsePagesFromBody = (body) => {
  nodeId = 1;
  const pages = [];

  body.forEach((node) => {
    parseBodyNodes(node, pages);
  });

  return pages;
};

const findBodyInElement = (el) => {
  const firstLayer = el.children[1].children;
  if (firstLayer[0].tagName === 'B') {
    const secondLayer = firstLayer[0].children;
    return secondLayer;
  }
  return firstLayer;
};

const stripExtractedLessonContent = (c) => {
  const stripped = [];
  c.forEach((page) => {
    const strippedPage = { body: [] };
    const body = page.body;
    body.forEach((el, i) => {
      if (i === 0 && !el.text) {
        return;
      }
      if (i === body.length - 1 && !el.text) {
        return;
      }

      strippedPage.body.push(el);
    });
    stripped.push(strippedPage);
  });
  return stripped;
};

export const LessonContentImport = ({ onChange, key, label }) => {
  const [isDebugging, setIsDebugging] = React.useState(false);
  const [debugHTML, setDebugHTML] = React.useState(null);
  const [v, setV] = React.useState('');

  const handleChange = (e) => {
    // setV(e.target.value);
  };

  const parseRichTextForFormattedContent = (richText) => {
    const el = document.createElement('html');
    el.innerHTML = richText;
    setDebugHTML(richText);
    const body = findBodyInElement(el);
    const extractedLessonContent = [];
    const pages = parsePagesFromBody(body);
    pages.forEach((nodes, index) => {
      extractedLessonContent.push({ body: [] });
      nodes.forEach((node) => {
        extractLessonTextFromNode(node, extractedLessonContent[index].body);
      });
    });
    return extractedLessonContent;
  };

  const onPaste = (e) => {
    const richText = e.clipboardData.getData('text/html');
    const extractedLessonContent = parseRichTextForFormattedContent(richText);
    const strippedExtractedLessonContent = stripExtractedLessonContent(extractedLessonContent);
    onChange(DATA_KEYS.contents, JSON.stringify(strippedExtractedLessonContent));
    console.log(JSON.stringify(strippedExtractedLessonContent));
    setV(JSON.stringify(strippedExtractedLessonContent));
  };

  return (
    <>
      <Container bottom={8}>
        <Body5>Lesson {label}</Body5>
      </Container>
      <textarea onPaste={onPaste} onChange={handleChange} value={v} />
      <Container top={8}>
        {isDebugging ? (
          <FlatButton transparent onClick={() => setIsDebugging(false)}>
            HIDE
          </FlatButton>
        ) : (
          <FlatButton transparent onClick={() => setIsDebugging(true)}>
            SEE RICH TEXT
          </FlatButton>
        )}
      </Container>
      {isDebugging && <DebugWrapper>{debugHTML?.toString()}</DebugWrapper>}
    </>
  );
};
