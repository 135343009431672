import { collectivesAdapter } from '../adapters/collectivesAdapter';

import { FETCH_COLLECTIVES, ADD_COLLECTIVES } from '../constants';

export const fetchCollectives = () => {
  return function (dispatch) {
    return collectivesAdapter.fetchCollectives().then((response) => {
      if (response && response.data && response.data.collectives) {
        const collectives = response.data.collectives;
        dispatch({
          type: ADD_COLLECTIVES,
          payload: { collectives },
        });
      } else {
        // TODO: failed
        console.error('Fetch collective failed');
      }
      return response;
    });
  };
};

export const fetchCollective = (userId) => {
  return function (dispatch) {
    return collectivesAdapter.fetchCollective(userId).then((response) => {
      if (response && response.data && response.data.users) {
        const collectives = response.data.users;
        dispatch({
          type: ADD_COLLECTIVES,
          payload: { collectives },
        });
      } else {
        // TODO: failed
        console.error('Fetch collective failed');
      }
      return response;
    });
  };
};
