import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClickEventComponent from './EventActionWrappers/ClickEventComponent';
import { ActionSwitchNoEventHandling } from '../buttons/ActionSwitchNoEventHandling';
import { COLOR_THEME_NAMES } from '@src/main/lib/nvstr-utils.es';
import { changeColorScheme, logMetricsTrackingEvent } from '../../actions/index';

const ColorThemeToggle = () => {
  const currentColorTheme = useSelector((state) => state.colorTheme?.value);
  const dispatch = useDispatch();

  const _changeCurrentUserColorScheme = () => {
    const desiredColorTheme =
      currentColorTheme === COLOR_THEME_NAMES.light ? COLOR_THEME_NAMES.dark : COLOR_THEME_NAMES.light;
    changeColorScheme(desiredColorTheme)(dispatch);
    _logColorThemeChange(desiredColorTheme);
  };

  const _logColorThemeChange = (newColorTheme) => {
    const event = 'Changed Color Theme';
    const properties = {
      'Current Color Theme': currentColorTheme || 'No Color Scheme',
      'New Color Theme': newColorTheme || 'No Color Scheme',
    };
    logMetricsTrackingEvent(event, properties)();
  };

  const handleActionSwitchClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    _changeCurrentUserColorScheme();
  };

  return (
    <div className={`color-theme-toggle`}>
      <ClickEventComponent
        key={'color-theme-nav-toggle'}
        id="color-theme-nav-toggle"
        handleClick={handleActionSwitchClick}
      >
        <ActionSwitchNoEventHandling name="dark-theme-switch" value={currentColorTheme === COLOR_THEME_NAMES.dark} />
      </ClickEventComponent>
    </div>
  );
};

export default ColorThemeToggle;
