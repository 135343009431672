import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/index';

import { Link } from 'react-router-dom';

export class LinkTo extends Component {
  render() {
    return (
      <span
        style={this.props.customContainerStyle || {}}
        onClick={this._handleClick}
        onMouseOver={this._handleMouseOver}
        onMouseOut={this._handleMouseOut}
      >
        {this._renderLink()}
      </span>
    );
  }

  _shouldCreatePageLoad = () => {
    return this.props.fullPageLoad;
  };

  _renderLink = () => {
    return this._shouldCreatePageLoad() ? this._renderAnchorComponent() : this._renderLinkComponent();
  };

  _renderLinkComponent = () => {
    return (
      <Link to={this.props.to} className={this.props.className} style={this._returnStyling()}>
        {this._renderChildren()}
      </Link>
    );
  };

  _renderAnchorComponent = () => {
    return (
      <a
        href={this.props.to}
        className={this.props.className}
        style={this._returnStyling()}
        {...(this.props.anchorProps || {})}
      >
        {this._renderChildren()}
      </a>
    );
  };

  _renderChildren = () => this._returnChildren();

  _returnChildren = () => {
    const { child, text, children } = this.props;
    if (!child && !text && !children) {
      console.error('Must supply child or text to LinkTo Component');
      return null;
    }
    return children || child || <span>{text}</span>;
  };

  _returnStyling = () => {
    if (this.props.noTextDecoration) {
      return {
        textDecoration: 'none',
        ...(this.props.styling || {}),
      };
    }
    return {
      ...(this.props.styling || {}),
    };
  };

  _scrollToTop = () => {
    $('html, body').animate({ scrollTop: 0 }, 150);
  };

  _shouldScrollToTop = () => {
    return this.props.scrollToTop;
  };

  _handleClick = () => {
    if (this.props.additionalClickAction) {
      this.props.additionalClickAction();
    }

    if (this._shouldScrollToTop()) {
      this._scrollToTop();
    }

    if (this.props.closeModal) {
      this.props.actions.hideModal();
    }
  };

  _handleMouseOver = () => {
    if (this.props.onMouseOver) {
      this.props.onMouseOver();
    }
  };

  _handleMouseOut = () => {
    if (this.props.onMouseOut) {
      this.props.onMouseOut();
    }
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(LinkTo);
