import React from 'react';
import { connect } from 'react-redux';
import { addKarmaPoints, showInfoIconModal } from '../../actions';
import KarmaLabel from './KarmaLabel';
import KarmaPointEarnedAnimation from './KarmaPointEarnedAnimation';
import KarmaTotal from './KarmaTotalValue';
import KarmaMoreInfo from './KarmaMoreInfo';

import { getCurrentUserKarmaLabelFromState, getKarmaTotalFromState } from '../../selectors/karma';
import KarmaEarnedBackgroundFlash from './KarmaEarnedBackgroundFlash';
import { withRouter } from '../../main/utils';

class KarmaHeaderDisplay extends React.PureComponent {
  // componentDidMount () {
  // const {
  //   dispatch
  // } = this.props;
  // const value = 1;
  //
  // setTimeout(
  //   () => {
  //     addKarmaPoints(value)(dispatch);
  //   },
  //   500
  // )
  //
  // this.int = setInterval(() => {
  //   addKarmaPoints(value)(dispatch);
  // }, 5000)
  // }

  // componentWillUnmount () {
  // if (this.int)
  //   clearInterval(this.int)
  // }

  render() {
    const karmaLabelFontSize = '10px';
    const karmaPointsFontSize = this.getKarmaPointTotal() > 9999 ? '10px' : '12px';

    return (
      <div>
        <a
          ref={(el) => (this.DisplayContainer = el)}
          className={'no-text-decoration karma-label'}
          style={{
            display: 'block',
            height: '40px',
            width: '65px',
            cursor: 'pointer',
          }}
          onClick={this.handleClick}
        >
          <div
            style={{
              position: 'relative',
              height: '40px',
              width: '65px',
              borderRadius: '2px',
              padding: '5px 5px 5px 5px',
            }}
          >
            <KarmaEarnedBackgroundFlash />

            <div className={'text-center'} style={{ lineHeight: karmaLabelFontSize }}>
              <KarmaLabel fontSize={karmaLabelFontSize} />
            </div>
            <div>
              <KarmaPointEarnedAnimation>
                {(animationValue) => (
                  <div className={'text-center'} style={{ lineHeight: karmaPointsFontSize, marginTop: '4px' }}>
                    <KarmaTotal fontSize={karmaPointsFontSize} value={animationValue} />
                  </div>
                )}
              </KarmaPointEarnedAnimation>
            </div>
          </div>
        </a>
      </div>
    );
  }

  handleClick = () => {
    this.showKarmaInfoModal();
  };

  showKarmaInfoModal = () => {
    const { dispatch } = this.props;
    const data = {
      elementRef: this.DisplayContainer,
      useBottomRightAsAnchor: true,

      definitionKey: 'tooltip_karma',
      CustomComponent: KarmaMoreInfo,
    };
    showInfoIconModal(data)(dispatch);
  };

  getKarmaPointTotal = () => this.props.karmaTotal;
}

const mapStateToProps = (state) => {
  return {
    karmaLabelText: getCurrentUserKarmaLabelFromState(state),
    karmaTotal: getKarmaTotalFromState(state),
  };
};

const connectedComponent = connect(mapStateToProps)(KarmaHeaderDisplay);
export default withRouter(connectedComponent);
