import React from 'react';
import { connect } from 'react-redux';
import DesktopVerticalNav from './verticalNavComponents/DesktopVerticalNav';
import MobileVerticalNav from './verticalNavComponents/MobileVerticalNav';
import { isCurrentUserOnboarding } from '../../helpers/currentUserHelpers';
import styled from 'styled-components';

const Wrapper = styled.div`
  .vertical-nav-header {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 64px;
    padding: 0 0 0 15px;
  }
  .vertical-nav-header {
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
  }
  .vertical-nav-dismiss-container {
    position: absolute;
    top: 10px;
    right: 15px;
  }
`;

const VerticalNav = (props) => {
  const {
    nav,

    isOnboarding,
  } = props;

  const { alwaysOpen } = nav;

  if (isOnboarding) {
    return null;
  }

  return <Wrapper>{alwaysOpen ? <DesktopVerticalNav /> : <MobileVerticalNav />}</Wrapper>;
};

const mapStateToProps = (state) => {
  return {
    nav: state.nav,
    isOnboarding: isCurrentUserOnboarding(state.currentUser),
  };
};

export default connect(mapStateToProps)(VerticalNav);
