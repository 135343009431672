import { isUndefinedOrNull } from './generalHelpers';

export const serializeResponseForPortfolioData = (response) => {
  if (!response.users) {
    console.error('Could not find data in response from current user.');
    return {};
  }

  const currentUserData = response.users[0];
  const acceptableKeys = [
    'cash',
    'buying_power',
    'equity',
    'positions',
    'price_quotes_info',
    'total_day_change',
    'total_day_change_percent',
    'year_to_date_change',
    'year_to_date_change_percent',
  ];

  // this will prevent undefined or null data from making it into store, non of these keys should ever be undefined or null
  const acceptableData = {};
  acceptableKeys.forEach((key) => {
    if (!isUndefinedOrNull(currentUserData[key])) {
      acceptableData[key] = currentUserData[key];
    }
  });
  return acceptableData;
};
