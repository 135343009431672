import React from 'react';
import { useSelector } from 'react-redux';
import { returnIdeaFromIdeaStore } from '../../../helpers/ideaHelpers';
import { useUserIdeasPerformance } from './useIdeaPerformance';

const findUserIdeas = (userIdeaLookup, userId) => {
  if (!userIdeaLookup) {
    return null;
  }
  const ideaKeys = Object.keys(userIdeaLookup);
  const userIdeas = [];

  for (let i = 0; i < ideaKeys.length; i++) {
    let ideaId = ideaKeys[i];
    let idea = userIdeaLookup[ideaId];
    if (idea.user_id === userId) {
      userIdeas.push(idea);
    }
  }
  return userIdeas;
};

export const useIdeaForSecurity = (userId, securityId) => {
  const ideaLookup = useSelector((state) => state.ideas.ideaList);
  const ideas = findUserIdeas(ideaLookup, userId);

  const [idea, setIdea] = React.useState(ideas.filter((i) => i.security.id === securityId)[0] || null);

  React.useEffect(
    () => {
      const ideas = findUserIdeas(ideaLookup, userId);
      setIdea(ideas.filter((i) => i.security.id === securityId)[0] || null);
    },
    [ideaLookup, userId]
  );

  return idea;
};

export const useIdea = (id) => {
  return useSelector((state) => returnIdeaFromIdeaStore(id, state.ideas));
};
