import React, { useState } from 'react';
import { connect } from 'react-redux';

import { addIdeas, createAndPlaceOrder, placeOrders, fetchCurrentUserQuickInfo, getOrders } from '../../actions';

import PlacingOrders from '../../containers/Orders/components/Overlays/PlacingOrders';
import OrdersPlacedMessage from '../../containers/Orders/components/Overlays/OrdersPlacedMessage';
import OrderWarnings from '../../containers/Orders/components/OrderWarnings';
import OrderErrors from '../../containers/Orders/components/OrderErrors';
import TradeAddProConPanel from '../../containers/Orders/TradeAddProConPanel';

import {
  doesTradesRequireApproval,
  returnCurrentUserId,
  returnCurrentUserThemeTestGroups,
  isCurrentUserLiveTrading,
} from '../../helpers/currentUserHelpers';
import {
  createIdeasFromOrdersIfNoIdeaExists,
  isOptimizedTrade,
  calculateCommissionsFromGeneratedOrders,
  sortAndFormatOrdersDataForAddProConPanel,
} from '../../helpers/ordersHelpers';
import { sendFacebookTrackingEvent, sendFacebookLTVEvent } from '../../constants/facebookTracking';

import { throwError } from '../../helpers/devToolHelpers';
import { eventTrackingHandlers } from '../../constants/eventTracking';
import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import { TrackingEvents } from '../../utils/tracking/events';

const logPlaceOrdersClick = eventTrackingHandlers.orderEvents.click.placeOrders;

const PlaceTrades = (props) => {
  const {
    currentUserId,
    currentUser,
    ideasLookup,

    doOrdersRequireApproval,

    handleDismiss,

    dispatch,
    children,
  } = props;

  const [isPlacingOptimizedOrder, setPlacingOptimizedOrder] = useState(false);
  const [placingOrders, setPlacingOrders] = useState([]);
  const [isPlacingOrders, setIsPlacingOrders] = useState(false);
  const [showPlacedOrdersSuccess, setShowPlacedOrdersSuccess] = useState(false);
  const [orderWarnings, setOrderWarnings] = useState([]);
  const [orderErrors, setOrderErrors] = useState([]);
  const [placedOrdersForAddProConPanel, setPlacedOrdersForAddProConPanel] = useState([]);

  const placingOrdersCount = (placingOrders || []).length;

  const arePlaceOrderWarnings = orderWarnings.length > 0;
  const arePlaceOrderErrors = orderErrors.length > 0;

  const wasOrderPlaced = placedOrdersForAddProConPanel.length > 0;
  const isAddProConPanelShowing = !showPlacedOrdersSuccess && wasOrderPlaced;

  const isLiveTrading = isCurrentUserLiveTrading(currentUser);

  const defaultFacebookTrackingParams = {
    themeTestGroups: returnCurrentUserThemeTestGroups(currentUser),
    accountType: isLiveTrading ? 'Live' : 'Paper',
  };
  const logPlaceOrdersSuccessInFacebook = (params) =>
    sendFacebookTrackingEvent('Place Trade', {
      ...params,
      ...defaultFacebookTrackingParams,
    });

  const refreshUserData = () => {
    setTimeout(() => fetchCurrentUserQuickInfo()(dispatch), 1000);
  };

  const refreshOrdersData = () => {
    setTimeout(() => getOrders()(dispatch), 1000);
    setTimeout(() => getOrders()(dispatch), 5000);
  };

  const refreshData = () => {
    refreshUserData();
    refreshOrdersData();
  };

  const _clearState = () => {
    // resets to default state - should batch these otherwise explore api for this functionality
    setPlacingOptimizedOrder(false);
    setPlacingOrders([]);
    setIsPlacingOrders(false);
    setShowPlacedOrdersSuccess(false);
    setOrderWarnings([]);
    setOrderErrors([]);
    setPlacedOrdersForAddProConPanel([]);
  };

  const handleAcceptAllWarnings = (warnings) => {
    if (!(placingOrders.length > 0)) {
      setOrderErrors(['Something went wrong, please refresh and try again.']);
      return throwError('Attempting to place orders but none were found in state', true, { placingOrders });
    }
    handlePlaceOrders(placingOrders, warnings);
  };

  const handlePlaceOrdersCancel = () => {
    _clearState();
  };

  const handleDismissAddProConPanel = () => {
    setPlacedOrdersForAddProConPanel([]);
    handleDismiss && handleDismiss();
  };

  const showAddProConPanelForPlacedOrders = () => {
    handleDismissShowPlacedOrders();
  };

  const handleDismissShowPlacedOrders = () => {
    setShowPlacedOrdersSuccess(false);
  };

  const handlePlaceOrdersResponse = (response, ordersForWarning) => {
    setOrderWarnings([]);
    setOrderErrors([]);
    setPlacingOptimizedOrder(isOptimizedTrade(ordersForWarning));
    setPlacingOrders(ordersForWarning);
    setIsPlacingOrders(false);

    const wereOrdersPlacedSuccessfully =
      response && response.data && response.data.status && response.data.status === 'success';
    if (wereOrdersPlacedSuccessfully) {
      const placedOrders = response.data.orders;

      const facebookTrackingParams = {
        tradeType: isOptimizedTrade(placedOrders) ? 'Optimized' : 'Manual',
        num_items: placedOrders.length,
        value: calculateCommissionsFromGeneratedOrders(placedOrders),
      };
      logPlaceOrdersSuccessInFacebook(facebookTrackingParams);

      if (isLiveTrading) {
        sendFacebookLTVEvent(10);
      }

      setShowPlacedOrdersSuccess(true);
      refreshData();
      setPlacedOrdersForAddProConPanel(placedOrders);
    } else {
      const defaultErrorMessage = 'Something went wrong. Please try again later.';
      if (!response || !response.data || !response.data.status) {
        return setOrderErrors([defaultErrorMessage]); // return because request failed or there is a server error
      }
      const { status, orders, error, warnings } = response.data;
      if (status === 'error') {
        // error trumps warnings
        setOrderErrors([error || defaultErrorMessage]);
      }
      if (status === 'success_with_error') {
        setOrderErrors([error || defaultErrorMessage]);
        const ordersPlacedSuccessfully = orders;
        const facebookTrackingParams = {
          tradeType: isOptimizedTrade(ordersPlacedSuccessfully) ? 'Optimized' : 'Manual',
          num_items: ordersPlacedSuccessfully.length,
          value: calculateCommissionsFromGeneratedOrders(ordersPlacedSuccessfully),
        };
        logPlaceOrdersSuccessInFacebook(facebookTrackingParams);
        refreshData();
        setPlacedOrdersForAddProConPanel(ordersPlacedSuccessfully);
      }
      if (status === 'warning') {
        setOrderWarnings(warnings);
      }
    }
  };

  const handlePlaceOrders = async (orders, acceptedWarnings = []) => {
    // orders can be already generated with ids, or without
    setIsPlacingOrders(true);
    logPlaceOrdersClick(arePlaceOrderWarnings);

    const _saveNewIdeas = async (ideas) =>
      addIdeas(ideas)(dispatch).then((response) => {
        const ok = response && response.ideas && response.ideas.length > 0;
        // TODO: stricter error checking in ideas, for now assumes if api returned ideas, they should have saved correctly with no errors
        if (ok) {
          return {
            ok,
          };
        } else {
          return {
            error: (response && response.error) || 'Something went wrong. Please try again.',
          };
        }
      });

    let ideaSavedStatus = { ok: true };
    const newIdeasFromOrders = createIdeasFromOrdersIfNoIdeaExists(orders, currentUserId, ideasLookup);
    if (newIdeasFromOrders.length > 0) {
      ideaSavedStatus = await _saveNewIdeas(newIdeasFromOrders);
    }
    if (ideaSavedStatus.ok) {
      const wereOrdersAlreadyGenerated = orders.every((order) => !isUndefinedOrNull(order.order_id));
      if (wereOrdersAlreadyGenerated) {
        const ordersListById = orders.map((order) => order.order_id);
        const acceptedWarningTypes = acceptedWarnings.map((warningData) => warningData.type);
        placeOrders(
          ordersListById,
          acceptedWarningTypes
        )(dispatch).then((placeOrdersResponse) => {
          if (placeOrdersResponse.ok) {
            handlePlaceOrdersResponse(placeOrdersResponse, orders);
          } else {
            setIsPlacingOrders(false);
            const error = placeOrdersResponse.error;
            setOrderErrors([error]);
          }
        });
      } else {
        if (orders.length > 1) {
          throwError('Received more than one order to create and place order method', true, { orders });
        }
        const order = orders[0];
        const acceptedWarningTypes = acceptedWarnings.map((warningData) => warningData.type);
        createAndPlaceOrder(
          order,
          acceptedWarningTypes
        )(dispatch).then((placeOrdersResponse) => {
          if (placeOrdersResponse.ok) {
            handlePlaceOrdersResponse(placeOrdersResponse, orders);
          } else {
            setIsPlacingOrders(false);
            const error = placeOrdersResponse.error;
            setOrderErrors([error]);
          }
        });
      }
    } else {
      setIsPlacingOrders(false);
      const error = ideaSavedStatus.error;
      setOrderErrors([error]);
    }
  };

  const shouldRestrictDismissal = () => {
    if (isPlacingOrders) {
      return true;
    }
    if (showPlacedOrdersSuccess) {
      return false;
    }
    if (arePlaceOrderWarnings) {
      return true;
    }
    if (arePlaceOrderErrors) {
      return false;
    }
    if (placedOrdersForAddProConPanel.length > 0) {
      return false;
    }
  };

  const renderPlacingOrdersState = () => {
    // state machine for place orders lifecycle messages
    if (isPlacingOrders) {
      return <PlacingOrders />;
    }
    if (showPlacedOrdersSuccess) {
      return (
        <OrdersPlacedMessage
          ordersCount={placingOrdersCount}
          requiresApproval={doOrdersRequireApproval}
          handleDismiss={handleDismissShowPlacedOrders}
        />
      );
    }
    if (arePlaceOrderErrors) {
      return <OrderErrors errors={orderErrors} onCloseClick={handlePlaceOrdersCancel} />;
    }
    if (arePlaceOrderWarnings) {
      return (
        <OrderWarnings
          ordersCount={placingOrdersCount}
          isOptimizedOrder={isPlacingOptimizedOrder}
          warnings={orderWarnings}
          handleAcceptAllWarnings={handleAcceptAllWarnings}
          handleCancel={handlePlaceOrdersCancel}
        />
      );
    }
    if (placedOrdersForAddProConPanel.length > 0) {
      return (
        <TradeAddProConPanel
          orders={sortAndFormatOrdersDataForAddProConPanel(placedOrdersForAddProConPanel)}
          closePanel={handleDismissAddProConPanel}
        />
      );
    }
  };

  return (
    <div className="place-orders">
      {children({
        handlePlaceOrders,
        renderPlacingOrdersState,
        shouldRestrictDismissal: shouldRestrictDismissal(),
        showAddProConPanelForPlacedOrders,
        wasOrderPlaced,
        isAddProConPanelShowing,
        clearPlaceOrdersState: _clearState,
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const ideasStore = state.ideas;
  const currentUser = state.currentUser;
  return {
    doOrdersRequireApproval: doesTradesRequireApproval(currentUser),
    currentUser,
    currentUserId: returnCurrentUserId(currentUser),

    ideasLookup: ideasStore.ideaList,
  };
};

export default connect(mapStateToProps)(PlaceTrades);
