import React from 'react';

import RebalancerContainer from './RebalancerContainer';
import Page from '../../components/layout/Page';

class RebalancerPage extends React.PureComponent {
  render() {
    return (
      <Page>
        <div className={'optimizer-container optimizer-on-page'}>
          <RebalancerContainer renderOnPage />
        </div>
      </Page>
    );
  }
}

export default RebalancerPage;
