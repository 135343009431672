import React from 'react';

export const Checkbox = (props) => {
  const name = props.name;
  const checked = props.isChecked(name) ? 'checked' : '';
  const handleToggleClick = () => {
    props.handleToggleClick(name);
  };

  return (
    <div className="checkbox-container">
      <div name={name} className={`checkbox ${checked}`} onClick={handleToggleClick}></div>
    </div>
  );
};
export default Checkbox;
