const getModalText = (children) => {
  if (typeof children === 'string') {
    return children;
  }

  // todo for modals without title and message such as Credit Card input
  if (children?.props?.viewingContext) {
    return children.props.viewingContext;
  }

  // element has multiple childs
  if (Array.isArray(children)) {
    return children
      .map(getModalText)
      .filter((i) => !!i)
      .join(', ');
  }

  if (children?.props?.children) {
    return getModalText(children?.props?.children);
  }
};

export default getModalText;
