import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/index';
import SectionHeading from './components/SectionHeading';
import {
  isCurrentUserLiveTrading,
  currentUserHasMembership,
  currentUserMembershipMonthlyFeeAmount,
  currentUserMembershipMonthlyFreeTradeLimit,
  currentUserMembershipFeeBalance,
  currentUserMembershipChargeCycleResetsAt,
} from '@src/helpers/currentUserHelpers';
import { returnPathTo } from '@src/constants/paths';
import { parseQueryString, createQueryString } from '@src/helpers/routerHelpers';
import { formatLocalizedDateTime, moment } from '@src/helpers/timeHelpers';
import { withRouter } from '@src/main/utils';
import { FlatButton } from '@src/main/components/buttons';
import { Container } from '@src/main/components/ui';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { webappRoutes } from '@src/main/lib/nvstr-utils.es';

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  button {
    width: 262px;
    padding: 8px 16px;
  }
`;

const TrustedContactFormLink = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(webappRoutes.trustedContact);
  };
  return (
    <Container fullWidth centerAll top={16}>
      <ActionWrapper className={`action-container`}>
        <FlatButton onClick={handleClick}>Update Trusted Contact</FlatButton>
      </ActionWrapper>
    </Container>
  );
};

class LiveAccountSettingsForm extends React.Component {
  render() {
    return (
      <div className="live-account-fontSettings-form-container">
        <Container bottom={16}>
          <SectionHeading headingText={'Live Brokerage Account'} />
        </Container>
        <>
          {this.isLiveTrading() ? (
            <>
              {currentUserHasMembership(this.returnCurrentUserData()) && (
                <div className="membership-info-container" style={{ textAlign: 'center', paddingBottom: '8px' }}>
                  <p>
                    {`As a member, each month you get ${currentUserMembershipMonthlyFreeTradeLimit(
                      this.returnCurrentUserData()
                    )} free trades resetting on ${formatLocalizedDateTime(
                      'ls',
                      moment(currentUserMembershipChargeCycleResetsAt(this.returnCurrentUserData()) * 1000)
                    )} for $${currentUserMembershipMonthlyFeeAmount(this.returnCurrentUserData())}.`}
                  </p>
                  {currentUserMembershipFeeBalance(this.returnCurrentUserData()) > 0 && (
                    <p>
                      {`You current membership fee balance is $${currentUserMembershipFeeBalance(
                        this.returnCurrentUserData()
                      )}.`}
                    </p>
                  )}
                </div>
              )}
              <ActionWrapper className={`action-container`}>
                <FlatButton onClick={this.handleUpdateAccountClick}>Update Account</FlatButton>
              </ActionWrapper>
            </>
          ) : (
            <ActionWrapper className={`action-container`}>
              <FlatButton onClick={this.handleOpenAccountClick}>Open Live Brokerage Account</FlatButton>
            </ActionWrapper>
          )}
        </>
        {this.isLiveTrading() && <TrustedContactFormLink />}
      </div>
    );
  }

  handleUpdateAccountClick = () => {
    this.openEditLiveAccountPanel();
    this._logUpdateAccountClick();
  };

  handleOpenAccountClick = () => {
    this.navigateToLiveAccountSignup();
    this._logOpenAccountClick();
  };

  openEditLiveAccountPanel = () => {
    const currentQuery = parseQueryString(this.props.location.search);
    currentQuery.panel = 'editUserAccount';
    const query = createQueryString(currentQuery);
    return this.props.navigate(`${this.props.location.pathname}${query}`);
  };

  navigateToLiveAccountSignup = () => this.props.navigate(returnPathTo('registerLiveAccountFormStart'));

  isLiveTrading = () => isCurrentUserLiveTrading(this.returnCurrentUserData());

  returnCurrentUserData = () => this.props.currentUser;

  _logUpdateAccountClick = () => {
    const event = 'Clicked Edit Live Account';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logOpenAccountClick = () => {
    const event = 'Clicked Open Live Account';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveAccountSettingsForm);
export default withRouter(connectedComponent);
