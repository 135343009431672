import { notificationsAdapter } from '../adapters/notificationsAdapter';
import { returnIdeaListFromIdeaNotifications } from '../helpers/ideaHelpers';
import { groupThoughtNotificationsBySecurityId } from '../helpers/thoughtsHelpers';

import {
  ADD_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS,
  UPDATE_NEW_NOTIFICATIONS_COUNT,
  CLEAR_NOTIFICATIONS,
  CLEAR_NEW_NOTIFICATIONS,
  CLEAR_NEW_NOTIFICATIONS_ALERT_EXPAND,
  ADD_THOUGHTS,
  ADD_IDEAS,
  ADD_USERS,
  ADD_EXCLUDE_THOUGHTS_SUGGESTIONS,
} from '../constants';

export const getUnseenNotificationsCount = () => {
  return function (dispatch) {
    return notificationsAdapter.getUnseenNotificationsCount().then((response) => {
      if (response && response.data) {
        const count = response.data.count;
        dispatch({
          type: UPDATE_NEW_NOTIFICATIONS_COUNT,
          payload: { count },
        });
      }
      return response;
    });
  };
};

export const getNotifications = (config) => {
  return function (dispatch) {
    return notificationsAdapter
      .getNotifications(config)
      .then((response) => {
        const wasSuccess = response && response.data && !('error' in response);
        if (wasSuccess) {
          const notifications = response.data.notifications || [];
          const usersToAddFromConnectionNotifications = [];

          const connectionNotifications = notifications.filter((notif) => notif.type === 'connection');
          connectionNotifications.forEach((notif) => {
            if (notif.connections) {
              notif.connections.forEach((user) => usersToAddFromConnectionNotifications.push(user));
            }
          });
          if (usersToAddFromConnectionNotifications.length > 0) {
            dispatch({
              type: ADD_USERS,
              payload: { users: usersToAddFromConnectionNotifications },
            });
          }

          const thoughtNotifications = notifications.filter((notif) =>
            ['thought', 'thought_clean_style'].includes(notif.type)
          );
          const thoughtNotificationsThoughtIdsList = thoughtNotifications.map(
            (thoughtNotif) => thoughtNotif.data.thought.id
          );
          const groupedThoughtNotificationsBySecurityId = groupThoughtNotificationsBySecurityId(thoughtNotifications);

          dispatch({
            type: ADD_THOUGHTS,
            payload: groupedThoughtNotificationsBySecurityId,
          });
          dispatch({
            type: ADD_EXCLUDE_THOUGHTS_SUGGESTIONS,
            payload: thoughtNotificationsThoughtIdsList,
          });
          const ideaNotifications = notifications.filter((notif) => notif.type === 'idea_action');
          const ideasToAddToStore = returnIdeaListFromIdeaNotifications(ideaNotifications);
          dispatch({
            type: ADD_IDEAS,
            payload: ideasToAddToStore,
          });
          dispatch({
            type: ADD_NOTIFICATIONS,
            payload: {
              notifications,
              page: config.page,
              batch_index: config.batch_index,
              didReturnNotifications: notifications.length > 0,
            },
          });
        }
        return response;
      })
      .catch((error) => console.error('Something went wrong!', error));
  };
};

export const getUpdatedNotifications = (config) => {
  return function (dispatch) {
    return notificationsAdapter.getNotifications(config).then((response) => {
      const wasSuccess = response && response.data && !('error' in response);
      if (wasSuccess) {
        const notifications = response.data.notifications || [];
        const usersToAddFromConnectionNotifications = [];

        const connectionNotifications = notifications.filter((notif) => notif.type === 'connection');
        connectionNotifications.forEach((notif) => {
          if (notif.connections) {
            notif.connections.forEach((user) => usersToAddFromConnectionNotifications.push(user));
          }
        });
        if (usersToAddFromConnectionNotifications.length > 0) {
          dispatch({
            type: ADD_USERS,
            payload: { users: usersToAddFromConnectionNotifications },
          });
        }

        const thoughtNotifications = notifications.filter((notif) =>
          ['thought', 'thought_clean_style'].includes(notif.type)
        );
        const thoughtNotificationsThoughtIdsList = thoughtNotifications.map(
          (thoughtNotif) => thoughtNotif.data.thought.id
        );
        const groupedThoughtNotificationsBySecurityId = groupThoughtNotificationsBySecurityId(thoughtNotifications);
        dispatch({
          type: ADD_THOUGHTS,
          payload: groupedThoughtNotificationsBySecurityId,
        });
        dispatch({
          type: ADD_EXCLUDE_THOUGHTS_SUGGESTIONS,
          payload: thoughtNotificationsThoughtIdsList,
        });
        const ideaNotifications = notifications.filter((notif) => notif.type === 'idea_action');
        const ideasToAddToStore = returnIdeaListFromIdeaNotifications(ideaNotifications);
        dispatch({
          type: ADD_IDEAS,
          payload: ideasToAddToStore,
        });
        dispatch({
          type: UPDATE_NOTIFICATIONS,
          payload: {
            notifications,
            page: config.page,
            batch_index: config.batch_index,
            didReturnNotifications: notifications.length > 0,
            forceRefresh: config.forceRefresh,
          },
        });
      }
      return response;
    });
  };
};

export const clearNewNotificationsAlertExpand = (config) => {
  return function (dispatch) {
    dispatch({
      type: CLEAR_NEW_NOTIFICATIONS_ALERT_EXPAND,
    });
  };
};

export const clearNotifications = (config) => {
  return function (dispatch) {
    dispatch({
      type: CLEAR_NOTIFICATIONS,
    });
  };
};

export const postUpdatedLastSeenNotificationTime = (config) => {
  return function (dispatch) {
    dispatch({
      type: CLEAR_NEW_NOTIFICATIONS,
    });

    return notificationsAdapter
      .postUpdatedLastSeenNotificationTime(config)
      .then((response) => response)
      .catch((error) => console.error('Something went wrong!', error));
  };
};

export const fetchPerformanceRankingNotificationData = (dateStr) => {
  return notificationsAdapter
    .fetchPerformanceRankingNotificationData(dateStr)
    .then((response) => {
      if (response && response.data) {
        return {
          ok: true,
          data: response.data,
        };
      } else {
        return {
          ok: false,
          error: 'Something went wrong, please try again later.',
        };
      }
    })
    .catch((error) => console.error('Something went wrong!', error));
};
