import React from 'react';

import { getDescriptionFromViewpointOrg } from '../../../helpers/viewpointHelpers';

import ViewpointData from '../ViewpointData';
import Button from '../../../components/buttons/Button';
import { UserAvatarAndName } from '../../UI/User/UserAvatarAndName';
import TextExpander from '../../UI/TextExpander';
import Icon from '../../../components/misc/Icon';
import { logMetricsTrackingEvent } from '../../../actions';
import ProfileAvatar from '../../../components/user/ProfileAvatar';
import UserName from '../../../components/user/UserName';
import { FlatButton } from '../../../main/components/buttons';

class SelectableViewpointCard extends React.PureComponent {
  render() {
    const { id, isSelected, style } = this.props;
    const viewpointOrgId = id;

    return (
      <div
        className={isSelected ? 'success-border-accent' : 'border-accent'}
        style={{
          borderWidth: '1px',
          borderStyle: 'solid',
          padding: '15px',
          borderRadius: '4px',
        }}
      >
        <div className={'flex-center-space-between'}>
          <ViewpointData id={viewpointOrgId}>
            {(id, avatarImgSource, username, userInitials, isLoading, displayName, secondaryDisplayName, org) => {
              return (
                <div className={'user-container new-style'} style={{ paddingRight: '10px' }}>
                  <div className={'flex-text'}>
                    <div
                      style={{
                        display: 'inline-block',
                        height: '40px',
                        marginRight: '3px',
                      }}
                    >
                      <ProfileAvatar
                        viewpointOrgId={viewpointOrgId}
                        isViewpointOrg
                        imageSrc={avatarImgSource}
                        initials={userInitials}
                        size={'nav'}
                        withLink={false}
                      />
                    </div>
                    <div className={'flex-text'} style={{ height: '40px' }}>
                      <div className={'breaking-username'}>
                        <UserName
                          viewpointOrgId={viewpointOrgId}
                          isViewpointOrg
                          displayText={displayName}
                          withLink={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          </ViewpointData>
          {isSelected ? (
            <div>
              <Icon icon="fa-check" colorClassName="success-text-color" size="medium" />
            </div>
          ) : (
            <div style={{ paddingBottom: 5 }}>
              <FlatButton onClick={this.handleClick}>Select</FlatButton>
            </div>
          )}
        </div>
        <div style={{ marginTop: '15px' }}>
          <ViewpointData id={id}>
            {(id, avatarImgSource, username, userInitials, isLoading, displayName, secondaryDisplayName, org) => (
              <TextExpander text={getDescriptionFromViewpointOrg(org)} defaultExpandedState={false} />
            )}
          </ViewpointData>
        </div>
      </div>
    );
  }

  handleClick = () => {
    const { id, handleClick } = this.props;

    handleClick();

    const event = 'Select Featured Viewpoint';
    const properties = {
      ID: id,
    };
    logMetricsTrackingEvent(event, properties)();
  };
}

SelectableViewpointCard.defaultProps = {
  style: {},
};

export default SelectableViewpointCard;
