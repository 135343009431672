import React from 'react';
import IconButton from '../../components/buttons/IconButton';

export class TooltipIconButton extends React.Component {
  constructor() {
    super();
    this.state = {
      isFocused: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isFocused && this.state.isFocused) {
      this.onFocus();
    }
    if (prevState.isFocused && !this.state.isFocused) {
      this.onUnfocus();
    }
  }

  render() {
    return (
      <div className={`tooltip-button-container`}>
        <div className={`tooltip-button-wrapper`}>
          <IconButton {...this.props.iconProps} handleClick={this.handleClick} />
          {this.state.isFocused && this.props.children}
        </div>
      </div>
    );
  }

  handleFocus = () => {
    this._setFocused();
  };

  handleUnfocus = () => {
    setTimeout(this._setUnfocused, 50);
  };

  _setFocused = () => {
    this.setState(() => ({
      isFocused: true,
    }));
  };

  _setUnfocused = () => {
    this.setState(() => ({
      isFocused: false,
    }));
  };

  handleClick = () => {
    if (this.state.isFocused) {
      this.handleUnfocus();
    } else {
      this.handleFocus();
      this.props.additionalClickAction && this.props.additionalClickAction();
    }
  };

  _createUnfocusListener = () => {
    $('body').on('click', this.handleUnfocus);
  };

  _destroyUnfocusListener = () => {
    $('body').off('click', this.handleUnfocus);
  };

  onFocus = () => {
    this._createUnfocusListener();
  };

  onUnfocus = () => {
    this._destroyUnfocusListener();
  };
}
