export const scrollToTop = () => {
  window.$('html, body').animate({ scrollTop: 0 }, 150);
};

export const scrollToElement = function (
  $selector,
  $scrollContainer = window.$('html, body'),
  speed = 150,
  offset = 0
) {
  const elementPosition = $selector.offset();
  if (!elementPosition) {
    return false;
  }
  $scrollContainer.animate(
    {
      scrollTop: ($selector.offset().top || 0) - offset,
    },
    speed
  );
};

export const runWhenElIsRendered = function ($el, cb) {
  if ($el && $el.length) {
    cb();
  } else {
    setTimeout(cb, 500);
  }
};
