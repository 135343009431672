import React from 'react';
import { connect } from 'react-redux';

import {
  getViewpointOrgDataFromState,
  getViewpointAvatarURLFromState,
  getViewpointDisplayNameFromState,
  getViewpointInitialsFromState,
} from '../../selectors/viewpoints';

class ViewpointData extends React.PureComponent {
  render() {
    const {
      id,
      avatarImgSource,
      username,
      userInitials,
      isLoading,
      displayName,
      secondaryDisplayName,
      org,

      children,
    } = this.props;

    return children(id, avatarImgSource, username, userInitials, isLoading, displayName, secondaryDisplayName, org);
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  return {
    org: getViewpointOrgDataFromState(state, id),

    avatarImgSource: getViewpointAvatarURLFromState(state, id),
    username: getViewpointDisplayNameFromState(state, id),
    userInitials: getViewpointInitialsFromState(state, id),
    isLoading: false,
    displayName: getViewpointDisplayNameFromState(state, id),
    secondaryDisplayName: null,
  };
};

export default connect(mapStateToProps)(ViewpointData);
