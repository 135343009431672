import React from 'react';
import { isUndefinedOrNull } from '../../../helpers/generalHelpers';
import { RewardCard } from '../../../main/components/rewards/RewardCard';

export const RewardPrizeCard = (props) => {
  const { prizeData, prizeResponseData, didMakePrizeCardSelection, showPrize } = props;
  const _wasPrizeChosen = prizeData.chosen;
  const handleClick = () => props.handleClick(prizeData);

  const shouldShowPrize = showPrize && _wasPrizeChosen;
  let prize = null;

  let baseReward = null;
  let amountMultiplier = null;
  let bonusPercentage = null;
  if (prizeResponseData) {
    prize = prizeResponseData.reward_amount;
    baseReward = prizeResponseData.amount_base;
    amountMultiplier = prizeResponseData.amount_multiplier;
    bonusPercentage = !isUndefinedOrNull(amountMultiplier) ? Math.round(amountMultiplier * 100) - 100 : null;
  }

  const _renderAvailablePrizeCard = () => {
    return <RewardCard onClick={handleClick} />;
  };

  const _renderChosenPrizeCard = () => {
    return (
      <div className={'rewards-card-wrapper'}>
        <RewardCard
          isFlippable
          shouldShowPrize={shouldShowPrize}
          prize={prize}
          baseReward={baseReward}
          bonusPercentage={bonusPercentage}
        />
      </div>
    );
  };

  if (didMakePrizeCardSelection) {
    if (_wasPrizeChosen) {
      return _renderChosenPrizeCard();
    }
  } else {
    return _renderAvailablePrizeCard();
  }
};
