import { logMetricsTrackingEvent } from '../actions';

// Not complete, migrating to this model
const allTrackingEvents = {
  // TODO:
};

const _returnPropertiesForTrackingEvent = (eventName, data) => {
  // TODO:
  return data;
};

export const logTrackingEvent = (eventName, data) => {
  const event = allTrackingEvents[eventName];
  const properties = _returnPropertiesForTrackingEvent(eventName, data);
  return logMetricsTrackingEvent(event, properties)();
};

const fieldNameToEventPropertyLookup = {
  risk_profile: 'Risk Profile',
  total_investible_assets: 'Total Investible Assets',
  annual_income: 'Annual Income',
  given_name: 'First Name',
  family_name: 'Last Name',
  contact_street_address: 'Street Address',
  contact_apartment: 'Apartment',
  contact_city: 'City',
  contact_state: 'State',
  contact_postal_code: 'Zip Code',
  contact_country: 'Country',
  phone_number: 'Phone Number',
  social_security_number: 'Social Security Number',
  date_of_birth: 'Date of Birth',
  citizenship_country: 'Citizenship Country',
  is_permanent_resident: 'Is Permanent Resident',
  green_card_number: 'Green Card Number',
  green_card_expiration_date: 'Green Card Expiration Date',
  birth_country: 'Birth Country',
  visa_type: 'Visa Type',
  visa_number: 'Visa Number',
  visa_expiration_date: 'Visa Expiration Date',
  is_control_person: 'Is Control Person',
  company_symbols: 'Control Person: ( Ticker )',
  is_affiliated_exchange_or_finra: 'Is Affiliated With Exchange or Finra',
  firm_name: 'Affiliated With Exchange or Finra: Name of Firm',
  is_politically_exposed: 'Is Politically Exposed',
  immediate_family: 'Politically Exposed: Family Member',
  political_organization: 'Politically Exposed: Name of Organization',
  acknowledged_account_agreement: 'Account Agreement Acknowledgement',
  acknowledged_options_agreement: 'Options Trading Agreement',

  bank_account_type: 'Bank Account Type',
  owner_name: 'Bank Account Owner Name',
  bank_routing_number: 'Bank Routing Number',
  bank_account: 'Bank Account Number',

  conviction: 'Conviction',
  horizon: 'Horizon',
  thesis: 'Thesis',
  price_target: 'Price Target',
  expected_return: 'Expected Return',
  expected_dividend: 'Expected Dividend',

  active: 'Include In Optimizer',
  idea_type: 'Idea Type',
  current_price: 'Current Price',
  identifier: 'Stock Identifier',

  dash_identifier_with_current_price: 'Dashboard Stock Identifier With Price Change',
  identifier_with_current_price_no_change: 'Dashboard Stock Identifier',
  identifier_with_current_price: 'Portfolio Stock Identifier',
  idea_day_and_total_change: 'Day Change',
  portfolio_idea_cell: 'Idea Cell',
  identifier_with_idea_type: 'Stock Identifier With Idea Type',
  profile_security_allocation_cell: 'Allocation Percentage Cell',
  idea_performance_cell: 'Idea Performance Cell',
  optimized_allocation_with_trade: 'Optimizer Cell',
  thought_leader_data_cell: 'Thought Leader Idea Data Cell',
  view_idea: 'Table View Idea Button',

  position_total_change_percentage_cell: 'Position Total Change Cell',
  position_day_change_percentage_cell: 'Position Day Change Cell',

  external_position_identifier: 'Ext Position Identifier Cell',
  external_position_amount_cell: 'Ext position Amount Cell',
  external_position_day_change_percentage_cell: 'Ext Position Day Change Cell',
  external_position_total_change_percentage_cell: 'Ext Position Total Change Cell',
};

const tabToEventPropertyLookup = {
  thoughts: 'Thought Tab',
  ideas: 'Idea Tab',
};

const presetFilterCardToEventPropertyLookup = {
  defineMyOwn: 'Add Own Filters',
  growthStocks: 'Growth Stocks',
  friendsIdeasStocks: 'Connection Ideas',
  famousInvestorsStocks: 'Thought Leader Ideas',
  valueStocks: 'Value Stocks',
  dividends: 'Dividend Stocks',
  discountToBookValue: 'Price / Tangible Book',
  likeMeStocks: 'ML Stocks',
  techSector: 'Technology Sector',
  financeSector: 'Financial Sector',
  healthcareSector: 'Healthcare Sector',
  energySector: 'Energy Sector',
  diversifyingStocks: 'Diversifying Stocks',
};

const filterNameToEventPropertyLookup = {
  'Price / Earnings': 'Price / Earnings',
  'Forward P/E': 'Forward P/E',
  'Forward P/E Next Yr': 'Forward P/E Next Yr',
  'Price / Tangible Book': 'Price / Tangible Book',
  'Market Cap': 'Market Cap',
  'Net Debt': 'Net Debt',
  Price: 'Price',
  Sectors: 'Sectors',
  "On Connections' Watchlists": "On Connections' Watchlists",
  'Owned by Thought Leaders': 'Owned by Thought Leaders',
  'Revenue LTM': 'Revenue LTM',
  'Projected Revenue Growth': 'Projected Revenue Growth',
  'Dividend Yield': 'Dividend Yield',
};

export const convertPresetFilterCardToMetricsTrackingEventProperty = (filterCardName) => {
  const amplitudeEventProperty = presetFilterCardToEventPropertyLookup[filterCardName];
  if (!amplitudeEventProperty) {
    return console.error(`Preset Filter Card name: ${filterCardName} not found, cannot send to tracking`);
  }
  return amplitudeEventProperty;
};

export const convertFieldNameToMetricsTrackingEventProperty = (fieldName) => {
  const amplitudeEventProperty = fieldNameToEventPropertyLookup[fieldName];
  if (!amplitudeEventProperty) {
    return console.error(`Field name: ${fieldName} not found, cannot send to tracking`);
  }
  return amplitudeEventProperty;
};

export const convertFilterNameToMetricsTrackingEventProperty = (filterName) => {
  const amplitudeEventProperty = filterNameToEventPropertyLookup[filterName];
  if (!amplitudeEventProperty) {
    return console.error(`Field name: ${filterName} not found, cannot send to tracking`);
  }
  return amplitudeEventProperty;
};

export const sanitizeInputErrorsForTracking = (errorObj) => {
  const keys = Object.keys(errorObj);
  const inputErrorObj = {};

  keys.forEach((key) => {
    let sanitizedKey = convertFieldNameToMetricsTrackingEventProperty(key);
    inputErrorObj['Input Error: ' + sanitizedKey] = errorObj[key];
  });
  return inputErrorObj;
};

export const convertTabToMetricsTrackingEventProperty = (tab) => {
  const amplitudeEventProperty = tabToEventPropertyLookup[tab];
  if (!amplitudeEventProperty) {
    return console.error(`Tab name: ${tab} not found, cannot send to tracking`);
  }
  return amplitudeEventProperty;
};
