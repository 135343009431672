import { UPDATE_VIEWPOINT_DATA, VIEWPOINT_SUBSCRIBE_CANCELLED, VIEWPOINT_SUBSCRIBE_COMPLETE } from '../constants';

import {
  getAdminUserIdFromViewpointOrg,
  getDisplayNameFromViewpointOrg,
  isSubscribedToViewpointOrg,
  isViewpointOrgSubscriptionFreeEligible,
} from '../helpers/viewpointHelpers';

import { buildLookupObjectFromArray } from '../helpers/generalHelpers';

const defaultState = {
  lookup: {},
  adminUserIdToViewpointLookup: {},
  nameToIdLookup: {},

  subscriptionIds: [],
  featuredSubscriptionIds: [],
  allViewpointIds: [],
  viewpointAdminUserIds: [],

  featuredSubscriptionIdsDisplayOrder: [],
  defaultSelection: null,
};

const parseViewpointData = (orgs) => {
  const subscriptionIds = [];
  const featuredSubscriptionIds = [];
  const allViewpointIds = [];
  const viewpointAdminUserIds = [];

  const adminUserIdToViewpointLookup = {};
  const nameToIdLookup = {};

  orgs.forEach((viewpointOrg) => {
    const { id } = viewpointOrg;
    const adminUserId = getAdminUserIdFromViewpointOrg(viewpointOrg);
    const name = getDisplayNameFromViewpointOrg(viewpointOrg).toLowerCase();

    allViewpointIds.push(id);
    nameToIdLookup[name] = id;

    if (adminUserId !== null) {
      viewpointAdminUserIds.push(adminUserId);
      adminUserIdToViewpointLookup[adminUserId] = id;
    }

    if (isViewpointOrgSubscriptionFreeEligible(viewpointOrg)) featuredSubscriptionIds.push(id);

    if (isSubscribedToViewpointOrg(viewpointOrg)) subscriptionIds.push(id);
  });

  return {
    subscriptionIds,
    featuredSubscriptionIds,
    allViewpointIds,
    viewpointAdminUserIds,
    adminUserIdToViewpointLookup,
    nameToIdLookup,
  };
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_VIEWPOINT_DATA: {
      const { orgs, featuredSubscriptionIdsDisplayOrder, defaultSelection } = action.payload;

      const {
        subscriptionIds,
        featuredSubscriptionIds,
        allViewpointIds,
        viewpointAdminUserIds,
        adminUserIdToViewpointLookup,
        nameToIdLookup,
      } = parseViewpointData(orgs);

      const lookup = buildLookupObjectFromArray(orgs);
      const nextState = {
        ...state,
        lookup,
        subscriptionIds,
        featuredSubscriptionIds,
        allViewpointIds,
        viewpointAdminUserIds,
        adminUserIdToViewpointLookup,
        featuredSubscriptionIdsDisplayOrder,
        defaultSelection,
        nameToIdLookup,
      };

      return nextState;
    }

    case VIEWPOINT_SUBSCRIBE_COMPLETE: {
      const { id, isFree } = action.payload;

      const subbedOrg = state.lookup[id];
      const updatedSubbedOrg = {
        ...subbedOrg,
        is_free: isFree,
        is_subscribed: true,
      };

      const nextState = {
        ...state,
        lookup: {
          ...state.lookup,
          [id]: updatedSubbedOrg,
        },
        subscriptionIds: [...state.subscriptionIds, id],
      };
      return nextState;
    }

    case VIEWPOINT_SUBSCRIBE_CANCELLED: {
      const id = action.payload;
      const cancelledOrg = state.lookup[id];
      const updatedCancelledOrg = {
        ...cancelledOrg,
        is_free: false,
        is_subscribed: !cancelledOrg.is_free,
        is_canceled: true,
      };

      const nextState = {
        ...state,
        lookup: {
          ...state.lookup,
          [id]: updatedCancelledOrg,
        },
        subscriptionIds: state.subscriptionIds.filter((currentSubId) => currentSubId !== id),
      };
      return nextState;
    }

    default: {
      return state;
    }
  }
}
