import { connect } from 'react-redux';

const NewNotificationsCount = (props) => {
  const {
    unseenCount,

    children,
  } = props;

  const areUnseenNotifications = unseenCount > 0;
  const unseenCountDisplay = unseenCount > 9 ? '9+' : unseenCount;

  return children(unseenCount, unseenCountDisplay, areUnseenNotifications);
};

const mapStateToProps = (state) => {
  const notifStore = state.notifications;
  const unseenCount = notifStore.unseenCount || 0;
  return {
    unseenCount,
  };
};

export default connect(mapStateToProps)(NewNotificationsCount);
