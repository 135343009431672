import { FETCH_COLLECTIVES, ADD_COLLECTIVES } from '../constants';

const defaultState = {
  loading: true,
  lookup: {},
};

export default function collectivesReducer(state = defaultState, action) {
  switch (action.type) {
    case ADD_COLLECTIVES:
      const { collectives } = action.payload;
      const lookup = {};
      collectives.forEach((user) => {
        lookup[user.user_id] = user;
      });
      return {
        ...state,
        loading: false,
        lookup: {
          ...state.lookup,
          ...lookup,
        },
      };

    default:
      return state;
  }
}
