/*
  -> liveAccount
*/

export const getLiveTradingAccountDataFromLiveAccountState = (liveAccountState) => {
  return liveAccountState.liveTrading;
};

export const returnSubscriptionEndDateFromState = (state) => {
  return state.currentUser && state.currentUser.subscription_ending_at;
};
