import React from 'react';
import ProfileAvatar from '../../../components/user/ProfileAvatar';
import styled from 'styled-components';
import { useUser } from '../../hooks/user/useUser';
import { Body4, Body5, Body6 } from '../../lib/nvstr-common-ui.es';
import {
  displayThoughtLeaderCompany,
  displayThoughtLeaderName,
  isUserCollective,
  isUserThoughtLeader,
  userFullName,
  userFullNamePossessive,
} from '../../../helpers/usersHelpers';
import { Container } from '../../components/ui';
import InfoIcon from '../../../containers/UI/InfoIcon';
import { useUserIdeasPerformance } from '../../hooks/ideas/useIdeaPerformance';
import { SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '../../components/ui/SvgLoadingIcon';
import { scrollToTop } from '../../../helpers/pageHelpers';
import { parseQueryString } from '../../../helpers/routerHelpers';
import { useSearchParams } from 'react-router-dom';
import { sendFacebookTrackingEvent } from '../../../constants/facebookTracking';
import {
  fetchCollective,
  fetchMutualConnections,
  getUser,
  getUserConnections,
  logMetricsTrackingEvent,
  viewProfile,
} from '../../../actions';
import { useDispatch } from 'react-redux';
import { PRODUCT_NAME } from '../../../appConfig';
import { DISPLAY_FORMAT_TYPES, formatValueTo } from '../../utils/numbers';
import { ColorChangeText } from '../../components/molecules/ColorChangeText';
import { ThoughtLeaderIdeaPerformanceTable } from '../../containers/user/ThoughtLeaderIdeaTable';
import { Page } from '../../components/layout';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const AvatarWrapper = styled.div``;
const IdentityWrapper = styled.div`
  padding: 0 0 0 24px;
`;

const ContentWrapper = styled.div``;
const IdeaPerformanceWrapper = styled.div`
  min-width: 300px;
  margin-left: 10%;

  border: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  border-radius: 5px;
  padding: 24px 0;
`;
const ProfileBodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 24px 0 0 0;

  .row {
    &:first-child {
      padding-top: 0;
    }
    padding-top: 12px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: block;

    ${IdeaPerformanceWrapper} {
      margin: 24px auto 0 auto;
      max-width: 420px;
    }
  }
`;
const InfoIconWrapper = styled.span`
  position: relative;
  path {
    fill: ${({ theme }) => theme.themeColors.secondaryText};
  }
`;
const IdeaPerformanceHeadingWrapper = styled.div`
  padding: 0 24px 24px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
`;
const IdeaPerformanceDataWrapper = styled.div`
  padding: 24px 24px 0 24px;
`;
const IdeaPerformanceHorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: space-around;
`;
const IdeaPerformanceDataPointWrapper = styled.div`
  .datapoint-value {
    padding-top: 8px;
    font-size: 16px;
    line-height: 18px;
  }
`;
const IdeaPerformanceDataPoint = ({ data }) => {
  return (
    <IdeaPerformanceDataPointWrapper>
      <div>
        <Body5 isLowContrast>{data.name}</Body5>
      </div>
      <Container height={18}>
        <ColorChangeText value={data.value} className={'datapoint-value'}>
          <Body5 bold>{formatValueTo(DISPLAY_FORMAT_TYPES.PERCENTAGE, data.value)}</Body5>
        </ColorChangeText>
      </Container>
    </IdeaPerformanceDataPointWrapper>
  );
};
const IdeaPerformanceData = ({ userId }) => {
  const performance = useUserIdeasPerformance(userId);
  if (!performance) {
    return (
      <IdeaPerformanceHorizontalWrapper>
        <Container centerAll height={36}>
          <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.balls} />
        </Container>
      </IdeaPerformanceHorizontalWrapper>
    );
  }

  const { performance_last_month, performance_this_month, performance_today } = performance;
  const datapoints = [
    { name: 'Today', value: performance_today },
    { name: 'This Month', value: performance_this_month },
    { name: 'Last Month', value: performance_last_month },
  ];
  return (
    <IdeaPerformanceHorizontalWrapper>
      {datapoints.map((dp) => (
        <IdeaPerformanceDataPoint key={dp.name} data={dp} />
      ))}
    </IdeaPerformanceHorizontalWrapper>
  );
};

const Profile = ({ userId }) => {
  const isMobileSize = false;
  const user = useUser(userId);
  const { city, state, location } = user;

  const company = displayThoughtLeaderCompany(user);
  const leader = displayThoughtLeaderName(user);
  const userLocation = location
    ? location
    : city && state
    ? `${city}, ${state}`
    : city
    ? `${city}`
    : state
    ? `${state}`
    : null;

  const thoughtLeaderCompanyPossessive = userFullNamePossessive(user);
  const disclaimer = `The positions below came from publicly available sources. These are long positions only and do not necessarily reflect ${thoughtLeaderCompanyPossessive} complete portfolio or current thinking on these investments.`;

  return (
    <Container>
      <HeaderWrapper>
        <AvatarWrapper>
          <ProfileAvatar user={user} size={isMobileSize ? 'large' : 'profile'} withLink={false} showNameTag={false} />
        </AvatarWrapper>
        <IdentityWrapper>
          <Container>
            <Body6>{company}</Body6>
          </Container>
          <Container top={2}>
            <Body5>{leader}</Body5>
          </Container>
          <Container top={2}>
            <Body5 isLowContrast>{userLocation}</Body5>
          </Container>
        </IdentityWrapper>
      </HeaderWrapper>
      <ProfileBodyWrapper>
        <ContentWrapper>
          {user.quotation && user.quotation.length > 0 ? (
            <div className={'row'}>
              <Body5>{user.quotation}</Body5>
            </div>
          ) : null}
          {user.bio && user.bio.length > 0 ? (
            <div className={'row'}>
              <Body5>{user.bio}</Body5>
            </div>
          ) : null}
          <div className={'row'}>
            <Body5 isLowContrast>{disclaimer}</Body5>
          </div>
        </ContentWrapper>
        <IdeaPerformanceWrapper>
          <IdeaPerformanceHeadingWrapper>
            <InfoIconWrapper>
              <Body4 isLowContrast thin>
                Idea Performance
              </Body4>
              <InfoIcon word={'performance_numbers'} style={{ top: '3px', bottom: '1px' }} />
            </InfoIconWrapper>
          </IdeaPerformanceHeadingWrapper>

          <IdeaPerformanceDataWrapper>
            <IdeaPerformanceData userId={userId} />
          </IdeaPerformanceDataWrapper>
        </IdeaPerformanceWrapper>
      </ProfileBodyWrapper>
    </Container>
  );
};

const TabbedProfile = ({ userId }) => {
  return <ThoughtLeaderIdeaPerformanceTable userId={userId} />;
};

const _updatePageTitle = (user) => {
  const usersFullName = isUserThoughtLeader(user)
    ? displayThoughtLeaderCompany(user)
    : isUserCollective(user)
    ? user.collective_name
    : userFullName(user);
  document.title = `${PRODUCT_NAME} - ${usersFullName}`;
};

const _updatePageURL = (user, setSearchParams) => {
  if (isUserThoughtLeader(user)) {
    setSearchParams({ 'is-thought-leader': true });
  }
};

const _logProfileView = (user) => {
  sendFacebookTrackingEvent('View Thought Leader', {});

  const event = 'View User Profile';
  const properties = {
    'From React Router': true,
    'In Onboarding': !user.has_completed_onboarding,
    'On Waitlist': user.is_on_waitlist,
    'Is Connected': user.connection_status_to_current_user === 'connected',
    'Profile User ID': user.analytics_id,
    'Is Collective User': isUserCollective(user),
  };
  logMetricsTrackingEvent(event, properties)();
};

const _logProfileViewToApi = (user) => {
  const query = parseQueryString(window.location.search);
  viewProfile(user.user_id, query.context);
};

export const ThoughtLeaderProfile = ({ userId }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const overrideStyle = {
    marginBottom: 0,
  };
  const tabbedSectionOverrideStyle = {
    ...overrideStyle,
    marginTop: '8px',
    marginBottom: '24px',
  };

  const user = useUser(userId);

  React.useEffect(() => {
    scrollToTop();

    _logProfileView(user);
    _logProfileViewToApi(user);
    _updatePageTitle(user);
    _updatePageURL(user, setSearchParams);

    getUserConnections(userId)(dispatch);
    const config = {
      include: ['performance'],
    };
    getUser(userId, config)(dispatch);

    fetchMutualConnections([userId])(dispatch);
  }, []);

  return (
    <>
      <Page width={'1100px'} style={overrideStyle} mobileTransparentBackground>
        <Profile userId={userId} />
      </Page>
      <Page width={'1100px'} style={tabbedSectionOverrideStyle} noPadding>
        <TabbedProfile userId={userId} />
      </Page>
    </>
  );
};
