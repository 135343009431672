import React from 'react';
import { Overlay } from '../layout';
import { Body5 } from '../../lib/nvstr-common-ui.es';
import { colorPalette } from '../../lib/nvstr-utils.es';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
`;
const TextWrapper = styled.div`
  text-align: center;
  padding-top: 8px;
  opacity: 0.8;
`;

export const LoadingOverlay = ({ message }) => {
  return (
    <Overlay>
      <Wrapper>
        <div>
          <svg width="48px" height="48px" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#ccc">
            <g fill="none" fillRule="evenodd">
              <g transform="translate(1 1)" strokeWidth="2">
                <circle strokeOpacity=".8" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="500ms"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </div>
        {message && (
          <TextWrapper>
            <Body5 thin color={colorPalette.primary.oak}>
              {message}
            </Body5>
          </TextWrapper>
        )}
      </Wrapper>
    </Overlay>
  );
};
