import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../../actions/index';

import DropdownWithStrings from '../../StandaloneInputs/DropdownWithStrings';
import Input from '../../StandaloneInputs/Input';
import Button from '../../../components/buttons/Button';

import { listUsStateNames, listCountryNames } from '../../../helpers/formHelpers';

class BillingAddress extends React.Component {
  constructor() {
    super();
    this.state = {
      formData: {
        firstName: '',
        lastName: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      },
      errors: {},
    };
  }

  componentDidMount() {
    if (this.props.startingFormData) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          ...this.props.startingFormData,
        },
      }));
    }
  }

  _returnAllFieldNames = () => ['firstName', 'lastName', 'street', 'city', 'state', 'zip', 'country'];

  _isFieldRequired = (fieldName) => {
    const alwaysRequiredFieldsList = ['firstName', 'lLastName', 'street', 'city', 'state', 'zip', 'country'];
    return alwaysRequiredFieldsList.includes(fieldName);
  };

  _setErrorForField = (fieldName, error) => {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [fieldName]: error,
      },
    }));
  };

  _clearErrorForField = (fieldName) => {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [fieldName]: null,
      },
    }));
  };

  _isFieldMissingValue = (value) => value === '';

  _returnRequiredFieldErrorMessage = () => 'Field is required';

  _validateField = (fieldName, value) => {
    if (this._isFieldRequired(fieldName) && this._isFieldMissingValue(value)) {
      this._setErrorForField(fieldName, this._returnRequiredFieldErrorMessage());
      return false;
    }
    return true;
  };

  _validateForm = () => {
    let isFormValid = true;
    this._returnAllFieldNames().forEach((fieldName) => {
      const isValid = this._validateField(fieldName, this.returnValueForFieldName(fieldName));
      if (!isValid) {
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  _doesFieldHaveError = (fieldName) => !!this.returnFieldError(fieldName);

  render() {
    return (
      <div className={`credit-card-form-container`}>
        <div className={`credit-card-payment-inputs`}>
          <Input
            containerClassName={'credit-card-input card-holder-first-name'}
            type={'text'}
            name={'firstName'}
            label={'First Name'}
            value={this.returnFirstNameValue()}
            errorMessage={this.returnFieldError('firstName')}
            handleChange={this.handleInputChange}
            handleBlur={this.handleInputBlur}
            isFloatingStyle
          />
          <Input
            containerClassName={'credit-card-input card-holder-last-name'}
            type={'text'}
            name={'lastName'}
            label={'Last Name'}
            value={this.returnLastNameValue()}
            errorMessage={this.returnFieldError('lastName')}
            handleChange={this.handleInputChange}
            handleBlur={this.handleInputBlur}
            isFloatingStyle
          />
        </div>
        <div className={`credit-card-billing-address-inputs`}>
          <Input
            containerClassName={'credit-card-input street-address'}
            type={'text'}
            name={'street'}
            label={'Street Address'}
            value={this.returnStreetAddressValue()}
            errorMessage={this.returnFieldError('street')}
            handleChange={this.handleInputChange}
            handleBlur={this.handleInputBlur}
            isFloatingStyle
          />
          <Input
            containerClassName={'credit-card-input city-input'}
            type={'text'}
            name={'city'}
            label={'City'}
            value={this.returnCityValue()}
            errorMessage={this.returnFieldError('city')}
            handleChange={this.handleInputChange}
            handleBlur={this.handleInputBlur}
            isFloatingStyle
          />
          <div className={`credit-card-input state-input`}>
            <DropdownWithStrings
              type={'text'}
              name={'state'}
              label={'State'}
              value={this.returnStateValue()}
              options={this.returnStateDropdownOptions()}
              errorMessage={this.returnFieldError('state')}
              handleSelection={this.handleDropdownSelection}
              handleBlur={this.handleInputBlur}
              includeLabelAsSelection
              showArrowIcons
            />
          </div>
          <Input
            containerClassName={'credit-card-input zip-input'}
            type={'number'}
            name={'zip'}
            label={'Postal Code'}
            value={this.returnZipValue()}
            errorMessage={this.returnFieldError('zip')}
            handleChange={this.handleInputChange}
            handleBlur={this.handleInputBlur}
            isFloatingStyle
          />
          <div className={`credit-card-input country-input`}>
            <DropdownWithStrings
              type={'number'}
              name={'country'}
              label={'Country'}
              value={this.returnCountryValue()}
              options={this.returnCountryDropdownOptions()}
              errorMessage={this.returnFieldError('country')}
              handleSelection={this.handleDropdownSelection}
              handleBlur={this.handleInputBlur}
              includeLabelAsSelection
              showArrowIcons
            />
          </div>
        </div>
        <div className={`form-action-container`}>
          <Button
            customClass={`btn btn-primary-color btn-small-tall btn-fixed-width-95`}
            text={
              this.props.useIsSubmittingBtn && this.props.isSubmitting ? 'Loading' : this.props.submitBtnText || 'Save'
            }
            handleClick={this.handleSubmit}
          />
          {!this.props.hideCancelBtn && (
            <Button
              customClass={`btn-flat btn-flat-grey btn-small-tall cancel-btn`}
              text={'Cancel'}
              handleClick={this.props.handleCancel}
            />
          )}
        </div>
      </div>
    );
  }

  returnFormData = () => this.state.formData;

  returnFormErrorMessageLookup = () => this.state.errors;

  returnFieldError = (fieldName) => this.returnFormErrorMessageLookup()[fieldName];

  returnStateDropdownOptions = () => listUsStateNames();

  returnCountryDropdownOptions = () => listCountryNames();

  handleDropdownSelection = (fieldName, value) => {
    this._setInputValue(fieldName, value);
    if (this._validateField(fieldName, value) && this._doesFieldHaveError(fieldName)) {
      this._clearErrorForField(fieldName);
    }
  };

  handleInputChange = (fieldName, value) => {
    if (this._doesFieldHaveError(fieldName)) {
      if (this._validateField(fieldName, value)) {
        this._clearErrorForField(fieldName);
      }
    }
    this._setInputValue(fieldName, value);
  };

  handleInputBlur = (value, fieldName) => {
    this._validateField(fieldName, value);
  };

  _setInputValue = (fieldName, value) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [fieldName]: value,
      },
    }));
  };

  getUseLiveAccountAddressValue = () => this._shouldUseLiveAccountAddress();

  handleToggleUseLiveAccountAddress = () => {
    this._toggleUseLiveAccountAddress();
  };

  handleSubmit = () => {
    if (this._validateForm()) {
      this.props.onPaymentSubmitStart && this.props.onPaymentSubmitStart();
      return this.props.handleSubmit(this.returnFormData());
    }
  };

  returnValueForFieldName = (fieldName) => this.returnFormData()[fieldName];

  returnFirstNameValue = () => this.returnFormData().firstName;
  returnLastNameValue = () => this.returnFormData().lastName;
  returnStreetAddressValue = () => this.returnFormData().street;
  returnCityValue = () => this.returnFormData().city;
  returnStateValue = () => this.returnFormData().state;
  returnZipValue = () => this.returnFormData().zip;
  returnCountryValue = () => this.returnFormData().country;

  returnLiveAccountData = () => this.props.liveAccountData;
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const connectedComponent = connect(null, mapDispatchToProps)(BillingAddress);

export default connectedComponent;
