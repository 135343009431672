export class RebalancePosition {
  constructor(securityId, allocPercent) {
    this._securityId = securityId;
    this._allocPercent = allocPercent;
  }

  setAllocPercent = (v) => (this.allocPercent = v);

  getSecurityId = () => this._securityId;
  getAllocPercent = () => this._allocPercent;

  toAPI = () => {
    return {
      security_id: this._securityId,
      alloc_percent: this._allocPercent,
    };
  };
}

export class RebalancePositionsList {
  constructor(securityIds = [], defaultAllocPercent = 0) {
    this.rebalancePositions = [];

    securityIds.forEach((id) => {
      const rebalancePosition = new RebalancePosition(id, defaultAllocPercent);

      this.rebalancePositions.push(rebalancePosition);
    });
  }

  createOrUpdate = (securityId, allocPercent) => {
    const updatedList = [];
    let isUpdating = false;

    this.rebalancePositions.forEach((rebalancePosition) => {
      const needsUpdate = securityId === rebalancePosition.getSecurityId();
      if (needsUpdate) isUpdating = true;

      const updatedRebalancePosition = needsUpdate
        ? new RebalancePosition(securityId, allocPercent)
        : rebalancePosition;

      updatedList.push(updatedRebalancePosition);
    });

    if (!isUpdating) updatedList.push(new RebalancePosition(securityId, allocPercent));

    this.rebalancePositions = updatedList;
    return updatedList;
  };

  toAPI = () => this.rebalancePositions.map((p) => p.toAPI());
}
