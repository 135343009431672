import React from 'react';

export const TextField = (props) => {
  const { name, label, size, autocompleteValue, inputType } = props;

  const value = props.getValue(name) || '';

  const isError = !!props.getErrorMessage(name);
  const errorMessage = isError ? props.getErrorMessage(name) : '';

  const isFocused = props.isFocused(name);
  const focusedOrValue = (!!value && value.length > 0) || isFocused;

  const focusNote = props.getFocusNote(name);
  const showFocusNote = !!focusNote && isFocused;

  const isDisabled = props.disabled;

  const handleChange = (e) => {
    if (!isDisabled) {
      props.handleChange(e, name);
    }
  };

  const handleBlur = () => {
    props.handleBlur(value, name);
  };

  const handleFocus = () => {
    props.handleFocus(name);
  };

  return (
    <div className={`floating-label-text-field ${isDisabled ? 'disabled' : ''} ${props.customWrapperClass || ''}`}>
      <fieldset className={`text-field ${size === 'small' ? 'field-small' : ''} `}>
        {showFocusNote && <div className="focus-note eye-catching-bg">{focusNote}</div>}
        <input
          type={inputType || 'text'}
          name={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          value={value}
          disabled={isDisabled}
          autoComplete={autocompleteValue || 'on'}
        />

        <label htmlFor={props.label} className={focusedOrValue ? 'text-field-lifted-label' : ''}>
          {label}
        </label>

        <div className={`after ${isError && !isFocused ? 'error-underline' : ''}`}></div>

        <p className={'text-field-error-message'}>{errorMessage}</p>
      </fieldset>
    </div>
  );
};

export default TextField;
