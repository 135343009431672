import { moment, formatLocalizedDateTime } from './timeHelpers';
import { isUndefinedOrNull, formatLocaleString } from './generalHelpers';
import { isFloat } from './numberHelpers';

export const formatForDisplay = (rawValue, type) => {
  const formatDict = {
    day: (rawValue) => {
      return formatLocalizedDateTime('Do', rawValue);
    },
    price: (rawValue) => {
      if (isUndefinedOrNull(rawValue)) {
        return '$0.00';
      }
      if (typeof rawValue !== 'number') {
        console.error('Value was not a number');
        return '$0';
      }
      const isNegative = rawValue < 0;
      const value = Math.abs(rawValue);
      const localeStringValue = formatLocaleString(value, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return isNegative ? `-$${localeStringValue}` : `$${localeStringValue}`;
    },
    priceInt: (rawValue) => {
      if (isUndefinedOrNull(rawValue)) {
        return '$0';
      }
      if (typeof rawValue !== 'number') {
        console.error('Value was not a number');
        return '$0';
      }
      const isNegative = rawValue < 0;
      const value = Math.abs(rawValue);
      const localeStringValue = formatLocaleString(value, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return isNegative ? `-$${localeStringValue}` : `$${localeStringValue}`;
    },
    int: (value) => {
      return formatLocaleString(value, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    float: (value) => {
      return formatLocaleString(value, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    shares: (value) => {
      if (isNaN(value)) return '0 sh';
      return `${formatLocaleString(value, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 5,
      })} sh`;
    },
    percentChange: (value) => {
      return `${value === 0 ? '' : value > 0 ? '+' : '-'}${formatLocaleString(Math.abs(value), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}%`;
    },
    percent: (rawValue) => {
      let value = rawValue;
      if (isNaN(value) || rawValue === 0) value = 0;
      return `${formatLocaleString(value, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}%`;
    },
    relativeTime: (value) => {
      const timeNow = moment();
      const timestampMoment = moment(value);
      // const isWithinADay = !timestampMoment.isBefore(timeNow, 'day');
      const timestampReadableDayTimeSinceString = timestampMoment.fromNow();
      return timestampReadableDayTimeSinceString;
    },
    time: (value) => {
      const timestampMoment = moment(value);
      const timestampReadableString = `${formatLocalizedDateTime('LTS', timestampMoment)}`;
      return timestampReadableString;
    },
    date: (value) => {
      const timestampMoment = moment(value);
      const timestampReadableString = `${formatLocalizedDateTime('LL', timestampMoment)}`;
      return timestampReadableString;
    },
    timeDate: (value) => {
      const timestampMoment = moment(value);
      const timestampReadableString = `${formatLocalizedDateTime(
        'LT',
        timestampMoment
      )} EST on ${formatLocalizedDateTime('MMMM Do', timestampMoment)}`;
      return timestampReadableString;
    },
    dateTime: (value) => {
      const timestampMoment = moment(value);
      const timestampReadableString = `${formatLocalizedDateTime('LLL', timestampMoment)}`;
      return timestampReadableString;
    },
    rewardAmount: (rawValue) => {
      if (isUndefinedOrNull(rawValue)) {
        return '$0.00';
      }
      if (typeof rawValue !== 'number') {
        console.error('Value was not a number');
        return '$0';
      }

      const isNegative = rawValue < 0;
      const value = Math.abs(rawValue);

      if (isFloat(rawValue)) {
        const localeStringValue = formatLocaleString(value, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return isNegative ? `-$${localeStringValue}` : `$${localeStringValue}`;
      } else {
        const localeStringValue = formatLocaleString(value, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        return isNegative ? `-$${localeStringValue}` : `$${localeStringValue}`;
      }
    },
  };
  if (!formatDict[type]) {
    console.error(`formatForDisplay type: ${type} was not found. Using string instead`);
    return rawValue.toString();
  }
  return formatDict[type](rawValue);
};
