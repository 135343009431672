import { logMetricsTrackingEvent } from '../../../actions';

const VIEW_BANNER = {
  send: (properties) => {
    // Type Event Name
    // Type
    // Message
    // Link Message
    // Is Live Trading
    // Is Above Feed

    const bannerName = properties['Type Event Name'];
    const event = `Show ${bannerName} Banner`;

    logMetricsTrackingEvent(event, properties);
    logMetricsTrackingEvent('Show Banner', properties);
  },
};

const PRESS_BANNER_ACTION = {
  send: (properties) => {
    // Type Event Name
    // Type
    // Message
    // Link Message
    // Is Live Trading
    // Is Above Feed

    const bannerName = properties['Type Event Name'];
    const event = `${bannerName} Banner Clicked`;
    logMetricsTrackingEvent(event, properties);
    logMetricsTrackingEvent('Banner Clicked', properties);
  },
};

const DISMISS_BANNER = {
  send: (properties) => {
    // Type Event Name
    // Type
    // Message
    // Link Message
    // Is Live Trading
    // Is Above Feed

    const bannerName = properties['Type Event Name'];
    const event = `${bannerName} Banner Dismissed`;
    logMetricsTrackingEvent(event, properties);
    logMetricsTrackingEvent('Banner Dismissed', properties);
  },
};

const VIEW_ABOVE_FEED_REFERRAL_BANNER = {
  send: (properties) => {
    // Message
    // Test Group

    const event = 'View Above Feed Referral Banner';
    logMetricsTrackingEvent(event, properties);
  },
};

const PRESS_ABOVE_FEED_REFERRAL_BANNER = {
  send: (properties) => {
    // Message
    // Test Group

    const event = 'Pressed Above Feed Referral Banner';
    logMetricsTrackingEvent(event, properties);
  },
};

const DISMISS_ABOVE_FEED_REFERRAL_BANNER = {
  send: (properties) => {
    // Message
    // Test Group

    const event = 'Dismissed Above Feed Referral Banner';
    logMetricsTrackingEvent(event, properties);
  },
};

const VIEW_ABOVE_FEED_UPCOMING_EARNINGS_BANNER = {
  send: (properties) => {
    // Message
    // Test Group

    const event = 'View Above Feed Upcoming Earnings Banner';
    logMetricsTrackingEvent(event, properties);
  },
};

const PRESS_ABOVE_FEED_UPCOMING_EARNINGS_BANNER = {
  send: (properties) => {
    // Message
    // Test Group

    const event = 'Press Above Feed Upcoming Earnings Banner';
    logMetricsTrackingEvent(event, properties);
  },
};

const DISMISS_ABOVE_FEED_UPCOMING_EARNINGS_BANNER = {
  send: (properties) => {
    // Message
    // Test Group

    const event = 'Dismiss Above Feed Upcoming Earnings Banner';
    logMetricsTrackingEvent(event, properties);
  },
};

const VIEW_ABOVE_FEED_FUNDING_BONUS_BANNER = {
  send: (properties) => {
    // Message
    // Test Group

    const event = 'View Above Feed Funding Bonus Banner';
    logMetricsTrackingEvent(event, properties);
  },
};

const PRESS_ABOVE_FEED_FUNDING_BONUS_BANNER = {
  send: (properties) => {
    // Message
    // Test Group

    const event = 'Press Above Feed Funding Bonus Banner';
    logMetricsTrackingEvent(event, properties);
  },
};

const DISMISS_ABOVE_FEED_FUNDING_BONUS_BANNER = {
  send: (properties) => {
    // Message
    // Test Group

    const event = 'Dismiss Above Feed Funding Bonus Banner';
    logMetricsTrackingEvent(event, properties);
  },
};
const VIEW_ABOVE_FEED_TRENDING_STOCKS_BANNER = {
  send: (properties) => {
    // ID
    // Message
    // Test Group

    const event = 'View Above Feed Trending Stocks Banner';
    logMetricsTrackingEvent(event, properties);
  },
};

const PRESS_ABOVE_FEED_TRENDING_STOCKS_BANNER = {
  send: (properties) => {
    // ID
    // Message
    // Test Group

    const event = 'Press Above Feed Trending Stocks Banner';
    logMetricsTrackingEvent(event, properties);
  },
};

const DISMISS_ABOVE_FEED_TRENDING_STOCKS_BANNER = {
  send: (properties) => {
    // ID
    // Message
    // Test Group

    const event = 'Dismiss Above Feed Trending Stocks Banner';
    logMetricsTrackingEvent(event, properties);
  },
};

const PRESS_REFERRAL_ACTION = {
  send: (properties) => {
    // Message
    // Test Group
    // Type Event Name
    // Type

    const event = 'Press Referral Action Above Feed Banner';
    logMetricsTrackingEvent(event, properties);
  },
};

const trackingEvents = {
  VIEW_BANNER,
  PRESS_BANNER_ACTION,
  DISMISS_BANNER,

  VIEW_ABOVE_FEED_REFERRAL_BANNER,
  PRESS_ABOVE_FEED_REFERRAL_BANNER,
  DISMISS_ABOVE_FEED_REFERRAL_BANNER,

  VIEW_ABOVE_FEED_UPCOMING_EARNINGS_BANNER,
  PRESS_ABOVE_FEED_UPCOMING_EARNINGS_BANNER,
  DISMISS_ABOVE_FEED_UPCOMING_EARNINGS_BANNER,

  VIEW_ABOVE_FEED_FUNDING_BONUS_BANNER,
  PRESS_ABOVE_FEED_FUNDING_BONUS_BANNER,
  DISMISS_ABOVE_FEED_FUNDING_BONUS_BANNER,

  VIEW_ABOVE_FEED_TRENDING_STOCKS_BANNER,
  PRESS_ABOVE_FEED_TRENDING_STOCKS_BANNER,
  DISMISS_ABOVE_FEED_TRENDING_STOCKS_BANNER,

  PRESS_REFERRAL_ACTION,
};

export default trackingEvents;
