export const USER_ACTION_CONTEXTS = {
  learnLesson: 'Learn Lesson',
  learnHome: 'Learn Home Screen',
};

export const USER_ACTION_EVENT_NAMES = {
  pressPartnerLink: 'Press Partner Link',
  viewPartnerLesson: 'View Partner Lesson',
  viewPartnerLessonBanner: 'View Partner Lesson Banner',
};
