import SmartyStreetsSDK from 'smartystreets-javascript-sdk';
import {
  doObjectsContainSameKeysAndValues,
  isUndefinedOrNull,
  convertObjectKeyValuesFromUndefinedOrNullToBlankString,
} from '../helpers/generalHelpers';

const SmartyStreetsCore = SmartyStreetsSDK.core;

const key = '85097069425102791';
// const hostname = 'nvstr.com';
// const hostname = 'd1-forerunner.nvstr.com';
const credentials = new SmartyStreetsCore.SharedCredentials(key, null);

const VerifyAddressUtil = SmartyStreetsSDK.usStreet.Lookup;
const AddressVerificationClient = SmartyStreetsCore.buildClient.usStreet(credentials);

const AddressAutocompleteUtil = SmartyStreetsSDK.usAutocompletePro.Lookup;
const AddressAutocompleteClient = SmartyStreetsCore.buildClient.usAutocompletePro(credentials);

class AddressUtil {
  constructor({ addressData, mappedAddressData }) {
    this._addressData = addressData;
    this._mappedAddressData = mappedAddressData;

    if (!mappedAddressData) {
      const { streetLine: street, secondary: street2, city, state, zipcode } = addressData;
      mappedAddressData = {
        street,
        street2,
        city,
        state,
        zipcode,
      };
      this._mappedAddressData = mappedAddressData;
    }
  }

  buildStreetAddressLine = () => {
    const { street, street2 } = this._mappedAddressData;

    let string = '';

    if (street && street.length > 0) string += street + ' ';
    if (street2 && street2.length > 0) string += street2 + ' ';
    return string;
  };

  buildAddressLastLine = () => {
    const { city, state, zipcode } = this._mappedAddressData;

    let string = '';

    if (city && city.length > 0) string += city + ', ';
    if (state && state.length > 0) string += state + ' ';
    if (zipcode && zipcode.length > 0) string += zipcode;

    return string;
  };

  formatAddressToTextString = () => {
    const { street, street2, city, state, zipcode } = this._addressData;

    let string = '';

    if (street && street.length > 0) string += street + ' ';
    if (street2 && street2.length > 0) string += street2 + ' ';
    if (city && city.length > 0) string += city + ', ';
    if (state && state.length > 0) string += state + ' ';
    if (zipcode && zipcode.length > 0) string += zipcode;

    return string;
  };

  verifyAddress = () => {
    /*
      Documentation for input fields can be found at:
      https://smartystreets.com/docs/us-street-api#input-fields
    */

    // Optional excluded
    // this._lookup.addressee = 'John Doe';
    // this._lookup.inputId = '24601';  // Optional ID from your system
    // this._lookup.street2 = 'closet under the stairs';
    // this._lookup.urbanization = '';  // Only applies to Puerto Rico addresses
    const givenAddress = this._mappedAddressData;

    const { street, street2, city, state, zipcode } = givenAddress;

    this._queryData = new VerifyAddressUtil();
    this._queryData.street = street;
    this._queryData.secondary = street2;
    this._queryData.city = city;
    this._queryData.state = state;
    this._queryData.zipCode = zipcode;
    this._queryData.maxCandidates = 5;

    /*
      "invalid" is the most permissive match,
      this will always return at least one result even if the address is invalid.
      Refer to the documentation for additional MatchStrategy options.
     */
    this._queryData.match = 'invalid';

    const failureResponse = {
      isValid: false,
      suggestions: null,
      givenAddress,
      verifiedAddress: null,
      wasGivenAddressMatchedExact: null,
    };

    return AddressVerificationClient.send(this._queryData)
      .then(function (results) {
        const responseLookup = results.lookups && results.lookups.length > 0 ? results.lookups[0] : null;
        if (responseLookup === null) {
          return failureResponse;
        }

        const data = results.lookups[0];
        const {
          result: _results,
          // metadata,
          // components,
        } = data;
        const result = _results[0];

        /* Itemized explanations at https://smartystreets.com/docs/cloud/us-street-api */
        const {
          // inputIndex,
          // candidateIndex,
          // addressee,
          deliveryLine1,
          deliveryLine2,
          // lastLine,
          // deliveryPointBarcode,
          components,
          // metadata,
          analysis,
        } = result;

        if (!analysis) return failureResponse;

        const {
          // active,
          // cmra,
          // dpvFootnotes,
          // footnotes,
          // isEwsMatch,
          // isSuiteLinkMatch,
          // lacsLinkCode,
          // lacsLinkIndicator,

          dpvMatchCode, // is confirmed
          /*
            Status of the Delivery Point Validation (DPV). This indicates whether or not the address is present in the USPS data.

            Y — Confirmed; entire address is present in the USPS data. (To be certain the address is actually deliverable, verify that the dpv_vacant field has a value of N. You may also want to verify that the dpv_no_stat field has a value of N. However, the USPS is often several months behind in updating this data point, so only rely on the dpv_no_stat data if you are fully aware of its weaknesses and limitations.)
            (e.g., 1600 Amphitheatre Pkwy Mountain View, CA)
            N — Not confirmed; address is not present in the USPS data.
            S — Confirmed by ignoring secondary info; the main address is present in the USPS data, but the submitted secondary information (apartment, suite, etc.) was not recognized.
            (e.g., 62 Ea Daren Dr Apt 298 Anniston, AL)
            D — Confirmed but missing secondary info; the main address is present in the USPS data, but it is missing secondary information (apartment, suite, etc.).
            (e.g., 122 Mast Rd Lee, NH)
            [blank or null] — The address is not present in the USPS database.
          */

          // noStat, // has undeliverable status
          // vacant, // is vacant
        } = analysis;

        const { cityName: verifiedCity, state: verifiedState, zipCode: verifiedZipcode } = components;

        const acceptableDPVMatchCodes = ['Y', 'S'];
        const isValid = acceptableDPVMatchCodes.includes(dpvMatchCode);

        const suggestions = [];
        let verifiedAddress = null;
        let wasGivenAddressMatchedExact = false;

        if (!isUndefinedOrNull(dpvMatchCode)) {
          verifiedAddress = {
            street: deliveryLine1,
            street2: deliveryLine2,
            city: verifiedCity,
            state: verifiedState,
            zipcode: verifiedZipcode,
          };
          suggestions.push(verifiedAddress);

          wasGivenAddressMatchedExact = doObjectsContainSameKeysAndValues(
            convertObjectKeyValuesFromUndefinedOrNullToBlankString(givenAddress),
            convertObjectKeyValuesFromUndefinedOrNullToBlankString(verifiedAddress)
          );
        }

        // console.log('analysis', {
        //   dpvMatchCode,
        //   // noStat,
        //   // vacant,
        //
        //   verifiedAddress,
        //   suggestions,
        //   components: JSON.stringify(components)
        // })

        return {
          isValid,
          suggestions,
          givenAddress,
          verifiedAddress,
          wasGivenAddressMatchedExact,
        };
      })
      .catch((err) => {
        console.error(err);
        return failureResponse;
      });
  };

  getAutocompleteSuggestions = () => {
    /*
      Documentation for input fields can be found at:
      https://smartystreets.com/docs/cloud/us-autocomplete-api#pro-http-request-input-fields
    */

    // const util = new AddressUtil({ addressData: this._addressData });
    const { street, street2, city, state, zipcode } = this._addressData;

    let query = `${street || ''}`;
    if (street2 && street2.length > 0) {
      query += ` ${street2}`;
    }

    this._queryData = new AddressAutocompleteUtil(query);

    if (city) this._queryData.prefer_cities = [city];

    if (state) this._queryData.prefer_states = [state];

    if (zipcode) this._queryData.prefer_zip_codes = [zipcode];

    this._queryData.preferGeolocation = 'city';
    this._queryData.maxResults = 5;
    this._queryData.preferRatio = 50;

    return AddressAutocompleteClient.send(this._queryData)
      .then(function (results) {
        const suggestions = results.result;
        if (suggestions && suggestions.length > 0) {
          return suggestions;
        } else {
          return null;
        }
      })
      .catch((err) => {
        console.error(err);
        return null;
      });
  };
}

export default AddressUtil;
