import {
  ADD_THOUGHTS,
  CREATE_THOUGHT,
  UPDATE_THOUGHT,
  LOADING_THOUGHTS_FOR_SECURITY,
  TRACK_SUGGESTED_THOUGHT,
  ADD_EXCLUDE_THOUGHTS_SUGGESTIONS,
} from '../constants';

import { convertArrayToObject, isUndefinedOrNull } from '../helpers/generalHelpers';
import { parseThoughtsIntoSecurities, findIndexOfThoughtInThoughts } from '../helpers/thoughtsHelpers';

const defaultState = {
  securityLookup: {},
  thoughtsByIdSuggested: [],
};

export default function thoughtsReducer(state = defaultState, action) {
  let payload,
    userCreated,
    thought,
    thoughts,
    thoughtsBySecurity,
    newThoughtIdArray,
    updatedThoughtSecurities,
    security,
    securityId,
    securityIds,
    securities,
    stateSecurities,
    stateSecuritiesKeys,
    securityThoughts,
    securityThoughtsCopy,
    currentSecurityThoughtData,
    currentSecurityThoughts,
    newThoughtData,
    updatedThoughtData,
    thoughtGroups;

  switch (action.type) {
    case CREATE_THOUGHT:
      thought = action.payload.thought;
      thought.showAtTop = true; // used to move current user freshly created thoughts to top of list
      securityId = thought.security_id;
      currentSecurityThoughtData = state.securityLookup[securityId] || {};
      currentSecurityThoughts = currentSecurityThoughtData.thoughtLookup || {};

      return {
        ...state,
        securityLookup: {
          ...state.securityLookup,
          [securityId]: {
            ...currentSecurityThoughtData,
            thoughtLookup: {
              ...currentSecurityThoughts,
              [thought.id]: thought,
            },
          },
        },
      };

    case ADD_THOUGHTS:
      thoughtGroups = action.payload;
      updatedThoughtData = {};
      thoughtGroups.forEach((group) => {
        const securityId = group.securityId;
        const thoughts = group.thoughts || [];
        const thoughtsData = {};
        thoughts.forEach((thought) => {
          thoughtsData[thought.id] = thought;
        });
        updatedThoughtData[securityId] = {
          ...(state.securityLookup[securityId] || {}),
          loading: false,
          thoughtLookup: {
            ...((state.securityLookup[securityId] || {}).thoughtLookup || {}),
            ...thoughtsData,
          },
        };
      });
      return {
        ...state,
        securityLookup: {
          ...state.securityLookup,
          ...updatedThoughtData,
        },
      };

    case UPDATE_THOUGHT:
      thought = action.payload.thought;
      securityId = thought.security_id;
      currentSecurityThoughtData = state.securityLookup[securityId] || {};
      currentSecurityThoughts = currentSecurityThoughtData.thoughtLookup || {};

      return {
        ...state,
        securityLookup: {
          ...state.securityLookup,
          [securityId]: {
            ...currentSecurityThoughtData,
            thoughtLookup: {
              ...currentSecurityThoughts,
              [thought.id]: thought,
            },
          },
        },
      };

    case LOADING_THOUGHTS_FOR_SECURITY:
      securityIds = action.payload;
      updatedThoughtData = {};
      securityIds.forEach((id) => {
        updatedThoughtData[id] = {
          ...(state.securityLookup[id] || {}),
          loading: true,
        };
      });

      return {
        ...state,
        securityLookup: {
          ...state.securityLookup,
          ...updatedThoughtData,
        },
      };

    case ADD_EXCLUDE_THOUGHTS_SUGGESTIONS:
      return {
        ...state,
        thoughtsByIdSuggested: [...state.thoughtsByIdSuggested, ...action.payload],
      };

    case TRACK_SUGGESTED_THOUGHT:
      return {
        ...state,
        thoughtsByIdSuggested: [...state.thoughtsByIdSuggested, action.payload],
      };

    default:
      return state;
  }
}
