import React from 'react';

import InfoIcon from '../../containers/UI/InfoIcon';
import FundamentalData from '../../containers/SecurityData/FundamentalData';

import { getFilterDisplayName } from '../../constants/finData';

const FinancialTableRow = (props) => {
  const { property, security, hideIfNA } = props;

  if (Array.isArray(property)) {
    const propertiesLabel = property.map((p) => (typeof p === 'object' ? p.displayName : p)).join(' ');
    const infoIconDefinitions = property.map(
      (p) => `tooltip_${getFilterDisplayName(typeof p === 'object' ? p.property : p, true)}`
    );

    return (
      <tr className="fin-data-row clearfix">
        <td className="row-key">
          <span className="info-icon-text-wrapper">
            {`${propertiesLabel}`}
            {infoIconDefinitions && (
              <InfoIcon
                word={infoIconDefinitions}
                symbol={(security || {}).symbol}
                style={{ top: 'auto', bottom: '-1px' }}
              />
            )}
          </span>
        </td>

        <td className="row-data">
          {property.map((p, i) => (
            <div
              style={{ display: 'inline' }}
              key={`fundamental-data-property-${typeof p === 'object' ? p.property : p}-${security.security_id}`}
            >
              <FundamentalData
                hideIfNA={hideIfNA}
                securityId={security.security_id}
                property={typeof p === 'object' ? p.property : p}
              />
              {i === 0 && ' ('}
              {i === 1 && ')'}
            </div>
          ))}
        </td>
      </tr>
    );
  }

  const propertyDisplayName = typeof property === 'object' ? property.displayName : property;
  const propertyName = typeof property === 'object' ? property.property : property;
  return (
    <tr className="fin-data-row clearfix">
      <td className="row-key">
        <span className="info-icon-text-wrapper">
          {propertyDisplayName}
          {getFilterDisplayName(propertyName, true) && (
            <InfoIcon
              word={`tooltip_${getFilterDisplayName(propertyName, true)}`}
              symbol={(security || {}).symbol}
              style={{ top: 'auto', bottom: '-1px' }}
            />
          )}
        </span>
      </td>

      <td className="row-data">
        <FundamentalData hideIfNA={hideIfNA} securityId={security.security_id} property={propertyName} />
      </td>
    </tr>
  );
};

export default FinancialTableRow;
