import { usersAdapter } from '../adapters/usersAdapter';
import { convertArrayToObject } from '../helpers/generalHelpers';
import { serializeResponseForPortfolioData } from '../helpers/portfolioHelpers';

import {
  ADD_THOUGHT_LEADERS,
  REMOVE_CONNECTION,
  REMOVE_CONNECTION_FAILURE,
  ADD_PEOPLE_YOU_MAY_KNOW,
  ADD_USERS,
  UPDATE_USER,
  HIDE_CONNECTION_SUGGESTION,
  SEND_CONNECTION_REQUEST,
  CONNECTION_REQUEST_FAILED,
  ACCEPT_CONNECTION_REQUEST,
  ACCEPT_CONNECTION_REQUEST_FAILED,
  IGNORE_CONNECTION_REQUEST,
  IGNORE_CONNECTION_REQUEST_FAILED,
  QUERY_USERS,
  USERS_QUERY_RESULTS,
  LOADING_USERS_IDEA_PERFORMANCES,
  ADD_USERS_PERFORMANCES,
  ADD_TOP_CONNECTION_PERFORMANCES,
  LOADING_CURRENT_USER_CONNECTIONS,
  ADD_CURRENT_USER_CONNECTIONS,
  LOADING_USER_CONNECTIONS,
  ADD_USER_CONNECTIONS,
  ADD_PORTFOLIO_DATA,
  COMPLETE_LOADING_PENDING_CONNECTIONS,
  SHOW_MODAL,
  ADD_COLLECTIVES,
  membershipActionTypes,
} from '../constants';

import { createBasicErrorModal } from '../constants/modals';
import { buildConnectionsDataFromResponse, isUserCollective } from '../helpers/usersHelpers';
import { setTrackingUserProperty } from '../utils/tracking/amplitude';
import { quickFetchSecuritiesData } from './securitiesActions';

export const getUser = (userId, config = {}) => {
  return function (dispatch) {
    if (config.include && config.include.includes('performance')) {
      dispatch({
        type: LOADING_USERS_IDEA_PERFORMANCES,
        payload: { userIds: [userId] },
      });
    }

    return usersAdapter.getUser(userId, config).then((response) => {
      const wasError = !response || response.error || !response.data.users || !response.data.users[0];
      const user = wasError ? {} : response.data.users[0];

      if (wasError) {
        if (config.updateUser) {
          dispatch({
            type: UPDATE_USER,
            payload: {
              users: [
                {
                  user_id: userId,
                  error: 'There was an error updating user information.',
                },
              ],
            },
          });
        } else {
          dispatch({
            type: ADD_USERS,
            payload: {
              users: [
                {
                  user_id: userId,
                  error: 'There was an error updating user information.',
                },
              ],
            },
          });
        }
        if (config.include && config.include.includes('performance')) {
          dispatch({
            type: ADD_USERS,
            payload: {
              users: [
                {
                  user_id: userId,
                  error: 'There was an error updating user information.',
                },
              ],
            },
          });
        }
      } else {
        if (!user) {
          console.error('User was not found in API response', response);
        }

        if (config.updateUser) {
          dispatch({
            type: UPDATE_USER,
            payload: { user },
          });
        } else {
          if (isUserCollective(user)) {
            dispatch({
              type: ADD_COLLECTIVES,
              payload: {
                collectives: [user],
              },
            });
          } else {
            dispatch({
              type: ADD_USERS,
              payload: { users: [user] },
            });
          }
        }

        if (config.include && config.include.includes('performance')) {
          const ideas = user.positions;
          if (ideas) {
            const securityIds = Object.keys(ideas);
            quickFetchSecuritiesData(securityIds)(dispatch);
          }

          dispatch({
            type: ADD_USERS_PERFORMANCES,
            payload: { users: [user] },
          });
        }

        if (config && config.isCurrentUser) {
          const portfolioData = serializeResponseForPortfolioData(response.data);
          dispatch({
            type: ADD_PORTFOLIO_DATA,
            payload: { portfolioData },
          });
        }
      }
    });
  };
};

export const getUserIdeaPerformances = (userIds) => {
  return function (dispatch) {
    dispatch({
      type: LOADING_USERS_IDEA_PERFORMANCES,
      payload: { userIds },
    });

    return usersAdapter.getUserIdeaPerformances(userIds).then((response) => {
      const wasError = !response || response.error;
      if (!wasError) {
        const users = response.data.users;
        dispatch({
          type: ADD_USERS_PERFORMANCES,
          payload: { users },
        });
      }
      return response;
    });
  };
};

export const getUserConnections = (userId) => {
  return function (dispatch) {
    dispatch({
      type: LOADING_USER_CONNECTIONS,
      payload: { userId },
    });

    return usersAdapter.getUserConnections(userId).then((response) => {
      const wasError = !response || response.error || !response.data;
      const users = wasError ? {} : response.data.connections;
      const connections = wasError ? {} : response.data.connections;
      if (wasError) {
        dispatch({
          type: ADD_USER_CONNECTIONS,
          payload: {
            user: {
              user_id: userId,
              error: 'There was an error updating user information.',
            },
          },
        });
      } else {
        dispatch({
          type: ADD_USERS,
          payload: { users },
        });
        dispatch({
          type: ADD_USER_CONNECTIONS,
          payload: { userId, connections },
        });
      }
    });
  };
};

export const getCurrentUserConnections = () => {
  return function (dispatch) {
    dispatch({
      type: LOADING_CURRENT_USER_CONNECTIONS,
    });
    return usersAdapter.getCurrentUserConnections().then((response) => {
      if (response && response.data && 'connections' in response.data) {
        const users = response.data.connections;
        const { connectionsArray } = buildConnectionsDataFromResponse(response);
        dispatch({
          type: ADD_USERS,
          payload: { users },
        });
        dispatch({
          type: ADD_CURRENT_USER_CONNECTIONS,
          payload: { connections: connectionsArray },
        });

        setTrackingUserProperty('Connection Count', users.length);
        return connectionsArray;
      } else {
        console.error('Error fetching connections');
      }
      return [];
    });
  };
};

export const fetchThoughtLeaders = () => {
  return function (dispatch) {
    return usersAdapter.fetchThoughtLeaders().then((response) => {
      if (response && 'thought_leaders' in response && response.thought_leaders.length > 0) {
        const thoughtLeadersArray = response.thought_leaders.map((tl) => ({
          ...tl,
          isThoughtLeader: true,
        }));
        const thoughtLeadersTable = convertArrayToObject(thoughtLeadersArray, 'user_id');

        dispatch({
          type: ADD_THOUGHT_LEADERS,
          payload: thoughtLeadersTable,
        });
      }
    });
  };
};

export const fetchTopConnections = () => {
  return function (dispatch) {
    return usersAdapter.fetchTopConnections().then((response) => {
      if (response && response.data) {
        const connectionsPerformance = response.data.performance_leaders || [];
        const formattedConnectionsPerformanceList = connectionsPerformance.map((obj) => ({
          performance: obj.performance,
          user_id: obj.user.user_id,
        }));

        dispatch({
          type: ADD_TOP_CONNECTION_PERFORMANCES,
          payload: {
            connectionsPerformance: formattedConnectionsPerformanceList,
          },
        });
      }
    });
  };
};

export const fetchPeopleYouMayKnow = () => {
  return function (dispatch) {
    return usersAdapter.fetchPeopleYouMayKnow().then((response) => {
      if (response && response.data && response.data.users) {
        const users = response.data.users;
        const usersIdList = users.map((user) => user.user_id);

        // make sure API returned data for users in usersYouMayKnow otherwise filter out user
        const usersYouMayKnow = response.data.suggestions;
        const usersYouMayKnowValidated = usersYouMayKnow.filter((userYouMayKnow) => {
          return usersIdList.includes(userYouMayKnow.user_id);
        });
        dispatch({
          type: ADD_USERS,
          payload: { users },
        });
        dispatch({
          type: ADD_PEOPLE_YOU_MAY_KNOW,
          payload: { usersYouMayKnow: usersYouMayKnowValidated },
        });
      } else {
        const modalMessage = 'Something went wrong. Please refresh and try again.';
        const modal = {
          contentComponent: createBasicErrorModal(modalMessage),
          dismissable: true,
          size: 'wide',
        };
        dispatch({
          type: SHOW_MODAL,
          payload: modal,
        });
      }
    });
  };
};

export const sendConnectionRequest = (connectUserId, context) => {
  return function (dispatch) {
    dispatch({
      type: SEND_CONNECTION_REQUEST,
      payload: { connectUserId },
    });

    return usersAdapter.sendConnectionRequest(connectUserId, context).then((response) => {
      const didRequestFail = !response || response.error || (response.data && response.data.error);
      if (didRequestFail) {
        dispatch({
          type: CONNECTION_REQUEST_FAILED,
          payload: { connectUserId },
        });
      }
      return response;
    });
  };
};

export const acceptConnectionRequest = (connectUserId, context, currentUserId) => {
  return function (dispatch) {
    return usersAdapter.acceptConnectionRequest(connectUserId, context).then((response) => {
      const didRequestFail = !response || response.error || (response.data && response.data.error);
      if (didRequestFail) {
        dispatch({
          type: ACCEPT_CONNECTION_REQUEST_FAILED,
          payload: { connectUserId },
        });
      } else {
        dispatch({
          type: ACCEPT_CONNECTION_REQUEST,
          payload: { connectUserId, userId: currentUserId },
        });
      }
      return response;
    });
  };
};

export const ignoreConnectionRequest = (connectUserId, context) => {
  return function (dispatch) {
    dispatch({
      type: IGNORE_CONNECTION_REQUEST,
      payload: { connectUserId },
    });

    return usersAdapter.ignoreConnectionRequest(connectUserId, context).then((response) => {
      const didRequestFail =
        !response || response.error || (response.data && response.data.error) || response.data.status !== 'success';
      if (didRequestFail) {
        dispatch({
          type: IGNORE_CONNECTION_REQUEST_FAILED,
          payload: { connectUserId },
        });
      }
      return response;
    });
  };
};

export const dismissConnectionSuggestion = (dismissUserId, dismissalType) => {
  return function (dispatch) {
    if (dismissalType === 'Clicked') {
      dispatch({
        type: HIDE_CONNECTION_SUGGESTION,
        payload: { dismissUserId },
      });
    }

    return usersAdapter.dismissConnectionSuggestion(dismissUserId, dismissalType);
  };
};

export const viewProfile = (userId, context) => {
  const decodedContext = decodeURIComponent(context);
  usersAdapter.viewProfile(userId, decodedContext);
  return {
    type: 'IGNORE',
  };
};

export const viewViewpointOrgProfile = (userId, context) => {
  const decodedContext = decodeURIComponent(context);
  usersAdapter.viewViewpointOrgProfile(userId, decodedContext);
  return {
    type: 'IGNORE',
  };
};

export const viewSuggestedConnection = (userIds, context) => {
  userIds.map((userId) => usersAdapter.viewSuggestedConnection(userId, context));
  return {
    type: 'IGNORE',
  };
};

export const sendUserInvite = (email, message, context) => {
  return function (dispatch) {
    return usersAdapter.sendUserInvite(email, message, context).then((response) => {
      return response;
    });
  };
};

export const queryUsers = (queryString) => {
  return function (dispatch) {
    dispatch({
      type: QUERY_USERS,
      payload: queryString,
    });

    return usersAdapter.queryUsers(queryString).then((response) => {
      if (response && response.data && response.data.users) {
        const users = response.data.users;
        const userIds = response.data.users.map((user) => user.user_id);

        dispatch({
          type: ADD_USERS,
          payload: { users },
        });

        dispatch({
          type: USERS_QUERY_RESULTS,
          payload: {
            query: queryString,
            results: userIds,
          },
        });
        return {
          ok: true,
          users: response.data.users,
        };
      } else {
        return {
          error: true,
        };
      }
    });
  };
};

export const getPendingConnectionRequestUsers = () => {
  return function (dispatch) {
    return usersAdapter.getPendingConnectionRequestUsers().then((response) => {
      if (response && response.data && response.data.users) {
        const users = response.data.users;
        dispatch({
          type: ADD_USERS,
          payload: { users },
        });
        dispatch({
          type: COMPLETE_LOADING_PENDING_CONNECTIONS,
        });
      } else {
        // TODO: handle error
      }
    });
  };
};

export const removeConnection = (currentUserId, userId, config = {}) => {
  return function (dispatch) {
    return usersAdapter.removeConnection(userId).then((response) => {
      const { status, data, errorMessage } = response;
      if (status === 200) {
        dispatch({
          type: REMOVE_CONNECTION,
          payload: { userId, currentUserId },
        });
      } else {
        const modalMessage = 'Could not remove connection. Please try again later.';
        const modal = {
          contentComponent: createBasicErrorModal(modalMessage),
          dismissable: true,
          size: 'wide',
        };
        dispatch({
          type: SHOW_MODAL,
          payload: {
            ...modal,
          },
        });
      }
    });
  };
};

export const fetchAnnualMembershipEligibility = () => async (dispatch) => {
  const response = await usersAdapter.fetchAnnualMembershipEligibility();
  const { status, data, raw_response } = response;
  if (status === 200) {
    const { already_enrolled, annual_membership_fee, eligible_for_program } = data;
    dispatch({
      type: membershipActionTypes.update,
      payload: {
        isEligible: eligible_for_program,
        isEnrolled: already_enrolled,
        annualFee: annual_membership_fee,
      },
    });
    return {
      ok: true,
    };
  } else {
    console.error(response);
    return {
      ok: false,
      errorMessage: raw_response?.data?.error || 'Something went wrong. Please try again.',
    };
  }
};

export const startAnnualMembership = async () => {
  const response = await usersAdapter.startAnnualMembership();
  const { status, raw_response } = response;
  if (status === 200) {
    return {
      ok: true,
    };
  } else {
    console.error(response);
    return {
      ok: false,
      errorMessage: raw_response?.data?.error || 'Something went wrong. Please try again.',
    };
  }
};

export const emailMeAppDownloadLink = async () => {
  const response = await usersAdapter.emailMeAppDownloadLink();
  const { status, raw_response } = response;
  if (status === 200) {
    return {
      ok: true,
    };
  } else {
    console.error(response);
    return {
      ok: false,
      errorMessage: raw_response?.data?.error || 'Something went wrong. Please try again.',
    };
  }
};
