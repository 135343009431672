import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import ThoughtContainer from './ThoughtContainer';
import ThoughtsDataContainer from './ThoughtsDataContainer';
import BasicSecurityDataWrapper from '../SecurityData/BasicSecurityDataWrapper';
import { LinkToSecurityThoughts } from '../UI/LinkToSecurityThoughts';

import Page from '../../components/layout/Page';
import PageLoading from '../../components/PageLoading';
import Icon from '../../components/misc/Icon';

import { throwError } from '../../helpers/devToolHelpers';
import { getThoughtImpressionEventName } from '../../constants/tracking';

class ThoughtPage extends Component {
  constructor() {
    super();
    this.state = {
      securityId: null,

      _noThoughtExists: false,
      _wasError: false,
    };
  }

  componentDidMount() {
    this.getData();
    this._logPageView();
  }

  render() {
    if (this.wasError()) {
      return this._renderError();
    }

    if (this.isLoading()) {
      return <PageLoading />;
    }

    return (
      <Page className={`thoughts-page-container`}>
        <BasicSecurityDataWrapper
          securityId={this.returnSecurityId()}
          useLoadingComponent
          isUserAuthed={this._isUserAuthed()}
        >
          <ThoughtsDataContainer thoughtId={this.returnThoughtId()} isUserAuthed={this._isUserAuthed()}>
            <ThoughtContainer
              viewingContext={getThoughtImpressionEventName('THOUGHT_PAGE')}
              isUserAuthed={this._isUserAuthed()}
              showThoughtHeading
              useStandaloneThoughtHeading
              showAlternateOpinionActionStyle
              useShareInModal
              recordImpressionOnMount
            />
          </ThoughtsDataContainer>
          <LinkToSecurityThoughts isUserAuthed={this._isUserAuthed()} />
        </BasicSecurityDataWrapper>
      </Page>
    );
  }

  _renderError = () => {
    if (this.doesThoughtNotExist()) {
      return this._renderThoughtNotFound();
    }

    return <Page className={`thoughts-page-container`}>Something went wrong, please try again later.</Page>;
  };

  _renderThoughtNotFound = () => {
    return (
      <Page className={`thoughts-page-container not-found`}>
        <div className={`not-found-message-container`}>
          <Icon icon="fa-search" size="xlarge" color="#ddd" />
          <div className={`message`}>We could not find that Pro/Con.</div>
        </div>
      </Page>
    );
  };

  isLoading = () => !this.returnSecurityId();

  _setWasError = () => {
    this.setState(() => ({
      _wasError: true,
    }));
  };

  wasError = () => this.state._wasError;

  doesThoughtNotExist = () => this.state._noThoughtExists;

  getData = () => {
    this.getThoughtData().then((response) => {
      if (!this._didThoughtDataRequestReturnAValidThought(response)) {
        this._setCouldNotFindThought();
        return this._setWasError();
      }

      const securityId = this._getSecurityIdFromGetThoughtDataResponse(response);
      if (!securityId) {
        return throwError('Could not find securityId in response', true, response);
      }

      this._setSecurityId(securityId);
      this.getThoughtSecurityData(securityId);
    });
  };

  _didThoughtDataRequestReturnAValidThought = (response) =>
    this._returnThoughtDataFromGetThoughtDataResponse(response).id > 0;

  _getSecurityIdFromGetThoughtDataResponse = (response) =>
    this._returnThoughtDataFromGetThoughtDataResponse(response).security_id;

  _returnThoughtDataFromGetThoughtDataResponse = (response) => (response && response.data) || {};

  _setCouldNotFindThought = () => {
    this.setState(() => ({
      _noThoughtExists: true,
    }));
  };

  getThoughtData = () => {
    return this.props.actions.getThoughtData(this.returnThoughtId());
  };

  getThoughtSecurityData = (securityId) => {
    return this.props.actions.quickFetchSecuritiesData([securityId]);
  };

  _setSecurityId = (securityId) => {
    this.setState(() => ({
      securityId,
    }));
  };

  returnSecurityId = () => this.state.securityId;

  returnThoughtId = () => {
    let thoughtId;
    try {
      thoughtId = parseInt(this.props.thoughtId, 10);
    } catch (error) {
      this._setCouldNotFindThought();
      return throwError(error, true, { props: this.props });
    }
    return thoughtId;
  };

  _isUserAuthed = () => this._returnCurrentUser().user_id;

  _returnCurrentUser = () => this.props.currentUser;

  _logPageView = () => {
    const event = 'Viewed Thought Page';
    const properties = {
      'Thought ID': this.returnThoughtId(),
      'Is Authed': this._isUserAuthed(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    thoughts: state.thoughts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThoughtPage);
