import React from 'react';
import styled from 'styled-components';
import IdeasListDataWrapper from '../DataWrappers/IdeasListDataWrapper';
import ThoughtsListWithSortOptions from '../Thoughts/ThoughtsListWithSortOptions';
import IdeasContainer from './IdeasContainer';
import CompareContainer from './CompareTab/CompareContainer';
import NewsContainer from '../News/NewsContainer';
import { convertLabelToDisplayName, tabIcons } from '@src/constants';
import { getIdeaPhraseFor } from '@src/helpers/terminologyHelpers';
import Icon from '@src/components/misc/Icon';

const Wrapper = styled.div``;
const HeaderTabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  text-align: center;
`;

const SecurityCardTab = (props) => {
  const tab = props.tab;
  const icon = tabIcons[tab.toLowerCase()];
  const isActiveOrHover = props.active || props.hovering ? 'active' : '';
  const activeClass = isActiveOrHover ? 'active' : '';

  const renderLabel = () => {
    let tabDisplayName = '';
    if (tab === 'ideas') {
      tabDisplayName = 'Community';
    } else {
      tabDisplayName = convertLabelToDisplayName(tab);
    }
    return <div className="tab-label-mobile">{tabDisplayName}</div>;
  };

  const handleClick = () => {
    props.handleClick(tab);
  };

  const renderFullSize = () => {
    let tabDisplayName = '';
    if (tab === 'ideas') {
      tabDisplayName = 'Community';
    } else {
      tabDisplayName = convertLabelToDisplayName(tab);
    }
    return (
      <div className={`security-card-tab flat-tab-color-style ${activeClass}`} onClick={handleClick}>
        <span>{tabDisplayName}</span>
      </div>
    );
  };

  const renderMobileSize = (icon) => {
    let tabDisplayName = '';
    if (tab === 'ideas') {
      tabDisplayName = 'Community';

    } else {
      tabDisplayName = convertLabelToDisplayName(tab);
    }

    return (
      <div
        className={`security-card-tab flat-tab-color-style tab-${tabDisplayName} ${activeClass} mobile`}
        onClick={handleClick}
      >
        <div className="tab-icon-mobile">
          <Icon icon={icon} size="fa-icon-medium" style={{ pointerEvents: 'none' }} />
        </div>
        {renderLabel()}
      </div>
    );
  };

  return props.isMobile ? renderMobileSize(icon) : renderFullSize();
};

export const SecurityCardBody = (props) => {
  const publicTabs = ['headlines', 'thoughts', 'compare'];
  const publicAndProtectedTabs = ['headlines', 'thoughts', 'ideas', 'compare'];

  const tabs = props.userIsAuthenticated ? publicAndProtectedTabs : publicTabs;
  const isMobile =
    (props.contentContainerWidth && props.contentContainerWidth < 625 && props.contentContainerWidth > 200) ||
    $(window).width() < 800;

  const renderTabContent = (activeTab) => {
    const renderThoughts = () => (
      <ThoughtsListWithSortOptions
        viewingContext={'Security Panel Thoughts List'} // viewingContext is used for tracking events related to filter and sorting
        security={props.security}
        isUserAuthed={props.userIsAuthenticated}
        showConnectionsThoughtsFilter
        scrollContainerSelector={props.isInPanel ? '.scroll-panel-container' : window}
        useInfiniteScroll
      />
    );

    const renderIdeas = () => {
      return (
        <IdeasListDataWrapper
          securityId={props.securityId}
          additionalTabData={props.additionalTabData}
          askingWhyFromNotif={props.askingWhyFromNotif}
          showCurrentUsersIdea={props.showCurrentUsersIdea}
        >
          <IdeasContainer
            security={props.security}
            removeIdea={props.removeIdea}
            additionalTabData={props.additionalTabData}
            askingWhyFromNotif={props.askingWhyFromNotif}
            showCurrentUsersIdea={props.showCurrentUsersIdea}
            currentUserId={props.currentUserId}
          />
        </IdeasListDataWrapper>
      );
    };

    const renderCompare = () => <CompareContainer securityId={props.securityId} />;

    const renderHeadlines = () => (
      <NewsContainer
        securityId={props.securityId}
        viewingContext={props.isInPanel ? 'Security Panel' : 'Security Page'}
        scrollContainerSelector={props.isInPanel ? '.scroll-panel-container' : window}
        isInPanel={props.isInPanel}
      />
    );

    const publicSessionRenderer = {
      thoughts: renderThoughts,
      compare: renderCompare,
      headlines: renderHeadlines,
    };

    // protected includes public tabs
    const protectedSessionRenderer = {
      ideas: renderIdeas,
    };

    const renderer = props.userIsAuthenticated
      ? { ...publicSessionRenderer, ...protectedSessionRenderer }
      : publicSessionRenderer;
    if (!activeTab) {
      const defaultTab = props.defaultActiveTab.toLowerCase();
      if (!defaultTab) {
        return renderer['thoughts']();
      }
      return renderer[defaultTab]();
    } else {
      const selectedTab = activeTab && activeTab.toLowerCase();
      if (!selectedTab || !renderer[selectedTab]) {
        console.error(`Tab ${selectedTab} is not valid, rendering default tab instead`);
        const defaultTab = props.defaultActiveTab && props.defaultActiveTab.toLowerCase();
        if (!defaultTab) {
          return renderer['thoughts']();
        }
        return renderer[defaultTab]();
      }
      return renderer[selectedTab]();
    }
  };

  return (
    <Wrapper className="security-card-body-container">
      <HeaderTabsWrapper>
        {tabs.map((tab, i) => (
          <SecurityCardTab
            key={`security-card-tab-${tab}`}
            isMobile={isMobile}
            tab={tab}
            active={(!props.activeTab && props.defaultActiveTab === tab) || tab === props.activeTab}
            handleClick={props.handleTabClick}
          />
        ))}
      </HeaderTabsWrapper>
      <div className="security-card-tab-content">{renderTabContent(props.activeTab)}</div>
    </Wrapper>
  );
};

export default SecurityCardBody;
