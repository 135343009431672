import { ADD_KARMA_POINTS, UPDATE_KARMA_POINTS, REACHED_MAX_DAILY_KARMA } from '../constants';

const defaultState = {
  karmaTotal: null,
  reachedDailyMax: false,
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case ADD_KARMA_POINTS:
      if (isNaN(state.karmaTotal)) {
        console.error('state.karmaTotal is null');
        return state;
      }

      const karmaEarned = action.payload;
      if (!isNaN(karmaEarned)) {
        return {
          ...state,
          karmaTotal: state.karmaTotal + karmaEarned,
          reachedDailyMax: false,
        };
      } else {
        return state;
      }

    case UPDATE_KARMA_POINTS:
      return {
        ...state,
        karmaTotal: action.payload,
      };

    case REACHED_MAX_DAILY_KARMA: {
      return {
        ...state,
        reachedDailyMax: true,
      };
    }

    default:
      return state;
  }
}
