import { ADD_PAGES_PARTIAL, PAGES_LOAD_ERROR } from '../constants';

const defaultState = {
  pagesLookup: {
    about: {
      isLoading: true,
      wasError: false,

      partial: null,
    },
    salesInquiry: {
      isLoading: true,
      wasError: false,

      partial: null,
    },
    enterprise: {
      isLoading: true,
      wasError: false,

      partial: null,
    },
    legal: {
      isLoading: true,
      wasError: false,

      partial: null,
    },
    termsOfService: {
      isLoading: true,
      wasError: false,

      partial: null,
    },
    privacyPolicy: {
      isLoading: true,
      wasError: false,

      partial: null,
    },
    marginDisclosure: {
      isLoading: true,
      wasError: false,

      partial: null,
    },
    referralRules: {
      isLoading: true,
      wasError: false,

      partial: null,
    },
    viewpointGuidelines: {
      isLoading: true,
      wasError: false,

      partial: null,
    },
  },
};

export default function pagesReducer(state = defaultState, action) {
  let page, partial;
  switch (action.type) {
    case ADD_PAGES_PARTIAL:
      page = action.payload.page;
      partial = action.payload.partial;
      return {
        ...state,
        pagesLookup: {
          ...state.pagesLookup,
          [page]: {
            ...state.pagesLookup[page],
            isLoading: false,
            wasError: false,
            partial,
          },
        },
      };

    case PAGES_LOAD_ERROR:
      page = action.payload.page;
      return {
        ...state,
        pagesLookup: {
          ...state.pagesLookup,
          [page]: {
            ...state.pagesLookup[page],
            isLoading: false,
            wasError: true,
          },
        },
      };

    default:
      return state;
  }
}
