import React from 'react';
import { useLearnLesson } from '../../../hooks/learn/useLearnLesson';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../../constants/paths';
import { BaseButtonWrapper, SkeletonButton } from '../../buttons';
import { Container } from '../../ui';
import { defineFontSettings, Text } from '../../../lib/nvstr-common-ui.es';
import { MessageOutline } from '../../../icons/svg/MessageOutline';
import { EXTRA_CONTENT_TYPES } from '../../../constants/learn/types';
import styled from 'styled-components';
import { tornadoColorPalette } from '../../../constants/colorPalettes/tornado';
import { useNavigateToSecurity } from '../../../hooks/securities/useNavigateToSecurity';
import { TrackingEvents } from '../../../../utils/tracking/events';

const fontSettings = defineFontSettings(
  {
    fontSize: '12px',
    lineHeight: '18px',
  },
  {
    fontSize: '12px',
    lineHeight: '18px',
  }
);

const Overrides = styled.span`
  ${BaseButtonWrapper} {
    padding: 8px 16px;
    @media (max-width: 350px) {
      padding: 8px 8px;
    }
  }
  * {
    white-space: unset !important;
    word-break: break-word;
  }
`;

const IconWrapper = styled.span`
  line-height: 1;
  svg {
    margin-top: 2.25px;
    height: 20px;
    width: 20px;

    path {
      fill: ${tornadoColorPalette.primary.charcoal};
    }
  }
`;

export const ExtraContent = ({ lessonId, textColor }) => {
  const { lesson: lessonData } = useLearnLesson(lessonId);
  const { metadata } = lessonData;
  const { extraContent } = metadata;

  const { id, type, message } = extraContent || {};
  const navigateToSecurity = useNavigateToSecurity(id);

  if (!extraContent) return null;

  const handleSecurityTypePress = () => {
    TrackingEvents.learn.PRESS_EXTRA_CONTENT_LINK.send({
      'Lesson ID': lessonId,
    });
    navigateToSecurity();
  };

  if (type === EXTRA_CONTENT_TYPES.security) {
    return (
      <Overrides>
        <SkeletonButton
          color={textColor}
          hoverTextColor={tornadoColorPalette.primary.oak}
          onClick={handleSecurityTypePress}
          fullWidth
        >
          <Container row verticallyCenter>
            <IconWrapper>
              <MessageOutline />
            </IconWrapper>
            <Container left={16}>
              <Text fontSettings={fontSettings} color={textColor}>
                {message}
              </Text>
            </Container>
          </Container>
        </SkeletonButton>
      </Overrides>
    );
  }

  return null;
};
