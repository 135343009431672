import React, { Component } from 'react';

import UserContainer from '../users/UserContainer';
import UserName from '../../components/user/UserName';
import IconButton from '../../components/buttons/IconButton';

class SeeConnectionOpinions extends Component {
  componentDidMount() {
    if (this._shouldAutoFocus()) {
      this._autoFocus();
    }
  }

  render() {
    return (
      <div
        ref={(el) => {
          this.Container = el;
        }}
        tabIndex="-1"
        className={`thought-users-container component-bg border-accent ${this._isTheSpacer() ? 'spacer' : ''}`}
        onBlur={this.props.hideTooltip}
      >
        {this._renderCloseIcon()}
        {this._renderUserList()}
      </div>
    );
  }

  _renderUserList = () => {
    const userIds = this._returnUserIds();
    const totalCount = this._returnCount();
    const connectionsRender = userIds.map((userId) => this._renderUserName(userId));
    const communityRender = this._renderCommunityCount();
    return totalCount > 0 ? (
      <div className={`thought-user-opinion-user-list`}>
        {connectionsRender}
        {communityRender}
      </div>
    ) : (
      <div className={`thought-user-opinion-user-list secondary-text-color`}>{'There are no responses yet.'}</div>
    );
  };

  _renderUserName = (userId) => {
    return (
      <UserContainer userId={userId} key={`thought-opinion-user-${userId}`}>
        <UserName withLink={false} />
      </UserContainer>
    );
  };

  _renderCommunityCount = () => {
    const count = this._returnCommunityUserCount();
    return (
      count > 0 && (
        <div className={`user-container`}>
          <div className={`user-name-container secondary-text-color`}>
            {`${count} Community Member${count > 1 ? 's' : ''}`}
          </div>
        </div>
      )
    );
  };

  _renderCloseIcon = () => {
    return (
      <IconButton
        customClass="dismiss-btn"
        icon="fa fa-times fa-times-thin"
        colorClassName="secondary-text-color"
        size="medium"
        handleClick={this.props.hideTooltip}
      />
    );
  };

  _shouldAutoFocus = () => this.props.autoFocus;

  _isTheSpacer = () => {
    // this is a hack so the list gets larger and does not cut off tooltip if too height and no additional thoughts below to absorb the height
    return this.props.isSpacer;
  };

  _autoFocus = () => {
    this._$returnNode().focus();
  };

  _returnNode = () => this.Container;

  _$returnNode = () => $(this._returnNode());

  _returnUserIds = () => this.props.opinionUserIds;

  _returnCount = () => this.props.opinionCount;

  _returnCommunityUserCount = () => this._returnCount() - this._returnUserIds().length;
}

export default SeeConnectionOpinions;
