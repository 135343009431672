import React from 'react';
import { connect } from 'react-redux';

import { Counter } from '../UI/Counter';

class RewardsCounter extends React.Component {
  render() {
    return <Counter count={this._returnRedeemableRewardsCount()} className={this.props.className} />;
  }

  _returnRewardsData = () => this.props.rewards;
  _returnRedeemableRewardsCount = () => this._returnRewardsData().not_redeemed_count;
}

const mapStateToProps = (state) => {
  return {
    rewards: state.rewards,
  };
};

export default connect(mapStateToProps)(RewardsCounter);
