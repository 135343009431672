import {
  OPEN_REBALANCER_PANEL,
  CLOSE_REBALANCER_PANEL,
  RUN_REBALANCER,
  NEW_REBALANCER_DATA_RECEIVED,
  REBALANCER_RUN_FAILED,
} from '../constants';
import { consoleError } from '../helpers/devToolHelpers';

const defaultState = {
  loading: true,

  isRebalancerRunning: false,
  latestRebalancerCallTimestamp: null,

  showPanel: false,
  data: {},
};

export default function rebalancerReducer(state = defaultState, action) {
  switch (action.type) {
    case OPEN_REBALANCER_PANEL: {
      return {
        ...state,
        showPanel: true,
      };
    }

    case CLOSE_REBALANCER_PANEL: {
      return {
        ...state,
        showPanel: false,
      };
    }

    case RUN_REBALANCER: {
      const optimizerData = action.payload.optimizerData;
      const optimizerCallTimestamp = action.payload.optimizerCallTimestamp;
      return {
        ...state,
        isRebalancerRunning: true,
        latestRebalancerCallTimestamp: optimizerCallTimestamp,
        data: {
          ...state.data,
          ...optimizerData,
        },
      };
    }

    case NEW_REBALANCER_DATA_RECEIVED: {
      const optimizerCallTimestamp = action.payload.optimizerCallTimestamp;

      if (state.latestRebalancerCallTimestamp === optimizerCallTimestamp) {
        const optimizerData = action.payload.optimizerData;
        return {
          ...state,
          loading: false,
          isRebalancerRunning: false,
          data: {
            ...state.data,
            ...optimizerData,
          },
        };
      } else {
        consoleError('Skipped Optimization: Optimization received but is older than current optimization request');
        return state;
      }
    }

    case REBALANCER_RUN_FAILED: {
      const optimizerCallTimestamp = action.payload.optimizerCallTimestamp;

      if (state.latestRebalancerCallTimestamp === optimizerCallTimestamp) {
        return {
          ...state,
          loading: false,
          isRebalancerRunning: false,
        };
      } else {
        consoleError('Skipped Optimization: Optimization received but is older than current optimization request');
        return state;
      }
    }

    default:
      return state;
  }
}
