import { consoleError } from './devToolHelpers';

export const returnUsersLookupFromUsersStore = (usersStore) => usersStore.userList;

export const userIsThoughtLeader = (user) => {
  if (!user) {
    return null;
  }
  return 'leader' in user;
};

export const isUserThoughtLeader = (user) => {
  if (!user) {
    return null;
  }
  return 'leader' in user;
};

export const isUserCollective = (user) => {
  return (user && user.is_collective) || false;
};

export const findUser = function (userId, usersStore, currentUser) {
  if (!userId) {
    consoleError(['Error: no userId provided', arguments]);
    return null;
  }

  if (!usersStore || !('userList' in usersStore)) {
    consoleError(['Error: invalid usersStore provided', arguments]);
    return null;
  }

  if (!currentUser) {
    consoleError(['Warning: currentUser not provided', arguments]);
  }

  const user = (currentUser.user_id === userId ? currentUser : usersStore.userList[userId]) || null;
  return (
    user ||
    consoleError([
      'Error: not able to find user in the usersStore and user is not current user',
      userId,
      usersStore,
      currentUser,
    ])
  );
};

export const findUserNonCurrentUser = (userId, usersStore) => {
  if (!userId) {
    consoleError(['Error: no userId provided', arguments]);
    return null;
  }
  if (!usersStore || !('userList' in usersStore)) {
    consoleError(['Error: invalid usersStore provided', arguments]);
    return null;
  }
  return (
    usersStore.userList[userId] ||
    consoleError(['Error: not able to find user in the usersStore and user is not current user', userId, usersStore])
  );
};

const possessiveizeName = (name) => name + (name[name.length - 1] === 's' ? "'" : "'s");

export const userDisplayName = (user) => user.profile_display_name;

export const userSecondaryDisplayName = (user) => user.profile_display_secondary_name;

export const userDisplayNamePossessive = (user) => possessiveizeName(userDisplayName(user));

export const userSecondaryDisplayNamePossessive = (user) => possessiveizeName(userSecondaryDisplayName(user));

export const userShortNamePossessive = (user) => possessiveizeName(`${user.first_name} ${user.last_name[0]}`);

export const userFirstNamePossessive = (user) => possessiveizeName(`${user.first_name}`);

export const userFullNamePossessive = (user) =>
  possessiveizeName(userFullName(user, { showThoughtLeaderCompany: true }));

export const userShortName = (user) => {
  if (user.isThoughtLeader || user.leader) {
    return displayThoughtLeaderName(user);
  }

  return `${user.first_name} ${user.last_name[0]}.`;
};

export const userFullName = (user, options) => {
  if ((user.isThoughtLeader || user.company) && options && options.showThoughtLeaderCompany) {
    return displayThoughtLeaderCompany(user);
  }
  if (user.isThoughtLeader || user.leader) {
    return displayThoughtLeaderName(user);
  }
  return `${user.first_name} ${user.last_name}`;
};

export const userFirstName = (user) => {
  if (isUserThoughtLeader(user)) {
    return `${user.company}`;
  } else {
    return `${user.first_name}`;
  }
};

export const userInitials = (user) => {
  if (isUserThoughtLeader(user)) {
    return displayThoughtLeaderInitials(user);
  }
  return `${user.first_name[0]}${user.last_name[0]}`;
};

export const displayThoughtLeaderCompany = (user) => {
  return `${user.company}`;
};

export const displayThoughtLeaderName = (user) => {
  return `${user.leader}`;
};

export const displayThoughtLeaderInitials = (user) => {
  return `${user.company
    .split(' ')
    .map((word) => word[0])
    .join('')}`;
};

// prop that is available inside <UserContainer/> given to all children
// export const userIsCurrentUser = ( user, currentUser ) => {
//   return user.user_id === currentUser.user_id;
// }

export const createUserLookupWithCurrentUser = (currentUser, userList) => {
  const userListCopy = { ...userList };
  const currentUserId = currentUser.user_id;

  return { ...userListCopy, [currentUserId]: { ...currentUser } };
};

export const getUserId = (user) => {
  return user ? user.user_id : null;
};

export const sortUserListAlphabetically = (users) =>
  users.sort((a, b) => {
    const aLastName = a.last_name;
    const aFirstName = a.first_name;
    const aName = `${aLastName} ${aFirstName}`;

    const bLastName = b.last_name;
    const bFirstName = b.first_name;
    const bName = `${bLastName} ${bFirstName}`;

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  });

export const returnConnectionStatus = (user) => user.connection_status_to_current_user;

export const isAConnection = (user) => returnConnectionStatus(user) === 'connected';

export const canRequestConnection = (user) => returnConnectionStatus(user) === null;

export const isActiveConnectionRequestSent = (user) => returnConnectionStatus(user) === 'request_sent';

export const isActiveConnectionRequestReceived = (user) => returnConnectionStatus(user) === 'request_received';

export const isConnectionRequestReceivedExpired = (user) => returnConnectionStatus(user) === 'request_received_expired';

export const isConnectionRequestSentExpired = (user) => returnConnectionStatus(user) === 'request_sent_expired';

export const isConnectionRequestExpired = (user) =>
  isConnectionRequestReceivedExpired(user) || isConnectionRequestSentExpired(user);

export const hasRequestedConnection = (user) =>
  returnConnectionStatus(user) === 'request_received' || isConnectionRequestReceivedExpired(user);

export const isRequestingConnection = (user) =>
  returnConnectionStatus(user) === 'request_sent' || isConnectionRequestSentExpired(user);

export const buildConnectionsDataFromResponse = (response) => {
  const users = response.data.connections;
  const connectionsArray = users.filter((user) => isAConnection(user));
  const pendingConnectionsArray = users.filter((user) => isRequestingConnection(user));
  const connectionRequestsReceivedArray = users.filter((user) => hasRequestedConnection(user));
  return {
    connectionsArray,
    pendingConnectionsArray,
    connectionRequestsReceivedArray,
  };
};

export const isUserPrivate = (user) => user.is_private;

export const convertUserIdsToUsers = (userIds, usersStore, currentUser) => {
  return userIds.map((id) => {
    const user = findUser(id, usersStore, currentUser);
    if (!user) {
      console.error(`Could not find user - ${id}`);
    }
    return user;
  });
};

export const sortCurrentUserToTop = (users, currentUserId, options) => {
  if (options && options.sort === 'alphabetically') {
    return users.sort((a, b) => {
      if (a.user_id === currentUserId) {
        return -1;
      }
      if (b.user_id === currentUserId) {
        return 1;
      }
      const aLastName = a.last_name;
      const aFirstName = a.first_name;
      const aName = `${aLastName} ${aFirstName}`;

      const bLastName = b.last_name;
      const bFirstName = b.first_name;
      const bName = `${bLastName} ${bFirstName}`;

      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    });
  }
  return users;
};

export const getUserIdFromUser = (user) => user.user_id;
