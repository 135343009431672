import React from 'react';
import { connect } from 'react-redux';
import { hasDoubleRewards } from '../../../helpers/currentUserHelpers';

class AvailableRewards extends React.PureComponent {
  render() {
    const _renderRewardOpportunity = (value, chance) => {
      if (chance === 1) {
        return (
          <div className={`reward-opportunity-container`}>
            <div className={`reward-value guaranteed-value'`}>100%</div>
            <div className={`reward-chance`}>{`chance of at least $${value}`}</div>
          </div>
        );
      } else {
        return (
          <div className={`reward-opportunity-container`}>
            <div className={`reward-value`}>{`$${value}`}</div>
            <div className={`reward-chance`}>{`1 in ${chance.toString()} chance`}</div>
          </div>
        );
      }
    };

    const _renderHeadingIneligible = () => {
      if (this.props.showHeading) {
        if (this.props.customHeadingText) {
          return (
            <div className={`rewards-available-heading`}>
              <span>{this.props.customHeadingText}</span>
            </div>
          );
        }
        return (
          <div className={`rewards-available-heading`}>
            <span className={`bold-text`} style={{ margin: '0 7px 0 0' }}>
              {'They'}
            </span>
            <span className={``}>{'get free cash to invest'}</span>
          </div>
        );
      }
      return null;
    };

    const _renderHeading = () => {
      if (this.props.showHeading) {
        if (this.props.customHeadingText) {
          return (
            <div className={`rewards-available-heading`}>
              <span>{this.props.customHeadingText}</span>
            </div>
          );
        }
        return (
          <div className={`rewards-available-heading`}>
            <span className={``} style={{ margin: '0 7px 0 0' }}>
              {'You'}
            </span>
            <span className={`bold-text`} style={{ margin: '0 7px 0 0' }}>
              {'both'}
            </span>
            <span className={``}>{'get free cash to invest'}</span>
          </div>
        );
      }
      return null;
    };

    return (
      <div className={`rewards-available-container`}>
        {this.props.isIneligible || this.props.reachedMaxReward ? _renderHeadingIneligible() : _renderHeading()}
        {!this.props.hideOdds && (
          <div className={`rewards-available`}>
            <div className={`reward-available-row`}>
              {_renderRewardOpportunity(20, 20)}
              {_renderRewardOpportunity(500, 2500)}
              {_renderRewardOpportunity(1000, 5000)}
            </div>
            <div className={`reward-available-row`}>{_renderRewardOpportunity(5, 1)}</div>
            {this.props.hasDoubleRewards && (
              <div className={`reward-doubled`}>Your reward values are doubled through February 12, 2019!</div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const currentUser = state.currentUser;
  return {
    hasDoubleRewards: hasDoubleRewards(currentUser),
  };
};

export default connect(mapStateToProps)(AvailableRewards);
