import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { returnPathTo } from '../../../constants/paths';
import * as Actions from '../../../actions/index';

import ConnectionsSearch from '../Search/ConnectionsSearch';
import TextArea from '../../StandaloneInputs/TextArea';

import CenterAlignItems from '../Layout/CenterAlignItems';
import InfoIconTooltip from '../InfoIconTooltip';
import UserMessageRecipient from '../../users/components/UserMessageRecipient';
import UserContainer from '../../users/UserContainer';
import UserName from '../../../components/user/UserName';

import FormLoadingOverlay from '../../../components/overlays/FormLoadingOverlay';
import Button from '../../../components/buttons/Button';
import IconButton from '../../../components/buttons/IconButton';
import PageLoading from '../../../components/PageLoading';
import LinkTo from '../../Links/LinkTo';
import WarningNotice from '../../../components/UI/WarningNotice';

import { userFirstName } from '../../../helpers/usersHelpers';
import { doesUserHaveConnections, isConnectionsDataLoadingForUser } from '../../../helpers/userConnectionsHelpers';
import { Facebook } from '../../Share/Facebook';
import { Twitter } from '../../Share/Twitter';
import { LinkedIn } from '../../Share/LinkedIn';
import { saveImpressionToStorageIfUnrecorded } from '../../../helpers/thoughtsHelpers';
import { sendFacebookTrackingEvent } from '../../../constants/facebookTracking';
import { returnCurrentUserThemeTestGroups } from '../../../helpers/currentUserHelpers';
import CopyUtil from '../Utils/CopyUtil';
import Icon from '../../../components/misc/Icon';
import { TrackingEvents } from '../../../utils/tracking/events';
import { REFERRAL_TYPES } from '../../../utils/tracking/events/referral';
import { FlatButton } from '../../../main/components/buttons';
import styled from 'styled-components';

const CopyLinkWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
  border: 1px solid transparent;

  :hover {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.buttonText};
  }

  i {
    font-size: 14px;
  }
  .text-w {
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    margin-left: 4px;
  }
`;

/*
  Required Props for <ReusableSearchContainer/>
    askType -> used for default question construction
    securitySymbol -> used for default question construction

  -----------------------------------------------

  Optional Props
    setToUserIds -> on mount will add users to To field
    cssAnimationClass -> string
    handleClose -> function to close form, used where parent is controlling whether or not the form is displayed, close button hidden if this func is not supplied
    showTooltipArrow -> renders a div for a tooltip arrow
*/

const CTL_ID = 'CTL';

class AskAnyoneForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _userIds: [],
      _userSearchText: '',
      _questionText: '',

      _didUserEnterCustomQuestion: false,

      _isSendingAsk: false,
      _wasSendAskSuccessful: false,
      _timeoutToCloseAskForm: null,

      _formErrorMessage: null,

      askCTLWarning: null,
    };

    this._didViewAskCTLWarning = false;
  }

  componentDidMount() {
    this._setDefaultQuestionCopy();
    const defaultUserIdsForToField = this.props.setToUserIds;
    if (defaultUserIdsForToField) {
      this._handleAddUserIdToAsk(defaultUserIdsForToField);
      setTimeout(this._focusTextAreaField, 50);
    }

    if (
      !doesUserHaveConnections(this._returnCurrentUserId(), this._returnUserConnectionsStore()) ||
      this.props.defaultAskingCTL
    ) {
      this._handleAddUserIdToAsk(CTL_ID);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const didUsersToSendToChange = this._returnUserIdsToAskLength(prevState) !== this._returnUserIdsToAskLength();
    if (!this._didUserEnterCustomQuestion() && didUsersToSendToChange) {
      this._setDefaultQuestionCopy();
    }

    if (!this._wasSendAskSuccessful(prevState) && this._wasSendAskSuccessful()) {
      this._onSendAskSuccessful();
    }

    const newUserIdAdded = this._returnNewUserIdAdded(prevState);
    if (newUserIdAdded.length > 0) {
      this._onNewUserAdded(newUserIdAdded);
    }

    // checks for form errors only if there is an error already in state
    this._revalidateForm();

    if (this.state.askCTLWarning && !this._didViewAskCTLWarning) {
      this._didViewAskCTLWarning = true;
      this._logViewAskCTLWarning(this.state.askCTLWarning);
    }
  }

  render() {
    return (
      <div
        ref={(el) => (this.AskAnyoneFormContainer = el)}
        className={`ask-anyone-form-container ${this.props.cssAnimationClass || ''}`}
        style={{
          ...(this.props.inModal ? {} : { position: 'relative' }),
        }}
      >
        {this._isLoading() ? this._renderLoading() : this._renderForm()}
      </div>
    );
  }

  _isLoading = () => isConnectionsDataLoadingForUser(this._returnCurrentUserId(), this._returnUserConnectionsStore());

  _renderLoading = () => <PageLoading flatStyle />;

  _renderAddConnectionsCTA = () => {
    return (
      <div className={'ask-anyone-form no-connections component-bg'}>
        {this._renderCloseButton()}
        <div className={'ask-anyone-add-connections-CTA-container'}>
          <span>Discuss investment ideas with people you trust</span>
          <span style={{ display: 'block', padding: '10px 0' }}>
            <LinkTo
              className="btn btn-primary-color btn-small-tall"
              to={returnPathTo('connections')}
              text={'Find Connections'}
              additionalClickAction={this._handleFindConnectionsClick}
            />
          </span>
        </div>
        {this._shouldShowShareOnSocialButton() && this._renderShareOnSocial()}
      </div>
    );
  };

  _renderForm = () => {
    return (
      <div className={'ask-anyone-form component-bg card-shadow-styling'}>
        {this._isCloseActionAvailable() && this._renderCloseButton()}
        <div className="border-accent" style={{ borderBottomWidth: '1px', borderBottomStyle: 'solid' }}>
          {this._renderToField()}
          {!this.isAskingCTL() && this._renderAskCTL()}
          {this.isAskingCTL() && this.state.askCTLWarning && this._renderAskCTLWarning()}
        </div>
        {this._renderQuestionField()}
        {this._isFormError() && this._renderFormErrorMessage()}
        {this._renderSendButton()}
        {this._isSendingAsk() && !this._wasSendAskSuccessful() && this._renderSendingAskOverlay()}
        {this._wasSendAskSuccessful() && this._renderSentAskOverlay()}
        {this._shouldShowShareOnSocialButton() && this._renderShareOnSocial()}
      </div>
    );
  };

  _renderAskCTLWarning = () => {
    const { askCTLWarning } = this.state;

    return (
      <div
        style={{
          paddingBottom: '20px',
        }}
      >
        <WarningNotice text={askCTLWarning} />
      </div>
    );
  };

  _renderCloseButton = () => {
    return (
      <div className={'close-button-container'}>
        <IconButton
          icon="fa-times fa-times-thin"
          colorClassName="secondary-text-color"
          handleClick={this._handleCancelAskWhy}
        />
      </div>
    );
  };

  _renderFormErrorMessage = () => {
    return <div className={'form-error-message-text'}>{this._returnFormErrorMessage()}</div>;
  };

  _renderSendButton = () => {
    return (
      <div className={'send-button-container'}>
        <FlatButton onClick={this._handleSendAskWhy}>Send</FlatButton>
      </div>
    );
  };

  _renderAskCTL = () => {
    return (
      <div className={'ask-ctl-btn-container'} style={{ padding: '0 0 15px 2px', marginTop: '-6px' }}>
        <CenterAlignItems>
          <InfoIconTooltip definitionKey={'tooltip_community_thought_leader'} position={'left'} />
          <span style={{ display: 'inline-block', position: 'relative' }}>
            <FlatButton transparent onClick={this._handleClickAskCTL}>
              Ask a Community Thought Leader
            </FlatButton>
          </span>
        </CenterAlignItems>
      </div>
    );
  };

  _renderToField = () => {
    const hasConnections = doesUserHaveConnections(this._returnCurrentUserId(), this._returnUserConnectionsStore());
    if (!hasConnections && !this._returnUserIdsToAsk().length) {
      return null;
    }
    const showInputAndUsers = !(this._isSendingAsk() || this._wasSendAskSuccessful());
    return (
      <div className={'to-field field-container'}>
        <label>To</label>
        {showInputAndUsers && this._renderUsersListAndInput()}
      </div>
    );
  };

  _renderQuestionField = () => {
    return (
      <div className={'question-field field-container border-accent'}>
        <TextArea
          name={'ask-anyone-question'}
          label={null}
          value={this._returnQuestionValue()}
          handleChange={this._handleQuestionInputChange}
        />
      </div>
    );
  };

  _renderUsersListAndInput = () => {
    return (
      <div className={'to-users-list'}>
        {this._renderUsers()}
        {doesUserHaveConnections(this._returnCurrentUserId(), this._returnUserConnectionsStore()) && (
          <ConnectionsSearch
            key={`connections-search-${this._returnUserIdsToAskLength()}`}
            placeholder={this._returnUserIdsToAsk().length > 0 ? 'Add Another' : 'Enter a name'}
            customKeyStrokeHandlers={this._returnCustomKeyPressHandlerForSearch()}
            handleResultSelection={this.handleResultSelection}
            customExcludeResultsFunc={this.customExcludeConnectionsSearchResultsFunc}
            onAfterQueryChange={this.handleConnectionsQueryChange}
            autoFocus
          />
        )}
      </div>
    );
  };

  _renderUsers = () => {
    return this._returnUserIdsToAsk().map((userId) => this._renderUser(userId));
  };

  _renderUser = (userId) => {
    return (
      <UserMessageRecipient
        key={`user-to-ask-${userId}`}
        className={'to-user no-select'}
        userId={userId}
        isCTL={userId === CTL_ID}
        isError={!this._isUserAskAllowed(userId)}
        errorMessage={this._returnCannotAskUserErrorMessage(userId)}
        handleRemove={this._handleRemoveUserFromTo}
      />
    );
  };

  _renderShareOnSocial = () => {
    return (
      <div className={'share-on-action-container'}>
        <div>
          <CopyUtil
            elementId={`thought_link_button_${this._returnThoughtId()}`}
            eventName={'Clicked Thought Link Copy'}
            copyValue={`${window.location.origin}/app/thoughts/${this._returnThoughtId()}`}
          >
            <CopyLinkWrapper id={'thought_link_button'}>
              <Icon icon={'fa fa-files-o'} size="small" />
              <span className={'text-w'}>Copy Link</span>
            </CopyLinkWrapper>
          </CopyUtil>
        </div>
        <div className={'share-on-social-container'}>
          <div className={'share-on-social-label secondary-text-color'}>
            <p>Share on:</p>
          </div>
          <div className={'share-on-social-links'}>
            <Facebook
              message={this.props.socialShareText}
              url={this.props.thoughtSocialShareLink}
              additionalActionOnClick={this._bindSocialNetworkToLogClickHandler('Facebook')}
            />
            <Twitter
              message={this.props.socialShareText}
              url={this.props.thoughtSocialShareLink}
              additionalActionOnClick={this._bindSocialNetworkToLogClickHandler('Twitter')}
            />
            <LinkedIn
              message={this.props.socialShareText}
              url={this.props.thoughtSocialShareLink}
              additionalActionOnClick={this._bindSocialNetworkToLogClickHandler('LinkedIn')}
            />
          </div>
        </div>
      </div>
    );
  };

  _returnAskDataForPermissionCheck = (userId) =>
    userId === CTL_ID
      ? {
          asked_community_thought_leader: true,
          askType: this._returnAskType(),
          [`${this._returnAskType()}_id`]: this._returnAskTypeId(),
        }
      : {
          asked_user_id: userId,
          askType: this._returnAskType(),
          [`${this._returnAskType()}_id`]: this._returnAskTypeId(),
        };

  _checkIsAskPermittedToUser = (userId) => {
    const askData = this._returnAskDataForPermissionCheck(userId);
    this.props.actions.checkAskPermission(askData).then((response) => {
      const { warning } = response;
      if (warning) {
        this.setState((prevState) => ({
          askCTLWarning: warning,
        }));
      }
    });
  };

  _isUserAskAllowed = (userId) =>
    !this._returnUserAskTypeIdData(userId, this._returnAskType(), this._returnAskTypeId());

  _returnCannotAskUserErrorMessage = (userId) =>
    this._returnUserAskTypeIdData(userId, this._returnAskType(), this._returnAskTypeId());

  _returnUserAskTypeIdData = (userId, askType, askTypeId) => this._returnUserAskTypeData(userId, askType)[askTypeId];

  _returnUserAskTypeData = (userId, askType) => this._returnUserAskData(userId)[askType] || {};

  _returnUserAskData = (userId) => this._returnAskDataUserLookup()[userId] || {};

  _returnAskDataUserLookup = () => this._returnAskDataStore().userLookup;

  _returnAskDataStore = () => this.props.ask;

  _renderSendingAskOverlay = () => {
    return (
      <FormLoadingOverlay
        show={this._isSendingAsk()}
        spinner={{
          iconClass: 'circle-loading-spinner circle-loading-spinner-medium',
          style: { marginBottom: '-7px' },
        }}
        message="Sending"
        className="posting-thought-overlay"
      />
    );
  };

  _renderSentAskOverlay = () => {
    return (
      <FormLoadingOverlay
        show={this._wasSendAskSuccessful()}
        spinner={{
          iconClass: 'fa fa-check-circle',
          style: {
            colorClassName: 'success-text-color',
            marginBottom: '-7px',
          },
        }}
        message="Sent"
      />
    );
  };

  _shouldShowShareOnSocialButton = () => this.props.allowSocialShare;

  _bindSocialNetworkToLogClickHandler = (network) => {
    return () => this._logNetworkShareClick(network);
  };

  _isCloseActionAvailable = () => this.props.handleClose;

  _returnUserSearchInputValue = () => this.state._userSearchText;

  _returnQuestionValue = () => this.state._questionText;

  _returnUserIdsToAsk = (state) => (state || this.state)._userIds;

  _returnUserIdsToAskLength = (state) =>
    Array.isArray(this._returnUserIdsToAsk(state)) ? this._returnUserIdsToAsk(state).length : 0;

  _returnLastAddedUserId = () =>
    this._returnUserIdsToAskLength() > 0 ? this._returnUserIdsToAsk()[this._returnUserIdsToAskLength() - 1] : null;

  _isFormError = () => !!this._returnFormErrorMessage();

  _returnFormErrorMessage = () => this.state._formErrorMessage;

  _returnNewUserIdAdded = (prevState) => {
    const prevUserIds = this._returnUserIdsToAsk(prevState);
    const newUserIds = this._returnUserIdsToAsk();
    return newUserIds.filter((id) => !prevUserIds.includes(id));
  };

  _isSendingAsk = (state) => (state || this.state)._isSendingAsk;

  _wasSendAskSuccessful = (state) => (state || this.state)._wasSendAskSuccessful;

  _returnTimeoutToCloseAskForm = () => this.state._timeoutToCloseAskForm;

  _onSendAskSuccessful = () => {
    this._setTimeoutToCloseAskForm();
  };

  _onNewUserAdded = (newUserIdAdded) => {
    if (Array.isArray(newUserIdAdded)) {
      newUserIdAdded.map((id) => {
        this._checkIsAskPermittedToUser(id);
      });
    } else {
      this._checkIsAskPermittedToUser(newUserIdAdded);
    }
  };

  _setIsSendingAsk = () => {
    this.setState(() => ({
      _isSendingAsk: true,
    }));
  };

  _clearIsSendingAsk = () => {
    this.setState(() => ({
      _isSendingAsk: false,
    }));
  };

  _setWasSendingAskSuccessful = () => {
    this.setState(() => ({
      _wasSendAskSuccessful: true,
    }));
  };

  _setTimeoutToCloseAskForm = () => {
    if (this._returnTimeoutToCloseAskForm()) {
      this.clearTimeoutToCloseAskForm();
    }

    const timeout = setTimeout(() => {
      this.props.handleClose();
    }, 2000);
    this.setState(() => ({
      _timeoutToCloseAskForm: timeout,
    }));
  };

  clearTimeoutToCloseAskForm = () => {
    const timeout = this._returnTimeoutToCloseAskForm();
    if (timeout) {
      clearTimeout(timeout);
      this.setState(() => ({
        _timeoutToCloseAskForm: null,
      }));
    }
  };

  _didUserEnterCustomQuestion = () => this.state._didUserEnterCustomQuestion;

  _setDidUserEnterCustomQuestion = () => {
    this.setState(() => ({
      _didUserEnterCustomQuestion: true,
    }));
  };

  _shouldAddGreetingToQuestion = () => this._returnUserIdsToAskLength() === 1;

  _addGreetingToQuestion = (questionString) => {
    const firstUserId = this._returnUserIdsToAsk()[0];
    const userFirstName = this._returnFirstNameFromUserId(firstUserId);
    if (!userFirstName) {
      return questionString;
    }
    const isFirstLetterI = questionString[0] === 'I';
    const edittedQuestion = isFirstLetterI
      ? questionString
      : `${questionString[0].toLowerCase()}${questionString.slice(1)}`;
    return `Hi ${userFirstName}, ${edittedQuestion}`;
  };

  _returnThoughtDefaultQuestion = () => {
    const defaultThoughtQuestion = 'I thought you may find this interesting. What do you think?';
    return this._shouldAddGreetingToQuestion()
      ? this._addGreetingToQuestion(defaultThoughtQuestion)
      : defaultThoughtQuestion;
  };

  _returnIdeaDefaultQuestion = () => {
    const defaultIdeaQuestion = `What are your latest thoughts on ${this._returnSecuritySymbol()}? What are the pros and cons here?`;
    return this._shouldAddGreetingToQuestion() ? this._addGreetingToQuestion(defaultIdeaQuestion) : defaultIdeaQuestion;
  };

  _returnSecurityDefaultQuestion = () => {
    const defaultSecurityQuestion = `What are your latest thoughts on ${this._returnSecuritySymbol()}? What are the pros and cons here?`;
    return this._shouldAddGreetingToQuestion()
      ? this._addGreetingToQuestion(defaultSecurityQuestion)
      : defaultSecurityQuestion;
  };

  _returnDefaultQuestion = () => {
    let string = '';
    if (this._returnAskType() === 'security') {
      string = this._returnSecurityDefaultQuestion();
    } else if (this._returnAskType() === 'idea') {
      string = this._returnIdeaDefaultQuestion();
    } else if (this._returnAskType() === 'thought') {
      string = this._returnThoughtDefaultQuestion();
    }
    return string;
  };

  _returnAskType = () => this.props.askType || console.error('Missing askType prop on <AskAnyoneForm/>');

  _returnAskTypeId = () => {
    const askType = this._returnAskType();
    if (askType === 'idea') {
      return this._returnIdeaId();
    } else if (askType === 'thought') {
      return this._returnThoughtId();
    } else if (askType === 'security') {
      return this._returnSecurityId();
    } else {
      return console.error('Could not find askTypeId based on askType given to <AskWhy/>');
    }
  };

  _returnSecuritySymbol = () => this.props.securitySymbol || 'this stock';

  customExcludeConnectionsSearchResultsFunc = (result) => !this._returnUserIdsToAsk().includes(result.data.user_id);

  _returnSecurityId = () => this.props.securityId;

  _returnIdeaId = () => this.props.ideaId;

  _returnThoughtId = () => this.props.thoughtId;

  _isThoughtAsk = () => this.props.thoughtId;

  isAskingCTL = () => this._returnUserIdsToAsk().includes(CTL_ID);

  _returnAskData = () => {
    if (this._returnUserIdsToAskLength() === 0) {
      return console.error('Cannot ask why with no one to send to.');
    }

    if (this._returnUserIdsToAskLength() > 1) {
      return this._returnUserIdsToAsk().map((id) => ({
        askedCTL: id === CTL_ID,
        userId: id,
        securityId: this._returnSecurityId(),
        ideaId: this._returnIdeaId(),
        thoughtId: this._returnThoughtId(),
        question: this._returnQuestionValue(),
      }));
    }
    return {
      askedCTL: this._returnUserIdsToAsk()[0] === CTL_ID,
      userId: this._returnUserIdsToAsk()[0],
      securityId: this._returnSecurityId(),
      ideaId: this._returnIdeaId(),
      thoughtId: this._returnThoughtId(),
      question: this._returnQuestionValue(),
    };
  };

  _handleClose = () => {
    this.props.handleClose();
  };

  _handleClickAskCTL = () => {
    this._handleUserSelection(CTL_ID);
  };

  _handleCancelAskWhy = () => {
    this._logCancelAskWhyForm();
    this.props.handleCancel();
  };

  _handleRemoveUserFromTo = (userId) => {
    this._removeToUserId(userId);
  };

  _handleAddUserIdToAsk = (userId) => {
    this._addToUserId(userId);
    sendFacebookTrackingEvent('Add User To Ask Form', {
      themeTestGroups: returnCurrentUserThemeTestGroups(this.props.currentUser),
    });
  };

  _handleUserSelection = (userId) => {
    this._handleAddUserIdToAsk(userId);
    this._logAddUser(userId);
  };

  _handleUserSearchInputChange = (name, value) => {
    this._setUserSearchValue(value);
  };

  _handleQuestionInputChange = (name, value) => {
    this._setQuestionValue(value);
    if (!this._didUserEnterCustomQuestion()) {
      this._setDidUserEnterCustomQuestion();
    }
  };

  handleResultSelection = (selectionData) => {
    const userId = selectionData && selectionData.data && selectionData.data.user_id;
    if (userId) {
      this._handleUserSelection(userId);
    }
  };

  handleConnectionsQueryChange = (newQueryValue) => {
    // can use this check for email enter
  };

  handleAfterAction = () => {
    if (this.props.handleAfterAction) {
      this.props.handleAfterAction();
    }
  };

  _handleSendAskWhy = () => {
    if (this._isFormInvalid()) {
      return false;
    }
    const askData = this._returnAskData();
    this._setIsSendingAsk();
    this.props.actions.sendAsk(askData).then((response) => {
      const { ok, rawResponse } = response;
      if (ok) {
        this._handleSendAskSuccess();
        this.handleAfterAction();
        this._logAskWhySent();
      } else {
        this._handleSendAskFailure(rawResponse, askData);
      }
    });
  };

  _handleSendAskSuccess = () => {
    this._setWasSendingAskSuccessful();
    if (this._isThoughtAsk()) {
      this._logThoughtAskImpression();
    }
  };

  _handleSendAskFailure = (response, askData) => {
    this._clearIsSendingAsk();
    this._clearSuccessAsksUserIdsFromForm(response, askData);

    const errorMessageComponent = this._generateErrorMessageFromAskResponse(response, askData);
    this._createAndShowErrorMessage(errorMessageComponent);
  };

  _clearSuccessAsksUserIdsFromForm = (response, askData) => {
    const successUserIds = this._returnSuccessAsksUserIdsFromResponse(response, askData);
    this._removeToUserIds(successUserIds);
  };

  _returnSuccessAsksFromResponse = (response, askData) => {
    const responseAskData = response && response.data && response.data.asks && Array.isArray(response.data.asks);
    if (responseAskData) {
      const responseAskDataWithFormData = responseAskData.map((ask, i) => ({
        ...ask,
        ...askData[i],
      }));
      const successfulAsks = responseAskDataWithFormData.filter((ask) => !ask.error);
      return successfulAsks;
    } else {
      return [];
    }
  };

  _returnSuccessAsksUserIdsFromResponse = (response, askData) => {
    const successfulAsks = this._returnSuccessAsksFromResponse(response, askData);
    const successUserIds = successfulAsks.map((askData) => askData.userId);
    return successUserIds;
  };

  _createErrorComponentForPartialSuccessAsks = (successUserIds) => {
    return (
      <div className={'ask-sent-success-message-container'}>
        <span style={{ marginRight: '4px' }}>{`Ask${successUserIds.length > 1 ? 's' : ''} sent successfully to `}</span>
        {successUserIds.map((userId, i) => (
          <span key={`sent-ask-success-to-${userId}`}>
            <UserContainer userId={userId}>
              <UserName displayNameStyle={'fullName'} withLink={false} />
            </UserContainer>
            {i + 1 === successUserIds.length ? null : i + 1 === successUserIds.length - 1 ? (
              <span style={{ margin: '0 4px' }}>and</span>
            ) : (
              <span style={{ marginRight: '4px' }}>,</span>
            )}
          </span>
        ))}
      </div>
    );
  };

  _createErrorComponentForFailedAsks = (failedAsks) => {
    return (
      <div className={''}>
        <div className={''}>Was not able to send ask to the following:</div>
        {failedAsks.map((ask, i) => (
          <div key={`sent-ask-failed-to-${ask.userId}`} className={'ask-sent-failure-message-container'}>
            <span>
              {!ask.userId ? (
                <div>
                  <span>Community Thought Leader</span>
                </div>
              ) : (
                <UserContainer userId={ask.userId}>
                  <UserName displayNameStyle={'fullName'} withLink={false} />
                </UserContainer>
              )}
            </span>
            <span>
              &#32;
              {` (${ask.error})`}
            </span>
          </div>
        ))}
      </div>
    );
  };

  _generateErrorMessageFromAskResponse = (response, askData) => {
    if (!response || !response.data || !response.data.asks || !Array.isArray(response.data.asks)) {
      return <p>{'Could not send ask. Please try again later.'}</p>;
    }
    const askReferenceData = Array.isArray(askData) ? askData : [askData];
    const responseAskData = response.data.asks;
    const responseAskDataWithFormData = responseAskData.map((ask, i) => ({
      ...ask,
      ...askReferenceData[i],
    }));

    const wasPartialSuccess = responseAskData.some((ask) => !ask.error);

    const successUserIds = this._returnSuccessAsksUserIdsFromResponse(response, askData);
    const failedAsks = responseAskDataWithFormData.filter((ask) => ask.error);

    if (wasPartialSuccess) {
      return (
        <div className={''}>
          {this._createErrorComponentForPartialSuccessAsks(successUserIds)}
          {this._createErrorComponentForFailedAsks(failedAsks)}
        </div>
      );
    } else {
      return <div className={''}>{this._createErrorComponentForFailedAsks(failedAsks)}</div>;
    }
  };

  _createAndShowErrorMessage = (errorMessageComponent) => {
    const generalComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {errorMessageComponent}
      </div>
    );

    const modal = {
      contentComponent: generalComponent,
      dismissable: true,
      size: 'wide',
    };
    this.props.actions.showModal(modal);
  };

  _createFormErrorMessage = (message) => {
    this.setState(() => ({
      _formErrorMessage: message,
    }));
  };

  _clearFormErrorMessage = (message) => {
    this.setState(() => ({
      _formErrorMessage: message,
    }));
  };

  _revalidateForm = () => {
    if (this._isFormError()) {
      if (this._checkForNoUsersInToFieldError()) {
        return false;
      }
      this._clearFormErrorMessage();
    }
  };

  _checkForNoUsersInToFieldError = () => {
    const errorMessage = 'Please add people to send to';
    if (this._returnUserIdsToAskLength() === 0) {
      if (this._returnFormErrorMessage() !== errorMessage) {
        this._createFormErrorMessage(errorMessage);
      }
      return true;
    }
  };

  _isFormInvalid = () => {
    if (this._checkForNoUsersInToFieldError()) {
      return true;
    }
  };

  _addToUserId = (userIds) => {
    const userIdsToAdd = Array.isArray(userIds) ? userIds : [userIds];
    this.setState((prevState) => ({
      _userIds: [...prevState._userIds, ...userIdsToAdd],
    }));
  };

  _removeToUserId = (userId) => {
    this.setState((prevState) => ({
      _userIds: prevState._userIds.filter((id) => id !== userId),
    }));
  };

  _removeToUserIds = (userIds) => {
    this.setState((prevState) => ({
      _userIds: prevState._userIds.filter((id) => !userIds.includes(id)),
    }));
  };

  _removeLastAddedUser = () => {
    const lastAddedUserId = this._returnLastAddedUserId();
    if (lastAddedUserId) {
      this._removeToUserId(lastAddedUserId);
    }
  };

  _returnCustomKeyPressHandlerForSearch = () => {
    return {
      8: (currentQuery) => {
        if (currentQuery === '') {
          this._removeLastAddedUser();
        }
      },
    };
  };

  _setUserSearchValue = (value) => {
    this.setState(() => ({
      _userSearchText: value,
    }));
  };

  _setQuestionValue = (value) => {
    this.setState(() => ({
      _questionText: value,
    }));
  };

  _returnFirstNameFromUserId = (userId) => {
    const user = this._returnUserFromUserId(userId);
    if (!user) {
      return null;
    }
    return userFirstName(user);
  };

  _returnUserFromUserId = (userId) => this._returnUsersLookup()[userId];

  _returnUsersLookup = () => this.props.users.userList;

  _returnUsersStore = () => this.props.users;

  _returnCurrentUser = () => this.props.currentUser;

  _returnCurrentUserId = () => this._returnCurrentUser().user_id;

  _returnUserConnectionsStore = () => this.props.userConnections;

  _setDefaultQuestionCopy = () => {
    this.setState(() => ({
      _questionText: this._returnDefaultQuestion(),
    }));
  };

  _focusTextAreaField = () => this._$getQuestionFieldTextAreaDOMNode().focus();

  _getContainerDOMNode = () => this.AskAnyoneFormContainer;

  _$getContainerDOMNode = () => window.$(this._getContainerDOMNode());

  _$getQuestionFieldTextAreaDOMNode = () => this._$getContainerDOMNode().find('.question-field textarea');

  _handleFindConnectionsClick = () => {
    this._handleClose();
    this._logFindConnectionsClick();
  };

  _logFindConnectionsClick = () => {
    const event = 'Clicked Find Connections';
    const properties = {
      Context: 'Ask Someone Form',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logAddUser = (userId) => {
    const event = 'Added User To Ask Anyone Form';
    const properties = {
      'Is Community Thought Leader': userId === CTL_ID,
      'User ID': userId,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logCancelAskWhyForm = () => {
    const event = 'Cancelled Ask Why Form';
    const properties = {};
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logAskWhySent = () => {
    const event = 'Clicked Like Idea';
    const properties = {
      'Is Community Thought Leader Ask': this._returnUserIdsToAsk().some((id) => id === CTL_ID),
      'Stock Symbol': this._returnSecuritySymbol(),
      'Security ID': this._returnSecurityId(),
      ...(this.props.viewingContext ? { Context: this.props.viewingContext } : {}),
      'Is Ask CTL': this.props.defaultAskingCTL,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logViewAskCTLWarning = (warningText) => {
    const event = 'Viewed Ask CTL Warning';
    const properties = {
      Message: warningText,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logNetworkShareClick = (network) => {
    TrackingEvents.referral.USE_REFERRAL_ACTION.send(REFERRAL_TYPES.askShare, { Network: network });

    const event = 'Clicked Share On Social Network';
    const properties = {
      Network: network,
      'Stock Symbol': this._returnSecuritySymbol(),
      'Security ID': this._returnSecurityId(),
      ...(this.props.viewingContext ? { Context: this.props.viewingContext } : {}),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
    saveImpressionToStorageIfUnrecorded('share', this._returnThoughtId(), this.props.viewingContext);
    this.props.actions.sendThoughtClickEvent(
      this._returnThoughtId(),
      'share',
      this.props.thoughtContext,
      this.props.thoughtMetadata,
      this.props.notificationIndex
    );
  };

  _logThoughtAskImpression = () => {
    saveImpressionToStorageIfUnrecorded('share', this._returnThoughtId(), this.props.viewingContext);
    this.props.actions.sendThoughtClickEvent(
      this._returnThoughtId(),
      'share',
      this.props.thoughtContext,
      this.props.thoughtMetadata,
      this.props.notificationIndex
    );
  };
}

const mapStateToProps = (state) => {
  return {
    ask: state.ask,
    currentUser: state.currentUser,
    users: state.users,
    userConnections: state.userConnections,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AskAnyoneForm);
