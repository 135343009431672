import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../../../actions/index';

import LinkTo from '../../../Links/LinkTo';

import { truncateName, snakeToHyphens, isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import { findUserIdeaForSecurity } from '../../../../helpers/ideaHelpers';
import { formatSecurityDataValue, formatDataValue } from '../../../../helpers/securitiesHelpers';

import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';

export class SecurityAllocationCell extends Component {
  render() {
    const columnKeyName = this.props.column.keyName;
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? (
          this.props.loadingCellComponent
        ) : (
          <LinkTo
            to={this._returnLinkForHandlingClick()}
            className={''}
            additionalClickAction={this.props.logSecurityPanelOpenClick}
            child={this._renderCellContent()}
            noTextDecoration
          />
        )}
      </td>
    );
  }

  _isLoading = () => {
    const { userId, securityId } = this.props;
    const security = this.props.securities.lookup[securityId];

    const userPortfolioLookup = this.props.portfolio.userPortfolioDict;
    const userPortfolioData = userPortfolioLookup && userPortfolioLookup[userId];
    const portfolioData = userPortfolioData && userPortfolioData.securities;

    const currentUserId = this.props.currentUser.user_id;
    const ideaUserId = userId || currentUserId;

    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);

    return (!portfolioData && !idea) || !('symbol' in security) || !('name' in security);
  };

  _returnLinkForHandlingClick = () => {
    const { userId, securityId } = this.props;
    const currentUserId = this.props.currentUser.user_id;
    const ideaUserId = userId || currentUserId;

    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    const ideaId = idea && idea.idea_id;

    const additionalProps = {
      activeTab: 'ideas',
      ideaId,
    };
    const openSecurityPanelConfig = {
      securityId,
      additionalProps,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    const { userId, securityId } = this.props;
    const userPortfolioLookup = this.props.portfolio.userPortfolioDict;
    const userPortfolioData = userPortfolioLookup && userPortfolioLookup[userId];
    const portfolioData = userPortfolioData && userPortfolioData.securities;
    const securityAllocationData = portfolioData && portfolioData[securityId];

    const currentUserId = this.props.currentUser.user_id;
    const ideaUserId = userId || currentUserId;

    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);

    const allocationPercent = securityAllocationData
      ? securityAllocationData.allocation_percent
      : idea
      ? idea.allocation || 0
      : 0;

    return (
      <span className={`security-table-allocation-container`}>
        <span className={`security-table-allocation-container`}>
          <span className={`security-table-allocation-total-value  `}>
            {formatDataValue(allocationPercent, 'percentage')}
          </span>
        </span>
      </span>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    ideas: state.ideas,
    portfolio: state.portfolio,
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(SecurityAllocationCell);

export default composedComponent;
