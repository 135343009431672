import React from 'react';
import Icon from '../../components/misc/Icon';

export const Email = (props) => {
  const returnTo = () => props.to || '';
  const returnSubject = () => props.subject || '';
  const returnBody = () => props.body || '';
  const returnLink = () => `mailto:${returnTo()}?subject=${returnSubject()}&body=${returnBody()}`;
  const handleClick = () => {
    if (props.additionalActionOnClick) {
      props.additionalActionOnClick();
    }
  };

  return (
    <div className={`social-button email`}>
      <a id="email_share" className={`${props.className || ''}`} href={returnLink()} onClick={handleClick}>
        <Icon icon={'fa fa-envelope'} />
      </a>
    </div>
  );
};
