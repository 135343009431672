import { BASEURL } from './index';
import { convertValuesFromNotation } from '../helpers/securitiesHelpers';
import { getIdKey } from '../helpers/generalHelpers';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;
const FILTER_URL = `${URL}/filter_securities`;

export const portfolioExplorerAdapter = {
  getThoughtLeaders: () => {
    return sendRequest('get', [`${URL}/thought_leaders`]).then((response) => response.data);
  },

  getSectors: () => {
    return sendRequest('get', [`${URL}/sectors`]).then((response) => response.data);
  },

  filterSecurities: (count, filters) => {
    const serializeFilters = filters
      .map((filter) =>
        'value' in filter
          ? {
              ...filter,
              value: convertValuesFromNotation(filter.name, filter.value),
            }
          : filter
      )
      .map((filter) => ('ids' in filter ? { ...filter, ids: filter.ids.map((f) => f[getIdKey(f)]) } : filter));
    return sendRequest('post', [FILTER_URL, { count, filters: serializeFilters }]).then((response) =>
      response && response.data && 'securities' in response.data ? response.data.securities : []
    );
  },
};
