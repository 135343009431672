import React from 'react';
import { useSelector } from 'react-redux';

export const usePlacedOrders = () => {
  const orders = useSelector((state) => state.u_orders.placed_orders);
  return orders;
};

export const useOrdersAwaitingApproval = () => {
  const orders = useSelector((state) => state.u_orders.awaiting_approval_orders);
  return orders;
};
