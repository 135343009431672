import React from 'react';

import LoadingIcon from '../components/misc/LoadingIcon';
import { returnPathTo } from '../constants/paths';
import { createBasicErrorModal } from '../constants/modals';

import { objectMap } from './generalHelpers';
import { customParseFloat } from './numberHelpers';
import { throwError } from './devToolHelpers';

export const getSecurityIdFromIdea = (idea) => {
  if (!idea) return null;

  return idea?.security?.security_id;
};
export const getSecuritySymbolFromIdea = (idea) => {
  if (!idea) return null;

  return idea?.security?.symbol;
};
export const getSecurityNameFromIdea = (idea) => {
  if (!idea) return null;

  return idea?.security?.name;
};

export const formatIdeasToSendToAPI = (ideas) => {
  return ideas.map((idea) => ({
    ...idea,
    security_id: getSecurityIdFromIdea(idea),
  }));
};

export const isIdeaABuy = (idea) => {
  if (!idea) return null;
  return idea?.idea_type?.id === 0;
};

export const isIdeaASell = (idea) => {
  if (!idea) return null;
  return idea?.idea_type?.id === 1;
};

export const findIdea = function (ideaId, ideasStore) {
  if (!ideaId) {
    return null;
  }

  if (!ideasStore || !('ideaList' in ideasStore)) {
    return null;
  }

  const idea = ideasStore.ideaList[ideaId] || null;

  if (!idea) {
    return null;
  }

  return idea;
};

export const findUserIdeaForSecurity = (userId, securityId, ideaList) => {
  if (!userId) {
    console.error('Must provide userId to findUserIdeaForSecurity');
    return null;
  }
  if (!securityId) {
    console.error('Must provide securityId to findUserIdeaForSecurity');
    return null;
  }
  if (!ideaList) {
    console.error('Must provide ideaList to findUserIdeaForSecurity');
    return null;
  }

  const ideaKeys = Object.keys(ideaList);
  for (let i = 0; i < ideaKeys.length; i++) {
    let ideaId = ideaKeys[i];
    let idea = ideaList[ideaId];
    let ideaUserId = idea.user_id ? idea.user_id.toString() : null;
    let ideaSecurityId = getSecurityIdFromIdea(idea) ? getSecurityIdFromIdea(idea).toString() : null;
    if (ideaUserId === userId.toString() && ideaSecurityId === securityId.toString()) {
      return idea;
    }
  }
  return null;
};

export const getUserIdeasLookupBySecurityId = (userId, ideaList) => {
  const ideaKeys = Object.keys(ideaList);
  const ideaLookup = {};

  for (let i = 0; i < ideaKeys.length; i++) {
    let ideaId = ideaKeys[i];
    let idea = ideaList[ideaId];
    if (idea.user_id === userId) {
      const sId = getSecurityIdFromIdea(idea);
      ideaLookup[sId] = idea;
    }
  }
  return ideaLookup;
};

export const findUserIdeas = (userId, ideaList) => {
  const ideaKeys = Object.keys(ideaList);
  const userIdeas = [];

  for (let i = 0; i < ideaKeys.length; i++) {
    let ideaId = ideaKeys[i];
    let idea = ideaList[ideaId];
    if (idea.user_id === userId) {
      userIdeas.push(idea);
    }
  }
  return userIdeas;
};

export const findIdeasForSecurity = (security, ideas) => {
  const ideaKeys = Object.keys(ideas);
  const securityIdeasIds = ideaKeys.filter(
    (i) => getSecurityIdFromIdea(ideas[i]).toString() === security.security_id.toString()
  );
  return securityIdeasIds.map((id) => ideas[id]);
};

export const findIdeasForSecurityId = (securityId, ideas) => {
  const ideaKeys = Object.keys(ideas);
  const securityIdeasIds = ideaKeys.filter((i) => getSecurityIdFromIdea(ideas[i]).toString() === securityId.toString());
  return securityIdeasIds.map((id) => ideas[id]);
};

export const separateIdeasByLongAndShort = (ideas) => {
  const long = ideas.filter((idea) => idea.idea_type.id === 0);
  const short = ideas.filter((idea) => idea.idea_type.id === 1);
  return { long, short };
};

export const doesUserHaveIdeas = (userId, ideasStore) => {
  const ideas = findUserIdeas(userId, ideasStore.ideaList);
  return ideas && ideas.length > 0;
};

export const userHasIdea = (userId, securityId, ideas) => {
  const ideaKeys = Object.keys(ideas);
  const securityIdeasIds = ideaKeys.filter((i) => getSecurityIdFromIdea(ideas[i]).toString() === securityId.toString());
  const ideasForSecurity = securityIdeasIds.map((id) => ideas[id]);
  return ideasForSecurity.filter((idea) => idea.user_id === userId).length > 0;
};

export const userHasIdeaForSecurity = (userId, securityId, ideas) => {
  const ideaKeys = Object.keys(ideas);
  const securityIdeasIds = ideaKeys.filter((i) => getSecurityIdFromIdea(ideas[i]).toString() === securityId.toString());
  const ideasForSecurity = securityIdeasIds.map((id) => ideas[id]);
  return ideasForSecurity.filter((idea) => idea.user_id === userId).length > 0;
};

export const convictionConversion = (propertyObj) => {
  const tableById = {
    0: { id: 0, name: 'Very Low' },
    1: { id: 1, name: 'Low' },
    2: { id: 2, name: 'Medium' },
    3: { id: 3, name: 'High' },
    4: { id: 4, name: 'Very High' },
  };

  const tableByName = {
    'Very Low': 0,
    Low: 1,
    Medium: 2,
    High: 3,
    'Very High': 4,
  };

  if ('id' in propertyObj) {
    return tableById[propertyObj.id];
  }
  if ('name' in propertyObj) {
    return tableByName[propertyObj.name];
  }
};

export const serializeIdea = (idea, action) => {
  const fieldConversions = {};
  const valueConversions = {
    active: function (value) {
      return action === 'create' ? true : value;
    },
    price_target: function (value) {
      return typeof value === 'string' ? value.split(',').join('') : value;
    },
    expected_return: function (value) {
      return typeof value === 'string' ? value.split(',').join('') : value;
    },
    expected_dividend: function (value) {
      return typeof value === 'string' ? value.split(',').join('') : value;
    },
    using_price_target: function (value) {
      return !value;
    },
  };
  const omitFieldIf = {
    expected_return: function (idea) {
      return 'price_target' in idea && idea.price_target !== null && idea.price_target >= 0;
    },
  };
  const convertKeyNames = (k, v) => {
    let value = v;
    if (valueConversions[k]) {
      value = valueConversions[k](v);
    }
    return { [fieldConversions[k] || k]: value };
  };
  const omitKeys = (k, v) => {
    if (omitFieldIf[k]) {
      if (omitFieldIf[k](idea)) {
        return false;
      }
    }
    return { k, v };
  };
  const convertedKeys = objectMap(idea, convertKeyNames);
  return objectMap(idea, convertKeyNames);
};

export const isIdeaUsingPriceTarget = (idea) => {
  return 'price_target' in idea && idea.price_target !== null && idea.price_target !== undefined;
};

export const isThoughtLeaderIdea = (idea) => {
  return (
    (!('price_target' in idea) && !('expected_return' in idea)) ||
    (idea.price_target === null && idea.expected_return === null)
  );
};

export const findIdeaBySecurityId = (securityId, ideas) => {
  return ideas.filter((idea) => getSecurityIdFromIdea(idea).toString() === securityId.toString())[0];
};

export const calculateRawExpectedReturnFromPriceTarget = (idea, security, formData, currentPrice) => {
  const priceTarget = formData && formData.price_target ? formData.price_target : idea.price_target;
  if (currentPrice === 0) {
    return '';
  }
  const rawExpectedReturn = ((customParseFloat(priceTarget) - currentPrice) / currentPrice) * 100;
  return rawExpectedReturn;
};

export const calculateExpectedReturn = (args) => {
  const { idea, security, formData, currentPrice } = args;
  if (!('idea' in args) || !('security' in args) || !('currentPrice' in args)) {
    console.error('Idea and security are required to calc expected return');
  }
  if (!('currentPrice' in args)) {
    console.error('Current price is required to calc expected return');
  }
  const ideaUsingPriceTarget =
    args.ideaUsingPriceTarget === undefined ? isIdeaUsingPriceTarget(idea) : args.ideaUsingPriceTarget;
  const horizonInYears = idea.horizon.id === 1 ? 1 : 0.5;
  const expectedDividend = customParseFloat(idea.expected_dividend);

  if (currentPrice === 0 && ideaUsingPriceTarget) {
    return '';
  }
  const rawExpectedReturn = ideaUsingPriceTarget
    ? calculateRawExpectedReturnFromPriceTarget(idea, security, formData, currentPrice)
    : customParseFloat(idea.expected_return);
  return rawExpectedReturn + expectedDividend * horizonInYears;
};

export const handleExitPosition = function () {
  if (!this.props.actions) {
    console.error("Must have actions in calling class' props");
  }
  if (!this.props.currentUser) {
    console.error("Must have current user in calling class' props");
  }
  if (!this.props.security) {
    console.error("Must have a security in calling class' props");
  }
  if (!this.props.idea) {
    console.error("Must have an idea in calling class' props");
  }
  if (!this.props.navigate) {
    console.error('Must have navigate prop to handle navigation');
  }

  const { security, idea, currentUser } = this.props;
  const positions = currentUser.positions;
  const userPosition = positions[security.security_id];
  const positionType =
    userPosition.shares < 0
      ? 'Buy'
      : userPosition.shares > 0
      ? 'Sell'
      : console.error("Error calculating user's total shares");
  if (typeof positionType !== 'string') {
    return false;
  }

  const event = 'Choose Exit Position In Idea';
  const properties = {
    'Idea Id': idea.idea_id,
    'Stock Symbol': security.symbol,
    'Security ID': security.security_id,
    Context: 'Security Table',
  };
  this.props.actions.logMetricsTrackingEvent(event, properties);

  const positionSize = userPosition.shares;
  const operation = positionType.toLowerCase();
  const shares = Math.abs(positionSize);
  const tradeObj = {
    security_id: security.security_id,
    operation,
    shares,
    type: 'market',
    is_from_optimizer: false,
  };
  const options = {
    clearOrdersBefore: true,
  };
  const orders = [tradeObj];

  const component = (
    <div className={`creating-optimized-orders component-bg`} style={{ maxWidth: '320px ' }}>
      <LoadingIcon size="medium" />
      <div style={{ marginTop: '4px', marginLeft: '8px', fontSize: '24px' }}>{'Creating Orders'}</div>
    </div>
  );
  const modal = {
    contentComponent: component,
    dismissable: false,
    size: 'wide',
  };
  this.props.actions.showModal(modal);

  this.props.actions.createOrders(orders, options).then((response) => {
    const ajaxWasSuccessful = response && response.data && !response.data.error;
    this.props.actions.hideModal();
    if (ajaxWasSuccessful) {
      this.props.navigate(returnPathTo('orders'));
    } else {
      const modalMessage = 'Could not create order. Please try again later.';
      const modal = {
        contentComponent: createBasicErrorModal(modalMessage),
        dismissable: true,
      };
      this.props.actions.showModal(modal);
    }
  });
};

export const returnIdeaListFromIdeaNotifications = (ideaNotifications) => {
  const ideas = [];
  ideaNotifications.forEach((ideaNotif) => {
    const ideaNotifIdeas = ideaNotif.ideas;
    ideaNotifIdeas.forEach((idea) => ideas.push(idea));
  });
  return ideas;
};

/* NEW HELPER LIST USE THESE OVER ANY ABOVE */

export const returnIdeaLookupFromIdeaStore = (ideaStore) => {
  if (!('ideaList' in ideaStore)) {
    return throwError('Invalid idea store passed into getIdeaListFromIdeaStore', true, { ideaStore });
  }
  return ideaStore.ideaList;
};

export const returnIdeaFromIdeaStore = (ideaId, ideaStore) => {
  return returnIdeaLookupFromIdeaStore(ideaStore)[ideaId];
};

export const isIdeaDataInIdeaStore = (ideaId, ideaStore) => {
  return !!returnIdeaFromIdeaStore(ideaId, ideaStore);
};

export const wasSecurityIdeasLoadingUpdate = (prevIdeaStore, nextIdeaStore) => {
  const loadingSecurityIdeas =
    prevIdeaStore.loadingIdeasForSecurityIdsList != nextIdeaStore.loadingIdeasForSecurityIdsList;
  return loadingSecurityIdeas;
};

export const wasUserIdeasLoadingUpdate = (prevIdeaStore, nextIdeaStore) => {
  const loadingUserIdeas = prevIdeaStore.loadingIdeasForUserIdsList != nextIdeaStore.loadingIdeasForUserIdsList;
  return loadingUserIdeas;
};

export const returnSecurityIdeasLoadingUpdateKeys = (prevIdeaStore, nextIdeaStore) => {
  const keys = [];
  prevIdeaStore.loadingIdeasForSecurityIdsList.forEach((id) => {
    if (!nextIdeaStore.loadingIdeasForSecurityIdsList.includes(id)) {
      keys.push(id);
    }
  });
  nextIdeaStore.loadingIdeasForSecurityIdsList.forEach((id) => {
    if (!prevIdeaStore.loadingIdeasForSecurityIdsList.includes(id)) {
      keys.push(id);
    }
  });
  return keys;
};

export const returnUserIdeasLoadingUpdateKeys = (prevIdeaStore, nextIdeaStore) => {
  const keys = [];
  prevIdeaStore.loadingIdeasForUserIdsList.forEach((id) => {
    if (!nextIdeaStore.loadingIdeasForUserIdsList.includes(id)) {
      keys.push(id);
    }
  });
  nextIdeaStore.loadingIdeasForUserIdsList.forEach((id) => {
    if (!prevIdeaStore.loadingIdeasForUserIdsList.includes(id)) {
      keys.push(id);
    }
  });
  return keys;
};

export const isLoadingIdeaDataForSecurity = (securityId, ideaStore) => {
  if (!('ideaList' in ideaStore)) {
    return throwError('Invalid idea store passed into isLoadingIdeaDataForSecurity', true, { ideaStore });
  }
  const loadingIdsList = ideaStore.loadingIdeasForSecurityIdsList;
  return loadingIdsList.indexOf(securityId) >= 0;
};

export const wasLoadingIdeaDataUpdateForSecurity = (id, prevIdeaStore, nextIdeaStore) => {
  if (!('ideaList' in prevIdeaStore) || !('ideaList' in nextIdeaStore)) {
    return throwError('Invalid idea store passed into wasLoadingIdeaDataUpdateForSecurity', true, {
      prevIdeaStore,
      nextIdeaStore,
    });
  }
  const prevLoadingIdsList = prevIdeaStore.loadingIdeasForSecurityIdsList.indexOf(id) >= 0;
  const nextLoadingIdsList = nextIdeaStore.loadingIdeasForSecurityIdsList.indexOf(id) >= 0;
  return prevLoadingIdsList || nextLoadingIdsList;
};

export const wasLoadingIdeaDataUpdateForUser = (id, prevIdeaStore, nextIdeaStore) => {
  if (!('ideaList' in prevIdeaStore) || !('ideaList' in nextIdeaStore)) {
    return throwError('Invalid idea store passed into wasLoadingIdeaDataUpdateForUser', true, {
      prevIdeaStore,
      nextIdeaStore,
    });
  }
  const prevLoadingIdsList = prevIdeaStore.loadingIdeasForUserIdsList.indexOf(id) >= 0;
  const nextLoadingIdsList = nextIdeaStore.loadingIdeasForUserIdsList.indexOf(id) >= 0;
  return prevLoadingIdsList || nextLoadingIdsList;
};

export const isLoadingIdeaDataForUser = (id, ideaStore) => {
  if (!('ideaList' in ideaStore)) {
    return throwError('Invalid idea store passed into isLoadingIdeaDataForUser', true, { ideaStore });
  }
  const loadingIdsList = ideaStore.loadingIdeasForUserIdsList;
  return loadingIdsList.indexOf(id) >= 0;
};

export const isLoadingIdeaDataForIdea = (id, ideaStore, idea) => {
  if (!('ideaList' in ideaStore)) {
    return throwError('Invalid idea store passed into isLoadingIdeaDataForIdea', true, { ideaStore });
  }
  let loadingThruSecurityOrUser = false;
  if (idea) {
    loadingThruSecurityOrUser =
      ideaStore.loadingIdeasForSecurityIdsList.includes(getSecurityIdFromIdea(idea)) ||
      ideaStore.loadingIdeasForUserIdsList.includes(idea.user_id);
  }
  return (
    (isIdeaDataInIdeaStore(id, ideaStore) && (returnIdeaFromIdeaStore(id, ideaStore).loading || false)) ||
    loadingThruSecurityOrUser
  );
};

export const isLoadingIdeaData = (type, id, ideaStore, idea) => {
  if (!('ideaList' in ideaStore)) {
    return throwError('Invalid idea store passed into isLoadingIdeaData', true, { ideaStore });
  }
  if (type === 'security') {
    return isLoadingIdeaDataForSecurity(id, ideaStore);
  }
  if (type === 'user') {
    return isLoadingIdeaDataForUser(id, ideaStore);
  }
  if (type === 'idea') {
    return isLoadingIdeaDataForIdea(id, ideaStore, idea);
  }
  return throwError('Invalid idea source type passed into isLoadingIdeaData', true, { type, id });
};

export const didIdeaLoadingStateChange = (idea, prevIdeaStore, nextIdeaStore) => {
  if (!idea) {
    return false;
  }
  const securityId = getSecurityIdFromIdea(idea);
  const userId = idea.user_id;
  const isIdeaLoadingThruSecurity =
    (prevIdeaStore.loadingIdeasForSecurityIdsList.includes(securityId) &&
      !nextIdeaStore.loadingIdeasForSecurityIdsList.includes(securityId)) ||
    (!prevIdeaStore.loadingIdeasForSecurityIdsList.includes(securityId) &&
      nextIdeaStore.loadingIdeasForSecurityIdsList.includes(securityId));
  const isIdeaLoadingThruUser =
    (prevIdeaStore.loadingIdeasForUserIdsList.includes(userId) &&
      !nextIdeaStore.loadingIdeasForUserIdsList.includes(userId)) ||
    (!prevIdeaStore.loadingIdeasForUserIdsList.includes(userId) &&
      nextIdeaStore.loadingIdeasForUserIdsList.includes(userId));
  return isIdeaLoadingThruSecurity || isIdeaLoadingThruUser;
};

export const returnAllIdeaIdsInStore = (ideaStore) => {
  return Object.keys(returnIdeaLookupFromIdeaStore(ideaStore));
};

export const returnAllIdeasInStore = (ideaStore) => {
  const ideaLookup = returnIdeaLookupFromIdeaStore(ideaStore);
  const ideaIds = returnAllIdeaIdsInStore(ideaStore);
  const ideas = [];
  for (let i = 0; i < ideaIds.length; i++) {
    const ideaId = ideaIds[i];
    const idea = ideaLookup[ideaId];
    ideas.push(idea);
  }
  return ideas;
};
