import { applicationAdapter } from '../adapters/applicationAdapter';
import {
  ADD_DATA_TYPES,
  SHOW_MODAL,
  SET_SESSION_RISK_ID,
  ADD_SECURITIES_DESCRIPTIONS,
  UPDATE_EMAIL_INVITE_COPY,
  UPDATE_STATIC_COPY,
  marketMonitorActionTypes,
  SET_MARKET_HOURS_DATA,
  SET_MARKET_STATE,
  applicationActionTypes,
} from '../constants';
import { returnBasicSomethingWentWrongErrorModalPayload } from '../constants/modals';
import { getSecurityIdsAndSymbolsFromSecurities } from '../helpers/securitiesHelpers';

export const showComponentModal = (d) => {
  return function(dispatch) {
    dispatch({
      type: applicationActionTypes.showComponentModal,
      payload: d,
    });
  };
};

export const hideComponentModal = () => {
  return function(dispatch) {
    dispatch({
      type: applicationActionTypes.hideComponentModal,
    });
  };
};

export const fetchDataTypes = (field) => {
  return function(dispatch) {
    return applicationAdapter.fetchDataTypes(field).then((response) => {
      const key = `${field}_types`;
      if (response && response[key]) {
        dispatch({
          type: ADD_DATA_TYPES,
          payload: { [key]: response[key] },
        });

        return { [key]: response[key] };
      } else {
        dispatch({
          type: SHOW_MODAL,
          payload: returnBasicSomethingWentWrongErrorModalPayload(),
        });
        return {};
      }
    });
  };
};

export const setMarketState = (s) => {
  return function(dispatch) {
    dispatch({
      type: SET_MARKET_STATE,
      payload: s,
    });
  };
};
export const addMarketMonitorSecurities = (s) => {
  return function(dispatch) {
    dispatch({
      type: marketMonitorActionTypes.addSecurity,
      payload: s,
    });
  };
};

export const fetchAboveFeedBannerData = (bannerType) => {
  return function(dispatch) {
    return applicationAdapter.fetchAboveFeedBannerData({ bannerType, dispatch }).then((response) => {
      const { status, data } = response;

      if (status === 200 && data && data.securities) {
        const { securities } = data;

        dispatch({
          type: ADD_SECURITIES_DESCRIPTIONS,
          payload: securities,
        });

        const { securityIds, securitySymbols } = getSecurityIdsAndSymbolsFromSecurities(securities);
        const dataNeeded = {
          securityIds,
          securitySymbols,
        };
        return {
          ok: true,
          data: dataNeeded,
        };
      } else {
        if (!data) {
          return {
            ok: false,
            message: 'Something went wrong. Please try again.',
          };
        }

        const { error_message: message } = data;
        return {
          ok: false,
          message,
        };
      }
    });
  };
};

export const getIsMarketOpen = () => {
  return function(dispatch) {
    return applicationAdapter.getIsMarketOpen().then((response) => {
      if (!response) {
        return { ok: false };
      }
      const { data, status } = response;
      if (status === 200 && data.prev_market_close_timestamp) {
        const { prev_market_close_timestamp, next_market_open_timestamp, next_market_close_timestamp } = data;
        dispatch({
          type: SET_MARKET_HOURS_DATA,
          payload: {
            prev_market_close_timestamp: prev_market_close_timestamp * 1000,
            next_market_open_timestamp: next_market_open_timestamp * 1000,
            next_market_close_timestamp: next_market_close_timestamp * 1000,
          },
        });
        return {
          ok: true,
          ...data,
        };
      } else {
        return { ok: false };
      }
    });
  };
};

export const setSessionRiskId = (value) => {
  return function(dispatch) {
    dispatch({
      type: SET_SESSION_RISK_ID,
      payload: value,
    });
    return null;
  };
};

export const registerAttributionIdentifiers = (attributionIdentifiers) => {
  return applicationAdapter.registerAttributionIdentifiers(attributionIdentifiers).then((response) => {
    const { status } = response;
    if (status === 200) {
      return {
        ok: true,
      };
    } else {
      return {
        ignoreError: true,
      };
    }
  });
};

export const fetchEmailInviteCopy = () => {
  return function(dispatch) {
    return applicationAdapter.fetchEmailInviteCopy({ dispatch }).then((response) => {
      const { status, data } = response;
      if (status === 200) {
        dispatch({
          type: UPDATE_EMAIL_INVITE_COPY,
          payload: data.invite_email_text,
        });
        return {
          ok: true,
        };
      } else {
        return {
          ignoreError: true,
        };
      }
    });
  };
};

export const getStaticCopy = () => {
  return function(dispatch) {
    return applicationAdapter.getStaticCopy({ dispatch }).then((response) => {
      const { status, data } = response;
      if (status === 200) {
        dispatch({
          type: UPDATE_STATIC_COPY,
          payload: data,
        });
        return {
          ok: true,
        };
      } else {
        return {
          error: true,
        };
      }
    });
  };
};
