import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as Actions from '../../../../actions/index';

import { getCurrentUserFromState } from '../../../../selectors/currentUser';
import { shouldUseStreamingPrices } from '../../../../helpers/currentUserHelpers';

import LinkTo from '../../../Links/LinkTo';
import NumberWithChangeIndicator from '../../../../components/UI/NumberWithChangeIndicator';
import NumberChange from '../../../../components/animations/NumberChange';

import { snakeToHyphens } from '../../../../helpers/generalHelpers';
import { findUserIdeaForSecurity } from '../../../../helpers/ideaHelpers';
import { returnSecurityPanelUrl } from '../../../../helpers/securityPanelHelpers';
import ExternalPositionPerformanceData from '../../../SecurityData/ExternalPositionPerformanceData';
import { ExternalPositionsBasicCell } from './ExternalPositionsBasicCell';

export class ExternalPositionDayChangePercentCell extends ExternalPositionsBasicCell {
  _returnLinkForHandlingClick = () => {
    const { userId, securityId } = this.props;
    const currentUserId = this.props.currentUser.user_id;
    const ideaUserId = userId || currentUserId;

    const ideaList = this.props.ideas.ideaList;
    const idea = findUserIdeaForSecurity(ideaUserId, securityId, ideaList);
    const ideaId = idea && idea.idea_id;

    const additionalProps = {
      activeTab: 'ideas',
      ideaId,
    };
    const openSecurityPanelConfig = {
      securityId,
      additionalProps,
      navigate: this.props.navigate,
      location: this.props.location,
    };
    return returnSecurityPanelUrl(openSecurityPanelConfig);
  };

  _renderCellContent = () => {
    const { securityId, plaidSecurityId, column, useStreamingPrice, additionalTableCellProps } = this.props;
    const { onDashboard } = column;

    const plaid_account_id_filter =
      additionalTableCellProps &&
      additionalTableCellProps['external_positions'] &&
      additionalTableCellProps['external_positions']['plaid_account_id_filter'];

    return (
      <ExternalPositionPerformanceData
        securityId={securityId}
        showStreamingPrices={onDashboard}
        plaidSecurityId={plaidSecurityId}
        plaid_account_id_filter={plaid_account_id_filter}
      >
        {(shares, todaysProfitChange, todaysProfitChangePercent) => {
          return (
            <>
              <span className={`security-table-day-change-container`}>
                {shares !== 0 && (
                  <span className={`security-table-daily-price-change-container`}>
                    <span className={`security-table-daily-price-change-percent`}>
                      {useStreamingPrice ? (
                        <NumberChange value={todaysProfitChange} textColorTypeValue={todaysProfitChange}>
                          {(value, animationClass) => (
                            <NumberWithChangeIndicator
                              customClass={'portfolio-dashboard daily-change-price-value'}
                              valueFormat={'price'}
                              value={value}
                              additionalClasses={animationClass}
                              showPlus
                              coloredText
                              hideIcon
                            />
                          )}
                        </NumberChange>
                      ) : (
                        <NumberWithChangeIndicator
                          customClass={'portfolio-dashboard daily-change-price-value'}
                          valueFormat={'price'}
                          value={todaysProfitChange}
                          showPlus
                          coloredText
                          hideIcon
                        />
                      )}
                    </span>
                  </span>
                )}
                {shares !== 0 && (
                  <span className={`security-table-daily-price-change-container`}>
                    <span className={`security-table-daily-price-change-percent`}>
                      {useStreamingPrice ? (
                        <NumberChange value={todaysProfitChangePercent} textColorTypeValue={todaysProfitChange}>
                          {(value, animationClass) => (
                            <NumberWithChangeIndicator
                              customClass={'portfolio-dashboard daily-change-percentage-value'}
                              valueFormat={'percentageFloat'}
                              value={value}
                              additionalClasses={animationClass}
                              showPlus
                              coloredText
                              hideIcon
                            />
                          )}
                        </NumberChange>
                      ) : (
                        <NumberWithChangeIndicator
                          customClass={'portfolio-dashboard daily-change-percentage-value'}
                          valueFormat={'percentageFloat'}
                          value={todaysProfitChangePercent}
                          showPlus
                          coloredText
                          hideIcon
                        />
                      )}
                    </span>
                  </span>
                )}
              </span>
            </>
          );
        }}
      </ExternalPositionPerformanceData>
    );
  };
}

const mapStateToProps = (state) => {
  const currentUser = getCurrentUserFromState(state);
  return {
    currentUser,
    ideas: state.ideas,
    securities: state.securities,
    useStreamingPrice: shouldUseStreamingPrices(currentUser),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(ExternalPositionDayChangePercentCell);

export default composedComponent;
