import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components/layout';
import {
  Body5,
  H5,
  FloatingLabelTextInput,
  INPUT_MASK_TYPES,
  FloatingLabelDropdown,
  createDropdownItem,
} from '../../lib/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import { CheckCircleFilled } from '../../icons/svg/CheckCircleFilled';
import { createRecurringDeposit, getBankAccounts } from '../../../actions';
import { useDispatch } from 'react-redux';
import { customParseFloat } from '../../../helpers/numberHelpers';
import { Container } from '../../components/ui';
import { useBankAccounts } from '../../hooks/funding/useBankAccounts';
import { useColorTheme } from '../../hooks/util';
import { SelectBankAccount } from '../../components/funding/SelectBankAccount';
import { LinkBankButton } from '../../containers/buttons';
import { FormState } from '../../components/form/FormState';
import InfoIcon from '../../../containers/UI/InfoIcon';
import { DepositAmountPicker } from '../../components/funding/DepositAmountPicker';
import { DISPLAY_FORMAT_TYPES, formatValueTo } from '../../utils/numbers';
import { TrackingEvents } from '@src/utils/tracking/events';
import { ELEMENT_COLOR_NAMES } from '@src/main/constants/elementColors';

const Content = styled.div`
  h3 {
    margin: 0;
  }
`;
const CheckIconWrapper = styled.div`
  svg {
    height: 36px !important;
    width: 36px !important;
  }
`;
const DepositPickerWrapper = styled.div`
  margin-left: -16px;
`;
const PrimaryCTAWrapper = styled.div`
  text-align: center;
`;
const InputWrapper = styled.div`
  position: relative;

  select {
    border-top: none;
    border-right: none;
    border-left: none;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }

  .sc-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .input-text-prefix {
    font-size: 28px;
    line-height: 30px;
    padding-top: 9px;
  }
  input {
    font-size: 28px;
    border: none;

    -webkit-appearance: auto;
    background: transparent !important;
    cursor: pointer;
  }
`;

const recurringOptionItems = [];

function ordinalSuffixOf(i) {
  let j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
}

const days = [];
// for (let i = 1; i <= 31; i++) {
for (let i = 1; i <= 28; i++) {
  days.push(i);
}
days.forEach((d) => {
  recurringOptionItems.push(createDropdownItem({ text: ordinalSuffixOf(d), value: d, disabled: false }));
});

export const CreateRecurringDeposits = () => {
  const navigate = useNavigate();
  const colorTheme = useColorTheme();
  const textColor = colorTheme[ELEMENT_COLOR_NAMES.primaryTextColor];

  const dispatch = useDispatch();
  const bankAccounts = useBankAccounts();

  const [bankAccountId, setBankAccountId] = React.useState(
    bankAccounts.length > 0 ? bankAccounts[0].account_id.toString() : ''
  );
  const [rawInputAmount, setRawInputAmount] = React.useState('');
  const [frequency, setFrequency] = React.useState(1);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [formError, setFormError] = React.useState(null);

  React.useEffect(
    () => {
      if (bankAccounts.length === 1) {
        const id = bankAccounts[0].account_id;
        if (bankAccountId === '') setBankAccountId(id.toString());
      }
    },
    [bankAccounts, bankAccountId]
  );

  const handleAmountChange = (n) => (v) => {
    if (isSubmitting) return;

    setRawInputAmount(v);
  };

  const handlePickerSelect = (v) => {
    if (isSubmitting) return;

    setRawInputAmount(formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, v).replace('$', ''));
  };

  const handleBankAccountChange = (n) => (v) => {
    if (isSubmitting) return;

    setBankAccountId(v);
  };

  const validateValues = () => {
    if (bankAccountId === '' || !bankAccountId) {
      setFormError('Please select a bank account to transfer funds to.');
      return false;
    }

    if (rawInputAmount === '' || isNaN(customParseFloat(rawInputAmount))) {
      setFormError('Please enter an amount.');
      return false;
    }

    if (customParseFloat(rawInputAmount) === 0) {
      setFormError('Please enter an amount greater than zero.');
      return false;
    }

    setFormError(null);
    return true;
  };

  const submitForm = async () => {
    // const nextMonthDate = createTimeInstance().add(1, 'month');
    // const startDate = createTimeInstance(`${nextMonthDate.month() + 1}/${frequency}/${nextMonthDate.year()}`);
    const recurringForm = {
      amount: rawInputAmount,
      day_of_the_month: frequency,
      // start_date: formatLocalizedDateTime('api2', startDate),
      account_id: bankAccountId,
    };
    return createRecurringDeposit(recurringForm)(dispatch);
  };

  const onFrequencyChange = () => (v) => {
    setFrequency(v);
  };
  const handleSubmitPress = async () => {
    if (!validateValues()) {
      return;
    }
    setFormError(null);
    setIsSubmitting(true);
    const { ok, errorMessage } = await submitForm();
    if (ok) await getBankAccounts()(dispatch);
    setIsSubmitting(false);
    if (ok) {
      TrackingEvents.funding.RECURRING_DEPOSIT_ADDED_SUCCESS.send({
        Amount: rawInputAmount,
      });
      setShowSuccess(true);
    } else {
      TrackingEvents.funding.RECURRING_DEPOSIT_ADDED_FAILURE.send({
        Reason: errorMessage,
      });
      setFormError(errorMessage);
    }
  };

  const onContinuePress = () => {
    navigate(-1);
  };

  if (showSuccess) {
    return (
      <Page width={'600px'}>
        <Content>
          <Container horizontal={16} flex={1} column>
            <Container centerAll top={24} row>
              <Container centerAll row right={16}>
                <CheckIconWrapper>
                  <CheckCircleFilled textColor />
                </CheckIconWrapper>
              </Container>
              <Container flex={1}>
                <H5>Recurring deposit scheduled successfully!</H5>
              </Container>
            </Container>
          </Container>
          <Container style={{ textAlign: 'center' }} horizontal={16} top={48} bottom={16}>
            <PrimaryCTAWrapper>
              <FlatButton onClick={onContinuePress} fullWidth>
                Continue
              </FlatButton>
            </PrimaryCTAWrapper>
          </Container>
        </Content>
      </Page>
    );
  }

  return (
    <Page width={'640px'}>
      <Content>
        <Container top={8} horizontal={16} flex={1} column>
          <Container flex={1} column>
            <H5>Schedule a deposit that will repeat every month</H5>
            <Container top={24}>
              <Container>
                <SelectBankAccount value={bankAccountId} error={null} onChange={handleBankAccountChange} />
              </Container>
              <Container row top={4}>
                <LinkBankButton transparent />
              </Container>

              <Container top={16} style={{ borderBottomWidth: 1, borderBottomColor: textColor }}>
                <Container bottom={2} row verticallyCenter>
                  <Body5 color={textColor}>
                    Day of the Month
                    <InfoIcon
                      word={'tooltip_recurring_deposit_day_selection'}
                      style={{ position: 'relative', right: '-7px', top: '1px' }}
                    />
                  </Body5>
                </Container>
                <Container>
                  <InputWrapper>
                    <FloatingLabelDropdown
                      placeholder={'Please select...'}
                      name={'frequency'}
                      label={null}
                      items={recurringOptionItems}
                      value={frequency}
                      onChange={onFrequencyChange}
                      error={null}
                    />
                  </InputWrapper>
                </Container>
              </Container>
              <Container top={28}>
                <InputWrapper>
                  <FloatingLabelTextInput
                    name={'amount'}
                    value={rawInputAmount}
                    prefix={'$'}
                    label={'Amount'}
                    onChange={handleAmountChange}
                    maskType={INPUT_MASK_TYPES.dollarAmount}
                    error={null}
                    forceLiftedState
                  />
                </InputWrapper>
              </Container>

              <Container>
                <DepositPickerWrapper>
                  <DepositAmountPicker
                    amounts={[5, 20, 50, 100]}
                    value={rawInputAmount}
                    onSelect={handlePickerSelect}
                  />
                </DepositPickerWrapper>
              </Container>
            </Container>
          </Container>
          <Container top={24}>
            <FormState isSubmitting={isSubmitting} error={formError} />
            <Container bottom={16}>
              <PrimaryCTAWrapper>
                <FlatButton fullWidth onClick={handleSubmitPress} disabled={isSubmitting}>
                  Submit
                </FlatButton>
              </PrimaryCTAWrapper>
            </Container>
          </Container>
        </Container>
      </Content>
    </Page>
  );
};
