import React from 'react';
import LearnCarousel from '../components/learn/LearnCarousel';
import { Container } from '../components/ui';
import { Body5, Body6 } from '../lib/nvstr-common-ui.es';
import { colorPalette } from '../lib/nvstr-utils.es';
import styled from 'styled-components';
import { FlatButton, SkeletonButton } from '../components/buttons';
import { LearnScreen } from '../components/learn';
import { useLearnLesson } from '../hooks/learn/useLearnLesson';
import { buildLearnLessonPhraseMessage } from '../utils/learn';
import { useSearchParams } from 'react-router-dom';
import LearnAndEarnMoreInfo from './LearnAndEarnMoreInfo';
import { publishLesson, showModal } from '@src/actions';
import { useDispatch } from 'react-redux';

const LessonPageEntryWrapper = styled.div`
  input {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    border-radius: 6px;
    min-height: 100px;
    width: 100%;
    padding: 16px;
  }
`;

const LessonSelector = ({ onSave, onClear }) => {
  const [lessonId, setLessonId] = React.useState('');
  const [lessonReward, setLessonReward] = React.useState('');

  const onChangeId = (e) => {
    setLessonId(e.target.value);
  };

  const handleSave = () => {
    const data = {
      lessonId: parseInt(lessonId, 10),
      lessonReward: isNaN(lessonReward) ? null : parseInt(lessonReward, 10),
    };
    onSave(data);
  };
  const handleNext = () => {
    const id = parseInt(lessonId, 10) + 1;
    setLessonId(id.toString(10));
    const data = {
      lessonId: parseInt(id, 10),
      lessonReward: isNaN(lessonReward) ? null : parseInt(lessonReward, 10),
    };
    onSave(data);
  };
  const handleBack = () => {
    const id = parseInt(lessonId, 10) - 1;
    setLessonId(id.toString(10));
    const data = {
      lessonId: parseInt(id, 10),
      lessonReward: isNaN(lessonReward) ? null : parseInt(lessonReward, 10),
    };
    onSave(data);
  };

  return (
    <LessonPageEntryWrapper>
      <Container bottom={8}>
        <Body5>Lesson ID</Body5>
      </Container>
      <input onChange={onChangeId} value={lessonId} />
      <Container top={8} row>
        <SkeletonButton onClick={handleBack}>Prev</SkeletonButton>
        <Container left={8} row>
          <SkeletonButton onClick={handleNext}>Next</SkeletonButton>
        </Container>
        <Container left={8} row>
          <FlatButton onClick={handleSave}>Load Lesson</FlatButton>
        </Container>
        <Container left={8} row>
          <FlatButton transparent onClick={onClear}>
            Clear
          </FlatButton>
        </Container>
      </Container>
    </LessonPageEntryWrapper>
  );
};

const NextLearnLessonBannerPreview = ({ lessonId }) => {
  const { lesson: lessonData } = useLearnLesson(lessonId);
  const { phraseDisplay } = lessonData;

  const buildMessage = (phraseDisplay) => {
    return `${buildLearnLessonPhraseMessage(phraseDisplay)} Find out more and earn rewards.`;
  };

  return (
    <div>
      <Body6 bold>LEARN & EARN</Body6>
      <div style={{ paddingTop: 4 }}>
        <Body5>
          {phraseDisplay ? buildMessage(phraseDisplay) : 'Start learning about investing and earn rewards.'}
        </Body5>
      </div>
    </div>
  );
};

export const LearnAndEarnLessonReview = () => {
  const dispatch = useDispatch();
  const [metadata, setMetadata] = React.useState(null);
  const [isPublishing, setIsPublishing] = React.useState(false);
  const [publishedSuccess, setPublishedSuccess] = React.useState(false);

  const [searchParams] = useSearchParams();
  const showMoreInfo = searchParams.get('showMoreInfo');

  const onSave = (v) => {
    setPublishedSuccess(false);
    setMetadata(v);
  };
  const onClear = () => {
    setPublishedSuccess(false);
    setMetadata(null);
  };

  const onPublish = async () => {
    setIsPublishing(true);

    const lid = metadata.lessonId;
    if (lid) {
      const response = await publishLesson(lid);
      if (response?.ok) setPublishedSuccess(true);

      setIsPublishing(false);
    } else {
      const contentComponent = (
        <div className="modal-message" style={{ paddingTop: '0px' }}>
          No lesson id was found
        </div>
      );
      showModal({
        contentComponent,
        size: 'wide',
        dismissable: true,
      })(dispatch);
    }
  };

  const { lesson: lessonData } = useLearnLesson(metadata?.lessonId, true);
  return (
    <Container top={24}>
      <Container bottom={24} style={{ maxWidth: '600px', margin: '0 auto' }}>
        <LessonSelector onSave={onSave} onClear={onClear} />
      </Container>
      {metadata?.lessonId && !lessonData?.name ? (
        <Container top={48} bottom={148} style={{ maxWidth: '600px', margin: '0 auto' }} centerAll>
          <Body6>Lesson does not exist</Body6>
        </Container>
      ) : null}
      {metadata?.lessonId && lessonData?.name && (
        <Container>
          <Container bottom={24} style={{ maxWidth: '600px', margin: '0 auto' }}>
            <Container bottom={16}>
              <Body5>{`Lesson Levels: ${lessonData?.metadata?.minInvestingExperience} - ${
                lessonData?.metadata?.maxInvestingExperience
              }`}</Body5>
            </Container>
            <NextLearnLessonBannerPreview lessonId={metadata.lessonId} />
          </Container>
          <LearnScreen>
            {showMoreInfo && <LearnAndEarnMoreInfo lessonId={metadata.lessonId} />}
            <LearnCarousel key={metadata.lessonId} lessonId={metadata.lessonId} onComplete={() => null} />
          </LearnScreen>
        </Container>
      )}
      {lessonData?.name ? (
        lessonData?.metadata?.publishedAt || publishedSuccess ? (
          <Container top={16} bottom={48}>
            <Container centerAll>
              <FlatButton fullWidth disabled onClick={() => false}>
                Already Published
              </FlatButton>
            </Container>
            <Container top={4} centerAll>
              <Body5 center>Inform engineering if you need to unpublish</Body5>
            </Container>
          </Container>
        ) : (
          <Container top={16} bottom={48} centerAll>
            <FlatButton fullWidth disabled={isPublishing} onClick={onPublish}>
              {isPublishing ? 'Publishing Lesson...' : 'Publish This Lesson'}
            </FlatButton>
          </Container>
        )
      ) : (
        <Container top={96} />
      )}
    </Container>
  );
};
