import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@src/main/components/ui';
import { CreateOrderSearch } from '@src/main/containers/Orders/CreateOrderSearch';
import { useFilteredOrdersInCart, useOrdersInCart } from '@src/main/hooks/orders/useOrdersInCart';
import { useCurrentUser } from '@src/main/hooks/user';
import { PRODUCT_COMMISSION_FEE } from '@src/appConfig';
import { ORDER_TRANSACTION_TYPES } from '@src/main/constants/orders';
import { Body5, Body6, Body7, Warning } from '@src/main/lib/nvstr-common-ui.es';
import { usePriceLookup } from '@src/main/hooks/securities/usePriceData';
import { DISPLAY_FORMAT_TYPES, formatValueTo } from '@src/main/utils/numbers';
import { UnplacedOrder } from '@src/main/containers/Orders/UnplacedOrder';
import OrderSizeFilterSlider from '@src/containers/Orders/components/OrderSizeFilterSlider';
import { useEquityValue } from '@src/main/hooks/portfolio/useEquityData';
import { pluralize } from '@src/helpers/usefulFuncs';
import { FlatButton } from '@src/main/components/buttons';
import { deleteAllOrdersV1, hideModal, logMetricsTrackingEvent } from '@src/actions';
import { approximateValueOfOrder } from '@src/main/utils/orders';
import { showModal } from '@src/actions/modalActions';
import { createBasicErrorModalWithReloadButton } from '@src/constants/modals';

const UnplacedOrdersList = styled.div`
  padding-top: 8px;
`;
const OrderSummaryPlaceOrdersWrapper = styled.div`
  padding-top: 24px;
`;
const OrderCartWrapper = styled.div`
  //
`;
const CartOrderSummaryWrapper = styled.div`
  padding: 16px;
  margin-top: 16px;
  margin-left: 32px;

  border: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
  border-radius: 5px;

  min-width: 280px;
`;
const OrderActionsWrapper = styled.div`
  padding-top: 24px;
`;
const OrderCartBodyWrapper = styled.div`
  padding-top: 8px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 840px) {
    flex-direction: column;

    ${CartOrderSummaryWrapper} {
      width: 100%;
      //display: flex;
      //justify-content: flex-end;
    }
    ${CartOrderSummaryWrapper} {
      max-width: 320px;
      margin-left: auto;
      border: none;
    }
    ${UnplacedOrdersList} {
      width: 100%;
    }
    ${OrderSummaryPlaceOrdersWrapper} {
      display: none;
    }
  }
`;

const FilteringOrdersWrapper = styled.div`
  background: ${({ theme }) => theme.themeColors.lowContrastBorder};
  // border: 1px solid ${({ theme }) => theme.themeColors.border};
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 16px;
    width: 16px;

    margin-right: 5px;

    * {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

const calculateOrdersSummary = (orders, freeTradesCount, priceLookup) => {
  const totalTradesCount = orders.length;
  const freeTradesLeft = freeTradesCount - totalTradesCount;
  const commissionsFee = freeTradesLeft < 0 ? freeTradesLeft * -1 * PRODUCT_COMMISSION_FEE : 0;

  const buyOrders = orders.filter((o) => o.operation === ORDER_TRANSACTION_TYPES.buy);
  const sellOrders = orders.filter((o) => o.operation === ORDER_TRANSACTION_TYPES.sell);

  const missingPrices = orders.map((o) => o.security_id);
  missingPrices.forEach((sid) => {
    const price = priceLookup[sid];
    if (!price) {
      console.log('missing', sid);
    }
  });

  const buyOrdersValue = buyOrders.reduce(
    (currentValue, el) => approximateValueOfOrder(el, priceLookup[el.security_id]?.price) + currentValue,
    0
  );
  const sellOrdersValue = sellOrders.reduce(
    (currentValue, el) => approximateValueOfOrder(el, priceLookup[el.security_id]?.price) + currentValue,
    0
  );
  const totalValue = commissionsFee + buyOrdersValue + sellOrdersValue;

  return {
    buyOrdersValue: isNaN(buyOrdersValue) ? '...' : buyOrdersValue,
    sellOrdersValue: isNaN(sellOrdersValue) ? '...' : sellOrdersValue,
    commissionsFee,
    totalValue: isNaN(totalValue) ? '...' : totalValue,
  };
};

const KeyValueRow = ({ k, v }) => {
  return (
    <Container row spaceBetween verticallyCenter bottom={8}>
      <Container>
        <Body5>{k}</Body5>
      </Container>
      <Container>
        <Body5>{v === '...' ? v : formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, v)}</Body5>
      </Container>
    </Container>
  );
};

const CartOrderSummary = ({ orders }) => {
  const { membership_free_trade_count: freeTradesCount } = useCurrentUser();
  const priceLookup = usePriceLookup();
  const { buyOrdersValue, sellOrdersValue, commissionsFee, totalValue } = calculateOrdersSummary(
    orders,
    freeTradesCount,
    priceLookup
  );
  return (
    <Container>
      <KeyValueRow k={'Buy Orders:'} v={buyOrdersValue} />
      <KeyValueRow k={'Sell Orders:'} v={sellOrdersValue} />
      {commissionsFee > 0 ? <KeyValueRow k={'Commission Fee:'} v={commissionsFee} /> : null}
      <KeyValueRow k={'Total Estimated Value:'} v={totalValue} />
    </Container>
  );
};

const FilteredOutOrdersBanner = ({ count }) => {
  if (count === 0) {
    return null;
  }

  const message = `Filtering ${count} small ${pluralize(count, 'order')}. Use the slider above to adjust.`;
  return (
    <Container top={16}>
      <FilteringOrdersWrapper>
        <Container verticallyCenter top={7} bottom={9} horizontal={6}>
          <Warning />
          <Body7 thin>{message}</Body7>
        </Container>
      </FilteringOrdersWrapper>
    </Container>
  );
};

function getIsOptimizedUnplacedOrders(orders) {
  return orders.some((o) => o.is_from_optimizer);
}

export const OrderCart = ({ onPlaceAll, onCancel }) => {
  const dispatch = useDispatch();
  const { value: equity } = useEquityValue();
  const [minOrderValue, setMinOrderValue] = React.useState(Math.ceil(equity * 0.01) || 5);
  const maxSliderValue = Math.ceil(equity * 0.25);

  const ordersInCart = useOrdersInCart();
  const filteredOrdersInCart = useFilteredOrdersInCart(minOrderValue);
  const filteredOutOrdersCount = ordersInCart.length - filteredOrdersInCart.length;

  const isOptimizerOrders = getIsOptimizedUnplacedOrders(ordersInCart);

  const handleMinimumOrderValueChange = (v) => {
    setMinOrderValue(v);
  };

  const handleDeleteAll = async () => {
    logMetricsTrackingEvent('Remove All Orders', {})();

    const component = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        Deleting Orders...
      </div>
    );
    const modal = {
      contentComponent: component,
      dismissable: true,
    };
    showModal(modal)(dispatch);
    const response = await deleteAllOrdersV1()(dispatch);
    if (response.ok) {
      onCancel();
      hideModal()(dispatch);
    } else {
      hideModal()(dispatch);
      const defaultErrorMessage = 'Something went wrong, could not delete orders at this time.';
      showModal(createBasicErrorModalWithReloadButton(defaultErrorMessage))(dispatch);
    }
  };

  const handlePlaceAll = () => {
    onPlaceAll(filteredOrdersInCart);
  };

  if (!ordersInCart || ordersInCart.length === 0) {
    return (
      <Container top={148}>
        <Container>
          <FlatButton onClick={onCancel}>Back to orders page</FlatButton>
        </Container>
      </Container>
    );
  }

  return (
    <OrderCartWrapper>
      <Container width={300}>
        <CreateOrderSearch inCart />
      </Container>

      <Container>
        {isOptimizerOrders && (
          <Container top={24}>
            <Container top={24}>
              <OrderSizeFilterSlider
                min={0}
                max={maxSliderValue}
                value={minOrderValue}
                handleChange={handleMinimumOrderValueChange}
              />
            </Container>
          </Container>
        )}
        <FilteredOutOrdersBanner count={filteredOutOrdersCount} />
      </Container>
      <OrderCartBodyWrapper>
        <UnplacedOrdersList>
          {filteredOrdersInCart.map((o) => (
            <UnplacedOrder key={o.id} id={o.id} />
          ))}
          <Container top={12} horizontal={8}>
            {filteredOrdersInCart.length > 1
              ? 'Orders above are not yet placed.  Click the Place Trade button to submit them.'
              : 'Order above is not yet placed.  Click the Place Trade button to submit it.'}
          </Container>
        </UnplacedOrdersList>

        <CartOrderSummaryWrapper>
          <CartOrderSummary orders={filteredOrdersInCart} />
          <OrderSummaryPlaceOrdersWrapper>
            <FlatButton fullWidth onClick={handlePlaceAll}>
              Place Orders
            </FlatButton>
          </OrderSummaryPlaceOrdersWrapper>
        </CartOrderSummaryWrapper>
      </OrderCartBodyWrapper>

      <OrderActionsWrapper>
        <Container centerAll>
          <FlatButton fullWidth onClick={handlePlaceAll}>
            Place Orders
          </FlatButton>
        </Container>
        <Container centerAll top={4}>
          <FlatButton transparent onClick={handleDeleteAll}>
            Delete All
          </FlatButton>
        </Container>
        {isOptimizerOrders && (
          <Container top={16} horizontal={8}>
            <Body5>
              The output of the Optimizer is not a recommendation. You may modify or cancel any or all of the templated
              orders above. Please consider whether any trade is appropriate for your risk tolerance, investment goals,
              and other preferences before transacting.
            </Body5>
          </Container>
        )}
      </OrderActionsWrapper>
    </OrderCartWrapper>
  );
};
