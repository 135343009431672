import React from 'react';
import { Thresholds } from '../../../constants';

import Icon from '../../../components/misc/Icon';
import { useIsMarketOpen } from '../../../main/hooks/securities/useIsMarketOpen';

const OrderBasketListHeading = (props) => {
  const {
    isMobile,
    windowWidth,

    isPricingUpdating,
    handleRefreshPricingClick,
  } = props;

  const { isOpen: isMarketOpen } = useIsMarketOpen();
  const showCompactView = windowWidth <= Thresholds.widthForOrderCompactView;
  return (
    <div
      className={`
      order-basket-list-heading
      ${showCompactView ? 'condensed-order-basket-list-heading' : ''}
    `}
    >
      <div className={`order-type-and-operation`}>
        {isMarketOpen && (
          <div className={`refresh-prices-container refresh-text-color`} onClick={handleRefreshPricingClick}>
            <span className={`refresh-icon-container ${isPricingUpdating ? 'is-updating' : ''}`}>
              <Icon icon="fa-refresh" size="small" colorClassName="refresh-text-color" />
            </span>
            <span className={`text-container`}>{'Refresh pricing'}</span>
          </div>
        )}
      </div>
      <div className={`order-security-identifier`}>{''}</div>
      <div className={`order-last-price-container ${isMobile ? 'mobile' : ''}`}>{'Last Price'}</div>
      {!isMobile && <div className={`order-current-position-container`}>{'Current Position'}</div>}
      <div className={`order-size-container ${isMobile ? 'mobile' : ''}`}>{'Trade Size'}</div>
      {!isMobile && <div className={`order-approx-value-container`}>{'Approx. Trade Value'}</div>}
      <div className={`order-action-container-filler`} />
    </div>
  );
};

export default OrderBasketListHeading;
