import {
  GET_LIVE_TRADING_ACCOUNT,
  LIVE_TRADING_SUBMIT_STATUS,
  LIVE_TRADING_SUBMIT_FORM,
  LIVE_TRADING_ACCOUNT_UPDATE,
  UPDATE_LIVE_TRADING_ACCOUNT_STATUS,
  GET_BANK_ACCOUNTS,
  ADD_BANK_ACCOUNT,
  REMOVE_BANK_ACCOUNT,
  VERIFY_BANK_ACCOUNT,
  VERIFY_BANK_ACCOUNT_APPROVED,
  VERIFY_BANK_ACCOUNT_MAX_ATTEMPTS_REACHED,
  CREATE_BANK_ACCOUNT_NOTICE,
  CLEAR_BANK_ACCOUNT_NOTICE,
  CREATE_FUNDING_FORM_NOTICE,
  CLEAR_FUNDING_FORM_NOTICE,
  TRANSACTION_ACCEPTED,
  SUBMIT_APPEAL_IMAGE,
  START_LINKING_PLAID_ACCOUNT,
  CANCEL_LINKING_PLAID_ACCOUNT,
} from '../constants';

const defaultState = {
  liveTrading: { loaded: false }, // liveTrading account
  liveAccounts: { loaded: false, accounts: null, paperTradingAccount: null }, // bank accounts TODO: break this out into own reducer
  linkingPlaidAccount: false,
};

let bankAccount, liveBankAccounts, bankAccountId, updatedBankAccount, notice;

export default function liveAccountReducer(state = defaultState, action) {
  switch (action.type) {
    case START_LINKING_PLAID_ACCOUNT:
      return {
        ...state,
        linkingPlaidAccount: true,
      };

    case CANCEL_LINKING_PLAID_ACCOUNT:
      return {
        ...state,
        linkingPlaidAccount: false,
      };

    case GET_LIVE_TRADING_ACCOUNT:
      let liveTradingAccount = action.payload;
      return {
        ...state,
        liveTrading: {
          ...state.liveTrading,
          loaded: true,
          ...liveTradingAccount,
        },
      };

    case UPDATE_LIVE_TRADING_ACCOUNT_STATUS:
      let liveTradingAccountStatus = action.payload;
      return {
        ...state,
        liveTrading: {
          ...state.liveTrading,
          loaded: true,
          status: liveTradingAccountStatus,
        },
      };

    case LIVE_TRADING_SUBMIT_STATUS: {
      let liveTradingAccountSubmissionStatus = action.payload;
      if (action.payload) {
        return {
          ...state,
          liveTrading: {
            ...state.liveTrading,
            ...liveTradingAccountSubmissionStatus,
          },
        };
      } else {
        return state;
      }
    }

    case LIVE_TRADING_SUBMIT_FORM:
      let liveTradingAccountSubmission = action.payload;
      return {
        ...state,
        liveTrading: {
          ...state.liveTrading,
          loaded: true,
          ...liveTradingAccountSubmission,
        },
      };

    case LIVE_TRADING_ACCOUNT_UPDATE:
      let liveTradingAccountUpdated = action.payload;
      return {
        ...state,
        liveTrading: { ...state.liveTrading, ...liveTradingAccountUpdated },
      };

    case SUBMIT_APPEAL_IMAGE:
      return {
        ...state,
        liveTrading: {
          ...state.liveTrading,
          loaded: true,
          accountStatus: 'UNDER_REVIEW',
        },
      };

    case GET_BANK_ACCOUNTS:
      if ('paper_trading_account' in action.payload) {
        const paperTradingAccount = {
          account_id: action.payload.paper_trading_account.id,
        };

        return {
          ...state,
          liveAccounts: {
            ...state.liveAccounts,
            loaded: true,
            accounts: null,
            paperTradingAccount,
          },
        };
      } else {
        let accounts = action.payload.accounts;
        if (!accounts) {
          return state;
        }
        let marginCallAmount = action.payload.margin_call_amount;
        let isAllowedToDepositFunds = !!action.payload.is_allowed_to_deposit_funds;
        let maxAchLinks = action.payload.maximum_ach_links || 3;
        liveBankAccounts = {};
        accounts.forEach((account) => (liveBankAccounts[account.account_id] = account));

        return {
          ...state,
          liveAccounts: {
            ...state.liveAccounts,
            marginCallAmount,
            recurring_deposits: action.payload.recurring_deposits,
            isAllowedToDepositFunds,
            maxAchLinks,
            loaded: true,
            accounts: {
              ...state.liveAccounts.accounts,
              ...liveBankAccounts,
            },
            paperTradingAccount: null,
          },
        };
      }

    case ADD_BANK_ACCOUNT:
      let newBankAccount = {
        [action.payload.account.account_id]: action.payload.account,
      };
      return {
        ...state,
        linkingPlaidAccount: false,
        liveAccounts: {
          ...state.liveAccounts,
          accounts: { ...state.liveAccounts.accounts, ...newBankAccount },
        },
      };

    case VERIFY_BANK_ACCOUNT:
      return state;

    case VERIFY_BANK_ACCOUNT_APPROVED:
      let approvedBankAccountId = action.payload.id;
      let approvedBankAccount = state.liveAccounts.accounts[approvedBankAccountId];
      updatedBankAccount = {
        [approvedBankAccountId]: {
          ...approvedBankAccount,
          can_accept_transfers: true,
          needs_micro_deposit_verification: false,
        },
      };

      return {
        ...state,
        liveAccounts: {
          ...state.liveAccounts,
          accounts: { ...state.liveAccounts.accounts, ...updatedBankAccount },
        },
      };

    case VERIFY_BANK_ACCOUNT_MAX_ATTEMPTS_REACHED:
      window.scrollTo(0, 0);
      let failedBankAccountId = action.payload.id;
      let failedBankAccount = state.liveAccounts.accounts[failedBankAccountId];
      updatedBankAccount = {
        [failedBankAccountId]: {
          ...failedBankAccount,
          can_accept_transfers: false,
          needs_micro_deposit_verification: false,
        },
      };

      return {
        ...state,
        liveAccounts: {
          ...state.liveAccounts,
          accounts: {
            ...state.liveAccounts.accounts,
            ...updatedBankAccount,
          },
        },
      };

    case CREATE_BANK_ACCOUNT_NOTICE:
      if (state.liveAccounts.paperTradingAccount) {
        bankAccount = state.liveAccounts.paperTradingAccount;
        let message = action.payload.message;
        let style = action.payload.style;
        notice = { message, style };
        updatedBankAccount = {
          paperTradingAccount: {
            ...state.liveAccounts.paperTradingAccount,
            notice,
          },
        };

        return {
          ...state,
          liveAccounts: { ...state.liveAccounts, ...updatedBankAccount },
        };
      } else {
        bankAccountId = action.payload.id;
        bankAccount = state.liveAccounts.accounts[bankAccountId];

        let message = action.payload.message;
        let style = action.payload.style;
        notice = { message, style };
        updatedBankAccount = { [bankAccountId]: { ...bankAccount, notice } };

        return {
          ...state,
          liveAccounts: {
            ...state.liveAccounts,
            accounts: { ...state.liveAccounts.accounts, ...updatedBankAccount },
          },
        };
      }

    case CLEAR_BANK_ACCOUNT_NOTICE:
      let bankAccountWithNoticeId = action.payload.id;
      let bankAccountWithNotice = state.liveAccounts.accounts[bankAccountWithNoticeId];
      notice = null;
      updatedBankAccount = {
        [bankAccountWithNoticeId]: { ...bankAccountWithNotice, notice },
      };

      return {
        ...state,
        liveAccounts: {
          ...state.liveAccounts,
          accounts: { ...state.liveAccounts.accounts, ...updatedBankAccount },
        },
      };

    case CREATE_FUNDING_FORM_NOTICE:
      notice = action.payload;

      return { ...state, liveAccounts: { ...state.liveAccounts, notice } };

    case CLEAR_FUNDING_FORM_NOTICE:
      notice = null;
      return { ...state, liveAccounts: { ...state.liveAccounts, notice } };

    case TRANSACTION_ACCEPTED:
      const { transactionType, amount } = action.payload;
      if (transactionType.toLowerCase() === 'withdrawal') {
        return {
          ...state,
          liveAccounts: {
            ...state.liveAccounts,
            fundsAvailable: state.liveAccounts.fundsAvailable - amount,
          },
        };
      } else {
        return {
          ...state,
          liveAccounts: {
            ...state.liveAccounts,
            isAllowedToDepositFunds: false,
          },
        };
      }

    case REMOVE_BANK_ACCOUNT:
      bankAccountId = action.payload.id.toString();
      let bankAccountIds = Object.keys(state.liveAccounts.accounts);
      let filteredBankAccountIds = bankAccountIds.filter((account_id) => account_id !== bankAccountId);
      liveBankAccounts = {};
      filteredBankAccountIds.forEach(
        (account_id) => (liveBankAccounts[account_id] = state.liveAccounts.accounts[account_id])
      );

      return {
        ...state,
        liveAccounts: {
          ...state.liveAccounts,
          accounts: { ...liveBankAccounts },
        },
      };

    default:
      return state;
  }
}
