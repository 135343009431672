import * as Sentry from '@sentry/react';

export const logErrorEvent = (errorObj, properties, config) => {
  console.error(errorObj, properties);
  return () => {
    if (!errorObj || typeof errorObj !== 'object') {
      Sentry.captureException(new Error(errorObj), properties);
      return;
    }
    const additionalErrorConfig = config || {};
    Sentry.captureException(errorObj, properties);
  };
};
