import React from 'react';
import Icon from '../../misc/Icon';

const SelectionOption = (props) => {
  const value = props.value;
  const obj = props.obj;
  const selected = props.selected;
  const inputName = props.inputName;

  const handleSelectionClick = () => {
    props.handleSelectionClick(inputName, obj);
  };

  const renderIcon = () => {
    return selected ? <Icon icon={'fa-check'} color={'#3db735'} size="small" /> : <div></div>;
  };

  return (
    <div className={`selection-option ${selected ? 'selected' : ''} `} onClick={handleSelectionClick}>
      <div className="selection-icon-container">{renderIcon()}</div>
      <div className="selection-value-container">{value}</div>
    </div>
  );
};

export default SelectionOption;
