import React from 'react';
import { HorizontalNav } from '../../../containers/Nav/HorizontalNav';

export const PublicPage = ({ children }) => {
  return (
    <div>
      <HorizontalNav forceEmptyNav />
      {children}
    </div>
  );
};
