import SecurityFilter, { SECTOR_ID_PRESETS, SECURITY_FILTER_TYPES, SECURITY_FILTER_OPERATORS } from './securityFilter';

class SecurityPresetFilter {
  constructor(type, filters) {
    this.filters = filters;
    this.type = type;
  }

  toApi = () => {
    return this.filters.map((f) => f.toApi());
  };
}

const baseCollectiveIntelligenceFilter = new SecurityFilter({ type: SECURITY_FILTER_TYPES.COLLECTIVE_INTELLIGENCE });
const baseUpcomingEarningsFilter = new SecurityFilter({ type: SECURITY_FILTER_TYPES.UPCOMING_EARNINGS });
const baseTrendingStocksFilter = new SecurityFilter({ type: SECURITY_FILTER_TYPES.TRENDING_STOCKS });

const baseTechSectorFilter = new SecurityFilter({
  type: SECURITY_FILTER_TYPES.SECTOR,
  filterIds: SECTOR_ID_PRESETS.TECHNOLOGY,
});
const baseFinancialSectorFilter = new SecurityFilter({
  type: SECURITY_FILTER_TYPES.SECTOR,
  filterIds: SECTOR_ID_PRESETS.FINANCIAL,
});
const baseHealthcareSectorFilter = new SecurityFilter({
  type: SECURITY_FILTER_TYPES.SECTOR,
  filterIds: SECTOR_ID_PRESETS.HEALTHCARE,
});
const baseEnergySectorFilter = new SecurityFilter({
  type: SECURITY_FILTER_TYPES.SECTOR,
  filterIds: SECTOR_ID_PRESETS.ENERGY,
});

export const SECURITY_FILTER_PRESETS = {
  COLLECTIVE_INTELLIGENCE: new SecurityPresetFilter('COLLECTIVE_INTELLIGENCE', [baseCollectiveIntelligenceFilter]),
  UPCOMING_EARNINGS: new SecurityPresetFilter('UPCOMING_EARNINGS', [baseUpcomingEarningsFilter]),
  TRENDING_STOCKS: new SecurityPresetFilter('TRENDING_STOCKS', [baseTrendingStocksFilter]),

  TECH_SECTOR: new SecurityPresetFilter('TECH_SECTOR', [baseTechSectorFilter]),
  FINANCIAL_SECTOR: new SecurityPresetFilter('FINANCIAL_SECTOR', [baseFinancialSectorFilter]),
  HEALTHCARE_SECTOR: new SecurityPresetFilter('HEALTHCARE_SECTOR', [baseHealthcareSectorFilter]),
  ENERGY_SECTOR: new SecurityPresetFilter('ENERGY_SECTOR', [baseEnergySectorFilter]),

  JUICY_DIVIDENDS: new SecurityPresetFilter('JUICY_DIVIDENDS', [
    new SecurityFilter({
      type: SECURITY_FILTER_TYPES.DIVIDEND_YIELD,
      operator: SECURITY_FILTER_OPERATORS.GREATER_THAN,
      operatorValue: 3,
    }),
    new SecurityFilter({
      type: SECURITY_FILTER_TYPES.MARKET_CAP,
      operator: SECURITY_FILTER_OPERATORS.GREATER_THAN,
      operatorValue: 1000000000,
    }),
    new SecurityFilter({
      type: SECURITY_FILTER_TYPES.REVENUE_LTM,
      operator: SECURITY_FILTER_OPERATORS.GREATER_THAN,
      operatorValue: 1000000000,
    }),
    // new SecurityFilter({ type: SECURITY_FILTER_TYPES.COLLECTIVE_INTELLIGENCE }),
  ]),

  GROWTH_STOCKS: new SecurityPresetFilter('GROWTH_STOCKS', [
    new SecurityFilter({
      type: SECURITY_FILTER_TYPES.PROJECTED_REVENUE_GROWTH,
      operator: SECURITY_FILTER_OPERATORS.GREATER_THAN,
      operatorValue: 20,
    }),
    new SecurityFilter({
      type: SECURITY_FILTER_TYPES.PROJECTED_REVENUE_GROWTH,
      operator: SECURITY_FILTER_OPERATORS.LESS_THAN,
      operatorValue: 200,
    }),
    new SecurityFilter({
      type: SECURITY_FILTER_TYPES.MARKET_CAP,
      operator: SECURITY_FILTER_OPERATORS.GREATER_THAN,
      operatorValue: 1000000000,
    }),
    new SecurityFilter({
      type: SECURITY_FILTER_TYPES.REVENUE_LTM,
      operator: SECURITY_FILTER_OPERATORS.GREATER_THAN,
      operatorValue: 1000000000,
    }),
    // new SecurityFilter({ type: SECURITY_FILTER_TYPES.COLLECTIVE_INTELLIGENCE }),
  ]),

  VALUE_STOCKS: new SecurityPresetFilter('VALUE_STOCKS', [
    new SecurityFilter({
      type: SECURITY_FILTER_TYPES.FORWARD_PE,
      operator: SECURITY_FILTER_OPERATORS.LESS_THAN,
      operatorValue: 12,
    }),
    new SecurityFilter({
      type: SECURITY_FILTER_TYPES.FORWARD_PE,
      operator: SECURITY_FILTER_OPERATORS.GREATER_THAN,
      operatorValue: 0,
    }),
    new SecurityFilter({
      type: SECURITY_FILTER_TYPES.MARKET_CAP,
      operator: SECURITY_FILTER_OPERATORS.GREATER_THAN,
      operatorValue: 1000000000,
    }),
    new SecurityFilter({
      type: SECURITY_FILTER_TYPES.REVENUE_LTM,
      operator: SECURITY_FILTER_OPERATORS.GREATER_THAN,
      operatorValue: 1000000000,
    }),
    // new SecurityFilter({ type: SECURITY_FILTER_TYPES.COLLECTIVE_INTELLIGENCE }),
  ]),

  FAMOUS_INVESTORS: new SecurityPresetFilter('FAMOUS_INVESTORS', [
    new SecurityFilter({ type: SECURITY_FILTER_TYPES.THOUGHT_LEADERS, filterIds: null }),
  ]),

  FRIENDS_IDEAS: new SecurityPresetFilter('FRIENDS_IDEAS', [
    new SecurityFilter({ type: SECURITY_FILTER_TYPES.CONNECTIONS, filterIds: null }),
  ]),
};

export default SecurityPresetFilter;
