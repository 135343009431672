import React from 'react';

import LinkTo from '../Links/LinkTo';

import { returnPathTo } from '../../constants/paths';
import { logMetricsTrackingEvent } from '../../actions/trackingActions';

export const LinkToSecurityThoughts = (props) => {
  const { securityId, symbol, isUserAuthed } = props;

  const logViewAllThoughts = () => {
    const event = 'Clicked View All Thoughts For Security';
    const properties = {
      'Is Authed': !!isUserAuthed,
      'Security ID': securityId,
      Symbol: symbol,
    };
    logMetricsTrackingEvent(event, properties)();
  };

  return (
    <div className={`see-all-security-thoughts-link`}>
      <LinkTo
        to={returnPathTo('securitiesPage', securityId, `?activeTab=thoughts`)}
        className="link-btn link-btn-std-color"
        additionalClickAction={logViewAllThoughts}
        child={<span className={``}>{`See all Pros/Cons for ${symbol}`}</span>}
        scrollToTop
      />
    </div>
  );
};
