import React from 'react';
import { connect } from 'react-redux';

import { getUserIdFromUser } from '../../helpers/usersHelpers';

const withCurrentUserId = (WrappedComponent) => {
  const HOC = class extends React.Component {
    shouldComponentUpdate(nextProps) {
      return this.returnCurrentUserId(nextProps) !== this.returnCurrentUserId();
    }

    render() {
      return <WrappedComponent {...this.props} currentUserId={this.returnCurrentUserId()} />;
    }

    returnCurrentUserId = (props = this.props) => getUserIdFromUser(props.currentUser);
  };

  return connect(mapStateToProps)(HOC);
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

export default withCurrentUserId;
