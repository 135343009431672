import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as Actions from '../../../../actions/index';
import Switch from '../../../../components/form/Switch';
import { truncateName, snakeToHyphens, isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import { findUserIdeaForSecurity, findIdea } from '../../../../helpers/ideaHelpers';
import { createInputComponent } from '../../../../helpers/formHelpers';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex !important;
  flex-direction: row;
  justify-content: center;
`;

export class OptimizerToggleCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputs: [
        {
          type: Switch,
          typeName: 'Switch',
          name: 'active',
          label: '',
          required: true,
          props: {
            saveOnChange: this._updateCellIdea,
            // HACK: objectType can be removed once all primary keys are back to id not idea_id
            objectType: 'idea',
          },
        },
      ],

      formData: {},
      errors: {},
      warnings: {},

      loadingFormData: true,
    };
  }

  componentDidMount() {
    this._updateFormData();
  }

  render() {
    const columnKeyName = this.props.column.keyName;
    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? this.props.loadingCellComponent : this._renderCellContent()}
      </td>
    );
  }

  _isLoading = () => {
    const security = this._returnSecurity();
    return !security || !('name' in security) || !this._returnIdea() || this.state.loadingFormData;
  };

  _renderInputComponent = () => {
    const inputs = this.state.inputs;
    return inputs.length > 0 && createInputComponent.call(this, inputs[0], null, inputs[0].name);
  };

  _returnIdea = () => this.props.idea;
  _returnSecurity = () => this.props.securities.lookup[this.props.securityId];
  _isSecurityUnfeatured = () => !this._returnSecurity().featured;

  _renderCellContent = () => {
    if (this._isSecurityUnfeatured()) {
      return null;
    }

    return (
      <Wrapper style={{ textAlign: 'center' }} onClick={this._logEventForOptimizerToggleClick}>
        {this._renderInputComponent()}
      </Wrapper>
    );
  };

  _logEventForOptimizerToggleClick = () => {
    const security = this._returnSecurity();
    const idea = this._returnIdea();

    const event = idea.active ? 'Toggled Idea Inactive' : 'Toggled Idea Active';
    const properties = {
      'Stock Symbol': security.symbol,
      'Security ID': security.security_id,
      Context: 'Security Table',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _updateCellIdea = (idea) => {
    const rollbackToIdea = findIdea(idea.idea_id, this.props.ideas);
    this.props.actions.updateIdea(idea, rollbackToIdea);
  };

  _updateFormData = () => {
    const idea = this._returnIdea();

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        active: idea.active,
      },
      loadingFormData: false,
    }));
  };
}

const mapStateToProps = (state) => {
  return {
    ideas: state.ideas,
    securities: state.securities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(connect(mapStateToProps, mapDispatchToProps))(OptimizerToggleCell);

export default composedComponent;
