import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const optimizerAdapter = {
  runOptimizer: (customOptimizerSettings) => {
    const options = { ignoreAuth: true };
    const params = customOptimizerSettings ? { ...customOptimizerSettings } : {};

    return sendRequest('post', [`${URL}/optimize`, params], options).then((response) => response);
  },

  createOptimizedOrders: () => {
    return sendRequest('post', [`${URL}/optimize/orders`]).then((response) => response);
  },

  clearOptimizerCooldown: () => {
    const options = { ignoreAuth: true };
    return sendRequest('post', [`${URL}/optimize/clear_cooldown`], options).then((response) => response);
  },

  getOptimizerAllocationConstraints: () => {
    return sendRequest('get', [`${URL}/optimizer/constraints`]).then((response) => response);
  },

  createOptimizerAllocationConstraints: (securityIds) => {
    // server takes an array of optimize_constraints
    const params = {
      optimize_constraints: Array.isArray(securityIds) ? securityIds : [securityIds],
    };
    return sendRequest('post', [`${URL}/optimizer/constraints`, params]).then((response) => response);
  },

  updateOptimizerAllocationConstraints: (optimizeAllocation) => {
    const params = {
      optimize_constraints: [optimizeAllocation],
    };
    return sendRequest('put', [`${URL}/optimizer/constraints`, params]).then((response) => response);
  },

  deleteOptimizerAllocationConstraint: (allocationConstraintId) => {
    return sendRequest('delete', [`${URL}/optimizer/constraints/${allocationConstraintId}`]).then(
      (response) => response
    );
  },
};
