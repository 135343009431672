import React from 'react';
import { formatForDisplay } from '../../../helpers/displayHelpers';
import { isUndefinedOrNull } from '../../../helpers/generalHelpers';

const ChargeRow = (props) => {
  const { chargeData } = props;
  const { title, description, value, discountedValue } = chargeData;

  return (
    <div className={`charge-row-container`}>
      <div className={`single-charge-info`}>
        <p className="title  ">{title}</p>
        <p className="description  secondary-text-color">{description}</p>
      </div>
      <div className={`single-charge-value`}>
        <p className="charge-value  ">{formatForDisplay(value, 'price')}</p>
        {!isUndefinedOrNull(discountedValue) && (
          <p className="charge-value secondary-text-color">
            {`First month ${formatForDisplay(discountedValue, 'price')}`}
          </p>
        )}
      </div>
    </div>
  );
};

export default ChargeRow;
