import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  fetchSecuritiesPriceData,
  logMetricsTrackingEvent,
  quickFetchSecuritiesData,
  showComponentModal,
} from '@src/actions';
import SearchContainer from '../../../containers/UI/Search/SearchContainer';
import { SearchIconLoadingWrap, SearchIconWrap } from '@src/containers/UI/Search/components/SearchInput';
import { ROUTES } from '@src/constants/paths';
import { COMPONENT_MODAL_TYPES } from '@src/main/constants';
import { ORDER_TRANSACTION_TYPES } from '@src/main/constants/orders';
import { useOrdersInCart } from '@src/main/hooks/orders/useOrdersInCart';

const Wrapper = styled.div`
  max-width: 400px;

  input {
    border: 1px solid ${({ theme }) => theme.themeColors.text};
  }

  ${SearchIconLoadingWrap} {
    top: -4px;
    left: -15px;
  }

  ${SearchIconWrap} {
    top: 10px;

    svg {
      height: 16px;
      width: 16px;

      fill: ${({ theme }) => theme.themeColors.text};
    }
  }

  .default-search-container .search-results-container {
    top: 36px;
    left: 0;
    width: 100%;
  }
  @media (max-width: 1075px) {
    .default-search-container .search-results-container {
      left: 0px;
      right: 0px;
    }
  }
  .default-search-container i.query-icon {
    left: 13px;
    z-index: 5;
  }
  @media (max-width: 500px) {
    .default-search-container {
      max-width: 275px;
    }
    .default-search-container .search-results-container {
      left: 0px;
      right: 0px;
    }
  }
`;

// inCart - creates and sets order to not_placed state to appear in the order cart
export const CreateOrderSearch = ({ inCart }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orders = useOrdersInCart();

  const findOrder = (securityId, orders) => {
    return orders.filter((o) => o.security_id === securityId)[0];
  };

  const _fetchDataForSecurity = (securityId) => {
    quickFetchSecuritiesData([securityId])(dispatch);
    fetchSecuritiesPriceData([securityId])(dispatch);
  };

  const handleSelection = (query) => {
    if (!query) {
      // means they clicked something that wasn't a search result that triggered action so do nothing
      return false;
    }

    const querySecurity = query;
    const securityId = querySecurity.id;
    const symbol = querySecurity.symbol;
    _fetchDataForSecurity(securityId);

    const properties = {
      'Security Id': securityId,
      'Using Basket': inCart,
    };
    logMetricsTrackingEvent('Selected Result from Order Security Search', properties)();

    if (inCart) {
      const order = findOrder(securityId, orders);
      let modal;
      if (order) {
        const { is_from_optimizer, id, operation, type, shares, limit_price, stop_price } = order;
        modal = {
          type: COMPONENT_MODAL_TYPES.editCartOrder,
          props: {
            isFromOptimizer: is_from_optimizer,
            orderId: id,
            transactionType: operation,
            orderType: type,
            shares,
            limitPrice: limit_price,
            stopPrice: stop_price,
            securityId,
          },
        };
      } else {
        modal = {
          type: COMPONENT_MODAL_TYPES.createCartOrder,
          props: {
            transactionType: ORDER_TRANSACTION_TYPES.buy,
            securityId,
          },
        };
      }
      showComponentModal(modal)(dispatch);
    } else {
      navigate(ROUTES.CREATE_ORDER_SYMBOL.build(null, symbol, securityId));
    }
  };

  return (
    <Wrapper className={`add-order-container`}>
      <SearchContainer
        id="add-order-search"
        autoFocus={false}
        showPlus={inCart}
        placeholder={'Enter ticker or company name...'}
        handleSecurityQuerySelection={handleSelection}
        dismissAfterSelection
      />
    </Wrapper>
  );
};
