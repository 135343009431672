import React from 'react';

import CommunityPositionsList from '../../components/IdeaLab/PositionVisualizer/CommunityPositionsList';
import Heading from '../../components/IdeaLab/PositionVisualizer/Heading';

class PositionVisualizer extends React.PureComponent {
  render() {
    const { securities, navigate, location } = this.props;

    return (
      <div className="position-visualizer-container">
        <Heading />
        <CommunityPositionsList sortOrder={'Default'} securities={securities} navigate={navigate} location={location} />
      </div>
    );
  }
}

export default PositionVisualizer;
