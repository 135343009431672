import React from 'react';

import { Place, Text } from '@src/main/lib/nvstr-common-ui.es';
import LoadingSpinner from '@src/main/components/Images/LoadingSpinner';
import { Container } from '@src/main/components/ui';

function Loading({ hideText, text }) {
  return (
    <Place>
      <LoadingSpinner />
      {!hideText && (
        <Container style={{ textAlign: 'center' }}>
          <Text fontSize="14px">{text || 'Loading...'}</Text>
        </Container>
      )}
    </Place>
  );
}

export default Loading;
