import React from 'react';

import NotificationSecuritiesDataGetter from './NotificationSecuritiesDataGetter';
import Notification from './components/Notification';
import LoadingIcon from '../../components/misc/LoadingIcon';
import { consoleError } from '../../helpers/devToolHelpers';
import styled from 'styled-components';
import { TrackIteratively } from '../../utils/itly';
import { LoadingAnimation } from '@src/main/components/ui/LoadingAnimation';
import { SVG_LOADING_ICON_TYPES, SvgLoadingIcon } from '@src/main/components/ui/SvgLoadingIcon';
import { Container } from '@src/main/components/ui';

const NotificationsListWrapper = styled.div`
  .notification:first-child {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  .rails-notification-body-container {
    a,
    span {
      color: ${({ theme }) => theme.themeColors.text} !important;
    }
  }

  li.notification {
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.themeColors.appBackground};

    margin: 0 0 5px 0;
    padding: 25px;

    .message {
      color: ${({ theme }) => theme.themeColors.text};
    }
    .message a {
      color: ${({ theme }) => theme.themeColors.text};
    }
    .when {
      color: ${({ theme }) => theme.themeColors.secondaryText};
    }
    .message .pro {
      color: ${({ theme }) => theme.themeColors.proColor};
    }
    .message .con {
      color: ${({ theme }) => theme.themeColors.conColor};
    }
  }
`;
export class NotificationsList extends React.Component {
  constructor() {
    super();
    this.state = {
      notificationLoadCompletedCount: 0,
    };
    this._notificationLoadTracker = [];
  }

  componentDidMount() {
    this._logItlyTrackFeedLoaded();
  }

  componentDidUpdate(prevProps) {
    if (this._returnNotificationsList() !== this._returnNotificationsList(prevProps)) {
      this.onNotificationsListChange();
    }
  }

  render() {
    return (
      <NotificationsListWrapper className={'notifications-list-container'}>
        <NotificationSecuritiesDataGetter />
        <ul className={`notifications-list ${this.props.size || 'default'}`} style={{ paddingTop: '8px' }}>
          {this._returnNotificationsList().map((notification, i) => {
            return (
              <Notification
                key={this._returnNotificationKey(notification, i)}
                notificationIndex={i + 1}
                notification={notification}
                isNotificationListVisible={this.props.isVisible}
                isNotificationBatchLoaded={this.state.notificationLoadCompletedCount > i}
                onLoadComplete={this.onSingleNotificationLoadComplete}
                size={this.props.size}
                inFeed={this.props.inFeed}
                inActivityPageFeed={this.props.inActivityPageFeed}
              />
            );
          })}
        </ul>
        {this._isLoadingMoreNotifications() && this._renderIsLoadingMoreNotifications()}
        {this._isNoMoreNotifications() && this._renderIsNoMoreNotifications()}
      </NotificationsListWrapper>
    );
  }

  _returnNotificationKey = (notification, i) => {
    if (!notification) {
      return i;
    }

    if (notification.prerendered_html) {
      return `${notification.prerendered_html}-${i}`;
    }

    if (!notification.type) {
      return i;
    }

    if (notification.type === 'news') {
      const securityId = notification.security_id;
      const newsNotifId =
        (notification.data && notification.data.open_graph_object && notification.data.open_graph_object.url) ||
        JSON.stringify(notification.data); // best attempt to create a unique identifier to prevent rare occurance where new notif changes but key doesn't causing render bugs with image sizing
      return `news-article-${i}-${securityId}-${newsNotifId}`;
    } else if (notification.type === 'performance_ranking') {
      return `performance_ranking-notification-${i}-${notification.timestamp}`;
    } else if (notification.type === 'thought' || notification.type === 'thought_clean_style') {
      return `thought-notification-${i}-thought-id-${notification.data.thought.id}-${notification.data.time}`;
    } else if (notification.type === 'ask') {
      const askIds =
        notification.asks && notification.asks.length > 0
          ? notification.asks.map((askObj) => askObj.id || '').join('-')
          : '';
      return `ask-notification-${askIds}-${notification.timestamp}`;
    } else if (notification.type === 'idea_action') {
      const ideaKeys = notification.ideas ? notification.ideas.map((idea) => idea.idea_id).join('-') : '';
      const userKey = notification.owner ? notification.owner.user_id : '';
      return `idea-action-notification-${notification.action_type}-${ideaKeys}-${userKey}-${notification.timestamp}`;
    } else if (notification.type === 'idea_performance') {
      return `idea-performance-notification-${i}-${notification.adjective}-${notification.start_phrase}-${
        notification.end_phrase
      }}`;
    } else if (notification.type === 'price_target') {
      return `price_target-notification-${i}-${notification.owner.user_id}-${notification.timestamp}}`;
    } else if (notification.type === 'forward_eps_change') {
      return `forward_eps_change-${i}-${notification.owner.user_id}-${notification.timestamp}}`;
    } else if (notification.type === 'order') {
      return `order-notification-${i}-${notification.owner.user_id}-${notification.timestamp}}`;
    } else if (notification.type === 'connection') {
      return `order-notification-${i}-${notification.action}-${notification.timestamp}}`;
    } else {
      consoleError('missing key definition for notification in NotificationList method _returnNotificationKey', {
        notification,
      });
      return i;
    }
  };

  _renderIsLoadingMoreNotifications = () => {
    return (
      <div className={'is-loading-more-notifications'}>
        <Container centerAll height={36} width={80}>
          <SvgLoadingIcon type={SVG_LOADING_ICON_TYPES.balls} />
        </Container>
      </div>
    );
  };

  _renderIsNoMoreNotifications = () => {
    return (
      <div className={'is-no-more-notifications'}>
        <span className={'secondary-text-color'}>There are no more notifications.</span>
      </div>
    );
  };

  _isLoadingMoreNotifications = () => this.props.isLoadingMoreNotifications;

  _isNoMoreNotifications = () => this.props.isNoMoreNotifications;

  _returnNotificationsList = (props = this.props) => props.notificationsList;

  _returnNotificationLoadTracker = () => this._notificationLoadTracker;

  _setNotificationLoadCompletedCount = (count) => this.setState(() => ({ notificationLoadCompletedCount: count }));

  onSingleNotificationLoadComplete = () => {
    this._returnNotificationLoadTracker().push('complete');
    if (this._returnNotificationLoadTracker().length === this._returnNotificationsList().length) {
      this.onNotificationsLoadComplete(this._returnNotificationLoadTracker().length);
    }
  };

  onNotificationsLoadComplete = (count) => {
    this._setNotificationLoadCompletedCount(count);
  };

  onNotificationsListChange = () => {
    this._getUpdatedSecurityDataForThoughtNotifications();
  };

  _getUpdatedSecurityDataForThoughtNotifications = () => {
    const securityIds = [];
    this._returnNotificationsList().forEach((notif) => {
      if (notif && notif.type && ['thought', 'thought_clean_style'].includes(notif.type)) {
        const securityId = notif && notif.data && notif.data.security && notif.data.security.security_id;
        securityIds.push(securityId);
      }
    });
    if (securityIds.length > 0) {
      this.props.actions.quickFetchSecuritiesData(securityIds);
    }
  };

  _logItlyTrackFeedLoaded = () => {
    TrackIteratively.generic.FEED_LOADED.send({
      item_count: this.props.notificationsList.length,
      url: window.location.pathname,
      url_query: window.location.search,
    });
  };
}

export default NotificationsList;
