import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';
import { getSecurityIdFromIdea } from '../helpers/ideaHelpers';

const URL = `${BASEURL}/api/idea_suggest`;
const IDEAS_URL = `${BASEURL}/api/ideas`;

export const ideaExplorerAdapter = {
  getIdeasForSecurity: (securities) => {
    const key = typeof securities[0] === 'string' ? console.error('Must be ids') : 'security_ids';
    const securitiesCSV = securities.join(',');

    return sendRequest('get', [
      `${IDEAS_URL}?${key}=${securitiesCSV}&include_self=true&include_connections=true&include_thought_leaders=true`,
    ]).then((response) => {
      return response.data;
    });
  },

  importIdeas: () => {
    return sendRequest('get', [`${IDEAS_URL}`]).then((response) => {
      return response.data;
    });
  },

  initializeIdeas: (count) => {
    const params = { count: count * 2 };

    return sendRequest('post', [`${URL}`, params]).then((response) => response.data);
  },

  updateSuggestions: (countSuggestionsNeeded, valuedIdeas) => {
    const params = { count: countSuggestionsNeeded, known: [...valuedIdeas] };

    return sendRequest('post', [`${URL}`, params]).then((response) => response.data);
  },

  completeExploration: (ideas) => {
    const serializedIdeas = ideas.map((idea) => ({
      security_id: getSecurityIdFromIdea(idea),
      idea_type: idea.idea_type,
      idea_id: idea.idea_id,
    }));
    const params = { ideas: serializedIdeas };

    return sendRequest('post', [`${IDEAS_URL}`, params]).then((response) => response.data);
  },
};
