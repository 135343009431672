import React, { Component } from 'react';

import { logMetricsTrackingEvent } from '../../actions';

import SecuritiesFilterCard from '../../components/PortfolioExplorer/SecuritiesFilterCard';
import IconButton from '../../components/buttons/IconButton';

import { parseQueryString } from '../../helpers/routerHelpers';

class SecuritiesFilterCardList extends Component {
  constructor() {
    super();

    this.state = {
      cardWidth: 150,
      paddingBetweenCards: 25,
      offsetToFitArrows: 100,
      cardRowDisplaying: 0,
      width: ('container' in this && this.container.offsetWidth) || 875,

      hoveringPresetCard: null,
    };
  }

  componentDidMount() {
    let cardWidth = window.innerWidth > 650 ? 150 : 125;
    let paddingBetweenCards = window.innerWidth > 650 ? 25 : 20;
    let offsetToFitArrows = window.innerWidth > 650 ? 100 : 50;

    this.setState({
      width: ('container' in this && this.container.offsetWidth) || 875,
      cardWidth,
      paddingBetweenCards,
      offsetToFitArrows,
    });

    this.changeToStartingPageBasedOnParams();

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    const totalFilters = Object.keys(this.props.presetFilterList).length;
    const totalRows = Math.ceil(totalFilters / this.cardsInARow()) - 1;
    if (totalRows < this.state.cardRowDisplaying) {
      this.setState((prevState) => {
        return {
          cardRowDisplaying: prevState.cardRowDisplaying - 1,
        };
      });
    }
  }

  changeToStartingPageBasedOnParams = () => {
    const query = parseQueryString(window.location.search);
    const preset_page = Math.max(0, parseFloat(query.preset_page) - 1);
    if (typeof query === 'object' && query.preset_page && !isNaN(preset_page)) {
      this.setState((prevState) => ({
        cardRowDisplaying: preset_page,
      }));
    }
  };

  handleMouseOver = (presetCard) => {
    this.setState((prevState) => ({
      hoveringPresetCard: presetCard,
    }));
  };

  handleMouseOut = () => {
    this.setState((prevState) => ({
      hoveringPresetCard: null,
    }));
  };

  handleResize = (e) => {
    let cardWidth = window.innerWidth > 650 ? 150 : 100;
    let paddingBetweenCards = window.innerWidth > 650 ? 25 : 20;
    let offsetToFitArrows = window.innerWidth > 650 ? 100 : 50;

    this.setState({
      width: ('container' in this && this.container && this.container.offsetWidth) || 875,
      cardWidth,
      paddingBetweenCards,
      offsetToFitArrows,
    });
  };

  prevFilterList = () => {
    const event = 'Clicked Previous Filter Preset Page';
    logMetricsTrackingEvent(event, { Page: this.state.cardRowDisplaying })();

    this.setState({ cardRowDisplaying: this.state.cardRowDisplaying - 1 });
  };

  nextFilterList = () => {
    const event = 'Clicked Next Filter Preset Page';
    logMetricsTrackingEvent(event, { Page: this.state.cardRowDisplaying })();

    this.setState({ cardRowDisplaying: this.state.cardRowDisplaying + 1 });
  };

  cardsInARow = () => {
    return Math.floor(
      (this.state.width - this.state.offsetToFitArrows) / (this.state.cardWidth + this.state.paddingBetweenCards)
    );
  };

  determineCardPosition = (i, row) => {
    const rowWidth = this.state.width;
    const cardWidthTotalSpace = this.state.cardWidth + this.state.paddingBetweenCards;
    const cardsCanFitInRow = this.cardsInARow();

    const startingOffset = (rowWidth - cardsCanFitInRow * cardWidthTotalSpace) / 2 + this.state.paddingBetweenCards / 2;
    const cardRowIndexPositions = [startingOffset];

    const addCardPositions = (array) => {
      for (let i = 1; i < cardsCanFitInRow; i++) {
        array.push(cardWidthTotalSpace * i + startingOffset);
      }
    };
    addCardPositions(cardRowIndexPositions);

    const cardRowIndex = i % cardsCanFitInRow;
    const cardRow = Math.floor(i / cardsCanFitInRow);
    const rowOffset = cardRow - row;
    const top = '25px';
    const left = `${cardRowIndexPositions[cardRowIndex] + rowOffset * rowWidth}px`;

    return { top, left };
  };

  render() {
    const filters = this.props.presetFilterList;
    const filterKeys = Object.keys(filters);

    return (
      <div
        className={'securities-filter-card-list-container'}
        ref={(el) => {
          this.container = el;
        }}
        style={this.props.presetFilterCardSize === 'small' ? { height: '100px' } : {}}
      >
        <div className="securities-filter-card-list-arrow-container securities-filter-card-list-left-arrow-container">
          {this.state.cardRowDisplaying !== 0 && (
            <IconButton
              customClass="circle-fill-button horizontal-list-change-page-btn"
              style={{ padding: '3px 5px 0 0' }}
              skinny
              showShadow
              icon="fa-chevron-left"
              handleClick={this.prevFilterList}
            />
          )}
        </div>

        <div className={'securities-filter-card-list'}>
          {filterKeys.map((filterName, i) => {
            return (
              <SecuritiesFilterCard
                key={`filter-${filterName}`}
                {...filters[filterName]}
                name={filterName}
                position={this.determineCardPosition(i, this.state.cardRowDisplaying)}
                size={this.props.presetFilterCardSize}
                handleClick={this.props.handleFilterCardClick}
                isHovered={this.state.hoveringPresetCard === filterName}
                handleMouseOver={this.handleMouseOver}
                handleMouseOut={this.handleMouseOut}
              />
            );
          })}
        </div>

        <div className="securities-filter-card-list-arrow-container securities-filter-card-list-right-arrow-container">
          {this.state.cardRowDisplaying < Math.ceil(filterKeys.length / this.cardsInARow()) - 1 && (
            <IconButton
              customClass="circle-fill-button horizontal-list-change-page-btn"
              style={{ padding: '3px 0 0 4px' }}
              skinny
              showShadow
              icon="fa-chevron-right"
              handleClick={this.nextFilterList}
            />
          )}
        </div>
      </div>
    );
  }
}

export default SecuritiesFilterCardList;
