import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/index';
import { returnPathTo } from '../../constants/paths';
import { REFERRAL_PAGE_HEADING_COPY_VERSIONS_BY_AB_GROUP } from './constants/RewardsCopy';
import { PRODUCT_DISPLAY_NAME, PRODUCT_NAME } from '@src/appConfig';
import Page from '../../components/layout/Page';
import PageLoading from '../../components/PageLoading';
import GradientLine from '../UI/GradientLine';
import UnredeemedRewardsRedemption from './UnredeemedRewardsRedemption';
import NvstrOptionsEarned from './components/NvstrOptionsEarned';
import ReferralCashRewardsDisclaimer from '../UI/Disclaimers/ReferralCashRewards';
import GmailImportButton from '../ImportConnections/ImportButtons/GmailImport';
import InviteFriends from '../People/InviteFriends';
import ShareLink from '../People/ShareLink';
import AvailableRewards from './components/AvailableRewards';
import { ReloadMessage } from '../Errors/ReloadMessage';
import RewardsProgress from './components/RewardsProgress';

import {
  isReferralProgramActive,
  referralRewardLimit,
  hasRandomReferralRewardAmount,
} from '../../helpers/currentUserHelpers';
import { REFERRAL_SHARE_LINK_CONTEXTS } from '../../main/hooks/user/useCurrentUserReferral';

class Heading extends React.PureComponent {
  constructor(props) {
    super();
    const { headingCopyTestGroup } = props;
    this.HEADING_COPY = REFERRAL_PAGE_HEADING_COPY_VERSIONS_BY_AB_GROUP[headingCopyTestGroup];
  }

  componentDidMount() {
    window.addEventListener('resize', this._handleResize);
  }

  componentDidUpdate() {
    this._handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleResize);
  }

  render() {
    const {
      isIneligibleForReferralProgram,
      showSubHeading,

      gradientWidth,
    } = this.props;

    if (isIneligibleForReferralProgram) {
      return null;
    }

    return (
      <div className={'rewards-page-heading'}>
        <h2 ref={(el) => (this.Heading = el)}>{this._returnHeadingText()}</h2>
        {showSubHeading && (
          <>
            <GradientLine width={gradientWidth} />
            {this._buildSubHeadingTextComponent()}
          </>
        )}
      </div>
    );
  }

  _handleResize = () => {
    const width = window.$(this.Heading).width();
    this.props.setGradientWidth(width);
  };

  _returnHeadingText = () => {
    const { didReachReferralRewardLimit, isRewardProgramActive } = this.props;
    if (didReachReferralRewardLimit) {
      return 'Congrats! You reached the maximum reward.';
    }
    if (!isRewardProgramActive) {
      return 'Redeem your rewards.';
    }
    return this.HEADING_COPY.heading;
  };

  _buildSubHeadingTextComponent = () => {
    return this.HEADING_COPY.subtext;
  };
}

class RewardsPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      gradientWidth: 750,
    };

    const createNoSubheadingTestGroup = () => {
      const roll = Math.random();
      if (roll < 0.5) {
        return 'a';
      } else {
        return 'b';
      }
    };

    this.SHARE_LINK_MESSAGE_TEST_GROUP = 'c';
    this.NO_PAGE_SUBHEADING_TEST_GROUP = createNoSubheadingTestGroup();
    this.HEADING_COPY_TEST_GROUP = 'a';

    this.TEST_GROUP_TRACKING_DATA = {
      'AB Test Group': this.HEADING_COPY_TEST_GROUP,
      'Share Link Message AB Test Group': this.SHARE_LINK_MESSAGE_TEST_GROUP,
      'No Subheading AB Test Group': this.NO_PAGE_SUBHEADING_TEST_GROUP,
    };
  }

  componentDidMount() {
    if (!this._isReferralProgramActive() && !this._hasRedeemableRewards()) {
      const path = returnPathTo('home');
      this.props.navigate(path);
    }

    document.title = `${PRODUCT_DISPLAY_NAME} - Referral Rewards`;
    this.props.actions.getRewardsData();
    this._logPageView();
  }

  render() {
    const { isAuthed } = this.props;
    if (!isAuthed) {
      return null;
    }
    if (this._isLoading()) {
      return (
        <div>
          <PageLoading loadingMessage="Getting Your Latest Rewards" />
        </div>
      );
    }
    if (!this._isLoaded()) {
      return null;
    }
    if (this._wasError()) {
      return <ReloadMessage />;
    }
    return (
      <Page className={`rewards-page ${this._didUserReachReferralRewardLimit() ? 'reached-max-reward' : ''}`}>
        <Heading
          isIneligibleForReferralProgram={this._isUserIneligibleForReferralProgram()}
          showSubHeading={this.NO_PAGE_SUBHEADING_TEST_GROUP === 'a'}
          headingCopyTestGroup={this.HEADING_COPY_TEST_GROUP}
          didReachReferralRewardLimit={this._didUserReachReferralRewardLimit()}
          isRewardProgramActive={this._isReferralProgramActive()}
          gradientWidth={this.state.gradientWidth}
          setGradientWidth={this.setGradientWidth}
        />
        <UnredeemedRewardsRedemption
          isUserEligibleForRewards={!this._isUserIneligibleForReferralProgram()}
          hasReachedRewardLimit={this._didUserReachReferralRewardLimit()}
          unredeemedRewardsCount={this._returnRedeemableRewardsCount()}
          redeemedRewardsTotal={this._returnRedeemedRewardsTotal()}
          TEST_GROUP_TRACKING_DATA={this.TEST_GROUP_TRACKING_DATA}
        />
        {this._shouldShowMidPageBreak() && (
          <div className={'mid-page-break'}>
            <GradientLine width={this.state.gradientWidth} />
          </div>
        )}

        <div className={'referral-invite-section'}>
          {this._renderUserReferralSection()}
          <div className={'referral-section referral-last-section'}>
            {this._isReferralProgramActive() && (
              <div className={'referral-section-heading-number secondary-heading-text-color'}>2.</div>
            )}
            <AvailableRewards
              customHeadingText={this._isReferralProgramActive() ? null : 'Reward Chances'}
              showHeading={this._isReferralProgramActive()}
              isIneligible={this._isUserIneligibleForReferralProgram()}
              reachedMaxReward={this._didUserReachReferralRewardLimit()}
              hideOdds={this._doesUserGetFixedReferralRewardAmount()}
            />
          </div>
          <NvstrOptionsEarned />
          <ReferralCashRewardsDisclaimer />
        </div>
      </Page>
    );
  }

  _renderUserReferralSection = () => {
    if (!this._isReferralProgramActive()) {
      return null;
    }

    return (
      <div className={'referral-section'}>
        <div className={'referral-section-heading'}>
          <div className={'referral-section-heading-number secondary-heading-text-color'}>{'1.'}</div>
          <div>{'Share your invite link:'}</div>
        </div>

        <div className={'referrals-share-link-container'}>
          <ShareLink
            shareContext={'Rewards Page'}
            additionalPropertiesForEventLogging={this.TEST_GROUP_TRACKING_DATA}
            shareLinkMessageABTestGroup={this.SHARE_LINK_MESSAGE_TEST_GROUP}
          />
        </div>

        <div>
          <GmailImportButton />
        </div>

        <div>
          <InviteFriends
            placeholder={"Or enter a friend's email to invite"}
            inviteContext={'Rewards Page'}
            shareLinkContext={REFERRAL_SHARE_LINK_CONTEXTS.REWARDS_SCREEN}
            additionalPropertiesForEventLogging={this.TEST_GROUP_TRACKING_DATA}
            hideHeading
          />
        </div>
        <div style={{ paddingBottom: '12px' }} />
        {!this._isUserIneligibleForReferralProgram() && !this._didUserReachReferralRewardLimit() && <RewardsProgress />}
      </div>
    );
  };

  setGradientWidth = (width) => {
    const didWidthChange = width !== this.state.gradientWidth;
    if (didWidthChange && width > 0) {
      this.setState(() => ({
        gradientWidth: width,
      }));
    }
  };

  _isLoaded = () => this._returnRewardsData().loaded;

  _isLoading = (props) => this._returnRewardsData(props).loading;

  _wasError = () => this._returnRewardsData().wasError || this.state._wasError;

  _shouldShowMidPageBreak = () =>
    this._didUserReachReferralRewardLimit() || this._hasRedeemableRewards() || this._returnRedeemedRewardsTotal() > 0;

  _returnMaxPrizeLimit = () => referralRewardLimit(this._returnCurrentUser());
  _didUserReachReferralRewardLimit = () => this._returnRedeemedRewardsTotal() >= this._returnMaxPrizeLimit();

  _returnRewardsData = (props = this.props) => props.rewards;

  _returnRedeemableReferralRewardsCount = () => this._returnRewardsData().referral_rewards_not_redeemed_count || 0;
  _returnRedeemableFundingRewardsCount = () => this._returnRewardsData().funding_rewards_not_redeemed_count || 0;
  _returnRedeemableRewardsCount = () =>
    this._returnRedeemableReferralRewardsCount() + this._returnRedeemableFundingRewardsCount();
  _hasRedeemableRewards = () => this._returnRedeemableRewardsCount() > 0;

  _returnRedeemedRewardsTotal = () => this._returnRewardsData().referral_reward_total_redeemed;

  _returnCurrentUser = () => this.props.currentUser;

  _isReferralProgramActive = () => isReferralProgramActive(this._returnCurrentUser());

  _isUserIneligibleForReferralProgram = () => !this._returnCurrentUser().is_eligible_for_referral_program;

  _doesUserGetFixedReferralRewardAmount = () => !hasRandomReferralRewardAmount(this._returnCurrentUser());

  _logPageView = () => {
    const event = 'View Referral Program';
    const properties = {
      ...this.TEST_GROUP_TRACKING_DATA,
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };
}

const mapStateToProps = (state) => {
  const currentUser = state.currentUser;
  return {
    isAuthed: 'user_id' in currentUser,
    currentUser,
    rewards: state.rewards,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardsPage);
