import React from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../actions';

import Button from '../buttons/Button';
import { FlatButton } from '@src/main/components/buttons';

class BasicErrorModal extends React.PureComponent {
  render() {
    const { message, withOkay } = this.props;

    return (
      <div>
        <div className="modal-message" style={{ paddingTop: '0px' }}>
          {message}
        </div>
        {withOkay && (
          <div className="modal-custom-button">
            <FlatButton onClick={this.handleDismiss}>Okay</FlatButton>
          </div>
        )}
      </div>
    );
  }

  handleDismiss = () => {
    const { dispatch } = this.props;
    hideModal()(dispatch);
  };
}

export default connect()(BasicErrorModal);
