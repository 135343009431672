import { parseQueryString } from '../helpers/routerHelpers';
import { toCapitalCase } from '../helpers/generalHelpers';

// used for routes that do not have params
const _directMatchRouteToContextDataLookup = {
  '/': {
    getPageContextName: () => {
      const query = parseQueryString(window.location.search);
      if (query.tab === 'positions') {
        return 'Dashboard Positions';
      } else if (query.tab === 'feed') {
        return 'Dashboard Feed';
      } else {
        return 'Dashboard';
      }
    },
  },

  '/orders': {
    getPageContextName: () => 'Trade Page',
  },

  '/ideas/portfolio_explorer': {
    getPageContextName: () => 'Portfolio Explorer',
  },
  '/ideas/community': {
    getPageContextName: () => 'Community Ideas Page',
  },
  '/ideas': {
    getPageContextName: () => 'My Ideas Page',
  },

  '/portfolio': {
    getPageContextName: () => 'Portfolio Page',
  },

  '/portfolio/history': {
    getPageContextName: () => 'History Page',
  },

  '/portfolio/funding': {
    getPageContextName: () => 'Funding Page',
  },

  '/account_type': {
    getPageContextName: () => 'Account Type Selection Page',
  },

  '/investing_experience': {
    getPageContextName: () => 'Investing Experience Form Page',
  },

  '/live_accounts/new': {
    getPageContextName: () => 'Live Account Signup Start',
  },

  '/live_accounts/confirmation': {
    getPageContextName: () => 'Live Account Signup Confirmation Page',
  },

  '/live_accounts/agreements': {
    getPageContextName: () => 'Live Account Signup Agreements Form Page',
  },

  '/live_accounts/disclosures': {
    getPageContextName: () => 'Live Account Signup Disclosures Form Page',
  },

  '/live_accounts/identity': {
    getPageContextName: () => 'Live Account Signup Identity Form Page',
  },

  '/live_accounts/contact': {
    getPageContextName: () => 'Live Account Signup Contact Form Page',
  },

  '/live_accounts/investor_profile': {
    getPageContextName: () => 'Live Account Signup Investor Profile Form Page',
  },

  '/users/edit': {
    getPageContextName: () => 'Edit Profile Page',
  },

  '/users/connections': {
    getPageContextName: () => 'Connections Page',
  },

  '/users/thought_leaders': {
    getPageContextName: () => 'List Thought Leaders Page',
  },

  '/referrals': {
    getPageContextName: () => 'Referral Rewards Page',
  },

  '/ask': {
    getPageContextName: () => 'Ask Page',
  },

  '/notifications': {
    getPageContextName: () => 'Activity Page',
  },

  '/faq': {
    getPageContextName: () => 'FAQ Page',
  },

  '/invites/provider': {
    getPageContextName: () => 'Invite Contacts Choose Provider Page',
  },

  '/contacts/failure': {
    getPageContextName: () => 'Contacts Import Failure Page',
  },

  '/documents': {
    getPageContextName: () => 'Live Account Documents Page',
  },

  '/about': {
    getPageContextName: () => 'About Page',
  },

  '/legal': {
    getPageContextName: () => 'Legal Page',
  },

  '/margin_disclosure': {
    getPageContextName: () => 'Margin Disclosure Page',
  },

  '/privacy_policy': {
    getPageContextName: () => 'Privacy Policy Page',
  },

  '/terms_of_service': {
    getPageContextName: () => 'Terms Of Service Page',
  },

  '/terms_of_service_nvstr_financial': {
    getPageContextName: () => 'Terms Of Service Page',
  },

  '/terms_of_service_nvstr_technologies': {
    getPageContextName: () => 'Terms Of Service Page',
  },

  '/referral_rules': {
    getPageContextName: () => 'Referral Rules Page',
  },

  '/login': {
    getPageContextName: () => 'Sign In Form',
  },

  '/subscriptions': {
    getPageContextName: () => 'Subscriptions Signup Page',
  },

  '/membership': {
    getPageContextName: () => 'Paper Membership Signup Page',
  },
};

const _panelRouteToContextData = [
  {
    panelKey: 'security',
    getPageContextName: () => 'Security Panel',
  },
  {
    panelKey: 'ideas',
    getPageContextName: () => 'My Ideas Panel',
  },
  {
    panelKey: 'idea',
    getPageContextName: () => 'Idea Panel',
  },
  {
    panelKey: 'editUserAccount',
    getPageContextName: () => 'Edit Live Account Form Panel',
  },
  {
    panelKey: 'defaultExpectedReturn',
    getPageContextName: () => 'Default Expected Return Form Panel',
  },
  {
    panelKey: 'add-etfs-to-portfolio',
    getPageContextName: () => 'Add ETFs Form Panel',
  },
  {
    panelKey: 'interests',
    getPageContextName: () => 'Select Interests Panel',
  },
  {
    panelKey: 'watchlist',
    getPageContextName: () => 'Watchlist Panel',
  },
];

// used if a param is in url such as userId or securityId
const _indirectMatchRouteToContextData = [
  {
    pathStringToMatch: 'public_profile',
    getPageContextName: () => {
      const urlQuery = window.location.search;
      const query = parseQueryString(urlQuery);
      if (query['is-collective']) {
        return 'Collective Profile';
      } else if (query['is-thought-leader']) {
        return 'Thought Leader Profile';
      } else {
        return 'User Profile';
      }
    },
  },
  {
    pathStringToMatch: 'contact_callback',
    getPageContextName: () => `Import ${toCapitalCase(window.location.pathname.split('/')[2])} Contacts Page`,
  },
  {
    pathStringToMatch: 'contact_failure',
    getPageContextName: () => `Import ${toCapitalCase(window.location.pathname.split('/')[2])} Contacts Failure Page`,
  },
  {
    pathStringToMatch: 'securities',
    getPageContextName: () => 'Securities Page',
  },
];

export const getPageNameFromPathname = (pathname) => {
  let context = null;
  // const formattedPathname = pathname.split('/app')[1];
  // console.log([pathname, formattedPathname]);
  const directMatchRouteData = _directMatchRouteToContextDataLookup[pathname];
  if (directMatchRouteData) {
    context = directMatchRouteData.getPageContextName();
  }
  return context;
};

export const getPageContextEventDataFromLocation = () => {
  const { pathname, search } = window.location;
  const formattedPathname = pathname.split('/app')[1];
  const query = parseQueryString(search);
  const openPanelName = query.panel;

  let context = 'Unknown Page Context';

  if (openPanelName) {
    // check for panel
    _panelRouteToContextData.forEach((panelRouteData) => {
      if (panelRouteData.panelKey === openPanelName) {
        context = panelRouteData.getPageContextName();
      }
    });
  }

  if (context === 'Unknown Page Context') {
    // check direct pathname match
    const directMatchRouteData = _directMatchRouteToContextDataLookup[formattedPathname];
    if (directMatchRouteData) {
      context = directMatchRouteData.getPageContextName();
    }
  }
  if (context === 'Unknown Page Context') {
    // check indirect match
    _indirectMatchRouteToContextData.forEach((pathRouteData) => {
      if (formattedPathname?.includes(pathRouteData.pathStringToMatch)) {
        context = pathRouteData.getPageContextName();
      }
    });
  }

  // else log unknown, include location data
  if (context !== 'Unknown Page Context') {
    return {
      'Page Context': context,
    };
  } else {
    return {
      'Page Context': context,
      'Page Pathname': formattedPathname,
      'Page Url Query': search,
    };
  }
};

export const PROFILE_CONTEXTS = {
  MUTUAL_CONNECTIONS: 'mutual connections',
  SEARCH: 'search',
  SUGGESTED_CONNECTION: 'suggested connection',
  CONNECTIONS: 'connections',
  TOP_PERFORMERS: 'top performers',

  PREVIEW_PROFILE: 'preview profile',

  NOTIFICATION: 'notification',
  THOUGHT: 'thought',
  THOUGHT_BANNER: 'thought banner',
  IDEA: 'idea',

  VIEWPOINT_SUBSCRIPTIONS_LIST: 'viewpoint subscriptions list',
};
