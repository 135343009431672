import React from 'react';

import UserContainer from '../../users/UserContainer';
import UserName from '../../../components/user/UserName';
import LoadingIcon from '../../../components/misc/LoadingIcon';

import { throwError } from '../../../helpers/devToolHelpers';

export const IdeasListDataWrapperDisplay = (props) => {
  const {
    className,

    ideas,
    filters,
    thoughtLeaderListByUserId,
    connectionsListByUserId,

    isLoading,
    hideIfNA,
    renderInLabel,
    containerClassName,
    labelClassName,
    labelText,
    valueClassName,
  } = props;

  const filterIdeasForThoughtLeaders = (ideas) => {
    return ideas.filter((idea) => thoughtLeaderListByUserId.includes(idea.user_id));
  };

  const filterIdeasForConnections = (ideas) => {
    return ideas.filter((idea) => connectionsListByUserId.includes(idea.user_id));
  };

  const filterIdeas = (filters, ideas) => {
    if (filters[0] === 'thought_leaders') {
      return filterIdeasForThoughtLeaders(ideas);
    }
    if (filters[0] === 'connections') {
      return filterIdeasForConnections(ideas);
    }
    throwError('Unknown filter supplied', true);
  };

  const ideasToDisplayList = filters ? filterIdeas(filters, ideas) : ideas;

  const renderValues = () => {
    return (
      <div className={`ideas-list-data-display-container ${className || ''}`}>
        {ideasToDisplayList.map((idea) => (
          <UserContainer key={`ideas-list-data-display-${idea.user_id}-${idea.idea_id}`} userId={idea.user_id}>
            <UserName displayNameStyle="fullName" withLink={false} useYou />
          </UserContainer>
        ))}
      </div>
    );
  };

  if (isLoading) {
    return <LoadingIcon icon="fading-3balls" size="small" />;
  }

  if (hideIfNA && ideasToDisplayList.length === 0) {
    return null;
  }

  if (renderInLabel) {
    return (
      <div className={`${containerClassName}`}>
        <div className={`${labelClassName}`}>{labelText}</div>
        <div className={`${valueClassName}`}>{renderValues()}</div>
      </div>
    );
  } else {
    return renderValues();
  }
};
