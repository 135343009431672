import React from 'react';
import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import { getTextColorTypeBasedOnValue } from '../../helpers/securityPriceHelpers';

class QueuedAnimation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _animationPlayCount: 0,
      _appendClassString: null,
      _displayValue: this.getValue(props),
    };
    this._animationQueue = [];
    this._animationTimeout = null;
  }

  // assuming animation duration is under this amount, if not this will need to receive a prop
  animationLength = 1000;

  componentDidUpdate(prevProps, prevState) {
    if (this.didValueChange(prevProps)) {
      this.onValueChange();
    }
  }

  componentWillUnmount() {
    const animationTimeout = this._animationTimeout;
    if (animationTimeout) {
      clearTimeout(animationTimeout);
    }
  }

  onValueChange = () => {
    const animation = this.createAnimation();
    this.addAnimationToQueue(animation);
    this.attemptPlayAnimation();
  };

  attemptPlayAnimation = () => {
    if (!this.getIsAnimating()) {
      this.runAnimation();
    }
  };

  runAnimation = () => {
    const queue = this.getAnimationQueue();
    if (queue.length > 0 && !this.getIsAnimating()) {
      const animation = this.getNextAnimationForPlayback();
      this.startAnimation(animation);
      this.createOnAnimationFinishTimeout();
    }
  };

  createOnAnimationFinishTimeout = () => {
    return setTimeout(this.handleFinishAnimation, this.animationLength);
  };

  handleFinishAnimation = () => {
    const queue = this.getAnimationQueue();
    if (queue.length > 0) {
      this.stopAnimation();
      setTimeout(this.runAnimation, 25);
    } else {
      this.stopAnimation();
    }
  };

  render() {
    return this.props.children(this.getDisplayValue(), this.getAnimationClass(), this.getIsIncreasing());
  }

  stopAnimation = () => {
    this.clearIsAnimating();
  };

  startAnimation = (animation) => {
    const { value, textColorClass } = animation;
    this.setState((prevState) => {
      return {
        _isAnimating: true,
        _animationPlayCount: prevState._animationPlayCount + 1,
        _isIncreasing: prevState._displayValue < value,
        _appendClassString: textColorClass,
        _displayValue: value,
      };
    });
  };

  clearIsAnimating = () => this.setState(() => ({ _isAnimating: false }));

  createAnimation = () => {
    const textColorValue = this.getTextColorTypeValue();
    return {
      value: this.getValue(),
      textColorClass: !isUndefinedOrNull(textColorValue) ? getTextColorTypeBasedOnValue(textColorValue) : '',
    };
  };

  addAnimationToQueue = (animation) => {
    this._animationQueue = [...this._animationQueue, animation];
  };

  _clearQueue = () => (this._animationQueue = []);

  getNextAnimationForPlayback = () => {
    const queue = this.getAnimationQueue();
    const animation = queue[queue.length - 1];
    this._clearQueue();
    return animation;
  };

  didValueChange = (prevProps) => {
    return this.getValue(prevProps) !== this.getValue();
  };

  didQueueUpdate = (prevState) => {
    return this.getAnimationQueue(prevState) !== this.getAnimationQueue();
  };

  getAnimationQueue = () => this._animationQueue;

  getValue = (props = this.props) => props.value;

  getTextColorTypeValue = (props = this.props) => props.textColorTypeValue;

  getDisplayValue = (state = this.state) => state._displayValue;

  getAnimationPlayCount = (state = this.state) => state._animationPlayCount;

  getIsAnimating = (state = this.state) => state._isAnimating;

  getAnimationClass = () =>
    this.getIsAnimating()
      ? `${this.props.animationClass}${this.state._appendClassString ? `-${this.state._appendClassString}` : ''}`
      : '';

  getIsIncreasing = (state = this.state) => state._isIncreasing;
}

export default QueuedAnimation;
