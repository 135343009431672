import { rebalancerAdapter } from '../adapters/rebalancerAdapter';

import {
  OPEN_REBALANCER_PANEL,
  CLOSE_REBALANCER_PANEL,
  RUN_REBALANCER,
  NEW_REBALANCER_DATA_RECEIVED,
  REBALANCER_RUN_FAILED,
} from '../constants';
import { moment } from '../helpers/timeHelpers';

export const openRebalancerPanel = () => {
  return function (dispatch) {
    dispatch({
      type: OPEN_REBALANCER_PANEL,
    });
  };
};

export const closeRebalancerPanel = () => {
  window.$('html, body').animate(
    {
      scrollTop: 0,
    },
    300
  );

  return function (dispatch) {
    dispatch({
      type: CLOSE_REBALANCER_PANEL,
    });
  };
};

export const runRebalancer = (rebalancePositionsList, config) => {
  return function (dispatch) {
    const optimizerCallTimestamp = moment().valueOf();

    dispatch({
      type: RUN_REBALANCER,
      payload: {
        optimizerData: {
          summary_message: 'Rebalancer is analyzing portfolio',
        },
        optimizerCallTimestamp: optimizerCallTimestamp,
      },
    });

    return rebalancerAdapter.runRebalancer(rebalancePositionsList, config).then((response) => {
      const wasRequestSuccessful = response && response.data && response.data.rebalanced_positions_by_security_id;
      const optimizerData = wasRequestSuccessful ? response.data : { status: 'failed' };
      optimizerData.lastUpdated = optimizerCallTimestamp;

      if (wasRequestSuccessful) {
        dispatch({
          type: NEW_REBALANCER_DATA_RECEIVED,
          payload: {
            optimizerData,
            optimizerCallTimestamp: optimizerCallTimestamp,
          },
        });
      } else {
        dispatch({
          type: REBALANCER_RUN_FAILED,
          payload: { optimizerCallTimestamp },
        });
      }
      return response;
    });
  };
};

export const createRebalancerOrders = (rebalancePositionsList) => {
  return function (dispatch) {
    return rebalancerAdapter.createRebalancerOrders(rebalancePositionsList);
  };
};
