import React from 'react';
import { connect } from 'react-redux';
import VerticalNavItem from '../../components/VerticalNavItem';
import PortfolioAnalysisActionsCounter from '../../../../containers/Counters/PortfolioAnalysisActionsCounter';
import PieChartIcon from '@src/main/components/Images/PieChartIcon';
import { inAnalysisNavTabBadgeTestGroup, inAlternateAnalysisNavTabTestGroup } from '@src/helpers/currentUserHelpers';

const Analysis = (props) => {
  const { inShowBadgeTestGroup } = props;

  return (
    <VerticalNavItem
      name={'Portfolio Analysis'}
      customIcon={<PieChartIcon />}
      // customAdditionalElement={
      //   inShowBadgeTestGroup ? (
      //     <PortfolioAnalysisActionsCounter className="vertical-nav-notifications-counter notification-circle-color" />
      //   ) : null
      // }
      route="/portfolio"
    />
  );
};

const mapStateToProps = (state) => {
  const currentUser = state.currentUser;
  return {
    inShowBadgeTestGroup: inAnalysisNavTabBadgeTestGroup(currentUser),
  };
};

export default connect(mapStateToProps)(Analysis);
