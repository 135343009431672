import React from 'react';

import { NewConnectionRow } from './NewConnectionRow';
import { Timestamp } from '../../../../UI/Timestamp';
import MaxRowsList from '../../../../UI/MaxRowsList';

export const ConnectionAcceptedNotification = (props) => {
  const { notification } = props;
  const { users, timestamp } = notification;
  const connectionAcceptedUserIds = users.map((user) => user.user_id);
  return (
    <div className={`connection-accepted-notification-container`}>
      <div className={`new-connection-notification-message`}>
        {`Your connection request${users.length > 1 ? 's were' : ' was'} accepted`}
      </div>
      <div className={`new-connections-list notification-indent`}>
        <MaxRowsList
          ids={connectionAcceptedUserIds}
          containerStyles={{
            padding: '5px 23px', // used to indent past avatar
          }}
        >
          {(ids) => (
            <table>
              <tbody>
                {ids.map((userId) => (
                  <NewConnectionRow key={`new-connection-notif-row-${userId}`} userId={userId} />
                ))}
              </tbody>
            </table>
          )}
        </MaxRowsList>
      </div>
      <Timestamp className={'notification-timestamp'} epochTime={timestamp} />
    </div>
  );
};
