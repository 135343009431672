import React from 'react';
import styled from 'styled-components';
import { fetchAnnualMembershipEligibility, logMetricsTrackingEvent } from '../../../actions';
import { Page } from '../../components/layout';
import { LoadingPage } from '../../components/page';
import FundingBonusBannerRenderer from '../../../components/banners/FundingBonusBannerRenderer';
import { useCurrentUser, usePromotions } from '../../hooks/user';
import { DepositForm } from '../../containers/forms/DepositForm';
import { LinkBankPlaid } from '../funding/LinkBankPlaid';
import { H5 } from '../../lib/nvstr-common-ui.es';
import { ONBOARDING_PATHS, ONBOARDING_STATES } from '../../constants/onboarding';
import { FlatButton } from '../../components/buttons';
import { useDispatch } from 'react-redux';
import { LocationContext } from '../../../context';
import { TRACKING_ROUTE_NAMES } from '../../lib/nvstr-utils.es';

const ContentWrapper = styled.div`
  max-width: 416px;
  margin: 0 auto;
`;
const Heading = styled.div`
  margin: 0 auto;
  padding-top: 8px;

  h5 {
    margin: 0;
    line-height: 1.3;
  }
`;
const BannerStyle = styled.div`
  margin: 8px auto 0 auto;
  border-radius: 5px;
`;
const Action = styled.div`
  padding-top: 8px;
  display: flex;
  justify-content: center;
`;

export const EnterDeposit = ({ onContinue, onPathChange, onboardingState }) => {
  const dispatch = useDispatch();
  const { isShowingFundingPromo } = usePromotions();
  const currentUser = useCurrentUser();
  const isInAllowSkipTestGroup = currentUser.hide_skip_deposit_in_onboarding_test_group === 0;
  const [isUsingDepositPicker] = React.useState(true);
  const [isReady] = React.useState(true);

  const [plaidReauthToken, setPlaidReauthToken] = React.useState(null);
  const [cachedFormValues, setCachedFormValues] = React.useState(null);

  const [wasApiFailure, setWasApiFailure] = React.useState(false);

  React.useEffect(() => {
    logMetricsTrackingEvent('View Bank Transfer Form')();
  }, []);

  const handleContinueClick = React.useCallback(async () => {
    await fetchAnnualMembershipEligibility()(dispatch);
    onContinue(null, onboardingState);
  }, []);

  const handleSkip = React.useCallback(() => {
    logMetricsTrackingEvent('Skipped Deposit Entry After Api Error')();
    onPathChange(ONBOARDING_PATHS.postFundingSteps, onboardingState, { nextState: ONBOARDING_STATES.redeemRewards });
  }, []);

  const onPlaidReauthRequired = React.useCallback(
    async (plaidAccountToken, formValues) => {
      setCachedFormValues(formValues);
      setPlaidReauthToken(plaidAccountToken);
    },
    [setCachedFormValues, setPlaidReauthToken]
  );

  const handleReauthContinue = React.useCallback(
    async (success) => {
      setPlaidReauthToken(null);
    },
    [setPlaidReauthToken]
  );

  const onApiFailure = React.useCallback(() => {
    setWasApiFailure(true);
  }, []);

  if (!isReady) return <LoadingPage />;

  if (plaidReauthToken !== null) {
    return (
      <LinkBankPlaid
        bankAccountId={cachedFormValues.account_id}
        onContinue={handleReauthContinue}
        reauthToken={plaidReauthToken}
      />
    );
  }

  return (
    <LocationContext.Provider value={TRACKING_ROUTE_NAMES.fundingTransferOnboarding}>
      <Page width={'600px'}>
        <ContentWrapper>
          <Heading>
            <H5>Add Funding</H5>
          </Heading>

          {isShowingFundingPromo && (
            <BannerStyle>
              <FundingBonusBannerRenderer />
            </BannerStyle>
          )}

          <DepositForm
            initialFormValues={cachedFormValues}
            showDepositPicker={isUsingDepositPicker}
            onContinue={handleContinueClick}
            onPlaidReauthRequired={onPlaidReauthRequired}
            onApiFailure={onApiFailure}
          />
          {(wasApiFailure || isInAllowSkipTestGroup) && (
            <Action>
              <FlatButton fullWidth transparent onClick={handleSkip}>
                Skip
              </FlatButton>
            </Action>
          )}
        </ContentWrapper>
      </Page>
    </LocationContext.Provider>
  );
};
