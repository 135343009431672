import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import { isUserThoughtLeader, returnUsersLookupFromUsersStore } from '../../helpers/usersHelpers';

/*
  passes data to children
    {
      ...this.props,
      thoughtLeaderListByUserId: this.returnThoughtLeaderListByUserId(),
      usersStore: this.returnUsersStore(),
    }

  <ThoughtLeadersDataWrapper
    getThoughtLeadersData -> bool -> ill get ideas on mount depending on ids supplied
    // future feature idea ->  filters -> array of filter type strings
  >
    {children}
  </ThoughtLeadersDataWrapper>

*/

class ThoughtLeadersDataWrapper extends Component {
  componentDidMount() {
    if (this.props.getThoughtLeadersData) {
      this._getThoughtLeadersData();
    }
  }

  render() {
    return (
      <span className={`basic-connections-list-data-wrapper`}>{this._renderChildrenWithConnectionDataProps()}</span>
    );
  }

  _renderChildrenWithConnectionDataProps = () => {
    return React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, this.returnThoughtLeaderDataProps())
    );
  };

  returnThoughtLeaderDataProps = () => ({
    ...this.props,

    thoughtLeaderListByUserId: this.returnThoughtLeaderListByUserId(),
    usersStore: this.returnUsersStore(),
  });

  returnThoughtLeaderListByUserId = (props) => {
    const usersLookup = returnUsersLookupFromUsersStore(this.returnUsersStore(props));
    return Object.keys(usersLookup)
      .filter((id) => isUserThoughtLeader(usersLookup[id]))
      .map((id) => parseInt(id, 10));
  };

  returnUsersStore = (props) => (props || this.props).users;

  _getThoughtLeadersData = () => {
    this.props.actions.fetchThoughtLeaders();
  };
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const component = connect(mapStateToProps, mapDispatchToProps)(ThoughtLeadersDataWrapper);

export default component;
