import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import OptimizeArrow from '@src/main/components/Images/OptimizeArrow';
import { openRebalancerPanel, logMetricsTrackingEvent, showModal } from '../../../actions';

import HighlightComponent from '../../UI/Tour/HighlightComponent';

import { doesCurrentUserHaveActiveIdeas } from '../../../helpers/currentUserHelpers';

export const ButtonWrap = styled('div')`
  & .optimizer-button-color-style {
    border-radius: 6px;
  }
`;

class RebalancerButton extends React.PureComponent {
  render() {
    return this.props.viewOptimizerMessage ? this._renderViewOptimizerButton() : this._renderNavOptimizerButton();
  }

  _renderNavOptimizerButton = () => {
    const rebalancerTopLine = this.generateButtonTopLineText();
    const rebalancerBottomLine = this.generateButtonBottomLineText();

    return (
      <ButtonWrap>
        <div onClick={this.handleClick}>
          <HighlightComponent tourStep={5} />
          <a
            id="optimize_button"
            className={'optimize optimizer-button-color-style'}
            style={{ textDecoration: 'none' }}
          >
            <div className="optimizer-button-body">
              <div className="optimizer-logo-spacer">
                <OptimizeArrow />
              </div>
              <div className="optimizer-text-container">
                <span className="top">{rebalancerTopLine}</span>
                <span ref={(el) => (this.bottomMessage = el)} className="bottom">
                  {rebalancerBottomLine}
                </span>
              </div>
            </div>
          </a>
        </div>
      </ButtonWrap>
    );
  };

  _renderViewOptimizerButton = () => {
    return (
      <div className={'view-optimizer-container'}>
        <div
          className={'optimize-link btn btn-secondary-color btn-small-tall'}
          style={{ textDecoration: 'none' }}
          onClick={this.handleClick}
        >
          View Rebalancer
        </div>
      </div>
    );
  };

  handleClick = () => {
    this._logClick();

    if (this.props.inVerticalNav) {
      this.props.closeNav();
    }

    if (!this._doesCurrentUserHaveActiveIdeas()) {
      if (!this._isLoadingIdeas()) {
        const route = '/ideas/portfolio_explorer';
        window.location = route;
        return true;
      }

      return false;
    }

    // HACK: To stop body from becoming scrollable when panel is open because closing nav makes it scrollable.
    // This can be implemented better when on only React frontend
    setTimeout(() => {
      openRebalancerPanel()(this.props.dispatch);
    }, 150);
  };

  _isLoadingIdeas = () => this.props.ideas.loadingCurrentUserIdeas;

  _currentUserHasNoEquity = () => this.props.currentUser.equity === 0;

  _doesCurrentUserHaveActiveIdeas = () =>
    doesCurrentUserHaveActiveIdeas(this.props.currentUser.user_id, this.props.ideas);

  generateButtonTopLineText = () => {
    const cachedTopText = 'Click to';

    const message = this.props.optimizer.data && this.props.optimizer.data.summary_message;
    const topMessage = message && message.split(' ').slice(0, 2).join(' ');

    if (this.props.optimizer.isOptimizerRunning) {
      return 'Rebalancer is';
    }

    if (!this._doesCurrentUserHaveActiveIdeas() && !this._isLoadingIdeas()) {
      return 'To Rebalance';
    } else if (!this.props.currentUser || this.props.currentUser.equity > 0) {
      return topMessage || cachedTopText || 'Click to';
    } else if (this._currentUserHasNoEquity()) {
      return 'To Rebalance';
    } else {
      return 'Click to';
    }
  };

  generateButtonBottomLineText = () => {
    const cachedBottomText = 'Rebalance';

    const message = this.props.optimizer.data && this.props.optimizer.data.summary_message;
    const bottomMessage = message && message.split(' ').slice(2, message.length).join(' ');

    if (this.props.optimizer.isOptimizerRunning) {
      return 'Analyzing Portfolio';
    }

    if (!this._doesCurrentUserHaveActiveIdeas() && !this._isLoadingIdeas()) {
      return 'Add Ideas';
    } else if (!this.props.currentUser || this.props.currentUser.equity > 0) {
      return bottomMessage || cachedBottomText || 'Rebalance';
    } else if (this._currentUserHasNoEquity()) {
      return 'Add Funding';
    } else {
      return 'Rebalance';
    }
  };

  createOpenRebalancerFailureMessage = () => {
    const generalComponent = (
      <div className="modal-message" style={{ paddingTop: '0px' }}>
        {'Could not run the rebalancer at this time. Please try again later.'}
      </div>
    );

    const modal = {
      contentComponent: generalComponent,
      dismissable: true,
    };
    showModal(modal)(this.props.dispatch);
  };

  _logNavButtonClick = () => {
    const event = 'Clicked Open Rebalancer Button';
    const properties = {
      Context: 'In Nav',
    };
    logMetricsTrackingEvent(event, properties)();
  };

  _logViewButtonClick = () => {
    const event = 'Clicked Open Rebalancer Button';
    const properties = {
      Context: 'In Dashboard',
    };
    logMetricsTrackingEvent(event, properties)();
  };

  _logClick = () => {
    if (this.props.viewOptimizerMessage) {
      this._logViewButtonClick();
    } else {
      this._logNavButtonClick();
    }
  };
}

const mapStateToProps = (state) => {
  return {
    ideas: state.ideas,
    optimizer: state.optimizer,
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps)(RebalancerButton);
