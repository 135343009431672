import { BASEURL } from './index';
import { sendRequest } from '../helpers/ajaxHelpers';

const URL = `${BASEURL}/api`;

export const viewpointAdapter = {
  fetchAllViewpointData: () => {
    const baseUrl = `${URL}/viewpoints`;
    return sendRequest('get', [baseUrl]).then((response) => response);
  },

  subscribeToViewpoint: ({ viewpointOrgId, isFree }) => {
    const body = {
      viewpoint_id: viewpointOrgId,
      is_free_expected: isFree,
    };
    const baseUrl = `${URL}/viewpoint_subs`;
    return sendRequest('post', [baseUrl, body]).then((response) => response);
  },

  cancelViewpointSubscription: (viewpointOrgId) => {
    const baseUrl = `${URL}/viewpoint_subs?viewpoint_id=${viewpointOrgId}`;
    return sendRequest('delete', [baseUrl]).then((response) => response);
  },

  // fetchStocksCovered: () => {
  //   const baseUrl = `${URL}/thoughts/securities_covered`;
  //   return sendRequest('get', [baseUrl])
  //     .then(response => response)
  // },
};
