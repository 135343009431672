import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import LoadingIcon from '../../components/misc/LoadingIcon';

import { isLoadingFundamentalData, formatSecurityPropertyValue } from '../../helpers/securitiesHelpers';
import { getFilterDisplayName } from '../../constants/finData';
import { throwError } from '../../helpers/devToolHelpers';
import { didFirstLevelPropsValueChange } from '../../helpers/generalHelpers';

class FundamentalData extends Component {
  componentDidMount() {
    if (!this.props.securityId) {
      throwError('No security id found');
    }
    if (!this.props.property) {
      throwError('No property for fundamental data found');
    }
  }

  shouldComponentUpdate(nextProps) {
    const excludeKeys = ['actions', 'fundamentalData'];
    if (didFirstLevelPropsValueChange(this.props, nextProps, excludeKeys)) {
      return true;
    }
    if (this.returnSecurityFundamentalData() != this.returnSecurityFundamentalData(nextProps)) {
      return true;
    }
    return false;
  }

  render() {
    if (this.isLoading()) {
      return <LoadingIcon icon="fading-3balls" size="small" />;
    }
    if (this.shouldNotRender()) {
      return null;
    }

    if (this.props.renderInLabel) {
      return (
        <div className={`${this.props.containerClassName}`}>
          <div className={`${this.props.labelClassName}`}>{this.props.labelText}</div>
          <div className={`${this.props.valueClassName}`}>{this._renderValue()}</div>
        </div>
      );
    } else {
      return this._renderValue();
    }
  }

  _renderValue = () => {
    const { customClass, customStyle } = this.props;
    return (
      <span className={customClass || ''} style={customStyle || {}}>
        {this.formatValue()}
      </span>
    );
  };

  isLoading = () =>
    isLoadingFundamentalData(this.returnSecurityId(), this.returnFundamentalDataStore()) ||
    !this.returnSecurityFundamentalData();

  shouldNotRender = () => this.props.hideIfNA && this.formatValue() === 'N/A';

  getValue = () =>
    this.returnSecurityFundamentalData()[this.returnSecurityProperty()] ||
    this.returnSecurityFundamentalData()[getFilterDisplayName(this.returnSecurityProperty())];

  formatValue = () => formatSecurityPropertyValue(this.getValue(), this.returnSecurityProperty());

  returnSecurityProperty = () => this.props.property;

  returnSecurityId = () => this.props.securityId;

  returnSecurityFundamentalData = (props) => this.returnFundamentalDataStoreLookup(props)[this.returnSecurityId()];

  returnFundamentalDataStoreLookup = (props) => this.returnFundamentalDataStore(props).securities;

  returnFundamentalDataStore = (props) => (props || this.props).fundamentalData;
}

const mapStateToProps = (state) => {
  return {
    fundamentalData: state.fundamentalData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FundamentalData);
