import React from 'react';

import { formatSecurityDataValue } from '../../helpers/securitiesHelpers';
import { convictionConversion } from '../../helpers/ideaHelpers';

const Rating = (props) => {
  const shouldBeFilled = (i, value) => {
    return i <= convictionConversion({ name: value.name });
  };

  const renderRatings = (totalCount) => {
    const name = props.name;
    const value = props.value;
    const array = [];

    for (let i = 0; i < totalCount; i++) {
      array.push(i);
    }

    return array.map((el, i) => (
      <div key={`rating-${name}-${i}`} name={name} className={`rating-circle-container`}>
        <div
          className={`rating-circle rating-circle-display
            ${shouldBeFilled(i, value) && 'rating-filled'}`}
        />
      </div>
    ));
  };

  const renderRatingToDescriptionText = () => {
    const name = props.name;
    const value = props.value;
    const displayValue = formatSecurityDataValue(value, name);
    const suffix = props.ratingDescriptionSuffix;
    return suffix ? displayValue + suffix : suffix;
  };
  return (
    <div className={`rating-container`}>
      {!props.hideRatingCircles && renderRatings(props.maxRating)}
      <div className="ratings-conversion ratings-conversion-display secondary-text-color">
        {renderRatingToDescriptionText()}
      </div>
    </div>
  );
};

export default Rating;
