import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../../../actions/index';

import Idea from './Idea';
import AdditionalIdeasContainer from '../../../../containers/IdeaLab/AdditionalIdeasContainer';

class IdeasList extends Component {
  constructor() {
    super();

    this.state = {
      ideaBeingHovered: null,
    };
  }

  render() {
    const limit = this.determineMaxProfilePics(window.innerWidth);
    const ideas = this.props.ideas;
    let visibleIdeas, additionalIdeas;
    if (ideas.length > limit) {
      visibleIdeas = ideas.slice(0, limit - 1);
      additionalIdeas = ideas.slice(limit - 1, ideas.length);
    } else {
      visibleIdeas = ideas.slice(0, limit + 1);
      additionalIdeas = [];
    }

    if (
      (this.props.position === 'short' && !this.props.reverse) ||
      (this.props.position === 'long' && this.props.reverse)
    ) {
      visibleIdeas = visibleIdeas.reverse();
    }
    const cssClass = this.props.style === 'responsive' ? 'responsive-ideas-list-container' : 'ideas-list-container';

    const renderAtBeginning = this.props.position === 'short' && !this.props.reverse;
    const renderAtEnd = this.props.position === 'long' || (this.props.position === 'short' && this.props.reverse);

    return (
      <div className={cssClass}>
        {renderAtBeginning && (
          <AdditionalIdeasContainer
            limit={limit}
            ideas={additionalIdeas}
            position={this.props.position}
            ideaBeingHovered={this.state.ideaBeingHovered}
            hideLabels={this.props.hideLabels}
            handleHover={this.handleHover}
            handleHoverEnd={this.handleHoverEnd}
            expandDirection={this.props.expandDirection}
            showIdeaTypeShadow={this.props.showIdeaTypeShadow}
          />
        )}
        {visibleIdeas.map((idea) => (
          <Idea
            key={`idea-${idea.user_id}`}
            userId={idea.user_id}
            idea={this.props.showIdeaTypeShadow && idea}
            hideLabels={this.props.hideLabels}
            showNameTag={idea.idea_id === this.state.ideaBeingHovered}
            handleHover={this.handleHover}
            handleHoverEnd={this.handleHoverEnd}
          />
        ))}
        {this.props.ideas.length === 0 && <div className="position-visualizer-idea-container"></div>}
        {renderAtEnd && (
          <AdditionalIdeasContainer
            limit={limit}
            ideas={additionalIdeas}
            position={this.props.position}
            hideLabels={this.props.hideLabels}
            ideaBeingHovered={this.state.ideaBeingHovered}
            handleHover={this.handleHover}
            handleHoverEnd={this.handleHoverEnd}
            expandDirection={this.props.expandDirection}
            showIdeaTypeShadow={this.props.showIdeaTypeShadow}
          />
        )}
      </div>
    );
  }

  determineMaxProfilePics = (windowSize) => {
    if (windowSize < 401) {
      return 2;
    }
    if (windowSize < 481) {
      return 3;
    } else if (windowSize < 581) {
      return 4;
    } else {
      return 5;
    }
  };

  handleHover = (ideaId) => {
    this.setState({ ideaBeingHovered: ideaId });
  };

  handleHoverEnd = () => {
    this.setState({ ideaBeingHovered: null });
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdeasList);
