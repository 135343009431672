import React from 'react';
import styled from 'styled-components';
import { useOptimizerData } from '@src/main/hooks/portfolio/useOptimizerData';
import OptimizerGraph from '@src/containers/Optimizer/OptimizerPanel/OptimizerGraph';
import { Body5, H5 } from '@src/main/lib/nvstr-common-ui.es';
import { DISPLAY_FORMAT_TYPES, formatValueTo } from '@src/main/utils/numbers';
import { Body1, Body4, H3 } from '../../lib/nvstr-common-ui.es';
import InfoIcon from '../../../containers/UI/InfoIcon';
import { isUndefinedOrNull } from '@src/helpers/generalHelpers';
import { securityDataFormatTable } from '@src/helpers/securitiesHelpers';
import {
  returnChangeAdverbalPrefix,
  returnChangeSymbolPrefix,
  returnChangeVerbalPrefix,
} from '@src/helpers/numberHelpers';
import { CloseButton, FlatButton } from '../../components/buttons';
import InvestmentObjectivesContainer from '../../../containers/InvestmentObjectives/InvestmentObjectivesContainer';
import MeasureSize from '@src/containers/ExtraFunctionalityComponents/MeasureSize';
import { useOptimizerStatus } from '../../hooks/portfolio/useOptimizerData';

const Wrapper = styled.div`
  position: relative;
  padding: 16px 24px;

  @media (max-width: 840px) {
    padding: 16px 16px;
  }
`;
const IsOptimizingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: -100000px;
  right: 0;
  cursor: not-allowed;
  cursor: progress;

  background: ${({ theme }) => theme.themeColors.appBackground};
  opacity: ${({ show }) => (show ? 0.8 : 0)};
  z-index: ${({ show }) => (show ? 100 : -1)};
`;
const ProjectedValueLabel = styled.div`
  * {
    font-weight: 800;
    letter-spacing: 0.4px;
  }
`;
const ProjectedValue = styled.div`
  padding-top: 10px;
  * {
    font-size: 28px;
    line-height: 36px;
    font-weight: 100;
    color: ${({ theme, isOptimized }) =>
      isOptimized ? theme.themeColors.positive : theme.themeColors.text} !important;
  }
`;
const ProjectedValueRisk = styled.div`
  padding-top: 5px;
`;
const ProjectedValuesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
`;
const OptimizerGraphWrapper = styled.div`
  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  margin-top: -12px;
  padding-left: 8px;
  padding-bottom: 16px;
`;
const OptimizerVisualizationsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  padding-top: 48px;

  @media (max-width: 840px) {
    display: block;

    ${OptimizerGraphWrapper} {
      padding-top: 24px;
      padding-left: 0;
      padding-bottom: 20px;
    }
  }
`;
const OptimizerDataOutputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
`;
const InfoIconWrapper = styled.div`
  position: relative;
  display: inline-block;
  path {
    fill: ${({ theme }) => theme.themeColors.secondaryText};
  }
`;

const Label = styled.div`
  * {
    letter-spacing: 0.8px;
  }
`;
const Description = styled.div`
  padding-top: 4px;
  * {
    font-size: 12px;
    line-height: 12px;
  }
`;
const OptimizedDelta = styled.div`
  margin-top: -2px;
  min-width: 90px;
`;
const OptimizedDeltaLabel = styled.div`
  * {
    font-size: 12px;
    line-height: 14px;
  }
`;
const ValueComparisonWrapper = styled.div`
  padding-top: 2px;
`;
const ValueComparison = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  * {
    color: ${({ theme }) => theme.themeColors.secondaryText};
  }
`;
const ValueLabel = styled.div`
  margin-right: 6px;
`;

const DataSegDescription = styled.div`
  width: 250px;
  min-width: 250px;
`;
const DataSegValue = styled.div``;
const DataSegmentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;

  @media (max-width: 440px) {
    width: 100%;
    justify-content: space-between;

    ${OptimizedDelta} {
      min-width: auto;
    }
    ${DataSegValue} {
      text-align: right;
      flex-grow: 1;

      * {
        white-space: nowrap;
      }
    }
    ${DataSegDescription} {
      width: auto;
      min-width: auto;
      flex-grow: 0;
    }
  }
`;
const NoBreak = styled.span`
  white-space: nowrap;
`;

const _formatPrice = (v) => {
  return formatValueTo(DISPLAY_FORMAT_TYPES.PRICE, v);
};

const DataSegment = ({ data }) => {
  const { key, description, infoIconWord, optimizedDeltaLabel, optimizedDelta, currentValue, optimizedValue } = data;
  return (
    <DataSegmentWrapper>
      <DataSegDescription>
        <InfoIconWrapper>
          <Label>
            <Body4 bold>{key}</Body4>
          </Label>
          <InfoIcon word={infoIconWord} style={{ top: '3px', right: '-18px' }} />
        </InfoIconWrapper>
        <Description>
          <Body5 isLowContrast thin>
            {description}
          </Body5>
        </Description>
        <ValueComparisonWrapper>
          <ValueComparison>
            <ValueLabel>
              {/*<Body5 thin>Current/Optimized: </Body5>*/}
              <Body5 thin>Current: </Body5>
            </ValueLabel>
            <NoBreak>
              <Body5 thin>{currentValue}</Body5>
              {/*<Body5 thin>{currentValue}/</Body5>*/}
              {/*<Body5 thin>{optimizedValue}</Body5>*/}
            </NoBreak>
          </ValueComparison>
        </ValueComparisonWrapper>
      </DataSegDescription>
      {null && (
        <DataSegValue>
          <OptimizedDelta>
            <Body1 bold>{optimizedDelta}</Body1>
          </OptimizedDelta>
          <OptimizedDeltaLabel>
            <Body5 thin>{optimizedDeltaLabel}</Body5>
          </OptimizedDeltaLabel>
        </DataSegValue>
      )}
    </DataSegmentWrapper>
  );
};

const FooterWrapper = styled.div`
  padding: 24px 0 0 0;

  @media (max-width: 840px) {
    padding: 8px 0 0 0;
  }
`;
const InvestmentObjectivesWrapper = styled.div`
  padding: ${({ isShowing }) => (isShowing ? '16px 24px' : '')};
`;
const CustomizeOptimizerButtonWrapper = styled.div`
  padding: 0 24px 12px 24px;

  button {
    padding: 8px 0;
  }
`;
const OpenSettingsHeader = styled.div`
  position: relative;
  padding: 8px 24px;
  border-top: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};

  button {
    position: absolute;
    top: 16px;
    right: 24px;
  }

  * {
    color: ${({ theme }) => theme.themeColors.text};
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;
const OptimizerOutputData = () => {
  const {
    portfolio_stats: { current: currentPortfolioStats, optimized: optimizedPortfolioStats, delta: portfolioDelta },
  } = useOptimizerData();
  const userHasFunding = !isUndefinedOrNull(currentPortfolioStats.total_value) && currentPortfolioStats.total_value > 0;

  const currentPortfolioProjectedValue = currentPortfolioStats.projected_value || 0;
  const optimizedPortfolioProjectedValue = optimizedPortfolioStats.projected_value || 0;

  const currentPortfolioTotalValue = currentPortfolioStats.total_value || 0;
  const currentProjectedReturnPercentage = currentPortfolioStats.profit_loss_percentage || 0;
  const optimizedPortfolioTotalValue = optimizedPortfolioStats.total_value || 0;
  const optimizedProjectedReturnPercentage = optimizedPortfolioStats.profit_loss_percentage || 0;

  const currentProjectedReturnValue = currentPortfolioProjectedValue - currentPortfolioTotalValue;
  const optimizedProjectedReturnValue = optimizedPortfolioProjectedValue - optimizedPortfolioTotalValue;

  // const currentProjectedReturn = userHasFunding
  //   ? securityDataFormatTable.price(currentProjectedReturnValue)
  //   : securityDataFormatTable.percentage(currentProjectedReturnPercentage);
  // const optimizedProjectedReturn = userHasFunding
  //   ? securityDataFormatTable.price(optimizedProjectedReturnValue)
  //   : securityDataFormatTable.percentage(optimizedProjectedReturnPercentage);
  // const returnDifferenceVsCurrent = portfolioDelta.profit_loss || 0;
  // const displayReturnDifferenceVsCurrent = `${returnChangeSymbolPrefix(
  //   returnDifferenceVsCurrent
  // )}${securityDataFormatTable.price(Math.abs(returnDifferenceVsCurrent))}`;
  // const returnsMessage = `${returnChangeVerbalPrefix(returnDifferenceVsCurrent)} Returns`;

  const currentRiskValue = currentPortfolioStats.risk || 0;
  const currentRiskPercentage = currentPortfolioStats.risk_percentage || 0;
  const optimizedRiskValue = optimizedPortfolioStats.risk || 0;
  const optimizedRiskPercentage = optimizedPortfolioStats.risk_percentage || 0;
  const riskDifferenceVsCurrent = optimizedRiskValue - currentRiskValue;

  const currentRisk = userHasFunding
    ? securityDataFormatTable.price(currentRiskValue)
    : securityDataFormatTable.percentage(currentRiskPercentage);
  const optimizedRisk = userHasFunding
    ? securityDataFormatTable.price(optimizedRiskValue)
    : securityDataFormatTable.percentage(optimizedRiskPercentage);

  const displayRiskDifferenceVsCurrent = `${returnChangeSymbolPrefix(
    riskDifferenceVsCurrent
  )}${securityDataFormatTable.price(Math.abs(riskDifferenceVsCurrent))}`;
  const riskMessage = `${returnChangeVerbalPrefix(riskDifferenceVsCurrent)} Risk`;

  const optimizedDividendIncomePercentage = optimizedPortfolioStats.expected_dividends_percentage;
  const displayOptimizedDividendIncomePercentage = securityDataFormatTable.percentage(
    optimizedPortfolioStats.expected_dividends_percentage
  );

  const currentDividendIncome = userHasFunding
    ? securityDataFormatTable.price(currentPortfolioStats.expected_dividends)
    : '0%';

  const optimizedDividendIncome = userHasFunding
    ? securityDataFormatTable.price(optimizedPortfolioStats.expected_dividends)
    : displayOptimizedDividendIncomePercentage;

  const dividendMessage = userHasFunding
    ? `${returnChangeVerbalPrefix(portfolioDelta.expected_dividends)} Dividends`
    : `${returnChangeVerbalPrefix(optimizedDividendIncomePercentage)} Dividends`;

  const estimatedChangeInDividends = userHasFunding
    ? `${returnChangeSymbolPrefix(portfolioDelta.expected_dividends)}${securityDataFormatTable.price(
        Math.abs(portfolioDelta.expected_dividends)
      )}`
    : `${returnChangeSymbolPrefix(optimizedDividendIncomePercentage)}${securityDataFormatTable.percentage(
        Math.abs(optimizedDividendIncomePercentage)
      )}`;

  const estimatedChangeInBeta = `${returnChangeSymbolPrefix(portfolioDelta.beta)}${securityDataFormatTable.float(
    Math.abs(portfolioDelta.beta)
  )}`;
  const currentBeta = securityDataFormatTable.float(currentPortfolioStats.beta);
  const optimizedBeta = securityDataFormatTable.float(optimizedPortfolioStats.beta);
  const betaMessage = `${returnChangeAdverbalPrefix(estimatedChangeInBeta)} Beta`;

  const dataSegments = [
    // {
    //   key: 'Projected Return',
    //   description: 'Projected 1-year change in portfolio value',
    //   infoIconWord: 'tooltip_projected_return',
    //   optimizedDeltaLabel: returnsMessage,
    //   optimizedDelta: displayReturnDifferenceVsCurrent,
    //   currentValue: currentProjectedReturn,
    //   optimizedValue: optimizedProjectedReturn,
    // },
    {
      key: 'Portfolio Volatility',
      description: <>Standard deviation of projected&nbsp;returns&nbsp;($)</>,
      infoIconWord: 'tooltip_portfolio_volatility',
      optimizedDeltaLabel: riskMessage,
      optimizedDelta: displayRiskDifferenceVsCurrent,
      currentValue: currentRisk,
      optimizedValue: optimizedRisk,
    },
    {
      key: 'Projected Dividends',
      description: <>Projected dividend income&nbsp;over&nbsp;1&nbsp;year</>,
      infoIconWord: 'tooltip_estimated_dividends',
      optimizedDeltaLabel: dividendMessage,
      optimizedDelta: estimatedChangeInDividends,
      currentValue: currentDividendIncome,
      optimizedValue: optimizedDividendIncome,
    },
    {
      key: 'Market Beta',
      description: <>Exposure to broad stock&nbsp;market</>,
      infoIconWord: 'tooltip_market_beta',
      optimizedDeltaLabel: betaMessage,
      optimizedDelta: estimatedChangeInBeta,
      currentValue: currentBeta,
      optimizedValue: optimizedBeta,
    },
  ];

  return dataSegments.map((d) => <DataSegment key={d.key} data={d} />);
};

const NotLoadedWrapper = styled.div`
  padding: 96px;
`;
export const Optimizer = () => {
  const data = useOptimizerData();
  const isRunning = useOptimizerStatus();
  const [graphWidth, setGraphWidth] = React.useState(0);
  const [showOptimizerSettings, setShowOptimizerSettings] = React.useState(false);

  if (!data || !data?.portfolio_stats) {
    return <NotLoadedWrapper />;
  }

  const { lastUpdated, portfolio_stats, summary_message } = data;
  const { current, delta, optimized, risk_confidence_multiplier } = portfolio_stats;
  const {
    risk: currentRisk,
    profit_loss: currentProfitLoss,
    total_value: currentTotalValue,
    risk_confidence_min: currentRiskConfMin,
  } = current;
  const {
    risk: optimizedRisk,
    profit_loss: optimizedProfitLoss,
    total_value: optimizedTotalValue,
    risk_confidence_min: optimizedRiskConfMin,
  } = optimized;

  const optimizerPortfolioData = {
    current_profit: currentProfitLoss,
    current_risk: currentRisk,
    current_value: currentTotalValue,
    optimized_profit: optimizedProfitLoss,
    optimized_risk: optimizedRisk,
    risk_confidence_multiplier: risk_confidence_multiplier || 0.674489750196082,
  };

  const handleAdvancedSettingsClick = () => setShowOptimizerSettings(true);
  const handleCloseAdvancedSettingsClick = () => setShowOptimizerSettings(false);

  const handleMutation = (sizing) => {
    setGraphWidth(sizing.width);
  };
  return (
    <>
      <Wrapper>
        <IsOptimizingOverlay show={isRunning} />
        <ProjectedValuesWrapper>
          <div>
            <ProjectedValueLabel>
              <Body5 thin>Current Projected Value</Body5>
            </ProjectedValueLabel>
            <ProjectedValue>
              <H3>{_formatPrice(currentTotalValue + currentProfitLoss)}</H3>
            </ProjectedValue>
            <ProjectedValueRisk>
              <Body5 thin isLowContrast>
                75% chance of being above {_formatPrice(currentRiskConfMin)}
              </Body5>
            </ProjectedValueRisk>
          </div>
          {null && (
            <div>
              <ProjectedValueLabel>
                <Body5 thin isLowContrast>
                  Optimized Projected Value
                </Body5>
              </ProjectedValueLabel>
              <ProjectedValue isOptimized>
                <H3>{_formatPrice(optimizedTotalValue + optimizedProfitLoss)}</H3>
              </ProjectedValue>
              <ProjectedValueRisk>
                <Body5 thin isLowContrast>
                  75% chance of being above {_formatPrice(optimizedRiskConfMin)}
                </Body5>
              </ProjectedValueRisk>
            </div>
          )}
        </ProjectedValuesWrapper>
        <OptimizerVisualizationsWrapper>
          <OptimizerDataOutputWrapper>
            <OptimizerOutputData />
          </OptimizerDataOutputWrapper>
          <MeasureSize onMutation={handleMutation} resize>
            <OptimizerGraphWrapper>
              <OptimizerGraph optimizerPortfolioData={optimizerPortfolioData} panelWidth={graphWidth} />
            </OptimizerGraphWrapper>
          </MeasureSize>
        </OptimizerVisualizationsWrapper>
      </Wrapper>
      {null && (
        <FooterWrapper>
          {showOptimizerSettings ? (
            <OpenSettingsHeader>
              <CloseButton onClick={handleCloseAdvancedSettingsClick} />
            </OpenSettingsHeader>
          ) : (
            <CustomizeOptimizerButtonWrapper>
              <FlatButton transparent onClick={handleAdvancedSettingsClick}>
                Customize Optimizer Settings
              </FlatButton>
            </CustomizeOptimizerButtonWrapper>
          )}
          <InvestmentObjectivesWrapper isShowing={showOptimizerSettings}>
            <InvestmentObjectivesContainer
              show={showOptimizerSettings}
              isPanel={false}
              $scrollContainer={window.$('html, body')}
              // below not needed
              saveInvestmentObjectives={() => null}
              resetUnsavedChangesInInvestmentObjectives={() => null}
              resetSaveInvestmentObjectives={() => null}
              registerUnsavedChangesInInvestmentObjectives={() => null}
              hideComponent={() => null}
              hideCreatingOptimizedOrdersMessage={() => null}
              internalCreateOptimizedOrdersHandler={() => null}
            />
          </InvestmentObjectivesWrapper>
        </FooterWrapper>
      )}
    </>
  );
};
