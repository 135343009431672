import { getFilteredSecurities, getUpcomingEarningsAnnouncements } from '@src/actions';
import { SECURITY_FILTER_TYPES } from '@src/main/constants/securityFilter';

class SecurityFilterApiAdapter {
  constructor({ filters, count }) {
    this.filters = filters;
    this.count = count;
  }

  get = (dispatch) => {
    this._validateFilters();

    const hasUpcomingEarningsFilter = this.filters.some(
      (filter) => filter.type === SECURITY_FILTER_TYPES.UPCOMING_EARNINGS
    );
    if (hasUpcomingEarningsFilter) {
      return getUpcomingEarningsAnnouncements()(dispatch);
    }

    return getFilteredSecurities(this._getFiltersForApi(), this.count)(dispatch);
  };

  _getFiltersForApi = () => {
    return this.filters.map((f) => f.toApi());
  };

  _validateFilters = () => {
    const hasUpcomingEarningsFilter = this.filters.some(
      (filter) => filter.type === SECURITY_FILTER_TYPES.UPCOMING_EARNINGS
    );
    if (hasUpcomingEarningsFilter && this.filters.length > 1) {
      console.log('Cannot use upcoming earnings with other filters currently');
    }
  };
}

export default SecurityFilterApiAdapter;
