import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import LoadingIcon from '../../components/misc/LoadingIcon';

import { returnCurrentUserId } from '../../helpers/currentUserHelpers';
import { returnConnectionDataForUser, isConnectionsDataLoadingForUser } from '../../helpers/userConnectionsHelpers';

/*
  passes data to children
    {
      ...this.props
      isLoading
      isInitialLoad
      isLoadingIgnoreInitialLoad
      connectionsListByUserId
      userConnectionStore
      connectionsForUserId
    }

  **** GOTCHAS
    isInitialLoad -> assumes some sort of loading function is guaranteed to run before or shortly after mount, if doesn't happen, value will stay true even if there is cached data and no load is occurring

  <ConnectionsDataWrapper
    userId -> will return connections for user ** if you want current user you don't have to supply this
    showLoading -> bool -> component will render a loading state
    getUserConnectionsData -> bool -> ill get ideas on mount depending on ids supplied
    useAsLoadingWrapper -> bool -> doesn't add idea wrapper logic props to children
    useInitialLoading -> bool -> isLoading value will only be for an initial load
    // future feature idea ->  filters -> array of filter type strings

    customLoadingComponent -> jsx -> will render this component instead of default loading component
    hideWhenLoading -> bool, won't display a loading state
  >
    {children}
  </ConnectionsDataWrapper>

*/

class ConnectionsDataWrapper extends Component {
  constructor() {
    super();
    this.state = {
      _isInitialLoadRunning: true,
    };
  }

  componentDidMount() {
    if (this.props.getUserConnectionsData) {
      this._getUserConnectionsData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.isInitialLoad()) {
      this._determineIfInitialLoadCompleted(prevProps);
    }
  }

  render() {
    if (this.isLoading() && this.props.showLoading) {
      if (this._shouldHideWhenLoading()) {
        return null;
      }
      return this.props.customLoadingComponent || <LoadingIcon icon="fading-3balls" size="small" />;
    }

    return (
      <span className={`basic-connections-list-data-wrapper`}>
        {this.props.useAsLoadingWrapper ? this.props.children : this._renderChildrenWithConnectionDataProps()}
      </span>
    );
  }

  _renderChildrenWithConnectionDataProps = () => {
    return React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, this.returnConnectionDataProps())
    );
  };

  returnConnectionDataProps = () => ({
    ...this.props,
    isLoading: this.props.useInitialLoading ? this.isInitialLoad() : this.isLoading(),
    isInitialLoad: this.isInitialLoad(),
    isLoadingIgnoreInitialLoad: this.isLoading(),

    connectionsListByUserId: this.returnConnectionsByUserId(),
    userConnectionStore: this.returnUserConnectionsStore(),
    connectionsForUserId: this._returnUserId(),
  });

  isLoading = (props) => isConnectionsDataLoadingForUser(this._returnUserId(), this.returnUserConnectionsStore(props));

  isInitialLoad = () => this.state._isInitialLoadRunning;

  returnConnectionsByUserId = (props) =>
    returnConnectionDataForUser(this._returnUserId(), this.returnUserConnectionsStore(props));

  returnUserConnectionsStore = (props) => (props || this.props).userConnections;

  _returnUserId = () => this.props.userId || this.returnCurrentUserId();

  returnCurrentUserId = () => returnCurrentUserId(this.returnCurrentUser());

  returnCurrentUser = () => this.props.currentUser;

  _shouldHideWhenLoading = () => this.props.hideWhenLoading;

  _getUserConnectionsData = () => {
    this.props.actions.getUserConnections(this._returnUserId());
  };

  _determineIfInitialLoadCompleted = (prevProps) => {
    if (this.isInitialLoad() && this.isLoading(prevProps) && !this.isLoading()) {
      this.setState(() => ({
        _isInitialLoadRunning: false,
      }));
    }
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    userConnections: state.userConnections,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const component = connect(mapStateToProps, mapDispatchToProps)(ConnectionsDataWrapper);

export default component;
