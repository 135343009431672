import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions/index';

import AnimatedOverlay from '../../components/layout/AnimatedOverlay';
import Panel from '../../components/layout/Panel';

import InfoIcon from '../UI/InfoIcon';
import Toggle from '../Inputs/Toggle';
import Dropdown from '../Inputs/Dropdown';
import TextNumberField from '../Inputs/TextNumberField';

import LivePricing from '../Security/LivePricing';
import OrderHeader from './components/OrderHeader';
import PageLoading from '../../components/PageLoading';
import IconButton from '../../components/buttons/IconButton';
import Button from '../../components/buttons/Button';
import FormLoadingOverlay from '../../components/overlays/FormLoadingOverlay';
import Submit from '../../components/buttons/Submit';
import LoadingIcon from '../../components/misc/LoadingIcon';

import { isUndefinedOrNull, formatLocaleString } from '../../helpers/generalHelpers';
import {
  isCurrentUserOnboarding,
  returnCurrentUserId,
  doesCurrentUserHavePositionIn,
  returnCurrentUserPositionShares,
  isCurrentUserLiveTrading,
} from '../../helpers/currentUserHelpers';
import { renderInput, validateInputRequired } from '../../helpers/inputHelpers';
import { returnPricingForSecurity } from '../../helpers/securitiesHelpers';
import { Row } from '@src/main/lib/nvstr-common-ui.es';

import { calculateOrderApproxValue, calculateOrderApproxShares } from '../../helpers/ordersHelpers';
import { findUserIdeaForSecurity } from '../../helpers/ideaHelpers';
import { customParseFloat, countDecimalPlaces, roundToDecimalPlace } from '../../helpers/numberHelpers';
import { TrackingEvents } from '../../utils/tracking/events';
import { FlatButton, SkeletonButton } from '../../main/components/buttons';
import WarningNotice from '../../components/UI/WarningNotice';
import MoneyField from '../../components/form/MoneyField';
import { quickFetchSecuritiesData } from '../../actions/index';
import { withRouter } from '../../main/utils';

const ErrorBannerWrapper = styled.div`
  background-color: ${({ theme }) => theme.themeColors.error}
  color: ${({ theme }) => theme.themeColors.buttonText};
  `;

const OrderOperationToggleWrapper = styled.div`
  .toggle-left.operation-toggle.toggle-active {
    background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.buttonText};
  }
  .toggle-right.operation-toggle.toggle-active {
    background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.buttonText};
  }
`;
const FormWrapper = styled.div`
  max-width: 400px;

  .currency-input {
    border-radius: 0 !important;

    padding-left: 20px;
  }

  .input-currency-prefix {
    top: -3px;
    left: 5px;
    font-size: 16px;
  }
`;

const ToggleFractionalContainer = styled(Row)`
  padding: 8px 0px 24px 0px;

  button {
    padding: 8px 8px;
  }
`;

const OrderFormWrapper = styled.div`
  input,
  select {
    background-color: var(--light-gray);
  }

  .squared-toggle .operation-toggle {
    padding: 8px 6px;
    width: 100px;
  }
`;

const FormSubmitWrapper = styled.div`
  input {
    background-color: ${({ theme }) => theme.themeColors.primaryCtaButton};
    color: ${({ theme }) => theme.themeColors.buttonText};
    border: none;
    margin: 0 auto;
    padding: 12px 0px !important;
    width: 200px !important;
    border-radius: 6px !important;
    text-decoration: none !important;
  }
  input:hover {
    opacity: 0.8;
  }
`;

const TradeAllOptionWrapper = styled(Row)``;

export class OrderForm extends React.PureComponent {
  constructor(props) {
    super();
    this.state = {
      isSaving: false,
      isSaved: false,
      wasErrorSaving: false,
      isCreatingOrder: false,

      isFractionalTrading: false,

      inputErrors: {},
      errors: [],
      warnings: [],
      formData: {
        operation: null,
        type: { id: 0, name: 'market', displayName: 'Market' },
        shares: '',
        limit_price: '',
        stop_price: '',
        cash_amount: '',
      },

      // TODO: below
      isExceedingMaxLeverage: false,
      isExceedingBuyingPower: false,

      isADayTrader: false,
      isADayTradeOrder: false,

      shouldShowMorePricing: false,

      isCommissionsLoading: false,
      orderCommissionsValue: null,
    };
  }

  componentDidMount() {
    this._isEditForm() ? this._logEditPanelOpen() : this._logPanelOpen();

    this._updateDefaultFormDataFromProps();
    this._refreshSecurityData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this._shouldValidateForm(prevState)) {
      this._validateForm();
    }
    if (this._shouldUpdateCommissions(prevState)) {
      this._getOrderCommissionValue();
    }
  }

  _shouldUpdateCommissions = (prevState) => {
    let update = false;
    const { isFractionalTrading } = this.state;
    const hasNoShareEntry = !isFractionalTrading && this._returnNumOfSharesInOrder() === '';
    const hasNoDollarAmountEntry = isFractionalTrading && this._returnEnteredCashAmountInOrder() === '';

    if (this._returnOrderOperationEntered() === null || hasNoShareEntry || hasNoDollarAmountEntry) {
      return false;
    }

    const didSharesChange = this._returnNumOfSharesInOrder(prevState) !== this._returnNumOfSharesInOrder();
    didSharesChange && (update = true);

    const didCashAmountChange =
      this._returnEnteredCashAmountInOrder(prevState) !== this._returnEnteredCashAmountInOrder();
    didCashAmountChange && (update = true);

    const didOperationChange = this._returnOrderOperationEntered(prevState) !== this._returnOrderOperationEntered();
    didOperationChange && (update = true);
    return update;
  };

  _getOrderCommissionValue = () => {
    const order_id = this._returnOrderId();
    const orderData = {
      order_id,
    };
    this._commissionsForOrderData = orderData;
    this.setState((prevState) => ({
      isCommissionsLoading: true,
    }));
    Actions.getOrderCommission(orderData).then((response) => {
      const {
        ok,
        data,
        // errorMessage
      } = response;
      if (ok) {
        // only save response to state if it was the current request, skip old requests
        if (this._commissionsForOrderData === orderData) {
          this.setState((prevState) => ({
            isCommissionsLoading: false,
            orderCommissionsValue: data.commission,
          }));
        }
      } else {
        // errorMessage?
        setTimeout(() => {
          this._getOrderCommissionValue();
        }, 1000);
      }
    });
  };

  render() {
    return (
      <OrderFormWrapper className={'create-trade-container'}>
        <AnimatedOverlay
          additionalClasses={`create-trade-form-panel ${this._shouldRenderSeeMorePricing() ? 'see-more-pricing' : ''}`}
          handleClick={this._handleOverlayClick}
          showOverlay
          light
          fixedCenter
        >
          <Panel className="ignore-scroll-panel-overflow" withoutOverlay>
            {this._isLoading() ? this._renderLoading() : this._renderForm()}
          </Panel>
        </AnimatedOverlay>
      </OrderFormWrapper>
    );
  }

  _renderForm = () => {
    if (this._isTradingHalted()) {
      return <FormWrapper>{this._renderHaltedSecurityForm()}</FormWrapper>;
    } else if (this._shouldRenderSeeMorePricing()) {
      return <FormWrapper>{this._renderSeeMorePricing()}</FormWrapper>;
    } else {
      return (
        <div className={'create-trade-form-container height-change-animation-fast'}>
          {this._isPlaceOrdersLifecycleRunning() ? (
            this.props.renderPlacingOrdersState()
          ) : (
            <FormWrapper>
              {this._renderCloseIcon()}
              {this._renderHeader()}
              {this._renderOrderForm()}
              {this._renderCreatingOrderOverlay()}
            </FormWrapper>
          )}
        </div>
      );
    }
  };

  _renderSeeMorePricing = () => {
    return (
      <LivePricing
        securityId={this._returnSecurityId()}
        symbol={this._returnSymbol()}
        handleDismiss={this.handleSeeMorePricingDismiss}
        type={'modalInPanel'}
      />
    );
  };

  _isPlaceOrdersLifecycleRunning = () =>
    !!(this.props.renderPlacingOrdersState && this.props.renderPlacingOrdersState());

  _shouldRenderSeeMorePricing = () => this.state.shouldShowMorePricing;

  handleSeeMorePricingClick = () => {
    this._logSeeMorePricingClick();
    this._showMorePricing();
  };

  _handleOverlayClick = () => {
    if (this._shouldRenderSeeMorePricing()) {
      return this.handleSeeMorePricingDismiss();
    }

    if (this.props.shouldRestrictDismissal) {
      return false; // Don't allow dismissal of overlay if this is running to prevent missing important info
    }

    if (this.props.wasOrderPlaced && !this.props.isAddProConPanelShowing) {
      // prevents clicking the overlay to dismiss placed orders message from skipping showing the add pro con panel
      return this.props.showAddProConPanelForPlacedOrders();
    }
    if (this.props.isAddProConPanelShowing) {
      return this._closeForm();
    } else {
      return this._handleCancelOrder(null, true);
    }
  };

  handleSeeMorePricingDismiss = () => {
    this._logSeeMorePricingDismiss();
    this._hideMorePricing();
  };

  _renderHaltedSecurityForm = () => {
    return (
      <div className={'create-trade-form-container height-change-animation-fast'}>
        {this._renderCloseIcon()}
        {this._renderTradingHaltedBanner()}
        {this._renderHeader()}
      </div>
    );
  };

  _renderCloseIcon = () => {
    return (
      <div className={'close-icon-container'}>
        <IconButton
          customClass="new-order-cancel-x"
          icon="fa fa-times fa-times-thin"
          colorClassName="secondary-text-color"
          size="medium"
          handleClick={this._handleCancelOrder}
        />
      </div>
    );
  };

  _renderTradingHaltedBanner = () => {
    return <div className={'halted-trading-banner'}>{`Trading for ${this._returnSymbol()} is temporarily halted`}</div>;
  };

  _renderHeader = () => {
    return (
      <OrderHeader
        securityId={this._returnSecurityId()}
        isLiveTrading={this._isCurrentUserLiveTrading()}
        buyingPower={this._returnBuyingPower()}
        buyingPowerDisplay={this._displayFormatOfBuyingPower()}
        handleSeeMorePricingClick={this.handleSeeMorePricingClick}
      />
    );
  };

  _toggleFractionalTrading = () => {
    this.setState((prevState) => ({
      isFractionalTrading: !prevState.isFractionalTrading,
    }));
  };

  _renderOrderForm = () => {
    const BuyOrSell = this._isSellingShares() ? 'Sell' : 'Buy';
    const hideFractionalToggle = this._isStopOrder();
    const { isFractionalTrading } = this.state;
    return (
      <form className={'order-inputs'} onSubmit={this._handleSubmit}>
        {this._renderOrderOperationInputRow()}
        <div className={'input-group'}>
          {this._renderOrderTypeDropdown()}
          {isFractionalTrading ? this._renderFractionalTradingInput() : this._renderNumOfSharesInput()}
        </div>

        {!hideFractionalToggle && this._isFractionalTradingUnavailableReason() === null ? (
          <ToggleFractionalContainer hAlign="center">
            <SkeletonButton
              buttonProps={{ width: '224px', padding: '6px 0px' }}
              onClick={this._toggleFractionalTrading}
            >
              {isFractionalTrading ? `Switch to ${BuyOrSell} in Shares` : `Switch to ${BuyOrSell} in Dollars`}
            </SkeletonButton>
          </ToggleFractionalContainer>
        ) : (
          this._renderFractionalUnavailableMessage(this._isFractionalTradingUnavailableReason())
        )}

        {this._renderOrderAttributes()}

        {this._renderWarnings()}
        {this._renderErrors()}

        {this._renderActionButtons()}
      </form>
    );
  };

  _renderOrderOperationInputRow = () => {
    return (
      <OrderOperationToggleWrapper className={'order-operation-row'}>
        {this._renderOperationToggle()}
      </OrderOperationToggleWrapper>
    );
  };

  _renderBlankCoverSellAllOption = () => <div className={'blank-cover-sell-all-spacer'} />;

  _renderCoverAllOption = () => {
    if (this._doesUserHaveAShortPosition()) {
      return (
        <FlatButton smallNoVert transparent onClick={this._handleCoverAllClick}>
          Cover All
        </FlatButton>
      );
    } else {
      return this._renderBlankCoverSellAllOption('cover-spacer');
    }
  };

  _renderSellAllOption = () => {
    if (this._doesUserHaveALongPosition()) {
      return (
        <FlatButton smallNoVert transparent onClick={this._handleSellAllClick}>
          Sell All
        </FlatButton>
      );
    } else {
      return this._renderBlankCoverSellAllOption('sell-spacer');
    }
  };

  _handleCoverAllClick = () => {
    this._logCoverAllClick();
    this._updateFormToCoverPosition();
  };

  _handleSellAllClick = () => {
    this._logSellAllClick();
    this._updateFormToSellPosition();
  };

  _updateFormToCoverPosition = () => {
    this.setState((prevState) => ({
      formData: {
        operation: { id: 0, name: 'Buy', display: 'Buy' },
        type: { id: 0, name: 'market', displayName: 'Market' },
        shares: Math.abs(this._returnNumberOfCurrentSharesInPosition()),
        limit_price: null,
        stop_price: null,
        cash_amount: null,
      },
    }));
  };

  _updateFormToSellPosition = () => {
    this.setState((prevState) => ({
      formData: {
        operation: { id: 1, name: 'Sell', display: 'Sell' },
        type: { id: 0, name: 'market', displayName: 'Market' },
        shares: this._returnNumberOfCurrentSharesInPosition(),
        limit_price: null,
        stop_price: null,
        cash_amount: null,
      },
    }));
  };

  _renderOrderAttributes = () => {
    const { isFractionalTrading } = this.state;
    return (
      <div className={'order-form-attributes'}>
        {this._isLimitOrder() ? this._renderLimitPriceField() : null}
        {this._isStopOrder() ? this._renderStopPriceField() : null}
        {this._doesCurrentUserHavePosition(this._returnSecurityId()) ? this._renderCurrentSharesField() : null}
        {isFractionalTrading ? this._renderApproxSharesField() : this._renderApproxValueField()}
        {this._isCurrentUserLiveTrading() ? this._renderCommissionsField() : null}
      </div>
    );
  };

  _renderLimitPriceInput = () => {
    return this._renderInput(this._returnLimitPriceInput());
  };

  _renderLimitPriceField = () => {
    const classKey = 'limit-price';
    const config = {
      classKey,
      withInput: true,
    };
    return this._renderOrderAttributesField('Limit Price', this._renderLimitPriceInput(), config);
  };

  _renderStopPriceInput = () => {
    return this._renderInput(this._returnStopPriceInput());
  };

  _renderStopPriceField = () => {
    const classKey = 'stop-price';
    const config = {
      classKey,
      withInput: true,
    };
    return this._renderOrderAttributesField('Stop Price', this._renderStopPriceInput(), config);
  };

  _renderCurrentSharesField = () => {
    const classKey = 'current-shares';
    const config = { classKey };
    return this._renderOrderAttributesField(
      'Current Shares',
      <TradeAllOptionWrapper hAlign="right">
        <span style={{ display: 'inline-block', marginRight: '8px' }}>
          {this._doesUserHaveAShortPosition() && this._renderCoverAllOption()}
          {this._doesUserHaveALongPosition() && this._renderSellAllOption()}
        </span>
        {this._returnNumberOfCurrentSharesInPosition()}
      </TradeAllOptionWrapper>,
      config
    );
  };

  _renderCommissionsField = () => {
    const { isCommissionsLoading } = this.state;
    const classKey = 'commissions';
    const config = {
      classKey,
      isLoading: isCommissionsLoading,
    };
    const commissionValue = this._returnCommissionFee() === null ? null : this._displayFormatOfOrderCommissionTotal();
    return this._renderOrderAttributesField('Commissions', commissionValue, config);
  };

  _renderApproxValueField = () => {
    const classKey = 'approx-order-value';
    const config = { classKey };
    return this._renderOrderAttributesField('Approx Value', this._displayFormatOfOrderApproxValue(), config);
  };

  _approximateSharesForFractionalTrade = () => {
    if (isUndefinedOrNull(this._returnCurrentPriceForSecurity())) {
      return '';
    }
    return calculateOrderApproxShares(this._parseFormCashAmount(), this._returnCurrentPriceForSecurity());
  };

  _parseFormCashAmount = () => {
    const {
      formData: { cash_amount },
    } = this.state;
    return customParseFloat(cash_amount);
  };

  _renderApproxSharesField = () => {
    const classKey = 'approx-order-shares';
    const config = { classKey };

    const approxShares = this._approximateSharesForFractionalTrade();
    return this._renderOrderAttributesField('Approx Shares', approxShares, config);
  };

  _displayFormatOfOrderCommissionTotal = () => `$${this._returnCommissionFee().toFixed(2)}`;

  _displayFormatOfOrderApproxValue = () => {
    return (
      '$' +
      formatLocaleString(this._calculateOrderApproxValue(), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };

  _returnCommissionFee = () => this.state.orderCommissionsValue;

  _calculateOrderApproxValue = () => {
    if (isUndefinedOrNull(this._returnCurrentPriceForSecurity())) {
      return '';
    }
    return calculateOrderApproxValue(
      this._isSellingShares(),
      this._returnNumOfSharesInOrderAsValue(),
      this._returnCurrentPriceForSecurity(),
      this._isLimitOrder(),
      this._returnOrderLimitPriceEnteredValue(),
      this._isStopOrder(),
      this._returnOrderStopPriceEnteredValue()
    );
  };

  _renderOrderAttributesField = (key, value, config = {}) => {
    const classes = [];
    if (config.withInput) {
      classes.push('with-input');
    }
    if (config.classKey) {
      classes.push(config.classKey);
    }
    const showSECInfoIcon = key === 'Commissions' && this._willTradeSizeCrossZero();
    const isGoingAcrossZeroToShort = this._willTradeSizeCrossZeroToShort();
    return (
      <div className={`order-attributes-field ${classes.join(' ')}`}>
        <div className={'label  '}>{key}</div>
        <span className="default-flex-end">
          {showSECInfoIcon && (
            <InfoIcon
              position="right"
              word={
                isGoingAcrossZeroToShort
                  ? 'tooltip_order_form_sec_rule_for_short_sale'
                  : 'tooltip_order_form_sec_rule_for_long_sale'
              }
              style={{ position: 'relative', top: '0', left: '2px' }}
            />
          )}
          <div className={'value'}>
            {config.isLoading ? (
              <LoadingIcon icon="fading-3balls" size={'small'} style={{ marginRight: '15px' }} />
            ) : (
              value
            )}
          </div>
        </span>
      </div>
    );
  };

  _renderActionButtons = () => {
    return (
      <FormSubmitWrapper className={'create-order-action-buttons'}>
        <Submit
          className={'btn'}
          handleClick={() => true} // its a submit typed button, it submits the form so there is no current action
          text={this._returnTextForPlaceOrderButton()}
          disabled={this._isFormErrors()}
        />
      </FormSubmitWrapper>
    );
  };

  _renderCreatingOrderOverlay = () => {
    return (
      <FormLoadingOverlay
        className={'order-form-action-overlay'}
        show={this._isCreatingOrder()}
        spinner={{
          iconClass: 'circle-loading-spinner circle-loading-spinner-medium',
          style: { marginBottom: '-7px' },
        }}
        message={this._returnMessageForCreatingOrderOverlay()}
      />
    );
  };

  _returnTextForPlaceOrderButton = () => {
    return this._isEditForm() ? 'UPDATE ORDER' : this.isUsingBasket() ? 'ADD TO BASKET' : 'PLACE ORDER';
  };

  isUsingBasket = () => this.props.isUsingBasket;

  _returnOperationInput = () => ({
    type: Toggle,
    typeName: 'Toggle',
    name: 'operation',
    required: true,
    props: {
      values: [{ id: 0, name: 'buy', display: 'Buy' }, { id: 1, name: 'sell', display: 'Sell' }],
      isDisabled: (value) => {
        if (value.id === 1) {
          return !this._isShortAllowed() && !this._doesUserHaveALongPosition();
        } else {
          return false;
        }
      },
      returnDisabledMessage: (value) => {
        if (value.id === 1) {
          return this._returnCannotShortMessage();
        } else {
          return false;
        }
      },
      toggleButtonClass: 'operation-toggle',
    },
  });

  _renderOperationToggle = () => {
    const input = this._returnOperationInput();
    return this._renderInput(input);
  };

  _returnOrderTypeInput = () => ({
    type: Dropdown,
    typeName: 'Dropdown',
    name: 'type',
    label: 'Order Type',
    required: true,
    props: {
      styling: 'dropdown-inline-left',
      values: this._returnOrderTypes(),
      includeLabelAsSelection: false,
      forceShowLabel: true,
      showArrowIcons: true,
    },
  });

  _renderOrderTypeDropdown = () => {
    const input = this._returnOrderTypeInput();
    return this._renderInput(input);
  };

  _returnNumOfSharesInput = () => {
    let inputObj = {
      type: TextNumberField,
      typeName: 'TextNumberField',
      label: 'Shares',
      name: 'shares',
      props: {
        styling: 'dropdown-styled-text-field',
        step: '1',
      },
    };

    if (this._isStopLimitOrder() || this._isStopOrder() || this._isFractionalTradingUnavailableReason() !== null) {
      inputObj.props = { ...inputObj.props, inputRestrictionChars: /^[0-9]+$/ };
    } else {
      inputObj.props = {
        ...inputObj.props,
        customInputEntryOverride: (inputValueString) => this._returnFormattedNumberForInputField(inputValueString, 5),
      };
    }
    return inputObj;
  };

  _returnFractionalTradingInput = () => ({
    type: MoneyField,
    typeName: 'MoneyField',
    label: 'Dollar Amount',
    name: 'cash_amount',
    required: true,
    props: {
      inverted: true,
      overwriteClassNames: 'text-number-field cash_amount dropdown-styled-text-field force-text-field-style',
      step: '1',
      customInputEntryOverride: (inputValueString) => this._returnFormattedNumberForInputField(inputValueString),
    },
  });

  _renderFractionalTradingInput = () => {
    const input = this._returnFractionalTradingInput();
    return this._renderInput(input);
  };

  _renderNumOfSharesInput = () => {
    const input = this._returnNumOfSharesInput();
    return this._renderInput(input);
  };

  _returnLimitPriceInput = () => ({
    type: TextNumberField,
    typeName: 'TextNumberField',
    name: 'limit_price',
    required: this._isLimitPriceInputRequired,
    props: {
      prefix: '$',
      autoHideErrors: true,
      hideLabel: true,
      styling: 'highlight-text-field text-field-right',
      step: '0.01',
      customInputEntryOverride: (inputValueString) =>
        this._returnOverrideInputValueForDecimalAccuracy(inputValueString),
      validateForWarnings: (inputValue) => {
        if (inputValue) {
          const range = 0.1;
          const currentPrice = this._returnCurrentPriceForSecurity();
          const isOutOfRange = Math.abs((inputValue - currentPrice) / currentPrice) > range;
          if (isOutOfRange) {
            return ['Your limit price is more than 10% away from the last price.'];
          }
          if (this._isStopLimitOrder()) {
            const stopPrice = this._returnOrderStopPriceEntered();
            if (stopPrice) {
              const isStopOutOfRange = Math.abs((inputValue - stopPrice) / stopPrice) > range;
              if (isStopOutOfRange) {
                return ['Your limit price is more than 10% away from your stop price.'];
              }
            }
          }
        }
        return [];
      },
    },
  });

  _returnFormattedNumberForInputField = (inputValueString, decimalPlaces) => {
    let inputValue = +inputValueString;
    if (inputValue < 0) {
      inputValue *= -1;
    }
    return this._returnOverrideInputValueForDecimalAccuracy(inputValue, decimalPlaces);
  };

  // used to prevent a user from submitting an invalid price due to a decimal accuracy beyond what is supported
  _returnOverrideInputValueForDecimalAccuracy = (inputValueString, decimalPlaces) => {
    const floatInputValue = customParseFloat(inputValueString);
    if (floatInputValue > 0) {
      const allowableDecimalPlaces = decimalPlaces && decimalPlaces > 0 ? decimalPlaces : floatInputValue < 1 ? 4 : 2;

      if (countDecimalPlaces(floatInputValue) > allowableDecimalPlaces) {
        return roundToDecimalPlace(floatInputValue, allowableDecimalPlaces).toString();
      }
    }
    return inputValueString;
  };

  _returnStopPriceInput = () => ({
    type: TextNumberField,
    typeName: 'TextNumberField',
    name: 'stop_price',
    required: this._isStopPriceInputRequired,
    props: {
      prefix: '$',
      autoHideErrors: true,
      hideLabel: true,
      styling: 'highlight-text-field text-field-right',
      step: '0.01',
      customInputEntryOverride: (inputValueString) =>
        this._returnOverrideInputValueForDecimalAccuracy(inputValueString),
      validateForWarnings: (stopPrice) => {
        if (stopPrice) {
          const currentPrice = this._returnCurrentPriceForSecurity();
          const range = 0.1;
          const isOutOfRange = Math.abs((stopPrice - currentPrice) / currentPrice) > range;
          if (isOutOfRange) {
            return ['Your stop price is more than 10% away from the last price.'];
          }
          if (this._isStopLimitOrder()) {
            const limitPrice = this._returnOrderLimitPriceEntered();
            if (limitPrice) {
              const isStopOutOfRange = Math.abs((limitPrice - stopPrice) / stopPrice) > range;
              if (isStopOutOfRange) {
                return ['Your limit price is more than 10% away from your stop price.'];
              }
            }
          }
        }
        return [];
      },
    },
  });

  _isLimitPriceInputRequired = () => this._isLimitOrder();

  _isStopPriceInputRequired = () => this._isStopOrder();

  _returnInputs = () => {
    const { isFractionalTrading } = this.state;
    const orderQuantityField = isFractionalTrading
      ? this._returnFractionalTradingInput()
      : this._returnNumOfSharesInput();

    return [
      this._returnOperationInput(),
      this._returnOrderTypeInput(),
      orderQuantityField,
      this._returnLimitPriceInput(),
      this._returnStopPriceInput(),
    ];
  };

  _returnOrderTypes = () => {
    const { isFractionalTrading } = this.state;
    let options = [
      {
        id: 0,
        name: 'market',
        displayName: 'Market',
        isEnabled: () => true,
        disabledReason: () => null,
      },
      {
        id: 1,
        name: 'limit',
        displayName: 'Limit',
        isEnabled: () => this._isCurrentUserLiveTrading(),
        disabledReason: () => 'Only Available for Live Trading',
      },
    ];
    if (!isFractionalTrading) {
      options.push(
        {
          id: 2,
          name: 'stop',
          displayName: 'Stop',
          isEnabled: () => this._isCurrentUserLiveTrading(),
          disabledReason: () => 'Only Available for Live Trading',
        },
        {
          id: 3,
          name: 'stop_limit',
          displayName: 'Stop Limit',
          isEnabled: () => this._isCurrentUserLiveTrading(),
          disabledReason: () => 'Only Available for Live Trading',
        }
      );
    }
    return options;
  };

  _renderInput = (input) => {
    return renderInput.call(this, input);
  };

  // Warnings
  _checkIfHasAnIdeaWithOppositePosition = () => {
    const idea = this._returnCurrentUserIdea();
    if (idea) {
      const isBuyIdea = idea.idea_type.id === 0;
      if (!isBuyIdea && this._isGoingLong()) {
        return 'You currently have a SELL idea for this stock';
      }
      if (isBuyIdea && this._isShorting()) {
        return 'You currently have a BUY idea for this stock';
      }
    }
    return null;
  };

  // Errors
  _checkIfEnteringAShortPositionWhenNotAllowed = () => {
    if (!this._isShortAllowed() && this._isShorting()) {
      return this._returnCannotShortMessage();
    }
  };

  _checkIfSharesAreZero = () => {
    const { isFractionalTrading, formData } = this.state;
    const { shares } = formData;
    if (!isFractionalTrading && shares == 0) {
      return 'Shares cannot be 0';
    }
  };

  _checkRequiredFieldsAreComplete = (config) => {
    const formClassContext = this;
    let isRequiredFieldMissing = false;
    const inputErrors = {};
    const inputs = this._returnInputs();
    inputs.forEach((input) => {
      const isRequiredAndCompleteOrNotRequired = validateInputRequired(formClassContext, input);
      if (!isRequiredAndCompleteOrNotRequired) {
        inputErrors[input.name] = 'Required Field';
        isRequiredFieldMissing = true;
      }
    });
    if (config.updateInputErrors) {
      this.setState(() => ({
        inputErrors,
      }));
    }
    return isRequiredFieldMissing ? 'Please fill out all required fields.' : null;
  };

  _checkStopPriceAgainstCurrentPrice = () => {
    if (this._isStopOrder()) {
      if (this._isSellingShares()) {
        return this._returnOrderStopPriceEntered() <= this._returnCurrentPriceForSecurity()
          ? null
          : "Stop price can't be greater than last price.";
      } else {
        return this._returnOrderStopPriceEntered() >= this._returnCurrentPriceForSecurity()
          ? null
          : "Stop price can't be less than last price.";
      }
    }
    return null;
  };

  // Validations
  _shouldValidateForm = (prevState) => {
    // skip validation if form didn't change
    if (!this._wasFormDataChanged(prevState)) {
      return false;
    }
    if (
      (!prevState.isFractionalTrading && this.state.isFractionalTrading) ||
      (prevState.isFractionalTrading && !this.state.isFractionalTrading)
    ) {
      return false;
    }
    // if in error always attempt validation
    if (this.state.errors.length > 0) {
      return true;
    }

    // start validating when form is complete
    var valid = true;
    if (this._isMarketOrder()) {
      const isOperationEntered = this._isBuyingShares() || this._isSellingShares();
      const isSharesEntered = this._returnNumOfSharesInOrderAsValue() > 0;
      valid = isOperationEntered && isSharesEntered;
    }
    if (this._isLimitOrder()) {
      const isOperationEntered = this._isBuyingShares() || this._isSellingShares();
      const isSharesEntered = this._returnNumOfSharesInOrderAsValue() > 0;
      const isLimitPriceEntered = this._returnOrderLimitPriceEntered() > 0;
      valid = valid && isOperationEntered && isSharesEntered && isLimitPriceEntered;
    }
    if (this._isStopOrder()) {
      const isOperationEntered = this._isBuyingShares() || this._isSellingShares();
      const isSharesEntered = this._returnNumOfSharesInOrderAsValue() > 0;
      const isStopPriceEntered = this._returnOrderStopPriceEntered() > 0;
      const isStopPriceValid = this._isSellingShares()
        ? this._returnOrderStopPriceEntered() <= this._returnCurrentPriceForSecurity()
        : this._returnOrderStopPriceEntered() >= this._returnCurrentPriceForSecurity();
      valid = valid && isOperationEntered && isSharesEntered && isStopPriceEntered && !isStopPriceValid;
    }
    return valid;
  };

  _returnAllErrorValidations = () => [
    this._checkIfSharesAreZero,
    this._checkIfEnteringAShortPositionWhenNotAllowed,
    () => this._checkRequiredFieldsAreComplete({ updateInputErrors: true }),
    this._checkStopPriceAgainstCurrentPrice,
  ];

  _returnAllWarningValidations = () => [this._checkIfHasAnIdeaWithOppositePosition];

  _validateForm = () => {
    const {
      isFractionalTrading,
      formData: { shares },
    } = this.state;
    const shareAmount = (shares && +shares) || 0;
    const sharesIsInteger = shareAmount > 0 && Number.isInteger(shareAmount);
    const errors = [];
    const warnings = [];
    this._returnAllErrorValidations().forEach((validationFunc) => {
      const error = validationFunc();
      if (error) {
        errors.push(error);
      }
    });
    this._returnAllWarningValidations().forEach((validationFunc) => {
      const warning = validationFunc();
      if (warning) {
        warnings.push(warning);
      }
    });

    if (isFractionalTrading && this._isBuyingShares() && this._parseFormCashAmount() < 5) {
      errors.push('Dollar amount must be at least $5.');
    }

    if ((this._isStopOrder() || this._isStopLimitOrder()) && !sharesIsInteger) {
      errors.push('Stop orders require a whole number for shares.');
    }

    this._overwriteWarnings(warnings);
    this._overwriteErrors(errors);
    return errors.length === 0;
  };

  _handleCreate = () => {
    const _this = this;
    if (this._validateForm()) {
      this._startCreatingOrder();
      this._submitOrderForm().then((response) => {
        setTimeout(() => {
          _this._endCreatingOrder();
          _this._handleOrderSubmitResponse(response);
        }, 500);
      });
    } else {
      this._logSubmitInvalidOrderFormForm();
    }
  };

  _handleEdit = () => {
    const _this = this;
    if (this._validateForm()) {
      this._startCreatingOrder();
      this._submitOrderForm().then((response) => {
        setTimeout(() => {
          _this._endCreatingOrder();
          _this._handleOrderSubmitResponse(response);
        }, 500);
      });
    } else {
      this._logSubmitInvalidOrderFormForm();
    }
  };

  // Requests
  _handleSubmit = (e) => {
    e.preventDefault();
    if (this._validateForm()) {
      if (this._isEditForm()) {
        return this._handleEdit();
      } else {
        if (this.isUsingBasket()) {
          this._handleCreate();
        } else {
          const orders = [this._normalizeFormDataForApi()];
          this.props.handlePlaceOrders(orders);
        }
      }
    }
  };

  _handleOrderSubmitResponse = (response) => {
    if (response && response.wasSuccessful) {
      this._isEditForm() ? this._logUpdateManualOrder() : this._logCreateManualOrder();
      this.props.handleCompleteOrder();
    } else {
      const wasData = response && response.data;
      const error =
        wasData && Array.isArray(response.data)
          ? response.data[0] && response.data[0].error
          : response && response.data && response.data.error;
      this._createOrderError(error);
    }
  };

  _convertAPIErrorMessageToFrontendMessage = (errorMessage) => {
    const dict = {
      'User already has an order for this security': 'Order already exists for that stock. Click edit to modify it.',
      'Can not create an order for an un-featured security': 'This security is not currently available to trade.',
    };
    return dict[errorMessage] || errorMessage || 'Something went wrong. Please try again later.';
  };

  _createOrderError = (error) => {
    const normalizedErrorMessage = this._convertAPIErrorMessageToFrontendMessage(error);
    this._addError(normalizedErrorMessage);
  };

  _submitOrderForm = () =>
    this._isEditForm()
      ? this.props.actions.updateOrderInBasket(this._normalizeFormDataForApi())
      : this.props.actions.addOrderToBasket(this._normalizeFormDataForApi());

  _normalizeFormDataForApi = () => {
    const { isFractionalTrading } = this.state;
    let order = {
      operation: this._returnOrderOperationEntered().name.toLowerCase(),
      type: this._returnOrderTypeEntered().name.toLowerCase(),
      security_id: this._returnSecurityId(),
      is_from_optimizer: false,
    };
    if (isFractionalTrading) {
      order = {
        ...order,
        cash_amount: +this._parseFormCashAmount() || 0,
      };
    } else {
      order = { ...order, shares: +this._returnNumOfSharesInOrderAsValue() };
    }

    if (this._isEditForm()) {
      order.order_id = this._returnOrderId();
    }
    if (this._isLimitOrder()) {
      order.limit_price = this._returnOrderLimitPriceEntered();
    }
    if (this._isStopOrder()) {
      order.stop_price = this._returnOrderStopPriceEntered();
    }
    return order;
  };

  _isLoading = () => false;

  _renderLoading = () => <PageLoading />;

  _isCreatingOrder = () => this.state.isCreatingOrder;

  _returnMessageForCreatingOrderOverlay = () => (this._isEditForm() ? 'Updating Order' : 'Adding Order');

  _isFormErrors = () => !(!this._returnFormErrors() || this._returnFormErrors().length === 0); // not not errors

  _returnFormErrors = () => this.state.errors;

  _renderErrors = () => {
    if (!this._isFormErrors()) {
      return null;
    }
    return (
      <ErrorBannerWrapper className={'new-order-error-message-container error-banner'}>
        {this._returnFormErrors().map((error, i) => (
          <span key={`new-order-error-${i}`} className="text-block">
            {error}
          </span>
        ))}
      </ErrorBannerWrapper>
    );
  };

  _isFormWarnings = () => !(!this._returnFormWarnings() || this._returnFormWarnings().length === 0); // not not errors

  _returnFormWarnings = () => this.state.warnings;

  _renderWarnings = () => {
    if (!this._isFormWarnings()) {
      return null;
    }
    return (
      <div className={'new-order-warning-message-container warning-banner'}>
        {this._returnFormWarnings().map((warning, i) => (
          <span key={`new-order-warning-${i}`} className="text-block">
            {warning}
          </span>
        ))}
      </div>
    );
  };

  _isEditForm = () => this.props.editMode;

  _wasError = () => this.state.wasErrorSaving;

  _wasFormDataChanged = (prevState) =>
    JSON.stringify(this._returnFormData(prevState)) !== JSON.stringify(this._returnFormData());

  _returnFormData = (state = this.state) => state.formData;

  _updateDefaultFormDataFromProps = () => {
    const defaultFormData = this.props.defaultFormData;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        ...defaultFormData,
      },
    }));
  };

  _returnOrderId = () => this.props.orderId;

  _returnNumOfSharesInOrder = (state = this.state) => this._returnFormData(state).shares;

  _returnEnteredCashAmountInOrder = (state = this.state) => this._returnFormData(state).cash_amount;

  _returnNumOfSharesInOrderAsValue = () =>
    this._returnNumOfSharesInOrder() === '' ? 0 : this._returnNumOfSharesInOrder();

  _returnOrderTypeEntered = (state = this.state) => this._returnFormData(state).type;

  _returnOrderOperationEntered = (state = this.state) => this._returnFormData(state).operation;

  _returnOrderLimitPriceEntered = () => this._returnFormData().limit_price;

  _returnOrderLimitPriceEnteredValue = () =>
    isUndefinedOrNull(this._returnOrderLimitPriceEntered()) || this._returnOrderLimitPriceEntered() === ''
      ? 0
      : this._returnOrderLimitPriceEntered();

  _returnOrderStopPriceEntered = () => this._returnFormData().stop_price;

  _returnOrderStopPriceEnteredValue = () =>
    isUndefinedOrNull(this._returnOrderStopPriceEntered()) || this._returnOrderStopPriceEntered() === ''
      ? 0
      : this._returnOrderStopPriceEntered();

  _isBuyingShares = () => this._returnFormData().operation && this._returnFormData().operation.id === 0;

  _isSellingShares = () => this._returnFormData().operation && this._returnFormData().operation.id === 1;

  _isGoingLong = () => {
    const isLong = this._doesUserHaveALongPosition();
    const isBuying = this._isBuyingShares();
    const hasNoPosition = !this._doesCurrentUserHavePosition();

    const isShort = this._doesUserHaveAShortPosition();
    const positionSize = Math.abs(this._returnNumberOfCurrentSharesInPosition());
    const orderSize = this._returnNumOfSharesInOrderAsValue();
    const isCrossOverLong = isShort && isBuying && orderSize > positionSize;

    return (isLong && isBuying) || (hasNoPosition && isBuying) || isCrossOverLong;
  };

  _isExitingLong = () => {
    const isLong = this._doesUserHaveALongPosition();
    const isSelling = this._isSellingShares();
    return isLong && isSelling;
  };

  _isShorting = () => {
    const isShort = this._doesUserHaveAShortPosition();
    const isSelling = this._isSellingShares();
    const hasNoPosition = !this._doesCurrentUserHavePosition();

    const isLong = this._doesUserHaveALongPosition();
    const positionSize = Math.abs(this._returnNumberOfCurrentSharesInPosition());
    const orderSize = this._returnNumOfSharesInOrderAsValue();
    const isCrossOverShort = isLong && isSelling && orderSize > positionSize;
    return (isShort && isSelling) || (hasNoPosition && isSelling) || isCrossOverShort;
  };

  _isCovering = () => {
    const isShort = this._doesUserHaveAShortPosition();
    const isBuying = this._isBuyingShares();
    return isShort && isBuying;
  };

  _isMarketOrder = () => this._returnFormData().type && this._returnFormData().type.name === 'market';

  _isLimitOrder = () =>
    this._returnFormData().type &&
    (this._returnFormData().type.name === 'limit' || this._returnFormData().type.name === 'stop_limit');

  _isStopOrder = () =>
    this._returnFormData().type &&
    (this._returnFormData().type.name === 'stop' || this._returnFormData().type.name === 'stop_limit');

  _isStopLimitOrder = () => this._returnFormData().type && this._returnFormData().type.name === 'stop_limit';

  _closeForm = () => {
    if (this.props.handleCancelOrder) {
      this.props.handleCancelOrder();
    } else {
      console.error('no handleCancelOrder supplied to OrderForm');
    }
  };

  _handleCancelOrder = (e, isOverlay = false) => {
    this._isEditForm() ? this._logEditOrderCancel(isOverlay) : this._logManualOrderCancel(isOverlay);
    return this._closeForm();
  };

  _refreshSecurityData = () => {
    this.props.actions.quickFetchSecuritiesData([this._returnSecurityId()]);
    this.props.actions.fetchSecuritiesPriceData([this._returnSecurityId()]);
  };

  _returnCurrentUserIdea = () =>
    findUserIdeaForSecurity(this._returnCurrentUserId(), this._returnSecurityId(), this._returnIdeasList());

  _doesCurrentUserHavePosition = () =>
    doesCurrentUserHavePositionIn(this._returnSecurityId(), this._returnPortfolioStore());

  _willTradeSizeCrossZeroToShort = () => {
    if (!this._doesCurrentUserHavePosition()) {
      return false;
    }

    if (this._doesUserHaveALongPosition()) {
      if (this._isSellingShares()) {
        if (this._returnNumOfSharesInOrderAsValue() > this._returnNumberOfCurrentSharesInPosition()) {
          return true;
        }
      }
    }
    return false;
  };

  _willTradeSizeCrossZero = () => {
    if (!this._doesCurrentUserHavePosition()) {
      return false;
    }

    if (this._doesUserHaveALongPosition()) {
      if (this._isSellingShares()) {
        if (this._returnNumOfSharesInOrderAsValue() > this._returnNumberOfCurrentSharesInPosition()) {
          return true;
        }
      }
    }
    if (this._doesUserHaveAShortPosition()) {
      if (this._isBuyingShares()) {
        if (this._returnNumOfSharesInOrderAsValue() > Math.abs(this._returnNumberOfCurrentSharesInPosition())) {
          return true;
        }
      }
    }
    return false;
  };

  _doesUserHaveALongPosition = () => this._returnNumberOfCurrentSharesInPosition() > 0;

  _doesUserHaveAShortPosition = () => this._returnNumberOfCurrentSharesInPosition() < 0;

  _returnNumberOfCurrentSharesInPosition = () =>
    returnCurrentUserPositionShares(this._returnSecurityId(), this._returnPortfolioStore());

  _returnPortfolioStore = () => this.props.portfolio;

  _returnIdeasStore = () => this.props.ideas;

  _returnIdeasList = () => this._returnIdeasStore().ideaList;

  _isCurrentUserLiveTrading = () => isCurrentUserLiveTrading(this._returnCurrentUser());

  _isCurrentUserOnboarding = () => isCurrentUserOnboarding(this._returnCurrentUser());

  _returnCurrentUserId = () => returnCurrentUserId(this.props.currentUser);

  _returnCurrentUser = () => ({
    ...this.props.currentUser,
  });

  _renderFractionalUnavailableMessage = (reason) => {
    if (!reason) {
      return null;
    }

    const BuyOrSell = this._isSellingShares() ? 'Sell' : 'Buy';
    const { isFractionalTrading } = this.state;
    let message = null;
    if (reason === 'marketClosed') {
      message =
        'Trading shares in fractions or trading by dollar amounts is not currently supported when the market is closed.';
    }

    if (reason === 'securityNotSupported') {
      message =
        'Trading shares in fractions or trading by dollar amounts is not currently supported for this instrument.';
    }

    if (reason === 'userNotEnrolled') {
      message = 'Trading shares in fractions or trading by dollar amounts is not currently available.';
    }

    if (message !== null) {
      return (
        <div>
          <Row hAlign="center">
            <SkeletonButton disabled buttonProps={{ width: '224px', padding: '6px 0px' }} onClick={() => false}>
              {isFractionalTrading ? `Switch to ${BuyOrSell} in Shares` : `Switch to ${BuyOrSell} in Dollars`}
            </SkeletonButton>
          </Row>
          <div style={{ opacity: 0.4, paddingTop: '8px', paddingBottom: '24px' }}>
            <WarningNotice text={message} />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  _isFractionalSupportedSecurity = () => this.props.isFractionalSupportedSecurity;

  _isFractionalTradingUnavailableReason = () => {
    if (!this._isFractionalSupportedSecurity()) {
      return 'securityNotSupported';
    }
    if (this._returnCurrentUser().show_fractional_trading_test_group !== 1) {
      return 'userNotEnrolled';
    }

    return null;
  };

  _returnCurrentPriceForSecurity = () => this._returnPricingDataForSecurity().current_price;

  _returnPricingDataForSecurity = () =>
    returnPricingForSecurity(this._returnSecurityId(), this._returnSecuritiesPriceStore());

  _returnSecuritiesPriceStore = () => this.props.securitiesPrice;

  _returnBuyingPower = () => this._returnCurrentUser().buying_power;

  _displayFormatOfBuyingPower = () => {
    return (
      '$' +
      formatLocaleString(this._returnBuyingPower(), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };

  _returnSecurity = () => this._returnSecuritiesLookup()[this._returnSecurityId()] || {};

  _returnSecuritiesLookup = () => this._returnSecuritiesStore().lookup;

  _returnSecuritiesStore = () => this.props.securities;

  _returnSymbol = () => this._returnSecurity().symbol || 'this stock';

  _isShortAllowed = () => this._returnPricingDataForSecurity().is_short_allowed || false;

  _isTradingHalted = () => this._returnPricingDataForSecurity().is_halted || false;

  _returnSecurityId = () => parseInt(this.props.securityId, 10);

  _returnOrdersStore = () => this.props.orders;

  _returnOrder = () => this.props.order || {};

  _isFromOptimizer = () => this._returnOrder().is_from_optimizer;

  // Tracking functions
  returnOrderDataForTracking = () => ({
    'From Optimizer': this._isFromOptimizer(),
    Shares: parseInt(this._returnNumOfSharesInOrder(), 10),
    Operation: this._returnOrderOperationEntered().display,
    Type: this._returnOrderTypeEntered().displayName,
    'Limit Price': this._returnOrderLimitPriceEntered(),
    'Stop Price': this._returnOrderStopPriceEntered(),
    'Security ID': this._returnSecurityId(),
    'Security Symbol': this._returnSymbol(),
  });

  _logSeeMorePricingDismiss = () => {
    const event = 'Dismiss See More Pricing In Order Form';
    const properties = {
      'Using Basket': this.isUsingBasket(),
      'Security ID': this._returnSecurityId(),
      'Stock Symbol': this._returnSymbol(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logPanelOpen = () => {
    const event = 'Manual Trade Panel Open';
    const properties = {
      'Using Basket': this.isUsingBasket(),
      'Security ID': this._returnSecurityId(),
      'Stock Symbol': this._returnSymbol(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logEditPanelOpen = () => {
    const event = 'Edit Trade Panel Open';
    const properties = {
      'Using Basket': this.isUsingBasket(),
      'Security ID': this._returnSecurityId(),
      'Stock Symbol': this._returnSymbol(),
      'From Optimizer': this._isFromOptimizer(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logManualOrderCancel = (isOverlay) => {
    const event = 'Click Close Manual Trade Panel';
    const properties = {
      'Clicked Overlay': isOverlay,
      'Using Basket': this.isUsingBasket(),
      'Security ID': this._returnSecurityId(),
      'Stock Symbol': this._returnSymbol(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logEditOrderCancel = (isOverlay) => {
    const event = 'Click Close Edit Trade Panel';
    const properties = {
      'Clicked Overlay': isOverlay,
      'Using Basket': this.isUsingBasket(),
      'Security ID': this._returnSecurityId(),
      'Stock Symbol': this._returnSymbol(),
      'From Optimizer': this._isFromOptimizer(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logCoverAllClick = () => {
    const event = 'Clicked Cover All Shares';
    const properties = {
      'Using Basket': this.isUsingBasket(),
      'Security ID': this._returnSecurityId(),
      'Stock Symbol': this._returnSymbol(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logSellAllClick = () => {
    const event = 'Clicked Sell All Shares';
    const properties = {
      'Using Basket': this.isUsingBasket(),
      'Security ID': this._returnSecurityId(),
      'Stock Symbol': this._returnSymbol(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logSubmitInvalidOrderFormForm = () => {
    const event = 'Submitted Invalid Order Form';
    const properties = {
      'Using Basket': this.isUsingBasket(),
      Errors: this._returnFormErrors(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logSeeMorePricingClick = () => {
    const event = 'Clicked See More Pricing';
    const properties = {
      'Using Basket': this.isUsingBasket(),
      Context: 'Order Form',
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logCreateManualOrder = () => {
    this._logCreateOrder();

    const event = 'Create Manual Trade';
    const properties = {
      'Using Basket': this.isUsingBasket(),
      ...this.returnOrderDataForTracking(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  _logCreateOrder = () => {
    // new amp event to group all possible trade types
    const properties = {
      'Using Basket': this.isUsingBasket(),
      ...this.returnOrderDataForTracking(),
      'Order Creation Type': 'Manual',
    };
    TrackingEvents.orders.PLACED_TRADE.send(properties);
  };

  _logUpdateManualOrder = () => {
    const event = 'Edit Manual Trade';
    const properties = {
      'Using Basket': this.isUsingBasket(),
      ...this.returnOrderDataForTracking(),
    };
    this.props.actions.logMetricsTrackingEvent(event, properties);
  };

  // _returnCannotShortMessage = () => `You cannot short sell ${this._returnSecurity().symbol} because it is not easy to borrow`;
  // margin trading features removed on 1/22/21
  _returnCannotShortMessage = () => `You must own shares of ${this._returnSecurity().symbol} to sell`;

  // if warning validation is not apart of main validation, do not use as the main validation will overwrite this later, this may need refactor if use is needed
  _addWarning = (warning) => {
    this.setState((prevState) => ({
      warnings: [...prevState.warnings, warning],
    }));
  };
  // if error validation is not apart of main validation, do not use as the main validation will overwrite this later, this may need refactor if use is needed
  _addError = (error) => {
    this.setState((prevState) => ({
      errors: [...prevState.errors, error],
    }));
  };

  _overwriteWarnings = (warnings) => {
    this.setState(() => ({
      warnings,
    }));
  };
  _overwriteErrors = (errors) => {
    this.setState(() => ({
      errors,
    }));
  };

  _showMorePricing = () => {
    this.setState(() => ({
      shouldShowMorePricing: true,
    }));
  };

  _hideMorePricing = () => {
    this.setState(() => ({
      shouldShowMorePricing: false,
    }));
  };

  _startCreatingOrder = () => {
    this.setState(() => ({
      isCreatingOrder: true,
    }));
  };

  _endCreatingOrder = () => {
    this.setState(() => ({
      isCreatingOrder: false,
    }));
  };
}

const mapStateToProps = (state, ownProps) => {
  const securityId = parseInt(ownProps.securityId, 10);
  const isFractionalSupportedSecurity = state.securities.lookup[securityId]?.is_fractional_allowed;
  return {
    currentUser: state.currentUser,
    ideas: state.ideas,
    orders: state.orders,
    portfolio: state.portfolio,
    securities: state.securities,
    securitiesPrice: state.securitiesPrice,
    isFractionalSupportedSecurity: isFractionalSupportedSecurity !== false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderForm);
export default withRouter(connectedComponent);
