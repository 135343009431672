import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import BlankCell from '../BlankCells/BlankCell';

import { snakeToHyphens, isUndefinedOrNull } from '../../../../helpers/generalHelpers';
import { formatDataValue } from '../../../../helpers/securitiesHelpers';
import { getIsUsingRebalancerForPortfolioOptimization } from '../../../../helpers/portfolioOptimizationHelpers';

export class OptimizerCashCell extends Component {
  render() {
    const isUsingRebalancer = getIsUsingRebalancerForPortfolioOptimization();
    const columnKeyName = this.props.column.keyName;

    if (isUsingRebalancer) return <BlankCell />;

    return (
      <td className={`security-table-${snakeToHyphens(columnKeyName)}`}>
        {this._isLoading() ? this.props.loadingCellComponent : this._renderCellContent()}
      </td>
    );
  }

  _isLoading = () => {
    return this.props.optimizer.data && !('optimized_cash' in this.props.optimizer.data);
  };

  _renderCellContent = () => {
    const optimizedCashData = this.props.optimizer.data.optimized_cash;
    const optimizedCashValue = optimizedCashData.value || 0;
    const optimizedCashPercentage = optimizedCashData.allocation_percent || 0;
    return (
      <div className="security-table-optimizer-cash-cell">
        {!isUndefinedOrNull(this.props.optimizer.data.portfolio_stats.current.total_value) && (
          <div className={'portfolio-cash'}>{formatDataValue(optimizedCashValue, 'price')}</div>
        )}
        <div className={'portfolio-cash-percentage-of-equity'}>
          {formatDataValue(optimizedCashPercentage, 'percentage')}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    optimizer: state.optimizer,
  };
};

const composedComponent = compose(connect(mapStateToProps, null))(OptimizerCashCell);

export default composedComponent;
