import React from 'react';
import Icon from '../../../../components/misc/Icon';
import { Body5 } from '../../../../main/lib/nvstr-common-ui.es';
import styled from 'styled-components';

const UploadButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 5px;
  padding: 8px 16px;
  background: ${({ theme }) => theme.themeColors.primaryCtaButton};

  * {
    color: ${({ theme }) => theme.themeColors.buttonText};
  }
`;

const SelectDocumentButton = (props) => {
  const { onChange, documentType, selectedDocument, isFront, isBack } = props;
  const handleChange = (e) => {
    const metadata = {
      selectedDocument,
      documentType,
      isFront,
      isBack,
    };
    onChange(e, metadata);
  };
  const id = `${documentType}_upload_document_${isBack ? 'back' : 'front'}`;
  return (
    <div>
      <label for={id} htmlFor={id}>
        <UploadButtonWrapper>
          <Icon icon={'fa fa-upload'} />
          <div style={{ paddingLeft: '8px' }}>
            <Body5 isSmall>Choose File/ Take Photo</Body5>
          </div>
        </UploadButtonWrapper>
      </label>
      <div style={{ paddingTop: '8px', display: 'none' }}>
        <input id={id} name={id} type="file" onChange={handleChange} multiple={false} />
      </div>
    </div>
  );
};

export default SelectDocumentButton;
