import React from 'react';
import { formatForDisplay } from '../../../helpers/displayHelpers';
import { isUndefinedOrNull } from '../../../helpers/generalHelpers';
import { isFloatOrInteger } from '../../../helpers/numberHelpers';
import { throwError } from '../../../helpers/devToolHelpers';

const Number = (props) => {
  const {
    className,
    value,
    type, // type for formatForDisplay reference displayHelpers.js
  } = props;
  if (isUndefinedOrNull(value) || !isFloatOrInteger(value)) {
    throwError('Invalid value passed to Number, expecting float or integer', true, { props });
    return null;
  }
  const displayString = formatForDisplay(value, type);
  return <span className={`number-container ${className || ''}`}>{displayString}</span>;
};

export default Number;
