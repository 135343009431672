import React from 'react';

import unrankedBadge from '../../assets/images/badges/badge-icon-unranked.png';
import bronzeBadge from '../../assets/images/badges/badge-icon-bronze.png';
import silverBadge from '../../assets/images/badges/badge-icon-silver.png';
import goldBadge from '../../assets/images/badges/badge-icon-gold.png';
import platinumBadge from '../../assets/images/badges/badge-icon-platinum.png';
import eliteBadge from '../../assets/images/badges/badge-icon-elite.png';

const Badge = (props) => {
  const { badgeName } = props;

  const getImageFilenameForBadge = (badgeName) => {
    switch (badgeName) {
      case 'unranked':
        return unrankedBadge;
      case 'bronze':
        return bronzeBadge;
      case 'silver':
        return silverBadge;
      case 'gold':
        return goldBadge;
      case 'platinum':
        return platinumBadge;
      case 'elite':
        return eliteBadge;
    }
  };

  const renderBadgeIcon = () => {
    const imageFilename = getImageFilenameForBadge(badgeName);
    return <img src={imageFilename} className="badge-image" />;
  };

  return renderBadgeIcon();
};

export default Badge;
