import React from 'react';
import { connect } from 'react-redux';

import { showInfoIconModal } from '../../actions';
import ToolTip from '../../main/icons/Tooltip';

/*
  NEW VERSION OF INFOICON.JS

  improvements:
    - wont get clipped by overflow as long as position is not used in a parent that is a child of overflow

*/

const InfoIconTooltip = (props) => {
  const {
    definitionKey,
    symbol,

    customDefinition,

    position: positionKey,
    className,
    conditionalProps,
    style,
    dispatch,
  } = props;

  const containerRef = React.useRef();

  const handleIconClick = () => {
    const data = {
      elementRef: containerRef,

      definitionKey,
      symbol,

      customDefinition,

      positionKey,
      className,
      conditionalProps,
    };
    showInfoIconModal(data)(dispatch);
  };

  return (
    <span
      tabIndex="-1"
      className={`react-info-icon ${className}`}
      onFocus={handleIconClick}
      ref={containerRef}
      style={{
        position: 'static',
        top: 'auto',
        right: 'auto',
        bottom: 'auto',
        left: 'auto',
        ...(style || {}),
      }}
      {...conditionalProps}
    >
      <ToolTip width={'13px'} />
    </span>
  );
};

InfoIconTooltip.defaultProps = {
  position: 'default',
  className: '',
  conditionalProps: {},
};

export default connect()(InfoIconTooltip);
