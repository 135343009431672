import { livePriceActionTypes, marketMonitorActionTypes } from '@src/constants';

const defaultState = {
  lookupById: [],
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case livePriceActionTypes.updatePrices: {
      const updates = {};
      const data = action.payload;
      if (!data || data.length < 1) {
        return state;
      }
      data.forEach((securityPriceData) => {
        const { id } = securityPriceData;
        updates[id] = securityPriceData;
      });
      return {
        lookupById: {
          ...state.lookupById,
          ...updates,
        },
      };
    }

    default:
      return state;
  }
}
