import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import withWindowSize from '../HOCS/withWindowSize';

import * as Actions from '../../actions/index';

import TableHeading from './TableHeading';
import TableBody from './TableBody';

import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import { getIsUsingRebalancerForPortfolioOptimization } from '../../helpers/portfolioOptimizationHelpers';

class SecurityTable extends Component {
  constructor() {
    super();

    this.state = {
      defaultColumns: [
        'ideaType',
        'identifier',
        'currentPrice',
        'portfolioIdeaCell',
        'ideaActive',
        'allocation',
        'removeIdea',
      ],
      defaultOnboardingColumns: [
        'ideaType',
        'identifier',
        'currentPrice',
        'portfolioIdeaCell',
        'ideaActive',
        'removeIdea',
      ],
    };
  }

  render() {
    const concernables = this.props.concernables;
    const sortedConcernables = { ...concernables, list: concernables.list };
    return (
      <div className="security-table-drop-container">
        {this.renderTable(this.generateColumns(), sortedConcernables)}
      </div>
    );
  }

  renderTable = (columns, concernables) => {
    if (!concernables || concernables.list.length === 0) {
      return <div className="empty-security-table-container"></div>;
    }

    return (
      <div ref={(node) => (this.SecurityTableContainerNode = node)} className="security-table-container">
        {this.props.tableTitle && <div className="security-table-title">{this.props.tableTitle}</div>}
        <table className={`security-table ${this._returnSizeClass()}`}>
          {!this.props.hideHeadingRow && <TableHeading columns={columns} />}
          <TableBody
            columns={columns}
            concernables={concernables}
            customTrailingRows={
              this.props.customTrailingRows && this.props.customTrailingRows.map((row) => this.generateColumns(row))
            }
            footerRowColumns={this.props.footerRow && this.generateColumns(this.props.footerRow)}
            additionalTableCellProps={this.props.additionalTableCellProps}
          />
        </table>
      </div>
    );
  };

  generateColumns = (customColumns) => {
    const defaultColumns = this.state.defaultColumns;
    const columnsToDisplay = customColumns || this.props.columns || defaultColumns;
    return columnsToDisplay.map((columnName) => this.tableColumns(columnName));
  };

  _returnSecurityTableContainerNode = () => this.SecurityTableContainerNode;

  _return$SecurityTableContainerNode = () => window.$(this._returnSecurityTableContainerNode());

  _returnSecurityTableContainerOuterWidth = () => {
    const width = this._return$SecurityTableContainerNode().outerWidth();
    return isUndefinedOrNull(width) ? window.$(window).width() : width;
  };

  _isMobileWidth = () =>
    !isUndefinedOrNull(this._returnSecurityTableContainerOuterWidth()) &&
    this._returnSecurityTableContainerOuterWidth() < 450;

  _returnSizeClass = () => (this._isMobileWidth() ? 'mobile' : '');

  tableColumns = (columnName) => {
    const lookupTable = {
      blank: {
        headingDisplayName: '',
        keyName: 'blank',
      },
      optimizerBlank: {
        headingDisplayName: '',
        keyName: 'optimizer_blank',
      },
      cashCell: {
        headingDisplayName: '',
        keyName: 'cash_cell',
      },
      tradeAllOptimizedOrders: {
        headingDisplayName: '',
        keyName: 'trade_all_optimized_orders',
      },
      portfolioCash: {
        headingDisplayName: '',
        keyName: 'portfolio_cash',
      },
      optimizerCash: {
        headingDisplayName: '',
        keyName: 'optimizer_cash',
      },
      ideaType: {
        headingDisplayName: '',
        keyName: 'idea_type',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      ideaBuySell: {
        headingDisplayName: 'Trade',
        keyName: 'idea_buy_sell',
      },
      optionType: {
        headingDisplayName: 'Type',
        keyName: 'blank',
      },
      expiryDate: {
        headingDisplayName: 'Expires',
        keyName: 'blank',
      },
      strikePrice: {
        headingDisplayName: 'Strike Price',
        keyName: 'blank',
      },
      identifier: {
        headingDisplayName: '',
        keyName: 'identifier',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      identifierWithIdeaType: {
        headingDisplayName: '',
        keyName: 'identifier_with_idea_type',
        buildExpandedSecurityCardProperties: (security, idea) => {
          return idea
            ? {
                panel: 'idea',
                securityId: security.security_id,
                activeTab: 'ideas',
                ideaId: idea.idea_id,
              }
            : {
                panel: 'security',
                securityId: security.security_id,
                activeTab: 'ideas',
              };
        },
      },
      identifierWithCurrentPrice: {
        headingDisplayName: '',
        keyName: 'identifier_with_current_price',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      dashIdentifierWithPrice: {
        headingDisplayName: '',
        keyName: 'dash_identifier_with_current_price',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      identifierWithPriceNoChangeCell: {
        headingDisplayName: '',
        keyName: 'identifier_with_current_price_no_change',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      currentPrice: {
        headingDisplayName: 'Current Price',
        keyName: 'current_price',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      expectedReturn: {
        headingDisplayName: 'Expected Return',
        keyName: 'expected_return',
        infoIcon: true,
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      ideaActive: {
        headingDisplayName: getIsUsingRebalancerForPortfolioOptimization()
          ? 'Include In Rebalancer'
          : 'Include In Optimizer',
        keyName: 'active',
        buildExpandedSecurityCardProperties: () => false,
      },
      optimizeAllocation: {
        headingDisplayName: 'Optimized Allocation',
        keyName: 'optimize_allocation',
        buildExpandedSecurityCardProperties: () => false,
      },
      portfolioIdeaCell: {
        headingDisplayName: 'Your Expected Return',
        keyName: 'portfolio_idea_cell',
        infoIcon: true,
        infoIconPosition: 'right',
        customInfoIconName: 'expected_return',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      profilePortfolioIdeaCell: {
        headingDisplayName: 'Expected Return',
        keyName: 'portfolio_idea_cell',
        infoIcon: true,
        infoIconPosition: 'right',
        customInfoIconName: 'expected_return',
        buildExpandedSecurityCardProperties: (security, idea) => {
          return idea
            ? {
                panel: 'idea',
                securityId: security.security_i6d,
                activeTab: 'ideas',
                ideaId: idea.idea_id,
              }
            : {
                panel: 'security',
                securityId: security.security_id,
                activeTab: 'ideas',
              };
        },
      },
      ideaDayAndTotalChange: {
        headingDisplayName: 'Gain/Loss',
        keyName: 'idea_day_and_total_change',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      portfolioAllocation: {
        headingDisplayName: 'Allocation',
        keyName: 'portfolio_allocation',
        infoIcon: true,
        infoIconPosition: 'right',
        customInfoIconName: 'allocation',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      profileSecurityAllocationCell: {
        headingDisplayName: 'Current Allocation',
        keyName: 'profile_security_allocation_cell',
        infoIcon: true,
        infoIconPosition: 'right',
        customInfoIconName: 'allocation',
        buildExpandedSecurityCardProperties: (security, idea) => {
          return idea
            ? {
                panel: 'idea',
                securityId: security.security_id,
                activeTab: 'ideas',
                ideaId: idea.idea_id,
              }
            : {
                panel: 'security',
                securityId: security.security_id,
                activeTab: 'ideas',
              };
        },
      },
      ideaPerformanceCell: {
        headingDisplayName: 'Idea Performance',
        keyName: 'idea_performance_cell',
        buildExpandedSecurityCardProperties: (security, idea) => {
          return idea
            ? {
                panel: 'idea',
                securityId: security.security_id,
                activeTab: 'ideas',
                ideaId: idea.idea_id,
              }
            : {
                panel: 'security',
                securityId: security.security_id,
                activeTab: 'ideas',
              };
        },
      },
      identifierWithIdeaTypeCollective: {
        headingDisplayName: '',
        keyName: 'identifier_with_idea_type_collective',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      collectiveUserIdeaPerformanceCell: {
        headingDisplayName: 'Idea Performance',
        keyName: 'collective_user_idea_performance_cell',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      allocationAndDayChange: {
        headingDisplayName: 'Current Allocation',
        keyName: 'allocation_and_day_change',
        infoIcon: true,
        customInfoIconName: 'allocation',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      trade: {
        headingDisplayName: '',
        keyName: 'trade',
        buildExpandedSecurityCardProperties: () => false,
      },
      optimizedAllocationWithTrade: {
        headingDisplayName: 'Optimized Allocation',
        keyName: 'optimized_allocation_with_trade',
        infoIcon: true,
        infoIconPosition: 'right',
        customInfoIconName: 'optimized_allocation',
        buildExpandedSecurityCardProperties: () => false,
      },
      allocation: {
        headingDisplayName: 'Allocation',
        keyName: 'allocation',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      removeIdea: {
        headingDisplayName: '',
        keyName: 'remove_idea',
        buildExpandedSecurityCardProperties: () => false,
      },
      ideaAskWhy: {
        headingDisplayName: '',
        keyName: 'ask_why',
        buildExpandedSecurityCardProperties: () => false,
      },
      ideaView: {
        headingDisplayName: '',
        keyName: 'view_idea',
        buildExpandedSecurityCardProperties: () => false,
      },
      collectiveUserDataCell: {
        headingDisplayName: '',
        keyName: 'collective_user_data_cell',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      thoughtLeaderDataCell: {
        headingDisplayName: '',
        keyName: 'thought_leader_data_cell',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      identifierSymbol: {
        headingDisplayName: '',
        keyName: 'identifier_symbol',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      ideaDayChange: {
        headingDisplayName: 'Today',
        keyName: 'position_day_change_percentage_cell',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      ideaTotalChange: {
        headingDisplayName: 'Total',
        keyName: 'position_total_change_percentage_cell',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      externalPositionsIdentifier: {
        headingDisplayName: '',
        keyName: 'external_position_identifier',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      externalPositionAllocation: {
        headingDisplayName: 'Amount',
        keyName: 'external_position_amount_cell',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      externalPositionDayChange: {
        headingDisplayName: 'Today',
        keyName: 'external_position_day_change_percentage_cell',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      externalPositionTotalChange: {
        headingDisplayName: 'Total',
        keyName: 'external_position_total_change_percentage_cell',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      dashIdeaDayChange: {
        headingDisplayName: 'Today',
        keyName: 'position_day_change_percentage_cell',
        onDashboard: true,
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      dashIdeaTotalChange: {
        headingDisplayName: 'Total',
        keyName: 'position_total_change_percentage_cell',
        onDashboard: true,
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
      portfolioAllocationPercentage: {
        headingDisplayName: 'Allocation',
        keyName: 'position_allocation_percentage_cell',
        infoIcon: true,
        infoIconPosition: 'right',
        customInfoIconName: 'allocation',
        buildExpandedSecurityCardProperties: (security) => ({
          panel: 'security',
          securityId: security.security_id,
          activeTab: 'ideas',
        }),
      },
    };

    return lookupTable[columnName] || null;
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

const composedComponent = compose(withWindowSize, connect(null, mapDispatchToProps))(SecurityTable);

export default composedComponent;

// DISABLE DRAG AND DROP
// export default compose(
//   DropTarget(ItemTypes.CARD, wellTarget, collect),
//   connect(mapStateToProps, mapDispatchToProps)
// )(SecurityTable);
