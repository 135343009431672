import React from 'react';
import { isUndefinedOrNull } from '../../helpers/generalHelpers';
import Icon from '../../components/misc/Icon';

class Dropdown extends React.Component {
  render() {
    return (
      <fieldset className={`dropdown-container ${this._returnName()} ${this._returnClassName()}`}>
        {this._renderLabel()}
        <div style={{ position: 'relative' }}>
          {this._renderDropdown()}
          {this.props.showArrowIcons && (
            <Icon
              size={'small'}
              icon={'fa-sort-asc'}
              colorClassName={' '}
              style={{ position: 'absolute', top: '14px', right: '10px' }}
            />
          )}
          {this.props.showArrowIcons && (
            <Icon
              size={'small'}
              icon={'fa-sort-desc'}
              colorClassName={' '}
              style={{ position: 'absolute', top: '15px', right: '10px' }}
            />
          )}
        </div>
      </fieldset>
    );
  }

  _renderLabel = () => {
    return this._shouldShowLabel() && <label>{this._returnLabel()}</label>;
  };

  _renderDropdown = () => {
    return (
      <select
        name={this._returnName()}
        value={this._returnValue() ? this._returnValue().name : ''}
        onChange={this._handleDropdownSelection}
      >
        {this._returnOptionValues().map((value, i) => this._renderOption(value, i))}
      </select>
    );
  };

  _renderOption = (value, i) => {
    const text = value.displayName;
    const disabledMessage = value.disabledReason();
    const textWithDisabledMessage = text + ' - ' + disabledMessage;
    return (
      <option
        value={value.name}
        key={`${this._returnName()}-${value.name}-${i}`}
        className={`option-${this._returnName()}-${value.name}`}
        disabled={this._isDisabled(value)}
      >
        {this._isDisabled(value) ? textWithDisabledMessage : text}
      </option>
    );
  };

  _renderErrorMessage = () => {
    const name = this._returnName();
    const isError = !!this.props.getErrorMessage(name);
    const errorMessage = isError ? this.props.getErrorMessage(name) : '';
    return <p className={`text-field-error-message`}>{errorMessage}</p>;
  };

  _returnOptionValues = () => {
    const values = this.props.values;
    const labelValue = {
      id: null,
      name: this._returnLabel(),
      displayName: this._returnLabel(),
      isEnabled: () => true,
      disabledReason: () => null,
    };
    return this._shouldIncludeLabelAsSelection() ? [labelValue, ...values] : values;
  };

  _returnName = () => this.props.name || '';

  _shouldIncludeLabelAsSelection = () =>
    this.props.label && this.props.label !== '' && this.props.includeLabelAsSelection;

  _returnLabel = () => this.props.label;

  _returnFormValue = () => this.props.getValue(this._returnName());

  _returnValue = () => {
    const value = this._returnFormValue();
    return isUndefinedOrNull(value) ? null : value;
  };
  _returnClassName = () => this.props.styling || '';

  _shouldShowLabel = () => {
    if (isUndefinedOrNull(this._returnLabel())) {
      return false;
    }
    const value = this._returnValue();
    return this.props.forceShowLabel || this._returnLabel() !== value;
  };

  _convertSelectionValueToValue = (selectionNameValue) => {
    return this._returnOptionValues().filter((value) => value.name === selectionNameValue)[0];
  };

  _isDisabled = (value) => {
    if (!('isEnabled' in value)) {
      return false;
    }
    return !value.isEnabled();
  };

  _handleDropdownSelection = (e) => {
    const selectionNameValue = e.target.value;
    const value = this._convertSelectionValueToValue(selectionNameValue);
    if (this._isDisabled(value)) {
      return false;
    } else {
      this.props.handleChange(this._returnName(), value);
      this._handleAdditionalActions(value);
      this._saveOnChange();
    }
  };

  _handleAdditionalActions = (value) => {
    if (this.props.handleAdditionalActions) {
      this.props.handleAdditionalActions(value, this._returnName());
    }
  };

  // used if you want to persist after change, saveOnChange must be a function that makes the ajax call
  _saveOnChange = (data) => {
    // TODO:
    // this might not work
    // if (this.props.saveOnChange) {
    //   const data = { [`${this.props.objectType}_id`]: this.props.getObjectId(this.props.objectType), [name]: selectionValues[i] };
    //   this.props.saveOnChange(data);
    // }
  };
}

export default Dropdown;
