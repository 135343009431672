import React from 'react';
import styled from 'styled-components';
import { SignInForm } from '@src/main/containers/SignIn/SignInForm';
import { PageCard } from '@src/main/components/layout';
import { PageCardWrapper } from '@src/main/components/layout/PageCard';
import { PublicPageMessaging } from '@src/main/components/public/PublicPageMessaging';
import { SplitPage } from '@src/main/components/layout/SplitPage';
import { Container } from '@src/main/components/ui';
import { BaseButton, BaseButtonWrapper, FlatButton, SkeletonButton } from '@src/main/components/buttons';
import { getItemFromStorage, INTERNAL_STORAGE_KEYS } from '@src/utils/storage';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Body1 } from '@src/main/lib/nvstr-common-ui.es';
import { FullLogo, TornadoFullLogo } from '@src/main/components/logo/FullLogo';
import { PRODUCT_NAME, PRODUCT_NAMES } from '@src/appConfig';
import { isSignedIn } from '@src/main/containers/SignIn/utils/services';

const SignInFormWrapper = styled.div`
  ${PageCardWrapper} {
    padding: 24px;

    -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  }
`;

const ActionSelectionWrapper = styled.div`
  min-width: 320px;
`;

const LogoButtonWrapper = styled.div`
  width: 100%;

  ${BaseButtonWrapper} {
    width: 100%;
    max-width: 380px;
    background: none;
    color: ${({ theme }) => theme.themeColors.text};
    fill: ${({ theme }) => theme.themeColors.text};
    border: 1px solid ${({ theme }) => theme.themeColors.text};

    ${({ small, smallNoVert }) => (smallNoVert ? `padding: 0px 8px` : small ? `padding: 4px 8px` : '')};

    * {
      color: ${({ theme }) => theme.themeColors.text};
      fill: ${({ theme }) => theme.themeColors.text};
    }

    :hover {
      background: ${({ theme }) => theme.themeColors.text};
      color: ${({ theme }) => theme.themeColors.buttonText};
      fill: ${({ theme }) => theme.themeColors.buttonText};
      border: 1px solid ${({ theme }) => theme.themeColors.text};
      opacity: 0.8;

      * {
        color: ${({ theme }) => theme.themeColors.buttonText};
        fill: ${({ theme }) => theme.themeColors.buttonText};
      }
    }
  }
`;

const LogoButton = ({ onClick, children }) => {
  return (
    <LogoButtonWrapper>
      <BaseButton onClick={onClick}>{children}</BaseButton>
    </LogoButtonWrapper>
  );
};

const PartnerSignInTypeSelect = ({ onLoginTornadoClick }) => {
  const navigate = useNavigate();
  const [alreadyHasAccount, setAlreadyHasAccount] = React.useState(false);

  const signUpClick = () => {
    navigate('/signup');
  };
  const loginClick = () => {
    onLoginTornadoClick();
  };

  const alreadyHasAccountClick = () => {
    setAlreadyHasAccount(true);
  };

  if (alreadyHasAccount) {
    return (
      <SplitPage
        LeftComponent={<PublicPageMessaging />}
        RightComponent={
          <SignInFormWrapper>
            <PageCard>
              <ActionSelectionWrapper>
                <Container>
                  <Body1 bold>Where was your account previously used?</Body1>
                </Container>
                <Container top={24}>
                  <LogoButton onClick={signUpClick}>TheStreet.com</LogoButton>
                </Container>
                <Container top={16}>
                  <LogoButton onClick={loginClick}>
                    <TornadoFullLogo />
                  </LogoButton>
                </Container>
              </ActionSelectionWrapper>
            </PageCard>
          </SignInFormWrapper>
        }
      />
    );
  }

  return (
    <SplitPage
      LeftComponent={<PublicPageMessaging />}
      RightComponent={
        <SignInFormWrapper>
          <PageCard>
            <ActionSelectionWrapper>
              <Container>
                <FlatButton fullWidth onClick={signUpClick}>
                  Sign Up
                </FlatButton>
              </Container>
              <Container top={16}>
                <SkeletonButton fullWidth onClick={alreadyHasAccountClick}>
                  Already have an account?
                </SkeletonButton>
              </Container>
            </ActionSelectionWrapper>
          </PageCard>
        </SignInFormWrapper>
      }
    />
  );
};

const hasLoggedInBefore = () => {
  const email = getItemFromStorage(INTERNAL_STORAGE_KEYS.EMAIL);
  return email?.length > 0;
};

export const SignIn = () => {
  const [searchParams] = useSearchParams();
  const [hasLogin, setHasLogin] = React.useState(hasLoggedInBefore() || PRODUCT_NAME === PRODUCT_NAMES.Tornado);
  const [forceTornadoLogin, setForceTornadoLogin] = React.useState(false);

  const onLoginTornadoClick = () => {
    setHasLogin(true);
  };

  React.useEffect(() => {
    if (searchParams.get('force') === 'true') {
      setForceTornadoLogin(true);
    }
  }, []);

  React.useEffect(() => {
    const checkIfAlreadyAuthed = async () => {
      const { isAuthed } = await isSignedIn();
      if (isAuthed) {
        window.location = '/app/';
      }
    };
    checkIfAlreadyAuthed();
  }, []);

  if (hasLogin || forceTornadoLogin) {
    return (
      <SplitPage
        LeftComponent={<PublicPageMessaging />}
        RightComponent={
          <SignInFormWrapper>
            <PageCard>
              <SignInForm />
            </PageCard>
          </SignInFormWrapper>
        }
      />
    );
  } else {
    return <PartnerSignInTypeSelect onLoginTornadoClick={onLoginTornadoClick} />;
  }
};
