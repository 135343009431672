export const SECURITY_FILTER_TYPES = {
  UPCOMING_EARNINGS: 'upcoming_earnings', // this doesn't work with other filters
  TRENDING_STOCKS: 'trending_stocks', // this doesn't work with other filters

  COLLECTIVE_INTELLIGENCE: 'collective_intelligence',

  SECTOR: 'sector',

  DIVIDEND_YIELD: 'dividend_yield',
  MARKET_CAP: 'market_cap',
  REVENUE_LTM: 'revenue_ltm',
  PROJECTED_REVENUE_GROWTH: 'projected_revenue_growth',
  THOUGHT_LEADERS: 'owned_by_thought_leaders',
  CONNECTIONS: 'on_connections_watchlists',

  PRICE: 'price',

  FORWARD_PE: 'forward_pe',
  PRICE_TO_EARNINGS: 'price_earnings',
  FORWARD_PE_CURR_YR: 'forward_pe_curr_yr',
  FORWARD_PE_NEXT_YR: 'forward_pe_next_yr',
  PRICE_TO_SALES: 'price_sales',
  FORWARD_PS_CURR_YR: 'forward_ps_curr_yr',
  FORWARD_PS: 'forward_ps',
  FORWARD_PS_NEXT_YR: 'forward_ps_next_yr',
  PRICE_TO_TANGIBLE_BOOK: 'price_tangible_book',

  FORWARD_REVENUE_CURR_YR: 'forward_revenue_curr_yr',
  FORWARD_REVENUE: 'forward_revenue',
  FORWARD_REVENUE_NEXT_YR: 'forward_revenue_next_yr',

  NET_DEBT: 'net_debt',
  AVG_CORRELATION: 'avg_correlation',
};

export const SECTOR_ID_PRESETS = {
  // {"sector_id": 25,"description": "Computer Communications"},
  // {"sector_id": 26,"description": "Computer Peripherals"},
  // {"sector_id": 27,"description": "Computer Processing Hardware"},
  // {"sector_id": 66,"description": "Information Technology Services"},
  // {"sector_id": 70,"description": "Internet Software/Services"},
  // {"sector_id": 103,"description": "Packaged Software"},
  // {"sector_id": 121,"description": "Semiconductors"},
  // {"sector_id": 130,"description": "Telecommunications Equipment"},
  TECHNOLOGY: [25, 26, 27, 66, 70, 103, 121, 130],
  // {"sector_id": 46,"description": "Finance/Rental/Leasing"},
  // {"sector_id": 47,"description": "Financial Conglomerates"},
  // {"sector_id": 67,"description": "Insurance Brokers/Services"},
  // {"sector_id": 71,"description": "Investment Banks/Brokers"},
  // {"sector_id": 72,"description": "Investment Managers"},
  // {"sector_id": 74,"description": "Life/Health Insurance"},
  // {"sector_id": 76, "description": "Major Banks"},
  // {"sector_id": 90,"description": "Multi-Line Insurance"},
  // {"sector_id": 109,"description": "Property/Casualty Insurance"},
  // {"sector_id": 118,"description": "Regional Banks"},
  // {"sector_id": 120,"description": "Savings Banks"},
  // {"sector_id": 124,"description": "Specialty Insurance"},
  FINANCIAL: [46, 47, 67, 71, 72, 74, 76, 90, 109, 118, 120, 124],
  // {"sector_id": 14,"description": "Biotechnology"},
  // {"sector_id": 60,"description": "Hospital/Nursing Management"},
  // {"sector_id": 78,"description": "Managed Health Care"},
  // {"sector_id": 81,"description": "Medical Distributors"},
  // {"sector_id": 82,"description": "Medical Specialties"},
  // {"sector_id": 83,"description": "Medical/Nursing Services"},
  // {"sector_id": 105,"description": "Pharmaceuticals: Generic"},
  // {"sector_id": 106,"description": "Pharmaceuticals: Major"},
  // {"sector_id": 107,"description": "Pharmaceuticals: Other"},
  // {"sector_id": 122,"description": "Services to the Health Industry"},
  HEALTHCARE: [14, 60, 78, 81, 82, 83, 105, 106, 107, 122],
  // {"sector_id": 6,"description": "Alternative Power Generation"},
  // {"sector_id": 23,"description": "Coal"},
  // {"sector_id": 31,"description": "Contract Drilling"},
  // {"sector_id": 36,"description": "Electric Utilities"},
  // {"sector_id": 37,"description": "Electrical Products"},
  // {"sector_id": 38,"description": "Electronic Components"},
  // {"sector_id": 39,"description": "Electronic Equipment/Instruments"},
  // {"sector_id": 40,"description": "Electronic Production Equipment"},
  // {"sector_id": 55,"description": "Gas Distributors"},
  // {"sector_id": 68,"description": "Integrated Oil"},
  // {"sector_id": 95,"description": "Oil & Gas Pipelines"},
  // {"sector_id": 96,"description": "Oil & Gas Production"},
  // {"sector_id": 97,"description": "Oil Refining/Marketing"},
  // {"sector_id": 98,"description": "Oilfield Services/Equipment"},
  // {"sector_id": 137,"description": "Water Utilities"},
  ENERGY: [6, 23, 31, 36, 37, 38, 39, 40, 55, 68, 95, 96, 97, 98, 137],
};

export const SECURITY_FILTER_OPERATORS = {
  OR: '||',

  GREATER_THAN: '>',
  LESS_THAN: '<',
};

class SecurityFilter {
  constructor({ type, filterIds, operator, operatorValue = null }) {
    const typeToApiName = {
      [SECURITY_FILTER_TYPES.COLLECTIVE_INTELLIGENCE]: 'Collective Intelligence',
      [SECURITY_FILTER_TYPES.SECTOR]: 'Sectors',

      [SECURITY_FILTER_TYPES.DIVIDEND_YIELD]: 'Dividend Yield',
      [SECURITY_FILTER_TYPES.MARKET_CAP]: 'Market Cap',
      [SECURITY_FILTER_TYPES.REVENUE_LTM]: 'Revenue LTM',
      [SECURITY_FILTER_TYPES.PROJECTED_REVENUE_GROWTH]: 'Projected Revenue Growth',
      [SECURITY_FILTER_TYPES.THOUGHT_LEADERS]: 'Owned by Thought Leaders',
      [SECURITY_FILTER_TYPES.CONNECTIONS]: "On Connections' Watchlists",

      [SECURITY_FILTER_TYPES.PRICE]: 'Price',

      [SECURITY_FILTER_TYPES.FORWARD_PE]: 'Forward P/E',
      [SECURITY_FILTER_TYPES.PRICE_TO_EARNINGS]: 'Price / Earnings',
      [SECURITY_FILTER_TYPES.FORWARD_PE_CURR_YR]: 'Forward P/E Curr Yr',
      [SECURITY_FILTER_TYPES.FORWARD_PE_NEXT_YR]: 'Forward P/E Next Yr',
      [SECURITY_FILTER_TYPES.PRICE_TO_SALES]: 'Price / Sales',
      [SECURITY_FILTER_TYPES.FORWARD_PS_CURR_YR]: 'Forward P/S Curr Yr',
      [SECURITY_FILTER_TYPES.FORWARD_PS]: 'Forward P/S',
      [SECURITY_FILTER_TYPES.FORWARD_PS_NEXT_YR]: 'Forward P/S Next Yr',
      [SECURITY_FILTER_TYPES.PRICE_TO_TANGIBLE_BOOK]: 'Price / Tangible Book',

      [SECURITY_FILTER_TYPES.FORWARD_REVENUE_CURR_YR]: 'Forward Revenue Curr Yr',
      [SECURITY_FILTER_TYPES.FORWARD_REVENUE]: 'Forward Revenue',
      [SECURITY_FILTER_TYPES.FORWARD_REVENUE_NEXT_YR]: 'Forward Revenue Next Yr',

      [SECURITY_FILTER_TYPES.NET_DEBT]: 'Net Debt',
      [SECURITY_FILTER_TYPES.AVG_CORRELATION]: 'Avg. Correlation',
    };

    this.type = type;
    this.filterIds = filterIds;

    this.name = typeToApiName[type];

    this.operator = operator || SECURITY_FILTER_OPERATORS.OR;
    this.operatorValue = operatorValue;
  }

  addOperator = (operator, operatorValue = null) => {
    this.operator = operator;
    this.operatorValue = operatorValue;
  };

  toApi = () => {
    const format = {
      name: this.name,
      operation: this.operation,
    };
    const { operator, operatorValue, filterIds } = this;

    if (operator !== null) {
      format.operator = operator;
    }

    if (operatorValue !== null) {
      format.value = operatorValue;
    }

    if (filterIds !== null) {
      format.ids = filterIds;
    }

    return format;
  };
}

export default SecurityFilter;
