import React from 'react';
import Button from '../buttons/Button';
import { FlatButton } from '../../main/components/buttons';
import { Container } from '../../main/components/ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  path {
    fill: ${({ theme }) => theme.themeColors.positive};
  }
`;

const SuccessDisplay = (props) => {
  const {
    message,
    customMessageStyle,

    closeBtnText,
    onCloseClick,
  } = props;

  return (
    <Wrapper className={'success-check-container'}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 1200">
        <path
          className="primary-circle"
          d="M400 850c-138.071 0-250-111.929-250-250s111.929-250 250-250 250 111.929 250 250-111.929 250-250 250z"
        />
        <path
          className="ripple-circle"
          d="M400 850c-138.071 0-250-111.929-250-250s111.929-250 250-250 250 111.929 250 250-111.929 250-250 250z"
        />
        <path
          className="check-mark"
          d="M269.5 592.875l92.5 89.5 169.5-163.75"
          stroke="#FFF"
          strokeWidth="40"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </svg>
      <p className="payment-complete-message" style={customMessageStyle || {}}>
        {message || 'Success'}
      </p>
      {onCloseClick && (
        <Container top={38}>
          <FlatButton onClick={onCloseClick}>{closeBtnText || 'Close'}</FlatButton>
        </Container>
      )}
    </Wrapper>
  );
};

export default SuccessDisplay;
