import React from 'react';

const TextArea = (props) => {
  const { name, label, value } = props;

  const containerClassName = props.containerClassName;

  const handleChange = (e) => {
    const newValue = e.target.value;
    props.handleChange(name, newValue);
  };

  const handleFocus = () => {
    if (props.handleFocus) {
      props.handleFocus(name);
    }
  };

  const handleBlur = (p, e) => {
    if (props.saveOnChange) {
      const data = {
        [`${props.objectType}_id`]: props.getObjectId(props.objectType),
        [name]: value,
      };
      props.saveOnChange(data);
    }

    if (props.handleBlur) {
      props.handleBlur(value, name);
    }

    if ('additionalBlurAction' in props) {
      props.additionalBlurAction();
    }
  };

  return (
    <div className={`${containerClassName || ''} stand-alone-textarea-container`}>
      <fieldset className={``}>
        {label ? (
          <label htmlFor={name} className={`label-${name}`}>
            {label}
          </label>
        ) : null}
        <textarea
          type={props.type || 'text'}
          name={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={props.placeholder || ''}
          value={value}
          autoFocus={props.autoFocus}
        />
      </fieldset>
    </div>
  );
};

export default TextArea;
