import { logMetricsTrackingEvent } from '../../../actions';

const VIEW_LEARN_LESSON_SCREEN = {
  send: (properties) => {
    // Subject
    // Slide

    const event = 'View Learn Lesson Screen';
    logMetricsTrackingEvent(event, properties)();
  },
};

const VIEW_LEARN_DISCLAIMER_SCREEN = {
  send: (properties) => {
    // Subject

    const event = 'View Learn Disclaimer Screen';
    logMetricsTrackingEvent(event, properties)();
  },
};

const VIEW_LEARN_REWARD_SCREEN = {
  send: (properties) => {
    // Subject
    // Amount

    const event = 'View Learn Reward Screen';
    logMetricsTrackingEvent(event, properties)();
  },
};

const COMPLETE_LEARN_LESSON = {
  send: (properties) => {
    // Subject

    const event = 'Complete Learn Lesson';
    logMetricsTrackingEvent(event, properties)();
  },
};

const VIEW_ALL_LEARN_LESSONS_COMPLETED_SCREEN = {
  send: (properties) => {
    // Subject

    const event = 'View All Learn Lessons Completed Screen';
    logMetricsTrackingEvent(event, properties)();
  },
};

const VIEW_DOUBLE_REWARD_WITH_DEPOSIT_CTA = {
  send: (properties) => {
    const event = 'View Double Reward With Deposit CTA';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_DOUBLE_REWARD_WITH_DEPOSIT_CTA = {
  send: (properties) => {
    const event = 'Press Double Reward With Deposit CTA';
    logMetricsTrackingEvent(event, properties)();
  },
};

const VIEW_ACCESS_REWARDS_WITH_DEPOSIT_CTA = {
  send: (properties) => {
    const event = 'View Access Rewards With Deposit CTA';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_ACCESS_REWARDS_WITH_DEPOSIT_CTA = {
  send: (properties) => {
    const event = 'Press Access Rewards With Deposit CTA';
    logMetricsTrackingEvent(event, properties)();
  },
};

const VIEW_LEARN_GET_STARTED_BANNER = {
  send: (properties) => {
    const event = 'View Learn Get Started Banner';
    logMetricsTrackingEvent(event, properties)();
  },
};

const CLICK_LEARN_GET_STARTED_BANNER = {
  send: (properties) => {
    const event = 'Click Learn Get Started Banner';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_SPONSOR_CALLOUT_LINK = {
  send: (properties) => {
    const event = 'Press Sponsor Callout Link';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_SEE_LEARN_SPONSOR_RULES_AND_DISCLAIMERS = {
  send: (properties) => {
    const event = 'Press Learn Sponsor See Rules and Disclaimers';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_SEE_MORE_ON_LEARN_TOPIC = {
  send: (properties) => {
    const event = 'Press See More On Learn Topic';
    logMetricsTrackingEvent(event, properties)();
  },
};

const RECURRING_INVESTMENT_TOOL_FORM_INPUT_CHANGE = {
  send: (properties) => {
    const event = 'Recurring Investment Tool Form Input Change';
    logMetricsTrackingEvent(event, properties)();
  },
};

const RECURRING_INVESTMENT_TOOL_FORM_UPDATE = {
  send: (properties) => {
    const event = 'Recurring Investment Tool Form Update';
    logMetricsTrackingEvent(event, properties)();
  },
};
const COMPLETED_DEPOSIT_LEARN_DEPOSIT_LESSON = {
  // Amount
  send: (properties) => {
    const event = 'Completed Deposit Learn Deposit Lesson';
    logMetricsTrackingEvent(event, properties)();
  },
};

const VIEW_SHARED_LEARN_LESSON_COMPLETE_SCREEN = {
  send: (properties) => {
    const event = 'View Shared Learn Lesson Complete Screen';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_SIGNUP_NOW_SHARED_LEARN_LESSON = {
  send: (properties) => {
    const event = 'Press Signup Now Shared Learn Lesson';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_TO_HOME_SHARED_LEARN_LESSON = {
  send: (properties) => {
    const event = 'Press To Home Shared Learn Lesson';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_VIEW_PREVIOUS_COMPLETED_LESSON = {
  send: (properties) => {
    const event = 'Press View Previous Completed Lesson';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_SHARE_PREVIOUS_COMPLETED_LESSON = {
  send: (properties) => {
    const event = 'Press Share Previous Completed Lesson';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_VIEW_LEARN_HOME = {
  send: (properties) => {
    const event = 'Press View Learn Home';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_PRODUCT_PARTNER_CALLOUT_LINK = {
  send: (properties) => {
    const event = 'Press Product Partner Outgoing Link';
    logMetricsTrackingEvent(event, properties)();
  },
};

const VIEW_PARTNER_PROMO_LESSON_BANNER = {
  send: (properties) => {
    const event = 'View Partner Promo Lesson Banner';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_VIEW_PARTNER_PROMO_LESSON = {
  send: (properties) => {
    const event = 'Press View Partner Promo Lesson';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_CUSTOM_MORE_INFO_LINK = {
  send: (properties) => {
    const event = 'Press Custom Lesson More Info Link';
    logMetricsTrackingEvent(event, properties)();
  },
};

const PRESS_EXTRA_CONTENT_LINK = {
  send: (properties) => {
    const event = 'Press Learn Extra Content Link';
    logMetricsTrackingEvent(event, properties)();
  },
};

const trackingEvents = {
  VIEW_LEARN_LESSON_SCREEN,
  VIEW_LEARN_DISCLAIMER_SCREEN,

  PRESS_VIEW_LEARN_HOME,

  PRESS_VIEW_PREVIOUS_COMPLETED_LESSON,
  PRESS_SHARE_PREVIOUS_COMPLETED_LESSON,

  VIEW_SHARED_LEARN_LESSON_COMPLETE_SCREEN,
  PRESS_SIGNUP_NOW_SHARED_LEARN_LESSON,
  PRESS_TO_HOME_SHARED_LEARN_LESSON,

  COMPLETE_LEARN_LESSON,
  VIEW_LEARN_REWARD_SCREEN,
  VIEW_ALL_LEARN_LESSONS_COMPLETED_SCREEN,

  VIEW_PARTNER_PROMO_LESSON_BANNER,
  PRESS_VIEW_PARTNER_PROMO_LESSON,

  VIEW_DOUBLE_REWARD_WITH_DEPOSIT_CTA,
  PRESS_DOUBLE_REWARD_WITH_DEPOSIT_CTA,

  VIEW_ACCESS_REWARDS_WITH_DEPOSIT_CTA,
  PRESS_ACCESS_REWARDS_WITH_DEPOSIT_CTA,

  VIEW_LEARN_GET_STARTED_BANNER,
  CLICK_LEARN_GET_STARTED_BANNER,

  PRESS_PRODUCT_PARTNER_CALLOUT_LINK,

  PRESS_SPONSOR_CALLOUT_LINK,
  PRESS_SEE_LEARN_SPONSOR_RULES_AND_DISCLAIMERS,
  PRESS_SEE_MORE_ON_LEARN_TOPIC,

  RECURRING_INVESTMENT_TOOL_FORM_INPUT_CHANGE,
  RECURRING_INVESTMENT_TOOL_FORM_UPDATE,

  PRESS_CUSTOM_MORE_INFO_LINK,

  COMPLETED_DEPOSIT_LEARN_DEPOSIT_LESSON,

  PRESS_EXTRA_CONTENT_LINK,
};

export default trackingEvents;
