import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as Actions from '../../actions/index';

import FinancialTableRow from '../../components/financials/FinancialTableRow';
import PageLoading from '../../components/PageLoading';

class BasicFinancialsContainer extends Component {
  render() {
    if (this.isLoading()) {
      return this.renderLoading();
    }
    return (
      <div className="basic-financials-container">
        {this.renderTable('Left')}
        {this.renderTable('Right')}
      </div>
    );
  }

  renderLoading = () => {
    return <PageLoading pageName={'Fundamental Data'} icon="fading-3balls" size="small" flatStyle={true} />;
  };

  renderTable = (side) => {
    const propertiesToDisplay = this[`return${side}TableProperties`]();
    return (
      <div className={`table-container ${side.toLowerCase()}-table-container`}>
        <table className="financials-basic-summary-table scrollable">
          <tbody>
            {propertiesToDisplay.map((property) => (
              <FinancialTableRow
                key={`fundamental-${typeof property === 'object' ? property.property : property}`}
                property={property}
                security={this.props.security}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  isLoading = () => {
    return !this.didSecurityFundamentalDataLoad();
  };

  returnPropertiesToDisplay = () => {
    const defaultPropertiesToDisplay = [
      [
        {
          displayName: 'Price/Earnings',
          property: 'Price / Earnings',
        },
        {
          displayName: '(Fwd)',
          property: 'Forward P/E',
        },
      ],
      {
        displayName: 'Price/Sales',
        property: 'Price / Sales',
      },
      {
        displayName: 'Proj. Rev. Growth',
        property: 'Projected Revenue Growth',
      },
      'Dividend Yield',
      'Market Cap',
      'Net Debt',
      {
        displayName: 'Fwd EPS (Curr Qtr)',
        property: 'Forward EPS Curr Qtr',
      },
      {
        displayName: 'Fwd EPS (Curr Yr)',
        property: 'Forward EPS Curr Yr',
      },
    ];
    return defaultPropertiesToDisplay;
  };

  returnPropertiesAvailableOnSecurity = () => {
    return this.returnPropertiesToDisplay().filter((property) => this.isDataAvailableForProperty(property));
  };

  returnLeftTableProperties = () => {
    const propertiesAvailableOnSecurity = this.returnPropertiesAvailableOnSecurity();
    const half = Math.ceil(propertiesAvailableOnSecurity.length / 2);
    return propertiesAvailableOnSecurity.slice(0, half);
  };

  returnRightTableProperties = () => {
    const propertiesAvailableOnSecurity = this.returnPropertiesAvailableOnSecurity();
    const half = Math.ceil(propertiesAvailableOnSecurity.length / 2);
    return propertiesAvailableOnSecurity.slice(half, Math.ceil(propertiesAvailableOnSecurity.length));
  };

  returnSecurityId = () => {
    return this.props.security.security_id;
  };

  returnAllSecuritiesFundamentalData = () => {
    return this.props.fundamentalData.securities;
  };

  returnSecurityFundamentalData = () => {
    const securityId = this.returnSecurityId();
    const allFundamentalData = this.returnAllSecuritiesFundamentalData();
    return allFundamentalData[securityId];
  };

  didSecurityFundamentalDataLoad = () => {
    return this.returnSecurityFundamentalData() !== undefined;
  };

  isDataAvailableForProperty = (property) => {
    const fundamentalData = this.returnSecurityFundamentalData();
    if (Array.isArray(property)) {
      const values = property.map((p) => fundamentalData[typeof p === 'object' ? p.property : p]);
      return values.every((value) => value !== null && value !== undefined);
    } else {
      const value = fundamentalData[typeof property === 'object' ? property.property : property];
      return value !== null && value !== undefined;
    }
  };
}

const mapStateToProps = (state) => {
  return {
    securities: state.securities,
    fundamentalData: state.fundamentalData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicFinancialsContainer);
