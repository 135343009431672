import React from 'react';
import { useSelector } from 'react-redux';
import { isUndefinedOrNull } from '../../../helpers/generalHelpers';

const getSecurityPriceFromState = (state, id) => state.securitiesPrice.securities[id] || null;

// TODO: this shows is_halted, merge property out

export const useSecurityPrice = (id) => {
  const storeSecurity = useSelector((state) => getSecurityPriceFromState(state, id));

  const [security, setSecurity] = React.useState(storeSecurity || {});

  React.useEffect(() => {
    if (security !== storeSecurity && !isUndefinedOrNull(storeSecurity)) {
      setSecurity(storeSecurity);
    }
  }, [storeSecurity, security]);

  return security;
};
