import { connect } from 'react-redux';

const OrdersData = (props) => {
  const {
    not_placed_orders,
    placed_orders,
    awaiting_approval_orders,

    children,
  } = props;

  return children(placed_orders, awaiting_approval_orders, not_placed_orders);
};

const mapStateToProps = (state) => {
  const ordersStore = state.orders;
  const { not_placed_orders, placed_orders, awaiting_approval_orders } = ordersStore;
  return {
    not_placed_orders,
    placed_orders,
    awaiting_approval_orders,
  };
};

export default connect(mapStateToProps)(OrdersData);
